import { BOUND_STATUS } from '../../../_statics/policy.statics';
import { BINDER_RESCINDED } from '../../../_statics/quote.statics';
import type { Binder } from '../../../accounts/summary/types/binder.types';

export const useAgencyBinderPrimeXOptions = (binder: Binder) => {
  const canDownloadApplication = !!binder.quoteId && !binder.isPrimePlus;
  const canDownloadBinder =
    !!binder.quoteId && binder.status !== BINDER_RESCINDED;
  const canDownloadInvoice = !!binder.isDocumentAvailable;
  const canDownloadReceipt = binder.isPaid === true;
  const canRescindBinder = binder.status === BOUND_STATUS;
  const canIssuePolicy = !!binder.quoteId && binder.status === BOUND_STATUS;

  return {
    documents: {
      name: 'Documents',
      show: canDownloadApplication || canDownloadBinder || canDownloadInvoice,
    },
    downloadApplication: {
      name: 'Download Application',
      show: canDownloadApplication,
    },
    downloadBinder: {
      name: 'Download Binder',
      show: canDownloadBinder,
    },
    downloadInvoice: {
      name: 'Download Invoice',
      show: canDownloadInvoice,
    },
    downloadReceipt: {
      name: 'Download Receipt',
      show: canDownloadReceipt,
    },
    rescindBinder: {
      name: 'Rescind Binder',
      show: canRescindBinder,
    },
    issuePolicy: {
      name: 'Issue Policy',
      show: canIssuePolicy,
    },
  };
};
