import React from 'react';
// mui
import { styled } from '@mui/material';
// components
import Showable from '../../Showable';

const FullScreenDropZoneWrapper = ({
  FileDropZone = FileDropZoneDefault,
  children,
}) => {
  const [isUserDroppingFiles, setIsUserDroppingFiles] = React.useState(false);

  React.useLayoutEffect(() => {
    const onDragEnter = () => {
      setIsUserDroppingFiles(true);
    };

    const onMouseLeave = () => {
      setIsUserDroppingFiles(false);
    };

    document.addEventListener('dragenter', onDragEnter);
    document.addEventListener('mouseleave', onMouseLeave);

    return () => {
      document.removeEventListener('dragenter', onDragEnter);
      document.removeEventListener('mouseleave', onMouseLeave);
    };
  }, []);

  return (
    <>
      <Showable show={isUserDroppingFiles}>
        <FileDropZone />
      </Showable>
      {children}
    </>
  );
};

export default FullScreenDropZoneWrapper;

// styling
const FileDropZoneDefault = styled('div')(({ theme: { palette } }) => ({
  position: 'absolute',
  inset: 0,
  zIndex: 1000,
  backgroundColor:
    palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.15)'
      : 'rgba(0, 0, 0, 0.15)',
}));
