import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CbButton from '../../../components/Buttons/CbButton';

const ErrorPage = () => {
  const history = useHistory();

  const handleGoToInbox = () => {
    history.push('/agency/inbox');
  };

  const handleGoToAccounts = () => {
    history.push('/agency/accounts');
  };

  const handlePageRefresh = () => {
    window.location.reload();
  };

  return (
    <ErrorContainer data-testid="error-page">
      <Typography style={{ fontWeight: 600 }} className="contrast-text">
        Uh oh! It looks like a system error has occured. We apologize for the
        inconvenience. <br />
        In the meantime here are some options for you.
      </Typography>
      <ButtonContainer>
        <CbButton styleName="ctaButton" size="medium" onClick={handleGoToInbox}>
          Go to Inbox
        </CbButton>
        <CbButton
          styleName="ctaButton"
          size="medium"
          onClick={handleGoToAccounts}
        >
          Go to Accounts
        </CbButton>
        <CbButton
          styleName="ctaButton"
          size="medium"
          onClick={handlePageRefresh}
        >
          Retry
        </CbButton>
      </ButtonContainer>
    </ErrorContainer>
  );
};

export default ErrorPage;

const ErrorContainer = styled('section')({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  gap: '1.5rem',
  justifyContent: 'center',
  marginTop: '1.5rem',
});
