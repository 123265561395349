import React from 'react';
import { compose } from 'redux';
// mui
import { withTheme, makeStyles } from '@mui/styles';

// components
import { withShowable } from '../console/_global/lib/withShowable';
import InsuranceLineA from '../_assets/svg/InsuranceLineA.svg';
import InsuranceLineS from '../_assets/svg/InsuranceLineS.svg';

export const InsuranceLineSymbol = compose(
  withShowable,
  withTheme
)(({ isSurplus, theme }) => {
  const classes = useStyles();

  return (
    <div className={classes.insuranceLine}>
      {isSurplus === true && (
        <InsuranceLineS fill={theme.palette.primary.contrastText} />
      )}
      {isSurplus === false && (
        <InsuranceLineA fill={theme.palette.primary.contrastText} />
      )}
    </div>
  );
});

const useStyles = makeStyles(({ config, palette }) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  insuranceLine: {
    color: palette.text.link,
    width: '100%',
    textAlign: 'right',
    lineHeight: 1,
    fontSize: config.textSizes.inch,
  },
}));
