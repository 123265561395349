import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

// mui
import { DialogActions, DialogContent, Grid } from '@mui/material';

// cowbell ui
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import CbButton from '../../Buttons/CbButton';
import { TextFieldBase } from '../../inputs/TextFieldBase';

// hocs
import { withFormController } from '../../hocs/forms';

// hooks
import { useAPIErrorHandler } from '../../hooks/useAPIErrorHandler';

// apis
import { updateQuoteAgentInfo } from '../../../inbox/QuotesService';

import { AgentAutoComplete } from './UpdateAgentInfo/AgentAutoComplete';
import { TeamAutoComplete } from './UpdateAgentInfo/TeamAutoComplete';
import { NoOptionsText } from '../../error/NoOptionsText';
import { Value } from '../../../console/manage/account/lookup/AccountLookupUpdateAgentFields';
import { useCowbellTranslations } from '../../../i18n/translations';

const TextField = withFormController(TextFieldBase);

interface UpdateAgentInfoModalProps {
  data: {
    agencyId: string;
    agencyName: string;
    agentEmail: string;
    agentFirstName: string;
    agentLastName: string;
    companyName: string;
    id: string;
    teams: string[];
    onSuccess: () => void;
  };
  close: () => void;
}

function UpdateAgentInfoModal({ data, close }: UpdateAgentInfoModalProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleAPIError = useAPIErrorHandler();
  const { translations } = useCowbellTranslations(['Agent', 'Agency']);

  const defaultValues = {
    agencyId: data.agencyId,
    agencyName: data.agencyName,
    agentEmail: data.agentEmail,
    agentFirstName: data.agentFirstName,
    agentLastName: data.agentLastName,
    quoteId: data.id,
    agent: { label: '', value: '' },
    team: { label: '', value: '' },
  };

  const formMethods = useForm<UpdateAgentInfoFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const onSubmit = (formValues: UpdateAgentInfoFormValues) => {
    const payload = getPayloadFromFormValues({ id: data.id, formValues });

    updateQuoteAgentInfo({}, payload)
      .then(() => {
        enqueueSnackbar(`${translations.Agent} updated successfully!`, {
          variant: 'success',
        });

        if (typeof data.onSuccess === 'function') {
          data.onSuccess();
        }

        close();
      })
      .catch(
        handleAPIError(
          'Not able to update agent at this time. Please try again later.'
        )
      );
  };

  const selectedAgent = formMethods.watch('agent');

  const hasCurrentAgentEmail = Boolean(data.agentEmail);
  const currentAgent = hasCurrentAgentEmail
    ? `${data.agentFirstName} ${data.agentLastName} (${data.agentEmail})`
    : `${data.agentFirstName} ${data.agentLastName}`;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2rem 5rem' }}>
          <Grid container>
            <Grid item sm={5}>
              <Value>Account Name:</Value>
            </Grid>
            <Grid item sm={7}>
              <Value>{data.companyName}</Value>
            </Grid>

            <Grid item sm={12}>
              <TextField
                name="agencyName"
                fullWidth
                disabled
                containerClass={undefined}
                label={`${translations.Agency}`}
                error={undefined}
                helperText={undefined}
                required={false}
              />
            </Grid>

            <Grid item sm={5}>
              <Value>Current {translations.Agent}:</Value>
            </Grid>
            <Grid item sm={7}>
              <Value>{currentAgent}</Value>
            </Grid>

            <Grid item sm={12}>
              <AgentAutoComplete
                name="agent"
                label={`Assign New ${translations.Agent}`}
                required
                autoCompleteProps={{
                  freeSolo: false,
                  noOptionsText: (
                    <NoOptionsText label={`${translations.Agent} not found`} />
                  ),
                }}
                inputLabelBaseProps={{
                  indent: true,
                  className: classes.label,
                }}
                onSelect={(value) => {
                  formMethods.setValue('agent', value);
                }}
                agencyId={data.agencyId}
              />
            </Grid>

            <Grid item sm={12}>
              <TeamAutoComplete
                name="team"
                label="Assign New Team"
                required
                autoCompleteProps={{
                  disabled: !selectedAgent?.value,
                  freeSolo: false,
                  noOptionsText: <NoOptionsText label="Team not found" />,
                }}
                inputLabelBaseProps={{
                  indent: true,
                  className: classes.label,
                }}
                onSelect={(value) => {
                  formMethods.setValue('team', value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton onClick={close} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
          >
            Update
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
}

const validationSchema = Yup.object().shape({
  agent: Yup.object().shape({
    value: Yup.string().required().label('Selection is required'),
  }),
  team: Yup.object().shape({
    value: Yup.string().required().label('Team selection is required'),
  }),
});

const getPayloadFromFormValues = ({
  id,
  formValues,
}: {
  id: string;
  formValues: UpdateAgentInfoFormValues;
}) => {
  return {
    agentFirstName: formValues.agent.firstName,
    agentLastName: formValues.agent.lastName,
    agentEmail: formValues.agent.email,
    agentPhone: formValues.agent.phone,
    quoteId: id,
    teamId: formValues.team.value,
    agencyId: formValues.agencyId,
  };
};

const useStyles = makeStyles(() => {
  return {
    valueLabel: {
      fontSize: '1.1666666667rem',
      padding: 0,
    },
    label: {
      fontSize: '1rem',
      lineHeight: '1.3333333333rem',
      marginLeft: '0.8333333333rem',
      padding: '1.5rem 0 0.5rem 0',
    },
  };
});

export const UpdateAgentInfoModalConfig = {
  UpdateAgentInfoModal: {
    component: UpdateAgentInfoModal,
    config: {
      title: 'Update Agent Info',
      maxWidth: 'sm',
      fullWidth: true,
    },
  },
};

export interface UpdateAgentInfoFormValues {
  agencyId: string;
  agent: {
    label: string;
    value: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  agentFirstName: string;
  agentLastName: string;
  agentEmail: string;
  team: { label: string; value: string };
}
