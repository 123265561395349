/**
 * @name quoteReducer
 * @description This reducer is a local reducer not intended to be used with redux or other global state mechanisms.
 */

import { handleActions } from 'redux-actions';

// account actions
export const SET_AGENCY_BIND = 'SET_AGENCY_BIND';
export const SET_AGENCY_SEND = 'SET_AGENCY_SEND';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_ROUTE = 'SET_ROUTE';

// action creators
export const setAgencyBindInfo = (payload) => ({
  type: SET_AGENCY_BIND,
  payload,
});

export const setAgencySendInfo = (payload) => ({
  type: SET_AGENCY_SEND,
  payload,
});

export const resetState = (payload) => ({
  type: SET_INITIAL_STATE,
  payload,
});

export const setRoute = (payload) => ({
  type: SET_ROUTE,
  payload,
});

export const initialState = {
  from: null,
  bind: {
    customer: {},
    surplus: {},
  },
  send: {
    customer: {},
    surplus: {},
  },
};

export const quoteReducer = handleActions(
  {
    [SET_ROUTE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [SET_AGENCY_BIND]: (state, action) => {
      return {
        ...state,
        bind: {
          ...state.bind,
          ...action.payload,
          customer: {
            ...state.bind.customer,
            ...action.payload.customer,
          },
          surplus: {
            ...state.bind.surplus,
            ...action.payload.surplus,
          },
        },
      };
    },

    [SET_AGENCY_SEND]: (state, action) => {
      return {
        ...state,
        send: {
          ...state.send,
          ...action.payload,
          customer: {
            ...state.send.customer,
            ...action.payload.customer,
          },
          surplus: {
            ...state.send.surplus,
            ...action.payload.surplus,
          },
        },
      };
    },

    [SET_INITIAL_STATE]: () => {
      return initialState;
    },
  },

  initialState
);
