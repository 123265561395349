// components
import { Typography } from '@mui/material';

import NextPublicWrapper from '../NextPublicWrapper';

const PasswordExpired = () => {
  return (
    <NextPublicWrapper>
      <Typography className="great-primer-text" gutterBottom paragraph>
        Your password has expired
      </Typography>

      <Typography gutterBottom paragraph>
        If you have a login with Cowbell the email has been sent to you.
      </Typography>
      <Typography gutterBottom paragraph>
        If you do not receive an email please contact{' '}
        <a
          href="mailto:support@cowbellcyber.ai"
          style={{ color: 'white', textDecoration: 'underline' }}
        >
          support@cowbellcyber.ai
        </a>
      </Typography>
    </NextPublicWrapper>
  );
};

export default PasswordExpired;
