import React from 'react';

type ShowProps = {
  when: boolean;
  fallback?: React.ReactNode;
};

export function Show({
  children,
  when,
  fallback,
  ...props
}: React.PropsWithChildren<ShowProps>) {
  if (when !== true) {
    return <>{fallback}</>;
  }

  const renderChildren = () => {
    if (Array.isArray(children)) {
      return React.Children.map(children, mergeProps(props));
    }

    return mergeProps(props)(children);
  };

  return <>{renderChildren()}</>;
}

const mergeProps = (newProps: unknown) => (element: React.ReactNode) => {
  if (typeof element != 'object' || element == null) {
    return element;
  }

  // @ts-expect-error unknown props for children
  return React.cloneElement(element, newProps);
};
