import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { useQuery } from '@tanstack/react-query';
import CbButton from '../../Buttons/CbButton';
import { SimpleSelect } from '../../inputs';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { InputLabelBase } from '../../inputs/InputLabelBase';
import { CurrencyField as CurrencyFieldBase } from '../../inputs/CurrencyField';
import { withFormController } from '../../hocs/forms';
import { getAllCarriers } from '../../../api/excess-quote.api';

const Select = withFormController(SimpleSelect);
const TextField = withFormController(TextFieldBase);
const CurrencyField = withFormController(CurrencyFieldBase);

const schema = Yup.object().shape({
  carrierName: Yup.string().label('Primary Policy Carrier').required(),
  policyNumber: Yup.string()
    .label('Policy Number')
    .when('quoteNumber', {
      is: (quoteNumber) => quoteNumber === '' || quoteNumber === undefined,
      then: Yup.string().required('Policy Number or Quote Number is required.'),
      otherwise: Yup.string(),
    }),
  quoteNumber: Yup.string().label('Quote Number'),
  limit: Yup.number()
    .label('Limit of Liability')
    .fromCurrency()
    .min(1)
    .typeError('${label} is a required field')
    .required(),
  attachment: Yup.number()
    .label('Attachment')
    .fromCurrency()
    .min(1)
    .typeError('${label} is a required field')
    .required(),
});

export const AddPrimaryInsurance = ({ data = {}, ...props }) => {
  const classes = useStyles();
  const { onSave, values } = data;
  const defaultValues = _.pick(values, [
    'carrierName',
    'policyNumber',
    'quoteNumber',
    'limit',
    'attachment',
  ]);

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { data: carriers } = useQuery(['carriers'], () => {
    return getAllCarriers().then((res) => res.data);
  });

  const uiCarrierOptions = constructUiCarrierOptions(carriers);

  const onSubmit = (formValues) => {
    return onSave(formValues);
  };

  const handleCancel = () => {
    props.close();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingTop: '5rem' }}>
          <Grid container>
            <Grid item container spacing={4} direction="row">
              <Grid item md={6}>
                <Select
                  name="carrierName"
                  options={uiCarrierOptions}
                  label="Primary Policy Carrier"
                  required
                />
              </Grid>
              <Grid
                item
                container
                sm={6}
                direction="row"
                justifyContent="space-between"
              >
                <Box>
                  <InputLabelBase indent>Policy Number</InputLabelBase>
                  <TextField name="policyNumber" />
                </Box>
                <Box mt={6}>
                  <Typography>OR</Typography>
                </Box>
                <Box>
                  <InputLabelBase indent>Quote Number</InputLabelBase>
                  <TextField name="quoteNumber" />
                </Box>
              </Grid>
            </Grid>
            <Grid item container spacing={4} direction="row">
              <Grid item md={6}>
                <Box>
                  <InputLabelBase indent required>
                    Limit of Liability
                  </InputLabelBase>
                  <CurrencyField name="limit" />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <InputLabelBase indent>Attachment</InputLabelBase>
                  <CurrencyField
                    name="attachment"
                    disabled
                    className={classes.attachment}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton onClick={handleCancel} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton type="submit" styleName="ctaButton">
            Save
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const useStyles = makeStyles(() => ({
  attachment: {
    '& input': { border: 'none' },
  },
}));

const constructUiCarrierOptions = (carriers = []) => {
  return carriers.map((carrier) => {
    return {
      label: carrier,
      value: carrier,
    };
  });
};

export const AddPrimaryInsuranceConfig = {
  AddPrimaryInsurance: {
    component: AddPrimaryInsurance,
    // all mui related config
    config: {
      fullWidth: true,
      maxWidth: 'lg',
      title: 'Add Primary Insurance',
    },
  },
};
