import React from 'react';
import { DialogContent, DialogActions, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';

import CbButton from '../Buttons/CbButton';

const styles = ({ config }) => {
  return {
    innerTitle: {
      fontSize: config.textSizes.primer,
      fontWeight: 600,
      margin: '0px 0px 10px 0px',
    },
  };
};

export const SecurityQuestionsReasons = withStyles(styles)(
  ({ classes, close, data }) => {
    const divider1 =
      (!data.contingentSystemFailureValid && !data.cyberCrimeLossValid) ||
      (!data.contingentSystemFailureValid && !data.fullSystemFailureValid);
    const divider2 = !data.cyberCrimeLossValid && !data.fullSystemFailureValid;

    return (
      <div>
        <DialogContent>
          <div className="modal-title" style={{ textAlign: 'left' }}>
            {!data.contingentSystemFailureValid && (
              <div>
                <p className={classes.innerTitle}>
                  To add Cyber Contingent System Failure to this policy, the
                  insured will need to:
                </p>
                {!data.dmzSeparation && (
                  <li>
                    All internet-accessible systems (e.g. web-, email-servers)
                    should be segregated from the organization’s trusted network
                    (e.g. within a demilitarized zone (DMZ) or at a third-party
                    service provider)
                  </li>
                )}
                {!data.thirdPartySecurityAgreement && (
                  <li>
                    Agreements with third-party service providers are at the
                    levels of security commensurate with the organization’s
                    information security standard
                  </li>
                )}
              </div>
            )}
            {divider1 && <Divider style={{ margin: '20px 0' }} />}
            {!data.cyberCrimeLossValid && (
              <div>
                <p className={classes.innerTitle}>
                  To add Cyber Crime Loss to this policy, the insured will need
                  to:
                </p>

                {!data.isVerifyingBankAccounts && (
                  <li>
                    Verify vendor/supplier bank accounts before adding to your
                    accounts payable systems
                  </li>
                )}
                {!data.isAuthenticatingFundTransferRequests && (
                  <li>
                    Authenticate any funds transfer requests (e.g. by calling a
                    customer to verify the request at a predetermined phone
                    number)
                  </li>
                )}
                {!data.isPreventingUnauthorizedWireTransfers && (
                  <li>
                    Prevent unauthorized employees from initiating wire
                    transfers
                  </li>
                )}
              </div>
            )}
            {divider2 && <Divider style={{ margin: '20px 0' }} />}
            {!data.fullSystemFailureValid && (
              <div>
                <p className={classes.innerTitle}>
                  To add Full System Failure to this policy, the insured will
                  need to:
                </p>

                {!data.backupFrequency && (
                  <li>
                    The organization performs backups of business-critical data
                    on periodic basis
                  </li>
                )}
                {!data.dmzSeparation && (
                  <li>
                    All internet-accessible systems (e.g. web-, email-servers)
                    should be segregated from the organization’s trusted network
                    (e.g. within a demilitarized zone (DMZ) or at a third-party
                    service provider)
                  </li>
                )}
                {!data.testedFullFailover && (
                  <li>
                    The organization has tested a full failover of the most
                    critical servers
                  </li>
                )}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="ctaButton" buttonText="Close" onClick={close} />
        </DialogActions>
      </div>
    );
  }
);

export default SecurityQuestionsReasons;
