import React from 'react';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';

import {
  Cancel,
  Brightness1 as CircleIcon,
  CheckCircle,
} from '@mui/icons-material';

interface Props {
  children?: () => JSX.Element;
  constraints: any[];
  initial: boolean;
}

export function PasswordConstraintsList({
  children,
  constraints,
  initial,
}: Props) {
  if (typeof children === 'function') {
    return children();
  }

  return (
    <List dense style={{ maxHeight: 'unset' }}>
      {constraints.map((constraint: any) => (
        <ListItem key={constraint.code}>
          <ListItemIcon>
            <PasswordIcons isValid={constraint.isValid} initialIcon={initial} />
          </ListItemIcon>
          <ListItemText primary={constraint.detail} />
        </ListItem>
      ))}
    </List>
  );
}

interface PasswordIconsProps {
  isValid: boolean;
  initialIcon: boolean;
}

const PasswordIcons = ({
  isValid,
  initialIcon = false,
}: PasswordIconsProps) => {
  if (initialIcon) {
    return <CircleIcon color="secondary" />;
  }

  if (!isValid) {
    return <CancelIcon />;
  }

  return <CheckCircleIcon />;
};

const CheckCircleIcon = styled(CheckCircle)(({ theme }: any) => ({
  color: theme.palette.common.cowbellGreen,

  '&:hover': {
    color: theme.palette.common.cowbellGreen,
    cursor: 'default',
  },
}));

const CancelIcon = styled(Cancel)(({ theme }: any) => ({
  color: theme.palette.common.tomato,

  '&:hover': {
    color: theme.palette.common.tomato,
    cursor: 'default',
  },
}));
