import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Box, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import classnames from 'classnames';
import { DetailsLineItem } from './DetailsLineItem';
import { CustomCheckbox } from '../../../components/inputs';

export const CoverageDetails = compose(withRouter)(
  ({ id, group, groupedCoverages, background }) => {
    const classes = styles({ background });

    const checkboxClasses = classnames({
      mandatory: false,
    });
    const coverages = groupedCoverages[id];

    return (
      <>
        <Box mt={4} mb={2}>
          <Divider />
        </Box>
        <div className={classes.groupContainer}>
          <Grid container>
            <Grid item md={4} style={{ paddingLeft: '1.33rem' }}>
              <CustomCheckbox
                className={checkboxClasses}
                classes={classes}
                variant="filled"
                indeterminate={group.name !== 'Liability Expense'}
                label={<h4 className={classes.heading}>{group.name}</h4>}
                checked
              />
            </Grid>
            <Grid
              item
              md={8}
              className="flex--spaced"
              style={{ paddingRight: '0.416rem' }}
            >
              <Grid container className={classes.labels}>
                <Grid item md={3}>
                  COVERAGE LIMIT
                </Grid>
                <Grid item md={3}>
                  DEDUCTIBLE
                </Grid>
                <Grid item md={3}>
                  WAITING PERIOD
                </Grid>
                <Grid item md={3} style={{ paddingRight: '1rem' }}>
                  RETRO ACTIVE PERIOD
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {coverages.map((coverage) => (
            <DetailsLineItem coverage={coverage} />
          ))}
        </div>
      </>
    );
  }
);

const styles = makeStyles(({ config, palette }) => ({
  groupContainer: {
    '& > div:nth-child(even)': {
      background: (props) => {
        if (props.background) {
          return props.background;
        }
        return palette.background.default;
      },
    },
  },
  heading: {
    color: palette.primary.contrastText,
    textTransform: 'uppercase',
    fontSize: config.textSizes.tertia,
    fontWeight: config.weights.bold,
  },
  labels: {
    textAlign: 'right',
    paddingRight: '1rem',
    color: palette.primary.contrastText,
    fontSize: config.textSizes.normal,
  },
  checked: {
    color: `${palette.common.cowbellGreen2} !important`,

    '& + span': {
      color: `${palette.common.cowbellGreen2}`,
    },

    '&.mandatory': {
      color: `${palette.common.cowbellBlue} !important`,
    },

    '&.mandatory + span': {
      color: `${palette.common.cowbellBlue}`,
    },
    '&:hover': {
      background: 'none !important',
    },
  },
}));
