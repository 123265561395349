import React, { useState } from 'react';
import { useForm } from 'react-hook-form-4';
import _ from 'lodash';
import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as api from '../../../api/apis';
import CbButton from '../../Buttons/CbButton';
import { getCowbellTranslations } from '../../../i18n/translations';
// services

export const AgencyDeleteConfirm = ({ data, ...props }) => {
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState('');
  const { makeRefreshCall } = data;

  const onSubmit = () => {
    api
      .deleteSelectedAgency(data.id)
      .then(() => {
        enqueueSnackbar(`${t(translationKeys.agency)} deleted successfully!`, {
          variant: 'success',
        });
        props.close();
        makeRefreshCall();
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data',
            'Something went wrong while changing updating.'
          )
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent className="paf">
        <div className="modal-title">
          Are you sure you want to delete the{' '}
          {t(translationKeys.agency).toLowerCase()}
          <span className="modal-blue-text"> {data.name}</span>?
        </div>
        {error && (
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={props.close}>
          Cancel
        </CbButton>
        <CbButton
          loading={isSubmitting}
          styleName="ctaButton"
          buttonText={`Remove ${t(translationKeys.agency)}`}
          type="submit"
        />
      </DialogActions>
    </form>
  );
};

const { t, translationKeys } = getCowbellTranslations();

export const AgencyDeleteConfirmConfig = {
  AgencyDeleteConfirm: {
    component: AgencyDeleteConfirm,
    config: {
      title: `Delete ${t(translationKeys.agency)}`,
      fullWidth: true,
    },
  },
};
