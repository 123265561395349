import React from 'react';
import {
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@mui/material';

export const CheckBox = ({ label = '', formLabelProps = {}, ...props }) => {
  const color = props.disableRipple ? 'primary' : 'secondary';
  if (label) {
    return (
      <MuiFormControlLabel
        label={label}
        variant="body1"
        control={<MuiCheckbox color={color} {...props} fontSize="small" />}
        {...formLabelProps}
      />
    );
  }

  return <MuiCheckbox color={color} fontSize="small" {...props} />;
};

CheckBox.defaultProps = {
  disableRipple: true,
  formLabelProps: {},
};
export default CheckBox;
