import * as Ramda from 'ramda';
import jwtdecode from 'jwt-decode';
import { createTransform } from 'redux-persist';

import type { TokenPayload } from '../components/hooks/useAuth';
import {
  UNKNOWN_PERSONA,
  type Persona,
} from '../console/_statics/persona.statics';
import { retrieveAuthTokens } from '../utils/next/auth.utils';

function filterUISettings(state: any) {
  const UI_SETTINGS_BLACKLIST = ['version'];
  return Ramda.omit(UI_SETTINGS_BLACKLIST, state);
}

export const SideEffectTransformations = createTransform(
  /**
   * @name inbound
   * @description Data on it's way to being persisted (out of the application state)
   */
  (inboundState: any, key) => {
    const filteredState = filterUISettings(inboundState);

    // set state into localstorage under persona key
    if (inboundState.persona !== null) {
      const persona = pullPersonFromToken();
      localStorage.setItem(persona, JSON.stringify(filteredState));
    }

    return filteredState;
  },

  /**
   * @name outbound
   * @description Data on it's way to being rehydrated (into the application state)
   */
  (outboundState, key) => {
    const filteredState = filterUISettings(outboundState);
    return filteredState;
  },

  { whitelist: ['uiSettings'] }
);

function pullPersonFromToken(): Persona | typeof UNKNOWN_PERSONA {
  const { accessToken } = retrieveAuthTokens();

  if (!accessToken || typeof accessToken !== 'string') {
    // throw new Error('Unable to pull persona for persistence');
    return UNKNOWN_PERSONA;
  }

  const payload: TokenPayload = jwtdecode(accessToken as string);
  return payload.at;
}
