import React, { Component } from 'react';

/* Actions */
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { DialogContent } from '@mui/material';

import { withStyles } from '@mui/styles';
import { PubSub } from '../../../../utils/eventUtils';

import _config from '../../../../themes/_base/_config';
import { verifyProfileGroupById } from '../../../../accounts/AccountActions';

import { withActor } from '../../../hocs/withActor';
import { withToggleModal } from '../../../hocs/withToggleModal';

const styles = () => {
  return {
    topContainer: {
      flex: '1 0 auto',
      padding: '50px 70px 0 70px',
    },
    greenText: {
      color: _config.colors.cowbellGreen2,
    },
    blueText: {
      color: _config.colors.cowbellLight,
    },
    title: {
      color: _config.colors.white,
    },
    line: {
      textAlign: 'center',
      width: '100%',
    },
    firstLine: {
      marginBottom: '2vh',
      fontSize: 24,
    },
    group2: {
      fontSize: 21,
    },
    boldText: {
      fontWeight: 600,
    },
    group3: {
      fontSize: 16,
    },
    group4: {
      fontSize: 14,
    },
    margin2: {
      marginBottom: '2vh',
    },
    margin3: {
      marginBottom: '2vh',
    },
    margin4: {
      marginBottom: 69,
    },
    buttonBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: _config.colors.darkGrey2,
      margin: '0 3vw 3vh',
      flexShrink: 0,
    },
  };
};

class NoDomainConfirmation extends Component {
  componentDidMount() {
    this.subscription = PubSub.subscribe('foot-button-one', (eventData) => {
      if (eventData) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit = () => {
    const { props } = this;

    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);

    const callback = () => {
      this.props.enqueueSnackbar('Attested Successfully', {
        variant: 'success',
      });
      PubSub.publish('loading', false);
      PubSub.publish('disabled', false);
      this.props.toggleModal.direct('NoDomainConfirmation', false);
    };

    props.verifyProfileGroupById(props.actor.account.id, 1, true, callback);
  };

  render() {
    const { props } = this;
    const { classes } = props;

    return (
      <DialogContent>
        <div className="modal-title">
          Are you sure your organization does not have a{' '}
          <span className={`${classes.greenText} ${classes.boldText}`}>
            Web Domain
          </span>
          ?
        </div>
      </DialogContent>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyProfileGroupById,
    },
    dispatch
  );
};

export default compose(
  withToggleModal,
  withSnackbar,
  withStyles(styles),
  withActor
)(connect(null, mapDispatchToProps)(NoDomainConfirmation));
