import React from 'react';
import { SvgIcon } from '@mui/material';

export const ClaimsDueDateIcon = ({ color = 'red', ...props }) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="15.5"
    height="18"
    viewBox="0 0 15.5 18"
    {...props}
  >
    <path
      data-name="Icon awesome-calendar-day"
      d="M0 16.313A1.675 1.675 0 0 0 1.661 18h12.178a1.675 1.675 0 0 0 1.661-1.687V6.75H0zm2.214-6.75A.56.56 0 0 1 2.768 9h3.321a.56.56 0 0 1 .554.563v3.375a.56.56 0 0 1-.554.563H2.768a.56.56 0 0 1-.554-.562zM13.839 2.25h-1.66V.563A.56.56 0 0 0 11.625 0h-1.107a.56.56 0 0 0-.554.563V2.25H5.536V.563A.56.56 0 0 0 4.982 0H3.875a.56.56 0 0 0-.554.563V2.25h-1.66A1.675 1.675 0 0 0 0 3.938v1.687h15.5V3.938a1.675 1.675 0 0 0-1.661-1.688z"
      style={{ fill: color }}
    />
  </SvgIcon>
);
