import * as Yup from 'yup';

import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, DialogActions, DialogContent, styled } from '@mui/material';

import TextField from '../../../../../../components/inputs/text-fields/TextField';
import CbButton from '../../../../../../components/Buttons/CbButton';
import { withFormController } from '../../../../../../components/hocs/forms';

type ReferToUWModalProps = {
  data: {
    quoteId: string;
    subjectivityId: string;
  };
  close: () => void;
};

function ReferToUWModal({ close }: ReferToUWModalProps) {
  const form = useForm<FormValues>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(formSchema),
  });

  const handleSubmit: SubmitHandler<FormValues> = (formValues) => {
    // eslint-disable-next-line no-console
    console.log({ formValues });
    // todo: plug in subjectivity UW referral API
  };

  return (
    <DialogContent style={{ padding: '1rem 2rem' }}>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          noValidate
          autoComplete="false"
        >
          <FormContentContainer>
            <ControlledTextField
              name="referralReason"
              required
              label="Referral Reason"
              placeholder="Please enter the reason for referring to underwriting"
              autoFocus
              multiline
              minRows={4}
              maxRows={6}
              fullWidth
            />
          </FormContentContainer>

          <DialogActions>
            <CbButton size="medium" styleName="cancel" onClick={close}>
              Cancel
            </CbButton>

            <CbButton size="medium" styleName="ctaButton" type="submit">
              Submit
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    </DialogContent>
  );
}

export default ReferToUWModal;

export const ReferToUWModalConfig = {
  ReferToUWModal: {
    component: ReferToUWModal,
    config: {
      title: 'Refer to Underwriter',
      maxWidth: 'sm',
    },
  },
};

type FormValues = {
  referralReason: string;
};

const defaultFormValues = {
  referralReason: '',
};

const formSchema = Yup.object().shape({
  referralReason: Yup.string().required().label('Referral Reason'),
});

const FormContentContainer = styled(Box)({
  padding: '1rem 2rem',
});

const ControlledTextField = withFormController(TextField);
