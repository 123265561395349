import { Box, Divider } from '@mui/material';
import { Skeleton } from '@mui/lab';

function BindQuoteWorkflowSkeleton() {
  return (
    <>
      <Box marginBottom="1rem" data-testid="bind-quote-workflow-skeleton">
        <Skeleton width="30rem" height="5.5rem" />
        <Skeleton
          width="15rem"
          height="3.5rem"
          style={{ marginTop: '-1rem' }}
        />
        <Divider />
      </Box>

      <Box height="30rem" display="flex" justifyContent="flex-end">
        <Skeleton
          width="23rem"
          height="100%"
          variant="rectangular"
          style={{ borderRadius: '8px' }}
        />
      </Box>
    </>
  );
}

export default BindQuoteWorkflowSkeleton;
