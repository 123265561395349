import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store, { persistor } from '../config/storeConfig';
import { QueryParamsProvider } from '../components/providers/QueryParamsProvider';
import GrowthBookProvider from './GrowthBookProvider';

import AmplitudeProvider from './AmplitudeProvider';
import QueryClientProvider from './QueryClientProvider';
import SnackbarProvider from './SnackbarProvider';
import ThemeProvider from './ThemeProvider';

import CSVDownloadSubWrapper from './CSVDownloadSubWrapper';

import type { WithChildrenProps } from '../types/components/common';

function GlobalProviders({ children }: WithChildrenProps) {
  return (
    <QueryClientProvider>
      <ReduxProvider store={store as any}>
        <PersistGate persistor={persistor}>
          <GrowthBookProvider>
            <BrowserRouter>
              <AmplitudeProvider>
                <ThemeProvider>
                  <SnackbarProvider>
                    <QueryParamsProvider>
                      <CSVDownloadSubWrapper>{children}</CSVDownloadSubWrapper>
                    </QueryParamsProvider>
                  </SnackbarProvider>
                </ThemeProvider>
              </AmplitudeProvider>
            </BrowserRouter>
          </GrowthBookProvider>
        </PersistGate>
      </ReduxProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default GlobalProviders;
