import Moment from 'moment';
import { applyQueryConfig, ServiceHandler } from '../utils';
import {
  standardParamSerializer,
  standardQuerySerializer,
} from './serializers';
import { cmailAccountsParamsSerializer } from './cmail/cmail-accounts.params-serializer';

export const transformMessage = (message = {}) => {
  return {
    ...message,
    ui_dateReceivedShort: Moment.utc(message.dateReceived).format('MMM D'),
    ui_dateReceivedLong: `${Moment.utc(message.dateReceived).format(
      'LLLL'
    )} - (${Moment.utc(message.dateReceived).fromNow()})`,
    seenBy: message.seenBy ?? [],
    isArchived: message.isArchived ?? false,
    isStarred: message.isStarred ?? false,
    labels: message.labels ?? [],
  };
};

export const getTestMessage = ServiceHandler({
  url: '/api/docmessage/v1/message?msgId=CH2PR12MB48739B5211D061AF32E47D65FA059%40CH2PR12MB4873.namprd12.prod.outlook.com',
  transformResponse: [JSON.parse, transformMessage],
});

export const getAllMessages = ServiceHandler({
  url: '/api/docmessage/v1/search',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(standardParamSerializer, params)),
  transformResponse: [JSON.parse],
});

export const updateCmailMessage = ServiceHandler({
  url: '/api/docmessage/v1/message',
  method: 'PUT',
});

export const getMessagesByAccountIdAndAccountName = ServiceHandler({
  url: '/api/docmessage/v1/searchForAccount',
  paramsSerializer: (params) =>
    standardQuerySerializer(
      applyQueryConfig(cmailAccountsParamsSerializer, params)
    ),
});
