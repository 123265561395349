import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { CustomTypeahead } from './CustomTypeahead';
import { useAgencyDashboardSearchData } from '../hooks/useAgencyDashboardSearchData';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import {
  useAmplitude as useAnalytics,
  AMPLITUDE_EVENTS as ANALYTICS_EVENTS,
} from '../../../../providers/AmplitudeProvider';
import AgencyDashboardSearchResults from './AgencyDashboardSearchResults';
import { selectGlobalTeam } from '../../../../reducers/team.reducer';
import { useCowbellTranslations } from '../../../../i18n/translations';
import type { AccountDto } from '../../../../types';

export const AgencyDashboardSearch = () => {
  const analytics = useAnalytics();
  const theme = useTheme<any>();
  const team = useSelector(selectGlobalTeam);
  const { searchTerm, onSearchTermChange } = useAgencyDashboardState();
  const [userInput, setUserInput] = React.useState(searchTerm);
  const { t, translationKeys } = useCowbellTranslations();

  const currentTeamQueryCache = useAgencyDashboardSearchData(
    userInput,
    team.id,
    false
  );
  const allTeamsQueryCache = useAgencyDashboardSearchData(
    userInput,
    'all',
    false
  );
  const myAgencyQueryCache = useAgencyDashboardSearchData(
    userInput,
    'all',
    true
  );

  const onChange = (value: string) => {
    setUserInput(value);
  };

  const onEnterKey = () => {
    onSearchTermChange(userInput);
  };

  const onClearInput = () => {
    setUserInput('');
    onSearchTermChange('');
  };

  const onSelect = (account: AccountDto) => {
    analytics.track(ANALYTICS_EVENTS.search);
    onSearchTermChange(account);
  };

  const baseStyles = {
    padding: '0.5rem',
    backgroundColor: theme.agencyDash.background.contrast,
  };

  const focusedStyles = {
    ...baseStyles,
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.3)',
  };

  return (
    <Box m="1rem">
      <CustomTypeahead
        onChange={onChange}
        onEnterKey={onEnterKey}
        onClearInput={onClearInput}
        defaultValue={userInput}
        style={userInput ? focusedStyles : baseStyles}
        placeholder={`Search quotes, binders, policies, and clients within your ${t(
          translationKeys.agency
        ).toLowerCase()}`}
      >
        {(typeaheadProps) => (
          <AgencyDashboardSearchResults
            {...typeaheadProps}
            onSelect={onSelect}
            currentTeamQueryCache={currentTeamQueryCache}
            allTeamsQueryCache={allTeamsQueryCache}
            myAgencyQueryCache={myAgencyQueryCache}
            searchTerm={userInput}
          />
        )}
      </CustomTypeahead>
    </Box>
  );
};
