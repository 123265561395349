const AWS = 'AWS';
const CLOUDFLARE = 'CLOUDFLARE';
const GOOGLEWORKSPACE = 'GOOGLEWORKSPACE';
const GSC = 'GSC';
const MICROSOFT = 'MICROSOFT';
const QUALYSCS = 'QUALYSCS';
const QUALYSPC = 'QUALYSPC';
const QUALYSVM = 'QUALYSVM';
const SAFEGUARD = 'SAFEGUARD';
const SECUREWORKS = 'SECUREWORKS';
const SECURITYSTUDIO = 'SECURITYSTUDIO';
const SOPHOS = 'SOPHOS';
const WIZER = 'WIZER';

export const CONNECTOR_TYPES = {
  AWS,
  CLOUDFLARE,
  GOOGLEWORKSPACE,
  GSC,
  MICROSOFT,
  QUALYSCS,
  QUALYSPC,
  QUALYSVM,
  SAFEGUARD,
  SECUREWORKS,
  SECURITYSTUDIO,
  SOPHOS,
  WIZER,
} as const;

export type ConnectorType =
  (typeof CONNECTOR_TYPES)[keyof typeof CONNECTOR_TYPES];
