import React from 'react';

const SVG = ({
  id = 'manage',
  style = {},
  fill = '#9192a4',
  width = '100%',
  className = '',
  viewBox = '0 0 30 30',
  title = 'Manage',
}) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .svg-manage-1 { fill: none; }
          .${id}.svg-fill-manage { fill: ${fill}; }
        `}
      </style>
    </defs>
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Isolation_Mode" data-name="Isolation Mode">
        <rect className="svg-manage-1" width="30" height="30" />
        <path
          className={`${id} svg-fill-manage`}
          d="M25.49,17.05l-1.86-1.4c0-.22,0-.43,0-.62a5,5,0,0,0,0-.67L25.5,13a1.32,1.32,0,0,0,.32-1.7L23.75,7.77a1.34,1.34,0,0,0-1.6-.61L19.94,8a7.53,7.53,0,0,0-1.17-.67l-.35-2.29a1.34,1.34,0,0,0-1.3-1H12.93A1.32,1.32,0,0,0,11.6,5.14l-.27,2.24A8.88,8.88,0,0,0,10.17,8L8,7.18a1.36,1.36,0,0,0-1.6.55L4.2,11.21a1.32,1.32,0,0,0,.31,1.71l1.86,1.4c0,.21,0,.42,0,.62a5.17,5.17,0,0,0,0,.68L4.48,17a1.29,1.29,0,0,0-.28,1.69l2.1,3.57a1.36,1.36,0,0,0,1.61.53L10.12,22a7.92,7.92,0,0,0,1.16.67L11.62,25a1.33,1.33,0,0,0,1.29,1h4.22a1.32,1.32,0,0,0,1.3-1.12l.33-2.26A8.88,8.88,0,0,0,19.92,22l2.21.85a1.32,1.32,0,0,0,1.61-.56l2.08-3.5A1.32,1.32,0,0,0,25.49,17.05Zm-3,4.2-2.81-1.08-.35.26a7.43,7.43,0,0,1-1.6.91l-.41.17-.43,2.91H13.14l-.41-2.9-.43-.17a6.93,6.93,0,0,1-1.59-.9l-.35-.27L7.54,21.27,5.68,18.11l2.39-1.8L8,15.83A4,4,0,0,1,7.92,15,6.54,6.54,0,0,1,8,14.09l.08-.47-2.39-1.8,1.9-3.08,2.82,1.09.35-.27a7,7,0,0,1,1.6-.9l.43-.18.35-2.89H16.9l.44,2.87.42.17a6.66,6.66,0,0,1,1.58.9l.36.27,2.78-1.08,1.85,3.13-2.39,1.8.07.47a5.26,5.26,0,0,1,0,.9,5.2,5.2,0,0,1,0,.88l-.07.46,2.39,1.8Z"
        />
        <path
          className={`${id} svg-fill-manage`}
          d="M15,11.27A3.78,3.78,0,0,0,11.27,15a3.65,3.65,0,0,0,2.36,3.41,3.82,3.82,0,0,0,1.42.28,3.87,3.87,0,0,0,2.7-1.09,3.65,3.65,0,0,0,.79-4.07A3.79,3.79,0,0,0,15,11.27Z"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
