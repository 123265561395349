import { commonDateParamMappings } from '../common/mappings';

export const claimsReservesServiceMappings = [
  ...commonDateParamMappings,
  { path: 'page' },
  { path: 'size' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: { orderBy: string; order: string }) => {
      if (orderBy && order) {
        switch (orderBy) {
          case 'created':
          case 'id':
          case 'firstPartyExpenseReserveChange':
          case 'firstPartyLossReserveChange':
          case 'lossAdjustmentReserveChange':
          case 'thirdPartyLossReserveChange':
          case 'totalReserveChange':
            return `${orderBy},${order}`;
          default:
            return `${orderBy}.keyword,${order}`;
        }
      }
      return null;
    },
  },
  { path: 'searchAfter' },
  { path: 'pageRequested' },
  { path: 'claimId' },
];
