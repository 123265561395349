import React from 'react';
// ui
// components
import { FormContext, useForm } from 'react-hook-form-4';
import _ from 'lodash';
import { Box, DialogActions, Typography } from '@mui/material';
import CBButton from '../../../../../components/Buttons/CbButton';
import { toggleModalDirect } from '../../../../../utils/storeUtils';
import { release250Quote } from '../../../../../api/p250.service';

export const UWReleaseQuoteConfirmation = ({ data, ...props }) => {
  const { classes } = data;
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const onSubmit = (formData) => {
    const { payload, enqueueSnackbar, history } = data;

    return release250Quote({}, payload)
      .then(() => {
        enqueueSnackbar('Quote released', { variant: 'success' });
        toggleModalDirect('UWReleaseQuoteConfirmation', false);
        setTimeout(() => {
          history.push('/admin/inbox');
        }, 2000);
      })
      .catch((error) => {
        const msg = _.get(error, 'response.data[0]', 'Failed to release quote');
        enqueueSnackbar(msg, { variant: 'error' });
        toggleModalDirect('UWReleaseQuoteConfirmation', false);
      });
  };

  const handleCancel = () => {
    toggleModalDirect('UWReleaseQuoteConfirmation', false);
  };

  return (
    <section>
      <FormContext>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            mb={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            {data?.spotlightCount && (
              <>
                <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
                  {`${data.spotlightCount} Spotlight(s) on this account`}
                </Typography>
                <Typography>
                  This affects the account's risk posture and Cowbell Factors.
                </Typography>
              </>
            )}

            <Box my={3}>
              <Typography>
                Are you sure you want to release the terms?
              </Typography>
              <Typography>
                This will update the quote and change the status to ‘Ready’.
              </Typography>
            </Box>
          </Box>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Release"
            />
          </DialogActions>
        </form>
      </FormContext>
    </section>
  );
};
