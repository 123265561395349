import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { FormContext, useForm } from 'react-hook-form-4';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// mui
import { DialogContent, Grid as MuiGrid, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { TextFieldBase } from '../inputs/TextFieldBase';
import { SimpleSelect } from '../inputs';
import CBButton from '../Buttons/CbButton';
import GrayAWSLogo from '../../_assets/svg/GrayAWSLogo.svg';

// utils
import { withFormController } from '../hocs/withFormController';
import { PubSub } from '../../utils/eventUtils';

// apis
import {
  getAWSregions,
  connectAWS,
} from '../../policyholders/connectors/ConnectorService';

const TextField = withFormController(TextFieldBase);
const Select = withFormController(SimpleSelect);

const schema = Yup.object().shape({
  accountId: Yup.string().required().label('Account ID'),
  region: Yup.string().required().label('region'),
  accessId: Yup.string().required().label('Access Key ID'),
  secret: Yup.string().required().label('Secret Key ID'),
});

export const AWSConnector = ({ ...props }) => {
  const classes = useClasses();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, ...methods } = useForm({ validationSchema: schema });
  const [options, setOptions] = React.useState([]);

  const {
    formState: { isSubmitting },
  } = methods;

  const customer = useSelector(({ account }) => account);

  React.useEffect(() => {
    getAWSregions()
      .then((response) => {
        const regions = Object.keys(response.data);
        const massagedValue = [];
        // eslint-disable-next-line array-callback-return
        const data = regions.map((region) => {
          massagedValue.push({
            label: region,
            value: region.replaceAll('_', '-'),
          });
        });
        setOptions(massagedValue);
      })
      .catch((error) => {
        const message = _.get(
          error.response,
          'data.message',
          'Cannot find AWS Regions, please try again later'
        );
        enqueueSnackbar(message, { variant: 'error' });
      });
    // eslint-disable-next-line
  }, []);

  const onSubmit = (formData) => {
    return connectAWS({ cbid: parseInt(customer.cbid) }, { ...formData })
      .then((response) => {
        enqueueSnackbar('AWS account created successfully!', {
          variant: 'success',
        });
        PubSub.publish('connector-page-refetch');
        props.close();
      })
      .catch((error) => {
        const message = _.get(
          error.response,
          'data.message',
          'Failed to add the connector'
        );

        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent classes={{ root: classes.container }}>
          <MuiGrid>
            <p className={classes.description}>
              Please enter your AWS authentication information to activate this
              connector.
            </p>
          </MuiGrid>
          <MuiGrid container spacing={2} justifyContent="center">
            <MuiGrid item md={6}>
              <TextField
                name="accountId"
                placeholder="Please enter account ID"
                label="Account ID"
                fullWidth
                required
                data-qa="accountId"
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <Select
                label="Region"
                required
                name="region"
                fullWidth
                displayEmpty
                renderValue={renderValue}
                InputLabelProps={{ className: classes.selectLabel }}
                options={options}
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={2}>
            <MuiGrid item md={6}>
              <TextField
                name="accessId"
                type="password"
                placeholder="Please enter your access key ID"
                label="Access Key ID"
                fullWidth
                required
                data-qa="accessId"
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <TextField
                name="secret"
                type="password"
                placeholder="Please enter your secret access key"
                label="Secret Access Key"
                fullWidth
                required
                data-qa="secret"
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <DialogActions>
          <CBButton
            type="submit"
            styleName="cancel"
            buttonText="Cancel"
            onClick={() => props.close()}
          />
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="Next"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

function renderValue(value) {
  return value || 'Please select your region';
}

const useClasses = makeStyles(({ palette, config }) => ({
  selectLabel: {
    paddingTop: '5px',
  },
  email: {
    color: `${palette.email.primary} !important`,
    fontSize: 12,
    marginLeft: '0.75rem',
  },
  container: {
    overflow: 'auto',
    paddingBottom: '1rem',
    color: palette.primary.contrastText,
  },
  description: {
    color: palette.primary.contrastText,
    fontWeight: config.weights.bold,
    lineHeight: 1.17,
    textAlign: 'left',
    fontSize: '1.33333rem',
  },
  header: {
    fontSize: '1.6667rem',
    lineHeight: 0.9,
    textAlign: 'left',
    margin: '2rem 0 0 0',
    color: palette.primary.contrastText,
  },
}));

export const AWSConnectorConfig = {
  AWSConnector: {
    component: AWSConnector,
    config: {
      override: false,
      maxWidth: 'md',
      minWidth: 'sm',
      title: 'Amazon Web Services Authentication',
      icon: <GrayAWSLogo />,
    },
  },
};
