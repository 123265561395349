import React from 'react';
import jwtdecode from 'jwt-decode';

// utils
import { retrieveAuthTokens } from '../../utils/next/auth.utils';

// other
import type { TOKEN_REALMS } from '../../console/_statics/users/roles.statics';
import type { Persona } from '../../console/_statics/persona.statics';
import { PERSONA_TYPES } from '../../console/_statics/persona.statics';

export type TokenPayload = {
  exp: number;
  at: Persona;
  st: Persona;
  tt: string;
  rlm: (typeof TOKEN_REALMS)[keyof typeof TOKEN_REALMS];
};

/**
 * @name useAuth
 * @description consolidates decoding of auth token for components
 * @returns Details about the token used to authenticate
 */
export function useAuth(getTokenCallback = retrieveAuthTokens) {
  if (typeof getTokenCallback !== 'function') {
    throw new Error(
      '"getTokenCallback" is not a function please provide a valid function to "useAuth" to retrieve tokens'
    );
  }

  const { accessToken } = getTokenCallback();

  const tokenPayload = React.useMemo<TokenPayload | null>(() => {
    if (accessToken && typeof accessToken === 'string') {
      return jwtdecode(accessToken as string);
    }

    return null;
  }, [accessToken]);

  const isAuthenticated = React.useMemo(
    () =>
      tokenPayload !== null
        ? tokenPayload.exp * 1000 > new Date().valueOf()
        : false,
    [tokenPayload]
  );

  // type of account the user IS IN, e.g. COWBELL user in CUSTOMER account
  const accountType = React.useMemo<Persona | null>(
    () => (tokenPayload !== null ? tokenPayload.at : null),
    [tokenPayload]
  );

  // type of account the user IS, e.g. COWBELL
  const subscriberType = React.useMemo(
    () => (tokenPayload !== null ? tokenPayload.st : null),
    [tokenPayload]
  );

  // type of token being used, mostly for determining if user is authenticated when outside the console (e.g. token type FLOW)
  const tokenType = React.useMemo(
    () => (tokenPayload !== null ? tokenPayload.tt : null),
    [tokenPayload]
  );

  // persona base url path for initial resolving
  const accountBasePath = React.useMemo(
    () =>
      accountType !== null
        ? PERSONA_TYPE_TO_BASE_PATH_MAPPING[accountType]
        : null,
    [accountType]
  );

  const isGhosting = React.useMemo(
    () => accountType !== subscriberType,
    [accountType, subscriberType]
  );

  const isCowbellUserJumped =
    subscriberType == PERSONA_TYPES.COWBELL && accountType != subscriberType;

  const realm = tokenPayload !== null ? tokenPayload.rlm : null;

  return {
    isAuthenticated,
    accountBasePath,
    accountType,
    subscriberType,
    isGhosting,
    isCowbellUserJumped,
    tokenType,
    tokenScope: realm,
    tokenRealm: realm,
  };
}

export const PERSONA_TYPE_TO_BASE_PATH_MAPPING = {
  COWBELL: 'admin',
  DIGITAL: 'da',
  MSSP: 'mssp',
  CARRIER: 'carrier',
  VENDOR: 'vendor',
  CLUSTER: 'cluster',
  AGENCY: 'agency',
  CUSTOMER: 'customer',
  UNKNOWN: 'unknown',
};
