import { createAction } from 'redux-actions';

// action types
export const REHYDRATE_FROM_PERSONA = 'REHYDRATE_FROM_PERSONA';
export const TOGGLE_FEATURE_FLAGS = 'TOGGLE_FEATURE_FLAGS';
export const PLATFORM_LANGUAGE_UPDATED = 'PLATFORM_LANGUAGE_UPDATED';

// actions
export const reyhdrateFromPersona = createAction(REHYDRATE_FROM_PERSONA);
export const toggleFeatureFlags = createAction(TOGGLE_FEATURE_FLAGS);
export const setPlatformLanguage = createAction(PLATFORM_LANGUAGE_UPDATED);
