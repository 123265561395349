import React from 'react';
import { useHistory } from 'react-router-dom';
// helpers
import _ from 'lodash';
import Moment from 'moment';
import { useSnackbar } from 'notistack';
// mui
import { IconButton as MuiIconButton } from '@mui/material';
// components
import { ProtectedListItem } from '../../components/Menus/ListItemBase';
import { SimpleMenu } from '../../components/Menus/SimpleMenu';
import { ProtectedMenuItem } from '../../console/agencies/inbox/table/ProtectedMenuItem';
import { SubMenu } from '../../components/Menus/SubMenu';
import { CommonPolicyOptions } from './CommonPolicyOptions';
// utils
import { loadOneIncByPolicyNumber } from '../../utils/appUtils';
// icons
import TableMenuIcon from '../../_assets/svg/TableMenuIcon.svg';
// actions
import {
  adminPaymentComplete,
  getBalanceOnPolicy,
} from '../../console/customers/_services/BillingService';
// hooks
import { useAccountAttestation } from '../../accounts/attestation/useAccountAttestation';
import { determineRenewalRoute } from '../../accounts/accountUtils';
import { PolicyStatus } from '../../console/_statics/policy.statics';
import { toUniversalUtcDate } from '../../utils/date.utils';
import { MidTermEndorsementOption } from './MidtermEndorsementOption';

export const AgencyPolicyOptions = ({
  data,
  handleTableRefetch,
  setShowCircular,
  isJQueryLoaded,
}) => {
  const { handleRenewals, handleMidtermEndorsement, handleCharge } =
    useHandlers(data, handleTableRefetch, setShowCircular);

  const { isPaid, totalPremium, isPrimePlus, isOpenForRenewal } = data;

  const isActive = [
    PolicyStatus.IN_FORCE_STATUS,
    PolicyStatus.ISSUED_STATUS,
    PolicyStatus.BOUND_STATUS,
  ].includes(data.status);

  return (
    <SimpleMenu
      id="agency-policy-options"
      anchor={
        <MuiIconButton size="small" aria-haspopup="true">
          <TableMenuIcon fontSize="small" />
        </MuiIconButton>
      }
    >
      <CommonPolicyOptions data={data} />

      <ProtectedMenuItem
        allow={{ policy: 'manage' }}
        onClick={handleRenewals}
        show={isOpenForRenewal && !isPrimePlus}
      >
        Generate Renewal Quote
      </ProtectedMenuItem>

      <MidTermEndorsementOption
        policy={data}
        onClick={handleMidtermEndorsement}
      >
        Midterm Endorsement
      </MidTermEndorsementOption>

      <SubMenu closeMenu header="Payments" show={isActive && !isPaid}>
        <ProtectedListItem
          allowIfAll={[{ role: 'admin' }, { account: 'agency' }]}
          show={totalPremium > 0}
          onClick={() => handleCharge('bankAccount')}
          disabled={!isJQueryLoaded}
          name="Charge Bank Account"
        />

        <ProtectedListItem
          allowIfAll={[{ role: 'admin' }, { account: 'agency' }]}
          show={totalPremium > 0}
          onClick={() => handleCharge('creditCard')}
          disabled={!isJQueryLoaded}
          name="Charge Credit Card"
        />
      </SubMenu>
    </SimpleMenu>
  );
};

export const useHandlers = (data, handleTableRefetch, setShowCircular) => {
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const policyNumber = _.get(data, 'policyNumber');
  const product = _.get(data, 'product');
  const accountId = _.get(data, 'accountId');

  const handleNewBusinessRedirect = () => {
    enqueueSnackbar(
      'Policy cannot be renewed at this time. Please contact support@cowbellcyber.ai',
      { variant: 'error' }
    );
  };

  const handleRenewalRedirect = (apiData) => {
    const renewalroute = determineRenewalRoute({ ...apiData, accountId });
    if (renewalroute === null) {
      enqueueSnackbar(
        'Policy cannot be renewed at this time. Please contact support@cowbellcyber.ai',
        { variant: 'error' }
      );
    } else {
      push(renewalroute);
    }
  };

  const { handleCheckStatusOnAccount } = useAccountAttestation({
    account: { id: accountId },
    onRenewalRedirect: handleRenewalRedirect,
    onNewBusinessRedirect: handleNewBusinessRedirect,
  });

  const handleRenewals = () => {
    handleCheckStatusOnAccount();
  };

  const handleMidtermEndorsement = () => {
    push(`/agency/policies/${policyNumber}/endorsement/${product}`);
  };

  const handleCharge = (type) => {
    loadOneIncByPolicyNumber(_.get(data, 'policyNumber', ''))
      .then((getOneIncKeyResp) => {
        setShowCircular(true);
        const paymentCategory = type === 'creditCard' ? 'CreditCard' : 'ECheck';

        getBalanceOnPolicy({ policyId: data.id })
          .then((getBalanceOnPolicyResp) => {
            const balance = getBalanceOnPolicyResp.data;

            window.$('#portalOneContainer').on('portalOne.load', function () {
              setShowCircular(false);
            });

            window.$('#portalOneContainer').on('portalOne.unload', function () {
              handleTableRefetch();
            });

            const paymentCompleteHandler = function (e, d) {
              if (d.acknowledge) {
                d.acknowledge();
              }

              const payload = {
                transactionId: d.transactionId,
                transactionDate: Moment(d.transactionDate).valueOf(),
                paymentAmount: d.paymentAmount,
                totalPaymentAmount: d.totalPaymentAmount,
                policyId: data.id,
                policyNumber: data.policyNumber,
                lastFourDigits: _.get(d, 'transactions[0].lastFourDigits', ''),
                customerEmail: data.customerEmail,
                quoteId: data.quoteId,
                agencyId: data.agencyId,
                policyCreated: data.created,
                bankName: d.bankName || '',
                cardType: d.cardType || '',
              };

              adminPaymentComplete({ accountId }, payload)
                .then(() => {})
                .catch(() => {
                  enqueueSnackbar('Failed to pay, please try again later', {
                    variant: 'error',
                  });
                });
            };

            window
              .$('#portalOneContainer')
              .on('portalOne.paymentComplete', paymentCompleteHandler);

            window.$('#portalOneContainer').portalOne();
            const xhttp = new XMLHttpRequest();
            xhttp.overrideMimeType('application/json');
            xhttp.onreadystatechange = function () {
              if (xhttp.readyState === 4 && xhttp.status === 200) {
                const resp = JSON.parse(xhttp.responseText);
                const sessionID = resp.PortalOneSessionKey;
                window
                  .$('#portalOneContainer')
                  .data('portalOne')
                  .makePayment({
                    paymentCategory,
                    feeContext: 'PaymentWithFee',
                    amountContext: 'AmountDue',
                    minAmountDue: balance,
                    accountBalance: '0',
                    accountGroupCode: getOneIncKeyResp.data.authCode,
                    billingZip: '',
                    billingAddressStreet:
                      '6800 Koll Center Pkwy. Suite 250, Pleasanton, CA',
                    policyHolderName: `${data.customerFirstName} ${data.customerLastName}`,
                    referenceNumber: '',
                    saveOption: 'DoNotSave',
                    clientReferenceData1: data.policyNumber,
                    clientReferenceData2: toUniversalUtcDate(
                      Moment(data.created).add(7, 'days')
                    ),
                    clientReferenceData3: 'false',
                    clientReferenceData4: data.id,
                    sessionId: sessionID,
                    displayMode: 'modal',
                    confirmationDisplay: 'true',
                  });
              }
            };

            xhttp.open(
              'GET',
              `${getOneIncKeyResp.data.url}/Api/Api/Session/Create?portalOneAuthenticationKey=${getOneIncKeyResp.data.key}`,
              true
            );
            xhttp.send();
          })
          .catch(() => {
            setShowCircular(false);
            enqueueSnackbar('Something went wrong.Please try again later', {
              variant: 'error',
            });
          });
      })
      .catch(() => {
        setShowCircular(false);
        enqueueSnackbar('Something went wrong.Please try again later', {
          variant: 'error',
        });
      });
  };

  return {
    handleRenewals,
    handleMidtermEndorsement,
    handleCharge,
  };
};

export default AgencyPolicyOptions;
