import { Api } from '../config/axiosConfig';
import { ServiceHandlerFactory } from '../utils/serviceUtils';
import { ServiceHandler } from '../utils';
import { activityTrackingServiceMappings } from './activityTrackingServiceMappings';

export function refreshAccessToken(refreshToken) {
  return Api.post('/api/auth/v1/access', {
    refreshToken,
  });
}

export function getAgencyLogoSmall(agencyId) {
  return Api.get(`/api/agency/v1/logo/s/${agencyId}`);
}

export function getAgencyLogoMedium(agencyId) {
  return Api.get(`/api/agency/v1/logo/m/${agencyId}`);
}

export function postAgencyLogo(agencyId, isSmall, payload) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return Api.post(
    `/api/agency/v1/logo/initial/${agencyId}?isSmall=${isSmall}`,
    payload,
    config
  );
}

export function uploadAgencyLogoSmall(req, payload) {
  return Api.post(
    `/api/agency/v1/logo?xCoordinate=${req.xCoordinate}&yCoordinate=${req.yCoordinate}`,
    payload
  );
}

export function delAgencyLogoSmall(agencyId) {
  return Api.delete(`/api/agency/v1/logo/s/${agencyId}`);
}

export function delAgencyLogoMedium(agencyId) {
  return Api.delete(`/api/agency/v1/logo/m/${agencyId}`);
}

export const checkSSO = ServiceHandlerFactory({
  axios: {
    // url: '/api/auth/v1/sso/status', commentint it until we have SSO integration
  },
  isCancelable: true,
});

export const postSSOToken = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/sso/token',
    method: 'post',
  },
  isCancelable: true,
});

export function getQRCode(type) {
  return Api.put(`/api/auth/v1/mfa/update?mfaType=${type}`);
}

export function verifyQR(payload) {
  return Api.post(`/api/auth/v1/mfa/verify`, payload);
}

export function resetMfa(payload) {
  return Api.post(`/api/auth/v1/mfa/reset`, payload);
}

export function confirmResetMfa(resetMFAToken) {
  return Api.post(`/api/auth/v1/validateMFAReset/${resetMFAToken}`);
}

export const resetPasswordByEmail = ServiceHandler({
  url: '/api/auth/v1/passwords/:email/reset',
  method: 'PUT',
});

export function sendMfaCode(type) {
  return Api.put(`/api/auth/v1/mfa/update?mfaType=${type}`);
}

export function verifyMfaCode(payload) {
  return Api.post(`/api/auth/v1/mfa/verify`, payload);
}

export function getEnvironment() {
  return Api.get(`/api/auth/v1/env`);
}
export function sendCodeToRetail(workflowId) {
  return Api.get(`/api/auth/v1/wf/access/${workflowId}`);
}

export function getRetailAccessToken(payload) {
  return Api.post(`/api/auth/v1/wf/token`, payload);
}

export function getRedirectUrl(workflowId) {
  return Api.get(`/api/auth/v1/wf/${workflowId}`);
}

export function resendPhoneCode(workflowId) {
  return Api.get(`/api/auth/v1/wf/access/phone/${workflowId}`);
}

export function getSiteKey() {
  return Api.get(`/api/auth/v1/login/sitekey`);
}
export const getApiKeys = ServiceHandlerFactory({
  axios: {
    url: `api/auth/v1/api`,
  },
  isCancelable: false,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        return orderBy ? `${orderBy},${order}` : null;
      },
    },
  ],
});

export const createApiKey = ServiceHandlerFactory({
  axios: {
    url: 'api/auth/v1/api',
    method: 'post',
  },
});

export const updateApiKey = ServiceHandlerFactory({
  axios: {
    url: 'api/auth/v1/api/:keyId',
    method: 'put',
  },
});

export const fetchActivityTracking = ServiceHandlerFactory({
  axios: {
    url: '/api/apiactivity/v1/search',
  },
  isCancelable: true,
  paramSchema: activityTrackingServiceMappings,
});

export const fetchActivityGraph = ServiceHandlerFactory({
  axios: {
    url: '/api/apiactivity/v1/graph',
  },
  isCancelable: true,
  paramSchema: activityTrackingServiceMappings,
});

export const fetchActivityStats = ServiceHandlerFactory({
  axios: {
    url: '/api/apiactivity/v1/agg/stats',
    isCancelable: true,
    paramSchema: activityTrackingServiceMappings,
  },
});

export const regenerateSecret = ServiceHandlerFactory({
  axios: {
    url: 'api/auth/v1/api/regenerate/:keyId',
    method: 'put',
  },
});

export const deleteApiKey = ServiceHandlerFactory({
  axios: {
    url: 'api/auth/v1/api/:keyId',
    method: 'delete',
  },
});

export const getPermissions = ServiceHandlerFactory({
  axios: {
    url: `/api/auth/v1/permissions/map`,
  },
  isCancelable: true,
});
