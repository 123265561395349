import { PubSubModule } from '../../utils/eventUtils';

export const NotesEvents = new PubSubModule();

export const Note = {
  /**
   * @name show
   * @description immediately show a note
   * @param type: type of the note
   * @param config: static configuration of the note
   */
  show(type, config) {
    NotesEvents.publish('note:toggle', {
      type,
      open: true,
      ...config,
    });
  },

  /**
   * @name show
   * @description hide the note
   * @param type: type of the note
   */
  hide(type) {
    NotesEvents.publish('note:toggle', {
      type,
      open: false,
    });
  },

  /**
   * @name deferShow
   * @description defer the showing of a note until later, e.g. onClick handler is invoked
   * @param type: type of the note
   * @param config: static configuration of the note
   */
  deferShow(type, config) {
    return () => {
      this.show(type, config);
    };
  },
};
