import React, { useState } from 'react';
import { useForm } from 'react-hook-form-4';
import _ from 'lodash';
import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { useSnackbar } from 'notistack';
import CbButton from '../../Buttons/CbButton';
import { removeAgency } from '../../../admin/cluster/cluster.service';

export const ClusterAgencyRemoveConfirm = ({ data, ...props }) => {
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState('');
  const { makeRefreshCall, isCluster } = data;

  const onSubmit = () => {
    removeAgency(data.id, isCluster)
      .then(() => {
        enqueueSnackbar('Agency Removed successfully!', { variant: 'success' });
        props.close();
        makeRefreshCall();
      })
      .catch((error) => {
        setError(_.get(error.response, 'data', 'Something went wrong.'));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent className="paf">
        <div className="modal-title">
          Are you sure you want to remove the agency
          <span className="modal-blue-text"> {data.name}</span>?
        </div>
        {error && (
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={props.close}>
          Cancel
        </CbButton>
        <CbButton
          loading={isSubmitting}
          styleName="ctaButton"
          buttonText="Remove Agency"
          type="submit"
        />
      </DialogActions>
    </form>
  );
};

export const ClusterAgencyRemoveConfirmConfig = {
  ClusterAgencyRemoveConfirm: {
    component: ClusterAgencyRemoveConfirm,
    config: {
      title: 'Remove Agency',
      fullWidth: true,
    },
  },
};
