import React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  Radio as RadioBase,
  styled,
  useTheme,
} from '@mui/material';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import CreateIconBase from '@mui/icons-material/Create';
import CheckCircleOutlineIconBase from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIconBase from '@mui/icons-material/HighlightOff';
import { iconMappings } from '../../../console/_global/navigation/IconsMappings';
import CbButton from '../../Buttons/CbButton';
import {
  searchRiskEngQuestionTemplates,
  updateRiskEngQuestionTemplate,
  deleteRiskEngQuestionTemplate,
} from '../../../api/riskEng.api';

import { DeletionDialog } from '../../../console/risk-eng/forms/admin/CowbellRiskEngQuestionContainers';
import { TextAreaBase } from '../../inputs/TextAreaBase';
import { SearchField as SearchFieldBase } from '../../inputs/SearchField';
import { Modal } from './helpers/v2.modal.helpers';

export const RiskEngLoadTemplateModal = ({ iconStyles, data, ...props }) => {
  const [search, setSearch] = React.useState('');
  const [selectedTemplate, setSelectedTemplate] = React.useState('');

  const { palette } = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: templates, isLoading } = useQuery(
    ['risk-eng-template-search', search],
    () => {
      return searchRiskEngQuestionTemplates({
        params: {
          search,
          size: 100,
        },
      }).then(({ data: { searchHits } }) =>
        searchHits.map(({ source }) => source)
      );
    },
    {
      refetchOnWindowFocus: false,
      placeholderData: [],
    }
  );

  const { mutate: onDeleteTemplate } = useOptimisticMutation(
    ['risk-eng-template-search', search],
    (templateId) => {
      return deleteRiskEngQuestionTemplate(templateId);
    },
    {
      syncOptimisticCacheMutation: (deletedTemplateId) =>
        templates.filter((template) => template.id !== deletedTemplateId),
      onError: () => {
        enqueueSnackbar('Failed to delete template', { variant: 'error' });
      },
      onSuccess: () =>
        enqueueSnackbar('Template deleted successfully', {
          variant: 'success',
        }),
    }
  );

  const { mutate: onUpdateTemplate } = useOptimisticMutation(
    ['risk-eng-template-search'],
    ({ templateId, ...updateFields }) => {
      return updateRiskEngQuestionTemplate(templateId, {
        data: {
          ...updateFields,
        },
      });
    },
    {
      syncOptimisticCacheMutation: ({ templateId, updateFields }) => {
        const updatedTemplates = [...templates];
        const index = updatedTemplates.findIndex(
          (template) => template.id === templateId
        );

        if (index !== -1) {
          updatedTemplates[index] = { ...templates[index], ...updateFields };
          return updatedTemplates;
        }

        return templates;
      },
      onError: () => {
        enqueueSnackbar('Failed to update template', { variant: 'error' });
      },
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(['risk-eng-template-search']);
        }, 1000);
        enqueueSnackbar('Template update successfully', {
          variant: 'success',
        });
      },
    }
  );

  const onChangeTemplateSection = (e, templateId) => {
    e.stopPropagation();
    setSelectedTemplate(templateId);
  };

  const onLoadTemplate = () => {
    setTimeout(() => {
      Modal.show('RiskEngImportTemplate', {
        data: { ...selectedTemplate, accountId: data.accountId },
      });
    }, 0);
    props.close();
  };

  return (
    <>
      <Box mt={1} width="90%" mr="auto" ml="auto">
        <StickyContainer>
          <SearchField
            isDebounce={false}
            onChange={(searchInput) => setSearch(searchInput)}
          />
        </StickyContainer>
        <HeaderRow mt={2}>
          <Header width="15%">Select</Header>
          <Header width="30%" textAlign="left">
            Template Name
          </Header>
          {/* <Header width="15%">Number of Acc. used</Header> */}
          <Header width="40%" textAlign="left">
            Template Description
          </Header>
          <Header width="15%">Delete</Header>
        </HeaderRow>
      </Box>
      <DialogContent style={{ width: '90%', margin: '0 auto', padding: 0 }}>
        <Box height="30rem" width="100%">
          {isLoading ? (
            <Box mt={2} display="flex" justifyContent="center">
              Fetching hold on tight...
            </Box>
          ) : (
            templates.map((template, idx) => {
              return (
                <BodyRow
                  onClick={(e) => onChangeTemplateSection(e, template)}
                  key={`${template.id}${template.name}`}
                >
                  <Text width="15%">
                    <Radio
                      size="small"
                      color="secondary"
                      checked={selectedTemplate.id === template.id}
                      value={template.id}
                      inputProps={{ 'aria-label': `${idx}` }}
                      style={{ color: palette.text.text12 }}
                    />
                  </Text>
                  <EditField
                    templateId={template.id}
                    value={template.name}
                    onSubmit={onUpdateTemplate}
                  />
                  {/* <Text width="15%">3</Text> */}
                  <Text width="40%" textAlign="left">
                    {template.description}
                  </Text>
                  <Text width="15%" justifyContent="center">
                    <DeletionDialog
                      onClick={() => onDeleteTemplate(template.id)}
                    />
                  </Text>
                </BodyRow>
              );
            })
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <CbButton onClick={props.close} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton
          disabled={!selectedTemplate}
          type="submit"
          onClick={onLoadTemplate}
          styleName="ctaButton"
        >
          Load Template
        </CbButton>
      </DialogActions>
    </>
  );
};

const TextArea = styled(TextAreaBase)(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.background.darkerBlue,
  resize: 'none',
  padding: '0.4rem 0.6rem',
  maxWidth: '100%',
  maxHeight: '2rem',
  color: theme.palette.primary.light,

  '&:focus': {
    outline: 'none',
  },
}));

const EditField = ({ templateId, value, onSubmit }) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [userInput, setUserInput] = React.useState(value);

  const onSubmitLocal = (e) => {
    e.stopPropagation();
    onSubmit({ templateId, name: userInput?.trim() });
    setIsEditing(false);
  };

  const onCancel = (e) => {
    e.stopPropagation();
    setIsEditing(false);
    setUserInput(value);
  };

  const onEdit = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const onChange = (e) => {
    setUserInput(e.target.value);
  };

  const onFocus = (e) => {
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  };

  return isEditing ? (
    <Text width="30%" textAlign="left" pr={1}>
      <HighlightOffIcon onClick={onCancel} />
      <TextArea
        onFocus={onFocus}
        autoFocus
        maxRows={1}
        defaultValue={value}
        onChange={onChange}
      />
      <CheckCircleOutlineIcon onClick={onSubmitLocal} />
    </Text>
  ) : (
    <Text width="30%" textAlign="left">
      <CreateIcon onClick={onEdit}>Edit</CreateIcon>
      {value}
    </Text>
  );
};

const SearchField = styled(SearchFieldBase)(({ theme: { palette } }) => ({
  backgroundColor: palette.background.default,
}));

const Radio = styled(RadioBase)(({ theme: { palette } }) => ({
  color: palette.text.text12,
  width: '1rem',
  height: '1rem',
  margin: '0 auto',
}));

export const RiskEngLoadTemplateModalConfig = {
  RiskEngLoadTemplateModal: {
    component: RiskEngLoadTemplateModal,
    config: {
      fullWidth: true,
      title: 'Load Template',
      maxWidth: 'md',
      icon: iconMappings.PoliciesIcon.iconImport,
    },
  },
};

const CreateIcon = styled(CreateIconBase)(() => ({
  marginRight: '0.25rem',
  fontSize: '1.5rem',
}));

const HighlightOffIcon = styled(HighlightOffIconBase)(() => ({
  marginRight: '0.25rem',
  fontSize: '1.5rem',
}));

const CheckCircleOutlineIcon = styled(CheckCircleOutlineIconBase)(
  ({ theme }) => ({
    fontSize: '1.5rem',
    color: theme.palette.text.text4,
  })
);

const StickyContainer = styled(Box)(({ theme: { palette } }) => ({
  backgroundColor: palette.background.main,
  position: 'sticky',
  top: 0,
  height: '5rem',
  display: 'flex',
  alignItems: 'center',
  zIndex: 10,
}));

const HeaderRow = styled(Box)(({ theme: { palette } }) => ({
  backgroundColor: palette.background.default,
  height: '3rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 5,
}));

const BodyRow = styled(HeaderRow)(() => ({
  borderRadius: 0,
  cursor: 'pointer',
  marginTop: '0.4rem',
  width: '100%',
}));

const Header = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'textAlign',
})(({ textAlign }) => ({
  textAlign: textAlign || 'center',
}));

const Text = styled(Header)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
}));

const useOptimisticMutation = (
  cacheKey,
  asyncMutation,
  {
    syncOptimisticCacheMutation,
    onError,
    onSettled,
    ...useMutationOptions
  } = {}
) => {
  const queryClient = useQueryClient();

  return useMutation(asyncMutation, {
    onMutate: async (newState) => {
      await queryClient.cancelQueries(cacheKey);

      const previousState = queryClient.getQueryData(cacheKey);

      queryClient.setQueryData(cacheKey, () =>
        syncOptimisticCacheMutation(newState)
      );

      return { previousState, newState };
    },

    onError: (err, newState, context) => {
      queryClient.setQueryData(cacheKey, context.previousState);
      if (typeof onError === 'function') {
        onError(err);
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries(cacheKey);
      if (typeof onSettled === 'function') {
        onSettled();
      }
    },
    ...useMutationOptions,
  });
};
