import React from 'react';

export const Prime250Context = React.createContext(null);

export const use250Context = () => React.useContext(Prime250Context);

export const UserContext = React.createContext('');

export const useUserContext = () => React.useContext(UserContext);

export const SurplusContext = React.createContext('');

export const useSurplusContext = () => React.useContext(SurplusContext);
