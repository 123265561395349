import _ from 'lodash';
import { useSnackbar } from 'notistack';
// react
import React, { useState } from 'react';
import { compose } from 'redux';
import { useForm, FormContext } from 'react-hook-form-4';
// mui
import { DialogActions, DialogContent, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/styles';

import { delayedEvent } from '../../../../utils/eventUtils';
// components
import CBButton from '../../../../components/Buttons/CbButton';
// actions
import { deleteBillingContact } from '../../../customers/_services/BillingService';
import { deleteCommissionsContact } from '../../_services/commission.service';

export const DeleteBillingCommissionContact = compose(
  withStyles(({ palette, config }) => ({
    text: {
      color: palette.primary.contrastText,
      fontWeight: config.weights.bold,
      lineHeight: 1.38,
      fontSize: config.textSizes.primer,
    },
  }))
)(({ classes, data, ...props }) => {
  const [backendError, setBackendError] = useState('');
  const {
    formState: { isSubmitting },
    handleSubmit,
    ...methods
  } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = () => {
    if (data.isBill) {
      return deleteBillingContact({ id: data.id })
        .then(() => {
          enqueueSnackbar('Billing contact deleted succesfully!', {
            variant: 'success',
          });
          props.close();
          makeRefreshCall();
        })
        .catch((error) => {
          setBackendError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Not able to update contact at this time.Please try again later.'
              )
            )
          );
        });
    }
    return deleteCommissionsContact({ id: data.id })
      .then(() => {
        enqueueSnackbar('Commission contact deleted succesfully!', {
          variant: 'success',
        });
        props.close();
        makeRefreshCallCommission();
      })
      .catch((error) => {
        setBackendError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Not able to update contact at this time.Please try again later.'
            )
          )
        );
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2.5rem 5rem' }}>
          <div className={classes.text}>
            Are you sure you want to delete the{' '}
            {data.isBill ? 'billing' : 'commission'} contact for{' '}
            {data.locationName}?
          </div>
        </DialogContent>
        <FormHelperText className="api-text-error">
          {backendError}
        </FormHelperText>
        <DialogActions>
          <CBButton
            onClick={props.close}
            styleName="cancel"
            buttonText="Cancel"
          />
          <CBButton
            type="submit"
            styleName="ctaButton"
            loading={isSubmitting}
            disabled={isSubmitting}
            buttonText="Delete Contact"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
});

export const DeleteBillingCommissionContactConfig = {
  DeleteBillingCommissionContact: {
    component: DeleteBillingCommissionContact,
    config: {
      width: 'sm',
      maxWidth: 'sm',
    },
  },
};

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, 'billingContact');
}

function makeRefreshCallCommission() {
  delayedEvent('table-refetch', 500, 'commissionContacts');
}
