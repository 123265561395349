import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// react
import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
// mui
import {
  Grid as MuiGrid,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';

// components
import { Checkbox } from '../../components/inputs';
import { hostname as hostnameSchema } from '../../utils/validationSchemas/accounts.schema';
import { withFormController } from '../../components/hocs/withFormController';
import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { delayedEvent } from '../../utils/eventUtils';
import { getUrlHost } from '../../utils/appUtils';
import CbButton from '../../components/Buttons/CbButton';

// actions
import { createVendor, updateVendor } from '../../api/vendor.api';

const TextField = withFormController(TextFieldBase);
const hostName = getUrlHost();
const Check = withFormController(Checkbox);
const schema = Yup.object().shape({
  hostname: hostnameSchema,
  name: Yup.string()
    .required()
    .label('Name')
    .matches(
      /^[aA-zZ,0-9\s]+$/,
      'Only alphanumerics are allowed for this field '
    ),
  description: Yup.string().required().label('Description'),
});

export const VendorCreateEdit = ({ data, close }) => {
  const edit = !_.isEmpty(data);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');
  const urlHost = getUrlHost();

  const {
    handleSubmit,
    formState: { isSubmitting, dirty },
    ...methods
  } = useForm({
    defaultValues: {
      name: data.name || '',
      description: data.description || '',
      hostname: data.hostname ? data.hostname.split('.')[0] : '',
      enforceMFA: data.enforceMFA || false,
    },
    validationSchema: schema,
  });

  methods.watch(['hostname']);

  const onSubmit = (formData) => {
    const { name, description, hostname, enforceMFA } = formData;
    const finalHostName = `${hostname}${urlHost.trim()}`;

    const payload = {
      name,
      description,
      hostname: edit ? hostname : finalHostName,
      enforceMFA,
    };

    const api = edit
      ? updateVendor({ id: data.id }, { ...payload })
      : createVendor({}, payload);

    api
      .then(() => {
        enqueueSnackbar(`Vendor Updated successfully!`, { variant: 'success' });
        delayedEvent('table-refetch', 1000, 'vendors');
        close();
      })
      .catch((error) => {
        const errorMessage = _.get(error, 'response.data');
        if (typeof errorMessage === 'string') {
          setError(errorMessage);
        } else {
          setError('Unable to update your data, please try again');
        }
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} data-qa="addVendor">
        <DialogContent style={{ padding: '2rem 5rem' }}>
          <MuiGrid container spacing={2}>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="name"
                label="Name"
                required
                fullWidth
                inputProps={{ required: true }}
              />
            </MuiGrid>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="description"
                label="Description"
                required
                fullWidth
              />
            </MuiGrid>
          </MuiGrid>

          <TextField
            required
            label="Hostname"
            name="hostname"
            helperText={`example${hostName}`}
          />
          <MuiGrid item>
            <Check name="enforceMFA" label="Enforce MFA?" />
          </MuiGrid>
        </DialogContent>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText={edit ? 'Update Vendor' : 'Add Vendor'}
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

export const VendorCreateEditConfig = {
  VendorCreateEdit: {
    component: VendorCreateEdit,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
    },
  },
};
