import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTheme } from '@mui/styles';
import type { DefaultTheme } from '@mui/styles';
import { BaseFlag } from './FlagCell';
import { withShowable } from '../../console/_global/lib/withShowable';

interface ProgramFlagProps {
  programName: string;
}

export const ProgramFlag = withShowable(({ programName }: ProgramFlagProps) => {
  const theme = useTheme<DefaultTheme>();
  return (
    <BaseFlag
      color={theme.palette.flags.program}
      tooltip={`Program: ${programName}`}
    >
      <FontAwesomeIcon icon={solid('list-tree')} />
    </BaseFlag>
  );
});
