import React from 'react';
import { useQuery } from '@tanstack/react-query';

// components
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomTypeahead } from '../global-search/CustomTypeahead';
import Showable from '../../../../components/Showable';

// apis
import { getData } from '../../../../utils/functional/fp';
import { searchPoliciesV2 } from '../../../../policies/PolicyService';
import {
  AgencyDashboardPolicyRow,
  PolicyRowDetail,
} from '../listing-tabs/policies/AgencyDashboardPoliciesTab';
import type { Policy } from '../../../accounts/summary/types/policy.types';
import { RenewPolicySearchListing } from './RenewPolicySearchListing';
import { ACTIVE_STATUS, ISSUED_STATUS } from '../../../_statics/policy.statics';
import { DRAFT_STATUS } from '../../../_statics/quote.statics';
import { useGetPlatformRegion } from '../../../../utils';
import { languages } from '../../../../i18n/i18n.language-config';

export const RenewPolicy = () => {
  const [policy, setPolicy] = React.useState<Policy>();
  const region = useGetPlatformRegion();

  const { data: policies = [] } = usePoliciesQuery({
    search: policy?.companyName,
    f: {
      status: { in: ['IN_FORCE', ISSUED_STATUS, DRAFT_STATUS, ACTIVE_STATUS] },
      isOpenForRenewal: { bo: 1 },
    },
  });

  const onSelect = (selectedPolicy: Policy) => {
    setPolicy(selectedPolicy);
  };

  const onClear = () => {
    setPolicy(undefined);
  };

  if (region === languages['en-GB']) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop={8}
      >
        <Typography>
          There are no policies that need to be renewed at this time
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={8}
    >
      <Typography>Please search for the client or policy below</Typography>
      <Box display="flex" justifyContent="center" mt={2} width="50rem">
        <RenewPolicySearch onSelect={onSelect} onClear={onClear} />
      </Box>
      <Showable show={!!policy}>
        <PoliciesListing policies={policies} />
      </Showable>
    </Box>
  );
};

interface RenewPolicySearchProps {
  onSelect: (policy: Policy) => void;
  onClear: () => void;
}

const RenewPolicySearch = ({ onSelect, onClear }: RenewPolicySearchProps) => {
  const [userInput, setUserInput] = React.useState('');

  const onChange = (value: string) => {
    setUserInput(value);
  };

  const onClearInput = () => {
    setUserInput('');
    onClear();
  };

  return (
    <CustomTypeahead
      onChange={onChange}
      onClearInput={onClearInput}
      maxListHeight="15rem"
    >
      {(typeaheadProps) => (
        <RenewPolicySearchListing
          {...typeaheadProps}
          onSelect={onSelect}
          userInput={userInput}
        />
      )}
    </CustomTypeahead>
  );
};

const PoliciesListing = ({ policies }: { policies: Policy[] }) => {
  const classes = usePolicyRowStyles();
  return (
    <Box
      marginTop={10}
      marginBottom={5}
      width="90%"
      style={{ overflow: 'auto', height: '55vh' }}
    >
      {policies.map((policy) => (
        <Box key={policy.id} className={classes.container}>
          <Box display="flex" flexDirection="column">
            <AgencyDashboardPolicyRow policy={policy} />
            <PolicyRowDetail policy={policy} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const usePoliciesQuery = (query: any) => {
  return useQuery({
    queryKey: ['agency-dashboard-policies', query],
    queryFn: () => searchPoliciesV2({ params: query }).then(getData),
    select: (resp) => resp.content,
  });
};

export const usePolicyRowStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '9rem',
    margin: '1rem',
    border: `1px solid ${palette.notification.containerBorder}`,
    borderRadius: 5,
    overflow: 'hidden',
  },
}));
