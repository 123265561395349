import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Button as MuiButton,
  Card as MuiCard,
  Popover as MuiPopover,
  Typography,
  useTheme,
  Box as MuiBox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  CalendarToday as CalendarTodayIcon,
  ChevronLeft,
} from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import { useQueryParams } from '../providers/QueryParamsProvider';

import withRouter from '../hocs/withRouter';
import CbButton from '../Buttons/CbButton';
import { SimpleSelect } from './selects';
import { withShowable } from '../../console/_global/lib/withShowable';
import { toUniversalUtcDate } from '../../utils/date.utils';
import { useCowbellTranslations } from '../../i18n/translations';
import { languages } from '../../i18n/i18n.language-config';

const Box = withShowable(MuiBox);

const mappings = {
  1: { startDate: Moment().subtract(7, 'days') },
  2: { startDate: Moment().subtract(1, 'month') },
  3: { startDate: Moment().subtract(3, 'months') },
  4: { startDate: Moment().subtract(6, 'months') },
  5: { startDate: Moment().subtract(12, 'months') },
  6: { startDate: Moment().subtract(1, 'days') },
  7: { startDate: Moment().subtract(2, 'days') },
  8: { startDate: Moment().startOf('month') },
  9: { startDate: Moment().startOf('quarter') },
  10: { startDate: Moment().startOf('year') },
  11: { startDate: Moment().subtract(2, 'years') },
  12: { startDate: Moment().subtract(3, 'years') },
  13: {
    startDate: Moment().subtract(2, 'years').startOf('year'),
    endDate: Moment().subtract(2, 'years').endOf('year'),
  },
  14: {
    startDate: Moment().subtract(1, 'year').startOf('year'),
    endDate: Moment().subtract(1, 'year').endOf('year'),
  },
  15: { startDate: Moment().startOf('year') },
};
const oneYearFromToday = Moment().subtract(1, 'year').toDate();

const presets = [
  {
    label: '7 days',
    value: '1',
  },
  {
    label: '1 month',
    value: '2',
  },
  {
    label: '3 months',
    value: '3',
  },
  {
    label: '6 months',
    value: '4',
  },
  {
    label: '1 year',
    value: '5',
  },
  {
    label: '2 years',
    value: '11',
  },
  {
    label: '3 years',
    value: '12',
  },
];

const CustomDay = (day) => {
  return <span>{day}</span>;
};

export const DateRangePicker = ({
  start,
  end,
  onChange,
  onPresetSelect,
  startSelected,
  endSelected,
  label,
  popoverProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  preset,
  StartDatePickerProps = {},
  EndDatePickerProps = {},
  customPreset,
  DateFilterSelector = () => null,
}) => {
  const { palette } = useTheme();
  const classes = useGeneralStyles();
  const valueContainerClasses = useValueContainerStyles();
  const cardClasses = useCardStyles();
  const presetItemClasses = usePresetItemStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = useState(startSelected);
  const [endDate, setEndDate] = useState(endSelected);
  const [tempStart, setTempStart] = useState(null);
  const [tempEnd, setTempEnd] = useState(null);
  const datePresets = customPreset ? [...customPreset] : [...presets];
  const [customSelect, setCustomSelect] = useState(false);
  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  React.useEffect(() => {
    setStartDate(startSelected);
    setEndDate(endSelected);
  }, [endSelected, startSelected]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setTempStart(null);
    setTempEnd(null);
  }, []);

  const startOnChange = useCallback((date) => {
    setTempStart(date);
    setCustomSelect(true);
    // eslint-disable-next-line
  }, []);

  const endOnChange = useCallback((date) => {
    setTempEnd(date);
    setCustomSelect(true);
    // eslint-disable-next-line
  }, []);

  const handlePresetSelect = useCallback(
    (event) => {
      if (typeof onPresetSelect === 'function') {
        setCustomSelect(false);
        // eslint-disable-next-line
        const preset = _.get(
          event.target.closest('[data-value]'),
          'dataset.value'
        );
        if (preset && preset !== '0') {
          const nextEnd = mappings[preset].endDate ?? Moment();
          const nextStart = mappings[preset].startDate;
          setStartDate(nextStart.startOf('day').toDate());
          setEndDate(nextEnd.endOf('day').toDate());
          setTempStart(null);
          setTempEnd(null);
          onPresetSelect(
            preset,
            nextStart.startOf('day').toDate(),
            nextEnd.endOf('day').toDate()
          );
          if (typeof onChange === 'function') {
            onChange({
              start: Moment(nextStart).startOf('day').toDate(),
              end: Moment(nextEnd).endOf('day').toDate(),
            });
          }
          handleClose();
        }
      }
    },
    [onPresetSelect, onChange, handleClose]
  );

  const handleApply = useCallback(() => {
    if (tempStart !== null) {
      setStartDate(tempStart);
    }

    if (tempEnd !== null) {
      setEndDate(tempEnd);
    }

    if (typeof onChange === 'function') {
      onChange({
        start: Moment(tempStart || startDate)
          .startOf('day')
          .toDate(),
        end: Moment(tempEnd || endDate)
          .endOf('day')
          .toDate(),
      });
    }
    handleClose();
  }, [tempStart, tempEnd, onChange, handleClose, startDate, endDate]);

  const { i18n } = useCowbellTranslations();
  const DATE_FORMAT =
    i18n.language === languages['en-US'] ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const selectedStart = tempStart || startDate;
  const selectedEnd = tempEnd || endDate;
  const displayValue =
    selectedStart && selectedEnd
      ? `${Moment(selectedStart).format(DATE_FORMAT)} - ${Moment(
          selectedEnd
        ).format(DATE_FORMAT)}`
      : 'Select Date Range';

  return (
    <>
      {label}
      <MuiButton
        classes={valueContainerClasses}
        aria-describedby={id}
        onClick={handleOpen}
      >
        {displayValue}{' '}
        <CalendarTodayIcon
          style={{
            color:
              selectedStart && selectedEnd
                ? palette.common.cowbellBlue
                : '#9192a4',
            paddingLeft: '0.25rem',
          }}
        />
      </MuiButton>
      <MuiPopover
        {...popoverProps}
        open={open}
        id={id}
        onClose={handleClose}
        anchorEl={anchorEl}
        style={{ marginTop: '0.4166666667rem' }}
      >
        <MuiCard classes={cardClasses}>
          <div className={classes.calendarContainer}>
            <div className={classes.calendarDateContainer}>
              <strong>From</strong>{' '}
              <span className={classes.dateDisplay}>
                {toUniversalUtcDate(selectedStart)}
              </span>
            </div>
            <DatePicker
              selected={selectedStart}
              onChange={startOnChange}
              selectsStart
              startDate={selectedStart}
              endDate={selectedEnd}
              renderDayContents={CustomDay}
              shouldCloseOnSelect={false}
              minDate={start}
              maxDate={end}
              inline
              {...StartDatePickerProps}
            />
          </div>
          <div className={classes.calendarContainer}>
            <div className={classes.calendarDateContainer}>
              <strong>To</strong>{' '}
              <span className={classes.dateDisplay}>
                {toUniversalUtcDate(selectedEnd)}
              </span>
            </div>
            <DatePicker
              selected={selectedEnd}
              onChange={endOnChange}
              selectsEnd
              startDate={selectedStart}
              endDate={selectedEnd}
              renderDayContents={CustomDay}
              shouldCloseOnSelect={false}
              minDate={selectedStart}
              maxDate={end}
              inline
              {...EndDatePickerProps}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <h4
              style={{
                margin: 0,
                marginTop: '0.4166666667rem',
                marginLeft: '1.6666666667rem',
              }}
            >
              Date Range:
            </h4>
            <ol className={`list--unstyled ${classes.presetList}`}>
              {datePresets.map(({ value, ...ps }) => {
                let selectedCss = '';
                if (!customSelect) {
                  if (preset === value) {
                    selectedCss = 'selected';
                  } else {
                    selectedCss = '';
                  }
                } else if (value === '0') {
                  selectedCss = 'selected';
                } else {
                  selectedCss = '';
                }
                return (
                  <li>
                    <MuiButton
                      classes={presetItemClasses}
                      className={selectedCss}
                      data-value={value}
                      onClick={handlePresetSelect}
                      fullWidth
                    >
                      {preset === value && !customSelect && (
                        <ChevronLeft style={{ color: palette.text.link }} />
                      )}
                      {ps.label}
                    </MuiButton>
                  </li>
                );
              })}
            </ol>
            <CbButton
              styleName="primary"
              style={{ color: palette.secondary.contrastText }}
              onClick={handleApply}
            >
              Apply
            </CbButton>
            <DateFilterSelector />
          </div>
        </MuiCard>
      </MuiPopover>
    </>
  );
};

export const UrlDateRangePicker = withRouter(
  ({
    onChange,
    start = oneYearFromToday,
    sort,
    minSelectableDate = new Date('2020-01-02'), // Earliest date the user can pick
    maxSelectableDate = new Date(),
    dateFilterOptions,
    ...props
  }) => {
    const { query, replace } = useQueryParams();
    const { pathname } = useLocation();
    const startDate = query.after
      ? Moment(Number(query.after) * 1000).toDate()
      : start;
    const endDate = query.before
      ? Moment(Number(query.before) * 1000).toDate()
      : Moment().toDate();

    const handleChange = (values) => {
      const { page, searchAfter, pageRequested, ...queryRest } = query;
      if (values.start.getTime() < values.end.getTime()) {
        replace(pathname, {
          query: {
            ...queryRest,
            after: Moment.utc(values.start).unix(),
            before: Moment.utc(values.end).unix(),
            dps: undefined, // ensures the preset gets removed
            orderBy: sort ?? query.orderBy,
            page: 0,
          },
        });

        if (typeof onChange === 'function') {
          onChange(values);
        }
      }
    };

    const handlePresetSelect = (value, nextStart, nextEnd) => {
      const { page, searchAfter, pageRequested, ...queryRest } = query;
      replace(pathname, {
        query: {
          ...queryRest,
          dps: value,
          before: Moment(nextEnd).unix(),
          after: Moment(nextStart).unix(),
        },
      });
    };

    const onDateFilterChange = (event) => {
      replace(pathname, {
        query: { ...query, dateFilter: event.target.value },
      });
    };

    return (
      <DateRangePicker
        start={minSelectableDate}
        end={maxSelectableDate}
        startSelected={startDate}
        endSelected={endDate}
        onChange={handleChange}
        onPresetSelect={handlePresetSelect}
        preset={query.dps}
        DateFilterSelector={() => (
          <DefaultDateFilterSelector
            options={dateFilterOptions}
            onChange={onDateFilterChange}
            value={query.dateFilter}
          />
        )}
        {...props}
      />
    );
  }
);

const DefaultDateFilterSelector = ({ value, options, onChange }) => (
  <Box show={!!options} style={{ marginTop: '0.5rem' }}>
    <Typography variant="subtitle1">Filter By:</Typography>
    <SimpleSelect value={value} options={options} onChange={onChange} />
  </Box>
);

const useCardStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    padding: '0.6666666667rem',
    background: palette.background.active,
    border: `1px solid ${palette.primary.oldBorder}`,

    '& strong': {
      color: palette.text.link,
    },

    '& > div:last-of-type': {
      marginRight: 0,
    },

    '& .react-datepicker__header': {
      background: palette.background.default,
      borderBottom: 'none',
    },

    '& .react-datepicker': {
      background: palette.background.active,
    },

    '& .react-datepicker__current-month': {
      color: palette.text.link,
    },

    '& .react-datepicker__day-names': {
      marginTop: 5,
      background: palette.background.lighter,
    },

    '& .react-datepicker__day-name': {
      color: palette.text.link,
    },

    // styling the days
    '& .react-datepicker__day': {
      boxSizing: 'content-box',
      margin: 0,
      padding: '0.166rem',
      borderRadius: 0,
      color: palette.text.text6,
      outline: 'none',
    },

    '& .react-datepicker__day.react-datepicker__day--selecting-range-start:hover:not(.react-datepicker__day--in-selecting-range)':
      {
        background: palette.common.softBlue,
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
      },

    '& .react-datepicker__day.react-datepicker__day--selecting-range-end:hover:not(.react-datepicker__day--in-selecting-range)':
      {
        background: palette.common.softBlue,
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
      },

    '& .react-datepicker__week': {
      '& > :first-child': {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
      },

      '& > :last-child': {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
      },
    },

    '& .react-datepicker__day.react-datepicker__day--in-selecting-range': {
      background: palette.common.softBlue,
    },

    '& .react-datepicker__day.react-datepicker__day--in-range': {
      background: palette.common.softBlue,
    },

    // start and end circles
    '& .react-datepicker__day:hover:not(.react-datepicker__day--disabled), .react-datepicker__day--range-start, & .react-datepicker__day--range-end, & .react-datepicker__day--selecting-range-end, & .react-datepicker__day--selecting-range-start':
      {
        position: 'relative',

        '& > *': {
          position: 'relative',
          zIndex: 1,
        },

        '&:before': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          background: '#2280e2',
          borderRadius: '50%',
        },
      },

    '& .react-datepicker__day--range-start, & .react-datepicker__day--range-end':
      {
        '&.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selected)':
          {
            '&:before': {
              content: 'unset',
            },
          },
      },

    '& .react-datepicker__day--range-start:not(.react-datepicker__day--in-selecting-range)':
      {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
      },

    '& .react-datepicker__day--range-end:not(.react-datepicker__day--in-selecting-range)':
      {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
      },

    '& .react-datepicker__day--selecting-range-start:not(.react-datepicker__day--in-range)':
      {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
      },

    '& .react-datepicker__day--selecting-range-end:not(.react-datepicker__day--in-range)':
      {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
      },
  },
}));

const useValueContainerStyles = makeStyles(({ palette, shape }) => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'space-between',

    width: 'fit-content',
    minWidth: '14.8333333333rem',
    padding: '0.4166666667rem 0.8333333333rem',
    border: `1px solid ${palette.common.cowbellBlue}`,
    borderRadius: shape.borderRadius,
    textTransform: 'capitalize',
  },

  label: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const usePresetItemStyles = makeStyles(({ palette }) => ({
  root: {
    justifyContent: 'flex-start',

    paddingLeft: '1.6666666667rem',
    paddingBottom: '0.1666666667rem',
    textTransform: 'capitalize',

    '&:hover': {
      color: palette.text.link,
    },

    '&.selected': {
      color: palette.text.link,
      border: `1px solid ${palette.common.cowbellBlue}`,
    },
  },

  label: {
    position: 'relative',

    '& > svg': {
      position: 'absolute',
      top: '0.25rem',
      right: '100%',
    },
  },
}));

const useGeneralStyles = makeStyles(({ palette, shape }) => ({
  dateDisplay: {
    display: 'inline-block',
    padding: '0.4166666667rem 0.8333333333rem',
    marginLeft: '0.4166666667rem',
    border: `1px solid ${palette.primary.oldBorder}`,
    borderRadius: shape.borderRadius,
  },

  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '0.6666666667rem',
  },

  calendarDateContainer: {
    textAlign: 'right',
    marginBottom: '0.4166666667rem',

    // div auto generated by the picker component
    '& + div': {
      display: 'flex',
      height: '100%',
    },
  },
}));
