import React from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import FileUploaderIcon from '../../_assets/svg/FileUploaderIcon.svg';
import { PubSub } from '../../utils/eventUtils';

export const DropZoneOverlay = () => {
  const classes = DropZoneOverlayClasses();
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const sub = PubSub.subscribe('DropZoneOverlay', (eventData) => {
      setVisible(eventData);
    });
    return () => {
      sub.remove();
    };
  }, []);

  return (
    <section className={visible ? classes.show : classes.hide}>
      <section className={classes.overlay} />
      <section className={classes.icon}>
        <FileUploaderIcon
          style={{
            height: '6.25rem',
            width: '6.25rem',
            position: 'relative',
          }}
        />
      </section>
      <div className={classes.description}>
        Drag and Drop a file to instantly upload
      </div>
    </section>
  );
};

const DropZoneOverlayClasses = makeStyles(({ palette }) => ({
  show: {
    width: '100%',
    height: '100%',
    display: 'inherit',
  },
  overlay: {
    display: 'inherit',
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    marginLeft: '-1rem',
    backgroundColor: palette.background.button,
    opacity: 0.19,
  },
  hide: {
    display: 'none',
  },
  icon: {
    position: 'absolute',
    bottom: '5rem',
    left: 'calc(50% - 2rem)',
  },
  description: {
    width: '19.167rem',
    height: '4.5rem',
    borderRadius: 5,
    backgroundColor: palette.background.button,
    fontSize: '1rem',
    lineHeight: 1.33,
    color: palette.primary.main,
    textAlign: 'center',
    padding: '1.5rem 0',
    margin: '0 auto',
    bottom: '1rem',
    position: 'absolute',
    left: 'calc(50% - 9rem)',
  },
}));

export const TableDropZoneOverlay = () => {
  const classes = TableDropZoneOverlayClasses();

  return (
    <section className={classes.container}>
      <section className={classes.screenCenter} />
      <section className={classes.icon}>
        <FileUploaderIcon
          style={{
            height: '6.25rem',
            width: '6.25rem',
            position: 'relative',
          }}
        />
      </section>
      <div className={classes.description}>
        Drag and Drop a file to instantly upload
      </div>
    </section>
  );
};

const TableDropZoneOverlayClasses = makeStyles(({ palette }) => ({
  screenCenter: {
    position: 'absolute',
    bottom: '0',
    backgroundColor: palette.background.button,
    opacity: 0.19,
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
  },
  icon: {
    position: 'absolute',
    bottom: '5rem',
    left: 'calc(50% - 2rem)',
  },
  description: {
    width: '19.167rem',
    height: '4.5rem',
    borderRadius: 5,
    backgroundColor: palette.background.button,
    fontSize: '1rem',
    lineHeight: 1.33,
    color: palette.primary.main,
    textAlign: 'center',
    padding: '1.5rem 0',
    margin: '0 auto',
    bottom: '1rem',
    position: 'absolute',
    left: 'calc(50% - 9rem)',
  },
}));

/**
 * @description: HOC that wraps around your main component with Dropzone library. You'll need to provide callback
 * functions coming from a higher order HOC (ex. withUploadFeatureDocuments: documents tab under UW page) for your
 * particular usecases.
 */
export const withUploadFeature = ({ screenCenter, hide, acceptFileTypes }) => {
  return (Component) => {
    return (props) => {
      const readOnly = false;
      const [disabled, setDisabled] = React.useState(hide || readOnly);
      const classes = DropzoneStyles();
      const componentRef = React.useRef(null);
      const {
        isDragActive,
        isDragAccept,
        isDragReject,
        open,
        getRootProps,
        getInputProps,
      } = useDropzone({
        noClick: true,
        onDrop: props.onDrop,
        disabled,
        ...(acceptFileTypes && { accept: acceptFileTypes }),
      });

      React.useEffect(() => {
        // support any button triggering the upload feature
        const sub = PubSub.subscribe('openFileUploadFeature', () => {
          open();
        });
        const sub1 = PubSub.subscribe(
          'toggleFileUploadFeature',
          (eventData) => {
            setDisabled(eventData);
          }
        );
        return () => {
          sub.remove();
          sub1.remove();
        };
      }, [open]);

      const style = React.useMemo(() => {
        if (isDragAccept) {
          PubSub.publish('DropZoneOverlay', true);
        }

        if (isDragActive) {
          if (screenCenter) {
            return classes.activeStyle;
          }
          return '';
        }
        if (isDragReject) return classes.rejectStyle;

        PubSub.publish('DropZoneOverlay', false);
      }, [
        isDragAccept,
        isDragActive,
        isDragReject,
        classes.rejectStyle,
        classes.activeStyle,
      ]);

      return (
        <section
          style={{
            minHeight: `${props.minHeight}rem`,
            width: '100%',
            position: 'relative',
          }}
          name="withUploadFeature"
          {...getRootProps({
            className: `${classes.container} ${classes.baseStyle} ${style}`,
          })}
        >
          <input {...getInputProps()} />
          <section ref={componentRef}>
            <Component {...props} />
          </section>
          {isDragAccept && screenCenter && <TableDropZoneOverlay />}
        </section>
      );
    };
  };
};

const DropzoneStyles = makeStyles(({ palette }) => ({
  textIconContainer: {},
  overlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 100,
    opacity: 0.19,
    backgroundColor: palette.background.fileUploader,
    top: 0,
  },
  container: {},
  baseStyle: {
    borderWidth: 2,
    borderTopWidth: 0,
    borderRadius: 2,
    borderColor: 'transparent',
    borderStyle: 'solid',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    opacity: 1,
  },

  activeStyle: {
    borderColor: `${palette.border.acceptFileUploader} !important`,
  },

  rejectStyle: {
    borderColor: 'red !important',
  },
}));
