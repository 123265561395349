import React from 'react';
import _ from 'lodash';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

//mui
import type { AutocompleteChangeReason } from '@mui/material';
import {
  Box as MuiBox,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';

//components
import CbButton from '../../Buttons/CbButton';
import { DefaultFilePreview } from '../../fileUploader/v2/DefaultFilePreview';
import { Uploadzone } from '../../fileUploader/v2/UploadZone';
import { getOptionLabel, SimpleSelect as Select } from '../../inputs';
import {
  ButtonContainer,
  FullScreenDropzonePlaceholder,
} from './ApplicationUpload';

//hooks and hocs
import useUploadZone from '../../fileUploader/v2/hooks/useUploadzone';
import { withShowable } from '../../../console/_global/lib/withShowable';

//services
import { uploadDocumentV3 } from '../../../api/documents.api';
import { getDocTypes } from '../../../api/DocumentsService';

//contants
import { COWBELL_ACCOUNTS } from '../../tables/table_constants';
import type { AgencyDto } from '../../../types/agencies/dto/agency.dto';
import { acceptedUploadDocs } from '../../../console/workflows/bind-quote/constants/constants';

const Box = withShowable(MuiBox);

interface Props {
  data: any;
  close: () => void;
}

type AgencyOption = { label: string; value: string | undefined };

type InterestedAgencies = AgencyOption[] | [];

type SelectedAgency = {
  label: string;
  value: string;
} | null;

type SelectedDocType = string | null;

export const DocumentUpload = ({ data: modalData = {}, close }: Props) => {
  const [selectedAgency, setSelectedAgency] =
    React.useState<SelectedAgency>(null);
  const [selectedDocType, setSelectedDocType] = React.useState<SelectedDocType>(
    modalData.docTypesConfig?.defaultDocType ?? null
  );

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { files, handleUploads, ...uploadZoneParms } = useUploadZone();
  const { data: docTypes } = useDocTypes({
    docTypesPayload: modalData.docTypesConfig?.docTypesPayload,
  });

  const interestedAgencies = React.useMemo<InterestedAgencies>(() => {
    const agencyMapping = modalData.account.agencies?.map(
      (agency: AgencyDto) => ({
        label: agency.agencyName,
        value: agency.agencyId,
      })
    );

    return [{ label: 'All Agencies', value: undefined }, ...agencyMapping];
  }, []);

  const handleAgencyChange = (
    event: React.ChangeEvent<object>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    setSelectedAgency(value);
  };

  const handleDocTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedDocType(value);
  };

  const handleAsyncDocUpload = async ({
    axiosConfig,
  }: {
    axiosConfig: AxiosRequestConfig;
  }) => {
    const formData = new FormData();
    formData.append('file', files![0]);

    const uploadDocParams = {
      accountId: modalData.account.id,
      agencyId: selectedAgency?.value ?? undefined,
      productType: modalData.account.productType,
      docType: selectedDocType,
      docName: (files![0] as { name: string }).name,
      temp: true,
    };

    return uploadDocumentV3({
      params: uploadDocParams,
      data: formData,
      ...axiosConfig,
    })
      .then(() => {
        enqueueSnackbar('Successfully uploaded document.', {
          variant: 'success',
        });
        queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
        close();
      })
      .catch(() => {
        enqueueSnackbar('Document upload failed. Please try again', {
          variant: 'error',
        });
      });
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    handleUploads({
      asyncUploadFunc: handleAsyncDocUpload,
      onFileUploadError: null,
      onFileUploadSuccess: null,
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <DialogContent className="contrast-text" style={{ padding: '2rem 5rem' }}>
        <Box style={{ fontSize: '1.2rem', textAlign: 'center' }}>
          If selected agency field is left blank, document will be <br />
          visible to all interested agencies.
        </Box>

        <Box style={{ padding: '0.5rem 8.5rem 1.5rem' }}>
          <label htmlFor="agencySelect">Select an Agency:</label>
          <Autocomplete
            options={interestedAgencies}
            getOptionLabel={getOptionLabel}
            onChange={handleAgencyChange}
            renderInput={(params) => (
              <TextField
                variant="standard"
                name="agencySelect"
                placeholder="Select an agency"
                {...params}
              />
            )}
          />
        </Box>

        <Box style={{ padding: '0.5rem 8.5rem 1.5rem' }}>
          <label htmlFor="docType">Select a Doc Type:</label>
          <Select
            value={selectedDocType}
            options={docTypes}
            onChange={handleDocTypeChange}
          />
        </Box>

        <ButtonContainer files={files}>
          <Box>
            <Uploadzone
              {...uploadZoneParms}
              accept={acceptedUploadDocs}
              files={files}
              hasPreview={Boolean(files)}
              Preview={DefaultFilePreview}
              Placeholder={<FullScreenDropzonePlaceholder />}
            />
          </Box>
        </ButtonContainer>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={close}>
          Cancel
        </CbButton>
        <CbButton
          type="submit"
          styleName="ctaButton"
          loading={uploadZoneParms.isUploading}
          disabled={uploadZoneParms.isUploading || !files}
        >
          Confirm
        </CbButton>
      </DialogActions>
    </form>
  );
};

export const DocumentUploadConfig = {
  DocumentUpload: {
    component: DocumentUpload,
    config: {
      title: 'Upload Document',
      maxWidth: 'md',
      minWidth: 'md',
    },
  },
};

interface UseDocTypesPropsDto {
  docTypesPayload: {
    claim?: boolean;
  };
}

const useDocTypes = ({ docTypesPayload }: UseDocTypesPropsDto) => {
  return useQuery({
    queryKey: ['doc-types', docTypesPayload],
    queryFn: () => getDocTypes(docTypesPayload),
    select: (resp: AxiosResponse) => transformDocTypesForSelect(resp.data),
  });
};

const transformDocTypesForSelect = (docTypesRaw: any) =>
  _.map(docTypesRaw, (v, k) => {
    return {
      label: k,
      value: v,
    };
  });
