import _ from 'lodash';
import React, { useMemo } from 'react';
import { Grid as MuiGrid, styled, TextField, Button } from '@mui/material';
import Numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { TooltipCheckbox } from '../../../components/inputs/TooltipCheckbox';
import { FakeBar } from './fakebar.chart';
import { Modal } from '../../../components/modals/v2/helpers/v2.modal.helpers';
import { setPrime100Ui } from '../../_reducers/prime100.reducer';
import { PubSub } from '../../../utils/eventUtils';

export const CoverageChart = ({
  data = {},
  editable = false,
  options,
  renderSublimit,
  renderRansomlimit,
  renderSocialLimit,
  renderSocialDeductible,
  renderWebsiteLimit,
  social,
  ransom,
  website,
  readOnly,
  endorsement = false,
  coverageEligibility,
}) => {
  const classes = styles({
    isRenewal: data.isRenewal,
    isMigration: data.isMigration,
  });
  const formLabelProps = useMemo(
    () => ({
      classes: { label: classes.checkbox },
    }),
    // eslint-disable-next-line
    []
  );
  const aggregateLimit = data.limit;
  const biee = data.businessIncomeCoverage / data.limit;
  const se = social ? data.socialEngLimit / data.limit : 0;
  const rp = ransom ? data.ransomPaymentLimit / data.limit : 0;
  const wm = website
    ? data.websiteMediaContentLiabilitySubLimit / data.limit
    : 0;
  const prime100Ui = useSelector(({ prime100 }) => prime100.ui);
  const dispatch = useDispatch();

  const handleAISubmit = useAdditionalInsuredSubmission({ dispatch });
  const eligibleOptions = useCoverageEligibility(coverageEligibility, options);

  return (
    <div className={classes.chartContainer}>
      <MuiGrid container>
        {eligibleOptions.map((option) => {
          // TODO Trevor, we need to clean this.
          if (option.id === 'BI_EXTRA_EXPENSE') {
            option.percentage = biee;
          } else if (option.id === 'SOCIAL_ENGINEERING') {
            option.percentage = _.isNaN(se) ? 0 : se;
          } else if (option.id === 'COMPUTER_FUNDS_TRANSFER_FRAUD') {
            option.defaultChecked = data.computerFraudEndorsement;
            if (data.computerFraudEndorsement) {
              option.percentage = 1;
            }
          } else if (option.id === 'RANSOM_PAYMENTS') {
            option.percentage = _.isNaN(rp) ? 0 : rp;
          } else if (option.id === 'WEBSITE_MEDIA_CONTENT_LIABILITY') {
            option.percentage = _.isNaN(wm) ? 0 : wm;
          }

          if (option.id === 'sublimit' && _.isFunction(renderSublimit)) {
            return renderSublimit({ classes });
          }
          if (option.id === 'socialLimit' && _.isFunction(renderSocialLimit)) {
            return renderSocialLimit({ classes });
          }
          if (
            option.id === 'socialDeductible' &&
            _.isFunction(renderSocialDeductible)
          ) {
            return renderSocialDeductible({ classes });
          }
          if (option.id === 'ransomLimit' && _.isFunction(renderRansomlimit)) {
            return renderRansomlimit({ classes });
          }
          if (
            option.id === 'websiteLimit' &&
            _.isFunction(renderWebsiteLimit)
          ) {
            return renderWebsiteLimit({ classes });
          }
          if (option.id === 'ADDITIONAL_INSURED' && !endorsement) {
            const AIcount = _.get(option.checked, 'length', 0);
            option.disabled = !!readOnly;

            return (
              <>
                <MuiGrid item sm={7}>
                  <TooltipCheckbox
                    {...option}
                    disabled={
                      readOnly && AIcount > 0
                        ? !option.disabled
                        : option.disabled || !editable
                    }
                    formLabelProps={formLabelProps}
                    checked={
                      readOnly
                        ? AIcount > 0
                        : option.checked ||
                          prime100Ui.additionalInsuredList.length
                    }
                  />
                </MuiGrid>
                <MuiGrid
                  container
                  item
                  sm={5}
                  justifyContent={readOnly ? 'flex-start' : 'space-evenly'}
                >
                  {!readOnly && (
                    <MuiGrid item sm={6}>
                      <AddEditButton
                        variant="outlined"
                        size="small"
                        onClick={Modal.deferShow('AdditionalInsuredModal', {
                          data: {
                            insureds: prime100Ui.additionalInsuredList,
                            onSubmit: handleAISubmit,
                          },
                        })}
                      >
                        ADD/EDIT
                      </AddEditButton>
                    </MuiGrid>
                  )}
                  <MuiGrid item sm={6} className="flex align-right">
                    <TextField
                      variant="standard"
                      name="additionalInsureds"
                      value={
                        readOnly
                          ? AIcount
                          : prime100Ui.additionalInsuredList.length
                      }
                      classes={{
                        root: classes.inputField,
                      }}
                      InputProps={{
                        classes: { input: classes.inputBase },
                      }}
                      disabled
                    />
                  </MuiGrid>
                </MuiGrid>
              </>
            );
          }

          if (option.id === 'ADDITIONAL_INSURED' && endorsement) {
            return null;
          }

          return (
            <MuiGrid container>
              <MuiGrid item sm={7} className={classes.gridItem}>
                <TooltipCheckbox
                  {...option}
                  formLabelProps={formLabelProps}
                  disabled={
                    readOnly && option.checked
                      ? !option.disabled
                      : option.disabled || !editable
                  }
                  onClick={
                    option.modalName &&
                    option.onClick &&
                    option.onClick.bind(this, option.modalName)
                  }
                  ref={
                    option.register && option.register({ name: option.name })
                  }
                  showRedTooltip={!readOnly && option.showRedTooltip}
                />
              </MuiGrid>

              <MuiGrid item sm={5} className={classes.gridItem}>
                <FakeBar
                  value={option.percentage * 100}
                  aggregate={data.limit}
                  percentage={option.percentage}
                  readOnly={readOnly}
                />
              </MuiGrid>

              <div className={classes.chartAxis}>
                <i
                  className={`${classes.axis} label-first label-left`}
                  data-label="0"
                />
                <i
                  className={`${classes.axis} label-last label-left`}
                  data-label={Numeral(aggregateLimit).format('0a')}
                />
              </div>
            </MuiGrid>
          );
        })}
      </MuiGrid>
    </div>
  );
};

const styles = makeStyles(({ config, palette }) => ({
  chartContainer: {
    position: 'relative',
  },

  chartAxis: {
    width: '41.666667%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
  },

  axis: {
    height: 'calc(100% - 1.5rem)',
    position: 'absolute',
    top: '-1.5rem',
    borderRight: '1px dashed #9492A4',
    fontSize: config.textSizes.petite,
    transition: 'left 300ms ease-out',

    '&:before': {
      content: 'attr(data-label)',
      position: 'absolute',
      lineHeight: 0.9,
      right: 5,
    },

    '&.label-first': {
      left: 0,
    },

    '&.label-last': {
      right: 0,
    },
  },

  checkbox: {
    fontSize: config.textSizes.normal,
  },

  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },

  inputBase: {
    padding: '0.35rem !important',
    height: '1rem',
    textAlign: 'center',
    background: (props) => {
      if (props.isRenewal || props.isMigration) {
        return `${palette.drawer.background} !important`;
      }
    },
  },
  inputField: {
    width: '4rem',
    height: '2rem',
    textAlign: 'center',
    fontSize: '1.167rem',
    color: palette.primary.main,
    marginTop: '0.1rem',
  },
}));

const AddEditButton = styled(Button)(({ theme: { palette } }) => ({
  color: palette.primary.contrastText,
  borderColor: palette.common.cowbellBlue,
}));

function useAdditionalInsuredSubmission({ dispatch }) {
  return function handleSubmit({ insureds }) {
    return new Promise((resolve) => {
      dispatch(
        setPrime100Ui({
          additionalInsuredList: insureds,
        })
      );

      PubSub.publish('change:AI', insureds.length);

      resolve();
    });
  };
}

const useCoverageEligibility = (coverageEligibility, options) => {
  if (!coverageEligibility) {
    return options;
  }

  const {
    computerFundsTransfer,
    hardwareReplCostEndorsement,
    postBreachRemediationEndorsement,
    ransomPaymentEndorsement,
    socialEngEndorsement,
    telecomsFraudEndorsement,
    websiteMediaContentLiabilityEndorsement,
  } = coverageEligibility;

  return options.map((option) => {
    switch (option.id) {
      case 'COMPUTER_FUNDS_TRANSFER_FRAUD':
        return { ...option, disabled: !computerFundsTransfer.isAvailable };

      case 'SOCIAL_ENGINEERING':
        return { ...option, disabled: !socialEngEndorsement.isAvailable };

      case 'WEBSITE_MEDIA_CONTENT_LIABILITY':
        return {
          ...option,
          disabled: !websiteMediaContentLiabilityEndorsement.isAvailable,
        };

      case 'HARDWARE_REPLACEMENT_COSTS':
        return {
          ...option,
          disabled: !hardwareReplCostEndorsement.isAvailable,
        };

      case 'POST_BREACH_REMEDIATION':
        return {
          ...option,
          disabled: !postBreachRemediationEndorsement.isAvailable,
        };

      case 'RANSOM_PAYMENTS':
        return { ...option, disabled: !ransomPaymentEndorsement.isAvailable };

      case 'TELECOM_FRAUD':
        return { ...option, disabled: !telecomsFraudEndorsement.isAvailable };

      default:
        return option;
    }
  });
};
