import { Box, Checkbox, FormControlLabel } from '@mui/material';
import type { DefaultTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';

import color from 'color';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SimpleTooltip from '../../../../../../components/SimpleTooltip';

interface CheckBoxWrapperProps {
  description?: string;
  children: React.ReactElement;
}

const CheckBoxWrapper = ({ description, children }: CheckBoxWrapperProps) => {
  if (description) {
    return (
      <SimpleTooltip title={description} enterDelay={500} enterNextDelay={500}>
        {children}
      </SimpleTooltip>
    );
  }

  return <>{children}</>;
};

const InfoIcon = ({ description }: { description?: string }) => {
  if (description) {
    return (
      <InfoOutlinedIcon
        style={{
          marginBottom: '-0.3rem',
          marginLeft: '0.35rem',
          fontSize: '1.2rem',
          pointerEvents: 'none',
        }}
      />
    );
  }

  return null;
};

interface LabelProp {
  label?: string | JSX.Element;
  description?: string;
}

interface DisabledProp {
  disabled?: boolean;
}

const Mandatory = ({ label, description }: LabelProp) => {
  const classes = useClasses({ isMandatory: true });

  const formControlLabelClasses = useFormControlClasses({});
  return (
    <CheckBoxWrapper description={description}>
      <Box style={{ pointerEvents: 'none' }}>
        <FormControlLabel
          classes={formControlLabelClasses}
          control={<Checkbox checked classes={classes} color="secondary" />}
          label={
            <>
              {label ?? 'mandatory'}
              <InfoIcon description={description} />
            </>
          }
        />
      </Box>
    </CheckBoxWrapper>
  );
};

const Selected = ({
  label,
  disabled,
  description,
}: LabelProp & DisabledProp) => {
  const classes = useClasses({});
  const formControlLabelClasses = useFormControlClasses({});

  return (
    <CheckBoxWrapper description={description}>
      <Box style={disabled ? { pointerEvents: 'none' } : {}}>
        <FormControlLabel
          classes={formControlLabelClasses}
          control={<Checkbox color="secondary" checked classes={classes} />}
          label={
            <>
              {label ?? 'selected'}
              <InfoIcon description={description} />
            </>
          }
        />
      </Box>
    </CheckBoxWrapper>
  );
};

const Available = ({
  label,
  disabled,
  description,
}: LabelProp & DisabledProp) => {
  const classes = useClasses({});
  const formControlLabelClasses = useFormControlClasses({});
  return (
    <CheckBoxWrapper description={description}>
      <Box style={disabled ? { pointerEvents: 'none' } : {}}>
        <FormControlLabel
          classes={formControlLabelClasses}
          control={<Checkbox classes={classes} color="secondary" />}
          label={
            <>
              {label ?? 'available'}
              <InfoIcon description={description} />
            </>
          }
        />
      </Box>
    </CheckBoxWrapper>
  );
};

const NotAvailable = ({ label, description }: LabelProp) => {
  const classes = useClasses({});
  const formControlLabelClasses = useFormControlClasses({});
  return (
    <CheckBoxWrapper description={description}>
      <Box style={{ pointerEvents: 'none' }}>
        <FormControlLabel
          disabled
          classes={formControlLabelClasses}
          control={
            <Checkbox color="primary" classes={classes} checked={false} />
          }
          label={
            <>
              {label || 'not available'}
              <InfoIcon description={description} />
            </>
          }
        />
      </Box>
    </CheckBoxWrapper>
  );
};

export default {
  Mandatory,
  Selected,
  Available,
  NotAvailable,
} as const;

type UseClassesProps = { isMandatory?: boolean };
const useClasses = makeStyles<DefaultTheme, UseClassesProps>(({ palette }) => {
  return {
    root: {
      padding: '0 0.5rem 0 0.75rem',
      color: (props) =>
        getCheckboxColor({
          palette,
          isMandatory: props.isMandatory,
        }) as string,

      '&.Mui-checked': {
        color: (props: UseClassesProps) =>
          getCheckboxColor({
            palette,
            isMandatory: props.isMandatory,
          }) as string,
      },
    },
  };
});

const getCheckboxColor = ({
  palette,
  isMandatory,
}: {
  palette: any;
  isMandatory: boolean | undefined;
}) => {
  if (isMandatory && palette.mode === 'light') {
    return color(palette.primary.main).darken(0.2).toString();
  }
  if (isMandatory && palette.mode === 'dark') {
    return palette.primary.contrastText;
  }
  return palette.primary.cowbellBlue;
};

type UseFormControlClassesProps = { disabled?: boolean };
const useFormControlClasses = makeStyles<
  DefaultTheme & UseFormControlClassesProps
>(({ palette, disabled }) => ({
  label: {
    color: disabled ? palette.text.disabled : palette.primary.main,
    textTransform: 'capitalize',
  },
}));
