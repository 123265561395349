// mui
import { Box, Divider } from '@mui/material';

// components
import { Flexbox } from '../../../../../../components/layout/Flexbox';

// utils
import { PolicyDrawerTypography as Typography } from '../policy-details.utils';
import type { PolicyTableInfoDto } from '../../../../../../types/policy/tables/policy-table-info.dto';
import { useCowbellTranslations } from '../../../../../../i18n/translations';
import { truncateString } from '../../../../../../utils';
import SimpleTooltip from '../../../../../../components/SimpleTooltip';

interface Props {
  policy: PolicyTableInfoDto;
}

export const OrganizationBrokerWithBOR = ({ policy }: Props) => {
  const { t, translationKeys } = useCowbellTranslations();

  const previousAgencyName = truncateString(policy.agencyName, 20);

  const currentAgencyName = truncateString(
    policy.brokerOfRecord?.agencyName ?? '-',
    20
  );

  return (
    <>
      <Box>
        <Typography variant="subtitle2" color="primary">
          {t(translationKeys.agent).toUpperCase()} INFO
        </Typography>
      </Box>
      <Divider style={{ width: '100%', margin: '0.5rem 0 1rem 0' }} />
      <Box display="flex">
        <Box width="50%" marginRight={1}>
          <Flexbox>
            <Typography>Previous {t(translationKeys.agency)}</Typography>
            <Box>
              <SimpleTooltip title={policy.agencyName}>
                <Typography>{previousAgencyName}</Typography>
              </SimpleTooltip>
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Previous {t(translationKeys.agent)} Name</Typography>
            <Box>
              <Typography>{`${policy.agentFirstName} ${policy.agentLastName}`}</Typography>
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Previous {t(translationKeys.agent)} Email</Typography>
            <Box>
              <Typography
                component="a"
                href={`mailto:${policy.agentEmail}`}
                className="link-underlined--hover"
              >
                {policy.agentEmail}
              </Typography>
            </Box>
          </Flexbox>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box width="50%" marginLeft={1}>
          <Flexbox>
            <Typography>Current {t(translationKeys.agency)}</Typography>
            <Box>
              <SimpleTooltip title={policy.brokerOfRecord?.agencyName}>
                <Typography>{currentAgencyName}</Typography>
              </SimpleTooltip>
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Current {t(translationKeys.agent)} Name</Typography>
            <Box>
              <Typography>{`${policy.brokerOfRecord?.agentFirstName} ${policy.brokerOfRecord?.agentLastName}`}</Typography>
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Current {t(translationKeys.agent)} Email</Typography>
            <Box>
              <Typography
                component="a"
                href={`mailto:${policy.brokerOfRecord?.agentEmail}`}
                className="link-underlined--hover"
              >
                {policy.brokerOfRecord?.agentEmail}
              </Typography>
            </Box>
          </Flexbox>
        </Box>
      </Box>
    </>
  );
};
