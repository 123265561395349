import React, { useEffect } from 'react';
import { compose } from 'redux';
import { FormContext, useForm } from 'react-hook-form-4';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
// mui
import {
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  Box,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// components
import { useQueryClient } from '@tanstack/react-query';
import { withUploadFeature } from '../fileUploader/withUploadFeature';
import { withUploadFeatureApplications } from '../fileUploader/withUploadFeatureApplications';
import CBButton from '../Buttons/CbButton';
// utils
import { PubSub } from '../../utils/eventUtils';

// actions
import { uploadAccountDocument } from '../../accounts/AccountService';
import { uploadDocumentV2 } from '../../api/DocumentsService';
import { MultiSelect } from '../Selects/index';
import { ProductTypes } from '../../types';
// constants
import { AGENCY_ACCOUNTS, COWBELL_ACCOUNTS } from '../tables/table_constants';

import { useActor } from '../hooks/useActor';
import { withToggleModal } from '../hocs/withToggleModal';

const ApplicationUpload = compose(
  withToggleModal,
  withUploadFeatureApplications(),
  withUploadFeature({
    screenCenter: false,
    hide: false,
    acceptFileTypes: `image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf`,
  })
)(({ classes, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState('');
  const [fileDetails, setFileDetails] = React.useState({});
  const [interestedAgencies, setInterestedAgencies] = React.useState([]);

  const actor = useActor();

  const { handleSubmit, ...methods } = useForm({});
  const {
    register,
    getValues,
    setValue,
    formState: { isSubmitting },
  } = methods;
  const values = getValues();
  const queryClient = useQueryClient();

  useEffect(() => {
    const sub = PubSub.subscribe(
      'withUploadFeatureApplications:selectedFile',
      (eventData) => {
        if (eventData) {
          setFile(eventData);
          const splitString = eventData.name.split('.');
          const extension = splitString.pop();
          const fileName = splitString.join('.');
          setFileDetails({ extension, fileName });
          setError('');
        } else {
          setError('Unsupported file type.');
        }
      }
    );
    const agencies = _.get(data, 'agencies', []);
    const agencyMapping = agencies.map((agency) => {
      return {
        label: agency.agencyName,
        value: agency.agencyId,
      };
    });
    setInterestedAgencies([
      { label: 'All', value: undefined },
      ...agencyMapping,
    ]);
    return () => {
      sub.remove();
    };
  }, [data, file]);

  const downloadTemplate = React.useCallback(() => {
    const productAppResourceMap = {
      [ProductTypes.p250]: {
        path: 'https://assets.cowbellcyber.ai/Cowbell-Cyber-Prime-250-Main-Application.pdf',
        downloadName: 'Cowbell-Cyber-Prime-250-Main-Application.pdf',
      },
      [ProductTypes.p100]: {
        path: 'https://assets.cowbellcyber.ai/Cowbell_Cyber_Prime_100_Application_Quotes_Only.pdf',
        downloadName: 'Cowbell-Cyber-Prime-100-Application-Quotes-Only.pdf',
      },
    };

    const resource =
      productAppResourceMap?.[_.get(data, 'productType', ProductTypes.p100)];

    if (resource == null) {
      enqueueSnackbar('Could not find application for this product type', {
        variant: 'error',
      });
    }

    const link = document.createElement('a');
    link.href = resource.path;
    link.download = resource.downloadName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // eslint-disable-next-line
  }, []);

  const onSubmit = ({ agencyId }) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    return uploadDocumentV2(
      {
        accountId: data.id,
        docType: 'Application',
        docName: fileDetails.fileName,
        temp: true,
        agencyId,
      },
      bodyFormData
    )
      .then((resp) => {
        const { docId, docName, docType, fileType } = resp.data;
        const payload = {
          accountId: data.id,
          otherApp: { docId, docName, docType, fileType },
          productType: data.productType,
        };
        uploadAccountDocument({}, payload)
          .then(() => {
            enqueueSnackbar('File uploaded successfully!', {
              variant: 'success',
            });
            queryClient.invalidateQueries([AGENCY_ACCOUNTS]);
            queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
            this.props.toggleModal.direct('ApplicationUpload', false);
          })
          .catch(() => {
            enqueueSnackbar('Failed to upload the file!', { variant: 'error' });
            this.props.toggleModal.direct('ApplicationUpload', false);
          });
      })
      .catch(() => {
        enqueueSnackbar('Failed to upload the file!', { variant: 'error' });
        this.props.toggleModal.direct('ApplicationUpload', false);
      });
  };

  const uploadDocumentHandler = React.useCallback((event) => {
    PubSub.publish('openFileUploadFeature', event);
  }, []);

  const cancelSelectedFile = React.useCallback(() => {
    setFile(null);
  }, []);

  const handleCancel = React.useCallback(() => {
    this.props.toggleModal.direct('ApplicationUpload', false);
  }, []);

  const handleSelectAgency = React.useCallback(
    (name, selectedOption) => {
      setValue('agencyId', selectedOption.value);
    },
    [setValue]
  );

  return (
    <section className={classes.container}>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={classes.container}>
            {actor.isCowbell && (
              <>
                <DialogContentText>
                  If Select Agency field is left blank, document will be visible
                  to all interested agencies.
                </DialogContentText>
                <Box m={3} />
                <Grid item md={12}>
                  <Grid container justifyContent="left">
                    <Grid item md={4}>
                      <DialogContentText>Select Agency:</DialogContentText>
                    </Grid>
                    <Grid item md={8}>
                      <MultiSelect
                        ref={register({ name: 'agencyId' })}
                        required
                        label=""
                        options={interestedAgencies}
                        onChange={handleSelectAgency}
                        values={values.agency}
                        isMulti={false}
                        data-qa="agencyId"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box m={3} />
              </>
            )}
            <section className={classes.bodyContainer}>
              {file ? (
                <section className={classes.selectedFileContainer}>
                  <p className={classes.selectedFile}>
                    SELECTED FILE: {file.name}
                  </p>
                  <Box
                    component="img"
                    alt=""
                    src="images/cancel.svg"
                    className={classes.iconCancel}
                    onClick={cancelSelectedFile}
                  />
                </section>
              ) : (
                <Box
                  className={classes.dragDropContainer}
                  onClick={uploadDocumentHandler}
                >
                  <p className={classes.dragDropText}>
                    Drag and Drop a file here or Click to Upload
                  </p>
                  <img
                    alt=""
                    src="images/cloud-upload.svg"
                    className={classes.icon1}
                  />
                </Box>
              )}
              <p className={classes.supportedFiles}>
                Supported File Types: PDF, DOC, JPEG & PNG
              </p>
              <section className={classes.downloadApplication}>
                <p className={classes.downloadTemplateText}>
                  Cowbell Application:
                  <Box
                    component="span"
                    className={classes.downloadTemplate}
                    onClick={downloadTemplate}
                  >
                    Download
                  </Box>
                </p>
                <img
                  alt=""
                  src="images/Download.svg"
                  className={classes.icon}
                />
              </section>
              {error ? <p>{error}</p> : null}
            </section>
          </DialogContent>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || !file}
              styleName="ctaButton"
              buttonText="Submit"
            />
          </DialogActions>
        </form>
      </FormContext>
    </section>
  );
});

const styles = ({ palette }) => {
  return {
    iconCancel: {
      width: '2rem',
      height: '2rem',
      margin: '1.3rem 0 0 0.5rem',
      cursor: 'pointer',
    },
    selectedFile: {
      fontSize: '1.33rem',
      lineHeight: 1.38,
      color: palette.primary.main,
      marginBottom: '0 !important',
    },
    selectedFileContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    dragDropText: {
      width: '10.67rem',
      fontSize: '1rem',
      lineHeight: 1.33,
      color: palette.primary.main,
    },
    dragDropContainer: {
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      height: '5.833rem',
      width: '25rem',
      borderRadius: '5px',
      border: `1px solid ${palette.primary.main}`,
      cursor: 'pointer',
    },
    container: {
      width: '50rem',
      backgroundColor: palette.background.active,
    },
    bodyContainer: {
      height: 'fit-content',
      textAlign: 'center',
    },
    downloadApplication: {
      borderTop: `1px solid ${palette.primary.modalBorder}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      fontSize: '1.333rem',
    },
    supportedFiles: {
      fontSize: '1.167rem',
      lineHeight: 1.5,
      color: palette.text.secondary,
      marginBottom: 20,
    },
    icon: {
      width: '1.5rem',
      height: '1.25rem',
      margin: '1.6rem 0 0 1rem',
    },
    icon1: {
      width: '5rem',
      height: '5rem',
      margin: '0 0 0 1rem',
    },
    downloadTemplate: {
      'text-decoration': 'underline',
      fontWeight: 600,
      cursor: 'pointer',
      color: palette.primary.main,
      marginLeft: '0.5rem',
    },
    rowName: {
      color: 'red',
      lineHeight: 1.38,
      padding: '0.5rem 0',
    },
  };
};

export default withStyles(styles)(ApplicationUpload);
