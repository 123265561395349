import React from 'react';

import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getYesNoLabel } from '../../../utils/appUtils';

export const SecurityInfo = ({ parentClasses, firmographicData = {} }) => {
  const classes = useStyles();

  return (
    <>
      <h3 className={`contrast-text ${classes.heading}`}>
        SECURITY ASSESSMENT
      </h3>
      <Card className={parentClasses.card}>
        <ul className={`list--unstyled ${classes.securityQuestions}`}>
          {Object.keys(securityQuestions).map((skey) => (
            <li>
              {securityQuestions[skey].question}{' '}
              <span>{getYesNoLabel(firmographicData[skey])}</span>
            </li>
          ))}
        </ul>

        <h4 className="tertia-text">
          <i>Social Engineering Elibility Questions</i>
        </h4>
        <ul className={`list--unstyled ${classes.securityQuestions}`}>
          {Object.keys(socialEngineeringQuestions).map((sekey) => (
            <li>
              {socialEngineeringQuestions[sekey].question}{' '}
              <span>{getYesNoLabel(firmographicData[sekey])}</span>
            </li>
          ))}
        </ul>
      </Card>
    </>
  );
};

const useStyles = makeStyles(({ config }) => ({
  securityQuestions: {
    '& li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '2.0833333333rem',
    },

    '& li:last-of-type': {
      marginBottom: '0',
    },
  },
  heading: {
    fontWeight: config.weights.bold,
    lineHeight: 1.38,
  },
}));

export const securityQuestions = {
  claimHistory: {
    question: 'Have you ever had any cyber insurance claim(s)?',
  },
  isSecurityOfficer: {
    question:
      'Does your organization have a Designated Information Security Contact?',
  },
  isSecurityTraining: {
    question:
      'If not, are you willing to implement it during the policy period?',
  },
  useEncryption: {
    question:
      'Does your organization encrypt all emails, mobile and computing devices containing sensitive information (e.g., PII, PHI, PCI) sent to external parties?',
  },
  useCloudStorage: {
    question:
      'Does your organization have sensitive information stored on the cloud?',
  },
};

const socialEngineeringQuestions = {
  isVerifyingBankAccounts: {
    question:
      'Do you or your employees verify vendor/supplier bank accounts before adding to your accounts payable systems?',
  },
  isAuthenticatingFundTransferRequests: {
    question:
      'Do you or your employees authenticate funds transfer requests (e.g. by calling a customer to verify the request at a predetermined phone number)?',
  },
  isPreventingUnauthorizedWireTransfers: {
    question:
      'Do you or your employees prevent unauthorized employees from initiating wire transfers?',
  },
};
