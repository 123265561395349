import Showable from '../../../../components/Showable';

import { AGENCY_DASHBOARD_LISTING_TABS } from '../agency-dashboard.statics';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import { AgencyDashboardClientsTab } from './clients/AgencyDashboardClientsTab';
import { AgencyDashboardPoliciesTab } from './policies/AgencyDashboardPoliciesTab';
import { AgencyDashboardQuotesTab } from './quotes/AgencyDashboardQuotesTab';

export const AgencyDashboardListing = () => {
  const { currentTab } = useAgencyDashboardState();

  return (
    <>
      <Showable show={currentTab === AGENCY_DASHBOARD_LISTING_TABS.QUOTES}>
        <AgencyDashboardQuotesTab />
      </Showable>
      <Showable show={currentTab === AGENCY_DASHBOARD_LISTING_TABS.BINDERS}>
        <AgencyDashboardPoliciesTab />
      </Showable>
      <Showable show={currentTab === AGENCY_DASHBOARD_LISTING_TABS.POLICIES}>
        <AgencyDashboardPoliciesTab />
      </Showable>
      <Showable show={currentTab === AGENCY_DASHBOARD_LISTING_TABS.ACCOUNTS}>
        <AgencyDashboardClientsTab />
      </Showable>
    </>
  );
};
