import RFS from '../console/_statics/riskfactors.statics';
import { getCowbellTranslations } from '../i18n/translations';
import type { GenericJsonApiSpecResponse } from '../types';

const { t, translationKeys } = getCowbellTranslations();

export const CowbellFactorsMapping: { [key: string]: any } = {
  companyAggregate: {
    label: 'Company Aggregate',
    tooltip: `${RFS.companyAggregateCF.description}`,
    color: '#2180e2',
    dataColor: '#2180e2',
    remainingColor: '#b4d4f5',
  },
  industryAggregate: {
    label: 'Industry Aggregate',
    tooltip: `${RFS.industryAggregateCF.description} This score ranges from 0 to 100, 100 being the best. An industry with a score of 80 represents less risk than one with a score of 56.`,
    color: '#6ac52a',
    dataColor: '#6ac52a',
    remainingColor: '#bee6a2',
  },
  networkSecurity: {
    color: '#6baedf',
    dataColor: '#6baedf',
    remainingColor: '#b4d4f5',
    label: 'Network Security',
    subtext: `Measures the strength of the ${t(
      translationKeys.organization
    ).toLowerCase()}'s network infrastructure`,
    tooltip: `${RFS.networkSecurityCF.description} This score ranges from 0 to 100, 100 being the highest.`,
  },
  cloudSecurity: {
    color: '#4dc8e8',
    dataColor: '#4dc8e8',
    remainingColor: '#b4d4f5',
    label: 'Cloud Security',
    subtext: `${t(
      translationKeys.organization
    )}'s cloud security risk and use of public cloud / storage`,
    tooltip: `${RFS.cloudSecurityCF.description} This score ranges from 0 to 100, 100 being the highest.`,
  },
  endpointSecurity: {
    color: '#83cebb',
    dataColor: '#83cebb',
    remainingColor: '#b4d4f5',
    label: 'Endpoint Security',
    subtext:
      'Endpoint (servers, mobile, IoT) vulnerabilities & security measures implemented',
    tooltip: `${RFS.endpointSecurityCF.description} The score ranges from 0 to 100, 100 representing the highest level of preparedness.`,
  },
  darkIntelligence: {
    color: '#0098db',
    dataColor: '#0098db',
    remainingColor: '#b4d4f5',
    label: 'Dark Intelligence',
    subtext: `${t(
      translationKeys.organization
    )}'s exposure to darkweb (type and volume of data)`,
    tooltip: `${RFS.darkIntelligenceCF.description} This score ranges from 0 to 100, 100 representing the least amount of exposure.`,
  },
  fundsTransfer: {
    color: '#7581e5',
    dataColor: '#7581e5',
    remainingColor: '#b4d4f5',
    label: 'Funds Transfer',
    subtext: 'Risk markers related to spear phishing (email, SMS, web)',
    tooltip: `${RFS.fundsTransferCF.description} This score ranges from 0 to 100, 100 representing the lowest level of risk.`,
  },
  cyberExtortion: {
    color: '#91afd9',
    dataColor: '#91afd9',
    remainingColor: '#b4d4f5',
    label: 'Cyber Extortion',
    subtext:
      'Potential exposure to extortion-related attacks such as ransomware',
    tooltip: `${RFS.cyberExtortionCF.description} This score ranges from 0 to 100, 100 representing the lowest level of exposure.`,
  },
  compliance: {
    color: '#00aea0',
    dataColor: '#00aea0',
    remainingColor: '#b4d4f5',
    label: 'Compliance',
    subtext:
      'Level of compliance to security standards (such as NIST, CSC-20, CIS)',
    tooltip: `${RFS.complianceCF.description} This score ranges from 0 to 100, 100 being the best.`,
  },
  supplyChain: {
    label: 'Supply Chain',
    tooltip: `Measures an ${t(
      translationKeys.organization
    ).toLowerCase()}'s susceptibility to software supply chain incidents. This factor is compiled from technographic and firmographic data, web scraping information and public exploits and vulnerability repositories. This score ranges from 0 to 100, 100 being the best. An industry with a score of 80 represents less risk than one with a score of 56.`,
    subtext: 'Visibility of risk exposure related to digital supply chain',
    color: '#00aea0',
    dataColor: '#00aea0',
    remainingColor: '#b4d4f5',
  },
};

export default CowbellFactorsMapping;

export function mapCFKeys({ data }: GenericJsonApiSpecResponse<any>) {
  const transformedDetails = Object.entries(data.details).reduce(
    (acc, currEntry) => {
      const [key, val] = currEntry;

      let newKey: string;
      switch (key) {
        case 'company':
          newKey = 'companyAggregate';
          break;
        default:
          newKey = key;
          break;
      }

      return { ...acc, [newKey]: val };
    },
    {}
  );

  return { ...data, details: transformedDetails };
}
