import React from 'react';
// components
import { FormContext, useForm } from 'react-hook-form-4';
import _ from 'lodash';
import * as Yup from 'yup';
import { DialogContent, DialogActions, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { withFormController } from '../../components/hocs/withFormController';
import CBButton from '../../components/Buttons/CbButton';
import { toggleModalDirect } from '../../utils/storeUtils';
import { securityStudioConnect } from './ConnectorService';
import { PubSub } from '../../utils/eventUtils';

// helpers

const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  token: Yup.string().required('Token is required'),
});

const SecurityStudioTokenForm = ({ classes, data }) => {
  const { cbid } = data;
  const defaultValues = {
    token: '',
  };

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    validationSchema: schema,
  });
  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (formData) => {
    const payload = {};
    return securityStudioConnect({ token: formData.token, cbid }, payload)
      .then(() => {
        toggleModalDirect('SecurityStudioTokenForm', false);
        PubSub.publish('connector-page-refetch', true);
      })
      .catch(console.error.bind(console));
  };

  const handleCancel = () => {
    toggleModalDirect('SecurityStudioTokenForm', false);
  };

  return (
    <section className={classes.cardContentContainer}>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className={classes.description}>
            Get the refresh token for your account from securitystudio.com
          </p>
          <DialogContent classes={{ root: classes.container }}>
            <Grid
              container
              justifyContent="left"
              className={classes.inputFields}
            >
              <Grid item md={10}>
                <TextField
                  error={_.get(methods, 'errors.token.message')}
                  name="token"
                  label="Enter Token"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Connect"
            />
          </DialogActions>
        </form>
      </FormContext>
    </section>
  );
};

const styles = ({ palette }) => {
  return {
    description: {
      fontSize: '1.167rem',
      color: palette.primary.main,
      lineHeight: 1.5,
      fontStyle: 'italic',
      textAlign: 'center',
      margin: '0 0 2rem 0 !important',
    },
    container: {
      flex: '1 1 auto',
      padding: '0 5rem 0.5rem 5rem',
      'overflow-y': 'visible',
    },
    inputFields: {
      justifyContent: 'space-around',
    },
  };
};

export default withStyles(styles)(SecurityStudioTokenForm);
