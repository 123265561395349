import React from 'react';
import { SvgIcon } from '@mui/material';

export const WarningTriangleIcon = ({ fill = 'salmon', ...props }) => {
  return (
    <SvgIcon
      data-name="Download Application"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="28.235"
      viewBox="0 0 32 28.235"
      {...props}
    >
      <path
        data-name="Polygon 54"
        d="M15.13 1.535a1 1 0 0 1 1.74 0l14.284 25.207a1 1 0 0 1-.87 1.493H1.716a1 1 0 0 1-.87-1.493z"
        style={{ fill }}
      />
      <path
        data-name="Path 3052"
        d="M12.262 20.2h-3.08v-2.968h3.08zm0-5.944h-3.08v-5.94h3.08z"
        transform="translate(5.406 3.571)"
        style={{ fill: '#26262e' }}
      />
    </SvgIcon>
  );
};
