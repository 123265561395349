import React from 'react';
import * as Yup from 'yup';
// mui
import { Grid as MuiGrid } from '@mui/material';
// components
import { withFormController } from '../../../../../../components/hocs/forms';
import { TextFieldBase } from '../../../../../../components/inputs/TextFieldBase';
import LanguageCurrencyFieldBase from '../../../../../../components/inputs/LanguageCurrencyField';
// actions
const TextField = withFormController(TextFieldBase);
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);

const nameValidationKeys = (value) => {
  return ['Drop-Down', 'Non-Follow Form'].includes(value);
};

const limitValidationKeys = (value) => {
  return ['Drop-Down', 'Schedule of Underlying Insurance Endorsement'].includes(
    value
  );
};

export const dropDownFormValidation = {
  name: Yup.string().when('endorsement', {
    is: nameValidationKeys,
    then: Yup.string().label('Name').required(),
  }),
  limit: Yup.number()
    .fromCurrency()
    .when('endorsement', {
      is: limitValidationKeys,
      then: Yup.number()
        .fromCurrency()
        .label('Limit')
        .min(0, 'Limit must be at least 1 characters')
        .required('Limit is required'),
    }),
};

export const DropdownForm = () => {
  return (
    <>
      <MuiGrid container spacing={5}>
        <MuiGrid item md={12} style={{ paddingTop: 0 }}>
          <TextField required name="name" label="Name" fullWidth />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container spacing={5}>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <LanguageCurrencyField required label="Limit" name="limit" />
        </MuiGrid>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <LanguageCurrencyField
            required
            label="Attachment"
            name="attachment"
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
