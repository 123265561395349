import _ from 'lodash';
import moment from 'moment';

const DEFAULT_ORDINAL = 5; // Last 7 Days

export const RangeTypes = [
  {
    name: 'Last 15 Minutes',
    ordinal: 0,
  },
  {
    name: 'Last 60 Minutes',
    ordinal: 1,
  },
  {
    name: 'Last 4 Hours',
    ordinal: 2,
  },
  {
    name: 'Today',
    ordinal: 3,
  },
  {
    name: 'Last 24 Hours',
    ordinal: 4,
  },
  {
    name: 'Last 7 Days',
    ordinal: 5,
  },
  {
    name: 'This Month',
    ordinal: 6,
  },
  {
    name: 'Last 30 Days',
    ordinal: 7,
  },
  {
    name: 'Last 90 Days',
    ordinal: 8,
  },
];

export function graphTickFormatter(range, from, to) {
  if (range <= 4) {
    return (tick) => moment(tick).local().format('h:mm a');
  }

  if (range === 9) {
    return (tick) => moment.utc(tick).format("MMM 'YY");
  }

  if (!_.isNil(from) && !_.isNil(to)) {
    const mFrom = moment.unix(from);
    const mTo = moment.unix(to);
    const dayDiff = Math.abs(moment.duration(mFrom.diff(mTo)).as('days'));

    if (dayDiff === 0) {
      return (tick) => moment.utc(tick).local().format('h:mm a');
    }

    if (dayDiff <= 2) {
      return (tick) => moment.utc(tick).local().format('MMM D h:mm a');
    }

    return (tick) => moment.utc(tick).local().format("MMM D 'YY");
  }

  return (tick) => moment.utc(tick).format("MMM D 'YY");
}

// after/before are the query window to the server.
// start/end are the window of time the graph shows.
export function calculateDuration(ordinal) {
  const after = moment();
  const before = moment();

  switch (Number(ordinal)) {
    case 0: // Last 15 minutes
      return {
        after: after.startOf('minute').subtract(15, 'minutes'),
        before: before.endOf('minute'),
        start: after.clone(),
        end: before.clone().startOf('minute'),
      };

    case 1: // Last 60 minutes
      return {
        after: after.startOf('minute').subtract(60, 'minutes'),
        before: before.endOf('minute'),
        start: after.clone(),
        end: before.clone().startOf('minute'),
      };

    case 2: // Last 4 hours
      return {
        after: after.startOf('hour').subtract(4, 'hours'),
        before: before.endOf('hour'),
        start: after.clone(),
        end: before.clone().startOf('hour'),
      };

    case 3: // Today
      return {
        after: after.startOf('day'),
        before: before.endOf('hour'),
        start: after.clone(),
        end: before.clone().startOf('hour'),
      };

    case 4: // Last 24 hours
      return {
        after: after.startOf('hour').subtract(24, 'hours'),
        before: before.endOf('hour'),
        start: after.clone(),
        end: before.clone().startOf('hour'),
      };

    case 6: // This month
      return {
        after: after.startOf('month'),
        before: before.endOf('hour'),
        start: after.clone(),
        end: before.clone().startOf('hour'),
      };

    case 7: // Last 30 days
      return {
        after: after.startOf('day').subtract(30, 'days'),
        before: before.endOf('hour'),
        start: after.clone(),
        end: before.clone().startOf('day'),
      };

    case 8: // Last 90 days
      return {
        after: after.startOf('day').subtract(90, 'days'),
        before: before.endOf('hour'),
        start: after.clone(),
        end: before.clone().startOf('day'),
      };

    case DEFAULT_ORDINAL: // Last 7 days
    default:
      // Last 7 days
      return {
        after: after.startOf('day').subtract(7, 'days'),
        before: before.endOf('hour'),
        start: after.clone(),
        end: before.clone().startOf('day'),
      };
  }
}

export function checkAndSetDuration(duration) {
  if (isValidDuration(duration)) {
    return DEFAULT_ORDINAL;
  }
  return Number(duration);
}

export function generateEmptyThreatRange(ordinal) {
  const result = [];
  let m = moment();
  switch (ordinal) {
    case 0: // Last 15 minutes
      m.startOf('minute').subtract(15, 'minutes');
      for (let x = 0; x <= 15; x += 1) {
        result.push(makeEmptyThreatAggregate(m));
        m.add(1, 'minutes');
      }
      break;

    case 1: // Last 60 minutes
      m.startOf('minute').subtract(60, 'minutes');
      for (let x = 0; x <= 60; x += 1) {
        result.push(makeEmptyThreatAggregate(m));
        m.add(1, 'minutes');
      }
      break;

    case 2: // Last 4 hours
      m.startOf('hour').subtract(4, 'hours');
      for (let x = 0; x <= 4; x += 1) {
        result.push(makeEmptyThreatAggregate(m));
        m.add(1, 'hours');
      }
      break;

    case 3: // Today
      m.startOf('day');
      // Use local time to count how many hours to show
      for (let x = 0; x <= moment().get('hour'); x += 1) {
        result.push(makeEmptyThreatAggregate(m));
        m.add(1, 'hours');
      }
      break;

    case 4: // Last 24 hours
      m.startOf('hour').subtract(24, 'hours');
      for (let x = 0; x <= 24; x += 1) {
        result.push(makeEmptyThreatAggregate(m));
        m.add(1, 'hours');
      }
      break;

    case 6: // This month
      m = moment.utc();
      m.startOf('month');
      for (let x = 1; x <= moment.utc().get('date'); x += 1) {
        result.push(makeEmptyThreatAggregate(m));
        m.add(1, 'days');
      }
      break;

    case 7: // Last 30 days
      m = moment.utc();
      m.startOf('day').subtract(30, 'days');
      for (let x = 0; x <= 30; x += 1) {
        result.push(makeEmptyThreatAggregate(m));
        m.add(1, 'days');
      }
      break;

    case 8: // Last 90 days
      m = moment.utc();
      m.startOf('day').subtract(90, 'days');
      for (let x = 0; x <= 90; x += 1) {
        result.push(makeEmptyThreatAggregate(m));
        m.add(1, 'days');
      }
      break;

    case 5: // Last 7 days
    default:
      // Last 7 days
      m = moment.utc();
      m.startOf('day').subtract(7, 'days');
      for (let x = 0; x <= 7; x += 1) {
        result.push(makeEmptyThreatAggregate(m));
        m.add(1, 'days');
      }
      break;
  }

  return result;
}

function isValidDuration(duration) {
  const ordinals = _.map(RangeTypes, 'ordinal');
  const MAX_ORDINAL = _.max(ordinals);
  const MIN_ORDINAL = _.min(ordinals);

  return (
    typeof duration === 'undefined' ||
    Number.isNaN(duration) ||
    duration < MIN_ORDINAL ||
    duration > MAX_ORDINAL
  );
}

function makeEmptyThreatAggregate(date) {
  return {
    timeAgg: date.valueOf(), // date is a "moment"
    critical: 0,
    elevated: 0,
    low: 0,
    normal: 0,
  };
}
