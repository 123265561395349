import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

// platform helpers
import _ from 'lodash';
import type { AxiosError, AxiosResponse } from 'axios';
import { _isOneOf } from '../../../utils/data.utils';

// actions
import { fetchAccountDetailsByIdForP100 } from '../../../accounts/AccountService';
import { getAgencyDetails } from '../../../agencies/AgencyService';
import { setAgencyData, setVerifyUI } from '../../_reducers/prime100.reducer';

// components
import { QuoteStepLoader } from '../../customers/quotes/QuoteStepLoader';
import { useAuth } from '../../../components/hooks/useAuth';
import { usePersona } from '../../../components/hooks/usePersona';
import { useActor } from '../../../components/hooks/useActor';
import type { AccountDto, RootStore } from '../../../types';
import { getQuoteP100 } from '../../../api';
import type { P100QuoteRequestDto } from '../../../types/quotes/request/p100-quote-request.dto';

interface P100RequestQuoteDependenciesProps {
  match: {
    params: {
      status: string;
      quoteId: string;
      accountId: string;
    };
  };
  tab: string;
  quoteId: string;
  accountId: string;
  policyId: string;
}

interface QuoteDetails {
  initialRequestData: P100QuoteRequestDto;
}

interface ChildProps {
  data: Data[];
  quoteDetails: QuoteDetails | undefined;
  reQuoteRenew: boolean;
  policyDetails: PolicyDetails;
}

interface Data {
  account: AccountDto;
  agency: {
    agencyId: string;
    agencyName: string;
    additionalBrokerFee: number;
    agentFirstName: string;
    agentLastName: string;
    agentEmail: string;
    agentPhone: string;
    teamIds: string;
  };
  agent: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
}

interface PolicyDetails {
  policyEndDate: string;
  policyStartDate: string;
  policyId: string;
  isRenewal: boolean;
  isEndorsement: boolean;
}

/**
 * @name withP100RequestQuoteDependencies
 * @description This component is meant to enable the data combination and preperation for rendering
 */
export const withP100RequestQuoteDependencies = () => {
  return (Component: React.ComponentType<ChildProps>) => {
    return (props: P100RequestQuoteDependenciesProps & ChildProps) => {
      const [quoteDetails, setQuoteDetails] = React.useState<QuoteDetails>();
      const [data, setData] = React.useState<Data[]>([]);
      const [policyDetails, setPolicyDetails] = React.useState<PolicyDetails>({
        policyEndDate: '',
        policyStartDate: '',
        policyId: '',
        isRenewal: false,
        isEndorsement: false,
      });
      const status = props.match.params.status || props.tab;
      const isNonNewQuoteFlow = _isOneOf(status, [
        'reQuote',
        'renew',
        'edit-quote',
        'verify',
      ]);

      const isEndorsementFlow = status == 'endorsement';

      const dependencyparams = React.useMemo(
        () => ({
          quoteId: props.match.params.quoteId || props.quoteId,
          accountId: props.match.params.accountId || props.accountId,
          policyId: props.policyId,
          isNonNewQuoteFlow,
          isEndorsementFlow,
          setData,
          setQuoteDetails,
          setPolicyDetails,
        }),
        [
          props.match.params.quoteId,
          props.match.params.accountId,
          props.quoteId,
          props.accountId,
          props.policyId,
          isNonNewQuoteFlow,
          isEndorsementFlow,
        ]
      );

      useGetDependencies(dependencyparams);

      const existingQuoteStatusParamValues = [
        'reQuote',
        'renew',
        'edit-quote',
        'endorsement',
        'verify',
      ];

      const isExistingQuote =
        existingQuoteStatusParamValues.includes(props.match.params.status) ||
        existingQuoteStatusParamValues.includes(props.tab);

      if (_.isEmpty(data) || (isExistingQuote && _.isEmpty(quoteDetails))) {
        return (
          <section>
            <QuoteStepLoader />
            <div id="portalOneContainer" />
          </section>
        );
      }

      if (data.length > 0 || (isExistingQuote && !_.isEmpty(quoteDetails))) {
        return (
          <Component
            {...props}
            data={data}
            quoteDetails={quoteDetails}
            reQuoteRenew={isNonNewQuoteFlow}
            policyDetails={policyDetails}
          />
        );
      }

      return null;
    };
  };
};

interface UseGetDependenciesProps {
  quoteId: string;
  accountId: string;
  policyId: string;
  isNonNewQuoteFlow: boolean;
  isEndorsementFlow: boolean;
  setData: React.Dispatch<React.SetStateAction<Data[]>>;
  setPolicyDetails: React.Dispatch<React.SetStateAction<PolicyDetails>>;
  setQuoteDetails: React.Dispatch<
    React.SetStateAction<QuoteDetails | undefined>
  >;
}

function useGetDependencies({
  quoteId,
  accountId,
  policyId,
  setData,
  setQuoteDetails,
  isNonNewQuoteFlow,
  isEndorsementFlow,
  setPolicyDetails,
}: UseGetDependenciesProps) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isCowbellUserJumped } = useAuth();
  const persona = usePersona();
  const actor = useActor();

  const agencyData = useSelector<RootStore, RootStore['prime100']['agency']>(
    ({ prime100 }) => prime100.agency
  );
  const assignedAgent = React.useMemo(
    () => ({
      firstName: agencyData.agentFirstName,
      lastName: agencyData.agentLastName,
      email: agencyData.agentEmail,
      phone: agencyData.agentPhone,
    }),
    [agencyData]
  );

  React.useEffect(() => {
    if (isNonNewQuoteFlow || isEndorsementFlow) {
      getQuoteP100(quoteId)
        .then((resp) => resp.data)
        .then(({ data }) => {
          setQuoteDetails(data);

          if (policyId) {
            setPolicyDetails({
              policyEndDate: data.initialRequestData?.endDate ?? '',
              policyStartDate: data.initialRequestData?.effectiveDate ?? '',
              policyId,
              isRenewal:
                data.initialRequestData?.isRenewal ||
                data.initialRequestData?.isMigration,
              isEndorsement: data.initialRequestData?.isEndorsement,
            });
          }

          const agencyDataFromQuote = {
            agencyId: data.agencyId,
            agencyName: data.agencyName,
            additionalBrokerFee: data?.additionalBrokerFee ?? 0,
            agentFirstName: data.agentFirstName,
            agentLastName: data.agentLastName,
            agentEmail: data.agentEmail,
            agentPhone: data.agentPhone ?? '',
            teamIds: data.firmographicData.teamIds,
          };

          dispatch(setAgencyData(agencyDataFromQuote));
        })
        .catch(() => {
          enqueueSnackbar('No quote information available at this time.', {
            variant: 'error',
          });
        });
    }
  }, [
    dispatch,
    enqueueSnackbar,
    isEndorsementFlow,
    isNonNewQuoteFlow,
    policyId,
    quoteId,
    setPolicyDetails,
    setQuoteDetails,
  ]);

  React.useEffect(() => {
    fetchAccountDetailsByIdForP100(accountId)
      .then((accountDetailsResp) => accountDetailsResp.data)
      .then((accountDetailsData) => {
        if (persona.isCowbell) {
          const payload = [
            {
              account: accountDetailsData,
              agency: agencyData,
              agent: assignedAgent,
            },
          ];

          dispatch(setVerifyUI({ data: payload }));
          return setData(payload);
        }

        if (!persona.isCowbell) {
          getAgencyDetails()
            .then(({ data }: AxiosResponse) => {
              let agentDetails = _.pick(actor, [
                'firstName',
                'lastName',
                'email',
                'phone',
              ]);

              if (isCowbellUserJumped) {
                if (isNonNewQuoteFlow && !_.isEmpty(assignedAgent)) {
                  agentDetails = assignedAgent;
                } else if (accountDetailsData.agentEmail != null) {
                  agentDetails = {
                    firstName: accountDetailsData.agentFirstName,
                    lastName: accountDetailsData.agentLastName,
                    email: accountDetailsData.agentEmail,
                    phone: accountDetailsData.agentPhone,
                  };
                } else {
                  agentDetails = {
                    firstName: data.contactFirstName,
                    lastName: data.contactLastName,
                    email: data.contactEmail,
                    phone: data.contactPhone,
                  };
                }
              }

              const payload = [
                {
                  account: accountDetailsData,
                  agency: data,
                  agent: agentDetails,
                },
              ];

              return setData(payload);
            })
            .catch((err: AxiosError) => {
              if (err.message != 'Request Cancelled') {
                enqueueSnackbar(
                  'No agency information available at this time.',
                  {
                    variant: 'error',
                  }
                );
              }
            });
        }
      })
      .catch(() => {
        enqueueSnackbar('No account information available at this time.', {
          variant: 'error',
        });
      });
  }, [
    accountId,
    agencyData,
    assignedAgent,
    dispatch,
    enqueueSnackbar,
    isNonNewQuoteFlow,
    setData,
    isCowbellUserJumped,
    persona.isCowbell,
    actor,
  ]);
}
