export default (state) => {
  const modifiedHeaders = state.uiSettings.inbox.tableHeaders.map((header) => {
    if (header.id === 'options') {
      return {
        ...header,
        showable: false,
      };
    }

    return header;
  });

  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      inbox: {
        ...state.uiSettings.inbox,
        tableHeaders: modifiedHeaders,
      },
    },
  };
};
