import React, { useEffect } from 'react';
// ui
// components
import { FormContext, useForm } from 'react-hook-form-4';
import {
  DialogContent,
  Grid,
  Grid as MuiGrid,
  DialogActions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';

import _ from 'lodash';
import CBButton from '../Buttons/CbButton';
import { withFormController } from '../hocs/withFormController';
import { delayedEvent } from '../../utils/eventUtils';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { MultiSelect } from '../Selects';
import {
  createRenewalOptout,
  getRenewalOptoutReasons,
} from '../../policies/PolicyService';
import { TextAreaBase } from '../inputs/TextAreaBase';
import { getCompetitors } from '../../accounts/AccountService';
import { FieldError } from '../inputs';

const TextArea = withFormController(TextAreaBase);

const TextField = withFormController(TextFieldBase);

export const OptOutRenewalProcessReason = ({ data, ...props }) => {
  const classes = useClasses();
  const [errors, setErrors] = React.useState({});
  const [reason, setReason] = React.useState();
  const [reasonsList, setReasonsList] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [competitorsList, setCompetitorsList] = React.useState(null);
  const [competitors, setCompetitors] = React.useState();
  const defaultValues = { description: '', fillInOptionText: '' };
  const { handleSubmit, ...methods } = useForm({ defaultValues });
  const {
    formState: { isSubmitting },
    control,
  } = methods;

  useEffect(() => {
    Promise.all([getRenewalOptoutReasons({}), getCompetitors({})])
      .then(([renewalOptoutReasonsResp, competitorResp]) => {
        const renewalOptoutReasonsArr = _.map(
          renewalOptoutReasonsResp.data,
          (value) => ({
            label: value,
            value,
          })
        );

        const competitorsArr = _.map(competitorResp.data, (value) => ({
          label: value.name,
          value: value.name,
        }));
        competitorsArr.push({
          label: 'Other',
          value: 'other',
        });

        // need to remove cowbell competitor option from API
        competitorsArr.shift();

        setCompetitorsList(competitorsArr);
        setReasonsList(renewalOptoutReasonsArr);
      })
      .catch(() => {
        enqueueSnackbar('Unable to download data', { variant: 'error' });
      });
  }, [data.id, data.policyNumber, enqueueSnackbar]);

  const onSubmit = (formData) => {
    if (!reason) {
      setErrors({ reason: 'Please select a reason' });
      return;
    }

    const payload = {
      reason: reason.value,
      ...(reason &&
        reason.value === 'Went with a Competitor' && {
          competitor: competitors.value,
        }),
      ...(reason &&
        reason.value === 'Fill-in option' && {
          fillInReason: formData.fillInOptionText,
        }),
      description: formData.description,
    };

    return createRenewalOptout({ policyId: data.id }, payload)
      .then(() => {
        enqueueSnackbar('Lead updated Successfully!', { variant: 'success' });
        delayedEvent('table-refetch', 4000, 'adminPolicies');
        props.close();
      })
      .catch(() => {
        enqueueSnackbar('Failed to update the lead', { variant: 'error' });
      });
  };

  const handleCancel = () => {
    props.close();
  };

  const handleSelect = React.useMemo(() => {
    return (type) => {
      return (name, selectedOption) => {
        if (type === 'competitors') {
          setCompetitors(selectedOption);
        } else if (type === 'reason') {
          setReason(selectedOption);
          setErrors((current) => ({ ...current, reason: undefined }));
        }
      };
    };
    // eslint-disable-next-line
  }, []);

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent classes={{ root: classes.container }}>
          <MuiGrid container spacing={4} style={{ marginBottom: '0.5rem' }}>
            <MuiGrid item md={12}>
              <MultiSelect
                label="Reason for Opting-Out of Renewal Process"
                buttonPlaceholder="please select a reason"
                isMulti={false}
                options={reasonsList}
                onChange={handleSelect('reason')}
                values={reason}
                showErrorPlace={false}
                required
              />
              <FieldError name="reason" errors={errors} />
            </MuiGrid>
          </MuiGrid>

          {reason && reason.value === 'Went with a Competitor' && (
            <MuiGrid container spacing={4} style={{ marginBottom: '0.5rem' }}>
              <MuiGrid item md={12}>
                <MultiSelect
                  label="Select Competitor from the List"
                  isMulti={false}
                  options={competitorsList}
                  onChange={handleSelect('competitors')}
                  values={competitors}
                  showErrorPlace={false}
                />
              </MuiGrid>
            </MuiGrid>
          )}

          {reason && reason.value === 'Fill-in option' && (
            <MuiGrid container spacing={4} style={{ marginBottom: '0.5rem' }}>
              <MuiGrid item md={12}>
                <TextField name="fillInOptionText" />
              </MuiGrid>
            </MuiGrid>
          )}

          <Grid container>
            <MuiGrid item md={12}>
              <p className={classes.descriptionLabel}>
                Description (Account Notes)
              </p>
            </MuiGrid>
            <MuiGrid item md={12}>
              <TextArea
                className={classes.textAreaRoot}
                maxRows={6}
                minRows={10}
                name="description"
                fullWidth
                multiline
                control={control}
              />
            </MuiGrid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CBButton action={handleCancel} styleName="cancel">
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="OPT-OUT"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  descriptionLabel: {
    marginLeft: '0.75rem',
  },
  container: {
    paddingTop: '2rem !important',
    overflowY: 'visible',
    color: palette.primary.contrastText,
  },
  textAreaRoot: {
    width: '100%',
    borderRadius: 5,
    fontSize: '1rem',
    lineHeight: '1.33',
    color: palette.primary.main,
    height: '9.5rem',
    border: `0.8px solid ${palette.text.secondary}`,
    background: palette.background.darkerBlue,
  },
}));

export const OptOutRenewalProcessReasonConfig = {
  OptOutRenewalProcessReason: {
    component: OptOutRenewalProcessReason,
    config: {
      minWidth: 'md',
      title: 'Opt-Out of Renewal Process',
    },
  },
};
