import React from 'react';
import type { MouseEventHandler } from 'react';

import { Menu } from '@mui/material';

// components
import { AnchorButton } from '../AgencyDashSecondaryActionsMenu';
import { AgencyDashboardMenuItem as MenuItem } from '../../menu-options/AgencyDashboardMenuOptions.components';

// utils
import { useMenuState } from '../../../../../components/tables/cell-components/menu/Menu';
import type { AccountDto } from '../../../../../types';
import { useAgencyAccountMenuOptionHandlers } from '../../../../../accounts/AgencyAccountOptions';
import { useCowbellTranslations } from '../../../../../i18n/translations';

interface AgencyClientsMenuOptionsProps {
  account: AccountDto;
}

const AgencyClientsMenuOptions = ({
  account,
}: AgencyClientsMenuOptionsProps) => {
  const menuState = useMenuState();
  const menuOptions = useAgencyClientOptions(account);
  const menuOptionHandlers = useAgencyAccountMenuOptionHandlers(account);

  const handleClose: MouseEventHandler<HTMLLIElement | HTMLDivElement> = (
    event
  ) => {
    event.stopPropagation();
    menuState.handleClose();
  };

  return (
    <>
      <AnchorButton onClick={menuState.handleOpen} />
      <Menu
        id="more-options-menu"
        anchorEl={menuState.anchorEl}
        keepMounted
        open={menuState.open}
        onClick={handleClose}
        onClose={handleClose}
      >
        <MenuItem onClick={menuOptionHandlers.handleEditAccountDetails}>
          {menuOptions.editClientDetails.name}
        </MenuItem>
        <MenuItem onClick={menuOptionHandlers.handleAddContact}>
          {menuOptions.addContact.name}
        </MenuItem>
        <MenuItem onClick={menuOptionHandlers.handleUpdateAgent}>
          {menuOptions.updateAgent.name}
        </MenuItem>
        <MenuItem onClick={menuOptionHandlers.handleApplyTags}>
          {menuOptions.applyTags.name}
        </MenuItem>
        <MenuItem onClick={menuOptionHandlers.handleDelete}>
          {menuOptions.deleteAccount.name}
        </MenuItem>
      </Menu>
    </>
  );
};

const useAgencyClientOptions = (account: AccountDto) => {
  const { translations } = useCowbellTranslations(['Agent']);

  const doesPhContactExist =
    !!account.policyContactFirstName &&
    !!account.policyContactLastName &&
    !!account.policyContactEmail;

  const addContactLabel = doesPhContactExist ? 'Update Contact' : 'Add Contact';

  return {
    editClientDetails: {
      name: 'Edit Client Details',
    },
    addContact: {
      name: addContactLabel,
    },
    updateAgent: {
      name: `Update ${translations.Agent}`,
    },
    applyTags: {
      name: 'Apply/Change Tag(s)',
    },
    deleteAccount: {
      name: 'Delete Account',
    },
  };
};

export default AgencyClientsMenuOptions;
