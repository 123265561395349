import Moment from 'moment';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

//mui
import {
  Menu,
  MenuItem as MuiMenuItem,
  IconButton as MuiIconButton,
  styled,
} from '@mui/material';

//components
import type { MouseEventHandler } from 'react';
import type { DefaultTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { useMenuState } from '../../components/tables/cell-components/menu/Menu';

//services
import { downloadDocument } from '../../utils/documents.utils';
import {
  downloadBinder as downloadBinderApi,
  downloadQuoteDocuments,
} from '../QuotesService';
import {
  downloadInvoice as downloadInvoiceApi,
  downloadReceipt as downloadReceiptApi,
} from '../../policies/PolicyService';

//constants
import { Modal } from '../../components/modals/v2/helpers/v2.modal.helpers';
import AgencyBindersPrimeXMenuOptions from './AgencyBindersPrimeXMenuOptions';
import type { Binder } from '../../console/accounts/summary/types/binder.types';
import TableMenuIcon from '../../_assets/svg/TableMenuIcon.svg';

interface PrimeXAgencyBindersMenuProps {
  binder: Binder;
  makeRefreshCall: () => void;
}

const PrimeXAgencyBindersMenu = ({
  binder,
  makeRefreshCall,
}: PrimeXAgencyBindersMenuProps) => {
  const classes = useClasses();
  const handleClose: MouseEventHandler<HTMLLIElement | HTMLDivElement> = (
    event
  ) => {
    event.stopPropagation();
    menuState.handleClose();
  };

  const menuState = useMenuState();

  return (
    <>
      <MuiIconButton
        size="small"
        aria-haspopup="true"
        onClick={menuState.handleOpen}
      >
        <TableMenuIcon />
      </MuiIconButton>
      <Menu
        id="more-options-menu"
        anchorEl={menuState.anchorEl}
        keepMounted
        open={menuState.open}
        onClick={handleClose}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <AgencyBindersPrimeXMenuOptions
          binder={binder}
          MenuItem={MenuItem}
          makeRefreshCall={makeRefreshCall}
        />
      </Menu>
    </>
  );
};

export default PrimeXAgencyBindersMenu;

interface useMenuItemHandlersParams {
  binder: Binder;
  makeRefreshCall: () => void;
}

export const usePrimeXAgencyBindersMoreOptionHandlers = ({
  binder,
  makeRefreshCall,
}: useMenuItemHandlersParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const downloadBinder = () => {
    downloadDocument({
      asyncDownload: () =>
        downloadBinderApi(
          binder.accountId,
          binder.quoteId,
          Moment(binder.boundOn).unix()
        ),
      blobFileParams: {
        docName: `${binder.companyName}-${binder.quoteNumber}-Cowbell-Binder`,
        docType: '.pdf',
      },
      enqueueSnackbar,
    });
  };

  const downloadInvoice = () => {
    downloadDocument({
      asyncDownload: () =>
        downloadInvoiceApi(
          binder.accountId,
          binder.id,
          Moment(binder.created).unix(),
          true,
          binder.isEndorsement
        ),
      blobFileParams: {
        docName: `${binder.policyNumber}-Cowbell-Invoice`,
        docType: '.pdf',
      },
      enqueueSnackbar,
    });
  };

  const downloadApplication = () => {
    downloadDocument({
      asyncDownload: () =>
        downloadQuoteDocuments(
          binder.accountId,
          binder.quoteId,
          Moment(binder.boundOn).unix(),
          binder.isEndorsement
        ),
      blobFileParams: {
        docName: `${binder.companyName}-${binder.quoteNumber}-Cowbell-Application`,
        docType: '.pdf',
      },
      enqueueSnackbar,
    });
  };

  const downloadReceipt = () => {
    downloadDocument({
      asyncDownload: () =>
        downloadReceiptApi(
          binder.accountId,
          binder.id,
          Moment(binder.created).unix(),
          binder.isEndorsement
        ),
      blobFileParams: {
        docName: `${binder.policyNumber}-Cowbell-Receipt`,
        docType: '.pdf',
      },
      enqueueSnackbar,
    });
  };

  const issuePolicy = () => {
    history.push(`/agency/bind/quote/${binder.product}/${binder.quoteId}`);
  };

  const rescindBinder = () => {
    Modal.show('RescindBinder', {
      data: { ...binder, makeRefreshCall },
      config: { title: `Rescind Binder for ${binder.companyName}` },
    });
  };

  return {
    downloadBinder,
    downloadReceipt,
    downloadInvoice,
    downloadApplication,
    issuePolicy,
    rescindBinder,
  };
};

const useClasses = makeStyles(({ palette }) => ({
  paper: {
    minWidth: 'auto',
    background: palette.common.darkBlue,
    border: `1px solid ${palette.common.almostWhite}`,
    color: palette.common.almostWhite,
  },
}));

const MenuItem = styled(MuiMenuItem)(({ theme }: { theme: DefaultTheme }) => ({
  padding: '0.4166666667rem 0.8333333333rem',
  color: theme.config.colors.white,

  '&:hover, &:focus': {
    color: theme.config.colors.white,
    fontWeight: 600,
    background: '#113E68',
  },
}));
