// maps to BE billing invoice statuses
// https://github.com/Cowbell-Cyber/fir-billing/blob/master/src/main/java/com/cowbell/server/billing/enums/InvoiceStatus.java
import * as Ramda from 'ramda';
import { transformToOption } from '../../utils/functional/fp';

const OPEN = 'Open';
const VOID = 'Void';
const CLOSED = 'Closed';
const CANCEL = 'Cancel';
const DRAFT = 'Draft';
const RESCIND = 'Rescind';
const FLAT_CANCELLED = 'Flat Cancelled';
const INVALID = 'Invalid';

export const BillingInvoiceStatus = {
  OPEN,
  VOID,
  CLOSED,
  CANCEL,
  DRAFT,
  RESCIND,
  FLAT_CANCELLED,
  INVALID,
} as const;

export const BILLING_INVOICE_STATUS_OPTIONS = Object.values(
  BillingInvoiceStatus
).map(transformToOption(Ramda.identity, Ramda.identity));
