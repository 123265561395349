import React from 'react';
import {
  Card as MuiCard,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { useFormContext } from 'react-hook-form-4';

import { withFormController } from '../../../../components/hocs/withFormController';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import { InputLabelBase as InputLabel } from '../../../../components/inputs/InputLabelBase';

import {
  useCardStyles,
  useGridStylesLeft,
  useGridStylesRight,
} from './ClaimModalStyles';

const TextField = withFormController(TextFieldBase);

export const CreateClaimOther = withStyles(({ palette }) => ({
  heading: {
    marginTop: 0,
    marginBottom: 25,
    color: palette.common.cowbellBlue,
    textAlign: 'center',
  },
}))(({ classes }) => {
  const cardStyles = useCardStyles();
  const gridStylesLeft = useGridStylesLeft();
  const gridStylesRight = useGridStylesRight();
  const { control, getValues, register, onSelect } = useFormContext();

  return (
    <MuiCard classes={cardStyles}>
      <h2 className={classes.heading}>Other Information</h2>
      <MuiGrid container spacing={1}>
        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="isBusinessImpacted">
            Is your ability to do business impacted?:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <MuiSelect
            variant="standard"
            name="isBusinessImpacted"
            ref={register({ name: 'isBusinessImpacted' })}
            onChange={onSelect}
            defaultValue={getValues().isBusinessImpacted}
          >
            {yesNoOptions.map(({ label, value }) => (
              <MuiMenuItem key={label} value={value}>
                {label}
              </MuiMenuItem>
            ))}
          </MuiSelect>
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="isCyberIncident">
            Is the cyber incident ongoing?:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <MuiSelect
            variant="standard"
            name="isCyberIncident"
            ref={register({ name: 'isCyberIncident' })}
            onChange={onSelect}
            defaultValue={getValues().isCyberIncident}
          >
            {yesNoOptions.map(({ label, value }) => (
              <MuiMenuItem key={label} value={value}>
                {label}
              </MuiMenuItem>
            ))}
          </MuiSelect>
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="isRemediationServiceBegun">
            Have remediation services already begun?:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <MuiSelect
            variant="standard"
            name="isRemediationServiceBegun"
            ref={register({ name: 'isRemediationServiceBegun' })}
            onChange={onSelect}
            defaultValue={getValues().isRemediationServiceBegun}
          >
            {yesNoOptions.map(({ label, value }) => (
              <MuiMenuItem key={label} value={value}>
                {label}
              </MuiMenuItem>
            ))}
          </MuiSelect>
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="otherInformationExplanation">
            If yes, please explain:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <TextField
            name="otherInformationExplanation"
            control={control}
            fullWidth
            multiline
            minRows="4"
          />
        </MuiGrid>
      </MuiGrid>
    </MuiCard>
  );
});

const yesNoOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
