import { useQuery } from '@tanstack/react-query';

import { Box, Paper } from '@mui/material';
import { Skeleton } from '@mui/lab';

import Tile from '../../../../components/tiles/Tile';

import { useQueryParams } from '../../../../components/providers/QueryParamsProvider';

import {
  fetchPolicyLimit,
  fetchPolicyPremium,
} from '../../../../policies/PolicyService';
import {
  summarizeValidPolicyLimits,
  summarizeValidPolicyStats,
} from '../../../../utils/appUtils';
import PolicyCount from './PolicyCount';

const initialStatsMap = {
  stats: {
    totalPolicies: {
      value: 0,
      name: 'Total Policies',
      type: 'policies',
      dataKey: 'data.aggregations.Status.Status.buckets',
      supplement: {
        name: 'Binders',
        value: 0,
        format: (value) => (value < 1050 ? '0a' : '0.0a'),
      },
    },
    totalPremium: {
      value: 0,
      name: 'Total Premium',
      type: 'premiumMedium',
      dataKey: '',
      supplement: {
        name: 'Bound',
        format: () => '0.0a',
        value: 0,
      },
    },
    avgPremium: { value: 0, name: 'Avg Premium', type: 'premium', dataKey: '' },
    totalInsuredValue: {
      value: 0,
      name: 'Total Insured Value',
      type: 'premiumMedium',
      dataKey: 'data.aggregations.Status.Status.buckets',
    },
    premiumToLimitRatioPercentage: {
      value: 0,
      name: 'Premium to Limit Ratio',
      type: 'limit',
      dataKey: '',
    },
  },
  policyCounts: {
    cancelledPolicies: {
      value: 0,
      name: 'Cancelled',
      dataKey: '',
    },
    renewedPolicies: {
      value: 0,
      name: 'Renewed',
      dataKey: '',
    },
    expiredPolicies: {
      value: 0,
      name: 'Expired',
      dataKey: '',
    },
  },
};

function CowbellPoliciesStatsBar() {
  const { query } = useQueryParams();

  const policyStatsQuery = useQuery({
    queryKey: ['policy-stats', { query }],
    queryFn: () =>
      Promise.all([fetchPolicyPremium(query), fetchPolicyLimit(query)]).then(
        (responses) => {
          const transformedStats = { ...initialStatsMap.stats };
          const transformedPolicyCounts = { ...initialStatsMap.policyCounts };
          const [premiumStats, limits] = responses;

          const policySummary = summarizeValidPolicyStats(
            premiumStats,
            transformedStats.totalPolicies.dataKey
          );

          const limitsSummary = summarizeValidPolicyLimits(
            limits,
            transformedStats.totalInsuredValue.dataKey
          );

          const premiumToLimitRatioPercentage =
            100 * (policySummary.premium / limitsSummary.premium);

          transformedStats.totalPolicies.value = policySummary.count;
          transformedStats.totalPremium.value = policySummary.premium;
          transformedStats.avgPremium.value =
            policySummary.premium / policySummary.count;

          transformedStats.totalInsuredValue.value = limitsSummary.premium;
          transformedStats.premiumToLimitRatioPercentage.value =
            premiumToLimitRatioPercentage;

          /* Policy Counts */
          transformedPolicyCounts.cancelledPolicies.value =
            policySummary.cancelledCount;
          transformedPolicyCounts.renewedPolicies.value =
            policySummary.renewedCount;
          transformedPolicyCounts.expiredPolicies.value =
            policySummary.expiredCount;

          /* Supplemental Binder Stats */
          transformedStats.totalPolicies.supplement.value =
            policySummary.binders;
          transformedStats.totalPremium.supplement.value =
            policySummary.boundPremium;

          if (premiumToLimitRatioPercentage < 0.1) {
            transformedStats.premiumToLimitRatioPercentage.color = 'red';
          }

          return {
            stats: Object.values(transformedStats),
            policyCounts: Object.values(transformedPolicyCounts),
          };
        }
      ),
  });

  if (policyStatsQuery.isLoading) {
    return loadingSkeleton;
  }

  return (
    <Paper className="stats-bar" elevation={6}>
      {policyStatsQuery.data.stats &&
        policyStatsQuery.data.stats.map((stat) => (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Tile key={stat.name} query={query} {...stat} />
          </Box>
        ))}

      {policyStatsQuery.data.policyCounts.length && (
        <PolicyCount stats={policyStatsQuery.data.policyCounts} />
      )}
    </Paper>
  );
}

export default CowbellPoliciesStatsBar;

const loadingSkeleton = (
  <Paper className="stats-bar" elevation={6}>
    {Array(6)
      .fill()
      .map(() => (
        <Skeleton
          variant="rectangular"
          component="div"
          height="84px"
          style={{ borderRadius: '4px' }}
        >
          <Tile />
        </Skeleton>
      ))}
  </Paper>
);
