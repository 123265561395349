import React from 'react';
import chmln from '@chamaeleonidae/chmln';
import { useActor } from '../components/hooks/useActor';

export function useChameleon() {
  const actor = useActor();
  React.useEffect(() => {
    chmln.init(
      'Sk7PYEaTnjVB07uXeD72eMcWXfhonAVgp5PBVDOZoW1Du-1PJJV2-EjOnZN77WKIC3GiJ',
      { fastUrl: 'https://fast.chameleon.io/' }
    );
  }, []);

  React.useEffect(() => {
    if (actor.id) {
      chmln.identify(actor.id);
    }
  }, [actor.id]);
}
