import React from 'react';
import _ from 'lodash';
import type { PopoverProps } from '@mui/material';
import {
  Box,
  Button,
  List,
  ListItem,
  Popover,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';

import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { usePopOverState } from '../../../agencies/quotes/PrimeX/customizeQuote/components/DatePicker';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import { CheckBox } from '../../../../components/inputs/Checkbox';
import type { FilterOptionConfig } from '../agency-dashboard.statics';
import { mergeFilters } from '../agency-dashboard.statics';
import {
  AMPLITUDE_EVENTS as ANALYTICS_EVENTS,
  useAmplitude as useAnalytics,
} from '../../../../providers/AmplitudeProvider';

export const AgencyDashboardFilters = () => {
  const theme = useTheme();
  const popoverState = usePopOverState();
  const { currentTabFilters } = useAgencyDashboardState();
  const showTypography = _.isEmpty(currentTabFilters);

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      flexWrap="wrap"
    >
      <ActiveFiltersListing activeFilters={currentTabFilters} />
      <Button
        variant="outlined"
        style={{
          padding: '0.4rem',
          minWidth: 'unset',
          marginLeft: '0.2rem',
          width: showTypography ? '12rem' : '2.8rem',
          transition: 'width 500ms',
          overflow: 'hidden',
          color: theme.agencyDash.interactable,
          borderColor: theme.agencyDash.interactable,
        }}
        disableRipple
        onClick={popoverState.onOpen}
        data-qa="ADD_FILTER_BUTTON"
      >
        <AddIcon style={{ fontSize: '1.8rem' }} />
        <Typography
          variant="body2"
          style={{
            opacity: showTypography ? 1 : 0,
            overflow: 'hidden',
            maxHeight: '2rem',
            transition: 'opacity 500ms',
          }}
        >
          Add Filter
        </Typography>
      </Button>
      <FilterMenu {...popoverState} />
    </Box>
  );
};

const FilterMenu = ({ ...popoverState }: PopoverProps) => {
  const analytics = useAnalytics();
  const {
    currentTab,
    onUpdateQuery,
    currentTabFilters,
    onApplyFilter,
    onRemoveFilter,
    onClearFilters,
    filterOptions,
    searchTerm,
  } = useAgencyDashboardState();

  const onClick = (filter: FilterOptionConfig) => {
    if (filter.id in currentTabFilters) {
      return onRemoveFilter(filter.id);
    }
    analytics.track(ANALYTICS_EVENTS.addFilter, {
      tab: currentTab.toLowerCase(),
      name: filter.label.toLowerCase(),
    });
    return onApplyFilter(filter);
  };

  const isRelevantSelected = _.isEmpty(currentTabFilters);
  const defaultOptionText = searchTerm ? 'ALL' : 'ACTIVE (default)';
  const onRelevantClick = () => {
    if (!isRelevantSelected) {
      onClearFilters();
    }
  };

  React.useEffect(() => {
    const update = mergeFilters(currentTabFilters);
    onUpdateQuery(currentTab, { ...update, page: 0 });
  }, [currentTabFilters]);

  return (
    <Popover
      id="agency-dashboard-filter-menu"
      PaperProps={{ style: { minWidth: '13rem', paddingRight: '1rem' } }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...popoverState}
    >
      <List>
        <FilterOption onClick={onRelevantClick} isSelected={isRelevantSelected}>
          {defaultOptionText}
        </FilterOption>
        {filterOptions.map((option: FilterOptionConfig) => (
          <FilterOption
            key={`${currentTab}:${option.id}`}
            onClick={() => onClick(option)}
            isSelected={option.id in currentTabFilters}
          >
            {option.label}
          </FilterOption>
        ))}
      </List>
    </Popover>
  );
};

type FilterOptionProps = {
  onClick: () => void;
  isSelected: boolean;
};

const FilterOption = ({
  children,
  onClick,
  isSelected,
}: React.PropsWithChildren<FilterOptionProps>) => {
  return (
    <ListItem
      onClick={onClick}
      style={{ cursor: 'pointer', padding: 0 }}
      data-qa={`FILTER_OPTION:${children}`}
    >
      <CheckBox checked={isSelected} />
      {children}
    </ListItem>
  );
};

const ActiveFiltersListing = ({ activeFilters }: { activeFilters: any }) => {
  const { onRemoveFilter } = useAgencyDashboardState();
  return (
    <>
      {Object.keys(activeFilters).map((filterName) => (
        <Button
          disableRipple
          variant="outlined"
          style={{
            margin: '0.2rem',
            padding: '0.3rem 0.5rem',
            borderRadius: 20,
          }}
          onClick={() => onRemoveFilter(filterName)}
          data-qa={`FILTER_CHIP:${filterName}`}
        >
          <Typography variant="caption">{filterName}</Typography>
          <CloseIcon />
        </Button>
      ))}
    </>
  );
};
