import React from 'react';
import type {
  AgencyDashboardListingTab,
  AgencyDashboardQueries,
} from '../agency-dashboard.statics';
import {
  agencyDashboardQueryDefaults,
  AGENCY_DASHBOARD_LISTING_TABS,
  AGENCY_DASHBOARD_VIEWS,
  initialFilters,
} from '../agency-dashboard.statics';

export const useAgencyDashboardSavedSettings = () => {
  const rawStore = sessionStorage.getItem('cowbell') ?? '{}';
  const store = JSON.parse(rawStore) ?? {};
  const uiSettings = store.uiSettings ?? {};
  const AGENCY_DASHBOARD = uiSettings.AGENCY_DASHBOARD ?? {};
  const { currentTab, view, queryState, activeFilters, searchTerm } =
    AGENCY_DASHBOARD;

  return {
    tab: currentTab ?? AGENCY_DASHBOARD_LISTING_TABS.QUOTES,
    view: view ?? AGENCY_DASHBOARD_VIEWS.LISTING,
    queries: queryState ?? agencyDashboardQueryDefaults,
    filters: activeFilters ?? initialFilters,
    searchTerm: searchTerm ?? '',
  };
};

interface AutosaveData {
  currentTab: AgencyDashboardListingTab;
  searchTerm: string;
  queryState: AgencyDashboardQueries;
  activeFilters: any;
}

export const useAgencyDashboardAutosave = (data: AutosaveData) => {
  const rawStore = sessionStorage.getItem('cowbell') ?? '{}';
  const store = JSON.parse(rawStore) ?? {};
  const uiSettings = store.uiSettings ?? {};
  const AGENCY_DASHBOARD = uiSettings.AGENCY_DASHBOARD ?? {};

  React.useEffect(() => {
    const update = {
      ...store,
      uiSettings: {
        ...uiSettings,
        AGENCY_DASHBOARD: { ...AGENCY_DASHBOARD, ...data },
      },
    };
    asyncSetItem('cowbell', JSON.stringify(update));
  }, [data]);
};

const asyncSetItem = async (key: string, data: string) =>
  sessionStorage.setItem(key, data);
