import { ServiceHandlerFactory } from '../../../utils/serviceUtils';

export const searchDunsByPhone = ServiceHandlerFactory({
  axios: {
    url: '/api/nad/v1/dunslookup/phone/:telephoneNumber',
  },
  isCancelable: true,
  paramSchema: [{ path: 'page' }, { path: 'size' }, { path: 'country' }],
});

export const searchDunsByCriteria = ServiceHandlerFactory({
  axios: {
    url: '/api/nad/v1/dunslookup/criteriaSearch/:primaryName/:state',
  },
  isCancelable: true,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'streetAddress' },
    { path: 'city' },
    { path: 'zipCode' },
    { path: 'country' },
  ],
});
