import React, { useState } from 'react';
import { useForm } from 'react-hook-form-4';
import _ from 'lodash';
import {
  DialogContent,
  DialogActions,
  DialogContentText,
  FormHelperText,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CbButton from '../../Buttons/CbButton';
import { togglePaymentReminders } from '../../../policies/PolicyService';

export const MutePaymentReminderConfirm = ({ data, ...props }) => {
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState('');
  const { id, policyNumber, companyName, muteReminders, handleTableRefetch } =
    data;

  const onSubmit = () => {
    togglePaymentReminders(id)
      .then(() => {
        enqueueSnackbar(
          `Mute/Unmute Payment Reminder flag updated for Policy ${policyNumber}`,
          { variant: 'success' }
        );
        props.close();
        handleTableRefetch();
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong while changing updating.'
            )
          )
        );
      });
  };

  const muteRemindersText = muteReminders ? 'Unmute' : 'Mute';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent className="paf">
        <DialogContentText>
          Are you sure you want to {muteRemindersText} Payment Reminders for
          policy <span className="modal-blue-text">{policyNumber}</span> of{' '}
          <span className="modal-blue-text">{companyName}</span> ?
        </DialogContentText>
        {error && (
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={props.close}>
          Cancel
        </CbButton>
        <CbButton
          loading={isSubmitting}
          styleName="ctaButton"
          buttonText="Update"
          type="submit"
        />
      </DialogActions>
    </form>
  );
};

export const MutePaymentReminderConfirmConfig = {
  MutePaymentReminderConfirm: {
    component: MutePaymentReminderConfirm,
    config: {
      title: 'Mute/Unmute Payment Reminders',
      fullWidth: true,
    },
  },
};
