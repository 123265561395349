import Moment from 'moment';
import type { AxiosResponse } from 'axios';
import {
  applyQueryConfig,
  serializeStandardResponse,
  ServiceHandler,
  stringifyRequestBody,
} from '../../utils';
import { standardQuerySerializer } from '../serializers';
import { renewalSubmissionParamsSerializer } from './renewal-submissions.params-serializer';
import { aeNotesParamsSerializer } from './ae-notes-params-serializer';
import { NoteTypeAliases } from './common';

interface NoteTypes {
  ACTIVITY_LOG: string;
  GENERAL: string;
  CONTACTED: string;
}

interface BENote {
  accountId: string;
  attachments: any[];
  created: number;
  createdBy: string;
  createdByEmail: string;
  id: string;
  modified: number;
  modifiedBy?: string;
  modifiedByEmail?: string;
  noteText: string;
  submissionId: string;
  submissionNoteType: keyof NoteTypes;
  title?: string;
}
interface UINote extends BENote {
  ui_created: string;
  ui_modified: string;
  noteType: keyof NoteTypes;
}

// submission crud
export const createRenewalSubmission = ServiceHandler({
  url: '/api/policy/v1/:productType/renewal-submission',
  method: 'POST',
  paramsSerializer: (params) =>
    standardQuerySerializer(
      applyQueryConfig(renewalSubmissionParamsSerializer, params)
    ),
});

export const getRenewalSubmissionsListing = ServiceHandler({
  url: '/api/quote/v1/search/renewal-submission',
  paramsSerializer: (params) =>
    standardQuerySerializer(
      applyQueryConfig(renewalSubmissionParamsSerializer, params)
    ),
});

export const updateRenewalSubmissionListing = ServiceHandler({
  url: '/api/quote/v1/submission/renewal/:submissionId',
  method: 'PUT',
});

// TerritoryContact CRUD
export const getTerritoryContacts = ServiceHandler({
  url: '/api/quote/v1/submission/contacts',
});

export const createTerritoryContact = ServiceHandler({
  url: '/api/quote/v1/submission/contact',
  method: 'POST',
});

export const deleteTerritoryContact = ServiceHandler({
  url: '/api/quote/v1/submission/contact/:contactId',
  method: 'DELETE',
});

// enums
export const getLostReasons = ServiceHandler({
  url: '/api/quote/v1/submission/lost-reasons',
});

export const getBrokerConcerns = ServiceHandler({
  url: '/api/quote/v1/submission/broker-concerns',
});

export const getSubmissionStatuses = ServiceHandler({
  url: '/api/quote/v1/submission/statuses',
});

// notes
const formatNoteTypes = (resp: AxiosResponse) => {
  const noteTypes: string[] = resp.data;
  return noteTypes.reduce(
    (acc, type) => ({
      ...acc,
      [type.toUpperCase().split(' ').join('_')]: { name: type },
    }),
    {}
  );
};

export const getAENoteTypes = ServiceHandler({
  url: '/api/quote/v1/note/submission/types',
  transformResponse: [serializeStandardResponse, formatNoteTypes],
});

const formatAENotesListing = (resp: AxiosResponse) => ({
  ...resp.data,
  content: resp?.data?.content.map((note: BENote) =>
    formatAENotesDetails({ data: note })
  ),
});

export const getAENotesListing = ServiceHandler({
  url: '/api/quote/v1/note/submission',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(aeNotesParamsSerializer, params)),
  transformResponse: [serializeStandardResponse, formatAENotesListing],
});

const formatAENotesDetails = ({ data: note }: { data: BENote }) => ({
  ...note,
  ui_created:
    !!note.created && Moment(note.created).format('MMM Do YYYY @ h:mma'),
  ui_modified:
    !!note.modifiedBy && Moment(note.modified).format('MMM Do YYYY @ h:mma'),
  noteType: Object.keys(NoteTypeAliases).filter(
    (alias: string) =>
      NoteTypeAliases[alias as keyof NoteTypes] === note.submissionNoteType
  ),
});

export const getAENoteDetails = ServiceHandler({
  url: '/api/quote/v1/note/submission/:noteId',
  transformResponse: [serializeStandardResponse, formatAENotesDetails],
});

const formatAENotePostPayload = (payload: UINote) => {
  const { noteType, ...rest } = payload;
  return {
    ...rest,
    submissionNoteType: NoteTypeAliases[noteType],
  };
};

export const createAENote = ServiceHandler({
  url: '/api/quote/v1/note/submission',
  method: 'POST',
  transformRequest: [formatAENotePostPayload, stringifyRequestBody],
});

export const updateAENote = ServiceHandler({
  url: '/api/quote/v1/note/submission/:noteId',
  method: 'PUT',
  transformRequest: [formatAENotePostPayload, stringifyRequestBody],
});

export const deleteAENote = ServiceHandler({
  url: '/api/quote/v1/note/submission/:noteId',
  method: 'DELETE',
});
