import { Skeleton } from '@mui/lab';
import { Box } from '@mui/material';

const AdminQuoteDrawerSkeleton = () => {
  return (
    <>
      <Box mb={1.5}>
        <Skeleton />
      </Box>
      <Box mb={1.5}>
        <Skeleton />
      </Box>
      <Box mb={1.5}>
        <Skeleton />
      </Box>
    </>
  );
};

export default AdminQuoteDrawerSkeleton;
