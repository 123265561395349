import { DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';
import CbButton from '../../Buttons/CbButton';
import { refreshRiskData } from '../../../api/p250.service';
import type { ProductType } from '../../../types';
import { useAPIErrorHandler } from '../../hooks/useAPIErrorHandler';

interface RefreshRiskModalProps {
  data: {
    accountId: string;
    quoteId: string;
    agentEmail: string;
    productType: ProductType;
    refetchCall?: () => void;
  };
  close: () => void;
}

const RefreshRiskModal = ({
  data: modalProps,
  close,
}: RefreshRiskModalProps) => {
  const handleAPIError = useAPIErrorHandler();
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const history = useHistory();

  const handleRefreshRisk = () => {
    setIsLoading(true);
    refreshRiskData(
      { accountId: modalProps.accountId },
      {
        quoteId: modalProps.quoteId,
        agentEmail: modalProps.agentEmail,
        product: modalProps.productType,
      }
    )
      .then(() => {
        enqueueSnackbar('Risk reassessed successfully', { variant: 'success' });
        modalProps.refetchCall?.();
        close();
        history.replace('/admin');
        setTimeout(() => history.replace(pathname), 0);
      })
      .catch(handleAPIError('Failed to reassess risk for this account'));
  };

  return (
    <>
      <DialogContent style={{ padding: '2.5rem' }}>
        <div
          style={{ textAlign: 'center', fontSize: '1.1rem' }}
          className="contrast-text"
        >
          <p>Are you sure you want to reassess the risk for this account?</p>
          <p>
            You will lose unsaved changes and will not be able to revert back
            after the update!
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" size="medium" onClick={close}>
          Cancel
        </CbButton>
        <CbButton
          styleName="ctaButton"
          size="medium"
          onClick={handleRefreshRisk}
          loading={isLoading}
          disabled={isLoading}
        >
          Reassess Risk
        </CbButton>
      </DialogActions>
    </>
  );
};

export const RefreshRiskModalConfig = {
  RefreshRiskModal: {
    component: RefreshRiskModal,
    config: {
      title: 'Reassess Risk',
      maxWidth: 'sm',
    },
  },
};
