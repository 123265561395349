import React from 'react';
import type { FieldValues } from 'react-hook-form';
import * as Yup from 'yup';
import _ from 'lodash';
import Moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import type { AccountDto, ProductType, RootStore } from '../../../../../types';
import { ProductTypes } from '../../../../../types';
import { FlowTypes } from '../../../../../types/flows';
import { RequestQuoteSteps } from '../constants';
import type { Question } from '../securityQuestionsSchema/securityQuestions.statics';
import { QuestionTypes } from '../securityQuestionsSchema/securityQuestions.statics';
import { sleep } from '../../../../../utils/appUtils';
import type {
  Preset,
  QuoteViewState,
} from '../requestQuote/new-business-reducer/types';
import { useSteps } from '../../../../../components/hooks/useSteps';

interface GetSecurityQuestionsPayloadArgs {
  accountData?: AccountDto;
  productType: ProductType;
  formValues: FieldValues;
}

export const retroActiveDateMap = Object.freeze({
  1: '1 Year',
  2: '2 Years',
  3: '3 Year',
  '-1': 'Full Prior Acts',
});

export const getSecurityQuestionsPayload = ({
  accountData,
  productType,
  formValues,
}: GetSecurityQuestionsPayloadArgs) => {
  if (productType === ProductTypes.p100_pro) {
    const primeXQuestions = Object.keys(formValues)
      .filter(
        (formValue) =>
          !formValue.includes('comments') &&
          typeof formValues[formValue] !== 'undefined'
      )
      .reduce((acc, question) => {
        //This check is because BE could not chnage the index in elastic for this question from boolean to string 😬
        if (question === 'ngav') {
          return {
            ...acc,
            [question]: {
              ngav: formValues[question] ?? null,
              comments: formValues[`${question}-comments`],
            },
          };
        }

        if (question === 'eolProducts') {
          return {
            ...acc,
            [question]: {
              eolProducts: formValues[question] ?? null,
              comments: formValues[`${question}-comments`],
            },
          };
        }

        return {
          ...acc,
          [question]: {
            answer: formValues[question] ?? null,
            comments: formValues[`${question}-comments`],
          },
        };
      }, {}) as any;

    const payload = {
      accountId: accountData?.id,
      isVerifyingBankAccounts: accountData?.isVerifyingBankAccounts ?? null,
      isAuthenticatingFundTransferRequests:
        accountData?.isAuthenticatingFundTransferRequests ?? null,
      isPreventingUnauthorizedWireTransfers:
        accountData?.isPreventingUnauthorizedWireTransfers ?? null,
      isSecurityOfficer: accountData?.isSecurityOfficer ?? null,
      isSecurityTraining: accountData?.isSecurityTraining ?? null,
      useEncryption: accountData?.useEncryption ?? null,
      useCloudStorage: accountData?.useCloudStorage ?? null,
      mfaAuthentication: accountData?.mfaAuthentication ?? null,
      mfaEmail: accountData?.mfaEmail ?? null,
      mfaCloudDeployments: accountData?.mfaCloudDeployments ?? null,
      mfaMissionCriticalSystems: accountData?.mfaMissionCriticalSystems ?? null,
      mfaRemoteAccess: accountData?.mfaRemoteAccess ?? null,
      incidentResponsePlan: accountData?.incidentResponsePlan ?? null,
      priorCoverage: primeXQuestions?.priorCoverage?.answer,
      p250Details: accountData?.p250Details,
      p100ProQuestions: {
        ...accountData?.p100ProQuestions,
        ...primeXQuestions,
      },
    };
    return payload;
  }

  type Filtered = {
    key: string;
    value: string | boolean | string[];
    comment?: string;
  }[];

  const securityAnswers = Object.keys(formValues).reduce(
    (filtered: Filtered, formKey) => {
      if (!formKey.includes('comments') && _.has(formValues, formKey)) {
        filtered.push({
          key: formKey,
          value: formatAnswer(formValues[formKey]),
          comment: formValues[`${formKey}-comments`],
        });
      }

      return filtered;
    },
    []
  );

  return {
    securityAnswers,
  };
};

const formatAnswer = (answer: string | boolean | string[]) => {
  if (answer === 'true') {
    return true;
  }

  if (answer === 'false') {
    return false;
  }

  return answer;
};

interface GetValidationSchemaArgs {
  questions: Readonly<Question[]>;
}

export const getValidationSchema = ({ questions }: GetValidationSchemaArgs) => {
  let formFields = {};
  const validationTypeMapping = {
    [QuestionTypes.boolAnswer]: Yup.bool(),
    [QuestionTypes.singleSelect]: Yup.string(),
    [QuestionTypes.multiSelect]: Yup.array(),
    [QuestionTypes.text]: Yup.string(),
  };

  for (const question of questions) {
    if (question.required) {
      formFields = {
        ...formFields,
        [question.name]: validationTypeMapping[question.type]
          .required()
          .label('This'),
      };
      if (question.dynamic && question.dynamicQuestions) {
        for (const dynamicQuestion of question.dynamicQuestions) {
          if (dynamicQuestion.required) {
            formFields = {
              ...formFields,
              [dynamicQuestion.name]: validationTypeMapping[
                dynamicQuestion.type
              ].when(dynamicQuestion.show.fieldName, {
                is: (value: string) => {
                  const customEvaluation = dynamicQuestion.show.is
                    ? value === dynamicQuestion.show.is
                    : value !== dynamicQuestion.show.isNot;

                  return !!value && customEvaluation;
                },
                then: validationTypeMapping[dynamicQuestion.type]
                  .required()
                  .label('This'),
                otherwise: validationTypeMapping[dynamicQuestion.type],
              }),
            };
          }
        }
      }
    }
  }
  return Yup.object().shape(formFields);
};

export const getFakePresets = async () => {
  await sleep(1500);

  return {
    presets: {
      COVERAGE_ENDORSEMENTS: [
        {
          name: 'EXTORTION_THREATS_AND_RANSOM_PAYMENTS',
          limit: 1000000,
          ordinal: 32,
          deductible: 5000,
          retroActivePeriod: 1,
          isAvailable: true,
          isMandatory: true,
          isSelected: false,
          alignMax: true,
          refer: false,
        },
        {
          name: 'DATA_RESTORATION',
          limit: 1000000,
          ordinal: 39,
          deductible: 5000,
          retroActivePeriod: 1,
          isAvailable: true,
          isMandatory: true,
          isSelected: true,
          alignMax: true,
          refer: false,
        },
      ],
      FIRST_PARTY_EXPENSE: [
        {
          name: 'BI_EXTRA_EXPENSE',
          limit: 1000000,
          ordinal: 2,
          deductible: 5000,
          retroActivePeriod: 1,
          isMandatory: true,
          isAvailable: true,
          isSelected: true,
          alignMax: true,
          refer: false,
        },
        {
          name: 'COWBELL_BREACH_FUND',
          limit: 1000000,
          ordinal: 2,
          deductible: 5000,
          retroActivePeriod: 1,
          isMandatory: true,
          isAvailable: true,
          isSelected: true,
          alignMax: true,
          refer: false,
        },
      ],
      TEST_GROUP: [
        {
          name: 'LIABILITY',
          limit: 1000000,
          ordinal: 2,
          deductible: 5000,
          retroActivePeriod: 1,
          isMandatory: true,
          isAvailable: false,
          isSelected: true,
          alignMax: true,
          refer: false,
        },
      ],
    },
    rules: {
      deductibles: [
        2500, 5000, 10000, 15000, 20000, 25000, 50000, 75000, 100000, 125000,
        150000, 175000, 200000, 250000, 300000, 400000, 500000,
      ],
    },
  };
};

export const getGridSize = (numberOfItems: number) => {
  const gridSize = 12 / numberOfItems;

  if (gridSize < 3) {
    return 3;
  }

  return gridSize;
};

interface GetDefaultFormValuesArgs {
  accountData?: AccountDto;
  productType: ProductType;
}

export const getDefaultFormValues = ({
  productType,
  accountData,
}: GetDefaultFormValuesArgs) => {
  if (productType === ProductTypes.p100_pro && !!accountData) {
    const { p100ProQuestions = {} } = accountData;
    return Object.keys(p100ProQuestions).reduce(
      (acc, question) => {
        if (question === 'ngav') {
          return {
            ...acc,
            [question]: p100ProQuestions[question]?.ngav,
            [`${question}-comments`]: p100ProQuestions[question]?.comments,
          };
        }

        if (question === 'eolProducts') {
          return {
            ...acc,
            [question]: p100ProQuestions[question]?.eolProducts,
            [`${question}-comments`]: p100ProQuestions[question]?.comments,
          };
        }

        return {
          ...acc,
          [question]:
            typeof p100ProQuestions[question]?.answer === 'boolean'
              ? `${p100ProQuestions[question]?.answer}`
              : p100ProQuestions[question]?.answer,
          [`${question}-comments`]: p100ProQuestions[question]?.comments,
        };
      },
      { backupTypes: [], priorCoverage: accountData.priorCoverage }
    );
  }

  const defaultValues = accountData?.securityAnswers?.reduce(
    (acc: Record<string, string | boolean | string[]>, question) => {
      return {
        ...acc,
        [question.key]:
          typeof question.value === 'boolean'
            ? `${question.value}`
            : question.value,
        ...(question.comment
          ? {
              [`${question.key}-comments`]: question.comment,
            }
          : {}),
      };
    },
    { backupTypes: [] }
  );

  return (
    defaultValues || {
      backupTypes: [],
    }
  );
};

export const getFakeRaterData = async () => {
  await sleep(1000);

  return {
    data: {
      totalPremium: +(Math.random() * 10000).toFixed(2),
    },
  };
};

export const getFullName = (firstName: string, lastName: string) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return '-';
};

export const ProductApplicationResourceMapping = {
  [ProductTypes.p250]: {
    href: 'https://assets.cowbellcyber.ai/Cowbell-Cyber-Prime-250-Main-Application.pdf',
    download: 'Cowbell-Cyber-Prime-250-Main-Application.pdf',
  },
  [ProductTypes.p100]: {
    href: 'https://assets.cowbellcyber.ai/Cowbell_Cyber_Prime_100_Application_Quotes_Only.pdf',
    download: 'Cowbell-Cyber-Prime-100-Application-Quotes-Only.pdf',
  },
  [ProductTypes.p100_pro]: {
    href: 'https://assets.cowbellcyber.ai/Prime 100 Pro Application 01.22.25_V2.pdf',
    download: 'Cowbell-Cyber-Prime-100-Pro-Application.pdf',
  },
  [ProductTypes.prime_one]: {
    href: 'https://assets.cowbellcyber.ai/Prime One Application.pdf',
    download: 'Cowbell-Cyber-Prime-One-Pro-Application.pdf',
  },
} as const;

export const getCartPayload = ({ productType, data }: any) => {
  if (productType === ProductTypes.p100_pro) {
    if (!data.retroActivePeriod) {
      data.retroActivePeriod = -1;
    }

    const hydratedCoverages = data.coverages
      .map((coverage: any) => ({
        ...coverage,
        retroActivePeriod: data.retroActivePeriod,
      }))
      .filter(
        (_coverage: any) => _coverage.isSelected && _coverage.isAvailable
      );

    data.coverages = hydratedCoverages;

    return _.omit(data, ['commissionRate']);
  }

  if (productType === ProductTypes.prime_one) {
    const hydratedCoverages = data.coverages.filter(
      (_coverage: any) => _coverage.isSelected && _coverage.isAvailable
    );

    data.coverages = hydratedCoverages;

    return data;
  }

  return data;
};

export const getSecurityQuestionsByProduct = (
  accountData: AccountDto | undefined,
  productType: ProductType
) => {
  if (productType === ProductTypes.p100_pro) {
    return {
      securityQuestions: {
        priorCoverage: accountData?.priorCoverage,
        ...accountData?.p100ProQuestions,
      },
    };
  }

  if (productType === ProductTypes.prime_one) {
    return {
      securityAnswers: accountData?.securityAnswers,
    };
  }
};

export const getPresetSecurityQuestionsByProduct = (
  accountData: AccountDto | undefined,
  productType: ProductType
) => {
  if (productType === ProductTypes.p100_pro) {
    return {
      securityQuestions: {
        priorCoverage: accountData?.priorCoverage,
        ...accountData?.p100ProQuestions,
      },
    };
  }

  if (productType === ProductTypes.prime_one) {
    if (!accountData?.securityAnswers) {
      return {
        securityAnswers: [],
      };
    }
    return {
      securityAnswers: accountData?.securityAnswers as Record<string, any>[],
    };
  }
};

export const getRaterFirmoData = (
  accountData: AccountDto | undefined,
  productType: ProductType
) => {
  if (productType === ProductTypes.p100_pro) {
    return {
      raterFirmoData: {
        city: accountData?.city,
        companyType: accountData?.ownershipType,
        industryClass: accountData?.industryClass,
        primaryNaics: accountData?.naicsCode,
        revenue: accountData?.revenue,
        totalRevenue: accountData?.totalRevenue,
        nonUsRevenue: accountData?.nonUsRevenue,
        state: accountData?.state,
        yearEstablished: accountData?.yearEstablished,
      },
    };
  }

  return {
    primeOneRaterFirmoData: {
      city: accountData?.city,
      companyType: accountData?.ownershipType,
      industryClass: accountData?.industryClass,
      primaryNaics: accountData?.naicsCode,
      revenue: accountData?.revenue,
      totalRevenue: accountData?.totalRevenue,
      nonUsRevenue: accountData?.nonUsRevenue,
      state: accountData?.state,
      yearEstablished: accountData?.yearEstablished,
    },
    primaryNaics: accountData?.naicsCode,
    revenue: accountData?.revenue,
    city: accountData?.city,
    companyType: accountData?.ownershipType,
    industryClass: accountData?.industryClass,
    totalRevenue: accountData?.totalRevenue,
    nonUsRevenue: accountData?.nonUsRevenue,
    state: accountData?.state,
    yearEstablished: accountData?.yearEstablished,
  };
};

const dirtyComponents = new Set();
export const usePrompt = () => {
  const [show, setShow] = React.useState(false);

  const toggleShow = () => setShow((prev) => !prev);

  const determineIsViewDirty = (name: string, dirty: boolean) => {
    if (dirty) {
      dirtyComponents.add(name);
    } else {
      dirtyComponents.delete(name);
    }
    setShow(dirtyComponents.size >= 1);
  };

  return {
    show,
    setShow,
    toggleShow,
    determineIsViewDirty,
  };
};

export const determineIsLegacyProduct = (product: ProductType) => {
  return [
    ProductTypes.p100,
    ProductTypes.p250,
    ProductTypes.notSupported,
  ].includes(product as any);
};

interface GetIsAddToCartDisabled {
  quote: any;
  preset: Preset;
  quoteViewState: QuoteViewState;
}

export const getIsAddToCartDisabled = ({
  quote,
  preset,
  quoteViewState,
}: GetIsAddToCartDisabled) => {
  if (quote.product === ProductTypes.prime_one) {
    if (
      quote.presets === preset.name &&
      quote.aggLimit === preset.limit &&
      quote.deductible === preset.deductible &&
      Moment.utc(quote.effectiveDate).format('YYYY-MM-DD') ===
        quoteViewState.effectiveDate &&
      Moment.utc(quote.endDate).format('YYYY-MM-DD') ===
        quoteViewState.endDate &&
      quote.commissionRate === quoteViewState.commission
    ) {
      return true;
    }
    return false;
  }
  if (
    quote.presets === preset.name &&
    quote.aggLimit === preset.limit &&
    quote.deductible === preset.deductible &&
    Moment.utc(quote.effectiveDate).format('YYYY-MM-DD') ===
      quoteViewState.effectiveDate &&
    Moment.utc(quote.endDate).format('YYYY-MM-DD') === quoteViewState.endDate
  ) {
    return true;
  }
  return false;
};
