import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { delayedEvent } from '../../utils/eventUtils';
import { generatePolicyQuote } from '../../inbox/QuotesService';
import CbButton from '../Buttons/CbButton';

export const GeneratePolicy = ({ close, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const [error, setError] = useState('');
  const { id, quoteNumber, product } = data;

  const onSubmit = () => {
    return generatePolicyQuote(id, product, {})
      .then(() => {
        enqueueSnackbar(
          `Quote ${quoteNumber} submitted for policy generation`,
          { variant: 'success' }
        );
        delayedEvent('table-refetch', 500, 'adminQuotes');
        delayedEvent('table-refetch', 500, 'adminBinders');
        close();
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong while sending payment reminder.'
            )
          )
        );
      });
  };
  return (
    <FormContext>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div className="modal-title">
            Are you sure you want to submit quote#{' '}
            <span className="modal-blue-text">{quoteNumber}</span> to generate
            policy?
          </div>
          {error && (
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close}>
            Cancel
          </CbButton>
          <CbButton
            loading={isSubmitting}
            styleName="ctaButton"
            buttonText="Generate Policy"
            type="submit"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

export default GeneratePolicy;
