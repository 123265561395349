import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
// mui
import { Grid, DialogContent, DialogActions, useTheme } from '@mui/material';
// platform helpers
import _ from 'lodash';
import { useSnackbar } from 'notistack';
// actions
import { FormProvider, useForm } from 'react-hook-form';
import { updateAgent } from '../../../agencies/AgencyService';
// components
import CbButton from '../../Buttons/CbButton';
import {
  AccountLookupUpdateAgentFields,
  agentFinder,
  Value,
} from '../../../console/manage/account/lookup/AccountLookupUpdateAgentFields';
import { InputLabelBase } from '../../inputs/InputLabelBase';
import { COWBELL_ACCOUNTS } from '../../tables/table_constants';
import { withShowable } from '../../../console/_global/lib/withShowable';
// utils
import { manageAPIError } from '../../../utils';
import { reduceForAgencyInfo } from '../../../console/admin/users/UsersUtils';

export const CowbellUpdateAgent = ({ data, close }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const isSingleAgency = _.get(data, 'agencies', []).length === 1;

  const { defaultAgency, agentInfo } = React.useMemo(() => {
    return {
      defaultAgency: isSingleAgency
        ? {
            label: data.agencies[0].agencyName,
            value: data.agencies[0].agencyId,
          }
        : { label: '', value: '' },
      agentInfo: isSingleAgency
        ? agentFinder(data.agencies, data.agencies[0].agencyId)
        : agentFinder(),
    };
    // eslint-disable-next-line
  }, [data]);

  const form = useForm({
    defaultValues: {
      agent: { label: '', value: '' },
      team: { label: '', value: '' },
      agency: defaultAgency,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const selectedAgency = form.watch('agency');
  const selectedAgent = form.watch('agent');
  const selectedTeam = form.watch('team');

  function onSubmit(formValues) {
    const {
      policyContactFirstName,
      policyContactLastName,
      policyContactEmail,
      policyContactPhone,
      id,
      name,
    } = data;

    const agentPhone = _.get(formValues, 'agency.meta.contactPhone', undefined)
      ? _.get(formValues, 'agency.meta.contactPhone')
      : _.get(formValues, 'agent.meta.phone');

    const payload = {
      accountId: id,
      accountName: name,
      contactFirstName: policyContactFirstName ?? '',
      contactLastName: policyContactLastName ?? '',
      contactEmail: policyContactEmail,
      contactPhone: policyContactPhone,
      agentEmail: _.get(formValues, 'agent.value', ''),
      agentFirstName: _.get(formValues, 'agent.meta.firstName', ''),
      agentLastName: _.get(formValues, 'agent.meta.lastName', ''),
      agentPhone,
      team: {
        name: _.get(formValues, 'team.label', ''),
        id: _.get(formValues, 'team.value', ''),
      },
      agencyId: _.get(formValues, 'agency.value', ''),
    };

    return updateAgent(payload)
      .then(() => {
        enqueueSnackbar(
          `Account ${data.name} updated with Agent ${_.get(
            formValues,
            'agent.meta.firstName'
          )} ${_.get(formValues, 'agent.meta.lastName', '')}`,
          { variant: 'success' }
        );

        close();
        queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
      })
      .catch((error) => {
        enqueueSnackbar(
          manageAPIError(error, 'An error has occured. Please try again'),
          { variant: 'error' }
        );
        close();
      });
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2rem 5rem' }}>
          <Grid container>
            <Grid item md={5}>
              <InputLabelBase
                style={{ fontSize: theme.config.textSizes.tertia, padding: 0 }}
              >
                Account Name:
              </InputLabelBase>
            </Grid>
            <Grid item md={7}>
              <Value padding="0">{data.name}</Value>
            </Grid>
          </Grid>
          <AccountLookupUpdateAgentFields
            agencySearchOptions={() => reduceForAgencyInfo(data?.agencies)}
            isAgencyAssigned={_.get(data, 'agencies', []).length !== 0}
            data={data}
            agent={agentInfo}
          />
        </DialogContent>
        <DialogActions>
          <CbButton
            buttonText={_.isUndefined(data.agencies) ? 'OK' : 'Cancel'}
            action={close}
            styleName={_.isUndefined(data.agencies) ? 'ctaButton' : 'cancel'}
          />
          <HideButton
            show={!_.isUndefined(data.agencies)}
            buttonText="Update Agent"
            type="submit"
            loading={isSubmitting}
            disabled={
              isSubmitting ||
              !(
                _.get(selectedAgency, 'value') &&
                _.get(selectedAgent, 'value') &&
                _.get(selectedTeam, 'value')
              )
            }
            styleName="ctaButton"
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const HideButton = withShowable(CbButton);

export const CBUpdateAgentConfig = {
  CowbellUpdateAgent: {
    component: CowbellUpdateAgent,
    config: {
      title: 'Update Agent on the Account',
      maxWidth: 'sm',
    },
  },
};
