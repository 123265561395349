import { useQuery } from '@tanstack/react-query';
import { TERRITORY_CONTACT_QUERY_KEY } from './renewal-submissions.constants';

// apis
import {
  getBrokerConcerns,
  getLostReasons,
  getSubmissionStatuses,
  getTerritoryContacts,
} from '../../../../../api/submissions/renewal-submissions.api';
import { getCompetitors } from '../../../../../accounts/AccountService';

export const getContactLabel = (contact = {}) => {
  return (
    (contact.firstName ?? '') +
    (contact.lastName ? ` ${contact.lastName}` : '') +
    (contact.email ? ` (${contact.email})` : '')
  );
};

export const getOptionsFromContacts = (contacts = []) => {
  return contacts.map((contact) => ({
    label: getContactLabel(contact),
    value: contact.email,
    data: contact,
  }));
};

export const getBrokerConcernsAsOptions = async () => {
  return getBrokerConcerns()
    .then((resp) =>
      resp.data.map((brokerConcern) => ({
        label: brokerConcern,
        value: brokerConcern,
      }))
    )
    .catch(() => []);
};

export const getSubmissionStatusesAsOptions = async () => {
  return getSubmissionStatuses()
    .then((resp) =>
      resp.data.map((submissionStatus) => ({
        label: submissionStatus,
        value: submissionStatus,
      }))
    )
    .catch(() => []);
};

export const getLostReasonsAsOptions = async () => {
  return getLostReasons()
    .then((resp) =>
      resp.data.map((lostReason) => ({
        label: lostReason,
        value: lostReason,
      }))
    )
    .catch(() => []);
};

export const getCompetitorsAsOptions = async () => {
  return getCompetitors()
    .then((resp) =>
      Object.values(resp.data).map((competitor) => ({
        label: competitor.name,
        value: competitor.name,
      }))
    )
    .catch(() => []);
};

export const getRenewalAesAsOptions = () => {
  return getTerritoryContacts().then((resp) => {
    const renewalAes = resp.data.filter((contact) => contact.role === 'RAE');
    return getOptionsFromContacts(renewalAes);
  });
};

export const getRenewalUwsAsOptions = () => {
  return getTerritoryContacts().then((resp) => {
    const renewalUws = resp.data.filter((contact) => contact.role === 'RUW');
    return getOptionsFromContacts(renewalUws);
  });
};

export const useTerritoryContacts = ({ search, role }) => {
  return useQuery({
    queryKey: TERRITORY_CONTACT_QUERY_KEY,
    queryFn: () => getTerritoryContacts({ params: { search } }),
    select: (resp) => {
      if (!role) {
        return resp.data;
      }
      return resp.data.filter((contact) => contact.role === role);
    },
  });
};

export const ColumnNameAliases = {
  renewalAeEmail: 'RAE',
  renewalUwEmail: 'RUW',
  submissionStatus: 'Renewal Status',
  needByDate: 'Needby Date',
  appReceivedDate: 'Application Received Date',
  quoteSentDate: 'Quote Sent Date',
  brokerConcern: 'Broker Concern',
  lostReason: 'Lost Reason',
  isAffinityProgram: 'Programs',
  riskEngCall: 'Risk Engineering Call',
  riskEngCallScheduled: 'Risk Engineering Call Scheduled',
  lastHadContact: 'Last Had Contact',
};
