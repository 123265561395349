import { ServiceHandlerFactory } from '../utils/serviceUtils';
import { Api } from '../config/axiosConfig';
import { commonDateParamMappings } from '../api/common/mappings';

export const getAgencyBills = ServiceHandlerFactory({
  axios: {
    url: `/api/billing/v1/agencyBilledInvoices`,
  },
  isCancelable: false,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        const newOrder = order || 'asc';

        switch (orderBy) {
          case 'created':
          case 'year':
          case 'dueAmount':
          case 'month':
            return `${orderBy},${newOrder}`;
          default:
            return orderBy ? `${orderBy}.keyword,${newOrder}` : null;
        }
      },
    },
    ...commonDateParamMappings,
  ],
});

export const getInvoices = ServiceHandlerFactory({
  axios: {
    url: `api/billing/v1/invoices`,
  },
  isCancelable: false,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        const newOrder = order || 'asc';
        switch (orderBy) {
          case 'numberOfRemindersSent':
          case 'dueAmount':
          case 'isPremiumFinanced':
          case 'isAgencyBilled':
          case 'muteReminders':
          case 'isSurplus':
          case 'surplusFees':
          case 'dueDate':
          case 'policyCreated':
          case 'created':
          case 'modified':
          case 'commissionRate':
          case 'taxes':
          case 'fees':
          case 'commissionablePremium':
          case 'version':
          case 'commissionsAmount':
            return `${orderBy},${newOrder}`;
          default:
            return orderBy ? `${orderBy}.keyword,${newOrder}` : null;
        }
      },
    },
    ...commonDateParamMappings,
  ],
});

export function getInvoiceDocUrl(
  accountId,
  policyId,
  createdDate,
  isEndorsement
) {
  return Api.get(
    `/api/docs/v1/invoice?accountId=${accountId}&policyId=${policyId}&createdDate=${createdDate}&latest=true&isEndorsement=${isEndorsement}`,
    {
      responseType: 'application/json',
    }
  );
}
export function getAgencyBillDocId(agencyId, year, month) {
  return Api.get(
    `api/billing/v1/agencyBillInvoice/${month}/${year}?agencyId=${agencyId}`,
    {
      responseType: 'application/json',
    }
  );
}
export function getInvoiceUrl(docId) {
  return Api.get(`api/docs/v1/download?docId=${docId}`, {
    responseType: 'application/json',
  });
}
export function downloadAWSDoc(url) {
  return Api.get(url, { responseType: 'blob' });
}
