import React from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useQueryClient, useIsFetching } from '@tanstack/react-query';
import _ from 'lodash';

// devexpress table components
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnVisibility,
  DragDropProvider,
  TableColumnReordering,
  TableFixedColumns,
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import { SortingState } from '@devexpress/dx-react-grid';

// native components, utils
import {
  DefaultTableHeaderCell,
  TableHeaderCellContent,
} from './table-components/headerComponents';
import { DefaultTableRow } from './table-components/rowComponents';
import { TableStateContext } from './TableStateContext';
import { useWindowResizeEffect } from './hooks/useWindowResizeEffect';
import { mapColumnExtensions } from './table.utils.js';
import {
  FixedColumnCell,
  DefaultTableCell,
} from './table-components/cell-components/cell-components';

export const BaseTable = ({ ...props }) => {
  const { state, handlers, ...rest } = React.useContext(TableStateContext);
  return <BaseTableBase {...props} {...state} {...handlers} {...rest} />;
};

const getRowId = (row) => row.id;

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} />;

const usePaperStyles = makeStyles(({ breakpoints }) => ({
  root: {
    height: 'calc(100vh - 15.5rem)',
    [breakpoints.up('lg')]: {
      height: 'calc(100vh - 15.1rem)',
    },
  },
}));

const BaseTableBase = ({
  HeaderRowComponent,
  RowComponent,
  DataProcessors,
  Placeholder,
  height,
  isVirtualized = true,
  ...props
}) => {
  const qc = useQueryClient();
  const queryIsFetching = useIsFetching([props.tableId]);
  const paperClasses = usePaperStyles();

  const columns = React.useMemo(
    () =>
      Object.values(props.columns).map((column) => {
        return column.getCellValue
          ? {
              ...column,
              title: column.title || ' ',
              getCellValue: (row) => column.getCellValue(row, props.context),
            }
          : {
              ...column,
              title: column.title || ' ',
              getCellValue: (row) => row[column.name] || '-',
            };
      }),
    [props.columns, props.context]
  );

  const sortState = React.useMemo(
    () =>
      props.orderBy && props.order
        ? [{ columnName: props.orderBy, direction: props.order }]
        : null,
    [props.order, props.orderBy]
  );

  const columnExtensions = useWindowResizeEffect(
    (width) => mapColumnExtensions(columns, width),
    {
      delay: 500,
      id: props.tableId,
    }
  );

  React.useEffect(() => {
    return () => qc.resetQueries([props.tableId]);
    // eslint-disable-next-line
  }, []);

  if (
    !queryIsFetching &&
    Placeholder &&
    Array.isArray(props.rows) &&
    _.isEmpty(props.rows)
  ) {
    return <Placeholder />;
  }

  const TableComponent = isVirtualized ? VirtualTable : Table;

  return (
    <Paper
      id={props.tableId}
      classes={props.sticky ? paperClasses : null}
      style={{ height }}
      elevation={props.elevation ?? 6}
    >
      <Grid
        columns={columns}
        rows={props.rows || []}
        getRowId={getRowId}
        rootComponent={Root}
      >
        <SortingState
          sorting={sortState || []}
          onSortingChange={(newSort) => props.onSortingChange(newSort)}
          columnExtensions={props.sortingDisabledColumns}
        />

        {DataProcessors ? <DataProcessors /> : null}

        <TableComponent
          columnExtensions={columnExtensions}
          rowComponent={(rowProps) =>
            RowComponent ? (
              <RowComponent {...rowProps} />
            ) : (
              <DefaultTableRow {...rowProps} />
            )
          }
          cellComponent={DefaultTableCell}
        />

        {props.children}

        <TableColumnVisibility
          hiddenColumnNames={props.hiddenColumnNames}
          onHiddenColumnNamesChange={(newHidden) =>
            props.onHiddenColumnNamesChange(newHidden)
          }
        />

        <DragDropProvider />
        <TableColumnReordering
          order={props.columnOrder}
          onOrderChange={(newOrder) => props.onOrderChange(newOrder)}
        />

        {HeaderRowComponent ? (
          <HeaderRowComponent />
        ) : (
          <TableHeaderRow
            showSortingControls={!!sortState}
            cellComponent={DefaultTableHeaderCell}
            contentComponent={(headerContentProps) => (
              <TableHeaderCellContent
                context={props.context}
                {...headerContentProps}
              />
            )}
          />
        )}
        <TableFixedColumns
          leftColumns={props.fixedLeft}
          rightColumns={props.fixedRight}
          cellComponent={FixedColumnCell}
        />
      </Grid>
    </Paper>
  );
};

export default BaseTableBase;
