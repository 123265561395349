import React from 'react';
// lodash
import _ from 'lodash';
// mui
import { DialogActions, DialogContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import CbButton from '../../components/Buttons/CbButton';

export const ConfirmationBORChange = ({
  close,
  data,
  newAgency,
  handleFinalSubmit,
  loading,
}) => {
  const classes = useStyles();

  const onSubmit = () => {
    if (_.isFunction(handleFinalSubmit)) {
      handleFinalSubmit();
    }
  };

  return (
    <>
      <DialogContent className={classes.wrapper}>
        <p>
          Are you sure you want to change the BOR for{' '}
          <strong>{data.companyName}</strong> from{' '}
          <strong>{data.agencyName}</strong> to <strong>{newAgency}</strong>?
        </p>
        <p>
          Confirming this will send a separate email to both, <br />
          the incumbent agency <strong>{data.agencyName}</strong> as well as the
          new agency <strong>{newAgency}</strong>, notifying them of the
          process.
        </p>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid className={classes.pagination}>Step 3 of 3: Confirm</Grid>
          <Grid>
            <CbButton buttonText="Cancel" styleName="cancel" onClick={close} />
            <CbButton
              buttonText="Confirm"
              styleName="ctaButton"
              onClick={onSubmit}
              loading={loading}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const useStyles = makeStyles(({ config, palette }) => ({
  wrapper: {
    color: palette.primary.contrastText,
    padding: '2rem 5rem !important',
    fontSize: config.textSizes.primer,
    lineHeight: 1.2,
    textAlign: 'center',
  },
  pagination: {
    fontSize: config.textSizes.tertia,
    color: palette.primary.contrastText,
  },
}));
