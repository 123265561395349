import React from 'react';

import { DialogActions, DialogContent } from '@mui/material';

import CbButton from '../../Buttons/CbButton';

import { iconMappings } from '../../../console/_global/navigation/IconsMappings';

/**
 * Test modal will receive all props from the configuration, plus more...
 * iconStyles.root supplies styles for the title icon in case you're using the override config option
 */
export const TestModal = ({ iconStyles, ...props }) => {
  return (
    <>
      {/* <Dialog {...props}>
        <DialogTitle>
          <Box>
            <AssignmentOutlinedIcon className={iconStyles.root} /> Signup for Cowbell Sponsored Wizer
            Training
          </Box>
        </DialogTitle> */}
      <DialogContent>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod itaque
          quo autem at alias dolore harum, reprehenderit hic fugiat libero a
          repudiandae reiciendis, architecto possimus omnis, tempore voluptas
          dolorem. Veritatis?
        </p>
      </DialogContent>
      <DialogActions>
        <CbButton onClick={props.close} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton type="submit" onClick={props.close} styleName="ctaButton">
          Save Thing
        </CbButton>
      </DialogActions>
      {/* </Dialog> */}
    </>
  );
};

export const TestModalConfig = {
  TestModal: {
    component: TestModal,
    config: {
      /* any material ui configuration options  */
      fullWidth: true,
      // maxWidth: 'sm',

      /* take complete control of the modal, you have to render the Dialog */
      // override: true,

      /* indicate which theme the dialog inherits from (light, dark) */
      inheritTheme: 'light',

      /* if not using override, set the title */
      title: 'Testing modal title',

      /* if not using override set the icon, defaults to cowbell logo */
      icon: iconMappings.ACCOUNTS.iconImport,
    },
  },
};
