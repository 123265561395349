import React from 'react';
import { useFormContext } from 'react-hook-form-4';
import { SimpleSelect } from '../../../../inputs/selects/SimpleSelect';
import { getPresetData } from '../../../../../api/p250.service.js';

const CoveragesInput = ({ setCoverage, naicsCode }) => {
  const [data, setData] = React.useState([]);
  const { setValue } = useFormContext();

  const options = React.useMemo(() => mappingCoverageOptions(data), [data]);
  const coverageRef = React.useMemo(() => {
    return data.reduce(
      (accum, coverage) => ({
        ...accum,
        [coverage.coverage]: coverage,
      }),
      {}
    );
  }, [data]);

  function pickCoverage(event) {
    const selected = coverageRef[event.target.value];

    setCoverage(selected);
    setValue('name', event.target.value);
  }

  // get options
  React.useEffect(() => {
    // api to call and get available coverages
    if (naicsCode) {
      getPresetData({
        limit: 15000000,
        naicsCode,
      }).then((response) => {
        const coverageNames = Object.keys(response.data).map(
          (key) => response.data[key]
        );
        setData(coverageNames);
      });
    }
    // eslint-disable-next-line
  }, [naicsCode]);

  return (
    <SimpleSelect
      label="Coverage Name"
      options={options}
      onChange={pickCoverage}
      name="name"
      error
      required
      displayEmpty
      renderValue={renderValue}
    />
  );
};

function mappingCoverageOptions(coverages) {
  return coverages.reduce((covList, coverage) => {
    return [...covList, { label: coverage.coverage, value: coverage.coverage }];
  }, []);
  // return names;
}

function renderValue(value) {
  return value || 'Please Choose...';
}

export default CoveragesInput;
