import type { UseMutationOptions, QueryKey } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';

interface UseOptimisticMutationOptions<CacheType, MReturn, MParams>
  extends UseMutationOptions<
    MReturn,
    Error,
    MParams,
    { previousState: CacheType | undefined }
  > {
  syncCacheUpdate: (
    previousState: CacheType,
    mutationParams: MParams
  ) => CacheType;
  queryKey: QueryKey;
}

export const useOptimisticMutation = <CacheType>() => {
  const queryClient = useQueryClient();

  return <MReturn, MParams>({
    queryKey,
    mutationFn,
    onMutate,
    syncCacheUpdate,
    onError,
    ...useMutationOptions
  }: UseOptimisticMutationOptions<CacheType, MReturn, MParams>) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation<
      MReturn,
      Error,
      MParams,
      { previousState: CacheType | undefined }
    >({
      mutationFn,
      onMutate: async (...params) => {
        await queryClient.cancelQueries({ queryKey });

        const previousState = queryClient.getQueryData<CacheType>(queryKey);

        if (previousState === undefined) return { previousState };

        queryClient.setQueryData(queryKey, () =>
          syncCacheUpdate(previousState, ...params)
        );

        return { previousState };
      },

      onError: (err, _, context) => {
        queryClient.setQueryData(queryKey, context?.previousState);
        if (typeof onError === 'function') {
          return onError(err, _, context);
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
      ...useMutationOptions,
    });
};
