const POLICY_DETAILS_CONTACT_STEP = 'POLICY_DETAILS_CONTACT_STEP';
const POLICY_SURPLUS_COMPLIANCE_STEP = 'POLICY_SURPLUS_COMPLIANCE_STEP';
const POLICY_DETAILS_PREMIUM_STEP = 'POLICY_DETAILS_PREMIUM_STEP';
const POLICY_GENERATED_CONFIRMATION_STEP = 'POLICY_GENERATED_CONFIRMATION_STEP';
const POLICY_DOC_UPLOAD_STEP = 'POLICY_DOC_UPLOAD_STEP';
const POLICY_DOC_UPLOAD_CONFIRMATION_STEP =
  'POLICY_DOC_UPLOAD_CONFIRMATION_STEP';

export const CreateEditP500FlowSteps = {
  POLICY_DETAILS_CONTACT_STEP,
  POLICY_SURPLUS_COMPLIANCE_STEP,
  POLICY_DETAILS_PREMIUM_STEP,
  POLICY_GENERATED_CONFIRMATION_STEP,
  POLICY_DOC_UPLOAD_STEP,
  POLICY_DOC_UPLOAD_CONFIRMATION_STEP,
} as const;

export type CreateEditP500FlowStep =
  (typeof CreateEditP500FlowSteps)[keyof typeof CreateEditP500FlowSteps];
