import React from 'react';

const SVG = ({
  id = 'CSVIcon',
  style = {},
  fill = '#fff',
  className = '',
  viewBox = '0 0 11.425 12',
  title = 'CSVIcon',
}) => (
  <svg
    fill={fill}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
        .cls-1{fill:#fff}.cls-2{fill:#848280}
        `}
      </style>
    </defs>
    <title>{title}</title>
    <path
      id="Path_5356"
      d="M231.56 707.128h-5.737a.285.285 0 0 0-.284.286v11.136a.286.286 0 0 0 .286.286h8.281a.286.286 0 0 0 .286-.286v-8.591a.285.285 0 0 0-.084-.2l-2.545-2.545a.283.283 0 0 0-.203-.086z"
      className="cls-1"
      transform="translate(-223.112 -706.982)"
    />
    <path
      id="Path_5357"
      d="M234.307 709.549L231.761 707a.435.435 0 0 0-.3-.126h-5.737a.433.433 0 0 0-.43.432v11.136a.432.432 0 0 0 .432.432H234a.432.432 0 0 0 .432-.432v-8.591a.434.434 0 0 0-.125-.302zm-2.728-2.314l2.5 2.5h-2.358a.14.14 0 0 1-.139-.139zm2.421 11.35h-8.281a.14.14 0 0 1-.139-.139V707.31a.139.139 0 0 1 .138-.139h5.566v2.424a.432.432 0 0 0 .432.432h2.423v8.42a.139.139 0 0 1-.139.138z"
      className="cls-2"
      transform="translate(-223.009 -706.878)"
    />
    <g id="Group_8513" transform="translate(4.712 5.001)">
      <path
        id="Path_5358"
        d="M234.184 721.276h-1.428a.143.143 0 1 0 0 .286h1.428a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-230.757 -717.849)"
      />
      <path
        id="Path_5359"
        d="M230.87 718.348h-1.428v.286h1.428a.143.143 0 0 0 0-.286z"
        className="cls-2"
        transform="translate(-229.442 -716.635)"
      />
      <path
        id="Path_5360"
        d="M230.87 715.421h-1.428v.286h1.428a.143.143 0 0 0 0-.286z"
        className="cls-2"
        transform="translate(-229.442 -715.421)"
      />
      <path
        id="Path_5361"
        d="M230.87 716.885h-1.428v.286h1.428a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-229.442 -716.028)"
      />
      <path
        id="Path_5362"
        d="M236.17 715.708h1.43a.143.143 0 0 0 0-.286h-1.429a.143.143 0 0 0 0 .287z"
        className="cls-2"
        transform="translate(-232.172 -715.421)"
      />
      <path
        id="Path_5363"
        d="M230.87 719.811h-1.428v.286h1.428a.143.143 0 0 0 0-.286z"
        className="cls-2"
        transform="translate(-229.442 -717.241)"
      />
      <path
        id="Path_5364"
        d="M230.87 721.276h-1.428v.286h1.428a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-229.442 -717.849)"
      />
      <path
        id="Path_5365"
        d="M234.184 719.813h-1.428a.143.143 0 0 0 0 .286h1.428a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-230.757 -717.242)"
      />
      <path
        id="Path_5366"
        d="M237.6 718.349h-1.43a.143.143 0 1 0 0 .286h1.43a.143.143 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-232.172 -716.635)"
      />
      <path
        id="Path_5367"
        d="M237.6 719.813h-1.43a.143.143 0 1 0 0 .286h1.43a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-232.172 -717.242)"
      />
      <path
        id="Path_5368"
        d="M237.6 716.886h-1.43a.143.143 0 1 0 0 .286h1.43a.142.142 0 1 0 0-.284z"
        className="cls-2"
        transform="translate(-232.172 -716.029)"
      />
      <path
        id="Path_5369"
        d="M237.6 721.276h-1.43a.143.143 0 1 0 0 .286h1.43a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-232.172 -717.849)"
      />
      <path
        id="Path_5370"
        d="M234.184 718.349h-1.428a.143.143 0 0 0 0 .286h1.428a.143.143 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-230.757 -716.635)"
      />
      <path
        id="Path_5371"
        d="M234.184 716.886h-1.428a.143.143 0 0 0 0 .286h1.428a.142.142 0 1 0 0-.284z"
        className="cls-2"
        transform="translate(-230.757 -716.029)"
      />
      <path
        id="Path_5372"
        d="M234.184 715.421h-1.429a.143.143 0 0 0 0 .287h1.428a.143.143 0 0 0 0-.286z"
        className="cls-2"
        transform="translate(-230.757 -715.421)"
      />
    </g>
    <g id="Group_8514" transform="translate(0 4.098)">
      <rect
        id="Rectangle_4059"
        width="5.713"
        height="5.713"
        className="cls-2"
        rx="1"
      />
      <path
        id="Path_5334"
        d="M223.124 717.128c-.523 0-.731.246-.731.882 0 .688.165.945.724.945a4.547 4.547 0 0 0 .488-.049l-.011-.22s-.281.032-.414.032c-.436 0-.516-.19-.516-.71 0-.468.109-.644.513-.644.133 0 .414.031.414.031l.011-.214a4.094 4.094 0 0 0-.478-.053zm2.049.067a4.483 4.483 0 0 0-.637-.067c-.32 0-.632.127-.632.513 0 .351.19.425.585.488s.474.105.474.288c0 .239-.155.3-.443.3-.179 0-.579-.059-.579-.059l-.014.228a5.015 5.015 0 0 0 .619.065c.421 0 .681-.137.681-.548 0-.341-.165-.428-.6-.5-.372-.063-.457-.109-.457-.274 0-.207.165-.267.383-.267a5.722 5.722 0 0 1 .615.058zm.269-.032l.5 1.756h.527l.5-1.756h-.27l-.425 1.528h-.13l-.411-1.528z"
        className="cls-1"
        transform="translate(-221.808 -715.225)"
      />
    </g>
  </svg>
);

export default SVG;
