import React from 'react';
import {
  DialogContent,
  DialogActions,
  DialogContentText,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CbButton from '../../../components/Buttons/CbButton';
import { useSavedSettings } from '../../../components/tables/dev-express/hooks/useSavedSettings';
import { CMAIL } from '../cmail.statics';
import { DetailViewMode } from './useCmailState';
import { Flexbox } from '../../../components/layout/Flexbox';

const CmailSettingsModal = ({ data, ...props }) => {
  const { onDetailViewModeChange } = data;
  const { uiSettings, saveSetting } = useSavedSettings(CMAIL, {});
  const [detailViewMode, setDetailViewMode] = React.useState(
    uiSettings.detailViewMode ?? DetailViewMode.SIDE_BY_SIDE
  );

  const onSubmit = (event) => {
    event.preventDefault();
    saveSetting({ detailViewMode });
  };

  const onChange = (event, value) => {
    setDetailViewMode(value);
    onDetailViewModeChange(value);
  };

  return (
    <form onSubmit={onSubmit}>
      <DialogContent style={{ margin: '4rem 3rem 0' }}>
        <RadioGroup value={detailViewMode} onChange={onChange}>
          <FormControlLabel
            value={DetailViewMode.SIDE_BY_SIDE}
            control={<Radio color="secondary" />}
            style={{ margin: '1rem 0' }}
            label={
              <Flexbox alignItems="center">
                <img
                  src="images/Split_Screen_Gif.gif"
                  style={{
                    width: '7rem',
                    borderRadius: 5,
                    marginRight: '2rem',
                  }}
                  alt="split screen"
                />
                <DialogContentText style={{ margin: 0 }}>
                  Split Screen: screen is divided between Listing and Detail
                  views
                </DialogContentText>
              </Flexbox>
            }
          />
          <FormControlLabel
            value={DetailViewMode.FULL_SCREEN}
            control={<Radio color="secondary" />}
            style={{ margin: '1rem 0' }}
            label={
              <Flexbox alignItems="center">
                <img
                  src="images/Full_Screen_Gif.gif"
                  style={{
                    width: '7rem',
                    borderRadius: 5,
                    marginRight: '2rem',
                  }}
                  alt="split screen"
                />
                <DialogContentText style={{ margin: 0 }}>
                  Full Screen: screen toggles between Listing and Detail views
                </DialogContentText>
              </Flexbox>
            }
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <CbButton onClick={props.close} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton onClick={props.close} styleName="ctaButton" type="submit">
          Save
        </CbButton>
      </DialogActions>
    </form>
  );
};

export const CmailSettingsModalConfig = {
  CmailSettingsModal: {
    component: CmailSettingsModal,
    config: {
      maxWidth: 'md',
      title: 'cMail Settings',
      icon: <SettingsIcon />,
    },
  },
};
