import React from 'react';

const SVG = ({
  id = 'inbox',
  style = {},
  fill = '#9192a4',
  width = '100%',
  className = '',
  viewBox = '0 0 30 30',
  title = 'Inbox',
}) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .svg-inbox-1 { fill: none; }
          .${id}.svg-fill-inbox { fill: ${fill}; }
        `}
      </style>
    </defs>
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Isolation_Mode" data-name="Isolation Mode">
        <rect className="svg-inbox-1" width="30" height="30" />
        <path
          id="Path_241"
          data-name="Path 241"
          className={`${id} svg-fill-inbox`}
          d="M19.09,11.33h-2a.33.33,0,0,1-.33-.33V8a.33.33,0,0,0-.33-.33H13.56a.33.33,0,0,0-.33.33v3a.33.33,0,0,1-.33.33h-2a.34.34,0,0,0-.24.57L14.77,16a.32.32,0,0,0,.46,0l4.1-4.1A.33.33,0,0,0,19.09,11.33ZM23.56,4H6.43A2.43,2.43,0,0,0,4,6.43H4V23.56A2.43,2.43,0,0,0,6.42,26H23.56A2.45,2.45,0,0,0,26,23.56V6.44A2.45,2.45,0,0,0,23.56,4ZM23.4,24.66H6.6A1.27,1.27,0,0,1,5.34,23.4V19.86a.52.52,0,0,1,.52-.52H11a.65.65,0,0,1,.55.31,3.91,3.91,0,0,0,7,0,.66.66,0,0,1,.55-.31h5.1a.52.52,0,0,1,.52.52V23.4A1.27,1.27,0,0,1,23.4,24.66ZM24.14,18H18.56a1.33,1.33,0,0,0-1.25.83,2.44,2.44,0,0,1-4.6,0A1.31,1.31,0,0,0,11.47,18H5.86a.52.52,0,0,1-.52-.51V6.61A1.27,1.27,0,0,1,6.59,5.34H23.4a1.27,1.27,0,0,1,1.26,1.27V17.48A.52.52,0,0,1,24.14,18Z"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
