import Moment from 'moment';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

// types
import type { AxiosError, AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import type { Policy } from '../../../console/accounts/summary/types/policy.types';
import { ProductTypes } from '../../../types';

// apis
import { fetchLatestPolicyData } from '../../../api/policies.api';

// helpers
import { useAPIErrorHandler } from '../../../components/hooks/useAPIErrorHandler';
import { getData } from '../../../utils/functional/fp';
import type { PolicyTableInfoDto } from '../../../types/policy/tables/policy-table-info.dto';

interface UseGetLatestPolicyParams {
  policy: Pick<
    PolicyTableInfoDto,
    'product' | 'policyNumber' | 'endDate' | 'renewalOptOut'
  > &
    Partial<PolicyTableInfoDto>;
  queryOptions?: Omit<
    UseQueryOptions<
      AxiosResponse<Policy, unknown>,
      AxiosError<any, any>,
      Policy,
      string[]
    >,
    'initialData'
  >;
}

const useLatestPrime250PolicyFunctions = ({
  policy,
  queryOptions = {},
}: UseGetLatestPolicyParams) => {
  const handleAPIError = useAPIErrorHandler();

  const {
    data: latestPolicyData,
    isLoading,
    isError,
  } = useQuery({
    ...queryOptions,
    queryKey: ['latest-policy', policy.product, policy.policyNumber],
    queryFn: () => fetchLatestPolicyData(policy.product, policy.policyNumber),
    select: getData,
    onError: handleAPIError('Failed to fetch latest policy data'),
  });

  const policyEndDate = isError ? policy.endDate : latestPolicyData?.endDate;

  const today = Moment.utc().startOf('D');
  const endDate = Moment.utc(policyEndDate);
  const daysFromRenewal = Moment.duration(endDate.diff(today)).asDays();

  const canGenerateP250RenewalQuote =
    latestPolicyData?.product === ProductTypes.p250 &&
    daysFromRenewal <= 120 &&
    daysFromRenewal >= 0 &&
    isEmpty(policy.renewalOptOut);

  const additional6MonthsOnPolicy = Moment.utc(policyEndDate).add('6', 'M');
  const canMidTermEndorsePolicy =
    additional6MonthsOnPolicy.diff(today, 'days') > 0;

  return {
    canGenerateP250RenewalQuote,
    canMidTermEndorsePolicy,
    isLoading,
    isError,
  };
};

export default useLatestPrime250PolicyFunctions;
