import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import {
  DialogContent,
  DialogActions,
  Avatar,
  Slider,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Cropper from 'react-easy-crop';
import { PubSub } from '../../utils/eventUtils';
import CbButton from '../Buttons/CbButton';
import { postAgencyLogo, uploadAgencyLogoSmall } from '../../api/AuthService';

const useStyles = makeStyles(({ config }) => {
  return {
    wrapper: {
      fontSize: `${config.textSizes.tertia} !important`,
      textAlign: 'left !important',
      fontWeight: 'normal !important',
      display: 'flex',
      justifyContent: 'center',
    },
    flexBox: {
      display: 'flex',
      paddingTop: '1.66rem',
      justifyContent: 'space-around',
    },
    darkTheme: {
      width: 220,
      height: 105,
      borderRadius: '5px',
      backgroundColor: config.colors.darkGreyLighter,
    },
    lightTheme: {
      width: 220,
      height: 105,
      borderRadius: '5px',
      backgroundColor: config.colors.eggWhite,
      border: `1px solid ${config.colors.darkGrey3}`,
    },
    text: {
      fontSize: config.textSizes.normal,
      paddingTop: '0.833rem',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    avatar: {
      width: 450,
      height: 250,
    },
    negitive: {
      paddingRight: 10,
      position: 'relative',
      bottom: 3.5,
    },
    positive: {
      paddingLeft: 10,
      position: 'relative',
      bottom: 3.5,
    },
  };
});

export const UploadAgencyLogoMedium = ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cp, setCp] = useState({});
  const [error, setError] = useState('');
  const [pic, setPic] = useState(data.url);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const img = useRef(null);
  const cnv = useRef(null);
  const img1 = useRef(null);
  const cnv1 = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {}, []);

  useEffect(() => {
    const canvas = cnv.current;
    const image = img.current;
    const canvas1 = cnv1.current;
    const image1 = img.current;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, cp.width, cp.height);
    ctx.drawImage(
      image,
      cp.x,
      cp.y,
      cp.width,
      cp.height,
      0,
      0,
      cp.width,
      cp.height
    );

    const ctx1 = canvas1.getContext('2d');
    ctx1.clearRect(0, 0, cp.width, cp.height);
    ctx1.drawImage(
      image1,
      cp.x,
      cp.y,
      cp.width,
      cp.height,
      0,
      0,
      cp.width,
      cp.height
    );
    // eslint-disbale-next-line
  }, [cp.height, cp.width, cp.x, cp.y]);

  const onCropChange = (cropValue) => {
    setCrop(cropValue);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCp(croppedAreaPixels);
  };

  const onZoomChange = (zoomvalue) => {
    setZoom(zoomvalue);
  };

  const closeModal = () => {
    close();
    data.ref.current.value = '';
  };

  const selectFile = () => {
    if (fileInputRef) {
      fileInputRef.current.click();
    }
  };

  const changeImage = () => {
    const bodyFormData = new FormData();
    const file = fileInputRef.current.files[0];
    bodyFormData.append('file', file);
    const { size } = file;

    if (size / 1000 > 150) {
      setError('The file size is too big, 150kb max.');
    } else {
      setLoading(true);
      postAgencyLogo(data.id, false, bodyFormData)
        .then((resp) => {
          setLoading(false);
          setError('');
          setPic(resp.data);
        })
        .catch((error) => {
          setLoading(false);
          setError(error.response.data);
        });
    }
  };

  const onSubmit = () => {
    data.ref.current.value = '';
    setUploadLoading(true);

    const payload = {
      zoomFactor: zoom,
      width: Math.min(cp.width, img.current.width),
      height: Math.min(cp.height, img.current.height),
      logoPath: pic,
      agencyId: data.id,
      small: false,
    };
    uploadAgencyLogoSmall(
      { xCoordinate: cp.x < 0 ? 0 : cp.x, yCoordinate: cp.y < 0 ? 0 : cp.y },
      payload
    )
      .then(() => {
        setUploadLoading(false);
        enqueueSnackbar('Logo Uploaded Successfully!', {
          variant: 'success',
        });
        close();
        PubSub.publish('agency-medium:uploaded', true);
      })
      .catch((error) => {
        setUploadLoading(false);
        setError(
          _.get(
            error,
            'response.data',
            'Something went wrong.Please try again!'
          )
        );
      });
  };

  const canvasStyle = {
    width: 150,
    height: 35,
  };

  return (
    <>
      <DialogContent className="modal-title">
        <div className={classes.wrapper}>
          Please select a PNG, JPG or GIF image file with size smaller than
          150kb to upload and crop to be your profile picture.
        </div>
        <div className={classes.flexBox}>
          <div style={{ paddingRight: '1rem' }}>
            <Avatar variant="square" className={classes.avatar}>
              <Cropper
                image={pic}
                crop={crop}
                zoom={zoom}
                aspect={1}
                minZoom={0.4}
                restrictPosition={false}
                cropShape="rect"
                cropSize={{ width: 300, height: 72 }}
                showGrid={false}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
              />
            </Avatar>
            <div style={{ display: 'flex' }}>
              <span className={classes.negitive}>-</span>
              <Slider
                value={zoom}
                min={0.4}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ container: classes.slider }}
                onChange={(e, sliderZoom) => setZoom(sliderZoom)}
              />
              <span className={classes.positive}>+</span>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ paddingRight: '1rem' }}>
              <Avatar variant="square" className={classes.darkTheme}>
                <img
                  ref={img1}
                  id="source"
                  style={{ display: 'none' }}
                  src={pic}
                  alt="dark profile"
                />
                <canvas
                  ref={cnv1}
                  id="canvas"
                  width={cp.width}
                  height={cp.height}
                  style={canvasStyle}
                />
              </Avatar>
              <div className={classes.text}>DARK THEME</div>
            </div>

            <div>
              <Avatar variant="square" className={classes.lightTheme}>
                <img
                  ref={img}
                  id="source"
                  style={{ display: 'none' }}
                  src={pic}
                  alt="light profile"
                />
                <canvas
                  ref={cnv}
                  id="canvas"
                  width={cp.width}
                  height={cp.height}
                  style={canvasStyle}
                />
              </Avatar>
              <div className={classes.text}>LIGHT THEME</div>
            </div>
          </div>
        </div>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
      </DialogContent>
      <DialogActions style={{ display: 'block' }}>
        <div className={classes.buttonWrapper}>
          <div>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              type="file"
              ref={fileInputRef}
              onChange={changeImage}
            />
            <CbButton
              styleName="cancel"
              size="medium"
              onClick={selectFile}
              loading={loading}
              style={{ border: '1px solid #2180e2' }}
            >
              Change Image
            </CbButton>
          </div>

          <div>
            <CbButton styleName="cancel" size="small" onClick={closeModal}>
              Cancel
            </CbButton>
            <CbButton
              styleName="ctaButton"
              buttonText="Upload"
              loading={uploadLoading}
              onClick={onSubmit}
              size="small"
            />
          </div>
        </div>
      </DialogActions>
    </>
  );
};

export default UploadAgencyLogoMedium;
