import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
// mui
import { DialogContent, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/styles';

// platform helpers
import { compose } from 'redux';
import { delayedEvent, PubSub } from '../utils/eventUtils';

import { hasUrl } from '../utils/appUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../reducers/UiSettingsReducers';
// actions
import { deleteBulkQuote, deleteQuote } from './QuotesService';
import { withToggleModal } from '../components/hocs/withToggleModal';

const styles = ({ palette, config }) => {
  return {
    title: {
      fontFamily: 'Titillium Web',
      fontSize: config.textSizes.primer,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: palette.text.text8,
    },
    text: {
      color: palette.primary.title,
    },
    greenText: {
      color: palette.text.text4,
    },
    error: {
      textAlign: 'center',
      maxWidth: '600px',
      fontSize: config.textSizes.normal,
    },
  };
};

class DeleteQuote extends Component {
  constructor(props) {
    super(props);
    if (props.data.length >= 2) {
      this.state = {
        quoteId: props.data,
        quoteNumber: '',
        // companyName: '',
        isCustomer: hasUrl('customer/'),
        error: '',
        isBulk: true,
      };
    } else {
      this.state = {
        quoteId: '',
        quoteNumber: '',
        // companyName: '',
        isCustomer: hasUrl('customer/'),
        error: '',
        isBulk: false,
      };
    }
  }

  componentDidMount() {
    const {
      props: { data },
    } = this;

    if (data.length >= 2) {
      const quoteIds = data.map((quote) => quote.id);
      this.setState({
        quoteId: quoteIds,
      });
      this.subscription = PubSub.subscribe('foot-button-one', (eventData) => {
        if (eventData) {
          this.handleSubmit();
        }
      });
    } else {
      this.setState({
        quoteId: data.id,
        quoteNumber: data.quoteNumber,
      });
      this.subscription = PubSub.subscribe('foot-button-one', (eventData) => {
        if (eventData) {
          this.handleSubmit();
        }
      });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  handleSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    const updatePromise = this.state.isBulk
      ? deleteBulkQuote({ quoteIds: this.state.quoteId })
      : deleteQuote(this.state.quoteId);

    return updatePromise
      .then((response) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.props.toggleModal.direct('DeleteQuote', false);
        if (this.state.isCustomer) {
          delayedEvent('table-refetch', 500, 'customerQuotes');
        } else {
          delayedEvent('table-refetch', 500, AGENCY_QUOTES_LISTING_STORE_ID);
        }
        delayedEvent('table-refetch', 500, 'SalesInbox');
        delayedEvent('quote:read', 500);
        this.props.enqueueSnackbar('Quote Deleted Successfully!', {
          variant: 'success',
        });
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.props.enqueueSnackbar('Failed to delete quote', {
          variant: 'error',
        });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <DialogContent>
        {this.state.isBulk ? (
          <div className="modal-title">
            Are you sure you want to delete the selected Quotes?
          </div>
        ) : (
          <div className="modal-title">
            Are you sure you want to delete the Quote{' '}
            <span className={classes.greenText}> {this.state.quoteNumber}</span>{' '}
            ?
          </div>
        )}
        <FormHelperText className="api-text-error">
          {this.state.error}
        </FormHelperText>
      </DialogContent>
    );
  }
}

export default compose(
  withToggleModal,
  withSnackbar,
  withStyles(styles)
)(DeleteQuote);
