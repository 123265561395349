import type { SvgIconProps } from '@mui/material';
import {
  styled,
  Typography as TypographyBase,
  Alert as AlertBase,
  useTheme,
} from '@mui/material';
import color from 'color';
import type { ReactNode } from 'react';
import React from 'react';
import SpotlightIcon from '../../_assets/svg/SpotlightIcon.svg';
import { Show } from '../Show';
import SpotlightNotification from './SpotlightNotification';

type Props = {
  title: string;
  subtitle?: string;
  children?: ReactNode;
};

export default function SpotlightBanner({ title, subtitle, children }: Props) {
  return (
    <Alert
      variant="outlined"
      color="warning"
      severity="warning"
      icon={
        <ThemedSpotlightIcon
          style={{ fontSize: '3.5rem', cursor: 'default' }}
        />
      }
    >
      <Typography variant="subtitle1">{title}</Typography>
      <Show when={!!children && !subtitle}>{children}</Show>
      <Show when={!!subtitle}>
        <Typography>{subtitle}</Typography>
      </Show>
    </Alert>
  );
}

export type ThemedSpotlightIconProps = React.ComponentProps<
  typeof SpotlightIcon
>;

export const ThemedSpotlightIcon = ({
  style = { fontSize: '1.5rem' },
  ...props
}: ThemedSpotlightIconProps) => {
  const pal = useTheme().palette;

  const iconColor =
    pal.mode === 'light'
      ? color(pal.warning.main).darken(0.2).toString()
      : pal.warning.main;

  return <SpotlightIcon style={style} color={iconColor} {...props} />;
};

const Typography = styled(TypographyBase)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? color(theme.palette.warning.main).darken(0.2).toString()
      : theme.palette.primary.main,
  fontWeight: 'bold',
}));

const Alert = styled(AlertBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '1rem 0',
  fontWeight: 'bold',
  ...(theme.palette.mode === 'light'
    ? {
        border: `3px solid ${theme.palette.warning.main}`,
        backgroundColor: `${color(theme.palette.warning.main)
          .lighten(0.8)
          .toString()}`,
      }
    : {
        border: `3px solid ${theme.palette.warning.dark}`,
        backgroundColor: `${color(theme.palette.warning.dark)
          .darken(0.8)
          .toString()}`,
      }),
}));
