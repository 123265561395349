import React from 'react';

import { DialogActions, DialogContent, DialogContentText } from '@mui/material';

import CbButton from '../../Buttons/CbButton';

import { iconMappings } from '../../../console/_global/navigation/IconsMappings';

export const DeleteModal = ({ data, ...props }) => {
  const { message, buttonLabel, handleDelete: handleDeleteBase } = data;

  function handleDelete() {
    handleDeleteBase().finally(props.close);
  }

  return (
    <>
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center' }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CbButton
          onClick={props.close}
          styleName="cancel"
          buttonText="Cancel"
        />
        <CbButton
          type="submit"
          onClick={handleDelete}
          styleName="ctaButton"
          buttonText={buttonLabel}
        />
      </DialogActions>
    </>
  );
};

export const DeleteModalConfig = {
  DeleteModal: {
    component: DeleteModal,
    config: {
      /* any material ui configuration options  */
      fullWidth: true,

      /* indicate which theme the dialog inherits from (light, dark) */
      inheritTheme: 'dark',

      /* if not using override, set the title */
      title: 'Delete Item',

      /* if not using override set the icon, defaults to cowbell logo */
      icon: iconMappings.Delete.iconImport,
    },
  },
};
