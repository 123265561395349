import React from 'react';
import { Paper, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const PrimaryToolbar = ({
  style = {},
  sticky = true,
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Paper
      elevation={6}
      className={classes.toolbar}
      style={
        sticky ? { position: 'sticky', top: '4.4rem', ...style } : { ...style }
      }
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        {...props}
      >
        {children}
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  toolbar: {
    height: '5rem',
    padding: '1rem',
    marginBottom: '1rem',
    zIndex: 100,
  },
}));
