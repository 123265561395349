import React from 'react';

// components
import { MenuItem as MuiMenuItem } from '@mui/material';
import ProtectedComponent from '../../../../components/main/protected/ProtectedComponent';
import { withShowable } from '../../../_global/lib/withShowable';

const MenuItem = withShowable(MuiMenuItem);

export const ProtectedMenuItem = ({
  allow = { inbox: 'manage' },
  allowEither = undefined,
  allowIfAll = undefined,
  show = true,
  ...props
}) => {
  if (!show) {
    return null;
  }
  return (
    <ProtectedComponent
      allow={allow}
      allowEither={allowEither}
      allowIfAll={allowIfAll}
      {...props}
    >
      <MenuItem {...props} />
    </ProtectedComponent>
  );
};
