import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { Card, CardContent } from '@mui/material';

/* component */
/* Actions */
import { compose } from 'redux';

import { withStyles } from '@mui/styles';
import { deleteBlackListedDomains } from '../../api/apis';
import { publishEvent } from '../../utils/eventUtils';
import CBButton from '../Buttons/CbButton';
import { withToggleModal } from '../hocs/withToggleModal';

const styles = ({ palette }) => {
  return {
    card: {
      background: 'inherit',
      outline: 'none',
      padding: '0',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
    },
    cardContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      width: '700px',
      height: '150px',
      margin: 'auto',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    insideWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    dataWrapper: {
      width: '100%',
    },
    title: {
      fontFamily: 'Titillium Web',
      fontSize: '17px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      textAlign: 'center',
      marginTop: 50,
      color: palette.text.link,
    },
    buttonBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginBottom: 35,
    },
    color: {
      opacity: 0.9,
    },
    text: {
      color: palette.primary.title,
      fontSize: '17px',
    },
  };
};

class BlackListDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      disabled: false,
    };
  }

  componentDidMount() {
    const {
      props: { data },
    } = this;

    this.setState({
      name: data.name,
    });
  }

  cancelAction = (event) => {
    event.stopPropagation();
    this.props.toggleModal.direct('BlackListDeleteConfirm', false);
    setTimeout(() => {
      publishEvent('table:force-fetch-domains');
    }, 250);
  };

  handleSubmit = (event) => {
    event.stopPropagation();
    this.setState({
      disabled: true,
    });
    deleteBlackListedDomains({
      domainName: this.state.name,
    })
      .then(() => {
        this.props.toggleModal.direct('BlackListDeleteConfirm', false);
        this.props.enqueueSnackbar('User successfully deleted', {
          variant: 'success',
        });
        setTimeout(() => {
          publishEvent('table:force-fetch-domains');
        }, 250);
        this.setState({
          disabled: false,
        });
      })
      .catch((error) => {
        this.props.toggleModal.direct('BlackListDeleteConfirm', false);
        this.props.enqueueSnackbar(
          `Failed to delete domain: ${error.response.data}`,
          { variant: 'error' }
        );
        this.setState({
          disabled: false,
        });
      });
  };

  render() {
    const { classes } = this.props;
    const { disabled } = this.state;

    return (
      <div className={classes.color}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.title}>
              You are about to remove the domain
              <span className={classes.text}> {this.state.name}</span> from the
              blacklist. <br />
              Are you sure you want to do this?
            </div>
          </CardContent>
        </Card>
        <div className={classes.buttonBox}>
          <CBButton
            action={this.cancelAction}
            buttonText="Cancel"
            size="large"
            styleName="cancel"
          />
          <CBButton
            disabled={disabled}
            loading={disabled}
            action={this.handleSubmit}
            buttonText="Delete Domain"
            size="large"
            styleName="ctaButton"
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withToggleModal,
  withSnackbar,
  withStyles(styles)
)(BlackListDeleteModal);
