import React from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import { styled } from '@mui/styles';

import CbButton from '../../../../../Buttons/CbButton';
import { getRenewalOptoutReasons } from '../../../../../../policies/PolicyService';
import { ManagedFetchingAutoComplete } from '../../../../../inputs/autocomplete/ManagedFetchingAutoComplete';
import { withFormController } from '../../../../../hocs/forms';
import { TextAreaBase } from '../../../../../inputs/TextAreaBase';
import { TextFieldBase } from '../../../../../inputs/TextFieldBase';

import { withAutocompleteFormControllerBase } from '../../../../../hocs/withAutocompleteFormController';

import { getCompetitors } from '../../../../../../accounts/AccountService';

export const DialogActionsBase = ({
  onCancel,
  onConfirm,
  isLoading,
  isSubmitDisabled,
  confirmationButtonText = 'Next',
}) => (
  <DialogActions>
    <CbButton onClick={onCancel} styleName="cancel">
      Cancel
    </CbButton>
    <CbButton
      type={typeof onConfirm == 'function' ? 'button' : 'submit'}
      onClick={onConfirm}
      loading={isLoading}
      disabled={isSubmitDisabled}
      styleName="ctaButton"
      buttonText={confirmationButtonText}
    />
  </DialogActions>
);

export const DialogContentBase = styled(DialogContent)(({ theme }) => ({
  paddingTop: '12px !important',
  padding: '12px 3.5rem',
  color: theme.palette.primary.main,
}));

export const ManagedRenewalOptOutReasonAutocomplete = ({ ...props }) => {
  const queryKey = ['renewal-opt-out-reasons'];
  function fetchFuncton() {
    return getRenewalOptoutReasons().then((res) =>
      res.data.map((reason) => ({ id: reason, name: reason }))
    );
  }

  return (
    <ManagedFetchingAutoComplete
      {...props}
      queryKey={queryKey}
      fetchFunc={fetchFuncton}
    />
  );
};

export const ControlledRenewalOptOutReasonAutocomplete =
  withAutocompleteFormControllerBase(ManagedRenewalOptOutReasonAutocomplete);

export const ManagedCompetitorsAutocomplete = ({ ...props }) => {
  const queryKey = ['competitors'];
  function fetchFuncton() {
    return getCompetitors().then((res) => {
      return Object.entries(res.data).map(([id, value]) => ({ id, ...value }));
    });
  }

  return (
    <ManagedFetchingAutoComplete
      {...props}
      queryKey={queryKey}
      fetchFunc={fetchFuncton}
    />
  );
};

export const ControlledCompetitorsAutocomplete =
  withAutocompleteFormControllerBase(ManagedCompetitorsAutocomplete);

export const ControlledTextArea = withFormController(TextAreaBase);
export const ControlledTextField = withFormController(TextFieldBase);
