import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUISettings } from '../../../reducers/UiSettingsReducers';
import withRouter from '../../hocs/withRouter';

export const withFilterStore = (Component) => {
  return withRouter(({ history, location, storeId, q, ...props }) => {
    const dispatch = useDispatch();
    const persisted = useSelector((state) => state.uiSettings[storeId]);

    React.useEffect(() => {
      if (storeId && persisted && q.fn !== persisted.filterPreset) {
        history.replace(
          `${location.pathname}?${persisted.filterPresetString}&fn=${persisted.filterPreset}`
        );
      }
      // eslint-disable-next-line
    }, []);

    const onFilterSelect = React.useCallback((name, filterStr) => {
      dispatch(
        updateUISettings({
          domain: storeId,
          update: {
            filterPreset: name,
            filterPresetString: filterStr,
          },
        })
      );
      // eslint-disable-next-line
    }, []);

    const onRemoveSelectedFilter = React.useCallback(() => {
      dispatch(
        updateUISettings({
          domain: storeId,
          update: {
            filterPreset: undefined,
            filterPresetString: undefined,
          },
        })
      );
      // eslint-disable-next-line
    }, []);

    return (
      <Component
        onSelect={onFilterSelect}
        onNoFilters={onRemoveSelectedFilter}
        {...props}
      />
    );
  });
};
