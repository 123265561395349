import React from 'react';
import * as Ramda from 'ramda';
import { useDispatch } from 'react-redux';
import { useFeature } from '@growthbook/growthbook-react';
import { mappedModals } from './components/modals/v2';

import './config/appConfig';

import GlobalStyles from './GlobalStyles';
import { setModals } from './reducers/global-modals.reducer.js';
import { RouteResolver } from './RouteResolver';
import { PubSub } from './utils/eventUtils';
import NetworkLayer from './workers/NetworkLayer';
import VersionLayer from './workers/VersionLayer';

import { fetchConsoleVersion } from './api/apis';
import { setUIVersion } from './reducers/UiSettingsReducers';
import { useChameleon } from './utility/Chameleon';
import { getData } from './utils/functional/fp';
import { FEATURE_FLAG_KEYS } from './config/growthbook';

const { REACT_APP_VERSION } = import.meta.env;

const App = () => {
  useInit();
  useListeners();
  useChameleon();

  return (
    <>
      <GlobalStyles />
      <RouteResolver />
    </>
  );
};

export default App;

function useListeners() {
  React.useEffect(() => {
    const serverDownEvent = PubSub.subscribe('server-down', () => {
      window.location.href = '/serverdown';
    });

    // clean up func
    return () => {
      serverDownEvent.remove();
    };
    // eslint-disable-next-line
  }, []);
}

function useInit() {
  const dispatch = useDispatch();
  const cloudflareFeature = useFeature(FEATURE_FLAG_KEYS.CLOUDFLARE);

  React.useEffect(() => {
    const dispatchUIVersionChange = Ramda.compose(dispatch, setUIVersion);

    // set app's current version within Redux
    if (cloudflareFeature.on) {
      dispatchUIVersionChange({ v: REACT_APP_VERSION });
    } else {
      fetchConsoleVersion().then(getData).then(dispatchUIVersionChange).catch();
    }

    dispatch(setModals(mappedModals));

    NetworkLayer.init();
    VersionLayer.init();
    // eslint-disable-next-line
  }, []);
}
