import { DialogActions } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import CbButton from '../../../Buttons/CbButton';
import Showable from '../../../Showable';

export const ConnectorActions = ({ handleClose, stepperProps }) => {
  const formContext = useFormContext();

  return (
    <DialogActions>
      <Showable show={stepperProps.nextStep}>
        <CbButton onClick={handleClose} styleName="cancel">
          Cancel
        </CbButton>
      </Showable>

      <Showable show={stepperProps.previousStep && stepperProps.nextStep}>
        <CbButton onClick={stepperProps.goBack} styleName="cancel">
          Back
        </CbButton>
      </Showable>

      <Showable show={stepperProps.nextStep}>
        <CbButton
          type="submit"
          styleName="ctaButton"
          form="connector"
          loading={formContext.formState.isSubmitting}
          disabled={formContext.formState.isSubmitting}
        >
          Next
        </CbButton>
      </Showable>

      <Showable show={!stepperProps.nextStep}>
        <CbButton type="submit" styleName="ctaButton" form="connector">
          Close
        </CbButton>
      </Showable>
    </DialogActions>
  );
};
