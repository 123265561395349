import React from 'react';

import { DialogActions, DialogContent, Typography } from '@mui/material';
import CbButton from '../../Buttons/CbButton';

/**
 * Returns a modal with a message and two buttons - confirm and cancel.
 * @param {Object} data - Contains custom info that will be used to render the modal.
 * @param {String} data.body - Message that will be displayed inside the modal.
 * @param {Function} data.onConfirm - Function that will be fired when user clicks on the confirm button.
 * @param {Function} [data.onCancel] - Function that will be fired when user clicks on the cancel button.
 * @param {String} [data.confirmButton] - Optional text that can be displayed instead of "Confirm".
 * @param {String} [data.cancelButton] - Optional text that can be displayed instead of "Cancel".
 */
export const SimpleConfirm = ({ data = {}, ...props }) => {
  const { body, onConfirm, onCancel, cancelButtonProps, confirmButtonProps } =
    data;

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    props.close();
  };

  const handleConfirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm();
    }
    props.close();
  };

  return (
    <>
      <DialogContent>
        <Typography color="primary">{body}</Typography>
      </DialogContent>
      <DialogActions>
        <CbButton
          onClick={handleCancel}
          styleName="cancel"
          {...cancelButtonProps}
        >
          {data.cancelButton || 'Cancel'}
        </CbButton>
        <CbButton
          type="submit"
          onClick={handleConfirm}
          styleName="ctaButton"
          {...confirmButtonProps}
        >
          {data.confirmButton || 'Confirm'}
        </CbButton>
      </DialogActions>
    </>
  );
};

export const SimpleConfirmConfig = {
  SimpleConfirm: {
    component: SimpleConfirm,
    // all mui related config
    config: {
      fullWidth: true,
      title: 'Simple Confirm Modal',
    },
  },
};
