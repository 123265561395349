import React from 'react';
// draft.js
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';

const Wrapped = React.forwardRef(({ Component, ...props }, ref) => {
  return <Component {...props} ref={ref} />;
});

export const withEditorFunctions = (Component) => {
  return ({ ...props }) => {
    const [editorState, setEditorState] = React.useState(() =>
      EditorState.moveFocusToEnd(EditorState.createEmpty())
    );

    const editor = React.useRef(null);

    const focusEditor = () => {
      editor.current.focus();
    };

    const setEditor = (state) => {
      setEditorState(state);
    };

    const createEmptyEditor = () => {
      setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()));
    };

    const createEditorWithText = (text) => {
      return EditorState.moveFocusToEnd(
        EditorState.createWithContent(ContentState.createFromText(text))
      );
    };

    const getEditorText = () => {
      const contentState = editorState.getCurrentContent();
      return convertToRaw(contentState).blocks[0].text;
    };

    return (
      <Wrapped
        Component={Component}
        {...props}
        ref={editor}
        editorState={editorState}
        focusEditor={focusEditor}
        createEmptyEditor={createEmptyEditor}
        createEditorWithText={createEditorWithText}
        getEditorText={getEditorText}
        setEditor={setEditor}
      />
    );
  };
};
