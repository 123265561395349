import { Allow } from '../../../../../../components/auth/Allow';
import ReferToUWButton from './ReferToUWButton';

type CowbellReferToUWButtonProps = React.ComponentPropsWithoutRef<
  typeof ReferToUWButton
>;

function CowbellReferToUWButton({ ...props }: CowbellReferToUWButtonProps) {
  return (
    <Allow allow={['account:cowbell']}>
      <ReferToUWButton {...props} />
    </Allow>
  );
}

export default CowbellReferToUWButton;
