import Moment from 'moment';

// mui
import { MenuItem as MuiMenuItem } from '@mui/material';

// hocs
import { useHistory } from 'react-router-dom';
import type { AxiosResponse } from 'axios';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useFeature } from '@growthbook/growthbook-react';
import PoliciesService from '../../console/policies/policies.service';
import { withShowable } from '../../console/_global/lib/withShowable';

// hooks
import { useScopes } from '../../components/hooks/useScopes';

// components
import { ListItemBase } from '../../components/Menus/ListItemBase';
import { SimpleMenu } from '../../components/Menus/SimpleMenu';
import { SubMenu } from '../../components/Menus/SubMenu';

import { Modal } from '../../components/modals/v2/helpers/v2.modal.helpers';

// enums
import {
  CANCELLED_STATUS,
  IN_FORCE_STATUS,
  VOID_STATUS,
} from '../../console/_statics/policy.statics';
import { determineIfAllowedScopes } from '../../utils/next/auth.utils';
import { CommonPolicyOptions } from './CommonPolicyOptions';
import { ProductTypes, type ProductType } from '../../types';
import { toggleModalDirect } from '../../utils/storeUtils';
import { loadOneIncByPolicyNumber } from '../../utils/appUtils';
import {
  adminPaymentComplete,
  getBalanceOnPolicy,
} from '../../console/customers/_services/BillingService';
import { FEATURE_FLAG_KEYS } from '../../config/growthbook';

interface P100ProCowbellPolicyOptionsProps {
  policy: Policy;
  handleTableRefetch: () => void;
  isJQueryLoaded: boolean;
  setShowCircular: (show: boolean) => void;
}

const MenuItem = withShowable(MuiMenuItem);
const ListItem = withShowable(ListItemBase);

const PrimeXCowbellPolicyOptions = ({
  policy,
  handleTableRefetch,
  isJQueryLoaded,
  setShowCircular,
}: P100ProCowbellPolicyOptionsProps) => {
  const handlers = useMenuItemHandlers({
    policy,
    handleTableRefetch,
    setShowCircular,
  });
  const actions = useP100ProPolicyActions({ policy });

  return (
    <SimpleMenu minWidth="19rem">
      <MenuItem show={actions.canGenerateLossRuns} onClick={handlers.lossRuns}>
        Loss Runs
      </MenuItem>

      <MenuItem
        show={actions.canChangeBilling}
        onClick={handlers.changeBillingCompliance}
      >
        Update Billing/Compliance
      </MenuItem>

      <MenuItem show={actions.canVoidPolicy} onClick={handlers.voidPolicy}>
        Void Policy
      </MenuItem>

      <SubMenu closeMenu header="Payments" show={actions.isPaymentSubMenuShown}>
        <ListItem
          onClick={handlers.applyPayment}
          name="Payment/Refund"
          show={actions.canApplyPaymentOrIssueRefund}
        />

        <ListItem
          onClick={() => handlers.handleCharge('bankAccount')}
          disabled={!isJQueryLoaded}
          name="Charge Bank Account"
          show={actions.canChargeBankAccount}
        />

        <ListItem
          show={actions.canChargeCreditCard}
          onClick={() => handlers.handleCharge('creditCard')}
          disabled={!isJQueryLoaded}
          name="Charge Credit Card"
        />

        <ListItem
          show={actions.canMarkCheckInMail}
          onClick={handlers.markCheckInMail}
          name="Mark as Check In Mail"
        />
      </SubMenu>

      <SubMenu
        show={actions.isPolicyActionsSubmenuShown}
        closeMenu
        header="Policy Actions"
      >
        <ListItem
          onClick={handlers.cancelPolicy}
          name="Cancel Policy"
          show={actions.canCancelPolicy}
        />

        <ListItem
          onClick={handlers.onRegenerateInvoice}
          name="Regenerate Invoice"
          show={actions.canRegeneratePolicy}
        />

        <ListItem
          onClick={handlers.regeneratePolicyDocuments}
          name="Regenerate Policy Documents"
          show={actions.canRegeneratePolicyDocuments}
        />
      </SubMenu>

      <CommonPolicyOptions data={policy} />
    </SimpleMenu>
  );
};

const useP100ProPolicyActions = ({ policy }: { policy: Policy }) => {
  const p100ProCancellationsFeature = useFeature(
    FEATURE_FLAG_KEYS.P100PRO_CANCELLATIONS
  );
  const scopes = useScopes();

  const isVoidable = (policy: Policy) => {
    // Shamelessly copied from policies.service.ts so this may change
    const END_OF_CURRENT_MONTH = Moment.utc().endOf('month');
    const START_OF_PREVIOUS_MONTH = Moment.utc()
      .subtract(1, 'month')
      .startOf('month');
    //check for between or start of month
    const BETWEEN_START_END_OF_MONTH = Moment.utc(
      policy.effectiveDate
    ).isBetween(START_OF_PREVIOUS_MONTH, END_OF_CURRENT_MONTH, undefined, '[]');
    //enable option if policy's effective date is in the future
    const IN_FUTURE = Moment.utc(policy.effectiveDate).isAfter(new Date());

    return BETWEEN_START_END_OF_MONTH || IN_FUTURE;
  };

  const canApplyPaymentOrIssueRefund = determineIfAllowedScopes(scopes, [
    'account:cowbell',
    'account_profile:manage',
  ]);

  const canGenerateLossRuns =
    !policy.isLossRunsAvailable &&
    ![CANCELLED_STATUS, VOID_STATUS].includes(policy.status);

  const canVoidPolicy =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    isVoidable(policy) &&
    !policy.isEndorsement;

  const canRegeneratePolicy =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    PoliciesService.isActivePolicyByStatus(policy);

  const canRegeneratePolicyDocuments =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    PoliciesService.isActivePolicyByStatus(policy);

  const isPolicyActionsSubmenuShown =
    canRegeneratePolicy || canRegeneratePolicyDocuments;

  const canChangeBilling = (function IIFE() {
    const today = Moment().startOf('day');
    const effectiveDate = Moment.utc(policy.effectiveDate);

    const isEffectiveDateGreaterThanThirtyDaysAgo =
      today.diff(effectiveDate, 'days') > 30;

    return (
      !policy.isEndorsement &&
      PoliciesService.isActivePolicyByStatus(policy) &&
      PoliciesService.isPayable(policy) &&
      (!isEffectiveDateGreaterThanThirtyDaysAgo || !policy.isSurplus)
    );
  })();

  const canCancelPolicy =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    policy.status === IN_FORCE_STATUS &&
    !policy.isErp &&
    !policy.isEndorsement &&
    p100ProCancellationsFeature.on;

  const canChargeBankAccount =
    determineIfAllowedScopes(scopes, ['account:cowbell', 'role:admin']) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    PoliciesService.isPayable(policy) &&
    policy.product !== ProductTypes.prime_one;

  const canChargeCreditCard =
    determineIfAllowedScopes(scopes, ['account:cowbell', 'role:admin']) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    PoliciesService.isPayable(policy) &&
    policy.product !== ProductTypes.prime_one;

  const canMarkCheckInMail =
    determineIfAllowedScopes(scopes, ['account:cowbell', 'role:admin']) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    PoliciesService.isPayable(policy) &&
    policy.product !== ProductTypes.prime_one;

  const isPaymentSubMenuShown =
    canApplyPaymentOrIssueRefund ||
    canChargeBankAccount ||
    canChargeCreditCard ||
    canMarkCheckInMail;

  return {
    canApplyPaymentOrIssueRefund,
    canGenerateLossRuns,
    canVoidPolicy,
    canRegeneratePolicy,
    canRegeneratePolicyDocuments,
    isPolicyActionsSubmenuShown,
    canChangeBilling,
    canCancelPolicy,
    canChargeBankAccount,
    canChargeCreditCard,
    canMarkCheckInMail,
    isPaymentSubMenuShown,
  };
};

const useMenuItemHandlers = ({
  policy,
  handleTableRefetch,
  setShowCircular,
}: {
  policy: Policy;
  handleTableRefetch: () => void;
  setShowCircular: (show: boolean) => void;
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const applyPayment = () => {
    Modal.show('ApplyExternalPayment', {
      data: { ...policy, refresh: handleTableRefetch },
    });
  };

  const lossRuns = () => {
    history.push(`/admin/account/${policy.accountId}/loss-runs`);
  };

  const voidPolicy = () => {
    Modal.show('VoidPolicy', {
      data: policy,
      config: { title: `Void Policy for ${policy?.companyName}` },
    });
  };

  const onRegenerateInvoice = () => {
    Modal.show('RegeneratePolicyInvoice', { data: policy });
  };

  const regeneratePolicyDocuments = () => {
    Modal.show('RegeneratePolicyDocuments', { data: policy });
  };

  const changeBillingCompliance = () => {
    const policyId = policy.id;
    const productType = policy.product;

    Modal.show('UpdateBillingComplianceModal', {
      data: {
        policyId,
        product: productType,
      },
    });
  };

  const cancelPolicy = () => {
    Modal.show('CancelPolicy', {
      data: policy,
      config: {
        title: `Cancel Policy for ${policy.companyName}`,
      },
    });
  };

  const markCheckInMail = () => {
    toggleModalDirect('MarkCheckInMailConfirm', policy, {
      title: 'Mark policy as Check In Mail',
      icon: 'Edit',
      maxWidth: 'sm',
    });
  };

  const handleCharge = (type: string) => {
    loadOneIncByPolicyNumber(policy.policyNumber)
      .then((getOneIncKeyResp: AxiosResponse) => {
        setShowCircular(true);
        const paymentCategory = type === 'creditCard' ? 'CreditCard' : 'ECheck';

        getBalanceOnPolicy({ policyId: policy.id })
          .then((getBalanceOnPolicyResp: AxiosResponse) => {
            const balance = getBalanceOnPolicyResp.data;

            window.$('#portalOneContainer').on('portalOne.load', function () {
              setShowCircular(false);
            });

            window.$('#portalOneContainer').on('portalOne.unload', function () {
              handleTableRefetch();
            });

            const paymentCompleteHandler = function (e: any, d: any) {
              if (d.acknowledge) {
                d.acknowledge();
              }

              const payload = {
                transactionId: d.transactionId,
                transactionDate: Moment(d.transactionDate).valueOf(),
                paymentAmount: d.paymentAmount,
                totalPaymentAmount: d.totalPaymentAmount,
                policyId: policy.id,
                policyNumber: policy.policyNumber,
                lastFourDigits: _.get(d, 'transactions[0].lastFourDigits', ''),
                customerEmail: policy.customerEmail,
                quoteId: policy.quoteId,
                agencyId: policy.agencyId,
                policyCreated: policy.created,
                bankName: d.bankName || '',
                cardType: d.cardType || '',
              };
              const { accountId } = policy;

              adminPaymentComplete({ accountId }, payload).catch(() => {
                enqueueSnackbar('Failed to pay, please try again later', {
                  variant: 'error',
                });
              });
            };

            window
              .$('#portalOneContainer')
              .on('portalOne.paymentComplete', paymentCompleteHandler);

            window.$('#portalOneContainer').portalOne();
            const xhttp = new XMLHttpRequest();
            xhttp.overrideMimeType('application/json');
            xhttp.onreadystatechange = function () {
              if (xhttp.readyState === 4 && xhttp.status === 200) {
                const resp = JSON.parse(xhttp.responseText);
                const sessionID = resp.PortalOneSessionKey;
                window
                  .$('#portalOneContainer')
                  .data('portalOne')
                  .makePayment({
                    paymentCategory,
                    feeContext: 'PaymentWithFee',
                    amountContext: 'AmountDue',
                    minAmountDue: balance,
                    accountBalance: '0',
                    accountGroupCode: getOneIncKeyResp.data.authCode,
                    billingZip: '',
                    billingAddressStreet:
                      '6800 Koll Center Pkwy. Suite 250, Pleasanton, CA',
                    policyHolderName: `${policy.customerFirstName} ${policy.customerLastName}`,
                    referenceNumber: '',
                    saveOption: 'DoNotSave',
                    clientReferenceData1: policy.policyNumber,
                    clientReferenceData2: Moment(policy.created)
                      .add(7, 'days')
                      .format('L'),
                    clientReferenceData3: 'false',
                    clientReferenceData4: policy.id,
                    sessionId: sessionID,
                    displayMode: 'modal',
                    confirmationDisplay: 'true',
                  });
              }
            };

            xhttp.open(
              'GET',
              `${getOneIncKeyResp.data.url}/Api/Api/Session/Create?portalOneAuthenticationKey=${getOneIncKeyResp.data.key}`,
              true
            );
            xhttp.send();
          })
          .catch(() => {
            setShowCircular(false);
            enqueueSnackbar('Something went wrong. Please try again later', {
              variant: 'error',
            });
          });
      })
      .catch(() => {
        setShowCircular(false);
        enqueueSnackbar('Something went wrong. Please try again later', {
          variant: 'error',
        });
      });
  };

  return {
    applyPayment,
    lossRuns,
    voidPolicy,
    onRegenerateInvoice,
    regeneratePolicyDocuments,
    changeBillingCompliance,
    cancelPolicy,
    markCheckInMail,
    handleCharge,
  };
};

export default PrimeXCowbellPolicyOptions;

interface Policy {
  accountId: string;
  agencyBilled: boolean;
  boundOn: string;
  companyName: string;
  created: number;
  effectiveDate: string;
  id: string;
  isDocumentAvailable: boolean;
  isEndorsement: boolean;
  isLossRunsAvailable: boolean;
  isPaid: boolean;
  isPrimePlus: boolean;
  policyNumber: string;
  quoteId: string;
  quoteNumber: string;
  status: string;
  totalPremium: number;
  isCertificateAvailable?: boolean;
  product: ProductType;
  isSurplus: boolean;
  isErp: boolean;
  agencyId: string;
  customerEmail: string;
  customerFirstName: string;
  customerLastName: string;
}
