import React from 'react';
import * as Ramda from 'ramda';

import { useSnackbar } from 'notistack';
import type { AxiosError } from 'axios';
import { manageAPIError } from '../../utils';

type APIErrorHandler = (
  fallbackMsg: string
) => (error: Error | AxiosError) => void;

export function useAPIErrorHandler() {
  const { enqueueSnackbar } = useSnackbar();

  return React.useMemo<APIErrorHandler>(() => {
    const enqueueErrorSnackbar = Ramda.partialRight(enqueueSnackbar, [
      { variant: 'error' },
    ]);

    return Ramda.curryN(2)(
      Ramda.compose(enqueueErrorSnackbar, Ramda.flip(manageAPIError))
    );
  }, [enqueueSnackbar]);
}
