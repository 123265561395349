import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { getQuoteSubjectivityProgress } from '../../../../../api';
import { manageAPIError } from '../../../../../utils';

const useGetSubjectivityProgress = ({
  quoteSubjectivityId,
  quoteId,
  productType,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    ['subjectivity', quoteSubjectivityId, quoteId],
    () => {
      return getQuoteSubjectivityProgress(
        productType,
        quoteSubjectivityId
      ).then(({ data }) => data);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        enqueueSnackbar(
          manageAPIError(err, 'Something went wrong. Please try again later.')
        );
      },
    }
  );
};

export default useGetSubjectivityProgress;
