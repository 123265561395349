import { Box, DialogActions, DialogContent } from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import { makeStyles } from '@mui/styles';
import { downloadDocument } from '../../api/DocumentsService';
import CbButton from '../Buttons/CbButton';

const DocumentPreviewModal = ({ data, ...props }) => {
  const classes = useStyles();
  const { docId, onFetch } = data;

  const fetchDocSrc = () => {
    return typeof onFetch === 'function' ? onFetch() : defaultFetch(docId);
  };

  const { data: docSrc, isLoading } = useQuery(
    ['doc-preview', docId],
    () => fetchDocSrc(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const validSrc = typeof docSrc === 'string' && docSrc.startsWith('http');

  return (
    <>
      <DialogContent style={{ padding: '2rem 4rem' }}>
        {!isLoading && !validSrc ? (
          <Placeholder />
        ) : (
          <iframe
            title="applicationDoc"
            className={classes.iframe}
            src={docSrc || ''}
            allowFullScreen
            webkitallowfullscreen
            mozallowfullscreen
          />
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="ctaButton" onClick={props.close}>
          Done
        </CbButton>
      </DialogActions>
    </>
  );
};

export const DocumentPreviewModalConfig = {
  DocumentPreviewModal: {
    component: DocumentPreviewModal,
    config: {
      maxWidth: 'xl',
      title: 'Document Preview',
    },
  },
};

const useStyles = makeStyles(() => ({
  iframe: {
    width: '100%',
    minHeight: '70vh',
    border: 0,
    overflow: 'scroll',
  },
}));

// helpers
const Placeholder = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
  >
    <h1>We're sorry, we couldn't locate your document</h1>
    <h3>Please try again or contact a network admin for assistance</h3>
  </Box>
);

const defaultFetch = (docId) => {
  return downloadDocument({ docId }).then((resp) => resp.data);
};
