import { responseStringTransformer, ServiceHandler } from '../../utils';

import {
  serializeElasticPageableResponse,
  standardQuerySerializer,
} from '../serializers';

// custom serializers
import { latestRiskSerializer } from './latest-risk.param-serializer';

export const getLatestRisk = ServiceHandler({
  url: '/api/risk/v1/searchLatestRisk',
  paramsSerializer: (params) => {
    return standardQuerySerializer(latestRiskSerializer(params));
  },
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableResponse,
  ],
});

export const getCowbellFactorsByNaicsCode = ServiceHandler({
  url: '/api/risk/v1/averageByNaics',
  paramsSerializer: (params) => {
    return standardQuerySerializer(latestRiskSerializer(params));
  },
  transformResponse: [responseStringTransformer, (data) => ({ data })],
});

export const getAggregateCowbellFactorsByIndustry = ServiceHandler({
  url: '/api/risk/v1/acf',
  paramsSerializer: (params) => {
    return standardQuerySerializer(latestRiskSerializer(params));
  },
});

export const getSpotlights = ServiceHandler({
  url: 'api/account/v1/spotlight/get-all-spotlights',
  paramsSerializer: (params) => {
    return standardQuerySerializer(latestRiskSerializer(params));
  },
});

export const searchSpotlights = ServiceHandler({
  url: 'api/account/v1/spotlight/page',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const createSpotlight = ServiceHandler({
  url: '/api/account/v1/spotlight/add-spotlight',
  method: 'POST',
});

export const searchAccountsWithSpotlights = ServiceHandler({
  url: '/api/account/v1/spotlight/account-spotlight/get-page',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const getAccountsBySpotlights = ServiceHandler({
  url: '/api/account/v1/spotlight/aggregate/spotlightName',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const updateSpotlightDuration = ServiceHandler({
  url: '/api/account/v1/spotlight/account-spotlight/update/:spotlightName/:extendedDuration',
  method: 'PUT',
});

export const toggleAccountSpotlight = ServiceHandler({
  url: '/api/account/v1/spotlight/account-spotlight/disable/:spotlightName/:accountId',
  method: 'POST',
});
