import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { getEnvironment } from '../api/AuthService';
import type { EnvDto } from '../types/common/env.dto';

const queryKey = ['env'];

export function useEnvQuery(
  queryConfig?: Omit<
    UseQueryOptions<EnvDto, AxiosError, EnvDto, typeof queryKey>,
    'queryKey'
  >
) {
  return useQuery({
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      sessionStorage.setItem('env', data.envName);
    },
    ...queryConfig,
    staleTime: 60000 * 30,
    queryKey,
    queryFn: () => getEnvironment().then((resp) => resp.data),
  });
}
