import { DialogActions, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CbButton from '../../Buttons/CbButton';
import { RefreshAccountFirmo } from '../../../api';
import type { ProductType } from '../../../types';
import { manageAPIError } from '../../../utils';

interface RefreshFirmoModalProps {
  data: {
    accountId: string;
    quoteId: string;
    productType: ProductType;
    agencyId: string;
    agentEmail: string;
    refetchCall?: () => void;
    setIsBlockingRender?: (stateUpdate: boolean) => void;
  };
  close: () => void;
}

const RefreshFirmoModal = ({
  data: modalProps,
  close,
}: RefreshFirmoModalProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const history = useHistory();

  const {
    accountId,
    quoteId,
    productType,
    agencyId,
    agentEmail,
    refetchCall,
    setIsBlockingRender,
  } = modalProps;

  const handleRefreshFirmo = () => {
    setIsLoading(true);
    RefreshAccountFirmo(accountId, {
      data: {
        quoteId,
        product: productType,
        agencyId,
        agentEmail,
      },
    })
      .then(() => {
        close();
        enqueueSnackbar('Firmo refreshed sucessfully', { variant: 'success' });
        refetchCall?.();
        //used on account profile page - temporary until we can refactor all forms
        if (typeof setIsBlockingRender === 'function') {
          setIsBlockingRender(true);

          setTimeout(() => setIsBlockingRender(false), 500);
        } else {
          history.replace('/admin');
          setTimeout(() => history.replace(pathname), 0);
        }
      })
      .catch((error) => {
        enqueueSnackbar(manageAPIError(error), { variant: 'error' });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <DialogContent style={{ padding: '2.5rem' }}>
        <div
          style={{ textAlign: 'center', fontSize: '1.1rem' }}
          className="contrast-text"
        >
          <p>
            Are you sure you want to update the Firmo Data for this account?
          </p>
          <p>
            You will lose unsaved changes and will not be able to revert back
            after the update!
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" size="medium" onClick={close}>
          Cancel
        </CbButton>
        <CbButton
          styleName="ctaButton"
          size="medium"
          onClick={handleRefreshFirmo}
          loading={isLoading}
          disabled={isLoading}
        >
          Refresh Firmo
        </CbButton>
      </DialogActions>
    </>
  );
};

export const RefreshFirmoModalConfig = {
  RefreshFirmoModal: {
    component: RefreshFirmoModal,
    config: {
      title: 'Refresh Firmo',
      maxWidth: 'sm',
    },
  },
};
