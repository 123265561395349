// mui
import { DialogActions, DialogContent, FormHelperText } from '@mui/material';
// components
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-4';
import CbButton from '../../../components/Buttons/CbButton';
// utils
import { delayedEvent } from '../../../utils/eventUtils';
import { deleteCarrierRiskProfile } from '../carrier.service';

const CarrierRiskProfileDeleteConfirm = ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = data;
  const [error, setError] = useState(false);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = () => {
    return deleteCarrierRiskProfile({ id })
      .then(() => {
        enqueueSnackbar('Carrier risk profile removed successfully!', {
          variant: 'success',
        });
        delayedEvent('table-refetch', 500, 'carrierRiskProfiles');
        close();
      })
      .catch((error) => {
        const msg = _.get(error, 'response.data.error');
        if (msg === 'Forbidden') {
          setError('Deleting this carrier risk profile is not allowed.');
        } else {
          setError(
            _.get(
              error,
              'response.data',
              'Something went wrong. Please try again later'
            )
          );
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent style={{ padding: '2rem 5rem' }}>
        <section className="modal-title">
          Are you sure you want to delete this risk profile?
        </section>
        {error && (
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" action={close}>
          Cancel
        </CbButton>
        <CbButton
          loading={isSubmitting}
          disabled={isSubmitting}
          styleName="ctaButton"
          buttonText="Delete"
          type="submit"
        />
      </DialogActions>
    </form>
  );
};

export default CarrierRiskProfileDeleteConfirm;

export const CarrierRiskProfileDeleteConfirmConfig = {
  CarrierRiskProfileDeleteConfirm: {
    component: CarrierRiskProfileDeleteConfirm,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
    },
  },
};
