import React from 'react';
// mui
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// compariosn
import { Coverages } from './comparisons/Coverages';

export const CurrentEndorsementCompare = ({ endorsement, diffs = [] }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={6} direction="column" className={classes.lineItems}>
        <Grid className={classes.firstHeader}>Coverage Details and Limits</Grid>
        <Coverages coverages={endorsement} labels diffs={diffs} />
      </Grid>

      <Grid
        item
        md={6}
        container
        direction="column"
        className={`${classes.container} ${classes.lineItems}`}
      >
        <Grid className={classes.secondHeader}>Endorsement Update</Grid>
        <Coverages coverages={endorsement} diffs={diffs} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(({ palette, config }) => ({
  firstHeader: {
    color: palette.primary.contrastText,
    alignSelf: 'center',
    paddingRight: '5rem',
    height: '2.66rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondHeader: {
    fontWeight: config.weights.bold,
    color: palette.primary.contrastText,
    height: '2.66rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    borderWidth: '0 0 0 1px',
    borderStyle: 'solid',
  },
  lineItems: {
    '& > div:nth-child(odd)': {
      background: palette.drawer.rowsOdd,
    },
    '& > div:nth-child(even)': {
      background: palette.drawer.rowsEven,
    },
  },
}));

export default CurrentEndorsementCompare;
