import React from 'react';
import _ from 'lodash';

export const useScrollListener = () => {
  const [scroll, setScroll] = React.useState(window.scrollY);
  React.useEffect(() => {
    window.scrollY = scroll;
    window.onscroll = _.debounce(() => setScroll(window.scrollY), 500);
    return () => (window.onscroll = null);
    // eslint-disable-next-line
  }, []);
  return scroll;
};
