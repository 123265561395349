import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { DialogContent, FormHelperText } from '@mui/material';
import { PubSub } from '../../utils/eventUtils';
import {
  deleteAgencyLicense,
  deleteAgentLicense,
} from '../../console/agencies/_services/licenses.service';
import { useToggleModal } from '../../utils/modal.utils';

export const AgencyLicenseDeleteConfirm = ({
  data: { id, number, isAgent },
}) => {
  const toggleModal = useToggleModal();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');

  useEffect(() => {
    const sub = PubSub.subscribe('foot-button-one', (eventData) => {
      if (eventData) {
        onConfirm();
      }
    });
    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  const onConfirm = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    const func = isAgent ? deleteAgentLicense : deleteAgencyLicense;

    func({ licenseId: id })
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        PubSub.publish('table-refetch', 'agency_licenses');
        PubSub.publish('table-refetch', 'agent_licenses');
        toggleModal.direct('LicenseDeleteConfirm', false);
        enqueueSnackbar(`License ${number} successfully removed`, {
          variant: 'success',
        });
        // setDisabled(false);
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        setError(error.response.data);
      });
  };

  return (
    <DialogContent>
      <div className="modal-title">
        Are you sure you want to remove the following license{' '}
        <span className="modal-blue-text">{number}</span>?
      </div>
      <FormHelperText className="api-text-error">{error}</FormHelperText>
    </DialogContent>
  );
};
