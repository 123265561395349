import * as Sentry from '@sentry/react';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useFeature } from '@growthbook/growthbook-react';
import { truncateString } from '../../utils';
import withRouter from '../hocs/withRouter';
import CbButton from '../Buttons/CbButton';
import { FEATURE_FLAG_KEYS } from '../../config/growthbook';

const SENTRY_ORG = 'cowbellcyber';
const PROJECT_ID = `5471177`;
const BASE_SENTRY_ISSUE_URL = `https://sentry.io/organizations/${SENTRY_ORG}/issues/?project=${PROJECT_ID}`;

export const GlobalErrorBoundary = ({ children }) => {
  const globalErrorBoundaryFeature = useFeature(
    FEATURE_FLAG_KEYS.GLOBAL_ERROR_BOUNDARY
  );
  const onReset = () => {
    if (globalErrorBoundaryFeature.on) {
      return;
    }
    window.location.reload();
  };

  return (
    <Sentry.ErrorBoundary
      fallback={(sentryProps) => (
        <Fallback onReset={onReset} sentryProps={sentryProps} />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

const FallbackBase = ({ onReset, sentryProps, ...routeProps }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleErrorCopy = () => {
    const issueLink = `${BASE_SENTRY_ISSUE_URL}&query=${sentryProps.eventId}`;
    const errorMessage = `Error: ${truncateString(
      sentryProps.error.message,
      60
    )}\n${issueLink}`;
    navigator.clipboard.writeText(errorMessage);
    enqueueSnackbar('Copied Error Details', { variant: 'success' });
  };

  const handleSamePageReset = () => {
    sentryProps.resetError();
    onReset();
  };

  const handleRedirect = () => {
    sentryProps.resetError();
    routeProps.history.push(
      `/${routeProps.location.pathname.split('/')[1]}/dashboard`
    );
    onReset();
  };

  return (
    <div className="main-root-wrapper">
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Box style={{ position: 'relative' }}>
          <img
            src="images/Error_Cows.png"
            alt="cow-tippers on the loose"
            style={{
              maxHeight: '50rem',
              boxShadow: '0 0 100px 100px rgba(191, 212, 219, 0.2)',
              borderRadius: 150,
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ position: 'absolute', top: '76%', left: '6%' }}
          >
            <h1
              className="table-heading"
              style={{ color: '#2180e2', marginBottom: 0 }}
            >
              There's a cow-tipper on the loose!
            </h1>
            <Typography variant="body2" style={{ margin: 0, color: '#5e5e5f' }}>
              You are seeing this page as a result of an internal systems error.
              Please notify a system admin so we can resolve the issue.
            </Typography>
            <Typography
              variant="body2"
              style={{ marginBottom: '0.5rem', color: '#5e5e5f' }}
            >
              In the meantime, here are some options:
            </Typography>
            <Box
              width="100%"
              maxWidth="45rem"
              display="flex"
              justifyContent="space-evenly"
            >
              <CbButton styleName="error" onClick={handleErrorCopy}>
                Copy Error Details
              </CbButton>
              <CbButton styleName="ctaButton" onClick={handleSamePageReset}>
                Reset Current Page
              </CbButton>
              <CbButton
                styleName="ctaButton"
                size="fit-content"
                onClick={handleRedirect}
              >
                Redirect to Dashboard
              </CbButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const Fallback = withRouter(FallbackBase);
