import React from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { withShowable } from '../../console/_global/lib/withShowable';
import LanguageForm from './LanguageForm';

const Grid = withShowable(MuiGrid);

const GridLanguageForm = ({
  gridLayoutSettings,
}: {
  gridLayoutSettings: Record<string, boolean>;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={6} show={gridLayoutSettings.showAddress1}>
        <LanguageForm.Address1 />
      </Grid>

      <Grid item sm={6} show={gridLayoutSettings.showAddress2}>
        <LanguageForm.Address2 />
      </Grid>

      <Grid item sm={12} show={gridLayoutSettings.showAddress3}>
        <LanguageForm.Address3 />
      </Grid>

      <Grid item sm={6} show={gridLayoutSettings.showCity}>
        <LanguageForm.City />
      </Grid>

      <Grid item sm={3} show={gridLayoutSettings.showState}>
        <LanguageForm.State />
      </Grid>

      <Grid
        item
        sm={gridLayoutSettings.showState ? 3 : 6}
        show={gridLayoutSettings.showPostCode}
      >
        <LanguageForm.PostalCode />
      </Grid>
    </Grid>
  );
};

export default GridLanguageForm;
