import * as Yup from 'yup';
// react
import React from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
// mui
import { Grid as MuiGrid, DialogActions, DialogContent } from '@mui/material';
// components
import { withFormController } from '../../../../../components/hocs/withFormController';
import { TextFieldBase } from '../../../../../components/inputs/TextFieldBase';
import CbButton from '../../../../../components/Buttons/CbButton';
// actions

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  carrier: Yup.string().required().label('Carrier'),
  policyNumber: Yup.string().label('Policy Number').required(),
  limit: Yup.string().label('Limit').required(),
  attachmentPoint: Yup.string().label('Attachment Point').required(),
});

export const ScheduleUnderlyingInsurance = ({
  dispatch,
  close,
  endorsementType,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting, dirty },
    ...methods
  } = useForm({
    defaultValues: {
      carrier: '',
      policyNumber: '',
      limit: '',
      attachmentPoint: '',
    },
    validationSchema: schema,
  });

  const onSubmit = (formData) => {
    dispatch({
      type: 'addEndorsement',
      payload: {
        endorsementType,
        value: [
          [
            {
              label: 'Carrier',
              value: formData.carrier,
            },
            {
              label: 'Policy Number',
              value: formData.policyNumber,
            },
            {
              label: 'Limit',
              value: formData.limit,
            },
            {
              label: 'Attachment Point',
              value: formData.attachmentPoint,
            },
          ],
        ],
      },
    });
    close();
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} data-qa="excessQuotaShare">
        <DialogContent style={{ padding: '2rem 5rem', overflow: 'visible' }}>
          <MuiGrid container spacing={5}>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="carrier"
                label="Carrier"
                fullWidth
                inputProps={{ required: true }}
              />
            </MuiGrid>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="policyNumber"
                label="Policy Number"
                fullWidth
                inputProps={{ required: true }}
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={5}>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="limit"
                label="Limit"
                fullWidth
                inputProps={{ required: true }}
              />
            </MuiGrid>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="attachmentPoint"
                label="Attachment Point"
                fullWidth
                inputProps={{ required: true }}
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Save"
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};
