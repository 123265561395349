import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { imageMappings } from './imageMappings';
import { getConnector } from './ConnectorService';
import MSConnectorConfig from './MSConnectorConfig';

const MSConnectorConfigContainer = ({ classes, data, theme }) => {
  const { connectorName, connectorType, description } = data.data;
  const { cbid } = data;
  const [myData, setMyData] = useState(null);

  const shareData = {
    MICROSOFT: {
      text1: 'Microsoft Accounts',
      text2: `Click ‘ADD’ button above to enter your Microsoft 365 credentials for each Microsoft account you want to connect.`,
      text3: 'Microsoft',
    },
    GOOGLE: {
      text1: 'Google Accounts',
      text2: `Click ‘ADD’ button above to enter your Google account credentials for each of Goggle accounts you want to connect.`,
      text3: 'Google',
    },
    AWS: {
      text1: 'AWS Accounts',
      text2: `Click ‘ADD’ button above to enter your AWS account credentials for each of AWS accounts you want to connect.`,
      text3: 'AWS',
    },
  };

  useEffect(() => {
    Promise.all([getConnector({ connectorType: connectorType.toLowerCase() })])
      .then((responses) => {
        setMyData({
          connector: responses[0].data.content[0],
        });
      })
      .catch(console.error.bind(console));
  }, [connectorType]);

  const renderImage = () => {
    const image = imageMappings[connectorType][theme.palette.mode];
    return <img src={image} alt="" className={classes.iconStyle} />;
  };

  const handleClick = () => {
    const { state } = myData.connector;
    const { clientId, responseType, redirectUri, scope } =
      myData.connector[`${connectorType.toLowerCase()}Payload`];
    const prompt = connectorType === 'GOOGLE' ? 'consent' : 'select_account';
    const accessType = connectorType === 'GOOGLE' ? '&access_type=offline' : '';
    const authPortalUri =
      connectorType === 'GOOGLE'
        ? 'https://accounts.google.com/o/oauth2/v2/auth'
        : 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';

    window.location.href = `${authPortalUri}?client_id=${clientId}&response_type=${responseType}&redirect_uri=${encodeURI(
      redirectUri
    )}&response_mode=query&scope=${scope.join(
      '%20'
    )}&state=${`${state}.${cbid}&prompt=${prompt}${accessType}`}`;
  };

  if (myData) {
    return (
      <section className={classes.mainContainer}>
        <section className={classes.headerContainer}>
          {renderImage()}
          <section className={classes.verticalLine} />
          <section className={classes.headerDetailsContainer}>
            <h3 className={classes.title}>{connectorName}</h3>
            <p className={classes.description}>{description}</p>
            <div
              role="button"
              onClick={handleClick}
              className={classes.availableButton}
              tabIndex="0"
            >
              ADD
            </div>
          </section>
        </section>
        <section className={classes.middleContainer}>
          <h3 className={classes.midTitle}>{shareData[connectorType].text1}</h3>
          <p className={classes.midContent}>{shareData[connectorType].text2}</p>
          <p className={classes.midContent}>
            These credentials{' '}
            <span className={classes.readonlyAccess}>
              only require read-only access
            </span>{' '}
            to your
            {shareData[connectorType].text3} account.
          </p>
        </section>
        <MSConnectorConfig connectorType={connectorType} />
      </section>
    );
  }
  return <div className={classes.loading}>Loading...</div>;
};

const styles = ({ palette }) => {
  return {
    readonlyAccess: {
      fontWeight: 600,
      textDecoration: 'underline',
    },
    loading: {
      textAlign: 'center',
      margin: 50,
    },
    iconStyle: {
      width: '10rem',
      height: '5rem',
      textAlign: 'center',
      margin: '20px 0 0 -40px',
    },
    midContent: {
      fontSize: '1rem',
      lineHeight: 1,
      color: palette.primary.main,
      paddingBottom: 2,
      margin: '0px 0 5px 0 !important',
    },
    midTitle: {
      fontSize: '1.5rem',
      lineHeight: 1.5,
      color: palette.primary.title,
      paddingBottom: 2,
      margin: '0px 0 15px 0 !important',
      textTransform: 'uppercase',
    },
    middleContainer: {
      marginBottom: 30,
    },
    mainContainer: {
      width: '75rem',
      height: 'fit-content',
      marginBottom: 30,
      padding: '0 30px',
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: '30px 0 40px 0',
    },
    verticalLine: {
      border: `1px solid ${palette.primary.oldBorder2}`,
      marginRight: 30,
    },
    headerDetailsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: '1.083rem',
      lineHeight: 1.33,
      fontWeight: 'bold',
      color: palette.primary.main,
      paddingBottom: 2,
      margin: '0px 0 15px 0 !important',
      textTransform: 'uppercase',
    },
    description: {
      fontSize: '0.9167rem',
      lineHeight: 1.2,
      color: palette.text.secondary,
    },
    availableButton: {
      width: '7.5rem',
      height: '1.667rem',
      borderRadius: 5,
      backgroundColor: palette.background.button,
      color: palette.primary.main,
      fontSize: '1.083rem',
      lineHeight: 1.33,
      margin: '0 0 15px 0',
      paddingTop: 2,
      textTransform: 'uppercase',
      cursor: 'pointer',
      textAlign: 'center',
    },
  };
};

export default withStyles(styles, { withTheme: true })(
  MSConnectorConfigContainer
);
