import _ from 'lodash';
import Moment from 'moment';
import React, { useEffect } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import { compose } from 'redux';
import { useSnackbar } from 'notistack';
// mui
import { DialogActions, DialogContent } from '@mui/material';

// components
import { useQueryClient } from '@tanstack/react-query';
import { withStyles } from '@mui/styles';
import { MultiSelect } from '../Selects';
import { RadioButtons } from '../inputs/ResetableRadioButtons';
import { withFormController } from '../hocs/withFormController';
import { TextAreaBase } from '../inputs/TextAreaBase';
import { TextFieldBase } from '../inputs/TextFieldBase';
import CBButton from '../Buttons/CbButton';
// actions
import { downloadDocument } from '../../api/DocumentsService';
import {
  getCompetitors,
  updateAppDetails,
} from '../../accounts/AccountService';
// constants
import { AGENCY_ACCOUNTS, COWBELL_ACCOUNTS } from '../tables/table_constants';

const TextArea = withFormController(TextAreaBase);
const TextField = withFormController(TextFieldBase);
const radioValues = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
const getValue = (val) => {
  if (val === true || val === 'true') {
    return { value: true, label: 'Yes' };
  }
  if (_.isNull(val)) {
    return { value: null, label: 'No' };
  }
  return { value: false, label: 'No' };
};

const styles = ({ palette }) => {
  return {
    textAreaRoot: {
      width: '95%',
      borderRadius: 5,
      fontSize: '1rem',
      lineHeight: '1.33',
      color: palette.primary.main,
      border: `0.8px solid ${palette.text.secondary}`,
      background: palette.background.darkerBlue,
      float: 'left',
    },
    notesTitle: {
      fontSize: '1.5rem',
      lineHeight: 1.5,
      color: palette.primary.main,
      margin: '0 0 1.5rem 0',
      textAlign: 'left',
    },
    descriptions: {
      fontSize: '1.1677rem',
      lineHeight: 1.14,
      textAlign: 'left',
      color: palette.text.text11,
      fontStyle: 'italic',
      margin: '0 0 2.5rem 0',
    },
    radioButtonsContainer: {
      border: `1px solid ${palette.common.almostWhite}`,
      borderRadius: 5,
    },
    line: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      fontSize: '1.5rem',
      lineHeight: 1.5,
      color: palette.primary.main,
    },
    lineTitle: {
      marginRight: '3rem',
      textAlign: 'left',
      width: '15rem',
      margin: '0',
    },
    lineContent: {
      marginBottom: '0',
    },
    iframe: {
      height: '100%',
      width: '100%',
      border: 0,
      background: palette.background.iframe,
      overflow: 'scroll',
    },
    formContainer: {
      width: '50%',
      borderRight: `1px solid ${palette.primary.modalBorder}`,
      marginRight: '1rem',
    },
    docContainer: {
      width: '50%',
    },
    iconCancel: {
      width: '2rem',
      height: '2rem',
      margin: '1.3rem 0 0 0.5rem',
      cursor: 'pointer',
    },
    selectedFile: {
      fontSize: '1.33rem',
      lineHeight: 1.38,
      color: palette.primary.main,
      marginBottom: '0 !important',
    },
    selectedFileContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    dragDropText: {
      width: '10.67rem',
      fontSize: '1rem',
      lineHeight: 1.33,
      color: palette.primary.main,
    },
    dragDropContainer: {
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      height: '5.833rem',
      width: '25rem',
      borderRadius: '5px',
      border: `1px solid ${palette.primary.main}`,
      cursor: 'pointer',
    },
    container: {
      backgroundColor: palette.background.active,
      height: '55rem',
    },
    bodyContainer: {
      height: '100%',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    downloadApplication: {
      borderTop: `1px solid ${palette.primary.modalBorder}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      fontSize: '1.333rem',
    },
    supportedFiles: {
      fontSize: '1.167rem',
      lineHeight: 1.5,
      color: palette.text.secondary,
      marginBottom: 20,
    },
    icon: {
      width: '1.5rem',
      height: '1.25rem',
      margin: '1.6rem 0 0 1rem',
    },
    icon1: {
      width: '5rem',
      height: '5rem',
      margin: '0 0 0 1rem',
    },
    downloadTemplate: {
      'text-decoration': 'underline',
      fontWeight: 600,
      cursor: 'pointer',
      color: palette.primary.main,
      marginLeft: '0.5rem',
    },
  };
};

const VerifyUploadedApplicationModal = compose(withStyles(styles))(
  ({ classes, data, ...props }) => {
    const { enqueueSnackbar } = useSnackbar();
    const {
      otherAppNotes,
      otherAppInsuranceCompany,
      isOtherAppSigned,
      isOtherAppAcceptable,
      otherAppSignedDate,
    } = data;
    const docId = _.get(data, 'otherApp.docId');
    const fileType = _.get(data, 'otherApp.fileType');
    const docType = _.get(data, 'otherApp.docType');
    const docName = _.get(data, 'otherApp.docName');
    const queryClient = useQueryClient();

    const defaultValues = {
      isOtherAppSigned: getValue(isOtherAppSigned),
      isOtherAppAcceptable: getValue(isOtherAppAcceptable),
      otherAppNotes,
      otherAppInsuranceCompany,
      otherAppSignedDate,
    };

    const { handleSubmit, ...methods } = useForm({ defaultValues });
    const {
      control,
      register,
      formState: { isSubmitting },
      setValue,
      getValues,
      watch,
    } = methods;
    const [url, setUrl] = React.useState('');
    const [competitors, setCompetitors] = React.useState([]);
    const values = getValues();
    watch(['isOtherAppSigned', 'isOtherAppAcceptable']);

    useEffect(() => {
      downloadDocument({ docId })
        .then((response) => {
          setUrl(_.get(response, 'data'));
        })
        .catch(() => {
          enqueueSnackbar('Unable to download the document', {
            variant: 'error',
          });
        });
    }, [docId, enqueueSnackbar]);

    useEffect(() => {
      getCompetitors({})
        .then((response) => {
          const temp = _.map(response.data, (value) => {
            return {
              label: value.name,
              value: value.name,
            };
          });
          const getCompetitor = (val) => {
            return temp.find((type) => type.value === val) || {};
          };
          setValue(
            'otherAppInsuranceCompany',
            getCompetitor(otherAppInsuranceCompany)
          );
          setCompetitors(temp);
        })
        .catch(() => {
          enqueueSnackbar('Unable to download competitors', {
            variant: 'error',
          });
        });
    }, [enqueueSnackbar, otherAppInsuranceCompany, setValue]);

    const onSubmit = (formData) => {
      const payload = {
        accountId: data.id,
        productType: data.productType,
        otherApp: {
          docId,
          docName,
          docType,
          fileType,
        },
        isOtherAppAcceptable: formData.isOtherAppAcceptable.value,
        isOtherAppSigned: formData.isOtherAppSigned.value,
        ...(formData.otherAppInsuranceCompany && {
          otherAppInsuranceCompany: formData.otherAppInsuranceCompany.value,
        }),
        ...(formData.otherAppSignedDate && {
          otherAppSignedDate: formData.otherAppSignedDate,
        }),
        ...(formData.otherAppNotes && {
          otherAppNotes: formData.otherAppSignedDate,
        }),
      };
      return updateAppDetails({}, payload)
        .then(() => {
          props.close();
          enqueueSnackbar('Verified application successfully', {
            variant: 'success',
          });
          queryClient.invalidateQueries([AGENCY_ACCOUNTS]);
          queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
        })
        .catch(() => {
          enqueueSnackbar('Failed to verify application', { variant: 'error' });
          props.close();
        });
    };

    const handleSelectCompany = React.useCallback(
      (name, selectedOption) => {
        setValue('otherAppInsuranceCompany', selectedOption);
      },
      [setValue]
    );

    const inFunc = React.useMemo(() => {
      return (name) => {
        return (value) => {
          setValue(name, getValue(value));
        };
      };
      // eslint-disable-next-line
    }, []);

    const before30Days = React.useMemo(() => {
      return Moment().subtract(30, 'd').format('YYYY-MM-DD');
      // eslint-disable-next-line
    }, []);

    const today = React.useMemo(() => {
      return Moment().format('YYYY-MM-DD');
      // eslint-disable-next-line
    }, []);

    return (
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <section className={classes.bodyContainer}>
              <section className={classes.formContainer}>
                <section
                  className={classes.line}
                  style={{ marginBottom: '2rem' }}
                >
                  <p
                    className={classes.lineTitle}
                    style={{ marginTop: '2rem' }}
                  >
                    Organization Name
                  </p>
                  <p className={classes.lineContent}>{data.name}</p>
                </section>
                <section className={classes.line}>
                  <p className={classes.lineTitle}>Insurance Company</p>
                  <section
                    className={classes.lineContent}
                    style={{ width: '15rem' }}
                  >
                    <MultiSelect
                      name="otherAppInsuranceCompany"
                      isMulti={false}
                      buttonPlaceholder="Select a Company"
                      options={competitors}
                      onChange={handleSelectCompany}
                      values={values.otherAppInsuranceCompany}
                      ref={register({ name: 'otherAppInsuranceCompany' })}
                      error={_.get(methods, 'errors.otherAppInsuranceCompany')}
                    />
                  </section>
                </section>
                <section className={classes.line}>
                  <p className={classes.lineTitle}>Signed</p>
                  <section className={classes.lineContent}>
                    <RadioButtons
                      ref={register({ name: 'isOtherAppSigned' })}
                      infunc={inFunc('isOtherAppSigned')}
                      value={values.isOtherAppSigned}
                      classContainer={classes.radioButtonsContainer}
                      values={radioValues}
                    />
                  </section>
                </section>
                <section className={classes.line}>
                  <p className={classes.descriptions}>
                    Is the application signed and the signature is clearly
                    visible
                  </p>
                </section>
                <section className={classes.line}>
                  <p className={classes.lineTitle}>Signed Date</p>
                  <section className={classes.lineContent}>
                    <TextField
                      name="otherAppSignedDate"
                      control={control}
                      type="date"
                      inputProps={{ max: today }}
                    />
                  </section>
                </section>
                <section className={classes.line}>
                  <p
                    className={classes.descriptions}
                    style={{ marginTop: '-1.5rem' }}
                  >
                    The signed date must be on or after {before30Days}{' '}
                  </p>
                </section>
                <section className={classes.line}>
                  <p className={classes.lineTitle}>Acceptable</p>
                  <section className={classes.lineContent}>
                    <RadioButtons
                      ref={register({ name: 'isOtherAppAcceptable' })}
                      infunc={inFunc('isOtherAppAcceptable')}
                      value={values.isOtherAppAcceptable}
                      classContainer={classes.radioButtonsContainer}
                      values={radioValues}
                    />
                  </section>
                </section>
                <section className={classes.line}>
                  <p className={classes.descriptions}>
                    Are all Cowbell’s security questions answered or can be
                    derived from the application? The application must be signed
                    in the last 30 days for it to be acceptable
                  </p>
                </section>
                <p className={classes.notesTitle}>Notes</p>
                <TextArea
                  className={classes.textAreaRoot}
                  maxRows={10}
                  minRows={10}
                  name="otherAppNotes"
                  fullWidth
                  multiline
                  control={control}
                />
              </section>
              <section className={classes.docContainer}>
                <iframe
                  title="applicationDoc"
                  className={classes.iframe}
                  src={url}
                  allowFullScreen
                  webkitallowfullscreen
                  mozallowfullscreen
                />
              </section>
            </section>
          </DialogContent>
          <DialogActions>
            <CBButton action={props.close} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Submit"
            />
          </DialogActions>
        </form>
      </FormContext>
    );
  }
);

export const VerifyUploadedApplicationConfig = {
  VerifyUploadedApplication: {
    component: VerifyUploadedApplicationModal,
    config: {
      title: 'Verify Uploaded Application',
      minWidth: 'lg',
      maxWidth: 'xl',
    },
  },
};
