import React from 'react';
import { withStyles } from '@mui/styles';
import Numeral from 'numeral';

export const FakeBar = withStyles(({ palette, config }) => ({
  barContainer: {
    width: '100%',
    height: (props) => props.height || '1rem',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
    background: (props) => props.barbackground || palette.graphs.line,
  },

  bar: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: '100%',
    transform: 'translateX(0)',
    transition: 'transform 300ms ease-in',
    background: (props) => props.barcolor || palette.common.darkGreen,
  },
  number: {
    color: config.colors.white,
    fontSize: config.textSizes.petite,
    fontWeight: 600,
    marginLeft: '100%',
    position: 'relative',
    top: -1,
    fontFamily: config.fonts.stackSans,
    fontStyle: 'italic',
  },
}))(({ classes, className, value = 0, aggregate, percentage, readOnly }) => {
  const barStyles = {
    transform: `translateX(${value}%)`,
  };

  const barLabel = formatLimit(percentage * aggregate);

  return (
    <div className={`${classes.barContainer} ${className}`}>
      <div className={classes.bar} style={barStyles}>
        <span className={classes.number}>
          {barLabel === '$0' || barLabel === '0' ? null : barLabel}
        </span>
      </div>
      {percentage === 1 && (
        <span
          className={classes.number}
          style={readOnly ? { marginLeft: '85%' } : { marginLeft: '90%' }}
        >
          {barLabel}
        </span>
      )}
    </div>
  );
});

function formatLimit(raw) {
  return Number.isInteger(raw / 1000)
    ? Numeral(raw).format('0,0a')
    : Numeral(raw).format('$0,0');
}
