import React from 'react';
import { Box, styled } from '@mui/material';

const DefaultFileUploadPlaceholder = ({ accept }) => {
  const acceptedFileExtensions = React.useMemo(() => {
    let temp = accept;
    if (typeof accept === 'string') {
      temp = accept.replaceAll(',', '').split(' ');
    }
    return temp
      .map((fileType) => fileType.split('/')[1])
      .join(', ')
      .toUpperCase();
  }, [accept]);

  return (
    <>
      <DnDContainer>
        <CloudIcon alt="cloud-upload" src="images/cloud-upload.svg" />
        <DnDText>
          Drag and Drop a file here
          <br /> or Click to Upload
        </DnDText>
      </DnDContainer>
      <Box mt={1} display="flex" justifyContent="center" width="100%">
        <SupportedFilesText>
          Supported File Types: {acceptedFileExtensions}
        </SupportedFilesText>
      </Box>
    </>
  );
};

export default DefaultFileUploadPlaceholder;

const DnDContainer = styled('div')(({ theme: { palette } }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'center',
  margin: '0 auto',
  height: '5.833rem',
  width: '25rem',
  borderRadius: '5px',
  border: `1px solid ${palette.primary.main}`,
  cursor: 'pointer',
}));

const CloudIcon = styled('img')({
  marginLeft: '2rem',
  width: '3rem',
  height: '3rem',
  margin: '0 0 0 1rem',
});

const DnDText = styled('p')(({ theme: { palette } }) => ({
  marginLeft: '2.3rem',
  maxWidth: '12rem',
  fontSize: '1rem',
  lineHeight: 1.33,
  color: palette.primary.main,
}));

const SupportedFilesText = styled('p')(({ theme: { palette, config } }) => ({
  fontSize: config.textSizes.normal,
  color: palette.primary.main,
  margin: 0,
}));
