import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTheme } from '@mui/styles';
import type { DefaultTheme } from '@mui/styles';
import { BaseFlag } from './FlagCell';
import { withShowable } from '../../console/_global/lib/withShowable';

export const ApiFlag = withShowable(() => {
  const theme = useTheme<DefaultTheme>();

  return (
    <BaseFlag color={theme.palette.flags.api} tooltip="Is API">
      <FontAwesomeIcon icon={solid('webhook')} />
    </BaseFlag>
  );
});
