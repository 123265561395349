import React from 'react';
import { compose } from 'redux';
// components
import { withStyles } from '@mui/styles';
import { DraftEditor } from './DraftEditor';

const styles = ({ config }) => {
  return {
    listContainer: {
      fontWeight: config.weights.bold,
      fontSize: config.textSizes.petite,
      color: config.colors.cowbellBlue,
      margin: '0.416rem 0',

      '& .MuiButton-label': {
        fontStyle: 'italic',
        fontWeight: config.weights.bold,
        fontSize: config.textSizes.petite,
        color: config.colors.cowbellBlue,
      },
    },
    editNote: {
      fontSize: '1rem',
      textAlign: 'left',
      fontWeight: 'normal',
      color: config.colors.darkGrey,
      padding: '0.416rem',
      lineHeight: 1.33,
      maxHeight: '6rem',
      overflow: 'auto',
    },
  };
};

export const NotesBody = compose(
  withStyles(styles),
  React.forwardRef
)(({ classes, data, edit, noteId, delNote, ...props }, ref) => {
  const itsEdit = edit && data.id === noteId;
  const itsDel = delNote && data.id === noteId;
  // const content = props.createEditorWithText(value.noteText); //to-do

  return (
    <div className={classes.listContainer}>
      {!itsEdit ? (
        <div
          className={classes.editNote}
          style={itsDel ? { background: 'rgba(255, 0, 0, 0.25)' } : null}
        >
          {data.noteText}
        </div>
      ) : (
        <DraftEditor
          ref={ref}
          editorState={props.editorState}
          spellcheck
          {...props}
        />
      )}
    </div>
  );
});
