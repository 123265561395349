import React from 'react';
import Moment from 'moment';

import * as Yup from 'yup';

import { Box, Typography } from '@mui/material';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import type { AxiosResponse } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import type { StepperScreenProps } from '../../bind-quote/StepperScreen';

import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import CbButton from '../../../../components/Buttons/CbButton';

import { withFormController } from '../../../../components/hocs/forms';
import { useAPIErrorHandler } from '../../../../components/hooks/useAPIErrorHandler';
import useUploadZone from '../../../../components/fileUploader/v2/hooks/useUploadzone';

import { uploadDocumentV3 } from '../../../../api/documents.api';
import DocumentUploadBase from '../../bind-quote/stepper-screens/shared/DocumentUploadBase';
import { updateQuoteBindOrder } from '../../../../api/bind-quote.api';
import { useDocInfoQuery } from '../../../../components/hooks/queries/useDocInfoQuery';

type UploadBindOrderProps = {
  goForward: () => void;
  completeStep: () => void;
  step: string;
  formState: StepperScreenProps['formState'];
  formHandlers: StepperScreenProps['formHandlers'];
  quoteDetails: StepperScreenProps['quoteDetails'];
  quoteProgress: StepperScreenProps['quoteProgress'];
};

function UploadBindOrder({
  goForward,
  completeStep,
  step,
  formState,
  formHandlers,
  quoteDetails,
  quoteProgress,
}: UploadBindOrderProps) {
  const handleAPIError = useAPIErrorHandler();
  const queryClient = useQueryClient();
  const today = Moment();

  const form = useForm<FormValues>({
    defaultValues: {
      bindOrderDate: formState.bindOrderDate
        ? Moment.utc(formState.bindOrderDate as string).format('YYYY-MM-DD')
        : '',
      'fileName-0': '',
    },
    resolver: yupResolver(formSchema),
  });

  React.useEffect(() => {
    form.watch();
  }, []);

  const uploadZoneParams = useUploadZone();
  const bindOrderDocQuery = useDocInfoQuery({
    accountId: quoteDetails.uiData.accountId,
    docId: quoteDetails.uiData?.bindOrderDocumentInfo?.docId,
  });

  const existingDocsName = bindOrderDocQuery.data?.docName;
  const { setValue } = form;
  React.useEffect(() => {
    form.resetField('fileName-0', { defaultValue: existingDocsName ?? '' });
  }, [existingDocsName, setValue]);

  const submitButtonElRef = React.useRef<HTMLButtonElement>(null);

  const isNewFileSelected = Boolean(uploadZoneParams.files);
  const isFormDirty =
    form.formState.touchedFields.bindOrderDate || isNewFileSelected;

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const requestUpdateQuoteBindOrder = (bindDocId: string) =>
      updateQuoteBindOrder(quoteDetails.uiData.quoteId, {
        data: {
          docId: bindDocId,
          docSignedOn: formData.bindOrderDate,
        },
      })
        .then(() =>
          queryClient.invalidateQueries([
            'quote-details',
            quoteDetails.uiData.quoteId,
          ])
        )
        .then(() =>
          queryClient.invalidateQueries([
            'quote-progress',
            quoteDetails.uiData.quoteId,
          ])
        )
        .then(() => bindOrderDocQuery.refetch())
        .then(() => {
          formHandlers.saveBindOrderInfo({
            bindOrderDate: formData.bindOrderDate,
          });
        })
        .then(completeStep)
        .then(goForward)
        .catch(
          handleAPIError(
            "An issue occurred while updating the quote's bind order"
          )
        );

    if (isNewFileSelected) {
      const uploadRequestor = makeUploadRequestor({
        accountId: quoteDetails.uiData.accountId,
        docName: formData['fileName-0'],
      });

      return uploadZoneParams.handleUploads({
        asyncUploadFunc: uploadRequestor,
        onFileUploadError: handleAPIError(
          'An issue occurred while uploading the bind order.'
        ),
        onFileUploadSuccess: (docRes: AxiosResponse) =>
          requestUpdateQuoteBindOrder(docRes.data.docId),
      });
    }

    const existingDocId = bindOrderDocQuery.data.id;
    return requestUpdateQuoteBindOrder(existingDocId);
  };

  const handleNextClick = () => {
    submitButtonElRef.current?.click();
  };

  return (
    <Box className="contrast-text" maxWidth="60%">
      <Box>
        <Typography
          variant="h4"
          component="h3"
          style={{
            fontWeight: 600,
            fontSize: '1.5rem',
            marginBottom: '1.17rem',
          }}
        >
          Upload Bind Order
        </Typography>

        <Typography
          variant="h4"
          component="span"
          style={{ fontWeight: 600, fontSize: '1.17rem' }}
        >
          Please upload the Bind Order Email associated with this quote below:
        </Typography>
      </Box>

      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <Box width="30rem" paddingY="1rem">
            <ControlledTextField
              name="bindOrderDate"
              label="Bind Order Date"
              required
              disabled={false}
              type="date"
              error={form.formState.errors.bindOrderDate?.message}
              containerClass={undefined}
              fullWidth={undefined}
              helperText={undefined}
              InputProps={{
                inputProps: {
                  max: today.format('YYYY-MM-DD'),
                },
              }}
            />

            <DocumentUploadBase
              uploadZoneParams={uploadZoneParams}
              files={uploadZoneParams.files}
              isUploadingDoc={uploadZoneParams.isUploading}
              docType="Bind Order"
              isStepEditable
              uploadedDocs={
                bindOrderDocQuery.data ? [bindOrderDocQuery.data] : []
              }
              datePickerProps={undefined}
            />
          </Box>

          <button
            ref={submitButtonElRef}
            style={{ display: 'none' }}
            type="submit"
          />
        </form>

        <Box>
          <CbButton
            styleName="ctaButton"
            size="medium"
            onClick={handleNextClick}
            loading={form.formState.isSubmitting}
            disabled={form.formState.isSubmitting || !isFormDirty}
          >
            Next
          </CbButton>
        </Box>
      </FormProvider>
    </Box>
  );
}

export default UploadBindOrder;

type FormValues = {
  bindOrderDate: string;
  'fileName-0': string;
};

const formSchema = Yup.object({
  bindOrderDate: Yup.string().min(1).label('Bind Order Date').required(),
  'fileName-0': Yup.string().label('File Name').required(),
});

const ControlledTextField = withFormController(TextFieldBase);

function makeUploadRequestor({
  accountId,
  docName,
}: {
  accountId: string;
  docName: string;
}) {
  return function ({ file, axiosConfig }: any) {
    const formData = new FormData();
    formData.append('file', file);

    const docData = {
      accountId,
      docType: 'Bind_Order',
      docName,
      temp: false,
    };

    return uploadDocumentV3({
      data: formData,
      params: docData,
      ...axiosConfig,
    });
  };
}
