import React from 'react';
import * as Yup from 'yup';

import { Box, DialogActions, DialogContent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import CbButton from '../Buttons/CbButton';
import { withFormController } from '../hocs/forms';
import { SimpleSelect } from '../inputs';

import {
  AmendmentRetroactiveDate,
  amendmentRetroactiveDateValidation,
  DropdownForm,
  dropDownFormValidation,
  ExcessQuotaShare,
  excessQuoteShareValidation,
  NonFollowForm,
  PriorPendingExclusion,
  priorPendingExclusionValidation,
  ScheduleUnderlyingInsurance,
  scheduleUnderlyingInsuranceValidation,
} from '../../console/workflows/quotes/prime-250-excess/components/endorsementsFormFields';
import { updateExcessQuote } from '../../api/excess-quote.api';

const MultiSelect = withFormController(SimpleSelect);

const validationSchema = Yup.object().shape({
  endorsement: Yup.string().required(),
  ...amendmentRetroactiveDateValidation,
  ...dropDownFormValidation,
  ...excessQuoteShareValidation,
  ...priorPendingExclusionValidation,
  ...scheduleUnderlyingInsuranceValidation,
});

const AddAdditionalEndorsements = ({ iconStyles, data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const { handleSubmit, watch, formState, ...methods } = useForm({
    defaultValues: {
      endorsement: endorsementOptions[0].value,
    },
    shouldUnregister: true,
    resolver: yupResolver(validationSchema),
  });

  const endorseemntField = watch('endorsement');

  const onSubmit = (formVals) => {
    updateExcessQuote(data.excessQuoteId, {
      data: {
        endorsements: [
          {
            ...formVals,
          },
        ],
      },
    })
      .then(() => {
        enqueueSnackbar('Endorsement added successfully', {
          variant: 'success',
        });
        queryClient.invalidateQueries(['excess-quote', data.excessQuoteId]);
        props.close();
      })
      .catch(console.error.bind(console));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <DialogContent>
          <MultiSelect
            name="endorsement"
            label="Endorsement"
            isMulti={false}
            options={endorsementOptions}
            required
          />
          <Box mt={4}>{renderFormFields(endorseemntField)}</Box>
        </DialogContent>
        <DialogActions>
          <CbButton onClick={props.close} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton type="submit" styleName="ctaButton">
            Save
          </CbButton>
        </DialogActions>
      </FormProvider>
    </form>
  );
};

export const AddAdditionalEndorsementsConfig = {
  AddAdditionalEndorsements: {
    component: AddAdditionalEndorsements,
    config: {
      fullWidth: true,
      title: 'Add Endorsements',
    },
  },
};

const endorsementOptions = [
  {
    label: 'Amendment of Retroactive Date Endorsement',
    value: 'Amendment of Retroactive Date Endorsement',
  },
  {
    label: 'Drop-Down',
    value: 'Drop-Down',
  },
  {
    label: 'Excess Quota Share Endorsement',
    value: 'Excess Quota Share Endorsement',
  },
  {
    label: 'Exclusion of Foreign Coverage Endorsement',
    value: 'Exclusion of Foreign Coverage Endorsement',
  },
  {
    label: 'Non-Follow Form',
    value: 'Non-Follow Form',
  },
  {
    label: 'Prior or Pending Exclusion Endorsement',
    value: 'Prior or Pending Exclusion Endorsement',
  },
  {
    label: 'Schedule of Underlying Insurance Endorsement',
    value: 'Schedule of Underlying Insurance Endorsement',
  },
  {
    label: 'Specific Event Exclusion Endorsement',
    value: 'Specific Event Exclusion Endorsement',
  },
  {
    label: 'Specific Individual/Entity Exclusion Endorsement',
    value: 'Specific Individual/Entity Exclusion Endorsement',
  },
];

const renderFormFields = (value) => {
  if (value === 'Amendment of Retroactive Date Endorsement') {
    return <AmendmentRetroactiveDate />;
  }
  if (value === 'Drop-Down') {
    return <DropdownForm />;
  }
  if (value === 'Excess Quota Share Endorsement') {
    return <ExcessQuotaShare />;
  }
  if (value === 'Exclusion of Foreign Coverage Endorsement') {
    return <AmendmentRetroactiveDate />;
  }
  if (value === 'Non-Follow Form') {
    return <NonFollowForm />;
  }
  if (value === 'Prior or Pending Exclusion Endorsement') {
    return <PriorPendingExclusion />;
  }
  if (value === 'Schedule of Underlying Insurance Endorsement') {
    return <ScheduleUnderlyingInsurance />;
  }
  if (value === 'Specific Event Exclusion Endorsement') {
    return <AmendmentRetroactiveDate />;
  }
  if (value === 'Specific Individual/Entity Exclusion Endorsement') {
    return <AmendmentRetroactiveDate />;
  }
};
