import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Skeleton } from '@mui/lab';

export const UpdateBillingComplianceModalSkeleton = () => {
  return (
    <>
      <DialogTitle>
        <Box>Update Billing Compliance</Box>
      </DialogTitle>
      <DialogContent>
        <Box width="45%" margin="0 auto">
          <Skeleton width={200} />
          <Skeleton width={300} />
          <Skeleton
            variant="rectangular"
            height={100}
            width={350}
            style={{ marginTop: 8 }}
          />
        </Box>
      </DialogContent>
      <DialogActions className="flex--spaced">
        <Skeleton width={150} />
        <Box display="flex" alignItems="center">
          <Skeleton width={100} style={{ marginRight: 8 }} />
          <Skeleton
            variant="rectangular"
            height={40}
            width={175}
            style={{ marginRight: 8 }}
          />
          <Skeleton variant="rectangular" height={40} width={175} />
        </Box>
      </DialogActions>
    </>
  );
};
