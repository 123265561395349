import React, { useState } from 'react';
import { useForm } from 'react-hook-form-4';

// helpers
import fp from 'lodash/fp';
import { useSnackbar } from 'notistack';

// mui
import { DialogContent, DialogActions } from '@mui/material';

// components
import CBButton from '../../components/Buttons/CbButton';

import { useAPIErrorHandler } from '../../components/hooks/useAPIErrorHandler';

// actions
import { rescindBinder } from '../QuotesService';
import {
  rescindExcessBinder,
  rescindPrimeTechExcessPolicy,
} from '../../api/policies.api';
import { ProductTypes } from '../../types';

export const RescindBinder = ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleAPIError = useAPIErrorHandler();

  const [loading, setLoading] = useState(false);

  const { handleSubmit } = useForm();

  const onSubmit = () => {
    setLoading(true);

    let rescindAPI;
    if (data.isPrimePlus) {
      rescindAPI = fp.partial(rescindExcessBinder)([data.id]);
    } else if (data.product === ProductTypes.prime_tech_plus) {
      rescindAPI = fp.partial(rescindPrimeTechExcessPolicy)([
        data.product,
        data.id,
      ]);
    } else {
      rescindAPI = fp.partial(rescindBinder)([{ quoteId: data.quoteId }]);
    }

    return rescindAPI()
      .then(() => {
        close();

        enqueueSnackbar('Binder Rescinded Successfully!', {
          variant: 'success',
        });

        if (typeof data.makeRefreshCall == 'function') {
          data.makeRefreshCall();
        }
      })
      .catch(handleAPIError('Cannot Rescind Binder. Please try again later.'))
      .finally(() => setLoading(false));
  };

  const binderNumber =
    data.isPrimePlus || data.product === ProductTypes.prime_tech_plus
      ? data.policyNumber
      : data.quoteNumber;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent
        className="modal-title"
        style={{ padding: '3rem 5rem', fontWeight: 600 }}
      >
        Are you sure you want to rescind{' '}
        <span className="blue-text">Binder #{binderNumber}</span>?
      </DialogContent>
      <DialogActions>
        <CBButton action={close} styleName="cancel" buttonText="Cancel" />
        <CBButton
          type="submit"
          loading={loading}
          disabled={loading}
          styleName="ctaButton"
          buttonText="Rescind Binder"
        />
      </DialogActions>
    </form>
  );
};

export const RescindBinderConfig = {
  RescindBinder: {
    component: RescindBinder,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
    },
  },
};
