import React from 'react';
import {
  GrowthBook,
  GrowthBookProvider as BaseProvider,
} from '@growthbook/growthbook-react';

import { ENVIRONMENTS } from '../types/common/env.dto';
import { useEnvQuery } from '../queries/useEnvQuery';
import type { WithChildrenProps } from '../types/components/common';

// Create a GrowthBook instance
const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    // eslint-disable-next-line no-console
    console.log({
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
});

function GrowthBookProvider({ children }: WithChildrenProps) {
  const [isLoading, setIsLoading] = React.useState(true);
  useEnvQuery({
    onSuccess: (data) => {
      const growthbookEndpoint = growthbookEndpointsToEnvMap[data.envName];

      fetch(growthbookEndpoint)
        .then((res) => res.json())
        .then((json) => {
          growthbook.setFeatures(json.features);
        })
        .catch((error) => {
          console.error(
            `Failed to init growthbook: Endpoint: ${growthbookEndpoint}, ENV: ${data.envName}`
          );
          console.error(error);
        })
        .finally(() => setIsLoading(false));
    },
  });

  if (isLoading) {
    return null;
  }

  return <BaseProvider growthbook={growthbook}>{children}</BaseProvider>;
}

export default GrowthBookProvider;

// The endpoints provide readonly access and can be safely exposed to users in source code
const growthbookEndpointsToEnvMap = {
  [ENVIRONMENTS.DEV_ENV_ID]:
    'https://cdn.growthbook.io/api/features/key_dev_7118bfe6719fef28',
  [ENVIRONMENTS.QA_ENV_ID]:
    'https://cdn.growthbook.io/api/features/key_qa_82c243a17999ffd7',
  [ENVIRONMENTS.STAGING_ENV_ID]:
    'https://cdn.growthbook.io/api/features/key_stag_30e416ee12bb9572',
  [ENVIRONMENTS.PROD_ENV_ID]:
    'https://cdn.growthbook.io/api/features/key_prod_a77be91da2019faa',
} as const;
