import React from 'react';

import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Collapse as MuiCollapse,
  CardContent as MuiCardContent,
} from '@mui/material';

export const ExpandableRowContent = ({ ctx, children, ...props }) => {
  let colcount = ctx.columns.length;

  if (ctx.fixed) {
    colcount = ctx.columns.filter(({ show }) => {
      return typeof show === 'undefined' || show;
    }).length;
  }

  return (
    <MuiTableRow className={props.classes.rowRoot}>
      <MuiTableCell
        className={
          props.expanded
            ? props.classes.cellRootOpen
            : props.classes.cellRootClosed
        }
        colSpan={colcount}
      >
        <MuiCollapse in={props.expanded} timeout="auto">
          <MuiCardContent>{children}</MuiCardContent>
        </MuiCollapse>
      </MuiTableCell>
    </MuiTableRow>
  );
};
