import React from 'react';
import { useForm } from 'react-hook-form-4';
import Moment from 'moment';

// mui
import { DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { InputLabelBase } from '../../../../inputs/InputLabelBase';
import CbButton from '../../../../Buttons/CbButton';
import { TextFieldBase } from '../../../../inputs/TextFieldBase';
import { withFormController } from '../../../../hocs/withFormController';

const DateField = withFormController(TextFieldBase);

export const ExtendPaymentNOC = ({
  close,
  handleExtendPayment,
  paymentDueDate,
}) => {
  const classes = useStyles();

  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting },
    ...methods
  } = useForm({
    defaultValues: {
      defaultDate: Moment.utc(paymentDueDate).format('YYYY-MM-DD'),
      newDueDate: Moment.utc(paymentDueDate).add(7, 'd').format('YYYY-MM-DD'),
    },
  });

  const onSubmit = (formData) => {
    handleExtendPayment({ ...formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent className={classes.wrapper}>
        <Grid container direction="column">
          <Grid container spacing={2}>
            <Grid item md={6}>
              <InputLabelBase required>Current Payment Due Date</InputLabelBase>
            </Grid>
            <Grid item md={6}>
              <InputLabelBase required>New Payment Due Date</InputLabelBase>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <DateField
                type="date"
                control={control}
                name="defaultDate"
                disabled
              />
            </Grid>
            <Grid item md={6}>
              <DateField
                name="newDueDate"
                control={control}
                type="date"
                InputProps={{
                  inputProps: {
                    min: Moment(paymentDueDate).format('YYYY-MM-DD'),
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Grid item md={6}>
            <Typography color="primary" variant="body1">
              Step 1 of 2: Extend Date
            </Typography>
          </Grid>
          <Grid item md={6} align="right">
            <CbButton
              buttonText="Cancel"
              styleName="cancel"
              onClick={() => close()}
            />
            <CbButton
              buttonText="Next"
              styleName="ctaButton"
              type="submit"
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '2rem 5rem !important',
  },
}));
