import { ServiceHandlerFactory } from '../utils/serviceUtils';

export const getWebHooks = ServiceHandlerFactory({
  axios: {
    url: '/api/webhook/v1',
  },
  isCancelable: true,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        return orderBy ? `${orderBy}.keyword,${order}` : null;
      },
    },
  ],
});

export const createWebHook = ServiceHandlerFactory({
  axios: {
    url: '/api/webhook/v1/createHook',
    method: 'post',
  },
});

export const updateWebHook = ServiceHandlerFactory({
  axios: {
    url: '/api/webhook/v1',
    method: 'put',
  },
});

export const deleteWebHookById = ServiceHandlerFactory({
  axios: {
    url: '/api/webhook/v1/:id',
    method: 'delete',
  },
});
