import _config from '../../themes/_base/_config';

export const colorMappings = {
  DRAFT: _config.colors.greyWhite,
  TEMPORARY: _config.colors.greyWhite,
  REQUESTED: _config.colors.yellow,
  REFERRED: _config.colors.textRed,
  INDICATION: _config.colors.textRed,
  READY: _config.colors.cowbellAccent,
  BOUND: _config.colors.cowbellAccent,
  SIGNED: _config.colors.cowbellBlue,
  ACTIVE: _config.colors.cowbellBlue,
  INVALID: _config.colors.error,
  EXPIRED: _config.colors.error,
  DELETED: _config.colors.error,
  DECLINED: _config.colors.error,
  LOST: _config.colors.error,
  VOID: _config.colors.error,
  CANCEL_PENDING: _config.colors.error,
  CANCELLED: _config.colors.error,
  IN_REVIEW: _config.colors.yellow,
};

export const policyColorMappings = {
  REQUESTED: _config.colors.yellow,
  ISSUED: _config.colors.cowbellBlue,
  BOUND: _config.colors.cowbellBlue,
  ACTIVE: _config.colors.cowbellBlue,
  'IN FORCE': _config.colors.cowbellBlue,
  INVALID: _config.colors.error,
  EXPIRED: _config.colors.error,
  DELETED: _config.colors.error,
  VOID: _config.colors.error,
  'CANCEL PENDING': _config.colors.error,
  'CANCEL REQUESTED': _config.colors.error,
  CANCELLED: _config.colors.error,
};

export const uwColorMappings = {
  DRAFT: _config.colors.greyWhite,
  TEMPORARY: _config.colors.greyWhite,
  REQUESTED: _config.colors.yellow,
  REFERRED: _config.colors.yellow,
  INDICATION: _config.colors.yellow,
  READY: _config.colors.cowbellAccent,
  BOUND: _config.colors.cowbellLight,
  SIGNED: _config.colors.cowbellAccent,
  ACTIVE: _config.colors.cowbellAccent,
  INVALID: _config.colors.textRed,
  EXPIRED: _config.colors.error,
  DELETED: _config.colors.error,
  DECLINED: _config.colors.textRed,
  LOST: _config.colors.error,
  VOID: _config.colors.error,
  CANCEL_PENDING: _config.colors.error,
  CANCELLED: _config.colors.error,
  IN_REVIEW: _config.colors.yellow,
};
