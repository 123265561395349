import { withStyles } from '@mui/styles';

// import { Redirect } from 'react-router-dom';
// import { hasUrl } from '../../../utils/appUtils';
// import { useConsoleVersion } from '../../hooks/queries';

// components
import Footer from '../../entry/Footer';
import _config from '../../../themes/_base/_config';

function ServerDown({ classes, ...props }) {
  // const { data: consoleVersion } = useConsoleVersion();

  // if (hasUrl('/serverdown')) {
  //   if (consoleVersion && consoleVersion.v !== 'SERVER_DOWN') {
  //     return <Redirect to={{ pathname: '/loading' }} />;
  //   }
  // }

  setTimeout(() => window.location.reload(), 30000);

  return (
    <main className={`${classes.publicContentWrapper}`}>
      <div className={`${classes.publicContentContainer} ${props.class}`}>
        <div className={classes.mainContent}>
          <h3>
            Apologies for the interruption, we are down for scheduled
            maintenance. Please check back soon.
          </h3>
          <a href="/loading" className={classes.link}>
            Refresh
          </a>
        </div>
      </div>
      <Footer />
    </main>
  );
}

const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1;

const backgroundImages = {
  dark: `logos/login_background_dark.${isSafari ? 'jpg' : 'webp'}`,
  light: `logos/login_background_light.${isSafari ? 'jpg' : 'webp'}`,
  serverDown: `logos/404Screen.${isSafari ? 'jpg' : 'webp'}`,
};

const styles = ({ palette }) => ({
  mainContent: {
    display: 'block',
    padding: 40,
    textAlign: 'center',
    border: `1px solid ${palette.common.lightBlueGrey}`,
    backgroundColor: _config.colors.white,

    h3: {
      color: _config.colors.grey1,
    },
  },

  link: {
    color: _config.colors.brownGrey,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  publicContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'inherit',
    backgroundImage: `url(${backgroundImages.serverDown})`,
    backgroundSize: 'cover',

    '& .public-logo': {
      marginBottom: 40,
      maxWidth: 400,
      width: 250,
    },
  },

  publicContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40,
  },
});

export default withStyles(styles)(ServerDown);
