import React from 'react';
import { SvgIcon } from '@mui/material';

export default function PaperClip({ fill = '#2180e2', ...props }) {
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
      <path fill="none" d="M0 0h12v12H0z" />
      <path
        fill={fill}
        d="M11.75 3.5v5.75a2 2 0 0 1-4 0V3a1.25 1.25 0 0 1 2.5 0v5.25a.5.5 0 1 1-1 0V3.5H8.5v4.75a1.25 1.25 0 1 0 2.5 0V3a2 2 0 0 0-4 0v6.25a2.75 2.75 0 0 0 5.5 0V3.5z"
        transform="translate(-3.5 -.5)"
      />
    </SvgIcon>
  );
}
