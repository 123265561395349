export const imageMappings = {
  MICROSOFT: {
    light: 'images/Microsoft365B.svg',
    dark: 'images/Microsoft365B.svg',
  },
  AWS: {
    light: 'images/AWSW.svg',
    dark: 'images/AWSB.svg',
  },
  GOOGLE: {
    light: 'images/GoogleCloudB.svg',
    dark: 'images/GoogleCloudB.svg',
  },
  QUALYS: {
    light: 'images/QualysW.svg',
    dark: 'images/QualysB.svg',
  },
  SPLUNK: {
    light: 'images/SplunkW.svg',
    dark: 'images/SplunkB.svg',
  },
  TENABLE: {
    light: 'images/TenableB.svg',
    dark: 'images/TenableB.svg',
  },
  ZIMPERIUM: {
    light: 'images/ZimperiumB.svg',
    dark: 'images/ZimperiumB.svg',
  },

  AZURE: {
    light: 'images/AzureB.svg',
    dark: 'images/AzureB.svg',
  },
  FICO: {
    light: 'images/FicoB.svg',
    dark: 'images/FicoB.svg',
  },
  MOBILEIRON: {
    light: 'images/MobileIronLight.svg',
    dark: 'images/MobileIronB.svg',
  },
  PALOALTO: {
    light: 'images/PaloAltoLight.svg',
    dark: 'images/PaloAltoB.svg',
  },
  RAPID7: {
    light: 'images/Rapid7L.svg',
    dark: 'images/Rapid7D.svg',
  },
  SENTINEL: {
    light: 'images/AzureSentinel.svg',
    dark: 'images/AzureSentinel.svg',
  },
  SECURITYSTUDIO: {
    light: 'images/securityStudio_light.svg',
    dark: 'images/securityStudio_dark.svg',
  },
  QUALYSPC: {
    light: 'images/Qualys.svg',
    dark: 'images/Qualys.svg',
  },
  QUALYSVM: {
    light: 'images/Qualys.svg',
    dark: 'images/Qualys.svg',
  },
  QUALYSCS: {
    light: 'images/Qualys.svg',
    dark: 'images/Qualys.svg',
  },
  FORTINET: {
    light: 'images/Fortinet_logo_Black.svg',
    dark: 'images/Fortinet_logo_White.svg',
  },
  WIZER: {
    light: 'images/Wizer_LightLogo.svg',
    dark: 'images/Wizer_DarkLogo.svg',
  },
  SAFEGUARD: {
    light: 'images/SafeguardLogo_Black.svg',
    dark: 'images/SafeguardLogo_White.svg',
  },
  PROOFPOINT: {
    light: 'images/ProofPoint_Black.svg',
    dark: 'images/ProofPoint_White.svg',
  },
  SECUREWORKS: {
    light: 'images/secureworks_icon.png',
    dark: 'images/secureworks_icon.png',
  },
  GOOGLEWORKSPACE: {
    light: 'images/GoogleWorkspace.svg',
    dark: 'images/GoogleWorkspace.svg',
  },
  GSC: {
    light: 'images/connectors/GSC.svg',
    dark: 'images/connectors/GSC.svg',
  },
  CLOUDFLARE: {
    light: 'images/connectors/Cloudflare.svg',
    dark: 'images/connectors/Cloudflare.svg',
  },
  SOPHOS: {
    light: 'images/connectors/SophosCardLogo.svg',
    dark: 'images/connectors/SophosCardLogo.svg',
  },
  default: {
    light: 'images/DefaultConnector.svg',
    dark: 'images/DefaultConnector.svg',
  },
};
