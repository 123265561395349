import React from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
// helpers
import _ from 'lodash';
import { useSnackbar } from 'notistack';

// mui
import {
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  MenuItem,
  Select as MuiSelect,
  useTheme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

// icons
import { useFeature } from '@growthbook/growthbook-react';
import { iconMappings } from '../../console/_global/navigation/IconsMappings';

// components
import CbButton from '../../components/Buttons/CbButton';
import { TypeAheadBase } from '../../components/inputs';
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import { TextFieldBase } from '../../components/inputs/TextFieldBase';

// actions
import { getAgentsInAdmin } from '../../api/apis';
import { updateAgent } from '../../agencies/AgencyService';

// reducers
import { setAgencyData } from '../../console/_reducers/prime100.reducer';

import { COWBELL_ACCOUNTS } from '../../components/tables/table_constants';

import { manageAPIError } from '../../utils';
import { useAccountAttestation } from '../../accounts/attestation/useAccountAttestation';
import { Modal } from '../../components/modals/v2/helpers/v2.modal.helpers';
import { checkProductEligibilityForQuoting } from '../../policies/PolicyService';
import { PrimeXProductTypes } from '../../types';
import { useCowbellTranslations } from '../../i18n/translations';
import { FEATURE_FLAG_KEYS } from '../../config/growthbook';

// FIR-3460
export const CowbellQuoteCreationWithAgencySelection = ({ data, close }) => {
  const prime100ProFeature = useFeature(FEATURE_FLAG_KEYS.P100PRO);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { translations } = useCowbellTranslations(['Agency', 'Agent']);

  // determine if agent was assigned on account creation (whether agent exists), otherwise use this to assign agent while creating quote
  const [agentExistsOnAccountCreation, setAgentExistsOnAccountCreation] =
    React.useState(false);
  // cannot create quote as there are no agents in the agency
  const [noQuoteCreation, setNoQuoteCreation] = React.useState(false);

  const [agentInputValue, setAgentInputValue] = React.useState('');
  const [agencyId, setAgencyId] = React.useState(_.get(data, 'agencyId', ''));
  const [agentOptions, setAgentOptions] = React.useState([]);

  const hardLinkedAgencyFromAgenciesData = _.find(data.agencies, { agencyId });

  const classes = useStyles();
  const {
    handleSubmit,
    formState: { isSubmitting },
    ...methods
  } = useForm({
    defaultValues: {
      agency: {
        agencyName: _.get(hardLinkedAgencyFromAgenciesData, 'agencyName', ''),
        agencyId: _.get(hardLinkedAgencyFromAgenciesData, 'agencyId', ''),
      },
      agent: {
        agentFirstName: _.get(
          hardLinkedAgencyFromAgenciesData,
          'agentFirstName',
          ''
        ),
        agentLastName: _.get(
          hardLinkedAgencyFromAgenciesData,
          'agentLastName',
          ''
        ),
        agentEmail: _.get(hardLinkedAgencyFromAgenciesData, 'agentEmail', ''),
        agentPhone: _.get(hardLinkedAgencyFromAgenciesData, 'agentPhone', ''),
      },
      team: {
        teamIds: _.get(hardLinkedAgencyFromAgenciesData, 'teamIds'),
        teamNames: _.get(hardLinkedAgencyFromAgenciesData, 'teamNames'),
      },
    },
  });

  methods.watch(['agency', 'agent', 'team']);

  const { agencies } = data;

  const values = methods.getValues();

  React.useEffect(() => {
    // adding api for testing purposes phase1.
    checkProductEligibilityForQuoting(
      {},
      {
        accountId: data.id,
        eligibleProducts: data.eligibleProducts,
      }
    ).catch(() => {
      enqueueSnackbar('Cannot Quote at the moment.', { variant: 'error' });
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setAgentExistsOnAccountCreation(!_.isUndefined(data.agentEmail));
    methods.register({ name: 'agency' }, { required: true });
    methods.register({ name: 'agent' }, { required: true });
    methods.register({ name: 'team' }, { required: true });
    // eslint-disable-next-line
  }, []);

  const handleChange = ({ target }) => {
    methods.setValue(target.name, target.value);
    setAgencyId(_.get(target, 'value.agencyId'));
    methods.setValue('agent', '');
    methods.setValue('team', target.value);

    if (
      _.isUndefined(_.get(target, 'value.agentEmail')) ||
      !_.get(target, 'value.agentEmail')
    ) {
      setAgentExistsOnAccountCreation(false);
      setNoQuoteCreation(true);

      getAgentsInAdmin({
        agencyId: target.value.agencyId,
        teamIds: _.get(target, 'value.teamIds[0]'),
      }).then(({ data: adminData }) => {
        if (adminData.content.length === 0) {
          setNoQuoteCreation(true);
          methods.setValue('team', '');
        } else {
          setNoQuoteCreation(false);
          return setAgentOptions(
            adminData.content.map((agent) => ({
              label: `${agent.firstName} ${agent.lastName} (${agent.email})`,
              value: agent.email,
              agentFirstName: agent.firstName,
              agentLastName: agent.lastName,
              agentEmail: agent.email,
              agentPhone: agent.phone,
              meta: {
                ...agent,
              },
            }))
          );
        }
      });
    } else {
      setAgentExistsOnAccountCreation(true);
      setNoQuoteCreation(false);
      methods.setValue('agent', target.value);
    }
  };

  const handleNewBusinessRedirect = () => {
    const isPrimeXProduct = Object.values(PrimeXProductTypes).includes(
      data.productType
    );
    // check the dispatch function on 189 for bug fixing
    if (prime100ProFeature.on || isPrimeXProduct) {
      push(`/admin/quotes/${data.productType}/${data.id}/requestQuote/verify`);
    } else {
      push(`/admin/requestQuote/${data.productType}/${data.id}/details`); // do not change this url or add agencyId to it if things fail on P250 customise page
    }
  };

  const handleRenewalRedirect = () => {
    Modal.show('RenewalMessageForCowbell', {
      data: { companyName: data.name, ...values },
    });
  };

  const { handleCheckStatusOnAccount } = useAccountAttestation({
    account: data,
    onNewBusinessRedirect: handleNewBusinessRedirect,
    onRenewalRedirect: handleRenewalRedirect,
  });

  const onSubmit = (formData) => {
    close();

    const { agency, agent, team } = formData;
    // eslint-disable-next-line no-shadow
    const { agencyId, agencyName } = agency;
    const { agentFirstName, agentLastName, agentEmail, agentPhone } = agent;
    const { teamIds, teamNames } = team;

    const agencyData = {
      agencyId,
      agencyName,
      agentFirstName,
      agentLastName,
      agentEmail,
      agentPhone,
      teamIds,
      teamNames,
    };
    dispatch(setAgencyData({ ...agencyData }));

    if (!agentExistsOnAccountCreation) {
      const {
        policyContactFirstName,
        policyContactLastName,
        policyContactEmail,
        policyContactPhone,
        id,
        name,
      } = data;

      const payload = {
        accountId: id,
        accountName: name,
        contactFirstName: policyContactFirstName,
        contactLastName: policyContactLastName,
        contactEmail: policyContactEmail,
        contactPhone: policyContactPhone,
        agentEmail,
        agentFirstName,
        agentLastName,
        agentPhone,
        team: {
          name: teamNames[0],
          id: teamIds[0],
        },
        agencyId,
      };

      return updateAgent(payload)
        .then(() => {
          enqueueSnackbar(
            `Account ${data.name} updated with ${translations.Agent} ${agentFirstName} ${agentLastName}`,
            { variant: 'success' }
          );

          close();
          queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
        })
        .catch((error) => {
          enqueueSnackbar(
            manageAPIError(error, 'An error has occured. Please try again'),
            { variant: 'error' }
          );
        });
    }

    handleCheckStatusOnAccount(values?.agency?.agencyId);
  };

  const renderOptions = (option) => {
    let nextOption = option;
    if (typeof option === 'string') {
      nextOption = { label: option, value: option };
    }

    return (
      <MenuItem key={nextOption.agencyName} value={nextOption}>
        {nextOption.agencyName}
      </MenuItem>
    );
  };

  const handleAgent = React.useCallback(
    (selectedOption) => {
      methods.setValue('agent', selectedOption);
    },
    // eslint-disable-next-line
    []
  );

  const onFetchAgent = React.useCallback(
    ({ input }, callback) => {
      getAgentsInAdmin({ agencyId, search: input }).then(
        ({ data: adminData }) => {
          callback(
            adminData.content.map((agent) => ({
              label: `${agent.firstName} ${agent.lastName} (${agent.email})`,
              value: agent.email,
              agentFirstName: agent.firstName,
              agentLastName: agent.lastName,
              agentEmail: agent.email,
              agentPhone: agent.phone,
              meta: {
                ...agent,
              },
            }))
          );
        }
      );
    },
    [agencyId]
  );

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2rem 5rem 3.5rem' }}>
          <Grid container direction="column">
            <Grid item md={12}>
              <InputLabelBase required className={classes.label}>
                {translations.Agency}
              </InputLabelBase>
              {!_.isUndefined(data.agencyId) ? (
                <TextFieldBase
                  name="agency"
                  required
                  value={_.get(values, 'agency.agencyName')}
                  disabled
                />
              ) : (
                <MuiSelect
                  variant="standard"
                  name="agency"
                  required
                  onChange={handleChange}
                  value={values.agency}
                  classes={{
                    select: classes.select,
                    icon: classes.selectIcon,
                  }}
                >
                  {agencies.map(renderOptions)}
                </MuiSelect>
              )}
            </Grid>
            <Grid item md={12}>
              <InputLabelBase required className={classes.label}>
                {translations.Agent}
              </InputLabelBase>
              {agentExistsOnAccountCreation ? (
                <TextFieldBase
                  name="agent"
                  required
                  value={`${_.get(values, 'agent.agentFirstName')} ${_.get(
                    values,
                    'agent.agentLastName'
                  )} (${_.get(values, 'agent.agentEmail')})`}
                  disabled
                />
              ) : (
                <TypeAheadBase
                  name="agent"
                  required
                  error={
                    !values.agent && _.get(methods.errors, 'agent.message')
                  }
                  placeholder="select agent"
                  value={values.agent}
                  onInputChange={setAgentInputValue}
                  inputValue={agentInputValue}
                  disabled={!agencyId || noQuoteCreation}
                  defaultOptions={agentOptions}
                  onChange={handleAgent}
                  options={agentOptions}
                  onOptionsChange={setAgentOptions}
                  onFetch={onFetchAgent}
                />
              )}
            </Grid>
            <Grid item md={12}>
              <InputLabelBase required className={classes.label}>
                {translations.Agent}'s Team
              </InputLabelBase>

              <TextFieldBase
                name="team"
                required
                value={_.get(values, 'team.teamNames[0]')}
                disabled
              />
            </Grid>
          </Grid>
          {noQuoteCreation && (
            <FormHelperText className="api-text-error">
              Cannot create quote for account {data.name}.
              <br />
              No agents exists.
              <br />
              Please contact support@cowbellcyber.ai.
            </FormHelperText>
          )}
          {values?.agent?.agentEmail && !agentExistsOnAccountCreation && (
            <FormHelperText
              style={{
                color: theme.config.colors.cowbellBlue,
                fontStyle: 'italic',
              }}
            >
              Selected agent will also get assigned to the account if you choose
              to proceed.
            </FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton buttonText="Cancel" onClick={close} styleName="cancel" />
          <CbButton
            buttonText="Create Quote"
            type="submit"
            styleName="ctaButton"
            loading={isSubmitting}
            disabled={
              isSubmitting ||
              noQuoteCreation ||
              !(
                values?.agency?.agencyId &&
                values?.agent?.agentEmail &&
                values?.team.teamNames
              )
            }
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

const useStyles = makeStyles(() => ({
  select: {
    justifyContent: 'left',
    '&:focus': {
      borderRadius: 5,
    },
  },
  selectIcon: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

export const AgencySelectionForQuoteCreationConfig = {
  CowbellQuoteCreationWithAgencySelection: {
    component: CowbellQuoteCreationWithAgencySelection,
    config: {
      title: 'Create Quote(s)',
      icon: iconMappings.ACCOUNTS.iconImport,
      maxWidth: 'md',
      fullWidth: false,
    },
  },
};

export default CowbellQuoteCreationWithAgencySelection;
