export const mapColumnExtensions = (columns, tableWidth) =>
  columns.map((column) => ({
    columnName: column.name,
    wordWrapEnabled: column.multiline !== false,
    align: column.align || 'left',
    width: calculateColumnWidth(column, tableWidth),
    minWidth: 90,
  }));

export const calculateColumnWidth = (column, tableWidth) => {
  if (column.width === 'auto') {
    return 'auto';
  }
  if (!column.width) return 180;
  if (typeof column.width === 'number') return column.width;
  if (typeof column.width === 'string' && column.width.endsWith('%')) {
    const factor =
      Number(column.width.slice(0, column.width.length - 1)) * 0.01;
    return tableWidth * factor;
  }
  if (
    typeof column.width === 'string' &&
    Object.prototype.hasOwnProperty.call(columnWidthMapping, column.width)
  ) {
    const { pct, min, max } = columnWidthMapping[column.width];
    const percent = pct * tableWidth;

    if (percent < min) return min;
    if (percent > max) return max;
    return percent;
  }
  throw new Error(
    `Invalid value provided for "column.width": { columnName: ${column.name}, width: ${column.width} }`
  );
};

const columnWidthMapping = {
  xxs: { pct: 0, min: 50 },
  xs: { pct: 0.055, min: 70, max: 80 },
  s: { pct: 0.073, min: 90, max: 120 },
  m: { pct: 0.13, min: 140, max: 250 },
  l: { pct: 0.18, min: 220, max: 360 },
  xl: { pct: 0.3, min: 0, max: Infinity },
};
