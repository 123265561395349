import React, { useState } from 'react';
import { useForm } from 'react-hook-form-4';
import _ from 'lodash';
import Moment from 'moment';

// mui
import {
  Box as MuiBox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// components
import DescTooltip from '../DescTooltip';
import RadioButtons from '../inputs/RadioButtons';
import CbButton from '../Buttons/CbButton';

// utils
import { setPrime250SecurityQuestions } from '../../console/_reducers/prime250.reducer';
import { radioOptions, selectOptions } from '../inputs/InputOptionsStatistics';
import { PubSub } from '../../utils/eventUtils';

import { withShowable } from '../../console/_global/lib/withShowable';

// actions
import { updateSecurityQuestions } from '../../accounts/AccountService';
import { manageAPIError } from '../../utils';
import { useToggleModal } from '../../utils/modal.utils';

const styles = ({ config, palette }) => {
  return {
    container: {
      fontSize: `${config.textSizes.tertia} !important`,
      lineHeight: `${1.2} !important`,
    },
    radioGroup: {
      marginTop: '0.416rem',
      maxHeight: '3rem',
      border: `1px solid ${palette.common.almostWhite}`,
      borderRadius: 5,
      '& .MuiFormControlLabel-root': {
        marginRight: 0,
      },
    },
    radioGroupContainer: {
      width: '100%',
      height: '100%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    flexBox: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 15,
    },
    leftButton: {
      paddingRight: '1rem',
      margin: '-1px 0 -1px -1px',
      borderTop: '1px solid transparent',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
    rightButton: {
      paddingRight: '1rem',
      margin: '-1px -1px -1px 0',
      borderTop: '1px solid transparent',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
    labels: {
      textAlign: 'left',
      marginRight: 30,
      maxWidth: 560,
    },
    sectionHeadig: {
      textAlign: 'left',
      fontSize: config.textSizes.paragon,
      lineHeight: 1,
      fontWeight: 'normal',
      color: palette.primary.contrastText,
    },
    blueColor: {
      color: config.colors.lightBlue,
    },
    noContainer: {
      margin: '2rem 0',
      fontSize: config.textSizes.primer,
      color: palette.primary.contrastText,
      textAlign: 'center',
    },
    select: {
      width: '7.5rem',
      maxHeight: '3rem',
      justifyContent: 'center',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    questionSection: {
      position: 'relative',

      '&:not(:last-of-type):before': {
        content: "''",
        width: '100%',
        height: 1,
        margin: 0,
        flexShrink: 0,
        position: 'absolute',
        bottom: 0,
        left: 0,
        border: 'none',
        backgroundColor: '#707070',
      },
    },
  };
};

const tooltip =
  'Internet-accessible systems are web-servers and email-servers. An organization’s trusted network can be within a demilitarized zone (DMZ) or at a third-party service provider.';

const SaveButtonText = {
  'quote-now': 'Save & Quote Now',
  'open-cart': 'Save & View Quotes',
  'add-to-list': 'Save & Add to List',
};

const Box = withShowable(MuiBox);

export const SecurityQuestions = withStyles(styles)(
  ({ close, classes, data, ...props }) => {
    const toggleModal = useToggleModal();

    const {
      account,
      accountId,
      action,
      actionSource,
      coverage,
      dispatch,
      isQuoting,
      questions,
      ui,
    } = data;
    const {
      contingentSystemFailureRequired,
      cyberCrimeLossRequired,
      fullSystemFailureRequired,
    } = questions;

    const getValue = (val) => {
      if (val === true || val === 'true') {
        return { value: true, label: 'Yes' };
      }
      if (_.isNull(val)) {
        return { value: null, label: 'No' };
      }
      return { value: false, label: 'No' };
    };

    const {
      register,
      setValue,
      getValues,
      watch,
      handleSubmit,
      formState: { isSubmitting },
    } = useForm({
      defaultValues: {
        thirdPartySecurityAgreement: getValue(
          questions.thirdPartySecurityAgreement
        ),
        isVerifyingBankAccounts: getValue(questions.isVerifyingBankAccounts),
        isAuthenticatingFundTransferRequests: getValue(
          questions.isAuthenticatingFundTransferRequests
        ),
        isPreventingUnauthorizedWireTransfers: getValue(
          questions.isPreventingUnauthorizedWireTransfers
        ),
        backupFrequency: questions.backupFrequency,
        testedFullFailover: getValue(questions.testedFullFailover),
        dmzSeparation: getValue(questions.dmzSeparation),
      },
    });

    React.useEffect(() => {
      register({ name: 'dmzSeparation', type: 'custom' });
      watch(['backupFrequency', 'dmzSeparation']);
      // eslint-disable-next-line
    }, []);

    const [error, setError] = useState('');

    const values = getValues();

    const inFunc = (name) => (value) => {
      setValue(name, getValue(value));
    };

    const handleChange = (event) => {
      setValue('dmzSeparation', getValue(event.target.value));
    };

    const handleSelect = (event) => {
      setValue('backupFrequency', event.target.value);
    };

    const onSubmit = (formData) => {
      const {
        civilOrCriminalAction,
        lossInBusinessIncome,
        pastCyberIncident,
        patchingFrequency,
        pendingLitigation,
        securityBreachRequiringNotification,
        claimHistory,
        mfaAuthentication,
        mfaCloudDeployments,
        mfaEmail,
        mfaMissionCriticalSystems,
        mfaRemoteAccess,
        mfaOther,
        incidentResponsePlan,
        changeInScope,
        changeInScopeDetail,
        backupFrequencyEncrypted,
        backupFrequencySeparate,
        backupFrequencyOther,
        backupFrequencyTested,
      } = questions;

      const payload = {
        accountId,
        isVerifyingBankAccounts: _.get(
          formData,
          'isVerifyingBankAccounts.value',
          undefined
        ),
        isAuthenticatingFundTransferRequests: _.get(
          formData,
          'isAuthenticatingFundTransferRequests.value',
          undefined
        ),
        isPreventingUnauthorizedWireTransfers: _.get(
          formData,
          'isPreventingUnauthorizedWireTransfers.value',
          undefined
        ),
        claimHistory,
        mfaAuthentication,
        mfaCloudDeployments,
        mfaEmail,
        mfaMissionCriticalSystems,
        mfaRemoteAccess,
        mfaOther,
        incidentResponsePlan,
        changeInScope,
        changeInScopeDetail,
        p250Details: {
          backupFrequencyEncrypted,
          backupFrequencySeparate,
          backupFrequencyOther,
          backupFrequencyTested,
          dmzSeparation: _.get(formData, 'dmzSeparation.value', undefined),
          thirdPartySecurityAgreement: _.get(
            formData,
            'thirdPartySecurityAgreement.value',
            undefined
          ),
          backupFrequency: _.get(
            formData,
            'backupFrequency',
            questions.backupFrequency
          ),
          testedFullFailover: _.get(
            formData,
            'testedFullFailover.value',
            undefined
          ),
          civilOrCriminalAction,
          lossInBusinessIncome,
          pastCyberIncident,
          patchingFrequency,
          pendingLitigation,
          securityBreachRequiringNotification,
        },
      };

      return updateSecurityQuestions({}, payload)
        .then((resp) => {
          close();
          const dispatchPayload = {
            lastSecurityAnswered: Moment().unix(),
            ..._.pick(resp.data, [
              'isVerifyingBankAccounts',
              'isAuthenticatingFundTransferRequests',
              'isPreventingUnauthorizedWireTransfers',
            ]),
            ..._.pick(resp.data.p250Details, [
              'dmzSeparation',
              'thirdPartySecurityAgreement',
              'backupFrequency',
              'testedFullFailover',
            ]),
          };

          data.dispatch(setPrime250SecurityQuestions(dispatchPayload));

          setTimeout(() => {
            PubSub.publish('security-questions:saved');
          }, 0);

          switch (action) {
            case 'quote-now':
              close();
              PubSub.publish('security-questions:saved:quote-now');
              break;

            case 'open-cart':
              close();
              toggleModal.direct(
                'RequestQuote250Cart',
                { account, ui, dispatch, ...props },
                { title: 'List of Quotes to Request', maxWidth: 'lg' }
              );
              break;

            case 'add-to-list':
              close();
              PubSub.publish(
                'security-questions:saved:add-to-list',
                actionSource
              );
              break;

            default:
              return null;
          }
        })
        .catch((error) => {
          const errorMessage = manageAPIError(
            error,
            'Failed to update security questions.'
          );

          setError(errorMessage);
        });
    };

    const renderOptions = ({ label, value }) => {
      return (
        <MuiMenuItem className={classes.menuItem} key={label} value={value}>
          {label}
        </MuiMenuItem>
      );
    };

    const DmzRadioOptions = () => {
      return (
        <FormControl
          variant="standard"
          component="fieldset"
          className={classes.radioGroup}
        >
          <RadioGroup
            row
            aria-label="position"
            name="dmzSeparation"
            value={_.get(values, 'dmzSeparation.value')}
            onChange={handleChange}
            className={classes.radioGroupContainer}
          >
            {radioOptions.map((radio, idx) => {
              return (
                <FormControlLabel
                  value={radio.value}
                  control={
                    <Radio
                      color="primary"
                      inputProps={{ required: contingentSystemFailureRequired }}
                    />
                  }
                  label={radio.label}
                  labelPlacement="end"
                  className={`${
                    idx === 0 ? classes.leftButton : classes.rightButton
                  }`}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      );
    };

    let showContingentSystemFailure = contingentSystemFailureRequired;
    let showCyberCrimeLoss = cyberCrimeLossRequired;
    let showFullSystemFailure = fullSystemFailureRequired;

    // show all questions if no specific coverage is passed
    if (!coverage && !isQuoting) {
      showContingentSystemFailure = true;
      showCyberCrimeLoss = true;
      showFullSystemFailure = true;
    } else if (!isQuoting) {
      showContingentSystemFailure = coverage._id == 23;
      showCyberCrimeLoss = coverage._id == 24;
      showFullSystemFailure = coverage._id == 34;
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingBottom: 0 }}>
          <div className={`modal-title ${classes.container}`}>
            <Box
              className={classes.questionSection}
              show={showContingentSystemFailure}
            >
              <h1 className={classes.sectionHeadig}>
                Contingent System Failure
              </h1>
              <div className={classes.flexBox}>
                <span className={`${classes.labels} ${classes.blueColor}`}>
                  Are all internet-accessible systems segregated from the
                  organization’s trusted network?
                  <DescTooltip tooltip={tooltip} />
                </span>
                {DmzRadioOptions(contingentSystemFailureRequired)}
              </div>
              <div className={classes.flexBox}>
                <span className={classes.labels}>
                  Do agreements with third-party service providers require
                  levels of security commensurate with the organization’s
                  information security standard?
                </span>
                <RadioButtons
                  classContainer={classes.radioGroup}
                  value={values.thirdPartySecurityAgreement}
                  values={radioOptions}
                  infunc={inFunc('thirdPartySecurityAgreement')}
                  name="thirdPartySecurityAgreement"
                  ref={register(
                    { name: 'thirdPartySecurityAgreement' },
                    { required: contingentSystemFailureRequired }
                  )}
                />
              </div>
            </Box>

            <Box className={classes.questionSection} show={showCyberCrimeLoss}>
              <h1 className={classes.sectionHeadig}>Cyber Crime Loss</h1>
              <div className={classes.flexBox}>
                <span className={classes.labels}>
                  Does the organization verify vendor/supplier bank accounts
                  before adding to their accounts payable systems?
                </span>
                <RadioButtons
                  classContainer={classes.radioGroup}
                  value={values.isVerifyingBankAccounts}
                  values={radioOptions}
                  infunc={inFunc('isVerifyingBankAccounts')}
                  name="isVerifyingBankAccounts"
                  ref={register(
                    { name: 'isVerifyingBankAccounts' },
                    { required: cyberCrimeLossRequired }
                  )}
                />
              </div>
              <div className={classes.flexBox}>
                <span className={classes.labels}>
                  Does the organization authenticate funds transfer requests
                  (e.g. by calling a customer to verify the request at a
                  predetermined phone number)?
                </span>
                <RadioButtons
                  classContainer={classes.radioGroup}
                  value={values.isAuthenticatingFundTransferRequests}
                  values={radioOptions}
                  infunc={inFunc('isAuthenticatingFundTransferRequests')}
                  name="isAuthenticatingFundTransferRequests"
                  ref={register(
                    { name: 'isAuthenticatingFundTransferRequests' },
                    { required: cyberCrimeLossRequired }
                  )}
                />
              </div>
              <div className={classes.flexBox}>
                <span className={classes.labels}>
                  Does the organization prevent unauthorized employees from
                  initiating wire transfers?
                </span>
                <RadioButtons
                  classContainer={classes.radioGroup}
                  value={values.isPreventingUnauthorizedWireTransfers}
                  values={radioOptions}
                  infunc={inFunc('isPreventingUnauthorizedWireTransfers')}
                  name="isPreventingUnauthorizedWireTransfers"
                  ref={register(
                    { name: 'isPreventingUnauthorizedWireTransfers' },
                    { required: cyberCrimeLossRequired }
                  )}
                />
              </div>
            </Box>

            <Box
              className={classes.questionSection}
              show={showFullSystemFailure}
            >
              <h1 className={classes.sectionHeadig}>Full System Failure</h1>
              <div className={classes.flexBox}>
                <span className={classes.labels}>
                  How often does the organization perform backups of
                  business-critical data?
                </span>
                <MuiSelect
                  variant="standard"
                  name="backupFrequency"
                  required
                  onChange={handleSelect}
                  value={values.backupFrequency}
                  classes={{
                    select: classes.select,
                    icon: classes.selectIcon,
                  }}
                  ref={register({ name: 'backupFrequency' })}
                >
                  {selectOptions.map(renderOptions)}
                </MuiSelect>
              </div>
              <div className={classes.flexBox}>
                <span className={`${classes.labels} ${classes.blueColor}`}>
                  Are all internet-accessible systems segregated from the
                  organization’s trusted network?
                  <DescTooltip tooltip={tooltip} />
                </span>
                {DmzRadioOptions(fullSystemFailureRequired)}
              </div>

              <div className={classes.flexBox}>
                <span className={classes.labels}>
                  Has the organization tested a full failover of the most
                  critical servers?
                </span>
                <RadioButtons
                  classContainer={classes.radioGroup}
                  value={values.testedFullFailover}
                  values={radioOptions}
                  infunc={inFunc('testedFullFailover')}
                  name="testedFullFailover"
                  ref={register(
                    { name: 'testedFullFailover' },
                    { required: fullSystemFailureRequired }
                  )}
                />
              </div>
            </Box>
          </div>
          {error && (
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" action={close} buttonText="Cancel" />
          <CbButton
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText={SaveButtonText[action] || 'Save'}
            type="submit"
          />
        </DialogActions>
      </form>
    );
  }
);

export default SecurityQuestions;
