import { ServiceHandlerFactory } from '../../../utils/serviceUtils';

export const searchRetailAgents = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/wra/search',
  },
});

export const getAddToSlackUrl = ServiceHandlerFactory({
  axios: {
    url: '/api/slack/v1/app/add-url',
  },
});

export const postFeedback = ServiceHandlerFactory({
  axios: {
    url: ' /api/user/v1/feedback',
    method: 'post',
  },
});

export const declineAccount = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/account/decline/:id',
    method: 'put',
  },
});

export const undeclineAccount = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/account/undecline/:id',
    method: 'put',
  },
});

export const lostAccount = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/lost/:id',
    method: 'put',
  },
});
