import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormHelperText, Box, Fade } from '@mui/material';

import { styled, makeStyles } from '@mui/styles';
import { PubSub } from '../../utils/eventUtils';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/forms';
import { InputLabelBase } from '../inputs/InputLabelBase';
import { SimpleSelect } from '../inputs';

const TextField = withFormController(TextFieldBase);
const Select = withFormController(SimpleSelect);

export const FileUpload = ({ handleUploadDocument }) => {
  return (
    <DnDContainer onClick={handleUploadDocument}>
      <CloudIcon alt="cloud-upload" src="images/cloud-upload.svg" />
      <DnDText>
        Drag and Drop a file
        <br /> here or Click to Upload
      </DnDText>
    </DnDContainer>
  );
};

const defaultDocTypeOptions = [
  {
    value: 'LPR',
    label: 'LPR',
  },
  {
    value: 'PH_Cancellation_Request',
    label: 'PH Cancellation Request',
  },
];

export const FileUploadWithFormFields = ({
  fileDetails,
  file,
  handleReUploadFile,
  handleUploadFile,
  RenderFileIcon,
  errors = {},
  FileUploadHeaderText,
  docTypeOptions = defaultDocTypeOptions,
}) => {
  const classes = useClasses();
  const { ...methods } = useFormContext();

  return !fileDetails ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={1}
      width="100%"
    >
      {!FileUploadHeaderText ? (
        <Fade in timeout={1500}>
          <Box width="100%" display="flex" justifyContent="center">
            <Box
              width="75%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <FileUploadHeader required>Upload Document</FileUploadHeader>
              <FileUploadSubHeader>
                Please upload LPR or a PDF of the email confirmation with
                cancellation date from the Policyholder.
              </FileUploadSubHeader>
            </Box>
          </Box>
        </Fade>
      ) : (
        <Fade in timeout={1250}>
          <Box width="100%">
            <FileUploadHeaderText />
          </Box>
        </Fade>
      )}
      <FileUpload handleUploadDocument={handleUploadFile} />
      <Box mt={1} display="flex" justifyContent="center" width="100%">
        <SupportedFilesText>
          Supported File Types: PDF, DOC, JPEG & PNG
        </SupportedFilesText>
      </Box>
    </Box>
  ) : (
    <>
      <Fade in timeout={1250}>
        <Box mt={2} width="100%">
          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width="30%" display="flex" alignItems="center">
              <FormInputLabel>File Name:</FormInputLabel>
            </Box>
            <Box width="70%" display="flex" alignItems="center">
              <StyledTextField
                showErrorPlace={false}
                name="fileName"
                defaultValue={file.name}
                fullWidth
              />
            </Box>
          </Box>
          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width="30%" display="flex" alignItems="center">
              <FormInputLabel>File Type:</FormInputLabel>
            </Box>
            <Box width="70%" display="flex" alignItems="center">
              {RenderFileIcon}
            </Box>
          </Box>
          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width="30%" display="flex" alignItems="center">
              <FormInputLabel required>Doc Type:</FormInputLabel>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width="70%"
              justifyContent="center"
            >
              <Select
                name="docType"
                control={methods.control}
                fullWidth
                classes={{
                  select: classes.select,
                  icon: classes.selectIcon,
                }}
                options={docTypeOptions}
                required
                inputProps={{ indent: false }}
                ErrorProps={{ renderErrorPlaceholder: true }}
                MenuProps={{
                  getContentAnchorEl: () => null,
                }}
              />
              {errors.docType && (
                <StyledFormHelperText>
                  {errors.docType.message}
                </StyledFormHelperText>
              )}
            </Box>
          </Box>
        </Box>
      </Fade>
      <Fade in timeout={1500}>
        <Box mt={4} display="flex" justifyContent="center" alignItems="center">
          <ReUploadButton onClick={handleReUploadFile}>
            Replace Document
          </ReUploadButton>
        </Box>
      </Fade>
    </>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  select: {
    justifyContent: 'left',

    '&.MuiInputBase-input': {
      backgroundColor: palette.background.active,
    },

    '&:focus': {
      borderRadius: 5,
      backgroundColor: palette.background.active,
    },
  },
  selectIcon: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

const DnDContainer = styled('div')(({ theme: { palette } }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'center',
  margin: '0 auto',
  height: '5.833rem',
  width: '25rem',
  borderRadius: '5px',
  border: `1px solid ${palette.primary.main}`,
  cursor: 'pointer',
}));

const CloudIcon = styled('img')({
  marginLeft: '2rem',
  width: '3rem',
  height: '3rem',
  margin: '0 0 0 1rem',
});

const DnDText = styled('p')(({ theme: { palette } }) => ({
  marginLeft: '2.3rem',
  maxWidth: '12rem',
  fontSize: '1rem',
  lineHeight: 1.33,
  color: palette.primary.main,
}));

const Header = styled('p')(({ theme: { palette, config } }) => ({
  fontSize: config.textSizes.primer,
  color: palette.primary.main,
  margin: 0,
  padding: 0,
}));

const FormInputLabel = styled(InputLabelBase)(
  ({ theme: { palette, config } }) => ({
    fontSize: config.textSizes.primer,
    color: palette.primary.main,
    margin: 0,
    padding: 0,
  })
);

const StyledFormHelperText = styled(FormHelperText)({
  margin: 0,
  padding: 0,
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.modal,
  width: '100%',
  margin: 0,
  padding: 0,
}));

const ReUploadButton = styled('button')(({ theme: { palette, config } }) => ({
  fontSize: config.textSizes.tertia,
  color: palette.primary.main,
  backgroundColor: palette.background.radioButtonSelected,
  borderRadius: 5,
  padding: '0.5rem 1rem',
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
}));

export const FileUploadHeader = styled(InputLabelBase)(
  ({ theme: { config } }) => ({
    fontSize: config.textSizes.paragon,
  })
);

export const FileUploadSubHeader = styled(Header)(
  ({ theme: { palette, config } }) => ({
    fontSize: config.textSizes.normal,
    color: palette.text.text9,
    margin: '0.75rem 0 1.25rem 0',
  })
);

const SupportedFilesText = styled(FileUploadSubHeader)(
  ({ theme: { palette, config } }) => ({
    fontSize: config.textSizes.normal,
    color: palette.primary.main,
    margin: 0,
  })
);

export const useFileUploadFromOS = () => {
  const [fileDetails, setFileDetails] = React.useState();
  const [file, setFile] = React.useState();

  React.useEffect(() => {
    const sub = PubSub.subscribe(
      'withUploadFeatureForms:selectedFile',
      (eventData) => {
        if (eventData) {
          setFile(eventData);
          const splitString = eventData.name.split('.');
          const extension = splitString.pop();
          const fileName = splitString.join('.');
          setFileDetails({ extension, fileName });
        }
      }
    );
    return () => {
      sub.remove();
    };
  }, [file]);

  const handleUploadFile = React.useCallback((event) => {
    PubSub.publish('openFileUploadFeature', event);
  }, []);

  const handleResetFile = React.useCallback(() => {
    setFile(null);
    setFileDetails(null);
  }, []);

  const handleReUploadFile = React.useCallback(() => {
    handleResetFile();
    handleUploadFile();
  }, [handleResetFile, handleUploadFile]);

  return {
    fileDetails,
    file,
    handleResetFile,
    handleUploadFile,
    handleReUploadFile,
  };
};
