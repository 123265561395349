import React, { useEffect } from 'react';
// ui
// components
import { FormContext, useForm } from 'react-hook-form-4';
import { DialogContent, Grid as MuiGrid, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import _ from 'lodash';
import CBButton from '../../../components/Buttons/CbButton';
import { withFormController } from '../../../components/hocs/withFormController';
import { delayedEvent } from '../../../utils/eventUtils';
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import { getLeadOpportunityStatuses, updateLead } from '../sales.service';
import { MultiSelect } from '../../../components/Selects';
import { getCompetitors } from '../../../accounts/AccountService';
import { leadSchema } from '../../../components/globalValidations';

const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  winRate: leadSchema.percentToWin,
});

const competitorValuesFunc = (competitors) => {
  return competitors
    ? competitors.map((item) => ({
        label: item,
        value: item,
      }))
    : [];
};

const statusValuesFunc = (status, opportunitiesList) => {
  return opportunitiesList.filter((item) => item.value === status);
};

export const PercentWinCompetitorsOpportunity = ({ data, ...props }) => {
  const classes = useClasses();

  const [opportunitiesList, setOpportunitiesList] = React.useState(null);
  const [competitorsList, setCompetitorsList] = React.useState(null);
  const { id, winRate } = data;
  const [competitors, setCompetitors] = React.useState(data.competitors);
  const [status, setStatus] = React.useState(data.status);
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = { winRate };
  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    validationSchema: schema,
  });
  const {
    formState: { isSubmitting },
  } = methods;
  const competitorValues = competitorValuesFunc(data.competitors);
  const [statusValues, setStatusValues] = React.useState(null);

  useEffect(() => {
    Promise.all([getCompetitors({}), getLeadOpportunityStatuses({})])
      .then(([competitorResp, opportunityStatusResp, tamsResp, bdsResp]) => {
        const competitorsArr = _.map(competitorResp.data, (value) => ({
          label: value.name,
          value: value.name,
        }));
        competitorsArr.push({
          label: 'Other',
          value: 'Other',
        });

        // need to remove cowbell competitor option from API
        competitorsArr.shift();
        const opportunitiesArr = _.map(opportunityStatusResp.data, (value) => ({
          label: value.value,
          value: value.name,
        }));

        setOpportunitiesList(opportunitiesArr);
        setCompetitorsList(competitorsArr);
        setStatusValues(statusValuesFunc(data.status, opportunitiesArr));
      })
      .catch(() => {
        enqueueSnackbar('Unable to download data', { variant: 'error' });
      });
  }, [data.status, enqueueSnackbar]);

  const onSubmit = (formData) => {
    const payload = {
      winRate: formData.winRate,
      competitors: competitors ? competitors.map((item) => item.value) : [],
      status: status.value,
    };

    return updateLead({ id }, { accountId: data?.accountId, ...payload })
      .then(() => {
        enqueueSnackbar('Lead updated Successfully!', { variant: 'success' });
        delayedEvent('table-refetch', 4000, 'SalesInbox');
        props.close();
      })
      .catch(() => {
        enqueueSnackbar('Failed to update the lead', { variant: 'error' });
      });
  };

  const handleCancel = () => {
    props.close();
  };

  const handleSelect = React.useMemo(() => {
    return (type) => {
      return (name, selectedOption) => {
        if (type === 'competitors') {
          setCompetitors(selectedOption);
        } else if (type === 'status') {
          setStatus(selectedOption);
        }
      };
    };
    // eslint-disable-next-line
  }, []);

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent classes={{ root: classes.container }}>
          <MuiGrid container spacing={4}>
            <MuiGrid item md={6}>
              <TextField
                name="winRate"
                label="% to Win"
                type="number"
                required
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <MultiSelect
                label="Competitor(s)"
                buttonPlaceholder="--"
                isMulti
                options={competitorsList}
                onChange={handleSelect('competitors')}
                values={competitorValues}
                showErrorPlace={false}
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={4}>
            <MuiGrid item md={12}>
              <MultiSelect
                name="LeadStatus"
                buttonPlaceholder="--"
                options={opportunitiesList}
                onChange={handleSelect('status')}
                values={statusValues}
                showErrorPlace={false}
                isMulti={false}
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <DialogActions>
          <CBButton action={handleCancel} styleName="cancel">
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="OK"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  container: {
    paddingTop: '2rem !important',
    overflowY: 'visible',
    color: palette.primary.contrastText,
  },
}));

export const PercentWinCompetitorsOpportunityConfig = {
  PercentWinCompetitorsOpportunity: {
    component: PercentWinCompetitorsOpportunity,
    config: {
      minWidth: 'md',
      title: '% to Win / Competitor(s) / Opportunity Status',
      PaperProps: {
        style: { overflowY: 'visible' },
      },
    },
  },
};
