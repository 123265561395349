import { Box, styled, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const ChipListing = ({
  children,
  onDelete,
  chipList = [],
  ...props
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      flexWrap="wrap"
      {...props}
    >
      {typeof children === 'function'
        ? children({ chipList, onDelete, ...props })
        : chipList.map((chip) => (
            <Chip
              key={`chip-chip:${chip.id}`}
              chip={chip}
              onDelete={onDelete}
            />
          ))}
    </Box>
  );
};

const Chip = ({ chip, onDelete }) => {
  const handleDelete = () => onDelete(chip.id);
  return (
    <ChipContainer>
      <Typography variant="body2">{chip.value}</Typography>
      <IconButton onClick={handleDelete} size="small">
        <CloseIcon />
      </IconButton>
    </ChipContainer>
  );
};

const ChipContainer = styled(Box)(({ theme }) => ({
  padding: '0.2rem 0.3rem 0.2rem 0.8rem',
  margin: '0.25rem 0.5rem 0.25rem 0',
  borderRadius: 8,
  border: `1px solid ${theme.palette.primary.contrastText}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'fit-content',
}));
