import _ from 'lodash';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParams } from '../../../components/providers/QueryParamsProvider';
import type {
  AgencyDashboardView,
  AgencyDashboardListingTab,
  AgencyDashboardQueries,
  QueryParams,
  FilterOptionConfig,
} from './agency-dashboard.statics';
import {
  filterStatics,
  AGENCY_DASHBOARD_VIEWS,
  initialFilters,
} from './agency-dashboard.statics';
import {
  useAgencyDashboardSavedSettings,
  useAgencyDashboardAutosave,
} from './hooks/useAgencyDashboardSavedSettings';
import type { AccountDto } from '../../../types';

export const useAgencyDashboardStateOrchestrator = () => {
  const savedSettings = useAgencyDashboardSavedSettings();
  const history = useHistory();
  const location = useLocation();
  const { query } = useQueryParams();

  const [searchTerm, setSearchTerm] = React.useState(savedSettings.searchTerm);
  const [view, setView] = React.useState<AgencyDashboardView>(
    AGENCY_DASHBOARD_VIEWS.LISTING
  );
  const [currentTab, setCurrentTab] = React.useState<AgencyDashboardListingTab>(
    savedSettings.tab
  );
  const [activeFilters, setActiveFilters] = React.useState(
    savedSettings.filters
  );
  const currentTabFilters = activeFilters[currentTab];
  const filterOptions = filterStatics[currentTab];

  const [queryState, dispatch] = React.useReducer(
    queryReducer,
    savedSettings.queries
  );

  useAgencyDashboardAutosave({
    currentTab,
    queryState,
    activeFilters,
    searchTerm,
  });

  const activeQuery = queryState[`${currentTab}_QUERY`];

  const onUpdateQuery = (
    tab: AgencyDashboardListingTab,
    update: Partial<QueryParams>
  ) => {
    dispatch({
      type: QueryActionTypes[`UPDATE_${tab}_QUERY`],
      payload: update,
    });
  };

  const onSearchTermChange = (account: AccountDto) => {
    setSearchTerm(account.name);
    dispatch({
      type: QueryActionTypes.UPDATE_SEARCH_TERM,
      payload: account
        ? { search: account.name, accountId: account.id, page: 0 }
        : { page: 0 },
    });
    if (account) {
      setActiveFilters(initialFilters);
    }
  };

  const handleViewChange = (newView: AgencyDashboardView) => {
    setView(newView);
  };

  const handleListingTabChange = React.useCallback(
    (newListingTab: AgencyDashboardListingTab) => {
      onUpdateQuery(currentTab, { page: 0 });
      setCurrentTab(newListingTab);
    },
    [currentTab]
  );

  const onApplyFilter = (filter: FilterOptionConfig) => {
    setActiveFilters((prev: any) => ({
      ...prev,
      [currentTab]: {
        ...prev[currentTab],
        [filter.id]: filter.f,
      },
    }));
  };

  const onRemoveFilter = (filterId: string) => {
    setActiveFilters((prev: any) => ({
      ...prev,
      [currentTab]: _.omit(prev[currentTab], filterId),
    }));
  };

  const onClearFilters = () => {
    setActiveFilters((prev: any) => ({
      ...prev,
      [currentTab]: {},
    }));
  };

  React.useEffect(() => {
    if (query.view) {
      handleViewChange(query.view);
      history.replace(location.pathname);
    }
    if (query.tab) {
      handleListingTabChange(query.tab);
      history.replace(location.pathname);
    }
  }, [handleListingTabChange, history, location.pathname, query]);

  return {
    view,
    handleViewChange,

    currentTab,
    handleListingTabChange,

    activeQuery,
    queryState,
    searchTerm,
    onUpdateQuery,
    onSearchTermChange,

    activeFilters,
    filterOptions,
    currentTabFilters,
    onApplyFilter,
    onRemoveFilter,
    onClearFilters,
  };
};

export const QueryActionTypes = {
  UPDATE_QUOTES_QUERY: 'UPDATE_QUOTES_QUERY',
  UPDATE_BINDERS_QUERY: 'UPDATE_BINDERS_QUERY',
  UPDATE_POLICIES_QUERY: 'UPDATE_POLICIES_QUERY',
  UPDATE_ACCOUNTS_QUERY: 'UPDATE_ACCOUNTS_QUERY',
  UPDATE_SEARCH_TERM: 'UPDATE_SEARCH_TERM',
};

interface ReducerAction {
  type: (typeof QueryActionTypes)[keyof typeof QueryActionTypes];
  payload: Partial<QueryParams>;
}

export const queryReducer = (
  state: AgencyDashboardQueries,
  action: ReducerAction
): AgencyDashboardQueries => {
  switch (action.type) {
    case QueryActionTypes.UPDATE_QUOTES_QUERY:
      return {
        ...state,
        QUOTES_QUERY: {
          ...state.QUOTES_QUERY,
          ...action.payload,
        },
      };

    case QueryActionTypes.UPDATE_BINDERS_QUERY:
      return {
        ...state,
        BINDERS_QUERY: {
          ...state.BINDERS_QUERY,
          ...action.payload,
        },
      };

    case QueryActionTypes.UPDATE_POLICIES_QUERY:
      return {
        ...state,
        POLICIES_QUERY: {
          ...state.POLICIES_QUERY,
          ...action.payload,
        },
      };

    case QueryActionTypes.UPDATE_ACCOUNTS_QUERY:
      return {
        ...state,
        ACCOUNTS_QUERY: {
          ...state.ACCOUNTS_QUERY,
          ...action.payload,
        },
      };

    case QueryActionTypes.UPDATE_SEARCH_TERM:
      return Object.keys(state).reduce((acc, query) => {
        const targetQuery = state[query as keyof AgencyDashboardQueries];
        let payload = _.omit(targetQuery, ['search', 'accountId']);

        if (action.payload.search) {
          payload = { ...targetQuery, ...action.payload };
        }

        return { ...acc, [query]: payload };
      }, {}) as AgencyDashboardQueries;

    default:
      return state;
  }
};
