/* eslint-disable no-prototype-builtins */
import qs from 'qs';
import * as filterStatics from '../../../console/_statics/filter.statics.json';

const stringOperators = filterStatics.default.operators.string;

export function stringifyQuery(query, cb) {
  const nextQuery = query.reduce(
    (acc, { column, operator, value }) => {
      if (!column || !operator || !value) {
        return acc;
      }

      // encode value if column operator is a string-type operator
      const formattedValue = stringOperators.hasOwnProperty(operator)
        ? encodeURIComponent(value)
        : value;

      return {
        ...acc,
        f: {
          ...acc.f,
          [column]: {
            ...acc.f[column],
            [operator]: formattedValue,
          },
        },
      };
    },
    { f: {} }
  );

  if (typeof cb === 'function') return cb(nextQuery);

  return qs.stringify(nextQuery, { encode: false, arrayFormat: 'comma' });
}

export const flattenQuery = (query) => {
  const queryString = typeof query === 'string' ? query : stringifyQuery(query);
  return queryString.split('&').reduce((acc, param) => {
    const [key, value] = param.split('=');
    return { ...acc, [key]: value };
  }, {});
};
