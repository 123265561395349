import _ from 'lodash';
import Moment from 'moment';

import Coverages from './comparisons/statics/coverages.statics.json';
import Globals from './comparisons/statics/globals.static.json';
import { ProductTypes } from '../../../types';
import { formatCurrencyToTenthsPlaceWithUnit } from '../../../utils/currency.utils';
import { toUniversalUtcDate } from '../../../utils/date.utils';
import { PolicyStatus } from '../../_statics/policy.statics';

const limitEquivalentCoverages = [
  'securityBreachExpense',
  'securityBreachLiability',
  'restorationOfElectronicsData',
  'computerFraudEndorsement',
];
const percentLimit = ['extortionThreats', 'publicRelationsExpense'];

export const retroMappingFull = {
  1: '1 Year',
  2: '2 Years',
  3: 'Full Prior Acts', // P100
  '-1': 'Full Prior Acts', //  P250
};

export const retroMappingShort = {
  1: '1 Yr',
  2: '2 Yrs',
  3: 'Full', // P100
  '-1': 'Full', // P250
};

export function generateCoverageText(data) {
  const coveragesAdded = _.get(data, 'coveragesAdded', []);
  const coveragesRemoved = _.get(data, 'coveragesRemoved', []);
  const coveragesUpdated = _.get(data, 'coveragesUpdated', []);

  const added = _.get(coveragesAdded, 'length', 0);
  const removed = _.get(coveragesRemoved, 'length', 0);
  const updated = _.get(coveragesUpdated, 'length', 0);

  let coverageText = 'Updated';
  const isAdded = added > 0 && removed === 0 && updated === 0;
  const isRemoved = added === 0 && removed > 0 && updated === 0;
  const isNone = added === 0 && removed === 0 && updated === 0;

  if (isAdded) {
    coverageText = 'Added';
  } else if (isRemoved) {
    coverageText = 'Removed';
  } else if (isNone) {
    coverageText = 'None';
  }

  return coverageText;
}

export function generateInsuredInformationText(data) {
  const oldData = _.get(data, 'firmographicData', []);
  let coverageText = 'None';

  if (oldData.name !== data.companyName) {
    coverageText = 'Updated';
  }

  return coverageText;
}

function checkIsAfter(date1, date2) {
  return Moment(date1).isAfter(date2);
}

export function generatePolicyDurationText(data) {
  const oldEndDate = toUniversalUtcDate(data?.initialRequestData?.endDate);
  const newEndDate = toUniversalUtcDate(data?.expiresOn);

  let coverageText = 'None';

  const isUpdated = checkIsAfter(newEndDate, oldEndDate);

  if (isUpdated) {
    coverageText = 'Updated';
  }

  return coverageText;
}

export function getQuotePayload(
  latestPolicydata,
  originalPolicydata,
  agencyData,
  loggedInAgent,
  product,
  isCowbellJumpedIntoAgency
) {
  if (!latestPolicydata) return;

  const coverage = getCoverages(latestPolicydata, product);

  // pick up agent on account from broker of record object if there is BOR processed
  let agentOnAccount = _.pick(latestPolicydata, [
    'agentFirstName',
    'agentLastName',
    'agentEmail',
    'agentPhone',
  ]);

  if (
    originalPolicydata.isBOR &&
    !_.isEmpty(originalPolicydata.brokerOfRecord)
  ) {
    agentOnAccount = _.pick(originalPolicydata.brokerOfRecord, [
      'agentFirstName',
      'agentLastName',
      'agentEmail',
      'agentPhone',
    ]);
  }
  const agent = getAgent(
    agentOnAccount,
    agencyData,
    loggedInAgent,
    isCowbellJumpedIntoAgency
  );

  const payload = {
    ...coverage,
    ...agent,
    teamIds:
      originalPolicydata.isBOR && !_.isEmpty(originalPolicydata.brokerOfRecord)
        ? [originalPolicydata.brokerOfRecord?.teamId ?? '']
        : originalPolicydata.teamIds ?? [],
  };

  return payload;
}

export function getCoverages(latestPolicydata, product) {
  let coverage;
  if (product === ProductTypes.p100) {
    coverage = {
      ..._.pick(latestPolicydata, [
        'limit',
        'businessIncomeCoverage',
        'sublimit',
        'ransomPaymentEndorsement',
        'ransomPaymentLimit',
        'hardwareReplCostEndorsement',
        'hardwareReplCostSubLimit',
        'telecomsFraudEndorsement',
        'telecomsFraudSubLimit',
        'postBreachRemediationEndorsement',
        'postBreachRemediationSubLimit',
        'computerFraudEndorsement',
        'websiteMediaContentLiabilitySubLimit',
        'socialEngLimit',
        'socialEngDeductible',
        'socialEngEndorsement',
        'deductible',
        'waitingPeriod',
        'retroactivePeriod',
      ]),
      websiteMediaContentLiabilityEndorsement: _.get(
        latestPolicydata,
        'websiteMediaContentLiabilityEndorsement',
        false
      ),
    };
  }

  if (product === ProductTypes.p250) {
    coverage = {
      ..._.pick(latestPolicydata.initialRequestData, [
        'limit',
        'deductible',
        'coverages',
      ]),
    };
  }

  let effectiveDate = Moment().startOf('day').format('YYYY-MM-DD'); // endorsement effective date
  if (latestPolicydata.status == PolicyStatus.EXPIRED_STATUS) {
    effectiveDate = Moment.utc(latestPolicydata.endDate).format('YYYY-MM-DD');
  }

  return {
    ...getCommonPolicyDetails(latestPolicydata),
    coverages: { ...coverage },
    effectiveDate,
  };
}

function getAgent(
  agentOnAccount,
  agencyData,
  loggedInAgent,
  isCowbellJumpedIntoAgency
) {
  let agentDetails = _.pick(loggedInAgent, [
    'firstName',
    'lastName',
    'email',
    'phone',
  ]);

  if (isCowbellJumpedIntoAgency) {
    if (_.isUndefined(agentOnAccount.agentEmail)) {
      if (!_.isUndefined(agencyData.contactEmail)) {
        agentDetails = {
          firstName: agencyData.contactFirstName,
          lastName: agencyData.contactLastName,
          email: agencyData.contactEmail,
          phone: agencyData.contactPhone,
        };
      }
    } else {
      agentDetails = {
        firstName: agentOnAccount.agentFirstName,
        lastName: agentOnAccount.agentLastName,
        email: agentOnAccount.agentEmail,
        phone: agentOnAccount.agentPhone,
      };
    }
  }

  return agentDetails;
}

function getCommonPolicyDetails(latestPolicydata) {
  return {
    ..._.pick(latestPolicydata, [
      'premium',
      'totalPremium',
      'brokerFee',
      'accountId',
      'mgaFee',
    ]),
    policyId: latestPolicydata.id,
    endDate: Moment.utc(_.get(latestPolicydata, 'endDate')).format(
      'YYYY-MM-DD'
    ),
    additionalInsureds: latestPolicydata?.additionalInsured ?? [],
    insuredInformation: {
      name: latestPolicydata.companyName,
      ..._.pick(latestPolicydata.firmographicData, [
        'address1',
        'address2',
        'city',
        'state',
        'zipCode',
      ]),
    },
  };
}

export function getPolicyCoverages(data) {
  const coverage = {
    ..._.pick(data?.coverages, [
      'businessIncomeCoverage',
      'sublimit',
      'ransomPaymentEndorsement',
      'ransomPaymentLimit',
      'hardwareReplCostEndorsement',
      'hardwareReplCostSubLimit',
      'telecomsFraudEndorsement',
      'telecomsFraudSubLimit',
      'postBreachRemediationEndorsement',
      'postBreachRemediationSubLimit',
      'computerFraudEndorsement',
      'websiteMediaContentLiabilitySubLimit',
      'socialEngLimit',
      'socialEngDeductible',
      'socialEngEndorsement',
      'limit',
      'deductible',
      'waitingPeriod',
      'premium',
      'totalPremium',
      'brokerFee',
      'accountId',
      'teamIds',
    ]),
    websiteMediaContentLiabilityEndorsement: _.get(
      data,
      'websiteMediaContentLiabilityEndorsement',
      false
    ),
    retroActivePeriod: _.get(
      data,
      'retroactivePeriod',
      _.get(data, 'coverages.retroactivePeriod', '-')
    ),
    policyId: data?.id,
    endDate: (data?.endDate
      ? Moment.utc(data.endDate)
      : Moment.utc().add(1, 'years')
    ).format('YYYY-MM-DD'),
  };

  return coverage;
}

export function getCoverageFormat(data) {
  const { limit, additionalInsureds } = data;

  const mappedCoverages = Coverages.map((coverage) => {
    const coverageKey = data[coverage.covLabel];
    const subLimit = data[coverage.sublimit];
    const subDeductible = data[coverage.subDeductible];

    let finalLimit = subLimit;
    if (limitEquivalentCoverages.includes(coverage.covLabel)) {
      finalLimit = limit;
    } else if (percentLimit.includes(coverage.covLabel)) {
      finalLimit = 0.05 * limit;
    } else if (!coverageKey) {
      finalLimit = '-';
    }

    if (coverage.endorsement) {
      if (coverage.key === 'ADDITIONAL_INSURED') {
        return {
          ...coverage,
          selected: !_.isEmpty(additionalInsureds),
        };
      }
    }

    return {
      ...coverage,
      selected: coverage.selected ? coverage.selected : coverageKey,
      limit:
        typeof finalLimit === 'number'
          ? formatCurrencyToTenthsPlaceWithUnit(finalLimit)
          : '-',
      deductible:
        coverageKey && subDeductible
          ? formatCurrencyToTenthsPlaceWithUnit(subDeductible)
          : '-',
      waitingPeriod: data.waitingPeriod,
      retroActivePeriod: data.retroactivePeriod,
    };
  });

  return getGlobalCoveragesFormat(data, mappedCoverages);
}

export function getGlobalCoveragesFormat(data, mappedCoverages) {
  const mapCoverages = Globals.map((coverage) => {
    const coverageKey = data[coverage.covLabel];

    let formattedValue =
      typeof coverageKey === 'number'
        ? formatCurrencyToTenthsPlaceWithUnit(coverageKey)
        : '-';
    if (coverage.covLabel === 'waitingPeriod') {
      formattedValue = `${_.get(data, 'waitingPeriod')} Hours`;
    }
    if (coverage.covLabel === 'retroactivePeriod') {
      formattedValue = retroMappingFull[coverageKey];
    }

    return {
      ...coverage,
      value: coverageKey,
      formattedValue,
    };
  });

  return mappedCoverages.concat(mapCoverages);
}

export function getKeysForUpdatedCoverages(data1, data2) {
  const finalCoverages = [];
  data2.map((coverage) => {
    const { limit, deductible, selected, _id, covLabel, groupId, value } =
      coverage;
    const ID = _.findKey(data1, ['_id', _id]);

    if (groupId === '0') {
      const previousValue = data1[ID].value;

      if (previousValue !== value) {
        finalCoverages.push(covLabel);
      }
    } else {
      const previousSelected = data1[ID].selected;
      const previousLimit = data1[ID].limit;
      const previousDeductible = data1[ID].deductible;

      const currentSelected = _.isUndefined(selected) ? false : selected;

      if (previousSelected !== currentSelected) {
        finalCoverages.push(covLabel);
      } else if (previousSelected === currentSelected) {
        if (previousLimit !== limit || previousDeductible !== deductible) {
          finalCoverages.push(covLabel);
        }
      }
    }

    return null;
  });
  return finalCoverages;
}

export function getDiffCoverages(summedUpKeys, coverages) {
  if (_.isEmpty(coverages)) {
    return [];
  }
  const mapped = summedUpKeys.map((covLabel) => {
    const ordinal = _.findKey(coverages, ['covLabel', covLabel]);

    const coverage = coverages[ordinal];
    return coverage;
  });

  return mapped;
}

export function getDiffCoveragesSummary(summedUpKeys, coverages) {
  return summedUpKeys.map((covLabel) => {
    return Object.values(coverages).find(
      (endorsementCov) =>
        endorsementCov.covLabel.toLowerCase() == covLabel.toLowerCase()
    );
  });
}

export function getConsolidatesKeys(data) {
  const coveragesAdded = data?.coveragesAdded ?? [];
  const coveragesRemoved = data?.coveragesRemoved ?? [];
  const coveragesUpdated = data?.coveragesUpdated ?? [];

  return [...coveragesAdded, ...coveragesRemoved, ...coveragesUpdated];
}

export function generateAIText(data) {
  const coveragesAdded = data?.coveragesAdded ?? [];
  const coveragesRemoved = data?.coveragesRemoved ?? [];
  let text = 'None';

  if (coveragesAdded.includes('additionalInsureds')) {
    text = 'Added';
  } else if (coveragesRemoved.includes('additionalInsureds')) {
    text = 'Removed';
  }

  return text;
}

export function getAddressInfo(policy) {
  return _.pick(policy, ['address1', 'address2', 'city', 'state', 'zipCode']);
}
