import _ from 'lodash';

export const es6PaginationAdaptor = ({ newPage }) => ({ page: newPage });

export const es7PaginationAdaptor = ({
  newPage,
  nextToken,
  prevToken,
  query,
}) => {
  const currentPage = _.get(query, 'page', 0);
  const pageRequested = newPage > currentPage ? 'NEXT' : 'PREV';
  const searchAfter = newPage > Number(currentPage) ? nextToken : prevToken;

  return { page: newPage, searchAfter, pageRequested };
};
