import React from 'react';
import classnames from 'classnames';
// mui
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { CustomCheckbox } from '../../../../components/inputs';

export const LabelLineItem = ({ coverage, diffs = [], ...props }) => {
  const classes = useLabelStyles();
  const checkboxClasses = classnames({
    [classes.mandatory]: coverage.mandatory,
    [classes.selected]: !coverage.mandatory && coverage.selected,
    [classes.diff]:
      diffs.includes(coverage.covLabel) &&
      !coverage.mandatory &&
      !coverage.global,
  });

  return (
    <Grid className={`${props.className} ${classes.label}`}>
      <span className={checkboxClasses}>{coverage.name}</span>
    </Grid>
  );
};

export const CoverageLineItem = ({ coverage, diffs = [] }) => {
  const classes = useTextStyles();
  const checkboxStyles = useCheckboxStyles();

  const checkboxClasses = classnames({
    mandatory: coverage.mandatory,
    [classes.normalText]: !diffs.includes(coverage.covLabel),
    [classes.diffText]: diffs.includes(coverage.covLabel),
  });

  return (
    <Grid container className={checkboxClasses}>
      <Grid item md={2} style={{ paddingLeft: 'calc(1rem + 18px)' }}>
        <CustomCheckbox
          className={checkboxClasses}
          classes={checkboxStyles}
          name={coverage._id}
          variant={!coverage.mandatory ? 'base' : 'filled'}
          checked={coverage.mandatory || coverage.selected}
          disabled
        />
      </Grid>
      <Grid
        item
        md={4}
        className={`${classes.verticalCenter} ${classes.textRight}`}
      >
        <span>{coverage.limit}</span>
      </Grid>

      <Grid
        item
        md={2}
        className={`${classes.verticalCenter} ${classes.textRight}`}
      >
        <span>{coverage.deductible}</span>
      </Grid>

      <Grid
        item
        md={2}
        className={`${classes.verticalCenter} ${classes.textCenter}`}
      >
        <span>{coverage.waitingPeriod}</span>
      </Grid>

      <Grid
        item
        md={2}
        className={`${classes.verticalCenter} ${classes.textCenter}`}
      >
        <span>{coverage.retroactivePeriod}</span>
      </Grid>
    </Grid>
  );
};

export const GlobalLineItem = ({ coverage, diffs = [] }) => {
  const classes = useTextStyles();

  const checkboxClasses = classnames({
    mandatory: coverage.mandatory,
    [classes.normalText]: !diffs.includes(coverage.covLabel),
    [classes.diffText]: diffs.includes(coverage.covLabel),
  });

  return (
    <Grid container className={checkboxClasses}>
      <Grid
        item
        md={12}
        className={`${classes.verticalCenter} ${classes.textCenter}`}
      >
        <span>{coverage.formattedValue}</span>
      </Grid>
    </Grid>
  );
};

export const EndorsementLineItem = ({ coverage, diffs = [] }) => {
  const classes = useTextStyles();
  const checkboxStyles = useCheckboxStyles();

  const checkboxClasses = classnames({
    mandatory: coverage.mandatory,
    [classes.normalText]: !diffs.includes(coverage.covLabel),
    [classes.diffText]: diffs.includes(coverage.covLabel),
  });

  return (
    <Grid container className={checkboxClasses}>
      <Grid
        item
        md={12}
        className={`${classes.verticalCenter} ${classes.textCenter}`}
      >
        <CustomCheckbox
          className={checkboxClasses}
          classes={checkboxStyles}
          name={coverage._id}
          variant={!coverage.mandatory ? 'base' : 'filled'}
          checked={coverage.mandatory || coverage.selected}
          disabled
        />
      </Grid>
    </Grid>
  );
};

const useCheckboxStyles = makeStyles(({ palette }) => ({
  root: {
    paddingTop: '0.4rem !important',
    color: palette.text.primary,
  },

  checked: {
    color: `${palette.common.cowbellGreen2} !important`,

    '& + span': {
      color: `${palette.common.cowbellGreen2} !important`,
    },

    '&.mandatory': {
      color: `${palette.common.cowbellBlue} !important`,
    },

    '&.mandatory + span': {
      color: `${palette.common.cowbellBlue} !important`,
    },
  },
}));

const useTextStyles = makeStyles(({ config, palette }) => ({
  normalText: {
    height: '2.16rem',
    color: palette.icon.secondary,
  },
  diffText: {
    height: '2.16rem',
    color: config.colors.yellow,
  },
  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  textRight: {
    justifyContent: 'flex-end',
    paddingRight: 'calc(1rem + 18px)',
  },
  textCenter: {
    justifyContent: 'center',
  },
}));

const useLabelStyles = makeStyles(({ config, palette }) => {
  return {
    label: {
      paddingRight: '5rem',
      height: '2.16rem',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      color: palette.icon.secondary,
    },
    mandatory: {
      color: config.colors.cowbellBlue,
    },
    selected: {
      color: config.colors.cowbellGreen2,
    },
    diff: {
      color: config.colors.yellow,
    },
  };
});
