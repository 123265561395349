import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { DialogContent, DialogActions, Grid as MuiGrid } from '@mui/material';
import { useSnackbar } from 'notistack';
import CbButton from '../../components/Buttons/CbButton';
import { MultiSelect } from '../../components/Selects';
import { withFormController } from '../../components/hocs/forms';
import {
  createVendor,
  getVendorSubcategories,
  updateVendor,
} from '../../api/vendor.api';
import { delayedEvent } from '../../utils/eventUtils';

import '../../console/_global/lib/validations/special-chars.schema.js';
import LanguageForm, {
  useLanguageFormState,
} from '../../i18n/forms/LanguageForm';
import { useGetPlatformRegion } from '../../utils';
import { FormLanguageProvider } from '../../i18n/forms/FormLanguageProvider';
import TextFieldBase from '../../components/inputs/text-fields/base/TextFieldBase';
import { withShowable } from '../../console/_global/lib/withShowable';

const TextField = withFormController(TextFieldBase);
const Grid = withShowable(MuiGrid);

const deriveSchema = (languageFormSchema) =>
  Yup.object().shape({
    name: Yup.string()
      .notAllSpecialChars()
      .trim()
      .required()
      .label('Vendor Name'),
    paymentTerms: Yup.number()
      .required()
      .min(0)
      .max(1999999999, 'Payment term too high. Please try a lower number')
      .label('Payment Term'),
    ...languageFormSchema,
  });

const AddEditVendorV2 = ({ data: vendor = {}, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [selection, setSelection] = React.useState([]);
  const [selectionError, setError] = React.useState(null);
  const [disableSubmit, setDisableSubmit] = React.useState(true);
  const languageFormState = useLanguageFormState({
    options: { validatePhone: true },
  });

  const isEditing = !_.isEmpty(vendor);

  // set up edit mode
  const defaultValues = {
    ..._.omit(vendor, [
      'category',
      'id',
      'logoURL',
      'created',
      'modified',
      'country',
    ]),
    paymentTerms: vendor.paymentTerms || 30,
  };

  React.useEffect(() => {
    setSelection(mapStringsToOptions(_.get(vendor, 'subCategory')));
    // eslint-disable-next-line
  }, []);

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(deriveSchema(languageFormState.languageFormSchema)),
  });

  // fetch dropdown data
  const { data: subcategories } = useQuery(
    ['vendor-subcategories'],
    () =>
      getVendorSubcategories()
        .then((resp) => resp.data)
        .catch(() =>
          enqueueSnackbar(
            'Unable to fetch vendor subcategories, please try again',
            { variant: 'error' }
          )
        ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  // event handlers
  const onCancel = React.useCallback(() => props.close(), [props]);
  const onBack = React.useCallback(() => setPage(0), []);

  const handleSubcategories = React.useCallback(
    (event, value) => {
      if (isEditing) {
        setDisableSubmit(false);
      }
      if (selectionError) {
        setError(null);
      }
      setSelection(value);
    },
    [isEditing, selectionError]
  );

  // submit function
  const onSubmit = (formData) => {
    if (selection.length === 0) {
      setError('Please select at least one option.');
      return;
    }
    const payload = {
      ..._.omit(vendor, [
        'category',
        'id',
        'logoURL',
        'created',
        'modified',
        'country',
      ]),
      ...formData,
      subCategory: mapOptionsToStrings(selection),
    };

    const createOrUpdateVendor = () =>
      isEditing
        ? updateVendor(vendor.id, { data: payload })
        : createVendor({ data: payload });

    createOrUpdateVendor()
      .then(() =>
        enqueueSnackbar(
          `Vendor ${isEditing ? 'updated' : 'added'} successfully!`,
          { variant: 'success' }
        )
      )
      .catch((error) =>
        enqueueSnackbar(
          _.get(
            error,
            'response.data.message',
            'There was an issue with your request, please try again'
          ),
          {
            variant: 'error',
          }
        )
      );
    delayedEvent('table-refetch', 500, 'vendors');
    props.close();
  };

  return (
    <FormProvider {...methods}>
      <FormLanguageProvider language={languageFormState.language}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          {page === 0 ? (
            <Panel1
              onCancel={onCancel}
              subcategories={subcategories}
              handleSubCategories={handleSubcategories}
              selectionError={selectionError}
              selection={selection}
              isEditing={isEditing}
              methods={methods}
              disableSubmit={disableSubmit}
              gridLayoutSettings={languageFormState.gridLayoutSettings}
            />
          ) : (
            <Panel2 onBack={onBack} />
          )}
        </form>
      </FormLanguageProvider>
    </FormProvider>
  );
};

export const AddEditVendorV2Config = {
  AddEditVendorV2: {
    component: AddEditVendorV2,
    config: {
      fullWidth: true,
      title: 'Add/Edit a Vendor',
    },
  },
};

const Panel1 = ({
  onCancel,
  subcategories = [],
  handleSubCategories,
  selectionError,
  selection,
  isEditing,
  methods,
  disableSubmit,
  gridLayoutSettings,
}) => {
  return (
    <>
      <DialogContent style={{ padding: '2rem' }}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <TextField name="name" label="Vendor Name" required />
          </Grid>
          <Grid item md={6}>
            <MultiSelect
              name="subCategory"
              label="Expert Type/Subcategory"
              required
              values={selection}
              options={mapStringsToOptions(subcategories)}
              onChange={handleSubCategories}
              error={selectionError}
            />
          </Grid>
          <Grid item sm={6} show={gridLayoutSettings.showAddress1}>
            <LanguageForm.Address1 />
          </Grid>

          <Grid item sm={6} show={gridLayoutSettings.showAddress2}>
            <LanguageForm.Address2 />
          </Grid>

          <Grid item sm={12} show={gridLayoutSettings.showAddress3}>
            <LanguageForm.Address3 />
          </Grid>

          <Grid item sm={6} show={gridLayoutSettings.showCity}>
            <LanguageForm.City />
          </Grid>

          <Grid item sm={3} show={gridLayoutSettings.showState}>
            <LanguageForm.State />
          </Grid>

          <Grid
            item
            sm={gridLayoutSettings.showState ? 3 : 6}
            show={gridLayoutSettings.showPostCode}
          >
            <LanguageForm.PostalCode />
          </Grid>

          <Grid item md={6}>
            <LanguageForm.PhoneField label="Business Phone Number" required />
          </Grid>
          <Grid item md={6}>
            <TextField
              name="paymentTerms"
              label="Payment Term"
              type="number"
              inputProps={{ min: 0 }}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={onCancel}>
          Cancel
        </CbButton>

        {/* ENABLE NEXT BUTTON ONCE FILE UPLOAD IS READY */}
        {/* <CbButton styleName="ctaButton" onClick={onNext}>
          Next
        </CbButton> */}

        <CbButton
          styleName="ctaButton"
          type="submit"
          disabled={
            isEditing
              ? disableSubmit && !methods.formState.isDirty
              : !methods.formState.isDirty
          }
        >
          {isEditing ? 'Update' : 'Add'}
        </CbButton>
      </DialogActions>
    </>
  );
};

const Panel2 = ({ onBack, file }) => {
  return (
    <>
      <DialogContent>
        <h1>File Upload Feature Here</h1>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={onBack}>
          Back
        </CbButton>
        <CbButton styleName="ctaButton" type="submit">
          Skip
        </CbButton>
        <CbButton styleName="ctaButton" type="submit" disabled={!file}>
          Add
        </CbButton>
      </DialogActions>
    </>
  );
};

// helpers
const mapStringsToOptions = (options = []) =>
  options.map((option) => ({ label: option, value: option }));
const mapOptionsToStrings = (options = []) =>
  options.map((option) => _.get(option, 'value'));
