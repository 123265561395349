import { Grid } from '@mui/material';

import Color from 'color';
import type { DefaultTheme } from '@mui/styles';
import { styled } from '@mui/styles';
import Showable from '../../../../../../components/Showable';
import CoverageCheckbox from '../../customizeQuote/components/CoverageCheckbox';
import type { QuoteDetailsCoverage } from '../hooks/useGetQuotePageDetails';
import { retroActiveDateMap } from '../../utils/primeX.util';
import { toUniversalUtcDate } from '../../../../../../utils/date.utils';
import type { Languages } from '../../../../../../i18n/i18n.language-config';
import { deriveCurrencyFormat } from '../../../../../../i18n/currencies';

interface CoverageLineItemProps extends QuoteDetailsCoverage {
  isOdd: boolean;
  language: Languages;
}

const CoverageLineItem = ({
  isOdd,
  language,
  ...coverageData
}: CoverageLineItemProps) => {
  return (
    <GridContainer isOdd={isOdd} container>
      <Grid item md={4}>
        <Showable show={Boolean(coverageData.isMandatory)}>
          <CoverageCheckbox.Mandatory
            label={coverageData.name.split('_').join(' ')}
          />
        </Showable>

        <Showable show={!coverageData.isMandatory && coverageData.isSelected}>
          <CoverageCheckbox.Selected
            disabled
            label={coverageData.name.split('_').join(' ')}
          />
        </Showable>

        <Showable show={!coverageData.isSelected && coverageData.isAvailable}>
          <CoverageCheckbox.Available
            disabled
            label={coverageData.name.split('_').join(' ')}
          />
        </Showable>
      </Grid>

      <Grid item md={2} style={{ textAlign: 'center' }}>
        <span>
          {coverageData.limit
            ? deriveCurrencyFormat(language, coverageData.limit, {
                minimumFractionDigits: 0,
              })
            : '-'}
        </span>
      </Grid>
      <Grid item md={2} style={{ textAlign: 'center' }}>
        <span>
          {coverageData.deductible
            ? deriveCurrencyFormat(language, coverageData.deductible, {
                minimumFractionDigits: 0,
              })
            : '-'}
        </span>
      </Grid>
      <Grid item md={2} style={{ textAlign: 'center' }}>
        <span>
          {coverageData.waitingPeriod
            ? `${coverageData.waitingPeriod} hours`
            : 'None'}
        </span>
      </Grid>
      <Grid item md={2} style={{ textAlign: 'center' }}>
        <span>{getUiRetroData(coverageData)}</span>
      </Grid>
    </GridContainer>
  );
};

export default CoverageLineItem;

const getUiRetroData = (
  coverageData: Omit<CoverageLineItemProps, 'isOdd' | 'language'>
) => {
  if (coverageData.retroActiveDate) {
    return toUniversalUtcDate(coverageData.retroActiveDate);
  }

  if (coverageData.retroActivePeriod) {
    return retroActiveDateMap[
      coverageData.retroActivePeriod as keyof typeof retroActiveDateMap
    ];
  }

  return '-';
};

type GridContainerProps = { isOdd: boolean };
const GridContainer = styled(Grid)<DefaultTheme, GridContainerProps>(
  ({ theme, isOdd }) => {
    const oddRowBackground = Color(theme.palette.background.default)
      .lighten(theme.palette.mode === 'dark' ? 0.28 : 0.025)
      .toString();

    const evenRowBAckground =
      theme.palette.mode === 'light'
        ? theme.palette.background.modal
        : Color(theme.palette.background.modal).darken(0.15).toString();
    return {
      backgroundColor: isOdd ? oddRowBackground : evenRowBAckground,
      padding: '1rem',
    };
  }
);
