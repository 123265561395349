import _ from 'lodash';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// react
import React, { useState } from 'react';
import { compose } from 'redux';
import { useForm, FormContext } from 'react-hook-form-4';
// mui
import {
  DialogActions,
  Grid,
  DialogContent,
  FormHelperText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// components
import CBButton from '../../../../components/Buttons/CbButton';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import { withFormController } from '../../../../components/hocs/withFormController';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
// actions
import { updateBillingContact } from '../../../customers/_services/BillingService';
import { updateCommissionsContact } from '../../_services/commission.service';
// helpers
import { delayedEvent } from '../../../../utils/eventUtils';
import { Modal } from '../../../../components/modals/v2/helpers/v2.modal.helpers';

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  contactEmail: Yup.string().email().label('Email').required(),
  contactName: Yup.string().label('Contact Name ').required(),
});

export const UpdateBillingCommissionContact = compose(
  withStyles(({ palette, config }) => ({
    container: {
      color: palette.primary.contrastText,
    },
    label: {
      fontSize: config.textSizes.primer,
      fontWeight: config.weights.bold,
    },
    locationName: {
      fontSize: config.textSizes.tertia,
      fontWeight: 'normal',
    },
  }))
)(({ classes, data, ...props }) => {
  const [backendError, setBackendError] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    contactEmail: data.contactEmail || '',
    contactName: data.contactName || '',
    muteCommunications: data.status === 'Mute',
  };
  // validationSchema
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { isSubmitting },
    ...methods
  } = useForm({
    defaultValues,
    validationSchema: schema,
  });

  watch('muteCommunications');

  const values = getValues();

  const handleMuteCommunications = (event) => {
    setValue('muteCommunications', event.target.checked);
  };

  const onSubmit = (formData) => {
    const payload = {
      id: data.id,
      agencyId: data.agencyId,
      ...formData,
    };

    if (data.isBill) {
      return updateBillingContact({}, payload)
        .then(() => {
          enqueueSnackbar('Billing Contact was updated Succesfully', {
            variant: 'success',
          });
          makeRefreshCall();
          Modal.hide();
        })
        .catch((error) => {
          setBackendError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Not able to update contact at this time. Please try again later.'
              )
            )
          );
        });
    }
    return updateCommissionsContact({}, payload)
      .then(() => {
        enqueueSnackbar('Commissions Contact was updated Succesfully', {
          variant: 'success',
        });
        makeRefreshCallCommissions();
        Modal.hide();
      })
      .catch((error) => {
        setBackendError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Not able to update contact at this time. Please try again later.'
            )
          )
        );
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2.5rem 5rem', overflow: 'visible' }}>
          <Grid
            container
            direction="column"
            spacing={1}
            className={classes.container}
            style={{ paddingBottom: '1rem' }}
          >
            <Grid item md={6} className={classes.label}>
              Location
            </Grid>
            <Grid item md={6} className={classes.locationName}>
              {data.locationName}
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <TextField
                label="Contact or Alias Name"
                name="contactName"
                required
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField label="Email" name="contactEmail" required fullWidth />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="column"
            className={classes.container}
          >
            <Grid item md={6} className={classes.label}>
              Mute
            </Grid>
            <Grid item md={6}>
              <CheckBoxBase
                label="Deactive communications"
                onChange={handleMuteCommunications}
                name="muteCommunications"
                ref={register({ name: 'muteCommunications' })}
                checked={values.muteCommunications}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <FormHelperText className="api-text-error">
          {backendError}
        </FormHelperText>
        <DialogActions>
          <CBButton
            onClick={props.close}
            styleName="cancel"
            buttonText="Cancel"
          />
          <CBButton
            type="submit"
            styleName="ctaButton"
            buttonText="Update"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
});

export const UpdateBillingCommissionContactConfig = {
  UpdateBillingCommissionContact: {
    component: UpdateBillingCommissionContact,
    config: {
      fullWidth: true,
      maxWidth: 'md',
      PaperProps: { style: { overflow: 'visible' } },
    },
  },
};

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, 'billingContact');
}

function makeRefreshCallCommissions() {
  delayedEvent('table-refetch', 500, 'commissionContacts');
}
