import { Box, Button } from '@mui/material';

import { Show } from '../../../../../../components/Show';
import { ReferToUWModalConfig } from './ReferToUWModal';

import { Modal } from '../../../../../../components/modals/v2/helpers/v2.modal.helpers';
import type { ProductType } from '../../../../../../types';
import { ProductTypes } from '../../../../../../types';

type ReferToUWButtonProps = {
  quoteId: string;
  subjectivityId: string;
  productType: ProductType;
};

function ReferToUWButton({
  quoteId,
  subjectivityId,
  productType,
}: ReferToUWButtonProps) {
  function handleClick() {
    Modal.show(ReferToUWModalConfig, { data: { quoteId, subjectivityId } });
  }

  return (
    <Show when={productType == ProductTypes.p250}>
      <Box style={{ display: 'inline-block', width: '12.5rem' }}>
        <Button
          onClick={handleClick}
          size="medium"
          variant="outlined"
          style={{ borderColor: '#f2c142' }}
          color="primary"
          fullWidth
        >
          Refer to UW
        </Button>
      </Box>
    </Show>
  );
}

export default ReferToUWButton;
