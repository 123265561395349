import _ from 'lodash';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormContext, useForm } from 'react-hook-form-4';
import { compose } from 'redux';
// mui
import {
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// components
import { useSnackbar } from 'notistack';
import { RegisteredAgencyAutoComplete } from '../../../../accounts/admin-account-flow/AgencyAutoComplete';
import { withFormController } from '../../../../components/hocs/withFormController';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import { InputLabelBase } from '../../../../components/inputs/InputLabelBase';
import withRouter from '../../../../components/hocs/withRouter';
// platform helpers
import { delayedEvent } from '../../../../utils/eventUtils';
import { UsStates } from '../../../../utils/USState';
import CBButton from '../../../../components/Buttons/CbButton';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import { withShowable } from '../../../_global/lib/withShowable';
// services
import { addLicense, updateLicense } from '../Modals/surplusServices';
import { manageAPIError } from '../../../../utils';
import { useActor } from '../../../../components/hooks/useActor';

const TextField = withFormController(TextFieldBase);

// Showable
const MuiCheckBox = withShowable(CheckBoxBase);
const MuiGrid = withShowable(Grid);

export const presetsOptions = [
  {
    label: 'Popular',
    value: 'POPULAR',
  },
  {
    label: 'Elite',
    value: 'ELITE',
  },
  {
    label: 'Basic',
    value: 'BASIC',
  },
];

const schema = Yup.object().shape({
  agency: Yup.object().shape({
    label: Yup.string().label('Agency'),
    value: Yup.string().label('Agency'),
  }),
  brokerFirstName: Yup.string()
    .required('Brokers First Name is required.')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  brokerLastName: Yup.string()
    .required('Brokers Last Name is required.')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  brokerEmail: Yup.string()
    .email('Please enter a valid Email Address')
    .required('Please enter Email Address'),
  surplusLicenseNumber: Yup.string()
    .required('Broker License Number is required')
    .matches(
      /^[0-9aA-zZ\s]+$/,
      'Only alphabets and numbers are allowed for this field '
    ),
  surplusLinesState: Yup.string().required('Broker License State is required'),
});

export const AddSurplusLicenses = compose(
  withRouter,
  withStyles(({ config, palette }) => ({
    optionsBox: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 12,
      marginBottom: -40,
      display: 'flex',
    },
    select_css: {
      '& label': {
        paddingTop: '.3rem',
      },
    },
    colorContrast: {
      color: palette.primary.contrastText,
    },
    label: {
      padding: '0.5rem',
      fontSize: config.textSizes.normal,
    },
  }))
)(({ classes, close, data, location }) => {
  const [backendError, setBackendError] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const actor = useActor();
  const editMode = !_.isEmpty(data);
  const route = location.pathname.split('/')[1];

  const defaultValues = {
    agency: { label: data.agencyName, value: data.agencyId } || '',
    surplusLinesState: data.surplusLinesState || '',
    brokerFirstName: data.brokerFirstName || '',
    brokerLastName: data.brokerLastName || '',
    brokerEmail: data.brokerEmail || '',
    surplusLicenseNumber: data.surplusLicenseNumber || '',
    signatureOnFile: data.signatureOnFile,
  };

  const {
    handleSubmit,
    getValues,
    register,
    formState: { isSubmitting },
    setValue,
    watch,
    ...methods
  } = useForm({
    validationSchema: schema,
    defaultValues,
  });

  watch(['signatureOnFile', 'surplusLinesState', 'agency']);

  function onSubmit({ agency, ...formValues }) {
    const payload = {
      agencyId: route === 'agency' ? actor.account.id : agency.value,
      ...formValues,
    };

    if (editMode) {
      updateLicense({ id: data.id }, payload)
        .then(() => {
          enqueueSnackbar('Surplus License updated succesfully!', {
            variant: 'success',
          });
          close();
          delayedEvent('table-refetch', 200, 'surplusLicense');
        })
        .catch((error) => {
          const errorMessage = manageAPIError(
            error,
            'Failed to edit license. Please try again!'
          );

          setBackendError(errorMessage);
        });
    } else {
      addLicense({}, payload)
        .then(() => {
          enqueueSnackbar('Surplus License added succesfully!', {
            variant: 'success',
          });
          close();
          delayedEvent('table-refetch', 500, 'surplusLicense');
        })
        .catch((error) => {
          const errorMessage = manageAPIError(
            error,
            'Failed to add license. Please try again!'
          );

          setBackendError(errorMessage);
        });
    }
  }

  const handleCheckboxChange = (event) => {
    setValue(event.target.name, event.target.checked);
  };

  const handleChange = (event) => {
    setValue(event.target.name, event.target.value);
  };

  const renderOptions = (option) => {
    const currOption =
      typeof option === 'string' ? { label: option, value: option } : option;

    return (
      <MuiMenuItem key={currOption.label} value={currOption.value}>
        {currOption.label}
      </MuiMenuItem>
    );
  };

  const values = getValues();

  return (
    <FormContext {...methods} register={register} setValue={setValue}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <MuiGrid style={{ paddingBottom: '1.5rem' }} show={route === 'admin'}>
            <RegisteredAgencyAutoComplete
              name="agency"
              value={values.agency}
              placeholder="select agency"
              error={
                _.get(methods.errors, 'agency.type') === 'typeError'
                  ? 'The selected agency is invalid.'
                  : _.get(methods.errors, 'agency.label.message') ||
                    _.get(methods.errors, 'agency.value.message')
              }
              required
            />
          </MuiGrid>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <TextField
                label="Broker First Name"
                name="brokerFirstName"
                placeholder="Please enter surplus lines broker first name"
                required
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Broker Last Name"
                name="brokerLastName"
                placeholder="Please enter surplus lines broker last name"
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item lg={2}>
              <InputLabelBase required className={classes.label}>
                State
              </InputLabelBase>
              <MuiSelect
                variant="standard"
                name="surplusLinesState"
                required
                onChange={handleChange}
                value={values.surplusLinesState}
                classes={{
                  select: classes.select,
                  icon: classes.selectIcon,
                }}
                ref={register({ name: 'surplusLinesState' })}
                inputProps={{ placeholder: 'Please enter state' }}
                error={_.get(methods, 'errors.surplusLinesState.message')}
              >
                {UsStates.map(renderOptions)}
              </MuiSelect>
              {!values.surplusLinesState && (
                <FormHelperText style={{ color: '#fa8072', marginTop: 0 }}>
                  {_.get(methods, 'errors.surplusLinesState.message')}
                </FormHelperText>
              )}
            </Grid>
            <Grid item lg={4}>
              <TextField
                label="Broker License Number"
                name="surplusLicenseNumber"
                placeholder="Please enter surplus license number"
                required
                fullWidth
              />
            </Grid>

            <Grid item md={6}>
              <TextField
                label="Broker Email"
                name="brokerEmail"
                required
                placeholder="Please enter surplus lines broker email"
                fullWidth
              />
            </Grid>
          </Grid>

          <div style={{ textAlign: 'center' }}>
            <MuiCheckBox
              label="Signature On File"
              checked={values.signatureOnFile}
              onChange={handleCheckboxChange}
              name="signatureOnFile"
              ref={register({ name: 'signatureOnFile' })}
              show={
                route === 'admin' &&
                (values.surplusLinesState == 'LA' ||
                  values.surplusLinesState == 'MT')
              }
            />
          </div>

          <FormHelperText className="api-text-error">
            {backendError}
          </FormHelperText>
        </DialogContent>

        <DialogActions>
          <CBButton styleName="cancel" onClick={close} buttonText="Cancel" />

          <CBButton
            type="submit"
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText={editMode ? 'Update License' : 'Add License'}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
});

export default AddSurplusLicenses;
