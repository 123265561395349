import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// mui
import { Box as MuiBox, styled } from '@mui/material';

// components
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import CheckBoxLabel from '../CheckBoxLabel';
import CbButton from '../../../../components/Buttons/CbButton';
import DocumentUploadBase from './shared/DocumentUploadBase';
import { ScreenTitle } from './shared/ScreenTitle';
import { TextAreaBase } from '../../../../components/inputs/TextAreaBase';
import { InputLabelBase } from '../../../../components/inputs/InputLabelBase';

// utils
import useGetSubjectivityProgress from './shared/useGetSubjectivityProgress';
import useUploadZone from '../../../../components/fileUploader/v2/hooks/useUploadzone';
import useDocUpload from './shared/useDocUpload';
import useCompleteQuoteSubjectivity from './shared/useCompleteQuoteSubjectivity';
import { withFormController } from '../../../../components/hocs/forms';
import { determineIfStepIsEditable } from '../utils/bindQuoteWorkFlowUtils';
import useGetSubjectivityDocs from './shared/useGetSubjectivityDocs';
import { withShowable } from '../../../_global/lib/withShowable';
import { SubjectivityCompletionDate as SubjectivityCompletionDateBase } from './shared/SubjectivityCompletionDate';
import { POST_ISSUANCE, PRE_ISSUANCE } from '../constants/constants';
import CowbellReferToUWButton from './shared/referral/CowbellReferToUWButton';
import { Show } from '../../../../components/Show';

const Box = withShowable(MuiBox);
const SubjectivityCompletionDate = withShowable(SubjectivityCompletionDateBase);

const ManuscriptedSubjectivity = ({
  subjectivityMetaData,
  quoteDetails,
  quoteProgress,
  step,
  completeStep,
  goForward,
  goBack,
  incompletePreBindSteps,
  jumpTo,
  sortedPreBindSteps: preBindSteps,
  sortedPreIssueSteps: preIssueSteps = [],
}) => {
  const {
    subjectivityTitle,
    subjectivitySubType,
    quoteSubjectivityId,
    completed: isStepCompleted,
    subjectivityKey,
  } = subjectivityMetaData;

  const isStepEditable = determineIfStepIsEditable({
    subjectivitySubType,
    status: quoteProgress.quoteAgencyStatus,
    isAPI: quoteProgress.isAPI,
    productType: quoteDetails.uiData.productType,
  });

  const [isSubjectivityAcknowledged, setIsSubjectivityAcknowledged] =
    React.useState(isStepCompleted);

  const { handleSubmit, ...methods } = useForm();

  const {
    data: subjectivityData = {},
    isLoading: subjectivityDataLoading,
    isFetching: isFetchingSubjectivityData,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId,
    quoteId: quoteDetails.id,
    productType: quoteDetails.uiData.productType,
  });

  const {
    data: uploadedDocuments,
    isInitialLoading: uploadedDocsLoading,
    isFetching: isFetchingUploadedDocs,
  } = useGetSubjectivityDocs({
    quoteSubjectivityId,
    accountId: quoteDetails.uiData?.accountId,
    uploadedDocs: subjectivityData?.data?.uploadedDocuments,
    isFetchingSubjectivityData,
  });

  const { uploadMultipleDocuments, isUploadingDoc } = useDocUpload({
    quoteDetails,
  });

  const { files, ...uploadZoneParams } = useUploadZone({
    docType: 'Manuscript_Subjectivity_Information',
    maxFiles: 4,
    multiple: true,
  });

  React.useEffect(() => {
    methods.setValue('paragraph', subjectivityData?.data?.paragraph);
    // eslint-disable-next-line
  }, [subjectivityData]);

  React.useEffect(() => {
    if (files) {
      setIsSubjectivityAcknowledged(false);
    }
  }, [files]);

  const { completeSubjectivity, isCompletingStep } =
    useCompleteQuoteSubjectivity({
      quoteId: quoteDetails.uiData.quoteId,
      productType: quoteDetails.uiData.productType,
    });

  const handleCheckboxChange = () => {
    setIsSubjectivityAcknowledged((prevState) => !prevState);
  };

  const onSubmit = (formValues) => {
    if (!files && formValues.paragraph) {
      return completeManuscriptedSubjectivity(formValues);
    }

    if (!files) {
      return goForward();
    }

    const uploadDocumentParams = {
      formValues,
      files,
      quoteDetails,
      completeStep,
      goForward,
      step,
      docTypes: Array(files.length).fill('Manuscript_Subjectivity_Information'),
      quoteSubjectivityId,
      paragraph: formValues.paragraph,
      userAttested: isSubjectivityAcknowledged,
    };

    uploadMultipleDocuments(uploadDocumentParams);
  };

  const completeManuscriptedSubjectivity = (formValues) => {
    const completeSubjectivityParams = {
      quoteSubjectivityId,
      completeStep,
      goForward,
      step,
      payload: {
        paragraph: formValues.paragraph,
        uploadedDocuments: subjectivityData?.data?.uploadedDocuments,
        userAttested: true,
      },
    };
    completeSubjectivity(completeSubjectivityParams);
  };

  const handleGoBack = () => {
    if (
      preIssueSteps[0]?.subjectivityKey === subjectivityKey &&
      preBindSteps.length &&
      incompletePreBindSteps.length &&
      subjectivitySubType === PRE_ISSUANCE
    ) {
      jumpTo(preBindSteps[preBindSteps.length - 1].subjectivityKey);
    } else {
      goBack();
    }
  };

  const loadingComponent =
    subjectivityDataLoading ||
    isFetchingSubjectivityData ||
    uploadedDocsLoading ||
    isFetchingUploadedDocs;

  if (loadingComponent) {
    return null;
  }

  const { subjectivityDescription } = subjectivityData;

  return (
    <Box className="contrast-text" width="50%">
      <ScreenTitle
        subjectivitySubType={subjectivitySubType}
        title={subjectivityTitle}
      />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <h3>{subjectivityDescription}</h3>

            <Box>
              <InputLabelBase>{subjectivityTitle} Fill-In</InputLabelBase>
              <TextArea
                name="paragraph"
                minRows={5}
                multiline
                placeholder="More details about the subjectivity..."
                disabled={!isStepEditable}
              />
            </Box>
            <Box
              show={
                isStepEditable || !!subjectivityData?.data?.uploadedDocuments
              }
            >
              <DocumentUploadBase
                uploadZoneParams={uploadZoneParams}
                files={files}
                isUploadingDoc={isUploadingDoc}
                isStepEditable={isStepEditable}
                uploadedDocs={uploadedDocuments}
                docType="Manuscript Subjectivity Info"
              />
            </Box>
          </Box>

          <Box
            show={
              files ||
              (isStepCompleted && !!subjectivityData?.data?.uploadedDocuments)
            }
            paddingTop="1rem"
          >
            <CheckBoxBase
              name="manuscriptedSubjectivityAcknowledgement"
              label={
                <CheckBoxLabel
                  label={`Check to verify signature and date of ${subjectivityTitle}`}
                />
              }
              onChange={handleCheckboxChange}
              checked={isSubjectivityAcknowledged}
              disabled={isStepCompleted && !files}
            />
          </Box>

          <SubjectivityCompletionDate
            allSubjectivityInformation={subjectivityData}
            show={isStepCompleted}
          />

          <Box paddingTop="2rem">
            <CbButton styleName="cancel" size="medium" onClick={handleGoBack}>
              Back
            </CbButton>

            <CbButton
              styleName="ctaButton"
              size="medium"
              type="submit"
              disabled={
                isCompletingStep ||
                isUploadingDoc ||
                (files && !isSubjectivityAcknowledged)
              }
              loading={isCompletingStep || isUploadingDoc}
              style={{ marginRight: '0.833rem' }}
            >
              Next
            </CbButton>

            <Show when={subjectivitySubType != POST_ISSUANCE}>
              <CowbellReferToUWButton
                quoteId={quoteDetails.id}
                subjectivityId={quoteSubjectivityId}
                productType={quoteDetails.uiData.productType}
              />
            </Show>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

const TextArea = withFormController(
  styled(TextAreaBase)(({ theme: { palette } }) => ({
    width: '100%',
    backgroundColor: palette.background.paper,
    resize: 'none',
  }))
);

export default ManuscriptedSubjectivity;
