import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { PopoverProps } from '@mui/material';
import { IconButton, List, ListItem, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import type { JSXElementConstructor, MouseEventHandler } from 'react';
import { usePopOverState } from '../../../agencies/quotes/PrimeX/customizeQuote/components/DatePicker';

interface Option {
  label: string;
  onClick: () => void;
}
interface MenuProps {
  options: Option[];
  Button?: JSXElementConstructor<any>;
}

export const EllipsisMenu = ({ options, Button }: MenuProps) => {
  const classes = useStyles();
  const popoverState = usePopOverState();
  const onOpen: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    popoverState.onOpen(event);
  };
  const onClose: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    popoverState.onClose();
  };
  return (
    <>
      {Button ? (
        <Button onClick={onOpen} />
      ) : (
        <IconButton size="small" onClick={onOpen} disableRipple>
          <FontAwesomeIcon
            size="lg"
            icon={solid('ellipsis')}
            className={classes.icon}
          />
        </IconButton>
      )}
      <EllipsisMenuPopover
        {...popoverState}
        onClose={onClose}
        options={options}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: { '&:hover': { color: theme.config.colors.cowbellBlue } },
}));

const EllipsisMenuPopover = ({
  options,
  ...popoverState
}: MenuProps & PopoverProps) => {
  return (
    <Popover
      id="agency-dashboard-filter-menu"
      PaperProps={{ style: { width: '13rem' } }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...popoverState}
    >
      <List>
        {options.map((option: Option) => {
          return <MenuListItem option={option} key={option.label} />;
        })}
      </List>
    </Popover>
  );
};

interface ListItemProps {
  option: Option;
}

const MenuListItem = ({ option }: ListItemProps) => {
  return (
    <ListItem onClick={option.onClick} style={{ cursor: 'pointer' }}>
      <Typography variant="body2">{option.label}</Typography>
    </ListItem>
  );
};
