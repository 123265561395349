import React from 'react';
import _ from 'lodash';

// mui
import { Box, Card, Divider, Typography } from '@mui/material';
import { styled } from '@mui/styles';

// components
import DescTooltip from '../../../../../components/DescTooltip';
import {
  DetailedCFRangeGraph as DetailedCFRangeGraphBase,
  DetailedCFRangeGraphLegend as DetailedCFRangeGraphLegendBase,
  DetailedNonLiveCFRangeGraphLegend as DetailedNonLiveCFRangeGraphLegendBase,
  DetailedNonLiveCFRangeGraph as DetailedNonLiveCFRangeGraphBase,
} from '../../../../../components/graphs/DetailedCFRangeGraph';

// utils
import CowbellFactorsMapping from '../../../../../utility/CowbellFactorsMapping';
import { Flexbox } from './policy-details.shared-components.utils';
import { withShowable } from '../../../../_global/lib/withShowable';
import { DoubleLabelSwitch } from '../../../../../components/inputs/switches/DoubleLabelSwitch';

const DetailedCFRangeGraph = withShowable(DetailedCFRangeGraphBase);
const DetailedCFRangeGraphLegend = withShowable(DetailedCFRangeGraphLegendBase);
const DetailedNonLiveCFRangeGraph = withShowable(
  DetailedNonLiveCFRangeGraphBase
);
const DetailedNonLiveCFRangeGraphLegend = withShowable(
  DetailedNonLiveCFRangeGraphLegendBase
);

export const CowbellFactorsTab = ({
  policy,
  accountRiskDetails,
  latestRisk,
}) => {
  const { latestRiskSelected, handleLatestRiskToggle } = latestRisk;

  const cowbellFactorRiskDetails = useNormalizeCowbellFactorRiskDetails({
    accountRiskDetails,
    latestRiskSelected,
    policy,
  });

  return (
    <CowbellFactorsTabCard>
      <Box display="flex" flexDirection="column">
        <Box className="justify-center">
          <DoubleLabelSwitch
            onChange={handleLatestRiskToggle}
            value={latestRiskSelected}
            offLabel="Risk as of Last Underwriting"
            onLabel="Latest Risk"
          />
        </Box>
        <DetailedCFRangeGraphLegend
          show={latestRiskSelected}
          legendContainerProps={{ display: 'flex', style: { gap: '1rem' } }}
        />
        <DetailedNonLiveCFRangeGraphLegend
          show={!latestRiskSelected}
          legendContainerProps={{ display: 'flex', style: { gap: '1rem' } }}
        />
      </Box>
      <Divider style={{ width: '100%', marginBottom: '1rem' }} />
      <CowbellFactorRiskDetails
        factors={cowbellFactorRiskDetails}
        latestRiskSelected={latestRiskSelected}
      />
    </CowbellFactorsTabCard>
  );
};

const CowbellFactorRiskDetails = ({ factors, ...props }) => {
  return factors.map((factor) => {
    if (factor.factorKey === 'insiderThreat') return null;
    return <CowbellFactorRiskDetail factor={factor} {...props} />;
  });
};

const CowbellFactorRiskDetail = ({ factor, latestRiskSelected }) => {
  const { factorKey, min, max, company, industry } = factor;
  const { label, tooltip, subtext } = CowbellFactorsMapping[factorKey];

  return (
    <Box mb={4}>
      <Flexbox>
        <Box width="50%">
          <Box display="flex" style={{ alignItems: 'center' }}>
            <Typography>{label}</Typography>
            <DescTooltip tooltip={tooltip} />
          </Box>
          <Box>
            <Typography variant="caption" color="secondary">
              {subtext}
            </Typography>
          </Box>
        </Box>
        <Box style={{ fontWeight: '600' }} width="30%">
          <DetailedCFRangeGraph
            show={latestRiskSelected}
            min={min}
            max={max}
            factor={company}
            average={industry}
          />
          <DetailedNonLiveCFRangeGraph
            show={!latestRiskSelected}
            min={min}
            max={max}
            factor={company}
            average={industry}
          />
        </Box>
      </Flexbox>
    </Box>
  );
};

const useNormalizeCowbellFactorRiskDetails = ({
  accountRiskDetails,
  latestRiskSelected,
  policy,
}) => {
  return React.useMemo(() => {
    const { details } = accountRiskDetails;
    if (latestRiskSelected) {
      return Object.keys(details)
        .map((factorKey) => {
          return { factorKey, ...details[factorKey] };
        })
        .sort((a, b) => (a.factorKey < b.factorKey ? -1 : 1));
    }
    const policyCowbellFactors = _.get(
      policy,
      'cowbellRiskFactors.factors',
      {}
    );
    return Object.keys(policyCowbellFactors)
      .map((factor) => {
        const key = factor.slice(0, -2);
        return {
          factorKey: key,
          company: policyCowbellFactors[factor],
          min: details[key].min,
          max: details[key].max,
          industry: details[key].industry,
        };
      })
      .sort((a, b) => (a.factorKey < b.factorKey ? -1 : 1));
  }, [accountRiskDetails, latestRiskSelected, policy]);
};

const CowbellFactorsTabCard = styled(Card)(({ theme: { palette } }) => ({
  padding: '1rem',
  backgroundColor: palette.background.default,
}));
