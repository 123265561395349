import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash';
import {
  es6PaginationAdaptor,
  es7PaginationAdaptor,
} from '../adaptors/table-pagination.adaptors';

const defaultConfig = { paginationAdaptor: es6PaginationAdaptor };
const defaultReplaceOptions = {
  blacklist: ['page', 'searchAfter', 'pageRequested'],
};

export const useQueryHandlers = (query, config = {}) => {
  const history = useHistory();
  const location = useLocation();
  const { paginationAdaptor } = { ...defaultConfig, ...config };

  const replace = React.useCallback(
    (update, options = {}) => {
      const { blacklist } = { ...defaultReplaceOptions, ...options };
      const nextQuery = _.omit({ ...query, ...update }, blacklist);
      history.replace({
        pathname: location.pathname,
        search: qs.stringify(nextQuery, {
          encode: false,
          addQueryPrefix: true,
        }),
      });
    },
    [history, location.pathname, query]
  );

  const onCurrentPageChange = React.useCallback(
    (newPage, nextToken, prevToken) => {
      const update = paginationAdaptor({
        newPage,
        nextToken,
        prevToken,
        query,
      });
      replace(update, { blacklist: [] });
    },
    [paginationAdaptor, query, replace]
  );

  const onPageSizeChange = React.useCallback(
    (newSize) => {
      replace({ size: newSize });
    },
    [replace]
  );

  const onSortingChange = React.useCallback(
    ([{ columnName, direction }]) => {
      replace({ orderBy: columnName, order: direction });
    },
    [replace]
  );

  const onSearchValueChange = React.useCallback(
    (value) => {
      if (value === '') {
        const { blacklist: defaultBlacklist } = defaultReplaceOptions;
        replace(
          {},
          {
            blacklist: ['search', ...defaultBlacklist],
          }
        );
      } else {
        replace({ search: value });
      }
    },
    [replace]
  );

  return {
    queryHandlers: {
      onCurrentPageChange,
      onPageSizeChange,
      onSortingChange,
      onSearchValueChange,
    },
  };
};

export const useES7QueryHandlers = (query, config) =>
  useQueryHandlers(query, {
    ...config,
    paginationAdaptor: es7PaginationAdaptor,
  });
