import { Grid } from '@mui/material';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/forms';

const TextField = withFormController(TextFieldBase);

export const FormGridBuilder = ({ formConfig = [], ...gridContainerProps }) => {
  return (
    <Grid container spacing={2} {...gridContainerProps}>
      {formConfig.map((formField) => {
        const {
          component: Component,
          gridItemProps,
          ...fieldProperties
        } = formField;

        if (formField.name === 'placeholder') {
          return (
            <Grid item {...gridItemProps} key={formField.name}>
              <div style={{ visibility: 'hidden' }} />
            </Grid>
          );
        }

        return (
          <Grid item {...gridItemProps} key={formField.name}>
            {Component ? (
              <Component
                {...fieldProperties}
                textFieldProps={{
                  labelProps: { style: { marginLeft: 0 } },
                }}
                InputLabelProps={{ indent: false }}
                labelProps={{ style: { marginLeft: 0 } }}
              />
            ) : (
              <TextField
                {...fieldProperties}
                labelProps={{ style: { marginLeft: 0 } }}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};
