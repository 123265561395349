import axios from 'axios';
import { applyQueryConfig, ServiceHandler } from '../utils/api.utils';
import {
  standardParamSerializer,
  standardQuerySerializer,
} from './serializers';
import { Api } from '../config/axiosConfig';

export interface UploadDocumentV3Response {
  docId: string;
  docName: string;
  docType: string;
  fileType: string;
  s3path: string;
  bucketName: string;
}

export const uploadDocumentV3 = ServiceHandler<
  FormData,
  UploadDocumentV3Response
>({
  url: `/api/docs/v1/upload/doc`,
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const uploadDocumentGeneral = ServiceHandler({
  url: '/api/docs/v1/upload/doc-general',
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const uploadDocuments = ServiceHandler({
  url: `/api/docs/v1/upload/docs`,
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const downloadBlankNKLL = ServiceHandler({
  url: '/api/docs/v1/template?type=nkll',
});

export const downloadBlankP100NKLL = ServiceHandler({
  url: '/api/docs/v1/template?type=p100-nkll',
});

export const downloadNKLLTemplate = (endpoint: string) => {
  return Api.get(endpoint);
};

const docsParamsSerializer = [
  ...standardParamSerializer,
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: any) => {
      if (orderBy && order) {
        switch (orderBy) {
          case 'modified':
          case 'created':
          case 'retainedDate':
          case 'docTime':
            return `${orderBy},${order}`;
          default:
            return `${orderBy}.keyword,${order}`;
        }
      }
      return null;
    },
  },
];

export const getDocsInfo = ServiceHandler({
  url: '/api/docs/v1/search/:accountId',
  paramsSerializer: (params) => {
    return standardQuerySerializer(
      applyQueryConfig(docsParamsSerializer, params)
    );
  },
});
