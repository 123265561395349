import { useQuery } from '@tanstack/react-query';

import { Paper } from '@mui/material';
import { Skeleton } from '@mui/lab';

import Tile from '../../../../components/tiles/Tile';

import { useQueryParams } from '../../../../components/providers/QueryParamsProvider';

import { fetchEndorsementPolicyPremiumStats } from '../../../../policies/PolicyService';
import {
  IN_FORCE_STATUS,
  ISSUED_STATUS,
} from '../../../_statics/policy.statics';

const initialStatsMap = {
  stats: {
    totalEndorsements: {
      value: 0,
      name: 'Total Endorsements',
      type: 'policies',
    },
    totalPremium: {
      value: 0,
      name: 'Total Premium',
      type: 'premiumMedium',
    },
    avgPremium: { value: 0, name: 'Avg Premium', type: 'premium' },
  },
};

export default function CowbellEndorsementsStatsBar() {
  const { query } = useQueryParams();

  const policyStatsQuery = useQuery({
    queryKey: ['endorsements-stats', { query }],
    queryFn: () =>
      fetchEndorsementPolicyPremiumStats(query).then(({ data }) => {
        const transformedStats = { ...initialStatsMap.stats };

        const { totalCount, totalPremium } =
          data.aggregations.Status.Status.buckets.reduce(
            (acc, currBucket) => {
              if ([IN_FORCE_STATUS, ISSUED_STATUS].includes(currBucket.key)) {
                return {
                  ...acc,
                  totalCount: acc.totalCount + currBucket.doc_count,
                  totalPremium: acc.totalPremium + currBucket.GWP.value,
                };
              }

              return acc;
            },
            { totalCount: 0, totalPremium: 0 }
          );

        const avgPremium = totalPremium / totalCount;

        transformedStats.totalEndorsements.value = totalCount;
        transformedStats.totalPremium.value = totalPremium;
        transformedStats.avgPremium.value = avgPremium;

        return Object.entries(transformedStats).map(([, value]) => value);
      }),
  });

  if (policyStatsQuery.isLoading) {
    return loadingSkeleton;
  }

  return (
    <Paper className="stats-bar" elevation={6}>
      {policyStatsQuery.data &&
        policyStatsQuery.data.map((stat) => (
          <Tile key={stat.name} query={query} {...stat} />
        ))}
    </Paper>
  );
}

const loadingSkeleton = (
  <Paper className="stats-bar" elevation={6}>
    {Array(3)
      .fill()
      .map(() => (
        <Skeleton
          variant="rectangular"
          component="div"
          height="84px"
          style={{ borderRadius: '4px' }}
        >
          <Tile />
        </Skeleton>
      ))}
  </Paper>
);
