import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// react
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
// mui
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Grid,
} from '@mui/material';
// platform helpers
import { useQueryClient } from '@tanstack/react-query';
import { delayedEvent } from '../../utils/eventUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../reducers/UiSettingsReducers';
// actions
import { submitForUwReview } from '../../inbox/QuotesService';
// components
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/withFormController';
import CbButton from '../Buttons/CbButton';

const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  reason: Yup.string().required('Reason is a required field'),
});

export const SubmitForUWReview = ({ close, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const defaultValues = {
    apiKey: '',
  };
  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    validationSchema: schema,
  });
  const {
    formState: { isSubmitting },
  } = methods;

  const [error, setError] = useState('');
  const { id, quoteNumber } = data;

  const onSubmit = (formData) => {
    const payload = {
      reason: formData.reason,
      quoteId: id,
    };

    submitForUwReview(payload)
      .then(() => {
        enqueueSnackbar(
          `Quote# ${quoteNumber} submitted for Underwriter review`,
          { variant: 'success' }
        );
        delayedEvent('table-refetch', 500, AGENCY_QUOTES_LISTING_STORE_ID);
        queryClient.invalidateQueries(['COWBELL_QUOTES']);
        close();
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong, please try again later.'
            )
          )
        );
      });
  };
  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid item md={8}>
            <TextField
              error={_.get(methods, 'errors.reason.message')}
              name="reason"
              label="Reason"
              fullWidth
              required
            />
          </Grid>
          {error && (
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close}>
            Cancel
          </CbButton>
          <CbButton
            loading={isSubmitting}
            styleName="ctaButton"
            buttonText="Submit for Review"
            type="submit"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};
