import Moment from 'moment';

export const commonDateParamMappings = [
  {
    path: 'after',
    transform: ({ after }: { after: number }) =>
      after ? Moment.unix(after).startOf('day').toISOString() : null,
  },
  {
    path: 'before',
    transform: ({ before }: { before: number }) =>
      before
        ? Moment.unix(before).endOf('day').toISOString()
        : Moment().endOf('day').toISOString(),
  },
];
