import clsx from 'classnames';

import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import type { WithChildrenProps } from '../../../../types/components/common';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import type { AgencyDashboardView } from '../agency-dashboard.statics';
import { AGENCY_DASHBOARD_VIEWS } from '../agency-dashboard.statics';
import { START_NEW_QUOTE_AGENCY_DASHBOARD_EVENT } from '../../../../analytics/events/start-new-quote-agency-dashboard.event';
import { useAmplitude as useAnalytics } from '../../../../providers/AmplitudeProvider';
import { BIND_QUOTE_AGENCY_DASHBOARD_EVENT } from '../../../../analytics/events/bind-quote-agency-dashboard.event';
import { RENEW_POLICY_AGENCY_DASHBOARD_EVENT } from '../../../../analytics/events/renew-policy-agency-dashboard.event';

type ActionButtonProps = WithChildrenProps & {
  name: AgencyDashboardView | 'commissions';
  onClick?: () => void;
  accentColor: `#${string}`;
};

export const LargeActionButton = ({
  children,
  name,
  onClick,
  accentColor,
}: ActionButtonProps) => {
  const { handleViewChange } = useAgencyDashboardState();
  const classes = useButtonStyles();
  const analytics = useAnalytics();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      if (name === AGENCY_DASHBOARD_VIEWS.NEW_QUOTE) {
        analytics.track(START_NEW_QUOTE_AGENCY_DASHBOARD_EVENT);
      } else if (name === AGENCY_DASHBOARD_VIEWS.BIND_QUOTE) {
        analytics.track(BIND_QUOTE_AGENCY_DASHBOARD_EVENT);
      } else if (name === AGENCY_DASHBOARD_VIEWS.RENEW_POLICY) {
        analytics.track(RENEW_POLICY_AGENCY_DASHBOARD_EVENT);
      }
      handleViewChange(name);
    }
  };

  return (
    <div style={{ width: '50%' }}>
      <div className="ratio--1x1">
        <div className="ratio__content">
          <Button
            disableRipple
            variant="contained"
            data-qa={`BUTTON:${name}`}
            onClick={handleClick}
            classes={classes}
            className={clsx('scale--small')}
            style={{ borderLeft: `4px solid ${accentColor}` }}
          >
            {children}
          </Button>
        </div>
      </div>
    </div>
  );
};

const useButtonStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    height: '100%',
    padding: '2rem 1.5rem',
    backgroundColor: theme.agencyDash.background.button,
    color: theme.palette.primary.contrastText,

    textAlign: 'center',

    '&:hover': {
      backgroundColor: theme.agencyDash.background.button,
    },
  },

  label: {
    fontSize: '1rem',
  },
}));
