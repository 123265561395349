import _ from 'lodash';
import * as Ramda from 'ramda';
import Numeral from 'numeral';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import { useSnackbar } from 'notistack';

// react
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form-4';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// mui
import {
  Button as MuiButton,
  ButtonBase as MuiButtonBase,
  CircularProgress as MuiCircularProgress,
  FormHelperText,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  Box,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
// components
import { useQuery } from '@tanstack/react-query';
import withRouter from '../../../components/hocs/withRouter';
import { QuotesListTable } from '../../../inbox/BatchQuotes/QuotesListTable';
import AddToListIcon from '../../../_assets/svg/AddToList';
import SimpleTooltip from '../../../components/SimpleTooltip';
import { CoverageChart } from '../../_global/charts/coverage.chart';
import { calculateCoverageOptions } from '../../_statics/coverage.statics';
import CbButton from '../../../components/Buttons/CbButton';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import { withP100RequestQuoteDependencies } from './withP100RequestQuoteDependencies';
// actions
import {
  addToListQuotes,
  generateMultipleQuotesForAccount,
  generateQuoteEdit,
  generateQuoteForAccount,
  getSocialEngg,
} from '../../../api/apis';
import {
  calculatePricingV2,
  checkStatusOnAccount,
  getQuoteSublimitEligibility,
} from '../../../inbox/QuotesService';

// platform helpers
import { manageAPIError } from '../../../utils';
import { delayedEvent, PubSub } from '../../../utils/eventUtils';
import { useToggleModal } from '../../../utils/modal.utils';

// reducers
import {
  resetAgencyData,
  setPrime100Ui,
} from '../../_reducers/prime100.reducer';

import { Modal } from '../../../components/modals/v2/helpers/v2.modal.helpers';
import { useAuth } from '../../../components/hooks/useAuth';

import {
  convertDateUTC,
  oneYearInpast,
  sevenDaysInpast,
  sixtyDaysInFuture,
  utcToNativeDate,
} from '../../../utils/date.utils';

import { numberToCurrency } from '../../../utils/currency.utils';

import { useQuoteCreationFunctions } from '../../../accounts/accountUtils';
import { retroMappingFull } from '../../_global/endorsements/endorsement.utils';
import {
  useAmplitude,
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES,
} from '../../../providers/AmplitudeProvider';
import { useAgencyDashboardFeature } from '../../dashboard/agency/agency-dashboard.statics';
import { AGENCY_DASHBOARD_BASE_PATH } from '../../dashboard/agency/DashboardViewContainer';

const aggregateLimits = [
  { value: 50000, label: '$50,000' },
  { value: 100000, label: '$100,000' },
  { value: 250000, label: '$250,000' },
  { value: 500000, label: '$500,000' },
  { value: 750000, label: '$750,000' },
  { value: 1000000, label: '$1,000,000' },
  { value: 2000000, label: '$2,000,000' },
  { value: 3000000, label: '$3,000,000' },
];

const deductibleAmounts = [
  { value: 1000, label: '$1,000' },
  { value: 1500, label: '$1,500' },
  { value: 2500, label: '$2,500' },
  { value: 5000, label: '$5,000' },
  { value: 10000, label: '$10,000' },
  { value: 25000, label: '$25,000' },
  { value: 50000, label: '$50,000' },
];

const waitingPeriods = [
  { value: 6, label: '6 Hours' },
  { value: 8, label: '8 Hours' },
  { value: 12, label: '12 Hours' },
  { value: 24, label: '24 Hours' },
];

let retroActivePeriods = [
  { value: 1, label: '1 Year' },
  { value: 2, label: '2 Years' },
  { value: 3, label: 'Full Prior Acts' },
];

const socialLimits = [
  { value: 50000, label: '$50,000' },
  { value: 100000, label: '$100,000' },
  { value: 250000, label: '$250,000' },
];

const social50LimitDeductible = [
  { value: 5000, label: '$5,000' },
  { value: 10000, label: '$10,000' },
  { value: 25000, label: '$25,000' },
];
const socialDeductibles = [
  { value: 10000, label: '$10,000' },
  { value: 25000, label: '$25,000' },
  { value: 50000, label: '$50,000' },
];

const websiteLimits = [
  { value: 50000, label: '$50,000' },
  { value: 100000, label: '$100,000' },
  { value: 250000, label: '$250,000' },
  { value: 500000, label: '$500,000' },
  { value: 1000000, label: '$1,000,000' },
];

const DateToggle = React.forwardRef(({ value, onClick, classes }, ref) => {
  return (
    <MuiButtonBase className={classes.datePicker} onClick={onClick} ref={ref}>
      {value}
      <ArrowDropDownIcon style={{ marginLeft: 5 }} />
    </MuiButtonBase>
  );
});

const GenerateQuoteModal = compose(
  withStyles(
    ({ palette, config, ...theme }) => ({
      addToListIcon: {
        width: '3.5rem',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: palette.background.lighter,

        '&:hover': {
          cursor: 'pointer',
        },
      },

      centerActions: {
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },

        height: '96%',
        width: 2,
        position: 'absolute',
        top: '52%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: palette.common.cowbellBlue,

        '& button': {
          width: 'inherit',
          minWidth: 'unset',
          padding: 0,
        },

        '& button:hover': {
          background: 'none',
        },

        '& img': {
          width: 'inherit',
        },
      },

      labels: {
        fontfamily: 'Titillium Web',
        fontSize: config.textSizes.normal,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: palette.text.text8,
        marginBottom: '-10px',
      },
      datePicker: {
        fontSize: '1.166rem',
        fontWeight: 600,
        padding: '0 5px',
        margin: '10px 0',
        color: palette.text.highlighter,
        background: palette.text.highlightedtext,
        fontfamily: 'Titillium Web',
      },

      indentedLabel: {
        paddingLeft: '3.4rem',
        fontSize: config.textSizes.normal,
        color: palette.text.text8,
        marginTop: -10,
      },

      price: {
        color: palette.common.cowbellBlue,
        fontSize: config.textSizes.primer,
        lineHeight: '1',
        marginTop: 10,
        marginBottom: -6,
      },

      graphContainer: {
        marginTop: '2.083rem',
        paddingBottom: '0.6rem',
        borderTop: `1px solid ${palette.text.primary}`,
        borderBottom: `1px solid ${palette.text.primary}`,
      },

      quoteList: {
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      },

      sectionHeading: {
        alignItems: 'flex-end !important',
        margin: 0,
        paddingBottom: '6px !important',
        borderBottom: `1px solid ${palette.text.primary}`,
        textTransform: 'uppercase',
        fontSize: config.textSizes.primer,
        color: palette.text.title,
      },

      select: {
        marginRight: 1,
        border: 0,
      },

      selectIcon: {
        top: '50%',
        transform: 'translateY(-50%)',
      },

      bluetext: {
        color: palette.common.cowbellBlue,
      },
      menuItem: {
        color: palette.text.text8,
        fontSize: '14px',
        '&:hover': {
          cursor: 'pointer',
          fontWeight: 600,
        },
      },
      flexBox: {
        display: 'flex',
        justifyContent: 'space-around',
        paddingTop: 30,
      },
      wrapper: {
        position: 'fixed',
        background: palette.background.lighter,
        padding: '20px',
        height: 'calc(100% - 145px)',
        overflow: 'auto',
      },
      background: {
        background: palette.background.lighter,
      },
      breadcrumb: {
        color: palette.primary.contrastText,
      },
      apiError: {
        bottom: 5,
        width: '100%',
        textAlign: 'center',
      },
      company: {
        margin: '0 0 1rem',
        fontWeight: config.weights.bold,
        fontSize: config.textSizes.paragon,
      },
      link: {
        color: `${config.colors.cowbellBlue} !important`,
        textDecoration: 'none',
      },
    }),
    { withTheme: true }
  ),
  withRouter,
  withP100RequestQuoteDependencies()
)(
  ({
    classes,
    theme,
    data,
    match,
    quoteDetails,
    reQuoteRenew,
    policyDetails,
    updateDate,
    endorsementDetails = {},
    ...props
  }) => {
    if (_.isEmpty(data)) return null;
    const { enqueueSnackbar } = useSnackbar();
    const agencyDashboardFeature = useAgencyDashboardFeature();
    const toggleModal = useToggleModal();

    const auth = useAuth();

    const isCowbellPersona = auth.accountType === 'COWBELL';

    const { P_100 } = useSelector((globalState) => globalState.auth);
    const AI = useSelector(({ prime100 }) => prime100.ui.additionalInsuredList);
    const dispatch = useDispatch();
    const amplitude = useAmplitude();
    const { policyEndDate, policyId, isEndorsement } = policyDetails;

    const renew = props.tab === 'renew';
    const editQuote =
      match.params.tab === 'edit-quote' ||
      props.tab === 'edit-quote' ||
      props.tab === 'verify' ||
      match.params.status === 'edit-quote';

    const isVerify = props.tab === 'verify';
    const [verify, setVerify] = React.useState(false);
    const [initialLoad, setInitialLoad] = React.useState(true);
    const endorsement = props.tab === 'endorsement' || isEndorsement;

    const { account, agency, agent } = data[0];

    const { naicsCode, excludedFromSE, coverages100, state } = account;

    const { determineTeamIdOfAgent } = useQuoteCreationFunctions({
      ...account,
      agencies: _.get(account, 'agencies[0]', {}),
    });

    const initialRequestData = _.get(quoteDetails, 'initialRequestData', {});
    const addInsured = _.get(initialRequestData, 'additionalInsureds', []);

    const coverageList = coverages100;
    const coverageMapping = P_100;

    const disableRansom = _.isEmpty(coverageMapping)
      ? false
      : !coverageList?.includes(coverageMapping.RANSOM_PAYMENTS.ordinal);

    const disableWebsite = _.isEmpty(coverageMapping)
      ? false
      : !coverageList?.includes(
          coverageMapping.WEBSITE_MEDIA_CONTENT_LIABILITY.ordinal
        );

    const [isCalculating, setIsCalculating] = useState(false);
    const [quotes, setQuotes] = useState([]);
    const [canAdd, setCanAdd] = useState(quotes.length < 5);
    const [loading, setLoading] = useState(false);
    const [quoteApiError, setQuoteApiError] = useState('');
    // only here to help things re-render after a field change
    // eslint-disable-next-line
    const [shouldCalculate, setShouldCalculate] = useState('');
    const [premium, setPremium] = useState(0);
    const [brokerFee, setBrokerFee] = useState(0);
    const [totalPremium, setTotalPremium] = useState(0);
    // coverage disabling
    const [socialEngEndorsement, setSocial] = useState(false);
    const [disableSocial, setDisable] = useState(
      _.isEmpty(coverageMapping)
        ? false
        : coverageList.includes(coverageMapping.SOCIAL_ENGINEERING.ordinal)
    );
    const [socialReason, setReason] = useState('');
    const [disableButton, setDisableButton] = useState(false);
    const [disableRansomUnder250, setUnder250] = useState(false);
    const persona = React.useMemo(
      () => document.location.pathname.split('/')[1],
      []
    );
    const quoteId = React.useMemo(
      () => props.quoteId || document.location.pathname.split('/')[5],
      [props.quoteId]
    );

    const naicsCodeAsString = `${naicsCode}`;
    const isEducation = naicsCodeAsString.search(/^61/) !== -1;
    const isPublicAdmin = naicsCodeAsString.search(/^92/) !== -1;
    const isManufacturing = /^31|^32|^33/.test(naicsCodeAsString);

    const defaultEffectiveDate = React.useMemo(() => {
      if (editQuote) {
        return Moment.utc(
          _.get(quoteDetails, 'initialRequestData.effectiveDate')
        )
          .endOf('day')
          .toDate();
      }

      if (renew) {
        return Moment.utc(policyEndDate).endOf('day').toDate();
      }

      if (endorsement) {
        return utcToNativeDate(new Date(endorsementDetails.effectiveDate));
      }

      return Moment().endOf('day').add(7, 'days').toDate();
    }, [
      editQuote,
      endorsement,
      endorsementDetails.effectiveDate,
      policyEndDate,
      quoteDetails,
      renew,
    ]);

    const reQuoteRenewDefaultValues = React.useMemo(
      () => ({
        ..._.pick(initialRequestData, [
          'businessIncomeCoverage',
          'ransomPaymentEndorsement',
          'ransomPaymentLimit',
          'hardwareReplCostEndorsement',
          'hardwareReplCostSubLimit',
          'telecomsFraudEndorsement',
          'telecomsFraudSubLimit',
          'postBreachRemediationEndorsement',
          'postBreachRemediationSubLimit',
          'computerFraudEndorsement',
          'websiteMediaContentLiabilityEndorsement',
          'websiteMediaContentLiabilitySubLimit',
          'socialEngLimit',
          'socialEngDeductible',
          'limit',
          'deductible',
          'waitingPeriod',
        ]),
        retroActivePeriod: initialRequestData.retroactivePeriod,
        effectiveDate: defaultEffectiveDate,
        additionalInsureds: addInsured.length > 0,
        insured: addInsured.length,
      }),
      [addInsured.length, defaultEffectiveDate, initialRequestData]
    );

    const endorsementDefaults = React.useMemo(
      () => ({
        ..._.pick(endorsementDetails.coverages, [
          'businessIncomeCoverage',
          'ransomPaymentEndorsement',
          'ransomPaymentLimit',
          'hardwareReplCostEndorsement',
          'hardwareReplCostSubLimit',
          'telecomsFraudEndorsement',
          'telecomsFraudSubLimit',
          'postBreachRemediationEndorsement',
          'postBreachRemediationSubLimit',
          'computerFraudEndorsement',
          'websiteMediaContentLiabilityEndorsement',
          'websiteMediaContentLiabilitySubLimit',
          'socialEngLimit',
          'socialEngDeductible',
          'limit',
          'deductible',
          'waitingPeriod',
        ]),
        effectiveDate: defaultEffectiveDate,
        additionalInsureds: false,
        insured: 0,
        retroActivePeriod: endorsementDetails?.coverages?.retroactivePeriod,
      }),
      [defaultEffectiveDate, endorsementDetails.coverages]
    );

    const baseDefaultValues = React.useMemo(
      () => ({
        businessIncomeCoverage: 1000000,
        ransomPaymentEndorsement:
          !_.isEmpty(coverageMapping) &&
          !!coverageList.includes(coverageMapping.RANSOM_PAYMENTS.ordinal),
        ransomPaymentLimit: 500000,
        hardwareReplCostEndorsement: false,
        hardwareReplCostSubLimit: 50000,
        telecomsFraudEndorsement: false,
        telecomsFraudSubLimit: 50000,
        postBreachRemediationEndorsement: false,
        postBreachRemediationSubLimit: 50000,
        computerFraudEndorsement:
          !_.isEmpty(coverageMapping) &&
          !!coverageList.includes(
            coverageMapping.COMPUTER_FUNDS_TRANSFER_FRAUD.ordinal
          ),
        websiteMediaContentLiabilityEndorsement:
          !_.isEmpty(coverageMapping) &&
          !!coverageList.includes(
            coverageMapping.WEBSITE_MEDIA_CONTENT_LIABILITY.ordinal
          ),
        websiteMediaContentLiabilitySubLimit: 1000000,
        socialEngLimit: 100000,
        socialEngDeductible: 10000,
        effectiveDate: defaultEffectiveDate,
        limit: 1000000,
        deductible: isPublicAdmin || isEducation ? 25000 : 2500,
        waitingPeriod: isManufacturing ? 12 : 6,
        retroActivePeriod: 3,
        additionalInsureds: false,
        insured: 0,
      }),
      [
        coverageList,
        coverageMapping,
        defaultEffectiveDate,
        isEducation,
        isManufacturing,
        isPublicAdmin,
      ]
    );

    const defaults = React.useMemo(() => {
      if (reQuoteRenew) {
        return reQuoteRenewDefaultValues;
      }

      if (endorsement) {
        return endorsementDefaults;
      }

      return baseDefaultValues;
    }, [
      baseDefaultValues,
      endorsement,
      endorsementDefaults,
      reQuoteRenew,
      reQuoteRenewDefaultValues,
    ]);

    const { handleSubmit, register, getValues, setValue, watch } = useForm({
      defaultValues: defaults,
    });

    const form = useForm({
      defaultValues: {
        exclude: false,
        socialEngg: true,
      },
    });

    form.watch([
      'exclude',
      'socialEngg',
      'websiteMediaContentLiabilitySubLimit',
    ]);

    const {
      effectiveDate,
      limit,
      deductible,
      ransomPaymentLimit,
      websiteMediaContentLiabilitySubLimit,
    } = watch([
      'deductible',
      'effectiveDate',
      'limit',
      'additionalInsureds',
      'insured',
      'ransomPaymentLimit',
      'websiteMediaContentLiabilitySubLimit',
    ]);

    const openModal = (value) => {
      if (!disableSocial) {
        if (!socialEngg) {
          if (value === 'SocialEngg') {
            getSocialEngg(account.id).then((resp) => {
              toggleModal.direct(
                [value],
                { accountId: account.id, ...resp.data },
                {
                  title: 'Social Engineering',
                  maxWidth: 'md',
                }
              );
            });
          }
        } else {
          form.setValue('socialEngg', false);
          setSocial(false);
          setValue('socialEngLimit', 100000);
          setValue('socialEngDeductible', 10000);
        }
      }
    };

    useEffect(() => {
      const sub = PubSub.subscribe('disable:AI', () => {
        setValue('additionalInsureds', false);
      });

      const sub1 = PubSub.subscribe('change:AI', (length) => {
        setValue('insured', length);
        // PubSub.publish('premium:calculate');
      });

      dispatch(
        setPrime100Ui({
          additionalInsuredList:
            reQuoteRenew && !endorsement
              ? addInsured.map((item) => {
                  // eslint-disable-next-line no-shadow
                  const { naicsCode, naicsDescription } = item;
                  return {
                    ...item,
                    naicsCode: {
                      label: `${naicsCode} ${
                        naicsDescription && `[${naicsDescription}]`
                      }`,
                      value: naicsCode,
                      meta: {
                        secondaryIndustry: naicsDescription,
                      },
                    },
                  };
                })
              : [],
        })
      );

      return () => {
        sub.remove();
        sub1.remove();
      };
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      form.register({ name: 'exclude' }, { required: true });
      form.register({ name: 'socialEngg' }, { required: true });

      const sub = PubSub.subscribe('set-social', (socialData) => {
        setSocial(socialData);
        form.setValue('socialEngg', socialData);
        PubSub.publish('premium:calculate', {
          accountData: account,
          agencyData: agency,
        });
      });

      return () => {
        sub.remove();
      };
    }, [account, agency, form]);

    const values = getValues();

    const selectedDeductible = values.deductible;
    const selectedAggregateLimit = values.limit;
    const selectedSocialEngSublimit = values.socialEngLimit;
    const eligibilityParams = React.useMemo(() => {
      return {
        deductible: selectedDeductible,
        limit: selectedAggregateLimit,
        socialEngLimit: selectedSocialEngSublimit,
        ..._.pick(account, [
          'naicsCode',
          'coverages100',
          'industryClass',
          'revenue',
          'excludedFromSE',
        ]),
      };
    }, [
      account,
      selectedAggregateLimit,
      selectedDeductible,
      selectedSocialEngSublimit,
    ]);

    const { data: isNewBusiness } = useQuery(
      ['isNewBusiness', account.id],
      () => {
        return checkStatusOnAccount({ accountId: data[0].account?.id })
          .then((resp) => resp?.data?.quoteFlowType === 'NEW_BUSINESS')
          .catch(() => {
            enqueueSnackbar(
              'Failed to fetch Business Status, please try again',
              {
                variant: 'error',
              }
            );
          });
      },
      { refetchOnWindowFocus: false }
    );

    const { data: coverageEligibility } = useQuery(
      ['coverageEligibility', eligibilityParams, isNewBusiness],
      () => {
        if (!isNewBusiness && !editQuote) {
          return Promise((resolve) => resolve(null));
        }
        return getQuoteSublimitEligibility({
          params: {
            ...eligibilityParams,
            isRenewal: !isNewBusiness,
            coverages100: eligibilityParams.coverages100?.join(','),
          },
        })
          .then((resp) => resp.data)
          .catch((error) => {
            const errorMessage = manageAPIError(
              error,
              'Failed to fetch Coverage Eligibility, please try again'
            );

            enqueueSnackbar(errorMessage, { variant: 'error' });
            return null;
          });
      },
      {
        onSuccess: (queryData) => {
          function setDefaults() {
            if (!editQuote) {
              setValue(
                'ransomPaymentLimit',
                queryData?.ransomPaymentEndorsement?.default
              );

              setValue(
                'websiteMediaContentLiabilitySubLimit',
                queryData?.websiteMediaContentLiabilityEndorsement?.default
              );
            }
          }

          setDefaults();
        },
        refetchOnWindowFocus: false,
        enabled: isNewBusiness || editQuote,
      }
    );

    const _waitingPeriods = React.useMemo(() => {
      if (coverageEligibility) {
        const options = _.get(coverageEligibility, 'waitingPeriod.values', []);
        return options.map((option) => ({
          value: option,
          label: `${option} Hours`,
        }));
      }
      return waitingPeriods;
    }, [coverageEligibility]);

    const _retroactivePeriods = React.useMemo(() => {
      if (coverageEligibility) {
        const options = _.get(
          coverageEligibility,
          'retroactivePeriod.values',
          []
        );
        return options.map((option) => ({
          value: option,
          label: retroMappingFull[option] ?? option,
        }));
      }
      return retroActivePeriods;
    }, [coverageEligibility]);

    // subscribe to multiple quotes
    useMultipleQuotesSub(setQuotes);

    // initial calculation

    useEffect(() => {
      if (excludedFromSE) {
        getSocialEngg(account.id).then((resp) => {
          setReason(resp.data.reason[0]);
        });
        setDisable(true);
        form.setValue('exclude', true);
        setSocial(false);
        form.setValue('socialEngg', false);
        PubSub.publish('premium:calculate', {
          accountData: account,
          agencyData: agency,
        });
      } else {
        setSocial(false);
        form.setValue('socialEngg', false);
        setDisable(false);
        form.setValue('exclude', false);
        PubSub.publish('premium:calculate', {
          accountData: account,
          agencyData: agency,
        });
      }

      if (reQuoteRenew) {
        setSocial(initialRequestData.socialEngEndorsement);
        form.setValue('socialEngg', initialRequestData.socialEngEndorsement);
      }
      if (endorsement) {
        setSocial(endorsementDetails.coverages?.socialEngEndorsement);
        form.setValue(
          'socialEngg',
          endorsementDetails.coverages?.socialEngEndorsement
        );
      }
      // eslint-disable-next-line
    }, [account, agency]);

    function onSubmit({ retroActivePeriod, ...formData }) {
      const finalData = _.omit(formData, 'insured');
      setDisableButton(true);
      setLoading(true);
      const { socialEngg: _socialEngg } = form.getValues();

      const seMeta = form.getValues();
      const isValidSE = validateSE(values, seMeta.socialEngg);
      if (_socialEngg && !isValidSE) {
        enqueueSnackbar('Invalid Social Engineering Sublimit or Deductible', {
          variant: 'error',
        });
        setLoading(false);
        return;
      }

      const payload = {
        ...finalData,
        retroactivePeriod: retroActivePeriod,
        socialEngEndorsement: _socialEngg ?? false,
        premium,
        brokerFee,
        totalPremium,
        agentFirstName: agent.firstName,
        agentLastName: agent.lastName,
        agentEmail: agent.email,
        agentPhone: agent.phone,
        accountId: account.id,
        agencyId: agency.agencyId,
        effectiveDate: Moment(formData.effectiveDate).format('YYYY-MM-DD'),
        endDate: Moment(formData.effectiveDate)
          .add(1, 'y')
          .format('YYYY-MM-DD'),
        additionalInsureds: AI,
        teamIds: determineTeamIdOfAgent(),
      };

      if (verify) payload.isVerified = true;

      if (renew) {
        payload.policyId = policyId;
      }
      if (endorsement) {
        const finalPayload = _.omit(payload, [
          'additionalInsureds',
          'endDate',
          'teamIds',
          'agentFirstName',
          'agentLastName',
          'agentEmail',
          'agentPhone',
          'totalPremium',
          'brokerFee',
          'premium',
          'accountId',
        ]);
        const endorse = {
          ...finalPayload,
          effectiveDate: formData.effectiveDate,
        };

        if (_.isFunction(props.onSubmit)) {
          props.onSubmit(endorse);
        }
      }

      let successMsg = 'Quote Requested Successfully!';
      if (editQuote) successMsg = 'Quote Updated';
      if (verify) successMsg = 'Quote Verified';

      if (editQuote) {
        payload.quoteId = quoteId;

        return generateQuoteEdit(quoteId, payload)
          .then(() => {
            delayedEvent('quote:read');
            setTimeout(() => {
              enqueueSnackbar(successMsg, { variant: 'success' });
              if (isVerify) {
                props.history.push('/admin/quotes?f[isVerified][bo]=0');
              } else {
                props.history.push(
                  persona === 'admin' ? '/admin/quotes' : '/agency/inbox'
                );
              }
              setDisableButton(false);
              setLoading(false);
            }, 3000);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            const errMessage = manageAPIError(error, 'Failed to request quote');

            enqueueSnackbar(errMessage, { variant: 'error' });
          });
      }
      if (!endorsement) {
        return generateQuoteForAccount(account.id, payload)
          .then((res) => {
            amplitude.track(AMPLITUDE_EVENTS.requestQuote, {
              [AMPLITUDE_PROPERTIES.quoteId]: res.data.id,
            });
            delayedEvent('quote:read');
            setTimeout(() => {
              if (persona === 'admin') {
                props.history.push(`/${persona}/quotes`);
              } else if (persona === 'agency' && agencyDashboardFeature.on) {
                props.history.push(AGENCY_DASHBOARD_BASE_PATH);
              } else {
                props.history.push(`/${persona}/inbox`);
              }
              dispatch(resetAgencyData());
              setDisableButton(false);
              setLoading(false);
              enqueueSnackbar('Quote Requested Successfully!', {
                variant: 'success',
              });
            }, 3000);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            const errMessage = manageAPIError(error, 'Failed to request quote');

            setQuoteApiError(errMessage);
            enqueueSnackbar(errMessage, { variant: 'error' });
          });
      }
    }

    const generateMultipleQuotes = React.useCallback(() => {
      setDisableButton(true);
      setLoading(true);

      const payload = {
        accountId: account.id,
        agentFirstName: agent.firstName,
        agentLastName: agent.lastName,
        agentEmail: agent.email,
        agentPhone: agent.phone,
        agencyId: agency.agencyId,
        quoteIds: quotes.map(Ramda.prop('id')),
        teamIds: determineTeamIdOfAgent(),
      };

      generateMultipleQuotesForAccount(payload)
        .then(() => {
          delayedEvent('quote:read');
          setTimeout(() => {
            if (persona === 'admin') {
              props.history.push(`/${persona}/quotes`);
            } else if (persona === 'agency' && agencyDashboardFeature.on) {
              props.history.push(AGENCY_DASHBOARD_BASE_PATH);
            } else {
              props.history.push(`/${persona}/inbox`);
            }
            dispatch(resetAgencyData());
            setDisableButton(false);
            setLoading(false);
            enqueueSnackbar('Quotes Requested Successfully!', {
              variant: 'success',
            });
          }, 3000);
        })
        .catch((error) => {
          setDisableButton(false);
          setLoading(false);
          const errMessage = manageAPIError(error, 'Failed to request quote');

          setQuoteApiError(errMessage);
          enqueueSnackbar(errMessage, { variant: 'error' });
        });
    }, [
      account.id,
      agency.agencyId,
      agencyDashboardFeature,
      agent.email,
      agent.firstName,
      agent.lastName,
      agent.phone,
      determineTeamIdOfAgent,
      dispatch,
      enqueueSnackbar,
      persona,
      props.history,
      quotes,
    ]);

    const onSelect = React.useCallback(
      ({ target }) => {
        if (target.name === 'socialEngLimit' && target.value === 50000) {
          setValue('socialEngDeductible', 5000);
        } else if (target.name === 'socialEngLimit' && target.value !== 50000) {
          setValue('socialEngDeductible', 10000);
        }
        setValue(target.name, target.value);
        PubSub.publish('premium:calculate', {
          accountData: account,
          agencyData: agency,
        });

        // force a render to update bi value
        if (
          target.name === 'limit' ||
          target.name === 'businessIncomeCoverage'
        ) {
          if (target.name === 'limit' && !disableRansom) {
            setUnder250(false);
            setValue('ransomPaymentEndorsement', true);
          }
          setShouldCalculate(new Date().toISOString());
        }
      },
      [account, agency, disableRansom, setValue]
    );

    const addToListHandler = useCallback(() => {
      if (quotes.length >= 5) {
        setCanAdd(false);
        return;
      }
      const { retroActivePeriod, ...formData } = getValues();
      const finalData = _.omit(formData, 'insured');
      const { socialEngg: _socialEngg } = form.getValues();

      addToListQuotes({
        accountId: account.id,
        agencyId: agency.agencyId,
        ...finalData,
        effectiveDate: Moment(formData.effectiveDate).format('YYYY-MM-DD'),
        endDate: Moment(formData.effectiveDate)
          .add(1, 'y')
          .format('YYYY-MM-DD'),
        retroactivePeriod: retroActivePeriod,
        premium,
        socialEngEndorsement: _socialEngg,
        additionalInsureds:
          Array.isArray(AI) && AI.length
            ? AI.map((item) => {
                // eslint-disable-next-line no-shadow
                const { state, naicsCode } = item;
                return {
                  ...item,
                  state,
                  naicsCode,
                  naicsDescription: _.get(
                    naicsCode,
                    'meta.secondaryIndustry',
                    ''
                  ),
                };
              })
            : [],
      })
        .then(() => {
          setCanAdd(false);
          PubSub.publish('table:listQuotes:refetch');
        })
        .catch((error) => {
          setDisableButton(false);
          setLoading(false);
          const errMessage = manageAPIError(error, 'Failed to request quote');

          setQuoteApiError(errMessage);
          enqueueSnackbar(errMessage, { variant: 'error' });
        });

      // eslint-disable-next-line
    }, [premium, AI, quotes]);

    const handleCoverageCategoryChange = useCallback(
      (event, value) => {
        setValue(event.target.name, value);

        const CAcoverages = [
          'postBreachRemediationEndorsement',
          'telecomsFraudEndorsement',
          'hardwareReplCostEndorsement',
        ];
        const mappings = {
          postBreachRemediationEndorsement: 'postBreachRemediationSubLimit',
          telecomsFraudEndorsement: 'telecomsFraudSubLimit',
          hardwareReplCostEndorsement: 'hardwareReplCostSubLimit',
        };

        if (CAcoverages.includes(event.target.name) && value) {
          setValue(mappings[event.target.name], 50000);
        }

        if (event.target.name === 'additionalInsureds') {
          setValue('insured', value ? AI.length : 0);
        }

        setShouldCalculate(new Date().toISOString());
        PubSub.publish('premium:calculate', {
          accountData: account,
          agencyData: agency,
        });
      },
      // eslint-disable-next-line
      [AI, account, agency]
    );

    const onEndorseCancel = () => {
      if (_.isFunction(props.onCancel)) {
        props.onCancel();
      }
    };

    const handleSelectDate = useCallback(
      (date) => {
        setValue('effectiveDate', date);

        setShouldCalculate(new Date().toISOString());
        PubSub.publish('premium:calculate', {
          accountData: account,
          agencyData: agency,
        });

        if (endorsement) {
          updateDate(date);
        }
      },
      [account, agency, endorsement, setValue, updateDate]
    );

    const coverageOptions = useMemo(
      () =>
        calculateCoverageOptions(values, {
          onChange: handleCoverageCategoryChange,
          register,
          onClick: openModal,
          disableSocial,
          disableRansom,
          disableRansomUnder250,
          socialEngEndorsement,
          socialReason,
          disableWebsite,
          coverageList,
          coverageMapping,
          state,
          additionalInsureds: AI,
        }),
      // eslint-disable-next-line
      [values, socialEngEndorsement]
    );

    const biCoverages = useMemo(() => {
      if (coverageEligibility) {
        const sublimits = _.get(
          coverageEligibility,
          'businessIncomeCoverage.values'
        );
        return stringsToOptions(sublimits);
      }

      return generateBiCoverages(values.limit);
    }, [coverageEligibility, values.limit]);

    const socialCoverages = useMemo(() => {
      if (coverageEligibility) {
        const sublimits = _.get(
          coverageEligibility,
          'socialEngEndorsement.limits'
        );
        return stringsToOptions(sublimits);
      }

      return socialLimitCoverages(values.limit, setValue);
    }, [coverageEligibility, values.limit, setValue]);

    const socialDeductible = useMemo(() => {
      if (coverageEligibility) {
        const deductibles = _.get(
          coverageEligibility,
          'socialEngEndorsement.deductibles'
        );
        return stringsToOptions(deductibles);
      }

      return checkSocialDeductible(
        values.socialEngLimit,
        values.deductible,
        setValue
      );
    }, [
      coverageEligibility,
      values.socialEngLimit,
      values.deductible,
      setValue,
    ]);

    const ransomSublimitOptions = useMemo(() => {
      if (coverageEligibility) {
        const sublimits = _.get(
          coverageEligibility,
          'ransomPaymentEndorsement.limits'
        );

        return stringsToOptions(sublimits);
      }

      return determineAvailableSublimits(values.limit);
    }, [coverageEligibility, values.limit]);

    const websiteContentSublimitOptions = useMemo(() => {
      if (coverageEligibility) {
        const sublimits = _.get(
          coverageEligibility,
          'websiteMediaContentLiabilityEndorsement.limits'
        );
        return stringsToOptions(sublimits);
      }

      return determineAvailableSublimits(values.limit);
    }, [coverageEligibility, values.limit]);

    React.useEffect(() => {
      function ensureSublimitSelectionWithinRange(
        fieldName,
        availableSublimitOptions
      ) {
        const highestAvailableSublimitValue =
          availableSublimitOptions?.[availableSublimitOptions?.length - 1]
            ?.value;

        const selectedValue = getValues()[fieldName];

        if (
          highestAvailableSublimitValue &&
          selectedValue > highestAvailableSublimitValue
        ) {
          setValue(fieldName, highestAvailableSublimitValue);
        }
      }

      ensureSublimitSelectionWithinRange(
        'websiteMediaContentLiabilitySubLimit',
        websiteContentSublimitOptions
      );

      ensureSublimitSelectionWithinRange(
        'ransomPaymentLimit',
        ransomSublimitOptions
      );
    }, [
      getValues,
      ransomSublimitOptions,
      setValue,
      websiteContentSublimitOptions,
    ]);

    useDefaultValueForSelection({
      formMethods: { setValue, getValues },
      truthyFieldName: 'ransomPaymentEndorsement',
      selectionFieldName: 'ransomPaymentLimit',
      defaultValue: ransomSublimitOptions?.[0].value,
    });

    useDefaultValueForSelection({
      formMethods: { setValue, getValues },
      truthyFieldName: 'websiteMediaContentLiabilityEndorsement',
      selectionFieldName: 'websiteMediaContentLiabilitySubLimit',
      defaultValue: websiteContentSublimitOptions?.[0].value,
    });

    const availableLimits = useMemo(() => {
      if (coverageEligibility) {
        const limits = _.get(coverageEligibility, 'limit.values');
        return stringsToOptions(limits);
      }

      return aggregateLimits;
    }, [coverageEligibility]);

    const availableDeductibles = useMemo(() => {
      if (coverageEligibility) {
        const deductibles = _.get(coverageEligibility, 'deductible.values');
        return stringsToOptions(deductibles);
      }

      const date = Moment(effectiveDate);

      // early exit due to wrong naics codes || edit
      if (editQuote || (!isEducation && !isPublicAdmin)) {
        return deductibleAmounts;
      }

      // early exit due to before 9/1 -
      if (!date.isSameOrAfter('9/1/2021')) {
        return deductibleAmounts;
      }

      // from here on we can assume effective date is past 9/1
      if (limit < 3000000) {
        setValue('deductible', 25000);
        return [
          { value: 25000, label: '$25,000' },
          { value: 50000, label: '$50,000' },
        ];
      }

      if (limit >= 3000000) {
        setValue('deductible', 50000);
        return [{ value: 50000, label: '$50,000' }];
      }
      // eslint-disable-next-line
    }, [
      effectiveDate,
      naicsCode,
      editQuote,
      limit,
      setValue,
      coverageEligibility,
    ]);

    useEffect(() => {
      const exists = biCoverages.find(
        (option) => option.value === values.businessIncomeCoverage
      );

      if (!exists) {
        setValue('businessIncomeCoverage', _.get(_.last(biCoverages), 'value'));
        setShouldCalculate(new Date().toISOString());
      }
      // eslint-disable-next-line
    }, [biCoverages, values.limit, values.businessIncomeCoverage]);

    useEffect(() => {
      if (values.socialEngLimit === 50000) {
        if (socialDeductible.length === 0) {
          setDisable(true);
          setReason(
            'Social Deductible should be higher or equal to policy deductible.'
          );
        } else {
          setDisable(false);
        }
      } else {
        setDisable(false);
      }
      // eslint-disable-next-line
    }, [socialDeductible, values.socialEngLimit]);

    const hasMultipleQuotes = Boolean(quotes.length);

    register({ name: 'effectiveDate' });
    register({ name: 'hardwareReplCostSubLimit' });
    register({ name: 'telecomsFraudSubLimit' });
    register({ name: 'postBreachRemediationSubLimit' });
    register({ name: 'socialEngLimit' });
    register({ name: 'socialEngDeductible' });
    register({ name: 'additionalInsureds' });
    register({ name: 'insured' });

    const currentYear = Moment([Moment().format('YYYY')]);
    const companyYear = Moment([account.yearEstablished]);
    const yearsInBusiness = currentYear.diff(companyYear, 'years');

    retroActivePeriods = [{ value: 3, label: 'Full Prior Acts' }];
    if (yearsInBusiness >= 3) {
      retroActivePeriods.splice(
        0,
        0,
        { value: 1, label: '1 Year' },
        { value: 2, label: '2 Years' }
      );
    }

    function renderOptions({ label, value }) {
      return (
        <MuiMenuItem className={classes.menuItem} key={label} value={value}>
          {label}
        </MuiMenuItem>
      );
    }

    const calculate = React.useCallback(
      ({ accountData, agencyData }) => {
        if (persona === 'admin' && editQuote) {
          setDisableButton(false);
        } else {
          setDisableButton(true);
        }

        setQuoteApiError('');

        setIsCalculating(true);
        getSocialEngg(accountData.id)
          .then((socialEngResp) => {
            const seQuestions = formatSeQuestions(socialEngResp?.data?.reason);
            const {
              limit: _limit,
              retroActivePeriod,
              insured,
              additionalInsureds,
              ...formData
            } = getValues();

            const { exclude, socialEngg: _socialEngg } = getValues();

            return calculatePricingV2(
              {},
              {
                aggregateLimit: _limit,
                additionalInsureds: AI,
                yearsInBusiness:
                  Moment.utc(formData.effectiveDate).format('YYYY') -
                  accountData.yearEstablished,
                retroactiveYear: retroActivePeriod,
                retroactivePeriod: retroActivePeriod,
                companyType: accountData.ownershipType,
                numberOfEmployees: accountData.noOfEmployeesAll,
                questionLeadership: accountData.isSecurityOfficer,
                questionTraining: accountData.isSecurityTraining,
                questionCloud: accountData.useCloudStorage,
                questionEncryption: accountData.useEncryption,
                excludedFromSE: exclude,
                socialEngEndorsement: _socialEngg,
                revenue: parseInt(Number(accountData.revenue).toPrecision()),
                additionalBrokerFee: agencyData.additionalBrokerFee,
                ..._.pick(accountData, ['naicsCode', 'state']),
                ...formData,
                effectiveDate: Moment(formData.effectiveDate).format(
                  'YYYY-MM-DD'
                ),
                endDate: Moment(formData.effectiveDate)
                  .add(1, 'y')
                  .format('YYYY-MM-DD'),
                yearEstablished: accountData.yearEstablished,
                claimHistory: accountData.claimHistory,
                policyId,
                zipCode: accountData.zipCode,
                ...socialEngResp.data,
                ...seQuestions,
              }
            ).then((response) => {
              setPremium(
                response.data.finalPremium || response.data.totalPremium
              );
              setBrokerFee(response.data.brokerFee);
              setTotalPremium(
                response.data.totalAdjustedPremium || response.data.totalPremium
              );
              setIsCalculating(false);
              setCanAdd(true);
              setDisableButton(false);
            });
          })
          .catch(() => {
            const _formData = getValues();
            const seMeta = form.getValues();
            const isValidSE = validateSE(_formData, seMeta.socialEngg);

            if (!isValidSE) {
              enqueueSnackbar(
                'Social Engineering deductible must be greater than or equal to overall deductible',
                { variant: 'error' }
              );
            } else if (initialLoad) {
              Modal.show('P100CalculatorError');
              setInitialLoad(null);
            } else {
              setDisableButton(false);
            }
          });
      },
      [
        AI,
        editQuote,
        enqueueSnackbar,
        form,
        getValues,
        initialLoad,
        persona,
        policyId,
      ]
    );

    useEffect(() => {
      if (!isEndorsement) {
        const calculateSub = PubSub.subscribe('premium:calculate', calculate);

        return () => {
          calculateSub.remove();
        };
      }
    }, [calculate, isEndorsement]);

    // recalculate when ais change
    useEffect(() => {
      PubSub.publish('premium:calculate', {
        accountData: account,
        agencyData: agency,
      });
    }, [AI, account, agency]);

    const { socialEngg } = form.getValues();

    const {
      ransomPaymentEndorsement,
      websiteMediaContentLiabilityEndorsement,
    } = values;

    const coverageStartDateSelectedDate = values.effectiveDate;

    const datePickerMinDate = () => {
      if (isCowbellPersona) {
        return oneYearInpast.toDate();
      }
      if (endorsement) {
        return props?.endorsementDates?.minEndorsementStartDate;
      }
      return sevenDaysInpast.toDate();
    };

    const datePickerMaxDate = () => {
      if (endorsement) {
        return props?.endorsementDates?.maxEndorsementEndDate;
      }

      return sixtyDaysInFuture.toDate();
    };

    return (
      <div>
        {reQuoteRenew && !endorsement && !editQuote && !renew ? (
          <Breadcrumbs>
            <Link
              to={editQuote ? '/admin/quotes/' : '/agency/inbox'}
              className={`table-heading ${classes.link}`}
            >
              Inbox
            </Link>
            <p className={`table-heading ${classes.breadcrumb}`}>
              Request Quote(s) for {account.name}
            </p>
          </Breadcrumbs>
        ) : null}

        <div className={!endorsement ? classes.wrapper : classes.background}>
          {!reQuoteRenew && !endorsement && (
            <p className={`${classes.breadcrumb} ${classes.company}`}>
              {account.name}
            </p>
          )}
          <MuiGrid container spacing={8}>
            <MuiGrid
              item
              md={12}
              lg={reQuoteRenew || endorsement || editQuote ? 12 : 6}
            >
              <Box
                display="flex"
                justifyContent={editQuote ? 'space-between' : 'flex-start'}
                alignItems="center"
                width="100%"
                className={classes.sectionHeading}
              >
                <h3>Coverage Options</h3>
              </Box>
              <form id="request-single-quote" onSubmit={handleSubmit(onSubmit)}>
                <label
                  htmlFor="effectiveDate"
                  className={`flex--spaced ${classes.labels}`}
                  style={{ fontSize: '1.166rem', color: '#2180e2' }}
                >
                  Coverage Start Date
                  <span className="flex--spaced">
                    <DatePicker
                      id="effectiveDate"
                      customInput={<DateToggle classes={classes} />}
                      onChange={handleSelectDate}
                      minDate={datePickerMinDate()}
                      maxDate={datePickerMaxDate()}
                      selected={coverageStartDateSelectedDate}
                      popperPlacement="bottom-end"
                      popperProps={{ zIndex: 2 }}
                      style={{ flex: 1, textAlign: 'right' }}
                      disabled={renew}
                    />
                  </span>
                </label>

                <label
                  className={`flex--spaced ${classes.labels}`}
                  htmlFor="limit"
                >
                  Aggregate Limit
                  <MuiSelect
                    variant="standard"
                    name="limit"
                    ref={register({ name: 'limit' })}
                    onChange={onSelect}
                    defaultValue={values.limit}
                    classes={{
                      select: classes.select,
                      icon: classes.selectIcon,
                    }}
                  >
                    {availableLimits.map(renderOptions)}
                  </MuiSelect>
                </label>

                <label
                  className={`flex--spaced ${classes.labels}`}
                  htmlFor="deductible"
                >
                  Deductible Amount
                  <MuiSelect
                    variant="standard"
                    name="deductible"
                    ref={register({ name: 'deductible' })}
                    onChange={onSelect}
                    defaultValue={values.deductible}
                    value={deductible}
                    classes={{
                      select: classes.select,
                      icon: classes.selectIcon,
                    }}
                  >
                    {availableDeductibles.map(renderOptions)}
                  </MuiSelect>
                </label>

                <div className={classes.graphContainer}>
                  <CoverageChart
                    data={values}
                    social={socialEngEndorsement}
                    ransom={ransomPaymentEndorsement}
                    website={websiteMediaContentLiabilityEndorsement}
                    exclude={excludedFromSE}
                    editable
                    readOnly={false}
                    options={coverageOptions}
                    endorsement={endorsement}
                    coverageEligibility={coverageEligibility}
                    renderSublimit={(chartProps) => (
                      <>
                        <MuiGrid
                          item
                          sm={7}
                          className={chartProps.classes.gridItem}
                        >
                          <span className={classes.indentedLabel}>
                            Select Sublimit
                          </span>
                        </MuiGrid>
                        <MuiGrid
                          item
                          sm={5}
                          className={chartProps.classes.gridItem}
                          style={{ justifyContent: 'flex-end' }}
                        >
                          <MuiSelect
                            variant="standard"
                            name="businessIncomeCoverage"
                            ref={register({
                              name: 'businessIncomeCoverage',
                              type: 'custom',
                            })}
                            onChange={onSelect}
                            defaultValue={values.businessIncomeCoverage}
                            value={values.businessIncomeCoverage}
                            classes={{
                              select: classes.select,
                              icon: classes.selectIcon,
                            }}
                            style={{
                              position: 'relative',
                              zIndex: 1,
                              marginTop: -10,
                            }}
                          >
                            {biCoverages.map(renderOptions)}
                          </MuiSelect>
                        </MuiGrid>
                      </>
                    )}
                    renderRansomlimit={(chartProps) =>
                      !disableRansom &&
                      ransomPaymentEndorsement && (
                        <>
                          <MuiGrid
                            item
                            sm={7}
                            className={chartProps.classes.gridItem}
                          >
                            <span className={classes.indentedLabel}>
                              Select Sublimit
                            </span>
                          </MuiGrid>
                          <MuiGrid
                            item
                            sm={5}
                            className={chartProps.classes.gridItem}
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <MuiSelect
                              variant="standard"
                              name="ransomPaymentLimit"
                              ref={register({
                                name: 'ransomPaymentLimit',
                                type: 'custom',
                              })}
                              onChange={onSelect}
                              value={ransomPaymentLimit ?? 0}
                              classes={{
                                select: classes.select,
                                icon: classes.selectIcon,
                              }}
                              style={{
                                position: 'relative',
                                zIndex: 1,
                                marginTop: -10,
                              }}
                            >
                              {ransomSublimitOptions.map(renderOptions)}
                            </MuiSelect>
                          </MuiGrid>
                        </>
                      )
                    }
                    renderWebsiteLimit={(chartProps) =>
                      !disableWebsite &&
                      websiteMediaContentLiabilityEndorsement && (
                        <>
                          <MuiGrid
                            item
                            sm={7}
                            className={chartProps.classes.gridItem}
                          >
                            <span className={classes.indentedLabel}>
                              Select Sublimit
                            </span>
                          </MuiGrid>
                          <MuiGrid
                            item
                            sm={5}
                            className={chartProps.classes.gridItem}
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <MuiSelect
                              variant="standard"
                              name="websiteMediaContentLiabilitySubLimit"
                              ref={register({
                                name: 'websiteMediaContentLiabilitySubLimit',
                                type: 'custom',
                              })}
                              onChange={onSelect}
                              value={websiteMediaContentLiabilitySubLimit ?? 0}
                              classes={{
                                select: classes.select,
                                icon: classes.selectIcon,
                              }}
                              style={{
                                position: 'relative',
                                zIndex: 1,
                                marginTop: -10,
                              }}
                            >
                              {websiteContentSublimitOptions.map(renderOptions)}
                            </MuiSelect>
                          </MuiGrid>
                        </>
                      )
                    }
                    renderSocialLimit={(chartProps) =>
                      !disableSocial && socialEngg ? (
                        <>
                          <MuiGrid
                            item
                            sm={7}
                            className={chartProps.classes.gridItem}
                          >
                            <span className={classes.indentedLabel}>
                              Select Sublimit
                            </span>
                          </MuiGrid>
                          <MuiGrid
                            item
                            sm={5}
                            className={chartProps.classes.gridItem}
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <MuiSelect
                              variant="standard"
                              name="socialEngLimit"
                              ref={register({
                                name: 'socialEngLimit',
                                type: 'custom',
                              })}
                              onChange={onSelect}
                              defaultValue={values.socialEngLimit}
                              value={values.socialEngLimit}
                              classes={{
                                select: classes.select,
                                icon: classes.selectIcon,
                              }}
                              style={{
                                position: 'relative',
                                zIndex: 1,
                                marginTop: -10,
                              }}
                            >
                              {socialCoverages.map(renderOptions)}
                            </MuiSelect>
                          </MuiGrid>
                        </>
                      ) : null
                    }
                    renderSocialDeductible={(chartProps) =>
                      !disableSocial && socialEngg ? (
                        <>
                          <MuiGrid
                            item
                            sm={7}
                            className={chartProps.classes.gridItem}
                          >
                            <span className={classes.indentedLabel}>
                              Select Deductible
                            </span>
                          </MuiGrid>
                          <MuiGrid
                            item
                            sm={5}
                            className={chartProps.classes.gridItem}
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <MuiSelect
                              variant="standard"
                              name="socialEngDeductible"
                              ref={register({
                                name: 'socialEngDeductible',
                                type: 'custom',
                              })}
                              onChange={onSelect}
                              defaultValue={values.socialEngDeductible}
                              value={values.socialEngDeductible}
                              classes={{
                                select: classes.select,
                                icon: classes.selectIcon,
                              }}
                              style={{
                                position: 'relative',
                                zIndex: 1,
                                marginTop: -10,
                              }}
                            >
                              {socialDeductible.map(renderOptions)}
                            </MuiSelect>
                          </MuiGrid>
                        </>
                      ) : null
                    }
                  />
                </div>

                <label
                  className={`flex--spaced ${classes.labels}`}
                  htmlFor="waitingPeriod"
                >
                  Waiting Period
                  <MuiSelect
                    variant="standard"
                    name="waitingPeriod"
                    ref={register({ name: 'waitingPeriod' })}
                    onChange={onSelect}
                    value={values.waitingPeriod}
                    classes={{
                      select: classes.select,
                      icon: classes.selectIcon,
                    }}
                  >
                    {_waitingPeriods.map(renderOptions)}
                  </MuiSelect>
                </label>

                <label
                  className={`flex--spaced ${classes.labels}`}
                  htmlFor="retroActivePeriod"
                >
                  Retro Active Period
                  <MuiSelect
                    variant="standard"
                    name="retroActivePeriod"
                    ref={register({ name: 'retroActivePeriod' })}
                    onChange={onSelect}
                    defaultValue={values.retroActivePeriod}
                    classes={{
                      select: classes.select,
                      icon: classes.selectIcon,
                    }}
                  >
                    {_retroactivePeriods.map(renderOptions)}
                  </MuiSelect>
                </label>
                {!endorsement && (
                  <h5
                    className={`flex--spaced ${classes.price}`}
                    style={{ alignItems: 'baseline' }}
                  >
                    <span>Indicated Pricing</span>
                    <span style={{ textAlign: 'right', marginRight: 25 }}>
                      {isCalculating && <MuiCircularProgress size={14} />}
                      {Numeral(premium).format('$0,0.00')}
                    </span>
                  </h5>
                )}

                {isVerify ? (
                  <div
                    className={classes.flexBox}
                    style={{ justifyContent: 'center' }}
                  >
                    <CbButton
                      styleName="cancel"
                      style={{ margin: '0 0.5rem' }}
                      onClick={() =>
                        props.history.push('/admin/quotes?f[isVerified][bo]=0')
                      }
                    >
                      Cancel
                    </CbButton>
                    <CbButton
                      style={{ margin: '0 0.5rem' }}
                      styleName="ctaButton"
                      type="submit"
                      loading={loading && !verify}
                      disabled={disableButton}
                    >
                      Save
                    </CbButton>
                    <CbButton
                      style={{ margin: '0 0.5rem' }}
                      styleName="ctaButton"
                      type="submit"
                      onClick={() => setVerify(true)}
                      loading={loading && verify}
                      disabled={disableButton}
                    >
                      Verify
                    </CbButton>
                  </div>
                ) : (
                  <>
                    <div className={classes.flexBox}>
                      {!endorsement && (
                        <CbButton
                          form="request-single-quote"
                          disabled={hasMultipleQuotes || disableButton}
                          loading={!hasMultipleQuotes && loading}
                          type="submit"
                          styleName="ctaButton"
                          buttonText={
                            editQuote ? 'Update Quote' : 'Request A Quote'
                          }
                          size="large"
                        />
                      )}
                    </div>
                    {endorsement && (
                      <div
                        style={{
                          textAlign: 'right',
                          justifyContent: 'space-between',
                        }}
                      >
                        <CbButton
                          form="request-single-quote"
                          type="submit"
                          styleName="cancel"
                          buttonText="Back"
                          onClick={onEndorseCancel}
                          style={{ margin: '0 1.66rem' }}
                        />
                        <CbButton
                          form="request-single-quote"
                          type="submit"
                          styleName="ctaButton"
                          buttonText="Next"
                        />
                      </div>
                    )}
                  </>
                )}
              </form>
            </MuiGrid>

            {reQuoteRenew || endorsement || editQuote ? null : (
              <MuiGrid item md={6} classes={{ root: classes.quoteList }}>
                <h3 className={classes.sectionHeading}>
                  List of Quotes to Request
                </h3>
                <div>
                  <QuotesListTable
                    accountId={account.id}
                    dates={handleDates}
                    canAdd={setCanAdd}
                    agencyId={agency.agencyId}
                  />
                </div>
                <div className={classes.flexBox} style={{}}>
                  <CbButton
                    onClick={generateMultipleQuotes}
                    disabled={!hasMultipleQuotes || disableButton}
                    loading={hasMultipleQuotes && loading}
                    type="submit"
                    styleName="ctaButton"
                    buttonText="Request Above Quotes"
                    size="large"
                  />
                </div>
              </MuiGrid>
            )}

            <MuiGrid item md={12}>
              <FormHelperText className={classes.apiError}>
                {quoteApiError}
              </FormHelperText>
            </MuiGrid>
          </MuiGrid>

          {reQuoteRenew || endorsement || editQuote ? null : (
            <div className={classes.centerActions}>
              <SimpleTooltip
                className={classes.addToListIcon}
                title={
                  !canAdd
                    ? 'Change Coverage Options to Add to List!'
                    : 'Add to List'
                }
                hideInfoIcon
              >
                <MuiButton disabled={!canAdd} onClick={addToListHandler}>
                  <AddToListIcon
                    fill={
                      canAdd
                        ? theme.palette.common.cowbellBlue
                        : theme.palette.text.disabled
                    }
                  />
                </MuiButton>
              </SimpleTooltip>
            </div>
          )}
        </div>
      </div>
    );
  }
);

function handleDates() {
  // console.log('handleDates');
}

function generateBiCoverages(limit) {
  const biCoverages = [];
  const million = 1000000;

  let coverageAmt = 0;
  let coveragePct = 10;

  while (coverageAmt < million && coverageAmt < limit) {
    coverageAmt = (limit * coveragePct) / 100;
    coveragePct += 5;

    coverageAmt = coverageAmt > million ? million : coverageAmt;
    biCoverages.push({
      value: coverageAmt,
      label: Numeral(coverageAmt).format('$0,0'),
    });
  }

  return biCoverages;
}

function checkSocialDeductible(socialEngLimit, deductible, setValue) {
  const finalSocialDeductible = [];

  if (socialEngLimit === 50000) {
    _.remove(social50LimitDeductible, function (n) {
      if (n.value >= deductible) {
        finalSocialDeductible.push(n);
      }
    });
    if (finalSocialDeductible.length === 0) {
      PubSub.publish('set-social', false);
    } else {
      setValue('socialEngDeductible', finalSocialDeductible[0].value);
    }
  } else {
    _.remove(socialDeductibles, function (n) {
      if (n.value >= deductible) {
        finalSocialDeductible.push(n);
      }
    });

    setValue('socialEngDeductible', _.get(finalSocialDeductible[0], 'value'));
  }
  return finalSocialDeductible;
}

function socialLimitCoverages(aggregate, setValue) {
  let finalSocialLimit = [];

  if (aggregate === 50000) {
    finalSocialLimit.push(socialLimits[0]);
    setValue('socialEngLimit', socialLimits[0].value);
  } else if (aggregate === 100000) {
    finalSocialLimit.push(socialLimits[0], socialLimits[1]);
  } else {
    finalSocialLimit = socialLimits;
  }
  return finalSocialLimit;
}

function determineAvailableSublimits(aggregateLimit) {
  return websiteLimits.filter((sublimitOption) => {
    return sublimitOption.value <= aggregateLimit;
  });
}

function useMultipleQuotesSub(setQuotes) {
  useEffect(() => {
    const quoteSub = PubSub.subscribe('quote-list:retrieved', (quotes) => {
      setQuotes(quotes);
    });

    return () => {
      quoteSub.remove();
    };
    // eslint-disable-next-line
  }, []);
}

export default GenerateQuoteModal;

const validateSE = (formData, hasEndorsement) => {
  if (!hasEndorsement) {
    return true;
  }
  const isValidSELimit = formData.limit >= formData.socialEngLimit;
  const isValidSEDeductible =
    formData.socialEngDeductible >= formData.deductible;
  return isValidSELimit && isValidSEDeductible;
};

const stringsToOptions = (strings = []) => {
  return strings.map((string) => ({
    label: numberToCurrency(string),
    value: string,
  }));
};

const formatSeQuestions = (questions = []) => {
  if (!Array.isArray(questions)) {
    return [];
  }
  return questions.reduce((acc, question) => {
    return { ...acc, [question]: true };
  }, {});
};

// useful for setting a default value for selection inputs that are dependant on the truthyness of some other field's value
function useDefaultValueForSelection({
  formMethods,
  truthyFieldName,
  selectionFieldName,
  defaultValue,
}) {
  const { setValue } = formMethods;

  const {
    [truthyFieldName]: truthyFieldVal,
    [selectionFieldName]: selectionFieldVal,
  } = formMethods.getValues();

  React.useEffect(() => {
    if (truthyFieldVal === true && !selectionFieldVal) {
      setValue(selectionFieldName, defaultValue);
    }
  }, [
    setValue,
    defaultValue,
    selectionFieldName,
    selectionFieldVal,
    truthyFieldVal,
  ]);
}
