import * as Yup from 'yup';
// react
import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
// mui
import {
  Grid as MuiGrid,
  DialogContent,
  FormHelperText,
  DialogActions,
} from '@mui/material';
// components
import Moment from 'moment';
import { withFormController } from '../../../../../components/hocs/withFormController';
import { TextFieldBase } from '../../../../../components/inputs/TextFieldBase';
import CbButton from '../../../../../components/Buttons/CbButton';
import LanguageCurrencyFieldBase from '../../../../../components/inputs/LanguageCurrencyField';
// actions

const TextField = withFormController(TextFieldBase);
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);

const schema = Yup.object().shape({
  policyNumber: Yup.string().required().label('Policy Number'),
  additionalPremium: Yup.string()
    .min(0, 'Additional Premium must be at least 1 characters')
    .required('Additional Premium is required'),
  returnPremium: Yup.string()
    .min(0, 'Return Premium must be at least 1 characters')
    .required('Return Premium is required'),
  effectiveDateOfPolicy: Yup.string()
    .label('Effective Date of Policy')
    .required(),
  expirationDateOfPolicy: Yup.string()
    .label('Expiration Date Of Policy')
    .required(),
  effectiveDateOfEndorsement: Yup.string()
    .label('Effective Date of Endorsement')
    .required(),
});

export const AmendmentRetroactiveDate = ({
  dispatch,
  close,
  endorsementType,
}) => {
  const [error] = useState('');
  const {
    handleSubmit,
    formState: { isSubmitting, dirty },
    ...methods
  } = useForm({
    defaultValues: {
      policyNumber: 'D98344fsaf',
      additionalPremium: 3000000,
      returnPremium: 50000000,
      effectiveDateOfPolicy: '',
      expirationDateOfPolicy: '',
      effectiveDateOfEndorsement: '',
    },
    validationSchema: schema,
  });

  const onSubmit = (formData) => {
    dispatch({
      type: 'addEndorsement',
      payload: {
        endorsementType,
        value: [
          [
            {
              label: 'Policy Number',
              value: formData.policyNumber,
            },
            {
              label: 'Effective Date of Policy',
              value: formData.effectiveDateOfPolicy,
            },
            {
              label: 'Expiration Date of Policy',
              value: formData.expirationDateOfPolicy,
            },
          ],
          [
            {
              label: 'Effective Date of Endorsement',
              value: formData.effectiveDateOfEndorsement,
            },
            {
              label: 'Additional Premium',
              value: formData.additionalPremium,
            },
            {
              label: 'Return Premiun',
              value: formData.returnPremium,
            },
          ],
        ],
      },
    });
    close();
  };

  return (
    <FormContext {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-qa="amendmentRetroactiveDate"
      >
        <DialogContent style={{ padding: '2rem 5rem', overflow: 'visible' }}>
          <MuiGrid container spacing={5}>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="policyNumber"
                label="Policy Number"
                fullWidth
                inputProps={{ required: true }}
              />
            </MuiGrid>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="effectiveDateOfPolicy"
                label="Effective Date of Policy"
                InputProps={{
                  inputProps: { min: Moment().format('YYYY-MM-DD') },
                }}
                required
                type="date"
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={5}>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <LanguageCurrencyField
                label="Additional Premium"
                name="additionalPremium"
              />
            </MuiGrid>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="expirationDateOfPolicy"
                label="Expiration Date of Policy"
                InputProps={{
                  inputProps: { min: Moment().format('YYYY-MM-DD') },
                }}
                required
                type="date"
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={5}>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <LanguageCurrencyField
                label="Return Premium"
                name="returnPremium"
              />
            </MuiGrid>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="effectiveDateOfEndorsement"
                label="Effective Date of Endorsement"
                InputProps={{
                  inputProps: { min: Moment().format('YYYY-MM-DD') },
                }}
                required
                type="date"
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Save"
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};
