import { createAction, handleActions } from 'redux-actions';

export interface GlobalStoredTeam {
  id: string;
  name: string;
}

export interface TeamDto extends GlobalStoredTeam {
  isDefault: boolean;
  location: boolean;
}

const SET_TEAM = 'SET_TEAM';

export const setGlobalTeam = createAction(SET_TEAM);

// selectors
/**
 * @name selectGlobalTeam
 * @description Selects the currently set team, this looks useless
 * but if we ever change store shape we want a way to reliably select the team
 */
export const selectGlobalTeam = (state: {
  [key: string]: any;
}): GlobalStoredTeam => state.team;

export const selectGlobalTeamAsDropdownOption = (state: {
  [key: string]: any;
}) => ({
  label: state.team.name,
  value: state.team.id,
});

export const initialState: GlobalStoredTeam = {
  id: 'all',
  name: 'All',
};

export const GlobalTeamReducer = handleActions(
  {
    [SET_TEAM]: (state: GlobalStoredTeam, action): GlobalStoredTeam => {
      return action.payload;
    },
  },
  initialState
);
