import React, { useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { useQuery } from '@tanstack/react-query';
// helpers
import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// material ui
import {
  DialogContent,
  DialogActions,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  Grid as MuiGrid,
  Divider,
  Checkbox as MuiCheckbox,
  ListItemText,
  CircularProgress,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

// platform helpers
import { yupResolver } from '@hookform/resolvers/yup';
import { renderOptions } from '../utils/render.utils';
import { withShowable } from '../console/_global/lib/withShowable';
import { delayedEvent } from '../utils/eventUtils';
import { UsStatesFull } from '../utils/USState';
// components
import { CoverageColumnSelection } from './CoverageColumnSelection';
import CbButton from '../components/Buttons/CbButton';
import { TextFieldBase } from '../components/inputs/TextFieldBase';
import CheckBoxBase from '../components/inputs/Checkbox';
import { InputLabelBase } from '../components/inputs/InputLabelBase';
// actions
import {
  getAvailableProducts,
  getCoveragesPerProduct,
} from '../accounts/AccountService';
import { updateState, createState, fetchTimeZones } from './StateService';
import {
  createCoverages,
  getLabel,
  grouped,
  mergeCoveragesWithOrdinals,
  specialCases,
} from './states.utils';
import { ProductTypes } from '../types';
import { withFormController } from '../components/hocs/forms';

const TextField = withFormController(TextFieldBase);
const ViewGrid = withShowable(MuiGrid);

const schema = Yup.object().shape({
  stateNameAbbrv: Yup.string().ensure().required('State is required'),
  timeZone: Yup.string().ensure().required('Timezone is required'),
  products: Yup.string().ensure().required('Product is required'),
});

export const StateCreate = ({ close, data }) => {
  const classes = styles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const formLabelPropsSelected = { classes: { label: classes.coverageLabel } };
  const formLabelPropsReg = { classes: { label: classes.colorContrast } };

  const edit = !_.isEmpty(data);

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      stateNameAbbrv: edit ? data.stateNameAbbrv : '',
      timeZone: data.timeZone || '',
      products: data.products || [],
      lossCostModifier: data.lossCostModifier || '',
      cancellationDays: data.cancellationDays || '',
      isSupported: data.isSupported || true,
      coverages100: data.coverages100 || [],
      coverages250: data.coverages250 || [],
    },
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting },
    getValues,
  } = methods;

  const values = getValues();

  const [coveragesList, setCoveragesList] = useState([]);
  const formLabelProps = values.selectAll
    ? formLabelPropsSelected
    : formLabelPropsReg;
  const checkStyle = values.selectAll
    ? theme.config.colors.cowbellBlue
    : theme.palette.text.primary;

  methods.watch(['stateNameAbbrv', 'timeZone', 'products', 'isSupported']);

  React.useEffect(() => {
    methods.register('coverages100');
    methods.register('coverages250');
    if (edit) {
      call(values.products);
    }
    // eslint-disable-next-line
  }, [edit]);

  const { data: timeZonesList = [] } = useQuery(
    ['timeZones'],
    () => {
      return fetchTimeZones().then((result) => result.data);
    },
    { retry: false, refetchOnWindowFocus: false }
  );

  const { data: productList = [] } = useQuery(
    ['products'],
    () => {
      return getAvailableProducts().then((result) => result.data);
    },
    { retry: false, refetchOnWindowFocus: false }
  );

  const call = useCallback(
    _.debounce((prod) => {
      return prod.map((product) => {
        setCoveragesList([]);

        return getCoveragesPerProduct({ product }).then((resp) => {
          const v = mergeCoveragesWithOrdinals(
            resp.data,
            values[getLabel(product)]
          );
          Object.entries(v).map((key) => {
            if (
              prod.includes(ProductTypes.p250) &&
              product === ProductTypes.p250S
            )
              return;
            methods.register(`${key[0]}`);
            methods.setValue(`${key[0]}`, key[1]);
            return null;
          });

          setCoveragesList((prevState) => {
            const c = {
              product,
              data: grouped(resp.data),
            };

            return [...prevState, c];
          });
        });
      });
    }, 500),
    []
  );

  const renderMultiOptions = (option) => {
    let nextOptions = option;

    if (typeof option === 'string') {
      nextOptions = { label: option, value: option };
    }

    return (
      <MuiMenuItem key={nextOptions.label} value={nextOptions.value}>
        <MuiCheckbox
          checked={values.products.indexOf(nextOptions.label) > -1}
        />
        <ListItemText primary={nextOptions.label.split('_').join(' ')} />
      </MuiMenuItem>
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    methods.setValue(name, value);
    if (name === 'products') {
      call(value);
    }
  };

  const handleSelectAll = (event) => {
    methods.setValue(event.target.name, event.target.checked);

    coveragesList.forEach((option) => {
      if (`${option.product}-selectAll` === event.target.name) {
        Object.entries(option.data).map((sections) => {
          return sections[1].forEach((coverage) => {
            return methods.setValue(coverage.name, event.target.checked);
          });
        });
      }
    });
  };

  const handleCheckbox = (event) => {
    methods.setValue(event.target.name, event.target.checked);
  };

  const onSubmit = (formData) => {
    const { stateNameAbbrv, timeZone, isSupported } = formData;
    const finalCoverages = createCoverages(coveragesList, values, formData);

    const payload = {
      id: data.id,
      stateNameAbbrv,
      timeZone,
      products: values.products,
      isSupported,
      ...finalCoverages,
    };

    const api = edit ? updateState : createState;

    return api({}, payload)
      .then(() => {
        delayedEvent('table-refetch', 500, 'states');
        enqueueSnackbar(
          edit ? 'State  Updated Successfully' : 'State  Created Successfully',
          { variant: 'success' }
        );
        close();
      })
      .catch((error) => {
        enqueueSnackbar(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong.Please try again later'
            )
          ),
          { variant: 'error' }
        );
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2rem 5rem', overflow: 'visible' }}>
          <MuiGrid container spacing={6} justifyContent="center">
            <MuiGrid item md={8}>
              {!edit ? (
                <>
                  <InputLabelBase required className={classes.label}>
                    State
                  </InputLabelBase>
                  <MuiSelect
                    variant="standard"
                    name="stateNameAbbrv"
                    required
                    {...methods.register('stateNameAbbrv')}
                    value={values.stateNameAbbrv}
                    classes={{
                      select: classes.select,
                      icon: classes.selectIcon,
                    }}
                    onChange={handleChange}
                  >
                    {UsStatesFull.map(renderOptions)}
                  </MuiSelect>
                </>
              ) : (
                <TextField
                  name="stateNameAbbrv"
                  value={values.stateNameAbbrv}
                  disabled
                  style={{ marginTop: '2.5rem' }}
                />
              )}
              {!values.stateNameAbbrv && (
                <FormHelperText>
                  {' '}
                  {_.get(methods, 'errors.stateNameAbbrv.message')}
                </FormHelperText>
              )}
            </MuiGrid>

            <MuiGrid
              item
              md={4}
              style={{ alignSelf: 'center', whiteSpace: 'nowrap' }}
            >
              <CheckBoxBase
                name="isSupported"
                {...methods.register('isSupported')}
                defaultChecked
                checked={values.isSupported}
                label="Is State Supported?"
                onChange={handleCheckbox}
              />
            </MuiGrid>
          </MuiGrid>

          <MuiGrid container spacing={6} justifyContent="center">
            <MuiGrid item md={8}>
              <InputLabelBase required className={classes.label}>
                Timezone
              </InputLabelBase>
              <MuiSelect
                variant="standard"
                name="timeZone"
                required
                onChange={handleChange}
                value={values.timeZone}
                classes={{
                  select: classes.select,
                  icon: classes.selectIcon,
                }}
                {...methods.register('timeZone')}
              >
                {timeZonesList &&
                  timeZonesList.map((timezone) => {
                    return (
                      <MenuItem key={timezone} value={timezone}>
                        {timezone}
                      </MenuItem>
                    );
                  })}
              </MuiSelect>
              {!values.timeZone && (
                <FormHelperText>
                  {' '}
                  {_.get(methods, 'errors.timeZone.message')}
                </FormHelperText>
              )}
            </MuiGrid>

            <MuiGrid item md={4}>
              <InputLabelBase required className={classes.label}>
                Product
              </InputLabelBase>
              <MuiSelect
                variant="standard"
                name="products"
                renderValue={(selected) => selected.join(', ')}
                required
                {...methods.register('products')}
                value={values.products}
                multiple
                classes={{
                  select: classes.select,
                  icon: classes.selectIcon,
                }}
                onChange={handleChange}
              >
                {productList.map(renderMultiOptions)}
              </MuiSelect>
              {_.isEmpty(values.products) && (
                <FormHelperText>
                  {' '}
                  {_.get(methods, 'errors.products.message')}
                </FormHelperText>
              )}
            </MuiGrid>
          </MuiGrid>

          {_.get(values, 'products.length', 0) !==
            _.get(coveragesList, 'length', 0) && (
            <CircularProgress
              color="inherit"
              size="8rem"
              thickness="2"
              classes={{ root: classes.progress }}
            />
          )}

          <ViewGrid
            container
            spacing={1}
            show={
              _.get(values, 'products.length', 0) ===
              _.get(coveragesList, 'length', 0)
            }
            direction="column"
          >
            {coveragesList.map((option, index) => {
              const specialCondition = specialCases(option, values);
              if (specialCondition) return;

              const isReadOnly = option.product === ProductTypes.p100_pro;

              return (
                <div key={index}>
                  <Divider className={classes.divider} />
                  <MuiGrid container direction="column">
                    <MuiGrid container>
                      <MuiGrid item md={6}>
                        <h3 className={classes.headings}>
                          Enable coverages for{' '}
                          {option.product.split('_').join(' ')}
                        </h3>
                      </MuiGrid>
                      <MuiGrid item md={6}>
                        <CheckBoxBase
                          id={`${option.product}-selectAll`}
                          label="Select All"
                          {...methods.register(`${option.product}-selectAll`)}
                          value={values[`${option.product}-selectAll`]}
                          name={`${option.product}-selectAll`}
                          formLabelProps={formLabelProps}
                          style={{ color: checkStyle }}
                          onChange={handleSelectAll}
                          disabled={isReadOnly}
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid>
                      {Object.entries(option.data).map((coverages) => {
                        return (
                          <>
                            <h3
                              className={classes.headings}
                              style={{ padding: '1rem 0' }}
                            >
                              {coverages[0]}
                            </h3>
                            <MuiGrid
                              container
                              spacing={2}
                              direction="row"
                              key={index}
                            >
                              {coverages[1].map((value) => {
                                return (
                                  <MuiGrid key={value.name} item md={4}>
                                    <CoverageColumnSelection
                                      coverage={value}
                                      disabled={isReadOnly}
                                    />
                                  </MuiGrid>
                                );
                              })}
                            </MuiGrid>
                          </>
                        );
                      })}
                    </MuiGrid>
                  </MuiGrid>
                </div>
              );
            })}
          </ViewGrid>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" action={close}>
            Cancel
          </CbButton>
          <CbButton
            loading={isSubmitting}
            styleName="ctaButton"
            buttonText={edit ? 'Update State' : 'Add State'}
            type="submit"
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const styles = makeStyles(({ config, palette }) => {
  return {
    select: {
      justifyContent: 'left',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    label: {
      padding: '0 0 0.5rem',
      fontSize: config.textSizes.normal,
    },
    headings: {
      color: palette.primary.contrastText,
      marginTop: '0.3rem',
      marginBottom: 0,
      fontWeight: 600,
    },
    divider: {
      marginTop: '2.5rem',
      marginBottom: '1.6666666667rem',
    },
    progress: {
      margin: 'auto',
      position: 'relative',
      display: 'flex',
      marginTop: '3rem',
      marginBottom: '1rem',
    },
  };
});

export const StateCreateConfig = {
  StateCreate: {
    component: StateCreate,
    config: {
      fullWidth: true,
      maxWidth: 'md',
      PaperProps: { style: { overflow: 'visible' } },
    },
  },
};
