import React from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';

// helpers
import Color from 'color';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

// mui
import {
  DialogActions,
  DialogContent,
  styled,
  Checkbox,
  Divider,
  Grid,
  Button,
  Box,
  RadioGroup as RadioGroupBase,
  FormControlLabel,
  FormControl,
  Radio,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// components
import { ErrorMessage } from '@hookform/error-message';
import { IfFeatureEnabled, useFeature } from '@growthbook/growthbook-react';
import CbButtonBase from '../../../../components/Buttons/CbButton';
import { InputLabelBase } from '../../../../components/inputs/InputLabelBase';

// hocs
import { withUnderwritingSubjectivitiesDependencies } from './UnderwritingSubjectivitiesDependencies';

// utils
import {
  checkSubjectivityOnOneQuoteFromAll,
  formatUWSubjectivityPayload,
  SubjectivitySubType,
} from '../utils/bindQuoteWorkFlowUtils';
import { manageAPIError } from '../../../../utils';
import { withFormController } from '../../../../components/hocs/forms';
import { withShowable } from '../../../_global/lib/withShowable';
import { Modal } from '../../../../components/modals/v2/helpers/v2.modal.helpers';

// apis
import { saveSubjectivitiesFromUW } from '../../../../inbox/QuotesService';
import { createManuscriptSubjectivity } from '../../../../api';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import Showable from '../../../../components/Showable';
import policiesService from '../../../policies/policies.service';
import {
  CANCELLED_STATUS,
  EXPIRED_STATUS,
  RENEWED_STATUS,
  VOID_STATUS,
  SIGNED_STATUS,
} from '../../../_statics/policy.statics';

import { WaiveSubjectivityModalConfig } from '../../../../components/modals/v2/subjectivities/WaiveSubjectivityModal';
import { CowbellBindQuoteModal } from '../../../cowbell/modals/bind-quote/CowbellBindQuoteModal';
import { useAPIErrorHandler } from '../../../../components/hooks/useAPIErrorHandler';
import { PERSONA_TYPES } from '../../../_statics/persona.statics';
import { FEATURE_FLAG_KEYS } from '../../../../config/growthbook';

const TextField = withFormController(TextFieldBase);
const RadioGroup = withFormController(RadioGroupBase);
const CbButton = withShowable(CbButtonBase);

export const UnderwritingSubjectivities =
  // fetching the required data
  withUnderwritingSubjectivitiesDependencies()(
    ({ availableSubjectivities, quoteId, close, ...props }) => {
      const [completeModal, setCompleteModal] = React.useState({
        subjectivityKey: '',
        isOpen: false,
      });
      const { data: underwritingSubjectivitiesModalData } = props;
      const { productType } = underwritingSubjectivitiesModalData;

      const { enqueueSnackbar } = useSnackbar();
      const queryClient = useQueryClient();
      const handleAPIError = useAPIErrorHandler();

      const isCowbellBindQuoteWorkflowFFEnabled = useFeature(
        FEATURE_FLAG_KEYS.COWBELL_BIND_QUOTE_WORKFLOW
      ).on;

      const {
        handleSubmit,
        formState: { isSubmitting },
        ...methods
      } = useForm({
        defaultValues: availableSubjectivities.formattedData, // formatted fetched subjectivities for default values
      });

      const readOnly =
        policiesService.isActivePolicyByStatus(props.data.quoteStatus) ||
        [
          RENEWED_STATUS,
          CANCELLED_STATUS,
          EXPIRED_STATUS,
          VOID_STATUS,
          SIGNED_STATUS,
        ].includes(props.data.quoteStatus);

      const values = methods.getValues();

      const onSubmit = (formValues) => {
        // formatting form values for the api
        const payload = formatUWSubjectivityPayload(
          availableSubjectivities.sortedData,
          formValues
        );

        return saveSubjectivitiesFromUW(
          { productType, quoteId },
          { subjectivitySettings: payload }
        )
          .then(() => {
            close();
            enqueueSnackbar('Subjectivities saved successfully', {
              variant: 'success',
            });
            queryClient.invalidateQueries(['uw-subjectivities', quoteId]);
          })
          .catch(handleAPIError('There was a problem saving subjectivities.'));
      };

      const manuscriptFormMethods = useForm({
        resolver: yupResolver(ManuscriptedSubjectivityContainerSchema),
      });

      const fieldArrayMethods = useFieldArray({
        control: manuscriptFormMethods.control,
        name: 'manuscriptSubjectivities',
      });

      const handleAddManuscriptSubjectivity = () => {
        fieldArrayMethods.append({ title: '', description: '', subType: '' });
      };
      const scrollableRef = React.useRef();

      const handleWaive = ({ description, id, subType }) => {
        const payload = formatUWSubjectivityPayload(
          availableSubjectivities.sortedData,
          values
        );

        return saveSubjectivitiesFromUW(
          { productType, quoteId },
          { subjectivitySettings: payload }
        )
          .then(() => {
            enqueueSnackbar('Subjectivities saved successfully', {
              variant: 'success',
            });
            queryClient.invalidateQueries(['uw-subjectivities', quoteId]);

            Modal.show(WaiveSubjectivityModalConfig, {
              data: {
                description,
                subjectivityId: id,
                subType,
                underwritingSubjectivitiesModalData,
              },
            });
          })
          .catch(handleAPIError('There was a problem saving subjectivities.'));
      };

      const handleComplete = ({ key }) => {
        const payload = formatUWSubjectivityPayload(
          availableSubjectivities.sortedData,
          values
        );

        return saveSubjectivitiesFromUW(
          { productType, quoteId },
          { subjectivitySettings: payload }
        )
          .then(() => {
            enqueueSnackbar('Subjectivities saved successfully', {
              variant: 'success',
            });
            queryClient.invalidateQueries(['uw-subjectivities', quoteId]);
            queryClient.refetchQueries([
              'quote-progress',
              quoteId,
              PERSONA_TYPES.COWBELL,
            ]);

            setTimeout(
              () => setCompleteModal({ subjectivityKey: key, isOpen: true }),
              800
            );
          })
          .catch(handleAPIError('There was a problem saving subjectivities.'));
      };

      const handleCompleteClose = () => {
        queryClient.refetchQueries(['uw-subjectivities', quoteId]);
        setCompleteModal({ subjectivityKey: '', isOpen: false });
      };

      React.useEffect(() => {
        if (fieldArrayMethods.fields) {
          scrollableRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [fieldArrayMethods.fields]);

      return (
        <>
          <MuiDialogContent>
            <HeaderContainer container>
              <TextCenterGrid item md={1}>
                Add To <br /> This Quote
              </TextCenterGrid>
              <TextCenterGrid item md={1}>
                Add To <br />
                All Quotes
              </TextCenterGrid>
              <TextCenterGrid item md={1}>
                Version
              </TextCenterGrid>
              <Grid
                item
                md={isCowbellBindQuoteWorkflowFFEnabled ? 5 : 6}
                style={{ paddingLeft: '2rem' }}
              >
                Description
              </Grid>
              <HeaderPaddedGrid
                style={{ paddingLeft: '2rem' }}
                item
                md={isCowbellBindQuoteWorkflowFFEnabled ? 1 : 2}
              >
                Required
              </HeaderPaddedGrid>
              <TextCenterGrid item md={1} style={{ paddingRight: '2rem' }}>
                Verify
              </TextCenterGrid>
              <IfFeatureEnabled
                feature={FEATURE_FLAG_KEYS.COWBELL_BIND_QUOTE_WORKFLOW}
              >
                <TextCenterGrid item md={1} style={{ paddingRight: '2rem' }}>
                  Waive
                </TextCenterGrid>
                <TextCenterGrid item md={1} style={{ paddingRight: '2rem' }}>
                  Complete
                </TextCenterGrid>
              </IfFeatureEnabled>
            </HeaderContainer>

            <Divider />

            <FormProvider {...methods}>
              <form id="subjectivities" onSubmit={handleSubmit(onSubmit)}>
                {availableSubjectivities.sortedData.map((subjectivity) => {
                  return (
                    <React.Fragment key={subjectivity.id}>
                      <Grid container direction="column">
                        <GridContainer container>
                          <Grid item md={1}>
                            <NestedCheckBox
                              name={`${subjectivity.id}_addToThisQuote`}
                              disabled={
                                values[`${subjectivity.id}_addToAllQuotes`] ||
                                readOnly
                              }
                            />
                          </Grid>
                          <Grid item md={1}>
                            <NestedCheckBox
                              name={`${subjectivity.id}_addToAllQuotes`}
                              disabled={readOnly}
                            />
                          </Grid>
                          <Grid item md={1}>
                            <div style={{ textAlign: 'center' }}>
                              {subjectivity.version ?? ''}
                            </div>
                          </Grid>
                          <Grid
                            item
                            md={isCowbellBindQuoteWorkflowFFEnabled ? 5 : 6}
                            style={{ paddingLeft: '2rem' }}
                          >
                            {subjectivity.description}
                          </Grid>
                          <TextPaddedGrid
                            item
                            md={isCowbellBindQuoteWorkflowFFEnabled ? 1 : 2}
                          >
                            {subjectivity.subType}
                          </TextPaddedGrid>
                          <Grid item md={1}>
                            <NestedCheckBox
                              name={`${subjectivity.id}_verified`}
                              disabled
                            />
                          </Grid>
                          <IfFeatureEnabled
                            feature={
                              FEATURE_FLAG_KEYS.COWBELL_BIND_QUOTE_WORKFLOW
                            }
                          >
                            <Grid item md={1}>
                              <SubjectivityActionButton
                                subjectivity={subjectivity}
                                handler={handleWaive}
                                buttonText="Waive"
                              />
                            </Grid>
                            <Grid item md={1}>
                              <SubjectivityActionButton
                                subjectivity={subjectivity}
                                handler={handleComplete}
                                buttonText="Complete"
                              />
                            </Grid>
                          </IfFeatureEnabled>
                        </GridContainer>
                      </Grid>

                      <Divider />
                    </React.Fragment>
                  );
                })}
              </form>
            </FormProvider>
            <ManuscriptedSubjectivitiesContainer
              accountId={props.data.accountId}
              quoteId={quoteId}
              fieldArrayMethods={fieldArrayMethods}
              manuscriptFormMethods={manuscriptFormMethods}
            />
            <div ref={scrollableRef} />
          </MuiDialogContent>
          <DialogActions style={{ position: 'relative' }}>
            <Button
              disabled={readOnly}
              onClick={handleAddManuscriptSubjectivity}
              startIcon={<AddIcon />}
              className="contrast-text"
              style={{
                position: 'absolute',
                left: '1rem',
              }}
            >
              Add Manuscript Subjectivity
            </Button>
            <CbButton styleName="cancel" buttonText="Cancel" onClick={close} />
            <CbButton
              type="submit"
              form="subjectivities"
              styleName="ctaButton"
              buttonText="Save Changes"
              loading={isSubmitting}
              disabled={isSubmitting || readOnly}
            />
          </DialogActions>
          <CowbellBindQuoteModal
            data={{
              quoteId,
              productType,
              subjectivityKey: completeModal.subjectivityKey,
            }}
            open={completeModal.isOpen}
            close={handleCompleteClose}
            maxWidth="xl"
            fullWidth
          />
        </>
      );
    }
  );

const SubjectivityActionButton = ({ subjectivity, handler, buttonText }) => {
  const { watch } = useFormContext();

  const addToThisQuote = watch(`${subjectivity.id}_addToThisQuote`);
  const addToAllQuotes = watch(`${subjectivity.id}_addToAllQuotes`);

  const isActionDisabled =
    subjectivity?.subType === SubjectivitySubType.PostIssuance ||
    !(addToThisQuote || addToAllQuotes) ||
    subjectivity?.verified;

  return (
    <CbButton
      size="small"
      styleName="ctaButton"
      onClick={() => handler(subjectivity)}
      disabled={isActionDisabled}
      buttonText={buttonText}
    />
  );
};

const ManuscriptedSubjectivitiesContainer = ({
  accountId,
  quoteId,
  manuscriptFormMethods,
  fieldArrayMethods,
}) => {
  const queryClient = useQueryClient();
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const {
    formState: { isSubmitting },
  } = manuscriptFormMethods;

  const onSubmit = (values) => {
    return createManuscriptSubjectivity({ data: { accountId, ...values } })
      .then(() => {
        enqueueSnackbar('Manuscripted subjectivities saved', {
          variant: 'success',
        });
        queryClient.invalidateQueries(['uw-subjectivities', quoteId]);
        fieldArrayMethods.remove();
      })
      .catch((errorResponse) => {
        const customErrMessage =
          'Not able to save manuscripted subjectivites at this time.';
        const error = manageAPIError(errorResponse, customErrMessage);
        enqueueSnackbar(error || customErrMessage, { variant: 'error' });
      });
  };

  return (
    <FormProvider {...manuscriptFormMethods}>
      <form onSubmit={manuscriptFormMethods.handleSubmit(onSubmit)}>
        {fieldArrayMethods.fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <HeaderContainer
              show={fieldArrayMethods.fields.length > 0}
              style={{ paddingTop: '2rem' }}
            >
              Manuscripted Subjectivity
            </HeaderContainer>
            <Grid container spacing={6}>
              <Grid item md={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                <InputLabelBase required>Title</InputLabelBase>
                <TextField name={`manuscriptSubjectivities.${index}.title`} />
              </Grid>
              <Grid item md={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                <FormControl variant="standard" required component="fieldset">
                  <InputLabelBase required>Required</InputLabelBase>
                  <RadioGroup
                    required
                    name={`manuscriptSubjectivities.${index}.subType`}
                    style={{ flexDirection: 'row' }}
                  >
                    <FormControlLabel
                      value="PreBind"
                      control={<Radio color="secondary" />}
                      label="Pre-Bind"
                    />
                    <FormControlLabel
                      value="PreIssuance"
                      control={<Radio color="secondary" />}
                      label="Pre-Issuance"
                    />
                    <FormControlLabel
                      value="PostIssuance"
                      control={<Radio color="secondary" />}
                      label="Post-Issuance"
                    />
                  </RadioGroup>
                  <ErrorMessage
                    name={`manuscriptSubjectivities.${index}.subType`}
                    render={({ message }) => (
                      <span style={{ color: '#fa8072' }}>{message}</span>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} style={{ paddingTop: 0 }}>
                <InputLabelBase required style={{ paddingTop: 0 }}>
                  Description
                </InputLabelBase>
                <TextField
                  name={`manuscriptSubjectivities.${index}.description`}
                  minRows={5}
                  multiline
                  style={{
                    width: '100%',
                    backgroundColor: palette.background.paper,
                    resize: 'none',
                  }}
                />
              </Grid>
            </Grid>
            <Showable show={fieldArrayMethods.fields.length - 1 !== index}>
              <Divider style={{ margin: '1rem 0rem' }} />
            </Showable>
          </React.Fragment>
        ))}

        <Box display="flex" justifyContent="end">
          <CbButton
            type="submit"
            styleName="ctaButton"
            show={fieldArrayMethods.fields.length > 0}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Add to list
          </CbButton>
        </Box>
      </form>
    </FormProvider>
  );
};

// simplicity sake
const NestedCheckBox = ({ name, ...props }) => {
  const { watch, register, setValue, getValues } = useFormContext();

  const watchField = watch(name);

  const handleCheckbox = ({ target }) => {
    setValue(target.name, target.checked);

    // checking if add to this quote checbox should be true if add to all quotes is true
    const singleQuoteCheck = checkSubjectivityOnOneQuoteFromAll(
      target.name,
      target.checked
    );

    // setting the value based on the above condition
    if (!_.isNull(singleQuoteCheck)) {
      watch(singleQuoteCheck.name);
      setValue(singleQuoteCheck.name, singleQuoteCheck.value);
    }
  };

  const checkboxInfo = name.split('_');
  const values = getValues();

  const addToAllQuotesChecked =
    checkboxInfo[1] === 'addToThisQuote' &&
    values[`${checkboxInfo[0]}_addToAllQuotes`] === true;

  return (
    <MuiCheckbox
      name={name}
      ref={register(name)}
      checked={watchField || addToAllQuotesChecked}
      onChange={handleCheckbox}
      disableRipple
      {...props}
    />
  );
};

const MuiDialogContent = styled(DialogContent)(() => ({
  padding: '0rem 5rem 2rem',
  '&&': {
    paddingTop: '1rem',
  },
  maxHeight: '45rem',
  '& hr:last-child': {
    height: 0,
  },
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  lineHeight: 1.25,
  color: theme.palette.primary.contrastText,
  fontSize: theme.config.textSizes.tertia,
  alignItems: 'center',
  padding: '1rem 0',
}));

const HeaderContainer = withShowable(
  styled(GridContainer)(({ theme }) => ({
    fontSize: `${theme.config.textSizes.primer} !important`,
    fontWeight: theme.config.weights.bold,
  }))
);

const TextCenterGrid = styled(HeaderContainer)(() => ({
  textAlign: 'center',
}));

const HeaderPaddedGrid = styled(HeaderContainer)(() => ({
  padding: '0 3rem',
}));

const TextPaddedGrid = styled(GridContainer)(() => ({
  padding: '0 3rem',
}));

const MuiCheckbox = styled(Checkbox)(({ theme: { palette } }) => ({
  background: 'none !important',
  '&.Mui-disabled': {
    '& svg': {
      color: `${Color(palette.text.primary).fade(0.5).string()} !important`,
    },
  },
  '&.Mui-checked': {
    '& svg': {
      color: palette.common.cowbellBlue,
    },
  },
}));

export const UnderwritingSubjectivitiesConfig = {
  UnderwritingSubjectivities: {
    component: UnderwritingSubjectivities,
    config: {
      fullWidth: true,
      maxWidth: 'xl',
      title: 'Subjectivities',
    },
  },
};

const ManuscriptedSubjectivityRowSchema = {
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  subType: Yup.string().required('Type is required'),
};

const ManuscriptedSubjectivityContainerSchema = Yup.object().shape({
  manuscriptSubjectivities: Yup.array().of(
    Yup.object().shape(ManuscriptedSubjectivityRowSchema)
  ),
});

export default UnderwritingSubjectivities;
