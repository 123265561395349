import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import Numeral from 'numeral';

interface PolicyCountsProps {
  stats: {
    value: number;
    name: string;
  }[];
}

const PolicyCount = ({ stats }: PolicyCountsProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap="0.15rem"
    >
      {stats.map((stat) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            gap="1rem"
            fontSize="1.33rem"
            justifyContent="space-between"
          >
            <Box
              style={{ width: '3.5rem', textAlign: 'right', fontWeight: 600 }}
            >
              {Numeral(stat.value).format('0,0')}
            </Box>
            <StatName>({stat.name})</StatName>
          </Box>
        );
      })}
    </Box>
  );
};

export default PolicyCount;

const StatName = styled(Box)(({ theme }: { theme: any }) => {
  return {
    fontSize: theme.config.textSizes.primer,
    color: theme.palette.text.primary,
  };
});
