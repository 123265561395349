import { trimAndAddAsterisk } from '../../utility/QueryUtility';

export const fetchBlackListedDomainsParamsMapping = [
  {
    path: 'pageIndex',
    paramKey: 'page',
    transform: ({ pageIndex }) => Number(pageIndex),
  },
  {
    path: 'pageSize',
    paramKey: 'size',
    transform: ({ pageSize }) => {
      return Number(pageSize) === 10 ? Number(25) : Number(pageSize);
    },
  },
  {
    path: 'search',
    transform: ({ search }) => (search ? trimAndAddAsterisk(search) : null),
  },

  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
];
