import React from 'react';

//mui
import type { SelectChangeEvent } from '@mui/material';
import { DialogActions, DialogContent, Select } from '@mui/material';

//components
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import CbButton from '../Buttons/CbButton';
import { InputLabelBase } from '../inputs/InputLabelBase';

//utils and services
import { renderOptions } from '../../utils/render.utils';
import { updateProductType } from '../../accounts/AccountService';
import { manageAPIError } from '../../utils';
import { COWBELL_ACCOUNTS } from '../tables/table_constants';

interface ChangeProductTypeModalProps {
  data: {
    productType: string;
    accountId: string;
    name: string;
  };
  close: () => void;
}

const baseProductOptions = [
  { label: 'P100', value: 'P100' },
  { label: 'P250', value: 'P250' },
];

const ChangeProductTypeModal = ({
  data: modalDependencyData,
  close,
}: ChangeProductTypeModalProps) => {
  const [selectedProduct, setSelectedProduct] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const selectableProductOptions = baseProductOptions.filter(
    (product) => product.value !== modalDependencyData.productType
  );

  const handleProductSelect = (event: SelectChangeEvent) => {
    setSelectedProduct(event.target.value);
  };

  const handleChangeProduct = () => {
    setIsLoading(true);
    updateProductType(modalDependencyData.accountId, selectedProduct)
      .then(() => {
        queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
        enqueueSnackbar(`Product successfully updated to ${selectedProduct}`, {
          variant: 'success',
        });
        close();
      })
      .catch((error) => {
        enqueueSnackbar(
          manageAPIError(error, 'Not able to change the product at this time.'),
          {
            variant: 'error',
          }
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <DialogContent style={{ padding: '2rem', margin: '0 auto' }}>
        <InputLabelBase style={{ paddingTop: 0, fontSize: '1.2rem' }}>
          Which product would you like to assign to{' '}
          <strong>{modalDependencyData.name}</strong>?
        </InputLabelBase>
        <Select
          variant="standard"
          name="productSelection"
          onChange={handleProductSelect}
        >
          {selectableProductOptions.map((productOption) =>
            renderOptions(productOption)
          )}
        </Select>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" size="medium" onClick={close}>
          Cancel
        </CbButton>
        <CbButton
          styleName="ctaButton"
          size="medium"
          loading={isLoading}
          disabled={isLoading || !selectedProduct}
          onClick={handleChangeProduct}
        >
          Confirm
        </CbButton>
      </DialogActions>
    </>
  );
};

export const ChangeProductTypeModalConfig = {
  ChangeProductTypeModal: {
    component: ChangeProductTypeModal,
    config: {
      maxWidth: 'sm',
      minWidth: 'sm',
    },
  },
};
