import { useToggleModal } from '../../utils/modal.utils';

export function withToggleModal(C) {
  const Wrapper = (props) => {
    const toggleModal = useToggleModal();

    return <C toggleModal={toggleModal} {...props} />;
  };

  Wrapper.displayName = 'withToggleModal';

  return Wrapper;
}
