import React from 'react';
import { useParams, useHistory, Switch, Route } from 'react-router-dom';
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const TabSubrouter = ({
  children = undefined,
  baseUrl,
  subroutes,
  urlParam = 'tab',
  ...props
}) => {
  const params = useParams();
  const history = useHistory();
  const tab = params[urlParam];

  const transformBaseUrl = React.useCallback(
    (path) => {
      const pathArray = path.split('/');
      return pathArray
        .map((node) => {
          if (node.startsWith(':')) {
            return params[node.slice(1)];
          }
          return node;
        })
        .join('/');
    },
    [params]
  );

  const handleChange = (e, nextTab) => {
    if (typeof props.onChange === 'function') return props.onChange(nextTab);
    if (nextTab) {
      history.push(`${transformBaseUrl(baseUrl)}/${nextTab}`);
    } else {
      history.push(`${transformBaseUrl(baseUrl)}`);
    }
  };

  return (
    <>
      {typeof children === 'function' ? (
        <>
          {children({ onChange: handleChange, value: tab || null, subroutes })}
        </>
      ) : (
        <Tabs
          value={tab || null}
          onChange={handleChange}
          subroutes={subroutes}
        />
      )}
      <Switch>
        {subroutes.map((route) => {
          const { path, label, value, ...routeRest } = route;
          return (
            <Route
              exact
              key={`tab-subroutes-${path}`}
              path={tab ? `${baseUrl}/${path}` : `${baseUrl}`}
              {...routeRest}
            />
          );
        })}
      </Switch>
    </>
  );
};

export const Tabs = ({ subroutes, tabProps, ...props }) => {
  return (
    <TabsContainer {...props}>
      {subroutes.map((route) =>
        route.label ? (
          <Tab
            key={`tab-${route.path}`}
            value={route.value || route.path || null}
            label={route.label}
            data-qa={`TAB_NAVIGATION:${route.label}`}
            {...route.tabProps}
            {...tabProps}
          />
        ) : null
      )}
    </TabsContainer>
  );
};

export const TabsContainer = ({ ...props }) => {
  const classes = useTabContainerStyles();
  return <MuiTabs classes={classes} variant="scrollable" {...props} />;
};

const useTabContainerStyles = makeStyles(({ palette }) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  root: {
    overflow: 'visible',
  },
  flexContainer: {
    '& > button:last-of-type': {
      borderRight: `0.5px solid ${palette.border.grey}`,
    },
  },
}));

export const Tab = ({ ...props }) => {
  const classes = useTabStyles(props);
  return <MuiTab disableRipple classes={classes} {...props} />;
};

const useTabStyles = makeStyles(({ config, palette }) => ({
  root: {
    textTransform: 'none',
    color: `${config.colors.cowbellBlue} !important`,
    '&:focus': {
      opacity: 1,
    },
    width: '11.5rem',
    fontWeight: 'normal',
    overflow: 'visible',
    borderLeft: `0.5px solid ${palette.border.grey}`,
    fontSize: config.textSizes.tertia,
  },
  selected: (props) => ({
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    backgroundColor: palette.background.paper,
    color: `${palette.primary.contrastText} !important`,
    borderRight: 'none !important',
    borderLeft: 'none !important',
    '&.Mui-selected': {
      color: `${palette.primary.contrastText} !important`,
      fontWeight: config.weights.bold,
    },
    '& + button': {
      borderLeft: 'none !important',
    },
    ...props?.selectedStyles,
  }),
}));
