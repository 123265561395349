import _ from 'lodash';
import Moment from 'moment';
import numeral from 'numeral';
import { styled } from '@mui/material';
import { bindSteps } from '../constants/constants';
import { ProductTypes } from '../../../../types';
import { PaperSnackbar } from '../../../../components/snackbars';
import { formatCurrencyToTenthsPlaceWithUnit } from '../../../../utils/currency.utils';
import {
  BOUND_STATUS,
  IN_FORCE_STATUS,
  ISSUED_STATUS,
  SIGNED_STATUS,
} from '../../../_statics/policy.statics';
import { cowbellBindSteps } from '../../cowbell-bind-quote/constants/constants';
import { PERSONA_TYPES } from '../../../_statics/persona.statics';
import { toUniversalUtcDate } from '../../../../utils/date.utils';
import { deriveCurrencyFormat } from '../../../../i18n/currencies';

export const overhaulQuoteProgressData = (
  data,
  persona,
  quoteDetails,
  productType
) => {
  const isCowbell = persona == PERSONA_TYPES.COWBELL;

  const isUKProduct = determineIsUKProduct(productType);

  const { preBindSubjectivities = [], preIssuanceSubjectivities = [] } = data;
  const rawSubjectivities = [
    ...preBindSubjectivities,
    ...preIssuanceSubjectivities,
  ];

  const {
    preBindSteps,
    preBindSubjectivitiesObject,
    preBindStepsCompleted,
    preBindKeys,
  } = getPreBindInfo(preBindSubjectivities);

  const sortedPreBindSteps = sortSubjectivities(preBindSteps);
  const sortedPreBindKeys = sortSubjectivityKeys(preBindKeys);

  const preIssueSteps = preIssuanceSubjectivities.map(
    ({ subjectivityTitle, subjectivityKey }) => ({
      subjectivityTitle,
      subjectivityKey,
    })
  );

  const preIssueKeys = preIssuanceSubjectivities.map(
    ({ subjectivityKey }) => subjectivityKey
  );

  const sortedPreIssueSteps = sortSubjectivities(preIssueSteps);
  const sortedPreIssueKeys = sortSubjectivityKeys(preIssueKeys);

  const subjectivities = preIssuanceSubjectivities.reduce(
    (acc, subjectivity) => {
      return {
        ...acc,
        [subjectivity.subjectivityKey]: {
          ...subjectivity,
        },
      };
    },
    { ...preBindSubjectivitiesObject }
  );

  const allCowbellPreIssueSteps = [
    { subjectivityTitle: cowbellBindSteps.UPLOAD_BIND_ORDER },
    { subjectivityTitle: bindSteps.POLICY_EFFECTIVE_DATE },
    ...sortedPreBindSteps,
    { subjectivityTitle: bindSteps.BIND_QUOTE },
    ...sortedPreIssueSteps,
  ];

  const allCowbellSteps = [
    ...allCowbellPreIssueSteps,
    { subjectivityTitle: bindSteps.ISSUE_POLICY },
  ];

  const updateCommissionStep = isUKProduct
    ? [{ subjectivityTitle: bindSteps.UPDATE_COMMISSION }]
    : [];

  const allAgencyPreIssueSteps = [
    { subjectivityTitle: bindSteps.POLICY_EFFECTIVE_DATE },
    ...updateCommissionStep,
    ...sortedPreBindSteps,
    { subjectivityTitle: bindSteps.BIND_QUOTE },
    ...sortedPreIssueSteps,
  ];

  const allAgencySteps = [
    ...allAgencyPreIssueSteps,
    { subjectivityTitle: bindSteps.ISSUE_POLICY },
  ];

  const agencyInitialSteps = [
    bindSteps.POLICY_EFFECTIVE_DATE,
    ...(isUKProduct ? [bindSteps.UPDATE_COMMISSION] : []),
    ...sortedPreBindKeys,
    bindSteps.BIND_QUOTE,
    ...sortedPreIssueKeys,
    bindSteps.ISSUE_POLICY,
  ];

  const cowbellInitialSteps = [
    cowbellBindSteps.UPLOAD_BIND_ORDER,
    bindSteps.POLICY_EFFECTIVE_DATE,
    ...sortedPreBindKeys,
    bindSteps.BIND_QUOTE,
    ...sortedPreIssueKeys,
    bindSteps.ISSUE_POLICY,
  ];

  const initialSteps = isCowbell ? cowbellInitialSteps : agencyInitialSteps;
  const allSteps = isCowbell ? allCowbellSteps : allAgencySteps;
  const allPreIssueSteps = isCowbell
    ? allCowbellPreIssueSteps
    : allAgencyPreIssueSteps;

  const preIssueStepsCompleted = preIssuanceSubjectivities.reduce(
    (acc, subjectivity) => {
      return {
        ...acc,
        [subjectivity.subjectivityKey]: subjectivity.completed,
      };
    },
    {}
  );

  const stepsCompleted = {
    [cowbellBindSteps.UPLOAD_BIND_ORDER]: !_.isEmpty(
      quoteDetails.uiData.bindOrderDocumentInfo
    ),
    [bindSteps.POLICY_EFFECTIVE_DATE]: true,
    ...(isUKProduct ? { [bindSteps.UPDATE_COMMISSION]: true } : {}),
    ...preBindStepsCompleted,
    [bindSteps.BIND_QUOTE]:
      data.quoteAgencyStatus === 'BOUND' || data.quoteAgencyStatus === 'SIGNED',
    ...preIssueStepsCompleted,
    [bindSteps.ISSUE_POLICY]: data.quoteAgencyStatus === 'SIGNED',
  };

  const incompleteSteps = allSteps.filter((step) => {
    return (
      stepsCompleted[step.subjectivityTitle] === false ||
      stepsCompleted[step.subjectivityKey] === false
    );
  });

  const incompletePreBindSteps = preBindSubjectivities.filter((step) => {
    return (
      stepsCompleted[step.subjectivityTitle] === false ||
      stepsCompleted[step.subjectivityKey] === false
    );
  });

  const incompletePreIssueSteps = allPreIssueSteps.filter((step) => {
    return (
      stepsCompleted[step.subjectivityTitle] === false ||
      stepsCompleted[step.subjectivityKey] === false
    );
  });

  const manuscriptSubjectivities = rawSubjectivities.filter((subjectivity) => {
    return subjectivity.subjectivityKey.includes('M');
  });

  return {
    initialSteps,
    sortedPreBindSteps,
    preBindKeys,
    preIssueKeys,
    sortedPreIssueSteps,
    subjectivities,
    incompleteSteps,
    incompletePreBindSteps,
    incompletePreIssueSteps,
    manuscriptSubjectivities,
  };
};

const getPreBindInfo = (preBindSubjectivities) => {
  const preBindSubjectivitiesObject = {};
  const preBindStepsCompleted = {};
  const preBindSteps = [];
  const preBindKeys = [];

  for (const subjectivity of preBindSubjectivities) {
    const { subjectivityTitle, subjectivityKey } = subjectivity;
    preBindSubjectivitiesObject[subjectivityKey] = subjectivity;
    preBindStepsCompleted[subjectivityKey] = subjectivity.completed;
    preBindSteps.push({ subjectivityTitle, subjectivityKey });
    preBindKeys.push(subjectivityKey);
  }
  return {
    preBindSteps,
    preBindKeys,
    preBindSubjectivitiesObject,
    preBindStepsCompleted,
  };
};

const sortSubjectivities = (subjectivities) => {
  const manuscriptSubjectivities = [];
  const restSubjectivities = [];

  for (const subjectivity of subjectivities) {
    if (subjectivity.subjectivityKey.includes('M')) {
      manuscriptSubjectivities.push(subjectivity);
    } else {
      restSubjectivities.push(subjectivity);
    }
  }
  restSubjectivities.sort((a, b) => {
    if (a.subjectivityKey < b.subjectivityKey) {
      return -1;
    }
    if (a.subjectivityKey > b.subjectivityKey) {
      return 1;
    }
    return 0;
  });

  manuscriptSubjectivities.sort((a, b) => {
    if (a.subjectivityKey < b.subjectivityKey) {
      return -1;
    }
    if (a.subjectivityKey > b.subjectivityKey) {
      return 1;
    }
    return 0;
  });
  return [...restSubjectivities, ...manuscriptSubjectivities];
};

const sortSubjectivityKeys = (subjectivityKeys) => {
  const manuscriptSubjectivityKeys = [];
  const restSubjectivityKeys = [];

  for (const subjectivityKey of subjectivityKeys) {
    if (subjectivityKey.includes('M')) {
      manuscriptSubjectivityKeys.push(subjectivityKey);
    } else {
      restSubjectivityKeys.push(subjectivityKey);
    }
  }

  restSubjectivityKeys.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  manuscriptSubjectivityKeys.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  return [...restSubjectivityKeys, ...manuscriptSubjectivityKeys];
};

export const formatUWSubjectivityPayload = (
  availableSubjectivities,
  formValues
) => {
  if (!_.isArray(availableSubjectivities)) {
    return formValues;
  }

  const subjectivitySettings = [];
  availableSubjectivities.map((subjectivity) => {
    let eachSubjectivityValues = {};

    _.entries(formValues).map((formSubjectivity) => {
      const splitKeySubjectivity = formSubjectivity[0].split('_');

      if (subjectivity.id === splitKeySubjectivity[0]) {
        eachSubjectivityValues = {
          ...eachSubjectivityValues,
          [splitKeySubjectivity[1]]: _.isUndefined(formSubjectivity[1])
            ? false
            : formSubjectivity[1],
        };
      }
      return eachSubjectivityValues;
    });

    subjectivitySettings.push({
      subjectivityId: subjectivity.id,
      ...eachSubjectivityValues,
    });
    return subjectivitySettings;
  });

  return subjectivitySettings;
};

export const checkSubjectivityOnOneQuoteFromAll = (
  formField,
  formFieldValue
) => {
  const splitKeySubjectivity = formField.split('_');
  if (splitKeySubjectivity[1] === 'addToAllQuotes') {
    return {
      name: `${splitKeySubjectivity[0]}_addToThisQuote`,
      value: formFieldValue,
    };
  }
  return null;
};

export const formatSubjectivities = (availableSubjectivities) => {
  if (
    !_.isArray(availableSubjectivities) ||
    availableSubjectivities.length === 0
  ) {
    return [];
  }

  const preBindSubjectivities = [];
  const preIssuanceSubjectivities = [];
  const postIssuanceSubjectivities = [];

  for (const subjectivity of availableSubjectivities) {
    if (subjectivity.subType === 'PreBind') {
      preBindSubjectivities.push(subjectivity);
    }

    if (subjectivity.subType === 'PreIssuance') {
      preIssuanceSubjectivities.push(subjectivity);
    }

    if (subjectivity.subType === 'PostIssuance') {
      postIssuanceSubjectivities.push(subjectivity);
    }
  }

  const sortedSubjectvites = [
    ...preBindSubjectivities,
    ...preIssuanceSubjectivities,
    ...postIssuanceSubjectivities,
  ];

  let defaults = {};
  sortedSubjectvites.map((subjectivity) => {
    defaults = {
      ...defaults,
      [`${subjectivity.id}_addToThisQuote`]: subjectivity.addToThisQuote,
      [`${subjectivity.id}_addToAllQuotes`]: subjectivity.addToAllQuotes,
      [`${subjectivity.id}_verified`]: subjectivity.verified,
    };
    return defaults;
  });
  return { formattedData: defaults, sortedData: sortedSubjectvites };
};

export const getAddedSubjectivities = (subjectivites) => {
  const addedSubjectivities = subjectivites.filter((subjectivity) => {
    return subjectivity.addToThisQuote;
  });
  return addedSubjectivities.length;
};

export const getPolicyEffectiveDateRestrictions = (
  productType,
  isCowbeller
) => {
  if (productType === ProductTypes.p100) {
    const minDaysInPast = isCowbeller ? 365 : 7;
    const minDate = Moment()
      .subtract(minDaysInPast, 'days')
      .format('YYYY-MM-DD');
    const maxDate = Moment().add(60, 'days').format('YYYY-MM-DD');

    return { minDate, maxDate };
  }

  if (productType === ProductTypes.p250) {
    const minDate = Moment().subtract(7, 'days').format('YYYY-MM-DD');
    const maxDate = Moment().add(60, 'days').format('YYYY-MM-DD');

    return { minDate, maxDate };
  }

  if (![ProductTypes.p100, ProductTypes.p250].includes(productType)) {
    const minDate = Moment().subtract(7, 'days').format('YYYY-MM-DD');
    const maxDate = Moment().add(60, 'days').format('YYYY-MM-DD');

    return { minDate, maxDate };
  }
};

export const getP250FormattedData = (data) => {
  return {
    customerFirstName: data?.customerFirstName ?? '',
    customerLastName: data?.customerLastName ?? '',
    customerEmail: data?.customerEmail ?? '',
    customerPhone: data?.customerPhone ?? '',
    agencyMailingAddress1: data?.firmographicData?.agencyMailingAddress1 ?? '',
    agencyMailingAddress2: data?.firmographicData?.agencyMailingAddress2 ?? '',
    agencyMailingCity: data?.firmographicData?.agencyMailingCity ?? '',
    agencyMailingState: data?.firmographicData?.agencyMailingState ?? '',
    agencyMailingZipCode: data?.firmographicData?.agencyMailingZipCode ?? '',
    surplusLicenseNumber: data?.firmographicData?.surplusLinesNumber ?? '',
    surplusLinesBrokerEmail:
      data?.firmographicData?.surplusLinesBrokerEmail ?? '',
    surplusLinesBrokerFirstName:
      data?.firmographicData?.surplusLinesBrokerFirstName ?? '',
    surplusLinesBrokerLastName:
      data?.firmographicData?.surplusLinesBrokerLastName ?? '',
    surplusLinesState: data?.firmographicData?.surplusLinesState
      ? data?.firmographicData?.surplusLinesState
      : data?.firmographicData?.state ?? '',
    effectiveDateOfPolicy: data?.initial250RequestData?.effectiveDate
      ? Moment.utc(data.initial250RequestData.effectiveDate).format(
          'YYYY-MM-DD'
        )
      : '-',
    premium: data?.totalPremium
      ? numeral(data.totalPremium).format('$0,0.00')
      : '-',
    quoteNumber: data?.quoteNumber ?? '',
    companyName: data?.companyName ?? '',
    isSurplus: Boolean(data?.isSurplus),
    productType: data?.initial250RequestData?.product,
    accountId: data?.accountId,
    policyNumber: data?.policyNumber,
    quoteId: data?.id,
    created: data?.created,
    boundOn: data?.boundOn,
    bindOrderDocumentInfo: data.bindOrderDocumentInfo,
  };
};

export const getP100FormattedData = (data) => {
  return {
    customerFirstName: data?.customerFirstName ?? '',
    customerLastName: data?.customerLastName ?? '',
    customerEmail: data?.customerEmail ?? '',
    customerPhone: data?.customerPhone ?? '',
    agencyMailingAddress1: data?.firmographicData?.agencyMailingAddress1 ?? '',
    agencyMailingAddress2: data?.firmographicData?.agencyMailingAddress2 ?? '',
    agencyMailingCity: data?.firmographicData?.agencyMailingCity ?? '',
    agencyMailingState: data?.firmographicData?.agencyMailingState ?? '',
    agencyMailingZipCode: data?.firmographicData?.agencyMailingZipCode ?? '',
    surplusLicenseNumber: data?.firmographicData?.surplusLinesNumber ?? '',
    surplusLinesBrokerEmail:
      data?.firmographicData?.surplusLinesBrokerEmail ?? '',
    surplusLinesBrokerFirstName:
      data?.firmographicData?.surplusLinesBrokerFirstName ?? '',
    surplusLinesBrokerLastName:
      data?.firmographicData?.surplusLinesBrokerLastName ?? '',
    surplusLinesState: data?.firmographicData?.surplusLinesState
      ? data?.firmographicData?.surplusLinesState
      : data?.firmographicData?.state ?? '',
    effectiveDateOfPolicy: data?.initialRequestData?.effectiveDate
      ? Moment.utc(data.initialRequestData.effectiveDate).format('YYYY-MM-DD')
      : '-',
    quoteNumber: data?.quoteNumber ?? '',
    companyName: data?.companyName ?? '',
    isSurplus: Boolean(data?.isSurplus),
    bindQuoteEffectiveDate: toUniversalUtcDate(
      data?.initialRequestData?.effectiveDate
    ),
    deductible: data?.initialRequestData?.deductible
      ? numeral(data.initialRequestData.deductible).format('$0,0.00')
      : '-',
    limit: data?.initialRequestData?.limit
      ? formatCurrencyToTenthsPlaceWithUnit(data?.initialRequestData?.limit)
      : '-',
    premium: data?.totalPremium
      ? numeral(data.totalPremium).format('$0,0.00')
      : '-',
    productType: data?.initialRequestData?.product,
    revenue: data?.firmographicData?.revenue
      ? numeral(data?.firmographicData?.revenue).format('$0a')
      : '-',
    numOfEmployees: data?.firmographicData?.noOfEmployeesAll ?? '-',
    naics: data?.firmographicData?.naicsCode ?? '-',
    ownershipType: data?.firmographicData?.ownershipType ?? '-',
    primaryIndustry: data?.firmographicData?.primaryIndustry ?? '',
    accountId: data?.accountId,
    quoteId: data?.id,
    bindOrderDocumentInfo: data.bindOrderDocumentInfo,
  };
};

export const getFormattedQuoteData = (data, language) => {
  return {
    customerFirstName: data?.quote?.customerFirstName ?? '',
    customerLastName: data?.quote?.customerLastName ?? '',
    customerEmail: data?.quote?.customerEmail ?? '',
    customerPhone: data?.quote?.customerPhone ?? '',
    companyName: data?.quote?.companyName ?? '',
    agencyMailingAddress1: data?.firmographicData?.agencyMailingAddress1 ?? '',
    agencyMailingAddress2: data?.firmographicData?.agencyMailingAddress2 ?? '',
    agencyMailingCity: data?.firmographicData?.agencyMailingCity ?? '',
    agencyMailingState: data?.firmographicData?.agencyMailingState ?? '',
    agencyMailingZipCode: data?.firmographicData?.agencyMailingZipCode ?? '',
    surplusLicenseNumber: data?.firmographicData?.surplusLinesNumber ?? '',
    surplusLinesBrokerEmail:
      data?.firmographicData?.surplusLinesBrokerEmail ?? '',
    surplusLinesBrokerFirstName:
      data?.firmographicData?.surplusLinesBrokerFirstName ?? '',
    surplusLinesBrokerLastName:
      data?.firmographicData?.surplusLinesBrokerLastName ?? '',
    surplusLinesState: data?.firmographicData?.surplusLinesState
      ? data?.firmographicData?.surplusLinesState
      : data?.firmographicData?.state ?? '',
    effectiveDateOfPolicy: data?.quote?.effectiveDate
      ? Moment.utc(data?.quote?.effectiveDate).format('YYYY-MM-DD')
      : '-',
    premium: deriveCurrencyFormat(language, data?.quote?.totalPremium),
    quoteNumber: data?.quote?.quoteNumber ?? '',
    isSurplus: Boolean(data?.quote?.isSurplus),
    productType: data?.firmographicData?.product,
    accountId: data?.firmographicData?.accountId,
    policyNumber: data?.quote?.policyNumber,
    quoteId: data?.quote?.id,
    created: data?.firmographicData?.created,
    boundOn: data?.quote?.boundOn,
    deductible: deriveCurrencyFormat(language, data?.quote?.deductible),
    limit: deriveCurrencyFormat(language, data?.quote?.aggLimit, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }),
    bindQuoteEffectiveDate: toUniversalUtcDate(data?.quote?.effectiveDate),
    revenue: deriveCurrencyFormat(language, data?.firmographicData?.revenue, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }),
    numOfEmployees: data?.firmographicData?.noOfEmployeesAll ?? '-',
    naics: data?.firmographicData?.naicsCode ?? '-',
    ownershipType: data?.firmographicData?.ownershipType ?? '-',
    primaryIndustry: data?.firmographicData?.primaryIndustry ?? '',
    bindOrderDocumentInfo: data.bindOrderDocumentInfo,
  };
};

export const handleError = ({
  error,
  enqueueSnackbar,
  fallbackMessage = 'Unable to complete step at this time. Please try again later.',
}) => {
  if (
    error?.response?.status === 412 &&
    Array.isArray(error?.response?.data?.errors)
  ) {
    return enqueueSnackbar('Unable to complete step.', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      persist: true,
      content: (key, message) => {
        return (
          <PaperSnackbar key={key} message={message}>
            <strong>
              The step could not be completed for the following reason(s):
            </strong>
            <ul>
              {error.response.data.errors.map((error, index) => (
                <li key={index}>{error.detail}</li>
              ))}
            </ul>
          </PaperSnackbar>
        );
      },
    });
  }

  if (
    Array.isArray(error?.response?.data) &&
    error?.response?.data?.length > 0
  ) {
    return enqueueSnackbar('Unable to complete step', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      persist: true,
      content: (key, message) => {
        return (
          <PaperSnackbar key={key} message={message}>
            <strong>
              The step could not be completed for the following reason(s):
            </strong>
            <ul>
              {error.response.data.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </PaperSnackbar>
        );
      },
    });
  }

  enqueueSnackbar(fallbackMessage, { variant: 'error' });
};

export const sendEmailText = (quote) => {
  const { customerFirstName, customerLastName, companyName } = quote;
  return (
    <>
      <p>
        Are you sure you want to invite <Span>{customerFirstName}</Span>{' '}
        <Span>{customerLastName}</Span>, <Span>{companyName}</Span> to attest
        their application?
      </p>
      <p>
        Once the application has been signed by the insured, we will issue the
        policy. <br /> You will receive a notification via email with a
        confirmation.
      </p>
      <p>
        <i>
          NOTE: If the insured needs to make any changes to the application
          details, <br /> the original binder will be invalid due to material
          changes. The account will need to be requoted.
        </i>
      </p>
    </>
  );
};

const Span = styled('span')({
  fontWeight: 600,
});

export const SubjectivitySubType = {
  PreBind: 'PreBind',
  PreIssuance: 'PreIssuance',
  PostIssuance: 'PostIssuance',
};

export const SubjectivitySubTypeLabelMap = {
  [SubjectivitySubType.PreBind]: 'Pre-Bind',
  [SubjectivitySubType.PreIssuance]: 'Pre-Issuance',
  [SubjectivitySubType.PostIssuance]: 'Post-Issuance',
};

export const determineIfStepIsEditable = ({
  subjectivitySubType,
  status,
  isAPI,
  productType,
}) => {
  if (isAPI && productType === ProductTypes.p250) {
    return true;
  }

  if (subjectivitySubType === SubjectivitySubType.PreBind) {
    return status !== BOUND_STATUS && status !== SIGNED_STATUS;
  }

  return (
    status !== SIGNED_STATUS &&
    status !== ISSUED_STATUS &&
    status !== IN_FORCE_STATUS
  );
};

export const determineIsUKProduct = (product) => {
  return product === ProductTypes.prime_one;
};
