import React from 'react';
import TextFieldBase from './TextFieldBase';
import { CleaveBase } from '../../CleaveBase';

type Props = React.ComponentProps<typeof TextFieldBase>;

const PhoneFieldBase = React.forwardRef((props: Props, ref) => {
  return <TextFieldBase ref={ref} inputComponent={CleaveInput} {...props} />;
});

const CleaveInput = ({ inputRef, ...props }: any) => {
  return (
    <CleaveBase
      ref={inputRef}
      options={{
        delimiters: ['(', ') ', '-', '-'],
        blocks: [0, 3, 3, 4],
      }}
      {...props}
    />
  );
};

export default PhoneFieldBase;
