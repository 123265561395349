import { trimAndAddAsterisk } from '../../utility/QueryUtility';
import { commonDateParamMappings } from '../common/mappings';

export const renewalSubmissionParamsSerializer = [
  ...commonDateParamMappings,
  { path: 'page' },
  { path: 'size' },
  { path: 'policyId' },
  { path: 'dateFilter' },
  { path: 'product' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        switch (orderBy) {
          default:
            return `${orderBy},${order}`;
        }
      }
      return null;
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) =>
      !search ? search : trimAndAddAsterisk(search),
  },
  { path: 'f', paramKey: 'filter' },
];
