// lodash
import _ from 'lodash';
import Moment from 'moment';

import type {
  Account,
  AccountDto,
  UpdateAccountDetailsDto,
  AccountChangeLogSearchDto,
  AccountChangeLogSearch,
} from '../../types';
import { ProductTypes } from '../../types';

// utils
import { pull100AccountQuestions, pull250AccountQuestions } from '../../utils';

export function getAccountTransformer(data: AccountDto): Account {
  let securityQuestions = {};

  if (data.productType === ProductTypes.p100) {
    securityQuestions = pull100AccountQuestions(data);
  }

  if (data.productType === ProductTypes.p250) {
    securityQuestions = pull250AccountQuestions(data);
  }

  return {
    ...data,
    securityQuestions,
  };
}

export function getAccountChangeLogTransformer({
  content,
  totalPages,
  pageable,
  totalElements,
}: AccountChangeLogSearchDto): AccountChangeLogSearch {
  const formattedContent = content.map((log) => ({
    ...log,
    eventDetails: JSON.parse(log.eventDetails),
    modified: Moment(log.modified).format('MMMM Do, YYYY h:mm A'),
  }));

  return {
    content: formattedContent,
    totalPages,
    pageNumber: pageable.pageNumber,
    totalElements,
  };
}

export function updateAccountDetailsTransformer({
  id,
  ...data
}: Account): UpdateAccountDetailsDto {
  return {
    accountId: id,
    ..._.pick(data, [
      'isFranchise',
      'entityType',
      'phoneNumber',
      'zipCode',
      'state',
      'city',
      'address1',
      'dbaOrTradestyle',
      'noOfEmployeesAll',
      'domains',
      'primaryNaicsPercentage',
      'naicsCode',
      'ownershipType',
      'yearEstablished',
      'revenue',
      'name',
      'dunsNumber',
      'domainName',
      'percentInternationalSales',
    ]),
  };
}

export const accountChangeLogParamSchema = [
  { path: 'statusCode' },
  { path: 'size' },
  { path: 'page' },
  { path: 'eventType' },
  { path: 'accountId' },
  { path: 'sort' },
  { path: 'search' },
  { path: 'before' },
  { path: 'after' },
];
