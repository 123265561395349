import React, { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import Numeral from 'numeral';
import withRouter from '../hocs/withRouter';

import DescTooltip from '../DescTooltip';
import SupplementStat from '../../console/policies/listing/cowbell/SupplementStat';

import { StatBarCurrencySymbol } from '../../utils/currency.utils';
import { withPlatformRegion } from '../../utils/app.utils';
import ClaimsIcon from '../../_assets/svg/ClaimsIcon';
import InvoiceIcon from '../../_assets/svg/InvoiceIcon';

const tileTypeConfig = {
  accounts: { icon: 'Accounts.svg', format: '0,0' },
  quotes: { icon: 'Quotes_Created.svg', format: '0,0' },
  policies: { icon: 'Policies.svg', format: '0,0' },
  premium: { icon: 'Premium.svg', format: '0,0' },
  premiumShort: { icon: 'Premium.svg', format: 'a' },
  premiumMedium: { icon: 'Premium.svg', format: '0.00a' },
  avgPremium: { icon: 'Premium.svg', format: '0,0' },
  limit: { icon: 'PercentageSign.svg', format: '0.00' },
  api: { icon: 'StatsBarApi.svg', format: '0.0a' },
  currency: { icon: 'Premium.svg', format: '$0,0.00' },
  id: { icon: 'id', format: '0' },
  claim: { icon: 'Claims.svg', format: '0' },
};

class Tile extends PureComponent {
  renderTile(type, value, color, region) {
    const { classes } = this.props;
    const config = tileTypeConfig[type] || {
      icon: 'Accounts.svg',
      format: '0',
    };
    const fixedValue = !Number.isFinite(value) ? 0 : value;
    const colorStr = color || 'none';

    return (
      <div>
        <div className={classes.row}>
          <div>
            <StatIcon
              type={type}
              config={config}
              region={region}
              classes={classes}
            />
          </div>
          <div className={`tile-count ${classes.count} ${classes[colorStr]}`}>
            {Numeral(fixedValue).format(config.format)}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      classes,
      className,
      type,
      value,
      name,
      color,
      tooltip,
      supplement,
      region,
    } = this.props;

    return (
      <div className={`${className} ${classes.card}`}>
        {this.renderTile(type, value, color, region)}
        <div className={`tile-label ${classes.text}`}>
          <div style={{ display: 'flex' }}>
            {name}
            {tooltip && <DescTooltip tooltip={tooltip} />}
          </div>
          {supplement && <SupplementStat {...supplement} />}
        </div>
      </div>
    );
  }
}

const styles = ({ config, palette }) => {
  return {
    card: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: '0.4166666667rem 2.5rem 0.833333333333333rem',
    },

    text: {
      marginTop: '0.4166666667rem',
      alignItems: 'center',
      fontSize: config.textSizes.primer,
      color: palette.text.primary,
      lineHeight: 1,
    },

    description: {
      fontFamily: 'Titillium Web',
      fontWeight: '400',
      height: 5,
      fontSize: '1rem',
      textDecoration: 'none',
      alignSelf: 'center',
    },

    count: {
      color: palette.text.primary,
      fontFamily: 'Oswald',
      fontSize: config.textSizes.bigCanon,
      textDecoration: 'none',
      padding: 0,
      lineHeight: 1.3,
    },

    row: {
      display: 'flex',
      alignItems: 'flex-end',
    },

    icon: {
      width: '2.8rem',
      marginRight: '0.4166666667rem',
    },

    red: {
      color: palette.error.dark,
    },
  };
};

export default withStyles(styles)(withRouter(withPlatformRegion(Tile)));

const StatIcon = ({ config, type, region, classes }) => {
  if (
    [
      'premium',
      'premiumShort',
      'premiumMedium',
      'avgPremium',
      'currency',
    ].includes(type)
  ) {
    return <StatBarCurrencySymbol region={region} />;
  }

  if (type === 'quotes') {
    return (
      <InvoiceIcon
        style={{
          width: '3rem',
          height: '3rem',
          marginRight: '0.41666667rem',
        }}
      />
    );
  }

  if (type === 'claim') {
    return (
      <ClaimsIcon
        style={{
          width: '3rem',
          height: '3rem',
          marginRight: '0.41666667rem',
        }}
      />
    );
  }

  return (
    <img
      src={`images/${config.icon}`}
      alt="stat-icon"
      className={`tile-icon ${classes.icon}`}
    />
  );
};
