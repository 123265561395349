import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import useDocUpload from '../shared/useDocUpload';
import { Modal } from '../../../../../components/modals/v2/helpers/v2.modal.helpers';
import useUploadZone from '../../../../../components/fileUploader/v2/hooks/useUploadzone';
import useGetSubjectivityProgress from '../shared/useGetSubjectivityProgress';
import { signedDocumentConfirmationSchema } from '../schemas/validationSchemas';
import useGetSubjectivityDocs from '../shared/useGetSubjectivityDocs';
import useCompleteQuoteSubjectivity from '../shared/useCompleteQuoteSubjectivity';
import { useDocSignedDate } from '../shared/useDocSignedDate';
import { downloadDocument } from '../../../../../utils/documents.utils';
import { downloadQuoteDocuments } from '../../../../../inbox/QuotesService';
import { determineIfStepIsEditable } from '../../utils/bindQuoteWorkFlowUtils';

export const useInsuranceApplicationDependencies = ({
  quoteDetails,
  goForward,
  completeStep,
  step,
  formState,
  subjectivities,
  quoteProgress,
  incompletePreBindSteps,
  jumpTo,
  goBack,
  sortedPreBindSteps: preBindSteps,
  sortedPreIssueSteps: preIssueSteps = [],
}) => {
  const {
    quoteSubjectivityId,
    completed: isStepCompleted,
    subjectivitySubType,
    subjectivityKey,
  } = subjectivities[step];

  const { enqueueSnackbar } = useSnackbar();

  const { uploadMultipleDocuments, isUploadingDoc } = useDocUpload({
    quoteDetails,
  });

  const [isInsuranceAppAcknowledged, setIsInsuranceAppAcknowledged] =
    React.useState(isStepCompleted);

  const [uploadedDocs, setUploadedDocs] = React.useState([]);

  const isStepEditable = determineIfStepIsEditable({
    subjectivitySubType,
    status: quoteProgress.quoteAgencyStatus,
    isAPI: quoteProgress.isAPI,
    productType: quoteDetails.uiData.productType,
  });

  const { files, ...uploadZoneParams } = useUploadZone({
    docType: 'Application',
    maxFiles: 4,
    multiple: true,
  });

  const { handleSubmit, setValue, ...methods } = useForm({
    resolver: yupResolver(
      signedDocumentConfirmationSchema(files ?? uploadedDocs ?? [])
    ),
  });

  const isFormDirty = methods.formState.isDirty;

  const {
    data: allSubjectivityInformation = {},
    isLoading: subjectivityDataLoading,
    isFetching: isFetchingSubjectivityData,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId,
    quoteId: quoteDetails.id,
    productType: quoteDetails.uiData.productType,
  });

  const { data: subjectivityData } = allSubjectivityInformation;

  React.useEffect(() => {
    if (files || isFormDirty) {
      setIsInsuranceAppAcknowledged(false);
    }
    if (subjectivityData) {
      setValue('signeeFullName', subjectivityData.signeeFullName);
      setValue('signeeTitle', subjectivityData.signeeTitle);
    }
    if (subjectivityData && files) {
      setValue('signeeFullName', '');
      setValue('signeeTitle', '');
    }
  }, [files, isFormDirty, setValue, subjectivityData]);

  const {
    data: uploadedDocuments,
    isInitialLoading: isUploadedDocsLoading,
    isFetching: isFetchingUploadedDocs,
  } = useGetSubjectivityDocs({
    quoteSubjectivityId,
    accountId: quoteDetails.uiData?.accountId,
    uploadedDocs: subjectivityData?.uploadedDocuments,
    isFetchingSubjectivityData,
  });

  const { completeSubjectivity, isCompletingStep } =
    useCompleteQuoteSubjectivity({
      quoteId: quoteDetails.uiData.quoteId,
      productType: quoteDetails.uiData.productType,
    });

  React.useEffect(() => {
    setUploadedDocs(uploadedDocuments);
  }, [uploadedDocuments]);

  useDocSignedDate({
    formState,
    files,
    setValue,
    uploadedDocuments,
    subjectivityData,
  });

  const isLoading =
    subjectivityDataLoading ||
    isFetchingSubjectivityData ||
    isUploadedDocsLoading ||
    isFetchingUploadedDocs;

  const handleDocDownload = (onError) => {
    const { accountId, quoteId, created } = quoteDetails.uiData;
    downloadDocument({
      asyncDownload: () =>
        downloadQuoteDocuments(
          accountId,
          quoteId,
          moment(created).unix(),
          false // endorsement should be false
        ),
      blobFileParams: {
        docName: `${quoteDetails.uiData.companyName}-${quoteDetails.uiData.quoteNumber}-Cowbell-Application`,
        docType: '.pdf',
      },
      enqueueSnackbar,
      onError,
    });
  };

  const handleCheckboxChange = () => {
    setIsInsuranceAppAcknowledged((prevState) => !prevState);
  };

  const handleSendEmail = () => {
    Modal.show('EmailSignLinkConfirmation', {
      data: { ...formState, quoteDetails, product: 'P250', goForward },
      config: { title: 'Email E-Sign Link to Customer' },
    });
  };

  const onSubmit = (formValues) => {
    if (!files) {
      const uploadedDocumentsPayload = uploadedDocuments.map(
        ({ id }, index) => ({
          docId: id,
          docSignedOn: formValues[`docSignedOn-${index}`],
        })
      );
      const params = {
        quoteSubjectivityId,
        goForward,
        payload: {
          uploadedDocuments: uploadedDocumentsPayload,
          userAttested: isInsuranceAppAcknowledged,
          ...formValues,
        },
      };
      return completeSubjectivity(params);
    }

    const uploadDocumentParams = {
      formValues,
      files,
      userAttested: isInsuranceAppAcknowledged,
      quoteDetails,
      completeStep,
      goForward,
      step,
      docTypes: Array(files.length).fill('Application'),
      quoteSubjectivityId,
    };

    uploadMultipleDocuments(uploadDocumentParams);
  };

  const handleGoNext = () => {
    if (!files && !uploadedDocs?.length) {
      return goForward();
    }
  };

  const handleGoBack = () => {
    if (
      preIssueSteps[0]?.subjectivityKey === subjectivityKey &&
      preBindSteps.length &&
      incompletePreBindSteps.length
    ) {
      jumpTo(preBindSteps[preBindSteps.length - 1].subjectivityKey);
    } else {
      goBack();
    }
  };

  return {
    isUploadingDoc,
    isInsuranceAppAcknowledged,
    subjectivitySubType,
    files,
    subjectivityData,
    allSubjectivityInformation,
    subjectivityDataLoading,
    isFetchingSubjectivityData,
    uploadedDocuments,
    isStepEditable,
    isStepCompleted,
    isCompletingStep,
    isLoading,
    uploadZoneParams,
    isFormDirty,
    eventHandlers: {
      handleCheckboxChange,
      handleSendEmail,
      onSubmit,
      handleGoNext,
      handleGoBack,
      handleDocDownload,
    },
    reactHookForm: {
      handleSubmit,
      methods,
    },
  };
};
