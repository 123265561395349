import jwtdecode from 'jwt-decode';

// Returns boolean.  Doesn't perform signature validation.
function isValid(payload) {
  const current = Math.floor(Date.now() / 1000);
  return !(current >= payload.exp);
}

function getPayload(token) {
  return decodeAndPullPayload(token);
}

function decodeAndPullPayload(token) {
  return new Promise((resolve, reject) => {
    const payload = jwtdecode(token);

    if (payload) {
      try {
        return resolve(payload);
      } catch (error) {
        return reject(new Error(error));
      }
    }

    return reject(new Error('Decoded Token is Null'));
  });
}

export default {
  isValid,
  getPayload,
  decodeAndPullPayload,
};

/**
 * @name decodeJwtPayload
 * @description attempts to decode the jwt. Note: We're using the
 *              nodejs error-first callback style here, where "error"
 *              will be the first argument & not null, if an error Occurred.
 */
export function decodeJwtPayload(token, callback) {
  try {
    const payload = jwtdecode(token);
    callback(null, payload);
  } catch (error) {
    console.error('Failure when attempting to parse jwt');
    callback(error);
  }
}
