import Axios from 'axios';
import type { AxiosRequestConfig } from 'axios';

import { retrieveAuthTokens } from '../../utils/next/auth.utils';
import { getAxiosBaseURL } from './axiosApiConfig';

export const InternalApiAxiosInstance = Axios.create({
  baseURL: getAxiosBaseURL(),
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

InternalApiAxiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = config.headers || {};

    const tokens = retrieveAuthTokens();

    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }

    return config;
  }
);
