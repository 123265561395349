import * as Ramda from 'ramda';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { getAccountDetails } from '../api';
import type { AccountDto } from '../types';
import { useAPIErrorHandler } from '../components/hooks/useAPIErrorHandler';

interface UseGetAccountDetailsQueryProps {
  accountId: string | undefined;
}

export const useGetAccountDetailsQuery = ({
  accountId,
}: UseGetAccountDetailsQueryProps) => {
  const handleAPIError = useAPIErrorHandler();

  return useQuery<AccountDto, AxiosError>(
    ['account-details', accountId],
    () => {
      return getAccountDetails(accountId!).then(Ramda.prop('data'));
    },
    {
      onError: handleAPIError(
        'Unable to retrieve account details at this time. Please try again later.'
      ),
      enabled: Boolean(accountId),
    }
  );
};
