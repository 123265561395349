import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// lodash
import _ from 'lodash';
// yup
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// mui
import { DialogContent, DialogActions } from '@mui/material';
// helpers
import { useSnackbar } from 'notistack';
import { EnrichedOption } from '../../agencies/CowbellAddAgencyModalV2/helpers';
import { FormGridBuilder } from '../../components/forms/FormGridBuilder';
// components
import CbButtonBase from '../../components/Buttons/CbButton';
// icons
import { iconMappings } from '../../console/_global/navigation/IconsMappings';
// statics
import { AutoCompleteBase, TypeAheadBase } from '../../components/inputs';
import {
  getAllAgentAgencies,
  getAllPlatformAgents,
} from '../../api/UserService';
import { getTeamsForAgentInAgency } from '../../teams/TeamsService';
import { addNewAccount, getAutocompleteAccounts } from '../AccountService';
import { withShowable } from '../../console/_global/lib/withShowable';
import { accountCreationTeams } from '../../reducers/UiSettingsReducers';
import { NoOptionsText } from '../../components/error/NoOptionsText';
import { COWBELL_ACCOUNTS } from '../../components/tables/table_constants';

import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { formatNameWithLocationAlias } from '../../agencies/format.utils';
import { useAPIErrorHandler } from '../../components/hooks/useAPIErrorHandler';
import LanguageForm, {
  useLanguageFormState,
} from '../../i18n/forms/LanguageForm';
import { FormLanguageProvider } from '../../i18n/forms/FormLanguageProvider';
import GridLanguageForm from '../../i18n/forms/GridLanguageForm';
import { useCowbellTranslations } from '../../i18n/translations';
import { LANGUAGE_COUNTRY_MAPPING } from '../../i18n/i18n.language-config';
import { getIsUsPlatform, useGetPlatformRegion } from '../../utils';

const CbButton = withShowable(CbButtonBase);

const CowbellAddAccountModalV2 = ({ data: account, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleAPIError = useAPIErrorHandler();
  const queryClient = useQueryClient();

  const { gridLayoutSettings, ...languageFormState } = useLanguageFormState({
    options: { validatePhone: true, phoneFieldName: 'phone' },
  });

  const { translations } = useCowbellTranslations(['Agency', 'Agent']);

  const { handleSubmit, ...formMethods } = useForm({
    defaultValues: {
      state: '',
      region: languageFormState.language,
    },
    resolver: yupResolver(
      deriveFormSchema(languageFormState.languageFormSchema, translations)
    ),
  });

  const { showSearch, onBlur, onFocus, onNoOptions, onRedirect } = useHandlers({
    formMethods,
    onClose: props.close,
  });

  const onSubmit = React.useCallback(
    async (formData) => {
      const { accountMeta, agentMeta, team, teamMeta, agency, ...formRest } =
        formData;

      const payload = {
        ...formRest,
        agencyId: agency?.value,
        teams: [_.pick(teamMeta, ['id', 'name'])],
        agentFirstName: _.get(agentMeta, 'firstName'),
        agentLastName: _.get(agentMeta, 'lastName'),
        agentPhone: _.get(agentMeta, 'phone'),
        cbid: _.get(accountMeta, 'cbid'),
        country: LANGUAGE_COUNTRY_MAPPING[languageFormState.language],
      };

      try {
        await languageFormState.validateAddress(payload);
        return addNewAccount(payload)
          .then(() => {
            props.close();
            enqueueSnackbar('Account Created Successfully!', {
              variant: 'success',
            });
            queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
          })
          .catch(handleAPIError('An issue occurred creating this account!'));
      } catch {
        //
      }
    },
    [enqueueSnackbar, handleAPIError, props, queryClient]
  );

  const formConfig = deriveFormConfig({
    formMethods,
    onBlur,
    onFocus,
    onNoOptions,
    translations,
  });

  const { cbid } = formMethods.watch('accountMeta') ?? {};

  return (
    <FormLanguageProvider language={languageFormState.language}>
      <FormProvider {...formMethods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ padding: '3rem 5rem' }}>
            <FormGridBuilder formConfig={formConfig} />
            <GridLanguageForm gridLayoutSettings={gridLayoutSettings} />
          </DialogContent>
          <DialogActions>
            <CbButton styleName="cancel" onClick={props.close}>
              Cancel
            </CbButton>
            <CbButton
              styleName="ctaButton"
              onClick={() => onRedirect(languageFormState.language)}
              show={!cbid}
              disabled={!showSearch}
            >
              Search
            </CbButton>
            <CbButton
              styleName="ctaButton"
              type="submit"
              loading={formMethods.formState.isSubmitting}
              disabled={formMethods.formState.isSubmitting}
              show={!!cbid}
            >
              Submit
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    </FormLanguageProvider>
  );
};

export const CowbellAddAccountModalV2Config = {
  CowbellAddAccountModalV2: {
    component: CowbellAddAccountModalV2,
    config: {
      title: 'Set up New Account',
      maxWidth: 'md',
      icon: iconMappings.Add.iconImport,
    },
  },
};

const deriveFormConfig = ({
  formMethods,
  onBlur,
  onFocus,
  onNoOptions,
  translations,
}) => {
  const [agentMeta, agency, state] = formMethods.watch([
    'agentMeta',
    'agency',
    'state',
  ]);

  const {
    formState: { errors },
  } = formMethods;

  return [
    {
      name: 'agentEmail',
      label: translations.Agent,
      required: true,
      gridItemProps: { xs: 6 },
      component: AgentTypeahead,
      formMethods,
      error: _.get(errors, 'agentEmail.message'),
    },
    {
      name: 'agency',
      label: translations.Agency,
      required: true,
      gridItemProps: { xs: 6 },
      component: AgencyTypeahead,
      formMethods,
      agentMeta,
      agency,
      disabled: !agentMeta,
      error: errors?.agency?.value?.message,
    },
    {
      name: 'team',
      label: 'Team',
      required: true,
      gridItemProps: { xs: 6 },
      disabled: !agentMeta,
      agentMeta,
      formMethods,
      component: TeamTypeahead,
      error: _.get(errors, 'team.message'),
    },
    { name: 'placeholder', gridItemProps: { xs: 6 } },
    {
      name: 'name',
      label: 'Name',
      required: true,
      gridItemProps: { xs: 6 },
      component: AccountNameTypeahead,
      formMethods,
      error: _.get(errors, 'name.message'),
      onBlur,
      onFocus,
      onNoOptions,
    },
    {
      name: 'phone',
      label: 'Phone Number',
      gridItemProps: { xs: 6, style: { marginTop: '0.75rem' } },
      component: LanguageForm.PhoneField,
    },
  ];
};

const validateAccountType = (agentMeta = {}) => {
  const accountType = _.get(agentMeta, 'account.accountType', '');
  return ['AGENCY', 'COWBELL'].includes(accountType);
};

const deriveFormSchema = (languageSchema, translations) => {
  return Yup.object().shape({
    agentEmail: Yup.string().when('agentMeta', {
      is: validateAccountType,
      then: Yup.string().required().label('Agent'),
      otherwise: Yup.string().test(
        'accountType-test',
        `${translations.Agency} must be a user of type "Cowbell" or "${translations.Agency}"`,
        () => false
      ),
    }),
    agency: Yup.object({
      label: Yup.string().required(
        `${translations.Agency} is a required field`
      ),
      value: Yup.string().required(
        `${translations.Agency} is a required field`
      ),
    }).required(),
    name: Yup.string().required().label('Name'),
    team: Yup.string().required().label('Team'),
    ...languageSchema,
  });
};

const getOptionLabel = (option) => option?.label ?? '';

const Typeahead = ({
  queryKey,
  enabled = true,
  onSelect = () => {},
  onFetch = () => {},
  onError = () => {},
  noOptionsText = 'Type to search',
  ...props
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  const onTypeahead = _.debounce((e, value) => {
    setSearchTerm(value);
  }, 300);

  const { data: options } = useQuery(
    [...queryKey, searchTerm],
    () => {
      return onFetch(searchTerm);
    },
    { keepPreviousData: true, refetchOnWindowFocus: false, enabled, onError }
  );

  return (
    <AutoCompleteBase
      autoHighlight
      getOptionLabel={getOptionLabel}
      fullWidth
      onInputChange={onTypeahead}
      onChange={onSelect}
      options={options}
      noOptionsText={searchTerm ? noOptionsText : 'Type to search'}
      {...props}
    />
  );
};

const AgentTypeahead = ({ formMethods, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onFetch = (searchTerm) => {
    if (!searchTerm) {
      return [];
    }
    return getAllPlatformAgents({ search: searchTerm }).then((resp) => {
      return mapUsersToOptions(resp.data.content);
    });
  };
  const onError = () => {
    enqueueSnackbar(
      'There was a problem fetching Agent data. Please try again.',
      { variant: 'error' }
    );
  };

  const onSelect = (event, option) => {
    const canAgentEnterAgency = option.data?.role?.enterAgencies ?? false;

    formMethods.setValue('agentEmail', option.value);
    formMethods.setValue('agentMeta', option.data);
    formMethods.setValue('agency', {
      label: !canAgentEnterAgency ? option.data.account.name : '',
      value: !canAgentEnterAgency ? option.data.account.id : '',
      data: !canAgentEnterAgency ? option.data.account : {},
    });

    formMethods.setValue('team', undefined);
    formMethods.setValue('teamMeta', undefined);

    formMethods.clearErrors(['agentEmail', 'agency']);
  };

  return (
    <Typeahead
      onFetch={onFetch}
      onSelect={onSelect}
      queryKey={['agent-typeahead']}
      onError={onError}
      noOptionsText={<NoOptionsText label="Agent not found" />}
      {...props}
    />
  );
};

const mapUsersToOptions = (users = []) => {
  return users.map((user) => ({
    label: user.email,
    value: user.email,
    data: user,
  }));
};

const TeamTypeahead = ({ formMethods, agentMeta, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onFetch = (searchTerm) => {
    if (!agentMeta) {
      return [];
    }
    return getTeamsForAgentInAgency({
      search: searchTerm,
      userId: agentMeta?.id,
    }).then((resp) => {
      return mapTeamsToOptions(resp.data.content);
    });
  };

  const onError = () => {
    enqueueSnackbar(
      'There was a problem fetching Team data. Please try again.',
      { variant: 'error' }
    );
  };

  const onSelect = (event, option) => {
    formMethods.setValue('team', option.value);
    formMethods.setValue('teamMeta', option.data);
    formMethods.clearErrors('team');
  };

  return (
    <Typeahead
      queryKey={['team-typeahead', agentMeta?.id]}
      onFetch={onFetch}
      onSelect={onSelect}
      onError={onError}
      {...props}
      style={{ marginBottom: '1.2rem' }}
    />
  );
};

const mapTeamsToOptions = (teams = []) => {
  return teams.map((team) => ({
    label: team.name,
    value: team.name,
    data: team ?? {},
  }));
};

const AccountNameTypeahead = ({
  formMethods,
  onFocus,
  onNoOptions,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const region = useGetPlatformRegion();
  const isUSPlatform = getIsUsPlatform(region);
  const COUNTRY = LANGUAGE_COUNTRY_MAPPING[region];

  const onFetch = (searchTerm) => {
    const ACCOUNTS_PARAMS = isUSPlatform
      ? { search: searchTerm }
      : { search: searchTerm, country: COUNTRY };
    const manualOption = { label: searchTerm, value: searchTerm, data: {} };
    return getAutocompleteAccounts(ACCOUNTS_PARAMS).then((resp) => {
      if (_.isEmpty(resp.data.content)) {
        onNoOptions();
        return [manualOption];
      }
      onFocus();
      return mapTeamsToOptions(resp.data.content);
    });
  };
  const onSelect = (event, option) => {
    const relevantFields = ['address1', 'address2', 'city', 'state'];
    const autofillData = relevantFields.reduce(
      (acc, field) => ({
        ...acc,
        [field]: option.data[field] ?? '',
      }),
      {}
    );
    Object.keys(autofillData).forEach((key) => {
      formMethods.setValue(key, _.get(autofillData, key, ''));
    });

    formMethods.setValue('name', option.value);
    formMethods.setValue('accountMeta', option.data);
    formMethods.setValue('zipCode', _.get(option, 'data.zipCode', ''));
    formMethods.setValue('phone', _.get(option, 'data.phoneNumber', ''));

    formMethods.clearErrors(['name', 'zipCode', ...relevantFields]);
  };
  const onError = () => {
    enqueueSnackbar(
      'There was a problem loading account data. Please try again.',
      { variant: 'error' }
    );
  };

  return (
    <Typeahead
      onFetch={onFetch}
      onSelect={onSelect}
      onError={onError}
      queryKey={['account-typeahead']}
      renderOption={(optionsProps, option) => (
        <li {...optionsProps}>
          <EnrichedOption option={option} />
        </li>
      )}
      autoSelect
      {...props}
    />
  );
};

const AgencyTypeahead = ({ agentMeta, agency, formMethods, ...props }) => {
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const { translations } = useCowbellTranslations(['Agency']);

  const handleAgencySearch = React.useCallback(
    ({ input }, callback) => {
      if (!agentMeta) {
        return [];
      }
      return getAllAgentAgencies({
        search: input,
        username: agentMeta?.email,
      }).then((resp) => {
        if (resp.data?.content?.length === 0) {
          formMethods.setValue('agency', {
            label: agentMeta.account.name,
            value: agentMeta.account.id,
            data: agentMeta.account,
          });
        }
        callback(mapAgencyToOptions(resp.data.content));
      });
    },
    [agentMeta, formMethods]
  );

  React.useEffect(() => {
    function onSearchFinish(results) {
      if (results.length) {
        setOptions(() => results);
      }
    }

    if (agentMeta && !agency) {
      handleAgencySearch({ input: '' }, onSearchFinish);
    }
    // eslint-disable-next-line
  }, [agentMeta]);

  const handleChange = React.useCallback((nextValue) => {
    formMethods.setValue('agency', nextValue);
    formMethods.clearErrors(['agency']);
    // eslint-disable-next-line
  }, []);

  if (!agentMeta?.role?.enterAgencies) {
    return (
      <TextFieldBase
        name="agency"
        label={translations.Agency}
        required
        value={agency?.label}
        disabled
        {...props}
      />
    );
  }

  return (
    <TypeAheadBase
      label={translations.Agency}
      name="agency"
      onChange={handleChange}
      onFetch={handleAgencySearch}
      onOptionsChange={setOptions}
      onInputChange={setInputValue}
      inputValue={inputValue}
      value={agency?.label}
      defaultOptions={options}
      options={options}
      placeholder={`Select ${translations.Agency}`}
      required
      disabled={!agentMeta?.role?.enterAgencies}
      fetchOnMount
      {...props}
    />
  );
};

const mapAgencyToOptions = (agencies = []) => {
  return agencies.map((agency) => ({
    label: formatNameWithLocationAlias(agency?.name, agency?.locationAlias),
    value: agency.id,
    data: agency ?? {},
  }));
};

const useHandlers = ({ formMethods, onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = React.useState(false);

  const onRedirect = () => {
    const formData = formMethods.getValues();

    onClose();

    history.push('/admin/manage/account/lookup', {
      state: formData.state,
      accountName: formData.name,
      preload: true,
      city: formData.city,
      zip: formData.zipCode,
      phone: formData.phone,
      street: formData.address1,
      region: formData.region,
      teams: {
        label: _.get(formData, 'teamMeta.name'),
        value: _.get(formData, 'teamMeta.id'),
      },
      agent: {
        label: _.get(formData, 'agentMeta.email'),
        value: _.get(formData, 'agentMeta.id'),
        meta: _.get(formData, 'agentMeta'),
      },
      agency: {
        name: formData?.agency?.label,
        label: formData?.agency?.label,
        id: formData?.agency?.value,
        value: formData?.agency?.value,
      },
    });

    dispatch(
      accountCreationTeams({
        agencyId: formData?.agency?.value,
        name: formData?.teamMeta?.name,
        id: formData?.teamMeta?.id,
      })
    );
  };

  const onFocus = () => setShowSearch(false);

  const onNoOptions = () => setShowSearch(true);

  const onBlur = () => setShowSearch(true);

  return { showSearch, onFocus, onNoOptions, onBlur, onRedirect };
};
