import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
// platform helpers
import { compose } from 'redux';
import { DialogContent, FormHelperText } from '@mui/material';
import { delayedEvent, PubSub } from '../../utils/eventUtils';

import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../reducers/UiSettingsReducers';
// actions
import { deleteALlExpiredQuotes } from '../../inbox/QuotesService';
import { withToggleModal } from '../hocs/withToggleModal';

class DeleteExpiredConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  componentDidMount() {
    this.subscription = PubSub.subscribe('foot-button-one', (data) => {
      if (data) {
        this.handleSubmit();
      }
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  handleSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    deleteALlExpiredQuotes()
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.props.toggleModal.direct('DeleteExpiredConfirm', false);
        this.props.enqueueSnackbar(
          'Submitted Request to Delete All Expired Quotes!',
          { variant: 'success' }
        );
        delayedEvent('quote:read', 5000, '');
        delayedEvent('table-refetch', 5000, AGENCY_QUOTES_LISTING_STORE_ID);
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.setState({ error: error.response.data });
      });
  };

  render() {
    return (
      <DialogContent>
        <div className="modal-title">
          Are you sure you want to remove
          <span className="modal-blue-text"> All Expired</span> Quotes? <br />
        </div>
        <FormHelperText className="api-text-error">
          {this.state.error}
        </FormHelperText>
      </DialogContent>
    );
  }
}

export default compose(withSnackbar, withToggleModal)(DeleteExpiredConfirm);
