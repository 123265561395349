import React from 'react';
import Moment from 'moment';

import type {
  PopoverProps as popOverPropsBase,
  ButtonProps,
  BoxProps,
} from '@mui/material';
import {
  Box as MuiBox,
  MenuItem as MuiMenuItem,
  Popover,
  Button as MuiButton,
  Box,
} from '@mui/material';
import { styled, makeStyles } from '@mui/styles';

import DatePickerBase from 'react-datepicker';
import color from 'color';
import { CalendarToday } from '@mui/icons-material';
import Showable from '../../../../../../components/Showable';
import { InputLabelBase } from '../../../../../../components/inputs/InputLabelBase';
import { toUniversalDate } from '../../../../../../utils/date.utils';

export const usePopOverState = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const onOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return {
    open,
    onOpen,
    onClose,
    anchorEl,
  };
};

interface PopOverStateProps {
  children: (args: ReturnType<typeof usePopOverState>) => JSX.Element;
}

const PopOverState = ({ children }: PopOverStateProps) => {
  const methods = usePopOverState();
  return <>{children(methods)}</>;
};

type PopOverProps = popOverPropsBase;

const PopOver = ({
  children,
  id,
  open,
  anchorEl,
  onClose,
  ...props
}: PopOverProps) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    >
      {children}
    </Popover>
  );
};

const PopOverContainer = ({ children, ...props }: BoxProps) => {
  return (
    <Container display="flex" gap="1rem" p={2} pb={1} pr={0} {...props}>
      {children}
    </Container>
  );
};

const Calendar = (props: React.ComponentProps<typeof DatePickerBase>) => {
  const classes = useStyles();

  return (
    <MuiBox
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      className={classes.root}
    >
      <DatePickerBase inline {...props} />
    </MuiBox>
  );
};

interface OptionList {
  title: string;
  children: React.ReactNode;
}

const OptionsList = ({ title, children }: OptionList) => {
  return (
    <div
      className="contrast-text"
      style={{
        textOverflow: 'ellipsis',
        textAlign: 'center',
        overflowY: 'auto',
        maxHeight: '15rem',
        width: '100%',
        height: '100%',
        paddingRight: '1.3rem',
      }}
    >
      <span
        style={{
          fontSize: '1.1re',
          fontWeight: 600,
          display: 'inline-block',
          marginBottom: '0.65rem',
        }}
      >
        {title}
      </span>
      {children}
    </div>
  );
};

type OptionItemProps = {
  label?: string;
} & React.ComponentProps<typeof MenuItem>;

const OptionItem = ({
  value,
  isSelected,
  label,
  ...props
}: OptionItemProps) => {
  return (
    <MenuItem value={value} isSelected={isSelected} {...props}>
      {label}
    </MenuItem>
  );
};

interface BProps extends ButtonProps {
  children?: React.ReactNode;
  showLabel?: boolean;
  iconStyles?: React.CSSProperties;
  isDisabled?: boolean;
}

const Button = ({
  children,
  showLabel = true,
  iconStyles,
  ...props
}: BProps) => {
  return (
    <StyledButton {...props}>
      <Showable show={Boolean(showLabel)}>{children}</Showable>
      <Showable show={!props.disabled}>
        <CalendarToday
          fontSize="small"
          color="primary"
          style={{
            fontSize: '1rem',
            pointerEvents: 'none',
            ...iconStyles,
          }}
        />
      </Showable>
    </StyledButton>
  );
};

const ButtonLabel = ({
  children,
  style = {},
  ...props
}: React.ComponentProps<typeof InputLabelBase>) => {
  return (
    <InputLabelBase
      style={{ paddingTop: 0, lineHeight: 1, ...style }}
      {...props}
    >
      {children}
    </InputLabelBase>
  );
};

type BaseDatePickerProps = {
  label: string;
  required?: boolean;
  LabelProps?: Omit<
    React.ComponentProps<typeof ButtonLabel>,
    'children' | 'translate'
  >;
  ButtonProps?: Omit<React.ComponentProps<typeof Button>, 'onClick'>;
  PopOverProps?: React.ComponentProps<typeof PopOver>;
  CalendarProps?: React.ComponentProps<typeof Calendar>;
} & Pick<
  React.ComponentProps<typeof Calendar>,
  'onChange' | 'selected' | 'minDate' | 'maxDate'
>;

function BaseDatePicker({
  label,
  required = false,
  selected,
  onChange,
  LabelProps,
  ButtonProps,
  PopOverProps,
  CalendarProps,
}: BaseDatePickerProps) {
  const selectedDateMoment = Moment(selected);
  let buttonRenderValue: string;

  if (selectedDateMoment.isValid()) {
    buttonRenderValue = toUniversalDate(selectedDateMoment, {
      placeholder: 'MM/DD/YYYY',
    });
  } else {
    buttonRenderValue = 'Select';
  }

  return (
    <PopOverState>
      {(popOverState) => {
        return (
          <Box>
            <ButtonLabel {...LabelProps} required={required}>
              {label}
            </ButtonLabel>

            <Button {...ButtonProps} onClick={popOverState.onOpen}>
              {buttonRenderValue}
            </Button>

            <PopOver {...popOverState} {...PopOverProps}>
              <Calendar
                {...CalendarProps}
                onChange={(selectedDate, event) => {
                  popOverState.onClose();
                  onChange(selectedDate, event);
                }}
              />
            </PopOver>
          </Box>
        );
      }}
    </PopOverState>
  );
}

export default {
  PopOverState,
  PopOverContainer,
  PopOver,
  Calendar,
  OptionsList,
  OptionItem,
  Button,
  ButtonLabel,
  BaseDatePicker,
} as const;

const MenuItem = styled(MuiMenuItem)(
  ({ theme, isSelected }: { isSelected: boolean; theme: any }) => {
    const contrastedColor =
      theme.palette.mode === 'dark'
        ? color(theme.palette.background.default).lighten(0.25).toString()
        : color(theme.palette.background.default).darken(0.025).toString();

    return {
      transition: 'all 0.25s',
      padding: '0.15rem 0.25rem',
      background: isSelected ? contrastedColor : '',
      borderRadius: 5,
      '&:hover': {
        paddingLeft: '0.5rem',
      },
    };
  }
);

const Container = styled(MuiBox)(({ theme }: { theme: any }) => {
  return {
    backgroundColor: color(theme.palette.background.active)
      .darken(theme.palette.mode === 'dark' ? 0.3 : 0)
      .toString(),
  };
});

const StyledButton = styled(MuiButton)(({ theme }: { theme: any }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.contrastText,
    backgroundColor: theme.palette.background.active,
    borderRadius: 4,
    padding: '7px 11px',
    maxHeight: '3.75rem',
    minWidth: '8.75rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.background.active,
    },
    '&:focus': {
      backgroundColor: theme.palette.background.active,
    },
    '&:disabled': {
      opacity: 1,
      color: theme.palette.text.contrastText,
    },
  };
});

const useStyles = makeStyles<{ palette: any }>(({ palette }) => ({
  root: {
    '& .react-datepicker__header': {
      background: color(palette.background.active)
        .darken(palette.mode === 'dark' ? 0.25 : 0)
        .toString(),
      borderBottom: 'none',
    },

    '& .react-datepicker': {
      background:
        palette.mode === 'dark' ? palette.background.active : '#f5f5f5',
    },

    '& .react-datepicker__day--selected': {
      borderRadius: '3 !important',
      backgroundColor: color(palette.background.active)
        .darken(palette.mode === 'dark' ? 0.3 : 0.05)
        .toString(),
    },

    '& .react-datepicker__current-month': {
      color: palette.text.contrastText,
    },

    '& .react-datepicker__day-names': {
      marginTop: 5,
      background: color(palette.background.active)
        .darken(palette.mode === 'dark' ? 0.25 : 0)
        .toString(),
    },

    '& .react-datepicker__day-name': {
      color: palette.text.contrastText,
    },

    // styling the days
    '& .react-datepicker__day': {
      boxSizing: 'content-box',
      margin: 0,
      padding: '0.166rem',
      borderRadius: 3,
      color: palette.text.contrastText,
      outline: 'none',
    },

    '& .react-datepicker__day:hover': {
      borderRadius: 3,
      backgroundColor: color(palette.background.active)
        .darken(palette.mode === 'dark' ? 0.25 : 0)
        .toString(),
    },
  },
}));
