import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';

// lodash
import _ from 'lodash';

import {
  DialogContent,
  DialogActions,
  DialogContentText,
  useTheme,
  Box as BoxBase,
  styled,
} from '@mui/material';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useSnackbar } from 'notistack';
import CbButton from '../../Buttons/CbButton';
import { withFormController } from '../../hocs/forms';
import { SimpleSelect } from '../../inputs';
import {
  bulkMigrateTeams,
  getAgencyTeams,
} from '../../../console/agencies/_services/teams.service';
import { withShowable } from '../../../console/_global/lib/withShowable';

import { reduceForSelect } from '../../../console/admin/users/UsersUtils';

const Select = withFormController(SimpleSelect);
const Box = withShowable(BoxBase);

const schema = Yup.object().shape({
  targetTeam: Yup.string().label('Target Team').required(),
});

const MigrateAccountsOnTeamChange = ({ data, ...props }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    userId,
    nextTeams,
    teamsRequiringMigration,
    onSuccess,
    onFetch,
    agencyId,
  } = data;

  const fetchTeams =
    typeof onFetch === 'function'
      ? onFetch
      : getAgencyTeams.bind(this, { size: 2000 });

  const { data: allTeams } = useQuery(['migrate', 'accounts', 'teams'], () =>
    fetchTeams().then((resp) => reduceForSelect(resp?.data?.content))
  );

  const availableTeams = React.useMemo(() => {
    return _.intersectionWith(
      allTeams,
      nextTeams,
      (team, nextTeamId) => team.value === nextTeamId
    );
  }, [allTeams, nextTeams]);

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    const payload = {
      sourceTeams: Object.keys(teamsRequiringMigration),
      targetTeam: formData.targetTeam,
    };

    bulkMigrateTeams(userId, { params: { agencyId }, data: payload })
      .then(() => {
        onSuccess();
        props.close();
      })
      .catch(() =>
        enqueueSnackbar('Unable to migrate accounts, please try again', {
          variant: 'error',
        })
      );
  };

  // render logic
  const oldTeams = React.useMemo(
    () =>
      Object.keys(teamsRequiringMigration).map((teamId) => ({
        id: teamId,
        name: teamsRequiringMigration[teamId]?.name,
        totalAccounts: teamsRequiringMigration[teamId]?.totalAccounts,
      })),
    [teamsRequiringMigration]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '3rem 4rem' }}>
          <DialogContentText>
            The following teams cannot be edited at this time as there are still
            user accounts linked to them. Accounts can only be migrated to a
            single target team. Click on the team name to show the list of
            linked accounts.
          </DialogContentText>

          <Box style={{ overflow: 'auto', maxHeight: 250 }}>
            <ul>
              {oldTeams.map((team) => (
                <TeamListItem key={team.id}>
                  {team.name} [{team.totalAccounts} accounts]
                </TeamListItem>
              ))}
            </ul>
          </Box>

          <Select
            name="targetTeam"
            label="Target Team"
            options={availableTeams}
            style={{ backgroundColor: theme.palette.background.modal }}
          />

          <DialogContentText style={{ paddingTop: '2rem' }}>
            By clicking "Migrate," you agree to move all of the linked accounts
            listed here into the selected target team. If you would like to
            migrate one team at a time, please cancel and restart the process.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={props.close}>
            Cancel
          </CbButton>
          <CbButton styleName="ctaButton" type="submit">
            Migrate
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

// helper components

const WarningIcon = () => (
  <ReportProblemIcon style={{ color: '#ff6736', marginRight: '1rem' }} />
);

const TeamListItem = styled('li')(({ theme }) => ({
  fontSize: '1.2rem',
  color: theme.palette.text.secondary,
}));

// config
export const MigrateAccountsOnTeamChangeConfig = {
  MigrateAccountsOnTeamChange: {
    component: MigrateAccountsOnTeamChange,
    config: {
      fullWidth: true,
      title: 'Account Migration Required',
      icon: <WarningIcon />,
    },
  },
};
