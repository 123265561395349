import _ from 'lodash';
import Moment from 'moment';
import * as Yup from 'yup';
import React, { useRef } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import { withSnackbar } from 'notistack';
import { compose } from 'redux';
import clsx from 'classnames';
import { DialogActions, DialogContent, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

// validations
import '../../../../../_global/lib/validations/PhoneSchemaType';
// components
import { SurplusUWBindQuote } from './SurplusUWBindQuote';
import { CowbellCompliance } from '../../../../../../inbox/surplusCompliance/CowbellCompliance';
import withRouter from '../../../../../../components/hocs/withRouter';
import { TextFieldBase } from '../../../../../../components/inputs/TextFieldBase';
import { withFormController } from '../../../../../../components/hocs/withFormController';
import CbButton from '../../../../../../components/Buttons/CbButton';
import { PhoneField as PhoneFieldBase } from '../../../../../../components/inputs/PhoneField';
// platform helpers
import { getShortDateTimeFromUtc } from '../../../../../../utils/csvUtils';
import {
  setAgencyBindInfo,
  setRoute,
} from '../../../../../../inbox/quote.reducer';
// actions
import { bind250Quote } from '../../../../../../api/p250.service';

const complianceStates = ['LA', 'MT'];

const styles = ({ config, palette }) => {
  return {
    pagination: {
      fontSize: config.textSizes.tertia,
      color: palette.primary.contrastText,
      paddingLeft: '3rem',
    },
    icon: {
      position: 'absolute',
      right: 0,
      paddingRight: '5rem',
    },
  };
};
const schema = Yup.object().shape({
  customerFirstName: Yup.string()
    .label("Policy Holder's First Name")
    .required(),
  customerLastName: Yup.string().label("Policy Holder's Last Name ").required(),
  customerEmail: Yup.string().label("Policy Holder's Email").email().required(),
  customerPhone: Yup.number()
    .label("Policy Holder's Phone")
    .fromPhone()
    .phone()
    .required(),
});

const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);

export const UWBindQuoteConfirmation = compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(({ data, dispatch, setHandleCancel, history, enqueueSnackbar, ...props }) => {
  const surplusCowbellInsurance = _.get(
    data,
    'payload.surplusCowbellInsurance',
    false
  );
  const policyEffectiveDate = getShortDateTimeFromUtc(
    Moment(data.payload.effectiveDate).utc()
  );
  const isSurplus = _.get(data, 'payload.isSurplus', false);

  const bindDetails = useRef({
    customerFirstName: data.account.customerFirstName,
    customerLastName: data.account.customerLastName,
    customerEmail: data.account.customerEmail,
    customerPhone: data.account.customerPhone,
  });

  const { handleSubmit, setValue, ...methods } = useForm({
    validationSchema: schema,
  });

  const [page, setPage] = React.useState(1);
  const { isSubmitting } = methods.formState;
  const { classes } = props;
  const { payload, quoteNumber, companyName } = data;
  const isCompliance = isSurplus
    ? complianceStates.includes(data.account.state) &&
      !data.account.surplus.signatureOnFile
    : false; // checking whether is LA/MT and signature does not exist

  React.useEffect(() => {
    const {
      customerFirstName,
      customerLastName,
      customerEmail,
      customerPhone,
    } = data.account;
    dispatch(
      setAgencyBindInfo({
        customer: {
          customerFirstName,
          customerLastName,
          customerEmail,
          customerPhone,
        },
      })
    );
    dispatch(setRoute({ from: 'bind' }));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const {
      customerFirstName,
      customerLastName,
      customerEmail,
      customerPhone,
    } = bindDetails.current;
    setValue('customerFirstName', customerFirstName);
    setValue('customerLastName', customerLastName);
    setValue('customerPhone', customerPhone);
    setValue('customerEmail', customerEmail);
    // eslint-disable-next-line
  }, [page]);

  const handleCancel = () => {
    if (typeof setHandleCancel === 'function') {
      setHandleCancel();
    }
  };

  const handleBindQuote = (formValues) => {
    return bind250Quote(
      {},
      {
        ...payload,
        customerEmail: formValues.customerEmail,
        customerFirstName: formValues.customerFirstName,
        customerLastName: formValues.customerLastName,
        customerPhone: formValues.customerPhone,
      }
    )
      .then(() => {
        enqueueSnackbar('Quote bound', { variant: 'success' });
        handleCancel();
        setTimeout(() => {
          history.push(`/admin/inbox`);
        }, 2000);
      })
      .catch(() => {
        enqueueSnackbar('Failed to bind quote', { variant: 'error' });
        handleCancel();
      });
  };

  const onSubmit = (formValues) => {
    dispatch(setAgencyBindInfo({ customer: formValues }));
    dispatch(setRoute({ from: 'bind' }));

    if (isSurplus) {
      setPage(2);
      bindDetails.current = formValues;
    } else {
      return handleBindQuote(formValues);
    }
  };

  return (
    <>
      {page === 1 && (
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent style={{ padding: '2rem 5rem 0 5rem' }}>
              <div className="modal-title">
                Are you sure you want to
                <span className={clsx('greenText', 'boldText')}>
                  {' '}
                  Bind Quote
                </span>
                <span className={clsx('modal-blue-text', 'boldText')}>
                  {' '}
                  {quoteNumber}
                </span>
                <p style={{ margin: 0 }}>
                  for{' '}
                  <span className={clsx('modal-blue-text', 'boldText')}>
                    {companyName}
                  </span>{' '}
                  with policy effective date{' '}
                  <span className={clsx('greenText', 'boldText')}>
                    {' '}
                    {policyEffectiveDate}
                  </span>
                  ?
                </p>
              </div>

              <p>
                <h3 className={clsx('contrastText')}>
                  Please confirm below policy holder details
                </h3>
              </p>

              <Grid container spacing={4}>
                <Grid item sm={6} style={{ paddingBottom: 0 }}>
                  <TextField
                    name="customerFirstName"
                    label="Policy Holder First Name"
                    placeholder="Please enter policy holder first name"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} style={{ paddingBottom: 0 }}>
                  <TextField
                    name="customerLastName"
                    label="Policy Holder Last Name"
                    placeholder="Please enter policy holder last name"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} style={{ paddingTop: 0 }}>
                  <TextField
                    name="customerEmail"
                    label="Policy Holder Email"
                    type="email"
                    placeholder="Please enter policy holder email"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} style={{ paddingTop: 0 }}>
                  <PhoneField
                    name="customerPhone"
                    label="Policy Holder Phone"
                    required
                    fullWidth
                    placeholder="(___)___-____"
                  />
                </Grid>
              </Grid>
              <p className={clsx('contrastText')}>
                <i>
                  Note: All other quotes for this customer will be invalidated.
                </i>
                <br />
                {surplusCowbellInsurance && isSurplus && (
                  <i className={clsx('contrastText')}>
                    Surplus Compliance: Cowbell Insurance Agency.
                  </i>
                )}
              </p>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                justifyContent={!isSurplus ? 'flex-end' : 'space-between'}
              >
                {isSurplus && (
                  <Grid className={classes.pagination}>
                    Step 1 of {isCompliance ? 3 : 2}: Policyholder Details
                  </Grid>
                )}
                <Grid>
                  <CbButton
                    styleName="cancel"
                    onClick={handleCancel}
                    buttonText="Cancel"
                  />
                  <CbButton
                    styleName="ctaButton"
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    buttonText={isSurplus ? 'Next' : 'Bind Quote'}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </FormContext>
      )}
      {page === 2 && surplusCowbellInsurance && (
        <CowbellCompliance
          handleSubmit={handleBindQuote}
          setPage={setPage}
          isSubmitting={isSubmitting}
        />
      )}
      {page === 2 && !surplusCowbellInsurance && (
        <SurplusUWBindQuote
          data={data}
          setCustomerPage={setPage}
          setHandleCancel={handleCancel}
        />
      )}
    </>
  );
});

export default UWBindQuoteConfirmation;
