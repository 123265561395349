import React from 'react';
import { DialogContent, DialogActions } from '@mui/material';
import CbButton from '../Buttons/CbButton';

const uploadSurplusConfirmModal = (props) => {
  return (
    <>
      <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
        <h2 style={{ fontSize: '1rem' }}>
          The agent surplus license upload was successful! Please wait for
          cowbell to verify notice.
        </h2>
      </DialogContent>
      <DialogActions>
        <CbButton
          onClick={props.close}
          type="submit"
          styleName="ctaButton"
          buttonText="Ok"
        />
      </DialogActions>
    </>
  );
};

export const uploadSurplusConfirm = {
  uploadSurplusConfirmModal: {
    component: uploadSurplusConfirmModal,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      title: 'Upload Modals',
    },
  },
};
