import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import _ from 'lodash';
// ui
import { useTheme } from '@mui/material/styles';
// components
import { FormContext, useForm } from 'react-hook-form-4';
import {
  Box,
  DialogContent,
  DialogActions,
  FormHelperText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { PubSub } from '../../../utils/eventUtils';

import { withFormController } from '../../../components/hocs/withFormController';
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import CBButton from '../../../components/Buttons/CbButton';
import { CircularProgressWithLabel } from '../../../components/CircularProgressWithlabel';

import {
  installmentPlanForm,
  getXpressDocument,
  confirmFinancing,
} from '../_services/BillingService';
import { downloadAWSDoc } from '../../../policies/PolicyService';
import { fetchAgencyDetails } from '../../../agencies/AgencyService';
import { saveBlobFile, moneyFormat2Decimals } from '../../../utils/appUtils';
import { ProductTypes } from '../../../types';
import { manageAPIError } from '../../../utils';
import { toUniversalUtcDate } from '../../../utils/date.utils';

const TextField = withFormController(TextFieldBase);

const InstallmentPlan = ({ close, classes, data }) => {
  const accountDetails = data.firmographicData ?? {};

  const theme = useTheme();

  const textStyle = {
    fontSize: '20px',
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
  };

  const [installmentData, setData] = useState('');
  const [error, setError] = useState(false);
  const [agencyDetailsMissing, setDetails] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({});

  useEffect(() => {
    const brokerFee = _.isUndefined(data.brokerFee) ? 0 : data.brokerFee;
    const mgaFee = _.isUndefined(data.mgaFee) ? 0 : data.mgaFee;

    const payload = {
      quoteNumber: data.quoteNumber,
      customerName: `${data.customerFirstName} ${data.customerLastName}`,
      customerPhone: data.customerPhone,
      customerEmail: data.customerEmail,
      addressLine1: accountDetails.address1,
      addressLine2: accountDetails.address2 || '',
      city: accountDetails.city,
      state: accountDetails.state,
      country: accountDetails.country,
      zip: accountDetails.zipCode,
      policyNumber: data.policyNumber,
      policyEffectiveDate: data.effectiveDate,
      policyTermMonths: 12,
      premium: (data.totalPremium - brokerFee - mgaFee).toFixed(2),
      earnedTaxes: brokerFee + mgaFee,
      financedTaxes: 0,
      policyCreated: data.created,
      policyId: data.id,
      productType: accountDetails.product,
      accountName: accountDetails.name,
      isEndorsement: _.get(data, 'isEndorsement', false),
    };

    if (
      accountDetails.product === ProductTypes.p250 ||
      accountDetails.product === ProductTypes.p100_pro
    ) {
      fetchAgencyDetails(data.agencyId)
        .then((resp) => {
          const { name, address1, address2, city, state, zipCode } = resp.data;
          payload.agencyId = data.agencyId;
          payload.agencyName = name;
          payload.agencyAddress = address1 + address2;
          payload.agencyCity = city;
          payload.agencyState = state;
          payload.agencyZip = zipCode;

          if (data.agencyId && name && address1 && city && state && zipCode) {
            installmentPlanForm({}, payload)
              .then((response) => {
                setData(response.data);
              })
              .catch((error) => {
                setDetails(false);
                setError(
                  _.get(
                    error.response,
                    'data.message',
                    _.get(
                      error.response,
                      'data',
                      'Something went wrong. Try again later.'
                    )
                  )
                );
              });
          } else {
            setDetails(true);
            setError(
              'Agency Details Missing. Please contact your agent or support@cowbellcyber.ai.'
            );
          }
        })
        .catch((error) => {
          setDetails(false);
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Agency Details Missing. Please contact your agent or support@cowbellcyber.ai.'
              )
            )
          );
        });
    } else {
      installmentPlanForm({}, payload)
        .then((resp) => {
          setData(resp.data);
        })
        .catch((error) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Something went wrong. Try again later.'
              )
            )
          );
        });
    }

    // eslint-disable-next-line
  }, []);

  const getXpressDoc = () => {
    const { policyNumber, companyName } = data;

    const payload = {
      policyId: data.id,
      accountId: accountDetails.accountId,
      createdDate: data.created,
    };
    getXpressDocument(payload)
      .then((response) => {
        const url = _.get(response, 'data');
        downloadAWSDoc(url)
          .then((resp) => {
            saveBlobFile(
              resp,
              `${companyName}-${policyNumber}-Xpress Document`,
              '.pdf'
            );
          })
          .catch(() => {
            setError('Unable to download the Policy Documents');
          });
      })
      .catch((error) => {
        setDetails(false);
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong. Try again later.'
            )
          )
        );
      });
  };

  const onSubmit = (formData) => {
    const payload = {
      policyId: data.id,
      financeQuoteNumber: installmentData.FinanceQuoteNumber,
      amountFinanced: installmentData.AmountFinanced,
      apr: installmentData.APR,
      numberOfIntsallments: installmentData.NumberOfInstallments,
      totalPremium: installmentData.TotalPremium,
      downPayment: installmentData.DownPayment,
      totalPaymentAmount: installmentData.TotalPaymentAmount,
      installmentAmount: installmentData.InstallmentAmount,
      financeCharge: installmentData.FinanceCharge,
      email: data.customerEmail,
      signature: formData.name,
      productType: accountDetails.product,
    };

    if (formData.name) {
      setError('');
      return confirmFinancing({}, payload)
        .then(() => {
          close();
          PubSub.publish('financing:accpeted', {
            isDownPayment: true,
          });
        })
        .catch((error) => {
          setDetails(false);

          setError(
            manageAPIError(
              error,
              'Something went wrong applying premium financing. Please try again.'
            )
          );
        });
    }
    setDetails(false);
    setError('Please enter full name.');
  };

  return (
    <>
      {installmentData ? (
        <section className={classes.cardContentContainer}>
          <FormContext>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent classes={{ root: classes.container }}>
                <section className={classes.policyDetails}>
                  <p
                    className={`${classes.title} ${classes.bold} ${classes.horizontalLine}`}
                  >
                    Installment Plan Details
                  </p>
                  <section className={classes.line}>
                    <p>APR</p>
                    <p>{installmentData.APR}%</p>
                  </section>
                  <section className={classes.line}>
                    <p>Total Premium</p>
                    <p>{moneyFormat2Decimals(installmentData.TotalPremium)}</p>
                  </section>
                  <section
                    className={`${classes.line} ${classes.horizontalLine} ${classes.bold}`}
                  >
                    <p>Down Payment</p>
                    <p>{moneyFormat2Decimals(installmentData.DownPayment)}</p>
                  </section>
                  <section className={classes.line}>
                    <p>Amount Financed</p>
                    <p>
                      {moneyFormat2Decimals(installmentData.AmountFinanced)}
                    </p>
                  </section>
                  <section
                    className={`${classes.line} ${classes.horizontalLine}`}
                  >
                    <p>Finance Charge</p>
                    <p>{moneyFormat2Decimals(installmentData.FinanceCharge)}</p>
                  </section>
                  <section
                    className={`${classes.line} ${classes.horizontalLine}`}
                  >
                    <p>Total of Payment</p>
                    <p>
                      {moneyFormat2Decimals(installmentData.TotalPaymentAmount)}
                    </p>
                  </section>

                  <section className={`${classes.line} ${classes.bold}`}>
                    <p>Installment Amount (x10)</p>
                    <p>
                      {moneyFormat2Decimals(installmentData.InstallmentAmount)}
                    </p>
                  </section>
                  <section className={classes.line}>
                    <p>Your installments are scheduled</p>
                    <p>Monthly</p>
                  </section>
                  <section className={`${classes.line}`}>
                    <p>
                      Your first installment in the amount of{' '}
                      {moneyFormat2Decimals(installmentData.InstallmentAmount)}{' '}
                      is due on
                    </p>
                    <p>
                      {toUniversalUtcDate(
                        Moment(installmentData.FirstInstallmentDue).add(
                          1,
                          'day'
                        )
                      )}
                    </p>
                  </section>
                </section>
                <section className={classes.terms}>
                  <h3>Acknowledgement of Premium Financing:</h3>
                  <p>
                    (A){' '}
                    <span>
                      I am entering into a premium finance agreement (“PFA”)
                      with gotoPremiumFinance.com
                    </span>
                  </p>
                  <p>
                    (B){' '}
                    <span>
                      A copy of the completed PFA disclosing all terms and
                      conditions of the financing transaction to which I am
                      agreeing can be viewed by clicking on this link.
                    </span>
                  </p>
                  <p>
                    (C){' '}
                    <Box
                      component="span"
                      className={classes.doc}
                      onClick={getXpressDoc}
                    >
                      I will have 10 days to disaffirm the PFA at no cost to me
                      if I do not agree to the terms and conditions of the PFA.
                    </Box>
                  </p>
                  <p>
                    (D){' '}
                    <span>
                      If I choose to disaffirm the PFA, I will be required to
                      emit the annual payment in full to Cowbell Cyber within 30
                      days or risk cancellation of my insurance policy.
                    </span>
                  </p>
                  <p>
                    (E){' '}
                    <span>
                      The PFA will include, among other things, my granting of a
                      power of attorney to cancel my insurance policy if I am in
                      default under the PFA.
                    </span>
                  </p>
                </section>
                <div className={classes.text}>
                  <TextField
                    id="standard-basic"
                    placeholder="enter full name"
                    name="name"
                    control={control}
                  />
                </div>
                {error && (
                  <FormHelperText className="api-text-error">
                    {error}
                  </FormHelperText>
                )}
              </DialogContent>
              <DialogActions style={{ justifyContent: 'flex-start' }}>
                <CBButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  styleName="ctaButton"
                  buttonText="I Accept"
                />
              </DialogActions>
            </form>
          </FormContext>
        </section>
      ) : (
        <>
          {!error ? (
            <div className={classes.loading}>
              <CircularProgressWithLabel
                value={
                  <>
                    Installment Plan <br />
                    Coming Up.
                    <br /> Please Wait.
                  </>
                }
                size={200}
                style={textStyle}
              />
            </div>
          ) : (
            <>
              {agencyDetailsMissing ? (
                <p style={textStyle}>
                  Agency Details are missing.
                  <br /> Please contact support@cowbellcyber.ai for more
                  information.
                </p>
              ) : (
                <>
                  <p style={textStyle}>Something went wrong.</p>
                  <div className={classes.flexbox}>
                    <FormHelperText className="api-text-error">
                      {error}
                    </FormHelperText>
                  </div>
                </>
              )}
            </>
          )}
          <DialogActions style={{ justifyContent: 'flex-start' }} />
        </>
      )}
    </>
  );
};

const styles = ({ config, palette }) => {
  return {
    cardContentContainer: {
      overflow: 'auto',
      maxHeight: '40rem',
    },
    terms: {
      fontSize: '1.167rem',
      color: palette.primary.main,
      paddingTop: '0.7rem',
      '& h3': {
        margin: '0 !important',
        fontWeight: config.weights.bold,
        lineHeight: 1.43,
        paddingLeft: '1.4rem',
      },
      '& p': {
        margin: '0.7rem 0 !important',
        lineHeight: 1.14,
      },
      '& span': {
        fontStyle: 'italic',
      },
    },
    bold: {
      fontWeight: config.weights.bold,
    },
    horizontalLine: {
      borderBottom: `1px solid ${palette.primary.modalBorder}`,
    },
    policyDetails: {
      width: '100%',
      borderRadius: 5,
      border: `1px solid ${palette.primary.modalBorder}`,
      padding: '1rem 1.33rem',

      '& p': {
        margin: '0 !important',
        fontSize: '1.33rem',
        lineHeight: 1.5,
        color: palette.primary.main,
      },
    },
    line: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '5px',
    },
    container: {
      width: '50rem',
      padding: '0 2.5rem',
    },
    inputFields: {
      justifyContent: 'space-around',
    },
    title: {
      paddingBottom: '5px',
    },
    doc: {
      color: config.colors.cowbellBlue,
      textDecoration: 'underline',
      fontWeight: config.weights.bold,
      cursor: 'pointer',
    },
    text: {
      '& .MuiInputBase-input': {
        borderRadius: 1,
        borderBottom: 'solid',
        borderStyle: 'inherit',
        borderBottomWidth: 'thin',
        background: palette.background.modal,
        width: 'auto',
        '&:focus': {
          color: palette.primary.contrastText,
          background: 'inherit',
        },
      },
    },
    flexbox: {
      display: 'flex',
      justifyContent: 'center',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '2rem',
    },
  };
};

export default withStyles(styles)(InstallmentPlan);
