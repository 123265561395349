import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Box, Grid, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import Numeral from 'numeral';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import Moment from 'moment';
import Skeleton from '@mui/lab/Skeleton';

import { getQuoteDetails } from '../../../api/p250.service';
import { fetchQuoteDetails } from '../../../inbox/QuotesService';
import CompareQuoteCoverages from './CompareQuoteCoverages';
import { CoverageInfoMapping } from '../../../utility/CoverageInfoMapping';
import { ProductTypes } from '../../../types';

export const CompareQuotesModal = ({ data = [], ...props }) => {
  const classes = useStyles();

  const { quotes, loading, isP100 } = use3Quotes(data);

  const memoizedCompanyInfoLeftColumn = useMemo(
    () => getCompanyInfoLeftColumn(quotes),
    [quotes]
  );
  const memoizedCompanyInfoRightColumn = useMemo(
    () => getCompanyInfoRightColumn(quotes),
    [quotes]
  );
  const memoizedQuotesInfoArray = useMemo(
    () => getQuoteInfoArray(quotes, isP100),
    [isP100, quotes]
  );

  return (
    <>
      <Dialog {...props}>
        <div style={{ width: '95rem' }}>
          <Box className={classes.closeButton} onClick={props.close}>
            <CloseIcon />
          </Box>
          <div className={classes.header}>
            <h1 className={classes.headerTitle}>Compare Quotes</h1>
          </div>
          <Box className={classes.subHeaderConatiner}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              {Object.keys(memoizedCompanyInfoLeftColumn).map((key) => (
                <React.Fragment key={key}>
                  <Grid item xs={6}>
                    <Box className={classes.subHeaderLabel}>{key}</Box>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.subHeaderValue}>
                      {loading ? (
                        <Skeleton variant="text" width="90%" height={20} />
                      ) : (
                        memoizedCompanyInfoLeftColumn[key] || '-'
                      )}
                    </div>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              {Object.keys(memoizedCompanyInfoRightColumn).map((key) => (
                <React.Fragment key={key}>
                  <Grid item xs={6}>
                    <Box className={classes.subHeaderLabel}>{key}</Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.subHeaderValue}>
                      {loading ? (
                        <Skeleton variant="text" width="90%" height={23} />
                      ) : (
                        memoizedCompanyInfoRightColumn[key] || '-'
                      )}
                    </Box>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
          <div className={classes.quoteInfoContainer}>
            <div className={classes.quoteInfoColumn}>
              <div className={classes.quoteInfoColumnHeader}>
                {loading ? (
                  <Skeleton variant="text" width="90%" height={23} />
                ) : (
                  <h3 className={classes.quoteInfoColumnHeaderItem}>
                    Quote Number
                  </h3>
                )}
              </div>
              <div>
                {quoteInfoTableRowLabels.map((row) => (
                  <div key={row.label} className={classes.quoteInfoColumnItem}>
                    {loading ? (
                      <Skeleton variant="text" width="90%" height={23} />
                    ) : (
                      <>
                        <span>{row.label}</span>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <div className={classes.quoteInfoColumnFotter}>
                <div className={classes.quoteInfoColumnFotterItem}>
                  {loading ? (
                    <Skeleton variant="text" width="90%" height={23} />
                  ) : (
                    <h3 className={classes.quoteInfoColumnFooterItemOverride}>
                      Final Premium
                    </h3>
                  )}
                </div>
              </div>
            </div>
            {memoizedQuotesInfoArray.map((quote, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={classes.quoteInfoColumn}
                    key={quote.quoteNumber}
                  >
                    <div
                      className={clsx(
                        classes.quoteInfoColumnHeader,
                        classes.quoteInfoColumnHeaderOverride
                      )}
                    >
                      {loading ? (
                        <Skeleton variant="text" width="90%" height={23} />
                      ) : (
                        <>
                          <h3 className={classes.quoteInfoColumnHeaderItem}>
                            {quote.quoteNumber}
                          </h3>
                          {quote.surplusAvailable && !isP100 && (
                            <span className={classes.isSurplusContainer}>
                              {quote.surplusAvailable}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      {quote.dataRows.map((row) => (
                        <div
                          key={row}
                          className={clsx(
                            classes.quoteInfoColumnItem,
                            classes.quoteInfoColumnItemOverride
                          )}
                        >
                          {loading ? (
                            <Skeleton variant="text" width="90%" height={15} />
                          ) : (
                            row
                          )}
                        </div>
                      ))}
                    </div>
                    <div
                      className={clsx(
                        classes.quoteInfoColumnFotter,
                        classes.quoteInfoColumnFotterOverride
                      )}
                    >
                      <div
                        className={clsx(
                          classes.quoteInfoColumnFotterItem,
                          classes.quoteInfoColumnFooterItemOverride
                        )}
                      >
                        {loading ? (
                          <Skeleton variant="text" width="90%" height={20} />
                        ) : (
                          quote.totalPremium
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className={classes.compareQuotesContainer}>
            <CompareQuoteCoverages
              quotes={quotes}
              loading={loading}
              isP100={isP100}
              showFooter
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(({ palette, config }) => ({
  closeButton: {
    height: '2rem',
    width: '2rem',
    padding: '0.083rem',
    border: `2.25px solid ${palette.primary.modalBorder}`,
    borderRadius: '1rem',
    position: 'absolute',
    right: 15,
    top: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.166rem',
  },
  compareQuotesContainer: {
    margin: '0 auto',
    padding: 0,
    width: '97.5%',
    border: 'none',
  },
  header: {
    marginTop: '2.08rem',
    height: '2.08rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },
  headerTitle: {
    fontSize: config.textSizes.paragon,
    fontWeight: config.weights.bold,
    color: palette.primary.main,
    paddingBottom: '0.085rem',
    textTransform: 'uppercase',
    borderBottom: `1px solid ${palette.primary.modalBorder}`,
  },
  isSurplusContainer: {
    height: '2.66rem',
    width: '2.66rem',
    borderRadius: '1.33rem',
    border: `3px solid ${palette.primary.title}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '1rem',
    textTransform: 'uppercase',
    fontSize: config.textSizes.paragon,
  },
  quoteInfoContainer: {
    width: '97.5%',
    display: 'flex',
    margin: '1.66rem auto 0 auto',
    color: palette.primary.main,
  },
  quoteInfoColumn: {
    width: '25%',
  },
  quoteInfoColumnHeader: {
    height: '4.65rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '1.66rem',
    borderTop: `1px solid ${palette.primary.modalBorder}`,
  },
  quoteInfoColumnHeaderOverride: {
    justifyContent: 'center',
    borderLeft: `1px solid ${palette.primary.modalBorder}`,
    paddingRight: 0,
  },
  quoteInfoColumnHeaderItem: {
    fontSize: config.textSizes.paragon,
    fontWeight: config.weights.normal,
    margin: 0,
  },
  quoteInfoColumnFotter: {
    height: '4.65rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    textAlign: 'right',
  },
  quoteInfoColumnFotterOverride: {
    textAlign: 'center',
    justifyContent: 'center',
    borderLeft: `1px solid ${palette.primary.modalBorder}`,
  },
  quoteInfoColumnFotterItem: {
    fontSize: config.textSizes.paragon,
    margin: 0,
    width: '100%',
    borderTop: `1px solid ${palette.primary.modalBorder}`,
    borderBottom: `1px solid ${palette.primary.modalBorder}`,
    height: '4rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '1.66rem',
  },
  quoteInfoColumnFooterItemOverride: {
    justifyContent: 'center',
    paddingRight: 0,
    fontWeight: config.weights.normal,
    fontSize: config.textSizes.paragon,
  },
  quoteInfoColumnItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '1.66rem',
    height: '2.2rem',
    fontSize: config.textSizes.tertia,
    '&:nth-child(odd)': {
      backgroundColor: palette.background.oddRow,
    },
  },
  quoteInfoColumnItemOverride: {
    justifyContent: 'center',
    borderLeft: `1px solid ${palette.primary.modalBorder}`,
    padding: 0,
    paddingRight: 0,
  },
  subHeaderConatiner: {
    width: '60%',
    display: 'flex',
    flex: 1,
    margin: '27px auto',
    justifyContent: 'center',
    color: palette.primary.main,
    fontSize: config.textSizes.tertia,
  },
  subHeaderLabel: {
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  subHeaderValue: {
    textAlign: 'left',
    marginLeft: '1.25rem',
    fontWeight: config.weights.bold,
    lineHeight: 1.4,
  },
  tooltip: {
    width: '1.5rem',
    marginRight: 0,
    paddingRight: 0,
  },
}));

const use3Quotes = (data) => {
  const [quotes, setQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isP100, setIsP100] = useState(false);

  // eslint-disable-next-line no-shadow
  const fetch3Quotes = useCallback((quotes) => {
    if (quotes.length > 3) {
      // eslint-disable-next-line no-param-reassign
      quotes = quotes.slice(0, 3);
    }
    if (quotes.every((quote) => quote.product === ProductTypes.p250)) {
      const promises = quotes.map((quote) =>
        getQuoteDetails({ quoteId: quote.id })
      );
      return Promise.all(promises);
    }
    if (quotes.every((quote) => quote.product === ProductTypes.p100)) {
      setIsP100(true);
      const promises = quotes.map((quote) => fetchQuoteDetails(quote.id));
      return Promise.all(promises);
    }
    return Promise.reject(new Error('Request Failed'));
  }, []);

  useEffect(() => {
    if (data.length === 0) return;
    let isMounted = true;
    setIsLoading(true);
    fetch3Quotes(data)
      .then((responses) => {
        // eslint-disable-next-line no-shadow
        const quotes = responses.map((response) => response.data);
        // Make sure there is always 3 columnss on the compare quote "table"

        if (quotes.length === 2) {
          quotes.push('backfill');
        }

        if (isMounted) {
          setQuotes(quotes);
          setIsLoading(false);
        }
      })
      .catch(() => (isMounted ? setQuotes([]) : null));

    return () => (isMounted = false);
  }, [data, fetch3Quotes]);

  return { quotes, loading: isLoading, isP100 };
};

const getCompanyInfoLeftColumn = (quotes) => ({
  'named insured': _.get(quotes, '[0].companyName'),
  'insured State': _.get(quotes, '[0].firmographicData.state'),
  'Mailing Address': (() => {
    const addr = _.get(quotes, '[0].firmographicData.address1');
    const state = _.get(quotes, '[0].firmographicData.state');
    const zip = _.get(quotes, '[0].firmographicData.zipCode');
    if (state && zip) return `${addr}, ${state} ${zip}`;
    return undefined;
  })(),
});

const quoteInfoTableRowLabels = [
  { label: 'Quote Expires', msg: '' },
  { label: 'Quote Created', msg: '' },
  { label: 'Policy Effective', msg: '' },
  { label: 'Policy Expiration', msg: '' },
  { label: 'Policy Term', msg: '' },
  {
    label: 'Aggregate Limit',
    msg: CoverageInfoMapping['Aggregate Limit'].tooltip,
  },
  { label: 'Deductible', msg: CoverageInfoMapping.Deductible.tooltip },
];

const getCompanyInfoRightColumn = (quotes) => ({
  'Projected Revenue': formatCash(
    _.get(quotes, '[0].firmographicData.revenue')
  ),
  'Number of Employes': _.get(quotes, '[0].firmographicData.noOfEmployeesAll'),
  'Year Stablished': _.get(quotes, '[0].firmographicData.yearEstablished'),
});

const getQuoteInfoArray = (quotes, is100Quote) => {
  if (!quotes.length) {
    // eslint-disable-next-line no-param-reassign
    quotes = [{}, {}, {}];
  }

  const initialRequestData = is100Quote
    ? 'initialRequestData'
    : 'initial250RequestData';

  return quotes.map((quote) => ({
    quoteNumber: _.get(quote, 'quoteNumber') || '-',
    totalPremium: formatCash(_.get(quote, 'totalPremium')),
    surplusAvailable: isSurplus(
      _.get(quote, 'firmographicData.surplusAvailable', undefined)
    ),

    dataRows: Object.values({
      expiresOn: formatDate(_.get(quote, 'expiresOn')),
      created: formatDate(_.get(quote, 'created')),
      policyEffective: formatDate(
        _.get(quote, `${initialRequestData}.effectiveDate`)
      ),
      policyExpires: formatDate(_.get(quote, `${initialRequestData}.endDate`)),
      policyTerm: getDateRange(
        _.get(quote, `${initialRequestData}.effectiveDate`),
        _.get(quote, `${initialRequestData}.endDate`)
      ),
      limit: formatCash(_.get(quote, `${initialRequestData}.limit`)),
      deductible: formatCash(_.get(quote, `${initialRequestData}.deductible`)),
    }),
  }));
};

// eslint-disable-next-line no-shadow
const isSurplus = (isSurplus) => {
  if (isSurplus === undefined) {
    return '';
  }
  if (isSurplus) {
    return 'S';
  }
  return 'A';
};

const formatDate = (date) => {
  if (!date) {
    return '-';
  }
  return Moment(date).format('MMM D, YYYY h:mm A');
};

const getDateRange = (from, to, unit = 'months') => {
  if (from && to) {
    const months = Moment(to).diff(from, unit);
    if (months && months > 1) {
      return `${months} ${unit}`;
    }
    if (months) {
      return `${months} ${unit.slice(0, -1)}`;
    }
    return '-';
  }
  return '-';
};

const formatCash = (cash) => {
  if (!cash) {
    return '-';
  }
  return Numeral(cash).format('$0,0.[00]');
};

export const CompareQuotesModalConfig = {
  CompareQuotesModal: {
    component: CompareQuotesModal,
    config: {
      fullWidth: false,
      maxWidth: false,
      title: 'Compare Quotes',
      override: true,
    },
  },
};
