import { getRegionByProduct } from '../../i18n/utils';
import type { GetPrimeXPolicyDetails } from '../policies.api.types';

export const transformPrimeXPolicyDetailsResponse = (
  data: GetPrimeXPolicyDetails
) => {
  const language = getRegionByProduct(data.policy.product);
  return {
    ...data,
    ...data.policy,
    agencyBilled: data.firmographicData.agencyBilled,
    cowbellRiskFactors: data.cowbellRiskFactors,
    firmographicData: {
      ...data.firmographicData,
      language,
    },
    language,
  };
};
