import React from 'react';
import { compose } from 'redux';

import { useForm, FormContext } from 'react-hook-form-4';
import _ from 'lodash';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import CbButton from '../../Buttons/CbButton';
import { regeneratePolicyInvoice } from '../../../api/policies.api';

export const RegeneratePolicyInvoice = compose(
  withStyles(({ config }) => ({
    highlight: {
      color: config.colors.cowbellBlue,
      fontWeight: config.weights.bold,
    },
  }))
)(({ data, classes, ...props }) => {
  const { id, product, policyNumber } = data;
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, ...methods } = useForm({});

  // sendEmail should be sent as false for regeneration apis.
  // We don't want multiple emails going out.
  const onSubmit = async () => {
    return regeneratePolicyInvoice(product, id, {
      params: { sendEmail: false },
    })
      .then(() => {
        enqueueSnackbar(
          'Policy submitted for Invoice Regeneration!. Updated invoice will be ready in about 2 minutes',
          { variant: 'success' }
        );
        props.close();
      })
      .catch((error) => {
        enqueueSnackbar(
          _.get(error, 'response.data', 'Invoice Regeneration request failed!'),
          {
            variant: 'error',
          }
        );
      });
  };

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className="paf">
            <DialogContentText>
              Are you sure you want to regenerate the Invoice for #
              <span className={classes.highlight}>{policyNumber}</span> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CbButton onClick={props.close} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton type="submit" styleName="ctaButton">
              Regenerate
            </CbButton>
          </DialogActions>
        </form>
      </FormContext>
    </>
  );
});

export const RegeneratePolicyInvoiceModalConfig = {
  RegeneratePolicyInvoice: {
    component: RegeneratePolicyInvoice,
    config: {
      fullWidth: true,
      title: 'Regenerate Invoice',
    },
  },
};
