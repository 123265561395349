import { useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import type { Quote } from '../../../../types/quotes/quote.entity';
import type { Binder } from '../../../accounts/summary/types/binder.types';
import type { Policy } from '../../../accounts/summary/types/policy.types';
import type { AgencyDashboardListingTab } from '../agency-dashboard.statics';
import {
  AGENCY_DASHBOARD_LISTING_TABS,
  earliestPossibleEntityDate,
} from '../agency-dashboard.statics';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import { EllipsisMenu } from './EllipsisMenu';
import { PrimaryActionButton } from './PrimaryActionButton';

interface MenuProps {
  row: Quote | Binder | Policy;
}

export const AgencyDashSecondaryActionsMenu = ({ row }: MenuProps) => {
  const { currentTab } = useAgencyDashboardState();
  const options = useAgencyDashboardOptionsStatics(currentTab, row);

  return <EllipsisMenu options={options} Button={AnchorButton} />;
};

export const AnchorButton = ({
  onClick,
}: {
  onClick: (event: any) => void;
}) => {
  const theme = useTheme<any>();

  return <PrimaryActionButton onClick={onClick}>More</PrimaryActionButton>;
};

const useAgencyDashboardOptionsStatics = (
  currentTab: AgencyDashboardListingTab,
  row: Quote | Binder | Policy
) => {
  const history = useHistory();

  const optionStatics = {
    [AGENCY_DASHBOARD_LISTING_TABS.QUOTES]: [
      {
        label: 'Manage Quote',
        onClick: () => {
          history.push(
            `/agency/inbox?search=${row.id}&after=${earliestPossibleEntityDate}`
          );
        },
      },
    ],
    [AGENCY_DASHBOARD_LISTING_TABS.BINDERS]: [
      {
        label: 'Manage Binder',
        onClick: () => {
          history.push(
            `/agency/binders?search=${row.id}&after=${earliestPossibleEntityDate}`
          );
        },
      },
    ],
    [AGENCY_DASHBOARD_LISTING_TABS.POLICIES]: [
      {
        label: 'Manage Policy',
        onClick: () => {
          history.push(
            `/agency/policies?search=${row.id}&after=${earliestPossibleEntityDate}`
          );
        },
      },
    ],
    [AGENCY_DASHBOARD_LISTING_TABS.ACCOUNTS]: [],
  };

  return optionStatics[currentTab];
};
