import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
import {
  Grid,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// actions
import {
  fetchDAAgencies,
  addAgentToCluster,
} from '../../agencies/AgencyService';
// platform helpers
import { PubSub } from '../../utils/eventUtils';
import { reduceForAgenciesData } from '../../console/admin/users/UsersUtils';
// components
import { InputLabelBase } from '../inputs/InputLabelBase';
import CbButton from '../Buttons/CbButton';
import { ScrollableMultiSelect } from '../Selects/MultiSelect/ScrollableMultiSelect';

const styles = ({ config, palette }) => {
  return {
    container: {
      marginBottom: '1rem',
    },
    label: {
      fontSize: config.textSizes.tertia,
      padding: 0,
    },
    value: {
      fontSize: config.textSizes.tertia,
      color: palette.primary.contrastText,
      fontWeight: config.weights.bold,
    },
  };
};

export const AddAgencyToCluster = withStyles(styles)(
  ({ classes, close, data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState('');
    const [agencies, setAgencies] = useState([]);
    const [totalPages, setTotal] = useState(1);

    const {
      setValue,
      register,
      getValues,
      handleSubmit,
      formState: { isSubmitting },
    } = useForm({
      defaultValues: {
        selAgency: [],
      },
    });

    useEffect(() => {
      fetchDAAgencies()
        .then((resp) => {
          const result = reduceForAgenciesData(resp.data.content);
          setAgencies(result);
          setTotal(resp.data.totalPages);
        })
        .catch((error) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(error.response, 'data', 'Not able to fetch Agencies list.')
            )
          );
        });
      // eslint-disable-next-line
    }, []);

    const onAgencyChange = (name, selection) => {
      setError('');
      setValue(name, selection);
    };

    const onSubmit = ({ selAgency }) => {
      const { id } = data;

      if (!selAgency.value) {
        setError('An agent must be selected.');
      } else {
        setError('');

        return addAgentToCluster(id, selAgency.value)
          .then(() => {
            enqueueSnackbar(
              `Agency '${selAgency.label}' added to the cluster.`,
              { variant: 'success' }
            );
            close();
            PubSub.publish('table-refetch', 'clusters');
          })
          .catch((error) => {
            const message = `An error occurred! Please try again`;
            setError(
              _.get(
                error.response,
                'data.message',
                _.get(error.response, 'data', message)
              )
            );
          });
      }
    };

    const values = getValues();

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container className={classes.container}>
            <Grid item md={4}>
              <InputLabelBase required className={classes.label}>
                Select Agency
              </InputLabelBase>
            </Grid>
            <Grid item md={8}>
              <div className={classes.value}>
                <ScrollableMultiSelect
                  name="selAgency"
                  buttonPlaceholder="Select Agency"
                  onChange={onAgencyChange}
                  value={values.selectedAgencies}
                  options={agencies}
                  isMulti={false}
                  classes={{
                    select: classes.select,
                    icon: classes.selectIcon,
                  }}
                  ref={register({ name: 'selAgency' })}
                  api={fetchDAAgencies}
                  totalPages={totalPages}
                  reduceFunc={reduceForAgenciesData}
                />
              </div>
            </Grid>
          </Grid>
          {error && (
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton buttonText="Cancel" action={close} styleName="cancel" />
          <CbButton
            buttonText="Add Agency"
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
          />
        </DialogActions>
      </form>
    );
  }
);

export default AddAgencyToCluster;
