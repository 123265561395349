import { ErrorMessage } from '@hookform/error-message';
import { FormHelperText } from '@mui/material';

export const ChipError = ({ errors, name }) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <FormHelperText>{message}</FormHelperText>}
    />
  );
};
