import React from 'react';
import {
  DialogContent,
  DialogActions,
  Divider,
  Box,
  FormControlLabel,
  Checkbox,
  Grid as GridBase,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CbButton from '../../../../components/Buttons/CbButton';
import { generateLossRunsDocument } from '../../../../api';
import { withShowable } from '../../../_global/lib/withShowable';
import { withFormController } from '../../../../components/hocs/forms';
import { TextField as TextFieldBase } from '../../../../components/inputs';

const Grid = withShowable(GridBase);
const TextField = withFormController(TextFieldBase);

const baseSchema = {
  agentEmail: Yup.string().label('Agent Email'),
  agentFirstName: Yup.string().label('Agent First Name'),
  agentLastName: Yup.string().label('Agent Last Name'),
};

const AgencyGenerateLossRuns = ({ data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { accountId, lossRuns } = data;
  const { companyName } = lossRuns[0];
  const [emailLossRuns, setEmailLossRuns] = React.useState(false);

  const schema = React.useMemo(
    () =>
      emailLossRuns
        ? Object.keys(baseSchema).reduce((acc, el) => ({
            ...acc,
            [el]: baseSchema[el].required(),
          }))
        : baseSchema,
    [emailLossRuns]
  );

  const handleCheckboxChange = React.useCallback(
    () => setEmailLossRuns((prev) => !prev),
    []
  );

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(Yup.object().shape(schema)),
  });

  const onSubmit = (formData) => {
    // generateLossRunsDocument(accountId, { data: { emailLossRuns } })
    //   .then(() =>
    //     enqueueSnackbar('Your loss runs are generating and will be available for download in a few minutes.', {
    //       variant: 'info',
    //     })
    //   )
    //   .catch(() =>
    //     enqueueSnackbar('There was a problem generating your loss runs document, please try again.', {
    //       variant: 'error',
    //     })
    //   );
    alert(JSON.stringify(formData, null, '\t'));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingBottom: '2rem' }}>
          <Box display="flex" justifyContent="center" width="100%">
            <h1>{companyName}</h1>
          </Box>
          <Divider />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '2rem' }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailLossRuns}
                  onChange={handleCheckboxChange}
                  color="secondary"
                />
              }
              label="Email Loss Runs"
            />

            <Grid container spacing={1} show={emailLossRuns}>
              <Grid item md={6}>
                <TextField
                  name="agentFirstName"
                  label="Agent First Name"
                  required
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="agentLastName"
                  label="Agent Last Name"
                  required
                />
              </Grid>
              <Grid item md={12}>
                <TextField name="agentEmail" label="Agent Email" required />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={props.close}>
            Cancel
          </CbButton>
          <CbButton styleName="ctaButton" type="submit">
            Generate
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const AgencyGenerateLossRunsConfig = {
  AgencyGenerateLossRuns: {
    component: AgencyGenerateLossRuns,
    config: {
      fullWidth: true,
      title: 'Generate Loss Runs',
    },
  },
};
