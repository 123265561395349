import React from 'react';
import { useSelector } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import _ from 'lodash';
import store from '../../../../config/storeConfig';

import {
  saveTableSettings,
  clearFilters,
} from '../../../../reducers/UiSettingsReducers';
import { useQueryParams } from '../../../providers/QueryParamsProvider';

export function withSaveSettings(settingsKey) {
  return (Component) => {
    const C = ({
      onChangePageSize,
      onColumnReorder,
      onToggleColumnVisibility,
      onSort,
      columns,
      ...props
    }) => {
      const settings = useSelector((state) => state.uiSettings);
      const stateChunk = settings[settingsKey || props.id] || { columns: [] };
      let hydratedColumns = columns;

      useFilterPersist({
        domain: settingsKey || props.id,
        savedSettings: stateChunk,
      });

      // this is hack-a-doo need to find a way to do this with useEffect
      if (!_.isEmpty(stateChunk) && Array.isArray(stateChunk.columns)) {
        hydratedColumns = hydrateColumns(columns, stateChunk.columns);

        if (columns.length !== stateChunk.columns.length) {
          store.dispatch(
            saveTableSettings({
              domain: settingsKey || props.id,
              columns,
            })
          );
        }
      }
      // ^^^^^ this is hack-a-doo need to find a way to do this with useEffect

      function _onColumnReorder(newOrder) {
        store.dispatch(
          saveTableSettings({
            domain: settingsKey || props.id,
            columns: newOrder,
          })
        );

        if (_.isFunction(onColumnReorder)) {
          onColumnReorder(newOrder);
        }
      }

      function _onToggleColumnVisibility(nextColumns) {
        store.dispatch(
          saveTableSettings({
            domain: settingsKey || props.id,
            columns: nextColumns,
          })
        );

        if (_.isFunction(onToggleColumnVisibility)) {
          onToggleColumnVisibility(columns);
        }
      }

      function _onChangePageSize(size) {
        store.dispatch(
          saveTableSettings({
            domain: settingsKey || props.id,
            size,
          })
        );

        if (_.isFunction(onChangePageSize)) {
          onChangePageSize(size);
        }
      }

      function _onSort(name, order) {
        store.dispatch(
          saveTableSettings({
            domain: settingsKey || props.id,
            orderBy: name,
            order,
          })
        );

        if (_.isFunction(onSort)) {
          onSort(name, order);
        }
      }

      return (
        <Component
          {...props}
          columns={hydratedColumns}
          onColumnReorder={_onColumnReorder}
          onToggleColumnVisibility={_onToggleColumnVisibility}
          onChangePageSize={_onChangePageSize}
          onSort={_onSort}
        />
      );
    };

    C.displayName = `withSaveSettings(${
      Component.displayName || Component.name
    })`;
    C.wrappedComponent = Component;

    return hoistNonReactStatics(C, Component);
  };
}

function hydrateColumns(columns, saved) {
  if (saved.length < columns.length) {
    return _.unionBy(columns, saved, 'name');
  }

  if (saved.length > columns.length) {
    return columns;
  }

  const savedHash = saved.reduce(
    (a, v, i) => ({ ...a, [v.name]: { ...v, order: i } }),
    {}
  );
  const modified = columns.reduce((acc, col) => {
    if (!savedHash[col.name]) {
      return [...acc, { ...col, show: col.show }];
    }

    return [
      ...acc,
      {
        ...savedHash[col.name],
        ...col,
        show: savedHash[col.name].show,
      },
    ];
  }, []);

  return modified;
}

const useFilterPersist = ({ domain, savedSettings = {} }) => {
  const { query } = useQueryParams();

  React.useEffect(() => {
    if (query.f && !_.isEqual(query.f, savedSettings.f)) {
      store.dispatch(
        saveTableSettings({
          domain,
          f: query.f,
        })
      );
    } else if (!query.f && _.has(savedSettings, 'f')) {
      store.dispatch(clearFilters({ domain }));
    }

    // eslint-disable-next-line
  }, [query]);
};
