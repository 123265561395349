import React from 'react';
import { SvgIcon } from '@mui/material';

export default function Check({
  fill = '#6ac52a',
  width = '9.6',
  height = '8',
  ...props
}) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <path
        d="M6.85,6.044l.791-.732A15.224,15.224,0,0,1,10.2,6.844,22.131,22.131,0,0,1,15.852,2l.257.593C14.127,4.323,12.675,6.25,10.584,10A39.672,39.672,0,0,0,6.85,6.044Z"
        transform="translate(-6.85 -2)"
        fill={fill}
      />
    </SvgIcon>
  );
}
