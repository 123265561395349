import React from 'react';

type SVGProps = {
  style?: React.CSSProperties;
  fill?: string;
  width?: string;
  className?: string;
  viewBox?: string;
};

const SVG = ({
  style = {
    height: '25px',
    width: '25px',
    position: 'relative',
  },
  fill = '#9ba0ae',
  width = '100%',
  className = '',
  viewBox = '0 0 30 30',
}: SVGProps) => (
  <svg
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>.cls-1{`fill:${fill};`}</style>
    </defs>
    <title>Refresh Data</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={fill}
          d="M22.81,16.85a1.48,1.48,0,0,0-1.4,1h0a7.07,7.07,0,0,1-11.14,2.41.31.31,0,0,1,0-.44l2.22-2.16a.3.3,0,0,0-.21-.51h-7a.3.3,0,0,0-.3.3v6.82a.3.3,0,0,0,.51.21l2.23-2.22a.29.29,0,0,1,.4,0A10.33,10.33,0,0,0,10,23.66a10,10,0,0,0,14-4.48,1.51,1.51,0,0,0,.25-.84A1.49,1.49,0,0,0,22.81,16.85Z"
        />
        <g id="Polygon_50" data-name="Polygon 50">
          <path
            fill={fill}
            d="M7.19,13.15a1.48,1.48,0,0,0,1.4-1h0A7.07,7.07,0,0,1,19.74,9.74a.31.31,0,0,1,0,.44l-2.22,2.16a.3.3,0,0,0,.21.51h7a.3.3,0,0,0,.3-.3V5.73a.3.3,0,0,0-.51-.21L22.26,7.74a.29.29,0,0,1-.4,0A10.33,10.33,0,0,0,20,6.34,10,10,0,0,0,6,10.82a1.51,1.51,0,0,0-.25.84A1.49,1.49,0,0,0,7.19,13.15Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
