import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

import {
  DialogContent,
  DialogActions,
  Grid,
  Box,
  useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import CbButton from '../../../components/Buttons/CbButton';
import { SimpleSelect } from '../../../components/inputs';
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import { withFormController } from '../../../components/hocs/forms';
import stateStatics from '../../_statics/states.statics.json';
import { globalSchema } from '../../../components/globalValidations';
import { getTerritories, postZipCode } from '../sales.service';
import { getCowbellTranslations } from '../../../i18n/translations';
import { Show } from '../../../components/Show';
import { getIsUsPlatform, useGetPlatformRegion } from '../../../utils';
import { useLanguageFormState } from '../../../i18n/forms/LanguageForm';
import { FormLanguageProvider } from '../../../i18n/forms/FormLanguageProvider';
import { useAPIErrorHandler } from '../../../components/hooks/useAPIErrorHandler';

const Select = withFormController(SimpleSelect);
const TextField = withFormController(TextFieldBase);

const deriveSchema = (languageSchema) =>
  Yup.object().shape({
    zip: languageSchema.zipCode,
    city: Yup.string().required(),
    county: Yup.string().required(),
    timeZone: Yup.string().label('Timezone').required(),
    state: languageSchema.state,
  });

const AddEditZipCode = ({ data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const languageFormState = useLanguageFormState();
  const handleApiError = useAPIErrorHandler();
  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(deriveSchema(languageFormState.languageFormSchema)),
  });
  const region = useGetPlatformRegion();
  const isUSRegion = getIsUsPlatform(region);

  const { data: partialStates } = useQuery(['partialStates'], () =>
    getTerritories({ size: 500 }).then((resp) => {
      return parsePartialStatesList(_.get(resp, 'data.hits'));
    })
  );

  const onSubmit = (formData) => {
    postZipCode({ data: formData })
      .then(() => {
        enqueueSnackbar('Zip code added successfully', { variant: 'success' });
        props.close();
      })
      .catch(
        handleApiError(
          'There was a problem adding that zip code. Please try again'
        )
      );
  };

  const selectedState = methods.watch('state');
  const partialStateOptions = React.useMemo(() => {
    if (Array.isArray(partialStates)) {
      return partialStates.reduce((acc, partial) => {
        return selectedState === partial.stateAbbr
          ? [...acc, { label: partial.name, value: partial.name }]
          : acc;
      }, []);
    }
  }, [partialStates, selectedState]);
  const isPartialStateDisabled = React.useMemo(
    () => _.isEmpty(partialStateOptions),
    [partialStateOptions]
  );
  const selectFieldStyles = React.useMemo(
    () => ({ backgroundColor: theme.palette.background.modal }),
    // eslint-disable-next-line
    []
  );

  return (
    <FormLanguageProvider {...languageFormState}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ padding: '2.5rem 4rem' }}>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <TextField name="zip" label="Zip Code" required />
              </Grid>
              <Grid item md={6}>
                <TextField name="city" label="City" required />
              </Grid>

              <Show when={isUSRegion}>
                <Grid item md={6}>
                  <Box style={{ position: 'relative', bottom: '1rem' }}>
                    <Select
                      name="state"
                      label="State"
                      options={stateStatics.full}
                      style={selectFieldStyles}
                      required
                    />
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box style={{ position: 'relative', bottom: '1rem' }}>
                    <Select
                      name="partialState"
                      label="Partial State"
                      options={partialStateOptions}
                      style={isPartialStateDisabled ? {} : selectFieldStyles}
                      disabled={_.isEmpty(partialStateOptions)}
                    />
                  </Box>
                </Grid>
              </Show>
              <Grid item md={6}>
                <TextField name="county" label="County" required />
              </Grid>
              <Grid item md={6}>
                <TextField name="timeZone" label="TimeZone" required />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CbButton styleName="cancel" onClick={props.close}>
              Cancel
            </CbButton>
            <CbButton styleName="ctaButton" type="submit">
              Add {t(translationKeys.zipCode)}
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    </FormLanguageProvider>
  );
};

const { t, translationKeys } = getCowbellTranslations();

export const AddEditZipCodeConfig = {
  AddEditZipCode: {
    component: AddEditZipCode,
    config: {
      fullWidth: true,
      title: `Add/Edit ${t(translationKeys.zipCode)}`,
    },
  },
};

// helpers
const parsePartialStatesList = (territories = []) => {
  const partialStates = territories.reduce((acc, territory) => {
    return Array.isArray(territory.partialStates)
      ? [...acc, ...territory.partialStates]
      : acc;
  }, []);
  return partialStates;
};
