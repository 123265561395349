import React from 'react';
// components
import {
  CoverageLineItem,
  EndorsementLineItem,
  GlobalLineItem,
  LabelLineItem,
} from './CoverageLineItem';
import { GroupHeader } from './GroupHeader';

export const GroupedCoverages = ({
  id,
  group,
  groupedCoverages,
  labels = false,
  diffs,
}) => {
  const coverages = groupedCoverages[id];

  return (
    <>
      <GroupHeader {...group} labels={labels} id={id} />

      {coverages.map((coverage, index) => {
        return (
          <>
            {labels && (
              <LabelLineItem
                className={(index + 1) % 2 ? 'even' : ''}
                coverage={coverage}
                key={coverage.key}
                diffs={diffs}
              />
            )}

            {!labels && id === '1' && (
              <CoverageLineItem
                coverage={coverage}
                key={coverage.key}
                diffs={diffs}
              />
            )}

            {!labels && id === '0' && (
              <GlobalLineItem
                coverage={coverage}
                key={coverage.key}
                diffs={diffs}
              />
            )}

            {!labels && coverage.endorsement && (
              <EndorsementLineItem
                coverage={coverage}
                key={coverage.key}
                diffs={diffs}
              />
            )}
          </>
        );
      })}
    </>
  );
};

export default GroupedCoverages;
