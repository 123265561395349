import { useState } from 'react';
import { useForm } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { sendMfaCode } from '../../api/AuthService';
import CBButton from '../Buttons/CbButton';
import { useMeQuery } from '../hooks/useMeQuery';
import { useToggleModal } from '../../utils/modal.utils';

export const SMSDisabled = ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();
  const queryClient = useQueryClient();

  const { type } = data;

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const [error, setError] = useState('');

  const onSubmit = () => {
    return sendMfaCode('NONE')
      .then(() => {
        toggleModal.direct('SMSDisabled', false);
        enqueueSnackbar(`${type} Authentication Disabled Successfully!`, {
          variant: 'success',
        });
        queryClient.invalidateQueries([useMeQuery.QUERY_KEY]);
      })
      .catch((error) => {
        setError(error.response.data);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
      <DialogContent>
        <div className="modal-title">
          {`Are you sure you want to disable ${type} Authentication?`}
        </div>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <CBButton action={close} styleName="cancel">
          Cancel
        </CBButton>
        <CBButton
          loading={isSubmitting}
          action={onSubmit}
          styleName="ctaButton"
          buttonText="Disable MFA"
          buttonId="disableSms-submit"
        />
      </DialogActions>
    </form>
  );
};

export default SMSDisabled;
