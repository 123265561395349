import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFeature } from '@growthbook/growthbook-react';
import { getAvailableProducts } from '../../accounts/AccountService';
import { getData } from '../../utils/functional/fp';
import { useAPIErrorHandler } from './useAPIErrorHandler';
import type { ProductType } from '../../types';
import { ProductTypes } from '../../types';
import PRODUCT_STATICS from '../../console/_statics/product.statics.json';
import { FEATURE_FLAG_KEYS } from '../../config/growthbook';

const useGetAvailableProducts = () => {
  const handleAPIError = useAPIErrorHandler();
  const isPrimeOne = useFeature(FEATURE_FLAG_KEYS.PRIME_ONE);
  const isP100Pro = useFeature(FEATURE_FLAG_KEYS.P100PRO);

  const { data = [], isLoading } = useQuery({
    queryKey: ['available-products'],
    queryFn: () => getAvailableProducts(),
    select: getData,
    onError: handleAPIError('Unable to retrieve available products.'),
    cacheTime: Infinity,
    staleTime: 3600000, //1 hour stale time
  });

  return React.useMemo(() => {
    if (isLoading) {
      return [];
    }

    return data.reduce(
      (
        filtered: { label: string; value: ProductType }[],
        productType: ProductType
      ) => {
        if (
          (productType === ProductTypes.p100_pro && isP100Pro.off) ||
          (productType === ProductTypes.prime_one && isPrimeOne.off)
        ) {
          return filtered;
        }

        return [
          ...filtered,
          {
            label: PRODUCT_STATICS.ProductTypes[productType]?.full,
            value: productType,
          },
        ];
      },
      []
    );
  }, [data, isLoading, isP100Pro.off, isPrimeOne.off]);
};

export default useGetAvailableProducts;
