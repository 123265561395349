import React from 'react';
import { Grid, styled } from '@mui/material';
import Color from 'color';
import { useCowbellTranslations } from '../../../../../../i18n/translations';

interface CoverageGroupHeaderProps {
  children: React.ReactNode;
}

const CoverageGroupHeader = ({ children }: CoverageGroupHeaderProps) => {
  const { t, translationKeys } = useCowbellTranslations();
  return (
    <GridContainer container>
      <Grid item md={4}>
        {children}
      </Grid>
      <Grid item md={2} style={{ textAlign: 'center' }}>
        <span>Coverage Limit</span>
      </Grid>
      <Grid item md={2} style={{ textAlign: 'center' }}>
        <span>{t(translationKeys.retention)}</span>
      </Grid>
      <Grid item md={2} style={{ textAlign: 'center' }}>
        <span>Waiting Period</span>
      </Grid>
      <Grid item md={2} style={{ textAlign: 'center' }}>
        <span>Retro Active Date</span>
      </Grid>
    </GridContainer>
  );
};

export default CoverageGroupHeader;

const GridContainer = styled(Grid)(({ theme }: { theme: any }) => {
  return {
    minHeight: '3rem',
    padding: '1rem',
    margin: 0,
    backgroundColor: Color(theme.palette.background.default)
      .lighten(theme.palette.mode === 'dark' ? 0.25 : 0.02)
      .toString(),
  };
});
