import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import {
  Box as MuiBox,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  FormHelperText,
} from '@mui/material';
// components
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { withFormController } from '../../../hocs/withFormController';
import { SimpleSelect } from '../../../inputs/selects/SimpleSelect';
// constants
import { COWBELL_ACCOUNTS } from '../../../tables/table_constants';

import {
  BulkUpdateTAMBDS,
  updateAgencyLocation,
} from '../../../../console/agencies/_services/locations.service';
import { updateNotice } from '../../../../api/p250.service';
import { fetchBds, fetchTams } from '../../../../api/UserService';
import { updateLead } from '../../../../console/salesInbox/sales.service.js';
import CBButton from '../../../Buttons/CbButton';
import { delayedEvent } from '../../../../utils/eventUtils';
import { withShowable } from '../../../../console/_global/lib/withShowable';

const schema = Yup.object().shape({
  tamEmail: Yup.string().required('TSM email is required'),
  bdsEmail: Yup.string().required('BDS email is required'),
});

const Select = withFormController(SimpleSelect);
const MuiGrid = withShowable(Grid);
const Box = withShowable(MuiBox);

export const UpdateTamBdsModal = ({ ...props }) => {
  const [backendError, setBackendError] = useState('');
  const [tamOptions, setTams] = useState([]);
  const [bdsOptions, setBds] = useState([]);
  const queryClient = useQueryClient();
  // managing defaultValues of different types
  let tam = '';
  let bds = '';

  if (props.data.isLocation || props.data.isQuote) {
    tam = props.data.tamEmail;
    bds = props.data.bdsEmail;
  } else {
    tam = props.data.tams === undefined ? '' : props.data.tams[0];
    bds = props.data.bizDevs === undefined ? '' : props.data.bizDevs[0];
  }

  const defaultValues = {
    tamEmail: tam,
    bdsEmail: bds,
  };

  // defaultValues
  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    validationSchema: schema,
  });
  const { enqueueSnackbar } = useSnackbar();
  const bulk = Array.isArray(props.data);

  const {
    formState: { isSubmitting, dirty },
  } = methods;

  function onSubmit({ ...formData }) {
    let updatePromise;
    if (bulk) {
      updatePromise = BulkUpdateTAMBDS(
        {},
        { ...formData, locationIds: props.data }
      );
    } else if (props.data.salesInbox) {
      updatePromise = updateLead(
        { id: props.data.id },
        {
          bizDevs: [formData.bdsEmail],
          tams: [formData.tamEmail],
          accountId: props.data.id,
        }
      );
    } else if (props.data.isAccount) {
      updatePromise = updateNotice(
        { accountId: props.data.id },
        {
          bizDevs: [formData.bdsEmail],
          tams: [formData.tamEmail],
          accountId: props.data.id,
        }
      );
    } else {
      updatePromise = updateAgencyLocation(
        {},
        {
          id: props.data.id,
          agencyId: props.data.agencyId,
          agencyName: props.data.name,
          ...formData,
        }
      );
    }

    return updatePromise
      .then(() => {
        enqueueSnackbar(`Updated Successfully!`, { variant: 'success' });
        if (props.data.salesInbox) {
          delayedEvent('table-refetch', 0, 'SalesInbox');
          delayedEvent('table-refetch', 550, 'SalesInbox');
        } else if (props.data.isAccount) {
          queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
        } else {
          delayedEvent('table-refetch', 1000, 'admin_locations');
        }
        props.close();
      })
      .catch((error) => error);
  }

  React.useEffect(() => {
    fetchTams().then((resp) => {
      const darray = resp.data.content.map(({ email }) => ({
        label: email,
        value: email,
      }));

      setTams(darray);
    });
    fetchBds()
      .then((resp) => {
        const users = _.get(resp, 'data.content', []);
        const darray = users.map(({ email }) => ({
          label: email,
          value: email,
        }));

        setBds(darray);
      })
      .catch((error) => {
        const errorMessage = _.get(
          error,
          'response.data',
          'Server Error, please try again'
        );
        setBackendError(errorMessage);
      });
  }, []);

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          show={bulk && props.data.length > 1}
          padding="0 1.25rem"
          textAlign="center"
        >
          <h2>{props.data.length} records selected</h2>
          <Divider />
        </Box>
        <DialogContent style={{ paddingTop: 0, paddingBottom: '1.7rem' }}>
          <MuiGrid container spacing={2} alignItems="center">
            <MuiGrid item md={6}>
              <Select
                label="Update TSM"
                name="tamEmail"
                renderValue={renderValue}
                options={tamOptions}
                required
                displayEmpty
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <Select
                label="Update BDS"
                name="bdsEmail"
                renderValue={renderValue}
                options={bdsOptions}
                required
                displayEmpty
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <FormHelperText className="api-text-error">
          {backendError}
        </FormHelperText>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <CBButton size="medium" styleName="cancel" onClick={props.close}>
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            styleName="ctaButton"
            size="medium"
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            Update
          </CBButton>
        </DialogActions>
      </form>
    </FormContext>
  );
};

function renderValue(value) {
  return value || 'Select...';
}

export const UpdateTamBdsModalConfig = {
  UpdateTamBdsModal: {
    component: UpdateTamBdsModal,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      title: 'Update TSM/BDS',
    },
  },
};
