import React, { Component } from 'react';
import { Toolbar } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    bottom: 0,
    flex: 1,
    justifyContent: 'space-between',
    minHeight: '0.1rem',
    width: '15rem',
    color: '#9e9e9e',
    fontSize: '1.5rem',
    marginTop: 5,
  },
};

class SocialMedia extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Toolbar className={classes.toolbar}>
        <a
          href="https://www.facebook.com/cowbellcyber"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>
            <i className="fab fa-facebook-f" />
          </span>
        </a>
        <a
          href="https://www.linkedin.com/company/cowbell-cyber/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>
            <i className="fab fa-linkedin-in" />
          </span>
        </a>
        <a
          href="https://twitter.com/cowbellcyber"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>
            <i className="fab fa-twitter" />
          </span>
        </a>
        <a
          href="mailto:support@cowbellcyber.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>
            <i className="fas fa-envelope" />
          </span>
        </a>
      </Toolbar>
    );
  }
}

export default withStyles(styles)(SocialMedia);
