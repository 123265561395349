import React from 'react';
import { DialogContent, Divider } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import _ from 'lodash';
import {
  DialogFooter,
  dialogPadding,
  SectionTitle,
  ParentAgencyAutocomplete,
  InheritanceMessage,
  AgencyNameAutocomplete,
} from './helpers';
import { FormGridBuilder } from '../../components/forms/FormGridBuilder';
import CbButton from '../../components/Buttons/CbButton';
import { getUrlHost } from '../../utils/appUtils';
import LanguageForm, {
  useLanguageFormState,
} from '../../i18n/forms/LanguageForm';
import GridLanguageForm from '../../i18n/forms/GridLanguageForm';
import { FormLanguageProvider } from '../../i18n/forms/FormLanguageProvider';
import { useCowbellTranslations } from '../../i18n/translations';

export const Step1 = ({ stepperProps, onCancel, ...aggregateFormState }) => {
  const { gridLayoutSettings, ...languageFormState } = useLanguageFormState({
    language: aggregateFormState.aggregateFormData.region,
    options: { validatePhone: true, phoneFieldName: 'contactPhone' },
  });

  const { goForward } = stepperProps;
  const { aggregateFormData, updateAggregateFormData } = aggregateFormState;
  const domain = getUrlHost();
  const { translations } = useCowbellTranslations(['Agency']);

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(
      deriveSchema(
        languageFormState.languageFormSchema,
        languageFormState.language,
        translations
      )
    ),
    defaultValues: {
      ..._.pick(aggregateFormData, [
        'name',
        'hostname',
        'contactFirstName',
        'contactLastName',
        'contactPhone',
        'contactEmail',
        'quotingDba',
        'address1',
        'address2',
        'city',
        'zipCode',
        'contactPhoneCountryCode',
      ]),
      state: aggregateFormData.state || '',
    },
  });

  const onSubmit = React.useCallback(
    async (formData) => {
      try {
        await languageFormState.validateAddress(formData);
        updateAggregateFormData(
          _.omit(formData, ['parentAgencyId', 'parentAgencyName'])
        );
        goForward();
      } catch {
        //
      }
    },
    [goForward, updateAggregateFormData]
  );

  const upperFormConfig = React.useMemo(() => {
    return getStep1FormConfig({
      methods,
      aggregateFormState,
      domain,
      translations,
    });
  }, [aggregateFormState, domain, methods]);

  return (
    <FormLanguageProvider language={languageFormState.language}>
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={dialogPadding}>
            <SectionTitle>{translations.Agency} Basic Info</SectionTitle>
            <InheritanceMessage aggregateFormData={aggregateFormData} />
            <FormGridBuilder formConfig={upperFormConfig} />

            <Divider style={{ margin: '1.2rem 0' }} />

            <SectionTitle>{translations.Agency} Location Address</SectionTitle>

            <GridLanguageForm gridLayoutSettings={gridLayoutSettings} />
          </DialogContent>

          <DialogFooter message="Step: 1 of 3">
            <CbButton onClick={onCancel} styleName="cancel">
              Cancel
            </CbButton>

            <CbButton styleName="ctaButton" disabled>
              Previous
            </CbButton>

            <CbButton styleName="ctaButton" type="submit">
              Next
            </CbButton>
          </DialogFooter>
        </form>
      </FormProvider>
    </FormLanguageProvider>
  );
};

export const getStep1FormConfig = ({
  methods,
  aggregateFormState,
  domain,
  translations,
}) => {
  const [hostnameInput, name] = methods.watch(['hostname', 'name']);
  const hostnameHelperText = hostnameInput ? `${hostnameInput}${domain}` : '';
  const { parentAgencyId, parentAgencyName } = _.get(
    aggregateFormState,
    'aggregateFormData',
    {}
  );

  return [
    {
      name: 'name',
      label: `${translations.Agency} Name`,
      component: AgencyNameAutocomplete,
      aggregateFormState,
      methods,
      required: true,
      gridItemProps: { xs: 6 },
      defaultValue: { label: name, value: name },
    },
    {
      name: 'locationAlias',
      label: 'Location Alias',
      gridItemProps: { xs: 6 },
    },
    {
      name: 'parentAgency',
      label: `Parent ${translations.Agency}`,
      gridItemProps: { xs: 6 },
      component: ParentAgencyAutocomplete,
      aggregateFormState,
      defaultValue: { label: parentAgencyName, value: parentAgencyId },
    },
    {
      name: 'hostname',
      label: 'Hostname',
      required: true,
      gridItemProps: { xs: 6 },
      helperText: hostnameHelperText,
    },
    {
      name: 'quotingDba',
      label: `${translations.Agency} Doing Business As`,
      gridItemProps: { xs: 6 },
    },
    {
      name: 'contactFirstName',
      label: `Primary ${translations.Agency} Contact First Name`,
      required: true,
      gridItemProps: { xs: 6 },
    },
    {
      name: 'contactLastName',
      label: `Primary ${translations.Agency} Contact Last Name`,
      required: true,
      gridItemProps: { xs: 6 },
    },
    {
      name: 'contactPhone',
      required: true,
      gridItemProps: { xs: 6, style: { marginTop: '0.75rem' } },
      component: LanguageForm.PhoneField,
    },
    {
      name: 'contactEmail',
      label: `Primary ${translations.Agency} Contact Email`,
      required: true,
      type: 'email',
      gridItemProps: { xs: 6 },
    },
  ];
};

const deriveSchema = (languageFormSchema, language, translations) => {
  return Yup.object().shape({
    ...step1Validations(translations),
    ...languageFormSchema,
  });
};

export const step1Validations = (translations) => {
  return {
    name: Yup.string().required().label(`${translations.Agency} Name`),
    hostname: Yup.string().required().label('Hostname'),
    contactFirstName: Yup.string()
      .required()
      .label(`${translations.Agency} Contact First Name`),
    contactLastName: Yup.string()
      .required()
      .label(`${translations.Agency} Contact Last Name`),

    contactEmail: Yup.string()
      .email()
      .required()
      .label(`${translations.Agency} Contact Email`),
  };
};
