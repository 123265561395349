import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { getQuoteProgress } from '../../../../api';
import { overhaulQuoteProgressData } from '../utils/bindQuoteWorkFlowUtils';
import type { Persona } from '../../../_statics/persona.statics';
import useGetQuoteDetails from './useGetQuoteDetails';
import type { ProductType } from '../../../../types';
import { useAPIErrorHandler } from '../../../../components/hooks/useAPIErrorHandler';

interface UseGetQuoteProgressParams {
  quoteId: string;
  persona: Persona;
  productType: ProductType;
}

const useGetQuoteProgress = ({
  quoteId,
  persona,
  productType,
}: UseGetQuoteProgressParams) => {
  const handleAPIError = useAPIErrorHandler();

  const quoteDetails = useGetQuoteDetails({ quoteId, productType });

  return useQuery(
    ['quote-progress', quoteId, persona],
    () => {
      return getQuoteProgress(quoteId).then(({ data }: AxiosResponse) => {
        const formattedData = overhaulQuoteProgressData(
          data,
          persona,
          quoteDetails.data,
          productType
        );

        return {
          quoteProgress: data,
          ...formattedData,
        };
      });
    },
    {
      enabled:
        Boolean(quoteId) &&
        Boolean(quoteDetails.data) &&
        !quoteDetails.isFetching,
      onError: handleAPIError(
        'There was a problem retrieving the quote progress information. Please try again later.'
      ),
    }
  );
};

export default useGetQuoteProgress;
