import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import NextPublicWrapper from './NextPublicWrapper';

import { logout } from '../../users/UserActions';

const LoginExisting = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <NextPublicWrapper>
      <Box pt={1}>
        <Typography className="canon-text">
          Thank you for signing up!
        </Typography>
        <Typography paragraph>
          We sent an email to your admin to setup your account.
        </Typography>
        <Typography>
          Once your account is setup, you will get a confirmation email.
        </Typography>
      </Box>
    </NextPublicWrapper>
  );
};

export default LoginExisting;
