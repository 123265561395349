import { DialogActions, DialogContent } from '@mui/material';
import * as Yup from 'yup';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import CbButton from '../Buttons/CbButton';
import TextFieldBase from '../inputs/text-fields/base/TextFieldBase';
import { withFormController } from '../hocs/forms';
import type { ProductType } from '../../types';
import { submitPxQuoteForUWReview } from '../../api';
import { useAPIErrorHandler } from '../hooks/useAPIErrorHandler';
import { delayedEvent } from '../../utils/eventUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../reducers/UiSettingsReducers';

const TextField = withFormController(TextFieldBase);

interface Props {
  data: {
    quoteId: string;
    productType: ProductType;
  };
  close: () => void;
}

const SubmitPxQuoteForUWReview = ({ data: modalProps, close }: Props) => {
  const handleAPIError = useAPIErrorHandler();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (formValues: FieldValues) => {
    return submitPxQuoteForUWReview(
      modalProps.productType,
      modalProps.quoteId,
      {
        data: formValues,
      }
    )
      .then(() => {
        enqueueSnackbar('Quote submitted to underwriter review.', {
          variant: 'success',
        });
        delayedEvent('table-refetch', 500, AGENCY_QUOTES_LISTING_STORE_ID);
        queryClient.invalidateQueries(['COWBELL_QUOTES']);
        close();
      })
      .catch(handleAPIError('Unable to submit for UW Review.'));
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
        <DialogContent style={{ padding: '2.5rem 3.5rem' }}>
          <TextField required label="Reason" name="reason" />
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close}>
            Cancel
          </CbButton>
          <CbButton
            styleName="ctaButton"
            type="submit"
            disabled={formMethods.formState.isSubmitting}
          >
            Submit
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default SubmitPxQuoteForUWReview;

export const SubmitPxQuoteForUWReviewConfig = {
  SubmitPxQuoteForUWReview: {
    component: SubmitPxQuoteForUWReview,
    config: {
      title: 'Submit for Underwriter Review',
      fullWidth: true,
      maxWidth: 'sm',
    },
  },
};

const schema = Yup.object({
  reason: Yup.string().trim().label('Reason').required(),
});

type FieldValues = {
  reason: string;
};
