import React from 'react';
import UWComparisonCoveragesRow from './UWComparisonCoveragesRow';
import UWComparisonGroupHeader from './UWComparisonGroupHeader';

const UWComparisonCoverages = ({ uwCompareCoverages = [], ...props }) => {
  const groups = React.useMemo(() => {
    return groupIds.map((groupId) =>
      getRowByGroup(groupId, uwCompareCoverages)
    );
  }, [uwCompareCoverages]);

  return groups.map((group) => {
    return <UWComparisonSection group={group} {...props} />;
  });
};

export default UWComparisonCoverages;

const UWComparisonSection = ({
  group = [],
  customUWComparisonGroupHeader: CustomUWComparisonGroupHeader,
  customUWComparisonCoveragesRow: CustomUWComparisonCoveragesRow,
  ...props
}) => {
  if (group.length === 0) {
    return null;
  }

  return (
    <>
      {CustomUWComparisonGroupHeader ? (
        <CustomUWComparisonGroupHeader
          sectionLabel={groupLabels[group[0]?.groupId]}
        />
      ) : (
        <UWComparisonGroupHeader
          sectionLabel={groupLabels[group[0]?.groupId]}
        />
      )}
      {group.map((row, index) => {
        return CustomUWComparisonCoveragesRow ? (
          <CustomUWComparisonCoveragesRow
            key={index}
            index={index}
            rowData={row}
            {...props}
          />
        ) : (
          <UWComparisonCoveragesRow
            key={index}
            index={index}
            rowData={row}
            {...props}
          />
        );
      })}
    </>
  );
};

const getRowByGroup = (groupNum, uwCompareCoverages) => {
  return uwCompareCoverages.filter((el) => {
    return el.groupId === groupNum;
  });
};

const groupIds = ['1', '2', '3', '4', '5', '6', '7'];

const groupLabels = {
  1: 'Liability Expense',
  2: 'First Party Expense',
  3: 'First Party Loss',
  4: 'Coverage Endorsements',
  5: 'Tech and Tele E&O Liability Coverage',
  6: 'Amendatory Endorsements',
  7: 'Amendatory Endorsements (REFERRAL)',
};
