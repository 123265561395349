import React from 'react';
import { SvgIcon } from '@mui/material';

export const ClaimIndicator = ({
  color = '#6ac52a',
  color1 = '#fff',
  status = 'OPEN',
  ...props
}) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <g data-name="Group 11654">
      <path
        data-name="Path 6971"
        d="M16.5.5v12.911l-8 3.089-8-3.089V.5z"
        transform="translate(-.5 -.5)"
        style={{ fill: color }}
      />
      <g data-name="Group 11653">
        {status !== 'NOTICE ONLY' ? (
          <path
            data-name="Path 6975"
            d="M3.036-6.132c-1.788 0-2.508.756-2.508 2.561 0 1.936.636 2.7 2.484 2.7a17.346 17.346 0 0 0 1.824-.179L4.8-1.927s-.948.081-1.4.081c-1.3 0-1.548-.434-1.548-1.724 0-1.16.288-1.583 1.536-1.583.456 0 1.4.081 1.4.081l.048-.867a15.125 15.125 0 0 0-1.8-.193z"
            transform="translate(5.09 11.232)"
            style={{ fill: color1 }}
          />
        ) : (
          <path
            data-name="Path 6976"
            d="M1.778.132v-3.98a3.1 3.1 0 0 1 1.159-.267c.78 0 .913.5.913 1.529V.132h1.108v-2.749c0-1.683-.348-2.493-1.774-2.493a3.412 3.412 0 0 0-1.416.431V-5H.66V.132z"
            transform="translate(5.34 10.213)"
            style={{ fill: color1 }}
          />
        )}
      </g>
    </g>
  </SvgIcon>
);
