import _ from 'lodash';
import fp from 'lodash/fp';

import { ProductTypes } from '../types';

const productsWithNoCoverages = ['P_PLUS'];

export const grouped = (coverages) => {
  return fp.flow(
    fp.filter((coverage) => Boolean(coverage)),
    fp.sortBy('order'),
    fp.groupBy('group')
  )(coverages);
};

export const specialCases = (option, values) => {
  return (
    productsWithNoCoverages.includes(option.product) ||
    (option.product === ProductTypes.p250S &&
      values.products.includes(ProductTypes.p250))
  );
};

export const getLabel = (product) => {
  if (product === ProductTypes.p250S) {
    return 'coverages250';
  }
  const prod = product.slice(1);
  return `coverages${prod}`;
};

export const createCoverages = (coveragesList, values, formData) => {
  let finalCoverages = {};
  coveragesList.map((option) => {
    const specialCondition = specialCases(option, values);
    if (specialCondition) return;

    const array = [];
    // eslint-disable-next-line
    return Object.entries(option.data).map((coverages) => {
      coverages[1].filter((coverage) => {
        if (formData[coverage.name]) {
          array.push(coverage.ordinal);
        }
        return null;
      });

      finalCoverages = {
        ...finalCoverages,
        [getLabel(option.product)]: array,
      };
    });
  });
  return finalCoverages;
};

export const mergeCoveragesWithOrdinals = (coverages, stateCoverages = []) => {
  let toSetCoverages = {};
  _.values(coverages).map((cov) => {
    if (stateCoverages.includes(cov.ordinal)) {
      return (toSetCoverages = {
        ...toSetCoverages,
        [cov.name]: true,
      });
    }
    return (toSetCoverages = {
      ...toSetCoverages,
      [cov.name]: false,
    });
  });
  return toSetCoverages;
};
