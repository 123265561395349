import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { compose } from 'redux';

import {
  Box,
  DialogActions,
  DialogContent,
  Grid as MuiGrid,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckBoxBase from '../../../inputs/Checkbox';
import { TextFieldBase } from '../../../inputs/TextFieldBase';

import {
  createAgencyLocation,
  updateAgencyLocation,
} from '../../../../console/agencies/_services/locations.service';

import CBButton from '../../../Buttons/CbButton';
import { delayedEvent } from '../../../../utils/eventUtils';
import { manageAPIError } from '../../../../utils';
import { useCowbellTranslations } from '../../../../i18n/translations';
import { withFormController } from '../../../hocs/forms';
import { AgencyLookUpSchema } from '../../../../accounts/admin-account-flow/AgencyAutoComplete';
import { AgencyLookupField } from '../../../../accounts/admin-account-flow/AgencyAutoCompleteV2';
import LanguageForm, {
  useLanguageFormState,
} from '../../../../i18n/forms/LanguageForm';
import { FormLanguageProvider } from '../../../../i18n/forms/FormLanguageProvider';
import GridLanguageForm from '../../../../i18n/forms/GridLanguageForm';

const TextField = withFormController(TextFieldBase);

const deriveSchema = (editing, languageFormSchema) =>
  Yup.object().shape({
    ...(!editing ? { agency: AgencyLookUpSchema } : {}),
    locationName: Yup.string().required('Location Name is a required field.'),
    ...languageFormSchema,
  });

export const AdminCreateEditLocationModal = compose(
  withStyles(({ config }) => ({
    error: {
      textAlign: 'center',
      maxWidth: '37.5rem',
      fontSize: config.textSizes.normal,
    },
    radioField: {
      minHeight: '3.75rem',
      justify: 'space-around',
    },
    addUser: {
      display: 'flex',
      justifyContent: 'center',
      margin: '1.25rem 0rem -0.625rem 0rem',
    },
    aboveMargin: {
      '& > label': {
        paddingTop: '0.38rem',
      },
    },
    zipStyles: {
      '& ::-webkit-outer-spin-button': {
        display: 'none',
        margin: 0,
      },
      '& ::-webkit-inner-spin-button': {
        display: 'none',
        margin: 0,
      },
    },
  }))
)(({ classes, data = {}, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const editing = !_.isEmpty(data);
  const { t, translationKeys } = useCowbellTranslations();
  const languageFormState = useLanguageFormState({
    options: { validatePhone: true },
  });

  const [agencyInputValue, setAgencyInputValue] = useState('');

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      address1: data.address1 || '',
      address2: data.address2 || '',
      city: data.city || '',
      state: data.state || '',
      zipCode: data.zipCode || '',
      agency: data.name || '',
      phoneNumber: data.phoneNumber
        ? data.phoneNumber.match(/[0-9]+/g).join('')
        : '',
      locationName: data.locationName || '',
      isHeadquarters: data.isHeadquarters || false,
      isMailingAddress: data.isMailingAddress || false,
      isLocationActive: data.isLocationActive || false,
    },
    resolver: yupResolver(
      deriveSchema(editing, languageFormState.languageFormSchema)
    ),
  });

  function onSubmit(formData) {
    if (editing) {
      return updateAgencyLocation(
        {},
        {
          ...formData,
          agencyId: data.agencyId,
          agencyName: data.name,
          id: data.id,
        }
      )
        .then(() => {
          enqueueSnackbar(`Location Updated successfully!`, {
            variant: 'success',
          });
          delayedEvent('table-refetch', 500, 'admin_locations');
          props.close();
        })
        .catch((error) => {
          enqueueSnackbar(
            manageAPIError(error, 'Server error. Please try again'),
            { variant: 'error' }
          );
        });
    }

    return createAgencyLocation(
      {},
      {
        ...formData,
        agencyId: formData.agency.meta.id,
        agencyName: formData.agency.label,
      }
    )
      .then(() => {
        enqueueSnackbar(`Location Created successfully!`, {
          variant: 'success',
        });
        delayedEvent('table-refetch', 500, 'admin_locations');
        props.close();
      })
      .catch((error) => {
        enqueueSnackbar(
          manageAPIError(error, 'Server error. Please try again'),
          { variant: 'error' }
        );
      });
  }

  const values = methods.getValues();
  const { isHeadquarters, isMailingAddress, isLocationActive } = values;

  const handleAgency = useCallback(
    (selectedOption) => {
      methods.setValue('agency', selectedOption);
    },
    // eslint-disable-next-line
    []
  );

  const handleInputChange = () => {
    return (newInputValue) => {
      methods.setValue('agency', newInputValue);
      return setAgencyInputValue(newInputValue);
    };
  };

  return (
    <FormLanguageProvider {...languageFormState}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent style={{ paddingTop: '1.5rem' }}>
            <AgencyLookupField
              name="agency"
              value={values.agency}
              {...methods.register('agency')}
              inputValue={agencyInputValue}
              onInputChange={handleInputChange('agency')}
              placeholder={`Select ${t(translationKeys.agency)}`}
              error={_.get(methods.formState.errors, 'agency.message')}
              required
              style={{ marginBottom: '0.75rem' }}
              onChange={handleAgency}
            />

            <TextField
              name="locationName"
              label="Location Name"
              required
              fullWidth
            />

            <GridLanguageForm
              gridLayoutSettings={languageFormState.gridLayoutSettings}
            />

            <Box pt={1}>
              <LanguageForm.PhoneField />
            </Box>

            <MuiGrid container spacing={0} className={classes.radioField}>
              <MuiGrid item md={4}>
                <div className={classes.addUser}>
                  <CheckBoxBase
                    name="isHeadquarters"
                    label="Headquarters"
                    {...methods.register('isHeadquarters')}
                    defaultChecked={isHeadquarters}
                  />
                </div>
              </MuiGrid>
              <MuiGrid item md={4}>
                <div className={classes.addUser}>
                  <CheckBoxBase
                    name="isMailingAddress"
                    label="Mailing Address"
                    {...methods.register('isMailingAddress')}
                    defaultChecked={isMailingAddress}
                  />
                </div>
              </MuiGrid>
              <MuiGrid item md={4}>
                <div className={classes.addUser}>
                  <CheckBoxBase
                    name="isLocationActive"
                    label="Active Location"
                    {...methods.register('isLocationActive')}
                    defaultChecked={isLocationActive}
                  />
                </div>
              </MuiGrid>
            </MuiGrid>
          </DialogContent>
          <DialogActions>
            <CBButton onClick={props.close} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={methods.formState.isSubmitting}
              disabled={
                !methods.formState.isDirty || methods.formState.isSubmitting
              }
              styleName="ctaButton"
              buttonText={editing ? 'Update Location' : 'Save Location'}
            />
          </DialogActions>
        </form>
      </FormProvider>
    </FormLanguageProvider>
  );
});

export const AdminCreateEditLocationModalConfig = {
  AdminCreateEditLocationModal: {
    component: AdminCreateEditLocationModal,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      title: 'Edit Location',
    },
  },
};
