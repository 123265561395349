import React from 'react';
import type { Autocomplete } from '@mui/lab';
import type { AxiosResponse } from 'axios';
import type { TextFieldProps } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { getTeamsForAgentInAgency } from '../../../../teams/TeamsService';
import type { InputLabelBaseProps } from '../../../inputs/InputLabelBase';
import { TypeAheadAutoComplete } from '../../../inputs/autocomplete/TypeAheadAutoComplete';
import type { UpdateAgentInfoFormValues } from '../UpdateAgentInfo';

interface TeamAutoCompleteProps {
  label: string;
  name: string;
  required?: boolean;
  onSelect: (value: TeamValue) => void;
  autoCompleteProps?: React.ComponentType<typeof Autocomplete> | object;
  inputLabelBaseProps?: Omit<InputLabelBaseProps, 'children'>;
  textFieldProps?: TextFieldProps;
}

type TeamValue = {
  label: string;
  value: string;
};

export const TeamAutoComplete = (props: TeamAutoCompleteProps) => {
  const { watch, formState, setValue } =
    useFormContext<UpdateAgentInfoFormValues>();

  const [selectedAgent, team] = watch(['agent', 'team']);

  const agentId = selectedAgent?.value ?? '';
  React.useEffect(() => {
    setValue('team', { label: '', value: '' });
  }, [props.name, agentId, setValue]);

  const queryKey = ['typeahead', props.name, selectedAgent?.value];

  const getTeams = (searchTerm: string) => {
    if (selectedAgent?.value) {
      return getTeamsForAgentInAgency({
        userId: selectedAgent?.value,
        search: searchTerm,
      }).then((resp: AxiosResponse) => {
        return resp.data.content.map((teamOption: TeamOption) => {
          return {
            label: teamOption.name,
            value: teamOption.id,
          };
        });
      });
    }

    return Promise.resolve([]);
  };

  return (
    <TypeAheadAutoComplete
      asyncFunction={getTeams}
      queryKey={queryKey}
      textFieldProps={{
        error: Boolean(formState.errors.team?.value),
        helperText: formState.errors.team?.value?.message,
      }}
      {...props}
      autoCompleteProps={{ ...props.autoCompleteProps, value: team }}
    />
  );
};

type TeamOption = {
  name: string;
  id: string;
};
