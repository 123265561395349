import { makeStyles } from '@mui/styles';

const useGlobalStyles = makeStyles(globalStyles);

const GlobalStyles = () => {
  // Sets global style overrides
  useGlobalStyles();

  return null;
};

export default GlobalStyles;

function globalStyles({ palette, shape, ...theme }) {
  const primaryText = palette.text.primary;
  const secondaryText = palette.text.secondary;

  return {
    '@global': {
      html: {
        fontSize: 12,

        '@media (min-width: 1620px)': {
          fontSize: 14,
        },

        '@media (min-width: 1920px)': {
          fontSize: 16,
        },
      },

      body: {
        minHeight: '100%',
        margin: 0,
        overflow: 'auto',
        background: palette.background.default,
        backgroundImage:
          'url(/images/public-background-overlay.png), url(/images/public-background-2k.jpg)',
        backgroundSize: 'cover, cover',
        backgroundRepeat: 'no-repeat, repeat',
        color: primaryText,
        fontFamily: theme.typography.fontFamily,
        '-webkit-font-smoothing': 'antialiased',
      },

      '.oswald': {
        fontFamily: 'Oswald',
      },

      '.titillium-reg, .titillium-light': {
        fontFamily: theme.config.fonts.titillium,
        fontWeight: theme.config.weights.normal,
      },

      '.titillium-light': {
        fontWeight: theme.config.weights.light,
      },

      img: {
        maxWidth: '100%',
      },

      a: {
        color: secondaryText,
        textDecoration: 'none',
      },

      h1: {
        fontSize: theme.config.textSizes.canon,
        fontWeight: theme.typography.fontWeightLight,
        color: palette.text.secondary,
        '-webkit-font-smoothing': 'antialiased',
        fontSmooth: 'always',
      },

      h2: {
        fontSize: theme.config.textSizes.paragon,
        color: palette.text.text8,
      },
      '.nonpareil-text': {
        fontSize: theme.config.textSizes.nonpareil,
      },

      '.canon-text': {
        fontSize: theme.config.textSizes.canon,
      },

      '.paragon-text': {
        fontSize: theme.config.textSizes.paragon,
      },

      '.great-primer-text': {
        fontSize: theme.config.textSizes.greatPrimer,
      },

      '.primer-text': {
        fontSize: theme.config.textSizes.primer,
      },

      '.tertia-prime-text': {
        fontSize: theme.config.textSizes.tertiaPrime,
      },

      '.tertia-text': {
        fontSize: theme.config.textSizes.tertia,
      },

      '.normal-text': {
        fontSize: theme.config.textSizes.normal,
      },

      '.petite-text': {
        fontSize: theme.config.textSizes.petite,
      },

      '.minion-text': {
        fontSize: theme.config.textSizes.minion,
      },

      '.contrast-text': {
        color: palette.primary.contrastText,
      },

      '.blue-text': {
        color: palette.common.cowbellBlue,

        'a&': {
          fontWeight: 600,
          textDecoration: 'underline',
        },
      },

      '.green-text': {
        color: palette.common.cowbellAccent,

        'a&': {
          fontWeight: 600,
          textDecoration: 'underline',
        },
      },

      '.yellow-text': {
        color: palette.common.yellow,
      },

      '.orange-text': {
        color: '#ffcc58',
      },

      '.error-text': {
        color: palette.common.tomato,
      },

      // used for global color for react-highlighter
      '.highlight': {
        background: palette.common.cowbellAccent,
        color: palette.primary.contrastText,
      },

      '.text-uppercase': {
        textTransform: 'uppercase',
      },

      '.card-heading': {
        marginBottom: 5,
        position: 'relative',
        fontSize: '1rem',
        textTransform: 'uppercase',

        '&:first-of-type': {
          marginTop: 0,
        },

        '&:after': {
          content: "''",
          width: '100%',
          height: 1,
          position: 'absolute',
          bottom: 0,
          left: 0,
          backgroundColor: palette.text.primary,
        },
      },

      '.company': {
        fontWeight: theme.typography.fontWeightRegular,
        '-webkit-font-smoothing': 'antialiased',
        fontSmooth: 'always',
      },

      '.flex--spaced': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        '&.align-baseline': {
          alignItems: 'baseline',
        },

        '&.align-start': {
          alignItems: 'flex-start',
        },

        '.action-bar > &': {
          marginBottom: '0.8333333333rem',
        },
      },

      '.view__header': {
        display: 'flex',
        padding: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',

        '& > div': {
          display: 'flex',
          alignItems: 'center',
        },
        '& > h1': {
          color: palette.background.paper,
          fontSize: '18px',
        },
      },

      '.main-root-wrapper': {
        width: '100%',
        minHeight: '100%',
        background: palette.background.default,
      },

      '.main-root-container': {
        display: 'flex',
        width: '100%',
        minHeight: '100%',
        background: palette.background.default,

        [theme.breakpoints.up('md')]: {
          padding: '5.4rem 1rem 0 1rem',
        },

        [theme.breakpoints.up('lg')]: {
          padding: '5.4rem 1rem 0 1rem',
        },
      },

      '.main-content-container': {
        flexBasis: '100%',
        flexGrow: 1,
        padding: '0 0 1rem 1rem',
        background: palette.background.default,

        '& h1': {
          color: palette.primary.title,
        },
      },

      '.main-content': {
        display: 'flex',
        flex: 1,
        flexGrow: 1,
        flexBasis: '100%',
        flexDirection: 'column',
        maxWidth: 'calc(100% - 11.6666666667rem)',
        [theme.breakpoints.down('md')]: {
          maxWidth: 'calc(100% - 0.833333333333333rem)',
        },

        '& > *': {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'flex-start',
        },
      },

      '.sidebar-container': {
        width: '11.6666666667rem',
        [theme.breakpoints.down('md')]: {
          width: 'auto',
          // height: 'auto',
        },
      },
      '.footer': {
        zIndex: 10,
        textAlign: 'center',
        fontSize: '1rem',
        display: 'flex',
        flexDirection: 'column',
        height: '7rem',
        bottom: '-2rem',
        position: 'fixed',
        paddingLeft: '4.166666666666667rem',
        color: palette.mode === 'light' ? '#e4e8ed' : '#47474d',
      },

      '.select--button': {
        background: palette.background.default,
        border: `1px solid ${palette.common.lightBlueGrey}`,
        borderRadius: shape.borderRadius,
        color: palette.text.primary,
        fontSize: theme.typography.fontSize,

        '&:before': {
          display: 'none',
        },

        '&:after': {
          display: 'none',
        },
      },

      '.text-center': {
        textAlign: 'center',
      },

      '.text-right': {
        textAlign: 'right',
      },

      '.align-left': {
        justifyContent: 'flex-start',
      },

      '.align-right': {
        justifyContent: 'flex-end',
      },

      '.table-row__expandable:hover': {
        cursor: 'pointer',
      },

      '.table-heading': {
        marginBottom: 0,
        fontSize: theme.config.textSizes.paragon,
      },

      '.is-loading.shimmer': {
        position: 'relative',
        overflowX: 'hidden',
        background: palette.background.default,

        '&:before': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          backgroundColor: palette.background.default,
          background: `linear-gradient(to right, transparent 1%, ${palette.tables.shimmer} 18%, transparent 40%)`,
          transform: 'translateX(-50%)',

          animationDuration: '1.75s',
          animationFillMode: 'forwards',
          animationIterationCount: 'infinite',
          animationName: 'block-shimmer',
        },

        '& > *': {
          opacity: 0,
        },
      },

      '.input-group': {
        marginTop: 15,
      },

      '.or-divider': {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',

        '&:before': {
          content: '""',
          width: '45%',
          height: 1,
          background: palette.text.primary,
          marginRight: 10,
        },

        '&:after': {
          content: '""',
          width: '45%',
          height: 1,
          background: palette.text.primary,
          marginLeft: 10,
        },
      },

      '.accordion-header__checkbox': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      },

      '.accordion-header__checkbox__label': {
        margin: 0,
      },
      '.cursor--pointer': {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      '.hover--link': {
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '.mtn': {
        marginTop: 0,
      },

      '.man': {
        margin: 0,
      },

      '.mtn-login': {
        marginBottom: 20,
        '& label': {
          color: theme.config.colors.almostWhite,
        },
      },

      '.stats-bar': {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexFlow: 'wrap',
        marginBottom: '1rem',
        padding: '1rem',
      },

      'html, .custom-scrollbar': {
        '&::-webkit-scrollbar-track': {
          borderRadius: 50,
          backgroundColor: 'transparent',
        },

        '&::-webkit-scrollbar': {
          width: 7,
          height: 7,
        },

        '&::-webkit-scrollbar-thumb': {
          borderRadius: 50,
          backgroundColor: palette.text.primary,
        },
      },

      '.custom-scrollbar': {
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent',
        },

        '&:hover::-webkit-scrollbar-thumb': {
          backgroundColor: palette.text.primary,
        },
      },

      '.page-container': {
        height: '100%',

        '& .main-container': {
          backgroundColor: palette.background.lighter,
          padding: '2.1667rem 2.083rem',
          height: '100%',

          '& .header-container': {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${palette.primary.modalBorder}`,
            paddingBottom: '2rem',

            '& .header-title': {
              fontSize: '2rem',
              fontWeight: 600,
              lineHeight: 1.13,
              margin: '0 !important',
            },
          },
        },
      },
    },
  };
}
