import { InputLabelBase } from '../../../../../components/inputs/InputLabelBase';
import type { Question } from './securityQuestions.statics';
import {
  QuestionTypes,
  commonAnswerGroupStyles,
  commonQuestionGroupStyles,
  encryptionQuestionGroupStyles,
  mfaQuestionGroupStyles,
  planningQuestionGroupStyles,
} from './securityQuestions.statics';

/* SCHEMAS */
export const prime100ProQuestions = [
  /* Training  */
  {
    questionHeading: '1. Training',
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0 }}
        htmlFor="training"
        {...inputLabelProps}
      >
        Is cybersecurity training required for all employees with Computer
        System access at least annually?
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    name: 'training',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /*  Multifactor Authentication - mfaEmail*/
  {
    questionHeading: '2. Multifactor Authentication',
    topLevelQuestion: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        className="tertia-text"
        style={{ paddingTop: '0.75rem', paddingLeft: '1.5rem' }}
        {...inputLabelProps}
      >
        Is MFA deployed uniformly across all accounts and logins for access to
        the following?
      </InputLabelBase>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaEmail"
        {...inputLabelProps}
      >
        Email:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'mfaEmail',
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
  },

  /* Multifactor Authentication - mfaRemoteSystemAccess */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaRemoteSystemAccess"
        {...inputLabelProps}
      >
        Remote Computer System:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaRemoteSystemAccess',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
  },

  /* Multifactor Authentication - mfaAdminAccounts */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaAdminAccounts"
        {...inputLabelProps}
      >
        Admin/Privileged Accounts:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaAdminAccounts',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
  },

  /* Multifactor Authentication - mfaCloud */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaCloud"
        {...inputLabelProps}
      >
        Cloud:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaCloud',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
  },

  /* Multifactor Authentication - mfaVendor */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaVendor"
        {...inputLabelProps}
      >
        Vendor Access to Computer System:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaVendor',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
  },

  /* Multifactor Authentication - mfaComments */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="mfaComments"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        Comments:
      </InputLabelBase>
    ),
    questionGroupStyle: {
      paddingLeft: '1.5rem',
      paddingBottom: '0.5rem',
    },
    type: QuestionTypes.text,
    placeholder: 'MFA comments',
    name: 'mfaComments',
  },

  /* Backups - backupFrequency */
  {
    questionHeading: '3. Backups',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0 }}
        htmlFor="backupFrequency"
        {...inputLabelProps}
      >
        How often does the Organization perform backups of business-critical
        data?
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: {
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'backupFrequency',
    required: true,
    dynamic: true,
    answers: [
      { label: 'Daily or More Often', value: 'DAILY' },
      { label: 'Weekly', value: 'WEEKLY' },
      { label: 'Monthly', value: 'MONTHLY' },
      { label: 'Quarterly', value: 'QUARTERLY' },
      { label: 'Biannually', value: 'SIX_MONTHS' },
      { label: 'Never', value: 'NEVER' },
    ],
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            style={{ paddingTop: 0 }}
            htmlFor="backupTypes"
            {...inputLabelProps}
          >
            3a. Backups are (select all that apply):
          </InputLabelBase>
        ),
        type: QuestionTypes.multiSelect,
        questionGroupStyle: {
          paddingLeft: '1.5rem',
        },
        answerGroupStyle: commonAnswerGroupStyles,
        name: 'backupTypes',
        required: true,
        dynamic: true,
        comments: true,
        show: {
          fieldName: 'backupFrequency',
          isNot: 'NEVER',
        },
        answers: [
          {
            label: 'Segmented (either offline or air-gapped)',
            value: 'BACKUP_FREQUENCY_SEGMENTED',
          },
          {
            label: `In a cloud service that is secured by login credentials separate from the Computer System credentials and/or requires MFA`,
            value: `BACKUP_FREQUENCY_CLOUD`,
            tooltip:
              'In a cloud service that is secured by login credentials separate from the Computer System credentials and/or requires MFA',
          },
          {
            label: 'Tested at least annually',
            value: 'BACKUP_FREQUENCY_TESTED_ANNUALLY',
          },
          { label: 'Other (please specify)', value: 'BACKUP_FREQUENCY_OTHER' },
        ],
      },
    ],
  },

  /* Backups - patchingFrequency */
  {
    questionHeading: '4. Patching',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0 }}
        htmlFor="patchingFrequency"
        {...inputLabelProps}
      >
        How often does the Organization apply updates to Computer Systems and
        applications when a patch is released?
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'patchingFrequency',
    questionGroupStyle: {
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers: [
      { label: 'Daily or More Often', value: 'DAILY' },
      { label: 'Weekly', value: 'WEEKLY' },
      { label: 'Monthly', value: 'MONTHLY' },
      { label: 'Quarterly', value: 'QUARTERLY' },

      { label: 'Biannually', value: 'SIX_MONTHS' },
      { label: 'Never', value: 'NEVER' },
    ],
  },

  /* Backups - vendorManagement */
  {
    questionHeading: '5. Vendors',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '65%' }}
        htmlFor="vendorManagement"
        {...inputLabelProps}
      >
        Does the Organization have a vendor management program to ensure third
        parties' levels of security are commensurate with the Organization's
        cyber security standard?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'vendorManagement',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Encryption - encryptionAtRest */
  {
    questionHeading: '6. Encryption',
    topLevelQuestion: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        className="tertia-text"
        style={{ paddingTop: '0.5rem', paddingLeft: '1.5rem' }}
        {...inputLabelProps}
      >
        Is encryption of sensitive information in place for the following?
      </InputLabelBase>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="encryptionAtRest"
        {...inputLabelProps}
      >
        At Rest:
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    questionGroupStyle: encryptionQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'encryptionAtRest',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Encryption - encryptionInTransit */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="encryptionInTransit"
        {...inputLabelProps}
      >
        In transit:
      </InputLabelBase>
    ),
    questionGroupStyle: encryptionQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'encryptionInTransit',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Encryption - encryptionOnDevices */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="encryptionOnDevices"
        {...inputLabelProps}
      >
        On mobile devices:
      </InputLabelBase>
    ),
    questionGroupStyle: encryptionQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'encryptionOnDevices',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Contingency Planning - planIncidentResponse */
  {
    questionHeading: '7. Contingency Planning',
    topLevelQuestion: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        className="tertia-text"
        style={{ paddingTop: '0.75rem', paddingLeft: '1.5rem' }}
        {...inputLabelProps}
      >
        Does the Organization have the following written plans, in place and
        tested, at least annually related to a potential interruption of their
        Computer Systems?
      </InputLabelBase>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="planIncidentResponse"
        {...inputLabelProps}
      >
        Incident Response:
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    questionGroupStyle: planningQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'planIncidentResponse',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Contingency Planning - planDisasterRecovery */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="planDisasterRecovery"
        {...inputLabelProps}
      >
        Disaster Recovery:
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    questionGroupStyle: planningQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'planDisasterRecovery',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Contingency Planning - planBusinessContinuity */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="planBusinessContinuity"
        {...inputLabelProps}
      >
        Business Continuity:
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    questionGroupStyle: planningQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'planBusinessContinuity',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Contingency Planning - plansComments */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="plansComments"
        {...inputLabelProps}
      >
        Comments:
      </InputLabelBase>
    ),
    questionGroupStyle: {
      paddingLeft: '1.5rem',
      paddingBottom: '0.5rem',
    },
    type: QuestionTypes.text,
    name: 'plansComments',
  },

  /* Cyber Crime - isVerifyingBankAccounts */
  {
    questionHeading: (
      <>
        8. Cyber Crime{' '}
        <i style={{ fontWeight: 500 }}>
          (Required if purchased Cyber Crime, otherwise Optional)
        </i>
      </>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="isVerifyingBankAccounts"
        {...inputLabelProps}
      >
        8a. Does the Organization verify vendor/supplier bank accounts before
        adding them to their accounts payable systems?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'isVerifyingBankAccounts',
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Cyber Crime - isAuthenticatingFundTransferRequests */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="isAuthenticatingFundTransferRequests"
        {...inputLabelProps}
      >
        8b. Is there an established procedure to verify changes to funds
        transfer instructions using a predetermined phone number of the vendor?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'isAuthenticatingFundTransferRequests',
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Cyber Crime - isPreventingUnauthorizedWireTransfers */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="isPreventingUnauthorizedWireTransfers"
        {...inputLabelProps}
      >
        8c. Does the Organization prevent unauthorized employees from initiating
        wire transfers?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'isPreventingUnauthorizedWireTransfers',
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },
] as Readonly<Question[]>;

export const optionalP100ProQuestions = [
  /* EOL Products */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="eolProducts"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        <strong>1. End-of-Life</strong> - Are any EOL products in use?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'eolProducts',
    dynamic: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase style={{ paddingTop: 0 }} {...inputLabelProps}>
            1a. If yes, please comment on usage with supplemental control
            information:
          </InputLabelBase>
        ),
        questionGroupStyle: {
          paddingBottom: '0.5rem',
        },
        type: QuestionTypes.text,
        name: 'eolProductsUsage',
        show: {
          fieldName: 'eolProducts',
          is: 'YES',
        },
        placeholder:
          'Please comment on usage with supplemental control information',
      },
    ],
  },

  /* Endpoint Detection and Response */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="edr"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        <strong>2. Endpoint Detection & Response</strong> - Is an EDR tool in
        place across the Organization's Computer System?
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'edr',
    /* comments: true, */
    dynamic: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'Uncertain', value: 'UNCERTAIN' },
    ],
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            htmlFor="edrVendor"
            style={{ paddingTop: 0 }}
            {...inputLabelProps}
          >
            2a. If yes, who is the vendor providing the EDR product? <br />
            Comments
          </InputLabelBase>
        ),

        type: QuestionTypes.text,
        placeholder: `Who is the vendor providing the EDR product?`,
        name: 'edrVendor',
        show: {
          fieldName: 'edr',
          is: 'YES',
        },
      },
    ],
  },

  /* Next generation Antivirus */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="ngav"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        <strong>3. Next Generation Antivirus</strong> - Is NGAV in place across
        the Organization's Computer System?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'ngav',
    /* comments: true, */
    dynamic: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'Uncertain', value: 'UNCERTAIN' },
    ],
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            htmlFor="ngavVendor"
            style={{ paddingTop: 0 }}
            {...inputLabelProps}
          >
            3a. Who is the vendor providing the NGAV product? <br />
            Comments
          </InputLabelBase>
        ),
        type: QuestionTypes.text,
        placeholder: `Who is the vendor providing the NGAV product?`,
        name: 'ngavVendor',
        show: {
          fieldName: 'ngav',
          is: 'YES',
        },
      },
    ],
  },
] as unknown as Readonly<Question[]>;

export const P100ProPastActivitiesQuestions = [
  /* priorCoverage */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="priorCoverage"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        1. Does the Organization currently carry an insurance policy that
        insures against a Cyber loss?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'priorCoverage',
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'Uncertain', value: 'UNCERTAIN' },
    ],
  },

  /* priorOrPendingClaimCircumstances */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="priorOrPendingClaimCircumstances"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        2. Is the Organization or any person aware of any prior or pending
        circumstances that could lead to a claim under the proposed policy?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'priorOrPendingClaimCircumstances',
    dynamic: true,
    required: true,
    comments: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* cyberEvent */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="cyberEvent"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        3. Has the Organization sustained a Cyber Incident in the last five
        years? If yes, provide loss runs and a detailed description of all
        claims.
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'cyberEvent',
    required: true,
    comments: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* privacyLitigation */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="privacyLitigation"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        4. In the past five years, has the Organization been subject to
        litigation resulting from a privacy matter? This includes regulatory and
        civil action.
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'privacyLitigation',
    required: true,
    comments: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* unscheduledSystemDowntime */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="unscheduledSystemDowntime"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        5. During the last three years, has the Organization suffered loss of
        business income as a result of unscheduled system downtime?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'unscheduledSystemDowntime',
    required: true,
    comments: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },
] as unknown as Readonly<Question[]>;
