// mui
import { Box, Divider } from '@mui/material';

// components
import { Flexbox } from '../../../../../../components/layout/Flexbox';

// utils
import { PolicyDrawerTypography as Typography } from '../policy-details.utils';
import { useCowbellTranslations } from '../../../../../../i18n/translations';
import type { PolicyTableInfoDto } from '../../../../../../types/policy/tables/policy-table-info.dto';

interface Props {
  policy: PolicyTableInfoDto;
}

export const OrganizationBroker = ({ policy }: Props) => {
  const { t, translationKeys } = useCowbellTranslations();

  return (
    <>
      <Box>
        <Typography variant="subtitle2" color="primary">
          {t(translationKeys.agent).toUpperCase()} INFO
        </Typography>
      </Box>
      <Divider style={{ width: '100%', margin: '0.5rem 0 1rem 0' }} />
      <Box display="flex">
        <Box width="50%" marginRight={1}>
          <Flexbox>
            <Typography>{t(translationKeys.agency)}</Typography>
            <Box>
              <Typography>{policy.agencyName}</Typography>
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>{t(translationKeys.agent)} Name</Typography>
            <Box>
              <Typography>{`${policy.agentFirstName} ${policy.agentLastName}`}</Typography>
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>{t(translationKeys.agent)} Email</Typography>
            <Box>
              <Typography
                component="a"
                href={`mailto:${policy.agentEmail}`}
                className="link-underlined--hover"
              >
                {policy.agentEmail}
              </Typography>
            </Box>
          </Flexbox>
        </Box>
      </Box>
    </>
  );
};
