import React, { useState } from 'react';
import _ from 'lodash';

// mui
import { useForm, FormContext } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
// components
import { DialogActions, DialogContent, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CBButton from '../../../../components/Buttons/CbButton';
// platform helpers
import { delayedEvent } from '../../../../utils/eventUtils';
// actions
import { deleteLicense } from '../Modals/surplusServices';

export const DeleteSurplusLicenseModal = ({
  classes,
  data,
  iconStyles,
  ...props
}) => {
  const [backendError, setBackendError] = useState('');
  const { handleSubmit, ...methods } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  function onSubmit() {
    return deleteLicense({ id: data.data.id })
      .then(() => {
        enqueueSnackbar('Surplus License deleted succesfully!', {
          variant: 'success',
        });
        props.close();
        delayedEvent('table-refetch', 500, 'surplusLicense');
      })
      .catch((error) => {
        setBackendError(error.statusText);
      });
  }
  const textStyle = useStyles();
  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ textAlign: 'center', marginTop: '3.375rem' }}>
            <span className={textStyle.textStyle}>
              Are you sure you want to delete Surplus License{' '}
              <span className={textStyle.deleteSpan}>
                {' '}
                {data.data.surplusLicenseNumber}
              </span>
              ?
            </span>
          </DialogContent>
          <FormHelperText className="api-text-error">
            {backendError}
          </FormHelperText>
          <DialogActions>
            <CBButton onClick={props.close} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton type="submit" styleName="ctaButton">
              Delete
            </CBButton>
          </DialogActions>
        </form>
      </FormContext>
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  deleteSpan: { color: palette.common.cowbellBlue },
  regularStyle: { color: palette.primary.main },
}));

export const DeleteSurplusLicenseConfig = {
  DeleteSurplusLicenseModal: {
    component: DeleteSurplusLicenseModal,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      title: 'Delete Surplus License',
    },
  },
};
