import React from 'react';
import { SvgIcon } from '@mui/material';

export const AddIcon = ({ ...props }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      {...props}
    >
      <g>
        <path
          fill="none"
          d="M0 0H45V45H0z"
          transform="translate(-250 -56) translate(250 56)"
        />
        <path
          fill="#9ba0ae"
          d="M6 9H3v21a3.009 3.009 0 0 0 3 3h21v-3H6zm24-6H12a3.009 3.009 0 0 0-3 3v18a3.009 3.009 0 0 0 3 3h18a3.009 3.009 0 0 0 3-3V6a3.009 3.009 0 0 0-3-3zm-1.5 13.5h-6v6h-3v-6h-6v-3h6v-6h3v6h6z"
          transform="translate(-250 -56) translate(255 61)"
        />
      </g>
    </SvgIcon>
  );
};
