/* eslint-disable no-shadow */
import { Divider, Slide, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
// actions
import {
  createNote,
  deleteNote,
  getAllQuoteNotes,
  updateNoteById,
} from './notes.service';
import { NotesEditor } from './NotesEditor';
// components
import { withUploadFeatureDocuments } from '../../../components/fileUploader/withUploadFeatureDocuments';
import { NotesHeader } from './NotesHeader';
import { withEditorFunctions } from './withEditorFunctions';
import { delayedEvent, PubSub } from '../../../utils/eventUtils';
import {
  DropZoneOverlay,
  withUploadFeature,
} from '../../../components/fileUploader/withUploadFeature';
import { Notes } from './Notes';
import { setUploadedDocs } from './notes.reducer';
import { NotesBodyHeader } from './NotesBodyHeader';

const styles = ({ config }) => {
  return {
    container: {
      width: '34rem',
      maxHeight: '45rem',
      borderRadius: 5,
      'box-shadow': '3px 3px 6px 0 rgba(0, 0, 0, 0.5)',
      border: `solid 1px ${config.colors.cowbellBlue}`,
      backgroundColor: config.colors.paleBlue,
      position: 'fixed',
      right: '4rem',
      top: '20rem',
      zIndex: 2,
      lineHeight: 1.3,
      padding: '1rem',
      overflow: 'auto',
      '& .MuiButton-root': {
        minWidth: 'auto',
        '&:last-child': {
          padding: '0 0 0 1rem',
        },
      },
      minHeight: '20rem',
    },
    modalContainer: {
      width: '50rem',
      maxHeight: '50rem',
      borderRadius: 5,
      'box-shadow': '3px 3px 6px 0 rgba(0, 0, 0, 0.5)',
      border: `solid 1px ${config.colors.cowbellBlue}`,
      backgroundColor: config.colors.paleBlue,
      padding: '1rem',
      overflow: 'auto',
      '& .MuiButton-root': {
        minWidth: 'auto',
        '&:last-child': {
          padding: '0 0 0 1rem',
        },
      },
    },
    noNotes: {
      fontSize: config.textSizes.tertia,
      fontWeight: config.weights.bold,
      padding: '1rem',
      color: config.colors.cowbellBlue,
    },
    divider: {
      backgroundColor: config.colors.cowbellBlue,
      marginTop: '0.1rem',
    },
  };
};

export const NotesContainer = compose(
  withEditorFunctions,
  withStyles(styles),
  withUploadFeatureDocuments(0, 'notes'),
  withUploadFeature({ screenCenter: false, hide: true }),
  React.forwardRef
)(
  (
    { classes, handleNotes, quoteData, quoteId, modal, readOnly, ...props },
    ref
  ) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [add, setAdd] = useState(false);
    const showSearch = !add;
    const [isNoteEditing, setEditing] = useState(false);
    const isNoteActive = add || isNoteEditing;
    const dispatch = useDispatch();
    const attachments = useSelector(({ notes }) => notes.docs.attachments);

    // fetch and search
    const searchAllNotesQuotes = (q) => {
      getAllQuoteNotes(
        { quoteId, search: q, orderBy: 'created', order: 'desc' },
        {}
      )
        .then((resp) => {
          setData(resp.data.content);
        })
        .catch((error) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Something went wrong.Please try again later'
              )
            )
          );
        });
    };

    useEffect(() => {
      PubSub.publish('toggleFileUploadFeature', !isNoteActive);
    }, [isNoteActive]);

    useEffect(() => {
      searchAllNotesQuotes();
      if (add) {
        props.focusEditor();
      }
      // eslint-disable-next-line
    }, []);

    // create note
    const saveNote = () => {
      const noteText = props.getEditorText();

      if (noteText.length === 0) {
        setError('Notes were empty, please enter notes');
        return;
      }
      setError('');

      const payload = {
        quoteId,
        noteText,
        attachments,
      };

      createNote({}, payload)
        .then(() => {
          dispatch(setUploadedDocs({ attachments: [] }));
          searchAllNotesQuotes();
          setAdd(false);
          props.createEmptyEditor();
          delayedEvent('table-refetch', 500, 'UWNotes');
        })
        .catch((error) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Something went wrong.Please try again later'
              )
            )
          );
        });
    };

    // update note
    const updateNote = (id, payload) => {
      if (payload.noteText.length === 0) {
        setError('Notes were empty, please enter notes');
        return;
      }
      setError('');

      updateNoteById({ noteId: id }, payload)
        .then(() => {
          searchAllNotesQuotes();
          setEditing(false);
          dispatch(setUploadedDocs({ attachments: [] }));
          props.createEmptyEditor();
        })
        .catch((error) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Something went wrong.Please try again later'
              )
            )
          );
        });
    };

    // delete note
    const delNote = (id) => {
      deleteNote({ noteId: id }, {})
        .then(() => {
          searchAllNotesQuotes();
          props.createEmptyEditor();
        })
        .catch((error) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Something went wrong.Please try again later'
              )
            )
          );
        });
    };

    return (
      <Slide
        direction={modal ? 'up' : 'left'}
        in={props.open}
        mountOnEnter
        unmountOnExit
      >
        <div
          className={
            modal
              ? `${classes.modalContainer} modal-title`
              : `${classes.container} modal-title`
          }
        >
          <NotesHeader
            account={quoteData}
            setAdd={setAdd}
            handleNotes={handleNotes}
            title={`Notes: ${quoteData.quoteNumber}`}
            search={showSearch}
            searchFunc={searchAllNotesQuotes}
            readOnly={readOnly || isNoteEditing}
          />
          {error && (
            <Typography className="api-text-error" color="error">
              {error}
            </Typography>
          )}

          {_.isEmpty(data) && !add && (
            <div className={classes.noNotes}>No Notes added.</div>
          )}

          {add && (
            <NotesEditor
              saveNote={saveNote}
              setAdd={setAdd}
              ref={ref}
              {...props}
            />
          )}

          {data &&
            data.map((value, index) => {
              return (
                <div key={index}>
                  <Notes
                    HeaderComponent={NotesBodyHeader}
                    data={value}
                    onUpdate={updateNote}
                    onDelete={delNote}
                    readOnly={readOnly || add}
                    setEditing={setEditing}
                    editing={isNoteEditing}
                    {...props}
                  />
                  <Divider className={classes.divider} />
                </div>
              );
            })}
          <DropZoneOverlay />
        </div>
      </Slide>
    );
  }
);

export default NotesContainer;
