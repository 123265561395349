import React from 'react';
import { useSelector } from 'react-redux';

import { useAuth } from './useAuth';

import { PERSONA_TYPES } from '../../console/_statics/persona.statics';
import { selectActor } from '../../store/selectors/user.selectors';
import { useAllow } from './useAllow';

export const useActor = () => {
  const { subscriberType: actorType } = useAuth();

  const { user, account } = useSelector(selectActor);

  const isAdminUser = useAllow('role:admin');
  const isPatraUser = useAllow('role:operator') && !isAdminUser;

  return React.useMemo(
    () => ({
      ...user,
      account,
      isPatraUser,

      /**
       * @description indicates the current USER that is acting in the platform.
       */
      isCowbell: actorType === PERSONA_TYPES.COWBELL,
      isAgency: actorType === PERSONA_TYPES.AGENCY,
      isInsured: actorType === PERSONA_TYPES.CUSTOMER,
      isDigital: actorType === PERSONA_TYPES.DIGITAL,
      isMssp: actorType === PERSONA_TYPES.MSSP,
      isCarrier: actorType === PERSONA_TYPES.CARRIER,
      isCluster: actorType === PERSONA_TYPES.CLUSTER,
      isVendor: actorType === PERSONA_TYPES.VENDOR,
    }),
    [user, account, actorType, isPatraUser]
  );
};
