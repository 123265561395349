import React from 'react';
import { CircularProgress as MuiCircularProgress } from '@mui/material';

import Button from './CbButton';

export const LoadingButton = ({
  children,
  loading = false,
  whileLoading,
  loadingText = 'Loading...',
  progressProps,
  ...props
}) => {
  const loadingContent =
    typeof whileLoading === 'function' ? (
      whileLoading(props)
    ) : (
      <>
        <MuiCircularProgress
          size={14}
          style={{ marginRight: 7 }}
          {...progressProps}
        />
        {loadingText}
      </>
    );

  return (
    <Button disabled={loading} {...props}>
      {loading ? loadingContent : children}
    </Button>
  );
};
