import React from 'react';

// components
import { IconButton, styled } from '@mui/material';
import { withShowable } from '../../console/_global/lib/withShowable';

export const JumpPersonaButton = withShowable(({ onJumpRequest, ...props }) => {
  function handleClick(event) {
    event.stopPropagation();

    if (typeof onJumpRequest === 'function') {
      onJumpRequest();
    }
  }

  return (
    <IconButton
      size="small"
      onClick={handleClick}
      {...props}
      style={{ marginLeft: '0.416rem' }}
    >
      <Icon src="/images/JumpIn.svg" alt="Jump into account icon" />
    </IconButton>
  );
});

const Icon = styled('img')({
  width: '100%',
  minWidth: '1rem',
  maxWidth: '1.2rem',
});
