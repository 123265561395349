import React from 'react';
import { compose } from 'redux';
import { useForm, FormContext } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';

import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { withStyles } from '@mui/styles';
import CBButton from '../../Buttons/CbButton';

import { deleteForm } from '../../../api/DocumentsService';
import { delayedEvent } from '../../../utils/eventUtils';

export const FormsLibraryConfirmDelete = compose(
  withStyles(({ config }) => ({
    container: {
      padding: '2.5rem',
    },
    highlight: {
      color: config.colors.cowbellBlue,
      fontWeight: config.weights.bold,
    },
  }))
)(({ data, classes, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { ...methods } = useForm();

  function onSubmit(id) {
    deleteForm({ id })
      .then(() => {
        delayedEvent('table-refetch', 500, 'manageFormsLibrary');
        enqueueSnackbar('Succesfully deleted form', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(
          'Sorry something went wrong, please try again in a few minutes.',
          { variant: 'error' }
        );
      });
    props.close();
  }
  return (
    <>
      <FormContext {...methods}>
        <DialogContent className={classes.container}>
          <div style={{ textAlign: 'center' }}>
            <DialogContentText>
              Are you sure you want to delete{' '}
              <span className={classes.highlight}>{data.name}</span>?
            </DialogContentText>
          </div>
        </DialogContent>

        <DialogActions>
          <CBButton size="small" styleName="cancel" onClick={props.close}>
            Cancel
          </CBButton>

          <CBButton
            size="small"
            type="button"
            styleName="ctaButton"
            onClick={() => {
              onSubmit(data.id);
            }}
          >
            Delete
          </CBButton>
        </DialogActions>
      </FormContext>
    </>
  );
});

export const FormsLibraryConfirmDeleteConfig = {
  FormsLibraryConfirmDelete: {
    component: FormsLibraryConfirmDelete,
    config: {
      fullWidth: true,
      title: 'Delete Form',
    },
  },
};
