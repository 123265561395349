import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form-4';
// Styles & Components
import { withStyles } from '@mui/styles';

// Helpers

// Lodash functions
import _ from 'lodash';

import { getUrlHost } from '../../utils/appUtils';
import { TextFieldBase } from './TextFieldBase';

export const HostnameField = withStyles(() => ({
  selectLabel: {
    paddingTop: '0.5rem',
  },
  textWrap: {
    '& div': {
      marginTop: '0rem',
    },
  },
}))((props) => {
  const [hostname, setHostname] = useState('');
  const methods = useFormContext();
  const hostName = getUrlHost();

  React.useEffect(() => {
    methods.register({ name: 'hostname' });
    if (props.defaultValue) {
      setHostname(props.defaultValue.split('.')[0]);
    }

    // eslint-disable-next-line
  }, [methods]);

  const handleHostnameChange = _.debounce((value) => {
    setHostname(value);
    methods.setValue('hostname', value);
  }, 500);

  const onHostnameChange = (event) => {
    handleHostnameChange(_.get(event, 'target.value'));
  };

  return (
    <TextFieldBase
      required
      label="Hostname"
      name="hostname"
      onChange={onHostnameChange}
      helperText={`${hostname || 'example'}${hostName}`}
      error={_.get(methods, 'errors.hostname.message')}
      defaultValue={
        !_.isUndefined(props.defaultValue)
          ? props.defaultValue.split('.')[0]
          : ''
      }
    />
  );
});
