// components
import { IconButton } from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';
import { ListItemBase } from '../../../../../../components/Menus/ListItemBase';

import { SimpleMenu } from '../../../../../../components/Menus/SimpleMenu';
import { SubMenu } from '../../../../../../components/Menus/SubMenu';

// other
import { Modal } from '../../../../../../components/modals/v2/helpers/v2.modal.helpers';
import { usePolicyActions } from '../../../../../../components/hooks/business-logic/policy-actions/usePolicyActions';
import { withShowable } from '../../../../../_global/lib/withShowable';

const ListItem = withShowable(ListItemBase);

export const CancelledPolicyDrawerActions = ({ data = {} }) => {
  const { handleApplyPayment } = useHandlers(data);

  const policyActions = usePolicyActions(data);

  return (
    <SimpleMenu
      id="cancelled-policy-drawer-actions"
      anchor={
        <IconButton size="small">
          <MenuIcon />
        </IconButton>
      }
      minWidth="19rem"
    >
      <SubMenu closeMenu header="Payments" show>
        <ListItem
          show={
            policyActions.CAN_APPLY_PAYMENT || policyActions.CAN_ISSUE_REFUND
          }
          onClick={handleApplyPayment}
          name="Apply Payment/Refund"
        />
      </SubMenu>
    </SimpleMenu>
  );
};

function useHandlers(data) {
  const handleApplyPayment = () => {
    Modal.show('ApplyExternalPayment', { data });
  };

  return { handleApplyPayment };
}
