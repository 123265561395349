import React from 'react';
import * as Ramda from 'ramda';

// mui
import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import {
  SubLevelStep,
  StepWithDescription,
  TopLevelStep as TopLevelStepBase,
} from './StepperSteps';
import { withShowable } from '../../../_global/lib/withShowable';
import Showable from '../../../../components/Showable';
import { Show } from '../../../../components/Show';
import { usePersona } from '../../../../components/hooks/usePersona';

import { BOUND_STATUS, SIGNED_STATUS } from '../../../_statics/quote.statics';
import { ProductTypes } from '../../../../types';

// steps
import { bindSteps } from '../constants/constants';
import { cowbellBindSteps } from '../../cowbell-bind-quote/constants/constants';
import { determineIsUKProduct } from '../utils/bindQuoteWorkFlowUtils';

const TopLevelStep = withShowable(TopLevelStepBase);

const BindQuoteStepper = ({
  stepperState,
  quoteProgress = {},
  sortedPreBindSteps: preBindSteps = [],
  sortedPreIssueSteps: preIssueSteps = [],
  subjectivities = {},
  incompletePreBindSteps = [],
  incompletePreIssueSteps = [],
  incompleteSteps = [],
  preBindKeys = [],
  preIssueKeys = [],
  quoteDetails,
}) => {
  const classes = useStyles();
  const persona = usePersona();

  const { productType } = quoteDetails.uiData;
  const isUKProduct = determineIsUKProduct(productType);

  const handleJumpToStep = (stepToJumpTo) => {
    return () => {
      stepperState.jumpTo(stepToJumpTo);
    };
  };

  const isUploadBindOrderStepComplete = Boolean(
    quoteDetails.uiData.bindOrderDocumentInfo?.docSignedOn &&
      quoteDetails.uiData.bindOrderDocumentInfo?.docId
  );

  const isUploadBindOrderPendingCompletion = incompleteSteps.some(
    Ramda.propEq('subjectivityTitle', cowbellBindSteps.UPLOAD_BIND_ORDER)
  );

  return (
    <Box className={classes.stepperContainer}>
      <Show when={persona.isCowbell}>
        <TopLevelStep
          stepperState={stepperState}
          stepName={cowbellBindSteps.UPLOAD_BIND_ORDER}
          hasStepBeenCompleted={isUploadBindOrderStepComplete}
          handleJumpToStep={handleJumpToStep}
        />
      </Show>

      <TopLevelStep
        stepperState={stepperState}
        stepName={bindSteps.POLICY_EFFECTIVE_DATE}
        hasStepBeenCompleted
        handleJumpToStep={handleJumpToStep}
        disableStep={isUploadBindOrderPendingCompletion}
      />

      <Showable show={isUKProduct}>
        <TopLevelStep
          stepperState={stepperState}
          stepName={bindSteps.UPDATE_COMMISSION}
          hasStepBeenCompleted
          handleJumpToStep={handleJumpToStep}
          disableStep={isUploadBindOrderPendingCompletion}
        />
      </Showable>

      <TopLevelStep
        show={!!preBindSteps.length}
        subSteps={preBindKeys}
        stepName="Pre-Bind Subjectivities"
        stepperState={stepperState}
        onClick={handleJumpToStep(preBindSteps[0]?.subjectivityKey)}
        hasStepBeenCompleted={determineCompletedSteps(
          preBindSteps,
          stepperState.isCompleted,
          subjectivities
        )}
        showCaret
        disableStep={isUploadBindOrderPendingCompletion}
      />

      {preBindSteps.map(({ subjectivityTitle, subjectivityKey }) => (
        <SubLevelStep
          stepperState={stepperState}
          subjectivityKey={subjectivityKey}
          hasStepBeenCompleted={Boolean(
            subjectivities[subjectivityKey]?.completed
          )}
          onClick={handleJumpToStep(subjectivityKey)}
          key={subjectivityKey}
          disableStep={isUploadBindOrderPendingCompletion}
        >
          {subjectivityTitle}
        </SubLevelStep>
      ))}

      <Divider />

      <StepWithDescription
        stepperState={stepperState}
        stepName={bindSteps.BIND_QUOTE}
        handleJumpToStep={handleJumpToStep}
        disableStep={
          !!incompletePreBindSteps.length || isUploadBindOrderPendingCompletion
        }
        hasStepBeenCompleted={
          quoteProgress.quoteAgencyStatus === SIGNED_STATUS ||
          quoteProgress.quoteAgencyStatus === BOUND_STATUS
        }
        description="Cannot bind quote until prerequisites met"
      >
        {quoteDetails.uiData?.productType === ProductTypes.p100
          ? 'Request to'
          : ''}{' '}
        Bind Quote
      </StepWithDescription>
      <Divider />

      <TopLevelStep
        show={!!preIssueSteps.length}
        subSteps={preIssueKeys}
        stepName="Pre-Issuance Subjectivities"
        stepperState={stepperState}
        hasStepBeenCompleted={determineCompletedSteps(
          preIssueSteps,
          stepperState.isCompleted,
          subjectivities
        )}
        onClick={handleJumpToStep(preIssueSteps[0]?.subjectivityKey)}
        disableStep={
          (quoteDetails.uiData?.productType === ProductTypes.p100 &&
            quoteProgress.quoteAgencyStatus !== BOUND_STATUS) ||
          isUploadBindOrderPendingCompletion
        }
        showCaret
      />

      {preIssueSteps.map(({ subjectivityTitle, subjectivityKey }) => (
        <SubLevelStep
          stepperState={stepperState}
          subjectivityKey={subjectivityKey}
          hasStepBeenCompleted={Boolean(
            subjectivities[subjectivityKey]?.completed
          )}
          onClick={handleJumpToStep(subjectivityKey)}
          key={subjectivityKey}
          disableStep={
            (quoteDetails.uiData?.productType === ProductTypes.p100 &&
              quoteProgress.quoteAgencyStatus !== BOUND_STATUS) ||
            isUploadBindOrderPendingCompletion
          }
        >
          {subjectivityTitle}
        </SubLevelStep>
      ))}

      <Showable show={!!preIssueSteps.length}>
        <Divider />
      </Showable>

      <StepWithDescription
        stepperState={stepperState}
        stepName={bindSteps.ISSUE_POLICY}
        handleJumpToStep={handleJumpToStep}
        hasStepBeenCompleted={quoteProgress.quoteAgencyStatus === SIGNED_STATUS}
        disableStep={
          !!incompletePreIssueSteps.length ||
          quoteProgress.quoteAgencyStatus !== BOUND_STATUS ||
          isUploadBindOrderPendingCompletion
        }
        description="Cannot issue policy until prerequisites met"
      >
        Issue Policy
      </StepWithDescription>
      <Divider />
    </Box>
  );
};

export default BindQuoteStepper;

const determineCompletedSteps = (
  steps,
  determineIsCompleted,
  subjectivities
) => {
  const subjectivityStepsIncomplete = steps.filter((step) => {
    return (
      !determineIsCompleted(step) &&
      !subjectivities[step.subjectivityKey]?.completed
    );
  });
  return Boolean(!subjectivityStepsIncomplete.length);
};

const useStyles = makeStyles(({ palette }) => ({
  stepperContainer: {
    width: '25rem',
    minWidth: '25rem',
    height: 'fit-content',
    marginLeft: 'auto',
    padding: '1rem',
    borderRadius: 8,
    background: palette.background.modal,
    maxHeight: 'calc(100vh - 16rem)',
    overflow: 'auto',
  },
}));
