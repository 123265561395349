import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import Moment from 'moment';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, DialogTitle, Divider, Grid, Typography } from '@mui/material';

import CbButton from '../../../../components/Buttons/CbButton';
import { Flexbox } from '../../../../components/layout/Flexbox';

import {
  GridRowField,
  ControlledCurrencyField,
  ControlledTextField,
  DialogFooter,
  FormGridContainer,
  DialogContent,
} from '../helpers';

import { UsStatesObjects } from '../../../../utils/USState';

const oneYearDuration = Moment.duration(1, 'year');

const fieldNames = [
  'policyEffectiveDate',
  'policyEndDate',
  'aggregateLimit',
  'deductible',
  'policyPremium',
  'tria',
  'underwritingFee',
  'surplusLinesTax',
  'surplusStampingFee',
  'kentuckyTaxCode',
  'kentuckyTax',
  'totalPremium',
];

const PolicyPremiumDetailsForm = ({
  stepperData,
  onFormSubmit,
  formsData,
  accountState,
  onGoBack,
  ...props
}) => {
  const pickedFormFields = _.pick(formsData, fieldNames);
  const formSchema = schemaCreator({ accountState });

  const formMethods = useForm({
    defaultValues: pickedFormFields,
    resolver: yupResolver(formSchema),
  });

  function handleCancel() {
    props.onClose();
  }

  function handleGoBack() {
    onGoBack(formMethods.getValues());
    stepperData.goBack();
  }

  useDateFieldSync(
    formMethods,
    'policyEndDate',
    'policyEffectiveDate',
    oneYearDuration
  );

  useTotalPremiumCalculation(formMethods);

  const [policyEffectiveDate, policyEndDate] = formMethods.watch([
    'policyEffectiveDate',
    'policyEndDate',
  ]);

  return (
    <>
      <DialogTitle>
        <Box>Generate PrimeCloud Policy</Box>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <form
            id="policy-premium-details"
            onSubmit={formMethods.handleSubmit(onFormSubmit)}
          >
            <FormGridContainer container>
              <Typography component="h3">Policy Details</Typography>

              <Grid item container spacing={4}>
                <Grid item xs={6}>
                  <ControlledTextField
                    name="policyEffectiveDate"
                    label="Policy Effective Date"
                    required
                    inputProps={{
                      type: 'date',
                      max: policyEndDate,
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <ControlledTextField
                    name="policyEndDate"
                    label="Policy Expiration Date"
                    required
                    inputProps={{
                      type: 'date',
                      min: policyEffectiveDate,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={4}>
                <Grid item xs={6}>
                  <ControlledCurrencyField
                    name="aggregateLimit"
                    label="Aggregate Limit"
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <ControlledCurrencyField
                    name="deductible"
                    label="Deductible"
                    required
                  />
                </Grid>
              </Grid>
            </FormGridContainer>

            <Divider style={{ margin: '1.2rem 0' }} variant="middle" />

            <FormGridContainer container spacing={2}>
              <Grid item container>
                <Flexbox justifyContent="center">
                  <Typography component="h3">
                    Premium, Taxes, and Fees
                  </Typography>
                </Flexbox>
              </Grid>

              <GridRowField
                name="policyPremium"
                label="Policy Premium (Includes TRIA)"
                required
                TextFieldComponent={ControlledCurrencyField}
              />

              <GridRowField
                name="tria"
                label="TRIA"
                required
                TextFieldComponent={ControlledCurrencyField}
              />

              <GridRowField
                name="underwritingFee"
                label="Underwriting Fee"
                required
                TextFieldComponent={ControlledCurrencyField}
              />

              <GridRowField
                name="surplusLinesTax"
                label="Surplus Lines Tax"
                required
                TextFieldComponent={ControlledCurrencyField}
              />

              <GridRowField
                name="surplusStampingFee"
                label="Surplus Stamping Fee"
                required
                TextFieldComponent={ControlledCurrencyField}
              />

              {accountState === UsStatesObjects.KY.value && (
                <>
                  <GridRowField
                    name="kentuckyTaxCode"
                    label="Kentucky Local Taxes Code"
                    required
                    TextFieldComponent={ControlledTextField}
                  />
                  <GridRowField
                    name="kentuckyTax"
                    label="Kentucky Local Tax"
                    required
                    TextFieldComponent={ControlledCurrencyField}
                  />
                </>
              )}

              <GridRowField
                name="totalPremium"
                label="Total"
                TextFieldComponent={ControlledCurrencyField}
                textFieldProps={{ disabled: true }}
                labelProps={{
                  style: { fontWeight: 600, marginRight: '0.38rem' },
                }}
              />
            </FormGridContainer>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogFooter
        stepNumber={stepperData.stepNumber}
        totalSteps={stepperData.steps.length}
        stepLabel="Policy Premium Details"
      >
        <CbButton variant="text" size="x-small" onClick={handleGoBack}>
          Back
        </CbButton>
        <CbButton onClick={handleCancel} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton
          type="submit"
          form="policy-premium-details"
          styleName="ctaButton"
          disabled={formMethods.formState.isSubmitting}
        >
          Generate Policy
        </CbButton>
      </DialogFooter>
    </>
  );
};

export default PolicyPremiumDetailsForm;

function schemaCreator({ accountState }) {
  const kentuckyFields = {
    kentuckyTaxCode: Yup.string().required().label('Kentucky Tax Code'),
    kentuckyTax: Yup.string().min(0).required().label('Kentucky Tax'),
  };

  return Yup.object().shape({
    policyEffectiveDate: Yup.string().required().label('Policy Effective Date'),
    policyEndDate: Yup.string().required().label('Policy Expiration Date'),
    aggregateLimit: Yup.number()
      .typeError(numberStringTypeError)
      .min(0)
      .max(5000000)
      .required()
      .label('Aggregate Limit'),
    deductible: Yup.number()
      .typeError(numberStringTypeError)
      .min(0)
      .required()
      .label('Deductible'),
    policyPremium: Yup.number()
      .typeError(numberStringTypeError)
      .min(0)
      .required()
      .label('Policy Premium'),
    tria: Yup.number()
      .typeError(numberStringTypeError)
      .min(0)
      .required()
      .label('TRIA'),
    underwritingFee: Yup.number()
      .typeError(numberStringTypeError)
      .min(0)
      .required()
      .label('Underwriting Fee'),
    surplusLinesTax: Yup.number()
      .typeError(numberStringTypeError)
      .min(0)
      .required()
      .label('Surplus Lines Tax'),
    surplusStampingFee: Yup.number()
      .typeError(numberStringTypeError)
      .min(0)
      .required()
      .label('Surplus Stamping Fee'),
    ...(accountState === UsStatesObjects.KY.value ? kentuckyFields : undefined),
  });
}

function numberStringTypeError({ label }) {
  return `${label} is a required field`;
}

function useDateFieldSync(
  formMethods,
  fieldNameToUpdate,
  fieldNameToBuildFrom,
  duration
) {
  formMethods.watch(fieldNameToBuildFrom);
  const fieldValueToBuildFrom = formMethods.getValues()[fieldNameToBuildFrom];

  React.useEffect(() => {
    if (fieldValueToBuildFrom) {
      formMethods.setValue(
        fieldNameToUpdate,
        Moment(fieldValueToBuildFrom).add(duration).format('YYYY-MM-DD')
      );

      // trigger validation due to value update
      formMethods.trigger(fieldNameToUpdate);
    }
  }, [duration, fieldNameToUpdate, fieldValueToBuildFrom, formMethods]);
}

function useTotalPremiumCalculation(formMethods) {
  formMethods.watch([
    'policyPremium',
    'underwritingFee',
    'surplusLinesTax',
    'surplusStampingFee',
    'kentuckyTax',
  ]);

  const {
    policyPremium,
    underwritingFee,
    surplusLinesTax,
    surplusStampingFee,
    kentuckyTax,
  } = formMethods.getValues();

  const totalPremium = React.useMemo(
    () =>
      [
        policyPremium,
        underwritingFee,
        surplusLinesTax,
        surplusStampingFee,
        kentuckyTax,
      ].reduce((sum, currFieldVal) => {
        const currFieldValAsNum = Number(currFieldVal || 0);
        return sum + (_.isFinite(currFieldValAsNum) ? currFieldValAsNum : 0);
      }, 0),
    [
      kentuckyTax,
      policyPremium,
      surplusLinesTax,
      surplusStampingFee,
      underwritingFee,
    ]
  );

  React.useEffect(() => {
    formMethods.setValue('totalPremium', totalPremium);
  }, [formMethods, totalPremium]);
}
