import { useQuery } from '@tanstack/react-query';
import { useQuoteCreationFunctions } from '../../../../../accounts/accountUtils';
import { getQuoteCartList } from '../../../../../api';
import type { AccountDto, ProductType } from '../../../../../types';
import type { GetQuoteCartListResponse } from '../../../../../api/quotes.api.types';

interface GetQuoteCartListParams {
  accountData?: AccountDto;
  accountId: string;
  productType: ProductType;
  enabled?: boolean;
}

export const useGetQuoteCartList = ({
  accountData,
  accountId,
  productType,
  enabled = true,
}: GetQuoteCartListParams) => {
  const { getAgencyId } = useQuoteCreationFunctions(accountData);

  return useQuery<GetQuoteCartListResponse, Error>(
    ['request-quote-cart', accountId, productType],
    async () => {
      return getQuoteCartList(productType, accountId, {
        params: getAgencyId() ? { agencyId: getAgencyId() } : {},
      }).then(({ data }) => {
        return {
          ...data,
          count: data?.quoteList?.length || 0,
        };
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(accountId) && Boolean(productType) && enabled,
    }
  );
};
