import React from 'react';

import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { PubSub } from '../../utils/eventUtils';
import { useFileTypeIcons } from './useFileTypeIcons';

export const FileUploadDragDrop = ({
  editing,
  fileDetails,
  handleClearFile,
}) => {
  const classes = useStyles();
  const renderFileIcon = useFileTypeIcons;

  const uploadDocumentHandler = React.useCallback((event) => {
    PubSub.publish('openFileUploadFeature', event);
  }, []);

  return (
    <>
      {fileDetails ? (
        <div className={classes.dragDropContainer}>
          <div className={classes.fileTypeIcon}>
            {renderFileIcon(fileDetails.extension)}
          </div>
          <p className={classes.dragDropText}>{fileDetails.fileName}</p>
          {!editing && (
            <CloseIcon
              className={classes.closeIcon}
              onClick={handleClearFile}
            />
          )}
        </div>
      ) : (
        <div
          className={classes.dragDropContainer}
          onClick={uploadDocumentHandler}
          aria-hidden
        >
          <img
            alt=""
            src="images/cloud-upload.svg"
            className={classes.cloudIcon}
          />
          <p className={classes.dragDropText}>
            Drag and Drop a file
            <br /> here or Click to Upload
          </p>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  dragDropContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    height: '5.833rem',
    width: '25rem',
    borderRadius: '5px',
    border: `1px solid ${palette.primary.main}`,
    cursor: 'pointer',
  },
  dragDropText: {
    marginLeft: '2.3rem',
    maxWidth: '12rem',
    fontSize: '1rem',
    lineHeight: 1.33,
    color: palette.primary.main,
  },
  cloudIcon: {
    marginLeft: '2rem',
    width: '3rem',
    height: '3rem',
    margin: '0 0 0 1rem',
  },
  fileTypeIcon: {
    marginLeft: '2rem',
  },
  closeIcon: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(-2rem, 2rem)',
    fill: palette.error.main,
    border: `1.5px solid ${palette.error.main}`,
    borderRadius: '50%',
  },
}));
