import { ActionType } from '../../../components/modals/v2/connectors/helpers';

export const GoogleWorkSpaceSchema = {
  name: 'Test Connector',
  steps: {
    0: {
      name: 'Step 1',
      elements: [
        {
          type: 'description',
          description:
            'To connect to Google Workspace, you will need a Google Workspace admin user and a Google Cloud Service Account.',
          column: 0,
        },
        {
          type: 'description',
          description:
            'Once you have created the Google Cloud Service Account, you can finish adding the Google connector in the Cowbell Platform.',
          column: 1,
        },
      ],
      layout: {
        columns: 3,
        containerProps: { spacing: 2, direction: 'row' },
        columnProps: {
          0: { xs: 12, md: 12 },
          1: { xs: 12, md: 12 },
        },
        elementContainerProps: {
          0: {},
          1: {},
        },
      },
      action: {
        type: ActionType.GO_FORWARD,
      },
    },
    1: {
      name: 'Step 2',
      elements: [
        {
          name: 'accountEmailText',
          type: 'description',
          description:
            'To finish adding the connector, Cowbell will use your Google Workspace admin email address.',
          column: 0,
        },
        {
          name: 'accountEmail',
          label: 'Google Workspace Admin Email Address',
          description: 'Please enter your Google Workspace admin email',
          type: 'text',
          required: true,
          column: 1,
        },
        {
          name: 'apiKeyText',
          description: 'Upload Service Account API Key',
          type: 'description',
          column: 2,
          elementProps: { type: 'body1', align: 'center' },
        },
        {
          name: 'credentials',
          label: 'Upload Service Center API Key',
          description:
            'The API key is a JSON file that you downloaded from Google Workspace',
          type: 'upload',
          elementProps: { accept: ['application/json'] },
          required: true,
          column: 3,
        },
      ],
      layout: {
        columns: 4,
        containerProps: { spacing: 2, direction: 'row' },
        columnProps: {
          0: { xs: 12, md: 12 },
          1: { xs: 12, md: 12 },
          2: { xs: 12, md: 12 },
          3: { xs: 12, md: 12 },
        },
        elementContainerProps: { 0: {}, 1: {}, 2: {}, 3: {} },
      },
      action: {
        type: ActionType.API_ENDPOINT,
        requestConfig: {
          url: '/api/connector/v1/googleworkspace/add',
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      },
    },
    2: {
      name: 'Step 3',
      elements: [
        {
          type: 'description',
          description:
            'The Google Workspace API key has been successfully uploaded.',
          column: 0,
        },
        {
          type: 'description',
          description:
            'A Google connector has been added for your organization.',
          column: 1,
        },
      ],
      layout: {
        columns: 2,
        containerProps: { spacing: 2, direction: 'row' },
        columnProps: {
          0: { xs: 12, md: 12 },
          1: {
            xs: 12,
            md: 12,
          },
        },
        elementContainerProps: { 0: {}, 1: {} },
      },
      action: {
        type: ActionType.CLOSE_MODAL,
      },
    },
  },
};
