import { ServiceHandlerFactory } from '../utils/serviceUtils';
import { fetchStateParamsMapping } from './stateServiceMappings';

export const fetchStates = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/state/search',
  },
  paramSchema: fetchStateParamsMapping,
});
export const fetchRates = ServiceHandlerFactory({
  axios: {
    url: 'api/rater/v1/all-raters',
  },
});

export const fetchTimeZones = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/state/timezone',
  },
});

export const createState = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/state',
    method: 'post',
  },
});

export const updateState = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/state',
    method: 'put',
  },
});

export const deleteState = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/state/:id',
    method: 'delete',
  },
});
