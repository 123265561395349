import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import type { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { fetchAccountRiskDetails } from '../../../../accounts/AccountService';
import { useQueryParams } from '../../../../components/providers/QueryParamsProvider';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import { AGENCY_DASHBOARD_VIEWS } from '../agency-dashboard.statics';
import type { PrimeXProductType } from '../../../../types';
import { PrimeXProductTypes } from '../../../../types';
import { useGetAccountDetailsQuery } from '../../../../queries/useGetAccountDetailsQuery';

export const AccountRiskLoader = () => {
  const { query } = useQueryParams();
  const {
    data: account,
    isLoading,
    isError,
    error,
  } = useGetAccountDetailsQuery({ accountId: query.accountId });

  const { handleViewChange } = useAgencyDashboardState();
  const history = useHistory();
  const [progress, setProgress] = React.useState(0);
  const isPrimeXProduct = Object.values(PrimeXProductTypes).includes(
    account?.productType as PrimeXProductType
  );

  React.useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((current) => {
        if (current >= 100) {
          handleViewChange(AGENCY_DASHBOARD_VIEWS.ACCOUNT_RISK_ERROR);
        }
        return current + Math.floor(Math.random() * 4);
      });
    }, 500);

    const fetchInterval = setInterval(() => {
      if (!isLoading) {
        fetchAccountRiskDetails(query.accountId).then((resp: AxiosResponse) => {
          if (resp.data.status !== 'Not Found') {
            setProgress(99);

            setTimeout(() => {
              if (isPrimeXProduct) {
                history.push(
                  `/agency/quotes/${account?.productType}/${account?.id}/requestQuote/verify`
                );
              } else {
                history.push(
                  `/agency/requestQuote/${account?.productType}/${account?.id}/details`
                );
              }
            }, 500);
          }
        });
      }
    }, 3000);

    return () => {
      clearInterval(progressInterval);
      clearInterval(fetchInterval);
    };
  }, [isLoading]);

  if (isError && (error as AxiosError).response?.status === 409) {
    handleViewChange(AGENCY_DASHBOARD_VIEWS.ACCOUNT_ALREADY_SIGNED);
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="90%"
      width="100%"
      flexDirection="column"
    >
      <Box width="80%" mb="1rem">
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Typography variant="body1">
        Loading your client's risk profile...
      </Typography>
    </Box>
  );
};
