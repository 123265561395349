import Moment from 'moment';

import {
  createUiProps,
  responseStringTransformer,
  ServiceHandler,
} from '../utils';

import {
  serializeElasticPageableResponse,
  serializeElasticPageableTokenResponse,
} from './serializers/elastic.serializers';
import { FORMAT_DATE_AND_TIME, toUniversalUtcDate } from '../utils/date.utils';

/* common */
export const getNote = ServiceHandler({
  url: '/api/quote/v1/note/:noteId',
  transformResponse: [
    responseStringTransformer,
    (data) => ({ data }),
    createUiProps(
      ['created', 'modified'],
      (date) =>
        date && toUniversalUtcDate(date, { format: FORMAT_DATE_AND_TIME })
    ),
  ],
});

export const deleteNote = ServiceHandler({
  url: '/api/quote/v1/note/:noteId',
  method: 'delete',
});

export const getAllNoteTypes = ServiceHandler({
  url: '/api/quote/v1/note/note-types',
});

/* accounts */
export const getAccountNotes = ServiceHandler({
  url: 'api/quote/v1/note/account/:accountId',
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableResponse,
    createUiProps(['created', 'lastUpdated'], (created) =>
      toUniversalUtcDate(created)
    ),
  ],
});

/* quotes */
export const getQuoteNotes = ServiceHandler({
  url: '/api/quote/v1/note/quote/:quoteId',
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableResponse,
    createUiProps(['created', 'lastUpdated'], (created) =>
      toUniversalUtcDate(created)
    ),
  ],
});

/* sales inbox */
export const getLeadNote = ServiceHandler({
  url: 'api/sales/v1/note/:noteId',
  transformResponse: [
    responseStringTransformer,
    (data) => ({ data }),
    createUiProps(
      ['created', 'lastUpdated'],
      (date) =>
        date && toUniversalUtcDate(date, { format: FORMAT_DATE_AND_TIME })
    ),
  ],
});

export const getLeadNotes = ServiceHandler({
  url: '/api/sales/v1/note/lead/:leadId',
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableTokenResponse,
    createUiProps(['created', 'lastUpdated'], (created) =>
      toUniversalUtcDate(created)
    ),
  ],
});

export const deleteLeadNote = ServiceHandler({
  url: 'api/sales/v1/note/:noteId',
  method: 'delete',
});

/* claims */
export const createClaimsNote = ServiceHandler({
  url: '/api/claimsmgmt/v1/note',
  method: 'post',
});

export const getClaimNotes = ServiceHandler({
  url: '/api/claimsmgmt/v1/note/claim/:claimId',
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableTokenResponse,
    createUiProps(['created', 'lastUpdated'], (created) =>
      toUniversalUtcDate(created)
    ),
  ],
});

export const getClaimNote = ServiceHandler({
  url: '/api/claimsmgmt/v1/note/:noteId',
  transformResponse: [
    responseStringTransformer,
    (data) => ({ data }),
    createUiProps(
      ['created', 'modified'],
      (date) =>
        date && toUniversalUtcDate(date, { format: FORMAT_DATE_AND_TIME })
    ),
  ],
});

export const updateClaimNote = ServiceHandler({
  url: 'api/claimsmgmt/v1/note/:noteId',
  method: 'put',
});

export const deleteClaimNote = ServiceHandler({
  url: 'api/claimsmgmt/v1/note/:noteId',
  method: 'delete',
});

export const getClaimNoteTypes = ServiceHandler({
  url: 'api/claimsmgmt/v1/note/note-types',
});

/* Claims Diary */
export const createDiaryEntry = ServiceHandler({
  url: '/api/claimsmgmt/v1/diary',
  method: 'post',
});

export const getDiaryEntry = ServiceHandler({
  url: '/api/claimsmgmt/v1/diary/:noteId',
  transformResponse: [
    responseStringTransformer,
    (data) => ({ data }),
    createUiProps(
      ['created', 'modified'],
      (date) =>
        date && toUniversalUtcDate(date, { format: FORMAT_DATE_AND_TIME })
    ),
  ],
});

export const updateDiaryEntry = ServiceHandler({
  url: '/api/claimsmgmt/v1/diary/:noteId',
  method: 'put',
});

export const deleteDiaryEntry = ServiceHandler({
  url: '/api/claimsmgmt/v1/diary/:id',
  method: 'delete',
});

export const getDiaryEntries = ServiceHandler({
  url: 'api/claimsmgmt/v1/diary',
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableResponse,
    createUiProps(['created', 'lastUpdated'], (created) =>
      toUniversalUtcDate(created)
    ),
  ],
});

export const getDiaryEntriesByAccount = ServiceHandler({
  url: 'api/claimsmgmt/v1/diary/account/:accountId',
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableResponse,
    createUiProps(['created', 'lastUpdated'], (created) =>
      toUniversalUtcDate(created)
    ),
  ],
});

export const getAllDiaryEntryTypes = ServiceHandler({
  url: '/api/claimsmgmt/v1/diary/note-types',
});
