import { useSelector } from 'react-redux';
import type { Permissions } from '../auth/permissions';
import type { RootStore } from '../../types';

const defaultConfig = { requireAll: false };

/**
 *
 * @param {*} scopes string or array indicating the required scopes
 * @param {*} config object - requireAll:true requires all scopes in list provided
 * @returns boolean indicating whether the required scopes were found in user's permissions
 */
export const useAllow = (
  scopes: Permissions[] | Permissions = [],
  config: typeof defaultConfig = defaultConfig
) => {
  const requiredScopes = typeof scopes === 'string' ? [scopes] : scopes;

  const scopesListing = useSelector((store: RootStore) => store.scopes);

  if (config.requireAll) {
    return requiredScopes.every((scope) => scopesListing.includes(scope));
  }

  return requiredScopes.some((scope) => scopesListing.includes(scope));
};
