//core
import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

//mui
import { Box, useTheme } from '@mui/material';

//table components
import { useSnackbar } from 'notistack';
import type { AxiosError } from 'axios';
import { PrimaryToolbar } from '../../../../../components/tables/dev-express/table-components/toolbar/PrimaryToolbar';
import { TableFilterSelectV2 } from '../../../../../components/tables/tableFilters/v2/TableFilterSelectV2';
import { TableSearch } from '../../../../../components/tables/dev-express/table-components/toolbar/TableSearch';
import { SecondaryToolbar } from '../../../../../components/tables/dev-express/table-components/toolbar/SecondaryToolbar';
import { CsvDownloadButton } from '../../../../../components/tables/dev-express/table-components/toolbar/CsvDownloadButton';
import { RefreshButton } from '../../../../../components/tables/dev-express/table-components/toolbar/RefreshButton';
import { ColumnChooser } from '../../../../../components/tables/dev-express/table-components/toolbar/ColumnChooser';
import { RemotePagingPlugin } from '../../../../../components/tables/dev-express/plugins/RemotePagingPlugin';
import { TableStateProvider } from '../../../../../components/tables/dev-express/TableStateContext';
import { BaseTable } from '../../../../../components/tables/dev-express/BaseTable';

//other components
import { Allow } from '../../../../../components/auth/Allow';
import CbButton from '../../../../../components/Buttons/CbButton';
import { useQueryParams } from '../../../../../components/providers/QueryParamsProvider';
import Breadcrumbs from '../../../../../components/breadcrumbs/Breadcrumbs';

//hooks
import { useSavedSettings } from '../../../../../components/tables/dev-express/hooks/useSavedSettings';
import { useTableState } from '../../../../../components/tables/dev-express/hooks/useTableState';
import { useQueryHandlers } from '../../../../../components/tables/dev-express/hooks/useQueryHandlers';

import { Modal } from '../../../../../components/modals/v2/helpers/v2.modal.helpers';
import type {
  AddEditAgencyLevelPayload,
  AgencyLevelListingTableRow,
} from '../../../../../api';
import { addAgencyLevel, getAgencyLevelListing } from '../../../../../api';
import { manageAPIError } from '../../../../../utils';
import type { ColumnConfig } from '../../../../../components/tables/dev-express/types/table.types';
import { prepColumnFilters } from '../../../../../utils/data.utils';
import { FilterModal } from '../../../../../components/modals/FilterModal';
import { RevenueRangeCell } from './components/RevenueRangeCell';
import AgencyLevelMenuOptions from './components/AgencyLevelMenuOptions';
import { useBackfill } from '../../../../../components/tables/dev-express/hooks/useBackfill';
import { useCowbellTranslations } from '../../../../../i18n/translations';

export const agencyLevelTableId = 'AGENCY_LEVELS' as const;
const backfill = {
  size: 25,
};

const AgencyLevelsListing = () => {
  const { query } = useQueryParams();
  const columnConfig = useColumnConfig();

  const { uiSettings } = useSavedSettings(agencyLevelTableId, columnConfig);
  useBackfill(backfill, uiSettings);
  const { state, columns, handlers } = useTableState(columnConfig, {
    uiSettings,
  });
  const { queryHandlers } = useQueryHandlers(query);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t, translationKeys } = useCowbellTranslations();

  const theme = useTheme();
  const context = { theme };

  const filterColumns = React.useMemo(() => {
    return prepColumnFilters(Object.values(columnConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: agencyLevelsData = {} as AgencyLevelListingData, isFetching } =
    useQuery(
      [agencyLevelTableId, query],
      () => {
        return getAgencyLevelListing({ params: query }).then(
          ({ data: responseData }) => {
            return {
              totalCount: responseData.totalElements,
              rows: responseData.content,
            };
          }
        );
      },
      {
        onError: (error: AxiosError) => {
          enqueueSnackbar(
            manageAPIError(
              error,
              'There was a problem loading the available levels. Please try again.'
            ),
            { variant: 'error' }
          );
        },
      }
    );

  const onRefetch = () => {
    queryClient.invalidateQueries([agencyLevelTableId]);
  };

  const mergedState = React.useMemo(
    () => ({ ...state, ...query }),
    [query, state]
  );
  const mergedHandlers = React.useMemo(
    () => ({ ...handlers, ...queryHandlers }),
    [handlers, queryHandlers]
  );

  const handleAddAgencyLevel = () => {
    const handleAsyncSubmit = (payload: AddEditAgencyLevelPayload) => {
      return addAgencyLevel({ data: payload });
    };

    Modal.show('AddEditAgencyLevelModal', {
      data: {
        onSubmit: handleAsyncSubmit,
      },
      config: { title: `Add ${t(translationKeys.agency)} Level` },
    });
  };

  return (
    <>
      <Box mb={2}>
        <Breadcrumbs title={`${t(translationKeys.agency)} Levels`}>
          <p>Manage</p>
          <p>{t(translationKeys.agency)} Levels</p>
        </Breadcrumbs>
      </Box>

      <PrimaryToolbar style={{ borderTopLeftRadius: 0 }}>
        <TableFilterSelectV2 tableId={agencyLevelTableId} />
        <Box display="flex" width="60%">
          <TableSearch />
        </Box>
      </PrimaryToolbar>

      <TableStateProvider
        state={mergedState}
        handlers={mergedHandlers}
        columns={columns}
        rows={agencyLevelsData?.rows ?? []}
        tableId={agencyLevelTableId}
        loading={isFetching}
        context={context}
      >
        <SecondaryToolbar justifyContent="end">
          <ColumnChooser />
          <CsvDownloadButton />
          <RefreshButton onClick={onRefetch} loading={isFetching} />
          <Allow allow={['account:cowbell', 'role:admin']} requireAll>
            <CbButton
              styleName="ctaButton"
              onClick={handleAddAgencyLevel}
              style={{ marginLeft: '1rem' }}
            >
              Add {t(translationKeys.agency)} Level
            </CbButton>
          </Allow>
        </SecondaryToolbar>

        <BaseTable sticky>
          <RemotePagingPlugin totalCount={agencyLevelsData?.totalCount ?? 0} />
        </BaseTable>
      </TableStateProvider>
      <FilterModal tableId={agencyLevelTableId} config={filterColumns} />
    </>
  );
};

export default AgencyLevelsListing;

export const useColumnConfig = (): ColumnConfig<AgencyLevelListingTableRow> => {
  const { t, translationKeys } = useCowbellTranslations();
  const REVENUE = t(translationKeys.revenue);
  return {
    levelName: {
      name: 'levelName',
      title: 'Level Name',
      filterType: 'string',
      width: 250,
      getCellValue: (row) => row.levelName?.split('_').join(' '),
    },

    revenueRangeOne: {
      name: 'revenueRangeOne',
      title: `${REVENUE} Range 1`,
      width: 300,
      getCellValue: (row) => (
        <RevenueRangeCell revenueBand={row?.ui_revenueBands[0]} />
      ),
    },

    revenueRangeTwo: {
      name: 'revenueRangeTwo',
      title: `${REVENUE} Range 2`,
      width: 300,
      getCellValue: (row) => (
        <RevenueRangeCell revenueBand={row?.ui_revenueBands[1]} />
      ),
    },

    revenueRangeThree: {
      name: 'revenueRangeThree',
      title: `${REVENUE} Range 3`,
      width: 300,
      getCellValue: (row) => (
        <RevenueRangeCell revenueBand={row?.ui_revenueBands[2]} />
      ),
    },

    revenueRangeFour: {
      name: 'revenueRangeFour',
      title: `${REVENUE} Range 4`,
      width: 300,
      getCellValue: (row) => (
        <RevenueRangeCell revenueBand={row?.ui_revenueBands[3]} />
      ),
    },

    revenueRangeFive: {
      name: 'revenueRangeFive',
      title: `${REVENUE} Range 5`,
      show: false,
      width: 300,
      getCellValue: (row) => (
        <RevenueRangeCell revenueBand={row?.ui_revenueBands[4]} />
      ),
    },

    revenueRangeSix: {
      name: 'revenueRangeSix',
      title: `${REVENUE} Range 6`,
      show: false,
      width: 300,
      getCellValue: (row) => (
        <RevenueRangeCell revenueBand={row?.ui_revenueBands[5]} />
      ),
    },

    options: {
      name: 'options',
      canToggle: false,
      sort: false,
      width: 'xs',
      fixed: 'right',
      getCellValue: (row) => {
        return (
          <Allow allow={['account:cowbell', 'role:admin']} requireAll>
            <AgencyLevelMenuOptions row={row} />
          </Allow>
        );
      },
    },
  };
};

interface AgencyLevelListingData {
  rows: AgencyLevelListingTableRow[];
  totalCount: number;
}
