import { ServiceHandlerFactory } from '../utils/serviceUtils';

export const getFilters = ServiceHandlerFactory({
  axios: {
    url: '/api/user/v1/filter',
  },
});

export const getFilter = ServiceHandlerFactory({
  axios: {
    url: '/api/user/v1/filter/:id',
  },
});

export const createFilter = ServiceHandlerFactory({
  axios: {
    url: '/api/user/v1/filter',
    method: 'post',
  },
});

export const getFiltersByTable = ServiceHandlerFactory({
  axios: {
    url: '/api/user/v1/filter/name/:table',
  },
});

export const postSystemFilter = ServiceHandlerFactory({
  axios: {
    url: '/api/user/v1/filter/system/:email',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  },
});
