import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import {
  DialogContent,
  DialogActions,
  Grid as GridBase,
  Dialog,
  DialogTitle,
  IconButton as IconButtonBase,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Edit as EditIcon } from '@mui/icons-material';
import {
  TextField as TextFieldBase,
  SimpleSelect,
} from '../../../../components/inputs';
import { withFormController } from '../../../../components/hocs/forms';
import CbButton from '../../../../components/Buttons/CbButton';
import { downloadDocument } from '../../../../api/DocumentsService';
import { utcForAPI } from '../../../../utils/date.utils';
import { withShowable } from '../../../_global/lib/withShowable';
import { updateClaimInvoice } from '../../../../api/claims/claim-invoices.api';
import { getClaimsPanel } from '../../../../api/claims/claims-panel.api';
import { CLAIMS_INVOICES, CLAIMS_INVOICES_AGGS } from '../../claims.constants';
import { getVendorById } from '../../../../api/vendor.api';
import { parseNumberTo2Decimals } from '../../../../utils/currency.utils';
import LanguageCurrencyFieldBase from '../../../../components/inputs/LanguageCurrencyField';

const TextField = withFormController(TextFieldBase);
const Select = withFormController(SimpleSelect);
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);
const Grid = withShowable(GridBase);
const IconButton = withShowable(IconButtonBase);

const schema = Yup.object().shape({
  vendorName: Yup.string().required().label('Vendor'),
  vendorSubCategory: Yup.string().required().label('Expert Type'),
  invoiceDate: Yup.date().required().label('Invoice Date'),
  invoiceNumber: Yup.string().required().label('Invoice Number'),
  amount: Yup.number().fromCurrency().min(0).required().label('Invoice Amount'),
});

const VerifyClaimsInvoice = ({ data = {}, ...props }) => {
  const classes = useStyles();
  const selectClasses = useSelectFieldStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { claim, invoiceData, onVerify, onReject } = data;

  const [editMode, setEditMode] = React.useState(false);
  const toggleEditMode = React.useCallback(
    () => setEditMode((prev) => !prev),
    []
  );

  // on load, fetch document by id
  const { data: docSrc } = useQuery(['docInfo', invoiceData.id], () =>
    downloadDocument({ docId: _.get(invoiceData, 'invoice.docId') })
      .then((resp) => resp.data)
      .catch(() =>
        enqueueSnackbar('Unable to locate invoice document, please try again', {
          variant: 'error',
        })
      )
  );

  // set up form state
  const defaultValues = React.useMemo(
    () => ({
      ..._.pick(invoiceData, [
        'vendorName',
        'vendorSubCategory',
        'invoiceNumber',
        'comment',
      ]),
      invoiceDate: utcForAPI(invoiceData.invoiceDate),
      amount: parseNumberTo2Decimals(invoiceData.amount),
    }),
    [invoiceData]
  );

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  // reset default form values when invoice data changes
  useFormReset(defaultValues, methods);

  // fetch claim panel experts for dropdown
  const { data: panel = {} } = useQuery(['panel-experts'], () =>
    getClaimsPanel(_.get(claim, 'id')).then((resp) => ({ ...resp.data }))
  );

  // fetch vendor by ID to get payment term data
  const { data: paymentTerm } = useQuery(['paymentTerm'], () =>
    getVendorById(_.get(invoiceData, 'vendorId')).then((resp) =>
      _.get(resp, 'data.paymentTerms')
    )
  );

  // get derived state for select fields
  const vendorName = methods.watch('vendorName');

  const vendorOptions = React.useMemo(() => {
    if (Array.isArray(panel.data)) {
      return mapStringsToOptions(
        panel.data.map((expert) => _.get(expert, 'vendorName'))
      );
    }
  }, [panel]);

  const selectedVendor = React.useMemo(() => {
    if (vendorName && Array.isArray(panel.data)) {
      const [vendorData] = panel.data.filter(
        (expert) => expert.vendorName === vendorName
      );
      return vendorData;
    }
  }, [panel, vendorName]);

  const subcategories = React.useMemo(() => {
    if (selectedVendor) {
      return mapStringsToOptions(_.get(selectedVendor, 'vendorSubCategories'));
    }
    return [];
  }, [selectedVendor]);

  // event handlers
  const handleClose = () => {
    methods.reset();
    setEditMode(false);
    props.close();
    queryClient.invalidateQueries([CLAIMS_INVOICES]);
    queryClient.invalidateQueries([CLAIMS_INVOICES_AGGS]);
  };

  const handleVerify = () => {
    onVerify();
    props.close();
  };

  const handleReject = () => {
    onReject();
    props.close();
  };

  const onCancelEdit = React.useCallback(() => {
    methods.reset();
    setEditMode(false);
  }, [methods]);

  const onSubmit = React.useCallback(
    (formData) => {
      const payload = {
        ...formData,
        vendorId: _.get(selectedVendor, 'vendorId'),
        invoiceDate: utcForAPI(formData.invoiceDate),
      };
      updateClaimInvoice(invoiceData.claimId, invoiceData.id, { data: payload })
        .then(() =>
          enqueueSnackbar('Invoice Successfully Updated !', {
            variant: 'success',
          })
        )
        .catch((error) => {
          enqueueSnackbar(
            _.get(
              error,
              'response.data.message',
              _.get(
                error,
                'response.data',
                'Something went wrong while processing the invoice.'
              )
            ),
            { variant: 'error' }
          );
        });
      toggleEditMode();
    },
    [selectedVendor, invoiceData, toggleEditMode, enqueueSnackbar]
  );

  return (
    <Dialog
      maxWidth="false"
      classes={{ paperWidthFalse: classes.paperWidthFalse }}
      {...props}
    >
      <DialogTitle>Verify Invoice</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ paddingTop: '2rem' }}>
            <Grid container spacing={6}>
              <Grid item md={6} style={{ position: 'relative' }}>
                <IconButton
                  size="small"
                  onClick={toggleEditMode}
                  className={classes.editButton}
                  show={!editMode}
                >
                  <EditIcon />
                </IconButton>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <Select
                      name="vendorName"
                      label="Vendor"
                      required
                      options={vendorOptions}
                      disabled={!editMode}
                      classes={selectClasses}
                      defaultValue={_.get(invoiceData, 'vendorName')}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Select
                      name="vendorSubCategory"
                      label="Expert Type/Subcategory"
                      required
                      options={subcategories}
                      classes={selectClasses}
                      disabled={!editMode}
                      defaultValue={_.get(invoiceData, 'vendorSubCategory')}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      name="invoiceNumber"
                      label="Vendor Invoice Number"
                      required
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      name="invoiceDate"
                      label="Invoice Date"
                      required
                      type="date"
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <LanguageCurrencyField
                      name="amount"
                      label="Invoice Amount"
                      required
                      disabled={!editMode}
                      style={{ marginTop: '0.8rem' }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      name="paymentTerm"
                      label="Payment Term"
                      value={paymentTerm}
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      name="comment"
                      label="Comment"
                      multiline
                      minRows={7}
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item md={12} show={editMode}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      style={{ paddingTop: '1rem' }}
                    >
                      <CbButton styleName="cancel" onClick={onCancelEdit}>
                        Cancel
                      </CbButton>
                      <CbButton styleName="ctaButton" type="submit">
                        Save
                      </CbButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <iframe
                  title="applicationDoc"
                  className={classes.iframe}
                  src={docSrc || ''}
                  allowFullScreen
                  webkitallowfullscreen
                  mozallowfullscreen
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CbButton styleName="error" onClick={handleReject}>
              Reject
            </CbButton>
            <CbButton styleName="cancel" onClick={handleClose}>
              Cancel
            </CbButton>
            <CbButton
              styleName="ctaButton"
              onClick={handleVerify}
              disabled={editMode}
            >
              Verify
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export const VerifyClaimsInvoiceConfig = {
  VerifyClaimsInvoice: {
    component: VerifyClaimsInvoice,
    config: {
      title: 'Verify Invoice',
      override: true,
    },
  },
};

const useStyles = makeStyles({
  paperWidthFalse: { width: '90rem' },
  iframe: {
    width: '100%',
    height: '100%',
    border: 0,
    overflow: 'scroll',
  },
  editButton: {
    position: 'absolute',
    right: '1rem',
  },
});

const useSelectFieldStyles = makeStyles(({ palette }) => ({
  root: { backgroundColor: palette.background.modal },
  disabled: { backgroundColor: palette.background.default },
}));

// helpers
const mapStringsToOptions = (strings = []) =>
  strings.map((string) => ({ label: string, value: string }));

function useFormReset(defaultValues, formMethods) {
  const { reset: resetForm } = formMethods;
  React.useEffect(() => {
    resetForm(defaultValues);
  }, [defaultValues, resetForm]);
}
