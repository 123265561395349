import React from 'react';
import _ from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';

import '../../../console/_global/lib/validations/PhoneSchemaType';

import { DialogActions, DialogContent, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { withFormController } from '../../../components/hocs/forms';

import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import CbButton from '../../../components/Buttons/CbButton';
import { PhoneField as PhoneFieldBase } from '../../../components/inputs/PhoneField';

// apis
import { updateAccountContact } from '../../../accounts/AccountService';

const schema = Yup.object().shape({
  contactFirstName: Yup.string().label('First Name').required(),
  contactLastName: Yup.string().label('Last Name').required(),
  contactEmail: Yup.string().label('Email').email().required(),
  contactPhone: Yup.number().label('Phone').fromPhone().phone().required(),
});

const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);

export const UpdatePolicyHolder = withSnackbar(
  ({ data, close, enqueueSnackbar }) => {
    const { handleSubmit, ...methods } = useForm({
      resolver: yupResolver(schema),
    });

    const { isSubmitting } = methods.formState;

    const onSubmit = (formValues) => {
      return updateAccountContact({
        accountId: data.accountId,
        agentFirstName: data.agentFirstName,
        agentLastName: data.agentLastName,
        ...formValues,
      })
        .then((response) => {
          enqueueSnackbar('Successfully updated policy holder contact');
          close();

          if (typeof data.onSuccess === 'function') {
            data.onSuccess(response);
          }
        })
        .catch((error) => {
          enqueueSnackbar('Failed to update policy holder contact', {
            variant: 'error',
          });

          if (typeof data.onError === 'function') {
            data.onSuccess(error);
          }
        });
    };

    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id="request-to-bind-form">
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item sm={6} style={{ paddingBottom: 0 }}>
                <TextField
                  name="contactFirstName"
                  label="Policy Holder First Name"
                  placeholder="Please enter policy holder first name"
                  required
                  fullWidth
                  error={_.get(methods, 'errors.contactFirstName.message')}
                  defaultValue={data.policyContactFirstName}
                />
              </Grid>
              <Grid item sm={6} style={{ paddingBottom: 0 }}>
                <TextField
                  name="contactLastName"
                  label="Policy Holder Last Name"
                  placeholder="Please enter policy holder last name"
                  required
                  fullWidth
                  error={_.get(methods, 'errors.contactLastName.message')}
                  defaultValue={data.policyContactLastName}
                />
              </Grid>
              <Grid item sm={6} style={{ paddingTop: 0 }}>
                <TextField
                  name="contactEmail"
                  label="Policy Holder Email"
                  type="email"
                  placeholder="Please enter policy holder email"
                  required
                  fullWidth
                  error={_.get(methods, 'errors.contactEmail.message')}
                  defaultValue={data.policyContactEmail}
                />
              </Grid>
              <Grid item sm={6} style={{ paddingTop: 0 }}>
                <PhoneField
                  name="contactPhone"
                  label="Policy Holder Phone"
                  required
                  fullWidth
                  placeholder="(___)___-____"
                  error={_.get(methods, 'errors.contactPhone.message')}
                  defaultValue={data.policyContactPhone}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CbButton styleName="cancel" type="submit" onClick={close}>
              Cancel
            </CbButton>
            <CbButton
              styleName="ctaButton"
              type="submit"
              disabled={isSubmitting}
            >
              Save Policy Holder
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    );
  }
);
