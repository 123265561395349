import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Button, Divider } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

// components
import { PubSub } from '../../../utils/eventUtils';
import { DraftEditor } from './DraftEditor';
import PaperClip from '../../../_assets/svg/docs/PaperClip.svg';
import { useFileTypeIcons } from '../../../components/fileUploader/useFileTypeIcons';
import { setUploadedDocs } from './notes.reducer';
import CheckBoxBase from '../../../components/inputs/Checkbox';

const styles = ({ config, palette }) => {
  return {
    detailsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.1rem 0 0.416rem 0',
      '& .MuiButton-root': {
        padding: 0,
      },
    },
    editorHeader: {
      fontStyle: 'italic',
      fontWeight: config.weights.bold,
      fontSize: config.textSizes.petite,
      color: config.colors.cowbellBlue,
      '& .MuiButton-label': {
        fontWeight: config.weights.bold,
        fontSize: config.textSizes.petite,
        textTransform: 'capitalize',
        lineHeight: 1.3,
      },
    },
    attachIcon: {
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      margin: '0.7rem',
    },
    flexbox: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '0.416rem',
    },
    divider: {
      backgroundColor: config.colors.cowbellBlue,
      marginTop: '0.1rem',
    },
    greyText: {
      color: config.colors.greyWhite,
      alignSelf: 'flex-start',
    },
    sendToUnderwriting: {
      color: palette.text.title,
    },
  };
};

const useFormLabelStyles = makeStyles(({ palette }) => ({
  label: {
    color: palette.text.text12,
  },
}));

const useCheckboxStyles = makeStyles(({ palette }) => ({
  checked: {
    color: `${palette.common.cowbellBlue} !important`,
  },
}));

const iconStyle = { width: '1rem', marginRight: '0.166rem' };

export const NotesEditor = compose(
  withStyles(styles),
  React.forwardRef
)(({ classes, saveNote, setAdd, ...props }, ref) => {
  const loggedUser = useSelector(
    ({ user }) => `${user.firstName}\t${user.lastName}`
  );
  const renderFileIcon = useFileTypeIcons;
  const attachments = useSelector(({ notes }) => notes.docs.attachments);
  const dispatch = useDispatch();
  const formLabelClasses = useFormLabelStyles();
  const checkboxClasses = useCheckboxStyles();
  const [checkSendToUnderwriting, setCheckSendToUnderwriting] = useState(false);

  const clearNote = () => {
    setAdd(true);
    props.createEmptyEditor();
  };

  const uploadDocumentHandler = React.useCallback((id, event) => {
    PubSub.publish('openFileUploadFeature', event);
  }, []);

  const handleCancel = () => {
    setAdd(false);
    dispatch(setUploadedDocs({ attachments: [] }));
  };

  const handleChangeCheckBox = (event, value) => {
    setCheckSendToUnderwriting(value);
  };

  const saveNotetHandler = React.useCallback(() => {
    const noteText = props.getEditorText();
    saveNote(checkSendToUnderwriting, noteText);
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      <div className={`${classes.detailsContainer} ${classes.editorHeader}`}>
        <span className={classes.greyText}>Created by: {loggedUser}</span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '50%',
          }}
        >
          {props.salesInbox && (
            <section className={classes.sendToUnderwriting}>
              <CheckBoxBase
                classes={checkboxClasses}
                checked={checkSendToUnderwriting}
                name="sendToUnderwriting"
                label="Send to Underwriting"
                onChange={handleChangeCheckBox}
                formLabelProps={{ classes: formLabelClasses }}
              />
            </section>
          )}
          {attachments.length > 0 &&
            attachments.map((docs, index) => {
              return (
                <section key={index}>
                  {renderFileIcon(docs.fileType, iconStyle)}
                </section>
              );
            })}
          {attachments.length < 5 && (
            <div
              aria-hidden
              className={classes.attachIcon}
              onClick={uploadDocumentHandler}
            >
              <PaperClip />
              <span>Attachments</span>
            </div>
          )}

          <Button
            disableRipple
            className={classes.editorHeader}
            onClick={saveNotetHandler}
          >
            Save
          </Button>
          <Button
            disableRipple
            className={classes.editorHeader}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
      <DraftEditor
        ref={ref}
        placeholder="Enter Notes.."
        spellcheck
        {...props}
      />
      <div className={classes.flexbox}>
        <Button
          className={classes.editorHeader}
          disableRipple
          onClick={clearNote}
        >
          Clear
        </Button>
      </div>
      <Divider className={classes.divider} />
    </>
  );
});
