import React from 'react';
import type { MouseEventHandler } from 'react';

import { Box, Collapse } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Show } from '../../../../../components/Show';
import { AgencyDashboardMenuItem as MenuItem } from '../../menu-options/AgencyDashboardMenuOptions.components';
import type { Policy } from '../../../../accounts/summary/types/policy.types';
import { ProductTypes } from '../../../../../types';
import {
  CANCELLED_STATUS,
  EXPIRED_STATUS,
  RENEWED_STATUS,
} from '../../../../_statics/policy.statics';
import { useAgencyPoliciesOptionHandlers } from '../../../../agency/logic/policies/agency-policies.logic';

interface AgencyPoliciesMenuOptions {
  policy: Policy;
}

export const AgencyPoliciesCommonMenuOptions = ({
  policy,
}: AgencyPoliciesMenuOptions) => {
  const [isDocumentsExpanded, setIsDocumentsExpanded] = React.useState(true);

  const menuOptionHandlers = useAgencyPoliciesOptionHandlers(policy);
  const menuOptions = useAgencyPolicyOptions(policy);

  const handleDocumentsClick: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    setIsDocumentsExpanded((value) => !value);
  };

  return (
    <>
      <Show when={menuOptions.documents.show}>
        <MenuItem onClick={handleDocumentsClick}>
          {menuOptions.documents.name}
          <Box ml={0.5}>
            {isDocumentsExpanded ? (
              <FontAwesomeIcon icon={light('chevron-up')} />
            ) : (
              <FontAwesomeIcon icon={light('chevron-down')} />
            )}
          </Box>
        </MenuItem>
        <Collapse
          in={isDocumentsExpanded}
          timeout="auto"
          unmountOnExit
          style={{ paddingLeft: '1rem' }}
        >
          <Show when={menuOptions.downloadApplication.show}>
            <MenuItem onClick={menuOptionHandlers.handleDownloadApplication}>
              {menuOptions.downloadApplication.name}
            </MenuItem>
          </Show>
          <Show when={menuOptions.downloadInvoice.show}>
            <MenuItem onClick={menuOptionHandlers.handleDownloadInvoice}>
              {menuOptions.downloadInvoice.name}
            </MenuItem>
          </Show>
          <Show when={menuOptions.downloadPolicyDocuments.show}>
            <MenuItem onClick={menuOptionHandlers.handleDownloadDocs}>
              {menuOptions.downloadPolicyDocuments.name}
            </MenuItem>
          </Show>
          <Show when={menuOptions.generatePolicyCertificate.show}>
            <MenuItem onClick={menuOptionHandlers.downloadEmailCertificate}>
              {menuOptions.generatePolicyCertificate.name}
            </MenuItem>
          </Show>
        </Collapse>
      </Show>
    </>
  );
};

const useAgencyPolicyOptions = (policy: Policy) => {
  const isActive = ['ACTIVE', 'IN FORCE', 'ISSUED', 'BOUND'].includes(
    policy.status
  );
  const canDownloadApplication =
    !policy.isPrimePlus &&
    policy.product !== ProductTypes.primecloud &&
    (isActive || [RENEWED_STATUS, CANCELLED_STATUS].includes(policy.status)) &&
    !policy.isEndorsement;

  const canDownloadInvoice =
    !policy.agencyBilled &&
    (isActive ||
      [EXPIRED_STATUS, CANCELLED_STATUS, RENEWED_STATUS].includes(
        policy.status
      ));

  const canDownloadPolicyDocuments = Boolean(
    policy.isDocumentAvailable &&
      (isActive || policy.status === RENEWED_STATUS) &&
      !(policy.isEndorsement && policy.product == ProductTypes.p250)
  );

  const canGeneratePolicyCertificate =
    policy.product !== ProductTypes.primecloud && isActive;

  return {
    documents: {
      name: 'Documents',
      show: canDownloadApplication || canDownloadInvoice,
    },
    downloadApplication: {
      name: 'Download Application',
      show: canDownloadApplication,
    },
    downloadInvoice: { name: 'Download Invoice', show: canDownloadInvoice },
    downloadPolicyDocuments: {
      name: 'Download Policy Documents',
      show: canDownloadPolicyDocuments,
    },
    generatePolicyCertificate: {
      name: 'Generate Policy Certificate',
      show: canGeneratePolicyCertificate,
    },
  };
};
