// lodash
import _ from 'lodash';
import { CBUpdateAgentConfig } from './CowbellUpdateAgent';
import { CowbellGenerateLossRunsConfig } from '../../../console/accounts/admin/loss-runs/CowbellGenerateLossRuns';
import { AgencyGenerateLossRunsConfig } from '../../../console/accounts/agency/loss-runs/AgencyGenerateLossRuns';
import { CarrierRiskProfileDeleteConfirmConfig } from '../../../admin/carrier/risk-profile/CarrierRiskProfileDeleteConfirm';
import { CarrierRiskProfileCreateEditConfig } from '../../../admin/carrier/risk-profile/CarrierRiskProfileCreateEdit';
import { AddEndorsementsConfig } from '../../../console/workflows/quotes/prime-250-excess/AddEndorsements';
import { AgencySelectionForQuoteCreationConfig } from '../../../inbox/admin/CowbellQuoteCreationWithAgencySelection';
import { ApplyExternalPaymentConfig } from './ApplyExternalPayment';
import { RescindBinderConfig } from '../../../inbox/binders/RescindBinder';
import { DeletePolicyConfig } from '../../../policies/DeletePolicy';
import { deleteBulkUploadErrorConfig } from '../../../accounts/Modals/deleteBulkUploadConfirmation';
import { ComposedBORConfig } from '../../../console/BOR/ComposedBOR';
import { RescindComposedBORConfig } from '../../../console/BOR/RescindComposedBOR';
import { ExpeditePolicyBorModalConfig } from '../../../console/BOR/ExpeditePolicyBor';
import { CarrierCreateEditConfig } from '../../../admin/carrier/CarrierCreateEdit';
import { CarrierDeleteConfirmConfig } from '../../../admin/carrier/CarrierDeleteConfirm';
import { ClusterCreateEditConfig } from '../../../admin/cluster/ClusterCreateEdit';
import { ClusterDeleteConfirmConfig } from '../../../admin/cluster/ClusterDeleteConfirm';
import { ArchiveQuotesConfig } from '../../../admin/modals/ArchiveQuotes';
import { MsspCreateEditConfig } from '../../../admin/mssp/MsspCreateEdit';
import { MsspDeleteConfirmConfig } from '../../../admin/mssp/MsspDeleteConfirm';
import { VendorCreateEditConfig } from '../../../admin/vendor/VendorCreateEdit';
import { VendorDeleteConfirmConfig } from '../../../admin/vendor/VendorDeleteConfirm';
import { MuteRenewalReminderConfig } from '../../../console/workflows/accounts-renewals/MuteRenewalReminder';
import { createOpportunityModalConfig } from '../../../console/admin/modals/CreateOpportunityModal';
import { DeleteSurplusLicenseConfig } from '../../../console/agencies/manage/licenses/DeleteSurplusModals';
import { AddBillingCommissionContactConfig } from '../../../console/agencies/manage/Modals/AddBillingCommissionContact';
import { DeleteBillingCommissionContactConfig } from '../../../console/agencies/manage/Modals/DeleteBillingCommissionContact';
import { UpdateBillingCommissionContactConfig } from '../../../console/agencies/manage/Modals/UpdateBillingCommissionContact';
import { AddBankPrivacyConsentConfig } from '../../../console/agencies/manage/profile/PrivacyConsent';
import { UWBindQuoteConfig } from '../../../console/agencies/quotes/250/underwriter/bind/UWBindQuote';
import { UWAdditionalNamedInsuredConfig } from '../../../console/agencies/quotes/250/underwriter/UWAdditionalNamedInsuredModal';
import { UWDeclineQuoteConfig } from '../../../console/agencies/quotes/250/underwriter/UWDeclineQuote';
import { UWDefenseCounselConfig } from '../../../console/agencies/quotes/250/underwriter/UWDefenseCounsel';
import { CompareQuotesModalConfig } from '../../../console/salesInbox/Modals/CompareQuotesModal';
import { DeclineLeadModalConfig } from '../../../console/salesInbox/Modals/declineLeadModal';
import { OpportunityStatusConfig } from '../../../console/salesInbox/Modals/OpportunityStatus';
import { PercentWinCompetitorsOpportunityConfig } from '../../../console/salesInbox/Modals/PercentWinCompetitorsOpportunity';
import { CreateReminderModalConfig } from '../../../console/salesInbox/Modals/reminderModal';
import { TargetEffDatePremiumConfig } from '../../../console/salesInbox/Modals/TargetEffDatePremium';
import { UpdateTamBdsTerritoryConfig } from '../../../console/salesInbox/Modals/updateTamBdsTerritory';
import { AddSalesInboxOverrideConfig } from '../../../console/salesInbox/Overrides/AddSalesInboxOverride';
import { AddStatesToTerritoryConfig } from '../../../console/salesInbox/Territories/AddStatesToTerritory';
import { DeleteSalesInboxTerritoryConfig } from '../../../console/salesInbox/Territories/DeleteSalesInboxTerritory';
import { DiffToolConfig } from '../../../console/_global/views/DiffTool';
import { BindQuoteConfig } from '../../../inbox/bind/BindQuote';
import { SendQuoteConfig } from '../../../inbox/send/SendQuote';
import { RenewalQuoteIssuedConfig } from '../../../policies/RenewalQuoteIssued';
import { AccountSyncLeadsConfig } from '../AccountSyncLeads';
import { AddFEINConfig } from '../AddFEIN';
import { AgentLicenseCreateEditConfig } from '../AgentLicenseCreateEdit';
import { AgentLicenseDeleteConfirmConfig } from '../AgentLicenseDeleteConfirm';
import { AWSConnectorConfig } from '../AWSConnector';
import { DeclineAccountConfirmConfig } from '../DeclineAccountConfirm';
import { DeclineQuoteConfirmConfig } from '../DeclineQuoteConfirm';
import { OptOutRenewalProcessConfig } from '../OptOutRenewalProcess';
import { OptOutRenewalProcessReasonConfig } from '../OptOutRenewalProcessReason';
import { uploadSurplusConfirm } from '../surplusUploadConfirm';
import { UploadW9Config } from '../UploadW9';
import { UploadW9NotificationConfig } from '../UploadW9Notification';
import { VerifyUploadedApplicationConfig } from '../VerifyUploadedApplication';
import { VoidPolicyConfig } from '../VoidPolicy';
import { AdditionalNamedInsuredConfig } from './AdditionalNamedInsuredModal';
import { AgencyDeleteConfirmConfig } from './AgencyDeleteConfirm';
import { ClusterAgencyRemoveConfirmConfig } from './ClusterAgencyRemoveConfirm';
import { DeleteModalConfig } from './DeleteModal';
import { FormsLibraryAddEditConfig } from './FormsLibraryAddEdit';
import { FormsLibraryConfirmDeleteConfig } from './FormsLibraryConfirmDelete';
import { FormTypeCRUDConfig } from './FormTypeCRUD';
import { ActiveLocationConfig } from './locations/activeLocation';
import { AgencyCreateEditLocationModalConfig } from './locations/AgencyEditLocation';
import { DeleteLocationModalConfig } from './locations/deleteLocations';
import { AdminCreateEditLocationModalConfig } from './locations/editLocation';
import { InactiveLocationConfig } from './locations/inactiveLocation';
import { UpdateTamBdsModalConfig } from './locations/updateTamBds';
import { MutePaymentReminderConfirmConfig } from './MutePaymentReminderConfirmV2';
import { AddPresetsConfig } from './PresetModals/AddPresetModal';
import { DeletePresetConfig } from './PresetModals/DeletePresetModals';
import { RenewalWorkflowEmailAgencyConfig } from './RenewalWorkflowEmailAgency';
import { SimpleConfirmConfig } from './SimpleConfirm';
import { ClaimsPayInvoiceConfig } from './ClaimsPayInvoice';
import { SurplusUploadModalConfig } from './surplusUploadModal';
import { TeamCreateEditModalConfig } from './TeamCreateEditModal';
import { TestModalConfig } from './TestModal';
import { UnDeclineAccountConfirmConfig } from './UndeclineAccountConfirm';
import { UpdateActivePolicyDatesConfig } from './UpdateActivePolicyDates';
import { UpdatePolicyModalConfig } from './UpdatePolicyModal';
import { UWDeleteQuoteV2Config } from './UWDeleteQuoteV2';
import { StateCreateConfig } from '../../../states/StateCreate';
import { UWInboxNotesModalV2Config } from './UWInboxNotesModalV2';
import { CreateUpdateWebhookConfig } from './Developers/Webhooks/CreateUpdateWebhook';
import { PromptWebhookStatusChangeConfig } from './Developers/Webhooks/PromptWebhookStatusChange';
import { ConfigureAuthConfig } from './Developers/Webhooks/ConfigureAuth';
import { WizerSignUpConfig } from './WizerSignUp';
import { WizerVideoPlayerConfig } from './WizerVideoPlayer';
import { AdditionalInsuredModalConfig } from './AdditionalInsuredModal';
import { StopRenewalQuoteConfig } from '../../../console/renewals/agency/StopRenewalQuote';
import { PolicyFlatCancelModalConfig } from './PolicyFlatCancelModal';
import { GenerateQuoteApplicationModalConfig } from './GenerateQuoteApplication';
import { GenerateQuoteProposalModalConfig } from './GenerateQuoteProposal';
import { GenerateBinderModalConfig } from './GenerateBinder';
import { RegeneratePolicyDocumentsModalConfig } from './RegeneratePolicyDocuments';
import { RegeneratePolicyInvoiceModalConfig } from './RegeneratePolicyInvoice';
import { P100CalculatorErrorConfig } from './P100CalculatorError';
import { PolicyMTEConfig } from '../PolicyMTEModal';
import { TestFormConfig } from './TestForm';
import { NoticeOfCancellationConfig } from './NoticeOfCancellation';
import { AddEditClaimModalConfig } from '../../../console/claims/listing/cowbell/AddEditClaimModal';
import { AddEditClaimInvoiceConfig } from '../../../console/claims/detail/invoices/AddEditClaimInvoice';
import { AddEditVendorV2Config } from '../../../admin/vendor/AddEditVendorV2';
import { AddEditClaimPanelExpertConfig } from '../../../console/claims/detail/panel/AddEditClaimPanelExpert';
import { CloseClaimModalConfig } from '../../../console/claims/listing/cowbell/CloseClaimModal';
import { NoticeOnlyModalConfig } from '../../../console/claims/listing/cowbell/NoticeOnlyModal';
import { ReopenClaimModalConfig } from '../../../console/claims/listing/cowbell/ReopenClaimModal';
import { ConfirmReserveChangesModalConfig } from '../../../console/claims/detail/financials/ConfirmReserveChangesModal';
import { VerifyClaimsInvoiceConfig } from '../../../console/claims/detail/invoices/VerifyClaimsInvoice';
import { PauseNOCConfig } from './NOC/PauseNOCModal';
import { ManuallyStartConfig } from './NOC/ManuallyStartNOCModal';
import { StopNOCConfig } from './NOC/StopNOCModal';
import { ExtendPaymentNOCConfig } from './NOC/ExtendPaymentNOCModal';
import { ApproveClaimsInvoiceConfig } from '../../../console/claims/detail/invoices/ApproveClaimsInvoice';
import { RejectClaimsInvoiceConfig } from '../../../console/claims/detail/invoices/RejectClaimsInvoice';
import { DocUploadInfoConfig } from './DocUploadInfo';
import { RiskEngQuestionsAddTemplateConfig } from '../riskEngSaveQuestionsTemplate';
import { LossRunsDownloadFailureConfig } from '../../../console/accounts/admin/loss-runs/LossRunsDownloadFailure';
import { RiskEngLoadTemplateModalConfig } from './RiskEngLoadTemplate';
import { RiskEngImportTemplateConfig } from './RiskEngImportTemplate';
import { DocumentPreviewModalConfig } from '../../fileUploader/DocumentPreviewModal';
import { MigrateAccountsOnTeamChangeConfig } from './MigrateAccountsOnTeamChange';
import { AddEditZipCodeConfig } from '../../../console/salesInbox/Territory-Details/AddEditZipCode';
import { CancelPolicyConfig } from './CancelPolicy';
import { UpdateBillingComplianceModalConfig } from '../../../console/payments/billing/billing-compliance/UpdateBillingComplianceModal';
import { AddPrimaryInsuranceConfig } from './AddPrimaryInsurance';
import { AddAttachmentDetailsConfig } from './AddAttachmentDetails';
import { AddAdditionalEndorsementsConfig } from '../AddEndorsements';
import { UploadEndorsementOrFormConfig } from './UploadEndorsementOrForm';
import { P100RenewalRequestToBindWorkflowConfig } from '../../../console/workflows/accounts-renewals/prime100/agency/bind/AgencyPrime100RenewalRequestToBindWorkflow';
import { CowbellAddAgencyModalV2Config } from '../../../agencies/CowbellAddAgencyModalV2/CowbellAddAgencyModalV2';
import { CreateEditPrimeCloudPolicyModalConfig } from '../../../accounts/Modals/CreateEditPrimePlusPolicyModal/CreateEditPrimePlusPolicyModal';
import { CowbellAddAccountModalV2Config } from '../../../accounts/admin-account-flow/CowbellAddAccountModalV2';
import { AddERPModalConfig } from '../AddERPModal';
import { ConfirmBindQuoteConfig } from '../ConfirmBindQuote';
import { MessageModalConfig } from './MessageModal';
import { EmailSignLinkConfirmationConfig } from './EmailSignLinkConfirmation';
import { UnderwritingSubjectivitiesConfig } from '../../../console/workflows/bind-quote/UW/UnderwritingSubjectivities';
import { ConfirmIssuePolicyConfig } from '../ConfirmIssuePolicy';
import { SurplusLinesNoticeConfirmationConfig } from '../SurplusLinesNoticeConfirmation';
import { BulkRenewalOptOutConfig } from './bulk-actions/policies/BulkRenewalOptOut/BulkRenewalOptOut';
import { QuoteChangeToReferredConfig } from './QuoteChangeToReferred';
import { ClearFiltersModalConfig } from '../../../console/submissions/admin/listings/helpers/ClearFiltersModal';
import { UpdateClaimClosingDateModalConfig } from './UpdateClaimClosingDate';
import { BulkRenewalOptInConfig } from './bulk-actions/policies/BulkRenewalOptIn/BulkRenewalOptIn';
import { TerritoryContactCRUDModalConfig } from '../../../console/submissions/admin/listings/helpers/TerritoryContactCRUDModal';
import { DisableEnableUserConfig } from './DisableEnableUser';
import { GenericConnectorConfig } from './GenericConnector';
import { ApplicationUploadConfig } from './ApplicationUpload';
import { AgencyUsersManageModalConfig } from '../../../users/listing/agency/modals/AgencyUsersManageModal';
import { P100ConfirmIssuePolicyConfig } from '../P100ConfirmIssuePolicy';
import { MigrateAgencyUsersModalConfig } from '../../../agencies/agency-detail-page/MigrateAgencyUsersModal/MigrateAgencyUsersModal';
import { P100NKLLUploadModalConfig } from '../P100NKLLUploadModal';
import { DocumentUploadConfig } from './DocumentUpload';
import { RenewalSubmissionLostReasonConfig } from './RenewalSubmissionLostReason';
import { RedirectToMfaConfig } from './RedirectToMfa';
import { RenewalMessageForCowbellConfig } from '../../../console/workflows/accounts-renewals/RenewalMessageForCowbell';
import { CreateSpotlightConfig } from './CreateSpotlight';
import { EditSpotlightConfig } from './EditSpotlight';
import { ConfirmLeavePageModalConfig } from '../ConfirmLeavePageModal';
import { FirmoChangeWarningConfig } from './FirmoChangeWarning';
import { CmailSettingsModalConfig } from '../../../console/cmail/components/CmailSettingsModal';
import { ChangeProductTypeModalConfig } from '../ChangeProductTypeModal';
import { QuoteCartModalConfig } from './QuoteCartModal';
import { ReinstatePolicyModalConfig } from './ReinstatePolicyModal';
import { AccountsBulkQuoteConfig } from './AccountsBulkQuote';
import { RefreshFirmoModalConfig } from './RefreshFirmoModal';
import { AddEditAgencyLevelModalConfig } from './AddEditAgencyLevelModal';
import { DeleteAgencyLevelModalModalConfig } from './DeleteAgencyLevelModal';
import { UpdatePHContactConfig } from './UpdatePHContact';
import { RefreshRiskModalConfig } from './RefreshRiskModal';

const GlobalModals = {
  ...CBUpdateAgentConfig,
  ...AgencyDeleteConfirmConfig,
  ...ClusterAgencyRemoveConfirmConfig,
  ...VerifyUploadedApplicationConfig,
  ...UWDefenseCounselConfig,
  ...OpportunityStatusConfig,
  ...DeclineLeadModalConfig,
  ...AccountSyncLeadsConfig,
  ...CreateReminderModalConfig,
  ...uploadSurplusConfirm,
  ...DeleteSurplusLicenseConfig,
  ...SurplusUploadModalConfig,
  ...UpdateTamBdsModalConfig,
  ...InactiveLocationConfig,
  ...ActiveLocationConfig,
  ...AgencyCreateEditLocationModalConfig,
  ...AdminCreateEditLocationModalConfig,
  ...DeleteLocationModalConfig,
  ...DisableEnableUserConfig,
  ...createOpportunityModalConfig,
  ...deleteBulkUploadErrorConfig,
  ...UpdateBillingCommissionContactConfig,
  ...DeleteBillingCommissionContactConfig,
  ...AddBillingCommissionContactConfig,
  ...ArchiveQuotesConfig,
  ...TestModalConfig,
  ...WizerSignUpConfig,
  ...WizerVideoPlayerConfig,
  ...DeletePresetConfig,
  ...AddPresetsConfig,
  ...AdditionalNamedInsuredConfig,
  ...DiffToolConfig,
  ...SendQuoteConfig,
  ...UWAdditionalNamedInsuredConfig,
  ...TargetEffDatePremiumConfig,
  ...PercentWinCompetitorsOpportunityConfig,
  ...UWBindQuoteConfig,
  ...BindQuoteConfig,
  ...CompareQuotesModalConfig,
  ...AgentLicenseCreateEditConfig,
  ...AgentLicenseDeleteConfirmConfig,
  ...MutePaymentReminderConfirmConfig,
  ...UWDeclineQuoteConfig,
  ...UnDeclineAccountConfirmConfig,
  ...DeclineAccountConfirmConfig,
  ...RenewalWorkflowEmailAgencyConfig,
  ...MsspCreateEditConfig,
  ...MsspDeleteConfirmConfig,
  ...VendorCreateEditConfig,
  ...VendorDeleteConfirmConfig,
  ...CarrierCreateEditConfig,
  ...CarrierDeleteConfirmConfig,
  ...ClusterCreateEditConfig,
  ...ClusterDeleteConfirmConfig,
  ...DeclineQuoteConfirmConfig,
  ...AddFEINConfig,
  ...UploadW9Config,
  ...UploadW9NotificationConfig,
  ...AddBankPrivacyConsentConfig,
  ...FormsLibraryAddEditConfig,
  ...FormsLibraryConfirmDeleteConfig,
  ...FormTypeCRUDConfig,
  ...UpdatePolicyModalConfig,
  ...UpdateActivePolicyDatesConfig,
  ...OptOutRenewalProcessConfig,
  ...OptOutRenewalProcessReasonConfig,
  ...RenewalQuoteIssuedConfig,
  ...AWSConnectorConfig,
  ...TeamCreateEditModalConfig,
  ...UWDeleteQuoteV2Config,
  ...ComposedBORConfig,
  ...ExpeditePolicyBorModalConfig,
  ...RescindComposedBORConfig,
  ...AddSalesInboxOverrideConfig,
  ...CreateUpdateWebhookConfig,
  ...PromptWebhookStatusChangeConfig,
  ...AddStatesToTerritoryConfig,
  ...VoidPolicyConfig,
  ...DeletePolicyConfig,
  ...PauseNOCConfig,
  ...StopNOCConfig,
  ...ManuallyStartConfig,
  ...ExtendPaymentNOCConfig,
  ...StateCreateConfig,
  ...ApplyExternalPaymentConfig,
  ...SimpleConfirmConfig,
  ...UpdateTamBdsTerritoryConfig,
  ...DeleteSalesInboxTerritoryConfig,
  ...DeleteModalConfig,
  ...UWInboxNotesModalV2Config,
  ...AgencySelectionForQuoteCreationConfig,
  ...ConfigureAuthConfig,
  ...CarrierRiskProfileCreateEditConfig,
  ...RescindBinderConfig,
  ...CarrierRiskProfileDeleteConfirmConfig,
  ...AdditionalInsuredModalConfig,
  ...StopRenewalQuoteConfig,
  ...PolicyFlatCancelModalConfig,
  ...GenerateQuoteApplicationModalConfig,
  ...GenerateQuoteProposalModalConfig,
  ...GenerateBinderModalConfig,
  ...RegeneratePolicyDocumentsModalConfig,
  ...RegeneratePolicyInvoiceModalConfig,
  ...P100CalculatorErrorConfig,
  ...PolicyMTEConfig,
  ...TestFormConfig,
  ...CowbellGenerateLossRunsConfig,
  ...AgencyGenerateLossRunsConfig,
  ...NoticeOfCancellationConfig,
  ...AddEndorsementsConfig,
  ...AddEditClaimModalConfig,
  ...AddEditClaimInvoiceConfig,
  ...AddEditVendorV2Config,
  ...AddEditClaimPanelExpertConfig,
  ...CloseClaimModalConfig,
  ...NoticeOnlyModalConfig,
  ...ReopenClaimModalConfig,
  ...ConfirmReserveChangesModalConfig,
  ...VerifyClaimsInvoiceConfig,
  ...ApproveClaimsInvoiceConfig,
  ...RejectClaimsInvoiceConfig,
  ...DocUploadInfoConfig,
  ...RiskEngQuestionsAddTemplateConfig,
  ...LossRunsDownloadFailureConfig,
  ...RiskEngLoadTemplateModalConfig,
  ...RiskEngImportTemplateConfig,
  ...DocumentPreviewModalConfig,
  ...UpdateBillingComplianceModalConfig,
  ...MigrateAccountsOnTeamChangeConfig,
  ...AddEditZipCodeConfig,
  ...CancelPolicyConfig,
  ...AddPrimaryInsuranceConfig,
  ...AddAttachmentDetailsConfig,
  ...AddAdditionalEndorsementsConfig,
  ...UploadEndorsementOrFormConfig,
  ...P100RenewalRequestToBindWorkflowConfig,
  ...CowbellAddAgencyModalV2Config,
  ...CreateEditPrimeCloudPolicyModalConfig,
  ...CowbellAddAccountModalV2Config,
  ...AddERPModalConfig,
  ...ClaimsPayInvoiceConfig,
  ...ConfirmBindQuoteConfig,
  ...MessageModalConfig,
  ...EmailSignLinkConfirmationConfig,
  ...UnderwritingSubjectivitiesConfig,
  ...ConfirmIssuePolicyConfig,
  ...SurplusLinesNoticeConfirmationConfig,
  ...MuteRenewalReminderConfig,
  ...QuoteChangeToReferredConfig,
  ...ClearFiltersModalConfig,
  ...BulkRenewalOptOutConfig,
  ...UpdateClaimClosingDateModalConfig,
  ...BulkRenewalOptInConfig,
  ...TerritoryContactCRUDModalConfig,
  ...GenericConnectorConfig,
  ...ApplicationUploadConfig,
  ...AgencyUsersManageModalConfig,
  ...P100ConfirmIssuePolicyConfig,
  ...MigrateAgencyUsersModalConfig,
  ...P100NKLLUploadModalConfig,
  ...DocumentUploadConfig,
  ...RenewalSubmissionLostReasonConfig,
  ...RedirectToMfaConfig,
  ...RenewalMessageForCowbellConfig,
  ...CreateSpotlightConfig,
  ...EditSpotlightConfig,
  ...ConfirmLeavePageModalConfig,
  ...FirmoChangeWarningConfig,
  ...CmailSettingsModalConfig,
  ...QuoteCartModalConfig,
  ...ChangeProductTypeModalConfig,
  ...ReinstatePolicyModalConfig,
  ...AccountsBulkQuoteConfig,
  ...RefreshFirmoModalConfig,
  ...AddEditAgencyLevelModalConfig,
  ...DeleteAgencyLevelModalModalConfig,
  ...UpdatePHContactConfig,
  ...RefreshRiskModalConfig,
};

export default GlobalModals;

export const mappedModals = Object.keys(GlobalModals).reduce(
  (acc, key) => ({
    ...acc,
    [key]: {
      config: _.get(GlobalModals, `[${key}].config`, {}),
    },
  }),
  {}
);
