import React from 'react';
import { useSnackbar } from 'notistack';
import { Grid as MuiGrid, DialogContent } from '@mui/material';

import CBButton from '../Buttons/CbButton';
import { signUpWizer } from '../../policyholders/training/wizerService.js';
import { useToggleModal } from '../../utils/modal.utils';

const WizerTrainingConfirm = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const handleSubmit = () => {
    signUpWizer()
      .then(() => {
        toggleModal.direct('TrainingInfo', false);
      })
      .catch(({ error }) => {
        enqueueSnackbar(error.response.data, { variant: 'error' });
      });
  };
  return (
    <>
      <DialogContent>
        <div className="modal-title">
          Once you sign up, you will receive an email from Wizer/Cowbell with
          the next steps.
        </div>
      </DialogContent>

      <MuiGrid
        container
        spacing={8}
        justifyContent="flex-end"
        style={{ marginLeft: '-80px', marginBottom: '15px', marginTop: '10px' }}
      >
        <CBButton size="medium" styleName="cancel" onClick={props.close}>
          Cancel
        </CBButton>

        <CBButton
          size="medium"
          type="submit"
          styleName="ctaButton"
          onClick={handleSubmit}
        >
          Sign Up
        </CBButton>
      </MuiGrid>
    </>
  );
};

export default WizerTrainingConfirm;
