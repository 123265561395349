import React, { useEffect, useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { PubSub } from '../../../utils/eventUtils';

export const withSubs = (events = []) => {
  return (Component) => {
    const C = (props) => {
      const [state, setState] = useState({});

      useEffect(() => {
        const subs = events.map((event) =>
          PubSub.subscribe(event, (nextState) => {
            setState((current) => ({
              ...current,
              ...nextState,
            }));
          })
        );

        return () => {
          subs.forEach((sub) => sub.remove());
        };
      }, [state]);

      return <Component {...props} {...state} />;
    };

    C.displayName = `withSubs(${Component.displayName || Component.name})`;
    C.wrappedComponent = Component;

    return hoistNonReactStatics(C, Component);
  };
};
