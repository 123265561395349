import React from 'react';
import clsx from 'classnames';

import { Button } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import type { WithChildrenProps } from '../../../../types/components/common';

interface SmallActionButtonProps extends WithChildrenProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  name: string;
  href?: any;
  target?: any;
}

export const SmallActionButton = ({
  children,
  onClick,
  name,
  ...props
}: SmallActionButtonProps) => {
  const theme = useTheme<any>();
  const classes = useButtonStyles();

  return (
    <div style={{ width: '50%' }}>
      <div className="ratio--1-5x1">
        <div className="ratio__content">
          <Button
            disableRipple
            variant="contained"
            classes={classes}
            className={clsx('scale--small')}
            data-qa={`BUTTON:${name}`}
            onClick={onClick}
            style={{
              color: theme.agencyDash.icon,
              borderLeft: `4px solid ${theme.agencyDash.interactable}`,
            }}
            {...props}
          >
            {children}
          </Button>
        </div>
      </div>
    </div>
  );
};

const useButtonStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '1rem',
    backgroundColor: theme.agencyDash.background.button,
    color: theme.palette.primary.contrastText,

    '&:hover': {
      backgroundColor: theme.agencyDash.background.button,
    },
  },

  label: {
    display: 'flex',
  },
}));
