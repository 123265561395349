import {
  QueryClient,
  QueryClientProvider as BaseProvider,
} from '@tanstack/react-query';

import type { WithChildrenProps } from '../types/components/common';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      refetchOnWindowFocus: false,
    },
  },
});

function QueryClientProvider({ children }: WithChildrenProps) {
  return <BaseProvider client={queryClient}>{children}</BaseProvider>;
}

export default QueryClientProvider;
