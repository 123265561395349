import React, { useState } from 'react';
import _ from 'lodash';
import {
  Grid,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { compose } from 'redux';
import * as Yup from 'yup';
import { useForm, FormContext } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
import { delayedEvent } from '../../../../utils/eventUtils';
import { withFormController } from '../../../hocs/withFormController';
import { SimpleSelect } from '../../../inputs/selects/SimpleSelect';
import { TextFieldBase } from '../../../inputs/TextFieldBase';
import CBButton from '../../../Buttons/CbButton';
import { Checkbox } from '../../../inputs/index';
import { iconMappings } from '../../../../console/_global/navigation/IconsMappings';
import { Add250Preset } from '../../../../api/p250.service.js';
import '../../../../console/_global/lib/validations/CurrencySchemaType.js';
import LimitInput from './presetFields/limitInput';

import { withShowable } from '../../../../console/_global/lib/withShowable';
import CoveragesInput from './presetFields/coverageName';
import DeductibleInput from './presetFields/DeductibleInput';
import RetroInput from './presetFields/RetroInputs';
import HoursInput from './presetFields/HoursInput';

import { presetProductOptions } from './constants';

const UISelect = withFormController(SimpleSelect);

const Check = withFormController(Checkbox);

const CoveragesInputs = withFormController(CoveragesInput);
// Showable
const MuiGrid = withShowable(Grid);
const CoveragesField = withShowable(CoveragesInputs);
const DeductibleField = withShowable(DeductibleInput);
const RetroField = withShowable(RetroInput);
const HoursField = withShowable(HoursInput);
const LimitsField = withShowable(LimitInput);

export const presetsOptions = [
  {
    label: 'Popular',
    value: 'POPULAR',
  },
  {
    label: 'Elite',
    value: 'ELITE',
  },
  {
    label: 'Basic',
    value: 'BASIC',
  },
];

export const AddPresetsModal = compose(
  withStyles({
    optionsBox: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 12,
      marginBottom: -40,
      display: 'flex',
    },
  })
)(({ classes, ...props }) => {
  const [backendError, setBackendError] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const [coverage, setCoverage] = useState([]);
  const [naicsCode, setNaicsCode] = useState([]);

  const { handleSubmit, setError, ...methods } = useForm({
    defaultValues: {
      limit: 0,
      isAvailable: false,
      isSelected: false,
      alignMax: false,
    },
  });

  const {
    getValues,
    formState: { dirty, isSubmitting },
  } = methods;

  const values = getValues();

  const handleNaicsCodeChange = _.debounce((value) => {
    setNaicsCode(value);
    methods.setValue('naicsCode', value);
  }, 500);

  const onNaicsCodeChange = (event) => {
    handleNaicsCodeChange(_.get(event, 'target.value'));
  };

  // initial render
  React.useEffect(() => {
    methods.register({ name: 'deductible', type: 'custom' });
    methods.register({ name: 'hrs', type: 'custom' });
    methods.register({ name: 'limit', type: 'custom' });
    methods.register({ name: 'retroActivePeriod', type: 'custom' });

    // methods.watch('naicsCode');
    return () =>
      methods.unregister(['deductible', 'limit', 'retroActivePeriod', 'hrs']);
    // eslint-disable-next-line
  }, []);

  const schema = React.useMemo(() => {
    const baseSchema = {
      name: Yup.string().required(),
      naicsCode: Yup.number().required(),
      limit: Yup.number().required(),
      preset: Yup.string()
        .test('len', 'Please select a Preset Category', (val) => val != 0)
        .required(),
    };

    if (Array.isArray(coverage.deductibles)) {
      baseSchema.deductible = Yup.number().required();
    }

    if (coverage.hrs) {
      baseSchema.hrs = Yup.number().required();
    }

    if (Array.isArray(coverage.retros) && coverage.retros.length) {
      baseSchema.retroActivePeriod = Yup.string().required();
    }

    return Yup.object().shape(baseSchema);
  }, [coverage]);

  function onSubmit(formData) {
    const formdata = { ...formData, naicsCode };
    try {
      schema.validateSync(formdata, { abortEarly: false });
      const payload = {
        naicsCode: formdata.naicsCode,
        preset: formdata.preset,
        productType: formData.productType,
        coverage: {
          name: formdata.name,
          limit: formdata.limit,
          deductible: formdata.deductible || null,
          retroActivePeriod: formdata.retroActivePeriod || null,
          isAvailable: formdata.isAvailable || false,
          isSelected: formdata.isSelected || false,
          alignMax: formdata.alignMax || false,
          waitingPeriod: formdata.hrs || null,
        },
      };

      return Add250Preset({}, payload)
        .then(() => {
          props.close();
          enqueueSnackbar('Preset added succesfully', { variant: 'success' });
          makeRefreshCall();
        })
        .catch((error) => {
          setBackendError(_.get(error, 'response.data'));
        });
    } catch ({ inner }) {
      const errors = {};

      inner.forEach(({ path, message }) => {
        errors[path] = message;
        setError(path, { message });
      });
    }
  }

  const keepDisabled = !values.name || !values.limit;

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <DialogContent style={{ top: 0 }}>
              <MuiGrid container spacing={2}>
                <MuiGrid item md={4}>
                  <TextFieldBase
                    label="Industry Code"
                    name="naicsCode"
                    onChange={onNaicsCodeChange}
                    required
                    fullWidth
                    showErrorPlace={false}
                  />
                </MuiGrid>
                <MuiGrid item md={4} style={{ marginTop: '-15px' }}>
                  <CoveragesField
                    setCoverage={setCoverage}
                    name="name"
                    naicsCode={naicsCode}
                  />
                </MuiGrid>
                <MuiGrid item md={4} style={{ marginTop: '-15px' }}>
                  <UISelect
                    label="Product"
                    name="productType"
                    options={presetProductOptions}
                    style={{
                      right: 0,
                    }}
                    displayEmpty
                    renderValue={renderValue}
                    fullWidth
                    required
                  />
                </MuiGrid>
              </MuiGrid>
              <LimitsField
                show={naicsCode && Array.isArray(coverage.limits)}
                limitVal={coverage.limits}
                name="limit"
              />
              <MuiGrid
                show={values.limit > 0}
                container
                style={{ justifyContent: 'center' }}
              >
                <MuiGrid item md={12} style={{ float: 'right' }}>
                  <UISelect
                    label="Preset Category"
                    name="preset"
                    options={presetsOptions}
                    style={{
                      right: 0,
                    }}
                    displayEmpty
                    renderValue={renderValue}
                    fullWidth
                    required
                  />
                </MuiGrid>
              </MuiGrid>
              <DeductibleField
                show={
                  typeof coverage.coverage === 'string' &&
                  Array.isArray(coverage.deductibles) &&
                  values.limit > 0
                }
                deductibles={coverage.deductibles}
              />
              <RetroField
                show={
                  typeof coverage.coverage === 'string' &&
                  Array.isArray(coverage.retros) &&
                  coverage.retros.length > 0 &&
                  values.limit > 0
                }
                retroInput={coverage.retros}
                name="retroActivePeriod"
              />
              <HoursField
                show={
                  typeof coverage.coverage === 'string' &&
                  Array.isArray(coverage.hrs) &&
                  coverage.hrs.length > 0 &&
                  values.limit > 0
                }
                name="hrs"
                hourInput={coverage.hrs}
              />

              <div className={classes.optionsBox}>
                <Check name="isAvailable" label="Is Available?" />
                <Check name="isSelected" label="Is Selected?" />
                <Check name="alignMax" label="Align Max?" />
              </div>
              {backendError != '' && (
                <FormHelperText
                  className="api-text-error"
                  style={{ marginTop: '45px' }}
                >
                  {backendError}
                </FormHelperText>
              )}
            </DialogContent>
          </>
          <DialogActions>
            <CBButton size="medium" styleName="cancel" onClick={props.close}>
              Cancel
            </CBButton>

            <CBButton
              size="medium"
              loading={isSubmitting}
              disabled={keepDisabled || !dirty || isSubmitting}
              type="submit"
              styleName="ctaButton"
            >
              Save
            </CBButton>
          </DialogActions>
        </form>
      </FormContext>
    </>
  );
});

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, 'admin_presets');
}
function renderValue(value) {
  return value || 'Please Choose...';
}

export const AddPresetsConfig = {
  AddPresetsModal: {
    component: AddPresetsModal,
    config: {
      title: 'Add Preset',
      maxWidth: 'md',
      minWidth: 'md',
      icon: iconMappings.Add.iconImport,
    },
  },
};
