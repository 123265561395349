import React from 'react';
import type { ControllerProps } from 'react-hook-form';
import { useFormContext, Controller } from 'react-hook-form';

/**
 * @name withHooksController
 * @description Wraps a given input with a Controller from react-hook-form
 */

export interface FormControllerProps {
  name: string;
  label?: string;
  controllerProps?: ControllerProps;
}

export function withFormController<P extends object>(
  Component: React.ComponentType<P>
): (props: P & FormControllerProps) => JSX.Element {
  return ({ controllerProps, ...props }) => {
    const context = useFormContext();

    React.useEffect(() => {
      if (context == null && controllerProps?.control == null) {
        throw new Error(
          'withFromController must either be used within a FormProvider or provided a valid control prop'
        );
      }
    });

    return (
      <Controller
        {...controllerProps}
        control={controllerProps?.control ?? context.control}
        name={props.name}
        render={({ field, fieldState: { error, ...fieldState } }) => {
          return (
            <Component
              {...field}
              {...fieldState}
              error={error && error.message}
              errorMessage={error && error.message}
              {...(props as P)}
            />
          );
        }}
      />
    );
  };
}
