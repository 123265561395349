import React from 'react';
import Moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

// mui
import { Box, styled, Typography } from '@mui/material';

// components
import CbButtonBase from '../../../../components/Buttons/CbButton';

// utils and helpers
import { Modal } from '../../../../components/modals/v2/helpers/v2.modal.helpers';
import {
  AttestCheckBox,
  AttestStepSecondaryText,
} from './shared/AttestationStepBase';
import { downloadBinder } from '../../../../inbox/QuotesService';
import { DocumentDownloader } from '../../../../components/fileUploader/DocumentDownloader';
import { withShowable } from '../../../_global/lib/withShowable';
import SimpleTooltip from '../../../../components/SimpleTooltip';
import { ProductTypes } from '../../../../types';
import { QuoteAgencyStatus } from '../../../../types/common/misc';

const CbButton = withShowable(CbButtonBase);

const IssuePolicy = ({
  goForward,
  goBack,
  completeStep,
  step,
  quoteDetails,
  incompletePreIssueSteps,
  quoteProgress,
}) => {
  const [issuePolicyAcknowledgement, setIssuePolicyAcknowledgement] =
    React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { quoteId } = quoteDetails.uiData;

  const { data: isBinderDownloadAvailable = false } = useQuery(
    ['binder-download-avaliable', quoteDetails.id],
    () => {
      return downloadBinder(
        quoteDetails.uiData.accountId,
        quoteId,
        Moment(quoteDetails.uiData.boundOn).unix()
      ).then((res) => {
        return Boolean(res.data);
      });
    }
  );

  const DownloadBinderWrapper = isBinderDownloadAvailable
    ? React.Fragment
    : SimpleTooltip;

  const tooltipProps = {
    title: (
      <div style={{ textAlign: 'center' }}>
        The binder is not currently available for download. Please check back
        later.
      </div>
    ),
  };

  React.useEffect(() => {
    if (
      incompletePreIssueSteps.length ||
      quoteProgress.quoteAgencyStatus !== QuoteAgencyStatus.BOUND
    ) {
      enqueueSnackbar(
        'All steps above must be completed before policy can be issued.',
        {
          variant: 'error',
        }
      );
      goBack();
      return null;
    }
  }, [enqueueSnackbar, goBack, incompletePreIssueSteps, quoteProgress]);

  const isPolicyIssued =
    quoteProgress.quoteAgencyStatus === QuoteAgencyStatus.SIGNED;

  const handleCheckboxChange = () => {
    setIssuePolicyAcknowledgement((prevState) => !prevState);
  };

  const handleDownloadBinder = () => {
    return downloadBinder(
      quoteDetails.uiData.accountId,
      quoteDetails.uiData.quoteId,
      Moment(quoteDetails.uiData.boundOn).unix()
    );
  };

  const handleIssuePolicy = () => {
    const modalComponent =
      quoteDetails.uiData.productType === ProductTypes.p100
        ? 'P100ConfirmIssuePolicy'
        : 'ConfirmIssuePolicy';

    Modal.show(modalComponent, {
      data: {
        quoteDetails,
        quoteId,
        goForward,
        step,
        completeStep,
      },
      config: { title: `Issue Policy for ${quoteDetails.uiData?.companyName}` },
    });
  };

  return (
    <Box className="contrast-text" maxWidth="65%">
      <Typography variant="h4" style={{ fontWeight: 600, fontSize: '1.5rem' }}>
        Attest and Issue Policy
      </Typography>

      <AttestCheckBox
        name="issuePolicyAcknowledgement"
        handleCheckboxChange={handleCheckboxChange}
        checked={isPolicyIssued || undefined}
        disabled={isPolicyIssued}
      />
      <AttestStepSecondaryText />
      <Box paddingTop="1.5rem">
        <CbButton styleName="cancel" size="medium" onClick={goBack}>
          Back
        </CbButton>
        <DownloadBinderWrapper
          style={!isBinderDownloadAvailable ? { display: 'inline' } : {}}
          {...(!isBinderDownloadAvailable ? tooltipProps : undefined)}
        >
          <DocumentDownloader
            type=".pdf"
            name={`${quoteDetails.uiData.companyName}-${quoteDetails.uiData.quoteNumber}-Cowbell-Binder`}
            onDownload={handleDownloadBinder}
          >
            {({ handleDocumentDownload }) => (
              <DownloadBinderButton
                size="medium"
                onClick={handleDocumentDownload}
                disabled={!isBinderDownloadAvailable}
              >
                Download Binder
              </DownloadBinderButton>
            )}
          </DocumentDownloader>
        </DownloadBinderWrapper>

        <CbButton
          styleName="ctaButton"
          size="medium"
          disabled={!issuePolicyAcknowledgement || quoteProgress.isUpdating}
          style={{
            border: `1px solid ${
              !issuePolicyAcknowledgement ? '#fff' : 'transparent'
            }`,
          }}
          onClick={handleIssuePolicy}
          show={!isPolicyIssued}
        >
          Issue
        </CbButton>
      </Box>
    </Box>
  );
};

export default IssuePolicy;

const DownloadBinderButton = styled(CbButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.text12}`,
  color: theme.palette.text.text12,
  background: 'transparent',
  marginRight: 10,
  '&:hover': {
    backgroundColor: theme.palette.button.background,
  },
}));
