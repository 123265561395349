// lodash
import _ from 'lodash';

// mui
import { LinearProgress } from '@mui/material';
import { styled, makeStyles } from '@mui/styles';

// utils
import { PubSub } from '../../utils/eventUtils';

export const standaridizeAccountDetails = (accountData) => {
  const rootLevel = _.pick(accountData, [
    'additionalNamedInsured',
    'name',
    'phoneNumber',
    'zipCode',
    'dbaOrTradestyle',
    'address1',
    'city',
    'state',
    'naicsCode',
    'secondNaicsCode',
    'primaryNaicsPercentage',
    'primaryIndustry',
    'secondaryIndustry',
    'secondPrimaryIndustry',
    'secondSecondaryIndustry',
    'revenue',
    'noOfEmployeesAll',
    'yearEstablished',
    'entityType',
    'ownershipType',
    'isFranchise',
    'domainName',
    'numberOfPersonalRecords',
    'percentInternationalSales',
    'policyContactName',
    'policyContactPhone',
    'policyContactEmail',
    'isSecurityOfficer',
    'isSecurityTraining',
    'useCloudStorage',
    'useEncryption',
    'url',
    'dunsNumber',
    'claimHistory',
    'policyContactFirstName',
    'policyContactLastName',
  ]);

  const nestedData = _.pick(_.get(accountData, 'p250Details', {}), [
    'pastCyberIncident',
  ]);

  const allOtherDomains = _.get(accountData, 'domains', []).map(
    (value) => value.domain
  );

  return {
    ...rootLevel,
    ...nestedData,
    allOtherDomains,
  };
};

export const standaridizeQuoteDetails = (quoteData) => {
  const nestedP250Data = _.pick(
    _.get(quoteData, 'firmographicData.p250Details', {}),
    ['pastCyberIncident']
  );

  const nestedData = _.pick(_.get(quoteData, 'firmographicData', {}), [
    'name',
    'phoneNumber',
    'zipCode',
    'address1',
    'city',
    'state',
    'dbaOrTradestyle',
    'isSecurityOfficer',
    'isSecurityTraining',
    'isFranchise',
    'useCloudStorage',
    'useEncryption',
    'yearEstablished',
    'entityType',
    'ownershipType',
    'dunsNumber',
    'url',
    'primaryNaicsPercentage',
    'noOfEmployeesAll',
    'domainName',
    'numberOfPersonalRecords',
    'percentInternationalSales',
    'naicsCode',
    'secondNaicsCode',
    'primaryNaicsPercentage',
    'primaryIndustry',
    'secondaryIndustry',
    'secondPrimaryIndustry',
    'secondSecondaryIndustry',
    'claimHistory',
    'revenue',
  ]);

  const policyContactName = _.get(quoteData, 'customerFirstName', false)
    ? `${_.get(quoteData, 'customerFirstName')} ${_.get(
        quoteData,
        'customerLastName'
      )}`
    : undefined;

  const allOtherDomains = _.get(quoteData, 'domains', []).map(
    (value) => value.domain
  );

  return {
    ...nestedP250Data,
    ...nestedData,
    policyContactName,
    policyContactFirstName: _.get(quoteData, 'customerLastName'),
    policyContactLastName: _.get(quoteData, 'customerFirstName'),
    policyContactPhone: _.get(quoteData, 'customerPhone'),
    policyContactEmail: _.get(quoteData, 'customerEmail'),
    allOtherDomains,
  };
};

const accountDetails = [
  'name',
  'phoneNumber',
  'zipCode',
  'dbaOrTradestyle',
  'address1',
  'city',
  'state',
  'naicsCode',
  'primaryNaicsPercentage',
  'yearEstablished',
  'entityType',
  'ownershipType',
  'isFranchise',
  'domainName',
  'numberOfPersonalRecords',
  'percentInternationalSales',
  'url',
  'domainName',
  'dunsNumber',
  'dbaOrTradestyle',
];

const policyContactQuestions = [
  'policyContactFirstName',
  'policyContactLastName',
  'policyContactPhone',
  'policyContactEmail',
];

const securityQuestions = [
  'pastCyberIncident',
  'claimHistory',
  'isSecurityOfficer',
  'isSecurityTraining',
  'useCloudStorage',
  'useEncryption',
];

export const standaridizeUndefinedDetails = (standaridizeDetails) => {
  const availableAnswer = Object.keys(standaridizeDetails);

  const undefinedAccountDetails = accountDetails.filter(
    (value) => !availableAnswer.includes(value)
  );
  // required fields to display
  undefinedAccountDetails.push('revenue', 'noOfEmployeesAll');

  const undefinedPolicyContact = policyContactQuestions.filter(
    (value) => !availableAnswer.includes(value)
  );

  const undefinedSecurityQuestions = securityQuestions.filter(
    (value) => !availableAnswer.includes(value)
  );

  return {
    undefinedAccountDetails,
    undefinedSecurityQuestions,
    undefinedPolicyContact,
  };
};

export const removeAttestCheck = () => {
  return PubSub.publish('org:agent-attested', {
    isVerifiedByAgent: false,
  });
};

export const getNKLLAttestationLabelForAgencyRenewals = () => {
  return <Text>NKLL is attached with the signed app</Text>;
};

export const getAttestationLabelForAgencyRenewals = () => {
  return (
    <Text>
      I understand that by checking this box, I am confirming that the document
      uploaded:
    </Text>
  );
};

export const bullettedAttestationForAgencyRenewals = () => {
  return (
    <Text weight="normal">
      <ul style={{ margin: 0 }}>
        <li>
          is the application used to obtain the Quote and / or Conditional
          Binder,{' '}
        </li>{' '}
        <li>
          to the best of my knowledge, contains no information that was false
          and / or untrue at the time it was provided,
        </li>{' '}
        <li>includes the Insured's signature, and</li>{' '}
        <li>
          makes no changes to the application used to obtain the Quote and / or
          Conditional Binder.
        </li>
      </ul>
    </Text>
  );
};

export const bullettedNKLLAttestationForAgencyRenewals = () => {
  return (
    <Text weight="normal">
      <ul style={{ margin: 0 }}>
        <li>
          is the No Known Loss Letter used to obtain the Quote and / or
          Conditional Binder,
        </li>{' '}
        <li>
          to the best of my knowledge, contains no information that was false
          and / or untrue at the time it was provided,
        </li>{' '}
        <li>includes the Insured's signature, and</li>{' '}
        <li>
          makes no changes to the application used to obtain the Quote and / or
          Conditional Binder.
        </li>
      </ul>
    </Text>
  );
};

const Text = styled('span')(
  ({ weight = '600', theme: { config, palette } }) => ({
    fontWeight: weight,
    color: palette.primary.contrastText,
    fontSize: config.textSizes.tertia,
  })
);

export const getFileDetailsToUpload = (files) => {
  const file = _.head(files);

  if (file) {
    const splitString = file.name.split('.');
    const extension = splitString.pop();
    const fileName = splitString.join('.');
    return { extension, fileName };
  }
  return { extension: '', fileName: '' };
};

export const LinearUploadProgress = () => {
  const classes = styles();
  return (
    <div className={classes.text}>
      Upload in progress...
      <LinearProgress
        classes={{
          colorPrimary: `${classes.progressDark}`,
          barColorPrimary: `${classes.progressLight}`,
        }}
      />
    </div>
  );
};

const styles = makeStyles(({ config, palette }) => {
  return {
    text: {
      color: palette.primary.contrastText,
    },
    progressLight: {
      backgroundColor: config.colors.softBlue,
    },
    progressDark: {
      backgroundColor: config.colors.cowbellBlue,
    },
  };
});
