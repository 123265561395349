import React from 'react';
import { SvgIcon } from '@mui/material';

export const DownloadDocs = ({ ...props }) => {
  return (
    <SvgIcon
      data-name="Download Docs"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <g id="Isolation_Mode" data-name="Isolation Mode">
        <path
          id="Rectangle_934"
          data-name="Rectangle 934"
          style={{ fill: 'none' }}
          d="M0 0h16v16H0z"
        />
        <rect
          id="Rectangle_935"
          data-name="Rectangle 935"
          className="cls-2"
          width=".768"
          height=".763"
          rx=".29"
          transform="translate(10.987 11.947)"
        />
        <path
          id="Path_2230"
          data-name="Path 2230"
          className="cls-2"
          d="M11.46 21.663a.171.171 0 0 1-.123-.048l-.752-.741a.176.176 0 0 0-.251 0l-.533.533a.171.171 0 0 1-.245 0l-.533-.533a.171.171 0 0 0-.245 0l-.485.48-.32.32a.16.16 0 0 0 0 .219l.32.32a.165.165 0 0 0 .224 0l.037-.037.352-.347.661.635a.176.176 0 0 0 .251 0l.533-.533a.176.176 0 0 1 .251 0l.432.432a.176.176 0 0 0 .123.048h2.779a.149.149 0 0 0 .128-.144v-.443a.155.155 0 0 0-.128-.149z"
          transform="translate(-3.7 -9.717)"
        />
        <path
          id="Path_2231"
          data-name="Path 2231"
          className="cls-2"
          d="M15.6 5.471 12.708 2.6a.357.357 0 0 0-.251-.1H4.852a.352.352 0 0 0-.352.352v12.629a.352.352 0 0 0 .352.352h10.5a.352.352 0 0 0 .352-.352V5.716a.352.352 0 0 0-.1-.245zm-.8 9.461H5.4V3.38h6.475v2.528a.352.352 0 0 0 .347.352h2.592z"
          transform="translate(-2.1 -1.167)"
        />
        <path
          id="Path_2232"
          data-name="Path 2232"
          className="cls-2"
          d="M11.017 16.46a.453.453 0 0 0 .453-.453.459.459 0 0 0-.453-.459H7.945a.459.459 0 1 0 0 .912z"
          transform="translate(-3.471 -7.254)"
        />
        <path
          id="Path_2233"
          data-name="Path 2233"
          className="cls-2"
          d="M13.2 11.81H7.993a.453.453 0 1 0 0 .907H13.2a.453.453 0 1 0 0-.907z"
          transform="translate(-3.519 -5.511)"
        />
      </g>
    </SvgIcon>
  );
};
