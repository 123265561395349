import React from 'react';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { completeQuoteSubjectivity } from '../../../../../api';
import { QuoteAgencyStatus } from '../../../../../types/common/misc';
import { handleError } from '../../utils/bindQuoteWorkFlowUtils';
import { usePersona } from '../../../../../components/hooks/usePersona';

const useCompleteQuoteSubjectivity = ({ quoteId, productType }) => {
  const [isCompletingStep, setIsCompletingStep] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const history = useHistory();
  const persona = usePersona();

  const completeSubjectivity = (completeSubjectivityParams) => {
    setIsCompletingStep(true);
    const {
      payload = {},
      quoteSubjectivityId,
      goForward,
    } = completeSubjectivityParams;

    completeQuoteSubjectivity(productType, quoteSubjectivityId, {
      data: payload,
    })
      .then(async ({ data }) => {
        await queryClient.invalidateQueries(['quote-progress', quoteId]);

        setTimeout(() => {
          goForward();
        }, 500);

        enqueueSnackbar('Step information saved!', { variant: 'success' });

        /* In the case the data sent for a subj results in a referral we notify the user. For no this is only for P250. */
        if (data?.hydratedInfo?.willGetReferred) {
          enqueueSnackbar(
            'This quote is being referred to an underwriter for review.',
            { variant: 'info' }
          );
        }
      })
      .catch(async (error) => {
        await queryClient.refetchQueries([
          'quote-progress',
          quoteId,
          persona.type,
        ]);

        const { quoteProgress } = queryClient.getQueryData([
          'quote-progress',
          quoteId,
          persona.type,
        ]);

        // Addresses concerns of quote status switching to invalid in the middle of bind flow
        if (quoteProgress.quoteAgencyStatus === QuoteAgencyStatus.INVALID) {
          enqueueSnackbar(
            'Quote status is invalid and a new quote must be generated for this account.',
            { variant: 'error' }
          );
          history.replace('/agency/accounts');
          return;
        }
        handleError({ error, enqueueSnackbar });
      })
      .finally(() => {
        setIsCompletingStep(false);
      });
  };
  return {
    completeSubjectivity,
    isCompletingStep,
  };
};

export default useCompleteQuoteSubjectivity;
