import Moment from 'moment';
import _ from 'lodash';

// types
import type {
  Coverage,
  CoverageP250MetadataDto,
  UiCoverageP250,
  AvailableCoveragesDto,
  P250QuoteInitialState,
  CoverageDto,
} from '../types';

import Coverages from '../console/_statics/250/coverages/coverages.statics.json';

export function mergeConveragesAndStateData(
  stateCoverages: Partial<Coverage>[] = [],
  coverages: CoverageDto[] = []
): Coverage[] {
  const unique: { [key: string]: Coverage } = _.intersectionWith(
    coverages,
    stateCoverages,
    (coverage: any, stateCoverage) => coverage.ordinal === stateCoverage._id
  ).reduce(
    (acc, val) => ({
      ...acc,
      [val.ordinal]: {
        ...val,
      },
    }),
    {}
  );

  return coverages.map(
    (coverage: CoverageDto): Coverage => ({
      ...coverage,
      _id: coverage.ordinal as number,
      available: unique[coverage.ordinal as number]
        ? unique[coverage.ordinal as number].isAvailable
        : false,
    })
  );
}

export function addRetroActiveToCoverages(coverages: Coverage[]): Coverage[] {
  return coverages.map((coverage: Coverage) => ({
    ...coverage,
    retroActivePeriod:
      typeof coverage.retroActivePeriod !== 'undefined'
        ? coverage.retroActivePeriod
        : undefined,
    // some retroActivePeriods are -1 so we can't shorthand e.g. !retroActivePeriod
    retroActiveDate:
      typeof coverage.retroActiveDate !== 'undefined'
        ? Moment(coverage.retroActiveDate).format('YYYY-MM-DD')
        : undefined,
  }));
}

export function constructAvailableCoverages(payload: {
  [key: number]: CoverageP250MetadataDto;
}): UiCoverageP250[] {
  const coverages = Coverages.map((coverage) => ({
    ...coverage,
    name: payload[coverage._id].name,
    key: payload[coverage._id].key,
    tooltip: payload[coverage._id].tooltip,
  }));

  return coverages;
}

// scoped data utils
export function zipPresets(data: Coverage[]): { [key: string]: Coverage } {
  return data.reduce(
    (acc: { [key: string]: Coverage }, preset: Coverage) => ({
      ...acc,
      [preset.name]: preset,
    }),
    {}
  );
}

export function createReducePreset(zippedPresets: {
  [key: string]: UiCoverageP250;
}) {
  return (acc: any, coverage: any) => {
    const preset = zippedPresets[coverage.key];

    if (!preset) {
      return acc;
    }

    const { isAvailable, isSelected } = preset;

    // preserve security question coverages from being filtered out
    if (
      coverage._id !== 23 &&
      coverage._id !== 24 &&
      coverage._id !== 34 &&
      !coverage.mandatory &&
      (!isAvailable || !isSelected)
    ) {
      return acc;
    }

    return [
      ...acc,
      {
        ...coverage,
        available: isAvailable,
        selected: isSelected,
        ...preset,
      },
    ];
  };
}

export function pullOptions(
  available: AvailableCoveragesDto[],
  used: number[]
): AvailableCoveragesDto[] {
  return _.intersectionWith(available, used, (a: any, u: any) => a.value === u);
}

export const P250_QUOTE_INTIAL_STATE: P250QuoteInitialState = {
  effectiveDate: Moment().endOf('day').add(14, 'days').toDate(),
  endDate: Moment().endOf('day').add(14, 'days').add(1, 'y').toDate(),
};

export const getRenewalQuoteCount = (quoteCounts = []) => {
  return _.get(
    quoteCounts.filter(
      (item: { key: number; doc_count: number }) => item.key === 1
    ),
    '[0].doc_count',
    0
  );
};

export function getIsRenewalOrMigration(
  isRenewal: boolean,
  isMigration: boolean
): boolean {
  return isRenewal || isMigration;
}
