import React from 'react';

// lodash
import _ from 'lodash';

// components
import { Box, Typography } from '@mui/material';

import { PROVIDER_AGENCY } from './billing-compliance.consts';
import { ProductTypes } from '../../../../types';

const CowbellAgencyName = 'Cowbell Insurance';

export const UpdateBillingComplianceConfirmation = ({
  agentLicenseInfo,
  billingProvider,
  complianceProvider,
  policy,
  product,
}) => {
  const changeTypes = React.useMemo(() => {
    let types = {
      billingChanged: false,
      complianceChanged: false,
      complianceInfoChanged: false,
    };

    if (policy.billingProvider !== billingProvider) {
      types = {
        ...types,
        billingChanged: true,
        previousBillingProvider:
          policy.billingProvider === PROVIDER_AGENCY
            ? policy.agencyName
            : CowbellAgencyName,
        nextBillingProvider:
          billingProvider === PROVIDER_AGENCY
            ? policy.agencyName
            : CowbellAgencyName,
      };
    }

    if (
      product !== ProductTypes.p100 &&
      policy.complianceProvider !== complianceProvider
    ) {
      types = {
        ...types,
        complianceChanged: true,
        previousComplianceProvider:
          policy.complianceProvider === PROVIDER_AGENCY
            ? policy.agencyName
            : CowbellAgencyName,
        nextComplianceProvider:
          complianceProvider === PROVIDER_AGENCY
            ? policy.agencyName
            : CowbellAgencyName,
      };
    }

    const policySurplusInfo = pullSurplusAgentInfo(policy);
    const formSurplusInfo = pullSurplusAgentInfo(agentLicenseInfo);
    if (
      product !== ProductTypes.p100 &&
      !_.isEqual(policySurplusInfo, formSurplusInfo)
    ) {
      types = {
        ...types,
        complianceInfoChanged: true,
      };
    }

    return types;
  }, [agentLicenseInfo, billingProvider, complianceProvider, policy, product]);

  const changeMessages = React.useMemo(() => {
    const {
      billingChanged,
      complianceChanged,
      complianceInfoChanged,
      previousBillingProvider,
      nextBillingProvider,
      previousComplianceProvider,
      nextComplianceProvider,
    } = changeTypes;

    const messages = [];

    if (billingChanged) {
      messages.push(
        <>
          You are changing the responsible party for billing activities from{' '}
          <span className="contrast-text">{previousBillingProvider}</span> to{' '}
          <span className="contrast-text">{nextBillingProvider}</span>
        </>
      );
    }

    if (product !== ProductTypes.p100 && complianceChanged) {
      messages.push(
        <>
          Currently,{' '}
          <span className="contrast-text">{previousComplianceProvider}</span> is
          handling your Surplus Compliance, your new Surplus Compliance handler
          will be{' '}
          <span className="contrast-text">{nextComplianceProvider}</span>
        </>
      );
    }

    if (product !== ProductTypes.p100 && complianceInfoChanged) {
      messages.push(
        <>
          We're confirming that you have made changes to either the Agency
          information and/or the Surplus Lines Broker information.
        </>
      );
    }

    return messages;
  }, [changeTypes, product]);

  if (
    !changeTypes.billingChanged &&
    !changeTypes.complianceChanged &&
    !changeTypes.complianceInfoChanged
  ) {
    return (
      <>
        <Typography
          variant="h2"
          className="great-primer-text text-center contrast-text"
          paragraph
        >
          Summary of Changes
        </Typography>
        <Typography variant="h3" className="primer-text text-center" paragraph>
          You haven't changed either billing or compliance, either confirm down
          below or restart this process to make a change.
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography
        variant="h2"
        className="great-primer-text text-center contrast-text"
        paragraph
      >
        Summary of Changes
      </Typography>
      <Box>
        {changeMessages.map((message) => (
          <Typography
            variant="h3"
            className="primer-text text-center"
            paragraph
          >
            {message}
          </Typography>
        ))}
      </Box>
      <Typography
        variant="h3"
        className="primer-text text-center contrast-text"
      >
        Are you sure you want to make these changes?
      </Typography>
    </>
  );
};

function pullSurplusAgentInfo(data) {
  return _.pick(data, [
    'agencyMailingAddress1',
    'agencyMailingCity',
    'agencyMailingState',
    'agencyMailingZipCode',
    'surplusLinesBrokerFirstName',
    'surplusLinesBrokerLastName',
    'surplusLinesState',
    'surplusLicenseNumber',
    'surplusLinesBrokerEmail',
  ]);
}
