import { useTheme, Box, Divider, Typography } from '@mui/material';
import Moment from 'moment';
import _ from 'lodash';
import SimpleTooltip from '../../SimpleTooltip';

export const BORIndicatorCell = ({ children, policy = {} }) => {
  const theme = useTheme();

  const color = policy.isBOR
    ? theme.config.colors.cowbellBlue
    : theme.palette.error.light;

  if (!policy.brokerOfRecord) {
    return children;
  }

  return (
    <SimpleTooltip title={<BORIndicatorTooltip policy={policy} />}>
      <Box style={{ color, fontWeight: 'bold' }}>{children}</Box>
    </SimpleTooltip>
  );
};

const BORIndicatorTooltip = ({ policy = {} }) => {
  const text = {
    title: policy.isBOR ? 'BOR Processed' : 'BOR in Process',
    originalAgencyLabel: policy.isBOR ? 'Previous Agency:' : 'Current Agency:',
    originalAgentLabel: policy.isBOR ? 'Previous Agent:' : 'Current Agent:',
    newAgencyLabel: policy.isBOR ? 'Current Agency:' : 'New Agency:',
    newAgentLabel: policy.isBOR ? 'Current Agent:' : 'New Agent:',
  };

  const {
    agentFirstName: originalAgentFirstName,
    agentLastName: originalAgentLastName,
    agencyName: originalAgencyName,
  } = policy;
  const {
    agentFirstName: newAgentFirstName,
    agentLastName: newAgentLastName,
    agencyName: newAgencyName,
    created: receivedOnDate,
  } = _.get(policy, 'brokerOfRecord', {});

  const receivedOnDateString = receivedOnDate
    ? Moment.utc(receivedOnDate).format('M/D/YYYY hh:mm A')
    : '-';

  return (
    <Box>
      <h3 style={{ textAlign: 'center' }}>{text.title}</h3>
      <Divider style={{ margin: '1rem' }} />
      <LineItem title={text.originalAgencyLabel} content={originalAgencyName} />
      <LineItem
        title={text.originalAgentLabel}
        content={`${originalAgentFirstName} ${originalAgentLastName}`}
      />
      <Divider style={{ margin: '1rem' }} />
      <LineItem title={text.newAgencyLabel} content={newAgencyName} />
      <LineItem
        title={text.newAgentLabel}
        content={`${newAgentFirstName} ${newAgentLastName}`}
      />
      <Divider style={{ margin: '1rem' }} />
      <LineItem title="Received On:" content={receivedOnDateString} />
    </Box>
  );
};

const LineItem = ({ title, content }) => (
  <Box display="flex" justifyContent="space-between">
    <Typography variant="body2" style={{ width: '12rem' }}>
      {title}
    </Typography>
    <Typography
      variant="body2"
      style={{
        width: '15rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {content}
    </Typography>
  </Box>
);
