import { prettyId } from './data.utils';

/**
 * Parse the browser url query param of filter modal into an array
 * @param q
 */
export const parseFilterModalQuery = (q) => {
  return Object.keys(q.f).reduce((acc, column) => {
    const filter = q.f[column];
    const reducedOperators = Object.keys(filter).reduce((a, operator) => {
      if (Number.isInteger(Number(operator))) {
        return a;
      }

      if (['in', 'ni', 'bo', 'ex'].includes(operator)) {
        return [
          ...a,
          {
            id: prettyId(),
            column,
            operator,
            value:
              typeof filter[operator] === 'string'
                ? filter[operator].split(',')
                : filter[operator],
          },
        ];
      }

      return [
        ...a,
        {
          id: prettyId(),
          column,
          operator,
          value: filter[operator],
        },
      ];
    }, []);

    return [...acc, ...reducedOperators];
  }, []);
};
