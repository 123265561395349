import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

// components
import { Box, Divider, Grid, Typography, styled } from '@mui/material';

import { InputLabelBase } from '../../../../components/inputs/InputLabelBase';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import {
  ManagedTypeAheadBase,
  SimpleSelect,
} from '../../../../components/inputs';

import { withFormController } from '../../../../components/hocs/forms';

// other
import StateOptions from '../../../_statics/states.statics.json';
import { getCowbellAgentSurplusLineLicenses } from '../../../agencies/_services/licenses.service';

import { SURPLUS_COMPLIANCE_INFORMATION_STEP } from './billing-compliance.consts';
import { globalSchema } from '../../../../components/globalValidations';

export const AgentSurplusLicenseForm = ({ agencyName, onSubmit, policy }) => {
  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      surplusBrokerData: policy,
    },
    resolver: yupResolver(validationSchema),
  });

  const surplusLinesState = methods.watch(
    'surplusBrokerData.surplusLinesState'
  );

  function onBrokerOnFileChange(nextBroker) {
    const { meta } = nextBroker;
    const current = methods.getValues();

    methods.reset({
      ...current,
      surplusBrokerData: {
        ...current.surplusBrokerData,
        surplusLinesBrokerFirstName: meta.brokerFirstName,
        surplusLinesBrokerLastName: meta.brokerLastName,
        surplusLinesBrokerEmail: meta.brokerEmail,
        surplusLinesState: meta.surplusLinesState?.toUpperCase(),
        surplusLicenseNumber: meta.surplusLicenseNumber,
      },
    });
  }

  function handleStateChange(event) {
    const { name, value } = event.target;
    methods.setValue(name, value);
  }

  function handleFormSubmit(values) {
    if (typeof onSubmit === 'function') {
      onSubmit(values);
    }
  }

  return (
    <>
      <Box mb={6} textAlign="center">
        <Typography variant="h2" className="great-primer-text">
          Agency Information
        </Typography>
        <Typography className="pt5">{agencyName}</Typography>
      </Box>
      <FormProvider {...methods}>
        <form
          id={SURPLUS_COMPLIANCE_INFORMATION_STEP}
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Grid container spacing={4}>
            <Grid item md={6} className="pvn">
              <TextField
                name="surplusBrokerData.agencyMailingAddress1"
                label="Address"
                required
                placeholder="Street"
              />
            </Grid>
            <Grid item md={6} className="pvn">
              <TextField
                name="surplusBrokerData.agencyMailingAddress2"
                label="Address cont."
                placeholder="Suite #, Bld #"
              />
            </Grid>
            <Grid item md={6} className="pvn">
              <TextField
                name="surplusBrokerData.agencyMailingCity"
                label="City"
                required
              />
            </Grid>
            <Grid item md={3} className="pvn">
              <InputLabelBase required indent className="pt5">
                State
              </InputLabelBase>
              <SelectField
                name="surplusBrokerData.agencyMailingState"
                required
                options={StateOptions.abbreviated}
              />
            </Grid>
            <Grid item md={3} className="pvn">
              <TextField
                name="surplusBrokerData.agencyMailingZipCode"
                label="Zip code"
                required
              />
            </Grid>
          </Grid>

          <Box pt={4} pb={4}>
            <Divider />
          </Box>

          <Box mb={6} textAlign="center">
            <Typography variant="h2" className="great-primer-text">
              Surplus Lines Broker Information
            </Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item md={12} className="pbn">
              <ControlledSurplusBrokerTypeAhead
                name="broker"
                label="Surplus Lines Brokers on File"
                fetchOnMount
                ref={null}
                placeholder="Search for broker by name"
                error={methods.formState.errors?.broker?.value?.message}
                onChange={onBrokerOnFileChange}
                agencyId={policy.agencyId}
                surplusLinesState={policy.surplusLinesState}
              />
            </Grid>
            <Grid item md={6} className="pbn">
              <TextField
                name="surplusBrokerData.surplusLinesBrokerFirstName"
                label="Surplus Lines Broker First Name"
                required
              />
            </Grid>
            <Grid item md={6} className="pbn">
              <TextField
                name="surplusBrokerData.surplusLinesBrokerLastName"
                label="Surplus Lines Broker Last Name"
                required
              />
            </Grid>
            <Grid item md={2} className="pvn">
              <InputLabelBase required indent className="pt5">
                State
              </InputLabelBase>
              <SelectField
                name="surplusBrokerData.surplusLinesState"
                required
                options={StateOptions.abbreviated}
                onChange={handleStateChange}
                value={surplusLinesState}
              />
            </Grid>
            <Grid item md={4} className="pvn">
              <TextField
                name="surplusBrokerData.surplusLicenseNumber"
                label="Surplus Lines Broker License #"
                required
              />
            </Grid>
            <Grid item md={6} className="pvn">
              <TextField
                name="surplusBrokerData.surplusLinesBrokerEmail"
                label="Surplus Lines Broker Email"
                required
                placeholder="example@domain.com"
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

const TextField = withFormController(TextFieldBase);
// const SelectField = withFormController(Select);
const SelectField = withFormController(
  styled(SimpleSelect)(({ theme }) => ({
    backgroundColor: theme.palette.background.modal,
  }))
);

const validationSchema = Yup.object().shape({
  surplusBrokerData: Yup.object().shape({
    agencyMailingAddress1: Yup.string()
      .label('Agency Mailing Address')
      .required(),
    agencyMailingCity: globalSchema.city.label('Agency Mailing City'),
    agencyMailingState: Yup.string().label('Agency Mailing State').required(),
    agencyMailingZipCode: globalSchema.zipCode.label('Agency Mailing ZipCode'),
    surplusLinesBrokerFirstName: Yup.string()
      .label('Broker First Name')
      .required(),
    surplusLinesBrokerLastName: Yup.string()
      .label('Broker Last Name')
      .required(),
    surplusLinesState: Yup.string().label('Broker State').required(),
    surplusLicenseNumber: Yup.string().label('Broker License #').required(),
    surplusLinesBrokerEmail: Yup.string().label('Broker Email').required(),
  }),
});

export const SurplusBrokerTypeAhead = ({
  label,
  surplusLinesState,
  agencyId,
  ...props
}) => {
  const handleSearch = React.useCallback(
    ({ input }, callback) => {
      return getCowbellAgentSurplusLineLicenses({
        surplusLinesState,
        agencyId,
        search: `${input}`,
      }).then((response) =>
        callback(
          response.data.content.map((broker) => ({
            label: `${broker.brokerFirstName} ${broker.brokerLastName} [${broker.brokerEmail}]`,
            value: broker.brokerEmail,
            meta: {
              ...broker,
            },
          }))
        )
      );
    },
    [agencyId, surplusLinesState]
  );

  return (
    <>
      <InputLabelBase
        indent
        htmlFor={props.name}
        required={props.required}
        style={{ paddingTop: 0 }}
      >
        {label || 'Agency'}
      </InputLabelBase>
      <ManagedTypeAheadBase onFetch={handleSearch} {...props} />
    </>
  );
};

export const ControlledSurplusBrokerTypeAhead = withFormController(
  SurplusBrokerTypeAhead
);
