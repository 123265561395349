import React from 'react';

import {
  DialogActions,
  DialogContent,
  Box,
  Grid,
  styled,
  CircularProgress,
} from '@mui/material';
import { FormContext, useForm } from 'react-hook-form-4';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import CbButton from '../../Buttons/CbButton';
import { InputLabelBase } from '../../inputs/InputLabelBase';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { PhoneField as PhoneFieldBase } from '../../inputs/PhoneField';
import { withFormController } from '../../hocs/withFormController';
import {
  updatePolicyNonPremiumData,
  fetch250PolicyPageDetails,
} from '../../../policies/PolicyService';
import { TextAreaBase } from '../../inputs/TextAreaBase';
import { delayedEvent } from '../../../utils/eventUtils';
import { COWBELL_POLICY } from '../../tables/table_constants';

const validationSchema = Yup.object().shape({
  address1: Yup.string().max(255).label('Address 1').required(),
  address2: Yup.string().max(255).label('Address 2'),
  additionalNamedInsured: Yup.mixed().transform((value) => {
    return value
      ? value
          .replaceAll(/\n/g, ';')
          .split(';')
          .map((str) => str.trim())
          .filter((str) => str)
      : [];
  }),
  customerEmail: Yup.string()
    .email('Please enter a valid email')
    .label('Customer Email')
    .nullable()
    .transform((value) => (value === '' ? [] : value)),
  companyName: Yup.string().max(255).label('Company Name').required(),
  customerFirstName: Yup.string().max(255).label('Customer First Name'),
  customerLastName: Yup.string().max(255).label('Customer Last Name'),
  customerPhoneNumber: Yup.string()
    .max(255)
    .transform((value) => value.match(/\d/g).join(''))
    .label('Customer Phone'),
  dbaOrTradestyle: Yup.string().max(255).label('DBA'),
  city: Yup.string().max(255).label('City').required(),
  zipCode: Yup.string()
    .label('Zip Code')
    .matches(/^\d{5}(-\d{4})?(?!-)$/, 'Invalid ZIP Code')
    .test('Is ZIP 00000', 'Invalid ZIP Code', (value) => value != '00000')
    .required(),
});

const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);
const TextArea = withFormController(TextAreaBase);

const inputProps = {
  style: {
    border: 'none',
  },
};

export const UpdatePolicyModal = ({ data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { isLoading: policyLoading, data: policy } = useQuery(
    ['policy', 'details', data.policyId],
    () =>
      fetch250PolicyPageDetails(data.policyId).then(({ responseData }) => {
        return {
          ..._.pick(responseData, [
            'address1',
            'address2',
            'city',
            'customerEmail',
            'customerFirstName',
            'customerLastName',
            'dbaOrTradestyle',
            'zipCode',
            'state',
          ]),
          customerPhoneNumber: _.get(responseData, 'customerPhone'),
          companyName: _.get(responseData, 'agencyName'),
          additionalNamedInsured: _.get(
            responseData,
            'initialRequestData.additionalNamedInsured'
          ),
        };
      })
  );

  const {
    handleSubmit,
    reset,
    formState: { dirty },
    ...methods
  } = useForm({ validationSchema });

  React.useEffect(() => {
    let additionalNamedInsured = _.get(policy, 'additionalNamedInsured');
    if (additionalNamedInsured) {
      const additionalNamedInsuredMatrix = _.chunk(additionalNamedInsured, 5);
      additionalNamedInsured = additionalNamedInsuredMatrix.reduce(
        (acc, cur) => `${acc + cur.join('; ')}\n`,
        ''
      );
    }
    reset({ ...policy, additionalNamedInsured });
  }, [policy, reset]);

  const onSubmit = (formValues) => {
    return updatePolicyNonPremiumData({ policyId: data.policyId }, formValues)
      .then(() => {
        queryClient.invalidateQueries([COWBELL_POLICY]);
        queryClient.invalidateQueries(['policy', 'details', data.policyId]);
        enqueueSnackbar('Policy Updated Successfully', { variant: 'success' });
        reset(formValues);
        props.close();
      })
      .catch(() => {
        reset(policy);
        enqueueSnackbar('An error has occurred please try again later', {
          variant: 'error',
        });
      });
  };

  if (policyLoading) {
    return (
      <LoadingContainer>
        <CircularProgress size="10rem" />
      </LoadingContainer>
    );
  }

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <Box>
                <InputLabelBase required>Company Name</InputLabelBase>
                <TextField
                  name="companyName"
                  inputProps={inputProps}
                  fullWidth
                  placeholder="Company name"
                />
              </Box>
              <Box>
                <InputLabelBase>Customer First Name</InputLabelBase>
                <TextField
                  name="customerFirstName"
                  inputProps={inputProps}
                  fullWidth
                  placeholder="Company name"
                />
              </Box>
              <Box>
                <InputLabelBase>Customer Last Name</InputLabelBase>
                <TextField
                  name="customerLastName"
                  inputProps={inputProps}
                  fullWidth
                  placeholder="Company name"
                />
              </Box>
              <Box>
                <InputLabelBase className="classes.inputLabelCustom">
                  Customer Phone Number
                </InputLabelBase>
                <PhoneField
                  name="customerPhoneNumber"
                  inputProps={inputProps}
                  fullWidth
                  placeholder="(123) 456-7890"
                />
              </Box>
              <Box>
                <InputLabelBase>Customer Email</InputLabelBase>
                <TextField
                  name="customerEmail"
                  inputProps={inputProps}
                  fullWidth
                  placeholder="Company name"
                />
              </Box>
              <Box>
                <InputLabelBase>DBA</InputLabelBase>
                <TextField
                  name="dbaOrTradestyle"
                  inputProps={inputProps}
                  fullWidth
                  placeholder="DBA"
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box alignItems="flex-start">
                <Box>
                  <Box>
                    <InputLabelBase
                      className="classes.inputLabelCustom"
                      required
                    >
                      Address 1
                    </InputLabelBase>
                    <TextField
                      name="address1"
                      inputProps={inputProps}
                      fullWidth
                      placeholder="123 Main St."
                    />
                  </Box>
                  <Box>
                    <InputLabelBase className="classes.inputLabelCustom">
                      Address 2
                    </InputLabelBase>
                    <TextField
                      name="address2"
                      inputProps={inputProps}
                      fullWidth
                      placeholder="Suite #123"
                    />
                  </Box>
                  <Box display="flex" paddingTop="4px">
                    <Box flexGrow="1">
                      <InputLabelBase
                        className="classes.inputLabelCustom"
                        required
                      >
                        City
                      </InputLabelBase>
                      <TextField
                        name="city"
                        inputProps={inputProps}
                        placeholder="San Marco"
                        disabled={policy.state === 'KY'}
                      />
                    </Box>
                    <Box maxWidth="13%" ml={2}>
                      <InputLabelBase
                        className="classes.inputLabelCustom"
                        required
                      >
                        State
                      </InputLabelBase>
                      <TextField
                        name="state"
                        inputProps={inputProps}
                        disabled
                      />
                    </Box>
                    <Box maxWidth="30%" ml={2}>
                      <InputLabelBase
                        className="classes.inputLabelCustom"
                        required
                      >
                        Zip Code
                      </InputLabelBase>
                      <TextField
                        name="zipCode"
                        inputProps={inputProps}
                        placeholder="92024"
                      />
                    </Box>
                  </Box>
                  <Box>
                    <InputLabelBase>
                      Enter a list of additional named insured separated by a
                      semi-colon or a new line.
                    </InputLabelBase>
                    <StyledTextArea
                      name="additionalNamedInsured"
                      inputProps={inputProps}
                      fullWidth
                      placeholder="E.g: ani1; ani2; ani3;"
                      rows="10"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton onClick={props.close} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton type="submit" disabled={!dirty} styleName="ctaButton">
            Update Policy
          </CbButton>
        </DialogActions>
      </form>
    </FormContext>
  );
};

const LoadingContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  height: '57.5rem',
}));

const StyledTextArea = styled(TextArea)(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.background.modal,
  resize: 'none',

  '&:focus': {
    outline: 'none',
    backgroundColor: theme.palette.background.darkerBlue,
  },
}));

export const UpdatePolicyModalConfig = {
  UpdatePolicyModal: {
    component: UpdatePolicyModal,
    config: {
      fullWidth: true,
      maxWidth: 'md',
      inheritTheme: 'dark',
      title: 'Update Policy',
    },
  },
};
