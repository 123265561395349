import React from 'react';
import { useSnackbar } from 'notistack';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

import { DialogActions, DialogContent, Grid } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import CbButton from '../../../../components/Buttons/CbButton';
import {
  SimpleSelect,
  TextField as TextFieldBase,
} from '../../../../components/inputs';
import { MultiSelect } from '../../../../components/Selects';

import { withFormController } from '../../../../components/hocs/forms';
import { utcForAPI } from '../../../../utils/date.utils';
import {
  getVendorSubcategories,
  getVendorsBySubcategory,
} from '../../../../api/vendor.api';
import { addPanelExpert } from '../../../../api/claims/claims-panel.api';
import { CLAIMS_EXPERTS } from '../../claims.constants';

const TextField = withFormController(TextFieldBase);
const Select = withFormController(SimpleSelect);

const schema = Yup.object().shape({
  vendor: Yup.string().required().label('Vendor'),
  retainedDate: Yup.string().required().label('Retained Date'),
});

const AddEditClaimPanelExpert = ({ data = {}, ...props }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [selection, setSelection] = React.useState([]);
  const [vendorOptions, setVendors] = React.useState([]);

  React.useEffect(() => {
    if (!_.isEmpty(selection)) {
      getVendorsBySubcategory({
        params: { subcategory: mapOptionsToStrings(selection).join(',') },
      }).then((resp) => setVendors(resp.data));
    }
  }, [selection]);

  const defaultValues = React.useMemo(
    () => ({
      ..._.pick(data.panelExpert, ['vendorSubCategories', 'additionalInfo']),
      retainedDate: utcForAPI(_.get(data, 'panelExpert.retainedDate')),
      additionalInfo: '',
    }),
    [data]
  );
  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { data: subcategories } = useQuery(
    ['vendor-subcategories'],
    () =>
      getVendorSubcategories()
        .then((resp) => resp.data)
        .catch(() =>
          enqueueSnackbar(
            'Unable to fetch vendor subcategories, please try again',
            { variant: 'error' }
          )
        ),
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const handleSubcategories = React.useCallback(
    (event, value) => setSelection(value),
    []
  );

  const onSubmit = (formData) => {
    const { vendor: vendorName, ...formRest } = formData;
    const [vendorData] = vendorOptions.filter(
      (vendor) => vendorName === vendor.name
    );

    const basePayload = {
      ...formRest,
      vendorId: vendorData.id,
      vendorSubCategories: vendorData.subCategory,
      vendorName,
    };

    const payload = {
      ...basePayload,
      ..._.pick(data.claim, ['accountId', 'agencyId', 'policyId']),
      claimId: _.get(data, 'claim.id'),
    };

    addPanelExpert({ data: payload }).then(() => {
      queryClient.invalidateQueries([CLAIMS_EXPERTS]);
      queryClient.invalidateQueries([CLAIMS_EXPERTS]);
      enqueueSnackbar('Expert added successfully!', { variant: 'success' });
    });
    props.close();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item md={12} style={{ position: 'relative', top: '1.05rem' }}>
              <MultiSelect
                name="vendorSubCategories"
                label="Expert Type/Subcategory"
                required
                options={mapStringsToOptions(subcategories)}
                onChange={handleSubcategories}
              />
            </Grid>
            <Grid item md={6}>
              <Select
                name="vendor"
                label="Vendor"
                required
                options={mapVendorsToOptions(vendorOptions)}
                disabled={_.isEmpty(vendorOptions)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                name="retainedDate"
                label="Retained Date"
                required
                type="date"
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                name="additionalInfo"
                label="More Information"
                multiline
                minRows={5}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={props.close}>
            Cancel
          </CbButton>
          <CbButton styleName="ctaButton" type="submit">
            Add
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const AddEditClaimPanelExpertConfig = {
  AddEditClaimPanelExpert: {
    component: AddEditClaimPanelExpert,
    config: {
      fullWidth: true,
      title: 'Add/Edit Panel Expert',
    },
  },
};

// helpers
const mapStringsToOptions = (options = []) =>
  options.map((option) => ({ label: option, value: option }));
const mapOptionsToStrings = (options = []) =>
  options.map((option) => _.get(option, 'value'));
const mapVendorsToOptions = (vendors = []) =>
  vendors.map((vendor) => {
    const vendorName = _.get(vendor, 'name');
    return { label: vendorName, value: vendorName };
  });
