import React from 'react';
import classnames from 'classnames';

// components
import { makeStyles } from '@mui/styles';
import {
  RegisteredTypeAhead,
  ManagedTypeAheadBase as TypeAheadBase,
} from '../components/inputs/autocomplete';
import { InputLabelBase } from '../components/inputs/InputLabelBase';
import { withShowable } from '../console/_global/lib/withShowable';

// services
import { fetchNaicsCodes } from './NaicsService';

const defaultLabelProps = {
  style: { paddingTop: 0 },
};

const InputLabel = withShowable(InputLabelBase);

/**
 * @deprecated use IndustryAutoComplete
 */
export const NaicsCodeAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  ...props
}) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(({ input }, callback) => {
    return fetchNaicsCodes({ search: input }).then((response) => {
      const options = response.data.content?.map((industry) => ({
        label: `${industry.naicsCode} ${
          industry.secondaryIndustry && `[${industry.secondaryIndustry}]`
        }`,
        value: industry.naicsCode,
        meta: {
          secondaryIndustry: industry.secondaryIndustry,
          primaryIndustry: industry.primaryIndustry,
        },
      }));

      callback(options ?? []);
    });
  }, []);

  return (
    <>
      <InputLabel
        show={Boolean(label)}
        indent
        htmlFor={props.name}
        required={props.required}
        {...labelProps}
      >
        {label || 'Industry Code'}
      </InputLabel>
      <TypeAheadBase
        id="industryCode"
        className={inputClasses}
        onFetch={handleSearch}
        filterOptions={filterOptions}
        {...props}
      />
    </>
  );
};

/**
 * @deprecated use IndustryAutoComplete
 */
export const RegisteredNaicsCodeAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  ...props
}) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(({ input }, callback) => {
    return fetchNaicsCodes({ search: `${input}` }).then((response) =>
      callback(
        response.data.content.map((industry) => ({
          label: `${industry.naicsCode} ${
            industry.secondaryIndustry && `[${industry.secondaryIndustry}]`
          }`,
          value: industry.naicsCode,
          meta: {
            secondaryIndustry: industry.secondaryIndustry,
            primaryIndustry: industry.primaryIndustry,
          },
        }))
      )
    );
  }, []);

  return (
    <>
      <InputLabel
        show={Boolean(label)}
        indent
        htmlFor={props.name}
        required={props.required}
        {...labelProps}
      >
        {label || 'Industry Code'}
      </InputLabel>
      <RegisteredTypeAhead
        id="industryCode"
        className={inputClasses}
        onFetch={handleSearch}
        filterOptions={filterOptions}
        {...props}
      />
    </>
  );
};

function filterOptions(options) {
  return options;
}

const useStyles = makeStyles(() => ({
  inline: {
    '& input': {
      padding: '6px !important',
    },
  },
}));
