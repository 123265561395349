import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CheckBoxIndeterminateIcon(props) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="m0 0h24v24h-24z" fill="none" />
      <g stroke="#c8ccd9" strokeWidth=".25">
        <g transform="translate(2.5 3)">
          <path d="m0 0h18v18h-18z" fill="none" />
          <path
            d="m0 0v18h18v-18zm16.29 16.29h-14.49v-14.49h14.58z"
            fill="#c9cdd9"
          />
        </g>
        <path
          d="m5.667 8.333h10v2h-10z"
          fill="#c9cdd9"
          transform="translate(.833 2.667)"
        />
      </g>
    </SvgIcon>
  );
}
