// core
import React from 'react';
import Moment from 'moment';
import Numeral from 'numeral';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

// mui
import { Box, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// table components
import { BaseTable } from '../../../../components/tables/dev-express/BaseTable';
import { TableStateProvider } from '../../../../components/tables/dev-express/TableStateContext';
import { TableSearch } from '../../../../components/tables/dev-express/table-components/toolbar/TableSearch';
import { CsvDownloadButton } from '../../../../components/tables/dev-express/table-components/toolbar/CsvDownloadButton';
import { RemotePagingPlugin } from '../../../../components/tables/dev-express/plugins/RemotePagingPlugin';
import { TableFilterSelectV2 } from '../../../../components/tables/tableFilters/v2/TableFilterSelectV2';
import { TableFilterChipsV2 } from '../../../../components/tables/tableFilters/v2/TableFilterChipsV2';
import { FilterModal } from '../../../../components/modals/FilterModal';
import { RefreshButton } from '../../../../components/tables/dev-express/table-components/toolbar/RefreshButton';
import { PrimaryToolbar } from '../../../../components/tables/dev-express/table-components/toolbar/PrimaryToolbar';
import { SecondaryToolbar } from '../../../../components/tables/dev-express/table-components/toolbar/SecondaryToolbar';
import { RowDetailPlugin } from '../../../../components/tables/dev-express/plugins/RowDetailPlugin';

// other components
import { CowbellPoliciesTableRow } from '../../../../components/tables/dev-express/table-components/rowComponents';
import { ColumnChooser } from '../../../../components/tables/dev-express/table-components/toolbar/ColumnChooser';
import { UrlDateRangePicker } from '../../../../components/inputs/DateRangePicker';
import { COWBELL_ENDORSEMENTS } from '../../../../components/tables/table_constants';
import { getFee, prepColumnFilters } from '../../../../utils/data.utils';
import {
  AgencyNameCell,
  BORChip,
  cowbellPolicyDatePresets,
  derivePolicyEffectiveDate,
  derivePolicyEndDate,
  formatUTCDate,
  getAgentCellValue,
  getCompanyNameCellValue,
  getNotesCellValue,
  PolicyNumberCell,
  getStatusCellValue,
  PolicyRowDetail,
} from './helpers';
import AdminAccountsQuoteDrawer, {
  useAdminQuoteDrawer,
} from '../../../accounts/admin/drawers/AdminQuoteDrawer';
import { AdminPolicyOptions } from '../../../../policies/options/AdminPolicyOptions';

// hooks
import { useUiSettingsAutosave } from '../../../../components/tables/dev-express/hooks/useUiSettingsAutosave';
import { useBackfill } from '../../../../components/tables/dev-express/hooks/useBackfill';
import { useQueryHandlers } from '../../../../components/tables/dev-express/hooks/useQueryHandlers';
import { useSavedSettings } from '../../../../components/tables/dev-express/hooks/useSavedSettings';
import { useTableState } from '../../../../components/tables/dev-express/hooks/useTableState';
import { useScrollListener } from '../../../../components/tables/dev-express/hooks/useScrollListener';
import { useQueryParams } from '../../../../components/providers/QueryParamsProvider';

// services + utils
import {
  fetchEndorsementPolicies,
  fetchPolicyStatuses,
} from '../../../../policies/PolicyService';
import {
  getBoolValueText,
  phoneFormatter,
  RenewalOptions,
  useJQuery,
  YesNoOptions,
} from '../../../../utils/appUtils';
import StateStatics from '../../../_statics/states.statics.json';
import ProductStatics from '../../../_statics/product.statics.json';

import { utcForAPI } from '../../../../utils/date.utils';
import { fetchTamsV2 } from '../../../../api/UserService';

import { Allow } from '../../../../components/auth/Allow';
import CowbellEndorsementsStatsBar from './CowbellEndorsementsStatsBar';
import { usePersona } from '../../../../components/hooks/usePersona';
import { getShortDateTimeFromUtc } from '../../../../utils/csvUtils';
import { formatTableCurrencyByRegion } from '../../../../utils/currency.utils';
import { getCurrencySymbolByProduct } from '../../../../i18n/utils';
import { getIsUsPlatform, useGetPlatformRegion } from '../../../../utils';
import { useCowbellTranslations } from '../../../../i18n/translations';
import { Show } from '../../../../components/Show';

const TABLE_ID = COWBELL_ENDORSEMENTS;
const backfill = {
  size: 25,
  order: 'desc',
  orderBy: 'modified',
  before: () => Moment().endOf('day').unix(),
  after: () => Moment().subtract(2, 'year').startOf('day').unix(),
};

export const CowbellEndorsementsListing = () => {
  useScrollListener();

  const { query } = useQueryParams();
  const { queryHandlers } = useQueryHandlers(query);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const theme = useTheme();
  const classes = useStyles();
  const persona = usePersona();
  const columnConfig = useColumnConfig();

  const quoteDrawer = useAdminQuoteDrawer();

  const { uiSettings } = useSavedSettings(TABLE_ID, columnConfig);
  useBackfill(backfill, uiSettings);

  const { state, columns, handlers } = useTableState(columnConfig, {
    uiSettings,
  });

  useUiSettingsAutosave(
    TABLE_ID,
    { columns, columnOrder: state.columnOrder, ...query },
    ['before', 'after']
  );

  const { isLoaded: isJQueryLoaded } = useJQuery();

  const { data, isFetching } = useQuery(
    [TABLE_ID, query],
    () => {
      return fetchEndorsementPolicies(query).then((resp) => ({
        totalCount: resp.data.totalElements,
        rows: resp.data.content,
      }));
    },
    {
      placeholderData: {},
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: () => {
        enqueueSnackbar(
          'There was a problem loading Policy data. Please try again',
          { variant: 'error' }
        );
      },
    }
  );

  const refetchTableData = React.useCallback(() => {
    queryClient.invalidateQueries([TABLE_ID]);
  }, [queryClient]);

  const mergedState = React.useMemo(
    () => ({ ...state, ...query }),
    [query, state]
  );
  const mergedHandlers = React.useMemo(
    () => ({ ...handlers, ...queryHandlers }),
    [handlers, queryHandlers]
  );
  const context = React.useMemo(
    () => ({
      persona,
      theme,
      onOpenDrawer: quoteDrawer.onOpen,
      classes,
      isJQueryLoaded,
      refetchTableData,
    }),
    [
      persona,
      theme,
      quoteDrawer.onOpen,
      classes,
      isJQueryLoaded,
      refetchTableData,
    ]
  );

  const filterColumns = React.useMemo(() => {
    return prepColumnFilters(Object.values(columnConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rows = React.useMemo(() => data?.rows ?? [], [data]);
  const totalCount = React.useMemo(() => data?.totalCount ?? 0, [data]);

  return (
    <>
      <PrimaryToolbar style={{ borderTopLeftRadius: 0 }}>
        <TableFilterSelectV2 tableId={TABLE_ID} />
        <Box display="flex" width="60%">
          <TableSearch />
          <UrlDateRangePicker
            inlineLabel
            customPreset={cowbellPolicyDatePresets}
          />
        </Box>
      </PrimaryToolbar>

      <CowbellEndorsementsStatsBar />

      <TableStateProvider
        state={mergedState}
        handlers={mergedHandlers}
        columns={columns}
        rows={rows}
        tableId={TABLE_ID}
        context={context}
      >
        <SecondaryToolbar>
          <Box display="flex">
            <TableFilterChipsV2 tableId={TABLE_ID} />
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <ColumnChooser />
            <CsvDownloadButton />
            <RefreshButton onClick={refetchTableData} loading={isFetching} />
          </Box>
        </SecondaryToolbar>

        <BaseTable sticky RowComponent={CowbellPoliciesTableRow}>
          <RemotePagingPlugin totalCount={totalCount} />
          <RowDetailPlugin
            rowDetailComponent={(props) => (
              <Allow allow={['policy:view']}>
                <PolicyRowDetail
                  onExpandRow={handlers.onExpandedRowIdsChange}
                  {...props}
                />
              </Allow>
            )}
          />
        </BaseTable>
      </TableStateProvider>
      <FilterModal tableId={TABLE_ID} config={filterColumns} />
      <Show when={Boolean(quoteDrawer.data)}>
        <AdminAccountsQuoteDrawer
          account={quoteDrawer.data}
          open={quoteDrawer.isOpen}
          onClose={quoteDrawer.onClose}
        />
      </Show>
    </>
  );
};

const EMAIL_COL_WIDTH = 190;
const useColumnConfig = () => {
  const region = useGetPlatformRegion();
  const isUsRegion = getIsUsPlatform(region);
  const { translations } = useCowbellTranslations(['Agent', 'Agency']);

  return {
    id: {
      name: 'id',
      title: 'ID',
      show: false,
      width: 'l',
    },
    policyNumber: {
      name: 'policyNumber',
      title: 'Policy Number',
      width: 160,
      fixed: 'left',
      filterType: 'string',
      getCellValue: (row) => <PolicyNumberCell row={row} />,
    },
    isBOR: {
      name: 'isBOR',
      title: 'Is BOR?',
      show: false,
      filterType: 'bool',
      width: 90,
      getListOptions: async () => YesNoOptions,
      getCellValue: (row, context) => <BORChip row={row} context={context} />,
      csv: (row) => getBoolValueText(row.isBOR),
    },
    premium: {
      name: 'premium',
      title: 'Premium',
      filterType: 'number',
      show: false,
      width: 's',
      getCellValue: formatTableCurrencyByRegion()('premium'),
      csv: formatTableCurrencyByRegion()('premium'),
    },
    brokerFee: {
      name: 'brokerFee',
      title: 'Fee',
      filterType: 'number',
      show: false,
      sort: false,
      width: 's',
      getCellValue: (row) => getFee(row),
      csv: (row) => getFee(row),
    },
    totalPremium: {
      name: 'totalPremium',
      title: 'Total Premium',
      filterType: 'number',
      width: 's',
      getCellValue: formatTableCurrencyByRegion()('totalPremium'),
      csv: formatTableCurrencyByRegion()('totalPremium'),
    },
    companyName: {
      name: 'companyName',
      title: 'Company Name',
      width: 'm',
      filterType: 'string',
      ommitInCsv: true,
      getCellValue: getCompanyNameCellValue,
    },
    ...(isUsRegion
      ? {
          state: {
            name: 'state',
            title: 'Account State',
            filterType: 'list',
            sort: false,
            show: false,
            width: 's',
            getListOptions: async () => StateStatics.full,
            getCellValue: (row) =>
              row.firmographicData?.state ? row.firmographicData?.state : '-',
            csv: (row) =>
              row.firmographicData?.state ? row.firmographicData?.state : '',
          },
        }
      : {}),
    customerEmail: {
      name: 'customerEmail',
      title: 'Customer Email',
      filterType: 'string',
      show: false,
      width: 'm',
    },
    customerName: {
      name: 'customerName',
      title: 'Customer Name',
      filterType: 'string',
      width: 190,
      getCellValue: (row) => {
        const { customerFirstName = '', customerLastName = '' } = row;
        return `${customerFirstName} ${customerLastName}`;
      },
      csv: (row) => {
        const { customerFirstName = '', customerLastName = '' } = row;
        return `${customerFirstName} ${customerLastName}`;
      },
    },
    customerPhone: {
      name: 'customerPhone',
      title: 'Customer Phone Number',
      show: false,
      width: 180,
      filterType: 'string',
      getCellValue: (row) => phoneFormatter(row.customerPhone, '-'),
    },
    product: {
      name: 'product',
      title: 'Product',
      show: false,
      filterType: 'list',
      getListOptions: async () => ProductStatics.PRODUCT_OPTIONS,
      getCellValue: (row) =>
        ProductStatics.ProductTypes?.[row.product]?.short ?? row.product,
    },
    isSurplus: {
      name: 'isSurplus',
      title: 'Is Surplus?',
      width: 110,
      show: false,
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => (row.isSurplus ? 'S' : 'A'),
      csv: (row) => (row.isSurplus ? 'S' : 'A'),
    },
    isRenewal: {
      name: 'isRenewal',
      title: 'Is Renewal?',
      filterType: 'bool',
      getListOptions: async () => RenewalOptions,
      width: 110,
      show: false,
      getCellValue: (row) => (row.isRenewal ? 'Renewal' : 'New'),
      csv: (row) => (row.isRenewal ? 'Renewal' : 'New'),
    },
    isMigration: {
      name: 'isMigration',
      title: 'Is Migration?',
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      width: 130,
      show: false,
      getCellValue: (row) => getBoolValueText(row.isMigration),
      csv: (row) => getBoolValueText(row.isMigration),
    },
    isErp: {
      name: 'isErp',
      title: 'Is ERP?',
      show: false,
      width: 110,
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => getBoolValueText(row.isErp),
      csv: (row) => getBoolValueText(row.isErp),
    },
    agencyBilled: {
      name: 'agencyBilled',
      title: `${translations.Agency} Billed?`,
      show: false,
      sort: false,
      width: 110,
      getCellValue: (row) =>
        getBoolValueText(_.get(row, 'firmographicData.agencyBilled', false)),
      csv: (row) =>
        getBoolValueText(_.get(row, 'firmographicData.agencyBilled', false)),
    },
    isPremiumFinanced: {
      name: 'isPremiumFinanced',
      title: 'Premium Financed?',
      show: false,
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => getBoolValueText(row.isPremiumFinanced),
      csv: (row) => getBoolValueText(row.isPremiumFinanced),
    },
    isPremiumFinancePaid: {
      name: 'isPremiumFinancePaid',
      title: 'Premium Financed Paid?',
      show: false,
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => getBoolValueText(row.isPremiumFinancePaid),
      csv: (row) => getBoolValueText(row.isPremiumFinancePaid),
    },
    isPaid: {
      name: 'isPaid',
      title: 'Is Paid?',
      show: false,
      width: 110,
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => getBoolValueText(row.isPaid),
      csv: (row) => getBoolValueText(row.isPaid),
    },
    isPrimePlus: {
      name: 'isPrimePlus',
      title: 'Is Excess?',
      filterType: 'bool',
      show: false,
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => getBoolValueText(row.isPrimePlus),
      csv: (row) => getBoolValueText(row.isPrimePlus),
    },
    isInvoiceSettled: {
      name: 'isInvoiceSettled',
      title: 'Is Invoice Settled?',
      filterType: 'bool',
      show: false,
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => getBoolValueText(row.isInvoiceSettled),
      csv: (row) => getBoolValueText(row.isInvoiceSettled),
    },
    isOpenForRenewal: {
      name: 'isOpenForRenewal',
      title: 'Is Open for Renewal?',
      filterType: 'bool',
      show: false,
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => getBoolValueText(row.isOpenForRenewal),
      csv: (row) => getBoolValueText(row.isOpenForRenewal),
    },
    agencyId: {
      name: 'agencyId',
      title: `${translations.Agency} ID`,
      filterType: 'string',
      show: false,
      sort: false,
      width: 'l',
      getCellValue: (row) => {
        if (row.brokerOfRecord && row.isBOR) {
          return row.brokerOfRecord.agencyId;
        }
        return row.agencyId;
      },
    },
    agencyName: {
      name: 'agencyName',
      title: `${translations.Agency} Name`,
      filterType: 'string',
      width: 200,
      getCellValue: (row) => <AgencyNameCell policy={row} />,
    },
    agent: {
      name: 'agent',
      title: translations.Agent,
      show: false,
      getCellValue: getAgentCellValue,
      csv: getAgentCellValue,
    },
    agentEmail: {
      name: 'agentEmail',
      title: `${translations.Agent} Email`,
      filterType: 'string',
      show: false,
      width: 'm',
      getCellValue: (row) => row.agentEmail || '-',
    },
    description: {
      name: 'description',
      title: 'Description',
      width: 'm',
      filterType: 'string',
      getCellValue: (row) => {
        let desc = row.description || '-';
        desc = desc.length > 100 ? `${desc.substr(0, 98)} ...` : desc;
        return desc;
      },
    },
    effectiveDate: {
      name: 'effectiveDate',
      title: 'Policy Effective',
      filterType: 'date',
      show: false,
      getCellValue: (row) =>
        derivePolicyEffectiveDate(row, getShortDateTimeFromUtc),
      csv: derivePolicyEffectiveDate,
    },
    endDate: {
      name: 'endDate',
      title: 'Policy Expiration',
      filterType: 'date',
      show: false,
      getCellValue: (row) => derivePolicyEndDate(row, getShortDateTimeFromUtc),
      csv: derivePolicyEndDate,
    },
    reportingMonth: {
      name: 'reportingMonth',
      title: 'Reporting Month',
      show: false,
      filterType: 'date',
      getCellValue: (row) =>
        row.reportingMonth ? formatUTCDate(row.reportingMonth, true) : '-',
      csv: (row) =>
        row.reportingMonth ? formatUTCDate(row.reportingMonth, true) : '',
    },
    cancellationDate: {
      name: 'cancellationDate',
      title: 'Cancellation Effective Date',
      filterType: 'date',
      show: false,
      getCellValue: (row) =>
        row.cancellationDate ? formatUTCDate(row.cancellationDate) : '-',
      csv: (row) =>
        row.cancellationDate ? formatUTCDate(row.cancellationDate) : '',
    },
    isNocSent: {
      name: 'isNocSent',
      title: 'Is NOC Sent?',
      show: false,
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => getBoolValueText(row.isNocSent),
      csv: (row) => getBoolValueText(row.isNocSent),
    },
    cancellationTriggerDate: {
      name: 'cancellationTriggerDate',
      title: 'NOC Trigger Date',
      show: false,
      getCellValue: (row) =>
        row.cancellationTriggerDate
          ? formatUTCDate(row.cancellationTriggerDate)
          : '-',
      csv: (row) =>
        row.cancellationTriggerDate
          ? utcForAPI(row.cancellationTriggerDate)
          : '',
    },
    timestamp: {
      name: 'timestamp',
      title: 'Created',
      show: false,
      getCellValue: (row) =>
        row.created ? formatUTCDate(row.created, true) : '-',
      csv: (row) => (row.created ? formatUTCDate(row.created, true) : ''),
    },
    boundOn: {
      name: 'boundOn',
      title: 'Bound',
      filterType: 'date',
      show: false,
      getCellValue: (row) => (row.boundOn ? formatUTCDate(row.boundOn) : '-'),
      csv: (row) => (row.boundOn ? formatUTCDate(row.boundOn) : ''),
    },
    issuedDate: {
      name: 'issuedDate',
      title: 'Issued',
      filterType: 'date',
      show: false,
      getCellValue: (row) =>
        row.issuedDate ? formatUTCDate(row.issuedDate) : '-',
      csv: (row) => (row.issuedDate ? formatUTCDate(row.issuedDate) : ''),
    },
    limit: {
      name: 'limit',
      title: 'Limit',
      width: 80,
      filterType: 'number',
      getCellValue: (row) => {
        const currencySymbol = getCurrencySymbolByProduct(row.product);
        return row.limit
          ? `${currencySymbol}${Numeral(row.limit).format('0a')}`
          : '-';
      },
      csv: (row) => {
        const currencySymbol = getCurrencySymbolByProduct(row.product);
        return row.limit
          ? `${currencySymbol}${Numeral(row.limit).format('0a')}`
          : '-';
      },
    },
    modified: {
      name: 'modified',
      title: 'Modified',
      filterType: 'date',
      getCellValue: (row) =>
        row.modified ? Moment.utc(row.modified).fromNow() : '-',
      csv: (row) => (row.modified ? formatUTCDate(row.modified) : ''),
    },
    quoteNumber: {
      name: 'quoteNumber',
      title: 'Quote Number',
      show: false,
      filterType: 'string',
      getCellValue: (row, context) => {
        const { classes } = context;

        if (!row.quoteNumber) {
          return '-';
        }

        return (
          <a
            className={classes.link}
            href={`/admin/quotes?search=${row.quoteNumber}`}
          >
            {row.quoteNumber}
          </a>
        );
      },
    },
    status: {
      name: 'status',
      title: 'Status',
      filterType: 'list',
      getListOptions: () =>
        fetchPolicyStatuses()
          .then(({ data }) => {
            if (data.length) {
              return data.map((el) => ({ label: el, value: el }));
            }
            return [];
          })
          .catch(() => []),
      getCellValue: getStatusCellValue,
    },
    notes: {
      name: 'notes',
      title: 'Notes',
      canToggle: false,
      align: 'center',
      width: 85,
      sort: false,
      getCellValue: getNotesCellValue,
      ommitInCsv: true,
    },
    ...(isUsRegion
      ? {
          stateTax: {
            name: 'stateTax',
            title: 'State Tax',
            show: false,
            filterType: 'number',
            getCellValue: formatTableCurrencyByRegion()('stateTax'),
            csv: formatTableCurrencyByRegion()('stateTax'),
          },
        }
      : {}),
    aeEmail: {
      name: 'aeEmail',
      title: 'AE Email',
      show: false,
      width: EMAIL_COL_WIDTH,
      getCellValue: (row) => (row.aeEmail ? row.aeEmail : '-'),
    },
    bdsEmail: {
      name: 'bdsEmail',
      title: 'BDS Email',
      show: false,
      width: EMAIL_COL_WIDTH,
      getCellValue: (row) => (row.bdsEmail ? row.bdsEmail : '-'),
    },
    tamEmail: {
      name: 'tamEmail',
      title: 'TSM Email',
      show: false,
      width: 'm',
      filterType: 'list',
      getListOptions: () =>
        fetchTamsV2({ size: 100 })
          .then(({ data }) => {
            if (data.content.length) {
              return data.content.map(({ email }) => ({
                label: email,
                value: email,
              }));
            }
            return [];
          })
          .catch(() => []),
      getCellValue: (row) => (row.tamEmail ? row.tamEmail : '-'),
    },
    uwEmail: {
      name: 'uwEmail',
      title: 'UW Email',
      show: false,
      width: 'm',
      filterType: 'string',
      getCellValue: (row) => (row.uwEmail ? row.uwEmail : '-'),
    },
    submissionId: {
      name: 'submissionId',
      title: 'Submission ID',
      width: 'm',
      filterType: 'string',
      show: false,
      getCellValue: (row) => row.submissionId ?? '-',
    },
    options: {
      name: 'options',
      width: 'xxs',
      canToggle: false,
      sort: false,
      disableExpandRow: true,
      ommitInCsv: true,
      fixed: 'right',
      align: 'center',
      getCellValue: (row, context) => {
        const { isJQueryLoaded, refetchTableData, persona } = context;
        const unavailableStatuses = ['INVALID', 'REQUESTED', 'FLAT CANCELLED'];
        if (
          unavailableStatuses.includes(row.status) ||
          (persona.isCluster && row.isBOR)
        ) {
          return null;
        }

        return (
          <AdminPolicyOptions
            data={row}
            handleTableRefetch={refetchTableData}
            setShowCircular={() => {}}
            isJQueryLoaded={isJQueryLoaded}
          />
        );
      },
    },
  };
};

const useStyles = makeStyles(() => ({
  link: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
