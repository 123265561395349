import React from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import clsx from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

//lodash
import _ from 'lodash';
import Numeral from 'numeral';

//mui
import { Box, CardContent, Divider, Drawer, styled, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TabContext, TabList, TabPanel as MuiTabPanel } from '@mui/lab';
import { number } from 'prop-types';
import { EXPIRED_STATUS } from '../../../_statics/policy.statics';
import {
  BOUND_STATUS,
  READY_STATUS,
  REFERRED_STATUS,
  IN_REVIEW_STATUS,
  DECLINED_STATUS,
} from '../../../_statics/quote.statics';

//components
import QuoteTabView from './tabs/QuoteTabView';
import BinderTabView from './tabs/BinderTabView';

//services
import { searchQuotes } from '../../../../api';
import { searchPolicies } from '../../../../api/policies.api';
import { fetchBinders } from '../../../../inbox/QuotesService';
import PoliciesTabView from './tabs/PoliciesTabView';

import type { BaseQuoteDetailsDto } from '../../../../types/quotes/details/base-quote-details.dto';
import { regionCurrencyToTenthsPlace } from '../../../../utils/currency.utils';
import { toUniversalUtcDate } from '../../../../utils/date.utils';
import { getRegionByProduct } from '../../../../i18n/utils';
import { deriveCurrencyFormat } from '../../../../i18n/currencies';

interface AdminAccountsQuoteDrawerProps {
  open: boolean;
  account: {
    id: string;
    name: string;
  };
  onClose: () => void;
}

interface UiQuoteDTO extends BaseQuoteDetailsDto {
  ui_quoteNumber: string;
  ui_limit: string;
  ui_deductible: string;
  ui_premium: string;
  ui_effectiveDate: string;
  ui_endDate: string;
  ui_status: string;
}

enum TabNames {
  QUOTES = 'Quotes',
  BINDERS = 'Binders',
  POLICIES = 'Policies',
}

const AdminAccountsQuoteDrawer: React.FC<AdminAccountsQuoteDrawerProps> = ({
  open,
  onClose,
  account,
}) => {
  const [value, setValue] = React.useState(TabNames.QUOTES);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };

  const {
    data: accountQuoteDrawerData = {
      formattedQuotesData: [],
      formattedPolicies: [],
      formattedBinders: [],
    },
    isInitialLoading: drawerDataLoading,
  } = useQuery<
    {
      formattedQuotesData: UiQuoteDTO[];
      formattedPolicies: any[];
      formattedBinders: any[];
    },
    Error
  >(
    ['quote-drawer-data', account.id],
    () => {
      return Promise.all([
        searchQuotes({
          params: {
            search: account.id,
            f: {
              agencyStatus: {
                in: [
                  BOUND_STATUS,
                  READY_STATUS,
                  REFERRED_STATUS,
                  IN_REVIEW_STATUS,
                  DECLINED_STATUS,
                  EXPIRED_STATUS,
                ],
              },
            },
          },
        }),
        searchPolicies({
          params: {
            search: account.id,
            after: UNIX_THREE_YEARS_IN_PAST,
          },
        }),
        fetchBinders({
          search: account.id,
        }),
      ]).then((responses) => {
        return formatDataStructure({
          quotesData: responses[0].data.content,
          policiesData: responses[1].data.content,
          binderData: responses[2].data.content,
        });
      });
    },
    {
      enabled: !!account.id,
      refetchOnWindowFocus: false,
      onError: (err) => {
        return enqueueSnackbar(
          _.get(
            err,
            'message',
            'Error retrieving drawer data. Please try again later.'
          ),
          { variant: 'error' }
        );
      },
    }
  );

  const formattedQuotesData = accountQuoteDrawerData?.formattedQuotesData;
  const formattedPolicies = accountQuoteDrawerData?.formattedPolicies;
  const formattedBinders = accountQuoteDrawerData?.formattedBinders;

  return (
    <Drawer anchor="right" open={open} classes={classes} onClose={onClose}>
      <CardContent>
        <Box className={classes.companyHeader} style={{ textAlign: 'center' }}>
          <Link
            className="link-underlined--hover"
            to={`/admin/account/${account.id}`}
          >
            {account.name}
          </Link>
        </Box>
        <Box style={{ width: '100%' }}>
          <TabContext value={value}>
            <Box>
              <TabList
                TabIndicatorProps={{ style: { color: 'blue !important' } }}
                onChange={handleTabChange as any}
              >
                <Tab
                  disableRipple
                  className={
                    classes[determineStyling(value === TabNames.QUOTES)]
                  }
                  label="Quotes"
                  value={TabNames.QUOTES}
                />
                <Tab
                  disableRipple
                  className={
                    classes[determineStyling(value === TabNames.BINDERS)]
                  }
                  label="Binders"
                  value={TabNames.BINDERS}
                />
                <Tab
                  disableRipple
                  className={
                    classes[determineStyling(value === TabNames.POLICIES)]
                  }
                  label="Policies"
                  value={TabNames.POLICIES}
                />
              </TabList>
              <Divider />
            </Box>
            <TabPanel value={TabNames.QUOTES}>
              <QuoteTabView
                isLoading={drawerDataLoading}
                quotes={formattedQuotesData}
              />
            </TabPanel>
            <TabPanel value={TabNames.BINDERS}>
              <BinderTabView
                isLoading={drawerDataLoading}
                binders={formattedBinders}
              />
            </TabPanel>
            <TabPanel value={TabNames.POLICIES}>
              <PoliciesTabView
                isLoading={drawerDataLoading}
                policies={formattedPolicies}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </CardContent>
    </Drawer>
  );
};

export default AdminAccountsQuoteDrawer;

const TabPanel = styled(MuiTabPanel)({
  paddingRight: 0,
  paddingLeft: 0,
});

const expirationDatePredicate = (quote: any) =>
  moment(quote.expiresOn).isAfter(moment().subtract(120, 'd'));

//helper functions
const formatDataStructure = (dataObj: any) => {
  const { quotesData = [], policiesData = [], binderData = [] } = dataObj;
  const formattedQuotesData = quotesData
    .filter(expirationDatePredicate)
    .map((quote: any) => {
      const language = getRegionByProduct(quote.product);
      return {
        ...quote,
        ui_quoteNumber: quote.quoteNumber,
        ui_limit: quote.aggLimit
          ? regionCurrencyToTenthsPlace(language)(quote.aggLimit)
          : '-',
        ui_deductible: quote.deductible
          ? regionCurrencyToTenthsPlace(language)(quote.deductible)
          : '-',
        ui_premium: quote.totalPremium
          ? deriveCurrencyFormat(language, quote.totalPremium)
          : '-',
        ui_effectiveDate: toUniversalUtcDate(quote.effectiveDate),
        ui_endDate: toUniversalUtcDate(quote.endDate),
        ui_status: quote.agencyStatus
          ? quote.agencyStatus.split('_').join(' ')
          : '-',
        created: moment.utc(quote.created).unix(),
      };
    });
  const formattedPolicies = policiesData.map((policy: any) => {
    const language = getRegionByProduct(policy.product);
    return {
      ...policy,
      ui_policyNumber: policy.policyNumber,
      ui_limit: policy.limit
        ? regionCurrencyToTenthsPlace(language)(policy.limit)
        : '-',
      ui_deductible: policy.deductible
        ? regionCurrencyToTenthsPlace(language)(policy.deductible)
        : '-',
      ui_premium: policy.totalPremium
        ? deriveCurrencyFormat(language, policy.totalPremium)
        : '-',
      ui_effectiveDate: toUniversalUtcDate(policy.effectiveDate),
      ui_endDate: toUniversalUtcDate(policy.endDate),
      ui_status: policy.status,
      created: moment(policy.created).unix(),
    };
  });
  const formattedBinders = binderData.map((binder: any) => {
    const language = getRegionByProduct(binder.product);
    return {
      ...binder,
      ui_binderNumber: binder.quoteNumber || '-',
      ui_limit: binder.limit
        ? regionCurrencyToTenthsPlace(language)(binder.limit)
        : '-',
      ui_premium: binder.premium
        ? deriveCurrencyFormat(language, binder.premium)
        : '-',
      ui_status: binder.status,
      boundOn: moment(binder.boundOn).unix(),
    };
  });

  return { formattedQuotesData, formattedPolicies, formattedBinders };
};

const determineStyling = (condition: boolean) => {
  return clsx({
    activeTab: condition,
  });
};

//styles
const useStyles = makeStyles<any>(({ palette, config }) => ({
  paper: {
    maxWidth: '60.84rem',
    width: '66.666666666%',
    height: 'fit-content',
    maxHeight: '62.34rem',
    marginTop: 15,
    marginRight: 15,
    color: palette.primary.main,
    backgroundColor: palette.background.paper,
    border: `1px solid ${palette.primary.border2}`,
    borderRadius: '0.5rem',
  },
  header: {
    fontSize: config.textSizes.tertia,
    textTransform: 'uppercase',
    margin: 0,
    marginBottom: '1.15rem',
    borderBottom: `1px solid ${palette.primary.modalBorder}`,
  },
  companyHeader: {
    fontSize: config.textSizes.tertia,
    textTransform: 'uppercase',
  },
  activeTab: {
    color: `${config.colors.cowbellBlue} !important`,
  },
}));

const UNIX_THREE_YEARS_IN_PAST = moment()
  .subtract(3, 'years')
  .startOf('day')
  .unix();

type AccountData = AdminAccountsQuoteDrawerProps['account'];

export const useAdminQuoteDrawer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [accountDataFromRow, setAccountDataFromRow] =
    React.useState<AccountData>();

  const onOpen = React.useCallback((rowData: AccountData) => {
    const isRowAccountData =
      typeof rowData?.name == 'string' && typeof rowData?.id == 'string';

    if (isRowAccountData) {
      setAccountDataFromRow(rowData as AccountData);
    }

    setIsOpen(true);
  }, []);

  const onClose = () => {
    setIsOpen(false);
  };

  return {
    onOpen,
    onClose,
    isOpen,
    data: accountDataFromRow,
  };
};
