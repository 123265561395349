import * as Yup from 'yup';

// mui
import { Box, DialogActions, DialogContent } from '@mui/material';

// hooks
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAPIErrorHandler } from '../../../hooks/useAPIErrorHandler';

// helpers
import { Modal } from '../helpers/v2.modal.helpers';

// hocs
import { withFormController } from '../../../hocs/forms';

import CbButton from '../../../Buttons/CbButton';
import { InputLabelBase } from '../../../inputs/InputLabelBase';
import { TextAreaBase } from '../../../inputs/TextAreaBase';

import { waiveSubjectivity } from '../../../../inbox/QuotesService';
import {
  SubjectivitySubType,
  SubjectivitySubTypeLabelMap,
} from '../../../../console/workflows/bind-quote/utils/bindQuoteWorkFlowUtils';

const TextArea = withFormController(TextAreaBase);

type SubType = keyof typeof SubjectivitySubType;

interface WaiveSubjectivityModalProps {
  data: {
    subjectivityId: string;
    description: string;
    subType: SubType;
    underwritingSubjectivitiesModalData: any;
  };
  close: () => void;
}

export const WaiveSubjectivityModal = ({
  data: modalData,
  close,
}: WaiveSubjectivityModalProps) => {
  const queryClient = useQueryClient();
  const handleAPIError = useAPIErrorHandler();

  const formMethods = useForm<FormValues>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(formSchema),
  });

  const onConfirm = (formValues: FormValues) => {
    // TODO: This is a stubbed API for now
    // eslint-disable-next-line no-console
    console.log({ formValues });
    waiveSubjectivity()
      .then(() => {
        const { quoteId } = modalData.underwritingSubjectivitiesModalData;

        queryClient.invalidateQueries(['uw-subjectivities', quoteId]);

        return Modal.show('UnderwritingSubjectivities', {
          data: modalData.underwritingSubjectivitiesModalData,
        });
      })
      .catch(handleAPIError('Not able to update subjectivity at this time.'));
  };

  const handleCancel = () => {
    return Modal.show('UnderwritingSubjectivities', {
      data: modalData.underwritingSubjectivitiesModalData,
    });
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onConfirm)}>
          <DialogContent style={{ padding: '2.5rem' }}>
            <Box className="contrast-text" fontSize="1.2rem">
              {subjectivityConfirmationText(modalData.subType)}
            </Box>
            <br />
            <Box className="contrast-text" fontSize="1.2rem">
              <strong>Waiving Subjectivity:</strong>{' '}
              <i>{formatSubjectivityType(modalData.subType)}:</i>{' '}
              {modalData.description}
            </Box>
            <Box className="contrast-text" fontSize="1.2rem">
              <InputLabelBase required>Waive Reason</InputLabelBase>
              <TextArea
                required
                maxRows={6}
                rows={10}
                name="waiveReason"
                fullWidth
                multiline
                style={{ border: 'none' }}
                blueBackground={false}
                error={undefined}
                className={undefined}
                blueText={undefined}
                editing={undefined}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <CbButton styleName="cancel" size="medium" onClick={handleCancel}>
              Cancel
            </CbButton>
            <CbButton styleName="ctaButton" size="medium" type="submit">
              Confirm
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    </>
  );
};

type FormValues = {
  waiveReason: string;
};

const defaultFormValues = {
  waiveReason: '',
};

const formSchema = Yup.object().shape({
  waiveReason: Yup.string().required().label('Waive Reason'),
});

function formatSubjectivityType(subType: SubType) {
  return SubjectivitySubTypeLabelMap[subType];
}

function subjectivityConfirmationText(subType: SubType) {
  if (subType === SubjectivitySubType.PreBind) {
    return "Are you sure you'd like to waive the need to complete the following subjectivity? If waived, this subjectivity will no longer be required to complete binding the quote.";
  }
  if (subType === SubjectivitySubType.PreIssuance) {
    return "Are you sure you'd like to waive the need to complete the following subjectivity? If waived, this subjectivity will no longer be required to complete issuing the policy.";
  }
  if (subType === SubjectivitySubType.PostIssuance) {
    return "Are you sure you'd like to waive the need to complete the following subjectivity? If waived, the risk engineering team will no longer follow up with the policyholder for this information.";
  }
}

export const WaiveSubjectivityModalConfig = {
  WaiveSubjectivityModal: {
    component: WaiveSubjectivityModal,
    config: {
      title: 'Subjectivities',
      maxWidth: 'md',
    },
  },
};
