import React from 'react';
// mui
import {
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Box,
  IconButton as MuiIconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';

// components
import CbButton from '../../../components/Buttons/CbButton';

export const StopRenewalQuote = ({ close, iconStyles, data, ...props }) => {
  const classes = styles();

  return (
    <Dialog {...props}>
      <DialogTitle>
        <Box>
          <ReportProblemIcon
            fontSize="large"
            className={`${iconStyles.root} ${classes.icon}`}
          />
          <span className={classes.text}>Cannot Generate Renewal Quote(s)</span>
        </Box>

        <MuiIconButton className="iconBody" onClick={close}>
          <img src="images/Close-Modal.svg" alt="modal-close" />
        </MuiIconButton>
      </DialogTitle>
      <DialogContent className={`${classes.body} modal-title`}>
        <p>
          You are currently {data.beforeDays} day(s) away from your policy
          expiration date.
        </p>

        <ul>
          <li>
            To avoid a lapse in coverage, we recommend preparing for the renewal
            process within {data.withinDays} days of the policy expiration date.
            Please try again within the recommended window.
          </li>
          <li>
            If you are within {data.withinDays} day(s) of the policy expiration
            date and still cannot generate renewal quote(s), please contact
            support@cowbellcyber.ai
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <CbButton
          styleName="ctaButton"
          buttonText="Close"
          onClick={close}
          customClass={classes.editButton}
        />
      </DialogActions>
    </Dialog>
  );
};

const styles = makeStyles(({ config }) => {
  return {
    editButton: {
      border: 'none',
      backgroundColor: config.colors.textRed,
    },
    icon: {
      fill: `${config.colors.textRed} !important`,
    },
    text: {
      color: config.colors.textRed,
    },
    body: {
      fontSize: config.textSizes.primer,
      fontWeight: 'normal',
      textAlign: 'left',
      '& ul': {
        margin: 0,
      },
    },
  };
});

export const StopRenewalQuoteConfig = {
  StopRenewalQuote: {
    component: StopRenewalQuote,
    config: {
      fullWidth: false,
      maxWidth: 'md',
      override: true,
    },
  },
};

export default StopRenewalQuote;
