import Moment from 'moment';

export const licenseServiceMappings = [
  { path: 'page' },
  { path: 'size' },
  { path: 'search' },
  { path: 'surplusLinesState' },
  { path: 'state' },
  { path: 'agencyId' },
  { path: 'email' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'created':
        case 'modified':
        case 'expirationDate':
        case 'activeResidentState':
          return `${orderBy},${order}`;
        case 'agentEmail':
          return `agentEmail.keyword,${order}`;
        default:
          return orderBy ? `${orderBy}.keyword,${order}` : null;
      }
    },
  },
  {
    path: 'from',
    paramKey: 'after',
    transform: ({ from, to }) => {
      if (!from) {
        return null;
      }

      return from === to
        ? Moment.unix(from).startOf('day').toISOString()
        : Moment.unix(from).toISOString();
    },
  },
  {
    path: 'to',
    paramKey: 'before',
    transform: ({ to, from }) => {
      if (!to) {
        return null;
      }

      return from === to
        ? Moment.unix(to).endOf('day').toISOString()
        : Moment.unix(to).toISOString();
    },
  },
];
