import React from 'react';
import { useSnackbar } from 'notistack';
// mui
import { DialogActions, DialogContent } from '@mui/material';
// platform helpers
import { delayedEvent } from '../utils/eventUtils';
import { hasUrl } from '../utils/appUtils';
import { AGENCY_POLICIES_LISTING_STORE_ID } from '../reducers/UiSettingsReducers';
// actions
import { deletePolicy } from './PolicyService';
// components
import CBButton from '../components/Buttons/CbButton';
import { manageAPIError } from '../utils';

const isCustomer = hasUrl('customer/');

export const DeletePolicy = ({ close, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    deletePolicy(data.id)
      .then(() => {
        close();
        if (isCustomer) {
          delayedEvent('table-refetch', 500, 'customerPolicies');
        } else {
          delayedEvent('table-refetch', 500, AGENCY_POLICIES_LISTING_STORE_ID);
        }
        enqueueSnackbar('Policy Successfully Deleted', { variant: 'success' });
        delayedEvent('policy:read');
      })
      .catch((error) => {
        enqueueSnackbar(
          manageAPIError(
            error,
            'Unable to delete policy at this time. Please try again later.'
          ),
          { variant: 'error' }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={onSubmit}>
      <DialogContent className="modal-title" style={{ padding: '2rem 5rem' }}>
        Are you sure you want to delete the{' '}
        <strong>
          <span className="blue-text"> Policy# {data.policyNumber}</span>
        </strong>{' '}
        for {data.companyName}?
      </DialogContent>
      <DialogActions>
        <CBButton styleName="cancel" buttonText="Cancel" action={close} />
        <CBButton
          type="submit"
          disabled={loading}
          loading={loading}
          styleName="ctaButton"
          buttonText="Delete Policy"
        />
      </DialogActions>
    </form>
  );
};

export const DeletePolicyConfig = {
  DeletePolicy: {
    component: DeletePolicy,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
    },
  },
};
