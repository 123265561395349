// react
import React from 'react';
// mui
import { DialogActions, Box } from '@mui/material';
// components
import CbButton from '../../../../components/Buttons/CbButton';
import { MultiSelect } from '../../../../components/Selects';
import { AmendmentRetroactiveDate } from './endorsementSubForms/AmendmentRetroactiveDate';
import { DropdownForm } from './endorsementSubForms/DropdownForm';
import { ExcessQuotaShare } from './endorsementSubForms/ExcessQuotaShare';
import { NonFollowForm } from './endorsementSubForms/NonFollowForm';
import { PriorPendingExclusion } from './endorsementSubForms/PriorPendingExclusion';
import { ScheduleUnderlyingInsurance } from './endorsementSubForms/ScheduleUnderlyingInsurance';
// actions

export const AddEndorsements = ({ data, close }) => {
  const [endorsement, setEndorsement] = React.useState();
  const { dispatch } = data;

  const endorsementOptions = [
    {
      label: 'Amendment of Retroactive Date Endorsement',
      value: 'Amendment of Retroactive Date Endorsement',
      subForm: AmendmentRetroactiveDate,
    },
    {
      label: 'Drop-Down',
      value: 'Drop-Down',
      subForm: DropdownForm,
    },
    {
      label: 'Excess Quota Share Endorsement',
      value: 'Excess Quota Share Endorsement',
      subForm: ExcessQuotaShare,
    },
    {
      label: 'Exclusion of Foreign Coverage Endorsement',
      value: 'Exclusion of Foreign Coverage Endorsement',
      subForm: AmendmentRetroactiveDate,
    },
    {
      label: 'Non-Follow Form',
      value: 'Non-Follow Form',
      subForm: NonFollowForm,
    },
    {
      label: 'Prior or Pending Exclusion Endorsement',
      value: 'Prior or Pending Exclusion Endorsement',
      subForm: PriorPendingExclusion,
    },
    {
      label: 'Schedule of Underlying Insurance Endorsement',
      value: 'Schedule of Underlying Insurance Endorsement',
      subForm: ScheduleUnderlyingInsurance,
    },
    {
      label: 'Specific Event Exclusion Endorsement',
      value: 'Specific Event Exclusion Endorsement',
      subForm: ExcessQuotaShare,
    },
    {
      label: 'Specific Individual/Entity Exclusion Endorsement',
      value: 'Specific Individual/Entity Exclusion Endorsement',
      subForm: ExcessQuotaShare,
    },
  ];

  const selectEndorsementHandler = React.useCallback(
    (name, selectedOptions) => {
      setEndorsement(selectedOptions);
    },
    // eslint-disable-next-line
    []
  );

  const FormElement = endorsement ? endorsement.subForm : '';
  return (
    <>
      <Box style={{ width: '35rem', margin: '0 auto' }}>
        <MultiSelect
          name="endorsement"
          label="Endorsement"
          isMulti={false}
          buttonPlaceholder="Select endorsement or search..."
          options={endorsementOptions}
          required
          onChange={selectEndorsementHandler}
          values={endorsement}
        />
      </Box>
      {endorsement && (
        <FormElement
          close={close}
          dispatch={dispatch}
          endorsementType={endorsement.value}
        />
      )}
      {!endorsement && (
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Save"
            disabled
          />
        </DialogActions>
      )}
    </>
  );
};

export const AddEndorsementsConfig = {
  AddEndorsements: {
    component: AddEndorsements,
    config: {
      title: 'Add Endorsements',
      fullWidth: true,
      maxWidth: 'md',
      PaperProps: {
        style: {
          overflowY: 'visible',
        },
      },
    },
  },
};
