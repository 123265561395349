import React, {
  useState,
  useCallback,
  useReducer,
  useEffect,
  useMemo,
} from 'react';
import _ from 'lodash';
import Moment from 'moment';
import { compose } from 'redux';
import { useSnackbar } from 'notistack';
import { Checkbox, FormHelperText, DialogActions } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';

import Numeral from 'numeral';
import CBButton from '../../../components/Buttons/CbButton';

import SearchBox from '../../../components/main/common/SearchBox';
import {
  setPagination,
  archiveQuotesReducer,
  initialState,
} from '../../../console/_reducers/archiveQuote.reducer';

import { ActionBar } from '../../../components/tables/tables-new/base/action-bar.table';
import { EnhancedTable } from '../../../components/tables/tables-new/variations/enhanced.table';
import { useRowSelections } from '../../../components/tables/tables-new/hooks';
import { TableFooter } from '../../../components/tables/tables-new/base/footer.table';

import { delayedEvent } from '../../../utils/eventUtils';
import { fetchQuotes, restoreQuotes } from '../../../inbox/QuotesService';
import { Modal } from '../../../components/modals/v2/helpers/v2.modal.helpers';
import { DateRangePicker } from '../../../components/inputs/DateRangePicker';
import { toUniversalUtcDate } from '../../../utils/date.utils';

const styles = ({ palette }) => ({
  presetName: {
    color: palette.common.cowbellGreen2,
    textTransform: 'capitalize',
  },

  deleteIcon: {
    margin: '0 auto',
    color: palette.error.main,

    '& svg:hover': {
      color: palette.error.main,
    },
  },
});

const useHeadStyles = makeStyles(({ palette }) => ({
  root: {
    '& tr': {
      border: 0,
    },

    '& th': {
      background: palette.background.modal,
      color: palette.primary.contrastText,

      '& span': {
        fontWeight: 400,
      },
    },
  },
  ActionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}));

// ui
export const ArchiveList = compose(withStyles(styles))(({ classes }) => {
  const [state, dispatch] = useReducer(archiveQuotesReducer, initialState);
  const [error, setError] = useState('');
  const {
    selectedRows,
    selectedRowsCount,
    handleSelectRow,
    handleSelectAllRows,
    handleDeselectAllRows,
  } = useRowSelections();

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    // Main three search paramaters

    const params = {
      size: state.rowsPerPage,
      page: state.page,
      search: state.search,
      archive: true,
      before: Moment(state.end).unix(),
      after: Moment(state.start).unix(),
    };

    fetchQuotes(params).then(({ data }) => {
      dispatch(setPagination({ data: data.content }));
      dispatch(setPagination({ totalRows: data.totalElements }));
    });

    // eslint-disable-next-line
  }, [state.rowsPerPage, state.page, state.search, state.start, state.end]);

  function handleSubmit() {
    const newPayload = {
      quoteIds: Object.keys(selectedRows),
    };
    restoreQuotes({}, newPayload)
      .then(() => {
        Modal.hide('archiveQuotes');
        dispatch(setPagination({ search: null }));
        enqueueSnackbar('Archive quotes added successfully!', {
          variant: 'success',
        });
        delayedEvent('table-refetch', 500, 'adminQuotes');
      })
      .catch((error) => {
        setError(
          _.get(error, 'response.data', 'Server Error, please try again')
        );
      });
  }

  const handleDate = (event) => {
    if (event && typeof event == 'object') {
      dispatch(setPagination({ end: event.end, start: event.start }));
    }
  };

  // callback functions linked to reducer dispatch
  function onPageChange(event, nextPage) {
    dispatch(setPagination({ page: nextPage }));
  }

  function changePageSize(event, target) {
    dispatch(setPagination({ rowsPerPage: target.key, page: 0 }));
  }

  // handle search
  const handleSearch = (value) => {
    dispatch(setPagination({ search: value }));
  };

  const onSelectAll = useCallback(
    (data) => {
      return () => {
        handleSelectAllRows(data.map((item) => item.id));
      };
    },
    [handleSelectAllRows]
  );

  const onSelectRow = useCallback(
    (id) => {
      return () => {
        handleSelectRow(id);
      };
    },
    [handleSelectRow]
  );
  // context utilized for EnhancedTable that will channel down to BaseTable
  const context = useMemo(
    () => ({
      style: {
        useHeadStyles,
      },
      classes,
      page: state.page,
      totalRows: state.totalRows,
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: state.rowsPerPage,
      onPageChange,
      changePageSize,
      handleSelectRow,
      handleDeselectAllRows,
      selectedRows,
      selectedRowsCount,
      onSelectAll,
      onSelectRow,
    }),
    [
      state.totalRows,
      state.page,
      state.rowsPerPage,
      classes,
      handleSelectRow,
      handleDeselectAllRows,
      selectedRows,
      selectedRowsCount,
      onSelectAll,
      onSelectRow,
    ]
  );

  return (
    <>
      <EnhancedTable
        id="250quotes"
        columns={columns}
        context={context}
        data={state.data}
      >
        <ActionBar
          render={() => (
            <>
              <div className="flex--spaced" style={{ width: '100%' }}>
                <div
                  className="flex--spaced align-baseline"
                  style={{ width: '50%', paddingBottom: '16px' }}
                >
                  <SearchBox handleSearch={handleSearch} />
                </div>
                <div
                  className="flex--spaced align-baseline"
                  style={{ right: 0, paddingBottom: '16px' }}
                >
                  <DateRangePicker
                    end={state.end}
                    style={{ right: 0 }}
                    start={state.start}
                    startSelected={state.start}
                    endSelected={state.end}
                    inlineLabel
                    onChange={handleDate}
                    onPresetSelect={handleDate}
                  />
                </div>
              </div>
            </>
          )}
        />
        <TableFooter context={context}>
          {selectedRowsCount <= 0 ? (
            <p
              style={{
                left: 0,
                fontSize: '1.1666666666666667rem',
                color: '#9aa0ae',
              }}
            >
              {state.totalRows} Records Found
            </p>
          ) : (
            <p style={{ fontSize: '1.1666666666666667rem', color: '#2180e2' }}>
              {selectedRowsCount} Records Selected
            </p>
          )}
        </TableFooter>
      </EnhancedTable>
      <FormHelperText className="api-text-error">{error}</FormHelperText>
      <DialogActions>
        <CBButton size="medium" styleName="cancel" onClick={Modal.hide}>
          Cancel
        </CBButton>

        <CBButton
          size="medium"
          type="submit"
          data-qa="restore"
          styleName="ctaButton"
          onClick={handleSubmit}
          disabled={selectedRowsCount <= 0}
        >
          Restore
        </CBButton>
      </DialogActions>
    </>
  );
});

// table columns
export const columns = [
  {
    canToggle: false,
    Head: {
      align: 'center',
      C: (column, ctx) => {
        const allSelected = ctx.data.length === ctx.selectedRowsCount;
        const indeterminate = ctx.selectedRowsCount && !allSelected;
        const callback = allSelected
          ? ctx.handleDeselectAllRows
          : ctx.onSelectAll(ctx.data);
        return (
          <Checkbox
            indeterminate={indeterminate}
            onChange={callback}
            color="secondary"
          />
        );
      },
    },
    sort: false,
    Cell: {
      C: ({ row, ctx }) => {
        return (
          <Checkbox
            checked={!!ctx.selectedRows[row.id]}
            onChange={ctx.onSelectRow(row.id)}
            color="secondary"
          />
        );
      },
    },
  },
  {
    name: 'id',
    label: 'Quote Number',
    Cell: {
      C: ({ row }) => row.quoteNumber || row.id,
    },
    csv: (row) => row.quoteNumber || row.id,
    sort: false,
  },
  {
    name: 'companyName',
    label: 'Name',
    filterType: 'string',
    sort: false,
  },
  {
    name: 'aggLimit',
    label: 'Aggregate Limit',
    filterType: 'string',
    sort: false,
    Cell: {
      C: ({ row }) =>
        !row.aggLimit ? '-' : Numeral(row.aggLimit).format('$0,0.00'),
    },
  },
  {
    name: 'deductible',
    label: 'Deductible',
    filterType: 'string',
    sort: false,
    Cell: {
      C: ({ row }) =>
        !row.deductible ? '-' : Numeral(row.deductible).format('$0,0.00'),
    },
  },
  {
    name: 'premium',
    label: 'Total Premium',
    filterType: 'string',
    sort: false,
    Cell: {
      C: ({ row }) =>
        !row.premium ? '-' : Numeral(row.premium).format('$0,0.00'),
    },
  },
  {
    name: 'modified',
    label: 'Modified',
    sort: false,
    Cell: {
      C: ({ row }) => (row.modified ? Moment(row.modified).fromNow() : ''),
    },
    csv: (row) => (row.modified ? Moment(row.modified).fromNow() : ''),
  },
  {
    name: 'agencyName',
    label: 'Agency Name',
    filterType: 'string',
    sort: false,
  },
  {
    name: 'agencyStatus',
    label: 'Quote Status',
    filterType: 'string',
    sort: false,
  },
  {
    name: 'expiresOn',
    label: 'Quote Expires',
    filterType: 'date',
    sort: false,
    Cell: {
      C: ({ row }) => toUniversalUtcDate(row.expiresOn),
    },
  },
];
