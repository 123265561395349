import React from 'react';

const SVG = ({
  id = 'PdfFileIcon',
  style = {},
  fill = '#949494',
  className = '',
  viewBox = '0 0 11.773 12',
  title = 'PdfFileIcon',
}) => (
  <svg
    fill={fill}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
        .cls-1{fill:#949494}.cls-2{fill:#f1f1f1}.cls-4{fill:#dc1d00}.cls-5{fill:#4d4d4d}.cls-6{fill:#fffefe}
        `}
      </style>
    </defs>
    <title>{title}</title>
    <path
      id="XMLID_19_"
      d="M163.726 68.4l2.387 2.489v9.452H157.2v.03h8.939v-9.453l-2.413-2.518"
      className="cls-1"
      transform="translate(-154.366 -68.37)"
    />
    <path
      id="XMLID_20_"
      d="M162.823 67.5H156.3v11.97h8.939v-9.452l-2.416-2.518"
      className="cls-2"
      transform="translate(-153.496 -67.5)"
    />
    <path
      id="XMLID_39_"
      fill="gray"
      d="M73.739 87H71v2.926h8.614V87z"
      transform="translate(-71 -86.359)"
    />
    <path
      id="XMLID_30_"
      d="M0 0H8.604V2.929H0z"
      className="cls-4"
      transform="translate(.056 .592)"
    />
    <g id="XMLID_2_" transform="translate(2.351 1.082)">
      <path
        id="XMLID_35_"
        d="M142.977 101.483h-.039v-.635h.082a.24.24 0 0 1 .2.079.4.4 0 0 1 .079.24.286.286 0 0 1-.1.27.4.4 0 0 1-.224.046m.082-.983h-.557v2.058h.441v-.694l.1.007a.9.9 0 0 0 .276-.049.587.587 0 0 0 .21-.125.528.528 0 0 0 .145-.194.933.933 0 0 0 .046-.388.99.99 0 0 0-.049-.276.553.553 0 0 0-.125-.178.56.56 0 0 0-.168-.1.943.943 0 0 0-.178-.046.746.746 0 0 0-.138-.013"
        className="cls-5"
        transform="translate(-142.5 -100.497)"
      />
      <path
        id="XMLID_38_"
        d="M188.283 102.087h-.043v-1.312h.007a.45.45 0 0 1 .256.046.5.5 0 0 1 .138.171.579.579 0 0 1 .059.227v.237a1.331 1.331 0 0 1-.013.22.8.8 0 0 1-.072.217.532.532 0 0 1-.154.154.338.338 0 0 1-.178.039m.079-1.687h-.562v2.058h.395a1.163 1.163 0 0 0 .441-.076.746.746 0 0 0 .293-.21.849.849 0 0 0 .161-.322 1.592 1.592 0 0 0 .049-.408 1.981 1.981 0 0 0-.036-.483.732.732 0 0 0-.174-.316.657.657 0 0 0-.23-.171 1.036 1.036 0 0 0-.237-.076.987.987 0 0 0-.1 0"
        className="cls-5"
        transform="translate(-186.311 -100.4)"
      />
      <path
        id="XMLID_64_"
        d="M238.042 100.6H237v2.058h.441v-.819H238v-.381h-.556v-.477h.6v-.381"
        className="cls-5"
        transform="translate(-233.893 -100.593)"
      />
    </g>
    <path
      id="XMLID_28_"
      d="M215.427 205c0-.039-.039-.516-.888-.5a6.207 6.207 0 0 0-1.052.072 5.4 5.4 0 0 1-.861-1.138 5.027 5.027 0 0 0 .266-1.332c-.013-.513-.135-.809-.529-.805s-.45.349-.4.861a3.966 3.966 0 0 0 .286 1s-.181.565-.424 1.131-.4.861-.4.861a4.107 4.107 0 0 0-1.167.6c-.352.329-.5.582-.312.835.161.217.72.266 1.223-.391a9.872 9.872 0 0 0 .727-1.069s.766-.21 1-.266.526-.1.526-.1a2.287 2.287 0 0 0 1.371.677c.67-.021.637-.399.634-.436zm-5.313 1.483c-.418-.25.878-1.022 1.115-1.049 0-.002-.674 1.31-1.115 1.047zm1.992-4.5c0-.408.132-.516.233-.516s.217.049.22.4a4.7 4.7 0 0 1-.22 1.042 1.93 1.93 0 0 1-.232-.933zm.542 2.785a3.108 3.108 0 0 0-.631.21l.171-.385c.171-.385.349-.911.349-.911a6.028 6.028 0 0 0 .71.967 4.13 4.13 0 0 0-.598.112zm1.009-.036s1.368-.247 1.368.22-.847.266-1.367-.227z"
      className="cls-4"
      transform="translate(-205.295 -196.9)"
    />
    <path
      id="XMLID_44_"
      d="M353.7 68.5v2.518h2.416z"
      className="cls-1"
      transform="translate(-344.406 -68.467)"
    />
    <path
      id="XMLID_21_"
      d="M354.7 67.5v2.518h2.416z"
      className="cls-2"
      transform="translate(-345.373 -67.5)"
    />
    <g id="XMLID_23_" transform="translate(2.318 1.049)">
      <path
        id="XMLID_43_"
        d="M141.977 100.483h-.039v-.635h.082a.24.24 0 0 1 .2.079.4.4 0 0 1 .079.24.286.286 0 0 1-.1.27.4.4 0 0 1-.224.046m.082-.983h-.557v2.058h.441v-.694l.1.007a.9.9 0 0 0 .276-.049.587.587 0 0 0 .21-.125.528.528 0 0 0 .145-.194.933.933 0 0 0 .046-.388.991.991 0 0 0-.049-.276.554.554 0 0 0-.125-.178.56.56 0 0 0-.168-.1.943.943 0 0 0-.178-.046.745.745 0 0 0-.138-.013"
        className="cls-6"
        transform="translate(-141.5 -99.497)"
      />
      <path
        id="XMLID_32_"
        d="M187.283 101.087h-.043v-1.312h.007a.45.45 0 0 1 .256.046.5.5 0 0 1 .138.171.579.579 0 0 1 .059.227v.237a1.335 1.335 0 0 1-.013.22.8.8 0 0 1-.072.217.533.533 0 0 1-.155.155.338.338 0 0 1-.178.039m.079-1.687h-.561v2.058h.395a1.163 1.163 0 0 0 .441-.076.746.746 0 0 0 .293-.21.849.849 0 0 0 .161-.322 1.593 1.593 0 0 0 .049-.408 1.981 1.981 0 0 0-.036-.483.732.732 0 0 0-.174-.316.657.657 0 0 0-.23-.171 1.035 1.035 0 0 0-.237-.076h-.1"
        className="cls-6"
        transform="translate(-185.311 -99.4)"
      />
      <path
        id="XMLID_24_"
        d="M237.042 99.6H236v2.058h.441v-.819H237v-.381h-.556v-.477h.6V99.6"
        className="cls-6"
        transform="translate(-232.893 -99.593)"
      />
    </g>
  </svg>
);

export default SVG;
