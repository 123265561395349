import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const AccountRiskError = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
      height="90%"
    >
      <ErrorOutlineIcon style={{ fontSize: '4rem', marginBottom: '1rem' }} />
      <Typography variant="body1">
        It's taking longer than expected to generate risk data for your client.
      </Typography>
      <Typography variant="body1">
        Please try again in a few minutes.
      </Typography>
    </Box>
  );
};
