import React from 'react';

// lodash
import _ from 'lodash';

import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  assessmentsMap,
  moneyFormat,
  numberFormat,
  phoneFormatter,
} from '../utils/appUtils';
import { assessmentsMap250, frequencyMetaMap } from '../utils/P250/appUtils250';

import TiledChart from '../components/graphs/TiledChart';
import { PubSub } from '../utils/eventUtils';
import DescTooltip from '../components/DescTooltip';
import { InputLabelBase } from '../components/inputs/InputLabelBase';
import { ProductTypes } from '../types';
import { usePersona } from '../components/hooks/usePersona';
import Showable from '../components/Showable';
import { SecurityQuestionList } from '../components/InboxFirmographic';
import { securityQuestions } from '../console/agencies/quotes/PrimeX/securityQuestionsSchema/securityQuestionSchema';
import { formatAddressByRegion } from '../i18n/utils';
import { useCowbellTranslations } from '../i18n/translations';
import { formatPhoneAsString } from '../i18n/forms/LanguageForm';
import { deriveCurrencyFormat } from '../i18n/currencies';

const styles = (themeSelected) => {
  const { palette, config } = themeSelected;
  return {
    profileMeter: {
      width: 70,
      height: 23,
    },
    title: {
      paddingTop: '1.25rem',
      fontFamily: 'Titillium Web',
      fontSize: config.textSizes.normal,
      fontWeight: 700,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.4,
      letterSpacing: 'normal',
      textAlign: 'right',
      color: palette.text.title,
      height: 30,
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 12,
    },
    statsTile: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    wideLabel: {
      flexDirection: 'row',
      width: '80%',
      fontWeight: 'bold',
      color: palette.text.title,
    },
    shortValue: {
      width: '20%',
      fontFamily: 'Titillium Web',
      textAlign: 'right',
    },

    dataWrapper: {
      display: 'flex',
      alignSelf: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      paddingTop: 7,
      paddingBottom: 7,
    },

    liveData: {
      textAlign: 'right',
      color: palette.primary.title,
    },
    legendContainer: {
      marginTop: '1rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    rectangle: {
      width: '0.66rem',
      height: '0.6rem',
      backgroundColor: palette.primary.title,
      marginRight: '1rem',
    },
    message: {
      fontSize: config.textSizes.petite,
      lineHeight: 1,
      color: palette.text.title,
    },
    field: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '.75rem',
    },
    label: {
      padding: '0',
      color: 'inherit',
      fontWeight: 'bold',
    },
    data: {
      '& li:first-child label': {
        padding: '0',
      },
      '& li': {
        paddingBottom: '.5rem',
      },
    },
    horiDivider: {
      borderBottom: `1px solid ${palette.text.primary}`,
    },
  };
};

const PolicyFirmographic = ({ classes, data, liveData = {} }) => {
  const { isCowbell, isAgency, ...persona } = usePersona();

  const { t, translationKeys } = useCowbellTranslations();

  const _deriveCurrencyFormat = (input) => {
    return deriveCurrencyFormat(data.language, input);
  };

  const [liveOn, setLive] = React.useState(null);

  React.useEffect(() => {
    const onPolicyLiveListener = PubSub.subscribe(
      'policy:liveData',
      (nextLiveOn) => {
        setLive(nextLiveOn);
      }
    );

    return () => {
      onPolicyLiveListener.remove();
    };
    // eslint-diable-next-line
  }, []);

  if (!data) {
    return <div>No data</div>;
  }

  const other = [];
  if (data.domains) {
    data.domains.map((value, index) => {
      if (value.domain !== data.domainName) {
        if (index === 0) {
          other.push(value.domain);
        } else {
          other.push(`,\t${value.domain}`);
        }
      }
      return 0;
    });
  }

  const agencyData = {
    [`${t(translationKeys.agency)} Name`]: data.agencyName || '-',
    [`${t(translationKeys.agent)} Name`]:
      `${data.agentFirstName} ${data.agentLastName}` || '-',
    [`${t(translationKeys.agent)} Email`]: data.agentEmail || '-',
  };

  const firmographicData = data.firmographicData || {};
  const profileScore = _.get(firmographicData, 'profileScore', 0);

  const orgData = {
    'Duns Number': firmographicData.dunsNumber || '-',
    DBA: firmographicData.dbaOrTradestyle || '-',
    'Main Domain': firmographicData.domainName || '-',
    'Industry Code': firmographicData.industryCode || '-',
  };

  const address = formatAddressByRegion(data.language, {
    address1: liveData.address1,
    address2: liveData.address2,
    address3: liveData?.address3,
    city: liveData.city,
    state: liveData.state,
    zipCode: liveData.zipCode,
  });

  let liveOtherData = null;
  if (liveOn) {
    const liveAddress = formatAddressByRegion(data.language, {
      address1: liveData.address1,
      address2: liveData.address2,
      address3: liveData?.address3,
      city: liveData.city,
      state: liveData.state,
      zipCode: liveData.zipCode,
    });
    liveOtherData = [
      _deriveCurrencyFormat(liveData.revenue) || '-',
      numberFormat(liveData.noOfEmployeesAll) || '-',
      liveData.ownershipType || '-',
      liveAddress,
      formatPhoneAsString(data.language, liveData.phoneNumber),
    ];
  }

  const finalPhoneNumber = formatPhoneAsString(
    data.language,
    firmographicData.phoneNumber
  );
  const otherData = {
    [`Projected ${t(translationKeys.revenue)}`]: _deriveCurrencyFormat(
      firmographicData.revenue
    ),
    'Number of Employees':
      numberFormat(firmographicData.noOfEmployeesAll) || '-',
    'Ownership Type': firmographicData.ownershipType || '-',
    Address: address,
    'Business Phone Number': finalPhoneNumber,
  };

  const shouldLink = isAgency || isCowbell;

  let mappings = {};
  if (firmographicData.product === ProductTypes.p250) {
    mappings = assessmentsMap250;
  } else if (firmographicData.product === ProductTypes.p100) {
    mappings = assessmentsMap;
  }

  return (
    <>
      <ul
        className={`${classes.data} list--unstyled`}
        style={{ height: '8.5rem' }}
      >
        {Object.keys(agencyData).map((factor, index) => {
          return (
            <li key={index} className="flex--spaced">
              <strong>
                <InputLabelBase className={classes.label}>
                  {factor}
                </InputLabelBase>
              </strong>
              <span>{agencyData[factor]}</span>
            </li>
          );
        })}
      </ul>

      <h4 className="tertia-text flex--spaced">
        <u>{t(translationKeys.organization).toUpperCase()} INFORMATION</u>
        <div className={classes.profileMeter}>
          <TiledChart
            key={profileScore}
            style={{ height: 10 }}
            value={profileScore}
          />
        </div>
      </h4>

      <div className={classes.field}>
        <InputLabelBase className={classes.label}>
          {t(translationKeys.organization)} Name
        </InputLabelBase>
        {shouldLink && (
          <Link
            className="blue-text"
            style={{ textDecoration: 'none' }}
            to={`${persona.basePath}/accounts?search=${data.accountId}`}
          >
            {firmographicData.name || ''}
          </Link>
        )}
        {(!shouldLink && firmographicData.name) || ''}
      </div>
      <ul className={`${classes.data} list--unstyled`}>
        {Object.keys(orgData).map((factor, index) => {
          return (
            <li key={index} className="flex--spaced">
              <strong>
                <InputLabelBase className={classes.label}>
                  {factor}
                </InputLabelBase>
              </strong>

              <span>
                {factor === 'Industry Code' && shouldLink ? (
                  <Link
                    className="blue-text"
                    style={{ textDecoration: 'none' }}
                    to={`${persona.basePath}/manage/industry?search=${orgData[factor]}`}
                  >
                    {orgData[factor]}
                  </Link>
                ) : (
                  orgData[factor]
                )}
              </span>
            </li>
          );
        })}
      </ul>

      <ul className="list--unstyled">
        {Object.keys(otherData).map((factor, index) => {
          return (
            <>
              <li
                key={index}
                className="flex--spaced"
                style={{ alignItems: 'baseline' }}
              >
                <strong>
                  <InputLabelBase className={classes.label}>
                    {factor}
                  </InputLabelBase>
                </strong>
                <span className={liveOn ? classes.horiDivider : null}>
                  {' '}
                  {otherData[factor]}
                </span>
              </li>
              {liveOn && (
                <div className={classes.liveData}>{liveOtherData[index]}</div>
              )}
            </>
          );
        })}
        {liveOn && (
          <div className={classes.legendContainer}>
            <div className={classes.rectangle} />
            <div className={classes.message}>
              Latest {t(translationKeys.organization)} Information
            </div>
          </div>
        )}
      </ul>

      <Showable
        show={[ProductTypes.p100, ProductTypes.p250].includes(
          firmographicData.product
        )}
      >
        <h4 className="tertia-text flex--spaced" style={{ marginBottom: 0 }}>
          <u>
            SECURITY ASSESSMENT
            <span>
              <DescTooltip
                tooltip={Object.keys(mappings).map((key, index) => {
                  const { label, options } = mappings[key];
                  let value = '';

                  switch (key) {
                    case 'claimHistory':
                      value = options[firmographicData[key]]?.label;
                      break;
                    case 'pastCyberIncident':
                      value = firmographicData.p250Details[key] ? 'Yes' : 'No';
                      break;
                    case 'backupFrequency':
                      value =
                        frequencyMetaMap[firmographicData.p250Details[key]];
                      break;
                    case 'patchingFrequency':
                      value =
                        frequencyMetaMap[firmographicData.p250Details[key]];
                      break;
                    default:
                      value = firmographicData[key] ? 'Yes' : 'No';
                  }

                  return (
                    <div key={index} className={classes.dataWrapper}>
                      <div className={classes.statsTile}>
                        <div className={classes.wideLabel}>{label}</div>
                        <div className={classes.shortValue} title={data[key]}>
                          {value}
                        </div>
                      </div>
                    </div>
                  );
                })}
              />
            </span>
          </u>
        </h4>
      </Showable>
    </>
  );
};

export default withStyles(styles)(PolicyFirmographic);
