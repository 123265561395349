// lodash
import _ from 'lodash';
import type { Permissions } from '../../components/auth/permissions';

const REFRESH_TKN_KEY = '_analytics';
const REMEMBER_DEVICE_KEY = '_global';

export function isDeviceRemembered() {
  const isRemembered = localStorage.getItem(REMEMBER_DEVICE_KEY);
  return Boolean(Number(isRemembered));
}

export function setIsDeviceRemembered(isRemembered = false) {
  localStorage.setItem(REMEMBER_DEVICE_KEY, String(Number(isRemembered)));
}

export function cacheAuthTokens(access: string, refresh: string) {
  window.sessionStorage.setItem('atkn', access);

  const isRemembered = isDeviceRemembered();
  if (isRemembered) {
    localStorage.setItem(REFRESH_TKN_KEY, refresh);
  } else {
    localStorage.removeItem(REFRESH_TKN_KEY);
    window.sessionStorage.setItem('rtkn', refresh);
  }
}

export function retrieveAuthTokens() {
  const refreshToken = isDeviceRemembered()
    ? localStorage.getItem(REFRESH_TKN_KEY)
    : window.sessionStorage.getItem('rtkn');

  return {
    accessToken: window.sessionStorage.getItem('atkn'),
    refreshToken,
  };
}

export function reduceScopes(rawScopes: Record<string, string[]>) {
  return Object.keys(rawScopes).reduce<string[]>((acc, scopeName) => {
    const permissions = rawScopes[scopeName];

    return [
      ...acc,
      ...permissions.map((permission) => `${scopeName}:${permission}`),
    ];
  }, []);
}

const determineIfAllowedScopes_defaultOptions = {
  requireAll: false,
};

determineIfAllowedScopes.DEFAULT_OPTIONS =
  determineIfAllowedScopes_defaultOptions;

export function determineIfAllowedScopes(
  scopes: Permissions[],
  allowed: Permissions[] | 'any' | 'none',
  requireAll: boolean = determineIfAllowedScopes_defaultOptions.requireAll
) {
  if (allowed === 'any') {
    return true;
  }

  if (allowed === 'none') {
    return false;
  }

  // force all scopes to be allowed
  if (requireAll) {
    return Boolean(
      allowed.every((allowedScope) => scopes.indexOf(allowedScope) !== -1)
    );
  }

  // if any allowed exist in user's scopes
  return Boolean(_.intersection(scopes, allowed).length);
}
