import { useHistory } from 'react-router-dom';

// utils
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  AMPLITUDE_EVENTS as ANALYTICS_EVENTS,
  AMPLITUDE_PROPERTIES as ANALYTICS_PROPERTIES,
  useAmplitude as useAnalytics,
} from '../../../../providers/AmplitudeProvider';

import { ActionButtonText } from '../blocks/ActionButtonText';
import { SmallActionButton } from '../blocks/SmallActionButton';
import { useGetPlatformRegion } from '../../../../utils';
import { languages } from '../../../../i18n/i18n.language-config';

export const DashboardSecondaryActionsModule = () => {
  const history = useHistory();
  const analytics = useAnalytics();
  const region = useGetPlatformRegion();

  const handleViewClaims = () => {
    analytics.track(`nav_to_${ANALYTICS_EVENTS.nav.claims}`, {
      to: '/agency/claims',
      version: ANALYTICS_PROPERTIES.version.two,
    });
    history.push('/agency/claims');
  };

  const handleResources = () => {
    analytics.track(`nav_to_${ANALYTICS_EVENTS.nav.resources}`, {
      to: '/agency/support',
      version: ANALYTICS_PROPERTIES.version.two,
    });
  };

  return (
    <div className="flex" style={{ gap: '1rem', marginTop: '1rem' }}>
      <SmallActionButton name="view-claims" onClick={handleViewClaims}>
        <FontAwesomeIcon
          icon={regular('file-exclamation')}
          size="2x"
          style={{ marginRight: '1rem' }}
        />
        <ActionButtonText>View Claims</ActionButtonText>
      </SmallActionButton>
      <SmallActionButton
        name="resources"
        onClick={handleResources}
        href={RESOURCE_LINK_MAPPING[region]}
        target="_blank"
      >
        <FontAwesomeIcon
          icon={regular('books')}
          size="2x"
          style={{ marginRight: '1rem' }}
        />
        <ActionButtonText>Resources</ActionButtonText>
      </SmallActionButton>
    </div>
  );
};

const RESOURCE_LINK_MAPPING = {
  [languages['en-GB']]: 'https://cowbell.insure/uk/prime-one/',
  [languages['en-US']]: 'https://cowbell.insure/broker_resources/',
};
