import React from 'react';
import { compose } from 'redux';
import { useForm } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
// lodash
import _ from 'lodash';
// mui
import {
  DialogActions,
  DialogContent,
  Grid,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { FileUploadDragDrop } from '../../components/fileUploader/FileUploadDragDrop';
import { withUploadFeature } from '../../components/fileUploader/withUploadFeature';
import { withUploadFeatureApplications } from '../../components/fileUploader/withUploadFeatureApplications';
import CbButton from '../../components/Buttons/CbButton';
// helpers
import { PubSub } from '../../utils/eventUtils';
// actions
import { uploadDocument } from '../../api/DocumentsService';

export const RescindBOR = compose(
  withUploadFeatureApplications(),
  withUploadFeature({
    screenCenter: false,
    hide: false,
    acceptFileTypes: `image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf`,
  })
)(({ close, handleFileSubmit, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const [file, setFile] = React.useState(null);
  const [fileDetails, setFileDetails] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const sub = PubSub.subscribe(
      'withUploadFeatureApplications:selectedFile',
      (eventData) => {
        if (eventData) {
          setFile(eventData);
          const splitString = eventData.name.split('.');
          const extension = splitString.pop();
          const fileName = splitString.join('.');
          setFileDetails({ extension, fileName });
          setError('');
        } else {
          setError('Unsupported file type.');
        }
      }
    );

    return () => {
      sub.remove();
    };
  }, []);

  const handleClearFile = () => {
    setFileDetails(null);
  };

  const onSubmit = () => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    return uploadDocument(
      bodyFormData,
      data.accountId,
      'BOR',
      fileDetails.fileName,
      true
    )
      .then((resp) => {
        enqueueSnackbar('Upload successful', { variant: 'success' });
        if (_.isFunction(handleFileSubmit)) {
          handleFileSubmit({ docInfo: resp.data });
        }
      })
      .catch(() => {
        enqueueSnackbar('Failed to upload the file!', { variant: 'error' });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent className={classes.wrapper}>
        <h2 className={classes.header}>Upload authorized recession letter.</h2>
        <h3 className={classes.subHeader}>Please upload the Letter below.</h3>

        <FileUploadDragDrop
          editing={false}
          fileDetails={fileDetails}
          handleClearFile={handleClearFile}
        />
        <p className={classes.supportedFiles}>
          Supported File Types: PDF, DOC, JPEG & PNG
        </p>

        <FormHelperText className="api-text-error">{error}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid className={classes.pagination}>
            Step 1 of 2: Upload Recession Letter
          </Grid>
          <Grid>
            <CbButton buttonText="Cancel" styleName="cancel" onClick={close} />
            <CbButton
              buttonText="Next"
              styleName="ctaButton"
              type="submit"
              disabled={!fileDetails || isSubmitting}
              loading={isSubmitting}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
});

const useStyles = makeStyles(({ config, palette }) => ({
  wrapper: {
    color: palette.primary.contrastText,
    padding: '2rem 5rem !important',
  },
  header: {
    fontWeight: 'normal',
    lineHeight: 0.9,
    margin: 0,
  },
  subHeader: {
    fontWeight: 'normal',
    lineHeight: 1.17,
    fontSize: config.textSizes.normal,
    paddingBottom: '1rem',
  },
  supportedFiles: {
    lineHeight: 1.5,
    textAlign: 'center',
  },
  pagination: {
    fontSize: config.textSizes.tertia,
    color: palette.primary.contrastText,
  },
}));
