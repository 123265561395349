import React from 'react';
import type { WithChildrenProps } from '../../../types/components/common';

const AgencyDashboardStateContext = React.createContext<any>(null);

export const useAgencyDashboardState = () => {
  return React.useContext(AgencyDashboardStateContext);
};

interface AgencyDashboardStateProviderProps extends WithChildrenProps {
  value: any; // the strcuture of agencyDashboardStateOrchestrator is not yet clear
}

export const AgencyDashboardStateProvider = ({
  children,
  ...props
}: AgencyDashboardStateProviderProps) => {
  return (
    <AgencyDashboardStateContext.Provider {...props}>
      {children}
    </AgencyDashboardStateContext.Provider>
  );
};
