import React from 'react';
import _ from 'lodash';
import { prettyId } from '../../../utils/data.utils';

export const useChipField = ({
  name,
  methods,
  defaultValues = [],
  validateChipInput = defaultTextFieldValidator,
}) => {
  if (!name) {
    throw Error('"name" is a required parameter');
  }

  const [inputValue, setInputValue] = React.useState('');
  const [inputError, setInputError] = React.useState('');
  const intitialChipList = React.useState(
    defaultValues.map((chip) => ({ id: prettyId(), value: chip }))
  )[0];
  const [chipList, setChipList] = React.useState(
    defaultValues.map((chip) => ({ id: prettyId(), value: chip }))
  );

  React.useEffect(() => {
    if (methods) {
      methods.setValue(name, defaultValues);
    }
    // eslint-disable-next-line
  }, []);

  const _onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const { isValid = true, message = '' } =
        validateChipInput(inputValue) || {};

      if (!isValid) {
        return setInputError(message);
      }

      const update = [
        ...chipList,
        { id: prettyId(), value: inputValue.trim() },
      ];

      setChipList(update);

      if (methods) {
        methods.setValue(
          name,
          update.map((chip) => chip.value)
        );
      }

      setInputValue('');
    }
  };

  const _onChange = (event) => {
    if (inputError) {
      setInputError('');
    }
    setInputValue(event.target.value);
  };

  const _onDelete = (id) => {
    const update = chipList.filter((chip) => id !== chip.id);

    setChipList(update);

    if (methods) {
      methods.setValue(
        name,
        update.map((chip) => chip.value)
      );
      methods.clearErrors();
    }
  };

  const getChipFieldProps = (props = {}) => ({
    ...props,
    name,
    value: inputValue,
    error: inputError,
    register: _.get(methods, 'register', () => {}),
    onKeyDown: callAll(_onKeyDown, props.onKeyDown),
    onChange: callAll(_onChange, props.onChange),
  });

  const getChipListingProps = (props = {}) => ({
    ...props,
    chipList,
    onDelete: callAll(_onDelete, props.onDelete),
  });

  const getChipErrorProps = () => ({
    name,
    errors: _.get(methods, 'formState.errors'),
  });

  return {
    getChipFieldProps,
    getChipListingProps,
    getChipErrorProps,
    inputValue,
    inputError,
    chipList,
    intitialChipList,
  };
};

// helpers
const defaultTextFieldValidator = (input) => {
  if (!input) {
    return { isValid: false, message: 'Input cannot be an empty field' };
  }
};

const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args));
