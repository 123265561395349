import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

const AccountLink = ({
  account = {},
  accountId,
  children,
  primeCache = false,
}) => {
  const queryClient = useQueryClient();

  let timer;
  const prefetchAccountData = (clearTimer = false) => {
    return () => {
      if (clearTimer || !primeCache) {
        clearTimeout(timer);
        return;
      }

      timer = setTimeout(
        () =>
          queryClient.setQueryData(
            ['account-summary-data', accountId],
            account
          ),
        500
      );
    };
  };

  return (
    <>
      <Link
        onMouseEnter={prefetchAccountData()}
        onMouseLeave={prefetchAccountData(true)}
        to={`/admin/account/${accountId}`}
      >
        {children}
      </Link>
    </>
  );
};

export default AccountLink;
