import _ from 'lodash';
import React, { useState } from 'react';
import moment from 'moment/moment';
import { useSnackbar } from 'notistack';
// components
import {
  Box,
  DialogActions,
  DialogContent,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormHelperText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { compose } from 'redux';
import _config from '../../themes/_base/_config';

import { CertificateHolderForm } from './certificateHolder';
import { saveBlobFile } from '../../utils/appUtils';
import {
  downloadAWSDoc,
  downloadPolicyCertificate,
  fetchAdditonalInsured,
  sendPolicyCertificateEmail,
} from '../../policies/PolicyService';
import { withShowable } from '../../console/_global/lib/withShowable';
import CBButtonBase from '../Buttons/CbButton';
import { manageAPIError } from '../../utils';
import { withToggleModal } from '../hocs/withToggleModal';

const CBButton = withShowable(CBButtonBase);
const styles = {
  radioGroup: {
    '& > label:first-child': {
      marginBottom: 20,
    },

    '& > label:last-child': {
      marginTop: 20,
    },
  },
  bulletText: {
    fontFamily: _config.fonts.titillum,
    fontSize: _config.textSizes.normal,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: _config.colors.white,
  },
  formControlLabel: {
    '& > span': {
      fontSize: '1.3333333333rem',
    },
    fontFamily: _config.fonts.titillum,
    fontSize: _config.textSizes.normal,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: _config.colors.white,
  },
};
export const DownloadEmailCertificate = compose(
  withToggleModal,
  withStyles(styles)
)(({ classes, data, close }) => {
  const [backendError, setBackendError] = useState('');
  const [additionalInsuredList, setAdditionalInsuredList] = React.useState([]);
  const [additionalInsuredId, setAdditonalInsuredID] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();

  const downloadCertificate = () => {
    const { accountId, policyNumber } = data;
    const policyId = data.id;
    const name = data.companyName;
    const created = moment(data.created).unix();

    downloadPolicyCertificate(accountId, policyId, created)
      .then((response) => {
        const url = _.get(response, 'data');
        return downloadAWSDoc(url);
      })
      .then((resp) => {
        saveBlobFile(
          resp,
          `${name}-${policyNumber}-Cowbell-Policy-Certificate`,
          '.pdf'
        );
        close();
        enqueueSnackbar('Generate Policy Certificate Successfully', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        const errorMessage = manageAPIError(
          error,
          'An error occured finding the document. Try again'
        );

        setBackendError(errorMessage);
      });
  };

  const emailAdditonalInsured = () => {
    const payload = {
      docType: 'Additional_Insured',
      additionalInsuredId,
      policyId: data.id,
    };

    return sendPolicyCertificateEmail({ productType: data.product }, payload)
      .then(() => {
        this.props.toggleModal.direct('certificateEmailConfirm', data, {
          title: 'Generate Policy Certificate',
          footerButtons: [
            {
              buttonText: 'Okay',
              action: 'cancel',
              showLoading: true,
              showDisabled: true,
            },
          ],
        });
      })
      .catch((error) => {
        setBackendError(
          manageAPIError(
            error,
            'Unable to request sending of policy certificate email.'
          )
        );
      });
  };

  React.useEffect(() => {
    fetchAdditonalInsured({ policyId: data.id })
      .then((response) => {
        if (response.data !== '') {
          setAdditionalInsuredList(response.data);
        }
      })
      .catch((error) => {
        const errorMessage = manageAPIError(
          error,
          'Unable to fetch additional insured info.'
        );

        setBackendError(errorMessage);
      });
    // eslint-disable-next-line
  }, []);

  const [option, setOption] = React.useState('downloadCertificate');
  // eslint-disable-next-line no-restricted-globals
  const additionalInsuredSelected = !isNaN(option);

  const handleChange = (event) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(event.target.value)) {
      setAdditonalInsuredID(event.target.value);
    }

    setOption(event.target.value);
  };

  return (
    <>
      <DialogContent>
        <RadioGroup
          aria-label="actions"
          name="certificate-action"
          value={option}
          onChange={handleChange}
          className={classes.radioGroup}
        >
          <FormControlLabel
            label="Download Certificate of Insurance"
            value="downloadCertificate"
            control={<Radio color="secondary" />}
            className={classes.formControlLabel}
            size="large"
          />

          <>
            {additionalInsuredList.map((insured) => (
              <FormControlLabel
                key={insured.businessName}
                label={`ADDITONAL INSURED ${insured.businessName} (${insured.address1}, ${insured.city}, ${insured.state}, ${insured.zipCode})`}
                value={`${insured.id}`}
                control={<Radio color="secondary" />}
                className={classes.formControlLabel}
              />
            ))}
          </>

          <FormControlLabel
            label="Certificate Holder"
            value="Certificate_Holder"
            control={<Radio color="secondary" />}
            className={classes.formControlLabel}
          />
        </RadioGroup>
        <CertificateHolderForm
          data={data}
          docType={option}
          show={option === 'Certificate_Holder'}
        />
        <FormHelperText className="api-text-error">
          {backendError}
        </FormHelperText>
        <Box
          component="p"
          className="tertia-text contrast-text"
          style={{ textAlign: 'center', fontStyle: 'italic' }}
          mb={3}
        >
          Note: By choosing this option, you will not be adding the certificate
          holder as an additonal insured on the policy.
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end' }}>
        <CBButton size="medium" styleName="cancel" onClick={close}>
          Cancel
        </CBButton>

        <CBButton
          show={option === 'downloadCertificate'}
          styleName="ctaButton"
          size="medium"
          onClick={downloadCertificate}
        >
          Download
        </CBButton>

        <CBButton
          show={additionalInsuredSelected}
          styleName="ctaButton"
          size="medium"
          onClick={emailAdditonalInsured}
        >
          Email me
        </CBButton>

        <CBButton
          type="submit"
          form="certificateHolderForm"
          show={option === 'Certificate_Holder'}
          styleName="ctaButton"
          size="medium"
        >
          Email me
        </CBButton>
      </DialogActions>
    </>
  );
});

export default DownloadEmailCertificate;
