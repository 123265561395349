import { Box, Typography, useTheme } from '@mui/material';
import * as Ramda from 'ramda';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link, useHistory } from 'react-router-dom';
import type { AxiosResponse } from 'axios';
import qs from 'qs';
import { useSnackbar } from 'notistack';
import type { Policy } from '../../../../accounts/summary/types/policy.types';
import {
  AgencyDashboardBaseRow,
  AgencyDashboardListingContainer,
  AgencyDashboardRowContent,
  AgencyDashboardRowDetail,
  ColumnDivider,
  ListingHeader,
  RowCell,
} from '../common/components';
import type { Binder } from '../../../../accounts/summary/types/binder.types';
import { AgencyDashboardNullResultFallback } from '../AgencyDashboardListingFallback';
import {
  formatCurrencyAsFloat,
  numberToCurrency,
} from '../../../../../utils/currency.utils';
import { UrgencyCalendar } from '../../../../claims/detail/components/UrgencyCalendar';
import {
  HyperlinkText,
  SecondaryText,
  TruncatedTextWithTooltip,
} from '../../agency-dashboard.utils';
import {
  ACTIVE_STATUS,
  BOUND_STATUS,
  IN_FORCE_STATUS,
  ISSUED_STATUS,
  PolicyStatus,
} from '../../../../_statics/policy.statics';
import { PrimaryActionButton } from '../PrimaryActionButton';
import { determineRenewalRoute } from '../../../../../accounts/accountUtils';
import { fetchBinders } from '../../../../../inbox/QuotesService';
import { getIsRenewalOrMigration } from '../../../../../utils';
import type { PrimeXProductType } from '../../../../../types';
import { PrimeXProductTypes, ProductTypes } from '../../../../../types';
import { AgencyDashboardListingError } from '../AgencyDashboardListingError';
import { AgencydashboardListingLoadingSkeleton } from '../AgencyDashboardListingLoadingSkeleton';
import { useAgencyDashboardListingData } from '../../hooks/useAgencyDashboardListingData';
import { useAgencyDashboardState } from '../../AgencyDashboardStateContext';
import {
  AGENCY_DASHBOARD_LISTING_TABS,
  earliestPossibleEntityDate,
  getPoliciesHeaderConfig,
} from '../../agency-dashboard.statics';
import { BINDER_RESCINDED } from '../../../../_statics/quote.statics';
import {
  AMPLITUDE_EVENTS as ANALYTICS_EVENTS,
  AMPLITUDE_PROPERTIES as ANALYTICS_PROPERTIES,
  useAmplitude as useAnalytics,
} from '../../../../../providers/AmplitudeProvider';
import { AgencyPoliciesMenuOptions } from './AgencyPoliciesMenuOptions';
import { Show } from '../../../../../components/Show';
import { AgencyBindersMenuOptions } from '../binders/AgencyBindersMenuOptions';
import { determineIsLegacyProduct } from '../../../../agencies/quotes/PrimeX/utils/primeX.util';
import { AgencyPoliciesPrimeXMenuOptions } from './AgencyPoliciesPrimeXMenuOptions';
import AgencyBindersPrimeXMenu from '../binders/AgencyBindersPrimeXMenu';
import { toUniversalUtcDate } from '../../../../../utils/date.utils';
import {
  getCurrencySymbolByLanguage,
  getRegionByProduct,
} from '../../../../../i18n/utils';
import {
  NO_DECIMAL_FORMAT,
  deriveCurrencyFormat,
} from '../../../../../i18n/currencies';
import { useCowbellTranslations } from '../../../../../i18n/translations';
import {
  ISSUE_POLICY_EVENT,
  getIssuePolicyProps,
} from '../../../../../analytics/events/issue-policy-event';
import {
  MANAGE_POLICY_EVENT,
  getManagePolicyProps,
} from '../../../../../analytics/events/manage-policy.event';

const initialData = { rows: [], totalPages: 0 };

export const AgencyDashboardPoliciesTab = () => {
  const { currentTab } = useAgencyDashboardState();
  const isBinder = currentTab === AGENCY_DASHBOARD_LISTING_TABS.BINDERS;
  const headerConfig = getPoliciesHeaderConfig(!!isBinder);

  const { bindersCache, policiesCache } = useAgencyDashboardListingData();
  const activeCache = isBinder ? bindersCache : policiesCache;
  const { data = initialData, isLoading, isError } = activeCache;

  if (isError) {
    return (
      <AgencyDashboardListingError>
        <Typography variant="h6" style={{ textAlign: 'center' }}>
          There was an error while fetching page data. <br /> Please try again
          in a few minutes.
        </Typography>
      </AgencyDashboardListingError>
    );
  }

  return (
    <AgencyDashboardListingContainer totalPages={data.totalPages}>
      <ListingHeader headerConfig={headerConfig} />
      <PoliciesListing
        policies={data.rows}
        isLoading={isLoading}
        isBinder={isBinder}
      />
    </AgencyDashboardListingContainer>
  );
};

interface ListingProps {
  policies: Policy[] | Binder[];
  isLoading: boolean;
  isBinder?: boolean;
}

export const PoliciesListing = ({
  policies,
  isLoading,
  isBinder,
}: ListingProps) => {
  if (isLoading) {
    return <AgencydashboardListingLoadingSkeleton />;
  }

  if (policies.length === 0) {
    return <AgencyDashboardNullResultFallback entityType="policies" />;
  }

  return (
    <>
      {policies.map((policy) => (
        <AgencyDashboardBaseRow isExpandable key={policy.id} rowId={policy.id}>
          <AgencyDashboardPolicyRow policy={policy} isBinder={!!isBinder} />
          <PolicyRowDetail policy={policy} isBinder={!!isBinder} />
        </AgencyDashboardBaseRow>
      ))}
    </>
  );
};

interface PolicyRowProps {
  policy: Policy | Binder;
  isBinder?: boolean;
}

export const AgencyDashboardPolicyRow = ({
  policy,
  isBinder,
}: PolicyRowProps) => {
  const theme = useTheme<any>();
  const analytics = useAnalytics();
  const isPolicyStatus = Ramda.equals(policy.status);
  const isLegacyProduct = determineIsLegacyProduct(policy.product);
  const targetDate = isBinder ? policy.effectiveDate : policy.endDate;
  const targetPath = isBinder
    ? '/agency/binders'
    : deriveManagePolicyBasePath(policy as Policy);
  let secondaryText: 'Endorsement' | 'New Business' | 'Renewal' =
    'New Business';

  if (policy.isEndorsement) {
    secondaryText = 'Endorsement';
  } else if (policy.isRenewal) {
    secondaryText = 'Renewal';
  }

  const handleAccountLinkClick = () => {
    analytics.track(`nav_to_${ANALYTICS_EVENTS.nav.clientProfile}`, {
      source: isBinder
        ? ANALYTICS_PROPERTIES.source.bindersTab
        : ANALYTICS_PROPERTIES.source.policiesTab,
    });
  };

  const handlePolicyLinkClick = () => {
    analytics.track(
      `nav_to_${
        isBinder ? ANALYTICS_EVENTS.nav.binders : ANALYTICS_EVENTS.nav.policies
      }`,
      {
        source: isBinder
          ? ANALYTICS_PROPERTIES.source.bindersTab
          : ANALYTICS_PROPERTIES.source.policiesTab,
      }
    );
  };

  return (
    <AgencyDashboardRowContent>
      <RowCell width="15%">
        <Box display="flex" alignItems="center">
          <UrgencyCalendar
            dueDate={targetDate}
            // @ts-expect-error UrgencyCalendar needs converted to TS
            Icon={AccessTimeIcon}
          />
          <Box ml="0.2rem">
            <Typography
              variant="body2"
              style={{ color: theme.agencyDash.text.primary }}
            >
              {toUniversalUtcDate(targetDate)}
            </Typography>
          </Box>
        </Box>
      </RowCell>
      <ColumnDivider />
      <RowCell width="30%">
        <Link
          to={`/agency/account/${policy.accountId}`}
          onClick={handleAccountLinkClick}
        >
          <HyperlinkText>
            <TruncatedTextWithTooltip
              text={policy.companyName}
              length={26}
              isMultiline
            />
          </HyperlinkText>
        </Link>
      </RowCell>
      <ColumnDivider />
      <RowCell width="25%">
        <Box display="flex" flexDirection="column">
          <Link
            to={`${targetPath}?search=${policy.id}&after=${earliestPossibleEntityDate}`}
            onClick={handlePolicyLinkClick}
          >
            <HyperlinkText>
              <TruncatedTextWithTooltip
                text={policy.policyNumber!}
                length={20}
              />
            </HyperlinkText>
          </Link>
          <SecondaryText variant="caption">{secondaryText}</SecondaryText>
        </Box>
      </RowCell>
      <ColumnDivider />
      <RowCell width="15%" style={{ justifyContent: 'center' }}>
        <Typography
          variant="body2"
          style={{ color: theme.agencyDash.text.primary }}
        >
          {formatStatus(policy.status)}
        </Typography>
      </RowCell>
      <ColumnDivider />
      <RowCell width="15%" style={{ marginRight: 0, justifyContent: 'center' }}>
        <PolicyRowActionButton policy={policy} />
        <Show
          when={
            !isBinder &&
            !RESTRICTED_STATUSES.some(isPolicyStatus) &&
            !(policy as Policy).isBOR
          }
        >
          <Show when={isLegacyProduct}>
            <AgencyPoliciesMenuOptions policy={policy as Policy} />
          </Show>
          <Show when={!isLegacyProduct}>
            <AgencyPoliciesPrimeXMenuOptions policy={policy as Policy} />
          </Show>
        </Show>
        <Show when={!!isBinder}>
          <Show when={isLegacyProduct}>
            <AgencyBindersMenuOptions binder={policy as Binder} />
          </Show>
          <Show when={!isLegacyProduct}>
            <AgencyBindersPrimeXMenu binder={policy as Binder} />
          </Show>
        </Show>
      </RowCell>
    </AgencyDashboardRowContent>
  );
};

export const PolicyRowDetail = ({ policy, isBinder }: PolicyRowProps) => {
  const targetLabel = isBinder ? 'Binder Expires' : 'Effective Date';
  const targetDate = isBinder ? policy.binderExpiresOn : policy.effectiveDate;

  const language = getRegionByProduct(policy.product);
  const currencySymbol = getCurrencySymbolByLanguage(language);
  const { translations } = useCowbellTranslations(['Retention']);
  const isPrimeXProduct = Object.values(PrimeXProductTypes).includes(
    policy.product as PrimeXProductType
  );

  return (
    <AgencyDashboardRowDetail>
      <RowCell width="25%">
        <Box display="flex" flexDirection="column">
          <strong>{targetLabel}</strong>
          <>{toUniversalUtcDate(targetDate)}</>
        </Box>
      </RowCell>
      <RowCell width="25%">
        <Box display="flex" flexDirection="column">
          <strong>Premium</strong>
          <>{deriveCurrencyFormat(language, policy.premium)}</>
        </Box>
      </RowCell>
      <RowCell width="25%">
        <Box display="flex" flexDirection="column">
          <strong>Limit</strong>
          <>{`${currencySymbol}${numberToCurrency(policy.limit, '0a')}`}</>
        </Box>
      </RowCell>
      <RowCell width="25%">
        <Box display="flex" flexDirection="column">
          <strong>
            {isPrimeXProduct ? translations.Retention : 'Deductible'}
          </strong>
          <>
            {deriveCurrencyFormat(
              language,
              policy.deductible,
              NO_DECIMAL_FORMAT
            )}
          </>
        </Box>
      </RowCell>
    </AgencyDashboardRowDetail>
  );
};

export const PolicyRowActionButton = ({ policy }: PolicyRowProps) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const analytics = useAnalytics();

  const handleManagePolicy = () => {
    analytics.track(MANAGE_POLICY_EVENT, getManagePolicyProps(2));
    const basePath = deriveManagePolicyBasePath(policy as Policy);
    history.push(
      `${basePath}?search=${policy.policyNumber}&after=${earliestPossibleEntityDate}`
    );
  };

  const handleRenew = () => {
    analytics.track(ANALYTICS_EVENTS.renewPolicy, {
      version: ANALYTICS_PROPERTIES.version.two,
    });
    const targetLocation = determineRenewalRoute({
      productType: policy.product,
      policyId: policy.id,
      accountId: policy.accountId,
    });
    if (targetLocation) {
      return history.push(targetLocation);
    }
  };

  const handleIssuePolicy = () => {
    analytics.track(ISSUE_POLICY_EVENT, getIssuePolicyProps(2));

    fetchBinders({ search: policy.policyNumber }).then(
      (resp: AxiosResponse) => {
        const binder = resp.data.content?.[0] as Binder | undefined;
        if (!binder) {
          return enqueueSnackbar(
            'We were unable to locate that binder. Please try again in a few minutes',
            { variant: 'error' }
          );
        }

        const isP100RenewalOrMigration =
          binder.product === ProductTypes.p100 &&
          getIsRenewalOrMigration(binder.isRenewal, binder.isMigration);

        if (isP100RenewalOrMigration) {
          const nextQueryParamString = qs.stringify(
            {
              product: binder.product,
              policyId: binder.id,
              quoteId: binder.quoteId,
            },
            { addQueryPrefix: true }
          );

          return history.push({
            pathname: `/agency/accounts/${binder.accountId}/renew/renewalSummary`,
            search: nextQueryParamString,
          });
        }
        history.push(`/agency/bind/quote/${binder.product}/${binder.quoteId}`);
      }
    );
  };

  switch (policy.status) {
    case BOUND_STATUS:
      return (
        <PrimaryActionButton onClick={handleIssuePolicy}>
          Issue Policy
        </PrimaryActionButton>
      );
    case IN_FORCE_STATUS:
    case ACTIVE_STATUS:
    case ISSUED_STATUS:
      return (
        <>
          {(policy as Policy).isOpenForRenewal ? (
            <PrimaryActionButton onClick={handleRenew}>
              Renew
            </PrimaryActionButton>
          ) : (
            <PrimaryActionButton onClick={handleManagePolicy}>
              Manage
            </PrimaryActionButton>
          )}
        </>
      );
    default:
      return <></>;
  }
};

const formatStatus = (status: string) => {
  if (status === BINDER_RESCINDED) {
    return 'RESCINDED';
  }
  return status;
};

const deriveManagePolicyBasePath = (policy: Policy) => {
  if (policy.isEndorsement) {
    return '/agency/policies/endorsements';
  }
  if (policy.isBOR) {
    return '/agency/policies/bor';
  }
  return '/agency/policies';
};

const RESTRICTED_STATUSES = [
  PolicyStatus.VOID_STATUS,
  PolicyStatus.CANCELLED_STATUS,
  PolicyStatus.REQUESTED_STATUS,
  PolicyStatus.CANCELLED_PENDING_STATUS,
  PolicyStatus.CANCELLED_IN_REINSTATEMENT_PERIOD,
  PolicyStatus.FLAT_CANCELLED_STATUS,
  PolicyStatus.INVALID_STATUS,
];
