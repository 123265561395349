import React from 'react';

export const MultiSelectStateContext = React.createContext();

export const MultiSelectStateProvider = ({ children, displayedRows }) => {
  const [selectedRows, setSelectedRows] = React.useState({});

  const isAllSelected = React.useMemo(() => {
    for (const row of displayedRows) {
      // eslint-disable-next-line
      if (!selectedRows.hasOwnProperty(row.id)) {
        return false;
      }
    }

    if (displayedRows.length == 0) {
      return false;
    }

    return true;
  }, [displayedRows, selectedRows]);

  const addRow = React.useCallback((rowId, rowData) => {
    setSelectedRows((prevState) => ({ ...prevState, [rowId]: rowData }));
  }, []);

  const removeRow = React.useCallback((rowId) => {
    setSelectedRows((prevState) => {
      const copy = { ...prevState };
      delete copy[rowId];
      return copy;
    });
  }, []);

  const addRows = React.useCallback(
    (rows) => {
      for (const row of rows) {
        addRow(row.id, row);
      }
    },
    [addRow]
  );

  const removeRows = React.useCallback(
    (rows) => {
      for (const row of rows) {
        removeRow(row.id);
      }
    },
    [removeRow]
  );

  const clearSelectedRows = React.useCallback(() => {
    setSelectedRows({});
  }, []);

  const handleRowSelect = React.useCallback(
    (rowId, rowData) => {
      // eslint-disable-next-line
      const isRowSelected = selectedRows.hasOwnProperty(rowId);

      if (!isRowSelected) {
        addRow(rowId, rowData);
      } else {
        removeRow(rowId);
      }
    },
    [addRow, removeRow, selectedRows]
  );

  const handleSelectAll = React.useCallback(() => {
    if (isAllSelected) {
      removeRows(displayedRows);
    } else {
      addRows(displayedRows);
    }
  }, [addRows, displayedRows, isAllSelected, removeRows]);

  const contextValue = React.useMemo(
    () => ({
      selectedRows,
      handleRowSelect,
      handleSelectAll,
      isAllSelected,
      clearSelectedRows,
    }),
    [
      clearSelectedRows,
      handleRowSelect,
      handleSelectAll,
      isAllSelected,
      selectedRows,
    ]
  );

  return (
    <MultiSelectStateContext.Provider value={contextValue}>
      {children}
    </MultiSelectStateContext.Provider>
  );
};

MultiSelectStateProvider.displayName = 'MultiSelectStateProvider';
