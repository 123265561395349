import React from 'react';
import { useSnackbar } from 'notistack';

// mui
import { Box, styled, Typography } from '@mui/material';

// components
import { ErrorOutlineOutlined } from '@mui/icons-material';

import { useQueryClient } from '@tanstack/react-query';
import { DialogBase } from '../../../../components/modals/DialogBase';
import CbButtonBase from '../../../../components/Buttons/CbButton';
import ConfirmBindQuote from '../../../../components/modals/ConfirmBindQuote';

// utils and helpers
import {
  AttestCheckBox,
  AttestStepSecondaryText,
} from './shared/AttestationStepBase';
import { withShowable } from '../../../_global/lib/withShowable';
import { useGetBinderInformation } from './shared/useGetBinderInformation';
import { SubjectivityCompletionDate as SubjectivityCompletionDateBase } from './shared/SubjectivityCompletionDate';
import {
  BOUND_STATUS,
  IN_REVIEW_STATUS,
  REFERRED_STATUS,
  SIGNED_STATUS,
} from '../../../_statics/quote.statics';
import { sleep } from '../../../../utils/appUtils';
import LoadingSpinnerOverlay from '../../../agencies/quotes/PrimeX/customizeQuote/components/LoadingSpinnerOverlay';
import SimpleTooltip from '../../../../components/SimpleTooltip';

const CbButton = withShowable(CbButtonBase);
const SubjectivityCompletionDate = withShowable(SubjectivityCompletionDateBase);

const BindP250Quote = ({
  goForward,
  goBack,
  completeStep,
  step,
  quoteDetails,
  incompletePreBindSteps = [],
  sortedPreIssueSteps: preIssueSteps = [],
  quoteProgress,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [bindQuoteAcknowledgement, setBindQuoteAcknowledgement] =
    React.useState(false);
  const [isPolling, setIsPolling] = React.useState(false);

  const { quoteId, productType } = quoteDetails.uiData;
  React.useEffect(() => {
    // refetch quote-details to get updated total premium on P250 quotes
    queryClient.refetchQueries(['quote-details', quoteId]);
  }, [queryClient, quoteId]);

  const { binder = {}, isLoadingBinderData } = useGetBinderInformation({
    quoteDetails,
    quoteProgress,
  });

  React.useEffect(() => {
    const pollQuoteProgress = async () => {
      setIsPolling(true);
      // eslint-disable-next-line no-plusplus
      for (let timesPolled = 0; timesPolled <= 5; timesPolled++) {
        await queryClient.invalidateQueries({
          queryKey: ['quote-progress', quoteId],
        });

        const { quoteProgress: quoteProgressCache } =
          await queryClient.getQueryData({
            queryKey: ['quote-progress', quoteId],
            exact: false,
          });

        if (!quoteProgressCache.isUpdating) {
          return setIsPolling(false);
        }

        await sleep(1000);
      }

      setIsPolling(false);
    };

    if (quoteProgress.isUpdating) {
      pollQuoteProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (incompletePreBindSteps.length) {
    if (!preIssueSteps.length) {
      enqueueSnackbar(
        'You must complete all pre-bind subjectivities before quote can be bound.',
        { variant: 'error' }
      );
      goBack();
    } else {
      goForward();
    }
    return null;
  }

  if (isLoadingBinderData) {
    return null;
  }

  const formattedBinderData = {
    ...binder,
    completedOn: binder.boundOn,
  };

  const isQuoteBound =
    quoteProgress.quoteAgencyStatus === BOUND_STATUS ||
    quoteProgress.quoteAgencyStatus === SIGNED_STATUS;

  const isQuoteReferred =
    quoteProgress.quoteAgencyStatus === IN_REVIEW_STATUS ||
    quoteProgress.quoteAgencyStatus === REFERRED_STATUS;

  const handleCheckboxChange = () => {
    setBindQuoteAcknowledgement((prevState) => !prevState);
  };

  const handleBindQuote = () => {
    setIsConfirmModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleGoNext = () => {
    goForward();
  };

  if (isPolling) {
    return (
      <LoadingSpinnerOverlay>
        <Box fontSize="1.25rem" className="contrast-text">
          Please wait a moment while we update your quote.
        </Box>
      </LoadingSpinnerOverlay>
    );
  }

  if (isQuoteReferred) {
    return (
      <Box className="contrast-text" width="70%">
        <Typography
          variant="h4"
          style={{ fontWeight: 600, fontSize: '1.5rem' }}
        >
          Attest and Bind Quote
        </Typography>

        <ErrorMessageContainer>
          <ErrorOutlineOutlined />
          This quote has been referred to an underwriter for review and cannot
          be bound at this time. Please check back later.
        </ErrorMessageContainer>

        <Box paddingTop="1rem">
          <CbButton styleName="cancel" size="medium" onClick={goBack}>
            Back
          </CbButton>
          <CbButton styleName="ctaButton" size="medium" onClick={handleGoNext}>
            Next
          </CbButton>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box className="contrast-text" maxWidth="65%">
        <Typography
          variant="h4"
          style={{ fontWeight: 600, fontSize: '1.5rem' }}
        >
          Attest and Bind Quote
        </Typography>

        <AttestCheckBox
          name="bindQuoteAcknowledgement"
          handleCheckboxChange={handleCheckboxChange}
          checked={isQuoteBound || undefined}
          disabled={isQuoteBound}
        />
        <AttestStepSecondaryText disabled={isQuoteBound} />

        <SubjectivityCompletionDate
          allSubjectivityInformation={formattedBinderData}
          completionText="Quote Bound On:"
          show={isQuoteBound}
        />

        <Box paddingTop="1rem" display="flex">
          <CbButton styleName="cancel" size="medium" onClick={goBack}>
            Back
          </CbButton>

          <BindButtonWrapper isUpdating={quoteProgress.isUpdating}>
            <CbButton
              styleName="ctaButton"
              size="medium"
              disabled={!bindQuoteAcknowledgement || quoteProgress.isUpdating}
              onClick={handleBindQuote}
              show={!isQuoteBound}
            >
              Bind
            </CbButton>
          </BindButtonWrapper>
          <CbButton
            styleName="ctaButton"
            size="medium"
            onClick={handleGoNext}
            show={isQuoteBound}
          >
            Next
          </CbButton>
        </Box>
      </Box>

      <DialogBase
        open={isConfirmModalOpen}
        onClose={closeConfirmationModal}
        fullWidth
        maxWidth="sm"
        title={`Bind Quote for ${quoteDetails?.uiData?.companyName}`}
      >
        <ConfirmBindQuote
          data={{
            quoteDetails,
            goForward,
            step,
            completeStep,
            quoteProgress,
            quoteId,
            productType,
          }}
          close={closeConfirmationModal}
        />
      </DialogBase>
    </>
  );
};

export default BindP250Quote;

const BindButtonWrapper = ({ isUpdating, children }) => {
  if (isUpdating) {
    return (
      <SimpleTooltip title="Quote cannot be bound at this time. The quote is still updating. Please try again in a moment.">
        {children}
      </SimpleTooltip>
    );
  }

  return <>{children}</>;
};

const ErrorMessageContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  fontSize: '1.2rem',
  padding: '2rem 0',
});
