// components
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Showable from '../../../../components/Showable';

import { PROVIDER_AGENCY, PROVIDER_COWBELL } from './billing-compliance.consts';

export const ChangeSurplusComplianceForm = ({
  agencyName,
  provider,
  onChange,
  isAgencyComplianceEnabled,
}) => {
  function handleChange(event, nextValue) {
    if (typeof onChange === 'function') {
      onChange(nextValue);
    }
  }

  return (
    <FormControl variant="standard" component="fieldset">
      <FormLabel component="legend" className="contrast-text">
        Surplus Compliance
      </FormLabel>
      <Typography>
        Choose who is responsible for compliance on the policy.
      </Typography>
      <RadioGroup
        name="surplus-provider"
        value={provider}
        onChange={handleChange}
        style={{ marginTop: '0.833rem' }}
      >
        <FormControlLabel
          className="normalized-control-label"
          value={PROVIDER_COWBELL}
          control={<Radio color="secondary" />}
          label="Cowbell Insurance"
        />
        <FormControlLabel
          className="normalized-control-label"
          value={PROVIDER_AGENCY}
          control={<Radio color="secondary" />}
          label={`${agencyName} (Agency on file)`}
          disabled={!isAgencyComplianceEnabled}
        />
        <Showable show={!isAgencyComplianceEnabled}>
          <Typography variant="caption" style={{ marginLeft: '2rem' }}>
            Agency compliance is not supported for direct billed policies.
          </Typography>
        </Showable>
      </RadioGroup>
    </FormControl>
  );
};
