import { CleaveBase } from './CleaveBase';
import { TextFieldBase } from './TextFieldBase';

export const DaysField = ({ InputProps = {}, ...props }) => {
  return (
    <TextFieldBase
      type="number"
      inputProps={{
        min: 0,
        step: 1,
      }}
      InputProps={{
        ...InputProps,
        inputComponent: CleaveInput,
      }}
      {...props}
    />
  );
};

const CleaveInput = ({ inputRef, ...props }) => {
  return (
    <CleaveBase
      ref={inputRef}
      options={{
        numeral: true,
        numeralIntegerScale: 3,
        numeralDecimalScale: 0,
        numeralPositiveOnly: true,
        numeralThousandsGroupStyle: 'none',
      }}
      {...props}
    />
  );
};
