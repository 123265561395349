import * as Yup from 'yup';
// react
import React from 'react';

// mui
import { Grid as MuiGrid } from '@mui/material';
// components

import { withFormController } from '../../../../../../components/hocs/forms';
import { TextFieldBase } from '../../../../../../components/inputs/TextFieldBase';
import LanguageCurrencyFieldBase from '../../../../../../components/inputs/LanguageCurrencyField';

// actions
const validationKeys = (value) => {
  return ['Excess Quota Share Endorsement'].includes(value);
};

const TextField = withFormController(TextFieldBase);
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);

export const excessQuoteShareValidation = {
  coInsurers: Yup.string().when('endorsement', {
    is: validationKeys,
    then: Yup.string().required().label('Coinsurer(s)'),
  }),
  coInsurerPolicyNumber: Yup.string().when('endorsement', {
    is: validationKeys,
    then: Yup.string().label('Coinsurer Policy Number').required(),
  }),
  quotaShare: Yup.number().when('endorsement', {
    is: validationKeys,
    then: Yup.number()
      .min(0, 'Please enter a value greater than 0')
      .max(100, 'Please enter a value less than 100')
      .label('Cowbell Quota Share')
      .required(),
  }),
  limitOfLayer: Yup.number().when('endorsement', {
    is: validationKeys,
    then: Yup.number().fromCurrency().label('Limit of Layer').required(),
  }),
};

export const ExcessQuotaShare = () => {
  return (
    <>
      <MuiGrid container spacing={5}>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <TextField
            required
            name="coInsurers"
            label="Coinsurer(s)"
            fullWidth
          />
        </MuiGrid>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <TextField
            name="coInsurerPolicyNumber"
            label="Coinsurer Policy Number"
            fullWidth
            required
          />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container spacing={5}>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <TextField
            required
            type="number"
            name="quotaShare"
            label="Cowbell Quota Share %"
            fullWidth
          />
        </MuiGrid>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <LanguageCurrencyField
            required
            name="limitOfLayer"
            label="Limit of Layer"
            fullWidth
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
