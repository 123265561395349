import React from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

// lodash
import _ from 'lodash';

// components
import { Box, styled } from '@mui/material';
import {
  ModalActions,
  ModalContent,
  ModalDetails,
} from '../../console/workflows/bind-quote/stepper-screens/shared/ModalBase';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES,
  useAmplitude,
} from '../../providers/AmplitudeProvider';

// services and utils
import { useAgencyDashboardFeature } from '../../console/dashboard/agency/agency-dashboard.statics';
import { issuePolicy } from '../../api';
import { useAPIErrorHandler } from '../hooks/useAPIErrorHandler';
import { usePersona } from '../hooks/usePersona';
import { PoliciesTabsViewPersonaRoutes } from '../../views/mappings/persona-route/policies-tabs';
import { AGENCY_DASHBOARD_BASE_PATH } from '../../console/dashboard/agency/DashboardViewContainer';

const ConfirmIssuePolicy = ({ data = {}, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleAPIError = useAPIErrorHandler();
  const persona = usePersona();

  const history = useHistory();
  const amplitude = useAmplitude();
  const agencyDashboardFeature = useAgencyDashboardFeature();

  const [isLoading, setIsLoading] = React.useState(false);

  const companyName = _.get(data.quoteDetails.uiData, 'companyName', '');
  const policyNumber = _.get(data.quoteDetails.uiData, 'policyNumber', '');

  const handleIssuePolicy = () => {
    setIsLoading(true);
    issuePolicy({
      persona: persona.type,
      quoteId: data.quoteDetails.uiData.quoteId,
      productType: data.quoteDetails.uiData.productType,
    })
      .then(() => {
        amplitude.track(AMPLITUDE_EVENTS.issuePolicy, {
          [AMPLITUDE_PROPERTIES.quoteId]: data.quoteId,
        });
        enqueueSnackbar('Policy issued successfully', { variant: 'success' });
        close();
        data.completeStep(data.step);
        const targetRedirect =
          persona.isAgency && agencyDashboardFeature.on
            ? AGENCY_DASHBOARD_BASE_PATH
            : PoliciesTabsViewPersonaRoutes[persona.type];

        setTimeout(() => history.replace(targetRedirect), 800);
      })
      .catch(handleAPIError('Unable to Issue Policy. Please try again later'))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ModalContent>
        <Box>
          Are you sure you want to issue <Span>{policyNumber}</Span> for{' '}
          <Span>{companyName}</Span>?
        </Box>
        <ModalDetails quoteData={data.quoteDetails.uiData} />
        <p>
          <i>NOTE: Your policy will be emailed to you shortly.</i>
        </p>
      </ModalContent>
      <ModalActions onClick={handleIssuePolicy} isLoading={isLoading} />
    </>
  );
};

export default ConfirmIssuePolicy;

export const ConfirmIssuePolicyConfig = {
  ConfirmIssuePolicy: {
    component: ConfirmIssuePolicy,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      minWidth: 'sm',
    },
  },
};

const Span = styled('span')({
  fontWeight: 600,
});
