// mui
import { Box, Paper } from '@mui/material';
import { useTheme, styled } from '@mui/styles';

// components
import { useSelector } from 'react-redux';

import { AgencyDashboardListingModule } from './listing-tabs/ListingModule';
import { DashboardPrimaryActionsModule } from './modules/DashboardPrimaryActionsModule';
import { DashboardSecondaryActionsModule } from './modules/DashboardSecondaryActionsModule';
import { DashboardStatsBarModule } from './modules/DashboardStatsBarModule';

// utils
import { AgencyDashboardStateProvider } from './AgencyDashboardStateContext';
import { useAgencyDashboardStateOrchestrator } from './useAgencyDashboardStateOrchestrator';
import { selectNewAgentExperienceLayout } from '../../../store/selectors/ui-settings.selectors';

import { LAYOUT_COMPRESSED } from '../../../reducers/UiSettingsReducers';

// TODO: Change this name to AgencyDashboard once everything is ready.
export const DashboardViewContainer = () => {
  const theme = useTheme();

  const agencyDashboardStateOrchestrator =
    useAgencyDashboardStateOrchestrator();

  const layout = useSelector(selectNewAgentExperienceLayout);

  return (
    <AgencyDashboardStateProvider value={agencyDashboardStateOrchestrator}>
      <div
        style={{
          maxWidth: layout === LAYOUT_COMPRESSED ? 1660 : '100%',
          height: 'calc(100vh - 6.4rem)',
          margin: 'auto',
          padding: '0 1rem 1rem 1rem',
        }}
      >
        <Box className="flex full-height" display="flex" gap="1rem">
          <SidebarContainer
            style={{
              backgroundColor: theme.agencyDash.background.paper,
            }}
          >
            <div>
              <DashboardStatsBarModule />
              <DashboardPrimaryActionsModule />
            </div>
            <DashboardSecondaryActionsModule />
          </SidebarContainer>
          <AgencyDashboardListingModule />
        </Box>
      </div>
    </AgencyDashboardStateProvider>
  );
};

const SidebarContainer = styled(Paper)({
  display: 'flex',
  width: '33.33333%',
  maxWidth: '24rem',
  minWidth: '22rem',
  height: '100%',
  padding: '1rem',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowY: 'scroll',
});

export const AGENCY_DASHBOARD_BASE_PATH = '/agency/dashboard';
