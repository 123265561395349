import React from 'react';
import Moment from 'moment';
import { styled, useTheme } from '@mui/material';
import { ClaimsDueDateIcon as ClaimsDueDateIconBase } from '../../../../_assets/svg/claims/ClaimsDueDateIcon.svg';

export const UrgencyCalendar = ({
  dueDate: rawDueDate,
  taskStatus = '',
  Icon = ClaimsDueDateIcon,
}) => {
  const theme = useTheme();
  const urgencyCalendarColor = React.useMemo(() => {
    const today = Moment();
    const dueDate = Moment(rawDueDate);

    const tomorrow = Moment(today).add(1, 'days').startOf('day');
    const isDeadlineTomorrow = tomorrow.isSame(dueDate, 'd');
    const isDeadlineToday = today.isSame(dueDate, 'd');
    const isDeadlinePastDue = dueDate.isBefore(today);

    if (isDeadlineToday || isDeadlineTomorrow || isDeadlinePastDue) {
      return theme.agencyDash.text.error;
    }

    const aWeekFromNow = Moment(today).add(1, 'weeks');
    const twoDaysFromNow = Moment(today).add(1, 'days');

    const isDeadlineWithinAWeek = dueDate.isBetween(
      twoDaysFromNow,
      aWeekFromNow
    );

    if (isDeadlineWithinAWeek) {
      return theme.agencyDash.text.warning;
    }

    return 'transparent';
  }, [rawDueDate, theme.agencyDash.text.error, theme.agencyDash.text.warning]);

  if (!rawDueDate || taskStatus === 'Done') {
    return null;
  }

  return <Icon style={{ color: urgencyCalendarColor }} />;
};

const ClaimsDueDateIcon = styled(ClaimsDueDateIconBase)({
  '&:hover': {
    cursor: 'initial',
  },
});
