import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { TableFilterChips } from '../TableFilterChips';
import { useFilterState } from './useFilterState';
import { useQueryParams } from '../../../providers/QueryParamsProvider';

export const TableFilterChipsV2 = ({ tableId }) => {
  const history = useHistory();
  const location = useLocation();
  const { query, replace } = useQueryParams();
  const filterProps = useFilterState(tableId);
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <TableFilterChips
        filterProps={filterProps}
        routeProps={{ replace, q: query, history, location }}
      />
    </Box>
  );
};
