import React, { Component } from 'react';

import { DialogContent } from '@mui/material';

class certificateEmailConfirm extends Component {
  render() {
    return (
      <DialogContent>
        <div className="modal-title">
          Thank you for your request. You will get an email shortly, with the
          Certificate of Insurance!
        </div>
      </DialogContent>
    );
  }
}

export default certificateEmailConfirm;
