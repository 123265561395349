import React from 'react';
import { Dialog } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { NotesContainer } from '../../../console/_tabs/notesTab/NotesContainer';

export const UWInboxNotesModalV2 = ({ data, ...props }) => {
  const handleNotes = props.close;
  const classes = useStyles();
  return (
    <Dialog classes={classes} maxWidth="false" {...props}>
      <NotesContainer handleNotes={handleNotes} {...data} />
    </Dialog>
  );
};

export const UWInboxNotesModalV2Config = {
  UWInboxNotesModalV2: {
    component: UWInboxNotesModalV2,
    config: {
      fullWidth: true,
      override: true,
    },
  },
};

const useStyles = makeStyles(() => ({
  paper: { width: '50rem' },
}));
