import React, { useCallback, cloneElement, Children } from 'react';
// helpers
import _ from 'lodash';
// mui
import { List, ListItem, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { withStyles } from '@mui/styles';

// components
import ProtectedComponent from '../main/protected/ProtectedComponent';

export const SubMenu = withStyles(({ config }) => ({
  root: {
    padding: 0,
    color: config.colors.white,
    '&:hover, &:focus': {
      background: 'none !important',
      fontWeight: 'normal',
    },
  },
  gutters: {
    padding: 0,
  },
  text: {
    marginTop: 0,
  },
  list: {
    padding: '0.4166666667rem 0.8333333333rem',
    '&:hover': {
      color: config.colors.white,
      background: '#113E68',
      fontWeight: 600,
    },
    '&:focus': {
      color: config.colors.white,
      fontWeight: 600,
    },
  },
}))(({ classes, children, closeMenu, header, show = true }) => {
  if (!show) {
    return null;
  }
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = useCallback(() => {
    if (_.isFunction(closeMenu)) {
      closeMenu();
    }

    // eslint-disable-next-line
  }, []);

  const createClickHandler = useCallback(
    (childOnClick) => {
      return (event) => {
        if (_.isFunction(childOnClick)) {
          childOnClick(event);
        }
        handleClose();
      };
    },
    // eslint-disable-next-line
    []
  );

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      classes={{ root: classes.root }}
    >
      <ListItem
        button
        onClick={handleClick}
        classes={{ gutters: classes.gutters, root: classes.list }}
      >
        <ListItemText
          primary={header}
          disableTypography
          classes={{ root: classes.text }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {Children.map(children, (child) => {
            if (child && child.props) {
              return cloneElement(child, {
                classes: {
                  gutters: classes.gutters,
                  root: classes.list,
                  ...child.props.classes,
                },
                onClick: createClickHandler(child.props.onClick),
              });
            }
            return <span />;
          })}
        </List>
      </Collapse>
    </List>
  );
});

export const ProtectedSubMenu = ({
  allow,
  allowEither,
  allowIfAll,
  name,
  ...props
}) => {
  return (
    <ProtectedComponent
      allow={allow}
      allowEither={allowEither}
      allowIfAll={allowIfAll}
    >
      <SubMenu name={name} {...props} />
    </ProtectedComponent>
  );
};
