import clsx from 'classnames';
// mui
import { Box, Grid, Typography } from '@mui/material';

const P100RenewalIssuePolicyConfirmation = ({ data }) => {
  const { account } = data;
  const { name: accountName } = account;

  return (
    <Grid container spacing={2} align="center">
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1" className={clsx('contrast-text')}>
            Renewal policy has been issued for {accountName}.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default P100RenewalIssuePolicyConfirmation;
