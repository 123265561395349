import { useQueryClient } from '@tanstack/react-query';

export function withQueryClient(C) {
  const Wrapper = (props) => {
    const queryClient = useQueryClient();
    return <C queryClient={queryClient} {...props} />;
  };

  Wrapper.displayName = 'withQueryClient';

  return Wrapper;
}
