import React from 'react';
// mui
import {
  ListItem as MuiListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import StopIcon from '@mui/icons-material/Stop';
// components
import { withShowable } from '../../console/_global/lib/withShowable';
import ProtectedComponent from '../main/protected/ProtectedComponent';

const ListItem = withShowable(MuiListItem);

export const ListItemBase = ({ name, show = true, ...props }) => {
  if (!show) {
    return null;
  }

  const classes = styles();

  return (
    <ListItem button {...props}>
      <ListItemIcon classes={{ root: classes.icon }}>
        <StopIcon style={{ fontSize: '0.6rem' }} />
      </ListItemIcon>

      <ListItemText
        primary={name}
        disableTypography
        classes={{ root: classes.text }}
      />
    </ListItem>
  );
};

export const ProtectedListItem = ({
  allow,
  allowEither,
  allowIfAll,
  name,
  ...props
}) => {
  return (
    <ProtectedComponent
      allow={allow}
      allowEither={allowEither}
      allowIfAll={allowIfAll}
      {...props}
    >
      <ListItemBase name={name} {...props} />
    </ProtectedComponent>
  );
};

const styles = makeStyles(({ config }) => {
  return {
    icon: {
      minWidth: '1.5rem',
      marginLeft: '1rem',
      color: config.colors.white,
    },
    text: {
      margin: 0,
    },
  };
});
