const ActionTypes = {
  // User
  USER_LOGOUT: 'USER_LOGOUT',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  // Manage Account Profile
  FETCH_ACCOUNT_SUCCEEDED: 'FETCH_ACCOUNT_SUCCEEDED',
  UPDATE_ACCOUNT_SUCCEEDED: 'UPDATE_ACCOUNT_SUCCEEDED',
  SEARCH_NAICS_CODES_SUCCEEDED: 'SEARCH_NAICS_CODES_SUCCEEDED',
  GET_PROFILE_SCORE_SUCCEEDED: 'GET_PROFILE_SCORE_SUCCEEDED',
  // Payments
  GET_PAYMENT_PROCESSOR: 'GET_PAYMENT_PROCESSOR',
  // Manage User Account Profile
  UPDATE_CURRENT_USER_FAILURE: 'update_current_user_FAILURE',
  // Policy
  UPDATE_EXCESS_DETAILS: 'UPDATE_EXCESS_DETAILS',
};

export default ActionTypes;
