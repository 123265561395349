import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
// components
import { useQueryClient } from '@tanstack/react-query';
import { Box, DialogContent } from '@mui/material';

import { withStyles } from '@mui/styles';
import Check from '../../_assets/svg/Check.svg';
// platform helpers
import { PubSub } from '../../utils/eventUtils';
// actions
import {
  addTagToExistingAccount,
  getTags,
} from '../../accounts/AccountService';
import { useToggleModal } from '../../utils/modal.utils';
import { AGENCY_ACCOUNTS } from '../tables/table_constants';

const ApplyChangeTags = ({ classes, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();
  const queryClient = useQueryClient();

  const accountId = data.id;
  const currentTags = data.tags;
  const [myData, setMyData] = useState({
    tags: [],
    ready: false,
  });

  useEffect(() => {
    if (!myData.ready) {
      Promise.all([getTags()]).then((resp) => {
        const tagsData = _.get(resp[0], 'data.tags', []);
        const tags = {};
        tagsData.forEach((tag) => {
          tags[tag] = {
            name: tag,
            selected: currentTags && currentTags.indexOf(tag) > -1,
          };
        });
        setMyData((prevState) => {
          return {
            ...prevState,
            ready: true,
            tags,
          };
        });
      });
    }

    const sub = PubSub.subscribe('foot-button-one', (eventData) => {
      if (eventData) {
        onSubmit();
      }
    });

    return () => {
      sub.remove();
    };
  });

  const toggleTag = (tag) => () => {
    const { tags } = myData;
    const { selected } = tags[tag.name];
    tags[tag.name].selected = !selected;
    setMyData((prevState) => {
      return {
        ...prevState,
        tags,
      };
    });
  };

  const renderTags = () => {
    const { tags } = myData;
    const elements = [];
    _.map(tags, (tag) => {
      elements.push(
        <Box
          className={`${classes.tagContainer} ${
            tag.selected ? classes.tagSelected : ''
          }`}
          onClick={toggleTag(tag)}
        >
          <div style={{ display: 'flex' }}>
            {tag.name}
            {tag.selected ? (
              <Check style={{ marginLeft: '0.833rem' }} fill="#ffffff" />
            ) : (
              <AddOutlinedIcon
                fontSize="medium"
                style={{ marginLeft: '0.833rem' }}
              />
            )}
          </div>
        </Box>
      );
    });
    return <section className={classes.tagsContainer}>{elements}</section>;
  };

  const onSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    const { tags } = myData;
    const arr = [];
    _.map(tags, (tag) => {
      if (tag.selected) {
        arr.push(tag.name);
      }
    });

    addTagToExistingAccount({ accountId }, arr)
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        toggleModal.direct('ApplyChangeTags', false);
        enqueueSnackbar('Tag Applied Successfully!', { variant: 'success' });
        queryClient.invalidateQueries([AGENCY_ACCOUNTS]);
      })
      .catch((error) => {
        console.error(error);
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
      });
  };

  if (!myData.ready) {
    return null;
  }

  const renderBody = () => {
    if (Object.keys(myData.tags).length > 0) {
      return (
        <form onSubmit={onSubmit} id="submit-form">
          <h3 className={classes.header}>
            CLICK TO ADD/EDIT TAG(S) FOR THIS ACCOUNT
          </h3>
          {renderTags()}
        </form>
      );
    }

    return (
      <section>
        No tags found, {` `}
        <Link
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          to="/agency/manage/tags"
          onClick={toggleModal.deferred('ApplyChangeTags', false)}
        >
          Setup Tags
        </Link>
      </section>
    );
  };

  return (
    <DialogContent className={classes.container}>{renderBody()}</DialogContent>
  );
};

const styles = ({ palette, config }) => {
  return {
    tagsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    tagContainer: {
      height: '2.66rem',
      borderRadius: 16,
      border: `1.3px solid ${palette.primary.contrastText}`,
      '&:hover': {
        border: `1.3px solid ${config.colors.snackGreen}`,
      },
      textAlign: 'center',
      padding: '0.6rem 1.10rem 0.525rem 1.33rem',
      margin: '0.5rem',
      cursor: 'pointer',
      color: palette.primary.contrastText,
      lineHeight: 1.33,
      fontSize: config.textSizes.normal,
    },
    container: {
      width: '35rem',
      height: 'fit-content',
      margin: 'auto',
      textAlign: 'center',
    },
    tagSelected: {
      border: `1.3px solid ${config.colors.snackGreen}`,
      background: config.colors.snackGreen,
    },
    header: {
      color: palette.primary.contrastText,
      fontWeight: config.weights.bold,
    },
  };
};

export default withStyles(styles)(ApplyChangeTags);
