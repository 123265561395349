import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { getDocsInfo } from '../../../../../api/documents.api';

const useGetSubjectivityDocs = ({
  quoteSubjectivityId,
  accountId,
  uploadedDocs,
  isFetchingSubjectivityData,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const docIds = uploadedDocs?.map(({ docId }) => docId);

  return useQuery(
    ['subjectivity-docs', quoteSubjectivityId, accountId],
    () => {
      return getDocsInfo(accountId, {
        params: {
          docIds,
        },
      }).then(({ data }) => {
        const formattedDocsData = data.content.map((docData, index) => {
          return {
            ...docData,
            docSignedOn: uploadedDocs[index].docSignedOn || null,
          };
        });
        return formattedDocsData;
      });
    },
    {
      enabled: !!uploadedDocs && !isFetchingSubjectivityData,
      onError: () => {
        enqueueSnackbar('Unable to retrieve doc information.', {
          variant: 'error',
        });
      },
    }
  );
};

export default useGetSubjectivityDocs;
