import React from 'react';

import { startDownload, handleDownload } from '../utils/csvUtils';
import { PubSub } from '../utils/eventUtils';

import type { WithChildrenProps } from '../types/components/common';

const CSV_DOWNLOAD_TOPIC = 'csv:download';

function CSVDownloadSubWrapper({ children }: WithChildrenProps) {
  React.useEffect(() => {
    const csvSub = PubSub.subscribe(
      CSV_DOWNLOAD_TOPIC,
      (payload: CSVDownloadSubCallbackNamedArguments) => {
        startDownload(payload).then(handleDownload);
      }
    );

    return function cleanUp() {
      csvSub.remove();
    };
  }, []);

  return <>{children}</>;
}

export default CSVDownloadSubWrapper;

type CSVDownloadSubCallbackNamedArguments = {
  filename: string;
  columns: Record<string, unknown>[];
  data: Record<string, unknown>[];
};

export function triggerCSVDownload({
  columns,
  data,
  filename,
}: CSVDownloadSubCallbackNamedArguments) {
  PubSub.publish(CSV_DOWNLOAD_TOPIC, {
    columns,
    data,
    filename,
  });
}
