import React from 'react';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import CbButton from '../../Buttons/CbButton';

const P100CalculatorError = ({ ...props }) => (
  <>
    <DialogContent style={{ padding: '2.4rem', paddingBottom: '1.8rem' }}>
      <DialogContentText>
        Oops! It looks like there was a problem calculating your quote premium.
        It may be an issue with your security questions, the limits you've
        selected, or your reported revenue.
      </DialogContentText>
      <DialogContentText style={{ marginTop: '0.3rem' }}>
        You can also try refreshing the page once or twice. If you are still
        unable to calculate a quote, please contact an administrator to resolve
        the issue.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <CbButton styleName="ctaButton" onClick={props.close}>
        Got It
      </CbButton>
    </DialogActions>
  </>
);

export const P100CalculatorErrorConfig = {
  P100CalculatorError: {
    component: P100CalculatorError,
    config: {
      fullWidth: true,
      title: 'Quote Calculator Error',
    },
  },
};
