import React from 'react';
import { useHistory } from 'react-router-dom';
// mui
import {
  DialogActions,
  DialogContent as DialogContentBase,
  styled,
} from '@mui/material';
// helpers
import { useSnackbar } from 'notistack';
// component
import CbButton from '../../Buttons/CbButton';
// utils
import { manageAPIError } from '../../../utils';
import { sendEmailText } from '../../../console/workflows/bind-quote/utils/bindQuoteWorkFlowUtils';

const EmailSignLinkConfirmation = ({ data: modalData = {}, close }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleConfirm = () => {
    setIsLoading(true);

    modalData
      .handleAsyncSubmit()
      .then(() => {
        enqueueSnackbar('E-sign link sent to customer!', {
          variant: 'success',
        });
        close();
        setTimeout(() => history.push('/agency/binders'), 500);
      })
      .catch((error) => {
        enqueueSnackbar(
          manageAPIError(error, 'Unable to send e-sign link at this time.'),
          { variant: 'error' }
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <DialogContent className="contrast-text">
        {sendEmailText(modalData?.quoteDetails?.uiData)}
      </DialogContent>
      <DialogActions>
        <CbButton
          styleName="ctaButton"
          buttonText="Confirm"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleConfirm}
        />
      </DialogActions>
    </>
  );
};

export default EmailSignLinkConfirmation;

const DialogContent = styled(DialogContentBase)({
  fontSize: '1.2rem',
  textAlign: 'center',
  padding: '1rem 2rem',
});

export const EmailSignLinkConfirmationConfig = {
  EmailSignLinkConfirmation: {
    component: EmailSignLinkConfirmation,
    config: {
      maxWidth: 'md',
      minWidth: 'sm',
    },
  },
};
