import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CheckBoxOutlineBlankIcon({
  width = 24,
  height = 24,
  fill,
  ...props
}) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <g transform="translate(13318 15758)">
        <path
          d="M0,0H24V24H0Z"
          transform="translate(-13318 -15758)"
          fill="none"
        />
        <path
          d="M0,0V18H18V0ZM16.29,16.29H1.8V1.8H16.38Z"
          transform="translate(-13315.5 -15755)"
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
}
