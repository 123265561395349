import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { ConnectorSteps } from './connectors/ConnectorSteps';

import { getConnectorSchema } from '../../../api/connectors/connectors.api';

export const GenericConnector = ({ data: tileData, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { cbid, connectorType } = tileData;

  const handleClose = () => {
    props.close();
  };

  const { data, isLoading } = useQuery(
    ['connectors'],
    () => {
      return getConnectorSchema(connectorType).then((resp) => resp);
    },
    {
      placeholderData: {},
      onError: () => {
        enqueueSnackbar(
          'There was a problem loading connector data, please try again',
          { variant: 'error' }
        );
      },
    }
  );

  if (isLoading || _.isEmpty(data)) {
    return null;
  }

  return (
    <ConnectorSteps
      handleClose={handleClose}
      schema={data}
      cbid={cbid}
      connectorType={connectorType}
    />
  );
};

export const GenericConnectorConfig = {
  GenericConnector: {
    component: GenericConnector,
    // all mui related config
    config: {
      maxWidth: 'sm',
    },
  },
};
