// components
import {
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  Typography,
  styled,
  Box,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PolicyEndorsementListing from '../../../policyholder/PolicyHolderEndorsementListing';
import { CancelledPolicySummary } from '../policy-information/CancelledPolicySummary';
import { PolicySummary } from '../policy-information/PolicySummary';

// other
import { PolicyCoverageDetails } from '../policy-information/PolicyCoverageDetails';

export const CancelledPolicyContent = ({ product, policy }) => {
  return (
    <>
      <CancelledPolicySummary policy={policy} />
      <Accordion square>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="details">
          <Typography color="primary">More Details</Typography>
        </AccordionSummary>

        <AccordionDetails className="newline pan">
          <Box mt={1}>
            <PolicySummary policy={policy} />
          </Box>
          <PolicyEndorsementListing
            policy={policy}
            productType={product}
            headerText="MIDTERM ENDORSEMENTS"
          />
          <PolicyCoverageDetails policy={policy} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  margin: 0,
  backgroundColor: theme.palette.background.default,

  '& > div': {
    margin: '0 !important',
  },
}));
