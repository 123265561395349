import React from 'react';
import { useSelector } from 'react-redux';

import { determineIfAllowedScopes } from '../../utils/next/auth.utils';
import { type Permissions } from './permissions';
import type { RootStore } from '../../types';

type childrenFunction = (isAllowed: boolean) => JSX.Element;

interface AllowProps {
  allow: Permissions[] | 'any';
  requireAll?: boolean;
  mergePropsWithChildren?: boolean;
  children: JSX.Element | childrenFunction;
}

export const Allow: React.FC<AllowProps> = ({
  allow = 'none' as const,
  requireAll = false,
  mergePropsWithChildren = true,
  children,
  ...props
}) => {
  const scopes = useSelector((state: RootStore) => state.scopes);
  const isAllowed = determineIfAllowedScopes(scopes, allow, requireAll);

  if (typeof children === 'function') {
    return children(isAllowed);
  }

  if (!isAllowed) {
    return null;
  }

  if (mergePropsWithChildren) {
    return (
      <>
        {React.Children.map(
          children as JSX.Element,
          mergeParentChildProps(props)
        )}
      </>
    );
  }

  return <>{children}</>;
};

function mergeParentChildProps(props: Record<string, any>) {
  return function mergeParentChildPropsInner(child: React.ReactElement) {
    return React.cloneElement(child, { ...props, ...child.props });
  };
}
