import { useQuery } from '@tanstack/react-query';

import { Avatar, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import Showable from '../../../../components/Showable';

import { getAgencyLogoSmall } from '../../../../api/AuthService';
import { useActor } from '../../../../components/hooks/useActor';

export const DashboardAgentGreeting = () => {
  const actor = useActor();
  const theme = useTheme();

  const { account } = actor;

  const { data: logoURL } = useQuery(
    ['account', 'logo', 'small', account.id],
    () =>
      getAgencyLogoSmall(account.id).then((resp) => {
        return resp.status === 200 ? resp.data : null;
      }),
    { cacheTime: 60 * 10000, enabled: Boolean(account.id) }
  );

  return (
    <Box className="flex justify-between" padding="1rem 1rem 0 1rem">
      <Box>
        <Typography>
          Good {getDayTimeLanguage()} {actor.firstName},
        </Typography>
        <Typography className="tertia-text">
          Welcome to your workspace
        </Typography>
      </Box>
      <Box className="flex align-center">
        <Box className="text-right">
          <Typography>{account.name}</Typography>
          <Typography
            className="tertia-text"
            style={{ color: theme.agencyDash.text.secondary }}
          >
            {account.locationAlias}
          </Typography>
        </Box>
        <Showable show={Boolean(logoURL)}>
          <Avatar
            src={logoURL}
            variant="circular"
            style={{ width: '2.8rem', height: '2.8rem', margin: '0 0.8rem' }}
          />
        </Showable>
      </Box>
    </Box>
  );
};

function getDayTimeLanguage() {
  const hour = new Date().getHours();

  if (hour < 12) {
    return 'Morning';
  }

  if (hour >= 12 && hour < 17) {
    return 'Afternoon';
  }

  if (hour >= 17) {
    return 'Evening';
  }
}
