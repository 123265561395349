import _ from 'lodash';
import * as Yup from 'yup';
import React, {
  useCallback,
  useReducer,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
// mui
import {
  DialogContent,
  FormHelperText,
  Grid,
  IconButton,
  Grid as MuiGrid,
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';

import CancelIconOutlined from '@mui/icons-material/CancelOutlined';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// components
import Moment from 'moment';
import { withFormController } from '../../../components/hocs/withFormController';

import { ActionBar } from '../../../components/tables/tables-new/base/action-bar.table';
import { EnhancedTable } from '../../../components/tables/tables-new/variations/enhanced.table';

import {
  setPagination,
  archiveQuotesReducer,
  initialState,
} from '../../_reducers/archiveQuote.reducer';
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import { createReminder, getReminder, deleteReminder } from '../sales.service';
import { delayedEvent } from '../../../utils/eventUtils';

const TextField = withFormController(TextFieldBase);

const defaultValues = {
  remindOn: '',
  note: '',
};

const schema = Yup.object().shape({
  remindOn: Yup.string().label('Date').required(),
  note: Yup.string().label('Note').required(),
});

export const CreateReminderModal = withStyles({
  selectLabel: {
    paddingTop: '0.5rem',
  },
})(({ classes, data }) => {
  const { enqueueSnackbar } = useSnackbar();

  // styles
  const style = remiderStyles();
  useRowStyles();
  useCellStyles();
  useHeadStyles();

  // reducer calling pagination logic
  const [state, dispatch] = useReducer(archiveQuotesReducer, initialState);

  useEffect(() => {
    // Main three search paramaters
    const params = {
      accountId: data.accountId,
      after: Moment().subtract(1, 'y').toISOString(),
      before: Moment().add(1, 'y').toISOString(),
      size: state.rowsPerPage,
      page: state.page,
    };

    setTimeout(() => {
      getReminder(params)
        .then(({ data: reminderData }) => {
          dispatch(setPagination({ data: reminderData.hits }));
          dispatch(setPagination({ totalRows: reminderData.hits.length }));
          makeRefreshCall();
        })
        .catch((error) => {
          const errorMessage = _.get(
            error.response,
            'data',
            'Server error! Please try again soon.'
          );
          enqueueSnackbar(errorMessage, { variant: 'error' });
        });
    }, 1000);

    // eslint-disable-next-line
  }, [state.rowsPerPage, state.page, state.totalRows]);

  const {
    handleSubmit,
    formState: { isSubmitting, dirty },
    reset,
    ...methods
  } = useForm({ validationSchema: schema });

  // State utilized for handling backendErrors
  const [backendErrors, setBackendError] = useState('');

  const onSubmit = (formData) => {
    const payload = {
      ...formData,
      leadId: data.id,
    };
    reset(defaultValues);
    return createReminder({}, payload)
      .then(() => {
        enqueueSnackbar('Reminder created successfully!', {
          variant: 'success',
        });
        setTimeout(() => {
          dispatch(setPagination({ totalRows: state.totalRows + 1 }));
        }, 1000);
        makeRefreshCall();
      })
      .catch((error) => {
        const errorMessage = _.get(
          error.response,
          'data',
          'Server error! Please try again soon.'
        );
        setBackendError(errorMessage);
      });
  };

  // callback functions linked to reducer dispatch
  function onPageChange(event, nextPage) {
    dispatch(setPagination({ page: nextPage }));
  }

  function changePageSize(event, target) {
    dispatch(setPagination({ rowsPerPage: target.key }));
  }

  const onSelectRow = useCallback(
    (id) => {
      return () => {
        deleteReminder({ reminderId: id })
          .then(() => {
            enqueueSnackbar('Reminder successfully deleted!', {
              variant: 'success',
            });
            dispatch(setPagination({ totalRows: state.totalRows - 1 }));
          })
          .catch((error) => {
            const errorMessage = _.get(
              error.response,
              'data',
              'Server error! Please try again soon.'
            );
            enqueueSnackbar(errorMessage, { variant: 'error' });
          });
      };
    },
    [enqueueSnackbar, state.totalRows]
  );

  // context utilized for EnhancedTable that will channel down to BaseTable
  const context = useMemo(
    () => ({
      style: {
        useRowStyles,
        useCellStyles,
        useHeadStyles,
      },
      classes,
      page: state.page,
      totalRows: state.totalRows,
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: state.rowsPerPage,
      onPageChange,
      onSelectRow,
      changePageSize,
    }),
    [onSelectRow, state.totalRows, state.page, state.rowsPerPage, classes]
  );

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} data-qa="addAgency">
        <DialogContent>
          <Grid container spacing={1} className={classes.headerGrid}>
            <Grid item md={4} />
            <Grid
              item
              md={8}
              style={{ textAlign: 'right' }}
              className={{ padding: 0 }}
            >
              <h1
                className={{
                  ...style.miniLabel,
                  ...{ marginBlockStart: 0, marginBlockEnd: 0 },
                }}
              >
                Today: {Moment().format('dddd, MMMM Do YYYY')}
              </h1>
            </Grid>
          </Grid>
          <MuiGrid container spacing={1} style={{ marginTop: '0.625rem' }}>
            <MuiGrid item md={3}>
              <TextField
                name="remindOn"
                InputProps={{
                  inputProps: {
                    min: Moment().format('YYYY-MM-DD'),
                    max: Moment().add(1, 'years').format('YYYY-MM-DD'),
                  },
                }}
                label="Reminder Date"
                required
                type="date"
              />
            </MuiGrid>
            <MuiGrid item md={8}>
              <TextField
                name="note"
                label="Add A Note"
                placeholder="Please add a reminder note"
                required
              />
            </MuiGrid>
            <Grid item md={1} style={{ marginTop: '1.32rem' }}>
              <IconButton
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !dirty}
                size="large"
              >
                <AddIcon fontSize="large" />
              </IconButton>
            </Grid>
          </MuiGrid>
          <MuiGrid container spacing={4} style={{ marginTop: '1rem' }}>
            <MuiGrid item md={12}>
              <EnhancedTable
                id="admin_reminder_list"
                columns={columns}
                context={context}
                data={state.data}
              >
                <ActionBar render={() => null} />
              </EnhancedTable>
            </MuiGrid>
          </MuiGrid>
          <FormHelperText className="api-text-error">
            {backendErrors}
          </FormHelperText>
        </DialogContent>
      </form>
    </FormContext>
  );
});

function makeRefreshCall() {
  delayedEvent('table-refetch', 4000, 'admin_reminder_list');
}

// table columns
export const columns = [
  {
    name: 'clock',
    sort: false,
    hide: 'false',
    toggle: 'false',
    Cell: {
      C: () => {
        const style = iconStyle();
        return <AccessTimeIcon className={style.clockIcon} />;
      },
    },
  },
  {
    name: 'remindOn',
    label: 'Date',
    filterType: 'string',
    sort: false,
    Cell: {
      C: ({ row }) => Moment(row.remindOn).utc().format('YYYY-MM-DD') || '-',
    },
  },
  {
    name: 'reminderSetBy',
    label: 'Set By',
    filterType: 'string',
    sort: false,
  },
  {
    name: 'created',
    label: 'Created Date',
    filterType: 'string',
    sort: false,
    Cell: {
      C: ({ row }) => Moment(row.created).format('YYYY-MM-DD') || '-',
    },
  },
  {
    name: 'noteText',
    label: 'Note',
    sort: false,
  },
  {
    label: 'Delete',
    name: 'delete',
    sort: false,
    hide: 'false',
    toggle: 'false',
    Cell: {
      C: ({ row, ctx }) => {
        const style = iconStyle();
        return (
          <IconButton onClick={ctx.onSelectRow(row.id)} size="large">
            <CancelIconOutlined className={style.deleteIcon} />
          </IconButton>
        );
      },
    },
  },
];

const remiderStyles = makeStyles(({ palette, shape }) => ({
  miniLabel: {
    fontSize: '1.5rem',
    marginBlockStart: 0,
    right: 0,
    color: palette.primary.contrastText,
    '& > h1': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
  },
  dateDisplay: {
    display: 'inline-block',
    padding: '0.4166666667rem 0.8333333333rem',
    marginLeft: '0.4166666667rem',
    border: `1px solid ${palette.primary.oldBorder}`,
    borderRadius: shape.borderRadius,
  },

  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '0.6666666667rem',
  },

  calendarDateContainer: {
    textAlign: 'right',
    marginBottom: '0.4166666667rem',

    // div auto generated by the picker component
    '& + div': {
      display: 'flex',
      height: '100%',
    },
  },
}));

const iconStyle = makeStyles(({ palette }) => ({
  deleteIcon: {
    margin: '0 auto',
    color: palette.error.main,
  },
  clockIcon: {
    color: palette.common.cowbellBlue,
  },
}));

const useRowStyles = makeStyles(({ palette }) => ({
  root: {
    background: palette.background.modal,
    borderBottom: `15px solid ${palette.background.modal}`,
    borderRadius: 0,
  },
}));

const useCellStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.background.contrastText,
  },
}));

const useHeadStyles = makeStyles(({ palette, config }) => ({
  root: {
    '& tr': {
      border: 0,
    },

    '& th': {
      background: palette.background.modal,
      color: palette.primary.contrastText,

      '& span': {
        fontWeight: config.weights.normal,
      },
    },
  },
  ActionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}));

export const CreateReminderModalConfig = {
  CreateReminderModal: {
    component: CreateReminderModal,
    config: {
      minWidth: 'sm',
      maxWidth: 'md',
      overflow: 'hidden',
      title: 'Set a Reminder',
    },
  },
};
