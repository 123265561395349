import Moment from 'moment';
import _ from 'lodash';

import type { AggregatedP500FormData } from '../CreateEditPrimePlusPolicyModal';

export function transformToFormStateShape(
  p500ResponseData: any,
  agentsResponseData: any
): AggregatedP500FormData {
  const agent = agentsResponseData?.content?.length
    ? agentsResponseData.content[0]
    : null;

  return {
    agency: {
      label: p500ResponseData.agencyName,
      value: p500ResponseData.agencyId,
      meta: {},
    },
    agent: {
      label: agent?.email ?? '',
      value: agent?.id ?? '',
      meta: {
        ...agent,
      },
    },
    agentTeam: {
      label: 'Team',
      value: p500ResponseData.teamIds[0],
      meta: {},
    },
    surplusCompliance: p500ResponseData.surplusCowbellInsurance
      ? 'cowbell'
      : 'agency',
    agencySurplusComplianceName: p500ResponseData.surplusCompliance,
    surplusLinesBroker: {
      label: '',
      value: '',
      meta: {
        surplusLicenseNumber: '',
        surplusLinesState: 'AK',
        brokerEmail: '',
        brokerFirstName: '',
        brokerLastName: '',
      },
    },
    agencyMailingAddress1: '',
    agencyMailingAddress2: '',
    agencyMailingCity: '',
    agencyMailingState: {
      value: '',
      label: '',
      meta: {},
    },
    agencyMailingZipCode: '',
    surplusLinesBrokerFirstName: '',
    surplusLinesBrokerLastName: '',
    surplusLinesBrokerEmail: '',
    surplusLinesState: {
      value: '',
      label: '',
      meta: {},
    },
    surplusLinesNumber: '',
    policyholderFirstName: p500ResponseData.customerFirstName,
    policyholderLastName: p500ResponseData.customerLastName,
    policyholderEmail: p500ResponseData.customerEmail,
    policyholderPhone: p500ResponseData.customerPhone,
    policyEffectiveDate: Moment.utc(p500ResponseData.effectiveDate).format(
      'YYYY-MM-DD'
    ),
    policyEndDate: Moment.utc(p500ResponseData.endDate).format('YYYY-MM-DD'),
    aggregateLimit: p500ResponseData.limit,
    deductible: p500ResponseData.deductible,
    policyPremium: p500ResponseData.premium,
    tria: p500ResponseData.policyResponse.tria,
    underwritingFee: p500ResponseData.policyResponse.mgaFee,
    surplusLinesTax: p500ResponseData.policyResponse.surplusLineTax,
    surplusStampingFee: p500ResponseData.policyResponse.surplusStampingFee,
    kentuckyTaxCode:
      p500ResponseData.policyResponse?.jurisdictionCode?.toString() ?? '',
    kentuckyTax: p500ResponseData?.stateTax ?? '',
    totalPremium: p500ResponseData.totalPremium,
  };
}

export function transformToP500Request(
  formState: AggregatedP500FormData,
  accountId: string,
  companyName: string
) {
  // BE has confusing names for these fields, so I attempted to simplify them on FE and send them in the format that they expect
  const isAgencyCompliance = formState.surplusCompliance === 'agency';

  const surplusCowbellInsurance = formState.surplusCompliance === 'cowbell';
  const surplusCompliance = isAgencyCompliance
    ? formState.agencySurplusComplianceName
    : undefined;

  const surplusBrokerPayload = isAgencyCompliance
    ? {
        ..._.pick(formState, [
          'surplusLinesBrokerFirstName',
          'surplusLinesBrokerLastName',
          'surplusLinesBrokerEmail',
          'surplusLinesNumber',
        ]),
        surplusLinesState: formState.surplusLinesState.value,
      }
    : {};

  const agencyMailingAddressPayload = isAgencyCompliance
    ? {
        ..._.pick(formState, [
          'agencyMailingAddress1',
          'agencyMailingAddress2',
          'agencyMailingCity',
          'agencyMailingZipCode',
        ]),
        agencyMailingState: formState.agencyMailingState.value,
      }
    : {};

  return {
    accountId,
    companyName,
    agencyId: formState.agency.value,
    agencyName: formState.agency.label,
    agentEmail: formState.agent.meta.email,
    agentFirstName: formState.agent.meta.firstName,
    agentLastName: formState.agent.meta.lastName,
    teamIds: [formState.agentTeam.value],
    surplusCowbellInsurance,
    surplusCompliance,
    ...surplusBrokerPayload,
    ...agencyMailingAddressPayload,
    ..._.omit(formState, [
      'agency',
      'agent',
      'agentTeam',
      'surplusCompliance',
      'agencySurplusComplianceName',
      'surplusLinesBroker',
      'surplusLinesBrokerFirstName',
      'surplusLinesBrokerLastName',
      'surplusLinesBrokerEmail',
      'surplusLinesState',
      'surplusLinesNumber',
      'agencyMailingAddress1',
      'agencyMailingAddress2',
      'agencyMailingCity',
      'agencyMailingState',
      'agencyMailingZipCode',
    ]),
  };
}
