import { Box, DialogContentText, DialogTitle, Typography } from '@mui/material';

import CbButton from '../../../../components/Buttons/CbButton';
import { DialogContent, DialogFooter } from '../helpers';

const PolicyUploadConfirmation = ({
  stepperData,
  policyFileName,
  ...props
}) => {
  return (
    <>
      <DialogTitle>
        <Box>Generate PrimeCloud Policy</Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center', marginBottom: '0' }}>
          <Typography
            component="span"
            variant="h3"
            style={{
              fontSize: '1.5rem',
              fontWeight: 600,
            }}
          >
            Policy document {policyFileName} has been uploaded to the platform.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogFooter
        stepNumber={stepperData.stepNumber}
        totalSteps={stepperData.steps.length}
        stepLabel="Policy Document Upload Confirmation"
      >
        <CbButton styleName="ctaButton" onClick={props.onClose}>
          Ok
        </CbButton>
      </DialogFooter>
    </>
  );
};

export default PolicyUploadConfirmation;
