// components
import { Box as MuiBox, Button, styled } from '@mui/material';

import { withShowable } from '../../console/_global/lib/withShowable';

const Box = withShowable(MuiBox);

export const AIActionBar = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '2rem 1rem',
});

export const AIFieldsContainer = styled(Box)({
  height: '50rem',
  overflow: 'hidden visible',
  padding: '0 1rem',
});

export const AIAddInsuredButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  borderColor: theme.palette.common.cowbellBlue,
}));

export const AINoContentMessage = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'inherit',
}));
