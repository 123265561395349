import React from 'react';

// components
import { Autocomplete as MuiAutocomplete } from '@mui/lab';
import { TextFieldBase } from '../TextFieldBase';
import { withShowable } from '../../../console/_global/lib/withShowable';

export const AutoCompleteBase = withShowable(
  ({
    label,
    name,
    required,
    fullWidth,
    error,
    textFieldProps = {},
    options = [],
    placeholder,
    ...props
  }) => {
    return (
      <MuiAutocomplete
        disableClearable
        fullWidth={fullWidth}
        getOptionLabel={getOptionLabel}
        forcePopupIcon={false}
        openOnFocus
        options={options}
        noOptionsText="Type to search"
        renderInput={(params) => {
          return (
            <TextFieldBase
              name={name}
              required={required}
              label={label}
              fullWidth={fullWidth}
              error={error}
              showErrorPlace={false}
              placeholder={placeholder}
              {...params}
              {...textFieldProps}
            />
          );
        }}
        {...props}
      />
    );
  }
);

export const getOptionLabel = (option) => option?.label ?? '';
