import React from 'react';

const SVG = ({ fill = '#2180e2' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g>
      <path
        fill={fill}
        d="M-2.1-10.093a4.085 4.085 0 0 0 4.346-4.323A6.717 6.717 0 0 0-.349-19.5l-3.069-2.688a2.876 2.876 0 0 1-1.187-2.464 1.8 1.8 0 0 1 1.994-1.994c1.882 0 2.285 1.523 2.419 3.315l2.15-.582C1.667-27.094.278-28.6-2.612-28.6a3.881 3.881 0 0 0-4.256 4.189A4.772 4.772 0 0 0-5.121-20.4l3.046 2.688C-.932-16.7-.1-15.849-.1-14.214c0 1.389-.694 2.128-1.994 2.128-1.949 0-2.442-1.949-2.576-3.9l-2.24.6c.177 3.143 1.655 5.293 4.81 5.293z"
        transform="translate(-533 -74) translate(551.488 109.494)"
      />
      <g fill="none">
        <path
          d="M16 0A16 16 0 1 1 0 16 16 16 0 0 1 16 0z"
          transform="translate(-533 -74) translate(533 74)"
        />
        <path
          fill="#2180e2"
          d="M16 2c-3.74 0-7.255 1.456-9.9 4.1C3.457 8.746 2 12.26 2 16s1.456 7.255 4.1 9.9C8.746 28.543 12.26 30 16 30s7.255-1.456 9.9-4.1c2.644-2.645 4.1-6.16 4.1-9.9s-1.456-7.255-4.1-9.9C23.254 3.457 19.74 2 16 2m0-2c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0z"
          transform="translate(-533 -74) translate(533 74)"
        />
      </g>
    </g>
  </svg>
);
export default SVG;
