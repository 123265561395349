import { Route, Switch } from 'react-router-dom';

// components
import { PolicyNavigation } from './PolicyNavigation';
import AdminBORPolicies from '../console/BOR/admin/AdminBORPolicies';
import { CowbellPoliciesListing } from '../console/policies/listing/cowbell/CowbellPoliciesListing';
import { CowbellEndorsementsListing } from '../console/policies/listing/cowbell/CowbellEndorsementsListing';
import { usePersona } from '../components/hooks/usePersona';

const rqps = ['size', 'orderBy', 'order', 'before', 'after'];

export const PolicyTab = () => {
  const persona = usePersona();

  return (
    <section>
      <PolicyNavigation />
      <Switch>
        <Route
          path={`${persona.basePath}/policies/bor`}
          exact
          children={(props) => <AdminBORPolicies rqps={rqps} {...props} />}
        />

        <Route
          path={`${persona.basePath}/policies/endorsements`}
          exact
          children={(props) => (
            <CowbellEndorsementsListing rqps={rqps} {...props} />
          )}
        />

        <Route
          path={`${persona.basePath}/policies`}
          children={(props) => <CowbellPoliciesListing {...props} />}
        />
      </Switch>
    </section>
  );
};

export default PolicyTab;

export const COWBELL_POLICY_TAB_VIEW_PATH = '/admin/policies';
