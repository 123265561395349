import { ServiceHandlerFactory } from '../../../utils/serviceUtils';
import { licenseServiceMappings } from './license.services.mappings';

export const getAgencyLicenses = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/license/search/',
  },
  isCancelable: true,
  paramSchema: licenseServiceMappings,
});

export const getAdminAgentLicenses = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/agent/license/searchall',
  },
  isCancelable: true,
  paramSchema: licenseServiceMappings,
});

export const getAgentSurplusLineLicenses = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/agency/surplus/search',
  },
  isCancelable: true,
  paramSchema: licenseServiceMappings,
});

export const getCowbellAgentSurplusLineLicenses = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/agency/cowbell/surplus/search',
  },
  isCancelable: true,
  paramSchema: licenseServiceMappings,
});

export const createAgencyLicense = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/license',
    method: 'post',
  },
});

export const updateAgencyLicense = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/license',
    method: 'put',
  },
});

export const deleteAgencyLicense = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/license/:licenseId',
    method: 'delete',
  },
});

export const getAgentLicenses = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/agent/license/search/',
  },
  isCancelable: true,
  paramSchema: licenseServiceMappings,
});

export const createAgentLicense = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/agent/license',
    method: 'post',
  },
});

export const updateAgentLicense = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/agent/license',
    method: 'put',
  },
});

export const deleteAgentLicense = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/agent/license/:licenseId',
    method: 'delete',
  },
});

export const getStateNotices = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/template?type=surplusNotice&state=:state',
    method: 'get',
  },
});

export const getAgentLicenseNumber = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/agent/license/:state',
    method: 'get',
  },
  paramSchema: licenseServiceMappings,
});
