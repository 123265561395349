import React from 'react';
import { withTheme } from '@mui/styles';

const PublicLogo = (props) => {
  return props.theme.palette.mode === 'dark' ? (
    <a href="https://cowbell.insure" target="_blank" rel="noreferrer">
      <img
        className="public-logo"
        src="logos/CowbellLogo2.svg"
        alt="Cowbell Cyber"
      />
    </a>
  ) : (
    <a href="https://cowbell.insure" target="_blank" rel="noreferrer">
      <img
        className="public-logo"
        src="logos/CowbellLogo2.svg"
        alt="Cowbell Cyber"
      />
    </a>
  );
};

export default withTheme(PublicLogo);
