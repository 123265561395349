import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import _ from 'lodash';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

// mui
import { Box, DialogActions, DialogContent, Grid } from '@mui/material';
import { styled, withStyles, makeStyles } from '@mui/styles';

// components
import CbButton from '../../Buttons/CbButton';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { InputLabelBase } from '../../inputs/InputLabelBase';

// utils
import useUploadZone from '../../fileUploader/v2/hooks/useUploadzone';
import { StepperModal } from './StepperModal/StepperModal';
import { Uploadzone } from '../../fileUploader/v2/UploadZone';
import { withFormController } from '../../hocs/forms';
import { useFileTypeIcons } from '../../fileUploader/useFileTypeIcons';

// apis
import { updateExcessQuote } from '../../../api/excess-quote.api';
import { uploadDocumentV3 } from '../../../api/documents.api';
import { manageAPIError } from '../../../utils';

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  title: Yup.string().required().label('Title'),
  formNumber: Yup.string().required().label('Form Name'),
  fileName: Yup.string().required().label('File Name'),
});

export const UploadEndorsementOrForm = ({ data = {}, ...props }) => {
  const { excessQuote, excessQuoteId } = data;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleCancel = () => {
    props.close();
  };

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
  });
  const { files, handleUploads, ...uploadZoneParams } = useUploadZone();

  const asyncUploadFunc = React.useCallback(
    ({ file, axiosConfig }) => {
      const formData = new FormData();
      formData.append('file', file);

      return uploadDocumentV3({
        data: formData,
        params: {
          accountId: _.get(excessQuote, 'accountId'),
          agencyId: _.get(excessQuote, 'agencyId'),
          docType: 'Policy',
          docName: file.name,
          temp: true,
        },
        ...axiosConfig,
      });
    },
    [excessQuote]
  );

  const onSubmit = (formValues) => {
    const { title, formNumber, fileName } = formValues;
    handleUploads({ asyncUploadFunc }).then((res) => {
      const { data: docData } = res[0];
      const formsPayload = _.get(excessQuote, 'forms');
      formsPayload.push({
        formName: title,
        formNumber,
        fileName,
        isManuscript: true,
        form: docData,
      });
      return updateExcessQuote(excessQuoteId, {
        data: {
          forms: formsPayload,
        },
      })
        .then(() => {
          queryClient.invalidateQueries(['excess-quote', excessQuoteId]);
          enqueueSnackbar('Form has been added successfully', {
            variant: 'success',
          });
        })
        .catch((err) =>
          enqueueSnackbar(manageAPIError(err, 'Unable to add the form'), {
            variant: 'error',
          })
        )
        .finally(() => props.close());
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepperModal>
          <Step1 onCancel={handleCancel} validateInputs={methods.trigger} />
          <Step2 files={files} uploadZoneParams={uploadZoneParams} />
          <Step3 files={files} onCancel={handleCancel} />
        </StepperModal>
      </form>
    </FormProvider>
  );
};

const Step1 = ({ stepperProps, onCancel, validateInputs }) => {
  const { formState } = useFormContext();
  const { isDirty } = formState;
  const handleNext = React.useCallback(() => {
    validateInputs(['title', 'formNumber']).then((isValid) =>
      isValid ? stepperProps.goForward() : null
    );
  }, [stepperProps, validateInputs]);

  return (
    <>
      <DialogContent style={{ paddingBottom: '3rem', paddingTop: '3rem' }}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={6}>
            <TextField name="title" label="Title" required />
          </Grid>
          <Grid item xs={6}>
            <TextField name="formNumber" label="Form Number" required />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box>{stepperProps.step + 1} of 3</Box>
        <Box>
          <CbButton styleName="cancel" onClick={onCancel}>
            Cancel
          </CbButton>
          <CbButton
            styleName="ctaButton"
            onClick={handleNext}
            disabled={!isDirty}
          >
            Next
          </CbButton>
        </Box>
      </DialogActions>
    </>
  );
};

const Step2 = ({ stepperProps, files, uploadZoneParams, onCancel }) => {
  const classes = useStyles();
  const handleNext = () => {
    stepperProps.goForward();
  };
  return (
    <>
      <DialogContent style={{ paddingBottom: '3rem', paddingTop: '3rem' }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classes.uploadZone}
        >
          <Uploadzone {...uploadZoneParams} files={files} />
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box>{stepperProps.step + 1} of 3</Box>
        <Box>
          <CbButton styleName="cancel" onClick={onCancel}>
            Cancel
          </CbButton>
          <CbButton
            styleName="ctaButton"
            onClick={handleNext}
            disabled={!files}
          >
            Next
          </CbButton>
        </Box>
      </DialogActions>
    </>
  );
};

const Step3 = ({ stepperProps, files, onCancel }) => {
  const { formState } = useFormContext();
  const isFileNameDirty = _.get(formState, 'dirtyFields.fileName');
  const classes = useStyles();

  const file = files[0];
  const fileDetails = React.useMemo(() => {
    const splitString = file.name.split('.');
    const extension = splitString.pop();
    const fileName = splitString.join('.');
    return { extension, fileName };
  }, [file]);
  const renderFileIcon = useFileTypeIcons(fileDetails.extension);

  return (
    <>
      <DialogContent style={{ paddingBottom: '3rem', paddingTop: '3rem' }}>
        <Grid container spacing={3}>
          <Grid xs={3}>
            <FormInputLabel required>File Name</FormInputLabel>
          </Grid>
          <Grid xs={9}>
            <TextField name="fileName" />
          </Grid>
          <Grid xs={3}>
            <FormInputLabel>File Type:</FormInputLabel>
          </Grid>
          <Grid xs={9} className={classes.fileIcon}>
            {renderFileIcon}
          </Grid>
          <Grid xs={3}>
            <FormInputLabel required>Form Number</FormInputLabel>
          </Grid>
          <Grid xs={9}>
            <TextField
              name="formNumber"
              disabled
              className={classes.formNumberDisabled}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box>{stepperProps.step + 1} of 3</Box>
        <Box>
          <CbButton styleName="cancel" onClick={onCancel}>
            Cancel
          </CbButton>
          <CbButton
            styleName="ctaButton"
            type="submit"
            disabled={!isFileNameDirty}
          >
            Next
          </CbButton>
        </Box>
      </DialogActions>
    </>
  );
};

const useStyles = makeStyles(() => ({
  formNumberDisabled: {
    '& input': {
      border: 'none',
    },
  },
  fileIcon: {
    marginBottom: '1rem',
  },
  uploadZone: {
    marginTop: '2rem',
  },
}));

const FormInputLabel = styled(InputLabelBase)(
  ({ theme: { palette, config } }) => ({
    fontSize: config.textSizes.primer,
    color: palette.primary.main,
    margin: 0,
    padding: 0,
  })
);

export const UploadEndorsementOrFormConfig = {
  UploadEndorsementOrForm: {
    component: UploadEndorsementOrForm,
    // all mui related config
    config: {
      fullWidth: true,
      title: 'Upload Endorsement or Form',
    },
  },
};
