import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

// components
import { styled, Typography } from '@mui/material';

// utils
import {
  setAccountDetails,
  setAdminDetails,
  setAgentDetails,
} from '../../../_tabs/tabs.reducer';
import { determineIfAllowedScopes } from '../../../../utils/next/auth.utils';
import { useScopes } from '../../../../components/hooks/useScopes';
import { usePersona } from '../../../../components/hooks/usePersona';

export const CowbellAccountProfileLink = ({ account }) => {
  const handleLink = useLinkToAccountProfile({ account });
  return (
    <Link
      className="link-underlined--hover"
      to={`/admin/quote/details/underwriter/no-quote/${account.id}/${account.productType}/account-profile`}
      onClick={handleLink}
    >
      <EditAccountProfileText>EDIT ACCOUNT PROFILE</EditAccountProfileText>
    </Link>
  );
};

export const useLinkToAccountProfile = ({ account }) => {
  const scopes = useScopes();
  const persona = usePersona();
  const dispatch = useDispatch();
  const location = useLocation();

  return React.useCallback(() => {
    const canManageAccountProfile = determineIfAllowedScopes(
      scopes,
      ['account_profile:manage'],
      true
    );
    const canDocDelete = determineIfAllowedScopes(
      scopes,
      ['account:cowbell'],
      true
    );
    const canManageAccounts = determineIfAllowedScopes(
      scopes,
      ['accounts:manage'],
      true
    );

    dispatch(
      setAccountDetails({
        account: {
          companyName: account.name,
          quoteNumber: '',
          accountId: account.id,
        },
      })
    );

    if (persona.isCowbell) {
      dispatch(
        setAdminDetails({
          admin: {
            from: '/admin/accounts',
            readOnly: {
              quote: false,
              policy: true,
              account: !canManageAccountProfile,
              risk: true,
              documents: {
                upload: false,
                delete: !canDocDelete,
              },
              notes: false,
            },
          },
        })
      );
    } else if (persona.isAgency) {
      dispatch(
        setAgentDetails({
          agent: {
            from: location.pathname,
            readOnly: {
              quote: true,
              policy: true,
              account: !canManageAccounts,
              risk: true,
              documents: {
                upload: false,
                delete: true,
              },
            },
          },
        })
      );
    }
  }, [
    scopes,
    location,
    dispatch,
    account.name,
    account.id,
    persona.isCowbell,
    persona.isAgency,
  ]);
};

const EditAccountProfileText = styled(Typography)(({ theme: { config } }) => ({
  color: config.colors.cowbellBlue,
  fontSize: config.textSizes.tertia,
  fontWeight: config.weights.bold,
  textTransform: 'uppercase',
}));
