import React from 'react';

import { Box, DialogActions, DialogContent, styled } from '@mui/material';

import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import CbButton from '../Buttons/CbButton';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/forms';
import { createRiskEngQuestionsTemplate } from '../../api/riskEng.api';
import { iconMappings } from '../../console/_global/navigation/IconsMappings';

const TextField = withFormController(TextFieldBase);

const inputProps = {
  style: {
    border: 'none',
  },
};

const schema = Yup.object().shape({
  name: Yup.string()
    .min(3)
    .max(255)
    .matches(/[a-zA-Z0-9]/, 'Template must contain letters and/or numbers')
    .label('Template Name')
    .required(),
  description: Yup.string()
    .max(255)
    .matches(/[a-zA-Z0-9]/, 'Template must contain letters and/or numbers')
    .label('Template Description'),
});

export const RiskEngQuestionsAddTemplate = ({ iconStyles, data, ...props }) => {
  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (formValues) => {
    const { mrqIds } = data;
    if ((mrqIds && mrqIds.length < 1) || !mrqIds) {
      return enqueueSnackbar(
        'No questions on account, please add questions to account before saving a template',
        {
          variant: 'error',
        }
      );
    }
    return createRiskEngQuestionsTemplate({
      data: {
        ...formValues,
        mrqIds: data.mrqIds,
      },
    })
      .then(() => {
        queryClient.invalidateQueries(['risk-eng']);
        enqueueSnackbar('Template saved suceessfully!', { variant: 'success' });
        props.close();
      })
      .catch(() =>
        enqueueSnackbar('Failed to create template, please try again later', {
          variant: 'error',
        })
      );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Box pt={5}>
          <FormProvider {...methods}>
            <TextField
              id="name"
              name="name"
              required
              inputProps={inputProps}
              label="Template Name"
              margin="none"
              placeholder="Template Name"
            />
            <TextField
              id="description"
              inputProps={inputProps}
              name="description"
              label="Template Description"
              margin="none"
              placeholder="Template Description"
              required
            />
          </FormProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <CbButton onClick={props.close} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton type="submit" styleName="ctaButton">
          Save Template
        </CbButton>
      </DialogActions>
    </form>
  );
};

export const RiskEngQuestionsAddTemplateConfig = {
  RiskEngQuestionsTemplate: {
    component: RiskEngQuestionsAddTemplate,
    config: {
      fullWidth: true,
      title: 'Add Template',
      icon: iconMappings.PoliciesIcon.iconImport,
    },
  },
};
