import moment from 'moment';
import _ from 'lodash';
// actions
import { downloadAWSDoc } from '../../policies/PolicyService';
import {
  downloadQuoteDocuments,
  downloadQuoteProposal,
} from '../../inbox/QuotesService';
// utils
import { saveBlobFile } from '../appUtils';

/* This file is used only for documents downloads. Its a generic one for maintainence purpose */

// when re using the function downloadQuoteApplication,
// make sure the data is in the format picked from the data object
// created should be in unix format
export const downloadQuoteApplication = (data, enqueueSnackbar) => {
  const {
    created,
    accountId,
    quoteId,
    isEndorsement,
    companyName,
    quoteNumber,
  } = data;

  const createdInUnix = moment(created).unix();

  downloadQuoteDocuments(accountId, quoteId, createdInUnix, isEndorsement)
    .then((response) => {
      const url = _.get(response, 'data');

      downloadAWSDoc(url).then((docResponse) => {
        saveBlobFile(
          docResponse,
          `${companyName}-${quoteNumber}-Cowbell-Application`,
          '.pdf'
        );
      });
    })
    .catch(() => {
      enqueueSnackbar('Unable to download the Quote Documents', {
        variant: 'error',
      });
    });
};

export const downloadProposal = (data, enqueueSnackbar) => {
  const { created, accountId, quoteId, companyName, quoteNumber } = data;

  const createdInUnix = moment(created).unix();

  downloadQuoteProposal(accountId, quoteId, createdInUnix)
    .then((response) => {
      const url = _.get(response, 'data');

      downloadAWSDoc(url).then((docResponse) => {
        saveBlobFile(
          docResponse,
          `${companyName}-${quoteNumber}-Cowbell-Proposal`,
          '.pdf'
        );
      });
    })
    .catch(() => {
      enqueueSnackbar('Unable to download the Quote Proposal', {
        variant: 'error',
      });
    });
};
