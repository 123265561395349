import * as Yup from 'yup';
import _ from 'lodash';
import React from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
import { Link } from 'react-router-dom';
// mui
import { FormHelperText, Typography } from '@mui/material';
// components
import { useRecaptcha } from '../../hooks';

import withRouter from '../../hocs/withRouter';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { withFormController } from '../../hocs/withFormController';
import CbButton from '../../Buttons/CbButton';
import NextPublicWrapper from '../NextPublicWrapper';
// actions
import { requestPasswordReset } from '../../../api/SignupService';
import { useToggleModal } from '../../../utils/modal.utils';

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  email: Yup.string().email().label('Email').required(),
});

interface Props {
  location: History;
}

interface FormValues {
  email: string;
}

const NewForgotPassword = withRouter(({ location }: Props) => {
  const toggleModal = useToggleModal();

  const [error, setError] = React.useState('');
  const [mfa, setMfa] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [disable, setDisable] = React.useState(false);

  const {
    recaptchaError,
    token,
    resetToken,
    executeChallenge,
    getElementProps,
  } = useRecaptcha();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
    validationSchema: schema,
  });

  React.useEffect(() => {
    if (!_.isUndefined(location.state)) {
      const { username, password } = location.state;
      setMfa(true);
      toggleModal.direct(
        'ResetMfaConfirm',
        { username, password },
        {
          title: 'Reset Multi Factor Authentication',
          icon: 'Refresh',
          showClose: false,
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data: FormValues) => {
    if (typeof token === 'string') {
      return requestPasswordReset(data.email, token)
        .then(() => {
          setRedirect(true);
        })
        .catch((error) => {
          setRedirect(false);
          resetToken();
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Not able to reset password at this time.Please try again after sometime.'
              )
            )
          );
        });
    }
    setDisable(true);
    setError(
      'Cannot reset password at this time. Please try again after sometime.'
    );
  };

  return (
    <NextPublicWrapper>
      {!redirect && !mfa && (
        <FormContext {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <TextField
              name="email"
              type="email"
              label="Email"
              required
              placeholder="Please enter email address"
            />
            <FormHelperText style={{ maxWidth: '20rem' }}>
              {error || recaptchaError}
            </FormHelperText>
            <div {...getElementProps()} />
            <div>
              <CbButton
                disabled={
                  formMethods.formState.isSubmitting ||
                  !!recaptchaError ||
                  disable
                }
                loading={formMethods.formState.isSubmitting}
                type="submit"
                styleName="ctaButton"
                buttonText="Reset Password"
                size="large"
                onMouseEnter={executeChallenge}
              />
            </div>
          </form>
        </FormContext>
      )}

      {mfa && (
        <Typography>
          An email has been sent to you. <br /> Please follow the instructions
          to reset your MFA.
        </Typography>
      )}
      {redirect && (
        <Typography>
          An email has been sent to you. <br /> Please follow the instructions
          to reset your password.
        </Typography>
      )}
      <p>
        Login to your account? <Link to="/login">Login Here!</Link>
      </p>
    </NextPublicWrapper>
  );
});

export default NewForgotPassword;
