import React from 'react';

// lodash
import _ from 'lodash';

// components
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Modal } from '../../../../../components/modals/v2/helpers/v2.modal.helpers';

// constants and services
import { surplusLinesStates } from '../../constants/constants';
import useCompleteQuoteSubjectivity from '../shared/useCompleteQuoteSubjectivity';
import useGetSubjectivityProgress from '../shared/useGetSubjectivityProgress';
import { getAgencyInformationSchema } from '../schemas/validationSchemas';
import { useActor } from '../../../../../components/hooks/useActor';
import { determineIfStepIsEditable } from '../../utils/bindQuoteWorkFlowUtils';
import { useLanguageFormState } from '../../../../../i18n/forms/LanguageForm';

export const useAgencyInformationDependencies = ({
  formState,
  goForward,
  quoteDetails,
  completeStep,
  step,
  subjectivities,
  quoteProgress,
  language,
}) => {
  const [isSurplusLinesBroker, setIsSurplusLinesBroker] = React.useState(false);

  const [isSurplusNoticeAttested, setIsSurplusNoticeAttested] =
    React.useState(null);

  const [showSurplusNoticeAttestation, setShowSurplusNoticeAttestation] =
    React.useState(false);

  const { completeSubjectivity, isCompletingStep } =
    useCompleteQuoteSubjectivity({
      quoteId: quoteDetails.uiData.quoteId,
      productType: quoteDetails.uiData.productType,
    });

  const languageFormState = useLanguageFormState({ language });

  const actor = useActor();

  const {
    quoteSubjectivityId,
    completed: isStepCompleted,
    subjectivitySubType,
  } = subjectivities[step];

  const isStepEditable = determineIfStepIsEditable({
    subjectivitySubType,
    status: quoteProgress.quoteAgencyStatus,
    isAPI: quoteProgress.isAPI,
    productType: quoteDetails.uiData.productType,
  });

  const {
    data: allSubjectivityInformation = {},
    isLoading,
    isFetching,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId,
    quoteId: quoteDetails.id,
    productType: quoteDetails.uiData.productType,
  });

  const { data: subjectivityData } = allSubjectivityInformation;

  const isSubjectivityDataLoading = isLoading || isFetching;

  const defaultValues = {
    ..._.pick(formState, [
      'agencyMailingAddress1',
      'agencyMailingAddress2',
      'agencyMailingCity',
      'agencyMailingState',
      'agencyMailingZipCode',
      'surplusLicenseNumber',
      'surplusLinesBrokerEmail',
      'surplusLinesBrokerFirstName',
      'surplusLinesBrokerLastName',
      'surplusLinesState',
      'surplusBrokerOnFile',
    ]),
  };

  const { handleSubmit, setValue, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(
      getAgencyInformationSchema(languageFormState.languageFormSchema)
    ),
  });

  React.useEffect(() => {
    if (subjectivityData) {
      setIsSurplusLinesBroker(subjectivityData.isSurplusLinesBroker);
      setIsSurplusNoticeAttested(subjectivityData.surplusNoticeAttested);
      setValue('agencyMailingAddress1', subjectivityData.agencyMailingAddress1);
      setValue('agencyMailingAddress2', subjectivityData.agencyMailingAddress2);
      setValue('agencyMailingCity', subjectivityData.agencyMailingCity);
      setValue('agencyMailingState', subjectivityData.agencyMailingState);
      setValue('agencyMailingZipCode', subjectivityData.agencyMailingZipCode);
      setValue(
        'surplusLinesBrokerFirstName',
        subjectivityData.surplusLinesBrokerFirstName
      );
      setValue(
        'surplusLinesBrokerLastName',
        subjectivityData.surplusLinesBrokerLastName
      );
      setValue('surplusLinesState', subjectivityData.surplusLinesState);
      setValue('surplusLicenseNumber', subjectivityData.surplusLinesNumber);
      setValue(
        'surplusLinesBrokerEmail',
        subjectivityData.surplusLinesBrokerEmail
      );
    }
    if (
      methods.getValues().surplusLinesState === surplusLinesStates.LA ||
      methods.getValues().surplusLinesState === surplusLinesStates.MT
    ) {
      setShowSurplusNoticeAttestation(true);
    } else {
      setShowSurplusNoticeAttestation(false);
    }
    // eslint-disable-next-line
  }, [subjectivityData]);

  const displaySendButton =
    !isSurplusLinesBroker &&
    (methods.getValues().surplusLinesState === surplusLinesStates.MT ||
      methods.getValues().surplusLinesState === surplusLinesStates.LA);

  const onSubmit = async (formValues) => {
    if (displaySendButton) {
      Modal.show('SurplusLinesNoticeConfirmation', {
        data: {
          formValues,
          quoteSubjectivityId,
          isSurplusLinesBroker,
          completeSubjectivity,
          goForward,
          quoteId: quoteDetails.id,
          productType: quoteDetails.uiData.productType,
        },
        config: { title: 'Send to Surplus Lines Broker' },
      });
      return;
    }

    const completeSubjectivityParams = {
      quoteSubjectivityId,
      completeStep,
      goForward,
      step,
      payload: {
        isSurplusLinesBroker: Boolean(isSurplusLinesBroker),
        surplusNoticeAttested: !!isSurplusNoticeAttested,
        surplusLinesNumber: formValues.surplusLicenseNumber,
        ...formValues,
      },
    };

    try {
      await languageFormState.validateAddress(formValues);
      completeSubjectivity(completeSubjectivityParams);
    } catch {
      //
    }
  };

  const handleCheckBox = (event) => {
    setIsSurplusLinesBroker((prevState) => !prevState);
    if (event.target.checked) {
      setValue('surplusLinesBrokerFirstName', actor.firstName);
      setValue('surplusLinesBrokerLastName', actor.lastName);
      setValue('surplusLinesBrokerEmail', actor.email);
      setValue('surplusLicenseNumber', '');
      if (
        methods.getValues().surplusLinesState === surplusLinesStates.LA ||
        methods.getValues().surplusLinesState === surplusLinesStates.MT
      ) {
        setShowSurplusNoticeAttestation(true);
      } else {
        setShowSurplusNoticeAttestation(false);
      }
    }
  };

  const handleBrokerOnFile = (selectedOption) => {
    setValue(
      'surplusLinesBrokerFirstName',
      selectedOption.meta.brokerFirstName
    );
    setValue('surplusLinesBrokerLastName', selectedOption.meta.brokerLastName);
    setValue('surplusLinesBrokerEmail', selectedOption.value);
    setValue('surplusLicenseNumber', selectedOption.meta.surplusLicenseNumber);
    setValue('surplusLinesState', selectedOption.meta.surplusLinesState);
  };

  const handlesurplusNoticeAttested = () => {
    setIsSurplusNoticeAttested((prevState) => !prevState);
  };

  return {
    isSurplusLinesBroker,
    isSurplusNoticeAttested,
    showSurplusNoticeAttestation,
    surplusLinesState: methods.getValues().surplusLinesState,
    isCompletingStep,
    displaySendButton,
    isSubjectivityDataLoading,
    subjectivitySubType,
    isStepEditable,
    allSubjectivityInformation,
    subjectivityData,
    isStepCompleted,
    languageFormState,
    eventHandlers: {
      onSubmit,
      handleCheckBox,
      handleBrokerOnFile,
      handlesurplusNoticeAttested,
    },
    reactHookForm: {
      handleSubmit,
      methods,
    },
  };
};
