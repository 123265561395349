import React from 'react';
import _ from 'lodash';
import Moment from 'moment';
import Numeral from 'numeral';
import { compose } from 'redux';

// mui
import { Divider, Grid as MuiGrid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import withRouter from '../../../components/hocs/withRouter';
import { DetailsHeaderMapping } from '../../../utility/CoverageInfoMapping';
import DescTooltip from '../../../components/DescTooltip';
import Connector from '../../../_assets/svg/Connector.svg';
import { ProductTypes } from '../../../types';
import { toUniversalUtcDate } from '../../../utils/date.utils';

const finalPremiumStatus = ['READY', 'BOUND', 'SIGNED'];
const rpMapping = {
  3: 'Full Prior Acts',
  2: '2 Years',
  1: '1 Year',
};

export const QuoteDetailsHeader = compose(withRouter)(
  ({ data, coverageData, product }) => {
    if (
      _.isUndefined(coverageData) ||
      (product === ProductTypes.p100 && _.isUndefined(data.firmographicData))
    ) {
      return null;
    }
    const classes = styles();

    const effectiveDate = Moment(coverageData.effectiveDate);
    const endDate = Moment(coverageData.endDate);
    const duration = Moment.duration(endDate.diff(effectiveDate));

    const quote = {
      'Quote Expires': toUniversalUtcDate(data.expiresOn),
      'Quote Created': toUniversalUtcDate(data.created),
      'Policy Term': `${Math.round(duration.asDays())} Days`,
      'Policy Holder Name':
        !_.isUndefined(data.customerFirstName) &&
        `${data.customerFirstName} ${data.customerLastName}`,
      'Policy Holder Email': data.customerEmail,
    };

    let deductible = '';
    if (product === ProductTypes.p100) {
      deductible = Numeral(coverageData.deductible).format('$0,0');
    } else if (product === ProductTypes.p250) {
      deductible = 'N/A';
    }

    const amount = {
      'Aggregate Limit': Numeral(coverageData.limit).format('$0,0'),
      Deductible: deductible,
    };

    const connector = _.get(data, 'firmographicData.numberOfConnectors', 0);
    const connectorConnected = parseInt(connector) !== 0;

    return (
      <>
        <Divider className={classes.horiDivider} />
        <MuiGrid container spacing={6} className={classes.summary}>
          <MuiGrid item md={6}>
            <div
              className="flex--spaced"
              style={connectorConnected ? { color: '#2180e2' } : null}
            >
              <div style={{ display: 'flex' }}>
                <Connector
                  style={{ fontSize: '2rem', marginRight: '0.5rem' }}
                  fill={connectorConnected ? { color: '#2180e2' } : null}
                />
                <span>Connector</span>
              </div>
              {connectorConnected ? (
                <div>{connector} Connected </div>
              ) : (
                <div>None Connected</div>
              )}
            </div>
          </MuiGrid>
        </MuiGrid>
        <Divider className={classes.horiDivider} />

        <h2 className={classes.heading}>Quote Summary</h2>
        <MuiGrid container spacing={6} className={classes.summary}>
          <MuiGrid item md={6}>
            {_.entries(quote).map((values, index) => (
              <div key={index} className="flex--spaced">
                <div>{values[0]}</div>
                <div>{values[1]}</div>
              </div>
            ))}
          </MuiGrid>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
          <MuiGrid item md={5}>
            <div className={`flex--spaced ${classes.premium}`}>
              <div>Coverage Start Date</div>
              <div>{toUniversalUtcDate(effectiveDate)}</div>
            </div>
            <div className="flex--spaced">
              <div>Coverage End Date</div>
              <div>{toUniversalUtcDate(endDate)}</div>
            </div>

            {_.entries(amount).map((values, index) => {
              const tooltip = DetailsHeaderMapping[values[0]].tooltip || '';
              return (
                <div key={index} className="flex--spaced">
                  <div>
                    {values[0]}
                    <DescTooltip tooltip={tooltip} />
                  </div>
                  <div>{values[1]}</div>
                </div>
              );
            })}
            {product === ProductTypes.p100 && (
              <>
                <div className="flex--spaced">
                  <div>Waiting Period</div>
                  <div>{`${coverageData.waitingPeriod} Hours`}</div>
                </div>
                <div className="flex--spaced">
                  <div>Retroactive Period</div>
                  <div>{rpMapping[coverageData.retroactivePeriod]}</div>
                </div>
              </>
            )}
            <div className={`flex--spaced ${classes.premium}`}>
              <div>
                {finalPremiumStatus.includes(data.agencyStatus)
                  ? 'Final Premium'
                  : 'Indicated Pricing'}
              </div>
              <div>
                {data.agencyStatus === 'REFERRED'
                  ? 'Referred'
                  : Numeral(data.totalPremium).format('$0,0.00')}
              </div>
            </div>
          </MuiGrid>
        </MuiGrid>
      </>
    );
  }
);

const styles = makeStyles(({ config, palette }) => ({
  heading: {
    background: palette.background.details,
    color: palette.primary.contrastText,
    padding: '0.416rem 2.5rem',
    borderRadius: '5px',
    textAlign: 'left',
    fontWeight: 'normal',
  },
  spacing: {
    padding: '0 2.5rem',
  },
  summary: {
    fontSize: config.textSizes.primer,
    color: palette.primary.contrastText,
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1.8,
    paddingLeft: '2.5rem',
    paddingRight: '0.6rem',
  },
  divider: {
    margin: '2.5rem 3.33rem',
  },
  horiDivider: {
    margin: '1.66rem 3rem',
  },
  premium: {
    fontSize: config.textSizes.paragon,
    lineHeight: 1.4,
  },
}));
