import { bindSteps as agencyBindSteps } from '../../bind-quote/constants/constants';

export const cowbellBindSteps = {
  UPLOAD_BIND_ORDER: 'Upload Bind Order',
} as const;

export const orderedPreBindSteps = [
  cowbellBindSteps.UPLOAD_BIND_ORDER,
  agencyBindSteps.POLICY_HOLDER_INFORMATION,
  agencyBindSteps.AGENCY_INFORMATION,
  agencyBindSteps.POLICY_EFFECTIVE_DATE,
  agencyBindSteps.DILLIGENT_SEARCH_REPORT,
  agencyBindSteps.MANUFACTURING,
  agencyBindSteps.TECH_E_O_COV_CONFIRMATION,
  agencyBindSteps.NO_KNOWN_LOSS_LETTER,
  agencyBindSteps.LOSS_RUNS,
  agencyBindSteps.INSURANCE_APPLICATION,
] as const;
