import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import NextPublicWrapper from './NextPublicWrapper';

import { logout } from '../../users/UserActions';

const Logout = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <NextPublicWrapper>
      <Box pt={1}>
        <Typography>You've been successfully logged out.</Typography>
        <Typography className="normal-text">
          Have an account?{' '}
          <Link
            className="link-underlined"
            to="/login"
            style={{ color: '#fff' }}
          >
            Sign in
          </Link>
        </Typography>
      </Box>
    </NextPublicWrapper>
  );
};

export default Logout;
