import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { DialogActions, DialogContent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { COWBELL_SPOTLIGHTS } from '../../tables/table_constants';
import CbButton from '../../Buttons/CbButton';
import TextFieldBase from '../../inputs/text-fields/base/TextFieldBase';
import { updateSpotlightDuration } from '../../../api/risk/risk.api';
import { withFormController } from '../../hocs/forms';

const TextField = withFormController(TextFieldBase);

export const EditSpotlight = ({ data = {}, ...props }) => {
  const queryClient = useQueryClient();
  const { handleSubmit, ...formMethods } = useForm({
    // resolver: yupResolver(schema),
  });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (values) => {
    updateSpotlightDuration(data.spotlightName, values.durationInDays)
      .then(() => {
        enqueueSnackbar('Spotlight duration has been extended.', {
          variant: 'success',
        });
        queryClient.invalidateQueries([COWBELL_SPOTLIGHTS]);
        props.close();
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong while extending the duration.', {
          variant: 'error',
        });
      });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingTop: '4rem' }}>
          <TextField
            name="durationInDays"
            label="Duration (in days)"
            required
          />
        </DialogContent>
        <DialogActions>
          <CbButton onClick={props.close} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton type="submit" styleName="ctaButton">
            Confirm
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const EditSpotlightConfig = {
  EditSpotlight: {
    component: EditSpotlight,
    // all mui related config
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      title: 'Extend Duration of Spotlight',
    },
  },
};
