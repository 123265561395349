import React from 'react';
import _ from 'lodash';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import type { UseQueryResult } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QuotesListing } from '../listing-tabs/quotes/AgencyDashboardQuotesTab';
import { PoliciesListing } from '../listing-tabs/policies/AgencyDashboardPoliciesTab';
import {
  AgencyDashboardListingContainer,
  ListingHeader,
} from '../listing-tabs/common/components';

// utils
import type { formatClientQueriesResponseReturnType } from '../hooks/useNewQuoteClientData';
import {
  AGENCY_DASHBOARD_LISTING_TABS,
  AGENCY_DASHBOARD_VIEWS,
  getPoliciesHeaderConfig,
  quotesHeaderConfig,
} from '../agency-dashboard.statics';
import type { _Account } from './StartNewQuote';
import type { Quote } from '../../../../types/quotes/quote.entity';
import type { Binder } from '../../../accounts/summary/types/binder.types';
import type { Policy } from '../../../accounts/summary/types/policy.types';
import { Show } from '../../../../components/Show';
import type { WithChildrenProps } from '../../../../types/components/common';
import { setGlobalTeam } from '../../../../reducers/team.reducer';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';

interface AccountSupplementaryInfoProps {
  clientQueryData: UseQueryResult<formatClientQueriesResponseReturnType>;
  account?: _Account;
}

export const AccountSupplementaryInfo = ({
  clientQueryData,
  account,
}: AccountSupplementaryInfoProps) => {
  const { data: clientData, isLoading: isClientDataLoading } = clientQueryData;

  if (isClientDataLoading) {
    return null;
  }

  if (account?.hardlinkedToAnotherAgency) {
    return (
      <AccountSupplementaryInfoContainer>
        <AssociatedWithAnotherAgencyMessage />
      </AccountSupplementaryInfoContainer>
    );
  }

  if (account?.clientOnAnotherTeam) {
    return (
      <AccountSupplementaryInfoContainer>
        <AssociatedWithAnotherAgentMessage />
      </AccountSupplementaryInfoContainer>
    );
  }

  if (!clientData?.areAllEntitiesEmpty) {
    return (
      <AccountSupplementaryInfoContainer>
        <ClientInfoTables clientData={clientData} />
      </AccountSupplementaryInfoContainer>
    );
  }

  if (account?.clientOnYourTeam) {
    return (
      <AccountSupplementaryInfoContainer>
        <ClientExistsWithinTeamsMessage account={account} />
      </AccountSupplementaryInfoContainer>
    );
  }

  return null;
};

interface ClientInfoTablesProps {
  clientData?: formatClientQueriesResponseReturnType;
}

const ClientInfoTables = ({ clientData }: ClientInfoTablesProps) => {
  if (!clientData) return null;

  return (
    <>
      <Show when={!_.isEmpty(clientData?.activePoliciesOpenForRenewal.rows)}>
        <ActivePoliciesListing
          policies={clientData?.activePoliciesOpenForRenewal}
          isOpenForRenewal
        />
      </Show>

      <Show when={!_.isEmpty(clientData?.activePoliciesNotOpenForRenewal.rows)}>
        <ActivePoliciesListing
          policies={clientData?.activePoliciesNotOpenForRenewal}
          isOpenForRenewal={false}
        />
      </Show>

      <Show when={!_.isEmpty(clientData?.renewalQuotes.rows)}>
        <AvailableQuotesListing quotes={clientData?.renewalQuotes} isRenewal />
      </Show>

      <Show when={!_.isEmpty(clientData?.activeBinders.rows)}>
        <AvailableBindersListing binders={clientData?.activeBinders} />
      </Show>

      <Show when={!_.isEmpty(clientData.activeQuotes.rows)}>
        <AvailableQuotesListing
          quotes={clientData?.activeQuotes}
          isRenewal={false}
        />
      </Show>
    </>
  );
};

const supportEmail = 'support@cowbellcyber.ai';

const AssociatedWithAnotherAgencyMessage = () => {
  const classes = useStyles();

  return (
    <>
      <Typography color="error">
        The Client is already associated with another Agency!
      </Typography>
      <Typography>
        Please contact{' '}
        <a href={`mailto:${supportEmail}`} className={classes.link}>
          {supportEmail}
        </a>{' '}
        if you need help.
      </Typography>
    </>
  );
};

const AssociatedWithAnotherAgentMessage = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.warningText}>
        This Client is already associated with someone else within your Agency!
      </Typography>
      <Typography>
        Please contact your Agency Admin or{' '}
        <a href={`mailto:${supportEmail}`} className={classes.link}>
          {supportEmail}
        </a>{' '}
        if you need help.
      </Typography>
    </>
  );
};

interface ClientExistsWithinTeamsMessageProps {
  account?: _Account;
}

const ClientExistsWithinTeamsMessage = ({
  account,
}: ClientExistsWithinTeamsMessageProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleListingTabChange, handleViewChange, onSearchTermChange } =
    useAgencyDashboardState();

  if (!account) return null;

  const setTeamToAll = () => {
    dispatch(
      setGlobalTeam({
        id: 'all',
        name: 'All',
      })
    );
  };

  const handleClientClick = () => {
    setTeamToAll();
    handleViewChange(AGENCY_DASHBOARD_VIEWS.LISTING);
    handleListingTabChange(AGENCY_DASHBOARD_LISTING_TABS.ACCOUNTS);
    onSearchTermChange(account.name);
  };
  return (
    <>
      <Typography>
        You already have access to this{' '}
        <Typography
          component="span"
          className={classes.link}
          onClick={handleClientClick}
        >
          Client
        </Typography>{' '}
        within your Teams
      </Typography>
      <Typography>
        Please contact{' '}
        <a href={`mailto:${supportEmail}`} className={classes.link}>
          {supportEmail}
        </a>{' '}
        if you need help.
      </Typography>
    </>
  );
};

interface ClientDataUseQueryResult {
  totalPages: number;
}

interface QuotesUseQueryResult extends ClientDataUseQueryResult {
  rows: Quote[];
}

interface AvailableQuotesListingProps {
  quotes?: QuotesUseQueryResult;
  isRenewal: boolean;
}

const AvailableQuotesListing = ({
  quotes,
  isRenewal,
}: AvailableQuotesListingProps) => {
  if (!quotes) return null;

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" m={3}>
        <Typography variant="body2">
          {isRenewal
            ? 'This Client is up for Renewal and has Renewal Quote(s) already available!'
            : 'Quote(s) already available for this Client!'}
        </Typography>
        <Typography variant="body2">
          {isRenewal
            ? 'Please select the right action from the renewal quote(s) below, if you do not want to create a new one.'
            : 'Please select the desired action from the quote(s) below if you do not want to create a new one.'}
        </Typography>
      </Box>
      <AgencyDashboardListingContainer totalPages={quotes.totalPages}>
        <ListingHeader headerConfig={quotesHeaderConfig} />
        <QuotesListing quotes={quotes.rows} isLoading={false} />
      </AgencyDashboardListingContainer>
    </>
  );
};

interface BindersUseQueryResult extends ClientDataUseQueryResult {
  rows: Binder[];
}

interface AvailableBindersListingProps {
  binders?: BindersUseQueryResult;
}

const AvailableBindersListing = ({ binders }: AvailableBindersListingProps) => {
  if (!binders) return null;

  const headerConfig = getPoliciesHeaderConfig(true);

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" m={3}>
        <Typography variant="body2">
          Binder already available for this Client!
        </Typography>
        <Typography variant="body2">
          Please proceed with the binder below, if you do not want to create a
          new quote.
        </Typography>
      </Box>
      <AgencyDashboardListingContainer totalPages={binders.totalPages}>
        <ListingHeader headerConfig={headerConfig} />
        <PoliciesListing policies={binders.rows} isLoading={false} isBinder />
      </AgencyDashboardListingContainer>
    </>
  );
};

interface PoliciesUseQueryResult extends ClientDataUseQueryResult {
  rows: Policy[];
}

interface ActivePolicyListingProps {
  policies?: PoliciesUseQueryResult;
  isOpenForRenewal: boolean;
}

const ActivePoliciesListing = ({
  policies,
  isOpenForRenewal,
}: ActivePolicyListingProps) => {
  if (!policies) return null;

  const headerConfig = getPoliciesHeaderConfig(false);

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" m={3}>
        <Typography variant="body2">
          {isOpenForRenewal
            ? 'There is a Policy for this Client and it is currently inside of the Renewal window! '
            : 'There is a Policy for this Client and it is currently outside of the Renewal window!'}
        </Typography>
        <Typography variant="body2">
          {isOpenForRenewal
            ? 'Please proceed to create a Renewal Quote for this Client.'
            : 'Please proceed to Manage to update the Client Information in preparation for the Renewal.'}
        </Typography>
      </Box>
      <AgencyDashboardListingContainer totalPages={policies.totalPages}>
        <ListingHeader headerConfig={headerConfig} />
        <PoliciesListing
          policies={policies.rows}
          isLoading={false}
          isBinder={false}
        />
      </AgencyDashboardListingContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.agencyDash.interactable,
    cursor: 'pointer',
  },
  warningText: {
    color: theme.agencyDash.text.warning,
  },
}));

const AccountSupplementaryInfoContainer = ({ children }: WithChildrenProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      mt={2}
      alignItems="center"
      mb={2}
    >
      {children}
    </Box>
  );
};
