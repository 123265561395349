// mui
import { Grid, Typography, Button, Paper, Box } from '@mui/material';

import { makeStyles, useTheme } from '@mui/styles';

// utils
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import type { AgencyDashboardListingTab } from '../agency-dashboard.statics';
import { AGENCY_DASHBOARD_LISTING_TABS } from '../agency-dashboard.statics';
import { AgencyDashboardSearch } from '../global-search/AgencyDashboardSearch';
import { AgencyDashboardListingOverlay } from '../onboarding-screens/AgencyDashboardListingOverlay';
import { AgencyDashboardListing } from './AgencyDashboardListing';
import { AgencyDashboardFilters } from './AgencyDashboardFilters';
import {
  AMPLITUDE_PROPERTIES,
  AMPLITUDE_PROPERTIES as ANALYTICS_PROPERTIES,
  useAmplitude as useAnalytics,
} from '../../../../providers/AmplitudeProvider';
import { DashboardAgentGreeting } from '../modules/DashboardAgentGreeting';

export const AgencyDashboardListingModule = () => {
  const theme = useTheme();

  return (
    <Box width="100%" position="relative">
      <Paper
        elevation={3}
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          backgroundColor: theme.agencyDash.background.paper,
        }}
      >
        <DashboardAgentGreeting />
        <AgencyDashboardSearch />
        <AgencyDashboardTabs />
        <AgencyDashboardListing />
      </Paper>
      <AgencyDashboardListingOverlay />
    </Box>
  );
};

const AgencyDashboardTabs = () => {
  return (
    <Box width="100%" style={{ padding: '1rem' }}>
      <Box display="flex" justifyContent="space-between">
        <Tabs />
        <AgencyDashboardFilters />
      </Box>
    </Box>
  );
};

const Tabs = () => {
  return (
    <Box display="flex">
      <Tab name={AGENCY_DASHBOARD_LISTING_TABS.QUOTES} />
      <Tab name={AGENCY_DASHBOARD_LISTING_TABS.BINDERS} />
      <Tab name={AGENCY_DASHBOARD_LISTING_TABS.POLICIES} />
      <Tab name={AGENCY_DASHBOARD_LISTING_TABS.ACCOUNTS} label="CLIENTS" />
    </Box>
  );
};

interface TabProps {
  name: AgencyDashboardListingTab;
  label?: string;
}

const Tab = ({ name, label }: TabProps) => {
  const analytics = useAnalytics();
  const theme = useTheme<any>();
  const agencyDashboardState = useAgencyDashboardState();
  const isSelected = name === agencyDashboardState.currentTab;
  const classes = useTabStyles({
    isSelected,
  });

  const handleClick = () => {
    const tabName =
      ANALYTICS_TAB_NAMES[
        name.toLowerCase() as keyof typeof ANALYTICS_TAB_NAMES
      ];
    analytics.track(`click_${tabName}`, {
      version: ANALYTICS_PROPERTIES.version.two,
    });
    agencyDashboardState.handleListingTabChange(name);
  };

  return (
    <Grid item>
      <Button
        onClick={handleClick}
        data-qa={`TAB:${name}`}
        classes={classes}
        disableRipple
      >
        <Typography variant="body2">{label ?? name}</Typography>
      </Button>
    </Grid>
  );
};

const useTabStyles = makeStyles((theme) => ({
  root: {
    padding: '0.5rem 2rem',
    marginRight: '0.6rem',
    boxShadow: '1px 1px 3px 0 rgba(0, 0, 0, 0.2)',
    color: ({ isSelected }: any) => {
      return isSelected
        ? theme.agencyDash.text.tab
        : theme.palette.primary.contrastText;
    },
    background: ({ isSelected }: any) => {
      return isSelected
        ? theme.agencyDash.tabs.selected
        : theme.agencyDash.tabs.default;
    },
    '&:hover': {
      backgroundColor: theme.agencyDash.tabs.hover,
      color: theme.agencyDash.text.tab,
    },
    '&:focus': {
      backgroundColor: theme.agencyDash.tabs.selected,
      color: theme.agencyDash.text.tab,
    },
  },
}));

const ANALYTICS_TAB_NAMES = {
  quotes: AMPLITUDE_PROPERTIES.source.quotesTab,
  binders: AMPLITUDE_PROPERTIES.source.bindersTab,
  policies: AMPLITUDE_PROPERTIES.source.policiesTab,
  accounts: AMPLITUDE_PROPERTIES.source.clientsTab,
};
