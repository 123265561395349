import React from 'react';
import classnames from 'classnames';

// components
import { makeStyles } from '@mui/styles';
import { useFormContext } from 'react-hook-form';
import type { AxiosResponse } from 'axios';
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import { withShowable } from '../../console/_global/lib/withShowable';
import { TypeAheadBase } from '../../components/inputs/autocomplete/index';

// services
import { fetchAgencies } from '../../api/apis';
import {
  getSelectItemAgency,
  reduceForSelect,
} from '../../console/admin/users/UsersUtils';
import { useCowbellTranslations } from '../../i18n/translations';

const defaultLabelProps = {
  style: { fontSize: '1rem' },
};

interface AgencyLookupFieldProps {
  show?: boolean;
  defaultOptions: Record<string, any>;
  label: string;
  labelProps: React.ComponentProps<typeof InputLabelBase>;
  name: string;
  required?: boolean;
}

/* Copy paste of old Typeahead for usage with RHF7 */
export const AgencyLookupField = withShowable(
  ({
    show = true,
    defaultOptions,
    label,
    labelProps = defaultLabelProps,
    ...props
  }: AgencyLookupFieldProps) => {
    const { t, translationKeys } = useCowbellTranslations();
    if (!show) {
      return null;
    }

    const classes = useStyles();
    const inputClasses = classnames({
      [classes.inline]: labelProps.inline,
    });

    const { setValue } = useFormContext();
    const [options, setOptions] = React.useState(defaultOptions);

    function handleChange(newValue: string) {
      setValue(props.name, newValue);
    }

    const onFetch = React.useCallback(({ input }, callback) => {
      fetchAgencies({ search: input, size: 100 }).then(
        ({ data }: AxiosResponse) => {
          callback(reduceForSelect(data.content, getSelectItemAgency));
        }
      );
      // eslint-disable-next-line
    }, []);

    function onOptionsChange(newOptions: Record<string, any>) {
      setOptions(newOptions);
    }

    return (
      <>
        <InputLabelBase
          indent
          htmlFor={props.name}
          required={props.required}
          {...labelProps}
        >
          {label || t(translationKeys.agency)}
        </InputLabelBase>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <TypeAheadBase
          id="agency"
          options={options}
          onChange={handleChange}
          onOptionsChange={onOptionsChange}
          onFetch={onFetch}
          className={inputClasses}
          {...props}
        />
      </>
    );
  }
);

const useStyles = makeStyles(() => ({
  inline: {
    '& input': {
      padding: '6px !important',
    },
  },
}));
