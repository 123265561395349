/* eslint-disable guard-for-in */
import React, { useState } from 'react';
import _ from 'lodash';
import {
  Grid,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  DialogContent,
  FormHelperText,
} from '@mui/material';

import { withStyles } from '@mui/styles';

import { useForm, FormContext } from 'react-hook-form-4';
import * as Yup from 'yup';
import CBButton from '../Buttons/CbButton';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/withFormController';
import { SimpleSelect } from '../inputs/selects/SimpleSelect';

// utils

import { withShowable } from '../../console/_global/lib/withShowable';
import { postFeedback } from '../../console/agencies/_services/accounts.service.js';
import { useToggleModal } from '../../utils/modal.utils';

const Select = withFormController(SimpleSelect);
const MuiGrid = withShowable(Grid);
const TextField = withFormController(TextFieldBase);
const RadioGroups = withFormController(RadioGroup);
const schema = Yup.object().shape({
  category: Yup.string().required('Category is required'),
  feedbackText: Yup.string()
    .required('Description is required')
    .max(500, 'Must be 500 characters or less'),
});

const feedbackOptions = [
  {
    label: 'General Feedback',
    value: 'General Feedback',
  },
  {
    label: 'Give Kudos to the team',
    value: 'Give Kudos to the team',
  },
  {
    label: 'Report an issue',
    value: 'Report a high priority issue',
  },
  {
    label: 'Have a question',
    value: 'Have a question',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const FeedbackModal = withStyles(({ config, palette }) => ({
  rowBox: { display: 'flex', flexDirection: 'row ', alignItems: 'center' },
  labelStyle: {
    fontFamily: 'Titillium Web',
    fontSize: '1.25rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    textAlign: 'left',
  },
  requiredIcon: {
    color: '#f95f53',
    fontSize: '1.25rem',
    lineHeight: '1rem',
  },
  labelStyleHidden: {
    fontFamily: 'Titillium Web',
    fontSize: '1.1rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    textAlign: 'left',
  },
  radioBox: {
    width: '150px',
    height: '32px',
    padding: '10.7px 19px 7.6px 20.4px',
    borderRadius: '5px',
    border: 'solid 0.8px #c9cdd9',
    display: 'flex',
    flexDirection: 'row',
  },
}))(({ classes, ...props }) => {
  const toggleModal = useToggleModal();

  const [backendError, setBackendError] = useState('');

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      category: feedbackOptions[0].value,
      feedbackText: '',
      permissionToShare: 'No',
    },
    validationSchema: schema,
  });

  const {
    formState: { isSubmitting, dirty },
  } = methods;

  function onSubmit({ ...formData }) {
    let permissionToShare;
    if (formData.permissionToShare == 'Yes') {
      permissionToShare = true;
    } else if (formData.permissionToShare) {
      permissionToShare = false;
    }
    postFeedback({}, { ...formData, permissionToShare })
      .then(() => {
        toggleModal.direct(
          'FeedbackConfirm',
          {},
          { title: 'Thank You', maxWidth: 'sm' }
        );
      })
      .catch((error) => {
        setBackendError(error.response.data);
      });
  }

  React.useEffect(() => {
    methods.watch('category');
    methods.watch('permissionToShare');
  }, [methods]);

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <MuiGrid container spacing={2} alignItems="center">
            <MuiGrid item>
              <h2 className={classes.labelStyle}>
                Please select a Category
                <i className={classes.requiredIcon}>*</i>
              </h2>
            </MuiGrid>
            <MuiGrid item style={{ float: 'right' }}>
              <Select
                name="category"
                options={feedbackOptions}
                style={{
                  paddingTop: '-100px',
                  right: 0,
                  minWidth: '12.9rem',
                  maxWidth: '12.9rem',
                }}
              />
            </MuiGrid>
          </MuiGrid>

          <div style={{ display: 'flex', flexDirection: 'row ' }}>
            <h2 className={classes.labelStyle}>
              Description
              <i className={classes.requiredIcon}>*</i>
            </h2>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row ',
              marginTop: '-1.5rem',
            }}
          >
            <TextField
              required
              name="feedbackText"
              multiline
              minRows="5"
              margin="normal"
              fullWidth
            />
          </div>
          <MuiGrid
            spacing={3}
            alignItems="center"
            container
            show={methods.getValues().category === 'Give Kudos to the team'}
          >
            <MuiGrid item>
              <h2 className={classes.labelStyleHidden}>
                Permission to share your feedback
              </h2>
            </MuiGrid>
            <MuiGrid item>
              <RadioGroups aria-label="actions" name="permissionToShare">
                <div className={classes.radioBox}>
                  <FormControlLabel
                    label="Yes"
                    value="Yes"
                    control={<Radio color="secondary" />}
                    size="large"
                  />
                  <FormControlLabel
                    label="No"
                    value="No"
                    control={<Radio color="secondary" />}
                  />
                </div>
              </RadioGroups>
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <FormHelperText className="api-text-error">
          {backendError}
        </FormHelperText>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <CBButton size="medium" styleName="cancel" onClick={props.close}>
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            styleName="ctaButton"
            size="medium"
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            Send
          </CBButton>
        </DialogActions>
      </form>
    </FormContext>
  );
});
