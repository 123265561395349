import React from 'react';

const GO_FORWARD = 'GO_FORWARD';
const GO_BACK = 'GO_BACK';
const COMPLETE_STEP = 'COMPLETE_STEP';
const JUMP_TO = 'JUMP_TO';
const SET_STEPS = 'SET_STEPS';

type Actions<T = string | number> =
  | { type: typeof GO_FORWARD }
  | { type: typeof GO_BACK }
  | { type: typeof COMPLETE_STEP; payload?: T }
  | { type: typeof JUMP_TO; payload: T }
  | { type: typeof SET_STEPS; payload: T[] };

const initialState = {
  steps: [],
  step: null,
  stepIndex: 0,
  completed: {},
  nextStep: null,
  previousStep: null,
  isFirstStep: true,
  isLastStep: false,
};

export interface StepperState<T = string | number> {
  steps: T[];
  step: T | null;
  stepIndex: number;
  completed: { [key: string]: boolean };
  nextStep: T | null;
  previousStep: T | null;
  isFirstStep: boolean;
  isLastStep: boolean;
}

const stepsReducer = <T = string | number>(
  state: StepperState<T>,
  action: Actions<T>
): StepperState<T> => {
  switch (action.type) {
    case GO_FORWARD: {
      const { stepIndex } = state;
      let nextStepIndex = stepIndex;

      if (stepIndex + 1 < state.steps.length) {
        nextStepIndex = stepIndex + 1;
      }

      return {
        ...state,
        step: state.steps[nextStepIndex],
        stepIndex: nextStepIndex,
        nextStep: state.steps[nextStepIndex + 1] || null,
        previousStep: state.steps[nextStepIndex - 1] || null,
        isFirstStep: nextStepIndex === 0,
        isLastStep: nextStepIndex === state.steps.length - 1,
      };
    }

    case GO_BACK: {
      const { stepIndex } = state;
      let nextStepIndex = stepIndex;

      if (stepIndex - 1 >= 0) {
        nextStepIndex = stepIndex - 1;
      }
      return {
        ...state,
        step: state.steps[nextStepIndex],
        stepIndex: nextStepIndex,
        nextStep: state.steps[nextStepIndex + 1] || null,
        previousStep: state.steps[nextStepIndex - 1] || null,
        isFirstStep: nextStepIndex === 0,
        isLastStep: nextStepIndex === state.steps.length - 1,
      };
    }

    case JUMP_TO: {
      const indexToJump = state.steps.indexOf(action.payload);

      if (indexToJump === -1) {
        return state;
      }

      return {
        ...state,
        step: state.steps[indexToJump],
        stepIndex: indexToJump,
        nextStep: state.steps[indexToJump + 1] || null,
        previousStep: state.steps[indexToJump - 1] || null,
        isFirstStep: indexToJump === 0,
        isLastStep: indexToJump === state.steps.length - 1,
      };
    }

    case SET_STEPS: {
      const { stepIndex } = state;
      const nextSteps = action.payload;
      return {
        ...state,
        step: nextSteps[stepIndex],
        steps: nextSteps,
        nextStep: nextSteps[stepIndex + 1] || null,
        previousStep: nextSteps[stepIndex - 1] || null,
        isFirstStep: stepIndex === 0,
        isLastStep: stepIndex === state.steps.length - 1,
      };
    }

    case COMPLETE_STEP: {
      return {
        ...state,
        completed: {
          ...state.completed,
          [(action.payload || state.steps[state.stepIndex]) as string | number]:
            true,
        },
      };
    }

    default:
      return state;
  }
};

interface UseStepsParams<T> {
  startStep: T;
  initialSteps: T[];
  reducer?: typeof stepsReducer<T>;
}

export const useSteps = <T = number | string>({
  startStep,
  initialSteps = [],
  reducer = stepsReducer as typeof stepsReducer<T>,
}: UseStepsParams<T>) => {
  const initialStepIndex = initialSteps.indexOf(startStep);
  const [
    {
      step,
      stepIndex,
      steps,
      completed,
      previousStep,
      nextStep,
      isFirstStep,
      isLastStep,
    },
    dispatch,
  ] = React.useReducer(reducer, {
    ...initialState,
    step: initialSteps[initialStepIndex] || null,
    stepIndex: initialStepIndex,
    steps: initialSteps,
    nextStep: initialSteps[initialStepIndex + 1] || null,
    previousStep: initialSteps[initialStepIndex - 1] || null,
    isFirstStep: initialStepIndex === 0,
    isLastStep: initialStepIndex === initialSteps.length - 1,
  });

  const isCompleted = (stepToFind: string) => {
    return completed[stepToFind];
  };

  function goBack() {
    dispatch({ type: GO_BACK });
  }

  function goForward() {
    dispatch({ type: GO_FORWARD });
  }

  function completeStep(stepToComplete: T) {
    if (stepToComplete) {
      dispatch({ type: COMPLETE_STEP, payload: stepToComplete });
    } else {
      dispatch({ type: COMPLETE_STEP });
    }
  }

  function setSteps(nextSteps: T[] = []) {
    dispatch({ type: SET_STEPS, payload: nextSteps });
  }

  const jumpTo = React.useCallback((futureStep: T) => {
    dispatch({ type: JUMP_TO, payload: futureStep });
  }, []);

  return {
    step,
    stepIndex,
    stepNumber: stepIndex + 1,
    steps,
    setSteps,
    completed,
    isCompleted,
    nextStep,
    goForward,
    goBack,
    previousStep,
    completeStep,
    jumpTo,
    isLastStep,
    isFirstStep,
  };
};
