import Moment from 'moment';

// mui
import { Typography as TypographyBase } from '@mui/material';
import { getShortDateTimeFromUtc } from '../../../../../utils/csvUtils';
import { deriveCurrencyFormat } from '../../../../../i18n/currencies';
import { getRegionByProduct } from '../../../../../i18n/utils';
import { getCowbellTranslations } from '../../../../../i18n/translations';

export const tabs = {
  POLICY_INFORMATION_TAB: 'policy-information',
  ORGANIZATION_DETAILS_TAB: 'organization-details',
  COWBELL_FACTORS_TAB: 'cowbell-factors',
};

const { t, translationKeys } = getCowbellTranslations();

export const subroutes = [
  {
    label: 'Policy Information',
    value: tabs.POLICY_INFORMATION_TAB,
  },
  {
    label: `${t(translationKeys.organization)} Details`,
    value: tabs.ORGANIZATION_DETAILS_TAB,
  },
  {
    label: 'Cowbell Factors',
    value: tabs.COWBELL_FACTORS_TAB,
  },
];

export const formatCancellationPolicy = (
  cancellationDate,
  cancellationTriggerDate
) => {
  const uiCancellationDate = cancellationTriggerDate
    ? getShortDateTimeFromUtc(cancellationTriggerDate)
    : '-';

  const uiCancellationEffectiveDate = cancellationDate
    ? getShortDateTimeFromUtc(cancellationDate)
    : '-';

  return { uiCancellationDate, uiCancellationEffectiveDate };
};

export const formatPolicySummaryData = ({
  limit,
  deductible,
  effectiveDate,
  expiresOn,
  issuedDate,
  product,
}) => {
  const language = getRegionByProduct(product);
  const uiLimit = deriveCurrencyFormat(language, limit, {
    minimumFractionDigits: 0,
  });
  const uiDeductible = deriveCurrencyFormat(language, deductible, {
    minimumFractionDigits: 0,
  });

  const uiEffectiveDate = Moment.utc(effectiveDate).format(
    'MMM DD, YYYY h:mm a'
  );

  const uiExpiresOn = Moment.utc(expiresOn).format('MMM DD, YYYY h:mm a');
  const uiIssuedDate = issuedDate
    ? Moment.utc(issuedDate).format('MMM DD, YYYY h:mm a')
    : '-';

  return {
    uiLimit,
    uiDeductible,
    uiEffectiveDate,
    uiExpiresOn,
    uiIssuedDate,
  };
};

export const PolicyDrawerTypography = ({ children, ...props }) => (
  <TypographyBase variant="caption" {...props}>
    {children}
  </TypographyBase>
);
