import React from 'react';

import {
  Box,
  DialogActions,
  DialogContent,
  styled,
  useTheme,
} from '@mui/material';

import WarningIconBase from '@mui/icons-material/Warning';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import CbButton from '../../Buttons/CbButton';

import { iconMappings } from '../../../console/_global/navigation/IconsMappings';
import { loadTemplateToRiskEngAccount } from '../../../api/riskEng.api';

export const RiskEngImportTemplate = ({ iconStyles, data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { palette } = useTheme();

  const loadTemplateToAccount = ({ override = false }) => {
    loadTemplateToRiskEngAccount(data.accountId, {
      data: {
        mrqIds: data.mrqIds,
        override,
      },
    })
      .then(() => {
        queryClient.invalidateQueries(['risk-eng']);
        enqueueSnackbar('Template successfully loaded into account', {
          variant: 'success',
        });
        props.close();
      })
      .catch(() => {
        enqueueSnackbar('Failed to load template into account', {
          variant: 'error',
        });
      });
  };

  return (
    <>
      <DialogContent>
        <Box mb={1} width="100%" display="flex" justifyContent="center">
          <WarningIcon fontSize="large" />
        </Box>
        <Text>
          To start fresh, click override, and it will load {data.name}. You will
          lose your existing set of questions. In order to add the questions
          from {data.name} to the existing set of questions, click add
          questions.
        </Text>
      </DialogContent>
      <DialogActions>
        <CbButton onClick={props.close} styleName="cancel">
          Cancel
        </CbButton>

        <CbButton
          type="submit"
          onClick={loadTemplateToAccount}
          styleName="ctaButton"
        >
          Add Questions
        </CbButton>
        <CbButton
          type="submit"
          onClick={() => loadTemplateToAccount({ override: true })}
          styleName="ctaButton"
          style={{ backgroundColor: palette.text.text9 }}
        >
          Override
        </CbButton>
      </DialogActions>
    </>
  );
};

export const RiskEngImportTemplateConfig = {
  RiskEngImportTemplate: {
    component: RiskEngImportTemplate,
    config: {
      fullWidth: true,
      title: 'Load Template',
      icon: iconMappings.PoliciesIcon.iconImport,
    },
  },
};

const Text = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.config.textSizes.primer,
}));

const WarningIcon = styled(WarningIconBase)(({ theme }) => ({
  color: theme.palette.primary.mainYellow,
  '&:hover': {
    color: theme.palette.primary.mainYellow,
    cursor: 'default',
  },
}));
