import React from 'react';
// mui
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const InsuredInformation = ({ insuredInfo }) => {
  const classes = useStyles();

  if (!insuredInfo) {
    return null;
  }

  const { address1, address2 = '', city, state, zipCode } = insuredInfo;

  return (
    <>
      <div className={classes.heading}>Insured Information</div>
      <Grid
        container
        direction="column"
        item
        md={7}
        style={{ textAlign: 'left', paddingLeft: '1rem' }}
      >
        <Grid Item>{insuredInfo?.companyName}</Grid>
        <Grid item>
          {address1}, {address2}
        </Grid>
        <Grid item>
          {city}, {state} {zipCode?.split('-')[0]}
        </Grid>
      </Grid>
    </>
  );
};

export default InsuredInformation;

const useStyles = makeStyles(({ palette }) => {
  return {
    heading: {
      textDecoration: 'underline',
      'text-underline-offset': '2px',
      textTransform: 'uppercase',
      textAlign: 'left',
      fontSize: '1.16rem',
      fontWeight: 600,
      lineHeight: 1.5,
      color: palette.primary.contrastText,
      paddingTop: '2rem',
      paddingBottom: '0.66rem',
    },
  };
});
