import React from 'react';
import { TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';
import { TableStateContext } from '../TableStateContext';

export const ColumnResizingPlugin = () => {
  const { state, handlers } = React.useContext(TableStateContext);
  return (
    <ColumnSizingPluginBase
      columnWidths={state.columnWidths}
      onColumnWidthsChange={handlers.onColumnWidthsChange}
    />
  );
};

const ColumnSizingPluginBase = ({ columnWidths, onColumnWidthsChange }) => (
  <TableColumnResizing
    columnWidths={columnWidths}
    onColumnWidthsChange={onColumnWidthsChange}
  />
);
