import {
  applyQueryConfig,
  responseStringTransformer,
  ServiceHandler,
} from '../../utils';
import {
  serializeElasticPageableResponse,
  standardQuerySerializer,
} from '../serializers';

interface SortParams {
  order: string;
  orderBy: string;
}

export const getUsers = ServiceHandler({
  url: '/api/auth/v1/users',
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableResponse,
  ],
});

const userParamsSerializer = [
  { path: 'search' },
  { path: 'size' },
  { path: 'page' },
  { path: 'agencyId' },
  {
    paramKey: 'sort',
    transform: ({ order, orderBy }: SortParams) => {
      if (order && orderBy) {
        return `${orderBy},${order}`;
      }
      return null;
    },
  },
];

export const getAgencyUsersByAgencyId = ServiceHandler({
  url: 'api/auth/v1/users',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(userParamsSerializer, params)),
});

export const checkUserLoggedInStatus = ServiceHandler({
  url: '/api/auth/v1/users/login/status',
  method: 'POST',
});

export const migrateAgencyUser = ServiceHandler({
  url: '/api/auth/v1/users/migrate/:userId',
  method: 'POST',
});

export const bulkMigrateAgencyUsers = ServiceHandler({
  url: '/api/auth/v1/users/migrate',
  method: 'POST',
});

export const getUserMigrationsListing = ServiceHandler({
  url: '/api/auth/v1/users/migrations',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(userParamsSerializer, params)),
});

export const retriggerUserMigration = ServiceHandler({
  url: '/api/auth/v1/users/migrations/:migrationId',
  method: 'PUT',
});
