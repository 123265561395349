import React from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import CbButton from '../Buttons/CbButton';

interface ConfirmLeavePageModalProps {
  data: {
    handleConfirm: () => void;
    setShowPrompt: (state: boolean) => void;
  };
  close: () => void;
}

const ConfirmLeavePageModal = ({
  data: modalData,
  close,
}: ConfirmLeavePageModalProps) => {
  React.useEffect(() => {
    if (modalData.setShowPrompt) {
      modalData.setShowPrompt(false);
    }
  }, []);

  const handleConfirm = () => {
    modalData.handleConfirm();
    close();
  };

  const handleCancel = () => {
    if (modalData.setShowPrompt) {
      modalData.setShowPrompt(true);
    }
    close();
  };

  return (
    <>
      <DialogContent
        className="contrast-text"
        style={{ padding: '2rem', textAlign: 'center', fontSize: '1.2rem' }}
      >
        <p>
          You have unsaved changes on this page! By choosing “ok”, you will
          leave and your changes will be lost. Choose “cancel” to review and
          save your changes.
        </p>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" size="medium" onClick={handleCancel}>
          Cancel
        </CbButton>
        <CbButton styleName="ctaButton" size="medium" onClick={handleConfirm}>
          Ok
        </CbButton>
      </DialogActions>
    </>
  );
};

export const ConfirmLeavePageModalConfig = {
  ConfirmLeavePageModal: {
    component: ConfirmLeavePageModal,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      minWidth: 'sm',
    },
  },
};
