export const TRANSLATION_KEYS = {
  agent: 'Agent',
  agents: 'Agents',
  agency: 'Agency',
  revenue: 'Revenue',
  organization: 'Organization',
  agencies: 'Agencies',
  retention: 'Retention',
  zipCode: 'Zip Code',
  customize: 'Customize',
  usRevenue: 'US Revenue',
  nonUsRevenue: 'Non US Revenue',
  totalRevenue: 'Total Revenue',
  check: 'Check',
  checked: 'Checked',
  checking: 'Checking',
} as const;

export type TranslationKeys =
  (typeof TRANSLATION_KEYS)[keyof typeof TRANSLATION_KEYS];
