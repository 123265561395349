import type { ConnectorType } from '../../policyholders/connectors/connectors.constants';
import { CONNECTOR_TYPES } from '../../policyholders/connectors/connectors.constants';
import { ServiceHandler } from '../../utils';
import { CloudflareSchema } from './schemas/cloudflare.schema';
import { GoogleCloudSecurityCommandCenterSchema } from './schemas/googleCloudSecurityCommandCenter.schema';
import { GoogleWorkSpaceSchema } from './schemas/googleWorkspace.schema';
import { SophosSchema } from './schemas/sophos.schema';

const schemaMapping = {
  [CONNECTOR_TYPES.AWS]: {},
  [CONNECTOR_TYPES.CLOUDFLARE]: CloudflareSchema,
  [CONNECTOR_TYPES.GOOGLEWORKSPACE]: GoogleWorkSpaceSchema,
  [CONNECTOR_TYPES.GSC]: GoogleCloudSecurityCommandCenterSchema,
  [CONNECTOR_TYPES.MICROSOFT]: {},
  [CONNECTOR_TYPES.QUALYSCS]: {},
  [CONNECTOR_TYPES.QUALYSPC]: {},
  [CONNECTOR_TYPES.QUALYSVM]: {},
  [CONNECTOR_TYPES.SAFEGUARD]: {},
  [CONNECTOR_TYPES.SECUREWORKS]: {},
  [CONNECTOR_TYPES.SECURITYSTUDIO]: {},
  [CONNECTOR_TYPES.SOPHOS]: SophosSchema,
  [CONNECTOR_TYPES.WIZER]: {},
};

export const getConnectorSchema = (connectorType: ConnectorType) => {
  switch (connectorType) {
    case connectorType:
      return Promise.resolve(schemaMapping[connectorType]);
    default:
      return {};
  }
};

export const deleteConnectorById = ServiceHandler<unknown, unknown>({
  url: '/api/connector/v1/:connectorId',
  method: 'DELETE',
});

export const addConnector = ServiceHandler({
  url: '/api/connector/v1/add',
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  data: null, // this is needed or Content-Type header is removed
});

interface WizerConnectorDetailsResponse {
  listTrainingMagicLink: string;
  addUserMagicLink: string;
  dashboardMagicLink: string;
}

export const getWizerConnectorDetails = ServiceHandler<
  unknown,
  WizerConnectorDetailsResponse
>({
  url: '/api/connector/v1/wizer/generateMagicLinks',
});
