import React from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form-4';
// mui
import { makeStyles } from '@mui/styles';
// components
import {
  RegisteredTypeAhead,
  ManagedTypeAheadBase as TypeAheadBase,
} from '../../components/inputs/autocomplete';
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import { withShowable } from '../../console/_global/lib/withShowable';
// services
import {
  getAgentSurplusLineLicenses,
  getCowbellAgentSurplusLineLicenses,
} from '../../console/agencies/_services/licenses.service';
import { ManagedFetchingAutoComplete } from '../../components/inputs/autocomplete/ManagedFetchingAutoComplete';

const defaultLabelProps = {
  style: { paddingTop: 0 },
};

const InputLabel = withShowable(InputLabelBase);

export const BrokerLookupField = withShowable(
  ({
    show = true,
    defaultOptions,
    label,
    labelProps = defaultLabelProps,
    state,
    ...props
  }) => {
    if (!show) {
      return null;
    }
    const classes = useStyles();
    const inputClasses = classnames({
      [classes.inline]: labelProps.inline,
    });

    const { setValue } = useFormContext();
    const [options, setOptions] = React.useState(defaultOptions);

    function handleChange(newValue) {
      setValue(props.name, newValue);
    }

    const onFetch = React.useCallback(
      ({ input }, callback) => {
        getAgentSurplusLineLicenses({
          surplusLinesState: state,
          search: input,
        }).then(({ data }) => {
          callback(
            data.content.map((broker) => ({
              label: `${broker.brokerFirstName} ${broker.brokerLastName} [${broker.brokerEmail}]`,
              value: broker.brokerEmail,
              meta: {
                ...broker,
              },
            }))
          );
        });
      },
      [state]
    );

    function onOptionsChange(newOptions) {
      setOptions(newOptions);
    }

    return (
      <>
        <InputLabelBase
          indent
          htmlFor={props.name}
          required={props.required}
          {...labelProps}
        >
          {label || 'Surplus Broker'}
        </InputLabelBase>
        <TypeAheadBase
          id="surplusBrokerOnFile"
          options={options}
          onChange={handleChange}
          onOptionsChange={onOptionsChange}
          onFetch={onFetch}
          className={inputClasses}
          {...props}
        />
      </>
    );
  }
);

export const SurplusAutoAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  state,
  ...props
}) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(
    ({ input }, callback) => {
      return getAgentSurplusLineLicenses({
        surplusLinesState: state,
        search: input,
      }).then((response) =>
        callback(
          response.data.content.map((broker) => ({
            label: `${broker.brokerFirstName} ${broker.brokerLastName} [${broker.brokerEmail}]`,
            value: broker.brokerEmail,
            meta: {
              ...broker,
            },
          }))
        )
      );
    },
    [state]
  );

  return (
    <>
      <InputLabel
        show={Boolean(label)}
        indent
        htmlFor={props.name}
        required={props.required}
        {...labelProps}
      >
        {label || 'Surplus Broker'}
      </InputLabel>
      <TypeAheadBase
        id="surplusBrokerOnFile"
        className={inputClasses}
        onFetch={handleSearch}
        filterOptions={filterOptions}
        {...props}
      />
    </>
  );
};

export const RegisteredSurplusAutoAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  state,
  ...props
}) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(
    ({ input }, callback) => {
      return getAgentSurplusLineLicenses({
        surplusLinesState: state,
        search: `${input}`,
      }).then((response) =>
        callback(
          response.data.content.map((broker) => ({
            label: `${broker.brokerFirstName} ${broker.brokerLastName} [${broker.brokerEmail}]`,
            value: broker.brokerEmail,
            meta: {
              ...broker,
            },
          }))
        )
      );
    },
    [state]
  );

  return (
    <>
      <InputLabel
        show={Boolean(label)}
        indent
        htmlFor={props.name}
        required={props.required}
        {...labelProps}
      >
        {label || 'Surplus Broker'}
      </InputLabel>
      <RegisteredTypeAhead
        id="surplusBrokerOnFile"
        className={inputClasses}
        onFetch={handleSearch}
        filterOptions={filterOptions}
        {...props}
      />
    </>
  );
};

export const RegisteredCowbellSurplusAutoAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  state,
  agencyId,
  ...props
}) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(
    ({ input }, callback) => {
      return getCowbellAgentSurplusLineLicenses({
        surplusLinesState: state,
        agencyId,
        search: `${input}`,
      }).then((response) =>
        callback(
          response.data.content.map((broker) => ({
            label: `${broker.brokerFirstName} ${broker.brokerLastName} [${broker.brokerEmail}]`,
            value: broker.brokerEmail,
            meta: {
              ...broker,
            },
          }))
        )
      );
    },
    [agencyId, state]
  );

  return (
    <>
      <InputLabel
        show={Boolean(label)}
        indent
        htmlFor={props.name}
        required={props.required}
        {...labelProps}
      >
        {label || 'Surplus Broker'}
      </InputLabel>
      <RegisteredTypeAhead
        id="surplusBrokerOnFile"
        className={inputClasses}
        onFetch={handleSearch}
        filterOptions={filterOptions}
        {...props}
      />
    </>
  );
};

export const ManagedSurplusBrokerAutoComplete = ({ agencyId, ...props }) => {
  const queryKey = ['agency-surplus-broker-options', agencyId];
  function fetchFuncton({ search }) {
    if (!agencyId) return Promise.resolve([]);

    return getCowbellAgentSurplusLineLicenses({ agencyId, search }).then(
      (res) =>
        res.data.content.map((broker) => ({
          ...broker,
          name: `${broker.brokerFirstName} ${broker.brokerLastName} [${broker.brokerEmail}]`,
          id: broker.brokerEmail,
        }))
    );
  }

  return (
    <ManagedFetchingAutoComplete
      {...props}
      queryKey={queryKey}
      fetchFunc={fetchFuncton}
    />
  );
};

function filterOptions(options) {
  return options;
}

const useStyles = makeStyles(() => ({
  inline: {
    '& input': {
      padding: '6px !important',
    },
  },
}));
