import * as qs from 'qs';
import { ServiceHandlerFactory } from '../utils/serviceUtils';

import { accountServiceMappings } from '../accounts/accountServiceMappings';
import { ServiceHandler } from '../utils';

export const getVendors = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/vendor/search',
  },
  isCancelable: true,
  paramSchema: accountServiceMappings,
});

export const getVendorById = ServiceHandler({
  url: '/api/account/v1/vendor/id/:vendorId',
});

export const createVendor = ServiceHandler({
  url: '/api/auth/v1/vendor',
  method: 'POST',
});

export const updateVendor = ServiceHandler({
  url: '/api/auth/v1/vendor/:id',
  method: 'PUT',
});

export const deleteVendor = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/vendor/:id',
    method: 'delete',
  },
});

export const enterVendor = ServiceHandler({
  url: '/api/auth/v1/vendor/:vendorId/enter',
  method: 'POST',
});

export const getVendorDetails = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/vendor`,
  },
  isCancelable: true,
});

export const setVendorDetails = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/vendor',
    method: 'put',
  },
  isCancelable: true,
});

export const getVendorSubcategories = ServiceHandler({
  url: '/api/claimsmgmt/v1/panel/subcategories',
});

export const getVendorsBySubcategory = ServiceHandler({
  url: '/api/account/v1/vendor/panel',
  paramsSerializer: (params) => qs.stringify(params),
});
