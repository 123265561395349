import type { ReactNode } from 'react';
import { Modal } from '../modals/v2/helpers/v2.modal.helpers';

interface Props {
  docId: string;
  style?: {
    [key: string]: string;
  };
  children: ReactNode;
}

export const DocumentPreviewer: React.FC<Props> = ({
  style = {},
  children,
  docId,
}) => {
  const onClick = () => {
    if (docId) {
      Modal.show('DocumentPreviewModal', { data: { docId } });
    }
  };

  return (
    <div aria-hidden onClick={onClick} style={{ cursor: 'pointer', ...style }}>
      {children}
    </div>
  );
};
