import _ from 'lodash';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  List as ListBase,
  ListItem,
  Typography,
} from '@mui/material';
import type { FieldValues } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { styled } from '@mui/styles';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import type { AxiosError } from 'axios';
import CbButton from '../../../../components/Buttons/CbButton';
import type { Policy } from '../../../accounts/summary/types/policy.types';
import { withFormController } from '../../../../components/hocs/forms';
import { SimpleSelect } from '../../../../components/inputs';
import { getAgencyPrograms } from '../../../../agencies/AgencyService';
import type { Program } from '../../../../agencies/agency-detail-page/AgencyProgramsListing';
import { bulkUpdateProgramPolicies } from '../../../../api/policies.api';
import { manageAPIError } from '../../../../utils';
import { COWBELL_POLICY } from '../../../../components/tables/table_constants';
import { useAPIErrorHandler } from '../../../../components/hooks/useAPIErrorHandler';

const Select = withFormController(SimpleSelect);

interface SelectedItem {
  id: string;
  data: Policy;
}

const BulkAddProgramModal = ({ data: selection, ...props }: any) => {
  const formMethods = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const handleAPIError = useAPIErrorHandler();

  const { isValidAgency, isValidProduct } = useSelectionValidation(selection);

  const { agencyId } = selection[0].data;
  const { data: programs } = useQuery({
    queryKey: ['program-options', agencyId],
    queryFn: () => getAgencyPrograms(agencyId),
    select: (resp) => {
      return resp.data.map((program: Program) => ({
        label: program.name,
        value: program,
      }));
    },
  });

  const programOptions = _.isEmpty(programs)
    ? [{ label: 'No programs were found for the quoting agency', value: null }]
    : programs;

  if (!isValidAgency || !isValidProduct) {
    return (
      <>
        <DialogContent>
          <Typography variant="h6">Invalid Selection</Typography>
          <br />
          <DialogContentText>
            Please verify that all selected policies have the same product type,
            and are issued by the same agency, and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="ctaButton" onClick={props.close}>
            OK
          </CbButton>
        </DialogActions>
      </>
    );
  }

  const onSubmit = ({ program }: FieldValues) => {
    const targetProduct = selection[0].data.product;
    const policyIds = selection.map((item: SelectedItem) => item.id);
    return bulkUpdateProgramPolicies(targetProduct, {
      data: { policyIds, program },
    })
      .then((resp) => {
        props.close();
        enqueueSnackbar(resp.data, { variant: 'success' });
        queryClient.invalidateQueries([COWBELL_POLICY]);
      })
      .catch(
        handleAPIError(
          'There was an issue while bulk adding programs. Please try again'
        )
      );
  };

  const programValue = formMethods.watch('program');

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            Please review the selected policies and choose a program to add:
          </DialogContentText>
          <List>
            {selection.map((policy: SelectedItem) => (
              <ListItem key={policy.id}>
                <Typography>{`${policy.data.companyName} (${policy.data.policyNumber})`}</Typography>
              </ListItem>
            ))}
          </List>
          <Select name="program" label="Program" options={programOptions} />
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={props.close}>
            Cancel
          </CbButton>
          <CbButton
            styleName="ctaButton"
            type="submit"
            disabled={formMethods.formState.isSubmitting || !programValue}
          >
            Submit
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const BulkAddProgramModalConfig = {
  BulkAddProgramModal: {
    component: BulkAddProgramModal,
    config: {
      title: 'Bulk Add Program',
      fullWidth: true,
    },
  },
};

const List = styled(ListBase)(() => ({
  maxHeight: '20rem',
  overflow: 'auto',
  marginTop: '0.6rem',
}));

const useSelectionValidation = (selection: SelectedItem[]) => {
  const selectedAgencies = _.uniq(selection.map((item) => item.data.agencyId));

  const selectedProducts = _.uniq(selection.map((item) => item.data.product));

  return {
    isValidAgency: selectedAgencies.length === 1,
    isValidProduct: selectedProducts.length === 1,
  };
};
