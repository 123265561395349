import React from 'react';
import { DialogContent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import {
  BdsAutocomplete,
  DialogFooter,
  dialogPadding,
  InheritanceMessage,
  LevelField,
  SectionTitle,
  SegmentField,
  TamAutocomplete,
} from './helpers';
import CbButton from '../../components/Buttons/CbButton';
import { FormGridBuilder } from '../../components/forms/FormGridBuilder';
import { languages } from '../../i18n/i18n.language-config';
import { useCowbellTranslations } from '../../i18n/translations';

export const Step2 = ({ stepperProps, onCancel, ...aggregateFormState }) => {
  const { aggregateFormData, updateAggregateFormData } = aggregateFormState;
  const { goBack, goForward } = stepperProps;
  const { translations } = useCowbellTranslations(['Agency']);

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(
      Yup.object().shape(step2Validations(aggregateFormData.region))
    ),
    defaultValues: {
      ..._.pick(aggregateFormData, [
        'tamEmail',
        'bdsEmail',
        'npn',
        'fein',
        'segment',
        'level',
        'nationalAccountContactName',
        'parentAgencyId',
      ]),
    },
  });

  const segment = methods.watch('segment');

  const formConfig = React.useMemo(() => {
    return getStep2FormConfig({
      segment,
      aggregateFormState,
    });
  }, [aggregateFormState, segment]);

  const onSubmit = React.useCallback(
    (formData) => {
      const isValidInput = validateContactInfo(aggregateFormState);

      if (isValidInput) {
        updateAggregateFormData(formData);
        goForward();
      }
    },
    [aggregateFormState, goForward, updateAggregateFormData]
  );

  const onError = React.useCallback(() => {
    validateContactInfo(aggregateFormState);
  }, [aggregateFormState]);

  const onPrevious = () => {
    updateAggregateFormData(methods.getValues());
    goBack();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent style={dialogPadding}>
          <SectionTitle>{translations.Agency} Info</SectionTitle>
          <InheritanceMessage aggregateFormData={aggregateFormData} />
          <FormGridBuilder formConfig={formConfig} />
        </DialogContent>
        <DialogFooter message="Step: 2 of 3">
          <CbButton onClick={onCancel} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton onClick={onPrevious} styleName="ctaButton">
            Previous
          </CbButton>
          <CbButton styleName="ctaButton" type="submit">
            Next
          </CbButton>
        </DialogFooter>
      </form>
    </FormProvider>
  );
};

export const getStep2FormConfig = ({ segment, aggregateFormState }) => {
  const {
    aggregateFormData: { tamEmail, bdsEmail, parentAgencyId, region },
  } = aggregateFormState;

  const isUSRegion = region === languages['en-US'];

  return [
    {
      name: 'tamEmail',
      label: 'Primary Cowbell Contact',
      required: true,
      gridItemProps: { xs: 6 },
      component: TamAutocomplete,
      aggregateFormState,
      defaultValue: { value: tamEmail, label: tamEmail },
    },
    {
      name: 'bdsEmail',
      label: 'Secondary Cowbell Contact',
      required: true,
      gridItemProps: { xs: 6 },
      component: BdsAutocomplete,
      aggregateFormState,
      defaultValue: { value: bdsEmail, label: bdsEmail },
    },
    ...(isUSRegion
      ? [
          {
            name: 'npn',
            label: 'NPN',
            required: !parentAgencyId,
            gridItemProps: { xs: 6 },
          },
        ]
      : []),
    ...(isUSRegion
      ? [
          {
            name: 'fein',
            label: 'FEIN',
            gridItemProps: { xs: 6 },
          },
        ]
      : []),
    {
      name: 'segment',
      label: 'Segment',
      required: true,
      gridItemProps: { xs: 6 },
      component: SegmentField,
    },
    {
      name: 'level',
      label: 'Level',
      required: true,
      gridItemProps: { xs: 6 },
      component: LevelField,
    },
    {
      name:
        segment === 'DIAMOND' ? 'nationalAccountContactName' : 'placeholder',
      label: 'National Account Contact',
      required: true,
      gridItemProps: { xs: 6 },
    },
  ];
};

export const validateContactInfo = (aggregateFormState) => {
  const {
    aggregateFormData: { meta, tamEmail, bdsEmail },
    updateAggregateFormData,
  } = aggregateFormState;

  if (!tamEmail && !bdsEmail) {
    updateAggregateFormData({
      meta: {
        ...meta,
        tamError: 'Primary Contact is a required field.',
        bdsError: 'Secondary Contact is a required field.',
      },
    });
  } else if (!tamEmail) {
    updateAggregateFormData({
      meta: { ...meta, tamError: 'Primary Contact is a required field.' },
    });
  } else if (!bdsEmail) {
    updateAggregateFormData({
      meta: { ...meta, bdsError: 'Secondary Contact is a required field.' },
    });
  }

  return tamEmail && bdsEmail;
};

export const step2Validations = (language) => {
  const isUSRegion = language === languages['en-US'];
  const USValidations = {
    npn: Yup.string()
      .label('NPN')
      .when('parentAgencyId', {
        is: (value) => !value,
        then: (fieldSchema) => fieldSchema.required(),
      }),
  };
  return {
    parentAgencyId: Yup.string(),
    level: Yup.string().required().label('Level'),
    segment: Yup.string().required().label('Segment'),
    nationalAccountContactName: Yup.string()
      .label('National Contact Name')
      .when('segment', {
        is: (value) => value === 'DIAMOND',
        then: Yup.string().required(),
      }),
    ...(isUSRegion && USValidations),
  };
};
