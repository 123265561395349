import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
// mui
import { Grid as MuiGrid, Fade, ClickAwayListener } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Numeral from 'numeral';

// components
import withRouter from '../../../components/hocs/withRouter';
// colors
import _config from '../../../themes/_base/_config';
import { colorMappings } from '../colorMappings';
import { setAccountDetails } from '../tabs.reducer';
import { toUniversalUtcDate } from '../../../utils/date.utils';

const styles = makeStyles(({ config, palette }) => {
  return {
    flexBox: {
      fontSize: config.textSizes.paragon,
      color: `${palette.primary.contrastText} !important`,
      fontWeight: config.weights.bold,
    },
    company: {
      fontSize: config.textSizes.paragon,
      color: `${palette.primary.contrastText} !important`,
    },
    heading: {
      background: palette.background.details,
      color: palette.primary.contrastText,
      padding: '0.416rem 2.5rem',
      borderRadius: '5px',
      textAlign: 'left',
      fontWeight: 'normal',
    },
    status: {
      paddingLeft: 5,
    },
    industry: {
      color: config.colors.greyWhite,
      fontWeight: config.weights.bold,
      fontSize: config.textSizes.primer,
      textAlign: 'right',
    },
    agent: {
      color: palette.primary.contrastText,
      fontWeight: config.weights.bold,
      fontSize: config.textSizes.primer,
      textAlign: 'center',
    },
    spacing: {
      padding: '0 2.5rem',
    },
    summary: {
      fontSize: config.textSizes.primer,
      color: palette.primary.contrastText,
      fontWeight: 'normal',
      letterSpacing: 'normal',
      lineHeight: 1.8,
      paddingLeft: '2.5rem',
      paddingRight: '0.6rem',
    },
    container: {
      borderRadius: '3px',
      maxHeight: '18.5rem',
      width: '26.5rem',
      border: `solid 1px #c9cdd9`,
      backgroundColor: palette.background.lighter,
      padding: '10px !important',
      lineHeight: 1.5,
      overflowY: 'auto',
      position: 'absolute !important',
      zIndex: 1,
      'box-shadow': '3px 3px 6px 0 rgba(0, 0, 0, 0.5)',
    },
    individualItem: {
      fontSize: config.textSizes.tertia,
      cursor: 'pointer',
      '&:hover': {
        background: palette.notification.hover,
      },
    },
    gridRight: {
      textAlign: 'right',
      fontWeight: 'normal',
    },
    subText: {
      fontSize: config.textSizes.normal,
      lineHeight: 1.33,
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
  };
});

export const MultiQuotesHeader = withRouter(
  ({ data, list, history, location }) => {
    const classes = styles();
    const dispatch = useDispatch();
    const pathPrefix = location.pathname.split('/')[1];
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
      setOpen(false);
    };

    const handleUrl = (selectedQuote) => {
      setOpen(false);

      dispatch(
        setAccountDetails({
          account: {
            policyId: selectedQuote.policyId ?? 'no-policy',
            quoteNumber: selectedQuote.quoteNumber,
          },
        })
      );
      if (pathPrefix === 'admin') {
        history.push(
          `/admin/quote/details/underwriter/${selectedQuote.id}/${selectedQuote.accountId}/${selectedQuote.product}/quotes`
        );
      }
      if (pathPrefix === 'agency') {
        history.push(
          `/agency/accounts/details/${selectedQuote.id}/${selectedQuote.accountId}/${selectedQuote.product}/quotes`
        );
      }
    };

    return (
      <>
        <MuiGrid container className={`flex--spaced ${classes.spacing}`}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <MuiGrid item md={5}>
              <div className={classes.flexBox}>
                {data.quoteNumber}
                <span
                  className={classes.status}
                  style={{ color: colorMappings[data.agencyStatus] }}
                >
                  [{data.agencyStatus}]
                </span>
                {list.length > 1 && (
                  <ExpandMoreOutlinedIcon
                    fontSize="small"
                    style={{ verticalAlign: 'middle' }}
                    onClick={handleClick}
                  />
                )}
                {list.length > 1 && open ? (
                  <Fade in={open}>
                    <div className={classes.container}>
                      {list.map((value, index) => {
                        return (
                          <MuiGrid
                            container
                            spacing={2}
                            className={classes.individualItem}
                            key={index}
                            onClick={() => handleUrl(value)}
                          >
                            <MuiGrid item container md={6} direction="column">
                              <MuiGrid item>{value.quoteNumber}</MuiGrid>
                              <MuiGrid item className={classes.subText}>
                                {value.agencyName}
                              </MuiGrid>
                            </MuiGrid>
                            <MuiGrid item md={2} style={{ textAlign: 'right' }}>
                              {_.isUndefined(value.aggLimit)
                                ? '-'
                                : Numeral(value.aggLimit).format('0a')}
                            </MuiGrid>
                            <MuiGrid item md={4} className={classes.gridRight}>
                              <MuiGrid
                                item
                                style={{
                                  color: colorMappings[value.agencyStatus],
                                }}
                              >
                                {value.agencyStatus}
                              </MuiGrid>
                              <MuiGrid
                                item
                                className={classes.subText}
                                style={{ fontSize: _config.textSizes.minion }}
                              >
                                Expiry: {toUniversalUtcDate(value.expiresOn)}
                              </MuiGrid>
                            </MuiGrid>
                          </MuiGrid>
                        );
                      })}
                    </div>
                  </Fade>
                ) : (
                  <div />
                )}
              </div>
            </MuiGrid>
          </ClickAwayListener>

          <MuiGrid item md={3} className={classes.agent}>
            Agency: {data.agencyName}
          </MuiGrid>
          <MuiGrid item md={4} className={classes.industry}>
            Industry{' '}
            {data?.naicsCode || data.firmographicData?.naicsCode || '-'}
          </MuiGrid>
        </MuiGrid>

        <MuiGrid container className={`flex--spaced ${classes.spacing}`}>
          <MuiGrid item md={5} className={classes.company}>{`${
            data.companyName
          } [${list.length} ${list.length > 1 ? 'Quotes' : 'Quote'}]`}</MuiGrid>

          <MuiGrid item md={3} className={classes.agent}>
            Agent: {`${data.agentFirstName} ${data.agentLastName}`}
          </MuiGrid>

          <MuiGrid item md={4} className={classes.industry}>
            {data.primaryIndustry || data.firmographicData?.primaryIndustry}
          </MuiGrid>
        </MuiGrid>
      </>
    );
  }
);

export default MultiQuotesHeader;
