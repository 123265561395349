import { useFeature } from '@growthbook/growthbook-react';
import type { AxiosResponse } from 'axios';
import React from 'react';
import { getAccessToken } from '../../api/auth/auth.api';
import {
  useAmplitude,
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES,
} from '../../providers/AmplitudeProvider';
import {
  cacheAuthTokens,
  isDeviceRemembered,
  retrieveAuthTokens,
} from '../../utils/next/auth.utils';
import { FEATURE_FLAG_KEYS } from '../../config/growthbook';

interface UseRememberDeviceParams {
  onSuccess?: () => void;
  onFailure?: () => void;
}

export const useRememberDevice = ({
  onSuccess,
  onFailure,
}: UseRememberDeviceParams) => {
  const amplitude = useAmplitude();
  const rememberDeviceFeature = useRememberDeviceFeature();

  const isRemembered = isDeviceRemembered();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (!isRemembered || rememberDeviceFeature.off) {
      setIsLoading(false);
    } else {
      const { refreshToken } = retrieveAuthTokens();
      getAccessToken({ data: { refreshToken } })
        .then((resp: AxiosResponse) => {
          cacheAuthTokens(resp.data.accessToken, String(refreshToken));
          amplitude.track(AMPLITUDE_EVENTS.login, {
            status: AMPLITUDE_PROPERTIES.login.status.success,
            reason: AMPLITUDE_PROPERTIES.login.reason.rememberDevice,
          });
          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        })
        .catch(() => {
          amplitude.track(AMPLITUDE_EVENTS.login, {
            status: AMPLITUDE_PROPERTIES.login.status.failure,
            reason: AMPLITUDE_PROPERTIES.login.reason.rememberDeviceExpired,
          });
          if (typeof onFailure === 'function') {
            onFailure();
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  return { isRemembered, isLoading };
};

export const useRememberDeviceFeature = () =>
  useFeature(FEATURE_FLAG_KEYS.REMEMBER_DEVICE);
