import Moment from 'moment';
import { trimAndAddAsterisk, trim } from '../utility/QueryUtility';

export const agencyServiceMappings = [
  {
    path: 'from',
    paramKey: 'after',
    transform: ({ from, to }) => {
      if (!from) {
        return null;
      }

      return from === to
        ? Moment.unix(from).startOf('day').toISOString()
        : Moment.unix(from).toISOString();
    },
  },
  {
    path: 'to',
    paramKey: 'before',
    transform: ({ to, from }) => {
      if (!to) {
        return null;
      }

      return from === to
        ? Moment.unix(to).endOf('day').toISOString()
        : Moment.unix(to).toISOString();
    },
  },
  { path: 'page' },
  { path: 'size' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'agent':
          if (orderBy && order) {
            return `agentFirstName.keyword,${order}`;
          }
          break;
        case 'npn':
        case 'dunsNumber':
        case 'commission':
        case 'additionalBrokerFee':
        case 'hardLinkOnQuote':
        case 'isWholesale':
        case 'modified':
        case 'isAppointed':
        case 'agencyBilled':
        case 'enforceMFA':
        case 'paymentTerms':
        case 'created':
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          break;
        case 'timestamp':
          if (order) {
            return `created,${order}`;
          }
          break;
        default:
          if (orderBy && order) {
            return `${orderBy}.keyword,${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search, searchValue }) => {
      if (searchValue) {
        return searchValue;
      }
      return search ? trimAndAddAsterisk(search) : null;
    },
  },
  { path: 'fav' },

  // Service Mappings for search fields
  {
    path: 'name',
  },
  {
    path: 'description',
  },
  {
    path: 'hostname',
  },
  { path: 'naicsCode' },
  { path: 'state' },
  {
    path: 'teamIds',
    transform: ({ teamIds }) => {
      return teamIds ? trim(teamIds) : null;
    },
  },

  { path: 'country' },
  { path: 'agencyId' },
];
