import _ from 'lodash';
import * as Yup from 'yup';
import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
import { DialogActions, DialogContent, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

// validations
import '../../console/_global/lib/validations/PhoneSchemaType';
// components
import { useQuery } from '@tanstack/react-query';
import { CowbellCompliance } from '../surplusCompliance/CowbellCompliance';
import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { withFormController } from '../../components/hocs/withFormController';
import CbButton from '../../components/Buttons/CbButton';
import { PhoneField as PhoneFieldBase } from '../../components/inputs/PhoneField';
import { SurplusAgencyBindQuote } from './SurplusAgencyBindQuote';
// platform helpers
import { getShortDateTimeFromUtc } from '../../utils/csvUtils';
import { delayedEvent } from '../../utils/eventUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../reducers/UiSettingsReducers';
import { setAgencyBindInfo, setRoute } from '../quote.reducer';
// actions
import { agentBindQuote } from '../../api/bind-quote.api';
import { completeQuoteSubjectivity, getQuoteProgress } from '../../api';
import { subjectivityKeys } from '../../console/workflows/bind-quote/constants/constants';
import { sleep } from '../../utils/appUtils';
import { approveQuote } from '../QuotesService';
import { manageAPIError } from '../../utils';

const complianceStates = ['LA', 'MT'];

const styles = ({ palette, config }) => {
  return {
    greenText: {
      color: palette.text.text4,
    },
    boldText: {
      fontWeight: 600,
    },
    whiteText: {
      color: palette.primary.contrastText,
    },
    pagination: {
      fontSize: config.textSizes.tertia,
      color: palette.primary.contrastText,
      fontWeight: 'normal',
      paddingLeft: '3rem',
    },
  };
};
const schema = Yup.object().shape({
  customerFirstName: Yup.string()
    .label("Policy Holder's First Name")
    .required(),
  customerLastName: Yup.string().label("Policy Holder's Last Name ").required(),
  customerEmail: Yup.string().label("Policy Holder's Email").email().required(),
  customerPhone: Yup.number()
    .label("Policy Holder's Phone")
    .fromPhone()
    .phone()
    .required(),
});

const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);

export const AgencyBindQuote = withStyles(styles)(
  ({ data, dispatch, setHandleCancel, product, ...props }) => {
    const surplusCowbellInsurance = _.get(
      data,
      'initial250RequestData.surplusCowbellInsurance',
      false
    );
    const policyEffectiveDate = getShortDateTimeFromUtc(
      Moment(data.effectiveDate).utc()
    );

    const { enqueueSnackbar } = useSnackbar();

    const bindDetails = useRef({
      customerFirstName: data.customerFirstName,
      customerLastName: data.customerLastName,
      customerEmail: data.customerEmail,
      customerPhone: data.customerPhone,
    });

    const { handleSubmit, setValue, ...methods } = useForm({
      validationSchema: schema,
    });

    const [page, setPage] = useState(1);
    const { isSubmitting } = methods.formState;
    const { classes } = props;
    const isCompliance = data.isSurplus
      ? complianceStates.includes(data.surplus.state) &&
        !data.surplus.signatureOnFile
      : false; // checking whether is LA/MT and signature does not exist

    useEffect(() => {
      const {
        customerFirstName,
        customerLastName,
        customerEmail,
        customerPhone,
      } = data;
      dispatch(
        setAgencyBindInfo({
          customer: {
            customerFirstName,
            customerLastName,
            customerEmail,
            customerPhone,
          },
        })
      );
      dispatch(setRoute({ from: 'bind' }));
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const {
        customerFirstName,
        customerLastName,
        customerEmail,
        customerPhone,
      } = bindDetails.current;
      setValue('customerFirstName', customerFirstName);
      setValue('customerLastName', customerLastName);
      setValue('customerPhone', customerPhone);
      setValue('customerEmail', customerEmail);
      // eslint-disable-next-line
    }, [page]);

    const handleCancel = () => {
      if (typeof setHandleCancel === 'function') {
        setHandleCancel();
      }
    };

    const {
      data: quoteSubjectivities = {},
      isInitialLoading: isLoadingSubjectivities,
    } = useGetSubjectivities({ quoteId: data?.id });

    const handleApproveQuote = async (formValues) => {
      if (quoteSubjectivities.shouldCompleteSubjectivity) {
        try {
          await completeQuoteSubjectivity(
            product,
            quoteSubjectivities.policyHolderSubjectivity[0].quoteSubjectivityId,
            {
              data: formValues,
            }
          );
        } catch (error) {
          enqueueSnackbar(
            'Unable to bind quote at this time. Please try again later.',
            { variant: 'error' }
          );
          return;
        }
      }

      await sleep(1500);

      return agentBindQuote(data?.id)
        .then(() => {
          handleCancel();
          enqueueSnackbar('Quote Bound Successfully!', { variant: 'success' });
          delayedEvent('table-refetch', 500, AGENCY_QUOTES_LISTING_STORE_ID);
          delayedEvent('quote:read');
        })
        .catch((error) => {
          const message =
            error.response.status == 400
              ? 'Renewal quote(s) are still patching, please try approving again in a minute.'
              : manageAPIError(
                  error,
                  'Not able to bind at this time. Please try again later.'
                );

          enqueueSnackbar(message, { variant: 'error' });
        });
    };

    const onSubmit = (formValues) => {
      dispatch(setAgencyBindInfo({ customer: formValues }));
      dispatch(setRoute({ from: 'bind' }));

      if (data.isSurplus) {
        setPage(2);
        bindDetails.current = formValues;
      } else {
        return handleApproveQuote(formValues);
      }
    };

    return (
      <>
        {page === 1 && (
          <FormContext {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent style={{ padding: '2rem 5rem 0 5rem' }}>
                <div className="modal-title">
                  Are you sure you want to
                  <span className={`${classes.greenText} ${classes.boldText}`}>
                    {' '}
                    Bind Quote
                  </span>
                  <span className={`modal-blue-text ${classes.boldText}`}>
                    {' '}
                    {data.quoteNumber}
                  </span>
                  <p style={{ margin: 0 }}>
                    for{' '}
                    <span className={`modal-blue-text ${classes.boldText}`}>
                      {data.companyName}
                    </span>{' '}
                    with policy effective date{' '}
                    <span
                      className={`${classes.greenText} ${classes.boldText}`}
                    >
                      {' '}
                      {policyEffectiveDate}
                    </span>
                    ?
                  </p>
                </div>

                <p>
                  <h3 className={classes.whiteText}>
                    Please confirm below policy holder details
                  </h3>
                </p>

                <Grid container spacing={4}>
                  <Grid item sm={6} style={{ paddingBottom: 0 }}>
                    <TextField
                      name="customerFirstName"
                      label="Policy Holder First Name"
                      placeholder="Please enter policy holder first name"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} style={{ paddingBottom: 0 }}>
                    <TextField
                      name="customerLastName"
                      label="Policy Holder Last Name"
                      placeholder="Please enter policy holder last name"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} style={{ paddingTop: 0 }}>
                    <TextField
                      name="customerEmail"
                      label="Policy Holder Email"
                      type="email"
                      placeholder="Please enter policy holder email"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} style={{ paddingTop: 0 }}>
                    <PhoneField
                      name="customerPhone"
                      label="Policy Holder Phone"
                      required
                      fullWidth
                      placeholder="(___)___-____"
                    />
                  </Grid>
                </Grid>
                <p className={classes.whiteText}>
                  <i>
                    Note: All other quotes for this customer will be
                    invalidated.
                  </i>
                  <br />
                  {surplusCowbellInsurance && data.isSurplus && (
                    <i className={classes.whiteText}>
                      Surplus Compliance: Cowbell Insurance Agency.
                    </i>
                  )}
                </p>
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  justifyContent={
                    !data.isSurplus ? 'flex-end' : 'space-between'
                  }
                >
                  {data.isSurplus && (
                    <Grid className={classes.pagination}>
                      Step 1 of {isCompliance ? 3 : 2}: Policyholder Details
                    </Grid>
                  )}
                  <Grid>
                    <CbButton
                      styleName="cancel"
                      onClick={handleCancel}
                      buttonText="Cancel"
                    />
                    <CbButton
                      styleName="ctaButton"
                      type="submit"
                      disabled={isSubmitting || isLoadingSubjectivities}
                      loading={isSubmitting || isLoadingSubjectivities}
                      buttonText={data.isSurplus ? 'Next' : 'Bind Quote'}
                    />
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          </FormContext>
        )}
        {page === 2 && surplusCowbellInsurance && (
          <CowbellCompliance
            handleSubmit={handleApproveQuote}
            setPage={setPage}
            isSubmitting={isSubmitting}
          />
        )}
        {page === 2 && !surplusCowbellInsurance && (
          <SurplusAgencyBindQuote
            data={data}
            setCustomerPage={setPage}
            setHandleCancel={handleCancel}
          />
        )}
      </>
    );
  }
);

export default AgencyBindQuote;

const useGetSubjectivities = ({ quoteId }) => {
  return useQuery(
    ['quote-subjectivities', quoteId],
    () => {
      return getQuoteProgress(quoteId).then(({ data: responseData }) => {
        const { preBindSubjectivities = [] } = responseData;
        const policyHolderSubjectivity = preBindSubjectivities.filter(
          (subjectivity) =>
            subjectivity.subjectivityKey ===
            subjectivityKeys.POLICY_HOLDER_CONTACT
        );
        return {
          policyHolderSubjectivity,
          shouldCompleteSubjectivity: Boolean(policyHolderSubjectivity?.length),
          responseData,
        };
      });
    },
    { enabled: !!quoteId }
  );
};
