import { Route, Switch } from 'react-router-dom';

// components
import { PolicyNavigation } from './PolicyNavigation';
import AgencyPolicies from './AgencyPolicies';
import AgencyBORPolicies from '../console/BOR/agency/AgencyBORPolicies';
import { AgencyEndorsementsListing } from '../console/policies/listing/agency/AgencyEndorsementsListing';
import { usePersona } from '../components/hooks/usePersona';

const rqps = ['size', 'orderBy', 'order', 'before', 'after'];

export const AgencyPolicyTab = () => {
  const persona = usePersona();

  return (
    <section>
      <PolicyNavigation />
      <Switch>
        <Route
          path={`${persona.basePath}/policies/bor`}
          exact
          children={(props) => <AgencyBORPolicies rqps={rqps} {...props} />}
        />

        <Route
          path={`${persona.basePath}/policies/endorsements`}
          exact
          children={(props) => (
            <AgencyEndorsementsListing rqps={rqps} {...props} />
          )}
        />

        <Route
          path={`${persona.basePath}/policies`}
          children={(props) => <AgencyPolicies rqps={rqps} {...props} />}
        />
      </Switch>
    </section>
  );
};

export default AgencyPolicyTab;

export const AGENCY_POLICY_TABS_VIEW_PATH = '/agency/policies';
