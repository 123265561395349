import React from 'react';

export interface IWithShowableProps {
  show?: boolean;
}
export const withShowable = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return ({ show = true, ...props }) => {
    if (!show) {
      return null;
    }
    return <Component {...(props as P)} />;
  };
};
