// mui
import { Box, IconButton, Typography, styled } from '@mui/material';

// components
import MuiCloseIcon from '@mui/icons-material/HighlightOff';

// utils
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import type { AgencyDashboardOnboardingScreenTitleKeys } from '../agency-dashboard.statics';
import {
  AGENCY_DASHBOARD_VIEWS,
  AGENCY_DASHBOARD_ONBOARDING_SCREEN_TITLES,
} from '../agency-dashboard.statics';

export const OnboardingScreenHeader = () => {
  const agencyDashboardState = useAgencyDashboardState();
  const title =
    AGENCY_DASHBOARD_ONBOARDING_SCREEN_TITLES[
      agencyDashboardState.view as AgencyDashboardOnboardingScreenTitleKeys
    ];
  const onHideOverlay = () =>
    agencyDashboardState.handleViewChange(AGENCY_DASHBOARD_VIEWS.LISTING);

  return (
    <HeaderContainer
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="5rem"
    >
      <div />
      <HeaderTitle>{title}</HeaderTitle>
      <IconButton size="small" onClick={onHideOverlay}>
        <CloseIcon />
      </IconButton>
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Box)(({ theme }: { theme: any }) => ({
  backgroundColor: theme.agencyDash.background.header,
  position: 'sticky',
  top: 0,
  zIndex: 1000,
}));

const HeaderTitle = styled(Typography)(({ theme }: { theme: any }) => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  color: theme.agencyDash.background.paper,
}));

const CloseIcon = styled(MuiCloseIcon)(({ theme }: { theme: any }) => ({
  fontSize: '2.4rem',
  color: theme.agencyDash.background.paper,
  '&:hover': { color: theme.agencyDash.background.paper },
  position: 'absolute',
  marginRight: '4rem',
}));
