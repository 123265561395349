import * as Yup from 'yup';

Yup.addMethod(
  Yup.string,
  'domain',
  function pattern(name, message = 'Not a valid domain name') {
    const regex = new RegExp(
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/
    );

    return this.test({
      message,
      test: (value) =>
        value === null ||
        value === '' ||
        value === undefined ||
        regex.test(value),
    });
  }
);
