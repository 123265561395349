import React from 'react';
import type { Languages } from '../i18n.language-config';

export const FormLanguageContext = React.createContext<{
  language: Languages | null;
}>({
  language: null,
});

interface FormLanguageProviderProps {
  children: React.ReactNode;
  language: Languages;
}

export const FormLanguageProvider = ({
  children,
  language,
}: FormLanguageProviderProps) => {
  return (
    <FormLanguageContext.Provider value={{ language: language as Languages }}>
      {children}
    </FormLanguageContext.Provider>
  );
};
