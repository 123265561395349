import React from 'react';
import Moment from 'moment';

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { Box, DialogActions, DialogContent, styled } from '@mui/material';

import CbButton from '../../Buttons/CbButton';
import { InputLabelBase } from '../../inputs/InputLabelBase';
import { updatePolicyEffEndDate } from '../../../policies/PolicyService';
import { delayedEvent } from '../../../utils/eventUtils';
import { COWBELL_POLICY } from '../../tables/table_constants';

const oneMonthFromNow = Moment.utc(new Date())
  .add(60, 'days')
  .format('YYYY-MM-DD');

const oneMonthToNow = Moment.utc(new Date())
  .subtract(1, 'months')
  .format('YYYY-MM-DD');

export const UpdateActivePolicyDates = ({ data, ...props }) => {
  const queryClient = useQueryClient();

  const [effectiveDate, setEffectiveDate] = React.useState(
    Moment.utc(data.effectiveDate).format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState(
    Moment.utc(data.endDate).format('YYYY-MM-DD')
  );

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    setEndDate(Moment.utc(effectiveDate).add(1, 'y').format('YYYY-MM-DD'));
  }, [effectiveDate]);

  const onSubmit = () => {
    updatePolicyEffEndDate({ policyId: data.id }, { effectiveDate, endDate })
      .then(() => {
        delayedEvent('table-refetch', 0, 'adminPolicies');
        enqueueSnackbar('Update Successful!', { variant: 'success' });
        props.close();

        queryClient.invalidateQueries([COWBELL_POLICY]);
      })
      .catch((err) => {
        enqueueSnackbar('Update failed', { variant: 'error' });
      });
  };

  return (
    <>
      <DialogContent>
        <Box
          display="flex"
          width="26.5rem"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <InputLabel>Effective Date</InputLabel>
            <DateInput
              onKeyDown={(e) => e.preventDefault()}
              type="date"
              value={effectiveDate}
              onChange={(e) => setEffectiveDate(e.target.value)}
              min={oneMonthToNow}
              max={oneMonthFromNow}
            />
          </Box>
          <Box>
            <InputLabel>End Date</InputLabel>
            <DateInput
              onKeyDown={(e) => e.preventDefault()}
              type="date"
              name="trip-start"
              value={endDate}
              disabled
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <CbButton onClick={props.close} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton type="submit" onClick={onSubmit} styleName="ctaButton">
          Update
        </CbButton>
      </DialogActions>
    </>
  );
};

const DateInput = styled('input')(({ theme }) => ({
  backgroundColor: theme.palette.background.fileUploader,
  color: theme.palette.primary.main,
  border: '1px solid white',
  fontSize: theme.config.textSizes.tertia,
  borderRadius: '0.25rem',
  padding: '0.25rem 0.5rem',
  '&::-webkit-datetime-edit-year-field': {
    color: theme.palette.primary.main,
  },
  '&::-webkit-calendar-picker-indicator': {
    filter: 'invert(1)',
  },
  '&:disabled': {
    backgroundColor: theme.palette.background.autocompleteOptionFocus,
  },
}));

const InputLabel = styled(InputLabelBase)({
  padding: 0,
  paddingBottom: '0.25rem',
});

export const UpdateActivePolicyDatesConfig = {
  UpdateActivePolicyDates: {
    component: UpdateActivePolicyDates,
    config: {
      fullWidth: false,
      maxWidth: 'md',
      inheritTheme: 'dark',
      title: 'Update Policy Eff./End Date',
    },
  },
};
