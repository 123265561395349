import _ from 'lodash';
import { UsStatesFull } from './USState';

export function zipReference(
  array = [],
  labelKey = 'name',
  valueKey = 'id',
  additionalFields = []
) {
  return array.reduce(
    (acc, item) => ({
      ...acc,
      [item[valueKey]]: _.pick(item, [labelKey, ...additionalFields]),
    }),
    {}
  );
}

export const getStateObject = (state) => {
  return UsStatesFull.find((item) => item.value === state);
};
