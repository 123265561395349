import React from 'react';
import { FormContext, useForm, useFieldArray } from 'react-hook-form-4';
import * as Yup from 'yup';
import Moment from 'moment';

// lodash
import _ from 'lodash';

import { DialogContent, Grid, IconButton, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// components
import CBButton from '../../../../../components/Buttons/CbButton';
import { TextFieldBase } from '../../../../../components/inputs/TextFieldBase';
import { TextAreaBase } from '../../../../../components/inputs/TextAreaBase';

// misc
import { withFormController } from '../../../../../components/hocs/withFormController';
import { setPrime250Ui } from '../../../../_reducers/prime250.reducer';
import { toggleModalDirect } from '../../../../../utils/storeUtils';
import { withShowable } from '../../../../_global/lib/withShowable';

const TextArea = withFormController(TextAreaBase);

const TextField = withFormController(TextFieldBase);

const GridShow = withShowable(Grid);

const schema = Yup.object().shape({
  eventFields: Yup.array().of(
    Yup.object().shape({
      claimNumber: Yup.string().label('Claim Number').required(),
      claimName: Yup.string().label('Claim Name').required(),
      claimDate: Yup.string()
        .label('Claim Date')
        .test('claimDate', 'Invalid date format', (input) => {
          if (!input) return false;
          const date = Moment(input);
          return date.isValid();
        }),
    })
  ),
});

// ui
export const UWEventExclusionModal = ({ data }) => {
  const classes = useClasses();
  const { specifiedEventExclusionList, dispatch } = data;

  const { handleSubmit, ...methods } = useForm({
    validationSchema: schema,
  });

  const {
    control,
    errors,
    formState: { isSubmitting },
  } = methods;

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'eventFields',
  });

  React.useEffect(
    () => append(specifiedEventExclusionList),
    // eslint-disable-next-line
    [specifiedEventExclusionList]
  );

  const emptyEvent = {
    claimNumber: '',
    claimName: '',
    claimDescription: '',
    claimDate: '',
  };

  const handleRemove = (idx) => {
    return () => remove(idx);
  };

  const handleAppend = () => {
    append({});
  };

  const onSubmit = (formData) => {
    dispatch(
      setPrime250Ui({
        specifiedEventExclusionList: _.isEmpty(formData.eventFields)
          ? [emptyEvent]
          : formData.eventFields,
        shouldRecalculate: Moment().unix(),
      })
    );
    toggleModalDirect('UWEventExclusionModal', false);
  };

  const handleCancel = () => {
    toggleModalDirect('UWEventExclusionModal', false);
  };

  if (!specifiedEventExclusionList) {
    return null;
  }

  return (
    <section>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent classes={{ root: classes.container }}>
            <section className={classes.eventsContainer}>
              {fields.map((field, idx) => {
                return (
                  <SEEvent
                    field={field}
                    idx={idx}
                    classes={classes}
                    errors={errors}
                    handleRemove={handleRemove}
                    control={control}
                  />
                );
              })}
            </section>
            <GridShow
              className={classes.emptyListMessage}
              show={_.isEmpty(fields)}
            >
              There are no SE events to display.
            </GridShow>
            <Grid container>
              <Grid item md={12} className="flex align-right">
                {/* eslint-disable-next-line */}
                <div className={classes.plusSign} onClick={handleAppend}>
                  +
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Save Changes"
            />
          </DialogActions>
        </form>
      </FormContext>
    </section>
  );
};

const SEEvent = ({ field, idx, classes, errors, handleRemove, control }) => {
  return (
    <section key={field.id} className={classes.eventContainer}>
      <Grid container>
        <Grid item md={12} className="flex align-right">
          <IconButton
            className={classes.deleteIcon}
            onClick={handleRemove(idx)}
            size="small"
          >
            <HighlightOffIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} className="flex align-right">
          <TextField
            name={`eventFields[${idx}].claimNumber`}
            label="Incident Number / Claim Number"
            error={`${_.get(
              errors,
              ['eventFields', idx, 'claimNumber', 'message'],
              ''
            )}`}
            defaultValue={field.claimNumber}
            required
            fullWidth
          />
        </Grid>
        <Grid item md={5} className="flex align-right">
          <TextField
            name={`eventFields[${idx}].claimName`}
            label="Incident Claim / Claim Name / Claimant"
            error={`${_.get(
              errors,
              ['eventFields', idx, 'claimName', 'message'],
              ''
            )}`}
            defaultValue={field.claimName}
            required
            fullWidth
          />
        </Grid>
        <Grid item md={3} className="flex align-right">
          <TextField
            name={`eventFields[${idx}].claimDate`}
            label="Date"
            error={`${_.get(
              errors,
              ['eventFields', idx, 'claimDate', 'message'],
              ''
            )}`}
            defaultValue={
              field.claimDate !== ''
                ? Moment.utc(field.claimDate).format('YYYY-MM-DD')
                : ''
            }
            type="date"
            fullWidth
            placeholder="MM/DD/YYYY"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.eventContainerTopRow}>
        <TextArea
          className={classes.textAreaRoot}
          maxRows={6}
          minRows={10}
          label="Incident / Claim Description (internal use only)"
          key={`eventFields[${idx}].claimDescription`}
          name={`eventFields[${idx}].claimDescription`}
          defaultValue={field.claimDescription}
          fullWidth
          multiline
          control={control}
        />
      </Grid>
    </section>
  );
};

const useClasses = makeStyles(({ palette, config }) => ({
  deleteIcon: {
    margin: '0 0 -2rem 0',
    zIndex: 10,
    color: palette.error.main,

    '& svg:hover': {
      color: palette.error.main,
    },
  },
  container: {
    maxHeight: '45rem',
    overflow: 'auto',
    paddingBottom: '1rem',
  },
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-end',
    borderBottom: `1px solid ${palette.primary.modalBorder}`,
    padding: '1rem 0 2rem 0',
    '&:first:child': {
      padding: '0 0 2rem 0',
    },
  },
  textAreaRoot: {
    width: '100%',
    borderRadius: 5,
    fontSize: '1rem',
    lineHeight: '1.33',
    color: palette.primary.main,
    height: '9.5rem',
    border: `0.8px solid ${palette.text.secondary}`,
    background: palette.background.darkerBlue,
  },
  plusSign: {
    fontSize: '2.5rem',
    fontWeight: 600,
    lineHeight: 1.43,
    color: palette.text.secondary,
    cursor: 'pointer',
  },
  emptyListMessage: {
    fontSize: config.textSizes.primer,
    color: config.colors.white2,
    textAlign: 'center',
  },
}));
