import * as Ramda from 'ramda';
import type { AccountDto, ProductType } from '../../types';
import { PrimeXProductTypes, ProductTypes } from '../../types';
import type { FirmographicDataDto } from '../../types/firmo/firmographic-data.dto';
import { COUNTRY_MAPPING, languages } from '../i18n.language-config';
import type { Countries, Languages } from '../i18n.language-config';
import { getCowbellTranslations } from '../translations';

interface FormatUKAddressParams {
  address1: string | undefined;
  address2: string | undefined;
  address3: string | undefined;
  city: string | undefined;
  zipCode: string | undefined;
}

export function FormatUKAddress({
  address1 = '',
  address2 = '',
  address3 = '',
  city = '',
  zipCode = '',
  ...props
}: FormatUKAddressParams) {
  const addressLines = [address1, address2, address3].filter(Boolean);

  const cityAndPostcodeParts = [city, zipCode].filter(Boolean);

  return (
    <div style={{ textAlign: 'right' }} {...props}>
      {addressLines.length > 0 &&
        addressLines.map((line, index) => (
          <div key={`address-${line}-${index}`}>{line}</div>
        ))}
      <div>
        {cityAndPostcodeParts.length &&
          cityAndPostcodeParts.join(', ').toUpperCase()}
      </div>
    </div>
  );
}

interface FormatUSAddressParams {
  address1: string | undefined;
  address2: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zipCode: string | undefined;
}

function FormatUSAddress({
  address1 = '',
  address2 = '',
  city = '',
  state = '',
  zipCode = '',
  ...props
}: FormatUSAddressParams) {
  const addressLines = [address1, address2].filter(Boolean);

  return (
    <div style={{ textAlign: 'right' }} {...props}>
      {addressLines.length > 0 &&
        addressLines.map((line, index) => (
          <div key={`address-${line}-${index}`}>{line}</div>
        ))}
      <div>
        {city}, {state} {zipCode}
      </div>
    </div>
  );
}

export function formatAddressByRegion(
  region: Languages,
  addressOptions: FormatUKAddressParams & FormatUSAddressParams
) {
  switch (region) {
    case languages['en-GB']: {
      return FormatUKAddress(addressOptions);
    }

    default:
      return FormatUSAddress(addressOptions);
  }
}

export const UkAddressFromApi = {
  address1: 'Suite 123',
  address2: 'King Charles III House',
  address3: '1001 Cowbell Avenue',
  city: 'GLASGOW',
  zipCode: 'EH7 4RT',
};

export const getRegionByProduct = (productType: ProductType) => {
  if (productType === ProductTypes.prime_one) {
    return languages['en-GB'];
  }

  return languages['en-US'];
};

export const UK_CURRENCY_SYMBOL = '£';
export const US_CURRENCY_SYMBOL = '$';

export const getCurrencySymbolByProduct = (productType: ProductType) => {
  if (productType === ProductTypes.prime_one) {
    return UK_CURRENCY_SYMBOL;
  }

  return US_CURRENCY_SYMBOL;
};

export const getCurrencySymbolByLanguage = (language: Languages) => {
  if (language === languages['en-GB']) {
    return UK_CURRENCY_SYMBOL;
  }

  return US_CURRENCY_SYMBOL;
};

export const getLanguageFromAccount = (
  account: AccountDto | FirmographicDataDto
) => {
  if (!account.country) {
    return languages['en-US'];
  }

  return COUNTRY_MAPPING[account.country as Countries];
};

export const getDeductibleLanguage = (product: ProductType) => {
  const isPrimeXProduct = Object.values(PrimeXProductTypes).some(
    Ramda.equals(product)
  );
  const { t, translationKeys } = getCowbellTranslations();
  if (!isPrimeXProduct) {
    return 'Deductible';
  }

  return t(translationKeys.retention);
};
