import type { UIRevenueBand } from '../../../../../../api';
import DescTooltip from '../../../../../../components/DescTooltip';
import Showable from '../../../../../../components/Showable';
import { moneyFormat } from '../../../../../../utils/appUtils';

interface RevenueRangeCellProps {
  revenueBand: UIRevenueBand | undefined;
}

export const RevenueRangeCell = ({ revenueBand }: RevenueRangeCellProps) => {
  if (!revenueBand) {
    return <>-</>;
  }

  const tooltip = (
    <>
      <div>Primary Product: {revenueBand.primaryProductType}</div>

      <Showable show={Boolean(revenueBand.secondaryProductType)}>
        <div>Secondary Product: {revenueBand.secondaryProductType}</div>
      </Showable>
    </>
  );
  return (
    <>
      {revenueBand.minRevenue} - {revenueBand.maxRevenue}
      <DescTooltip tooltip={tooltip} />
    </>
  );
};
