import { delayedEvent, PubSub } from '../utils/eventUtils';
import WebWorker from './workerSetup';
import silentWorker from './silentWorker';
import { retrieveAuthTokens } from '../utils/next/auth.utils';

const INTERVAL_SECONDS = 30;

const NetworkLayer = {
  init() {
    const worker = new WebWorker(silentWorker);
    const tokenInterval = setInterval(() => {
      const { accessToken } = retrieveAuthTokens();

      if (!accessToken) {
        worker.terminate();
        clearInterval(tokenInterval);
        return;
      }

      worker.postMessage({ accessToken });
    }, INTERVAL_SECONDS * 1000);

    worker.onmessage = (event) => {
      const { badge, data, stats } = event.data;

      if (badge && badge !== null && badge.length > 0) {
        badge.map((value) => {
          if (value === 'QUOTE') {
            delayedEvent('quote:read');
          } else if (value === 'POLICY') {
            delayedEvent('policy:read');
          } else if (value === 'VISIBLENOTIFICATION') {
            delayedEvent('visible:read');
            PubSub.publish('visible:auto');
          }
          return value;
        });
      }

      if (stats && stats !== null && stats.length > 0) {
        // console.log('in stats');
        stats.map((value) => {
          if (value === 'QUOTE') {
            PubSub.publish('inbox:stats', '');
          } else if (value === 'POLICY') {
            PubSub.publish('policy:stats');
          } else if (value === 'ACCOUNT') {
            PubSub.publish('account:stats');
          } else if (value === 'COMMISSION') {
            PubSub.publish('commission:stats');
          }
          return value;
        });
      }
      if (data && data !== null && data.length > 0) {
        data.map((value) => {
          // console.log(value);
          if (value === 'QUOTE') {
            PubSub.publish('quote-refresh:show', true);
          } else if (value === 'POLICY') {
            PubSub.publish('policy-refresh:show', true);
          } else if (value === 'ACCOUNT') {
            PubSub.publish('account-refresh:show', true);
          }
          return value;
        });
      }
    };
  },
};

export default NetworkLayer;
