import { ErrorMessage } from '@hookform/error-message';
import { styled } from '@mui/material';
import { useEffect } from 'react';
import { Uploadzone } from '../../../../fileUploader/v2/UploadZone';

export const FormUpload = ({ uploadZoneParams, element, files, formProps }) => {
  const { name, required } = element;
  const { register, unregister, setValue, errors } = formProps;

  useEffect(() => {
    setValue(name, files, { shouldValidate: required });
    return () => {
      setValue(null);
    };
  }, [files, name, required, setValue]);

  useEffect(() => {
    register(name, { required: required && 'File upload is required' });
    return () => {
      unregister(name);
    };
  }, [register, name, unregister, required]);

  return (
    <>
      <Uploadzone
        {...uploadZoneParams}
        files={files}
        {...element.elementProps}
      />
      <ErrorMessage
        errors={errors}
        name={element.name}
        render={({ message }) => <ErrorText>{message}</ErrorText>}
      />
    </>
  );
};

export default FormUpload;

const ErrorText = styled('span')(({ theme }) => ({
  color: theme.palette.text.text9,
  display: 'inherit',
  textAlign: 'center',
}));
