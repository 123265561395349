export const ACCOUNT_CMAIL = 'ACCOUNT_CMAIL';
export const ACCOUNT_SPOTLIGHTS = 'ACCOUNT_SPOTLIGHTS';
export const AGENCY_ACCOUNTS = 'AGENCY_ACCOUNTS';
export const AGENCY_ENDORSEMENTS = 'AGENCY_ENDORSEMENTS';
export const COWBELL_ACCOUNTS = 'COWBELL_ACCOUNTS';
export const COWBELL_AGENCY_PROGRAMS = 'COWBELL_AGENCY_PROGRAMS';
export const COWBELL_BINDERS = 'COWBELL_BINDERS';
export const COWBELL_ENDORSEMENTS = 'COWBELL_ENDORSEMENTS';
export const COWBELL_POLICY = 'COWBELL_POLICY';
export const COWBELL_QUOTES = 'COWBELL_QUOTES';
export const COWBELL_RISK_ENG = 'COWBELL_RISK_ENG';
export const COWBELL_SPOTLIGHTS = 'COWBELL_SPOTLIGHTS';
