import React from 'react';
import {
  DialogActions,
  DialogContent,
  Grid as MuiGrid,
  Box,
  FormHelperText,
} from '@mui/material';
import { FormContext, useForm } from 'react-hook-form-4';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import CbButton from '../../Buttons/CbButton';
import { Checkbox as CheckboxBase } from '../../inputs';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { withFormController } from '../../hocs/withFormController';
import { delayedEvent } from '../../../utils/eventUtils';
import {
  updateAgencyTeam,
  createAgencyTeam,
} from '../../../console/agencies/_services/teams.service';
import { manageAPIError } from '../../../utils';

const TextField = withFormController(TextFieldBase);
const Checkbox = withFormController(CheckboxBase);

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .label('Team Name')
    .matches(/^[a-zA-Z0-9_ ]*$/, 'Please enter only Alphabets, Numbers and _')
    .test(
      'len',
      // eslint-disable-next-line
      '${label} must be between 3 and 255 characters',
      (team) => team.length <= 255 && team.length >= 2
    )
    .required(),
});

export const TeamCreateEditModal = ({ close, data = {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = React.useState('');
  const {
    watch,
    handleSubmit,
    formState: { isSubmitting, dirty },
    ...methods
  } = useForm({
    defaultValues: {
      name: data.name || '',
      isDefault: data.isDefault || false,
      location: data.location !== false,
    },
    validationSchema,
  });

  function onSubmit(formData) {
    const isEditing = !!data.id;
    const createOrUpdateTeam = () => {
      return isEditing
        ? updateAgencyTeam({ id: data.id }, formData)
        : createAgencyTeam({}, formData);
    };

    return createOrUpdateTeam()
      .then(() => {
        const successText = isEditing ? 'Updated' : 'Created';
        enqueueSnackbar(`Team ${successText} successfully!`, {
          variant: 'success',
        });
        delayedEvent('table-refetch', 1000, 'agency_teams');
        close();
      })
      .catch((error) => {
        const errorMessage = manageAPIError(
          error,
          'Unable to save your data, please try again'
        );

        setError(errorMessage);
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContext {...methods}>
        <DialogContent style={{ padding: '2rem 5rem' }}>
          <MuiGrid container spacing={1}>
            <MuiGrid item md={12}>
              <TextField
                name="name"
                label="Name"
                required
                fullWidth
                inputProps={{ required: true }}
              />
            </MuiGrid>
          </MuiGrid>
          <Box mt={2} display="flex" justifyContent="space-evenly">
            <Checkbox name="isDefault" label="Default Team" />
            <Checkbox name="location" label="Has Location" />
          </Box>
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <CbButton onClick={close} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton
            type="submit"
            styleName="ctaButton"
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            Save Changes
          </CbButton>
        </DialogActions>
      </FormContext>
    </form>
  );
};

export const TeamCreateEditModalConfig = {
  TeamCreateEditModal: {
    component: TeamCreateEditModal,
    config: {
      fullWidth: true,
      title: 'Create/Edit Team',
    },
  },
};
