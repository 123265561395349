import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { DialogContent, DialogActions, Grid, useTheme } from '@mui/material';
import CbButton from '../../../../components/Buttons/CbButton';
import Showable from '../../../../components/Showable';
import {
  TextField as TextFieldBase,
  SimpleSelect,
} from '../../../../components/inputs';

import {
  closeClaim,
  createDiaryEntry,
  getCloseClaimReasons,
  getDenyClaimReasons,
} from '../../../../api';

import {
  CLAIMS_INCURRED_AGGS,
  CLAIMS_STATUS_AGGS,
  COWBELL_CLAIMS,
} from '../../claims.constants';

import { withFormController } from '../../../../components/hocs/forms';
import { useAPIErrorHandler } from '../../../../components/hooks/useAPIErrorHandler';
import { getData } from '../../../../utils/functional/fp';

const Select = withFormController(SimpleSelect);
const TextField = withFormController(TextFieldBase);

type CloseClaimModalProps = {
  data: any;
  close: () => void;
};

function CloseClaimModal({
  data: claim,
  close: closeModal,
}: CloseClaimModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const handleAPIError = useAPIErrorHandler();
  const queryClient = useQueryClient();
  const theme = useTheme<Record<string, any>>();

  const { data: claimCloseReasons } = useClaimCloseReasonsQuery();
  const { data: claimDeclinationReasons } = useClaimDeclinationReasons();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      [FORM_CONSTANTS.fields.closeClaimReason.name]:
        FORM_CONSTANTS.fields.closeClaimReason.defaultVal,
      [FORM_CONSTANTS.fields.denyClaimReason.name]:
        FORM_CONSTANTS.fields.denyClaimReason.defaultVal,
      [FORM_CONSTANTS.fields.details.name]:
        FORM_CONSTANTS.fields.details.defaultVal,
      [FORM_CONSTANTS.fields.otherDenyClaimReason.name]:
        FORM_CONSTANTS.fields.otherDenyClaimReason.defaultVal,
    },
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    return closeClaim(claim.id, { data: formData })
      .then(() => {
        enqueueSnackbar('Claim closed successfully!', { variant: 'success' });

        queryClient.invalidateQueries([COWBELL_CLAIMS]);
        queryClient.invalidateQueries([CLAIMS_INCURRED_AGGS]);
        queryClient.invalidateQueries([CLAIMS_STATUS_AGGS]);

        // if additional details provided, create diary entry
        if (
          Boolean(formData.details) ||
          Boolean(formData.otherDenyClaimReason)
        ) {
          createClaimDenialDiaryEntry(
            claim,
            formData.details ?? formData.otherDenyClaimReason
          );
        }

        closeModal();
      })
      .catch(
        handleAPIError(
          'There was a problem with your request, please try again'
        )
      );
  };

  const [closeClaimReasonSelection, denyClaimReasonSelection] =
    formMethods.watch([
      FORM_CONSTANTS.fields.closeClaimReason.name,
      FORM_CONSTANTS.fields.denyClaimReason.name,
    ]);

  const isClaimCloseOther = closeClaimReasonSelection == CLAIM_REASON_OTHER;
  const isClaimCloseDeclination =
    closeClaimReasonSelection == CLAIM_REASON_DECLINED;
  const isDenyClaimReasonIncludesOther = React.useMemo(
    () => denyClaimReasonSelection?.includes(DECLINATION_REASON_OTHER) ?? false,
    [denyClaimReasonSelection]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Select
                name={FORM_CONSTANTS.fields.closeClaimReason.name}
                label={FORM_CONSTANTS.fields.closeClaimReason.label}
                options={claimCloseReasons}
                style={{ backgroundColor: theme.palette.background.modal }}
                required
              />
            </Grid>

            <Showable show={isClaimCloseOther}>
              <Grid item md={12}>
                <TextField name="details" label="Details" multiline rows={5} />
              </Grid>
            </Showable>

            <Showable show={isClaimCloseDeclination}>
              <Grid item md={12}>
                <Select
                  name={FORM_CONSTANTS.fields.denyClaimReason.name}
                  label={FORM_CONSTANTS.fields.denyClaimReason.label}
                  options={claimDeclinationReasons}
                  style={{ backgroundColor: theme.palette.background.modal }}
                  required
                  multiple
                  MenuProps={{
                    getContentAnchorEl: () => null,
                  }}
                />
              </Grid>

              <Showable show={isDenyClaimReasonIncludesOther}>
                <Grid item md={12}>
                  <TextField
                    name={FORM_CONSTANTS.fields.otherDenyClaimReason.name}
                    label={FORM_CONSTANTS.fields.otherDenyClaimReason.label}
                    multiline
                    minRows={5}
                  />
                </Grid>
              </Showable>
            </Showable>
          </Grid>
        </DialogContent>

        <DialogActions>
          <CbButton styleName="cancel" onClick={closeModal}>
            Cancel
          </CbButton>

          <CbButton
            styleName="ctaButton"
            type="submit"
            disabled={!formMethods.formState.isDirty}
          >
            Close
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
}

export const CloseClaimModalConfig = {
  CloseClaimModal: {
    component: CloseClaimModal,
    config: {
      fullWidth: true,
      title: 'Close Claim',
    },
  },
};

const CLAIM_REASON_DECLINED = 'DECLINED';
const CLAIM_REASON_OTHER = 'OTHER';
const DECLINATION_REASON_OTHER = 'OTHER';

const FORM_CONSTANTS = {
  fields: {
    closeClaimReason: {
      name: 'closeClaimReason',
      label: 'Reason',
      defaultVal: '',
    },
    denyClaimReason: {
      name: 'denyClaimReason',
      label: 'Declination Reason',
      defaultVal: [],
    },
    details: { name: 'details', label: 'Details', defaultVal: '' },
    otherDenyClaimReason: {
      name: 'otherDenyClaimReason',
      label: 'Declination Details',
      defaultVal: '',
    },
  },
} as const;

type FormValues = Record<keyof typeof FORM_CONSTANTS.fields, string> & {
  [FORM_CONSTANTS.fields.denyClaimReason.name]: string[];
};

const schema = Yup.object().shape({
  [FORM_CONSTANTS.fields.closeClaimReason.name]: Yup.string()
    .label(FORM_CONSTANTS.fields.closeClaimReason.label)
    .required(),
  [FORM_CONSTANTS.fields.denyClaimReason.name]: Yup.array()
    .of(Yup.string())
    .label(FORM_CONSTANTS.fields.denyClaimReason.label)
    .when(FORM_CONSTANTS.fields.closeClaimReason.name, {
      is: CLAIM_REASON_DECLINED,
      then: (fieldSchema: any) => fieldSchema.min(1),
    }),
  [FORM_CONSTANTS.fields.details.name]: Yup.string().label(
    FORM_CONSTANTS.fields.details.label
  ),
  [FORM_CONSTANTS.fields.otherDenyClaimReason.name]: Yup.string().label(
    FORM_CONSTANTS.fields.otherDenyClaimReason.label
  ),
});

function createClaimDenialDiaryEntry(
  claim: Record<string, any>,
  denialDetails: string
) {
  const payload = {
    ..._.pick(claim, ['accountId', 'policyNumber']),
    accountName: claim.companyName,
    claimId: claim.id,
    title: 'Claim Closed',
    noteType: 'CLAIM_CLOSED',
    noteText: denialDetails,
    attachments: [],
  };

  return createDiaryEntry({ data: payload });
}

function mapReasonsToOptions(reasons: Record<string, string> = {}) {
  return Object.keys(reasons).map((reason) => ({
    label: reasons[reason],
    value: reason,
  }));
}

useClaimCloseReasonsQuery.QUERY_KEY_TOPIC = 'claim-close-reasons';
function useClaimCloseReasonsQuery() {
  const handleAPIError = useAPIErrorHandler();

  return useQuery({
    queryKey: [useClaimCloseReasonsQuery.QUERY_KEY_TOPIC],
    queryFn: () => getCloseClaimReasons().then(getData),
    select: mapReasonsToOptions,
    onError: handleAPIError('Failed to fetch claim close options'),
  });
}

useClaimDeclinationReasons.QUERY_KEY_TOPIC = 'claim-declination-reasons';
function useClaimDeclinationReasons() {
  const handleAPIError = useAPIErrorHandler();

  return useQuery({
    queryKey: [useClaimDeclinationReasons.QUERY_KEY_TOPIC],
    queryFn: () => getDenyClaimReasons().then(getData),
    select: mapReasonsToOptions,
    onError: handleAPIError('Failed to fetch claim declination options'),
  });
}
