// platform helpers
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
// mui
import { Box, Card, Divider, Typography } from '@mui/material';
import { styled, makeStyles, useTheme } from '@mui/styles';
// json
import CoverageStatics from '../../../../../_statics/250/coverages/coverages.statics.json';
// apis
import UWComparisonCoverages from '../../../../../_tabs/uwComparisonDrawer/UWComparisonCoverages';
import { fetchP250Coverages } from '../../../../../../accounts/AccountService';
// utils
import { Flexbox } from '../policy-details.shared-components.utils';
import {
  getCoverageFormat,
  getCoverages,
  retroMappingShort,
} from '../../../../../_global/endorsements/endorsement.utils';
import { PrimeXProductTypes, ProductTypes } from '../../../../../../types';
import { formatCurrencyToTenthsPlaceWithUnit } from '../../../../../../utils/currency.utils';
import { toUniversalUtcDate } from '../../../../../../utils/date.utils';
import { PolicyStatus } from '../../../../../_statics/policy.statics';
import CoverageDetails from '../../../../../agencies/quotes/PrimeX/quoteDetails/components/CoverageDetails';

const policyStatusForShowingDrawer = [
  PolicyStatus.ISSUED_STATUS,
  PolicyStatus.IN_FORCE_STATUS,
  PolicyStatus.RENEWED_STATUS,
  PolicyStatus.EXPIRED_STATUS,
];

export const PolicyCoverageDetails = ({ policy, product }) => {
  const fetchLatestPolicyByStatus = policyStatusForShowingDrawer.includes(
    policy.status
  );

  const isPrimeXProduct = Object.values(PrimeXProductTypes).includes(product);

  const coverageDetails = useCoverageDetails({
    policy,
    product,
    fetchLatestPolicyByStatus,
  });

  return (
    <CoverageDetailsCard>
      {isPrimeXProduct ? (
        <CoverageDetails
          coverages={policy.coverages}
          productType={product}
          boxProps={{ pl: '1rem' }}
        />
      ) : (
        <>
          <Flexbox p={1}>
            <Typography color="primary">Coverage Details and Limits</Typography>
            <Typography color="primary">
              (Including all Endorsements)
            </Typography>
          </Flexbox>

          <UWComparisonCoverages
            uwCompareCoverages={coverageDetails}
            customUWComparisonCoveragesRow={CustomUWComparisonCoveragesRow}
            customComparisonRowLabel={CustomComparisonRowLabel}
            customComparisonColumn={CustomComparisonColumn}
            customUWComparisonGroupHeader={CustomUWComparisonGroupHeader}
          />
        </>
      )}
    </CoverageDetailsCard>
  );
};

const useCoverageDetails = ({ policy, product, fetchLatestPolicyByStatus }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: p250Coverages } = useQuery(
    ['p250-coverages'],
    () => {
      return fetchP250Coverages().then((res) => res.data);
    },
    {
      enabled: product === ProductTypes.p250,
      refetchOnWindowFocus: false,
      onError: (err) => {
        return enqueueSnackbar(
          _.get(err, 'message', 'Error retrieving P250 coverages.'),
          { variant: 'error' }
        );
      },
    }
  );

  if (product === ProductTypes.p100) {
    let formattedCoverages = {};

    if (!fetchLatestPolicyByStatus) {
      const p100Coverages = getCoverages(policy, product);
      formattedCoverages = getCoverageFormat(p100Coverages?.coverages);
    } else {
      formattedCoverages = getCoverageFormat(
        policy?.latestCoverages?.coverages
      );
    }

    const finalP100Coverages = constructFinalP100Coverages(formattedCoverages);
    return finalP100Coverages;
  }

  if (product === ProductTypes.p250) {
    const coverageListToObjects = (coveragesArray = []) => {
      return coveragesArray.reduce((acc, coverage) => {
        return {
          ...acc,
          [coverage.name]: {
            ...coverage,
          },
        };
      }, {});
    };

    const policyCoveragesObject = coverageListToObjects(policy.latestCoverages);
    const finalP250Coverages = handleMergeData({
      policyCoveragesObject,
      p250Coverages,
    });

    return finalP250Coverages;
  }
};

const constructFinalP100Coverages = (coverages) => {
  return coverages.map((coverage) => {
    return {
      groupId: coverage.groupId,
      groupName: coverage.key,
      label: coverage.name,
      row: [
        {
          ..._.pick(coverage, ['limit', 'deductible', 'retroActivePeriod']),
          waitingPeriod: coverage?.waitingPeriod
            ? `${coverage?.waitingPeriod} Hrs`
            : '-',
        },
      ],
    };
  });
};

const handleMergeData = ({
  policyCoveragesObject = {},
  p250Coverages = [],
}) => {
  return CoverageStatics.filter((stat) => {
    if (!policyCoveragesObject[stat.key]?.name) return false;
    return true;
  }).map((stat) => {
    const { groupId } = stat;

    return {
      groupId,
      groupName: stat.key,
      label: p250Coverages[stat._id]?.name,
      row: [
        {
          limit: policyCoveragesObject[stat.key]?.limit
            ? formatCurrencyToTenthsPlaceWithUnit(
                +policyCoveragesObject[stat.key].limit
              )
            : '-',
          deductible: policyCoveragesObject[stat.key]?.deductible
            ? formatCurrencyToTenthsPlaceWithUnit(
                +policyCoveragesObject[stat.key].deductible
              )
            : '-',
          waitingPeriod: policyCoveragesObject[stat.key]?.waitingPeriod
            ? `${policyCoveragesObject[stat.key]?.waitingPeriod} Hrs`
            : '-',
          retroActivePeriod:
            policyCoveragesObject[stat.key]?.retroActivePeriod || '-',
          retroActiveDate:
            policyCoveragesObject[stat.key]?.retroActiveDate || '-',
        },
      ],
    };
  });
};

const CustomUWComparisonCoveragesRow = ({ rowData, index }) => {
  const { palette } = useTheme();
  const classes = useClasses();

  return (
    <div
      style={
        index % 2 === 0
          ? { background: palette.background.default, width: '100%' }
          : { background: palette.background.modal, width: '100%' }
      }
    >
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.container}
      >
        <CustomComparisonRowLabel rowData={rowData} />
        <Divider orientation="vertical" flexItem />
        {rowData.row.map((row, idx) => {
          return <CustomComparisonColumn rowData={row} index={idx} />;
        })}
      </Box>
    </div>
  );
};

const CustomComparisonRowLabel = ({ rowData }) => {
  return (
    <div style={{ width: '13rem', textAlign: 'right', fontSize: '1rem' }}>
      {rowData?.label}
    </div>
  );
};

const CustomComparisonColumn = ({ rowData }) => {
  const classes = useClasses();

  let retro = '-';
  if (
    rowData.retroActiveDate !== '-' &&
    rowData.retroActiveDate !== undefined
  ) {
    retro = toUniversalUtcDate(rowData.retroActiveDate);
  } else if (rowData.retroActivePeriod !== '-') {
    retro = retroMappingShort[rowData.retroActivePeriod];
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex="0.35"
        style={{ position: 'relative' }}
        className={classes.innerContainer}
      >
        <div>{rowData.limit}</div>
        <div>{rowData.deductible}</div>
        <div>{rowData.waitingPeriod}</div>
        <div>{retro}</div>
      </Box>
    </>
  );
};

const CustomUWComparisonGroupHeader = ({ sectionLabel }) => {
  const headerClasses = useHeaderClasses();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-evenly"
      className={headerClasses.container}
    >
      <Box>
        <h3 style={{ width: '13rem' }} className={headerClasses.sectionTitle}>
          {sectionLabel}
        </h3>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        flex="0.35"
        className={headerClasses.innerContainer}
      >
        <div className={headerClasses.covLimit}>Coverage Limit</div>
        <div>Deductible</div>
        <div>Waiting Period</div>
        <div className={headerClasses.borderedRightCell}>
          Retro Active Period
        </div>
      </Box>
    </Box>
  );
};

const CoverageDetailsCard = styled(Card)(({ theme: { palette } }) => ({
  marginTop: '1rem',
  backgroundColor: palette.background.default,
}));

const useClasses = makeStyles(() => ({
  container: {
    height: '2.56rem',
    '& > div': {
      fontSize: '0.75rem',
      lineHeight: '1.17',
    },
  },
  innerContainer: {
    gap: '2.5rem',
    '& > div': {
      minWidth: '3.5rem',
      textAlign: 'center',
      fontSize: '1rem',
    },
  },
  blueText: {
    color: '#2180e2 !important',
  },
  greenText: {
    color: '#6ac52a',
  },
}));

const useHeaderClasses = makeStyles(({ palette }) => ({
  container: {
    width: '100%',
    background: palette.background.paper,
    color: palette.text.secondary,
    textTransform: 'uppercase',
    borderTop: `solid 1px ${palette.primary.border}`,
    '& > div': {
      fontSize: '0.75rem',
      lineHeight: '1.17',
    },
  },
  innerContainer: {
    gap: '2.5rem',
    '& > div': {
      width: '3.5rem',
      textAlign: 'center',
      fontSize: '0.8rem',
    },
  },
  sectionTitle: {
    fontSize: '0.875rem',
    lineHeight: '1.43',
    fontWeight: '600',
    margin: '0',
    padding: '1rem 0',
    textAlign: 'right',
  },
  borderedCell: {
    borderLeft: `solid 1px ${palette.primary.border}`,
    borderRight: `solid 1px ${palette.primary.border}`,
    padding: '0 0.625rem',
  },
  covLimit: {
    textAlign: 'center',
    width: '25%',
  },
}));
