import React from 'react';

import { useSteps } from '../../../components/hooks/useSteps';

export const actions = {
  SAVE_PH_INFO: 'SAVE_PH_INFO',
  SAVE_AGENCY_INFO: 'SAVE_AGENCY_INFO',
  SAVE_BIND_ORDER_INFO: 'SAVE_BIND_ORDER_INFO',
  SAVE_POLICY_EFFECTIVE_DATE: 'SAVE_POLICY_EFFECTIVE_DATE',
  SAVE_NKLL: 'SAVE_NKLL',
  SAVE_DILLIGENT_SEARCH_REPORT: 'SAVE_DILLIGENT_SEARCH_REPORT',
  SAVE_LOSS_RUNS: 'SAVE_LOSS_RUNS',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SAVE_PH_INFO:
      return {
        ...state,
        customerFirstName: action.payload.customerFirstName,
        customerLastName: action.payload.customerLastName,
        customerEmail: action.payload.customerEmail,
        customerPhone: action.payload.customerPhone,
      };
    case actions.SAVE_AGENCY_INFO:
      return {
        ...state,
        agencyMailingAddress1: action.payload.agencyMailingAddress1,
        agencyMailingAddress2: action.payload.agencyMailingAddress2,
        agencyMailingCity: action.payload.agencyMailingCity,
        agencyMailingState: action.payload.agencyMailingState,
        agencyMailingZipCode: action.payload.agencyMailingZipCode,
        surplusLicenseNumber: action.payload.surplusLicenseNumber,
        surplusLinesBrokerEmail: action.payload.surplusLinesBrokerEmail,
        surplusLinesBrokerFirstName: action.payload.surplusLinesBrokerFirstName,
        surplusLinesBrokerLastName: action.payload.surplusLinesBrokerLastName,
        surplusLinesState: action.payload.surplusLinesState,
      };
    case actions.SAVE_BIND_ORDER_INFO:
      return {
        ...state,
        bindOrderDate: action.payload.bindOrderDate,
      };
    case actions.SAVE_POLICY_EFFECTIVE_DATE:
      return {
        ...state,
        effectiveDateOfPolicy: action.payload.effectiveDateOfPolicy,
      };
    case actions.SAVE_LOSS_RUNS:
      return {
        ...state,
        hadPreviousCoverage: action.payload.hadPreviousCoverage,
        yearsOfPreviousCoverage: action.payload.yearsOfPreviousCoverage,
      };
    case actions.SAVE_NKLL:
      return {
        ...state,
        isNKLLDocUploaded: action.payload.isNKLLDocUploaded,
      };
    case actions.SAVE_DILLIGENT_SEARCH_REPORT:
      return {
        ...state,
        dilligentSearchReportExemption:
          action.payload.dilligentSearchReportExemption,
      };
    default:
      return {
        ...state,
      };
  }
};

export const StepperProvider = ({
  children,
  quoteDetails,
  startStep,
  initialSteps = [],
  incompleteSteps = [],
}) => {
  const stepperState = useSteps({
    startStep,
    initialSteps,
  });

  // If a subj gets added while agent is working on flow, BE and frontend seem to get our of sync. This resyncs FE and BE to prevent issues
  React.useEffect(() => {
    if (initialSteps.length !== stepperState.steps.length) {
      stepperState.setSteps(initialSteps);
    }
  }, [initialSteps, stepperState]);

  const { uiData } = quoteDetails;

  const initialState = React.useMemo(() => {
    return {
      customerFirstName: uiData.customerFirstName,
      customerLastName: uiData.customerLastName,
      customerEmail: uiData.customerEmail,
      customerPhone: uiData.customerPhone,
      agencyMailingAddress1: uiData.agencyMailingAddress1,
      agencyMailingAddress2: uiData.agencyMailingAddress2,
      agencyMailingCity: uiData.agencyMailingCity,
      agencyMailingState: uiData.agencyMailingState.toUpperCase(),
      agencyMailingZipCode: uiData.agencyMailingZipCode,
      surplusLicenseNumber: uiData.surplusLicenseNumber,
      surplusLinesBrokerEmail: uiData.surplusLinesBrokerEmail,
      surplusLinesBrokerFirstName: uiData.surplusLinesBrokerFirstName,
      surplusLinesBrokerLastName: uiData.surplusLinesBrokerLastName,
      surplusLinesState: uiData.surplusLinesState.toUpperCase(),
      effectiveDateOfPolicy: uiData.effectiveDateOfPolicy,
      hadPreviousCoverage: null,
      yearsOfPreviousCoverage: '',
      manufacturingAcknowledgement: false,
      techENOAcknowledgement: false,
      isNKLLDocUploaded: false,
      dilligentSearchReportExemption: null,
      bindOrderDate: uiData.bindOrderDocumentInfo?.docSignedOn ?? '',
    };
  }, [uiData]);

  const [formState, dispatch] = React.useReducer(reducer, initialState);

  const savePHInfo = (payload) => {
    dispatch({
      type: actions.SAVE_PH_INFO,
      payload,
    });
  };

  const saveAgencyInfo = (payload) => {
    dispatch({
      type: actions.SAVE_AGENCY_INFO,
      payload,
    });
  };

  const saveBindOrderInfo = (payload) => {
    dispatch({
      type: actions.SAVE_BIND_ORDER_INFO,
      payload,
    });
  };

  const savePolicyEffectiveDate = (payload) => {
    dispatch({
      type: actions.SAVE_POLICY_EFFECTIVE_DATE,
      payload,
    });
  };

  const saveLossRuns = (payload) => {
    dispatch({
      type: actions.SAVE_LOSS_RUNS,
      payload,
    });
  };

  const saveBindQuote = (payload) => {
    dispatch({
      type: actions.SAVE_BIND_QUOTE,
      payload,
    });
  };

  const acknowledgeManufacturing = (payload) => {
    dispatch({
      type: actions.ACKNOWLEDGE_MANUFACTURING,
      payload,
    });
  };

  const acknowledgeTechENO = (payload) => {
    dispatch({
      type: actions.ACKNOWLEDGE_TECH_ENO,
      payload,
    });
  };

  const saveNKLL = (payload) => {
    dispatch({
      type: actions.SAVE_NKLL,
      payload,
    });
  };

  const saveDilligentSearchReport = (payload) => {
    dispatch({
      type: actions.SAVE_DILLIGENT_SEARCH_REPORT,
      payload,
    });
  };

  const formHandlers = {
    savePHInfo,
    saveAgencyInfo,
    saveBindOrderInfo,
    savePolicyEffectiveDate,
    saveLossRuns,
    saveBindQuote,
    acknowledgeManufacturing,
    acknowledgeTechENO,
    saveNKLL,
    saveDilligentSearchReport,
  };

  return (
    <>{children({ formState, formHandlers, stepperState, incompleteSteps })}</>
  );
};
