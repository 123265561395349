export const COWBELL_CLAIMS = 'COWBELL_CLAIMS';
export const COWBELL_CLAIMS_INVOICES = 'COWBELL_CLAIMS_INVOICES';
export const CLAIMS_INVOICES = 'CLAIMS_INVOICES';
export const CLAIMS_INVOICES_AGGS = 'CLAIMS_INVOICES_AGGS';
export const RESERVE_DATA_AGGS = 'RESERVE_DATA_AGGS';
export const CLAIMS_EXPERTS = 'CLAIMS_EXPERTS';
export const CLAIMS_STATUS_AGGS = 'CLAIMS_STATUS_AGGS';
export const CLAIMS_INCURRED_AGGS = 'CLAIMS_INCURRED_AGGS';
export const AGENCY_CLAIMS = 'AGENCY_CLAIMS';
export const VENDOR_CLAIMS = 'VENDOR_CLAIMS';
export const CARRIER_CLAIMS = 'CARRIER_CLAIMS';
export const CLAIMS_DIARY = 'CLAIMS_DIARY';
