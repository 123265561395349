import React, { useContext } from 'react';

import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Collapse as MuiCollapse,
  CardContent as MuiCardContent,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { TableContext } from './context.table';

const styles = () => ({
  rowRoot: {
    height: 'unset',
    padding: 0,
  },

  cellRootClosed: {
    padding: 0,
    borderBottom: 0,
  },

  cellRootOpen: {
    padding: 0,
  },
});

export const ExpandableRow = withStyles(styles)(
  ({ children, render, ...props }) => {
    const ctx = useContext(TableContext);

    if (typeof render === 'function') {
      return render(props, ctx);
    }

    return (
      <MuiTableRow className={props.classes.rowRoot}>
        <MuiTableCell
          className={
            props.expanded
              ? props.classes.cellRootOpen
              : props.classes.cellRootClosed
          }
          colSpan={ctx.columns.length}
        >
          <MuiCollapse in={props.expanded} timeout="auto">
            <MuiCardContent>{children}</MuiCardContent>
          </MuiCollapse>
        </MuiTableCell>
      </MuiTableRow>
    );
  }
);

ExpandableRow.displayName = 'ExpandableRow';
