import React from 'react';
import _ from 'lodash';

export const useSavedSettings = (domain, columnConfig) => {
  const rawStore = localStorage.getItem(`cowbell`);
  const store = JSON.parse(rawStore);

  const slice = React.useMemo(
    () => _.get(store, `uiSettings.${domain}`, {}),
    // eslint-disable-next-line
    [store]
  );

  const reconciledColumns = React.useMemo(
    () =>
      _.isEmpty(slice)
        ? columnConfig
        : Object.values(columnConfig).reduce((acc, col) => {
            const hasSavedSettings = _.get(slice, `columns.${col.name}`);
            const show = hasSavedSettings
              ? _.get(slice, `columns.${col.name}.show`) !== false
              : col.show !== false;
            return {
              ...acc,
              [col.name]: { ...col, show },
            };
          }, {}),
    // eslint-disable-next-line
    []
  );

  const saveSetting = React.useCallback(
    (update) => {
      localStorage.setItem(
        `cowbell`,
        JSON.stringify({
          ...store,
          uiSettings: {
            ...store.uiSettings,
            [domain]: { ...slice, ...update },
          },
        })
      );
    },
    // eslint-disable-next-line
    [slice]
  );

  return { uiSettings: { ...slice, columns: reconciledColumns }, saveSetting };
};
