import { DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { useLocation } from 'react-router-dom';
import CbButton from '../../../../../components/Buttons/CbButton';
import { useQueryParams } from '../../../../../components/providers/QueryParamsProvider';

const ClearFiltersModal = ({ data, ...props }) => {
  const { query, replace } = useQueryParams();
  const location = useLocation();
  const { f, fn, ...queryRest } = query;
  const onClearOnce = () => {
    props.close();
    replace(location.pathname, { query: queryRest });
  };
  const onClearPermanently = () => {
    const store = JSON.parse(localStorage.getItem('cowbell'));
    localStorage.setItem(
      'cowbell',
      JSON.stringify({
        ...store,
        uiSettings: {
          ...store.uiSettings,
          [data.tableId]: {
            ...store.uiSettings[data.tableId],
            clearDefaultFilters: true,
          },
        },
      })
    );
    props.close();
    replace(location.pathname, { query: queryRest });
  };
  return (
    <>
      <DialogContent>
        <DialogContentText>
          Oops! It looks like we couldn't find any data for this table. By
          default, Renewal Submission results are filtered by{' '}
          <strong>Renewal Status</strong> and <strong>Logged-in User</strong>.
          Below are some options for how to proceed:
        </DialogContentText>
        <DialogContentText>
          <ul>
            <li>
              <strong>Clear Once:</strong> clears default filters and refreshes
              page data this time only.
            </li>
            <li>
              <strong>Clear Permanently:</strong> clears default filters
              permanently and refreshes page data. Whenever you return to the
              page, data will default to unfiltered, and you will not see this
              message again.
            </li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={onClearOnce}>
          Clear Once
        </CbButton>
        <CbButton styleName="cancel" onClick={onClearPermanently}>
          Clear Permanently
        </CbButton>
      </DialogActions>
    </>
  );
};

export const ClearFiltersModalConfig = {
  ClearFiltersModal: {
    component: ClearFiltersModal,
    config: {
      fullWidth: true,
      title: 'Clear Default Filters',
    },
  },
};
