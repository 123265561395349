import { Box, DialogActions, DialogContent } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { withFormController } from '../../hocs/forms';
import TextFieldBase from '../../inputs/text-fields/base/TextFieldBase';
import { ControlledCheckBox } from '../../inputs/ControlledCheckBox';
import { updateAccountContact } from '../../../accounts/AccountService';
import type { QuoteSearchRowDto } from '../../../types/quotes/tables/quote-table-row.dto';
import { iconMappings } from '../../../console/_global/navigation/IconsMappings';
import CbButton from '../../Buttons/CbButton';
import LanguageForm, {
  useLanguageFormState,
} from '../../../i18n/forms/LanguageForm';
import { FormLanguageProvider } from '../../../i18n/forms/FormLanguageProvider';

import { getRegionByProduct } from '../../../i18n/utils';
import type { ProductType } from '../../../types';

const TextField = withFormController(TextFieldBase);

type Data = Pick<
  QuoteSearchRowDto,
  | 'agencyId'
  | 'customerPhone'
  | 'customerFirstName'
  | 'customerLastName'
  | 'accountId'
  | 'customerEmail'
  | 'agentPhone'
  | 'agentFirstName'
  | 'agentLastName'
  | 'agentEmail'
  | 'addUserToSystem'
  | 'product'
> & { refetchTableData?: () => Promise<void> };

type FormValues = Pick<
  Data,
  | 'customerPhone'
  | 'customerEmail'
  | 'customerLastName'
  | 'customerFirstName'
  | 'addUserToSystem'
>;

interface Props {
  data: Data;
  close: () => void;
}

export const UpdatePHContact = ({
  data: {
    customerFirstName,
    customerLastName,
    customerPhone,
    customerEmail,
    refetchTableData,
    product,
    ...quote
  },
  ...props
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const region = getRegionByProduct(product as ProductType);
  const languageFormState = useLanguageFormState({
    language: region,
    options: {
      validatePhone: true,
      phoneFieldName: 'customerPhone',
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      customerEmail,
      customerFirstName,
      customerLastName,
      customerPhone,
      addUserToSystem: quote.addUserToSystem ?? false,
    },
    resolver: yupResolver(getSchema(languageFormState.languageFormSchema)),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (formValues: FormValues) => {
    const payload = {
      ...quote,
      addUserToSystem: formValues.addUserToSystem,
      contactFirstName: formValues.customerFirstName,
      contactLastName: formValues.customerLastName,
      contactEmail: formValues.customerEmail,
      contactPhone: formValues.customerPhone,
    };

    return updateAccountContact(payload)
      .then(() => {
        enqueueSnackbar('Updated Customer Information Successfully', {
          variant: 'success',
        });
        refetchTableData?.();
        props.close();
      })
      .catch(() =>
        enqueueSnackbar('Failed to update PH contact', { variant: 'error' })
      );
  };

  return (
    <FormProvider {...methods}>
      <FormLanguageProvider {...languageFormState}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box
              mb={2}
              display="flex"
              gap="1rem"
              justifyContent="space-between"
            >
              <TextField
                required
                name="customerFirstName"
                label="Policy Holder First Name"
              />
              <TextField
                required
                name="customerLastName"
                label="Policy Holder Last Name"
              />
            </Box>
            <Box display="flex" gap="1rem" justifyContent="space-between">
              <TextField
                required
                name="customerEmail"
                label="Policy Holder E-mail"
              />
              <LanguageForm.PhoneField
                required
                name="customerPhone"
                label="Policy Holder Phone Number"
              />
            </Box>
            <Box display="flex" justifyContent="center" mt={3}>
              <ControlledCheckBox
                style={{ height: '1.5rem' }}
                name="addUserToSystem"
                label="Invite Policy Holder to the Cowbell Cyber Platform"
              />
            </Box>
            <Box width="100%" style={{ textAlign: 'center' }}>
              Note: Select this only if you have communicated with the Customer
            </Box>
          </DialogContent>
          <DialogActions>
            <CbButton onClick={props.close} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton
              type="submit"
              disabled={isSubmitting}
              styleName="ctaButton"
            >
              Update PH Contact
            </CbButton>
          </DialogActions>
        </form>
      </FormLanguageProvider>
    </FormProvider>
  );
};

const getSchema = (languageSchema: Record<string, any>) => {
  return Yup.object().shape({
    customerFirstName: Yup.string()
      .required('Please enter Policy Holder First Name.')
      .test(
        'empty-check',
        'first name must be at least 8 characters',
        (fn: string) => fn.trim().length > 0
      ),
    customerLastName: Yup.string()
      .required('Please enter Policy Holder Last Name.')
      .test(
        'empty-check',
        'last name must be at least 8 characters',
        (ln: string) => ln.trim().length > 0
      ),
    customerEmail: Yup.string()
      .email('Please enter a valid Email Address')
      .required('Please enter Email Address'),
    customerPhone: languageSchema.customerPhone,
  });
};

export const UpdatePHContactConfig = {
  UpdatePHContact: {
    component: UpdatePHContact,
    config: {
      minWidth: 'md',
      fullWidth: true,
      title: 'Add/Update PH Contact',
      icon: iconMappings.AddAccount.iconImport,
    },
  },
};
