import React from 'react';

import { useSnackbar } from 'notistack';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';

// mui
import { Box as MuiBox, Grid, styled, Typography } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Skeleton } from '@mui/lab';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { TextFieldBase as TextField } from '../../../../components/inputs/TextFieldBase';

// components
import CbButton from '../../../../components/Buttons/CbButton';

// helpers
import { Show } from '../../../../components/Show';
import { withShowable } from '../../../_global/lib/withShowable';
import { useAuth } from '../../../../components/hooks/useAuth';

// apis
import {
  calculatePremiumWithEffDate,
  updatePolicyEffectiveDate,
} from '../../../../api/bind-quote.api';

import { numberToCurrency } from '../../../../utils/currency.utils';
import { CARRIERS_PREFIX_MAP } from '../../../../utils/carrier.utils';
import {
  getPolicyEffectiveDateRestrictions,
  handleError,
} from '../utils/bindQuoteWorkFlowUtils';
import { ProductTypes } from '../../../../types';
import { getIsRenewalOrMigration } from '../../../../utils';
import { QuoteStatus } from '../../../_statics/quote.statics';
import { bindSteps } from '../constants/constants';
import { toUniversalUtcDate } from '../../../../utils/date.utils';
import { FEATURE_FLAG_KEYS } from '../../../../config/growthbook';

const Box = withShowable(MuiBox);

const PolicyEffectiveDate = ({
  goForward,
  goBack,
  completeStep,
  step,
  formState,
  formHandlers,
  quoteDetails,
  quoteProgress,
  steps,
}) => {
  const { isRenewal, isMigration, uiData } = quoteDetails;
  const { effectiveDateOfPolicy } = formState;

  const { enqueueSnackbar } = useSnackbar();
  const [isPolicyEffectiveDateEntered, setIsPolicyEffectiveDateEntered] =
    React.useState(false);
  const [isCompletingStep, setIsCompletingStep] = React.useState(false);
  const [raterReferToUw, setRaterReferToUw] = React.useState(false);

  const queryClient = useQueryClient();
  const auth = useAuth();

  const isRenewalOrMigration = getIsRenewalOrMigration(isRenewal, isMigration);

  const canEditEffectiveDate =
    quoteProgress.quoteAgencyStatus !== QuoteStatus.BOUND_STATUS &&
    quoteProgress.quoteAgencyStatus !== QuoteStatus.SIGNED_STATUS &&
    !isRenewalOrMigration &&
    ![ProductTypes.p100_pro, ProductTypes.prime_one].includes(
      uiData.productType
    );

  const { minDate, maxDate } = getPolicyEffectiveDateRestrictions(
    uiData.productType,
    auth.isCowbellUserJumped
  );

  const onSubmit = (e) => {
    e.preventDefault();
    setIsCompletingStep(true);

    if (effectiveDateOfPolicy || isPolicyEffectiveDateEntered) {
      updatePolicyEffectiveDate(uiData.quoteId, {
        data: {
          effectiveDate: effectiveDateOfPolicy,
        },
      })
        .then(async () => {
          enqueueSnackbar('Policy effective date saved successfully.', {
            variant: 'success',
          });
          await queryClient.invalidateQueries([
            'quote-progress',
            uiData.quoteId,
          ]);
          await queryClient.invalidateQueries([
            'quote-details',
            uiData.quoteId,
          ]);

          completeStep(step);
          goForward();
        })
        .catch((error) => {
          handleError({ error, enqueueSnackbar });
        })
        .finally(() => setIsCompletingStep(false));
    }
  };

  const moveForward = () => {
    if (!effectiveDateOfPolicy || !isPolicyEffectiveDateEntered) {
      goForward();
    }
  };

  const handleDateChange = (e) => {
    formHandlers.savePolicyEffectiveDate({ [e.target.name]: e.target.value });
    setIsPolicyEffectiveDateEntered(true);
  };

  const { data: premiumData = {}, isSuccess: isPremiumDataSuccess } =
    useNewEffDateCalculator({
      quoteId: uiData.quoteId,
      effectiveDateOfPolicy,
      isPolicyEffectiveDateEntered,
      setRaterReferToUw,
      productType: uiData.productType,
    });

  const isFirstStep = steps[0] === bindSteps.POLICY_EFFECTIVE_DATE;
  return (
    <Box className="contrast-text" maxWidth="60%">
      <Typography variant="h4" style={{ fontWeight: 600, fontSize: '1.5rem' }}>
        Policy Effective Date
      </Typography>

      <h3>Please confirm policy effective date or select a date below:</h3>

      <form onSubmit={onSubmit}>
        <Box width="30rem">
          <Grid>
            <TextField
              name="effectiveDateOfPolicy"
              label="Effective Date of Policy"
              onChange={handleDateChange}
              value={formState.effectiveDateOfPolicy}
              required
              disabled={!canEditEffectiveDate}
              type="date"
              InputProps={{
                inputProps: {
                  min: minDate,
                  max: maxDate,
                },
              }}
            />
          </Grid>
          <PremiumDataSection
            show={
              isPolicyEffectiveDateEntered &&
              uiData.productType === ProductTypes.p250 &&
              !raterReferToUw
            }
            quoteDetails={quoteDetails}
            premiumData={premiumData}
            isPremiumDataSuccess={isPremiumDataSuccess}
          />
          <P100PremiumDataSection
            show={
              isPolicyEffectiveDateEntered &&
              uiData.productType === ProductTypes.p100
            }
            premiumData={premiumData}
            isPremiumDataSuccess={isPremiumDataSuccess}
          />
        </Box>
        <Box
          show={
            (!!premiumData.referToUnderwriter &&
              isPolicyEffectiveDateEntered) ||
            raterReferToUw
          }
        >
          <ErrorMessageContainer>
            <ErrorOutlineOutlined />
            This policy effective date change will refer this quote to an
            underwriter for review. If you wish to proceed, please click next.
          </ErrorMessageContainer>
        </Box>
        <Box>
          <IfFeatureEnabled
            feature={FEATURE_FLAG_KEYS.COWBELL_BIND_QUOTE_WORKFLOW}
          >
            <Show when={!isFirstStep}>
              <CbButton styleName="cancel" size="medium" onClick={goBack}>
                Back
              </CbButton>
            </Show>
          </IfFeatureEnabled>

          <CbButton
            styleName="ctaButton"
            size="medium"
            type="submit"
            loading={isCompletingStep}
            disabled={isCompletingStep}
            onClick={moveForward}
          >
            Next
          </CbButton>
        </Box>
      </form>
    </Box>
  );
};

export default PolicyEffectiveDate;

const RaterContainer = styled(Box)({
  fontSize: '1.2rem',
  paddingBottom: '1.5rem',
});

const RaterHeader = styled(Box)({
  fontSize: '1.2rem',
  fontWeight: 600,
});

const ErrorMessageContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  fontSize: '1.2rem',
  paddingBottom: '2rem',
});

const useNewEffDateCalculator = ({
  quoteId,
  effectiveDateOfPolicy,
  isPolicyEffectiveDateEntered,
  setRaterReferToUw,
  productType,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    ['calculate-premium-eff-date', quoteId, effectiveDateOfPolicy],
    () => {
      return calculatePremiumWithEffDate(quoteId, {
        data: {
          effectiveDate: effectiveDateOfPolicy,
        },
      }).then(({ data }) => {
        return data;
      });
    },
    {
      enabled: !!effectiveDateOfPolicy && isPolicyEffectiveDateEntered,
      retry: false,
      onError: (error) => {
        if (
          error?.response?.status === 400 &&
          productType !== ProductTypes.p100
        ) {
          return setRaterReferToUw(true);
        }

        handleError({
          error,
          enqueueSnackbar,
          fallbackMessage:
            'Something went wrong while calculating the new premium.',
        });
      },
    }
  );
};

const PremiumDataSkeleton = withShowable(() => {
  return (
    <>
      <Grid container spacing={12}>
        <Grid item sm={4}>
          <Skeleton animation="wave" height={60} width={100} />
        </Grid>
        <Grid item sm={4}>
          <Skeleton animation="wave" height={60} width={100} />
        </Grid>
        <Grid item sm={4}>
          <Skeleton animation="wave" height={60} width={100} />
        </Grid>
      </Grid>
      <Grid container spacing={12}>
        <Grid item sm={4}>
          <Skeleton animation="wave" height={60} width={100} />
        </Grid>
        <Grid item sm={4}>
          <Skeleton animation="wave" height={60} width={100} />
        </Grid>
      </Grid>
    </>
  );
});

const P100PremiumDataSkeleton = () => {
  return (
    <>
      <Grid container spacing={12} style={{ paddingBottom: '1rem' }}>
        <Grid item sm={6}>
          <Skeleton animation="wave" height={60} width={100} />
        </Grid>
        <Grid item sm={6}>
          <Skeleton animation="wave" height={60} width={100} />
        </Grid>
      </Grid>
      <Grid container spacing={12} style={{ paddingBottom: '1rem' }}>
        <Grid item sm={6}>
          <Skeleton animation="wave" height={60} width={100} />
        </Grid>
        <Grid item sm={6}>
          <Skeleton animation="wave" height={60} width={100} />
        </Grid>
      </Grid>
    </>
  );
};

const P100PremiumDataSection = withShowable(
  ({ premiumData, isPremiumDataSuccess }) => {
    const oldPremium = premiumData?.oldTotalPremium
      ? numberToCurrency(premiumData?.oldTotalPremium)
      : '-';
    const newPremium = premiumData?.newTotalPremium
      ? numberToCurrency(premiumData?.newTotalPremium)
      : '-';
    const baseCommission = premiumData?.baseCommission
      ? `${premiumData?.baseCommission}%`
      : '-';
    const policyEndDate = toUniversalUtcDate(premiumData?.policyEndDate);

    if (!isPremiumDataSuccess) {
      return <P100PremiumDataSkeleton />;
    }

    return (
      <RaterContainer>
        <Grid container spacing={12} style={{ paddingBottom: '1rem' }}>
          <Grid item sm={6}>
            <RaterHeader>Old Premium:</RaterHeader>
            <Box>{oldPremium}</Box>
          </Grid>
          <Grid item sm={6}>
            <RaterHeader>New Premium:</RaterHeader>
            <Box>{newPremium}</Box>
          </Grid>
        </Grid>
        <Grid container spacing={12} style={{ paddingBottom: '1rem' }}>
          <Grid item sm={6}>
            <RaterHeader>Base Commision:</RaterHeader>
            <Box>{baseCommission}</Box>
          </Grid>
          <Grid item sm={6}>
            <RaterHeader>Policy End Date:</RaterHeader>
            <Box>{policyEndDate}</Box>
          </Grid>
        </Grid>
      </RaterContainer>
    );
  }
);

const PremiumDataSection = withShowable(
  ({ quoteDetails, premiumData, isPremiumDataSuccess }) => {
    const oldPremium = quoteDetails?.totalPremium
      ? numberToCurrency(quoteDetails?.totalPremium)
      : '-';
    const newPremium = premiumData?.newTotalPremium
      ? numberToCurrency(premiumData?.newTotalPremium)
      : '-';
    const commission = premiumData?.baseCommission
      ? `${premiumData?.baseCommission}%`
      : '-';
    const carrier = premiumData?.newCarrierPrefix;
    const endDate = toUniversalUtcDate(premiumData?.policyEndDate);

    if (isPremiumDataSuccess) {
      return (
        <RaterContainer>
          <Grid container spacing={12} style={{ paddingBottom: '1rem' }}>
            <Grid item sm={4}>
              <RaterHeader>Old Premium:</RaterHeader>
              <Box>{oldPremium}</Box>
            </Grid>
            <Grid item sm={4}>
              <RaterHeader>New Premium:</RaterHeader>
              <Box>{newPremium}</Box>
            </Grid>
            <Grid item sm={4}>
              <RaterHeader>Base Commission:</RaterHeader>
              <Box>{commission}</Box>
            </Grid>
          </Grid>
          <Grid container spacing={12}>
            <Grid item sm={4}>
              <RaterHeader>New Carrier:</RaterHeader>
              <Box>{CARRIERS_PREFIX_MAP[carrier] || '-'}</Box>
            </Grid>
            <Grid item sm={4}>
              <RaterHeader>End Date of Policy:</RaterHeader>
              <Box>{endDate}</Box>
            </Grid>
          </Grid>
        </RaterContainer>
      );
    }
    return <PremiumDataSkeleton />;
  }
);
