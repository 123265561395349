import _ from 'lodash';

import type {
  AccountDto,
  P100SecurityQuestions,
  P250SecurityQuestions,
} from '../types';

export function pull250AccountQuestions(
  account: AccountDto
): P250SecurityQuestions {
  return {
    ..._.pick(account, [
      'mfaAuthentication',
      'mfaCloudDeployments',
      'mfaEmail',
      'mfaMissionCriticalSystems',
      'mfaRemoteAccess',
      'mfaOther',
      'incidentResponsePlan',
      'changeInScope',
      'changeInScopeDetail',
      'isVerifyingBankAccounts',
      'isAuthenticatingFundTransferRequests',
      'isPreventingUnauthorizedWireTransfers',
      'isSecurityOfficer',
      'isSecurityTraining',
      'useEncryption',
      'useCloudStorage',
      'claimHistory',
      'renewalChangeInAnswers',
    ]),
    ..._.pick(account.p250Details, [
      'backupFrequencyEncrypted',
      'backupFrequencySeparate',
      'backupFrequencyOther',
      'backupFrequencyTested',
      'dmzSeparation',
      'thirdPartySecurityAgreement',
      'backupFrequency',
      'testedFullFailover',
      'patchingFrequency',
      'civilOrCriminalAction',
      'lossInBusinessIncome',
      'pastCyberIncident',
      'pastCyberIncidentDetails',
      'pendingLitigation',
      'securityBreachRequiringNotification',
    ]),
  };
}

export function pull100AccountQuestions(
  account: AccountDto
): P100SecurityQuestions {
  return _.pick(account, [
    'isVerifyingBankAccounts',
    'isAuthenticatingFundTransferRequests',
    'isPreventingUnauthorizedWireTransfers',
    'claimHistory',
    'isSecurityOfficer',
    'isSecurityTraining',
    'useEncryption',
    'useCloudStorage',
  ]);
}

export function pullAccountDetails(account: AccountDto): any {
  return {
    ..._.pick(account, [
      'id',
      'agentFirstName',
      'agentLastName',
      'agentEmail',
      'agentPhone',
      'name',
      'revenue',
      'naicsCode',
      'dunsNumber',
      'address1',
      'address2',
      'address3',
      'city',
      'state',
      'zipCode',
      'noOfEmployeesAll',
      'surplusOnly',
      'surplusAvailable',
      'yearEstablished',
      'industryClass',
    ]),
    agencies: account.agencies[0],
    industry: account.primaryIndustry,
    percentagePrimaryNaics: 1,
  };
}

export function constructPresetSecurityQuestionsPayload(
  securityQuestions: P250SecurityQuestions
) {
  return {
    isVerifyingBankAccount: securityQuestions.isVerifyingBankAccounts,
    isAuthenticatingFundTransferRequest:
      securityQuestions.isAuthenticatingFundTransferRequests,
    isPreventingUnauthorizedWireTransfers:
      securityQuestions.isPreventingUnauthorizedWireTransfers,
    dmzSeparation: securityQuestions.dmzSeparation,
    thirdPartySecurityAgreement: securityQuestions.thirdPartySecurityAgreement,
    testedFullFailover: securityQuestions.testedFullFailover,
    patchingFrequency: securityQuestions.patchingFrequency,
    backupFrequency: securityQuestions.backupFrequency,
    mfaAuthentication: securityQuestions.mfaAuthentication,
    mfaEmail: securityQuestions.mfaEmail,
    mfaCloudDeployments: securityQuestions.mfaCloudDeployments,
    mfaMissionCriticalSystems: securityQuestions.mfaMissionCriticalSystems,
    mfaRemoteAccess: securityQuestions.mfaRemoteAccess,
  };
}

export function determineAnsweredP250SecurityQuestions(
  securityQuestions: P250SecurityQuestions
) {
  const {
    dmzSeparation,
    thirdPartySecurityAgreement,
    isVerifyingBankAccounts,
    isAuthenticatingFundTransferRequests,
    isPreventingUnauthorizedWireTransfers,
    backupFrequency,
    testedFullFailover,
  } = securityQuestions;
  // answereds
  const contingentSystemFailureAnswered =
    !_.isUndefined(dmzSeparation) &&
    !_.isUndefined(thirdPartySecurityAgreement);

  const cyberCrimeLossAnswered =
    !_.isUndefined(isVerifyingBankAccounts) &&
    !_.isUndefined(isAuthenticatingFundTransferRequests) &&
    !_.isUndefined(isPreventingUnauthorizedWireTransfers);

  const fullSystemFailureAnswered =
    !_.isUndefined(backupFrequency) &&
    !_.isUndefined(dmzSeparation) &&
    !_.isUndefined(testedFullFailover);

  return {
    contingentSystemFailureAnswered,
    cyberCrimeLossAnswered,
    fullSystemFailureAnswered,
  };
}
