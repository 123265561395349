import React from 'react';
import _ from 'lodash';

import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import {
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  ListItem,
  Box,
  IconButton,
  List,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import CbButton from '../../../../../components/Buttons/CbButton';
import {
  SectionTitle,
  Select,
} from '../../../../../agencies/CowbellAddAgencyModalV2/helpers';
import { FormGridBuilder } from '../../../../../components/forms/FormGridBuilder';
import {
  getContactLabel,
  getOptionsFromContacts,
  useTerritoryContacts,
} from './renewal-submissions.utils';
import {
  createTerritoryContact,
  deleteTerritoryContact,
} from '../../../../../api/submissions/renewal-submissions.api';
import {
  AutoCompleteBase,
  getOptionLabel,
} from '../../../../../components/inputs';
import { TERRITORY_CONTACT_QUERY_KEY } from './renewal-submissions.constants';
import { getUsers } from '../../../../../api/users/users.api';
import Showable from '../../../../../components/Showable';

const schema = Yup.object().shape({
  role: Yup.string().required().label('Role'),
  contact: Yup.object().required().label('Contact'),
});

const TerritoryContactCRUDModal = ({ data, ...props }) => {
  const { handleSubmit, ...formMethods } = useForm({
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (formData) => {
    const { role, contact } = formData;
    const columnName = role === 'RAE' ? 'renewalAeEmail' : 'renewalUwEmail';
    createTerritoryContact({
      data: {
        role,
        ..._.pick(contact, ['firstName', 'lastName', 'email']),
      },
    })
      .then(() => {
        queryClient.invalidateQueries(TERRITORY_CONTACT_QUERY_KEY);
        queryClient.invalidateQueries(['select-options', columnName]);
        enqueueSnackbar('Territory Contact created successfully', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(
          'A Territory Contact record with that email already exists',
          { variant: 'error' }
        );
      });
  };

  const { data: renewalAes = [] } = useTerritoryContacts({ role: 'RAE' });
  const { data: renewalUws = [] } = useTerritoryContacts({ role: 'RUW' });

  const formConfig = deriveFormConfig({
    formMethods,
    isRuwEnabled: data.isRuwEnabled,
  });

  return (
    <>
      <DialogContent style={{ padding: '3rem 4rem' }}>
        <SectionTitle>Edit Territory Contacts</SectionTitle>
        <Divider style={{ marginBottom: '1.2rem' }} />
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={6}>
            <h3 style={{ padding: '0 0 1rem', margin: 0 }}>Renewal AEs</h3>
            <List style={{ maxHeight: '20rem', overflow: 'auto' }}>
              {renewalAes.map((contact) => (
                <TerritoryContactListItem contact={contact} key={contact.id} />
              ))}
            </List>
          </Grid>
          <Showable show={data.isRuwEnabled}>
            <Grid item xs={6}>
              <h3 style={{ padding: '0 0 1rem', margin: 0 }}>Renewal UWs</h3>
              <List style={{ maxHeight: '20rem', overflow: 'auto' }}>
                {renewalUws.map((contact) => (
                  <TerritoryContactListItem
                    contact={contact}
                    key={contact.id}
                  />
                ))}
              </List>
            </Grid>
          </Showable>
        </Grid>
        <br />
        <SectionTitle>Add New Territory Contact</SectionTitle>
        <Divider style={{ marginBottom: '1.2rem' }} />
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGridBuilder formConfig={formConfig} />
            <Box display="flex" justifyContent="flex-end">
              <CbButton styleName="ctaButton" type="submit">
                Add Contact
              </CbButton>
            </Box>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={props.close}>
          Done
        </CbButton>
      </DialogActions>
    </>
  );
};

export const TerritoryContactCRUDModalConfig = {
  TerritoryContactCRUDModal: {
    component: TerritoryContactCRUDModal,
    config: {
      maxWidth: 'md',
      title: 'Manage Territory Contacts',
    },
  },
};

// helpers
const deriveFormConfig = ({ formMethods, isRuwEnabled }) => {
  const role = formMethods.watch('role');
  return [
    {
      name: 'role',
      label: 'Role',
      component: Select,
      options: [
        { label: 'RAE', value: 'RAE' },
        ...(isRuwEnabled ? [{ label: 'RUW', value: 'RUW' }] : []),
      ],
      gridItemProps: { xs: 4 },
    },
    {
      name: 'contact',
      label: 'Contact',
      gridItemProps: { xs: 8 },
      component: ContactAutocomplete,
      disabled: !role,
      role,
      formMethods,
    },
  ];
};

const TerritoryContactListItem = ({ contact }) => {
  const classes = useListItemStyles();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const columnName =
    contact.role === 'RAE' ? 'renewalAeEmail' : 'renewalUwEmail';

  const handleDeleteContact = () => {
    deleteTerritoryContact(contact.id)
      .then(() => {
        queryClient.invalidateQueries(TERRITORY_CONTACT_QUERY_KEY);
        queryClient.invalidateQueries(['select-options', columnName]);
        enqueueSnackbar('Territory Contact deleted successfully', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(
          'There was a problem deleting that record. Please try again',
          { variant: 'error' }
        );
      });
  };

  const contactLabel = getContactLabel(contact);

  return (
    <ListItem className={classes.listItem}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box>{contactLabel}</Box>
        <IconButton
          size="small"
          onClick={handleDeleteContact}
          style={{ color: 'inherit' }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </ListItem>
  );
};

const useListItemStyles = makeStyles(({ palette }) => ({
  listItem: {
    color: palette.text.secondary,
    '&:hover': {
      backgroundColor: palette.background.modal,
      color: palette.primary.contrastText,
    },
  },
}));

export const ContactAutocomplete = ({ role, formMethods, ...props }) => {
  const [search, setSearchTerm] = React.useState('');

  const { data: territoryContactOptions = [] } = useQuery({
    queryKey: ['create-territory-contact:options', role, search],
    queryFn: () => getUsers({ params: { search } }),
    select: (resp) => getOptionsFromContacts(resp.data.data),
  });

  const onTypeahead = _.debounce((e, value) => {
    setSearchTerm(value);
  }, 300);

  const onSelect = (e, option) => {
    formMethods.setValue('contact', option.data);
    formMethods.clearErrors();
  };

  return (
    <AutoCompleteBase
      autoHighlight
      getOptionLabel={getOptionLabel}
      fullWidth
      options={territoryContactOptions}
      onInputChange={onTypeahead}
      onChange={onSelect}
      style={{ marginBottom: '1rem' }}
      error={_.get(formMethods, 'formState.errors.contact.message')}
      {...props}
    />
  );
};
