import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import CbButton from '../../Buttons/CbButton';

import { updateQuoteCommission } from '../../../api';
import { withFormController } from '../../hocs/forms';
import type { ProductType } from '../../../types';
import TextFieldBase from '../../inputs/text-fields/base/TextFieldBase';
import { useAPIErrorHandler } from '../../hooks/useAPIErrorHandler';
import { COWBELL_BINDERS } from '../../tables/table_constants';

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  commission: Yup.number()
    .positive('${label} must be greater than 0')
    .max(100)
    .label('Commission')
    .required()
    .typeError('Commission is a required field'),
});

interface UpdateCommissionModalProps {
  data: {
    quoteId: string;
    commission: number;
    productType: ProductType;
  };
  close: () => void;
}

export const UpdateCommissionModal = ({
  data: modalProps,
  close,
}: UpdateCommissionModalProps) => {
  const { quoteId, commission } = modalProps;
  const { enqueueSnackbar } = useSnackbar();
  const handleAPIError = useAPIErrorHandler();
  const queryClient = useQueryClient();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      quoteId,
      commission,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (formValues: FormValues) => {
    return updateQuoteCommission(quoteId, {
      data: formValues,
      params: {
        productType: modalProps.productType,
      },
    })
      .then(() => {
        enqueueSnackbar(
          'Commission updated Successfully! The related documents will be updated in about 5 minutes',
          {
            variant: 'success',
          }
        );
        queryClient.invalidateQueries([COWBELL_BINDERS]);
        close();
      })
      .catch(handleAPIError('Commission update failed!'));
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <DialogContent className="paf">
            <DialogContentText>
              Updating the commission percentage will change the amount the
              agency will receive for this quote.
            </DialogContentText>
            <Box width="65%" margin="0 auto">
              <TextField
                label="Commission Percentage (%)"
                name="commission"
                type="number"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <CbButton onClick={close} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton
              type="submit"
              styleName="ctaButton"
              loading={formMethods.formState.isSubmitting}
              disabled={formMethods.formState.isSubmitting}
            >
              Update
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    </>
  );
};

type FormValues = {
  commission: number;
  quoteId: string;
};

export const UpdateCommissionModalConfig = {
  UpdateCommissionModal: {
    component: UpdateCommissionModal,
    config: {
      fullWidth: true,
      title: 'Update Commission',
    },
  },
};
