import { Box } from '@mui/material';
import { Skeleton as SkeletonBase } from '@mui/lab';
import _ from 'lodash';

const Skeleton = ({ style = {}, ...props }) => {
  const mergedStyles = { borderRadius: 5, marginTop: '1rem', ...style };
  return (
    <SkeletonBase
      variant="rectangular"
      animation="wave"
      height="1rem"
      style={mergedStyles}
      {...props}
    />
  );
};

export const TypeaheadLoadingSkeleton = ({
  numOfGroups = 3,
}: {
  numOfGroups?: number;
}) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="flex-start"
      alignItems="flex-start"
      flexDirection="column"
    >
      {_.times(numOfGroups, () => {
        return (
          <>
            <Skeleton
              width="10%"
              style={{ marginLeft: '2rem', marginTop: '2rem' }}
            />
            <Skeleton width="50%" style={{ marginLeft: '4rem' }} />
            <Skeleton width="50%" style={{ marginLeft: '4rem' }} />
            <Skeleton width="50%" style={{ marginLeft: '4rem' }} />
          </>
        );
      })}
    </Box>
  );
};
