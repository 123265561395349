import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { DialogContent } from '@mui/material';
import { withStyles } from '@mui/styles';

import { PubSub } from '../../utils/eventUtils';
import { deleteDa } from './da.service';
import { toggleModalDirect } from '../../utils/storeUtils';

const DaDeleteConfirm = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id, name } = data;
  useEffect(() => {
    const sub = PubSub.subscribe('foot-button-one', (eventData) => {
      if (eventData) {
        onSubmit();
      }
    });

    return () => {
      sub.remove();
    };
  });

  const onSubmit = () => {
    deleteDa({ id })
      .then(() => {
        enqueueSnackbar('Successfully removed the DA', { variant: 'success' });
        PubSub.publish('table-refetch', 'da');
        toggleModalDirect('DaDeleteConfirm', false);
      })
      .catch((error) => {
        console.error(error);
        toggleModalDirect('DaDeleteConfirm', false);
      });
  };

  return (
    <DialogContent>
      <section className="modal-title">
        {`Are you sure you want to delete '${name}'`}?
      </section>
    </DialogContent>
  );
};

const styles = ({ palette }) => {
  return {
    fieldContainer: {
      width: '100%',
      marginBottom: '0.5rem',
      fontSize: '1.33rem',
      lineHeight: 1.13,
      textAlign: 'center',
      color: palette.text.primary,
    },
    container: {
      width: '40rem',
      height: 'fit-content',
      margin: 'auto',
      textAlign: 'left',
    },
  };
};

export default withStyles(styles)(DaDeleteConfirm);
