import React from 'react';

const SVG = ({
  id = 'CSVIcon',
  style = {},
  // fill = '#fff',
  className = '',
  viewBox = '0 0 20 30',
  title = 'CSVIcon',
}) => (
  <svg
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
        .cls-1{fill:#fff}.cls-2{fill:#848280}
        `}
      </style>
    </defs>
    <title>{title}</title>
    <g id="Layer_1">
      <path id="Rectangle_3471" fill="none" d="M0 0H28.408V29.851H0z" />
      <path
        id="Path_5354"
        fill="#fff"
        d="M16.4.25H2.123a.672.672 0 0 0-.7.7v27.708a.7.7 0 0 0 .7.821H22.77a.7.7 0 0 0 .721-.7V7.29a.7.7 0 0 0-.2-.5L16.9.449a.746.746 0 0 0-.5-.199z"
        transform="translate(2.106 .372)"
      />
      <path
        id="Path_5355"
        fill="#848280"
        d="M23.578 6.727L17.235.383a1.07 1.07 0 0 0-.747-.323H2.21a1.1 1.1 0 0 0-1.07 1.07v27.711a1.1 1.1 0 0 0 1.095 1.07h20.572a1.07 1.07 0 0 0 1.07-1.07V7.373a1.045 1.045 0 0 0-.3-.647zm-.771 22.388H2.21a.348.348 0 0 1-.348-.348V1.155A.348.348 0 0 1 2.21.806h13.83v6.02A1.1 1.1 0 0 0 17.135 7.9h6.045v20.941a.348.348 0 0 1-.348.348z"
        transform="translate(1.696 .089)"
      />
      <path
        id="Path_5375"
        fill="#59cbf5"
        d="M18.758 13.142V3.64H2.44v9.5z"
        transform="translate(3.63 5.415)"
      />
      <path
        id="Path_5376"
        fill="#3fb57c"
        d="M9.79 5.83L7.874 7.721 5.71 9.885h8.134z"
        transform="translate(8.494 8.672)"
      />
      <path
        id="Path_5377"
        fill="#ffea2f"
        d="M7.483 4.16a1.368 1.368 0 0 1 1.343 1.368 1.343 1.343 0 0 1-1.343 1.344 1.269 1.269 0 0 1-.945-.4 1.343 1.343 0 0 1 .945-2.312z"
        transform="translate(9.109 6.188)"
      />
      <path
        id="Path_5378"
        fill="#309e6d"
        d="M12.739 9.342L10.5 6.9 8.535 4.74 2.44 11.506h12.214z"
        transform="translate(3.63 7.051)"
      />
      <path
        id="Path_5379"
        d="M11.326 11.768v2.712h-1.095v-.4a.945.945 0 0 1-.672.348 1.119 1.119 0 0 1-.672 0 2.786 2.786 0 0 1-1.095-.2 3.408 3.408 0 0 1-.746-.547 2.09 2.09 0 0 1-.4-.821 3.955 3.955 0 0 1-.149-.945 4.627 4.627 0 0 1 .149-1.07 1.94 1.94 0 0 1 .4-.9 1.692 1.692 0 0 1 .821-.6 2.935 2.935 0 0 1 2.488 0c.2.124.323.274.522.4s.274.423.423.7l-1.07.522a2.612 2.612 0 0 0-.423-.547.9.9 0 0 0-.672-.2.821.821 0 0 0-.622.2 1.318 1.318 0 0 0-.323.473 1.766 1.766 0 0 0-.149.6v.547a1.517 1.517 0 0 0 0 .547 1.269 1.269 0 0 0 .149.547 1 1 0 0 0 .323.4.821.821 0 0 0 .622.2.97.97 0 0 0 .8-.274c.224-.124.274-.323.348-.6H9.211v-.945h2.114z"
        className="cls-8"
        transform="translate(9.669 13.505)"
      />
      <path
        id="Path_5380"
        d="M8.854 9.971a1.443 1.443 0 0 1 0 .746 1.517 1.517 0 0 1-.522 1.219 2.488 2.488 0 0 1-1.567.423h-.821v2.09H4.65V9.15h2.985a2.488 2.488 0 0 1 .672.274.945.945 0 0 1 .547.547zm-1.095.746a.9.9 0 0 0 0-.348.241.241 0 0 0-.149-.2 1.756 1.756 0 0 0-.746-.124h-.92v1.219h1.417l.2-.2c.149-.148.198-.198.198-.347z"
        className="cls-8"
        transform="translate(6.917 13.611)"
      />
      <path
        id="Path_5381"
        d="M6.085 9.13v3.781a1.318 1.318 0 0 1-.473 1.169 1.642 1.642 0 0 1-1.219.4 2.065 2.065 0 0 1-.9-.2 2.886 2.886 0 0 1-.5-.5l.871-.6.2.274a.721.721 0 0 0 .348 0 .2.2 0 0 0 .2 0l.149-.124v-4.2z"
        className="cls-8"
        transform="translate(4.463 13.581)"
      />
    </g>
  </svg>
);

export default SVG;
