export const normalizedAverageDataValue = (
  avg: number,
  min: number,
  max: number
) => {
  if (avg > max) {
    return 100;
  }
  if (avg < min) {
    return 0;
  }
  return ((avg - min) / (max - min)) * 100;
};

export const normalizedFactorValue = (
  factor: number,
  min: number,
  max: number
) => {
  if (factor < min) {
    return 0;
  }
  if (factor > max) {
    return 100;
  }
  return ((factor - min) / (max - min)) * 100;
};

export const factorValueStyle = (
  avg: number,
  factor: number,
  config: { colors: { cowbellAccent: string; textRed: string } }
) => {
  if (factor < avg) {
    return {
      color: config.colors.textRed,
    };
  }
  return {
    color: config.colors.cowbellAccent,
  };
};
