import React from 'react';
import _ from 'lodash';

// components
import { Box, Typography } from '@mui/material';
import type { UseQueryResult } from '@tanstack/react-query';
import {
  AgencyDashboardSearchGroupingHeader as GroupingHeader,
  AgencyDashboardSearchListItemBase as ListItemBase,
} from '../global-search/agency-dashboard-search.components';

// utils
import type { Quote } from '../../../../types/quotes/quote.entity';
import { TypeaheadLoadingSkeleton } from '../global-search/TypeaheadLoadingSkeleton';
import type { Binder } from '../../../accounts/summary/types/binder.types';
import { BOUND_STATUS } from '../../../_statics/policy.statics';
import type {
  BindQuoteSearchEntity,
  IBinder,
  IQuote,
} from './bind-quote.utils';

export interface BindQuoteSearchListingProps {
  onChange: (value: string) => void;
  queryCache: UseQueryResult<any>;
  onSelect: (entity: BindQuoteSearchEntity) => void;
}

export const BindQuoteSearchListing = ({
  onChange,
  onSelect,
  queryCache,
}: BindQuoteSearchListingProps) => {
  const { data, isFetching } = queryCache;
  const items = data ?? [];

  const handleSelect = (quote: BindQuoteSearchEntity) => {
    onSelect(quote);
    onChange(quote.companyName);
  };

  if (isFetching) {
    return <TypeaheadLoadingSkeleton numOfGroups={1} />;
  }

  if (!items.length) {
    return <BindQuoteSearchNullResult />;
  }

  const itemsGroupedByCompanyName = _.uniqBy<IQuote | IBinder>(
    items,
    'companyName'
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ maxHeight: '13rem', overflow: 'auto', margin: '0.4rem' }}
    >
      {itemsGroupedByCompanyName.map((item: BindQuoteSearchEntity) => (
        <BindQuoteListItem key={item.id} item={item} onClick={handleSelect} />
      ))}
    </Box>
  );
};

interface BindQuoteListItemProps {
  item: BindQuoteSearchEntity;
  onClick: (item: BindQuoteSearchEntity) => void;
}

const BindQuoteListItem = ({ item, onClick }: BindQuoteListItemProps) => {
  return (
    <ListItemBase>
      <Typography
        variant="body1"
        style={{ marginLeft: '2rem', fontWeight: 'inherit' }}
        onClick={() => onClick(item)}
      >
        {item.companyName}
      </Typography>
    </ListItemBase>
  );
};

const BindQuoteSearchNullResult = () => (
  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
    <Typography variant="h6" style={{ textAlign: 'center' }}>
      No bindable quotes available for this client
    </Typography>
  </Box>
);
