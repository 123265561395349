import { ServiceHandlerFactory } from '../../../utils/serviceUtils';
import { ServiceHandler } from '../../../utils';

export const getAgencyLocations = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/location/search',
  },
  isCancelable: true,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'isLocationActive':
          case 'modified':
            if (orderBy && order) {
              return `${orderBy},${order}`;
            }
            break;

          default:
            return orderBy ? `${orderBy}.keyword,${order}` : null;
        }
      },
    },
  ],
});

export const createAgencyLocation = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/location',
    method: 'post',
  },
});

export const getAgencyStats = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/location/stats',
    method: 'get',
  },
});

export const updateAgencyLocation = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/location/update',
    method: 'put',
  },
});

export const deleteAgencyLocation = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/location/:locationIds',
    method: 'delete',
  },
});

export const BulkDelete = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/location?locationIds=:locationIds',
    method: 'delete',
  },
});

export const BulkActive = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/location/active/:activation',
    method: 'put',
  },
});

export const BulkUpdateTAMBDS = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/location/tambds ',
    method: 'put',
  },
});

export const getAgencyTeamLocationsMapping = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/locations/mappings',
    method: 'get',
  },
});

export const updateAgencyTeamLocationsMapping = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/locations/mappings',
    method: 'post',
  },
});

export const deleteAgencyTeamLocationsMapping = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/locations/mappings',
    method: 'delete',
  },
});

export const searchLocationsByTeam = ServiceHandler({
  url: '/api/auth/v1/locations/mappings/team/mapped',
});

export const getUnmappedLocations = ServiceHandler({
  url: '/api/auth/v1/locations/mappings/team/unmapped',
});
