import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import type { SyntheticEvent } from 'react';
import type { WithChildrenProps } from '../../../../types/components/common';

interface PrimaryActionButtonProps extends WithChildrenProps {
  onClick: (event: SyntheticEvent, reason?: string) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const PrimaryActionButton = ({
  children,
  onClick,
  disabled,
  ...props
}: PrimaryActionButtonProps) => {
  const classes = useStyles();

  const handleClick = (event: SyntheticEvent, reason?: string) => {
    event.stopPropagation();
    onClick(event, reason);
  };

  return (
    <Button
      disableRipple
      variant="contained"
      onClick={handleClick}
      className={classes.button}
      data-qa={`TABLE_ACTION_BUTTON:${children}`}
      disabled={disabled}
      {...props}
    >
      <Typography variant="body1" className={classes.text}>
        {children}
      </Typography>
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'transparent',
    minWidth: 'unset',
    padding: '0rem 0.4rem',
    color: theme.agencyDash.interactable,
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: 'transparent',
    },
    transition: 'color 150ms, background-color 150ms',
  },
  text: {
    fontWeight: 600,
    fontSize: '1.1rem',
    [theme.breakpoints.up('xl')]: { fontSize: '1rem' },
  },
}));
