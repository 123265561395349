import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useScopes } from '../../hooks/useScopes';
import { useAuth } from '../../hooks/useAuth';

/*
allows the component to show according to the permissions available in the token.
allow - for single permission; eg: {accounts:'enter'} or {accounts:['enter':'view']}
allowEither - for permissions that are either or, we need an array to make this work; eg: [{role:'admin'},{accounts:['enter','view']}] (OR)
allowIfAll -  for permissions that work if includes all, we need an array to make this work; eg: [{role:'admin'},{accounts:['enter','view']}] (AND)
*/
const ProtectedComponent = ({
  allow,
  allowEither,
  allowIfAll,
  allowPersona,
  children,
  show = true,
}) => {
  const { scopes, persona } = useReducedAuth();

  if (!show) {
    return null;
  }

  if (!_.isUndefined(allowPersona) && allowPersona !== persona) {
    return null;
  }

  if (typeof allow === 'string' && allow === 'any') {
    return <>{children}</>;
  }

  const allowPermissions = (permission, userPermissions) => {
    if (_.isArray(userPermissions)) {
      if (_.isString(permission)) {
        if (userPermissions.includes(permission)) {
          return true;
        }
        return false;
      }

      if (_.isArray(permission)) {
        let showChildren = false;
        _.forEach(permission, (value) => {
          if (userPermissions.includes(value)) {
            showChildren = true;
            return false;
          }
        });
        if (showChildren) {
          return true;
        }
      }
    }
  };

  let sendChildren = false;
  if (allowEither || allowIfAll) {
    // OR
    const array = [];
    if (_.isArray(allowEither)) {
      _.forEach(allowEither, (dict) => {
        _.forEach(dict, (value, key) => {
          const permission = value; // yields 'view' or 'manage'
          const userPermissions = _.get(scopes, key); // yields an array
          const result = allowPermissions(permission, userPermissions);
          array.push(result);
        });
      });
      sendChildren = array.includes(true);
    }
    // AND
    if (_.isArray(allowIfAll)) {
      const arrayKeys = [];
      const arrayVals = [];
      let userPermissions = [];
      _.forEach(allowIfAll, (dict) => {
        const keys = _.keys(dict)[0];
        arrayKeys.push(keys);
        arrayVals.push(_.values(dict)[0]);
        userPermissions = userPermissions.concat(_.get(scopes, keys)); // yields an array
      });
      const permission = arrayVals; // yields 'view' or 'manage'
      sendChildren = _.difference(permission, userPermissions).length === 0;
    }
  } else {
    // for multiple scopes
    const allowKeys = Object.keys(allow);
    for (let i = allowKeys.length; i >= 0; i -= 1) {
      const permission = allow[allowKeys[i]]; // yields 'view' or 'manage'
      const userPermissions = _.get(scopes, allowKeys[i]); // yields an array
      sendChildren = allowPermissions(permission, userPermissions);
    }
  }
  if (sendChildren) {
    return <>{children}</>;
  }
  return null;
};

const useReducedAuth = () => {
  const scopes = useScopes();
  const reducedScopes = React.useMemo(
    () => reduceListedScopesToKeyed(scopes),
    [scopes]
  );

  const { accountType } = useAuth();
  const persona = `${accountType?.toLowerCase()}User`;

  return {
    scopes: reducedScopes,
    persona,
  };
};

// Reduces scopes in the listed format to keyed format
// ["claims:view", "claims:manage", "notes:view"] -> { claims: ["view, manage"], notes: ["view"] }
function reduceListedScopesToKeyed(rawScopes) {
  return rawScopes.reduce((acc, curr) => {
    const [scopeName, scopePerm] = curr.split(':');
    return scopeName && scopePerm
      ? {
          ...acc,
          [scopeName]: [...(acc[scopeName] || []), scopePerm],
        }
      : acc;
  }, {});
}

ProtectedComponent.propTypes = {
  allow: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ProtectedComponent.defaultProps = {
  allow: {},
};

export default ProtectedComponent;
