import * as Yup from 'yup';

export const getAgencyInformationSchema = (languageFormSchema) => {
  return Yup.object().shape({
    surplusLinesBrokerFirstName: Yup.string()
      .label('Surplus Lines Broker First Name')
      .required(),
    surplusLinesBrokerLastName: Yup.string()
      .label('Surplus Lines Broker Last Name')
      .required(),
    agencyMailingZipCode: languageFormSchema.zipCode,
    surplusLicenseNumber: Yup.string()
      .required('Broker License Number is required')
      .matches(
        /^[0-9aA-zZ\s]+$/,
        'Only alphabets and numbers are allowed for this field '
      ),
    surplusLinesBrokerEmail: Yup.string()
      .required()
      .label('Surplus Lines Broker Email')
      .email(),
  });
};

export const lossRunsSchema = Yup.object({
  yearsOfPreviousCoverage: Yup.number()
    .typeError('This field is required')
    .integer('Value must be a whole number')
    .min(1, 'Value can only be between 1 and 4')
    .max(4, 'Value can only be between 1 and 4'),
});

export const signedDocumentConfirmationSchema = (files) => {
  let signedDocOnFields = {};
  for (const index of files?.keys()) {
    signedDocOnFields = {
      ...signedDocOnFields,
      [`docSignedOn-${index}`]: Yup.string().label('Date signed').required(),
    };
  }
  return Yup.object({
    signeeFullName: Yup.string().label('Signature Holder Name').required(),
    signeeTitle: Yup.string().label('Signature Holder Title').required(),
    ...signedDocOnFields,
  });
};
