import * as yup from 'yup';

/**
 * @name fein
 * @description Takes a phone in the form of a string and converts it to a number e.g. (555) 555 - 5555 => 5555555555
 */
yup.addMethod(yup.string, 'fein', function () {
  return this.transform(function (value, originalValue) {
    let nextValue = value;

    if (originalValue) {
      nextValue = originalValue.match(/[0-9]+/g);
      return value ? nextValue.join('') : '';
    }

    return undefined;
  });
});
