import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import { useQueryClient } from '@tanstack/react-query';
// material ui
import { DialogActions, DialogContent, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
// componenets
import CbButton from '../../Buttons/CbButton';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { withFormController } from '../../hocs/withFormController';
// actions
import { undeclineAccount } from '../../../console/agencies/_services/accounts.service';
// constants
import { COWBELL_ACCOUNTS } from '../../tables/table_constants';

const TextField = withFormController(TextFieldBase);

const useStyles = makeStyles(({ config, palette }) => {
  return {
    header: {
      fontSize: config.textSizes.primer,
      color: palette.primary.contrastText,
      paddingBottom: '1.66rem',
      '& p': {
        margin: 0,
      },
    },
  };
});

const schema = Yup.object().shape({
  reason: Yup.string().required().label('Reason'),
});

export const UnDeclineAccountConfirm = ({ close, data }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState('');

  const queryClient = useQueryClient();

  const {
    handleSubmit,
    formState: { isSubmitting },
    ...methods
  } = useForm({
    defaultValues: {
      reason: '',
    },
    validationSchema: schema,
  });

  const onSubmit = (formData) => {
    const payload = {
      productType: data.product,
      reason: formData.reason,
    };

    return undeclineAccount({ id: data.id }, payload)
      .then(() => {
        close();
        enqueueSnackbar('Account UnDeclined Successfully!', {
          variant: 'success',
        });
        queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong.Please try again later'
            )
          )
        );
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2rem 5rem' }}>
          <div className={classes.header}>
            <p>Are you sure you want to un-decline the account? </p>
            <p>Please provide a reason</p>
          </div>

          <TextField
            label="Reason"
            type="text"
            name="reason"
            multiline
            required
            minRows={4}
          />

          {error && (
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="UnDecline"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

export const UnDeclineAccountConfirmConfig = {
  UnDeclineAccountConfirm: {
    component: UnDeclineAccountConfirm,
    config: {
      fullWidth: false,
      maxWidth: 'md',
      inheritTheme: 'dark',
      title: 'Un-decline Account',
    },
  },
};
