import React from 'react';
import { useFormContext } from 'react-hook-form';

// mui
import { makeStyles } from '@mui/styles';
// components
import CheckBoxBase from '../components/inputs/Checkbox';

export const CoverageColumnSelection = ({ coverage, disabled }) => {
  const { watch, setValue, getValues, register } = useFormContext();
  const formLabelClasses = useFormLabelStyles();
  const checkboxClasses = useCheckboxStyles();

  const values = getValues();

  watch(values[coverage.name]);

  const handleChange = (event, checked) => {
    setValue(coverage.name, checked);
  };

  return (
    <CheckBoxBase
      name={coverage.name}
      label={coverage.name}
      checked={values[coverage.name]}
      classes={checkboxClasses}
      {...register(coverage.name)}
      onChange={handleChange}
      formLabelProps={{ classes: formLabelClasses }}
      disabled={disabled}
    />
  );
};

const useFormLabelStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.text.contrastText,
  },
}));

const useCheckboxStyles = makeStyles(({ palette }) => ({
  checked: {
    color: `${palette.common.cowbellBlue} !important`,
  },
}));
