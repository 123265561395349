import React from 'react';
import _ from 'lodash';

import { useForm } from 'react-hook-form-4';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

// mui
import { DialogActions, DialogContent } from '@mui/material';

// platform helpers
import { delayedEvent } from '../../../../utils/eventUtils';
import { COWBELL_POLICY } from '../../../tables/table_constants';

// service
import { stopNoc } from '../../../../api/NOCService';

// components
import CBButton from '../../../Buttons/CbButton';

export const StopNOCModal = ({ close, data }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, ...methods } = useForm();

  const onSubmit = () => {
    const policyId = _.get(data, 'id');
    const payload = {
      productType: _.get(data, 'product'), // P100 OR P250
    };

    stopNoc({ policyId }, payload)
      .then(() => {
        queryClient.invalidateQueries([COWBELL_POLICY]);
        enqueueSnackbar('Notice of Cancellation has been stopped.', {
          variant: 'success',
        });
        close();
      })
      .catch((error) => {
        const message = _.get(
          error.response,
          'data.message',
          _.get(
            error.response,
            'data',
            'Not able to stop Notice of Cancellation.'
          )
        );
        enqueueSnackbar(message, { variant: 'error' });
        close();
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent className="modal-title" style={{ padding: '2rem 5rem' }}>
        Are you sure you want to stop the NOC Process for {data.companyName},
        Policy Number{data.policyNumber}?
      </DialogContent>
      <DialogActions>
        <CBButton styleName="cancel" buttonText="Cancel" action={close} />
        <CBButton
          type="submit"
          disabled={_.get(methods, 'formState.isSubmitting')}
          styleName="ctaButton"
          buttonText="Stop"
        />
      </DialogActions>
    </form>
  );
};

export const StopNOCConfig = {
  StopNOCModal: {
    component: StopNOCModal,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
    },
  },
};
