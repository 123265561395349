import { IconButton } from '@mui/material';
import { Visibility as Eye } from '@mui/icons-material';

const EyeButton = ({ onClick, ...props }) => {
  return (
    <IconButton
      size="small"
      style={{ marginLeft: '0.5rem' }}
      onClick={onClick}
      {...props}
    >
      <Eye />
    </IconButton>
  );
};

export default EyeButton;
