import React from 'react';
import * as Ramda from 'ramda';
import * as Yup from 'yup';

import { FormProvider, useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import {
  Box,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from '@mui/material';

import { Warning } from '@mui/icons-material';
import type { PolicyTableInfoDto } from '../../types/policy/tables/policy-table-info.dto';
import { type ProductType, ProductTypes } from '../../types';
import type { SelectOption } from '../../types/components/common';
import { useAPIErrorHandler } from '../../components/hooks/useAPIErrorHandler';
import {
  fetchPolicyDetailsP100,
  updatePolicyBilling,
} from '../../api/policies.api';
import { COWBELL_POLICY } from '../../components/tables/table_constants';
import { getData } from '../../utils/functional/fp';
import { fetch250PolicyPageDetails } from '../PolicyService';
import CbButton from '../../components/Buttons/CbButton';
import { withFormController } from '../../components/hocs/forms';
import TextFieldBase from '../../components/inputs/text-fields/base/TextFieldBase';
import { SurplusBrokerTypeAhead } from '../../console/payments/billing/billing-compliance/AgentSurplusLicenseForm';
import { SimpleSelect } from '../../components/inputs';
import { UsStatesFull } from '../../utils/USState';
import FormWatcher from '../../components/forms/FormWatcher';
import { Show } from '../../components/Show';

type AdminUpdateBillingModalProps = {
  close: () => void;
  data: {
    policy: PolicyTableInfoDto;
  };
};

export const AdminUpdateBillingModal = (
  props: AdminUpdateBillingModalProps
) => {
  const form = useBillingUpdateForm(
    props.data.policy.id,
    props.data.policy.product
  );

  const [selectedBroker, setSelectedBroker] =
    React.useState<SelectOption | null>(null);

  React.useEffect(() => {
    if (form.formMethods.formState.isSubmitSuccessful) {
      props.close();
    }
  }, [form.formMethods.formState, props]);

  if (form.isQueryLoading) {
    return (
      <Box className="align-center justify-center" paddingY="3rem">
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (form.isQueryError) {
    return (
      <Box className="align-center justify-center" paddingY="3rem">
        <Typography>An error has occurred...</Typography>
      </Box>
    );
  }

  const isPolicyP250 = props.data.policy.product === ProductTypes.p250;
  return (
    <FormProvider {...form.formMethods}>
      <form onSubmit={form.formMethods.handleSubmit(form.handlers.submit)}>
        <DialogContent style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
          <Grid container spacing={2}>
            <Grid container>
              <Show when={isPolicyP250}>
                <Grid item sm={12} style={{ marginBottom: '1rem' }}>
                  <Typography
                    className="contrast-text"
                    style={{ fontSize: '1.4rem' }}
                  >
                    Billing & Compliance Providers
                  </Typography>
                </Grid>
              </Show>

              <Grid item sm={6}>
                <FormControl variant="standard" component="fieldset">
                  <FormLabel component="legend" className="contrast-text">
                    Billing Provider
                  </FormLabel>

                  <ControlledRadioGroup name="isAgencyBilled">
                    <FormControlLabel
                      label="Direct Bill: Cowbell"
                      value="false"
                      control={<Radio color="secondary" />}
                    />

                    <FormControlLabel
                      label={`Agency Bill: ${form.data.policy.agencyName}`}
                      value="true"
                      control={<Radio color="secondary" />}
                    />
                  </ControlledRadioGroup>
                </FormControl>
              </Grid>

              <Grid item sm={6}>
                {isPolicyP250 && (
                  <FormControl variant="standard" component="fieldset">
                    <FormLabel component="legend" className="contrast-text">
                      Surplus Compliance Provider
                    </FormLabel>

                    <ControlledRadioGroup name="surplusCowbellInsurance">
                      <FormControlLabel
                        label="Cowbell"
                        value="true"
                        control={<Radio color="secondary" />}
                      />

                      <FormControlLabel
                        label={`Agency: ${form.data.policy.agencyName}`}
                        value="false"
                        control={<Radio color="secondary" />}
                      />
                    </ControlledRadioGroup>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            {isPolicyP250 && (
              <>
                <Divider style={{ width: '100%', margin: '1rem 0' }} />

                <Grid container spacing={1}>
                  <Grid item sm={12}>
                    <Typography className="contrast-text">
                      Agency Information
                    </Typography>
                  </Grid>

                  <Grid item sm={6}>
                    <ControlledTextField
                      label="Address"
                      name="surplusBrokerData.agencyMailingAddress1"
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <ControlledTextField
                      label="Address 2"
                      name="surplusBrokerData.agencyMailingAddress2"
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <ControlledTextField
                      label="City"
                      name="surplusBrokerData.agencyMailingCity"
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <ControlledSimpleSelect
                      label="State"
                      name="surplusBrokerData.agencyMailingState"
                      options={UsStatesFull}
                      InputLabelProps={{
                        indent: false,
                        style: { padding: '0 0 0.7rem 0' },
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <ControlledTextField
                      label="Postal Code"
                      name="surplusBrokerData.agencyMailingZipCode"
                    />
                  </Grid>
                </Grid>

                <Divider style={{ width: '100%', margin: '1rem 0' }} />

                <Grid container spacing={1}>
                  <Grid item sm={12}>
                    <Typography className="contrast-text">
                      Surplus Broker Information
                    </Typography>
                  </Grid>

                  <Grid item md={12} style={{ margin: '0.75rem 0' }}>
                    <SurplusBrokerTypeAhead
                      name="broker"
                      label="Surplus Lines Brokers on File"
                      placeholder="Search for broker by name"
                      fetchOnMount
                      ref={null}
                      value={selectedBroker}
                      onChange={(nextValue: SelectOption) => {
                        setSelectedBroker(nextValue);
                        form.handlers.brokerTypeAheadChange(nextValue);
                      }}
                      agencyId={props.data.policy.agencyId}
                      surplusLinesState={
                        form.data.policy.surplusBrokerData.surplusLinesState
                      }
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <ControlledTextField
                      label="First Name"
                      name="surplusBrokerData.surplusLinesBrokerFirstName"
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <ControlledTextField
                      label="Last Name"
                      name="surplusBrokerData.surplusLinesBrokerLastName"
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <ControlledSimpleSelect
                      label="State"
                      name="surplusBrokerData.surplusLinesState"
                      options={UsStatesFull}
                      InputLabelProps={{
                        indent: false,
                        style: { padding: '0 0 0.7rem 0' },
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <ControlledTextField
                      label="License Number"
                      name="surplusBrokerData.surplusLicenseNumber"
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <ControlledTextField
                      label="Email"
                      name="surplusBrokerData.surplusLinesBrokerEmail"
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item sm={12}>
              <Box className="align-center justify-center">
                <StyledWarningIcon style={{ marginRight: '0.2rem' }} />
                <Typography variant="caption" className="contrast-text">
                  Warning: This operation bypasses some business logic rules and
                  is meant for administrative action only.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton onClick={props.close} styleName="cancel">
            Cancel
          </CbButton>

          <FormWatcher>
            <CbButton
              styleName="ctaButton"
              type="submit"
              loading={form.formMethods.formState.isSubmitting}
              disabled={form.formMethods.formState.isSubmitting}
            >
              Submit
            </CbButton>
          </FormWatcher>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const ControlledRadioGroup = withFormController(RadioGroup);

export const AdminUpdateBillingModalConfig = {
  AdminUpdateBillingModal: {
    component: AdminUpdateBillingModal,
    config: {
      title: 'Update Billing',
      maxWidth: 'md',
    },
  },
};

const validStateValues = UsStatesFull.map((option) => option.value);

const formSchema = Yup.object().shape({
  isAgencyBilled: Yup.boolean().nullable().label('Billing Provider'),
  surplusCowbellInsurance: Yup.boolean()
    .nullable()
    .label('Compliance Provider'),
  surplusCompliance: Yup.string().nullable().label('Compliance Provider Name'),
  surplusBrokerData: Yup.object().shape({
    agencyMailingAddress1: Yup.string().nullable().label('Address'),
    agencyMailingAddress2: Yup.string().nullable().label('Address 2'),
    agencyMailingCity: Yup.string().nullable().label('City'),
    agencyMailingState: Yup.string().nullable().label('State'),
    agencyMailingZipCode: Yup.string().nullable().label('Postal Code'),
    surplusLicenseNumber: Yup.string().nullable().label('License Number'),
    surplusLinesState: Yup.string()
      .nullable()
      .oneOf(validStateValues)
      .label('State'),
    surplusLinesBrokerFirstName: Yup.string().nullable().label('First Name'),
    surplusLinesBrokerLastName: Yup.string().nullable().label('Last Name'),
    surplusLinesBrokerEmail: Yup.string().nullable().label('Email'),
  }),
});

const initialFormValues: FormValues = {
  isAgencyBilled: 'false',
  surplusCowbellInsurance: 'true',
  surplusCompliance: '',
  surplusBrokerData: {
    agencyMailingAddress1: '',
    agencyMailingAddress2: '',
    agencyMailingCity: '',
    agencyMailingState: '',
    agencyMailingZipCode: '',
    surplusLicenseNumber: '',
    surplusLinesState: '',
    surplusLinesBrokerFirstName: '',
    surplusLinesBrokerLastName: '',
    surplusLinesBrokerEmail: '',
  },
};

type FormValues = {
  isAgencyBilled: 'true' | 'false';
  // indicates if cowbell handles surplus compliance
  surplusCowbellInsurance: 'true' | 'false';
  // name of the party handling surplus compliance (if not cowbell)
  surplusCompliance: string;
  surplusBrokerData: {
    agencyMailingAddress1: string;
    agencyMailingAddress2: string;
    agencyMailingCity: string;
    agencyMailingState: string;
    agencyMailingZipCode: string;
    surplusLicenseNumber: string;
    surplusLinesState: string;
    surplusLinesBrokerFirstName: string;
    surplusLinesBrokerLastName: string;
    surplusLinesBrokerEmail: string;
  };
};

const usePolicyDetailsQuery = (policyId: string, product: ProductType) => {
  const handleAPIError = useAPIErrorHandler();
  const policyDetailsAPI =
    product == ProductTypes.p250
      ? fetch250PolicyPageDetails
      : fetchPolicyDetailsP100;
  const isP250OrP100 =
    product == ProductTypes.p100 || product == ProductTypes.p250;

  return useQuery({
    queryKey: ['policy-details', { id: policyId, product }],
    queryFn: () => policyDetailsAPI(policyId).then(getData),
    onError: handleAPIError('Unable to fetch policy details at this time'),
    enabled: isP250OrP100,
  });
};

const useDefaultFormValues = (policyDetails: Record<string, any> | undefined) =>
  React.useMemo<FormValues>(() => {
    if (policyDetails != undefined) {
      const hasSurplusBrokerData = !Ramda.isEmpty(
        policyDetails.surplusBrokerData
      );

      let transformedExistingBrokerData:
        | null
        | FormValues['surplusBrokerData'] = null;

      if (hasSurplusBrokerData) {
        transformedExistingBrokerData = {
          ...Ramda.omit(
            ['surplusLinesNumber'],
            policyDetails.surplusBrokerData
          ),
          surplusLicenseNumber:
            policyDetails.surplusBrokerData?.surplusLinesNumber,
        } as FormValues['surplusBrokerData'];
      }

      const isCowbellHandlingCompliance = Boolean(
        policyDetails.initialRequestData?.surplusCowbellInsurance
      );

      return {
        isAgencyBilled: policyDetails.agencyBilled ? 'true' : 'false',
        surplusCowbellInsurance: isCowbellHandlingCompliance ? 'true' : 'false',
        surplusCompliance: !isCowbellHandlingCompliance
          ? policyDetails.agencyName
          : '',
        surplusBrokerData:
          transformedExistingBrokerData || initialFormValues.surplusBrokerData,
      };
    }

    return initialFormValues;
  }, [policyDetails]);

const useBillingUpdateForm = (policyId: string, productType: ProductType) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const policyDetailsQuery = usePolicyDetailsQuery(policyId, productType);

  const defaultFormValues = useDefaultFormValues(policyDetailsQuery.data);
  const form = useForm<FormValues>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(formSchema),
  });

  const { reset: resetForm } = form;
  React.useEffect(() => {
    resetForm(defaultFormValues);
  }, [resetForm, defaultFormValues]);

  const submit: SubmitHandler<FormValues> = (formData) => {
    const p250Payload = {
      ...formData,
      policyId,
      productType,
      bypassRules: true,
    };

    const p100Payload = {
      isAgencyBilled: formData.isAgencyBilled,
      surplusCowbellInsurance: true,
      policyId,
      productType,
      bypassRules: true,
    };

    const payload =
      productType == ProductTypes.p250 ? p250Payload : p100Payload;

    return updatePolicyBilling({ data: payload })
      .then(() => {
        const invalidatePolicyQueries = () =>
          queryClient
            .invalidateQueries([
              'policy-details',
              { id: policyId, product: productType },
            ])
            .then(() => queryClient.invalidateQueries([COWBELL_POLICY]));

        setTimeout(invalidatePolicyQueries, 500);
        enqueueSnackbar('Billing information update requested successfully!', {
          variant: 'success',
        });
      })
      .catch((error) => {
        const errorMsg =
          error.response.data?.error ||
          'Unable to update billing information at this time.';

        enqueueSnackbar(errorMsg, {
          variant: 'error',
        });

        throw error;
      });
  };

  const brokerTypeAheadChange = (nextValue: SelectOption) => {
    const { meta } = nextValue;
    const current = form.getValues();

    const nextBrokerDataState: FormValues['surplusBrokerData'] = {
      ...current.surplusBrokerData,
      surplusLinesBrokerFirstName: meta.brokerFirstName,
      surplusLinesBrokerLastName: meta.brokerLastName,
      surplusLinesBrokerEmail: meta.brokerEmail,
      surplusLinesState: meta.surplusLinesState?.toUpperCase(),
      surplusLicenseNumber: meta.surplusLicenseNumber,
    };

    form.setValue('surplusBrokerData', nextBrokerDataState);
  };

  return {
    formMethods: form,
    handlers: {
      submit,
      brokerTypeAheadChange,
    },
    data: {
      policy: policyDetailsQuery.data,
    },
    isQueryLoading: policyDetailsQuery.isInitialLoading,
    isQueryError: policyDetailsQuery.isError,
  };
};

const ControlledTextField = withFormController(TextFieldBase);
const ControlledSimpleSelect = withFormController(SimpleSelect);

const StyledWarningIcon = styled(Warning)(({ theme }) => ({
  color: theme.palette.primary.red,
}));
