import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import PublicComponentWrapper from '../../wrappers/PublicComponentWrapper';
import { checkSignupEmailToken } from '../../../api/SignupService';
import { submitJoinExistingAccount } from '../../../users/UserActions';
import SignupEmailExisting from './SignupEmailExisting';
import SignupEmailNew from './SignupEmailNew';

/* Components */

class SignupEmailLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      badToken: false,
      errorStatus: null,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    checkSignupEmailToken(params.token)
      .then((resp) => {
        this.setState({ data: resp.data });
      })
      .catch((error) => {
        if (error?.response?.status) {
          this.setState({ badToken: true, errorStatus: error.response.status });
        }
      });
  }

  render() {
    const {
      match: { params },
    } = this.props;

    const { data } = this.state;

    const tokenType = _.get(data, 'tokenType', undefined);
    const redirectUrl = _.get(data, 'redirectURL');
    return (
      <PublicComponentWrapper>
        {this.state.badToken && this.state.errorStatus && (
          <div>{handleErrorMessage(this.state.errorStatus)}</div>
        )}

        {tokenType === 'NEW_ACCOUNT' && (
          <SignupEmailNew
            token={params.token}
            accountType={data.accountType}
            history={this.props.history}
          />
        )}

        {tokenType === 'EXISTING_ACCOUNT' && (
          <SignupEmailExisting
            token={params.token}
            data={this.state.data}
            submitJoinExistingAccount={this.props.submitJoinExistingAccount}
          />
        )}

        {tokenType === 'EXISTING_USER' &&
          this.props.history.push({
            pathname: '/login',
            state: { existingUser: true },
          })}

        {tokenType === 'QUOTE_SENT_NEW_USER' && (
          <SignupEmailExisting
            token={params.token}
            data={this.state.data}
            submitJoinExistingAccount={this.props.submitJoinExistingAccount}
            redirectUrl={redirectUrl}
          />
        )}

        {tokenType === 'QUOTE_SENT_EXISTING_USER' &&
          this.props.history.push({
            pathname: '/login',
            state: { existingUserForQuote: true, redirectUrl },
          })}

        {tokenType === 'QUOTE_LINK_NEW_AGENT' && (
          <SignupEmailExisting
            token={params.token}
            data={this.state.data}
            submitJoinExistingAccount={this.props.submitJoinExistingAccount}
            redirectUrl={_.isUndefined(redirectUrl) ? null : redirectUrl}
          />
        )}

        {tokenType === 'QUOTE_LINK_EXISTING_AGENT' &&
          this.props.history.push({
            pathname: '/login',
            state: { existingAgentForQuote: true, redirectUrl },
          })}
      </PublicComponentWrapper>
    );
  }
}

const handleErrorMessage = (status) => {
  if (status === 400 || status === 404) {
    return (
      <div>
        <Typography variant="body1" align="center" color="error">
          Link Expired/Not Found!
        </Typography>
        <div>
          <Typography variant="body1" color="inherit" align="center">
            To get a new link , please contact Cowbell Support Team.
            <br />
            If you already have an account? <Link to="/login">Login here</Link>
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Typography variant="body1" align="center">
        Opps! {'\n'} Looks like your credentials have expired. <br />
        Contact your account admin to get a new link.
      </Typography>
      <div className="main-entry-signuplanding-error-link">
        <Typography variant="body1" color="inherit" align="center">
          If not an existing user,
          <Link to="/signup" style={{ color: 'inherit' }}>
            {' '}
            signup here
          </Link>
        </Typography>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ submitJoinExistingAccount }, dispatch);
};

export default connect(null, mapDispatchToProps)(SignupEmailLanding);
