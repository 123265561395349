/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { makeStyles } from '@mui/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import SimpleTooltip from './SimpleTooltip';

export const Expand = (props, isShowingDetails, classList, toggleDetails) => {
  return (
    <ExpandMore
      className={isShowingDetails ? classList.toggle : classList.icon}
      onClick={toggleDetails(props.id)}
    />
  );
};

export const EditIcon = (classList, enterEdit) => {
  return <Edit className={classList.iconStyle} onClick={enterEdit} />;
};

export const DeleteIcon = (classList, enterDelete) => {
  return <Delete className={classList.iconStyle} onClick={enterDelete} />;
};

export const NoKnownLossLetterIndicator = ({ status }) => {
  const classes = useLossLetterStyles();

  if (status === 'PROVIDED') {
    return (
      <SimpleTooltip title="Certifcate of No Known Loss letter received">
        <img
          src="images/GreenFlag.svg"
          className={classes.flag}
          alt="loss letter received indicator"
        />
      </SimpleTooltip>
    );
  }

  if (status === 'REQUIRED') {
    return (
      <SimpleTooltip title="Certifcate of No Known Loss required">
        <img src="images/RedFlag.svg" className={classes.flag} alt="" />
      </SimpleTooltip>
    );
  }

  return null;
};

const useLossLetterStyles = makeStyles(({ config }) => ({
  fontSize: config.textSizes.primer,
  marginLeft: '0.4166666667rem',
  width: '1.2rem',
  height: '.8rem',
}));
