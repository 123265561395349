import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Card as MuiCard,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { useFormContext } from 'react-hook-form-4';

import { withFormController } from '../../../../components/hocs/withFormController';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import { InputLabelBase as InputLabel } from '../../../../components/inputs/InputLabelBase';

import { MultiSelect } from '../../../../components/Selects';
import { getUsers } from '../../../../api/UserService';
import {
  useCardStyles,
  useGridStylesLeft,
  useGridStylesRight,
} from './ClaimModalStyles';

const TextField = withFormController(TextFieldBase);

export const CreateClaimReporter = withStyles(({ palette }) => ({
  heading: {
    marginTop: 0,
    marginBottom: 25,
    color: palette.common.cowbellBlue,
    textAlign: 'center',
  },
}))(({ classes, editing }) => {
  const cardStyles = useCardStyles();
  const gridStylesLeft = useGridStylesLeft();
  const gridStylesRight = useGridStylesRight();
  const { control, getValues, register, onSelect, onMultiSelect, errors } =
    useFormContext();

  const [names, setnames] = useState([]);
  const formValues = getValues();

  useEffect(
    () => {
      if (!editing) {
        getUsers({ size: 100 }).then((resp) => {
          const options = resp.data.content.map(
            ({ account, firstName, lastName, email, id, role }) => {
              const fullName = `${firstName} ${lastName}`;
              return {
                email,
                role: role.name,
                organizationName: account.name,
                label: fullName,
                value: id,
              };
            }
          );

          setnames(options);
        });
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <MuiCard classes={cardStyles}>
      <h2 className={classes.heading}>Claim Reported By</h2>
      <MuiGrid container spacing={1}>
        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="name" required>
            Name:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          {editing ? (
            _.get(formValues, 'name.label', '-')
          ) : (
            <MultiSelect
              name="name"
              isMulti={false}
              options={names}
              onChange={onMultiSelect}
              error={_.get(errors, 'name.value.message')}
            />
          )}
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="role" required>
            Role:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          {_.get(formValues, 'role', '-')}
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="email" required>
            Email:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          {_.get(formValues, 'email', '-')}
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="workPhone">
            Work Phone:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <TextField
            name="workPhone"
            control={control}
            placeholder="(___) ___ - ____"
          />
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="cellPhone">
            Cell Phone:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <TextField
            name="cellPhone"
            control={control}
            placeholder="(___) ___ - ____"
          />
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="isRightContact" required>
            Are you the right contact for this claim?
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <MuiSelect
            variant="standard"
            name="isRightContact"
            ref={register({ name: 'isRightContact' })}
            onChange={onSelect}
            defaultValue={getValues().isRightContact}
          >
            {claimContactOptions.map(({ label, value }) => (
              <MuiMenuItem key={label} value={value}>
                {label}
              </MuiMenuItem>
            ))}
          </MuiSelect>
        </MuiGrid>

        {formValues.isRightContact === 'no' && (
          <>
            <MuiGrid item md={6} classes={gridStylesLeft}>
              <InputLabel inline htmlFor="contactName" required>
                Contact Name for this Claim:
              </InputLabel>
            </MuiGrid>
            <MuiGrid item md={6} classes={gridStylesRight}>
              <TextField name="contactName" required control={control} />
            </MuiGrid>

            <MuiGrid item md={6} classes={gridStylesLeft}>
              <InputLabel inline htmlFor="contactEmail" required>
                Contact Email for this Claim:
              </InputLabel>
            </MuiGrid>
            <MuiGrid item md={6} classes={gridStylesRight}>
              <TextField name="contactEmail" required control={control} />
            </MuiGrid>

            <MuiGrid item md={6} classes={gridStylesLeft}>
              <InputLabel inline htmlFor="contactPhone" required>
                Contact Phone for this Claim:
              </InputLabel>
            </MuiGrid>
            <MuiGrid item md={6} classes={gridStylesRight}>
              <TextField name="contactPhone" required control={control} />
            </MuiGrid>
          </>
        )}
      </MuiGrid>
    </MuiCard>
  );
});

const claimContactOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
