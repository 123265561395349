import { ServiceHandlerFactory } from '../../utils/serviceUtils';
import {
  getAccountTransformer,
  updateAccountDetailsTransformer,
} from './accounts.transformers';

const ACCOUNT_ROOT_PATH = '/api/account/v1/account';

export const getAccount = ServiceHandlerFactory({
  axios: {
    url: `${ACCOUNT_ROOT_PATH}/:accountId`,
    transformResponse: [JSON.parse, getAccountTransformer],
  },
});

export const updateAccountDetails = ServiceHandlerFactory({
  axios: {
    url: `${ACCOUNT_ROOT_PATH}/profile`,
    method: 'put',
    transformRequest: [updateAccountDetailsTransformer, JSON.stringify],
  },
});
