import _ from 'lodash';
import fp from 'lodash/fp';
import React, { useState, useContext, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { withStyles } from '@mui/styles';
import { ColumnItemList } from './column-item.table';

import { TableContext } from '../context.table';

const styles = () => ({});

export const ColumnSorter = withStyles(styles)(() => {
  const { columns, reorderColumn } = useContext(TableContext);
  const { items, onDragEnd } = useDraggable(columns, reorderColumn);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="column-order">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <ColumnItemList items={items} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

const keyMap = {
  name: 'id',
  label: 'content',
};

function useDraggable(initial, reorderColumn) {
  const [state, setState] = useState([]);

  // mounting, format list
  useEffect(() => {
    setState(
      initial.map((item) =>
        fp.flow(
          fp.pick(['name', 'label', 'show', 'canToggle']),
          fp.mapKeys((key) => keyMap[key] || key)
        )(item)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial]);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(state, result.source.index, result.destination.index);

    setState(items);

    if (_.isFunction(reorderColumn)) {
      reorderColumn(items);
    }
  }

  return { onDragEnd, items: state };
}

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

ColumnSorter.displayName = 'ColumnSorter';
