import React from 'react';
import clsx from 'classnames';

// mui
import { Box as MuiBox } from '@mui/material';
import { styled, makeStyles } from '@mui/styles';

import { ToggleButton as ToggleButtonBase } from '@mui/lab';

// components
import { FormProvider } from 'react-hook-form';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import CbButton from '../../../../components/Buttons/CbButton';
import { InputLabelBase } from '../../../../components/inputs/InputLabelBase';
import { RegisteredToggleButtonGroup } from '../../../../components/Buttons/registered/v7';
import DocumentUploadBase from './shared/DocumentUploadBase';
import { ScreenTitle } from './shared/ScreenTitle';

// hocs and hooks
import { withFormController } from '../../../../components/hocs/forms';
import { withShowable } from '../../../_global/lib/withShowable';

// utils
import { lossRunsCheckboxLabel } from '../constants/constants';
import CheckBoxLabel from '../CheckBoxLabel';
import { useLossRunsDependencies } from './dependencies/useLossRunsDependencies';
import { SubjectivityCompletionDate as SubjectivityCompletionDateBase } from './shared/SubjectivityCompletionDate';
import CowbellReferToUWButton from './shared/referral/CowbellReferToUWButton';

const TextField = withFormController(TextFieldBase);
const Box = withShowable(MuiBox);
const SubjectivityCompletionDate = withShowable(SubjectivityCompletionDateBase);

const LossRuns = ({ goBack, ...props }) => {
  const classes = useStyles();

  const { eventHandlers, reactHookForm, ...lossRunsState } =
    useLossRunsDependencies(props);

  if (lossRunsState.isLoadingSubjectivityData) {
    return null;
  }

  const { quoteSubjectivityId } = props.subjectivities[props.step];

  return (
    <Box className="contrast-text" maxWidth="65%">
      <ScreenTitle
        subjectivitySubType={lossRunsState.subjectivitySubType}
        title={lossRunsState.allSubjectivityInformation.subjectivityTitle}
      />

      <h3>
        {lossRunsState.allSubjectivityInformation.subjectivityDescription ?? ''}
      </h3>

      <h3>Has the insured had previous coverage?</h3>
      <FormProvider {...reactHookForm.methods}>
        <form onSubmit={reactHookForm.handleSubmit(eventHandlers.onSubmit)}>
          <Box>
            <RegisteredToggleButtonGroup name="hadPreviousCoverage" exclusive>
              <ToggleButton
                value
                onClick={eventHandlers.handleToggleClick(true)}
                disabled={!lossRunsState.isStepEditable}
              >
                <div
                  className={clsx({
                    [classes.disabledButtonText]: !lossRunsState.isStepEditable,
                  })}
                >
                  Yes
                </div>
              </ToggleButton>
              <ToggleButton
                value={false}
                onClick={eventHandlers.handleToggleClick(false)}
                disabled={!lossRunsState.isStepEditable}
              >
                <div
                  className={clsx({
                    [classes.disabledButtonText]: !lossRunsState.isStepEditable,
                  })}
                >
                  No
                </div>
              </ToggleButton>
            </RegisteredToggleButtonGroup>
          </Box>
          <Box show={lossRunsState.hadPreviousCoverage}>
            <Box>
              <InputLabelBase required>
                If less than 5 years, how many years of previous coverage?
              </InputLabelBase>
              <TextField
                required
                name="yearsOfPreviousCoverage"
                type="number"
                disabled={!lossRunsState.isStepEditable}
                style={{ width: '10rem' }}
              />
            </Box>
            <Box>
              <h3>
                Please upload loss run document(s) for the years the Insured had
                coverage. <br /> You can upload multiple at a time
              </h3>
              <DocumentUploadBase
                uploadZoneParams={lossRunsState.uploadZoneParams}
                files={lossRunsState.files}
                isUploadingDoc={lossRunsState.isUploadingDoc}
                uploadedDocs={lossRunsState.uploadedDocuments}
                isStepEditable={lossRunsState.isStepEditable}
                docType="Loss Runs"
              />
            </Box>
          </Box>
          <Box
            show={
              lossRunsState.files ||
              (lossRunsState.subjectivityData &&
                lossRunsState.subjectivityData?.userAttested)
            }
          >
            <Box
              show={
                (!!lossRunsState.subjectivityData?.uploadedDocuments ||
                  !!lossRunsState.files) &&
                lossRunsState.hadPreviousCoverage
              }
              paddingTop="0.5rem"
            >
              <CheckBoxBase
                name="lossRunsAcknowledgement"
                label={<CheckBoxLabel label={lossRunsCheckboxLabel} />}
                onChange={eventHandlers.handleCheckboxChange}
                checked={lossRunsState.isLossRunsAcknowledged}
                disabled={
                  (lossRunsState.subjectivityData?.userAttested &&
                    !lossRunsState.files) ||
                  lossRunsState.isUploadingDoc
                }
              />
            </Box>
          </Box>

          <SubjectivityCompletionDate
            allSubjectivityInformation={
              lossRunsState.allSubjectivityInformation
            }
            show={lossRunsState.isStepCompleted}
          />

          <Box paddingTop="2rem">
            <CbButton styleName="cancel" size="medium" onClick={goBack}>
              Back
            </CbButton>

            <CbButton
              styleName="ctaButton"
              size="medium"
              type="submit"
              onClick={eventHandlers.handleGoForward}
              loading={
                lossRunsState.isUploadingDoc || lossRunsState.isCompletingStep
              }
              disabled={
                lossRunsState.isUploadingDoc ||
                lossRunsState.isCompletingStep ||
                (!lossRunsState.isLossRunsAcknowledged && lossRunsState.files)
              }
              style={{ marginRight: '0.833rem' }}
            >
              Next
            </CbButton>

            <CowbellReferToUWButton
              quoteId={props.quoteDetails.id}
              subjectivityId={quoteSubjectivityId}
              productType={props.quoteDetails.uiData.productType}
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default LossRuns;

const ToggleButton = styled(ToggleButtonBase)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.contrastText}`,
  borderLeft: `1px solid ${theme.palette.primary.contrastText} !important`,
  '&:hover': {
    backgroundColor: theme.palette.background.modal,
  },
  padding: '0.5rem 4rem',
}));

const useStyles = makeStyles(({ palette }) => {
  return {
    disabledButtonText: {
      color: palette.text.text14,
    },
  };
});
