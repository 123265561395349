import { useDispatch } from 'react-redux';
import { createAction } from 'redux-actions';

const toggleModal = createAction('UI_TOGGLE_MODAL', (key, content, config) => ({
  key,
  content,
  config,
}));

/**
 * useToggleModal
 * @deprecated use new modal system Modal.show
 */
export const useToggleModal = () => {
  const dispatch = useDispatch();

  return {
    direct: (...args) => dispatch(toggleModal(...args)),
    deferred:
      (...args) =>
      () =>
        dispatch(toggleModal(...args)),
  };
};
