import type { UsStatesObjects } from '../../utils/USState';

export type TechENOFactors = Record<string, number>;
export type AdditionalInsured = Record<string, any>;
export type DefenseCounsel = Record<string, number>;
export type IRPMRiskCharacteristics = Record<string, number>;
export type TechENOEntry = Record<string, string>;
export type AddressRequestData = Record<string, string>;
export type SecurityQuestions = Record<string, string>;
export type UnderlyingInsurance = Record<string, any>;
export type CoveragePremiumOutput = Record<string, any>;
export type EndorsementPremiumOutput = Record<string, any>;
export type P250Details = Record<string, any>;
export type DocInfo = Record<string, any>;
export type SurplusBrokerData = Record<string, string>;

export type USState = keyof typeof UsStatesObjects;

export enum ServiceName {
  LOGIN = 'LOGIN',
  ACCOUNT = 'ACCOUNT',
  AGENCY = 'AGENCY',
  RISK = 'RISK',
  QUOTE = 'QUOTE',
  POLICY = 'POLICY',
  SALES = 'SALES',
  AUDIT = 'AUDIT',
  SYNC = 'SYNC',
  NAD = 'NAD',
  NONE = 'NONE',
  WEBHOOK = 'WEBHOOK',
  RESEND = 'RESEND',
}

export enum NoKnownLossLetterStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  REQUIRED = 'REQUIRED',
  PROVIDED = 'PROVIDED',
}

export enum QuoteCustomerStatus {
  REQUESTED = 'REQUESTED',
  REFERRED = 'REFERRED',
  AVAILABLE = 'AVAILABLE',
  APPROVED = 'APPROVED',
  SIGNED = 'SIGNED',
  ACTIVE = 'ACTIVE',
  INVALID = 'INVALID',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED',
  VOID = 'VOID',
  CANCEL_PENDING = 'CANCEL_PENDING',
  CANCELLED = 'CANCELLED',
  FLAT_CANCELLED = 'FLAT_CANCELLED',
  IN_REVIEW = 'IN_REVIEW',
  RESCIND = 'RESCIND',
}

export enum QuoteAgencyStatus {
  DRAFT = 'DRAFT',
  TEMPORARY = 'TEMPORARY',
  REQUESTED = 'REQUESTED',
  REFERRED = 'REFERRED',
  INDICATION = 'INDICATION',
  READY = 'READY',
  BOUND = 'BOUND',
  SIGNED = 'SIGNED',
  ACTIVE = 'ACTIVE',
  INVALID = 'INVALID',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED',
  DECLINED = 'DECLINED',
  LOST = 'LOST',
  SUBMISSION_LOST = 'SUBMISSION_LOST',
  QUOTE_LOST = 'QUOTE_LOST',
  BINDER_LOST = 'BINDER_LOST',
  BINDER_EXPIRED = 'BINDER_EXPIRED',
  VOID = 'VOID',
  CANCEL_PENDING = 'CANCEL_PENDING',
  CANCELLED = 'CANCELLED',
  FLAT_CANCELLED = 'FLAT_CANCELLED',
  IN_REVIEW = 'IN_REVIEW',
  RESCIND = 'RESCIND',
}

export enum PolicyStatusOptions {
  SIGNED = 'SIGNED',
  ISSUED = 'ISSUED',
  IN_FORCE = 'IN_FORCE',
}

export interface SpecifiedEventExclusionList {
  claimNumber?: string;
  claimName?: string;
  claimDate?: string;
  claimDescription?: string;
}
