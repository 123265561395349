import React from 'react';

const SVG = ({
  id = 'warning',
  style = {},
  fill = '#fbc000',
  width = '100%',
  className = '',
  viewBox = '0 0 20 20',
  title = 'Warning',
}) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .svg-warning-1 { fill: none; }
          .${id}.svg-fill-warning { fill: ${fill}; }
        `}
      </style>
    </defs>
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect className="svg-warning-1" width="45" height="45" />
        <path
          className={`${id} svg-fill-warning`}
          d="M17.37,15.24,10.87,3.76a1,1,0,0,0-1.74,0L2.63,15.24a1,1,0,0,0,.87,1.5h13a1,1,0,0,0,.87-1.5Zm-6.52-.79H9.27V12.92h1.58Zm0-3H9.27V8.34h1.58Z"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
