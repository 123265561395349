import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import * as Ramda from 'ramda';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Add as AddIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { AutoCompleteBase, DaysField, MultiSelectBase } from '../inputs';
import CbButton from '../Buttons/CbButton';
import { Button as ButtonBase } from '../Buttons/Button';
import { withShowable } from '../../console/_global/lib/withShowable';

// utils
import { prettyId } from '../../utils/data.utils';

// helpers
import { flattenQuery } from './filters/filter.helpers';

// statics
import filterStatics from '../../console/_statics/filter.statics.json';
import { PubSub } from '../../utils/eventUtils';
import { SaveFilterForm } from './filters/SaveFilterForm';
import { parseFilterModalQuery } from '../../utils/query.utils';
import { useQueryParams } from '../providers/QueryParamsProvider';

const Button = withShowable(ButtonBase);

function isOptionEqualToValue(option, value) {
  return option.id === value;
}

function getOptionLabel(option) {
  return option?.text ?? '';
}

const Autocomplete = (props) => (
  <AutoCompleteBase
    autoHighlight
    getOptionLabel={getOptionLabel}
    isOptionEqualToValue={isOptionEqualToValue}
    fullWidth
    error=""
    {...props}
  />
);

export const FilterModal = ({
  tableId = 'filters',
  open = false,
  config = {},
}) => {
  const { replace, query = { f: {} } } = useQueryParams();
  const { pathname } = useLocation();
  const classes = useStyles();
  const [nextQ, setNextQ] = useState([]);
  const [originalQ, setOriginalQ] = useState([]);
  const [isOpen, setOpen] = useState(open);

  // turn the current url into something we can work with
  useEffect(() => {
    if (_.isEmpty(query.f)) {
      setNextQ([]);
      setOriginalQ([]);
      handleAddRow();
      return;
    }

    const reduced = parseFilterModalQuery(query);
    setNextQ(reduced);
    setOriginalQ(reduced);
    // eslint-disable-next-line
  }, [query]);

  // listen for the toggle
  useEffect(() => {
    const toggleSub = PubSub.subscribe('table:filters:toggle', (tid) => {
      setOpen(tid === tableId);
    });

    return () => {
      toggleSub.remove();
    };

    // eslint-disable-next-line
  }, []);

  // turn the columns into a pickable list
  const columnOptions = useMemo(
    () =>
      Object.keys(config).map((column) => ({
        id: column,
        text: _.get(config, `[${column}].text`),
      })),
    [config]
  );

  // handle column changes by resetting the operator (ephemeral state)
  const onColumnChange = useCallback((id, column) => {
    return (event, nextColumn) => {
      if (!nextColumn) {
        return;
      }

      setNextQ((current) => {
        const index = _.findIndex(current, (filter) => filter.id === id);

        if (column !== nextColumn) {
          current[index].operator = null;
          current[index].value = null;
        }

        current[index].column = nextColumn.id;

        return [...current];
      });
    };
  }, []);

  // handle operator changes (ephemeral state)
  const onOperatorChange = useCallback((id) => {
    return (event, nextOperator) => {
      if (!nextOperator) {
        return;
      }

      setNextQ((current) => {
        const index = _.findIndex(current, (filter) => filter.id === id);
        const currentOperatorId = current[index].operator;

        if (currentOperatorId !== nextOperator.id) {
          current[index].value = null;
        }

        current[index].operator = nextOperator.id;
        return [...current];
      });
    };
  }, []);

  function handleValueChange(id, value) {
    if (!id) {
      return;
    }

    setNextQ((current) => {
      const index = _.findIndex(current, (filter) => filter.id === id);
      if (index === -1) {
        return current;
      }

      current[index].value = value;
      return [...current];
    });
  }

  const handleDebouncedValueChange = _.debounce(handleValueChange, 100);

  // handle value changes (ephemeral state)
  function onValueChange(id) {
    return (event) => {
      handleValueChange(id, event?.target?.value);
    };
  }

  function onDebouncedValueChange(id) {
    return (event) => {
      handleDebouncedValueChange(id, event?.target?.value);
    };
  }

  const onSelectValueChange = (id) => {
    return (nextRawValue) => {
      if (!nextRawValue) {
        return;
      }

      if (Array.isArray(nextRawValue) && !nextRawValue.length) {
        return;
      }

      const nextValue = Array.isArray(nextRawValue)
        ? nextRawValue
        : [nextRawValue];

      const nextPreppedValue = nextValue.map(({ value }) => value).join(',');
      handleValueChange(id, nextPreppedValue);
    };
  };

  // simply remove (ephemeral state)
  const removeFilter = useCallback(
    (id) => () => {
      setNextQ((current) => {
        const copy = [...current];
        _.remove(copy, (filter) => filter.id === id);
        return copy;
      });
    },
    []
  );

  // apply changes to the URL, letting it hold the final state (lasting state)
  const applyFilters = useCallback(() => {
    const { f, fn, page, searchAfter, pageRequested, ...rest } = query;

    const transformedNextQ = transformQueryParams(config)(nextQ);
    const filterParams = flattenQuery(transformedNextQ);

    setTimeout(() => {
      PubSub.publish('table:filters:toggle');
    }, 500);

    replace(pathname, { query: { ...rest, ...filterParams, fn: query.fn } });
    // eslint-disable-next-line
  }, [nextQ, query]);

  // clear all (persisted & ephemeral state)
  const clearAll = useCallback(() => {
    setNextQ([]);
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    setTimeout(() => {
      setNextQ(originalQ);
    }, 500);

    PubSub.publish('table:filters:toggle');
  };

  const handleAddRow = useCallback(() => {
    const id = prettyId();

    setNextQ((current) => [
      ...current,
      {
        id,
        column: null,
        operator: null,
        value: null,
      },
    ]);

    setTimeout(() => {
      const $firstinput = document.getElementById(`col-${id}`);
      if ($firstinput) {
        $firstinput.focus();
      }
    }, 0);
  }, []);

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth disableEnforceFocus>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" p={2} pb={0} pt={0}>
          Filter columns
          <SaveFilterForm nextQuery={nextQ} tableId={tableId} />
        </Box>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Box p="1.33rem" pr="3.33rem" pt="0.66rem">
          <Grid container spacing={2}>
            <Grid item md={4}>
              Column Name
            </Grid>
            <Grid item md={4}>
              Filter
            </Grid>
            <Grid item md={4}>
              Value
            </Grid>
            {nextQ.map(({ id, column, operator, value }) => {
              const type = _.get(config[column], 'type', '');
              const selectedColumn =
                column && config[column]
                  ? { id: column, text: config[column].text }
                  : null;
              const selectedOperator =
                operator &&
                filterStatics.operators[type] &&
                filterStatics.operators[type][operator]
                  ? {
                      id: operator,
                      text: filterStatics.operators[type][operator].text,
                    }
                  : null;
              const valueFieldProps = _.get(config, [column], {});

              return (
                <>
                  <Grid item md={4} key={`col-${id}`}>
                    <Autocomplete
                      id={`col-${id}`}
                      options={columnOptions}
                      defaultValue={selectedColumn}
                      onChange={onColumnChange(id, column, operator, value)}
                      value={selectedColumn}
                      data-qa="column-input"
                    />
                  </Grid>
                  <Grid item md={4} key={`op-${id}`}>
                    <Autocomplete
                      id={`op-${id}`}
                      options={operatorOptions[type]}
                      defaultValue={selectedOperator}
                      disabled={!selectedColumn}
                      onChange={onOperatorChange(id, column, operator, value)}
                      value={selectedOperator}
                      data-qa="filter-input-type"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className={classes.container}
                    key={`value-${id}`}
                  >
                    <ValueField
                      id={`${id}-${column}-${operator}`}
                      value={value}
                      disabled={!selectedOperator}
                      type={type}
                      operator={selectedOperator}
                      selectProps={{ onChange: onSelectValueChange(id) }}
                      textProps={{ onChange: onDebouncedValueChange(id) }}
                      dateProps={{
                        onChange: onValueChange(id),
                        placeholder: 'number in days (e.g. 5)',
                      }}
                      column={column}
                      {...valueFieldProps}
                    />
                    <IconButton
                      size="small"
                      className={classes.remove}
                      onClick={removeFilter(id)}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Grid>
                </>
              );
            })}
            {!nextQ.length && (
              <Grid
                item
                md={12}
                style={{
                  textAlign: 'center',
                  paddingTop: 18,
                  paddingBottom: 18,
                }}
              >
                No filters
              </Grid>
            )}
            <Grid item md={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  color="primary"
                  onClick={handleAddRow}
                  startIcon={<AddIcon />}
                >
                  Add Filter
                </Button>
                <Button color="primary" onClick={clearAll}>
                  Clear all
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <CbButton color="secondary" styleName="cancel" onClick={handleCancel}>
          Cancel
        </CbButton>
        <CbButton onClick={applyFilters} styleName="ctaButton">
          Apply Filters
        </CbButton>
      </DialogActions>
    </Dialog>
  );
};

const ValueField = React.memo(
  ({
    type,
    operator,
    getListOptions,
    dateProps,
    selectProps,
    textProps,
    value,
    column,
    ...props
  }) => {
    const controlledValue = value ?? '';

    const { data: options = [] } = useQuery({
      queryKey: ['filter-options', { column }],
      queryFn: getListOptions,
      enabled: typeof getListOptions == 'function',
      placeholderData: [],
    });

    if (type === 'list' || type === 'bool' || type === 'existence') {
      return (
        <MultiSelectBase
          options={options}
          {...props}
          {...selectProps}
          defaultValue={value}
          multiple={type === 'list'}
        />
      );
    }

    if (type === 'date') {
      if (operator && ['bf', 'af'].includes(operator.id)) {
        return (
          <TextFieldBase
            showErrorPlace={false}
            fullWidth
            inputProps={{ min: '2020-01-01', max: '9999-12-31' }}
            type="date"
            {...props}
            {...dateProps}
            value={controlledValue}
          />
        );
      }
      return (
        <DaysField
          showErrorPlace={false}
          fullWidth
          {...props}
          {...dateProps}
          value={controlledValue}
        />
      );
    }

    return (
      <TextFieldBase
        showErrorPlace={false}
        fullWidth
        {...props}
        {...textProps}
        value={controlledValue}
      />
    );
  }
);

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },

  remove: {
    position: 'absolute',
    top: '50%',
    left: '102%',
    transform: 'translateY(-22%)',
  },
}));

const operatorOptions = {
  string: Object.keys(filterStatics.operators.string).map((operator) => ({
    id: operator,
    text: filterStatics.operators.string[operator].text,
  })),
  number: Object.keys(filterStatics.operators.number).map((operator) => ({
    id: operator,
    text: filterStatics.operators.number[operator].text,
  })),
  list: Object.keys(filterStatics.operators.list).map((operator) => ({
    id: operator,
    text: filterStatics.operators.list[operator].text,
  })),
  date: Object.keys(filterStatics.operators.date).map((operator) => ({
    id: operator,
    text: filterStatics.operators.date[operator].text,
  })),
  bool: Object.keys(filterStatics.operators.bool).map((operator) => ({
    id: operator,
    text: filterStatics.operators.bool[operator].text,
  })),
  existence: Object.keys(filterStatics.operators.existence).map((operator) => ({
    id: operator,
    text: filterStatics.operators.existence[operator].text,
  })),
};

function transformQueryParams(config) {
  function filterQueryParamValueTransformer(filterQueryParam) {
    const filterParamType = config[filterQueryParam.column]?.type ?? '';
    const paramValueTransformation =
      filterParamTypeValueTransformations[filterParamType];
    const newParamValue = paramValueTransformation?.(filterQueryParam.value);

    return {
      ...filterQueryParam,
      value: newParamValue ?? filterQueryParam.value,
    };
  }

  return Ramda.map(filterQueryParamValueTransformer);
}

const stripCommas = Ramda.replace(/,/g)('');
const filterParamTypeValueTransformations = {
  number: stripCommas,
};
