import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const AccountAlreadySignedError = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
      height="90%"
    >
      <ErrorOutlineIcon style={{ fontSize: '4rem', marginBottom: '1rem' }} />
      <Typography variant="body1">
        We are unable to quote that client as they have already selected another
        agency.
      </Typography>
    </Box>
  );
};
