import type { JsonApiSpecResponse } from '../../types';
import CowbellFactorsMapping from '../../utility/CowbellFactorsMapping';

export function pullFactorsFromCowbellRiskFactors(
  data: any
): JsonApiSpecResponse {
  const nextFactors = data.cowbellRiskFactors?.factors || {};

  return {
    data: {
      ...data,
      factors: nextFactors,
    },
  };
}

export function serializeCowbellFactors(body: any): JsonApiSpecResponse {
  const { data } = body;

  return {
    data: {
      ...data,
      factors: Object.keys(data.factors).reduce(
        (acc: { [key: string]: any }, factorKey: string) => {
          const nextFactorKey = factorKey.replace(/(CF)$/i, '');
          const factorValue = data.factors[factorKey];

          if (!CowbellFactorsMapping[nextFactorKey]) {
            return acc;
          }

          return {
            ...acc,
            [nextFactorKey]: factorValue,
          };
        },
        {}
      ),
    },
  };
}
