import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
// components
import { NotesBody } from './NotesBody';
import { withEditorFunctions } from './withEditorFunctions';
import { NotesBodyFooter } from './NotesBodyFooter';
import { setUploadedDocs } from './notes.reducer';

export const Notes = compose(withEditorFunctions)(
  ({
    HeaderComponent,
    data,
    readOnly,
    editing,
    onUpdate,
    onDelete,
    ...props
  }) => {
    const [noteId, setId] = useState('');
    const [delNote, setDelete] = useState(false);
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(setUploadedDocs({ attachments: [] }));
      // eslint-disable-next-line
    }, []);

    const itsEdit = edit && data.id === noteId;

    let show = false;
    if (readOnly || delNote) {
      show = true;
    }
    if (editing && !itsEdit) {
      show = true;
    }

    return (
      <>
        <HeaderComponent
          data={data}
          {...props}
          readOnly={show}
          setEdit={setEdit}
          edit={edit}
          setId={setId}
          noteId={noteId}
          onUpdate={onUpdate}
        />
        <NotesBody
          data={data}
          {...props}
          edit={edit}
          noteId={noteId}
          delNote={delNote}
        />
        <NotesBodyFooter
          data={data}
          {...props}
          readOnly={readOnly || editing}
          setDelete={setDelete}
          delNote={delNote}
          setId={setId}
          noteId={noteId}
          setEdit={setEdit}
          onDelete={onDelete}
        />
      </>
    );
  }
);

export default Notes;
