import { useSnackbar } from 'notistack';
import Moment from 'moment';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import type { AxiosResponse } from 'axios';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES,
  useAmplitude,
} from '../../../../providers/AmplitudeProvider';
import {
  downloadQuoteDocuments,
  downloadQuoteProposal,
  fetchP250QuotePageDetails,
} from '../../../../inbox/QuotesService';
import { getQuoteDetails } from '../../../../api/p250.service';
import { ProductTypes } from '../../../../types';
import { fetchAccountDetailsById } from '../../../../accounts/AccountService';
import { toggleModalDirect } from '../../../../utils/storeUtils';
import { getIsRenewalOrMigration, manageAPIError } from '../../../../utils';
import { getAccountDetails } from '../../../../api/accounts/accounts.api';
import {
  durationOfDaysFromToday,
  threeMonthsInPast,
} from '../../../../utils/date.utils';
import { downloadAWSDoc } from '../../../../policies/PolicyService';
import { saveBlobFile } from '../../../../utils/appUtils';
import { useAllow } from '../../../../components/hooks/useAllow';
import {
  setAccountDetails,
  setAgentDetails,
} from '../../../_tabs/tabs.reducer';
import { reviveQuote } from '../../../../api';
import { delayedEvent } from '../../../../utils/eventUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../../../reducers/UiSettingsReducers';
import { Modal } from '../../../../components/modals/v2/helpers/v2.modal.helpers';
import type { Quote } from '../../../../types/quotes/quote.entity';

export const useAgencyQuotesOptionsHandlers = (data: Quote) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const amplitude = useAmplitude();

  const handleSendQuoteToPolicyHolder = () => {
    const quoteData = { ...data };
    if (data.isSurplus) {
      fetchP250QuotePageDetails(data.id).then((resp) => {
        // @ts-expect-error appending additional data to Quote entity
        quoteData.surplus = resp.data.firmographicData;
        Modal.show('SendQuote', {
          data: { ...quoteData },
        });
      });
    } else {
      Modal.show('SendQuote', {
        data: { ...quoteData },
      });
    }
  };

  const handleReQuote = () => {
    if (data.product === ProductTypes.p250) {
      return getQuoteDetails({ quoteId: data.id }).then(
        (response: AxiosResponse) => {
          history.push(
            `/agency/requestQuote/${data.product}/${data.accountId}`,
            {
              ...response.data.initial250RequestData,
              requote: true,
              quoteId: data.id,
            }
          );
        }
      );
    }

    fetchAccountDetailsById(data.accountId).then((response) => {
      // check to see if account / product are of the same type
      if (data.product != response.data.productType) {
        // if not the same type enter the redirect-requote modal to 'new quote'
        toggleModalDirect('RedirectRequote', data, {
          maxWidth: 'sm',
          title: `Re-Quote`,
        });
      } else {
        // product & account type match therefore route user to requestQuote
        history.push(
          `/agency/requestQuote/${data.product}/${data.accountId}/${data.id}/reQuote`
        );
      }
    });
  };

  const handleDeleteQuote = () => {
    toggleModalDirect('DeleteQuote', data, {
      title: `Delete Quote for ${data.companyName}`,
      icon: 'Delete',
      maxWidth: 'md',
      footerButtons: [
        { buttonText: 'Cancel', action: 'cancel' },
        {
          buttonText: 'Delete Quote',
          action: 'foot-button-one',
          showLoading: true,
          showDisabled: true,
        },
      ],
    });
  };

  const handleSendQuoteToRetailAgent = () => {
    toggleModalDirect('SendQuoteToRetailAgent', data, {
      title: `Send Quote ${`${data.quoteNumber}`} to Retail Agent`,
      icon: 'Send',
      maxWidth: 'md',
    });
  };

  const handleRequestToBind = () => {
    amplitude.track(AMPLITUDE_EVENTS.requestToBind, {
      version: AMPLITUDE_PROPERTIES.version.one,
    });
    const renewalBaseUrl = `/agency/accounts/${data.accountId}/renew`;
    const renewalUrlParams = `product=${ProductTypes.p100}&policyId=${data.policyId}`;

    if (!getIsRenewalOrMigration(data.isRenewal, data.isMigration ?? false)) {
      history.push(`/agency/bind/quote/${data.product}/${data.id}`);
      return;
    }

    if (data.isAutoRenewal) {
      getAccountDetails(data.accountId)
        .then((resp) => {
          const { isVerifiedByAgent, verifiedDate } = resp?.data;

          const durationOfDaysFromAttestedWithRespectToToday =
            durationOfDaysFromToday(verifiedDate);

          const durationOfDaysFromTodayWithRespectTo3Months =
            durationOfDaysFromToday(threeMonthsInPast);

          if (
            isVerifiedByAgent &&
            durationOfDaysFromAttestedWithRespectToToday <
              durationOfDaysFromTodayWithRespectTo3Months
          ) {
            history.push(
              `${renewalBaseUrl}/renewalSummary?${renewalUrlParams}&quoteId=${data.id}`
            );
          } else {
            history.push(
              `${renewalBaseUrl}/accountDetails?${renewalUrlParams}`
            );
          }
        })
        .catch(() => {
          history.push(`${renewalBaseUrl}/accountDetails?${renewalUrlParams}`);
        });
      return;
    }

    history.push(
      `${renewalBaseUrl}/renewalSummary?${renewalUrlParams}&quoteId=${data.id}`
    );
  };

  const handleSubmitForUWReview = () => {
    toggleModalDirect('SubmitForUWReview', data, {
      title: `Submit for Underwriter Review`,
      icon: 'Submit',
      maxWidth: 'md',
    });
  };

  const handleBindQuote = () => {
    amplitude.track(AMPLITUDE_EVENTS.requestToBind, {
      [AMPLITUDE_PROPERTIES.quoteId]: data.id,
      version: AMPLITUDE_PROPERTIES.version.one,
    });
    history.push(`/agency/bind/quote/${data.product}/${data.id}`);
  };

  const handleEditQuote = () => {
    const { id: quoteId, accountId } = data;
    history.push(
      `/agency/requestQuote/${ProductTypes.p100}/${accountId}/${quoteId}/edit-quote`,
      data
    );

    amplitude.track(AMPLITUDE_EVENTS.editQuote);
  };

  const handleDownloadProposal = (filename: string) => {
    return () => {
      const { id, accountId, quoteNumber, companyName, created } = data;

      downloadQuoteProposal(accountId, id, Moment(created).unix())
        .then((response) => downloadAWSDoc(response.data))
        .then((response) =>
          saveBlobFile(
            response,
            `${companyName}-${quoteNumber}-Cowbell-${filename}`,
            '.pdf'
          )
        )
        .catch((error) => {
          const errorMessage = manageAPIError(
            error,
            'Unable to download the Quote Proposal'
          );

          enqueueSnackbar(errorMessage, { variant: 'error' });
        });
    };
  };

  const checkAutoRenewalQuote = (filename: string) => {
    const onDownload = handleDownloadProposal(filename);
    const { isAutoRenewal } = data;
    if (!isAutoRenewal) {
      return onDownload();
    }
    getAccountDetails(data.accountId)
      .then((resp) => {
        const { isVerifiedByAgent, verifiedDate } = resp?.data;
        const { created } = data;
        if (
          isVerifiedByAgent &&
          Moment(verifiedDate).isAfter(Moment(created))
        ) {
          return onDownload();
        }
        Modal.show('FirmoChangeWarning', {
          data: { onDownload, onUpdateFirmo: handleRequestToBind },
        });
      })
      .catch(() =>
        enqueueSnackbar(
          'There was a problem with your download request. Please try again.',
          { variant: 'error' }
        )
      );
  };

  const handleDownloadApplication = () => {
    const { id, companyName, accountId, created, quoteNumber } = data;
    const isEndorsement = _.get(data, 'isEndorsement', false);

    downloadQuoteDocuments(accountId, id, Moment(created).unix(), isEndorsement)
      .then((response) => downloadAWSDoc(response.data))
      .then((response) =>
        saveBlobFile(
          response,
          `${companyName}-${quoteNumber}-Cowbell-Application`,
          '.pdf'
        )
      )
      .catch((error) => {
        const errorMessage = manageAPIError(
          error,
          'Unable to download the application.'
        );

        enqueueSnackbar(errorMessage, { variant: 'error' });
      });
  };

  const handleCopyApplicationLink = () => {
    toggleModalDirect('CopyApplicationLink', data, {
      title: 'Copy Cowbell Application Link',
      maxWidth: 'md',
    });
  };

  const CAN_MANAGE_ACCOUNT = useAllow(['accounts:manage']);

  const handleRoute = () => {
    dispatch(
      setAccountDetails({
        account: {
          companyName: data.companyName,
          quoteNumber: data.quoteNumber,
          accountId: data.accountId,
          agencyStatus: data.agencyStatus,
          makeLive: true,
        },
      })
    );

    dispatch(
      setAgentDetails({
        agent: {
          from: location.pathname,
          readOnly: {
            quote: true,
            policy: true,
            account: !CAN_MANAGE_ACCOUNT,
            risk: true,
            documents: {
              upload: false,
              delete: true,
            },
          },
        },
      })
    );

    history.push(
      `/agency/accounts/details/${data.id}/${data.accountId}/${data.product}/account-profile`
    );
  };

  const handleReviveQuote = () => {
    const onConfirm = () => {
      reviveQuote(data.id)
        .then(() =>
          delayedEvent('table-refetch', 500, AGENCY_QUOTES_LISTING_STORE_ID)
        )
        .catch(() =>
          enqueueSnackbar(
            'There was a problem with your request, please try again',
            { variant: 'error' }
          )
        );
    };

    Modal.show('SimpleConfirm', {
      data: {
        body: `Are you sure you want to refer quote number ${data.quoteNumber}?`,
        confirmButton: 'Confirm',
        onConfirm,
      },
      config: { title: 'Refer Quote to Underwriting' },
    });
  };

  return {
    handleSendQuoteToPolicyHolder,
    handleReQuote,
    handleDeleteQuote,
    handleSendQuoteToRetailAgent,
    handleRequestToBind,
    handleSubmitForUWReview,
    handleBindQuote,
    handleEditQuote,
    handleDownloadProposal,
    handleDownloadApplication,
    handleCopyApplicationLink,
    handleRoute,
    checkAutoRenewalQuote,
    handleReviveQuote,
  };
};
