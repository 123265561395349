import { TextFieldBase } from './TextFieldBase';
import { CleaveBase } from './CleaveBase';

export const FeinField = ({ InputProps = {}, ...props }) => {
  return (
    <TextFieldBase
      InputProps={{
        ...InputProps,
        inputComponent: CleaveInput,
      }}
      {...props}
    />
  );
};

const CleaveInput = ({ inputRef, ...props }) => {
  return (
    <CleaveBase
      ref={inputRef}
      options={{
        delimiters: ['-'],
        blocks: [2, 7],
      }}
      {...props}
    />
  );
};
