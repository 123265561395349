import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { useTheme } from '@mui/material';

export const DefaultTableHeaderCell = ({ ...props }) => {
  const { palette } = useTheme();
  const draggingEnabled =
    props.draggingEnabled &&
    !Object.prototype.hasOwnProperty.call(props, 'fixed');
  return (
    <TableHeaderRow.Cell
      {...props}
      style={{
        backgroundColor: palette.background.tableHeader,
        ...props.style,
      }}
      draggingEnabled={draggingEnabled}
    />
  );
};

export const TableHeaderCellContent = (props) => {
  const { children, column } = props;
  const { HeaderComponent } = column;

  return (
    <TableHeaderRow.Content column={column} {...props}>
      {HeaderComponent ? <HeaderComponent {...props} /> : children}
    </TableHeaderRow.Content>
  );
};
