import { Box, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import type { TypographyProps } from '@mui/material';

import SimpleTooltip from '../../../components/SimpleTooltip';
import type { WithChildrenProps } from '../../../types/components/common';
import type { AccountDto } from '../../../types';

interface TruncatedTextWithTooltipProps {
  text: string;
  length: number;
  isMultiline?: boolean;
}

export const TruncatedTextWithTooltip = ({
  text: rawText,
  length,
  isMultiline,
}: TruncatedTextWithTooltipProps) => {
  if (!rawText) {
    return <></>;
  }

  let text = rawText;

  if (!isMultiline && text.length > length) {
    return (
      <SimpleTooltip
        title={rawText}
        style={{ lineHeight: '1.5', marginLeft: 0, cursor: 'pointer' }}
      >
        <>{text.slice(0, length)}...</>
      </SimpleTooltip>
    );
  }

  if (rawText.split(' ')[0]?.length > length) {
    text = `${rawText.slice(0, length)}- ${rawText.slice(length)}`;
  }

  if (text.length < length * 2) {
    return <>{text}</>;
  }

  return (
    <SimpleTooltip
      title={rawText}
      style={{ lineHeight: '1.5', marginLeft: 0, cursor: 'pointer' }}
    >
      <>{text.slice(0, length * 2)}...</>
    </SimpleTooltip>
  );
};

export const SecondaryText = ({
  children,
  variant,
}: WithChildrenProps & TypographyProps) => {
  const theme = useTheme<any>();
  return (
    <Typography
      variant={variant}
      style={{ color: theme.palette.text.disabled }}
    >
      {children}
    </Typography>
  );
};

export const HyperlinkText = ({
  children,
  variant = 'body2',
}: WithChildrenProps & TypographyProps) => {
  const classes = useHyperlinkStyles();
  return (
    <Typography variant={variant} className={classes.text}>
      {children}
    </Typography>
  );
};

const useHyperlinkStyles = makeStyles((theme) => ({
  text: {
    color: theme.agencyDash.interactable,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const TypeToSearchMessage = () => (
  <Box
    width="100%"
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    padding="2rem 0"
  >
    <Typography style={{ fontSize: '1.5rem' }}>
      Start typing to search...
    </Typography>
  </Box>
);

export const getAddressString = (account: AccountDto) => {
  const { address1, address2, city, state, zipCode } = account;
  return `${address1} ${address2 || ''}, ${city}, ${state} ${zipCode}`;
};
