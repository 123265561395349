import React from 'react';
import _ from 'lodash';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';

// mui
import { Box, Typography, Grid, useTheme } from '@mui/material';

// components
import type { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { CustomTypeahead } from '../global-search/CustomTypeahead';

// utils
import Showable from '../../../../components/Showable';
import { getIsUsPlatform, useGetPlatformRegion } from '../../../../utils';

import type { AccountDto } from '../../../../types';

// apis
import { addNewAccount } from '../../../../accounts/AccountService';
import { StartNewQuoteSearchResults } from './AccountSearchListing';
import { getMyTeams } from '../../../../teams/TeamsService';
import type {
  GlobalStoredTeam,
  TeamDto,
} from '../../../../reducers/team.reducer';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import { AGENCY_DASHBOARD_VIEWS } from '../agency-dashboard.statics';
import { SimpleSelect } from '../../../../components/inputs';
import { UsStatesFull, UsStatesObjects } from '../../../../utils/USState';
import { PrimaryActionButton } from '../listing-tabs/PrimaryActionButton';
import {
  AMPLITUDE_EVENTS as ANALYTICS_EVENTS,
  AMPLITUDE_PROPERTIES,
  useAmplitude as useAnalytics,
} from '../../../../providers/AmplitudeProvider';
import { HyperlinkText } from '../agency-dashboard.utils';
import { AccountSupplementaryInfo } from './AccountSupplementaryInfo';
import { RowCell, ColumnDivider } from '../listing-tabs/common/components';
import type { formatClientQueriesResponseReturnType } from '../hooks/useNewQuoteClientData';
import { useClientQueries } from '../hooks/useNewQuoteClientData';
import { PolicyRowActionButton } from '../listing-tabs/policies/AgencyDashboardPoliciesTab';
import { searchDunsByCriteria } from '../../../agencies/_services/duns.service';
import { searchAccountsAndNad } from '../../../../api';
import { InputLabelBase } from '../../../../components/inputs/InputLabelBase';
import { Show } from '../../../../components/Show';
import type { Languages } from '../../../../i18n/i18n.language-config';
import {
  COUNTRY_MAPPING,
  LANGUAGE_COUNTRY_MAPPING,
} from '../../../../i18n/i18n.language-config';
import {
  formatAddressAsString,
  formatPhoneAsString,
} from '../../../../i18n/forms/LanguageForm';

export interface _Account extends AccountDto {
  isNAD?: boolean;
  isDnb?: boolean;
  clientOnAnotherTeam: boolean;
  clientOnYourTeam: boolean;
  clientOnYourAgency: boolean;
  hardlinkedToAnotherAgency: boolean;
  teamId: string;
  teamName: string;
}

export interface TeamOption extends GlobalStoredTeam {
  isDefault?: boolean;
  label?: string;
  value?: GlobalStoredTeam;
}

export const StartNewQuote = () => {
  const { data: allTeams = [] } = useTeamsQuery();
  const [team, setTeam] = React.useState<GlobalStoredTeam | TeamOption>({
    id: '',
    name: '',
  });
  const [selectedAccount, setSelectedAccount] = React.useState<_Account>();

  useChangeTeamIfSelectedAccountHasOne({ account: selectedAccount, setTeam });

  React.useEffect(() => {
    const defaultTeam = allTeams.find(
      (team: TeamDto) => team.isDefault === true
    );

    if (defaultTeam) {
      setTeam(defaultTeam);
    }
  }, [allTeams]);

  const clientQueryData = useClientQueries(selectedAccount);

  const handleTeamSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTeam(event.target.value as unknown as GlobalStoredTeam);
  };

  if (!allTeams.length) {
    return <></>;
  }

  const teamSelectProps = selectedAccount?.teamId
    ? {
        disabled: true,
      }
    : null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={8}
    >
      <Typography>Please search for the client below</Typography>
      <AccountSearch
        onAccountSelect={setSelectedAccount}
        team={team}
        onTeamSelect={handleTeamSelect}
        teamSelectProps={teamSelectProps}
      />
      <Showable show={!!selectedAccount}>
        <AccountDetails
          account={selectedAccount}
          clientQueryData={clientQueryData}
          team={team}
        />
      </Showable>
      <Showable show={!!selectedAccount}>
        <AccountSupplementaryInfo
          account={selectedAccount}
          clientQueryData={clientQueryData}
        />
      </Showable>
    </Box>
  );
};

interface AccountSearchProps {
  onAccountSelect: (account: _Account | undefined) => void;
  team: GlobalStoredTeam;
  onTeamSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  teamSelectProps: Record<'disabled', boolean> | null;
}

const AccountSearch = ({
  onAccountSelect,
  team,
  onTeamSelect,
  teamSelectProps,
}: AccountSearchProps) => {
  const [userInput, setUserInput] = React.useState('');
  const [state, setState] = React.useState('');
  const region = useGetPlatformRegion();
  const isUSPlatform = getIsUsPlatform(region);

  const { data: teams = [] } = useTeamsQuery();
  const accountsNadAndDnbQuery = useAccountNadAndDnbQuery(userInput, state);

  const onChange = (value: string) => {
    setUserInput(value);
  };

  const onClearInput = () => {
    setUserInput('');
    onAccountSelect(undefined);
  };

  const onStateSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState(event.target.value);
    onAccountSelect(undefined);
  };

  const accountSearchFieldPlaceholder =
    isUSPlatform && !state ? 'Please select a state' : 'Search';

  return (
    <Box display="flex" mt={2} width="100%">
      <Grid
        container
        style={isUSPlatform ? { padding: '0 1rem' } : { padding: '0 5rem' }}
      >
        <Show when={isUSPlatform}>
          <Grid
            item
            xs={2}
            style={{
              paddingRight: '1rem',
            }}
          >
            <SimpleSelect
              name="state"
              label="State"
              value={state}
              options={UsStatesFull}
              onChange={onStateSelect}
              displayEmpty
              renderValue={renderState}
              InputLabelProps={inputLabelProps}
            />
          </Grid>
        </Show>
        <Grid item xs={isUSPlatform ? 8 : 10}>
          <InputLabelBase {...inputLabelProps}>Client Name</InputLabelBase>
          <CustomTypeahead
            onChange={onChange}
            onClearInput={onClearInput}
            disabled={isUSPlatform && !state}
            placeholder={accountSearchFieldPlaceholder}
          >
            {(typeaheadProps) => (
              <StartNewQuoteSearchResults
                {...typeaheadProps}
                onSelect={onAccountSelect}
                queryCache={accountsNadAndDnbQuery}
                searchTerm={userInput}
                state={state}
                team={team}
              />
            )}
          </CustomTypeahead>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            paddingLeft: '1rem',
          }}
        >
          <SimpleSelect
            name="team"
            label="Team"
            value={team}
            options={teams}
            onChange={onTeamSelect}
            renderValue={renderTeam}
            InputLabelProps={inputLabelProps}
            {...teamSelectProps}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const inputLabelProps = {
  style: { margin: 0, padding: 0 },
};

interface AccountDetailsProps {
  account?: _Account;
  clientQueryData: UseQueryResult<formatClientQueriesResponseReturnType>;
  team: GlobalStoredTeam;
}

const AccountDetails = ({ account, ...props }: AccountDetailsProps) => {
  const theme = useTheme<any>();

  if (!account) return null;

  const language =
    COUNTRY_MAPPING[account.country as keyof typeof COUNTRY_MAPPING];

  const address = formatAddressAsString({
    language,
    address1: account.address1,
    address2: account.address2,
    address3: account.address3,
    city: account.city,
    state: account.state,
    zipCode: account.zipCode,
  });
  const phoneNumber = formatPhoneAsString(language, account.phoneNumber);

  return (
    <Box
      display="flex"
      width="95%"
      height="5rem"
      justifyContent="space-between"
      alignItems="center"
      marginTop={4}
      style={{
        padding: '0 1rem',
        borderRadius: 5,
        border: `1px solid ${theme.palette.notification.containerBorder}`,
      }}
    >
      <RowCell width="35%">
        <Link to={`/agency/account/${account.id}`}>
          <HyperlinkText>{account.name}</HyperlinkText>
        </Link>
      </RowCell>
      <ColumnDivider />
      <RowCell width="15%">
        <Typography variant={phoneNumber ? 'body2' : 'caption'} style={{}}>
          {phoneNumber || '(phone unavailable)'}
        </Typography>
      </RowCell>
      <ColumnDivider />
      <RowCell width="40%">
        <Typography variant="body2">{address}</Typography>
      </RowCell>
      <ColumnDivider />
      <RowCell width="10%" style={{ justifyContent: 'center', marginRight: 0 }}>
        <NewQuoteActionButton account={account} {...props} />
      </RowCell>
    </Box>
  );
};

const NewQuoteActionButton = ({
  account,
  clientQueryData,
  team,
}: AccountDetailsProps) => {
  const { handleViewChange } = useAgencyDashboardState();
  const { enqueueSnackbar } = useSnackbar();
  const region = useGetPlatformRegion();
  const location = useLocation();
  const history = useHistory();
  const analytics = useAnalytics();
  const theme = useTheme<any>();
  const { data: clientData } = clientQueryData;

  if (!account) return null;

  const accountNeedsToBeCreated =
    account.isNAD ||
    account.isDnb ||
    (!account.clientOnYourAgency && !account.hardlinkedToAnotherAgency);

  if (account.hardlinkedToAnotherAgency) {
    return (
      <Typography align="center" style={{ color: theme.agencyDash.text.error }}>
        Unable to quote
      </Typography>
    );
  }

  if (account.clientOnAnotherTeam) {
    return (
      <Typography
        align="center"
        style={{ color: theme.agencyDash.text.warning }}
      >
        Unable to quote
      </Typography>
    );
  }

  if (
    !_.isEmpty(clientData?.activePoliciesOpenForRenewal.rows) ||
    !_.isEmpty(clientData?.renewalQuotes.rows)
  ) {
    return (
      <PolicyRowActionButton
        policy={clientData?.activePoliciesOpenForRenewal.rows[0]}
      />
    );
  }

  if (!_.isEmpty(clientData?.activePoliciesNotOpenForRenewal.rows)) {
    return (
      <PolicyRowActionButton
        policy={clientData?.activePoliciesNotOpenForRenewal.rows[0]}
      />
    );
  }

  const handleQuoteClick = () => {
    analytics.track(ANALYTICS_EVENTS.startNewQuote, {
      version: AMPLITUDE_PROPERTIES.version.two,
    });
    if (accountNeedsToBeCreated) {
      if (!team.id || team.id === '') {
        enqueueSnackbar('A team must be selected before account creation', {
          variant: 'error',
        });
        return;
      }
      return createNewAccount(account, team, region)
        .then((resp: AxiosResponse) => {
          history.replace(`${location.pathname}?accountId=${resp.data.id}`);
          handleViewChange(AGENCY_DASHBOARD_VIEWS.ACCOUNT_RISK_LOADER);
        })
        .catch((error: AxiosError) => {
          const errorMessage =
            error?.response?.status === 409
              ? 'Unable to quote - Customer is already associated with another Agency.'
              : 'There was a problem while starting that quote. Please try again in a few minutes';

          enqueueSnackbar(errorMessage, { variant: 'error' });
        });
    }
    history.replace(`${location.pathname}?accountId=${account.id}`);
    handleViewChange(AGENCY_DASHBOARD_VIEWS.ACCOUNT_RISK_LOADER);
  };

  return (
    <PrimaryActionButton onClick={handleQuoteClick}>Quote</PrimaryActionButton>
  );
};

const createNewAccount = (
  account: AccountDto,
  team: GlobalStoredTeam,
  region: Languages
) => {
  const payload = {
    ..._.pick(account, ['city', 'address1', 'name', 'cbid', 'state']),
    phone: account.phoneNumber,
    zipCode: account.zipCode,
    teams: [team],
    dunsNumber: account.dunsNumber ?? null,
    country: LANGUAGE_COUNTRY_MAPPING[region],
  };

  return addNewAccount(payload);
};

type UsStates = keyof typeof UsStatesObjects;

function renderState(value: UsStates) {
  return UsStatesObjects[value]?.label || 'State';
}

function renderTeam(selectedTeam: TeamOption) {
  return <>{selectedTeam.label ?? selectedTeam.name}</>;
}

const useTeamsQuery = () => {
  return useQuery({
    queryKey: ['my-teams'],
    queryFn: () =>
      getMyTeams().then(({ data }: AxiosResponse) => {
        return data;
      }),
    select: (data) => {
      return data.content.map((team: TeamDto) => ({
        isDefault: team.isDefault,
        label: team.name,
        id: team.id,
        value: {
          id: team.id,
          name: team.name,
        },
      }));
    },
  });
};

const useAccountNadAndDnbQuery = (userInput: string, state: string) => {
  const region = useGetPlatformRegion();
  const isUSPlatform = getIsUsPlatform(region);
  const country =
    LANGUAGE_COUNTRY_MAPPING[region as keyof typeof LANGUAGE_COUNTRY_MAPPING];

  const _params = isUSPlatform
    ? { search: userInput, state, size: 5 }
    : { search: userInput, country, size: 5 };

  const _dnbParams = isUSPlatform
    ? {
        size: 5,
        page: 0,
        primaryName: userInput,
        state,
      }
    : {
        size: 5,
        page: 0,
        primaryName: userInput,
        state: 'NA',
        country,
      };

  return useQuery({
    queryKey: ['agency-dashboard-2.0', 'accounts', userInput, state],
    queryFn: async () => {
      const accountsAndNadData = await searchAccountsAndNad({
        params: _params,
      });

      if (
        accountsAndNadData.data.content &&
        accountsAndNadData.data.content.length > 0
      ) {
        return accountsAndNadData;
      }

      const dnbData = await searchDunsByCriteria(_dnbParams, {});

      return dnbData;
    },
    select: (resp: AxiosResponse) => {
      if (resp.data.content?.[0]?.duns) {
        const dunsAccounts = formatDunsAccounts(resp.data.content);
        return {
          accounts: dunsAccounts,
        };
      }

      return {
        accounts: resp.data.content,
      };
    },
    refetchOnWindowFocus: false,
    enabled: !!userInput,
    retry: false,
  });
};

interface DunsAccountTelephone {
  telephoneNumber: string;
}

interface DunsAccount {
  primaryName: string;
  primaryAddress: {
    streetAddress: {
      line1: string;
    };
    addressLocality: {
      name: string;
    };
    addressRegion: {
      abbreviatedName: string;
    };
    postalCode: string;
  };
  telephone: DunsAccountTelephone[];
  duns: string;
}

const formatDunsAccounts = (accounts: DunsAccount[]) => {
  return accounts.map((account: DunsAccount) => {
    return {
      name: account.primaryName,
      address1: account.primaryAddress?.streetAddress?.line1,
      city: account.primaryAddress?.addressLocality?.name,
      state: account.primaryAddress?.addressRegion?.abbreviatedName,
      zipCode: account.primaryAddress?.postalCode,
      phoneNumber: account.telephone?.[0]?.telephoneNumber,
      dunsNumber: account.duns,
      isDnb: true,
    };
  });
};

interface UseChangeTeamIfSelectedAccountHasOneProps {
  setTeam: React.Dispatch<React.SetStateAction<GlobalStoredTeam | TeamOption>>;
  account?: _Account;
}

const useChangeTeamIfSelectedAccountHasOne = ({
  setTeam,
  account,
}: UseChangeTeamIfSelectedAccountHasOneProps) => {
  React.useEffect(() => {
    if (account?.teamId) {
      setTeam({
        id: account.teamId,
        name: account.teamName,
        value: {
          id: account.teamId,
          name: account.teamName,
        },
      });
    }
  }, [account]);
};
