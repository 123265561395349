import React from 'react';

const SVG = ({
  id = 'filter',
  style = {},
  fill = '#9192a4',
  width = '100%',
  className = '',
  viewBox = '0 0 19 17',
  title = 'Filter',
}) => {
  return (
    <svg
      fill={fill}
      width={width}
      style={style}
      className={`svg ${className}`}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>
          {`
          .${id}.svg-fill-filter { fill: ${fill}; stroke-width: 0.5px; }
        `}
        </style>
      </defs>
      <title>{title}</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            id="filter"
            className={`${id} svg-fill-filter`}
            d="M18.2.56a.54.54,0,0,0-.5-.31H.8A.55.55,0,0,0,.25.8a.52.52,0,0,0,.12.34L6.87,9V16.3a.55.55,0,0,0,.55.54.64.64,0,0,0,.24-.05L11.31,15a.55.55,0,0,0,.3-.49V9l6.5-7.89A.54.54,0,0,0,18.2.56ZM10.66,8.48a.56.56,0,0,0-.13.35v5.32L8,15.41V8.83a.56.56,0,0,0-.13-.35L2,1.34h14.6Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default SVG;
