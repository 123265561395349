import _ from 'lodash';
import React, { useMemo, useContext } from 'react';

import { TableCell as MuiTableCell } from '@mui/material';

import { TableContext } from './context.table';

export const TableCell = ({ key, ...props }) => {
  const { styles, ...ctx } = useContext(TableContext);
  const { column, row, ctx: context, ...htmlAttributes } = props;

  const data = _.get(row, column.name);
  let cellContent = data;
  let cellProps = {};

  if (_.isFunction(column.Cell)) {
    cellContent = column.Cell(props);
  }

  if (_.isObject(column.Cell)) {
    cellProps = _.omit(column.Cell, 'C');

    if (_.has(column.Cell, 'C')) {
      cellContent = column.Cell.C(props);
    }
  }

  const classes = useMemo(() => {
    if (styles) {
      return typeof styles.cell === 'function'
        ? styles.cell(data)
        : styles.cell || {};
    }

    return {};
  }, [styles, data]);

  const inlineStyle = useMemo(() => {
    return ctx.fixed
      ? {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ...cellProps.style,
        }
      : props.style;
  }, [ctx.fixed, cellProps.style, props.style]);

  if (column.show === false) {
    return null;
  }

  return (
    <MuiTableCell
      key={key}
      {...htmlAttributes}
      align="left"
      classes={classes}
      {...cellProps}
      style={inlineStyle}
    >
      {cellContent}
    </MuiTableCell>
  );
};

TableCell.displayName = 'TableCell';
