import React from 'react';

//mui
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CloudDownload } from '@mui/icons-material';
import AdminAccountsQuoteDrawerHeader from '../AdminAccountsQuoteDrawerHeader';

//components
import RowDataWrapper from './RowDataWrapper';
import AdminQuoteDrawerSkeleton from '../AdminQuoteDrawerSkeleton';

//services
import { downloadBinder } from '../../../../../inbox/QuotesService';
import { DocumentDownloader } from '../../../../../components/fileUploader/DocumentDownloader';

interface BinderTabViewProps {
  binders?: any[];
  isLoading: boolean;
}

const BinderTabView: React.FC<BinderTabViewProps> = ({
  binders = [],
  isLoading,
}) => {
  const classes = useStyles();

  if (isLoading) {
    return <AdminQuoteDrawerSkeleton />;
  }

  return (
    <>
      {!binders.length ? (
        <Box>No Binders to view</Box>
      ) : (
        <Box className={classes.container}>
          <AdminAccountsQuoteDrawerHeader
            headerContent={binderHeaderContentProp}
          />
          {binders.map((binder: any, index: number) => (
            <RowDataWrapper key={index} index={index}>
              <Box className={classes.binderNumber}>
                {binder.ui_binderNumber}
              </Box>
              <Box className={classes.wideColumn}>{binder.ui_limit}</Box>
              <Box className={classes.wideColumn}>{binder.ui_premium}</Box>
              <Box className={classes.wideColumn}>{binder.ui_status}</Box>
              <Box className={classes.wideColumn}>
                <DocumentDownloader
                  id={binder.accountId}
                  name={`${binder.companyName}-${binder.quoteNumber}-Cowbell-Binder`}
                  type=".pdf"
                  onDownload={() =>
                    downloadBinder(
                      binder.accountId,
                      binder.quoteId,
                      binder.boundOn
                    )
                  }
                >
                  {({ handleDocumentDownload }) => (
                    <CloudDownload onClick={handleDocumentDownload} />
                  )}
                </DocumentDownloader>
              </Box>
            </RowDataWrapper>
          ))}
        </Box>
      )}
    </>
  );
};

const binderHeaderContentProp = [
  {
    style: { width: '25%', textAlign: 'left' },
    text: 'BINDER #',
  },
  {
    style: { width: '18.75%' },
    text: 'LIMIT',
  },
  {
    style: { width: '18.75%' },
    text: 'PREMIUM',
  },
  {
    style: { width: '18.75%' },
    text: 'STATUS',
  },
  {
    style: { width: '18.75%' },
    text: 'ACTIONS',
  },
];

export default BinderTabView;

const useStyles = makeStyles(({ palette }) => ({
  container: {
    border: `solid 1px ${palette.primary.border}`,
  },
  binderNumber: {
    width: '25%',
    textAlign: 'left',
  },
  wideColumn: {
    width: '18.75%',
  },
}));
