import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// lodash
import _ from 'lodash';

import {
  Box as MuiBox,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography as TypographyBase,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';

import {
  TextField as TextFieldBase,
  SimpleSelect,
} from '../../../../components/inputs';
import { withFormController } from '../../../../components/hocs/forms';
import CbButtonBase from '../../../../components/Buttons/CbButton';
import { getClaimsPanel } from '../../../../api/claims/claims-panel.api';
import {
  createClaimInvoice,
  getDefaultReserveCategory,
  getTotalReserveCategories,
  updateClaimInvoice,
} from '../../../../api/claims/claim-invoices.api';
import { Uploadzone } from '../../../../components/fileUploader/v2/UploadZone';
import useUploadzone from '../../../../components/fileUploader/v2/hooks/useUploadzone';
import { uploadDocumentV3 } from '../../../../api/documents.api';
import { utcForAPI } from '../../../../utils/date.utils';
import { CLAIMS_INVOICES, CLAIMS_INVOICES_AGGS } from '../../claims.constants';
import { withShowable } from '../../../_global/lib/withShowable';
import { StepperModal } from '../../../../components/modals/v2/StepperModal/StepperModal';
import LanguageCurrencyFieldBase from '../../../../components/inputs/LanguageCurrencyField';

const schema = Yup.object().shape({
  vendorName: Yup.string().required().label('Vendor'),
  vendorSubCategory: Yup.string().required().label('Expert Type'),
  invoiceDate: Yup.date().required().label('Invoice Date'),
  invoiceNumber: Yup.string().required().label('Invoice Number'),
  amount: Yup.string().required().label('amount'),
  reserveCategory: Yup.string().required().label('Reserve Category'),
});

const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);
const TextField = withFormController(TextFieldBase);
const Select = withFormController(SimpleSelect);
const Typography = withShowable(TypographyBase);
const Box = withShowable(MuiBox);
const CbButton = withShowable(CbButtonBase);

const AddEditClaimInvoice = ({ data = {}, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [reserveCat, setReserveCategories] = React.useState([]);
  const { claim = {}, invoice = {} } = data;

  const isEdit = !_.isEmpty(invoice);

  // initialize file upload state and handlers
  const { files, handleUploads, ...uploadZoneParams } = useUploadzone();
  const asyncUploadFunc = React.useCallback(
    ({ file, axiosConfig }) => {
      const formData = new FormData();
      formData.append('file', file);

      return uploadDocumentV3({
        data: formData,
        params: {
          accountId: _.get(claim, 'accountId'),
          agencyId: _.get(claim, 'agencyId'),
          docType: 'Claim',
          docName: file.name,
          temp: true,
        },
        ...axiosConfig,
      });
    },
    [claim]
  );

  const defaultValues = {
    ..._.pick(invoice, [
      'vendorName',
      'vendorSubCategory',
      'invoiceNumber',
      'amount',
      'comment',
    ]),
    reserveCategory: invoice.reserveCategory || '',
    invoiceDate: utcForAPI(invoice.invoiceDate),
  };

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  // fetch claim panel experts for dropdown
  const { data: panel = {} } = useQuery(['panel-experts'], () =>
    getClaimsPanel(_.get(claim, 'id')).then((resp) => ({ ...resp.data }))
  );

  // get derived state for select fields
  const vendorName = methods.watch('vendorName');
  const vendorSubCategory = methods.watch('vendorSubCategory');
  methods.watch('reserveCategory');

  React.useEffect(() => {
    getTotalReserveCategories(_.get(claim, 'id'))
      .then((response) => {
        setReserveCategories(response.data);
      })
      .catch(() => []);

    if (!isEdit && !_.isUndefined(vendorSubCategory)) {
      getDefaultReserveCategory(vendorSubCategory)
        .then((resp) => {
          methods.setValue('reserveCategory', Object.values(resp.data)[0]);
        })
        .catch(() => []);
    }
    // eslint-disable-next-line
  }, [claim, vendorSubCategory]);

  const vendorOptions = React.useMemo(() => {
    if (Array.isArray(panel.data)) {
      return mapStringsToOptions(
        panel.data.map((expert) => _.get(expert, 'vendorName'))
      );
    }
  }, [panel]);

  const selectedVendor = React.useMemo(() => {
    if (vendorName && Array.isArray(panel.data)) {
      const [vendorData] = panel.data.filter(
        (expert) => expert.vendorName === vendorName
      );
      return vendorData;
    }
  }, [panel, vendorName]);

  const subcategories = React.useMemo(() => {
    if (selectedVendor) {
      return mapStringsToOptions(_.get(selectedVendor, 'vendorSubCategories'));
    }
    return [];
  }, [selectedVendor]);

  const onSubmit = React.useCallback(
    (formData) => {
      const payload = {
        ...formData,
        amount: formData.amount.replace(/[^0-9.-]+/g, ''),
        vendorId: _.get(selectedVendor, 'vendorId'),
        invoiceDate: utcForAPI(formData.invoiceDate),
      };

      const createOrUpdateInvoice = (docData) =>
        _.isEmpty(invoice)
          ? createClaimInvoice(claim.id, {
              data: { ...payload, invoice: docData },
            })
          : updateClaimInvoice(claim.id, invoice.id, {
              data: { ...payload, invoice: docData },
            });

      const handleInvoiceWithUpload = () => {
        handleUploads({ asyncUploadFunc })
          .then((resp) => {
            const { data: docData } = resp[0];
            createOrUpdateInvoice(docData)
              .then(() => {
                enqueueSnackbar(
                  `Invoice Successfully ${
                    _.isEmpty(invoice) ? 'Uploaded' : 'Updated'
                  } !`,
                  {
                    variant: 'success',
                  }
                );
                queryClient.invalidateQueries([CLAIMS_INVOICES]);
                queryClient.invalidateQueries([CLAIMS_INVOICES_AGGS]);
              })
              .catch((error) => {
                enqueueSnackbar(
                  _.get(
                    error,
                    'response.data.message',
                    _.get(
                      error,
                      'response.data',
                      'Something went wrong while processing the invoice.'
                    )
                  ),
                  { variant: 'error' }
                );
              });
          })
          .catch(() =>
            enqueueSnackbar(
              'There was a problem uploading your document, please try again',
              { variant: 'error' }
            )
          );
        props.close();
      };

      // if adding a new invoice
      if (_.isEmpty(invoice)) {
        if (!files) {
          enqueueSnackbar('Please upload an invoice to proceed.', {
            variant: 'error',
          });
        } else {
          handleInvoiceWithUpload();
        }
        // if editing an existing invoice and there are files to upload
      } else if (files && files.length > 0) {
        handleInvoiceWithUpload();
        // if editing an existing invoice and there are no files to upload
      } else {
        createOrUpdateInvoice(null).then(() => {
          enqueueSnackbar(
            `Invoice Successfully ${
              _.isEmpty(invoice) ? 'Uploaded' : 'Updated'
            } !`,
            {
              variant: 'success',
            }
          );
          queryClient.invalidateQueries([CLAIMS_INVOICES]);
          queryClient.invalidateQueries([CLAIMS_INVOICES_AGGS]);
          props.close();
        });
      }
    },
    // eslint-disable-next-line
    [asyncUploadFunc, claim.id, handleUploads, selectedVendor]
  );

  /* business logic */
  const hasAttachedInvoice = _.has(invoice, 'invoice.docId');
  /* end business logic */

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepperModal>
          <Panel1
            methods={methods}
            onCancel={props.close}
            validateInputs={methods.trigger}
            subcategories={subcategories}
            vendorOptions={vendorOptions}
            reserveCategories={reserveCat}
            invoice={invoice}
          />
          <Panel2
            files={files}
            uploadZoneParams={uploadZoneParams}
            invoice={invoice}
            hasAttachedInvoice={hasAttachedInvoice}
          />
        </StepperModal>
      </form>
    </FormProvider>
  );
};

export const AddEditClaimInvoiceConfig = {
  AddEditClaimInvoice: {
    component: AddEditClaimInvoice,
    config: {
      fullWidth: true,
    },
  },
};

const Panel1 = ({
  onCancel,
  validateInputs,
  subcategories = [],
  vendorOptions = [],
  stepperProps,
}) => {
  const classes = usePanel1Styles();

  const onNext = React.useCallback(() => {
    validateInputs().then((isValid) =>
      isValid ? stepperProps.goForward() : null
    );
  }, [stepperProps, validateInputs]);

  return (
    <>
      <DialogContent style={{ paddingBottom: '1rem' }}>
        <Grid container spacing={1}>
          <Grid container spacing={1} direction="row">
            <Grid item md={6}>
              <Select
                name="vendorName"
                label="Vendor Name"
                required
                options={vendorOptions}
                classes={classes}
              />
            </Grid>
            <Grid item md={6}>
              <Select
                name="vendorSubCategory"
                label="Expert Type/Subcategory"
                required
                options={subcategories}
                classes={classes}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="baseline"
          >
            <Grid item md={12}>
              <TextField
                name="invoiceNumber"
                label="Vendor's Invoice #"
                required
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="baseline"
          >
            <Grid item md={6} style={{ paddingBottom: '3.33rem' }}>
              <TextField
                name="invoiceDate"
                label="Invoice Date"
                type="date"
                required
              />
            </Grid>
            <Grid item md={6}>
              <LanguageCurrencyField
                name="amount"
                label="Invoice Amount"
                required
                inputProps={{ style: { marginTop: '4px' } }}
              />
            </Grid>
          </Grid>
          <Grid item md={12}>
            <TextField name="comment" label="Comment" multiline rows={4} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box>Step {stepperProps.stepNumber} of 2</Box>
        <Box>
          <CbButton styleName="cancel" onClick={onCancel}>
            Cancel
          </CbButton>
          <CbButton styleName="ctaButton" onClick={onNext}>
            Next
          </CbButton>
        </Box>
      </DialogActions>
    </>
  );
};

const usePanel1Styles = makeStyles(({ palette }) => ({
  root: { backgroundColor: palette.background.modal },
  disabled: { backgroundColor: palette.background.default },
}));

const Panel2 = ({
  files = [],
  uploadZoneParams = {},
  invoice,
  hasAttachedInvoice,
  stepperProps,
}) => {
  const [showDropzone, setShowDropzone] = React.useState(!hasAttachedInvoice);
  const document = _.get(invoice, 'invoice', {});

  return (
    <>
      <DialogContent style={{ paddingTop: '3rem' }}>
        <Box show={showDropzone}>
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginBottom: '2rem' }}
          >
            <DialogContentText>Upload Invoice</DialogContentText>
            <Typography variant="subtitle2" show={_.isEmpty(invoice)}>
              It is required to upload the vendor invoice in order to proceed.
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
            <Uploadzone {...uploadZoneParams} files={files} />
          </Box>
        </Box>

        <Box
          show={!showDropzone}
          style={{ textAlign: 'center', paddingBottom: '2px' }}
        >
          <DialogContentText>Existing document</DialogContentText>
          <p>
            This invoice has an existing document{' '}
            <strong>{document.docName}</strong>, if you would like to upload a
            new document choose "Re-upload Document" below. Otherwise you can
            simply skip this step and save your changes.
          </p>
          <CbButton styleName="cancel" onClick={() => setShowDropzone(true)}>
            Re-upload Document
          </CbButton>
          <CbButton styleName="ctaButton" type="submit">
            Skip this step
          </CbButton>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box>Step {stepperProps.stepNumber} of 2</Box>
        <Box>
          <CbButton styleName="cancel" onClick={stepperProps.goBack}>
            Back
          </CbButton>
          <CbButton styleName="ctaButton" type="submit">
            Add
          </CbButton>
        </Box>
      </DialogActions>
    </>
  );
};

// helpers
const mapStringsToOptions = (strings = []) => {
  return strings.map((string) => ({ label: string, value: string }));
};
