import React from 'react';
import Moment from 'moment';
import { withStyles } from '@mui/styles';
import LogoHeader from '../../entry/LogoHeader';
import SocialMedia from '../../entry/SocialMedia';

const styles = ({ config }) => {
  return {
    modalTitle: {
      fontFamily:
        '"Titillium Web", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif', // config.fonts.stackSans,
      fontSize: '1.16rem', // config.textSizes.tertia,
      fontWeight: 300,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#ffffff', // palette.text.text8,
    },
    header: {
      fontSize: '2.5rem', // _config.textSizes.nonpareil,
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
    },
    platform: {
      fontSize: '1.33rem', // _config.textSizes.primer,
      fontWeight: 'normal',
      color: '#c9cdd9', // config.colors.almostWhite,
      lineHeight: 1.22,
    },
    contact: {
      padding: '3rem',
      margin: 0,
      lineHeight: 1.22,
      color: config.colors.darkGreySoft,
      fontSize: '1.166666666666667rem',
    },
    publicContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'inherit',
      color: '#c9cdd9',
      fontSize: '10px !important',
      '& a': {
        textDecoration: 'underline',
        color: '#2180e2',
        fontSize: '1.166666666666667rem',
      },
      '& .public-logo': {
        marginBottom: 40,
        maxWidth: 400,
        width: 250,
      },
    },

    publicContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 'auto',
    },
    publicFooter: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 2,
      marginTop: 'auto',
      bottom: '0',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 10,
    },

    termsContainer: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '10px', // config.textSizes.petite,

      '& > *': {
        margin: '0 10px',
      },
    },
    socialContainer: {
      fontSize: '10px', // config.textSizes.primer,
    },

    entry: {
      display: 'flex',
      flexDirection: 'row',
      margin: 0,
      justifyContent: 'center',
    },
  };
};

const ApplicationSigned = withStyles(styles)(({ classes }) => {
  return (
    <main className={classes.publicContentWrapper}>
      <div className={classes.publicContentContainer}>
        <LogoHeader />
        <div className={classes.modalTitle}>
          <p className={classes.header}>
            Thank you for signing Cowbell Application!
          </p>

          <div className={classes.platform}>
            Your policy documents are being generated.
            <br />
            Please check your email or contact your agent for next steps and
            payment options.
          </div>

          <p className={classes.contact}>
            Please contact{' '}
            <a href="email:support@cowbellcyber.ai">support@cowbellcyber.ai</a>{' '}
            if you have any questions
          </p>
        </div>
      </div>
      <footer className={classes.publicFooter}>
        <div className={classes.termsContainer}>
          <div className={classes.entry}>
            <a
              href="https://cowbell.insure/terms/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Service
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              href="https://cowbell.insure/privacy/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
          <div
            className={classes.entry}
          >{`© ${Moment().year()} Cowbell Cyber Inc. All Rights Reserved`}</div>
          <div className={classes.entry}>
            Cowbell Insurance Agency LLC, CDI License #0N04366
          </div>
        </div>

        <div className={classes.socialContainer}>
          <SocialMedia />
        </div>
      </footer>
    </main>
  );
});

export default ApplicationSigned;
