import React from 'react';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_REGION = 'UPDATE_REGION';

export const useFormAggregator = ({ initialState = {} } = {}) => {
  const [aggregateFormData = {}, dispatch] = React.useReducer(
    formAggregatorReducer,
    initialState
  );

  const updateAggregateFormData = React.useCallback((payload) => {
    return dispatch({ type: UPDATE_FORM_DATA, payload });
  }, []);

  const updateRegion = React.useCallback((payload) => {
    return dispatch({ type: UPDATE_REGION, payload });
  }, []);

  return { aggregateFormData, updateAggregateFormData, updateRegion };
};

const formAggregatorReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_REGION:
      return {
        ...state,
        region: action.payload,
      };
    default:
      return state;
  }
};
