import React from 'react';
import { Box, CircularProgress, Divider } from '@mui/material';
import { styled } from '@mui/styles';

import { useQuery } from '@tanstack/react-query';

import Numeral from 'numeral';
import { DownloadIcon } from '../../../../_assets/svg/formsLibrary/DownloadIcon.svg';
import { fetchMidtermEndorsementsList } from '../../../../policies/PolicyService';
import { withShowable } from '../../../_global/lib/withShowable';
import { DownloadDocs } from '../../../../_assets/svg/DownloadDocs.svg';
import { Modal } from '../../../../components/modals/v2/helpers/v2.modal.helpers';

import { ProductTypes } from '../../../../types';
import { toUniversalUtcDate } from '../../../../utils/date.utils';
import { useAgencyPoliciesOptionHandlers as usePolicyCommonHandlers } from '../../../agency/logic/policies/agency-policies.logic';

export default function PolicyHolderPolicyEndorsementListing({
  policy,
  productType,
  headerText,
}) {
  const { data: endorsementList, isInitialLoading } = useQuery(
    ['midtermEndorsementListing', policy.id],
    () =>
      fetchMidTermEndorsements({
        productType,
        policyNumber: policy.policyNumber,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: productType !== ProductTypes.primecloud,
    }
  );

  function displayPolicyMTEModal(policyId) {
    Modal.show('PolicyMTE', { data: { policyId, product: productType } });
  }

  if (productType === ProductTypes.primecloud) {
    return null;
  }

  if (isInitialLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  if (!endorsementList || endorsementList.length === 0) return null;

  return (
    <Container>
      <HeaderContainer>
        <Header>{headerText || 'MidTerm Endorsements'}</Header>
        <Divider />
        <HeaderRow>
          <HeaderCell width="40%" justifyContent="flex-end">
            Description
          </HeaderCell>
          <Box ml={1}>
            <Divider orientation="vertical" flexItem />
          </Box>
          <HeaderCell width="15%">Effective Date</HeaderCell>
          <HeaderCell width="15%">Premium Difference</HeaderCell>
          <HeaderCell width="10%">Invoice</HeaderCell>
          <HeaderCell width="10%">Receipt</HeaderCell>
          <HeaderCell width="10%">Docs</HeaderCell>
        </HeaderRow>
      </HeaderContainer>
      <BodyContainer>
        {endorsementList.map((endorsement, idx) => (
          <BodyRow key={endorsement.id}>
            <BodyCell width="5%">
              <Box style={{ marginRight: '0.25rem' }}>
                {idx < 9 ? `0${idx + 1}` : idx + 1}
              </Box>
              <Divider flexItem orientation="vertical" />
            </BodyCell>
            <BodyCell width="35%" justifyContent="flex-end">
              <Link onClick={() => displayPolicyMTEModal(endorsement.id)}>
                Endorsement Dated {endorsement.uiCreated}
              </Link>
            </BodyCell>
            <Box ml={1}>
              <Divider orientation="vertical" flexItem />
            </Box>
            <BodyCell width="15%">{endorsement.uiEffectiveDate}</BodyCell>
            <BodyCell width="15%">{endorsement.uiPremiumDifference}</BodyCell>
            <DownloadCells
              endorsement={{ ...policy, ...endorsement }}
              productType={productType}
            />
          </BodyRow>
        ))}
      </BodyContainer>
    </Container>
  );
}

const DownloadCells = ({ endorsement, productType }) => {
  const { handleDownloadReceipt, handleDownloadDocs, handleDownloadInvoice } =
    usePolicyCommonHandlers({
      ...endorsement,
      isEndorsement: true,
    });

  return (
    <>
      <BodyCell width="10%" justifyContent="center">
        {endorsement.isInvoiceAvailable ? (
          <DownloadIcon onClick={handleDownloadInvoice} />
        ) : (
          '-'
        )}
      </BodyCell>
      <BodyCell width="10%" justifyContent="center">
        {endorsement.isReceiptAvailable ? (
          <DownloadIcon onClick={handleDownloadReceipt} />
        ) : (
          '-'
        )}
      </BodyCell>
      <BodyCell width="10%" justifyContent="center">
        {endorsement.isDocumentAvailable && productType != ProductTypes.p250 ? (
          <DownloadDocs onClick={handleDownloadDocs} />
        ) : (
          '-'
        )}
      </BodyCell>
    </>
  );
};

const LoadingContainer = styled(Box)(({ theme: { palette } }) => ({
  backgroundColor: palette.background.default,
  width: '100%',
  borderRadius: 4,
  minHeight: '7rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Container = styled(Box)(({ theme: { palette } }) => ({
  backgroundColor: palette.background.default,
  padding: '0.45rem',
  width: '100%',
  borderRadius: 5,
}));

const HeaderContainer = styled(Box)(({ theme: { palette } }) => ({
  backgroundColor: palette.background.default,
  width: '100%',
  borderTopRightRadius: 4,
  borderTopLeftRadius: 4,
  pading: 0,
}));

const BodyContainer = styled(Box)({
  width: '100%',
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
  padding: 0,
  overflow: 'hidden',
});

const Header = styled('h3')(({ theme: { config } }) => ({
  margin: 0,
  fontSize: config.textSizes.tertia,
  fontWeight: config.weights.normal,
  padding: '0.5rem',
}));

const FlexBox = styled(Box)(() => ({
  display: 'flex',
}));

const HeaderRow = styled(FlexBox)({
  display: 'flex',
  width: '100%',
  minHeight: '4rem',
});

const HeaderCell = styled(FlexBox)(({ justifyContent, theme: { config } }) => ({
  textAlign: 'center',
  fontSize: config.textSizes.tertia,
  lineHeight: 1.2,
  justifyContent: justifyContent || 'center',
  alignItems: 'center',
}));

const BodyCell = withShowable(
  styled(HeaderCell)(({ theme: { config } }) => ({
    fontSize: config.textSizes.normal,
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
  }))
);

const BodyRow = styled(HeaderRow)(({ theme: { palette } }) => ({
  minHeight: '2.15rem',
  display: 'flex',
  alignItems: 'center',
  '&:nth-child(odd)': {
    backgroundColor: palette.background.connectorQuickView,
  },
  '&:nth-child(even)': {
    backgroundColor: palette.background.default,
  },
}));

const Link = styled('a')({
  textDecoration: 'underline',
  cursor: 'pointer',
});

function getPremiumDiffernce(premiumDiff) {
  if (!(typeof premiumDiff === 'number')) return null;
  if (premiumDiff >= 0) return Numeral(premiumDiff).format('$0,000.00');
  return `(${Numeral(premiumDiff).format('$0,000.00')})`;
}

export const fetchMidTermEndorsements = (args) => {
  return fetchMidtermEndorsementsList(args).then(({ data }) =>
    data.map((endorsement) => ({
      ...endorsement,
      uiCreated: toUniversalUtcDate(endorsement.created),
      uiEffectiveDate: toUniversalUtcDate(endorsement.effectiveDate),
      uiPremiumDifference: getPremiumDiffernce(endorsement.premiumDifference),
    }))
  );
};
