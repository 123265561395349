import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useSnackbar } from 'notistack';

// components
import { TableValidationNotification } from '../supporting/validation-notification.table';

export function withColumnValidation(fallback) {
  return (Component) => {
    const C = (props) => {
      const { columns, q, updateUrl } = props;
      const { enqueueSnackbar } = useSnackbar();

      React.useEffect(() => {
        const columnRef = columns.reduce(
          (acc, { name }) => ({ ...acc, [name]: true }),
          {}
        );
        const validColumn = columnRef[q.orderBy];
        const validOrder = q.order === 'asc' || q.order === 'desc';

        if (!validColumn || !validOrder) {
          updateUrl({
            orderBy: validColumn ? q.orderBy : fallback.orderBy,
            order: validOrder ? q.order : fallback.order,
          });

          props.onSort('modified', validOrder ? q.order : 'asc');

          enqueueSnackbar('Platform update!', {
            content: (key, message) => (
              <TableValidationNotification id={key} message={message} />
            ),
            persist: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          });
        }
        // eslint-disable-next-line
      }, []);

      return <Component {...props} />;
    };

    C.displayName = `withColumnValidation(${
      Component.displayName || Component.name
    })`;
    C.wrappedComponent = Component;

    return hoistNonReactStatics(C, Component);
  };
}
