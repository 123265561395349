import { Box, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

const messageStyles = {
  marginLeft: '10px',
  position: 'absolute',
  top: '18px',
} as const;

type TableUpdatedSnackbarMessageProps = {
  onRefreshClick: () => void;
};

const TableUpdatedSnackbarMessage: React.FC<
  TableUpdatedSnackbarMessageProps
> = ({ onRefreshClick }) => {
  return (
    <Box>
      <IconButton
        onClick={onRefreshClick}
        style={{ display: 'initial', padding: '0' }}
        size="large"
      >
        <RefreshIcon fill="#ffffff" />
      </IconButton>
      <span style={messageStyles}>Table Updated</span>
    </Box>
  );
};

export default TableUpdatedSnackbarMessage;
