import React from 'react';
import { Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import type { WithChildrenProps } from '../../../../types/components/common';

export const AgencyDashboardListingError = ({
  children,
}: WithChildrenProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="75%"
    >
      <ErrorOutlineIcon style={{ fontSize: '4rem', marginBottom: '1rem' }} />
      {children}
    </Box>
  );
};
