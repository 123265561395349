import { createAction } from 'redux-actions';

import store from '../config/storeConfig';

const toggleModal = createAction('UI_TOGGLE_MODAL', (key, content, config) => ({
  key,
  content,
  config,
}));

/**
 * toggleModalDirect
 * @deprecated use new modal system Modal.show
 * if you must use old modal system, utilize the useToggleModal hook to avoid creating a dependency cycle
 */
export const toggleModalDirect = (...args) => {
  store.dispatch(toggleModal(...args));
};

/**
 * toggleModalDiffered
 * @deprecated use new modal system Modal.differShow
 */
export const toggleModalDiffered = (...args) => {
  return () => {
    return store.dispatch(toggleModal(...args));
  };
};

export const navigateTo = (incomingUrl) => {
  return () => {
    let url = incomingUrl;

    if (incomingUrl) {
      url = `${window.location.protocol}//${window.location.host}${url}`;
      const win = window.open(url, '_blank');
      win.focus();
    }
  };
};
