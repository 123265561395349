import React from 'react';
import _ from 'lodash';
import type { AxiosResponse } from 'axios';
import Moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';

import { useSnackbar } from 'notistack';

import type { DialogProps } from '@mui/material';
import { Dialog } from '@mui/material';

import { useSteps } from '../../../components/hooks/useSteps';
import { ShowableBox } from './helpers';

// Step components
import PolicyContactDetailsForm from './steps/PolicyContactDetailsForm';
import PolicySurplusComplianceForm from './steps/PolicySurplusComplianceForm';
import PolicyPremiumDetailsForm from './steps/PolicyPremiumDetailsForm';
import PolicyGeneratedConfirmation from './steps/PolicyGeneratedConfirmation';
import PolicyUpload from './steps/PolicyUpload';
import PolicyUploadConfirmation from './steps/PolicyUploadConfirmation';

import type { CreateEditP500FlowStep } from './steps/config';
import { CreateEditP500FlowSteps } from './steps/config';

import { initialFormDataCreator } from './utils/form-data-creator';

// types
import type { AccountDto } from '../../../types';
import type { IUseStepsData, UploadDocResponseDTO } from './types';

// services
import { truncateString } from '../../../utils';
import { makePrimecloudPolicyRequest } from './api';
import { updatePrimecloudPolicyDoc } from '../../../api/policies.api';
import { delayedEvent } from '../../../utils/eventUtils';
import { sleep } from '../../../utils/appUtils';
// constants
import { COWBELL_ACCOUNTS } from '../../../components/tables/table_constants';

const steps = Object.keys(CreateEditP500FlowSteps);

export type AggregatedP500FormData = ReturnType<typeof initialFormDataCreator>;

interface ICreateEditP500PolicyModalProps extends DialogProps {
  data: {
    account: Pick<AccountDto, 'id' | 'name' | 'state'> & Partial<AccountDto>;
    policy?: Record<string, any>;
  };
  close: () => void;
}

const CreateEditPrimeCloudPolicyModal: React.FC<
  ICreateEditP500PolicyModalProps
> = ({ data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const stepper: any = useSteps({
    initialSteps: steps,
    startStep: !data.policy
      ? CreateEditP500FlowSteps.POLICY_DETAILS_CONTACT_STEP
      : CreateEditP500FlowSteps.POLICY_DOC_UPLOAD_STEP,
  });

  const initialFormData = React.useMemo(
    () => initialFormDataCreator(data.account),
    [data.account]
  );

  const [formsData, setFormsData] =
    React.useState<AggregatedP500FormData>(initialFormData);

  const [createdPolicy, setCreatedPolicy] = React.useState<{
    policyNumber: string;
    policyId: string;
    createdDate: string;
  } | null>(null);

  const [uploadedDocName, setUploadedDocName] = React.useState<string | null>(
    null
  );

  useFlowReset(props.open, Boolean(data.policy), stepper, [
    { dispatch: setFormsData, initialState: initialFormData },
    { dispatch: setUploadedDocName, initialState: null },
    { dispatch: setCreatedPolicy, initialState: null },
  ]);

  async function updatePolicyDocument(
    res: AxiosResponse<UploadDocResponseDTO>,
    onUpdateSuccess: () => void,
    onUpdateFailure: (err: Error) => void
  ) {
    if (!(createdPolicy?.policyId || data.policy?.id)) {
      throw new Error('There must be a policy ID before calling this function');
    }

    // BE doc service isn't guaranteed to have indexed the uploaded document immediately, so we need to wait a short time before calling the policy service
    await sleep(450);
    updatePrimecloudPolicyDoc(createdPolicy?.policyId ?? data.policy!.id, {
      data: { docInfo: res.data },
    })
      .then(() => {
        onUpdateSuccess();

        setUploadedDocName(res.data.docName);
        stepper.goForward();

        delayedEvent('table-refetch', 1500, 'adminPolicies');
        queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
      })
      .catch((err) => {
        onUpdateFailure(err);

        enqueueSnackbar(
          typeof err.response.data === 'string'
            ? truncateString(err.response.data, 60)
            : "There has been an error updating the policy's document",
          { variant: 'error' }
        );
      });
  }

  function updateAggregatedFormState(
    formValues: Partial<AggregatedP500FormData>
  ) {
    setFormsData((prevValues) => ({ ...prevValues, ...formValues }));
  }

  function handleSubformSubmit(formValues: Partial<AggregatedP500FormData>) {
    updateAggregatedFormState(formValues);

    if (!stepper.isLastStep) {
      stepper.goForward();
    }
  }

  function handleFinalSubformSubmit(
    subFormValues: Partial<AggregatedP500FormData>
  ) {
    makePrimecloudPolicyRequest(
      { ...formsData, ...subFormValues },
      data.account
    )
      .then((res) => {
        setCreatedPolicy({
          ...res.data,
          createdDate: Moment().format('YYYY-MM-DD'),
        });

        stepper.jumpTo('POLICY_GENERATED_CONFIRMATION_STEP');
        queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
      })
      .catch((err) =>
        enqueueSnackbar(
          typeof err.response.data === 'string'
            ? truncateString(err.response.data, 60)
            : 'There has been an issue creating the policy',
          { variant: 'error' }
        )
      );
  }

  return (
    <Dialog {...props}>
      <ShowableBox
        show={
          stepper.step === CreateEditP500FlowSteps.POLICY_DETAILS_CONTACT_STEP
        }
      >
        <PolicyContactDetailsForm
          stepperData={stepper}
          onClose={props.close}
          formsData={formsData}
          onFormSubmit={handleSubformSubmit}
          account={data.account}
        />
      </ShowableBox>

      <ShowableBox
        show={
          stepper.step ===
          CreateEditP500FlowSteps.POLICY_SURPLUS_COMPLIANCE_STEP
        }
      >
        <PolicySurplusComplianceForm
          stepperData={stepper}
          onClose={props.close}
          formsData={formsData}
          onFormSubmit={handleSubformSubmit}
          onGoBack={updateAggregatedFormState}
        />
      </ShowableBox>

      <ShowableBox
        show={
          stepper.step === CreateEditP500FlowSteps.POLICY_DETAILS_PREMIUM_STEP
        }
      >
        <PolicyPremiumDetailsForm
          stepperData={stepper}
          onClose={props.close}
          onFormSubmit={handleFinalSubformSubmit}
          onGoBack={updateAggregatedFormState}
          formsData={formsData}
          accountState={data.account?.state}
        />
      </ShowableBox>

      <ShowableBox
        show={
          stepper.step ===
          CreateEditP500FlowSteps.POLICY_GENERATED_CONFIRMATION_STEP
        }
      >
        <PolicyGeneratedConfirmation
          stepperData={stepper}
          onClose={props.close}
          confirmationMessage={`PrimeCloud Policy Number${createdPolicy?.policyNumber} has been created!`}
        />
      </ShowableBox>

      <ShowableBox
        show={stepper.step === CreateEditP500FlowSteps.POLICY_DOC_UPLOAD_STEP}
      >
        <PolicyUpload
          stepperData={stepper}
          onClose={props.close}
          onUploadSuccess={updatePolicyDocument}
          policy={{
            accountId: data.account.id,
            id: createdPolicy ? createdPolicy.policyId : data.policy?.id,
            agencyId: createdPolicy
              ? formsData.agency.value
              : data.policy?.agencyId,
            policyNumber: createdPolicy
              ? createdPolicy.policyNumber
              : data.policy?.policyNumber,
            created: createdPolicy
              ? createdPolicy.createdDate
              : Moment.utc(data.policy?.created).format('YYYY-MM-DD'),
          }}
        />
      </ShowableBox>

      <ShowableBox
        show={
          stepper.step ===
          CreateEditP500FlowSteps.POLICY_DOC_UPLOAD_CONFIRMATION_STEP
        }
      >
        <PolicyUploadConfirmation
          stepperData={stepper}
          onClose={props.close}
          policyFileName={uploadedDocName}
        />
      </ShowableBox>
    </Dialog>
  );
};

export const CreateEditPrimeCloudPolicyModalConfig = {
  CreateEditPrimeCloudPolicyModal: {
    component: CreateEditPrimeCloudPolicyModal,
    config: {
      fullWidth: true,
      maxWidth: 'md',
      override: true,
    },
  },
};

function useFlowReset(
  isOpen: boolean,
  wasOpenedWithPolicy: boolean,
  stepper: IUseStepsData<CreateEditP500FlowStep>,
  stateSlicesToReset: {
    dispatch: React.SetStateAction<any>;
    initialState: any;
  }[]
) {
  // reset flow upon open
  React.useEffect(() => {
    if (isOpen && wasOpenedWithPolicy) {
      stateSlicesToReset.forEach((slice) => slice.dispatch(slice.initialState));
      stepper.jumpTo(CreateEditP500FlowSteps.POLICY_DOC_UPLOAD_STEP);
    }

    if (isOpen && !wasOpenedWithPolicy) {
      stateSlicesToReset.forEach((slice) => slice.dispatch(slice.initialState));
      stepper.jumpTo(CreateEditP500FlowSteps.POLICY_DETAILS_CONTACT_STEP);
    }
  }, [isOpen, wasOpenedWithPolicy]);
}
