import { Box } from '@mui/material';

import { debounce } from 'lodash';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from '@mui/styles';
import { reduceForSelect } from '../../../console/admin/users/UsersUtils';
import { AutoCompleteBase } from './AutoCompleteBase';

const minuteAsMS = 1000 * 60;

export const ManagedFetchingAutoComplete = ({
  fetchFunc,
  itemsToSelectOptionsTransformer = reduceForSelect,
  queryKey,
  textFieldProps = {},
  ...props
}) => {
  const classes = useStyles();
  const [input, setInput] = React.useState('');

  const { data: availableOptions } = useQuery(
    [...queryKey, input],
    () =>
      fetchFunc({ search: input }).then((data) => {
        return itemsToSelectOptionsTransformer(data);
      }),
    {
      placeholderData: [],
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 1 * minuteAsMS,
      onError: () => {},
    }
  );

  const handleInputChange = debounce((event, newValue) => {
    setInput(newValue);
  }, 300);

  function handleSelected(event, selectedOption) {
    props.onChange(selectedOption);
  }

  return (
    <AutoCompleteBase
      isOptionEqualToValue={(option, value) => option.value === value.value}
      {...props}
      options={availableOptions}
      onInputChange={handleInputChange}
      onChange={handleSelected}
      textFieldProps={{ classes, ...textFieldProps }}
      renderOption={(optionProps, option) => {
        return (
          <li {...optionProps}>
            <Box component="li" classes={classes}>
              {option.label}
            </Box>
          </li>
        );
      }}
    />
  );
};

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
});
