import React from 'react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { fetchBinders } from '../../../../../inbox/QuotesService';

export const useGetBinderInformation = ({ quoteDetails, quoteProgress }) => {
  const {
    data: allBinders = [],
    isLoading,
    isFetching,
  } = useQuery(['binder-information', quoteDetails.accountId], () => {
    if (quoteProgress.quoteAgencyStatus === 'BOUND') {
      return fetchBinders({
        search: quoteDetails.accountId,
      }).then(({ data }) => data.content);
    }

    return [];
  });

  const binder = React.useMemo(() => {
    return _.find(allBinders, ['quoteId', quoteDetails.id]);
  }, [allBinders, quoteDetails.id]);

  return { binder, allBinders, isLoadingBinderData: isLoading || isFetching };
};
