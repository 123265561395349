import React from 'react';
import { SvgIcon } from '@mui/material';

export default function DetailsButton({
  fill = '#2180e2',
  fill1 = '#fff',
  ...props
}) {
  return (
    <SvgIcon width="20.056" height="33" viewBox="0 0 20.056 33" {...props}>
      <g transform="translate(-2962 6357)">
        <rect
          width="32"
          height="32"
          rx="5"
          transform="translate(2962 -6357)"
          fill={fill}
        />
        <g transform="translate(2969 -6352)">
          <g transform="translate(0 -0.001)">
            <path
              d="M9.43,16.69h-4.8a.716.716,0,1,0,0,1.433h4.83a.716.716,0,0,0,0-1.433Z"
              transform="translate(-1.34 -5.722)"
              fill={fill1}
            />
            <path
              d="M12.745,11.908H4.591a.716.716,0,1,0,0,1.426h8.155a.716.716,0,1,0,0-1.426Z"
              transform="translate(-1.305 -4.082)"
              fill={fill1}
            />
            <path
              d="M2.839,19.589H1.419V1.413H11.565V5.355a.559.559,0,0,0,.552.552h4.068v7.32c.486.256.959.545,1.419.848V5.066a.539.539,0,0,0-.164-.388L12.9.158A.572.572,0,0,0,12.485,0H.552A.559.559,0,0,0,0,.552v19.9a.559.559,0,0,0,.552.572H4.495a19.259,19.259,0,0,1-1.656-1.439ZM12.985,2.247,15.258,4.5H12.985Z"
              transform="translate(0 0.001)"
              fill={fill1}
            />
            <path
              d="M2.559,7.754A15.9,15.9,0,0,1,.128,5.421a.585.585,0,0,1,0-.729A16.646,16.646,0,0,1,2.559,2.359,9.822,9.822,0,0,1,8.434,0a9.8,9.8,0,0,1,5.88,2.359,16.962,16.962,0,0,1,2.439,2.333.585.585,0,0,1,.032.729,16.234,16.234,0,0,1-2.438,2.333,10.014,10.014,0,0,1-5.914,2.359A9.8,9.8,0,0,1,2.559,7.754Zm.69-4.488A16.3,16.3,0,0,0,1.278,5.06,16.61,16.61,0,0,0,3.25,6.847,8.828,8.828,0,0,0,8.4,8.949a8.919,8.919,0,0,0,5.158-2.1A16.694,16.694,0,0,0,15.531,5.06a16.629,16.629,0,0,0-1.972-1.794,11.306,11.306,0,0,0-2.454-1.49,3.529,3.529,0,1,1-5.414.007A11.763,11.763,0,0,0,3.25,3.266ZM6.266,3.1a1.735,1.735,0,1,0,1.6-1.073A1.73,1.73,0,0,0,6.266,3.1Z"
              transform="translate(3.158 12.887)"
              fill={fill1}
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
