import { SelectComponent } from '../helpers';
import { useMultiSelectState } from '../useMultiSelectState';

function SelectCell({ row }) {
  const { selectedRows, handleRowSelect } = useMultiSelectState();
  // eslint-disable-next-line
  const isSelected = selectedRows.hasOwnProperty(row.id);

  return (
    <SelectComponent
      checked={isSelected}
      onChange={() => handleRowSelect(row.id, row)}
    />
  );
}

export default SelectCell;
