import React from 'react';
import clsx from 'classnames';
import Moment from 'moment';
import { Box as MuiBox, Button as MuiButton, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Visibility } from '@mui/icons-material';

// components
import CbButton from '../../../../../components/Buttons/CbButton';
import { InputLabelBase } from '../../../../../components/inputs/InputLabelBase';
import { TextFieldBase } from '../../../../../components/inputs/TextFieldBase';
import { DocumentPreviewer } from '../../../../../components/fileUploader/DocumentPreviewer';

// hooks and hocs
import { useFileTypeIcons } from '../../../../../components/fileUploader/useFileTypeIcons';
import { withFormController } from '../../../../../components/hocs/forms';
import { withShowable } from '../../../../_global/lib/withShowable';

const TextField = withFormController(TextFieldBase);
const Button = withShowable(MuiButton);
const Box = withShowable(MuiBox);

const TextFieldFilePreview = ({
  files,
  isRepickDisabled = false,
  rePickFilesFromOS,
  ...props
}) => {
  if (!(files && files.length > 0)) {
    return null;
  }

  return (
    <>
      {files.map((file, idx) => (
        <DocPreview
          file={file}
          key={file.name}
          name={`fileName-${idx}`}
          datePickerName={`docSignedOn-${idx}`}
          index={idx}
          docType={file.docType}
          {...props}
        />
      ))}
      <Box mt={2} mb={2}>
        <CbButton
          styleName="ctaButton"
          size="small"
          onClick={rePickFilesFromOS}
          disabled={isRepickDisabled || props.disabled}
        >
          Repick Files
        </CbButton>
      </Box>
    </>
  );
};

export default TextFieldFilePreview;

const DocPreview = ({
  file,
  name,
  index,
  disabled,
  onRemoveFile,
  docsPreviouslyUploaded,
  docType,
  userSelectedFiles,
  datePickerName,
  displayDatePicker = false,
  min,
  max,
}) => {
  const { filename, filesize, ...classes } = useStyles();
  const extension = file?.name?.split('.').pop();
  const icon = useFileTypeIcons(file.fileType || extension);

  return (
    <>
      <Box
        className={clsx(classes.docContainer, classes.minWidth, {
          [classes.paddingTop]: index === 0,
        })}
      >
        <Box flex={1}>
          <Box>File Name:</Box>
          <Box>
            <TextField
              disabled={disabled || !userSelectedFiles}
              name={name}
              defaultValue={file.name}
            />
          </Box>
        </Box>
        <Box show={displayDatePicker}>
          <InputLabelBase style={{ padding: 0 }} required>
            Date document signed:
          </InputLabelBase>
          <TextField
            name={datePickerName}
            disabled={disabled}
            type="date"
            InputProps={{
              inputProps: {
                min: Moment.utc(min).format('YYYY-MM-DD'),
                max: Moment.utc(max).format('YYYY-MM-DD'),
              },
            }}
          />
        </Box>
        <Box>
          <Box marginBottom="0.5rem">File Type:</Box>
          <Box>{icon}</Box>
        </Box>
        <Box>
          <Button
            show={!docsPreviouslyUploaded}
            color="primary"
            className={classes.remove}
            disabled={disabled}
            size="small"
            variant="text"
            onClick={() => onRemoveFile(file.name)}
          >
            Remove
          </Button>
          <Box
            className={classes.previouslyUploadedDocContainer}
            show={docsPreviouslyUploaded}
          >
            <Box width="10rem">
              <Box>Doc Type:</Box>
              <Box>
                {file.docType ? file.docType.split('_').join(' ') : docType}
              </Box>
            </Box>
            <Box show={!!file.id}>
              <DocumentPreviewer docId={file.id}>
                <IconButton size="medium">
                  <Visibility />
                </IconButton>
              </DocumentPreviewer>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  docContainer: {
    display: 'flex',
    gap: '3rem',
    width: '100%',
  },
  minWidth: {
    minWidth: '50rem',
  },
  remove: {
    marginTop: '1rem',
  },
  previouslyUploadedDocContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
  },
}));
