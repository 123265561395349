import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react';

export const CircularProgressIndicatorWithPercentage = React.memo(
  ({ value, ...props }) => {
    return (
      <Container>
        <CircularProgress
          size={45}
          variant="determinate"
          value={value}
          {...props}
        />
        <PercentageContainer>
          <PercentageText>{`${value}%`}</PercentageText>
        </PercentageContainer>
      </Container>
    );
  }
);

const Container = styled(Box)(() => ({
  position: 'relative',
  display: 'inline-flex',
}));

const PercentageContainer = styled(Box)(() => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const PercentageText = styled('p')(({ theme: { palette, config } }) => ({
  margin: 0,
  color: palette.primary.main,
  fontWeight: config.weights.bold,
}));
