import { DialogActions, Typography } from '@mui/material';
import CbButton from '../../../../../../Buttons/CbButton';
import { DialogContentBase } from '../helpers';

function Confirmation({ onConfirm }) {
  return (
    <>
      <DialogContentBase>
        <Typography align="center" style={{ padding: '2rem 0' }}>
          Renewal opt-out has been requested for the selected policies.
        </Typography>
      </DialogContentBase>
      <DialogActions>
        <CbButton styleName="ctaButton" onClick={onConfirm}>
          Done
        </CbButton>
      </DialogActions>
    </>
  );
}

export default Confirmation;
