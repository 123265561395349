import React from 'react';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

// components
import { Box, styled } from '@mui/material';

import {
  ModalActions,
  ModalContent,
  ModalDetails,
} from '../../console/workflows/bind-quote/stepper-screens/shared/ModalBase';
import { bindQuote } from '../../api/bind-quote.api';
import { manageAPIError } from '../../utils';
import { QuoteAgencyStatus } from '../../types/common/misc';
import { sleep } from '../../utils/appUtils';
import { PaperSnackbar } from '../snackbars';
import {
  useAmplitude,
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES,
} from '../../providers/AmplitudeProvider';
import { usePersona } from '../hooks/usePersona';

const ConfirmBindQuote = ({ data = {}, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const amplitude = useAmplitude();

  const persona = usePersona();

  const [isLoading, setIsLoading] = React.useState(false);

  const quoteNumber = data.quoteDetails?.uiData?.quoteNumber ?? '';
  const companyName = data.quoteDetails?.uiData?.companyName ?? '';

  const pollP250QuoteProgress = async () => {
    // eslint-disable-next-line
    for (let timesPolled = 0; timesPolled <= 5; timesPolled++) {
      await queryClient.refetchQueries(['quote-progress', data.quoteId]);
      const { quoteProgress: quoteProgressData } =
        await queryClient.getQueryData({
          queryKey: ['quote-progress', data.quoteId],
          exact: false,
        });

      if (
        quoteProgressData.quoteAgencyStatus !== QuoteAgencyStatus.READY &&
        quoteProgressData.quoteAgencyStatus !== QuoteAgencyStatus.BOUND
      ) {
        return { status: pollAPIStatuses.ERROR }; // Very bad if this were to happen 😬. Will show error to user
      }

      if (quoteProgressData.quoteAgencyStatus === QuoteAgencyStatus.BOUND) {
        return { status: pollAPIStatuses.FINISHED }; // Finshed status will allow user to move forward
      }
      await sleep(500);
    }
    return { status: pollAPIStatuses.ERROR };
  };

  const handleBindQuote = () => {
    setIsLoading(true);
    bindQuote(persona.type, data.productType, data.quoteId)
      .then(pollP250QuoteProgress)
      .then(({ status }) => {
        if (status === pollAPIStatuses.FINISHED) {
          amplitude.track(AMPLITUDE_EVENTS.bindQuote, {
            [AMPLITUDE_PROPERTIES.quoteId]: data.quoteId,
          });

          enqueueSnackbar('Quote bind was successful', {
            variant: 'success',
          });
          close();
          data.completeStep(data.step);
          setTimeout(() => {
            data.goForward();
          }, 500);
        }
        if (status === pollAPIStatuses.ERROR) {
          enqueueSnackbar(
            'Something went wrong. A policy will not be able to be issued at this time',
            { variant: 'error' }
          );
          close();
        }
      })
      .catch(async (error) => {
        await queryClient.invalidateQueries(['quote-progress', data.quoteId]);
        if (
          error?.response?.status === 412 &&
          Array.isArray(error?.response?.data?.errors)
        ) {
          enqueueSnackbar('Unable to bind quote', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            persist: true,
            content: (key, message) => {
              return (
                <PaperSnackbar key={key} message={message}>
                  <strong>
                    The quote could not be bound for the following reason(s):
                  </strong>
                  <ul>
                    {error.response.data.errors.map((error, index) => (
                      <li key={index}>{error.detail}</li>
                    ))}
                  </ul>
                </PaperSnackbar>
              );
            },
          });
        } else {
          enqueueSnackbar(
            manageAPIError(error, 'Something went wrong while binding quote'),
            { variant: 'error' }
          );
        }
        close();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ModalContent>
        <Box>
          Are you sure you want to bind <Span>{quoteNumber}</Span> for{' '}
          <Span>{companyName}</Span>?
        </Box>
        <ModalDetails quoteData={data.quoteDetails.uiData} />
        <p>
          <i>NOTE: Your binder will be emailed to you shortly.</i>
          <br />
          <i>
            Once quote is bound, pre-bind subjectivities can no longer be
            edited.
          </i>
        </p>
      </ModalContent>
      <ModalActions
        onClick={handleBindQuote}
        loading={isLoading}
        disabled={isLoading}
      />
    </>
  );
};

export default ConfirmBindQuote;

export const ConfirmBindQuoteConfig = {
  ConfirmBindQuote: {
    component: ConfirmBindQuote,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      minWidth: 'sm',
    },
  },
};

const Span = styled('span')({
  fontWeight: 600,
});

const pollAPIStatuses = {
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
};
