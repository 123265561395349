import * as Yup from 'yup';
// react
import React from 'react';

// mui
import { Grid as MuiGrid } from '@mui/material';
// components
import Moment from 'moment';
import { withFormController } from '../../../../../../components/hocs/forms';
import { TextFieldBase } from '../../../../../../components/inputs/TextFieldBase';

// actions

const TextField = withFormController(TextFieldBase);

export const priorPendingExclusionValidation = {
  date: Yup.string().when('endorsement', {
    is: 'Prior or Pending Exclusion Endorsement',
    then: Yup.string().required().label('Date'),
  }),
};

export const PriorPendingExclusion = () => {
  return (
    <>
      <MuiGrid container spacing={5}>
        <MuiGrid item md={12} style={{ paddingTop: 0 }}>
          <TextField
            name="date"
            label="Date"
            InputProps={{
              inputProps: { min: Moment().format('YYYY-MM-DD') },
            }}
            required
            type="date"
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
