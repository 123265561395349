import React from 'react';
import { useSnackbar } from 'notistack';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import {
  Divider,
  Box,
  DialogActions,
  DialogContent,
  TextField,
  InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { AddIcon } from '../../../_assets/svg/Add.svg';
import CbButton from '../../Buttons/CbButton';

import { TextFieldBase } from '../../inputs/TextFieldBase';
import { getFormTypes, createFormType } from '../../../api/DocumentsService';
import {
  FormTypeCRUDTable,
  FormTypeTableSkeleton,
} from '../../../console/support/formsLibrary/components/FormTypeCRUDTable';

export const FormTypeCRUD = ({ ...props }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data: formTypes, isLoading } = useQuery(['formTypes'], () => {
    return getFormTypes()
      .then(({ data }) => data.content)
      .catch(() =>
        enqueueSnackbar(
          'Something went wrong, please try again in a few minutes',
          { variant: 'error' }
        )
      );
  });

  const [filteredFormTypes, setFilteredFormTypes] = React.useState(
    formTypes || []
  );

  React.useEffect(() => {
    if (formTypes) {
      setFilteredFormTypes(formTypes);
    }
  }, [formTypes]);

  const handleSearch = _.debounce((searchTerm) => {
    if (!searchTerm) {
      return setFilteredFormTypes(formTypes);
    }
    setFilteredFormTypes(
      formTypes.filter((formType) =>
        formType.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, 500);

  return (
    <>
      <DialogContent className={classes.bodyPadding}>
        <SearchBox handleSearch={handleSearch} />
        {isLoading ? (
          <FormTypeTableSkeleton />
        ) : (
          <FormTypeCRUDTable formTypes={filteredFormTypes} />
        )}
        <Divider style={{ margin: '1.5rem 0' }} />
        <AddFormType />
      </DialogContent>
      <DialogActions>
        <CbButton type="button" onClick={props.close} styleName="ctaButton">
          Done
        </CbButton>
      </DialogActions>
    </>
  );
};

const SearchBox = ({ handleSearch }) => {
  const classes = useSearchStyles();
  return (
    <TextField
      variant="standard"
      onChange={(e) => handleSearch(e.target.value)}
      className={classes.searchBoxWrapper}
      InputProps={{
        placeholder: 'Search by name',
        classes: {
          root: classes.searchBoxNaked,
          focused: classes.searchBoxFocused,
        },
        endAdornment: (
          <InputAdornment classes={{ root: classes.searchIcon }} position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export const AddFormType = ({ onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [formType, setFormType] = React.useState('');

  const handleAddFormType = () => {
    createFormType({}, { name: formType })
      .then(() => {
        setFormType('');
        queryClient.invalidateQueries(['formTypes']);
        enqueueSnackbar('Form Type Added Successfully!', {
          variant: 'success',
        });
        if (typeof onSuccess === 'function') return onSuccess();
      })
      .catch((error) =>
        enqueueSnackbar(
          _.get(
            error,
            'response.data',
            'Something went wrong, please try again in a few minutes'
          ),
          {
            variant: 'error',
          }
        )
      );
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <TextFieldBase
        name="name"
        label="Form Name"
        style={{ minWidth: '18rem' }}
        placeholder="Add a new form type"
        value={formType}
        onChange={(e) => setFormType(e.target.value)}
        required
      />
      <CbButton
        type="button"
        onClick={handleAddFormType}
        styleName="ctaButton"
        style={{ marginTop: '1rem' }}
        disabled={!formType}
      >
        Add
      </CbButton>
    </Box>
  );
};

const useStyles = makeStyles({
  bodyPadding: {
    paddingTop: '2.5rem',
    paddingBottom: '1.5rem',
  },
});

const useSearchStyles = makeStyles(({ palette }) => ({
  searchBoxNaked: {
    position: 'relative',
    color: palette.text.primary,

    '&:before': {
      display: 'none',
      position: 'initial',
    },

    '&:after': {
      display: 'none',
      position: 'initial',
    },

    '& > input': {
      background: palette.background.default,
      color: palette.text.primary,
    },

    '.action-bar &': {
      border: 'unset',
    },

    '.action-bar & input': {
      height: 'unset',
      padding: '0.4166666667rem 0.8333333333rem',
    },
  },

  searchBoxFocused: {
    '& svg': {
      color: palette.primary.contrastText,
    },
  },

  searchBoxWrapper: {
    width: '100%',
    backgroundColor: palette.background.lighter,
    color: palette.text.primary,

    '.action-bar &': {
      padding: 0,
      backgroundColor: 'unset',
    },
  },

  searchIcon: {
    height: '90%',
    maxHeight: 'initial',
    width: 40,
    padding: '0 5px',
    marginRight: 1,
    marginLeft: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',

    '& svg': {
      height: 22,
      width: 22,
    },

    '.action-bar & svg': {
      height: 18,
      width: 18,
    },
  },
}));

export const FormTypeCRUDConfig = {
  FormTypeCRUD: {
    component: FormTypeCRUD,
    config: {
      fullWidth: true,
      title: 'Add/Edit Form Type',
      icon: <AddIcon />,
    },
  },
};
