import { useTheme } from '@mui/material';
import React from 'react';

export const useToggle = (init = true) => {
  const [bool, setBool] = React.useState(init);

  const toggleBool = (val) => {
    if (val === true || val === false) {
      return setBool(val);
    }
    setBool((prevBool) => !prevBool);
  };

  return [bool, toggleBool];
};

export const useStatusColors = (status) => {
  const { palette } = useTheme();

  switch (status) {
    case 'DRAFT':
    case 'REFERRED':
    case 'IN REVIEW':
    case 'INDICATION':
      return '#fbc000';
    case 'BOUND':
    case 'ISSUED':
    case 'REQUESTED':
    case 'SIGNED':
      return '#2180e2';
    case 'IN FORCE':
    case 'READY':
      return '#6ac52a';
    case 'EXPIRED':
    case 'RESCIND':
    case 'CANCEL PENDING':
    case 'CANCEL':
    case 'VOID':
    case 'FLAT CANCEL':
    case 'INVALID':
    case 'LOST':
    case 'BINDER LOST':
    case 'SUBMISSION LOST':
      return '#fa8072';
    case 'RENEWED':
      return palette.primary.main;
    default:
      return palette.primary.main;
  }
};
