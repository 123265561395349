import React from 'react';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';

// components
import { Box, FormHelperText as FormHelperTextBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Save as SaveIcon } from '@mui/icons-material';
import { Button as ButtonBase } from '../../Buttons/Button';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { withShowable } from '../../../console/_global/lib/withShowable';

// helpers
import { stringifyQuery } from './filter.helpers';

// utils
import { PubSub } from '../../../utils/eventUtils';

// services
import { createFilter } from '../../../_services';

const Button = withShowable(ButtonBase);
const FormHelperText = withShowable(FormHelperTextBase);

export const SaveFilterForm = ({ tableId, nextQuery }) => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [shouldSave, setShouldSave] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [filterError, setFilterError] = React.useState(false);
  const filterName = React.useRef(null);

  const handleSaveToggle = React.useCallback(() => {
    setShouldSave((currentValue) => !currentValue);
    // eslint-disable-next-line
  }, []);

  // set filterError to false when user makes changes to form
  React.useEffect(() => {
    if (filterError) setFilterError(false);
    // eslint-disable-next-line
  }, [nextQuery]);

  const handleSaveFilter = React.useCallback(() => {
    const specialChars = [
      '!',
      '@',
      '#',
      '$',
      '%',
      '^',
      '&',
      '*',
      '+',
      '=',
      '?',
    ];
    const invalidName = filterName.current
      .split('')
      .some((char) => specialChars.includes(char));
    if (!filterName.current || invalidName) return setError(true);

    // check for unwanted null values before saving filter
    if (!isValidFilter(nextQuery)) return setFilterError(true);

    const query = stringifyQuery(nextQuery);

    createFilter(
      {},
      {
        filterName: filterName.current,
        table: tableId,
        filterString: query.replace(/f\[/g, 'filter['),
      }
    )
      .then(() => {
        setShouldSave(false);
        PubSub.publish('filter:created');
        enqueueSnackbar('Filter saved', { variant: 'success' });
        queryClient.invalidateQueries([`${tableId}-tableFilters`]);
      })
      .catch(() => {
        enqueueSnackbar('Failed to save filter', { variant: 'error' });
      })
      .finally(() => {
        setError(false);
        filterName.current = '';
      });
    // eslint-disable-next-line
  }, [nextQuery]);

  const handleFilterNameChange = React.useCallback((event) => {
    if (event.target.value) {
      setError(false);
    } else {
      setError(true);
    }

    filterName.current = event.target.value;
    // eslint-disable-next-line
  }, []);

  const handleCancel = React.useCallback(() => {
    setError(false);
    setFilterError(false);
    setShouldSave(false);
  }, []);

  return (
    <>
      <Button
        show={!shouldSave}
        onClick={handleSaveToggle}
        endIcon={<SaveIcon />}
      >
        Save Filters
      </Button>
      {shouldSave && (
        <Box display="flex" alignItems="center">
          <TextFieldBase
            name="filterName"
            placeholder="Name your filter"
            className={classes.saveInput}
            showErrorPlace={false}
            onChange={handleFilterNameChange}
            error={error || filterError}
          />
          <FormHelperText className={classes.errorMsg} show={error}>
            {filterName.current
              ? 'Special characters are not allowed'
              : 'Filter Name is a required field'}
          </FormHelperText>
          <FormHelperText
            className={classes.errorMsg}
            show={filterError && !error}
          >
            Please fill in the missing form values
          </FormHelperText>
          <Button
            onClick={handleCancel}
            style={{ padding: 10, marginLeft: 10 }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveFilter}
            stylename="primary"
            style={{ padding: 10, marginLeft: 10 }}
          >
            Save
          </Button>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  saveInput: {
    minWidth: 200,

    '& input': {
      padding: 6,
    },
  },
  errorMsg: {
    marginTop: 0,
    position: 'absolute',
    top: 53,
  },
}));

const isValidFilter = (query) => {
  if (_.isEmpty(query)) return false;
  const flattened = query.reduce((acc, f) => {
    return [...acc, ...Object.values(f)];
  }, []);
  return flattened.every(Boolean);
};
