import * as Ramda from 'ramda';

import { TextFieldBase } from './TextFieldBase';
import { CleaveBase } from './CleaveBase';

// for use with react-hook-form controller
// replaces the event's target's value with rawValue which is a special key from cleave.js containing the input's value stripped of prefixes (e.g: $)
export function transformCleaveCurrencyEvent(event) {
  const isRawValueNegativePrefixOnly = event.target.rawValue === '-';
  const rawValue = isRawValueNegativePrefixOnly ? -0 : event.target.rawValue;

  return Ramda.assocPath(['target', 'value'], Number(rawValue), event);
}

export const CleaveInput = ({
  prefix = '$',
  inputRef,
  numericOnly = false,
  options,
  ...props
}) => {
  return (
    <CleaveBase
      ref={inputRef}
      options={{
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        prefix,
        rawValueTrimPrefix: true,
        numericOnly,
        numeralDecimalScale: 2,
        ...options,
      }}
      {...props}
    />
  );
};

export const CurrencyField = ({
  InputProps = {},
  inputProps = {},
  options = {},
  ...props
}) => {
  return (
    <TextFieldBase
      InputProps={{
        ...InputProps,
        inputProps: { ...inputProps, options },
        inputComponent: CleaveInput,
      }}
      {...props}
    />
  );
};
