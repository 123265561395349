import React from 'react';
import type { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import type { TranslationKeys } from '../constants';
import { TRANSLATION_KEYS } from '../constants';

type UseCowbellTranslationsParams = TranslationKeys[];

export const useCowbellTranslations = (
  translations?: UseCowbellTranslationsParams
) => {
  const i18n = useTranslation();

  const _translations = React.useMemo(() => {
    if (!translations) {
      return;
    }

    return translations.reduce((acc, key) => {
      return {
        ...acc,
        [key]: i18n.t(key),
      };
    }, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translations]);

  return {
    t: i18n.t,
    i18n: i18n.i18n,
    translationKeys: TRANSLATION_KEYS,
    translations: (_translations ?? {}) as Record<TranslationKeys, string>,
  };
};

export const withCowbellTranslations = <P extends object>(
  Component: ComponentType<P>
) => {
  return (props: P) => {
    const cowbellTranslations = useCowbellTranslations();

    return <Component {...props} {...cowbellTranslations} />;
  };
};

export const getCowbellTranslations = () => {
  return {
    t: i18n.t,
    i18n,
    translationKeys: TRANSLATION_KEYS,
  };
};
