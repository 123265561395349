import React from 'react';
import clsx from 'classnames';

//mui
import type { MenuProps as MuiMenuProps, IconButtonProps } from '@mui/material';
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  IconButton,
  Collapse,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

//assets
import TableMenuIconBase from '../../../../_assets/svg/TableMenuIcon.svg';
import TableMenuCloseIcon from '../../../../_assets/svg/TableMenuCloseIcon.svg';

export const useMenuState = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return {
    anchorEl,
    handleOpen,
    handleClose,
    open,
  };
};

interface MenuStateProps {
  children: (args: ReturnType<typeof useMenuState>) => JSX.Element;
}

const MenuState = ({ children }: MenuStateProps) => {
  const menuState = useMenuState();

  return <>{children(menuState)}</>;
};

interface IconProps extends IconButtonProps {
  handleOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  anchorEl: HTMLButtonElement | null;
}

const Icon = ({ handleOpen, anchorEl, ...props }: IconProps) => {
  return (
    <IconButton
      size="small"
      aria-haspopup="true"
      onClick={handleOpen}
      {...props}
    >
      {anchorEl ? <TableMenuCloseIcon /> : <TableMenuIconBase />}
    </IconButton>
  );
};

interface MenuProps extends MuiMenuProps {
  anchorEl: null | HTMLButtonElement;
  handleClose: () => void;
  children: React.ReactNode;
}

const Menu = ({
  anchorEl,
  open,
  handleClose,
  children,
  ...props
}: MenuProps) => {
  const classes = useStyles();
  return (
    <MuiMenu
      anchorEl={anchorEl}
      open={open}
      classes={{ paper: classes.menu }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
      {...props}
    >
      {children}
    </MuiMenu>
  );
};

const Item = ({
  children,
  ...props
}: React.ComponentProps<typeof MuiMenuItem>) => {
  const classes = useStyles();

  return (
    <MuiMenuItem style={{ padding: 0 }} {...props}>
      <li className={classes.menuItem}>{children}</li>
    </MuiMenuItem>
  );
};

export const useDropDownState = () => {
  const [isCollapsed, setisCollapsed] = React.useState(false);

  const handleToggle = () => {
    setisCollapsed((prevState) => !prevState);
  };

  return {
    isCollapsed,
    handleToggle,
  };
};

interface DropDownMenuItemStateProps {
  children: (args: ReturnType<typeof useDropDownState>) => JSX.Element;
}

const DropDownState = ({ children }: DropDownMenuItemStateProps) => {
  const dropDownState = useDropDownState();

  return <>{children(dropDownState)}</>;
};

interface DropDownMenuProps {
  isCollapsed: boolean;
  handleToggle: () => void;
  title: string;
  children: React.ReactNode;
}

const DropDown = ({
  isCollapsed,
  handleToggle,
  title,
  children,
}: DropDownMenuProps) => {
  const classes = useStyles();

  return (
    <>
      <Box onClick={handleToggle} className={classes.dropDown}>
        <li className={classes.menuItem}>{title}</li>

        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: !isCollapsed,
          })}
          size="large"
        >
          <ExpandMoreIcon
            style={{ pointerEvents: 'none', fontSize: '1.25rem' }}
            color="primary"
          />
        </IconButton>
      </Box>
      <Collapse in={!isCollapsed}>{children}</Collapse>
    </>
  );
};

interface DropDownItemProps extends React.ComponentProps<typeof MuiMenuItem> {
  Icon?: JSX.Element | null;
}

const DropDownItem = ({
  Icon = <FiberManualRecordRoundedIcon style={{ fontSize: '0.60rem' }} />,
  children,
  ...props
}: DropDownItemProps) => {
  const classes = useStyles();

  return (
    <MuiMenuItem style={{ padding: 0 }} {...props}>
      <li style={{ paddingLeft: '1.5rem' }} className={classes.menuItem}>
        <>
          {Icon} {children}
        </>
      </li>
    </MuiMenuItem>
  );
};

export default {
  MenuState,
  Icon,
  Menu,
  Item,
  DropDownState,
  DropDown,
  DropDownItem,
} as const;

const useStyles = makeStyles(({ palette, config, transitions }) => {
  return {
    menu: {
      minWidth: 'auto',
      background: palette.common.darkBlue,
      border: `1px solid ${palette.common.almostWhite}`,
      color: palette.common.almostWhite,
    },

    dropDown: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',

      '&:hover, &:focus': {
        color: config.colors.white,
        fontWeight: 600,
        background: '#113E68',
      },
    },

    menuItem: {
      padding: '0.4166666667rem 0.8333333333rem',
      color: config.colors.white,
      transition: 'all 0.25s',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '0.25rem',

      '&:hover, &:focus': {
        color: config.colors.white,
        background: '#113E68',
      },
    },

    expand: {
      height: '1.5rem',
      width: '1.5rem',
      color: palette.primary.main,
      transform: 'rotate(0deg)',
      transition: transitions.create('transform', {
        duration: transitions.duration.shortest,
      }),
    },

    expandOpen: {
      transform: 'rotate(180deg)',
    },
  };
});
