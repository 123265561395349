import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { Grid as MuiGrid, DialogContent } from '@mui/material';

// utils
import { PubSub } from '../utils/eventUtils';
import { createWebHook } from './WebHookService';
import { TextField } from '../components/inputs';
import { useToggleModal } from '../utils/modal.utils';

const WebHookCreate = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  let companyName = '';

  useEffect(() => {
    PubSub.publish('disabled', true);
    const sub = PubSub.subscribe('foot-button-one', (eventData) => {
      if (eventData) {
        handleSubmit();
      }
    });
    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  function handleSubmit() {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);

    return createWebHook({}, { companyName })
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        enqueueSnackbar(`WebHook Created successfully!`, {
          variant: 'success',
        });
        PubSub.publish('table-refetch', 'webhooks');
        toggleModal.direct('WebHookCreate', false);
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
      });
  }

  const handleChange = (event) => {
    const { value } = event.target;
    if (!value) {
      PubSub.publish('disabled', true);
    } else {
      PubSub.publish('disabled', false);
    }
    companyName = value;
  };

  return (
    <DialogContent>
      <MuiGrid spacing={8} style={{ marginTop: -20 }}>
        <TextField
          name="companyName"
          label="Company Name"
          onChange={handleChange}
        />
      </MuiGrid>
    </DialogContent>
  );
};

export default WebHookCreate;
