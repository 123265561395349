import React from 'react';
// hooks
import { SurplusContext } from '../console/agencies/quotes/250/prime250.context';

// reducers
import { quoteReducer, initialState } from './quote.reducer';

/**
 * @name withSurplus
 * @description withSurplus is a decorator around the top most parent component of a page requiring access to surplus.
 * It is meant to facilitate providing dispatch to all children components via context.
 */

export const withSurplus = () => {
  return (Component) => {
    return (props) => {
      const [state, dispatch] = React.useReducer(quoteReducer, initialState);

      return (
        <SurplusContext.Provider
          value={{
            dispatch,
            ...state,
          }}
        >
          <Component {...props} />
        </SurplusContext.Provider>
      );
    };
  };
};
