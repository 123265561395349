import React, { cloneElement, useEffect, useState } from 'react';

import { IconButton as MuiIconButton } from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { PubSub } from '../../../../utils/eventUtils';

export const ExpandIcon = ({
  icon,
  iconProps = { className: 'expand-icon' },
  id,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const expandedSub = PubSub.subscribe('table-row:expanded', (rowId) => {
      if (id === rowId) {
        setExpanded(true);
      }
    });

    const collapsedSub = PubSub.subscribe('table-row:collapsed', (rowId) => {
      if (id === rowId) {
        setExpanded(false);
      }
    });

    return () => {
      expandedSub.remove();
      collapsedSub.remove();
    };
    // eslint-disable-next-line
  }, []);

  if (icon) {
    return (
      <MuiIconButton className="expand-icon" size="small" {...props}>
        {cloneElement(icon, { className: 'expand-icon', expanded })}
      </MuiIconButton>
    );
  }

  return (
    <MuiIconButton className="expand-icon" size="small" {...props}>
      {expanded ? (
        <ExpandLessIcon {...iconProps} />
      ) : (
        <ExpandMoreIcon {...iconProps} />
      )}
    </MuiIconButton>
  );
};
