import { trimAndAddAsterisk } from '../../utility/QueryUtility';
import { commonDateParamMappings } from '../common/mappings';

export const claimServiceMappings = [
  ...commonDateParamMappings,
  { path: 'page' },
  { path: 'size' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        switch (orderBy) {
          case 'closedDate':
          case 'created':
          case 'modified':
          case 'reportedDate':
          case 'incidentDate':
          case 'isThereAnotherClaim':
            return `${orderBy},${order}`;
          default:
            return `${orderBy}.keyword,${order}`;
        }
      }
      return null;
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) =>
      !search ? search : trimAndAddAsterisk(search),
  },
  { path: 'f', paramKey: 'filter' },
  { path: 'searchAfter' },
  { path: 'pageRequested' },
  { path: 'accountId' },
  { path: 'teamIds' },
];
