import { SvgIcon } from '@mui/material';

const SVG = () => {
  return (
    <SvgIcon style={{ width: '60px', height: '30px' }} viewBox="10 -10 120 60">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="60"
        viewBox="0 0 97 60"
      >
        <g transform="translate(-1091 -236)">
          <g transform="translate(1112 248.001)">
            <g transform="translate(0 -0.001)">
              <path
                d="M107.775,48.912l-1.605-.642C98.624,65.247,68.608,54.926,66.8,59.977c-.3,3.412,16.392.649,28.326,1.227,3.639.176,5.464,2.923,3.919,7.4l3.044.009c3.511-10.945,14.716-5.36,15.184-8.972C116.505,57.267,104.4,59.642,107.775,48.912Z"
                transform="translate(-46.606 -33.981)"
                fill="#fff"
              />
              <path
                d="M53.3,33.752a4.585,4.585,0,0,0-.482-4.174,4.968,4.968,0,0,0-3.372-1.766L21.514,27.49c-.161,0-.321-.161-.482-.161a.3.3,0,0,1,0-.482c.161-.321.321-.482.642-.482l28.1-.321a10.1,10.1,0,0,0,8.188-6.1l1.606-4.174c0-.161.161-.321,0-.482A18.3,18.3,0,0,0,24.4,13.362a8.763,8.763,0,0,0-5.78-1.605,8.364,8.364,0,0,0-7.385,7.385,8.763,8.763,0,0,0,.161,2.89A11.723,11.723,0,0,0,0,33.752a6.586,6.586,0,0,0,.161,1.766A.513.513,0,0,0,.642,36H52.179c.321,0,.642-.161.642-.482Z"
                transform="translate(0 -0.999)"
                fill="#f4811f"
              />
              <path
                d="M188.352,49.863h-.8c-.161,0-.321.161-.482.321l-1.124,3.853a4.584,4.584,0,0,0,.482,4.174,4.968,4.968,0,0,0,3.371,1.766l5.94.321c.161,0,.321.161.482.161a.3.3,0,0,1,0,.482c-.161.321-.321.482-.642.482l-6.1.321a10.1,10.1,0,0,0-8.188,6.1l-.321,1.445c-.161.161,0,.482.321.482H202.48a.426.426,0,0,0,.482-.482,17.458,17.458,0,0,0,.642-4.175,15.349,15.349,0,0,0-15.252-15.252"
                transform="translate(-126.219 -35.092)"
                fill="#faad3f"
              />
            </g>
          </g>
          <rect
            width="120"
            height="60"
            transform="translate(1091 236)"
            fill="none"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default SVG;
