import { ServiceHandlerFactory } from '../../utils/serviceUtils';
import { Api } from '../../config/axiosConfig';
import { accountServiceMappings } from '../../accounts/accountServiceMappings';

export const fetchMssps = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/mssp/search',
  },
  isCancelable: true,
  paramSchema: accountServiceMappings,
});

export const createMssp = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/mssp',
    method: 'post',
  },
});

export const updateMssp = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/mssp/:id',
    method: 'put',
  },
});

export const deleteMssp = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/mssp/:id',
    method: 'delete',
  },
});
export function enterMssp(id) {
  return Api.post(`/api/auth/v1/da/${id}/enter`);
}
export const getMsspDetails = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/mssp`,
  },
  isCancelable: true,
});
export const setMsspDetails = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/mssp',
    method: 'put',
  },
  isCancelable: true,
});
