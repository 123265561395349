import { ServiceHandlerFactory } from '../utils/serviceUtils';
import { fetchBlackListedDomainsParamsMapping } from '../mappings/services/domainServiceMappings';

export const fetchBlackListedDomains = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/blacklisteddomains',
  },
  paramSchema: fetchBlackListedDomainsParamsMapping,
});

export const createBlackListedDomains = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/blacklisteddomains',
    method: 'post',
  },
});

export const deleteBlackListedDomains = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/blacklisteddomains/:domainName',
    method: 'delete',
  },
});
