import _ from 'lodash';
import { handleActions } from 'redux-actions';

import AT from '../../actions/ActionTypes';

import type { UserDto } from '../../types/users/dto/user.dto';
import type { UserFlowDto } from '../../types/users/dto/user-flow.dto';
import type { MfaType } from '../../types/users/mfa.types';

import {
  SET_INITIAL_USER,
  FLOW_USER_AUTHENTICATED,
} from '../actions/user.store.actions';

import type { ReducerAction } from '../types/reducer.types';

interface User {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  fullName: string;
  email: string;
  roleName: string;
  roleId: string;
  mfaType: MfaType;
}

const INITIAL_STATE: Partial<User> = {};

// store
export const userstore = handleActions(
  {
    [SET_INITIAL_USER]: (state, action: ReducerAction<UserDto>) => {
      const { role, ...nextUser } = _.pick(action.payload, [
        'id',
        'firstName',
        'middleName',
        'lastName',
        'initials',
        'email',
        'phone',
        'role',
        'mfaType',
        'avatarUrl',
      ]);

      const fullName = [
        nextUser.firstName,
        nextUser.middleName,
        nextUser.lastName,
      ]
        .filter((n) => !!n)
        .join(' ');

      return {
        ...state,
        ...nextUser,
        fullName,
        roleName: role?.name,
        roleId: role?.id,
      };
    },

    [FLOW_USER_AUTHENTICATED]: (state, action: ReducerAction<UserFlowDto>) => {
      if (action.payload == null) {
        return INITIAL_STATE;
      }

      const {
        id,
        flowUserFirstName,
        flowUserLastName,
        flowUserEmail,
        flowUserPhone,
      } = action.payload;

      const fullName = [flowUserFirstName, flowUserLastName]
        .filter((n) => !!n)
        .join(' ');

      return {
        id,
        firstName: flowUserFirstName,
        lastName: flowUserLastName,
        fullName,
        email: flowUserEmail,
        phone: flowUserPhone,
      };
    },

    [AT.USER_LOGOUT]: () => {
      return INITIAL_STATE;
    },
  },
  INITIAL_STATE
);
