import Moment from 'moment';
import { useQuery } from '@tanstack/react-query';

import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Skeleton,
} from '@mui/material';
import { useTheme } from '@mui/styles';

// utils
import { useSelector } from 'react-redux';
import Numeral from 'numeral';
import { searchAccountsV2, searchQuotesV2 } from '../../../../api';
import {
  DRAFT_STATUS,
  READY_STATUS,
  REFERRED_STATUS,
} from '../../../_statics/quote.statics';
import { searchPoliciesV2 } from '../../../../policies/PolicyService';
import {
  ACTIVE_STATUS,
  BOUND_STATUS,
  ISSUED_STATUS,
} from '../../../_statics/policy.statics';
import type { GlobalStoredTeam } from '../../../../reducers/team.reducer';
import { selectGlobalTeam } from '../../../../reducers/team.reducer';
import { Show } from '../../../../components/Show';

export const DashboardStatsBarModule = () => {
  const theme = useTheme();
  const team = useSelector(selectGlobalTeam);
  const { data: statsBarData, isLoading: isStatsBarDataLoading } =
    useStatsBarQuery(team);

  const data = [
    {
      label: 'Expiring Quotes',
      value: statsBarData?.expiringQuotes,
      color: theme.agencyDash.statsBar.expiringQuotes,
    },
    {
      label: 'Expiring Binders',
      value: statsBarData?.expiringBinders,
      color: theme.agencyDash.statsBar.expiringBinders,
    },
    {
      label: 'Policies up for Renewal',
      value: statsBarData?.policiesOpenForRenewal,
      color: theme.agencyDash.statsBar.policiesUpForRenewal,
    },
  ];

  const totalClients = statsBarData?.totalClients;
  const totalNumberOfEntities = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <Grid item md={12}>
      <Card
        elevation={2}
        style={{ backgroundColor: theme.agencyDash.background.button }}
      >
        <CardContent>
          <Show when={isStatsBarDataLoading}>
            <Skeleton variant="rectangular" width="100%" height={120} />
          </Show>
          <Show when={!isStatsBarDataLoading}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={1}
            >
              <Grid item>
                <Typography>
                  {Numeral(totalClients).format('0,00')} Clients
                </Typography>
                <Typography className="normal-text">
                  {team.id === 'all'
                    ? 'Across all my teams'
                    : `In team ${team.name}`}
                </Typography>
              </Grid>
            </Box>

            <Box
              display="flex"
              height="12px"
              width="100%"
              borderRadius="10px"
              overflow="hidden"
            >
              {data.map((item, index) => {
                return (
                  <Box
                    key={index}
                    width={`${(item.value / totalNumberOfEntities) * 100}%`}
                    borderRadius="10px"
                    marginRight="2px"
                    marginLeft="2px"
                    style={{ backgroundColor: item.color }}
                  />
                );
              })}
            </Box>

            <Box display="flex" justifyContent="flex-start" mt={2}>
              {data.map((item, index) => {
                return (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="column"
                    marginRight={2}
                  >
                    <Typography align="left" variant="caption">
                      {item.label}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Box
                        width={16}
                        height={16}
                        borderRadius="50%"
                        marginRight={1}
                        style={{ backgroundColor: item.color }}
                      />
                      <Typography variant="caption">{item.value}</Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Show>
        </CardContent>
      </Card>
    </Grid>
  );
};

const useStatsBarQuery = (team: GlobalStoredTeam) => {
  const teamIds = team.id === 'all' ? undefined : [team.id];
  const today = Moment().startOf('day').toISOString();
  const weekFromToday = Moment().add('7', 'days').startOf('day').toISOString();
  const fourteenDaysBeforeToday = Moment()
    .subtract('14', 'days')
    .startOf('day')
    .toISOString();

  return useQuery({
    queryKey: ['stats-bar', team.id],
    queryFn: () => {
      return Promise.all([
        searchAccountsV2({
          params: {
            teamIds,
          },
        }),
        searchQuotesV2({
          params: {
            f: {
              agencyStatus: {
                in: [READY_STATUS, REFERRED_STATUS],
              },
            },
            effectiveDateAfter: today,
            effectiveDateBefore: weekFromToday,
            teamIds,
          },
        }),
        searchPoliciesV2({
          params: {
            f: {
              agencyStatus: {
                in: [BOUND_STATUS],
              },
            },
            binderExpiresOnAfter: today,
            effectiveDateAfter: fourteenDaysBeforeToday,
            effectiveDateBefore: today,
            teamIds,
          },
        }),
        searchPoliciesV2({
          params: {
            f: {
              status: {
                in: ['IN_FORCE', ISSUED_STATUS, DRAFT_STATUS, ACTIVE_STATUS],
              },
              isOpenForRenewal: { bo: 1 },
            },
            teamIds,
          },
        }),
      ]);
    },
    select: ([accountsResp, quotesResp, bindersResp, policiesResp]) => {
      return {
        totalClients: accountsResp.data.totalElements,
        expiringQuotes: quotesResp.data.totalElements,
        expiringBinders: bindersResp.data.totalElements,
        policiesOpenForRenewal: policiesResp.data.totalElements,
      };
    },
  });
};
