import { useSelector } from 'react-redux';
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material';

import darktheme from '../themes/dark.theme';
import lighttheme from '../themes/light.theme';

import type { RootStore } from '../types';
import type { WithChildrenProps } from '../types/components/common';

const themes = {
  dark: darktheme,
  light: lighttheme,
};

const ThemeProvider = ({ children }: WithChildrenProps) => {
  const theme = useSelector((state: RootStore) => state.uiSettings.theme);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={themes[theme]}>{children}</MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
