import { applyQueryConfig } from '../../utils';
import { trimAndAddAsterisk } from '../../utility/QueryUtility';

const map = [
  { path: 'page' },
  { path: 'size' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: { orderBy: string; order: string }) => {
      if (orderBy && order) {
        switch (orderBy) {
          case 'modified':
          case 'created':
          case 'naics':
            return `${orderBy},${order}`;
          default:
            return `${orderBy}.keyword,${order}`;
        }
      }
      return null;
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      return !search ? search : trimAndAddAsterisk(search);
    },
  },
  { path: 'f', paramKey: 'filter' },
  { path: 'teamIds' },
];

export const latestRiskSerializer = (params: unknown[]) => {
  return applyQueryConfig(map, params);
};
