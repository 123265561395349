import React from 'react';
import { DialogContent } from '@mui/material';

import { withStyles } from '@mui/styles';
import CBCopyToClipboard from '../../components/copyToClipboard/CopyToClipboard';

const KeyGenerated = ({ classes, data }) => {
  return (
    <DialogContent className={classes.container}>
      <section className={classes.fieldContainer}>
        <label className={classes.label} htmlFor>
          Client ID
        </label>
        <div className={classes.textContainer}>
          {data.id}
          <CBCopyToClipboard text={data.id} />
        </div>
      </section>
      <section className={classes.fieldContainer1}>
        <label className={classes.label} htmlFor>
          Client Secret
        </label>
        <div className={classes.textContainer}>
          {data.secret}
          <CBCopyToClipboard text={data.secret} />
        </div>
      </section>
      <p className={classes.message}>
        Make sure you copy your new API access key. You will not be able to see
        it again.
      </p>
    </DialogContent>
  );
};

const styles = ({ palette }) => {
  return {
    message: {
      fontSize: '1.167rem',
      lineHeight: 1.29,
      textAlign: 'left',
      color: palette.text.primary,
      fontStyle: 'italic',
      paddingLeft: '1rem',
      margin: '0.3rem !important',
    },
    label: {
      fontSize: '1.167rem',
      lineHeight: 1.5,
      color: palette.text.secondary,
      margin: '0 0 0.2rem 1rem',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '2.75rem',
      borderRadius: '0.4167rem',
      border: `solid 0.8px ${palette.text.secondary}`,
      fontSize: '.9rem',
      lineHeight: 1.5,
      textAlign: 'left',
      color: palette.primary.main,
      padding: '0.4167rem 0.833rem',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: '2rem',
    },
    fieldContainer1: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    container: {
      width: '45rem',
      height: 'fit-content',
      margin: 'auto',
      textAlign: 'left',
    },
  };
};

export default withStyles(styles)(KeyGenerated);
