import {
  COUNTRIES,
  getIndustryCodePath,
} from '../../i18n/i18n.language-config';
import type { AccountDto } from '../../types';
import type { FirmographicDataDto } from '../../types/firmo/firmographic-data.dto';
import type { GetPrimeXPolicyDetails } from '../policies.api.types';
import type { GetPrimeXQuoteDetailsResponse } from '../quotes.api.types';

export const serializeAccountIndustryCode = ({
  data,
}: {
  data: AccountDto;
}) => {
  const industryCodePath = getIndustryCodePath(data.country);

  return {
    ...data,
    industryCode: data[industryCodePath],
  };
};

export const serializeLegacyIndustryCode = ({
  data,
}: {
  data: { firmographicData: FirmographicDataDto };
}) => {
  const industryCodePath = getIndustryCodePath(
    data?.firmographicData?.country || COUNTRIES.US
  );
  return {
    data: {
      ...data,
      firmographicData: {
        ...data.firmographicData,
        industryCode: data.firmographicData?.[industryCodePath],
      },
    },
  };
};

export const serializePxQuoteIndustryCode = ({
  data,
}: {
  data: GetPrimeXPolicyDetails & GetPrimeXQuoteDetailsResponse;
}) => {
  const industryCodePath = getIndustryCodePath(data.firmographicData.country);
  return {
    data: {
      ...data,
      firmographicData: {
        ...data.firmographicData,
        industryCode: data.firmographicData[industryCodePath],
      },
    },
  };
};
