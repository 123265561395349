import { handleActions } from 'redux-actions';

import _pick from 'lodash/pick';

import AT from '../../actions/ActionTypes';

import type { ReducerAction } from '../types/reducer.types';

import { PERSONA_TYPES } from '../../console/_statics/persona.statics';
import { SET_USER_ACCOUNT } from '../actions/user-account.store.actions';
import { FLOW_USER_AUTHENTICATED } from '../actions/user.store.actions';

interface UserAccount {
  id: string;
  name: string;
  accountType: string;
  enforceMfa: boolean;
}

const initialState = {};

export const userAccountReducer = handleActions(
  {
    [SET_USER_ACCOUNT]: (state, action: ReducerAction<UserAccount>) => {
      const nextUserAccount = _pick(action.payload, [
        'id',
        'name',
        'accountType',
        'enforceMfa',
      ]);

      let fullName = 'name';

      /* agency specific accounts */
      if (nextUserAccount.accountType === PERSONA_TYPES.AGENCY) {
        const nextUserAgencyAccount = _pick(action.payload, [
          'npn',
          'locationAlias',
        ]);

        fullName = [nextUserAccount.name, nextUserAgencyAccount.locationAlias]
          .filter((n) => !!n)
          .join(' - ');

        return {
          ...state,
          ...nextUserAccount,
          ...nextUserAgencyAccount,
          fullName,
        };
      }

      /* customer specific accounts */
      if (nextUserAccount.accountType === PERSONA_TYPES.CUSTOMER) {
        const nextUserCustomerAccount = _pick(action.payload, [
          'wizerCustomerId',
        ]);

        return {
          ...state,
          ...nextUserAccount,
          ...nextUserCustomerAccount,
        };
      }

      return { ...state, ...nextUserAccount };
    },

    [FLOW_USER_AUTHENTICATED]: (state, action: ReducerAction<any>) => {
      if (action.payload == null) {
        return initialState;
      }

      const { flowAccountName } = action.payload;

      return {
        name: flowAccountName,
      };
    },

    [AT.USER_LOGOUT]: () => {
      return initialState;
    },
  },
  initialState
);
