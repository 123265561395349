import { quoteServiceMappings } from '../../inbox/quoteServiceMappings';
import { ServiceHandlerFactory } from '../../utils/serviceUtils';
import { salesInboxServiceMappings } from './salesInboxSerivceMappings';
import { ServiceHandler } from '../../utils/api.utils';
import { commonDateParamMappings } from '../../api/common/mappings';

/**
 * Get leads
 *
 */
export const getLeads = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/lead/search',
  },
  isCancelable: true,
  paramSchema: salesInboxServiceMappings,
});

/**
 * Get leads
 */
export const getSalesInboxQuotes = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/search/sales-inbox',
  },
  isCancelable: true,
  paramSchema: quoteServiceMappings,
});

/**
 * Get lead opportunity statuses
 *
 */
export const getLeadOpportunityStatuses = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/lead/statuses',
  },
  isCancelable: true,
});

/**
 * Search sales inbox notes
 *
 */
export const searchAllSalesInboxNotes = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/note?search=:search',
  },
  isCancelable: true,
});

/**
 * Search sales inbox notes
 *
 */
export const getAllSalesInboxNotesByLeadId = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/note/lead/:leadId',
  },
  isCancelable: true,
  paramSchema: [
    ...commonDateParamMappings,
    { path: 'page' },
    { path: 'size' },
    {
      path: 'sort',
      transform: ({ orderBy, order }) => {
        return `${orderBy},${order}`;
      },
    },
    { path: 'search' },
  ],
});

export const createSalesInboxNote = ServiceHandlerFactory({
  axios: {
    url: `/api/sales/v1/note`,
    method: 'post',
  },
  isCancelable: false,
});

export const updateLead = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/lead/:id',
    method: 'put',
    isCancelable: true,
  },
});

export const updateSalesInboxNoteById = ServiceHandlerFactory({
  axios: {
    url: `/api/sales/v1/note/:noteId`,
    method: 'put',
  },
  isCancelable: false,
});

export const deleteSalesInboxNote = ServiceHandlerFactory({
  axios: {
    url: `api/sales/v1/note/:noteId`,
    method: 'delete',
  },
  isCancelable: false,
});

export const changeFlagByLeadId = ServiceHandlerFactory({
  axios: {
    url: `/api/sales/v1/lead/flag/:id`,
    method: 'put',
  },
  isCancelable: false,
});

export const getStatusAggregations = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/lead/agg/status',
  },
  isCancelable: false,
  paramSchema: salesInboxServiceMappings,
});

export const getPremiumAggByWinRate = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/lead/agg/premium?winRate=:rate',
  },
  isCancelable: false,
  paramSchema: salesInboxServiceMappings,
});

export const getTerritories = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/territory',
  },
  isCancelable: false,
  paramSchema: salesInboxServiceMappings,
});

export const getTerritoryDetails = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/territory-details',
  },
  isCancelable: false,
  paramSchema: salesInboxServiceMappings,
});

export const createLead = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/lead',
    method: 'post',
  },
});

export const createReminder = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/reminder',
    method: 'post',
  },
});

/**
 * @param {String} accountId the account whose reminder to fetch
 */
export const getReminder = ServiceHandlerFactory({
  axios: {
    url: `/api/sales/v1/reminder/account/:accountId`,
    paramSchema: salesInboxServiceMappings,
  },
});

/**
 * @param {String} reminderId distinguish which reminder to delete
 */
export const deleteReminder = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/reminder/:reminderId',
    method: 'delete',
  },
});

export const createTerritory = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/territory',
    method: 'post',
  },
});

export const updateTerritory = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/territory/:id',
    method: 'put',
  },
});

export const deleteTerritory = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/territory/:id',
    method: 'delete',
  },
});

export const updateTAMBDSTerritory = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/territory/tambds/:id',
    method: 'put',
  },
});

export const getTerritoryDetailById = ServiceHandlerFactory({
  axios: {
    url: '/api/sales/v1/territory/:id',
  },
  isCancelable: false,
  paramSchema: salesInboxServiceMappings,
});

export const postZipCode = ServiceHandler({
  url: 'api/sales/v1/zip',
  method: 'POST',
});
