import { Typography, useTheme } from '@mui/material';

interface INoOptionsTextProps {
  label: string;
}

export const NoOptionsText: React.FC<INoOptionsTextProps> = ({ label }) => {
  const theme = useTheme();
  return (
    <Typography style={{ color: theme.palette.error.light }}>
      {label}
    </Typography>
  );
};
