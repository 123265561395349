import React from 'react';
import classnames from 'classnames';
import Highlight from 'react-highlighter';
// components
import { makeStyles } from '@mui/styles';

import { InputLabelBase } from '../inputs/InputLabelBase';

import { getAutocompleteAccounts } from '../../accounts/AccountService';
import { ManagedTypeAheadBase as TypeAheadBase } from '../inputs/autocomplete';
import { RegisteredTypeAhead } from '../inputs/autocomplete/RegisteredTypeAhead';

const defaultLabelProps = {
  style: { padding: '0.5rem 0', fontSize: '1rem' },
};

export const AccountsAutoComplete = ({
  label,
  state,
  labelProps = defaultLabelProps,
  id,
  ...props
}) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(
    ({ input }, callback) => {
      return getAutocompleteAccounts({ state, search: input }).then(
        (response) =>
          callback(
            response.data.content.map((account) => {
              if (account) {
                return {
                  label: `${account.name} (${account.address1}, ${account.state})`,
                  value: account.name,
                  meta: account,
                };
              }
              return null;
            })
          )
      );
    },
    [state]
  );

  return (
    <>
      <InputLabelBase
        indent
        htmlFor={props.name}
        required={props.required}
        {...labelProps}
      >
        {label || 'Name'}
      </InputLabelBase>
      <TypeAheadBase
        id={id || 'name'}
        className={inputClasses}
        onFetch={handleSearch}
        filterOptions={filterOptions}
        {...props}
      />
    </>
  );
};

export const RegisteredAccountsAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  endpoint = getAutocompleteAccounts,
  ...props
}) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  React.useEffect(() => {
    if (typeof endpoint !== 'function') {
      throw new Error('Provided service is not a function');
    }
    // eslint-disable-next-line
  }, []);

  const handleSearch = React.useCallback(
    ({ input }, callback) => {
      return endpoint({ search: input }).then((response) =>
        callback(
          response.data.content.map((account = {}) => ({
            label: `${account.name}`,
            value: account.name,
            meta: account,
          }))
        )
      );
    },
    [endpoint]
  );

  return (
    <>
      <InputLabelBase
        indent
        htmlFor={props.name}
        required={props.required}
        {...labelProps}
      >
        {label || 'Name'}
      </InputLabelBase>
      <RegisteredTypeAhead
        freeSolo
        renderOption={(optionProps, option, state) => (
          <li {...optionProps}>
            <DefaultAccountOption option={option} state={state}>
              {(meta, styles) => (
                <>
                  <p
                    className={styles.option}
                    style={{ margin: '0', fontWeight: 'bold' }}
                  >
                    <Highlight search={state.inputValue}>
                      {option.meta.name}
                    </Highlight>
                  </p>
                  <p className={styles.optionText}>
                    {meta.address1} {meta.city} {meta.state}
                  </p>
                </>
              )}
            </DefaultAccountOption>
          </li>
        )}
        className={inputClasses}
        onFetch={handleSearch}
        filterOptions={filterOptions}
        {...props}
      />
    </>
  );
};

export function DefaultAccountOption({ children, option }) {
  const classes = useOptionStyles();

  if (typeof option !== 'object') {
    return null;
  }

  return (
    <div className={classes.parentOption}>{children(option.meta, classes)}</div>
  );
}

function filterOptions(options) {
  return options;
}

const useStyles = makeStyles(() => ({
  inline: {
    '& input': {
      padding: '6px !important',
    },
  },
}));

const useOptionStyles = makeStyles(({ palette }) => ({
  optionTitle: {
    color: palette.primary.contrastText,
    fontSize: '1.1666666667rem',
    fontWeight: 400,
    margin: 0,
  },
  parentOption: { width: '100%', position: 'relative', paddingBottom: '10' },
  optionText: {
    display: 'block',
    fontSize: '1rem',
  },
}));
