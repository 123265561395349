import React from 'react';
// mui
import {
  InputAdornment as MuiInputAdornment,
  IconButton,
  InputBase,
  styled,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import _config from '../../../../themes/_base/_config';
import { InputLabelBase } from '../../InputLabelBase';

export const PasswordField = React.forwardRef(
  ({ label, inputLabelProps = {}, id, ...props }, ref) => {
    if (label) {
      return (
        <>
          <InputLabelBase
            indent
            required={props?.required}
            {...inputLabelProps}
            style={{
              paddingTop: '.5rem',
              ...inputLabelProps.style,
              color: _config.colors.almostWhite,
            }}
          >
            {label}
          </InputLabelBase>
          <PasswordInput ref={ref} {...props} />
        </>
      );
    }

    return <PasswordInput ref={ref} {...props} />;
  }
);

const PasswordInput = React.forwardRef((props, ref) => {
  const name = props.name || 'password';
  const [show, setShow] = React.useState(false);

  function toggleShowPassword() {
    setShow(!show);
  }

  return (
    <InputBase
      type={show ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            size="small"
            onClick={toggleShowPassword}
            tabIndex="-1"
            style={{ marginRight: 8 }}
          >
            <PasswordIcon show={show} />
          </IconButton>
        </InputAdornment>
      }
      data-qa={name}
      ref={ref}
      {...props}
    />
  );
});

const InputAdornment = styled(MuiInputAdornment)({
  height: 'inherit',
  position: 'absolute',
  right: 0,
});

const PasswordIcon = ({ show }) => {
  return show ? (
    <VisibilityOff color="primary" />
  ) : (
    <Visibility color="primary" />
  );
};
