import {
  VirtualTable,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import { useTheme } from '@mui/material';

export const DefaultTableCell = ({ ...cellProps }) => {
  const onClick = cellProps.column.disableExpandRow
    ? (e) => e.stopPropagation()
    : null;
  return (
    <VirtualTable.Cell
      onClick={onClick}
      data-qa={`TABLE_CELL:${cellProps.column?.name}`}
      {...cellProps}
    />
  );
};

export const FixedColumnCell = ({ ...props }) => {
  const { tableColumn } = props;
  const { palette } = useTheme();
  const color =
    props.tableRow.key === 'Symbol(heading)'
      ? palette.background.tableHeader
      : 'inherit';
  return (
    <TableFixedColumns.Cell
      {...props}
      style={{
        backgroundColor: color,
        ...props.style,
        ...tableColumn?.column?.style,
      }}
    />
  );
};
