import * as Yup from 'yup';
import _ from 'lodash';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'classnames';

// mui
import { Box, Divider, Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { TextFieldBase } from '../../../../../../components/inputs/TextFieldBase';
import { PhoneField as PhoneFieldBase } from '../../../../../../components/inputs/PhoneField';

// utils
import { withFormController } from '../../../../../../components/hocs/forms';
import { getShortDateTimeFromUtc } from '../../../../../../utils/csvUtils';

// statics
import { ProductTypes } from '../../../../../../types';

const P100RenewalPolicyHolderDetails = ({
  data = {},
  onBindQuote,
  quoteSubjectivityData,
}) => {
  const classes = styles();
  const theme = useTheme();

  const { account = {}, quote = {} } = data;
  const { name: accountName } = account;
  const formattedRenewalQuoteEffectiveDate = getShortDateTimeFromUtc(
    quote.initialRequestData?.effectiveDate
  );

  const defaultValues = {
    policyHolderFirstName: account.policyContactFirstName,
    policyHolderLastName: account.policyContactLastName,
    policyHolderEmail: account.policyContactEmail,
    policyHolderPhone: account.policyContactPhone,
  };

  const { handleSubmit, formState, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  // submit function
  const onSubmit = (formData) => {
    const payload = {
      quoteId: quote.id,
      productType: ProductTypes.p100, // This is hard coded right now for P100 Renewals
      customerEmail: formData.policyHolderEmail,
      customerFirstName: formData.policyHolderFirstName,
      customerLastName: formData.policyHolderLastName,
      customerPhone: formData.policyHolderPhone,
    };

    let subjectivityParams;
    if (quoteSubjectivityData) {
      subjectivityParams = {
        quoteSubjectivityId: quoteSubjectivityData.quoteSubjectivityId,
        payload: {
          customerEmail: formData.policyHolderEmail,
          customerFirstName: formData.policyHolderFirstName,
          customerLastName: formData.policyHolderLastName,
          customerPhone: formData.policyHolderPhone,
        },
      };
    }

    if (_.isFunction(onBindQuote)) {
      onBindQuote(payload, subjectivityParams);
    }
  };

  return (
    <FormProvider {...methods}>
      <form id="POLICY_HOLDER_CONTACT_FORM" onSubmit={handleSubmit(onSubmit)}>
        <Box display="div" fontSize={theme.config.textSizes.primer}>
          <p className={clsx('contrastText', classes.headingText)}>
            Are you sure you want to Bind{' '}
            <strong>Quote# {quote.quoteNumber}</strong> for {accountName} with{' '}
            <strong>
              policy effective {formattedRenewalQuoteEffectiveDate}
            </strong>
            ?
          </p>

          <Divider />

          <p className={clsx('contrastText')}>
            Please confirm the policy holder details below:
          </p>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              name="policyHolderFirstName"
              label="Policy Holder First Name"
              type="text"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="policyHolderLastName"
              label="Policy Holder Last Name"
              type="text"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="policyHolderEmail"
              label="Policy Holder Email"
              type="text"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneField
              name="policyHolderPhone"
              label="Policy Holder Phone Number"
              required
              placeholder="(___) ___-____"
            />
          </Grid>
        </Grid>

        <p className={clsx('contrastText', classes.text)}>
          <i>Note: All other quotes for this account will be invalidated.</i>
        </p>
      </form>
    </FormProvider>
  );
};

const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);

const schema = Yup.object().shape({
  policyHolderFirstName: Yup.string()
    .label('Policy Holder First Name')
    .required(),
  policyHolderLastName: Yup.string()
    .label('Policy Holder Last Name')
    .required(),
  policyHolderEmail: Yup.string()
    .email()
    .label('Policy Holder Email')
    .required(),
  policyHolderPhone: Yup.number()
    .fromPhone()
    .phone()
    .label('Policy Holder Phone Number')
    .required(),
});

const styles = makeStyles(() => ({
  headingText: {
    textAlign: 'center',
    marginTop: 0,
  },
  text: {
    fontSize: '1.16rem',
    marginBottom: 0,
  },
}));

export default P100RenewalPolicyHolderDetails;
