import React, {
  cloneElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { makeStyles } from '@mui/styles';

import { PubSub } from '../../../../utils/eventUtils';
import { TableContext } from '../base/context.table';

export function withExpandableRow(Row) {
  const C = ({ classes = {}, ...props }) => {
    const [expanded, setExpanded] = useState(false);
    const { childOverrides } = useContext(TableContext);
    const rowClasses = useRowStyles();

    useEffect(() => {
      const sub = PubSub.subscribe('table-row:expanded', (rowId) => {
        if (expanded && rowId !== props.row.id) {
          setTimeout(setExpanded(false), 300);
        }
      });

      return () => {
        sub.remove();
      };
    }, [expanded, props.row.id]);

    const expandRow = useCallback(
      (event) => {
        if (
          event.target.matches &&
          event.target.closest &&
          !event.target.matches('td') &&
          !event.target.matches('.expand-icon') &&
          !event.target.closest('.expand-icon')
        ) {
          return;
        }

        if (!expanded) {
          PubSub.publish('table-row:expanded', props.row.id);
        } else {
          PubSub.publish('table-row:collapsed', props.row.id);
        }

        setExpanded(!expanded);
      },
      [expanded, props.row.id]
    );

    if (!childOverrides.ExpandableRow) {
      return <Row {...props} />;
    }

    return (
      <>
        <Row
          {...props}
          className={[classes.withHover, rowClasses.root]}
          onClick={expandRow}
          expanded={expanded}
        />
        {cloneElement(childOverrides.ExpandableRow, {
          expanded,
          classes,
          ...props,
        })}
      </>
    );
  };

  C.displayName = `withExpandableRow(${Row.displayName || Row.name})`;
  C.wrappedComponent = Row;

  return hoistNonReactStatics(C, Row);
}

const useRowStyles = makeStyles({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
