/**
 * @name notesReducer
 * @description This reducer is a local reducer not intended to be used with redux or other global state mechanisms.
 */

import { handleActions } from 'redux-actions';

export const SET_DOCS = 'SET_DOCS';

// action creators

export const setUploadedDocs = (payload) => ({
  type: SET_DOCS,
  payload,
});

export const initialState = {
  docs: {
    attachments: [],
  },
};

export const notesReducer = handleActions(
  {
    [SET_DOCS]: (state, action) => {
      return {
        ...state,
        docs: {
          ...state.docs,
          ...action.payload,
        },
      };
    },
  },
  initialState
);
