import React from 'react';
import Moment from 'moment';
import { getDateString } from '../../../utils/csvUtils';
import SimpleTooltip from '../../SimpleTooltip';

export const CreatedDateCell = (row) =>
  row.created ? (
    <SimpleTooltip title={getDateString(row.created)}>
      {Moment(row.created).fromNow()}
    </SimpleTooltip>
  ) : (
    '-'
  );

export const ModifiedDateCell = (row) =>
  row.modified ? (
    <SimpleTooltip title={getDateString(row.modified)}>
      {Moment(row.modified).fromNow()}
    </SimpleTooltip>
  ) : (
    '-'
  );
