import React from 'react';

const SVG = ({
  id = 'MSExcelIcon',
  style = {},
  fill = '#fff',
  className = '',
  viewBox = '0 0 11.425 12',
  title = 'MSExcelIcon',
}) => (
  <svg
    fill={fill}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
        .cls-1{fill:#fff}.cls-2{fill:#848280}
        `}
      </style>
    </defs>
    <title>{title}</title>
    <path
      id="Path_5335"
      d="M231.56 675.128h-5.737a.285.285 0 0 0-.284.286v11.136a.286.286 0 0 0 .286.286h8.281a.286.286 0 0 0 .286-.286v-8.591a.284.284 0 0 0-.084-.2l-2.545-2.545a.283.283 0 0 0-.203-.086z"
      className="cls-1"
      transform="translate(-223.112 -674.982)"
    />
    <path
      id="Path_5336"
      d="M234.307 677.55l-2.546-2.55a.435.435 0 0 0-.3-.126h-5.737a.433.433 0 0 0-.43.432v11.136a.432.432 0 0 0 .432.432H234a.432.432 0 0 0 .432-.432v-8.591a.434.434 0 0 0-.125-.301zm-2.729-2.315l2.5 2.5h-2.359a.14.14 0 0 1-.139-.139zm2.422 11.35h-8.281a.14.14 0 0 1-.139-.139V675.31a.139.139 0 0 1 .138-.139h5.566v2.423a.432.432 0 0 0 .432.432h2.423v8.42a.139.139 0 0 1-.139.139z"
      className="cls-2"
      transform="translate(-223.008 -674.878)"
    />
    <g id="Group_8508" transform="translate(4.712 5.001)">
      <path
        id="Path_5337"
        d="M230.87 683.421h-1.428v.286h1.428a.143.143 0 1 0 0-.286z"
        className="cls-2"
        transform="translate(-229.442 -683.421)"
      />
      <path
        id="Path_5338"
        d="M230.87 684.885h-1.428v.286h1.428a.143.143 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-229.442 -684.028)"
      />
      <path
        id="Path_5339"
        d="M234.184 689.276h-1.428a.143.143 0 0 0 0 .286h1.428a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-230.757 -685.849)"
      />
      <path
        id="Path_5340"
        d="M230.87 686.348h-1.428v.286h1.428a.143.143 0 1 0 0-.286z"
        className="cls-2"
        transform="translate(-229.442 -684.635)"
      />
      <path
        id="Path_5341"
        d="M230.87 689.276h-1.428v.286h1.428a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-229.442 -685.849)"
      />
      <path
        id="Path_5342"
        d="M230.87 687.811h-1.428v.286h1.428a.143.143 0 0 0 0-.286z"
        className="cls-2"
        transform="translate(-229.442 -685.241)"
      />
      <path
        id="Path_5343"
        d="M234.184 687.813h-1.428a.143.143 0 0 0 0 .286h1.428a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-230.757 -685.242)"
      />
      <path
        id="Path_5344"
        d="M236.17 683.708h1.43a.143.143 0 1 0 0-.286h-1.429a.143.143 0 0 0 0 .287z"
        className="cls-2"
        transform="translate(-232.172 -683.421)"
      />
      <path
        id="Path_5345"
        d="M237.6 687.813h-1.43a.143.143 0 1 0 0 .286h1.43a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-232.172 -685.242)"
      />
      <path
        id="Path_5346"
        d="M237.6 686.349h-1.43a.143.143 0 1 0 0 .286h1.43a.143.143 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-232.172 -684.635)"
      />
      <path
        id="Path_5347"
        d="M237.6 684.886h-1.43a.143.143 0 1 0 0 .286h1.43a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-232.172 -684.028)"
      />
      <path
        id="Path_5348"
        d="M234.184 683.421h-1.429a.143.143 0 0 0 0 .287h1.428a.143.143 0 1 0 0-.286z"
        className="cls-2"
        transform="translate(-230.757 -683.421)"
      />
      <path
        id="Path_5349"
        d="M234.184 684.886h-1.428a.143.143 0 0 0 0 .286h1.428a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-230.757 -684.028)"
      />
      <path
        id="Path_5350"
        d="M237.6 689.276h-1.43a.143.143 0 1 0 0 .286h1.43a.142.142 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-232.172 -685.849)"
      />
      <path
        id="Path_5351"
        d="M234.184 686.349h-1.428a.143.143 0 0 0 0 .286h1.428a.143.143 0 1 0 0-.285z"
        className="cls-2"
        transform="translate(-230.757 -684.635)"
      />
    </g>
    <g id="Group_8509" transform="translate(0 4.001)">
      <g id="Path_5327">
        <path
          id="Path_5352"
          fill="#226b2f"
          d="M221.964 687.424h4.566a.571.571 0 0 0 .571-.571v-4.566a.571.571 0 0 0-.569-.574h-4.566a.571.571 0 0 0-.574.569v4.568a.571.571 0 0 0 .569.574z"
          transform="translate(-221.393 -681.713)"
        />
      </g>
      <g id="Path_5328" transform="translate(1.278 1.143)">
        <path
          id="Path_5353"
          d="M223.576 687.092l1.126-1.718-1.031-1.709h.829l.563 1.092a2.441 2.441 0 0 1 .106.234h.007c.039-.083.078-.163.117-.241l.6-1.085h.761l-1.058 1.7 1.085 1.727h-.81l-.65-1.2a1.01 1.01 0 0 1-.078-.16h-.009a.729.729 0 0 1-.075.155l-.67 1.2z"
          className="cls-1"
          transform="translate(-223.576 -683.665)"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
