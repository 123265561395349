import {
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
  List as ListBase,
  ListItem,
  styled,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import CbButton from '../../../../components/Buttons/CbButton';
import type { Policy } from '../../../accounts/summary/types/policy.types';
import { deleteProgramFromPolicy } from '../../../../api/policies.api';
import { COWBELL_POLICY } from '../../../../components/tables/table_constants';
import { useAPIErrorHandler } from '../../../../components/hooks/useAPIErrorHandler';

interface SelectedItem {
  id: string;
  data: Policy;
}

const BulkRemoveProgramModal = ({ data: selection, ...props }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const handleAPIError = useAPIErrorHandler();

  const isValidSelection = selection.every((policy: SelectedItem) => {
    return Boolean(policy.data.program);
  });

  if (!isValidSelection) {
    return (
      <>
        <DialogContent>
          <Typography variant="h6">Invalid Selection</Typography>
          <br />
          <DialogContentText>
            One or more of the selected policies is not currently associated
            with a program. Please double check your selection and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="ctaButton" onClick={props.close}>
            OK
          </CbButton>
        </DialogActions>
      </>
    );
  }

  const onConfirm = () => {
    const promises = selection.map((policy: SelectedItem) => {
      return deleteProgramFromPolicy(policy.data.product, policy.id);
    });
    return Promise.all(promises)
      .then(() => {
        enqueueSnackbar('Policies updated successfully', {
          variant: 'success',
        });
        queryClient.invalidateQueries([COWBELL_POLICY]);
        props.close();
      })
      .catch(
        handleAPIError(
          'There was a problem with your request, please try again'
        )
      );
  };

  return (
    <>
      <DialogContent>
        <DialogContentText>
          Are you sure you would like to remove programs from the following
          policies?
        </DialogContentText>
        <List>
          {selection.map((policy: SelectedItem) => (
            <ListItem key={policy.id}>
              <Typography>{`${policy.data.companyName} (${policy.data.policyNumber})`}</Typography>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={props.close}>
          Cancel
        </CbButton>
        <CbButton styleName="ctaButton" onClick={onConfirm}>
          Confirm
        </CbButton>
      </DialogActions>
    </>
  );
};

const List = styled(ListBase)`
  max-height: 20rem;
  overflow: auto;
  margin-top: 0.6rem;
`;

export const BulkRemoveProgramModalConfig = {
  BulkRemoveProgramModal: {
    component: BulkRemoveProgramModal,
    config: {
      title: 'Bulk Remove Program',
      fullWidth: true,
    },
  },
};
