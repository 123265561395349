import React from 'react';

import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Typography,
} from '@mui/material';

import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  setAccountDetails,
  setAgentDetails,
} from '../console/_tabs/tabs.reducer';
import store from '../config/storeConfig';

import TableMenuIcon from '../_assets/svg/TableMenuIcon.svg';
import TableMenuCloseIcon from '../_assets/svg/TableMenuCloseIcon.svg';
import { useMenuState } from '../components/tables/cell-components/menu/Menu';
import { useToggleModal } from '../utils/modal.utils';
import { useAllow } from '../components/hooks/useAllow';
import { useCowbellTranslations } from '../i18n/translations';
import { Modal } from '../components/modals/v2/helpers/v2.modal.helpers';
import { UpdatePHContactConfig } from '../components/modals/v2/UpdatePHContact';
import { AGENCY_ACCOUNTS } from '../components/tables/table_constants';
import { COUNTRIES } from '../i18n/i18n.language-config';

const AgencyAccountOptions = ({ account }) => {
  const classes = useStyles();
  const menuState = useMenuState();
  const { translations } = useCowbellTranslations(['Agent']);

  const isUpdate =
    !!account.policyContactFirstName &&
    !!account.policyContactLastName &&
    !!account.policyContactEmail;

  const addContactLabel = isUpdate ? 'Update Contact' : 'Add Contact';

  const menuOptionHandlers = useAgencyAccountMenuOptionHandlers(
    account,
    isUpdate,
    addContactLabel
  );

  return (
    <Box
      className={classes.headerMenu}
      onClick={(event) => event.stopPropagation()}
    >
      <MuiIconButton
        size="small"
        aria-haspopup="true"
        onClick={menuState.handleOpen}
        aria-owns={menuState.anchorEl ? 'header-menu' : null}
        data-qa="accountActions"
      >
        {menuState.anchorEl ? (
          <ArrowRightIcon fontSize="large" className={classes.iconStyle} />
        ) : null}
        {menuState.anchorEl ? (
          <TableMenuCloseIcon fontSize="small" />
        ) : (
          <TableMenuIcon fontSize="small" />
        )}
      </MuiIconButton>
      <Menu
        id="user-menu"
        anchorEl={menuState.anchorEl}
        style={{ left: -10 }}
        anchorOrigin={{ vertical: 'right', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={classes.menu}
        open={Boolean(menuState.anchorEl)}
        onClose={menuState.handleClose}
        PaperProps={{
          style: {
            transform: 'translateX(-12%) ',
          },
        }}
      >
        <MenuItem
          dense
          onClick={menuState.handleClose}
          className={classes.menuItem}
        >
          <Box
            onClick={menuOptionHandlers.handleEditAccountDetails}
            className={classes.linkWrapper}
          >
            <Typography variant="body1" className={classes.menuItemText}>
              Edit Account Details
            </Typography>
          </Box>
        </MenuItem>

        <MenuItem
          dense
          onClick={menuState.handleClose}
          className={classes.menuItem}
        >
          <Box
            onClick={menuOptionHandlers.handleAddContact}
            className={classes.linkWrapper}
          >
            <Typography variant="body1" className={classes.menuItemText}>
              {addContactLabel}
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          dense
          onClick={menuState.handleClose}
          className={classes.menuItem}
        >
          <Box
            onClick={menuOptionHandlers.handleUpdateAgent}
            className={classes.linkWrapper}
          >
            <Typography variant="body1" className={classes.menuItemText}>
              Update {translations.Agent}
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          dense
          onClick={menuState.handleClose}
          className={classes.menuItem}
        >
          <Box
            onClick={menuOptionHandlers.handleApplyTags}
            className={classes.linkWrapper}
          >
            <Typography variant="body1" className={classes.menuItemText}>
              Apply/Change Tag(s)
            </Typography>
          </Box>
        </MenuItem>

        <MenuItem
          dense
          onClick={menuState.handleClose}
          className={classes.menuItem}
        >
          <Box
            onClick={menuOptionHandlers.handleDelete}
            className={classes.linkWrapper}
          >
            <Typography variant="body1" className={classes.menuItemText}>
              Delete Account
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export const useAgencyAccountMenuOptionHandlers = (account) => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const toggleModal = useToggleModal();
  const history = useHistory();
  const accountManage = useAllow(['accounts:manage']);
  const { translations } = useCowbellTranslations(['Agent']);

  const handleAddContact = () => {
    Modal.show(UpdatePHContactConfig, {
      data: {
        customerPhone: account.policyContactPhone,
        customerEmail: account.policyContactEmail,
        customerFirstName: account.policyContactFirstName,
        customerLastName: account.policyContactLastName,
        accountId: account.id,
        product: account.productType,
        agencyId: account.agencyId,
        agentFirstName: account.agentFirstName,
        agentLastName: account.agentLastName,
        agentEmail: account.agentEmail,
        agentPhone: account.agentPhone,
        refetchTableData: () =>
          setTimeout(
            () => queryClient.invalidateQueries([AGENCY_ACCOUNTS]),
            1250
          ),
      },
    });
  };

  const handleDelete = () => {
    toggleModal.direct('AccountsDeleteConfirm', account, {
      title: 'Delete Account',
      icon: 'Delete',
      footerButtons: [
        { buttonText: 'Cancel', action: 'cancel' },
        {
          buttonText: 'Delete Account',
          action: 'foot-button-one',
          showLoading: true,
          showDisabled: true,
        },
      ],
    });
  };

  const handleApplyTags = () => {
    const Icon = (
      <img
        alt="AccountSvg"
        src="images/Accounts.svg"
        className={classes.icon}
      />
    );

    toggleModal.direct('ApplyChangeTags', account, {
      title: `Apply/Change Tag(s) for ${account.name}`,
      icon: 'Accounts',
      maxWidth: 'md',
      footerButtons: [
        { buttonText: 'Cancel', action: 'cancel' },
        {
          buttonText: 'Save',
          action: 'foot-button-one',
          showLoading: true,
          showDisabled: true,
        },
      ],
      iconComponent: Icon,
      scroll: 'body',
    });
  };

  const handleEditAccountDetails = () => {
    store.dispatch(
      setAccountDetails({
        account: {
          companyName: account.name,
          quoteNumber: '',
          accountId: account.id,
        },
      })
    );

    store.dispatch(
      setAgentDetails({
        agent: {
          from: '/agency/accounts',
          readOnly: {
            quote: true,
            policy: true,
            account: !accountManage,
            risk: true,
            documents: {
              upload: false,
              delete: true,
            },
          },
        },
      })
    );

    history.push(
      `/agency/accounts/details/no-quote/${account.id}/${account.productType}/account-profile`
    );
  };

  const handleUpdateAgent = () => {
    const Icon = (
      <img alt="Agents" src="images/Agents.svg" className={classes.icon} />
    );
    toggleModal.direct('AgencyUpdateAgent', account, {
      title: `Update ${translations.Agent} for this account`,
      maxWidth: 'md',
      iconComponent: Icon,
    });
  };

  return {
    handleAddContact,
    handleDelete,
    handleApplyTags,
    handleEditAccountDetails,
    handleUpdateAgent,
  };
};

const useStyles = makeStyles(({ config, palette }) => ({
  menu: {
    '& button': {
      padding: 0,
      color: palette.components.globalHeader.contrastText,
      textTransform: 'capitalize',
    },
    '& ul': {
      padding: 20,
      backgroundColor: palette.background.darkerBlue,
      color: palette.text.title,
      border: `1px solid ${palette.border.menuItem}`,
      borderRadius: '5px',
    },
  },

  iconStyle: {
    marginLeft: '-39.5px',
    color: palette.border.icon,
  },

  linkWrapper: {
    textDecoration: 'none',
    outline: 0,
  },

  menuItemText: {
    color: palette.secondary.contrastText,
    fontSize: config.textSizes.normal,
    '&:hover': {
      cursor: 'pointer',
      maxWidth: 150,
      fontWeight: 600,
    },
  },
  icon: {
    width: '2.75rem',
    height: '2.75rem',
    margin: '1.667rem 1.25rem',
  },
}));

export default AgencyAccountOptions;
