import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { Typography } from '@mui/material';

import {
  DialogActionsBase,
  DialogContentBase,
} from '../BulkRenewalOptOut/helpers';

import { manageAPIError } from '../../../../../../utils';
import { bulkRenewalProcessToggle } from '../../../../../../api/policies.api';

import { COWBELL_POLICY } from '../../../../../tables/table_constants';

function BulkRenewalOptIn({ data: { policyIdList }, ...props }) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  if (!Array.isArray(policyIdList) || policyIdList.length == 0) {
    throw new Error(
      `The ${BulkRenewalOptIn.name} component must be provided an array of policies`
    );
  }

  function handleOptIn() {
    const reqBody = {
      policyIdList,
      optOut: false,
    };

    return bulkRenewalProcessToggle({
      data: reqBody,
    })
      .then(() => {
        queryClient.invalidateQueries([COWBELL_POLICY]);
        props.close();
        enqueueSnackbar('Renewal opt-in requested!', { variant: 'success' });
      })
      .catch((err) =>
        enqueueSnackbar(
          manageAPIError(err, 'There was an issue making this request'),
          { variant: 'error' }
        )
      );
  }

  return (
    <>
      <DialogContentBase>
        <Typography align="center" style={{ padding: '2rem 0' }}>
          Are you sure you want to opt-in to the renewal process for all
          selected policies?
        </Typography>
      </DialogContentBase>
      <DialogActionsBase
        onCancel={props.close}
        onConfirm={handleOptIn}
        confirmationButtonText="Opt-In"
      />
    </>
  );
}

export const BulkRenewalOptInConfig = {
  BulkRenewalOptIn: {
    component: BulkRenewalOptIn,

    config: {
      maxWidth: 'sm',
      title: 'Renewal Opt-In',
    },
  },
};

export default BulkRenewalOptIn;
