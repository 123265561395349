import React from 'react';
import { MultiSelectStateContext } from './MultiSelectStateContext';

export function useMultiSelectState() {
  const state = React.useContext(MultiSelectStateContext);
  if (state === undefined) {
    throw new Error(
      'useMultiSelectState must be used within a MultiSelectStateProvider'
    );
  }

  return state;
}
