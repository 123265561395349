import { ServiceHandler } from '../utils';

export const createExcessQuote = ServiceHandler({
  url: '/api/quote/v1/excess/:accountId',
  method: 'POST',
});

export const getExcessQuote = ServiceHandler({
  url: '/api/quote/v1/excess/:quoteId',
});

export const updateExcessQuote = ServiceHandler({
  url: '/api/quote/v1/excess/:quoteId',
  method: 'PUT',
});

export const deleteExcessQuoteEndorsement = ServiceHandler({
  url: '/api/quote/v1/excess/:quoteId/endorsement/:endorsementId',
  method: 'DELETE',
});

export const requestExcessQuote = ServiceHandler({
  url: '/api/quote/v1/excess/:accountId/requestquote',
  method: 'POST',
});

export const getAllCarriers = ServiceHandler({
  url: '/api/quote/v1/excess/carriers',
});

export const createUnderlyingInsuranceLayer = ServiceHandler({
  url: '/api/quote/v1/excess/:quoteId/underlyinginsurance',
  method: 'POST',
});

export const updateUnderlyingInsuranceLayer = ServiceHandler({
  url: '/api/quote/v1/excess/:quoteId/underlyinginsurance',
  method: 'PUT',
});

export const deleteUnderlyingInsuranceLayer = ServiceHandler({
  url: '/api/quote/v1/excess/:quoteId/underlyinginsurance/:underlyingInsuranceId',
  method: 'DELETE',
});
