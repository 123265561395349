import React, { useState } from 'react';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import {
  Grid as MuiGrid,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';
import { useForm, FormContext } from 'react-hook-form-4';

import * as Yup from 'yup';
import { toggleModalDirect } from '../../utils/storeUtils';
import { withFormController } from '../../components/hocs/withFormController';
import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { delayedEvent } from '../../utils/eventUtils';
import { hostname } from '../../utils/validationSchemas/accounts.schema';
import { createDa, updateDa } from './da.service';
import { getUrlHost } from '../../utils/appUtils';
import CbButton from '../../components/Buttons/CbButton';
import { HostnameField } from '../../components/inputs/HostnameField';

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  hostname,
  name: Yup.string().required().label('Name'),
  description: Yup.string().required().label('Description'),
});

const DaCreateEdit = ({ data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');

  const {
    handleSubmit,
    formState: { isSubmitting, dirty },
    ...methods
  } = useForm({
    defaultValues: {
      name: data.name || '',
      description: data.description || '',
      hostname: data.hostname || '',
    },
    validationSchema: schema,
  });

  const onSubmit = (formData) => {
    const domain = getUrlHost();
    const payload = {
      hostname: formData.hostname + domain,
      description: formData.description,
      name: formData.name,
    };
    if (data && data.id) {
      return updateDa({ id: data.id }, formData)
        .then(() => {
          enqueueSnackbar(`DA Updated successfully!`, { variant: 'success' });
          delayedEvent('table-refetch', 500, 'da');
          toggleModalDirect('DaCreateEdit', false);
        })
        .catch((error) => {
          const errorMessage = _.get(error, 'response.data');
          if (typeof errorMessage === 'string') {
            setError(errorMessage);
          } else {
            setError('Unable to save your data, please try again');
          }
        });
    }
    return createDa({}, payload)
      .then(() => {
        enqueueSnackbar(`DA Created successfully!`, { variant: 'success' });
        delayedEvent('table-refetch', 500, 'da');
        toggleModalDirect('DaCreateEdit', false);
      })
      .catch((error) => {
        const errorMessage = _.get(error, 'response.data');
        if (typeof errorMessage === 'string') {
          setError(errorMessage);
        } else {
          setError('Unable to save your data, please try again');
        }
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} data-qa="addAgency">
        <DialogContent>
          <MuiGrid spacing={8} style={{ marginTop: -20 }}>
            <TextField
              name="name"
              label="Name"
              required
              fullWidth
              inputProps={{ required: true }}
            />
            <TextField
              name="description"
              label="Description"
              required
              fullWidth
            />
            <HostnameField name="hostname" defaultValue={data.hostname} />
          </MuiGrid>
        </DialogContent>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
        <DialogActions>
          <CbButton
            styleName="cancel"
            onClick={props.close}
            buttonText="Cancel"
          />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText={data.id ? 'Edit Aggregator' : 'Add Aggregator'}
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

export default DaCreateEdit;
