import _ from 'lodash';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Grid as MuiGrid,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// lib
// platform helpers
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '../Buttons/Button';
import { withShowable } from '../../console/_global/lib/withShowable';
import { AgencyTeamsAccountCreationHeader } from '../../accounts/admin-account-flow/AgencyTeamsAccountCreationHeader';
import { PubSub } from '../../utils/eventUtils';
// components
import withRouter from '../hocs/withRouter';
import CBAutocomplete from '../autocomplete/CBAutocomplete';

import CBButton from '../Buttons/CbButton';

// actions
import {
  addNewAccount,
  getAutocompleteAccounts,
} from '../../accounts/AccountService';
// constants
import { AGENCY_ACCOUNTS, COWBELL_ACCOUNTS } from '../tables/table_constants';

import {
  getIsUsPlatform,
  getPlatformRegion,
  manageAPIError,
} from '../../utils';
import { withFormController } from '../hocs/forms';
import LanguageForm, {
  formatPhoneAsString,
  useLanguageFormState,
} from '../../i18n/forms/LanguageForm';
import { FormLanguageProvider } from '../../i18n/forms/FormLanguageProvider';
import TextFieldBase from '../inputs/text-fields/base/TextFieldBase';
import { LANGUAGE_COUNTRY_MAPPING } from '../../i18n/i18n.language-config';

const TextField = withFormController(TextFieldBase);
const Grid = withShowable(MuiGrid);

const defaultValues = {
  state: '',
  accountName: '',
  phone: '',
  street: '',
  city: '',
  zipCode: '',
};

let searchStr = '';
const AccountsCreateEdit = withRouter(
  ({ classes, close, location, ...props }) => {
    const { enqueueSnackbar } = useSnackbar();
    const teams = useSelector(
      ({ uiSettings }) => uiSettings.team.accountCreation
    );

    const { gridLayoutSettings, ...languageFormState } = useLanguageFormState();
    const isUSPlatform = getIsUsPlatform(languageFormState.language);

    const route = location.pathname.split('/')[1];
    const queryClient = useQueryClient();

    const { handleSubmit, ...methods } = useForm({
      defaultValues,
    });

    const {
      control,
      getValues,
      formState: { dirty, isSubmitting, errors },
      setValue,
    } = methods;

    const values = getValues();

    const [state, setState] = React.useState({
      cbid: null,
      accountNames: [],
      isSearching: true,
      accountName: '',
      errorMessage: '',
    });

    const resetHandler = () => {
      setState((prevState) => {
        return {
          ...prevState,
          cbid: null,
          accountNames: [],
          isSearching: true,
          accountName: '',
          errorMessage: '',
        };
      });

      methods.reset(defaultValues);

      PubSub.publish('updateButtonText', {
        buttonId: 'AccountsCreateEdit-submit',
        buttonText: 'Search',
      });
    };

    const accountNamesSearch = (addressState) => {
      if (searchStr) {
        const SEARCH_PARAMS = isUSPlatform
          ? {
              state: addressState,
              search: searchStr,
              page: 0,
              pageSize: 10,
            }
          : {
              country: LANGUAGE_COUNTRY_MAPPING[languageFormState.language],
              search: searchStr,
              page: 0,
              pageSize: 10,
            };
        getAutocompleteAccounts(SEARCH_PARAMS).then((resp) => {
          const accountNames = resp.data.content || [];

          setState((prevState) => {
            return {
              ...prevState,
              accountNames,
            };
          });
        });
      }
    };

    const searchAccountsByName = useCallback(
      _.debounce(accountNamesSearch, 200),
      []
    );

    const onSubmit = async (formData) => {
      const payload = {
        state: formData.state,
        name: formData.accountName,
        cbid: state.cbid,
        phone: formData.phone,
        address1: formData.street,
        city: formData.city,
        zipCode: formData.zipCode,
        teams: [{ name: _.get(teams, 'name', ''), id: _.get(teams, 'id', '') }],
        country: LANGUAGE_COUNTRY_MAPPING[languageFormState.language],
      };

      if (state.isSearching) {
        close();
        props.history.push(`/${route}/manage/account/lookup`, {
          state: formData.state,
          accountName: formData.accountName,
          preload: true,
          city: formData.city,
          zip: formData.zipCode,
          phone: formData.phone,
          street: formData.street,
          teams: { label: teams.name, value: teams.id },
          region: languageFormState.language,
        });
      } else {
        try {
          await languageFormState.validateAddress({
            address1: formData.street,
            city: formData.city,
            zipCode: formData.zipCode,
          });
          return addNewAccount(payload)
            .then(() => {
              close();
              enqueueSnackbar('Account Created Successfully!', {
                variant: 'success',
              });
              queryClient.invalidateQueries([AGENCY_ACCOUNTS]);
              queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
            })
            .catch((error) => {
              const errorMessage = manageAPIError(
                error,
                'Server Error. Please try again'
              );

              setState({
                errorMessage,
              });

              if (_.get(error, 'response.status', 409) === 409) {
                resetHandler();
                enqueueSnackbar(errorMessage, { variant: 'error' });
              }
            });
        } catch {
          //
        }
      }
    };

    const handleAccountNameChange = (event, value) => {
      if (value && typeof value === 'object') {
        PubSub.publish('updateButtonText', {
          buttonId: 'AccountsCreateEdit-submit',
          buttonText: 'Add Account',
        });

        setState((prevState) => {
          return {
            ...prevState,
            isSearching: false,
            cbid: value.cbid,
            accountName: value.name,
          };
        });

        setValue('accountName', value.name);
        setValue('phone', value.phoneNumber);
        setValue('street', value.address1);
        setValue('city', value.city);
        setValue('zipCode', value.zipCode);
        setValue('state', value.state);
      }
    };

    const handleSearch = (event) => {
      const { value } = event.target;
      setState((prevState) => {
        return {
          ...prevState,
          accountName: value,
        };
      });
      setValue('accountName', value);
      if (!value) {
        setState((prevState) => {
          return {
            ...prevState,
            accountNames: [],
          };
        });
        searchStr = '';
      }

      if (value) {
        searchStr = value;
        searchAccountsByName(values?.state ?? '');
      }
    };

    return (
      <section>
        <FormLanguageProvider language={languageFormState.language}>
          <FormProvider {...methods}>
            <form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              data-qa="newAccount"
            >
              <DialogContent classes={{ root: classes.container }}>
                <Button
                  onClick={resetHandler}
                  className={`${classes.resetButton} ${
                    isSubmitting || !dirty ? classes.resetDisabled : ''
                  }`}
                  disableRipple
                >
                  CLEAR ALL
                </Button>

                <Grid container spacing={6}>
                  <Grid
                    item
                    md={route === 'agency' ? 6 : 12}
                    show={gridLayoutSettings.showState}
                  >
                    <LanguageForm.State
                      showFullStates
                      disabled={!state.isSearching}
                    />
                  </Grid>
                  <Grid
                    item
                    md={gridLayoutSettings.showState ? 6 : 12}
                    show={route === 'agency'}
                  >
                    <AgencyTeamsAccountCreationHeader data={state} />
                  </Grid>
                </Grid>

                <section className={classes.autocompleteContainer}>
                  <CBAutocomplete
                    id="accountName"
                    required="true"
                    inputValue={state.accountName || ''}
                    placeholder="please enter account name"
                    options={state.accountNames}
                    disabled={!state.isSearching}
                    getOptionLabel={(o) => o?.name ?? ''}
                    renderOption={renderAccountOption(
                      classes,
                      languageFormState.language
                    )}
                    onChangeHandler={handleAccountNameChange}
                    label="Account Name"
                    onTextChangeHandler={handleSearch}
                    data-qa="name"
                  />
                  {errors.accountName && (
                    <span role="alert" style={{ color: '#fa8072' }}>
                      Account Name is exceeding 100 Characters. Please reset
                      Handler.
                    </span>
                  )}
                </section>

                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <LanguageForm.PhoneField
                      name="phone"
                      fullWidth
                      required={false}
                      disabled={!state.isSearching}
                    />
                  </Grid>

                  <Grid item sm={12} style={{ paddingTop: 0 }}>
                    <TextField
                      placeholder="enter street address"
                      name="street"
                      label="Street"
                      control={control}
                      fullWidth
                      disabled={!state.isSearching}
                      data-qa="street"
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <LanguageForm.City
                      required={false}
                      disabled={!state.isSearching}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <LanguageForm.PostalCode
                      required={false}
                      disabled={!state.isSearching}
                    />
                  </Grid>
                </Grid>
                <FormHelperText className="api-text-error">
                  {state.errorMessage}
                </FormHelperText>
                <section
                  className={`${classes.showWarning} ${
                    state.isSearching && values.accountName
                      ? ''
                      : classes.hideWarning
                  }`}
                >
                  <section className={classes.warning1}>
                    <img
                      src="images/info-2.svg"
                      className={classes.icon}
                      alt="Info icon"
                    />
                    Can’t find the new account? Just hit search
                  </section>
                </section>
              </DialogContent>
              <DialogActions>
                <CBButton action={close} styleName="cancel">
                  Cancel
                </CBButton>
                <CBButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting || !(values.accountName && teams.id)}
                  styleName="ctaButton"
                  buttonText="Search"
                  buttonId="AccountsCreateEdit-submit"
                  data-qa="search"
                />
              </DialogActions>
            </form>
          </FormProvider>
        </FormLanguageProvider>
      </section>
    );
  }
);

const styles = ({ palette, config }) => {
  return {
    container: {
      flex: '1 1 auto',
      padding: '0 5rem 0.5rem 5rem',
      'overflow-y': 'visible',
    },

    icon: {
      paddingLeft: 5,
      paddingRight: 5,
      width: '2rem',
    },
    warning1: {
      display: 'flex',
      justifyContent: 'center',
    },
    showWarning: {
      fontSize: '1.167rem',
      visibility: 'visible',
      lineHeight: 1.14,
      color: palette.primary.mainYellow,
      textAlign: 'center',
    },
    hideWarning: {
      visibility: 'hidden !important',
    },
    resetButton: {
      position: 'absolute',
      top: '90px',
      right: 0,
      '& .MuiButton-label': {
        fontSize: '1.167rem',
        fontWeight: 600,
      },
      lineHeight: 1.5,
      color: config.colors.cowbellBlue,
      cursor: 'pointer',
      paddingRight: '1rem',
    },
    resetDisabled: {
      color: palette.button.disabledBackground,
      cursor: 'auto',
    },
    autocompleteContainer: {
      marginTop: '1.5rem',
      marginBottom: '1rem',
    },
    domainName: {
      bottom: 10,
      right: 0,
      margin: '0 !important',
      fontSize: '0.67rem',
    },
    itemContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    itemNameLine: {
      margin: 0,
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: palette.primary.main,
      fontSize: '1.167rem',
    },
    error: {
      textAlign: 'left',
      maxWidth: '600px',
      fontSize: config.textSizes.normal,
    },
    stateInput: {
      padding: 0,
    },
    addressLine: {
      margin: '0 !important',
      fontSize: '1rem',
      lineHeight: 1.17,
      color: palette.primary.secondary,
    },
  };
};

function renderAccountOption(classes, language) {
  return (optionProps, option) => {
    if (option.name) {
      const { domainName, address1, name, city, zipCode, phoneNumber } = option;
      const tempPhone = phoneNumber.replace(/[^0-9]/g, '');
      const finalPhone = formatPhoneAsString(language, tempPhone);

      const stateVal = option.state;
      return (
        <li {...optionProps}>
          <div className={classes.itemContainer}>
            <section>
              <p className={classes.itemNameLine}>{name}</p>
              <p className={classes.addressLine}>
                {address1}, {city}, {stateVal}, {zipCode}
              </p>
              <p className={classes.addressLine}>{finalPhone}</p>
            </section>
            <span className={classes.domainName}>{domainName}</span>
          </div>
        </li>
      );
    }

    return <li {...optionProps}>''</li>;
  };
}

export default withStyles(styles)(withRouter(AccountsCreateEdit));
