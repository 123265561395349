import React from 'react';
import { compose } from 'redux';

import { useForm, FormContext } from 'react-hook-form-4';
import _ from 'lodash';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { withStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';
import CbButton from '../../components/Buttons/CbButton';
import { expediteBOR } from '../../policies/PolicyService';
import { manageAPIError } from '../../utils';

export const ExpeditePolicyBor = compose(
  withStyles(({ config }) => ({
    highlight: {
      color: config.colors.cowbellBlue,
      fontWeight: config.weights.bold,
    },
  }))
)(({ data, classes, ...props }) => {
  const { id, policyNumber } = data;
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, ...methods } = useForm({});

  const onSubmit = () => {
    expediteBOR({ id })
      .then(() => {
        enqueueSnackbar('Expedite BOR request submitted.', {
          variant: 'success',
        });
        props.close();
      })
      .catch((error) => {
        const errorMessage = manageAPIError(
          error,
          'Expedite BOR request failed!'
        );

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      });
  };

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className="paf">
            <DialogContentText>
              Are you sure you want to Expedite BOR for #
              <span className={classes.highlight}>{policyNumber}</span> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CbButton onClick={props.close} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton type="submit" styleName="ctaButton">
              Expedite BOR
            </CbButton>
          </DialogActions>
        </form>
      </FormContext>
    </>
  );
});

export const ExpeditePolicyBorModalConfig = {
  ExpeditePolicyBor: {
    component: ExpeditePolicyBor,
    config: {
      fullWidth: true,
      title: 'Expedite BOR',
    },
  },
};
