import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

/**
 * @description Removes team from uiSettings
 */
export default (state) => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      team: uiSettingsDefaults.team,
    },
  };
};
