import TableMenu, {
  useMenuState,
} from '../../../../../../components/tables/cell-components/menu/Menu';
import type {
  AddEditAgencyLevelPayload,
  AgencyLevelListingTableRow,
} from '../../../../../../api';
import { editAgencyLevel } from '../../../../../../api';
import { Modal } from '../../../../../../components/modals/v2/helpers/v2.modal.helpers';
import { useCowbellTranslations } from '../../../../../../i18n/translations';

interface AgencyLevelMenuOptionsProps {
  row: AgencyLevelListingTableRow;
}

const AgencyLevelMenuOptions = ({ row }: AgencyLevelMenuOptionsProps) => {
  const menuState = useMenuState();
  const { t, translationKeys } = useCowbellTranslations();

  const handleEditAgencyLevel = () => {
    menuState.handleClose();

    const handleAsyncSubmit = (payload: AddEditAgencyLevelPayload) => {
      return editAgencyLevel(row.id, { data: payload });
    };

    Modal.show('AddEditAgencyLevelModal', {
      data: {
        levelName: row.levelName,
        revenueBands: row.ui_revenueBands,
        onSubmit: handleAsyncSubmit,
      },
      config: { title: `Edit ${t(translationKeys.agency)} Level` },
    });
  };

  const handleDeleteAgency = () => {
    menuState.handleClose();
    Modal.show('DeleteAgencyLevelModal', {
      data: {
        levelId: row.id,
        levelName: row.levelName,
      },
      config: { title: `Delete ${t(translationKeys.agency)} Level` },
    });
  };

  return (
    <>
      <TableMenu.Icon
        handleOpen={menuState.handleOpen}
        anchorEl={menuState.anchorEl}
      />

      <TableMenu.Menu {...menuState}>
        <TableMenu.Item onClick={handleEditAgencyLevel}>
          Edit {t(translationKeys.agency)} Level
        </TableMenu.Item>
        <TableMenu.Item onClick={handleDeleteAgency}>
          Delete {t(translationKeys.agency)} Level
        </TableMenu.Item>
      </TableMenu.Menu>
    </>
  );
};

export default AgencyLevelMenuOptions;
