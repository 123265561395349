import React from 'react';

const SVG = ({
  id = 'accounts',
  style = {},
  fill = '#9192a4',
  width = '100%',
  className = '',
  viewBox = '0 0 30 30',
  title = 'Accounts',
}) => {
  return (
    <svg
      fill={fill}
      width={width}
      style={style}
      className={`svg ${className}`}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>
          {`
          .svg-accounts-1 { fill: none; }
          .${id}.svg-fill-accounts { fill: ${fill}; }
        `}
        </style>
      </defs>
      <title>{title}</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Isolation_Mode" data-name="Isolation Mode">
          <rect className="svg-accounts-1" width="30" height="30" />
          <path
            className={`${id} svg-fill-accounts`}
            d="M22.65,11.07A3.68,3.68,0,1,0,16.49,8a3.67,3.67,0,0,0-3,0,3.68,3.68,0,1,0-6.16,3.1A8.06,8.06,0,0,0,2.5,18.16a.71.71,0,0,0,.14.47,8.82,8.82,0,0,0,5.68,3.6,8.83,8.83,0,0,0,12.46.95,7.84,7.84,0,0,0,.95-1,8.78,8.78,0,0,0,5.63-3.59.71.71,0,0,0,.14-.47A8.06,8.06,0,0,0,22.65,11.07Zm-2.5-4.91a2.21,2.21,0,1,1-2.21,2.21A2.21,2.21,0,0,1,20.15,6.16ZM15,9.11a2.21,2.21,0,1,1-2.21,2.21A2.21,2.21,0,0,1,15,9.11ZM9.84,6.16A2.21,2.21,0,1,1,7.63,8.37,2.21,2.21,0,0,1,9.84,6.16ZM7.72,20.58A7.42,7.42,0,0,1,4,18c.31-3.35,2.84-5.93,5.86-5.93a5.33,5.33,0,0,1,1.63.25,3.66,3.66,0,0,0,1,1.71A8,8,0,0,0,7.72,20.58ZM15,23.84a7.4,7.4,0,0,1-5.86-2.9C9.47,17.59,12,15,15,15s5.55,2.59,5.86,5.94A7.38,7.38,0,0,1,15,23.84Zm7.3-3.27A8,8,0,0,0,17.53,14a3.61,3.61,0,0,0,1-1.71,5.26,5.26,0,0,1,1.58-.24c3,0,5.55,2.58,5.86,5.93a7.42,7.42,0,0,1-3.69,2.58Z"
          />
        </g>
      </g>
    </svg>
  );
};
export default SVG;
