import React from 'react';
import { Autocomplete } from '@mui/lab';
import type { TextFieldProps } from '@mui/material';
import { Popper, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import type { AxiosError } from 'axios';
import { manageAPIError } from '../../../utils';
import { InputLabelBase } from '../InputLabelBase';
import type { InputLabelBaseProps } from '../InputLabelBase';

/**
 * Meant to be a reusable typeahead component.
 * It takes a async function as a promise and bubbles the search term back to the user to do with however they please
 * Uses default mui autocomplete behavior
 *
 * @prop {label} - used as an input label
 * @prop {name} - used as the name attribute for the field
 * @prop {required} - denotes whether the field is required or not
 * @prop {autoCompleteProps} - optional props that override the default autocomplete props
 * @prop {inputLabelBaseProps} - optional props that override the default input label base props
 * @prop {textFieldProps} - optional props that override the default text field props
 * @prop {asyncFunction} - is an api that is used inside of a useQuery. This function takes the search term and bubbles back out to the developer
 * @prop {onSelect} - is a function that takes the selected item and bubbles it back out to the developer
 * @prop {queryKey} - optional props to provide custom queryKey for useQuery
 * @example
 *    <TypeAheadAutoComplete
          name="naics"
          label="Industry"
          required
          asyncFunction={(searchTerm) =>
            handleNaicsCodeSearch(searchTerm)
          }
          onSelect={(value) =>
            useFormMethods.setValue('naics', value)
          }
      />
 */

type TypeAheadAutoCompleteProps = {
  label: string;
  name: string;
  required?: boolean;
  autoCompleteProps?: React.ComponentType<typeof Autocomplete> | object;
  inputLabelBaseProps?: Omit<InputLabelBaseProps, 'children'>;
  textFieldProps?: TextFieldProps;
  asyncFunction: (searchTerm: string) => Promise<any>;
  onSelect: (value: any) => void;
  queryKey?: (string | object)[];
};

export const TypeAheadAutoComplete = ({
  label,
  name,
  required,
  autoCompleteProps = {},
  inputLabelBaseProps,
  textFieldProps,
  asyncFunction,
  onSelect,
  queryKey = [name],
}: TypeAheadAutoCompleteProps) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();

  const { data: options = [] } = useQuery({
    queryKey: [...queryKey, searchTerm],
    queryFn: () => asyncFunction(searchTerm),
    keepPreviousData: true,
    onError: (error: AxiosError) => {
      enqueueSnackbar(manageAPIError(error), { variant: 'error' });
    },
  });

  return (
    <>
      <InputLabelBase
        htmlFor={name}
        required={required}
        {...inputLabelBaseProps}
      >
        {label}
      </InputLabelBase>

      <Autocomplete
        freeSolo
        options={options}
        getOptionLabel={(option: string | { label: string; value: string }) => {
          if (typeof option == 'object') {
            return option.label || '';
          }

          return option;
        }}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            placeholder="Type to search..."
            {...textFieldProps}
          />
        )}
        PopperComponent={(props) => (
          <Popper {...props} placement="bottom-start" />
        )}
        {...autoCompleteProps}
        onChange={(_e, value) => onSelect(value)}
        onInputChange={(_e, value) => setSearchTerm(value)}
      />
    </>
  );
};
