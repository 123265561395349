import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// helpers
import _ from 'lodash';
import Moment from 'moment';
import { Button, Grid as MuiGrid } from '@mui/material';
import { withStyles } from '@mui/styles';

// actions
import { PubSub } from '../../../utils/eventUtils';
// components
import PaperClip from '../../../_assets/svg/docs/PaperClip.svg';
import { useFileTypeIcons } from '../../../components/fileUploader/useFileTypeIcons';
import { withShowable } from '../../_global/lib/withShowable';
import { setUploadedDocs } from './notes.reducer';
import {
  FORMAT_DATE_AND_TIME,
  toUniversalUtcDate,
} from '../../../utils/date.utils';

const EButton = withShowable(Button);

const styles = ({ config }) => {
  return {
    listContainer: {
      fontWeight: config.weights.bold,
      fontSize: config.textSizes.petite,
      color: config.colors.cowbellBlue,

      '& .MuiButton-label': {
        fontStyle: 'italic',
        fontWeight: config.weights.bold,
        fontSize: config.textSizes.petite,
        textTransform: 'capitalize',
        lineHeight: 1.3,
      },
    },
    detailsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '0.1rem',
      fontStyle: 'italic',
    },
    greyText: {
      color: config.colors.greyWhite,
      alignSelf: 'center',
    },
  };
};

const iconStyle = { width: '1rem', marginRight: '0.166rem' };

export const NotesBodyHeader = withStyles(styles)(
  ({
    classes,
    data,
    readOnly,
    setEditing,
    setEdit,
    edit,
    setId,
    noteId,
    ...props
  }) => {
    const attachments = useSelector(({ notes }) => notes.docs.attachments);
    const renderFileIcon = useFileTypeIcons;
    const dispatch = useDispatch();
    const [uploadId, setUploadId] = useState('');

    const editNote = (value) => {
      dispatch(
        setUploadedDocs({ attachments: _.get(data, 'attachments', []) })
      );
      setId(value.id);
      setUploadId(value.id);
      setEdit(true);
      setEditing(true);
      const content = props.createEditorWithText(value.noteText);
      props.setEditor(content);
    };

    const updateNote = (id) => {
      const noteText = props.getEditorText();
      const payload = {
        noteText,
        attachments,
      };

      if (typeof props.onUpdate === 'function') {
        props.onUpdate(id, payload);
      }
      setEdit(false);
      setEditing(false);
    };

    const uploadDocumentHandler = React.useCallback((id, event) => {
      setUploadId(id);
      PubSub.publish('openFileUploadFeature', event);
    }, []);

    const itsEdit = edit && data.id === noteId;
    const length = _.get(data, 'attachments.length', 0);

    return (
      <div className={classes.listContainer}>
        <MuiGrid container className={classes.detailsContainer}>
          <MuiGrid item lg={5} style={{ textAlign: 'left' }}>
            <span className={classes.greyText}>
              Created by: {data.createdBy || '-'}
            </span>
          </MuiGrid>
          <MuiGrid item md={4} style={{ textAlign: 'left' }}>
            {data.quoteId ? (
              <span className={classes.greyText}>
                Quote Number: {data.quoteNumber} &nbsp;&nbsp;&nbsp;{' '}
                {toUniversalUtcDate(data.created, {
                  format: FORMAT_DATE_AND_TIME,
                })}
              </span>
            ) : (
              <span className={classes.greyText}>
                {toUniversalUtcDate(data.created, {
                  format: FORMAT_DATE_AND_TIME,
                })}
              </span>
            )}
          </MuiGrid>
          <MuiGrid
            item
            md={3}
            style={{
              textAlign: 'right',
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            {itsEdit && uploadId === data.id && (
              <>
                {attachments.length > 0 &&
                  attachments.map((docs, index) => {
                    return (
                      <section key={index}>
                        {renderFileIcon(docs.fileType, iconStyle)}
                      </section>
                    );
                  })}
              </>
            )}
            {itsEdit && attachments.length < 5 && (
              <PaperClip
                style={{ width: '1rem' }}
                onClick={() => uploadDocumentHandler(data.id)}
              />
            )}
            {!itsEdit &&
              length > 0 &&
              data.attachments.map((docs, index) => {
                return (
                  <section key={index}>
                    {renderFileIcon(docs.fileType, iconStyle)}
                  </section>
                );
              })}

            <EButton
              disableRipple
              style={
                itsEdit
                  ? { color: '#2180e2', padding: 0 }
                  : { color: '#1f1f29', padding: 0 }
              }
              onClick={() => (edit ? updateNote(data.id) : editNote(data))}
              show={!readOnly}
            >
              {itsEdit ? 'Done' : 'Edit'}
            </EButton>
          </MuiGrid>
        </MuiGrid>
      </div>
    );
  }
);
