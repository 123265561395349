/**
 * Warning! This file is not meant to be updated frequently!
 * Please make sure you actually need to modify THIS file before doing so!
 *
 * You must meet the following criteria before modifying this file...
 * 1. You are changing something that will affect every theme
 * 2. You think that this is silly, and putting css in js is very silly
 */

import _ from 'lodash';
import _base from './_base';
import _config from './_config';

export const defaultStyleConfig = {
  inputBaseStyles: {
    maxHeight: 40,
    padding: 11,
    position: 'relative',
    background: _config.colors.darkGreyActive,
    borderWidth: 1,
    borderRadius: 5,
    borderStyle: 'solid',
    fontFamily: _config.fonts.stackSans,
    fontSize: _config.textSizes.normal,
    lineHeight: 1,
  },
};

export default ({ themebase, ...themeConfigOverrides } = {}) => {
  const config = _.merge(
    {},
    defaultStyleConfig,
    _base,
    themebase,
    themeConfigOverrides
  );

  const {
    config: { textSizes, fonts },
  } = config;

  return {
    MuiPaper: {
      root: {
        backgroundImage: 'unset',
      },
    },

    MuiAutocomplete: {
      popup: {
        zIndex: 9999,
      },

      paper: {
        backgroundColor: themebase.palette.background.default,
        border: `1px solid ${themebase.palette.primary.border}`,
      },

      listbox: {
        maxHeight: '20vh',
      },

      input: {
        padding: '11px !important',
      },

      option: {
        '&[data-focus="true"]': {
          backgroundColor: themebase.palette.background.autocompleteOptionFocus,
        },
      },

      popupIndicator: {
        display: 'none',
      },

      clearIndicator: {
        position: 'absolute',
        right: 11,
        top: '50%',
        transform: 'translateY(-5%)',
      },

      inputRoot: {
        padding: '0 !important',
      },
    },

    MuiChip: {
      outlinedPrimary: {
        borderColor: themebase.palette.common.cowbellBlue,

        '&.boxy': {
          borderRadius: 4,
        },
      },
    },

    MuiDivider: {
      root: {
        backgroundColor: themebase.palette.primary.border,
      },
    },

    MuiButton: {
      root: {
        fontWeight: 400,
        borderRadius: config.shape.borderRadius,
        fontSize: textSizes.normal,

        color: themebase.palette.text.primary,

        '&.Mui-disabled': {
          opacity: 0.5,
        },

        '& > span': {
          fontSize: textSizes.normal,
          fontFamily: fonts.stackSans,
          fontWeight: 400,
        },
      },

      text: {
        background: 'none',

        '&:hover, &:focus': {
          background: 'none',
        },
      },

      label: {
        fontSize: textSizes.normal,
        fontFamily: fonts.stackSans,
        fontWeight: 400,
      },

      contained: {
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: _config.colors.lightGrey4,
      },
    },

    MuiIconButton: {
      root: {
        display: 'flex',
        // marginLeft: 'auto',
      },
    },
    MuiSelect: {
      icon: {
        right: 5,
      },

      select: {
        minHeight: '1.1875em',

        minWidth: 30,
        display: 'flex',
        alignItems: 'center',
        borderRadius: _base.shape.borderRadius,
        lineHeight: 1,
        background: 'inherit',
        borderWidth: 1,

        '&:focus': {
          backgroundColor: 'inherit',
          color: themebase.palette.background.white1,
          borderRadius: 5,
        },

        '&.MuiInputBase-input': {
          padding: '11.25px',
          paddingRight: '22px',
        },
      },
    },
    MuiList: {
      root: {
        maxHeight: '27.2rem',
      },
    },

    MuiMenuItem: {
      root: {
        fontSize: textSizes.normal,
        fontFamily: fonts.stackSans,
        display: 'flex',
        alignItems: 'center',
      },
    },

    MuiInputBase: {
      root: {
        display: 'flex',
        '&:before': {
          borderBottom: 'none !important',
        },
      },

      input: {
        ...config.inputBaseStyles,

        '$error &': {
          borderColor: themebase.palette.common.tomato,
        },

        '&:disabled': {
          background: themebase.palette.background.disabled,
        },

        '&::placeholder': {
          fontFamily: 'Roboto, Helvetica, sans-serif',
          fontSize: '1rem',
          fontStretch: 'normal',
          fontStyle: 'italic',
          lineHeight: 1.33,
          letterSpacing: 'normal',
          textAlign: 'left',
        },
      },

      multiline: {
        padding: 0,
      },
    },

    MuiSvgIcon: {
      root: {
        objectFit: 'contain',
        justifyContent: 'center',
        fontSize: _config.textSizes.primer,
        '&:hover': {
          color: 'grey',
          cursor: 'pointer',
        },
      },
      fontSizeLarge: {
        fontSize: '2.9166667rem',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 30,
      },
    },
    MuiFilledInput: {
      padding: 0,
    },
    MuiInput: {
      inputMultiline: {
        ...config.inputBaseStyles,
        maxHeight: 'unset',
        borderWidth: 0.8,
        borderRadius: 5,
        lineHeight: 1.5,
      },
      underline: {
        '&:after': {
          borderBottom: 'none',
        },
      },
    },
    MuiFormControl: {
      root: {
        padding: '1.5px',
      },
    },
    MuiFormControlLabel: {
      label: {
        '.normalized-control-label &': {
          fontSize: '1.33333333333rem',
        },
      },
      labelPlacementStart: {
        marginLeft: 0,
      },
    },

    MuiTableCell: {
      root: {
        padding: '0 1.25rem 0 0.833333333333333rem',
        fontFamily: fonts.stackSans,
      },
      head: {
        backgroundColor: config.tableHeaderBackground,
        color: 'inherit',
        fontWeight: 700,
        fontSize: textSizes.normal,
        lineHeight: '1.5rem',
      },
      body: {
        fontSize: textSizes.normal,
        borderBottom: `0.25rem solid ${themebase.palette.background.default}`,

        '& a': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
      stickyHeader: {
        backgroundColor: '',
        borderBottom: `.5rem solid ${themebase.palette.background.default}`,
      },
      footer: {
        borderBottom: 'initial',
        fontSize: textSizes.normal,
      },
    },

    MuiTablePagination: {
      root: {
        fontSize: textSizes.normal,
        fontFamily: fonts.stackSans,
      },
      actions: {
        display: 'flex',
        marginRight: 0,
      },
      select: {
        padding: 2,
        borderRadius: 2,
        top: 'auto !important',
        bottom: '100% !important',
      },
      selectIcon: {
        top: '50%',
        transform: 'translateY(-50%)',
      },
      toolbar: {
        height: 'inherit',
        minHeight: 38,
      },
    },

    MuiDialog: {
      root: {
        overflowY: 'visible',
      },
      paper: {
        overflowY: 'visible',
      },
      paperScrollPaper: {
        maxHeight: 'unset',
      },
    },

    MuiDialogContent: {
      root: {
        padding: '0 5rem 2.5rem 5rem',
        overflowY: 'visible',

        '&:first-child': {
          paddingTop: 0,
        },

        '& > *:first-child': {
          marginTop: 0,
        },
      },
    },

    MuiDialogTitle: {
      root: {
        padding: '0',
        margin: '20px 0px',
        // fontSize: '24px',
        // color: config.white,
        // fontWeight: 400,
      },
    },

    MuiDialogActions: {
      root: {
        display: 'flex',
        padding: '1.6666666667rem 0',
        marginLeft: '1.25rem',
        marginRight: '1.25rem',
        justifyContent: 'flex-end',
        borderTop: `1px solid ${themebase.palette.primary.border}`,

        '& button': {
          marginLeft: 10,
        },
      },
    },
    MuiListItem: {
      button: {
        background: 'inherit',

        '&:hover': {
          backgroundColor: config.darkGreyActive,
          color: themebase.palette.primary.contrastText,
        },

        '&:focus': {
          backgroundColor: config.darkGreyActive,
          color: themebase.palette.primary.contrastText,
        },
      },
    },
    MuiTab: {
      root: {
        minWidth: 'unset !important',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        border: `2px solid transparent`,
        flexDirection: 'row',
      },
      textColorInherit: {
        opacity: 1,
        fontWeight: 'bold',

        '& .Mui-selected': {
          backgroundColor: _config.colors.cowbellLight,
        },
      },
    },
    MuiTableRow: {
      root: {
        // borderBottom: `3px solid ${config.borderColor}`,
        height: '3.3333333333rem',
      },
      head: {
        height: '4rem',
        fontWeight: 700,
        borderBottom: `6px solid ${themebase.palette.background.default}`,
      },
    },
    MuiTableFooter: {
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        borderTop: `6px solid ${themebase.palette.background.default}`,
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: _config.colors.snackGreen,
        color: _config.colors.white,
        borderRadius: 5,
        padding: '6px',
        fontWeight: 400,
        justifyContent: 'center',
        fontFamily: _config.fonts.stackSans,
        fontSize: _config.textSizes.normal,
        marginLeft: '5px',
        marginTop: '2px',
      },
      action: {
        marginRight: '5px',
        color: _config.colors.white,
      },
      message: {
        padding: '8px',
      },
    },
    MuiSnackbar: {
      anchorOriginTopRight: {
        '@media (min-width: 600px)': {
          top: '272px',
        },
      },
    },
    MuiTypography: {
      h6: {
        fontFamily: _config.fonts.stackSans,
        fontSize: _config.textSizes.paragon,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.8,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: themebase.palette.text.text8,
      },
      body1: {},
      body2: {
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
      },
    },

    MuiFormHelperText: {
      root: {
        color: themebase.palette.error.errorText,
        marginTop: 10,
      },
    },

    MuiToggleButton: {
      root: {
        fontSize: '1rem',
      },
    },

    MuiInputLabel: {
      root: {
        color: themebase.palette.primary.contrastText,
        fontSize: '1rem',

        '&.gutterBottom': {
          marginBottom: '0.366667rem',
        },
      },
    },

    MuiCheckbox: {
      colorSecondary: {
        color: _config.colors.cowbellBlue,

        '&.Mui-checked': {
          color: _config.colors.cowbellBlue,
        },
      },
    },

    MuiRadio: {
      colorSecondary: {
        color: _config.colors.cowbellBlue,

        '&.Mui-checked': {
          color: _config.colors.cowbellBlue,
        },
      },
    },

    MuiSwitch: {
      colorPrimary: {
        '&.Mui-checked': {
          color: _config.colors.cowbellBlue,
        },
      },
    },

    MuiTooltip: {
      tooltip: {
        padding: 10,
        marginBottom: 5,
        border: `1px solid  ${themebase.palette.common.cowbellBlue}`,
        backgroundColor: themebase.palette.background.default,
        color: themebase.palette.text.primary,
        fontSize: '1rem',

        '&.tt-wide': {
          maxWidth: '46.66666667rem',
        },

        '.assist &': {
          padding: '3px 5px',
          backgroundColor: themebase.palette.background.default,
          border: 'none',
          color: themebase.palette.text.primary,
          fontSize: '1rem',
          position: 'relative',
        },
      },

      arrow: {
        color: _config.colors.cowbellBlue,
      },
    },
  };
};
