// mui
import { DialogContent as DialogContentBase, styled } from '@mui/material';
// utils
import { sendEmailText } from '../../../../bind-quote/utils/bindQuoteWorkFlowUtils';

const P100RenewalEmailConfirmation = ({ quote }) => {
  return (
    <DialogContent className="contrast-text">
      {sendEmailText(quote)}
    </DialogContent>
  );
};

const DialogContent = styled(DialogContentBase)({
  fontSize: '1.2rem',
  textAlign: 'left',
  padding: '0 2rem',
});

export default P100RenewalEmailConfirmation;
