// do not change the value of the below status consts, they map to backend actual statuses
export const ACTIVE_STATUS = 'ACTIVE';
export const READY_STATUS = 'READY';
export const INDICATION_STATUS = 'INDICATION';
export const SIGNED_STATUS = 'SIGNED';
export const EXPIRED_STATUS = 'EXPIRED';
export const BINDER_EXPIRED_STATUS = 'BINDER EXPIRED';
export const INVALID_STATUS = 'INVALID';
export const BOUND_STATUS = 'BOUND';
export const REQUESTED_STATUS = 'REQUESTED';
export const REFERRED_STATUS = 'REFERRED';
export const REVIEW_STATUS = 'IN_REVIEW';
export const LOST_STATUS = 'LOST';
export const DELETED_STATUS = 'DELETED';
export const DECLINED_STATUS = 'DECLINED';
export const VOID_STATUS = 'VOID';
export const IN_REVIEW_STATUS = 'IN_REVIEW';
export const DRAFT_STATUS = 'DRAFT';
export const IN_FORCE_STATUS = 'IN FORCE';
export const ISSUED_STATUS = 'ISSUED';
export const TEMPORARY_STATUS = 'TEMPORARY';
export const API_REJECTED = 'API_REJECTED';
export const BINDER_RESCINDED = 'RESCIND';

export const QuoteStatus = {
  ACTIVE_STATUS,
  READY_STATUS,
  INDICATION_STATUS,
  SIGNED_STATUS,
  EXPIRED_STATUS,
  BINDER_EXPIRED_STATUS,
  INVALID_STATUS,
  BOUND_STATUS,
  REQUESTED_STATUS,
  REFERRED_STATUS,
  REVIEW_STATUS,
  LOST_STATUS,
  DELETED_STATUS,
  DECLINED_STATUS,
  VOID_STATUS,
  IN_REVIEW_STATUS,
  DRAFT_STATUS,
  IN_FORCE_STATUS,
  ISSUED_STATUS,
  TEMPORARY_STATUS,
  API_REJECTED,
  BINDER_RESCINDED,
} as const;

export const POLICY_STATUS_OPTIONS = [
  { value: BOUND_STATUS, label: 'Bound' },
  { value: EXPIRED_STATUS, label: 'Expired' },
  { value: IN_FORCE_STATUS, label: 'In Force' },
  { value: ISSUED_STATUS, label: 'Issued' },
  { value: SIGNED_STATUS, label: 'Signed' },
  { value: ACTIVE_STATUS, label: 'Active' },
  { value: VOID_STATUS, label: 'Void' },
];
