import React from 'react';
import { useSelector } from 'react-redux';

import { determineIfAllowedScopes } from '../../utils/next/auth.utils';
import type { Permissions } from '../auth/permissions';
import type { RootStore } from '../../types';

interface Props {
  allow: Permissions[] | 'any';
  requireAll?: boolean;
  onDeny?: () => JSX.Element | null;
  show?: boolean;
}

export const withAllow = <P extends object>(
  Component: React.ComponentType<P>
) => {
  const Wrapper = ({
    allow,
    requireAll = false,
    onDeny,
    ...props
  }: P & Props) => {
    const scopes = useSelector((state: RootStore) => state.scopes);
    const isAllowed = determineIfAllowedScopes(scopes, allow, requireAll);

    // your custom action must return a component or null
    if (!isAllowed && typeof onDeny === 'function') {
      return onDeny();
    }

    if (!isAllowed) {
      return null;
    }

    return <Component {...(props as P)} />;
  };

  Wrapper.displayName = `withAllow${Component.displayName || Component.name}`;

  return Wrapper;
};
