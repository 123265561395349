import React from 'react';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

//mui
import type { AutocompleteChangeReason } from '@mui/material';
import {
  Box as MuiBox,
  DialogActions,
  DialogContent,
  styled,
  TextField,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';
import {
  CloudDownload as CloudDownloadBase,
  CloudUpload as CloudIconBase,
} from '@mui/icons-material';

//components
import type { AxiosRequestConfig } from 'axios';
import CbButton from '../../Buttons/CbButton';
import { DefaultFilePreview } from '../../fileUploader/v2/DefaultFilePreview';
import FullScreenDropZoneWrapper from '../../fileUploader/v2/FullScreenDropZoneWrapper';
import { Uploadzone } from '../../fileUploader/v2/UploadZone';

//hooks and hocs
import useUploadZone from '../../fileUploader/v2/hooks/useUploadzone';
import { withShowable } from '../../../console/_global/lib/withShowable';

//services
import { uploadDocumentV3 } from '../../../api/documents.api';

//contants
import { COWBELL_ACCOUNTS } from '../../tables/table_constants';

import type { AgencyDto } from '../../../types/agencies/dto/agency.dto';
import { uploadAccountDocument } from '../../../accounts/AccountService';
import { ProductApplicationResourceMapping } from '../../../console/agencies/quotes/PrimeX/utils/primeX.util';
import { getOptionLabel } from '../../inputs';

const Box = withShowable(MuiBox);

interface Props {
  data: any;
  close: () => void;
}

type AgencyOption = { label: string; value: string | undefined };

type InterstedAgencies = AgencyOption[] | [];

type SelectedAgency = {
  label: string;
  value: string;
} | null;

export const ApplicationUpload = ({ data: modalData = {}, close }: Props) => {
  const [selectedAgency, setSelectedAgency] =
    React.useState<SelectedAgency>(null);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { files, handleUploads, ...uploadZoneParms } = useUploadZone();

  const interestedAgencies = React.useMemo<InterstedAgencies>(() => {
    const agencyMapping = modalData.agencies?.map((agency: AgencyDto) => ({
      label: agency.agencyName,
      value: agency.agencyId,
    }));

    return [{ label: 'All Agencies', value: undefined }, ...agencyMapping];
  }, []);

  const handleDownload = () => {
    const resource =
      ProductApplicationResourceMapping[
        modalData.productType as keyof typeof ProductApplicationResourceMapping
      ];

    if (!resource) {
      enqueueSnackbar('Could not find application for this product type', {
        variant: 'error',
      });
    }

    const link = document.createElement('a');
    link.href = resource.href;
    link.download = resource.download;
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAgencyChange = (
    event: React.ChangeEvent<object>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    setSelectedAgency(value);
  };

  const handleAsyncDocUpload = async ({
    axiosConfig,
  }: {
    axiosConfig: AxiosRequestConfig;
  }) => {
    const formData = new FormData();
    formData.append('file', files![0]);

    const uploadDocParams = {
      accountId: modalData.id,
      agencyId: selectedAgency?.value ?? undefined,
      docType: 'Application',
      docName: (files![0] as { name: string }).name,
      temp: true,
    };

    return uploadDocumentV3({
      params: uploadDocParams,
      data: formData,
      ...axiosConfig,
    })
      .then(({ data: responseData }) => {
        const { docId, docName, docType, fileType } = responseData;
        const payload = {
          accountId: modalData.id,
          otherApp: { docId, docName, docType, fileType },
          productType: modalData.productType,
        };
        uploadAccountDocument({}, payload).then(() => {
          enqueueSnackbar('Successfully uploaded document.', {
            variant: 'success',
          });
          queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
          close();
        });
      })
      .catch(() => {
        enqueueSnackbar('Application upload failed. Please try again', {
          variant: 'error',
        });
      });
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    handleUploads({
      asyncUploadFunc: handleAsyncDocUpload,
      onFileUploadError: null,
      onFileUploadSuccess: null,
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <DialogContent className="contrast-text" style={{ padding: '2rem 5rem' }}>
        <Box style={{ fontSize: '1.2rem', textAlign: 'center' }}>
          If selected agency field is left blank, document will be <br />
          visible to all interested agencies.
        </Box>

        <Box style={{ padding: '0.5rem 8.5rem 1.5rem' }}>
          <label htmlFor="agencySelect">Select an Agency:</label>
          <Autocomplete
            options={interestedAgencies}
            getOptionLabel={getOptionLabel}
            onChange={handleAgencyChange}
            renderInput={(params) => (
              <TextField
                variant="standard"
                name="agencySelect"
                placeholder="Select an agency"
                {...params}
              />
            )}
          />
        </Box>

        <ButtonContainer files={files}>
          <Box>
            <Uploadzone
              {...uploadZoneParms}
              accept={acceptedUploadDocs}
              files={files}
              hasPreview={Boolean(files)}
              Preview={DefaultFilePreview}
              Placeholder={<FullScreenDropzonePlaceholder />}
            />
          </Box>

          <Box width="20rem" show={!files}>
            <CbButton
              styleName="ctaButton"
              size="large"
              endIcon={<CloudDownload />}
              onClick={handleDownload}
            >
              Download
            </CbButton>
            <SupportedText>Download Blank Cowbell Application</SupportedText>
          </Box>
        </ButtonContainer>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={close}>
          Cancel
        </CbButton>
        <CbButton
          type="submit"
          styleName="ctaButton"
          loading={uploadZoneParms.isUploading}
          disabled={uploadZoneParms.isUploading || !files}
        >
          Confirm
        </CbButton>
      </DialogActions>
    </form>
  );
};

export const ApplicationUploadConfig = {
  ApplicationUpload: {
    component: ApplicationUpload,
    config: {
      title: 'Upload Completed Application',
      maxWidth: 'md',
      minWidth: 'md',
    },
  },
};

export const FullScreenDropzonePlaceholder = () => {
  return (
    <Box style={{ width: '20rem', textAlign: 'center' }}>
      <FullScreenDropZoneWrapper
        // @ts-expect-error underlying component needs typed
        FileDropZone={React.Fragment}
      >
        <CbButton
          styleName="ctaButton"
          buttonText="Upload Document(s)"
          size="large"
          endIcon={<CloudUpload />}
        />
        <SupportedText>
          Supported File Types: PNG, JPG, JPEG, PDF, DOC
        </SupportedText>
      </FullScreenDropZoneWrapper>
    </Box>
  );
};

export const ButtonContainer = styled(Box)((props: any) => {
  if (props.files) {
    return {
      padding: '0rem 8.5rem',
      marginLeft: '-1rem',
    };
  }
  return {
    display: 'flex',
    justifyContent: 'center',
    gap: '1.5rem',
  };
});

export const CloudUpload = styled(CloudIconBase)({
  pointerEvents: 'none',
});

const CloudDownload = styled(CloudDownloadBase)({
  pointerEvents: 'none',
});

const SupportedText = withShowable(
  styled('p')({
    textAlign: 'center',
  })
);

const acceptedUploadDocs =
  'image/png, image/jpg, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
