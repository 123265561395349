/**
 * DISCLAIMER: Putting lots exportable components in a single file is a nightmare. Nicht gut
 */

import React from 'react';
import clsx from 'classnames';

import type { DefaultTheme } from '@mui/styles';
import { Box, Typography, Divider } from '@mui/material';
import { makeStyles, styled, useTheme } from '@mui/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useAgencyDashboardState } from '../../AgencyDashboardStateContext';
import {
  useAmplitude as useAnalytics,
  AMPLITUDE_EVENTS as ANALYTICS_EVENTS,
  AMPLITUDE_PROPERTIES as ANALYTICS_PROPERTIES,
} from '../../../../../providers/AmplitudeProvider';
import type { WithChildrenProps } from '../../../../../types/components/common';
import { AgencyDashboardListingPagination } from '../AgencyDashboardListingPagination';

interface ListingHeaderProps {
  headerConfig: HeaderCellProps[];
}

interface HeaderCellProps {
  name: string;
  label: string;
  sort: boolean;
  width: string;
  divider?: boolean;
  style?: React.CSSProperties;
}

export const ListingHeader = ({ headerConfig }: ListingHeaderProps) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        backgroundColor: theme.agencyDash.background.paper,
        padding: '0 1rem',
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      <Box
        display="flex"
        style={{
          backgroundColor: theme.agencyDash.background.contrast,
          padding: '0 calc(0.6rem + 1px)',
          borderRadius: 5,
        }}
      >
        {headerConfig.map(
          ({ divider = true, ...headerCellConfig }: HeaderCellProps) => (
            <>
              <HeaderCell key={headerCellConfig.name} {...headerCellConfig} />
              {divider ? <ColumnDivider /> : null}
            </>
          )
        )}
      </Box>
    </Box>
  );
};

export const HeaderCell = ({
  name,
  label,
  sort,
  width,
  style = {},
}: HeaderCellProps) => {
  const theme = useTheme();
  const { activeQuery } = useAgencyDashboardState();
  const isSelected = activeQuery.orderBy === name;

  return (
    <Box
      display="flex"
      alignItems="center"
      width={width}
      style={{
        color: theme.palette.primary.contrastText,
        padding: '0.5rem 0',
        marginRight: '0.8rem',
        ...style,
      }}
    >
      <Typography
        variant="body2"
        style={{ fontWeight: isSelected ? 'bold' : 'inherit' }}
      >
        {label}
      </Typography>
      {sort ? (
        <AgencyDashboardSortControls name={name} isSelected={isSelected} />
      ) : null}
    </Box>
  );
};

interface SortControlsProps {
  name: string;
  isSelected: boolean;
}

const AgencyDashboardSortControls = ({
  name,
  isSelected,
}: SortControlsProps) => {
  const analytics = useAnalytics();
  const { currentTab, activeQuery, onUpdateQuery } = useAgencyDashboardState();

  const isAscSelected = isSelected && activeQuery.order === 'asc';
  const isDescSelected = isSelected && activeQuery.order === 'desc';

  const onClick = (direction: 'asc' | 'desc') => {
    analytics.track(ANALYTICS_EVENTS.sortListing, {
      tab: currentTab.toLowerCase(),
      column: name,
      version: ANALYTICS_PROPERTIES.version.two,
    });
    onUpdateQuery(currentTab, { orderBy: name, order: direction, page: 0 });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{ marginLeft: '0.3rem' }}
    >
      <FontAwesomeIcon
        icon={isAscSelected ? solid('chevron-up') : regular('chevron-up')}
        size={isAscSelected ? '1x' : 'xs'}
        style={{ cursor: 'pointer' }}
        onClick={() => onClick('asc')}
        data-qa={`SORT_CONTROLS:${name},asc`}
      />
      <FontAwesomeIcon
        icon={isDescSelected ? solid('chevron-down') : regular('chevron-down')}
        size={isDescSelected ? '1x' : 'xs'}
        style={{ cursor: 'pointer' }}
        onClick={() => onClick('desc')}
        data-qa={`SORT_CONTROLS:${name},desc`}
      />
    </Box>
  );
};

interface AgencyDashboardBaseRowProps extends WithChildrenProps {
  isExpandable?: boolean;
  rowId: string;
}

export const AgencyDashboardBaseRow = ({
  children,
  isExpandable,
  rowId,
}: AgencyDashboardBaseRowProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const onClick = () => {
    if (isExpandable) {
      setIsExpanded((prev) => !prev);
    }
  };

  const classes = useBaseRowStyles({
    isExpanded,
    isExpandable,
  });

  return (
    <Box
      className={classes.container}
      onClick={onClick}
      data-qa={`TABLE_ROW:${rowId}`}
    >
      <Box display="flex" flexDirection="column">
        {children}
      </Box>
    </Box>
  );
};

const useBaseRowStyles = makeStyles<
  DefaultTheme,
  { isExpanded?: boolean; isExpandable?: boolean }
>(({ agencyDash, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: ({ isExpanded }) => (isExpanded ? '9rem' : '4.5rem'),
    margin: '0.4rem 1rem',
    border: `1px solid ${agencyDash.border.default}`,
    borderRadius: 5,
    overflow: 'hidden',
    boxShadow: '1px 1px 3px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: agencyDash.background.tableRow,

    '&:hover': {
      boxShadow: ({ isExpandable }: any) =>
        isExpandable
          ? '2px 2px 8px 0 rgba(0, 0, 0, 0.4)'
          : '1px 1px 3px 0 rgba(0, 0, 0, 0.2)',
      transform: ({ isExpandable }: any) =>
        isExpandable ? 'scaleY(1.01) scaleX(1.002)' : 'inherit',
      cursor: ({ isExpandable }: any) => (isExpandable ? 'pointer' : 'inherit'),
    },

    transition: 'height 150ms, transform 100ms, box-shadow 100ms',
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 0.6rem',
    height: '4.5rem',
    borderBottom: `1px solid ${agencyDash.background.divider}`,
  },
  rowDetail: {
    background:
      palette.mode === 'light'
        ? agencyDash.background.contrast
        : agencyDash.background.tableRow,
  },
}));

interface RowCellProps extends WithChildrenProps {
  width: string;
  style?: React.CSSProperties;
}

export const RowCell = ({ width, children, style = {} }: RowCellProps) => {
  return (
    <Box
      display="flex"
      width={width}
      style={{ marginRight: '0.8rem', overflow: 'hidden', ...style }}
    >
      {children}
    </Box>
  );
};

export const AgencyDashboardRowContent = ({ children }: WithChildrenProps) => {
  const classes = useBaseRowStyles({});
  return <Box className={classes.row}>{children}</Box>;
};

export const AgencyDashboardRowDetail = ({ children }: WithChildrenProps) => {
  const classes = useBaseRowStyles({});
  return <Box className={clsx(classes.row, classes.rowDetail)}>{children}</Box>;
};

type ListingContainerProps = {
  totalPages: number;
};

export const AgencyDashboardListingContainer = ({
  children,
  totalPages,
}: React.PropsWithChildren<ListingContainerProps>) => {
  return (
    <ListingContainer>
      <>{children}</>
      <AgencyDashboardListingPagination totalPages={totalPages} />
    </ListingContainer>
  );
};

const ListingContainer = styled(Box)({
  width: '100%',
  maxHeight: '100%',
  overflow: 'auto',
  paddingBottom: '21rem',
});

export const ColumnDivider = () => {
  const theme = useTheme();
  return (
    <Divider
      style={{
        backgroundColor: theme.agencyDash.background.divider,
        width: 1,
        position: 'relative',
        right: 5,
      }}
      flexItem
    />
  );
};
