// mui
import { Box, DialogTitle, IconButton as MuiIconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
// components
import { InsuranceLineSymbol } from '../../../../../../components/InsuranceLineSymbol';
// icons
import CowbellIcon from '../../../../../../_assets/svg/CBIcon.svg';

export const ModalTitle = ({ title, ...props }) => {
  const iconStyles = useIconStyles();

  return (
    <DialogTitle>
      <Box display="flex" alignItems="center">
        <CowbellIcon fontSize="large" className={iconStyles.icon} />
        {title}
      </Box>
      <div className={iconStyles.surplusIcon}>
        <InsuranceLineSymbol isSurplus={false} />
      </div>
      <MuiIconButton className={iconStyles.closeIcon} onClick={props.close}>
        <img src="images/Close-Modal.svg" alt="modal-close" />
      </MuiIconButton>
    </DialogTitle>
  );
};

export const useIconStyles = makeStyles(({ config, palette }) => ({
  icon: {
    width: '2rem',
    height: '2rem',
    marginRight: '1rem',
    float: 'left',
    fill: palette.icon.primary,
  },
  surplusIcon: {
    position: 'absolute',
    right: 0,
    top: '0.75rem',
    paddingRight: '5rem',
  },
  closeIcon: {
    padding: 0,
    width: '25px',
    right: 0,
    top: '0.75rem',
    margin: '0.3rem 2rem',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 100,
    color: config.colors.darkgreySoft,
    '& img': {
      maxWidth: '100%',
    },
  },
}));
