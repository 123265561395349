import _ from 'lodash';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@mui/styles';
import DescTooltip from '../DescTooltip';

const defaults = {
  cutoutPercentage: 85,
  legend: { display: false },
  tooltips: {
    enabled: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
    labels: {
      render: () => {
        return '';
      },
    },
  },
};

export const DoughnutChart = ({
  data,
  className,
  label = '',
  options = {},
  maxWidth = 200,
  padding = 0,
  fontSize = null,
}) => {
  const classes = useGraphStyles();
  const chartOptions = _.merge(defaults, options);

  const value = _.get(data, 'datasets[0].data[0]', '');

  return (
    <div
      className={`${classes.container} ${className}`}
      style={{ maxWidth, padding }}
    >
      <div className={classes.chartContainer}>
        <Doughnut data={data} options={chartOptions} />
        <span className={classes.value} style={{ fontSize }}>
          {value}
        </span>
      </div>
      {label && (
        <span className={`${classes.label}`} style={{ color: data.color }}>
          {label}
          {data.tooltip && <DescTooltip tooltip={data.tooltip} />}
        </span>
      )}
    </div>
  );
};

const useGraphStyles = makeStyles(({ palette, config }) => ({
  descContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    position: 'relative',
    textAlign: 'center',
  },

  chartContainer: {
    position: 'relative',
  },

  value: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color:
      palette.mode === 'dark' ? palette.common.white : palette.common.darkGrey,
    fontSize: config.textSizes.paragon,
    fontFamily: 'Oswald',
  },

  label: {
    display: 'block',
    margin: '1.25rem auto 0 auto',
    fontWeight: 'bold',
    width: '6.2rem',
  },
}));
