import React from 'react';
import _ from 'lodash';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@mui/styles';
import { TableStateContext } from '../TableStateContext';

const rowStyleNames = {
  DEFAULT: 'default',
  EXPANDABLE_ROW: 'expandableRow',
  P100: 'p100',
  P250: 'p250',
  PRIME_CLOUD: 'primecloud',
  NOT_SUPPORTED: 'notSupported',
  ENDORSEMENT: 'endorsement',
};

const useRowStyles = makeStyles(({ palette }) => ({
  default: {
    backgroundColor: palette.background.paper,
  },
  expandableRow: {
    backgroundColor: palette.background.paper,
    '&:hover': {
      backgroundColor: palette.background.modal,
      cursor: 'default',
    },
  },
  p100: {
    backgroundColor: palette.background.paper,
    '&:hover': { backgroundColor: palette.background.modal },
  },
  p250: {
    backgroundColor: palette.tables.productRow,
    '&:hover': { backgroundColor: palette.background.modal },
  },
  primecloud: {
    backgroundColor: palette.background.paper,
    '&:hover': { backgroundColor: palette.background.modal },
  },
  notSupported: {
    backgroundColor: palette.background.paper,
    '&:hover': { backgroundColor: palette.background.modal },
  },
  endorsement: {
    backgroundColor: palette.tables.endorsement,
    '&:hover': { backgroundColor: palette.background.modal },
  },
}));

const deriveProductStylingKey = (row, enableEndorsementLogic = true) => {
  if (enableEndorsementLogic && row.isEndorsement) {
    return rowStyleNames.ENDORSEMENT;
  }

  const productType = row.product ?? row.productType;
  switch (productType) {
    case 'P100':
      return rowStyleNames.P100;
    case 'P250':
      return rowStyleNames.P250;
    case 'PRIME_CLOUD':
      return rowStyleNames.PRIME_CLOUD;
    case 'NotSupported':
      return rowStyleNames.NOT_SUPPORTED;
    default:
      return rowStyleNames.DEFAULT;
  }
};

export const DefaultTableRow = ({ row, ...props }) => {
  const classes = useRowStyles();
  return (
    <VirtualTable.Row
      className={classes[rowStyleNames.DEFAULT]}
      data-qa={`TABLE_ROW:${props.tableRow?.rowId}`}
      {...props}
    />
  );
};

export const ExpandableRow = ({ ...props }) => {
  const { handlers } = React.useContext(TableStateContext);
  return (
    <ExpandableRowBase onClick={handlers.onExpandedRowIdsChange} {...props} />
  );
};

export const ExpandableRowBase = ({ row, onClick, ...props }) => {
  const classes = useRowStyles();
  const [mouseDown, setMouseDown] = React.useState(null);
  const [mouseUp, setMouseUp] = React.useState(null);

  const handleClick = (e) => {
    const classList = _.get(e, 'target.classList', '');
    if (
      typeof onClick == 'function' &&
      mouseUp - mouseDown < 500 &&
      ![...classList].includes('disableRowDetail')
    )
      onClick(row.id);
  };
  return (
    <VirtualTable.Row
      onMouseDown={() => setMouseDown(new Date())}
      onMouseUp={() => setMouseUp(new Date())}
      onClick={handleClick}
      className={classes[rowStyleNames.EXPANDABLE_ROW]}
      data-qa={`TABLE_ROW:${props.tableRow?.rowId}`}
      {...props}
    />
  );
};

export const AdminQuotesTableRow = ({ row, ...props }) => {
  const classes = useRowStyles();
  const styleKey = deriveProductStylingKey(row);

  return <ExpandableRow {...props} row={row} className={classes[styleKey]} />;
};

export const CowbellPoliciesTableRow = ({ row, ...props }) => {
  const classes = useRowStyles();
  const styleKey = deriveProductStylingKey(row, false);

  return (
    <ExpandableRowBase {...props} row={row} className={classes[styleKey]} />
  );
};

export const CowbellAccountsTableRow = ({ row, ...props }) => {
  const classes = useRowStyles();
  const styleKey = deriveProductStylingKey({}, false);

  return (
    <ExpandableRowBase {...props} row={row} className={classes[styleKey]} />
  );
};
