export const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
export const UPDATE_PAGE_SIZE = 'UPDATE_PAGE_SIZE';
export const UPDATE_SORT = 'UPDATE_SORT';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const UPDATE_COLUMN_VISIBILITY = 'UPDATE_COLUMN_VISIBILITY';
export const UPDATE_COLUMN_ORDER = 'UPDATE_COLUMN_ORDER';
export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const UPDATE_EXPANDED_ROWS = 'UPDATE_EXPANDED_ROWS';
export const UPDATE_COLUMN_WIDTHS = 'UPDATE_COLUMN_WIDTHS';

export const tableStateReducer = (state, action) => {
  const newState = { ...state };

  switch (action.type) {
    case UPDATE_PAGINATION:
      return {
        ...newState,
        page: action.payload,
      };

    case UPDATE_PAGE_SIZE:
      return {
        ...newState,
        ...action.payload,
      };

    case UPDATE_SORT:
      return {
        ...newState,
        ...action.payload,
      };

    case UPDATE_SEARCH:
      break;

    case UPDATE_COLUMN_VISIBILITY:
      return {
        ...newState,
        columns: {
          ...newState.columns,
          ...action.payload.columns,
        },
        hiddenColumnNames: action.payload.hiddenColumnNames,
      };

    case UPDATE_COLUMN_ORDER:
      return {
        ...newState,
        columnOrder: action.payload,
      };

    case UPDATE_SELECTION:
      return {
        ...newState,
        selection: action.payload,
      };

    case UPDATE_EXPANDED_ROWS:
      return {
        ...newState,
        expandedRowIds: action.payload,
      };

    case UPDATE_COLUMN_WIDTHS:
      return {
        ...newState,
        columnWidths: action.payload,
      };

    default:
      return newState;
  }
};
