import React from 'react';
import {
  DialogContent,
  DialogActions,
  Grid,
  DialogContentText,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import CbButton from '../../../components/Buttons/CbButton';
import { AutoCompleteBase } from '../../../components/inputs';
import { getFullAgencyHierarchyAsFlatList } from '../../AgencyService';
import { getTeamsForAgency } from '../../../teams/TeamsService';
import Showable from '../../../components/Showable';
import {
  bulkMigrateAgencyUsers,
  migrateAgencyUser,
} from '../../../api/users/users.api';
import { COWBELL_AGENCY_USERS } from '../agency.constants';
import { ArrayUtils } from '../../../utils/array.utils';
import { getAgencyLabel } from '../../../console/admin/users/UsersUtils';
import { useAPIErrorHandler } from '../../../components/hooks/useAPIErrorHandler';

export const SelectionStep = ({
  stepperProps,
  agencyId,
  selection,
  isAccountRealm,
  onClose,
}) => {
  const { handleSubmit, formState, ...formMethods } = useForm();
  const queryClient = useQueryClient();
  const handleAPIError = useAPIErrorHandler();

  const onSubmit = (formData) => {
    const params = {
      destinationAgencyId: formData.destinationAgencyId,
      destinationTeamId: formData.destinationTeam.value,
    };
    const triggerMigration = () => {
      return selection.length > 1
        ? bulkMigrateAgencyUsers({
            params,
            data: { userIds: ArrayUtils.mapEntitiesToIds(selection) },
          })
        : migrateAgencyUser(selection[0].id, { params });
    };

    triggerMigration()
      .then(() => {
        queryClient.invalidateQueries(COWBELL_AGENCY_USERS);
        stepperProps.goForward();
      })
      .catch((error) => {
        queryClient.invalidateQueries(COWBELL_AGENCY_USERS);
        onClose();
        handleAPIError(
          'One or more user migrations failed to initialize. Please check your selection and try again.'
        )(error);
      });
  };

  const [destinationAgencyId, destinationTeam] = formMethods.watch([
    'destinationAgencyId',
    'destinationTeam',
  ]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '3rem 5rem' }}>
          <DialogContentText>
            Please choose a destination agency{' '}
            {!isAccountRealm ? 'and team' : ''} for the selected user(s).
          </DialogContentText>
          <br />
          <Grid container spacing={2}>
            <Grid item md={12}>
              <AgencyAutocomplete
                agencyId={agencyId}
                formMethods={formMethods}
              />
            </Grid>
            <Grid item md={12}>
              <TeamAutocomplete
                formMethods={formMethods}
                isAccountRealm={isAccountRealm}
                destinationAgencyId={destinationAgencyId}
                destinationTeam={destinationTeam}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton onClick={stepperProps.goBack} styleName="cancel">
            Back
          </CbButton>
          <CbButton
            type="submit"
            styleName="ctaButton"
            loading={formState.isSubmitting}
            disabled={
              !destinationAgencyId || !destinationTeam || formState.isSubmitting
            }
          >
            Confirm Migration
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const AgencyAutocomplete = ({ agencyId, formMethods }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: agencyHierarchyOptions } = useQuery({
    queryKey: ['AGENCY_HIERARCHY_FLAT_LIST', agencyId],
    queryFn: () => getFullAgencyHierarchyAsFlatList({ params: { agencyId } }),
    select: (resp) => {
      return resp.data?.agencies
        .filter((agency) => agency.id !== agencyId)
        .map((agency) => ({
          label: getAgencyLabel(agency),
          value: agency.id,
        }));
    },
    onError: () =>
      enqueueSnackbar(
        'There was a problem loading Agency data. Please try again.',
        { variant: 'error' }
      ),
  });

  const onChange = (_, option) => {
    formMethods.setValue('destinationTeam', null);
    formMethods.setValue('destinationAgencyId', option.value);
  };

  return (
    <AutoCompleteBase
      label="Destination Agency"
      options={agencyHierarchyOptions ?? []}
      onChange={onChange}
    />
  );
};

const TeamAutocomplete = ({
  formMethods,
  isAccountRealm,
  destinationAgencyId,
  destinationTeam,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: teamOptions } = useQuery({
    queryKey: ['TEAMS', destinationAgencyId],
    queryFn: () =>
      getTeamsForAgency({ agencyId: destinationAgencyId, size: 100 }),
    select: (resp) => {
      return resp.data?.content.map((team) => ({
        label: team.name,
        value: team.id,
        data: team,
      }));
    },
    enabled: !!destinationAgencyId,
    onError: () =>
      enqueueSnackbar(
        'There was a problem loading Teams data. Please try again.',
        { variant: 'error' }
      ),
  });

  React.useEffect(() => {
    if (isAccountRealm && teamOptions) {
      formMethods.setValue('destinationTeam', {
        value: teamOptions.find((team) => team.data.isDefault)?.value,
      });
    }
    // eslint-disable-next-line
  }, [isAccountRealm, teamOptions]);

  const onChange = (_, option) => {
    formMethods.setValue('destinationTeam', option);
  };

  return (
    <Showable show={!isAccountRealm}>
      <AutoCompleteBase
        label="Destination Team"
        options={teamOptions ?? []}
        onChange={onChange}
        disabled={!destinationAgencyId}
        value={destinationTeam ?? { label: '', value: '' }}
      />
    </Showable>
  );
};
