import React from 'react';
import { DialogContent, DialogActions, DialogContentText } from '@mui/material';
import CbButton from '../../../../components/Buttons/CbButton';

const LossRunsDownloadFailure = (props) => (
  <>
    <DialogContent>
      <DialogContentText>
        There was a problem downloading your loss runs document. If you do not
        have an existing document, please generate and try again later. Please
        be patient as this process may take some time.
      </DialogContentText>
      <br />
      <DialogContentText>
        If you have already generated loss runs documents and are still unable
        to download, please contact a system administrator for assistance.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <CbButton styleName="ctaButton" onClick={props.close}>
        Got It
      </CbButton>
    </DialogActions>
  </>
);

export const LossRunsDownloadFailureConfig = {
  LossRunsDownloadFailure: {
    component: LossRunsDownloadFailure,
    config: {
      fullWidth: true,
      title: 'Download Error',
    },
  },
};
