import React, { useState, useCallback } from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import { useSnackbar } from 'notistack';

// mui
import {
  DialogContent,
  DialogActions,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// platform helpers
import { getStateObject } from '../../utils/componentUtils';
import { delayedEvent, PubSub } from '../../utils/eventUtils';
import { UsStatesFull } from '../../utils/USState';
// components
import { Button } from '../Buttons/Button';
import { AccountLookupField } from '../../accounts/admin-account-flow/AccountAutoComplete';
import withRouter from '../hocs/withRouter';
import { withFormController } from '../hocs/withFormController';
import CbButton from '../Buttons/CbButton';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { InputLabelBase } from '../inputs/InputLabelBase';
import { PhoneField as PhoneFieldBase } from '../inputs/PhoneField';
// actions
import { addNewAccount } from '../../api/apis';

const PhoneField = withFormController(PhoneFieldBase);
const TextField = withFormController(TextFieldBase);

// globalSchema
const schema = Yup.object().shape({
  state: Yup.string().label('State').ensure().required(),
  accountName: Yup.string().label('Name').required(),
  zip: Yup.string()
    .matches(/^\d{5}(-\d{4})?(?!-)$/, {
      message: 'Invalid ZIP Code',
      excludeEmptyString: true,
    })
    .test('Is ZIP 00000', 'Invalid ZIP Code', (value) => value != '00000')
    .label('Zip Code'),
  city: Yup.string()
    .matches(/^([a-zA-Z\s-]+)?$/, 'Invalid City')
    .label('City'),
  phone: Yup.number().fromPhone().phone().label('Business Phone'),
});

export const AddAccountMSSP = withRouter(({ history, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();

  const defaultValues = {
    state: '',
    accountName: '',
    phone: '',
    street: '',
    city: '',
    zip: '',
  };

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    validationSchema: schema,
  });
  const {
    control,
    getValues,
    setValue,
    register,
    watch,
    reset,
    formState: { isSubmitting, dirty },
  } = methods;

  const values = getValues();

  const [searching, setSearching] = useState(true);
  const [cbid, setCbId] = useState('');
  const [accountInputValue, setAccountInputValue] = React.useState('');

  const agencyDetails = useSelector(({ account }) => account.agency);

  // when initial load
  React.useEffect(() => {
    register({ name: 'accountName' }, { required: true });
    watch(['state', 'accountName', 'phone', 'street', 'city', 'zip']);
  }, [register, watch]);

  const renderOptions = (option) => {
    let nextOption = option;

    if (typeof option === 'string') {
      nextOption = { label: option, value: option };
    }

    return (
      <MenuItem key={nextOption.label} value={nextOption.value}>
        {nextOption.label}
      </MenuItem>
    );
  };

  const handleNameChange = useCallback(
    (selectedOption) => {
      if (selectedOption && typeof selectedOption === 'object') {
        setValue('accountName', selectedOption.value);
        setValue('street', selectedOption.meta.address1);
        setValue('city', selectedOption.meta.city);
        setValue('zip', selectedOption.meta.zipCode);
        setCbId(selectedOption.meta.cbid);
        setValue('state', selectedOption.meta.state);
        setValue('phone', selectedOption.meta.phoneNumber);
        setSearching(false);
        PubSub.publish('updateButtonText', {
          buttonId: 'AccountsCreateEdit-submit',
          buttonText: 'Add Account',
        });
      }
    },
    // eslint-disable-next-line
    []
  );

  const handleChange = (event) => {
    setValue(event.target.name, event.target.value);
  };

  const handleInputChange = (name) => {
    return (newInputValue) => {
      if (name === 'account') {
        if (typeof newInputValue === 'string') {
          setValue('accountName', newInputValue);
        } else {
          setValue('accountName', '');
        }
        return setAccountInputValue(newInputValue);
      }
    };
  };

  const resetHandler = () => {
    setSearching(true);
    reset();
    setValue('accountName', '');
    setAccountInputValue('');
    PubSub.publish('updateButtonText', {
      buttonId: 'AccountsCreateEdit-submit',
      buttonText: 'Search',
    });
  };

  const onSubmit = (formData) => {
    const payload = {
      state: formData.state,
      name: formData.accountName,
      cbid,
      phone: formData.phone,
      address1: formData.street,
      city: formData.city,
      zip: formData.zip,
    };

    const route = '/mssp/manage/accountlookup';

    if (searching) {
      close();
      history.push(route, {
        state: getStateObject(formData.state),
        accountName: formData.accountName,
        preload: true,
        city: formData.city,
        zip: formData.zip,
        phone: formData.phone,
        street: formData.street,
      });
    } else {
      return addNewAccount(payload)
        .then(() => {
          close();
          enqueueSnackbar('Account Created Successfully!', {
            variant: 'success',
          });
          delayedEvent('table-refetch', 1000, 'msspAccounts');
        })
        .catch((error) => {
          if (_.get(error, 'response.status', 409) === 409) {
            enqueueSnackbar(
              _.get(error, 'response.data', 'Cannot create account.'),
              { variant: 'error' }
            );
          } else {
            enqueueSnackbar(
              _.get(
                error,
                'response.data',
                _.get(
                  error,
                  'response.data.message',
                  'Server Error, please try again'
                )
              ),
              { variant: 'error' }
            );
          }
          resetHandler();
        });
    }
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Button
            onClick={resetHandler}
            className={`${classes.resetButton} ${
              isSubmitting || !dirty ? classes.resetDisabled : ''
            }`}
            disableRipple
          >
            CLEAR ALL
          </Button>
          <Grid style={{ paddingBottom: '1rem' }}>
            <TextField
              name="agency"
              label="Agency"
              defaultValue={agencyDetails.name}
              fullWidth
              disabled
              required
            />
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <InputLabelBase required className={classes.label}>
                State
              </InputLabelBase>
              <Select
                variant="standard"
                name="state"
                required
                onChange={handleChange}
                value={values.state}
                classes={{
                  select: classes.select,
                  icon: classes.selectIcon,
                  disabled: classes.selectDisabled,
                }}
                ref={register({ name: 'state' })}
                disabled={!searching}
                error={!values.state && _.get(methods.errors, 'state.message')}
              >
                {UsStatesFull.map(renderOptions)}
              </Select>
            </Grid>
            <Grid item md={6}>
              <AccountLookupField
                name="accountName"
                required
                onChange={handleNameChange}
                error={
                  !values.accountName &&
                  _.get(methods.errors, 'accountName.message')
                }
                state={values.state}
                disabled={!searching}
                onInputChange={handleInputChange('account')}
                inputValue={accountInputValue}
                value={values.accountName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <TextField
                name="street"
                placeholder="enter street"
                label="Street"
                control={control}
                fullWidth
                disabled={!searching}
              />
            </Grid>
            <Grid item md={6}>
              <PhoneField
                name="phone"
                label="Phone Number"
                control={control}
                placeholder="(___)___-____"
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <TextField
                name="city"
                placeholder="enter city"
                label="City"
                control={control}
                fullWidth
                disabled={!searching}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                name="zip"
                placeholder="enter zip code"
                label="Zip Code"
                control={control}
                fullWidth
                disabled={!searching}
              />
            </Grid>
          </Grid>
          <section
            className={`${classes.showWarning} ${
              searching && values.state && values.accountName
                ? ''
                : classes.hideWarning
            }`}
          >
            <section className={classes.warning1}>
              <img
                src="images/info-2.svg"
                className={classes.icon}
                alt="Info icon"
              />
              Can’t find the new account? Just hit search
            </section>
          </section>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" action={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Search"
            loading={isSubmitting}
            disabled={isSubmitting || !(values.state && values.accountName)}
            buttonId="AccountsCreateEdit-submit"
            data-qa="search"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
});

const styles = makeStyles(({ config, palette }) => {
  return {
    select: {
      justifyContent: 'left',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    selectDisabled: {
      background: palette.background.disabled,
    },
    label: {
      padding: 0,
      fontSize: config.textSizes.normal,
    },
    resetButton: {
      position: 'absolute',
      right: 0,
      '& .MuiButton-label': {
        fontSize: '1.167rem',
        fontWeight: 600,
      },
      lineHeight: 1.5,
      color: config.colors.cowbellBlue,
      cursor: 'pointer',
      paddingRight: '1rem',
    },
    resetDisabled: {
      color: palette.background.disabled,
      cursor: 'auto',
    },
    icon: {
      paddingLeft: 5,
      paddingRight: 5,
      width: '2rem',
    },
    warning1: {
      display: 'flex',
      justifyContent: 'center',
    },
    showWarning: {
      fontSize: '1.167rem',
      visibility: 'visible',
      lineHeight: 1.14,
      color: palette.primary.mainYellow,
      textAlign: 'center',
    },
    hideWarning: {
      visibility: 'hidden !important',
    },
  };
});

export default AddAccountMSSP;
