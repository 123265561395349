import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import { TableValidationNotification } from '../../tables-new/supporting/validation-notification.table';
import { useQueryParams } from '../../../providers/QueryParamsProvider';

export const useBackfill = (rawFallback, uiSettings = {}, blacklist = []) => {
  const { STATUS } = useBackfill;
  const [status, setStatus] = React.useState(STATUS.IDLE);

  const { query, replace } = useQueryParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const fallback = React.useMemo(
    () =>
      Object.keys(rawFallback).reduce(
        (acc, key) => ({
          ...acc,
          [key]:
            typeof rawFallback[key] === 'function'
              ? rawFallback[key]()
              : rawFallback[key],
        }),
        {}
      ),
    [rawFallback]
  );

  React.useEffect(() => {
    setStatus(STATUS.PENDING);

    // check if current query contains all required parameters
    const validUrl = Object.keys(fallback).every((key) =>
      Object.prototype.hasOwnProperty.call(query, key)
    );

    if (!validUrl) {
      const { nextQuery, platformError } = validateQueryParams(
        query,
        fallback,
        uiSettings,
        blacklist
      );

      if (platformError) {
        enqueueSnackbar('Platform update!', {
          content: (key, message) => (
            <TableValidationNotification id={key} message={message} />
          ),
          persist: true,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }

      replace(location.pathname, { query: nextQuery });
    }

    setStatus(STATUS.COMPLETE);
    // eslint-disable-next-line
  }, [query, fallback]);

  return { status };
};

// defines static property on the function's (hook's) obj
useBackfill.STATUS = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
};

const validateQueryParams = (query, fallback, uiSettings, blacklist) => {
  const { columns = {}, columnWidths, columnOrder, ...rest } = uiSettings;
  const allSavedQueryParams = _.omit(rest, blacklist);

  const cleanedQuery = cleanQuery(query, columns);

  // if no saved settings, return defaults
  if (_.isEmpty(allSavedQueryParams)) {
    return { nextQuery: { ...fallback, ...cleanedQuery } };
  }

  const { order, orderBy, ...savedParamsRest } = allSavedQueryParams;
  const validSortingParams =
    Object.prototype.hasOwnProperty.call(columns, orderBy) &&
    ['asc', 'desc'].includes(order);

  // if saved sorting params are valid, merge defaults with saved values
  if (validSortingParams) {
    return {
      nextQuery: { ...fallback, ...allSavedQueryParams, ...cleanedQuery },
    };
  }

  // if saved sort settings invalid, merge only valid saved params and trigger platform error message
  return {
    nextQuery: { ...fallback, ...savedParamsRest, ...cleanedQuery },
    platformError: true,
  };
};

const cleanQuery = (query, columns) => {
  const { order, orderBy, ...queryRest } = query;

  if (!order || !orderBy) return queryRest;

  const validOrder = ['asc', 'desc'].includes(order);
  const validOrderBy = Object.prototype.hasOwnProperty.call(columns, orderBy);

  if (validOrder && validOrderBy) {
    return query;
  }

  return queryRest;
};
