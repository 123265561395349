import React from 'react';
import { useSnackbar } from 'notistack';

// mui
import { Box, styled, Typography } from '@mui/material';

// components
import { useQueryClient } from '@tanstack/react-query';
import CbButtonBase from '../../../../components/Buttons/CbButton';
import CheckBoxBase from '../../../../components/inputs/Checkbox';

// constants
import { BOUND_STATUS, SIGNED_STATUS } from '../../../_statics/quote.statics';

// hocs
import { withShowable } from '../../../_global/lib/withShowable';
import { bindQuote } from '../../../../api/bind-quote.api';
import { QuoteAgencyStatus } from '../../../../types/common/misc';
import { manageAPIError } from '../../../../utils';
import SimpleTooltip from '../../../../components/SimpleTooltip';
import { sleep } from '../../../../utils/appUtils';
import { PaperSnackbar } from '../../../../components/snackbars';
import { ProductTypes } from '../../../../types';
import { usePersona } from '../../../../components/hooks/usePersona';
import { useCowbellTranslations } from '../../../../i18n/translations';
import { attestationQuoteCheckBoxLabel } from '../constants/constants';

const CbButton = withShowable(CbButtonBase);

const BindQuote = ({
  goForward,
  goBack,
  quoteDetails,
  incompletePreBindSteps,
  quoteProgress,
  completeStep,
  step,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const persona = usePersona();

  const { t, translationKeys } = useCowbellTranslations();

  const isQuoteBound =
    quoteProgress.quoteAgencyStatus === BOUND_STATUS ||
    quoteProgress.quoteAgencyStatus === SIGNED_STATUS;

  const [isBindingQuote, setIsBindingQuote] = React.useState(false);
  const [bindQuoteAcknowledgement, setBindQuoteAcknowledgement] =
    React.useState(isQuoteBound);

  const [isPollingQuoteProgress, setIsPollingQuoteProgress] =
    React.useState(false);

  const { productType, quoteId } = quoteDetails.uiData;

  React.useEffect(() => {
    if (incompletePreBindSteps.length) {
      enqueueSnackbar(
        'You must complete all pre-bind subjectivities before quote can be bound.',
        { variant: 'error' }
      );
      goBack();
      return null;
    }
  }, [enqueueSnackbar, goBack, incompletePreBindSteps]);

  const isIndicationStatus =
    quoteProgress.quoteAgencyStatus === QuoteAgencyStatus.INDICATION;

  React.useEffect(() => {
    if (!isIndicationStatus) {
      return;
    }
    // edge case that user is moving quickly through stepper and quote is in indication. Quote may not update to ready immediatley
    const pollQuoteStatus = async () => {
      setIsPollingQuoteProgress(true);
      // eslint-disable-next-line
      for (let timesPolled = 0; timesPolled <= 5; timesPolled++) {
        await queryClient.refetchQueries({
          queryKey: ['quote-progress', quoteId],
        });

        const { quoteProgress: quoteProgressData } =
          await queryClient.getQueryData({
            queryKey: ['quote-progress', quoteId],
            exact: false,
          });

        if (quoteProgressData.quoteAgencyStatus === QuoteAgencyStatus.READY) {
          return setIsPollingQuoteProgress(false);
        }
        await sleep(500);
      }
      setIsPollingQuoteProgress(false);
    };

    pollQuoteStatus();
    // eslint-disable-next-line
  }, []);

  const bindQuoteButtonText =
    productType === ProductTypes.p100 ? 'Request to bind' : 'Bind Quote';

  const { uiData = {} } = quoteDetails;

  const quoteDetailsData = [
    { label: 'Limit', value: uiData.limit },
    {
      label:
        productType === ProductTypes.p100
          ? 'Deductible'
          : t(translationKeys.retention),
      value: uiData.deductible,
    },
    { label: 'Premium', value: uiData.premium },
    { label: 'Effective Date', value: uiData.bindQuoteEffectiveDate },
  ];

  const firmographicDetails = [
    { label: 'Revenue', value: uiData.revenue },
    { label: 'Number of Employees', value: uiData.numOfEmployees },
    {
      label: 'Industry Code',
      value: `${uiData.naics} - ${uiData.primaryIndustry}`,
    },
    { label: 'Ownership Type', value: uiData.ownershipType },
  ];

  const handleCheckboxChange = () => {
    setBindQuoteAcknowledgement((prevState) => !prevState);
  };

  const pollQuoteProgress = async () => {
    // eslint-disable-next-line
    for (let timesPolled = 0; timesPolled <= 5; timesPolled++) {
      await queryClient.refetchQueries({
        queryKey: ['quote-progress', quoteId],
      });

      const { quoteProgress: quoteProgressData } =
        await queryClient.getQueryData({
          queryKey: ['quote-progress', quoteId],
          exact: false,
        });

      if (quoteProgressData.quoteAgencyStatus === QuoteAgencyStatus.BOUND) {
        return { status: pollAPIStatuses.FINISHED }; // Finshed status will allow user to move forward
      }
      await sleep(500);
    }
    return { status: pollAPIStatuses.ERROR };
  };

  const handleBindQuote = () => {
    setIsBindingQuote(true);
    bindQuote(persona.type, productType, quoteId)
      .then(pollQuoteProgress)
      .then(({ status }) => {
        if (status === pollAPIStatuses.FINISHED) {
          enqueueSnackbar('Quote bind was successful', {
            variant: 'success',
          });
          completeStep(step);

          setTimeout(() => {
            goForward();
          }, 500);
        }
        if (status === pollAPIStatuses.ERROR) {
          enqueueSnackbar(
            'Something went wrong. A policy will not be able to be issued at this time',
            { variant: 'error' }
          );
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 412 &&
          Array.isArray(error?.response?.data?.errors)
        ) {
          enqueueSnackbar('Unable to bind quote', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            persist: true,
            content: (key, message) => {
              return (
                <PaperSnackbar key={key} message={message}>
                  <strong>
                    The quote could not be bound for the following reason(s):
                  </strong>
                  <ul>
                    {error.response.data.errors.map((error, index) => (
                      <li key={index}>{error.detail}</li>
                    ))}
                  </ul>
                </PaperSnackbar>
              );
            },
          });
        } else {
          enqueueSnackbar(
            manageAPIError(error, 'Something went wrong while binding quote'),
            { variant: 'error' }
          );
        }
      })
      .finally(() => {
        setIsBindingQuote(false);
      });
  };

  return (
    <Box className="contrast-text" width="65%">
      <Typography
        variant="h4"
        style={{ fontWeight: 600, fontSize: '1.5rem', paddingBottom: '1.5rem' }}
      >
        Attest and Request to Bind Quote
      </Typography>

      <Typography variant="h4" style={{ fontSize: '1.5rem' }}>
        Are you sure you want to bind <strong>{uiData.quoteNumber}</strong> for{' '}
        <strong>{uiData.companyName}</strong>?
      </Typography>

      <Box style={{ display: 'flex', gap: '5rem' }}>
        <QuoteDetailsContainer>
          {quoteDetailsData.map(({ label, value }) => (
            <Box display="flex" key={label}>
              <Box width="11rem">{label}:</Box>
              <Box style={{ fontWeight: 500 }}>{value}</Box>
            </Box>
          ))}
        </QuoteDetailsContainer>

        <QuoteDetailsContainer>
          {firmographicDetails.map(({ label, value }) => (
            <Box display="flex" alignItems="center" key={label}>
              <Box width="11rem">{label}:</Box>
              <Box style={{ fontWeight: 500 }}>{value}</Box>
            </Box>
          ))}
        </QuoteDetailsContainer>
      </Box>

      <p style={{ width: '60%' }}>
        <i>
          NOTE: If there are any material changes that need to be made to the
          account details after binding, the binder will be considered invalid
          and a new quote will need to be created. Please review the above
          details.
        </i>
      </p>

      <CheckBoxBase
        name="bindQuoteAcknowledgment"
        label={attestationQuoteCheckBoxLabel(
          t(translationKeys.checking)?.toLowerCase()
        )}
        onChange={handleCheckboxChange}
        checked={bindQuoteAcknowledgement}
        disabled={isQuoteBound}
      />

      <Box paddingTop="1rem">
        <CbButton styleName="cancel" size="medium" onClick={goBack}>
          Back
        </CbButton>
        <RequestToBindWrapper isIndicationStatus={isIndicationStatus}>
          <CbButton
            styleName="ctaButton"
            size="medium"
            disabled={
              !bindQuoteAcknowledgement ||
              isBindingQuote ||
              isPollingQuoteProgress
            }
            loading={isBindingQuote || isPollingQuoteProgress}
            onClick={handleBindQuote}
            show={!isQuoteBound}
          >
            {bindQuoteButtonText}
          </CbButton>
        </RequestToBindWrapper>
        <CbButton
          show={isQuoteBound}
          styleName="ctaButton"
          size="medium"
          onClick={goForward}
        >
          Next
        </CbButton>
      </Box>
    </Box>
  );
};

export default BindQuote;

const RequestToBindWrapper = ({ isIndicationStatus, children }) => {
  if (isIndicationStatus) {
    const tooltipTitle = (
      <div style={{ textAlign: 'center' }}>
        This quote cannot be bound at this time. Please try again later.
      </div>
    );

    return (
      <SimpleTooltip title={tooltipTitle} style={{ display: 'inline' }}>
        {children}
      </SimpleTooltip>
    );
  }
  return children;
};

const QuoteDetailsContainer = styled(Box)({
  fontSize: '1.2rem',
  fontWeight: 600,
  padding: '1rem 0',
});

const pollAPIStatuses = {
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
};
