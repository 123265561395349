import React from 'react';
import Axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useQueryParams } from '../../../providers/QueryParamsProvider';
import { retrieveAuthTokens } from '../../../../utils/next/auth.utils';
import { getAxiosBaseURL } from '../../../../config/axios/axiosApiConfig';

export const useFilterState = (
  tableId,
  { fetchFilterData, deleteFilter, onNoFilters } = {}
) => {
  const { query, replace } = useQueryParams();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { accessToken: token } = retrieveAuthTokens();

  const { data: allFilters } = useQuery(
    [`${tableId}-tableFilters`],
    () => getFilters(),
    {
      keepPreviousData: true,
      staleTime: 300000,
      refetchOnWindowFocus: false,
    }
  );

  const tableFilterOptions = React.useMemo(
    () => (Array.isArray(allFilters) ? allFilters.filter((f) => !f.chip) : []),
    [allFilters]
  );
  const systemFilters = React.useMemo(
    () => (Array.isArray(allFilters) ? allFilters.filter((f) => f.system) : []),
    [allFilters]
  );
  const chips = React.useMemo(
    () => (Array.isArray(allFilters) ? allFilters.filter((f) => f.chip) : []),
    [allFilters]
  );

  const getFilters = React.useCallback(() => {
    if (typeof fetchFilterData === 'function') return fetchFilterData();

    return Axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
      url: `/api/user/v1/filter/name/${tableId}`,
      baseURL: getAxiosBaseURL(),
    })
      .then(({ data }) => {
        if (data.content) {
          return data.content.map((f) => ({
            label: f.name,
            value: f.id,
            filter: f.filter.replace(/filter/g, 'f'),
            system: f.system,
            chip: f.chip,
          }));
        }
      })
      .catch((err) => {
        enqueueSnackbar(
          'Sorry there was a problem fetching your filters, please try again in a few minutes.',
          {
            variant: 'error',
          }
        );

        throw err;
      });
  }, [enqueueSnackbar, fetchFilterData, tableId, token]);

  const handleDeleteFilter = React.useCallback(
    (option) => {
      if (typeof deleteFilter === 'function') return deleteFilter(option.value);

      return (event) => {
        event.preventDefault();
        event.stopPropagation();

        Axios({
          method: 'DELETE',
          headers: { Authorization: `Bearer ${token}` },
          url: `/api/user/v1/filter/${option.value}`,
        })
          .then(() => {
            enqueueSnackbar('Filter removed', { variant: 'success' });
            queryClient.invalidateQueries([`${tableId}-tableFilters`]);
          })
          .catch(() => {
            enqueueSnackbar('Failed to remove filter', { variant: 'error' });
          });
      };
    },
    // eslint-disable-next-line
    [allFilters]
  );

  const handleNoFilters = React.useCallback(() => {
    const { f, fn, page, searchAfter, pageRequested, ...rest } = query;
    if (typeof onNoFilters === 'function') return onNoFilters();
    replace(`${pathname}`, { query: rest });
    // eslint-disable-next-line
  }, [query]);

  const filterProps = React.useMemo(
    () => ({
      id: tableId,
      allFilters,
      tableFilterOptions,
      systemFilters,
      chips,
      getFilters,
      handleDeleteFilter,
      handleNoFilters,
    }),
    // eslint-disable-next-line
    [allFilters, query]
  );

  return filterProps;
};
