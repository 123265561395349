import React from 'react';
import _ from 'lodash';

// components
import { AdditionalInsured } from './AdditionalInsured';
import { PaymentSummary } from './PaymentSummary';
import { EndorsementHeader } from './EndorsementHeader';
import { DrawerCoverageSummary } from './DrawerCoverageSummary';

// utils
import {
  getCoverages,
  getConsolidatesKeys,
  generateAIText,
  getAddressInfo,
} from './endorsement.utils';
import { ProductTypes } from '../../../types';
import InsuredInformationBase from './InsuredInformation';
import { withShowable } from '../lib/withShowable';

const InsuredInformation = withShowable(InsuredInformationBase);

export const P100PolicyMidTermEndorsementDrawer = ({ policy: data }) => {
  const {
    effectiveDate,
    additionalInsureds,
    paymentData,
    policyNumber,
    endorsementCoverages,
    address,
    companyName,
    isAIApplicable,
    coverageConsolidatedKeys,
  } = React.useMemo(() => {
    return {
      additionalInsureds: _.get(data, 'additionalInsureds', []),
      effectiveDate: _.get(data, 'effectiveDate'),
      paymentData: {
        ..._.pick(data, [
          'totalPremium',
          'coveragesAdded',
          'coveragesRemoved',
          'coveragesUpdated',
        ]),
      },
      address: getAddressInfo(data),
      companyName: _.get(data, 'companyName'),
      policyNumber: _.get(data, 'policyNumber'),
      endorsementCoverages: getCoverages(data, ProductTypes.p100),
      isAIApplicable: generateAIText(data),
      coverageConsolidatedKeys: getConsolidatesKeys(data).filter(
        (key) => key !== 'additionalInsureds' && key !== 'insuredInfo'
      ),
    };
  }, [data]);

  const hasInsuredInfo = !!data?.coveragesUpdated?.includes('insuredInfo');

  return (
    <>
      <EndorsementHeader
        effectiveDate={effectiveDate}
        number={policyNumber}
        label="Policy#"
        endDate={data.endDate}
      />
      <InsuredInformation
        show={hasInsuredInfo}
        insuredInfo={{ ...address, companyName }}
      />
      <DrawerCoverageSummary
        current={endorsementCoverages}
        existingKeys={coverageConsolidatedKeys}
      />
      <AdditionalInsured
        additionalInsureds={additionalInsureds}
        isAIApplicable={isAIApplicable}
      />
      <PaymentSummary
        data={{
          hasInsuredInfo,
          coverageConsolidatedKeys,
          isAIApplicable,
          ...paymentData,
        }}
      />
    </>
  );
};
