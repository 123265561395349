import _ from 'lodash';
import { handleActions } from 'redux-actions';
import Moment from 'moment';
// actions
// account actions
export const SET_PAGINATION = 'SET_PAGINATION';

// action creators
export const setPagination = (payload) => ({
  type: SET_PAGINATION,
  payload,
});

export const initialState = {
  page: 0,
  totalRows: 0,
  rowsPerPage: 25,
  data: [],
  selected: [],
  search: null,
  start: Moment().subtract(1, 'year').toDate(),
  end: Moment().toDate(),
};

export const archiveQuotesReducer = handleActions(
  {
    [SET_PAGINATION]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  initialState
);
