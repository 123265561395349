/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import '../console/_global/lib/validations/CurrencySchemaType';
import moment from 'moment';
import type { Languages } from '../i18n/i18n.language-config';

const ukPhoneRegex = /^\+[0-9]{2}\s\d{4}\s\d{6}$/;
const ukPostalCodeRegExp =
  /^([A-PR-UWYZ][0-9][A-HJKPSTUW]? [0-9][ABD-HJLNP-UW-Z]{2}|[A-PR-UWYZ][A-HK-Y0-9][0-9][ABEHMNPRVWXY0-9]? [0-9][ABD-HJLNP-UW-Z]{2}|GIR 0AA)$/i;

const stripSpecialChars = (value: string) => {
  //remove speacial chars and spaces
  return value.match(/[0-9]+/g)?.join('') ?? '';
};

const phoneValidation = (length: number, label: string) =>
  Yup.string()
    .transform((value, originalValue) => stripSpecialChars(originalValue))
    .test(
      `${label} length`,
      `${label} is invalid`,
      (value) => value?.length === length
    )
    .label(label)
    .required();

export const globalSchema = {
  city: Yup.string()
    .label('City')
    .matches(/^([a-zA-Z\s-]+)?$/, 'Invalid City')
    .required(),
  zipCode: Yup.string()
    .matches(/^\d{5}(-\d{4})?(?!-)$/, 'Invalid ZIP Code')
    .test('Is ZIP 00000', 'Invalid ZIP Code', (value) => value != '00000')
    .label('Zip Code')
    .trim()
    .required(),
  usPhone: phoneValidation(10, 'US phone number'),
  ukPhone: phoneValidation(11, 'UK phone number'),
  postalCode: Yup.string()
    .matches(ukPostalCodeRegExp, 'Invalid UK postal code')
    .label('Postal Code')
    .trim()
    .required(),
  team: Yup.object({
    label: Yup.string().required('Please select a Team'),
    value: Yup.string().required(),
  })
    .required()
    .typeError('Please select a valid Team, current option is not valid.'),
  agency: Yup.object({
    label: Yup.string().required('Please select a Agency'),
    value: Yup.string().required(),
  })
    .required()
    .typeError('Please select a valid Agency, current option not found.'),
  currency: Yup.string()
    .min(0)
    .required('Payment Amount is required')
    .typeError('Payment Amount is considered invalid'),
  noOfEmployees: Yup.number()
    .required()
    .label('Number of Employees')
    .min(1)
    .round('round')
    .typeError('${label} is a required whole number field'),
  revenue: Yup.number()
    .label('Projected Revenue')
    .fromCurrency()
    .min(1)
    .max(
      1000000000000,
      '${label} must be less than or equal to 1 Trillion Dollars.'
    )
    .typeError('${label} is a required field')
    .required(),
  yearEstablished: Yup.string()
    .matches(/^\d+$/, {
      message: 'Year Established must be a valid year',
      excludeEmptyString: true,
    })
    .test({
      name: 'yearEstablished',
      message: `Year Established must be between 1500 and ${moment().year()}`,
      test: (value: string) => {
        if (
          !value ||
          (Number(value) >= 1500 && Number(value) <= moment().year())
        ) {
          return true;
        }

        return false;
      },
    })
    .label('Year Established'),
  alphabetOnly: (label = 'Field') =>
    Yup.string()
      .matches(/^[A-Za-z]+$/, {
        message: `${label} may only contain alphabetical characters`,
        excludeEmptyString: true,
      })
      .label(label),
  trim: (label = 'Field') =>
    Yup.string()
      .matches(/^\S*\S$/, {
        message: `${label} may not begin or end with whitespace`,
        excludeEmptyString: true,
      })
      .label(label),
} as const;

// normalized validations for SalesInbox Leads
export const leadSchema = {
  percentToWin: Yup.number()
    .label('Win Rate')
    .min(0)
    .max(100)
    .typeError('${label} must be a number')
    .required(),
} as const;

export const stateValidation = Yup.string().transform(
  function transformNaicsCode(value, original) {
    if (Array.isArray(original) && original.length) {
      return original[0].value;
    }

    if (typeof original === 'object') {
      return original.value;
    }

    return original;
  }
);

export const naicsCodeValidation = Yup.string().transform(
  function transformNaicsCode(value, original) {
    if (typeof original === 'object') {
      return original.value;
    }

    return original;
  }
);

export const naicsDescriptionValidation = Yup.string().transform(
  function transformNaicsCode(value, original) {
    if (typeof original === 'object') {
      return original.meta.secondaryIndustry;
    }

    return original;
  }
);

export const internationalValidations = {
  city: (language: Languages) => {
    const label = language === 'en-US' ? 'City' : 'City/Town';
    return Yup.string()
      .matches(/^([a-zA-Z\s-]+)?$/, `Invalid ${label}`)
      .trim()
      .label(label);
  },
  phone: (language: Languages, label?: string) => {
    switch (language) {
      case 'en-GB':
        return Yup.string()
          .matches(
            ukPhoneRegex,
            'Phone number must be in the format 44 5555 555555'
          )
          .label(label ?? 'Phone');
      default:
        return Yup.number()
          .fromPhone()
          .phone()
          .label(label ?? 'Phone') as Yup.StringSchema<string>;
    }
  },
} as const;

export const nullableNumericOnlyField = (label = 'Field') => {
  return Yup.string().matches(/^\d+$/, {
    message: `${label} must be a numeric value`,
    excludeEmptyString: true,
  });
};
