import { useSteps } from '../../../hooks/useSteps';
import Showable from '../../../Showable';
import { ConnectorActions } from './ConnectorActions';
import { Connector } from './Connector';
import { InitializedFormProvider } from '../../../forms/InitializedFormProvider';

export const ConnectorSteps = ({
  schema,
  handleClose,
  cbid,
  connectorType,
}) => {
  const stepNames = Object.keys(schema.steps);

  const stepperProps = useSteps({
    startStep: stepNames[0],
    initialSteps: stepNames,
  });

  return Object.keys(schema.steps).map((step) => {
    const stepSchema = schema.steps[step];
    return (
      <Showable show={stepperProps.step === step}>
        <InitializedFormProvider>
          <Connector
            handleClose={handleClose}
            stepperProps={stepperProps}
            schema={stepSchema}
            cbid={cbid}
            connectorType={connectorType}
          />
          <ConnectorActions
            handleClose={handleClose}
            stepperProps={stepperProps}
          />
        </InitializedFormProvider>
      </Showable>
    );
  });
};
