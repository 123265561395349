import { prettyId } from '../../../utils/data.utils';

export function reduceForSelect(array, getSelectItem = getSelectItemDefault) {
  if (!Array.isArray(array) || !array.length) {
    return [];
  }

  return array.reduce((accum, value) => {
    const selectItem = getSelectItem(value);

    if (selectItem == null) {
      return accum;
    }

    return [...accum, selectItem];
  }, []);
}

function getSelectItemDefault(listItem) {
  const { id, name } = listItem;
  if (!listItem || !id || !name) {
    return undefined;
  }

  return { label: name, value: id, meta: { ...listItem } };
}

export function getSelectItemAgency(agency) {
  const label = getAgencyLabel(agency);

  return { label, value: agency.id, meta: { ...agency } };
}

export function reduceForAccountData(array) {
  if (array && array.length) {
    return array.reduce((accum, value) => {
      const { accountId, accountName, roleId, accountType, teams } = value;
      if (!value) {
        return accum;
      }
      return [
        ...accum,
        {
          id: prettyId(),
          account: accountId,
          accountName,
          role: roleId,
          accountType,
          teams,
        },
      ];
    }, []);
  }
}

export function reduceForAgenciesData(array) {
  if (array && array.length) {
    return array.reduce((accum, value) => {
      const { name, id } = value;
      if (!value) {
        return accum;
      }
      return [...accum, { label: `${name}`, value: id }];
    }, []);
  }
}

export function mapAgencyPersonas(array) {
  if (array && array.length) {
    return array.map((post) => {
      return post.account;
    });
  }
}

export function reduceForAgencies(array) {
  if (array && array.length) {
    return array.reduce((accum, value) => {
      const {
        id,
        contactFirstName,
        contactLastName,
        contactEmail,
        contactPhone,
      } = value;

      if (!value) {
        return accum;
      }

      const label = getAgencyLabel(value);

      return [
        ...accum,
        {
          label,
          value: id,
          agentFirstName: contactFirstName,
          agentLastName: contactLastName,
          agentEmail: contactEmail,
          agentPhoneNumber: contactPhone,
        },
      ];
    }, []);
  }
}

export function reduceForAgency(array) {
  if (Array.isArray(array) && array.length) {
    return array.reduce((accum, value) => {
      const { email } = value;
      if (!value) {
        return accum;
      }
      return [...accum, { label: email, value: email }];
    }, []);
  }
}

export function reduceForLocations(array) {
  if (Array.isArray(array) && array.length) {
    return array.reduce((accum, value) => {
      const { id, locationName, isHeadquarters, isLocationActive } = value;
      if (!value || !isLocationActive) {
        return accum;
      }
      return [
        ...accum,
        {
          label: isHeadquarters ? `${locationName} [HQ]` : locationName,
          value: id,
          isHeadquarters,
        },
      ];
    }, []);
  }
}

export function reduceForAgencyInfo(array) {
  if (array && array.length) {
    return array.reduce((accum, value) => {
      const { agencyName, agencyId } = value;
      if (!value) {
        return accum;
      }
      return [...accum, { label: `${agencyName}`, value: agencyId }];
    }, []);
  }
}

export function getAgencyLabel(
  agency,
  nameKey = 'name',
  locationAliasKey = 'locationAlias'
) {
  const label =
    agency[locationAliasKey] && !agency[locationAliasKey].includes('null')
      ? `${agency[nameKey]} - ${agency[locationAliasKey]}`
      : agency[nameKey];

  return label;
}
