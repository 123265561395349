import { InternalApiAxiosInstance } from '../../config/axios/internal.api.config';
import { ServiceHandler } from '../../utils';

export const getJumpToken = ServiceHandler(
  {
    url: '/api/auth/v1/admin/enter/:token',
    method: 'post',
  },
  InternalApiAxiosInstance
);

export const getTempExchangeTokenForJump = ServiceHandler(
  {
    url: '/api/auth/v1/:personaPath/:accountId/jtoken',
    method: 'post',
  },
  InternalApiAxiosInstance
);

export const getSwitchAccountsToken = ServiceHandler(
  {
    url: '/api/auth/v1/accounts/:accountId/myToken',
    method: 'post',
  },
  InternalApiAxiosInstance
);
