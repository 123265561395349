import React from 'react';
import { styled } from '@mui/styles';
import {
  LAComplianceCheckBox,
  MTComplianceCheckBox,
} from '../../constants/constants';
import CheckBoxBase from '../../../../../components/inputs/Checkbox';

export const LACompliance = ({ handlesurplusNoticeAttested, ...props }) => {
  return (
    <>
      <CheckBoxBase
        name="surplusNoticeAttested"
        onChange={handlesurplusNoticeAttested}
        label={<Label>{LAComplianceCheckBox}</Label>}
        {...props}
      />
      <div>
        <p>
          This insurance policy is delivered as surplus line coverage under the
          Louisiana Insurance Code.
        </p>
        <p>
          In the event of insolvency of the company issuing this contract, the
          policyholder or claimant is not covered by the Louisiana Insurance
          Guaranty Association or the Louisiana Life and Health Guaranty
          association, which guarantees only specific types of policies issued
          by insurance companies authorized to do business in Louisiana.
        </p>
        <p>
          This surplus lines policy has been procured by the following licensed
          Louisiana surplus lines broker:
        </p>
      </div>
    </>
  );
};

export const MTCompliance = ({ handlesurplusNoticeAttested, ...props }) => {
  return (
    <>
      <CheckBoxBase
        name="surplusNoticeAttested"
        onChange={handlesurplusNoticeAttested}
        label={<Label>{MTComplianceCheckBox}</Label>}
        {...props}
      />
      <div>
        <p>
          This coverage is issued by an unauthorized insurer that is an eligible
          surplus lines insurer. If this insurer becomes insolvent, there is no
          coverage by the Montana Insurance Guaranty Association under the
          Montana Insurance Guaranty Association Act.
        </p>
      </div>
    </>
  );
};

const Label = styled('label')({
  display: 'block',
  fontSize: '1.17em',
  marginBlockStart: '1em',
  marginBlockEnd: '1em',
  fontWeight: 'bold',
  pointerEvents: 'none',
});
