import React from 'react';
import { useSnackbar } from 'notistack';
// components
import { FormContext, useForm } from 'react-hook-form-4';
import _ from 'lodash';
import * as Yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import { DialogContent, DialogActions, Grid } from '@mui/material';

import { withStyles } from '@mui/styles';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/withFormController';
import CBButton from '../Buttons/CbButton';

import { editHostname } from '../../accounts/AccountService';

import { COWBELL_ACCOUNTS } from '../tables/table_constants';

import { manageAPIError } from '../../utils';
import { useToggleModal } from '../../utils/modal.utils';

// helpers

const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  hostname: Yup.string().required('Hostname is required'),
});

const EditHostname = ({ classes, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const defaultValues = {
    hostname: data.hostname.split('.')[0],
  };

  const queryClient = useQueryClient();

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    validationSchema: schema,
  });
  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (formData) => {
    const payload = {
      hostname: `${formData.hostname}${data.hostname.substring(
        data.hostname.indexOf('.')
      )}`,
    };
    return editHostname({ accountId: data.id }, payload)
      .then(() => {
        toggleModal.direct('EditHostname', false);
        enqueueSnackbar('Hostname Edited Successfully!', {
          variant: 'success',
        });
        queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
      })
      .catch((error) => {
        const errorMessage = manageAPIError(
          error,
          'An issue occurred while updating the hostname. Try again'
        );
        enqueueSnackbar(errorMessage, { variant: 'error' });
      });
  };

  const handleCancel = () => {
    toggleModal.direct('EditHostname', false);
  };

  return (
    <section className={classes.cardContentContainer}>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent classes={{ root: classes.container }}>
            <Grid
              container
              justifyContent="left"
              className={classes.inputFields}
            >
              <Grid item md={12}>
                <TextField
                  error={_.get(methods, 'errors.hostname.message')}
                  name="hostname"
                  label="Host Name"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Save"
            />
          </DialogActions>
        </form>
      </FormContext>
    </section>
  );
};

const styles = {
  container: {
    flex: '1 1 auto',
    padding: '0 5rem 0.5rem 5rem',
    'overflow-y': 'visible',
  },
  inputFields: {
    justifyContent: 'space-around',
  },
};

export default withStyles(styles)(EditHostname);
