import { ProductTypes } from '../../../../../types';
import {
  CANCELLED_STATUS,
  EXPIRED_STATUS,
  RENEWED_STATUS,
} from '../../../../../console/_statics/policy.statics';

import PoliciesService from '../../../../../console/policies/policies.service';
import { VOID_STATUS } from '../../../../../console/_statics/quote.statics';
import type { Permissions } from '../../../../auth/permissions';
import { determineIfAllowedScopes } from '../../../../../utils/next/auth.utils';
import type { PolicyTableInfoDto } from '../../../../../types/policy/tables/policy-table-info.dto';

interface IOptions {
  flags: object;
}

export const getPolicyActionsForCarrier = (
  policy: PolicyTableInfoDto,
  scopes: Permissions[],
  options: IOptions = { flags: {} }
) => {
  const CAN_APPLY_PAYMENT =
    !policy.isPaid &&
    policy.product === ProductTypes.prime_one &&
    determineIfAllowedScopes(
      scopes,
      ['account:carrier', 'policy:apply_payment'],
      true
    );

  const CAN_DOWNLOAD_APPLICATION =
    !policy.isPrimePlus &&
    policy.product !== ProductTypes.primecloud &&
    ![VOID_STATUS, EXPIRED_STATUS].includes(policy.status);

  const CAN_DOWNLOAD_INVOICE =
    (policy.isDocumentAvailable ||
      (policy.product === ProductTypes.primecloud &&
        policy.isInvoiceAvailable)) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      [RENEWED_STATUS, EXPIRED_STATUS, CANCELLED_STATUS].includes(
        policy.status
      ));

  const CAN_DOWNLOAD_POLICY_DOCUMENTS = ![
    EXPIRED_STATUS,
    VOID_STATUS,
    CANCELLED_STATUS,
  ].includes(policy.status);

  const CAN_VIEW_RATER =
    (PoliciesService.isActivePolicyByStatus(policy) ||
      [RENEWED_STATUS, EXPIRED_STATUS, VOID_STATUS, CANCELLED_STATUS].includes(
        policy.status
      )) &&
    policy.product === ProductTypes.p250 &&
    !policy.isPrimePlus &&
    !policy.isEndorsement;

  return {
    CAN_APPLY_PAYMENT,
    CAN_DOWNLOAD_APPLICATION,
    CAN_DOWNLOAD_INVOICE,
    CAN_DOWNLOAD_POLICY_DOCUMENTS,
    CAN_VIEW_RATER,
  };
};
