import React from 'react';
import {
  Grid,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import CBCopyToClipboard from '../copyToClipboard/CopyToClipboard';
import CbButton from '../Buttons/CbButton';

const styles = ({ palette, config }) => {
  return {
    container: {
      marginBottom: '1rem',
      justifyContent: 'center',
    },
    content: {
      fontSize: config.textSizes.paragon,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      background: palette.background.default,
      borderRadius: 5,
      lineHeight: 2,
    },
    link: {
      marginTop: '-5px',
      padding: '5px 10px',
      maxWidth: '39rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    text: {
      fontSize: config.textSizes.tertia,
      width: '40rem',
      marginTop: '10px',
      textAlign: 'center',
      fontStyle: 'italic',
    },
    icon: {
      paddingTop: 10,
    },
  };
};

export const CopyApplicationLink = withStyles(styles)(
  ({ classes, close, data }) => {
    const { externalSignUrl } = data;
    let error = '';
    if (!externalSignUrl) {
      error =
        'Application Link is not available at this time. Please try again after sometime.';
    }

    return (
      <form>
        <DialogContent>
          <Grid container className={classes.container}>
            <Grid item md={8}>
              <div className={classes.content}>
                <span className={classes.link}>{externalSignUrl}</span>
                <div className={classes.icon}>
                  <CBCopyToClipboard
                    text={externalSignUrl}
                    className={classes.clipboard}
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={8}>
              <div className={classes.text}>
                Note: You can copy and paste the ‘Cowbell Application Link’ in
                your email to the insured for signature.
              </div>
            </Grid>
          </Grid>
          {error && (
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton buttonText="Close" action={close} styleName="ctaButton" />
        </DialogActions>
      </form>
    );
  }
);

export default CopyApplicationLink;
