import React from 'react';
import { useFormContext } from 'react-hook-form-4';
import { SimpleSelect } from '../../../../inputs/selects/SimpleSelect';
import { useRHFError } from '../../../../hooks/index';

const RetroInput = ({
  name = 'retroActivePeriod',
  retroInput = [],
  ...props
}) => {
  const { setValue, errors, clearError } = useFormContext();
  const error = useRHFError(name, errors);

  React.useEffect(() => {
    setValue(name, props.defaultValue, { shouldDirty: false });
    // eslint-disable-next-line
  }, []);

  const options = React.useMemo(
    () =>
      [...retroInput].map((yr) => ({
        label: `${yr} year`,
        value: yr,
      })),
    [retroInput]
  );

  const handleSelect = React.useCallback((event) => {
    setValue(name, event.target.value, { shouldDirty: true });
    clearError(name);
    // eslint-disable-next-line
  }, []);

  return (
    <SimpleSelect
      label="Retroactive Period"
      name={name}
      onChange={handleSelect}
      options={options}
      displayEmpty
      renderValue={renderValue}
      required
      error={error}
      {...props}
    />
  );
};

function renderValue(value) {
  return value || 'Please Choose...';
}

export default RetroInput;
