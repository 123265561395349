import React from 'react';
import { TablePagination as MuiTablePagination, useTheme } from '@mui/material';

export const DefaultTablePagination = ({
  totalCount,
  currentPage,
  pageSize,
  pageSizes,
  onCurrentPageChange,
  onPageSizeChange,
}) => {
  const { palette } = useTheme();
  return (
    <MuiTablePagination
      style={{
        borderBottom: 'none',
        backgroundColor: palette.background.tableHeader,
      }}
      count={Number(totalCount)}
      page={Number(currentPage)}
      rowsPerPage={Number(pageSize)}
      rowsPerPageOptions={pageSizes}
      backIconButtonProps={{
        onClick: () => onCurrentPageChange(currentPage - 1),
      }}
      nextIconButtonProps={{
        onClick: () => onCurrentPageChange(currentPage + 1),
      }}
      onRowsPerPageChange={(event) => onPageSizeChange(event.target.value)}
    />
  );
};
