import _ from 'lodash';
import React from 'react';

import { useSelector } from 'react-redux';
import { type match } from 'react-router-dom';

import { Backdrop, CircularProgress, useTheme } from '@mui/material';

import { fetch250PolicyPageDetails } from '../../../policies/PolicyService';

import { getQuoteDetailsP250, getQuoteP100, searchQuotes } from '../../../api';

import { use250Context } from '../../agencies/quotes/250/prime250.context';

// reducers
import {
  setPrime250Coverages,
  setPrime250Ui,
} from '../../_reducers/prime250.reducer';
import { ProductTypes } from '../../../types';
import { toUniversalUtcDate } from '../../../utils/date.utils';

import type {
  CoverageDto,
  ProductType,
  RootStore,
  UiCoverageP250,
} from '../../../types';
import { useAPIErrorHandler } from '../../../components/hooks/useAPIErrorHandler';
import {
  getPolicyDetailsP100,
  searchPolicies,
} from '../../../api/policies.api';

type MatchParams = { quoteId: string; product: ProductType; accountId: string };

/**
 * @name withDetailsDependencies
 * @description This component is meant to enable the data combination and preparation for rendering
 */
export const withDetailsDependencies = (type: 'quote' | 'policy') => {
  function wrapped<P = object>(WrappedComponent: React.ComponentType<P>) {
    const ComponentWithDetailsDependencies: React.FC<
      P & { match: match<MatchParams> }
    > = (props) => {
      const theme = useTheme();
      // TODO: The 250 context needs typed 😬
      const { availableCoverages, dispatch } = use250Context() as any;

      const [data, setData] = React.useState('');
      const [list, setList] = React.useState([]);
      const [loading, setLoading] = React.useState(true);
      const savedCoverages = _.merge(availableCoverages);
      const account = useSelector<RootStore, RootStore['tabs']['account']>(
        ({ tabs }) => tabs.account
      );

      const dependencyParams = React.useMemo(
        () => ({
          quoteId: props.match.params.quoteId,
          policyId: account.policyId,
          availableCoverages: savedCoverages,
          product: props.match.params.product,
          accountId: props.match.params.accountId,
          dispatch,
          setData,
          setList,
          type,
          setLoading,
        }),
        // eslint-disable-next-line
        [
          dispatch,
          props.match.params.product,
          props.match.params.quoteId,
          props.match.params.accountId,
          savedCoverages,
          account,
        ]
      );

      useGetDependencies(dependencyParams);

      if (loading) {
        return (
          <Backdrop
            style={{
              zIndex: theme.zIndex.drawer + 1,
              color: theme.palette.text.link,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        );
      }

      if (data) {
        return <WrappedComponent {...props} data={data} list={list} />;
      }

      return null;
    };

    ComponentWithDetailsDependencies.displayName = `withDetailsDependencies(${WrappedComponent.displayName})`;
    return ComponentWithDetailsDependencies;
  }

  return wrapped;
};

type UseGetDependenciesProps = {
  dispatch: React.Dispatch<any>;
  type: 'quote' | 'policy';
  quoteId?: string;
  policyId?: string;
  availableCoverages: (UiCoverageP250 & { key: string })[];
  product: ProductType;
  accountId: string;
  setData: React.Dispatch<React.SetStateAction<any>>;
  setList: React.Dispatch<React.SetStateAction<any>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

function useGetDependencies({
  dispatch,
  quoteId,
  policyId,
  availableCoverages,
  product,
  accountId,
  setData,
  setList,
  type,
  setLoading,
}: UseGetDependenciesProps) {
  const initial = React.useRef(true);
  const handleAPIError = useAPIErrorHandler();

  // on initial load
  React.useEffect(() => {
    if (type === 'quote') {
      searchQuotes({ params: { 'accountId.keyword': accountId } }).then(
        (resp) => {
          setList(resp.data.content);
        }
      );

      if (product === ProductTypes.p100 && quoteId) {
        getQuoteP100(quoteId)
          .then(({ data }) => {
            setData(data.data);
          })
          .then(() => {
            initial.current = false;
            dispatch(setPrime250Ui({ initialRender: false }));
            setLoading(false);
          })
          .catch(
            handleAPIError(
              'Cannot fetch quote details at this time. Please try again later.'
            )
          );
      }

      if (product === ProductTypes.p250 && quoteId) {
        getQuoteDetailsP250(quoteId)
          .then(({ data }) => {
            setData(data.data);
            return data.data.initial250RequestData.coverages;
          })
          .then((requestedCoverages) =>
            mergeResponseData({ availableCoverages, requestedCoverages })
          )
          .then(zipCoverages)
          .then(setCoverages(dispatch))
          .then(() => {
            initial.current = false;
            dispatch(setPrime250Ui({ initialRender: false }));
            setLoading(false);
          })
          .catch(
            handleAPIError(
              'Cannot fetch quote details at this time. Please try again later.'
            )
          );
      }
    } else if (type === 'policy') {
      searchPolicies({ params: { 'accountId.keyword': accountId } }).then(
        (resp) => {
          setList(resp.data.content);
          if (policyId === 'no-policy') {
            setData([]);
            initial.current = false;
            dispatch(setPrime250Ui({ initialRender: false }));
            setLoading(false);
          }
        }
      );

      if (
        product === ProductTypes.p100 &&
        policyId !== 'no-policy' &&
        policyId
      ) {
        getPolicyDetailsP100(policyId)
          .then(({ data }) => {
            setData(data.data);
          })
          .then(() => {
            initial.current = false;
            dispatch(setPrime250Ui({ initialRender: false }));
            setLoading(false);
          })
          .catch(
            handleAPIError(
              'Cannot fetch policy details at this time. Please try again later.'
            )
          );
      }

      if (product === ProductTypes.p250 && policyId !== 'no-policy') {
        fetch250PolicyPageDetails(policyId)
          .then((resp) => {
            setData(resp.data);
            return resp.data.initialRequestData.coverages;
          })
          .then((requestedCoverages) =>
            mergeResponseData({ availableCoverages, requestedCoverages })
          )
          .then(zipCoverages)
          .then(setCoverages(dispatch))
          .then(() => {
            initial.current = false;
            dispatch(setPrime250Ui({ initialRender: false }));
            setLoading(false);
          })
          .catch(
            handleAPIError(
              'Cannot fetch policy details at this time. Please try again later.'
            )
          );
      }
    }

    // eslint-disable-next-line
  }, [quoteId, policyId, product]);
}

function mergeResponseData({
  availableCoverages,
  requestedCoverages,
}: {
  availableCoverages: (UiCoverageP250 & { key: string })[];
  requestedCoverages: CoverageDto[];
}) {
  const coverageDataMap = zipResponse(availableCoverages);

  const coverages = requestedCoverages.map((coverage) => {
    const coverageData = coverageDataMap[coverage.name];

    const {
      deductible,
      limit,
      retroActivePeriod,
      retroActiveDate,
      waitingPeriod,
    } = coverage;

    return {
      ...coverageData,
      available: true,
      selected: true,
      limit: {
        value: limit,
      },
      deductible: {
        available: typeof deductible !== 'undefined',
        value: deductible,
      },
      waitingPeriod: {
        available: typeof waitingPeriod !== 'undefined',
        value: waitingPeriod,
      },
      retroActivePeriod: {
        available:
          typeof retroActivePeriod !== 'undefined' ||
          typeof retroActiveDate !== 'undefined',
        value: toUniversalUtcDate(retroActiveDate, {
          placeholder:
            retroActivePeriod != null
              ? String(retroActivePeriod)
              : retroActivePeriod,
        }),
      },
    };
  });

  return coverages;
}

// scoped data utils
function zipResponse<T extends { key: string }>(data: T[]) {
  return data.reduce(
    (acc, coverage) => ({
      ...acc,
      [coverage.key]: coverage,
    }),
    {} as Record<string, T>
  );
}

function zipCoverages<T extends { _id?: string | number }>(data: T[]) {
  return data.reduce((acc, coverage) => {
    if (coverage._id == undefined) return acc;

    return {
      ...acc,
      [coverage._id]: coverage,
    };
  }, {} as Record<string, T>);
}

function setCoverages(dispatch: React.Dispatch<any>) {
  return (coverages: any) => {
    // set coverages
    dispatch(setPrime250Coverages(coverages));
  };
}
