import React from 'react';

// mui
import { Box as MuiBox } from '@mui/material';

// components
import { FormProvider } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import CbButton from '../../../../components/Buttons/CbButton';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import DocumentUploadBase from './shared/DocumentUploadBase';

// hooks and hocs
import { withShowable } from '../../../_global/lib/withShowable';

// services
import CheckBoxLabel from '../CheckBoxLabel';

// utils
import { insuranceApplicationCheckboxLabel } from '../constants/constants';
import { ScreenTitle } from './shared/ScreenTitle';
import { useInsuranceApplicationDependencies } from './dependencies/useInsuranceApplicationDependencies';
import SignatureHolderConfirmation from './shared/SignatureHolderConfirmation';
import { SubjectivityCompletionDate as SubjectivityCompletionDateBase } from './shared/SubjectivityCompletionDate';
import CowbellReferToUWButton from './shared/referral/CowbellReferToUWButton';

const Box = withShowable(MuiBox);
const SubjectivityCompletionDate = withShowable(SubjectivityCompletionDateBase);

const InsuranceApplication = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { eventHandlers, reactHookForm, ...insuranceApplicationState } =
    useInsuranceApplicationDependencies(props);

  if (insuranceApplicationState.isLoading) {
    return null;
  }

  const { quoteSubjectivityId } = props.subjectivities[props.step];

  return (
    <Box className="contrast-text" width="50%">
      <ScreenTitle
        subjectivitySubType={insuranceApplicationState.subjectivitySubType}
        title={
          insuranceApplicationState.allSubjectivityInformation.subjectivityTitle
        }
      />

      <h3>
        {insuranceApplicationState.allSubjectivityInformation
          .subjectivityDescription ?? ''}
      </h3>

      <FormProvider {...reactHookForm.methods}>
        <form onSubmit={reactHookForm.handleSubmit(eventHandlers.onSubmit)}>
          <Box>
            <Box>
              <DocumentUploadBase
                uploadZoneParams={insuranceApplicationState.uploadZoneParams}
                files={insuranceApplicationState.files}
                isUploadingDoc={insuranceApplicationState.isUploadingDoc}
                uploadedDocs={insuranceApplicationState.uploadedDocuments}
                isStepEditable={insuranceApplicationState.isStepEditable}
                docType="Insurance Application"
                datePickerProps={{
                  displayDatePicker: true,
                  min: insuranceApplicationState.allSubjectivityInformation
                    ?.hydratedInfo?.signatureValidRangeStartDate,
                  max: insuranceApplicationState.allSubjectivityInformation
                    ?.hydratedInfo?.signatureValidRangeEndDate,
                }}
              />
            </Box>

            <Box
              show={
                insuranceApplicationState.files ||
                insuranceApplicationState.subjectivityData
              }
            >
              <SignatureHolderConfirmation
                disableFormFields={
                  !insuranceApplicationState.isStepEditable ||
                  insuranceApplicationState.isUploadingDoc
                }
              />
            </Box>

            <Box
              paddingBottom="1rem"
              show={
                !insuranceApplicationState.files &&
                insuranceApplicationState.isStepEditable
              }
            >
              <h3>Don't have the signed Application?</h3>

              <CbButton
                styleName="ctaButton"
                size="large"
                onClick={() =>
                  eventHandlers.handleDocDownload(() =>
                    enqueueSnackbar(
                      'Application is still being generated. Please try again in a few minutes.',
                      { variant: 'info' }
                    )
                  )
                }
              >
                Download Pre-Filled App
              </CbButton>
            </Box>

            <Box
              show={
                insuranceApplicationState.files ||
                insuranceApplicationState.subjectivityData
              }
            >
              <Box>
                <CheckBoxBase
                  name="insuranceApplicationAcknowledgement"
                  label={
                    <CheckBoxLabel label={insuranceApplicationCheckboxLabel} />
                  }
                  onChange={eventHandlers.handleCheckboxChange}
                  checked={insuranceApplicationState.isInsuranceAppAcknowledged}
                  disabled={
                    (insuranceApplicationState.subjectivityData?.userAttested &&
                      !insuranceApplicationState.files &&
                      !insuranceApplicationState.isFormDirty) ||
                    insuranceApplicationState.isUploadingDoc
                  }
                />
              </Box>
            </Box>
          </Box>

          <SubjectivityCompletionDate
            allSubjectivityInformation={
              insuranceApplicationState.allSubjectivityInformation
            }
            show={insuranceApplicationState.isStepCompleted}
          />

          <Box paddingTop="2rem">
            <CbButton
              styleName="cancel"
              size="medium"
              onClick={eventHandlers.handleGoBack}
            >
              Back
            </CbButton>
            <CbButton
              styleName="ctaButton"
              size="medium"
              type="submit"
              onClick={eventHandlers.handleGoNext}
              loading={
                insuranceApplicationState.isUploadingDoc ||
                insuranceApplicationState.isCompletingStep
              }
              disabled={
                insuranceApplicationState.isUploadingDoc ||
                insuranceApplicationState.isCompletingStep ||
                (!insuranceApplicationState.isInsuranceAppAcknowledged &&
                  insuranceApplicationState.files) ||
                (insuranceApplicationState.isStepCompleted &&
                  !insuranceApplicationState.isInsuranceAppAcknowledged)
              }
              style={{ marginRight: '0.833rem' }}
            >
              Next
            </CbButton>

            <CowbellReferToUWButton
              quoteId={props.quoteDetails.id}
              subjectivityId={quoteSubjectivityId}
              productType={props.quoteDetails.uiData.productType}
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default InsuranceApplication;
