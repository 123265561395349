import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// lodash
import _ from 'lodash';

// components
import { Box, FormHelperText, DialogContent } from '@mui/material';

import { withStyles } from '@mui/styles';
import CBButton from '../../components/Buttons/CbButton';
import CheckBoxBase from '../../components/inputs/Checkbox';
import { AgencyTeamsAccountCreationHeader } from '../admin-account-flow/AgencyTeamsAccountCreationHeader';

// apis
import { bulkUpload, getTags } from '../AccountService';
import { refreshAccessToken } from '../../api/apis';

// utils

import { PubSub } from '../../utils/eventUtils';
import { retrieveAuthTokens } from '../../utils/next/auth.utils';
import { useToggleModal } from '../../utils/modal.utils';

const BulkUpload = ({ classes }) => {
  const toggleModal = useToggleModal();

  const [data, setData] = useState({
    error: '',
    selectedFile: null,
    ready: false,
    tags: null,
    checkbox: false,
  });
  const [checkbox, setCheckbox] = useState(false);
  const fileInputRef = useRef(null);
  const team = useSelector(({ uiSettings }) => uiSettings.team.accountCreation);
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!data.ready) {
      const { accessToken } = retrieveAuthTokens();

      Promise.all([refreshAccessToken(accessToken), getTags()]).then((resp) => {
        const tagsData = _.get(resp[1], 'data.tags', []);
        const tags = {};
        tagsData.forEach((tag) => {
          tags[tag] = {
            name: tag,
            selected: false,
          };
        });
        setData((prevState) => {
          return {
            ...prevState,
            ready: true,
            tags,
          };
        });
      });
    }

    const sub = PubSub.subscribe('foot-button-one', (eventData) => {
      if (eventData) {
        onSubmit();
      }
    });

    return () => {
      sub.remove();
    };
  });

  const onSubmit = () => {
    const { selectedFile } = data;

    if (!selectedFile) {
      setErrors({ selectedFile: true });
      return;
    }

    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);

    const bodyFormData = new FormData();
    bodyFormData.append('file', selectedFile);

    setData((prevState) => {
      return {
        ...prevState,
      };
    });
    const { tags } = data;
    const arr = [];
    _.forEach(tags, (tag) => {
      if (tag.selected) {
        arr.push(tag.name);
      }
    });

    bulkUpload(bodyFormData, arr.join(','), checkbox, team)
      .then(() => {
        setData((prevState) => {
          return {
            ...prevState,
            selectedFile: null,
          };
        });
        toggleModal.direct('BulkUpload', false);
        enqueueSnackbar(`Uploaded Successfully`, { variant: 'success' });
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
      })
      .catch(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        toggleModal.direct('BulkUpload', false);
      });
  };

  const selectFile = () => {
    if (fileInputRef) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelected = () => {
    const selectedFile = fileInputRef.current.files[0];
    const { size } = selectedFile;

    if (errors.selectedFile) {
      setErrors((currentErrors) => {
        const nextErrors = { ...currentErrors, selectedFile: false };
        return nextErrors;
      });
    }

    if (size / 1024 / 1024 > 5) {
      setData((prevState) => {
        return {
          ...prevState,
          error: 'The file size is too big, 5MB max.',
          selectedFile: null,
        };
      });
      PubSub.publish('disabled', true);
      PubSub.publish('loading', false);
    } else {
      setData((prevState) => {
        return {
          ...prevState,
          error: '',
          selectedFile,
        };
      });
      PubSub.publish('disabled', false);
      PubSub.publish('loading', false);
    }
  };

  const downloadTemplate = (event) => {
    event.preventDefault();
    const url = 'resources/CowbellCyber-Bulk Upload Template.xls';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'CowbellCyber-Bulk Upload Template.xls';
    link.click();
  };

  const toggleTag = (tag) => () => {
    const { tags } = data;
    const { selected } = tags[tag.name];
    tags[tag.name].selected = !selected;
    setData((prevState) => {
      return {
        ...prevState,
        tags,
      };
    });
  };

  const renderTags = () => {
    const { tags } = data;
    const elements = [];
    _.forEach(tags, (tag) => {
      elements.push(
        <Box
          className={`${classes.tagContainer} ${
            tag.selected ? classes.tagSelected : ''
          }`}
          onClick={toggleTag(tag)}
        >
          {tag.name}
        </Box>
      );
    });
    return <section className={classes.myTags}>{elements}</section>;
  };

  const cancelSelectedFile = () => {
    fileInputRef.current.value = fileInputRef.current.defaultValue;
    setData((prevState) => {
      return {
        ...prevState,
        selectedFile: null,
      };
    });
  };

  const handleChangeCheckBox = (event, value) => {
    setCheckbox(value);
  };

  if (!data.ready) {
    return null;
  }

  return (
    <DialogContent className={classes.container}>
      <form onSubmit={onSubmit} id="submit-form">
        <section className={classes.bodyContainer}>
          <AgencyTeamsAccountCreationHeader data={team} />
          <div className={classes.downloadTemplateContainer}>
            <p className={classes.downloadTemplateText}>
              Excel template for bulk upload:&nbsp;
              <Box
                component="span"
                className={classes.downloadTemplate}
                onClick={downloadTemplate}
              >
                Download Template
              </Box>
            </p>
            <img
              src="images/Download.svg"
              className={classes.icon}
              alt="Download icon"
            />
          </div>
          <div>
            <CheckBoxBase
              defaultChecked={checkbox}
              label="Add red accounts (i.e accounts with insufficient data)"
              onChange={handleChangeCheckBox}
            />
          </div>
          {Object.keys(data.tags).length > 0 ? (
            <section className={classes.tagsContainer}>
              <p className={classes.tagsTitle}>
                CLICK ON TAG(S) TO APPLY THEM TO ACCOUNTS
              </p>
              {renderTags()}
            </section>
          ) : null}
          <section className={classes.buttonContainer}>
            <input
              type="file"
              id="file"
              className={classes.fileInput}
              ref={fileInputRef}
              accept=".xls,.csv,.xlsx"
              onChange={handleFileSelected}
            />
            {errors.selectedFile && (
              <Box mb={2}>
                <FormHelperText className="api-text-error">
                  No file selected for upload.
                </FormHelperText>
              </Box>
            )}

            <CBButton
              disabled={data.selectedFile}
              styleName="ctaButton"
              action={selectFile}
              buttonText="ADD FILE TO UPLOAD"
              size="large"
            />
          </section>
          {data.selectedFile ? (
            <section className={classes.selectedFileContainer}>
              <p className={classes.selectedFile}>
                SELECTED FILE: {data.selectedFile.name}
              </p>
              <Box
                component="img"
                src="images/cancel.svg"
                className={classes.iconCancel}
                onClick={cancelSelectedFile}
              />
            </section>
          ) : null}
          <p className={classes.supportedFiles}>
            Supported File Types: XLXS, XLS & CSV
          </p>
          {data.error ? <p>{data.error}</p> : null}
        </section>
      </form>
    </DialogContent>
  );
};

const styles = ({ palette }) => {
  return {
    buttonContainer: {
      marginTop: '1.75rem',
    },
    fileInput: {
      display: 'none',
    },
    container: {
      width: '50rem',
      backgroundColor: palette.background.active,
    },
    bodyContainer: {
      height: 'fit-content',
      textAlign: 'center',
    },
    supportedFiles: {
      fontSize: '1.167rem',
      lineHeight: 1.5,
      color: palette.text.secondary,
      marginBottom: 20,
    },
    selectedFile: {
      fontSize: '1.33rem',
      lineHeight: 1.38,
      color: palette.primary.main,
      marginBottom: '0 !important',
    },
    icon: {
      width: '1.5rem',
      height: '1.25rem',
      margin: '0 0 0 1rem',
    },
    iconCancel: {
      width: '2rem',
      height: '2rem',
      margin: '1.3rem 0 0 0.5rem',
      cursor: 'pointer',
    },
    downloadTemplate: {
      'text-decoration': 'underline',
      fontWeight: 600,
      cursor: 'pointer',
    },
    downloadTemplateContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      borderBottom: `1px solid ${palette.primary.oldBorder5}`,
      padding: '1.67rem 0',
    },
    downloadTemplateText: {
      fontSize: '1.33rem',
      color: palette.text.secondary,
      margin: '0 !important',
    },
    tagsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '38rem',
      margin: '0 auto 2.5rem auto',
    },
    tagsTitle: {
      fontSize: '1.33rem',
      margin: '1.67rem 0 1.75rem 0 !important',
    },
    myTags: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    tagContainer: {
      width: '10.83rem',
      height: '2.5rem',
      borderRadius: 16,
      border: `1.3px solid ${palette.primary.main}`,
      textAlign: 'center',
      paddingTop: '0.5rem',
      margin: '0.5rem 0.4rem',
      float: 'left',
      cursor: 'pointer',
    },
    tagSelected: {
      border: `1.3px solid ${palette.primary.border2}`,
    },
    selectedFileContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  };
};

export default withStyles(styles)(BulkUpload);
