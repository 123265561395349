import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'classnames';

// mui
import { Box as MuiBox } from '@mui/material';
import { styled, makeStyles } from '@mui/styles';

import { ToggleButton as ToggleButtonBase } from '@mui/lab';

// components
import CheckBoxLabel from '../CheckBoxLabel';
import DocumentUploadBase from './shared/DocumentUploadBase';
import { ScreenTitle } from './shared/ScreenTitle';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import CbButton from '../../../../components/Buttons/CbButton';
import { RegisteredToggleButtonGroup } from '../../../../components/Buttons/registered/v7';

// hook and hocs
import useUploadZone from '../../../../components/fileUploader/v2/hooks/useUploadzone';
import { withShowable } from '../../../_global/lib/withShowable';
import useDocUpload from './shared/useDocUpload';

// constants
import { dilligentSearchReportCheckBoxLabel } from '../constants/constants';
import useGetSubjectivityProgress from './shared/useGetSubjectivityProgress';
import useCompleteQuoteSubjectivity from './shared/useCompleteQuoteSubjectivity';
import useGetSubjectivityDocs from './shared/useGetSubjectivityDocs';
import { SubjectivityCompletionDate as SubjectivityCompletionDateBase } from './shared/SubjectivityCompletionDate';
import { determineIfStepIsEditable } from '../utils/bindQuoteWorkFlowUtils';
import CowbellReferToUWButton from './shared/referral/CowbellReferToUWButton';

const Box = withShowable(MuiBox);
const SubjectivityCompletionDate = withShowable(SubjectivityCompletionDateBase);

const DilligentSearchReport = ({
  goForward,
  goBack,
  completeStep,
  formState,
  formHandlers,
  step,
  quoteDetails,
  subjectivities,
  quoteProgress,
}) => {
  const {
    quoteSubjectivityId,
    completed: isStepCompleted,
    subjectivitySubType,
  } = subjectivities[step];

  const [
    isDilligentSearchReportAcknowledged,
    setIsDilligentSearchReportAcknowledged,
  ] = React.useState(isStepCompleted);

  const [diligentSearchReportExemption, setDiligentSearchReportExemption] =
    React.useState(formState.dilligentSearchReportExemption);

  const classes = useStyles();

  const isStepEditable = determineIfStepIsEditable({
    subjectivitySubType,
    status: quoteProgress.quoteAgencyStatus,
    isAPI: quoteProgress.isAPI,
    productType: quoteDetails.uiData.productType,
  });

  const { files, ...uploadZoneParams } = useUploadZone({
    docType: 'Dilligent_Search_Report',
    maxFiles: 4,
    multiple: true,
  });

  const { uploadMultipleDocuments, isUploadingDoc } = useDocUpload({
    quoteDetails,
  });

  const { completeSubjectivity, isCompletingStep } =
    useCompleteQuoteSubjectivity({
      quoteId: quoteDetails.uiData.quoteId,
      productType: quoteDetails.uiData.productType,
    });

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      dilligentSearchReportExemption: formState.dilligentSearchReportExemption,
    },
  });

  const {
    data: allSubjectivityInformation = {},
    isLoading: subjectivityDataLoading,
    isFetching: isFetchingSubjectivityData,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId,
    quoteId: quoteDetails.id,
    productType: quoteDetails.uiData.productType,
  });

  const { data: subjectivityData } = allSubjectivityInformation;

  const {
    data: uploadedDocuments,
    isInitialLoading: isUploadedDocsLoading,
    isFetching: isFetchingUploadedDocs,
  } = useGetSubjectivityDocs({
    quoteSubjectivityId,
    accountId: quoteDetails.uiData?.accountId,
    uploadedDocs: subjectivityData?.uploadedDocuments,
    isFetchingSubjectivityData,
  });

  React.useEffect(() => {
    if (files) {
      setIsDilligentSearchReportAcknowledged(false);
    }
  }, [files]);

  React.useEffect(() => {
    if (subjectivityData?.uploadedDocuments) {
      methods.setValue('dilligentSearchReportExemption', false);
      setDiligentSearchReportExemption(false);
    }

    if (subjectivityData?.diligentSearchReportExemption) {
      methods.setValue('dilligentSearchReportExemption', true);
      setDiligentSearchReportExemption(true);
    }
    // eslint-disable-next-line
  }, [subjectivityData]);

  const isLoadingSubjectivityData =
    subjectivityDataLoading ||
    isFetchingSubjectivityData ||
    isUploadedDocsLoading ||
    isFetchingUploadedDocs;

  if (isLoadingSubjectivityData) {
    return null;
  }

  const onSubmit = (formValues) => {
    formHandlers.saveDilligentSearchReport({ diligentSearchReportExemption });

    if (diligentSearchReportExemption) {
      const completeSubjectivityParams = {
        quoteSubjectivityId,
        completeStep,
        goForward,
        step,
        payload: {
          diligentSearchReportExemption: true,
          userAttested: true,
        },
      };

      return subjectivityData?.diligentSearchReportExemption
        ? goForward()
        : completeSubjectivity(completeSubjectivityParams);
    }

    if (!files) {
      return goForward();
    }

    const uploadDocumentParams = {
      formValues,
      files,
      quoteDetails,
      completeStep,
      goForward,
      step,
      docTypes: Array(files.length).fill('Diligent_Search_Report'),
      quoteSubjectivityId,
      userAttested: isDilligentSearchReportAcknowledged,
      diligentSearchReportExemption,
    };

    uploadMultipleDocuments(uploadDocumentParams);
  };

  const handleCheckboxChange = () => {
    setIsDilligentSearchReportAcknowledged((prevState) => !prevState);
  };

  return (
    <Box className="contrast-text" maxWidth="65%">
      <ScreenTitle
        subjectivitySubType={subjectivitySubType}
        title={allSubjectivityInformation.subjectivityTitle}
      />

      <h3>{allSubjectivityInformation.subjectivityDescription ?? ''}</h3>

      <h3>Does the insured qualify for exemption?</h3>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <RegisteredToggleButtonGroup
              name="dilligentSearchReportExemption"
              exclusive
            >
              <ToggleButton
                disabled={!isStepEditable}
                value
                onClick={() => setDiligentSearchReportExemption(true)}
              >
                <div
                  className={clsx({
                    [classes.disabledButtonText]: !isStepEditable,
                  })}
                >
                  Yes
                </div>
              </ToggleButton>
              <ToggleButton
                disabled={!isStepEditable}
                value={false}
                onClick={() => setDiligentSearchReportExemption(false)}
              >
                <div
                  className={clsx({
                    [classes.disabledButtonText]: !isStepEditable,
                  })}
                >
                  No
                </div>
              </ToggleButton>
            </RegisteredToggleButtonGroup>
          </Box>

          <Box show={diligentSearchReportExemption === false}>
            <Box>
              <DocumentUploadBase
                uploadZoneParams={uploadZoneParams}
                files={files}
                isUploadingDoc={isUploadingDoc}
                isStepEditable={isStepEditable}
                uploadedDocs={uploadedDocuments}
                docType="Diligent Search Report"
              />
            </Box>
            <Box show={!!files || !!subjectivityData?.uploadedDocuments}>
              <Box>
                <CheckBoxBase
                  name="insuranceApplicationAcknowledgement"
                  label={
                    <CheckBoxLabel label={dilligentSearchReportCheckBoxLabel} />
                  }
                  onChange={handleCheckboxChange}
                  checked={isDilligentSearchReportAcknowledged}
                  disabled={
                    (subjectivityData?.userAttested && !files) || isUploadingDoc
                  }
                />
              </Box>
            </Box>
          </Box>
          <SubjectivityCompletionDate
            allSubjectivityInformation={allSubjectivityInformation}
            show={isStepCompleted}
          />
          <Box paddingTop="1.5rem">
            <CbButton styleName="cancel" size="medium" onClick={goBack}>
              Back
            </CbButton>

            <CbButton
              styleName="ctaButton"
              size="medium"
              type="submit"
              loading={isUploadingDoc || isCompletingStep}
              disabled={
                isUploadingDoc ||
                isCompletingStep ||
                (!isDilligentSearchReportAcknowledged &&
                  files &&
                  diligentSearchReportExemption === false)
              }
              style={{ marginRight: '0.833rem' }}
            >
              Next
            </CbButton>

            <CowbellReferToUWButton
              quoteId={quoteDetails.id}
              subjectivityId={quoteSubjectivityId}
              productType={quoteDetails.uiData.productType}
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default DilligentSearchReport;

const ToggleButton = styled(ToggleButtonBase)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.contrastText}`,
  borderLeft: `1px solid ${theme.palette.primary.contrastText} !important`,
  '&:hover': {
    backgroundColor: theme.palette.background.modal,
  },
  padding: '0.5rem 4rem',
}));

const useStyles = makeStyles(({ palette }) => {
  return {
    disabledButtonText: {
      color: palette.text.text14,
    },
  };
});
