import * as React from 'react';
import {
  useForm,
  useFieldArray,
  FormProvider,
  Controller,
} from 'react-hook-form';
import { useQuery, useQueries, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

// helpers
import _ from 'lodash';

import * as Yup from 'yup';

import { HighlightOff as HighlightOffIcon } from '@mui/icons-material';
import {
  DialogActions,
  DialogContent,
  Grid as MuiGrid,
  IconButton,
  Box,
  Typography,
  MenuItem as MuiMenuItem,
  createFilterOptions,
  //
  //
} from '@mui/material';

import { makeStyles, withStyles } from '@mui/styles';

// actions
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchPagedAccounts } from '../../accounts/AccountService';
import { fetchAgencies } from '../../agencies/AgencyService';
import {
  fetchRoles,
  addExternalAccounts,
  getUserAccountsById,
} from '../../api/UserService';
import { getTeamsForAgency } from '../../teams/TeamsService';

// components
import { ManagedTypeAheadBase, SimpleSelect } from '../inputs';
import { InputLabelBase } from '../inputs/InputLabelBase';
import { ButtonMenu } from '../Menus/ButtonMenu';
import CbButton from '../Buttons/CbButton';

// platform helpers
import { prettyId } from '../../utils/data.utils';
import {
  getSelectItemAgency,
  reduceForSelect,
} from '../../console/admin/users/UsersUtils';
import { PubSub } from '../../utils/eventUtils';
import { fetchClusters } from '../../admin/cluster/cluster.service';
import { fetchDAs } from '../../admin/digital/da.service';
import { fetchCarriers } from '../../admin/carrier/carrier.service';
import { fetchMssps } from '../../admin/mssp/mssp.service';
import { useCowbellTranslations } from '../../i18n/translations';

const styles = ({ config, palette }) => {
  return {
    dialog: {
      '&.MuiDialogContent-root': {
        maxHeight: '500px',
        overflowY: 'auto',
        textAlign: 'left',
      },
    },
    container: {
      position: 'relative',
    },
    container1: {
      position: 'relative',
      padding: '0 2rem !important',
    },
    remove: {
      position: 'absolute',
      top: '10px',
      right: 0,
    },
    heading: {
      fontWeight: 300,
      margin: 0,
      lineHeight: 1.35,
      color: config.colors.almostWhite,
    },
    select: {
      justifyContent: 'left',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    label: {
      padding: 0,
      marginLeft: 0,
      fontSize: config.textSizes.normal,
      color: palette.primary.contrastText,
    },
    button: {
      color: config.colors.cowbellLight,
      fontWeight: config.weights.bold,
      whiteSpace: 'nowrap',
    },
    loadingContainer: {
      display: 'flex',
      padding: '2rem 4rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

const useSimpleSelectStyles = makeStyles({
  select: {
    '&:focus': {
      borderRadius: '5px',
    },
  },
});

const ACCOUNT_TYPE = {
  AGENCY: 'AGENCY',
  CLUSTER: 'CLUSTER',
  DIGITAL: 'DIGITAL',
  CARRIER: 'CARRIER',
  CUSTOMER: 'CUSTOMER',
  MSSP: 'MSSP',
};

const accountsFetchMap = {
  [ACCOUNT_TYPE.AGENCY]: fetchAgencies,
  [ACCOUNT_TYPE.CLUSTER]: fetchClusters,
  [ACCOUNT_TYPE.DIGITAL]: fetchDAs,
  [ACCOUNT_TYPE.CARRIER]: fetchCarriers,
  [ACCOUNT_TYPE.CUSTOMER]: fetchPagedAccounts,
  [ACCOUNT_TYPE.MSSP]: fetchMssps,
};

const schema = Yup.object().shape({
  accounts: Yup.array().of(
    Yup.object()
      .shape({
        id: Yup.string(),
        accountType: Yup.string().label('Account Type').required(),
        role: Yup.string().label('Account Role').required(),
        organization: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string().required('Account is required'),
          })
          .label('Account Organization'),
        teams: Yup.array()
          .of(Yup.string())
          .label('Teams')
          .when('accountType', {
            is: (value) => value === ACCOUNT_TYPE.AGENCY,
            then: (rule) => rule.required(),
          }),
      })
      .label('Accounts')
      .when({
        is: (value) => value.length > 0,
        then: (rule) => rule.required(),
      })
  ),
});

// Query function for fetching user's accounts by user email. Resolves to a derived list of accounts with their available teams
const accountsQueryFn = (userEmail) =>
  getUserAccountsById(userEmail)
    .then((resp) =>
      resp.data.map((account) => ({
        entityId: prettyId(),
        organization: { label: account.accountName, value: account.accountId },
        role: account.roleId,
        accountType: account.accountType,
        teams: account.teams,
        teamsOptions: null,
      }))
    )
    .then((derivedAccounts) => {
      const teamsPromisesMap = derivedAccounts.reduce((accum, account) => {
        if (account.accountType === ACCOUNT_TYPE.AGENCY) {
          return {
            ...accum,
            [account.organization.value]: getTeamsForAgency({
              agencyId: account.organization.value,
              size: 100,
            }),
          };
        }

        return accum;
      }, {});

      return Promise.all(Object.values(teamsPromisesMap)).then((responses) => {
        const teamsOptionsPerAgencyId = Object.keys(teamsPromisesMap).reduce(
          (accum, agencyId, idx) => {
            const teamsOptions = _.get(responses[idx], 'data.content', []).map(
              (team) => ({
                label: team.name,
                value: team.id,
              })
            );

            return { ...accum, [agencyId]: teamsOptions };
          },
          {}
        );

        return derivedAccounts.map((account) => {
          if (account.accountType === ACCOUNT_TYPE.AGENCY) {
            return {
              ...account,
              teamsOptions: teamsOptionsPerAgencyId[account.organization.value],
            };
          }

          return account;
        });
      });
    });

export const AddAccounts = withStyles(styles)(({ classes, close, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const simpleSelectStyles = useSimpleSelectStyles();
  const queryClient = useQueryClient();
  const { t, translationKeys } = useCowbellTranslations();

  const { handleSubmit, formState, ...methods } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, ...fieldArrayMethods } = useFieldArray({
    name: 'accounts',
    control: methods.control,
  });

  const rolesQueries = useQueries({
    queries: Object.keys(accountsFetchMap).map((accType) => ({
      queryKey: [accType, 'roles'],

      queryFn: () =>
        fetchRoles(accType)
          .then((resp) => ({
            accType,
            roles: reduceForSelect(resp.data),
          }))
          .catch(() =>
            enqueueSnackbar(
              'Sorry, we were unable to fetch the account roles data, please try again.',
              {
                variant: 'error',
              }
            )
          ),
      refetchOnWindowFocus: false,
    })),
  });

  const isFetchingRoles = rolesQueries.some((result) => result.isFetching);
  const rolesByAccountTypeMap = !isFetchingRoles
    ? rolesQueries.reduce(
        (accum, slice) => ({
          ...accum,
          [slice.data.accType]: slice.data.roles,
        }),
        {}
      )
    : {};

  const userAccountsQuery = useQuery(
    [data.email, 'accounts'],
    () => accountsQueryFn(data.email),
    {
      refetchOnWindowFocus: false,
      placeholderData: [],
      onError: () => {
        enqueueSnackbar(
          "We could not fetch the user's accounts, please try again.",
          { variant: 'error' }
        );
      },
      retry: 0,
    }
  );

  React.useEffect(() => {
    methods.setValue('accounts', userAccountsQuery.data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountsQuery.isFetched]);

  const handleOrganizationFetch = React.useCallback(
    (accountType) =>
      ({ input }, callback) => {
        accountsFetchMap[accountType]({ search: input })
          .then((resp) => {
            const selectItemGetter =
              accountType == ACCOUNT_TYPE.AGENCY
                ? getSelectItemAgency
                : undefined;

            callback(reduceForSelect(resp.data.content, selectItemGetter));
          })
          .catch(console.error.bind(console));
      },
    []
  );

  const handleOrganizationChange = React.useCallback(
    (entityId, accountType, onChange) => (selection) => {
      // Pass selection to the controller's change handler
      onChange(selection);

      // Fetch available teams for agency account
      if (accountType === ACCOUNT_TYPE.AGENCY) {
        getTeamsForAgency({ agencyId: selection.value, size: 100 })
          .then((resp) => {
            const options = reduceForSelect(resp.data.content);
            const accounts = methods.getValues('accounts').map((account) => {
              if (account.entityId === entityId) {
                return {
                  ...account,
                  teamsOptions: options,
                };
              }

              return account;
            });

            methods.setValue(`accounts`, accounts);
          })
          .catch(() =>
            enqueueSnackbar(
              "Sorry, we were unable to fetch the account's teams, please try again.",
              {
                variant: 'error',
              }
            )
          );
      }
    },
    [enqueueSnackbar, methods]
  );

  const handleAddRow = React.useCallback(
    (accountType) => () => {
      const newAccount = {
        entityId: prettyId(),
        organization: { label: '', value: '' },
        role: '',
        teams: [],
        teamsOptions: null,
        accountType,
      };

      fieldArrayMethods.append(newAccount);
    },
    [fieldArrayMethods]
  );

  const handleRemoveRow = React.useCallback(
    (idx) => () => {
      fieldArrayMethods.remove(idx);
    },
    [fieldArrayMethods]
  );

  const handleCancel = React.useCallback(() => {
    close();
  }, [close]);

  const onSubmit = React.useCallback(
    ({ ...formData }) => {
      const accountRoleMap = {};
      const accountTeamsMap = {};

      formData.accounts.forEach(
        ({ organization, teams, role, teamsOptions }) => {
          // Map organizations and associated roles
          _.merge(accountRoleMap, { [organization.value]: role });

          // Map organizations and associated teams
          if (!!teamsOptions && !!teams.length) {
            _.merge(accountTeamsMap, { [organization.value]: teams });
          }
        }
      );

      addExternalAccounts(data.email, { accountRoleMap, accountTeamsMap })
        .then(() => {
          close();
          enqueueSnackbar('Accounts Added Successfully.', {
            variant: 'success',
          });

          PubSub.publish('fetch-myAccounts', true);
          queryClient.invalidateQueries([data.email, 'accounts']);
        })
        .catch(() => {
          enqueueSnackbar(
            "There's been an error adding these accounts, Please try again.",
            { variant: 'error' }
          );
        });
    },
    [close, data.email, enqueueSnackbar, queryClient]
  );

  if (isFetchingRoles || userAccountsQuery.isFetching) {
    return <Box className={classes.loadingContainer}>Loading...</Box>;
  }

  const filterOptions = createFilterOptions({
    stringify: ({ label, value }) => `${label} ${value}`,
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={`${classes.dialog} "modal-title"`}>
          <MuiGrid container spacing={4}>
            <MuiGrid item sm={6}>
              <h2 className={classes.heading}>Primary Account</h2>
              <h2 className={classes.heading} style={{ fontWeight: 600 }}>
                {data.account.name}
              </h2>
            </MuiGrid>
            <MuiGrid item sm={6}>
              <h2 className={classes.heading}>Role</h2>
              <h2 className={classes.heading} style={{ fontWeight: 600 }}>
                {data.role.name}
              </h2>
            </MuiGrid>
          </MuiGrid>

          <h3 style={{ fontWeight: 'normal', fontSize: '1.33rem' }}>
            Additional Accounts
          </h3>
          {fields.map(({ id, entityId, accountType, teamsOptions }, index) => {
            return (
              <Box key={id} className={classes.container} mb="0.5rem">
                <MuiGrid container spacing={5}>
                  <MuiGrid item sm={2}>
                    <Box>
                      <Typography className={classes.label}>
                        Account Type
                      </Typography>
                      <Box mt="0.5rem">
                        <Typography>
                          {accountType === 'AGENCY'
                            ? t(translationKeys.agency).toUpperCase()
                            : accountType}
                        </Typography>
                      </Box>
                    </Box>
                  </MuiGrid>

                  <MuiGrid item sm={5}>
                    <InputLabelBase required className={classes.label}>
                      Additional Account
                    </InputLabelBase>

                    <Controller
                      name={`accounts.${index}.organization`}
                      control={methods.control}
                      render={(fieldData) => (
                        <ManagedTypeAheadBase
                          {...fieldData.field}
                          ref={null}
                          onChange={handleOrganizationChange(
                            entityId,
                            accountType,
                            fieldData.field.onChange
                          )}
                          required
                          onFetch={handleOrganizationFetch(accountType)}
                          error={_.get(
                            fieldData.fieldState,
                            'error.value.message',
                            ''
                          )}
                          filterOptions={filterOptions}
                        />
                      )}
                    />
                  </MuiGrid>

                  <MuiGrid item sm={5} className={classes.container}>
                    <Box>
                      <Controller
                        name={`accounts.${index}.role`}
                        control={methods.control}
                        render={(fieldData) => (
                          <SimpleSelect
                            {...fieldData.field}
                            ref={null}
                            classes={simpleSelectStyles}
                            required
                            options={rolesByAccountTypeMap[accountType]}
                            label="Role"
                            InputLabelProps={{ className: classes.label }}
                            error={_.get(
                              fieldData.fieldState,
                              'error.message',
                              ''
                            )}
                          />
                        )}
                      />
                    </Box>
                    {teamsOptions && (
                      <Box marginTop="0.5rem">
                        <Controller
                          name={`accounts.${index}.teams`}
                          control={methods.control}
                          render={(fieldData) => (
                            <SimpleSelect
                              {...fieldData.field}
                              ref={null}
                              classes={simpleSelectStyles}
                              required
                              multiple
                              options={teamsOptions}
                              label="Teams"
                              InputLabelProps={{ className: classes.label }}
                              error={_.get(
                                fieldData.fieldState,
                                `error.message`,
                                ''
                              )}
                            />
                          )}
                        />
                      </Box>
                    )}
                  </MuiGrid>
                </MuiGrid>
                <IconButton
                  size="small"
                  className={classes.remove}
                  onClick={handleRemoveRow(index)}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            );
          })}
          <Box mt="3rem">
            <MuiGrid item sm={2}>
              <ButtonMenu
                id="admin-add-accounts-menu"
                minWidth="12rem"
                label="Add"
                className="contrast-text"
              >
                <MuiMenuItem
                  className="contrast-text"
                  onClick={handleAddRow('AGENCY')}
                >
                  {t(translationKeys.agency)}
                </MuiMenuItem>
                <MuiMenuItem
                  className="contrast-text"
                  onClick={handleAddRow('DIGITAL')}
                >
                  Aggregator
                </MuiMenuItem>
                <MuiMenuItem
                  className="contrast-text"
                  onClick={handleAddRow('CARRIER')}
                >
                  Carrier
                </MuiMenuItem>
                <MuiMenuItem
                  className="contrast-text"
                  onClick={handleAddRow('CLUSTER')}
                >
                  Cluster
                </MuiMenuItem>
                <MuiMenuItem
                  className="contrast-text"
                  onClick={handleAddRow('MSSP')}
                >
                  MSSP
                </MuiMenuItem>
                <MuiMenuItem
                  className="contrast-text"
                  onClick={handleAddRow('CUSTOMER')}
                >
                  Policyholder
                </MuiMenuItem>
              </ButtonMenu>
            </MuiGrid>
          </Box>
        </DialogContent>

        <DialogActions>
          <CbButton
            styleName="cancel"
            onClick={handleCancel}
            buttonText="Cancel"
          />
          <CbButton
            styleName="ctaButton"
            buttonText="Add Accounts"
            type="submit"
            loading={formState.isSubmitting}
            disabled={formState.isSubmitting}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
});

export default AddAccounts;
