import React from 'react';

// lodash
import _ from 'lodash';

// materialUI
import { Card, Grid } from '@mui/material';

// components
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { PolicySummaryP100 } from '../components/PolicySummaryP100';
import PolicyFirmographic from './PolicyFirmographic';
import PolicyCowbellFactors from '../components/PolicyCowbellFactors';

// services / utils
import { fetchAccountRiskDetails } from '../accounts/AccountService';
import { CowbellFactorsMapping } from '../utility/CowbellFactorsMapping';
import { PubSub } from '../utils/eventUtils';
import { getPolicyDetails } from '../api/policies.api';
import type { ProductType } from '../types';
import { ProductTypes } from '../types';
import { PolicySummary } from '../components/PolicySummary';
import { useGetAccountDetailsQuery } from '../queries/useGetAccountDetailsQuery';
import { manageAPIError } from '../utils';
import Showable from '../components/Showable';

interface PolicyDetailsAccordionProps {
  policy: Policy;
}

interface Policy {
  product: ProductType;
  accountId: string;
  id: string;
}

const PolicyDetailsAccordion = ({ policy }: PolicyDetailsAccordionProps) => {
  const classes = useStyles();
  const riskData = _.omit(
    CowbellFactorsMapping,
    'insiderThreat',
    'companyAggregate',
    'industryAggregate'
  );

  const { accountData, policyDetails, riskDetails, isLoading, isError } =
    useGetPolicyAccordionDependencies(policy);

  React.useEffect(() => {
    if (policyDetails?.factors) {
      Object.entries(policyDetails?.factors).forEach((factor) => {
        riskData[factor[0]].value = factor[1];
      });
    }

    PubSub.publish('policy:table-row:read', {
      id: policyDetails?.id,
      isRead: true,
    });
  }, [policyDetails]);

  const factorsData = React.useMemo(() => {
    if (policyDetails?.cowbellRiskFactors) {
      return {
        ...policyDetails.cowbellRiskFactors.factors,
        companyAggregateCF: policyDetails.cowbellRiskFactors.companyAggregateCF,
        industryAggregateCF:
          policyDetails.cowbellRiskFactors.industryAggregateCF,
      };
    }
    return {};
  }, [policyDetails]);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '2rem' }}>Loading...</div>
    );
  }

  if (isError) {
    return (
      <div style={{ textAlign: 'center', padding: '2rem' }}>
        An Error occurred! Please contact cowbell support.
      </div>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item md={4} className={classes.backgroundColors}>
        <Card elevation={6} className={classes.card}>
          <PolicyFirmographic liveData={accountData} data={policyDetails} />
        </Card>
      </Grid>

      <Grid item md={4} className={classes.backgroundColors}>
        <Card elevation={6} className={classes.card}>
          <PolicyCowbellFactors
            riskDetails={riskData}
            data={factorsData}
            liveData={riskDetails}
          />
        </Card>
      </Grid>

      <Grid item md={4} className={classes.backgroundColors}>
        <Card elevation={6} className={classes.card}>
          <Showable show={policy.product === ProductTypes.p100}>
            <PolicySummaryP100
              policyData={policy}
              entireData={policyDetails}
              binder
            />
          </Showable>

          <Showable show={policy.product !== ProductTypes.p100}>
            <PolicySummary
              policyData={policy}
              entireData={policyDetails}
              binder
            />
          </Showable>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PolicyDetailsAccordion;

const useGetPolicyAccordionDependencies = (policy: Policy) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: accountData, isError: isAccountError } =
    useGetAccountDetailsQuery({
      accountId: policy.accountId,
    });

  const {
    data: policyDetails,
    isLoading,
    isError: isPolicyDetailsError,
  } = useQuery({
    queryKey: ['policy-accordion-details', policy.id, policy.product],
    queryFn: () => getPolicyDetails(policy.id, policy.product),
    select: (response: AxiosResponse) => response.data.data,
    keepPreviousData: true,
    onError: (error: AxiosError) => {
      enqueueSnackbar(manageAPIError(error), { variant: 'error' });
    },
  });

  const { data: riskDetails, isError: isRiskError } = useQuery({
    queryKey: ['risk-details', policy.accountId],
    queryFn: () => fetchAccountRiskDetails(policy.accountId),
    select: (response: AxiosResponse) => response.data,
    keepPreviousData: true,
    onError: (error: AxiosError) => {
      enqueueSnackbar(manageAPIError(error), { variant: 'error' });
    },
  });

  const isError = isAccountError || isPolicyDetailsError || isRiskError;

  return {
    accountData,
    policyDetails,
    riskDetails,
    isLoading,
    isError,
  };
};

const useStyles = makeStyles(({ palette }) => {
  return {
    container: {
      position: 'relative',
      overflow: 'hidden',
    },
    card: {
      height: '100%',
      padding: 10,
      background: palette.background.paper,
      position: 'relative',
    },
    backgroundColors: {
      padding: '4px',
      background: palette.background.default,
      marginBottom: '-6px',
    },
  };
});
