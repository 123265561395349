import React from 'react';
import { FormProvider } from 'react-hook-form';

// mui
import {
  Box as MuiBox,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import CbButtonBase from '../../../../components/Buttons/CbButton';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import { ScreenTitle } from './shared/ScreenTitle';

// utils and hocs
import { withFormController } from '../../../../components/hocs/forms';
import { withShowable } from '../../../_global/lib/withShowable';
import { surplusLinesStates } from '../constants/constants';

// services
import { LACompliance, MTCompliance } from './shared/Compliance';
import { useAgencyInformationDependencies } from './dependencies/useAgencyInformationDependencies';
import { SubjectivityCompletionDate as SubjectivityCompletionDateBase } from './shared/SubjectivityCompletionDate';
import { SurplusAutoAutoComplete } from '../../../../inbox/bind/SurplusBrokerAutoComplete';
import CowbellReferToUWButton from './shared/referral/CowbellReferToUWButton';
import LanguageForm from '../../../../i18n/forms/LanguageForm';
import { FormLanguageProvider } from '../../../../i18n/forms/FormLanguageProvider';

const TextField = withFormController(TextFieldBase);
const Grid = withShowable(MuiGrid);
const Box = withShowable(MuiBox);
const CbButton = withShowable(CbButtonBase);
const SubjectivityCompletionDate = withShowable(SubjectivityCompletionDateBase);

const AgencyInformation = ({ goBack, formState, ...props }) => {
  const classes = useStyles();

  const {
    eventHandlers,
    reactHookForm,
    languageFormState,
    ...agencyInfoState
  } = useAgencyInformationDependencies({
    formState,
    ...props,
  });

  const { gridLayoutSettings } = languageFormState;

  if (agencyInfoState.isSubjectivityDataLoading) {
    return null;
  }

  const { quoteSubjectivityId } = props.subjectivities[props.step];

  return (
    <Box className="contrast-text">
      <ScreenTitle
        subjectivitySubType={agencyInfoState.subjectivitySubType}
        title={agencyInfoState.allSubjectivityInformation.subjectivityTitle}
      />

      <h3>
        {agencyInfoState.allSubjectivityInformation.subjectivityDescription ??
          ''}
      </h3>

      <h3>{props.quoteDetails.agencyName}</h3>

      <FormLanguageProvider language={languageFormState.language}>
        <FormProvider {...reactHookForm.methods}>
          <form
            noValidate
            className={classes.form}
            onSubmit={reactHookForm.handleSubmit(eventHandlers.onSubmit)}
          >
            <Box className={classes.formFieldsContainer}>
              <Grid container spacing={2}>
                <Grid item sm={6} show={gridLayoutSettings.showAddress1}>
                  <LanguageForm.Address1
                    name="agencyMailingAddress1"
                    disabled={!agencyInfoState.isStepEditable}
                  />
                </Grid>

                <Grid item sm={6} show={gridLayoutSettings.showAddress2}>
                  <LanguageForm.Address2
                    name="agencyMailingAddress2"
                    disabled={!agencyInfoState.isStepEditable}
                  />
                </Grid>

                <Grid item sm={12} show={gridLayoutSettings.showAddress3}>
                  <LanguageForm.Address3
                    name="agencyMailingAddress3"
                    disabled={!agencyInfoState.isStepEditable}
                  />
                </Grid>

                <Grid item sm={6} show={gridLayoutSettings.showCity}>
                  <LanguageForm.City
                    name="agencyMailingCity"
                    disabled={!agencyInfoState.isStepEditable}
                  />
                </Grid>

                <Grid item sm={2} show={gridLayoutSettings.showState}>
                  <LanguageForm.State
                    name="agencyMailingState"
                    disabled={!agencyInfoState.isStepEditable}
                  />
                </Grid>

                <Grid
                  item
                  sm={gridLayoutSettings.showState ? 4 : 6}
                  show={gridLayoutSettings.showPostCode}
                >
                  <LanguageForm.PostalCode
                    name="agencyMailingZipCode"
                    disabled={!agencyInfoState.isStepEditable}
                  />
                </Grid>
              </Grid>

              <h3>Surplus Lines Broker Information</h3>

              <Grid item>
                <CheckBoxBase
                  name="isSurplusBroker"
                  label="Please check this box if you are the Surplus Lines Broker for this account."
                  onChange={eventHandlers.handleCheckBox}
                  checked={agencyInfoState.isSurplusLinesBroker}
                  disabled={!agencyInfoState.isStepEditable}
                />
              </Grid>

              <Grid item style={{ paddingBottom: '1rem' }}>
                <SurplusAutoAutoComplete
                  name="surplusBrokerOnFile"
                  disabled={
                    agencyInfoState.isSurplusLinesBroker ||
                    !agencyInfoState.isStepEditable
                  }
                  label="Surplus Lines Brokers on File"
                  placeholder="Please select a surplus lines broker from file or manually enter a new one"
                  state={props.quoteDetails.firmographicData?.state}
                  fullWidth
                  onChange={eventHandlers.handleBrokerOnFile}
                />
              </Grid>

              <Grid container spacing={4}>
                <Grid item sm={6} style={{ paddingBottom: 0 }}>
                  <TextField
                    name="surplusLinesBrokerFirstName"
                    label="Surplus Lines Broker First Name"
                    placeholder="Please enter surplus lines broker first name"
                    disabled={
                      agencyInfoState.isSurplusLinesBroker ||
                      !agencyInfoState.isStepEditable
                    }
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} style={{ paddingBottom: 0 }}>
                  <TextField
                    name="surplusLinesBrokerLastName"
                    label="Surplus Lines Broker Last Name"
                    placeholder="Please enter surplus lines broker last name"
                    disabled={
                      agencyInfoState.isSurplusLinesBroker ||
                      !agencyInfoState.isStepEditable
                    }
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} style={{ paddingBottom: '1rem' }}>
                <Grid
                  item
                  sm={2}
                  show={gridLayoutSettings.showState}
                  style={{ paddingTop: '2rem' }}
                  display="flex"
                  alignItems="center"
                >
                  <LanguageForm.State name="surplusLinesState" disabled />
                </Grid>
                <Grid item sm={gridLayoutSettings.showState ? 4 : 6}>
                  <TextField
                    name="surplusLicenseNumber"
                    label="License #"
                    placeholder="License number"
                    required
                    disabled={!agencyInfoState.isStepEditable}
                  />
                </Grid>

                <Grid item sm={6}>
                  <TextField
                    name="surplusLinesBrokerEmail"
                    label="Surplus Lines Broker Email"
                    placeholder="Please enter surplus lines broker email"
                    disabled={
                      agencyInfoState.isSurplusLinesBroker ||
                      !agencyInfoState.isStepEditable
                    }
                    required
                    fullWidth
                  />
                </Grid>
                <Grid
                  show={
                    agencyInfoState.showSurplusNoticeAttestation &&
                    agencyInfoState.isSurplusLinesBroker
                  }
                  item
                  style={{ paddingTop: 0, fontSize: '1.2rem' }}
                >
                  <Box
                    show={
                      agencyInfoState.surplusLinesState ===
                        surplusLinesStates.LA &&
                      agencyInfoState.isSurplusLinesBroker
                    }
                  >
                    <LACompliance
                      handlesurplusNoticeAttested={
                        eventHandlers.handlesurplusNoticeAttested
                      }
                      checked={agencyInfoState.isSurplusNoticeAttested}
                      disabled={!agencyInfoState.isStepEditable}
                    />
                  </Box>
                  <Box
                    show={
                      agencyInfoState.surplusLinesState ===
                        surplusLinesStates.MT &&
                      agencyInfoState.isSurplusLinesBroker
                    }
                  >
                    <MTCompliance
                      handlesurplusNoticeAttested={
                        eventHandlers.handlesurplusNoticeAttested
                      }
                      checked={agencyInfoState.isSurplusNoticeAttested}
                      disabled={!agencyInfoState.isStepEditable}
                    />
                  </Box>
                  <h4>
                    This serves as your legal signature and acknowledges the
                    information provided.
                  </h4>
                </Grid>
              </Grid>
            </Box>
            <SubjectivityCompletionDate
              allSubjectivityInformation={
                agencyInfoState.allSubjectivityInformation
              }
              show={agencyInfoState.isStepCompleted}
            />
            <Box paddingTop="1rem">
              <CbButton styleName="cancel" size="medium" onClick={goBack}>
                Back
              </CbButton>

              <CbButton
                styleName="ctaButton"
                size="medium"
                type="submit"
                disabled={
                  (agencyInfoState.showSurplusNoticeAttestation &&
                    !agencyInfoState.isSurplusNoticeAttested &&
                    agencyInfoState.isSurplusLinesBroker) ||
                  agencyInfoState.isCompletingStep
                }
                loading={agencyInfoState.isCompletingStep}
                show={!agencyInfoState.displaySendButton}
                style={{ marginRight: '0.833rem' }}
              >
                Next
              </CbButton>

              <CbButton
                styleName="ctaButton"
                size="medium"
                type="submit"
                show={agencyInfoState.displaySendButton}
                style={{ marginRight: '0.833rem' }}
              >
                Send
              </CbButton>

              <CowbellReferToUWButton
                quoteId={props.quoteDetails.id}
                subjectivityId={quoteSubjectivityId}
                productType={props.quoteDetails.uiData.productType}
              />
            </Box>
          </form>
        </FormProvider>
      </FormLanguageProvider>
    </Box>
  );
};

export default AgencyInformation;

const renderOptions = (option) => {
  let nextOption = option;
  if (typeof option === 'string') {
    nextOption = { label: option, value: option };
  }

  return (
    <MuiMenuItem key={nextOption.label} value={nextOption.value}>
      {nextOption.label}
    </MuiMenuItem>
  );
};

const useStyles = makeStyles(({ palette, config }) => {
  return {
    form: {
      maxWidth: '50rem',
    },
    label: {
      padding: '0.5rem',
      fontSize: config.textSizes.normal,
    },
  };
});
