import React from 'react';

import { DialogActions, DialogContent, Typography } from '@mui/material';

import CbButton from '../../../../Buttons/CbButton';

export const PromptWebhookStatusChange = ({ data, ...props }) => {
  const { enabled, handleStatusChange } = data;
  const handleStatusChangeInModal = () => {
    props.close();
    handleStatusChange();
  };

  return (
    <>
      <DialogContent>
        {enabled ? (
          <>
            <Typography color="primary">
              This webhook endpoint may be temporarily disabled so that it will
              not receive notifications until it is enabled again.
            </Typography>
            <br />
            <Typography color="primary">
              Cowbell Cyber will not retry any notifications that are generated
              while the endpoint is disabled.
            </Typography>
          </>
        ) : (
          <>
            <Typography color="primary">
              This webhook is disabled and no longer receives notifications.
              This may have been done automatically because we detected an
              extended period of failures.
            </Typography>
            <br />
            <Typography color="primary">
              If you’ve corrected the issue, you can re-enable the webhook
              endpoint here.
            </Typography>
            <br />
            <Typography color="primary">
              Cowbell Cyber will not retry any notifications that were generated
              in the intervening period, and if we continue to detect failures
              on this endpoint, we will disable the end point again.
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton onClick={props.close} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton
          type="submit"
          onClick={handleStatusChangeInModal}
          styleName="ctaButton"
        >
          {enabled ? 'Disable' : 'Enable'} webhook
        </CbButton>
      </DialogActions>
    </>
  );
};

export const PromptWebhookStatusChangeConfig = {
  PromptWebhookStatusChange: {
    component: PromptWebhookStatusChange,
    config: {
      fullWidth: true,
      inheritTheme: 'dark',
      title: 'Disable a webhook endpoint',
    },
  },
};
