import React from 'react';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

// mui
import {
  Box,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from '@mui/material';

// components
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import CbButton from '../../Buttons/CbButton';
import Showable from '../../Showable';
import LineItem from '../../../console/accounts/summary/modules/LineItem';
import { ManagedFetchingAutoComplete } from '../../inputs/autocomplete/ManagedFetchingAutoComplete';
import { PrimaryTypography as Typography } from '../../Typography';

// utils
import { reduceForSelect } from '../../../console/admin/users/UsersUtils';
import { getShortDateTimeFromUtc } from '../../../utils/csvUtils';
import { numberToCurrency } from '../../../utils/currency.utils';
import { ProductTypes } from '../../../types';
import { manageAPIError, yesNoOptions } from '../../../utils';

// apis
import {
  bulkApplyP100QuoteToAccounts,
  bulkApplyP250QuoteToAccounts,
  searchQuotes,
} from '../../../api';
import { SimpleSelect } from '../../inputs';
import { withFormController } from '../../hocs/forms';
import { getAgencyPrograms } from '../../../agencies/AgencyService';
import { useActor } from '../../hooks/useActor';

const Select = withFormController(SimpleSelect);

export const AccountsBulkQuote = ({ data = {}, ...props }) => {
  const actor = useActor();

  const isProgramFlaggingEnabled = actor.isCowbell;

  const { enqueueSnackbar } = useSnackbar();
  const [selectedQuote, setSelectedQuote] = React.useState(null);

  const formMethods = useForm({
    defaultValues: { isProgram: false },
    resolver: yupResolver(validationSchema),
  });

  const { data: programOptions = [] } = useQuery({
    queryKey: ['agency-programs', data.agencyId],
    queryFn: () => getAgencyPrograms(data.agencyId),
    select: (resp) =>
      resp.data.map((program) => ({ label: program.name, value: program })),
    enabled: actor.isCowbell,
  });

  const handleCancel = () => {
    props.close();
  };

  const handleBulkQuote = (formData) => {
    const { isProgram, program } = formData;

    const accountIdsForBulkQuoting = data?.rows?.map((row) => {
      return row.data.id;
    });

    const bulkApplyRequest =
      selectedQuote.product === ProductTypes.p100
        ? bulkApplyP100QuoteToAccounts
        : bulkApplyP250QuoteToAccounts;

    return bulkApplyRequest(selectedQuote.id, {
      data: {
        accountIds: accountIdsForBulkQuoting,
        agentFirstName: selectedQuote.agentFirstName,
        agentLastName: selectedQuote.agentLastName,
        agentEmail: selectedQuote.agentEmail,
        teamId: _.get(selectedQuote, 'teamIds[0]', null),
        teamName: _.get(selectedQuote, 'teamNames[0]', null),
        ...(isProgram && !!program ? { program } : {}),
      },
    })
      .then(() => {
        enqueueSnackbar('The bulk quote request has been sent successfully.', {
          variant: 'success',
        });
        props.close();
      })
      .catch((error) => {
        enqueueSnackbar(
          manageAPIError(
            error,
            'There was a problem in the bulk quoting process. Please try again later.'
          ),
          { variant: 'error' }
        );
      });
  };

  function fetchFunc({ search }) {
    return searchQuotes({ params: { search } }).then((res) => res.data.content);
  }

  function itemsToSelectOptionsTransformer(listItems) {
    return reduceForSelect(listItems, getSelectItemQuote);
  }

  function getSelectItemQuote(quote) {
    return { label: quote.quoteNumber, value: quote.id, meta: quote };
  }

  function handleQuoteNumberChange(option) {
    setSelectedQuote(option?.meta);
  }

  const queryKey = ['quote-search-results'];
  const uiQuote = formatQuote(selectedQuote);

  const formValues = formMethods.watch();

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleBulkQuote)}>
          <DialogContent>
            <Box display="flex" justifyContent="center">
              <Typography style={{ marginBottom: '1rem', fontSize: '1.2rem' }}>
                Enter the quote that you'd like to apply to these{' '}
                {data.rows.length} accounts
              </Typography>
            </Box>
            <ManagedFetchingAutoComplete
              name="quoteNumber"
              label="Quote Number"
              queryKey={queryKey}
              fetchFunc={fetchFunc}
              itemsToSelectOptionsTransformer={itemsToSelectOptionsTransformer}
              onChange={handleQuoteNumberChange}
            />
            <Showable show={!!selectedQuote}>
              <Grid
                container
                spacing={1}
                style={{ padding: '2rem', marginBottom: '1rem' }}
              >
                <Grid item xs={12}>
                  <LineItem>
                    <Typography variant="h6">Quote Summary</Typography>
                  </LineItem>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <LineItem>
                    <Typography>Quote Number</Typography>
                    <Typography>{selectedQuote?.quoteNumber}</Typography>
                  </LineItem>
                </Grid>
                <Grid item xs={12}>
                  <LineItem>
                    <Typography>Agency</Typography>
                    <Typography>{selectedQuote?.agencyName}</Typography>
                  </LineItem>
                </Grid>
                <Grid item xs={12}>
                  <LineItem>
                    <Typography>Effective Date</Typography>
                    <Typography>{uiQuote?.effectiveDate}</Typography>
                  </LineItem>
                </Grid>
                <Grid item xs={12}>
                  <LineItem>
                    <Typography>End Date</Typography>
                    <Typography>{uiQuote?.endDate}</Typography>
                  </LineItem>
                </Grid>
                <Grid item xs={12}>
                  <LineItem>
                    <Typography>Limit</Typography>
                    <Typography>{uiQuote?.limit}</Typography>
                  </LineItem>
                </Grid>
                <Grid item xs={12}>
                  <LineItem>
                    <Typography>Deductible</Typography>
                    <Typography>{uiQuote?.deductible}</Typography>
                  </LineItem>
                </Grid>
                <Grid item xs={12}>
                  <LineItem>
                    <Typography>Product</Typography>
                    <Typography>{selectedQuote?.product}</Typography>
                  </LineItem>
                </Grid>
              </Grid>
              <Showable
                show={isProgramFlaggingEnabled && !_.isEmpty(programOptions)}
              >
                <Select
                  name="isProgram"
                  label="Are these quotes a part of a program?"
                  options={yesNoOptions}
                />
              </Showable>
              <Showable show={isProgramFlaggingEnabled && formValues.isProgram}>
                <Select
                  name="program"
                  label="Program Name"
                  options={programOptions}
                />
              </Showable>
            </Showable>
          </DialogContent>
          <DialogActions>
            <CbButton onClick={handleCancel} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton
              styleName="ctaButton"
              type="submit"
              disabled={formMethods.formState.isSubmitting}
            >
              Bulk Quote
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    </>
  );
};

function formatQuote(quote) {
  if (!quote) return;
  return {
    effectiveDate: getShortDateTimeFromUtc(quote.effectiveDate),
    endDate: getShortDateTimeFromUtc(quote.endDate),
    limit: getLimit(quote),
    deductible: numberToCurrency(quote.deductible),
  };
}

function getLimit(quote) {
  const productType = quote.product;
  let limit;

  switch (productType) {
    case ProductTypes.p100:
      // eslint-disable-next-line
      limit = quote.initialRequestData.limit;
      break;
    case ProductTypes.p250:
      limit = quote.aggLimit;
      break;
    default:
      // eslint-disable-next-line
      limit = quote.limit;
      break;
  }

  return numberToCurrency(limit);
}

export const AccountsBulkQuoteConfig = {
  AccountsBulkQuote: {
    component: AccountsBulkQuote,
    // all mui related config
    config: {
      fullWidth: true,
      title: 'Select a Quote for Bulk Quoting',
    },
  },
};

const validationSchema = Yup.object().shape({
  program: Yup.object()
    .when('isProgram', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Program Name'),
});
