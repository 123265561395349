import * as yup from 'yup';

/**
 * @name fromPhone
 * @description Takes a phone in the form of a string and converts it to a number e.g. (555) 555 - 5555 => 5555555555
 *
 */
yup.addMethod(yup.number, 'fromPhone', function () {
  return this.transform(function (value, originalValue) {
    if (typeof originalValue === 'number') {
      return originalValue;
    }

    if (originalValue) {
      // eslint-disable-next-line
      value = originalValue.match(/[0-9]+/g);
      return value ? Number(value.join('')) : 0;
    }

    return undefined;
  });
});

yup.addMethod(yup.number, 'phone', function (message) {
  return this.test('is-phone', message, function (value) {
    const { createError, schema } = this;

    if (typeof value === 'undefined' && schema._exclusive.required) {
      return createError(
        message || { message: `${schema._label} is required` }
      );
    }

    if (typeof value === 'undefined' && !schema._exclusive.required) {
      return true;
    }

    if (typeof value !== 'undefined') {
      const { length } = `${value}`;

      return length < 10 || length > 11
        ? createError(
            message || {
              message: `${schema._label} is not a valid phone number`,
            }
          )
        : true;
    }
  });
});
