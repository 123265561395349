import React from 'react';

const SVG = ({
  style = {},
  fill = '#707070',
  width = '100%',
  className = '',
  viewBox = '0 0 50 50',
}) => (
  <svg
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>.cls-1{`fill:${fill};`}</style>
    </defs>
    <title>AddToList</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={fill}
          d="M25,50A25,25,0,1,1,50,25,25,25,0,0,1,25,50ZM25,3A22,22,0,1,0,47,25,22,22,0,0,0,25,3Z"
        />
        <g id="Polygon_50" data-name="Polygon 50">
          <path
            fill={fill}
            d="M36.28,24a2,2,0,0,1,.19,2.83,1.62,1.62,0,0,1-.19.18l-15,13.1a2,2,0,0,1-2.83-.19A2,2,0,0,1,18,38.59V12.41a2,2,0,0,1,2-2,2,2,0,0,1,1.32.49Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
