import React, { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { DragHandle as DragHandleIcon } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { TableContext } from '../context.table';

import CheckBoxBase from '../../../../inputs/Checkbox';

export const ColumnItemList = ({ items }) => {
  return items.map((item, idx) =>
    item.canToggle === false ? null : (
      <ColumnItem item={item} index={idx} key={item.id} />
    )
  );
};

const itemStyles = () => ({
  item: {
    display: 'flex',
    minWidth: 200,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const ColumnItem = withStyles(itemStyles)(({ classes, item, index }) => {
  const { toggleColumnVisibility } = useContext(TableContext);
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.item}
        >
          <CheckBoxBase
            name={item.id}
            defaultChecked={
              typeof item.show === 'undefined' || item.show === true
            }
            label={item.content}
            onChange={toggleColumnVisibility}
          />
          <DragHandleIcon />
        </div>
      )}
    </Draggable>
  );
});
