import React, { Component } from 'react';
import { Box } from '@mui/material';
import { styled, withStyles } from '@mui/styles';

import { compose } from 'redux';
import { CowbellFactorsMapping } from '../utility/CowbellFactorsMapping';
import { PubSub } from '../utils/eventUtils';
import withRouter from './hocs/withRouter';
import { Button } from './Buttons/Button';
import DescTooltip from './DescTooltip';
import { CowbellFactorLineStarGraph } from './graphs/line/CowbellFactorLineStarGraph';

const styles = (themeSelected) => {
  const { palette, config } = themeSelected;
  return {
    switch: {
      cursor: 'pointer',
    },
    policySideToggle: {
      color: palette.graphs.pie.secondaryBlue,
    },
    accountSideToggle: {
      color: palette.graphs.pie.primaryBlue,
    },
    liveOff: {
      fontSize: config.textSizes.petite,
      color: palette.text.icon,
    },
    liveOn: {
      fontSize: config.textSizes.petite,
      color: palette.primary.title,
    },
    iconStyle: {
      width: '24px !important',
      height: '24px !important',
      color: palette.components.globalHeader.contrastText,
      marginLeft: 5,
      marginRight: 5,
    },
    wrapper: {
      height: '100%',
      minHeight: 540,
      display: 'flex',
      flexDirection: 'column',
    },
    nodataMessage: {
      textAlign: 'center',
      padding: 80,
    },
    btnPrimary: {
      '&:hover': {
        background: palette.common.cowbellBlue,
        borderColor: palette.common.cowbellBlue,
      },
    },
  };
};

const btnprops = {
  variant: 'outlined',
  size: 'small',
  color: 'primary',
  style: { marginLeft: 8 },
};

class PolicyCowbellFactors extends Component {
  state = {
    liveOn: false,
  };

  render() {
    const { props } = this;
    const { classes, data, riskDetails, loading, noData, liveData } = props;
    const { liveOn } = this.state;

    if (loading) {
      return (
        <div style={{ alignItems: 'center' }} className={classes.wrapper}>
          <p>Loading</p>
        </div>
      );
    }

    if (noData || !data || !riskDetails) {
      return (
        <div className={classes.nodataMessage}>
          <p>
            We are working to get details for this account, please check back in
            shortly or click the button below
          </p>
          <Button
            show
            {...btnprops}
            onClick={this.reloadRiskData}
            className={classes.btnPrimary}
          >
            Refresh Data
          </Button>
        </div>
      );
    }

    const factors = riskDetails || {};

    const { companyAggregateValues, industryAggregateValues } =
      this.getAggregateValues(data);
    return (
      <>
        <Header>
          <Box
            display="flex"
            alignItems="center"
            className={classes.switch}
            onClick={this.handleSwitch}
          >
            <span className={classes.policySideToggle}>Policy</span>
            <img
              src={liveOn ? 'images/toggleOn.svg' : 'images/toggleOff.svg'}
              className={classes.iconStyle}
              alt=""
            />
            <span className={classes.accountSideToggle}>Latest</span>
          </Box>
        </Header>

        <Box height={25} textAlign="center" className="contrast-text">
          {liveOn &&
            'Showing latest Cowbell Factors, higher value means low level of risk'}
        </Box>

        <ul className="list--unstyled">
          <CowbellFactorListItem className="flex--spaced">
            <CowbellFactorListItemTitle className="contrast-text">
              Company Aggregate
              <Box component="span" ml="0.4166rem">
                <DescTooltip
                  tooltip={CowbellFactorsMapping.companyAggregate.tooltip}
                />
              </Box>
            </CowbellFactorListItemTitle>
            <CowbellFactorListItemFigure>
              <CowbellFactorLineStarGraph values={companyAggregateValues} />
            </CowbellFactorListItemFigure>
          </CowbellFactorListItem>

          <CowbellFactorListItem className="flex--spaced">
            <CowbellFactorListItemTitle className="contrast-text">
              Industry Aggregate
              <Box component="span" ml="0.4166rem">
                <DescTooltip
                  tooltip={CowbellFactorsMapping.industryAggregate.tooltip}
                />
              </Box>
            </CowbellFactorListItemTitle>
            <CowbellFactorListItemFigure>
              <CowbellFactorLineStarGraph values={industryAggregateValues} />
            </CowbellFactorListItemFigure>
          </CowbellFactorListItem>
        </ul>

        <h4 className="tertia-text">
          <u>COWBELL FACTORS </u>
          <sup>TM</sup> (CF)
        </h4>

        <ul className="list--unstyled">
          {Object.keys(factors).map((factorKey) => {
            const CBFactor = factors[factorKey];
            let values = [{ value: CBFactor.value }];
            if (liveOn) {
              values = [
                { value: CBFactor.value },
                { value: liveData.factors[factorKey] },
              ];
            }

            return (
              <CowbellFactorListItem className="flex--spaced" key={factorKey}>
                <CowbellFactorListItemTitle className="contrast-text">
                  {CBFactor.label}
                  <Box component="span" ml="0.4166rem">
                    <DescTooltip tooltip={CBFactor.tooltip} />
                  </Box>
                </CowbellFactorListItemTitle>
                <CowbellFactorListItemFigure>
                  <CowbellFactorLineStarGraph values={values} />
                </CowbellFactorListItemFigure>
              </CowbellFactorListItem>
            );
          })}
        </ul>
      </>
    );
  }

  getAggregateValues() {
    const { liveOn } = this.state;
    const { data, liveData } = this.props;

    let nextCompanyAggregateValues = [{ value: data.companyAggregateCF || 0 }];
    let nextIndustryAggregateValues = [
      { value: data.industryAggregateCF || 0 },
    ];

    if (liveOn) {
      nextCompanyAggregateValues = [
        ...nextCompanyAggregateValues,
        { value: liveData.companyAggregate || 0 },
      ];
      nextIndustryAggregateValues = [
        ...nextIndustryAggregateValues,
        { value: liveData.industryAggregate || 0 },
      ];
    }

    return {
      companyAggregateValues: nextCompanyAggregateValues,
      industryAggregateValues: nextIndustryAggregateValues,
    };
  }

  reloadRiskData = () => {
    if (this.props.loadRiskData) {
      this.props.loadRiskData();
    }
  };

  handleSwitch = () => {
    const { liveOn } = this.state;

    this.setState((current) => ({
      liveOn: !current.liveOn,
    }));

    PubSub.publish('policy:liveData', !liveOn);
  };
}

const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const CowbellFactorListItem = styled('li')({
  paddingTop: '1.25rem',
  paddingBottom: '1.25rem',
});

const CowbellFactorListItemTitle = styled('h4')(({ theme }) => ({
  margin: 0,
  fontSize: theme.config.textSizes.tertiaPrime,
  fontWeight: 400,
}));

const CowbellFactorListItemFigure = styled('figure')(() => ({
  width: '50%',
  margin: 0,
}));

export default compose(withRouter, withStyles(styles))(PolicyCowbellFactors);
