import * as Ramda from 'ramda';
import Numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  duotone,
  light,
  thin,
} from '@fortawesome/fontawesome-svg-core/import.macro';
import { getCurrencySymbolByLanguage, getRegionByProduct } from '../i18n/utils';
import { deriveCurrencyFormat } from '../i18n/currencies';
import type { ProductType } from '../types';
import type { Languages } from '../i18n/i18n.language-config';
import { languages } from '../i18n/i18n.language-config';
import CircleDollar from '../_assets/svg/CircleDollar.svg';

export function safeRoundTo2Decimals(n: number) {
  return Math.round((n + Number.EPSILON) * 100) / 100;
}

export const parseNumberTo2Decimals = Ramda.compose<
  [string | number],
  string,
  number,
  string
>(Ramda.invoker(1, 'toFixed')(2), parseFloat, String);

export function numberToCurrency(value: number, format = '$0,0') {
  return Numeral(value).format(format);
}

export function currencyToNumber(value: string) {
  return Numeral(value).value();
}

type CurrencyType = 'float';

export function toCurrency(type: CurrencyType | undefined, format?: string) {
  return (value: number) => {
    switch (type) {
      case 'float':
        return numberToCurrency(value, '$0,0.00');
      default:
        return numberToCurrency(value, format);
    }
  };
}

export const formatCurrencyToTenthsPlaceWithUnit = Ramda.partialRight(
  numberToCurrency,
  ['$0.0a']
);

export const regionCurrencyToTenthsPlace =
  (language: Languages) => (value: number) => {
    const symbol = getCurrencySymbolByLanguage(language);
    return `${symbol}${Numeral(value).format('0.0a')}`;
  };

export function formatCurrencyAsFloat(value: number | undefined) {
  // eslint-disable-next-line
  if (value == null || isNaN(value)) {
    return '-';
  }

  const parsedValue = Number(parseNumberTo2Decimals(value));
  if (parsedValue === 0) {
    return Numeral(parsedValue).format('$0');
  }

  return Numeral(parsedValue).format('$0,0.00');
}

export const formatPropAsCurrency = (prop: string) => {
  return Ramda.compose(formatCurrencyAsFloat, Ramda.prop(prop));
};

type Options = Parameters<typeof deriveCurrencyFormat>[2];

export const formatTableCurrencyByRegion =
  (formatOptions?: Options) =>
  (path: string | Ramda.Path) =>
  (row: { product?: ProductType; productType?: ProductType }) => {
    const productType = row.product || row.productType;
    const language = getRegionByProduct(productType as ProductType);

    const _path = Array.isArray(path) ? path : [path];

    return deriveCurrencyFormat(
      language,
      Ramda.path(_path, row),
      formatOptions
    );
  };

export const StatBarCurrencySymbol = ({ region }: { region: Languages }) => {
  if (region === languages['en-GB']) {
    return (
      <FontAwesomeIcon
        style={{
          width: '3rem',
          height: '3rem',
          marginRight: '0.41666667rem',
          color: 'rgb(145, 146, 164)',
        }}
        icon={light('circle-sterling')}
      />
    );
  }

  return (
    <CircleDollar
      style={{ width: '3.333333rem', marginRight: '0.41666667rem' }}
    />
  );
};
