import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import Moment from 'moment';

// mui
import { Box as MuiBox, styled } from '@mui/material';

// components
import { Link as LinkBase } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from '../../../../components/modals/v2/helpers/v2.modal.helpers';
import { ScreenTitle } from './shared/ScreenTitle';
import DocumentUploadBase from './shared/DocumentUploadBase';
import SignatureHolderConfirmationBase from './shared/SignatureHolderConfirmation';
import CbButton from '../../../../components/Buttons/CbButton';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import CheckBoxLabel from '../CheckBoxLabel';

// hooks and hocs
import useUploadZone from '../../../../components/fileUploader/v2/hooks/useUploadzone';
import { withShowable } from '../../../_global/lib/withShowable';

// services
import { signedDocumentConfirmationSchema } from './schemas/validationSchemas';
import {
  downloadQuoteDocuments,
  sendQuote,
} from '../../../../inbox/QuotesService';
import { downloadAWSDoc } from '../../../../policies/PolicyService';
import { saveBlobFile } from '../../../../utils/appUtils';

// constants
import {
  insuranceApplicationCheckboxLabel,
  insuranceAppNkll,
} from '../constants/constants';
import { BOUND_STATUS } from '../../../_statics/quote.statics';
import useDocUpload from './shared/useDocUpload';
import useGetSubjectivityProgress from './shared/useGetSubjectivityProgress';
import useGetSubjectivityDocs from './shared/useGetSubjectivityDocs';

import { useDocSignedDate } from './shared/useDocSignedDate';
import useCompleteQuoteSubjectivity from './shared/useCompleteQuoteSubjectivity';
import { SubjectivityCompletionDate } from './shared/SubjectivityCompletionDate';
import { determineIfStepIsEditable } from '../utils/bindQuoteWorkFlowUtils';
import CowbellReferToUWButton from './shared/referral/CowbellReferToUWButton';

const SignatureHolderConfirmation = withShowable(
  SignatureHolderConfirmationBase
);

const Box = withShowable(MuiBox);

const P100InsuranceApplication = ({
  goForward,
  goBack,
  quoteDetails,
  quoteProgress,
  step,
  completeStep,
  subjectivities,
  formState,
}) => {
  const {
    quoteSubjectivityId,
    subjectivitySubType,
    completed: isStepCompleted,
  } = subjectivities[step];

  const [isNkllAcknowledged, setIsNkllAcknowledged] = React.useState(false);
  const [isInsuranceAppAcknowledged, setIsInsuranceAppAcknowledged] =
    React.useState(isStepCompleted);
  const [isDownloadingApp, setIsDownloadingApp] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const isStepEditable = determineIfStepIsEditable({
    subjectivitySubType,
    status: quoteProgress.quoteAgencyStatus,
  });

  const { files, ...uploadZoneParams } = useUploadZone({
    docType: 'Application',
  });

  const { handleSubmit, setValue, ...methods } = useForm({
    resolver: yupResolver(signedDocumentConfirmationSchema(files ?? [])),
  });

  const { uploadDocument, isUploadingDoc } = useDocUpload({
    quoteDetails,
  });

  const { completeSubjectivity, isCompletingStep } =
    useCompleteQuoteSubjectivity({
      quoteId: quoteDetails.uiData.quoteId,
      productType: quoteDetails.uiData.productType,
    });

  React.useEffect(() => {
    if (quoteProgress.quoteAgencyStatus !== BOUND_STATUS) {
      enqueueSnackbar(
        'This quote must be bound before insurance application step can be unlocked.',
        {
          variant: 'error',
        }
      );
      goBack();
      return null;
    }
  }, [enqueueSnackbar, goBack, quoteProgress.quoteAgencyStatus]);

  const quoteId = quoteDetails.id;
  // at this point quote details cache may be stale
  React.useEffect(() => {
    queryClient.invalidateQueries(['quote-details', quoteId]);
  }, [queryClient, quoteId]);

  // Gathering Subj information
  const {
    data: allSubjectivityInformation = {},
    isFetching: isFetchingSubjectivityData,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId,
    quoteId: quoteDetails.id,
    productType: quoteDetails.uiData.productType,
  });

  React.useEffect(() => {
    if (
      allSubjectivityInformation?.hydratedInfo?.isNkllRequired &&
      allSubjectivityInformation.completed
    ) {
      setIsNkllAcknowledged(true);
    }
  }, [allSubjectivityInformation]);

  // Get any uploaded docs
  const { data: uploadedDocuments, isFetching: isFetchingUploadedDocs } =
    useGetSubjectivityDocs({
      quoteSubjectivityId,
      accountId: quoteDetails.uiData?.accountId,
      uploadedDocs: allSubjectivityInformation.data?.uploadedDocuments,
      isFetchingSubjectivityData,
    });

  useDocSignedDate({
    formState,
    files,
    setValue,
    uploadedDocuments,
    subjectivityData: allSubjectivityInformation?.data,
  });

  const isFormDirty = methods.formState.isDirty;

  React.useEffect(() => {
    if (files || isFormDirty) {
      setIsInsuranceAppAcknowledged(false);
    }
    if (allSubjectivityInformation.data) {
      setValue(
        'signeeFullName',
        allSubjectivityInformation.data.signeeFullName
      );
      setValue('signeeTitle', allSubjectivityInformation.data.signeeTitle);
    }
    if (allSubjectivityInformation.data && files) {
      setValue('signeeFullName', '');
      setValue('signeeTitle', '');
    }
  }, [allSubjectivityInformation.data, files, isFormDirty, setValue]);

  const handleSendToPolicyHolder = async () => {
    const payload = {
      quoteId: quoteDetails?.id,
      productType: quoteDetails.uiData.productType,
      customerEmail: quoteDetails.uiData.customerEmail,
      customerFirstName: quoteDetails.uiData.customerFirstName,
      customerLastName: quoteDetails.uiData.customerLastName,
      customerPhone: quoteDetails.uiData.customerPhone,
    };

    await sendQuote(payload);
  };

  const handleSendEmail = () => {
    Modal.show('EmailSignLinkConfirmation', {
      data: { quoteDetails, handleAsyncSubmit: handleSendToPolicyHolder },
      config: { title: 'Invite policy holder to digitally sign the app' },
    });
  };

  const createStateToggleHandler = (stateDispatch) => {
    return () => {
      stateDispatch((prevState) => !prevState);
    };
  };

  const onSubmit = (formValues) => {
    // if nkll wasnt acknowledged but is required, show modal
    if (
      !isNkllAcknowledged &&
      allSubjectivityInformation?.hydratedInfo?.isNkllRequired
    ) {
      Modal.show('P100NKLLUploadModal', {
        data: {
          quoteDetails,
          appDocName: formValues[`fileName-0`],
          appFile: files[0],
          dateMin:
            allSubjectivityInformation?.hydratedInfo
              ?.signatureValidRangeStartDate,
          dateMax:
            allSubjectivityInformation?.hydratedInfo
              ?.signatureValidRangeEndDate,
          ...formValues,
          quoteSubjectivityId,
          completeStep,
          goForward,
          step,
          nkllDownloadPath:
            allSubjectivityInformation?.hydratedInfo?.nkllTemplatePath,
        },
        config: { title: 'Upload NKLL' },
      });
      return;
    }

    if (!files) {
      const uploadedDocumentsPayload = uploadedDocuments.map(
        ({ id }, index) => ({
          docId: id,
          docSignedOn: formValues[`docSignedOn-${index}`],
        })
      );
      const params = {
        quoteSubjectivityId,
        goForward,
        payload: {
          uploadedDocuments: uploadedDocumentsPayload,
          userAttested: isInsuranceAppAcknowledged,
          nkllIsIncluded:
            allSubjectivityInformation?.data?.nkllIsIncluded ||
            isNkllAcknowledged,
          ...formValues,
        },
      };
      return completeSubjectivity(params);
    }

    const applicationUploadParams = {
      formValues,
      files,
      userAttested: true,
      quoteDetails,
      completeStep,
      goForward,
      step,
      docType: 'Application',
      quoteSubjectivityId,
      nkllIsIncluded: isNkllAcknowledged,
    };

    // TODO: This api doesnt work yet. Adding here for future use!
    return uploadDocument(applicationUploadParams);
  };

  const handleGoNext = () => {
    if (!files && !uploadedDocuments?.length) {
      return goForward();
    }
  };

  const handleDocumentDownload = () => {
    setIsDownloadingApp(true);
    const {
      accountId,
      created,
      isEndorsement = false,
      companyName,
      quoteNumber,
    } = quoteDetails;

    const createdUnix = Moment(created).unix();

    downloadQuoteDocuments(accountId, quoteId, createdUnix, isEndorsement)
      .then(({ data: url }) => {
        enqueueSnackbar(
          'Application download started, file will be available shortly',
          {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'info',
          }
        );

        downloadAWSDoc(url).then((docResponse) => {
          saveBlobFile(
            docResponse,
            `${companyName}-${quoteNumber}-Cowbell-Application`,
            '.pdf'
          );
        });
      })
      .catch(() => {
        enqueueSnackbar('Unable to download the application', {
          variant: 'error',
        });
      })
      .finally(() => setIsDownloadingApp(false));
  };

  if (isFetchingSubjectivityData || isFetchingUploadedDocs) {
    return null;
  }

  return (
    <Box className="contrast-text" width="55%">
      <ScreenTitle
        subjectivitySubType={subjectivitySubType}
        title="Insurance Application"
      />

      <h3>
        Please upload Cowbell Application signed by intended Policyholder and
        dated within 30 days prior to binding.
      </h3>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DocumentUploadBase
            uploadZoneParams={uploadZoneParams}
            files={files}
            isUploadingDoc={isUploadingDoc}
            isStepEditable={isStepEditable}
            uploadedDocs={uploadedDocuments}
            docType="Application"
            datePickerProps={{
              displayDatePicker: true,
              min: allSubjectivityInformation?.hydratedInfo
                ?.signatureValidRangeStartDate,
              max: allSubjectivityInformation?.hydratedInfo
                ?.signatureValidRangeEndDate,
            }}
          />
          <SignatureHolderConfirmation
            show={files || Boolean(allSubjectivityInformation?.data)}
            disableFormFields={false}
          />

          <Box show={isStepEditable && !files} paddingBottom="2rem">
            <h3>Don't have the signed Application?</h3>

            <CbButton
              styleName="ctaButton"
              size="large"
              onClick={handleDocumentDownload}
              loading={isDownloadingApp}
              disabled={isDownloadingApp}
            >
              Download prefilled app
            </CbButton>
            <CbButton
              style={{ marginLeft: '1rem' }}
              styleName="ctaButton"
              size="large"
              onClick={handleSendEmail}
            >
              Send an Email to Policyholder
            </CbButton>
          </Box>

          <Box
            show={files || !!uploadedDocuments?.length}
            paddingBottom="0.5rem"
          >
            <Box
              show={!!allSubjectivityInformation?.hydratedInfo?.isNkllRequired}
            >
              <CheckBoxBase
                name="nkllAcknowledgement"
                label={insuranceAppNkll}
                onChange={createStateToggleHandler(setIsNkllAcknowledged)}
                checked={isNkllAcknowledged}
                disabled={
                  allSubjectivityInformation?.data?.userAttested &&
                  !files &&
                  !isFormDirty
                }
              />
            </Box>

            <CheckBoxBase
              name="insuranceApplicationAcknowledgement"
              label={
                <CheckBoxLabel label={insuranceApplicationCheckboxLabel} />
              }
              onChange={createStateToggleHandler(setIsInsuranceAppAcknowledged)}
              checked={isInsuranceAppAcknowledged}
              disabled={
                allSubjectivityInformation?.data?.userAttested &&
                !files &&
                !isFormDirty
              }
            />

            <p>
              <i>
                NOTE: If the insured has made any changes to the application you
                are uploading, please do not proceed. <br /> Process the
                insured's changes by requoting the risk{' '}
                <Link
                  to={`/agency/requestQuote/P100/${quoteDetails.accountId}/details`}
                >
                  here
                </Link>
                .
              </i>
            </p>
          </Box>
          <SubjectivityCompletionDate
            allSubjectivityInformation={allSubjectivityInformation}
            show={isStepCompleted}
          />
          <CbButton styleName="cancel" size="medium" onClick={goBack}>
            Back
          </CbButton>

          <CbButton
            styleName="ctaButton"
            size="medium"
            type="submit"
            onClick={handleGoNext}
            loading={isUploadingDoc || isCompletingStep}
            disabled={
              !isInsuranceAppAcknowledged ||
              isUploadingDoc ||
              isCompletingStep ||
              (!files && !uploadedDocuments?.length)
            }
            style={{ marginRight: '0.833rem' }}
          >
            Next
          </CbButton>

          <CowbellReferToUWButton
            quoteId={quoteDetails.id}
            subjectivityId={quoteSubjectivityId}
            productType={quoteDetails.uiData.productType}
          />
        </form>
      </FormProvider>
    </Box>
  );
};

export default P100InsuranceApplication;

const Link = styled(LinkBase)(({ theme }) => ({
  textDecoration: 'underline',
  cursor: 'pointer',
  color: theme.config.colors.cowbellBlue,
  '&:hover': {
    opacity: 0.7,
  },
}));
