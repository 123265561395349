import _ from 'lodash';
import React from 'react';

import { TablePagination as MuiTablePagination } from '@mui/material';

export const TablePagination = React.memo(
  ({
    onPageChange,
    changePageSize,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    totalRows,
  }) => {
    return (
      <MuiTablePagination
        count={totalRows}
        rowsPerPage={Number(rowsPerPage)}
        page={Number(page)}
        onPageChange={onPageChange}
        onRowsPerPageChange={changePageSize}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    );
  },
  (prev, next) => {
    const pickKeys = ['page', 'size'];
    const _prev = _.pick(prev.q, pickKeys);
    const _next = _.pick(next.q, pickKeys);

    if (_.isEqual(_prev, _next)) {
      return false;
    }

    if (_.eq(prev.totalRows, next.totalRows)) {
      return false;
    }

    // will not update
    return true;
  }
);

TablePagination.displayName = 'TablePagination';

TablePagination.defaultProps = {
  page: 0,
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  totalRows: 0,
  onPageChange: () => null,
  handleChangeRowsPerPage: () => null,
};
