import { useHistory } from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  useTheme,
} from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';
import CbButton from '../../Buttons/CbButton';

// icons

interface Props {
  open: boolean;
  close: () => void;
  data: {
    profileMfaUrl: string;
  };
}

export const RedirectToMfa = ({ data, open, close }: Props) => {
  const { palette } = useTheme();
  const history = useHistory();

  const onSubmit = () => {
    close();
    history.push(data?.profileMfaUrl);
  };

  return (
    <BlurredDialog open={open}>
      <DialogTitle>
        <Box display="flex" whiteSpace="nowrap" alignItems="center">
          <WarningIcon
            fontSize="large"
            style={{ color: palette.warning.main, marginRight: '1rem' }}
          />
          Multi-Factor Authentication Not Enabled
        </Box>
      </DialogTitle>
      <DialogContent className="contrast-text" style={{ padding: '2rem 5rem' }}>
        Please enable multi-factor authentication before continuing to the
        Cowbell Platform.
      </DialogContent>
      <DialogActions>
        <CbButton type="submit" onClick={onSubmit} styleName="ctaButton">
          Set Up MFA
        </CbButton>
      </DialogActions>
    </BlurredDialog>
  );
};

export const RedirectToMfaConfig = {
  RedirectToMfa: {
    component: RedirectToMfa,
    config: {
      override: true,
    },
  },
};

const BlurredDialog = styled(Dialog)(() => ({
  backdropFilter: 'blur(0.333rem)',
}));
