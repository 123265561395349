// mui
import {
  Box,
  Card,
  Divider,
  styled,
  Typography as TypographyBase,
} from '@mui/material';
// components
import { Flexbox } from '../../../../../../components/layout/Flexbox';
import DescTooltip from '../../../../../../components/DescTooltip';
import { CancelledPolicyDrawerActions } from './CancelledPolicyDrawerActions';
// utils
import { formatCancellationPolicy } from '../policy-details.utils';

export const CancelledPolicySummary = ({ policy = {} }) => {
  const {
    uiTotalPremium,
    cancellationDescription,
    uiCancellationReason,
    uiRefund,
  } = policy;

  const { uiCancellationDate, uiCancellationEffectiveDate } =
    formatCancellationPolicy(
      policy?.cancellationDate,
      policy?.cancellationTriggerDate
    );

  return (
    <>
      <PolicySummaryCard square>
        <Flexbox>
          <Typography variant="subtitle2" color="primary">
            Cancellation Information
          </Typography>
          <CancelledPolicyDrawerActions data={policy} />
        </Flexbox>
        <Divider style={{ width: '100%' }} />
        <Box>
          <Flexbox alignItems="flex-start">
            <Box
              width="50%"
              marginRight={1}
              style={{ borderRight: '.1rem solid' }}
            >
              <div style={{ paddingRight: '1rem' }}>
                <Flexbox>
                  <Typography variant="caption">Canceled On</Typography>
                  <Typography>{uiCancellationDate}</Typography>
                </Flexbox>

                <Flexbox>
                  <Typography>Cancellation Effective</Typography>
                  <Typography>{uiCancellationEffectiveDate}</Typography>
                </Flexbox>
                <Flexbox />
              </div>
            </Box>
            <Box width="50%" marginLeft={1}>
              <Flexbox>
                <Typography>Cancellation Reason</Typography>
                <Typography>{uiCancellationReason ?? '-'}</Typography>
              </Flexbox>
              <Flexbox>
                <Typography>Total Premium</Typography>
                <Typography>{uiTotalPremium ?? '-'}</Typography>
              </Flexbox>
              <Flexbox>
                <Typography>
                  Premium Refund{' '}
                  <DescTooltip tooltip="Refund does not include any policy fee and is pro-rated for days of coverage provided." />
                </Typography>
                <Typography>{uiRefund ?? '-'}</Typography>
              </Flexbox>
            </Box>
          </Flexbox>
        </Box>
      </PolicySummaryCard>
      <PolicySummaryCard>
        <Divider style={{ width: '100%' }} />
        <Box>
          <Typography variant="subtitle2" color="primary">
            Cancellation Description
          </Typography>
        </Box>
        <Divider style={{ width: '100%' }} />
        <Box width="100%" marginRight={1}>
          <Flexbox>
            <Typography>
              {cancellationDescription ?? 'Description Unavailable'}
            </Typography>
          </Flexbox>
        </Box>
      </PolicySummaryCard>
    </>
  );
};

const Typography = ({ children, ...props }) => (
  <TypographyBase variant="caption" {...props}>
    {children}
  </TypographyBase>
);

const PolicySummaryCard = styled(Card)(({ theme: { palette } }) => ({
  padding: '1rem',
  backgroundColor: palette.background.default,
}));
