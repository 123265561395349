import React from 'react';

const SVG = ({
  id = 'policies',
  style = {},
  fill = '#9192a4',
  width = '100%',
  className = '',
  viewBox = '0 0 30 30',
  title = 'Policies',
}) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .svg-policies-1 { fill: none; }
          .${id}.svg-fill-policies { fill: ${fill}; }
        `}
      </style>
    </defs>
    <title>Policies</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Isolation_Mode" data-name="Isolation Mode">
        <rect className="svg-policies-1" width="30" height="30" />
        <rect
          className={`${id} svg-fill-policies`}
          x="20.6"
          y="22.4"
          width="1.44"
          height="1.43"
          rx="0.29"
        />
        <path
          className={`${id} svg-fill-policies`}
          d="M14.55,22.4a.32.32,0,0,1-.23-.09l-1.41-1.39a.33.33,0,0,0-.47,0l-1,1a.32.32,0,0,1-.46,0l-1-1a.32.32,0,0,0-.46,0l-.91.9h0l-.6.6a.3.3,0,0,0,0,.41l.6.6a.31.31,0,0,0,.42,0l.07-.07h0l.66-.65L11,23.9a.33.33,0,0,0,.47,0l1-1a.33.33,0,0,1,.47,0l.81.81a.33.33,0,0,0,.23.09h5.21v0a.28.28,0,0,0,.24-.27V22.7a.29.29,0,0,0-.24-.28v0Z"
        />
        <path
          className={`${id} svg-fill-policies`}
          d="M25.31,8.07,19.89,2.69a.67.67,0,0,0-.47-.19H5.16a.66.66,0,0,0-.66.66V26.84a.66.66,0,0,0,.66.66H24.84a.66.66,0,0,0,.66-.66V8.53A.66.66,0,0,0,25.31,8.07Zm-1.5,17.74-17.62,0,0-21.66,12.14,0V8.89a.66.66,0,0,0,.65.66l4.86,0Z"
        />
        <path
          className={`${id} svg-fill-policies`}
          d="M14.15,17.26a.85.85,0,0,0,.85-.85.86.86,0,0,0-.85-.86H8.39a.86.86,0,1,0,0,1.71Z"
        />
        <path
          className={`${id} svg-fill-policies`}
          d="M18.15,11.81H8.39a.85.85,0,1,0,0,1.7h9.76a.85.85,0,0,0,0-1.7Z"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
