import {
  DialogContent,
  DialogActions,
  DialogContentText,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import CbButton from '../../../components/Buttons/CbButton';

export const ConfirmStep = ({ onClose }) => {
  const theme = useTheme();
  return (
    <>
      <DialogContent style={{ padding: '3rem 5rem' }}>
        <DialogContentText style={{ fontSize: '1.6rem', fontWeight: 'bold' }}>
          Your user migration request has been successfully initialized!
        </DialogContentText>
        <DialogContentText>
          The migration process will move all of the selected users' accounts to
          the destination agency as well as any associated quotes, binders,
          policies, claims, invoices, and commissions. User migration may take
          some time, please be patient while we fulfill your request.
        </DialogContentText>
        <DialogContentText>
          The broker will be notified by email once the migration is complete.
          You can monitor migration statuses in the{' '}
          <Link
            to="/admin/agencies/user-migrations"
            onClick={onClose}
            style={{
              color: theme.config.colors.cowbellBlue,
              fontWeight: 'bold',
            }}
          >
            User Migrations Table
          </Link>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CbButton onClick={onClose} styleName="cancel">
          Close
        </CbButton>
      </DialogActions>
    </>
  );
};
