// lodash
import _ from 'lodash';

// components
import type { InputProps } from '@mui/material';
import {
  Input as MuiInput,
  InputAdornment as MuiInputAdornment,
  styled,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

interface Props extends Omit<InputProps, 'onChange'> {
  delay?: number;
  onChange: (value: string) => void;
}

export const SearchField = ({ delay = 200, onChange, ...props }: Props) => {
  const handleChange = _.debounce<(value: string) => void>((value) => {
    if (typeof onChange === 'function') {
      onChange(value);
    }
  }, delay);

  return (
    <Input
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
      placeholder="Search"
      {...props}
      onChange={(event) => handleChange(event.target.value)}
    />
  );
};

const Input = styled(MuiInput)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 5,
  width: '100%',

  '& input': {
    border: 'none',
    backgroundColor: 'transparent',

    '&:focus': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.contrastText,
    },
  },
}));

const InputAdornment = styled(MuiInputAdornment)({
  margin: 0,
  paddingRight: 11,
});
