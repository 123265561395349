import React from 'react';
import Moment from 'moment';
import _ from 'lodash';

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

// service
import { extendPaymentDueDate } from '../../../../api/NOCService';
import { getInvoices } from '../../../../admin/invoices.bills.service';

// components
import { ExtendPaymentConfirmation } from './extendPayment/ExtendPaymentConfirmation';
import { ExtendPaymentNOC } from './extendPayment/ExtendPayment';

import { COWBELL_POLICY } from '../../../tables/table_constants';
import { BillingInvoiceStatus } from '../../../../console/_statics/billing-invoice.statics';
import { useAPIErrorHandler } from '../../../hooks/useAPIErrorHandler';

export const ExtendPaymentNOCModal = ({ close, data }) => {
  const queryClient = useQueryClient();
  const handleAPIError = useAPIErrorHandler();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = React.useState(1);
  const [payload, setPayload] = React.useState({});
  const [paymentDueDate, setPaymentDueDate] = React.useState(0);

  React.useEffect(() => {
    // get open invoices by policy number
    getInvoices({
      search: data?.policyNumber,
      sort: 'created,desc',
      f: {
        status: {
          co: BillingInvoiceStatus.OPEN,
        },
      },
    }).then((resp) => {
      setPaymentDueDate(_.get(resp, 'data.content[0].dueDate'));
    });
  }, [data, paymentDueDate]);

  const handleExtendPayment = (formData) => {
    setPage(2);
    setPayload(formData);
  };

  const onSubmit = (finalizedData) => {
    const policyId = _.get(data, 'id');

    const paymentDueDatePayload = {
      dueDate: Moment(_.get(finalizedData, 'newDueDate')).unix() * 1000,
    };

    extendPaymentDueDate({ policyId }, paymentDueDatePayload)
      .then(() => {
        queryClient.invalidateQueries([COWBELL_POLICY]);
        enqueueSnackbar('Payment due date has been extended.', {
          variant: 'success',
        });
        close();
      })
      .catch(handleAPIError('Not able to extend policy payment due date.'));
  };

  if (paymentDueDate === 0) {
    return null;
  }

  return (
    <div>
      {page === 1 && (
        <ExtendPaymentNOC
          handleExtendPayment={handleExtendPayment}
          data={data}
          paymentDueDate={paymentDueDate}
          close={close}
        />
      )}
      {page === 2 && (
        <ExtendPaymentConfirmation
          submission={onSubmit}
          data={data}
          payload={payload}
          paymentDueDate={paymentDueDate}
          close={close}
        />
      )}
    </div>
  );
};

export const ExtendPaymentNOCConfig = {
  ExtendPaymentNOCModal: {
    component: ExtendPaymentNOCModal,
    config: {
      fullWidth: true,
      maxWidth: 'md',
      minWidth: 'sm',
    },
  },
};
