import React from 'react';
import Numeral from 'numeral';
import { useSnackbar } from 'notistack';
import {
  Box,
  DialogActions,
  DialogContent,
  Button,
  styled,
  Typography as TypographyBase,
  Paper,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import type { AxiosError } from 'axios';
import { useQuoteCreationFunctions } from '../../../accounts/accountUtils';

// mui

// components
import CbButton from '../../Buttons/CbButton';
import type { AccountDto, ProductType } from '../../../types';

// services
import {
  bulkRequestQuotesFromCart,
  deleteQuoteFromCartList,
} from '../../../api';
import { useGetQuoteCartList } from '../../../console/agencies/quotes/PrimeX/hooks/useGetQuoteCartList';
import { useOptimisticMutation } from '../../hooks/tanstack-query/useOptimisticUpdate';
import { manageAPIError } from '../../../utils';
import type { GetQuoteCartListResponse } from '../../../api/quotes.api.types';
import { toUniversalUtcDate } from '../../../utils/date.utils';
import {
  getCurrencySymbolByProduct,
  getRegionByProduct,
} from '../../../i18n/utils';
import { useCowbellTranslations } from '../../../i18n/translations';
import { usePersona } from '../../hooks/usePersona';
import { PERSONA_TYPES } from '../../../console/_statics/persona.statics';
import { Show } from '../../Show';
import { deriveCurrencyFormat } from '../../../i18n/currencies';

interface QuoteCartProps {
  data: {
    accountData?: AccountDto;
    accountId: string;
    productType: ProductType;
    uiSettings: Record<string, boolean>;
  };
  close: () => void;
}

const QuoteCartModal = ({ data: modalProps, close }: QuoteCartProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();
  const { type } = usePersona();
  const { accountId, productType, accountData, uiSettings } = modalProps;
  const currencySymbol = getCurrencySymbolByProduct(productType);
  const region = getRegionByProduct(productType);

  const { t, translationKeys } = useCowbellTranslations();

  const { detemineAgentOnQuoteCreation, getAgencyId, determineTeamIdOfAgent } =
    useQuoteCreationFunctions(accountData);

  const { enqueueSnackbar } = useSnackbar();

  const removeQuoteFromCartMutation =
    useOptimisticMutation<GetQuoteCartListResponse>()({
      queryKey: ['request-quote-cart', accountId, productType],
      mutationFn: (quoteId: string) => {
        return deleteQuoteFromCartList(productType, quoteId, {
          params: getAgencyId() ? { agencyId: getAgencyId() } : {},
        });
      },
      syncCacheUpdate: (previousCache, quoteId) => {
        return {
          ...previousCache,
          quoteList: previousCache?.quoteList?.filter(
            (quote) => quote.id !== quoteId
          ),
          count: previousCache.count - 1,
        };
      },
      onSuccess: ({ data }) => {
        enqueueSnackbar(
          `Successfully removed quote ${data.quote?.quoteNumber}`,
          {
            variant: 'success',
          }
        );
      },
      onError: () => {
        enqueueSnackbar('Failed to remove quote, please try again', {
          variant: 'error',
        });
      },
    });

  const { data: cartData, isInitialLoading } = useGetQuoteCartList({
    accountData: modalProps.accountData,
    accountId: modalProps.accountId,
    productType: modalProps.productType,
  });

  const handleRequestQuotes = () => {
    setIsLoading(true);

    bulkRequestQuotesFromCart(productType, accountId, {
      params: getAgencyId() ? { agencyId: getAgencyId() } : {},
      data: {
        ...detemineAgentOnQuoteCreation(),
        teamId: determineTeamIdOfAgent()[0],
      },
    })
      .then(() => {
        enqueueSnackbar('Quotes requested successfully', {
          variant: 'success',
        });
        close();
        setTimeout(() => {
          history.push(pathMapping[type as keyof typeof pathMapping]);
        }, 800);
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar(
          manageAPIError(error, 'Unable to request quotes at this time.'),
          { variant: 'error' }
        );
      })
      .finally(() => setIsLoading(false));
  };

  if (isInitialLoading) {
    return null;
  }

  return (
    <>
      <DialogContent style={{ padding: '2rem' }}>
        {cartData?.count === 0 ? (
          <Box
            className="primer-text contrast-text"
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding="2rem 0"
          >
            No quotes have been added to the cart yet.
          </Box>
        ) : (
          <Paper
            elevation={3}
            style={{ borderRadius: '6px', overflow: 'hidden' }}
          >
            <HeaderContainer className="contrast-text">
              <Typography
                variant="subtitle2"
                style={{
                  width: uiSettings.renderCommissionSlider ? '20%' : '30%',
                }}
              >
                Quote Number
              </Typography>
              <Typography variant="subtitle2" style={{ width: '10%' }}>
                Coverage Selection
              </Typography>
              <Typography variant="subtitle2" style={{ width: '10%' }}>
                Aggregate Limit
              </Typography>
              <Typography variant="subtitle2" style={{ width: '10%' }}>
                {t(translationKeys.retention)} Amount
              </Typography>
              <Typography variant="subtitle2" style={{ width: '12%' }}>
                Indicated <br /> Pricing
              </Typography>
              <Typography variant="subtitle2" style={{ width: '15%' }}>
                Coverage <br /> Start Date
              </Typography>

              <Show when={uiSettings.renderCommissionSlider}>
                <Typography variant="subtitle2" style={{ width: '10%' }}>
                  Commission
                </Typography>
              </Show>
              <Typography
                variant="subtitle2"
                style={{ width: '8%', textAlign: 'center' }}
              >
                Delete
              </Typography>
            </HeaderContainer>
            {cartData?.quoteList?.map((quote) => {
              return (
                <Container key={quote?.quoteNumber}>
                  <Typography
                    variant="body2"
                    style={{
                      width: uiSettings.renderCommissionSlider ? '20%' : '30%',
                    }}
                  >
                    {quote.quoteNumber}
                  </Typography>
                  <Typography style={{ width: '10%' }}>
                    {quote.presets}
                  </Typography>
                  <Typography variant="body2" style={{ width: '10%' }}>
                    {currencySymbol}
                    {Numeral(quote.aggLimit).format('0,00a')}
                  </Typography>
                  <Typography variant="body2" style={{ width: '10%' }}>
                    {currencySymbol}
                    {Numeral(quote.deductible).format(`0,0`)}
                  </Typography>
                  <Typography variant="body2" style={{ width: '12%' }}>
                    {deriveCurrencyFormat(region, quote.totalPremium, {
                      placeholder: 'Referred',
                    })}
                  </Typography>
                  <Typography variant="body2" style={{ width: '15%' }}>
                    {toUniversalUtcDate(quote.effectiveDate)}
                  </Typography>

                  <Show when={uiSettings.renderCommissionSlider}>
                    <Typography
                      variant="subtitle2"
                      style={{ width: '10%', paddingLeft: '1rem' }}
                    >
                      {quote.commissionRate}%
                    </Typography>
                  </Show>

                  <Typography
                    variant="body2"
                    style={{ width: '8%', textAlign: 'center' }}
                  >
                    <Button
                      style={{ padding: 0, marginRight: '-0.6rem' }}
                      color="primary"
                      size="small"
                      variant="text"
                      onClick={() =>
                        removeQuoteFromCartMutation.mutate(quote.id)
                      }
                    >
                      Delete
                    </Button>
                  </Typography>
                </Container>
              );
            })}
          </Paper>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" size="medium" onClick={close}>
          Cancel
        </CbButton>
        <CbButton
          disabled={cartData?.count === 0 || isLoading}
          styleName="ctaButton"
          size="medium"
          onClick={handleRequestQuotes}
          loading={isLoading}
        >
          Request Above Quotes
        </CbButton>
      </DialogActions>
    </>
  );
};

const Container = styled(Box)(({ theme }: { theme: any }) => ({
  display: 'flex',
  justifyContent: 'space-betwee',
  gap: '0.65rem',
  padding: '0.65rem',
  '&:nth-child(odd)': {
    backgroundColor: theme.palette.background.oddRow,
  },
}));

const HeaderContainer = styled(Container)(() => ({
  backgroundColor: 'red',
  alignItems: 'center',
}));

const Typography = styled(TypographyBase)(() => ({
  lineHeight: 1.25,
}));

export const QuoteCartModalConfig = {
  QuoteCartModal: {
    component: QuoteCartModal,
    config: {
      title: 'List of Quotes to Request',
      fullWidth: true,
      maxWidth: 'md',
    },
  },
};

const pathMapping = {
  [PERSONA_TYPES.COWBELL]: '/admin/quotes',
  [PERSONA_TYPES.AGENCY]: '/agency/inbox',
} as const;
