/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withRouter from 'components/hocs/withRouter';
import _ from 'lodash';
import { Paper as MuiPaper } from '@mui/material';

import Tile from 'components/tiles/Tile';
import { fetchPolicyPremium } from 'policies/PolicyService';
import { getRenewalQuoteCount } from 'utils';
import { PubSub } from '../utils/eventUtils';

class PolicyStatsBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: [
        {
          value: 0,
          name: 'Total Policies',
          type: 'policies',
          dataKey: 'data.aggregations.Status.Status.buckets',
        },
        { value: 0, name: 'Total Premium', type: 'premium', dataKey: '' },
        { value: 0, name: 'Avg Premium', type: 'premium', dataKey: '' },
        {
          value: 0,
          name: 'Renewals',
          type: 'quotes',
          dataKey: 'data.aggregations.Status.Renewals.buckets',
        },
      ],
    };
  }

  componentDidMount() {
    this.fetchStats();
    this.refetch = PubSub.subscribe('table-refetch', (data) => {
      this.fetchStats();
    });
    this.fetch = PubSub.subscribe('table-fetch:success', (data) => {
      this.fetchStats(data.search);
    });
    this.subscription = PubSub.subscribe('policy:stats', (data) => {
      this.fetchStats();
    });
  }

  fetchStats = (query) => {
    return new Promise((resolve, reject) => {
      const { stats } = this.state;

      const queryArray = [fetchPolicyPremium(query)];

      Promise.all(queryArray)
        .then((responses) => {
          const premiumStats = responses[0];

          const activePolicyBucket = _.get(premiumStats, stats[0].dataKey, []);
          const issuedPolicies = _.find(activePolicyBucket, {
            key: 'ISSUED',
          }) || { GWP: { value: 0 } };
          const inForcePolicies = _.find(activePolicyBucket, {
            key: 'IN FORCE',
          }) || { GWP: { value: 0 } };

          const cancelledPolicies = _.find(activePolicyBucket, {
            key: 'CANCELLED',
          }) || { GWP: { value: 0 } };

          stats[0].value =
            _.get(inForcePolicies, 'doc_count', null) +
            _.get(cancelledPolicies, 'doc_count', null) +
            _.get(issuedPolicies, 'doc_count', null);

          stats[1].value =
            _.get(inForcePolicies, 'GWP.value', null) +
            _.get(cancelledPolicies, 'GWP.value', null) +
            _.get(issuedPolicies, 'GWP.value', null);

          stats[2].value = stats[1].value / stats[0].value;

          stats[3].value = getRenewalQuoteCount(
            _.get(premiumStats, stats[3].dataKey)
          );
          this.setState({ stats });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { state } = this;

    if (prevState.stats !== state.stats) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ stats: state.stats });
      return true;
    }
  }

  componentWillUnmount() {
    this.fetch.remove();
    this.refetch.remove();
    this.subscription.remove();
  }

  render() {
    const { props } = this;
    const { stats } = this.state;

    return (
      <MuiPaper className="stats-bar" elevation={6}>
        {stats &&
          stats.map((stat) => {
            return (
              <Tile key={stat.name} query={props.location.query} {...stat} />
            );
          })}
      </MuiPaper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stats: state.stats,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PolicyStatsBar)
);
