import v2 from './v2-table-headers-reset';
import v3 from './v3-inbox-showable-headers';
import v4 from './v4-add-primary-contact';
import v5 from './v5-store-reset';
import RelocateGlobalTeam from './v6-relocate-global-team';
import DisableGrowthbookFeatureFlags from './v8-feature-flag-default-off';
import PlatformLanguageSettings from './v9-platform-language-settings';

export default {
  2: v2,
  3: v3,
  4: v4,
  5: v5,
  6: RelocateGlobalTeam,
  7: DisableGrowthbookFeatureFlags,
  8: PlatformLanguageSettings,
};
