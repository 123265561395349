import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { DialogContent, DialogActions } from '@mui/material/';

import CBButton from '../Buttons/CbButton';
import { ProductTypes } from '../../types';

const RedirectRequote = compose(withRouter)(({ data, ...props }) => {
  const handleRequote = () => {
    props.close();
    props.history.push(
      `/agency/requestQuote/${ProductTypes.p250}/${data.accountId}`
    );
  };

  const handleRedirectBack = () => {
    props.close();
    props.history.push(`/agency/inbox`);
  };

  return (
    <>
      <DialogContent>
        <div className="modal-title">
          Re-Quote cannot be created for this Quote. Would you like to generate
          a New Quote instead? Please click on ‘New Quote’ below.
        </div>
      </DialogContent>

      <DialogActions>
        <CBButton size="medium" styleName="cancel" onClick={handleRedirectBack}>
          Cancel
        </CBButton>
        <CBButton
          size="medium"
          type="submit"
          styleName="ctaButton"
          onClick={handleRequote}
        >
          New Quote
        </CBButton>
      </DialogActions>
    </>
  );
});

export default RedirectRequote;
