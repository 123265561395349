import React from 'react';
import _ from 'lodash';
import { useSteps } from '../../../hooks/useSteps';

export const StepperModal = ({
  children = [],
  showPredicate = () => {},
  ...props
}) => {
  const stepState = useSteps({
    startStep: '0',
    initialSteps: Object.keys(children),
    ...props,
  });

  const enrichedChildren = React.useMemo(() => {
    return children.map((child) => {
      return React.cloneElement(child, {
        stepperProps: stepState,
      });
    });
  }, [children, stepState]);

  const { step, stepIndex } = stepState;

  const activeStep = React.useMemo(
    () =>
      enrichedChildren.find((child, childIndex) => {
        const childName = _.get(child, 'props.name', '');
        const showCondition = showPredicate(child, childIndex);
        return showCondition || childName === step || childIndex === stepIndex;
      }),
    [enrichedChildren, showPredicate, step, stepIndex]
  );

  return <>{activeStep}</>;
};
