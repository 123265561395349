export const UsStates = [
  {
    label: 'AL',
    value: 'AL',
  },
  {
    label: 'AK',
    value: 'AK',
  },
  {
    label: 'AS',
    value: 'AS',
  },
  {
    label: 'AZ',
    value: 'AZ',
  },
  {
    label: 'AR',
    value: 'AR',
  },
  {
    label: 'CA',
    value: 'CA',
  },
  {
    label: 'CO',
    value: 'CO',
  },
  {
    label: 'CT',
    value: 'CT',
  },
  {
    label: 'DE',
    value: 'DE',
  },
  {
    label: 'DC',
    value: 'DC',
  },
  {
    label: 'FM',
    value: 'FM',
  },
  {
    label: 'FL',
    value: 'FL',
  },
  {
    label: 'GA',
    value: 'GA',
  },
  {
    label: 'GU',
    value: 'GU',
  },
  {
    label: 'HI',
    value: 'HI',
  },
  {
    label: 'ID',
    value: 'ID',
  },
  {
    label: 'IL',
    value: 'IL',
  },
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: 'IA',
    value: 'IA',
  },
  {
    label: 'KS',
    value: 'KS',
  },
  {
    label: 'KY',
    value: 'KY',
  },
  {
    label: 'LA',
    value: 'LA',
  },
  {
    label: 'ME',
    value: 'ME',
  },
  {
    label: 'MH',
    value: 'MH',
  },
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MI',
    value: 'MI',
  },
  {
    label: 'MN',
    value: 'MN',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MO',
    value: 'MO',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'NE',
    value: 'NE',
  },
  {
    label: 'NV',
    value: 'NV',
  },
  {
    label: 'NH',
    value: 'NH',
  },
  {
    label: 'NJ',
    value: 'NJ',
  },
  {
    label: 'NM',
    value: 'NM',
  },
  {
    label: 'NY',
    value: 'NY',
  },
  {
    label: 'NC',
    value: 'NC',
  },
  {
    label: 'ND',
    value: 'ND',
  },
  {
    label: 'MP',
    value: 'MP',
  },
  {
    label: 'OH',
    value: 'OH',
  },
  {
    label: 'OK',
    value: 'OK',
  },
  {
    label: 'OR',
    value: 'OR',
  },
  {
    label: 'PW',
    value: 'PW',
  },
  {
    label: 'PA',
    value: 'PA',
  },
  {
    label: 'PR',
    value: 'PR',
  },
  {
    label: 'RI',
    value: 'RI',
  },
  {
    label: 'SC',
    value: 'SC',
  },
  {
    label: 'SD',
    value: 'SD',
  },
  {
    label: 'TN',
    value: 'TN',
  },
  {
    label: 'TX',
    value: 'TX',
  },
  {
    label: 'UT',
    value: 'UT',
  },
  {
    label: 'VT',
    value: 'VT',
  },
  {
    label: 'VI',
    value: 'VI',
  },
  {
    label: 'VA',
    value: 'VA',
  },
  {
    label: 'WA',
    value: 'WA',
  },
  {
    label: 'WV',
    value: 'WV',
  },
  {
    label: 'WI',
    value: 'WI',
  },
  {
    label: 'WY',
    value: 'WY',
  },
];

export const UsStatesFull = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District of Columbia',
    value: 'DC',
  },

  {
    label: 'Florida',
    value: 'FL',
  },

  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];
export const UsStatesObjects = {
  AL: {
    label: 'Alabama',
    value: 'AL',
    timeZone: 'US/Central',
  },
  AK: {
    label: 'Alaska',
    value: 'AK',
    timeZone: 'US/Alaska',
  },
  AZ: {
    label: 'Arizona',
    value: 'AZ',
    timeZone: 'US/Mountain',
  },
  AR: {
    label: 'Arkansas',
    value: 'AR',
    timeZone: 'US/Central',
  },
  CA: {
    label: 'California',
    value: 'CA',
    timeZone: 'US/Pacific',
  },
  CO: {
    label: 'Colorado',
    value: 'CO',
    timeZone: 'US/Mountain',
  },
  CT: {
    label: 'Connecticut',
    value: 'CT',
    timeZone: 'US/Eastern',
  },
  DE: {
    label: 'Delaware',
    value: 'DE',
    timeZone: 'US/Eastern',
  },
  DC: {
    label: 'District of Columbia',
    value: 'DC',
    timeZone: 'US/Eastern',
  },
  FL: {
    label: 'Florida',
    value: 'FL',
    timeZone: 'US/Eastern',
  },
  GA: {
    label: 'Georgia',
    value: 'GA',
    timeZone: 'US/Eastern',
  },
  HI: {
    label: 'Hawaii',
    value: 'HI',
    timeZone: 'US/Hawaii',
  },
  ID: {
    label: 'Idaho',
    value: 'ID',
    timeZone: 'US/Mountain',
  },
  IL: {
    label: 'Illinois',
    value: 'IL',
    timeZone: 'US/Central',
  },
  IN: {
    label: 'Indiana',
    value: 'IN',
    timeZone: 'US/Eastern',
  },
  IA: {
    label: 'Iowa',
    value: 'IA',
    timeZone: 'US/Central',
  },
  KS: {
    label: 'Kansas',
    value: 'KS',
    timeZone: 'US/Central',
  },
  KY: {
    label: 'Kentucky',
    value: 'KY',
    timeZone: 'US/Eastern',
  },
  LA: {
    label: 'Louisiana',
    value: 'LA',
    timeZone: 'US/Central',
  },
  ME: {
    label: 'Maine',
    value: 'ME',
    timeZone: 'US/Eastern',
  },
  MD: {
    label: 'Maryland',
    value: 'MD',
    timeZone: 'US/Eastern',
  },
  MA: {
    label: 'Massachusetts',
    value: 'MA',
    timeZone: 'US/Eastern',
  },
  MI: {
    label: 'Michigan',
    value: 'MI',
    timeZone: 'US/Eastern',
  },
  MN: {
    label: 'Minnesota',
    value: 'MN',
    timeZone: 'US/Central',
  },
  MS: {
    label: 'Mississippi',
    value: 'MS',
    timeZone: 'US/Central',
  },
  MO: {
    label: 'Missouri',
    value: 'MO',
    timeZone: 'US/Central',
  },
  MT: {
    label: 'Montana',
    value: 'MT',
    timeZone: 'US/Mountain',
  },
  NE: {
    label: 'Nebraska',
    value: 'NE',
    timeZone: 'US/Central',
  },
  NV: {
    label: 'Nevada',
    value: 'NV',
    timeZone: 'US/Pacific',
  },
  NH: {
    label: 'New Hampshire',
    value: 'NH',
    timeZone: 'US/Eastern',
  },
  NJ: {
    label: 'New Jersey',
    value: 'NJ',
    timeZone: 'US/Eastern',
  },
  NM: {
    label: 'New Mexico',
    value: 'NM',
    timeZone: 'US/Mountain',
  },
  NY: {
    label: 'New York',
    value: 'NY',
    timeZone: 'US/Eastern',
  },
  NC: {
    label: 'North Carolina',
    value: 'NC',
    timeZone: 'US/Eastern',
  },
  ND: {
    label: 'North Dakota',
    value: 'ND',
    timeZone: 'US/Central',
  },
  OH: {
    label: 'Ohio',
    value: 'OH',
    timeZone: 'US/Eastern',
  },
  OKAL: {
    label: 'Oklahoma',
    value: 'OK',
    timeZone: 'US/Central',
  },
  OR: {
    label: 'Oregon',
    value: 'OR',
    timeZone: 'US/Pacific',
  },
  PA: {
    label: 'Pennsylvania',
    value: 'PA',
    timeZone: 'US/Eastern',
  },
  RI: {
    label: 'Rhode Island',
    value: 'RI',
    timeZone: 'US/Eastern',
  },
  SC: {
    label: 'South Carolina',
    value: 'SC',
    timeZone: 'US/Eastern',
  },
  SD: {
    label: 'South Dakota',
    value: 'SD',
    timeZone: 'US/Central',
  },
  TN: {
    label: 'Tennessee',
    value: 'TN',
    timeZone: 'US/Central',
  },
  TX: {
    label: 'Texas',
    value: 'TX',
    timeZone: 'US/Central',
  },
  UT: {
    label: 'Utah',
    value: 'UT',
    timeZone: 'US/Mountain',
  },
  VT: {
    label: 'Vermont',
    value: 'VT',
    timeZone: 'US/Eastern',
  },
  VA: {
    label: 'Virginia',
    value: 'VA',
    timeZone: 'US/Eastern',
  },
  WA: {
    label: 'Washington',
    value: 'WA',
    timeZone: 'US/Pacific',
  },
  WV: {
    label: 'West Virginia',
    value: 'WV',
    timeZone: 'US/Eastern',
  },
  WI: {
    label: 'Wisconsin',
    value: 'WI',
    timeZone: 'US/Central',
  },
  WY: {
    label: 'Wyoming',
    value: 'WY',
    timeZone: 'US/Mountain',
  },
};
