export const ProductTypes = {
  p100: 'P100',
  p250: 'P250',
  primecloud: 'PRIME_CLOUD',
  notSupported: 'NotSupported',
  p250S: 'P250_S',
  p100_pro: 'P100_PRO',
  prime_one: 'P_ONE',
  p_plus: 'P_PLUS',
  prime_tech_plus: 'PRIME_TECH_PLUS',
} as const;

export type ProductType = (typeof ProductTypes)[keyof typeof ProductTypes];

export const PrimeXProductTypes = {
  p100_pro: ProductTypes.p100_pro,
  prime_one: ProductTypes.prime_one,
} as const satisfies Partial<typeof ProductTypes>;

export type PrimeXProductType =
  (typeof ProductTypes)[keyof typeof PrimeXProductTypes];
