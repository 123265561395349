import React from 'react';
import { useFormContext } from 'react-hook-form-4';
// components
import { ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/lab';

export const RegisteredToggleButtonGroup = ({ name, ...props }) => {
  const { register, setValue, watch, formState } = useFormContext();
  const { dirty } = formState;
  const value = watch(name);

  React.useEffect(() => {
    register({ name });
    // eslint-disable-next-line
  }, [dirty]);

  const handleChange = React.useCallback(
    (event, nextValue) => {
      setValue(name, nextValue);
    },
    // eslint-disable-next-line
    [setValue]
  );

  return (
    <MuiToggleButtonGroup onChange={handleChange} value={value} {...props} />
  );
};
