import type { ReactNode } from 'react';
import React from 'react';
//mui
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const LineItem: React.FC<{
  marginBottom?: number;
  style?: { [key: string]: any };
  children: ReactNode;
}> = ({ children, marginBottom = 0, style = {} }) => {
  const classes = lineItemStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      component="li"
      style={style}
      mb={marginBottom}
      className={classes.lineItem}
    >
      {children}
    </Box>
  );
};

export default LineItem;

const lineItemStyles = makeStyles(({ palette, config }) => ({
  lineItem: {
    position: 'relative',
    zIndex: 0,

    '&::before': {
      content: '""',
      boxSizing: 'initial',
      width: '100%',
      height: '100%',
      padding: '.2rem .5rem',
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: -1,
      transform: 'translate(-50%, -50%)',
      background:
        palette.mode === 'dark'
          ? config.colors.lightGrey2
          : config.colors.mediumGrey,
      borderRadius: 4,
      opacity: 0,
    },

    '&:hover::before': {
      opacity: 0.075,
    },
  },
}));
