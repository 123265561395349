import { combineReducers } from 'redux';

// reducers
import auth from './AuthReducers';
import ux from './UxReducers';
import { uiSettings } from './UiSettingsReducers';
import { globalModalsReducer as modals } from './global-modals.reducer';
import { account } from './AccountReducers';
import { Coverages250Reducer as coverage250 } from './Coverages250Reducers';
import { prime100Reducer as prime100 } from '../console/_reducers/prime100.reducer';
import { notesReducer as notes } from '../console/_tabs/notesTab/notes.reducer';
import { TabsReducer as tabs } from '../console/_tabs/tabs.reducer';
import { scopesReducer as scopes } from './scopes.reducer';
import { GlobalTeamReducer } from './team.reducer.ts';

import { userstore } from '../store/reducers/user.store';
import { userAccountReducer as userAccount } from '../store/reducers/user-account.store';
import { teamsStore } from '../store/reducers/teams.store';

const reducers = combineReducers({
  auth,
  account,
  user_account: userAccount,
  user: userstore,
  team: GlobalTeamReducer,
  teams: teamsStore,
  scopes,
  tabs,
  coverage250,
  prime100,
  notes,
  ux,
  uiSettings,
  modals,
});

export default reducers;
