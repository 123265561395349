import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { searchAccountsV2 } from '../../../../api';
import { AgencyDashboardQueryKeys } from '../agency-dashboard.statics';
import {
  defaultShowQuoteStatuses,
  defaultShowBinderStatuses,
  defaultShowPolicyStatuses,
} from './useAgencyDashboardListingData';

export const useAgencyDashboardSearchData = (
  searchTerm: string,
  teamId: string,
  allAgencyResults: boolean
) => {
  const teamIds = teamId === 'all' ? undefined : [teamId];

  return useQuery({
    queryKey: [
      AgencyDashboardQueryKeys.SEARCH,
      searchTerm,
      teamIds,
      allAgencyResults,
      defaultShowQuoteStatuses,
    ],
    queryFn: () =>
      searchAccountsV2({
        params: {
          size: 10,
          search: searchTerm,
          teamIds,
          allAgencyResults,
          quoteFilter: { agencyStatus: { in: defaultShowQuoteStatuses } },
          binderFilter: { status: { in: defaultShowBinderStatuses } },
          policyFilter: { status: { in: defaultShowPolicyStatuses } },
        },
      }),
    select: formatAgencyDashboardData,
  });
};

export const formatAgencyDashboardData = (data: AxiosResponse) => {
  const {
    data: { content: accounts = [], totalElements = 0 },
  } = data;

  return { accounts, totalElements };
};
