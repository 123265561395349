export const CARRIERS_PREFIX_MAP = {
  BCM: 'Benchmark',
  OBD: 'Obsidian',
  PLM: 'Palomar',
  GRA: 'Great America',
  BLU: 'State National',
  FLY: 'Spinnaker',
  'PLM-CG': 'SwissRe',
  SKY: 'Skyward',
} as const;
