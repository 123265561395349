import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';

const UWComparisonGroupHeader = ({ sectionLabel }) => {
  const classes = useClasses();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-evenly"
      className={classes.container}
    >
      <Box>
        <h3 style={{ width: '11rem' }} className={classes.sectionTitle}>
          {sectionLabel}
        </h3>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        flex="0.35"
        className={classes.innerContainer}
      >
        <div className={classes.covLimit}>Coverage Limit</div>
        <div>D</div>
        <div className={classes.borderedCell}>W</div>
        <div className={classes.borderedRightCell}>R</div>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        display="flex"
        flex="0.35"
        justifyContent="center"
        alignItems="center"
        className={classes.innerContainer}
      >
        <div className={classes.covLimit}>Coverage Limit</div>
        <div>D</div>
        <div className={classes.borderedCell}>W</div>
        <div>R</div>
      </Box>
    </Box>
  );
};

export default UWComparisonGroupHeader;

const useClasses = makeStyles(({ palette, config }) => ({
  container: {
    width: '100%',
    background: palette.background.paper,
    color: palette.text.secondary,
    textTransform: 'uppercase',
    borderTop: `solid 1px ${palette.primary.border}`,
    '& > div': {
      fontSize: '0.75rem',
      lineHeight: '1.17',
    },
  },
  innerContainer: {
    gap: '0.5rem',
    '& > div': {
      width: '3.5rem',
      textAlign: 'center',
    },
  },
  sectionTitle: {
    fontSize: '0.875rem',
    lineHeight: '1.43',
    fontWeight: '600',
    margin: '0',
    padding: '1rem 0',
    textAlign: 'right',
  },
  borderedCell: {
    borderLeft: `solid 1px ${palette.primary.border}`,
    borderRight: `solid 1px ${palette.primary.border}`,
    padding: '0 0.625rem',
  },
  covLimit: {
    textAlign: 'center',
    width: '25%',
  },
}));
