// core
import React from 'react';
import Moment from 'moment';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

// mui
import { Box, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// table components
import _ from 'lodash';
import { BaseTable } from '../../../../components/tables/dev-express/BaseTable';
import { TableStateProvider } from '../../../../components/tables/dev-express/TableStateContext';
import { TableSearch } from '../../../../components/tables/dev-express/table-components/toolbar/TableSearch';
import { CsvDownloadButton } from '../../../../components/tables/dev-express/table-components/toolbar/CsvDownloadButton';
import { RemotePagingPlugin } from '../../../../components/tables/dev-express/plugins/RemotePagingPlugin';
import { TableFilterSelectV2 } from '../../../../components/tables/tableFilters/v2/TableFilterSelectV2';
import { TableFilterChipsV2 } from '../../../../components/tables/tableFilters/v2/TableFilterChipsV2';
import { FilterModal } from '../../../../components/modals/FilterModal';
import { RefreshButton } from '../../../../components/tables/dev-express/table-components/toolbar/RefreshButton';
import { PrimaryToolbar } from '../../../../components/tables/dev-express/table-components/toolbar/PrimaryToolbar';
import { SecondaryToolbar } from '../../../../components/tables/dev-express/table-components/toolbar/SecondaryToolbar';
import { RowDetailPlugin } from '../../../../components/tables/dev-express/plugins/RowDetailPlugin';

// other components
import { CowbellPoliciesTableRow } from '../../../../components/tables/dev-express/table-components/rowComponents';
import { ColumnChooser } from '../../../../components/tables/dev-express/table-components/toolbar/ColumnChooser';
import { UrlDateRangePicker } from '../../../../components/inputs/DateRangePicker';
import { AGENCY_ENDORSEMENTS } from '../../../../components/tables/table_constants';
import { getFee, prepColumnFilters } from '../../../../utils/data.utils';
import {
  cowbellPolicyDatePresets,
  derivePolicyEffectiveDate,
  derivePolicyEndDate,
  getAgentCellValue,
  PolicyNumberCell,
  getStatusCellValue,
  PolicyRowDetail,
  AgentNameCell,
} from '../cowbell/helpers';

import { CreatedDateCell } from '../../../../components/tables/tables-new/tableCellComponents';
import AdminAccountsQuoteDrawer, {
  useAdminQuoteDrawer,
} from '../../../accounts/admin/drawers/AdminQuoteDrawer';
import { AgencyPolicyOptions } from '../../../../policies/options/AgencyPolicyOptions';

// hooks
import { useUiSettingsAutosave } from '../../../../components/tables/dev-express/hooks/useUiSettingsAutosave';
import { useBackfill } from '../../../../components/tables/dev-express/hooks/useBackfill';
import { useQueryHandlers } from '../../../../components/tables/dev-express/hooks/useQueryHandlers';
import { useSavedSettings } from '../../../../components/tables/dev-express/hooks/useSavedSettings';
import { useTableState } from '../../../../components/tables/dev-express/hooks/useTableState';
import { useScrollListener } from '../../../../components/tables/dev-express/hooks/useScrollListener';
import { useQueryParams } from '../../../../components/providers/QueryParamsProvider';

// services + utils
import {
  fetchEndorsementPolicies,
  fetchPolicyStatuses,
} from '../../../../policies/PolicyService';
import {
  getBoolValueText,
  phoneFormatter,
  RenewalOptions,
  useJQuery,
  YesNoOptions,
} from '../../../../utils/appUtils';
import StateStatics from '../../../_statics/states.statics.json';
import ProductStatics from '../../../_statics/product.statics.json';

import { Allow } from '../../../../components/auth/Allow';
import CowbellEndorsementsStatsBar from '../cowbell/CowbellEndorsementsStatsBar';
import {
  getShortDateString,
  getShortDateTimeFromUtc,
} from '../../../../utils/csvUtils';
import { PolicyStatus } from '../../../_statics/policy.statics';
import { useTeamQueryParam } from '../../../../components/hooks/listings/useTeamQueryParam';
import { formatTableCurrencyByRegion } from '../../../../utils/currency.utils';
import { getIsUsPlatform, useGetPlatformRegion } from '../../../../utils';
import { useCowbellTranslations } from '../../../../i18n/translations';
import { Show } from '../../../../components/Show';

const TABLE_ID = AGENCY_ENDORSEMENTS;
const backfill = {
  size: 25,
  order: 'desc',
  orderBy: 'modified',
  before: () => Moment().endOf('day').unix(),
  after: () => Moment().subtract(2, 'year').startOf('day').unix(),
};

export const AgencyEndorsementsListing = () => {
  useScrollListener();
  useTeamQueryParam();

  const { query } = useQueryParams();
  const { queryHandlers } = useQueryHandlers(query);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const theme = useTheme();
  const classes = useStyles();
  const columnConfig = useColumnConfig();

  const quoteDrawer = useAdminQuoteDrawer({
    getAccountData: (rowData) => ({ ...rowData, id: rowData.accountId }),
  });

  const { uiSettings } = useSavedSettings(TABLE_ID, columnConfig);
  useBackfill(backfill, uiSettings);

  const { state, columns, handlers } = useTableState(columnConfig, {
    uiSettings,
  });

  useUiSettingsAutosave(
    TABLE_ID,
    { columns, columnOrder: state.columnOrder, ...query },
    ['before', 'after']
  );

  const { isLoaded: isJQueryLoaded } = useJQuery();

  const { data, isFetching } = useQuery(
    [TABLE_ID, query],
    () => {
      return fetchEndorsementPolicies(query).then((resp) => ({
        totalCount: resp.data.totalElements,
        rows: resp.data.content,
      }));
    },
    {
      placeholderData: {},
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: () => {
        enqueueSnackbar(
          'There was a problem loading Policy data. Please try again',
          { variant: 'error' }
        );
      },
    }
  );

  const refetchTableData = React.useCallback(() => {
    queryClient.invalidateQueries([TABLE_ID]);
  }, [queryClient]);

  const mergedState = React.useMemo(
    () => ({ ...state, ...query }),
    [query, state]
  );
  const mergedHandlers = React.useMemo(
    () => ({ ...handlers, ...queryHandlers }),
    [handlers, queryHandlers]
  );

  const filterColumns = React.useMemo(() => {
    return prepColumnFilters(Object.values(columnConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = React.useMemo(
    () => ({
      theme,
      onOpenDrawer: quoteDrawer.onOpen,
      classes,
      isJQueryLoaded,
      refetchTableData,
    }),
    [classes, quoteDrawer.onOpen, theme, isJQueryLoaded, refetchTableData]
  );

  const rows = React.useMemo(() => data?.rows ?? [], [data]);
  const totalCount = React.useMemo(() => data?.totalCount ?? 0, [data]);

  return (
    <>
      <PrimaryToolbar style={{ borderTopLeftRadius: 0 }}>
        <TableFilterSelectV2 tableId={TABLE_ID} />
        <Box display="flex" width="60%">
          <TableSearch />
          <UrlDateRangePicker
            inlineLabel
            customPreset={cowbellPolicyDatePresets}
          />
        </Box>
      </PrimaryToolbar>

      <CowbellEndorsementsStatsBar />

      <TableStateProvider
        state={mergedState}
        handlers={mergedHandlers}
        columns={columns}
        rows={rows}
        tableId={TABLE_ID}
        context={context}
      >
        <SecondaryToolbar>
          <Box display="flex">
            <TableFilterChipsV2 tableId={TABLE_ID} />
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <ColumnChooser />
            <CsvDownloadButton />
            <RefreshButton onClick={refetchTableData} loading={isFetching} />
          </Box>
        </SecondaryToolbar>

        <BaseTable sticky RowComponent={CowbellPoliciesTableRow}>
          <RemotePagingPlugin totalCount={totalCount} />
          <RowDetailPlugin
            rowDetailComponent={(props) => (
              <Allow allow={['policy:view']}>
                <PolicyRowDetail
                  onExpandRow={handlers.onExpandedRowIdsChange}
                  {...props}
                />
              </Allow>
            )}
          />
        </BaseTable>
      </TableStateProvider>
      <FilterModal tableId={TABLE_ID} config={filterColumns} />
      <Show when={Boolean(quoteDrawer.data)}>
        <AdminAccountsQuoteDrawer
          account={quoteDrawer.data}
          open={quoteDrawer.isOpen}
          onClose={quoteDrawer.onClose}
        />
      </Show>
    </>
  );
};

const useColumnConfig = () => {
  const region = useGetPlatformRegion();
  const isUSPlatform = getIsUsPlatform(region);
  const { translations } = useCowbellTranslations(['Agency', 'Agent']);

  return {
    id: {
      name: 'id',
      title: 'ID',
      show: false,
      width: 'l',
    },
    policyNumber: {
      name: 'policyNumber',
      title: 'Policy Number',
      width: 160,
      fixed: 'left',
      filterType: 'string',
      getCellValue: (row) => <PolicyNumberCell row={row} />,
    },
    isBOR: {
      name: 'isBOR',
      title: 'Lost Account?',
      show: false,
      filterType: 'bool',
      width: 120,
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => getBoolValueText(row.isBOR),
      csv: (row) => getBoolValueText(row.isBOR),
    },
    premium: {
      name: 'premium',
      title: 'Premium',
      filterType: 'number',
      show: true,
      width: 's',
      getCellValue: formatTableCurrencyByRegion()('premium'),
      csv: formatTableCurrencyByRegion()('premium'),
    },
    brokerFee: {
      name: 'brokerFee',
      title: 'Fee',
      filterType: 'number',
      show: false,
      sort: false,
      width: 's',
      getCellValue: (row) => getFee(row),
      csv: (row) => getFee(row),
    },
    totalPremium: {
      name: 'totalPremium',
      title: 'Total Premium',
      filterType: 'number',
      width: 's',
      getCellValue: formatTableCurrencyByRegion()('totalPremium'),
      csv: formatTableCurrencyByRegion()('totalPremium'),
    },
    companyName: {
      name: 'companyName',
      title: 'Company Name',
      width: 'm',
      filterType: 'string',
      getCellValue: (row) => row.companyName,
    },
    dbaOrTradestyle: {
      name: 'dbaOrTradestyle',
      title: 'DBA',
      filterType: 'string',
      show: false,
      sort: false,
    },
    ...(isUSPlatform
      ? {
          state: {
            name: 'state',
            title: 'Company State',
            filterType: 'list',
            sort: false,
            show: false,
            width: 's',
            getListOptions: async () => StateStatics.full,
            getCellValue: (row) =>
              row.firmographicData?.state ? row.firmographicData?.state : '-',
            csv: (row) =>
              row.firmographicData?.state ? row.firmographicData?.state : '',
          },
        }
      : {}),
    customerEmail: {
      name: 'customerEmail',
      title: 'Customer Email',
      filterType: 'string',
      show: false,
      width: 'm',
    },
    customerName: {
      name: 'customerName',
      title: 'Customer Name',
      filterType: 'string',
      width: 190,
      getCellValue: (row) => {
        const { customerFirstName = '', customerLastName = '' } = row;
        return `${customerFirstName} ${customerLastName}`;
      },
      csv: (row) => {
        const { customerFirstName = '', customerLastName = '' } = row;
        return `${customerFirstName} ${customerLastName}`;
      },
    },
    customerPhone: {
      name: 'customerPhone',
      title: 'Customer Phone Number',
      show: false,
      width: 180,
      filterType: 'string',
      getCellValue: (row) => phoneFormatter(row.customerPhone, '-'),
    },
    product: {
      name: 'product',
      title: 'Product',
      show: false,
      filterType: 'list',
      getListOptions: async () => ProductStatics.PRODUCT_OPTIONS,
      getCellValue: (row) =>
        ProductStatics.ProductTypes?.[row.product]?.short ?? row.product,
    },
    isSurplus: {
      name: 'isSurplus',
      title: 'Is Surplus?',
      width: 110,
      show: false,
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => (row.isSurplus ? 'S' : 'A'),
      csv: (row) => (row.isSurplus ? 'S' : 'A'),
    },
    isRenewal: {
      name: 'isRenewal',
      title: 'Is Renewal?',
      filterType: 'bool',
      getListOptions: async () => RenewalOptions,
      width: 110,
      show: false,
      getCellValue: (row) => (row.isRenewal ? 'Renewal' : 'New'),
      csv: (row) => (row.isRenewal ? 'Renewal' : 'New'),
    },
    isMigration: {
      name: 'isMigration',
      title: 'Is Migration?',
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      width: 130,
      show: false,
      getCellValue: (row) => getBoolValueText(row.isMigration),
      csv: (row) => getBoolValueText(row.isMigration),
    },
    isErp: {
      name: 'isErp',
      title: 'Is ERP?',
      show: false,
      width: 110,
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => getBoolValueText(row.isErp),
      csv: (row) => getBoolValueText(row.isErp),
    },
    isPrimePlus: {
      name: 'isPrimePlus',
      title: 'Is Excess?',
      filterType: 'bool',
      show: false,
      getListOptions: async () => YesNoOptions,
      getCellValue: (row) => getBoolValueText(row.isPrimePlus),
      csv: (row) => getBoolValueText(row.isPrimePlus),
    },
    agencyId: {
      name: 'agencyId',
      title: `${translations.Agency} ID`,
      filterType: 'string',
      show: false,
      sort: false,
      width: 'l',
    },
    agent: {
      name: 'agent',
      title: translations.Agent,
      width: 180,
      getCellValue: (row) => <AgentNameCell row={row} />,
      csv: getAgentCellValue,
    },
    agentEmail: {
      name: 'agentEmail',
      title: `${translations.Agent} Email`,
      filterType: 'string',
      show: false,
      width: 'm',
      getCellValue: (row) => row.agentEmail || '-',
    },
    description: {
      name: 'description',
      title: 'Description',
      width: 'm',
      filterType: 'string',
      getCellValue: (row) => {
        let desc = row.description || '-';
        desc = desc.length > 100 ? `${desc.substr(0, 98)} ...` : desc;
        return desc;
      },
    },
    effectiveDate: {
      name: 'effectiveDate',
      title: 'Policy Effective',
      filterType: 'date',
      show: false,
      getCellValue: (row) =>
        derivePolicyEffectiveDate(row, getShortDateTimeFromUtc),
      csv: derivePolicyEffectiveDate,
    },
    endDate: {
      name: 'endDate',
      title: 'Policy Expiration',
      filterType: 'date',
      show: false,
      getCellValue: (row) => derivePolicyEndDate(row, getShortDateTimeFromUtc),
      csv: derivePolicyEndDate,
    },
    created: {
      name: 'created',
      title: 'Created',
      filterType: 'date',
      show: false,
      getCellValue: (row) => CreatedDateCell(row),
      csv: (row) => getShortDateString(row.create),
    },
    modified: {
      name: 'modified',
      title: 'Modified',
      filterType: 'date',
      getCellValue: (row) =>
        row.modified ? Moment.utc(row.modified).fromNow() : '-',
      csv: (row) => getShortDateString(row.modified),
    },
    quoteNumber: {
      name: 'quoteNumber',
      title: 'Quote Number',
      show: false,
      filterType: 'string',
    },
    status: {
      name: 'status',
      title: 'Status',
      filterType: 'list',
      getListOptions: () =>
        fetchPolicyStatuses()
          .then(({ data }) => {
            if (data.length) {
              return data.map((el) => ({ label: el, value: el }));
            }
            return [];
          })
          .catch(() => []),
      getCellValue: getStatusCellValue,
    },
    options: {
      name: 'options',
      width: 'xxs',
      canToggle: false,
      sort: false,
      disableExpandRow: true,
      ommitInCsv: true,
      fixed: 'right',
      align: 'center',
      getCellValue: (row, context) => {
        const { isJQueryLoaded, refetchTableData } = context;

        const unvailableStatuses = [
          PolicyStatus.VOID_STATUS,
          PolicyStatus.CANCELLED_STATUS,
          PolicyStatus.EXPIRED_STATUS,
          PolicyStatus.REQUESTED_STATUS,
          PolicyStatus.CANCELLED_PENDING_STATUS,
          PolicyStatus.FLAT_CANCELLED_STATUS,
        ];

        return (
          !unvailableStatuses.includes(row.status) &&
          !row.isBOR && (
            <AgencyPolicyOptions
              data={row}
              handleTableRefetch={refetchTableData}
              isJQueryLoaded={isJQueryLoaded}
              setShowCircular={_.noop}
            />
          )
        );
      },
    },
  };
};

const useStyles = makeStyles(() => ({
  link: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
