import { emphasize } from '@mui/material';
import Color from 'color';

export default ({ config, palette, ...theme }) => {
  return {
    root: {
      flexGrow: 1,

      '& > div > div': {
        backgroundColor: palette.background.default,
      },
    },
    input: {
      display: 'flex',
      padding: 0,
      backgroundColor: palette.background.default,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
    },
    chip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
      backgroundColor: 'initial',
      borderRadius: '0',
      '& > span': {
        padding: 0,
      },
    },
    chipFocused: {
      backgroundColor: emphasize(
        palette.mode === 'light' ? palette.grey[300] : palette.grey[700],
        0.08
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16,
    },
    paper: {
      minWidth: '100%',
      marginTop: theme.spacing.unit,
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: 100,
      border: `1px solid ${palette.primary.oldBorder}`,

      '& > div > div': {
        marginTop: 0,
      },
    },
    divider: {
      height: theme.spacing.unit * 2,
    },

    buttonDown: {
      position: 'absolute',
      top: '50%',
      right: '5px',
      transform: 'translateY(-50%)',
    },

    menuList: {
      width: 'calc(100% + 2px)',
      maxHeight: '220px',
      marginLeft: '-1px',
      position: 'relative',
      overflowY: 'scroll',
      backgroundColor: palette.background.paper,
      border: `1px solid ${palette.primary.oldBorder}`,
      borderTop: 'none',
    },
    select: {
      padding: '11.2px',
      position: 'relative',
      border: `1px solid ${palette.common.almostWhite}`,
      textAlign: 'left',
      textTransform: 'none',
      lineHeight: 1,
      fontWeight: 400,
      borderRadius: 5,
      overflow: 'auto',
      scrollBehavior: 'smooth',

      '& > span': {
        display: 'block',
        width: '100%',
        textAlign: 'left',
      },

      '&:hover': {
        background: Color(palette.common.darkBlue).darken(0.1).string(),
      },
    },

    menuItem: {
      paddingLeft: 0,
    },

    isDisabled: {
      opacity: 0.5,
    },

    label: {
      marginTop: '0.5rem',
      marginLeft: '0.833rem',
      paddingBottom: '0.5rem',
      display: 'flex',
      fontSize: config.textSizes.normal,
      color: palette.text.text8,
    },

    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0.5rem 0 0 0.833rem',
      paddingBottom: '0.5rem',
      cursor: 'pointer',
      borderBottom: `1px solid ${palette.primary.modalBorder}`,
    },
    newLegendItem: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
      paddingBottom: '0.5rem',
      paddingTop: '0.5rem',
      borderBottom: `1px solid ${palette.primary.modalBorder}`,
    },
    legendIcon: {
      width: '0.833rem',
      height: '0.833rem',
      backgroundColor: palette.primary.main,
      margin: '3px 10px 0 0',
    },
    emptyLegendIcon: {
      width: '0.833rem',
      height: '0.833rem',
      border: `1px solid ${palette.primary.main}`,
      margin: '3px 10px 0 0',
    },
    legendText: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.33,
      color: palette.primary.contrastText,
      margin: '0px !important',
    },
    errorText: {
      visibility: 'visible',
      color: palette.text.text9,
    },

    noErrorText: {
      visibility: 'hidden',
    },
  };
};

export const MultiSelectStyles = ({ palette }) => {
  return {
    container: (base) => ({
      ...base,
      '& > div': {
        border: 'none !important',
        boxShadow: 'none !important',
      },
    }),
    control: (base) => ({
      ...base,
      position: 'relative',
      backgroundColor: palette.background.default,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      color: palette.text.primary,

      '& *': {
        color: palette.text.primary,
      },

      '& svg': {
        color: palette.text.primary,
        position: 'absolute',
        top: '50%',
        right: 7,
        transform: 'translateY(-50%)',
        fontSize: 18,
      },
    }),
    menu: (base) => ({
      ...base,
      '& .MuiMenuItem-root': {
        backgroundColor: palette.background.paper,
      },
    }),
  };
};

export const MultiSelectStyles1 = ({ palette }) => {
  return {
    container: (base) => ({
      ...base,
      '& > div': {
        border: 'none !important',
        boxShadow: 'none !important',
      },
    }),
    control: () => ({
      display: 'none',
    }),
    menu: (base) => ({
      ...base,
      '& .MuiMenuItem-root': {
        backgroundColor: palette.background.paper,
      },
    }),
  };
};
