import React from 'react';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import { Paper, Box, TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Skeleton } from '@mui/lab';
import {
  CheckCircleOutline as CheckIcon,
  HighlightOff as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import {
  deleteFormType,
  updateFormType,
} from '../../../../api/DocumentsService';

export const FormTypeTableSkeleton = () => {
  const classes = useStyles();
  return (
    <Box style={{ padding: '1rem' }}>
      <FormTypeTableHeader />
      <>
        {new Array(4).fill(1).map((el, i) => (
          <Paper
            key={i}
            className={classes.itemContainer}
            style={{ justifyContent: 'space-between', padding: 0 }}
          >
            <Skeleton
              component="p"
              variant="rectangular"
              height={10}
              width="50%"
              style={{ marginLeft: '5rem' }}
            />
            <Skeleton
              component="h5"
              variant="circular"
              height={20}
              width={20}
              style={{ marginRight: '3rem' }}
            />
          </Paper>
        ))}
      </>
    </Box>
  );
};

export const FormTypeCRUDTable = ({ formTypes }) => {
  const orderedFormTypes = reorderFormTypes(formTypes);
  const classes = useStyles();
  return (
    <>
      {!_.isEmpty(orderedFormTypes) ? (
        <Box style={{ padding: '1rem' }}>
          <FormTypeTableHeader />
          <Box className={classes.bodyContainer}>
            {orderedFormTypes.map((formType) => (
              <FormTypeTableItem key={formType.id} formType={formType} />
            ))}
          </Box>
        </Box>
      ) : (
        <Box className={classes.noFormTypesMessage}>
          Oops, no form types were found. <br />
          Try reloading the page, or adding your own!
        </Box>
      )}
    </>
  );
};

const reorderFormTypes = (formTypes) => {
  const systemGenerated = formTypes.filter((type) => type.systemGenerated);
  const userGenerated = formTypes.filter((type) => !type.systemGenerated);
  return [...systemGenerated, ...userGenerated];
};

const FormTypeTableHeader = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.headerContainer}>
      <Box className={classes.editColumn}>Edit</Box>
      <Box className={classes.nameColumn}>Form Type</Box>
      <Box className={classes.deleteColumn}>Delete</Box>
    </Paper>
  );
};

const FormTypeTableItem = ({ formType }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  const handleCancelEditing = () => setEditing(false);

  const handleConfirmUpdate = (formName) => {
    updateFormType({ id: formType.id }, { name: formName })
      .then(() => {
        enqueueSnackbar('Form Type Updated Successfully!', {
          variant: 'success',
        });
        queryClient.invalidateQueries(['formTypes']);
      })
      .catch(() =>
        enqueueSnackbar(
          'Something went wrong, please try again in a few minutes',
          { variant: 'error' }
        )
      );
    setEditing(false);
  };

  const handleCancelDelete = () => setConfirmDelete(false);

  const handleConfirmDelete = () => {
    deleteFormType({ id: formType.id })
      .then(() => {
        enqueueSnackbar('Form Type Deleted Successfully!', {
          variant: 'success',
        });
        queryClient.invalidateQueries(['formTypes']);
      })
      .catch(() =>
        enqueueSnackbar(
          'Something went wrong, please try again in a few minutes',
          { variant: 'error' }
        )
      );
    setConfirmDelete(false);
  };

  // systemGenerated formTypes will have no edit/delete options
  if (formType.systemGenerated) {
    return (
      <Paper className={classes.itemContainer}>
        <Box className={classes.editColumn} />
        <Box className={classes.nameColumn}>{formType.name}</Box>
        <Box className={classes.deleteColumn} />
      </Paper>
    );
  }

  return (
    <>
      <Paper
        className={classes.itemContainer}
        style={{
          marginTop: editing || confirmDelete ? '1.3rem' : 0,
          marginBottom: confirmDelete || editing ? '1.2rem' : '0.35rem',
        }}
      >
        {editing && (
          <Box className={classes.editingHelperText}>Enter new name</Box>
        )}
        {confirmDelete && (
          <Box className={classes.deleteHelperText}>Are you sure?</Box>
        )}
        <Box className={classes.editColumn}>
          {editing || confirmDelete ? null : (
            <EditIcon
              className={classes.editIcon}
              onClick={() => {
                setEditing(true);
                setConfirmDelete(false);
              }}
            />
          )}
        </Box>
        <Box className={classes.nameColumn}>
          {editing ? (
            <EditingMode
              formType={formType}
              onCancel={handleCancelEditing}
              onUpdate={handleConfirmUpdate}
            />
          ) : (
            formType.name
          )}
        </Box>
        {!editing && (
          <Box className={classes.deleteColumn}>
            {confirmDelete ? (
              <Box style={{ position: 'relative', left: '1.5rem' }}>
                <ConfirmDelete
                  onCancel={handleCancelDelete}
                  onDelete={handleConfirmDelete}
                />
              </Box>
            ) : (
              <CloseIcon
                className={classes.deleteIcon}
                onClick={() => {
                  setEditing(false);
                  setConfirmDelete(true);
                }}
              />
            )}
          </Box>
        )}
      </Paper>
    </>
  );
};

const EditingMode = ({ formType, onCancel, onUpdate }) => {
  const classes = useStyles();
  const [formName, setFormName] = React.useState('');
  return (
    <Box className={classes.editingContainer}>
      <CloseIcon className={classes.closeIcon} onClick={onCancel} />
      <TextField
        variant="standard"
        name="formType"
        placeholder={formType.name}
        onChange={(e) => setFormName(e.target.value)}
        InputProps={{ classes: { input: classes.editingInput } }}
      />
      {formName ? (
        <CheckIcon
          className={classes.checkIcon}
          onClick={() => onUpdate(formName)}
        />
      ) : null}
    </Box>
  );
};

export const ConfirmDelete = ({ onCancel, onDelete }) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Button className={classes.cancelButton} onClick={onCancel} disableRipple>
        Cancel
      </Button>
      <Button className={classes.deleteButton} onClick={onDelete} disableRipple>
        Delete
      </Button>
    </Box>
  );
};

const useStyles = makeStyles(({ config, palette }) => ({
  headerContainer: {
    width: '100%',
    display: 'flex',
    padding: '1rem 0',
    marginTop: '1.2rem',
    marginBottom: '0.35rem',

    backgroundColor: palette.background.default,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    fontWeight: 700,
  },
  bodyContainer: {
    maxHeight: '16rem',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0.7rem 0',
    position: 'relative',
    marginBottom: '0.35rem',

    backgroundColor: palette.background.default,
    borderRadius: 0,
    transition: 'margin-top 200ms, margin-bottom 200ms',
  },
  editingContainer: {
    width: '25rem',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    right: '2.5rem',
  },
  editingInput: {
    padding: '0.3rem 0.5rem',
    width: '19rem',
  },
  editColumn: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
  nameColumn: {
    width: '60%',
    padding: '0 1.2rem',
  },
  deleteColumn: {
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '2.4rem',
  },
  editIcon: {
    '&:hover': {
      color: 'inherit',
    },
  },
  deleteIcon: {
    fontSize: '1.67rem',
    color: palette.error.main,
    marginRight: '0.6rem',
    '&:hover': {
      color: palette.error.main,
    },
  },
  closeIcon: {
    fontSize: '1.67rem',
    marginRight: '0.5rem',
    '&:hover': {
      color: 'inherit',
    },
  },
  checkIcon: {
    fontSize: '1.67rem',
    color: palette.primary.green,
    marginLeft: '0.5rem',
    '&:hover': {
      color: palette.primary.green,
    },
  },
  checkIconDisabled: {
    fontSize: '1.67rem',
    marginLeft: '0.5rem',
    '&:hover': {
      cursor: 'default',
      color: 'inherit',
    },
  },
  editingHelperText: {
    fontSize: '0.9rem',
    fontStyle: 'italic',
    position: 'absolute',
    left: '5rem',
    bottom: '95%',
  },
  deleteHelperText: {
    fontSize: '0.9rem',
    fontStyle: 'italic',
    position: 'absolute',
    right: '1rem',
    bottom: '95%',
  },
  cancelButton: {
    width: '4rem',
    height: '1.5rem',
    border: `1.8px solid ${config.colors.grey4}`,
    borderRadius: '1rem',
    marginRight: '0.3rem',
  },
  deleteButton: {
    width: '4rem',
    height: '1.5rem',
    borderRadius: '1rem',
    color: config.colors.white,
    backgroundColor: config.colors.cowbellBlue,
    '&:hover': {
      backgroundColor: config.colors.cowbellBlue,
    },
  },
  noFormTypesMessage: {
    fontSize: config.textSizes.greatPrimer,
    fontWeight: config.weights.bold,
    lineHeight: 1.5,
    color: config.colors.cowbellLight,
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '1.5rem',
  },
}));
