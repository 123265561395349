import { claimsInvoicesQueryMap } from './claims.invoices.param-serializer';
import {
  applyQueryConfig,
  responseStringTransformer,
  ServiceHandler,
} from '../../utils';
import {
  standardQuerySerializer,
  serializeElasticPageableTokenResponse,
} from '../serializers';

const claimsParamSerializer = (params: any[]) => {
  return applyQueryConfig(claimsInvoicesQueryMap, params);
};

export const getClaimInvoices = ServiceHandler({
  url: '/api/claimsmgmt/v1/search/invoices',
  paramsSerializer: (params) =>
    standardQuerySerializer(claimsParamSerializer(params)),
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableTokenResponse,
  ],
});

export const getTotalReserveCategories = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/reserve/total',
});

export const createClaimInvoice = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/invoice',
  method: 'POST',
});

export const updateClaimInvoice = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/invoice/:invoiceId',
  method: 'PUT',
});

export const getClaimsReservesStats = ServiceHandler({
  url: '/api/claimsmgmt/v1/aggregate/reserves',
  paramsSerializer: (params) =>
    standardQuerySerializer(claimsParamSerializer(params)),
});

export const getClaimsInvoiceIncurredStats = ServiceHandler({
  url: '/api/claimsmgmt/v1/aggregate/incurred',
  paramsSerializer: (params) =>
    standardQuerySerializer(claimsParamSerializer(params)),
});

export const getClaimsInvoiceStats = ServiceHandler({
  url: '/api/claimsmgmt/v1/aggregate/invoices',
  paramsSerializer: (params) =>
    standardQuerySerializer(claimsParamSerializer(params)),
});

export const deleteClaimsInvoice = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/invoice/:invoiceId',
  method: 'DELETE',
});

// status changes
export const verifyClaimsInvoice = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/invoice/verify/:invoiceId',
  method: 'PUT',
});

export const approveClaimsInvoice = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/invoice/approve/:invoiceId',
  method: 'PUT',
});

export const payClaimsInvoice = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/invoice/pay/:invoiceId',
  method: 'PUT',
});

export const rejectClaimsInvoice = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/invoice/reject/:invoiceId',
  method: 'PUT',
});

export const getDefaultReserveCategory = ServiceHandler({
  url: '/api/claimsmgmt/v1/category/:vendorSubCategory',
});
