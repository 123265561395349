import React from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';

// components
import {
  Box,
  Card,
  CardActions,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../../Buttons/Button';

export const TableValidationNotification = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  function handleDismiss() {
    closeSnackbar(props.id);
  }

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card>
        <CardActions className={classes.actionRoot}>
          <Typography variant="subtitle2" className={classes.typeography}>
            {props.message}
          </Typography>
          <IconButton onClick={handleDismiss} size="small">
            <CloseIcon className={classes.actionIconRoot} />
          </IconButton>
        </CardActions>
        <Paper className={classes.paperRoot}>
          <Typography gutterBottom>
            Hey there! We've updated our platform, we detected the link you used
            to get to this page had some out of date information. We've updated
            the page for you, if you used a bookmark to get here, please
            consider updating the bookmark.
          </Typography>
          <Box textAlign="right">
            <Button onClick={handleDismiss} stylename="primary" fixed size="sm">
              Got it!
            </Button>
          </Box>
        </Paper>
      </Card>
    </SnackbarContent>
  );
});

const useStyles = makeStyles(({ palette }) => ({
  actionRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.83rem',
    // background: palette.common.cowbellBlue,
    background: palette.common.yellow,
    color: palette.text.highlighter,
  },

  actionIconRoot: {
    color: palette.text.highlighter,
  },

  paperRoot: {
    maxWidth: 400,
    padding: '0.83rem',
    background: palette.background.modal,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    color: palette.primary.contrastText,
  },
}));
