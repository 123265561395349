import React, { Component } from 'react';
import { withSnackbar } from 'notistack';

import { compose } from 'redux';
import { DialogContent, FormHelperText } from '@mui/material';
import { delayedEvent, PubSub } from '../../utils/eventUtils';
import { refreshCustomerFirmographicData } from '../../accounts/AccountService';
import { refreshAgencyFirmographicData } from '../../agencies/AgencyService';

import { COWBELL_ACCOUNTS } from '../tables/table_constants';

import { withQueryClient } from '../hocs/withQueryClient';
import { withToggleModal } from '../hocs/withToggleModal';

class RefreshFirmographic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountName: '',
      accountId: '',
      error: '',
    };
  }

  componentDidMount() {
    const {
      props: { data },
    } = this;
    this.setState({
      accountName: data.name,
      accountId: data.id,
    });

    this.subscription = PubSub.subscribe('foot-button-one', (subData) => {
      if (subData) {
        this.handleSubmit();
      }
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  handleSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    const { data, queryClient } = this.props;

    if (data.isAgency) {
      refreshAgencyFirmographicData(this.state.accountId)
        .then(() => {
          PubSub.publish('loading', false);
          PubSub.publish('disabled', false);
          this.props.toggleModal.direct('RefreshFirmographic', false);
          this.props.enqueueSnackbar('Data Refreshed Successfully!', {
            variant: 'success',
          });
          delayedEvent('table-refetch', 500, 'agencies');
        })
        .catch((error) => {
          PubSub.publish('loading', false);
          PubSub.publish('disabled', false);
          this.setState({ error: error.response.data });
        });
    } else {
      refreshCustomerFirmographicData(this.state.accountId)
        .then(() => {
          PubSub.publish('loading', false);
          PubSub.publish('disabled', false);
          this.props.toggleModal.direct('RefreshFirmographic', false);
          this.props.enqueueSnackbar('Data Refreshed Successfully!', {
            variant: 'success',
          });
          queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
        })
        .catch((error) => {
          PubSub.publish('loading', false);
          PubSub.publish('disabled', false);
          this.setState({ error: error.response.data });
        });
    }
  };

  render() {
    return (
      <DialogContent>
        <div className="modal-title">
          Are you sure you want to refresh data for
          <span className="modal-blue-text"> {this.state.accountName}</span>?
        </div>
        <FormHelperText className="api-text-error">
          {this.state.error}
        </FormHelperText>
      </DialogContent>
    );
  }
}

RefreshFirmographic.defaultProps = {
  data: {},
};

export default compose(
  withQueryClient,
  withSnackbar,
  withToggleModal
)(RefreshFirmographic);
