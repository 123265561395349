import React from 'react';

// mui
import {
  Box,
  Card,
  Divider,
  styled,
  Typography as TypographyBase,
  useTheme,
} from '@mui/material';

// components
import { Flexbox } from '../../../../../../components/layout/Flexbox';

// utils
import { formatPolicySummaryData } from '../policy-details.utils';
import { getDeductibleLanguage } from '../../../../../../i18n/utils';

export const PolicySummary = ({ policy = {}, product = null }) => {
  const { palette } = useTheme();

  const {
    limit,
    deductible,
    uiTotalPremium,
    effectiveDate,
    expiresOn,
    issuedDate,
    uiTerm,
  } = policy;

  const { uiLimit, uiDeductible, uiEffectiveDate, uiExpiresOn, uiIssuedDate } =
    formatPolicySummaryData({
      limit,
      deductible,
      effectiveDate,
      expiresOn,
      issuedDate,
      product,
    });

  return (
    <PolicySummaryCard square>
      <Box>
        <Typography variant="subtitle2" color="primary">
          POLICY SUMMARY
        </Typography>
      </Box>
      <Divider style={{ width: '100%', margin: '0.5rem 0 1rem 0' }} />
      <Box>
        <Flexbox>
          <Box width="50%" marginRight={1}>
            <Flexbox>
              <Typography variant="caption">Aggregate Limit</Typography>
              <Typography>{uiLimit}</Typography>
            </Flexbox>
            <Flexbox>
              <Typography>{getDeductibleLanguage(product)}</Typography>
              <Typography>{uiDeductible}</Typography>
            </Flexbox>
            <Flexbox>
              <Typography style={{ color: palette.text.text12 }}>
                Total Premium
              </Typography>
              <Typography>{uiTotalPremium}</Typography>
            </Flexbox>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box width="50%" marginLeft={1}>
            <Flexbox>
              <Typography>Policy Issued</Typography>
              <Typography>{uiIssuedDate}</Typography>
            </Flexbox>
            <Flexbox>
              <Typography>Policy Effective</Typography>
              <Typography>{uiEffectiveDate}</Typography>
            </Flexbox>
            <Flexbox>
              <Typography>Policy Expiration</Typography>
              <Typography>{uiExpiresOn}</Typography>
            </Flexbox>
            <Flexbox>
              <Typography>Policy Term</Typography>
              <Typography>{uiTerm} days</Typography>
            </Flexbox>
          </Box>
        </Flexbox>
      </Box>
    </PolicySummaryCard>
  );
};

const Typography = ({ children, ...props }) => (
  <TypographyBase variant="caption" {...props}>
    {children}
  </TypographyBase>
);

const PolicySummaryCard = styled(Card)(({ theme: { palette } }) => ({
  padding: '1rem',
  backgroundColor: palette.background.default,
}));
