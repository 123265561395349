import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import ToggleButton from '@mui/lab/ToggleButton';
// helpers
import {
  reasons,
  accountReasons,
} from '../../console/_statics/decline.statics';
import { delayedEvent } from '../../utils/eventUtils';
// componenets
import { withShowable } from '../../console/_global/lib/withShowable';
import CbButton from '../Buttons/CbButton';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/withFormController';
import { InputLabelBase } from '../inputs/InputLabelBase';
import { _isOneOf } from '../../utils/data.utils';
import { RegisteredToggleButtonGroup } from '../Buttons/RegisteredToggleButtonGroup';
// actions
import { declineQuote } from '../../console/agencies/_services/quotes.service';
import { declineAccount } from '../../console/agencies/_services/accounts.service';
import { manageAPIError } from '../../utils';

const TextField = withFormController(TextFieldBase);
const ShowGrid = withShowable(MuiGrid);

const styles = ({ palette, config }) => {
  return {
    declineButton: {
      border: 'none',
      backgroundColor: palette.background.red,
    },
    header: {
      fontSize: config.textSizes.primer,
      color: palette.primary.contrastText,
      '& p': {
        margin: 0,
      },
    },
    select: {
      justifyContent: 'left',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    label: {
      padding: '1.5rem 0 0.5rem 1rem',
      fontSize: config.textSizes.normal,
    },
    toggleButton: {
      padding: '0.5rem 4rem',
    },
    accountLabel: {
      justifyContent: 'center',
      fontWeight: 600,
      fontSize: config.textSizes.primer,
    },
    error: {
      color: config.colors.textRed,
      textAlign: 'left',
      marginTop: 0,
    },
  };
};

const schema = Yup.object().shape({
  reasonSummary: Yup.string().ensure().required().label('Declaration Reason'),
  reason: Yup.string().required().label('Reason Description'),
  reasonFillIn: Yup.string().when(['reasonSummary'], {
    is: (reasonSummary) => _isOneOf(reasonSummary, ['Fill-in option']),
    then: Yup.string().ensure().required().label('Fill-In text'),
    otherwise: Yup.string(),
  }),
  accountReasonSummary: Yup.string()
    .label('Declaration Reason')
    .when(['accountDecline'], {
      is: (accountReasonSummary) => accountReasonSummary,
      then: Yup.string().required().ensure(),
      otherwise: Yup.string(),
    }),
  accountReason: Yup.string()
    .label('Reason Description')
    .when(['accountDecline'], {
      is: (accountReason) => accountReason,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
  accountReasonFillIn: Yup.string().when(
    ['accountReasonSummary', 'accountDecline'],
    {
      is: (accountReasonSummary, accountDecline) =>
        _isOneOf(accountReasonSummary, ['Fill-in option']) && accountDecline,
      then: Yup.string().ensure().required().label('Fill-In text'),
      otherwise: Yup.string(),
    }
  ),
});

export const DeclineQuoteConfirm = withStyles(styles)(
  ({ classes, close, data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [error, setError] = useState('');
    const [showFillIn, setShowFillIn] = useState(false);
    const [accountShowFillIn, setAccountShowFillIn] = useState(false);

    const defaultValues = {
      reason: '',
      reasonSummary: '',
      reasonFillIn: '',
      accountDecline: false,
      accountReason: '',
      accountReasonSummary: '',
      accountReasonFillIn: '',
    };

    const { handleSubmit, getValues, ...methods } = useForm({
      defaultValues,
      validationSchema: schema,
    });
    const {
      formState: { isSubmitting },
    } = methods;

    const values = getValues();

    const handleReasonType = (event) => {
      methods.setValue(event.target.name, event.target.value);
      if (event.target.value === reasons[6].value) {
        setShowFillIn(true);
        values.reasonFillIn = '';
      } else {
        setShowFillIn(false);
        values.reasonFillIn = event.target.value;
      }
    };

    const handleAccountReasonType = (event) => {
      const { name, value } = event.target;
      methods.setValue(name, value);
      if (value === accountReasons[4].value) {
        setAccountShowFillIn(true);
        values.accountReasonFillIn = '';
      } else {
        setAccountShowFillIn(false);
        values.accountReasonFillIn = value;
      }
    };

    const renderOptions = ({ label, value }) => {
      return (
        <MuiMenuItem className={classes.menuItem} key={label} value={value}>
          {label}
        </MuiMenuItem>
      );
    };

    const onSubmit = (formData) => {
      const reasonSummary = showFillIn
        ? formData.reasonFillIn
        : formData.reasonSummary;
      // schema.validate({ reason: formData.reason, reasonSummary });

      if (formData.accountDecline) {
        const accountReasonSummary = accountShowFillIn
          ? formData.accountReasonFillIn
          : formData.accountReasonSummary;
        // schema.validate({
        //   reason: formData.accountReason,
        //   accountReasonSummary,
        // });
      }

      if (formData.accountDecline) {
        const accountPayload = {
          productType: data.product,
          reason: formData.accountReason,
          reasonSummary: formData.accountReasonSummary,
        };

        declineAccount({ id: data.accountId }, accountPayload)
          .then(() => {
            enqueueSnackbar('Account Declined Successfully!', {
              variant: 'success',
            });
          })
          .catch((err) => {
            const errorMessage = manageAPIError(
              err,
              'Unable to decline account.'
            );

            setError(errorMessage);
          });
      }

      const payload = {
        productType: data.product,
        reason: formData.reason,
        reasonSummary,
      };

      return declineQuote({ id: data.id }, payload)
        .then(() => {
          close();
          enqueueSnackbar('Quote Declined Successfully!', {
            variant: 'success',
          });
          delayedEvent('table-refetch', 500, 'adminQuotes');
        })
        .catch((error) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(error.response, 'data', 'Unable to decline quote')
            )
          );
        });
    };

    return (
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            className="modal-title"
            style={{ textAlign: 'left', padding: '2rem 5rem' }}
          >
            <div className={classes.header}>
              <p>Are you sure you want to decline the quote?</p>
              <p> Please provide a reason for the declination below.</p>
            </div>

            <MuiGrid style={{ paddingBottom: '1rem' }}>
              <InputLabelBase required className={classes.label}>
                Reason for Declination
              </InputLabelBase>

              <MuiSelect
                variant="standard"
                name="reasonSummary"
                required
                onChange={handleReasonType}
                values={values.reasonSummary}
                classes={{
                  select: classes.select,
                  icon: classes.selectIcon,
                }}
                placeholder="please select a reason"
                ref={methods.register({ name: 'reasonSummary' })}
                error={
                  !values.reasonSummary &&
                  _.get(methods, 'errors.reasonSummary.message')
                }
              >
                {reasons.map(renderOptions)}
              </MuiSelect>
              {!values.reasonSummary && (
                <FormHelperText className={classes.error}>
                  {_.get(methods, 'errors.reasonSummary.message')}
                </FormHelperText>
              )}
            </MuiGrid>

            <ShowGrid show={showFillIn}>
              <TextField name="reasonFillIn" required />
            </ShowGrid>

            <MuiGrid>
              <TextField
                label="Declination Description"
                name="reason"
                multiline
                required
                maxRows={6}
                minRows={6}
              />
            </MuiGrid>

            <MuiGrid>
              <div style={{ textAlign: 'center' }}>
                <InputLabelBase className={classes.accountLabel}>
                  Are you also declining the entire account?
                </InputLabelBase>
                <RegisteredToggleButtonGroup name="accountDecline" exclusive>
                  <ToggleButton value className={classes.toggleButton}>
                    <div>Yes</div>
                  </ToggleButton>
                  <ToggleButton value={false} className={classes.toggleButton}>
                    <div>No</div>
                  </ToggleButton>
                </RegisteredToggleButtonGroup>
              </div>
            </MuiGrid>

            {values.accountDecline && (
              <>
                <MuiGrid style={{ paddingBottom: '1rem' }}>
                  <InputLabelBase required className={classes.label}>
                    Reason for Account Declination
                  </InputLabelBase>

                  <MuiSelect
                    variant="standard"
                    name="accountReasonSummary"
                    required
                    onChange={handleAccountReasonType}
                    values={values.accountReasonSummary}
                    classes={{
                      select: classes.select,
                      icon: classes.selectIcon,
                    }}
                    placeholder="please select a reason"
                    ref={methods.register({ name: 'accountReasonSummary' })}
                    error={
                      !values.accountReasonSummary &&
                      _.get(methods, 'errors.accountReasonSummary.message')
                    }
                  >
                    {accountReasons.map(renderOptions)}
                  </MuiSelect>
                  {!values.accountReasonSummary && (
                    <FormHelperText className={classes.error}>
                      {_.get(methods, 'errors.accountReasonSummary.message')}
                    </FormHelperText>
                  )}
                </MuiGrid>

                <ShowGrid show={showFillIn}>
                  <TextField name="accountReasonFillIn" required />
                </ShowGrid>

                <MuiGrid>
                  <TextField
                    label="Account Declination Description"
                    name="accountReason"
                    multiline
                    required
                    maxRows={6}
                    minRows={6}
                  />
                </MuiGrid>
              </>
            )}

            {error && (
              <FormHelperText className="api-text-error">
                {error}
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
            <CbButton
              type="submit"
              styleName="ctaButton"
              buttonText="Decline"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </DialogActions>
        </form>
      </FormContext>
    );
  }
);

export const DeclineQuoteConfirmConfig = {
  DeclineQuoteConfirm: {
    component: DeclineQuoteConfirm,
    config: {
      fullWidth: false,
      maxWidth: 'md',
      title: 'Decline Quote',
      scroll: 'body',
    },
  },
};
