import React from 'react';

import type { SliderProps, BoxProps } from '@mui/material';
import { Box, Slider } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface TickedSliderProps extends Omit<SliderProps, 'onChange' | 'children'> {
  onChange?: (newValue: number | number[]) => void;
  initialSliderValue: number | number[];
  children?: (sliderValue: number | number[] | undefined) => JSX.Element;
  boxProps?: BoxProps;
}

const TickedSlider = ({
  onChange,
  initialSliderValue,
  children,
  boxProps = {},
  ...props
}: TickedSliderProps) => {
  const [sliderValue, setSliderValue] = React.useState<number | number[]>();
  const classes = useStyles();

  React.useEffect(() => {
    setSliderValue(initialSliderValue);
  }, [initialSliderValue]);

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    setSliderValue(newValue);

    onChange?.(newValue);
  };

  return (
    <Box display="flex" {...boxProps}>
      <Slider
        marks
        defaultValue={initialSliderValue}
        value={sliderValue ?? initialSliderValue}
        classes={classes}
        onChange={handleSliderChange}
        {...props}
      />
      {typeof children === 'function' ? (
        children(sliderValue)
      ) : (
        <span>{sliderValue}</span>
      )}
    </Box>
  );
};

export default TickedSlider;

const useStyles = makeStyles(({ palette }) => ({
  // root: {
  //   '&:hover > .MuiSlider-markLabel': {
  //     opacity: 1,
  //   },
  // },
  valueLabel: {
    color: 'transparent',
  },
  marked: {
    marginBottom: 0,
  },
  markLabel: {
    fontSize: '0.8rem',
    color: palette.text.contrastText,
    opacity: 0,
    transition: 'opacity 0.5s',
    top: 19,
    fontWeight: 600,
  },
  mark: {
    height: 8,
    width: 2,
  },
  thumb: {
    width: 10,
    height: 10,
  },
}));
