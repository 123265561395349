export default ({ palette }: any) =>
  ({
    overrides: {
      MuiDialogTitle: {
        root: {
          padding: '1.25rem',
          margin: 0,
          position: 'relative',

          '&::before': {
            content: '""',
            width: 'calc(100% - 2.5rem)',
            height: 1,
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            background: palette.primary.border,
          },

          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          lineHeight: 1.1,

          '& > div': {
            maxWidth: '75%',
          },
        },
      },

      MuiDialog: {
        root: {
          overflowY: 'auto',
        },
        paper: {
          overflowY: 'auto',
        },
        paperScrollPaper: {
          maxHeight: 'calc(100% - 64px)',
        },
      },

      MuiDialogContent: {
        root: {
          padding: '4.58rem 5rem 4.58rem 5rem',
          overflowY: 'auto',

          // Temporarily fixes a bug with MUI 5
          // https://github.com/mui/material-ui/issues/27851
          '&&': {
            paddingTop: '4.58rem',
          },

          // we're undoing other strong-armed styles from base overrides
          '&.paf': {
            padding: '4.58rem 5rem 4.58rem 5rem',
          },
        },
      },
    },
    props: {
      MuiDialog: {
        fullWidth: true,
        maxWidth: 'sm',
      },
    },
  } as const);
