import React from 'react';
import { SvgIcon } from '@mui/material';

export default function RenewalIconDark({ fill = '#cacdd9', ...props }) {
  return (
    <SvgIcon viewBox="0 0 43 46" {...props}>
      <path
        id="Path_7853"
        data-name="Path 7853"
        d="M35.938,31.969A17.969,17.969,0,1,1,17.969,14,17.969,17.969,0,0,1,35.938,31.969"
        transform="translate(0 -3.938)"
        fill="#b1c2de"
      />
      <path
        id="Path_7854"
        data-name="Path 7854"
        d="M42.063,6.031H35.594V3.156A2.156,2.156,0,0,0,33.438,1H11.156A2.156,2.156,0,0,0,9,3.156V5.313h4.313V31.188a2.156,2.156,0,0,0,2.156,2.156h6.469v5.031H44.219V8.188a2.156,2.156,0,0,0-2.156-2.156"
        transform="translate(-2.531 -0.281)"
        fill="#465266"
      />
      <path
        id="Line_64"
        data-name="Line 64"
        d="M4.031.438H-.281A.719.719,0,0,1-1-.281.719.719,0,0,1-.281-1H4.031a.719.719,0,0,1,.719.719A.719.719,0,0,1,4.031.438Z"
        transform="translate(14.219 33.344)"
        fill="#465266"
      />
      <path
        id="Path_7855"
        data-name="Path 7855"
        d="M31.475,38.813H20.937V7.906a1.438,1.438,0,0,0-2.875,0v23a2.875,2.875,0,0,1-5.75,0V2.875a1.437,1.437,0,1,0-2.875,0V4.313h1.437a.719.719,0,0,1,0,1.438H8V2.875a2.875,2.875,0,1,1,5.75,0V30.906a1.438,1.438,0,0,0,2.875,0v-23a2.875,2.875,0,0,1,5.75,0V37.375h9.1a.719.719,0,1,1,0,1.438Z"
        transform="translate(-2.25)"
        fill="#b1c2de"
      />
      <path
        id="Path_7856"
        data-name="Path 7856"
        d="M48.875,40.781H44.857a.719.719,0,0,1,0-1.437h2.581V9.875A1.439,1.439,0,0,0,46,8.438H23.719a.719.719,0,0,1,0-1.437H46a2.878,2.878,0,0,1,2.875,2.875Z"
        transform="translate(-6.469 -1.969)"
        fill="#b1c2de"
      />
      <path
        id="Path_7857"
        data-name="Path 7857"
        d="M37.156,4.313H35.719V2.875a1.439,1.439,0,0,0-1.437-1.437H12V0H34.281a2.878,2.878,0,0,1,2.875,2.875Z"
        transform="translate(-3.375)"
        fill="#b1c2de"
      />
      <path
        id="Path_7858"
        data-name="Path 7858"
        d="M44,49v8.266l2.156-1.437,2.156,1.438V49Z"
        transform="translate(-12.375 -13.781)"
        fill="#465266"
      />
      <path
        id="Path_7859"
        data-name="Path 7859"
        d="M43,48h5.75V58.327l-2.875-1.917L43,58.327Zm4.313,1.438H44.438v6.2l1.438-.958,1.438.958Z"
        transform="translate(-12.094 -13.5)"
        fill="#b1c2de"
      />
      <path
        id="Path_7860"
        data-name="Path 7860"
        d="M49.625,42.313A4.312,4.312,0,1,1,45.313,38a4.312,4.312,0,0,1,4.313,4.313"
        transform="translate(-11.531 -10.688)"
        fill="#465266"
      />
      <path
        id="Path_7861"
        data-name="Path 7861"
        d="M45.032,47.062a5.031,5.031,0,1,1,5.031-5.031A5.036,5.036,0,0,1,45.032,47.062Zm0-8.624a3.593,3.593,0,1,0,3.594,3.594A3.6,3.6,0,0,0,45.032,38.438Z"
        transform="translate(-11.25 -10.406)"
        fill="#b1c2de"
      />
      <path
        id="Line_65"
        data-name="Line 65"
        d="M10.5.438H-.281A.719.719,0,0,1-1-.281.719.719,0,0,1-.281-1H10.5a.719.719,0,0,1,.719.719A.719.719,0,0,1,10.5.438Z"
        transform="translate(25.438 18.25)"
        fill="#b1c2de"
      />
      <path
        id="Line_66"
        data-name="Line 66"
        d="M6.188.438H-.281A.719.719,0,0,1-1-.281.719.719,0,0,1-.281-1H6.188a.719.719,0,0,1,.719.719A.719.719,0,0,1,6.188.438Z"
        transform="translate(25.438 21.125)"
        fill="#b1c2de"
      />
      <path
        id="Line_67"
        data-name="Line 67"
        d="M10.5.438H-.281A.719.719,0,0,1-1-.281.719.719,0,0,1-.281-1H10.5a.719.719,0,0,1,.719.719A.719.719,0,0,1,10.5.438Z"
        transform="translate(25.438 15.375)"
        fill="#b1c2de"
      />
      <path
        id="Line_68"
        data-name="Line 68"
        d="M.438,4.313H-1V0H.438Z"
        transform="translate(4.594 26.594)"
        fill="#465266"
      />
      <path
        id="Line_69"
        data-name="Line 69"
        d="M4.313.438H0V-1H4.313Z"
        transform="translate(2.156 29.031)"
        fill="#465266"
      />
      <path
        id="Line_70"
        data-name="Line 70"
        d="M.438,4.313H-1V0H.438Z"
        transform="translate(11.063 37.375)"
        fill="#465266"
      />
      <path
        id="Line_71"
        data-name="Line 71"
        d="M4.313.438H0V-1H4.313Z"
        transform="translate(8.625 39.813)"
        fill="#465266"
      />
    </SvgIcon>
  );
}
