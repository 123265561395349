import React from 'react';
import { compose } from 'redux';
import classnames from 'classnames';

// components
import { Button as MuiButton } from '@mui/material';

import { withStyles } from '@mui/styles';
import { withShowable } from '../../console/_global/lib/withShowable';

export const Button = compose(
  withShowable,
  withStyles(({ config, palette, shape }) => ({
    root: {
      lineHeight: 1,
      letterSpacing: 1,
      textTransform: 'unset',
    },

    primary: {
      padding: '0.916rem 0',
      backgroundColor: palette.common.cowbellBlue,
      color: 'white',
      // fontSize: config.textSizes.tertia,
      textTransform: 'uppercase',
      borderRadius: shape.radius,

      '&:hover, &:focus': {
        backgroundColor: palette.background.darkerBlue,
      },

      '&:disabled': {
        backgroundColor: palette.button.disabledBackground,
        color: palette.button.disabledText,
      },
    },

    secondary: {
      padding: '0.916rem 0',
      backgroundColor: palette.common.cowbellGreen2,
      color: 'white',
      // fontSize: config.textSizes.tertia,
      textTransform: 'uppercase',
      borderRadius: shape.radius,

      '&:hover, &:focus': {
        backgroundColor: palette.common.cowbellGreenDarkGreen,
      },

      '&:disabled': {
        backgroundColor: palette.button.disabledBackground,
        color: palette.button.disabledText,
      },
    },

    pill: {
      padding: '5px 28px',
      border: `1px solid ${palette.common.cowbellGreen2}`,
      borderRadius: '50px',
      color: palette.primary.contrastText,

      '&.selected': {
        backgroundColor: palette.common.cowbellGreen2,
        color: palette.common.darkGrey,
      },

      '&:disabled': {
        borderColor: palette.primary.border,
      },

      '&:disabled.selected': {
        backgroundColor: 'transparent',
      },
    },

    xs: {
      '&.fixed': {
        width: '6.91rem',
      },
    },

    sm: {
      '&.fixed': {
        width: '7.5rem',
      },
    },

    md: {
      '&.fixed': {
        width: '12.5rem',
      },
    },

    lg: {
      '&.fixed': {
        width: '20.41rem',
      },
    },
  }))
)(({ classes, fixed = false, size = 'md', selected, stylename, ...props }) => {
  const rootClasses = classnames(classes.root, {
    [classes.primary]: stylename === 'primary',
    [classes.secondary]: stylename === 'secondary',
    [classes.pill]: stylename === 'pill',
    [classes[size]]: size,
    fixed,
    selected,
  });

  return <MuiButton className={rootClasses} {...props} />;
});
