import React from 'react';

// mui
import {
  Box,
  DialogActions,
  DialogContent as DialogContentBase,
  styled,
} from '@mui/material';

// components
import CbButton from '../../../../../components/Buttons/CbButton';
import { toUniversalDate } from '../../../../../utils/date.utils';

export const ModalContent = ({ children }) => {
  return <DialogContent className="contrast-text">{children}</DialogContent>;
};

export const ModalDetails = ({ quoteData }) => {
  return (
    <>
      <Box paddingTop="1rem">
        <RowContainer>
          <RowTitle>Total Premium:</RowTitle>
          <Box>{quoteData.premium}</Box>
        </RowContainer>
        <RowContainer>
          <RowTitle>Effective Date:</RowTitle>
          <Box>{toUniversalDate(quoteData.effectiveDateOfPolicy)}</Box>
        </RowContainer>
      </Box>
    </>
  );
};

export const ModalActions = ({ onClick, ...props }) => {
  return (
    <DialogActions>
      <CbButton
        styleName="ctaButton"
        buttonText="Confirm"
        onClick={onClick}
        {...props}
      />
    </DialogActions>
  );
};

const DialogContent = styled(DialogContentBase)({
  fontSize: '1.2rem',
  textAlign: 'center',
  padding: '2rem 3rem',
});

const RowContainer = styled(Box)({
  display: 'flex',
  width: '17.5rem',
  margin: '0 auto',
});

const RowTitle = styled(Box)({
  fontWeight: 600,
  width: '10rem',
  textAlign: 'left',
});
