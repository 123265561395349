import _ from 'lodash';
import React, { useContext } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { TableCell as MuiTableCell } from '@mui/material';

import { TableContext } from '../base/context.table';
import { TableRow } from '../base/row.table';

export function withLoader(Component) {
  const C = (props) => {
    const { columns, data, loading } = useContext(TableContext);

    if (loading || (loading && _.isEmpty(data.length))) {
      return (
        <Component {...props}>
          {Array(data.length || 6)
            .fill(null)
            .map((row, i) => (
              <TableRow
                className={loading && props.classes.rowLoading}
                key={`loading-row-${i}`}
              />
            ))}
        </Component>
      );
    }

    if (!loading && _.isEmpty(data.length)) {
      return (
        <Component {...props}>
          <TableRow>
            <MuiTableCell colSpan={columns.length}>No Data</MuiTableCell>
          </TableRow>
        </Component>
      );
    }

    return <Component {...props} />;
  };

  C.displayName = `withExpandableRow(${
    Component.displayName || Component.name
  })`;
  C.wrappedComponent = Component;

  return hoistNonReactStatics(C, Component);
}
