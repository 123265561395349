import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import _ from 'lodash';

import { DialogActions, DialogContent, FormHelperText } from '@mui/material';

import { useSnackbar } from 'notistack';
import { BulkDelete } from '../../../../console/agencies/_services/locations.service';
import CbButton from '../../../Buttons/CbButton';
import { delayedEvent } from '../../../../utils/eventUtils';

export const DeleteLocationModal = ({ data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');
  const { ...methods } = useForm();

  const bulk = _.isArray(data);
  function onSubmit(locationIds, address = '') {
    const id = bulk === true ? data : locationIds;
    return BulkDelete({ locationIds: id })
      .then(() => {
        enqueueSnackbar(`Location ${address} Removed Successfully!`, {
          variant: 'success',
        });
        delayedEvent('table-refetch', 1000, 'admin_locations');
        delayedEvent('table-refetch', 1000, 'agency_locations');
        props.close();
      })
      .catch((error) => {
        setError(
          _.get(error, 'response.data', 'Server Error, please try again')
        );
      });
  }

  return (
    <FormContext {...methods}>
      <DialogContent style={{ paddingTop: 25, paddingBottom: 25 }}>
        <div className="modal-title">
          Are you sure you want to remove the following location(s){' '}
          <span className="modal-blue-text"> {data.locationName}</span>?
        </div>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <CbButton onClick={props.close} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton
          onClick={() => {
            onSubmit(data.id, data.locationName);
          }}
          type="submit"
          styleName="ctaButton"
        >
          Remove Location
        </CbButton>
      </DialogActions>
    </FormContext>
  );
};

export const DeleteLocationModalConfig = {
  DeleteLocationModal: {
    component: DeleteLocationModal,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      title: 'Delete Location(s)',
    },
  },
};
