import { PERSONA_TYPES } from '../console/_statics/persona.statics';
import { ProductTypes } from '../types';
import type { ProductType } from '../types';
import { ServiceHandler } from '../utils';

export const updatePolicyEffectiveDate = ServiceHandler({
  url: '/api/quote/v1/:quoteId/update/effective-date',
  method: 'PUT',
});

export const calculatePremiumWithEffDate = ServiceHandler({
  url: '/api/quote/v1/:quoteId/recalculate/effective-date',
  method: 'POST',
});

export const updateQuoteBindOrder = ServiceHandler({
  url: '/api/quote/v1/:quoteId/bind-order',
  method: 'PUT',
});

type BindQuotePersonas =
  | typeof PERSONA_TYPES.AGENCY
  | typeof PERSONA_TYPES.COWBELL;

export const bindQuote = (
  persona: BindQuotePersonas,
  productType: ProductType,
  quoteId: string
) => {
  if (
    persona == PERSONA_TYPES.AGENCY &&
    (productType == ProductTypes.p100 || productType == ProductTypes.p250)
  ) {
    return agentBindQuote(quoteId);
  }

  return bindQuoteByProduct(productType, quoteId);
};

export const bindQuoteByProduct = ServiceHandler({
  url: '/api/quote/v1/:productType/bind/:quoteId',
  method: 'PUT',
});

export const agentBindQuote = ServiceHandler({
  url: '/api/quote/v1/:quoteId/agent-bind',
  method: 'PUT',
});
