import { Box } from '@mui/material';
import { Pagination } from '@mui/lab';
import { styled } from '@mui/styles';

import { useAgencyDashboardState } from '../AgencyDashboardStateContext';

interface PaginationProps {
  totalPages: number;
}

export const AgencyDashboardListingPagination = ({
  totalPages,
}: PaginationProps) => {
  const { currentTab, onUpdateQuery, activeQuery } = useAgencyDashboardState();

  if (totalPages <= 1) {
    return null;
  }

  const onChange = (event: any, nextPage: number) => {
    onUpdateQuery(currentTab, { page: nextPage - 1 });
  };

  return (
    <PositionContainer>
      <Pagination
        page={(activeQuery.page ?? 0) + 1}
        onChange={onChange}
        count={totalPages}
        style={{ padding: '0.6rem' }}
      />
    </PositionContainer>
  );
};

const PositionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: '0.8rem 0',
  justifyContent: 'center',
  alignItems: 'center',

  position: 'absolute',
  bottom: 0,
  backgroundColor: theme.agencyDash.background.paper,
  borderRadius: 5,
}));
