import { ServiceHandlerFactory } from '../../../utils/serviceUtils';
import { trimAndAddAsterisk } from '../../../utility/QueryUtility';
import { ServiceHandler } from '../../../utils';

export const getAgencyTeams = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/teams/all',
  },
  isCancelable: true,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    {
      path: 'search',
      transform: ({ search, searchValue }) => {
        if (searchValue) {
          return searchValue;
        }
        return search ? trimAndAddAsterisk(search) : null;
      },
    },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          default:
            return orderBy ? `${orderBy},${order}` : null;
        }
      },
    },
  ],
});

export const createAgencyTeam = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/teams',
    method: 'post',
  },
});

export const updateAgencyTeam = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/teams/:id',
    method: 'put',
  },
});

export const deleteAgencyTeam = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/teams/:id',
    method: 'delete',
  },
});

export const bulkMigrateTeams = ServiceHandler({
  url: '/api/auth/v1/users/teams/migrate/:userId',
  method: 'PUT',
});
