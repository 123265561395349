import { Box, Divider, styled, Typography } from '@mui/material';
import { withShowable } from '../../../../_global/lib/withShowable';

export const Flexbox = ({ children, ...props }) => {
  return (
    <Box display="flex" justifyContent="space-between" {...props}>
      {children}
    </Box>
  );
};

export const CaptionTypography = ({ children, ...props }) => (
  <Typography variant="caption" {...props}>
    {children}
  </Typography>
);

export const AccountDataTypography = styled(CaptionTypography)(
  ({ theme: { palette, config } }) => ({
    color: config.colors.cowbellBlue,
    lineHeight: 1.2,
  })
);

export const AccountData = withShowable(({ data, children }) => {
  return (
    <>
      <Divider style={{ width: '100%', margin: '0.25rem 0' }} />
      <AccountDataTypography>{data || children}</AccountDataTypography>
    </>
  );
});
