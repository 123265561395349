import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form-4';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
// mui
import { FormHelperText, DialogContent, DialogActions } from '@mui/material';
// services
import { assignAgency, fetchAgencies } from '../../api/apis';
// platform helpers

import CbButton from '../Buttons/CbButton';
import { reduceForAgencies } from '../../console/admin/users/UsersUtils';
// components
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/withFormController';
import { ScrollableMultiSelect } from '../Selects/MultiSelect/ScrollableMultiSelect';
// constants
import { COWBELL_ACCOUNTS } from '../tables/table_constants';
import { useCowbellTranslations } from '../../i18n/translations';

const TextField = withFormController(TextFieldBase);

export const SoftAssignAgency = ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = React.useState('');
  const [agencies, setAgencies] = React.useState([]);
  const [totalPages, setTotal] = React.useState(1);
  const { translations } = useCowbellTranslations(['Agency', 'Agencies']);

  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      accountName: data.name,
      agencySelected: '',
    },
  });

  const queryClient = useQueryClient();

  const values = getValues();

  React.useEffect(() => {
    fetchAgencies()
      // eslint-disable-next-line no-shadow
      .then((values) => {
        const result = reduceForAgencies(_.get(values, 'data.content'));
        setAgencies(result);
        setTotal(_.get(values, 'data.totalPages'));
      })
      .catch(() => {
        setError(
          `Not able to assign ${translations.Agency.toLowerCase()} at this time. Please try again later.`
        );
      });
    // eslint-disable-next-line
  }, []);

  const handleSelect = (name, selectedOption) => {
    const option = _.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];
    setValue(name, option);
  };

  const onSubmit = ({ agencySelected }) => {
    const agency = agencySelected[0];

    const payload = {
      agencyId: _.get(agency, 'value') || '',
      agencyName: _.get(agency, 'label') || '',
      agentFirstName: _.get(agency, 'agentFirstName') || '',
      agentLastName: _.get(agency, 'agentLastName') || '',
      agentEmail: _.get(agency, 'agentEmail') || '',
      agentPhoneNumber: _.get(agency, 'agentPhoneNumber') || '',
    };

    return assignAgency(data.id, payload)
      .then(() => {
        enqueueSnackbar(
          `${translations.Agency}\t${_.get(
            agency,
            'label'
          )}\tAssigned Successfully!`,
          { variant: 'success' }
        );
        close();
        queryClient.invalidateQueries([COWBELL_ACCOUNTS]);
      })
      .catch((e) => {
        const errorData = _.get(
          e,
          'response.data',
          'Not able to assign agency at this time.Please try again later.'
        );
        setError(errorData);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <TextField
          name="accountName"
          label="Account Name"
          control={control}
          readOnly
        />
        <ScrollableMultiSelect
          name="agencySelected"
          label={translations.Agencies}
          buttonPlaceholder={`Assign ${translations.Agencies}`}
          options={agencies}
          onChange={handleSelect}
          values={values.agencySelected}
          isMulti={false}
          ref={register({ name: 'agencySelected' })}
          api={(q) => fetchAgencies({ ...q, size: 100 })}
          totalPages={totalPages}
          reduceFunc={reduceForAgencies}
        />
        <FormHelperText className="api-text-error">{error}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
        <CbButton
          type="submit"
          styleName="ctaButton"
          buttonText={`Assign ${translations.Agency}`}
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </DialogActions>
    </form>
  );
};

export default SoftAssignAgency;
