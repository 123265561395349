import { DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { reinstateCancelledPolicy } from '../../../policies/PolicyService';
import CbButton from '../../Buttons/CbButton';
import { COWBELL_POLICY } from '../../tables/table_constants';

const ReinstatePolicyModal = ({ data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const onSubmit = () => {
    reinstateCancelledPolicy(data)
      .then(() => {
        enqueueSnackbar('Policy reinstated!', { variant: 'success' });
        queryClient.invalidateQueries(COWBELL_POLICY);
      })
      .catch(() =>
        enqueueSnackbar(
          'There was a problem with your request, please try again',
          { variant: 'error' }
        )
      )
      .finally(props.close);
  };

  return (
    <>
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center' }}>
          Are you sure you want to reinstate this policy for <br />
          <strong>{data.companyName}</strong>?
          <br />
          Policy number {data.policyNumber}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={props.close}>
          Cancel
        </CbButton>
        <CbButton styleName="ctaButton" onClick={onSubmit}>
          Confirm
        </CbButton>
      </DialogActions>
    </>
  );
};

export const ReinstatePolicyModalConfig = {
  ReinstatePolicyModal: {
    component: ReinstatePolicyModal,
    config: { fullWidth: true, title: 'Reinstate Policy' },
  },
};
