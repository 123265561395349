import { SelectComponent } from '../helpers';
import { useMultiSelectState } from '../useMultiSelectState';

function SelectHeader() {
  const { handleSelectAll, isAllSelected } = useMultiSelectState();

  return <SelectComponent onChange={handleSelectAll} checked={isAllSelected} />;
}

export default SelectHeader;
