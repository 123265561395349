import React from 'react';

// helpers
import _ from 'lodash';
import * as Yup from 'yup';

import { FormContext, useForm } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

// mui
import { DialogContent, DialogActions } from '@mui/material';
// components
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/withFormController';
import CBButton from '../Buttons/CbButton';
// actions
import { voidPolicy } from '../../policies/PolicyService';
// utils
import { delayedEvent } from '../../utils/eventUtils';
import { COWBELL_POLICY } from '../tables/table_constants';

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  reason: Yup.string().required().label('Reason'),
});

const VoidPolicy = ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      reason: '',
    },
    validationSchema: schema,
  });

  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (formData) => {
    const payload = {
      cancelReason: formData.reason,
    };

    return voidPolicy({ policyId: data.id, productType: data.product }, payload)
      .then(() => {
        close();
        enqueueSnackbar('Policy Voided Successfully!', { variant: 'success' });

        queryClient.invalidateQueries([COWBELL_POLICY]);
      })
      .catch((error) => {
        const message = _.get(
          error.response,
          'data.message',
          _.get(
            data.response,
            'data',
            `Cannot Void Policy. Please try again later.`
          )
        );
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2rem 5rem 3rem' }}>
          <TextField
            error={_.get(methods, 'errors.reason.message')}
            name="reason"
            label="Reason"
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <CBButton action={close} styleName="cancel" buttonText="Cancel" />
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="Void Policy"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

export const VoidPolicyConfig = {
  VoidPolicy: {
    component: VoidPolicy,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
    },
  },
};

export default VoidPolicy;
