import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// helpers
import _ from 'lodash';
import Moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  IconButton as MuiIconButton,
  MenuItem as MuiMenuItem,
} from '@mui/material';

import { useFeature } from '@growthbook/growthbook-react';
import { CANCELLED_STATUS } from '../../console/_statics/policy.statics';
import {
  fetchP250QuoteDetails,
  fetchQuoteDetails,
} from '../../inbox/QuotesService';
import { usePolicyActions } from '../../components/hooks/business-logic/policy-actions/usePolicyActions';

// components
import { ListItemBase } from '../../components/Menus/ListItemBase';
import { SimpleMenu } from '../../components/Menus/SimpleMenu';
import { withShowable } from '../../console/_global/lib/withShowable';
import { SubMenu } from '../../components/Menus/SubMenu';
import { CommonPolicyOptions } from './CommonPolicyOptions';
import { Modal } from '../../components/modals/v2/helpers/v2.modal.helpers';
// icons
import TableMenuIcon from '../../_assets/svg/TableMenuIcon.svg';
// utils
import { loadOneIncByPolicyNumber } from '../../utils/appUtils';
import { toggleModalDirect } from '../../utils/storeUtils';
// reducers
import {
  setAccountDetails,
  setAdminDetails,
} from '../../console/_tabs/tabs.reducer';
// actions
import {
  adminPaymentComplete,
  getBalanceOnPolicy,
} from '../../console/customers/_services/BillingService';
import { ProtectedMenuItem } from '../../console/agencies/inbox/table/ProtectedMenuItem';
import { ProductTypes } from '../../types';
import { createRenewalSubmission } from '../../api/submissions/renewal-submissions.api';
import { useAllow } from '../../components/hooks/useAllow';
import { UpdateAgentInfoModalConfig } from '../../components/modals/v2/UpdateAgentInfo';
import { toUniversalUtcDate } from '../../utils/date.utils';
import { generateRenewalQuote } from '../../api/policies.api';
import { useAPIErrorHandler } from '../../components/hooks/useAPIErrorHandler';
import { GenerateRenewalQuoteOption } from './GenerateRenewalQuoteOption';
import { UndoCancelledPolicyConfirmModalConfig } from '../../components/modals/v2/UndoCancelledPolicyConfirmModal';
import { AdminUpdateBillingModalConfig } from '../modals/AdminUpdateBillingModal';
import { FEATURE_FLAG_KEYS } from '../../config/growthbook';
import { UpdateCommissionModalConfig } from '../../components/modals/v2/UpdateCommissionModal';

const MenuItem = withShowable(MuiMenuItem);
const ListItem = withShowable(ListItemBase);

export const AdminPolicyOptions = ({
  data = {},
  handleTableRefetch,
  setShowCircular,
  isJQueryLoaded,
}) => {
  // functions
  const policyActions = usePolicyActions(data);
  const adminBillingUpdateFeature = useFeature(
    FEATURE_FLAG_KEYS.ADMIN_UPDATE_BILLING
  );

  // submenus
  const isPaymentsSubMenuShown =
    policyActions.CAN_APPLY_PAYMENT ||
    policyActions.CAN_ISSUE_REFUND ||
    policyActions.CAN_ADD_PREMIUM_FINANCER ||
    policyActions.CAN_CHARGE_BANK_ACCOUNT ||
    policyActions.CAN_CHARGE_CREDIT_CARD ||
    policyActions.CAN_MARK_CHECK_IN_MAIL;

  const isPolicyActionsSubmenuShown =
    policyActions.CAN_CANCEL_POLICY ||
    policyActions.CAN_UPDATE_POLICY ||
    policyActions.CAN_UPDATE_COMMISSION ||
    policyActions.CAN_UPDATE_POLICY_DATES ||
    policyActions.CAN_SEND_NOTICE_OF_CANCELLATION ||
    policyActions.CAN_FLAT_CANCEL_POLICY ||
    policyActions.CAN_REGENERATE_INVOICE ||
    policyActions.CAN_REGENERATE_POLICY_DOCUMENTS ||
    policyActions.CAN_UPDATE_AGENT_INFO ||
    policyActions.CAN_VOID_POLICY;

  // handlers
  const {
    handleChangeBillingCompliance,
    ratingViewHandler,
    hadnleUpdateActivePolicyDates,
    handleCancelPolicy,
    handleVoidPolicy,
    handleMutePaymentReminders,
    handleSendPaymentReminder,
    handleOptOutRenewal,
    handleBORChange,
    handleExpediteBOR,
    handleRescindBOR,
    updatePolicy,
    handleApplyPayment,
    handleAddFinancer,
    handleCharge,
    handleUpdateAgent,
    handleMarkCheckInMail,
    handleFlatCancel,
    handleLossRuns,
    handleRegenerateInvoice,
    regeneratePolicyDocuments,
    handleEditCommission,
    handleStopNOC,
    handleExtendPaymentNOC,
    handlePauseNOC,
    handleStartNOC,
    handleERP,
    handleUploadPolicyDoc,
    handleCreateRenewalSubmission,
    handleMuteRenewalReminder,
    handleGenerateRenewalQuote,
    handleUndoCancelledPolicy,
    handleAdminBillingUpdate,
  } = useHandlers(data, handleTableRefetch, setShowCircular);

  return (
    <SimpleMenu
      id="admin-policy-options"
      anchor={
        <MuiIconButton size="small" aria-haspopup="true">
          <TableMenuIcon fontSize="small" />
        </MuiIconButton>
      }
      minWidth="19rem"
    >
      <MenuItem
        // Explicit coercion to boolean below is intended for extra safety against nullish values
        show={Boolean(policyActions.CAN_UNDO_CANCELLED_POLICY)}
        onClick={handleUndoCancelledPolicy}
      >
        Undo Cancellation
      </MenuItem>

      <MenuItem show={policyActions.CAN_CHANGE_BOR} onClick={handleBORChange}>
        Change BOR
      </MenuItem>

      <MenuItem
        show={policyActions.CAN_CHANGE_BILLING}
        onClick={handleChangeBillingCompliance}
      >
        Update Billing/Compliance
      </MenuItem>

      <MenuItem
        show={
          policyActions.CAN_UPDATE_BILLING_ADMIN &&
          Boolean(adminBillingUpdateFeature.on)
        }
        onClick={handleAdminBillingUpdate}
      >
        Update Billing/Compliance (Admin)
      </MenuItem>

      <ProtectedMenuItem
        allow={{ accounts: 'uw' }}
        show={policyActions.CAN_GENERATE_ERP}
        onClick={handleERP}
      >
        Add ERP
      </ProtectedMenuItem>

      <MenuItem
        show={
          data.product === ProductTypes.primecloud && !data.isDocumentAvailable
        }
        onClick={handleUploadPolicyDoc}
      >
        Upload Policy Document
      </MenuItem>

      <CommonPolicyOptions data={data} />

      <GenerateRenewalQuoteOption
        onClick={handleGenerateRenewalQuote}
        show={policyActions.CAN_GENERATE_RENEWAL_QUOTE}
        policy={data}
      >
        Generate Renewal Quote
      </GenerateRenewalQuoteOption>

      <MenuItem
        show={policyActions.CAN_GENERATE_LOSS_RUNS}
        onClick={handleLossRuns}
      >
        Loss Runs
      </MenuItem>

      <MenuItem
        show={policyActions.CAN_OPTOUT_RENEWAL_PROCESS}
        onClick={handleOptOutRenewal}
        disabled={_.get(data, 'renewalOptOut', false)}
      >
        Opt-Out of Renewal Process
      </MenuItem>
      <SubMenu closeMenu header="NOC" show={policyActions.CAN_VIEW_NOC_OPTIONS}>
        <ListItem
          show={policyActions.CAN_EXTEND_PAYMENT_DUE_DATE}
          name="Extend Payment Due Date"
          onClick={handleExtendPaymentNOC}
        />
        <ListItem
          show={policyActions.CAN_EXTEND_NOC_DUE_DATE}
          name="Pause NOC Process"
          onClick={handlePauseNOC}
        />
        <ListItem
          show={policyActions.CAN_START_NOC_PROCESS}
          name="Manually Start NOC"
          onClick={handleStartNOC}
        />
        <ListItem
          show={policyActions.CAN_STOP_NOC_PROCESS}
          name="Stop NOC Process"
          onClick={handleStopNOC}
        />
      </SubMenu>
      <SubMenu closeMenu header="Payments" show={isPaymentsSubMenuShown}>
        <ListItem
          show={
            policyActions.CAN_APPLY_PAYMENT || policyActions.CAN_ISSUE_REFUND
          }
          onClick={handleApplyPayment}
          name="Payment/Refund"
        />
        <ListItem
          show={policyActions.CAN_ADD_PREMIUM_FINANCER}
          onClick={handleAddFinancer}
          name="Add Premium Financer"
        />

        <ListItem
          show={policyActions.CAN_CHARGE_BANK_ACCOUNT}
          onClick={() => handleCharge('bankAccount')}
          disabled={!isJQueryLoaded}
          name="Charge Bank Account"
        />

        <ListItem
          show={policyActions.CAN_CHARGE_CREDIT_CARD}
          onClick={() => handleCharge('creditCard')}
          disabled={!isJQueryLoaded}
          name="Charge Credit Card"
        />

        <ListItem
          show={policyActions.CAN_MARK_CHECK_IN_MAIL}
          onClick={handleMarkCheckInMail}
          name="Mark as Check In Mail"
        />
      </SubMenu>

      <SubMenu
        closeMenu
        header="Policy Actions"
        show={isPolicyActionsSubmenuShown && data.status !== CANCELLED_STATUS}
      >
        <ListItem
          show={policyActions.CAN_GENERATE_RENEWAL_SUBMISSION}
          onClick={handleCreateRenewalSubmission}
          name="Create Renewal Submission"
        />

        <ListItem
          show={policyActions.CAN_CANCEL_POLICY}
          onClick={handleCancelPolicy}
          name="Cancel Policy"
        />

        <ListItem
          show={policyActions.CAN_UPDATE_POLICY}
          onClick={updatePolicy}
          name=" Update Policy"
        />

        <ListItem
          show={policyActions.CAN_UPDATE_COMMISSION}
          onClick={handleEditCommission}
          name=" Update Commission"
        />

        <ListItem
          show={policyActions.CAN_UPDATE_POLICY_DATES}
          onClick={hadnleUpdateActivePolicyDates}
          name="Update Policy Eff./End Date"
        />

        <ListItem
          show={policyActions.CAN_FLAT_CANCEL_POLICY}
          onClick={handleFlatCancel}
          name="Flat Cancel"
        />

        <ListItem
          show={policyActions.CAN_REGENERATE_INVOICE}
          onClick={handleRegenerateInvoice}
          name="Regenerate Invoice"
        />

        <ListItem
          show={policyActions.CAN_REGENERATE_POLICY_DOCUMENTS}
          onClick={regeneratePolicyDocuments}
          name="Regenerate Policy Documents"
          {...data}
        />

        <ListItem
          show={policyActions.CAN_UPDATE_AGENT_INFO}
          onClick={handleUpdateAgent}
          name="Update Agent Info"
        />

        <ListItem
          show={policyActions.CAN_VOID_POLICY}
          onClick={handleVoidPolicy}
          name="Void Policy"
        />
      </SubMenu>

      <MenuItem show={policyActions.CAN_VIEW_RATER} onClick={ratingViewHandler}>
        Rating View
      </MenuItem>

      <SubMenu
        closeMenu
        header="Reminders"
        show={
          policyActions.CAN_HANDLE_PAYMENT_REMINDERS ||
          policyActions.CAN_MUTE_RENEWAL_REMINDER
        }
      >
        <ListItem
          onClick={handleMutePaymentReminders}
          show={policyActions.CAN_HANDLE_PAYMENT_REMINDERS}
          name={
            data.muteReminders
              ? 'Unmute Payment Reminders'
              : 'Mute Payment Reminders'
          }
        />

        <ListItem
          onClick={handleSendPaymentReminder}
          show={policyActions.CAN_HANDLE_PAYMENT_REMINDERS}
          name="Send Payment Reminder"
        />

        <ListItem
          onClick={handleMuteRenewalReminder}
          show={policyActions.CAN_MUTE_RENEWAL_REMINDER}
          name={
            data.muteRenewalReminders
              ? 'Unmute Renewal Reminders'
              : 'Mute Renewal Reminders'
          }
        />
      </SubMenu>

      <MenuItem
        show={policyActions.CAN_EXPEDITE_BOR}
        onClick={handleExpediteBOR}
      >
        Expedite BOR
      </MenuItem>

      <MenuItem show={policyActions.CAN_RESCIND_BOR} onClick={handleRescindBOR}>
        Rescind BOR
      </MenuItem>
    </SimpleMenu>
  );
};

const useHandlers = (data, handleTableRefetch, setShowCircular) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const handleAPIError = useAPIErrorHandler();

  const policyActions = usePolicyActions(data);

  const CAN_UW_ACCOUNTS = useAllow(['accounts:uw']);
  const CAN_MANAGE_ACCOUNT_PROFILE = useAllow(['account_profile:manage']);
  const CAN_COWBELL_ACCOUNT = useAllow(['account:cowbell']);

  const handleFlatCancel = () => {
    Modal.show('PolicyFlatCancelModal', {
      data: { ...data, handleTableRefetch },
    });
  };

  const handleChangeBillingCompliance = () => {
    const policyId = data.id;
    const productType = _.get(data, 'product', '');

    Modal.show('UpdateBillingComplianceModal', {
      data: {
        policyId,
        product: productType,
      },
    });
  };

  const ratingViewHandler = () => {
    dispatch(
      setAccountDetails({
        account: {
          companyName: data.companyName,
          quoteNumber: data.quoteNumber,
          accountId: data.accountId,
          policyId: data.id,
          rating: true,
        },
      })
    );

    dispatch(
      setAdminDetails({
        admin: {
          from: location.pathname,
          readOnly: {
            quote: true,
            policy: true,
            account: !(CAN_UW_ACCOUNTS || CAN_MANAGE_ACCOUNT_PROFILE),
            risk: true,
            documents: {
              upload: false,
              delete: !CAN_COWBELL_ACCOUNT,
            },
            notes: false,
          },
        },
      })
    );

    const quoteId = _.get(data, 'quoteId');
    const accountId = _.get(data, 'accountId');
    const url = `/admin/quote/details/underwriter/${quoteId}/${accountId}/${ProductTypes.p250}/quotes`;
    history.push(url);
  };

  const hadnleUpdateActivePolicyDates = () => {
    const dataProps = {
      effectiveDate: data.effectiveDate,
      endDate: data.endDate,
      id: data.id,
    };
    Modal.show('UpdateActivePolicyDates', { data: dataProps });
  };

  const handleCancelPolicy = () => {
    Modal.show('CancelPolicy', {
      data,
      config: {
        title: `Cancel Policy for ${data.companyName}`,
      },
    });
  };

  const handleVoidPolicy = () => {
    Modal.show('VoidPolicy', {
      data: { ...data },
      config: { title: `Void Policy for ${data.companyName}` },
    });
  };

  const handleLossRuns = () => {
    dispatch(
      setAccountDetails({
        account: {
          companyName: data.companyName,
          quoteNumber: data.quoteNumber,
          accountId: data.accountId,
          policyId: data.id,
          rating: false,
        },
      })
    );

    dispatch(
      setAdminDetails({
        admin: {
          from: location.pathname,
          readOnly: {
            quote: true,
            policy: true,
            account: !(CAN_UW_ACCOUNTS || CAN_MANAGE_ACCOUNT_PROFILE),
            risk: true,
            documents: {
              upload: false,
              delete: !CAN_COWBELL_ACCOUNT,
            },
            notes: false,
          },
        },
      })
    );
    const quoteId = _.get(data, 'quoteId');
    const accountId = _.get(data, 'accountId');
    const product = _.get(data, 'product', ProductTypes.p100);
    history.push(
      `/admin/quote/details/underwriter/${quoteId}/${accountId}/${product}/loss-runs`
    );
  };

  const handleMutePaymentReminders = () => {
    const muteReminders = _.get(data, 'muteReminders', false);
    const modalProps = {
      id: _.get(data, 'id'),
      companyName: _.get(data, 'companyName'),
      policyNumber: _.get(data, 'policyNumber'),
      muteReminders,
      handleTableRefetch,
    };

    Modal.show('MutePaymentReminderConfirm', { data: modalProps });
  };

  const handleSendPaymentReminder = () => {
    toggleModalDirect('SendPaymentReminder', data, {
      title: `Send Payment Reminder`,
      maxWidth: 'md',
    });
  };

  const handleOptOutRenewal = () => {
    Modal.show('OptOutRenewalProcess', { data });
  };

  const handleBORChange = () => {
    Modal.show('ComposedBOR', {
      data: {
        ...data,
      },
    });
  };

  const handleExpediteBOR = () => {
    Modal.show('ExpeditePolicyBor', {
      data: {
        ...data,
      },
    });
  };

  const handleRescindBOR = () => {
    Modal.show('RescindComposedBOR', {
      data: {
        ...data,
      },
    });
  };

  const updatePolicy = () => {
    Modal.show('UpdatePolicyModal', { data: { policyId: data.id } });
  };

  const getP100NOCPostPaymentCallback = (policy) => {
    if (
      !(
        policyActions.CAN_RESCIND_P100_POLICY ||
        policyActions.CAN_REINSTATE_P100_POLICY ||
        policy.status === CANCELLED_STATUS
      )
    ) {
      return null;
    }

    const postPaymentNOCMessage = (() => {
      if (policyActions.CAN_RESCIND_P100_POLICY) {
        return 'The NOC has been rescinded, and policy is now In Force';
      }
      if (policyActions.CAN_REINSTATE_P100_POLICY) {
        return 'Policy has been reinstated and is now In Force';
      }
      if (policy.status === CANCELLED_STATUS) {
        return "We've applied payment, policy remains cancelled.";
      }
    })();

    return {
      onPaymentSuccessful: () => {
        Modal.show('MessageModal', {
          data: {
            message: postPaymentNOCMessage,
          },
        });
      },
    };
  };

  const handleApplyPayment = () => {
    const p100NOCCallback = getP100NOCPostPaymentCallback(data);

    Modal.show('ApplyExternalPayment', {
      data: {
        ...data,
        refresh: handleTableRefetch,
        ...(p100NOCCallback && {
          ...p100NOCCallback,
        }),
      },
    });
  };

  const handleAddFinancer = () => {
    toggleModalDirect('CreatePremiumFinancing', data, {
      title: `Add Premium Financer for ${data.companyName}`,
      maxWidth: 'md',
    });
  };

  const handleCharge = (type) => {
    loadOneIncByPolicyNumber(_.get(data, 'policyNumber', ''))
      .then((getOneIncKeyResp) => {
        setShowCircular(true);
        const paymentCategory = type === 'creditCard' ? 'CreditCard' : 'ECheck';

        getBalanceOnPolicy({ policyId: data.id })
          .then((getBalanceOnPolicyResp) => {
            const balance = getBalanceOnPolicyResp.data;

            window.$('#portalOneContainer').on('portalOne.load', function () {
              setShowCircular(false);
            });

            window.$('#portalOneContainer').on('portalOne.unload', function () {
              handleTableRefetch();
            });

            const paymentCompleteHandler = function (e, d) {
              if (d.acknowledge) {
                d.acknowledge();
              }

              const payload = {
                transactionId: d.transactionId,
                transactionDate: Moment(d.transactionDate).valueOf(),
                paymentAmount: d.paymentAmount,
                totalPaymentAmount: d.totalPaymentAmount,
                policyId: data.id,
                policyNumber: data.policyNumber,
                lastFourDigits: _.get(d, 'transactions[0].lastFourDigits', ''),
                customerEmail: data.customerEmail,
                quoteId: data.quoteId,
                agencyId: data.agencyId,
                policyCreated: data.created,
                bankName: d.bankName || '',
                cardType: d.cardType || '',
              };
              const { accountId } = data;

              adminPaymentComplete({ accountId }, payload)
                .then(() => {})
                .catch(() => {
                  enqueueSnackbar('Failed to pay, please try again later', {
                    variant: 'error',
                  });
                });
            };

            window
              .$('#portalOneContainer')
              .on('portalOne.paymentComplete', paymentCompleteHandler);

            window.$('#portalOneContainer').portalOne();
            const xhttp = new XMLHttpRequest();
            xhttp.overrideMimeType('application/json');
            xhttp.onreadystatechange = function () {
              if (xhttp.readyState === 4 && xhttp.status === 200) {
                const resp = JSON.parse(xhttp.responseText);
                const sessionID = resp.PortalOneSessionKey;
                window
                  .$('#portalOneContainer')
                  .data('portalOne')
                  .makePayment({
                    paymentCategory,
                    feeContext: 'PaymentWithFee',
                    amountContext: 'AmountDue',
                    minAmountDue: balance,
                    accountBalance: '0',
                    accountGroupCode: getOneIncKeyResp.data.authCode,
                    billingZip: '',
                    billingAddressStreet:
                      '6800 Koll Center Pkwy. Suite 250, Pleasanton, CA',
                    policyHolderName: `${data.customerFirstName} ${data.customerLastName}`,
                    referenceNumber: '',
                    saveOption: 'DoNotSave',
                    clientReferenceData1: data.policyNumber,
                    clientReferenceData2: toUniversalUtcDate(
                      Moment(data.created).add(7, 'days')
                    ),
                    clientReferenceData3: 'false',
                    clientReferenceData4: data.id,
                    sessionId: sessionID,
                    displayMode: 'modal',
                    confirmationDisplay: 'true',
                  });
              }
            };

            xhttp.open(
              'GET',
              `${getOneIncKeyResp.data.url}/Api/Api/Session/Create?portalOneAuthenticationKey=${getOneIncKeyResp.data.key}`,
              true
            );
            xhttp.send();
          })
          .catch(() => {
            setShowCircular(false);
            enqueueSnackbar('Something went wrong.Please try again later', {
              variant: 'error',
            });
          });
      })
      .catch(() => {
        setShowCircular(false);
        enqueueSnackbar('Something went wrong.Please try again later', {
          variant: 'error',
        });
      });
  };

  const handleMarkCheckInMail = () => {
    toggleModalDirect('MarkCheckInMailConfirm', data, {
      title: 'Mark policy as Check In Mail',
      icon: 'Edit',
      maxWidth: 'sm',
    });
  };

  const handleUpdateAgent = () => {
    Modal.show(UpdateAgentInfoModalConfig, {
      data: { ...data, id: data.quoteId, onSuccess: handleTableRefetch },
    });
  };

  const handleRegenerateInvoice = (event) => {
    event.stopPropagation();
    Modal.show('RegeneratePolicyInvoice', { data });
  };

  const regeneratePolicyDocuments = (event) => {
    event.stopPropagation();
    Modal.show('RegeneratePolicyDocuments', { data });
  };

  const handleEditCommission = (event) => {
    event.stopPropagation();
    const { quoteId, product } = data;
    let commission;
    if (product === ProductTypes.p100) {
      fetchQuoteDetails(quoteId)
        .then((resp) => {
          commission = _.get(resp, 'data.firmographicData.commission', '');
          Modal.show(UpdateCommissionModalConfig, {
            data: { quoteId, commission, productType: product },
          });
        })
        .catch(console.error.bind(console));
    }
    if (product === ProductTypes.p250) {
      fetchP250QuoteDetails(quoteId)
        .then((resp) => {
          commission = _.get(resp, 'data.firmographicData.commission', '');
          Modal.show(UpdateCommissionModalConfig, {
            data: { quoteId, commission, productType: product },
          });
        })
        .catch(console.error.bind(console));
    }
  };

  const handleStopNOC = () => {
    Modal.show('StopNOCModal', {
      data,
      config: { title: `Stop NOC Process` },
    });
  };

  const handlePauseNOC = () => {
    Modal.show('PauseNOCModal', {
      data,
      config: { title: `Extend Cancellation Date` },
    });
  };

  const handleStartNOC = () => {
    Modal.show('ManuallyStartNOCModal', {
      data,
      config: { title: `Manually Start NOC Process` },
    });
  };

  const handleExtendPaymentNOC = () => {
    Modal.show('ExtendPaymentNOCModal', {
      data,
      config: { title: `Extend Payment Due Date` },
    });
  };

  const handleERP = () => {
    Modal.show('AddERPModal', {
      data,
      config: { title: 'Add ERP' },
    });
  };

  const handleUploadPolicyDoc = () => {
    Modal.show('CreateEditPrimeCloudPolicyModal', {
      data: {
        policy: data,
        account: {
          id: data.accountId,
          name: data.companyName,
          state: data.firmographicData.state,
        },
      },
    });
  };

  const handleCreateRenewalSubmission = () => {
    createRenewalSubmission(data.product, { params: { policyId: data.id } })
      .then(() =>
        enqueueSnackbar(
          `Submission successfully created for policy ${data.policyNumber}`,
          {
            variant: 'success',
          }
        )
      )
      .catch(() =>
        enqueueSnackbar(
          `There is already a submission associated with policy ${data.policyNumber}`,
          { variant: 'error' }
        )
      );
  };

  const handleMuteRenewalReminder = () => {
    Modal.show('MuteRenewalReminder', { data: { ...data } });
  };

  const handleReinstate = () => {
    Modal.show('ReinstatePolicyModal', { data });
  };

  const handleGenerateRenewalQuote = () => {
    generateRenewalQuote(data.product, data.id, { data: {} }) // this is intentional in passing an empty data obj
      .then(() =>
        enqueueSnackbar(
          `Generated renewal quote successfully for policy ${data.policyNumber}`,
          {
            variant: 'success',
          }
        )
      )
      .catch(
        handleAPIError(
          `There was an error generating renewal quote for policy ${data.policyNumber}`
        )
      );
  };

  const handleUndoCancelledPolicy = () => {
    Modal.show(UndoCancelledPolicyConfirmModalConfig, { data });
  };

  const handleAdminBillingUpdate = () => {
    Modal.show(AdminUpdateBillingModalConfig, {
      data: { policy: data },
      config: { title: `Update Billing for ${data.policyNumber}` },
    });
  };

  return {
    ratingViewHandler,
    hadnleUpdateActivePolicyDates,
    handleCancelPolicy,
    handleVoidPolicy,
    handleMutePaymentReminders,
    handleChangeBillingCompliance,
    handleSendPaymentReminder,
    handleOptOutRenewal,
    handleBORChange,
    handleExpediteBOR,
    handleRescindBOR,
    updatePolicy,
    handleApplyPayment,
    handleAddFinancer,
    handleCharge,
    handleUpdateAgent,
    handleMarkCheckInMail,
    handleFlatCancel,
    handleRegenerateInvoice,
    regeneratePolicyDocuments,
    handleEditCommission,
    handleLossRuns,
    handleStopNOC,
    handleExtendPaymentNOC,
    handlePauseNOC,
    handleStartNOC,
    handleERP,
    handleUploadPolicyDoc,
    handleMuteRenewalReminder,
    handleCreateRenewalSubmission,
    handleReinstate,
    handleGenerateRenewalQuote,
    handleUndoCancelledPolicy,
    handleAdminBillingUpdate,
  };
};

export default AdminPolicyOptions;
