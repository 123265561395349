import Moment from 'moment';
import { trimAndAddAsterisk } from '../../../utility/QueryUtility';

export const formsServiceMappings = [
  { path: 'page' },
  { path: 'size' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'name':
        case 'productTypes':
        case 'formType':
        case 'supportedPaper':
          if (orderBy && order) {
            return `${orderBy}.keyword,${order}`;
          }
          break;
        default:
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search }) => (search ? trimAndAddAsterisk(search) : null),
  },
  { path: 'status' },
  { path: 'teamIds' },
  { path: 'accountId.keyword' },
];
