import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Grid as MuiGrid, DialogContent, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/styles';

// components
// utils

import { useForm } from 'react-hook-form-4';

import { compose } from 'redux';
import { updateWebHook } from './WebHookService';
import { PubSub } from '../utils/eventUtils';
import { TextFieldBase } from '../components/inputs/TextFieldBase';
import { withFormController } from '../components/hocs/withFormController';
import { useToggleModal } from '../utils/modal.utils';

const TextField = withFormController(TextFieldBase);

const styles = ({ palette, config }) => {
  return {
    title: {
      fontFamily: 'Titillium Web',
      fontSize: config.textSizes.primer,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: palette.text.text8,
    },
    text: {
      color: palette.primary.title,
    },
    error: {
      textAlign: 'center',
      maxWidth: '600px',
      fontSize: config.textSizes.normal,
    },
  };
};

const WebHookEdit = compose(withStyles(styles))(({ classes, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const [error, setError] = useState('');
  const {
    control,
    formState: { dirty, isSubmitting },
  } = useForm({
    defaultValues: {
      clientId: data.clientId || '',
      clientSecret: data.clientSecret || '',
      webhookUrl: data.webhookUrl || '',
    },
  });

  useEffect(() => {
    const sub = PubSub.subscribe('foot-button-one', (eventData) => {
      if (eventData) {
        handleSubmit();
      }
    });
    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  function handleSubmit() {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    // formData

    const { clientId, clientSecret, webhookUrl } = control.getValues();

    const payload = {
      id: data.id,
      clientId,
      clientSecret,
      webhookUrl,
    };
    return updateWebHook(
      {},
      {
        ...payload,
      }
    )
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        enqueueSnackbar(`WebHook Updated successfully!`, {
          variant: 'success',
        });
        PubSub.publish('table-refetch', 'webhooks');
        toggleModal.direct('WebHookEdit', false);
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        setError(error.response.data);
      });
  }

  const inputProps32 = { maxlength: 32, minlength: 32 };
  const inputProps16 = { maxlength: 16, minlength: 16 };

  return (
    <DialogContent>
      <form id="submit-form">
        <MuiGrid item style={{ marginTop: -20 }}>
          <TextField
            name="clientId"
            label="Client ID"
            required
            control={control}
            fullWidth
            inputProps={inputProps32}
          />
        </MuiGrid>
        <MuiGrid item style={{ paddingTop: 0 }}>
          <TextField
            name="clientSecret"
            label="Client Secret"
            required
            control={control}
            fullWidth
            inputProps={inputProps32}
          />
        </MuiGrid>
        <MuiGrid item style={{ paddingTop: 0 }}>
          <TextField
            name="webhookUrl"
            label="Webhook Url"
            required
            control={control}
            fullWidth
            inputProps={inputProps16}
          />
        </MuiGrid>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
      </form>
    </DialogContent>
  );
});

export default WebHookEdit;
