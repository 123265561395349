import { useFeature } from '@growthbook/growthbook-react';
import { useScopes } from '../../useScopes';
import { usePersona } from '../../usePersona';
import { getPolicyActionsForCowbell } from './cowbell/getPolicyActionsForCowbell';

import { personaAliases } from '../../../../console/_statics/persona-alias.statics';
import { getPolicyActionsForCarrier } from './carrier/getPolicyActionsForCarrier';
import type { PolicyTableInfoDto } from '../../../../types/policy/tables/policy-table-info.dto';
import { FEATURE_FLAG_KEYS } from '../../../../config/growthbook';

export const usePolicyActions = (policy: PolicyTableInfoDto) => {
  const scopes = useScopes();
  const { _legacy_persona } = usePersona();

  // Feature flags
  const flatCancelFeature = useFeature(FEATURE_FLAG_KEYS.FLAT_CANCEL);
  const cowbellPolicyGenerateRenewalQuoteFeature = useFeature(
    FEATURE_FLAG_KEYS.COWBELL_GENERATE_RENEWAL_QUOTE
  );
  const undoCancelledPolicyFeature = useFeature(
    FEATURE_FLAG_KEYS.UNDO_CANCELLED_POLICY
  );

  if (
    _legacy_persona === personaAliases.cowbell ||
    _legacy_persona === personaAliases.da ||
    _legacy_persona === personaAliases.cluster
  ) {
    const options = {
      flags: {
        isFlatCancelFeatureOn: flatCancelFeature.on,
        isCowbellPolicyGenerateRenewalQuoteOn:
          cowbellPolicyGenerateRenewalQuoteFeature.on,
        isUndoCancelledPolicyFeatureOn: undoCancelledPolicyFeature.on,
      },
    };
    const cowbellPolicyActions = getPolicyActionsForCowbell(
      policy,
      scopes,
      options
    );

    return cowbellPolicyActions;
  }

  if (_legacy_persona === personaAliases.carrier) {
    const options = { flags: {} };
    const carrierPolicyActions = getPolicyActionsForCarrier(
      policy,
      scopes,
      options
    );

    return carrierPolicyActions;
  }
};
