import type { TextFieldProps as MUITextFieldProps } from '@mui/material';
import { TextField as MUITextField } from '@mui/material';

type TextFieldProps = {
  error?: MUITextFieldProps['helperText'];
} & Omit<MUITextFieldProps, 'helperText'>;

function TextField({ error, ...props }: TextFieldProps) {
  return (
    <MUITextField
      variant={undefined}
      {...props}
      InputLabelProps={{
        style: { fontSize: '1.5rem' },
        shrink: true,
        ...props.InputLabelProps,
      }}
      InputProps={{
        style: { marginTop: '2.2rem' },
        error: Boolean(error),
        ...props.InputProps,
      }}
      helperText={error}
    />
  );
}

export default TextField;
