import type { AxiosError } from 'axios';
import _ from 'lodash';

export class ApiError extends Error {
  meta = {};

  developers = {};

  constructor(...args: any[]) {
    super(...args);
    const [message, options = {}] = args;
    const { meta, developers } = options;

    this.meta = meta;
    this.developers = developers;

    return this;
  }
}

export function isApiError(error: any) {
  return error instanceof ApiError;
}

const GENERIC_SERVER_STATUS_CODES = Object.freeze([500, 502, 503, 504]);
export function manageAPIError(
  error: AxiosError | undefined,
  fallbackMessage = 'An error has occurred. Please try again later.'
) {
  const isGenericServerError =
    error?.response &&
    GENERIC_SERVER_STATUS_CODES.includes(error.response.status);

  if (isGenericServerError) {
    return 'Our servers seem to be having trouble. Please try again later.';
  }

  const receivedErrorMessage = getAPIError(error);
  return receivedErrorMessage
    ? (receivedErrorMessage as string)
    : fallbackMessage;
}

function getAPIError(error: AxiosError | undefined) {
  const errorMessagePath =
    typeof error?.response?.data === 'string' ? 'data' : 'data.message';

  return _.get(error?.response, errorMessagePath) as string | undefined;
}
