import React from 'react';
import { Box } from '@mui/material';

import clsx from 'classnames';
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';

export const CowbellFactorLineStarGraph = ({ children, ...props }) => {
  const { min = 0, max = 100, values = [] } = props;

  if (typeof children === 'function') {
    return children(props);
  }

  return (
    <Container>
      <Box className="contrast-text">{min}</Box>
      <ValuesContainer>
        <Line />
        {values.map((item, index) => (
          <Value {...item} key={index} index={index} />
        ))}
      </ValuesContainer>
      <Box className="contrast-text">{max}</Box>
    </Container>
  );
};

const Value = ({ value = 0, index }) => {
  const valueIndicatorStyles = useValueIndicatorStyles();

  const valueIndicatorClasses = clsx({
    [valueIndicatorStyles.primary]: index === 1,
    [valueIndicatorStyles.secondary]: index === 0,
  });

  const valueIndicatorLabelClasses = clsx({
    [valueIndicatorStyles.bottomRight]: index === 1,
    [valueIndicatorStyles.topRight]: index === 0,
  });

  return (
    <ValueIndicatorContainer left={`${value}%`}>
      <ValueIndicator className={valueIndicatorClasses} />
      <ValueIndicatorLabel className={valueIndicatorLabelClasses}>
        {value}
      </ValueIndicatorLabel>
    </ValueIndicatorContainer>
  );
};

const Container = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
});

const Line = styled(Box)(({ theme }) => ({
  width: 'inherit',
  height: 1,
  backgroundColor: theme.palette.primary.border,
}));

const ValuesContainer = styled(Box)({
  width: 'inherit',
  marginLeft: 10,
  marginRight: 10,
  position: 'relative',
});

const ValueIndicatorContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
});

const ValueIndicator = styled(Box)({
  width: 10,
  height: 10,
  position: 'absolute',
  top: 0,
  transform: 'translateY(-50%) rotate(45deg)',
  backgroundColor: 'white',
});

const ValueIndicatorLabel = styled(Box)({
  position: 'absolute',
});

const useValueIndicatorStyles = makeStyles(({ palette }) => ({
  topRight: {
    transform: 'translate(10px, -100%)',
    color: palette.graphs.pie.secondaryBlue,
  },

  bottomRight: {
    transform: 'translate(10px, 0)',
    color: palette.graphs.pie.primaryBlue,
  },

  primary: {
    backgroundColor: palette.graphs.pie.primaryBlue,
  },

  secondary: {
    backgroundColor: palette.graphs.pie.secondaryBlue,
  },
}));
