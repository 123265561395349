import React from 'react';
import {
  DialogContent,
  DialogActions,
  Divider,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import CbButton from '../../../../components/Buttons/CbButton';
import { generateLossRunsDocument, getAccountDetails } from '../../../../api';

const CowbellGenerateLossRuns = ({ data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = data;
  const [sendEmailToAgent, setEmailAgent] = React.useState(false);
  const [sendEmailToPH, setEmailPH] = React.useState(false);

  const { data: accountDetails } = useQuery(
    ['account', 'details', accountId],
    () => getAccountDetails(accountId).then((resp) => resp.data)
  );

  const handleAgentCheckboxChange = React.useCallback(
    () => setEmailAgent((prev) => !prev),
    []
  );
  const handlePHCheckboxChange = React.useCallback(
    () => setEmailPH((prev) => !prev),
    []
  );

  const onSubmit = (e) => {
    e.preventDefault();
    generateLossRunsDocument({
      data: { accountId, sendEmailToAgent, sendEmailToPH },
    })
      .then(() =>
        enqueueSnackbar(
          'Success! Your loss runs are generating and will be available for download in a few minutes.',
          {
            variant: 'success',
          }
        )
      )
      .catch(() =>
        enqueueSnackbar(
          'There was a problem generating your loss runs document, please try again.',
          {
            variant: 'error',
          }
        )
      );
    props.close();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <DialogContent style={{ paddingBottom: '2rem' }}>
          <Box display="flex" justifyContent="center" width="100%">
            <h1>{accountDetails?.name}</h1>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="center"
            style={{ marginTop: '2rem' }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendEmailToAgent}
                    onChange={handleAgentCheckboxChange}
                    color="secondary"
                  />
                }
                label="Email Loss Runs to Agent"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendEmailToPH}
                    onChange={handlePHCheckboxChange}
                    color="secondary"
                  />
                }
                label="Email Loss Runs to Policy Holder"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={props.close}>
            Cancel
          </CbButton>
          <CbButton styleName="ctaButton" type="submit">
            Generate
          </CbButton>
        </DialogActions>
      </form>
    </>
  );
};

export const CowbellGenerateLossRunsConfig = {
  CowbellGenerateLossRuns: {
    component: CowbellGenerateLossRuns,
    config: {
      fullWidth: true,
      title: 'Generate Loss Runs',
    },
  },
};
