export default () => {
  onmessage = (event) => {
    const { origin } = event.target;
    const { accessToken } = event.data;

    const options = {
      method: 'get',
      headers: {
        Accept: 'application/json , text/plain , */*',
        'Content-Type': 'application/json',
        'Z-Client-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    fetch(`${origin}/api/notification/v1/silent`, options)
      .then((response) => response.json())
      .then((data) => {
        postMessage(data);
      })
      .catch(console.error.bind(console));
  };
};
