import React from 'react';

// components
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import type { WithChildrenProps } from '../../../../types/components/common';

export const AgencyDashboardSearchGroupingHeader = ({
  children,
}: WithChildrenProps) => (
  <Typography
    variant="body1"
    style={{ margin: '1rem 2rem 0', fontSize: '1.4rem', fontWeight: 'bold' }}
  >
    {children}
  </Typography>
);

export const AgencyDashboardSearchListItemBase = ({
  children,
}: WithChildrenProps) => {
  const classes = useListItemStyles();
  return <Box className={classes.root}>{children}</Box>;
};

const useListItemStyles = makeStyles((theme) => ({
  root: {
    margin: '0.1rem 0',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.modal,
      fontWeight: 'bold',
    },
  },
}));
