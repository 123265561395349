import _ from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { compose } from 'redux';
import { Box, TableRow as MuiTableRow } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import Numeral from 'numeral';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { PubSub } from '../../utils/eventUtils';

import withRouter from '../../components/hocs/withRouter';
import { withBackfill } from '../../components/hocs/withBackfill';

import { getListForAccount, deleteListedQuote } from '../../api/apis';

import { TableFooter } from '../../components/tables/tables-new/base/footer.table';
import { ActionBar } from '../../components/tables/tables-new/base/action-bar.table';
import { TableContext } from '../../components/tables/tables-new/base/context.table';
import { withCsvDownload } from '../../components/tables/tables-new/hocs/withCsvDownload';
import { TableBody } from '../../components/tables/tables-new/base/body.table';
import { TableRow } from '../../components/tables/tables-new/base/row.table';
import { BaseTable } from '../../components/tables/tables-new/base/base.table';

import SimpleTooltip from '../../components/SimpleTooltip';
import { toUniversalUtcDate } from '../../utils/date.utils';

const noop = () => null;

const styles = ({ config, palette }) => ({
  icon: {
    color: '#fb2200',
    position: 'relative',
    top: 3,
  },
  title: {
    paddingTop: '10px',
    paddingLeft: '10px',
    fontFamily: 'Titillium Web',
    fontSize: '15px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#9192a4',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  request: {
    fontFamily: 'Titillium Web',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    textAlign: 'start', // change to start for delete function
    color: config.colors.cowbellLight,
    paddingRight: 35,
  },
  delete: {
    fontFamily: 'Titillium Web',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#fb2200',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  helperText: {
    fontFamily: 'Titillium Web',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: config.colors.textRed,
    paddingLeft: 12,
  },
  tooltip: {
    maxWidth: 600,
    width: 500,
  },
  tooltipIndicator: {
    marginLeft: 5,
    position: 'relative',
    top: 1,
    // width: '1.9rem',
  },
  tooltipHighlight: {
    color: palette.primary.main,
    // whiteSpace: 'nowrap',
  },
  tooltipList: {
    '& li': {
      marginBottom: 5,
      // whiteSpace: 'nowrap',
    },
  },
  tableRow: {
    background: '#17171a',
    borderBottom: '20px solid #1f1f29',
    height: '70px',
    cursor: 'pointer',
  },
  textColor: {
    color: palette.text.text8,
  },
  noQuotes: {
    textAlign: 'center',
    left: '70%',
    top: '53%',
    position: 'fixed',
    color: config.colors.cowbellLight,
    fontFamily: config.fonts.stackSans,
    fontSize: config.textSizes.normal,
  },
});

const useRowStyles = makeStyles(({ palette }) => ({
  root: {
    background: palette.background.customTable,
    borderBottom:
      palette.mode === 'dark' ? '20px solid #26262E' : '20px solid #FDFDFE',
  },
}));

const useCellStyles = makeStyles(() => ({
  root: {
    paddingTop: '0.58rem',
    paddingBottom: '0.58rem',
  },
}));

// ui
export const QuotesListTable = compose(
  withRouter,
  withCsvDownload('listQuotes'),
  withBackfill('uiSettings.listQuotes', {
    order: 'desc',
    orderBy: 'quoteNumber',
  }),
  withStyles(styles)
)(({ classes, accountId, dates, canAdd, agencyId }) => {
  const rowStyles = useRowStyles();
  const cellStyles = useCellStyles();
  const [data, setData] = useState([]);
  const [totalCount, setCount] = useState(0);

  const fetch = useCreateFetch(accountId, setCount, setData, canAdd, agencyId);

  const context = useMemo(
    () => ({
      accountId,
      agencyId,
      dates,
      classes,
      styles: {
        row: rowStyles,
        cell: cellStyles,
      },
      canAdd,
    }),
    [accountId, agencyId, canAdd, cellStyles, classes, dates, rowStyles]
  );

  React.useEffect(() => {
    fetch();

    const sub = PubSub.subscribe('table:listQuotes:refetch', fetch);

    return () => sub.remove();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BaseTable
        id="listQuotes"
        columns={columns}
        data={data}
        context={context}
      >
        <ActionBar render={noop} classes={classes} />
        <TableBody
          render={(rows) => {
            if (!rows.length) {
              return (
                <MuiTableRow>
                  <div className={classes.noQuotes}>
                    Please select ‘Coverage Options’ and <br />
                    click on ‘Add to List’
                    <p style={{ margin: 0 }}>
                      to add selection to the List of ‘Quote Request’!
                    </p>
                  </div>
                </MuiTableRow>
              );
            }

            return (
              <>
                {rows.map((row, idx) => (
                  <TableRow key={`tr-${idx}`} row={row} />
                ))}
              </>
            );
          }}
        />
        <TableFooter show={false} />
      </BaseTable>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {totalCount >= 5 ? (
          <div className={classes.helperText}>
            You have reached 5 request limit, please delete one to add another
            variation!
          </div>
        ) : null}
        <div className={classes.wrapper}>
          <div className={classes.request}>
            {totalCount}
            {totalCount === 1 ? '\tRequest' : '\tRequests'}
          </div>
        </div>
      </div>
    </>
  );
});

export const columns = [
  {
    name: 'quoteNumber',
    label: 'Quote Number',
    sort: false,
    Cell: {
      width: 100,
      C: ({ row }) => {
        return <span style={{ whiteSpace: 'nowrap' }}>{row.quoteNumber}</span>;
      },
    },
  },
  {
    name: 'calculatorGeneratedData.limit',
    label: 'Aggregate Limit',
    sort: false,
    Head: {
      align: 'right',
      C: () => {
        const { classes } = useContext(TableContext);
        return <span className={classes.textColor}>Aggregate Limit</span>;
      },
    },
    Cell: {
      align: 'right',
      width: 100,
      C: ({ row }) => {
        const { classes } = useContext(TableContext);
        return (
          <span className={classes.textColor}>
            {row.calculatorGeneratedData
              ? Numeral(row.calculatorGeneratedData.limit).format('$0,00a')
              : null}
          </span>
        );
      },
    },
  },
  {
    path: 'calculatorGeneratedData.deductible',
    label: 'Deductible Amount',
    sort: false,
    Head: {
      align: 'right',
      C: () => {
        const { classes } = useContext(TableContext);
        return <span className={classes.textColor}>Deductible Amount</span>;
      },
    },
    Cell: {
      align: 'right',
      width: 100,
      C: ({ row }) => {
        const { classes } = useContext(TableContext);
        return (
          <span className={classes.textColor}>
            {row.calculatorGeneratedData
              ? Numeral(row.calculatorGeneratedData.deductible).format('$0,0')
              : null}
          </span>
        );
      },
    },
  },
  {
    path: 'calculatorGeneratedData.premium',
    label: 'Indicated Pricing',
    sort: false,
    Head: {
      align: 'right',
      C: () => {
        return (
          <span style={{ color: '#2180e2', background: 'inherit' }}>
            Indicated Pricing
          </span>
        );
      },
    },
    Cell: {
      align: 'right',
      width: 120,
      C: ({ row }) => {
        const { classes } = useContext(TableContext);
        if (!row.calculatorGeneratedData) {
          return null;
        }

        const {
          businessIncomeCoverage,
          computerFraudEndorsement,
          waitingPeriod,
          retroactivePeriod,
          effectiveDate,
          endDate,
          socialEngEndorsement,
          socialEngLimit,
          socialEngDeductible,
          ransomPaymentEndorsement,
          ransomPaymentLimit,
          hardwareReplCostEndorsement,
          hardwareReplCostSubLimit,
          telecomsFraudEndorsement,
          telecomsFraudSubLimit,
          postBreachRemediationEndorsement,
          postBreachRemediationSubLimit,
          websiteMediaContentLiabilityEndorsement,
          websiteMediaContentLiabilitySubLimit,
          additionalInsureds,
        } = row.calculatorGeneratedData;

        const retroactivePeriodOptions = {
          1: 'year',
          2: 'years',
          3: 'or more yrs',
        };

        return (
          <p style={{ margin: 0, display: '-webkit-inline-box' }}>
            <span style={{ color: '#2180e2' }}>
              {Numeral(row.calculatorGeneratedData.premium).format('$0,0.00')}
            </span>
            <SimpleTooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                <ul className={`${classes.tooltipList} list--unstyled`}>
                  <li>
                    Computer & Funds Transfer Fraud:{' '}
                    <span className={classes.tooltipHighlight}>
                      {computerFraudEndorsement ? 'Selected' : 'Not Selected'}
                    </span>
                  </li>
                  <li>
                    Business Income & Extra Expense:{' '}
                    <span className={classes.tooltipHighlight}>
                      {Numeral(businessIncomeCoverage).format('$0,0.00')}
                    </span>
                  </li>
                  <li>
                    Social Engineering Endorsement:{' '}
                    <span className={classes.tooltipHighlight}>
                      {socialEngEndorsement ? 'Selected' : 'Not Selected'}
                    </span>
                  </li>
                  {socialEngEndorsement && (
                    <>
                      <li>
                        Social Engineering Limit:{' '}
                        <span className={classes.tooltipHighlight}>
                          {Numeral(socialEngLimit).format('$0,0.00')}
                        </span>
                      </li>
                      <li>
                        Social Engineering Deductible:{' '}
                        <span className={classes.tooltipHighlight}>
                          {Numeral(socialEngDeductible).format('$0,0.00')}
                        </span>
                      </li>
                    </>
                  )}
                  <li>
                    Ransom Payments:{' '}
                    <span className={classes.tooltipHighlight}>
                      {ransomPaymentEndorsement
                        ? Numeral(ransomPaymentLimit).format('$0,0.00')
                        : 'Not Selected'}
                    </span>
                  </li>
                  <li>
                    Hardware Replacement Costs:{' '}
                    <span className={classes.tooltipHighlight}>
                      {hardwareReplCostEndorsement
                        ? Numeral(hardwareReplCostSubLimit).format('$0,0.00')
                        : 'Not Selected'}
                    </span>
                  </li>
                  <li>
                    Telecommunications Fraud:{' '}
                    <span className={classes.tooltipHighlight}>
                      {telecomsFraudEndorsement
                        ? Numeral(telecomsFraudSubLimit).format('$0,0.00')
                        : 'Not Selected'}
                    </span>
                  </li>
                  <li>
                    Post Breach Remediation Coverage :{' '}
                    <span className={classes.tooltipHighlight}>
                      {postBreachRemediationEndorsement
                        ? Numeral(postBreachRemediationSubLimit).format(
                            '$0,0.00'
                          )
                        : 'Not Selected'}
                    </span>
                  </li>

                  <li>
                    Website Media Content Liability :{' '}
                    <span className={classes.tooltipHighlight}>
                      {websiteMediaContentLiabilityEndorsement
                        ? Numeral(websiteMediaContentLiabilitySubLimit).format(
                            '$0,0.00'
                          )
                        : 'Not Selected'}
                    </span>
                  </li>

                  <li>
                    Waiting Period:{' '}
                    <span className={classes.tooltipHighlight}>
                      {waitingPeriod} Hrs
                    </span>
                  </li>
                  <li>
                    Retro Active Period:{' '}
                    <span className={classes.tooltipHighlight}>
                      {retroactivePeriod}{' '}
                      {retroactivePeriodOptions[retroactivePeriod]}
                    </span>
                  </li>
                  <li>
                    Effective Start Date:{' '}
                    <span className={classes.tooltipHighlight}>
                      {toUniversalUtcDate(effectiveDate)}
                    </span>
                  </li>
                  <li>
                    Effective End Date:{' '}
                    <span className={classes.tooltipHighlight}>
                      {toUniversalUtcDate(endDate)}
                    </span>
                  </li>
                  <li>
                    Additional Insured:{' '}
                    <span className={classes.tooltipHighlight}>
                      {additionalInsureds.length}
                    </span>
                  </li>
                </ul>
              }
            >
              <img
                src="/images/Tooltip.svg"
                className={classes.tooltipIndicator}
                alt="ToolTip"
              />
            </SimpleTooltip>
          </p>
        );
      },
    },
  },
  {
    name: 'calculatorGeneratedData.effectiveDate',
    label: 'Coverage Start Date',
    sort: false,
    Head: {
      align: 'right',
      C: () => {
        return <span style={{ color: '#2180e2' }}>Coverage Start Date</span>;
      },
    },
    Cell: {
      align: 'right',
      width: 100,
      C: ({ row }) => {
        return (
          <span style={{ color: '#2180e2' }}>
            {toUniversalUtcDate(row.calculatorGeneratedData.effectiveDate)}
          </span>
        );
      },
    },
  },
  {
    name: 'actions',
    label: 'Delete Quote',
    canToggle: false,
    sort: false,
    Head: {
      align: 'right',
    },
    Cell: {
      align: 'right',
      width: 50,
      C: ({ row }) => {
        const { classes, agencyId, canAdd } = useContext(TableContext);
        function cancelAction() {
          canAdd(true);
          deleteListedQuote(row.id, agencyId).then(() =>
            PubSub.publish('table:listQuotes:refetch')
          );
        }
        return (
          <Box component="span" onClick={cancelAction}>
            <HighlightOffIcon className={classes.icon} fontSize="medium" />
          </Box>
        );
      },
    },
  },
];

const useCreateFetch = (accountId, setCount, setData, canAdd, agencyId) => {
  const fetch = React.useCallback(() => {
    return getListForAccount(accountId, agencyId).then(({ data }) => {
      PubSub.publish(
        'quote-list:retrieved',
        data.content.map((dataMap) => ({
          id: dataMap.id,
          ..._.pick(dataMap.calculatorGeneratedData, [
            'businessIncomeCoverage',
            'computerFraudEndorsement',
            'deductible',
            'limit',
            'retroactivePeriod',
            'waitingPeriod',
            'effectiveDate',
            'endDate',
            'socialEngEndorsement',
            'socialEngLimit',
            'socialEngDeductible',
            'ransomPaymentEndorsement',
            'ransomPaymentLimit',
            'hardwareReplCostEndorsement',
            'hardwareReplCostSubLimit',
            'telecomsFraudEndorsement',
            'telecomsFraudSubLimit',
            'postBreachRemediationEndorsement',
            'postBreachRemediationSubLimit',
            'websiteMediaContentLiabilityEndorsement',
            'websiteMediaContentLiabilitySubLimit',
            'additionalInsureds',
          ]),
        }))
      );

      setCount(data.totalElements);
      setData(data.content);

      if (data.totalElements >= 5) {
        canAdd(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  return fetch;
};
