export const commonOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export const assessmentsMap250 = {
  pastCyberIncident: {
    label: (
      <span>
        Had any <strong>past Cyber Incidents?</strong>
      </span>
    ),
    options: commonOptions,
  },
  isSecurityOfficer: {
    label: (
      <span>
        Does the policyholder agree to be the Designated
        <strong> Information Security Contact? </strong>
      </span>
    ),
    options: commonOptions,
  },
  isSecurityTraining: {
    label: (
      <span>
        Does policyholder provide mandatory{' '}
        <strong>information security training</strong> to all employees at least
        annually? If not, are they willing to implement it during the policy
        period?
      </span>
    ),
    options: commonOptions,
  },
  useEncryption: {
    label: (
      <span>
        Does the policyholder <strong>encrypt</strong> emails, mobile and
        computing devices containing <strong>sensitive information</strong>{' '}
        (e.g., PII, PHI, PCI) sent to external parties?
      </span>
    ),
    options: commonOptions,
  },
  useCloudStorage: {
    label: (
      <span>
        Does the policyholder have <strong>sensitive information</strong> stored{' '}
        <strong>on the cloud</strong>?
      </span>
    ),
    options: commonOptions,
  },
  backupFrequency: {
    label: (
      <span>
        How often does the policyholder perform backups of{' '}
        <strong>business-critical data?</strong>
      </span>
    ),
    options: [
      { label: 'Weekly or more', value: 'WEEKLY' },
      { label: 'Monthly', value: 'MONTHLY' },
      { label: 'Quarterly', value: 'QUARTERLY' },
      { label: 'Every 6 Months', value: 'SIX_MONTHS' },
      { label: 'Never', value: 'NEVER' },
    ],
  },
  patchingFrequency: {
    label: (
      <span>
        How often does the policyholder apply{' '}
        <strong>updates to critical IT-systems and applications?</strong>
      </span>
    ),
    options: [
      { label: 'Weekly or more', value: 'WEEKLY' },
      { label: 'Monthly', value: 'MONTHLY' },
      { label: 'Quarterly', value: 'QUARTERLY' },
      { label: 'Every 6 Months', value: 'SIX_MONTHS' },
      { label: 'Never', value: 'NEVER' },
    ],
  },
};

export const policyHolderAssessmentsMap250 = {
  pastCyberIncident: {
    label: 'Had any past Cyber Incidents?',
    options: commonOptions,
  },
  isSecurityOfficer: {
    label:
      'Does your organization have a Designated Information Security Contact?',
    options: commonOptions,
  },
  isSecurityTraining: {
    label:
      'Does your organization provide mandatory information security training to all employees at least annually? If not, are you willing to implement it during the policy period?',
    options: commonOptions,
  },
  useEncryption: {
    label:
      'Does your organization encrypt emails, mobile and computing devices containing sensitive information (e.g., PII, PHI, PCI) sent to external parties?',
    options: commonOptions,
  },
  useCloudStorage: {
    label:
      'Does your organization have sensitive information stored on the cloud?',
    options: commonOptions,
  },
  backupFrequency: {
    label:
      'How often does your organization perform backups of business-critical data?',
    options: [
      { label: 'Weekly or more', value: 'WEEKLY' },
      { label: 'Monthly', value: 'MONTHLY' },
      { label: 'Quarterly', value: 'QUARTERLY' },
      { label: 'Every 6 Months', value: 'SIX_MONTHS' },
      { label: 'Never', value: 'NEVER' },
    ],
  },
  patchingFrequency: {
    label:
      'How often does your organization apply updates to critical IT-systems and applications?',
    options: [
      { label: 'Weekly or more', value: 'WEEKLY' },
      { label: 'Monthly', value: 'MONTHLY' },
      { label: 'Quarterly', value: 'QUARTERLY' },
      { label: 'Every 6 Months', value: 'SIX_MONTHS' },
      { label: 'Never', value: 'NEVER' },
    ],
  },
};

export const pastIncidentMap = {
  claimHistory: {
    label: (
      <span>Has the Organization filed any claims due to a cyber event?</span>
    ),
    options: [
      { label: 'Never', value: 0 },
      { label: 'within last 12 months', value: 1 },
      { label: 'within last 2 years', value: 2 },
      { label: 'within last 3 years', value: 3 },
      { label: 'within last 4 years', value: 4 },
      { label: 'within 5 years or more', value: 5 },
    ],
  },
  civilOrCriminalAction: {
    label: (
      <span>
        Has the organization ever been a party to a Civil or criminal action or
        administrative proceeding alleging violation of any federal, state,
        local or common law?
      </span>
    ),
    options: commonOptions,
  },
  pendingLitigation: {
    label: (
      <span>
        Is there currently any pending litigation, administrative proceeding or
        claim against the named applicant, organization and/or any of the
        prospective insureds?
      </span>
    ),
    options: commonOptions,
  },
  lossInBusinessIncome: {
    label: (
      <span>
        During the last three years, has the organization suffered loss of
        business income as a result of unscheduled system downtime?
      </span>
    ),
    options: commonOptions,
  },
  securityBreachRequiringNotification: {
    label: (
      <span>
        During the last three years, has the organization suffered a security
        breach requiring customer or third-party notification according to state
        or federal regulations?
      </span>
    ),
    options: commonOptions,
  },
};

export const frequencyMetaMap = {
  WEEKLY: 'Weekly or more',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  SIX_MONTHS: 'Every 6 Months',
  NEVER: 'Never',
};
