import React from 'react';
import { useSnackbar } from 'notistack';
import { useToggleModal } from '../../utils/modal.utils';

export const withUploadFeatureDocuments = (minHeight, from) => {
  return (Component) => {
    return (props) => {
      const toggleModal = useToggleModal();

      const { docsData = { companyName: '' } } = props;
      const { enqueueSnackbar } = useSnackbar();
      const truncatedAccountName =
        docsData?.companyName?.length > 32
          ? `${docsData?.companyName?.substr(0, 32)}...`
          : docsData?.companyName;

      const modalTitle = React.useMemo(() => {
        return docsData?.companyName
          ? `Upload file for ${truncatedAccountName}`
          : 'Upload File';
      }, [docsData.companyName, truncatedAccountName]);

      const onDrop = React.useCallback(
        (files) => {
          const wrapper = (message, severity) => {
            enqueueSnackbar(message, severity);
          };

          const maxSize = 26214400; // 25 * 1024 * 1024
          if ((files?.[0]?.size ?? 0) > maxSize) {
            wrapper('The file size is too big, 25MB max.', {
              variant: 'error',
            });
          } else {
            toggleModal.direct(
              'AgencyUploadDocumentModal',
              { file: files[0], docsData, from },
              {
                title: modalTitle,
                maxWidth: 'sm',
              }
            );
          }
        },
        // eslint-disable-next-line
        [docsData]
      );

      return (
        <section name="withUploadFeatureDocuments">
          <Component {...props} onDrop={onDrop} minHeight={minHeight} />
        </section>
      );
    };
  };
};
