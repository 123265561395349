import React from 'react';

const SVG = ({
  style = {},
  fill = '#707070',
  width = '100%',
  className = '',
  viewBox = '0 0 45 45',
  title = 'Upload button',
}) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>{/* <style>.cls-1{fill:none;}.cls-2{fill:#fff;}</style> */}</defs>
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="Group_4291" data-name="Group 4291">
          <g id="Layer_2-2" data-name="Layer 2">
            <g id="Layer_1-3" data-name="Layer 1">
              <path
                id="Path_3038"
                data-name="Path 3038"
                className="cls-2"
                d="M21.57,15.73V30.22h2.86V15.67l5.69,5.91,2.06-2.08L23,10l-9.18,9.5,2,2.08Z"
              />
              <path
                id="Path_3039"
                data-name="Path 3039"
                className="cls-2"
                d="M38,32.18V27.23H35.27v4.95H10.72V27.23H8V35H38Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
