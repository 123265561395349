import type { ReactNode, SyntheticEvent } from 'react';
import React from 'react';

import { Controller } from 'react-hook-form';

import type { GridProps } from '@mui/material';
import {
  Box,
  DialogActions,
  FormControl,
  Grid,
  RadioGroup,
  styled,
  Typography,
  DialogContent as DialogContentBase,
  CircularProgress,
} from '@mui/material';

import { withFormController } from '../../../../components/hocs/forms';

import { Flexbox } from '../../../../components/layout/Flexbox';
import { withShowable } from '../../../../console/_global/lib/withShowable';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import { ManagedTeamByAgencyAutocomplete } from '../../../admin-account-flow/TeamsAutoComplete';
import { ManagedAgentByAgencyAutoComplete } from '../../../agentUpdate/AgentsAutoComplete';
import { InputLabelBase } from '../../../../components/inputs/InputLabelBase';
import { AutoCompleteBase } from '../../../../components/inputs';

import type { TextFieldBaseProps } from '../types';
import type { SelectOption } from '../../../../types/components/common';

import { ManagedSurplusBrokerAutoComplete } from '../../../../inbox/bind/SurplusBrokerAutoComplete';
import type { LanguageCurrencyFieldProps } from '../../../../components/inputs/LanguageCurrencyField';
import LanguageCurrencyFieldBase from '../../../../components/inputs/LanguageCurrencyField';

export const ShowableBox = withShowable(Box);

interface IDialogFooterProps {
  stepNumber: number;
  totalSteps: number;
  stepLabel?: string;
  children: ReactNode;
}

export const DialogFooter: React.FC<IDialogFooterProps> = ({
  children,
  stepNumber,
  totalSteps,
  stepLabel,
}) => {
  return (
    <DialogActions>
      <Flexbox>
        <Box>
          <Typography variant="body1">
            Step {stepNumber} of {totalSteps}: {stepLabel}
          </Typography>
        </Box>
        <Box>{children}</Box>
      </Flexbox>
    </DialogActions>
  );
};

type ControlledTextFieldProps = {
  name: string;
  TextFieldComponent?: React.ComponentType<TextFieldBaseProps>;
} & TextFieldBaseProps;

export const ControlledTextField: React.FC<ControlledTextFieldProps> = ({
  name,
  label,
  required,
  fullWidth,
  containerClass,
  TextFieldComponent = TextFieldBase,
  ...props
}) => {
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error, ...fieldState } }) => (
        <TextFieldComponent
          variant="outlined"
          helperText={undefined}
          containerClass={containerClass}
          fullWidth={fullWidth}
          {...props}
          required={required}
          label={label}
          {...field}
          {...fieldState}
          error={error && (error.message as any)}
        />
      )}
    />
  );
};

type AutocompleteWithControllerProps = {
  name: string;
  label: string;
  AutocompleteComponent: React.ComponentType<any>;
  autocompleteProps: {
    required: boolean;
    textFieldProps?: TextFieldBaseProps;
    [key: string]: unknown;
  };
  cleanseEvent?: boolean;
};

export const AutocompleteWithController: React.FC<
  AutocompleteWithControllerProps
> = ({
  name,
  label,
  AutocompleteComponent,
  autocompleteProps,
  cleanseEvent = false,
}) => {
  function cleanOnChange(onChange: (newValue: any) => void) {
    return (event: SyntheticEvent, newValue: any) => onChange(newValue);
  }

  return (
    <Controller
      name={name}
      render={({ field, fieldState, formState }) => (
        <AutocompleteComponent
          {...autocompleteProps}
          {...field}
          onChange={
            cleanseEvent ? cleanOnChange(field.onChange) : field.onChange
          }
          formState={formState}
          label={label}
          defaultValue={field.value}
          textFieldProps={{
            ...autocompleteProps.textFieldProps,
            ...fieldState,
            error: fieldState.error?.message,
          }}
        />
      )}
    />
  );
};

export const ControlledSurplusBrokerAutocomplete: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  agencyId: string;
}> = ({
  name,
  label = 'Surplus Broker',
  required = false,
  disabled = false,
  agencyId,
}) => {
  return (
    <AutocompleteWithController
      name={name}
      label={label}
      AutocompleteComponent={ManagedSurplusBrokerAutoComplete}
      autocompleteProps={{
        required,
        disabled,
        agencyId,
        textFieldProps: { showErrorPlace: true },
      }}
    />
  );
};

export const ControlledAutocomplete: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  options?: SelectOption<any>[];
}> = ({
  name,
  label = '',
  required = false,
  disabled = false,
  options = [],
}) => {
  return (
    <AutocompleteWithController
      name={name}
      label={label}
      AutocompleteComponent={AutoCompleteBase}
      autocompleteProps={{
        required,
        disabled,
        textFieldProps: { showErrorPlace: true },
        options,
      }}
      cleanseEvent
    />
  );
};

export const ControlledAgentAutocomplete: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  agencyId?: string;
}> = ({
  name,
  label = 'Agent',
  required = false,
  disabled = false,
  agencyId,
}) => {
  return (
    <AutocompleteWithController
      name={name}
      label={label}
      AutocompleteComponent={ManagedAgentByAgencyAutoComplete}
      autocompleteProps={{
        required,
        agencyId,
        disabled,
        textFieldProps: { showErrorPlace: true },
      }}
    />
  );
};

export const ControlledTeamsAutocomplete: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  agencyId?: string;
}> = ({
  name,
  label = 'Team',
  required = false,
  disabled = false,
  agencyId,
}) => {
  return (
    <AutocompleteWithController
      name={name}
      label={label}
      AutocompleteComponent={ManagedTeamByAgencyAutocomplete}
      autocompleteProps={{
        required,
        agencyId,
        disabled,
        textFieldProps: { showErrorPlace: true },
      }}
    />
  );
};

const LanguageCurrencyField: React.FC<LanguageCurrencyFieldProps> = ({
  onChange,
  ...props
}) => {
  function handleChange(
    e: React.ChangeEvent<HTMLInputElement & { rawValue: string }>
  ) {
    e.target.value = e.target.rawValue;
    onChange?.(e);
  }

  return <LanguageCurrencyFieldBase {...props} onChange={handleChange} />;
};

export const ControlledCurrencyField = withFormController(
  LanguageCurrencyField
);

const FormGridContainerBase: React.FC<GridProps> = ({ ...props }) => (
  <Grid container {...props} />
);

export const FormGridContainer = styled(FormGridContainerBase)(({ theme }) => ({
  '& label': {
    fontSize: '1.3333rem',
    fontWeight: 400,
  },
  '& input': {
    padding: '0.875rem 0.5rem',
  },
  '& h3': {
    fontWeight: 600,
    marginBottom: '0.3rem',
    fontSize: '1.4rem',
  },
  '& > *:not(:first-child)': {
    paddingLeft: '.75rem',
    paddingRight: '.75rem',
  },
}));

export const DialogContent = styled(DialogContentBase)(({ theme }) => ({
  padding: '3.6rem 4.91rem',
  maxHeight: '75vh',
  '& h3': {
    color: theme.palette.primary.main,
  },
}));

interface GridRowFieldProps {
  name: string;
  label: string;
  labelProps?: Record<string, any>;
  required?: boolean;
  TextFieldComponent: React.FC<TextFieldBaseProps>;
  textFieldProps: TextFieldBaseProps;
}

export const GridRowField: React.FC<GridRowFieldProps> = ({
  name,
  label,
  labelProps = {},
  textFieldProps = {},
  required = false,
  TextFieldComponent,
}) => {
  return (
    <Grid item container spacing={2}>
      <Grid item xs={7}>
        <InputLabelBase
          htmlFor={name}
          align="right"
          required={required}
          className={undefined}
          inline={undefined}
          inlineBlock={undefined}
          indent={undefined}
          {...labelProps}
          style={{
            paddingTop: '0.5rem',
            ...(labelProps?.style ? labelProps.style : {}),
          }}
        >
          {label}
        </InputLabelBase>
      </Grid>
      <Grid item xs={5}>
        <Box width="40%">
          <TextFieldComponent
            name={name}
            required={required}
            {...textFieldProps}
            showErrorPlace={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const ControlledRadioGroup = withFormController(RadioGroup);

export const ControlledRadioGroupWithLabel: React.FC<any> = ({
  name,
  label = '',
  children,
}) => {
  return (
    <FormControl
      variant="standard"
      component="fieldset"
      fullWidth
      required
      style={{ height: '100%' }}
    >
      <InputLabelBase
        align={undefined}
        className={undefined}
        inline={undefined}
        inlineBlock={undefined}
        indent={undefined}
        htmlFor={name}
        required
        style={{
          padding: '0.5rem 0',
          lineHeight: '1.3333rem',
        }}
      >
        {label}
      </InputLabelBase>
      <ControlledRadioGroup
        name={name}
        aria-label={label}
        label={undefined}
        controllerProps={undefined}
        style={{
          flexDirection: 'row',
        }}
      >
        {children}
      </ControlledRadioGroup>
    </FormControl>
  );
};

interface OverlayProps {
  style?: React.CSSProperties;
  children: ReactNode;
}

const Overlay: React.FC<OverlayProps> = ({ style = {}, children }) => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export const CircularProgressOverlay = ({
  OverlayStyle = {},
  spinnerSize = '5rem',
}) => {
  return (
    <Overlay style={OverlayStyle}>
      <CircularProgress size={spinnerSize} />
    </Overlay>
  );
};
