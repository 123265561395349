import React from 'react';
// mui
import {
  Box,
  Backdrop,
  CircularProgress,
  Card as MuiCard,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Skeleton } from '@mui/lab';
// components
import withRouter from '../../../components/hocs/withRouter';

export const QuoteStepLoader = withRouter(() => {
  const classes = useStyles();

  return (
    <>
      <MuiCard style={{ height: '100%' }}>
        <Box p={3} height="100%">
          <Skeleton />
        </Box>
      </MuiCard>
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.text.link,
  },
}));
