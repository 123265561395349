import React from 'react';
import _ from 'lodash';
import type { UseQueryResult } from '@tanstack/react-query';
import Highlight from 'react-highlighter';

// components
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { NewQuoteSearchNullResult } from '../global-search/AgencyDashboardNullResults';

// utils
import type { AccountDto } from '../../../../types';
import { TypeaheadLoadingSkeleton } from '../global-search/TypeaheadLoadingSkeleton';
import type { _Account } from './StartNewQuote';
import {
  formatAddressAsString,
  formatPhoneAsString,
} from '../../../../i18n/forms/LanguageForm';
import type { GlobalStoredTeam } from '../../../../reducers/team.reducer';
import { COUNTRY_MAPPING } from '../../../../i18n/i18n.language-config';

interface StartNewQuoteSearchResultsProps {
  onChange: (value: string) => void;
  queryCache: UseQueryResult<AccountSearchData>;
  onSelect: (params: _Account) => any;
  searchTerm: string;
  team: GlobalStoredTeam;
  state: string;
}

export interface AccountSearchData {
  accounts: _Account[];
}

const initialData = { accounts: [], isNAD: false };

export const StartNewQuoteSearchResults = ({
  onChange,
  onSelect,
  queryCache,
  searchTerm,
  team,
  state,
}: StartNewQuoteSearchResultsProps) => {
  const theme = useTheme<any>();
  const { data = initialData, isFetching } = queryCache;
  const accounts = data?.accounts ?? [];

  const handleSelect = (value: _Account) => {
    onSelect(value);
    onChange(value.name);
  };

  if (isFetching) {
    return <TypeaheadLoadingSkeleton numOfGroups={1} />;
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" textAlign="center" justifyContent="center">
        <Typography variant="caption" style={{ padding: '1rem' }}>
          Search by client name, address, or domain name
        </Typography>
      </Box>
      <Divider
        style={{
          backgroundColor: theme.agencyDash.border.divider,
          margin: '0 1rem 1rem 1rem',
        }}
      />
      <AccountsSearchListing
        accounts={accounts}
        searchTerm={searchTerm}
        onClick={handleSelect}
        team={team}
        state={state}
      />
    </Box>
  );
};

interface AccountsSearchListingProps {
  accounts: _Account[];
  searchTerm: string;
  onClick: (account: _Account) => void;
  team: GlobalStoredTeam;
  state: string;
}

const AccountsSearchListing = ({
  accounts,
  searchTerm,
  onClick,
  team,
  state,
}: AccountsSearchListingProps) => {
  if (_.isEmpty(accounts)) {
    return (
      <NewQuoteSearchNullResult
        team={team}
        searchTerm={searchTerm}
        state={state}
      />
    );
  }

  return (
    <Box style={{ height: '27rem', overflow: 'auto' }}>
      <List>
        {accounts?.map((account: _Account) => (
          <AccountListItem
            key={account.id}
            account={account}
            searchTerm={searchTerm}
            onClick={onClick}
          />
        ))}
      </List>
    </Box>
  );
};

interface AccountListItemProps {
  account: _Account;
  searchTerm: string;
  onClick: (account: _Account) => void;
}

const AccountListItem = ({
  account,
  searchTerm,
  onClick,
}: AccountListItemProps) => {
  const theme = useTheme();
  const listClasses = useListStyles();

  const language =
    COUNTRY_MAPPING[account.country as keyof typeof COUNTRY_MAPPING];
  const address = formatAddressAsString({
    language,
    address1: account.address1,
    address2: account.address2,
    address3: account.address3,
    city: account.city,
    state: account.state,
    zipCode: account.zipCode,
  });

  return (
    <>
      <ListItem
        className={listClasses.listItem}
        onClick={() => onClick(account)}
      >
        <ListItemText
          primary={
            <Highlight
              search={searchTerm}
              matchStyle={{
                fontWeight: 'bold',
                backgroundColor: 'transparent',
              }}
            >
              {account.name}
            </Highlight>
          }
          secondary={address}
          secondaryTypographyProps={{
            variant: 'caption',
            style: { color: theme.agencyDash.text.secondary },
          }}
        />
        <ListItemRightSideInfo account={account} searchTerm={searchTerm} />
      </ListItem>
      <Divider
        style={{
          backgroundColor: theme.agencyDash.border.divider,
          margin: '0 1rem',
        }}
      />
    </>
  );
};

const ListItemRightSideInfo = ({
  account,
  searchTerm,
}: {
  account: AccountDto;
  searchTerm: string;
}) => {
  const theme = useTheme<any>();
  const language =
    COUNTRY_MAPPING[account.country as keyof typeof COUNTRY_MAPPING];
  const phoneNumber = formatPhoneAsString(language, account.phoneNumber);

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        variant="caption"
        style={{ color: theme.agencyDash.text.secondary, textAlign: 'end' }}
      >
        {phoneNumber}
      </Typography>
      <Typography
        variant="caption"
        style={{ color: theme.agencyDash.text.secondary, textAlign: 'end' }}
      >
        <Highlight
          search={searchTerm}
          matchStyle={{
            fontWeight: 'bold',
            backgroundColor: 'transparent',
          }}
        >
          {account.domainName ? `www.${account.domainName}` : '-'}
        </Highlight>
      </Typography>
    </Box>
  );
};

const useListStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      backgroundColor: theme.agencyDash.background.default,
      cursor: 'pointer',
    },
  },
}));
