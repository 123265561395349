import React from 'react';
import { useFormContext } from 'react-hook-form-4';
import Numeral from 'numeral';
import { SimpleSelect } from '../../../../inputs/selects/SimpleSelect';
import { useRHFError } from '../../../../hooks/index';

const LimitInput = ({ name = 'limit', limitVal = [], ...props }) => {
  const { setValue, errors, clearError } = useFormContext();
  const error = useRHFError(name, errors);

  const options = React.useMemo(
    () =>
      limitVal.map((deduct) => ({
        label: Numeral(deduct).format('$0,0'),
        value: deduct,
      })),
    [limitVal]
  );

  const handleSelect = React.useCallback((event) => {
    setValue(name, event.target.value, { shouldDirty: true });
    clearError(name);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SimpleSelect
        label="Coverage Limit"
        options={options}
        onChange={handleSelect}
        name={name}
        required
        displayEmpty
        renderValue={renderValue}
        error={error}
        {...props}
      />
    </>
  );
};

function renderValue(value) {
  return value || 'Please Choose...';
}

export default LimitInput;
