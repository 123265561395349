import { DialogContent, DialogContentText, useTheme } from '@mui/material';
import CbButton from '../../components/Buttons/CbButton';
import { DialogFooter } from './helpers';
import { useCowbellTranslations } from '../../i18n/translations';

export const Step4 = ({ stepperProps, onCancel, ...aggregateFormState }) => {
  const theme = useTheme();
  const headerStyle = {
    color: theme.palette.text.secondary,
    fontSize: theme.config.textSizes.greatPrimer,
    textAlign: 'center',
  };

  const {
    aggregateFormData: { onSuccess, agencyBilled, brokerHandlesCompliance },
  } = aggregateFormState;

  const { goBack } = stepperProps;
  const { translations } = useCowbellTranslations(['Agency']);

  const billingType = agencyBilled
    ? `${translations.Agency} Billed`
    : 'Direct Billed';
  const complianceType = brokerHandlesCompliance
    ? 'Broker Handles Compliance'
    : 'Cowbell Compliance';

  return (
    <>
      <DialogContent>
        <h3 style={headerStyle}>
          Non-Standard Billing/Compliance Configuration
        </h3>
        <br />
        <DialogContentText align="center">
          You chose <strong>{billingType}</strong> and{' '}
          <strong>{complianceType}</strong>.
          <br />
          This is not a standard configuration, are you sure you want to
          proceed?
        </DialogContentText>
      </DialogContent>
      <DialogFooter message="Step: Confirm">
        <CbButton onClick={onCancel} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton onClick={goBack} styleName="ctaButton">
          Previous
        </CbButton>
        <CbButton onClick={onSuccess} styleName="ctaButton">
          Confirm
        </CbButton>
      </DialogFooter>
    </>
  );
};
