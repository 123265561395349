import React from 'react';
import { SvgIcon } from '@mui/material';

export default function UWInboxNotes({ fill = '#6ba9eb', ...props }) {
  return (
    <SvgIcon
      width="23.632"
      height="18.402"
      viewBox="0 0 23.632 18.402"
      {...props}
    >
      <g transform="translate(0.125 -57.139)">
        <g transform="translate(0 57.264)">
          <g transform="translate(0 0)">
            <path
              d="M23.382,59.347a1.315,1.315,0,0,0-.388-.936l-.52-.52a1.324,1.324,0,0,0-1.872,0l-2.45,2.45V57.73a.466.466,0,0,0-.466-.466H.466A.466.466,0,0,0,0,57.73V74.95a.466.466,0,0,0,.466.466h17.22a.466.466,0,0,0,.466-.466V65.126l4.843-4.843A1.315,1.315,0,0,0,23.382,59.347ZM17.22,74.484H.932V58.2H17.22v3.077L8.693,69.8a.466.466,0,0,0-.113.182l-.867,2.6a.466.466,0,0,0,.589.589l2.6-.867a.466.466,0,0,0,.182-.113l6.135-6.135ZM10.5,71.456l-1.612.537.537-1.612,9.89-9.89,1.075,1.075ZM22.335,59.624l-1.283,1.283-.537-.537-.537-.537,1.283-1.283a.392.392,0,0,1,.555,0l.52.52h0a.392.392,0,0,1,0,.555Z"
              transform="translate(0 -57.264)"
              fill={fill}
              stroke={fill}
              strokeWidth="0.25"
            />
          </g>
        </g>
        <g transform="translate(3.109 62.889)">
          <g transform="translate(0 0)">
            <path
              d="M76.576,180.434H68.534a.466.466,0,1,0,0,.932h8.043a.466.466,0,1,0,0-.932Z"
              transform="translate(-68.068 -180.434)"
              fill={fill}
              stroke={fill}
              strokeWidth="0.25"
            />
          </g>
        </g>
        <g transform="translate(3.109 68.267)">
          <g transform="translate(0 0)">
            <path
              d="M71.445,298.2H68.534a.466.466,0,0,0,0,.932h2.911a.466.466,0,0,0,0-.932Z"
              transform="translate(-68.068 -298.202)"
              fill={fill}
              stroke={fill}
              strokeWidth="0.25"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
