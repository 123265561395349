import { Box, useTheme } from '@mui/material';
import type { DefaultTheme } from '@mui/styles';
import type { WithChildrenProps } from '../../types/components/common';
import SimpleTooltip from '../SimpleTooltip';

export const FlagCell = ({ children }: WithChildrenProps) => {
  return (
    <Box display="flex" justifyContent="flex-start" sx={{ overflow: 'hidden' }}>
      {children}
    </Box>
  );
};

interface BaseFlagProps extends WithChildrenProps {
  color: string;
  tooltip: string;
}

export const BaseFlag = ({ children, color, tooltip }: BaseFlagProps) => {
  const theme = useTheme<DefaultTheme>();

  return (
    <SimpleTooltip title={tooltip}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: color,
          borderRadius: '5px',
          color: theme.palette.flags.icon,
          height: '2rem',
          width: '2rem',
          fontWeight: 'bold',
          fontSize: '1.2rem',
        }}
      >
        {children}
      </Box>
    </SimpleTooltip>
  );
};

interface ColumnConfigParams {
  getCellValue?: (row: unknown) => React.ReactNode;
  name?: string;
  title?: string;
  label?: string;
  width?: string | number;
  align?: string;
  sort?: boolean;
  canToggle?: boolean;
  [x: string]: unknown;
}

export const getFlagsColumnConfig = (options: ColumnConfigParams) => ({
  name: 'flags',
  title: 'Flags',
  width: 's',
  sort: false,
  canToggle: false,
  fixed: 'left',
  ...options,
});
