import * as Yup from 'yup';
import numeral from 'numeral';
import Moment from 'moment';
import { useSnackbar } from 'notistack';
// react
import React from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
// validations
import '../../console/_global/lib/validations/PhoneSchemaType';
// mui
import { DialogActions, DialogContent, Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { withFormController } from '../../components/hocs/withFormController';
import CbButton from '../../components/Buttons/CbButton';
import { PhoneField as PhoneFieldBase } from '../../components/inputs/PhoneField';
// actions
import { patchQuote } from '../../console/agencies/_services/quotes.service';
// platform helpers
import { getShortDateTimeFromUtc } from '../../utils/csvUtils';
import { delayedEvent } from '../../utils/eventUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../reducers/UiSettingsReducers';

const schema = Yup.object().shape({
  customerFirstName: Yup.string()
    .label("Policy Holder's First Name")
    .required(),
  customerLastName: Yup.string().label("Policy Holder's Last Name ").required(),
  customerEmail: Yup.string().label("Policy Holder's Email").email().required(),
  customerPhone: Yup.number()
    .label("Policy Holder's Phone")
    .fromPhone()
    .phone()
    .required(),
});

const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);

const RequestToBind = ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { handleSubmit, ...methods } = useForm({ validationSchema: schema });

  const { isSubmitting } = methods.formState;

  const onSubmit = (formValues) => {
    patchQuote({ id: data.id }, formValues)
      .then((response) => {
        enqueueSnackbar('Request to bind successfully sent', {
          variant: 'success',
        });
        delayedEvent('table-refetch', 1000, AGENCY_QUOTES_LISTING_STORE_ID);
        close();

        if (typeof data.onSuccess === 'function') {
          data.onSuccess(response);
        }
      })
      .catch((error) => {
        enqueueSnackbar('Failed to request quote binding', {
          variant: 'error',
        });

        if (typeof data.onError === 'function') {
          data.onError(error);
        }
      });
  };

  const policyEffectiveDate = getShortDateTimeFromUtc(
    Moment(data.effectiveDate).utc()
  );
  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id="request-to-bind-form">
        <DialogContent>
          <ul className={`${classes.details} list--unstyled`}>
            <li className="paragon-text">
              {data.agencyType || 'Retail'} Agency: {data.agencyName}
            </li>
            <li className="paragon-text">Account Name: {data.companyName}</li>
            <li className="paragon-text">
              Quote# {data.quoteNumber} with total premium{' '}
              {numeral(data.totalPremium).format('$0,0')}
            </li>
            <li className="paragon-text">
              Policy Effective Date: {policyEffectiveDate}
            </li>
          </ul>
          <Divider className={classes.divider} />
          <Grid container spacing={4}>
            <Grid item sm={6} style={{ paddingBottom: 0 }}>
              <TextField
                name="customerFirstName"
                label="Policy Holder First Name"
                placeholder="Please enter policy holder first name"
                required
                fullWidth
                defaultValue={data.customerFirstName}
              />
            </Grid>
            <Grid item sm={6} style={{ paddingBottom: 0 }}>
              <TextField
                name="customerLastName"
                label="Policy Holder Last Name"
                placeholder="Please enter policy holder last name"
                required
                fullWidth
                defaultValue={data.customerLastName}
              />
            </Grid>
            <Grid item sm={6} style={{ paddingTop: 0 }}>
              <TextField
                name="customerEmail"
                label="Policy Holder Email"
                type="email"
                placeholder="Please enter policy holder email"
                required
                fullWidth
                defaultValue={data.customerEmail}
              />
            </Grid>
            <Grid item sm={6} style={{ paddingTop: 0 }}>
              <PhoneField
                name="customerPhone"
                required
                label="Policy Holder Phone"
                fullWidth
                placeholder="(___)___-____"
                defaultValue={data.customerPhone}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" type="submit" onClick={close}>
            Cancel
          </CbButton>
          <CbButton styleName="ctaButton" type="submit" disabled={isSubmitting}>
            Request to Bind
          </CbButton>
        </DialogActions>
      </form>
    </FormContext>
  );
};

const useStyles = makeStyles(() => ({
  details: {
    '& li': {
      marginBottom: '1rem',
    },
  },

  divider: {
    marginTop: '2.5rem',
    marginBottom: '2.5rem',
    width: 'calc(100% + 80px)',
    transform: 'translateX(-40px)',
  },
}));

export default RequestToBind;
