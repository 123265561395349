import { ServiceHandlerFactory } from '../utils/serviceUtils';
import { fetchNaicsCodesParamsMapping } from './naincsServiceMappings';

export const fetchNaicsCodes = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/naics/search',
  },
  paramSchema: fetchNaicsCodesParamsMapping,
});

export const createNaicsCodes = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/naics',
    method: 'post',
  },
});

export const updateNaicsCodes = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/naics',
    method: 'put',
  },
});

export const deleteNaicsCode = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/naics/:id',
    method: 'delete',
  },
});
