import * as qs from 'qs';
import { trimAndAddAsterisk } from '../../utility/QueryUtility';

export const standardQuerySerializer = (params: any) => {
  return qs.stringify(params, { encode: true, arrayFormat: 'comma' });
};

export const standardParamSerializer = [
  { path: 'page' },
  { path: 'size' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: any) => {
      if (orderBy && order) {
        switch (orderBy) {
          case 'modified':
          case 'created':
          case 'retainedDate':
            return `${orderBy},${order}`;
          default:
            return `${orderBy}.keyword,${order}`;
        }
      }
      return null;
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) =>
      !search ? search : trimAndAddAsterisk(search),
  },
  { path: 'f', paramKey: 'filter' },
  { path: 'searchAfter' },
  { path: 'pageRequested' },
  { path: 'docIds', paramKey: 'id.keyword' },
];
