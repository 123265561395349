import React from 'react';

const SVG = ({
  style = {},
  fill = '#707070',
  width = '100%',
  className = '',
  viewBox = '0 0 20 16.67',
}) => (
  <svg
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>.cls-1{`fill:${fill};`}</style>
    </defs>
    <title>Download</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={fill}
          id="Union_3"
          data-name="Union 3"
          d="M0,16.67v-5H1.82v3.19H18.18V11.66H20v3.19h0v1.82ZM3.88,6.92,5.26,5.57,9.05,9.38V0H11V9.35l3.82-3.78,1.35,1.35L10,13Z"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
