import React from 'react';

//components
import Showable from '../../../components/Showable';
import ManuscriptedSubjectivity from './stepper-screens/ManuscriptedSubjectivity';

//schema and constants
import { getSubjectivitySchema } from './stepper-screens/schemas/subjectivitySchema';

//types
import type { ProductType } from '../../../types';
import type {
  QuoteProgressDto,
  StepperState,
  Subjectivity,
} from './types/bindWorkflow.types';
import FallbackScreen from './stepper-screens/FallbackScreen';
import type { QuoteDetailsResponse } from './hooks/useGetQuoteDetails';

export interface StepperScreenProps {
  formHandlers: {
    [key: string]: (payload: { [key: string]: string | boolean }) => void;
  };
  formState: {
    [key: string]: string | number | null | boolean;
  };
  incompletePreBindSteps: { [key: string]: string | number | boolean }[];
  incompletePreIssueSteps: { [key: string]: string | number | boolean }[];
  incompleteSteps: { [key: string]: string | number | boolean }[];
  initialSteps: string[];
  manuscriptSubjectivities: Subjectivity[];
  preBindKeys: string[];
  preIssueKeys: string[];
  quoteDetails: QuoteDetailsResponse;
  stepperState: StepperState;
  quoteProgress: QuoteProgressDto;
  sortedPreBindSteps: { [key: string]: string }[];
  sortedPreIssueSteps: { [key: string]: string }[];
  subjectivities: {
    [key: string]: Subjectivity;
  };
}

const StepperScreen = ({ stepperState, ...props }: StepperScreenProps) => {
  const SubjectivityScreen: React.ElementType = deriveStepperScreen(
    stepperState.step,
    props.quoteDetails.uiData.productType as ProductType
  );

  return (
    <>
      <div data-testid="stepper-screen" />
      <SubjectivityScreen {...stepperState} {...props} />

      {props.manuscriptSubjectivities.map(
        (manuscriptSubjectivity: Subjectivity) => (
          <Showable
            show={stepperState.step === manuscriptSubjectivity.subjectivityKey}
          >
            <ManuscriptedSubjectivity
              key={manuscriptSubjectivity.subjectivityKey}
              subjectivityMetaData={manuscriptSubjectivity}
              {...props}
              {...stepperState}
            />
          </Showable>
        )
      )}
    </>
  );
};

export default StepperScreen;

const deriveStepperScreen = (currentStep: string, productType: ProductType) => {
  const subjectivitySchema = getSubjectivitySchema(productType);
  if (currentStep in subjectivitySchema) {
    return subjectivitySchema[currentStep];
  }

  //Checks if current step is a manuscript subjectivity
  if (currentStep.includes('M')) {
    return React.Fragment;
  }

  return FallbackScreen;
};
