import React from 'react';
import { useSnackbar } from 'notistack';

// mui
import { DialogActions, styled, DialogContent } from '@mui/material';

// components
import CbButton from '../../../../components/Buttons/CbButton';
import { WysiwygBase } from '../../../../components/inputs';

// utils
import { formatCurrencyAsFloat } from '../../../../utils/currency.utils';
import {
  EditorContainer,
  WYSIWYG_BLOCK_TYPES,
  WYSIWYG_INLINE_STYLES,
} from '../../../../utils/notes/note-editor.utils';
import { useAPIErrorHandler } from '../../../../components/hooks/useAPIErrorHandler';

export const ConfirmReserveChangesModal = ({
  data: { updateReserves, changes },
  close,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleAPIError = useAPIErrorHandler();
  const [noteText, setText] = React.useState('');
  const [isReservesUpdating, setIsReservesUpdating] = React.useState(false);

  function handleContentChange(nextContent) {
    setText(nextContent);
  }

  const onSubmit = () => {
    setIsReservesUpdating(true);
    updateReserves(noteText)
      .then(() => {
        setIsReservesUpdating(false);
        enqueueSnackbar('Reserves updated!', { variant: 'success' });
        close();
      })
      .catch(
        handleAPIError('An issue occurred while updating the reserve data.')
      )
      .finally(() => setIsReservesUpdating(false));
  };

  return (
    <form>
      <MuiDialogContent>
        <p>Are you sure you want to make the reserve changes?</p>

        <div style={{ paddingBottom: '1rem' }}>
          {changes.map((change, index) => (
            <p style={{ margin: 0 }} key={index}>
              {change.key} updated from{' '}
              <strong>
                {formatCurrencyAsFloat(change.initial ?? 0)} to{' '}
                {formatCurrencyAsFloat(change.updated)}
              </strong>
            </p>
          ))}
        </div>

        <EditorContainer>
          <WysiwygBase
            placeholder="Type something to remember..."
            onChange={handleContentChange}
            blockTypes={WYSIWYG_BLOCK_TYPES}
            inlineTypes={WYSIWYG_INLINE_STYLES}
          />
        </EditorContainer>
      </MuiDialogContent>

      <DialogActions>
        <CbButton buttonText="Cancel" onClick={close} styleName="cancel" />
        <CbButton
          buttonText="Confirm"
          onClick={onSubmit}
          styleName="ctaButton"
          loading={isReservesUpdating}
          disabled={isReservesUpdating}
        />
      </DialogActions>
    </form>
  );
};

const MuiDialogContent = styled(DialogContent)(
  ({ theme: { config, palette } }) => ({
    fontFamily: config.fonts.stackSans,
    fontSize: config.textSizes.tertia,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.8,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: palette.text.text8,
    padding: '2rem 5rem',
    marginTop: '2rem',
  })
);

export const ConfirmReserveChangesModalConfig = {
  ConfirmReserveChangesModal: {
    component: ConfirmReserveChangesModal,
    config: {
      title: 'Reserve Changes',
      fullWidth: true,
      maxWidth: 'md',
    },
  },
};

export default ConfirmReserveChangesModal;
