import React from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/styles';

// components
import {
  ModalActions,
  ModalContent,
} from '../../console/workflows/bind-quote/stepper-screens/shared/ModalBase';

// services
import { sendSignRequest } from '../../inbox/QuotesService';
import { manageAPIError } from '../../utils';
import { completeQuoteSubjectivity } from '../../api';

export const SurplusLinesNoticeConfirmation = ({ data = {}, close }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const brokerFirstName = data.formValues.surplusLinesBrokerFirstName ?? '';
  const brokerLastName = data.formValues.surplusLinesBrokerLastName ?? '';
  const brokerEmail = data.formValues.surplusLinesBrokerEmail ?? '';
  const surplusLinesState = data.formValues.surplusLinesState ?? '';
  const surplusLicenseNumber = data.formValues.surplusLicenseNumber ?? '';
  const quoteId = data.quoteId ?? '';

  const handleSendToBroker = async () => {
    setIsLoading(true);

    const completeSubjectivityPayload = {
      isSurplusLinesBroker: data.isSurplusLinesBroker,
      surplusNoticeAttested: false,
      surplusLinesNumber: data.formValues.surplusLicenseNumber,
      ...data.formValues,
    };

    const signRequestPayload = {
      brokerFirstName,
      brokerLastName,
      brokerEmail,
      surplusLinesState: surplusLinesState.toLowerCase(),
      surplusLicenseNumber,
      quoteId,
    };

    Promise.all([
      completeQuoteSubjectivity(data.productType, data.quoteSubjectivityId, {
        data: completeSubjectivityPayload,
      }),
      sendSignRequest({}, signRequestPayload),
    ])
      .then(() => {
        close();
        data.goForward();
        enqueueSnackbar('Email has been sent to the surplus lines broker', {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar(
          manageAPIError(err, 'Something went wrong. Please try again later.'),
          { variant: 'error' }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ModalContent>
        <p>
          A summary of the quote along with the Surplus Lines Notice will be
          sent to <br />
          <Span>
            {brokerFirstName} {brokerLastName}
          </Span>{' '}
          at <Span>{brokerEmail}</Span>.
        </p>
        <p>
          Please note that for Surplus Lines Compliance, we will require the
          Surplus Lines Broker Information along with their signature to issue
          the policy.
        </p>
        <p>
          If you have any questions, please feel free to contact{' '}
          <Span>underwriting@cowbellcyber.ai</Span>
        </p>
      </ModalContent>
      <ModalActions
        onClick={handleSendToBroker}
        loading={isLoading}
        disabled={isLoading}
      />
    </>
  );
};

const Span = styled('span')({
  fontWeight: 600,
});

export const SurplusLinesNoticeConfirmationConfig = {
  SurplusLinesNoticeConfirmation: {
    component: SurplusLinesNoticeConfirmation,
    config: {
      fullWidth: true,
      maxWidth: 'md',
      minWidth: 'sm',
    },
  },
};
