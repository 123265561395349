import React from 'react';
import _ from 'lodash';

// components
import { Box, Button, IconButton, MenuItem, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  KeyboardArrowDown,
  HighlightOff as DeleteIcon,
} from '@mui/icons-material';
import { SimpleMenu } from '../../Menus/SimpleMenu';
import FilterIcon from '../../../_assets/svg/Filter.svg';

// utils
import { PubSub } from '../../../utils/eventUtils';
import { withShowable } from '../../../console/_global/lib/withShowable';
import { flattenQuery } from '../../modals/filters/filter.helpers';

const IconButtonShow = withShowable(IconButton);

const TableFilterSelect = ({ filterProps, routeProps }) => {
  const {
    id,
    tableFilterOptions,
    handleDeleteFilter,
    handleNoFilters,
    onSelect,
  } = filterProps;
  const { location, q, replace } = routeProps;
  const { palette } = useTheme();
  const classes = useStyles({ isFiltered: q.f });

  const handleSelect = React.useCallback(
    (event) => {
      const option = JSON.parse(event.target.dataset.option);
      const filterParams = flattenQuery(option.filter);

      // apply the filters
      const { f, fn, page, searchAfter, pageRequested, ...rest } = q;
      replace(location.pathname, {
        query: { ...rest, ...filterParams, fn: option.label },
      });

      if (typeof onSelect === 'function') {
        onSelect(option.label, option.filter);
      }
    },
    // eslint-disable-next-line
    [q]
  );

  const handleShowFilters = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    PubSub.publish('table:filters:toggle', id);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SimpleMenu
        anchor={
          <Box className={classes.buttonContainer}>
            <Box onClick={handleShowFilters} className={classes.buttonIcon}>
              <FilterIcon
                fill={q.f ? palette.common.white : palette.text.primary}
              />
            </Box>
            <Button
              className={classes.button}
              variant="outlined"
              endIcon={<KeyboardArrowDown />}
            >
              {q.fn || 'Filter'}
            </Button>
          </Box>
        }
        minWidth="12.5rem"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem className={classes.menuItem} onClick={handleNoFilters}>
          No filters
        </MenuItem>
        {!_.isEmpty(tableFilterOptions) ? (
          tableFilterOptions.map((option) => {
            return (
              <MenuItem
                className={classes.menuItem}
                onClick={handleSelect}
                data-option={JSON.stringify(option)}
              >
                {option.label}
                <IconButtonShow
                  show={option.system === false}
                  onClick={handleDeleteFilter(option)}
                  size="small"
                >
                  <DeleteIcon className={classes.removeIcon} />
                </IconButtonShow>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem disabled>No saved filters</MenuItem>
        )}
      </SimpleMenu>
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  button: {
    marginLeft: '0 !important',
    border: 'none',
    textTransform: 'unset',

    '&:hover': {
      border: 'none',
    },
  },
  buttonContainer: {
    display: 'flex',
    maxHeight: '2.7rem',
    margin: '0.3rem 1.2rem 0.3rem 0',
    overflow: 'hidden',
    border: ({ isFiltered }) => {
      return isFiltered
        ? `1px solid ${palette.common.cowbellBlue}`
        : `1px solid ${palette.primary.border}`;
    },
    borderRadius: 5,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonIcon: {
    display: 'flex',
    width: 32,
    padding: 8,
    backgroundColor: ({ isFiltered }) =>
      isFiltered ? palette.common.cowbellBlue : 'transparent',
    borderRight: ({ isFiltered }) => {
      return isFiltered
        ? `1px solid ${palette.common.cowbellBlue}`
        : `1px solid ${palette.primary.border}`;
    },
  },
  menu: {
    minWidth: '12.5rem',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: palette.common.white,
  },
  removeIcon: {
    color: palette.common.white,
  },
}));

export default TableFilterSelect;
