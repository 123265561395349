import React from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

// lodash
import _ from 'lodash';

// components
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';

import CbButton from '../../../../components/Buttons/CbButton';

import { useSteps } from '../../../../components/hooks/useSteps';
import { withShowable } from '../../../_global/lib/withShowable';

import { ChangeBillingForm } from './ChangeBillingForm';
import { ChangeSurplusComplianceForm } from './ChangeSuplusComplianceForm';

// other
import { getPolicyDetails } from '../../../../policies/PolicyService';
import { ProductTypes } from '../../../../types';
import { COWBELL_POLICY } from '../../../../components/tables/table_constants';

import {
  PROVIDER_AGENCY,
  PROVIDER_COWBELL,
  CHANGE_BILLING_STEP,
  CHANGE_SURPLUS_COMPLIANCE_STEP,
  SURPLUS_COMPLIANCE_INFORMATION_STEP,
  CONFIRM_CHANGE_STEP,
} from './billing-compliance.consts';

import { UpdateBillingComplianceModalSkeleton } from './UpdateBillingComplianceSkeleton';
import { AgentSurplusLicenseForm } from './AgentSurplusLicenseForm';
import { UpdateBillingComplianceConfirmation } from './UpdateBillingComplianceConfirmation';
import { updatePolicyBilling } from '../../../../api/policies.api';

const cowbellComplianceSteps = [
  CHANGE_BILLING_STEP,
  CHANGE_SURPLUS_COMPLIANCE_STEP,
  CONFIRM_CHANGE_STEP,
];

const cowbellComplianceStepLabels = {
  [CHANGE_BILLING_STEP]: 'Change Billing',
  [CHANGE_SURPLUS_COMPLIANCE_STEP]: 'Change Surplus Compliance',
  [CONFIRM_CHANGE_STEP]: 'Change Confirmation',
};

const agencyComplianceSteps = [
  CHANGE_BILLING_STEP,
  CHANGE_SURPLUS_COMPLIANCE_STEP,
  SURPLUS_COMPLIANCE_INFORMATION_STEP,
  CONFIRM_CHANGE_STEP,
];

const agencyComplianceStepLabels = {
  [CHANGE_BILLING_STEP]: 'Change Biling',
  [CHANGE_SURPLUS_COMPLIANCE_STEP]: 'Change Surplus Compliance',
  [SURPLUS_COMPLIANCE_INFORMATION_STEP]: 'Surplus Compliance Information',
  [CONFIRM_CHANGE_STEP]: 'Change Confirmation',
};

const UpdateBillingComplianceModal = ({ title, data = {}, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    data: policy,
    isLoading: isLoadingPolicy,
    isError: isPolicyError,
    ...policyQuery
  } = useStandardRequest(data.policyId, data.product);

  const [billingProvider, setBillingProvider] =
    React.useState(PROVIDER_COWBELL);

  const [complianceProvider, setComplianceProvider] =
    React.useState(PROVIDER_COWBELL);

  const [agentLicenseInfo, setAgentLicenseInfo] = React.useState({});

  const {
    step,
    steps,
    setSteps,
    goBack,
    goForward,
    jumpTo,
    isFirstStep,
    isLastStep,
    stepNumber,
  } = useSteps({
    initialSteps: cowbellComplianceSteps,
    startStep: CHANGE_BILLING_STEP,
  });

  /**
   * start business logic
   * @documentation https://cowbell.atlassian.net/wiki/spaces/UID/pages/1609957416/Agency+Bill+Direct+Bill
   * @description The below is used for the initial state setup only after a policy is received
   */
  const COMPLIANCE_ENABLED =
    data.product === ProductTypes.p250 && policy?.isSurplus;

  React.useEffect(() => {
    // can't early return in a hook so stuck with this nested garbage
    if (policy) {
      if (policy.isAgencyBilled) {
        setSteps(agencyComplianceSteps);
        setBillingProvider(PROVIDER_AGENCY);
      } else {
        setSteps(cowbellComplianceSteps);
        setBillingProvider(PROVIDER_COWBELL);
      }

      if (!COMPLIANCE_ENABLED) {
        setSteps([CHANGE_BILLING_STEP, CONFIRM_CHANGE_STEP]);

        // edgecase whent the policy is admitted compliance provider is already agency,
        // but we default to cowbell on component mount.
        // This likely can be improved by getting all policy dependencies before mounting the modal
        if (policy.complianceProvider !== complianceProvider) {
          setComplianceProvider(policy.complianceProvider);
        }
      } else {
        setComplianceProvider(policy.complianceProvider);
      }

      // set the default values
      setAgentLicenseInfo(
        _.pick(policy, [
          'agencyMailingAddress1',
          'agencyMailingCity',
          'agencyMailingState',
          'agencyMailingZipCode',
          'surplusLinesBrokerFirstName',
          'surplusLinesBrokerLastName',
          'surplusLinesState',
          'surplusLicenseNumber',
          'surplusLinesBrokerEmail',
        ])
      );
    }
    // eslint-disable-next-line
  }, [data.product, policy]);

  /**
   * @description Update the steps when the user chooses a different compliance provider
   */
  React.useEffect(() => {
    // can't early return in a hook so stuck with this nested garbage
    if (policy && COMPLIANCE_ENABLED) {
      if (complianceProvider === PROVIDER_COWBELL) {
        setSteps(cowbellComplianceSteps);
      }

      if (complianceProvider === PROVIDER_AGENCY) {
        setSteps(agencyComplianceSteps);
      }
    }
    // eslint-disable-next-line
  }, [policy, complianceProvider]);
  /* end business logic */

  /* reset to first step on modal close */
  React.useEffect(() => {
    if (!props.open) {
      // let animation finish before resetting
      setTimeout(() => {
        // reset form
        jumpTo(CHANGE_BILLING_STEP);
        setBillingProvider(policy?.billingProvider || PROVIDER_COWBELL);
        setComplianceProvider(policy?.complianceProvider || PROVIDER_COWBELL);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [props.open, policy]);

  function onSurplusComplianceInformationSubmit(values) {
    setAgentLicenseInfo(values.surplusBrokerData);
    goForward();
  }

  const policyBillingMutation = useMutation(
    (update) => {
      return updatePolicyBilling({ data: update })
        .then(() => props.close())
        .catch((error) => {
          if (error?.response?.data?.error) {
            enqueueSnackbar(error?.response?.data?.error, { variant: 'error' });
            return;
          }

          enqueueSnackbar('Failed to save changes', { variant: 'error' });
        });
    },
    {
      onSuccess: function handlePolicyBillingUpdateSuccess() {
        policyQuery.refetch();
        queryClient.invalidateQueries([COWBELL_POLICY]);
      },
    }
  );

  function handleSaveConfirmation() {
    const surplusBrokerData =
      complianceProvider === PROVIDER_AGENCY
        ? _.pick(agentLicenseInfo, [
            'agencyMailingAddress1',
            'agencyMailingAddress2',
            'agencyMailingCity',
            'agencyMailingState',
            'agencyMailingZipCode',
            'surplusLicenseNumber',
            'surplusLinesState',
            'surplusLinesBrokerFirstName',
            'surplusLinesBrokerLastName',
            'surplusLinesBrokerEmail',
          ])
        : undefined;

    policyBillingMutation.mutate({
      policyId: data.policyId,
      productType: data.product,
      isAgencyBilled: billingProvider === PROVIDER_AGENCY,
      surplusCowbellInsurance: complianceProvider === PROVIDER_COWBELL,
      surplusBrokerData,
    });
  }

  const onBillingChange = (nextValue) => {
    setBillingProvider(nextValue);
    if (nextValue === PROVIDER_COWBELL) {
      setComplianceProvider(PROVIDER_COWBELL);
    }
  };

  const isAgencyComplianceEnabled = billingProvider !== PROVIDER_COWBELL;

  if (isPolicyError) {
    <Dialog {...props}>
      <DialogTitle>
        <Box>Update Billing Compliance</Box>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="h2"
          className="great-primer-text text-center contrast-text"
          paragraph
        >
          Failed to load policy
        </Typography>
        <Typography className="text-center">
          Please refresh and try again
        </Typography>
      </DialogContent>
    </Dialog>;
  }

  if (isLoadingPolicy) {
    return (
      <Dialog {...props}>
        <UpdateBillingComplianceModalSkeleton />
      </Dialog>
    );
  }

  return (
    <Dialog {...props}>
      <DialogTitle>
        <Box>Update Billing Compliance</Box>
      </DialogTitle>
      <DialogContent>
        <Step
          show={step === CHANGE_BILLING_STEP}
          display="flex"
          justifyContent="center"
        >
          <ChangeBillingForm
            agencyName={policy.agencyName}
            provider={billingProvider}
            onChange={onBillingChange}
          />
        </Step>

        <Step
          show={step === CHANGE_SURPLUS_COMPLIANCE_STEP}
          display="flex"
          justifyContent="center"
        >
          <ChangeSurplusComplianceForm
            agencyName={policy.agencyName}
            provider={complianceProvider}
            onChange={setComplianceProvider}
            isAgencyComplianceEnabled={isAgencyComplianceEnabled}
          />
        </Step>

        <Step show={step === SURPLUS_COMPLIANCE_INFORMATION_STEP}>
          <AgentSurplusLicenseForm
            agencyName={policy.agencyName}
            policy={policy}
            onSubmit={onSurplusComplianceInformationSubmit}
          />
        </Step>

        <Step show={step === CONFIRM_CHANGE_STEP}>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item md={8}>
              <UpdateBillingComplianceConfirmation
                agentLicenseInfo={agentLicenseInfo}
                billingProvider={billingProvider}
                complianceProvider={complianceProvider}
                policy={policy}
                product={data.product}
              />
            </Grid>
          </Grid>
        </Step>
      </DialogContent>
      <DialogActions className="flex--spaced">
        <StepDisplay
          step={step}
          stepNumber={stepNumber}
          totalSteps={steps.length}
          provider={complianceProvider}
        />
        <Box>
          <Button
            show={!isFirstStep}
            variant="text"
            size="x-small"
            onClick={goBack}
          >
            Back
          </Button>
          <Button onClick={props.close} styleName="cancel">
            Cancel
          </Button>
          <Button
            show={!isLastStep && step === SURPLUS_COMPLIANCE_INFORMATION_STEP}
            type="submit"
            form={SURPLUS_COMPLIANCE_INFORMATION_STEP}
            styleName="ctaButton"
          >
            Next
          </Button>
          <Button
            show={!isLastStep && step !== SURPLUS_COMPLIANCE_INFORMATION_STEP}
            onClick={goForward}
            styleName="ctaButton"
          >
            Next
          </Button>
          <Button
            show={isLastStep}
            onClick={handleSaveConfirmation}
            styleName="ctaButton"
          >
            {policyBillingMutation.isLoading ? 'Saving...' : 'Save Changes'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

// supporting components
const StepDisplay = ({ step, stepNumber, totalSteps, provider }) => {
  if (provider === PROVIDER_COWBELL) {
    return (
      <Box className="contrast-text">
        Step {stepNumber} of {totalSteps}: {cowbellComplianceStepLabels[step]}
      </Box>
    );
  }

  if (provider === PROVIDER_AGENCY) {
    return (
      <Box className="contrast-text">
        Step {stepNumber} of {totalSteps}: {agencyComplianceStepLabels[step]}
      </Box>
    );
  }
};

const Step = withShowable(Box);
const Button = withShowable(CbButton);

// supporting functions
const useStandardRequest = (policyId, product) => {
  const policyRequest = useQuery(
    ['policy', policyId, 'billing', 'compliance'],
    () =>
      getPolicyDetails(policyId, product, {
        view: 'summary',
      }).then(({ data: policy }) => {
        const cowbellHandlesCompliance = _.get(
          policy,
          'initialRequestData.surplusCowbellInsurance',
          false
        );

        return {
          agencyId: policy.agencyId,
          agencyName: policy.agencyName,
          isAgencyBilled: policy.agencyBilled,
          billingProvider: policy.agencyBilled
            ? PROVIDER_AGENCY
            : PROVIDER_COWBELL,
          complianceProvider: cowbellHandlesCompliance
            ? PROVIDER_COWBELL
            : PROVIDER_AGENCY,
          ...policy?.surplusBrokerData,
          surplusLicenseNumber: policy?.surplusBrokerData?.surplusLinesNumber,
          agencyMailingState:
            policy?.surplusBrokerData?.agencyMailingState?.toUpperCase(),
          surplusLinesState:
            policy?.surplusBrokerData?.surplusLinesState?.toUpperCase(),
          isSurplus: policy.isSurplus,
        };
      })
  );

  return policyRequest;
};

export const UpdateBillingComplianceModalConfig = {
  UpdateBillingComplianceModal: {
    component: UpdateBillingComplianceModal,
    config: {
      fullWidth: true,
      maxWidth: 'md',
      override: true,
    },
  },
};
