import React from 'react';
import _ from 'lodash';

// mui
import { Box, Divider } from '@mui/material';

// components
import SimpleTooltip from '../../../../../../components/SimpleTooltip';
import { CowbellAccountProfileLink } from '../../../../../accounts/admin/profile/CowbellAccountProfileLink';
import {
  AccountData,
  CaptionTypography as Typography,
  Flexbox,
} from '../policy-details.shared-components.utils';

// utils
import { truncateString } from '../../../../../../utils';
import { numberToCurrency } from '../../../../../../utils/currency.utils';
import {
  COUNTRY_MAPPING,
  languages,
} from '../../../../../../i18n/i18n.language-config';
import { deriveCurrencyFormat } from '../../../../../../i18n/currencies';
import { getLanguageFromAccount } from '../../../../../../i18n/utils';
import { useCowbellTranslations } from '../../../../../../i18n/translations';

export const OrganizationDetails = ({
  policy = {},
  account = {},
  latestRiskSelected = false,
}) => {
  const {
    naicsCode,
    industryCode,
    revenue: revenueRaw,
    dunsNumber = '',
    domainName,
    domains,
    url,
    entityType,
    isFranchise,
    primaryIndustry: fullPrimaryIndustry,
    ownershipType,
    noOfEmployeesAll,
  } = policy.firmographicData;

  const language = getLanguageFromAccount(policy.firmographicData);

  const revenue = deriveCurrencyFormat(language, revenueRaw);
  const { otherDomains, fullOtherDomains } = useOtherDomains({ domains });
  const primaryIndustry = truncateString(fullPrimaryIndustry, 25);

  // most recent data
  const accountRevenue = deriveCurrencyFormat(language, account.revenue);
  const {
    otherDomains: accountOtherDomains,
    fullOtherDomains: accountFullOtherDomains,
  } = useOtherDomains({ domains: account.domains });
  const accountFullPrimaryIndustry = _.get(account, 'primaryIndustry', '-');
  const accountPrimaryIndustry = truncateString(accountFullPrimaryIndustry, 25);
  const { t, translationKeys } = useCowbellTranslations();

  return (
    <>
      <Divider style={{ width: '100%', margin: '1rem 0 0.5rem' }} />
      <Flexbox>
        <Typography variant="subtitle2" color="primary">
          DETAILS
        </Typography>
        <CowbellAccountProfileLink account={account} />
      </Flexbox>
      <Divider style={{ width: '100%', margin: '0.5rem 0 1rem' }} />
      <Box display="flex">
        <Box width="50%" marginRight={1}>
          <Flexbox>
            <Typography variant="caption">Industry Code</Typography>
            <Box>
              <Typography>{industryCode || '-'}</Typography>
              <AccountData
                data={account.industryCode}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography variant="caption">
              {t(translationKeys.revenue)}
            </Typography>
            <Box>
              <Typography>{revenue}</Typography>
              <AccountData data={accountRevenue} show={latestRiskSelected} />
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>DUNS Number</Typography>
            <Box>
              <Typography>{dunsNumber !== '' ? dunsNumber : '-'}</Typography>
              <AccountData
                data={account.dunsNumber !== '' ? account.dunsNumber : '-'}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Main Domain</Typography>
            <Box>
              <Typography>{domainName !== '' ? domainName : '-'}</Typography>
              <AccountData
                data={account.domainName !== '' ? account.domainName : '-'}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Other Domain(s)</Typography>
            <Box>
              <SimpleTooltip title={fullOtherDomains} show={!!otherDomains}>
                <Typography>{otherDomains || '-'}</Typography>
              </SimpleTooltip>
              <SimpleTooltip
                title={accountFullOtherDomains}
                show={!!accountOtherDomains}
              >
                <AccountData
                  data={accountOtherDomains || '-'}
                  show={latestRiskSelected}
                />
              </SimpleTooltip>
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Url</Typography>
            <Box>
              <Typography
                component="a"
                href={url ? `https://${url}` : null}
                target="_blank"
                className="link-underlined--hover"
              >
                {url || '-'}
              </Typography>
              <AccountData
                data={account.url || '-'}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box width="50%" marginLeft={1}>
          <Flexbox>
            <Typography>Year Established</Typography>
            <Box mb={latestRiskSelected ? '1.8rem' : '0'}>
              <Typography>{account.yearEstablished}</Typography>
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Entity Type</Typography>
            <Box>
              <Typography>{entityType || '-'}</Typography>
              <AccountData
                data={account.entityType || '-'}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Subsidiary/Franchise</Typography>
            <Box>
              <Typography>{isFranchise ? 'Yes' : 'No'}</Typography>
              <AccountData
                data={account.isFranchise ? 'Yes' : 'No'}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Industry</Typography>
            <Box>
              <SimpleTooltip title={fullPrimaryIndustry}>
                <Typography>{primaryIndustry}</Typography>
              </SimpleTooltip>
              <SimpleTooltip title={accountFullPrimaryIndustry}>
                <AccountData
                  data={accountPrimaryIndustry}
                  show={latestRiskSelected}
                />
              </SimpleTooltip>
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Ownership Type</Typography>
            <Box>
              <Typography>{ownershipType || '-'}</Typography>
              <AccountData
                data={account.ownershipType || '-'}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Number of Employees</Typography>
            <Box>
              <Typography>{noOfEmployeesAll}</Typography>
              <AccountData
                data={account.noOfEmployeesAll || '-'}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
        </Box>
      </Box>
    </>
  );
};

const useOtherDomains = ({ domains }) => {
  return React.useMemo(() => {
    if (!domains) return '-';

    const initialValue = '';
    const commaSeparatedDomains = domains.reduce((prev, cur) => {
      if (prev === '') {
        return `${cur.domain}`;
      }
      return `${prev}, ${cur.domain}`;
    }, initialValue);

    return {
      otherDomains: truncateString(commaSeparatedDomains, 25),
      fullOtherDomains: commaSeparatedDomains,
    };
  }, [domains]);
};
