import { quoteServiceMappings } from '../inbox/quoteServiceMappings';
import {
  formatFinalParams,
  ServiceHandlerFactory,
} from '../utils/serviceUtils';
import { Api } from '../config/axiosConfig';
import { accountServiceMappings } from './accountServiceMappings';
import { ProductTypes } from '../types';
import { ServiceHandler } from '../utils';

/**
 * New location src/api/accounts.api.ts
 * @deprecated
 */
export const fetchAccountStats = ServiceHandlerFactory({
  axios: { url: 'api/account/v1/account/stats/status' },
  paramSchema: accountServiceMappings,
});

export const fetchAgencyBillStats = ServiceHandlerFactory({
  axios: { url: '/api/billing/v1/agencyBilledInvoices/stats' },
  paramSchema: accountServiceMappings,
});

/**
 * New location src/api/accounts.api.ts
 * @deprecated
 */
export const fetchQuickQuoteStats = ServiceHandlerFactory({
  axios: { url: 'api/account/v1/account/quick-quote-premium' },
  paramSchema: accountServiceMappings,
});

export function assignAgency(accountId, agencyDetails) {
  return Api.put(`api/auth/v1/accounts/${accountId}/assign`, agencyDetails);
}

export function markFavAccount(accountId, flag) {
  return Api.put(`api/auth/v1/accounts/${accountId}/favorite/${flag}`);
}

export function syncLeads(accountId) {
  return Api.put(`api/account/v1/account/sync/lead/${accountId}`);
}

export function fetchP100Coverages() {
  return Api.get('api/account/v1/state/p100');
}

export function fetchP250Coverages() {
  return Api.get('/api/account/v1/state/p250');
}

export const getCoveragesByProduct = ServiceHandler({
  url: '/api/account/v1/state/:productType',
});

export const fetchPagedAccounts = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/search',
  },
  isCancelable: true,
  paramSchema: accountServiceMappings,
});

/**
 * @name fetchAccountDetailsById
 * @deprecated
 * * see accounts.api.ts for up to date fn
 */
export function fetchAccountDetailsById(accountId) {
  return Api.get(`api/account/v1/account/${accountId}`);
}

export function fetchAccountDetailsByIdForP100(accountId) {
  return Api.get(
    `api/account/v1/account/${accountId}?product=${ProductTypes.p100}`
  );
}

export function reloadRiskDetails(accountId, cbid) {
  return Api.get(`/api/risk/v1/reload/${cbid}/${accountId}`);
}

export function fetchAccountRiskDetails(accountId) {
  return Api.get(`/api/risk/v1/${accountId}`);
}

export function fetchAccountQuoteDetailsById(params = {}, accountId) {
  const finalParams = formatFinalParams(quoteServiceMappings, params);
  return Api.get(`api/quote/v1/search?accountId.keyword=${accountId}`, {
    params: finalParams,
  });
}

export function updateAccountContact(account) {
  return Api.put(`api/account/v1/account/contact`, account);
}

export function addNewAccount(account) {
  return Api.post('/api/account/v1/account', account);
}

export const updateSecurityQuestions = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/questions',
    method: 'put',
  },
});

export const fetchUsersByAccount = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/accounts/users',
  },
});

export function deleteAccount(accountId) {
  return Api.delete(`/api/auth/v1/accounts/${accountId}`);
}

export function refreshFirmographicData(accountId) {
  return Api.get(`/api/account/v1/account/reload/${accountId}`);
}

export function refreshCustomerFirmographicData(accountId) {
  return Api.get(`/api/account/v1/account/reload/${accountId}`);
}

export function onDemandScan(accountId) {
  return Api.put(`/api/account/v1/account/scan/${accountId}`);
}

export function deleteAccountAgency(accountId) {
  return Api.put(`/api/auth/v1/accounts/${accountId}/unlink`);
}

/**
 * used for org profile group verification,
 * Domains: 1
 * Org Details: 2
 * Industry: 3
 * Contact: 4
 * Other: 5
 * @param groupId
 */
export function verifyOrgProfileGroupById(groupId, flag) {
  return Api.put(`/api/account/v1/account/profile/${groupId}/${flag}`);
}

/**
 * verify entire account
 */
export function verifyOrgProfileAll(details) {
  return Api.put(`/api/account/v1/account/profile/verify/all`, details);
}

export const updateOrgProfile = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/profile',
    method: 'put',
  },
});

/**
 * @param {String} accountId
 * @param {String} agencyId
 * @param {String} docName - name of the affidavit file
 * @param {FormData} file - contains data about the affidavit file
 */

export const getAffidavitInfo = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/upload/doc',
    method: 'post',
  },
});

/**
 * @param {Object} payload
 * @param {String} payload.accountId
 * @param {String} payload.agencyId
 * @param {Object} payload.affidavit - info about the affidavit file to be uploaded
 */
export function uploadAffidavit(payload) {
  return Api.post(`/api/account/v1/account/affdavit/upload`, payload);
}

export const verifyOrgProfile = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/profile/verify',
    method: 'put',
  },
});

export function getProfileScore() {
  return Api.get(`api/account/v1/account/score`);
}

export const getAutocompleteAccounts = ServiceHandlerFactory({
  axios: { url: 'api/nad/v1/search/' },
  paramSchema: accountServiceMappings,
});

export function getAgentsInterestedList() {
  return Api.get('/api/account/v1/agency/agencies');
}

export function assignAccount(accountId, payload) {
  return Api.put(`/api/auth/v1/accounts/${accountId}/assign`, payload);
}

export const updateSocialEngg = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/profile/socialEngineering',
    method: 'put',
  },
});

export function getSocialEngg(accountId) {
  return Api.get(
    `/api/account/v1/account/profile/socialEngineering/${accountId}`
  );
}

export function getMyAgent() {
  return Api.get(`/api/account/v1/agency/myagent`);
}

export function getAutocompleteAgency(params = {}) {
  const finalParams = formatFinalParams(accountServiceMappings, params);
  return Api.get('/api/agency/v1/location/agency/search', {
    params: finalParams,
  });
}

export function resquestAccountAccess(accountId) {
  return Api.put(`/api/auth/v1/accounts/${accountId}/request/access`);
}

export function certifyAccount(payload) {
  return Api.put(`/api/account/v1/account/profile/certify`, payload);
}

export function bulkUpload(data, tags, checkbox, team) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return Api.post(
    `/api/bus/v1/bus/upload?tags=${tags}&addRedAccounts=${checkbox}&teamId=${team.id}&teamName=${team.name}`,
    data,
    config
  );
}

export function updateProductType(accountId, productType) {
  return Api.put(`api/account/v1/account/product/${accountId}/${productType}`);
}
export function sendPaymentReminder(policyId) {
  return Api.post(`/api/billing/v1/invoiceReminder/${policyId}`, {});
}

export const getBulkUploadSummary = ServiceHandlerFactory({
  axios: {
    url: '/api/bus/v1/bus',
  },
  isCancelable: true,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'id':
          case 'numUploaded':
          case 'numSuccess':
          case 'numFailure':
          case 'uploadTime':
            return `${orderBy},${order}`;
          default:
            return orderBy ? `${orderBy}.keyword,${order}` : null;
        }
      },
    },
  ],
});

export const getBulkUploadErrors = ServiceHandlerFactory({
  axios: {
    url: `/api/bus/v1/bus/:id`,
  },
  isCancelable: false,
});
export const deleteBulkUploadErrors = ServiceHandlerFactory({
  axios: {
    url: `/api/bus/v1/bus/:id`,
    method: 'delete',
  },
});

export function downloadAWSDoc(url) {
  return Api.get(url, { responseType: 'blob' });
}

/**
 * Fetch the Additional Named Insured Affidavit document
 * @returns {Object} info about the ANI affidavit document. Download link is in Object.data
 */
export const getAniTemplate = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/template?type=additionalInsured',
    method: 'get',
  },
});

export const getTags = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/tags`,
  },
  isCancelable: false,
});

export const createTag = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/tags',
    method: 'post',
  },
});

export const deleteTag = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/tags',
    method: 'delete',
  },
});

export const addTagToExistingAccount = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/tags/:accountId',
    method: 'post',
  },
});

export const createNewAccount = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/account',
    method: 'post',
  },
});

export const editHostname = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/accounts/hostname/:accountId',
    method: 'put',
  },
});
export const acceptPrivacyConsent = ServiceHandlerFactory({
  axios: {
    url: '/api/connector/v1/attest/:connectorType',
    method: 'put',
  },
});

export const refreshFirmo = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/refresh/:accountId',
    method: 'PUT',
    isCancelable: true,
  },
});

export const uploadAccountDocument = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/account/upload',
    method: 'post',
  },
});

export const getCompetitors = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/account/competitors`,
  },
  isCancelable: false,
});

export const updateAppDetails = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/account/app-details',
    method: 'post',
  },
});

export const attestSurplusCompliance = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/agency/sign',
    method: 'put',
  },
});

export const validateDomainName = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/account/domain?domain=:domain`,
  },
});

export const setFEIN = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/agency/fein/:fein',
    method: 'put',
  },
  isCancelable: true,
});

export const getAgencyLevels = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/agency/agency-levels',
  },
});

export const getEriskKey = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/eriskhub',
  },
});

export const getAvailableProducts = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/state/products ',
  },
});

export const getCoveragesPerProduct = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/state/coverages/:product',
  },
});

export const validateQuoteRequest = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/profile/validate',
  },
  paramSchema: [
    { path: 'accountId' },
    { path: 'renewal' },
    { path: 'product' },
  ],
});

export const doesVerifyAppExistForAccount = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/verify-app/:accountId',
  },
});
