import * as yup from 'yup';

yup.addMethod(yup.string, 'notAllSpecialChars', function () {
  // eslint-disable-next-line no-template-curly-in-string
  return this.test(
    'notAllSpecialChars',
    '${label} must include at least one normal character',
    (value) => {
      const specialChars = ['!', '@', '#', '$', '%', '^', '&', '*'];
      if (typeof value === 'string') {
        return value.split('').some((char) => !specialChars.includes(char));
      }
    }
  );
});
