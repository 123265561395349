import React from 'react';
import Color from 'color';

import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = ({ palette, config }) => {
  return {
    root: {
      padding: '11.2px',
      position: 'relative',
      border: `1px solid ${palette.common.almostWhite}`,
      textAlign: 'left',
      textTransform: 'none',
      lineHeight: 1,
      fontWeight: 400,
      borderRadius: 5,
      maxWidth: ({ fullWidth }) => {
        return fullWidth ? 'unset' : '600px';
      },
      overflow: 'auto',
      scrollBehavior: 'smooth',

      '&:hover': {
        background: Color(palette.common.darkBlue).darken(0.1).string(),
      },
      '&:disabled': {
        backgroundColor: `${palette.background.disabled} !important`,
        opacity: '1 !important',
      },
    },
    select: {
      padding: '11.2px',
      position: 'relative',
      border: `1px solid ${palette.common.almostWhite}`,
      textAlign: 'left',
      textTransform: 'none',
      lineHeight: 1,
      fontWeight: 400,
      borderRadius: 5,
      overflow: 'auto',
      scrollBehavior: 'smooth',

      '&:hover': {
        background: Color(palette.common.darkBlue).darken(0.1).string(),
      },
    },
    label: {
      display: 'block',
      paddingRight: 40,
      fontSize: config.textSizes.normal,
      fontFamily: config.fonts.stackSans,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      lineHeight: '1.2em',
      // color: palette.text.text8,
    },
  };
};

export function MultiSelectButton({ theme, ...props }) {
  let styleProp = {};

  if (props.isOpen) {
    styleProp = {
      backgroundColor: theme.palette.common.darkBlue,
      color: 'white',
    };
  } else if (props.customStyle) {
    styleProp = {
      color: 'white',
      border: 'none',
    };
  } else {
    styleProp = {
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.background.default
          : theme.palette.common.darkGreyActive,
      color: theme.palette.text.text8,
    };
  }

  if (props.error) {
    styleProp.border = `1px solid ${theme.palette.text.text9}`;
  }

  return (
    <Button
      fullWidth
      onClick={props.toggleOpen}
      style={styleProp}
      {...props}
      classes={{
        root: props.classname !== '' ? props.classname : props.classes.root,
        label: props.classes.label,
      }}
    >
      {props.children}
    </Button>
  );
}

export default withStyles(styles, { withTheme: true })(MultiSelectButton);
