import clsx from 'classnames';

// mui
import { Box } from '@mui/material';
import { styled, makeStyles } from '@mui/styles';

import {
  CheckCircleOutlined,
  ExpandMore,
  RadioButtonUnchecked,
} from '@mui/icons-material';

export const TopLevelStep = ({
  showCaret = false,
  stepperState,
  stepName,
  hasStepBeenCompleted = false,
  handleJumpToStep = () => {},
  subSteps = [],
  disableStep = false,
  ...props
}) => {
  const classes = useStyles();

  const { isCompleted, step } = stepperState;
  const isActiveStep = step === stepName || subSteps.indexOf(step) !== -1;

  return (
    <TopLevelStepContainer
      className={clsx(classes.commonStepperStyles, {
        'contrast-text': !isActiveStep && !disableStep,
        [classes.selectedStep]: isActiveStep,
        [classes.disableStep]: disableStep,
      })}
      onClick={handleJumpToStep(stepName)}
      {...props}
    >
      {isCompleted(stepName) || hasStepBeenCompleted ? (
        <CheckCircleOutlined className={classes.completedStep} />
      ) : (
        <RadioButtonUnchecked className={classes.noPointerEvent} />
      )}
      <Box>{stepName}</Box>
      {showCaret && <ExpandMore />}
    </TopLevelStepContainer>
  );
};

export const SubLevelStep = ({
  stepperState,
  subjectivityKey,
  hasStepBeenCompleted,
  children,
  disableStep = false,
  ...props
}) => {
  const classes = useStyles();

  const { isCompleted, step } = stepperState;
  const isActiveStep = step === subjectivityKey;

  return (
    <SubLevelStepContainer
      className={clsx(classes.commonStepperStyles, {
        'contrast-text': !isActiveStep && !disableStep,
        [classes.selectedStep]: isActiveStep,
        [classes.disableStep]: disableStep,
      })}
      {...props}
    >
      {isCompleted(subjectivityKey) || hasStepBeenCompleted ? (
        <CheckCircleOutlined className={classes.completedStep} />
      ) : (
        <RadioButtonUnchecked className={classes.noPointerEvent} />
      )}
      <Box>{children}</Box>
    </SubLevelStepContainer>
  );
};

export const StepWithDescription = ({
  stepperState,
  stepName,
  description,
  handleJumpToStep = () => {},
  disableStep,
  hasStepBeenCompleted = false,
  children,
  ...props
}) => {
  const classes = useStyles();

  const { isCompleted, step } = stepperState;
  const isActiveStep = step === stepName;

  return (
    <Box style={{ padding: '0.25rem 0' }}>
      <StepWithDescriptionContainer
        className={clsx(classes.commonStepperStyles, {
          'contrast-text': !isActiveStep && !disableStep,
          [classes.selectedStep]: isActiveStep,
          [classes.disableStep]: disableStep,
        })}
        onClick={handleJumpToStep(stepName)}
        {...props}
      >
        {isCompleted(stepName) || hasStepBeenCompleted ? (
          <CheckCircleOutlined className={classes.completedStep} />
        ) : (
          <RadioButtonUnchecked style={{ pointerEvents: 'none' }} />
        )}
        <Box>{children}</Box>
      </StepWithDescriptionContainer>
      <Description>{description}</Description>
    </Box>
  );
};

// styled components
const TopLevelStepContainer = styled(Box)({
  fontWeight: 'bold',
  padding: '0.40rem 0',
  cursor: 'pointer',
});

const SubLevelStepContainer = styled(Box)({
  marginLeft: '1.5rem',
  padding: '0.40rem 0',
  cursor: 'pointer',
});

const StepWithDescriptionContainer = styled(Box)({
  fontWeight: 'bold',
  cursor: 'pointer',
});

const Description = styled(Box)({
  paddingLeft: '1.8rem',
  fontSize: '1.2rem',
  fontStyle: 'italic',
});

const useStyles = makeStyles(({ palette, config }) => ({
  completedStep: {
    color: config.colors.cowbellAccent,
    pointerEvents: 'none',
  },
  commonStepperStyles: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '1.2rem',
  },
  selectedStep: {
    color: config.colors.cowbellBlue,
  },
  disableStep: {
    pointerEvents: 'none',
    color: config.colors.mediumGrey,
  },
  noPointerEvent: {
    pointerEvents: 'none',
  },
}));
