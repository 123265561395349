import React from 'react';
import { SvgIcon } from '@mui/material';

export const EditIcon = ({ fill = '#9ba0ae', ...props }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      {...props}
    >
      <g id="Group_11009" transform="translate(-250 -56)">
        <path
          id="Icon_Border"
          fill="none"
          d="M0 0H45V45H0z"
          transform="translate(250 56)"
        />
        <g id="Icon_feather-edit" transform="translate(254.5 60.682)">
          <path
            id="Path_6199"
            d="M26.838 34.318H5.98a4.485 4.485 0 0 1-4.48-4.48V8.98A4.485 4.485 0 0 1 5.98 4.5h10.429a1.5 1.5 0 0 1 0 3H5.98A1.481 1.481 0 0 0 4.5 8.98v20.858a1.481 1.481 0 0 0 1.48 1.48h20.858a1.481 1.481 0 0 0 1.48-1.48V19.409a1.5 1.5 0 0 1 3 0v10.429a4.485 4.485 0 0 1-4.48 4.48z"
            className="cls-2"
            transform="translate(0)"
          />
          <path
            id="Path_6200"
            d="M30 1.318a4.682 4.682 0 0 1 3.311 7.993l-14.25 14.25a1.5 1.5 0 0 1-.7.395l-6 1.5a1.5 1.5 0 0 1-1.819-1.819l1.5-6a1.5 1.5 0 0 1 .395-.7l14.25-14.25A4.651 4.651 0 0 1 30 1.318zM17.233 21.146L31.189 7.189A1.682 1.682 0 0 0 28.81 4.81L14.854 18.767l-.793 3.172z"
            className="cls-2"
            transform="translate(-0.182)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
