import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// helpers
import moment from 'moment/moment';
import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'classnames';
import Color from 'color';
// mui
import { Box, Button, Divider, Grid } from '@mui/material';
import { styled, makeStyles, useTheme } from '@mui/styles';
// hocs
import useUploadZone from '../../../../../../components/fileUploader/v2/hooks/useUploadzone';
import { useFileTypeIcons } from '../../../../../../components/fileUploader/useFileTypeIcons';
import { withFormController } from '../../../../../../components/hocs/forms';
// components
import { TextFieldBase } from '../../../../../../components/inputs/TextFieldBase';
import CbButton from '../../../../../../components/Buttons/CbButton';
import { Uploadzone } from '../../../../../../components/fileUploader/v2/UploadZone';
import { Checkbox } from '../../../../../../components/inputs';
// utils
import {
  bullettedAttestationForAgencyRenewals,
  getAttestationLabelForAgencyRenewals,
  getFileDetailsToUpload,
  getNKLLAttestationLabelForAgencyRenewals,
  LinearUploadProgress,
} from '../../../../../renewals/renewal.utils';
import { withShowable } from '../../../../../_global/lib/withShowable';
import { downloadQuoteApplication } from '../../../../../../utils/docs/docs.utils';
import { InputLabelBase } from '../../../../../../components/inputs/InputLabelBase';
import useGetSubjectivityProgress from '../../../../bind-quote/stepper-screens/shared/useGetSubjectivityProgress';
import { SEND_TO_POLICYHOLDER } from '../../../../../_statics/conditionalBind.statics';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES,
  useAmplitude,
} from '../../../../../../providers/AmplitudeProvider';
import { ProductTypes } from '../../../../../../types';

const P100RenewalSignedUploadApp = ({
  onUpload,
  data,
  quoteSubjectivityData,
  ...props
}) => {
  const classes = styles();

  const {
    files,
    handleUploads,
    isUploading,
    onRemoveFile,
    setFiles,
    ...uploadZoneParams
  } = useUploadZone({
    docType: 'Application',
  });

  const { enqueueSnackbar } = useSnackbar();
  const amplitude = useAmplitude();
  const theme = useTheme();

  const [isAppAcknowledged, setIsAppAcknowledged] = React.useState(false);

  const { quote } = data;

  const {
    onIssuePolicy,
    isNKLLInApp,
    disableAttestation,
    setIsNKLLInApp,
    setDisable,
    onDelete,
    existingAppInfoForAccount,
    shouldSendToPH,
  } = props;

  const {
    accountId,
    created: quoteCreated,
    id: quoteId,
    companyName: name,
    quoteNumber,
    noKnownLossLetterReq,
  } = quote;

  const fileDetails = getFileDetailsToUpload(files);
  const renderFileIcon = useFileTypeIcons(fileDetails.extension);

  const {
    data: subjectivityData = {},
    isFetching: isFetchingSubjectivityData,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId: quoteSubjectivityData?.quoteSubjectivityId,
    quoteId: data?.quote?.id,
    productType: ProductTypes.p100,
  });

  const { handleSubmit, register, setValue, ...methods } = useForm({
    resolver: yupResolver(validationSchema(shouldSendToPH)),
    defaultValues: {
      fileName: '',
    },
  });

  methods.watch('fileName');

  React.useEffect(() => {
    if (!_.isEmpty(existingAppInfoForAccount)) {
      setValue(
        'fileName',
        _.get(existingAppInfoForAccount, 'docInfo.docName', null)
      );
      setFiles([
        {
          path: _.get(existingAppInfoForAccount, 'docInfo.docName', null),
          type: _.get(existingAppInfoForAccount, 'docInfo.type', null),
          name: `${_.get(
            existingAppInfoForAccount,
            'docInfo.docName',
            null
          )}.${_.get(existingAppInfoForAccount, 'docInfo.fileType', null)}`,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [existingAppInfoForAccount]);

  React.useEffect(() => {
    if (
      files &&
      _.isFunction(onUpload) &&
      _.isEmpty(existingAppInfoForAccount)
    ) {
      amplitude.track(AMPLITUDE_EVENTS.uploadDocument, {
        [AMPLITUDE_PROPERTIES.quoteId]: quoteId,
        source: AMPLITUDE_PROPERTIES.source.bindQuoteModal,
      });

      handleUploads({
        asyncUploadFunc: onUpload,
      });
      setValue('fileName', fileDetails.fileName);
    }

    // eslint-disable-next-line
  }, [files]);

  React.useEffect(() => {
    if (!files && !isAppAcknowledged && _.isFunction(setDisable)) {
      setDisable(true);
    }

    if (files && isAppAcknowledged && _.isFunction(setDisable)) {
      setDisable(false);
    }

    if (files && !isAppAcknowledged && _.isFunction(setDisable)) {
      setDisable(true);
    }
    // eslint-disable-next-line
  }, [files, isAppAcknowledged]);

  const handleCheckbox = (field, event) => {
    if (field === 'NKLLAttached') {
      setIsNKLLInApp(event.target.checked);
    }

    if (field === 'AppAcknowledge') {
      setIsAppAcknowledged(event.target.checked);
    }
  };

  const downloadDocs = () => {
    amplitude.track(AMPLITUDE_EVENTS.downloadApplication, {
      source: AMPLITUDE_PROPERTIES.source.bindQuoteModal,
    });

    const created = moment(quoteCreated).unix();

    const downloadData = {
      accountId,
      quoteId,
      created,
      isEndorsement: false,
      companyName: name,
      quoteNumber,
    };
    downloadQuoteApplication(downloadData, enqueueSnackbar);
  };

  const handleRemoveFile = (fileName) => {
    onRemoveFile(fileName);
    if (_.isFunction(onDelete)) {
      onDelete('Application');
    }
  };

  const handleSelection = (option) => {
    amplitude.track(AMPLITUDE_EVENTS.sendEmailToPolicyHolder, {
      [AMPLITUDE_PROPERTIES.quoteId]: quoteId,
    });

    if (_.isFunction(props.setShouldSendToPH)) {
      props.setShouldSendToPH(option);
      setDisable(false);
    }
  };

  const onSubmit = (formValues) => {
    const payload = {
      files,
      isAppAcknowledged,
    };
    let subjectivityParams;
    if (quoteSubjectivityData) {
      subjectivityParams = {
        quoteSubjectivityId: quoteSubjectivityData.quoteSubjectivityId,
        docSignedOn: formValues.docSignedDate,
        payload: {
          signeeFullName: formValues.signeeFullName,
          signeeTitle: formValues.signeeTitle,
          userAttested: true,
        },
      };
    }

    if (_.isFunction(onIssuePolicy)) {
      amplitude.track(AMPLITUDE_EVENTS.issuePolicy, {
        [AMPLITUDE_PROPERTIES.quoteId]: quoteId,
        source: AMPLITUDE_PROPERTIES.bindQuoteModal,
      });

      return onIssuePolicy(payload, subjectivityParams);
    }
  };

  return (
    <FormProvider {...methods}>
      <form id="UPLOAD_APP_FORM" onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={1}
          style={{ fontSize: theme.config.textSizes.tertia }}
        >
          <Grid item xs={12}>
            <p className={clsx('contrastText', classes.title)}>
              Upload Signed App
            </p>
          </Grid>
          <Grid item xs={12}>
            <Uploadzone
              onRemoveFile={handleRemoveFile}
              files={files}
              previewProps={{ disabled: isUploading }}
              {...uploadZoneParams}
            />
          </Grid>

          <ShowableGrid item md={12} show={!files}>
            <Divider />
            <Box display="flex" justifyContent="center">
              <p className={clsx('contrastText', classes.content)}>
                <strong>Need a prefilled application?</strong>
              </p>
            </Box>
          </ShowableGrid>
          <ShowableGrid item md={12} show={!files}>
            <Box display="flex" justifyContent="center">
              <CbButton onClick={downloadDocs} styleName="ctaButton">
                Download App
              </CbButton>
            </Box>
          </ShowableGrid>

          <ShowableGrid item md={12} show={!files}>
            <Box display="flex" justifyContent="center">
              <p className={clsx('contrastText', classes.content)}>
                <strong>Need to contact someone?</strong>
              </p>
            </Box>
          </ShowableGrid>
          <ShowableGrid item md={12} show={!files}>
            <Box display="flex" justifyContent="center">
              <MuiButton
                onClick={() => handleSelection(SEND_TO_POLICYHOLDER)}
                selected={shouldSendToPH === SEND_TO_POLICYHOLDER}
                blueborder
                size="large"
              >
                Send Email to Policy Holder
              </MuiButton>
            </Box>
          </ShowableGrid>

          <MuiBox width="100%" show={files && !isUploading}>
            <MuiDivider />
            <DocInfoContainer>
              <div style={{ width: '65%' }}>
                <Bold>File Name:</Bold>
                <TextField
                  required
                  name="fileName"
                  value={fileDetails.fileName}
                  ref={register('fileName')}
                />
              </div>

              <div>
                <Bold>File Type:</Bold>
                <div>{renderFileIcon}</div>
              </div>

              <div>
                <Bold>Doc Type:</Bold>
                <div className="contrast-text">Application</div>
              </div>
            </DocInfoContainer>

            <DocInfoContainer>
              <Box
                width="30%"
                display="flex"
                justifyContent="end"
                flexDirection="column"
              >
                <InputLabelBase required>Date document signed:</InputLabelBase>
                <TextField
                  type="date"
                  name="docSignedDate"
                  disabled={isFetchingSubjectivityData}
                  InputProps={{
                    inputProps: {
                      min: moment
                        .utc(
                          subjectivityData?.hydratedInfo
                            ?.signatureValidRangeStartDate
                        )
                        .format('YYYY-MM-DD'),
                      max: moment
                        .utc(
                          subjectivityData?.hydratedInfo
                            ?.signatureValidRangeEndDate
                        )
                        .format('YYYY-MM-DD'),
                    },
                  }}
                />
              </Box>
              <Box width="30%">
                <InputLabelBase required>Signature Holder Name:</InputLabelBase>
                <TextField
                  required
                  placeholder="Please enter signee's full name"
                  name="signeeFullName"
                />
              </Box>
              <Box width="30%">
                <InputLabelBase required>
                  Signature Holder Title:
                </InputLabelBase>
                <TextField
                  required
                  name="signeeTitle"
                  placeholder="Please enter signee's title with agency"
                />
              </Box>
            </DocInfoContainer>
            <MuiDivider />

            <MuiCheckbox
              checked={isNKLLInApp}
              onChange={(event) => handleCheckbox('NKLLAttached', event)}
              label={getNKLLAttestationLabelForAgencyRenewals()}
              show={noKnownLossLetterReq === 'REQUIRED'}
              disabled={disableAttestation}
            />

            <MuiCheckbox
              checked={isAppAcknowledged}
              onChange={(event) => handleCheckbox('AppAcknowledge', event)}
              label={getAttestationLabelForAgencyRenewals()}
              disabled={disableAttestation}
            />
            {bullettedAttestationForAgencyRenewals()}
          </MuiBox>
        </Grid>

        <MuiBox show={isUploading}>
          <LinearUploadProgress />
        </MuiBox>
      </form>
    </FormProvider>
  );
};

const TextField = withFormController(TextFieldBase);
const ShowableCheckbox = withShowable(Checkbox);
const MuiBox = withShowable(Box);
const ShowableGrid = withShowable(Grid);

const styles = makeStyles(({ config }) => {
  return {
    title: {
      fontSize: `${config.textSizes.canon} !important`,
      fontWeight: 'normal !important',
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
    },
    content: {
      fontSize: '1.33rem',
      margin: '0.5rem 0 0',
    },
  };
});

const MuiCheckbox = styled(ShowableCheckbox)(({ theme: { palette } }) => ({
  '&.MuiCheckbox-root': {
    top: 0,
    left: 5,
    marginRight: 5,
    position: 'relative',
    color: palette.common.cowbellBlue,

    '& > *': {
      color: palette.common.cowbellBlue,
      marginLeft: 2,
    },
  },

  '&.Mui-disabled': {
    '& svg': {
      color: `${Color(palette.text.primary).fade(0.5).string()} !important`,
    },
  },

  '&.Mui-checked': {
    '& svg': {
      color: palette.common.cowbellBlue,
    },
  },
}));

const MuiDivider = styled(Divider)(() => ({
  margin: '1rem 0',
  width: '100%',
}));

const Bold = styled('label')(({ theme: { palette } }) => ({
  color: palette.primary.contrastText,
  paddingBottom: '0.5rem',
  display: 'block',
}));

const DocInfoContainer = styled('div')({
  display: 'flex',
  gap: '3rem',
});

const MuiButton = styled(Button)(({ blueborder, selected, theme }) => ({
  color: theme.palette.button.text,
  border: blueborder ? `1px solid ${theme.palette.common.cowbellBlue}` : null,
  backgroundColor: selected
    ? theme.palette.common.cowbellBlue
    : theme.palette.button.background2,
  '&:hover, &:focus': {
    backgroundColor: selected
      ? theme.palette.common.cowbellBlue
      : theme.palette.button.background2,
  },
  'box-shadow': '3px 3px 6px 0 rgba(0, 0, 0, 0.5)',
}));

const validationSchema = (shouldSendToPH) => {
  if (shouldSendToPH !== null) return Yup.object({});

  return Yup.object().shape({
    fileName: Yup.string().required().label('File Name'),
    docSignedDate: Yup.string().required().label('Doc signed date'),
    signeeFullName: Yup.string().required().label('Signee name'),
    signeeTitle: Yup.string().required().label('Signee title'),
  });
};

export default P100RenewalSignedUploadApp;
