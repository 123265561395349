import './config/init.config';

import ReactDOM from 'react-dom';

import GlobalProviders from './providers/GlobalProviders';
import { GlobalErrorBoundary } from './components/error-boundaries/GlobalErrorBoundary';

import { BrowserUnsupported } from './components/main/common/BrowserUnsupported';
import { BrowserMappings } from './components/main/common/BrowserMappings';

import './_lib/validations/string.validations';
import './config/numeral/Numeral';

import App from './App';
import 'typeface-oswald';
import 'typeface-titillium-web';
import './i18n/i18n';

const $root = document.getElementById('root');

const browser = BrowserMappings();
if (browser === 'Microsoft Internet Explorer') {
  ReactDOM.render(<BrowserUnsupported />, $root);
} else {
  ReactDOM.render(
    <GlobalProviders>
      <GlobalErrorBoundary>
        <App />
      </GlobalErrorBoundary>
    </GlobalProviders>,
    $root
  );
}
