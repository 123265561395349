import React from 'react';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { Modal } from '../modals/v2/helpers/v2.modal.helpers';

const QueryContext = React.createContext();

export const QueryParamsProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  // derive query state from location.search
  const { modal, modalData, ...q } = React.useMemo(
    () => parseQuery(location.search),
    [location.search]
  );

  if (modal) {
    history.replace({ pathname: location.pathname, search: getNextQ(q) });
    setTimeout(() => {
      Modal.show(modal, { data: modalData });
    }, 1500);
  }

  // custom push and replace functions
  const push = React.useCallback(
    (to, state = {}) => {
      const { query = {}, ...stateRest } = state;
      const nextLocation =
        typeof to === 'string'
          ? { ...location, pathname: to, search: getNextQ(query) }
          : { ...location, ...to, search: to.search || getNextQ(query) };

      history.push(nextLocation, stateRest);
    },
    [history, location]
  );

  const replace = React.useCallback(
    (to, state = {}) => {
      const { query = {}, ...stateRest } = state;
      const nextLocation =
        typeof to === 'string'
          ? { ...location, pathname: to, search: getNextQ(query) }
          : { ...location, ...to, search: to.search || getNextQ(query) };

      history.replace(nextLocation, stateRest);
    },
    [history, location]
  );

  return (
    <QueryContext.Provider value={{ push, replace, query: q || {} }}>
      {children}
    </QueryContext.Provider>
  );
};

export const useQueryParams = () => React.useContext(QueryContext);

// helpers
function parseQuery(queryString) {
  return qs.parse(queryString, { ignoreQueryPrefix: true });
}

function getNextQ(query) {
  return _.isEmpty(query)
    ? ''
    : qs.stringify(
        Object.keys(query).reduce((acc, key) => {
          if (query[key]) return { ...acc, [key]: query[key] };
          return { ...acc };
        }, {}),
        { encode: false, addQueryPrefix: true }
      );
}
