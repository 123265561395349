import { useQuery } from '@tanstack/react-query';
import { useFeature } from '@growthbook/growthbook-react';

import { fetchConsoleVersion } from '../../../api/apis';
import { FEATURE_FLAG_KEYS } from '../../../config/growthbook';

/**
 * Should only be used to fetch the latest version available.
 * Current app version is injected at build-time, which can be accessed via the REACT_APP_VERSION environment
 *
 * @deprecated Deprecating in our migration to Cloudflare. Endpoint will no longer work once we've moved over
 */
export const useConsoleVersion = (options = {}) => {
  const cloudflareFeature = useFeature(FEATURE_FLAG_KEYS.CLOUDFLARE);

  return useQuery(
    ['console', 'version'],
    () => fetchConsoleVersion().then((resp) => resp.data),
    {
      ...options,
      enabled: cloudflareFeature.off,
    }
  );
};
