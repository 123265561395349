import _ from 'lodash';

import { StepperModal } from '../../components/modals/v2/StepperModal/StepperModal';
import { useFormAggregator } from '../../components/hooks/useFormAggregator';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { useGetPlatformRegion } from '../../utils';

const STEP_1 = 'STEP_1';
const STEP_2 = 'STEP_2';
const STEP_3 = 'STEP_3';
const STEP_4 = 'STEP_4';

const startStep = STEP_1;
const initialSteps = [STEP_1, STEP_2, STEP_3, STEP_4];

const CowbellAddAgencyModalV2 = ({ data: parentAgency, ...props }) => {
  const region = useGetPlatformRegion();

  const aggregateFormState = useFormAggregator({
    initialState: {
      region,
      meta: {
        inheritanceMode: !!parentAgency,
      },
      ..._.pick(parentAgency, [
        'npn',
        'fein',
        'segment',
        'level',
        'isWholesale',
        'muteCommunications',
        'quotingDba',
        'brokerHandlesCompliance',
        'agencyBilled',
        'commission',
        'maxFee',
        'additionalBrokerFee',
        'hardLinkOnQuote',
        'billingContactName',
        'billingContactPhone',
        'billingContactEmail',
        'nationalAccountContactName',
        'paymentTerms',
      ]),
      parentAgencyId: _.get(parentAgency, 'id'),
      parentAgencyName: _.get(parentAgency, 'name'),
    },
  });

  return (
    <StepperModal startStep={startStep} initialSteps={initialSteps}>
      <Step1 name={STEP_1} {...aggregateFormState} onCancel={props.close} />
      <Step2 name={STEP_2} {...aggregateFormState} onCancel={props.close} />
      <Step3 name={STEP_3} {...aggregateFormState} onCancel={props.close} />
      <Step4 name={STEP_4} {...aggregateFormState} onCancel={props.close} />
    </StepperModal>
  );
};

export const CowbellAddAgencyModalV2Config = {
  CowbellAddAgencyModalV2: {
    component: CowbellAddAgencyModalV2,
    config: {
      maxWidth: 'md',
    },
  },
};
