import React from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';

import { Box as MuiBox, styled } from '@mui/material';
import { Skeleton } from '@mui/lab';

import { withShowable } from '../_global/lib/withShowable';
import { getMe } from '../../api/UserService';

// api
import { getJumpToken } from '../../api/auth/jump.api';

// utils
import { sleep } from '../../utils/appUtils';
import { cacheAuthTokens } from '../../utils/next/auth.utils';

const Box = withShowable(MuiBox);

const USER_CACHE_STALE_MINUTES = 10;

export const PersonaLoadingView = () => {
  const location = useLocation();
  const history = useHistory();
  const { token: tempToken } = useParams();

  const query = React.useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );

  const { data: jumpToken } = useQuery(
    ['token', 'jump', tempToken],
    () => getJumpToken(tempToken).then(({ data }) => data),
    {
      enabled: !!tempToken,
      onSuccess: (data) => {
        cacheAuthTokens(data.accessToken, data.refreshToken);
      },
    }
  );

  const { data: accountType } = useQuery(
    ['me', jumpToken],
    () =>
      getMe().then((resp) => {
        // console.log(resp.data);
        return resp.data;
      }),
    {
      staleTime: 60000 * USER_CACHE_STALE_MINUTES,
      select: (data) => data.account.accountType,
    }
  );

  React.useEffect(() => {
    (async () => {
      if (accountType) {
        const entryRoute = PERSONA_PATH_MAPPING[accountType];
        await sleep(800);
        history.replace(entryRoute);
      }
    })();
    // eslint-disable-next-line
  }, [accountType, jumpToken]);

  return (
    <Container>
      <Paper
        display="flex"
        justifyContent="space-between"
        width="100%"
        height="4.66rem"
        marginBottom="1rem"
      >
        <Box>
          <Skeleton variant="rectangular" width="115px" height="100%" />
        </Box>
        <Box display="flex">
          <Skeleton variant="circular" width="40px" height="40px" />
          <Skeleton width="100px" style={{ marginLeft: '1rem' }} />
        </Box>
      </Paper>
      <Box display="flex" flexDirection="row" height="100%">
        <Paper width="11.6666666667rem">
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
        </Paper>
        <Box width="calc(100% - 11.6666666667rem)" paddingLeft="1rem">
          <Paper height="100%">
            <Skeleton width="10%" height="40px" />
            <Skeleton width="40%" />
            <Skeleton width="35%" />

            <Box mt={2}>
              <Skeleton width="10%" height="40px" />
              <Skeleton width="30%" />
              <Skeleton width="20%" />
              <Skeleton width="25%" />
            </Box>

            <Box mt={2}>
              <Skeleton width="10%" height="40px" />
              <Skeleton width="30%" />
              <Skeleton width="20%" />
              <Skeleton width="25%" />
            </Box>

            <MessageContainer>
              <Message show={!!query.accountName}>
                You're entering <strong>{query.accountName}</strong>.
              </Message>
              <Message show={!query.accountName}>
                We're preparing you to enter an account.
              </Message>
              <Message>Please wait.</Message>
            </MessageContainer>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 1rem 1rem 1rem',
  background: theme.palette.background.default,
}));

const Paper = styled(Box)(({ theme }) => ({
  padding: '1rem',
  background: theme.palette.background.paper,
  position: 'relative',
}));

const MessageContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const Message = styled(Box)(() => ({
  fontSize: '1.5rem',
  textAlign: 'center',
}));

const PERSONA_PATH_MAPPING = {
  COWBELL: '/admin/dashboard',
  DIGITAL: '/da/dashboard',
  MSSP: '/mssp/dashboard',
  CARRIER: '/carrier/dashboard',
  VENDOR: '/vendor/claims',
  CLUSTER: '/cluster/dashboard',
  AGENCY: '/agency/dashboard',
  CUSTOMER: '/customer/dashboard',
};
