import _ from 'lodash';
import Axios from 'axios';

// utils
import { PubSub } from '../../utils/eventUtils';

import {
  cacheAuthTokens,
  retrieveAuthTokens,
} from '../../utils/next/auth.utils';

export function getAxiosBaseURL() {
  // if we are NOT running a production build (ie. local development), do not provide a baseURL
  if (import.meta.env.DEV) {
    return undefined;
  }

  // take the current hostname
  const url = new URL(window.location.href);
  const currentHostname = url.hostname;

  // mutate the url object by replacing the bottom-most subdomain with the 'api.' subdomain
  const [, ...restDomains] = currentHostname.split('.');
  const API_HOSTNAME = `api.${restDomains.join('.')}`;
  url.hostname = API_HOSTNAME;

  // eslint-disable-next-line no-console
  console.log(`returning Axios baseURL as: ${url.origin}`);
  return url.origin;
}

const ApiInstance = Axios.create({
  baseURL: getAxiosBaseURL(),
  headers: {
    'Content-Type': 'application/json',
    'Z-Client-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    'Access-Control-Allow-Origin': '*',
  },
});

ApiInstance.interceptors.request.use(
  (config) => {
    const isPublic = checkPublicRoutes(config.url);
    const isSignedUrl = config.url.indexOf('amazonaws.') >= 0;

    if (isSignedUrl) {
      const newConfig = config;
      delete newConfig.headers.Authorization;
      return newConfig;
    }
    if (isPublic || config.headers.Authorization) {
      return config;
    }

    // Forward compatibility for next auth flow
    const { accessToken } = retrieveAuthTokens();
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  },
  (error) => Promise.reject(error)
);

const publicRoutes = [
  '/signup',
  '/login',
  '/loading',
  '/loading/token',
  '/validate/workflowId',
  '/wf/token',
  '/confirm',
  '/validateSignUp',
  '/reset?g-recaptcha-response',
  '/checkPasswordReset',
  '/validatePasswordReset',
  '/validateMFAReset',
  '/resendValEmail',
  '/join',
  '/validationToken',
  '/validationTokens',
  '/checkUserInvited',
  '/validateUserInvited',
  '/sso/token',
  '/sso/status',
  '/login/sitekey',
  '/application-signed',
];

// TODO, making a note that this could be a potential area of improvement for when we pass dynamic strings into our API paths
const whitelistUrls = [
  '/login/status',
  '/users/get-accounts',
  '/users/add-accounts',
];

function checkPublicRoutes(url) {
  const isWhitelisted = whitelistUrls.find(
    (partial) => url.indexOf(partial) !== -1
  );
  const isBlacklisted = publicRoutes.find(
    (partial) => url.indexOf(partial) !== -1
  );

  return !isWhitelisted && isBlacklisted;
}

ApiInstance.interceptors.response.use(
  (response) => {
    const url = _.get(response, 'request.responseURL');
    const isLogin =
      url &&
      (url.indexOf('/login') !== -1 ||
        url.indexOf('auth/v1/sso/token') !== -1 ||
        url.indexOf('auth/v1/signup?validationToken=') !== -1 ||
        url.indexOf('auth/v1/validateUserInvited') !== -1 ||
        url.indexOf('auth/v1/validatePasswordReset') !== -1 ||
        url.indexOf('auth/v1/validateMFAReset') !== -1 ||
        url.indexOf('auth/v1/join?validationToken') !== -1 ||
        url.indexOf('auth/v1/wf/token') !== -1 ||
        Boolean(url.match(/\/auth\/v1\/admin\/enter\/.+/, 'im')) ||
        Boolean(url.match(/\/auth\/v1\/valInvitedUser\/.+/, 'im')));

    // parse & set the token along with the user's scopes ONLY on login
    if (isLogin) {
      const accessToken = _.get(response, 'data.accessToken');
      const refreshToken = _.get(response, 'data.refreshToken');

      if (Boolean(accessToken) && Boolean(refreshToken)) {
        cacheAuthTokens(accessToken, refreshToken);
      }
    }

    return response;
  },
  (error) => {
    const { response } = error;
    // add 403 later*/
    if (!response) {
      // console.log('ERROR: no response', error);
    } else if (response.status === 307) {
      // console.log('307!!');
    } else if (response.status === 300 || response.status === 401) {
      // store.dispatch(logout());
    } else if (response.status === 500 || response.status === 502) {
      PubSub.publish('api:error:5xx', {
        message:
          'Oops!! Something is Wrong with our Server. Please Try Again after Few Minutes!',
      });
    } else if (response.status === 400 && response.data) {
      // console.log('returned 400, may be awsfile download', response);
    }

    if (Axios.isCancel(error)) {
      return Promise.reject(new Error('Request Cancelled'));
    }

    return Promise.reject(error);
  }
);

export default ApiInstance;
