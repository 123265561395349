import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, DialogTitle, Grid, Typography } from '@mui/material';

import CbButton from '../../../../components/Buttons/CbButton';
import { PhoneField } from '../../../../components/inputs';
import {
  ControlledAutocomplete,
  ControlledAgentAutocomplete,
  ControlledTeamsAutocomplete,
  ControlledTextField,
  DialogFooter,
  FormGridContainer,
  DialogContent,
} from '../helpers';

import { requiredSelectedOptionTestCreator } from '../utils/validation';

import type { CreateEditP500FlowStep } from './config';
import type { AggregatedP500FormData } from '../CreateEditPrimePlusPolicyModal';
import type { AccountDto } from '../../../../types';
import type { IUseStepsData } from '../types';
import { useCowbellTranslations } from '../../../../i18n/translations';

const fieldNames = [
  'policyholderFirstName',
  'policyholderLastName',
  'policyholderEmail',
  'policyholderPhone',
  'agency',
  'agent',
  'agentTeam',
  'agencySurplusComplianceName',
] as const;

interface IPolicyContactDetailsFormProps {
  stepperData: IUseStepsData<CreateEditP500FlowStep>;
  formsData: AggregatedP500FormData;
  account: Pick<AccountDto, 'id' | 'name' | 'state'> & Partial<AccountDto>;
  onClose: () => void;
  onFormSubmit: (formValues: Partial<AggregatedP500FormData>) => void;
}

const PolicyContactDetailsForm: React.FC<IPolicyContactDetailsFormProps> = ({
  stepperData,
  formsData,
  account,
  onFormSubmit,
  ...props
}) => {
  const pickedFormFields = React.useMemo(
    () => _.pick(formsData, fieldNames),
    [formsData]
  );
  const { t, translationKeys } = useCowbellTranslations();
  const agencyTranslation = t(translationKeys.agency);
  const agentTranslation = t(translationKeys.agent);

  const formMethods = useForm<typeof pickedFormFields>({
    defaultValues: pickedFormFields,
    resolver: yupResolver(formValidationSchema),
  });

  React.useEffect(() => {
    formMethods.reset(pickedFormFields);
  }, [pickedFormFields]);

  const agencyOptions = React.useMemo(
    () =>
      account.agencies?.map((currAgency) => ({
        value: currAgency.agencyId!,
        label: currAgency.agencyName!,
        meta: { ...currAgency },
      })),
    [account.agencies]
  );

  function handleCancel() {
    props.onClose();
  }

  useFieldsResetOnUpdate(formMethods, 'agency', ['agent', 'agentTeam']);

  const agencyName = formMethods.watch('agency')?.label;
  React.useEffect(() => {
    if (!formMethods.getValues().agencySurplusComplianceName) {
      formMethods.setValue('agencySurplusComplianceName', agencyName);
    }
  }, [agencyName]);

  return (
    <>
      <DialogTitle>
        <Box>Generate PrimeCloud Policy</Box>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <form
            id="policy-contact-details"
            onSubmit={formMethods.handleSubmit(onFormSubmit)}
          >
            {' '}
            <FormGridContainer>
              <Typography component="h3">{agencyTranslation}</Typography>

              <Grid item container spacing={4}>
                <Grid item xs={6}>
                  <ControlledAutocomplete
                    name="agency"
                    label={agencyTranslation}
                    required
                    disabled={Boolean(pickedFormFields.agency.value)}
                    options={agencyOptions}
                  />
                </Grid>

                <Grid item container xs={6} spacing={2}>
                  <Grid item xs={6}>
                    <ControlledTeamsAutocomplete
                      name="agentTeam"
                      label="Team"
                      required
                      agencyId={formMethods.getValues().agency?.value}
                      disabled={
                        !formMethods.getValues().agency?.value ||
                        Boolean(pickedFormFields.agentTeam.value)
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <ControlledAgentAutocomplete
                      name="agent"
                      label={agentTranslation}
                      required
                      agencyId={formMethods.getValues().agency?.value}
                      disabled={!formMethods.getValues().agency?.value}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </FormGridContainer>
            <FormGridContainer style={{ margin: '1.3rem 0' }}>
              <Typography component="h3">Policyholder</Typography>

              <Grid item container spacing={4}>
                <Grid item xs={6}>
                  <ControlledTextField
                    name="policyholderFirstName"
                    label="Policyholder's First Name"
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <ControlledTextField
                    name="policyholderLastName"
                    label="Policyholder's Last Name"
                    required
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={4}>
                <Grid item xs={6}>
                  <ControlledTextField
                    name="policyholderEmail"
                    label="Policyholder's Email"
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <ControlledTextField
                    name="policyholderPhone"
                    label="Policyholder's Phone"
                    required
                    TextFieldComponent={PhoneField}
                  />
                </Grid>
              </Grid>
            </FormGridContainer>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogFooter
        stepNumber={stepperData.stepNumber}
        totalSteps={stepperData.steps.length}
        stepLabel="Policy Contact Details"
      >
        <CbButton onClick={handleCancel} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton
          type="submit"
          form="policy-contact-details"
          styleName="ctaButton"
        >
          Next
        </CbButton>
      </DialogFooter>
    </>
  );
};

export default PolicyContactDetailsForm;

const formValidationSchema = Yup.object().shape({
  policyholderFirstName: Yup.string()
    .required()
    .label("Policyholder's First Name"),
  policyholderLastName: Yup.string()
    .required()
    .label("Policyholder's Last Name"),
  policyholderEmail: Yup.string()
    .email()
    .required()
    .label("Policyholder's Email"),
  policyholderPhone: Yup.number()
    .fromPhone()
    .phone()
    .required()
    .label("Policyholder's Phone"),
  agency: Yup.object()
    .test(requiredSelectedOptionTestCreator('Agency is a required field'))
    .label('Agency'),
  agent: Yup.object()
    .test(requiredSelectedOptionTestCreator('Agent is a required field'))
    .label('Agent'),
  agentTeam: Yup.object()
    .shape({})
    .test(requiredSelectedOptionTestCreator('Team is a required field'))
    .label('Team'),
});

function useFieldsResetOnUpdate(
  formMethods: any,
  fieldToWatch: string,
  reset: string[]
) {
  formMethods.watch(fieldToWatch);
  const { agency } = formMethods.getValues();

  React.useEffect(() => {
    reset.forEach((field) => {
      formMethods.resetField(field);
    });
  }, [agency.value]);
}
