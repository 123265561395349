import React from 'react';
import { Link, useHistory } from 'react-router-dom';
// mui
import {
  DialogActions,
  DialogContent,
  useTheme,
  Dialog,
  Box,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

// components
import CbButton from '../../../components/Buttons/CbButton';

// utils
import { withShowable } from '../../_global/lib/withShowable';
import { useAllow } from '../../../components/hooks/useAllow';

export const RenewalMessageForCowbell = ({
  data,
  iconStyles,
  close,
  ...props
}) => {
  const theme = useTheme();
  const classes = styles();
  const { push } = useHistory();

  const CAN_COWBELL_ACCOUNT = useAllow(['account:cowbell']);
  const CAN_MANAGE_QUOTE = useAllow(['quote:manage']);

  const { companyName, agency } = data;

  const handleLink = () => {
    push(`/admin/agencies?search=${agency?.agencyId}`);
    close();
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        <Box>
          <ReportProblemIcon
            fontSize="large"
            className={`${iconStyles.root} ${classes.icon}`}
          />
          <span style={{ color: theme.config.colors.textRed }}>
            Cannot Generate Renewal Quote(s)
          </span>
        </Box>

        <IconButton className="iconBody" onClick={close} size="large">
          <img src="images/Close-Modal.svg" alt="modal-close" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="modal-title"
        style={{ textAlign: 'left', fontWeight: 'normal' }}
      >
        <MuiBox show={CAN_COWBELL_ACCOUNT}>
          Account <strong>{companyName}</strong> is up for renewal. Please jump
          into the agency{' '}
          <Link
            onClick={handleLink}
            style={{ color: theme.config.colors.cowbellBlue }}
          >
            <strong>{agency?.agencyName}</strong>
          </Link>
          , go to Policies Tab and hit on 'Generate Renewal Quote' option in the
          hamburger menu to generate renewal quote(s).
        </MuiBox>

        <MuiBox show={!CAN_COWBELL_ACCOUNT && CAN_MANAGE_QUOTE}>
          Account <strong>{companyName}</strong> is up for renewal. In order to
          generate renewal quote(s), please contact support@cowbellcyber.ai.
        </MuiBox>
      </DialogContent>
      <DialogActions>
        <CbButton
          styleName="ctaButton"
          buttonText="OK"
          onClick={close}
          size="small"
        />
      </DialogActions>
    </Dialog>
  );
};

const MuiBox = withShowable(Box);

const styles = makeStyles(({ config }) => {
  return {
    icon: {
      fill: `${config.colors.textRed} !important`,
    },
  };
});

export const RenewalMessageForCowbellConfig = {
  RenewalMessageForCowbell: {
    component: RenewalMessageForCowbell,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      title: 'Cannot Generate Renewal Quote(s)',
      override: true,
    },
  },
};

export default RenewalMessageForCowbell;
