import { ServiceHandler } from '../../utils/api.utils';
import {
  standardQuerySerializer,
  serializeElasticPageableTokenResponse,
} from '../serializers';
import { claimServiceMappings } from './claim.params-serializer';
import { applyQueryConfig, responseStringTransformer } from '../../utils';
import { claimTasksServiceMappings } from './claim-tasks.params-serializer';
import { lossRunsParamsSerializer } from './loss-runs.params-serializer';
import type { GetClaimsReservesResponse } from './responses/GetClaimsReservesResponse';
import { claimsReservesServiceMappings } from './claims-reserves.params-serializer';
import type { VendorCategory } from '../../types/claims/VendorCategory';
import { VendorSubcategory } from '../../types/claims/VendorCategory';

const claimsParamSerializer = (params: unknown[]) => {
  return applyQueryConfig(claimServiceMappings, params);
};

const claimsReservesParamSerializer = (params: unknown[]) => {
  return applyQueryConfig(claimsReservesServiceMappings, params);
};

export const getClaims = ServiceHandler({
  url: '/api/claimsmgmt/v1',
  paramsSerializer: (params) =>
    standardQuerySerializer(claimsParamSerializer(params)),
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableTokenResponse,
  ],
});

export const getClaim = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId',
});

export const getClaimById = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId',
});

export const createClaim = ServiceHandler({
  url: '/api/claimsmgmt/v1',
  method: 'POST',
});

export const updateClaim = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId',
  method: 'PUT',
});

export const deleteClaim = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId',
  method: 'DELETE',
});

export const getClaimIncidentTypes = ServiceHandler({
  url: '/api/claimsmgmt/v1/incidentTypes',
});

// This API contains incident types AND secondary incident types
export const getClaimIncidentTypesV2 = ServiceHandler({
  url: '/api/claimsmgmt/v1/incidentTypesV2',
});

export const getLitigationStatuses = ServiceHandler({
  url: 'api/claimsmgmt/v1/litigationStatuses',
});

export const getClaimsHandlers = ServiceHandler({
  url: '/api/auth/v1/users/claim-handlers',
});

export const updateClaimIncidentInfo = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/incidentinfo',
  method: 'put',
});
export const updateClaimsCoverageInfo = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/coverageinfo',
  method: 'PUT',
});

export const getClaimTasks = ServiceHandler({
  url: '/api/claimsmgmt/v1/task/claim/:claimId',
  paramsSerializer: (params) =>
    standardQuerySerializer(
      applyQueryConfig(claimTasksServiceMappings, params)
    ),
});

export const getClaimTaskCategories = ServiceHandler({
  url: '/api/claimsmgmt/v1/task/category',
});

export const createClaimTask = ServiceHandler({
  url: '/api/claimsmgmt/v1/task',
  method: 'POST',
});

export const deleteClaimTask = ServiceHandler({
  url: '/api/claimsmgmt/v1/task/:taskId',
  method: 'DELETE',
});

export const updateClaimTask = ServiceHandler({
  url: '/api/claimsmgmt/v1/task/:taskId',
  method: 'PUT',
});

export const getClaimTaskStatuses = ServiceHandler({
  url: '/api/claimsmgmt/v1/task/status',
});

export const getClaimHandlers = ServiceHandler({
  url: '/api/auth/v1/users/claim-handlers',
});

export const updateMostLikelyOutcome = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/mostlikelyoutcome',
  method: 'put',
});

// stats bar
export const getClaimsStatusAggs = ServiceHandler({
  url: '/api/claimsmgmt/v1/aggregate/claims',
  paramsSerializer: (params) =>
    standardQuerySerializer(claimsParamSerializer(params)),
});

export const getClaimsIncurredAggs = ServiceHandler({
  url: '/api/claimsmgmt/v1/aggregate/incurred',
  paramsSerializer: (params) =>
    standardQuerySerializer(claimsParamSerializer(params)),
});

export const getClaimFinancials = ServiceHandler({
  url: '/api/policy/v1/claims/policyinfo',
});

type ClaimsReservesResp = Record<VendorCategory, number>;
const transformClaimReservesResponse = (data: ClaimsReservesResp) => ({
  firstPartyExpenseReserve: data['First Party Expense'],
  firstPartyLossReserve: data['First Party Loss'],
  lossAdjustmentReserve: data['Loss Adjustment Expense'],
  thirdPartyLossReserve: data['Third-Party Loss'],
  thirdPartyExpenseReserve: data['Third-Party Expense'],
});

export const getClaimReserveCategories = ServiceHandler<
  any,
  ReturnType<typeof transformClaimReservesResponse>
>({
  url: '/api/claimsmgmt/v1/:claimId/reserve/total',
  transformResponse: [
    responseStringTransformer,
    transformClaimReservesResponse,
  ],
});

type PrimaryCategory = Exclude<VendorCategory, 'Incident Response Cost'>;
type PrimaryCategoryBreakdown = Record<string, number | null>;

export type ClaimsReserveBreakdownRespData = {
  Total: number;
  Text: string | null;
} & Record<PrimaryCategory, PrimaryCategoryBreakdown>;

export const getClaimReserveBreakdown = ServiceHandler<
  any,
  ClaimsReserveBreakdownRespData
>({
  url: '/api/claimsmgmt/v1/:claimId/reserve/totalV2',
});

export const updateClaimFinancials = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/reserve',
  method: 'PUT',
});

export const updateClaimFinancialsReserveBreakdown = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/reserveV2',
  method: 'PUT',
});

export const getClaimTotalPaid = ServiceHandler({
  url: 'api/claimsmgmt/v1/:claimId/invoice/totalpaid',
});

export type ClaimReservePaidBreakdownRespData = ClaimsReserveBreakdownRespData;

export const getClaimReservePaidBreakdownV2 = ServiceHandler<
  any,
  ClaimReservePaidBreakdownRespData
>({
  url: '/api/claimsmgmt/v1/:claimId/invoice/totalPaidV2',
});

// claim status apis
export const getCloseClaimReasons = ServiceHandler({
  url: '/api/claimsmgmt/v1/closeclaimreasons',
});

export const getDenyClaimReasons = ServiceHandler({
  url: '/api/claimsmgmt/v1/denyClaimReasons',
  method: 'GET',
});

export const closeClaim = ServiceHandler({
  url: '/api/claimsmgmt/v1/close/:claimId',
  method: 'PUT',
});

export const reopenClaim = ServiceHandler({
  url: '/api/claimsmgmt/v1/reopen/:claimId',
  method: 'PUT',
});

export const claimNoticeOnlyStatus = ServiceHandler({
  url: '/api/claimsmgmt/v1/noticeonly/:claimId',
  method: 'PUT',
});

// statuses & other dependencies
export const getClaimStatuses = ServiceHandler({
  url: '/api/claimsmgmt/v1/status',
});

export const getClaimSeverities = ServiceHandler({
  url: '/api/claimsmgmt/v1/severity',
});

export const getMyClaimTasks = ServiceHandler({
  url: '/api/claimsmgmt/v1/task/my',
  paramsSerializer: (params) =>
    standardQuerySerializer(
      applyQueryConfig(claimTasksServiceMappings, params)
    ),
});

// loss-runs
export const getLossRunsHistory = ServiceHandler({
  url: '/api/claimsmgmt/v1/search/lossrun',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(lossRunsParamsSerializer, params)),
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableTokenResponse,
  ],
});

export const downloadLossRunsDocument = ServiceHandler({
  url: '/api/docs/v1/lossrun/:accountId',
});

export const generateLossRunsDocument = ServiceHandler({
  url: '/api/policy/v1/lossrun',
  method: 'POST',
});

interface IClaimReserveChangelogResp {
  First_Party_Expense_Reserve_Change: object;
  First_Party_Loss_Reserve_Change: object;
  Third_Party_Loss_Reserve_Change: object;
  Third_Party_Expense_Reserve_Change: object;
  Loss_Adj_Reserve_Change: object;
}

const transformReservesChangelogResp = (data: IClaimReserveChangelogResp) => {
  return {
    firstPartyExpenseReserveChange: data.First_Party_Expense_Reserve_Change,
    firstPartyLossReserveChange: data.First_Party_Loss_Reserve_Change,
    thirdPartyLossReserveChange: data.Third_Party_Loss_Reserve_Change,
    thirdPartyExpenseReserveChange: data.Third_Party_Expense_Reserve_Change,
    lossAdjReserveChange: data.Loss_Adj_Reserve_Change,
  };
};

export const getClaimReserveChangelog = ServiceHandler({
  url: '/api/claimsmgmt/v1/:claimId/reserve',
  transformResponse: [
    responseStringTransformer,
    transformReservesChangelogResp,
  ],
});

export interface ClaimUpdateCloseDatePayload {
  closeDate: string;
  claimId: string;
}

export const claimUpdateCloseDate = ServiceHandler<
  ClaimUpdateCloseDatePayload,
  unknown
>({
  url: '/api/claimsmgmt/v1/update/close-date',
  method: 'PUT',
});

export interface GetClaimsPaidInvoicesGraphResponse {
  hits: {
    total: {
      value: number;
    };
  };
  aggregations: {
    'filter#time range': {
      doc_count: number;
      'date_histogram#Event distribution by time': {
        buckets: {
          key_as_string: string;
          key: number;
          doc_count: number;
          'sum#amount': {
            value: number;
          };
        }[];
      };
    };
  };
}

export const CLAIMS_PAID_INVOICES_GRAPH = 'CLAIMS_PAID_INVOICES_GRAPH';

export const getClaimsPaidInvoicesGraph = ServiceHandler<
  unknown,
  GetClaimsPaidInvoicesGraphResponse
>({
  url: 'api/claimsmgmt/v1/paid-invoices/graph',
  paramsSerializer: (params) =>
    standardQuerySerializer(claimsParamSerializer(params)),
});

export interface GetClaimsReserveGraphResponse {
  hits: {
    total: {
      value: number;
    };
  };
  aggregations: {
    'filter#time range': {
      doc_count: number;
      'date_histogram#Event distribution by time': {
        buckets: {
          key_as_string: string;
          key: number;
          doc_count: number;
          'sum#LossAdjustmentReserveChange': {
            value: number;
          };
          'sum#TotalReserveChange': {
            value: number;
          };
        }[];
      };
    };
  };
}

export const CLAIMS_RESERVES_GRAPH = 'CLAIMS_RESERVES_GRAPH';

export const getClaimsReserveGraph = ServiceHandler<
  unknown,
  GetClaimsReserveGraphResponse
>({
  url: '/api/claimsmgmt/v1/reserve/graph',
  paramsSerializer: (params) =>
    standardQuerySerializer(claimsParamSerializer(params)),
});

export const getClaimsReserves = ServiceHandler<
  unknown,
  GetClaimsReservesResponse
>({
  url: '/api/claimsmgmt/v1/search/reserves',
  paramsSerializer: (params) =>
    standardQuerySerializer(claimsReservesParamSerializer(params)),
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableTokenResponse,
  ],
});

export const getClaimsReserveHistory = ServiceHandler({
  url: '/api/claimsmgmt/v1/search/reservesV2',
  paramsSerializer: (params) =>
    standardQuerySerializer(claimsReservesParamSerializer(params)),
});
