import { useSnackbar } from 'notistack';
import _ from 'lodash';
import Moment from 'moment';
import type { Policy } from '../../../accounts/summary/types/policy.types';
import {
  downloadPolicyDocuments,
  downloadReceipt,
  downloadInvoice,
  downloadPaymentPlan,
} from '../../../../policies/PolicyService';
import { downloadQuoteDocuments } from '../../../../inbox/QuotesService';
import { toggleModalDirect } from '../../../../utils/storeUtils';
import { Modal } from '../../../../components/modals/v2/helpers/v2.modal.helpers';
import { downloadDocument } from '../../../../utils/documents.utils';

export const useAgencyPoliciesOptionHandlers = (data: Policy) => {
  const { enqueueSnackbar } = useSnackbar();

  const accountId = _.get(data, 'accountId');
  const policyId = _.get(data, 'id');
  const policyNumber = _.get(data, 'policyNumber', '');
  const name = _.get(data, 'companyName');
  const created = Moment(_.get(data, 'created')).unix();
  const boundOn = _.get(data, 'boundOn');
  const isEndorsement = _.get(data, 'isEndorsement', false);
  const quoteId = _.get(data, 'quoteId');
  const companyName = _.get(data, 'companyName');

  const handleDownloadDocs = () => {
    downloadDocument({
      asyncDownload: () =>
        downloadPolicyDocuments(accountId, policyId, created, isEndorsement),
      blobFileParams: {
        docName: `${name}-${policyNumber}-Cowbell-Policy`,
        docType: '.pdf',
      },
      enqueueSnackbar,
    });
  };

  const handleDownloadReceipt = () => {
    downloadDocument({
      asyncDownload: () =>
        downloadReceipt(accountId, policyId, created, isEndorsement),
      blobFileParams: {
        docName: `${policyNumber}-Cowbell-Receipt`,
        docType: '.pdf',
      },
      enqueueSnackbar,
    });
  };

  const handleDownloadInvoice = () => {
    downloadDocument({
      asyncDownload: () =>
        downloadInvoice(accountId, policyId, created, true, isEndorsement),
      blobFileParams: {
        docName: `${policyNumber}-Cowbell-Invoice`,
        docType: '.pdf',
      },
      enqueueSnackbar,
    });
  };

  const handleDownloadPlan = () => {
    downloadDocument({
      asyncDownload: () =>
        downloadPaymentPlan(accountId, policyId, created, isEndorsement),
      blobFileParams: {
        docName: `${policyNumber}-Cowbell-FinancingAgreement`,
        docType: '.pdf',
      },
      enqueueSnackbar,
    });
  };

  const handleDownloadApplication = () => {
    downloadDocument({
      asyncDownload: () =>
        downloadQuoteDocuments(
          accountId,
          quoteId,
          Moment(boundOn).unix(),
          isEndorsement
        ),
      blobFileParams: {
        docName: `${companyName}-${policyNumber}-Cowbell-Application`,
        docType: '.pdf',
      },
      enqueueSnackbar,
    });
  };

  const downloadEmailCertificate = () => {
    toggleModalDirect('DownloadEmailCertificate', data, {
      title: 'Generate Policy Certificate',
      maxWidth: 'md',
      fullWidth: true,
    });
  };

  const enterDeleteModal = () => {
    Modal.show('DeletePolicy', {
      data,
      config: { title: `Delete Policy# ${_.get(data, 'policyNumber')}` },
    });
  };

  return {
    handleDownloadDocs,
    handleDownloadReceipt,
    handleDownloadInvoice,
    handleDownloadPlan,
    handleDownloadApplication,
    downloadEmailCertificate,
    enterDeleteModal,
  };
};
