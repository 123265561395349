import { trimAndAddAsterisk } from '../../utility/QueryUtility';
import { NoteTypeAliases } from './common';

export const aeNotesParamsSerializer = [
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        switch (orderBy) {
          default:
            return `${orderBy},${order}`;
        }
      }
      return null;
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) =>
      !search ? search : trimAndAddAsterisk(search),
  },
  { path: 'submissionId' },
  {
    paramKey: 'submissionNoteType',
    transform: ({ noteType }) => NoteTypeAliases[noteType],
  },
];
