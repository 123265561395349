import React from 'react';

// mui
import {
  Box,
  CircularProgress,
  styled,
  Typography,
  useTheme,
} from '@mui/material';

// components
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { Tabs } from '../../../../../components/tabs/v2/TabSubrouter';
import { OrganizationDetailsTab } from './organization-details/OrganizationDetailsTab';
import { PolicyInformationTab } from './policy-information/PolicyInformationTab';
import { CowbellFactorsTab } from './CowbellFactorsTab';

// utils
import { subroutes, tabs } from './policy-details.utils';
import { getAccountDetails, getAccountRiskDetails } from '../../../../../api';
import {
  formatForLatestPolicy,
  formatPolicy,
} from '../../../policies.services';
import { useStatusColors } from '../../../../quotes-policies/cards/policyholder/utils';

export const PolicyDetailsDrawer = ({
  policy = {},
  product,
  getPolicyStatus,
  fetchLatestPolicyByStatus = false,
}) => {
  const formattedPolicy = fetchLatestPolicyByStatus
    ? formatForLatestPolicy(policy, product)
    : formatPolicy(policy);

  const { palette } = useTheme();
  const [selectedTab, setSelectedTab] = React.useState(
    tabs.POLICY_INFORMATION_TAB
  );

  const { data: account } = useAccount({
    accountId: formattedPolicy.accountId,
  });
  const { data: accountRiskDetails, isLoading: areAccountRiskDetailsLoading } =
    useAccountRiskDetails({
      accountId: formattedPolicy.accountId,
    });

  const handleTabChange = (e, nextTab) => {
    setSelectedTab(nextTab);
  };

  if (areAccountRiskDetailsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="13rem"
        width="42rem"
      >
        <CircularProgress size="5rem" />
      </Box>
    );
  }

  return (
    <>
      <PolicyHeader
        policy={formattedPolicy}
        getPolicyStatus={getPolicyStatus}
      />
      <Tabs
        subroutes={subroutes}
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        tabProps={{
          selectedStyles: {
            backgroundColor: palette.background.default,
          },
        }}
      />
      <TabsRouter
        selectedTab={selectedTab}
        account={account}
        policy={formattedPolicy}
        product={product}
        accountRiskDetails={accountRiskDetails}
      />
    </>
  );
};

const TabsRouter = ({
  selectedTab,
  account,
  policy,
  product,
  accountRiskDetails,
}) => {
  const latestRisk = useLatestRiskToggle();
  switch (selectedTab) {
    case tabs.POLICY_INFORMATION_TAB:
      return <PolicyInformationTab policy={policy} product={product} />;

    case tabs.ORGANIZATION_DETAILS_TAB:
      return (
        <OrganizationDetailsTab
          policy={policy}
          account={account}
          latestRisk={latestRisk}
        />
      );
    case tabs.COWBELL_FACTORS_TAB:
      return (
        <CowbellFactorsTab
          policy={policy}
          accountRiskDetails={accountRiskDetails}
          latestRisk={latestRisk}
        />
      );
    default:
      return null;
  }
};

const useAccount = ({ accountId }) => {
  return useQuery(['account', accountId], () => {
    return getAccountDetails(accountId).then((res) => res.data);
  });
};

const useAccountRiskDetails = ({ accountId }) => {
  return useQuery(['account-risk-details', accountId], () => {
    return getAccountRiskDetails(accountId).then((res) => res.data);
  });
};

const useLatestRiskToggle = () => {
  const [latestRiskSelected, setLatestRiskSelected] = React.useState(false);

  const handleLatestRiskToggle = (event) => {
    setLatestRiskSelected(event.target.checked);
  };

  return {
    latestRiskSelected,
    handleLatestRiskToggle,
  };
};

const PolicyHeader = ({ policy, getPolicyStatus }) => {
  const status = getPolicyStatus(policy);
  const statusColor = useStatusColors(status);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt={1}
      mb={3}
    >
      <Box mt={1}>
        <DrawerHeaderText>
          Policy Number {policy.policyNumber}
          <DrawerHeaderSpan
            style={{ color: statusColor }}
          >{`[${status}]`}</DrawerHeaderSpan>
        </DrawerHeaderText>
      </Box>
      <Typography
        component={Link}
        to={`/admin/account/${policy.accountId}`}
        className="link-underlined--hover"
      >
        {policy.companyName}
      </Typography>
    </Box>
  );
};

const DrawerHeaderText = styled('h1')(({ theme: { palette, config } }) => ({
  color: palette.primary.main,
  fontWeight: config.weights.normal,
  margin: 0,
  fontSize: config.textSizes.paragon,
}));

const DrawerHeaderSpan = styled('span')(({ theme: { config } }) => ({
  color: 'green',
  fontSize: config.textSizes.greatPrimer,
  marginLeft: '0.75rem',
  textTransform: 'uppercase',
}));
