import React from 'react';

import { Dialog } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import CancelIcon from '@mui/icons-material/Cancel';

import { compose } from 'redux';
import { iconMappings } from '../../../console/_global/navigation/IconsMappings';

export const WizerVideoPlayer = compose(
  withStyles({
    cancelIcon: {
      right: '.5rem',
      top: '.5rem',
      fontSize: 25,
      position: 'sticky',
      float: 'right',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      borderRadius: '.25rem',
      color: 'white',
      '&:hover': { backgroundColor: '#1ab7ea', color: 'white' },
    },
  })
)(({ classes, ...props }) => {
  const dialogClasses = useDialogStyles();
  return (
    <>
      <Dialog {...props} classes={dialogClasses}>
        <iframe
          title="Wizer Video PLayer"
          src={props.data.link}
          width="900"
          height="594"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />

        <CancelIcon className={classes.cancelIcon} onClick={props.close} />
      </Dialog>
    </>
  );
});

const useDialogStyles = makeStyles(({ palette }) => ({
  paper: {
    variant: 'elevation',
    elevation: 0,
    background: 'transparent',
    hideBackdrop: true,
    boxShadow: 'none',
    overflowY: 'hidden',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
}));

export const WizerVideoPlayerConfig = {
  WizerVideoPlayer: {
    component: WizerVideoPlayer,
    config: {
      fullWidth: true,
      maxWidth: 'md',
      override: true,
      inheritTheme: 'dark',
      title: 'Testing modal title',
      icon: iconMappings.ACCOUNTS.iconImport,
    },
  },
};
