import { SvgIcon } from '@mui/material';

const SVG = ({ fill = '#005BC8' }) => {
  return (
    <SvgIcon
      style={{ width: '30px', height: '20px', fill }}
      viewBox="100 -10 310 163"
    >
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="310pt"
        height="163pt"
        viewBox="0 0 310 200"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,163.000000) scale(0.100000,-0.100000)"
          fill="#005BC8"
          stroke="none"
        >
          <path
            d="M754 1617 c-3 -8 -4 -252 -2 -543 l3 -529 23 -48 c28 -56 83 -110
149 -147 26 -14 175 -97 330 -183 155 -86 287 -157 292 -157 8 0 236 124 624
340 67 37 121 91 149 147 l23 48 0 540 0 540 -793 3 c-654 2 -793 0 -798 -11z
m1186 -327 l0 -90 -275 0 c-309 0 -325 -3 -344 -63 -13 -40 -4 -72 29 -102 20
-18 40 -20 229 -25 225 -5 233 -7 307 -73 55 -48 84 -117 84 -202 -1 -121 -63
-212 -174 -254 -53 -20 -76 -21 -341 -21 l-285 0 0 96 0 96 286 -4 286 -3 24
28 c36 42 33 97 -7 131 -30 26 -32 26 -208 26 -223 0 -262 10 -337 84 -54 55
-84 122 -84 191 0 114 89 232 200 266 14 4 157 8 318 8 l292 1 0 -90z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
export default SVG;
