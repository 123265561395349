import React from 'react';

import { DialogActions, DialogContent, styled } from '@mui/material';

import CbButton from '../../Buttons/CbButton';

export const MessageModal = ({ data, close }) => {
  return (
    <>
      <DialogContent>
        <Text>{data?.message}</Text>
      </DialogContent>
      <DialogActions>
        <CbButton onClick={close} styleName="ctaButton">
          Close
        </CbButton>
      </DialogActions>
    </>
  );
};

const Text = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.config.textSizes.greatPrimer,
}));

export const MessageModalConfig = {
  MessageModal: {
    component: MessageModal,
    config: {
      fullWidth: true,
      title: 'Alert',
    },
  },
};
