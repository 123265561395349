import { useSnackbar } from 'notistack';
import React from 'react';

const MAX_FILE_SIZE_MB = 50 * 1000000;
const defaultAcceptedFiles =
  'image/png, image/jpg, image/jpeg, application/pdf';

export default function useUploadZone({
  maxFiles = 3,
  maxSize = MAX_FILE_SIZE_MB,
  accept = defaultAcceptedFiles,
  multiple = false,
  docType = '',
} = {}) {
  const [isUploading, setIsUploading] = React.useState(false);
  const [percentageUploadedIndex, setpercentageUploadedIndex] = React.useState(
    {}
  );
  const [files, setFiles] = React.useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const percentageUploaded = React.useMemo(() => {
    const { total, ...uploadedPercentageOfFiles } = percentageUploadedIndex;
    if (!total || Object.keys(uploadedPercentageOfFiles).length === 0) {
      return 0;
    }

    const currentTotalUploadedPercentage = Object.values(
      uploadedPercentageOfFiles
    ).reduce((a, b) => a + b);
    return Math.round((currentTotalUploadedPercentage / total) * 100);
  }, [percentageUploadedIndex]);

  const hasPreview = React.useMemo(() => {
    return !!files;
  }, [files]);

  const onDropAccepted = React.useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    // eslint-disable-next-line
  }, []);

  const onDropRejected = React.useCallback((dropFiles) => {
    if (dropFiles && dropFiles.length > maxFiles) {
      enqueueSnackbar(`Please select no more than ${maxFiles} file(s)`, {
        variant: 'error',
      });
    } else if (dropFiles.some((dropFile) => !accept.includes(dropFile.type))) {
      enqueueSnackbar(`Please select only supported file types`, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line
  }, []);

  const onRemoveAllFiles = React.useCallback((event) => {
    event.stopPropagation();
    setFiles(null);
    // eslint-disable-next-line
  }, []);

  const onRemoveFile = React.useCallback(
    (fileName) => {
      if (files === null) return;
      const filteredFiles = files.filter((file) => {
        return file.name !== fileName;
      });
      setFiles(filteredFiles.length === 0 ? null : filteredFiles);

      // eslint-disable-next-line
    },
    [files]
  );

  const defaultOnFileUploadError = React.useCallback(
    ({ file }) => {
      enqueueSnackbar(`Failed to upload file ${file.name}`, {
        variant: 'error',
      });
    },
    // eslint-disable-next-line
    []
  );

  const handleUploads = React.useCallback(
    ({ asyncUploadFunc, onFileUploadError, onFileUploadSuccess }) => {
      if (!(files && files.length > 0))
        return Promise.reject(
          new Error('No files were selected to be uploaded')
        );

      setIsUploading(true);
      const uploadPromises = [];

      setpercentageUploadedIndex({ total: 100 * files.length });
      for (let i = 0; i < files.length; i += 1) {
        uploadPromises.push(
          asyncUploadFunc({
            file: files[i],
            axiosConfig: {
              onUploadProgress: (progressEvent) => {
                const localProgreess = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setpercentageUploadedIndex((prev) => {
                  return { ...prev, [files[i].name]: localProgreess };
                });
              },
            },
            docType,
          })
            .then((res) => {
              if (
                onFileUploadSuccess &&
                typeof onFileUploadSuccess === 'function'
              ) {
                onFileUploadSuccess(res);
              }

              return res;
            })
            .catch((error) => {
              if (
                onFileUploadError &&
                typeof onFileUploadError === 'function'
              ) {
                onFileUploadError({ error, file: files[i] });
              } else {
                defaultOnFileUploadError({ error, file: files[i] });
              }
            })
            .finally(() => {
              setIsUploading(false);
            })
        );
      }

      return Promise.all(uploadPromises)
        .catch(() => 1)
        .finally(() => {
          setIsUploading(false);
          setpercentageUploadedIndex({});
        });
    },
    // eslint-disable-next-line
    [files]
  );

  return {
    files,
    setFiles,
    maxFiles,
    maxSize,
    accept,
    multiple,
    hasPreview,
    onDropAccepted,
    onDropRejected,
    onRemoveAllFiles,
    onRemoveFile,
    handleUploads,
    isUploading,
    percentageUploaded,
    percentageUploadedIndex,
    inputProps: { name: 'file' },
  };
}
