import React from 'react';
import type { MouseEventHandler } from 'react';
import _ from 'lodash';

import { Box, Collapse, Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AnchorButton } from '../AgencyDashSecondaryActionsMenu';
import { useMenuState } from '../../../../../components/tables/cell-components/menu/Menu';
import { Show } from '../../../../../components/Show';
import { AgencyDashboardMenuItem as MenuItem } from '../../menu-options/AgencyDashboardMenuOptions.components';
import type { Policy } from '../../../../accounts/summary/types/policy.types';
import { useHandlers as useMoreAgencyPoliciesOptionsHandlers } from '../../../../../policies/options/AgencyPolicyOptions';
import { useJQuery } from '../../../../../utils/appUtils';
import { MidTermEndorsementOption } from '../../../../../policies/options/MidtermEndorsementOption';
import { AgencyPoliciesCommonMenuOptions } from './AgencyPoliciesCommonMenuOptions';

interface AgencyPoliciesMenuOptionsProps {
  policy: Policy;
}

export const AgencyPoliciesMenuOptions = ({
  policy,
}: AgencyPoliciesMenuOptionsProps) => {
  const classes = useStyles();
  const [isPaymentsExpanded, setIsPaymentsExpanded] = React.useState(true);

  const menuState = useMenuState();
  const menuOptionMoreHandlers = useMoreAgencyPoliciesOptionsHandlers(
    policy,
    _.noop,
    _.noop
  );
  const menuOptions = useAgencyPolicyOptions(policy);

  const handleClose: MouseEventHandler<HTMLLIElement | HTMLDivElement> = (
    event
  ) => {
    event.stopPropagation();
    menuState.handleClose();
  };

  const handlePaymentsClick: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    setIsPaymentsExpanded((value) => !value);
  };

  const { isLoaded: isJQueryLoaded } = useJQuery();

  return (
    <>
      <AnchorButton onClick={menuState.handleOpen} />
      <Menu
        id="more-options-menu"
        anchorEl={menuState.anchorEl}
        keepMounted
        open={menuState.open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <AgencyPoliciesCommonMenuOptions policy={policy} />

        <Show when={menuOptions.generateRenewalQuote.show}>
          <MenuItem onClick={menuOptionMoreHandlers.handleRenewals}>
            {menuOptions.generateRenewalQuote.name}
          </MenuItem>
        </Show>

        <Box className={classes.menuItem}>
          <MidTermEndorsementOption
            policy={policy as any}
            onClick={menuOptionMoreHandlers.handleMidtermEndorsement}
          >
            Midterm Endorsement
          </MidTermEndorsementOption>
        </Box>

        <Show when={menuOptions.payments.show}>
          <MenuItem onClick={handlePaymentsClick}>
            {menuOptions.payments.name}
            <Box ml={0.5}>
              {isPaymentsExpanded ? (
                <FontAwesomeIcon icon={light('chevron-up')} />
              ) : (
                <FontAwesomeIcon icon={light('chevron-down')} />
              )}
            </Box>
          </MenuItem>
          <Collapse
            in={isPaymentsExpanded}
            timeout="auto"
            unmountOnExit
            style={{ paddingLeft: '1rem' }}
          >
            <MenuItem
              onClick={() => menuOptionMoreHandlers.handleCharge('bankAccount')}
              disabled={!isJQueryLoaded}
            >
              {menuOptions.chargeBankAccount.name}
            </MenuItem>
            <MenuItem
              onClick={() => menuOptionMoreHandlers.handleCharge('creditCard')}
              disabled={!isJQueryLoaded}
            >
              {menuOptions.chargeCreditCard.name}
            </MenuItem>
          </Collapse>
        </Show>
      </Menu>
    </>
  );
};

const useAgencyPolicyOptions = (policy: Policy) => {
  const isActive = ['ACTIVE', 'IN FORCE', 'ISSUED', 'BOUND'].includes(
    policy.status
  );

  const canGenerateRenewalQuote =
    policy.isOpenForRenewal && !policy.isPrimePlus;

  const canShowPayments = isActive && !policy.isPaid;

  return {
    generateRenewalQuote: {
      name: 'Generate Renewal Quote',
      show: canGenerateRenewalQuote,
    },
    payments: {
      name: 'Payments',
      show: canShowPayments,
    },
    chargeBankAccount: {
      name: 'Charge Bank Account',
    },
    chargeCreditCard: {
      name: 'Charge Credit Card',
    },
  };
};

const useStyles = makeStyles((theme) => ({
  menuItem: {
    '&:hover': {
      background: theme.agencyDash.background.hover,
    },
  },
}));
