import React, { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import withRouter from '../hocs/withRouter';

class TiledChart extends PureComponent {
  render() {
    const { props } = this;
    const { value, classes, theme, show, disabled } = props;

    if (!show) {
      return null;
    }

    const primaryColrs = theme.palette.primary;
    let color = 'none';

    if (!disabled) {
      if (value > 7) {
        color = primaryColrs.green;
      } else if (value > 4) {
        color = primaryColrs.yellow;
      } else {
        color = primaryColrs.red;
      }
    }

    const bars = new Array(10).fill(0);

    const barTags = bars.map((val, index) => {
      return (
        <div
          key={`t-${index}`}
          className={classes.tile}
          style={{ backgroundColor: index < value ? color : primaryColrs.grey }}
        />
      );
    });

    return <div className={classes.chartContainer}>{barTags}</div>;
  }
}
const styles = ({ palette, config }) => ({
  chartContainer: {
    padding: 4,
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',

    '& div': {
      width: '7%',
      borderRadius: 7.5,
      backgroundColor: '#9192a4',
    },
  },
});

TiledChart.defaultProps = {
  show: true,
};

export default withRouter(withStyles(styles, { withTheme: true })(TiledChart));
