import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import _ from 'lodash';
import { DialogActions, DialogContent, Grid } from '@mui/material';
// components
import { useSnackbar } from 'notistack';
import { withFormController } from '../../../components/hocs/withFormController';
import { SimpleSelect } from '../../../components/inputs/selects/SimpleSelect';
import CBButton from '../../../components/Buttons/CbButton';
import { withShowable } from '../../_global/lib/withShowable';
import {
  fetchBds,
  fetchTams,
  getAccountExecutives,
  getAllUnderWriters,
} from '../../../api/UserService';
import { delayedEvent } from '../../../utils/eventUtils';
import { updateTAMBDSTerritory } from '../sales.service';

const Select = withFormController(SimpleSelect);
const MuiGrid = withShowable(Grid);

export const UpdateTamBdsTerritory = ({ ...props }) => {
  const [tamOptions, setTams] = useState([]);
  const [bdsOptions, setBds] = useState([]);
  const [aeOptions, setAE] = useState([]);
  const [uwOptions, setUW] = useState([]);

  const defaultValues = {
    tamEmail: props.data.tamEmail || '',
    bdsEmail: props.data.bdsEmail || '',
    aeEmail: props.data.aeEmail || '',
    uwEmail: props.data.uwEmail || '',
    renewalUwEmail: props.data.renewalUwEmail || '',
  };

  // defaultValues
  const { handleSubmit, ...methods } = useForm({ defaultValues });
  const { enqueueSnackbar } = useSnackbar();

  const {
    formState: { isSubmitting, dirty },
  } = methods;

  function onSubmit({ ...formData }) {
    return updateTAMBDSTerritory(
      { id: props.data.id },
      {
        bdsEmail: formData.bdsEmail,
        tamEmail: formData.tamEmail,
        aeEmail: formData.aeEmail,
        uwEmail: formData.uwEmail,
        renewalUwEmail: formData.renewalUwEmail,
      }
    )
      .then(() => {
        enqueueSnackbar(`Updated Successfully!`, { variant: 'success' });
        delayedEvent('table-refetch', 2000, 'SalesTerritories');
        props.close();
      })
      .catch((error) => {
        enqueueSnackbar(
          _.get(error, 'response.data', 'Unable to update this tettitory'),
          { variant: 'error' }
        );
      });
  }

  React.useEffect(() => {
    Promise.all([
      fetchTams(),
      fetchBds(),
      getAccountExecutives(),
      getAllUnderWriters(),
    ])
      .then((responses) => {
        const tamArray = _.get(responses[0], 'data.content', []).map(
          ({ email }) => ({
            label: email,
            value: email,
          })
        );
        setTams(tamArray);

        const bdsArray = _.get(responses[1], 'data.content', []).map(
          ({ email }) => ({
            label: email,
            value: email,
          })
        );
        setBds(bdsArray);

        const aeArray = _.get(responses[2], 'data.content', []).map(
          ({ email }) => ({
            label: email,
            value: email,
          })
        );
        setAE(aeArray);

        const uwArray = _.get(responses[3], 'data.content', []).map(
          ({ email }) => ({
            label: email,
            value: email,
          })
        );
        setUW(uwArray);
      })
      .catch((error) => {
        enqueueSnackbar(
          _.get(error, 'response.data', 'Server Error, please try again'),
          { variant: 'error' }
        );
      });
  }, [enqueueSnackbar]);

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingTop: 0, paddingBottom: '1.7rem' }}>
          <MuiGrid container spacing={2} alignItems="center">
            <MuiGrid item md={6}>
              <Select
                label="Update TSM"
                name="tamEmail"
                renderValue={renderValue}
                options={tamOptions}
                displayEmpty
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <Select
                label="Update BDS"
                name="bdsEmail"
                renderValue={renderValue}
                options={bdsOptions}
                displayEmpty
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <Select
                label="Update Account Executive"
                name="aeEmail"
                renderValue={renderValue}
                options={aeOptions}
                displayEmpty
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <Select
                label="Update UW"
                name="uwEmail"
                renderValue={renderValue}
                options={uwOptions}
                displayEmpty
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <Select
                label="Update Renewal UW"
                name="renewalUwEmail"
                renderValue={renderValue}
                options={uwOptions}
                displayEmpty
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <CBButton size="medium" styleName="cancel" onClick={props.close}>
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            styleName="ctaButton"
            size="medium"
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            Update
          </CBButton>
        </DialogActions>
      </form>
    </FormContext>
  );
};

function renderValue(value) {
  return value || 'Select...';
}

export const UpdateTamBdsTerritoryConfig = {
  UpdateTamBdsTerritory: {
    component: UpdateTamBdsTerritory,
    config: {
      fullWidth: true,
      maxWidth: 'md',
      title: 'Update TSM/BDS',
    },
  },
};
