import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// mui
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
// helpers
import moment from 'moment';
// components
import CbButton from '../../Buttons/CbButton';
import { TextFieldBase } from '../../inputs/TextFieldBase';
// hocs
import { withFormController } from '../../hocs/forms';
// utils
import { moneyFormat2Decimals } from '../../../utils/appUtils';
import { Allow } from '../../auth/Allow';
import { InputLabelBase } from '../../inputs/InputLabelBase';
import { useScopes } from '../../hooks/useScopes';
import { determineIfAllowedScopes } from '../../../utils/next/auth.utils';

const DateField = withFormController(TextFieldBase);

/**
 * Returns a modal with a message and two buttons - confirm and cancel.
 * @param {Object} data - Contains custom info that will be used to render the modal.
 * @param {Function} props.onConfirm - Function that will be fired when user clicks on the confirm button.
 */
export const ClaimsPayInvoice = ({ data = {}, close }) => {
  const { invoiceData } = data;
  const { invoiceNumber, amount, vendorName } = invoiceData;

  const onSubmit = (formValues) => {
    const formattedPaymentDate = `${moment(formValues.paymentDate).format(
      'YYYY-MM-DD'
    )}T${moment().format('HH:mm:ss.SSSZ')}`;

    if (typeof data.onConfirm === 'function') {
      data.onConfirm({
        paymentDate: formattedPaymentDate,
      });
    }
    close();
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
    ...methods
  } = useForm({
    defaultValues: {
      paymentDate: moment().format('YYYY-MM-DD'),
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingTop: '3.75rem' }}>
          <Typography className="text-center">
            Are you sure you want to pay invoice{' '}
            <Typography color="primary" component="span">
              {invoiceNumber}
            </Typography>{' '}
            from {vendorName} for {moneyFormat2Decimals(amount)}?
          </Typography>
          <Allow allow={['root:root', 'payment:release']}>
            <Box>
              <InputLabelBase indent={false}>Payment Date</InputLabelBase>
              <DateField type="date" name="paymentDate" />
            </Box>
          </Allow>
        </DialogContent>

        <DialogActions>
          <CbButton onClick={close} styleName="cancel" buttonText="Cancel" />

          <CbButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="Confirm"
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const ClaimsPayInvoiceConfig = {
  ClaimsPayInvoice: {
    component: ClaimsPayInvoice,
    // all mui related config
    config: {
      fullWidth: true,
      title: 'Simple Confirm Modal',
    },
  },
};
