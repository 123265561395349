import React from 'react';
import SimpleTooltip from '../SimpleTooltip';
import TootTipIcon from '../../_assets/svg/Tooltip.svg';
import CheckBoxBase from './Checkbox';

export const TooltipCheckbox = ({
  label,
  formLabelProps,
  showRedTooltip,
  tooltip,
  errorTooltip,
  ...props
}) => {
  return (
    <>
      <CheckBoxBase
        label={
          tooltip ? (
            <SimpleTooltip
              title={tooltip}
              placement="right-start"
              arrow
              style={{ cursor: 'default' }}
            >
              {label}
            </SimpleTooltip>
          ) : (
            label
          )
        }
        {...props}
        formLabelProps={formLabelProps}
        style={{ padding: '4px 6px' }}
      />

      {showRedTooltip && (
        <SimpleTooltip
          title={errorTooltip}
          style={{ marginTop: '0.3rem', marginLeft: '-0.8rem' }}
        >
          <TootTipIcon fill="#f53d00" />
        </SimpleTooltip>
      )}
    </>
  );
};

export default TooltipCheckbox;
