import * as Yup from 'yup';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';

// mui
import {
  Grid,
  Box,
  Typography,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { useSnackbar } from 'notistack';
import CbButton from '../../Buttons/CbButton';
import { SimpleSelect } from '../../inputs';
import { withShowable } from '../../../console/_global/lib/withShowable';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { InputLabelBase } from '../../inputs/InputLabelBase';

// utils
import { withFormController } from '../../hocs/forms';
import {
  ColumnNameAliases,
  getCompetitorsAsOptions,
  getLostReasonsAsOptions,
} from '../../../console/submissions/admin/listings/helpers/renewal-submissions.utils';
import { sleep } from '../../../utils/appUtils';
import { COWBELL_SUBMISSIONS } from '../../../console/submissions/admin/listings/helpers/renewal-submissions.constants';
import { NoteTypes } from '../../notes/notes.utils';

// apis
import {
  createAENote,
  updateRenewalSubmissionListing,
} from '../../../api/submissions/renewal-submissions.api';
import { useActor } from '../../hooks/useActor';
import LanguageCurrencyFieldBase from '../../inputs/LanguageCurrencyField';

const TextField = withFormController(TextFieldBase);
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);
const Select = withFormController(SimpleSelect);

const validationSchema = Yup.object().shape({
  lostReason: Yup.string().required().label('Lost Reason'),
  notes: Yup.string().required().label('Notes'),
  premium: Yup.number().fromCurrency().label('Premium'),
});

export const RenewalSubmissionLostReason = ({ data = {}, ...props }) => {
  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      lostReason: data.lostReason,
      competitor: data.lostReasonDetails?.competitor,
      premium: data.lostReasonDetails?.premium,
      // The Notes field will not be pre-populated on purpose. This modal should not be used to edit existing notes.
    },
    resolver: yupResolver(validationSchema),
  });
  const queryClient = useQueryClient();
  const actor = useActor();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data: lostReasonOptions, isLoading: areLostReasonOptionsLoading } =
    useQuery({
      queryKey: ['select-options', 'lostReason'],
      queryFn: getLostReasonsAsOptions,
    });

  const { data: competitorOptions, isLoading: areCompetitorOptionsLoading } =
    useQuery({
      queryKey: ['select-options', 'competitors'],
      queryFn: getCompetitorsAsOptions,
    });

  const onSubmit = (values) => {
    const payload = {
      lostReason: values.lostReason,
      lostReasonDetails: {
        notes: values.notes,
        competitor: values.competitor,
        premium: values.premium,
      },
    };

    return updateRenewalSubmissionListing(data.id, {
      data: payload,
    })
      .then(() => {
        return createAENote({
          data: {
            submissionId: data.id,
            title: 'Record Updated',
            noteType: NoteTypes.ACTIVITY_LOG,
            noteText: `"${ColumnNameAliases.lostReason}" changed ${
              data.lostReason ? ` from "${data.lostReason}"` : ''
            } to "${values.lostReason}" by ${actor.fullName ?? ''} \n \n ${
              values.notes
            }`,
          },
        }).then(async () => {
          await sleep(1500);
          await queryClient.invalidateQueries([COWBELL_SUBMISSIONS]);
          queryClient.invalidateQueries(['ae-notes', data.id]);
          enqueueSnackbar('Lost Reason has been updated.', {
            variant: 'success',
          });
          props.close();
        });
      })
      .catch(() => {
        enqueueSnackbar(
          'Something went wrong while updating the Lost Reason. Please try again',
          { variant: 'error' }
        );
      });
  };

  const handleCancel = () => {
    props.close();
  };

  const lostReason = methods.watch('lostReason');
  const shouldShowCompetitorDetails = lostReason
    ? lostReason.startsWith('Competitor')
    : false;

  if (areLostReasonOptionsLoading || areCompetitorOptionsLoading) {
    return (
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </DialogContent>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2rem 4rem' }}>
          <Box display="flex" justifyContent="center">
            <Typography color="primary">
              Change status for{' '}
              <strong>
                {data.policyNumber}, {data.companyName}
              </strong>{' '}
              to 'Lost'
            </Typography>
          </Box>
          <Grid container xs={12} spacing={2} style={{ marginTop: '2rem' }}>
            <Grid item xs={6}>
              <InputLabelBase indent required>
                Lost Reason
              </InputLabelBase>
              <Select name="lostReason" options={lostReasonOptions} />
              <ErrorMessage
                name="lostReason"
                render={({ message }) => (
                  <span className={classes.errorText}>{message}</span>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabelBase indent required>
                Note
              </InputLabelBase>
              <TextField
                name="notes"
                placeholder="Type something to remember..."
                className={classes.textfield}
              />
            </Grid>
            <CompetitorDetails
              show={shouldShowCompetitorDetails}
              competitorOptions={competitorOptions}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton
            onClick={handleCancel}
            styleName="cancel"
            disabled={methods.formState.isSubmitting}
          >
            Cancel
          </CbButton>
          <CbButton
            type="submit"
            styleName="ctaButton"
            loading={methods.formState.isSubmitting}
            disabled={methods.formState.isSubmitting}
          >
            Execute
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const CompetitorDetails = withShowable(({ competitorOptions }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={6}>
        <InputLabelBase indent>Competitor</InputLabelBase>
        <Select name="competitor" options={competitorOptions} />
      </Grid>
      <Grid item xs={6}>
        <InputLabelBase indent>Premium</InputLabelBase>
        <LanguageCurrencyField name="premium" className={classes.textfield} />
      </Grid>
    </>
  );
});

const useStyles = makeStyles(({ palette }) => ({
  textfield: {
    '& input': {
      backgroundColor: 'transparent',
    },
  },
  errorText: {
    color: palette.text.text9,
  },
}));

export const RenewalSubmissionLostReasonConfig = {
  RenewalSubmissionLostReason: {
    component: RenewalSubmissionLostReason,
    // all mui related config
    config: {
      maxWidth: 'md',
      fullWidth: true,
      title: 'Renewal Status - Lost',
    },
  },
};
