import React from 'react';
import { SvgIcon } from '@mui/material';

export default function BindQuoteIconDark({ fill = '#cacdd9', ...props }) {
  return (
    <SvgIcon viewBox="0 0 49 46" {...props}>
      <path
        id="Path_7889"
        data-name="Path 7889"
        d="M53.733,16.867A16.867,16.867,0,1,1,36.867,0,16.867,16.867,0,0,1,53.733,16.867"
        transform="translate(-4.666)"
        fill="#b1c2de"
      />
      <path
        id="Path_7890"
        data-name="Path 7890"
        d="M32.433,19H6.367L1,49.667H37.8Z"
        transform="translate(-0.232 -4.434)"
        fill="#465266"
      />
      <path
        id="Path_7891"
        data-name="Path 7891"
        d="M37.566,50.2H.766a.767.767,0,0,1-.755-.9L5.378,18.634A.767.767,0,0,1,6.133,18h20.94a.767.767,0,1,1,0,1.533H6.777l-5.1,29.133H36.8v-2.5a.767.767,0,1,1,1.533,0v3.264A.767.767,0,0,1,37.566,50.2Z"
        transform="translate(0 -4.199)"
        fill="#b1c2de"
      />
      <path
        id="Path_7892"
        data-name="Path 7892"
        d="M48.38,28.745a3.054,3.054,0,0,1-1.88-2.858V17.435c1.832-.6,3.067-1.708,3.067-2.985,0-1.906-2.746-3.45-6.133-3.45S37.3,12.544,37.3,14.45c0,1.277,1.235,2.388,3.067,2.985v8.452a3.054,3.054,0,0,1-1.88,2.858C36.375,29.58,35,30.935,35,32.467v4.6c0,2.541,3.776,4.6,8.433,4.6s8.433-2.059,8.433-4.6v-4.6c0-1.532-1.375-2.887-3.487-3.721"
        transform="translate(-8.166 -2.566)"
        fill="#b1c2de"
      />
      <path
        id="Path_7893"
        data-name="Path 7893"
        d="M43.2,42.2c-5.159,0-9.2-2.357-9.2-5.367v-4.6a3.836,3.836,0,0,1,1.164-2.652A7.924,7.924,0,0,1,37.972,27.8a2.3,2.3,0,0,0,1.4-2.145V17.739a6.229,6.229,0,0,1-1.982-1.219,3.176,3.176,0,0,1-1.084-2.3,3.69,3.69,0,0,1,2.187-3.108,10.57,10.57,0,0,1,9.425,0A3.69,3.69,0,0,1,50.1,14.217a3.176,3.176,0,0,1-1.084,2.3,6.229,6.229,0,0,1-1.982,1.219v7.915a2.3,2.3,0,0,0,1.4,2.145,7.924,7.924,0,0,1,2.807,1.782A3.836,3.836,0,0,1,52.4,32.233v4.6C52.4,39.843,48.359,42.2,43.2,42.2Zm0-30.667a8.235,8.235,0,0,0-3.961.912c-.893.5-1.406,1.148-1.406,1.771,0,.868,1,1.754,2.537,2.256l.529.172v9.009a3.925,3.925,0,0,1-.63,2.141,3.671,3.671,0,0,1-1.735,1.43c-1.88.743-3,1.868-3,3.008v4.6c0,.922.755,1.862,2.071,2.58a11.926,11.926,0,0,0,5.6,1.254,11.926,11.926,0,0,0,5.6-1.254c1.316-.718,2.071-1.658,2.071-2.58v-4.6c0-1.141-1.122-2.265-3-3.008a3.671,3.671,0,0,1-1.735-1.43,3.925,3.925,0,0,1-.63-2.141V16.645l.529-.172c1.541-.5,2.537-1.387,2.537-2.256,0-.623-.512-1.269-1.406-1.771A8.235,8.235,0,0,0,43.2,11.533Z"
        transform="translate(-7.933 -2.334)"
        fill="#383f4d"
      />
      <path
        id="Path_7894"
        data-name="Path 7894"
        d="M38.242,43.255a.765.765,0,0,1-.283-.054,7.9,7.9,0,0,1-2.8-1.784A3.84,3.84,0,0,1,34,38.767a.767.767,0,1,1,1.533,0c0,1.14,1.118,2.265,2.992,3.009a.767.767,0,0,1-.283,1.479Z"
        transform="translate(-7.933 -8.867)"
        fill="#383f4d"
      />
      <path
        id="Path_7895"
        data-name="Path 7895"
        d="M45.261,44.133a16.083,16.083,0,0,1-1.746-.094.767.767,0,1,1,.167-1.524,14.544,14.544,0,0,0,1.579.085,11.926,11.926,0,0,0,5.6-1.254c1.316-.718,2.071-1.658,2.071-2.58a.767.767,0,0,1,1.533,0C54.461,41.776,50.419,44.133,45.261,44.133Z"
        transform="translate(-9.994 -8.867)"
        fill="#383f4d"
      />
      <path
        id="Path_7896"
        data-name="Path 7896"
        d="M42.727,20.184a.77.77,0,0,1-.155-.016,9.678,9.678,0,0,1-1.046-.276A.767.767,0,0,1,42,18.435a8.141,8.141,0,0,0,.88.232.767.767,0,0,1-.154,1.518Z"
        transform="translate(-9.566 -4.293)"
        fill="#383f4d"
      />
      <path
        id="Path_7897"
        data-name="Path 7897"
        d="M46.951,20.355a.767.767,0,0,1-.062-1.531,8.777,8.777,0,0,0,1.985-.39.767.767,0,1,1,.474,1.458,10.311,10.311,0,0,1-2.333.46Z"
        transform="translate(-10.777 -4.293)"
        fill="#383f4d"
      />
      <path
        id="Line_73"
        data-name="Line 73"
        d="M12.033.533H-.233A.767.767,0,0,1-1-.233.767.767,0,0,1-.233-1H12.033a.767.767,0,0,1,.767.767A.767.767,0,0,1,12.033.533Z"
        transform="translate(12.5 21.699)"
        fill="#b1c2de"
      />
      <path
        id="Line_74"
        data-name="Line 74"
        d="M13.567.533H-.233A.767.767,0,0,1-1-.233.767.767,0,0,1-.233-1h13.8a.767.767,0,0,1,.767.767A.767.767,0,0,1,13.567.533Z"
        transform="translate(11.733 24.768)"
        fill="#b1c2de"
      />
      <g id="Plus_Sign" data-name="Plus Sign" transform="translate(-128 -518)">
        <path
          id="Line_83"
          data-name="Line 83"
          d="M.533,4.6H-1V0H.533Z"
          transform="translate(140.5 524.9)"
          fill="#b1c2de"
        />
        <path
          id="Line_84"
          data-name="Line 84"
          d="M4.6.533H0V-1H4.6Z"
          transform="translate(137.967 527.434)"
          fill="#b1c2de"
        />
      </g>
      <path
        id="Line_83-2"
        data-name="Line 83"
        d="M.533,4.6H-1V0H.533Z"
        transform="translate(26.301 3.834)"
        fill="#465266"
      />
      <path
        id="Line_83-3"
        data-name="Line 83"
        d="M.533,4.6H-1V0H.533Z"
        transform="translate(45.467 18.4)"
        fill="#465266"
      />
      <path
        id="Line_84-2"
        data-name="Line 84"
        d="M4.6.533H0V-1H4.6Z"
        transform="translate(23.768 6.367)"
        fill="#465266"
      />
      <path
        id="Line_84-3"
        data-name="Line 84"
        d="M4.6.533H0V-1H4.6Z"
        transform="translate(42.934 20.934)"
        fill="#465266"
      />
    </SvgIcon>
  );
}
