import { Box } from '@mui/material';
import numeral from 'numeral';

import React from 'react';

interface SupplementStatProps {
  name: string;
  value: number;
  format: (value: number) => string;
  children?: React.ReactNode;
}

const SupplementStat = ({
  name,
  value,
  format,
  children,
}: SupplementStatProps) => {
  return (
    <Box lineHeight={1} fontSize="1rem">
      {children || `+ ${numeral(value).format(format(value))} ${name}`}
    </Box>
  );
};

export default SupplementStat;
