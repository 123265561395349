import _ from 'lodash';

const baseStates = ['NY', 'WA'];
export const calculateCoverageOptions = (
  {
    businessIncomeCoverage,
    ransomPaymentEndorsement,
    ransomIncomeLimit,
    hardwareReplCostEndorsement,
    hardwareReplCostSubLimit,
    telecomsFraudEndorsement,
    telecomsFraudSubLimit,
    postBreachRemediationEndorsement,
    postBreachRemediationSubLimit,
    computerFraudEndorsement,
    websiteMediaContentLiabilityEndorsement,
    websiteMediaContentLiabilitySubLimit,
    limit,
    socialEngLimit,
    additionalInsureds,
  },
  {
    onChange = null,
    register,
    onClick = null,
    disableSocial,
    disableRansom,
    disableRansomUnder250,
    socialEngEndorsement,
    socialReason,
    disableWebsite,
    coverageList = [],
    coverageMapping,
    state,
  } = {}
) => [
  {
    id: 'SECURITY_BREACH_EXPENSE',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.SECURITY_BREACH_EXPENSE.name,
    defaultChecked: true,
    percentage: 1,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(coverageMapping.SECURITY_BREACH_EXPENSE.ordinal),
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.SECURITY_BREACH_EXPENSE.tooltip,
  },
  {
    id: 'SECURITY_BREACH_LIABILITY',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.SECURITY_BREACH_LIABILITY.name,
    defaultChecked: true,
    percentage: 1,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(coverageMapping.SECURITY_BREACH_LIABILITY.ordinal),
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.SECURITY_BREACH_LIABILITY.tooltip,
  },
  {
    id: 'RESTORATION_OF_ELECTRONIC_DATA',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.RESTORATION_OF_ELECTRONIC_DATA.name,
    defaultChecked: true,
    percentage: 1,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(
        coverageMapping.RESTORATION_OF_ELECTRONIC_DATA.ordinal
      ),
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.RESTORATION_OF_ELECTRONIC_DATA.tooltip,
  },
  {
    id: 'EXTORTION_THREATS',
    label:
      !_.isEmpty(coverageMapping) && coverageMapping.EXTORTION_THREATS.name,
    defaultChecked: true,
    percentage: baseStates.includes(state) ? 50000 / limit : 0.05,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(coverageMapping.EXTORTION_THREATS.ordinal),
    tooltip:
      !_.isEmpty(coverageMapping) && coverageMapping.EXTORTION_THREATS.tooltip,
  },
  {
    id: 'PUBLIC_RELATIONS_EXPENSE',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.PUBLIC_RELATIONS_EXPENSE.name,
    defaultChecked: true,
    percentage: baseStates.includes(state) ? 50000 / limit : 0.05,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(coverageMapping.PUBLIC_RELATIONS_EXPENSE.ordinal),
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.PUBLIC_RELATIONS_EXPENSE.tooltip,
  },
  {
    id: 'BI_EXTRA_EXPENSE',
    label: !_.isEmpty(coverageMapping) && coverageMapping.BI_EXTRA_EXPENSE.name,
    defaultChecked: true,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(coverageMapping.BI_EXTRA_EXPENSE.ordinal),
    percentage: businessIncomeCoverage / limit,
    tooltip:
      !_.isEmpty(coverageMapping) && coverageMapping.BI_EXTRA_EXPENSE.tooltip,
  },
  {
    id: 'sublimit',
  },
  {
    id: 'COMPUTER_FUNDS_TRANSFER_FRAUD',
    name: 'computerFraudEndorsement',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.COMPUTER_FUNDS_TRANSFER_FRAUD.name,
    defaultChecked: !!computerFraudEndorsement,
    percentage: !computerFraudEndorsement ? 0 : 1,
    onChange,
    checked: !!computerFraudEndorsement,
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.COMPUTER_FUNDS_TRANSFER_FRAUD.ordinal
      ),
    register,
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.COMPUTER_FUNDS_TRANSFER_FRAUD.tooltip,
  },
  {
    id: 'SOCIAL_ENGINEERING',
    label:
      !_.isEmpty(coverageMapping) && coverageMapping.SOCIAL_ENGINEERING.name,
    checked: socialEngEndorsement,
    defaultChecked: socialEngEndorsement,
    percentage: socialEngLimit / limit,
    onChange,
    onClick,
    modalName: 'SocialEngg',
    register,
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.SOCIAL_ENGINEERING.ordinal),
    showRedTooltip:
      disableSocial ||
      (!_.isEmpty(coverageMapping) &&
        !coverageList.includes(coverageMapping.SOCIAL_ENGINEERING.ordinal)),
    errorTooltip: disableSocial ? socialReason : 'State Not Supported',
    tooltip:
      !_.isEmpty(coverageMapping) && coverageMapping.SOCIAL_ENGINEERING.tooltip,
  },
  {
    id: 'socialLimit',
  },
  {
    id: 'socialDeductible',
  },
  {
    id: 'RANSOM_PAYMENTS',
    name: 'ransomPaymentEndorsement',
    label: !_.isEmpty(coverageMapping) && coverageMapping.RANSOM_PAYMENTS.name,
    defaultChecked: ransomPaymentEndorsement,
    percentage: ransomIncomeLimit / limit,
    onChange,
    register,
    disabled:
      (!_.isEmpty(coverageMapping) &&
        !coverageList.includes(coverageMapping.RANSOM_PAYMENTS.ordinal)) ||
      disableRansomUnder250,
    checked: ransomPaymentEndorsement,
    showRedTooltip:
      (!_.isEmpty(coverageMapping) &&
        !coverageList.includes(coverageMapping.RANSOM_PAYMENTS.ordinal)) ||
      disableRansomUnder250,
    // eslint-disable-next-line no-nested-ternary
    errorTooltip: disableRansom
      ? 'Not Available For Industry / State!'
      : disableRansomUnder250
      ? 'Not Eligible for Aggregate Limit Under 250K!'
      : '',
    tooltip:
      !_.isEmpty(coverageMapping) && coverageMapping.RANSOM_PAYMENTS.tooltip,
  },
  {
    id: 'ransomLimit',
  },
  {
    id: 'WEBSITE_MEDIA_CONTENT_LIABILITY',
    name: 'websiteMediaContentLiabilityEndorsement',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.WEBSITE_MEDIA_CONTENT_LIABILITY.name,
    defaultChecked: websiteMediaContentLiabilityEndorsement,
    percentage: websiteMediaContentLiabilitySubLimit / limit,
    onChange,
    register,
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.WEBSITE_MEDIA_CONTENT_LIABILITY.ordinal
      ),

    checked: websiteMediaContentLiabilityEndorsement,
    showRedTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.WEBSITE_MEDIA_CONTENT_LIABILITY.ordinal
      ),
    errorTooltip: disableWebsite ? 'Not Available For Industry / State!' : '',
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.WEBSITE_MEDIA_CONTENT_LIABILITY.tooltip,
  },
  {
    id: 'websiteLimit',
  },
  {
    id: 'HARDWARE_REPLACEMENT_COSTS',
    name: 'hardwareReplCostEndorsement',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.HARDWARE_REPLACEMENT_COSTS.name,
    defaultChecked: hardwareReplCostEndorsement,
    percentage: !hardwareReplCostEndorsement
      ? 0
      : hardwareReplCostSubLimit / limit,
    onChange,
    register,
    showRedTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.HARDWARE_REPLACEMENT_COSTS.ordinal
      ),
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.HARDWARE_REPLACEMENT_COSTS.ordinal
      ),
    checked: hardwareReplCostEndorsement,
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.HARDWARE_REPLACEMENT_COSTS.tooltip,
    errorTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.HARDWARE_REPLACEMENT_COSTS.ordinal
      ) &&
      'Not Available For Industry / State!',
  },
  {
    id: 'TELECOM_FRAUD',
    name: 'telecomsFraudEndorsement',
    label: !_.isEmpty(coverageMapping) && coverageMapping.TELECOM_FRAUD.name,
    defaultChecked: telecomsFraudEndorsement,
    percentage: !telecomsFraudEndorsement ? 0 : telecomsFraudSubLimit / limit,
    onChange,
    register,
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.TELECOM_FRAUD.ordinal),
    checked: telecomsFraudEndorsement,
    showRedTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.TELECOM_FRAUD.ordinal),
    tooltip:
      !_.isEmpty(coverageMapping) && coverageMapping.TELECOM_FRAUD.tooltip,
    errorTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.TELECOM_FRAUD.ordinal) &&
      'Not Available For Industry / State!',
  },
  {
    id: 'POST_BREACH_REMEDIATION',
    name: 'postBreachRemediationEndorsement',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.POST_BREACH_REMEDIATION.name,
    defaultChecked: postBreachRemediationEndorsement,
    percentage: !postBreachRemediationEndorsement
      ? 0
      : postBreachRemediationSubLimit / limit,
    onChange,
    register,
    showRedTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.POST_BREACH_REMEDIATION.ordinal),
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.POST_BREACH_REMEDIATION.ordinal),
    checked: postBreachRemediationEndorsement,
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.POST_BREACH_REMEDIATION.tooltip,
    errorTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.POST_BREACH_REMEDIATION.ordinal) &&
      'Not Available For Industry / State!',
  },
  {
    id: 'ADDITIONAL_INSURED',
    name: 'additionalInsureds',
    label: 'Additional Insured',
    register,
    onChange,
    checked: additionalInsureds,
    disabled: false,
  },
];

export const waitingPeriods = {
  6: '6 Hours',
  8: '8 Hours',
  12: '12 Hours',
  24: '24 Hours',
};

export const retroActivePeriods = {
  1: '1 Year',
  2: '2 Years',
  3: 'Full Prior Acts',
};
