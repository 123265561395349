import React from 'react';
import { Route } from 'react-router-dom';
import hoistNonReactStatics from 'hoist-non-react-statics';
import qs from 'qs';

let cache = {};
let cacheCount = 0;

export default function withRouter(Component) {
  const C = (props) => {
    const { wrappedComponentRef, ...rest } = props;

    return (
      <Route
        children={({ location, ...routeRest }) => {
          let query = {};

          if (location.search) {
            // pull out of cache if we can
            if (cache[location.search]) {
              query = cache[location.search];
            } else {
              query = qs.parse(location.search, {
                ignoreQueryPrefix: true,
                comma: true,
              });

              // place new query into cache
              cache[location.search] = query;
              cacheCount += 1;
            }

            // free up some memory
            if (cacheCount > 500) {
              cache = {};
            }
          }

          const modifiedProps = {
            ...routeRest,
            location: {
              ...location,
              query,
            },
            q: query,
          };

          return (
            <Component
              {...rest}
              {...modifiedProps}
              updateUrl={_updateUrl(query, routeRest.match, routeRest.history)}
              ref={wrappedComponentRef}
            />
          );
        }}
      />
    );
  };

  C.displayName = `withRouter(${Component.displayName || Component.name})`;
  C.wrappedComponent = Component;

  return hoistNonReactStatics(C, Component);
}

function _updateUrl(query, match, history) {
  return (update = {}, method = 'replace', pathnameOverride) => {
    const modifiedQuery = qs.stringify(
      {
        ...query,
        ...update,
      },
      { addQueryPrefix: true, encode: true }
    );

    history[method]({
      pathname: pathnameOverride || match.url.pathname,
      search: modifiedQuery,
    });
  };
}
