import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
} from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import * as Ramda from 'ramda';
// mui
import {
  Box,
  IconButton as MuiIconButton,
  useTheme,
  Snackbar,
  Slide,
  IconButton,
  Card,
  CircularProgress,
} from '@mui/material';
import { styled, makeStyles, withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
// helpers
import Moment from 'moment';
import _ from 'lodash';
// components
import { NoKnownLossLetterIndicator } from '../../../components/Reusable';
import { UrlDateRangePicker } from '../../../components/inputs/DateRangePicker';
import { withBackfill } from '../../../components/hocs/withBackfill';
import withRouter from '../../../components/hocs/withRouter';
import { FilterModal } from '../../../components/modals/FilterModal';
import { TableFilterChips } from '../../../components/tables/tableFilters/TableFilterChips';

import { UrlTable } from '../../../components/tables/tables-new/variations/url.table';
import { ColumnCustomizer } from '../../../components/tables/tables-new/base/column-customizer/column-customizer.table';
import { withCsvDownload } from '../../../components/tables/tables-new/hocs/withCsvDownload';
import { withSaveSettings } from '../../../components/tables/tables-new/hocs/withSaveSettings';
import { TableContext } from '../../../components/tables/tables-new/base/context.table';
import { ActionBar } from '../../../components/tables/tables-new/base/action-bar.table';

import SimpleTooltip from '../../../components/SimpleTooltip';
import FilterState from '../../../components/tables/tableFilters/FilterState';
import { withColumnValidation } from '../../../components/tables/tables-new/hocs/withColumnValidation';
import SearchBox from '../../../components/main/common/SearchBox';
import TableFilterSelect from '../../../components/tables/tableFilters/TableFilterSelect';
import { CreatedDateCell } from '../../../components/tables/tables-new/tableCellComponents';
// platform helpers
import { delayedEvent, PubSub } from '../../../utils/eventUtils';
import { getFee, prepColumnFilters } from '../../../utils/data.utils';
import {
  useJQuery,
  YesNoOptions,
  RenewalOptions,
  phoneFormatter,
  getBoolValueText,
} from '../../../utils/appUtils';
import {
  getShortDateString,
  getShortDateTimeFromUtc,
} from '../../../utils/csvUtils';
// actions
import {
  fetchBORPolicies,
  fetchPolicyStatuses,
} from '../../../policies/PolicyService';
// icons
import RefreshIcon from '../../../_assets/svg/Refresh';
// statics
import StateStatics from '../../_statics/states.statics.json';
import ProductStatics from '../../_statics/product.statics.json';
import { ClaimIndicator } from '../../../_assets/svg/claims/ClaimIndicator.svg';
import { AgencyPolicyOptions } from '../../../policies/options/AgencyPolicyOptions';
// reducers
import { selectGlobalTeam } from '../../../reducers/team.reducer';
import { ProductTypes } from '../../../types';
import { formatTableCurrencyByRegion } from '../../../utils/currency.utils';
import { PolicyStatus } from '../../_statics/policy.statics';
import { getIsUsPlatform, useGetPlatformRegion } from '../../../utils';
import { useCowbellTranslations } from '../../../i18n/translations';
import { AgentNameCell } from '../../policies/listing/cowbell/helpers';

const AGENCY_POLICIES_BACKEND_ID = 'AGENCY_POLICY';

const EnhancedUrlTable = compose(
  withRouter,
  withSaveSettings('agencyBorPolicies'),
  withColumnValidation({
    order: 'desc',
    orderBy: 'modified',
  })
)(UrlTable);

const styles = ({ config }) => ({
  options: {
    width: '30px',
    height: '25px',
    marginLeft: '18px',
    marginTop: '5px',
  },
  card: {
    padding: '0 1rem',
    marginBottom: '2rem',
  },
  link: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  status: {
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },
  close: {
    color: config.colors.darkGrey,
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit !important',
    },
  },
  message: {
    marginLeft: '10px',
    position: 'absolute',
    top: '22px',
  },
  progress: {
    zIndex: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  claimIndicatorParent: {
    '& tr': {
      position: 'relative',
    },
  },
  redDot: {
    fontSize: config.textSizes.primer,
    marginLeft: 5,
    width: '0.55rem',

    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const rqps = [
  'page',
  'size',
  'orderBy',
  'order',
  'search',
  'before',
  'after',
  'status',
];

const CsvDownloadButton = withCsvDownload('AgencyPolicies')((props) => {
  return (
    <MuiIconButton {...props}>
      <img src="images/Download.svg" alt="" className="table-ab-icon" />
    </MuiIconButton>
  );
});

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const useRowStyles = makeStyles(({ palette }) => ({
  root: {
    background: palette.tables.productRow,
    '&:hover': {
      background: palette.background.tableRowHover,
    },
  },
  open: {
    background: palette.background.tableRowHover,
    cursor: 'pointer',
  },
  endorse: {
    background: palette.tables.endorsement,
  },
}));

// ui

const AgencyBORPolicies = compose(
  withRouter,
  withCsvDownload('agencyBorPolicies'),
  withBackfill('uiSettings.agencyBorPolicies', {
    size: 25,
    order: 'desc',
    orderBy: 'modified',
    before: () => Moment().endOf('day').unix(),
    after: () => Moment().subtract(1, 'year').startOf('day').unix(),
  }),
  withStyles(styles)
)(({ classes, q, updateUrl, ...props }) => {
  const team = useSelector(selectGlobalTeam);
  const theme = useTheme();
  const rowStyles = useRowStyles();
  const [showCircular, setShowCircular] = React.useState(false);
  const { isLoaded } = useJQuery();
  const columns = useColumns();

  const filterColumns = React.useMemo(() => {
    return prepColumnFilters(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = useMemo(
    () => ({
      setShowCircular,
      isLoaded,
      classes,
      theme,
      updateUrl,
      styles: {
        row: (row, rowProps) => {
          if (rowProps.expanded) {
            return { root: rowStyles.open };
          }
          if (row.isEndorsement) {
            return { root: rowStyles.endorse };
          }
          if (row.product === ProductTypes.p250) return rowStyles;
        },
      },
      rowsPerPageOptions: [25, 50, 100],
    }),
    [classes, isLoaded, rowStyles, theme, updateUrl]
  );

  useLayoutEffect(() => {
    updateUrl({ teamIds: team.id });

    const sub = PubSub.subscribe('team-change', (teamData) => {
      updateUrl({ teamIds: teamData.id });
    });

    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showCircular && (
        <CircularProgress
          size="25rem"
          thickness="2.5"
          className={classes.progress}
        />
      )}
      <div className={classes.claimIndicatorParent}>
        <EnhancedUrlTable
          id="agencyBorPolicies"
          columns={columns}
          context={context}
          delegate={fetch}
          rqps={rqps}
        >
          <ActionBar
            render={() => (
              <>
                <Card className={classes.card}>
                  <Box
                    mt={2}
                    mb={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FilterState
                      id={AGENCY_POLICIES_BACKEND_ID}
                      storeId="agencyBorPolicies"
                      render={(filterProps, routeProps) => (
                        <>
                          <TableFilterSelect
                            filterProps={filterProps}
                            routeProps={routeProps}
                          />
                          <TableFilterChips
                            filterProps={filterProps}
                            routeProps={routeProps}
                          />
                        </>
                      )}
                    />

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      width="50%"
                    >
                      <SearchBox />
                      <UrlDateRangePicker
                        q={q}
                        maxDate={new Date()}
                        start={Moment().subtract(1, 'year').toDate()}
                        inlineLabel
                      />
                      <ColumnCustomizer />
                      <CsvDownloadButton />
                    </Box>
                  </Box>
                  <div id="portalOneContainer" />
                </Card>
              </>
            )}
          />
        </EnhancedUrlTable>
      </div>
      <FilterModal
        tableId={AGENCY_POLICIES_BACKEND_ID}
        config={filterColumns}
        q={q}
        history={props.history}
        location={props.location}
      />
    </>
  );
});

const SubscribedCell = ({ event, rowId, initialValue, path, children }) => {
  const [value, setValue] = useState(initialValue);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  useEffect(() => {
    const sub = PubSub.subscribe(event, (data) => {
      if (rowId === data.id && typeof data[path] !== 'undefined') {
        setValue(data[path]);
      } else if (data.id === undefined) {
        setRefresh(data);
        setOpen(data);
        setTransition(() => TransitionLeft);
      }
    });
    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  return <>{children(value, refresh, open, setOpen, transition)}</>;
};

export const useColumns = () => {
  const region = useGetPlatformRegion();
  const isUSPlatform = getIsUsPlatform(region);
  const { translations, translationKeys } = useCowbellTranslations([
    'Agency',
    'Agent',
  ]);

  return [
    {
      name: 'isRead',
      canToggle: false,
      sort: true,
      Cell: {
        C: ({ row }) => {
          const { classes } = useContext(TableContext);

          return (
            <SubscribedCell
              event="policy:table-row:read"
              rowId={row.id}
              path="isRead"
              initialValue={row.isRead}
            >
              {(newRead) => {
                if (!newRead) {
                  return (
                    <img
                      src="images/Red_Dot_1.svg"
                      alt=""
                      className={classes.redDot}
                    />
                  );
                }

                return null;
              }}
            </SubscribedCell>
          );
        },
      },
    },
    {
      name: 'id',
      label: 'ID',
      show: false,
    },
    {
      name: 'policyNumber',
      label: 'Policy Number',
      filterType: 'string',
      Cell: {
        C: ({ row }) => {
          const { theme } = useContext(TableContext);

          return (
            <>
              {row.claimId && (
                <>
                  <SimpleTooltip
                    title={`${_.get(row, 'claimStatus', '')} - Claim#  ${
                      row.claimNumber
                    }`}
                  >
                    <ClaimTag
                      color={
                        row.claimStatus === 'OPEN'
                          ? theme.config.cowbellAccent
                          : 'salmon'
                      }
                    />
                  </SimpleTooltip>
                </>
              )}
              {row.policyNumber}
            </>
          );
        },
      },
    },
    {
      name: 'premium',
      label: 'Premium',
      filterType: 'number',
      show: true,
      Cell: {
        C: Ramda.compose(
          formatTableCurrencyByRegion()('premium'),
          Ramda.prop('row')
        ),
      },
      csv: formatTableCurrencyByRegion()('premium'),
    },
    {
      name: 'brokerFee',
      label: 'Fee',
      filterType: 'number',
      show: true,
      sort: false,
      Cell: {
        C: ({ row }) => {
          return getFee(row);
        },
      },
      csv: (row) => {
        return getFee(row);
      },
    },
    {
      name: 'totalPremium',
      label: 'Total Premium',
      filterType: 'number',
      Head: {
        align: 'right',
      },
      Cell: {
        align: 'right',
        C: Ramda.compose(
          formatTableCurrencyByRegion()('totalPremium'),
          Ramda.prop('row')
        ),
      },
      csv: formatTableCurrencyByRegion()('totalPremium'),
    },
    {
      name: 'agentName',
      label: `${translations[translationKeys.agent]} Name`,
      Cell: {
        C: AgentNameCell,
        width: 180,
      },
    },
    {
      name: 'agentEmail',
      label: `${translations[translationKeys.agent]} Email`,
      show: false,
      filterType: 'string',
      Cell: {
        C: ({ row }) => row.agentEmail || '-',
      },
    },
    {
      name: 'companyName',
      label: 'Company Name',
      filterType: 'string',
    },
    {
      name: 'dbaOrTradestyle',
      label: 'DBA',
      filterType: 'string',
      show: false,
    },
    {
      name: 'product',
      label: 'Product',
      filterType: 'list',
      getListOptions: async () => ProductStatics.PRODUCT_OPTIONS,
      show: false,
    },
    ...(isUSPlatform
      ? [
          {
            name: 'state',
            label: 'Company State',
            sort: false,
            show: false,
            filterType: 'list',
            getListOptions: () =>
              new Promise((resolve) => resolve(StateStatics.full)),
            Cell: {
              C: ({ row }) => _.get(row, 'firmographicData.state', '-'),
            },
            csv: (row) => _.get(row, 'firmographicData.state', '-'),
          },
        ]
      : []),
    {
      name: 'customerEmail',
      label: 'Customer Email',
      show: false,
      filterType: 'string',
    },
    {
      name: 'customerFirstName',
      label: 'Customer Name',
      filterType: 'string',
      Cell: {
        C: ({ row }) => {
          return `${row.customerFirstName ? row.customerFirstName : ''} ${
            row.customerLastName ? row.customerLastName : ''
          }`;
        },
      },
      csv: (row) => {
        return `${row.customerFirstName ? row.customerFirstName : ''} ${
          row.customerLastName ? row.customerLastName : ''
        }`;
      },
    },
    {
      name: 'customerPhone',
      label: 'Customer Phone Number',
      show: false,
      filterType: 'string',
      Cell: ({ row }) => phoneFormatter(row.customerPhone, '-'),
    },
    {
      name: 'agencyId',
      label: `${translations.Agency} ID`,
      show: false,
      filterType: 'string',
    },
    {
      name: 'description',
      label: 'Description',
      show: false,
      Cell: {
        C: ({ row }) => {
          let desc = row.description || '-';
          desc = desc.length > 100 ? `${desc.substr(0, 98)} ...` : desc;
          return desc;
        },
      },
    },
    {
      name: 'isRenewal',
      label: 'Is Renewal?',
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(RenewalOptions)),
      show: false,
      Cell: {
        C: ({ row }) => (row.isRenewal ? 'Renewal' : 'New'),
      },
      csv: (row) => (row.isSurplus ? 'Renewal' : 'New'),
    },
    {
      name: 'isMigration',
      label: 'Is Migration?',
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(YesNoOptions)),
      show: false,
      Cell: {
        C: ({ row }) => getBoolValueText(row.isMigration),
      },
      csv: (row) => getBoolValueText(row.isMigration),
    },

    {
      name: 'isEndorsement',
      label: 'Is Endorsement?',
      show: false,
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(YesNoOptions)),
      Cell: {
        C: ({ row }) => getBoolValueText(row.isEndorsement),
      },
      csv: (row) => getBoolValueText(row.isEndorsement),
    },
    {
      name: 'isBOR',
      label: 'Lost Account?',
      show: false,
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(YesNoOptions)),
      Cell: {
        C: ({ row }) => getBoolValueText(row.isBOR),
      },
      csv: (row) => getBoolValueText(row.isBOR),
    },
    {
      name: 'isPrimePlus',
      label: 'Is Excess?',
      show: false,
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(YesNoOptions)),
      Cell: {
        C: ({ row }) => getBoolValueText(row.isPrimePlus),
      },
      csv: (row) => getBoolValueText(row.isPrimePlus),
    },
    {
      name: 'effectiveDate',
      label: 'Policy Effective',
      filterType: 'date',
      show: false,
      Cell: {
        C: ({ row }) => {
          const invalidStatus = ['DELETED'];

          const dt = _.get(row, 'effectiveDate');
          if (invalidStatus.includes(row.customerStatus)) {
            return '-';
          }

          return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
        },
      },

      csv: (row) => {
        const invalidStatus = ['DELETED'];

        const dt = _.get(row, 'effectiveDate');
        if (invalidStatus.includes(row.customerStatus)) {
          return '-';
        }
        return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
      },
    },
    {
      name: 'endDate',
      label: 'Policy Expiration',
      filterType: 'date',
      show: false,
      Cell: {
        C: ({ row }) => {
          const invalidStatus = ['DELETED'];

          const dt = _.get(row, 'endDate');
          if (invalidStatus.includes(row.customerStatus)) {
            return '-';
          }

          return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
        },
      },

      csv: (row) => {
        const invalidStatus = ['DELETED'];

        const dt = _.get(row, 'endDate');
        if (invalidStatus.includes(row.customerStatus)) {
          return '-';
        }
        return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
      },
    },
    {
      name: 'created',
      label: 'Created',
      filterType: 'date',
      show: false,
      Cell: {
        C: ({ row }) => CreatedDateCell(row),
      },
      csv: (row) => getShortDateString(row.created),
    },
    {
      name: 'modified',
      label: 'Modified',
      filterType: 'date',
      Cell: {
        C: ({ row }) => {
          return row.modified ? Moment(row.modified).fromNow() : '-';
        },
      },
      csv: (row) => getShortDateString(row.modified),
    },
    {
      name: 'quoteNumber',
      label: 'Quote Number',
      filterType: 'string',
      show: false,
    },

    {
      name: 'status',
      label: 'Status',
      filterType: 'list',
      getListOptions: () =>
        fetchPolicyStatuses()
          .then(({ data }) => {
            if (data.length) {
              return data.map((el) => ({
                label: el,
                value: el,
              }));
            }
            return [];
          })
          .catch(() => {
            return [];
          }),
      Cell: {
        C: ({ row }) => {
          const { status, noKnownLossLetterReq } = row;
          const { classes } = useContext(TableContext);

          return (
            <div className={classes.status}>
              {status}
              <NoKnownLossLetterIndicator status={noKnownLossLetterReq} />
            </div>
          );
        },
      },
    },
    {
      name: 'options',
      canToggle: false,
      sort: false,
      Cell: {
        C: ({ row }) => {
          const { setShowCircular, isLoaded } = useContext(TableContext);
          const restrictedStatus = [
            PolicyStatus.VOID_STATUS,
            PolicyStatus.CANCELLED_STATUS,
            PolicyStatus.REQUESTED_STATUS,
            PolicyStatus.CANCELLED_PENDING_STATUS,
            PolicyStatus.CANCELLED_IN_REINSTATEMENT_PERIOD,
            PolicyStatus.FLAT_CANCELLED_STATUS,
            PolicyStatus.INVALID_STATUS,
          ];
          return (
            !restrictedStatus.includes(row.status) && (
              <AgencyPolicyOptions
                data={row}
                handleTableRefetch={makeRefreshCall}
                setShowCircular={setShowCircular}
                isJQueryLoaded={isLoaded}
              />
            )
          );
        },
      },
    },

    {
      name: 'actions',
      canToggle: false,
      sort: false,
      Head: () => {
        const { classes, theme } = useContext(TableContext);

        return (
          <SubscribedCell event="policy-refresh:show">
            {(refresh, open, setOpen, transition) => {
              function onClose() {
                setOpen(false);
              }
              return (
                <>
                  <IconButton onClick={makeRefreshCall}>
                    <RefreshIcon
                      fill={
                        !refresh
                          ? theme.palette.text.primary
                          : theme.palette.snackBar.primary
                      }
                    />
                  </IconButton>
                  <Snackbar
                    open={open}
                    autoHideDuration={5000}
                    onClose={onClose}
                    TransitionComponent={transition}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    message={
                      <IconButton
                        style={{ textDecoration: 'underline' }}
                        size="large"
                        onClick={makeRefreshCall}
                      >
                        <RefreshIcon fill="#ffffff" />
                        <span className={classes.message}>Table Updated</span>
                      </IconButton>
                    }
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="large"
                        className={classes.close}
                        onClick={onClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  />
                </>
              );
            }}
          </SubscribedCell>
        );
      },
    },
  ];
};

const ClaimTag = styled(ClaimIndicator)({
  position: 'absolute',
  top: 0,
});

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, 'agencyBorPolicies');
  PubSub.publish('policy-refresh:show', false);
}

function fetch(q) {
  return fetchBORPolicies(q).then(({ data }) => ({
    data: data.content,
    count: data.totalElements,
  }));
}

export default AgencyBORPolicies;
