import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import {
  DialogContent,
  DialogActions,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { TextField as TextFieldBase } from '../../../../components/inputs';
import CbButton from '../../../../components/Buttons/CbButton';
import { withFormController } from '../../../../components/hocs/forms';
import { claimNoticeOnlyStatus, createDiaryEntry } from '../../../../api';
import {
  CLAIMS_INCURRED_AGGS,
  CLAIMS_STATUS_AGGS,
  COWBELL_CLAIMS,
} from '../../claims.constants';

const TextField = withFormController(TextFieldBase);

const NoticeOnlyModal = ({ data: claim, ...props }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, ...methods } = useForm();

  const onSubmit = React.useCallback(
    (formData) => {
      claimNoticeOnlyStatus(claim.id, { data: formData })
        .then(() => {
          enqueueSnackbar('Claim status updated!', { variant: 'success' });
          queryClient.invalidateQueries([COWBELL_CLAIMS]);
          queryClient.invalidateQueries([CLAIMS_INCURRED_AGGS]);
          queryClient.invalidateQueries([CLAIMS_STATUS_AGGS]);
        })
        .catch(() =>
          enqueueSnackbar(
            'There was a problem with your request, please try again',
            { variant: 'error' }
          )
        );
      if (formData.reason) {
        const payload = {
          ..._.pick(claim, ['accountId', 'policyNumber']),
          accountName: claim.companyName,
          claimId: claim.id,
          title: 'Claim Status changed to "Notice Only"',
          noteType: 'NOTICE_ONLY',
          noteText: formData.reason,
          attachments: [],
        };
        createDiaryEntry({ data: payload });
      }
      props.close();
    },
    [claim, enqueueSnackbar, props, queryClient]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '3rem' }}
          >
            <Typography>
              Are you sure you want to change the status of Claim #
              <br />
            </Typography>
            <Typography>
              <span style={{ color: theme.config.colors.cowbellBlue }}>
                {claim.claimNumber}
              </span>{' '}
              ?
            </Typography>
          </Box>
          <TextField name="reason" label="Reason" multiline minRows={5} />
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={props.close}>
            Cancel
          </CbButton>
          <CbButton styleName="ctaButton" type="submit">
            Update
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const NoticeOnlyModalConfig = {
  NoticeOnlyModal: {
    component: NoticeOnlyModal,
    config: {
      fullWidth: true,
      title: 'Change Claim Status to "Notice Only"',
    },
  },
};
