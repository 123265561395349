import Moment from 'moment';
import Numeral from 'numeral';
import { ProductTypes } from '../../types';
import { getBoolValueText } from '../../utils/appUtils';
import {
  formatCurrencyAsFloat,
  numberToCurrency,
} from '../../utils/currency.utils';
import { getCoverages } from '../_global/endorsements/endorsement.utils';
import { toUniversalUtcDate } from '../../utils/date.utils';
import {
  getCurrencySymbolByLanguage,
  getRegionByProduct,
} from '../../i18n/utils';
import { NO_DECIMAL_FORMAT, deriveCurrencyFormat } from '../../i18n/currencies';

function getTotalPremium(totalPremium, currencySymbol) {
  if (!(typeof totalPremium === 'number')) return null;
  if (totalPremium >= 0)
    return `${currencySymbol}${Numeral(totalPremium).format('0,000.00')}`;
  return `(${currencySymbol}${Numeral(totalPremium).format('0,000.00')})`;
}

export const formatPolicies = ({ data }) => {
  return data.map((policy) => formatPolicy(policy));
};

export const formatPolicy = (policy) => {
  const language = getRegionByProduct(policy.product);
  const currencySymbol = getCurrencySymbolByLanguage(language);

  return {
    ...policy,
    uiBinderExpiresOn: toUniversalUtcDate(policy.binderExpiresOn, {
      placeholder: null,
    }),
    uiEffectiveDate: toUniversalUtcDate(policy.effectiveDate, {
      placeholder: null,
    }),
    uiPolicyExpiresOn: toUniversalUtcDate(policy.expiresOn, {
      placeholder: null,
    }),
    uiCustomerFullName:
      policy.customerFirstName && policy.customerLastName
        ? `${policy.customerFirstName} ${policy.customerLastName}`
        : null,
    uiAgentFullName:
      policy.agentFirstName && policy.agentLastName
        ? `${policy.agentFirstName} ${policy.agentLastName}`
        : null,
    uiAgentInitials:
      policy.agentFirstName && policy.agentLastName
        ? `${policy.agentFirstName[0]}${policy.agentLastName[0]}`
        : null,
    uiPremium: policy.premium
      ? deriveCurrencyFormat(language, policy.premium, NO_DECIMAL_FORMAT)
      : null,
    uiRefund: deriveCurrencyFormat(language, policy.refund),
    uiLimit: policy.limit
      ? `${currencySymbol}${numberToCurrency(policy.limit, '0.0a')}`
      : null,
    uiTotalPremium: getTotalPremium(policy.totalPremium, currencySymbol),
    uiTerm:
      policy.effectiveDate && policy.expiresOn
        ? Moment.utc(policy.expiresOn).diff(
            Moment.utc(policy.effectiveDate),
            'days'
          )
        : null,
    uiDeductible: policy.deductible
      ? `${currencySymbol}${numberToCurrency(policy.deductible, '0.0a')}`
      : null,
    uiIsEndorsement: getBoolValueText(policy.isEndorsement),
    uiAddress: formatAddress({
      address1: policy.address1,
      city: policy.city,
      state: policy.state,
      zipCode: policy.zipCode,
    }),
    uiCancellationReason: policy?.cancellationReason?.split('_').join(' '),
  };
};

// used to display base policy + endorsements
export const formatForLatestPolicy = (policy, product) => {
  if (![ProductTypes.p100, ProductTypes.p250].includes(product)) {
    return formatPolicy(policy);
  }

  const language = getRegionByProduct(product);
  const currencySymbol = getCurrencySymbolByLanguage(language);
  const { originalPolicy = {}, latestPolicy = {} } = policy;

  const hasP250InitialRequestData = Boolean(latestPolicy?.initialRequestData);

  let latestCoverages = {};
  if (product === ProductTypes.p100) {
    latestCoverages = getCoverages(latestPolicy, product);
  } else if (product === ProductTypes.p250 && hasP250InitialRequestData) {
    latestCoverages = latestPolicy?.initialRequestData?.coverages;
  }

  return {
    ...originalPolicy,
    latestCoverages,
    companyName: originalPolicy?.firmographicData?.name,
    uiEffectiveDate: toUniversalUtcDate(originalPolicy.effectiveDate, {
      placeholder: null,
    }),
    expiresOn: toUniversalUtcDate(latestPolicy.endDate, {
      placeholder: null,
    }),
    uiCustomerFullName:
      originalPolicy.customerFirstName && originalPolicy.customerLastName
        ? `${originalPolicy.customerFirstName} ${originalPolicy.customerLastName}`
        : null,
    uiAgentFullName:
      originalPolicy.agentFirstName && originalPolicy.agentLastName
        ? `${originalPolicy.agentFirstName} ${originalPolicy.agentLastName}`
        : null,
    uiAgentInitials:
      originalPolicy.agentFirstName && originalPolicy.agentLastName
        ? `${originalPolicy.agentFirstName[0]}${originalPolicy.agentLastName[0]}`
        : null,
    uiPremium: latestPolicy.premium
      ? Numeral(latestPolicy.premium).format('$0,000')
      : null,
    uiRefund: formatCurrencyAsFloat(originalPolicy.refund),
    limit: latestPolicy.limit,
    uiTotalPremium: getTotalPremium(
      originalPolicy.totalPremium,
      currencySymbol
    ),
    uiTerm:
      originalPolicy.effectiveDate && latestPolicy.endDate
        ? Moment.utc(latestPolicy.endDate).diff(
            Moment.utc(originalPolicy.effectiveDate),
            'days'
          )
        : null,
    deductible: latestPolicy.deductible,
    uiIsEndorsement: getBoolValueText(originalPolicy.isEndorsement),
    uiAddress: formatAddress({
      address1: originalPolicy.address1,
      city: originalPolicy.city,
      state: originalPolicy.state,
      zipCode: originalPolicy.zipCode,
    }),
    uiCancellationReason: originalPolicy?.cancellationReason
      ?.split('_')
      .join(' '),
  };
};

function formatAddress({ address1, city, state, zipCode }) {
  let addr = '';
  if (address1) addr += address1;
  if (city) addr += `, ${city}`;
  if (state) addr += `, ${state}`;
  if (zipCode) addr += ` ${zipCode}`;

  return addr;
}
