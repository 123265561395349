import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
// helpers
import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// mui
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Divider,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  useTheme,
  DialogTitle,
  Dialog,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Numeral from 'numeral';
import CowbellModalIcon from '../../../_assets/svg/CowbellModal.svg';

// components
import { withShowable } from '../../../console/_global/lib/withShowable';
import { withFormController } from '../../hocs/withFormController';
import CbButton from '../../Buttons/CbButton';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { InputLabelBase } from '../../inputs/InputLabelBase';

// service
import {
  getBalanceOnPolicyInvoice,
  makePaymentFromCowbell,
} from '../../../console/customers/_services/BillingService';
import { useAllow } from '../../hooks/useAllow';
import LanguageCurrencyField from '../../inputs/LanguageCurrencyField';

import { deriveCurrencyFormat } from '../../../i18n/currencies';
import { useCowbellTranslations } from '../../../i18n/translations';
import { getRegionByProduct } from '../../../i18n/utils';
import { languages } from '../../../i18n/i18n.language-config';
import { ProductTypes } from '../../../types';

const HiddenGrid = withShowable(MuiGrid);
const HelperText = withShowable(FormHelperText);
const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  paymentType: Yup.string().ensure().required('Payment Type is required'),
  processorName: Yup.string().required('Processor Name is required'),
  paymentDesc: Yup.string(),
  paymentAmount: Yup.string()
    .required('Invalid Amount')
    .typeError('Payment Amount is required'),
});

export const ApplyExternalPayment = ({ data, close, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [paymentData, setPaymentData] = useState({});
  const [BEerror, setBEerror] = useState('');
  const [leftoverAmount, setLeftoverAmount] = React.useState(0);

  const canApplyOrRefundPayment = useAllow('role:admin');

  const region = getRegionByProduct(data.product);
  const { t, translationKeys } = useCowbellTranslations();

  const paymentOptions = getLocalPaymentOptions(region);
  const processorOptions = getLocalProcessorOptions(region);

  const {
    control,
    handleSubmit,
    setValue,

    formState: { isSubmitting },
    ...methods
  } = useForm({
    defaultValues: {
      paymentAmount: '',
      paymentDesc: '',
    },
    validationSchema: schema,
  });

  const values = methods.getValues();

  React.useEffect(() => {
    methods.reset();
    setPaymentData({});
    setLeftoverAmount(0);
    getBalanceOnPolicyInvoice({ policyId: data.id })
      .then((resp) => {
        setPaymentData(resp.data);
        setBEerror('');
        setValue(
          'paymentAmount',
          Numeral(resp.data.amountDue)._value.toFixed(2)
        );
      })
      .catch((error) => {
        setBEerror(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong. Please try again later'
            )
          )
        );
      });
    // eslint-disable-next-line
  }, [data]);

  const formCompliance = () => {
    if (paymentData.cowbellCompliance) {
      return 'Cowbell Compliance';
    }
    return `${t(translationKeys.agency)} Compliance`;
  };

  const getbillNaming = () => {
    if (data.product === ProductTypes.prime_one)
      return `${t(translationKeys.agency)} Bill`;
    const agencyBilled = _.get(data, 'firmographicData.agencyBilled');
    if (agencyBilled === undefined) return 'Direct Bill';
    if (agencyBilled) return `${t(translationKeys.agency)} Bill`;
    return 'Direct Bill';
  };

  const invoiceStatement = () => {
    if (
      !paymentData.cowbellCompliance &&
      _.get(data, 'firmographicData.agencyBilled') === true
    ) {
      return '(without Commission, without Surplus Taxes)';
    }
    if (
      paymentData.cowbellCompliance &&
      _.get(data, 'firmographicData.agencyBilled') === true
    ) {
      return '(without Commission)';
    }
    return '';
  };

  const isRefund = paymentData.amountDue < 0;

  const handleChange = ({ target }) => {
    const { rawValue } = target;

    setValue('paymentAmount', rawValue);
    const result = calculatePremium(paymentData.amountDue, rawValue);

    setLeftoverAmount(result);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleSelectPaymentType = (event) => {
    setValue('paymentType', event.target.value);
  };

  const handleSelectProcessorName = (event) => {
    setValue('processorName', event.target.value);
  };

  const renderOptions = ({ label, value }) => {
    return (
      <MuiMenuItem key={label} value={value}>
        {label}
      </MuiMenuItem>
    );
  };
  const onSubmit = (formData) => {
    const paymentAmount = Number(
      formData.paymentAmount.replace(/[^0-9.-]+/g, '')
    );
    const payload = {
      ...formData,
      accountId: data.accountId,
      policyId: data.id,
      customerEmail: data.customerEmail,
      paymentAmount: isRefund ? -Math.abs(paymentAmount) : paymentAmount,
    };

    return makePaymentFromCowbell({}, payload)
      .then(() => {
        enqueueSnackbar('Payment was made Successfully!', {
          variant: 'success',
        });
        close();
        data?.onPaymentSuccessful?.();
        data?.refresh?.();
      })
      .catch((error) => {
        setBEerror(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong. Please try again later!'
            )
          )
        );
      });
  };
  const buttonText = isRefund ? 'Refund' : 'Make Payment';

  return (
    <Dialog {...props}>
      <DialogTitle classes={{ root: classes.rootTitle }}>
        <Box className={classes.boxTitle}>
          <CowbellModalIcon />
          Payment/Refund for {data.companyName}
        </Box>
        <CancelOutlinedIcon
          className={classes.cancel}
          fontSize="1.3333333333rem"
          onClick={close}
        />
      </DialogTitle>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={`modal-title ${classes.dialogStyle}`}>
            <MuiGrid
              container
              justifyContent="center"
              direction="column"
              className={classes.header}
            >
              <MuiGrid item md={12}>
                <h2>Policy# {data.policyNumber}</h2>
              </MuiGrid>
              <MuiGrid item md={12}>
                <h3>
                  {getbillNaming()} | {formCompliance()}
                </h3>
              </MuiGrid>
            </MuiGrid>

            <Divider className={classes.divider} />

            <MuiGrid container>
              <MuiGrid item md={9}>
                {paymentData.totalPremium > 0
                  ? 'Total Premium'
                  : 'Premium Refund'}
              </MuiGrid>

              <MuiGrid
                item
                md={3}
                className={classes.textRight}
                style={
                  paymentData.totalPremium < 0
                    ? { color: theme.config.colors.ripeTomato }
                    : {}
                }
              >
                {deriveCurrencyFormat(
                  region,
                  Math.abs(paymentData.totalPremium || 0)
                )}
              </MuiGrid>
            </MuiGrid>

            <MuiGrid container>
              <MuiGrid item md={9}>
                <MuiGrid container>
                  <MuiGrid item md={12}>
                    {paymentData.commission > 0
                      ? 'Commissions'
                      : 'Commissions Refund'}
                  </MuiGrid>

                  <HiddenGrid
                    show={
                      data.isSurplus || data.product === ProductTypes.prime_one
                    }
                    item
                    md={12}
                  >
                    <HelperText className={classes.hiddenText}>
                      ({_.get(data, 'firmographicData.commission', 0)}% of
                      Commissionable Premium:{' '}
                      {deriveCurrencyFormat(
                        region,
                        Math.abs(paymentData.commissionablePremium)
                      )}
                      )
                    </HelperText>
                  </HiddenGrid>
                </MuiGrid>
              </MuiGrid>

              <MuiGrid
                item
                md={3}
                className={classes.textRight}
                style={
                  paymentData.commission < 0
                    ? { color: theme.config.colors.ripeTomato }
                    : {}
                }
              >
                {deriveCurrencyFormat(region, paymentData.commission)}
              </MuiGrid>
            </MuiGrid>

            <HiddenGrid show={data.isSurplus} container>
              <MuiGrid item md={9}>
                Surplus Taxes
              </MuiGrid>

              <MuiGrid
                item
                md={3}
                className={classes.textRight}
                style={
                  paymentData.surplusTaxes < 0
                    ? { color: theme.config.colors.ripeTomato }
                    : {}
                }
              >
                {deriveCurrencyFormat(region, paymentData.surplusTaxes)}
              </MuiGrid>
            </HiddenGrid>

            <Divider className={classes.divider} />
            <Divider
              className={classes.divider}
              style={{ margin: '0.25rem 0 0.5rem' }}
            />

            <MuiGrid container>
              <MuiGrid item md={9}>
                <MuiGrid container>
                  <MuiGrid item md={12}>
                    Invoice Amount
                  </MuiGrid>

                  <HiddenGrid show={invoiceStatement !== ''} item md={12}>
                    <HelperText className={classes.hiddenText}>
                      {invoiceStatement}
                    </HelperText>
                  </HiddenGrid>
                </MuiGrid>
              </MuiGrid>

              <MuiGrid item md={3} className={classes.textRight}>
                {deriveCurrencyFormat(region, paymentData.invoiceAmount)}
              </MuiGrid>
            </MuiGrid>

            <MuiGrid container>
              <MuiGrid item md={9}>
                Already Paid
              </MuiGrid>

              <MuiGrid
                item
                md={3}
                className={classes.textRight}
                style={
                  paymentData.alreadyPaid < 0
                    ? { color: theme.config.colors.ripeTomato }
                    : {}
                }
              >
                {deriveCurrencyFormat(region, paymentData.alreadyPaid)}
              </MuiGrid>
            </MuiGrid>

            <Divider className={classes.divider} />
            <Divider
              className={classes.divider}
              style={{ margin: '0.25rem 0 0.5rem' }}
            />

            <MuiGrid container>
              <MuiGrid item md={5}>
                <InputLabelBase style={{ paddingTop: '0rem' }}>
                  {paymentData.amountDue < 0 ? 'Over Paid' : 'Amount Due'}
                </InputLabelBase>
              </MuiGrid>

              <MuiGrid
                item
                md={7}
                className={classes.textRight}
                style={
                  paymentData.amountDue < 0
                    ? { color: theme.config.colors.ripeTomato }
                    : {}
                }
              >
                {deriveCurrencyFormat(region, paymentData.amountDue)}
              </MuiGrid>
            </MuiGrid>

            <HiddenGrid
              show={parseFloat(paymentData.amountDue) > 0}
              container
              className={classes.textRight}
            >
              <MuiGrid item md={5}>
                <InputLabelBase required className={classes.label}>
                  Pay
                </InputLabelBase>
              </MuiGrid>

              <MuiGrid item md={7}>
                <LanguageCurrencyField
                  language={region}
                  name="paymentAmount"
                  value={values.paymentAmount}
                  disabled={!canApplyOrRefundPayment}
                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                  control={control}
                  ref={methods.register({ name: 'paymentAmount' })}
                  onChange={handleChange}
                  error={
                    _.get(methods, 'errors.paymentAmount.message') ||
                    _.get(methods, 'errors.paymentAmount.types.message')
                  }
                />
              </MuiGrid>
            </HiddenGrid>
            <HiddenGrid
              show={paymentData.amountDue < 0}
              container
              className={classes.textRight}
            >
              <MuiGrid item md={5}>
                <InputLabelBase required className={classes.label}>
                  Refund
                </InputLabelBase>
              </MuiGrid>

              <MuiGrid item md={7}>
                <LanguageCurrencyField
                  language={region}
                  name="paymentAmount"
                  value={Math.abs(values.paymentAmount)}
                  disabled={!canApplyOrRefundPayment}
                  placeholder={paymentData.amountDue}
                  control={control}
                  inputProps={{
                    min: 0,
                    style: {
                      textAlign: 'right',
                      color: theme.config.colors.ripeTomato,
                    },
                  }}
                  ref={methods.register('paymentAmount', {
                    value: paymentData.amountDue,
                  })}
                  onChange={handleChange}
                  error={
                    _.get(methods, 'errors.paymentAmount.message') ||
                    _.get(methods, 'errors.paymentAmount.types.message')
                  }
                />
              </MuiGrid>
            </HiddenGrid>
            <MuiGrid container>
              <MuiGrid item md={5}>
                Balance
              </MuiGrid>
              <MuiGrid item md={7} className={classes.textRight}>
                {deriveCurrencyFormat(region, leftoverAmount)}
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container style={{ margin: '2rem 0' }}>
              <MuiGrid item md={5}>
                <InputLabelBase required className={classes.label}>
                  Payment Type
                </InputLabelBase>
              </MuiGrid>
              <MuiGrid item md={7}>
                <MuiSelect
                  variant="standard"
                  name="paymentType"
                  disabled={!canApplyOrRefundPayment}
                  required
                  onChange={handleSelectPaymentType}
                  classes={{
                    select: classes.select,
                    icon: classes.selectIcon,
                    root: classes.textRight,
                  }}
                  defaultValue={null}
                  displayEmpty
                  placeholder="please select a reason"
                  ref={methods.register({ name: 'paymentType' })}
                  error={_.get(methods, 'errors.paymentType.message')}
                >
                  {paymentOptions.map(renderOptions)}
                </MuiSelect>
                <FormHelperText className={classes.error}>
                  {_.get(methods, 'errors.paymentType.message')}
                </FormHelperText>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid
              container
              className={classes.textRight}
              style={{ paddingBottom: '24px' }}
            >
              <MuiGrid item md={5}>
                <InputLabelBase required className={classes.label}>
                  Processor
                </InputLabelBase>
              </MuiGrid>
              <MuiGrid item md={7}>
                <MuiSelect
                  variant="standard"
                  name="processorName"
                  disabled={!canApplyOrRefundPayment}
                  required
                  onChange={handleSelectProcessorName}
                  classes={{
                    select: classes.select,
                    icon: classes.selectIcon,
                    root: classes.textRight,
                  }}
                  defaultValue={null}
                  displayEmpty
                  ref={methods.register({ name: 'processorName' })}
                  error={_.get(methods, 'errors.processorName.message')}
                >
                  {processorOptions.map(renderOptions)}
                </MuiSelect>
                <FormHelperText className={classes.error}>
                  {_.get(methods, 'errors.processorName.message')}
                </FormHelperText>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container>
              <MuiGrid item md={5}>
                <InputLabelBase className={classes.label}>
                  Comments
                </InputLabelBase>
              </MuiGrid>
              <MuiGrid item md={7}>
                <TextField
                  type="text"
                  disabled={!canApplyOrRefundPayment}
                  name="paymentDesc"
                  multiline
                  minRows={4}
                  control={control}
                  placeholder="Details of payment"
                />
              </MuiGrid>
            </MuiGrid>
            {BEerror && (
              <FormHelperText className={classes.error}>
                {BEerror}
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
            <CbButton
              type="submit"
              styleName="ctaButton"
              loading={isSubmitting}
              disabled={
                !canApplyOrRefundPayment ||
                isSubmitting ||
                !!methods.errors.paymentAmount ||
                paymentData.amountDue === 0
              }
            >
              {buttonText}
            </CbButton>
          </DialogActions>
        </form>
      </FormContext>
    </Dialog>
  );
};

const calculatePremium = (invoiceAmount, payment) => {
  const total =
    invoiceAmount < 0
      ? parseFloat(invoiceAmount) + parseFloat(payment)
      : parseFloat(invoiceAmount) - parseFloat(payment);
  return total || 0;
};

const useStyles = makeStyles(({ config }) => ({
  header: {
    textAlign: 'center',
    margin: '0.5rem 0',
    '& h3': {
      margin: 0,
    },
    '& h2': {
      margin: 0,
    },
  },
  boxTitle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    textOverflow: 'ellipsis',
  },
  rootTitle: {
    '& > h2': {
      maxWidth: '99%',
    },
  },
  hiddenText: {
    marginTop: '0rem',
    color: '#9ba0ae',
  },
  dialogStyle: {
    textAlign: 'left',
    padding: '1rem 5rem !important',
  },
  label: {
    padding: '0 ',
  },
  textRight: {
    textAlign: 'right',
    '& .MuiInputBase-input': {
      textAlign: 'right',
    },
  },
  error: {
    fontSize: config.textSizes.normal,
    marginTop: 0,
    textAlign: 'right',
    color: config.colors.textRed,
  },
  select: {
    justifyContent: 'right',
    '&:focus': {
      borderRadius: 5,
    },
  },
  selectIcon: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
  divider: {
    marginTop: '0.5rem',
  },
  red: {
    color: config.colors.ripeTomato,
  },
}));

export const ApplyExternalPaymentConfig = {
  ApplyExternalPayment: {
    component: ApplyExternalPayment,
    config: {
      maxWidth: 'sm',
      minWidth: 'sm',
      override: true,
    },
  },
};

const paymentOptionsUS = [
  { label: 'Select a Payment Option...', value: null },
  { label: 'Check', value: 'Check' },
  { label: 'Credit Card', value: 'Credit Card' },
  { label: 'ACH/Wire Transfer', value: 'Wire' },
  { label: 'Bank Account', value: 'Bank Account' },
  { label: 'Refund/Credit', value: 'Refund/Credit' },
];

const paymentOptionsGB = [
  { label: 'Select a Payment Option...', value: null },
  { label: 'OneAdvent', value: 'OneAdvent' },
  { label: 'Refund/Credit', value: 'Refund/Credit' },
];

const processorOptionsGB = [
  { label: 'Select a Processor Option...', value: null },
  { label: 'Cowbell Credit', value: 'Cowbell Credit' },
  {
    label: 'Great American International Insurence Limited',
    value: 'Great American International Insurence Limited',
  },
];

const processorOptionsUS = [
  { label: 'Select a Processor Option...', value: null },
  { label: 'Boost - BLU', value: 'Boost - BLU' },
  { label: 'Boost - FLY', value: 'Boost - FLY' },
  { label: 'Palomar', value: 'Palomar' },
  { label: 'Surplus', value: 'Surplus' },
  { label: 'Obsidian', value: 'Obsidian' },
  { label: 'Skyward', value: 'Skyward' },
  { label: 'Cowbell Credit', value: 'Cowbell Credit' },
  { label: 'OneInc', value: 'OneInc' },
  { label: 'Benchmark', value: 'Benchmark' },
];

const getLocalPaymentOptions = (region) => {
  if (region === languages['en-GB']) {
    return paymentOptionsGB;
  }

  return paymentOptionsUS;
};

const getLocalProcessorOptions = (region) => {
  if (region === languages['en-GB']) {
    return processorOptionsGB;
  }

  return processorOptionsUS;
};
