import React from 'react';
import { DialogContent } from '@mui/material';

const VideoPlayerModal = (props) => (
  <>
    <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
      <iframe
        title="Video"
        src={props.data.link}
        width="900"
        height="594"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </DialogContent>
  </>
);

export default VideoPlayerModal;
