import React from 'react';
import _ from 'lodash';

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

// utils
import { delayedEvent } from '../../utils/eventUtils';
import { COWBELL_POLICY } from '../../components/tables/table_constants';

// components
import { ChangeBOR } from './ChangeBOR';
import { ProcessBOR } from './ProcessBOR';
import { ConfirmationBORChange } from './ConfirmationBORChange';

// actions
import { changeBOR } from '../../policies/PolicyService';

export const ComposedBOR = ({ close, data }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = React.useState(1);
  const [payload, setPayload] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const handleFileSubmit = (file) => {
    setPage(2);
    setPayload({ ...payload, ...file });
  };

  const handleAgentSubmit = (agentDetails) => {
    setPage(3);
    setPayload({
      ...payload,
      agencyId: agentDetails?.agency?.value,
      agencyName: agentDetails?.agency?.label,
      agentFirstName: agentDetails?.agent?.meta?.firstName,
      agentLastName: agentDetails?.agent?.meta?.lastName,
      agentEmail: agentDetails?.agent?.meta?.email,
      agentPhone: agentDetails?.agent?.meta?.phone,
      agencyHostName: agentDetails?.agency?.meta?.hostname,
      teamId: agentDetails?.team?.value,
      teamName: agentDetails?.team?.label,
    });
  };

  const handleFinalSubmit = () => {
    setLoading(true);
    changeBOR({ policyId: data.id }, { ...payload })
      .then(() => {
        close();
        enqueueSnackbar('BOR Change Requested Successfully.', {
          variant: 'success',
        });

        queryClient.invalidateQueries([COWBELL_POLICY]);
      })
      .catch((error) => {
        const message = _.get(
          error.response,
          'data.message',
          _.get(
            error.response,
            'data',
            'There was a problem changing BOR. Try again later'
          )
        );
        enqueueSnackbar(message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {page === 1 && (
        <ChangeBOR
          handleFileSubmit={handleFileSubmit}
          close={close}
          data={data}
        />
      )}
      {page === 2 && (
        <ProcessBOR
          close={close}
          data={data}
          handleAgentSubmit={handleAgentSubmit}
        />
      )}
      {page === 3 && (
        <ConfirmationBORChange
          close={close}
          data={data}
          handleFinalSubmit={handleFinalSubmit}
          newAgency={payload.agencyName}
          loading={loading}
        />
      )}
    </div>
  );
};

export const ComposedBORConfig = {
  ComposedBOR: {
    component: ComposedBOR,
    config: {
      fullWidth: false,
      maxWidth: 'md',
      title: 'Change BOR',
    },
  },
};
