import { useHistory } from 'react-router-dom';
import { SimpleMenu } from '../../components/Menus/SimpleMenu';
import { CommonPolicyOptions } from './CommonPolicyOptions';
import { MidTermEndorsementOption } from './MidtermEndorsementOption';
import type { PolicyTableInfoDto } from '../../types/policy/tables/policy-table-info.dto';
import type { ProductType } from '../../types';

interface P100ProAgencyPolicyOptionsProps {
  policy: PolicyTableInfoDto;
}

const PrimeXAgencyPolicyOptions = ({
  policy,
}: P100ProAgencyPolicyOptionsProps) => {
  const handlers = useMenuItemHandlers(policy);

  return (
    <SimpleMenu>
      <CommonPolicyOptions data={policy} />

      <MidTermEndorsementOption
        policy={policy}
        onClick={handlers.midtermEndorsement}
      >
        Midterm Endorsement
      </MidTermEndorsementOption>
    </SimpleMenu>
  );
};

export default PrimeXAgencyPolicyOptions;

type UseMenuItemHandlersProps = {
  policyNumber: string;
  product: ProductType;
};

const useMenuItemHandlers = (policy: UseMenuItemHandlersProps) => {
  const history = useHistory();

  const midtermEndorsement = () => {
    history.push(
      `/agency/policy/${policy.policyNumber}/midterm-endorsement/${policy.product}`
    );
  };

  return {
    midtermEndorsement,
  };
};
