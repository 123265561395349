import React, { useEffect } from 'react';
import { compose } from 'redux';
import { FormContext, useForm } from 'react-hook-form-4';
import { Box, DialogActions, DialogContent } from '@mui/material';

import { useSnackbar } from 'notistack';
import { withStyles } from '@mui/styles';
import { withUploadFeatureApplications } from '../fileUploader/withUploadFeatureApplications';
import { withUploadFeature } from '../fileUploader/withUploadFeature';
import CBButton from '../Buttons/CbButton';
import { PubSub } from '../../utils/eventUtils';
import { uploadDocument } from '../../api/DocumentsService';
import { Modal } from './v2/helpers/v2.modal.helpers';
import { getFileExtension, getFileName } from '../../utils';

const styles = ({ palette }) => {
  return {
    iconCancel: {
      width: '2rem',
      height: '2rem',
      margin: '1.3rem 0 0 0.5rem',
      cursor: 'pointer',
    },
    selectedFile: {
      fontSize: '1.33rem',
      lineHeight: 1.38,
      color: palette.primary.main,
      marginBottom: '0 !important',
    },
    selectedFileContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    dragDropText: {
      width: '10.67rem',
      fontSize: '1rem',
      lineHeight: 1.33,
      color: palette.primary.main,
    },
    dragDropContainer: {
      margin: '2rem auto 0 auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      height: '5.833rem',
      width: '25rem',
      borderRadius: '5px',
      border: `1px solid ${palette.primary.main}`,
      cursor: 'pointer',
    },
    container: {
      width: '50rem',
      backgroundColor: palette.background.active,
    },
    bodyContainer: {
      height: 'fit-content',
      textAlign: 'center',
    },
    supportedFiles: {
      fontSize: '1.167rem',
      lineHeight: 1.5,
      color: palette.text.secondary,
      marginBottom: 20,
    },
    icon: {
      width: '1.5rem',
      height: '1.25rem',
      margin: '1.6rem 0 0 1rem',
    },
    icon1: {
      width: '5rem',
      height: '5rem',
      margin: '0 0 0 1rem',
    },
    downloadTemplate: {
      'text-decoration': 'underline',
      fontWeight: 600,
      cursor: 'pointer',
      color: palette.primary.main,
      marginLeft: '0.5rem',
    },
    error: {
      color: palette.error.main,
    },
    description: {
      color: palette.primary.contrastText,
      lineHeight: 1.17,
      textAlign: 'center',
      fontSize: '1rem',
    },
    header: {
      fontSize: '1.6667rem',
      lineHeight: 0.9,
      margin: '2rem 0 0 0',
      color: palette.primary.contrastText,
      textAlign: 'center',
    },
  };
};

export const OptOutRenewalProcess = compose(
  withUploadFeatureApplications(),
  withUploadFeature({
    screenCenter: false,
    hide: false,
    acceptFileTypes: `image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf`,
  }),
  withStyles(styles)
)(({ classes, data, ...props }) => {
  const [file, setFile] = React.useState(null);
  const { handleSubmit, ...methods } = useForm({});
  const { enqueueSnackbar } = useSnackbar();

  const {
    formState: { isSubmitting },
  } = methods;
  const [fileDetails, setFileDetails] = React.useState({});

  useEffect(() => {
    const sub = PubSub.subscribe(
      'withUploadFeatureApplications:selectedFile',
      (eventData) => {
        setFile(eventData);
        const extension = getFileExtension(eventData.name);
        const fileName = getFileName(eventData.name);
        setFileDetails({ extension, fileName });
      }
    );
    return () => {
      sub.remove();
    };
  }, [file]);

  const onSubmit = () => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    return uploadDocument(
      bodyFormData,
      data.accountId,
      'Opt_Out',
      fileDetails.fileName,
      true
    )
      .then(() => {
        enqueueSnackbar('Upload successful', { variant: 'success' });
        props.close();
        Modal.show('OptOutRenewalProcessReason', { data });
      })
      .catch(() => {
        enqueueSnackbar('Failed to upload the file!', { variant: 'error' });
      });
  };

  const uploadDocumentHandler = React.useCallback((event) => {
    PubSub.publish('openFileUploadFeature', event);
  }, []);

  const cancelSelectedFile = React.useCallback(() => {
    setFile(null);
  }, []);

  const handleCancel = React.useCallback(() => {
    Modal.hide('OptOutRenewalProcess');
  }, []);

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ marginTop: '0.625rem', paddingBottom: 0 }}>
          <section className={classes.bodyContainer}>
            <p className={classes.header}>
              Account Name: {data && data.companyName}
            </p>
            <p className={classes.description}>
              Please upload the opt-out request below.
            </p>
            {file ? (
              <section className={classes.selectedFileContainer}>
                <p className={classes.selectedFile}>
                  SELECTED FILE: {file.name}
                </p>
                <Box
                  component="img"
                  alt=""
                  src="images/cancel.svg"
                  className={classes.iconCancel}
                  onClick={cancelSelectedFile}
                />
              </section>
            ) : (
              <Box
                className={classes.dragDropContainer}
                onClick={uploadDocumentHandler}
              >
                <p className={classes.dragDropText}>
                  Drag and Drop a file here or Click to Upload
                </p>
                <img
                  alt=""
                  src="images/cloud-upload.svg"
                  className={classes.icon1}
                />
              </Box>
            )}
            <p className={classes.supportedFiles}>
              Supported File Types: PDF, DOC, JPEG & PNG
            </p>
          </section>
        </DialogContent>
        <DialogActions>
          <CBButton action={handleCancel} styleName="cancel">
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting || file === null}
            styleName="ctaButton"
            buttonText="NEXT"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
});

export const OptOutRenewalProcessConfig = {
  OptOutRenewalProcess: {
    component: OptOutRenewalProcess,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      title: 'Opt-Out of Renewal Process',
    },
  },
};
