import React from 'react';

const SVG = ({ fill = '#2180e2' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g>
      <path
        fill={fill}
        d="M-7.945-10.206h2.374l.874-4.57H-1l.829 4.57h2.4l-3.88-18.144h-2.441zm3.584-6.384l1.5-8.176 1.523 8.176z"
        transform="translate(-6573 165) translate(6591.86 -129.594)"
      />
      <g fill="none">
        <path
          d="M16 0A16 16 0 1 1 0 16 16 16 0 0 1 16 0z"
          transform="translate(-6573 165) translate(6573 -165)"
        />
        <path
          fill="#2180e2"
          d="M16 2c-3.74 0-7.255 1.456-9.9 4.1C3.457 8.746 2 12.26 2 16s1.456 7.255 4.1 9.9C8.746 28.543 12.26 30 16 30s7.255-1.456 9.9-4.1c2.644-2.645 4.1-6.16 4.1-9.9s-1.456-7.255-4.1-9.9C23.254 3.457 19.74 2 16 2m0-2c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0z"
          transform="translate(-6573 165) translate(6573 -165)"
        />
      </g>
    </g>
  </svg>
);
export default SVG;
