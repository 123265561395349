import React from 'react';

import { makeStyles } from '@mui/styles';
import { useFormContext } from 'react-hook-form-4';

import { withShowable } from '../../../console/_global/lib/withShowable';
import { Button } from '../../../components/Buttons/Button';

export const EditSection = withShowable(() => {
  const methods = useFormContext();
  const classes = useEditSectionStyles();
  const { dirty } = methods.formState;

  const onCancelBtnClick = () => {
    const confirmCancel = window.confirm(
      'You have unsaved changes, are you sure you want to cancel?'
    );

    if (confirmCancel) {
      methods.reset();
    }
  };

  return (
    <div className={classes.editSection}>
      <Button
        disabled={!dirty}
        onClick={onCancelBtnClick}
        variant="outlined"
        fixed
        size="sm"
      >
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={!dirty || !!methods.errors.domainName}
        stylename="primary"
        fixed
        size="sm"
      >
        Save
      </Button>
    </div>
  );
});

const useEditSectionStyles = makeStyles(({ spacing }) => ({
  editSection: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',

    '& > *': {
      marginLeft: spacing(1),
    },
  },
}));
