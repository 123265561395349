import React from 'react';

const SVG = ({
  style = {},
  fill = '#9192a4',
  width = '100%',
  className = '',
  viewBox = '0 0 65 65',
}) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .cls-1 { fill: ${fill}; }
        `}
      </style>
    </defs>
    <title>Premium_SVG</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M34.41,30.72V19.43a5.63,5.63,0,0,1,2.67,2.22,1.86,1.86,0,0,0,2.55.63l.08-.06a2,2,0,0,0,.55-2.75,9.28,9.28,0,0,0-5.86-4.16V12.15a1.9,1.9,0,0,0-1.83-2,1.92,1.92,0,0,0-2,1.83v3.3a9.79,9.79,0,0,0,0,19V45.57a5.52,5.52,0,0,1-2.67-2.22,1.84,1.84,0,0,0-2.55-.62l-.08.05a2,2,0,0,0-.55,2.75,9.28,9.28,0,0,0,5.86,4.16v3.16a1.89,1.89,0,0,0,1.82,2,1.92,1.92,0,0,0,2-1.83V49.7a9.79,9.79,0,0,0,0-19Zm-3.81-.57a5.79,5.79,0,0,1,0-10.71Zm6.95,12.27a5.81,5.81,0,0,1-3.14,3.15V34.85A5.8,5.8,0,0,1,37.55,42.42Z"
        />
        <path
          className="cls-1"
          d="M32.5,2.5a30,30,0,1,0,30,30A30,30,0,0,0,32.5,2.5Zm0,58a28,28,0,1,1,28-28A28,28,0,0,1,32.5,60.51Z"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
