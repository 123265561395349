import React from 'react';
import Moment from 'moment';

import { Link } from 'react-router-dom';
import { Box, Chip, styled, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Allow } from '../../../../components/auth/Allow';
import { JumpPersonaButton } from '../../../../components/personas/JumpPersonaButton';
import SimpleTooltip from '../../../../components/SimpleTooltip';
import { onJumpRequested } from '../../../../utils';

import { ClaimIndicator } from '../../../../_assets/svg/claims/ClaimIndicator.svg';
import { ProductTypes } from '../../../../types';
import {
  CANCELLED_IN_REINSTATEMENT_PERIOD,
  CANCELLED_PENDING_STATUS,
  CANCELLED_STATUS,
  IN_FORCE_STATUS,
  PolicyStatus,
} from '../../../_statics/policy.statics';
import { NoKnownLossLetterIndicator } from '../../../../components/Reusable';
import UWInboxAccNotes from '../../../../_assets/svg/UWInboxAccNotes.svg';
import UWInboxNotes from '../../../../_assets/svg/UWInboxNotes.svg';
import { Note } from '../../../../components/notes/notes.helper';
import PolicyDrawer from '../../drawers/policyholder/PolicyDrawer';
import { P100PolicyMidTermEndorsementDrawer } from '../../../_global/endorsements/P100PolicyMidTermEndorsementDrawer';
import { getPolicyDetails } from '../../../../api/policies.api';
import { PolicyDetailsDrawer } from '../../drawers/cowbell/policy-details-drawer/PolicyDetailsDrawer';
import AccountLink from '../../../../components/tables/cell-components/account-link/AccountLink';
import EyeButton from '../../../../components/Buttons/EyeButton';
import { BORIndicatorCell } from '../../../../components/tables/cell-components/BORIndicatorCell';

import {
  AvatarWithInitialsFallBack,
  constructAvatarInitials,
  useProfilePhoto,
} from '../../../../components/notes/AvatarWithInitialsFallBack';
import { PERSONA_TYPES } from '../../../_statics/persona.statics';
import { usePersona } from '../../../../components/hooks/usePersona';
import SimpleDateDisplay from '../../../../components/SimpleDateDisplay';
import { fetchLatestPolicyData } from '../../../../policies/PolicyService';
import {
  FORMAT_DATE,
  FORMAT_DATE_AND_TIME,
  toUniversalUtcDate,
} from '../../../../utils/date.utils';

const policyStatusForShowingDrawer = [
  PolicyStatus.ISSUED_STATUS,
  PolicyStatus.IN_FORCE_STATUS,
  PolicyStatus.RENEWED_STATUS,
  PolicyStatus.EXPIRED_STATUS,
];

export function PolicyRowDetail({ row, onExpandRow }) {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);
  const persona = usePersona();

  if (
    row.isPrimePlus ||
    (row.isBOR && persona.isCluster) ||
    row.product === ProductTypes.primecloud
  ) {
    return null;
  }

  const fetchLatestPolicyByStatus = policyStatusForShowingDrawer.includes(
    row.status
  );

  if (row.isEndorsement) {
    return (
      <PolicyDrawer
        policyId={row.id}
        product={row.product}
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          onExpandRow(row.id);
        }}
      >
        {({ policy = {} }) => (
          <Box
            style={{
              overflowY: 'auto',
            }}
          >
            <P100PolicyMidTermEndorsementDrawer
              policy={{ ...policy, ...policy.coverages }}
            />
          </Box>
        )}
      </PolicyDrawer>
    );
  }

  return (
    <PolicyDrawer
      policyId={row.id}
      product={row.product}
      policyNumber={row.policyNumber}
      fetchLatestPolicyByStatus={fetchLatestPolicyByStatus}
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
        onExpandRow(row.id);
      }}
      onFetch={onFetch}
    >
      {({ policy }) => {
        return (
          <Box
            style={{
              overflowY: 'auto',
              maxWidth: '45rem',
            }}
          >
            <PolicyDetailsDrawer
              policy={policy}
              getPolicyStatus={getPolicyStatus}
              product={row.product}
              fetchLatestPolicyByStatus={fetchLatestPolicyByStatus}
            />
          </Box>
        );
      }}
    </PolicyDrawer>
  );
}

const onFetch = (
  policyId,
  product,
  policyNumber,
  fetchLatestPolicyByStatus
) => {
  if (
    [ProductTypes.p100, ProductTypes.p250].includes(product) &&
    fetchLatestPolicyByStatus
  ) {
    return Promise.all([
      getPolicyDetails(policyId, product),
      fetchLatestPolicyData({ product, policyNumber }),
    ]).then(([originalPolicy, latestPolicy]) => {
      return {
        originalPolicy: originalPolicy?.data?.data,
        latestPolicy: latestPolicy?.data,
      };
    });
  }

  return getPolicyDetails(policyId, product).then(({ data }) => data?.data);
};

export const ClaimTag = styled(ClaimIndicator)({
  position: 'absolute',
  bottom: '1rem',
});

export const cowbellPolicyDatePresets = [
  {
    label: '7 days',
    value: '1',
  },
  {
    label: '1 month',
    value: '2',
  },
  {
    label: '3 months',
    value: '3',
  },
  {
    label: '6 months',
    value: '4',
  },
  {
    label: '1 year',
    value: '5',
  },
  {
    label: '2 years',
    value: '11',
  },
  {
    label: '3 years',
    value: '12',
  },
];

export const AgencyNameCell = ({ policy = {} }) => {
  const hasProcessedBOR = policy.isBOR && policy.brokerOfRecord;
  const currentAgency = hasProcessedBOR
    ? {
        agencyId: policy.brokerOfRecord.agencyId,
        agencyName: policy.brokerOfRecord.agencyName,
      }
    : { agencyId: policy.agencyId, agencyName: policy.agencyName };

  return (
    <BORIndicatorCell policy={policy}>
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className="expand-icon"
      >
        <Box
          style={{
            maxWidth: '14rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Link
            style={{ color: 'inherit' }}
            to={`/admin/agencies?search=${currentAgency.agencyId}`}
            onClick={(event) => event.stopPropagation()}
          >
            {currentAgency.agencyName}
          </Link>
        </Box>
        {/* jump needs to be on the processed BOR agency if applicable */}
        <Allow allow={['agencies:enter', 'account:cowbell']}>
          <JumpPersonaButton
            onJumpRequest={onJumpRequested(
              PERSONA_TYPES.AGENCY,
              currentAgency.agencyId,
              currentAgency.agencyName
            )}
          />
        </Allow>
      </div>
    </BORIndicatorCell>
  );
};

export const renderDateComponent = (date) => <SimpleDateDisplay date={date} />;
export const derivePolicyEffectiveDate = (policy = {}, render = (x) => x) => {
  const invalidStatus = ['DELETED'];
  if (invalidStatus.includes(policy.customerStatus)) {
    return '-';
  }

  return policy.effectiveDate ? render(policy.effectiveDate) : '-';
};

export const derivePolicyEndDate = (policy = {}, render = (x) => x) => {
  const invalidStatus = ['DELETED'];
  if (invalidStatus.includes(policy.customerStatus)) {
    return '-';
  }

  return policy.endDate ? render(policy.endDate) : '-';
};

export function formatUTCDate(dateString, shouldDisplayTime = false) {
  return toUniversalUtcDate(dateString, {
    format: shouldDisplayTime ? FORMAT_DATE_AND_TIME : FORMAT_DATE,
  });
}

export function PolicyNumberCell({ row }) {
  const theme = useTheme();

  const claimStatus = row.claimStatus ?? '';
  const tagcolor =
    row.claimStatus === 'CLOSED' ? theme.config.cowbellAccent : 'salmon';

  return (
    <Box style={{ position: 'relative' }}>
      {row.claimId && (
        <SimpleTooltip title={`${claimStatus} - Claim #${row.claimNumber}`}>
          <ClaimTag color={tagcolor} />
        </SimpleTooltip>
      )}
      {row.policyNumber}
    </Box>
  );
}

export function getAgentCellValue(row) {
  const { agentFirstName, agentLastName } = row;

  if (!agentFirstName && !agentLastName) {
    return '-';
  }

  return `${agentFirstName} ${agentLastName}`;
}

export function getStatusCellValue(row) {
  const { noKnownLossLetterReq } = row;

  const status = getPolicyStatus(row);

  return (
    <div style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}>
      {status}
      <NoKnownLossLetterIndicator status={noKnownLossLetterReq} />
    </div>
  );
}

export function getNotesCellValue(row) {
  const attachmentMetaData = {
    accountId: row.accountId,
    agencyId: row.agencyId,
    companyName: row.companyName,
  };

  return (
    <div style={{ display: 'flex' }}>
      <SimpleTooltip title="Account Notes">
        <UWInboxAccNotes
          onClick={(event) => {
            event.stopPropagation();
            Note.show('account', {
              data: {
                accountId: row.accountId,
                attachmentMetaData,
              },
            });
          }}
        />
      </SimpleTooltip>

      {row.quoteId && (
        <SimpleTooltip title="Quote Notes">
          <UWInboxNotes
            onClick={(event) => {
              event.stopPropagation();
              Note.show('quote', {
                data: {
                  quoteId: row.quoteId,
                  product: row.product,
                },
              });
            }}
          />
        </SimpleTooltip>
      )}
    </div>
  );
}

export function getCompanyNameCellValue(row, context) {
  const { onOpenDrawer } = context;

  const handleOpenDrawer = (e) => {
    e.stopPropagation();
    onOpenDrawer({ id: row.accountId, name: row.companyName });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        style={{
          maxWidth: '14rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <AccountLink accountId={row.accountId} account={row}>
          {row.companyName || '-'}
        </AccountLink>
      </Box>

      <EyeButton onClick={handleOpenDrawer} />
    </div>
  );
}

const chipStyling = (row, config) => {
  if (!row.isBOR && row.brokerOfRecord) {
    return {
      color: config.colors.error,
      icon: <CloseIcon />,
    };
  }
  return {
    color: config.colors.cowbellGreen,
    icon: <DoneIcon />,
  };
};

export const BORChip = ({ row }) => {
  const theme = useTheme();
  const chipConfig = chipStyling(row, theme.config);

  if (!row?.isBOR && !row?.brokerOfRecord) {
    return null;
  }

  return (
    <Chip
      style={{
        border: `1px solid ${chipConfig.color}`,
        pointerEvents: 'none',
      }}
      icon={chipConfig.icon}
      label="BOR"
      size="small"
    />
  );
};

export const APIChip = ({ row }) => {
  const theme = useTheme();
  const chipConfig = {
    color: theme.config.colors.cowbellGreen,
    icon: <DoneIcon />,
  };

  if (!row?.isAPI) {
    return '-';
  }

  return (
    <Chip
      style={{
        border: `1px solid ${chipConfig.color}`,
        pointerEvents: 'none',
      }}
      icon={chipConfig.icon}
      label="API"
      size="small"
    />
  );
};

export const AgentNameCell = ({ row }) => {
  const { data: profilePhoto, isInitialLoading: isLoading } = useProfilePhoto({
    email: row.agentEmail,
  });

  if (isLoading) {
    return null;
  }
  if (!row.agentFirstName || !row.agentLastName) {
    return '';
  }

  const agentFullName = `${row.agentFirstName} ${row.agentLastName}`;
  const agentInitials = constructAvatarInitials(agentFullName);

  return (
    <Box display="flex" alignItems="center">
      <Box style={{ height: '2.5rem', width: '2.5rem', margin: '0.3rem' }}>
        <AvatarWithInitialsFallBack
          showProfilePhoto={!!profilePhoto}
          profilePhoto={profilePhoto}
          name={agentFullName}
          initials={agentInitials}
        />
      </Box>
      <div
        style={{
          marginLeft: '0.5rem',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {row.agentFirstName} {row.agentLastName}
      </div>
    </Box>
  );
};

export const getPolicyStatus = (policy) => {
  let { status } = policy;

  const isInReinstatementPeriod = status === CANCELLED_IN_REINSTATEMENT_PERIOD;
  const isCancelPending = status === CANCELLED_PENDING_STATUS;

  if (
    isCancelPending &&
    policy.isNocSent &&
    policy.product !== ProductTypes.p100
  ) {
    status = IN_FORCE_STATUS;
  }

  if (isInReinstatementPeriod) {
    status = CANCELLED_STATUS;
  }

  return status;
};
