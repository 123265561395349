import React from 'react';

// components
import { FormContext, useForm } from 'react-hook-form-4';
import { DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CBButton from '../../../../components/Buttons/CbButton';
import { toggleModalDirect } from '../../../../utils/storeUtils';
import { PubSub } from '../../../../utils/eventUtils';

export const DeleteBankAccountConfirmation = ({ data }) => {
  const classes = modalStyleClasses();
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    const { onDeleteBankAccount } = data;

    if (typeof onDeleteBankAccount === 'function') {
      return onDeleteBankAccount().then(() => {
        toggleModalDirect('DeleteBankAccountConfirmation', false);
        PubSub.publish('deleteBankAccountConfirmation', null);
      });
    }
  };

  const handleCancel = () => {
    toggleModalDirect('DeleteBankAccountConfirmation', false);
  };

  return (
    <section>
      <FormContext>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className={classes.description}>
            Once the bank account information is deleted, automatic monthly
            <br />
            commission payout will stop.
          </p>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="DELETE"
            />
          </DialogActions>
        </form>
      </FormContext>
    </section>
  );
};

const modalStyleClasses = makeStyles(({ palette }) => ({
  description: {
    fontSize: '1.167rem',
    color: palette.primary.main,
    lineHeight: 1.5,
    fontStyle: 'italic',
    textAlign: 'center',
    margin: '0 0 2rem 0 !important',
  },
}));
