import React from 'react';
import { Link as LinkBase, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// mui
import {
  Box,
  DialogContent as MuiDialogContent,
  Divider,
  styled,
  DialogActions,
} from '@mui/material';

// components
import CbButton from '../Buttons/CbButton';

// services and utils
import { issuePolicy } from '../../api';
import {
  useAmplitude,
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES,
} from '../../providers/AmplitudeProvider';
import { useAgencyDashboardFeature } from '../../console/dashboard/agency/agency-dashboard.statics';
import { usePersona } from '../hooks/usePersona';
import { useAPIErrorHandler } from '../hooks/useAPIErrorHandler';
import { PoliciesTabsViewPersonaRoutes } from '../../views/mappings/persona-route/policies-tabs';
import { AGENCY_DASHBOARD_BASE_PATH } from '../../console/dashboard/agency/DashboardViewContainer';

const P100ConfirmIssuePolicy = ({ data: modalData = {}, close }) => {
  const handleAPIError = useAPIErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const amplitude = useAmplitude();
  const agencyDashboardFeature = useAgencyDashboardFeature();
  const persona = usePersona();

  const [isLoading, setIsLoading] = React.useState(false);

  const {
    policyNumber = '',
    companyName = '',
    uiData = {},
  } = modalData?.quoteDetails;

  const quoteDetailsData = [
    { label: 'Limit', value: uiData.limit },
    { label: 'Deductible', value: uiData.deductible },
    { label: 'Premium', value: uiData.premium },
    { label: 'Effective Date', value: uiData.bindQuoteEffectiveDate },
  ];

  const handleIssuePolicy = () => {
    setIsLoading(true);
    issuePolicy({
      persona: persona.type,
      quoteId: uiData.quoteId,
      productType: uiData.productType,
    })
      .then(() => {
        amplitude.track(AMPLITUDE_EVENTS.issuePolicy, {
          [AMPLITUDE_PROPERTIES.quoteId]: modalData.quoteDetails.id,
        });
        enqueueSnackbar('Policy issued successfully', { variant: 'success' });
        close();
        modalData.completeStep(modalData?.step);
        const targetRedirect =
          persona.isAgency && agencyDashboardFeature.on
            ? AGENCY_DASHBOARD_BASE_PATH
            : PoliciesTabsViewPersonaRoutes[persona.type];

        setTimeout(() => history.replace(targetRedirect), 800);
      })
      .catch(handleAPIError('Unable to Issue Policy. Please try again later'))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <DialogContent className="contrast-text">
        <p>
          Are you sure you want to issue <strong>{policyNumber}</strong> for{' '}
          <strong>{companyName}</strong>?
        </p>

        <QuoteDetailsContainer>
          {quoteDetailsData.map(({ label, value }) => (
            <Box display="flex" key={label}>
              <Box width="11rem">{label}:</Box>
              <Box>{value}</Box>
            </Box>
          ))}
        </QuoteDetailsContainer>

        <p>
          <i>NOTE: Your policy will be emailed to you shortly.</i>
        </p>

        <Divider />

        <p>
          If you need to make changes to the material details of the account, it
          will invalidate the binder and a{' '}
          <Link
            onClick={close}
            to={`/agency/requestQuote/P100/${modalData.quoteDetails.accountId}/details`}
          >
            new quote
          </Link>{' '}
          will need to be made.
        </p>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" size="medium" onClick={close}>
          Cancel
        </CbButton>
        <CbButton
          styleName="ctaButton"
          size="medium"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleIssuePolicy}
        >
          Confirm
        </CbButton>
      </DialogActions>
    </>
  );
};

export default P100ConfirmIssuePolicy;

export const P100ConfirmIssuePolicyConfig = {
  P100ConfirmIssuePolicy: {
    component: P100ConfirmIssuePolicy,
    config: {
      maxWidth: 'sm',
      minWidth: 'sm',
    },
  },
};

const DialogContent = styled(MuiDialogContent)({
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1.2rem',
});

const QuoteDetailsContainer = styled(Box)({
  fontSize: '1.2rem',
  fontWeight: 600,
  textAlign: 'left',
  display: 'inline-block',
  margin: '0 auto',
});

export const Link = styled(LinkBase)(({ theme }) => ({
  textDecoration: 'underline',
  cursor: 'pointer',
  color: theme.config.colors.cowbellBlue,
  '&:hover': {
    opacity: 0.7,
  },
}));
