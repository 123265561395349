import { Box, DialogContentText, DialogTitle, Typography } from '@mui/material';

import CbButton from '../../../../components/Buttons/CbButton';
import { DialogContent, DialogFooter } from '../helpers';

import { CreateEditP500FlowSteps } from './config';

const PolicyGeneratedConfirmation = ({
  stepperData,
  confirmationMessage,
  ...props
}) => {
  function handleCancel() {
    props.onClose();
  }

  function handleUploadPolicyDocument() {
    stepperData.jumpTo(CreateEditP500FlowSteps.POLICY_DOC_UPLOAD_STEP);
  }

  return (
    <>
      <DialogTitle>
        <Box>Generate PrimeCloud Policy</Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center', marginBottom: '0' }}>
          <Typography
            variant="h3"
            component="span"
            style={{
              fontSize: '1.5rem',
              fontWeight: 600,
            }}
          >
            {confirmationMessage}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogFooter
        stepNumber={stepperData.stepNumber}
        totalSteps={stepperData.steps.length}
        stepLabel="Policy Generation Confirmation"
      >
        <CbButton onClick={handleCancel} styleName="cancel">
          I'm done for now
        </CbButton>
        <CbButton
          styleName="ctaButton"
          size="fit-content"
          onClick={handleUploadPolicyDocument}
        >
          Upload Policy Document
        </CbButton>
      </DialogFooter>
    </>
  );
};

export default PolicyGeneratedConfirmation;
