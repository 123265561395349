import type { AxiosError } from 'axios';
import type { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { saveBlobFile } from './appUtils';
import { downloadAWSDoc } from '../policies/PolicyService';
import { manageAPIError } from './error.utils';

interface DownloadDocumentParams {
  asyncDownload: () => Promise<{ data: string }>;
  blobFileParams: {
    docName: string;
    docType: string;
  };
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey;
  onError?: () => void;
}

export const downloadDocument = async ({
  asyncDownload,
  blobFileParams,
  enqueueSnackbar,
  onError,
}: DownloadDocumentParams) => {
  return asyncDownload()
    .then(({ data: url }) => {
      enqueueSnackbar('Download started, file will be available shortly', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'info',
      });

      downloadAWSDoc(url).then((response) =>
        saveBlobFile(response, blobFileParams.docName, blobFileParams.docType)
      );
    })
    .catch((error: AxiosError) => {
      if (typeof onError === 'function') {
        return onError();
      }
      enqueueSnackbar(manageAPIError(error), { variant: 'error' });
    });
};
