import React from 'react';

const SVG = ({
  id = 'quotes',
  style = {},
  fill = '#9192a4',
  width = '100%',
  className = '',
  viewBox = '0 0 30 30',
  title = 'Quotes',
}) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .svg-quotes-1 { fill: none; }
          .${id}.svg-fill-quotes { fill: ${fill}; }
        `}
      </style>
    </defs>
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Isolation_Mode" data-name="Isolation Mode">
        <rect className="svg-quotes-1" width="30" height="30" />
        <path
          className={`${id} svg-fill-quotes`}
          d="M21.72,5.44a2.65,2.65,0,0,0-.63-.07h-.81a.94.94,0,0,0-.87-.58H17.55a2.34,2.34,0,0,0-4.68,0H10.61a.94.94,0,0,0-.87.58H8.91a3.1,3.1,0,0,0-3.1,3.1v16a3.1,3.1,0,0,0,3.1,3.1H21.09a3.28,3.28,0,0,0,.63-.06,3.1,3.1,0,0,0,2.47-3v-16A3.09,3.09,0,0,0,21.72,5.44Zm-14.35,19v-16A1.53,1.53,0,0,1,8.91,6.94h.85a.93.93,0,0,0,.85.54h8.8a.93.93,0,0,0,.85-.54h.83a1.53,1.53,0,0,1,1.54,1.53v16A1.54,1.54,0,0,1,21.09,26H8.91A1.54,1.54,0,0,1,7.37,24.46Z"
        />
        <path
          className={`${id} svg-fill-quotes`}
          d="M9.78,17h3.87c.32,0,.58-.38.58-.85s-.26-.85-.58-.85H9.78c-.32,0-.58.38-.58.85S9.46,17,9.78,17Z"
        />
        <path
          className={`${id} svg-fill-quotes`}
          d="M10.05,12.54h9.71a.85.85,0,1,0,0-1.69H10.05a.85.85,0,1,0,0,1.69Z"
        />
        <path
          className={`${id} svg-fill-quotes`}
          d="M19.46,17.29a4.83,4.83,0,0,0-1.19,1.19,30.19,30.19,0,0,0-2.16,3.64,3.37,3.37,0,0,0-.47-1.27.88.88,0,0,0-.74-.36,2.64,2.64,0,0,0-1.63.77,3.72,3.72,0,0,1,1.35,1.6l.2.4c.07.14.15.33.25.56l.33.76c0,.07.11.24.23.53a36.15,36.15,0,0,1,3.21-4.92,13.86,13.86,0,0,1,3.05-3,3.63,3.63,0,0,0-1.12-.2A2.52,2.52,0,0,0,19.46,17.29Z"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
