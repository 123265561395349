import type { ProductType } from '../../types';
import { ProductTypes } from '../../types';
import { languages, type Languages } from '../i18n.language-config';

const gbpFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const currencyFormatter = {
  gbp: gbpFormatter,
  usd: usdFormatter,
};

type Options = {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  placeholder?: string;
};

const numberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const NO_DECIMAL_FORMAT = {
  minimumFractionDigits: 0,
};

const currencyFormaterByLanguage = {
  [languages['en-GB']]: gbpFormatter,
  [languages['en-US']]: usdFormatter,
};

export const deriveCurrencyFormat = (
  language: string,
  input: number | string | undefined | null,
  options?: Options
) => {
  if (input == null) return options?.placeholder || '-';

  const _options = { ...numberFormatOptions, ...options };

  const updatedOptions = Object.assign(
    {},
    (
      currencyFormaterByLanguage[
        language as keyof typeof currencyFormaterByLanguage
      ] || usdFormatter
    ).resolvedOptions(),
    _options
  );

  return new Intl.NumberFormat(undefined, updatedOptions).format(Number(input));
};
