import React from 'react';
import { SvgIcon } from '@mui/material';

export default function StartNewQuoteIconDark({ fill = '#cacdd9', ...props }) {
  return (
    <SvgIcon viewBox="0 0 44 46" {...props}>
      <path
        id="Path_7838"
        data-name="Path 7838"
        d="M35.523,17.761A17.761,17.761,0,1,1,17.761,0,17.761,17.761,0,0,1,35.523,17.761"
        fill="#b1c2de"
      />
      <path
        id="Path_7839"
        data-name="Path 7839"
        d="M42.462,9l5.92,5.92V47.483H21V9Z"
        transform="translate(-5.459 -2.34)"
        fill="#465266"
      />
      <path
        id="Path_7840"
        data-name="Path 7840"
        d="M55.66,15.66H49V9h.74l5.92,5.92Z"
        transform="translate(-12.738 -2.34)"
        fill="#465266"
      />
      <path
        id="Path_7841"
        data-name="Path 7841"
        d="M48.122,47.963H20.74a.74.74,0,0,1-.74-.74V37.6a.74.74,0,1,1,1.48,0v8.881h25.9V14.967L41.9,9.48H21.48v14.8a.74.74,0,1,1-1.48,0V8.74A.74.74,0,0,1,20.74,8H42.2a.74.74,0,0,1,.523.217l5.92,5.92a.74.74,0,0,1,.217.523V47.223A.74.74,0,0,1,48.122,47.963Z"
        transform="translate(-5.199 -2.08)"
        fill="#b1c2de"
      />
      <path
        id="Path_7842"
        data-name="Path 7842"
        d="M52.44,17.18h-3.7a.74.74,0,0,1-.74-.74v-3.7a.74.74,0,1,1,1.48,0V15.7h2.96a.74.74,0,0,1,0,1.48Z"
        transform="translate(-12.477 -3.119)"
        fill="#b1c2de"
      />
      <path
        id="Path_7843"
        data-name="Path 7843"
        d="M26.181,44.158s.035-2.925-1.627-4.588l-14.2-14.2a2.123,2.123,0,0,0-2.979-.141,2.268,2.268,0,0,0,.019,3.1l14.2,14.2c1.662,1.662,4.588,1.627,4.588,1.627"
        transform="translate(-1.76 -6.416)"
        fill="#b1c2de"
      />
      <path
        id="Path_7844"
        data-name="Path 7844"
        d="M25.907,44.638a7.876,7.876,0,0,1-5.1-1.844l-14.2-14.2a3.01,3.01,0,0,1-.019-4.147,2.629,2.629,0,0,1,1.876-.763,3.07,3.07,0,0,1,2.151.9l14.2,14.2c1.861,1.861,1.846,4.988,1.844,5.12l-.009.721ZM8.463,25.159a1.15,1.15,0,0,0-.829.33,1.53,1.53,0,0,0,.019,2.054l14.2,14.2A5.709,5.709,0,0,0,25.126,43.1a5.716,5.716,0,0,0-1.355-3.269l-14.2-14.2A1.58,1.58,0,0,0,8.463,25.159Z"
        transform="translate(-1.5 -6.156)"
        fill="#465266"
      />
      <path
        id="Line_39"
        data-name="Line 39"
        d="M.34,3.3-.707,2.252,2.252-.707,3.3.339Z"
        transform="translate(8.195 21.516)"
        fill="#465266"
      />
      <path
        id="Line_40"
        data-name="Line 40"
        d="M4.18.48H-.26A.74.74,0,0,1-1-.26.74.74,0,0,1-.26-1H4.18a.74.74,0,0,1,.74.74A.74.74,0,0,1,4.18.48Z"
        transform="translate(30.602 38.002)"
        fill="#b1c2de"
      />
      <path
        id="Line_41"
        data-name="Line 41"
        d="M12.321.48H-.26A.74.74,0,0,1-1-.26.74.74,0,0,1-.26-1H12.321a.74.74,0,0,1,.74.74A.74.74,0,0,1,12.321.48Z"
        transform="translate(23.201 20.98)"
        fill="#b1c2de"
      />
      <path
        id="Line_42"
        data-name="Line 42"
        d="M12.321.48H-.26A.74.74,0,0,1-1-.26.74.74,0,0,1-.26-1H12.321a.74.74,0,0,1,.74.74A.74.74,0,0,1,12.321.48Z"
        transform="translate(23.201 23.941)"
        fill="#b1c2de"
      />
      <path
        id="Line_43"
        data-name="Line 43"
        d="M7.881.48H-.26A.74.74,0,0,1-1-.26.74.74,0,0,1-.26-1H7.881a.74.74,0,0,1,.74.74A.74.74,0,0,1,7.881.48Z"
        transform="translate(23.201 26.902)"
        fill="#b1c2de"
      />
      <path
        id="Line_44"
        data-name="Line 44"
        d="M.48,4.44H-1V0H.48Z"
        transform="translate(10.621 36.262)"
        fill="#b1c2de"
      />
      <path
        id="Line_45"
        data-name="Line 45"
        d="M4.44.48H0V-1H4.44Z"
        transform="translate(8.141 38.742)"
        fill="#b1c2de"
      />
      <path
        id="Line_46"
        data-name="Line 46"
        d="M.48,4.44H-1V0H.48Z"
        transform="translate(3.961 31.822)"
        fill="#b1c2de"
      />
      <path
        id="Line_47"
        data-name="Line 47"
        d="M4.44.48H0V-1H4.44Z"
        transform="translate(1.48 34.303)"
        fill="#b1c2de"
      />
      <path
        id="Line_48"
        data-name="Line 48"
        d="M.48,4.44H-1V0H.48Z"
        transform="translate(10.621 9.621)"
        fill="#465266"
      />
      <path
        id="Line_49"
        data-name="Line 49"
        d="M4.44.48H0V-1H4.44Z"
        transform="translate(8.141 12.102)"
        fill="#465266"
      />
    </SvgIcon>
  );
}
