import React from 'react';
import TextFieldBase from './text-fields/base/TextFieldBase';
import { CleaveBase } from './CleaveBase';

const USPhoneField = (props: React.ComponentProps<typeof TextFieldBase>) => {
  return (
    <>
      <TextFieldBase inputComponent={CleaveInput} {...props} />
    </>
  );
};

export default USPhoneField;

type CleaveInputProps = {
  inputRef: React.Ref<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

const CleaveInput: React.FC<CleaveInputProps> = ({ inputRef, ...props }) => {
  return (
    <CleaveBase
      ref={inputRef}
      options={{
        delimiters: ['(', ') ', '-', '-'],
        blocks: [0, 3, 3, 4],
      }}
      {...props}
    />
  );
};
