import React from 'react';

const SVG = ({
  id = 'GIFIcon',
  style = {},
  fill = '#fff',
  className = '',
  viewBox = '0 0 20 30',
  title = 'GIFIcon',
}) => (
  <svg
    fill={fill}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
        .cls-1{fill:#fff}.cls-2{fill:#848280}
        `}
      </style>
    </defs>
    <title>{title}</title>
    <g>
      <path
        fill="#fff"
        d="M16.34.15H1.99a.7.7 0 0 0-.7.725V28.7a.7.7 0 0 0 .7.725h20.7a.725.725 0 0 0 .725-.725V7.275a.75.75 0 0 0-.2-.5L16.84.375a.675.675 0 0 0-.5-.225z"
        transform="translate(1.935 .225)"
      />
      <path fill="none" d="M0 0H28.55V30H0z" />
      <path
        fill="#848280"
        d="M23.765 7.08a.625.625 0 0 0-.15-.4L17.54.08h-15a1.225 1.225 0 0 0-1.25 1.175V28.7a1.225 1.225 0 0 0 1.25 1.18h20a1.2 1.2 0 0 0 1.225-1.18zm-13.2 20.775a8.525 8.525 0 0 1-2.5.4 4.3 4.3 0 0 1-2.95-.875 3.4 3.4 0 0 1-1.075-2.5 3.8 3.8 0 0 1 4.275-3.9 5.4 5.4 0 0 1 2.1.35l-.375 1.27a4.65 4.65 0 0 0-1.725-.225 2.225 2.225 0 0 0-2.5 2.3A2.2 2.2 0 0 0 8.19 27.03a2.775 2.775 0 0 0 .825 0v-1.5h-1.15v-1.25h2.7v3.575zm3.025.325h-1.625v-7.125h1.7zm6.125-5.8H16.84V24h2.7v1.3h-2.7v2.88h-1.7v-7.125h4.575zm-17.5-2.5V1.055h14.25V7.08a.6.6 0 0 0 .625.575h5.45V19.88z"
        transform="translate(1.935 .12)"
      />
      <path
        fill="#309e6d"
        d="M14.19 13.48l-3.2-3.625h1.775L9.815 6.53h1.85l-2.45-2.75h1.125l-1.85-2.1-1.7 2.1h1.15L5.715 6.53H7.54l-2.7 3.325h1.675L3.59 13.48h4.9v4.375h.8V13.48z"
        transform="translate(5.385 2.52)"
      />
    </g>
  </svg>
);

export default SVG;
