import { ServiceHandlerFactory } from '../utils/serviceUtils';

/*
  No body
*/
export const startNoc = ServiceHandlerFactory({
  axios: {
    url: '/api/billing/v1/trigger/noc/:policyId',
    method: 'post',
  },
});

export const stopNoc = ServiceHandlerFactory({
  axios: {
    url: 'api/policy/v1/noc/stop/:policyId',
    method: 'put',
  },
});

export const extendNocCancellationTriggerDate = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/noc/extend-cancellation-trigger-date/:policyId',
    method: 'put',
  },
});

export const extendPaymentDueDate = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/noc/extend-due-date/:policyId',
    method: 'put',
  },
});
