import { Avatar as AvatarBase, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getProfilePhotoByEmail } from '../../api/ImageService';

export const AvatarWithInitialsFallBack = ({
  showProfilePhoto,
  profilePhoto,
  name,
  initials,
  InitialsFallback = InitialsAvatar,
}) =>
  showProfilePhoto ? (
    <StyledAvatar src={profilePhoto} />
  ) : (
    <InitialsFallback name={name} initials={initials} />
  );

export const constructAvatarInitials = (createdBy) => {
  const createdBySplit = createdBy.split(' ');
  const firstName = createdBySplit[0];
  const lastName = createdBySplit.length > 1 ? createdBySplit[1] : undefined;

  return lastName ? `${firstName[0]}${lastName[0]}` : firstName.substring(0, 2);
};

const StyledAvatar = styled(AvatarBase)(() => ({
  width: '100%',
  height: '100%',
}));

const StyledInitialsAvatar = styled(AvatarBase)(({ theme }) => ({
  backgroundColor: theme.palette.common.cowbellGreen,
  fontSize: '1rem',
  textTransform: 'uppercase',
  width: '100%',
  height: '100%',
}));

export const InitialsAvatar = ({ initials, name }) => {
  return <StyledInitialsAvatar alt={name}>{initials}</StyledInitialsAvatar>;
};

export const useProfilePhoto = ({ email }) => {
  return useQuery(
    ['profile-photo', email],
    () => getProfilePhotoByEmail(email).then(({ data }) => data),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!email,
    }
  );
};
