import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { WithChildrenProps } from '../../types/components/common';

// TODO: Extend to allow watching of specific fields & Utilize RHF types to leverage IDE suggestions

const FormWatcher = (props: WithChildrenProps) => {
  const form = useFormContext();

  const { watch } = form;
  React.useEffect(() => {
    watch();
  }, [watch]);

  return <>{props.children}</>;
};

export default FormWatcher;
