import React from 'react';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import {
  uploadDocuments,
  uploadDocumentV3,
} from '../../../../../api/documents.api';
import useCompleteQuoteSubjectivity from './useCompleteQuoteSubjectivity';

const useDocUpload = ({ quoteDetails }) => {
  const [isUploadingDoc, setIsUploadingDoc] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { completeSubjectivity } = useCompleteQuoteSubjectivity({
    quoteId: quoteDetails.uiData.quoteId,
    productType: quoteDetails.uiData.productType,
  });

  const uploadDocument = (params) => {
    const {
      completeStep,
      goForward,
      step,
      docType,
      formValues,
      files,
      quoteSubjectivityId,
      userAttested = null,
      nkllIsIncluded,
    } = params;

    setIsUploadingDoc(true);

    const completeSubjectivityParams = {
      quoteSubjectivityId,
      completeStep,
      goForward,
      step,
    };

    const uploadParams = {
      accountId: quoteDetails.accountId,
      agencyId: quoteDetails.agencyId,
      docType,
      docName: formValues[`fileName-0`],
      temp: true,
    };

    const formData = new FormData();
    formData.append('file', files[0]);

    return uploadDocumentV3({
      data: formData,
      params: {
        ...uploadParams,
      },
    })
      .then(({ data = {} }) => {
        const payload = {
          uploadedDocuments: [
            {
              docId: data.docId,
              docSignedOn: formValues[`docSignedOn-0`],
            },
          ],
          nkllIsIncluded,
          signeeFullName: formValues.signeeFullName || null,
          signeeTitle: formValues.signeeTitle || null,
          userAttested,
        };
        completeSubjectivity({ payload, ...completeSubjectivityParams });
        enqueueSnackbar('Successfully uploaded document.', {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar(
          _.get(
            err,
            'message',
            'Unable to upload document at this time. Please try again later.'
          ),
          { variant: 'error' }
        );
      })
      .finally(() => {
        setIsUploadingDoc(false);
      });
  };

  const uploadMultipleDocuments = (params) => {
    setIsUploadingDoc(true);
    const {
      completeStep,
      goForward,
      step,
      formValues,
      files,
      yearsOfPreviousCoverage = null,
      quoteSubjectivityId,
      diligentSearchReportExemption = null,
      hasPreviousCoverage = null,
      userAttested = null,
      docTypes,
      paragraph = null,
    } = params;

    const docNames = files.map(
      (_file, index) => formValues[`fileName-${index}`]
    );

    const uploadParams = {
      accountId: quoteDetails.accountId,
      agencyId: quoteDetails.agencyId,
      docTypes,
      formTypes: Array(files.length).fill(null),
      docNames,
      temp: true,
    };

    const completeSubjectivityParams = {
      quoteSubjectivityId,
      completeStep,
      goForward,
      step,
    };

    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));
    formData.append('documentsUploadRequest', JSON.stringify(uploadParams));

    uploadDocuments({
      data: formData,
    })
      .then(({ data }) => {
        const uploadedDocuments = data.successfulUploads.map(
          ({ docId }, index) => ({
            docId,
            docSignedOn: formValues[`docSignedOn-${index}`],
          })
        );
        const payload = {
          uploadedDocuments,
          hasPreviousCoverage,
          yearsOfPreviousCoverage,
          userAttested,
          diligentSearchReportExemption,
          signeeFullName: formValues.signeeFullName || null,
          signeeTitle: formValues.signeeTitle || null,
          paragraph,
        };

        completeSubjectivity({ payload, ...completeSubjectivityParams });

        enqueueSnackbar('Successfully uploaded documents.', {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar(
          _.get(
            err,
            'message',
            'Unable to upload document at this time. Please try again later.'
          ),
          { variant: 'error' }
        );
      })
      .finally(() => {
        setIsUploadingDoc(false);
      });
  };

  return {
    uploadDocument,
    isUploadingDoc,
    uploadMultipleDocuments,
  };
};

export default useDocUpload;
