import { LAYOUT_COMPRESSED } from '../../reducers/UiSettingsReducers';

import type { RootStore } from '../../types/global-store';

export const selectGrowthbook = (state: RootStore) =>
  state.uiSettings.growthBook;

export const selectNewAgentExperienceLayout = (state: RootStore) => {
  return state.uiSettings.new_agent_experience?.layout || LAYOUT_COMPRESSED;
};

export const selectUserLanguagePreferences = (state: RootStore) => {
  return state.uiSettings.platformLanguage;
};
