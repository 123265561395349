const { REACT_APP_ENV } = import.meta.env;

export const LOCAL_ENV = 'local';
export const PRODUCTION_ENV = 'production';
export const DEVELOPMENT_ENV = 'development';

type Env = typeof LOCAL_ENV | typeof PRODUCTION_ENV | typeof DEVELOPMENT_ENV;
export type GetEnvResult = {
  ENV: Env;
  IS_LOCAL: boolean;
  IS_PRODUCTION: boolean;
};

((cowbell = {} as typeof window.cowbell) => {
  // setup environment
  const env = {
    ENV: REACT_APP_ENV as Env,
    IS_LOCAL: REACT_APP_ENV === LOCAL_ENV,
    IS_PRODUCTION: REACT_APP_ENV === PRODUCTION_ENV,
  } as const;

  cowbell.getEnv = function () {
    return env;
  };

  /* prepare features */
  let features = Object.keys(import.meta.env)
    .filter((envar) => envar.indexOf('REACT_APP_FEATURE') !== -1)
    .map((str) => str.split('REACT_APP_FEATURE_')[1])
    .reduce(reduceAvailableFeatures, {});

  const originalFeatures = { ...features };

  cowbell.getFeatures = function () {
    return features;
  };

  if (!env.IS_PRODUCTION) {
    const savedFeatures = JSON.parse(localStorage.getItem('fts') || '{}');

    features = { ...features, ...savedFeatures };

    cowbell.toggleFeature = function (feature) {
      if (features[feature]) {
        // toggle the ff
        features[feature] = !features[feature];
        // save what you toggled
        cowbell.saveFeatures();
      }
    };

    cowbell.addFeature = function (feature) {
      features[feature] = true;
      cowbell.saveFeatures();
    };

    cowbell.saveFeatures = function () {
      localStorage.setItem('fts', JSON.stringify(features));
    };

    cowbell.resetFeatures = function () {
      localStorage.removeItem('fts');
      features = originalFeatures;
    };

    cowbell.toggleAmplitudeTesting = function () {
      const isAmplitudeTestingEnabled: boolean =
        JSON.parse(
          localStorage.getItem('isAmplitudeTestingEnabled') as string
        ) ?? 'false';
      const nextVal = (!isAmplitudeTestingEnabled).toString();

      localStorage.setItem('isAmplitudeTestingEnabled', nextVal);

      return nextVal;
    };

    cowbell.getIsAmplitudeTestingEnabled = function () {
      return (
        JSON.parse(
          localStorage.getItem('isAmplitudeTestingEnabled') as string
        ) ?? 'false'
      );
    };
  }

  window.cowbell = cowbell;
})(window.cowbell);

function reduceAvailableFeatures(acc: Record<string, boolean>, key: string) {
  const value = import.meta.env[`REACT_APP_FEATURE_${[key]}`];

  return {
    ...acc,
    [key]: value === '1',
  };
}
