import React from 'react';
// ui
// components
import { FormContext, useForm } from 'react-hook-form-4';
import { DialogContent, Grid as MuiGrid, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import Moment from 'moment';
import CBButton from '../../../components/Buttons/CbButton';
import { withFormController } from '../../../components/hocs/withFormController';
import { delayedEvent } from '../../../utils/eventUtils';
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import { updateLead } from '../sales.service';
import LanguageCurrencyFieldBase from '../../../components/inputs/LanguageCurrencyField';

const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);
const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  targetDate: Yup.date().required('EffectiveDate is required.'),
  targetPremium: Yup.number()
    .label('Target Premium')
    .fromCurrency()
    .min(0, '${label} cannot be less than $0'),
});

export const TargetEffDatePremium = ({ data, ...props }) => {
  const classes = useClasses();
  const { id, targetDate, avgPremium } = data;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    targetDate: targetDate ? Moment.utc(targetDate).format('YYYY-MM-DD') : '',
    targetPremium: avgPremium,
  };
  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    validationSchema: schema,
  });
  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (formData) => {
    return updateLead({ id }, { accountId: data?.accountId, ...formData })
      .then(() => {
        enqueueSnackbar('Lead updated Successfully!', { variant: 'success' });
        delayedEvent('table-refetch', 4000, 'SalesInbox');
        props.close();
      })
      .catch(() => {
        enqueueSnackbar('Failed to update the lead', { variant: 'error' });
      });
  };

  const handleCancel = () => {
    props.close();
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent classes={{ root: classes.container }}>
          <MuiGrid container spacing={4}>
            <MuiGrid item md={6}>
              <TextField
                name="targetDate"
                label="Target eff. date"
                required
                type="date"
                inputProps={{ min: Moment().format('YYYY-MM-DD') }}
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <LanguageCurrencyField
                name="targetPremium"
                label="Target Premium ($)"
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <DialogActions>
          <CBButton action={handleCancel} styleName="cancel">
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="OK"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  container: {
    maxHeight: '45rem',
    overflow: 'auto',
    paddingBottom: '1rem',
    color: palette.primary.contrastText,
  },
}));

export const TargetEffDatePremiumConfig = {
  TargetEffDatePremium: {
    component: TargetEffDatePremium,
    config: {
      minWidth: 'sm',
      title: 'Target eff. Date / Target Premium',
    },
  },
};
