export const radioOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const selectOptions = [
  { label: 'Weekly or more', value: 'WEEKLY' },
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Quarterly', value: 'QUARTERLY' },
  { label: 'Every 6 Months', value: 'SIX_MONTHS' },
  { label: 'Never', value: 'NEVER' },
];

export const agencyLevels = [
  { label: 'SELECT', value: 'SELECT' },
  { label: 'PREFERRED', value: 'PREFERRED' },
  { label: 'PREFERRED_1', value: 'PREFERRED_1' },
  { label: 'PREFERRED_5', value: 'PREFERRED_5' },
  { label: 'PREFERRED_10', value: 'PREFERRED_10' },
  { label: 'PREMIER', value: 'PREMIER' },
];
