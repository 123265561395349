import React from 'react';
import { useHistory } from 'react-router-dom';
// mui
import {
  IconButton as MuiIconButton,
  MenuItem as MenuItemBase,
} from '@mui/material';
// helpers
import qs from 'qs';
import Moment from 'moment';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
// icons
import TableMenuIcon from '../../_assets/svg/TableMenuIcon.svg';
// components
import { SimpleMenu } from '../../components/Menus/SimpleMenu';
import { ProtectedMenuItem as ProtectedMenuItemBase } from '../../console/agencies/inbox/table/ProtectedMenuItem';
import { withShowable } from '../../console/_global/lib/withShowable';
import { Modal } from '../../components/modals/v2/helpers/v2.modal.helpers';
// actions
import {
  downloadQuoteDocuments,
  downloadBinder,
  fetchP250QuotePageDetails,
} from '../QuotesService';
import {
  downloadAWSDoc,
  downloadInvoice,
  downloadReceipt,
} from '../../policies/PolicyService';
import {
  adminPaymentComplete,
  getBalanceOnPolicy,
} from '../../console/customers/_services/BillingService';
import { getQuoteDetails } from '../../api/p250.service';
import { fetchAccountDetailsById } from '../../accounts/AccountService';
// utils
import { loadOneIncByPolicyNumber, saveBlobFile } from '../../utils/appUtils';
import { delayedEvent } from '../../utils/eventUtils';
import { toggleModalDirect } from '../../utils/storeUtils';
import { ProductTypes } from '../../types';
import { getIsRenewalOrMigration } from '../../utils';
import { toUniversalUtcDate } from '../../utils/date.utils';
import { BINDER_RESCINDED } from '../../console/_statics/quote.statics';

const MenuItem = withShowable(MenuItemBase);
const ProtectedMenuItem = withShowable(ProtectedMenuItemBase);

export const AgencyBindersMoreOption = ({
  data: binder,
  setShowCircular,
  makeRefreshCall,
  isJQueryLoaded,
}) => {
  const menuOptionHandlers = useAgencyBinderMenuOptionHandlers(
    binder,
    makeRefreshCall,
    setShowCircular
  );

  return (
    <SimpleMenu
      id="mangeAgencyBinders"
      anchor={
        <MuiIconButton size="small" aria-haspopup="true">
          <TableMenuIcon fontSize="small" />
        </MuiIconButton>
      }
    >
      <ProtectedMenuItem
        show={
          !!binder.quoteId &&
          binder.product === ProductTypes.p250 &&
          !!binder.externalSignUrl
        }
        onClick={menuOptionHandlers.handleCopyApplicationLink}
      >
        Application Link
      </ProtectedMenuItem>

      <ProtectedMenuItem
        show={
          !binder.isPaid &&
          binder.totalPremium > 0 &&
          binder.status === 'BOUND' &&
          binder.product === ProductTypes.p250
        }
        onClick={menuOptionHandlers.handleChargeCreditCardOrBankAccount('')}
        allowIfAll={[{ account: 'agency' }, { role: 'admin' }]}
        disabled={!isJQueryLoaded}
      >
        Charge Bank Account
      </ProtectedMenuItem>

      <ProtectedMenuItem
        show={
          !binder.isPaid &&
          binder.totalPremium > 0 &&
          binder.status === 'BOUND' &&
          binder.product === ProductTypes.p250
        }
        onClick={menuOptionHandlers.handleChargeCreditCardOrBankAccount(
          'creditCard'
        )}
        allowIfAll={[{ account: 'agency' }, { role: 'admin' }]}
        disabled={!isJQueryLoaded}
      >
        Charge Credit Card
      </ProtectedMenuItem>

      <ProtectedMenuItem
        show={!!binder.quoteId && !binder.isEndorsement}
        onClick={menuOptionHandlers.handleCreateQuoteFromBinder}
      >
        Create Quote from Binder
      </ProtectedMenuItem>

      <ProtectedMenuItem
        show={!!binder.quoteId && !binder.isPrimePlus}
        onClick={menuOptionHandlers.handleDownloadApplication}
      >
        Download Application
      </ProtectedMenuItem>

      <MenuItem
        show={!!binder.quoteId && binder.status !== BINDER_RESCINDED}
        onClick={menuOptionHandlers.handleDownloadBinder}
      >
        Download Binder
      </MenuItem>

      <ProtectedMenuItem
        allow={{ policy: 'view' }}
        show={binder.status !== BINDER_RESCINDED}
        disabled={!binder.isInvoiceAvailable}
        onClick={menuOptionHandlers.handleDownloadInvoice}
      >
        Download Invoice
      </ProtectedMenuItem>

      <ProtectedMenuItem
        allow={{ policy: 'view' }}
        show={binder.isPaid}
        disabled={!binder.isDocumentAvailable}
        onClick={menuOptionHandlers.handleDownloadReceipt}
      >
        Download Receipt
      </ProtectedMenuItem>

      <ProtectedMenuItem
        show={binder.status === 'BOUND'}
        allow={{ policy: 'manage' }}
        onClick={menuOptionHandlers.handleRescindBinder}
      >
        Rescind Binder
      </ProtectedMenuItem>

      <ProtectedMenuItem
        show={!!binder.quoteId && binder.status === 'BOUND'}
        onClick={menuOptionHandlers.handleIssuePolicy}
      >
        Issue Policy
      </ProtectedMenuItem>

      <ProtectedMenuItem
        show={
          !!binder.quoteId &&
          !['BINDER EXPIRED', 'RESCIND'].includes(binder.status)
        }
        onClick={menuOptionHandlers.handleSendBinderToPolicyHolder}
      >
        Send Binder to Policy Holder
      </ProtectedMenuItem>
    </SimpleMenu>
  );
};

export const useAgencyBinderMenuOptionHandlers = (
  binder,
  makeRefreshCall,
  setShowCircular
) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleRescindBinder = React.useCallback(
    (event) => {
      event.stopPropagation();
      Modal.show('RescindBinder', {
        data: { ...binder, makeRefreshCall },
        config: { title: `Rescind Binder for ${binder.companyName}` },
      });
    },
    [binder, makeRefreshCall]
  );

  const handleDownloadBinder = React.useCallback(() => {
    const { quoteId, accountId, quoteNumber, companyName, boundOn } = binder;

    downloadBinder(accountId, quoteId, Moment(boundOn).unix())
      .then(({ data }) => downloadAWSDoc(data))
      .then((response) =>
        saveBlobFile(
          response,
          `${companyName}-${quoteNumber}-Cowbell-Binder`,
          '.pdf'
        )
      )
      .catch(() =>
        enqueueSnackbar('Unable to dowload binder', { variant: 'error' })
      );
  }, [enqueueSnackbar, binder]);

  const handleDownloadApplication = React.useCallback(() => {
    const { quoteId, companyName, accountId, boundOn, quoteNumber } = binder;
    const isEndorsement = _.get(binder, 'isEndorsement', false);

    downloadQuoteDocuments(
      accountId,
      quoteId,
      Moment(boundOn).unix(),
      isEndorsement
    )
      .then(({ data }) => downloadAWSDoc(data))
      .then((response) =>
        saveBlobFile(
          response,
          `${companyName}-${quoteNumber}-Cowbell-Application`,
          '.pdf'
        )
      )
      .catch(() =>
        enqueueSnackbar('Unable to dowload application', { variant: 'error' })
      );
  }, [enqueueSnackbar, binder]);

  const handleDownloadReceipt = React.useCallback(
    (event) => {
      event.stopPropagation();
      const {
        accountId,
        id: policyId,
        created,
        policyNumber,
        isEndorsement,
      } = binder;

      downloadReceipt(
        accountId,
        policyId,
        Moment(created).unix(),
        isEndorsement
      )
        .then(({ data: url }) => {
          downloadAWSDoc(url)
            .then((resp) => {
              saveBlobFile(resp, `${policyNumber}-Cowbell-Receipt`, '.pdf');
            })
            .catch(() => console.error('Unable to download liki the Receipt'));
        })
        .catch(() => console.error('Unable to download lala the Receipt'));
    },
    [binder]
  );

  const handleDownloadInvoice = React.useCallback(
    (event) => {
      event.stopPropagation();

      const {
        accountId,
        id: policyId,
        created,
        policyNumber,
        isEndorsement,
      } = binder;

      downloadInvoice(
        accountId,
        policyId,
        Moment(created).unix(),
        true,
        isEndorsement
      )
        .then((response) => {
          const url = _.get(response, 'data');

          downloadAWSDoc(url)
            .then((resp) => {
              saveBlobFile(resp, `${policyNumber}-Cowbell-Invoice`, '.pdf');
            })
            .catch(console.error.bind(console));
        })
        .catch(() =>
          enqueueSnackbar('Unable to download the Invoice', {
            variant: 'error',
          })
        );
    },
    [binder, enqueueSnackbar]
  );

  const handleChargeCreditCardOrBankAccount = React.useCallback(
    (type) => async () => {
      const paymentCategory = type === 'creditCard' ? 'CreditCard' : 'ECheck';

      const { data: oneIncKey } = await loadOneIncByPolicyNumber(
        binder.policyNumber
      ).catch(() => 1);

      getBalanceOnPolicy({ policyId: binder.id })
        .then((getBalanceOnPolicyResp) => {
          const balance = getBalanceOnPolicyResp.data;

          if (typeof setShowCircular === 'function') {
            setShowCircular(true);
          }

          window.$('#portalOneContainer').on('portalOne.load', function () {
            if (typeof setShowCircular === 'function') {
              setShowCircular(false);
            }
          });

          window.$('#portalOneContainer').on('portalOne.unload', function () {
            delayedEvent('table-refetch', 500, 'agencyPolicies');
          });

          const paymentCompleteHandler = function (e, d) {
            if (d.acknowledge) {
              d.acknowledge();
            }

            const payload = {
              transactionId: d.transactionId,
              transactionDate: Moment(d.transactionDate).valueOf(),
              paymentAmount: d.paymentAmount,
              totalPaymentAmount: d.totalPaymentAmount,
              policyId: binder.id,
              policyNumber: binder.policyNumber,
              lastFourDigits: _.get(d, 'transactions[0].lastFourDigits', ''),
              customerEmail: binder.customerEmail,
              quoteId: binder.quoteId,
              agencyId: binder.agencyId,
              policyCreated: binder.created,
              bankName: d.bankName || '',
              cardType: d.cardType || '',
            };
            const { accountId } = binder;

            adminPaymentComplete({ accountId }, payload)
              .then(() => {})
              .catch(() => {
                enqueueSnackbar('Failed to pay, please try again later', {
                  variant: 'error',
                });
              });
          };

          window
            .$('#portalOneContainer')
            .on('portalOne.paymentComplete', paymentCompleteHandler);

          window.$('#portalOneContainer').portalOne();
          const xhttp = new XMLHttpRequest();
          xhttp.overrideMimeType('application/json');
          xhttp.onreadystatechange = function () {
            if (xhttp.readyState === 4 && xhttp.status === 200) {
              const resp = JSON.parse(xhttp.responseText);
              const sessionID = resp.PortalOneSessionKey;
              window
                .$('#portalOneContainer')
                .data('portalOne')
                .makePayment({
                  paymentCategory,
                  feeContext: 'PaymentWithFee',
                  amountContext: 'AmountDue',
                  minAmountDue: balance,
                  accountBalance: '0',
                  accountGroupCode: oneIncKey.authCode,
                  billingZip: '',
                  billingAddressStreet:
                    '6800 Koll Center Pkwy. Suite 250, Pleasanton, CA',
                  policyHolderName: `${binder.customerFirstName} ${binder.customerLastName}`,
                  referenceNumber: '',
                  saveOption: 'DoNotSave',
                  clientReferenceData1: binder.policyNumber,
                  clientReferenceData2: toUniversalUtcDate(
                    Moment(binder.created).add(7, 'days')
                  ),
                  clientReferenceData3: 'false',
                  clientReferenceData4: binder.id,
                  sessionId: sessionID,
                  displayMode: 'modal',
                  confirmationDisplay: 'true',
                });
            }
          };

          xhttp.open(
            'GET',
            `${oneIncKey.url}/Api/Api/Session/Create?portalOneAuthenticationKey=${oneIncKey.key}`,
            true
          );
          xhttp.send();
        })
        .catch(() =>
          enqueueSnackbar('Something went wrong.Please try again later', {
            variant: 'error',
          })
        );
    },
    [binder, enqueueSnackbar, setShowCircular]
  );

  const handleCreateQuoteFromBinder = React.useCallback(() => {
    if (binder.product === ProductTypes.p250) {
      return getQuoteDetails({ quoteId: binder.quoteId }).then((response) => {
        history.push(
          `/agency/requestQuote/${binder.product}/${binder.accountId}`,
          {
            ...response.data.initial250RequestData,
            requote: true,
            quoteId: binder.quoteId,
          }
        );
      });
    }

    fetchAccountDetailsById(binder.accountId).then((response) => {
      // check to see if account / product are of the same type
      if (binder.product != response.data.productType) {
        // if not the same type enter the redirect-requote modal to 'new quote'
        toggleModalDirect(
          'RedirectRequote',
          { ...binder, id: binder.quoteId },
          { maxWidth: 'sm', title: `Re-Quote` }
        );
      } else {
        // product & account type match therefore route user to requestQuote
        history.push(
          `/agency/requestQuote/${binder.product}/${binder.accountId}/${binder.quoteId}/reQuote`
        );
      }
    });
  }, [binder, history]);

  const handleCopyApplicationLink = React.useCallback(() => {
    toggleModalDirect(
      'CopyApplicationLink',
      { ...binder, id: binder.quoteId },
      {
        title: 'Copy Cowbell Application Link',
        maxWidth: 'md',
      }
    );
  }, [binder]);

  const handleIssuePolicy = () => {
    const isP100RenewalOrMigration =
      binder.product === ProductTypes.p100 &&
      getIsRenewalOrMigration(binder.isRenewal, binder.isMigration);

    if (isP100RenewalOrMigration) {
      const nextQueryParamString = qs.stringify(
        {
          product: binder.product,
          policyId: binder.id,
          quoteId: binder.quoteId,
        },
        { addQueryPrefix: true }
      );

      return history.push({
        pathname: `/agency/accounts/${binder.accountId}/renew/renewalSummary`,
        search: nextQueryParamString,
      });
    }

    history.push(`/agency/bind/quote/${binder.product}/${binder.quoteId}`);
  };

  const handleSendBinderToPolicyHolder = React.useCallback(
    (event) => {
      event.stopPropagation();

      if (binder.isSurplus) {
        fetchP250QuotePageDetails(binder.quoteId).then((resp) => {
          binder.surplus = resp.data.firmographicData;
          binder.initial250RequestData = resp.data.initial250RequestData;
          Modal.show('SendQuote', {
            data: { title: 'Send Binder to Policy Holder', ...binder },
          });
        });
      } else {
        Modal.show('SendQuote', {
          data: { title: 'Send Binder to Policy Holder', ...binder },
        });
      }
    },
    [binder]
  );

  return {
    handleRescindBinder,
    handleDownloadBinder,
    handleDownloadApplication,
    handleDownloadReceipt,
    handleDownloadInvoice,
    handleChargeCreditCardOrBankAccount,
    handleCreateQuoteFromBinder,
    handleCopyApplicationLink,
    handleIssuePolicy,
    handleSendBinderToPolicyHolder,
  };
};
