import { Box, DialogContentText, Typography, useTheme } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import type { MouseEventHandler } from 'react';
import type { Quote } from '../../../../../types/quotes/quote.entity';
import {
  AgencyDashboardBaseRow,
  AgencyDashboardListingContainer,
  AgencyDashboardRowContent,
  AgencyDashboardRowDetail,
  ColumnDivider,
  ListingHeader,
  RowCell,
} from '../common/components';
import { AgencyDashboardNullResultFallback } from '../AgencyDashboardListingFallback';
import {
  formatCurrencyAsFloat,
  numberToCurrency,
} from '../../../../../utils/currency.utils';
import { UrgencyCalendar } from '../../../../claims/detail/components/UrgencyCalendar';
import {
  HyperlinkText,
  SecondaryText,
  TruncatedTextWithTooltip,
} from '../../agency-dashboard.utils';
import {
  EXPIRED_STATUS,
  INDICATION_STATUS,
  READY_STATUS,
  REFERRED_STATUS,
} from '../../../../_statics/quote.statics';
import { PrimaryActionButton } from '../PrimaryActionButton';
import { Modal } from '../../../../../components/modals/v2/helpers/v2.modal.helpers';
import { deleteQuote } from '../../../../../inbox/QuotesService';
import {
  AgencyDashboardQueryKeys,
  earliestPossibleEntityDate,
  quotesHeaderConfig,
} from '../../agency-dashboard.statics';
import type { PrimeXProductType } from '../../../../../types';
import { PrimeXProductTypes, ProductTypes } from '../../../../../types';
import { getQuoteDetails } from '../../../../../api';
import { AgencyDashboardListingError } from '../AgencyDashboardListingError';
import { AgencydashboardListingLoadingSkeleton } from '../AgencyDashboardListingLoadingSkeleton';
import { useAgencyDashboardListingData } from '../../hooks/useAgencyDashboardListingData';
import {
  AMPLITUDE_EVENTS as ANALYTICS_EVENTS,
  AMPLITUDE_PROPERTIES as ANALYTICS_PROPERTIES,
  useAmplitude as useAnalytics,
} from '../../../../../providers/AmplitudeProvider';
import { AgencyQuotesMenuOptions } from './AgencyQuotesMenuOptions';
import { Show } from '../../../../../components/Show';
import { determineIsLegacyProduct } from '../../../../agencies/quotes/PrimeX/utils/primeX.util';
import { AgencyQuotesPrimeXMenuOptions } from './AgencyQuotesPrimeXMenuOptions';
import { toUniversalUtcDate } from '../../../../../utils/date.utils';
import {
  getCurrencySymbolByLanguage,
  getRegionByProduct,
} from '../../../../../i18n/utils';
import {
  NO_DECIMAL_FORMAT,
  deriveCurrencyFormat,
} from '../../../../../i18n/currencies';
import { useCowbellTranslations } from '../../../../../i18n/translations';

const initialData = { rows: [], totalPages: 0 };

export const AgencyDashboardQuotesTab = () => {
  const { quotesCache } = useAgencyDashboardListingData();
  const { data = initialData, isLoading, isError } = quotesCache;

  if (isError) {
    return (
      <AgencyDashboardListingError>
        <Typography variant="h6" style={{ textAlign: 'center' }}>
          There was an error while fetching page data. <br /> Please try again
          in a few minutes.
        </Typography>
      </AgencyDashboardListingError>
    );
  }

  return (
    <AgencyDashboardListingContainer totalPages={data.totalPages}>
      <ListingHeader headerConfig={quotesHeaderConfig} />
      <QuotesListing quotes={data.rows} isLoading={isLoading} />
    </AgencyDashboardListingContainer>
  );
};

interface ListingProps {
  quotes: Quote[];
  isLoading: boolean;
}

export const QuotesListing = ({ quotes, isLoading }: ListingProps) => {
  if (isLoading) {
    return <AgencydashboardListingLoadingSkeleton />;
  }

  if (quotes.length === 0) {
    return <AgencyDashboardNullResultFallback entityType="quotes" />;
  }

  return (
    <>
      {quotes.map((quote) => (
        <AgencyDashboardBaseRow isExpandable key={quote.id} rowId={quote.id}>
          <AgencyDashboardQuoteRow quote={quote} />
          <QuoteRowDetail quote={quote} />
        </AgencyDashboardBaseRow>
      ))}
    </>
  );
};

interface QuoteRowProps {
  quote: Quote;
}

export const AgencyDashboardQuoteRow = ({ quote }: QuoteRowProps) => {
  const theme = useTheme<any>();
  const isLegacyProduct = determineIsLegacyProduct(quote.product);
  const analytics = useAnalytics();
  const handleAccountLinkClick = () => {
    analytics.track(`nav_to_${ANALYTICS_EVENTS.nav.clientProfile}`, {
      source: ANALYTICS_PROPERTIES.source.quotesTab,
    });
  };

  const handleQuoteLinkClick = () => {
    analytics.track(`nav_to_${ANALYTICS_EVENTS.nav.quotes}`, {
      source: ANALYTICS_PROPERTIES.source.quotesTab,
    });
  };

  return (
    <AgencyDashboardRowContent>
      <RowCell width="12%">
        <Box display="flex" alignItems="center">
          <UrgencyCalendar
            dueDate={quote.effectiveDate}
            // @ts-expect-error needs converted to TS to allow for widening of Icon prop type
            Icon={AccessTimeIcon}
          />
          <Box ml="0.2rem">
            <Typography
              variant="body2"
              style={{ color: theme.agencyDash.text.primary }}
            >
              {toUniversalUtcDate(quote.effectiveDate)}
            </Typography>
          </Box>
        </Box>
      </RowCell>
      <ColumnDivider />
      <RowCell width="30%">
        <Link
          to={`/agency/account/${quote.accountId}`}
          onClick={handleAccountLinkClick}
        >
          <HyperlinkText>
            <TruncatedTextWithTooltip
              text={quote.companyName}
              length={25}
              isMultiline
            />
          </HyperlinkText>
        </Link>
      </RowCell>
      <ColumnDivider />
      <RowCell width="25%">
        <Box display="flex" flexDirection="column">
          <Link
            to={`/agency/inbox?search=${quote.id}&after=${earliestPossibleEntityDate}`}
            onClick={handleQuoteLinkClick}
          >
            <HyperlinkText>{quote.quoteNumber}</HyperlinkText>
          </Link>
          <SecondaryText variant="caption">
            {quote.isRenewal || quote.isAutoRenewal
              ? 'Renewal'
              : 'New Business'}
          </SecondaryText>
        </Box>
      </RowCell>
      <ColumnDivider />
      <RowCell width="18%" style={{ justifyContent: 'center' }}>
        <Typography
          variant="body2"
          style={{ color: theme.agencyDash.text.primary }}
        >
          {deriveQuoteStatus(quote)}
        </Typography>
      </RowCell>
      <ColumnDivider />
      <RowCell
        width="15%"
        style={{
          marginRight: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <QuoteRowActionButton quote={quote} />
        <Show when={isLegacyProduct}>
          <AgencyQuotesMenuOptions quote={quote} />
        </Show>
        <Show when={!isLegacyProduct}>
          <AgencyQuotesPrimeXMenuOptions quote={quote} />
        </Show>
      </RowCell>
    </AgencyDashboardRowContent>
  );
};

export const QuoteRowDetail = ({ quote }: QuoteRowProps) => {
  const language = getRegionByProduct(quote.product);
  const currencySymbol = getCurrencySymbolByLanguage(language);
  const { translations } = useCowbellTranslations(['Retention']);
  const isPrimeXProduct = Object.values(PrimeXProductTypes).includes(
    quote.product as PrimeXProductType
  );

  return (
    <AgencyDashboardRowDetail>
      <RowCell width="20%">
        <Box display="flex" flexDirection="column">
          <strong>Quote Expires</strong>
          <>{toUniversalUtcDate(quote.expiresOn)}</>
        </Box>
      </RowCell>
      <RowCell width="20%">
        <Box display="flex" flexDirection="column">
          <strong>Premium</strong>
          <>{deriveCurrencyFormat(language, quote.premium)}</>
        </Box>
      </RowCell>
      <RowCell width="15%">
        <Box display="flex" flexDirection="column">
          <strong>Limit</strong>
          <>{`${currencySymbol}${numberToCurrency(quote.aggLimit, '0a')}`}</>
        </Box>
      </RowCell>
      <RowCell width="20%">
        <Box display="flex" flexDirection="column">
          <strong>
            {isPrimeXProduct ? translations.Retention : 'Deductible'}
          </strong>
          <>
            {deriveCurrencyFormat(
              language,
              quote.deductible,
              NO_DECIMAL_FORMAT
            )}
          </>
        </Box>
      </RowCell>
      <RowCell width="25%">
        <Box display="flex" flexDirection="column">
          <strong>UW</strong>
          <UWCell quote={quote} />
        </Box>
      </RowCell>
    </AgencyDashboardRowDetail>
  );
};

const QuoteRowActionButton = ({ quote }: QuoteRowProps) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const analytics = useAnalytics();

  const handleBind = () => {
    analytics.track(ANALYTICS_EVENTS.requestToBind, {
      version: ANALYTICS_PROPERTIES.version.two,
    });
    if (quote.isRenewal && quote.product === ProductTypes.p100) {
      getQuoteDetails(quote.id, ProductTypes.p100).then((resp) => {
        return history.push(
          `/agency/accounts/${quote.accountId}/renew/accountDetails?product=${ProductTypes.p100}&policyId=${resp.data.data.policyId}&quoteId=${quote.id}`
        );
      });
    } else {
      history.push(`/agency/bind/quote/${quote.product}/${quote.id}`);
    }
  };
  const handleDelete = () => {
    Modal.show('SimpleConfirm', {
      data: {
        body: (
          <DialogContentText>
            Are you sure you want to delete quote number{' '}
            <strong>{quote.quoteNumber}</strong>?
          </DialogContentText>
        ),
        onConfirm: () => {
          deleteQuote(quote.id)
            .then(() => {
              queryClient.invalidateQueries([AgencyDashboardQueryKeys.QUOTES]);
            })
            .catch(() =>
              enqueueSnackbar(
                'There was a problem while deleting that quote. Please try again.',
                { variant: 'error' }
              )
            );
        },
      },
      config: { title: 'Delete Quote', fullWidth: true },
    });
  };
  const handleRequote = () => {
    history.push(
      `/agency/requestQuote/${quote.product}/${quote.accountId}/details`
    );
  };

  switch (quote.agencyStatus) {
    case READY_STATUS:
      return (
        <PrimaryActionButton onClick={handleBind}>Bind</PrimaryActionButton>
      );
    case INDICATION_STATUS:
      if (quote.product === ProductTypes.p100) {
        return (
          <PrimaryActionButton onClick={handleBind}>Bind</PrimaryActionButton>
        );
      }
      return <></>;

    case REFERRED_STATUS:
      return (
        <PrimaryActionButton onClick={handleDelete}>Delete</PrimaryActionButton>
      );
    case EXPIRED_STATUS:
      return (
        <PrimaryActionButton onClick={handleRequote}>
          Requote
        </PrimaryActionButton>
      );
    default:
      return <></>;
  }
};

const deriveQuoteStatus = (quote: Quote) => {
  if (
    quote.agencyStatus === INDICATION_STATUS &&
    quote.product === ProductTypes.p100
  ) {
    return 'QUOTE';
  }

  return quote.agencyStatus.split('_').join(' ');
};

const UWCell = ({ quote }: { quote: Quote }) => {
  const theme = useTheme<any>();
  const { uwName, uwEmail } = quote;

  if (!uwName && !uwEmail) {
    return <>-</>;
  }

  if (!uwName || !uwEmail) {
    return <>{uwName}</> ?? <>{uwEmail}</>;
  }

  const onClick: MouseEventHandler = (event) => event.stopPropagation();

  return (
    <a
      href={`mailto:${uwEmail}`}
      onClick={onClick}
      style={{ color: theme.agencyDash.interactable, fontSize: '1.2rem' }}
      target="_blank"
      rel="noreferrer"
    >
      {uwName}
    </a>
  );
};
