import React from 'react';
import { useQuery } from '@tanstack/react-query';

// components
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomTypeahead } from '../global-search/CustomTypeahead';
import { BindQuoteSearchListing } from './BindQuoteSearchListing';
import {
  QuoteRowDetail,
  AgencyDashboardQuoteRow,
} from '../listing-tabs/quotes/AgencyDashboardQuotesTab';
import Showable from '../../../../components/Showable';

// utils
import type { Quote } from '../../../../types/quotes/quote.entity';
import { QuoteStatus } from '../../../_statics/quote.statics';

// apis
import { searchQuotesV2 } from '../../../../api';
import { getData } from '../../../../utils/functional/fp';
import { searchPoliciesV2 } from '../../../../policies/PolicyService';
import { PolicyStatus } from '../../../_statics/policy.statics';
import type { Binder } from '../../../accounts/summary/types/binder.types';
import type {
  BindQuoteSearchEntity,
  IBinder,
  IQuote,
} from './bind-quote.utils';
import {
  AgencyDashboardPolicyRow,
  PolicyRowDetail,
} from '../listing-tabs/policies/AgencyDashboardPoliciesTab';

export const BindQuote = () => {
  const [selectedItem, setSelectedItem] =
    React.useState<BindQuoteSearchEntity>();

  const { data: quotes = [] } = useQuotesQuery(selectedItem?.companyName);
  const { data: binders = [] } = useBindersQuery(selectedItem?.companyName);

  const onSelect = (item: BindQuoteSearchEntity) => {
    setSelectedItem(item);
  };

  const onClear = () => {
    setSelectedItem(undefined);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={8}
    >
      <Typography>Please search for the client or quote below</Typography>
      <Box display="flex" justifyContent="center" mt={2} width="50rem">
        <BindQuoteSearch onSelect={onSelect} onClear={onClear} />
      </Box>
      <Showable show={!!selectedItem && selectedItem?.entityType === 'QUOTE'}>
        <QuoteListing quotes={quotes} />
      </Showable>
      <Showable show={!!selectedItem && selectedItem?.entityType === 'BINDER'}>
        <BinderListing binders={binders} />
      </Showable>
    </Box>
  );
};

interface BindQuoteSearchProps {
  onSelect: (quote: BindQuoteSearchEntity) => void;
  onClear: () => void;
}

const BindQuoteSearch = ({ onSelect, onClear }: BindQuoteSearchProps) => {
  const [userInput, setUserInput] = React.useState('');

  const queryCache = useBindQuoteQuery(userInput);

  const onChange = (value: string) => {
    setUserInput(value);
  };

  const onClearInput = () => {
    setUserInput('');
    onClear();
  };

  return (
    <CustomTypeahead
      onChange={onChange}
      onClearInput={onClearInput}
      maxListHeight="15rem"
    >
      {(typeaheadProps) => (
        <BindQuoteSearchListing
          {...typeaheadProps}
          onSelect={onSelect}
          queryCache={queryCache}
        />
      )}
    </CustomTypeahead>
  );
};

const QuoteListing = ({ quotes }: { quotes: Quote[] }) => {
  const classes = useQuoteRowStyles();
  return (
    <Box
      marginTop={10}
      marginBottom={5}
      width="90%"
      style={{ overflow: 'auto', height: '55vh' }}
    >
      {quotes.map((quote) => (
        <Box key={quote.id} className={classes.container}>
          <Box display="flex" flexDirection="column">
            <AgencyDashboardQuoteRow quote={quote} />
            <QuoteRowDetail quote={quote} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const BinderListing = ({ binders }: { binders: Binder[] }) => {
  const classes = useQuoteRowStyles();
  return (
    <Box
      marginTop={10}
      marginBottom={5}
      width="90%"
      style={{ overflow: 'auto', height: '55vh' }}
    >
      {binders.map((binder) => (
        <Box key={binder.id} className={classes.container}>
          <Box display="flex" flexDirection="column">
            <AgencyDashboardPolicyRow policy={binder} isBinder />
            <PolicyRowDetail policy={binder} isBinder />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const useQuotesQuery = (search?: string) => {
  return useQuery({
    queryKey: ['agency-dashboard-quotes', search],
    queryFn: () =>
      searchQuotesV2({
        params: {
          search,
          f: { agencyStatus: { in: [QuoteStatus.READY_STATUS] } },
        },
      }).then(getData),
    select: (resp) => resp.content,
    enabled: !!search,
  });
};

const useBindersQuery = (search?: string) => {
  return useQuery({
    queryKey: ['agency-dashboard-binders', search],
    queryFn: () =>
      searchPoliciesV2({
        params: {
          search,
          f: { status: { in: [PolicyStatus.BOUND_STATUS] } },
        },
      }).then(getData),
    select: (resp) => resp.content,
    enabled: !!search,
  });
};

const useBindQuoteQuery = (search: string) => {
  return useQuery({
    queryKey: ['agency-dashboard-bind-quote-search', search],
    queryFn: () => {
      return Promise.all([
        searchQuotesV2({
          params: {
            search,
            f: { agencyStatus: { in: [QuoteStatus.READY_STATUS] } },
            size: 10,
          },
        }),
        searchPoliciesV2({
          params: {
            search,
            f: { status: { in: [PolicyStatus.BOUND_STATUS] } },
            size: 10,
          },
        }),
      ]);
    },
    select: ([quotesResp, bindersResp]) => {
      quotesResp.data.content.forEach((quote: IQuote) => {
        quote.entityType = 'QUOTE';
      });
      bindersResp.data.content.forEach((binder: IBinder) => {
        binder.entityType = 'BINDER';
      });
      return [...quotesResp.data.content, ...bindersResp.data.content];
    },
    enabled: !!search,
  });
};

export const useQuoteRowStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '9rem',
    margin: '1rem',
    border: `1px solid ${palette.notification.containerBorder}`,
    borderRadius: 5,
    overflow: 'hidden',
  },
}));
