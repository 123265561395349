import _ from 'lodash';

// mui
import { Box, Divider } from '@mui/material';

// components
import { Flexbox } from '../../../../../../components/layout/Flexbox';
import { AccountData } from '../policy-details.shared-components.utils';

// utils
import { PolicyDrawerTypography as Typography } from '../policy-details.utils';

import {
  formatAddressByRegion,
  getLanguageFromAccount,
} from '../../../../../../i18n/utils';
import { formatPhoneAsString } from '../../../../../../i18n/forms/LanguageForm';

export const OrganizationContact = ({
  policy,
  account,
  latestRiskSelected,
}) => {
  const {
    customerEmail,
    customerPhone: customerPhoneNumberRaw,
    uiCustomerFullName: customerFullName,
  } = policy;
  const language = getLanguageFromAccount(account);

  const customerPhoneNumber = formatPhoneAsString(
    language,
    customerPhoneNumberRaw
  );

  const accountCustomerEmail = _.get(account, 'policyContactEmail', '-');
  const {
    accountBusinessPhoneNumber,
    accountPolicyContactPhoneNumber,
    accountPolicyContactName,
  } = getUiAccountValues(account);

  return (
    <>
      <Box>
        <Typography variant="subtitle2" color="primary">
          CONTACT
        </Typography>
      </Box>
      <Divider style={{ width: '100%', margin: '0.5rem 0 1rem 0' }} />
      <Box display="flex">
        <Box width="50%" marginRight={1}>
          <Flexbox>
            <Typography variant="caption">Address</Typography>
            <Box>
              <Address entity={policy.firmographicData} />
              <AccountData show={latestRiskSelected}>
                <Address entity={account} />
              </AccountData>
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Business Phone Number</Typography>
            <Box>
              <Typography
                component="a"
                href={`tel:${account.phoneNumber}`}
                className="link-underlined--hover"
              >
                {accountBusinessPhoneNumber}
              </Typography>
              <AccountData
                data={accountBusinessPhoneNumber}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box width="50%" marginLeft={1}>
          <Flexbox>
            <Typography>Policy Holder Name</Typography>
            <Box>
              <Typography>{customerFullName}</Typography>
              <AccountData
                data={accountPolicyContactName}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Policy Holder Email</Typography>
            <Box>
              <Typography
                component="a"
                href={`mailto:${customerEmail}`}
                className="link-underlined--hover"
              >
                {customerEmail}
              </Typography>
              <AccountData
                data={accountCustomerEmail}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
          <Flexbox>
            <Typography>Policy Holder Phone Number</Typography>
            <Box>
              <Typography
                component="a"
                href={`tel:${account.policyContactPhone}`}
                className="link-underlined--hover"
              >
                {customerPhoneNumber}
              </Typography>
              <AccountData
                data={accountPolicyContactPhoneNumber}
                show={latestRiskSelected}
              />
            </Box>
          </Flexbox>
        </Box>
      </Box>
    </>
  );
};

const Address = ({ entity }) => {
  const language = getLanguageFromAccount(entity);

  const addressObj = _.pick(entity, [
    'address1',
    'address2',
    'address3',
    'city',
    'state',
    'zipCode',
  ]);

  return formatAddressByRegion(language, addressObj);
};

const getUiAccountValues = (account) => {
  const { policyContactFirstName, policyContactLastName } = account;
  const language = getLanguageFromAccount(account);

  const accountBusinessPhoneNumber = formatPhoneAsString(
    language,
    account?.phoneNumber
  );
  const accountPolicyContactPhoneNumber = formatPhoneAsString(
    language,
    account.policyContactPhone
  );

  const accountPolicyContactName =
    !policyContactFirstName || !policyContactLastName
      ? '-'
      : `${policyContactFirstName} ${policyContactLastName}`;

  return {
    accountBusinessPhoneNumber,
    accountPolicyContactPhoneNumber,
    accountPolicyContactName,
  };
};
