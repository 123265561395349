import React, { useEffect } from 'react';
import { compose } from 'redux';

// mui
import { useSnackbar } from 'notistack';
import { FormContext, useForm } from 'react-hook-form-4';
import { Box, DialogActions, DialogContent } from '@mui/material';

import { withStyles } from '@mui/styles';
import { withUploadFeatureApplications } from '../../fileUploader/withUploadFeatureApplications';
import { withUploadFeature } from '../../fileUploader/withUploadFeature';
import CBButton from '../../Buttons/CbButton';
import { PubSub } from '../../../utils/eventUtils';

import { uploadDocument, updateNotice } from '../../../api/DocumentsService';

import { iconMappings } from '../../../console/_global/navigation/IconsMappings';
import { Modal } from './helpers/v2.modal.helpers';
import { getFileExtension, getFileName } from '../../../utils';
import { usePersona } from '../../hooks/usePersona';
import { withToggleModal } from '../../hocs/withToggleModal';

const styles = ({ palette }) => {
  return {
    iconCancel: {
      width: '2rem',
      height: '2rem',
      margin: '1.3rem 0 0 0.5rem',
      cursor: 'pointer',
    },
    selectedFile: {
      fontSize: '1.33rem',
      lineHeight: 1.38,
      color: palette.primary.main,
      marginBottom: '0 !important',
    },
    selectedFileContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    dragDropText: {
      width: '10.67rem',
      fontSize: '1rem',
      lineHeight: 1.33,
      color: palette.primary.main,
    },
    dragDropContainer: {
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      height: '5.833rem',
      width: '25rem',
      borderRadius: '5px',
      border: `1px solid ${palette.primary.main}`,
      cursor: 'pointer',
    },
    container: {
      width: '50rem',
      backgroundColor: palette.background.active,
    },
    bodyContainer: {
      height: 'fit-content',
      textAlign: 'center',
    },
    downloadApplication: {
      borderTop: `1px solid ${palette.primary.modalBorder}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      fontSize: '1.333rem',
    },
    supportedFiles: {
      fontSize: '1.167rem',
      lineHeight: 1.5,
      color: palette.text.secondary,
      marginBottom: 20,
    },
    icon: {
      width: '1.5rem',
      height: '1.25rem',
      margin: '1.6rem 0 0 1rem',
    },
    icon1: {
      width: '5rem',
      height: '5rem',
      margin: '0 0 0 1rem',
    },
    downloadTemplate: {
      'text-decoration': 'underline',
      fontWeight: 600,
      cursor: 'pointer',
      color: palette.primary.main,
      marginLeft: '0.5rem',
    },
    error: {
      color: palette.error.main,
    },
  };
};

export const SurplusUploadModal = compose(
  withToggleModal,
  withUploadFeatureApplications(),
  withUploadFeature({
    screenCenter: false,
    hide: false,
    acceptFileTypes: `image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf`,
  }),
  withStyles(styles)
)(({ classes, data, iconStyles, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState('');
  const { handleSubmit, ...methods } = useForm({});
  const persona = usePersona();

  const {
    formState: { isSubmitting },
  } = methods;

  const [fileDetails, setFileDetails] = React.useState({});

  useEffect(() => {
    const sub = PubSub.subscribe(
      'withUploadFeatureApplications:selectedFile',
      (eventData) => {
        if (eventData && eventData.type === 'application/pdf') {
          setFile(eventData);
          const extension = getFileExtension(eventData.name);
          const fileName = getFileName(eventData.name);
          setFileDetails({ extension, fileName });
          setError('');
        } else {
          setError('Unsupported file type.');
        }
      }
    );
    return () => {
      sub.remove();
    };
  }, [file]);

  const onSubmit = () => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    return uploadDocument(
      bodyFormData,
      data.data.agencyId,
      'Surplus_Lines_Notice',
      fileDetails.fileName,
      true
    )
      .then((resp) => {
        const pload = {
          ...data.data,
          surplusLinesNotice: { ...resp.data },
        };
        updateNotice({}, pload).then(() => {
          enqueueSnackbar('Upload successful', { variant: 'success' });
          if (persona.isAgency) {
            Modal.show('uploadSurplusConfirmModal', { ...props });
          } else {
            props.close();
          }
        });
      })
      .catch(() => {
        enqueueSnackbar('Failed to upload the file!', { variant: 'error' });
      });
  };

  const uploadDocumentHandler = React.useCallback((event) => {
    PubSub.publish('openFileUploadFeature', event);
  }, []);

  const cancelSelectedFile = React.useCallback(() => {
    setFile(null);
  }, []);

  const handleCancel = React.useCallback(() => {
    this.props.toggleModal.direct('ApplicationUpload', false);
  }, []);

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ marginTop: '0.625rem', paddingBottom: 0 }}>
          <section className={classes.bodyContainer}>
            {file ? (
              <section className={classes.selectedFileContainer}>
                <p className={classes.selectedFile}>
                  SELECTED FILE: {file.name}
                </p>
                <Box
                  component="img"
                  alt=""
                  src="images/cancel.svg"
                  className={classes.iconCancel}
                  onClick={cancelSelectedFile}
                />
              </section>
            ) : (
              <Box
                className={classes.dragDropContainer}
                onClick={uploadDocumentHandler}
              >
                <p className={classes.dragDropText}>
                  Drag and Drop a file here or Click to Upload
                </p>
                <img
                  alt=""
                  src="images/cloud-upload.svg"
                  className={classes.icon1}
                />
              </Box>
            )}
            <p className={classes.supportedFiles}>
              Supported File Types: PDF only
            </p>
            {error ? <p className={classes.error}>{error}</p> : null}
          </section>
        </DialogContent>
        <DialogActions>
          <CBButton action={handleCancel} styleName="cancel">
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting || file === null}
            styleName="ctaButton"
            buttonText="Submit"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
});

export const SurplusUploadModalConfig = {
  SurplusUploadModal: {
    component: SurplusUploadModal,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      title: 'Upload Modals',
      icon: iconMappings.ACCOUNTS.iconImport,
    },
  },
};
