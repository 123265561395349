import React from 'react';

import { useFormContext } from 'react-hook-form';
import { ManagedTypeAheadBase } from './ManagedTypeAhead';

interface ControlledTypeAheadProps {
  onChange: (option: unknown) => void;
  name: string;
  required?: boolean;
}

export const ControlledTypeAhead = ({
  onChange,
  ...props
}: ControlledTypeAheadProps) => {
  const { register, setValue, unregister } = useFormContext();

  React.useEffect(() => {
    register(props.name, { required: props.required });

    return () => unregister(props.name);
    // eslint-disable-next-line
  }, [props.name, props.required]);

  const handleChange = (selectedOption: AgencyOption) => {
    if (typeof onChange === 'function') {
      onChange(selectedOption);
    } else {
      setValue(props.name, selectedOption);
    }
  };

  const handleInputChange = (nextInputValue: string) => {
    setValue(props.name, nextInputValue);
  };

  return (
    <ManagedTypeAheadBase
      onChange={handleChange}
      onInputChange={handleInputChange}
      {...props}
    />
  );
};

type AgencyOption = {
  label: string;
  value: string;
  meta: Record<string, any>;
};
