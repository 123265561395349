import React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  useTheme,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import {
  AgencyDashboardBaseRow,
  AgencyDashboardListingContainer,
  AgencyDashboardRowContent,
  ColumnDivider,
  ListingHeader,
  RowCell,
} from '../common/components';
import type { AccountDto, ProductType } from '../../../../../types';
import { ProductTypes } from '../../../../../types';
import ProductStatics from '../../../../_statics/product.statics.json';
import { AgencyDashboardNullResultFallback } from '../AgencyDashboardListingFallback';
import SimpleTooltip from '../../../../../components/SimpleTooltip';
import {
  TruncatedTextWithTooltip,
  HyperlinkText,
} from '../../agency-dashboard.utils';
import { PrimaryActionButton } from '../PrimaryActionButton';
import { AgencydashboardListingLoadingSkeleton } from '../AgencyDashboardListingLoadingSkeleton';
import { AgencyDashboardListingError } from '../AgencyDashboardListingError';
import { useAgencyDashboardListingData } from '../../hooks/useAgencyDashboardListingData';
import {
  useAmplitude as useAnalytics,
  AMPLITUDE_EVENTS as ANALYTICS_EVENTS,
  AMPLITUDE_PROPERTIES as ANALYTICS_PROPERTIES,
} from '../../../../../providers/AmplitudeProvider';
import { ACCOUNTS_OPEN_STATUS } from '../../../../_statics/accounts.statics';
import { SimpleSelect } from '../../../../../components/inputs';
import CbButton from '../../../../../components/Buttons/CbButton';
import { Modal } from '../../../../../components/modals/v2/helpers/v2.modal.helpers';
import { UsStatesObjects } from '../../../../../utils/USState';
import AgencyClientsMenuOptions from './AgencyClientsMenuOptions';
import { getIsUsPlatform, useGetPlatformRegion } from '../../../../../utils';
import { Show } from '../../../../../components/Show';
import { languages } from '../../../../../i18n/i18n.language-config';
import { MANAGE_CLIENT_EVENT } from '../../../../../analytics/events/manage-client.event';

const initialData = { rows: [], totalPages: 0 };

export const AgencyDashboardClientsTab = () => {
  const { accountsCache } = useAgencyDashboardListingData();
  const { data = initialData, isLoading, isError } = accountsCache;
  const headerConfig = useHeaderConfig();

  if (isError) {
    return (
      <AgencyDashboardListingError>
        <Typography variant="h6" style={{ textAlign: 'center' }}>
          There was an error while fetching page data. <br /> Please try again
          in a few minutes.
        </Typography>
      </AgencyDashboardListingError>
    );
  }

  return (
    <AgencyDashboardListingContainer totalPages={data.totalPages}>
      <ListingHeader headerConfig={headerConfig} />
      <AccountsListing accounts={data.rows} isLoading={isLoading} />
    </AgencyDashboardListingContainer>
  );
};

const useHeaderConfig = () => {
  const region = useGetPlatformRegion();
  const isUSRegion = getIsUsPlatform(region);

  return [
    { name: 'name', label: 'Client', sort: true, width: '40%' },
    ...(isUSRegion
      ? [
          {
            name: 'state',
            label: 'State',
            sort: true,
            width: '15%',
            style: { justifyContent: 'center' },
          },
        ]
      : []),
    {
      name: 'eligibleProducts',
      label: 'Product',
      sort: false,
      width: '15%',
      style: { justifyContent: 'center' },
    },
    {
      name: 'status',
      label: 'Status',
      sort: true,
      width: isUSRegion ? '15%' : '30%',
      style: { justifyContent: 'center' },
    },
    {
      name: 'action',
      label: 'Action',
      sort: false,
      width: '15%',
      divider: false,
      style: { justifyContent: 'center', marginRight: 0 },
    },
  ];
};

interface ListingProps {
  accounts: AccountDto[];
  isLoading: boolean;
}

const AccountsListing = ({ accounts, isLoading }: ListingProps) => {
  if (isLoading) {
    return <AgencydashboardListingLoadingSkeleton />;
  }

  if (accounts.length === 0) {
    return <AgencyDashboardNullResultFallback entityType="accounts" />;
  }

  return (
    <>
      {accounts.map((account) => (
        <AgencyDashboardBaseRow key={account.id} rowId={account.id}>
          <AgencyDashboardClientRow account={account} />
        </AgencyDashboardBaseRow>
      ))}
    </>
  );
};

interface ClientRowProps {
  account: AccountDto;
}

const AgencyDashboardClientRow = ({ account }: ClientRowProps) => {
  const analytics = useAnalytics();
  const theme = useTheme<any>();
  const handleAccountLinkClick = () => {
    analytics.track(`nav_to_${ANALYTICS_EVENTS.nav.clientProfile}`, {
      source: ANALYTICS_PROPERTIES.source.clientsTab,
    });
  };
  const region = useGetPlatformRegion();

  return (
    <AgencyDashboardRowContent>
      <RowCell width="40%" style={{ display: 'block' }}>
        <Link
          to={`/agency/account/${account.id}`}
          onClick={handleAccountLinkClick}
        >
          <HyperlinkText>
            <TruncatedTextWithTooltip
              text={account.name}
              length={34}
              isMultiline
            />
          </HyperlinkText>
        </Link>
        <Box className="new-line" mt={0.5}>
          {account.address1}
        </Box>
      </RowCell>
      <ColumnDivider />
      <Show when={region === languages['en-US']}>
        <RowCell width="15%" style={{ justifyContent: 'center' }}>
          <Typography
            variant="body2"
            style={{ color: theme.agencyDash.text.primary }}
          >
            {UsStatesObjects[
              account.state?.toUpperCase() as keyof typeof UsStatesObjects
            ]?.label ?? '-'}
          </Typography>
        </RowCell>
        <ColumnDivider />
      </Show>
      <RowCell width="15%" style={{ justifyContent: 'center' }}>
        <ProductTypeCell
          eligibleProducts={account.eligibleProducts ?? account.productType}
        />
      </RowCell>
      <ColumnDivider />
      <RowCell
        width={region === languages['en-US'] ? '15%' : '30%'}
        style={{ justifyContent: 'center' }}
      >
        <Typography
          variant="body2"
          style={{ color: theme.agencyDash.text.primary }}
        >
          {account.status}
        </Typography>
      </RowCell>
      <ColumnDivider />
      <RowCell width="15%" style={{ justifyContent: 'center', marginRight: 0 }}>
        <ClientRowActionButton account={account} />
        <AgencyClientsMenuOptions account={account} />
      </RowCell>
    </AgencyDashboardRowContent>
  );
};

interface ProductTypeCellProps {
  eligibleProducts: ProductType[] | ProductType;
}

const ProductTypeCell = ({ eligibleProducts }: ProductTypeCellProps) => {
  if (!eligibleProducts || eligibleProducts.length === 0) {
    return <>-</>;
  }

  if (typeof eligibleProducts === 'string') {
    return <Typography variant="body2">{eligibleProducts}</Typography>;
  }

  const productLabels = eligibleProducts
    .filter((product) => !!ProductStatics.ProductTypes[product])
    .map((product) => ProductStatics.ProductTypes[product].full);

  if (productLabels.length === 1) {
    return <Typography variant="body2">{productLabels[0]}</Typography>;
  }

  return (
    <SimpleTooltip title={`${productLabels.slice(1).join(', ')}`}>
      <Typography variant="body2">
        {productLabels[0]} +{productLabels.length - 1}
      </Typography>
    </SimpleTooltip>
  );
};

const ClientRowActionButton = ({ account }: ClientRowProps) => {
  const history = useHistory();
  const analytics = useAnalytics();

  const handleManageClick = () => {
    analytics.track(MANAGE_CLIENT_EVENT);
    history.push(`/agency/account/${account.id}`);
  };
  const handleQuoteClick = () => {
    const onRedirect = () => {
      history.push(deriveQuoteFlowUrl(account.eligibleProducts[0], account.id));
    };

    if (account.eligibleProducts.length > 1) {
      return Modal.show(SelectProductModalConfig, {
        data: { account, onConfirm: onRedirect },
      });
    }
    analytics.track(ANALYTICS_EVENTS.startNewQuote, {
      version: ANALYTICS_PROPERTIES.version.two,
    });
    onRedirect();
  };

  if (
    account.status === ACCOUNTS_OPEN_STATUS &&
    account.eligibleProducts?.length
  ) {
    return (
      <PrimaryActionButton onClick={handleQuoteClick}>
        Quote
      </PrimaryActionButton>
    );
  }
  return (
    <PrimaryActionButton onClick={handleManageClick}>
      Manage
    </PrimaryActionButton>
  );
};

const SelectProductModal = ({
  data: { account, onConfirm },
  ...props
}: any) => {
  const analytics = useAnalytics();
  const [targetProduct, setTargetProduct] = React.useState('');
  const options = account.eligibleProducts.map(
    (product: keyof typeof ProductStatics.ProductTypes) => ({
      label: ProductStatics.ProductTypes[product].full,
      value: product,
    })
  );

  const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTargetProduct(event.target.value);
  };

  const handleConfirm = () => {
    props.close();
    analytics.track(ANALYTICS_EVENTS.startNewQuote, {
      version: ANALYTICS_PROPERTIES.version.two,
    });
    onConfirm();
  };

  return (
    <>
      <DialogContent>
        <DialogContentText>Select a product to quote</DialogContentText>
        <SimpleSelect options={options} onChange={onSelect} />
      </DialogContent>
      <DialogActions>
        <CbButton
          styleName="ctaButton"
          onClick={handleConfirm}
          disabled={!targetProduct}
        >
          Confirm
        </CbButton>
      </DialogActions>
    </>
  );
};
const SelectProductModalConfig = {
  SelectProductModal: {
    component: SelectProductModal,
    config: { title: 'Select Product', maxWidth: 'xs' },
  },
};

export const deriveQuoteFlowUrl = (
  productType: ProductType,
  accountId: string
) => {
  if (productType === ProductTypes.p100_pro) {
    return `/agency/quotes/${productType}/${accountId}/requestQuote/verify`;
  }
  return `/agency/requestQuote/${productType}/${accountId}/details`;
};
