import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

// lodash
import _ from 'lodash';

// components
import { Box, DialogContent } from '@mui/material';

import { Skeleton } from '@mui/lab';
import { AgencyUsersManageModalContent } from './AgencyUsersManageModalContent';

// other
import { fetchRoles } from '../../../../api/UserService';
import { getAgencyTeams } from '../../../../console/agencies/_services/teams.service';
import { fetchPagedAccounts } from '../../../../accounts/AccountService';
import { selectGlobalTeam } from '../../../../reducers/team.reducer';
import { reduceForSelect } from '../../../../console/admin/users/UsersUtils';
import { PERSONA_TYPES } from '../../../../console/_statics/persona.statics';
import { getCowbellTranslations } from '../../../../i18n/translations';

export const AgencyUsersManageModal = ({ close, data: modalData }) => {
  const queryKey = ['manage', 'user', modalData?.id];
  const queryClient = useQueryClient();
  const team = useSelector(selectGlobalTeam);

  const fetchDependencies = () => {
    return typeof modalData.fetchDependencies === 'function'
      ? modalData.fetchDependencies()
      : Promise.all([
          fetchRoles(PERSONA_TYPES.AGENCY),
          getAgencyTeams({ size: 100 }),
          fetchPagedAccounts({ teamIds: team.id }),
        ]);
  };
  const getTeams =
    typeof modalData.getTeams === 'function'
      ? modalData.getTeams
      : getAgencyTeams;

  const { data, isLoading, isFetching } = useQuery(
    queryKey,
    () => {
      return fetchDependencies().then(([roles, teams, accounts]) => {
        const nextRoles = reduceForSelect(roles.data);
        const nextAccounts = reduceForSelect(accounts.data.content);
        const nextTeams = reduceForSelect(teams.data.content);

        const selectedRole = nextRoles.find(
          (role) => role.value === modalData?.role?.id
        );

        const _existingAccounts = modalData?.userAccountsAccess || [];
        const selectedAccounts = _existingAccounts.map((account) => ({
          label: account.name,
          value: account.value,
        }));

        const selectedTeams = _.intersectionWith(
          nextTeams,
          modalData?.teams,
          (available, selected) => available.value === selected.id
        );

        const user = {
          firstName: modalData.firstName,
          lastName: modalData.lastName,
          email: modalData.email,
          phone: modalData.phone,
          npn: modalData.npn,
          billingContact: modalData.billingContact,
          primaryContact: modalData.primaryContact,
          role: selectedRole,
          accounts: selectedAccounts,
          teams: selectedTeams,
        };

        return {
          accounts: [...selectedAccounts, ...nextAccounts],
          totalAccounts: accounts.data.totalPages,
          roles: nextRoles,
          teams: nextTeams,
          totalTeams: teams.data.totalPages,
          user: modalData?.id ? user : {},
        };
      });
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(queryKey);
    };
    // eslint-disable-next-line
  }, []);

  if (isLoading || isFetching) {
    return (
      <DialogContent>
        <Box mb={1}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
        <Box mb={1}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
        <Box mb={1}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
        <Box mb={1}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
        <Box mb={1}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
        <Box mb={1}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
      </DialogContent>
    );
  }

  return (
    <AgencyUsersManageModalContent
      {...data}
      getTeams={getTeams}
      onSave={modalData.onSubmit}
      closeModal={close}
    />
  );
};

const { t, translationKeys } = getCowbellTranslations();

export const AgencyUsersManageModalConfig = {
  AgencyUsersManageModal: {
    component: AgencyUsersManageModal,
    config: {
      title: `Invite someone to your ${t(
        translationKeys.agency
      ).toLowerCase()}`,
      maxWidth: 'md',
    },
  },
};
