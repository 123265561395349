import React from 'react';

// mui
import { DialogActions, DialogContent } from '@mui/material';

// components
import CbButton from '../../Buttons/CbButton';
import { DocUploadWithAgencyForm } from '../../documents/uploads/DocUploadInfoForms';

export const DocUploadInfo = ({ data, ...props }) => {
  const { Form, Actions, formId = 'upload-document-form', ...rest } = data;

  return (
    <>
      <DialogContent>
        {Form ? (
          <Form formId={formId} {...rest} {...props} />
        ) : (
          <DocUploadWithAgencyForm formId={formId} {...rest} {...props} />
        )}
      </DialogContent>
      <DialogActions>
        {Actions ? (
          <Actions formId={formId} {...rest} />
        ) : (
          <DefaultUploadModalActions formId={formId} />
        )}
      </DialogActions>
    </>
  );
};

const DefaultUploadModalActions = ({ formId }) => {
  return (
    <>
      <CbButton styleName="cancel" buttonText="Cancel" />
      <CbButton
        type="submit"
        form={formId}
        styleName="ctaButton"
        buttonText="Submit"
      />
    </>
  );
};

export const DocUploadInfoConfig = {
  DocUploadInfo: {
    component: DocUploadInfo,
    // all mui related config
    config: {
      fullWidth: true,
      title: 'File Upload Info Modal',
    },
  },
};
