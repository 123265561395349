/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';

import CbButton from '../../../../components/Buttons/CbButton';

import {
  ControlledRadioGroupWithLabel,
  ControlledTextField,
  DialogFooter,
  FormGridContainer,
  DialogContent,
  ControlledSurplusBrokerAutocomplete,
  ControlledAutocomplete,
  ShowableBox,
} from '../helpers';

import { UsStatesFull, UsStatesObjects } from '../../../../utils/USState';

import type { FormStepProps } from '../types';
import type { AggregatedP500FormData } from '../CreateEditPrimePlusPolicyModal';
import type { USState } from '../../../../types/common/misc';
import { Flexbox } from '../../../../components/layout/Flexbox';
import { requiredSelectedOptionTestCreator } from '../utils/validation';

const fieldNames = [
  'surplusCompliance',
  'agencySurplusComplianceName',
  'surplusLinesBroker',
  'surplusLinesBrokerFirstName',
  'surplusLinesBrokerLastName',
  'surplusLinesBrokerEmail',
  'surplusLinesState',
  'surplusLinesNumber',
  'agencyMailingAddress1',
  'agencyMailingAddress2',
  'agencyMailingCity',
  'agencyMailingState',
  'agencyMailingZipCode',
] as const;

interface PolicySurplusComplianceFormProps extends FormStepProps {
  onGoBack: (formValues: Partial<AggregatedP500FormData>) => void;
}

const PolicySurplusComplianceForm: React.FC<
  PolicySurplusComplianceFormProps
> = ({ stepperData, formsData, onFormSubmit, ...props }) => {
  const pickedFormFields = React.useMemo(
    () => _.pick(formsData, fieldNames),
    [formsData]
  );

  const formMethods = useForm<typeof pickedFormFields>({
    defaultValues: {
      ...pickedFormFields,
    },
    resolver: yupResolver(formValidationSchema),
  });

  function handleGoBack() {
    props.onGoBack(formMethods.getValues());
    stepperData.goBack();
  }

  function handleCancel() {
    props.onClose();
  }

  const surplusCompliance = formMethods.watch('surplusCompliance');
  const isSurplusComplianceAgency = surplusCompliance === 'agency';

  const surplusLinesBroker = formMethods.watch('surplusLinesBroker');
  const isSurplusLinesBrokerSelected = Boolean(surplusLinesBroker?.value);

  // facing an issue where using a controller with an object in RHF state is not updating the obj's nested fields' controllers
  // using this imperative approach for now
  React.useEffect(() => {
    formMethods.setValue(
      'surplusLinesBrokerFirstName',
      surplusLinesBroker.meta?.brokerFirstName!
    );
    formMethods.setValue(
      'surplusLinesBrokerLastName',
      surplusLinesBroker.meta?.brokerLastName!
    );
    formMethods.setValue(
      'surplusLinesBrokerEmail',
      surplusLinesBroker.meta?.brokerEmail!
    );
    formMethods.setValue('surplusLinesState', {
      ...UsStatesObjects[surplusLinesBroker.meta.surplusLinesState as USState],
      meta: {},
    });
    formMethods.setValue(
      'surplusLinesNumber',
      surplusLinesBroker.meta.surplusLicenseNumber
    );
  }, [surplusLinesBroker]);

  const stateOptions = React.useMemo(
    () =>
      UsStatesFull.map((state) => ({
        label: state.label,
        value: state.value,
        meta: { ...state },
      })),
    []
  );

  return (
    <>
      <DialogTitle>
        <Box>Generate PrimeCloud Policy</Box>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <form
            id="policy-surplus-compliance"
            onSubmit={formMethods.handleSubmit(onFormSubmit)}
          >
            <FormGridContainer>
              <Typography component="h3">Compliance</Typography>

              <Grid item container spacing={4}>
                <Grid item xs={6}>
                  <ControlledRadioGroupWithLabel
                    name="surplusCompliance"
                    label="Surplus Compliance"
                  >
                    <FormControlLabel
                      value="cowbell"
                      label="Cowbell"
                      control={<Radio color="secondary" />}
                    />
                    <FormControlLabel
                      value="agency"
                      label="Agency"
                      control={<Radio color="secondary" />}
                    />
                  </ControlledRadioGroupWithLabel>
                </Grid>

                <Grid item xs={6}>
                  <ControlledTextField
                    name="agencySurplusComplianceName"
                    label="Surplus Compliance Name"
                    disabled={!isSurplusComplianceAgency}
                  />
                </Grid>
              </Grid>
            </FormGridContainer>

            <ShowableBox show={isSurplusComplianceAgency}>
              <FormGridContainer>
                <Typography component="h3">Agency Mailing Address</Typography>

                <Grid item container spacing={4}>
                  <Grid item xs={6}>
                    <ControlledTextField
                      name="agencyMailingAddress1"
                      label="Address"
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <ControlledTextField
                      name="agencyMailingAddress2"
                      label="Address 2"
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={4}>
                  <Grid item xs={6}>
                    <ControlledTextField
                      name="agencyMailingCity"
                      label="City"
                      required
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <ControlledAutocomplete
                      name="agencyMailingState"
                      label="State"
                      required
                      options={stateOptions}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <ControlledTextField
                      name="agencyMailingZipCode"
                      label="Zip Code"
                      required
                    />
                  </Grid>
                </Grid>
              </FormGridContainer>

              <FormGridContainer>
                <Typography component="h3">Surplus Lines Broker</Typography>

                <Grid item container spacing={4}>
                  <Grid item xs={12}>
                    <ControlledSurplusBrokerAutocomplete
                      name="surplusLinesBroker"
                      label="Broker"
                      agencyId={formsData.agency.value}
                      required
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={0}>
                  <Grid item container spacing={4}>
                    <Grid item xs={6}>
                      <ControlledTextField
                        name="surplusLinesBrokerFirstName"
                        label="First Name"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <ControlledTextField
                        name="surplusLinesBrokerLastName"
                        label="Last Name"
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={4}>
                    <Grid item xs={6}>
                      <ControlledTextField
                        name="surplusLinesBrokerEmail"
                        label="Email"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <ControlledAutocomplete
                        name="surplusLinesState"
                        label="State"
                        disabled
                        options={stateOptions}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <ControlledTextField
                        name="surplusLinesNumber"
                        label="License Number"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </FormGridContainer>
            </ShowableBox>
          </form>
        </FormProvider>

        <ShowableBox
          show={isSurplusComplianceAgency && !isSurplusLinesBrokerSelected}
        >
          <Flexbox justifyContent="center" marginTop="1rem">
            <Typography component="span" className="yellow-text tertia-text">
              <InfoOutlinedIcon component="svg" className="yellow-text" /> Can’t
              find what you are looking for? Add a broker in the Agency License
              Table.
            </Typography>
          </Flexbox>
        </ShowableBox>
      </DialogContent>
      <DialogFooter
        stepNumber={stepperData.stepNumber}
        totalSteps={stepperData.steps.length}
        stepLabel="Surplus Compliance Details"
      >
        <CbButton variant="text" size="x-small" onClick={handleGoBack}>
          Back
        </CbButton>
        <CbButton onClick={handleCancel} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton
          type="submit"
          form="policy-surplus-compliance"
          styleName="ctaButton"
        >
          Next
        </CbButton>
      </DialogFooter>
    </>
  );
};

export default PolicySurplusComplianceForm;

const formValidationSchema = Yup.object().shape({
  surplusCompliance: Yup.string().required().label('Surplus Compliance'),
  agencySurplusComplianceName: Yup.string()
    .when('surplusCompliance', {
      is: (value: string) => value === 'agency',
      then: (schema: any) =>
        schema
          .required()
          .matches(/[A-Za-z0-9]/, 'Must not only contain special characters '),
    })
    .label('Surplus Compliance Name'),
  surplusLinesBroker: Yup.object()
    .when('surplusCompliance', {
      is: (value: string) => value === 'agency',
      then: (schema: any) =>
        schema.test(
          requiredSelectedOptionTestCreator('Broker is a required field')
        ),
    })
    .label('Broker '),
  agencyMailingAddress1: Yup.string()
    .when('surplusCompliance', {
      is: (value: string) => value === 'agency',
      then: (schema: any) => schema.required(),
    })
    .label('Address'),
  agencyMailingAddress2: Yup.string().label('Address 2'),
  agencyMailingCity: Yup.string()
    .when('surplusCompliance', {
      is: (value: string) => value === 'agency',
      then: (schema: any) => schema.required(),
    })
    .label('City'),
  agencyMailingState: Yup.object()
    .when('surplusCompliance', {
      is: (value: string) => value === 'agency',
      then: (schema: any) =>
        schema.test(
          requiredSelectedOptionTestCreator('State is a required field')
        ),
    })
    .label('State'),
  agencyMailingZipCode: Yup.string()
    .when('surplusCompliance', {
      is: (value: string) => value === 'agency',
      then: (schema: any) =>
        schema
          .required()
          .matches(/^[0-9]+$/, 'Must be only digits')
          .min(5, 'Must be exactly 5 digits')
          .max(5, 'Must be exactly 5 digits'),
    })
    .label('Zip Code'),
});
