import React from 'react';

// components
import PolicyEndorsementListing from '../../../policyholder/PolicyHolderEndorsementListing';
import { PolicyCoverageDetails } from './PolicyCoverageDetails';
import { PolicySummary } from './PolicySummary';
import { withShowable } from '../../../../../_global/lib/withShowable';

import { CANCELLED_STATUS } from '../../../../../_statics/policy.statics';
import { CancelledPolicyContent } from '../views/CancelledPolicyContent';

const Showable = withShowable(React.Fragment);

export const PolicyInformationTab = ({ policy, product }) => {
  if (policy?.status === CANCELLED_STATUS) {
    return <CancelledPolicyContent policy={policy} productType={product} />;
  }

  return (
    <>
      <PolicySummary policy={policy} product={product} />
      <Showable mt={2}>
        <PolicyEndorsementListing
          policy={policy}
          productType={product}
          headerText="MIDTERM ENDORSEMENTS"
        />
        <PolicyCoverageDetails policy={policy} product={product} />
      </Showable>
    </>
  );
};
