import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { changeQuoteStatusToReferred } from '../../../api';
import CbButton from '../../Buttons/CbButton';
import { withFormController } from '../../hocs/forms';
import { TextField as TextFieldBase } from '../../inputs';

const TextField = withFormController(TextFieldBase);

const validationSchema = Yup.object().shape({
  reason: Yup.string().required().label('Reason'),
});

export const QuoteChangeToReferred = ({ data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const useFormFields = useForm({
    defaultValues: {
      reason: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit } = useFormFields;

  const onSubmit = ({ reason }) => {
    changeQuoteStatusToReferred({
      data: {
        quoteId: data.quoteId,
        reason,
      },
    })
      .then(() => {
        enqueueSnackbar(`Changed ${data.quoteNumer} to referred status`, {
          variant: 'success',
        });
        data.onTableRefetch?.();
        props.close();
      })
      .catch(() => {
        enqueueSnackbar(
          `Failed ${data.quoteNumer} to change quote to Referred Status`,
          { variant: 'error' }
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...useFormFields}>
        <DialogContent style={{ padding: 0 }} />
        <Box
          minHeight="10rem"
          display="flex"
          flex="1"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Box width="50%">
            <TextField label="Reason" required id="reason" name="reason" />
          </Box>
        </Box>
        <DialogActions>
          <CbButton onClick={props.close} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton type="submit" styleName="ctaButton">
            Change To Referred
          </CbButton>
        </DialogActions>
      </FormProvider>
    </form>
  );
};

export const QuoteChangeToReferredConfig = {
  QuoteChangeToReferred: {
    component: QuoteChangeToReferred,
    config: {
      title: 'Change to Referred',
    },
  },
};
