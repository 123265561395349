import React from 'react';
import * as yup from 'yup';
import { FormContext, useForm } from 'react-hook-form-4';

// components
import { DialogActions, DialogContent, Grid as MuiGrid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CBButton from '../Buttons/CbButton';
import { FeinField } from '../inputs';

import '../../console/_global/lib/validations/FeinSchemaType';

import { withFormController } from '../hocs/withFormController';

// utils
import { Modal } from './v2/helpers/v2.modal.helpers';

const RegisteredFeinField = withFormController(FeinField);

const schema = yup.object().shape({
  fein: yup
    .string()
    .fein()
    .length(9, 'Not a valid, a valid EIN / FEIN is 9 digits')
    .label('FEIN')
    .required(),
});

export const AddFEIN = ({ data = {}, ...props }) => {
  const classes = useClasses();

  const { handleSubmit, ...methods } = useForm({
    defaultValues: { fein: data.fein },
    validationSchema: schema,
  });

  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (formData) => {
    return data.onUpdateFein(formData).then(() => {
      props.close();
      Modal.show('UploadW9', { data });
    });
  };

  const handleCancel = () => {
    props.close();
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent classes={{ root: classes.container }}>
          <MuiGrid container>
            <p className={classes.header}>Add FEIN</p>
            <p className={classes.description}>
              Add FEIN, also known as a federal tax identification number or an
              employer identification number (EIN), issued to entities that do
              business in the United States. The FEIN is a unique nine-digit
              corporate ID number.
            </p>
            <MuiGrid item md={12}>
              <RegisteredFeinField
                name="fein"
                label="EIN / FEIN"
                required
                placeholder="Enter your EIN / FEIN"
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <DialogActions>
          <CBButton action={handleCancel} styleName="cancel">
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="NEXT"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  container: {
    maxHeight: '45rem',
    overflow: 'auto',
    paddingBottom: '1rem',
    color: palette.primary.contrastText,
  },
  description: {
    color: palette.primary.contrastText,
    lineHeight: 1.17,
    textAlign: 'left',
    fontSize: '1rem',
  },
  header: {
    fontSize: '1.6667rem',
    lineHeight: 0.9,
    textAlign: 'left',
    margin: '2rem 0 0 0',
    color: palette.primary.contrastText,
  },
}));

export const AddFEINConfig = {
  AddFEIN: {
    component: AddFEIN,
    config: {
      minWidth: 'sm',
      title: 'Add your FEIN and upload W9 form',
    },
  },
};
