import React from 'react';
import _ from 'lodash';
// mui
import { makeStyles } from '@mui/styles';

// components
import { CurrentEndorsementCompare } from './CurrentEndorsementCompare';
import { getCoverageFormat, getDiffCoverages } from './endorsement.utils';

export const DrawerCoverageSummary = ({ current = {}, existingKeys = [] }) => {
  const classes = useStyles();

  const formattedEndorsements = getCoverageFormat(current?.coverages);

  const endorsements = getDiffCoverages(existingKeys, formattedEndorsements);

  if (_.isEmpty(existingKeys)) {
    return null;
  }

  return (
    <>
      <div className={classes.heading}>Coverage/Limits</div>
      <CurrentEndorsementCompare
        endorsement={endorsements}
        diffs={existingKeys}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  heading: {
    textDecoration: 'underline',
    'text-underline-offset': '2px',
    textTransform: 'uppercase',
    textAlign: 'left',
    fontSize: '1.16rem',
    fontWeight: 600,
    lineHeight: 1.5,
    color: palette.primary.contrastText,
    paddingTop: '2rem',
    paddingBottom: '0.66rem',
  },
}));
