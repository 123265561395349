import React from 'react';
import { useHistory } from 'react-router-dom';
// helpers
import { useSnackbar } from 'notistack';
import _ from 'lodash';

// mui
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
// hocs
import { useQuery } from '@tanstack/react-query';
import { withShowable } from '../../../../../_global/lib/withShowable';
import { useSteps } from '../../../../../../components/hooks/useSteps';
// components
import P100RenewalPolicyHolderDetails from './AgencyPrime100RenewalPolicyHolderDetails';
import P100RenewalSignedUploadApp from './P100RenewalSignedUploadApp';
import P100RenewalIssuePolicyConfirmation from './P100RenewalIssuePolicyConfirmation';
import P100RenewalEmailConfirmation from './AgencyPrime100RenewalEmailConfirmation';
import P100RenewalGenerateNKLL from './P100RenewalGenerateNKLL';
import P100RenewalError from './P100RenewalError';
import CbButton from '../../../../../../components/Buttons/CbButton';
import { ModalTitle } from './GenericModalTitle';
// statics
import {
  API_ERROR,
  EMAIL_CONFIRMATION,
  POLICY_HOLDER_CONTACT,
  POLICY_ISSUE_CONFIRMATION,
  SEND_TO_POLICYHOLDER,
  UPLOAD_NKLL,
  UPLOAD_SIGNED_APP,
} from '../../../../../_statics/conditionalBind.statics';
// actions

import { uploadDocumentV3 } from '../../../../../../api/documents.api';
import { deleteDocumentById } from '../../../../../../api/DocumentsService';
import {
  completeQuoteSubjectivity,
  getQuoteProgress,
  agentIssuePolicy,
} from '../../../../../../api';
import { manageAPIError } from '../../../../../../utils';
import { subjectivityKeys } from '../../../../bind-quote/constants/constants';
import { agentBindQuote } from '../../../../../../api/bind-quote.api';
import { sleep } from '../../../../../../utils/appUtils';
import { ProductTypes } from '../../../../../../types';
import { sendQuote } from '../../../../../../inbox/QuotesService';
import {
  AGENCY_DASHBOARD_LISTING_TABS,
  useAgencyDashboardFeature,
} from '../../../../../dashboard/agency/agency-dashboard.statics';

const P100RenewalRequestToBindWorkflow = ({ data, ...props }) => {
  const { quote, setRefresh, existingAppInfoForAccount } = data;
  const { agencyStatus, noKnownLossLetterReq } = quote;
  const agencyDashboardFeature = useAgencyDashboardFeature();

  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  // const [conditionalBindFlow, setConditionalBindFlow] = React.useState('');
  const [isNKLLInApp, setIsNKLLInApp] = React.useState(false);
  const [uploadedDoc, setUploadedDoc] = React.useState({
    Application: {},
    No_Known_Loss_Letter: {},
  });
  const [loading, setLoading] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  // disable attestation till the file does not get uploaded
  const [disableAttestation, setDisableAttestation] = React.useState(true);
  // this is used to store the attestation value on uploaded app if we move to nkll step
  const [isUploadedAppAttested, setUploadedAppAttestation] =
    React.useState(false);
  const [approveDone, setApproveDone] = React.useState(false);
  const [refreshUploadedEffect, setRefreshUploadedEffect] =
    React.useState(false);
  const [subjectivityInfo, setSubjectivityInfo] = React.useState(null);
  const [shouldSendToPH, setShouldSendToPH] = React.useState('');

  const {
    step,
    setSteps,
    goBack,
    goForward,
    jumpTo,
    isFirstStep,
    isLastStep,
    stepNumber,
  } = useSteps({
    initialSteps: signedUploadAppSteps,
    startStep: POLICY_HOLDER_CONTACT,
  });

  const { data: quoteSubjectivities = {} } = useQuery(
    ['quote-progress', data?.quote?.id],
    () => {
      return getQuoteProgress(data?.quote?.id).then(
        ({ data: responseData }) => {
          const policyHolderSubjectivityData =
            responseData.preBindSubjectivities.filter(
              (subjectivity) =>
                subjectivity.subjectivityKey ===
                subjectivityKeys.POLICY_HOLDER_CONTACT
            );

          const insuranceAppSubjectivityData =
            responseData.preIssuanceSubjectivities.filter(
              (subjectivity) =>
                subjectivity.subjectivityKey ===
                subjectivityKeys.P100_RENEWAL_APPLICATION
            );

          return {
            policyHolderSubjectivityData: policyHolderSubjectivityData[0],
            insuranceAppSubjectivityData: insuranceAppSubjectivityData[0],
            query: responseData,
          };
        }
      );
    },
    {
      enabled: !!data?.quote?.id,
      onError: (error) => {
        enqueueSnackbar(
          manageAPIError(error, 'Unable to load subjectivities at this time'),
          { variant: 'error' }
        );
      },
    }
  );

  React.useEffect(() => {
    if (!_.isEmpty(existingAppInfoForAccount)) {
      setUploadedDoc((prevState) => {
        return {
          ...prevState,
          Application: existingAppInfoForAccount.docInfo,
        };
      });
      setDisableAttestation(false);
      setRefreshUploadedEffect(false);
    }
    // eslint-disable-next-line
  }, [existingAppInfoForAccount, refreshUploadedEffect]);

  React.useEffect(() => {
    if (agencyStatus === 'BOUND') {
      setSteps(boundAlreadyUploadAppSteps);
      jumpTo(UPLOAD_SIGNED_APP);
    } else {
      setSteps(signedUploadAppSteps);
    }

    // eslint-disable-next-line
  }, [quote]);

  const handleBind = async (payload, subjectivityParams = null) => {
    setLoading(true);

    let subjectivityCompletionError = false;
    if (subjectivityParams) {
      await completeQuoteSubjectivity(
        ProductTypes.p100,
        subjectivityParams.quoteSubjectivityId,
        {
          data: subjectivityParams.payload,
        }
      ).catch(() => {
        subjectivityCompletionError = true;
        enqueueSnackbar(
          'Unable to confirm policy holder details. Please try again later.',
          { variant: 'error' }
        );
        return 'ERROR';
      });
    }

    // Just to ensure we dont complete this step if we cant complete the subj first
    if (subjectivityCompletionError) {
      return setLoading(false);
    }

    await sleep(1500);

    return agentBindQuote(data?.quote?.id)
      .then(() => {
        setApproveDone(true);
        enqueueSnackbar(
          `Quote ${_.get(quote, 'quoteNumber')} approved successfully!`,
          {
            variant: 'success',
          }
        );
        goForward();
      })
      .catch((error) => {
        setApproveDone(false);
        enqueueSnackbar(
          _.get(
            error,
            'response.data.message',
            'There was an issue with your request, please try again'
          ),
          {
            variant: 'error',
          }
        );
        jumpTo(API_ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hanldeTemporarySignedAppUpload = React.useCallback(
    ({ file, axiosConfig, docType }) => {
      const formData = new FormData();
      formData.append('file', file);

      return uploadDocumentV3({
        data: formData,
        params: {
          accountId: _.get(data, 'policy.accountId'),
          agencyId: _.get(data, 'policy.agencyId'),
          docType,
          docName: file.name,
          temp: true,
        },
        ...axiosConfig,
      }).then((resp) => {
        setUploadedDoc((prevState) => {
          return {
            ...prevState,
            [docType]: resp.data,
          };
        });
        setShouldSendToPH(null);
        enqueueSnackbar('Document uploaded successfully.', {
          variant: 'success',
        });
        setDisableAttestation(false);
      });
    },
    [data, enqueueSnackbar]
  );

  const deleteUplaodedDocFromS3 = (docType) => {
    deleteDocumentById({ docId: uploadedDoc[docType].docId }).then(() => {
      setUploadedDoc({});
      setShouldSendToPH(null);
    });
  };

  const sendEmailToPH = async () => {
    const {
      customerFirstName,
      customerLastName,
      customerPhone,
      customerEmail,
    } = quote;

    const payload = {
      customerFirstName,
      customerLastName,
      customerEmail,
      customerPhone,
    };

    return sendQuote({
      productType: ProductTypes.p100,
      quoteId: quote.id,
      payload,
    })
      .then(() => {
        handleModalClose();
        enqueueSnackbar(
          `Sent Successfully to ${customerFirstName} ${customerLastName} at ${customerEmail}`,
          { variant: 'success' }
        );
      })
      .catch((error) => {
        const message = manageAPIError(
          error,
          'Unable to send to policy holder. Please try again later.'
        );
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  const handleIssuePolicy = async (formData, subjectivityParams = null) => {
    if (shouldSendToPH === SEND_TO_POLICYHOLDER) {
      jumpTo(EMAIL_CONFIRMATION);
    } else if (
      shouldSendToPH === null &&
      noKnownLossLetterReq === 'REQUIRED' &&
      !isNKLLInApp &&
      !formData.isNKLLAcknowledged
    ) {
      // store params in state in case user need to add nkll
      setSubjectivityInfo(subjectivityParams);
      goForward();
      setUploadedAppAttestation(formData.isAppAcknowledged);
    } else {
      setLoading(true);
      let subjectivityCompletionError = false;
      if (subjectivityInfo || subjectivityParams) {
        const subjectivityMetaData = subjectivityInfo || subjectivityParams;
        const application = {
          docId: uploadedDoc.Application.docId,
          docSignedOn: subjectivityMetaData.docSignedOn,
        };
        const nkll = uploadedDoc.No_Known_Loss_Letter.docId
          ? [
              {
                docId: uploadedDoc.No_Known_Loss_Letter.docId,
                docSignedOn: subjectivityMetaData.docSignedOn,
              },
            ]
          : [];
        const uploadedDocuments = [application, ...nkll];
        await completeQuoteSubjectivity(
          ProductTypes.p100,
          subjectivityMetaData.quoteSubjectivityId,
          {
            data: {
              uploadedDocuments,
              nkllIsIncluded:
                noKnownLossLetterReq === 'REQUIRED' && isNKLLInApp,
              ...subjectivityMetaData.payload,
            },
          }
        ).catch(() => {
          subjectivityCompletionError = true;
          enqueueSnackbar(
            'Unable to upload signed app at this time. Please try again later',
            { variant: 'error' }
          );
        });
      }

      // Dont want to proceed if api fails
      if (subjectivityCompletionError) {
        return setLoading(false);
      }

      agentIssuePolicy(data?.quote?.id)
        .then(() => {
          setUploadedAppAttestation(false);
          jumpTo(POLICY_ISSUE_CONFIRMATION);
        })
        .catch(() => {
          jumpTo(API_ERROR);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  /* reset to first step on modal close */
  React.useEffect(() => {
    if (!props.open) {
      setIsNKLLInApp(false);
      setDisable(false);
      setDisableAttestation(true);
      setUploadedAppAttestation(false);
      setUploadedDoc({ Application: {}, No_Known_Loss_Letter: {} });
      setRefreshUploadedEffect(true);
      setRefresh(true);
      setShouldSendToPH(null);

      if (approveDone) {
        if (step === POLICY_ISSUE_CONFIRMATION) {
          setSteps(signedUploadAppSteps);
          jumpTo(UPLOAD_SIGNED_APP);

          if (agencyDashboardFeature.on) {
            push(
              `/agency/dashboard?tab=${AGENCY_DASHBOARD_LISTING_TABS.POLICIES}`
            );
          } else {
            push('/agency/policies');
          }
        } else if (step === EMAIL_CONFIRMATION) {
          setSteps(signedUploadAppSteps);
          jumpTo(UPLOAD_SIGNED_APP);

          if (agencyDashboardFeature.on) {
            push(
              `/agency/dashboard?tab=${AGENCY_DASHBOARD_LISTING_TABS.BINDERS}`
            );
          } else {
            push('/agency/binders');
          }
        } else if (!isLastStep) {
          // needed because approve takes time from BE to invalidate all other ready quotes/binders
          // motive is to have only one binder active at this stage
          window.location.reload();
          setSteps(boundAlreadyUploadAppSteps);
          jumpTo(UPLOAD_SIGNED_APP);
        } else {
          setSteps(boundAlreadyUploadAppSteps);
          jumpTo(UPLOAD_SIGNED_APP);
        }
      } else {
        setSteps(signedUploadAppSteps);
        jumpTo(POLICY_HOLDER_CONTACT);
        // this is needed for binders getting issued
        if (agencyStatus === 'BOUND' && step === EMAIL_CONFIRMATION) {
          push('/agency/binders');
        }
        if (agencyStatus === 'BOUND' && step === POLICY_ISSUE_CONFIRMATION) {
          push('/agency/policies');
        }
      }
    }
    // eslint-disable-next-line
  }, [props.open]);

  const handleModalClose = () => {
    props.close();
  };

  const sharedProps = {
    data,
    onCancel: handleModalClose,
    ...props,
  };

  return (
    <Dialog {...props}>
      <ModalTitle
        title={`Bind Quote for ${_.get(data, 'quote.companyName')}`}
        {...props}
      />

      <DialogContent style={{ padding: '2rem 5rem' }}>
        <Step
          show={step === POLICY_HOLDER_CONTACT}
          display="flex"
          justifyContent="center"
        >
          <P100RenewalPolicyHolderDetails
            {...sharedProps}
            onBindQuote={handleBind}
            quoteSubjectivityData={
              quoteSubjectivities.policyHolderSubjectivityData
            }
          />
        </Step>

        <Step show={step === UPLOAD_SIGNED_APP}>
          <P100RenewalSignedUploadApp
            {...sharedProps}
            isNKLLInApp={isNKLLInApp}
            existingAppInfoForAccount={existingAppInfoForAccount}
            disableAttestation={disableAttestation}
            onDelete={deleteUplaodedDocFromS3}
            onUpload={hanldeTemporarySignedAppUpload}
            onIssuePolicy={handleIssuePolicy}
            setDisable={setDisable}
            setIsNKLLInApp={setIsNKLLInApp}
            quoteSubjectivityData={
              quoteSubjectivities.insuranceAppSubjectivityData
            }
            setShouldSendToPH={setShouldSendToPH}
            shouldSendToPH={shouldSendToPH}
          />
        </Step>

        <Step show={step === UPLOAD_NKLL}>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item md={8}>
              <P100RenewalGenerateNKLL
                {...sharedProps}
                disableAttestation={disableAttestation}
                onDelete={deleteUplaodedDocFromS3}
                onUpload={hanldeTemporarySignedAppUpload}
                onIssuePolicy={handleIssuePolicy}
                setDisable={setDisable}
                setIsNKLLInApp={setIsNKLLInApp}
              />
            </Grid>
          </Grid>
        </Step>

        <Step show={step === EMAIL_CONFIRMATION}>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item md={8}>
              <P100RenewalEmailConfirmation quote={quote} />
            </Grid>
          </Grid>
        </Step>

        <Step show={step === POLICY_ISSUE_CONFIRMATION}>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item md={8}>
              <P100RenewalIssuePolicyConfirmation {...sharedProps} />
            </Grid>
          </Grid>
        </Step>

        <Step show={step === API_ERROR}>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item md={8}>
              <P100RenewalError />
            </Grid>
          </Grid>
        </Step>

        <Step show={step === SEND_TO_POLICYHOLDER}>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item md={8}>
              <P100RenewalError />
            </Grid>
          </Grid>
        </Step>
      </DialogContent>
      <DialogActions className="flex--spaced">
        <StepDisplay
          step={step}
          stepNumber={stepNumber}
          isFirstStep={isFirstStep}
          agencyStatus={agencyStatus}
        />
        <Box>
          <Button
            show={
              !isFirstStep &&
              !oneButtonSteps.includes(step) &&
              step !== UPLOAD_SIGNED_APP
            }
            styleName="cancel"
            onClick={goBack}
            buttonText="Previous"
          />

          <Button
            onClick={handleModalClose}
            styleName="cancel"
            show={
              (isFirstStep && !(step === API_ERROR)) ||
              (step === UPLOAD_SIGNED_APP && !isFirstStep)
            }
            buttonText="Cancel"
          />

          <Button
            onClick={handleModalClose}
            styleName="error"
            show={step === API_ERROR}
            buttonText="OK"
          />

          <Button
            onClick={handleModalClose}
            styleName="ctaButton"
            show={step === POLICY_ISSUE_CONFIRMATION}
            buttonText="OK"
          />

          <Button
            onClick={sendEmailToPH}
            styleName="ctaButton"
            show={step === EMAIL_CONFIRMATION}
            buttonText="OK"
          />

          <Button
            type="submit"
            show={step === POLICY_HOLDER_CONTACT}
            form="POLICY_HOLDER_CONTACT_FORM"
            onBindQuote={handleBind}
            styleName="ctaButton"
            loading={loading}
            disabled={loading}
          >
            Bind
          </Button>

          <Button
            type="submit"
            show={step === UPLOAD_SIGNED_APP}
            form="UPLOAD_APP_FORM"
            styleName="ctaButton"
            loading={loading}
            disabled={loading || disable}
          >
            {(noKnownLossLetterReq === 'REQUIRED' && !isNKLLInApp) ||
            shouldSendToPH !== null
              ? 'Next'
              : 'Issue Policy'}
          </Button>

          <Button
            type="submit"
            show={step === UPLOAD_NKLL}
            form="UPLOAD_NKLL_FORM"
            styleName="ctaButton"
            loading={loading}
            disabled={loading || disable}
            buttonText="Issue Policy"
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

// choosing to upload app steps
const signedUploadAppSteps = [
  POLICY_HOLDER_CONTACT,
  UPLOAD_SIGNED_APP,
  UPLOAD_NKLL,
  POLICY_ISSUE_CONFIRMATION,
  EMAIL_CONFIRMATION,
  API_ERROR,
];

const signedUploadAppStepsLabels = {
  [POLICY_HOLDER_CONTACT]: 'Policy Holder Contact',
  [UPLOAD_SIGNED_APP]: 'Signed App',
  [UPLOAD_NKLL]: 'Upload NKLL',
  [POLICY_ISSUE_CONFIRMATION]: 'Policy Issue Confirmation',
  [EMAIL_CONFIRMATION]: 'Email Confirmation',
  [API_ERROR]: 'Error',
};

// binder upload app steps
const boundAlreadyUploadAppSteps = [
  UPLOAD_SIGNED_APP,
  UPLOAD_NKLL,
  POLICY_ISSUE_CONFIRMATION,
  EMAIL_CONFIRMATION,
  API_ERROR,
];

const boundAlreadyUploadAppStepsLabels = {
  [UPLOAD_SIGNED_APP]: 'Signed App',
  [UPLOAD_NKLL]: 'Upload NKLL',
  [POLICY_ISSUE_CONFIRMATION]: 'Policy Issue Confirmation',
  [EMAIL_CONFIRMATION]: 'Email Confirmation',
  [API_ERROR]: 'Error',
};

// these steps have only one button
const oneButtonSteps = [
  API_ERROR,
  POLICY_ISSUE_CONFIRMATION,
  EMAIL_CONFIRMATION,
];

// supporting components
const StepDisplay = ({ step, stepNumber, agencyStatus }) => {
  if (oneButtonSteps.includes(step))
    return (
      <Typography color="primary" variant="body1">
        {' '}
      </Typography>
    );

  if (agencyStatus === 'BOUND') {
    return (
      <Typography color="primary" variant="body1">
        Step {stepNumber}: {boundAlreadyUploadAppStepsLabels[step]}
      </Typography>
    );
  }
  return (
    <Typography color="primary" variant="body1">
      Step {stepNumber}: {signedUploadAppStepsLabels[step]}
    </Typography>
  );
};

const Step = withShowable(Box);
const Button = withShowable(CbButton);

export const P100RenewalRequestToBindWorkflowConfig = {
  P100RenewalRequestToBindWorkflow: {
    component: P100RenewalRequestToBindWorkflow,
    // all mui related config
    config: {
      override: true,
      maxWidth: 'md',
    },
  },
};

export default P100RenewalRequestToBindWorkflow;
