import React from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
// mui
import { DialogActions, DialogContent } from '@mui/material';

// components
import { makeStyles } from '@mui/styles';
import CBButton from '../../../components/Buttons/CbButton';
// platform helpers
import { delayedEvent } from '../../../utils/eventUtils';
// actions
import { deleteTerritory } from '../sales.service';

const styles = makeStyles({
  title: {
    padding: '2rem 5rem !important',
    fontWeight: 600,
  },
});

export const DeleteSalesInboxTerritory = ({ close, data }) => {
  const { handleSubmit, ...methods } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();

  const onSubmit = () => {
    return deleteTerritory({ id: data.id }, {})
      .then(() => {
        enqueueSnackbar('Territory deleted succesfully!', {
          variant: 'success',
        });
        close();
        delayedEvent('table-refetch', 500, 'SalesTerritories');
      })
      .catch(() => {
        enqueueSnackbar('Unable to delete the territory', { variant: 'error' });
      });
  };

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={`modal-title ${classes.title}`}>
            Are you sure you want to delete this territory?
          </DialogContent>
          <DialogActions>
            <CBButton onClick={close} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton type="submit" styleName="ctaButton">
              Delete
            </CBButton>
          </DialogActions>
        </form>
      </FormContext>
    </>
  );
};

export const DeleteSalesInboxTerritoryConfig = {
  DeleteSalesInboxTerritory: {
    component: DeleteSalesInboxTerritory,
    config: {
      /* any material ui configuration options  */
      fullWidth: true,
      maxWidth: 'sm',
      title: 'Delete Territory',
    },
  },
};
