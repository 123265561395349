import _ from 'lodash';
import React from 'react';
// ui
// components
import { FormContext, useForm } from 'react-hook-form-4';
import {
  DialogContent,
  FormHelperText,
  Grid as MuiGrid,
  DialogActions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import CbButton from '../../../components/Buttons/CbButton';
import { withFormController } from '../../../components/hocs/withFormController';
import { delayedEvent } from '../../../utils/eventUtils';
import { getLeadOpportunityStatuses, updateLead } from '../sales.service';
import { SimpleSelect } from '../../../components/inputs/selects/SimpleSelect';

const Select = withFormController(SimpleSelect);

const schema = Yup.object().shape({
  status: Yup.string().required('Status is required for update.'),
});

export const OpportunityStatus = ({ data, ...props }) => {
  const classes = useClasses();
  const { id } = data;
  const [opportunityList, setOpportunities] = React.useState([]);
  const [backendError, setBackendError] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = { status: data.status || '' };

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    validationSchema: schema,
  });

  const {
    formState: { isSubmitting, dirty },
  } = methods;

  React.useEffect(() => {
    Promise.all([getLeadOpportunityStatuses({})])
      .then(([opportunityStatusResp]) => {
        const opportunities = _.map(opportunityStatusResp.data, (value) => ({
          label: value.value,
          value: value.name,
        }));
        setOpportunities(opportunities);
      })
      .catch(() => {
        enqueueSnackbar('Unable to download data', { variant: 'error' });
      });
  }, [enqueueSnackbar]);

  const onSubmit = (formData) => {
    return updateLead({ id }, { accountId: data?.accountId, ...formData })
      .then(() => {
        enqueueSnackbar('Opportunity updated Successfully!', {
          variant: 'success',
        });
        delayedEvent('table-refetch', 3000, 'SalesInbox');
        props.close();
      })
      .catch((error) => {
        setBackendError(_.get(error.response, 'data.message', 'backendError'));
      });
  };

  const handleCancel = () => {
    props.close();
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent classes={{ root: classes.container }}>
          <MuiGrid container spacing={4}>
            <MuiGrid item md={12}>
              <Select
                name="status"
                label="Status"
                required
                options={opportunityList}
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <FormHelperText className="api-text-error">
          {backendError}
        </FormHelperText>
        <DialogActions>
          <CbButton action={handleCancel} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Update Opportunity"
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

function renderValue(value) {
  return value || 'Select a Lead Status...';
}

const useClasses = makeStyles(({ palette }) => ({
  container: {
    overflowY: 'visible',
    paddingTop: '1rem !important',
    color: palette.primary.contrastText,
  },
}));

export const OpportunityStatusConfig = {
  OpportunityStatus: {
    component: OpportunityStatus,
    config: {
      minWidth: 'md',
      title: 'Opportunity Status',
      PaperProps: {
        style: { overflowY: 'visible' },
      },
    },
  },
};
