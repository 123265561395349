import React from 'react';

//mui
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CloudDownload } from '@mui/icons-material';

//components
import AdminAccountsQuoteDrawerHeader from '../AdminAccountsQuoteDrawerHeader';
import RowDataWrapper from './RowDataWrapper';
import AdminQuoteDrawerSkeleton from '../AdminQuoteDrawerSkeleton';

//services
import { downloadPolicyDocuments } from '../../../../../policies/PolicyService';
import { DocumentDownloader } from '../../../../../components/fileUploader/DocumentDownloader';
import { getCowbellTranslations } from '../../../../../i18n/translations';
import { getIsUsPlatform, getPlatformRegion } from '../../../../../utils';

interface PoliciesTabViewProps {
  policies?: any[];
  isLoading: boolean;
}

const PoliciesTabView: React.FC<PoliciesTabViewProps> = ({
  policies = [],
  isLoading,
}) => {
  const classes = useStyles();

  if (isLoading) {
    return <AdminQuoteDrawerSkeleton />;
  }

  return (
    <>
      {!policies.length ? (
        <Box>No Policies to view</Box>
      ) : (
        <Box className={classes.container}>
          <AdminAccountsQuoteDrawerHeader
            headerContent={policiesHeaderContentProp}
          />
          {policies.map((policy: any, index: number) => (
            <RowDataWrapper key={index} index={index}>
              <Box className={classes.policyNumber}>{policy.policyNumber}</Box>
              <Box className={classes.narrowColumn}>{policy.ui_limit}</Box>
              <Box className={classes.wideColumn}>{policy.ui_deductible}</Box>
              <Box className={classes.wideColumn}>{policy.ui_premium}</Box>
              <Box className={classes.wideColumn}>
                {policy.ui_effectiveDate}
              </Box>
              <Box className={classes.wideColumn}>{policy.ui_endDate}</Box>
              <Box className={classes.wideColumn}>{policy.ui_status}</Box>
              <Box className={classes.narrowColumn}>
                <DocumentDownloader
                  name={`${policy.companyName}-${policy.policyNumber}-Cowbell-Policy`}
                  type=".pdf"
                  id={policy.accountId}
                  onDownload={() =>
                    downloadPolicyDocuments(
                      policy.accountId,
                      policy.id,
                      policy.created,
                      policy.isEndorsement
                    )
                  }
                >
                  {({ handleDocumentDownload }) => (
                    <CloudDownload onClick={handleDocumentDownload} />
                  )}
                </DocumentDownloader>
              </Box>
            </RowDataWrapper>
          ))}
        </Box>
      )}
    </>
  );
};

const { t, translationKeys } = getCowbellTranslations();
const region = getPlatformRegion();
const isUkRegion = !getIsUsPlatform(region);

const policiesHeaderContentProp = [
  {
    style: { width: '20%', textAlign: 'left' },
    text: 'POLICY NUMBER',
  },
  {
    style: { width: '10%' },
    text: 'LIMIT',
  },
  {
    style: { width: '12%' },
    text: isUkRegion
      ? t(translationKeys.retention).toUpperCase()
      : 'DEDUCTIBLE',
  },
  {
    style: { width: '12%' },
    text: 'PREMIUM',
  },
  {
    style: { width: '12%' },
    text: 'EFF. DATE',
  },
  {
    style: { width: '12%' },
    text: 'EXP. DATE',
  },
  {
    style: { width: '12%' },
    text: 'STATUS',
  },
  {
    style: { width: '10%' },
    text: 'ACTIONS',
  },
];

export default PoliciesTabView;

const useStyles = makeStyles(({ palette }) => ({
  container: {
    border: `solid 1px ${palette.primary.border}`,
  },
  policyNumber: {
    width: '20%',
    textAlign: 'left',
  },
  narrowColumn: {
    width: '10%',
  },
  wideColumn: {
    width: '12%',
  },
}));
