import { useState, useEffect } from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm, FormContext } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
import ReactCodeInput from 'react-code-input';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

import { sendMfaCode, verifyMfaCode } from '../../api/AuthService';
import { InputLabelBase } from '../inputs/InputLabelBase';
import CbButton from '../Buttons/CbButton';
import { withFormController } from '../hocs/withFormController';

import { manageAPIError } from '../../utils';
import { useMeQuery } from '../hooks/useMeQuery';
import { useActor } from '../hooks/useActor';
import { usePersona } from '../hooks/usePersona';
import { useToggleModal } from '../../utils/modal.utils';

const CodeInput = withFormController(ReactCodeInput);

const TypeAliases = {
  SMS: 'phone number',
  EMAIL: 'email',
};

export default ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();
  const queryClient = useQueryClient();
  const { type } = data;
  const history = useHistory();
  const actor = useActor();
  const persona = usePersona();

  const [apiError, setApiError] = useState('');
  const classes = useClasses();
  const {
    formState: { isSubmitting },
    handleSubmit,
    ...methods
  } = useForm();

  function fetch() {
    sendMfaCode(type)
      .then()
      .catch((error) => {
        const errorMessage = manageAPIError(
          error,
          'Failure to send SMS. Please try again'
        );

        setApiError(errorMessage);
      });
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  const onSubmit = (formValues) => {
    return verifyMfaCode({
      mfaType: type,
      mfa1: formValues.mfaCode,
    })
      .then(() => {
        toggleModal.direct('SMSEnabled', false);
        enqueueSnackbar(`${type} Enabled Successfully!`, {
          variant: 'success',
        });
        queryClient.invalidateQueries([useMeQuery.QUERY_KEY]);
        if (actor.isInsured && persona.isInsured) {
          setTimeout(() => {
            history.push('/customer/policies');
          }, 2000);
        }
      })
      .catch((error) => {
        const errorMessage = manageAPIError(
          error,
          `Failure to verify ${type}. Please try again!`
        );

        setApiError(errorMessage);
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div className="modal-title">
            {`Please Enter the 6-digit code sent to your ${TypeAliases[type]}.`}
          </div>
          <div className={classes.code}>
            <InputLabelBase required>Enter Access Code</InputLabelBase>
            <CodeInput
              name="mfaCode"
              type="text"
              autoFocus
              className={classes.in}
              fields={6}
            />
          </div>
          <Box onClick={fetch} className={classes.note}>
            Resend Access Code
          </Box>
          <FormHelperText className="api-text-error">{apiError}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" action={close}>
            Cancel
          </CbButton>
          <CbButton
            loading={isSubmitting}
            styleName="ctaButton"
            buttonText="Enable MFA"
            type="submit"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

const useClasses = makeStyles(({ config, palette }) => ({
  wrapper: {
    margin: '1.66rem 5rem',
    padding: '0 1.33rem',
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.833rem',
  },
  note: {
    fontStyle: 'italic',
    fontSize: config.textSizes.normal,
    color: config.colors.cowbellBlue,
    textAlign: 'center',
    cursor: 'pointer',
    padding: '0.833rem',
  },
  in: {
    '& input': {
      borderRadius: 5,
      border: `solid 1px ${config.colors.almostWhite}`,
      background: palette.common.darkBlue,
      marginTop: 5,
      marginLeft: 5,
      color: config.colors.white,
      fontSize: config.textSizes.paragon,
      textAlign: 'center',
      width: 36,
      height: 42,
    },
  },
  code: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 11rem',
  },
}));
