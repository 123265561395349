import React from 'react';
import type { UseQueryResult } from '@tanstack/react-query';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Highlight from 'react-highlighter';
import { useDispatch, useSelector } from 'react-redux';

import {
  Badge as MuiBadge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tab as MuiTab,
  Tabs,
  Typography,
} from '@mui/material';
import { styled, useTheme, makeStyles } from '@mui/styles';

// components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TypeaheadLoadingSkeleton } from './TypeaheadLoadingSkeleton';

// utils
import type { AccountDto } from '../../../../types';
import {
  initialState as initialStoreTeamState,
  selectGlobalTeam,
  setGlobalTeam,
} from '../../../../reducers/team.reducer';
import { Show } from '../../../../components/Show';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import type {
  AgencyDashboardListingTab,
  AgencyDashboardSearchTab,
} from '../agency-dashboard.statics';
import {
  AGENCY_DASHBOARD_SEARCH_TABS,
  AGENCY_DASHBOARD_LISTING_TABS,
} from '../agency-dashboard.statics';
import {
  AllTeamsNullResult,
  CurrentTeamNullResult,
} from './AgencyDashboardNullResults';
import { formatAddressAsString } from '../../../../i18n/forms/LanguageForm';
import { useAuth } from '../../../../components/hooks/useAuth';
import { TOKEN_REALMS } from '../../../_statics/users/roles.statics';
import { COUNTRY_MAPPING } from '../../../../i18n/i18n.language-config';

interface AgencyDashboardSearchAccount extends AccountDto {
  quoteCount: number;
  binderCount: number;
  policyCount: number;
  clientOnAnotherTeam: boolean;
}

interface CustomListingProps {
  onSelect: (params: any) => any;
  searchTerm: string;
  onClose: () => void;
  currentTeamQueryCache: UseQueryResult<AgencyDashboardSearchData>;
  allTeamsQueryCache: UseQueryResult<AgencyDashboardSearchData>;
  myAgencyQueryCache: UseQueryResult<AgencyDashboardSearchData>;
  onChange: (value: string) => void;
}

interface AgencyDashboardSearchData {
  accounts: AgencyDashboardSearchAccount[];
  totalElements: number;
}

const AgencyDashboardSearchResults = ({
  onSelect,
  onClose,
  onChange,
  currentTeamQueryCache,
  allTeamsQueryCache,
  myAgencyQueryCache,
  searchTerm,
}: CustomListingProps) => {
  const theme = useTheme<any>();

  const { data: currentTeamData, isFetching: isCurrentTeamDataFetching } =
    currentTeamQueryCache;

  const { data: allTeamsData, isFetching: isAllTeamsDataFetching } =
    allTeamsQueryCache;

  const { data: myAgencyData, isFetching: isMyAgencyDataFetching } =
    myAgencyQueryCache;

  const currentTeamAccounts = currentTeamData?.accounts;
  const allTeamAccounts = allTeamsData?.accounts;
  const myAgencyAccounts = myAgencyData?.accounts;

  const [tab, setTab] = React.useState<AgencyDashboardSearchTab>(
    AGENCY_DASHBOARD_SEARCH_TABS.CURRENT_TEAM
  );

  const team = useSelector(selectGlobalTeam);

  const handleTabChange = (
    event: React.ChangeEvent<object>,
    newTab: AgencyDashboardSearchTab
  ) => {
    setTab(newTab);
  };

  const handleSelect = (value: AccountDto) => {
    onSelect(value);
    onChange(value.name);
    onClose();
  };

  if (
    isCurrentTeamDataFetching ||
    isAllTeamsDataFetching ||
    isMyAgencyDataFetching
  ) {
    return <TypeaheadLoadingSkeleton />;
  }

  return (
    <Box padding={2}>
      <Box display="flex" justifyContent="center">
        <Typography variant="caption" style={{ padding: '1rem' }}>
          Search by client name, quote number, binder number, or policy number
        </Typography>
      </Box>
      <Divider
        style={{
          backgroundColor: theme.agencyDash.border.divider,
          margin: '0 1rem 1rem 1rem',
        }}
      />
      <Show when={team.id !== 'all'}>
        <TeamTabs
          tab={tab}
          tabBadgeCounts={{
            currentTeam: currentTeamData?.totalElements,
            allTeams: allTeamsData?.totalElements,
            myAgency: myAgencyData?.totalElements,
          }}
          onChange={handleTabChange}
        />
      </Show>
      <SearchListing
        currentTeamAccounts={currentTeamAccounts}
        allTeamAccounts={allTeamAccounts}
        myAgencyAccounts={myAgencyAccounts}
        searchTerm={searchTerm}
        tab={tab}
        onTabChange={handleTabChange}
        onClick={handleSelect}
      />
    </Box>
  );
};

interface TabBadgeCounts {
  currentTeam: number | undefined;
  allTeams: number | undefined;
  myAgency: number | undefined;
}

interface TeamTabsProps {
  tab: AgencyDashboardSearchTab;
  tabBadgeCounts: TabBadgeCounts;
  onChange: (
    event: React.ChangeEvent<object>,
    newTab: AgencyDashboardSearchTab
  ) => void;
}

const TeamTabs = ({ tab, tabBadgeCounts, onChange }: TeamTabsProps) => {
  const tabClasses = useTabStyles();
  const auth = useAuth();

  return (
    <>
      <Tabs
        value={tab}
        onChange={onChange}
        textColor="secondary"
        TabIndicatorProps={{ className: tabClasses.indicator }}
      >
        <Tab
          value={AGENCY_DASHBOARD_SEARCH_TABS.CURRENT_TEAM}
          label={
            <Badge color="secondary" badgeContent={tabBadgeCounts.currentTeam}>
              Current Team
            </Badge>
          }
          style={{ minHeight: '4rem' }}
        />
        <Tab
          value={AGENCY_DASHBOARD_SEARCH_TABS.ALL_MY_TEAMS}
          label={
            <Badge color="secondary" badgeContent={tabBadgeCounts.allTeams}>
              All My Teams
            </Badge>
          }
        />
        {auth.tokenRealm === TOKEN_REALMS.t && (
          <Tab
            value={AGENCY_DASHBOARD_SEARCH_TABS.MY_AGENCY}
            label={
              <Badge color="secondary" badgeContent={tabBadgeCounts.myAgency}>
                My Agency
              </Badge>
            }
          />
        )}
      </Tabs>
      <Divider />
    </>
  );
};

interface SearchListingProps {
  currentTeamAccounts: AgencyDashboardSearchAccount[] | undefined;
  allTeamAccounts: AgencyDashboardSearchAccount[] | undefined;
  myAgencyAccounts: AgencyDashboardSearchAccount[] | undefined;
  searchTerm: string;
  tab: AgencyDashboardSearchTab;
  onTabChange: (
    event: React.ChangeEvent<object>,
    newTab: AgencyDashboardSearchTab
  ) => void;
  onClick: (value: AccountDto) => void;
}

const SearchListing = ({
  currentTeamAccounts,
  allTeamAccounts,
  myAgencyAccounts,
  searchTerm,
  tab,
  onTabChange,
  onClick,
}: SearchListingProps) => {
  const {
    accounts,
    accountExistsInCurrentTeam,
    accountExistsInAllMyTeams,
    accountExistsInMyAgency,
  } = formatAccountDataForSearchListing(
    currentTeamAccounts,
    allTeamAccounts,
    myAgencyAccounts,
    tab
  );

  const handleAllMyTeamsClick: React.MouseEventHandler = (event) => {
    onTabChange(event, AGENCY_DASHBOARD_SEARCH_TABS.ALL_MY_TEAMS);
    event.stopPropagation();
  };

  const handleMyAgencyClick: React.MouseEventHandler = (event) => {
    onTabChange(event, AGENCY_DASHBOARD_SEARCH_TABS.MY_AGENCY);
    event.stopPropagation();
  };

  if (
    tab === AGENCY_DASHBOARD_SEARCH_TABS.CURRENT_TEAM &&
    !accountExistsInCurrentTeam
  ) {
    if (accountExistsInAllMyTeams) {
      return (
        <CurrentTeamNullResult
          onAllMyTeamsClick={handleAllMyTeamsClick}
          onMyAgencyClick={handleMyAgencyClick}
        />
      );
    }
    if (accountExistsInMyAgency) {
      return (
        <AllTeamsNullResult
          accountExistsInMyAgency
          onMyAgencyClick={handleMyAgencyClick}
        />
      );
    }

    return (
      <AllTeamsNullResult
        accountExistsInMyAgency={false}
        onMyAgencyClick={handleMyAgencyClick}
      />
    );
  }

  if (
    tab === AGENCY_DASHBOARD_SEARCH_TABS.ALL_MY_TEAMS &&
    !accountExistsInAllMyTeams
  ) {
    if (accountExistsInMyAgency) {
      return (
        <AllTeamsNullResult
          accountExistsInMyAgency
          onMyAgencyClick={handleMyAgencyClick}
        />
      );
    }

    return (
      <AllTeamsNullResult
        accountExistsInMyAgency={false}
        onMyAgencyClick={handleMyAgencyClick}
      />
    );
  }

  if (
    tab === AGENCY_DASHBOARD_SEARCH_TABS.MY_AGENCY &&
    !accountExistsInMyAgency
  ) {
    return (
      <AllTeamsNullResult
        isMyAgencyTab
        accountExistsInMyAgency={false}
        onMyAgencyClick={handleMyAgencyClick}
      />
    );
  }

  return (
    <Box style={{ height: '31rem', overflow: 'auto' }}>
      <List>
        {accounts?.map((account: AgencyDashboardSearchAccount) => (
          <AccountListItem
            key={account.id}
            account={account}
            searchTerm={searchTerm}
            onClick={onClick}
          />
        ))}
      </List>
    </Box>
  );
};

interface AccountListItemProps {
  account: AgencyDashboardSearchAccount;
  searchTerm: string;
  onClick: (value: AccountDto) => void;
}

const AccountListItem = ({
  account,
  searchTerm,
  onClick,
}: AccountListItemProps) => {
  const dispatch = useDispatch();
  const theme = useTheme<any>();
  const listClasses = useListStyles(account);
  const { handleListingTabChange } = useAgencyDashboardState();

  const language =
    COUNTRY_MAPPING[account.country as keyof typeof COUNTRY_MAPPING];

  const address = formatAddressAsString({
    language,
    address1: account.address1,
    address2: account.address2,
    address3: account.address3,
    city: account.city,
    state: account.state,
    zipCode: account.zipCode,
  });

  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    tab: AgencyDashboardListingTab
  ) => {
    if (!account.clientOnAnotherTeam) {
      onClick(account);
      handleListingTabChange(tab);
      dispatch(setGlobalTeam(initialStoreTeamState));
      event.stopPropagation();
    }
  };

  return (
    <>
      <ListItem
        className={listClasses.listItem}
        onClick={(event) =>
          handleActionClick(event, AGENCY_DASHBOARD_LISTING_TABS.ACCOUNTS)
        }
      >
        <ListItemText
          primary={
            <Highlight
              search={searchTerm}
              matchStyle={{
                fontWeight: 'bold',
                backgroundColor: 'transparent',
              }}
            >
              {account.name}
            </Highlight>
          }
          secondary={address}
          secondaryTypographyProps={{
            variant: 'caption',
            style: { color: theme.agencyDash.text.secondary },
          }}
        />
        <AccountDetails account={account} onActionClick={handleActionClick} />
      </ListItem>
      <Divider
        style={{
          backgroundColor: theme.agencyDash.border.divider,
          margin: '0 1rem',
        }}
      />
    </>
  );
};

interface AccountDetailsProps {
  account: AgencyDashboardSearchAccount;
  onActionClick: (
    event: React.MouseEvent<HTMLElement>,
    tab: AgencyDashboardListingTab
  ) => void;
}

const AccountDetails = ({ account, onActionClick }: AccountDetailsProps) => {
  const theme = useTheme<any>();

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Show when={account.clientOnAnotherTeam}>
          <Typography
            variant="caption"
            style={{ color: theme.agencyDash.text.warning }}
          >
            This Client is with someone else within your Agency!
          </Typography>
        </Show>

        <Show when={!account.clientOnAnotherTeam}>
          <Show when={account.quoteCount > 0}>
            <Typography
              onClick={(event) =>
                onActionClick(event, AGENCY_DASHBOARD_LISTING_TABS.QUOTES)
              }
              style={{
                color: theme.agencyDash.interactable,
              }}
            >
              {account.quoteCount}{' '}
              {account.quoteCount === 1 ? 'QUOTE' : 'QUOTES'}
            </Typography>
            <LinkIcon />
          </Show>
          <Show when={account.policyCount > 0}>
            <Typography
              onClick={(event) =>
                onActionClick(event, AGENCY_DASHBOARD_LISTING_TABS.POLICIES)
              }
              style={{
                marginLeft: '2rem',
                color: theme.agencyDash.interactable,
              }}
            >
              {account.policyCount}{' '}
              {account.policyCount === 1 ? 'POLICY' : 'POLICIES'}
            </Typography>
            <LinkIcon />
          </Show>
          <Show when={account.binderCount > 0}>
            <Typography
              onClick={(event) =>
                onActionClick(event, AGENCY_DASHBOARD_LISTING_TABS.BINDERS)
              }
              style={{
                marginLeft: '2rem',
                color: theme.agencyDash.interactable,
              }}
            >
              {account.binderCount}{' '}
              {account.binderCount === 1 ? 'BINDER' : 'BINDERS'}
            </Typography>
            <LinkIcon />
          </Show>
        </Show>
      </Box>
      <Typography
        variant="caption"
        style={{ color: theme.agencyDash.text.secondary }}
        align="right"
      >
        {account.url}
      </Typography>
    </Box>
  );
};

const formatAccountDataForSearchListing = (
  currentTeamAccounts: AgencyDashboardSearchAccount[] | undefined,
  allTeamAccounts: AgencyDashboardSearchAccount[] | undefined,
  myAgencyAccounts: AgencyDashboardSearchAccount[] | undefined,
  tab: AgencyDashboardSearchTab
) => {
  let accounts: AgencyDashboardSearchAccount[] | undefined = [];

  switch (tab) {
    case AGENCY_DASHBOARD_SEARCH_TABS.CURRENT_TEAM:
      accounts = currentTeamAccounts;
      break;
    case AGENCY_DASHBOARD_SEARCH_TABS.ALL_MY_TEAMS:
      accounts = allTeamAccounts;
      break;
    case AGENCY_DASHBOARD_SEARCH_TABS.MY_AGENCY:
      accounts = myAgencyAccounts;
      break;
    default:
      break;
  }

  const accountExistsInCurrentTeam = Boolean(
    currentTeamAccounts && currentTeamAccounts.length > 0
  );

  const accountExistsInAllMyTeams = Boolean(
    allTeamAccounts && allTeamAccounts.length > 0
  );

  const accountExistsInMyAgency = Boolean(
    myAgencyAccounts && myAgencyAccounts.length > 0
  );

  return {
    accounts,
    accountExistsInCurrentTeam,
    accountExistsInAllMyTeams,
    accountExistsInMyAgency,
  };
};

const useListStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      backgroundColor: (account: AgencyDashboardSearchAccount) =>
        !account.clientOnAnotherTeam
          ? theme.agencyDash.background.default
          : null,
      cursor: (account: AgencyDashboardSearchAccount) =>
        !account.clientOnAnotherTeam ? 'pointer' : null,
    },
  },
}));

const useTabStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: theme.agencyDash.interactable,
  },
}));

const Tab = styled(MuiTab)(({ theme }: { theme: any }) => ({
  '&.Mui-selected': {
    color: theme.agencyDash.interactable,
  },
  padding: '1.5rem 3rem 0.2rem 1rem',
}));

const Badge = styled(MuiBadge)(({ theme }: { theme: any }) => ({
  '& .MuiBadge-colorSecondary': {
    backgroundColor: theme.agencyDash.interactable,
    top: '-5px',
    right: '-11px',
  },
}));

const LinkIcon = () => {
  const theme = useTheme<any>();

  return (
    <FontAwesomeIcon
      icon={solid('arrow-up-right-from-square')}
      size="sm"
      style={{
        color: theme.agencyDash.icon,
        marginLeft: '0.3rem',
      }}
    />
  );
};

export default AgencyDashboardSearchResults;
