import { commonDateParamMappings } from '../../api/common/mappings';
import { trimAndAddAsterisk } from '../../utility/QueryUtility';

export const salesInboxServiceMappings = [
  ...commonDateParamMappings,
  { path: 'size' },
  { path: 'dateFilter' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      const newOrder = order || 'asc';
      switch (orderBy) {
        case 'keyword':
          return `${orderBy},${newOrder}`;
        case 'targetDate':
        case 'targetPremium':
        case 'winRate':
        case 'competitor':
        case 'maxPremium':
        case 'minPremium':
        case 'avgPremium':
        case 'productType':
        case 'modified':
        case 'created':
        case 'agencies.commission':
        case 'action':
        case 'id':
        case 'cities':
        case 'stateAbbr':
          return `${orderBy},${newOrder}`;
        // SalesInboxV2 Mapping, should not effect v1
        // will remove deprecated values once v1 is deprecated
        case 'commission':
          return `agencies.commission,${newOrder}`;
        case 'agencyName':
          return `agencies.agencyName.keyword,${newOrder}`;
        default:
          return orderBy ? `${orderBy}.keyword,${newOrder}` : null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search, searchValue }) => {
      if (searchValue) {
        return searchValue;
      }
      return search ? trimAndAddAsterisk(search) : null;
    },
  },
  {
    path: 'searchAfter',
    transform: ({ searchAfter }) => {
      if (Array.isArray(searchAfter)) {
        const copy = [...searchAfter];
        const id = copy.pop();
        const name = copy.join(',');

        return [name, id].join(encodeURIComponent(','));
      }

      return searchAfter;
    },
  },
  {
    path: 'pageRequested',
  },
];
