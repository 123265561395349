import Moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

//mui
import { IconButton, MenuItem as MenuItemBase } from '@mui/material';

//components
import { SimpleMenu } from '../../../../components/Menus/SimpleMenu';
import TableMenuIcon from '../../../../_assets/svg/TableMenuIcon.svg';
import { ProtectedMenuItem } from './ProtectedMenuItem';

//hooks & hocs
import { withShowable } from '../../../_global/lib/withShowable';
import { useAllow } from '../../../../components/hooks/useAllow';

//services
import {
  downloadQuoteDocuments,
  downloadQuoteProposal,
} from '../../../../inbox/QuotesService';
import { downloadDocument } from '../../../../utils/documents.utils';
import { QuoteAgencyStatus } from '../../../../types/common/misc';

//types
import type { QuoteSearchRowDto } from '../../../../types/quotes/tables/quote-table-row.dto';
import { ProductTypes } from '../../../../types';
import { SubmitPxQuoteForUWReviewConfig } from '../../../../components/modals/SubmitPxQuoteForUWReview';
import { Modal } from '../../../../components/modals/v2/helpers/v2.modal.helpers';

const MenuItem = withShowable(MenuItemBase);

interface P100ProAgencyMoreOptionsProps {
  rowData: QuoteSearchRowDto;
}

const PrimeXAgencyMoreOptions = ({
  rowData,
}: P100ProAgencyMoreOptionsProps) => {
  const handlers = usePrimeXAgencyQuoteMoreOptionsHandlers(rowData);

  const CAN_MANAGE_ACCOUNT = useAllow(['accounts:manage']);

  return (
    <SimpleMenu
      id="agency-inbox-options"
      anchor={
        <IconButton size="small" aria-haspopup="true">
          <TableMenuIcon />
        </IconButton>
      }
    >
      <MenuItem show={CAN_MANAGE_ACCOUNT} onClick={handlers.editAccount}>
        Edit Account Details
      </MenuItem>

      <ProtectedMenuItem
        show={
          rowData.agencyStatus === QuoteAgencyStatus.READY &&
          !rowData.isEndorsement
        }
        onClick={handlers.bindQuote}
      >
        Bind Quote
      </ProtectedMenuItem>

      <ProtectedMenuItem
        show={
          rowData.agencyStatus === QuoteAgencyStatus.READY &&
          rowData.product === ProductTypes.prime_one
        }
        onClick={handlers.handleSubmitForUWReview}
      >
        Submit For Underwriter Review
      </ProtectedMenuItem>

      <MenuItem
        show={rowData.agencyStatus === QuoteAgencyStatus.READY}
        onClick={handlers.downloadProposal}
        disabled={!rowData.isProposalAvailable}
      >
        Download Proposal
      </MenuItem>

      <MenuItem
        show={rowData.agencyStatus === QuoteAgencyStatus.READY}
        onClick={handlers.downloadApplication}
        disabled={!rowData.isApplicationAvailable}
      >
        Download Application
      </MenuItem>
    </SimpleMenu>
  );
};

export default PrimeXAgencyMoreOptions;

export const usePrimeXAgencyQuoteMoreOptionsHandlers = (
  rowData: QuoteSearchRowDto
) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const editAccount = () => {
    history.push({
      pathname: `/agency/quote/details/${rowData.id}/${rowData.product}/account-profile`,
      search: 'liveView',
    });
  };

  const bindQuote = () => {
    history.push(`/agency/bind/quote/${rowData.product}/${rowData.id}`);
  };

  const downloadProposal = () => {
    downloadDocument({
      asyncDownload: () =>
        downloadQuoteProposal(
          rowData.accountId,
          rowData.id,
          Moment(rowData.created).unix()
        ),
      blobFileParams: {
        docName: `${rowData.companyName}-${rowData.quoteNumber}-Cowbell-Proposal`,
        docType: '.pdf',
      },
      enqueueSnackbar,
    });
  };

  const downloadApplication = () => {
    downloadDocument({
      asyncDownload: () =>
        downloadQuoteDocuments(
          rowData.accountId,
          rowData.id,
          Moment(rowData.created).unix(),
          rowData.isEndorsement
        ),
      blobFileParams: {
        docName: `${rowData.companyName}-${rowData.quoteNumber}-Cowbell-Application`,
        docType: '.pdf',
      },
      enqueueSnackbar,
    });
  };

  const handleSubmitForUWReview = () => {
    Modal.show(SubmitPxQuoteForUWReviewConfig, {
      data: {
        quoteId: rowData.id,
        productType: rowData.product,
      },
    });
  };

  return {
    editAccount,
    bindQuote,
    downloadProposal,
    downloadApplication,
    handleSubmitForUWReview,
  };
};
