import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { useForm, FormContext } from 'react-hook-form-4';

import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { withStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';
import CbButton from '../../Buttons/CbButton';
import { regeneratePolicyDocs } from '../../../policies/PolicyService';

export const RegeneratePolicyDocuments = compose(
  withStyles(({ config }) => ({
    highlight: {
      color: config.colors.cowbellBlue,
      fontWeight: config.weights.bold,
    },
  }))
)(({ data, classes, ...props }) => {
  const { id, product, policyNumber } = data;
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, ...methods } = useForm({});

  const onSubmit = () => {
    regeneratePolicyDocs({ id, product })
      .then(() => {
        enqueueSnackbar(
          'Policy submitted for Document Regeneration!. Updated policy documents will be ready in about 2 minutes',
          { variant: 'success' }
        );
        props.close();
      })
      .catch(() => {
        enqueueSnackbar('Policy Documents Regeneration request failed!', {
          variant: 'error',
        });
      })
      .finally(() => props.close());
  };

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className="paf">
            <DialogContentText>
              Are you sure you want to regenerate the Documents for policy
              number <span className={classes.highlight}>{policyNumber}</span> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CbButton onClick={props.close} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton type="submit" styleName="ctaButton">
              Regenerate
            </CbButton>
          </DialogActions>
        </form>
      </FormContext>
    </>
  );
});

export const RegeneratePolicyDocumentsModalConfig = {
  RegeneratePolicyDocuments: {
    component: RegeneratePolicyDocuments,
    config: {
      fullWidth: true,
      title: 'Regenerate Policy Documents',
    },
  },
};
