import { makeStyles } from '@mui/styles';
import { SnackbarProvider as BaseProvider } from 'notistack';

import type { WithChildrenProps } from '../types/components/common';

function SnackbarProvider({ children }: WithChildrenProps) {
  const snackbarClasses = useSnackbarDefaultStyles();

  return (
    <BaseProvider {...SnackbarPropsDefaults} classes={snackbarClasses}>
      {children}
    </BaseProvider>
  );
}

export default SnackbarProvider;

const SnackbarPropsDefaults = {
  preventDuplicate: true,
  maxSnack: 3,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
} as const;

const useSnackbarDefaultStyles = makeStyles(
  ({ palette }: { palette: any }) => ({
    anchorOriginTopRight: {
      marginTop: 20,
      top: 100,
    },

    variantWarning: {
      color: palette.common.darkGrey,
      backgroundColor: palette.common.yellow,
    },

    variantError: {
      color: palette.error.contrastText,
      backgroundColor: `${palette.error.main} !important`,
    },
  })
);
