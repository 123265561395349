import { trimAndAddAsterisk } from '../utility/QueryUtility';

export const fetchNaicsCodesParamsMapping = [
  { path: 'page' },
  { path: 'size' },
  {
    path: 'search',
    transform: ({ search }) => (search ? trimAndAddAsterisk(search) : null),
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'coverageLimit':
        case 'industryClass':
        case 'isSupported':
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          break;
        default:
          if (orderBy && order) {
            return `${orderBy}.keyword,${order}`;
          }
      }

      return null;
    },
  },
];
