import _ from 'lodash';
import { Box } from '@mui/material';
import { Skeleton as SkeletonBase } from '@mui/lab';

const Skeleton = () => (
  <SkeletonBase
    width="calc(100% - 2rem)"
    variant="rectangular"
    animation="wave"
    height="4.5rem"
    style={{ margin: '0.4rem 1rem', borderRadius: 5 }}
  />
);

export const AgencydashboardListingLoadingSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column">
      {_.times(10, Skeleton)}
    </Box>
  );
};
