import React from 'react';
import { useTheme, IconButton } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { useQueryClient } from '@tanstack/react-query';
import SimpleTooltip from '../../../../SimpleTooltip';

export const RefreshButton = ({ onClick, loading }) => {
  const theme = useTheme();
  return (
    <>
      {loading ? (
        <>
          <IconButton
            size="small"
            style={{
              cursor: 'pointer',
              color: theme.palette.text.primary,
              marginLeft: '0.6rem',
            }}
          >
            <CachedIcon
              style={{
                fontSize: '2rem',
                position: 'relative',
                right: 1,
                animation: 'spin 0.5s ease-out infinite',
              }}
            />
            <style>{`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(-360deg); }
              }
            `}</style>
          </IconButton>
        </>
      ) : (
        <IconButton
          onClick={onClick}
          size="small"
          style={{
            cursor: 'pointer',
            color: theme.palette.text.primary,
            marginLeft: '0.4rem',
          }}
        >
          <SimpleTooltip title="Refresh Table Contents">
            <CachedIcon
              style={{ fontSize: '2rem', position: 'relative', right: 1 }}
            />
          </SimpleTooltip>
        </IconButton>
      )}
    </>
  );
};
