import React from 'react';
import { styled, Typography as TypographyBase } from '@mui/material';
import { SubjectivitySubTypeLabelMap } from '../../utils/bindQuoteWorkFlowUtils';

export const ScreenTitle = ({ subjectivitySubType, title = '' }) => {
  return (
    <Typography variant="h4">
      {SubjectivitySubTypeLabelMap[subjectivitySubType]} Subjectivity: {title}
    </Typography>
  );
};

const Typography = styled(TypographyBase)({
  fontWeight: 600,
  fontSize: '1.5rem',
});
