import React from 'react';
// lodash
import _ from 'lodash';
// mui
import { DialogActions, DialogContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import CbButton from '../../components/Buttons/CbButton';

export const ConfirmRescindBOR = ({
  close,
  data,
  handleFinalSubmit,
  loading,
}) => {
  const classes = useStyles();

  const onSubmit = () => {
    if (_.isFunction(handleFinalSubmit)) {
      handleFinalSubmit();
    }
  };

  return (
    <>
      <DialogContent className={classes.wrapper}>
        <p>
          <strong>{data.agencyName}</strong> will remain on the account{' '}
          <strong>{data.companyName}</strong>.
        </p>
        <p>Are you sure you want to stop the BOR change?</p>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid className={classes.pagination}>Step 2 of 2: Rescind</Grid>
          <Grid>
            <CbButton buttonText="Cancel" styleName="cancel" onClick={close} />
            <CbButton
              buttonText="Confirm"
              styleName="ctaButton"
              onClick={onSubmit}
              loading={loading}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const useStyles = makeStyles(({ config, palette }) => ({
  wrapper: {
    color: palette.primary.contrastText,
    padding: '2rem 5rem !important',
    fontSize: config.textSizes.primer,
    lineHeight: 1.2,
    textAlign: 'center',
  },
  pagination: {
    fontSize: config.textSizes.tertia,
    color: palette.primary.contrastText,
  },
}));
