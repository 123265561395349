import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { DialogContentText, Grid } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { useFileTypeIcons } from '../../fileUploader/useFileTypeIcons';
import { SimpleSelect } from '../../inputs';
import { withFormController } from '../../hocs/forms';

import { getDocTypes } from '../../../api/DocumentsService';
import { getAccountDetails } from '../../../api';

const TextField = withFormController(TextFieldBase);
const Select = withFormController(SimpleSelect);

const defaultValidationSchema = Yup.object().shape({
  docType: Yup.string().required().label('Doc Type'),
  docName: Yup.string().required().label('File Name'),
});

export const DocUploadInfoForm = ({
  file,
  formId,
  onConfirm,
  validationSchema = defaultValidationSchema,
  docParams,
  ...props
}) => {
  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const classes = useStyles();
  const { data: docTypesRaw, isLoading: areDocTypesLoading } = useDocTypes({
    docParams,
  });

  const docTypes = transformDocTypesForSelect(docTypesRaw);

  const fileDetails = React.useMemo(() => {
    const splitString = file.name.split('.');
    const extension = splitString.pop();
    const fileName = splitString.join('.');
    return { extension, fileName };
  }, [file.name]);

  const renderFileIcon = useFileTypeIcons(fileDetails.extension);

  const onSubmit = (values) => {
    props.close();
    if (typeof onConfirm === 'function') {
      return onConfirm(values);
    }
  };

  if (areDocTypesLoading) return null;

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item container md={12} justifyContent="left">
            <Grid item md={4}>
              File Name:
            </Grid>
            <Grid item md={8}>
              <TextField name="docName" fullWidth required data-qa="docName" />
            </Grid>
          </Grid>
          <Grid item container md={12} justifyContent="left">
            <Grid item md={4}>
              File Type:
            </Grid>
            <Grid item md={8}>
              {renderFileIcon}
            </Grid>
          </Grid>
          <Grid item container md={12} justifyContent="left">
            <Grid item md={4}>
              Doc Type:
            </Grid>
            <Grid item md={8}>
              <Select name="docType" options={docTypes} required />
              <ErrorMessage
                name="docType"
                render={({ message }) => (
                  <span className={classes.errorText}>{message}</span>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const DocUploadWithAgencyForm = ({
  file,
  formId,
  onConfirm,
  validationSchema = defaultValidationSchema,
  attachmentMetaData,
  docParams,
  ...props
}) => {
  const classes = useStyles();
  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { accountId } = attachmentMetaData;
  const { data: account } = useAccount({ accountId });
  const agencies = _.get(account, 'agencies', []);
  const agencyOptions = transformAgenciesForSelect(agencies);

  const { data: docTypesRaw } = useDocTypes({
    docParams,
  });

  const docTypes = transformDocTypesForSelect(docTypesRaw);

  const fileDetails = React.useMemo(() => {
    const splitString = file.name.split('.');
    const extension = splitString.pop();
    const fileName = splitString.join('.');
    return { extension, fileName };
  }, [file.name]);

  const renderFileIcon = useFileTypeIcons(fileDetails.extension);

  function onSubmit(values) {
    props.close();
    if (typeof onConfirm === 'function') {
      return onConfirm({ ...values });
    }
  }

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item>
            <DialogContentText>
              If Select Agency field is left blank, document will be visible to
              all interested agencies.
            </DialogContentText>
          </Grid>
          <Grid container spacing={2}>
            <Grid item container md={12} justifyContent="left">
              <Grid item md={4}>
                Select Agency:
              </Grid>
              <Grid item md={8}>
                <Select name="agencyId" options={agencyOptions} required />
              </Grid>
            </Grid>
            <Grid item container md={12} justifyContent="left">
              <Grid item md={4}>
                File Name:
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="docName"
                  fullWidth
                  required
                  data-qa="docName"
                />
              </Grid>
            </Grid>
            <Grid item container md={12} justifyContent="left">
              <Grid item md={4}>
                File Type:
              </Grid>
              <Grid item md={8}>
                {renderFileIcon}
              </Grid>
            </Grid>
            <Grid item container md={12} justifyContent="left">
              <Grid item md={4}>
                Doc Type:
              </Grid>
              <Grid item md={8}>
                <Select name="docType" options={docTypes} required />
                <ErrorMessage
                  name="docType"
                  render={({ message }) => (
                    <span className={classes.errorText}>{message}</span>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

const transformDocTypesForSelect = (docTypesRaw) =>
  _.map(docTypesRaw, (v, k) => {
    return {
      label: k,
      value: v,
    };
  });

const transformAgenciesForSelect = (agenciesRaw) => {
  const agencyMapping = agenciesRaw.map((agency) => {
    return {
      label: agency.agencyName,
      value: agency.agencyId,
    };
  });

  return [{ label: 'All', value: undefined }, ...agencyMapping];
};

const useAccount = ({ accountId }) => {
  return useQuery(['account', accountId], () =>
    getAccountDetails(accountId).then((res) => res.data)
  );
};

const useDocTypes = ({ docParams }) => {
  return useQuery(['doc-types'], () =>
    getDocTypes(docParams).then((res) => res.data)
  );
};

const useStyles = makeStyles(({ palette }) => ({
  errorText: {
    color: palette.text.text9,
    display: 'inherit',
  },
}));
