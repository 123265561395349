import { FormHelperText } from '@mui/material';
import { withFormController } from '../../hocs/forms';
import { TextFieldBase } from '../TextFieldBase';

const ControlledTextField = withFormController(TextFieldBase);

export const ChipField = ({ label = '', name, ...props }) => {
  return (
    <>
      <ControlledTextField
        showErrorPlace={false}
        name={name}
        label={label}
        fullWidth
        {...props}
      />
      <FormHelperText />
    </>
  );
};
