import React, { useState } from 'react';
import _ from 'lodash';

import { useForm } from 'react-hook-form-4';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { delayedEvent } from '../../utils/eventUtils';
import CbButton from '../Buttons/CbButton';
import { markCheckInMail } from '../../policies/PolicyService';
import { COWBELL_POLICY } from '../tables/table_constants';

export const MarkCheckInMailConfirm = ({ close, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const [error, setError] = useState('');
  const { id, policyNumber } = data;

  const onSubmit = () => {
    markCheckInMail(id)
      .then(() => {
        enqueueSnackbar(`Policy ${policyNumber} was marked as Check In Mail`, {
          variant: 'success',
        });
        close();

        queryClient.invalidateQueries([COWBELL_POLICY]);
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong while updating.'
            )
          )
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <div className="modal-title">
          Are you sure you want to mark policy {policyNumber} as Check In Mail?
        </div>
        {error && (
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={close}>
          Cancel
        </CbButton>
        <CbButton
          loading={isSubmitting}
          styleName="ctaButton"
          buttonText="Update"
          type="submit"
        />
      </DialogActions>
    </form>
  );
};

export default MarkCheckInMailConfirm;
