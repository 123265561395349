import React from 'react';

import { DialogActions, DialogContent, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import CbButton from '../../Buttons/CbButton';
import TextFieldBase from '../../inputs/text-fields/base/TextFieldBase';
import { InputLabelBase } from '../../inputs/InputLabelBase';
import { createSpotlight } from '../../../api/risk/risk.api';
import { withFormController } from '../../hocs/forms';
import { COWBELL_SPOTLIGHTS } from '../../tables/table_constants';

const TextField = withFormController(TextFieldBase);

export const CreateSpotlight = (props) => {
  const queryClient = useQueryClient();
  const { handleSubmit, ...formMethods } = useForm({
    // resolver: yupResolver(schema),
  });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (values) => {
    createSpotlight({ data: values })
      .then(() => {
        enqueueSnackbar('Spotlight has been created!', { variant: 'success' });
        queryClient.invalidateQueries([COWBELL_SPOTLIGHTS]);
        props.close();
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong while creating the spotlight.', {
          variant: 'error',
        });
      });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField name="spotlightName" label="Name" required />
            </Grid>
            <Grid item xs={6}>
              <TextField name="cveList" label="CVE(s)" required />
            </Grid>
            <Grid item xs={6}>
              <TextField name="productList" label="Product(s)" required />
            </Grid>
            <Grid item xs={6}>
              <TextField name="remediation" label="Remedy" required />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="effectiveDate"
                label="Effective Date"
                type="date"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="durationInDays"
                label="Duration (in days)"
                type="number"
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputLabelBase required>Description</InputLabelBase>
            <TextField name="description" minRows={5} multiline />
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton onClick={props.close} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton type="submit" styleName="ctaButton">
            Create Spotlight
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const CreateSpotlightConfig = {
  CreateSpotlight: {
    component: CreateSpotlight,
    // all mui related config
    config: {
      fullWidth: true,
      maxWidth: 'md',
      title: 'Create Spotlight',
    },
  },
};
