import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Divider,
  Grid,
  CircularProgress as MuiCircularProgress,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// helpers
import { useQueryClient } from '@tanstack/react-query';
import Numeral from 'numeral';
import { InputLabelBase } from '../inputs/InputLabelBase';
// componenets
import CbButton from '../Buttons/CbButton';
import TextField from '../inputs/TextField';
// actions
import {
  createPremFinance,
  getInstallmentAmount,
} from '../../console/customers/_services/BillingService';
import { COWBELL_POLICY } from '../tables/table_constants';
import LanguageCurrencyField from '../inputs/LanguageCurrencyField';

const textStyle = {
  paddingTop: 0,
  paddingBottom: 20,
};

const styles = ({ config }) => {
  return {
    container: {
      textAlign: 'left !important',
      padding: '0 4rem',
      fontSize: '1rem !important',
      marginTop: '-1rem',
      '& .MuiInputBase-input': {
        textAlign: 'right',
        padding: '11px',
        marginTop: '-20px',
      },
    },
    header: {
      textAlign: 'center',
    },
    premium: {
      textAlign: 'center',
      margin: '0 0 0.5rem',
      fontSize: config.textSizes.normal,
    },
    divider: {
      marginBottom: '0.833rem',
    },
    select: {
      justifyContent: 'center',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    label: {
      padding: 0,
      fontSize: config.textSizes.normal,
    },
  };
};

export const CreatePremiumFinancing = withStyles(styles)(
  ({ classes, close, data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const { accountId, id, customerEmail, totalPremium, policyNumber } = data;

    const [error, setError] = useState('');
    const [apiResp, setResp] = useState('');
    const [installmentAmount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false);

    const {
      handleSubmit,
      getValues,
      setValue,
      register,
      watch,
      formState: { isSubmitting },
    } = useForm({
      defaultValues: {
        apr: '',
        numberOfInstallments: '',
        downPayment: '',
        financerName: '',
      },
    });

    register({ name: 'downPayment', required: true });
    register({ name: 'apr', required: true });
    register({ name: 'numberOfInstallments', required: true });
    register({ name: 'financerName', required: true });

    watch(['downPayment', 'numberOfInstallments', 'apr', 'financerName']);

    const values = getValues();

    const callInstallment = useCallback(
      _.debounce((payload) => {
        const { apr, downPayment, numberOfInstallments } = payload;
        if (apr && downPayment && numberOfInstallments) {
          setLoading(true);

          getInstallmentAmount(
            {},
            {
              apr,
              financedAmount: (
                totalPremium - Number(downPayment.replace(/[^0-9.-]+/g, ''))
              ).toFixed(2),
              numberOfInstallments,
            }
          )
            .then((response) => {
              setLoading(false);
              setResp(response.data);
              setAmount(response.data.installmentAmount);
            })
            .catch((error) => {
              setLoading(false);
              setError(
                _.get(
                  error.response,
                  'data.message',
                  _.get(
                    error.response,
                    'data',
                    'Something went wrong.Please try again later.'
                  )
                )
              );
            });
        }
      }, 1000),
      []
    );

    const onChange = (event, name) => {
      const { value } = event.target;
      setValue(name, value);

      const { apr, downPayment, numberOfInstallments } = values;
      const payload = {
        apr: name === 'apr' ? value : apr,
        downPayment: name === 'downPayment' ? value : downPayment,
        numberOfInstallments:
          name === 'numberOfInstallments' ? value : numberOfInstallments,
      };

      callInstallment(payload);
    };

    const onSubmit = (formData) => {
      const downPayment = Number(
        formData.downPayment.replace(/[^0-9.-]+/g, '')
      );
      const payload = {
        ...formData,
        ...apiResp,
        accountId,
        policyId: id,
        customerEmail,
        totalPremium,
        apr: parseInt(formData.apr),
        numberOfInstallments: parseInt(formData.numberOfInstallments),
        downPayment,
        amountFinanced: parseFloat(
          Numeral(totalPremium - downPayment)._value.toFixed(2)
        ),
      };

      return createPremFinance({}, payload)
        .then(() => {
          close();
          enqueueSnackbar('Premium Financer added Successfully!', {
            variant: 'success',
          });

          queryClient.invalidateQueries([COWBELL_POLICY]);
        })
        .catch((error) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Something went wrong.Please try again later.'
              )
            )
          );
        });
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={`modal-title ${classes.container}`}>
          <h2 className={classes.header}>Policy# {policyNumber}</h2>
          <Divider className={classes.divider} />

          <div style={{ paddingRight: '1.66rem' }}>
            <Grid container>
              <Grid item md={4}>
                <InputLabelBase required className={classes.label}>
                  Financer
                </InputLabelBase>
              </Grid>
              <Grid item md={8}>
                <TextField
                  id="financerName"
                  placeholder="Financer Name"
                  fullWidth
                  onChange={(e) => onChange(e, 'financerName')}
                  inputProps={{ required: true }}
                  style={textStyle}
                />
              </Grid>
            </Grid>
            <Grid container style={{ paddingBottom: '0.833rem' }}>
              <Grid item md={4}>
                Total Premium
              </Grid>
              <Grid item md={8} style={{ textAlign: 'right' }}>
                {Numeral(totalPremium).format('$0,0.[00]')}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={9}>
                <InputLabelBase required className={classes.label}>
                  Down Payment
                </InputLabelBase>
              </Grid>
              <Grid item md={3} style={{ marginTop: '1rem' }}>
                <LanguageCurrencyField
                  id="downPayment"
                  name="downPayment"
                  fullWidth
                  style={{ marginTop: '0', ...textStyle }}
                  placeholder="Only digits"
                  inputProps={{ required: true }}
                  onChange={(e) => onChange(e, 'downPayment')}
                />
              </Grid>
            </Grid>
          </div>

          <Divider className={classes.divider} />

          <div style={{ paddingRight: '1.66rem' }}>
            <Grid container style={{ paddingBottom: '0.833rem' }}>
              <Grid item md={4}>
                Amount Financed
              </Grid>
              <Grid item md={8} style={{ textAlign: 'right' }}>
                {Numeral(
                  totalPremium -
                    Number(values.downPayment.replace(/[^0-9.-]+/g, ''))
                ).format('$0,0.[00]')}
              </Grid>
            </Grid>
          </div>

          <Divider className={classes.divider} />

          <div style={{ paddingRight: '1.66rem' }}>
            <Grid container>
              <Grid item md={4}>
                <InputLabelBase required className={classes.label}>
                  APR
                </InputLabelBase>
              </Grid>

              <Grid container item md={8} justifyContent="flex-end">
                <Grid item md={3}>
                  <TextField
                    id="apr"
                    fullWidth
                    placeholder="%"
                    inputProps={{ required: true, step: 0.001 }}
                    onChange={(e) => onChange(e, 'apr')}
                    style={textStyle}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item md={4}>
                <InputLabelBase required className={classes.label}>
                  Number of Installments
                </InputLabelBase>
              </Grid>
              <Grid container item md={8} justifyContent="flex-end">
                <Grid item md={3}>
                  <TextField
                    id="numberOfInstallments"
                    onChange={(e) => onChange(e, 'numberOfInstallments')}
                    fullWidth
                    inputProps={{ required: true }}
                    style={textStyle}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ paddingBottom: '0.833rem' }}>
              <Grid item md={4}>
                Installments Schedule
              </Grid>
              <Grid item md={8} style={{ textAlign: 'right' }}>
                Monthly
              </Grid>
            </Grid>
          </div>

          <Divider className={classes.divider} />

          <div style={{ paddingRight: '1.66rem' }}>
            <Grid container style={{ paddingBottom: '0.833rem' }}>
              <Grid item md={4}>
                Installment Amount
              </Grid>
              <Grid item md={8} style={{ textAlign: 'right' }}>
                {loading && <MuiCircularProgress size={14} />}
                {Numeral(installmentAmount).format('$0,0.[00]')}
              </Grid>
            </Grid>
          </div>

          {error && (
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Add"
            loading={isSubmitting}
            disabled={isSubmitting || !installmentAmount}
          />
        </DialogActions>
      </form>
    );
  }
);

export default CreatePremiumFinancing;
