import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import Showable from '../../../../../../Showable';
import { InputLabelBase } from '../../../../../../inputs/InputLabelBase';
import {
  ControlledRenewalOptOutReasonAutocomplete,
  DialogActionsBase,
  ControlledCompetitorsAutocomplete,
  DialogContentBase,
  ControlledTextArea,
  ControlledTextField,
} from '../helpers';

import { requiredSelectedOptionTestCreator } from '../../../../../../../accounts/Modals/CreateEditPrimePlusPolicyModal/utils/validation';
import { FILL_IN_OPTION, WENT_WITH_COMPETITOR } from '../utils/misc';

const formDefaultValues = {
  renewalOptOutReason: {
    value: '',
    label: '',
  },
  competitor: {
    value: '',
    label: '',
  },
  fillInReason: '',
  description: '',
};

function RenewalOptOutForm({ onSubmit, onCancel }) {
  const formMethods = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(formSchema),
  });

  const [renewalOptOutReason] = formMethods.watch(['renewalOptOutReason']);

  const isOptOutReasonCompetitor =
    renewalOptOutReason.value == WENT_WITH_COMPETITOR;

  const isOptOutReasonFillInOption =
    renewalOptOutReason.value == FILL_IN_OPTION;

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <DialogContentBase>
            <Grid container>
              <Grid item xs={12}>
                <ControlledRenewalOptOutReasonAutocomplete
                  label="Reason for Opting-Out of Renewal Process"
                  name="renewalOptOutReason"
                  required
                />
              </Grid>

              <Showable show={isOptOutReasonCompetitor}>
                <Grid item xs={12}>
                  <ControlledCompetitorsAutocomplete
                    label="Competitor"
                    name="competitor"
                    required
                  />
                </Grid>
              </Showable>

              <Showable show={isOptOutReasonFillInOption}>
                <Grid item xs={12}>
                  <ControlledTextField name="fillInReason" required />
                </Grid>
              </Showable>

              <Grid item xs={12}>
                <InputLabelBase indent style={{ padding: '0.5rem 0' }}>
                  Description
                </InputLabelBase>
                <ControlledTextArea
                  name="description"
                  maxRows={6}
                  minRows={10}
                  fullWidth
                  multiline
                  blueBackground={false}
                />
              </Grid>
            </Grid>
          </DialogContentBase>
          <DialogActionsBase
            onCancel={onCancel}
            confirmationButtonText="Opt-Out"
          />
        </form>
      </FormProvider>
    </>
  );
}

const formSchema = Yup.object().shape({
  renewalOptOutReason: Yup.object().test(
    requiredSelectedOptionTestCreator(
      'Renewal Opt-Out Reason is a required field'
    )
  ),
  competitor: Yup.object().when('renewalOptOutReason', {
    is: (field) => field.value == WENT_WITH_COMPETITOR,
    then: (schema) =>
      schema.test(
        requiredSelectedOptionTestCreator('Competitor is a required field')
      ),
  }),
  fillInReason: Yup.string()
    .label('Fill-in Reason')
    .when('renewalOptOutReason', {
      is: (field) => field.value == FILL_IN_OPTION,
      then: (schema) => schema.required(),
    }),
  description: Yup.string().label('Description'),
});

export default RenewalOptOutForm;
