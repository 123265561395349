import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CBButton from '../../Buttons/CbButton';
import { updateOrgProfile } from '../../../accounts/AccountService';

// utils
import { PubSub } from '../../../utils/eventUtils.js';
import {
  ChipField,
  ChipListing,
  useChipField,
  ChipError,
} from '../../inputs/chip-field';
import { ProductTypes } from '../../../types';

const addButtonStyle = {
  marginLeft: '1rem',
};

const generateSchema = (productType) => {
  return Yup.object().shape({
    ani: Yup.array().test(
      'ani',
      deriveErrorMessageFromProductType(productType),
      (value = []) => {
        switch (productType) {
          case ProductTypes.p100:
            return value.join('').length <= 250;
          default:
            return value.length <= 20;
        }
      }
    ),
  });
};

export const AdditionalNamedInsuredModal = ({ data = {}, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { accountName, productType } = data;

  const schema = React.useMemo(
    () => generateSchema(productType),
    [productType]
  );

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
  });

  const defaultValues = _.get(data, 'items', []);

  const {
    intitialChipList,
    chipList,
    getChipFieldProps,
    getChipListingProps,
    getChipErrorProps,
  } = useChipField({
    name: 'ani',
    methods,
    defaultValues,
  });

  const onAddANI = React.useCallback(() => {
    getChipFieldProps().onKeyDown(
      new KeyboardEvent('keypress', {
        key: 'Enter',
      })
    );
  }, [getChipFieldProps]);

  const onSubmit = (formData) => {
    // eslint-disable-next-line no-control-regex
    const cleanedANI = formData.ani
      .map((ani) => ani.trim())
      .filter((ani) => !!ani);
    const payload = { additionalNamedInsured: cleanedANI };

    updateOrgProfile(
      {},
      {
        accountId: data.accountId,
        ...payload,
      }
    )
      .then((resp) => {
        enqueueSnackbar(
          'Succesfully updated Additional Named Insured values!',
          { variant: 'success' }
        );
        PubSub.publish('ani:list:updated', {
          items: resp.data.additionalNamedInsured,
        });
        props.close();
      })
      .catch((error) => {
        enqueueSnackbar(
          _.get(
            error,
            'response.data',
            'There was a problem with your request, please try again'
          ),
          { variant: 'error' }
        );
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '3rem 4rem' }}>
          <h2>{accountName}</h2>
          <DialogContentText>
            The First Named Insured will be included automatically and does not
            need to be listed below.
          </DialogContentText>
          <Box display="flex" mb={1}>
            <ChipField
              {...getChipFieldProps({
                placeholder:
                  'Type in ANI and hit "Enter" or press the Add button',
                autoFocus: true,
              })}
            />
            <CBButton
              style={addButtonStyle}
              onClick={onAddANI}
              styleName="ctaButton"
              size="x-small"
            >
              Add
            </CBButton>
          </Box>
          <ChipListing {...getChipListingProps()} />
          <ChipError {...getChipErrorProps()} />
        </DialogContent>
        <DialogActions>
          <CBButton size="medium" styleName="cancel" onClick={props.close}>
            Cancel
          </CBButton>
          <CBButton
            disabled={intitialChipList.length === chipList.length}
            size="medium"
            type="submit"
            styleName="ctaButton"
          >
            Update
          </CBButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const AdditionalNamedInsuredConfig = {
  AdditionalNamedInsuredModal: {
    component: AdditionalNamedInsuredModal,
    config: {
      fullWidth: true,
      title: 'Add/Edit Additional Named Insured',
    },
  },
};

// helpers
const deriveErrorMessageFromProductType = (productType) => {
  switch (productType) {
    case ProductTypes.p100:
      return 'Combined input must be less than or equal to 250 characters';
    case ProductTypes.p250:
      return 'ANI list may not exceed 20 entities';
    default:
      return 'Invalid Product Type';
  }
};
