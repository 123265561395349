import _ from 'lodash';
import React, { PureComponent } from 'react';

import { InputAdornment, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';

import SearchIcon from '@mui/icons-material/Search';
import withRouter from '../../hocs/withRouter';

class SearchBox extends PureComponent {
  constructor(props) {
    super(props);
    const { handleSearch } = props;
    if (handleSearch) {
      this.debounceSearch = _.debounce(handleSearch, 1000);
    } else {
      this.debounceSearch = _.debounce(this.onSearch.bind(this), 1000);
    }
  }

  render() {
    const { props } = this;
    const { classes } = this.props;

    return (
      <>
        <TextField
          variant="standard"
          onChange={(e) => this.debounceSearch(e.target.value)}
          className={classes.searchBoxWrapper}
          defaultValue={this.props.location.query.search}
          InputProps={{
            placeholder: this.props.placeholder,
            classes: {
              root: classes.searchBoxNaked,
              focused: classes.searchBoxFocused,
            },
            endAdornment: (
              <InputAdornment
                classes={{ root: classes.searchIcon }}
                position="end"
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {props.filterIcon}
      </>
    );
  }

  onSearch(value) {
    const { props } = this;
    props.updateUrl({ search: value, page: 0 });
  }
}

SearchBox.defaultProps = {
  id: 'search-box',
  placeholder: 'Search',
  variant: 'filled',
};

const styles = ({ palette }) => {
  return {
    searchBoxNaked: {
      position: 'relative',
      border: `5px solid ${palette.background.lighter}`,
      color: palette.text.primary,

      '&:before': {
        display: 'none',
        position: 'initial',
      },

      '&:after': {
        display: 'none',
        position: 'initial',
      },

      '& > input': {
        background: palette.background.default,
        color: palette.text.primary,
      },

      '.action-bar &': {
        border: 'unset',
      },

      '.action-bar & input': {
        height: 'unset',
        padding: '0.4166666667rem 0.8333333333rem',
      },
    },

    searchBoxFocused: {
      '& svg': {
        color: palette.primary.contrastText,
      },
    },

    searchBoxWrapper: {
      width: '100%',
      backgroundColor: palette.background.lighter,
      color: palette.text.primary,

      '.action-bar &': {
        padding: 0,
        backgroundColor: 'unset',
      },
    },

    searchIcon: {
      height: '90%',
      maxHeight: 'initial',
      width: 40,
      padding: '0 5px',
      marginRight: 1,
      marginLeft: 0,
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',

      '& svg': {
        height: 22,
        width: 22,
      },

      '.action-bar & svg': {
        height: 18,
        width: 18,
      },
    },
  };
};

export default withStyles(styles)(withRouter(SearchBox));
