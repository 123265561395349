/* eslint-disable no-nested-ternary */
import React from 'react';
import classnames from 'classnames';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import NumberFormat from 'react-number-format';
import slugify from 'slugify';
import {
  Input as MuiInput,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import _config from '../../themes/_base/_config';

const TextField = ({
  classes,
  label,
  error,
  helperText,
  inputProps = {},
  containerClass,
  isShowing,
  tooltip,
  required,
  type,
  disabled,
  onBlurHandler = () => {},
  onFocusHandler = () => {},
  placeholder,
  maxlength,
  minlength,
  ...props
}) => {
  if (!isShowing) {
    return null;
  }

  const id = props.id || `text-filed-${slugify(label)}`;

  let inputEle = null;
  let adornmentRootClass = '';
  switch (type) {
    case 'tel':
      if (props.prop === 'true') {
        adornmentRootClass = classes.adornmentRootError;
      } else if (props.check === 'true') {
        adornmentRootClass = classes.adornmentRootCheck;
      } else if (props.loading === 'true') {
        adornmentRootClass = classes.adornmentRootLoading;
      }
      inputEle = (
        <div
          className={`MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-adornedEnd ${classes.inputWrapper}`}
        >
          <NumberFormat
            className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedEnd"
            customInput={MuiInput}
            required
            id={id}
            disabled={disabled}
            {...props}
            inputprops={inputProps}
            inputcomponent={props.inputComponent}
            error={Boolean(error).toString()}
            format={props.format}
            mask={props.mask}
            onBlur={onBlurHandler}
            onFocus={onFocusHandler}
            placeholder={placeholder || ''}
          />
          <div className={`MuiInputAdornment-root ${adornmentRootClass}`}>
            {props.prop === 'true' ? (
              <WarningIcon className={classes.icon} tabIndex="-1" />
            ) : props.check === 'true' ? (
              <CheckIcon className={classes.icon} tabIndex="-1" />
            ) : props.loading === 'true' ? (
              <CircularProgress
                classes={{ root: `${classes.loadingIconRoot}` }}
                color="inherit"
              />
            ) : null}
          </div>
        </div>
      );
      break;
    default:
      inputEle = (
        <MuiInput
          className={`${classes.inputWrapper}  ${
            props.prop === 'true'
              ? classes.input
              : props.check === 'true'
              ? classes.checkInput
              : ''
          }`}
          required={required}
          id={id}
          disabled={disabled}
          type={type}
          maxlength={maxlength}
          minlength={minlength}
          {...props}
          inputProps={{ 'data-qa': `TEXT_INPUT:${props.name}`, ...inputProps }}
          inputComponent={props.inputComponent}
          error={Boolean(error)}
          onChange={props.onChange}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          placeholder={placeholder || ''}
          endAdornment={
            <InputAdornment
              classes={
                props.prop === 'true'
                  ? { root: classes.adornmentRootError }
                  : props.check === 'true'
                  ? { root: classes.adornmentRootCheck }
                  : props.loading === 'true'
                  ? { root: classes.adornmentRootLoading }
                  : null
              }
              position="end"
              className={classes.adornmentContainer}
            >
              {undefined}
              {props.prop === 'true' ? (
                <WarningIcon className={classes.icon} tabIndex="-1" />
              ) : props.check === 'true' ? (
                <CheckIcon className={classes.icon} tabIndex="-1" />
              ) : props.loading === 'true' ? (
                <CircularProgress
                  classes={{ root: `${classes.loadingIconRoot}` }}
                  color="inherit"
                />
              ) : null}
            </InputAdornment>
          }
        />
      );
  }

  return (
    <div className={classnames(classes.container, containerClass)}>
      {label && (
        <label className={classes.label} htmlFor={id}>
          {label}
          {required ? (
            <span
              style={{ color: '#f95f53', fontSize: '16px', lineHeight: '16px' }}
            >
              *
            </span>
          ) : (
            ''
          )}
          {tooltip && (
            <img
              style={{ width: 15, height: 15, marginBottom: -4 }}
              alt="icons"
              src="images/Tooltip.svg"
              title={tooltip}
            />
          )}
        </label>
      )}
      <div>
        {inputEle}
        {helperText && <div className={classes.helperText}>{helperText}</div>}
        {error && <span className={classes.errorText}>{error}</span>}
      </div>
    </div>
  );
};

TextField.defaultProps = {
  isShowing: true,
};

const styles = ({ palette, config }) => {
  return {
    adornmentContainer: {
      margin: 0,
    },
    inputWrapper: {
      '& input': {
        'padding-right': '40px',
        'border-radius': '5px',
        border: `solid 0.8px ${config.colors.almostWhite}`,
      },

      // '& input:focus': {
      //   background: _config.colors.darkBlue,
      // },
    },
    text: {
      '& input': {
        'padding-right': '40px',
        'border-radius': '5px',
        border: `solid 0.8px ${config.colors.almostWhite}`,
        paddingLeft: 35,
        fontSize: 15,
      },
      '& input:focus': {
        background: _config.colors.darkBlue,
      },
    },
    adornmentRoot: {
      height: 'inherit',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
    },
    adornmentRootError: {
      height: 'inherit',
      position: 'absolute',
      top: '50%',
      right: 10,
      color: config.colors.textRed,
      transform: 'translateY(-50%)',
    },
    adornmentRootCheck: {
      height: 'inherit',
      position: 'absolute',
      top: '50%',
      right: 10,
      color: config.colors.cowbellAccent,
      transform: 'translateY(-50%)',
    },
    adornmentRootLoading: {
      position: 'absolute',
      top: '50%',
      right: 10,
      color: config.colors.cowbellAccent,
      transform: 'translateY(-50%)',
    },
    icon: {
      width: '20px',
      height: '30px',
    },
    checkBox: {
      width: '20px',
      height: '36px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
      position: 'relative',
      width: '100%',
    },

    label: {
      paddingBottom: 10,
      fontSize: config.textSizes.normal,
      fontFamily: config.fonts.stackSans,
      lineHeight: '16px',
      marginLeft: '10px',
      color: palette.text.text8,
    },

    input: {
      border: '0.1px solid #f95f53 !important',
    },

    checkInput: {
      // border:'1px solid #6ac52a !important',
    },
    helperText: {
      marginTop: 5,
      fontSize: '0.875rem',
      fontStyle: 'italic',
    },

    loadingIconRoot: {
      width: '20px !important',
      height: '20px !important',
    },

    errorText: {
      display: 'block',
      color: palette.error.main,
      fontWeight: 500,
    },
  };
};

export default withStyles(styles)(TextField);
