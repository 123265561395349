import React from 'react';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Moment from 'moment';
import _ from 'lodash';

// mui
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import CbButton from '../../Buttons/CbButton';
import { TextFieldBase } from '../../inputs/TextFieldBase';

// utils
import { withFormController } from '../../hocs/forms';
import {
  currencyToNumber,
  numberToCurrency,
} from '../../../utils/currency.utils';
import LanguageCurrencyFieldBase from '../../inputs/LanguageCurrencyField';

const TextField = withFormController(TextFieldBase);
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);

const schema = Yup.object().shape({
  limit: Yup.number()
    .label('Limit of Liability')
    .fromCurrency()
    .min(1)
    .typeError('${label} is a required field')
    .required(),
  premium: Yup.number().fromCurrency().label('Premium'),
  fee: Yup.number().fromCurrency().label('Fee'),
  surplusLinesTax: Yup.number().fromCurrency().label('Surplus Lines Tax'),
  coverageStartDate: Yup.string().label('Coverage Start Date').required(),
  coverageEndDate: Yup.string().label('Coverage End Date').required(),
});

const AddAttachmentDetails = ({ data, ...props }) => {
  const classes = useStyles();
  const onSave = _.get(data, 'onSave');
  const attachment = _.get(data, 'attachment', undefined);
  const defaultValues = {
    limit: undefined,
    coverageStartDate: undefined,
    coverageEndDate: undefined,
    attachment,
    premium: undefined,
    fee: undefined,
    surplusLinesTax: undefined,
  };

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { watch } = methods;

  const handleCancel = () => {
    props.close();
  };

  const onSubmit = (formValues) => {
    return onSave(formValues);
  };

  const watchedFields = watch([
    'premium',
    'surplusLinesTax',
    'fee',
    'coverageStartDate',
    'coverageEndDate',
  ]);
  const totalPremium = numberToCurrency(
    currencyToNumber(watchedFields[0]) +
      currencyToNumber(watchedFields[1]) +
      currencyToNumber(watchedFields[2])
  );

  const coverageStartDate = watchedFields[3];
  const coverageEndDate = watchedFields[4];

  const policyTerm =
    coverageEndDate !== undefined
      ? Moment(coverageEndDate).diff(Moment(coverageStartDate), 'days')
      : '0';

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingTop: '5rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LanguageCurrencyField
                name="limit"
                label="Limit of Liability"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <LanguageCurrencyField
                name="attachment"
                disabled
                label="Attachment"
                className={classes.attachment}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="coverageStartDate"
                label="Coverage Start Date"
                type="date"
                required
                InputProps={{
                  inputProps: {
                    min: Moment().format('YYYY-MM-DD'),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <LanguageCurrencyField name="premium" label="Premium" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="coverageEndDate"
                label="Coverage End Date"
                type="date"
                required
                InputProps={{
                  inputProps: {
                    min: Moment(coverageStartDate).format('YYYY-MM-DD'),
                  },
                }}
                disabled={coverageStartDate === undefined}
              />
            </Grid>
            <Grid item container xs={6} spacing={1} direction="row">
              <Grid item xs={6}>
                <LanguageCurrencyField name="fee" label="Fee" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <LanguageCurrencyField
                  name="surplusLinesTax"
                  label="Surplus Lines Tax"
                />
              </Grid>
            </Grid>
            <Grid item xs={6} justifyContent="center">
              <Box display="flex" justifyContent="center">
                <Typography>Policy Term</Typography>
                <Typography color="primary" style={{ marginLeft: '2rem' }}>
                  {policyTerm} Days
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} justifyContent="center">
              <Box display="flex" justifyContent="center">
                <Typography>Total Premium</Typography>
                <Typography color="primary" style={{ marginLeft: '2rem' }}>
                  {totalPremium}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton onClick={handleCancel} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton type="submit" styleName="ctaButton">
            Save
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const useStyles = makeStyles(() => ({
  attachment: {
    '& input': { border: 'none' },
  },
}));

export const AddAttachmentDetailsConfig = {
  AddAttachmentDetails: {
    component: AddAttachmentDetails,
    // all mui related config
    config: {
      fullWidth: true,
      maxWidth: 'md',
      title: 'Add Attachment Details',
    },
  },
};

export default AddAttachmentDetails;
