import React from 'react';
import { Grid } from '@mui/material';
import { withFormController } from '../../../../../components/hocs/forms';
import { InputLabelBase } from '../../../../../components/inputs/InputLabelBase';
import { TextFieldBase } from '../../../../../components/inputs/TextFieldBase';

const TextField = withFormController(TextFieldBase);

const SignatureHolderConfirmation = ({ disableFormFields }) => {
  return (
    <Grid container spacing={4}>
      <Grid item sm={6}>
        <InputLabelBase required>Signature Holder Name:</InputLabelBase>
        <TextField
          required
          placeholder="Please enter signee's full name"
          name="signeeFullName"
          disabled={disableFormFields}
        />
      </Grid>
      <Grid item sm={6}>
        <InputLabelBase required>Signature Holder Job Title:</InputLabelBase>
        <TextField
          required
          name="signeeTitle"
          disabled={disableFormFields}
          placeholder="Please enter signee's title with agency"
        />
      </Grid>
    </Grid>
  );
};

export default SignatureHolderConfirmation;
