import React from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form-4';
import * as Yup from 'yup';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

// components
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/HighlightOff';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import CbButton from '../../Buttons/CbButton';
import { FileUploadDragDrop } from '../../fileUploader/FileUploadDragDrop';
import { MultiSelect } from '../../Selects';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { InputLabelBase } from '../../inputs/InputLabelBase';
import { AddFormType } from './FormTypeCRUD';

// icons
import { EditIcon } from '../../../_assets/svg/Edit.svg';
import { AddIcon } from '../../../_assets/svg/Add.svg';

// hocs, services, utils
import { withUploadFeature } from '../../fileUploader/withUploadFeature';
import { withUploadFeatureForms } from '../../fileUploader/withUploadFeatureForms';
import { withFormController } from '../../hocs/withFormController';
import { delayedEvent, PubSub } from '../../../utils/eventUtils';
import {
  createFormTemplate,
  getProductTypes,
  getFormTypes,
  getSupportedPaperTypes,
  updateFormTemplate,
  uploadDocumentV2,
} from '../../../api/DocumentsService';
import {
  PAPER_MAPPING,
  PRODUCT_MAPPING,
} from '../../../console/support/formsLibrary/formsLibrary.statics';

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  name: Yup.string().required().label('Form Name'),
  formType: Yup.string()
    .required()
    .test(
      'no-fill-in',
      'Please select from list or create a new form-type below',
      (value) => value !== 'Fill-In'
    )
    .label('Form Type'),
  productTypes: Yup.array().of(Yup.string()).min(1).required().label('Product'),
  supportedPaper: Yup.array().of(Yup.string()).min(1).label('Paper').required(),
});

export const FormsLibraryAddEdit = compose(
  withUploadFeatureForms(),
  withUploadFeature({
    screenCenter: false,
    hide: false,
    acceptFileTypes: `image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf`,
  })
)(({ data, iconStyles, closeIconStyles, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id: accountId } = useSelector((state) => state.account);
  const queryClient = useQueryClient();
  const classes = useStyles();
  const editing = !!data;

  const [file, setFile] = React.useState(null);
  const [fileDetails, setFileDetails] = React.useState(null);

  const { handleSubmit, ...methods } = useForm({ validationSchema: schema });
  const {
    register,
    errors,
    setValue,
    setError,
    formState: { dirty, isSubmitting },
  } = methods;
  const values = methods.getValues();
  const showFillIn = React.useMemo(
    () => values.formType === 'Fill-In',
    [values.formType]
  );

  // fetch dependencies on modal load
  const { data: paperTypes } = useQuery(['paperTypes'], () =>
    getSupportedPaperTypes()
      .then((resp) => resp.data.map((paper) => PAPER_MAPPING[paper]))
      .catch(() => {
        enqueueSnackbar(
          'Sorry, there was an error loading paper types, please try again in a few minutes',
          {
            variant: 'error',
          }
        );
      })
  );
  const { data: productTypesData } = useQuery(['productTypes'], () =>
    getProductTypes()
      .then((resp) =>
        resp.data
          .map((product) => PRODUCT_MAPPING[product])
          .filter((product) => product !== undefined)
      )
      .catch(() => {
        enqueueSnackbar(
          'Sorry, there was an error loading product types, please try again in a few minutes',
          {
            variant: 'error',
          }
        );
      })
  );
  const { data: formTypes } = useQuery(['formTypes'], () =>
    getFormTypes()
      .then((resp) =>
        resp.data.content.map((formType) => ({
          label: formType.name,
          value: formType.name,
        }))
      )
      .catch(() => {
        enqueueSnackbar(
          'Sorry, there was an error loading form types, please try again in a few minutes',
          {
            variant: 'error',
          }
        );
      })
  );

  // set modal defaults for editing mode
  React.useEffect(() => {
    if (editing) {
      setFileDetails({ extension: data.docInfo.fileType, fileName: data.name });
      setValue('formType', _.get(data, 'docInfo.formType'));
      setValue('productTypes', _.get(data, 'productTypes'));
      setValue('supportedPaper', _.get(data, 'supportedPaper'));
    }
    // eslint-disable-next-line
  }, [data]);

  // handle file upload details
  React.useEffect(() => {
    const sub = PubSub.subscribe(
      'withUploadFeatureForms:selectedFile',
      (eventData) => {
        if (eventData) {
          setFile(eventData);
          const splitString = eventData.name.split('.');
          const extension = splitString.pop();
          const fileName = splitString.join('.');
          setFileDetails({ extension, fileName });
          setError('');
        } else {
          setError('Unsupported file type.');
        }
      }
    );
    return () => {
      sub.remove();
    };
  }, [data, file, setError]);

  // utility functions
  const handleClearFile = () => {
    setFile(null);
    setFileDetails(null);
  };

  const handleChange = (field, selected) => {
    setError(field, null);
    setValue(
      field,
      selected.map((el) => el.value)
    );
  };

  const handleSelectFormType = (field, selected) => {
    setError('formType', null);
    setValue('formType', selected.value);
  };

  const handleClose = () => {
    props.close();
    setTimeout(() => {
      handleClearFile();
      methods.reset();
    }, 300);
  };

  // encapsulate logic for submit handlers
  const editModeSubmitHandler = async ({
    formType,
    supportedPaper,
    productTypes,
    name,
    description,
  }) => {
    try {
      updateFormTemplate(
        { id: data.id },
        {
          formType,
          supportedPaper,
          productTypes,
          name,
          description,
          docInfo: { ...data.docInfo, formType, docName: name },
        }
      ).then(() =>
        enqueueSnackbar('Form Updated Successfully', { variant: 'success' })
      );
    } catch (error) {
      enqueueSnackbar(
        'Failed to update form details, please try again in a few minutes',
        { variant: 'error' }
      );
    }
    delayedEvent('table-refetch', 500, 'manageFormsLibrary');
  };

  const createModeSubmitHandler = async ({
    formType,
    supportedPaper,
    productTypes,
    name,
    description,
  }) => {
    return new Promise(async () => {
      const bodyFormData = new FormData();
      bodyFormData.append('file', file);

      let documentResp;
      try {
        documentResp = await uploadDocumentV2(
          {
            accountId,
            formType,
            docName: name,
            temp: true,
            agencyId: accountId,
          },
          bodyFormData
        );
      } catch (error) {
        enqueueSnackbar(
          'Form upload failed, please try again in a few minutes',
          { variant: 'error' }
        );
      }
      if (documentResp) {
        try {
          await createFormTemplate(
            {},
            {
              formType,
              supportedPaper,
              productTypes,
              name,
              description,
              docInfo: documentResp.data,
            }
          ).then(() => {
            enqueueSnackbar('Form Uploaded Successfully', {
              variant: 'success',
            });
            delayedEvent('table-refetch', 500, 'manageFormsLibrary');
          });
        } catch (error) {
          enqueueSnackbar(
            'Failed to save form details, please try again in a few minutes',
            { variant: 'error' }
          );
        }
      }
    });
  };

  const onSubmit = (formData) => {
    if (editing) {
      editModeSubmitHandler(formData);
    } else {
      createModeSubmitHandler(formData);
    }

    handleClose();
  };

  const clearFillIn = () => {
    setValue('formType', '');
    queryClient.invalidateQueries(['formTypes']);
  };

  return (
    <Dialog
      {...props}
      onBackdropClick={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Box>
              {editing ? (
                <Box>
                  <EditIcon className={iconStyles.root} />
                  Edit a Form
                </Box>
              ) : (
                <Box>
                  <AddIcon className={iconStyles.root} />
                  Add a New Form
                </Box>
              )}
            </Box>
          </DialogTitle>
          <DialogContent className={classes.bodyPadding}>
            <Box className={classes.headerContainer}>
              <div className={classes.header}>
                {editing ? 'Edit a Form' : 'Add a New Form'}
              </div>
              <p style={{ position: 'relative', bottom: '.8rem' }}>
                {editing
                  ? 'Please review the form below.'
                  : 'Please upload a form below.'}
              </p>
              <FileUploadDragDrop
                editing={editing}
                fileDetails={fileDetails}
                handleClearFile={handleClearFile}
              />
              <p className={classes.supportedFiles}>
                Supported File Types: PDF, DOC, JPEG & PNG
              </p>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box className={classes.inputsLayout}>
                <MultiSelect
                  name="productTypes"
                  label="Product"
                  ref={register('productTypes')}
                  options={productTypesData}
                  onChange={handleChange}
                  values={formatProductTypes(_.get(data, 'productTypes'))}
                  required
                  error={_.get(errors, 'productTypes.message')}
                />
                <MultiSelect
                  name="formType"
                  label="Form Type"
                  ref={register('formType')}
                  onChange={handleSelectFormType}
                  options={
                    formTypes
                      ? [...formTypes, { label: 'Fill-In', value: 'Fill-In' }]
                      : []
                  }
                  values={formatFormType(_.get(data, 'formType'))}
                  isMulti={false}
                  required
                  error={_.get(errors, 'formType.message')}
                />
              </Box>
              <Box className={classes.inputsLayout}>
                <MultiSelect
                  ref={register('supportedPaper')}
                  name="supportedPaper"
                  label="Paper"
                  options={paperTypes}
                  onChange={handleChange}
                  values={formatSupportedPaper(_.get(data, 'supportedPaper'))}
                  required
                  error={_.get(errors, 'supportedPaper.message')}
                />
                <TextField
                  name="name"
                  label="Form Name"
                  defaultValue={_.get(data, 'name')}
                  placeholder="Form Name"
                  required
                />
              </Box>
            </Box>
            {showFillIn ? <AddFormType onSuccess={clearFillIn} /> : null}
            <InputLabelBase indent>
              Add a description for 'Learn More'
            </InputLabelBase>
            <TextField
              name="description"
              defaultValue={_.get(data, 'description')}
              multiline
              minRows={4}
              fullWidth
              style={{ padding: '0 0.5rem' }}
            />
          </DialogContent>
          <DialogActions>
            <CbButton onClick={handleClose} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton
              type="submit"
              styleName="ctaButton"
              loading={isSubmitting}
              disabled={!fileDetails || !dirty}
            >
              {editing ? 'Update' : 'Create'}
            </CbButton>
          </DialogActions>
          <CloseIcon className={closeIconStyles.root} onClick={handleClose} />
        </form>
      </FormContext>
    </Dialog>
  );
});

const formatFormType = (formType) => {
  if (!formType) {
    return { label: 'Select', value: null };
  }
  return {
    label: formType,
    value: formType,
  };
};
const formatSupportedPaper = (supportedPaper) => {
  return supportedPaper
    ? supportedPaper.map((paper) => PAPER_MAPPING[paper])
    : [];
};

const formatProductTypes = (productTypes) => {
  return productTypes
    ? productTypes
        .map((product) => PRODUCT_MAPPING[product])
        .filter((product) => product !== undefined)
    : [];
};

const useStyles = makeStyles(({ config, palette }) => ({
  dialogPaper: {
    overflowY: 'visible',
  },
  headerContainer: {
    textAlign: 'center',
    color: palette.text.secondary,
    fontSize: config.textSizes.normal,
  },
  header: {
    fontSize: config.textSizes.paragon,
  },
  supportedFiles: {
    lineHeight: 1.5,
  },
  inputsLayout: {
    display: 'flex',
    flexDirection: 'column',
    width: '47%',
  },
  bodyPadding: {
    paddingTop: '2rem',
    paddingBottom: '1rem',
    overflow: 'visible',
  },
}));

export const FormsLibraryAddEditConfig = {
  FormsLibraryAddEdit: {
    component: FormsLibraryAddEdit,
    config: {
      fullWidth: true,
      override: true,
    },
  },
};
