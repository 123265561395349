import React from 'react';
import { Box, Button } from '@mui/material';

import Numeral from 'numeral';
import { makeStyles } from '@mui/styles';
import CbButton from '../../Buttons/CbButton';

export const DefaultFilePreview = ({
  disabled,
  isRepickDisabled = false,
  rePickFilesFromOS,
  files,
  onRemoveFile,
}) => {
  const { filename, filesize, ...classes } = useStyles();

  if (!(files && files.length > 0)) {
    return null;
  }

  return (
    <>
      {files.map((file, idx) => (
        <Box key={idx} classes={classes} pl={1} m={1}>
          <span className={filename} title={file.name}>
            {file.name}
          </span>
          <span className={filesize}>{Numeral(file.size).format('0.0 b')}</span>
          <Button
            color="primary"
            disabled={disabled}
            size="small"
            variant="text"
            onClick={() => onRemoveFile(file.name)}
          >
            Remove
          </Button>
        </Box>
      ))}
      <Box mt={2} mb={2}>
        <CbButton
          styleName="ctaButton"
          size="small"
          onClick={rePickFilesFromOS}
          disabled={isRepickDisabled}
        >
          Repick files
        </CbButton>
      </Box>
    </>
  );
};

const useStyles = makeStyles(({ palette, shape }) => ({
  root: {
    width: '100%',
    display: 'flex',
    padding: '5px 10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${palette.divider}`,
    borderRadius: shape.borderRadius,
    color: palette.primary.main,
  },

  filename: {
    display: 'block',
    flexBasis: '50%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  filesize: {
    width: '20%',
    textAlign: 'right',
  },
}));
