import React from 'react';
import { Box, styled } from '@mui/material';
import CheckBoxLabel from '../../CheckBoxLabel';
import CheckBoxBase from '../../../../../components/inputs/Checkbox';
import { attestationQuoteCheckBoxLabel } from '../../constants/constants';
import { useCowbellTranslations } from '../../../../../i18n/translations';

export const AttestCheckBox = ({ name, handleCheckboxChange, ...props }) => {
  const { t, translationKeys } = useCowbellTranslations();

  return (
    <Box paddingTop="1rem">
      <CheckBoxBase
        name={name}
        label={
          <Label>
            <CheckBoxLabel
              label={attestationQuoteCheckBoxLabel(
                t(translationKeys.checking)?.toLowerCase()
              )}
              tooltip={false}
            />
          </Label>
        }
        onChange={handleCheckboxChange}
        {...props}
      />
    </Box>
  );
};

export const AttestStepSecondaryText = ({ disabled }) => {
  return (
    <Box
      paddingLeft="2rem"
      style={disabled ? { color: 'rgba(255, 255, 255, 0.5)' } : {}}
    >
      <Box fontSize="1.2rem">
        <div>
          - Contains no misrepresentations and/or misstatements of fact;
        </div>
        <div>
          - Includes the insured's actual true and correct signature; and
        </div>
        <div>
          - Makes no changes to the the application used to obtain the
          Quote/Conditional Binder.
        </div>
      </Box>
      <h3>
        I understand that Cowbell may audit or otherwise examine the files
        and/or other information I provide in connection with the application
        used to obtain the Quote/Conditional Binder and I agree and consent to
        promptly provide Cowbell with any additional information it may request
        from me in connection with any such audit or examination it may conduct.
      </h3>
      <h3>
        I also understand that Cowbell may change coverage offered in the Quote
        or Insurance Policy if the document uploaded had additional information
        or changes information submitted in the application, between the time of
        the Quote and binding of the insurance policy.
      </h3>
    </Box>
  );
};

const Label = styled('label')({
  display: 'block',
  fontSize: '1.17em',
  marginBlockStart: '1em',
  marginBlockEnd: '1em',
  fontWeight: 'bold',
  pointerEvents: 'none',
});
