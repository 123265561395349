import React from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles } from '@mui/styles';
import { SearchField } from '../../../../inputs/SearchField';
import { useQueryParams } from '../../../../providers/QueryParamsProvider';

type Props = Omit<React.ComponentProps<typeof SearchField>, 'onChange'>;

export const TableSearch = (props: Props) => {
  const { pathname } = useLocation();
  const { query, replace } = useQueryParams();
  const classes = useStyles();

  const handleChange = (value: string) => {
    const { search, pageRequested, searchAfter, page, ...rest } = query;
    if (value) {
      replace(pathname, { query: { ...rest, search: value } });
    } else {
      replace(pathname, { query: { ...rest } });
    }
  };

  return (
    <SearchField
      defaultValue={_.get(query, 'search')}
      onChange={handleChange}
      placeholder="Search"
      delay={500}
      {...props}
      classes={classes}
    />
  );
};

const useStyles = makeStyles(({ config, palette }) => ({
  root: {
    backgroundColor: palette.background.default,
    border: `1px solid ${palette.notification.containerBorder}`,
    marginRight: '1rem',
  },
  focused: {
    border: `1px solid ${config.colors.darkBlue}`,
  },
}));
