import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFilterState } from './useFilterState';
import TableFilterSelect from '../TableFilterSelect';
import { useQueryParams } from '../../../providers/QueryParamsProvider';

interface TableFilterSelectProps {
  tableId: string;
}

export const TableFilterSelectV2: React.FC<TableFilterSelectProps> = ({
  tableId,
}) => {
  const { query, replace } = useQueryParams();
  const history = useHistory();
  const location = useLocation();
  const filterProps = useFilterState(tableId);

  return (
    <TableFilterSelect
      filterProps={filterProps}
      routeProps={{ location, history, q: query, replace }}
    />
  );
};
