import { Box, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import type { WithChildrenProps } from '../../../../types/components/common';
import { AgencyDashboardListingTab } from '../agency-dashboard.statics';

interface AgencyDashboardNullResultFallbackProps extends WithChildrenProps {
  entityType?: string;
  containerProps?: any;
}

export const AgencyDashboardNullResultFallback = ({
  entityType,
  containerProps = {},
  children,
}: AgencyDashboardNullResultFallbackProps) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...containerProps}
    >
      <Typography variant="h6" style={{ textAlign: 'center' }}>
        We're sorry, we couldn't find any {entityType ?? 'results'}
        <br />
        matching these search criteria{' '}
        <SearchIcon
          style={{ fontSize: '2rem', position: 'relative', top: 4 }}
        />
      </Typography>
      <>{children}</>
    </Box>
  );
};
