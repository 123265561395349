import React from 'react';
import { useForm } from 'react-hook-form-4';

// mui
import { DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import CbButton from '../../../../Buttons/CbButton';
import { toUniversalUtcDate } from '../../../../../utils/date.utils';

export const ExtendPaymentConfirmation = ({
  close,
  payload,
  submission,
  data,
}) => {
  const classes = useStyles();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = () => {
    submission(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent className={classes.wrapper}>
        <Typography color="primary" variant="body1" align="center">
          Are you sure you want to extend the payment due date for
          <br />
          {data.companyName}, Policy Number{data.policyNumber},<br />
          from {toUniversalUtcDate(payload.defaultDate)} to{' '}
          {toUniversalUtcDate(payload.newDueDate)}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Grid item md={6}>
            <Typography color="primary" variant="body1">
              Step 2 of 2: Confirm
            </Typography>
          </Grid>
          <Grid item md={6} align="right">
            <CbButton
              buttonText="Cancel"
              styleName="cancel"
              onClick={() => close()}
            />
            <CbButton
              buttonText="Confirm"
              styleName="ctaButton"
              type="submit"
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '2rem 5rem !important',
  },
}));
