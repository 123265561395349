import React from 'react';

const SVG = ({
  id = 'MSWordIcon',
  style = {},
  fill = '#fff',
  className = '',
  viewBox = '0 0 11.425 12',
  title = 'MSWordIcon',
}) => (
  <svg
    fill={fill}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .cls-1{fill:#fff}.cls-2{fill:#848280}
          `}
      </style>
    </defs>
    <title>{title}</title>
    <path
      id="Path_5354"
      d="M231.56 739.128h-5.737a.285.285 0 0 0-.284.286v11.136a.286.286 0 0 0 .286.286h8.281a.286.286 0 0 0 .286-.286v-8.591a.284.284 0 0 0-.084-.2l-2.545-2.545a.283.283 0 0 0-.203-.086z"
      className="cls-1"
      transform="translate(-223.112 -738.982)"
    />
    <path
      id="Path_5355"
      d="M234.307 741.55l-2.546-2.55a.435.435 0 0 0-.3-.126h-5.737a.433.433 0 0 0-.43.432v11.136a.432.432 0 0 0 .432.432H234a.432.432 0 0 0 .432-.432v-8.591a.434.434 0 0 0-.125-.301zm-2.729-2.315l2.5 2.5h-2.359a.14.14 0 0 1-.139-.139zm2.422 11.35h-8.281a.14.14 0 0 1-.139-.139V739.31a.139.139 0 0 1 .138-.139h5.566v2.423a.433.433 0 0 0 .432.432h2.423v8.42a.139.139 0 0 1-.139.139z"
      className="cls-2"
      transform="translate(-223.008 -738.878)"
    />
    <path
      id="Path_5331"
      d="M235.575 750.276h-4.426v-.286h4.426a.143.143 0 0 1 .143.143.143.143 0 0 1-.143.143zm0-.856h-4.426v-.286h4.426a.143.143 0 0 1 .143.143.143.143 0 0 1-.143.143zm0-.856h-4.426v-.286h4.426a.143.143 0 0 1 .143.143.143.143 0 0 1-.143.142zm0-.856h-4.426v-.286h4.426a.143.143 0 0 1 .143.143.143.143 0 0 1-.143.143zm0 3.427h-4.426v-.286h4.426a.143.143 0 0 1 .143.143.143.143 0 0 1-.143.142z"
      className="cls-2"
      transform="translate(-225.438 -742.42)"
    />
    <g id="Group_8511" transform="translate(0 4.001)">
      <path
        id="Path_5332"
        fill="#004c9c"
        d="M221.964 751.424h4.566a.571.571 0 0 0 .571-.571v-4.566a.571.571 0 0 0-.569-.573h-4.566a.571.571 0 0 0-.574.569v4.568a.571.571 0 0 0 .569.573z"
        transform="translate(-221.393 -745.713)"
      />
      <path
        id="Path_5333"
        d="M223.945 750.529c.009.074.016.139.019.195h.011c0-.053.013-.116.025-.191s.025-.138.036-.188l.5-2.192h.653l.522 2.16a3.024 3.024 0 0 1 .065.41h.009a3.2 3.2 0 0 1 .054-.395l.417-2.175h.594l-.731 3.141h-.691l-.5-2.081c-.015-.06-.031-.138-.049-.234s-.03-.166-.034-.21h-.009c-.006.051-.017.127-.034.228s-.03.175-.04.224l-.468 2.075h-.7l-.74-3.142h.6l.455 2.2c.016.04.026.1.036.175z"
        className="cls-1"
        transform="translate(-222 -746.724)"
      />
    </g>
  </svg>
);

export default SVG;
