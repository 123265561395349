import React from 'react';
import { useFormContext } from 'react-hook-form-4';
import { SimpleSelect } from '../../../../inputs/selects/SimpleSelect';
import { useRHFError } from '../../../../hooks/index';

const HoursInput = ({ name = 'hrs', hourInput = [], ...props }) => {
  const { setValue, errors, clearError } = useFormContext();
  const error = useRHFError(name, errors);

  React.useEffect(() => {
    setValue(name, props.defaultValue, { shouldDirty: false });
    // eslint-disable-next-line
  }, []);

  const options = React.useMemo(
    () =>
      hourInput.map((hr) => ({
        label: `${hr} hour`,
        value: hr,
      })),
    [hourInput]
  );

  const handleSelect = React.useCallback((event) => {
    setValue(name, event.target.value, { shouldDirty: true });
    clearError(name);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SimpleSelect
        label="Coverage Hours"
        options={options}
        onChange={handleSelect}
        name={name}
        required
        displayEmpty
        renderValue={renderValue}
        error={error}
        {...props}
      />
    </>
  );
};

function renderValue(value) {
  return value || 'Please Choose Coverage Hours...';
}

export default HoursInput;
