import React from 'react';
import type { MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Box, Collapse, Menu } from '@mui/material';

import { useMenuState } from '../../../../../components/tables/cell-components/menu/Menu';
import { Show } from '../../../../../components/Show';
import {
  BOUND_STATUS,
  READY_STATUS,
  SIGNED_STATUS,
} from '../../../../_statics/quote.statics';
import { _isOneOf } from '../../../../../utils/data.utils';
import { ProductTypes } from '../../../../../types';
import type { Quote } from '../../../../../types/quotes/quote.entity';
import { AnchorButton } from '../AgencyDashSecondaryActionsMenu';
import { QuoteAgencyStatus } from '../../../../../types/common/misc';
import { usePrimeXAgencyQuoteMoreOptionsHandlers } from '../../../../agencies/inbox/table/PrimeXAgencyMoreOptions';
import { AgencyDashboardMenuItem as MenuItem } from '../../menu-options/AgencyDashboardMenuOptions.components';
import { useAllow } from '../../../../../components/hooks/useAllow';

interface AgencyQuotesMenuOptions {
  quote: Quote;
}

export const AgencyQuotesPrimeXMenuOptions = ({
  quote,
}: AgencyQuotesMenuOptions) => {
  const [isDocumentsExpanded, setIsDocumentsExpanded] = React.useState(true);

  const menuState = useMenuState();
  const menuOptionHandlers = usePrimeXAgencyQuoteMoreOptionsHandlers(
    quote as any
  );
  const menuOptionMetadata = useAgencyQuotePrimeXMenuOptions(quote);

  const handleClose: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    menuState.handleClose();
  };

  const handleDocumentsClick: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    setIsDocumentsExpanded((value) => !value);
  };

  return (
    <>
      <AnchorButton onClick={menuState.handleOpen} />
      <Menu
        id="more-options-menu"
        anchorEl={menuState.anchorEl}
        keepMounted
        open={menuState.open}
        onClose={handleClose}
      >
        <Show when={menuOptionMetadata.documents.show}>
          <MenuItem onClick={handleDocumentsClick}>
            {menuOptionMetadata.documents.name}
            <Box ml={0.5}>
              {isDocumentsExpanded ? (
                <FontAwesomeIcon icon={light('chevron-up')} />
              ) : (
                <FontAwesomeIcon icon={light('chevron-down')} />
              )}
            </Box>
          </MenuItem>
        </Show>
        <Collapse
          in={isDocumentsExpanded}
          timeout="auto"
          unmountOnExit
          style={{ paddingLeft: '1rem' }}
        >
          <Show when={menuOptionMetadata.downloadProposal.show}>
            <MenuItem onClick={menuOptionHandlers.downloadProposal}>
              {menuOptionMetadata.downloadProposal.name}
            </MenuItem>
          </Show>
          <Show when={menuOptionMetadata.downloadApplication.show}>
            <MenuItem onClick={menuOptionHandlers.downloadApplication}>
              {menuOptionMetadata.downloadApplication.name}
            </MenuItem>
          </Show>
        </Collapse>
        <Show when={menuOptionMetadata.editClientDetails.show}>
          <MenuItem onClick={menuOptionHandlers.editAccount}>
            {menuOptionMetadata.editClientDetails.name}
          </MenuItem>
        </Show>
        <Show when={menuOptionMetadata.bindQuote.show}>
          <MenuItem onClick={menuOptionHandlers.bindQuote}>
            {menuOptionMetadata.bindQuote.name}
          </MenuItem>
        </Show>
        <Show when={menuOptionMetadata.submitForUwReview.show}>
          <MenuItem onClick={menuOptionHandlers.handleSubmitForUWReview}>
            {menuOptionMetadata.submitForUwReview.name}
          </MenuItem>
        </Show>
      </Menu>
    </>
  );
};

const useAgencyQuotePrimeXMenuOptions = (quote: Quote) => {
  const canDownloadProposal = Boolean(
    quote.agencyStatus === READY_STATUS && quote.isProposalAvailable
  );

  const canDownloadApplication = Boolean(
    quote.agencyStatus === READY_STATUS && quote.isApplicationAvailable
  );

  const canEditClientDetails = useAllow(['accounts:manage']);

  const canBindQuote =
    quote.agencyStatus === QuoteAgencyStatus.READY && !quote.isEndorsement;

  const canSubmitForUwReview =
    quote.agencyStatus === QuoteAgencyStatus.READY &&
    quote.product === ProductTypes.prime_one;

  return {
    documents: {
      name: 'Documents',
      show: canDownloadProposal || canDownloadApplication,
    },
    downloadProposal: { name: 'Download Proposal', show: canDownloadProposal },
    downloadApplication: {
      name: 'Download Application',
      show: canDownloadApplication,
    },
    editClientDetails: {
      name: 'Edit Client Details',
      show: canEditClientDetails,
    },
    bindQuote: {
      name: 'Bind Quote',
      show: canBindQuote,
    },
    submitForUwReview: {
      name: 'Submit for Underwriter Review',
      show: canSubmitForUwReview,
    },
  };
};
