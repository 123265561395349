import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { searchAccountsV2, searchQuotesV2 } from '../../../../api';
import { searchPoliciesV2 } from '../../../../policies/PolicyService';
import { selectGlobalTeam } from '../../../../reducers/team.reducer';
import {
  ACTIVE_STATUS,
  BOUND_STATUS,
  ISSUED_STATUS,
} from '../../../_statics/policy.statics';
import {
  DRAFT_STATUS,
  INDICATION_STATUS,
  IN_REVIEW_STATUS,
  READY_STATUS,
  REFERRED_STATUS,
  REQUESTED_STATUS,
} from '../../../_statics/quote.statics';

import {
  AgencyDashboardQueryKeys,
  bindersBaseFilterConfig,
  policiesBaseFilterConfig,
  quotesBaseFilterConfig,
} from '../agency-dashboard.statics';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';

export const defaultShowQuoteStatuses = [
  READY_STATUS,
  REFERRED_STATUS,
  REQUESTED_STATUS,
  IN_REVIEW_STATUS,
  INDICATION_STATUS,
];

export const defaultShowBinderStatuses = [BOUND_STATUS];

export const defaultShowPolicyStatuses = [
  'IN_FORCE',
  ISSUED_STATUS,
  DRAFT_STATUS,
  ACTIVE_STATUS,
];

export const useAgencyDashboardListingData = () => {
  const team = useSelector(selectGlobalTeam);
  const teamIds = team.id === 'all' ? undefined : [team.id];
  const { queryState, searchTerm } = useAgencyDashboardState();

  const quotesBaseFilters = searchTerm
    ? quotesBaseFilterConfig.f
    : { agencyStatus: { in: defaultShowQuoteStatuses } };

  const bindersBaseFilters = searchTerm
    ? bindersBaseFilterConfig.f
    : { status: { in: defaultShowBinderStatuses } };

  const policiesBaseFilters = searchTerm
    ? policiesBaseFilterConfig.f
    : { status: { in: defaultShowPolicyStatuses } };

  const quotesCache = useQuery({
    queryKey: [AgencyDashboardQueryKeys.QUOTES, queryState.QUOTES_QUERY, team],
    queryFn: () =>
      searchQuotesV2({
        params: {
          ...queryState.QUOTES_QUERY,
          f: { ...quotesBaseFilters, ...queryState.QUOTES_QUERY.f },
          teamIds,
        },
      }),
    select: (resp) => ({
      rows: resp.data.content,
      totalPages: resp.data.totalPages,
    }),
  });

  const bindersCache = useQuery({
    queryKey: [
      AgencyDashboardQueryKeys.BINDERS,
      queryState.BINDERS_QUERY,
      team,
    ],
    queryFn: () =>
      searchPoliciesV2({
        params: {
          ...queryState.BINDERS_QUERY,
          f: { ...bindersBaseFilters, ...queryState.BINDERS_QUERY.f },
          teamIds,
        },
      }),
    select: (resp) => ({
      rows: resp.data.content,
      totalPages: resp.data.totalPages,
    }),
  });

  const policiesCache = useQuery({
    queryKey: [
      AgencyDashboardQueryKeys.POLICIES,
      queryState.POLICIES_QUERY,
      team,
    ],
    queryFn: () =>
      searchPoliciesV2({
        params: {
          ...queryState.POLICIES_QUERY,
          f: { ...policiesBaseFilters, ...queryState.POLICIES_QUERY.f },
          teamIds,
        },
      }),
    select: (resp) => ({
      rows: resp.data.content,
      totalPages: resp.data.totalPages,
    }),
  });

  const accountsCache = useQuery({
    queryKey: [
      AgencyDashboardQueryKeys.ACCOUNTS,
      queryState.ACCOUNTS_QUERY,
      team,
    ],
    queryFn: () =>
      searchAccountsV2({ params: { ...queryState.ACCOUNTS_QUERY, teamIds } }),
    select: (resp) => ({
      rows: resp.data.content,
      totalPages: resp.data.totalPages,
    }),
  });

  return { quotesCache, bindersCache, policiesCache, accountsCache };
};
