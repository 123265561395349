import _ from 'lodash';
import { Grid, DialogContent, Typography, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import TextFieldBase from '../../../inputs/text-fields/base/TextFieldBase';
import { PasswordField as PasswordFieldBase } from '../../../inputs/text-fields/passwords/PasswordField';

import { withFormController } from '../../../hocs/forms';
import useUploadZone from '../../../fileUploader/v2/hooks/useUploadzone';
import {
  ActionType,
  handleAction,
  transformPayloadForConnector,
} from './helpers';
import { PubSub } from '../../../../utils/eventUtils';
import { manageAPIError } from '../../../../utils';
import { FormUpload } from './elements/FormUpload';
import CbButton from '../../../Buttons/CbButton';
import { InputLabelBase } from '../../../inputs/InputLabelBase';

const PasswordField = withFormController(PasswordFieldBase);
const TextField = withFormController(TextFieldBase);

const MAX_GRID_COLUMNS = 12;
const COMMON_FIELD_TYPES = ['text', 'number', 'date', 'email'];

const Column = ({
  schema,
  column,
  uploadZoneProps,
  formProps,
  elementContainerProps,
}) => {
  const { files, handleUploads, ...uploadZoneParams } = uploadZoneProps;

  return schema.elements
    .filter((element) => element.column === column)
    .map((element) => {
      return (
        <Grid key={element.name} item xs={12} {...elementContainerProps}>
          {element.type === 'upload' && (
            <FormUpload
              uploadZoneParams={uploadZoneParams}
              files={files}
              element={element}
              formProps={formProps}
            />
          )}

          {element.type === 'password' && (
            <>
              <PasswordInputLabel required={element.required}>
                {element.label}
              </PasswordInputLabel>
              <PasswordField
                name={element.name}
                required={element.required}
                inputLabelProps={{ indent: false }}
              />
            </>
          )}

          {COMMON_FIELD_TYPES.includes(element.type) && (
            <TextField
              type={element.type}
              label={element.label}
              name={element.name}
              required={element.required}
              placeholder=""
            />
          )}

          {element.type === 'downloadButton' && (
            <CbButton
              onClick={() => window.open(element.url, '_blank')}
              {...element.elementProps}
            >
              {element.description}
            </CbButton>
          )}

          {element.type === 'description' && (
            <Content {...element.elementProps}>{element.description}</Content>
          )}
        </Grid>
      );
    });
};

const GridColumns = ({ schema, uploadZoneProps, formProps }) => {
  const {
    columns: numberOfColumns,
    columnProps,
    elementContainerProps,
  } = schema.layout;
  const defaultGridItemSize = MAX_GRID_COLUMNS / numberOfColumns;

  const columnGrids = [];
  for (let count = 0; count < numberOfColumns; count += 1) {
    columnGrids.push(
      <Grid item key={count} md={defaultGridItemSize} {...columnProps[count]}>
        <Grid container>
          <Column
            schema={schema}
            column={count}
            uploadZoneProps={uploadZoneProps}
            formProps={formProps}
            elementContainerProps={elementContainerProps[count]}
          />
        </Grid>
      </Grid>
    );
  }
  return columnGrids;
};

export const Connector = ({
  schema,
  stepperProps,
  handleClose,
  cbid,
  connectorType,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const uploadZoneProps = useUploadZone({ maxFiles: 1 });

  const {
    handleSubmit,
    formState: { errors },
    ...formMethods
  } = useFormContext({
    // resolver: yupResolver(schema),
  });

  if (_.isEmpty(schema)) {
    return null;
  }

  const onSubmit = (formValues) => {
    const { action } = schema;

    if (action.type === ActionType.GO_FORWARD) {
      stepperProps.goForward();
    }

    if (action.type === ActionType.CLOSE_MODAL) {
      handleClose();
    }

    if (action.type === ActionType.API_ENDPOINT) {
      const rawPayload = {
        cbid,
        formValues,
        files: uploadZoneProps.files,
      };

      const payload = transformPayloadForConnector(connectorType, rawPayload);

      return handleAction(action, payload)
        .then(() => {
          PubSub.publish('connector-page-refetch');
          stepperProps.goForward();
        })
        .catch((error) => {
          enqueueSnackbar(
            manageAPIError(error, 'Failed to add the connector'),
            {
              variant: 'error',
            }
          );
        });
    }
  };

  const { containerProps } = schema.layout;
  return (
    <DialogContent>
      <form id="connector" onSubmit={handleSubmit(onSubmit)}>
        <Grid container {...containerProps}>
          <GridColumns
            schema={schema}
            uploadZoneProps={uploadZoneProps}
            formProps={{ ...formMethods, errors }}
            errors={errors}
          />
        </Grid>
      </form>
    </DialogContent>
  );
};

const Content = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const PasswordInputLabel = styled(InputLabelBase)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  paddingTop: 0,
}));
