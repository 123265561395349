import React from 'react';
import fp from 'lodash/fp';

import { Divider } from '@mui/material';
// components
import { withShowable } from '../../lib/withShowable';
import { GroupedCoverages } from './GroupedCoverages';
import CoverageGroups from './statics/groups.statics.json';

const MuiDivider = withShowable(Divider);

export const Coverages = ({ coverages, labels, diffs }) => {
  const grouped = React.useMemo(() => {
    return fp.flow(
      fp.filter((coverage) => coverage),
      fp.sortBy('order'),
      fp.groupBy('groupId')
    )(coverages);
  }, [coverages]);

  return (
    <>
      {Object.keys(grouped).map((groupId) => {
        return (
          <>
            <GroupedCoverages
              key={groupId}
              id={groupId}
              groupedCoverages={grouped}
              group={CoverageGroups[groupId]}
              labels={labels}
              diffs={diffs}
            />
            <MuiDivider
              show={Object.keys(grouped).length - 1 !== parseInt(groupId)}
            />
          </>
        );
      })}
    </>
  );
};

export default Coverages;
