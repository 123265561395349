import { PubSub } from '../../../../utils/eventUtils';

/**
 * Example of use
 *
 * Modal.deferShow('TestModal', {
 *   data: { custom: 'data' },
 * });
 *
 */
export const Modal = {
  /**
   * @name show
   * @description immediately show a modal
   * @param key: name of the modal
   * @param config: static configuration of the modal
   */
  show(nextModal, modalMeta = {}) {
    const {
      key,
      component,
      config: defaultConfig = {},
    } = deriveModalConfig(nextModal);

    const { config: inlineConfig = {}, ...metaRest } = modalMeta;

    PubSub.publish('modal:toggle', {
      key,
      open: true,
      config: { ...defaultConfig, ...inlineConfig },
      ...metaRest,
      component,
    });
  },

  /**
   * @name show
   * @description hide the modal
   * @param key: name of the modal
   */
  hide(key) {
    PubSub.publish('modal:toggle', {
      key,
      open: false,
    });
  },

  /**
   * @name show
   * @description defer the showing of a modal until later, e.g. onClick handler is invoked
   * @param key: name of the modal
   * @param config: static configuration of the modal
   */
  deferShow(key, config) {
    return () => {
      this.show(key, config);
    };
  },

  /**
   * @name show
   * @description defer the hiding of the modal until later
   * @param key: name of the modal
   */
  deferHide(key) {
    return () => {
      this.hide(key);
    };
  },
};

const deriveModalConfig = (nextModal) => {
  if (typeof nextModal === 'string') {
    return { key: nextModal };
  }
  if (typeof nextModal === 'object') {
    const [nextModalConfig] = Object.keys(nextModal).map(
      (key) => nextModal[key]
    );
    return nextModalConfig;
  }
};
