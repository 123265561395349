import React from 'react';
import qs from 'qs';
import _ from 'lodash';

import { Box } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { flattenQuery } from '../../modals/filters/filter.helpers';
import { parseFilterModalQuery } from '../../../utils/query.utils';

export const TableFilterChips = ({ filterProps, routeProps }) => {
  const { chips = [] } = filterProps;
  const { q } = routeProps;

  // parse query from url and remove id for deep-equality check against chip-filters
  let parsedQuery = [];
  if (q.f) {
    parsedQuery = parseFilterModalQuery(q).map(({ id, ...f }) => f);
  }

  // check to make sure table chips utilize the valid naming convention
  const validChips = chips.filter((chip) => {
    const split = chip.filter.split('&');
    return split.every((filter) => filter.startsWith('f'));
  });

  // overwrite chips' url-encoded query-string with parsed query object
  const parsedChips = validChips.map((chip) => ({
    ...chip,
    filter: parseFilterModalQuery(qs.parse(chip.filter)),
  }));

  return (
    <>
      {parsedChips.map((chip, idx) => (
        <FilterChip
          key={`tableChip-${idx}`}
          chip={chip}
          query={parsedQuery}
          {...routeProps}
        />
      ))}
    </>
  );
};

const FilterChip = ({ chip, query, q, ...routeProps }) => {
  const { location } = routeProps;
  const classes = useChipStyles();

  // delete filter ids for deep-equality check against query
  const deepEqualChip = {
    ...chip,
    filter: chip.filter.map(({ id, ...f }) => f),
  };

  // derive initial selected state by comparing chip-filters to the current url
  const selected = deepEqualChip.filter.every((f) => {
    return !_.isEmpty(query.filter((el) => _.isMatch(el, f)));
  });

  // toggle filter and update url onClick
  const handleSelect = () => {
    const { f, fn, page, searchAfter, pageRequested, ...rest } = q;
    let nextQ = [...query];
    if (selected) {
      deepEqualChip.filter.forEach((filterChip) => {
        nextQ = nextQ.filter((el) => !_.isMatch(el, filterChip));
      });
    } else {
      nextQ = [...query, ...deepEqualChip.filter];
    }
    const filterParams = flattenQuery(nextQ);
    routeProps.replace(location.pathname, {
      query: { ...rest, ...filterParams },
    });
  };

  return (
    <Box
      className={`${classes.chip} ${selected ? classes.chipSelected : ''}`}
      onClick={handleSelect}
      data-qa={`TABLE_FILTER_CHIP:${chip.label}`}
    >
      {chip.label}
    </Box>
  );
};

const useChipStyles = makeStyles(({ config, palette }) => ({
  chip: {
    padding: '0.4rem 0.8rem',
    margin: '0.3rem 1.2rem 0.3rem 0',
    cursor: 'pointer',
    borderRadius: '1.5rem',
    border: `1px solid ${config.colors.cowbellBlue}`,
    color: palette.text.secondary,
  },
  chipSelected: {
    backgroundColor: config.colors.cowbellBlue,
    color: 'white',
  },
}));
