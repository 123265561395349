import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormContext, useForm } from 'react-hook-form-4';
import _ from 'lodash';

// components
import { AgencyTeamsLookupField } from './AgencyTeamsAutoComplete';

// utils
import { PubSub } from '../../utils/eventUtils';
import { selectGlobalTeam } from '../../reducers/team.reducer';
import { accountCreationTeams } from '../../reducers/UiSettingsReducers';

// apis
import { getMyTeams } from '../../teams/TeamsService';

export const AgencyTeamsAccountCreationHeader = ({ data }) => {
  const reduxTeams = useSelector(selectGlobalTeam);
  const defaultTeamName = _.get(reduxTeams, 'name', '');

  const { ...methods } = useForm({
    defaultValues: {
      teams: defaultTeamName === 'All' ? '' : defaultTeamName,
    },
  });

  const [teamInputValue, setTeamInputValue] = React.useState('');
  const [teamOptions, setTeamOptions] = React.useState([]);
  const [defTeam, setDefTeam] = React.useState('');

  const { setValue, getValues, watch, register } = methods;
  const values = getValues();
  const dispatch = useDispatch();

  // when initial load
  React.useEffect(() => {
    register({ name: 'teams' }, { required: true });
    watch(['teams']);
  }, [register, watch]);

  React.useEffect(() => {
    // eslint-disable-next-line no-shadow
    getMyTeams().then(({ data }) => {
      setTeamOptions(data.content);
      // eslint-disable-next-line array-callback-return
      return data.content.map((team) => {
        if (team.isDefault) {
          setDefTeam(team);
        }
      });
    });
  }, []);

  React.useEffect(() => {
    let defaultTeam = {};

    if (data.teams) {
      defaultTeam =
        data.teams.id === 'all'
          ? { label: defTeam.name, value: defTeam.id }
          : { label: data.teams.name, value: data.teams.id };
    } else {
      defaultTeam =
        reduxTeams.id === 'all'
          ? { label: defTeam.name, value: defTeam.id }
          : { label: reduxTeams.name, value: reduxTeams.id };
    }

    dispatch(
      accountCreationTeams({
        name: defaultTeam.label,
        id: defaultTeam.value,
      })
    );
    // eslint-disable-next-line
  }, [defTeam]);

  // reset-values
  React.useEffect(() => {
    const sub = PubSub.subscribe('reset-teams', () => {
      setValue('teams', '');
      setTeamInputValue('');
    });
    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (name) => {
    return (newInputValue) => {
      if (name === 'teams') {
        return setTeamInputValue(newInputValue);
      }
    };
  };

  const handleTeams = useCallback(
    (selectedOption) => {
      setValue('teams', selectedOption);

      dispatch(
        accountCreationTeams({
          name: selectedOption.label,
          id: selectedOption.value,
        })
      );
    },
    // eslint-disable-next-line
    []
  );

  return (
    <FormContext {...methods}>
      <form>
        <AgencyTeamsLookupField
          name="teams"
          required
          error={!values.teams && _.get(methods.errors, 'teams.message')}
          placeholder="select team"
          value={values.teams}
          onInputChange={handleInputChange('teams')}
          inputValue={teamInputValue}
          onChange={handleTeams}
          defaultOptions={teamOptions}
        />
      </form>
    </FormContext>
  );
};

export default AgencyTeamsAccountCreationHeader;
