import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PoliciesIcon from '../../../_assets/svg/Policies.svg';
import ManageIcon from '../../../_assets/svg/Manage.svg';
import QuotesIcon from '../../../_assets/svg/Quotes.svg';
import InboxIcon from '../../../_assets/svg/Inbox.svg';
import AccountsIcon from '../../../_assets/svg/Accounts.svg';
import AgentsIcon from '../../../_assets/svg/Agents.svg';
import GoogleWorkspace from '../../../_assets/svg/GoogleWorkspace.svg';
import GSC from '../../../_assets/svg/connectors/GSC.svg';
import Cloudflare from '../../../_assets/svg/connectors/Cloudflare.svg';
import Sophos from '../../../_assets/svg/connectors/Sophos.svg';
import SpotlightIcon from '../../../_assets/svg/SpotlightIcon.svg';
import SpotlightNotification from '../../../components/spotlight/SpotlightNotification';
import { ThemedSpotlightIcon } from '../../../components/spotlight/SpotlightBanner';

const icon = {
  margin: '1.667rem 1.25rem',
  '&:hover': {
    cursor: 'initial',
    color: 'inherit',
  },
};

const image = {
  margin: '1.667rem 1.25rem',
  '&:hover': {
    cursor: 'initial',
    color: 'inherit',
  },
  width: '2.25rem',
};

const notyIcon = {
  width: '15px',
  // margin: '5px',
};

const userIcon = {
  width: '15px',
  // margin: '4.5px',
  fill: '#ecfb00',
};

const addAccountIcon = {
  margin: '1.667rem 1.25rem',
  width: 46,
  height: 46,
  '&:hover': {
    cursor: 'initial',
    color: 'inherit',
  },
};

export const iconMappings = {
  Add: {
    iconImport: <QueueOutlinedIcon fontSize="large" style={icon} />,
  },
  Refresh: {
    iconImport: <RefreshOutlinedIcon fontSize="large" style={icon} />,
  },
  Delete: {
    iconImport: <DeleteOutlineOutlinedIcon fontSize="large" style={icon} />,
  },
  Spotlight: {
    iconImport: <ThemedSpotlightIcon style={{ ...icon, fontSize: '3.5rem' }} />,
  },
  Assign: {
    iconImport: <AssignmentOutlinedIcon fontSize="large" style={icon} />,
  },
  Users: {
    iconImport: <GroupAddOutlinedIcon fontSize="large" style={icon} />,
  },
  Send: {
    iconImport: <EmailOutlinedIcon fontSize="large" style={icon} />,
  },
  Edit: {
    iconImport: <EditOutlinedIcon fontSize="large" style={icon} />,
  },
  Approve: {
    iconImport: <VerifiedUserOutlinedIcon fontSize="large" style={icon} />,
  },
  Attest: {
    iconImport: <ThumbUpAltOutlinedIcon fontSize="large" style={icon} />,
  },
  Upload: {
    iconImport: <CloudUploadOutlinedIcon fontSize="large" style={icon} />,
  },
  notVerified: {
    iconImport: (
      <img
        src="images/VerifiedExclamationNo.svg"
        alt="Social Engg Unavailable"
        style={image}
      />
    ),
  },
  INBOX: {
    iconImport: (
      <InboxIcon style={notyIcon} id="notifications" fill="#6ac52a" />
    ),
  },
  ACCOUNTS: {
    iconImport: (
      <AccountsIcon style={notyIcon} id="notifications" fill="#e60000" />
    ),
  },
  AddAccount: {
    iconImport: <AccountsIcon style={addAccountIcon} />,
  },
  AGENCIES: {
    iconImport: (
      <AgentsIcon style={notyIcon} id="notifications" fill="#11e1de" />
    ),
  },
  POLICIES: {
    iconImport: (
      <PoliciesIcon style={notyIcon} id="notifications" fill="#2180e2" />
    ),
  },
  COMMISSIONS: {
    iconImport: (
      <ManageIcon style={notyIcon} id="notifications" fill="#b183e5" />
    ),
  },
  USERS: {
    iconImport: <PersonOutlineOutlinedIcon style={userIcon} />,
  },
  QUOTES: {
    iconImport: (
      <QuotesIcon style={notyIcon} id="notifications" fill="#6ac52a" />
    ),
  },
  GOOGLE: {
    iconImport: <img src="images/Google.svg" alt="Aws" style={notyIcon} />,
  },
  GOOGLEWORKSPACE: {
    iconImport: <GoogleWorkspace />,
  },
  GSC: {
    iconImport: <GSC />,
  },
  CLOUDFLARE: {
    iconImport: <Cloudflare />,
  },
  SOPHOS: {
    iconImport: <Sophos />,
  },
  AWS: {
    iconImport: [
      { dark: <img src="images/AWS_White.svg" alt="Aws" style={notyIcon} /> },
      { light: <img src="images/AWS_Black.svg" alt="Aws" style={notyIcon} /> },
    ],
  },
  MICROSOFT: {
    iconImport: <img src="images/Microsoft.svg" alt="Aws" style={notyIcon} />,
  },
  Google_Authenticator: {
    iconImport: (
      <img
        src="images/GoogleAuthenticator.svg"
        alt="Google"
        style={{ width: '5rem' }}
      />
    ),
  },
  SMS: {
    iconImport: (
      <img
        src="images/SMS_Blurb.svg"
        alt="Google"
        style={{ width: '5rem', marginBottom: '1rem' }}
      />
    ),
  },
  PoliciesIcon: { iconImport: <PoliciesIcon /> },
};
