import React, { useMemo, useCallback } from 'react';
import _ from 'lodash';
import SkeletonBase from '@mui/lab/Skeleton';

import Numeral from 'numeral';
import Moment from 'moment';
import { styled } from '@mui/material';
import CheckBoxFilledIcon from '../../../_assets/svg/CheckBoxFilledIcon.svg';

import CheckBoxSelectedIcon from '../../../_assets/svg/CheckBoxSelectedIcon.svg';
import CheckBoxOutlineBlankIcon from '../../../_assets/svg/CheckBoxOutlineBlankIcon.svg';
import { withShowable } from '../../_global/lib/withShowable';
import Coverages from '../../_statics/250/coverages/coverages.statics.json';
import { useCoverages } from '../../../components/hooks/coverages/useCoverages';
import { ProductTypes } from '../../../types';
import { formatCurrencyToTenthsPlaceWithUnit } from '../../../utils/currency.utils';

const Skeleton = withShowable(SkeletonBase);

const CompareQuoteCoverages = ({
  quotes,
  loading = true,
  isP100 = false,
  showFooter = false,
}) => {
  const { data: availableCoverages } = useCoverages(ProductTypes.p250, {
    placeholderData: {},
  });

  const memoizedData = useMemo(
    () => (isP100 ? null : formatP250Data(quotes, availableCoverages)),
    [isP100, quotes, availableCoverages]
  );

  return (
    <Container>
      <RowFiller />
      <RowContainer>
        {_.times(4, (index) => (
          <FillerCell borderRadius key={index}>
            <Skeleton show={loading} variant="text" width="90%" height={20} />
            <Title show={index < 1 && !loading}>
              Coverage Details and Limits
            </Title>
          </FillerCell>
        ))}
      </RowContainer>
      {groupLabels.map(
        (label, index) =>
          !isP100 &&
          index !== 4 && (
            <React.Fragment key={label}>
              <RowHeader
                title={label}
                isAmendatoryEndorsement={index > 4}
                loading={loading}
              />
              <DataRows
                groupId={index + 1}
                memoizedData={memoizedData}
                isAmendatoryEndorsement={index > 4}
                loading={loading}
              />
              <RowFiller borderBottom />
            </React.Fragment>
          )
      )}
      {showFooter && (
        <CompareQuoteFooter quotes={quotes} isP100={isP100} loading={loading} />
      )}
    </Container>
  );
};

const RowFiller = ({ borderBottom = false }) => (
  <RowContainer height="1.25rem">
    {_.times(4, (index) => (
      <FillerCell transparentBg key={index} borderBottom={borderBottom} />
    ))}
  </RowContainer>
);

const RowHeader = ({ title, isAmendatoryEndorsement, loading }) => {
  if (loading) {
    return (
      <RowContainer height="4rem">
        {_.times(4, (index) => (
          <React.Fragment key={index}>
            <Cell transparentBg show={index < 1}>
              <Skeleton variant="text" width="90%" height={23} />
            </Cell>
            <Cell transparentBg show={index > 0}>
              <Skeleton variant="text" width="90%" height={23} />
            </Cell>
          </React.Fragment>
        ))}
      </RowContainer>
    );
  }
  return (
    <RowContainer height="4rem">
      {_.times(4, (index) => (
        <React.Fragment key={index}>
          <Cell transparentBg show={index < 1}>
            <CoverageTitle>{title}</CoverageTitle>
          </Cell>
          <Cell transparentBg show={index > 0}>
            {!isAmendatoryEndorsement &&
              headerLabels.map((label) => (
                <SubCell transparentBg key={label}>
                  <CoverageTitle small>{label}</CoverageTitle>
                </SubCell>
              ))}
          </Cell>
        </React.Fragment>
      ))}
    </RowContainer>
  );
};

const DataRows = ({
  groupId,
  memoizedData,
  isAmendatoryEndorsement,
  loading,
}) => {
  const getCheckbox = useCallback((mandatory, isSelected) => {
    if (mandatory)
      return <CheckBoxFilledIcon style={{ 'pointer-events': 'none' }} />;
    if (isSelected)
      return (
        <CheckBoxSelectedIcon
          viewBox="0 0 24 24"
          style={{ 'pointer-events': 'none' }}
        />
      );
    return <CheckBoxOutlineBlankIcon style={{ 'pointer-events': 'none' }} />;
  }, []);

  const isMandatory = useCallback(
    (mandatory, allAvailable, atLeastOneAvailable) => {
      if (mandatory) return 'title';
      if (allAvailable) return 'green';
      if (atLeastOneAvailable) return 'yellow';
      return undefined;
    },
    []
  );

  if (loading) {
    return (
      <>
        {Object.keys(memoizedData[groupId]).map((coverage, outerIndex) => (
          <RowContainer height="2.2rem" key={outerIndex}>
            <Cell transparentBg={outerIndex % 2 !== 0}>
              <Skeleton variant="text" width="90%" height={15} />
            </Cell>
            {memoizedData[groupId][coverage].data.map((__, innerIndex) => (
              <Cell key={innerIndex} transparentBg={outerIndex % 2 !== 0}>
                <Skeleton variant="text" width="90%" height={15} />
              </Cell>
            ))}
          </RowContainer>
        ))}
      </>
    );
  }

  return (
    <>
      {Object.keys(memoizedData[groupId]).map((coverage, outerIndex) => (
        <RowContainer height="2.2rem" key={outerIndex}>
          <Cell transparentBg={outerIndex % 2 !== 0}>
            <CoverageLabel
              color={isMandatory(
                memoizedData[groupId][coverage].mandatory,
                memoizedData[groupId][coverage].allAvailable,
                memoizedData[groupId][coverage].atLeastOneAvailable
              )}
            >
              {coverage}
            </CoverageLabel>
          </Cell>
          {memoizedData[groupId][coverage].data.map((arr, innerIndex) => (
            <Cell key={innerIndex} transparentBg={outerIndex % 2 !== 0}>
              {isAmendatoryEndorsement
                ? getCheckbox(memoizedData[groupId][coverage].mandatory, arr[0])
                : arr.map((el, index) => (
                    <React.Fragment key={el}>
                      <SubCell show={index === 0} transparentBg stye>
                        {getCheckbox(
                          memoizedData[groupId][coverage].mandatory,
                          el
                        )}
                      </SubCell>
                      <SubCell show={index !== 0} transparentBg>
                        {el || '-'}
                      </SubCell>
                    </React.Fragment>
                  ))}
            </Cell>
          ))}
        </RowContainer>
      ))}
    </>
  );
};

const CompareQuoteFooter = ({ quotes, isP100 = false, loading }) => {
  const memoizeQuotesFooter = useMemo(
    () => formatFotter(quotes, isP100),
    [isP100, quotes]
  );
  const memoizedFooterLabels = useMemo(
    () =>
      isP100
        ? ['Premium', 'Fees', 'Final Premium']
        : ['Premium', 'Fees', 'Surplus Tax/Surcharges', 'Final Premium'],
    [isP100]
  );

  return memoizeQuotesFooter.map((row, outerIndex) => (
    <>
      <RowFiller />
      <RowContainer height="1.5rem">
        {_.times(4, (index) => (
          <React.Fragment key={index}>
            <Cell transparentBg show={index < 1}>
              <Skeleton variant="text" width="90%" height={20} show={loading} />
              {memoizeQuotesFooter.length === outerIndex + 1 ? (
                <Title show={!loading} fontSize="paragon" isBold>
                  {memoizedFooterLabels[outerIndex]}
                </Title>
              ) : (
                <Title show={!loading}>
                  {memoizedFooterLabels[outerIndex]}
                </Title>
              )}
            </Cell>
            <Cell transparentBg show={index > 0}>
              <Skeleton variant="text" width="90%" height={20} show={loading} />
              {memoizeQuotesFooter.length === outerIndex + 1 ? (
                <Title show={!loading} fontSize="paragon" isBold>
                  {row[index - 1]}
                </Title>
              ) : (
                <Title show={!loading}>{row[index - 1]}</Title>
              )}
            </Cell>
          </React.Fragment>
        ))}
      </RowContainer>
    </>
  ));
};

export default CompareQuoteCoverages;

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: 0,
  marginBottom: '2rem',
  color: `${theme.palette.primary.main}`,
}));

const RowContainer = styled('div')(({ height }) => ({
  height: height || '2.66rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const Cell = withShowable(
  styled('div')(({ transparentBg, theme }) => ({
    backgroundColor: transparentBg
      ? 'transparent'
      : theme.palette.background.oddRow,
    height: '100%',
    minWidth: '25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:nth-child(n+2)': {
      borderLeft: `1px solid ${theme.palette.primary.modalBorder}`,
    },
    '&:first-child': {
      justifyContent: 'flex-end',
      paddingRight: '1.66rem',
      textAlign: 'right',
    },
  }))
);

const FillerCell = styled(Cell)(
  ({ borderRadius = false, borderBottom, theme }) => ({
    '&:first-child': {
      borderTopLeftRadius: borderRadius ? '0.5rem' : '0',
      borderBottomLeftRadius: borderRadius ? '0.5rem' : '0',
    },
    '&:last-child': {
      borderTopRightRadius: borderRadius ? '0.5rem' : '0',
      borderBottomRightRadius: borderRadius ? '0.5rem' : '0',
    },
    borderBottom: borderBottom
      ? `1px solid ${theme.palette.primary.modalBorder}`
      : 'none',
  })
);

const SubCell = withShowable(
  styled(Cell)(({ theme }) => ({
    minWidth: '22.75%',
    borderLeft: 'none',
    '&:nth-child(n+2)': {
      borderLeft: 'none',
    },
    '&:nth-child(1)': {
      minWidth: '21.75%',
    },
    '&:nth-child(5)': {
      minWidth: '23%',
    },
    '&:nth-child(4)': {
      minWidth: '9%',
      borderRight: `1px solid ${theme.palette.primary.modalBorder}`,
      borderLeft: `1px solid ${theme.palette.primary.modalBorder}`,
      height: '50%',
    },
    '&:nth-child(2)': {
      lineHeight: 1.4,
      textAlign: 'center',
    },
  }))
);

const Title = withShowable(
  styled('h3')(({ fontSize, isBold, theme }) => ({
    fontSize: fontSize
      ? theme.config.textSizes[fontSize]
      : theme.config.textSizes.primer,
    fontWeight: isBold
      ? theme.config.weights.bold
      : theme.config.weights.normal,
    margin: 0,
  }))
);

const CoverageTitle = styled('h4')(({ small, theme }) => ({
  fontSize: small
    ? theme.config.textSizes.normal
    : theme.config.textSizes.tertia,
  textTransform: 'uppercase',
  fontWeight: theme.config.weights.normal,
}));

const CoverageLabel = styled('h5')(({ color, theme }) => ({
  fontSize: theme.config.textSizes.normal,
  fontWeight: theme.config.weights.normal,
  color: color ? theme.palette.primary[color] : 'inherit',
}));

const getRetroActivePeriod = (retroActivePeriod, retroActiveDate) => {
  if (retroActivePeriod === -1) return 'Full';
  if (retroActiveDate) return Moment(retroActiveDate).utc().format('MM/DD/YY');
  return undefined;
};

const formatP250Data = (quotes, reduxP250Coverages) => {
  const finalDataFormat = {};
  const CoveragesObj = {};

  Coverages.forEach((coverage) => {
    CoveragesObj[coverage.key] = {
      mandatory: coverage.mandatory,
      groupId: coverage.groupId,
      name: reduxP250Coverages[coverage._id].name,
    };
  });

  // Initialize data model and prefill finalFormatData
  _.times(7, (index) => {
    const filteredByGroup = Coverages.filter(
      (coverage) => coverage.groupId == index + 1
    );
    const objOfCoverageNames = filteredByGroup.reduce((acc, curr) => {
      return (
        (acc[reduxP250Coverages[curr._id].name] = {
          mandatory: CoveragesObj[curr.key].mandatory || false,
          atLeastOneAvailable: false,
          allAvailable: false,
          data: [],
        }),
        acc
      );
    }, {});
    finalDataFormat[index + 1] = objOfCoverageNames;
  });

  let placeholderCounter = 0;

  const innerFormatQuote = async (quote) => {
    const quoteCoverageArray = [];
    let quoteCoverages = _.get(quote, 'initial250RequestData.coverages');

    if (!quoteCoverages) {
      return (placeholderCounter += 1);
    }

    // Prefill qquoteCoverages with all possible coverages
    quoteCoverages = _.unionBy(quoteCoverages, coveragesPlaceholder, 'name');

    // Get the coverage groupIds + orderIds  + coverage obj into quoteCoverageArray
    quoteCoverages.forEach((quoteCoverage) => {
      const coverage = CoveragesObj[quoteCoverage.name];
      if (coverage) {
        quoteCoverageArray.push({
          ...quoteCoverage,
          name: coverage.name,
          groupId: coverage.groupId,
          orderId: coverage.order,
        });
      }
    });

    // Sort coverages by groupId and OrderId before putting on finalFormatData obj
    quoteCoverageArray.sort((coverage0, coverage1) => {
      const orderId0 = coverage0.orderId;
      const orderId1 = coverage1.orderId;
      const groupId0 = parseInt(coverage0.groupId);
      const groupId1 = parseInt(coverage1.groupId);

      if (groupId0 > groupId1) {
        return 1;
      }
      if (groupId0 < groupId1) {
        return -1;
      }
      if (orderId0 > orderId1) {
        return 1;
      }
      if (orderId0 < orderId1) {
        return -1;
      }
      return -1;
    });

    // Map group and coverages to final data format
    quoteCoverageArray.forEach((coverage) => {
      finalDataFormat[coverage.groupId][coverage.name].data.push([
        coverage.isAvailable,
        coverage.limit
          ? formatCurrencyToTenthsPlaceWithUnit(coverage.limit)
          : undefined,
        coverage.deductible
          ? Numeral(coverage.deductible).format('$0,0.[00]')
          : undefined,
        coverage.waitingPeriod,
        getRetroActivePeriod(
          coverage.retroActivePeriod,
          coverage.retroActiveDate
        ),
      ]);
    });
  };

  quotes.forEach((quote) => {
    innerFormatQuote(quote);
  });

  // Make sure every coverage has a 2D Array with 3 rows and columns
  Object.keys(finalDataFormat).forEach((groupKey) => {
    Object.keys(finalDataFormat[groupKey]).forEach((rowKey) => {
      const array2D = finalDataFormat[groupKey][rowKey].data;

      if (array2D.length < 3) {
        const diff = 3 - array2D.length;

        _.times(diff, () => {
          array2D.push([undefined, undefined, undefined, undefined, undefined]);
        });
      }

      const arrayOfIsSelected = finalDataFormat[groupKey][rowKey].data
        .map((array) => array[0])
        .slice(0, 3 - placeholderCounter);

      if (arrayOfIsSelected.every((bool) => bool === true)) {
        finalDataFormat[groupKey][rowKey].allAvailable = true;
      }
      if (arrayOfIsSelected.some((bool) => bool === true)) {
        finalDataFormat[groupKey][rowKey].atLeastOneAvailable = true;
      }
    });
  });

  return finalDataFormat;
};

const formatFotter = (quotes, isP100 = false) => {
  const data = [];

  if (isP100) {
    _.times(3, () => data.push([]));
  } else {
    _.times(4, () => data.push([]));
  }

  if (quotes) {
    quotes.forEach((quote) => {
      const premium = _.get(quote, 'premium')
        ? Numeral(_.get(quote, 'premium')).format('$0,0.[00]')
        : '-';
      const fee = _.get(quote, 'mgaFee')
        ? Numeral(_.get(quote, 'mgaFee')).format('$0,0.[00]')
        : '-';
      const totalPremium = _.get(quote, 'totalPremium')
        ? Numeral(_.get(quote, 'totalPremium')).format('$0,0.[00]')
        : '-';

      if (isP100) {
        data[0].push(premium);
        data[1].push(fee);
        data[2].push(totalPremium);
      } else {
        let surplusLineTax = _.get(
          quote,
          'quoteResponse.surplusLineTaxRate',
          0
        );
        surplusLineTax =
          surplusLineTax !== 0
            ? Numeral(_.get(quote, 'premium')).format('$0,0.[00]')
            : 'N/A';
        data[0].push(premium);
        data[1].push(fee);
        data[2].push(surplusLineTax);
        data[3].push(totalPremium);
      }
    });
  }
  return data;
};

const coveragesPlaceholder = Coverages.map((coverage) => {
  return {
    name: coverage.key,
    isAvailable: false,
  };
});

const headerLabels = [' ', 'Coverage Limit', 'D', 'W', 'R'];

const groupLabels = [
  'Liability Expense',
  'First Party Expense',
  'First Party Loss',
  'Coverage Endorsements',
  'placeholder',
  'Amendatory Endorsements',
  'Amendatory Endorsements (Referral)',
];
