import React, { Suspense } from 'react';
import { Route, Switch, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import qs from 'qs';

// global store
import { useGrowthBook } from '@growthbook/growthbook-react';
import { setScopes } from '../reducers/scopes.reducer';

// utils
import {
  cacheAuthTokens,
  reduceScopes,
  retrieveAuthTokens,
} from '../utils/next/auth.utils';
import { PubSub, PubSubModule } from '../utils/eventUtils';
import { decodeJwtPayload } from '../utils/jwtUtils';

import { STATUS } from '../components/hooks/common/status.statics';
import { checkIfMfaEnforced } from '../utils/mfa.utils';
import { useEnvQuery } from '../queries/useEnvQuery';
import { useAuth } from '../components/hooks/useAuth';
import { useScrollReset } from '../components/hooks/useScrollReset';

import {
  EVENT_BUS_ACCOUNT_SWITCH_REQUESTED,
  EVENT_BUS_JUMP_REQUESTED,
  JumpEventBus,
} from '../_events/jump.bus';
import {
  getSwitchAccountsToken,
  getTempExchangeTokenForJump,
} from '../api/auth/jump.api';
import { exchangePersonaForPath, useGetPlatformRegion } from '../utils';
import { userPersonaDiscovered, reyhdrateFromPersona } from '../store/actions';
import { ConsoleLayoutLoading } from '../console/layouts/ConsoleLayoutLoading';
import {
  selectGrowthbook,
  selectUserLanguagePreferences,
} from '../store/selectors/ui-settings.selectors';
import { useActor } from '../components/hooks/useActor';
import { useCowbellTranslations } from '../i18n/translations';
import { getAxiosBaseURL } from '../config/axios/axiosApiConfig';

const CarrierDashboardRoutesWrapper = React.lazy(() =>
  import(
    /* webpackChunkName: 'CarrierDashboardRoutesWrapper' */ '../components/wrappers/CarrierDashboardWrapper'
  )
);
const VendorDashboardRoutesWrapper = React.lazy(() =>
  import(
    /* webpackChunkName: 'CarrierDashboardRoutesWrapper' */ '../components/wrappers/VendorDashboardWrapper'
  )
);

const ClusterDashboardRoutesWrapper = React.lazy(() =>
  import(
    /* webpackChunkName: 'ClusterDashboardRoutesWrapper' */ '../components/wrappers/ClusterDashboardWrapper'
  )
);

const CustomerDashboardRoutesWrapper = React.lazy(() =>
  import(
    /* webpackChunkName: 'CustomerDashboardRoutesWrapper' */ '../components/wrappers/CustomerDashboardWrapper'
  )
);

const AdminDashboardRoutesWrapper = React.lazy(() =>
  import(
    /* webpackChunkName: 'AdminDashboardRoutesWrapper' */ '../components/wrappers/AdminDashboardWrapper'
  )
);

const DaDashboardRoutesWrapper = React.lazy(() =>
  import(
    /* webpackChunkName: 'DaDashboardRoutesWrapper' */ '../components/wrappers/DaDashboardWrapper'
  )
);

const MsspDashboardRoutesWrapper = React.lazy(() =>
  import(
    /* webpackChunkName: 'MsspDashboardRoutesWrapper' */ '../components/wrappers/MsspDashboardWrapper'
  )
);

const AgencyDashboardRoutesWrapper = React.lazy(() =>
  import(
    /* webpackChunkName: 'AgencyDashboardRoutesWrapper' */ '../components/wrappers/AgencyDashboardWrapper'
  )
);

const AuthBus = new PubSubModule();

export const AuthenticatedRouter = ({ user }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { persona: personaFromURL } = useParams();
  const actor = useActor();
  const { accountType, isCowbellUserJumped } = useAuth();
  const location = useLocation();
  useScrollReset({ watchValue: location.pathname });
  const { featureFlagsEnabled, new_agent_experience } =
    useSelector(selectGrowthbook);
  const region = useGetPlatformRegion();
  const platformLanguage = useSelector(selectUserLanguagePreferences);
  const { i18n } = useCowbellTranslations();

  // GrowthBook
  const growthbook = useGrowthBook();
  // Set specific user info for your FF or test cases here
  React.useEffect(() => {
    growthbook?.setAttributes({
      // other user attributes can be added here as needed
      userEmail: user.email,
      wreckless_ignore: !featureFlagsEnabled,
      isCowbell: actor.isCowbell,
      agencyId: actor?.account?.id,
      new_agent_experience,
      region,
    });
  }, [
    growthbook,
    featureFlagsEnabled,
    user,
    actor,
    new_agent_experience,
    region,
  ]);

  React.useEffect(() => {
    if (platformLanguage) {
      i18n.changeLanguage(platformLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformLanguage]);

  React.useEffect(() => {
    const sub = PubSub.subscribe('api:error:5xx', (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    });

    // sync the store with token information
    dispatch(
      userPersonaDiscovered({
        persona: accountType,
        _legacy_persona: personaFromURL,
      })
    );

    // rehydrate the ui settings
    const uisettings = localStorage.getItem(accountType);

    if (uisettings) {
      const nextUiSettings = JSON.parse(uisettings);
      dispatch(reyhdrateFromPersona(nextUiSettings));
    }

    return () => sub.remove();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    // set title for application as a tab identifier - PS-2773
    const actorAccName = actor.account.fullName || actor.account.name;
    if (actorAccName) {
      document.title = `${actorAccName} - Cowbell Adaptive Insurance Platform`;
    }
  }, [actor]);

  // setup refreshing of access tokens
  useAuthWorker();

  // listen for user jumps
  useJumpListener();

  // listen for scope changes
  const { status: scopesListenerStatus } = useScopesListener();

  // perform check if mfa is enforced
  React.useEffect(() => {
    if (!user || isCowbellUserJumped) return;
    checkIfMfaEnforced(user);
  });

  // fetch env
  useEnvQuery();

  if (
    scopesListenerStatus === STATUS.REJECTED ||
    scopesListenerStatus === STATUS.PENDING ||
    scopesListenerStatus === STATUS.IDLE ||
    i18n.language !== platformLanguage
  ) {
    return null;
  }

  return (
    <Switch>
      <Suspense fallback={<ConsoleLayoutLoading />}>
        <Route path="/carrier" component={CarrierDashboardRoutesWrapper} />
        <Route path="/vendor" component={VendorDashboardRoutesWrapper} />
        <Route path="/cluster" component={ClusterDashboardRoutesWrapper} />
        <Route path="/customer" component={CustomerDashboardRoutesWrapper} />
        <Route path="/agency" component={AgencyDashboardRoutesWrapper} />
        <Route path="/da" component={DaDashboardRoutesWrapper} />
        <Route path="/mssp" component={MsspDashboardRoutesWrapper} />
        <Route path="/admin" component={AdminDashboardRoutesWrapper} />
      </Suspense>
    </Switch>
  );
};

const useAuthWorker = () => {
  React.useEffect(() => {
    // load the auth worker script
    const AuthWorker = new Worker('/js/workers/auth.worker.v3.js');
    const { accessToken, refreshToken } = retrieveAuthTokens();

    // retrieve scopes from access token
    decodeJwtPayload(accessToken, (error, payload) => {
      if (error) {
        console.error(error);
        return;
      }

      // start the auth worker (refresh polling)
      AuthWorker.postMessage({
        name: 'worker:auth:start',
        payload: {
          baseAPIURL: getAxiosBaseURL(),
          accessToken,
          refreshToken,
          expires: payload.exp,
        },
      });
    });

    // when the worker has success or error
    AuthWorker.onmessage = function (message) {
      if (!message.data.isError) {
        const { data } = message;
        cacheAuthTokens(data.payload.accessToken, data.payload.refreshToken);
        AuthBus.publish(
          'AUTHENTICATION:REFRESH:SUCCESSFUL',
          data.payload.accessToken
        );
      }
    };

    // stop auth worker when user logs out
    return () => {
      AuthWorker.terminate();
    };
    // eslint-disable-next-line
  }, []);
};

const useScopesListener = () => {
  const dispatch = useDispatch();
  const tokens = retrieveAuthTokens();
  const { accessToken } = tokens;
  const [status, setStatus] = React.useState(STATUS.IDLE);

  React.useEffect(() => {
    // load in initial scopes if they don't exist
    setStatus(STATUS.PENDING);
    decodeJwtPayload(accessToken, (error, payload) => {
      if (error) {
        setStatus(STATUS.REJECTED);
        return;
      }

      // reformat scopes
      const scopes = reduceScopes(payload.p);
      // set scopes in global store
      dispatch(setScopes(scopes));
      setStatus(STATUS.RESOLVED);
    });

    const onRefreshEvent = AuthBus.subscribe(
      'AUTHENTICATION:REFRESH:SUCCESSFUL',
      (nextAccessToken) => {
        decodeJwtPayload(nextAccessToken, (error, payload) => {
          if (error) {
            setStatus(STATUS.REJECTED);
            return;
          }

          // reformat scopes

          const scopes = reduceScopes(payload.p);
          // set scopes in global store
          dispatch(setScopes(scopes));
          setStatus(STATUS.RESOLVED);
        });
      }
    );

    return () => {
      onRefreshEvent.remove();
    };
    // eslint-disable-next-line
  }, []);

  return {
    status,
  };
};

const useJumpListener = () => {
  React.useEffect(() => {
    const onJumpRequestedListener = JumpEventBus.subscribe(
      EVENT_BUS_JUMP_REQUESTED,
      handleJumpRequested
    );

    const onSwitchAccountRequestedListener = JumpEventBus.subscribe(
      EVENT_BUS_ACCOUNT_SWITCH_REQUESTED,
      handleSwitchAccountRequested
    );

    return () => {
      onJumpRequestedListener.remove();
      onSwitchAccountRequestedListener.remove();
    };
  });
};

async function handleJumpRequested({ accountType, accountId, accountName }) {
  const personaPath = exchangePersonaForPath(accountType);

  if (!accountType || !personaPath) {
    throw new Error(`Jump Failed: Unsupported account type "${accountType}"`);
  }

  try {
    const { data: token } = await getTempExchangeTokenForJump(
      personaPath,
      accountId
    );

    const { origin } = window;
    const query = accountName
      ? qs.stringify({ accountName }, { addQueryPrefix: true })
      : '';

    window.open(`${origin}/loading/${token}${query}`, '_blank');
  } catch (error) {
    // likely we want to show an error in a snackbar at somepoint
    console.error(`Jump Failed: ${error.message}`);
  }
}

async function handleSwitchAccountRequested({
  accountType,
  accountId,
  accountName,
}) {
  if (!accountType) {
    throw new Error(
      `Account Switch Failed: Unsupported account type "${accountType}"`
    );
  }

  try {
    const { data: token } = await getSwitchAccountsToken(accountId);

    const { origin } = window;
    const query = accountName
      ? qs.stringify({ accountName }, { addQueryPrefix: true })
      : '';

    window.open(`${origin}/loading/${token}${query}`, '_blank');
  } catch (error) {
    console.error(`Switch Account Failed: ${error.message}`);
  }
}
