import React from 'react';

// components
import { Skeleton } from '@mui/lab';
import { Box, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const Header = () => {
  return (
    <Grid container style={{ padding: '10px 2.66rem 10px 2.5rem' }}>
      <Grid item md={4}>
        <Box display="flex">
          <Skeleton width="45%" height={15} animation="wave" />
        </Box>
      </Grid>
      <Grid item md={4}>
        <Box display="flex" justifyContent="center">
          <Skeleton width="40%" height={15} animation="wave" />
        </Box>
      </Grid>
      <Grid item md={4}>
        <Grid container>
          <Grid item md={4}>
            <Box display="flex" justifyContent="flex-end">
              <Skeleton width={75} height={15} animation="wave" />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box display="flex" justifyContent="flex-end">
              <Skeleton width={75} height={25} animation="wave" />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box display="flex" justifyContent="flex-end">
              <Skeleton width={75} height={25} animation="wave" />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CoverageLine = () => {
  return (
    <Grid container style={{ padding: '10px 2.66rem 10px 2.5rem' }}>
      <Grid item md={4} style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton
          variant="rectangular"
          width={10}
          height={10}
          animation="wave"
          style={{ marginRight: 10 }}
        />
        <Skeleton width={75} height={15} animation="wave" />
      </Grid>
      <Grid item md={4}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Skeleton
            width="90%"
            height={15}
            animation="wave"
            style={{ marginRight: 10 }}
          />
          <Skeleton
            variant="circular"
            width={10}
            height={10}
            animation="wave"
          />
        </Box>
      </Grid>
      <Grid item md={4}>
        <Grid container>
          <Grid item md={4}>
            <Box display="flex" justifyContent="flex-end">
              <Skeleton width={50} height={15} animation="wave" />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box display="flex" justifyContent="flex-end">
              <Skeleton width={50} height={15} animation="wave" />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box display="flex" justifyContent="flex-end">
              <Skeleton width={50} height={15} animation="wave" />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const RequestQuoteLoading = () => {
  const classes = useGroupStyles();

  return (
    <>
      <Divider style={{ margin: '25px 0' }} />
      <Header />
      <div className={classes.groupContainer}>
        {Array(3)
          .fill(null)
          .map(() => (
            <CoverageLine />
          ))}
      </div>
      <Divider style={{ margin: '15px 0' }} />
      <Header />
      <div className={classes.groupContainer}>
        {Array(5)
          .fill(null)
          .map(() => (
            <CoverageLine />
          ))}
      </div>
      <Divider style={{ margin: '15px 0' }} />
      <Header />
      <div className={classes.groupContainer}>
        {Array(7)
          .fill(null)
          .map(() => (
            <CoverageLine />
          ))}
      </div>
      <Divider style={{ margin: '15px 0' }} />
      <Header />
      <div className={classes.groupContainer}>
        {Array(10)
          .fill(null)
          .map(() => (
            <CoverageLine />
          ))}
      </div>
    </>
  );
};

const useGroupStyles = makeStyles(({ palette }) => ({
  groupContainer: {
    '& > div:nth-child(odd)': {
      background: palette.background.default,
    },
  },
}));
