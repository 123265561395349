import type { Persona } from '../../console/_statics/persona.statics';
import { PERSONA_TYPES } from '../../console/_statics/persona.statics';
import { ServiceHandler } from '../../utils';

export const getAccounts = ServiceHandler({
  url: '/api/account/v1/account/search',
});

export const getAccountDetails = ServiceHandler({
  url: '/api/account/v1/account/:persona',
});

export const getInsuredDetails = ServiceHandler({
  url: '/api/account/v1/account/:accountId',
});

/**
 * @name getAccountDetails
 * @description Works for all personas except "Cowbell", "Customer", "Digital Agg.",
 */
export const getAccount = async (persona: Persona, accountId?: string) => {
  if (persona === PERSONA_TYPES.CUSTOMER) {
    return getInsuredDetails(accountId as string);
  }

  return getAccountDetails(persona);
};
