import type { Autocomplete } from '@mui/lab';
import type { AxiosResponse } from 'axios';
import type { TextFieldProps } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { getAgentsInAdmin } from '../../../../api/UserService';
import type { InputLabelBaseProps } from '../../../inputs/InputLabelBase';
import { TypeAheadAutoComplete } from '../../../inputs/autocomplete/TypeAheadAutoComplete';
import type { UpdateAgentInfoFormValues } from '../UpdateAgentInfo';

interface AgentAutoCompleteProps {
  label: string;
  name: string;
  required?: boolean;
  onSelect: (value: AgentValue) => void;
  autoCompleteProps?: React.ComponentType<typeof Autocomplete> | object;
  inputLabelBaseProps?: Omit<InputLabelBaseProps, 'children'>;
  textFieldProps?: TextFieldProps;
  agencyId: string;
}

type AgentValue = {
  label: string;
  value: string;
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  phone: string;
};

export const AgentAutoComplete = (props: AgentAutoCompleteProps) => {
  const { formState } = useFormContext<UpdateAgentInfoFormValues>();

  const getAgents = (searchTerm: string) => {
    if (props.agencyId) {
      return getAgentsInAdmin({
        agencyId: props.agencyId,
        search: searchTerm,
      }).then((resp: AxiosResponse) => {
        return resp.data.content.map((agentOption: AgentOption) => {
          const hasAgentEmail = Boolean(agentOption?.email);
          const label = hasAgentEmail
            ? `${agentOption.firstName} ${agentOption.lastName} (${agentOption.email})`
            : `${agentOption.firstName} ${agentOption.lastName}`;

          return {
            label,
            value: agentOption.id,
            email: agentOption.email,
            firstName: agentOption.firstName,
            lastName: agentOption.lastName,
            phone: agentOption.phone,
          };
        });
      });
    }

    return Promise.resolve([]);
  };

  return (
    <TypeAheadAutoComplete
      textFieldProps={{
        error: Boolean(formState.errors.agent?.value),
        helperText: formState.errors.agent?.value?.message,
      }}
      asyncFunction={getAgents}
      {...props}
    />
  );
};

type AgentOption = {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  phone: string;
};
