import React from 'react';

export const useRowSelections = (defaultSelected = {}) => {
  const [selected, setSelected] = React.useState(defaultSelected);

  const handleSelectRow = (id) => {
    setSelected((currentSelected) => {
      // deselecting...
      if (currentSelected[id]) {
        const copy = { ...currentSelected };
        delete copy[id];

        return copy;
      }

      // selecting...
      return { ...currentSelected, [id]: true };
    });
  };

  const handleSelectAllRows = (data = []) => {
    setSelected(data.reduce((acc, id) => ({ ...acc, [id]: true }), {}));
  };

  const handleDeselectAllRows = React.useCallback(() => {
    setSelected({});
  }, []);

  return {
    selectedRows: selected,
    selectedRowsCount: Object.keys(selected).length,
    handleSelectRow,
    handleSelectAllRows,
    handleDeselectAllRows,
  };
};
