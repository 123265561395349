import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { AGENCY_DASHBOARD_VIEWS } from '../agency-dashboard.statics';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import { StartNewQuote } from './StartNewQuote';
import { BindQuote } from './BindQuote';
import Showable from '../../../../components/Showable';
import { OnboardingScreenHeader } from './OnboardingScreenHeader';
import { RenewPolicy } from './RenewPolicy';
import { AccountRiskLoader } from './AccountRiskLoader';
import { AccountRiskError } from './AccountRiskError';
import { AccountAlreadySignedError } from './AccountAlreadySignedError';

export const AgencyDashboardListingOverlay = () => {
  const { view } = useAgencyDashboardState();
  const showOverlay = view !== AGENCY_DASHBOARD_VIEWS.LISTING;

  const classes = useOverlayStyles({ showOverlay });

  return (
    <Paper className={classes.container}>
      <OnboardingScreenHeader />
      <Showable show={view === AGENCY_DASHBOARD_VIEWS.NEW_QUOTE}>
        <StartNewQuote />
      </Showable>
      <Showable show={view === AGENCY_DASHBOARD_VIEWS.BIND_QUOTE}>
        <BindQuote />
      </Showable>
      <Showable show={view === AGENCY_DASHBOARD_VIEWS.RENEW_POLICY}>
        <RenewPolicy />
      </Showable>
      <Showable show={view === AGENCY_DASHBOARD_VIEWS.ACCOUNT_RISK_LOADER}>
        <AccountRiskLoader />
      </Showable>
      <Showable show={view === AGENCY_DASHBOARD_VIEWS.ACCOUNT_RISK_ERROR}>
        <AccountRiskError />
      </Showable>
      <Showable show={view === AGENCY_DASHBOARD_VIEWS.ACCOUNT_ALREADY_SIGNED}>
        <AccountAlreadySignedError />
      </Showable>
    </Paper>
  );
};

const useOverlayStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    overflow: 'auto',
    width: ({ showOverlay }: any) => (showOverlay ? '100%' : 0),
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.5)',
    height: '100%',
    transition: 'width 600ms',
    zIndex: 1000,
    backgroundColor: theme.agencyDash.background.paper,
  },
}));
