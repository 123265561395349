import React from 'react';

// mui
import { Checkbox, FormControlLabel, styled } from '@mui/material';
// components

import { Controller, useFormContext } from 'react-hook-form';

export const ControlledCheckBox = ({
  label = '',
  name,
  checkBoxProps = {},
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <StyledFormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }, ...renderProps) => (
            <Checkbox
              {...renderProps}
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
              color="primary"
              {...checkBoxProps}
            />
          )}
        />
      }
      label={label}
      {...props}
    />
  );
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  fontSize: theme.config.textSizes.normal,
}));
