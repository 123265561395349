import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';

// mui
import { Divider } from '@mui/material';

// helpers
import { withStyles } from '@mui/styles';
import Numeral from 'numeral';

// components
import { InputLabelBase } from './inputs/InputLabelBase';
import withRouter from './hocs/withRouter';

// svgs
import Connector from '../_assets/svg/Connector.svg';
import DetailsButton from '../_assets/svg/DetailsButton.svg';

// reducers
import {
  setAccountDetails,
  setAdminDetails,
  setAgentDetails,
  setCarrierDetails,
  setClusterDetails,
} from '../console/_tabs/tabs.reducer';
import { ProductTypes } from '../types';
import { useAllow } from './hooks/useAllow';
import { usePersona } from './hooks/usePersona';
import { FORMAT_DATE_AND_TIME, toUniversalUtcDate } from '../utils/date.utils';

const styles = ({ config, palette }) => {
  return {
    label: {
      padding: '0.8rem 0 0 0',
      color: 'inherit',
    },
    valueTile: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginBottom: '1.5rem',
    },
    section: {
      width: '100%',
      display: 'flex',
      marginTop: '1rem',
      flexDirection: 'column',
    },
    pricingLabel: {
      color: config.colors.cowbellLight,
      fontSize: config.textSizes.paragon,

      '&:nth-child(1)': {
        textAlign: 'left',
        fontWeight: 600,
        fontFamily: 'Titillium Web',
        marginTop: 1,
      },

      '&:nth-child(2)': {
        flex: 'auto',
        textAlign: 'right',
        fontWeight: 'normal',
        fontFamily: 'Oswald',
      },
    },
    data: {
      '& li:first-child label': {
        padding: '0',
      },
    },
    detailsButton: {
      width: '3.4rem',
      height: '2.4rem',
      verticalAlign: 'middle',
      cursor: 'default',
    },
    horiDivider: {
      borderBottom: `1px solid ${palette.text.primary}`,
    },
    connector: {
      padding: '0.7rem 0',
      color: palette.text.secondary,
      fontSize: '1.16rem',
    },
  };
};

const rpMapping = {
  3: 'Full Prior Acts',
  2: '2 Years',
  1: '1 Year',
};

export const PolicySummaryP100 = compose(
  withRouter,
  withStyles(styles)
)(({ location, history, classes, policyData, entireData, binder }) => {
  const dispatch = useDispatch();
  const indicatedPrice = entireData.totalPremium
    ? Numeral(entireData.totalPremium).format('$0,0.00')
    : '-';
  const { waitingPeriod, retroactivePeriod } = entireData;

  const persona = usePersona();

  const CAN_UW_ACCOUNTS = useAllow(['accounts:uw']);
  const CAN_MANAGE_ACCOUNT_PROFILE = useAllow(['account_profile:manage']);
  const CAN_MANAGE_ACCOUNT = useAllow(['accounts:manage']);
  const CAN_COWBELL_ACCOUNT = useAllow(['account:cowbell']);

  let url = `${persona.basePath}/quote/details/underwriter/${entireData.quoteId}/${entireData.accountId}/${ProductTypes.p100}/policies`;
  if (persona.isAgency) {
    url = `/agency/accounts/details/${entireData.quoteId}/${entireData.accountId}/${ProductTypes.p100}/policies`;
  } else if (persona.isCarrier) {
    url = `/carrier/quoteDetails/${entireData.quoteId}/account/${entireData.accountId}/${ProductTypes.p100}/policies`;
  }

  const handleRoute = React.useCallback(() => {
    dispatch(
      setAccountDetails({
        account: {
          companyName: entireData.companyName,
          quoteNumber: entireData.quoteNumber,
          accountId: entireData.accountId,
          agencyId: entireData.agencyId,
          policyId: entireData.id,
          rating: false,
        },
      })
    );
    if (persona.isCowbell) {
      dispatch(
        setAdminDetails({
          admin: {
            from: location.pathname,
            readOnly: {
              quote: true,
              policy: true,
              account: !(CAN_UW_ACCOUNTS || CAN_MANAGE_ACCOUNT_PROFILE),
              risk: true,
              documents: {
                upload: false,
                delete: !CAN_COWBELL_ACCOUNT,
              },
              notes: false,
            },
          },
        })
      );
    } else if (persona.isCarrier) {
      dispatch(
        setCarrierDetails({
          carrier: {
            from: location.pathname,
            readOnly: {
              quote: true,
              policy: true,
              documents: {
                upload: false,
                delete: true,
              },
            },
          },
        })
      );
    } else if (persona.isCluster) {
      dispatch(
        setClusterDetails({
          cluster: {
            from: location.pathname,
            readOnly: {
              quote: true,
              policy: true,
              account: false,
              risk: false,
              documents: {
                upload: true,
                delete: true,
              },
              notes: false,
            },
          },
        })
      );
    } else if (persona.isAgency) {
      dispatch(
        setAgentDetails({
          agent: {
            from: location.pathname,
            readOnly: {
              quote: true,
              policy: true,
              account: !CAN_MANAGE_ACCOUNT,
              risk: true,
              documents: {
                upload: false,
                delete: true,
              },
            },
          },
        })
      );
    }
    history.push(url);

    // eslint-disable-next-line
  }, []);

  const quote = {
    'Policy Number': policyData.policyNumber,
  };

  const contact = {
    PolicyHolder: {
      'Policy Holder Name': `${_.get(
        policyData,
        'customerFirstName',
        ''
      )} ${_.get(policyData, 'customerLastName', '-')}`,
      'Policy Holder Email': _.get(policyData, 'customerEmail', '-'),
    },
    Agent: {
      'Agenct Name': `${_.get(policyData, 'agentFirstName', '')} ${_.get(
        policyData,
        'agentLastName',
        '-'
      )}`,
      'Agent Email': _.get(policyData, 'agentEmail', '-'),
    },
  };

  const quoteInfo = {
    'Policy Effective': `${toUniversalUtcDate(
      policyData.effectiveDate
    )} 12:01 AM`,
    'Policy Ends On': `${toUniversalUtcDate(policyData.endDate)} 12:01 AM`,
    'Policy Term': '365 Days',
  };

  const amount = {
    'Waiting Period': `${waitingPeriod}\tHours`,
    'Retro Active Period': rpMapping[retroactivePeriod],
    'Aggregate Limit': Numeral(entireData.limit).format('$0,000'),
    Deductible: Numeral(entireData.deductible).format('$0,000'),
    ...(entireData.boundOn && {
      'Bound On': `${toUniversalUtcDate(entireData.boundOn)}`,
    }),
    ...(entireData.status === 'CANCELLED' && {
      'Cancellation Date': `${toUniversalUtcDate(entireData.cancellationDate, {
        placeholder: 'N/A',
      })}`,
    }),
    ...(entireData.status === 'CANCELLED' && {
      'Cancellation Reason': entireData.cancellationReason || 'N/A',
    }),
  };

  const connector = _.get(entireData, 'firmographicData.numberOfConnectors', 0);
  const connectorConnected = parseInt(connector) !== 0;

  // get policy bound or issued label and from related fields
  // if not available, use current 'created'
  let policyBoundIssuedLabel = '';
  let policyBoundIssuedDate = '';

  if (binder) {
    policyBoundIssuedLabel = 'Bound On';
    policyBoundIssuedDate = toUniversalUtcDate(
      entireData.boundOn || entireData.created,
      { format: FORMAT_DATE_AND_TIME }
    );
  } else {
    policyBoundIssuedLabel = 'Policy Issued';
    policyBoundIssuedDate = toUniversalUtcDate(
      entireData.issuedDate || entireData.created,
      { format: FORMAT_DATE_AND_TIME }
    );
  }

  return (
    <>
      <ul className="list--unstyled" style={{ height: '8.5rem' }}>
        {_.entries(quote).map((factor, index) => {
          return (
            <li key={index} className="flex--spaced">
              <strong>
                <InputLabelBase className={classes.label}>
                  {factor[0]}
                </InputLabelBase>
              </strong>

              <span>
                {factor[1]}
                <DetailsButton
                  className={classes.detailsButton}
                  onClick={handleRoute}
                />
              </span>
            </li>
          );
        })}

        {_.entries(contact.PolicyHolder).map((factor, index) => {
          return (
            <li key={index} className="flex--spaced">
              <strong>
                <InputLabelBase className={classes.label}>
                  {factor[0]}
                </InputLabelBase>
              </strong>

              <span>{factor[1]}</span>
            </li>
          );
        })}
      </ul>

      <h4 className="tertia-text flex--spaced">
        <u>{binder ? 'BINDER INFORMDATION' : 'POLICY INFORMATION'}</u>
      </h4>

      <ul className={`${classes.data} list--unstyled`}>
        <li key="0-1" className="flex--spaced">
          <strong>
            <InputLabelBase className={classes.label}>
              {policyBoundIssuedLabel}
            </InputLabelBase>
          </strong>

          <span>{policyBoundIssuedDate}</span>
        </li>
        {binder && (
          <li key="0-1" className="flex--spaced">
            <strong>
              <InputLabelBase className={classes.label}>
                Expires On
              </InputLabelBase>
            </strong>

            <span>
              {toUniversalUtcDate(entireData.binderExpiresOn, {
                format: FORMAT_DATE_AND_TIME,
              })}
            </span>
          </li>
        )}

        {_.entries(quoteInfo).map((factor, index) => {
          return (
            <li key={index} className="flex--spaced">
              <strong>
                <InputLabelBase className={classes.label}>
                  {factor[0]}
                </InputLabelBase>
              </strong>

              <span>{factor[1]}</span>
            </li>
          );
        })}
      </ul>

      <h4 className="tertia-text flex--spaced">
        <u>COVERAGE INFORMATION</u>
      </h4>

      <ul className={`${classes.data} list--unstyled`}>
        {_.entries(amount).map((factor, index) => {
          return (
            <li key={index} className="flex--spaced">
              <strong>
                <InputLabelBase inline className={classes.label}>
                  {factor[0]}
                </InputLabelBase>
              </strong>
              <span>{factor[1]}</span>
            </li>
          );
        })}
      </ul>

      <Divider className={classes.horiDivider} style={{ marginTop: '2rem' }} />
      <div
        className={`flex--spaced ${classes.connector}`}
        style={connectorConnected ? { color: '#2180e2' } : null}
      >
        <div style={{ display: 'flex' }}>
          <Connector
            style={{ fontSize: '1.5rem', marginRight: '0.5rem' }}
            fill={connectorConnected ? { color: '#2180e2' } : null}
          />
          <div className={classes.label1}>Connector</div>
        </div>
        {connectorConnected ? (
          <div>{connector} Connected </div>
        ) : (
          <div>None Connected</div>
        )}
      </div>
      <Divider className={classes.horiDivider} />

      <div className={classes.section}>
        <div className={classes.valueTile}>
          <div className={classes.pricingLabel}>Total Premium</div>
          <div className={classes.pricingLabel}>{indicatedPrice}</div>
        </div>
      </div>
    </>
  );
});

export default PolicySummaryP100;
