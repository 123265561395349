import React from 'react';
import _ from 'lodash';

import Moment from 'moment';
import * as Yup from 'yup';

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// mui
import { Grid, DialogContent, DialogActions } from '@mui/material';

// components
import CbButton from '../../Buttons/CbButton';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { SimpleSelect } from '../../inputs';
import { InputLabelBase } from '../../inputs/InputLabelBase';

// utils
import CANCELLATION_REASONS, {
  P100_PRO_CANCELLATION_REASONS,
} from '../../../console/_statics/cancellationreasons.statics.js';
import { withFormController } from '../../hocs/forms';
import { withShowable } from '../../../console/_global/lib/withShowable';
import { useAPIErrorHandler } from '../../hooks/useAPIErrorHandler';

// apis
import {
  cancelP100Policy,
  cancelPrimeXPolicy,
  cancelP250ExcessPolicy,
  cancelP250Policy,
} from '../../../policies/PolicyService';
import { ProductTypes } from '../../../types';
import { COWBELL_POLICY } from '../../tables/table_constants';

const TextField = withFormController(withShowable(TextFieldBase));
const Select = withFormController(SimpleSelect);

export const CancelPolicy = ({ data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [schema, defaultValues] = getProductSchemaMap(data.product);
  const queryClient = useQueryClient();
  const handleAPIError = useAPIErrorHandler();

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    ...methods
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    // searches CANCELLATION_REASONS for the object with the currently selected cancellation reason

    const cancellationReason = _.find(CANCELLATION_REASONS, [
      'value',
      _.get(values, 'cancelReason'),
    ]);

    const payload = {
      ...values,
      cancelDescription: _.get(cancellationReason, 'description'),
    };

    return cancelPolicy({
      id: data.id,
      productType: data.product,
      isExcess: data.isPrimePlus,
      payload,
    })
      .then(() => {
        props.close();
        enqueueSnackbar('Policy Cancellation Requested!', {
          variant: 'success',
        });

        queryClient.invalidateQueries([COWBELL_POLICY]);
      })
      .catch(
        handleAPIError(
          'Something went wrong while requesting the policy cancellation.'
        )
      )
      .finally(() => props.close());
  };

  function handleCancel() {
    props.close();
  }

  const isPolicyP100 = data.product === ProductTypes.p100;
  const isPolicyP100Pro = data.product === ProductTypes.p100_pro;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingBottom: '8px' }}>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <Select
                name="cancelReason"
                disabled={isPolicyP100Pro}
                options={
                  isPolicyP100Pro
                    ? P100_PRO_CANCELLATION_REASONS
                    : CANCELLATION_REASONS
                }
                label="Reason Type"
              />
            </Grid>
            <Grid item md={6}>
              <InputLabelBase indent required>
                Cancellation Date
              </InputLabelBase>
              <TextField
                name="cancelDate"
                fullWidth
                type="date"
                inputProps={{
                  min: data.effectiveDate
                    ? Moment.utc(data.effectiveDate).format('YYYY-MM-DD')
                    : undefined,
                }}
                error={errors.cancelDate?.message}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                name="cancelComment"
                fullWidth
                label="Cancellation Comment"
                required
                show={isPolicyP100}
                error={errors.cancelComment?.message}
              />
              <TextField
                name="cancelAdditionalDescription"
                fullWidth
                label="Cancellation Additional Description"
                required
                show={!isPolicyP100}
                error={errors.cancelAdditionalDescription?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton
            styleName="cancel"
            buttonText="Cancel"
            onClick={handleCancel}
          />
          <CbButton
            type="submit"
            buttonText="Cancel Policy"
            styleName="ctaButton"
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const CancelPolicyConfig = {
  CancelPolicy: {
    component: CancelPolicy,
    config: {
      title: 'Cancel Policy',
    },
  },
};

function cancelPolicy({ id, productType, isExcess, payload }) {
  if (productType === ProductTypes.p100) {
    return cancelP100Policy(id, payload);
  }

  if (productType === ProductTypes.p250) {
    if (isExcess) {
      return cancelP250ExcessPolicy({ id }, payload);
    }

    return cancelP250Policy({ id }, payload);
  }

  return cancelPrimeXPolicy({ productType, policyId: id }, payload);
}

const p100Schema = Yup.object().shape({
  cancelDate: Yup.string().required().label('Cancellation Date'),
  cancelComment: Yup.string().required().label('Cancellation'),
});

const defaultSchema = Yup.object().shape({
  cancelDate: Yup.string().required().label('Cancellation Date'),
  cancelAdditionalDescription: Yup.string().required().label('Cancellation'),
});

const p100DefaultValues = {
  cancelReason: CANCELLATION_REASONS[2].value,
  cancelDate: Moment().format('YYYY-MM-DD'),
  cancelComment: '',
};

const __defaultValues = {
  cancelReason: CANCELLATION_REASONS[2].value,
  cancelDate: Moment().format('YYYY-MM-DD'),
  cancelAdditionalDescription: '',
};

const getProductSchemaMap = (productType) => {
  if (productType === ProductTypes.p100) {
    return [p100Schema, p100DefaultValues];
  }
  return [defaultSchema, __defaultValues];
};
