import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

// lodash
import _ from 'lodash';

// mui
import { Box as MuiBox, Grid } from '@mui/material';

// components
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import CbButton from '../../../../components/Buttons/CbButton';
import CowbellReferToUWButton from './shared/referral/CowbellReferToUWButton';

// hocs
import { withFormController } from '../../../../components/hocs/forms';
import { ScreenTitle } from './shared/ScreenTitle';
import useCompleteQuoteSubjectivity from './shared/useCompleteQuoteSubjectivity';
import { SubjectivityCompletionDate as SubjectivityCompletionDateBase } from './shared/SubjectivityCompletionDate';
import { withShowable } from '../../../_global/lib/withShowable';
import useGetSubjectivityProgress from './shared/useGetSubjectivityProgress';
import { QuoteAgencyStatus } from '../../../../types/common/misc';
import { determineIfStepIsEditable } from '../utils/bindQuoteWorkFlowUtils';
import { FormLanguageProvider } from '../../../../i18n/forms/FormLanguageProvider';
import LanguageForm, {
  useLanguageFormState,
} from '../../../../i18n/forms/LanguageForm';
import { InputLabelBase } from '../../../../components/inputs/InputLabelBase';
import { ProductTypes } from '../../../../types';

const TextField = withFormController(TextFieldBase);

const Box = withShowable(MuiBox);
const SubjectivityCompletionDate = withShowable(SubjectivityCompletionDateBase);

const PolicyHolderInformation = ({
  goForward,
  goBack,
  completeStep,
  step,
  formState,
  formHandlers,
  subjectivities,
  quoteDetails,
  quoteProgress,
  language,
}) => {
  const languageFormState = useLanguageFormState({
    language,
    options: {
      validatePhone: true,
      phoneFieldName: 'customerPhone',
    },
  });

  const defaultValues = {
    ..._.pick(formState, [
      'customerFirstName',
      'customerLastName',
      'customerEmail',
      'customerPhone',
    ]),
  };

  const isQuoteBound =
    quoteProgress.quoteAgencyStatus === QuoteAgencyStatus.BOUND;

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(deriveSchema(languageFormState.languageFormSchema)),
  });

  const { completeSubjectivity, isCompletingStep } =
    useCompleteQuoteSubjectivity({
      quoteId: quoteDetails.uiData.quoteId,
      productType: quoteDetails.uiData.productType,
    });

  const {
    quoteSubjectivityId,
    completed: isStepCompleted,
    subjectivitySubType,
  } = subjectivities[step];

  const isStepEditable = determineIfStepIsEditable({
    subjectivitySubType,
    status: quoteProgress.quoteAgencyStatus,
    isAPI: quoteProgress.isAPI,
    productType: quoteDetails.uiData.productType,
  });

  const {
    data: allSubjectivityInformation = {},
    isLoading: isSubjectivityDataLoading,
    isFetching: isFetchingSubjectivityData,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId,
    quoteId: quoteDetails.id,
    productType: quoteDetails.uiData.productType,
  });

  if (isSubjectivityDataLoading || isFetchingSubjectivityData) {
    return null;
  }

  const onSubmit = (formValues) => {
    if (
      isQuoteBound &&
      !(
        quoteProgress.isAPI &&
        quoteDetails.uiData.productType === ProductTypes.p250
      )
    ) {
      return goForward();
    }
    formHandlers.savePHInfo(formValues);
    const completeSubjectivityParams = {
      quoteSubjectivityId,
      completeStep,
      goForward,
      step,
      payload: {
        ...formValues,
        customerEmail: formValues.customerEmail || null,
        customerFirstName: formValues.customerFirstName || null,
        customerLastName: formValues.customerLastName || null,
        customerPhone: formValues.customerPhone || null,
      },
    };
    return completeSubjectivity(completeSubjectivityParams);
  };

  return (
    <Box className="contrast-text" width="60%">
      <ScreenTitle
        subjectivitySubType={subjectivitySubType}
        title={allSubjectivityInformation.subjectivityTitle}
      />

      <h3>{allSubjectivityInformation.subjectivityDescription ?? ''}</h3>

      <FormLanguageProvider language={languageFormState.language}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item sm={6} style={{ paddingBottom: 0 }}>
                <InputLabelBase required style={{ paddingTop: 0 }}>
                  Policy Holder First Name
                </InputLabelBase>
                <TextField
                  name="customerFirstName"
                  placeholder="Please enter policy holder first name"
                  required
                  disabled={!isStepEditable}
                />
              </Grid>
              <Grid item sm={6} style={{ paddingBottom: 0 }}>
                <InputLabelBase required style={{ paddingTop: 0 }}>
                  Policy Holder Last Name
                </InputLabelBase>
                <TextField
                  name="customerLastName"
                  placeholder="Please enter policy holder last name"
                  required
                  disabled={!isStepEditable}
                />
              </Grid>
              <Grid item sm={6} style={{ paddingTop: 0 }}>
                <InputLabelBase required style={{ paddingTop: 0 }}>
                  Policy Holder Email
                </InputLabelBase>
                <TextField
                  name="customerEmail"
                  placeholder="Please enter policy holder email"
                  required
                  disabled={!isStepEditable}
                />
              </Grid>
              <Grid item sm={6} style={{ paddingTop: '0.3rem' }}>
                <LanguageForm.PhoneField
                  name="customerPhone"
                  label="Policy Holder Phone"
                  disabled={!isStepEditable}
                />
              </Grid>
            </Grid>
            <p className="contrast-text">
              <i>
                NOTE: All other quotes for this account will be invalidated. You
                will have the opportunity to adjust effective date prior to bind
                in this workflow.
              </i>
            </p>
            <SubjectivityCompletionDate
              allSubjectivityInformation={allSubjectivityInformation}
              show={isStepCompleted}
            />
            <Box>
              <CbButton styleName="cancel" size="medium" onClick={goBack}>
                Back
              </CbButton>

              <CbButton
                buttonText="Next"
                type="submit"
                styleName="ctaButton"
                size="medium"
                loading={isCompletingStep}
                disabled={isCompletingStep}
                style={{ marginRight: '0.833rem' }}
              />

              <CowbellReferToUWButton
                quoteId={quoteDetails.id}
                subjectivityId={quoteSubjectivityId}
                productType={quoteDetails.uiData.productType}
              />
            </Box>
          </form>
        </FormProvider>
      </FormLanguageProvider>
    </Box>
  );
};

export default PolicyHolderInformation;

const deriveSchema = (languageFormSchema) => {
  return Yup.object().shape({
    customerFirstName: Yup.string().label('Customer first name').required(),
    customerLastName: Yup.string().label('Customer last name').required(),
    customerPhone: languageFormSchema.customerPhone,
    customerEmail: Yup.string().required().label('Customer Email').email(),
  });
};
