// components
import { Box, Card, Divider, styled } from '@mui/material';

import { DoubleLabelSwitch } from '../../../../../../components/inputs/switches/DoubleLabelSwitch';

import { OrganizationDetails } from './OrganizationDetails';
import { OrganizationContact } from './OrganizationContact';
import { OrganizationBroker } from './OrganizationBroker';
import { OrganizationBrokerWithBOR } from './OrganizationBrokerWithBOR';
import Showable from '../../../../../../components/Showable';

export const OrganizationDetailsTab = ({ policy, account, latestRisk }) => {
  const { latestRiskSelected, handleLatestRiskToggle } = latestRisk;

  return (
    <OrganizationDetailsTabCard square>
      <Box className="justify-center">
        <DoubleLabelSwitch
          onChange={handleLatestRiskToggle}
          value={latestRiskSelected}
          offLabel="Risk as of Last Underwriting"
          onLabel="Latest Risk"
        />
      </Box>
      <OrganizationDetails
        policy={policy}
        account={account}
        latestRiskSelected={latestRiskSelected}
      />
      <Divider style={{ width: '100%', margin: '1rem 0 0.5rem 0' }} />
      <OrganizationContact
        policy={policy}
        account={account}
        latestRiskSelected={latestRiskSelected}
      />
      <Divider style={{ width: '100%', margin: '1rem 0 0.5rem 0' }} />
      <Showable show={!policy.isBOR}>
        <OrganizationBroker policy={policy} />
      </Showable>
      <Showable show={Boolean(policy.isBOR) && Boolean(policy.brokerOfRecord)}>
        <OrganizationBrokerWithBOR policy={policy} />
      </Showable>
    </OrganizationDetailsTabCard>
  );
};

const OrganizationDetailsTabCard = styled(Card)(({ theme: { palette } }) => ({
  padding: '1rem',
  backgroundColor: palette.background.default,
}));
