import _ from 'lodash';
import React from 'react';
import { withSnackbar } from 'notistack';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

// componets
import { Box, Backdrop, CircularProgress, DialogContent } from '@mui/material';

import { CartList } from './CartList';
import { Button } from '../../../../components/Buttons/Button';

// services
import {
  deleteListedQuote,
  getTempQuotesByAccount,
} from '../../../../api/apis';

// reducers
import { setPrime250Ui } from '../../../_reducers/prime250.reducer';

import { useQuoteCreationFunctions } from '../../../../accounts/accountUtils';

export const RequestQuote250Cart = compose(
  withSnackbar,
  withRouter
)(({ enqueueSnackbar, data, close }) => {
  const [quotes, setQuotes] = React.useState([]);
  const [loading, setLoading] = React.useState();
  const [sending, setSending] = React.useState();

  const {
    detemineAgentOnQuoteCreation,
    determineRetailAgentForFlorida,
    getAgencyId,
    determineTeamIdOfAgent,
  } = useQuoteCreationFunctions(data.account);

  const handleRemoveQuote = React.useCallback(
    (id) => {
      return () => {
        const agencyId = getAgencyId();
        deleteListedQuote(id, agencyId)
          .then(() => {
            const nextQuotes = [...quotes];

            _.remove(nextQuotes, (quote) => quote.id === id);

            data.dispatch(setPrime250Ui({ cartTotal: nextQuotes.length }));
            setQuotes(nextQuotes);
          })
          .catch(() =>
            enqueueSnackbar('Failed to remove quote, please try again', {
              variant: 'error',
            })
          );
      };
    },
    // eslint-disable-next-line
    [quotes]
  );

  const handleQuoteNow = React.useCallback(() => {
    setSending(true);

    const agentInfo = detemineAgentOnQuoteCreation();
    const retailFloridaAgentInfo = determineRetailAgentForFlorida();

    const { agentFirstName, agentLastName, agentEmail, agentPhone } = agentInfo;
    const {
      retailAgentFirstName,
      retailAgentLastName,
      retailAgentLicenseNumber,
    } = retailFloridaAgentInfo;

    const payload = {
      quoteIds: quotes.map((q) => q.id),
      accountId: data.account.id,
      agencyId: getAgencyId(),
      agentFirstName,
      agentLastName,
      agentEmail,
      agentPhone,
      retailAgentFirstName,
      retailAgentLastName,
      retailAgentLicenseNumber,
      teamIds: determineTeamIdOfAgent(),
    };

    const successMessage = 'Quotes successfully sent';
    const errorMessage = 'Failed to send quotes';

    if (_.isFunction(data.handleBulkQuotes)) {
      data.handleBulkQuotes(
        payload,
        successMessage,
        errorMessage,
        setSending,
        close
      );
    }

    // eslint-disable-next-line
  }, [quotes]);

  React.useEffect(() => {
    const agencyId = getAgencyId();
    setLoading(true);

    getTempQuotesByAccount({ accountId: data.account.id, agencyId })
      .then((resp) => {
        setQuotes(resp.data.content);
        setLoading(false);
        data.dispatch(setPrime250Ui({ cartTotal: resp.data.content.length }));
      })
      .catch(() => {
        enqueueSnackbar('Failed to get cart', { variant: 'error' });
        setLoading(false);
      });

    // eslint-disable-next-line
  }, []);

  return (
    <DialogContent>
      <Backdrop open={loading} style={{ zIndex: 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {quotes.length ? (
        <>
          <CartList data={quotes} handleRemoveQuote={handleRemoveQuote} />
          <Box className="flex--spaced">
            <Box>
              {quotes.length >= 5 ? (
                <div>
                  You have reached 5 request limit, please delete one to add
                  another variation!
                </div>
              ) : null}
              <div className="blue-text">
                {quotes.length}
                {quotes.length === 1 ? '\tRequest' : '\tRequests'}
              </div>
            </Box>
            <Button
              fixed
              stylename="primary"
              disabled={sending}
              onClick={handleQuoteNow}
              size="lg"
            >
              Request Above Quotes
            </Button>
          </Box>
        </>
      ) : (
        <Box
          className="primer-text contrast-text"
          height="12rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          No quotes have been added to the cart yet.
        </Box>
      )}
    </DialogContent>
  );
});
