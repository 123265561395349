import { Controller, useFormContext } from 'react-hook-form-4';
import hoistNonReactStatics from 'hoist-non-react-statics';

/**
 * @name withHooksController
 * @description Wraps a given input with a Controller from react-hook-form
 * @deprecated begining to deprecate this definition, see the new version under the same name
 */
export function withFormController(Component) {
  const C = ({
    control,
    defaultValue,
    name,
    onBlur,
    onChange,
    rules,
    valueName,
    placeholder,
    ...props
  }) => {
    const formContext = useFormContext();
    let error = props.error || '';

    if (!name) {
      throw new Error(
        '"name" attribute/prop is required when using withFormController.'
      );
    }

    if (!error && formContext && formContext.errors) {
      const errorObj = formContext.errors[name] || {};
      error = errorObj.isManual ? errorObj.types.message : errorObj.message;
    }

    return (
      <Controller
        as={<Component {...props} error={error} name={name} />}
        control={control}
        defaultValue={defaultValue}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        rules={rules}
        valueName={valueName}
        placeholder={placeholder}
      />
    );
  };
  C.displayName = `withFormController(${
    Component.displayName || Component.name
  })`;
  C.wrappedComponent = Component;
  return hoistNonReactStatics(C, Component);
}
