import { useTheme } from '@mui/styles';
import type { DefaultTheme } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { BaseFlag } from './FlagCell';
import { withShowable } from '../../console/_global/lib/withShowable';

interface ClaimFlagProps {
  claimStatus: string;
  claimNumber: string;
}

export const ClaimsFlag = withShowable(
  ({ claimStatus, claimNumber }: ClaimFlagProps) => {
    const theme = useTheme<DefaultTheme>();
    const tagColor =
      claimStatus !== 'OPEN'
        ? theme.palette.flags.closedClaim
        : theme.palette.flags.openClaim;

    return (
      <BaseFlag
        color={tagColor}
        tooltip={`${claimStatus} - Claim #${claimNumber}`}
      >
        <FontAwesomeIcon icon={regular('circle-exclamation')} />
      </BaseFlag>
    );
  }
);
