import React from 'react';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
// helpers
import Moment from 'moment';
import { Button, Grid as MuiGrid } from '@mui/material';
import { withStyles } from '@mui/styles';

// actions
import { withShowable } from '../../_global/lib/withShowable';
import { setUploadedDocs } from './notes.reducer';
import {
  FORMAT_DATE_AND_TIME,
  toUniversalUtcDate,
} from '../../../utils/date.utils';

const EButton = withShowable(Button);

const styles = ({ config }) => {
  return {
    listContainer: {
      fontWeight: config.weights.bold,
      fontSize: config.textSizes.petite,
      color: config.colors.darkRed,

      '& .MuiButton-label': {
        fontStyle: 'italic',
        fontWeight: config.weights.bold,
        fontSize: config.textSizes.petite,
        textTransform: 'capitalize',
        lineHeight: 1.3,
      },
    },
    deleteConfirm: {
      padding: '0 0 0 0.833rem !important',
      color: config.colors.darkRed,
      fontWeight: config.weights.bold,
    },
    detailsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '0.1rem',
      fontStyle: 'italic',
    },
    flexBox: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    note: {
      fontSize: '1rem',
      textAlign: 'left',
      fontWeight: 'normal',
    },
    noteText: {
      fontStyle: 'italic',
      fontWeight: 'normal',
      alignSelf: 'center',
    },
    greyText: {
      color: config.colors.greyWhite,
      alignSelf: 'center',
    },
  };
};

export const NotesBodyFooter = compose(withStyles(styles))(
  ({
    classes,
    data,
    readOnly,
    delNote,
    setDelete,
    noteId,
    setId,
    setEdit,
    setEditing,
    ...props
  }) => {
    const dispatch = useDispatch();

    const confirmDelete = (id) => {
      setId(id);
      setDelete(true);
    };

    const deleteNotes = (id, confirmation) => {
      setDelete(false);
      if (confirmation) {
        if (typeof props.onDelete === 'function') {
          props.onDelete(id);
        }
      }
    };

    const handleCancel = () => {
      setEditing(false);
      setEdit(false);
      dispatch(setUploadedDocs({ attachments: [] }));
    };

    const itsDel = delNote && data.id === noteId;

    return (
      <div className={classes.listContainer}>
        {itsDel ? (
          <div className={classes.flexBox}>
            <span className={classes.noteText}>
              Are you sure you want to delete this note?
            </span>
            <Button
              className={classes.deleteConfirm}
              onClick={() => deleteNotes(data.id, true)}
              disableRipple
            >
              YES
            </Button>
            <Button
              className={classes.deleteConfirm}
              onClick={() => deleteNotes(data.id, false)}
              disableRipple
            >
              NO
            </Button>
          </div>
        ) : (
          <MuiGrid container className={classes.detailsContainer}>
            <MuiGrid item lg={5} style={{ textAlign: 'left' }}>
              <span className={classes.greyText}>
                Last Modified by: {data.modifiedBy || '-'}
              </span>
            </MuiGrid>
            <MuiGrid item md={4} style={{ textAlign: 'left' }}>
              <span className={classes.greyText}>
                {' '}
                {toUniversalUtcDate(data.modified, {
                  format: FORMAT_DATE_AND_TIME,
                })}
              </span>
            </MuiGrid>
            <MuiGrid item md={3} style={{ textAlign: 'end' }}>
              <EButton
                style={{ color: '#1f1f29' }}
                show={!readOnly}
                disableRipple
                onClick={() => confirmDelete(data.id)}
              >
                Delete
              </EButton>

              <EButton
                disableRipple
                style={{ color: '#2180e2', padding: 0 }}
                onClick={handleCancel}
                show={readOnly && data.id === noteId}
              >
                Cancel
              </EButton>
            </MuiGrid>
          </MuiGrid>
        )}
      </div>
    );
  }
);
