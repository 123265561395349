import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@mui/styles';

import Numeral from 'numeral';
import { assessmentsMap, getYesNoLabel } from '../utils/appUtils';
import { assessmentsMap250, frequencyMetaMap } from '../utils/P250/appUtils250';
import TiledChart from './graphs/TiledChart';
import { orgMap } from '../accounts/accountUtils';
import SimpleTooltip from './SimpleTooltip';
import DescTooltip from './DescTooltip';
import { InputLabelBase } from './inputs/InputLabelBase';
import { ProductTypes } from '../types';
import Showable from './Showable';
import { usePersona } from './hooks/usePersona';
import { formatAsPhone } from '../utils';
import { TruncatedTextWithTooltip } from '../console/dashboard/agency/agency-dashboard.utils';
import { useCowbellTranslations } from '../i18n/translations';
import { NO_DECIMAL_FORMAT, deriveCurrencyFormat } from '../i18n/currencies';
import { formatPhoneAsString } from '../i18n/forms/LanguageForm';
import { formatAddressByRegion } from '../i18n/utils';

const styles = ({ config, palette }) => {
  return {
    wrapper: {
      height: '100%',
      minHeight: 540,
      width: '100%',
      fontSize: '14px',

      '& p': {
        textAlign: 'center',
      },
    },
    field: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '.75rem',
    },
    label: {
      padding: '0',
      color: 'inherit',
      fontWeight: 'bold',
    },
    data: {
      '& li:first-child label': {
        padding: '0',
      },
      '& li': {
        paddingBottom: '.5rem',
      },
    },
    title: {
      fontFamily: 'Titillium Web',
      fontSize: config.textSizes.normal,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: 'inherit',
      marginBottom: '-5px',
      marginTop: '1.25rem',
      display: 'flex',
      justifyContent: 'space-between',
    },

    profileMeter: {
      width: 70,
      height: 23,
    },
    statsTile: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },

    wideLabel: {
      flexDirection: 'row',
      width: '80%',
      fontWeight: 'bold',
      color: palette.text.title,
    },

    shortValue: {
      width: '20%',
      fontFamily: 'Titillium Web',
      textAlign: 'right',
    },

    dataWrapper: {
      display: 'flex',
      alignSelf: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
      paddingTop: 10,
      paddingBottom: 10,
    },
  };
};

const InboxFirmographic = ({ classes, data }) => {
  const persona = usePersona();
  const { t, translationKeys } = useCowbellTranslations();

  if (!data) {
    return (
      <div className={classes.wrapper}>
        <p>Firmographic Data Missing!</p>
      </div>
    );
  }

  const shouldLink = persona.isCowbell || persona.isAgency;

  const { profileScore } = data;

  const orgData = orgMap.map((item) => {
    let value = data[item.id] || '';
    item.tooltip = undefined;

    if (item.id === 'domains' && data[item.id]) {
      const domains = data[item.id] || ['-'];
      value = domains.map((v) => `${v} `);
    } else if (item.id === 'isFranchise') {
      if (data.isFranchise === true) {
        value = 'Yes';
      } else if (data.isFranchise === false) {
        value = 'No';
      } else {
        value = null;
      }
    } else if (value.length > 33) {
      item.tooltip = value;
      value = `${value.substr(0, 30)} ...`;
    }

    item.value = value || '-';
    return item;
  });

  const finalPhoneNumber = formatPhoneAsString(data.language, data.phoneNumber);
  const contact = {
    Address: formatAddressByRegion(data.language, data),
    'Business Phone Number': finalPhoneNumber,
  };

  let mappings = {};

  if (data.product === ProductTypes.p100) {
    mappings = assessmentsMap;
  } else if (data.product === ProductTypes.p250) {
    mappings = assessmentsMap250;
  }

  return (
    <>
      <div className={classes.field}>
        <InputLabelBase className={classes.label}>Name</InputLabelBase>
        {shouldLink && (
          <Link
            className="blue-text"
            style={{ textDecoration: 'none' }}
            to={`${persona.basePath}/accounts?search=${data.accountId}`}
          >
            <TruncatedTextWithTooltip text={data.name} length={40} />
          </Link>
        )}
        {!shouldLink && (
          <TruncatedTextWithTooltip text={data.name} length={40} />
        )}
      </div>
      <div className={classes.field}>
        <InputLabelBase className={classes.label}>DBA</InputLabelBase>
        <span>{data.dbaOrTradestyle || '-'}</span>
      </div>
      <div className={classes.field}>
        <InputLabelBase className={classes.label}>Industry Code</InputLabelBase>
        {shouldLink && (
          <Link
            className="blue-text"
            style={{ textDecoration: 'none' }}
            to={`${persona.basePath}/manage/industry?search=${data.naicsCode}`}
          >
            {data.industryCode || '-'}
          </Link>
        )}
        {(!shouldLink && data.industryCode) || ''}
      </div>
      <div className={classes.field}>
        <InputLabelBase className={classes.label}>
          Projected {t(translationKeys.revenue)}
        </InputLabelBase>
        <span>
          {deriveCurrencyFormat(data.language, data.revenue, NO_DECIMAL_FORMAT)}
        </span>
      </div>

      <h4 className="tertia-text flex--spaced">
        <u>{t(translationKeys.organization).toUpperCase()} DETAILS</u>
        <div className={classes.profileMeter}>
          <TiledChart
            key={profileScore}
            style={{ height: 10 }}
            value={profileScore}
          />
        </div>
      </h4>

      <ul className={`${classes.data} list--unstyled`}>
        {Object.keys(orgData).map((factor, index) => {
          return (
            <li key={index} className="flex--spaced">
              <strong>
                <InputLabelBase className={classes.label}>
                  {orgData[factor].label}
                </InputLabelBase>
              </strong>
              <span className={classes.margin1}>
                {orgData[factor].tooltip ? (
                  <SimpleTooltip title={orgData[factor].tooltip}>
                    {orgData[factor].value}
                  </SimpleTooltip>
                ) : (
                  orgData[factor].value
                )}
              </span>
            </li>
          );
        })}
      </ul>

      <h4 className="tertia-text flex--spaced">
        <u>{t(translationKeys.organization).toUpperCase()} INFORMATION</u>
      </h4>

      <ul className={`${classes.data} list--unstyled`}>
        {Object.keys(contact).map((factor, index) => {
          return (
            <li key={index} className="flex--spaced">
              <strong>
                <InputLabelBase className={classes.label}>
                  {factor}
                </InputLabelBase>
              </strong>
              <span>{contact[factor]}</span>
            </li>
          );
        })}
      </ul>

      <Showable
        show={[ProductTypes.p100, ProductTypes.p250].includes(data.product)}
      >
        <h4 className="tertia-text flex--spaced" style={{ marginBottom: 0 }}>
          <u>
            SECURITY ASSESSMENT
            <span>
              <DescTooltip
                tooltip={Object.keys(mappings).map((key, index) => {
                  const { label, options } = mappings[key];
                  let value = '';

                  switch (key) {
                    case 'claimHistory':
                      value = options[data[key]].label;
                      break;
                    case 'pastCyberIncident':
                      value = data.p250Details[key] ? 'Yes' : 'No';
                      break;
                    case 'backupFrequency':
                      value = frequencyMetaMap[data.p250Details[key]];
                      break;
                    case 'patchingFrequency':
                      value = frequencyMetaMap[data.p250Details[key]];
                      break;
                    default:
                      value = data[key] ? 'Yes' : 'No';
                  }

                  return (
                    <div key={index} className={classes.dataWrapper}>
                      <div className={classes.statsTile}>
                        <div className={classes.wideLabel}>{label}</div>
                        <div className={classes.shortValue} title={data[key]}>
                          {value}
                        </div>
                      </div>
                    </div>
                  );
                })}
              />
            </span>
          </u>
        </h4>
      </Showable>
    </>
  );
};

export default withStyles(styles)(InboxFirmographic);

export const SecurityQuestionList = ({ questions, questionAnswers }) => {
  const classes = tooltipStyles();
  return questions.map((question, index) => {
    return (
      <Showable
        key={index}
        show={questionAnswers[question.name]?.answer != null}
      >
        <div style={{ maxHeight: 350, overflowY: 'auto' }}>
          <div className={classes.dataWrapper}>
            <div className={classes.statsTile}>
              <div className="contrast-text">{question.label}</div>
              <div className="contrast-text">
                {formatQuestionAnswer(questionAnswers[question.name]?.answer)}
              </div>
            </div>
          </div>
          <Showable show={Boolean(question.dynamic)}>
            {question?.dynamicQuestions?.map((dynamicQuestion, idx) => {
              return (
                <Showable
                  key={idx}
                  show={questionAnswers[dynamicQuestion]?.answer != null}
                >
                  <div key={index} className={classes.dataWrapper}>
                    <div className={classes.statsTile}>
                      <div className="contrast-text">
                        {dynamicQuestion.label}
                      </div>
                      <div className={classes.shortValue}>
                        {formatQuestionAnswer(
                          questionAnswers[dynamicQuestion.name]?.answer
                        )}
                      </div>
                    </div>
                  </div>
                </Showable>
              );
            })}
          </Showable>
        </div>
      </Showable>
    );
  });
};

const formatQuestionAnswer = (answer) => {
  if (typeof answer === 'boolean') {
    return getYesNoLabel(answer).toUpperCase();
  }
  if (Array.isArray(answer)) {
    return answer.join(', ').split('_').join(' ');
  }
  if (typeof answer === 'string') {
    return answer.split('_').join(' ');
  }
  return answer;
};

const tooltipStyles = makeStyles(({ config, palette }) => {
  return {
    wrapper: {
      height: '100%',
      minHeight: 540,
      width: '100%',
      fontSize: '14px',

      '& p': {
        textAlign: 'center',
      },
    },
    field: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '.75rem',
    },
    label: {
      padding: '0',
      color: 'inherit',
      fontWeight: 'bold',
    },
    data: {
      '& li:first-child label': {
        padding: '0',
      },
      '& li': {
        paddingBottom: '.5rem',
      },
    },
    title: {
      fontFamily: 'Titillium Web',
      fontSize: config.textSizes.normal,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: 'inherit',
      marginBottom: '-5px',
      marginTop: '1.25rem',
      display: 'flex',
      justifyContent: 'space-between',
    },

    profileMeter: {
      width: 70,
      height: 23,
    },
    statsTile: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },

    wideLabel: {
      flexDirection: 'row',
      width: '80%',
      fontWeight: 'bold',
      color: palette.text.title,
    },

    shortValue: {
      width: '20%',
      fontFamily: 'Titillium Web',
      textAlign: 'right',
    },

    dataWrapper: {
      display: 'flex',
      alignSelf: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
      paddingTop: 10,
      paddingBottom: 10,
    },
  };
});
