// mui
import { DialogActions, DialogContent, Grid as MuiGrid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ToggleButton } from '@mui/lab';
import _ from 'lodash';

import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import * as Yup from 'yup';
import { RegisteredToggleButtonGroup } from '../../../components/Buttons';
import { InputLabelBase } from '../../../components/inputs/InputLabelBase';
import { SimpleSelect } from '../../../components/inputs/selects/SimpleSelect';
import { Modal } from '../../../components/modals/v2/helpers/v2.modal.helpers';
import { MultiSelect } from '../../../components/Selects';
// react
import { delayedEvent } from '../../../utils/eventUtils';
import { UsStatesFull } from '../../../utils/USState';
import CbButton from '../../../components/Buttons/CbButton';
// components
import { withFormController } from '../../../components/hocs/withFormController';
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
// actions
import {
  createCarrierRiskProfile,
  fetchCarriers,
  updateCarrierRiskProfile,
} from '../carrier.service';
import { utcForAPI } from '../../../utils/date.utils';
import {
  getIsUsPlatform,
  manageAPIError,
  useGetPlatformRegion,
} from '../../../utils';
import useGetAvailableProducts from '../../../components/hooks/useGetAvailableProducts';
import { Show } from '../../../components/Show';

const Select = withFormController(SimpleSelect);
const TextField = withFormController(TextFieldBase);
const statesFunc = (supportedStates) => {
  return supportedStates
    ? supportedStates.map((item) => ({
        label: item,
        value: item,
      }))
    : [];
};

const LabelProps = {
  required: true,
  TypographyProps: {
    variant: 'body2',
    gutterBottom: true,
  },
};

const deriveSchema = (isUSPlatform) =>
  Yup.object().shape({
    carrier: Yup.string()
      .required()
      .label('Carrier Name')
      .typeError('${label} is a required field'),

    isSurplus: Yup.bool().required().label('Is Surplus?'),
    maxLimit: Yup.string()
      .required()
      .label('Max Limit')
      .matches(/^[0-9\s]+$/, 'Only numbers are allowed for this field'),
    maxRevenue: Yup.string()
      .required()
      .label('Max Revenue')
      .matches(/^[0-9\s]+$/, 'Only numbers are allowed for this field'),
    capacity: Yup.string()
      .required()
      .label('Capacity')
      .matches(/^[0-9\s]+$/, 'Only numbers are allowed for this field'),
    mgaCommission: Yup.number().required().label('MGA Commission'),
    frontingFee: Yup.number().required().label('Fronting Fee'),
    claimsFee: Yup.number().required().label('Claims Fee'),
    startDate: Yup.string()
      .required()
      .label('Start Date')
      .typeError('${label} is a required field'),
    endDate: Yup.string()
      .required()
      .label('End Date')
      .typeError('${label} is a required field'),
    product: Yup.string()
      .required()
      .label('Product type')
      .typeError('${label} is a required field'),
    ...(isUSPlatform
      ? {
          USStates: Yup.array()
            .of(Yup.object())
            .min(1)
            .required()
            .label('States'),
        }
      : {}),
  });

export const CarrierRiskProfileCreateEdit = ({ data, close }) => {
  const edit = !_.isEmpty(data);
  const { enqueueSnackbar } = useSnackbar();
  const [carriersList, setCarriersList] = React.useState([]);
  const productSelectOptions = useGetAvailableProducts();
  const region = useGetPlatformRegion();
  const isUSPlatform = getIsUsPlatform(region);

  const defaultValues = {
    carrier: '',
    product: data?.productType ?? '',
    isSurplus: _.get(data, 'isSurplus', false),
    maxLimit: _.get(data, 'maxLimit', ''),
    maxRevenue: _.get(data, 'maxRevenue', ''),
    capacity: _.get(data, 'capacity', ''),
    mgaCommission: _.get(data, 'mgaCommission', ''),
    frontingFee: _.get(data, 'frontingFee', ''),
    claimsFee: _.get(data, 'claimsFee', ''),
    startDate:
      data && data.startDate
        ? moment.utc(data.startDate).format('YYYY-MM-DD')
        : null,
    endDate:
      data && data.endDate
        ? moment.utc(data.endDate).format('YYYY-MM-DD')
        : null,
    USStates: statesFunc(data && data.supportedStates),
  };
  const classes = styles();
  const toggleButtonProps = {
    className: classes.toggleButton,
    classes: {
      disabled: classes.toggleButtonDisabled,
    },
  };
  const [carriersAPIData, setCarriersAPIData] = React.useState();

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    validationSchema: deriveSchema(isUSPlatform),
  });

  const { register, errors, setError, setValue, getValues, formState } =
    methods;
  const values = getValues();
  const { dirty, isSubmitting } = formState;

  React.useEffect(() => {
    const q = {
      size: 200,
      order: 'asc',
      orderBy: 'id',
    };
    fetchCarriers(q)
      .then((carriersResp) => {
        setCarriersAPIData(_.get(carriersResp, 'data.content', []));
        if (_.get(data, 'carrierId')) {
          setValue(
            'carrier',
            carriersResp.data.content.find(
              (element) => element.id === _.get(data, 'carrierId')
            ).name
          );
        }
        // const selectedCarrier = carriersAPIData.find(element => element.name === formData.carrier);
        setCarriersList([
          {
            label: 'Select a carrier...',
            value: null,
          },
          ...carriersResp.data.content.map((item) => ({
            label: item.name,
            value: item.name,
          })),
        ]);
      })
      .catch((error) => {
        enqueueSnackbar(manageAPIError(error, 'Unable to load data'), {
          variant: 'error',
        });
      });
    // eslint-disable-next-line
  }, []);

  const onSubmit = (formData) => {
    const selectedCarrier = carriersAPIData.find(
      (element) => element.name === formData.carrier
    );
    const payload = {
      carrierPrefix: selectedCarrier.prefix,
      carrierId: selectedCarrier.id,
      isSurplus: formData.isSurplus,
      maxRevenue: formData.maxRevenue,
      maxLimit: formData.maxLimit,
      capacity: formData.capacity,
      mgaCommission: formData.mgaCommission,
      frontingFee: formData.frontingFee,
      claimsFee: formData.claimsFee,
      supportedStates: formData.USStates.map((state) => state.value),
      endDate: utcForAPI(formData.endDate),
      startDate: utcForAPI(formData.startDate),
      productType: formData.product,
    };

    if (!edit) {
      return createCarrierRiskProfile({}, payload)
        .then(() => {
          enqueueSnackbar('Succesfully added a risk profile!', {
            variant: 'success',
          });
          Modal.hide('CarrierRiskProfileCreateEdit');
          delayedEvent('table-refetch', 1000, 'carrierRiskProfiles');
        })
        .catch((error) => {
          enqueueSnackbar(
            manageAPIError(error, 'Unable to add a risk profile'),
            { variant: 'error' }
          );
        });
    }
    return updateCarrierRiskProfile({ id: data.id }, payload)
      .then(() => {
        enqueueSnackbar('Succesfully updated the risk profile!', {
          variant: 'success',
        });
        Modal.hide('CarrierRiskProfileCreateEdit');
        delayedEvent('table-refetch', 1000, 'carrierRiskProfiles');
      })
      .catch(() => {
        enqueueSnackbar('Unable to update the risk profile', {
          variant: 'error',
        });
      });
  };

  const selectStatesHandler = React.useCallback((name, selectedOptions) => {
    setError('USStates', null);
    setValue('USStates', selectedOptions);
    // eslint-disable-next-line
  }, []);

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} data-qa="addCarrier">
        <DialogContent style={{ padding: '2rem 5rem' }}>
          <MuiGrid container spacing={2}>
            <MuiGrid
              item
              md={isUSPlatform ? 6 : 12}
              sx={{ marginTop: '-0.5rem' }}
            >
              <Select
                InputLabelProps={{
                  required: true,
                }}
                name="carrier"
                label="Select Carrier Name"
                options={carriersList}
                value={values.carrier}
                renderValue={renderValue}
                displayEmpty
              />
            </MuiGrid>
            <Show when={isUSPlatform}>
              <MuiGrid item md={6} style={{ paddingTop: 22 }}>
                <MultiSelect
                  name="USStates"
                  label="States"
                  ref={register('USStates')}
                  buttonPlaceholder="Select state"
                  isMulti
                  options={UsStatesFull}
                  onChange={selectStatesHandler}
                  InputLabelProps={{ className: classes.selectLabel }}
                  values={values.USStates}
                  defaultValue={null}
                  required
                  error={_.get(errors, 'USStates.message')}
                />
              </MuiGrid>
            </Show>
          </MuiGrid>
          <MuiGrid container spacing={2} sx={{ paddingTop: '0.5rem' }}>
            <MuiGrid item md={6}>
              <InputLabelBase {...LabelProps} style={{ padding: '0 0 0 12px' }}>
                Is Surplus?
              </InputLabelBase>
              <RegisteredToggleButtonGroup
                name="isSurplus"
                exclusive
                style={{ display: 'flex' }}
              >
                <ToggleButton value {...toggleButtonProps}>
                  <div>Yes</div>
                </ToggleButton>
                <ToggleButton value={false} {...toggleButtonProps}>
                  <div>No</div>
                </ToggleButton>
              </RegisteredToggleButtonGroup>
            </MuiGrid>
            <MuiGrid item md={6}>
              <Select
                label="Select a Product"
                InputLabelProps={{
                  style: { paddingTop: 0 },
                  required: true,
                }}
                name="product"
                options={productSelectOptions}
                value={values.product}
                renderValue={renderValue}
                displayEmpty
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={2}>
            <MuiGrid item md={6}>
              <TextField name="maxLimit" label="Max Limit" required fullWidth />
            </MuiGrid>
            <MuiGrid item md={6}>
              <TextField
                name="maxRevenue"
                label="Max Revenue"
                required
                fullWidth
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={2}>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField name="capacity" label="Capacity" required fullWidth />
            </MuiGrid>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="mgaCommission"
                label="MGA Commission (%)"
                required
                fullWidth
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={2}>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="frontingFee"
                label="Fronting Fee (%)"
                required
                fullWidth
              />
            </MuiGrid>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="claimsFee"
                label="Claims Fee (%)"
                required
                fullWidth
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={2}>
            <MuiGrid item md={6}>
              <TextField
                id={values.startDate}
                name="startDate"
                label="Start Date"
                required
                type="date"
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <TextField
                id={values.endDate}
                name="endDate"
                label="End Date"
                required
                type="date"
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText={edit ? 'Update Risk Profile' : 'Add Risk Profile'}
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

export const CarrierRiskProfileCreateEditConfig = {
  CarrierRiskProfileCreateEdit: {
    component: CarrierRiskProfileCreateEdit,
    config: {
      fullWidth: true,
      maxWidth: 'md',
    },
  },
};

function renderValue(value) {
  return value || 'Please Choose...';
}

const styles = makeStyles(({ palette }) => ({
  toggleButton: {
    flexGrow: 1,
    padding: '0.5rem 0',
    marginBottom: '1rem',
  },
  toggleButtonDisabled: {
    color: `${palette.primary.contrastText} !important`,
  },
}));
