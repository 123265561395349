const CANCELLATION_REASONS = [
  {
    value: 'Claims',
    label: 'Claims',
    description: 'Had past claims',
  },
  {
    value: 'fraud',
    label: 'Fraud',
    description: 'Fraud exists',
  },
  {
    value: 'insured_requested',
    label: 'Insured Requested',
    description: 'The insured has requested this cancellation.',
  },
  {
    value: 'organizational_change',
    label: 'Organizational Change',
    description: 'There was an organizational change',
  },
  {
    value: 'non_payment',
    label: 'Non Payment',
    description: 'No Payment received',
  },
  {
    value: 'premium_financing',
    label: 'Premium Financing',
    description: 'Premium Financing',
  },
];

export default CANCELLATION_REASONS;

export const P100_PRO_CANCELLATION_REASONS = [
  {
    value: 'insured_requested',
    label: 'Insured Requested',
    description: 'The insured has requested this cancellation.',
  },
];
