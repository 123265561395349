import React from 'react';

// components
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import CBButton from '../../Buttons/CbButton';
import { deleteQuote } from '../../../inbox/QuotesService';
import { delayedEvent } from '../../../utils/eventUtils';
import { usePersona } from '../../hooks/usePersona';

export const UWDeleteQuoteV2 = ({ data, ...props }) => {
  const persona = usePersona();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSubmit = () => {
    setIsSubmitting(true);
    const { quoteId, enqueueSnackbar, from, history } = data;
    deleteQuote(quoteId)
      .then(() => {
        enqueueSnackbar('Quote deleted successfully!', { variant: 'success' });
        if (persona.isCowbell) {
          setTimeout(() => {
            history.push(`${persona.basePath}/${from || 'inbox'}`);
          }, 2000);
        }

        if (persona.isDigital) {
          setTimeout(() => {
            history.push(`${persona.basePath}/${from}`);
          }, 2000);
        }
        delayedEvent('table-refetch', 500, 'uwInbox');
        delayedEvent('table-refetch', 500, 'adminQuotes');
        setIsSubmitting(false);
        props.close();
      })
      .catch(() => {
        enqueueSnackbar('Failed to delete quote!', { variant: 'error' });
        setIsSubmitting(false);
        props.close();
      });
  };

  const handleCancel = () => {
    props.close();
  };

  return (
    <>
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center' }}>
          Are you sure you want to delete this quote?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CBButton action={handleCancel} styleName="cancel">
          Cancel
        </CBButton>
        <CBButton
          type="button"
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={isSubmitting}
          styleName="ctaButton"
          buttonText="Delete"
        />
      </DialogActions>
    </>
  );
};

export const UWDeleteQuoteV2Config = {
  UWDeleteQuoteV2: {
    component: UWDeleteQuoteV2,
    config: {
      fullWidth: true,
      title: 'Delete Quote',
    },
  },
};
