import React from 'react';
import { SvgIcon } from '@mui/material';

export const AdmittedIcon = ({ fill = '#fff', ...props }) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g>
      <path
        fill={fill}
        d="M-7.945-14.742h1.781l.655-3.427h2.772l.622 3.427h1.8L-3.224-28.35h-1.831zm2.688-4.788l1.126-6.132 1.142 6.132z"
        transform="translate(-6573 165) translate(6589.131 -131.358)"
      />
      <g fill="none">
        <path
          d="M12 0A12 12 0 1 1 0 12 12 12 0 0 1 12 0z"
          transform="translate(-6573 165) translate(6573 -165)"
        />
        <path
          fill="#2180e2"
          d="M12 1.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z"
          transform="translate(-6573 165) translate(6573 -165)"
        />
      </g>
    </g>
  </SvgIcon>
);
