import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import _ from 'lodash';
import type { _Account } from '../onboarding-screens/StartNewQuote';
import { searchQuotesV2 } from '../../../../api';
import {
  INDICATION_STATUS,
  READY_STATUS,
  REFERRED_STATUS,
} from '../../../_statics/quote.statics';
import { searchPoliciesV2 } from '../../../../policies/PolicyService';
import { BOUND_STATUS } from '../../../_statics/policy.statics';

export const useClientQueries = (account: _Account | undefined) => {
  return useQuery({
    queryKey: ['client-data', account?.id],
    queryFn: () => fetchClientQueries(account),
    select: formatClientQueriesResponse,
    enabled: !!account,
  });
};

const fetchClientQueries = (account: _Account | undefined) => {
  const promises = [
    searchQuotesV2({
      params: {
        accountId: account?.id,
        search: account?.name,
        f: {
          agencyStatus: {
            in: [READY_STATUS, REFERRED_STATUS, INDICATION_STATUS],
          },
          isRenewal: {
            bo: 0,
          },
        },
      },
    }),
    searchQuotesV2({
      params: {
        accountId: account?.id,
        search: account?.name,
        f: {
          agencyStatus: {
            in: [READY_STATUS, REFERRED_STATUS, INDICATION_STATUS],
          },
          isRenewal: {
            bo: 1,
          },
        },
      },
    }),
    searchPoliciesV2({
      params: {
        accountId: account?.id,
        search: account?.name,
        f: {
          status: {
            in: [BOUND_STATUS],
          },
        },
      },
    }),
    searchPoliciesV2({
      params: {
        accountId: account?.id,
        search: account?.name,
        f: {
          status: {
            in: ['IN_FORCE'],
          },
          isOpenForRenewal: {
            bo: 1,
          },
          isEndorsement: {
            bo: 0,
          },
        },
      },
    }),
    searchPoliciesV2({
      params: {
        accountId: account?.id,
        search: account?.name,
        f: {
          status: {
            in: ['IN_FORCE'],
          },
          isOpenForRenewal: {
            bo: 0,
          },
          isEndorsement: {
            bo: 0,
          },
        },
      },
    }),
  ];

  return Promise.all(promises);
};

const formatClientQueriesResponse = (axiosResponse: AxiosResponse[]) => {
  const [
    {
      data: { content: activeQuotes = [], totalPages: activeQuotesTotalPages },
    },
    {
      data: {
        content: renewalQuotes = [],
        totalPages: renewalQuotesTotalPages,
      },
    },
    {
      data: {
        content: activeBinders = [],
        totalPages: activeBindersTotalPages,
      },
    },
    {
      data: {
        content: activePoliciesOpenForRenewal = [],
        totalPages: activePoliciesOpenForRenewalTotalPages,
      },
    },
    {
      data: {
        content: activePoliciesNotOpenForRenewal = [],
        totalPages: activePoliciesNotOpenForRenewalTotalPages,
      },
    },
  ] = axiosResponse;

  let areAllEntitiesEmpty = true;

  if (
    !_.isEmpty(activeQuotes) ||
    !_.isEmpty(renewalQuotes) ||
    !_.isEmpty(activeBinders) ||
    !_.isEmpty(activePoliciesOpenForRenewal) ||
    !_.isEmpty(activePoliciesNotOpenForRenewal)
  ) {
    areAllEntitiesEmpty = false;
  }

  return {
    activeQuotes: {
      rows: activeQuotes,
      totalPages: activeQuotesTotalPages,
    },
    renewalQuotes: {
      rows: renewalQuotes,
      totalPages: renewalQuotesTotalPages,
    },
    activeBinders: {
      rows: activeBinders,
      totalPages: activeBindersTotalPages,
    },
    activePoliciesOpenForRenewal: {
      rows: activePoliciesOpenForRenewal,
      totalPages: activePoliciesOpenForRenewalTotalPages,
    },
    activePoliciesNotOpenForRenewal: {
      rows: activePoliciesNotOpenForRenewal,
      totalPages: activePoliciesNotOpenForRenewalTotalPages,
    },
    areAllEntitiesEmpty,
  };
};

export type formatClientQueriesResponseReturnType = ReturnType<
  typeof formatClientQueriesResponse
>;
