import _ from 'lodash';
import React, { useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

export function withReorderColumns(Component) {
  const C = ({
    columns,
    context,
    onColumnReorder,
    onToggleColumnVisibility,
    ...props
  }) => {
    const [newColumns, setColumns] = useState(columns);

    function reorderColumn(order) {
      if (!order.length) {
        return;
      }

      const orderedColumns = order.map((item) => {
        const column = columns.find((col) => col.name === item.id);
        return {
          ...column,
          show: item.show,
        };
      });

      setColumns(orderedColumns);

      if (_.isFunction(onColumnReorder)) {
        onColumnReorder(orderedColumns);
      }
    }

    function toggleColumnVisibility({ target }) {
      const _columns = newColumns.map((col) => ({
        ...col,
        show: col.name === target.name ? target.checked : col.show,
      }));

      setColumns(_columns);

      if (_.isFunction(onToggleColumnVisibility)) {
        onToggleColumnVisibility(_columns);
      }
    }

    return (
      <Component
        {...props}
        columns={newColumns}
        context={{
          ...context,
          reorderColumn,
          toggleColumnVisibility,
        }}
      />
    );
  };

  C.displayName = `withReorderColumns(${
    Component.displayName || Component.name
  })`;
  C.wrappedComponent = Component;

  return hoistNonReactStatics(C, Component);
}
