import React, { useState } from 'react';
import { compose } from 'redux';
import { useForm, FormContext } from 'react-hook-form-4';
import { Grid, FormHelperText } from '@mui/material';

import * as Yup from 'yup';
import * as Ramda from 'ramda';

// components
import { withStyles } from '@mui/styles';
import { SimpleSelect } from '../inputs/selects/SimpleSelect';
import { withFormController } from '../hocs/withFormController';
import { TextFieldBase } from '../inputs/TextFieldBase';

import { UsStatesFull } from '../../utils/USState';
import { sendPolicyCertificateEmail } from '../../policies/PolicyService';

import { withShowable } from '../../console/_global/lib/withShowable';
import { globalSchema } from '../globalValidations';
import { withToggleModal } from '../hocs/withToggleModal';
import { manageAPIError } from '../../utils';

const ConnectedTextField = withFormController(TextFieldBase);
const Select = withFormController(SimpleSelect);

const MuiGrid = withShowable(Grid);
const TextField = withShowable(ConnectedTextField);

const schema = Yup.object().shape({
  address1: Yup.string().required('Please enter a Address '),
  organizationName: Yup.string().required('Please enter a Orginization.'),
  zip: globalSchema.zipCode,
  city: Yup.string().required('Please enter a City '),
  state: Yup.string().required('Please select a State'),
});

const styles = ({ config, palette }) => ({
  itemContainer: {
    width: '100%',
    position: 'relative',
    paddingBottom: 10,
  },
  headings: {
    marginTop: '27px',
    color: palette.primary.contrastText,
    fontWeight: config.weights.normal,
    fontSize: config.textSizes.primer,
  },
  nameContainer: {
    direction: 'row',
    justify: 'center',
    alignItems: 'center',
  },
  itemNameLine: {
    margin: 0,
    fontWeight: 'bold',
  },
  cowbellBlue: {
    color: palette.common.cowbellBlue,
  },
  spanContainer: {
    position: 'relative',
    right: '10px',
    top: '70px',
    fontSize: config.textSizes.normal,
    color: palette.text.text8,
  },
});

export const CertificateHolderForm = compose(
  withToggleModal,
  withStyles(styles),
  withShowable
)(({ classes, data, docType }) => {
  const [backendError, setBackendError] = useState('');
  const { handleSubmit, ...methods } = useForm({ validationSchema: schema });

  function onSubmit({ ...formData }) {
    const handleAPIError = Ramda.compose(setBackendError, manageAPIError);
    const payload = {
      ...formData,
      policyId: data.id,
      docType,
    };

    return sendPolicyCertificateEmail({ productType: data.product }, payload)
      .then(() => {
        this.props.toggleModal.direct('certificateEmailConfirm', data, {
          title: 'Generate Policy Certificate',
          footerButtons: [
            {
              buttonText: 'Okay',
              action: 'cancel',
              showLoading: true,
              showDisabled: true,
            },
          ],
        });
      })
      .catch(handleAPIError);
  }

  return (
    <FormContext {...methods}>
      <form id="certificateHolderForm" onSubmit={handleSubmit(onSubmit)}>
        <MuiGrid container>
          <MuiGrid container>
            <MuiGrid item md={12}>
              <TextField
                required
                label="Orginization Name"
                name="organizationName"
                fullwidth
              />
            </MuiGrid>
            <MuiGrid container spacing={5}>
              <MuiGrid item xs={5}>
                <TextField required label="Address" name="address1" />
              </MuiGrid>
              <MuiGrid item xs={3}>
                <TextField required name="city" label="City" />
              </MuiGrid>
              <MuiGrid
                item
                xs={2}
                style={{ marginTop: '-1.1rem', borderRadius: '1.5625rem' }}
              >
                <Select
                  label="State"
                  required
                  name="state"
                  options={UsStatesFull}
                />
              </MuiGrid>
              <MuiGrid item xs={2}>
                <TextField name="zip" label="Zip Code" required />
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>

        <FormHelperText className="api-text-error">
          {backendError}
        </FormHelperText>
      </form>
    </FormContext>
  );
});
