import * as Yup from 'yup';
// react
import React from 'react';

// mui
import { Grid as MuiGrid } from '@mui/material';
import { TextFieldBase } from '../../../../../../components/inputs/TextFieldBase';
import { withFormController } from '../../../../../../components/hocs/forms';
import LanguageCurrencyFieldBase from '../../../../../../components/inputs/LanguageCurrencyField';

const TextField = withFormController(TextFieldBase);
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);

const attachmentValidationKeys = (value) => {
  return ['Schedule of Underlying Insurance Endorsement', 'Drop-Down'].includes(
    value
  );
};

export const scheduleUnderlyingInsuranceValidation = {
  carrierName: Yup.string().when('endorsement', {
    is: 'Schedule of Underlying Insurance Endorsement',
    then: Yup.string().required().label('Carrer'),
  }),
  attachment: Yup.number().when('endorsement', {
    is: attachmentValidationKeys,
    then: Yup.number()
      .fromCurrency()
      .min(0, '${label} must be greater than zero')
      .label('Attachment Point')
      .required(),
  }),
};

export const ScheduleUnderlyingInsurance = () => {
  return (
    <>
      <MuiGrid container spacing={5}>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <TextField name="carrierName" label="Carrier" fullWidth required />
        </MuiGrid>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <TextField
            name="policyNumber"
            label="Policy Number"
            fullWidth
            required
          />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container spacing={5}>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <TextField name="limit" label="Limit" fullWidth required />
        </MuiGrid>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <LanguageCurrencyField
            name="attachment"
            label="Attachment Point"
            fullWidth
            required
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
