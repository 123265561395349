import type { DialogProps } from '@mui/material';
import { Dialog, DialogContent, IconButton } from '@mui/material';

import { Cancel } from '@mui/icons-material';
import CowbellBindQuoteWorkflow from '../../../workflows/cowbell-bind-quote/CowbellBindQuoteWorkflow';
import type { ProductType } from '../../../../types';

type CowbellBindQuoteModalProps = {
  data: {
    quoteId: string;
    productType: ProductType;
    subjectivityKey?: string;
  };
  close: () => void;
} & DialogProps;

export function CowbellBindQuoteModal({
  data,
  close,
  ...props
}: CowbellBindQuoteModalProps) {
  return (
    <Dialog {...props}>
      <DialogContent style={{ padding: '1rem 2rem' }}>
        <CloseButton onClick={close} />

        <CowbellBindQuoteWorkflow
          quoteId={data.quoteId}
          productType={data.productType}
          startStep={data.subjectivityKey}
        />
      </DialogContent>
    </Dialog>
  );
}

export const CowbellBindQuoteModalConfig = {
  CowbellBindQuoteModal: {
    component: CowbellBindQuoteModal,
    config: {
      title: 'Bind Quote',
      maxWidth: 'xl',
      fullWidth: true,
      override: true,
    },
  },
};

function CloseButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton
      size="small"
      style={{ position: 'absolute', top: '1rem', right: '1.833rem' }}
      onClick={onClick}
    >
      <Cancel style={{ fontSize: '1.7rem' }} />
    </IconButton>
  );
}
