import React from 'react';
import _ from 'lodash';
import { Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const AdditionalInsured = ({ additionalInsureds, isAIApplicable }) => {
  const classes = useStyles();

  const length = _.get(additionalInsureds, 'length', 0);
  if (length === 0 || isAIApplicable === 'None') {
    return null;
  }

  return (
    <>
      <div className={classes.heading}>Additional Insured</div>

      {additionalInsureds.map((insured, index) => {
        return (
          <div key={index}>
            <Grid container className={classes.back}>
              <Grid item md={5}>
                Additional Insured {index + 1}
              </Grid>
              <Grid item md={7} className={classes.text}>
                Endorsement Update
              </Grid>
            </Grid>
            <Grid
              container
              style={
                index + 1 === length
                  ? { marginBottom: 0 }
                  : { marginBottom: '1.75rem' }
              }
            >
              <Grid
                container
                direction="column"
                item
                md={4}
                style={{ textAlign: 'right' }}
              >
                <Grid item>Organization Name</Grid>
                <Grid item>Address</Grid>
                <Grid item>&nbsp;</Grid>
                <Grid item>Industry Code</Grid>
              </Grid>
              <Grid item md={1} style={{ padding: '0 1.5rem' }}>
                <Divider orientation="vertical" />
              </Grid>

              <Grid
                container
                direction="column"
                item
                md={7}
                style={{ textAlign: 'left' }}
              >
                <Grid item>{insured.businessName}</Grid>
                <Grid item>
                  {insured.address1}, {_.get(insured, 'address2', '')}
                </Grid>
                <Grid item>
                  {insured.city}, {insured.state} {insured.zipCode}
                </Grid>
                <Grid
                  item
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {insured.naicsCode}: {insured.naicsDescription}
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    heading: {
      textDecoration: 'underline',
      'text-underline-offset': '2px',
      textTransform: 'uppercase',
      textAlign: 'left',
      fontSize: '1.16rem',
      fontWeight: 600,
      lineHeight: 1.5,
      color: palette.primary.contrastText,
      paddingTop: '2rem',
      paddingBottom: '0.66rem',
    },
    back: {
      background: palette.warning.contrastText,
      color: palette.primary.contrastText,
      padding: '0.33rem 1rem',
      marginBottom: '0.33rem',
    },
    text: {
      textAlign: 'left',
      fontStyle: 'italic',
    },
  };
});
