import React from 'react';
import _ from 'lodash';
import { DialogActions, DialogContent } from '@mui/material';

import { useSnackbar } from 'notistack';
import {
  updateAgencyLocation,
  BulkActive,
} from '../../../../console/agencies/_services/locations.service';
import CbButton from '../../../Buttons/CbButton';

import { delayedEvent } from '../../../../utils/eventUtils';

export const InactiveLocation = ({ iconStyles, ...props }) => {
  const bulk = _.isArray(props.data);
  const { enqueueSnackbar } = useSnackbar();

  function handleSubmit() {
    if (bulk == false) {
      const payload = {
        id: props.data.id,
        agencyId: props.data.agencyId,
        agencyName: props.data.name,
        isLocationActive: false,
      };

      return updateAgencyLocation({}, payload)
        .then(() => {
          enqueueSnackbar(`Location updated successfully!`, {
            variant: 'success',
          });
          delayedEvent('table-refetch', 500, 'admin_locations');
          props.close();
        })
        .catch((error) => {
          const errorMessage = _.get(
            error,
            'response.data',
            'Server Error, please try again'
          );

          enqueueSnackbar(errorMessage, { variant: 'success' });
        });
    }

    BulkActive({ activation: false }, { locationIds: props.data })
      .then(() => {
        enqueueSnackbar(`Location updated successfully!`, {
          variant: 'success',
        });
        delayedEvent('table-refetch', 500, 'admin_locations');
        props.close();
      })
      .catch((error) => {
        const errorMessage = _.get(
          error,
          'response.data',
          'Server Error, please try again'
        );
        enqueueSnackbar(errorMessage, { variant: 'success' });
      });
  }

  return (
    <>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <h2
          style={{
            fontSize: '1.3rem',
            fontWeight: 'normal',
            fontFamily: 'Titillium Web',
          }}
        >
          Are you sure you want to make these location(s) ‘inActive’?
        </h2>
        <span style={{ fontSize: '1rem', fontStyle: 'italic' }}>
          Note: Users and accounts can be organized only by active locations
        </span>
      </DialogContent>
      <DialogActions>
        <CbButton
          styleName="cancel"
          action={() => {
            props.close();
          }}
          buttonText="Cancel"
        />
        <CbButton
          onClick={() => {
            handleSubmit();
          }}
          type="submit"
          styleName="ctaButton"
          buttonText="Submit"
        />
      </DialogActions>
    </>
  );
};

export const InactiveLocationConfig = {
  InactiveLocation: {
    component: InactiveLocation,
    config: {
      maxWidth: 'sm',
      title: 'Make Location(s) Inactive',
    },
  },
};
