import Moment from 'moment';
import { createAction, handleActions } from 'redux-actions';

// statics
import Coverages from '../console/_statics/250/coverages/coverages.statics.json';

// actions
export const SET_250_COVERAGE = 'SET_250_COVERAGE';

// selectors

// actions
export const set250Coverages = createAction(SET_250_COVERAGE);

// reducer
const initialState = {};

export const Coverages250Reducer = handleActions(
  {
    [SET_250_COVERAGE]: (state, { payload }) => {
      const coverages = Coverages.map((coverage) => ({
        ...coverage,
        name: payload[coverage._id].name,
        key: payload[coverage._id].key,
        tooltip: payload[coverage._id].tooltip,
      }));

      return {
        lastCache: Moment().add(15, 'minutes').unix() * 1000,
        coverages,
      };
    },
  },
  initialState
);
