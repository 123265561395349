import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm, FormContext } from 'react-hook-form-4';
import CbButton from '../../Buttons/CbButton';
import { TextAreaBase } from '../../inputs/TextAreaBase';
import { withFormController } from '../../hocs/withFormController';
import { sendRenewalQuoteDetailsEmail } from '../../../inbox/QuotesService';

const TextArea = withFormController(TextAreaBase);

const schema = Yup.object().shape({
  description: Yup.string().label('Description').required(),
});

export const RenewalWorkflowEmailAgency = ({ data, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, ...methods } = useForm({ validationSchema: schema });
  const { isSubmitted } = methods.formState;
  const { id: quoteId, product: productType } = data;

  const onSubmit = ({ description }) => {
    sendRenewalQuoteDetailsEmail(
      { quoteId },
      { quoteId, description, productType }
    )
      .then(() => {
        enqueueSnackbar('Email sent', { variant: 'success' });
        props.close();
        history.push('/customer/policies');
      })
      .catch(() => {
        enqueueSnackbar(
          'Something went wrong, please try again in a few minutes',
          { variant: 'error' }
        );
        props.close();
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={classes.container}>
          <h2 className={classes.header}>Binder# {data.quoteNumber} </h2>
          <Divider className={classes.divider} />
          <DialogContentText className={classes.subHeader}>
            Message to {data.agencyName}
          </DialogContentText>
          <Divider className={classes.divider} />
          <TextArea
            name="description"
            type="string"
            className={classes.textArea}
            placeholder="Please describe the desired changes to your policy"
            required
            minRows="6"
            maxRows="6"
          />
        </DialogContent>
        <DialogActions>
          <CbButton onClick={props.close} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton type="submit" styleName="ctaButton" loading={isSubmitted}>
            Send
          </CbButton>
        </DialogActions>
      </form>
    </FormContext>
  );
};

export const RenewalWorkflowEmailAgencyConfig = {
  RenewalWorkflowEmailAgency: {
    component: RenewalWorkflowEmailAgency,
    config: {
      title: 'Email Agent to Update Renewal Quote',
      fullWidth: true,
    },
  },
};

const useStyles = makeStyles(({ config }) => ({
  container: {
    position: 'relative',
    top: '2rem',
  },
  header: {
    fontSize: config.textSizes.canon,
    fontWeight: 600,
  },
  subHeader: {
    fontSize: config.textSizes.paragon,
    color: config.colors.white,
    fontWeight: 600,
  },
  textArea: {
    width: '100%',
    padding: '0.75rem',
    marginTop: '0.7rem',
    fontFamily: config.fonts.stackSans,
    fontSize: config.textSizes.tertia,
    '&::placeholder': {
      color: config.colors.white,
      fontStyle: 'italic',
    },
  },
  divider: {
    margin: '0.25rem 0 1.25rem 0',
  },
}));
