import type { SelectOption } from '../../../../types/components/common';
import type { AccountDto } from '../../../../types';
import { UsStatesObjects } from '../../../../utils/USState';
import type { USState } from '../../../../types/common/misc';

export function initialFormDataCreator(
  account?: Partial<AccountDto> & { linked?: boolean }
) {
  const hardLinkedAgency = account?.agencies?.find((agency) => agency.linked);
  const transformedHardLinkedAgency = hardLinkedAgency
    ? {
        label: hardLinkedAgency.agencyName!,
        value: hardLinkedAgency.agencyId!,
        meta: { ...hardLinkedAgency },
      }
    : null;

  const linkedTeam = hardLinkedAgency
    ? {
        label: hardLinkedAgency.teamNames![0],
        value: hardLinkedAgency.teamIds![0],
        meta: {},
      }
    : null;

  return {
    policyholderFirstName: '',
    policyholderLastName: '',
    policyholderEmail: '',
    policyholderPhone: '',
    agency:
      transformedHardLinkedAgency ??
      ({
        value: '',
        label: '',
        meta: {},
      } as SelectOption<any>),
    agent: {
      value: '',
      label: '',
      meta: {},
    } as SelectOption<any>,
    agentTeam:
      linkedTeam ??
      ({
        value: '',
        label: '',
        meta: {},
      } as SelectOption<any>),
    surplusCompliance: 'cowbell' as 'cowbell' | 'agency',
    agencySurplusComplianceName: '',
    surplusLinesBroker: {
      value: '',
      label: '',
      meta: {
        brokerFirstName: '',
        brokerLastName: '',
        brokerEmail: '',
        surplusLinesState: '',
        surplusLicenseNumber: '',
      },
    } as SelectOption<{
      brokerFirstName: string;
      brokerLastName: string;
      brokerEmail: string;
      surplusLinesState: USState | '';
      surplusLicenseNumber: string;
    }>,
    agencyMailingAddress1: '',
    agencyMailingAddress2: '',
    agencyMailingCity: '',
    agencyMailingState: {
      value: '',
      label: '',
    } as SelectOption<any>,
    agencyMailingZipCode: '',
    surplusLinesBrokerFirstName: '',
    surplusLinesBrokerLastName: '',
    surplusLinesBrokerEmail: '',
    surplusLinesState: {
      value: '',
      label: '',
    } as SelectOption<any>,
    surplusLinesNumber: '',
    policyEffectiveDate: '',
    policyEndDate: '',
    aggregateLimit: '',
    deductible: '',
    policyPremium: '',
    tria: '',
    underwritingFee: '',
    surplusLinesTax: '',
    surplusStampingFee: '',
    kentuckyTaxCode: '',
    kentuckyTax: '',
    totalPremium: '',
  };
}
