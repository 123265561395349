import * as Ramda from 'ramda';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
// helpers
import Moment from 'moment';
import { withStyles } from '@mui/styles';
import Numeral from 'numeral';
import {
  setAccountDetails,
  setAdminDetails,
  setAgentDetails,
  setCarrierDetails,
  setClusterDetails,
} from '../console/_tabs/tabs.reducer';
// components
import DescTooltip from './DescTooltip';
import { CoverageInfoMapping } from '../utility/CoverageInfoMapping';
import DetailsButton from '../_assets/svg/DetailsButton.svg';
import { InputLabelBase } from './inputs/InputLabelBase';
import { ProductTypes } from '../types';
import { useAllow } from './hooks/useAllow';
import { usePersona } from './hooks/usePersona';
import Showable from './Showable';
import {
  FORMAT_DATE_AND_TIME,
  toUniversalDate,
  toUniversalUtcDate,
} from '../utils/date.utils';
import { NO_DECIMAL_FORMAT, deriveCurrencyFormat } from '../i18n/currencies';
import { useCowbellTranslations } from '../i18n/translations';

const styles = ({ config, palette }) => {
  return {
    coverageWrapper: {
      display: 'flex',
      alignSelf: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
      paddingTop: 10,
      paddingBottom: 5,

      '&:nth-child(1)': {
        fontWeight: 'bold',
      },
      '&:nth-child(5)': {
        marginTop: '-.1rem',
        fontWeight: 'bold',
      },
    },
    label: {
      padding: '0.8rem 0 0 0',
      color: 'inherit',
    },

    valueTile: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginBottom: '1.5rem',
    },

    section: {
      width: '100%',
      display: 'flex',
      marginTop: '1rem',
      flexDirection: 'column',
    },
    fullBox: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
    },
    summaryLabel: {
      color: palette.text.secondary,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      textAlign: 'left',
      fontWeight: 600,
      fontFamily: 'Titillium Web',
    },
    summaryValue: {
      height: '13px',
      color: palette.text.secondary,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      flex: 'auto',
      textAlign: 'right',
      fontWeight: 'normal',
      fontFamily: 'Oswald',
      whiteSpace: 'nowrap',
    },

    pricingLabel: {
      color: config.colors.cowbellLight,
      fontSize: config.textSizes.paragon,

      '&:nth-child(1)': {
        textAlign: 'left',
        fontWeight: 600,
        fontFamily: 'Titillium Web',
        marginTop: 1,
      },

      '&:nth-child(2)': {
        flex: 'auto',
        textAlign: 'right',
        fontWeight: 'normal',
        fontFamily: 'Oswald',
      },
    },
    data: {
      '& li:first-child label': {
        padding: '0',
      },
    },
    detailsButton: {
      width: '3.4rem',
      height: '2.4rem',
      verticalAlign: 'middle',
      cursor: 'default',
    },
  };
};

export const PolicySummary = compose(
  withRouter,
  withStyles(styles)
)(({ classes, entireData, location, history, binder, policyData }) => {
  const _deriveCurrencyFormat = (input, options = {}) => {
    return deriveCurrencyFormat(entireData.language, input, options);
  };
  const { t, translationKeys } = useCowbellTranslations();

  const dispatch = useDispatch();
  const indicatedPrice = _deriveCurrencyFormat(entireData.totalPremium);

  const persona = usePersona();

  const CAN_UW_ACCOUNTS = useAllow(['accounts:uw']);
  const CAN_MANAGE_ACCOUNT_PROFILE = useAllow(['account_profile:manage']);
  const CAN_MANAGE_ACCOUNT = useAllow(['accounts:manage']);
  const CAN_COWBELL_ACCOUNT = useAllow(['account:cowbell']);

  let url = `${persona.basePath}/quote/details/underwriter/${entireData.quoteId}/${entireData.accountId}/${ProductTypes.p250}/policies`;
  if (persona.isAgency) {
    url = `/agency/accounts/details/${entireData.quoteId}/${entireData.accountId}/${ProductTypes.p250}/quotes`;
  } else if (persona.isCarrier) {
    url = `/carrier/quoteDetails/${entireData.quoteId}/account/${entireData.accountId}/${ProductTypes.p250}/policies`;
  }

  const handleRoute = React.useCallback(() => {
    dispatch(
      setAccountDetails({
        account: {
          companyName: entireData.firmographicData.name,
          quoteNumber: entireData.quoteNumber,
          accountId: entireData.accountId,
          agencyId: entireData.agencyId,
          policyId: entireData.id,
          rating: false,
        },
      })
    );
    if (persona.isCowbell) {
      dispatch(
        setAdminDetails({
          admin: {
            from: location.pathname,
            readOnly: {
              quote: true,
              policy: true,
              account: !(CAN_UW_ACCOUNTS || CAN_MANAGE_ACCOUNT_PROFILE),
              risk: true,
              documents: {
                upload: false,
                delete: !CAN_COWBELL_ACCOUNT,
              },
              notes: false,
            },
          },
        })
      );
    } else if (persona.isCarrier) {
      dispatch(
        setCarrierDetails({
          carrier: {
            from: location.pathname,
            readOnly: {
              quote: true,
              policy: true,
              documents: {
                upload: true,
                delete: true,
              },
            },
          },
        })
      );
    } else if (persona.isCluster) {
      dispatch(
        setClusterDetails({
          cluster: {
            from: location.pathname,
            readOnly: {
              quote: true,
              policy: true,
              account: false,
              risk: false,
              documents: {
                upload: false,
                delete: !CAN_COWBELL_ACCOUNT,
              },
              notes: false,
            },
          },
        })
      );
    } else if (persona.isAgency) {
      dispatch(
        setAgentDetails({
          agent: {
            from: location.pathname,
            readOnly: {
              quote: true,
              policy: true,
              account: !CAN_MANAGE_ACCOUNT,
              risk: true,
              documents: {
                upload: false,
                delete: true,
              },
            },
          },
        })
      );
    }
    history.push(url);

    // eslint-disable-next-line
  }, []);

  const quote = {
    'Policy Number': entireData.policyNumber,
  };

  const contact = {
    PolicyHolder: {
      'Policy Holder Name': `${_.get(
        entireData,
        'customerFirstName',
        ''
      )} ${_.get(entireData, 'customerLastName', '-')}`,
      'Policy Holder Email': _.get(entireData, 'customerEmail', '-'),
    },
    Agent: {
      'Agenct Name': `${_.get(entireData, 'agentFirstName', '')} ${_.get(
        entireData,
        'agentLastName',
        '-'
      )}`,
      'Agent Email': _.get(entireData, 'agentEmail', '-'),
    },
  };

  const policyStart = Moment.utc(entireData.effectiveDate);
  const policyEnd = Moment.utc(entireData.endDate);
  const duration = Moment.duration(policyEnd.diff(policyStart));
  const policyTerm = Math.round(duration.asDays());

  const quoteInfo = {
    'Policy Effective': `${toUniversalDate(policyStart)} 12:01 AM`,
    'Policy Ends On': `${toUniversalDate(policyEnd)} 12:01 AM`,
    'Policy Term': `${policyTerm} Days`,
  };

  const amount = {
    'Total Number of Coverages': entireData.totalCoverages,
    'Total Number of Endorsements': entireData.totalEndorsements,
    'Aggregate Limit': _deriveCurrencyFormat(
      entireData.limit,
      NO_DECIMAL_FORMAT
    ),
    [policyData.product === ProductTypes.p250
      ? 'Deductible'
      : t(translationKeys.retention)]: entireData.deductible
      ? _deriveCurrencyFormat(entireData.deductible, NO_DECIMAL_FORMAT)
      : 'N/A',
    ...(entireData.boundOn && {
      'Bound On': `${toUniversalDate(entireData.boundOn)}`,
    }),
    ...(entireData.status === 'CANCELLED' && {
      'Cancellation Date': `${toUniversalDate(entireData.cancellationDate, {
        placeholder: 'N/A',
      })}`,
    }),
    ...(entireData.status === 'CANCELLED' && {
      'Cancellation Reason': entireData.cancellationReason || 'N/A',
    }),
  };

  const primePlusDetails = {
    'Underlying Insurance': entireData.underlyingInsurance,
    'Other Underlying Insurance': entireData.otherUnderlyingInsurance,
    'Limit of Liability': Numeral(entireData.limit).format('$0,000'),
    Attachment: Numeral(entireData.attachment).format('$0,000'),
  };

  // get policy bound or issued label and from related fields
  // if not available, use current 'created'
  let policyBoundIssuedLabel = '';
  let policyBoundIssuedDate = '';

  if (binder) {
    policyBoundIssuedLabel = 'Bound On';
    policyBoundIssuedDate = toUniversalUtcDate(
      entireData.boundOn || entireData.created,
      { format: FORMAT_DATE_AND_TIME }
    );
  } else {
    policyBoundIssuedLabel = 'Policy Issued';
    policyBoundIssuedDate = toUniversalUtcDate(
      entireData.issuedDate || entireData.created,
      { format: FORMAT_DATE_AND_TIME }
    );
  }

  return (
    <>
      <ul className="list--unstyled" style={{ height: '8.5rem' }}>
        {_.entries(quote).map((factor, index) => {
          return (
            <li key={index} className="flex--spaced">
              <strong>
                <InputLabelBase className={classes.label}>
                  {factor[0]}
                </InputLabelBase>
              </strong>
              <span>
                {factor[1]}

                <Showable
                  show={
                    !policyData.isPrimePlus &&
                    ![
                      ProductTypes.primecloud,
                      ProductTypes.p100_pro,
                      ProductTypes.prime_one,
                      ProductTypes.prime_tech_plus,
                    ].some(Ramda.equals(policyData.product))
                  }
                >
                  <DetailsButton
                    className={classes.detailsButton}
                    onClick={handleRoute}
                  />
                </Showable>
              </span>
            </li>
          );
        })}
        {_.entries(contact.PolicyHolder).map((factor, index) => {
          return (
            <li key={index} className="flex--spaced">
              <strong>
                <InputLabelBase className={classes.label}>
                  {factor[0]}
                </InputLabelBase>
              </strong>
              <span>{factor[1]}</span>
            </li>
          );
        })}
      </ul>

      <h4 className="tertia-text flex--spaced">
        <u>{binder ? 'BINDER INFORMATION' : 'POLICY INFORMATION'}</u>
      </h4>

      <ul className={`${classes.data} list--unstyled`}>
        <li key="0-1" className="flex--spaced">
          <strong>
            <InputLabelBase className={classes.label}>
              {policyBoundIssuedLabel}
            </InputLabelBase>
          </strong>

          <span>{policyBoundIssuedDate}</span>
        </li>
        {binder && (
          <li key="0-1" className="flex--spaced">
            <strong>
              <InputLabelBase className={classes.label}>
                Expires On
              </InputLabelBase>
            </strong>

            <span>
              {toUniversalUtcDate(entireData.binderExpiresOn, {
                format: FORMAT_DATE_AND_TIME,
              })}
            </span>
          </li>
        )}

        {_.entries(quoteInfo).map((factor, index) => {
          return (
            <li key={index} className="flex--spaced">
              <strong>
                <InputLabelBase className={classes.label}>
                  {factor[0]}
                </InputLabelBase>
              </strong>

              <span>{factor[1]}</span>
            </li>
          );
        })}
      </ul>

      <h4 className="tertia-text flex--spaced">
        <u>COVERAGE INFORMATION</u>
      </h4>

      <ul className={`${classes.data} list--unstyled`}>
        {_.entries(entireData.isPrimePlus ? primePlusDetails : amount).map(
          (factor, index) => {
            const tooltip = _.get(
              CoverageInfoMapping[factor[0]],
              'tooltip',
              ''
            );
            return (
              <li key={index} className="flex--spaced">
                <strong>
                  <InputLabelBase inline className={classes.label}>
                    {factor[0]}
                  </InputLabelBase>
                  {!entireData.isPrimePlus && <DescTooltip tooltip={tooltip} />}
                </strong>
                <span>{factor[1]}</span>
              </li>
            );
          }
        )}
      </ul>

      <div className={classes.section}>
        <div className={classes.valueTile}>
          <div className={classes.pricingLabel}>Total Premium</div>
          <div className={classes.pricingLabel}>{indicatedPrice}</div>
        </div>
      </div>
    </>
  );
});

export default PolicySummary;
