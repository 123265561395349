import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

// lodash
import _ from 'lodash';

// components
import { Button, Grid, Typography, styled } from '@mui/material';

import { TextFieldBase } from '../../../components/inputs/TextFieldBase';

import { withFormController } from '../../../components/hocs/forms';
import { MultiSelectControlledBase } from '../../../components/inputs/autocomplete';
import { NaicsCodeAutoComplete } from '../../../naics/NaicsCodeAutoComplete';
import { FieldError } from '../../../components/inputs';

// statics
import StateStatics from '../../_statics/states.statics.json';

const MultiSelect = withFormController(MultiSelectControlledBase);
const TextField = withFormController(TextFieldBase);
const TextFieldProps = {
  required: true,
  showErrorPlace: false,
};

export const AdditionalInsuredFields = ({
  fieldsName,
  position,
  onRemove,
  defaultValues = {},
}) => {
  const { setValue } = useFormContext();
  const { errors } = useFormState();

  const insuredNumber = position + 1;

  function getFieldName(name) {
    return `${fieldsName}.${position}.${name}`;
  }

  function handleRemove() {
    if (typeof onRemove === 'function') {
      onRemove(position);
    }
  }

  function handleStateChange(event, nextState) {
    setValue(getFieldName('state'), nextState);
  }

  function handleNaicsChange(nextNaicsCode) {
    setValue(getFieldName('naicsCode'), nextNaicsCode);
    setValue(getFieldName('naicsDescription'), nextNaicsCode);
  }

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: '3rem' }}>
        <Grid item sm={12} className="flex--spaced">
          <Typography className="contrast-text" component="h3">
            Additional Insured {insuredNumber}
          </Typography>
          <RemoveButton variant="outlined" onClick={handleRemove}>
            Remove Insured {insuredNumber}
          </RemoveButton>
        </Grid>
        <Grid item md={12}>
          <TextField
            name={getFieldName('businessName')}
            label="Organization name"
            {...TextFieldProps}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            name={getFieldName('address1')}
            label="Address"
            {...TextFieldProps}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            name={getFieldName('address2')}
            label="Address cont."
            {...TextFieldProps}
            required={false}
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            name={getFieldName('city')}
            label="City"
            {...TextFieldProps}
          />
        </Grid>
        <Grid item md={4}>
          <MultiSelect
            name={getFieldName('state')}
            label="State"
            options={StateStatics.full}
            onChange={handleStateChange}
            required
            multiple={false}
            labelProps={{
              style: { paddingTop: '0.5rem' },
            }}
          />
          <FieldError
            error={_.get(errors, `insureds[${position}].state.message`)}
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            name={getFieldName('zipCode')}
            label="Zip code"
            {...TextFieldProps}
          />
        </Grid>
        <Grid item md={12}>
          <NaicsCodeAutoComplete
            label="Industry"
            onChange={handleNaicsChange}
            fetchOnMount
            defaultValue={_.get(
              defaultValues,
              'naicsCode.value',
              defaultValues.naicsCode
            )}
            error={_.get(errors, `insureds[${position}].naicsCode.message`)}
            required
          />
        </Grid>
      </Grid>
    </>
  );
};

const RemoveButton = styled(Button)(({ theme }) => ({
  color: theme.palette.error.light,
  borderColor: theme.palette.error.light,
}));
