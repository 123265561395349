import React from 'react';
import { PubSub } from '../../utils/eventUtils';

export const withUploadFeatureForms = () => {
  return (Component) => {
    return (props) => {
      const onDrop = React.useCallback((files) => {
        PubSub.publish('withUploadFeatureForms:selectedFile', files[0]);
      }, []);

      return (
        <section name="withUploadFeatureDocuments">
          <Component {...props} onDrop={onDrop} />
        </section>
      );
    };
  };
};
