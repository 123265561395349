import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withShowable } from '../../console/_global/lib/withShowable';

const Breadcrumbs = withShowable(({ classes, title, children, ...props }) => {
  return (
    <section className={classes.mainContainer}>
      {title ? <p className={classes.title}>{title}</p> : null}
      <MuiBreadcrumbs
        separator=">"
        classes={{
          ol: classes.pathContainer,
          li: classes.liContainer,
        }}
        {...props}
      >
        {children}
      </MuiBreadcrumbs>
    </section>
  );
});

const styles = ({ palette }) => {
  return {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    pathContainer: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '1rem',
      lineHeight: 1.5,
      textAlign: 'left',
      color: palette.text.primary,
      minWidth: '12rem',
    },
    liContainer: {
      '& p': {
        margin: '0 !important',
      },
      '& a': {
        color: palette.text.text10,
      },
    },
    title: {
      fontSize: '1.667rem',
      lineHeight: 1.4,
      textAlign: 'left',
      color: palette.primary.title,
      margin: '0 !important',
    },
  };
};

export default withStyles(styles)(Breadcrumbs);
