import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import Showable from '../../../../../Showable';

import { useSteps } from '../../../../../hooks/useSteps';
import { steps, STEPS } from './steps/step-config';

import RenewalOptOutForm from './steps/RenewalOptOutForm';
import Confirmation from './steps/Confirmation';

import { manageAPIError } from '../../../../../../utils';
import { transformFormDataToPayload } from './utils/transformers';
import { bulkRenewalProcessToggle } from '../../../../../../api/policies.api';

import { COWBELL_POLICY } from '../../../../../tables/table_constants';

function BulkRenewalOptOut({ data: { policyIdList }, ...props }) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const stepper = useSteps({
    startStep: STEPS.RENEWAL_OPT_OUT_FORM,
    initialSteps: steps,
  });

  if (!Array.isArray(policyIdList) || policyIdList.length == 0) {
    throw new Error(
      `The ${BulkRenewalOptOut.name} component must be provided an array of policies`
    );
  }

  function handleOptOutFormSubmit(formValues) {
    const reqBody = {
      ...transformFormDataToPayload(formValues),
      policyIdList,
      optOut: true,
    };

    return bulkRenewalProcessToggle({
      data: reqBody,
    })
      .then(() => {
        queryClient.invalidateQueries([COWBELL_POLICY]);
        stepper.goForward();
      })
      .catch((err) =>
        enqueueSnackbar(
          manageAPIError(err, 'There was an issue making this request'),
          { variant: 'error' }
        )
      );
  }

  return (
    <>
      <Showable show={stepper.step == STEPS.RENEWAL_OPT_OUT_FORM}>
        <RenewalOptOutForm
          onSubmit={handleOptOutFormSubmit}
          onCancel={props.close}
        />
      </Showable>

      <Showable show={stepper.step == STEPS.CONFIRM}>
        <Confirmation onConfirm={props.close} />
      </Showable>
    </>
  );
}

export const BulkRenewalOptOutConfig = {
  BulkRenewalOptOut: {
    component: BulkRenewalOptOut,

    config: {
      maxWidth: 'sm',
      title: 'Renewal Opt-Out',
    },
  },
};

export default BulkRenewalOptOut;
