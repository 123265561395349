import React from 'react';

const SVG = ({
  id = 'agents',
  style = {},
  fill = '#9192a4',
  width = '100%',
  className = '',
  viewBox = '0 0 45 45',
  title = 'Agents',
}) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .svg-agents-1 { fill: none; }
          .${id}.svg-fill-agents { fill: ${fill}; }
        `}
      </style>
    </defs>
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect className="svg-agents-1" width="45" height="45" />
        <path
          className={`${id} svg-fill-agents`}
          d="M22.5,5A17.5,17.5,0,1,0,40,22.5,17.52,17.52,0,0,0,22.5,5ZM13.72,35.79V33.2a8.22,8.22,0,0,1,2.64-5.92l.06-.06.21-.19.41-.34.36-.26a9.15,9.15,0,0,1,2.41-1.23l.82,1.59L19.43,32a.74.74,0,0,0,.22.7L22,34.88a.71.71,0,0,0,1,0l2.36-2.15a.75.75,0,0,0,.22-.71l-1.21-5.23.82-1.59a9.39,9.39,0,0,1,3.45,2.08,8.12,8.12,0,0,1,2.64,5.92v2.6a15.73,15.73,0,0,1-17.55,0Zm9.55-19.64a4.69,4.69,0,0,0,3.05,2v1.29a3.83,3.83,0,0,1-3.58,3.8h-.37a3.84,3.84,0,0,1-3.68-3.61V18.21A7,7,0,0,0,23.27,16.15Zm-4.58.6V15.47a3.8,3.8,0,0,1,3.61-3.72h.39a3.82,3.82,0,0,1,3.62,3.8v1.12a3.61,3.61,0,0,1-2.25-2.1.73.73,0,0,0-.95-.42.72.72,0,0,0-.37.31A5.15,5.15,0,0,1,18.69,16.75ZM22,27.43h1l1,4.51L22.5,33.36,21,31.94Zm-.72-2.59.21,0,.23,0h.12l.25,0h.8l.28,0h.11l.24,0,.2,0L23.14,26H21.85Zm14.87,5.93-.1.16L34.48,33a16.17,16.17,0,0,1-1.74,1.67V33.19a10.14,10.14,0,0,0-7.19-9.42,5.31,5.31,0,0,0,2.22-4.3V15.56A5.26,5.26,0,0,0,22,10.31l-.57.08-.15,0-.34.09-.17.06-.07,0a2.76,2.76,0,0,0-.47.2l-.09,0-.13.07-.51.31-.12.09-.28.22-.11.1-.07.07a.47.47,0,0,0-.1.1l-.08.07-.1.11-.32.38-.46.72-.07.13,0,.09-.06.13-.05.1a1.21,1.21,0,0,1-.05.14l0,.1s0,.09,0,.14l-.21.85c0,.06,0,.11,0,.16V15a1.15,1.15,0,0,0,0,.19v4.91s0,.08,0,.12l.1.5,0,.12,0,.12a1.09,1.09,0,0,0,.05.17l0,.07h0a.71.71,0,0,0,.07.18l0,.05a1.28,1.28,0,0,0,.09.22,5.22,5.22,0,0,0,1.75,2.09A10.83,10.83,0,0,0,15.63,26a12.16,12.16,0,0,0-1.36,1.47,9.55,9.55,0,0,0-2,5.77v1.48a12.36,12.36,0,0,1-.93-.84,4.23,4.23,0,0,1-.63-.62,11.64,11.64,0,0,1-1.09-1.33,15.17,15.17,0,0,1-.88-1.37l-.11-.17h0a15.68,15.68,0,0,1-2.1-7.84,16,16,0,0,1,31.94,0,15.78,15.78,0,0,1-2,7.72h0l-.18.32Zm-18.46-9.6Zm-.08-.27Z"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
