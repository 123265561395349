import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Moment from 'moment';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import {
  Grid as GridBase,
  DialogActions,
  DialogContent,
  useTheme,
  Typography,
  styled,
} from '@mui/material';
import { ToggleButton as ToggleButtonBase } from '@mui/lab';
import ClaimsIcon from '../../../../_assets/svg/Claims.svg';
import {
  AutoCompleteBase,
  SimpleSelect,
  TextField as TextFieldBase,
  getOptionLabel,
} from '../../../../components/inputs';
import CbButton from '../../../../components/Buttons/CbButton';
import { withFormController } from '../../../../components/hocs/forms';
import {
  getClaimIncidentTypes,
  getClaimsHandlers,
  createClaim,
  updateClaim,
} from '../../../../api/claims/claim.api';
import { fetchPolicies } from '../../../../policies/PolicyService';
import {
  toUniversalUtcDate,
  utcForAPI,
  utcToNativeDate,
} from '../../../../utils/date.utils';
import { COWBELL_CLAIMS } from '../../claims.constants';
import { RegisteredToggleButtonGroup } from '../../../../components/Buttons/registered/v7';
import { InputLabelBase } from '../../../../components/inputs/InputLabelBase';
import { withShowable } from '../../../_global/lib/withShowable';

const TextField = withFormController(TextFieldBase);
const Select = withFormController(SimpleSelect);
const Grid = withShowable(GridBase);

const ToggleButton = styled(ToggleButtonBase)(() => ({
  padding: '0.5rem 4rem',
}));

const defaultPolicyQuery = {
  size: 20,
  order: 'desc',
  orderBy: 'modified',
  before: Moment().endOf('day').unix(),
  after: Moment().subtract(2, 'year').startOf('day').unix(),
};

const AddEditClaimModal = ({ data: claim, ...props }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const schema = React.useMemo(
    () =>
      Yup.object().shape({
        reportedDate: Yup.date().required().label('Reported Date'),
        incidentDate: Yup.date().required().label('Incident Date'),
        incidentType: Yup.string().required().label('Incident Type'),
        handler: Yup.string().required().label('Claim Handler'),
        description: Yup.string().required().label('Description'),
        sendAckEmail: claim
          ? null
          : Yup.boolean().label('Acknowledgement Email').required(),
      }),
    [claim]
  );

  const defaultValues = {
    ..._.pick(claim, ['description', 'incidentType']),
    incidentDate: utcForAPI(Moment(_.get(claim, 'incidentDate'))),
    reportedDate: utcForAPI(Moment(_.get(claim, 'reportedDate'))),
    handler: getHandlerDefaultValue(claim),
  };

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [selectedPolicy, setPolicy] = React.useState({});
  const [policies, setPolicies] = React.useState([]);
  const [ephemeralInput, setInput] = React.useState('');

  // initialize edit mode
  React.useEffect(() => {
    if (claim) {
      // eslint-disable-next-line
      const { effectiveDate, endDate, companyName } = claim;
      setPolicy({ effectiveDate, endDate, companyName });
    }
    // eslint-disable-next-line
  }, []);

  // fetch policy data for typeahead
  React.useEffect(() => {
    fetchPolicies({ ...defaultPolicyQuery, search: ephemeralInput })
      .then((resp) => setPolicies(resp.data.content))
      .catch((error) => {
        if (error.message !== 'cancelled') {
          enqueueSnackbar(
            'There was a problem loading policy data. Please try again in a few minutes.',
            {
              variant: 'error',
            }
          );
        }
      });
    // eslint-disable-next-line
  }, [ephemeralInput]);

  // fetch additional data for dropdown fields
  const { data: incidentTypes } = useQuery(
    ['incidentTypes'],
    () => getClaimIncidentTypes().then((resp) => resp.data),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const { data: claimsHandlers } = useQuery(
    ['claimsHandlers'],
    () => getClaimsHandlers().then((resp) => resp.data.content),
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const handleTypeahead = _.debounce((e, value) => {
    setInput(value);
  }, 300);

  const handleSelectPolicy = (e, option) => {
    setPolicy(option.data);
  };

  const onSubmit = ({ handler, ...formData }) => {
    const [handlerFirstName, handlerLastName] = handler.split(' ');

    const [handlerData] = claimsHandlers.filter((claimsHandler) => {
      return (
        claimsHandler.firstName === handlerFirstName &&
        claimsHandler.lastName === handlerLastName
      );
    });

    // edit mode
    const basePayload = {
      ...formData,
      reportedDate: utcToNativeDate(formData.reportedDate),
      incidentDate: utcToNativeDate(formData.incidentDate),
      handlerId: handlerData.id,
      handlerPhone: handlerData.phone,
      handlerEmail: handlerData.email,
      handlerFirstName,
      handlerLastName,
    };

    // create mode
    const payload = {
      ...basePayload,
      ..._.pick(selectedPolicy, [
        'accountId',
        'agencyId',
        'companyName',
        'policyNumber',
        'agencyName',
        'customerFirstName',
        'customerLastName',
        'customerPhone',
      ]),
      policyId: selectedPolicy.id,
      productType: selectedPolicy.product,
      effectiveDate: utcForAPI(selectedPolicy.effectiveDate),
      endDate: utcForAPI(selectedPolicy.endDate),
    };

    const createOrUpdateClaim = () =>
      claim
        ? updateClaim(claim.id, { data: basePayload })
        : createClaim({ data: payload });
    createOrUpdateClaim()
      .then(() =>
        enqueueSnackbar(`Claim ${claim ? 'updated' : 'filed'} successfully!`, {
          variant: 'success',
        })
      )
      .catch(() =>
        enqueueSnackbar(
          'There was a problem with your request, please try again.',
          { variant: 'error' }
        )
      )
      .finally(() =>
        setTimeout(() => queryClient.invalidateQueries([COWBELL_CLAIMS]), 500)
      );
    props.close();
  };

  const { effectiveDate, endDate, companyName } = selectedPolicy;
  const incidentMaxDate = methods.watch('reportedDate');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingBottom: '2rem' }}>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <Autocomplete
                name="policyNumber"
                label="Policy Number"
                options={mapPolicyOptions(policies)}
                onInputChange={handleTypeahead}
                onChange={handleSelectPolicy}
                disabled={!!claim}
                placeholder={_.get(claim, 'policyNumber', '')}
                style={{ marginTop: '1.4rem' }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                name="reportedDate"
                label="Reported Date"
                type="date"
                required
                inputProps={{ max: utcForAPI(Moment()) }}
              />
            </Grid>
            <Grid item md={6}>
              <TextFieldBase
                name="Insured"
                label="Insured"
                disabled
                required
                value={companyName}
              />
            </Grid>
            <Grid item md={6}>
              <TextFieldBase
                name="policyPeriod"
                label="Policy Period"
                disabled
                required
                value={
                  effectiveDate && endDate
                    ? `${toUniversalUtcDate(
                        effectiveDate
                      )} - ${toUniversalUtcDate(endDate)}`
                    : ''
                }
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                name="incidentDate"
                label="Incident Date"
                type="date"
                required
                inputProps={{ max: utcForAPI(incidentMaxDate) }}
              />
            </Grid>
            <Grid item md={6}>
              <Select
                name="incidentType"
                label="Incident Type"
                options={mapSelectOptions(incidentTypes)}
                required
                style={{ backgroundColor: theme.palette.background.modal }}
              />
            </Grid>
            <Grid item md={6}>
              <Select
                name="handler"
                label="Claim Handler"
                options={mapHandlerOptions(claimsHandlers)}
                required
                style={{ backgroundColor: theme.palette.background.modal }}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                name="description"
                label="Description"
                multiline
                minRows={4}
                required
              />
            </Grid>
            <Grid item md={12} show={!claim}>
              <InputLabelBase indent>
                Enable Claim Acknowledgement
              </InputLabelBase>
              <RegisteredToggleButtonGroup name="sendAckEmail" exclusive>
                <ToggleButton value>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </RegisteredToggleButtonGroup>
              <br />
              <ErrorMessage
                errors={methods.errors}
                name="sendAckEmail"
                render={({ message }) => (
                  <span style={{ color: 'red' }}>{message}</span>
                )}
              />
              <br />
              <Typography variant="p" style={{ paddingTop: '1rem' }}>
                If claim acknowledgement is disabled, we will simply not send
                email confirmation of the claim creation
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <CbButton styleName="cancel" onClick={props.close}>
            Cancel
          </CbButton>
          <CbButton
            styleName="ctaButton"
            type="submit"
            disabled={_.isEmpty(selectedPolicy) || !methods.formState.isDirty}
          >
            {claim ? 'Update' : 'Create'}
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const AddEditClaimModalConfig = {
  AddEditClaimModal: {
    component: AddEditClaimModal,
    config: {
      fullWidth: true,
      maxWidth: 'md',
      minWidth: 'md',
      title: 'File/Edit a Claim',
      icon: <ClaimsIcon />,
    },
  },
};

// helpers
const mapSelectOptions = (options = []) => {
  return options.map((option) => ({ label: option, value: option }));
};

const mapPolicyOptions = (policies = []) => {
  return policies
    .filter((policy) => !!policy.policyNumber)
    .map((policy) => ({
      label: policy.policyNumber,
      value: policy.id,
      data: policy,
    }));
};

const mapHandlerOptions = (handlers = []) => {
  return handlers.map((handler = {}) => {
    const handlerName = `${handler.firstName} ${handler.lastName}`;
    return { label: handlerName, value: handlerName };
  });
};

const Autocomplete = (props) => (
  <AutoCompleteBase
    autoHighlight
    getOptionLabel={getOptionLabel}
    error=""
    fullWidth
    {...props}
  />
);

export const getHandlerDefaultValue = (claim = {}) => {
  const { handlerFirstName, handlerLastName } = claim;
  if (handlerFirstName && handlerLastName) {
    return `${handlerFirstName} ${handlerLastName}`;
  }
};
