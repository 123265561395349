import React from 'react';
import _ from 'lodash';
import fp from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import { compose } from 'redux';
import { Box, Card, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Numeral from 'numeral';
import { CustomCheckbox } from '../../../components/inputs';

// components
import { QuoteDetailsHeader } from './QuoteDetailsHeader';
import { CoverageDetails } from '../quotesPolicyCommon/CoverageDetails';
import { withPrime250 } from '../../agencies/quotes/250/withPrime250';
import { withDetailsDependencies } from '../quotesPolicyCommon/withDetailsDependencies';
import CoverageGroups from '../../_statics/250/coverages/groups.statics.json';
import { RequestQuoteLoading } from '../../agencies/quotes/250/RequestQuoteLoading';
import { P100CoveragesReadOnly } from '../quotesPolicyCommon/P100CoveragesReadOnly';
import { MultiQuotesHeader } from './MultiQuotesHeader';
// hooks
import { use250Context } from '../../agencies/quotes/250/prime250.context';
import { ProductTypes } from '../../../types';

export const LegendCheckbox = (props) => {
  const labelclasses = useLegendClasses();
  const checkclasses = useCheckClasses();

  return (
    <CustomCheckbox
      classes={checkclasses}
      disabled
      disableRipple
      formLabelProps={{ classes: labelclasses }}
      {...props}
    />
  );
};

const finalPremiumStatus = ['READY', 'BOUND', 'SIGNED'];

const QuoteDetails = compose(
  withRouter,
  withPrime250(),
  withDetailsDependencies('quote')
)(({ data, list, match }) => {
  if (!data) {
    return null;
  }
  const { coverages, ui } = use250Context();

  const { product } = match.params;

  let coverageData = {};
  if (product === ProductTypes.p100) {
    coverageData = data.initialRequestData;
  } else if (product === ProductTypes.p250) {
    coverageData = data.initial250RequestData;
  }

  const classes = styles();

  const grouped = React.useMemo(() => {
    return fp.flow(
      fp.filter((coverage) => Boolean(coverage.groupId)),
      fp.groupBy('groupId')
    )(coverages);
  }, [coverages]);

  if (ui.initialRender) {
    return <RequestQuoteLoading />;
  }

  return (
    <Card>
      <Box style={{ padding: '1.66rem 1.33rem' }}>
        <MultiQuotesHeader data={data} list={list} />
        <QuoteDetailsHeader
          data={data}
          coverageData={coverageData}
          product={product}
          list={list}
        />
        <h2 className={classes.covHeading}>Coverage Details and Limits</h2>
        {product === ProductTypes.p250 && (
          <>
            <Box
              className="list--unstyled flex--spaced"
              style={{ justifyContent: 'flex-start', paddingLeft: 15 }}
            >
              <li>
                <LegendCheckbox label="Mandatory" variant="filled" checked />
              </li>
              <li>
                <LegendCheckbox label="Selected" checked />
              </li>
              <li>
                <LegendCheckbox label="Available" />
              </li>
            </Box>

            {Object.keys(grouped).map((groupId) => (
              <CoverageDetails
                key={groupId}
                id={groupId}
                group={CoverageGroups[groupId]}
                groupedCoverages={grouped}
              />
            ))}
          </>
        )}

        {product === ProductTypes.p100 &&
          !_.isUndefined(data.firmographicData) &&
          !_.isUndefined(coverageData) && (
            <div style={{ padding: '2.5rem 2.5rem 0' }}>
              <P100CoveragesReadOnly
                data={data.initialRequestData}
                state={data.firmographicData.state}
              />
            </div>
          )}
      </Box>
      <Box mt={4} mb={2}>
        <Divider />
      </Box>
      <div className={classes.price}>
        {finalPremiumStatus.includes(data.agencyStatus)
          ? 'Final Premium:'
          : 'Indicated Pricing:'}{' '}
        {data.agencyStatus === 'REFERRED'
          ? 'Referred'
          : Numeral(data.totalPremium).format('$0,0.00')}
      </div>
    </Card>
  );
});

const styles = makeStyles(({ config, palette }) => ({
  heading: {
    fontSize: config.textSizes.paragon,
  },
  covHeading: {
    background: palette.background.details,
    color: palette.primary.contrastText,
    padding: '0.5rem 2.5rem',
    borderRadius: '5px',
    textAlign: 'left',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    fontStretch: 'normal',
  },
  price: {
    fontSize: config.textSizes.canon,
    color: palette.primary.contrastText,
    fontWeight: config.weights.bold,
    textAlign: 'right',
    paddingRight: '3.5rem',
    paddingBottom: '2rem',
  },
}));

const useLegendClasses = makeStyles(({ config, palette }) => ({
  root: {
    margin: 0,
    paddingRight: 10,
  },

  label: {
    fontSize: config.textSizes.petite,
    color: `${palette.primary.contrastText} !important`,
  },
}));

const useCheckClasses = makeStyles(() => ({
  root: {
    padding: 0,
    paddingRight: 5,
    paddingBottom: 1,
  },
}));

export default QuoteDetails;
