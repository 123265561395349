import _ from 'lodash';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
// mui
import { DialogActions, DialogContent, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CbButton from '../../../components/Buttons/CbButton';
import RadioButtons from '../../../components/inputs/RadioButtons';
import { updateLead } from '../sales.service';
import { delayedEvent } from '../../../utils/eventUtils';

const radioOptions = [
  { label: 'LOST', value: true },
  { label: 'DECLINED', value: false },
];

export const DeclineLeadModal = ({ iconStyles, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [backendError, setBackendError] = useState('');
  const classes = useStyles();
  const [status, setStatus] = useState({ label: 'LOST', value: true });
  function handleSubmit() {
    return updateLead(
      { id: props.data?.id },
      { status: status.label, accountId: props.data?.accountId }
    )
      .then(() => {
        enqueueSnackbar(`Lead updated Successfully!`, { variant: 'success' });
        props.close();
        delayedEvent('table-refetch', 3000, 'SalesInbox');
      })
      .catch((error) => {
        const errorMessage = _.get(
          error.response,
          'data',
          'Server Error, please try again'
        );
        setBackendError(errorMessage);
      });
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <h2 className={classes.headingOne}>
          Are you sure you want to mark the lead
          <span className={classes.headingTwo}> {props.data.accountName} </span>
          as
        </h2>
        <RadioButtons
          classContainer={classes.radioGroup}
          value={status}
          values={radioOptions}
          infunc={(value) => {
            if (value === 'true') {
              setStatus({ label: 'LOST', value: true });
            } else {
              setStatus({ label: 'DECLINED', value: false });
            }
          }}
        />
      </DialogContent>
      <FormHelperText className="api-text-error">{backendError}</FormHelperText>
      <DialogActions>
        <CbButton
          styleName="cancel"
          action={() => {
            props.close();
          }}
          buttonText="Cancel"
        />
        <CbButton
          onClick={() => {
            handleSubmit();
          }}
          type="submit"
          styleName="ctaButton"
          buttonText="Update"
        />
      </DialogActions>
    </>
  );
};

const useStyles = makeStyles(({ palette, config }) => ({
  dialogContent: { textAlign: 'center' },
  headingOne: { fontSize: config.textSizes.primer, fontWeight: 'normal' },
  headingTwo: { color: palette.common.cowbellBlue },
  radioGroup: {
    border: `1px solid ${palette.common.almostWhite}`,
    borderRadius: 5,
  },
}));

export const DeclineLeadModalConfig = {
  DeclineLeadModal: {
    component: DeclineLeadModal,
    config: {
      minWidth: 'md',
      title: 'Mark Lead as Lost/Declined',
    },
  },
};
