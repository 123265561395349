import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
// mui
import {
  Dialog,
  DialogTitle,
  Box,
  IconButton as MuiIconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
// components
import { useSurplusContext } from '../../console/agencies/quotes/250/prime250.context';
import { withSurplus } from '../withSurplus';
import { InsuranceLineSymbol } from '../../components/InsuranceLineSymbol';
import { AgencyBindQuote } from './AgencyBindQuote';
// reducers
import { resetState } from '../quote.reducer';
import { ProductTypes } from '../../types';

const styles = () => {
  return {
    icon: {
      position: 'absolute',
      right: 0,
      top: '0.75rem',
      paddingRight: '5rem',
    },
  };
};

export const BindQuote = compose(
  withSurplus(),
  withStyles(styles)
)(({ iconStyles, data, classes, ...props }) => {
  const product = _.get(data, 'product', ProductTypes.p100);
  const { dispatch } = useSurplusContext();

  const handleCancel = () => {
    dispatch(resetState());
    props.close();
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        <Box>
          <VerifiedUserOutlinedIcon
            fontSize="large"
            className={iconStyles.root}
          />{' '}
          Bind Quote for {data.companyName}
        </Box>
        {product === ProductTypes.p250 && (
          <div className={classes.icon}>
            <InsuranceLineSymbol isSurplus={data.isSurplus} />
          </div>
        )}
        <MuiIconButton className="iconBody" onClick={handleCancel}>
          <img src="images/Close-Modal.svg" alt="modal-close" />
        </MuiIconButton>
      </DialogTitle>
      <AgencyBindQuote
        data={data}
        product={product}
        setHandleCancel={handleCancel}
        dispatch={dispatch}
      />
    </Dialog>
  );
});

export const BindQuoteConfig = {
  BindQuote: {
    component: BindQuote,
    config: {
      fullWidth: false,
      maxWidth: 'md',
      override: true,
    },
  },
};
