import React from 'react';
import { Typography } from '@mui/material';

export const PrimaryTypography = ({ children, ...props }) => {
  return (
    <Typography color="primary" {...props}>
      {children}
    </Typography>
  );
};
