import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getDocsInfo } from '../../../api/documents.api';
import type { CustomQueryFunction } from './types';
import { useAPIErrorHandler } from '../useAPIErrorHandler';
import { getData } from '../../../utils/functional/fp';

type UseDocInfoQueryAccountParams = {
  accountId?: string;
  docId?: string;
};

// TODO: Abstract pattern to a custom query factory fn -- too verbose
export const useDocInfoQuery: CustomQueryFunction<
  UseDocInfoQueryAccountParams,
  UseQueryResult<any>
> = Object.assign(
  ({ accountId, docId }: UseDocInfoQueryAccountParams) => {
    const handleAPIError = useAPIErrorHandler();

    return useQuery(
      [...useDocInfoQuery.QUERY_KEY_TOPIC, { accountId, docId }],
      () =>
        getDocsInfoByAccount({
          accountId,
          docId,
        } as Required<UseDocInfoQueryAccountParams>).then(getData),
      {
        keepPreviousData: true,
        onError: handleAPIError('Failed to retrieve document info'),
        select: (data) => data.content?.[0],
        enabled: Boolean(accountId) && Boolean(docId),
      }
    );
  },
  { QUERY_KEY_TOPIC: ['doc-info'] }
);

function getDocsInfoByAccount({
  accountId,
  docId,
}: Required<UseDocInfoQueryAccountParams>) {
  return getDocsInfo(accountId, {
    params: {
      docIds: [docId],
    },
  });
}
