import React from 'react';
// ui
// components
import { FormContext, useForm } from 'react-hook-form-4';
import { DialogContent, Grid as MuiGrid, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CBButton from '../Buttons/CbButton';
import { Modal } from './v2/helpers/v2.modal.helpers';

export const UploadW9Notification = ({ data, ...props }) => {
  const classes = useClasses();
  const { handleSubmit, ...methods } = useForm({});
  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = () => {
    props.close();
    Modal.show('AddBankPrivacyConsent', { data });
  };

  const handleCancel = () => {
    props.close();
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent classes={{ root: classes.container }}>
          <MuiGrid>
            <p className={classes.description}>
              Your W9 Form was uploaded successfully!
              <br />
              We will contact you if we come across any issue.
            </p>
          </MuiGrid>
        </DialogContent>
        <DialogActions>
          <CBButton action={handleCancel} styleName="cancel">
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="NEXT"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  container: {
    maxHeight: '45rem',
    overflow: 'auto',
    paddingBottom: '1rem',
    color: palette.primary.contrastText,
  },
  description: {
    color: palette.primary.contrastText,
    lineHeight: 1.17,
    textAlign: 'center',
    fontSize: '1.33333rem',
  },
  header: {
    fontSize: '1.6667rem',
    lineHeight: 0.9,
    textAlign: 'left',
    margin: '2rem 0 0 0',
    color: palette.primary.contrastText,
  },
}));

export const UploadW9NotificationConfig = {
  UploadW9Notification: {
    component: UploadW9Notification,
    config: {
      minWidth: 'sm',
      title: 'Add your FEIN and upload W9 form',
    },
  },
};
