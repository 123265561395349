import React from 'react';
import Moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

// hooks
import { Prime250Context } from './prime250.context';

// services
import { fetchP250Coverages } from '../../../../accounts/AccountService';

// reducers
import { set250Coverages } from '../../../../reducers/Coverages250Reducers';
import {
  prime250Reducer,
  initialState,
} from '../../../_reducers/prime250.reducer';

/**
 * @name withPrime250
 * @description withPrime250 is a decorator around the top most parent component of a page requiring access to prime250 state.
 * It is meant to facilitate providing prime 250 dependencies to all children components via context.
 */
export const withPrime250 = (options) => {
  return (Component) => {
    return (props) => {
      const [state, dispatch] = React.useReducer(prime250Reducer, initialState);
      const globalDispatch = useDispatch();
      const { lastCache, coverages } = useSelector(
        (global) => global.coverage250
      );

      React.useEffect(() => {
        if (Moment().isSameOrAfter(lastCache)) {
          fetchP250Coverages().then((p250) => {
            globalDispatch(set250Coverages(p250.data));
          });
        }
      }, [globalDispatch, lastCache]);

      return (
        <Prime250Context.Provider
          value={{
            dispatch,
            availableCoverages: coverages,
            ...options,
            ...state,
          }}
        >
          <Component {...props} />
        </Prime250Context.Provider>
      );
    };
  };
};
