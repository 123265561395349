import React from 'react';
import {
  Menu,
  Box,
  IconButton,
  useTheme,
  Switch,
  FormControlLabel,
  styled,
} from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import CheckBoxBase from '../../../../inputs/Checkbox';
import { TableStateContext } from '../../TableStateContext';
import SimpleTooltip from '../../../../SimpleTooltip';

export const ColumnChooser = ({ ...props }) => {
  const { columns, handlers, ...rest } = React.useContext(TableStateContext);
  return (
    <ColumnChooserBase {...props} {...handlers} {...rest} columns={columns} />
  );
};

export const ColumnChooserBase = ({ columns: originalColumns, ...props }) => {
  const anchorElRef = React.useRef();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSorted, setIsSorted] = React.useState(false);
  const alphabeticallySortedColumns = React.useMemo(
    () => sortColumnsByTitle(originalColumns),
    [originalColumns]
  );

  const displayedColumns = isSorted
    ? alphabeticallySortedColumns
    : originalColumns;

  const handleToggle = () => {
    setIsSorted(!isSorted);
  };

  return (
    <>
      <ColumnChooserIcon ref={anchorElRef} onClick={() => setIsOpen(true)} />
      <Menu
        id="columnChooser-menu"
        anchorEl={anchorElRef.current}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        keepMounted
        open={isOpen}
        onClose={() => setIsOpen(false)}
        variant="menu"
      >
        <ColumnSwitchControlLabel
          value={isSorted}
          control={<Switch color="secondary" onChange={handleToggle} />}
          label="Alphabetical"
          labelPlacement="right"
        />
        <ColumnChooserList {...props} columns={displayedColumns} />
      </Menu>
    </>
  );
};

const ColumnChooserItem = ({ column, onHiddenColumnNamesChange }) => {
  const value = column.name;

  return (
    <CheckBoxBase
      name={column.name}
      checked={column.show !== false}
      label={column.title}
      onChange={() => onHiddenColumnNamesChange(value)}
    />
  );
};

const ColumnChooserList = ({ columns, ...props }) => {
  return (
    <Box
      style={{
        margin: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      {Object.values(columns).map((col) => (
        <>
          {col.canToggle !== false && !col.fixed ? (
            <ColumnChooserItem
              key={`column-chooser-item:${col.name}`}
              column={col}
              {...props}
            />
          ) : null}
        </>
      ))}
    </Box>
  );
};

export const ColumnChooserIcon = React.forwardRef(({ onClick }, ref) => {
  const theme = useTheme();

  return (
    <IconButton
      onClick={onClick}
      size="small"
      style={{
        cursor: 'pointer',
        color: theme.palette.text.primary,
        marginLeft: '0.4rem',
      }}
      ref={ref}
    >
      <SimpleTooltip title="Hide/Show Columns">
        <ViewColumnIcon
          style={{ fontSize: '2rem', position: 'relative', right: 2 }}
        />
      </SimpleTooltip>
    </IconButton>
  );
});

export const ColumnSwitchControlLabel = styled(FormControlLabel)(() => ({
  marginLeft: '0',
}));

// sortColumnsByTitle: sorts the object 'columns' alphabetically based on title
function sortColumnsByTitle(columns) {
  const columnArray = Object.values(columns);

  columnArray.sort(function (a, b) {
    if (a.title === undefined) return -1;
    if (b.title === undefined) return 0;
    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
    return 0;
  });

  return Object.assign({}, columnArray);
}
