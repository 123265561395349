import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

// hooks
import useUploadZone from '../../../../../components/fileUploader/v2/hooks/useUploadzone';
import useCompleteQuoteSubjectivity from '../shared/useCompleteQuoteSubjectivity';
import useDocUpload from '../shared/useDocUpload';
import useGetSubjectivityProgress from '../shared/useGetSubjectivityProgress';

// constants and utils
import { lossRunsSchema } from '../schemas/validationSchemas';
import useGetSubjectivityDocs from '../shared/useGetSubjectivityDocs';
import { determineIfStepIsEditable } from '../../utils/bindQuoteWorkFlowUtils';

export const useLossRunsDependencies = ({
  quoteDetails,
  subjectivities,
  goForward,
  step,
  completeStep,
  formState,
  formHandlers,
  quoteProgress,
}) => {
  const {
    quoteSubjectivityId,
    completed: isStepCompleted,
    subjectivitySubType,
  } = subjectivities[step];

  const [hadPreviousCoverage, setHadPreviousCoverage] = React.useState(null);
  const [isLossRunsAcknowledged, setIsLossRunsAcknowledged] =
    React.useState(isStepCompleted);

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(lossRunsSchema),
  });

  const { completeSubjectivity, isCompletingStep } =
    useCompleteQuoteSubjectivity({
      quoteId: quoteDetails.uiData.quoteId,
      productType: quoteDetails.uiData.productType,
    });

  const isStepEditable = determineIfStepIsEditable({
    subjectivitySubType,
    status: quoteProgress.quoteAgencyStatus,
    isAPI: quoteProgress.isAPI,
    productType: quoteDetails.uiData.productType,
  });

  const { files, ...uploadZoneParams } = useUploadZone({
    docType: 'Loss_Runs',
    maxFiles: 4,
    multiple: true,
  });

  const { uploadMultipleDocuments, isUploadingDoc } = useDocUpload({
    quoteDetails,
  });

  const {
    data: allSubjectivityInformation = {},
    isLoading: subjectivityDataLoading,
    isFetching: isFetchingSubjectivityData,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId,
    quoteId: quoteDetails.id,
    productType: quoteDetails.uiData.productType,
  });

  const { data: subjectivityData } = allSubjectivityInformation;

  const {
    data: uploadedDocuments,
    isInitialLoading: isUploadedDocsLoading,
    isFetching: isFetchingUploadedDocs,
  } = useGetSubjectivityDocs({
    quoteSubjectivityId,
    accountId: quoteDetails.uiData?.accountId,
    uploadedDocs: subjectivityData?.uploadedDocuments,
    isFetchingSubjectivityData,
  });

  const isLoadingSubjectivityData =
    subjectivityDataLoading ||
    isFetchingSubjectivityData ||
    isUploadedDocsLoading ||
    isFetchingUploadedDocs;

  React.useEffect(() => {
    if (files) {
      setIsLossRunsAcknowledged(false);
    }
  }, [files]);

  React.useEffect(() => {
    if (!subjectivityData) {
      setHadPreviousCoverage(formState.hadPreviousCoverage);
      methods.setValue('hadPreviousCoverage', formState.hadPreviousCoverage);
      methods.setValue(
        'yearsOfPreviousCoverage',
        formState.yearsOfPreviousCoverage
      );
    } else if (subjectivityData) {
      methods.setValue(
        'hadPreviousCoverage',
        subjectivityData.hasPreviousCoverage
      );
      setHadPreviousCoverage(subjectivityData.hasPreviousCoverage);
      methods.setValue(
        'yearsOfPreviousCoverage',
        subjectivityData.yearsOfPreviousCoverage || null
      );
    }
    // eslint-disable-next-line
  }, [subjectivityData]);

  const handleToggleClick = (hadPrevCoverage) => {
    return () => {
      setHadPreviousCoverage(hadPrevCoverage);
    };
  };

  const onSubmit = (formValues) => {
    formHandlers.saveLossRuns(formValues);
    if (hadPreviousCoverage && !files) {
      return subjectivityData ? completeLossRunsSubjectivity() : goForward();
    }

    if (hadPreviousCoverage === false) {
      formHandlers.saveLossRuns({
        hadPreviousCoverage: false,
        yearsOfPreviousCoverage: null,
      });
      return completeLossRunsSubjectivity();
    }

    const uploadDocumentParams = {
      formValues,
      files,
      quoteDetails,
      completeStep,
      goForward,
      step,
      docTypes: Array(files.length).fill('Loss_Run'),
      hasPreviousCoverage: hadPreviousCoverage,
      yearsOfPreviousCoverage: formValues.yearsOfPreviousCoverage,
      quoteSubjectivityId,
      userAttested: isLossRunsAcknowledged,
    };

    uploadMultipleDocuments(uploadDocumentParams);
  };

  const completeLossRunsSubjectivity = () => {
    const completeSubjectivityParams = {
      quoteSubjectivityId,
      completeStep,
      goForward,
      step,
      payload: {
        hasPreviousCoverage: hadPreviousCoverage,
        userAttested: true,
        yearsOfPreviousCoverage: hadPreviousCoverage
          ? methods.getValues('yearsOfPreviousCoverage')
          : 0,
        uploadedDocuments: hadPreviousCoverage
          ? subjectivityData?.uploadedDocuments
          : null,
      },
    };
    completeSubjectivity(completeSubjectivityParams);
  };

  const handleCheckboxChange = () => {
    setIsLossRunsAcknowledged((prevState) => !prevState);
  };

  const handleGoForward = () => {
    if (hadPreviousCoverage === null) {
      return goForward();
    }

    if (hadPreviousCoverage === false) {
      formHandlers.saveLossRuns({
        hadPreviousCoverage: false,
        yearsOfPreviousCoverage: null,
      });
      completeLossRunsSubjectivity();
    }
  };

  return {
    subjectivityData,
    allSubjectivityInformation,
    isLoadingSubjectivityData,
    hadPreviousCoverage,
    isLossRunsAcknowledged,
    isCompletingStep,
    subjectivitySubType,
    uploadZoneParams,
    uploadedDocuments,
    isStepCompleted,
    files,
    isUploadingDoc,
    isStepEditable,
    eventHandlers: {
      handleToggleClick,
      setHadPreviousCoverage,
      onSubmit,
      handleCheckboxChange,
      handleGoForward,
    },
    reactHookForm: {
      methods,
      handleSubmit,
    },
  };
};
