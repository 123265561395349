// mui
import { makeStyles } from '@mui/styles';

const P100RenewalError = () => {
  const classes = styles();

  return (
    <h1 className={classes.content}>
      Please contact Cowbell Support at support@cowbellcyber.ai
    </h1>
  );
};

const styles = makeStyles(({ config }) => ({
  content: {
    fontSize: config.textSizes.paragon,
    lineHeight: 1,
    color: `${config.colors.textRed} !important`,
    fontWeight: config.weights.bold,
    textAlign: 'center',
    margin: 0,
  },
}));

export default P100RenewalError;
