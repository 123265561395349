import _ from 'lodash';
import * as Yup from 'yup';
import React, { useState, useCallback, useRef } from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
import {
  DialogActions,
  DialogContent,
  Grid,
  Divider,
  FormHelperText,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { useSurplusContext } from '../../console/agencies/quotes/250/prime250.context';
import { LACompliance } from '../surplusCompliance/LAComplaince';
import { MTCompliance } from '../surplusCompliance/MTCompliance';
import { SendToSurplusBroker } from '../surplusCompliance/SendToSurplusBroker';
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { withFormController } from '../../components/hocs/withFormController';
import CbButton from '../../components/Buttons/CbButton';
import { UsStatesFull } from '../../utils/USState';
import CheckBoxBase from '../../components/inputs/Checkbox';
import { RegisteredSurplusAutoAutoComplete } from '../bind/SurplusBrokerAutoComplete';
// platform helpers
import { delayedEvent } from '../../utils/eventUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../reducers/UiSettingsReducers';
import { setAgencySendInfo, setRoute } from '../quote.reducer';
// actions
import { sendQuote } from '../QuotesService';
import { getAgentSurplusLineLicenses } from '../../console/agencies/_services/licenses.service';
import { useActor } from '../../components/hooks/useActor';

const complianceStates = ['LA', 'MT'];

const styles = makeStyles(({ palette, config }) => {
  return {
    greenText: {
      color: palette.text.text4,
    },
    boldText: {
      fontWeight: 600,
    },
    agencyInfo: {
      color: palette.primary.contrastText,
      fontSize: config.textSizes.normal,
    },
    horiDivider: {
      borderBottom: `1px solid ${palette.text.primary}`,
      margin: '1rem 0',
    },
    pagination: {
      fontSize: config.textSizes.tertia,
      color: palette.primary.contrastText,
      fontWeight: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      padding: '0.5rem',
      fontSize: config.textSizes.normal,
    },
  };
});

const schema = Yup.object().shape({
  agencyMailingAddress1: Yup.string().label('Address').required(),
  agencyMailingCity: Yup.string().label('City ').required(),
  agencyMailingState: Yup.string().label('State').ensure().required(),
  agencyMailingZipCode: Yup.number()
    .label('Zip Code')
    .typeError('${label} is a required field, and must be a number')
    .required(),
  surplusLinesBrokerFirstName: Yup.string()
    .label('Surplus Lines Broker First Name')
    .required(),
  surplusLinesBrokerLastName: Yup.string()
    .label('Surplus Lines Broker Last Name')
    .required(),
  surplusLinesState: Yup.string().label('State').ensure().required(),
  surplusLicenseNumber: Yup.string()
    .label('Surplus Lines Broker License Number')
    .required(),
  surplusLinesBrokerEmail: Yup.string()
    .label('Surplus Lines Broker Email')
    .email()
    .required(),
});

const TextField = withFormController(TextFieldBase);

export const SurplusSendQuote = ({
  data,
  setCustomerPage,
  setHandleCancel,
  canFormBeEdited,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();
  const surplusCompliance = _.get(
    data,
    'initial250RequestData.surplusCompliance',
    ''
  );
  const surplusCowbellInsurance = _.get(
    data,
    'initial250RequestData.surplusCowbellInsurance',
    false
  );
  const actor = useActor();
  const { send, dispatch } = useSurplusContext();

  const surplusDetails = useRef({
    agencyMailingAddress1: _.get(
      data,
      'surplus.agencyMailingAddress1',
      _.get(data, 'surplus.agencyAddress1', '')
    ),
    agencyMailingAddress2: _.get(
      data,
      'surplus.agencyMailingAddress2',
      _.get(data, 'surplus.agencyAddress2', '')
    ),
    agencyMailingCity: _.get(
      data,
      'surplus.agencyMailingCity',
      _.get(data, 'surplus.agencyCity', '')
    ),
    agencyMailingState: _.get(
      data,
      'surplus.agencyMailingState',
      _.get(data, 'surplus.agencyState', '')
    ).toUpperCase(),
    agencyMailingZipCode: _.get(
      data,
      'surplus.agencyMailingZipCode',
      _.get(data, 'surplus.agencyZipCode', '')
    ),
    surplusLinesBrokerFirstName: _.get(
      data,
      'surplus.surplusLinesBrokerFirstName',
      ''
    ),
    surplusLinesBrokerLastName: _.get(
      data,
      'surplus.surplusLinesBrokerLastName',
      ''
    ),
    surplusLicenseNumber: _.get(data, 'surplus.surplusLinesNumber', ''),
    surplusLinesBrokerEmail: _.get(data, 'surplus.surplusLinesBrokerEmail', ''),
    surplusLinesState: _.get(
      data,
      'surplus.surplusLinesState',
      _.get(data, 'surplus.state', '')
    ).toUpperCase(),
  });

  const { handleSubmit, ...methods } = useForm({
    validationSchema: schema,
  });

  const [error] = useState('');
  const [page, setPage] = useState(2);
  const [isSurplusBroker, setSurplusBroker] = useState(false); // agent logged in is the surplus broker
  const [surplusBrokerOnFile, setSurplusBrokerOnFile] = useState(false); // agent selected from the drop down list
  const [disabled, setDisabled] = useState(false);
  const [signatureOnFile, setSignature] = useState(
    _.get(data, 'surplus.signatureOnFile', false)
  ); // check if signature of broker selected exits

  const {
    setValue,
    formState: { isSubmitting },
    register,
    watch,
  } = methods;

  watch(['surplusLinesState', 'agencyMailingState']);

  const isCompliance = complianceStates.includes(
    surplusDetails.current.surplusLinesState
  );
  let nextBtnLabel;
  if (isCompliance && !signatureOnFile) {
    nextBtnLabel = 'Next';
  } else {
    nextBtnLabel = 'Send to Policy Holder';
  }

  React.useEffect(() => {
    getAgentSurplusLineLicenses({ surplusLinesState: data.state }).then(
      (response) => {
        if (response.data.content.length === 0) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      }
    );

    dispatch(setAgencySendInfo({ surplus: surplusDetails.current }));
    dispatch(setRoute({ from: 'send' }));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const {
      agencyMailingAddress1,
      agencyMailingAddress2,
      agencyMailingCity,
      agencyMailingState,
      agencyMailingZipCode,
      surplusLinesBrokerFirstName,
      surplusLinesBrokerLastName,
      surplusLicenseNumber,
      surplusLinesBrokerEmail,
      surplusLinesState,
    } = surplusDetails.current;
    setValue('agencyMailingAddress1', agencyMailingAddress1);
    setValue('agencyMailingAddress2', agencyMailingAddress2);
    setValue('agencyMailingCity', agencyMailingCity);
    setValue('agencyMailingState', agencyMailingState);
    setValue('agencyMailingZipCode', agencyMailingZipCode);
    setValue('surplusLinesBrokerFirstName', surplusLinesBrokerFirstName);
    setValue('surplusLinesBrokerLastName', surplusLinesBrokerLastName);
    setValue('surplusLicenseNumber', surplusLicenseNumber);
    setValue('surplusLinesBrokerEmail', surplusLinesBrokerEmail);
    setValue('surplusLinesState', surplusLinesState);
    // eslint-disable-next-line
  }, [page]);

  const handlePrevious = () => {
    setCustomerPage(1);
  };

  const handleChange = (event) => {
    surplusDetails.current.agencyMailingState = event.target.value;
    setValue(event.target.name, event.target.value);
  };

  const handleCheckbox = (event) => {
    setSurplusBroker(event.target.checked);
    if (event.target.checked) {
      setValue('surplusLinesBrokerFirstName', actor.firstName);
      setValue('surplusLinesBrokerLastName', actor.lastName);
      setValue('surplusLinesBrokerEmail', actor.email);
      setValue('surplusLicenseNumber', '');
      setSurplusBrokerOnFile('');
      setSignature(false);
    } else {
      setValue(
        'surplusLinesBrokerFirstName',
        _.get(data, 'surplus.surplusLinesBrokerFirstName', '')
      );
      setValue(
        'surplusLinesBrokerLastName',
        _.get(data, 'surplus.surplusLinesBrokerLastName', '')
      );
      setValue(
        'surplusLinesBrokerEmail',
        _.get(data, 'surplus.surplusLinesBrokerEmail', '')
      );
      setValue(
        'surplusLicenseNumber',
        _.get(data, 'surplus.surplusLinesNumber', '')
      );
      setSignature(_.get(data, 'surplus.signatureOnFile', false));
    }
  };

  const handleBrokerOnFile = useCallback(
    (selectedOption) => {
      if (selectedOption && typeof selectedOption === 'object') {
        setValue(
          'surplusLinesBrokerFirstName',
          selectedOption.meta.brokerFirstName
        );
        setValue(
          'surplusLinesBrokerLastName',
          selectedOption.meta.brokerLastName
        );
        setValue('surplusLinesBrokerEmail', selectedOption.value);
        setValue(
          'surplusLicenseNumber',
          selectedOption.meta.surplusLicenseNumber
        );
        setSurplusBrokerOnFile(selectedOption);
        setSignature(selectedOption.meta.signatureOnFile);
      }
    },
    // eslint-disable-next-line
    []
  );

  const renderOptions = (option) => {
    let nextOption = option;
    if (typeof option === 'string') {
      nextOption = { label: option, value: option };
    }

    return (
      <MuiMenuItem key={nextOption.label} value={nextOption.value}>
        {nextOption.label}
      </MuiMenuItem>
    );
  };

  const handleSendQuote = (formValues) => {
    const productType = _.get(data, 'product', '');
    const surplusValues = _.omit(formValues, [
      'isSurplusBroker',
      'surplusBrokerOnFile',
    ]);

    return sendQuote({
      quoteId: data.status === 'BOUND' ? data.quoteId : data.id,
      productType,
      ...surplusValues,
      ...send.customer,
    })
      .then(() => {
        enqueueSnackbar('Sent Successfully!', { variant: 'success' });
        delayedEvent('table-refetch', 500, AGENCY_QUOTES_LISTING_STORE_ID);
        delayedEvent('quote:read');
        if (typeof setHandleCancel === 'function') {
          setHandleCancel();
        }
      })
      .catch((error) => {
        const message = _.get(
          error,
          'response.data.message',
          'Server Error, please try again!'
        );
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  const onSubmit = (formValues) => {
    dispatch(setAgencySendInfo({ surplus: formValues }));
    dispatch(setRoute({ from: 'send' }));
    surplusDetails.current = formValues;
    if (isCompliance && !signatureOnFile) {
      setPage(3);
    } else {
      return handleSendQuote({ ...formValues, surplusNoticeAttested: false });
    }
  };

  return (
    <>
      {page === 2 && (
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent
              style={
                page === 1 ? { padding: 0 } : { padding: '1rem 5rem 0 5rem' }
              }
            >
              {!surplusCowbellInsurance && page === 2 && (
                <>
                  <div className="modal-title" style={{ fontWeight: 600 }}>
                    Agency Information
                    <Grid
                      container
                      justifyContent="flex-end"
                      className={classes.agencyInfo}
                    >
                      <Grid item md={2}>
                        Agency Name
                      </Grid>
                      <Grid item md={6} style={{ textAlign: 'left' }}>
                        {surplusCompliance}
                      </Grid>
                    </Grid>
                  </div>
                  <span className={classes.agencyInfo}>
                    Agency Mailing Address:
                  </span>
                  <Grid container spacing={4}>
                    <Grid item sm={6} style={{ paddingBottom: 0 }}>
                      <TextField
                        name="agencyMailingAddress1"
                        label="Address 1"
                        placeholder="Please enter address"
                        required
                        fullWidth
                        disabled={canFormBeEdited}
                      />
                    </Grid>
                    <Grid item sm={6} style={{ paddingBottom: 0 }}>
                      <TextField
                        name="agencyMailingAddress2"
                        label="Address 2"
                        placeholder="Please enter address"
                        fullWidth
                        disabled={canFormBeEdited}
                      />
                    </Grid>
                    <Grid item sm={6} style={{ paddingTop: 0 }}>
                      <TextField
                        name="agencyMailingCity"
                        label="City"
                        placeholder="Please enter city"
                        required
                        fullWidth
                        disabled={canFormBeEdited}
                      />
                    </Grid>
                    <Grid item sm={3} style={{ paddingTop: 0 }}>
                      <InputLabelBase required className={classes.label}>
                        State
                      </InputLabelBase>
                      <MuiSelect
                        variant="standard"
                        name="agencyMailingState"
                        required
                        onChange={handleChange}
                        value={surplusDetails.current.agencyMailingState}
                        classes={{
                          select: classes.select,
                          icon: classes.selectIcon,
                        }}
                        ref={register({ name: 'agencyMailingState' })}
                        placeholder="Please enter state"
                        error={_.get(
                          methods,
                          'errors.agencyMailingState.message'
                        )}
                        disabled={canFormBeEdited}
                      >
                        {UsStatesFull.map(renderOptions)}
                      </MuiSelect>
                      {!surplusDetails.current.agencyMailingState && (
                        <FormHelperText
                          style={{ color: '#fa8072', marginTop: 0 }}
                        >
                          {_.get(methods, 'errors.agencyMailingState.message')}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item sm={3} style={{ paddingTop: 0 }}>
                      <TextField
                        name="agencyMailingZipCode"
                        label="Zip Code"
                        required
                        placeholder="Please enter zip code"
                        fullWidth
                        disabled={canFormBeEdited}
                      />
                    </Grid>
                  </Grid>
                  <Divider className={classes.horiDivider} />
                  <div className="modal-title" style={{ fontWeight: 600 }}>
                    Surplus Lines Broker Information
                  </div>
                  <Grid
                    spacing={2}
                    container
                    direction="column"
                    style={{ padding: '1rem 0' }}
                  >
                    <Grid item>
                      <CheckBoxBase
                        name="isSurplusBroker"
                        checked={isSurplusBroker}
                        label="Please check this box if you are the Surplus Lines Broker for this account."
                        onChange={handleCheckbox}
                        ref={register({ name: 'isSurplusBroker' })}
                        disabled={canFormBeEdited}
                      />
                    </Grid>
                    <Grid item>
                      <RegisteredSurplusAutoAutoComplete
                        name="surplusBrokerOnFile"
                        label="Surplus Lines Brokers on File"
                        placeholder="Please select a surplus lines broker from file or manually enter a new one"
                        state={data.state}
                        fullWidth
                        disabled={
                          isSurplusBroker || disabled || canFormBeEdited
                        }
                        onChange={handleBrokerOnFile}
                        value={surplusBrokerOnFile}
                        fetchOnMount
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item sm={6} style={{ paddingBottom: '1rem' }}>
                      <TextField
                        name="surplusLinesBrokerFirstName"
                        label="Surplus Lines Broker First Name"
                        placeholder="Please enter surplus lines broker first name"
                        required
                        fullWidth
                        disabled={isSurplusBroker || canFormBeEdited}
                      />
                    </Grid>
                    <Grid item sm={6} style={{ paddingBottom: '1rem' }}>
                      <TextField
                        name="surplusLinesBrokerLastName"
                        label="Surplus Lines Broker Last Name"
                        placeholder="Please enter surplus lines broker last name"
                        required
                        fullWidth
                        disabled={isSurplusBroker || canFormBeEdited}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} style={{ paddingTop: 0 }}>
                    <Grid item sm={2} style={{ paddingTop: 0 }}>
                      <TextField
                        name="surplusLinesState"
                        label="State"
                        placeholder="Please enter state"
                        required
                        disabled
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={4} style={{ paddingTop: 0 }}>
                      <TextField
                        name="surplusLicenseNumber"
                        label="Surplus Lines Broker License#"
                        placeholder="Please enter filing state surplus license number"
                        required
                        fullWidth
                        disabled={canFormBeEdited}
                      />
                    </Grid>

                    <Grid item sm={6} style={{ paddingTop: 0 }}>
                      <TextField
                        name="surplusLinesBrokerEmail"
                        label="Surplus Lines Broker Email"
                        type="email"
                        placeholder="Please enter surplus lines broker email"
                        required
                        fullWidth
                        disabled={isSurplusBroker || canFormBeEdited}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <FormHelperText className="api-text-error">
                {error}
              </FormHelperText>
            </DialogContent>
            {!surplusCowbellInsurance && page === 2 && (
              <DialogActions>
                <Grid container justifyContent="space-between">
                  <Grid className={classes.pagination}>
                    Step 2 of {isCompliance && !signatureOnFile ? 3 : 2}: Agency
                    & Surplus Lines Information
                  </Grid>
                  <Grid>
                    <CbButton
                      styleName="cancel"
                      onClick={handlePrevious}
                      buttonText="Previous"
                    />
                    <CbButton
                      styleName="ctaButton"
                      type="submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      {nextBtnLabel}
                    </CbButton>
                  </Grid>
                </Grid>
              </DialogActions>
            )}
          </form>
        </FormContext>
      )}
      {surplusDetails.current.surplusLinesState === 'LA' &&
        page === 3 &&
        !signatureOnFile &&
        isSurplusBroker && (
          <LACompliance
            handleSubmit={handleSendQuote}
            setPage={setPage}
            isSubmitting={isSubmitting}
          />
        )}
      {surplusDetails.current.surplusLinesState === 'MT' &&
        page === 3 &&
        !signatureOnFile &&
        isSurplusBroker && (
          <MTCompliance
            handleSubmit={handleSendQuote}
            setPage={setPage}
            isSubmitting={isSubmitting}
          />
        )}
      {complianceStates.includes(surplusDetails.current.surplusLinesState) &&
        page === 3 &&
        !signatureOnFile &&
        !isSurplusBroker && (
          <SendToSurplusBroker
            setPage={setPage}
            quoteId={data.id}
            setHandleCancel={setHandleCancel}
          />
        )}
    </>
  );
};

export default SurplusSendQuote;
