import { ServiceHandlerFactory } from '../../../../utils/serviceUtils';

export const addLicense = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/agency/surplus',
    method: 'post',
  },
});

export const deleteLicense = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/agency/surplus/:id',
    method: 'delete',
  },
});

export const updateLicense = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/agency/surplus/:id',
    method: 'put',
  },
});
