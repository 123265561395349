import React from 'react';

// MUI
import type { MenuItemProps } from '@mui/material';
import { MenuItem as MuiMenuItem } from '@mui/material';

// hocs
import { withShowable } from '../../console/_global/lib/withShowable';

import useLatestPrime250PolicyFunctions from './hooks/useLatestPrime250PolicyFunctions';
import type { PolicyTableInfoDto } from '../../types/policy/tables/policy-table-info.dto';

const MenuItem = withShowable(MuiMenuItem);

interface GenerateRenewalQuoteOptionProps {
  children: React.ReactNode;
  policy: Pick<
    PolicyTableInfoDto,
    'product' | 'policyNumber' | 'endDate' | 'renewalOptOut'
  > &
    Partial<PolicyTableInfoDto>;
  disabled: boolean;
  show: boolean;
}

export const GenerateRenewalQuoteOption = ({
  children,
  policy,
  disabled,
  ...props
}: GenerateRenewalQuoteOptionProps & MenuItemProps) => {
  const { canGenerateP250RenewalQuote, isLoading } =
    useLatestPrime250PolicyFunctions({
      policy,
      queryOptions: {
        enabled: props.show,
        retry: false,
        onError: () => null,
      },
    });

  const isDisabled = isLoading || !canGenerateP250RenewalQuote || disabled;

  return (
    <MenuItem {...props} disabled={isDisabled}>
      {children}
    </MenuItem>
  );
};
