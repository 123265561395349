import Moment from 'moment';

// lodash
import _ from 'lodash';

import { ProductTypes } from '../../types';
import {
  IN_FORCE_STATUS,
  ISSUED_STATUS,
  BOUND_STATUS,
  CANCELLED_STATUS,
  CANCELLED_PENDING_STATUS,
  CANCELLED_IN_REINSTATEMENT_PERIOD,
  VOID_STATUS,
} from '../_statics/policy.statics';

type Policy = any;

function isActivePolicyByStatus(policy: Policy) {
  return [IN_FORCE_STATUS, ISSUED_STATUS, BOUND_STATUS].includes(policy.status);
}

function isCancelledPolicyByStatus(policy: Policy) {
  return [
    CANCELLED_PENDING_STATUS,
    CANCELLED_IN_REINSTATEMENT_PERIOD,
    CANCELLED_STATUS,
    VOID_STATUS,
  ].includes(policy.status);
}

function isBinder(policy: Policy) {
  return policy.status === 'BOUND' && !policy.isAppSigned;
}

function isInBORChange(policy: Policy) {
  return Boolean(_.get(policy, 'isBOR', false));
}

function isPayable(policy: Policy) {
  return !policy.isPaid && policy.totalPremium > 0;
}
function isSurplus(policy: Policy) {
  return Boolean(_.get(policy, 'isSurplus', true));
}

function isRescindable(policy: Policy) {
  return Boolean(_.get(policy, 'rescindEligible', false));
}

function hasCancellationNotice(policy: Policy) {
  return Boolean(_.get(policy, 'cancellationNotice', null));
}

function isVoidable(policy: Policy) {
  if (policy.product === ProductTypes.p100) {
    return !policy.isEndorsement && isActivePolicyByStatus(policy);
  }

  if (policy.product === ProductTypes.p250) {
    const END_OF_CURRENT_MONTH = Moment.utc().endOf('month');
    const START_OF_PREVIOUS_MONTH = Moment.utc()
      .subtract(1, 'month')
      .startOf('month');
    //check for between or start of month
    const BETWEEN_START_END_OF_MONTH = Moment.utc(
      policy.effectiveDate
    ).isBetween(START_OF_PREVIOUS_MONTH, END_OF_CURRENT_MONTH, undefined, '[]');
    //enable option if policy's effective date is in the future
    const IN_FUTURE = Moment.utc(policy.effectiveDate).isAfter(new Date());

    return BETWEEN_START_END_OF_MONTH || IN_FUTURE;
  }

  return false;
}

function isReported(policy: Policy) {
  const issuedDate = Moment(policy.issuedDate);
  const reportingDate = issuedDate
    .add(1, 'month')
    .startOf('month')
    .add(7, 'day');

  return Moment().isSameOrAfter(reportingDate);
}

function isPolicyCancellationDateTodayOrInTheFuture(policy: Policy) {
  const cancellationDate = Moment.utc(policy.cancellationDate);
  const today = Moment();

  const daysApartFromToday = cancellationDate.diff(today, 'days');
  return daysApartFromToday >= 0;
}

function isNonPremiumBearingEndorsement(policy: Policy) {
  const isNonPremiumBearing =
    policy.totalPremium === 0 || policy.totalPremium == undefined;

  return policy.isEndorsement && isNonPremiumBearing;
}

export default {
  isActivePolicyByStatus,
  isCancelledPolicyByStatus,
  isBinder,
  isInBORChange,
  isPayable,
  isSurplus,
  isRescindable,
  hasCancellationNotice,
  isVoidable,
  isReported,
  isPolicyCancellationDateTodayOrInTheFuture,
  isNonPremiumBearingEndorsement,
};
