/**
 * @name prime100Reducer
 * @description This reducer is a local reducer not intended to be used with redux or other global state mechanisms.
 */

import { handleActions } from 'redux-actions';
import Moment from 'moment';
import AT from '../../actions/ActionTypes';

export const SET_UI = 'SET_UI';
export const SET_FLORIDA_AGENT = 'SET_FLORIDA_AGENT';
export const RESET_AGENT = 'RESET_AGENT';
export const RESET_UI = 'RESET_UI';
export const SET_ENDORSEMENT = 'SET_ENDORSEMENT';
export const SET_AGENCY_DATA = 'SET_AGENCY_DATA';
export const RESET_AGENCY_DATA = 'RESET_AGENCY_DATA';
export const SET_VERIFY_QUOTE_UI = 'SET_VERIFY_QUOTE_UI';

// action creators

export const setPrime100Ui = (payload) => ({
  type: SET_UI,
  payload,
});

export const setVerifyUI = (payload) => ({
  type: SET_VERIFY_QUOTE_UI,
  payload,
});

export const resetUi = (payload) => ({
  type: RESET_UI,
  payload,
});

export const setEndorsement = (payload) => {
  return {
    type: SET_ENDORSEMENT,
    payload,
  };
};

export const setFloridaAgent = (payload) => ({
  type: SET_FLORIDA_AGENT,
  payload,
});

export const resetAgent = (payload) => ({
  type: RESET_AGENT,
  payload,
});

export const setAgencyData = (payload) => ({
  type: SET_AGENCY_DATA,
  payload,
});

export const resetAgencyData = (payload) => ({
  type: RESET_AGENCY_DATA,
  payload,
});

export const initialState = {
  ui: {
    additionalInsuredList: [],
    endorsement: {
      effectiveDate: Moment().toDate(),
    },
  },
  florida250license: {},
  agency: {},
};

export const prime100Reducer = handleActions(
  {
    [SET_UI]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
        },
      };
    },
    [SET_VERIFY_QUOTE_UI]: (state, action) => {
      return {
        ...state,
        verifyUI: {
          ...state.verifyUI,
          ...action.payload,
        },
      };
    },
    [RESET_UI]: (state) => {
      return {
        ...state,
        ui: {
          additionalInsuredList: [],
          endorsement: {
            effectiveDate: Moment().toDate(),
          },
        },
      };
    },

    [SET_ENDORSEMENT]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          endorsement: {
            ...state.ui.endorsement,
            ...action.payload,
          },
        },
      };
    },
    [SET_FLORIDA_AGENT]: (state, action) => {
      return {
        ...state,
        florida250license: {
          ...state.florida250license,
          ...action.payload,
        },
      };
    },
    [SET_AGENCY_DATA]: (state, action) => {
      return {
        ...state,
        agency: {
          ...state.agency,
          ...action.payload,
        },
      };
    },
    [RESET_AGENCY_DATA]: (state) => {
      return {
        ...state,
        agency: {},
      };
    },
    [RESET_AGENT]: (state) => {
      return {
        ...state,
        florida250license: {},
      };
    },
    [AT.USER_LOGOUT]: () => {
      return initialState;
    },
  },
  initialState
);
