import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectGlobalTeam } from '../../../../reducers/team.reducer';
import { useAgencyDashboardState } from '../AgencyDashboardStateContext';
import { AGENCY_DASHBOARD_VIEWS } from '../agency-dashboard.statics';
import { Show } from '../../../../components/Show';
import { ALL_TEAM } from '../../../../teams/TeamsMenu';
import type { TeamOption } from '../onboarding-screens/StartNewQuote';

interface CurrentTeamNullResultProps {
  onAllMyTeamsClick: React.MouseEventHandler;
  onMyAgencyClick: React.MouseEventHandler;
}

export const CurrentTeamNullResult = ({
  onAllMyTeamsClick,
  onMyAgencyClick,
}: CurrentTeamNullResultProps) => {
  const classes = useStyles();
  const team = useSelector(selectGlobalTeam);

  return (
    <Box padding="1rem">
      <Typography className={classes.header}>
        We couldn't find a match within your Team: {team.name}
      </Typography>
      <SearchTips />
      <ul className={classes.searchTipsList}>
        <GeneralWarning />
        <CheckOtherTabWarning
          accountExistsInAllMyTeams
          accountExistsInMyAgency
          onAllMyTeamsClick={onAllMyTeamsClick}
          onMyAgencyClick={onMyAgencyClick}
        />
        <SpellingWarning />
      </ul>
    </Box>
  );
};

interface AllTeamsNullResultProps {
  isMyAgencyTab?: boolean;
  accountExistsInMyAgency: boolean;
  onMyAgencyClick: React.MouseEventHandler;
}

export const AllTeamsNullResult = ({
  isMyAgencyTab,
  accountExistsInMyAgency,
  onMyAgencyClick,
}: AllTeamsNullResultProps) => {
  const classes = useStyles();
  const team = useSelector(selectGlobalTeam);
  const { handleViewChange } = useAgencyDashboardState();

  const handleStartNewQuoteClick = () => {
    handleViewChange(AGENCY_DASHBOARD_VIEWS.NEW_QUOTE);
  };

  return (
    <Box padding="1rem">
      <Typography className={classes.header}>
        {team.id === ALL_TEAM.id && !isMyAgencyTab
          ? "We couldn't find a match within 'All' of your Teams"
          : "We couldn't find a match!"}
      </Typography>
      <SearchTips />
      <ul className={classes.searchTipsList}>
        <GeneralWarning />
        <Show when={accountExistsInMyAgency}>
          <CheckOtherTabWarning
            accountExistsInAllMyTeams={false}
            accountExistsInMyAgency={accountExistsInMyAgency}
            onMyAgencyClick={onMyAgencyClick}
          />
        </Show>
        <SpellingWarning />
      </ul>
      <Show when={!accountExistsInMyAgency}>
        <Typography className={classes.subheader}>
          Would you like to{' '}
          <Typography
            className={classes.interactableText}
            onClick={handleStartNewQuoteClick}
          >
            'Start a New Quote'
          </Typography>{' '}
          instead?
        </Typography>
      </Show>
    </Box>
  );
};

export const NewQuoteSearchNullResult = ({
  team,
  searchTerm,
  state,
}: {
  team: TeamOption;
  searchTerm: string;
  state: string;
}) => {
  const classes = useStyles();
  const history = useHistory();

  const structuredTeamPayload = _.has(team, 'value')
    ? { label: team.value?.name, value: team.value?.id }
    : { label: team.name, value: team.id };

  const handleManuallyAddClient = () => {
    history.push('/agency/manual-account', {
      team: structuredTeamPayload,
      primaryName: searchTerm,
      state,
    });
  };

  return (
    <Box padding="1rem 4rem">
      <Typography className={classes.header}>
        We couldn't find a match!
      </Typography>
      <SearchTips />
      <ul className={classes.searchTipsList}>
        <GeneralWarning />
        <SpellingWarning />
      </ul>
      <Typography className={classes.subheader}>
        Would you like to{' '}
        <Typography
          className={classes.interactableText}
          onClick={handleManuallyAddClient}
        >
          'Manually Add Client'
        </Typography>{' '}
        instead?
      </Typography>
    </Box>
  );
};

const SearchTips = () => {
  const classes = useStyles();
  return <Typography className={classes.subheader}>Search tips</Typography>;
};

const GeneralWarning = () => (
  <li>
    Make sure that you are searching for a client name, Quote Number, binder #
    or policy #.
  </li>
);

const SpellingWarning = () => <li>Check your spelling.</li>;

interface CheckOtherTabWarningProps {
  accountExistsInAllMyTeams: boolean;
  accountExistsInMyAgency: boolean;
  onAllMyTeamsClick?: React.MouseEventHandler;
  onMyAgencyClick: React.MouseEventHandler;
}

const CheckOtherTabWarning = ({
  accountExistsInAllMyTeams,
  accountExistsInMyAgency,
  onAllMyTeamsClick,
  onMyAgencyClick,
}: CheckOtherTabWarningProps) => {
  const classes = useStyles();

  return (
    <li>
      Check{' '}
      <Show when={accountExistsInAllMyTeams}>
        <p
          className={classes.interactableText}
          onClick={onAllMyTeamsClick}
          role="presentation"
        >
          'All My Teams'
        </p>
        {' or '}
      </Show>
      <Show when={accountExistsInMyAgency}>
        <p
          className={classes.interactableText}
          onClick={onMyAgencyClick}
          role="presentation"
        >
          'My Agency'
        </p>{' '}
      </Show>
      to expand your search results.
    </li>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  subheader: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginTop: '1rem',
  },
  searchTipsList: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '1rem',
    gap: '10px',
  },
  interactableText: {
    display: 'inline',
    color: theme.agencyDash.interactable,
    cursor: 'pointer',
  },
}));
