import React from 'react';
// mui
import { DialogContent, DialogActions, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

// icons
import { useSurplusContext } from '../../console/agencies/quotes/250/prime250.context';
import WarningIcon from '../../_assets/svg/Warning.svg';
// components
import CbButton from '../../components/Buttons/CbButton';

const styles = ({ config, palette }) => {
  return {
    header: {
      fontSize: config.textSizes.paragon,
      lineHeight: 1.42,
    },
    icon: {
      width: '2rem',
      verticalAlign: 'sub',
      marginRight: '0.25rem',
    },
    subText: {
      textAlign: 'left',
      fontSize: config.textSizes.primer,
      lineHeight: 1.38,
      marginTop: '1rem',
      '& p:first-child': {
        marginTop: 0,
      },
      '& p:last-child': {
        marginBottom: 0,
      },
    },
    pagination: {
      fontSize: config.textSizes.tertia,
      color: palette.primary.contrastText,
      fontWeight: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
  };
};

export const CowbellCompliance = withStyles(styles)(
  ({ classes, handleSubmit, setPage, isSubmitting }) => {
    const { bind, send, from } = useSurplusContext();

    const handlePrevious = () => {
      setPage(1);
    };

    const onSubmit = () => {
      if (typeof handleSubmit === 'function') {
        handleSubmit(from === 'bind' ? bind.customer : send.customer);
      }
    };

    return (
      <>
        <DialogContent className="modal-title" style={{ padding: '2rem 5rem' }}>
          <WarningIcon className={classes.icon} />
          <span className={classes.header}>Cowbell Compliance</span>
          <div className={classes.subText}>
            <p>
              Cowbell Cyber Inc will be doing the compliance for this 'Surplus'
              policy.
            </p>
            <p>
              If that is not what you want, please contact
              support@cowbellcyber.ai to change the 'Surplus Compliance' from
              Cowbell to your agency/licensed surplus lines broker.
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid className={classes.pagination}>
              Step 2 of 2: Cowbell Compliance
            </Grid>
            <Grid>
              <CbButton
                styleName="cancel"
                onClick={handlePrevious}
                buttonText="Previous"
              />
              <CbButton
                styleName="ctaButton"
                onClick={onSubmit}
                disabled={isSubmitting}
                buttonText={from === 'bind' ? 'Bind Quote' : 'Send Quote'}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </>
    );
  }
);

export default CowbellCompliance;
