import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
// mui
import { DialogActions, DialogContent, FormHelperText } from '@mui/material';
// components
import CBButton from '../../../../components/Buttons/CbButton';
// platform helpers
import { delayedEvent } from '../../../../utils/eventUtils';
// actions
import { deleteLicense } from '../Modals/surplusServices';

// FIR-1866

export const DeleteSurplusLicense = ({ close, data }) => {
  const [backendError, setBackendError] = useState('');
  const { handleSubmit, ...methods } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  function onSubmit() {
    deleteLicense({ id: data.id })
      .then(() => {
        enqueueSnackbar('Surplus License deleted succesfully!', {
          variant: 'success',
        });
        close();
        delayedEvent('table-refetch', 500, 'surplusLicense');
      })
      .catch((error) => {
        setBackendError(error.statusText);
      });
  }

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className="modal-title" style={{ fontWeight: 600 }}>
            {`Are you sure you want to delete Surplus License ${data.surplusLicenseNumber}?`}
          </DialogContent>
          <FormHelperText className="api-text-error">
            {backendError}
          </FormHelperText>
          <DialogActions>
            <CBButton onClick={close} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton type="submit" styleName="ctaButton">
              Delete
            </CBButton>
          </DialogActions>
        </form>
      </FormContext>
    </>
  );
};

export default DeleteSurplusLicense;
