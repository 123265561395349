import _ from 'lodash';
import React, { useCallback } from 'react';
// ui
// components
import { FormContext, useForm } from 'react-hook-form-4';
import { DialogContent, Grid as MuiGrid, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { MultiSelect } from '../../../components/Selects';
import { UsStatesFull } from '../../../utils/USState';
import CbButton from '../../../components/Buttons/CbButton';
import { createTerritory, updateTerritory } from '../sales.service';
import { Modal } from '../../../components/modals/v2/helpers/v2.modal.helpers';
import { delayedEvent } from '../../../utils/eventUtils';

const getDefaultStates = (territoryData) => {
  if (!territoryData) {
    return [];
  }
  const { states, partialStates } = territoryData;
  const allStates = states.concat(partialStates.map((st) => st.stateAbbr));
  return allStates.map((s) => ({
    label: s,
    value: s,
  }));
};

export const AddStatesToTerritory = ({ data, ...props }) => {
  const classes = useClasses();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const territoryDetailData =
    data && data.id ? queryClient.getQueryData(['territoryDetailById']) : null;
  const initialStates = getDefaultStates(territoryDetailData);
  const { handleSubmit, ...methods } = useForm();
  const [states, setStates] = React.useState(initialStates);
  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = () => {
    if (data.id) {
      // updating a territory
      const obj = {
        states: [],
        partialStates: [],
      };
      states.reduce((acc, item) => {
        const partialStateTemp = territoryDetailData.partialStates.find(
          (i) => i.stateAbbr === item.value
        );
        if (partialStateTemp) {
          acc.partialStates.push(partialStateTemp);
        } else {
          acc.states.push(item.value);
        }
        return acc;
      }, obj);

      const payloadData = {
        name: data.territoryName,
        ...obj,
      };

      return updateTerritory({ id: data.id }, payloadData)
        .then(() => {
          enqueueSnackbar('Succesfully updated the territory!', {
            variant: 'success',
          });
          Modal.hide('AddStatesToTerritory');
          delayedEvent('table-refetch', 1000, 'SalesInboxTerritory');
        })
        .catch((error) => {
          enqueueSnackbar(
            _.get(error, 'response.data', 'Unable to delete a state'),
            { variant: 'error' }
          );
        });
    }
    // creating a new territory
    const myStates = states.map((item) => item.value);
    const payload = {
      name: data.territoryName,
      states: myStates,
    };

    return createTerritory({}, payload)
      .then((response) => {
        enqueueSnackbar('New territory succesfully created!', {
          variant: 'success',
        });
        Modal.hide('AddStatesToTerritory');
        data.history.push(
          `/admin/salesInbox/territory?territoryName=${data.territoryName}&id=${response.data}`
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          _.get(error, 'response.data', 'Unable to create a new territory'),
          { variant: 'error' }
        );
      });
  };

  const handleCancel = useCallback(() => {
    props.close();
    // eslint-disable-next-line
  }, []);

  const handleSelectState = useCallback(
    (name, selectedOption) => {
      setStates(selectedOption);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent classes={{ root: classes.container }}>
          <MuiGrid container spacing={4}>
            <MuiGrid item md={12}>
              <MultiSelect
                required
                label="State"
                name="state"
                isMulti
                error={_.get(methods, 'errors.state.message')}
                buttonPlaceholder="Select state"
                onChange={handleSelectState}
                options={UsStatesFull}
                values={states}
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <DialogActions>
          <CbButton action={handleCancel} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Add"
            loading={isSubmitting}
            disabled={states.length === 0 || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  container: {
    overflowY: 'visible',
    paddingTop: '1rem !important',
    color: palette.primary.contrastText,
  },
}));

export const AddStatesToTerritoryConfig = {
  AddStatesToTerritory: {
    component: AddStatesToTerritory,
    config: {
      minWidth: 'md',
      title: 'Add/Select States',
      overFlowVisible: true,
    },
  },
};
