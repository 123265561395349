import React from 'react';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import numeral from 'numeral';

// mui
import { Box, DialogActions, MenuItem, Select, styled } from '@mui/material';

// components
import CbButton from '../Buttons/CbButton';
import { InputLabelBase } from '../inputs/InputLabelBase';

// services
import { erpDuration, getErpPremium } from '../../policies/PolicyService';

export const AddERPModal = ({ data = {}, close }) => {
  const [selectedErpDuration, setSelectedErpDuration] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [erpPremium, setErpPremium] = React.useState(
    'Select an option to see ERP Premium'
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    erpDuration({}, { policyId: data.id, months: selectedErpDuration })
      .then(() => {
        enqueueSnackbar('ERP sucessfully sent.', {
          variant: 'success',
        });
        close();
      })
      .catch((err) => {
        enqueueSnackbar(
          _.get(
            err,
            'response.data',
            'There was a problem adding the ERP. Please try again later.'
          ),
          { variant: 'error' }
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleChange = (e) => {
    setSelectedErpDuration(e.target.value);
    getErpPremium({ policyId: data.id, months: e.target.value })
      .then(({ data: erpPremiumData = {} }) => {
        setErpPremium(numeral(+erpPremiumData.erpPremium).format('$0,0.00'));
      })
      .catch((err) => {
        enqueueSnackbar(
          _.get(
            err,
            'response.data',
            'There was a problem calculating the ERP Premium. Please try again later.'
          ),
          { variant: 'error' }
        );
      });
  };

  return (
    <>
      <Container
        className="contrast-text"
        style={{ padding: '1.5rem', paddingBottom: 0 }}
      >
        ERP Premium: {erpPremium}
      </Container>
      <form onSubmit={handleSubmit}>
        <Container style={{ padding: '1.5rem', paddingTop: 0 }}>
          <InputLabelBase required>Select ERP Duration</InputLabelBase>
          <Select
            variant="standard"
            name="ERP Duration"
            required
            onChange={handleChange}
          >
            {selectInputOptions.map(renderOptions)}
          </Select>
        </Container>
        <DialogActions>
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Submit"
            loading={isSubmitting}
            disabled={!selectedErpDuration || isSubmitting}
          />
        </DialogActions>
      </form>
    </>
  );
};

const selectInputOptions = ['12', '24', '36'];

const renderOptions = (option) => {
  const nextOption = { label: `${option} Months`, value: option };

  return (
    <MenuItem key={nextOption.label} value={nextOption.value}>
      {nextOption.label}
    </MenuItem>
  );
};

const Container = styled(Box)({
  width: '75%',
  margin: 'auto',
});

export const AddERPModalConfig = {
  AddERPModal: {
    component: AddERPModal,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      minWidth: 'sm',
    },
  },
};
