import React, {
  cloneElement,
  createElement,
  isValidElement,
  useContext,
} from 'react';

import { withStyles } from '@mui/styles';
import { Button as MuiButton } from '@mui/material';

import RefreshIcon from '@mui/icons-material/Cached';

import { TableContext } from './context.table';
import { ColumnCustomizer } from './column-customizer/column-customizer.table';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 10,
    marginTop: 30,
  },
  buttonRoot: {
    minWidth: 'auto',
  },
  altFlex: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',

    '& .MuiTextField-root + .MuiIconButton-root': {
      marginLeft: '.8rem',
    },

    '& .MuiTextField-root + .MuiButton-root, & * + .MuiButton-root': {
      marginLeft: '1.5rem',
    },

    '& .MuiIconButton-root + .MuiButton-root': {
      marginLeft: '.8rem',
    },

    '& .MuiButton-root + .MuiButton-root': {
      marginLeft: '1.5rem',
    },

    '& .MuiButton-root + .MuiIconButton-root': {
      marginLeft: '.8rem',
    },
  },
});

export const ActionBar = withStyles(styles)(
  ({ render, children, classes, ...props }) => {
    const { onRefresh, ...context } = useContext(TableContext);

    if (typeof render === 'function') {
      return (
        <div className={`action-bar ${classes.altFlex}`}>
          {render(props, context)}
        </div>
      );
    }

    return (
      <div className={`action-bar ${classes.altFlex}`}>
        {React.Children.map(children, (child) =>
          isValidElement(child)
            ? cloneElement(child, { ...child.props, ...props })
            : createElement('div', { ...child.props, ...props }, child)
        )}
        <div>
          <ColumnCustomizer />
          <MuiButton
            className={classes.buttonRoot}
            variant="contained"
            size="small"
            color="secondary"
            onClick={onRefresh}
          >
            <RefreshIcon fontSize="small" />
          </MuiButton>
        </div>
      </div>
    );
  }
);

ActionBar.displayName = 'ActionBar';

ActionBar.defaultProps = {
  columns: [],
  data: [],
  total: 0,
};
