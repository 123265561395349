import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
// platform helpers
import qs from 'qs';
import Moment from 'moment';
import { useSnackbar } from 'notistack';
// utils
import { PubSub } from '../../utils/eventUtils';
import {
  determineIsAccountEligibleForQuote,
  pullAttestationDataFromAccount,
  useQuoteCreationFunctions,
} from '../accountUtils';
import { manageAPIError } from '../../utils';
import { Modal } from '../../components/modals/v2/helpers/v2.modal.helpers';
// actions
import { certifyAccount, validateQuoteRequest } from '../AccountService';
import { checkStatusOnAccount } from '../../inbox/QuotesService';
import { FlowTypes } from '../../types/flows';
import { toUniversalUtcDate } from '../../utils/date.utils';

export const useAccountAttestation = ({
  account = {},
  renewal = false,
  onNewBusinessRedirect = handleNewBusinessRedirect,
  onRenewalRedirect = handleRenewalRedirect,
  onUnavailableRedirect = handleUnavailableRedirect,
  onAttestFailed = handleAttestFailed,
}) => {
  const { isVerifiedByAgent, verifiedDate, id } = account;

  const { enqueueSnackbar } = useSnackbar();
  const { product: productURLParam } = useParams();

  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  // state
  const [checkIfVerifiedByAgent, setCheckIfVerifiedByAgent] =
    React.useState(isVerifiedByAgent);
  const [agentVerifiedDate, setAgentVerifiedDate] = React.useState(() =>
    toUniversalUtcDate(verifiedDate)
  );
  const [disabledForUnsavedChanges, setDisableForUnsavedChanges] =
    React.useState(false);

  // hooks
  const { shouldQuotingBeDisabledForAccountError, error } =
    determineIsAccountEligibleForQuote(account, checkIfVerifiedByAgent);
  const { detemineAgentOnAttestation } = useQuoteCreationFunctions();

  React.useEffect(() => {
    const sub = PubSub.subscribe('disable-Submit', (value) => {
      setDisableForUnsavedChanges(value);
    });

    const verify = PubSub.subscribe('org:agent-attested', (value) => {
      setCheckIfVerifiedByAgent(value.isVerifiedByAgent);
    });

    return () => {
      sub.remove();
      verify.remove();
    };
    // eslint-disable-next-line
  }, []);

  const handleAttestment = React.useCallback(() => {
    if (checkIfVerifiedByAgent) {
      return null;
    }

    const payload = {
      ...detemineAgentOnAttestation(),
      ...pullAttestationDataFromAccount(account),
    };

    certifyAccount(payload)
      .then(() => {
        setCheckIfVerifiedByAgent(true);
        setAgentVerifiedDate(toUniversalUtcDate(new Date()));
      })
      .catch((error) => {
        onAttestFailed(error, enqueueSnackbar);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIfVerifiedByAgent, account, enqueueSnackbar]);

  const handleValidateQuoteRequest = () => {
    return validateQuoteRequest({
      accountId: id,
      renewal,
      product: productURLParam ?? queryParams.product,
    })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        enqueueSnackbar(
          manageAPIError(
            error,
            'Cannot quote at this time. Please try again later'
          ),
          { variant: 'error' }
        );
      });
  };

  const handleCheckStatusOnAccount = (agencyId, accountId) => {
    checkStatusOnAccount({
      accountId: id || accountId,
      agencyId,
    })
      .then(({ data }) => {
        const { quoteFlowType } = data;

        if (quoteFlowType === FlowTypes.newBusiness) {
          onNewBusinessRedirect(data, enqueueSnackbar);
        } else if (quoteFlowType === FlowTypes.unavailable) {
          onUnavailableRedirect(data);
        } else if (quoteFlowType === FlowTypes.renewal) {
          onRenewalRedirect(data, accountId, enqueueSnackbar);
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          manageAPIError(
            error,
            'Cannot quote at this time.Please try again later'
          ),
          { variant: 'error' }
        );
      });
  };

  return {
    checkIfVerifiedByAgent,
    agentVerifiedDate,
    shouldQuotingBeDisabledForAccountError,
    disabledForUnsavedChanges,
    disableSubmit:
      shouldQuotingBeDisabledForAccountError || disabledForUnsavedChanges,
    errorMessage: error,
    handleAttestment,
    handleValidateQuoteRequest,
    handleCheckStatusOnAccount,
  };
};

const handleNewBusinessRedirect = (data, enqueueSnackbar) => {
  enqueueSnackbar('Need a function to move ahead', { variant: 'error' });
};

const handleRenewalRedirect = (data, enqueueSnackbar) => {
  enqueueSnackbar('Need a function to move ahead', { variant: 'error' });
};

const handleUnavailableRedirect = (data) => {
  Modal.show('StopRenewalQuote', {
    data: { ...data },
  });
};

const handleAttestFailed = (error, enqueueSnackbar) => {
  return enqueueSnackbar(
    manageAPIError(
      error,
      'Something went wrong while verifying the organization profile.'
    ),
    { variant: 'error' }
  );
};
