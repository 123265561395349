import React from 'react';

const SVG = ({
  id = 'PNGIcon',
  style = {},
  // fill = '#fff',
  className = '',
  viewBox = '0 0 20 30',
  title = 'PNGIcon',
}) => (
  <svg
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .cls-1{fill:#fff}.cls-2{fill:#848280}
          `}
      </style>
    </defs>
    <title>{title}</title>
    <g id="Capa_1">
      <path
        id="Path_5354"
        fill="#fff"
        d="M16.34.21H1.99a.7.7 0 0 0-.7.7v27.85a.7.7 0 0 0 .7.725h20.7a.725.725 0 0 0 .725-.725V7.185a.625.625 0 0 0-.225-.5L16.84.41a.75.75 0 0 0-.5-.2z"
        transform="translate(1.935 .315)"
      />
      <path id="Rectangle_3469" fill="none" d="M0 0H28.55V30H0z" />
      <path
        id="Path_3761"
        d="M5.28 11.475h2.275v-2.3h-2.3V6.85h2.3V4.525h-2.3V2.2H2.93v2.325h2.3V6.85h-2.3v2.325h2.3v2.3H2.955V13.8h2.3v2.325H7.58V13.8h-2.3z"
        className="cls-3"
        transform="translate(4.395 3.3)"
      />
      <path
        id="Path_3762"
        d="M7.115 11.475H9.39v-2.3h-2.3V6.85h2.3V4.525h-2.3V2.2h-2.3v2.325h2.275V6.85H4.79v2.325h2.275v2.3H4.79V13.8h2.3v2.325h2.325V13.8h-2.3z"
        className="cls-3"
        transform="translate(7.185 3.3)"
      />
      <path
        id="Path_3763"
        d="M8.965 11.475h2.3v-2.3h-2.3V6.85h2.3V4.525h-2.3V2.2H6.64v2.325h2.3V6.85h-2.3v2.325h2.3v2.3H6.665V13.8h2.3v2.325h2.3V13.8h-2.3z"
        className="cls-3"
        transform="translate(9.96 3.3)"
      />
      <path
        id="Path_3764"
        d="M6.975 12.745l1.5 2.5h1.675V8.62H8.7v1.95a25.274 25.274 0 0 0 .125 2.825 25 25 0 0 0-1.15-2.325L6.2 8.57H4.3v6.73h1.475v-4.98a23.575 23.575 0 0 0 1.2 2.425z"
        className="cls-3"
        transform="translate(6.45 12.855)"
      />
      <path
        id="Path_3765"
        d="M11.074 9.827a3.8 3.8 0 0 1 1.65.3l.35-1.225a5.35 5.35 0 0 0-1.974-.325 3.575 3.575 0 0 0-4.025 3.6 3.125 3.125 0 0 0 1 2.5 4.025 4.025 0 0 0 2.85.925 8.725 8.725 0 0 0 2.5-.375v-3.7h-2.75V12.7h1.075v1.425a2.5 2.5 0 0 1-.775 0A2.075 2.075 0 0 1 8.749 11.9a2.1 2.1 0 0 1 2.325-2.075z"
        className="cls-3"
        transform="translate(10.601 12.823)"
      />
      <path
        id="Path_3766"
        d="M1.87 8.762v6.65h1.575v-2.5H4a3.35 3.35 0 0 0 2.3-.7 2.125 2.125 0 0 0 .65-1.575 1.975 1.975 0 0 0-.78-1.575 3.525 3.525 0 0 0-2.15-.525 14.45 14.45 0 0 0-2.15.225zm3.475 2c0 .7-.525 1.1-1.375 1.1a1.55 1.55 0 0 1-.525 0V9.837H4.1c.795-.125 1.245.3 1.245.925z"
        className="cls-3"
        transform="translate(2.805 12.788)"
      />
      <path
        id="Path_5355"
        fill="#848280"
        d="M23.69 6.7L17.315.325a1.075 1.075 0 0 0-.75-.325H2.215A1.075 1.075 0 0 0 1.14 1.075v27.85A1.1 1.1 0 0 0 2.24 30h20.675a1.075 1.075 0 0 0 1.075-1.075V7.5a1.025 1.025 0 0 0-.3-.8zm-.775 22.5h-20.7a.35.35 0 0 1-.35-.35V1.1a.35.35 0 0 1 .35-.35h13.9V6.8a1.1 1.1 0 0 0 1.1 1.075h6.075v21.05a.35.35 0 0 1-.35.35z"
        transform="translate(1.71)"
      />
    </g>
  </svg>
);

export default SVG;
