import {
  serializeElasticPageableTokenResponse,
  standardQuerySerializer,
} from '../serializers';
import {
  applyQueryConfig,
  responseStringTransformer,
  ServiceHandler,
} from '../../utils';
import { claimPanelParamsSerializer } from './claim-panel.params-serializer';

export const getClaimsPanel = ServiceHandler({
  url: '/api/claimsmgmt/v1/panel/claim/:claimId',
  paramsSerializer: (params) =>
    standardQuerySerializer(
      applyQueryConfig(claimPanelParamsSerializer, params)
    ),
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableTokenResponse,
  ],
});

export const addPanelExpert = ServiceHandler({
  url: '/api/claimsmgmt/v1/panel',
  method: 'POST',
});

export const deleteClaimsPanelExpert = ServiceHandler({
  url: '/api/claimsmgmt/v1/panel/:expertId',
  method: 'DELETE',
});
