import React from 'react';
import { Box, styled } from '@mui/material';

// mui
import { CloudUpload as CloudIconBase } from '@mui/icons-material';

// utils
import { LinearUploadProgress as LinearUploadProgressBase } from '../../../../renewals/renewal.utils';
import { acceptedUploadDocs } from '../../constants/constants';

// components
import { Uploadzone } from '../../../../../components/fileUploader/v2/UploadZone';
import CbButton from '../../../../../components/Buttons/CbButton';
import FullScreenDropZoneWrapper from '../../../../../components/fileUploader/v2/FullScreenDropZoneWrapper';
import TextFieldFilePreview from './TextFieldFilePreview';

// hocs
import { withShowable } from '../../../../_global/lib/withShowable';

const LinearUploadProgress = withShowable(LinearUploadProgressBase);

const DocumentUploadBase = ({
  uploadZoneParams,
  files,
  isUploadingDoc,
  uploadedDocs = [],
  docType,
  isStepEditable,
  datePickerProps,
}) => {
  const formattedUploadedDocs = uploadedDocs.map((doc) => {
    return {
      ...doc,
      name: doc.docName,
    };
  });

  return (
    <>
      <p style={{ fontSize: '1.2rem' }}>Upload Documentation</p>
      <Box
        paddingBottom="1.2rem"
        style={!files && !uploadedDocs.length ? { width: '20rem' } : {}}
      >
        <Uploadzone
          {...uploadZoneParams}
          accept={acceptedUploadDocs}
          files={files || formattedUploadedDocs}
          hasPreview={!!files || !!uploadedDocs.length}
          Preview={TextFieldFilePreview}
          previewProps={{
            disabled: isUploadingDoc || !isStepEditable,
            docsPreviouslyUploaded: !!uploadedDocs.length,
            docType,
            userSelectedFiles: !!files,
            ...datePickerProps,
          }}
          Placeholder={<FullScreenDropzonePlaceholder />}
        />
        <LinearUploadProgress show={isUploadingDoc} />
      </Box>
    </>
  );
};

export default DocumentUploadBase;

const FullScreenDropzonePlaceholder = () => {
  return (
    <Box style={{ width: '20rem', textAlign: 'center' }}>
      <FullScreenDropZoneWrapper>
        <CbButton
          styleName="ctaButton"
          buttonText="Upload Document(s)"
          size="large"
          endIcon={<CloudUpload />}
        />
        <SupportedText>
          Supported File Types: PNG, JPG, JPEG, PDF, DOC
        </SupportedText>
      </FullScreenDropZoneWrapper>
    </Box>
  );
};

const CloudUpload = styled(CloudIconBase)({
  pointerEvents: 'none',
});

const SupportedText = withShowable(
  styled('p')({
    textAlign: 'center',
  })
);
