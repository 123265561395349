import React from 'react';
import _ from 'lodash';

import {
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';

import {
  tableStateReducer,
  UPDATE_COLUMN_ORDER,
  UPDATE_COLUMN_VISIBILITY,
  UPDATE_COLUMN_WIDTHS,
  UPDATE_EXPANDED_ROWS,
  UPDATE_PAGE_SIZE,
  UPDATE_PAGINATION,
  // UPDATE_SEARCH,
  UPDATE_SELECTION,
  UPDATE_SORT,
} from '../tableStateReducer';
import { calculateColumnWidth } from '../table.utils';
import { useWindowResizeEffect } from './useWindowResizeEffect';

const getInitialColumnOrder = (columnConfig, uiSettings) => {
  const { columnOrder: savedOrder = [] } = uiSettings;

  const colNames = Object.keys(columnConfig);
  const leftFixed = colNames.filter(
    (colName) => _.get(columnConfig, `${colName}.fixed`) === 'left'
  );
  const rightFixed = colNames.filter(
    (colName) => _.get(columnConfig, `${colName}.fixed`) === 'right'
  );

  if (savedOrder) {
    const savedRest = savedOrder.filter(
      (colName) =>
        _.get(columnConfig, `${colName}.fixed`) === undefined &&
        Object.prototype.hasOwnProperty.call(columnConfig, colName)
    );
    const newColumns = colNames.filter(
      (colName) => !savedOrder.includes(colName)
    );
    return [...leftFixed, ...newColumns, ...savedRest, ...rightFixed];
  }

  const rest = colNames.filter(
    (colName) => _.get(columnConfig, `${colName}.fixed`) === undefined
  );
  return [...leftFixed, ...rest, ...rightFixed];
};

export const mapColumnNames = (columnConfig) =>
  Object.keys(columnConfig).reduce((acc, colName) => {
    if (Object.prototype.hasOwnProperty.call(columnConfig[colName], 'name')) {
      return { ...acc, [colName]: columnConfig[colName] };
    }
    return { ...acc, [colName]: { ...columnConfig[colName], name: colName } };
  }, {});

export const useTableState = (
  columnConfig,
  { uiSettings = {}, ...rest } = {}
) => {
  const { columnWidths } = uiSettings;
  const savedColumnWidths = Array.isArray(columnWidths) ? columnWidths : [];
  const defaultColumnWidths = useWindowResizeEffect(
    (tableWidth) =>
      Object.values(columnConfig).map((column) => ({
        columnName: column.name,
        width: calculateColumnWidth(column, tableWidth),
      })),
    { delay: 500, id: rest.tableId }
  );

  const reconciliation = uiSettings.columns ? uiSettings.columns : columnConfig;
  const initialState = React.useMemo(
    () => ({
      columns: reconciliation,
      sortingDisabledColumns: Object.values(columnConfig)
        .filter((col) => col.sort === false)
        .map((col) => ({ columnName: col.name, sortingEnabled: false })),
      hiddenColumnNames: Object.keys(reconciliation).filter(
        (colName) => reconciliation[colName].show === false
      ),
      columnOrder: getInitialColumnOrder(columnConfig, uiSettings),
      columnWidths: _.uniqBy(
        [...savedColumnWidths, ...defaultColumnWidths],
        'columnName'
      ),
      search: '',
      fixedLeft: [
        TableSelection.COLUMN_TYPE,
        ...Object.keys(columnConfig).filter(
          (colName) => _.get(columnConfig, `${colName}.fixed`) === 'left'
        ),
      ],
      fixedRight: [
        ...Object.keys(columnConfig).filter(
          (colName) => _.get(columnConfig, `${colName}.fixed`) === 'right'
        ),
        TableRowDetail.COLUMN_TYPE,
      ],
      selection: [],
      expandedRowIds: [],
      page: 0,
      size: 25,
      pageSizes: [25, 50, 100],
      ...rest,
    }),
    // eslint-disable-next-line
    []
  );

  // instantiate reducer
  const [{ columns, ...state }, dispatch] = React.useReducer(
    tableStateReducer,
    initialState
  );

  // local state handlers
  const onSortingChange = React.useCallback(([{ columnName, direction }]) => {
    dispatch({
      type: UPDATE_SORT,
      payload: { orderBy: columnName, order: direction, page: 0 },
    });
  }, []);

  const onSearchValueChange = React.useCallback(() => {}, []);

  const onCurrentPageChange = React.useCallback((newPage) => {
    dispatch({ type: UPDATE_PAGINATION, payload: newPage });
  }, []);

  const onPageSizeChange = React.useCallback((newSize) => {
    dispatch({ type: UPDATE_PAGE_SIZE, payload: { page: 0, size: newSize } });
  }, []);

  const onExpandedRowIdsChange = React.useCallback(
    (newExpanded) => {
      if (Array.isArray(newExpanded))
        return dispatch({ type: UPDATE_EXPANDED_ROWS, payload: newExpanded });
      const payload = state.expandedRowIds.includes(newExpanded)
        ? []
        : [newExpanded];
      dispatch({ type: UPDATE_EXPANDED_ROWS, payload });
    },
    [state.expandedRowIds]
  );

  const onSelectionChange = React.useCallback((newSelection) => {
    dispatch({ type: UPDATE_SELECTION, payload: newSelection });
  }, []);

  const onOrderChange = React.useCallback(
    (newOrder) => {
      const reorderEnabled = _.pullAll(newOrder, [
        ...state.fixedLeft,
        ...state.fixedRight,
      ]);
      const update = [
        ...state.fixedLeft,
        ...reorderEnabled,
        ...state.fixedRight,
      ].filter((el) => typeof el === 'string');
      dispatch({ type: UPDATE_COLUMN_ORDER, payload: update });
    },
    [state.fixedLeft, state.fixedRight]
  );

  const onHiddenColumnNamesChange = React.useCallback(
    (newHidden) => {
      const { show, ...columnRest } = columns[newHidden];

      const payload =
        show === false
          ? {
              columns: { [newHidden]: columnRest },
              hiddenColumnNames: _.pull(state.hiddenColumnNames, newHidden),
            }
          : {
              columns: { [newHidden]: { ...columnRest, show: false } },
              hiddenColumnNames: [...state.hiddenColumnNames, newHidden],
            };

      dispatch({ type: UPDATE_COLUMN_VISIBILITY, payload });

      // order columns so that the unhidden column comes last
      const newColumnOrder = [
        ..._.pullAll(state.columnOrder, [newHidden, ...state.fixedRight]),
        newHidden,
        ...state.fixedRight,
      ].filter((el) => typeof el === 'string');

      dispatch({ type: UPDATE_COLUMN_ORDER, payload: newColumnOrder });
    },
    [columns, state]
  );

  const onColumnWidthsChange = (newColumnWidths) => {
    dispatch({ type: UPDATE_COLUMN_WIDTHS, payload: newColumnWidths });
  };

  return {
    columns,
    state,
    handlers: {
      onSortingChange,
      onSearchValueChange,
      onCurrentPageChange,
      onPageSizeChange,
      onExpandedRowIdsChange,
      onSelectionChange,
      onHiddenColumnNamesChange,
      onOrderChange,
      onColumnWidthsChange,
    },
  };
};
