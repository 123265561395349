import React from 'react';
import type { MouseEventHandler } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Box, Collapse, Menu } from '@mui/material';

// components
import Numeral from 'numeral';
import { AnchorButton } from '../AgencyDashSecondaryActionsMenu';
import { AgencyDashboardMenuItem as MenuItem } from '../../menu-options/AgencyDashboardMenuOptions.components';

// utils
import { useMenuState } from '../../../../../components/tables/cell-components/menu/Menu';
import { Show } from '../../../../../components/Show';
import { useAgencyBinderMenuOptionHandlers } from '../../../../../inbox/binders/AgencyBindersMoreOptions';
import {
  BINDER_EXPIRED_STATUS,
  BINDER_RESCINDED,
  BOUND_STATUS,
} from '../../../../_statics/quote.statics';
import { useJQuery } from '../../../../../utils/appUtils';
import type { Binder } from '../../../../accounts/summary/types/binder.types';
import { ProductTypes } from '../../../../../types';

interface AgencyBindersMenuOptions {
  binder: Binder;
}

export const AgencyBindersMenuOptions = ({
  binder,
}: AgencyBindersMenuOptions) => {
  const [isDocumentsExpanded, setIsDocumentsExpanded] = React.useState(true);
  const [isPaymentsExpanded, setIsPaymentsExpanded] = React.useState(true);
  const menuState = useMenuState();
  const menuOptions = useAgencyBinderOptions(binder);
  const menuOptionHandlers = useAgencyBinderMenuOptionHandlers(binder);

  const { isLoaded: isJQueryLoaded } = useJQuery();

  const handleDocumentsClick: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    setIsDocumentsExpanded((value) => !value);
  };

  const handlePaymentsClick: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    setIsPaymentsExpanded((value) => !value);
  };

  const handleClose: MouseEventHandler<HTMLLIElement | HTMLDivElement> = (
    event
  ) => {
    event.stopPropagation();
    menuState.handleClose();
  };

  return (
    <>
      <AnchorButton onClick={menuState.handleOpen} />
      <Menu
        id="more-options-menu"
        anchorEl={menuState.anchorEl}
        keepMounted
        open={menuState.open}
        onClick={handleClose}
        onClose={handleClose}
      >
        <Show when={menuOptions.documents.show}>
          <MenuItem onClick={handleDocumentsClick}>
            {menuOptions.documents.name}
            <Box ml={0.5}>
              {isDocumentsExpanded ? (
                <FontAwesomeIcon icon={light('chevron-up')} />
              ) : (
                <FontAwesomeIcon icon={light('chevron-down')} />
              )}
            </Box>
          </MenuItem>
          <Collapse
            in={isDocumentsExpanded}
            timeout="auto"
            unmountOnExit
            style={{ paddingLeft: '1rem' }}
          >
            <Show when={menuOptions.downloadApplication.show}>
              <MenuItem onClick={menuOptionHandlers.handleDownloadApplication}>
                {menuOptions.downloadApplication.name}
              </MenuItem>
            </Show>
            <Show when={menuOptions.downloadBinder.show}>
              <MenuItem onClick={menuOptionHandlers.handleDownloadBinder}>
                {menuOptions.downloadBinder.name}
              </MenuItem>
            </Show>
            <Show when={menuOptions.downloadInvoice.show}>
              <MenuItem
                onClick={menuOptionHandlers.handleDownloadInvoice}
                disabled={!binder.isInvoiceAvailable}
              >
                {menuOptions.downloadInvoice.name}
              </MenuItem>
            </Show>
            <Show when={menuOptions.downloadReceipt.show}>
              <MenuItem onClick={menuOptionHandlers.handleDownloadReceipt}>
                {menuOptions.downloadReceipt.name}
              </MenuItem>
            </Show>
          </Collapse>
          <Show when={menuOptions.rescindBinder.show}>
            <MenuItem onClick={menuOptionHandlers.handleRescindBinder}>
              {menuOptions.rescindBinder.name}
            </MenuItem>
          </Show>
          <Show when={menuOptions.issuePolicy.show}>
            <MenuItem onClick={menuOptionHandlers.handleIssuePolicy}>
              {menuOptions.issuePolicy.name}
            </MenuItem>
          </Show>
          <Show when={menuOptions.sendBinderToClient.show}>
            <MenuItem
              onClick={menuOptionHandlers.handleSendBinderToPolicyHolder}
            >
              {menuOptions.sendBinderToClient.name}
            </MenuItem>
          </Show>
          <Show when={menuOptions.createQuoteFromBinder.show}>
            <MenuItem onClick={menuOptionHandlers.handleCreateQuoteFromBinder}>
              {menuOptions.createQuoteFromBinder.name}
            </MenuItem>
          </Show>

          <Show when={menuOptions.payments.show}>
            <MenuItem onClick={handlePaymentsClick}>
              {menuOptions.payments.name}
              <Box ml={0.5}>
                {isPaymentsExpanded ? (
                  <FontAwesomeIcon icon={light('chevron-up')} />
                ) : (
                  <FontAwesomeIcon icon={light('chevron-down')} />
                )}
              </Box>
            </MenuItem>
            <Collapse
              in={isPaymentsExpanded}
              timeout="auto"
              unmountOnExit
              style={{ paddingLeft: '1rem' }}
            >
              <MenuItem
                onClick={menuOptionHandlers.handleChargeCreditCardOrBankAccount(
                  ''
                )}
                disabled={!isJQueryLoaded}
              >
                {menuOptions.chargeBankAccount.name}
              </MenuItem>
              <MenuItem
                onClick={menuOptionHandlers.handleChargeCreditCardOrBankAccount(
                  'creditCard'
                )}
                disabled={!isJQueryLoaded}
              >
                {menuOptions.chargeCreditCard.name}
              </MenuItem>
            </Collapse>
          </Show>
        </Show>
      </Menu>
    </>
  );
};

const useAgencyBinderOptions = (binder: Binder) => {
  const canDownloadApplication = !!binder.quoteId && !binder.isPrimePlus;
  const canDownloadBinder =
    !!binder.quoteId && binder.status !== BINDER_RESCINDED;
  const canDownloadInvoice = binder.status !== BINDER_RESCINDED;
  const canDownloadReceipt = binder.isPaid === true;
  const canRescindBinder = binder.status === BOUND_STATUS;
  const canIssuePolicy = !!binder.quoteId && binder.status === BOUND_STATUS;
  const canSendBinderToClient =
    !!binder.quoteId &&
    ![BINDER_EXPIRED_STATUS, BINDER_RESCINDED].includes(binder.status);
  const canCreateQuoteFromBinder = !!binder.quoteId && !binder.isEndorsement;
  const canShowPayments =
    !binder.isPaid &&
    binder.totalPremium !== undefined &&
    binder.totalPremium > 0 &&
    binder.status === BOUND_STATUS &&
    binder.product === ProductTypes.p250;

  return {
    documents: {
      name: 'Documents',
      show: canDownloadApplication || canDownloadBinder || canDownloadInvoice,
    },
    downloadApplication: {
      name: 'Download Application',
      show: canDownloadApplication,
    },
    downloadBinder: {
      name: 'Download Binder',
      show: canDownloadBinder,
    },
    downloadInvoice: {
      name: 'Download Invoice',
      show: canDownloadInvoice,
    },
    downloadReceipt: {
      name: 'Download Receipt',
      show: canDownloadReceipt,
    },
    rescindBinder: {
      name: 'Rescind Binder',
      show: canRescindBinder,
    },
    issuePolicy: {
      name: 'Issue Policy',
      show: canIssuePolicy,
    },
    sendBinderToClient: {
      name: 'Send Binder to Client',
      show: canSendBinderToClient,
    },
    createQuoteFromBinder: {
      name: 'Create Quote from Binder',
      show: canCreateQuoteFromBinder,
    },
    payments: {
      name: 'Payments',
      show: canShowPayments,
    },
    chargeBankAccount: {
      name: 'Charge Bank Account',
    },
    chargeCreditCard: {
      name: 'Charge Credit Card',
    },
  };
};
