import React from 'react';

import { useSelector } from 'react-redux';
import { useQueryParams } from '../../providers/QueryParamsProvider';

import { selectGlobalTeam } from '../../../reducers/team.reducer';

export function useTeamQueryParam() {
  const { query, ...queryParamMethods } = useQueryParams();
  const team = useSelector(selectGlobalTeam);

  const { teamIds: currTeamIdsParam } = query;
  React.useEffect(() => {
    if (currTeamIdsParam != team.id) {
      queryParamMethods.push({}, { query: { ...query, teamIds: team.id } });
    }
  }, [queryParamMethods, team]);
}
