import axios from 'axios';
import { ServiceHandler } from '../utils/api.utils';
import { standardQuerySerializer } from './serializers';

export const searchRisKEngQuestions = ServiceHandler({
  url: '/api/riskmgmt/v1/mrq/search',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const getAllQuestionCategories = ServiceHandler({
  url: '/api/riskmgmt/v1/mrq/categories',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const createRiskEngAccount = ServiceHandler({
  url: '/api/riskmgmt/v1/risk-answer/:accountId',
  method: 'POST',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const getRiskEngAccountByAccountId = ServiceHandler({
  url: '/api/riskmgmt/v1/risk-answer/accountId/:accountId',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const deleteRiskEngAccount = ServiceHandler({
  url: '/api/riskmgmt/v1/risk-answer/:accountId',
  method: 'DELETE',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const updateRiskEngAccountAnsws = ServiceHandler({
  url: '/api/riskmgmt/v1/risk-answer/:id',
  method: 'PUT',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const riskEngQuestionAnswersBulkUpdate = ServiceHandler({
  url: '/api/riskmgmt/v1/risk-answer/bulk',
  method: 'PUT',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const createRiskEngQuestionsTemplate = ServiceHandler({
  url: '/api/riskmgmt/v1/rqt',
  paramsSerializer: (params) => standardQuerySerializer(params),
  method: 'POST',
});

export const getRiskEngQuestionsTemplate = ServiceHandler({
  url: '/api/riskmgmt/v1/rqt/:templateId',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const searchRiskEngQuestionTemplates = ServiceHandler({
  url: '/api/riskmgmt/v1/rqt/search',
  paramsSerializer: (params) => standardQuerySerializer(params),
});

export const deleteRiskEngQuestionTemplate = ServiceHandler({
  url: '/api/riskmgmt/v1/rqt/:templateId',
  paramsSerializer: (params) => standardQuerySerializer(params),
  method: 'DELETE',
});

export const updateRiskEngQuestionTemplate = ServiceHandler({
  url: '/api/riskmgmt/v1/rqt/:templateId',
  paramsSerializer: (params) => standardQuerySerializer(params),
  method: 'PUT',
});

export const loadTemplateToRiskEngAccount = ServiceHandler({
  url: '/api/riskmgmt/v1/risk-answer/load/:accountId',
  paramsSerializer: (params) => standardQuerySerializer(params),
  method: 'POST',
});

export const riskEngAccountReviewComplete = ServiceHandler({
  url: '/api/account/v1/riskmgmt/complete-review/:accountId',
  paramsSerializer: (params) => standardQuerySerializer(params),
  method: 'PUT',
});

export const unsetRiskEngAccount = ServiceHandler({
  url: '/api/account/v1/riskmgmt/unset/:accountId',
  paramsSerializer: (params) => standardQuerySerializer(params),
  method: 'PUT',
});

export const setRiskEngAccount = ServiceHandler({
  url: '/api/account/v1/riskmgmt/set/:accountId',
  paramsSerializer: (params) => standardQuerySerializer(params),
  method: 'PUT',
});

export const getRiskEngPriorityOptions = ServiceHandler({
  url: '/api/account/v1/account/priorityoptions',
});

export const getRiskEngCallReasonOptions = ServiceHandler({
  url: 'api/account/v1/riskmgmt/call-reasons',
});
