import _ from 'lodash';
import React, { useState } from 'react';
import { compose } from 'redux';
import { useSnackbar } from 'notistack';
import { DialogActions, DialogContent, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/styles';

import { useForm, FormContext } from 'react-hook-form-4';
import { delayedEvent } from '../../utils/eventUtils';
import CbButton from '../../components/Buttons/CbButton';
import { deleteBulkUploadErrors } from '../AccountService';

export const deleteBulkUploadError = compose(
  withStyles(({ palette, config }) => ({
    texts: {
      color: palette.primary.contrastText,
      textSize: config.bigCanon,
      weights: config.normal,
    },
    spans: {
      color: palette.common.cowbellBlue,
      textSize: config.greatPrimer,
      weights: config.bold,
    },
  }))
)(({ classes, ...props }) => {
  const [backendError, setBackendError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { ...methods } = useForm();

  function onSubmit(id) {
    deleteBulkUploadErrors({ id })
      .then(() => {
        props.close();
        enqueueSnackbar('Export deleted succesfully!', { variant: 'success' });
        makeRefreshCall();
      })
      .catch((error) => {
        setBackendError(_.get(error, 'response.data'));
      });
  }

  return (
    <>
      <FormContext {...methods}>
        <DialogContent>
          <div style={{ alignItems: 'center', textAlign: 'left' }}>
            <h3 className={classes.texts}>
              Are you sure you want to delete the following failed record?
            </h3>
          </div>
        </DialogContent>
        <FormHelperText className="api-text-error">
          {backendError}
        </FormHelperText>
        <DialogActions>
          <CbButton onClick={props.close} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton
            type="submit"
            onClick={() => {
              onSubmit(props.data.id);
            }}
            styleName="ctaButton"
          >
            Delete
          </CbButton>
        </DialogActions>
      </FormContext>
    </>
  );
});

export const deleteBulkUploadErrorConfig = {
  deleteBulkUploadError: {
    component: deleteBulkUploadError,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
      title: 'Delete Failed Records',
    },
  },
};

function makeRefreshCall() {
  delayedEvent('table-refetch', 200, 'bulkUploadSummary');
}
