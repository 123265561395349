import React from 'react';
import { useTheme, Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'classnames';
import { LegendCheckbox } from '../quotesTab/QuoteDetails';
import { retroMappingShort } from '../../_global/endorsements/endorsement.utils';

const UWComparisonCoveragesRow = ({
  rowData,
  index,
  customComparisonColumn: CustomComparisonColumn,
  customComparisonRowLabel: CustomComparisonRowLabel,
}) => {
  const { palette } = useTheme();
  const classes = useClasses();

  return (
    <div
      style={
        index % 2 === 0
          ? { background: palette.background.default, width: '100%' }
          : { background: palette.background.modal, width: '100%' }
      }
    >
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        style={rowData?.isDelta ? { color: '#fbc000' } : {}}
        className={classes.container}
      >
        {CustomComparisonRowLabel ? (
          <CustomComparisonRowLabel rowData={rowData} />
        ) : (
          <div
            style={{ width: '11rem', textAlign: 'right' }}
            className={classes[makeDynamicClass(rowData)]}
          >
            {rowData?.label}
          </div>
        )}
        <Divider orientation="vertical" flexItem />
        {rowData.row.map((row, idx) => {
          return CustomComparisonColumn ? (
            <CustomComparisonColumn rowData={row} index={idx} />
          ) : (
            <ComparisonColumn rowData={row} index={idx} />
          );
        })}
      </Box>
    </div>
  );
};

export default UWComparisonCoveragesRow;

const ComparisonColumn = ({ rowData, index }) => {
  const classes = useClasses();

  const checkboxAttributes = React.useMemo(() => {
    if (rowData?.mandatory) {
      return { checked: true, variant: 'filled' };
    }
    if (rowData?.isSelected && !rowData?.mandatory) {
      return { checked: true, variant: '' };
    }
    if (!rowData?.isSelected && !rowData?.mandatory) {
      return { checked: false, variant: '' };
    }
  }, [rowData.isSelected, rowData.mandatory]);

  const retroActivePeriod = retroMappingShort[rowData.retroActivePeriod] ?? '-';
  const waitingPeriod =
    rowData.waitingPeriod != null ? `${rowData.waitingPeriod} Hrs` : '-';

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex="0.35"
        style={{ position: 'relative' }}
        className={classes.innerContainer}
      >
        <div style={{ position: 'absolute', left: '-2.5rem' }}>
          <LegendCheckbox
            checked={checkboxAttributes.checked}
            variant={checkboxAttributes.variant}
          />
        </div>
        <div>{rowData.limit}</div>
        <div>{rowData.deductible}</div>
        <div>{waitingPeriod}</div>
        <div>{retroActivePeriod}</div>
      </Box>
      {index === 0 && <Divider orientation="vertical" flexItem />}
    </>
  );
};

const makeDynamicClass = (field) => {
  return clsx({
    greenText:
      field.originalCoverageIsSelected &&
      field.midtermCoverageIsSelected &&
      !field.mandatory &&
      !field.isDelta,
    blueText: field.mandatory,
  });
};

const useClasses = makeStyles(({ palette, config }) => ({
  container: {
    height: '1.56rem',
    '& > div': {
      fontSize: '0.75rem',
      lineHeight: '1.17',
    },
  },
  innerContainer: {
    gap: '0.5rem',
    '& > div': {
      minWidth: '3.5rem',
      textAlign: 'center',
    },
  },
  blueText: {
    color: '#2180e2 !important',
  },
  greenText: {
    color: '#6ac52a',
  },
}));
