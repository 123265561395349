import { commonDateParamMappings } from '../api/common/mappings';
import { trimAndAddAsterisk } from '../utility/QueryUtility';

const commonQuoteMappings = [
  { path: 'page' },
  { path: 'size' },
  { path: 'f', paramKey: 'filter' },
  { path: 'teamIds' },
];

export const quoteServiceMappings = [
  ...commonDateParamMappings,
  ...commonQuoteMappings,
  { path: 'accountId' },
  { path: 'agencyStatus' },
  { path: 'status' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        switch (orderBy) {
          case 'firmographicData.name':
            return `companyName.keyword,${order}`;

          case 'firmographicData.state':
            return `${orderBy}.keyword,${order}`;

          case 'customer':
            return `customerEmail.keyword,${order}`;

          case 'firmographicData.revenue':
          case 'firmographicData.noOfEmployeesAll':
          case 'firmographicData.naicsCode':
          case 'totalPremium':
          case 'premium':
          case 'limit':
          case 'initialRequestData.limit':
          case 'boundOn':
          case 'expiresOn':
          case 'binderExpiresOn':
          case 'effectiveDate':
          case 'endDate':
          case 'naicsCode':
          case 'created':
          case 'isRead':
          case 'isSurplus':
          case 'isReadByCustomer':
          case 'isRenewal':
          case 'modified':
          case 'aggLimit':
          case 'revenue':
          case 'urgencyIndex':
          case 'isEndorsement':
          case 'isPrimePlus':
          case 'isVerified':
          case 'isMigration':
          case 'verifiedBy':
          case 'needByDate':
          case 'isPaid':
          case 'isPremiumFinanced':
          case 'isPremiumFinancePaid':
          case 'isInvoiceSettled':
          case 'initialRequestData.surplusCowbellInsurance':
          case 'surplusCowbellInsurance':
          case 'reportingMonth':
            return `${orderBy},${order}`;

          case 'timestamp':
            return `created,${order}`;
          case 'agent':
            return `agentFirstName.keyword,${order}`;

          default:
            return `${orderBy}.keyword,${order}`;
        }
      }
      return null;
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) =>
      !search ? search : trimAndAddAsterisk(search),
  },
  {
    path: 'accountId.keyword',
  },
  { path: 'archive' },
];

export const quoteSearchV2Mappings = [
  ...commonQuoteMappings,
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }
      return null;
    },
  },
  { path: 'search' },
  { path: 'accountId' },
  { path: 'teamIds' },
  { path: 'effectiveDateAfter' },
  { path: 'effectiveDateBefore' },
];
