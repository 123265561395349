import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

// mui
import { Box as MuiBox } from '@mui/material';

// components
import { FormProvider, useForm } from 'react-hook-form';
import CbButton from '../../../../components/Buttons/CbButton';
import { DocumentDownloader } from '../../../../components/fileUploader/DocumentDownloader';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import DocumentUploadBase from './shared/DocumentUploadBase';
import SignatureHolderConfirmation from './shared/SignatureHolderConfirmation';

// hooks and hocs
import useUploadZone from '../../../../components/fileUploader/v2/hooks/useUploadzone';
import { withShowable } from '../../../_global/lib/withShowable';

import { getAniTemplate } from '../../../../accounts/AccountService';
import CheckBoxLabel from '../CheckBoxLabel';
// utils
import { ANIAffidavitCheckboxLabel } from '../constants/constants';
import { ScreenTitle } from './shared/ScreenTitle';
import useDocUpload from './shared/useDocUpload';
import useGetSubjectivityProgress from './shared/useGetSubjectivityProgress';
import { signedDocumentConfirmationSchema } from './schemas/validationSchemas';
import useGetSubjectivityDocs from './shared/useGetSubjectivityDocs';
import { SubjectivityCompletionDate as SubjectivityCompletionDateBase } from './shared/SubjectivityCompletionDate';
import { useGetBinderInformation } from './shared/useGetBinderInformation';
import useCompleteQuoteSubjectivity from './shared/useCompleteQuoteSubjectivity';
import { useDocSignedDate } from './shared/useDocSignedDate';
import { determineIfStepIsEditable } from '../utils/bindQuoteWorkFlowUtils';
import CowbellReferToUWButton from './shared/referral/CowbellReferToUWButton';

const Box = withShowable(MuiBox);
const SubjectivityCompletionDate = withShowable(SubjectivityCompletionDateBase);

const AdditonalNamedInsured = ({
  goForward,
  goBack,
  completeStep,
  step,
  jumpTo,
  quoteDetails,
  formState,
  sortedPreBindSteps: preBindSteps,
  sortedPreIssueSteps: preIssueSteps = [],
  subjectivities,
  incompletePreBindSteps,
  quoteProgress,
}) => {
  const {
    quoteSubjectivityId,
    completed: isStepCompleted,
    subjectivitySubType,
    subjectivityKey,
  } = subjectivities[step];

  const [isANIAcknowledged, setIsANIAcknowledged] =
    React.useState(isStepCompleted);

  const [uploadedDocs, setUploadedDocs] = React.useState([]);

  const { uploadMultipleDocuments, isUploadingDoc } = useDocUpload({
    quoteDetails,
  });

  const { files, ...uploadZoneParams } = useUploadZone({
    docType: 'Named_Insured_Affidavit',
    maxFiles: 4,
    multiple: true,
  });

  const { handleSubmit, setValue, ...methods } = useForm({
    resolver: yupResolver(
      signedDocumentConfirmationSchema(files ?? uploadedDocs ?? [])
    ),
  });

  const isFormDirty = methods.formState.isDirty;

  const { completeSubjectivity, isCompletingStep } =
    useCompleteQuoteSubjectivity({
      quoteId: quoteDetails.uiData.quoteId,
      productType: quoteDetails.uiData.productType,
    });

  const isStepEditable = determineIfStepIsEditable({
    subjectivitySubType,
    status: quoteProgress.quoteAgencyStatus,
    isAPI: quoteProgress.isAPI,
    productType: quoteDetails.uiData.productType,
  });

  const {
    data: allSubjectivityInformation = {},
    isLoading: subjectivityDataLoading,
    isFetching: isFetchingSubjectivityData,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId,
    quoteId: quoteDetails.id,
    productType: quoteDetails.uiData.productType,
  });

  const { data: subjectivityData } = allSubjectivityInformation;

  const {
    data: uploadedDocuments,
    isInitialLoading: isUploadedDocsLoading,
    isFetching: isFetchingUploadedDocs,
  } = useGetSubjectivityDocs({
    quoteSubjectivityId,
    accountId: quoteDetails.uiData?.accountId,
    uploadedDocs: subjectivityData?.uploadedDocuments,
    isFetchingSubjectivityData,
  });

  React.useEffect(() => {
    setUploadedDocs(uploadedDocuments);
  }, [uploadedDocuments]);

  React.useEffect(() => {
    if (files || isFormDirty) {
      setIsANIAcknowledged(false);
    }
    if (subjectivityData) {
      setValue('signeeFullName', subjectivityData.signeeFullName);
      setValue('signeeTitle', subjectivityData.signeeTitle);
    }
    if (subjectivityData && files) {
      setValue('signeeFullName', '');
      setValue('signeeTitle', '');
    }
  }, [files, isFormDirty, setValue, subjectivityData]);

  const { binder = {}, isLoadingBinderData } = useGetBinderInformation({
    quoteDetails,
    quoteProgress,
  });

  useDocSignedDate({
    formState,
    files,
    setValue,
    uploadedDocuments,
    subjectivityData,
    binder,
  });

  const isLoadingSubjectivityData =
    subjectivityDataLoading ||
    isFetchingSubjectivityData ||
    isUploadedDocsLoading ||
    isFetchingUploadedDocs ||
    isLoadingBinderData;

  if (isLoadingSubjectivityData) {
    return null;
  }

  const onSubmit = (formValues) => {
    if (!files) {
      const uploadedDocumentsPayload = uploadedDocuments.map(
        ({ id }, index) => ({
          docId: id,
          docSignedOn: formValues[`docSignedOn-${index}`],
        })
      );
      const params = {
        quoteSubjectivityId,
        goForward,
        payload: {
          uploadedDocuments: uploadedDocumentsPayload,
          userAttested: isANIAcknowledged,
          ...formValues,
        },
      };
      return completeSubjectivity(params);
    }
    const uploadDocumentParams = {
      formValues,
      files,
      userAttested: isANIAcknowledged,
      quoteDetails,
      completeStep,
      goForward,
      step,
      docTypes: Array(files?.length).fill('Named_Insured_Affidavit'),
      quoteSubjectivityId,
    };

    uploadMultipleDocuments(uploadDocumentParams);
  };

  const handleCheckboxChange = () => {
    setIsANIAcknowledged((prevState) => !prevState);
  };

  const handleGoBack = () => {
    if (
      preIssueSteps[0]?.subjectivityKey === subjectivityKey &&
      preBindSteps.length &&
      incompletePreBindSteps.length
    ) {
      jumpTo(preBindSteps[preBindSteps.length - 1].subjectivityKey);
    } else {
      goBack();
    }
  };

  const handleGoNext = () => {
    if (!files && !uploadedDocs?.length) {
      return goForward();
    }
  };

  return (
    <Box className="contrast-text" width="50%">
      <ScreenTitle
        subjectivitySubType={subjectivitySubType}
        title={allSubjectivityInformation.subjectivityTitle}
      />
      <Box show={!subjectivityData}>
        <h3>{allSubjectivityInformation.subjectivityDescription ?? ''}</h3>
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box>
              <DocumentUploadBase
                uploadZoneParams={uploadZoneParams}
                files={files}
                isUploadingDoc={isUploadingDoc}
                uploadedDocs={uploadedDocuments}
                isStepEditable={isStepEditable}
                docType="ANI"
                datePickerProps={{
                  displayDatePicker: true,
                  min: allSubjectivityInformation?.hydratedInfo
                    ?.signatureValidRangeStartDate,
                  max: allSubjectivityInformation?.hydratedInfo
                    ?.signatureValidRangeEndDate,
                }}
              />
            </Box>

            <Box show={files || subjectivityData}>
              <SignatureHolderConfirmation
                disableFormFields={!isStepEditable || isUploadingDoc}
              />
            </Box>

            <Box paddingBottom="1rem" show={!files && isStepEditable}>
              <h3>Don't have a signed Additional Named Insured Affidavit?</h3>

              <DocumentDownloader
                type=".docx"
                name="Additional Named Insured Affidavit"
                onDownload={getAniTemplate}
              >
                {({ handleDocumentDownload }) => (
                  <CbButton
                    styleName="ctaButton"
                    size="large"
                    onClick={handleDocumentDownload}
                  >
                    Download blank ANI Affidavit
                  </CbButton>
                )}
              </DocumentDownloader>
            </Box>
            <Box show={files || subjectivityData}>
              <Box>
                <CheckBoxBase
                  name="additionalNamedInsuredAffidavit"
                  label={<CheckBoxLabel label={ANIAffidavitCheckboxLabel} />}
                  onChange={handleCheckboxChange}
                  checked={isANIAcknowledged}
                  disabled={
                    (subjectivityData?.userAttested &&
                      !files &&
                      !isFormDirty) ||
                    isUploadingDoc
                  }
                />
              </Box>
            </Box>
          </Box>

          <SubjectivityCompletionDate
            allSubjectivityInformation={allSubjectivityInformation}
            show={isStepCompleted}
          />

          <Box paddingTop="2rem">
            <CbButton styleName="cancel" size="medium" onClick={handleGoBack}>
              Back
            </CbButton>

            <CbButton
              styleName="ctaButton"
              size="medium"
              type="submit"
              onClick={handleGoNext}
              loading={isUploadingDoc || isCompletingStep}
              disabled={
                isUploadingDoc ||
                isCompletingStep ||
                (!isANIAcknowledged && files) ||
                (isStepCompleted && !isANIAcknowledged)
              }
              style={{ marginRight: '0.833rem' }}
            >
              Next
            </CbButton>

            <CowbellReferToUWButton
              quoteId={quoteDetails.id}
              subjectivityId={quoteSubjectivityId}
              productType={quoteDetails.uiData.productType}
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default AdditonalNamedInsured;
