import React from 'react';

import { useSnackbar } from 'notistack';

import { Box, DialogContentText, DialogTitle, Typography } from '@mui/material';

import {
  CircularProgressOverlay,
  DialogContent,
  DialogFooter,
} from '../helpers';

import CbButton from '../../../../components/Buttons/CbButton';
import { Uploadzone } from '../../../../components/fileUploader/v2/UploadZone';
import FileUploadPlaceholderV2 from '../../../../components/fileUploader/v2/file-upload-placeholders/FileUploadPlaceholderV2';

import useUploadZone from '../../../../components/fileUploader/v2/hooks/useUploadzone';

import { uploadDocumentGeneral } from '../../../../api/documents.api';

const PolicyUpload = ({ stepperData, policy, onUploadSuccess, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { files, handleUploads, ...uploadZoneParams } = useUploadZone();
  const [isUpdatingPolicy, setIsUpdatingPolicy] = React.useState(false);

  function handleCancel() {
    props.onClose();
  }

  function handleSubmit() {
    const uploadRequestor = makeUploadRequestor({
      policyId: policy.id,
      accountId: policy.accountId,
      agencyId: policy.agencyId,
      policyNumber: policy.policyNumber,
      created: policy.created,
    });

    setIsUpdatingPolicy(true);
    handleUploads({
      asyncUploadFunc: uploadRequestor,
      onFileUploadError: () => {
        setIsUpdatingPolicy(false);
        enqueueSnackbar(
          'An issue occured while uploading the policy document.',
          {
            variant: 'error',
          }
        );
      },
      onFileUploadSuccess: (res) => {
        onUploadSuccess(
          res,
          () => setIsUpdatingPolicy(false),
          () => setIsUpdatingPolicy(false)
        );
      },
    });
  }

  return (
    <>
      <DialogTitle>
        <Box>Generate PrimeCloud Policy</Box>
      </DialogTitle>
      <DialogContent style={{ position: 'relative' }}>
        <DialogContentText>
          <Typography
            variant="h3"
            align="center"
            style={{ fontSize: '1.667rem' }}
          >
            Upload Policy
          </Typography>
          <Typography
            align="center"
            style={{ fontSize: '1rem', marginTop: '0.3rem' }}
          >
            Upload the policy document you would like to use with this policy
          </Typography>
        </DialogContentText>
        <Box
          margin="0 auto"
          marginTop="1.3334rem"
          width="50%"
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Uploadzone
            {...uploadZoneParams}
            files={files}
            previewProps={{
              disabled: isUpdatingPolicy,
              isRepickDisabled: isUpdatingPolicy,
            }}
            Placeholder={
              <FileUploadPlaceholderV2 accept={uploadZoneParams.accept} />
            }
          />
        </Box>
        {isUpdatingPolicy && <CircularProgressOverlay />}
      </DialogContent>
      <DialogFooter
        stepNumber={stepperData.stepNumber}
        totalSteps={stepperData.steps.length}
        stepLabel="Upload Policy Document"
      >
        <CbButton onClick={handleCancel} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton
          styleName="ctaButton"
          onClick={handleSubmit}
          loading={isUpdatingPolicy}
          disabled={isUpdatingPolicy || !files}
        >
          Upload
        </CbButton>
      </DialogFooter>
    </>
  );
};

export default PolicyUpload;

function makeUploadRequestor({
  policyId,
  accountId,
  agencyId,
  policyNumber,
  created,
}) {
  return function ({ file, axiosConfig }) {
    const formData = new FormData();
    formData.append('file', file);

    const docReq = {
      policyId,
      accountId,
      agencyId,
      docType: 'Policy',
      docName: `Policy_${policyNumber}`,
      isTemp: false,
      createdDate: created,
    };

    formData.append('documentUploadRequest', JSON.stringify(docReq));

    return uploadDocumentGeneral({
      data: formData,
      ...axiosConfig,
    });
  };
}
