import React from 'react';
// components
import { FormContext, useForm } from 'react-hook-form-4';
import _ from 'lodash';
import * as Yup from 'yup';
import { DialogContent, DialogActions, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import { withFormController } from '../../../components/hocs/withFormController';
import CBButton from '../../../components/Buttons/CbButton';
import { PhoneField as PhoneFieldBase } from '../../../components/inputs/PhoneField';
import { toggleModalDirect } from '../../../utils/storeUtils';
import { sendAccounting } from '../_services/BillingService';

// helpers

const PhoneField = withFormController(PhoneFieldBase);
const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
});

const SendToAccounting = ({ classes, data, ...props }) => {
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  };

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    validationSchema: schema,
  });
  const {
    getValues,
    formState: { dirty, isSubmitting },
  } = methods;

  const onSubmit = (formData) => {
    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      policyNumber: data.policyNumber,
      policyId: data.policyId,
      amount: data.amount,
      customerEmail: data.customerEmail,
    };
    return sendAccounting({}, payload)
      .then(() => {
        toggleModalDirect('SendToAccounting', false);
        const Icon = (
          <img
            src="images/email.svg"
            style={{
              width: '2.75rem',
              height: '2.75rem',
              margin: '1.667rem 1.25rem',
            }}
            alt=""
          />
        );
        toggleModalDirect(
          'SendAccountingConfirmation',
          {
            first: formData.firstName,
            last: formData.lastName,
            email: formData.email,
            policyNumber: data.policyNumber,
          },
          {
            maxWidth: 'md',
            title: `Email Sent`,
            buttonText: 'OK',
            iconComponent: Icon,
          }
        );
      })
      .catch(console.error.bind(console));
  };

  const handleCancel = () => {
    toggleModalDirect('SendToAccounting', false);
  };

  return (
    <section className={classes.cardContentContainer}>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent classes={{ root: classes.container }}>
            <Grid
              container
              justifyContent="left"
              className={classes.inputFields}
            >
              <Grid item md={5}>
                <TextField
                  error={_.get(methods, 'errors.firstName.message')}
                  name="firstName"
                  label="First Name"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item md={5} className={classes.autoCompleteContainer}>
                <TextField
                  error={_.get(methods, 'errors.lastName.message')}
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="left"
              className={classes.inputFields}
            >
              <Grid item md={5}>
                <TextField
                  error={_.get(methods, 'errors.email.message')}
                  name="email"
                  label="Email"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item md={5} className={classes.autoCompleteContainer}>
                <PhoneField
                  name="phone"
                  label="Phone Number"
                  required
                  fullWidth
                  placeholder="(___) ___-____"
                  error={_.get(methods, 'errors.phone.message')}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Send"
            />
          </DialogActions>
        </form>
      </FormContext>
    </section>
  );
};

const styles = {
  container: {
    flex: '1 1 auto',
    padding: '0 5rem 0.5rem 5rem',
    'overflow-y': 'visible',
  },
  inputFields: {
    justifyContent: 'space-around',
  },
};

export default withStyles(styles)(SendToAccounting);
