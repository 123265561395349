import React from 'react';
import Axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { withFilterStore } from './withFilterStore';
import { useQueryParams } from '../../providers/QueryParamsProvider';
import { retrieveAuthTokens } from '../../../utils/next/auth.utils';
import { getAxiosBaseURL } from '../../../config/axios/axiosApiConfig';

/**
 * @name FilterState
 * @description FilterState handles filter data-management and passes filterProps and routeProps to its children through a render prop.
 *
 * Required Params:
 * @param {String} id backend id for fetching and saving custom filters that persist in backend
 * @param {Function} render tells FilterState what ui to render, and what props to pass along
 *
 * Optional Params:
 * @param {String} storeId tells FilterState where to save uiSettings in redux. If no storeId is provided,settings will simply not be persisted in redux store.
 * @param {Function} fetchFilterData overrides the default getFilters method
 * @param {Function} deleteFilter overrides the default handleDeleteFilter method
 * @description defaults handle the most common use case: Table Filters. Overrides are provided for using FilterState outside a table.
 *
 * @returns {filterProps, routeProps} invokes the render prop, passing these props to all children.
 * filterProps:
 * @param id same as above, passed through to children
 * @param onSelect same as above, passed through to children
 * @param allFilters the unparsed dataset returned by the fetch function
 * @param tableFilterOptions allFilters without flag { chip: true }
 * @param systemFilters allFilters with flag { system: true }
 * @param chips allFilters with flag { chip: true }
 * @param getFilters default fetch function
 * @param handleDeleteFilters default delete function
 * @param handleNoFilters default function for clearing all filters from url
 * @param onSelect default function for selecting a filter preset from dropdown, passed down from withFilterStore
 */
const FilterState = ({
  render,
  id,
  fetchFilterData,
  deleteFilter,
  onSelect,
  onNoFilters,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { query, replace } = useQueryParams();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { accessToken: token } = retrieveAuthTokens();

  const { data: allFilters, isLoading } = useQuery(
    [`${id}-tableFilters`],
    () => getFilters(),
    {
      keepPreviousData: true,
      staleTime: 300000,
      refetchOnWindowFocus: false,
    }
  );

  const tableFilterOptions = React.useMemo(
    () => allFilters && allFilters.filter((f) => !f.chip),
    [allFilters]
  );
  const systemFilters = React.useMemo(
    () => allFilters && allFilters.filter((f) => f.system),
    [allFilters]
  );
  const chips = React.useMemo(
    () => allFilters && allFilters.filter((f) => f.chip),
    [allFilters]
  );

  const getFilters = React.useCallback(() => {
    if (typeof fetchFilterData === 'function') return fetchFilterData();

    return Axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
      url: `/api/user/v1/filter/name/${id}`,
      baseURL: getAxiosBaseURL(),
    })
      .then(({ data }) => {
        if (data.content) {
          return data.content.map((f) => ({
            label: f.name,
            value: f.id,
            filter: f.filter.replace(/filter/g, 'f'),
            system: f.system,
            chip: f.chip,
          }));
        }
      })
      .catch(() => []);
  }, [fetchFilterData, token, id]);

  const handleDeleteFilter = React.useCallback(
    (option) => {
      if (typeof deleteFilter === 'function') return deleteFilter(option.value);

      return (event) => {
        event.preventDefault();
        event.stopPropagation();

        Axios({
          method: 'DELETE',
          headers: { Authorization: `Bearer ${token}` },
          url: `/api/user/v1/filter/${option.value}`,
        })
          .then(() => {
            enqueueSnackbar('Filter removed', { variant: 'success' });
            queryClient.invalidateQueries([`${id}-tableFilters`]);
          })
          .catch(() => {
            enqueueSnackbar('Failed to remove filter', { variant: 'error' });
          });
      };
    },
    // eslint-disable-next-line
    [allFilters]
  );

  const handleNoFilters = React.useCallback(() => {
    const { f, fn, page, searchAfter, pageRequested, ...rest } = query;
    if (typeof onNoFilters === 'function') {
      onNoFilters();
    }
    replace(location.pathname, { query: rest });
    // eslint-disable-next-line
  }, [query]);

  const filterProps = React.useMemo(
    () => ({
      id,
      allFilters,
      tableFilterOptions,
      systemFilters,
      chips,
      getFilters,
      handleDeleteFilter,
      handleNoFilters,
      onSelect,
    }),
    // eslint-disable-next-line
    [allFilters, handleNoFilters]
  );

  return isLoading ? (
    <div style={{ visibility: 'hidden' }} />
  ) : (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexWrap="wrap"
    >
      {render(filterProps, { q: query, history, location, replace })}
    </Box>
  );
};

export default withFilterStore(FilterState);
