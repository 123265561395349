export const ACCOUNTS_DECLINED_STATUS = 'Declined';
export const ACCOUNTS_QUOTED_STATUS = 'Quoted';
export const ACCOUNTS_ACTIVE_POLICY_STATUS = 'Active Policy';
export const ACCOUNTS_OPEN_STATUS = 'Open';
export const ACCOUNTS_MISSING_DATA_STATUS = 'Missing Data';

const accountsStatics = {
  status: [
    {
      label: ACCOUNTS_DECLINED_STATUS,
      value: ACCOUNTS_DECLINED_STATUS,
    },
    {
      label: ACCOUNTS_QUOTED_STATUS,
      value: ACCOUNTS_QUOTED_STATUS,
    },
    {
      label: ACCOUNTS_ACTIVE_POLICY_STATUS,
      value: ACCOUNTS_ACTIVE_POLICY_STATUS,
    },
    {
      label: ACCOUNTS_OPEN_STATUS,
      value: ACCOUNTS_OPEN_STATUS,
    },
  ],
};

export default accountsStatics;
