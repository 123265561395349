import { createAction, handleActions } from 'redux-actions';

// lodash
import _ from 'lodash';

// external actions
import AT from '../actions/ActionTypes';

export const SET_SCOPES = 'SET_SCOPES';

export const setScopes = createAction(SET_SCOPES);

interface ReducerAction {
  type: string;
  payload: any;
}

type ScopesState = string[];

const initialState: string[] = [];

export const scopesReducer = handleActions(
  {
    [SET_SCOPES]: (state: ScopesState, action: ReducerAction): ScopesState => {
      if (_.isEqual(state, action.payload)) {
        return state;
      }

      return action.payload;
    },

    [AT.USER_LOGOUT]: (
      state: ScopesState,
      action: ReducerAction
    ): ScopesState => {
      return [];
    },
  },
  initialState
);
