import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// react
import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
// mui
import {
  Grid as MuiGrid,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';

// components
import { withFormController } from '../../components/hocs/withFormController';
import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { delayedEvent } from '../../utils/eventUtils';
import { getUrlHost } from '../../utils/appUtils';
import CbButton from '../../components/Buttons/CbButton';
import { HostnameField } from '../../components/inputs/HostnameField';
// actions
import { createCarrier, updateCarrier } from './carrier.service';

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  hostname: Yup.string().required().label('Hostname'),
  name: Yup.string().required().label('Name'),
  prefix: Yup.string().test(
    'len',
    'Prefix should be 3 charactors long',
    (val) => val.length === 3
  ),
});

export const CarrierCreateEdit = ({ data, close }) => {
  const edit = !_.isEmpty(data);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');
  const urlHost = getUrlHost();

  const {
    handleSubmit,
    formState: { isSubmitting, dirty },
    ...methods
  } = useForm({
    defaultValues: {
      name: data.name || '',
      prefix: data.prefix || '',
      description: data.description || '',
      hostname: data.hostname || '',
    },
    validationSchema: schema,
  });

  const onSubmit = (formData) => {
    const { name, prefix, description, hostname } = formData;
    const finalHostName = `${hostname}${urlHost}`;

    const payload = {
      name,
      prefix,
      description,
      hostname: edit ? hostname : finalHostName,
    };

    const api = edit
      ? updateCarrier({ id: data.id }, { hostname: finalHostName })
      : createCarrier({}, payload);

    api
      .then(() => {
        enqueueSnackbar(`Carrier Updated successfully!`, {
          variant: 'success',
        });
        delayedEvent('table-refetch', 1000, 'carrier');
        close();
      })
      .catch((error) => {
        const errorMessage = _.get(error, 'response.data');
        if (typeof errorMessage === 'string') {
          setError(errorMessage);
        } else {
          setError('Unable to update your data, please try again');
        }
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} data-qa="addCarrier">
        <DialogContent style={{ padding: '2rem 5rem' }}>
          <MuiGrid container spacing={2}>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="name"
                label="Name"
                required
                fullWidth
                inputProps={{ required: true }}
                disabled={edit}
              />
            </MuiGrid>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="prefix"
                label="Prefix"
                required
                fullWidth
                inputProps={{ required: true }}
                disabled={edit}
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={2}>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="description"
                label="Description"
                fullWidth
                disabled={edit}
              />
            </MuiGrid>
          </MuiGrid>
          <HostnameField name="hostname" defaultValue={data.hostname} />
        </DialogContent>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText={edit ? 'Update Carrier' : 'Add Carrier'}
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

export const CarrierCreateEditConfig = {
  CarrierCreateEdit: {
    component: CarrierCreateEdit,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
    },
  },
};
