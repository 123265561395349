import * as yup from 'yup';

yup.addMethod(yup.number, 'fromCurrency', function () {
  return this.transform(function (value, originalValue) {
    if (typeof originalValue === 'number') {
      return value;
    }

    if (typeof originalValue == 'string' && originalValue.length > 1) {
      // eslint-disable-next-line
      value = originalValue.replace(/[^\.\-\d]/g, '');
      return Number(value);
    }
  });
});
