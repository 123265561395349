import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  useTheme,
} from '@mui/material';

import CbButton from '../../../../components/Buttons/CbButton';
import { TextField } from '../../../../components/inputs';

const RejectClaimsInvoice = ({ data, ...props }) => {
  const { invoiceData = {}, onReject } = data;
  const theme = useTheme();

  const [userInput, setInput] = React.useState(null);

  const handleChange = React.useCallback((e) => {
    setInput(e.target.value);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    onReject({ reason: userInput });
    props.close();
  };

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center', paddingTop: '2rem' }}>
          Are you sure you want to reject invoice <br /> #
          <span style={{ color: theme.config.colors.cowbellBlue }}>
            {invoiceData.invoiceNumber}
          </span>{' '}
          from {invoiceData.vendorName} for ${invoiceData.amount}?
        </DialogContentText>
        <TextField
          name="reason"
          label="Reason"
          multiline
          minRows={5}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={props.close}>
          Cancel
        </CbButton>
        <CbButton styleName="error" type="submit">
          Reject
        </CbButton>
      </DialogActions>
    </form>
  );
};

export const RejectClaimsInvoiceConfig = {
  RejectClaimsInvoice: {
    component: RejectClaimsInvoice,
    config: {
      fullWidth: true,
      title: 'Reject Invoice',
    },
  },
};
