import { Box, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import CbButton from '../../Buttons/CbButton';
import { deleteAgencyLevel } from '../../../api';
import { manageAPIError } from '../../../utils';
import { agencyLevelTableId } from '../../../console/agencies/quotes/PrimeX/agencyLevels/AgencyLevelsListing';

interface DeleteAgencyLevelModalProps {
  data: {
    levelId: string;
    levelName: string;
  };
  close: () => void;
}

const DeleteAgencyLevelModal = ({
  data: modalData,
  close,
}: DeleteAgencyLevelModalProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleDeleteAgencyLevel = () => {
    setIsLoading(true);
    deleteAgencyLevel(modalData.levelId)
      .then(() => {
        enqueueSnackbar('Agency level deleted.', { variant: 'success' });
        queryClient.invalidateQueries([agencyLevelTableId]);
        close();
      })
      .catch((error) => {
        enqueueSnackbar(manageAPIError(error), { variant: 'error' });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <DialogContent style={{ padding: '2.5rem' }}>
        <Box className="contrast-text" textAlign="center" fontSize="1.2rem">
          Are you sure you want to delete level{' '}
          <strong>{modalData.levelName}</strong>?
        </Box>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" size="medium" onClick={close}>
          Cancel
        </CbButton>

        <CbButton
          styleName="ctaButton"
          size="medium"
          onClick={handleDeleteAgencyLevel}
          loading={isLoading}
          disabled={isLoading}
        >
          Confirm
        </CbButton>
      </DialogActions>
    </>
  );
};

export const DeleteAgencyLevelModalModalConfig = {
  DeleteAgencyLevelModal: {
    component: DeleteAgencyLevelModal,
    config: {
      maxWidth: 'sm',
    },
  },
};
