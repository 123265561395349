import React from 'react';
import { DialogContent } from '@mui/material';

import { ArchiveList } from './components/ArchiveList';

export const archiveQuotes = () => {
  return (
    <>
      <DialogContent style={{ padding: '1rem', paddingBottom: '1rem' }}>
        <ArchiveList />
      </DialogContent>
    </>
  );
};

export const ArchiveQuotesConfig = {
  archiveQuotes: {
    component: archiveQuotes,
    config: {
      maxWidth: 'xl',
      title: 'Search Quotes Archive',
    },
  },
};
