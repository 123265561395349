import { handleActions } from 'redux-actions';

import type { ReducerAction } from '../types/reducer.types';

import AT from '../../actions/ActionTypes';

import { GLOBAL_TEAM_CACHED_RECEIVED } from '../actions/teams.store.actions';
import type { GlobalTeam } from '../types/global-teams.types';

const initialState = {
  cache: [],
};

export const teamsStore = handleActions(
  {
    [GLOBAL_TEAM_CACHED_RECEIVED]: (
      state,
      action: ReducerAction<GlobalTeam>
    ) => {
      return {
        ...state,
        cache: action.payload,
      };
    },

    [AT.USER_LOGOUT]: () => {
      return initialState;
    },
  },
  initialState
);
