import { Box, Button } from '@mui/material';
import { styled, makeStyles } from '@mui/styles';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxIconBase from '@mui/icons-material/CheckBox';

import { Checkbox } from '../../inputs';

export function ActionsButton({ selectedCount, ...props }) {
  const isActive = selectedCount > 0;
  const classes = useButtonStyles({ isActive });

  return (
    <Box className={classes.buttonContainer}>
      <Box className={classes.buttonBadge}>
        <span>{selectedCount}</span>
      </Box>
      <Button
        className={classes.button}
        variant="outlined"
        endIcon={<KeyboardArrowDown />}
        disabled={!isActive}
        {...props}
      >
        Actions
      </Button>
    </Box>
  );
}

const useButtonStyles = makeStyles(({ palette }) => ({
  buttonContainer: {
    display: 'flex',
    maxHeight: '2.7rem',
    margin: '0.3rem 1.2rem 0.3rem 0',
    border: `1px solid ${palette.primary.border}`,
    borderRadius: 5,
    borderColor: (props) =>
      props.isActive ? palette.background.button : palette.primary.border,
  },
  button: {
    border: 'none',
    textTransform: 'uppercase',
  },
  buttonBadge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2.667rem',
    borderRight: 'inherit',

    background: (props) =>
      props.isActive ? palette.background.button : 'inherit',
    color: (props) => (props.isActive ? '#ffffff' : 'inherit'),
  },
}));

export function SelectComponent({ onChange, checked }) {
  return (
    <Box display="inline-block">
      <Checkbox
        onClick={(event) => event.stopPropagation()}
        onChange={onChange}
        checked={checked}
        checkedIcon={<CheckBoxIcon />}
      />
    </Box>
  );
}

const CheckBoxIcon = styled(CheckBoxIconBase)(({ theme }) => ({
  color: theme.config.colors.cowbellBlue,
}));
