import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { compose } from 'redux';
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Moment from 'moment';
import { useSnackbar } from 'notistack';

// mui
import {
  DialogActions,
  DialogContent,
  Typography,
  Box,
  Divider,
  DialogContentText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import CbButton from '../../Buttons/CbButton';
import { SimpleSelect } from '../../inputs';
import { InputLabelBase } from '../../inputs/InputLabelBase';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import WarningIcon from '../../../_assets/svg/Warning.svg';

// utils
import { withFormController } from '../../hocs/forms';
import {
  FileUploadHeader,
  FileUploadSubHeader,
  FileUploadWithFormFields,
  useFileUploadFromOS,
} from '../../fileUploader/FileUpload';
import { withUploadFeature } from '../../fileUploader/withUploadFeature';
import { withUploadFeatureForms } from '../../fileUploader/withUploadFeatureForms';

// apis
import {
  getDocTypes,
  uploadDocumentV2,
  uploadNOC,
} from '../../../api/DocumentsService';
import { useFileTypeIcons } from '../../fileUploader/useFileTypeIcons';
import { getAllCancellationReasons } from '../../../policies/PolicyService';

const Select = withFormController(SimpleSelect);
const TextField = withFormController(TextFieldBase);

const noticeOfCancellationSchema = Yup.object().shape({
  reasonForCancellation: Yup.string()
    .required()
    .label('Reason for cancellation')
    .typeError('Reason for cancellation is a required field'),
  cancellationDate: Yup.string()
    .required()
    .label('Cancellation Date')
    .typeError('Cancellation Date is a required field'),
  docType: Yup.string()
    .required()
    .label('Document Type')
    .typeError('Document type is a required field'),
});

/**
 * Notice of Cancellation modal
 * @param {Object} data - Contains custom info that will be used to render the modal.
 * @param {String} data.accountId - The account ID.
 * @param {String} data.agencyId - The agency ID
 * @param {String} data.policyId - The policy ID
 */
const NoticeOfCancellation = ({ data, ...props }) => {
  const { policyNumber, companyName, effectiveDate, endDate } = data;
  const uiEffectiveDate = Moment(effectiveDate)
    .add(1, 'day')
    .format('YYYY-MM-DD');
  const uiEndDate = Moment(endDate).add(1, 'day').format('YYYY-MM-DD');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [noticeOfCancellationFormValues, setNoticeOfCancellationFormValues] =
    useState(null);
  const fileData = useFileUploadFromOS();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: cancellationReasons = [],
    isLoading: areCancellationReasonsLoading,
  } = useCancellationReasons();
  const { data: docTypes = [], isLoading: areDocTypesLoading } = useDocTypes();

  const uiCancellationReasons = React.useMemo(
    () => constructUiCancellationReasons(cancellationReasons),
    [cancellationReasons]
  );
  const uiDocTypes = React.useMemo(
    () => transformDocTypesForSelect(docTypes),
    [docTypes]
  );

  const handleProcess = (values) => {
    setNoticeOfCancellationFormValues(values);
    setShowConfirmation(true);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleSend = () => {
    const { accountId, agencyId, policyId } = data;
    const docNameData = _.get(fileData, 'fileDetails.fileName', null);
    const docTypeData = _.get(noticeOfCancellationFormValues, 'docType', null);
    const file = _.get(fileData, 'file', null);
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    uploadDocumentV2(
      {
        accountId,
        docType: docTypeData,
        docName: docNameData,
        temp: true,
        agencyId,
      },
      bodyFormData
    )
      .then((res) => {
        const uploadedFile = res.data;
        const { docId, docName, docType, bucketName, s3path } = uploadedFile;
        const { reasonForCancellation, cancellationDate } =
          noticeOfCancellationFormValues;
        const payload = {
          reasonForCancellation,
          cancellationDate,
          noc: {
            docId,
            docName,
            docType,
            bucketName,
            s3Url: s3path,
          },
        };

        uploadNOC({ policyId }, payload)
          .then(() => {
            enqueueSnackbar('Notice of Cancellation sent successfully.', {
              variant: 'success',
            });
            props.close();
          })
          .catch(() =>
            enqueueSnackbar(
              'Something went wrong while sending the Notice of Cancellation',
              { variant: 'error' }
            )
          );
      })
      .catch(() =>
        enqueueSnackbar(
          'Something went wrong while uplpading the Notice of Cancellation document.',
          {
            variant: 'error',
          }
        )
      );
  };

  if (areCancellationReasonsLoading || areDocTypesLoading) return null;

  return showConfirmation ? (
    <NoticeOfCancellationConfirmation
      onSend={handleSend}
      onCancel={handleCancel}
      companyName={companyName}
      policyNumber={policyNumber}
    />
  ) : (
    <NoticeOfCancellationForm
      onProcess={handleProcess}
      fileData={fileData}
      cancellationReasons={uiCancellationReasons}
      docTypes={uiDocTypes}
      companyName={companyName}
      policyNumber={policyNumber}
      minDate={uiEffectiveDate}
      maxDate={uiEndDate}
      {...props}
    />
  );
};

const NoticeOfCancellationForm = ({
  onProcess,
  fileData,
  cancellationReasons,
  docTypes,
  companyName,
  policyNumber,
  minDate,
  maxDate,
  ...props
}) => {
  const {
    handleSubmit,
    formState: { errors },
    ...methods
  } = useForm({
    resolver: yupResolver(noticeOfCancellationSchema),
  });
  const onSubmit = (values) => {
    onProcess(values);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box>
            <GeneralInfo
              companyName={companyName}
              policyNumber={policyNumber}
              cancellationReasons={cancellationReasons}
              minDate={minDate}
              maxDate={maxDate}
            />
            <Divider style={{ marginTop: '1rem' }} />
            <FileUploadArea
              fileData={fileData}
              docTypes={docTypes}
              errors={errors}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={props.close}>
            Cancel
          </CbButton>
          <CbButton type="submit" styleName="ctaButton">
            Process
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const FileUploadArea = compose(
  withUploadFeatureForms(),
  withUploadFeature({
    screenCenter: false,
    hide: false,
    acceptFileTypes: `application/pdf, image/jpeg, image/png,`,
  })
)(({ fileData, docTypes, errors }) => {
  const { file, fileDetails, handleUploadFile, handleReUploadFile } = fileData;
  const RenderFileIcon = useFileTypeIcons(fileDetails?.extension);
  return (
    <FileUploadWithFormFields
      file={file}
      fileDetails={fileDetails}
      errors={errors}
      handleUploadFile={handleUploadFile}
      handleReUploadFile={handleReUploadFile}
      FileUploadHeaderText={FileUploadHeaderText}
      RenderFileIcon={RenderFileIcon}
      docTypeOptions={docTypes}
    />
  );
});

const GeneralInfo = ({
  companyName,
  policyNumber,
  cancellationReasons,
  minDate,
  maxDate,
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" mt={2}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography color="primary">{companyName}</Typography>
        <Typography color="primary">Policy Number {policyNumber}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Select
            name="reasonForCancellation"
            label="Cancellation Reason"
            required
            options={cancellationReasons}
            displayEmpty
            renderValue={renderValue}
            className={classes.select}
          />
        </Box>
        <Box>
          <InputLabelBase indent required>
            Cancellation Date
          </InputLabelBase>
          <TextField
            name="cancellationDate"
            id="date"
            type="date"
            required
            inputProps={{ min: minDate, max: maxDate }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const NoticeOfCancellationConfirmation = ({
  onSend,
  onCancel,
  companyName,
  policyNumber,
}) => {
  return (
    <>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <WarningIcon style={{ width: '3.7rem' }} />
        <DialogContentText style={{ textAlign: 'center' }}>
          {`Are you sure you want to send a notice of cancellation for the Policy Number ${policyNumber} to ${companyName}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={onCancel}>
          Cancel
        </CbButton>
        <CbButton styleName="ctaButton" onClick={onSend}>
          Send
        </CbButton>
      </DialogActions>
    </>
  );
};

const FileUploadHeaderText = () => (
  <Box width="100%" display="flex" justifyContent="center">
    <Box width="75%" display="flex" flexDirection="column" alignItems="center">
      <FileUploadHeader required>Add a NOC Document</FileUploadHeader>
      <FileUploadSubHeader>
        Please upload the notice of cancellation generated from Oden.
      </FileUploadSubHeader>
    </Box>
  </Box>
);

const constructUiCancellationReasons = (reasons) =>
  reasons.map((reason) => ({ label: reason, value: reason }));
const transformDocTypesForSelect = (docTypes) =>
  Object.keys(docTypes).map((key) => ({ value: docTypes[key], label: key }));
const renderValue = (value) => value || 'Please select a reason';

const useCancellationReasons = () =>
  useQuery(
    ['cancellation-reasons'],
    () => getAllCancellationReasons().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      placeholderData: [],
    }
  );

const useDocTypes = () =>
  useQuery(['doc-types'], () => getDocTypes().then((res) => res.data), {
    refetchOnWindowFocus: false,
    placeholderData: [],
  });

const useStyles = makeStyles(({ palette }) => ({
  select: {
    justifyContent: 'left',

    '&.MuiInputBase-input': {
      backgroundColor: palette.background.active,
    },

    '&:focus': {
      borderRadius: 5,
      backgroundColor: palette.background.active,
    },
  },
}));

export const NoticeOfCancellationConfig = {
  NoticeOfCancellation: {
    component: NoticeOfCancellation,
    config: {
      fullWidth: true,
      title: 'Notice of Cancellation',
    },
  },
};
