import React from 'react';
import type { MouseEventHandler } from 'react';

import { Menu } from '@mui/material';

// components
import { AnchorButton } from '../AgencyDashSecondaryActionsMenu';
import { AgencyDashboardMenuItem as MenuItem } from '../../menu-options/AgencyDashboardMenuOptions.components';

// utils
import { useMenuState } from '../../../../../components/tables/cell-components/menu/Menu';
import type { Binder } from '../../../../accounts/summary/types/binder.types';
import AgencyBindersPrimeXMenuOptions from '../../../../../inbox/binders/AgencyBindersPrimeXMenuOptions';

interface AgencyBindersPrimeXMenuProps {
  binder: Binder;
}

const AgencyBindersPrimeXMenu = ({ binder }: AgencyBindersPrimeXMenuProps) => {
  const menuState = useMenuState();

  const handleClose: MouseEventHandler<HTMLLIElement | HTMLDivElement> = (
    event
  ) => {
    event.stopPropagation();
    menuState.handleClose();
  };

  return (
    <>
      <AnchorButton onClick={menuState.handleOpen} />
      <Menu
        id="more-options-menu"
        anchorEl={menuState.anchorEl}
        keepMounted
        open={menuState.open}
        onClick={handleClose}
        onClose={handleClose}
      >
        <AgencyBindersPrimeXMenuOptions binder={binder} MenuItem={MenuItem} />
      </Menu>
    </>
  );
};

export default AgencyBindersPrimeXMenu;
