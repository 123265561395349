import React from 'react';
import Numeral from 'numeral';
import _ from 'lodash';
import { useSelector } from 'react-redux';
// mui
import { Grid as MuiGrid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { CoverageChart } from '../../_global/charts/coverage.chart';
import { calculateCoverageOptions } from '../../_statics/coverage.statics';

export const P100CoveragesReadOnly = ({ data, state }) => {
  const classes = styles();
  const P_100 = useSelector(({ auth }) => auth.P_100);

  const coverageOptions = calculateCoverageOptions(data, {
    onChange: null,
    register: null,
    onClick: null,
    disableSocial: data.socialEngEndorsement,
    disableRansom: data.ransomPaymentEndorsement,
    socialEngEndorsement: data.socialEngEndorsement,
    socialReason: !_.isEmpty(P_100) && P_100.SOCIAL_ENGINEERING.tooltip,
    disableWebsite: data.websiteMediaContentLiabilityEndorsement,
    coverageList: [],
    coverageMapping: P_100,
    state,
  });

  const {
    businessIncomeCoverage,
    ransomPaymentLimit,
    socialEngLimit,
    socialEngDeductible,
    socialEngEndorsement,
    ransomPaymentEndorsement,
    websiteMediaContentLiabilityEndorsement,
    websiteMediaContentLiabilitySubLimit,
  } = data;
  const excludedFromSE = _.get(data, 'firmographicData.excludedFromSE', false);
  return (
    <CoverageChart
      options={coverageOptions}
      readOnly
      social={socialEngEndorsement}
      ransom={ransomPaymentEndorsement}
      exclude={excludedFromSE}
      website={websiteMediaContentLiabilityEndorsement}
      data={data}
      renderSublimit={(chartProps) => (
        <>
          <MuiGrid item sm={7} className={chartProps.classes.gridItem}>
            <span className={classes.indentedLabel}>Selected Sublimit</span>
          </MuiGrid>
          <MuiGrid
            item
            sm={5}
            className={chartProps.classes.gridItem}
            style={{ justifyContent: 'flex-end', paddingRight: 10 }}
          >
            {Numeral(businessIncomeCoverage).format('$0,000')}
          </MuiGrid>
        </>
      )}
      renderRansomlimit={(chartProps) => (
        <>
          {ransomPaymentEndorsement && (
            <>
              <MuiGrid item sm={7} className={chartProps.classes.gridItem}>
                <span className={classes.indentedLabel}>Selected Sublimit</span>
              </MuiGrid>
              <MuiGrid
                item
                sm={5}
                className={chartProps.classes.gridItem}
                style={{ justifyContent: 'flex-end', paddingRight: 10 }}
              >
                {Numeral(ransomPaymentLimit).format('$0,000')}
              </MuiGrid>
            </>
          )}
        </>
      )}
      renderWebsiteLimit={(chartProps) => (
        <>
          {websiteMediaContentLiabilityEndorsement && (
            <>
              <MuiGrid item sm={7} className={chartProps.classes.gridItem}>
                <span className={classes.indentedLabel}>Selected Sublimit</span>
              </MuiGrid>
              <MuiGrid
                item
                sm={5}
                className={chartProps.classes.gridItem}
                style={{ justifyContent: 'flex-end', paddingRight: 10 }}
              >
                {Numeral(websiteMediaContentLiabilitySubLimit).format('$0,000')}
              </MuiGrid>
            </>
          )}
        </>
      )}
      renderSocialLimit={(chartProps) => (
        <>
          {socialEngEndorsement && (
            <>
              <MuiGrid item sm={7} className={chartProps.classes.gridItem}>
                <span className={classes.indentedLabel}>Selected Sublimit</span>
              </MuiGrid>
              <MuiGrid
                item
                sm={5}
                className={chartProps.classes.gridItem}
                style={{ justifyContent: 'flex-end', paddingRight: 10 }}
              >
                {Numeral(socialEngLimit).format('$0,000')}
              </MuiGrid>
            </>
          )}
        </>
      )}
      renderSocialDeductible={(chartProps) => (
        <>
          {socialEngEndorsement && (
            <>
              <MuiGrid item sm={7} className={chartProps.classes.gridItem}>
                <span className={classes.indentedLabel}>
                  Selected Deductible
                </span>
              </MuiGrid>
              <MuiGrid
                item
                sm={5}
                className={chartProps.classes.gridItem}
                style={{ justifyContent: 'flex-end', paddingRight: 10 }}
              >
                {Numeral(socialEngDeductible).format('$0,000')}
              </MuiGrid>
            </>
          )}
        </>
      )}
    />
  );
};

const styles = makeStyles(({ palette }) => ({
  indentedLabel: {
    paddingLeft: '3.4rem',
    color: palette.text.text8,
  },
}));

export default P100CoveragesReadOnly;
