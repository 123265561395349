import Moment from 'moment';
import * as Ramda from 'ramda';

import { determineIfAllowedScopes } from '../../../../../utils/next/auth.utils';
import { _isOneOf } from '../../../../../utils/data.utils';

import { ProductTypes } from '../../../../../types';
import {
  EXPIRED_STATUS,
  IN_FORCE_STATUS,
  ISSUED_STATUS,
  RENEWED_STATUS,
  CANCELLED_STATUS,
  CANCELLED_IN_REINSTATEMENT_PERIOD,
  CANCELLED_PENDING_STATUS,
  FLAT_CANCELLED_STATUS,
} from '../../../../../console/_statics/policy.statics';

import PoliciesService from '../../../../../console/policies/policies.service';
import { VOID_STATUS } from '../../../../../console/_statics/quote.statics';

import type { PolicyTableInfoDto } from '../../../../../types/policy/tables/policy-table-info.dto';
import type { Permissions } from '../../../../auth/permissions';

interface IOptions {
  flags: {
    isFlatCancelFeatureOn: boolean;
    isUndoCancelledPolicyFeatureOn: boolean;
  };
}

export const getPolicyActionsForCowbell = (
  policy: PolicyTableInfoDto,
  scopes: Permissions[],
  options: IOptions
) => {
  const { isFlatCancelFeatureOn, isUndoCancelledPolicyFeatureOn } =
    options.flags;

  const CAN_DOWNLOAD_CANCELLATION_NOTICE =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    policy.status === CANCELLED_STATUS &&
    PoliciesService.hasCancellationNotice(policy);

  const CAN_DOWNLOAD_LOSS_RUNS =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    Boolean(policy.isLossRunsAvailable) &&
    policy.product !== ProductTypes.prime_tech_plus;

  const CAN_CHANGE_BOR =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      policy.status === 'EXPIRED') &&
    !PoliciesService.isRescindable(policy) &&
    !policy.isEndorsement &&
    ![ProductTypes.primecloud, ProductTypes.prime_tech_plus].some(
      Ramda.equals(policy.product)
    );

  const CAN_CHANGE_BILLING = (function IIFE() {
    const today = Moment().startOf('day');
    const effectiveDate = Moment.utc(policy.effectiveDate);

    const isEffectiveDateGreaterThanThirtyDaysAgo =
      today.diff(effectiveDate, 'days') > 30;

    return (
      ![ProductTypes.primecloud, ProductTypes.prime_tech_plus].some(
        Ramda.equals(policy.product)
      ) &&
      !policy.isEndorsement &&
      PoliciesService.isActivePolicyByStatus(policy) &&
      PoliciesService.isPayable(policy) &&
      (!isEffectiveDateGreaterThanThirtyDaysAgo || !policy.isSurplus)
    );
  })();

  const CAN_GENERATE_LOSS_RUNS =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    !policy.isLossRunsAvailable &&
    ![CANCELLED_STATUS, VOID_STATUS].includes(policy.status) &&
    ![ProductTypes.primecloud, ProductTypes.prime_tech_plus].some(
      Ramda.equals(policy.product)
    );

  const CAN_OPTOUT_RENEWAL_PROCESS =
    determineIfAllowedScopes(scopes, ['policy:view', 'account:cowbell']) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    !policy.isEndorsement &&
    policy.product !== ProductTypes.primecloud &&
    !policy.isPrimePlus &&
    policy.product !== ProductTypes.prime_tech_plus;

  const CAN_APPLY_PAYMENT =
    determineIfAllowedScopes(scopes, [
      'account:cowbell',
      'account_profile:manage',
    ]) && !PoliciesService.isNonPremiumBearingEndorsement(policy);

  const CAN_ISSUE_REFUND =
    determineIfAllowedScopes(scopes, [
      'account:cowbell',
      'account_profile:manage',
    ]) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      PoliciesService.isCancelledPolicyByStatus(policy)) &&
    !PoliciesService.isNonPremiumBearingEndorsement(policy);

  const CAN_RESCIND_P100_POLICY = (() => {
    const hasUserPermission = determineIfAllowedScopes(scopes, [
      'account:cowbell',
    ]);

    return (
      hasUserPermission &&
      policy.status !== CANCELLED_STATUS &&
      policy.isNocSent === true &&
      PoliciesService.isPolicyCancellationDateTodayOrInTheFuture(policy) &&
      policy.product === ProductTypes.p100 &&
      !policy.isEndorsement
    );
  })();

  const CAN_RESCIND_P250_POLICY = (() => {
    const hasUserPermission = determineIfAllowedScopes(scopes, [
      'account:cowbell',
    ]);

    return (
      hasUserPermission &&
      policy.status !== CANCELLED_STATUS &&
      policy.isNocSent === true &&
      PoliciesService.isPolicyCancellationDateTodayOrInTheFuture(policy) &&
      policy.product === ProductTypes.p250 &&
      !policy.isEndorsement
    );
  })();

  const CAN_REINSTATE_P100_POLICY = (() => {
    const hasUserPermission = determineIfAllowedScopes(scopes, [
      'account:cowbell',
    ]);

    return (
      hasUserPermission &&
      policy.status === CANCELLED_IN_REINSTATEMENT_PERIOD &&
      policy.product === ProductTypes.p100 &&
      !policy.isEndorsement
    );
  })();

  const CAN_REINSTATE_P250_POLICY = (() => {
    const hasUserPermission = determineIfAllowedScopes(scopes, [
      'account:cowbell',
    ]);

    return (
      hasUserPermission &&
      policy.status === CANCELLED_IN_REINSTATEMENT_PERIOD &&
      policy.product === ProductTypes.p250 &&
      !policy.isEndorsement
    );
  })();

  const CAN_ADD_PREMIUM_FINANCER =
    determineIfAllowedScopes(scopes, [
      'account:cowbell',
      'account_profile:manage',
    ]) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    PoliciesService.isPayable(policy) &&
    !policy.isPremiumFinanced &&
    ![ProductTypes.primecloud, ProductTypes.prime_tech_plus].some(
      Ramda.equals(policy.product)
    );

  const CAN_CHARGE_BANK_ACCOUNT =
    determineIfAllowedScopes(scopes, ['account:cowbell', 'role:admin']) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      PoliciesService.isCancelledPolicyByStatus(policy)) &&
    !PoliciesService.isNonPremiumBearingEndorsement(policy);

  const CAN_CHARGE_CREDIT_CARD =
    determineIfAllowedScopes(scopes, ['account:cowbell', 'role:admin']) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      PoliciesService.isCancelledPolicyByStatus(policy)) &&
    !PoliciesService.isNonPremiumBearingEndorsement(policy);

  const CAN_MARK_CHECK_IN_MAIL =
    determineIfAllowedScopes(scopes, ['account:cowbell', 'role:admin']) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      PoliciesService.isCancelledPolicyByStatus(policy));

  const CAN_CANCEL_POLICY =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    policy.status === IN_FORCE_STATUS &&
    !policy.isErp &&
    !policy.isEndorsement &&
    ![ProductTypes.primecloud, ProductTypes.prime_tech_plus].some(
      Ramda.equals(policy.product)
    );

  const CAN_UPDATE_POLICY =
    determineIfAllowedScopes(scopes, ['account:cowbell', 'root:root'], true) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    policy.product === ProductTypes.p250;

  const CAN_UPDATE_COMMISSION =
    determineIfAllowedScopes(scopes, ['account:cowbell', 'accounts:uw']) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    _isOneOf(policy.status, [ISSUED_STATUS, IN_FORCE_STATUS]) &&
    ![ProductTypes.primecloud, ProductTypes.prime_tech_plus].some(
      Ramda.equals(policy.product)
    );

  const CAN_UPDATE_POLICY_DATES =
    determineIfAllowedScopes(scopes, ['account:cowbell', 'root:root'], true) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    policy.product === ProductTypes.p250;

  const CAN_SEND_NOTICE_OF_CANCELLATION =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    !policy.isEndorsement &&
    policy.status === IN_FORCE_STATUS;

  const CAN_FLAT_CANCEL_POLICY =
    isFlatCancelFeatureOn &&
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    Moment.utc().isSameOrAfter(Moment(policy.effectiveDate)) &&
    policy.product === ProductTypes.p250 &&
    !policy.isEndorsement;

  const CAN_VOID_POLICY =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    ((policy.product === ProductTypes.prime_tech_plus &&
      PoliciesService.isActivePolicyByStatus(policy)) ||
      (PoliciesService.isVoidable(policy) &&
        !(policy.isEndorsement && policy.product === ProductTypes.p250)));

  const CAN_VIEW_RATER =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    determineIfAllowedScopes(scopes, ['policy:view']) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      [RENEWED_STATUS, CANCELLED_STATUS, EXPIRED_STATUS, VOID_STATUS].includes(
        policy.status
      )) &&
    policy.product === ProductTypes.p250 &&
    !policy.isPrimePlus;

  const CAN_HANDLE_PAYMENT_REMINDERS =
    determineIfAllowedScopes(scopes, ['account:cowbell'], true) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    PoliciesService.isPayable(policy) &&
    policy.product !== ProductTypes.prime_tech_plus;

  const CAN_EXPEDITE_BOR =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      policy.status === 'EXPIRED') &&
    PoliciesService.isRescindable(policy) &&
    !policy.isEndorsement;

  const CAN_RESCIND_BOR =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      policy.status === 'EXPIRED') &&
    PoliciesService.isRescindable(policy) &&
    !policy.isEndorsement;

  const CAN_REGENERATE_INVOICE =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    !PoliciesService.isNonPremiumBearingEndorsement(policy) &&
    policy.product !== ProductTypes.prime_tech_plus;

  const CAN_REGENERATE_POLICY_DOCUMENTS =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    ![ProductTypes.primecloud, ProductTypes.prime_tech_plus].some(
      Ramda.equals(policy.product)
    );

  const CAN_UPDATE_AGENT_INFO =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    PoliciesService.isActivePolicyByStatus(policy) &&
    ![ProductTypes.primecloud, ProductTypes.prime_tech_plus].some(
      Ramda.equals(policy.product)
    );

  const CAN_EXTEND_PAYMENT_DUE_DATE =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    policy.product === ProductTypes.p250 &&
    !policy.isNocSent &&
    policy.status === IN_FORCE_STATUS;

  const CAN_EXTEND_NOC_DUE_DATE =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    policy.isNocSent &&
    policy.status === IN_FORCE_STATUS;

  const CAN_START_NOC_PROCESS =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    !policy.isNocSent &&
    policy.status === IN_FORCE_STATUS &&
    !policy.isPrimePlus;

  const CAN_STOP_NOC_PROCESS =
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    policy.isNocSent &&
    (policy.status === CANCELLED_PENDING_STATUS ||
      policy.status === CANCELLED_IN_REINSTATEMENT_PERIOD);

  const CAN_VIEW_NOC_OPTIONS =
    !policy.isEndorsement &&
    !policy.isPaid &&
    policy.product !== ProductTypes.prime_tech_plus &&
    (CAN_EXTEND_PAYMENT_DUE_DATE ||
      CAN_EXTEND_NOC_DUE_DATE ||
      CAN_START_NOC_PROCESS ||
      CAN_STOP_NOC_PROCESS);

  const CAN_DELETE_POLICY =
    determineIfAllowedScopes(
      scopes,
      ['account:cowbell', 'policy:manage'],
      true
    ) &&
    !PoliciesService.isActivePolicyByStatus(policy) &&
    policy.status !== RENEWED_STATUS;

  const CAN_DOWNLOAD_APPLICATION =
    determineIfAllowedScopes(scopes, ['policy:view']) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      policy.status === RENEWED_STATUS) &&
    !policy.isPrimePlus &&
    policy.product !== ProductTypes.prime_tech_plus;

  const CAN_DOWNLOAD_FINANCING_AGREEMENT =
    determineIfAllowedScopes(scopes, ['policy:view']) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      policy.status === RENEWED_STATUS) &&
    policy.isPremiumFinanced &&
    policy.isDocumentAvailable;

  const CAN_DOWNLOAD_INVOICE =
    determineIfAllowedScopes(scopes, ['policy:view']) &&
    (policy.isDocumentAvailable ||
      (policy.product === ProductTypes.primecloud &&
        policy.isInvoiceAvailable)) &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      policy.status === RENEWED_STATUS) &&
    !PoliciesService.isNonPremiumBearingEndorsement(policy);

  const CAN_DOWNLOAD_POLICY_DOCUMENTS =
    determineIfAllowedScopes(scopes, ['policy:view']) &&
    policy.isDocumentAvailable &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      policy.status === RENEWED_STATUS);

  const CAN_DOWNLOAD_RECEIPT =
    determineIfAllowedScopes(scopes, ['policy:view']) &&
    policy.isDocumentAvailable &&
    policy.isPaid &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      policy.status === RENEWED_STATUS);

  const CAN_GENERATE_POLICY_CERTIFICATE =
    determineIfAllowedScopes(scopes, ['policy:view']) &&
    policy.isCertificateAvailable &&
    (PoliciesService.isActivePolicyByStatus(policy) ||
      policy.status === RENEWED_STATUS) &&
    policy.product !== ProductTypes.prime_tech_plus;

  const CAN_GENERATE_ERP =
    policy.product === ProductTypes.p250 &&
    (policy.status === CANCELLED_STATUS || policy.status === EXPIRED_STATUS) &&
    !policy.isEndorsement;
  // ((policy.status === CANCELLED_STATUS &&
  //     Moment.utc().diff(policy.cancellationDate, 'days') <= 60) ||
  //     (policy.status === EXPIRED_STATUS &&
  //       Moment.utc().diff(policy.endDate, 'days') <= 60));

  const CAN_GENERATE_RENEWAL_SUBMISSION =
    determineIfAllowedScopes(scopes, ['renewals:manage']) &&
    policy.status === IN_FORCE_STATUS &&
    !policy.isPrimePlus &&
    !policy.isEndorsement &&
    policy.product !== ProductTypes.prime_tech_plus;

  const CAN_MUTE_RENEWAL_REMINDER = (function IIFE() {
    const today = Moment.utc().startOf('D');
    const endDate = Moment.utc(policy.endDate);
    const daysFromRenewal = Moment.duration(endDate.diff(today)).asDays();

    const isP250OpenForEarlyRenewal =
      policy.product === ProductTypes.p250 &&
      daysFromRenewal <= 120 &&
      daysFromRenewal >= 0;

    const isP100OpenForEarlyRenewal =
      policy.product === ProductTypes.p100 && policy.isOpenForRenewal;

    return (
      determineIfAllowedScopes(scopes, ['account:cowbell']) &&
      (isP100OpenForEarlyRenewal || isP250OpenForEarlyRenewal) &&
      [IN_FORCE_STATUS, RENEWED_STATUS, EXPIRED_STATUS].includes(
        policy.status
      ) &&
      !policy.isEndorsement
    );
  })();

  const CAN_REINSTATE =
    determineIfAllowedScopes(scopes, ['account:cowbell', 'role:admin'], true) &&
    policy.product === ProductTypes.p250 &&
    !policy.isPrimePlus &&
    !policy.isEndorsement &&
    [VOID_STATUS, CANCELLED_STATUS, FLAT_CANCELLED_STATUS].includes(
      policy.status
    ) &&
    !checkIfCancelledForNonPayment(policy);

  const CAN_GENERATE_RENEWAL_QUOTE =
    determineIfAllowedScopes(scopes, ['renewals:manage']) &&
    policy.status === IN_FORCE_STATUS &&
    policy.product === ProductTypes.p250 &&
    !policy.isEndorsement &&
    !policy.isPrimePlus;

  const CAN_UNDO_CANCELLED_POLICY =
    isUndoCancelledPolicyFeatureOn &&
    determineIfAllowedScopes(scopes, ['account:cowbell']) &&
    !policy.isEndorsement &&
    [ProductTypes.p100, ProductTypes.p250].some(Ramda.equals(policy.product)) &&
    [
      VOID_STATUS,
      CANCELLED_STATUS,
      FLAT_CANCELLED_STATUS,
      CANCELLED_IN_REINSTATEMENT_PERIOD,
    ].includes(policy.status);

  const CAN_UPDATE_BILLING_ADMIN =
    policy.product === ProductTypes.p250 ||
    policy.product === ProductTypes.p100;

  return {
    CAN_VOID_POLICY,
    CAN_DOWNLOAD_CANCELLATION_NOTICE,
    CAN_DOWNLOAD_LOSS_RUNS,
    CAN_CHANGE_BOR,
    CAN_CHANGE_BILLING,
    CAN_GENERATE_LOSS_RUNS,
    CAN_OPTOUT_RENEWAL_PROCESS,
    CAN_APPLY_PAYMENT,
    CAN_ISSUE_REFUND,
    CAN_ADD_PREMIUM_FINANCER,
    CAN_CHARGE_BANK_ACCOUNT,
    CAN_CHARGE_CREDIT_CARD,
    CAN_MARK_CHECK_IN_MAIL,
    CAN_CANCEL_POLICY,
    CAN_UPDATE_POLICY,
    CAN_UPDATE_COMMISSION,
    CAN_UPDATE_POLICY_DATES,
    CAN_SEND_NOTICE_OF_CANCELLATION,
    CAN_FLAT_CANCEL_POLICY,
    CAN_VIEW_RATER,
    CAN_HANDLE_PAYMENT_REMINDERS,
    CAN_EXPEDITE_BOR,
    CAN_RESCIND_BOR,
    CAN_REGENERATE_INVOICE,
    CAN_REGENERATE_POLICY_DOCUMENTS,
    CAN_UPDATE_AGENT_INFO,
    CAN_VIEW_NOC_OPTIONS,
    CAN_EXTEND_NOC_DUE_DATE,
    CAN_EXTEND_PAYMENT_DUE_DATE,
    CAN_START_NOC_PROCESS,
    CAN_STOP_NOC_PROCESS,
    CAN_DELETE_POLICY,
    CAN_DOWNLOAD_APPLICATION,
    CAN_DOWNLOAD_FINANCING_AGREEMENT,
    CAN_DOWNLOAD_INVOICE,
    CAN_DOWNLOAD_POLICY_DOCUMENTS,
    CAN_DOWNLOAD_RECEIPT,
    CAN_GENERATE_POLICY_CERTIFICATE,
    CAN_GENERATE_ERP,
    CAN_RESCIND_P100_POLICY,
    CAN_RESCIND_P250_POLICY,
    CAN_REINSTATE_P100_POLICY,
    CAN_REINSTATE_P250_POLICY,
    CAN_GENERATE_RENEWAL_SUBMISSION,
    CAN_MUTE_RENEWAL_REMINDER,
    CAN_REINSTATE,
    CAN_GENERATE_RENEWAL_QUOTE,
    CAN_UNDO_CANCELLED_POLICY,
    CAN_UPDATE_BILLING_ADMIN,
  };
};

const checkIfCancelledForNonPayment = (policy: PolicyTableInfoDto) => {
  if (
    policy.status === CANCELLED_STATUS &&
    !!policy.nocProcess &&
    policy.nocProcess !== 'STOP'
  ) {
    return true;
  }

  return false;
};
