import React from 'react';
import _ from 'lodash';

// components
import { useFormContext } from 'react-hook-form';
import { TypeAheadBase } from '../../../../components/inputs/autocomplete/TypeAheadBase';
import { InputLabelBase } from '../../../../components/inputs/InputLabelBase';

// services
import { getAgentsInAdmin } from '../../../../api/UserService';
import { fetchAgencies } from '../../../../agencies/AgencyService';
import { getTeamsForAgentInAgency } from '../../../../teams/TeamsService';

// utils
import {
  getSelectItemAgency,
  reduceForSelect,
} from '../../../admin/users/UsersUtils';
import { NoOptionsText } from '../../../../components/error/NoOptionsText';
import { ControlledTypeAhead } from '../../../../components/inputs/autocomplete/ControlledTypeAhead';
import { useCowbellTranslations } from '../../../../i18n/translations';

export const AccountLookupAdminFields = () => {
  const { setValue, watch } = useFormContext();
  const selectedAgency = watch('agency');
  const selectedAgent = watch('agent');
  const { translations } = useCowbellTranslations(['Agency', 'Agent']);

  const handleAgencySearch = React.useCallback(({ input }, callback) => {
    fetchAgencies({ search: input, size: 100 }).then(({ data }) => {
      callback(reduceForSelect(data.content, getSelectItemAgency));
    });
    // eslint-disable-next-line
  }, []);

  const agencyOnChange = React.useCallback(
    (nextAgency) => {
      setValue('agent', null);
      setValue('team', null);
      setValue('agency', nextAgency);
    },
    // eslint-disable-next-line
    [setValue]
  );

  return (
    <div>
      <InputLabelBase
        htmlFor="agency"
        indent
        required
        style={{ paddingTop: '.5rem' }}
      >
        {translations.Agency}
      </InputLabelBase>
      <ControlledTypeAhead
        name="agency"
        onFetch={handleAgencySearch}
        onChange={agencyOnChange}
        value={selectedAgency}
        defaultValue={selectedAgency}
        defaultOptions={[selectedAgency]}
        placeholder="Search for an agency by name"
        required
        fetchOnMount
      />

      <InputLabelBase
        htmlFor="agent"
        indent
        required
        style={{ paddingTop: '2.2rem' }}
      >
        {translations.Agent}
      </InputLabelBase>
      <AgentLookupField selectedAgency={selectedAgency} />

      <InputLabelBase
        htmlFor="teams"
        indent
        required
        style={{ paddingTop: '2.2rem' }}
      >
        Teams
      </InputLabelBase>
      <TeamsLookupField selectedAgent={selectedAgent} />
    </div>
  );
};

export const AgentLookupField = ({ selectedAgency = {}, ...props }) => {
  const { setValue, watch, register } = useFormContext();
  const selectedAgent = watch('agent');

  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');

  const handleAgentSearch = React.useCallback(
    ({ input }, callback) => {
      if (!selectedAgency.value) {
        return [];
      }
      getAgentsInAdmin({ search: input, agencyId: selectedAgency.value }).then(
        ({ data }) => {
          callback(
            data.content.map((agent) => ({
              label: `${agent.firstName} ${agent.lastName} (${agent.email})`,
              value: agent.email,
              meta: {
                ...agent,
              },
            }))
          );
        }
      );
      // eslint-disable-next-line
    },
    [selectedAgency]
  );

  React.useEffect(() => {
    function onSearchFinish(results) {
      if (results.length) {
        setOptions(() => results);
      }
    }

    if (selectedAgency.value && !selectedAgent) {
      handleAgentSearch({ input: '' }, onSearchFinish);
    }
    // eslint-disable-next-line
  }, [selectedAgency.value]);

  const handleChange = React.useCallback((nextValue) => {
    setValue('team', null);
    setValue('agent', nextValue);
    // eslint-disable-next-line
  }, []);

  const agentFieldPlaceholder = _.get(selectedAgency, 'label')
    ? `Search for an agent in ${_.get(selectedAgency, 'label')}`
    : `Select an agent by name`;

  const onTypeahead = _.debounce((value) => {
    setInputValue(value);
  }, 300);

  return (
    <TypeAheadBase
      name="agent"
      onChange={handleChange}
      onFetch={handleAgentSearch}
      onOptionsChange={setOptions}
      onInputChange={onTypeahead}
      inputValue={inputValue}
      value={selectedAgent}
      options={options}
      placeholder={agentFieldPlaceholder}
      required
      disabled={!selectedAgency.value}
      isOptionEqualToValue={isOptionEqualToValue}
      filterSelectedOptions={false}
      ref={register('agent', { required: true })}
      fetchOnMount
      noOptionsText={
        inputValue ? (
          <NoOptionsText label="Agent not found" />
        ) : (
          'Type to search'
        )
      }
      {...props}
    />
  );
};

export const TeamsLookupField = ({ selectedAgent = {}, ...props }) => {
  const { register, setValue, watch } = useFormContext();
  const selectedTeam = watch('team');

  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');

  const handleTeamSearch = React.useCallback(
    ({ input }, callback) => {
      if (_.isUndefined(_.get(selectedAgent, 'meta.id'))) {
        return null;
      }
      getTeamsForAgentInAgency({
        search: input,
        userId: _.get(selectedAgent, 'meta.id'),
      }).then(({ data }) => {
        callback(
          data.content.map((agency) => ({
            label: agency.name,
            value: agency.id,
          }))
        );
      });
      // eslint-disable-next-line
    },
    [selectedAgent]
  );

  React.useEffect(() => {
    function onSearchFinish(results) {
      if (results.length) {
        setOptions(() => results);
      }
    }

    if (_.get(selectedAgent, 'value') && !selectedTeam) {
      handleTeamSearch({ input: '' }, onSearchFinish);
    }
    // eslint-disable-next-line
  }, [_.get(selectedAgent, 'value')]);

  const handleChange = React.useCallback((nextValue) => {
    setValue('team', nextValue);
    // eslint-disable-next-line
  }, []);

  const teamFieldPlaceholder = _.get(selectedAgent, 'meta.firstName')
    ? `Search for a team for agent ${_.get(selectedAgent, 'meta.firstName')}`
    : `Select a team`;

  return (
    <TypeAheadBase
      name="team"
      onChange={handleChange}
      onFetch={handleTeamSearch}
      onOptionsChange={setOptions}
      onInputChange={setInputValue}
      inputValue={inputValue}
      value={selectedTeam}
      options={options}
      placeholder={teamFieldPlaceholder}
      required
      disabled={!_.get(selectedAgent, 'value')}
      isOptionEqualToValue={isOptionEqualToValue}
      filterSelectedOptions={false}
      ref={register('team', { required: true })}
      fetchOnMount
      {...props}
    />
  );
};

function isOptionEqualToValue(option, value) {
  return option.value === value.value;
}
