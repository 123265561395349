import React from 'react';
import { Box } from '@mui/material';

import BindQuoteWorkflowHeader from '../bind-quote/BindQuoteWorkflowHeader';

import useGetQuoteProgress from '../bind-quote/hooks/useGetQuoteProgress';
import type { QuoteDetailsResponse } from '../bind-quote/hooks/useGetQuoteDetails';
import useGetQuoteDetails from '../bind-quote/hooks/useGetQuoteDetails';

import { StepperProvider } from '../bind-quote/StepperProvider';
import type { StepperProviderChildrenProps } from '../bind-quote/types/bindWorkflow.types';
import type { StepperScreenProps } from '../bind-quote/StepperScreen';
import StepperScreen from '../bind-quote/StepperScreen';
import ErrorPage from '../bind-quote/ErrorPage';
import BindQuoteWorkflowSkeleton from '../bind-quote/BindQuoteWorkflowSkeleton';
import BindQuoteStepper from '../bind-quote/bind-quote-stepper/BindQuoteStepper';

import { cowbellBindSteps } from './constants/constants';
import { PERSONA_TYPES } from '../../_statics/persona.statics';
import type { ProductType } from '../../../types';
import { COUNTRY_MAPPING } from '../../../i18n/i18n.language-config';
import { useGetPlatformRegion } from '../../../utils';

type CowbellBindQuoteWorkflowProps = {
  quoteId: string;
  productType: ProductType;
  startStep?: string;
};

function CowbellBindQuoteWorkflow({
  quoteId,
  productType,
  startStep = cowbellBindSteps.UPLOAD_BIND_ORDER,
}: CowbellBindQuoteWorkflowProps) {
  const region = useGetPlatformRegion();

  const {
    data: quoteDetails = {} as QuoteDetailsResponse,
    isInitialLoading: quoteDetailsLoading,
    isError: isQuoteDetailsError,
  } = useGetQuoteDetails({ productType, quoteId });

  const {
    data: quoteProgress = {},
    isInitialLoading: quoteProgressLoading,
    isError: isQuoteProgressError,
  } = useGetQuoteProgress({
    quoteId,
    persona: PERSONA_TYPES.COWBELL,
    productType,
  });

  if (quoteDetailsLoading || quoteProgressLoading) {
    return <BindQuoteWorkflowSkeleton />;
  }

  if (isQuoteDetailsError || isQuoteProgressError) {
    return <ErrorPage />;
  }

  const BIND_FLOW_LANGUAGE =
    COUNTRY_MAPPING[
      quoteDetails?.firmographicData?.country as keyof typeof COUNTRY_MAPPING
    ] ?? region;

  return (
    <>
      <BindQuoteWorkflowHeader quoteDetails={quoteDetails.uiData} />
      <StepperProvider
        quoteDetails={quoteDetails}
        startStep={startStep}
        {...quoteProgress}
      >
        {({
          formState,
          formHandlers,
          stepperState,
          incompleteSteps,
        }: StepperProviderChildrenProps) => (
          <>
            <Box display="flex">
              <StepperScreen
                stepperState={stepperState}
                formState={formState}
                formHandlers={formHandlers}
                quoteDetails={quoteDetails}
                incompleteSteps={incompleteSteps}
                language={BIND_FLOW_LANGUAGE}
                {...(quoteProgress as Partial<StepperScreenProps> as any)}
              />

              <BindQuoteStepper
                stepperState={stepperState}
                quoteDetails={quoteDetails}
                {...quoteProgress}
              />
            </Box>
          </>
        )}
      </StepperProvider>
    </>
  );
}

export default CowbellBindQuoteWorkflow;
