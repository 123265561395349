import {
  ServiceHandlerFactory,
  transformParams,
} from '../../../utils/serviceUtils';
import { utcISO } from '../../../utils/date.utils';

function serializeDates({ createdDate, effectiveDate, ...params }) {
  return {
    ...params,
    createdDate: utcISO(createdDate),
    effectiveDate: utcISO(effectiveDate),
  };
}

export const patchQuote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/:id',
    method: 'put',
  },
});
export const declineQuote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/decline/:id',
    method: 'put',
  },
});
export const lostQuote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/lost/:id',
    method: 'put',
  },
});
export const updateNeedBy = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/update/needBy?quoteId=:id&needBy=:date',
    method: 'put',
  },
});

/**
 * @param limit
 * @param revenue
 * @param createdDate optional
 */
export const getCarrierProfiles = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/carrier/profiles',
    paramsSerializer: transformParams([serializeDates]),
  },
});
