import React from 'react';
import { Box, styled } from '@mui/material';
import UploadIconBase from '../../../../_assets/svg/Upload.svg';

const FileUploadPlaceholderV2 = ({ accept }) => {
  const acceptedFileExtensions = React.useMemo(() => {
    let temp = accept;
    if (typeof accept === 'string') {
      temp = accept.replaceAll(',', '').split(' ');
    }
    return temp
      .map((fileType) => fileType.split('/')[1])
      .join(', ')
      .toUpperCase();
  }, [accept]);

  return (
    <>
      <DnDContainer>
        <DnDText>
          Drag and Drop a file here
          <br /> to upload; or Click to browse for file
        </DnDText>
        <UploadIcon alt="cloud-upload" />
      </DnDContainer>
      <Box mt={1} display="flex" justifyContent="center" width="100%">
        <SupportedFilesText>
          Supported File Types: {acceptedFileExtensions}
        </SupportedFilesText>
      </Box>
    </>
  );
};

export default FileUploadPlaceholderV2;

const DnDContainer = styled('div')(({ theme: { palette } }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'center',
  margin: '0 auto',
  height: '5.833rem',
  width: '25rem',
  borderRadius: '5px',
  border: `1px solid ${palette.primary.main}`,
  cursor: 'pointer',
}));

const UploadIcon = styled(UploadIconBase)({
  width: '5.4167rem',
  marginLeft: '1.9rem',
  fill: '#2180e2',
});

const DnDText = styled('p')(({ theme: { palette } }) => ({
  marginLeft: '2.3rem',
  maxWidth: '12rem',
  fontSize: '1rem',
  lineHeight: 1.33,
  color: palette.text.secondary,
}));

const SupportedFilesText = styled('p')(({ theme: { config, palette } }) => ({
  fontSize: config.textSizes.normal,
  color: palette.text.secondary,
  margin: 0,
}));
