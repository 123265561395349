import React from 'react';
import { StepperModal } from '../../../components/modals/v2/StepperModal/StepperModal';
import { ConfirmStep } from './ConfirmStep';
import { SummaryStep } from './SummaryStep';
import { SelectionStep } from './SelectionStep';
import { ErrorHandlerWrapper } from './ErrorHandlerWrapper';

const ModalSteps = {
  SUMMARY: 'summary',
  SELECTION: 'selection',
  CONFIRM: 'confirm',
};

const startStep = ModalSteps.SUMMARY;
const initialSteps = Object.values(ModalSteps);

const MigrateAgencyUsersModal = ({ data, ...props }) => {
  const { agencyId, selection = [], onSelectionChange = () => {} } = data;
  const [localSelection, setLocalSelection] = React.useState(selection);

  const onClose = () => {
    onSelectionChange(localSelection);
    props.close();
  };

  const handleCloseAndClearSelection = () => {
    props.close();
    onSelectionChange([]);
  };

  const onLocalSelectionChange = (id) => {
    const nextSelection = localSelection.filter((user) => user.id !== id);
    setLocalSelection(nextSelection);
  };

  return (
    <ErrorHandlerWrapper selection={selection} {...props}>
      <StepperModal startStep={startStep} initialSteps={initialSteps}>
        <SummaryStep
          name={ModalSteps.SUMMARY}
          onCancel={onClose}
          selection={localSelection}
          onSelectionChange={onLocalSelectionChange}
        />
        <SelectionStep
          name={ModalSteps.SELECTION}
          agencyId={agencyId}
          selection={localSelection}
          isAccountRealm={localSelection[0].role.realm === 'a'}
          onClose={handleCloseAndClearSelection}
        />
        <ConfirmStep
          name={ModalSteps.CONFIRM}
          onClose={handleCloseAndClearSelection}
        />
      </StepperModal>
    </ErrorHandlerWrapper>
  );
};

export const MigrateAgencyUsersModalConfig = {
  MigrateAgencyUsersModal: {
    component: MigrateAgencyUsersModal,
    config: {
      title: 'Migrate Users',
      maxWidth: 'md',
    },
  },
};
