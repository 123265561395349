import type { AxiosRequestConfig, AxiosResponse } from 'axios';

// lodash
import _ from 'lodash';

import { Api } from '../config/axiosConfig';

/* types */
type ServiceArgument<D = any> =
  | string
  | number
  | Partial<AxiosRequestConfig<D>>;

export interface ServiceConfig extends AxiosRequestConfig {
  url: string;
}

const defaultStaticConfig: Partial<AxiosRequestConfig> = {
  method: 'get',
  // paramsSerializer: standardQuerySerializer,
  // transformRequest: [ignoreUndefineds],
};

/**
 * @name ServiceHandler
 * @description Creates and returns a static endpoint definition (function) for reuse.
 * @param staticConfig Any available axios config options
 * @param instance A valid axios instance
 * @returns function(...args?: string | number, axiosConfig?: AxiosConfig{})
 * @example const getTodo = ServiceHandler({ url: '/api/todos/:id' })
 * @example await getTodo('123456');
 */
export const ServiceHandler = <Request = any, Response = any>(
  staticConfig: ServiceConfig,
  instance = Api
) => {
  return (...args: ServiceArgument<Request>[]) => {
    const config = args.reduce(deriveHandlerConfig, {
      ...defaultStaticConfig,
      ...staticConfig,
      url: staticConfig.url,
    });

    return instance.request<
      Response,
      AxiosResponse<Response, Request>,
      Request
    >(config);
  };
};

function deriveHandlerConfig(
  acc: ServiceConfig,
  arg: ServiceArgument,
  index: number,
  array: ServiceArgument[]
): ServiceConfig {
  if (typeof arg === 'string' || typeof arg === 'number') {
    return {
      ...acc,
      url: acc.url.replace(/:[a-z]+/i, arg.toString()),
    };
  }

  const isLast: boolean = index === array.length - 1;
  if (isLast && typeof arg === 'object') {
    return { ...acc, ...arg };
  }
  throw new Error(`Service handler: Invalid argument at position ${index}`);
}

export function applyQueryConfig(MAP: any[], params: any) {
  const nextParams: any = {};

  for (let i = 0; i < MAP.length; i += 1) {
    const config = MAP[i];

    // key in the final payload
    let paramKey = config.path;
    if (config.paramKey) {
      paramKey =
        typeof config.paramKey === 'function'
          ? config.paramKey(params)
          : config.paramKey;
    }

    // final value, transformed or not
    const value =
      typeof config.transform === 'function'
        ? config.transform(params)
        : params[config.path];

    // gives us a way to omit undefineds from final nextParams
    if (value || _.isNumber(value)) {
      nextParams[paramKey] = value;
    }
  }

  return nextParams;
}

/**
 * @name responseStringTransformer
 * @deprecated
 */
export function responseStringTransformer(data: string) {
  return JSON.parse(data);
}

export function serializeStandardResponse(data: string) {
  return {
    data: JSON.parse(data),
  };
}

export function stringifyRequestBody(data: any) {
  return JSON.stringify(data);
}
