import { commonDateParamMappings } from '../../api/common/mappings';
import { Api } from '../../config/axiosConfig';
import { ServiceHandlerFactory } from '../../utils/serviceUtils';

const commissionsApiConfig = {
  isCancelable: true,

  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'agencyBilled' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        const newOrder = order || 'asc';
        const newOrderBy = orderBy === 'modified' ? 'transactionDate' : orderBy;

        switch (orderBy) {
          case 'commissionablePremium':
          case 'commissionRate':
          case 'commissionsAmount':
          case 'transactionDate':
          case 'effectiveDate':
          case 'agencyBilled':
          case 'modified':
          case 'totalPremium':
          case 'netDue':
            return `${newOrderBy},${newOrder}`;
          default:
            return newOrderBy ? `${newOrderBy}.keyword,${newOrder}` : null;
        }
      },
    },
    ...commonDateParamMappings,
  ],
};

export const getCommissionStatements = ServiceHandlerFactory({
  axios: {
    url: `/api/commissions/v1/statements`,
  },
  ...commissionsApiConfig,
});

export const getCommisionAggregations = ServiceHandlerFactory({
  axios: {
    url: `/api/commissions/v1/aggregate`,
  },
  ...commissionsApiConfig,
});

export const getCommissionStatementUrl = ServiceHandlerFactory({
  axios: {
    url: `/api/docs/v1/commissions?year=:year&month=:month`,
  },
  isCancelable: true,
});

export function downloadAWSDoc(url) {
  return Api.get(url, { responseType: 'blob' });
}

export const getBankAccountDetails = ServiceHandlerFactory({
  axios: {
    url: `/api/commissions/v1/:agencyId/ach`,
  },
  isCancelable: true,
});
