import React from 'react';
// material ui
import { Button, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
// components
import SearchBox from '../../../components/main/common/SearchBox';
import { ActionBar } from '../../../components/tables/tables-new/base/action-bar.table';

const styles = ({ config }) => {
  return {
    headingContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: config.weights.bold,
      fontSize: config.textSizes.petite,
      paddingBottom: '0.833rem',
      color: config.colors.darkGrey,
      textAlign: 'left',
      '& .MuiButton-label': {
        fontWeight: config.weights.bold,
        fontSize: config.textSizes.normal,
      },
      '& h4': {
        margin: 0,
        fontStyle: 'italic',
        fontSize: config.textSizes.tertia,
        fontWeight: config.weights.bold,
      },
      '& h6': {
        margin: 0,
        fontSize: config.textSizes.petite,
        lineHeight: 1.5,
        fontWeight: config.weights.bold,
      },
    },
    add: {
      color: config.colors.cowbellBlue,
      padding: '0 0 1rem 0',
    },
    close: {
      color: config.colors.grey7,
      textAlign: 'right',
      padding: '0 0 1rem 1rem !important',
    },
    divider: {
      backgroundColor: config.colors.cowbellBlue,
      marginTop: '0.5rem',
    },
    searchBoxNaked: {
      '& > input': {
        backgroundColor: config.colors.almostWhite,
        color: config.colors.darkBlack,
        border: 'unset',
        borderRadius: 0,
      },
      '& > input:focus': {
        backgroundColor: config.colors.almostWhite,
        color: config.colors.darkBlack,
        border: 'unset',
        borderRadius: 0,
      },
    },
  };
};

export const NotesHeader = withStyles(styles)(
  ({
    classes,
    account,
    setAdd,
    handleNotes,
    title,
    search,
    searchFunc,
    readOnly,
  }) => {
    const onSearch = (value) => {
      searchFunc(value);
    };

    const onClose = () => {
      handleNotes(false);
    };

    return (
      <>
        <div className={classes.headingContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '25rem',
            }}
          >
            <h4>{title}</h4>
            <h6>{account.name || account.companyName}</h6>
          </div>
          <div>
            {!readOnly && (
              <Button
                className={classes.add}
                onClick={() => setAdd(true)}
                disableRipple
              >
                <AddCircleOutlineOutlinedIcon style={{ cursor: 'default' }} />
                <span>ADD</span>
              </Button>
            )}
            <Button className={classes.close} onClick={onClose} disableRipple>
              Close
            </Button>
          </div>
        </div>
        {search && (
          <ActionBar
            render={() => (
              <SearchBox
                placeholder="Search Notes"
                classes={classes}
                handleSearch={onSearch}
              />
            )}
          />
        )}
        <Divider className={classes.divider} />
      </>
    );
  }
);
