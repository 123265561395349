import { ServiceHandlerFactory } from '../../utils/serviceUtils';

export const signUpWizer = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/training`,
    method: 'post',
  },
  isCancelable: true,
});

export const accountDetail = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/account:accountId`,
    method: 'get',
  },
  isCancelable: true,
});

export const agencyDetail = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/agency`,
    method: 'agency',
  },
  isCancelable: true,
});
