import React from 'react';

import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { AuthenticatedRoute } from './routes/AuthenticatedRoute';

import ModalContainer from './components/modals/ModalContainer';
import { withModalState } from './components/modals/v2/withModalState';
import { DialogBase } from './components/modals/DialogBase';

import { useConsoleVersion } from './components/hooks/queries';
import { useAuth } from './components/hooks/useAuth';

// statics
import { PUBLIC_ROUTE_MAPPING } from './mappings/routes/routeMappings';
import { ConsoleResolver } from './console/ConsoleResolver';

/**
 * @name RouteResolver
 * @description A layer of logic that is intended to re-route users to unauthenticated routes based on various conditions.
 */
export const RouteResolver = () => {
  const location = useLocation();

  const { accountBasePath, isAuthenticated } = useAuth();

  // get & set console version
  useConsoleVersion();

  // redirect logged in users to dashboard
  if (isAuthenticated && location.pathname === '/login') {
    return <Redirect to={`/${accountBasePath}/dashboard`} />;
  }

  // redirect root routes to login
  if (location.pathname === '/') {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Switch>
        {PUBLIC_ROUTE_MAPPING.map((route, index) => (
          <Route key={`pr-${index}`} {...route} />
        ))}
        <AuthenticatedRoute path="/:persona" component={ConsoleResolver} />
      </Switch>

      <ModalContainer />
      <ConsoleDialogs />
    </>
  );
};

const ConsoleDialogs = withModalState(DialogBase);
