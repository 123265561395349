import React, {
  cloneElement,
  createElement,
  isValidElement,
  useContext,
} from 'react';

import {
  TableFooter as MuiTableFooter,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from '@mui/material';

import { withStyles } from '@mui/styles';

import { TablePagination } from './pagination.table';
import { TableContext } from './context.table';

const styles = ({ palette }) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    background: palette.background.lighter,
  },

  rowRoot: {
    background: palette.background.lighter,
  },
});

export const TableFooter = withStyles(styles)(
  ({ render, children, classes, show = true, ...props }) => {
    const {
      onPageChange,
      page,
      rowsPerPage,
      rowsPerPageOptions,
      changePageSize,
      totalRows,
    } = useContext(TableContext);

    if (!show) {
      return null;
    }

    if (typeof render === 'function') {
      return (
        <MuiTableFooter>
          <MuiTableRow className={classes.row}>
            <MuiTableCell>{render(props)}</MuiTableCell>
          </MuiTableRow>
        </MuiTableFooter>
      );
    }

    return (
      <MuiTableFooter>
        <MuiTableRow className={classes.rowRoot}>
          <MuiTableCell>
            <div className={classes.row}>
              {React.Children.map(children, (child) =>
                isValidElement(child)
                  ? cloneElement(child, { ...child.props, ...props })
                  : createElement('div', { ...child.props, ...props }, child)
              )}
            </div>
          </MuiTableCell>
          <MuiTableCell>
            <TablePagination
              onPageChange={onPageChange}
              changePageSize={changePageSize}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              totalRows={totalRows}
            />
          </MuiTableCell>
        </MuiTableRow>
      </MuiTableFooter>
    );
  }
);

TableFooter.displayName = 'TableFooter';
