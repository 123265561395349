import React from 'react';
// components
import { AdditionalInsured } from './AdditionalInsured';
import { PaymentSummary } from './PaymentSummary';
import { EndorsementHeader } from './EndorsementHeader';
import InsuredInformation from './InsuredInformation';
import { ProductTypes } from '../../../types';

const P250PolicyMidTermEndorsementDrawer = ({ policy = {} }) => {
  const { effectiveDate, endDate, address, additionalInsureds, totalPremium } =
    policy;

  const paymentData = { additionalInsureds, totalPremium, address };
  return (
    <>
      <EndorsementHeader
        effectiveDate={effectiveDate}
        productType={ProductTypes.p250}
        endDate={endDate}
        number={policy.quoteNumber}
        label="Quote#"
      />
      <InsuredInformation insuredInfo={address} />
      <AdditionalInsured additionalInsureds={additionalInsureds} />
      <PaymentSummary data={paymentData} />
    </>
  );
};

export default P250PolicyMidTermEndorsementDrawer;
