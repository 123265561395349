import { useHistory } from 'react-router-dom';

// mui
import { useTheme } from '@mui/styles';

import { AGENCY_DASHBOARD_VIEWS } from '../agency-dashboard.statics';

import CommissionsIconLight from '../../../../_assets/svg/agency-dashboard/light/CommissionsIconLight.svg';
import StartNewQuoteIconLight from '../../../../_assets/svg/agency-dashboard/light/StartNewQuote.svg';
import RenewalIconLight from '../../../../_assets/svg/agency-dashboard/light/RenewalIconLight.svg';
import BindQuoteIconLight from '../../../../_assets/svg/agency-dashboard/light/BindQuoteIconLight.svg';
import BindQuoteIconDark from '../../../../_assets/svg/agency-dashboard/dark/BindQuoteIconDark.svg';
import CommissionsIconDark from '../../../../_assets/svg/agency-dashboard/dark/CommissionsIconDark.svg';
import RenewalIconDark from '../../../../_assets/svg/agency-dashboard/dark/RenewalIconDark.svg';
import StartNewQuoteIconDark from '../../../../_assets/svg/agency-dashboard/dark/StartNewQuoteIconDark.svg';
import { Show } from '../../../../components/Show';

import { LargeActionButton } from '../blocks/LargeActionButton';
import { ActionButtonText } from '../blocks/ActionButtonText';
import { Allow } from '../../../../components/auth/Allow';
import { useAmplitude as useAnalytics } from '../../../../providers/AmplitudeProvider';
import { VIEW_COMMISSIONS_EVENT } from '../../../../analytics/events/view-commissions.event';

export const DashboardPrimaryActionsModule = () => {
  const theme = useTheme();
  const history = useHistory();
  const analytics = useAnalytics();

  const handleViewCommissions = () => {
    analytics.track(VIEW_COMMISSIONS_EVENT);
    history.push('/agency/manage/commissions');
  };

  return (
    <div>
      <div className="flex" style={{ gap: '1rem', marginTop: '1rem' }}>
        <LargeActionButton
          name={AGENCY_DASHBOARD_VIEWS.NEW_QUOTE}
          accentColor={theme.agencyDash.buttons.startNewQuote}
        >
          <Show when={theme.palette.mode === 'light'}>
            <StartNewQuoteIconLight style={iconStyle} />
          </Show>
          <Show when={theme.palette.mode === 'dark'}>
            <StartNewQuoteIconDark style={iconStyle} />
          </Show>

          <ActionButtonText>Start a</ActionButtonText>
          <ActionButtonText>new quote</ActionButtonText>
        </LargeActionButton>

        <LargeActionButton
          name={AGENCY_DASHBOARD_VIEWS.RENEW_POLICY}
          accentColor={theme.agencyDash.buttons.renewPolicy}
        >
          <Show when={theme.palette.mode === 'light'}>
            <RenewalIconLight style={iconStyle} />
          </Show>
          <Show when={theme.palette.mode === 'dark'}>
            <RenewalIconDark style={iconStyle} />
          </Show>

          <ActionButtonText>Renew</ActionButtonText>
          <ActionButtonText>a policy</ActionButtonText>
        </LargeActionButton>
      </div>
      <div className="flex" style={{ gap: '1rem', marginTop: '1rem' }}>
        <LargeActionButton
          name={AGENCY_DASHBOARD_VIEWS.BIND_QUOTE}
          accentColor={theme.agencyDash.buttons.bindQuote}
        >
          <Show when={theme.palette.mode === 'light'}>
            <BindQuoteIconLight style={iconStyle} />
          </Show>
          <Show when={theme.palette.mode === 'dark'}>
            <BindQuoteIconDark style={iconStyle} />
          </Show>

          <ActionButtonText>Bind</ActionButtonText>
          <ActionButtonText>a quote</ActionButtonText>
        </LargeActionButton>
        <Allow allow={['commissions:view']}>
          <LargeActionButton
            name="commissions"
            onClick={handleViewCommissions}
            accentColor={theme.agencyDash.buttons.viewCommissions}
          >
            <Show when={theme.palette.mode === 'light'}>
              <CommissionsIconLight style={iconStyle} />
            </Show>
            <Show when={theme.palette.mode === 'dark'}>
              <CommissionsIconDark style={iconStyle} />
            </Show>

            <ActionButtonText>View</ActionButtonText>
            <ActionButtonText>Commissions</ActionButtonText>
          </LargeActionButton>
        </Allow>
      </div>
    </div>
  );
};

const iconStyle = {
  width: '45%',
  height: 'inherit',
  maxHeight: '40px',
};
