import React from 'react';
import { DialogContent, Box } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import {
  AgencyBillField,
  ClusterOrAggregatorAutocomplete,
  ClusterOrAggregatorField,
  DialogFooter,
  dialogPadding,
  HardlinkCheckbox,
  InheritanceMessage,
  IsAppointedCheckbox,
  SectionTitle,
  useClusterOrAggregatorNullifier,
  WholesaleField,
  YesNoField,
} from './helpers';
import CbButton from '../../components/Buttons/CbButton';
import { FormGridBuilder } from '../../components/forms/FormGridBuilder';
import { additionalBrokerFee } from '../../utils/validationSchemas/accounts.schema';
import { addNewAgency } from '../AgencyService';
import { delayedEvent } from '../../utils/eventUtils';
import { getUrlHost } from '../../utils/appUtils';
import LanguageForm, {
  useLanguageFormState,
} from '../../i18n/forms/LanguageForm';
import { FormLanguageProvider } from '../../i18n/forms/FormLanguageProvider';
import {
  LANGUAGE_COUNTRY_MAPPING,
  languages,
} from '../../i18n/i18n.language-config';
import { globalSchema } from '../../components/globalValidations';
import LanguageCurrencyFieldBase from '../../components/inputs/LanguageCurrencyField';
import { withFormController } from '../../components/hocs/forms';
import { useCowbellTranslations } from '../../i18n/translations';

const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);

export const Step3 = ({ stepperProps, onCancel, ...aggregateFormState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const languageFormState = useLanguageFormState({
    language: aggregateFormState.aggregateFormData.region,
    options: { validatePhone: true, phoneFieldName: 'billingContactPhone' },
  });
  const { aggregateFormData, updateAggregateFormData } = aggregateFormState;
  const { goBack, goForward } = stepperProps;
  const domain = getUrlHost();
  const { translations } = useCowbellTranslations(['Agency']);

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(
      deriveSchema(
        languageFormState.language,
        translations,
        languageFormState.languageFormSchema.billingContactPhone
      )
    ),
    defaultValues: {
      ..._.pick(aggregateFormData, [
        'billingContactEmail',
        'billingContactName',
        'billingContactPhone',
        'isWholesale',
        'muteCommunications',
        'brokerHandlesCompliance',
        'agencyBilled',
        'clusterOrAggregator',
        'commission',
        'maxFee',
        'additionalBrokerFee',
        'paymentTerms',
      ]),
    },
  });

  const [clusterOrAggregator, agencyBilled] = methods.watch([
    'clusterOrAggregator',
    'agencyBilled',
  ]);

  useClusterOrAggregatorNullifier({ clusterOrAggregator, aggregateFormState });

  const formConfig = React.useMemo(() => {
    return getStep3FormConfig({
      clusterOrAggregator,
      agencyBilled,
      aggregateFormState,
      translations,
    });
    // eslint-disable-next-line
  }, [
    agencyBilled,
    aggregateFormState,
    clusterOrAggregator,
    aggregateFormData,
  ]);

  const onSubmit = (formData) => {
    const onSuccess = () => {
      const isValidInput = validateAutocomplete({
        aggregateFormState,
        clusterOrAggregator,
      });

      const payload = {
        ..._.omit(aggregateFormData, [
          'meta',
          'onSuccess',
          'clusterOrAggregatorName',
          'region',
        ]),
        ...formData,
        hostname: `${aggregateFormData.hostname}${domain}`,
        hardLinkOnQuote: aggregateFormData.hardLinkOnQuote,
        isAppointed: aggregateFormData.isAppointed,
        region: aggregateFormData.region ?? languages['en-US'],
        country:
          LANGUAGE_COUNTRY_MAPPING[
            aggregateFormData.region ?? languages['en-US']
          ],
      };

      if (isValidInput) {
        updateAggregateFormData(formData);
        return addNewAgency(payload)
          .then(() => {
            onCancel();
            delayedEvent('table-refetch', 1000, 'agencies');
          })
          .catch((error) => {
            const feinError = _.get(
              error,
              'response.data.errors[0].defaultMessage'
            );
            const genericError = _.get(
              error,
              'response.data',
              'There was a problem with your request, please try again'
            );
            enqueueSnackbar(feinError ?? genericError, { variant: 'error' });
          });
      }
    };

    const isNonStandardCompliance = validateCompliance(formData);

    if (isNonStandardCompliance) {
      updateAggregateFormData({ ...formData, onSuccess });

      return goForward();
    }

    return onSuccess();
  };

  const onError = () => {
    validateAutocomplete({ aggregateFormState, clusterOrAggregator });
  };

  const onPrevious = () => {
    updateAggregateFormData(methods.getValues());
    goBack();
  };

  return (
    <FormLanguageProvider language={languageFormState.language}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <DialogContent style={dialogPadding}>
            <SectionTitle>Additional {translations.Agency} Info</SectionTitle>
            <InheritanceMessage aggregateFormData={aggregateFormData} />
            <FormGridBuilder formConfig={formConfig} />
            <Box display="flex" justifyContent="center">
              <HardlinkCheckbox {...aggregateFormState} />
              <IsAppointedCheckbox {...aggregateFormState} />
            </Box>
          </DialogContent>
          <DialogFooter message="Step: 3 of 3">
            <CbButton onClick={onCancel} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton onClick={onPrevious} styleName="ctaButton">
              Previous
            </CbButton>
            <CbButton styleName="ctaButton" type="submit">
              Submit
            </CbButton>
          </DialogFooter>
        </form>
      </FormProvider>
    </FormLanguageProvider>
  );
};

export const getStep3FormConfig = ({
  clusterOrAggregator,
  agencyBilled,
  aggregateFormState,
  translations,
}) => {
  const { aggregateFormData } = aggregateFormState;
  const { region } = aggregateFormData;
  const isUSRegion = region === languages['en-US'];

  return [
    {
      name: 'billingContactName',
      label: 'Billing Contact Name',
      required: true,
      gridItemProps: { xs: 6 },
    },
    {
      name: 'billingContactPhone',
      label: 'Billing Contact Phone',
      required: true,
      gridItemProps: { xs: 6, style: { marginTop: '0.75rem' } },
      component: LanguageForm.PhoneField,
    },
    {
      name: 'billingContactEmail',
      label: 'Billing Contact Email',
      required: true,
      type: 'email',
      gridItemProps: { xs: 6 },
    },
    {
      name: 'placeholder',
    },
    {
      name: 'isWholesale',
      label: 'Wholesale/Retail',
      required: true,
      gridItemProps: { xs: 6 },
      component: WholesaleField,
    },
    {
      name: 'muteCommunications',
      label: 'Mute Communications/Email? (Pre-Issue)',
      required: true,
      gridItemProps: { xs: 6 },
      component: YesNoField,
    },
    ...(isUSRegion
      ? [
          {
            name: 'brokerHandlesCompliance',
            label: 'Broker Handles Compliance?',
            required: true,
            gridItemProps: { xs: 6 },
            component: YesNoField,
          },
          {
            name: 'placeholder',
          },
        ]
      : []),
    {
      name: 'agencyBilled',
      label: `Direct Bill or ${translations.Agency} Bill?`,
      required: true,
      gridItemProps: { xs: 6 },
      component: AgencyBillField,
    },
    {
      name: 'clusterOrAggregator',
      label: 'Assign Cluster or Aggregator',
      gridItemProps: { xs: 6 },
      component: ClusterOrAggregatorField,
    },
    {
      name: agencyBilled ? 'paymentTerms' : 'placeholder',
      label: 'Payment Terms',
      required: true,
      type: 'number',
      gridItemProps: { xs: 6 },
    },
    {
      component: ClusterOrAggregatorAutocomplete,
      aggregateFormState,
      clusterOrAggregator,
      required: true,
      gridItemProps: { xs: 6 },
      defaultValue: {
        label: aggregateFormData.clusterOrAggregatorName,
        value: aggregateFormData.clusterId || aggregateFormData.aggregatorId,
      },
    },
    {
      name: 'commission',
      label: 'Commission %',
      required: true,
      gridItemProps: { xs: 6 },
      inputProps: {
        min: 0,
        max: 99,
        step: 0.01,
      },
      type: 'number',
    },
    {
      name: 'maxFee',
      label: 'Max Fee',
      required: true,
      gridItemProps: { xs: 3, style: { marginTop: '0.75rem' } },
      component: LanguageCurrencyField,
    },
    {
      name: 'additionalBrokerFee',
      label: 'Additional Broker Fee',
      required: true,
      gridItemProps: { xs: 3, style: { marginTop: '0.75rem' } },
      component: LanguageCurrencyField,
    },
  ];
};

export const step3Validations = (language, translations) => {
  const isUSRegion = language === languages['en-US'];
  const USValidations = {
    brokerHandlesCompliance: Yup.boolean()
      .required()
      .label('Broker Handles Compliance'),
  };
  return {
    billingContactName: Yup.string().required().label('Billing Contact Name'),
    billingContactEmail: Yup.string().required().label('Billing Contact Email'),
    isWholesale: Yup.boolean().required().label('Wholesale/Retail'),
    muteCommunications: Yup.boolean().required().label('Mute Communications'),
    agencyBilled: Yup.boolean().required().label(`${translations.Agency} Bill`),
    commission: Yup.number()
      .required()
      .min(0, 'Commission must not be less than $0')
      .max(99, 'Commission must be less than or equal to $99')
      .typeError('Commission must be a number'),
    additionalBrokerFee,
    maxFee: Yup.number()
      .label('Max Fee')
      .fromCurrency()
      .min(0, 'Max fee cannot be less than $0')
      .max(1000, 'Max fee must be less than or equal to $1,000')
      .required(),
    paymentTerms: Yup.number().label('Payment Terms').when('agencyBilled', {
      is: true,
      then: Yup.number().required(),
    }),
    ...(isUSRegion && USValidations),
  };
};

const deriveSchema = (language, translations, phoneSchema) => {
  return Yup.object().shape({
    ...step3Validations(language, translations),
    billingContactPhone: phoneSchema,
  });
};

const validateAutocomplete = ({ aggregateFormState, clusterOrAggregator }) => {
  const { aggregateFormData, updateAggregateFormData } = aggregateFormState;
  const { clusterId, aggregatorId, meta } = aggregateFormData;

  if (!clusterOrAggregator) {
    return true;
  }

  const isCluster = clusterOrAggregator === 'CLUSTER';

  if (isCluster && !clusterId) {
    updateAggregateFormData({
      meta: {
        ...meta,
        clusterOrAggregatorError: 'Cluster is a required field',
      },
    });
    return false;
  }

  if (!isCluster && !aggregatorId) {
    updateAggregateFormData({
      meta: {
        ...meta,
        clusterOrAggregatorError: 'Aggregator is a required field',
      },
    });
    return false;
  }

  return true;
};

const validateCompliance = (formData) => {
  if (formData.agencyBilled && !formData.brokerHandlesCompliance) {
    return true;
  }
  if (!formData.agencyBilled && formData.brokerHandlesCompliance) {
    return true;
  }
  return false;
};
