/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import * as Ramda from 'ramda';

import Moment from 'moment';
import _ from 'lodash';

import { getShortDateString, getShortDateTimeFromUtc } from 'utils/csvUtils';
import { delayedEvent, PubSub } from 'utils/eventUtils';

import {
  Box,
  CircularProgress,
  IconButton as MuiIconButton,
  Snackbar,
  Slide,
} from '@mui/material';
import { styled, makeStyles, withStyles, useTheme } from '@mui/styles';

import CloseIcon from '@mui/icons-material/Close';

import SearchBox from 'components/main/common/SearchBox';
import { FilterModal } from 'components/modals/FilterModal';

import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import withRouter from 'components/hocs/withRouter';
import { withBackfill } from 'components/hocs/withBackfill';

import { UrlDateRangePicker } from 'components/inputs/DateRangePicker';
import PolicyStatsBar from 'components/PolicyStatsBar';

import { NoKnownLossLetterIndicator } from 'components/Reusable';

import { ExpandIcon } from '../components/tables/tables-new/variations/expand-icon.table';
import { ActionBar } from '../components/tables/tables-new/base/action-bar.table';
import { TableContext } from '../components/tables/tables-new/base/context.table';
import { withSaveSettings } from '../components/tables/tables-new/hocs/withSaveSettings';
import { withCsvDownload } from '../components/tables/tables-new/hocs/withCsvDownload';
import { ColumnCustomizer } from '../components/tables/tables-new/base/column-customizer/column-customizer.table';
import { ExpandableRow } from '../components/tables/tables-new/base/expandable-row.table';
import { ExpandableRowContent } from '../components/tables/tables-new/variations/expanable-row-content.table';
import { UrlTable } from '../components/tables/tables-new/variations/url.table';

import { P100PolicyMidTermEndorsementDrawer } from '../console/_global/endorsements/P100PolicyMidTermEndorsementDrawer';
import RefreshIcon from '../_assets/svg/Refresh';
import { fetchPolicies, fetchPolicyStatuses } from './PolicyService';
import {
  useJQuery,
  RenewalOptions,
  YesNoOptions,
  phoneFormatter,
  getBoolValueText,
} from '../utils/appUtils';
import { withColumnValidation } from '../components/tables/tables-new/hocs/withColumnValidation';
import { getFee, prepColumnFilters } from '../utils/data.utils';
import { TableFilterChips } from '../components/tables/tableFilters/TableFilterChips';
import TableFilterSelect from '../components/tables/tableFilters/TableFilterSelect';
import StateStatics from '../console/_statics/states.statics.json';
import { AGENCY_POLICIES_LISTING_STORE_ID } from '../reducers/UiSettingsReducers';
import FilterState from '../components/tables/tableFilters/FilterState';
import ProductStatics from '../console/_statics/product.statics.json';
import { CreatedDateCell } from '../components/tables/tables-new/tableCellComponents';
import { AgencyPolicyOptions } from './options/AgencyPolicyOptions';
import PolicyDrawer from '../console/policies/drawers/policyholder/PolicyDrawer';
import { selectGlobalTeam } from '../reducers/team.reducer';
import SimpleTooltip from '../components/SimpleTooltip';
import { ClaimIndicator } from '../_assets/svg/claims/ClaimIndicator.svg';

import P250PolicyMidTermEndorsementDrawer from '../console/_global/endorsements/P250PolicyMidTermEndorsementDrawer';
import { ProductTypes } from '../types';
import TableUpdatedSnackbarMessage from '../components/snackbars/messages/TableUpdatedSnackbarMessage';
import {
  AgentNameCell,
  getPolicyStatus,
} from '../console/policies/listing/cowbell/helpers';
import { PolicyStatus } from '../console/_statics/policy.statics';
import PrimeXAgencyPolicyOptions from './options/PrimeXAgencyPolicyOptions';
import PolicyDetailsAccordion from './PolicyDetailsAccordion';
import { determineIsLegacyProduct } from '../console/agencies/quotes/PrimeX/utils/primeX.util';
import { formatTableCurrencyByRegion } from '../utils/currency.utils';
import { getIsUsPlatform, useGetPlatformRegion } from '../utils';
import { useCowbellTranslations } from '../i18n/translations';

const AGENCY_POLICIES_BACKEND_ID = 'AGENCY_POLICY';

const EnhancedUrlTable = compose(
  withRouter,
  withSaveSettings(AGENCY_POLICIES_LISTING_STORE_ID),
  withColumnValidation({
    order: 'desc',
    orderBy: 'modified',
  })
)(UrlTable);

const styles = ({ config, palette }) => ({
  badge: {
    background: `${palette.error.main} !important`,
  },
  profile: {
    width: 56,
    height: 18,
    borderRadius: 3,
  },
  notSupportedColor: {
    color: config.colors.textRed,
  },
  statusColor: {
    color: config.colors.cowbellAccent,
  },
  options: {
    width: '30px',
    height: '25px',
    marginLeft: '18px',
    marginTop: '5px',
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
  },
  redDot: {
    fontSize: config.textSizes.primer,
    marginLeft: 5,
    width: '0.55rem',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  status: {
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },
  flag: {
    fontSize: config.textSizes.primer,
    marginLeft: 10,
    marginTop: 4,
    width: '1.2rem',
    height: '.8rem',
  },
  headingMargins: {
    marginTop: '-4px',
  },
  refreshContainer: {
    cursor: 'pointer',
  },
  close: {
    color: config.colors.darkGrey,
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit !important',
    },
  },
  progress: {
    zIndex: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  claimIndicatorParent: {
    '& tr': {
      position: 'relative',
    },
  },
});

const rqps = [
  'page',
  'size',
  'orderBy',
  'order',
  'search',
  'before',
  'after',
  'status',
  'teamIds',
];

const CsvDownloadButton = withCsvDownload('AgencyPolicies')((props) => {
  return (
    <MuiIconButton {...props}>
      <img src="images/Download.svg" alt="" className="table-ab-icon" />
    </MuiIconButton>
  );
});

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const useRowStyles = makeStyles(({ palette }) => ({
  root: {
    background: palette.tables.productRow,
    '&:hover': {
      background: palette.background.tableRowHover,
    },
    open: {
      background: palette.background.tableRowHover,
      cursor: 'pointer',
    },
  },
  endorse: {
    background: palette.tables.endorsement,
  },
}));

// ui
const AgencyPolicies = compose(
  withRouter,
  withCsvDownload(AGENCY_POLICIES_LISTING_STORE_ID),
  withBackfill(`uiSettings.${AGENCY_POLICIES_LISTING_STORE_ID}`, {
    size: 25,
    order: 'desc',
    orderBy: 'modified',
    before: () => Moment().add(1, 'days').endOf('day').unix(),
    after: () => Moment().subtract(1, 'year').startOf('day').unix(),
  }),
  withStyles(styles)
)(({ classes, q, updateUrl, history, location }) => {
  const team = useSelector(selectGlobalTeam);
  const theme = useTheme();
  const rowStyles = useRowStyles();
  const [showCircular, setShowCircular] = React.useState(false);
  const { isLoaded } = useJQuery();
  const columns = useColumns();

  const filterColumns = React.useMemo(() => {
    return prepColumnFilters(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = useMemo(
    () => ({
      theme,
      classes,
      setShowCircular,
      isLoaded,
      updateUrl,
      styles: {
        row: (row, myProps) => {
          if (myProps.expanded) {
            return { root: rowStyles.open };
          }
          if (row.isEndorsement) {
            return { root: rowStyles.endorse };
          }
          if (row.product === ProductTypes.p250) return rowStyles;
        },
      },
      rowsPerPageOptions: [25, 50, 100],
    }),
    [theme, classes, isLoaded, updateUrl, rowStyles]
  );

  useLayoutEffect(() => {
    updateUrl({ teamIds: team.id });

    const sub = PubSub.subscribe('team-change', (teamData) => {
      updateUrl({ teamIds: teamData.id });
    });

    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showCircular && (
        <CircularProgress
          size="25rem"
          thickness="2.5"
          className={classes.progress}
        />
      )}
      <div className={classes.claimIndicatorParent}>
        <EnhancedUrlTable
          id={AGENCY_POLICIES_LISTING_STORE_ID}
          columns={columns}
          context={context}
          delegate={fetch}
          rqps={rqps}
        >
          <ActionBar
            render={() => (
              <>
                <div id="portalOneContainer" />
                <PolicyStatsBar />
                <Box
                  mt={2}
                  mb={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FilterState
                    id={AGENCY_POLICIES_BACKEND_ID}
                    storeId={AGENCY_POLICIES_LISTING_STORE_ID}
                    render={(filterProps, routeProps) => (
                      <>
                        <TableFilterSelect
                          filterProps={filterProps}
                          routeProps={routeProps}
                        />
                        <TableFilterChips
                          filterProps={filterProps}
                          routeProps={routeProps}
                        />
                      </>
                    )}
                  />

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    width="50%"
                  >
                    <SearchBox />
                    <UrlDateRangePicker
                      q={q}
                      maxDate={new Date()}
                      start={Moment().subtract(1, 'year').toDate()}
                      inlineLabel
                    />
                    <ColumnCustomizer />
                    <CsvDownloadButton />
                  </Box>
                </Box>
              </>
            )}
          />

          <ExpandableRow
            render={(rowProps, ctx) => {
              if (rowProps.row.isPrimePlus || rowProps.row.isBOR) {
                return '';
              }

              return (
                <ProtectedComponent allow={{ policy: 'view' }} isAgency>
                  {rowProps.expanded &&
                    (!rowProps.row.isEndorsement ? (
                      <ExpandableRowContent {...rowProps} ctx={ctx}>
                        <PolicyDetailsAccordion policy={rowProps.row} />
                      </ExpandableRowContent>
                    ) : (
                      <PolicyDrawer
                        policyId={rowProps.row.id}
                        product={rowProps.row.product}
                        open={rowProps.expanded}
                        onClose={() => {
                          PubSub.publish('table-row:expanded');
                        }}
                      >
                        {({ policy = {} }) => {
                          if (rowProps?.row?.product === ProductTypes.p250) {
                            return (
                              <Box
                                style={{
                                  overflowY: 'auto',
                                }}
                              >
                                <P250PolicyMidTermEndorsementDrawer
                                  policy={policy}
                                />
                              </Box>
                            );
                          }

                          if (rowProps?.row?.product === ProductTypes.p100) {
                            return (
                              <Box
                                style={{
                                  overflowY: 'auto',
                                }}
                              >
                                <P100PolicyMidTermEndorsementDrawer
                                  policy={{ ...policy, ...policy.coverages }}
                                />
                              </Box>
                            );
                          }

                          return null;
                        }}
                      </PolicyDrawer>
                    ))}
                  {rowProps.expanded && delayedEvent('policy:read')}
                </ProtectedComponent>
              );
            }}
          />
        </EnhancedUrlTable>
      </div>
      <FilterModal
        tableId={AGENCY_POLICIES_BACKEND_ID}
        config={filterColumns}
        q={q}
        history={history}
        location={location}
      />
    </>
  );
});

const SubscribedCell = ({ event, rowId, initialValue, path, children }) => {
  const [value, setValue] = useState(initialValue);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  useEffect(() => {
    const sub = PubSub.subscribe(event, (data) => {
      if (rowId === data.id && typeof data[path] !== 'undefined') {
        setValue(data[path]);
      } else if (data.id === undefined) {
        setRefresh(data);
        setOpen(data);
        setTransition(() => TransitionLeft);
      }
    });
    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  return <>{children(value, refresh, open, setOpen, transition)}</>;
};

export const useColumns = () => {
  const region = useGetPlatformRegion();
  const isUSPlatform = getIsUsPlatform(region);
  const { translations } = useCowbellTranslations(['Agent', 'Agency']);

  return [
    {
      name: 'isRead',
      canToggle: false,
      sort: true,
      Cell: {
        C: ({ row }) => {
          const { classes } = useContext(TableContext);
          return (
            <SubscribedCell
              event="policy:table-row:read"
              rowId={row.id}
              path="isRead"
              initialValue={row.isRead}
            >
              {(newRead) => {
                if (!newRead) {
                  return (
                    <img
                      src="images/Red_Dot_1.svg"
                      alt=""
                      className={classes.redDot}
                    />
                  );
                }
              }}
            </SubscribedCell>
          );
        },
      },
    },
    {
      name: 'id',
      label: 'ID',
      show: false,
    },
    {
      name: 'policyNumber',
      label: 'Policy Number',
      filterType: 'string',
      Cell: {
        C: ({ row }) => {
          const { theme } = useContext(TableContext);

          return (
            <>
              {row.claimId && (
                <>
                  <SimpleTooltip
                    title={`${_.get(row, 'claimStatus', '')} - Claim#  ${
                      row.claimNumber
                    }`}
                  >
                    <ClaimTag
                      color={
                        row.claimStatus === 'CLOSED'
                          ? theme.config.cowbellAccent
                          : 'salmon'
                      }
                    />
                  </SimpleTooltip>
                </>
              )}
              {row.policyNumber}
            </>
          );
        },
      },
    },
    {
      name: 'premium',
      label: 'Premium',
      filterType: 'number',
      show: true,
      Cell: {
        C: Ramda.compose(
          formatTableCurrencyByRegion()('premium'),
          Ramda.prop('row')
        ),
      },
      csv: formatTableCurrencyByRegion()('premium'),
    },
    {
      name: 'brokerFee',
      label: 'Fee',
      filterType: 'number',
      show: true,
      sort: false,
      Cell: {
        C: ({ row }) => {
          return getFee(row);
        },
      },
      csv: (row) => {
        return getFee(row);
      },
    },
    {
      name: 'totalPremium',
      label: 'Total Premium',
      filterType: 'number',
      Head: {
        align: 'right',
      },
      Cell: {
        align: 'right',
        C: Ramda.compose(
          formatTableCurrencyByRegion()('totalPremium'),
          Ramda.prop('row')
        ),
      },
      csv: formatTableCurrencyByRegion()('totalPremium'),
    },
    {
      name: 'agentName',
      label: `${translations.Agent} Name`,
      Cell: {
        C: AgentNameCell,
        width: 180,
      },
    },
    {
      name: 'agentEmail',
      label: `${translations.Agent} Email`,
      show: false,
      filterType: 'string',
      Cell: {
        C: ({ row }) => row.agentEmail || '-',
      },
    },
    {
      name: 'companyName',
      label: 'Company Name',
      filterType: 'string',
    },
    {
      name: 'dbaOrTradestyle',
      label: 'DBA',
      filterType: 'string',
      show: false,
    },
    {
      name: 'product',
      label: 'Product',
      filterType: 'list',
      getListOptions: async () => ProductStatics.PRODUCT_OPTIONS,
      show: false,
      Cell: {
        C: ({ row }) =>
          ProductStatics.ProductTypes?.[row.product]?.short ?? row.product,
      },
    },
    ...(isUSPlatform
      ? [
          {
            name: 'state',
            label: 'Company State',
            sort: false,
            show: false,
            filterType: 'list',
            getListOptions: () =>
              new Promise((resolve) => resolve(StateStatics.full)),
            Cell: {
              C: ({ row }) => _.get(row, 'firmographicData.state', '-'),
            },
            csv: (row) => _.get(row, 'firmographicData.state', '-'),
          },
        ]
      : []),
    {
      name: 'customerEmail',
      label: 'Customer Email',
      show: false,
      filterType: 'string',
    },
    {
      name: 'customerFirstName',
      label: 'Customer Name',
      filterType: 'string',
      Cell: {
        C: ({ row }) => {
          return `${row.customerFirstName ? row.customerFirstName : ''} ${
            row.customerLastName ? row.customerLastName : ''
          }`;
        },
      },
      csv: (row) => {
        return `${row.customerFirstName ? row.customerFirstName : ''} ${
          row.customerLastName ? row.customerLastName : ''
        }`;
      },
    },
    {
      name: 'customerPhone',
      label: 'Customer Phone Number',
      show: false,
      filterType: 'string',
      Cell: ({ row }) => phoneFormatter(row.customerPhone, '-'),
    },
    {
      name: 'agencyId',
      label: `${translations.Agency} ID`,
      show: false,
      filterType: 'string',
    },
    {
      name: 'description',
      label: 'Description',
      show: false,
      Cell: {
        C: ({ row }) => {
          let desc = row.description || '-';
          desc = desc.length > 100 ? `${desc.substr(0, 98)} ...` : desc;
          return desc;
        },
      },
    },
    {
      name: 'isOpenForRenewal',
      label: 'Open For Renewal?',
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(YesNoOptions)),
      show: false,
      Cell: {
        C: ({ row }) => getBoolValueText(row.isOpenForRenewal),
      },
      csv: (row) => getBoolValueText(row.isOpenForRenewal),
    },
    {
      name: 'isRenewal',
      label: 'Is Renewal?',
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(RenewalOptions)),
      show: false,
      Cell: {
        C: ({ row }) => (row.isRenewal ? 'Renewal' : 'New'),
      },
      csv: (row) => (row.isSurplus ? 'Renewal' : 'New'),
    },
    {
      name: 'isMigration',
      label: 'Is Migration?',
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(YesNoOptions)),
      show: false,
      Cell: {
        C: ({ row }) => getBoolValueText(row.isMigration),
      },
      csv: (row) => getBoolValueText(row.isMigration),
    },
    {
      name: 'isBOR',
      label: 'Lost Account?',
      show: false,
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(YesNoOptions)),
      Cell: {
        C: ({ row }) => getBoolValueText(row.isBOR),
      },
      csv: (row) => getBoolValueText(row.isBOR),
    },
    {
      name: 'isPrimePlus',
      label: 'Is Excess?',
      show: false,
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(YesNoOptions)),
      Cell: {
        C: ({ row }) => getBoolValueText(row.isPrimePlus),
      },
      csv: (row) => getBoolValueText(row.isPrimePlus),
    },
    {
      name: 'effectiveDate',
      label: 'Policy Effective',
      filterType: 'date',
      show: false,
      Cell: {
        C: ({ row }) => {
          const invalidStatus = ['DELETED'];

          const dt = _.get(row, 'effectiveDate');
          if (invalidStatus.includes(row.customerStatus)) {
            return '-';
          }

          return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
        },
      },

      csv: (row) => {
        const invalidStatus = ['DELETED'];

        const dt = _.get(row, 'effectiveDate');
        if (invalidStatus.includes(row.customerStatus)) {
          return '-';
        }
        return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
      },
    },
    {
      name: 'endDate',
      label: 'Policy Expiration',
      filterType: 'date',
      show: false,
      Cell: {
        C: ({ row }) => {
          const invalidStatus = ['DELETED'];

          const dt = _.get(row, 'endDate');
          if (invalidStatus.includes(row.customerStatus)) {
            return '-';
          }

          return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
        },
      },

      csv: (row) => {
        const invalidStatus = ['DELETED'];

        const dt = _.get(row, 'endDate');
        if (invalidStatus.includes(row.customerStatus)) {
          return '-';
        }
        return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
      },
    },
    {
      name: 'created',
      label: 'Created',
      filterType: 'date',
      show: false,
      Cell: {
        C: ({ row }) => CreatedDateCell(row),
      },
      csv: (row) => getShortDateString(row.created),
    },
    {
      name: 'modified',
      label: 'Modified',
      filterType: 'date',
      Cell: {
        C: ({ row }) => {
          return row.modified ? Moment(row.modified).fromNow() : '-';
        },
      },
      csv: (row) => getShortDateString(row.modified),
    },
    {
      name: 'quoteNumber',
      label: 'Quote Number',
      filterType: 'string',
      show: false,
    },

    {
      name: 'status',
      label: 'Status',
      filterType: 'list',
      getListOptions: () =>
        fetchPolicyStatuses()
          .then(({ data }) => {
            if (data.length) {
              return data.map((el) => ({
                label: el,
                value: el,
              }));
            }
            return [];
          })
          .catch(() => {
            return [];
          }),
      Cell: {
        C: ({ row }) => {
          const { classes } = useContext(TableContext);
          const status = getPolicyStatus(row);

          return (
            <div className={classes.status}>
              {status}
              <NoKnownLossLetterIndicator status={row.noKnownLossLetterReq} />
            </div>
          );
        },
      },
    },
    {
      name: 'options',
      canToggle: false,
      sort: false,
      Cell: {
        C: ({ row }) => {
          const { setShowCircular, isLoaded } = useContext(TableContext);
          const restrictedStatus = [
            PolicyStatus.VOID_STATUS,
            PolicyStatus.CANCELLED_STATUS,
            PolicyStatus.REQUESTED_STATUS,
            PolicyStatus.CANCELLED_PENDING_STATUS,
            PolicyStatus.CANCELLED_IN_REINSTATEMENT_PERIOD,
            PolicyStatus.FLAT_CANCELLED_STATUS,
            PolicyStatus.INVALID_STATUS,
          ];

          if (!restrictedStatus.includes(row.status) && !row.isBOR) {
            if (determineIsLegacyProduct(row.product)) {
              return (
                <AgencyPolicyOptions
                  data={row}
                  handleTableRefetch={makeRefreshCall}
                  setShowCircular={setShowCircular}
                  isJQueryLoaded={isLoaded}
                />
              );
            }

            return (
              <PrimeXAgencyPolicyOptions
                policy={row}
                refetchTable={makeRefreshCall}
              />
            );
          }

          return null;
        },
      },
    },

    {
      name: 'actions',
      canToggle: false,
      sort: false,
      Head: () => {
        const { classes, theme } = useContext(TableContext);

        return (
          <SubscribedCell event="policy-refresh:show">
            {(value, refresh, open, setOpen, transition) => {
              function onClose() {
                setOpen(false);
              }
              return (
                <>
                  <MuiIconButton
                    className={classes.refreshContainer}
                    onClick={makeRefreshCall}
                    size="small"
                  >
                    <RefreshIcon
                      fill={
                        !refresh
                          ? theme.palette.text.primary
                          : theme.palette.snackBar.primary
                      }
                    />
                  </MuiIconButton>
                  <Snackbar
                    open={open}
                    autoHideDuration={5000}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClose={onClose}
                    TransitionComponent={transition}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    message={
                      <TableUpdatedSnackbarMessage
                        onRefreshClick={makeRefreshCall}
                      />
                    }
                    action={
                      // eslint-disable-next-line react/jsx-no-bind
                      <MuiIconButton
                        aria-label="close"
                        color="inherit"
                        size="large"
                        className={classes.close}
                        onClick={onClose}
                      >
                        <CloseIcon />
                      </MuiIconButton>
                    }
                  />
                </>
              );
            }}
          </SubscribedCell>
        );
      },
      Cell: {
        width: 50,
        C: ({ row }) => {
          if (row.isPrimePlus || row.isBOR) {
            return '';
          }

          return (
            <ProtectedComponent allow={{ policy: 'view' }} isAgency>
              <ExpandIcon id={row.id} />
            </ProtectedComponent>
          );
        },
      },
    },
  ];
};

const ClaimTag = styled(ClaimIndicator)({
  position: 'absolute',
  top: 0,
});

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, AGENCY_POLICIES_LISTING_STORE_ID);
  PubSub.publish('policy-refresh:show', false);
}

function fetch(q) {
  return fetchPolicies(q).then(({ data }) => ({
    data: data.content,
    count: data.totalElements,
  }));
}

export default AgencyPolicies;
