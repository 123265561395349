import React from 'react';
import _ from 'lodash';
import { DialogActions, DialogContent, Grid } from '@mui/material';

import { FormContext, useForm } from 'react-hook-form-4';

import * as yup from 'yup';

import { setPrime250Ui } from '../../../../_reducers/prime250.reducer';
import { withFormController } from '../../../../../components/hocs/withFormController';
import CbButton from '../../../../../components/Buttons/CbButton';
import LanguageCurrencyFieldBase from '../../../../../components/inputs/LanguageCurrencyField';

const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);

const Schema = yup.object({
  partnerHourlyRate: yup
    .string()
    .min(0, 'Partner Hourly Rate must be at least 1 characters')
    .required('Partner Hourly Rate is required'),
  associateHourlyRate: yup
    .string()
    .min(0, 'Associate Hourly Rate must be at least 1 characters')
    .required('Associate Hourly Rate is required'),
  paralegalHourlyRate: yup
    .string()
    .min(0, 'Paralegal Hourly Rate must be at least 1 characters')
    .required('Paralegal Hourly Rate is required'),
});

export const UWDefenseCounselModal = ({ data, iconStyles, ...props }) => {
  const { dispatch, defaultValues } = data;

  const {
    handleSubmit,
    formState: { dirty },
    ...methods
  } = useForm({
    defaultValues: {
      associateHourlyRate: defaultValues.associateHourlyRate || 0,
      paralegalHourlyRate: defaultValues.paralegalHourlyRate || 0,
      partnerHourlyRate: defaultValues.partnerHourlyRate || 0,
    },
    validationSchema: Schema,
  });

  const onSubmit = (formData) => {
    dispatch(
      setPrime250Ui({
        defenseCounsel: {
          associateHourlyRate:
            formData.associateHourlyRate === '$' ||
            formData.associateHourlyRate <= 0
              ? parseFloat(0)
              : parseFloat(formData.associateHourlyRate.slice(1)),
          paralegalHourlyRate:
            formData.paralegalHourlyRate === '$' ||
            formData.paralegalHourlyRate <= 0
              ? parseFloat(0)
              : parseFloat(formData.paralegalHourlyRate.slice(1)),
          partnerHourlyRate:
            formData.partnerHourlyRate === '$' ||
            formData.partnerHourlyRate <= 0
              ? parseFloat(0)
              : parseFloat(formData.partnerHourlyRate.slice(1)),
        },
      })
    );
    props.close();
  };

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <div className="modal-title">
              <h1>{data.firmName ? data.firmName : ''}</h1>
            </div>
            <Grid container spacing={10}>
              <Grid item md={7}>
                <span> DEFENSE COUNSEL PARTNER HOURLY RATE</span>
              </Grid>
              <Grid item md={5}>
                <LanguageCurrencyField
                  name="partnerHourlyRate"
                  required
                  error={_.get(methods.errors, `partnerHourlyRate.message`)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={10}>
              <Grid item md={7}>
                <p> DEFENSE COUNSEL ASSOCIATE HOURLY RATE</p>
              </Grid>
              <Grid item md={5}>
                <LanguageCurrencyField
                  name="associateHourlyRate"
                  required
                  error={_.get(methods.errors, `associateHourlyRate.message`)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={10}>
              <Grid item md={7}>
                <p>DEFENSE COUNSEL PARALEGAL HOURLY RATE</p>
              </Grid>
              <Grid item md={5}>
                <LanguageCurrencyField
                  name="paralegalHourlyRate"
                  required
                  error={_.get(methods.errors, `paralegalHourlyRate.message`)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CbButton onClick={props.close} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton type="submit" disabled={!dirty} styleName="ctaButton">
              Save Changes
            </CbButton>
          </DialogActions>
        </form>
      </FormContext>
      {/* </Dialog> */}
    </>
  );
};

export const UWDefenseCounselConfig = {
  UWDefenseCounselModal: {
    component: UWDefenseCounselModal,
    config: {
      fullWidth: true,
      title: 'Defense Counsel (DC)',
    },
  },
};
