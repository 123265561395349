import React from 'react';

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

// mui
import { DialogActions, DialogContent } from '@mui/material';

// components
import CbButton from '../../../components/Buttons/CbButton';

// actions
import { muteRenewalReminders } from '../../../policies/PolicyService';

// utils
import { COWBELL_POLICY } from '../../../components/tables/table_constants';
import { useAPIErrorHandler } from '../../../components/hooks/useAPIErrorHandler';

export const MuteRenewalReminder = ({ data, close }) => {
  const handleAPIError = useAPIErrorHandler();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  // state
  const [isLoading, setLoading] = React.useState(false);

  const handleMuteRenewalReminder = () => {
    setLoading(true);
    muteRenewalReminders({ policyId: data.id, product: data.product })
      .then(() => {
        enqueueSnackbar('Request successfully sent to mute renewal reminder', {
          variant: 'success',
        });
        close();
        queryClient.invalidateQueries([COWBELL_POLICY]);
      })
      .catch(
        handleAPIError(
          'Cannot mute renewal reminder at this time. Please try again'
        )
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <DialogContent className="modal-title" style={{ textAlign: 'center' }}>
        Are you sure you want to {data.muteRenewalReminders ? 'unmute' : 'mute'}{' '}
        renewal reminders for <strong>Policy# {data.policyNumber}</strong>?
      </DialogContent>
      <DialogActions>
        <CbButton buttonText="Cancel" styleName="cancel" onClick={close} />
        <CbButton
          buttonText={
            data.muteRenewalReminders ? 'Unmute Reminder' : 'Mute Reminder'
          }
          styleName="ctaButton"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleMuteRenewalReminder}
        />
      </DialogActions>
    </>
  );
};

export const MuteRenewalReminderConfig = {
  MuteRenewalReminder: {
    component: MuteRenewalReminder,
    config: {
      fullWidth: false,
      maxWidth: 'md',
      title: 'Mute/Unmute Renewal Reminders',
    },
  },
};
