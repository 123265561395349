// do not change the value of the below status consts, they map to backend actual statuses
export const ACTIVE_STATUS = 'ACTIVE';
export const SIGNED_STATUS = 'SIGNED';
export const BOUND_STATUS = 'BOUND';
export const EXPIRED_STATUS = 'EXPIRED';
export const IN_FORCE_STATUS = 'IN FORCE';
export const ISSUED_STATUS = 'ISSUED';
export const INVALID_STATUS = 'INVALID';
export const RENEWED_STATUS = 'RENEWED';
export const VOID_STATUS = 'VOID';
export const VOID_TRANSACTION_STATUS = 'VOID TRANSACTION';
export const CANCELLED_STATUS = 'CANCELLED';
export const CANCELLED_PENDING_STATUS = 'CANCEL PENDING';
export const CANCEL_TRANSACTION = 'CANCEL TRANSACTION';
export const CANCELLED_IN_REINSTATEMENT_PERIOD = 'IN REINSTATEMENT PERIOD';
export const REQUESTED_STATUS = 'REQUESTED';
export const FLAT_CANCELLED_STATUS = 'FLAT CANCELLED';
export const CANCEL_REQUESTED_STATUS = 'CANCEL REQUESTED';

export const PolicyStatus = {
  ACTIVE_STATUS,
  BOUND_STATUS,
  EXPIRED_STATUS,
  IN_FORCE_STATUS,
  ISSUED_STATUS,
  INVALID_STATUS,
  RENEWED_STATUS,
  VOID_STATUS,
  VOID_TRANSACTION_STATUS,
  CANCELLED_STATUS,
  CANCELLED_PENDING_STATUS,
  CANCEL_TRANSACTION,
  CANCELLED_IN_REINSTATEMENT_PERIOD,
  REQUESTED_STATUS,
  FLAT_CANCELLED_STATUS,
  CANCEL_REQUESTED_STATUS,
} as const;

export type POLICY_STATUS = (typeof PolicyStatus)[keyof typeof PolicyStatus];
