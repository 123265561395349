import _ from 'lodash';
import React, { useMemo } from 'react';
import classnames from 'classnames';

import { Card, Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DoughnutChart } from '../../../components/graphs/DoughnutChart';

import CowbellFactorsMapping from '../../../console/_statics/riskfactors.statics';

export const CowbellFactors = ({
  parentClasses,
  cowbellRiskFactors = {},
  $cardRef,
}) => {
  const {
    palette: { graphs },
  } = useTheme();
  const classes = useStyles();
  const cardclass = classnames(parentClasses.card, classes.card);
  const factors = useMemo(
    () => ({
      companyAggregateCF: cowbellRiskFactors.companyAggregateCF,
      industryAggregateCF: cowbellRiskFactors.industryAggregateCF,
      ...cowbellRiskFactors.factors,
    }),
    [cowbellRiskFactors]
  );

  const companyAggregateGraph = useMemo(
    () => ({
      datasets: [
        {
          data: [factors.companyAggregateCF, 100 - factors.companyAggregateCF],
          backgroundColor: [graphs.pie.primaryBlue, graphs.pie.secondaryBlue],
          borderColor: [graphs.pie.primaryBlue, graphs.pie.secondaryBlue],
        },
      ],
    }),
    [
      factors.companyAggregateCF,
      graphs.pie.primaryBlue,
      graphs.pie.secondaryBlue,
    ]
  );

  const industryAggregateGraph = useMemo(
    () => ({
      datasets: [
        {
          data: [
            factors.industryAggregateCF,
            100 - factors.industryAggregateCF,
          ],
          backgroundColor: [graphs.pie.primaryGreen, graphs.pie.secondaryGreen],
          borderColor: [graphs.pie.primaryGreen, graphs.pie.secondaryGreen],
        },
      ],
    }),
    [
      factors.industryAggregateCF,
      graphs.pie.primaryGreen,
      graphs.pie.secondaryGreen,
    ]
  );

  const cardHeight = useMemo(() => {
    if ($cardRef) {
      return $cardRef.getBoundingClientRect().height;
    }
  }, [$cardRef]);

  return (
    <>
      <h3 className="contrast-text">Cowbell Factors</h3>
      <Card
        id="factors"
        className={cardclass}
        style={{ height: cardHeight || 'auto' }}
      >
        <Grid container style={{ marginBottom: '2.916rem' }}>
          <Grid
            item
            sm={6}
            className="flex align-right align-center text-right"
          >
            <span className={classes.graphLabel}>Company Aggregate CF</span>
            <DoughnutChart data={companyAggregateGraph} maxWidth="8rem" />
          </Grid>
          <Grid item sm={6} className="flex align-left align-center">
            <DoughnutChart data={industryAggregateGraph} maxWidth="8rem" />
            <span className={classes.graphLabel}>Industry Aggregate CF</span>
          </Grid>
        </Grid>
        <h3 className="card-heading">COWBELL FACTORS TM (CF)</h3>
        <div className={classes.factorDescriptions}>
          {_.intersection(
            Object.keys(CowbellFactorsMapping),
            displayedFactors
          ).map((rfkey) => {
            const { label, description } = CowbellFactorsMapping[rfkey];
            return (
              <div>
                <div className="flex--spaced align-center">
                  <h4
                    className={classes.heading}
                    style={{ marginTop: '1.25rem' }}
                  >
                    {label}
                  </h4>
                  <span className="oswald primer-text">{factors[rfkey]}</span>
                </div>
                <p
                  className="petite-text"
                  style={{ margin: 0, width: 'calc(100% - 3.33rem)' }}
                >
                  {description}
                </p>
              </div>
            );
          })}
        </div>
      </Card>
    </>
  );
};

const useStyles = makeStyles(({ config }) => ({
  heading: {
    marginBottom: '0.8333333333rem',
    marginTop: '2.5rem',
    fontSize: config.textSizes.tertia,
    fontWeight: 400,
  },

  graphLabel: {
    display: 'block',
    maxWidth: '6.25rem',
  },

  card: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  factorDescriptions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
}));

const displayedFactors = [
  'networkSecurity',
  'cloudSecurity',
  'endpointSecurity',
  'darkIntelligence',
  'fundsTransfer',
  'cyberExtortion',
  'compliance',
];
