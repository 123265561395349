import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// react
import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
// mui
import {
  Grid as MuiGrid,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';

// components
import { hostname as hostnameSchema } from '../../utils/validationSchemas/accounts.schema';
import { withFormController } from '../../components/hocs/withFormController';
import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { delayedEvent } from '../../utils/eventUtils';
import { RegisteredAgencyAutoComplete } from '../../accounts/admin-account-flow/AgencyAutoComplete';
import { getUrlHost } from '../../utils/appUtils';
import CbButton from '../../components/Buttons/CbButton';
// actions
import { createMssp, setMsspDetails } from './mssp.service';

const TextField = withFormController(TextFieldBase);
const hostName = getUrlHost();

const schema = Yup.object().shape({
  hostname: hostnameSchema,
  name: Yup.string()
    .required()
    .label('Name')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  description: Yup.string().required().label('Description'),
  agency: Yup.object({
    label: Yup.string().required(),
    value: Yup.string().required(),
  })
    .required()
    .typeError('Agency is a required field'),
});

export const MsspCreateEdit = ({ data, close }) => {
  const edit = !_.isEmpty(data);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');
  const urlHost = getUrlHost();

  const {
    handleSubmit,
    formState: { isSubmitting, dirty },
    ...methods
  } = useForm({
    defaultValues: {
      name: data.name || '',
      description: data.description || '',
      hostname: data.hostname ? data.hostname.split('.')[0] : '',
      agency: edit ? { label: data.agencyName, value: data.agencyId } : '',
    },
    validationSchema: schema,
  });

  methods.watch(['agency', 'hostname']);

  const onSubmit = (formData) => {
    const { name, description, hostname, agency } = formData;
    const { contactEmail, contactFirstName, contactLastName, contactPhone } =
      data;
    const finalHostName = `${hostname}${urlHost.trim()}`;

    const payload = {
      name,
      description,
      hostname: edit ? hostname : finalHostName,
      agencyId: agency.value,
      agencyName: agency.label,
    };

    const api = edit
      ? setMsspDetails(
          {},
          {
            msspId: data.id,
            contactEmail,
            contactFirstName,
            contactLastName,
            contactPhone,
            ...payload,
          }
        )
      : createMssp({}, payload);

    api
      .then(() => {
        enqueueSnackbar(`MSSP Updated successfully!`, { variant: 'success' });
        delayedEvent('table-refetch', 1000, 'mssp');
        close();
      })
      .catch((error) => {
        const errorMessage = _.get(error, 'response.data');
        if (typeof errorMessage === 'string') {
          setError(errorMessage);
        } else {
          setError('Unable to update your data, please try again');
        }
      });
  };

  const values = methods.getValues();

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} data-qa="addAgency">
        <DialogContent style={{ padding: '2rem 5rem' }}>
          <MuiGrid container spacing={2}>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="name"
                label="Name"
                required
                fullWidth
                inputProps={{ required: true }}
              />
            </MuiGrid>
            <MuiGrid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="description"
                label="Description"
                required
                fullWidth
              />
            </MuiGrid>
          </MuiGrid>

          <TextField
            required
            label="Hostname"
            name="hostname"
            helperText={`example${hostName}`}
          />
          <RegisteredAgencyAutoComplete
            name="agency"
            value={values.agency}
            placeholder="select agency"
            error={!values.agency && _.get(methods.errors, 'agency.message')}
            required
            fetchOnMount
          />
        </DialogContent>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText={edit ? 'Update MSSP' : 'Add MSSP'}
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

export const MsspCreateEditConfig = {
  MsspCreateEdit: {
    component: MsspCreateEdit,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
    },
  },
};
