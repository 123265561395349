import * as Yup from 'yup';
// react
import React from 'react';

// mui
import { Grid as MuiGrid, Box } from '@mui/material';
// components

import { withFormController } from '../../../../../../components/hocs/forms';
import { TextFieldBase } from '../../../../../../components/inputs/TextFieldBase';
import { ControlledCheckBox } from '../../../../../../components/inputs/ControlledCheckBox';

const TextField = withFormController(TextFieldBase);

export const nonFollowFormValidation = {
  name: Yup.string().when('endorsement', {
    is: 'Non-Follow Form',
    then: Yup.string().required().label('Name'),
  }),
};

export const NonFollowForm = () => {
  return (
    <>
      <MuiGrid container spacing={5}>
        <MuiGrid item md={12} style={{ paddingTop: 0 }}>
          <TextField name="name" label="Name" fullWidth required />
          <Box display="flex" justifyContent="center" alignItems="center">
            <ControlledCheckBox
              name="recognizeErosion"
              label="Recognize Erosion"
            />
          </Box>
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
