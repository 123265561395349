export function replace(
  baseArray: [string | number],
  valueToFind: string | number,
  replacementValue: string | number
) {
  const standarizedArray = baseArray;
  const index = baseArray.indexOf(`${valueToFind}`);

  if (index !== -1) {
    standarizedArray[index] = replacementValue;
  }

  return standarizedArray;
}

interface UnknownObject {
  [x: string]: any;
}

export class ArrayUtils {
  static mapEntitiesToIds = (entities: UnknownObject[]) => {
    return entities.map((entity) => entity.id);
  };

  static reduceArrayToObject = (array: UnknownObject[], targetKey = 'id') => {
    return array.reduce(
      (acc, entity) => ({ ...acc, [entity[targetKey]]: entity }),
      {}
    );
  };
}
