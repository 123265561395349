import React from 'react';
import classnames from 'classnames';
import Numeral from 'numeral';

// components
import { Grid, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CustomCheckbox } from '../../../components/inputs';

const retroMapping = {
  1: '1 Year',
  2: '2 Years',
  '-1': 'Full Prior Acts',
};

// main component
export const DetailsLineItem = ({ coverage }) => {
  const checkboxStyles = useCheckboxStyles();
  const classes = styles();
  const checkboxClasses = classnames({
    mandatory: coverage.mandatory,
  });

  const { limit, deductible, waitingPeriod, retroActivePeriod } = coverage;

  return (
    <Grid container>
      <Grid item md={4} style={{ paddingLeft: '1.33rem' }}>
        <CustomCheckbox
          className={checkboxClasses}
          classes={checkboxStyles}
          name={coverage._id}
          label={
            <Tooltip title={coverage.tooltip} disableFocusListener>
              <span>{coverage.name}</span>
            </Tooltip>
          }
          variant={!coverage.mandatory ? 'base' : 'filled'}
          checked={coverage.selected}
        />
      </Grid>

      <Grid
        item
        md={8}
        className="flex--spaced"
        style={{ paddingRight: '0.416rem' }}
      >
        <Grid container className={classes.values}>
          <Grid item md={3}>
            {limit.value ? Numeral(limit.value).format('$0,0') : '-'}
          </Grid>
          <Grid item md={3}>
            {deductible.available
              ? Numeral(deductible.value).format('$0,0')
              : '-'}
          </Grid>
          <Grid item md={3}>
            {waitingPeriod.available ? `${waitingPeriod.value} Hours` : '-'}
          </Grid>
          <Grid item md={3} style={{ paddingRight: '1rem' }}>
            {retroActivePeriod.available
              ? retroMapping[retroActivePeriod.value] || retroActivePeriod.value
              : '-'}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useCheckboxStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.text.primary,
  },

  checked: {
    color: `${palette.common.cowbellGreen2} !important`,

    '& + span': {
      color: `${palette.common.cowbellGreen2} !important`,
    },

    '&.mandatory': {
      color: `${palette.common.cowbellBlue} !important`,
    },

    '&.mandatory + span': {
      color: `${palette.common.cowbellBlue} !important`,
    },
    '&:hover': {
      background: 'none !important',
    },
  },
}));

const styles = makeStyles(({ config, palette }) => ({
  values: {
    textAlign: 'right',
    paddingRight: '1rem',
    color: palette.primary.contrastText,
    fontSize: config.textSizes.normal,
  },
}));
