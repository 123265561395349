import { CONNECTOR_TYPES } from '../../../../policyholders/connectors/connectors.constants';
import type { ServiceConfig } from '../../../../utils';
import { ServiceHandler } from '../../../../utils';

export enum ActionType {
  API_ENDPOINT = 'API Endpoint',
  CLOSE_MODAL = 'Close Modal',
  GO_FORWARD = 'Go Forward',
}

interface ActionProps {
  type: ActionType;
  requestConfig: ServiceConfig;
}

export const handleAction = (action: ActionProps, payload: any) => {
  const { requestConfig } = action;

  return ServiceHandler(requestConfig)(payload);
};

export const transformPayloadForConnector = (
  connectorType: string,
  payload: any
) => {
  if (connectorType === CONNECTOR_TYPES.GOOGLEWORKSPACE) {
    return transformPayloadForGoogleWorkspace(payload);
  }
  if (connectorType === CONNECTOR_TYPES.GSC) {
    return transformPayloadForGSC(payload);
  }
  if (connectorType === CONNECTOR_TYPES.CLOUDFLARE) {
    return transformPayloadForCloudflare(payload);
  }
  if (connectorType === CONNECTOR_TYPES.SOPHOS) {
    return transformPayloadForSophos(payload);
  }
  return payload;
};

const transformPayloadForGoogleWorkspace = (payload: any) => {
  const { cbid, formValues } = payload;
  const { accountEmail } = formValues;

  const formData = new FormData();
  if (formValues && formValues.credentials) {
    formData.append('credentials', formValues.credentials[0]);
  }

  return {
    data: formData,
    params: { cbid, accountEmail },
  };
};

const transformPayloadForGSC = (payload: any) => {
  const { cbid, formValues } = payload;
  const { orgId } = formValues;

  const formData = new FormData();
  if (formValues && formValues.credentials) {
    formData.append('credentials', formValues.credentials[0]);
  }

  return {
    data: formData,
    params: { cbid, orgId, type: CONNECTOR_TYPES.GSC },
  };
};

const transformPayloadForCloudflare = (payload: any) => {
  const { cbid, formValues } = payload;
  const { clientAPIKey, clientEmail } = formValues;

  return {
    params: {
      cbid,
      clientAPIKey,
      clientEmail,
      type: CONNECTOR_TYPES.CLOUDFLARE,
    },
  };
};

const transformPayloadForSophos = (payload: any) => {
  const { cbid, formValues } = payload;
  const { clientId, clientSecret } = formValues;

  return {
    params: { cbid, clientId, clientSecret, type: CONNECTOR_TYPES.SOPHOS },
  };
};
