import React, { Children } from 'react';
import { Box, IconButton, Collapse } from '@mui/material';
import { styled, makeStyles } from '@mui/styles';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import clsx from 'classnames';
import { withShowable } from '../../../_global/lib/withShowable';

export const CategoryContainer = withShowable(
  ({ categoryName, children, key }) => {
    const [expanded, setExpanded] = React.useState(true);
    const classes = useClasses();

    const childrenCount = Children.count(children);

    if (childrenCount < 1) return null;

    function toggleCollapse(e) {
      e.stopPropagation();
      e.preventDefault();
      setExpanded((prevState) => !prevState);
    }

    return (
      <CollapseContainer key={key}>
        <HeaderContainer onClick={toggleCollapse}>
          <CategoryHeader>{categoryName}</CategoryHeader>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={toggleCollapse}
            size="large"
          >
            <ExpandMoreIcon />
          </IconButton>
        </HeaderContainer>
        <Collapse in={expanded}>
          <div>{children}</div>
        </Collapse>
      </CollapseContainer>
    );
  }
);

const useClasses = makeStyles(() => ({
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const HeaderContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  cursor: 'pointer',
}));

const CollapseContainer = styled(Box)(({ theme: { palette, shape } }) => ({
  background: palette.background.paper,
  borderRadius: shape.borderRadius,
  padding: '0.25rem 1rem',
}));

const CategoryHeader = styled('h2')(({ theme: { config, palette } }) => ({
  fontSize: config.textSizes.canon,
  color: palette.primary.main,
  margin: 0,
  fontWeight: config.weights.normal,
}));

export const QuestionsContainer = styled(Box)(
  ({ theme: { palette, config } }) => ({
    border: `1px solid ${palette.background.darkGrey2}`,
    borderRadius: 5,
    width: '100%',
    margin: '0.5rem auto',
    padding: '0 1rem 1.5rem 1rem',
    color: palette.primary.main,
    fontSize: config.textSizes.primer,
  })
);

export const CloseButtonIcon = styled(HighlightOffIcon)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: theme.config.textSizes.paragon,
  '&:hover': {
    color: theme.palette.error.main,
  },
}));

const CancelButton = styled('button')(({ theme }) => ({
  outline: 'none',
  border: `1px solid ${theme.palette.error.contrastText}`,
  color: theme.palette.error.contrastText,
  borderRadius: 15,
  marginRight: '0.5rem',
  cursor: 'pointer',
  background: 'none',
}));

const DeleteButton = styled(CancelButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  marginRight: 0,
  border: 'none',
}));

export const DeletionDialog = ({ onClick }) => {
  const [deleteToggle, setDeleteToggle] = React.useState(false);

  const toggleState = (e) => {
    e.stopPropagation();
    setDeleteToggle((prevState) => !prevState);
  };

  const onDelete = (e) => {
    e.stopPropagation();
    onClick();
  };

  const onCancel = (e) => {
    e.stopPropagation();
    setDeleteToggle(false);
  };

  return deleteToggle ? (
    <Box display="flex">
      <CancelButton onClick={onCancel}>Cancel</CancelButton>
      <DeleteButton onClick={onDelete}>Delete</DeleteButton>
    </Box>
  ) : (
    <CloseButtonIcon onClick={toggleState} />
  );
};
