import AgencyInformation from '../AgencyInformation';
import DilligentSearchReport from '../DilligentSearchReport';
import InsuranceApplication from '../InsuranceApplication';
import LossRuns from '../LossRuns';
import Manufacturing from '../Manufacturing';
import NoKnownLossLetter from '../NoKnownLossLetter';
import PolicyHolderInformation from '../PolicyHolderInformation';
import TechENOCoverageConfirmation from '../TechENOCoverageConfirmation';
import AdditonalNamedInsured from '../AdditonalNamedInsured';
import RenewalApplication from '../RenewalApplication';
import P100InsuranceApplication from '../P100InsuranceApplication';
import {
  bindSteps as agencyBindSteps,
  subjectivityKeys,
} from '../../constants/constants';
import { cowbellBindSteps } from '../../../cowbell-bind-quote/constants/constants';
import PolicyEffectiveDate from '../PolicyEffectiveDate';
import IssuePolicy from '../IssuePolicy';
import type { ProductType } from '../../../../../types';
import { ProductTypes } from '../../../../../types';
import BindQuote from '../BindQuote';
import BindP250Quote from '../BindP250Quote';
import UploadBindOrder from '../../../cowbell-bind-quote/stepper-screens/UploadBindOrder';
import UpdateCommission from '../UpdateCommission';
import P100RenewalApplication from '../P100RenewalApplication';
import P250MissingSecurityQuestions from '../P250MissingSecurityQuestions';

interface SubjectivitySchema {
  [key: string]: React.ElementType;
}

export const getSubjectivitySchema = (productType: ProductType) => {
  const BindScreen = getBindScreen(productType);
  return {
    [cowbellBindSteps.UPLOAD_BIND_ORDER]: UploadBindOrder,
    [agencyBindSteps.POLICY_EFFECTIVE_DATE]: PolicyEffectiveDate,
    [agencyBindSteps.UPDATE_COMMISSION]: UpdateCommission,
    [agencyBindSteps.BIND_QUOTE]: BindScreen,
    [agencyBindSteps.ISSUE_POLICY]: IssuePolicy,
    [subjectivityKeys.POLICY_HOLDER_CONTACT]: PolicyHolderInformation,
    [subjectivityKeys.SURPLUS_LINES_TAX_DOCUMENTATION]: AgencyInformation,
    [subjectivityKeys.DILLIGENT_SEARCH_REPORT]: DilligentSearchReport,
    [subjectivityKeys.MANUFACTURING]: Manufacturing,
    [subjectivityKeys.LOSS_RUNS]: LossRuns,
    [subjectivityKeys.NO_KNOWN_LOSS_LETTER]: NoKnownLossLetter,
    [subjectivityKeys.TECH_E_O_COV_CONFIRMATION]: TechENOCoverageConfirmation,
    [subjectivityKeys.INSURANCE_APPLICATION]: InsuranceApplication,
    [subjectivityKeys.ADDITIONAL_NAMED_INSURED]: AdditonalNamedInsured,
    [subjectivityKeys.RENEWAL_APPLICATION]: RenewalApplication,
    [subjectivityKeys.P100_INSURANCE_APPLICATION]: P100InsuranceApplication,
    [subjectivityKeys.P100_RENEWAL_APPLICATION]: P100RenewalApplication,
    [subjectivityKeys.PRE_ISSUE_NKLL]: NoKnownLossLetter,
    [subjectivityKeys.PRE_ISSUE_LOSS_RUNS]: LossRuns,
    [subjectivityKeys.SECURITY_QUESTIONS]: P250MissingSecurityQuestions,
    [subjectivityKeys.PONE_NO_KNOWN_LOSS_LETTER]: NoKnownLossLetter,
    [subjectivityKeys.P100PRO_INSURANCE_APPLICATION]: InsuranceApplication,
    [subjectivityKeys.PONE_INSURANCE_APPLICATION]: InsuranceApplication,
    [subjectivityKeys.P100PRO_RENEWAL_APPLICATION]: RenewalApplication,
    [subjectivityKeys.PONE_RENEWAL_APPLICATION]: RenewalApplication,
  } as Readonly<SubjectivitySchema>;
};

const getBindScreen = (productType: ProductType) => {
  if (productType === ProductTypes.p250) {
    return BindP250Quote;
  }
  return BindQuote;
};
