import type { MouseEventHandler } from 'react';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Box, Collapse, Menu, MenuItem as MuiMenuItem } from '@mui/material';
import { styled } from '@mui/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMenuState } from '../../../../../components/tables/cell-components/menu/Menu';
import { Show } from '../../../../../components/Show';
import { getIsRenewalOrMigration } from '../../../../../utils';
import {
  BOUND_STATUS,
  DECLINED_STATUS,
  EXPIRED_STATUS,
  INDICATION_STATUS,
  INVALID_STATUS,
  LOST_STATUS,
  READY_STATUS,
  REFERRED_STATUS,
  REQUESTED_STATUS,
  REVIEW_STATUS,
  SIGNED_STATUS,
} from '../../../../_statics/quote.statics';
import { _isOneOf } from '../../../../../utils/data.utils';
import { ProductTypes } from '../../../../../types';
import type { Quote } from '../../../../../types/quotes/quote.entity';
import { AnchorButton } from '../AgencyDashSecondaryActionsMenu';
import { useAgencyQuotesOptionsHandlers } from '../../../../agency/logic/quotes/agency-quotes.logic';

interface AgencyQuotesMenuOptions {
  quote: Quote;
}

export const AgencyQuotesMenuOptions = ({ quote }: AgencyQuotesMenuOptions) => {
  const [isDocumentsExpanded, setIsDocumentsExpanded] = React.useState(true);

  const menuState = useMenuState();
  const menuOptionHandlers = useAgencyQuotesOptionsHandlers(quote);
  const menuOptionMetadata = useAgencyQuoteMenuOptions(quote);

  const handleClose: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    menuState.handleClose();
  };

  const handleDocumentsClick: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    setIsDocumentsExpanded((value) => !value);
  };

  return (
    <>
      <AnchorButton onClick={menuState.handleOpen} />
      <Menu
        id="more-options-menu"
        anchorEl={menuState.anchorEl}
        keepMounted
        open={menuState.open}
        onClose={handleClose}
      >
        <Show when={menuOptionMetadata.documents.show}>
          <MenuItem onClick={handleDocumentsClick}>
            {menuOptionMetadata.documents.name}
            <Box ml={0.5}>
              {isDocumentsExpanded ? (
                <FontAwesomeIcon icon={light('chevron-up')} />
              ) : (
                <FontAwesomeIcon icon={light('chevron-down')} />
              )}
            </Box>
          </MenuItem>
        </Show>
        <Collapse
          in={isDocumentsExpanded}
          timeout="auto"
          unmountOnExit
          style={{ paddingLeft: '1rem' }}
        >
          <Show when={menuOptionMetadata.downloadP100QuoteProposal.show}>
            <MenuItem
              onClick={() =>
                menuOptionHandlers.checkAutoRenewalQuote('proposal')
              }
              disabled={!quote.isProposalAvailable}
            >
              {menuOptionMetadata.downloadP100QuoteProposal.name}
            </MenuItem>
          </Show>
          <Show when={menuOptionMetadata.downloadP250QuoteProposal.show}>
            <MenuItem
              onClick={menuOptionHandlers.handleDownloadProposal('proposal')}
              disabled={!quote.isProposalAvailable}
            >
              {menuOptionMetadata.downloadP250QuoteProposal.name}
            </MenuItem>
          </Show>
          <Show when={menuOptionMetadata.downloadApplication.show}>
            <MenuItem
              onClick={menuOptionHandlers.handleDownloadApplication}
              disabled={!quote.isApplicationAvailable}
            >
              {menuOptionMetadata.downloadApplication.name}
            </MenuItem>
          </Show>
        </Collapse>
        <Show when={menuOptionMetadata.sendToPolicyholder.show}>
          <MenuItem onClick={menuOptionHandlers.handleSendQuoteToPolicyHolder}>
            {menuOptionMetadata.sendToPolicyholder.name}
          </MenuItem>
        </Show>
        <Show when={menuOptionMetadata.submitForUwReview.show}>
          <MenuItem onClick={menuOptionHandlers.handleSubmitForUWReview}>
            {menuOptionMetadata.submitForUwReview.name}
          </MenuItem>
        </Show>
        <MenuItem onClick={menuOptionHandlers.handleRoute}>
          {menuOptionMetadata.editClientDetails.name}
        </MenuItem>
        <Show when={menuOptionMetadata.reQuote.show}>
          <MenuItem onClick={menuOptionHandlers.handleReQuote}>
            {menuOptionMetadata.reQuote.name}
          </MenuItem>
        </Show>
        <Show when={menuOptionMetadata.deleteQuote.show}>
          <MenuItem onClick={menuOptionHandlers.handleDeleteQuote}>
            {menuOptionMetadata.deleteQuote.name}
          </MenuItem>
        </Show>
      </Menu>
    </>
  );
};

const useAgencyQuoteMenuOptions = (quote: Quote) => {
  const isRenewalOrMigration = getIsRenewalOrMigration(
    quote.isRenewal,
    quote.isMigration as boolean
  );

  let sendQuote = true;
  if (quote.product === ProductTypes.p250) {
    if (
      _isOneOf(quote.agencyStatus, [
        INVALID_STATUS,
        INDICATION_STATUS,
        REFERRED_STATUS,
        BOUND_STATUS,
        EXPIRED_STATUS,
        REVIEW_STATUS,
        LOST_STATUS,
        REQUESTED_STATUS,
        DECLINED_STATUS,
      ])
    ) {
      sendQuote = false;
    }
  }

  if (quote.product === ProductTypes.p100) {
    if (
      _isOneOf(quote.agencyStatus, [
        REQUESTED_STATUS,
        INVALID_STATUS,
        BOUND_STATUS,
        EXPIRED_STATUS,
        LOST_STATUS,
        DECLINED_STATUS,
      ])
    ) {
      sendQuote = false;
    }
  }

  const canDownloadP100QuoteProposal = Boolean(
    quote.product === ProductTypes.p100 &&
      _isOneOf(quote.agencyStatus, [
        INDICATION_STATUS,
        READY_STATUS,
        BOUND_STATUS,
        SIGNED_STATUS,
      ]) &&
      !quote.isEndorsement
  );

  const canDownloadP250QuoteProposal = Boolean(
    quote.product === ProductTypes.p250 &&
      _isOneOf(quote.agencyStatus, [READY_STATUS, BOUND_STATUS, SIGNED_STATUS])
  );

  const canDownloadApplication = _isOneOf(quote.agencyStatus, [
    READY_STATUS,
    BOUND_STATUS,
    SIGNED_STATUS,
  ]);

  const canSubmitForUwReview =
    quote.product === ProductTypes.p250 && quote.agencyStatus === READY_STATUS;

  const canReQuote =
    _isOneOf(quote.agencyStatus, [
      INDICATION_STATUS,
      READY_STATUS,
      BOUND_STATUS,
      SIGNED_STATUS,
      EXPIRED_STATUS,
      REQUESTED_STATUS,
      REFERRED_STATUS,
      LOST_STATUS,
    ]) &&
    !quote.isEndorsement &&
    !isRenewalOrMigration &&
    quote.product === ProductTypes.p250;

  const canSendToPolicyholder =
    sendQuote && !(quote.product === ProductTypes.p250 && quote.isEndorsement);

  const canDeleteQuote = _isOneOf(quote.agencyStatus, [
    INDICATION_STATUS,
    READY_STATUS,
    BOUND_STATUS,
    INVALID_STATUS,
    EXPIRED_STATUS,
    REQUESTED_STATUS,
    REFERRED_STATUS,
    REVIEW_STATUS,
    LOST_STATUS,
    DECLINED_STATUS,
  ]);

  return {
    documents: {
      name: 'Documents',
      show:
        canDownloadP100QuoteProposal ||
        canDownloadP250QuoteProposal ||
        canDownloadApplication,
    },
    downloadP100QuoteProposal: {
      name: 'Download Proposal',
      show: canDownloadP100QuoteProposal,
    },
    downloadP250QuoteProposal: {
      name: 'Download Proposal',
      show: canDownloadP250QuoteProposal,
    },
    downloadApplication: {
      name: 'Download Application',
      show: canDownloadApplication,
    },
    sendToPolicyholder: {
      name: 'Send to Policyholder',
      show: canSendToPolicyholder,
    },
    submitForUwReview: {
      name: 'Submit for UW Review',
      show: canSubmitForUwReview,
    },
    editClientDetails: { name: 'Edit Client Details' },
    reQuote: { name: 'Re-Quote', show: canReQuote },
    deleteQuote: { name: 'Delete Quote', show: canDeleteQuote },
  };
};

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  '&:hover': {
    background: theme.agencyDash.background.hover,
  },
}));
