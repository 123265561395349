import React from 'react';
import { useSelector } from 'react-redux';

// lodash
import _ from 'lodash';

// utils
import { PubSub } from '../../../utils/eventUtils';

import v2Modals from '.';

export const withModalState = (Component) => {
  return () => {
    const [modal, setModal] = React.useState({ open: false });
    const selectedModal = useSelector(({ modals }) =>
      _.get(modals, `[${modal.key}]`)
    );
    const config = (selectedModal && selectedModal.config) || {};

    const targetChildComponent =
      modal.component ?? _.get(v2Modals, `${modal.key}.component`, () => null);

    const handleClose = () => {
      setModal({ ...modal, open: false });
    };

    React.useEffect(() => {
      PubSub.subscribe('modal:toggle', (nextModal) => {
        setModal(nextModal);
      });
    }, []);

    return (
      <Component
        open={modal.open}
        onClose={handleClose}
        {...config}
        {...modal.config}
      >
        {React.createElement(targetChildComponent, {
          data: modal.data,
          close: handleClose,
        })}
      </Component>
    );
  };
};
