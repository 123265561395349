import { useQuery } from '@tanstack/react-query';
import { getCoveragesByProduct } from '../../../accounts/AccountService';
import type { ProductType } from '../../../types';
import { ProductTypes } from '../../../types';

export function useCoverages(product: ProductType, options: any) {
  return useQuery(
    ['coverages', product],
    () =>
      getCoveragesByProduct(product.toLowerCase()).then((resp) => resp.data),
    options
  );
}
