/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { MouseEvent } from 'react';
import React from 'react';
import { InputAdornment, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { SearchField } from '../../../../components/inputs/SearchField';
import { TypeToSearchMessage } from '../agency-dashboard.utils';

interface ChildrenProps {
  onClose: () => void;
  onChange: (value: string) => void;
  onClear: () => void;
}

interface CustomTypeaheadProps {
  children: (typeaheadProps: ChildrenProps) => React.ReactElement;
  onChange: (value: string) => void;

  onEnterKey?: () => void;
  onClearInput?: () => void;
  onKeyDown?: () => void;
  maxListHeight?: number | string;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
  style?: React.CSSProperties;
  typeToSearch?: boolean;
}

export const CustomTypeahead = ({
  children,
  onChange,
  onEnterKey,
  onClearInput,
  maxListHeight = '45rem',
  disabled,
  typeToSearch = true,
  ...props
}: CustomTypeaheadProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const showTypeToSearchPlaceholder = typeToSearch && !inputRef.current?.value;

  const [isOpen, setOpen] = React.useState(false);

  const inputClasses = useInputStyles();
  const containerClasses = useContainerStyles({
    isOpen,
    maxListHeight,
    showTypeToSearchPlaceholder,
  });

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const updateInputRef = (value: string) => {
    return inputRef.current ? (inputRef.current.value = value) : null;
  };

  const updateInputOnSelect = (value: string) => {
    updateInputRef(value);
    onChange(value);
  };

  const _onChange = (value: string) => {
    if (value === '' && inputRef.current) {
      updateInputRef(value);
    }
    return onChange(value);
  };

  const onEnter = () => {
    if (typeof onEnterKey === 'function') {
      onEnterKey();
    }

    if (inputRef.current) {
      inputRef.current.blur();
    }

    onClose();
  };

  const onClear = () => {
    if (typeof onClearInput === 'function') {
      onClearInput();
    }
    updateInputRef('');
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (typeof props.onKeyDown === 'function') {
      props.onKeyDown();
    }
    if (event.key === 'Escape') {
      onClear();

      if (inputRef.current) {
        inputRef.current.blur();
      }

      return onClose();
    }
    if (event.key === 'Enter') {
      return onEnter();
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLElement>) => {
    if (
      isOpen &&
      containerRef.current &&
      !containerRef.current.contains(event.target) &&
      event.target.id !== adornmentId &&
      event.target.parentElement?.id !== adornmentId
    ) {
      return onEnter();
    }
  };

  React.useEffect(() => {
    // @ts-ignore
    document.addEventListener('click', onBlur);
    // @ts-ignore
    return () => document.removeEventListener('click', onBlur);
  }, [onBlur]);

  React.useEffect(() => {
    // @ts-ignore
    document.addEventListener('click', onBlur);

    // @ts-ignore
    return () => document.removeEventListener('click', onBlur);
  }, [onBlur]);

  const onClearIconClick = (event: MouseEvent) => {
    onClear();
    if (isOpen) {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  return (
    <div ref={containerRef} style={{ position: 'relative', width: '100%' }}>
      <SearchField
        name="global-search"
        endAdornment={
          <EndAdornment
            showCloseIcon={Boolean(inputRef.current && inputRef.current.value)}
            onClear={onClearIconClick}
          />
        }
        classes={inputClasses}
        inputProps={{
          ref: inputRef,
          disabled,
          style: { backgroundColor: 'unset' },
          autocomplete: 'off',
        }}
        onChange={_onChange}
        onKeyDown={onKeyDown}
        onFocus={onOpen}
        {...props}
        data-qa="TYPEAHEAD"
      />
      <Paper className={containerClasses.dropdownContainer} elevation={3}>
        {
          // eslint-disable-next-line no-nested-ternary
          showTypeToSearchPlaceholder ? (
            <TypeToSearchMessage />
          ) : typeof children === 'function' ? (
            children({ onClose, onChange: updateInputOnSelect, onClear })
          ) : null
        }
      </Paper>
    </div>
  );
};

interface EndAdornmentProps {
  showCloseIcon: boolean;
  onClear: (event: MouseEvent) => void;
}

const adornmentId = 'custom-typeahead:end-adornment';
const EndAdornment = ({ showCloseIcon, onClear }: EndAdornmentProps) => (
  <InputAdornment position="end" style={{ margin: 0, paddingRight: 11 }}>
    {showCloseIcon ? (
      <CloseIcon
        onClick={onClear}
        style={{ fontSize: '2rem' }}
        id={adornmentId}
      />
    ) : (
      <SearchIcon style={{ fontSize: '2rem' }} />
    )}
  </InputAdornment>
);

const useInputStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.background.paper,
    border: `1px solid ${palette.notification.containerBorder}`,
    marginRight: '1rem',
    transition: 'box-shadow 100ms',
  },
  focused: {
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.3)',
  },
}));

const useContainerStyles = makeStyles(() => ({
  dropdownContainer: {
    maxHeight: ({ isOpen, ...params }: any) => {
      return isOpen ? deriveListHeight(params) : '0';
    },
    overflow: 'hidden',
    position: 'absolute',
    zIndex: 1000,
    width: '100%',
    transformOrigin: 'bottom',
  },
}));

interface DeriveListHeightParams {
  maxListHeight?: number | string;
  showTypeToSearchPlaceholder: boolean;
}

const deriveListHeight = ({
  maxListHeight,
  showTypeToSearchPlaceholder,
}: DeriveListHeightParams) => {
  if (showTypeToSearchPlaceholder) {
    return '5rem';
  }
  return maxListHeight;
};
