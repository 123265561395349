import React from 'react';

import { Box, DialogActions, DialogContent, Fade } from '@mui/material';
import { styled, withStyles, makeStyles } from '@mui/styles';
import Color from 'color';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { compose } from 'redux';

import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import CbButtonBase from '../../Buttons/CbButton';
import { useSteps } from '../../hooks/useSteps';
import { withShowable } from '../../../console/_global/lib/withShowable';
import { SimpleSelect } from '../../inputs';
import { withFormController } from '../../hocs/forms';
import { InputLabelBase } from '../../inputs/InputLabelBase';
import { TextAreaBase } from '../../inputs/TextAreaBase';
import { withUploadFeature } from '../../fileUploader/withUploadFeature';
import { withUploadFeatureForms } from '../../fileUploader/withUploadFeatureForms';
import {
  FileUploadWithFormFields,
  useFileUploadFromOS,
} from '../../fileUploader/FileUpload';
import { useFileTypeIcons } from '../../fileUploader/useFileTypeIcons';
import { WarningTriangleIcon } from '../../../_assets/svg/WarningTriangle.svg';
import { uploadDocumentV2 } from '../../../api/DocumentsService';
import {
  putP250FlatCancel,
  getPolicyAmountPaid,
} from '../../../policies/PolicyService';
import LanguageCurrencyFieldBase from '../../inputs/LanguageCurrencyField';

const CbButton = withShowable(CbButtonBase);

const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);
const Select = withFormController(SimpleSelect);
const TextArea = withFormController(TextAreaBase);

const cancellationReasonSchema = Yup.object().shape({
  cancellationReason: Yup.string()
    .required()
    .label('Cancellation Reason')
    .typeError('Cancellation Reason is a required field'),
  docType: Yup.string()
    .required()
    .label('Document Type')
    .typeError('Document type is a required field'),
});

const cancelPolicySchema = Yup.object().shape({
  docType: Yup.string()
    .required()
    .label('Document Type')
    .typeError('Document type is a required field'),
});

const stepsLegend = {
  1: 'Cancellation Reason',
  2: 'Cancel Policy',
};

const cancellationReasonOptions = [
  {
    value: 'NON_PAYMENT',
    label: 'Non-payment',
  },
  {
    value: 'INSURED_REQUESTED',
    label: 'Insured Requested',
  },
];

const cancelPolicyDocTypeOptions = [
  {
    value: 'Cancellation_Certificate',
    label: 'Cancellation Certificate',
  },
];

export const PolicyFlatCancelModal = ({ data: policy, close }) => {
  const { step, steps, goForward } = useSteps({
    startStep: 1,
    initialSteps: [1, 2],
  });
  const { enqueueSnackbar } = useSnackbar();

  const [multiFormState, setMultiFormState] = React.useState({});
  const [cancellationReasonFile, setcancellationReasonFile] =
    React.useState(null);
  const [cancelPolicyFile, setcancelPolicyFile] = React.useState(null);
  const [isSubmittingForm, setisSubmittingForm] = React.useState(false);

  const onSubmitCancellationReason = (formValues) => {
    setMultiFormState({ cancellationReason: { ...formValues } });
    goForward();
  };

  const promiseMeNoError = async (promise) => {
    try {
      const data = await promise;
      return [data, null];
    } catch (err) {
      return [null, err];
    }
  };

  const onSubmitCancelPolicy = async (formValues) => {
    setisSubmittingForm(true);
    const { accountId, agencyId } = policy;
    const finalFormValues = {
      ...multiFormState,
      cancelPolicy: {
        ...formValues,
      },
    };

    const { cancelPolicy, cancellationReason } = finalFormValues;

    const cancelPolicyFormBody = new FormData();
    cancelPolicyFormBody.append('file', cancelPolicyFile);

    const cancelPolicyS3UploadPromise = uploadDocumentV2(
      {
        accountId,
        docType: cancelPolicy.docType,
        docName: cancelPolicy.fileName,
        temp: true,
        agencyId,
      },
      cancelPolicyFormBody
    );

    const promises = [cancelPolicyS3UploadPromise];

    if (cancellationReasonFile) {
      const cancellationReasonFormBody = new FormData();
      cancellationReasonFormBody.append('file', cancellationReasonFile);

      const cancellationReasonS3UploadPromise = uploadDocumentV2(
        {
          accountId,
          docType: cancellationReason.docType,
          docName: cancellationReason.fileName,
          temp: true,
          agencyId,
        },
        cancellationReasonFormBody
      );

      promises.push(cancellationReasonS3UploadPromise);
    }

    const [filesUploadResp, filesUploadErrors] = await promiseMeNoError(
      Promise.all(promises)
    );

    if (filesUploadErrors) {
      return enqueueSnackbar('Failed to upload documents', {
        variant: 'error',
      });
    }

    putP250FlatCancel(
      {
        policyId: policy.id,
      },
      {
        cancelReason: finalFormValues.cancellationReason.cancellationReason,
        cancelDescription: finalFormValues.cancellationReason.description,
        ...(filesUploadResp.length > 1 && {
          cancellationDocument: {
            ...filesUploadResp[1].data,
          },
        }),
        cancellationCertificate: {
          ...filesUploadResp[0].data,
        },
      }
    )
      .then(() => {
        policy.handleTableRefetch();
        enqueueSnackbar('Flat Cancel Successful', { variant: 'success' });
        close();
      })
      .catch(() => {
        enqueueSnackbar('Failed to cancel policy', { variant: 'error' });
      })
      .finally(() => {
        setisSubmittingForm(false);
      });
  };

  return (
    <>
      <DialogContent>
        <CancellationReason
          show={step === 1}
          policy={policy}
          setFile={setcancellationReasonFile}
          setFormState={setMultiFormState}
          onSubmit={onSubmitCancellationReason}
        />
        <CancelPolicy
          show={step === 2}
          policy={policy}
          setFile={setcancelPolicyFile}
          onSubmit={onSubmitCancelPolicy}
        />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Box>
            Step {step} of {steps.length}: {stepsLegend[step]}
          </Box>
          <Box display="flex">
            <CbButton onClick={close} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton
              show={step === 1}
              disabled={
                multiFormState.cancellationReason?.cancellationReason ===
                  'INSURED_REQUESTED' && !cancellationReasonFile
              }
              type="submit"
              form="cancellationReason"
              styleName="ctaButton"
              buttonText="Next"
            />
            <CancelButton
              show={step === 2}
              disabled={!cancelPolicyFile}
              type="submit"
              form="cancelPolicy"
              styleName="ctaButton"
              loading={isSubmittingForm}
              buttonText="Cancel Policy"
            />
          </Box>
        </Box>
      </DialogActions>
    </>
  );
};

const CancellationReason = withShowable(
  compose(
    withUploadFeatureForms(),
    withUploadFeature({
      screenCenter: false,
      hide: false,
      acceptFileTypes: `application/pdf, image/jpeg, image/png,`,
    })
  )(({ onSubmit, policy, setFile, setFormState }) => {
    const { file, fileDetails, handleUploadFile, handleReUploadFile } =
      useFileUploadFromOS();

    const {
      getValues,
      watch,
      reset,
      handleSubmit,
      formState: { errors },
      ...methods
    } = useForm({
      resolver: yupResolver(cancellationReasonSchema),
      defaultValues: {
        cancellationReason: cancellationReasonOptions[0].value,
        docType: 'LPR',
      },
    });

    React.useEffect(() => {
      if (fileDetails) {
        setFile(file);
        return reset({ ...getValues(), fileName: file.name });
      }
      reset({ ...getValues(), fileName: undefined });
      setFile(null);
      // eslint-disable-next-line
    }, [fileDetails, file]);

    const RenderFileIcon = useFileTypeIcons(fileDetails?.extension);

    const classes = useClasses();

    const cancellationReasonCurField = watch('cancellationReason');

    const { data: refundAmount } = useQuery(
      ['policyPaidAmount', policy.id],
      () =>
        getPolicyAmountPaid({ policyId: policy.id }).then(({ data }) => data),
      {
        refetchOnWindowFocus: false,
      }
    );

    React.useEffect(() => {
      if (refundAmount < 1) {
        setFile(null);
        reset({
          ...getValues(),
          fileName: undefined,
          refund: 0,
          cancellationReason: cancellationReasonOptions[0].value,
        });
      } else if (refundAmount > 0) {
        reset({
          ...getValues(),
          refund: refundAmount,
          cancellationReason: cancellationReasonOptions[1].value,
        });
      }
      setFormState({
        cancellationReason: {
          ...getValues(),
        },
      });
      // eslint-disable-next-line
    }, [cancellationReasonCurField, refundAmount]);

    return (
      <FormProvider {...methods} getValues={getValues}>
        <form id="cancellationReason" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Header>{policy.companyName}</Header>
            <Header>
              Policy Number <BlueText>{`${policy.policyNumber}`}</BlueText>
            </Header>
          </Box>
          <Box
            mt={1}
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box width="48%">
              <Select
                name="cancellationReason"
                label="Cancellation Reason"
                control={methods.control}
                fullWidth
                classes={{
                  select: classes.select,
                  icon: classes.selectIcon,
                }}
                options={
                  refundAmount > 1
                    ? [cancellationReasonOptions[1]]
                    : [cancellationReasonOptions[0]]
                }
                required
                inputProps={{ indent: false }}
                ErrorProps={{ renderErrorPlaceholder: true }}
              />
            </Box>
            <Box width="48%" ml={1}>
              <InputLabelBase required>Refund</InputLabelBase>
              <LanguageCurrencyField
                classes={classes}
                fullWidth
                id="refund"
                name="refund"
                placeholder="Refund"
                disabled
              />
            </Box>
          </Box>
          {cancellationReasonCurField !==
            cancellationReasonOptions[0].value && (
            <FileUploadWithFormFields
              file={file}
              fileDetails={fileDetails}
              errors={errors}
              handleUploadFile={handleUploadFile}
              handleReUploadFile={handleReUploadFile}
              RenderFileIcon={RenderFileIcon}
            />
          )}
          <Box mt={1} width="100%">
            <InputLabelBase>Description</InputLabelBase>
            <StyledTextArea
              name="description"
              minRows={5}
              placeholder="Description..."
            />
          </Box>
        </form>
      </FormProvider>
    );
  })
);

const CancelPolicy = withShowable(
  compose(
    withUploadFeatureForms(),
    withUploadFeature({
      screenCenter: false,
      hide: false,
      acceptFileTypes: `application/pdf, image/jpeg, image/png,`,
    })
  )(({ policy, onSubmit, setFile }) => {
    const { file, fileDetails, handleUploadFile, handleReUploadFile } =
      useFileUploadFromOS();

    const {
      getValues,
      reset,
      handleSubmit,
      formState: { errors },
      ...methods
    } = useForm({
      resolver: yupResolver(cancelPolicySchema),
      defaultValues: {
        docType: 'LPR',
      },
    });

    React.useEffect(() => {
      if (fileDetails) {
        setFile(file);
        return reset({
          ...getValues(),
          fileName: file.name,
          docType: cancelPolicyDocTypeOptions[0].value,
        });
      }
      reset({ ...getValues(), fileName: undefined, file: undefined });
      setFile(null);
      // eslint-disable-next-line
    }, [fileDetails, file]);

    const RenderFileIcon = useFileTypeIcons(fileDetails?.extension);

    return (
      <FormProvider {...methods}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <WarningTriangleIcon />
          <Box mt={2} width="80%" style={{ textAlign: 'center' }}>
            <Header>Are you sure you want to Flat Cancel the</Header>
            <Header>
              Policy Number <BlueText>{`${policy.policyNumber}`}</BlueText>
              {` for ${policy.companyName}?`}
            </Header>
          </Box>
        </Box>
        <form id="cancelPolicy" onSubmit={handleSubmit(onSubmit)}>
          <FileUploadWithFormFields
            file={file}
            fileDetails={fileDetails}
            errors={errors}
            handleUploadFile={handleUploadFile}
            handleReUploadFile={handleReUploadFile}
            RenderFileIcon={RenderFileIcon}
            FileUploadHeaderText={CancelPolicyHeader}
            docTypeOptions={cancelPolicyDocTypeOptions}
          />
        </form>
      </FormProvider>
    );
  })
);

const CancelPolicyHeader = () => {
  return (
    <Fade in timeout={1000}>
      <Box width="100%" display="flex" justifyContent="center">
        <Box
          width="75%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <FileUploadHeader required>
            Add Cancellation Certificate
          </FileUploadHeader>
          <FileUploadSubHeader>
            Please upload the ‘Cancellation Certificate’, this will be attached
            to the email.
          </FileUploadSubHeader>
        </Box>
      </Box>
    </Fade>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  root: {
    padding: 0,
    margin: 0,
    width: '100%',
  },
  select: {
    justifyContent: 'left',

    '&.MuiInputBase-input': {
      backgroundColor: palette.background.active,
    },

    '&:focus': {
      borderRadius: 5,
      backgroundColor: palette.background.active,
    },
  },
  selectIcon: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

const StyledTextArea = styled(TextArea)(({ theme }) => ({
  backgroundColor: theme.palette.background.modal,
  resize: 'none',
  width: '100%',
  padding: '0.25rem 0.5rem',
  '&::-webkit-input-placeholder': {
    color: theme.palette.primary.main,
  },
  '&:focus': {
    outline: 'none',
    backgroundColor: theme.palette.background.darkerBlue,
  },
}));

const Header = styled('p')(({ theme: { palette, config } }) => ({
  fontSize: config.textSizes.primer,
  color: palette.primary.main,
  margin: 0,
  padding: 0,
}));

const CancelButton = styled(CbButton)(({ theme: { palette } }) => ({
  minWidth: '12rem',
  backgroundColor: palette.background.red,
  color: palette.primary.main,
  '&:hover': {
    backgroundColor: Color(palette.background.red).darken(0.2).string(),
  },
}));

export const PolicyFlatCancelModalConfig = {
  PolicyFlatCancelModal: {
    component: PolicyFlatCancelModal,
    config: {
      fullWidth: true,
      title: 'Flat Cancellation',
    },
  },
};

const FileUploadHeader = styled(InputLabelBase)(({ theme: { config } }) => ({
  fontSize: config.textSizes.paragon,
}));

const FileUploadSubHeader = styled(Header)(
  ({ theme: { palette, config } }) => ({
    fontSize: config.textSizes.normal,
    color: palette.text.text9,
    margin: '0.75rem 0 1.25rem 0',
  })
);

const BlueText = styled('span')(({ theme: { palette } }) => ({
  color: palette.text.text12,
}));
