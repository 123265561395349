import type { BoxProps, SvgIconProps } from '@mui/material';
import { Box, styled } from '@mui/material';
import color from 'color';
import { useActor } from '../hooks/useActor';
import { useGetAccountDetailsQuery } from '../../queries/useGetAccountDetailsQuery';
import {
  ThemedSpotlightIcon,
  type ThemedSpotlightIconProps,
} from './SpotlightBanner';

export default function SpotlightNotification({
  containerProps,
  accountId,
  ...props
}: ThemedSpotlightIconProps & { accountId?: string }) {
  const actor = useActor();

  const { data, isError } = useGetAccountDetailsQuery({
    accountId: accountId || actor.account.id,
  });

  if (isError) return null;

  if (!data?.spotlightIds?.length) return null;

  return (
    <Container {...containerProps}>
      <ThemedSpotlightIcon {...props} />
    </Container>
  );
}

export const SpotlightNotificationIcon = ({
  containerProps,
  ...props
}: ThemedSpotlightIconProps & { containerProps: BoxProps }) => {
  return (
    <Container {...containerProps}>
      <ThemedSpotlightIcon {...props} />
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  height: '1.85rem',
  width: '1.85rem',
  border: `1.5px solid ${
    theme.palette.mode === 'light'
      ? color(theme.palette.warning.main).darken(0.2).toString()
      : theme.palette.warning.main
  }`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '1.85rem',
}));
