import { Api } from '../config/axiosConfig';

export function postInitialImage(file) {
  return Api.post(`/api/user/v1/pic/initial`, file);
}

export function uploadImage(req, payload) {
  return Api.post(
    `/api/user/v1/pic/crop?xCoordinate=${req.xCoordinate}&yCoordinate=${req.yCoordinate}`,
    payload
  );
}

export function getProfilePhoto() {
  return Api.get(`/api/user/v1/pic`);
}

export function getProfilePhotoByEmail(email) {
  return Api.get(`/api/user/v1/pic/email/${email}`);
}

export function delProfilePhoto() {
  return Api.delete(`/api/user/v1/pic`);
}
