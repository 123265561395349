import { ServiceHandlerFactory } from '../utils/serviceUtils';
import { fetchServiceParamsMapping } from '../console/activity/activityServiceMappings';

export const fetchActivityDetails = ServiceHandlerFactory({
  axios: {
    url: '/api/activity/v1/search',
  },
  isCancelable: true,
  paramSchema: fetchServiceParamsMapping,
});

export const fetchActivityAggregation = ServiceHandlerFactory({
  axios: {
    url: '/api/activity/v1/aggregateEvents',
  },
  isCancelable: true,
  paramSchema: fetchServiceParamsMapping,
});
