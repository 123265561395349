import * as Ramda from 'ramda';

import { transformToOption } from './functional/fp';
import { currencyFormatter } from '../i18n/currencies';

import type { JsonApiSpecResponse } from '../types';

export function createUiProps(
  keys: string[] = [],
  callback = (value: any): any => undefined
) {
  return ({ data, ...rest }: JsonApiSpecResponse): JsonApiSpecResponse => {
    if (Array.isArray(data)) {
      return {
        data: data.map(
          (d: { [k: string]: any }) => ({
            ...d,
            ...keys.reduce(
              (acc, key: string) => ({
                ...acc,
                [`ui_${key}`]: callback(d[key]),
              }),
              {}
            ),
          }),
          {}
        ),
        ...rest,
      };
    }

    if (typeof data === 'object') {
      return {
        data: {
          ...data,
          ...keys.reduce(
            (acc, key: string) => ({
              ...acc,
              [`ui_${key}`]: callback(data[key]),
            }),
            {}
          ),
        },
      };
    }

    return { data, ...rest };
  };
}

export const formatToGBPOption = transformToOption<number>(
  currencyFormatter.gbp.format,
  Ramda.identity
);

export const formatToHoursOption = transformToOption<number>(
  (val) => `${val} Hours`,
  Ramda.identity
);
