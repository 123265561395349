import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Paper, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';

import { Checkbox } from '../../inputs';
import SelectClasses from './MultiSelectStyles';

const Blanket = (props) => <Paper {...props} />;

export const Menu = withStyles(SelectClasses)((props) => {
  return (
    <Paper square className={props.classes.paper}>
      {props.children}
    </Paper>
  );
});

export function Dropdown({ children, isOpen, target }) {
  return (
    <div style={{ position: 'relative' }}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket /> : null}
    </div>
  );
}

export function DropdownIndicator() {
  return <SearchIcon />;
}

export function Option(props) {
  return (
    <MenuItem
      className={props.selectProps.classes.menuItem}
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.isMulti ? (
        <React.Fragment>
          <Checkbox checked={props.isSelected} /> {props.children}
        </React.Fragment>
      ) : (
        <div
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          {props.children}
        </div>
      )}
    </MenuItem>
  );
}

export const MenuList = withStyles(SelectClasses)((props) => {
  const { selectProps } = props;
  const { value, options } = selectProps;
  const allSelected = value.length === options.length;

  return (
    <div className={props.selectProps.classes.menuList} id="menu-list">
      {props.selectProps.supportSelectAll && (
        <Box
          className={props.classes.newLegendItem}
          onClick={selectProps.toggleAllHandler}
        >
          <Checkbox checked={allSelected} />
          <p className={props.classes.legendText}>
            {allSelected ? 'Unselect All' : 'Select All'}
          </p>
        </Box>
      )}
      {props.children}
    </div>
  );
});
