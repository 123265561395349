import React from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
import _ from 'lodash';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { styled, withStyles, makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { compose } from 'redux';
import { regenerateProposal } from '../../../inbox/QuotesService';
import CbButton from '../../Buttons/CbButton';

export const GenerateQuoteProposal = compose(
  withStyles(({ config }) => ({
    highlight: {
      color: config.colors.cowbellBlue,
      fontWeight: config.weights.bold,
    },
  }))
)(({ data, classes, ...props }) => {
  const { id, product, quoteNumber } = data;
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, ...methods } = useForm({});

  const onSubmit = () => {
    regenerateProposal({ id, product })
      .then(() => {
        enqueueSnackbar(
          'Quote submitted for Proposal Regeneration!. Updated proposal documents will be ready in about 2 minutes',
          { variant: 'success' }
        );
        props.close();
      })
      .catch((error) => {
        enqueueSnackbar(
          _.get(
            error,
            'response.data',
            'Proposal Document Regeneration request failed!'
          ),
          {
            variant: 'error',
          }
        );
      });
  };

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className="paf">
            <DialogContentText>
              Are you sure you want to regenerate the proposal documents for
              Quote Number{' '}
              <span className={classes.highlight}>{quoteNumber}</span> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CbButton onClick={props.close} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton type="submit" styleName="ctaButton">
              Regenerate
            </CbButton>
          </DialogActions>
        </form>
      </FormContext>
    </>
  );
});

export const GenerateQuoteProposalModalConfig = {
  GenerateQuoteProposal: {
    component: GenerateQuoteProposal,
    config: {
      fullWidth: true,
      title: 'Regenerate Proposal',
    },
  },
};
