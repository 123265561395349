import React from 'react';
import { Box } from '@mui/material';

export const SecondaryToolbar = ({ style = {}, children, ...props }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{ marginBottom: '1rem', ...style }}
      {...props}
    >
      {children}
    </Box>
  );
};
