import React, { PureComponent } from 'react';
import { withSnackbar } from 'notistack';
import { compose } from 'redux';
import { DialogContent, DialogActions } from '@mui/material';
import { createBlackListedDomains } from '../../api/apis';
import { publishDelayedEvent } from '../../utils/eventUtils';

import CBButton from '../Buttons/CbButton';

import { TextField } from '../inputs';
import { withToggleModal } from '../hocs/withToggleModal';

const initialFormState = {
  name: '',
};

class BlackListCreateModal extends PureComponent {
  state = {
    form: initialFormState,
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { disabled } = this.state;

    return (
      <DialogContent>
        <TextField
          label="Name"
          placeholder="example.com"
          value={this.state.form.name}
          required
          onChange={this.handleChange('name')}
        />
        <DialogActions>
          <CBButton
            styleName="cancel"
            action={this.props.toggleModal.deferred('BlackListCreate', false)}
            buttonText="Cancel"
            size="large"
          />
          <CBButton
            loading={disabled}
            disabled={disabled}
            action={this.handleSubmit}
            type="submit"
            styleName="ctaButton"
            buttonText="Blacklist Domain"
            size="large"
          />
        </DialogActions>
      </DialogContent>
    );
  }

  handleChange(field) {
    const { state } = this;

    return (event) => {
      this.setState({
        form: {
          ...state.form,
          [field]: event.target.value,
        },
      });
    };
  }

  handleSubmit() {
    const { state } = this;
    this.setState({
      disabled: true,
    });

    createBlackListedDomains({}, state.form)
      .then(() => {
        // reset the form
        this.setState({ form: initialFormState, disabled: false });

        // fetch new results
        publishDelayedEvent('table:force-fetch-domains');

        // tell user operation was successful
        this.props.enqueueSnackbar('Domain successfully blacklisted', {
          variant: 'success',
        });

        // close the modal
        this.props.toggleModal.direct('BlackListCreate', false);
      })
      .catch(() => {
        this.props.toggleModal.direct('BlackListCreate', false);
        this.props.enqueueSnackbar(
          'Failed to blacklist domain. Please try again.',
          { variant: 'error' }
        );
        this.setState({
          disabled: false,
        });
      });
  }
}

BlackListCreateModal.defaultProps = {
  data: {},
};

export default compose(withSnackbar, withToggleModal)(BlackListCreateModal);
