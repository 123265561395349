import React from 'react';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';

// mui
import {
  Box as MuiBox,
  DialogActions,
  DialogContent as MuiDialogContent,
  styled,
} from '@mui/material';
import {
  CloudDownload as CloudDownloadIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';

// components
import { useSnackbar } from 'notistack';
import { Checkbox } from '../inputs';
import CbButton from '../Buttons/CbButton';
import { DocumentDownloader } from '../fileUploader/DocumentDownloader';
import useUploadZone from '../fileUploader/v2/hooks/useUploadzone';
import {
  downloadNKLLTemplate,
  uploadDocumentV3,
} from '../../api/documents.api';
import { Uploadzone } from '../fileUploader/v2/UploadZone';
import TextFieldFilePreview from '../../console/workflows/bind-quote/stepper-screens/shared/TextFieldFilePreview';
import FullScreenDropZoneWrapper from '../fileUploader/v2/FullScreenDropZoneWrapper';
import { withShowable } from '../../console/_global/lib/withShowable';
import CheckBoxLabel from '../../console/workflows/bind-quote/CheckBoxLabel';
import {
  acceptedUploadDocs,
  noKnowLossLetterCheckboxLabel,
} from '../../console/workflows/bind-quote/constants/constants';

// services
import { completeQuoteSubjectivity } from '../../api';
import { ProductTypes } from '../../types';

const Box = withShowable(MuiBox);

const signedDocumentSchema = Yup.object({
  'docSignedOn-0': Yup.string().label('Date signed').required(),
});

const P100NKLLUploadModal = ({ data: modalData = {}, close }) => {
  const [isNKLLAcknowledged, setIsNKLLAcknowledged] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { files, ...uploadZoneParams } = useUploadZone({
    docType: 'No_Known_Loss_Letter',
  });

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(signedDocumentSchema),
  });

  const handleCheckboxChange = () => {
    setIsNKLLAcknowledged((prevState) => !prevState);
  };

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    // need to upload docs
    const appUploadParams = {
      accountId: modalData.quoteDetails?.accountId,
      agencyId: modalData.quoteDetails?.agencyId,
      docType: 'Application',
      docName: modalData.appDocName,
      temp: true,
    };

    const nkllUploadParams = {
      accountId: modalData.quoteDetails?.accountId,
      agencyId: modalData.quoteDetails?.agencyId,
      docType: 'No_Known_Loss_Letter',
      docName: formValues[`fileName-0`],
      temp: true,
    };

    const insuranceAppFormData = new FormData();
    const nkllFormData = new FormData();

    insuranceAppFormData.append('file', modalData.appFile);
    nkllFormData.append('file', files[0]);

    const [applicationResponse, nkllResponse] = await Promise.all([
      uploadDocumentV3({
        data: insuranceAppFormData,
        params: appUploadParams,
      }),
      uploadDocumentV3({
        data: nkllFormData,
        params: nkllUploadParams,
      }),
    ])
      .then((responses) => {
        enqueueSnackbar('Documents uploaded successfully', {
          variant: 'success',
        });
        return responses;
      })
      .catch(() => {
        enqueueSnackbar(
          'Something went wrong while uploading documents. Please try again later',
          { variant: 'error' }
        );
      });

    const uploadedDocuments = [
      {
        docId: applicationResponse.data.docId,
        docSignedOn: modalData['docSignedOn-0'],
      },
      {
        docId: nkllResponse.data.docId,
        docSignedOn: formValues['docSignedOn-0'],
      },
    ];

    const subjectivityPayload = {
      uploadedDocuments,
      nkllIsIncluded: false,
      userAttested: true,
      signeeFullName: modalData.signeeFullName || null,
      signeeTitle: modalData.signeeTitle || null,
    };

    completeQuoteSubjectivity(
      ProductTypes.p100,
      modalData.quoteSubjectivityId,
      {
        data: subjectivityPayload,
      }
    )
      .then(async () => {
        await queryClient.invalidateQueries([
          'quote-progress',
          modalData.quoteDetails.id,
        ]);

        close();

        setTimeout(() => {
          modalData.goForward();
        }, 500);

        enqueueSnackbar('Step information saved!', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(
          'Unable to complete step at this time. Please try again later.',
          { variant: 'error' }
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className="contrast-text" files={!!files}>
          <h3 style={{ textAlign: 'center', paddingTop: '1rem' }}>
            Please upload a signed No Known Loss Letter
          </h3>
          <ButtonContainer files={!!files}>
            <Box>
              <Uploadzone
                {...uploadZoneParams}
                accept={acceptedUploadDocs}
                files={files}
                hasPreview={Boolean(files)}
                Preview={TextFieldFilePreview}
                Placeholder={<FullScreenDropzonePlaceholder />}
                previewProps={{
                  disabled: false,
                  docType: 'NKLL',
                  docsPreviouslyUploaded: false,
                  userSelectedFiles: !!files,
                  displayDatePicker: true,
                  min: modalData.dateMin,
                  max: modalData.dateMax,
                }}
              />
            </Box>
            <Box show={!files}>
              <DocumentDownloader
                type=".pdf"
                name="No Known Loss Letter"
                onDownload={() =>
                  downloadNKLLTemplate(modalData.nkllDownloadPath)
                }
              >
                {({ handleDocumentDownload }) => (
                  <>
                    <CbButton
                      styleName="ctaButton"
                      size="large"
                      endIcon={<CloudDownload />}
                      onClick={handleDocumentDownload}
                    >
                      Download
                    </CbButton>
                    <SupportedText>
                      Download Blank No Known Loss Letter
                    </SupportedText>
                  </>
                )}
              </DocumentDownloader>
            </Box>

            <Box show={!!files}>
              <Checkbox
                name="noKnownLossLetterConfirmation"
                label={<CheckBoxLabel label={noKnowLossLetterCheckboxLabel} />}
                onChange={handleCheckboxChange}
                checked={isNKLLAcknowledged}
              />
            </Box>
          </ButtonContainer>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" size="medium" onClick={close}>
            Cancel
          </CbButton>
          <CbButton
            styleName="ctaButton"
            size="medium"
            disabled={!files || !isNKLLAcknowledged || isLoading}
            loading={isLoading}
            type="submit"
          >
            Upload
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default P100NKLLUploadModal;

export const P100NKLLUploadModalConfig = {
  P100NKLLUploadModal: {
    component: P100NKLLUploadModal,
    config: {
      maxWidth: 'md',
      minWidth: 'md',
    },
  },
};

const FullScreenDropzonePlaceholder = () => {
  return (
    <Box style={{ width: '20rem', textAlign: 'center' }}>
      <FullScreenDropZoneWrapper>
        <CbButton
          styleName="ctaButton"
          buttonText="Upload Document"
          size="large"
          endIcon={<CloudUpload />}
        />
        <SupportedText>
          Supported File Types: PNG, JPG, JPEG, PDF, DOC
        </SupportedText>
      </FullScreenDropZoneWrapper>
    </Box>
  );
};

const DialogContent = styled(MuiDialogContent)(({ files }) => {
  return {
    padding: '2rem 5rem',
    textAlign: files ? 'left' : 'center',
  };
});

const ButtonContainer = styled('div')(({ files }) => {
  return {
    display: files ? 'block' : 'flex',
    justifyContent: 'center',
    gap: '2rem',
    paddingTop: '1rem',
  };
});

const SupportedText = styled('p')({
  textAlign: 'center',
});

const CloudUpload = styled(CloudUploadIcon)({
  pointerEvents: 'none',
});

const CloudDownload = styled(CloudDownloadIcon)({
  pointerEvents: 'none',
});
