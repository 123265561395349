import { Box, styled } from '@mui/material';
import { CheckCircleOutlined as CheckCircleOutlinedIcon } from '@mui/icons-material';
import Moment from 'moment';
import React from 'react';
import Showable from '../../../../../components/Showable';
import {
  FORMAT_DATE_AND_TIME,
  toUniversalDate,
} from '../../../../../utils/date.utils';

export const SubjectivityCompletionDate = ({
  allSubjectivityInformation = {},
  completionText = 'Subjectivity Completed On:',
}) => {
  const uiCompletionDate = allSubjectivityInformation?.completedOn
    ? toUniversalDate(allSubjectivityInformation.completedOn, {
        format: FORMAT_DATE_AND_TIME,
      })
    : '-';

  return (
    <Showable show={Boolean(allSubjectivityInformation?.completedOn)}>
      <CompletionContainer component="p">
        <CheckCircleOutlined /> {completionText} {uiCompletionDate}
      </CompletionContainer>
    </Showable>
  );
};

const CheckCircleOutlined = styled(CheckCircleOutlinedIcon)(({ theme }) => ({
  color: theme.config.colors.cowbellAccent,
  pointerEvents: 'none',
}));

const CompletionContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
});
