export const CoverageInfoMapping = {
  'Total Number of Coverages': {
    tooltip: `To see the details of coverages, please click on 'View Quote Details' button below.`,
  },
  'Total Number of Endorsements': {
    tooltip: `To see the details of endorsements, please click on 'View Quote Details' button below.`,
  },
  'Aggregate Limit': {
    tooltip: `The Aggregate Limit shown here is the most the Insurer(s) will pay regardless of the number of Insured Agreements purchased. For details regarding individual coverage limit, please click on 'View Quote Details' button below.`,
  },
  Deductible: {
    tooltip: `To view coverage specific deductible, please click on 'View Quote Details' button below.`,
  },
  Retention: {
    tooltip: `To view coverage specific retention, please click on 'View Quote Details' button above.`,
  },
};

export const DetailsHeaderMapping = {
  'Aggregate Limit': {
    tooltip:
      'The Aggregate Limit shown here is the most the Insurer(s) will pay regardless of the number of Insured Agreements purchased. For details regarding individual coverage limit, please see below.',
  },
  Deductible: {
    tooltip: 'View coverage specific deductible, below.',
  },
};
