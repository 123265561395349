import React, { useState } from 'react';
import _ from 'lodash';
import { DialogActions, DialogContent, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/styles';

import { compose } from 'redux';
import { useForm, FormContext } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
import { delayedEvent } from '../../../../utils/eventUtils';
import CBButton from '../../../Buttons/CbButton';
import { iconMappings } from '../../../../console/_global/navigation/IconsMappings';
import { delete250preset } from '../../../../api/p250.service.js';

export const DeletePresetsModal = compose(
  withStyles(({ palette, config }) => ({
    texts: {
      color: palette.primary.contrastText,
      textSize: config.bigCanon,
      weights: config.normal,
    },
    spans: {
      color: palette.common.cowbellBlue,
      textSize: config.greatPrimer,
      weights: config.bold,
    },
  }))
)(({ classes, ...props }) => {
  const [backendError, setBackendError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { ...methods } = useForm();
  const { data } = props.data;

  function onSubmit(id) {
    delete250preset({ id })
      .then(() => {
        props.close();
        makeRefreshCall();
        enqueueSnackbar('Succesfully deleted preset', { variant: 'success' });
      })
      .catch((error) => {
        setBackendError(_.get(error, 'response.data'));
      });
  }
  return (
    <>
      <FormContext {...methods}>
        <DialogContent>
          <div style={{ alignItems: 'center' }}>
            <h3 className={classes.texts}>
              Are you sure you want to delete the{' '}
              <span className={classes.spans}>{data.coverage.name}</span>{' '}
              Preset?
            </h3>
          </div>
          <FormHelperText className="api-text-error">
            {backendError}
          </FormHelperText>
        </DialogContent>

        <DialogActions>
          <CBButton size="small" styleName="cancel" onClick={props.close}>
            Cancel
          </CBButton>

          <CBButton
            size="small"
            type="submit"
            styleName="ctaButton"
            onClick={() => {
              onSubmit(data.id);
            }}
          >
            Delete
          </CBButton>
        </DialogActions>
      </FormContext>
    </>
  );
});

export const DeletePresetConfig = {
  DeletePresetsModal: {
    component: DeletePresetsModal,
    config: {
      maxWidth: 'sm',
      minWidth: 'xs',
      title: 'Delete Preset',
      icon: iconMappings.Delete.iconImport,
    },
  },
};

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, 'admin_presets');
}
