import React from 'react';
// ui
// components
import { FormContext, useForm } from 'react-hook-form-4';
import { DialogContent, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CheckBoxBase from '../../../../components/inputs/Checkbox';
import { withFormController } from '../../../../components/hocs/withFormController';
import CBButton from '../../../../components/Buttons/CbButton';
import { PubSub } from '../../../../utils/eventUtils';

const CheckBoxField = withFormController(CheckBoxBase);

const AddBankPrivacyConsent = ({ data, ...props }) => {
  const classes = useClasses();

  const [timeoutId, setTimeoutId] = React.useState(null);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
  } = useForm({ defaultValues: { agreement: false } });

  const agreement = watch('agreement');

  React.useEffect(() => {
    const sub = PubSub.subscribe('dismissAddBankPrivacyConsent', () => {
      props.close();
    });

    return () => {
      clearTimeout(timeoutId);
      sub.remove();
    };
  }, [props, timeoutId]);

  const onSubmit = () => {
    PubSub.publish('addBankPrivacyConsent', null);
    const delayPromise = (delay) => {
      return new Promise((resolve) => {
        setTimeoutId(setTimeout(resolve, delay));
      });
    };
    return delayPromise(30000).then(() => {});
  };

  const handleCancel = () => {
    props.close();
  };

  const { ConsentText } = data;

  return (
    <section className={classes.cardContentContainer}>
      <FormContext>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent classes={{ root: classes.container }}>
            <ConsentText />
            <section
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CheckBoxField
                control={control}
                name="agreement"
                label="I have read and accept Cowbell’s Authorization for Direct Deposit via ACH."
              />
            </section>
          </DialogContent>

          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={!agreement || isSubmitting}
              styleName="ctaButton"
              buttonText="Accept"
            />
          </DialogActions>
        </form>
      </FormContext>
    </section>
  );
};

const useClasses = makeStyles(() => ({
  container: {
    flex: '1 1 auto',
    padding: '0 5rem 0.5rem 5rem',
    'overflow-y': 'visible',
  },
  inputFields: {
    justifyContent: 'space-around',
  },
}));

export const AddBankPrivacyConsentConfig = {
  AddBankPrivacyConsent: {
    component: AddBankPrivacyConsent,
    config: {
      minWidth: 'md',
      title: 'Authorization for Direct Deposit via ACH',
    },
  },
};
