import { useContext, useCallback } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { TableContext } from '../base/context.table';
import { triggerCSVDownload } from '../../../../providers/CSVDownloadSubWrapper';

export function withCsvDownload(filename) {
  return (Component) => {
    const C = (props) => {
      const context = useContext(TableContext);
      if (!context) {
        throw new Error(
          `You tried to use withCsvDownload(${
            Component.displayName || Component.name
          }) outside table context. Table context is required for csv downloads to work.`
        );
      }

      const { columns, data } = context;
      const dispatchDownloadAction = useCallback(() => {
        triggerCSVDownload({
          columns,
          data,
          filename: filename || `${context.id}-export`,
        });
      }, [columns, context.id, data]);

      return <Component {...props} onClick={dispatchDownloadAction} />;
    };

    C.displayName = `withCsvDownload(${
      Component.displayName || Component.name
    })`;
    C.wrappedComponent = Component;

    return hoistNonReactStatics(C, Component);
  };
}
