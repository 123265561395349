import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import type { TranslationKeys } from './constants';
import { TRANSLATION_KEYS } from './constants';

// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=QwcxOhsUb_4vq7qHbSpZKA`;
type Translation = Readonly<{
  translation: Record<TranslationKeys, string>;
}>;

const USResources = {
  translation: {
    [TRANSLATION_KEYS.agent]: 'Agent',
    [TRANSLATION_KEYS.agents]: 'Agents',
    [TRANSLATION_KEYS.agency]: 'Agency',
    [TRANSLATION_KEYS.revenue]: 'Revenue',
    [TRANSLATION_KEYS.organization]: 'Organization',
    [TRANSLATION_KEYS.agencies]: 'Agencies',
    [TRANSLATION_KEYS.retention]: 'Retention',
    [TRANSLATION_KEYS.zipCode]: 'Zip Code',
    [TRANSLATION_KEYS.customize]: 'Customize',
    [TRANSLATION_KEYS.usRevenue]: 'US Revenue',
    [TRANSLATION_KEYS.nonUsRevenue]: 'Non US Revenue',
    [TRANSLATION_KEYS.totalRevenue]: 'Total Revenue',
    [TRANSLATION_KEYS.check]: 'Check',
    [TRANSLATION_KEYS.checked]: 'Checked',
    [TRANSLATION_KEYS.checking]: 'Checking',
  },
} as Translation;

const GBResources = {
  translation: {
    [TRANSLATION_KEYS.agent]: 'Broker',
    [TRANSLATION_KEYS.agents]: 'Brokers',
    [TRANSLATION_KEYS.agency]: 'Brokerage',
    [TRANSLATION_KEYS.revenue]: 'Turnover',
    [TRANSLATION_KEYS.organization]: 'Organisation',
    [TRANSLATION_KEYS.agencies]: 'Brokerages',
    [TRANSLATION_KEYS.retention]: 'Excess',
    [TRANSLATION_KEYS.zipCode]: 'Postcode',
    [TRANSLATION_KEYS.customize]: 'Customise',
    [TRANSLATION_KEYS.usRevenue]: 'Annual Turnover UK',
    [TRANSLATION_KEYS.nonUsRevenue]: 'Annual Turnover Non-UK',
    [TRANSLATION_KEYS.totalRevenue]: 'Annual Turnover (Total)',
    [TRANSLATION_KEYS.check]: 'Tick',
    [TRANSLATION_KEYS.checked]: 'Ticked',
    [TRANSLATION_KEYS.checking]: 'Ticking',
  },
} as Translation;

export const resources = {
  'en-US': USResources,
  'en-GB': GBResources,
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    debug: true,
    fallbackLng: ['en-US'],
    supportedLngs: ['en-US', 'en-GB'],
    ns: ['translation'],
    defaultNS: 'translation',
    resources,
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
      ],
      caches: ['cookie'],
      cookieOptions: { path: '/', sameSite: 'strict' },
      cookieMinutes: 1,
    },
  });

export default i18n;
