import React from 'react';

// components
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CheckBoxBase from './Checkbox';

// icons
import CheckBoxOutlineBlankIcon from '../../_assets/svg/CheckBoxOutlineBlankIcon.svg';
import CheckBoxSelectedIcon from '../../_assets/svg/CheckBoxSelectedIcon.svg';
import CheckBoxFilledIcon from '../../_assets/svg/CheckBoxFilledIcon.svg';
import CheckBoxIndeterminateIcon from '../../_assets/svg/CheckBoxIndeterminateIcon.svg';

export const CustomCheckbox = ({ variant = 'base', ...props }) => {
  const theme = useTheme();
  const checkboxClasses = useCheckboxClasses();
  const checkedIcon = React.useMemo(() => {
    return variant !== 'filled' ? (
      <CheckBoxSelectedIcon />
    ) : (
      <CheckBoxFilledIcon />
    );
  }, [variant]);

  return (
    <CheckBoxBase
      classes={checkboxClasses}
      disableRipple
      checkedIcon={checkedIcon}
      icon={
        <CheckBoxOutlineBlankIcon
          fill={!props.disabled ? '#c9cdd9' : theme.palette.icon.secondary}
        />
      }
      indeterminateIcon={<CheckBoxIndeterminateIcon />}
      {...props}
    />
  );
};

const useCheckboxClasses = makeStyles(() => ({
  root: {
    // padding: 5,
  },
}));
