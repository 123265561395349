import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, Dialog, DialogContent } from '@mui/material';
import { styled, makeStyles } from '@mui/styles';

import { useQuery } from '@tanstack/react-query';
import { P100PolicyMidTermEndorsementDrawer } from '../../console/_global/endorsements/P100PolicyMidTermEndorsementDrawer';
import { fetchPolicyDetails } from '../../console/policies/drawers/policyholder/PolicyDrawer';

export const PolicyMTE = ({ data: { policyId, product }, close, ...props }) => {
  const classes = useClasses();

  const { data: policy, isLoading } = useQuery(
    ['policy', policyId],
    () => fetchPolicyDetails({ policyId, product }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Dialog classes={classes} maxWidth="false" {...props}>
      <DialogContent>
        {isLoading ? (
          <LoadingContainer>
            <CircularProgress size="4.5rem" />
          </LoadingContainer>
        ) : (
          <>
            <CloseButton onClick={close}>
              <CloseIcon />
            </CloseButton>
            <Box pt={2}>
              <P100PolicyMidTermEndorsementDrawer
                policy={{ ...policy, ...policy.coverages }}
              />
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const useClasses = makeStyles(() => ({
  paper: { width: '47rem' },
}));

const CloseButton = styled('button')(({ theme: { palette } }) => ({
  height: '1.9rem',
  width: '1.9rem',
  border: `1px solid ${palette.primary.modalBorder}`,
  backgroundColor: palette.background.default,
  borderRadius: '1rem',
  position: 'absolute',
  right: 12,
  top: '0.8rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '0.166rem',
  color: palette.primary.modalBorder,
}));

const LoadingContainer = styled('div')({
  height: '40rem',
  width: '25rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const PolicyMTEConfig = {
  PolicyMTE: {
    component: PolicyMTE,
    config: {
      fullWidth: true,
      override: true,
    },
  },
};
