import React from 'react';
import { useSnackbar } from 'notistack';
import { DialogActions, DialogContent } from '@mui/material';

import _ from 'lodash';
import CBButton from '../../Buttons/CbButton';
import { iconMappings } from '../../../console/_global/navigation/IconsMappings';

import { signUpWizer } from '../../../policyholders/training/wizerService.js';
import { Modal } from './helpers/v2.modal.helpers.js';
import { manageAPIError } from '../../../utils';

export const WizerSignUp = ({ iconStyles, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = () => {
    signUpWizer()
      .then(({ resp }) => {
        Modal.hide('WizerSignUp');
        enqueueSnackbar('Succesfully signed up!', { variant: 'success' });
        setTimeout(function () {
          window.location.reload();
        }, 1400);
      })
      .catch((error) => {
        enqueueSnackbar(manageAPIError(error, 'Unable to sign up to wizer!'), {
          variant: 'error',
        });
      });
  };
  return (
    <>
      <DialogContent
        style={{
          minHeight: '150px',
          maxHeight: '150px',
          maxWidth: '50.5rem',
          display: 'flex',
          direction: 'row',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            marginLeft: '-4rem',
            top: 5,
            marginRight: '61.6px',
          }}
        >
          <img
            style={{
              maxHeight: '140px',
              minHeight: '140px',
              maxWidth: '181.9px',
              minWidth: '181.9px',
              left: '0rem',
            }}
            src="images/wizerModalImage.png"
            alt="Wizer"
          />
        </div>
        <p
          style={{
            minHeight: '3.75rem',
            minWidth: '20.625rem',
            maxHeight: '3.75rem',
            maxWidth: '20.625rem',
            marginTop: '2px',
            lineHeight: '1.375rem',
            fontSize: '1.2rem',
          }}
        >
          Once you sign up, you will receive an email from Wizer/Cowbell with
          the Next Steps.
        </p>
      </DialogContent>
      <DialogActions>
        <CBButton size="medium" styleName="cancel" onClick={props.close}>
          Cancel
        </CBButton>

        <CBButton
          size="medium"
          type="submit"
          styleName="ctaButton"
          onClick={handleSubmit}
        >
          Sign Up
        </CBButton>
      </DialogActions>
    </>
  );
};

export const WizerSignUpConfig = {
  WizerSignUp: {
    component: WizerSignUp,
    config: {
      maxWidth: 'sm',
      inheritTheme: 'light',
      title: 'Sign Up for Wizer Sponsored Training',
      icon: iconMappings.ACCOUNTS.iconImport,
    },
  },
};
