const ADMIN_UPDATE_BILLING = 'admin-update-billing-compliance';
const AGENCY_DASHBOARD_PREREQ = 'agency_dashboard_prereq';
const AWS_FLAG = 'aws_flag';
const CLIENT_PROFILE = 'client_profile';
const CLOUDFLARE = 'cloudflare';
const CMAIL = 'cmail';
const COWBELL_BIND_QUOTE_WORKFLOW = 'cowbell_bind_quote_workflow_ff';
const COWBELL_GENERATE_RENEWAL_QUOTE = 'cowbell_generate_renewal_quote';
const ELIGIBLE_PRODUCTS = 'eligible_products';
const EXCESS_FLOW = 'excess_flow';
const FLAT_CANCEL = 'flat_cancel';
const GLOBAL_ERROR_BOUNDARY = 'global_error_boundary';
const KRISTA_CHAT_BOT = 'krista_chat_bot';
const LANGUAGE_SELECTOR = 'language_selector';
const OPT_IN_NEW_AGENT_EXPERIENCE = 'opt_in_new_agent_experience';
const P100_RENEWAL_BIND_FLOW = 'p100-renewal-bind-flow';
const P100PRO = 'prime_100_pro';
const P100PRO_CANCELLATIONS = 'p100_pro_cancellation';
const P100PRO_MTE = 'midterm-endorsements_p100pro';
const PRIME_ONE = 'prime_one';
const PRIME_ONE_UW = 'prime_one_uw';
const PRIME_TECH_PLUS = 'prime-tech';
const REMEMBER_DEVICE = 'remember_device';
const SPOTLIGHT = 'spotlight';
const UNDO_CANCELLED_POLICY = 'undo-cancelled-policy';
const WIZER_TRAINING = 'wizer-training';

export const FEATURE_FLAG_KEYS = {
  ADMIN_UPDATE_BILLING,
  AGENCY_DASHBOARD_PREREQ,
  AWS_FLAG,
  CLIENT_PROFILE,
  CLOUDFLARE,
  CMAIL,
  COWBELL_BIND_QUOTE_WORKFLOW,
  COWBELL_GENERATE_RENEWAL_QUOTE,
  ELIGIBLE_PRODUCTS,
  EXCESS_FLOW,
  FLAT_CANCEL,
  GLOBAL_ERROR_BOUNDARY,
  KRISTA_CHAT_BOT,
  LANGUAGE_SELECTOR,
  OPT_IN_NEW_AGENT_EXPERIENCE,
  P100_RENEWAL_BIND_FLOW,
  P100PRO,
  P100PRO_CANCELLATIONS,
  P100PRO_MTE,
  PRIME_ONE,
  PRIME_ONE_UW,
  PRIME_TECH_PLUS,
  REMEMBER_DEVICE,
  SPOTLIGHT,
  UNDO_CANCELLED_POLICY,
  WIZER_TRAINING,
} as const;
