import React from 'react';
import type { MouseEventHandler } from 'react';
import _ from 'lodash';

import { Box, Menu } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { AnchorButton } from '../AgencyDashSecondaryActionsMenu';
import { useMenuState } from '../../../../../components/tables/cell-components/menu/Menu';
import type { Policy } from '../../../../accounts/summary/types/policy.types';
import { useHandlers as useMoreAgencyPoliciesOptionsHandlers } from '../../../../../policies/options/AgencyPolicyOptions';
import { MidTermEndorsementOption } from '../../../../../policies/options/MidtermEndorsementOption';
import { AgencyPoliciesCommonMenuOptions } from './AgencyPoliciesCommonMenuOptions';

interface AgencyPoliciesPrimeXMenuOptionsProps {
  policy: Policy;
}

export const AgencyPoliciesPrimeXMenuOptions = ({
  policy,
}: AgencyPoliciesPrimeXMenuOptionsProps) => {
  const classes = useStyles();

  const menuState = useMenuState();
  const menuOptionMoreHandlers = useMoreAgencyPoliciesOptionsHandlers(
    policy,
    _.noop,
    _.noop
  );

  const handleClose: MouseEventHandler<HTMLLIElement | HTMLDivElement> = (
    event
  ) => {
    event.stopPropagation();
    menuState.handleClose();
  };

  return (
    <>
      <AnchorButton onClick={menuState.handleOpen} />
      <Menu
        id="more-options-menu"
        anchorEl={menuState.anchorEl}
        keepMounted
        open={menuState.open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <AgencyPoliciesCommonMenuOptions policy={policy} />

        <Box className={classes.menuItem}>
          <MidTermEndorsementOption
            policy={policy as any}
            onClick={menuOptionMoreHandlers.handleMidtermEndorsement}
          >
            Midterm Endorsement
          </MidTermEndorsementOption>
        </Box>
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  menuItem: {
    '&:hover': {
      background: theme.agencyDash.background.hover,
    },
  },
}));
