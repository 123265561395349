import { Box, styled } from '@mui/material';

export const WYSIWYG_BLOCK_TYPES = [
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
];

export const WYSIWYG_INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
];

export const EditorContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  minHeight: 350,
  border: `1px solid ${theme.palette.primary.oldBorder}`,
  borderRadius: 4,
}));
