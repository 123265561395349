import React from 'react';

const SVG = ({
  style = {
    height: '25px',
    width: '25px',
    position: 'relative',
    top: '3px',
    left: '2px',
  },
  fill = '#9ba0ae',
  width = '100%',
  className = '',
  viewBox = '0 0 30 30',
}) => (
  <svg
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>.cls-1{`fill:${fill};`}</style>
    </defs>
    <title>Close Actions Menu</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path
          d="M3,18H16V16H3Zm0-5H13V11H3ZM3,6V8H16V6Zm18,9.59L17.42,12,21,8.41,19.59,7l-5,5,5,5Z"
          fill="#9ba0ae"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
