import { FormProvider } from 'react-hook-form';

// mui
import { Box as MuiBox } from '@mui/material';

// components
import CbButton from '../../../../components/Buttons/CbButton';
import DocumentUploadBase from './shared/DocumentUploadBase';
import { ScreenTitle } from './shared/ScreenTitle';
import SignatureHolderConfirmation from './shared/SignatureHolderConfirmation';
import { SubjectivityCompletionDate as SubjectivityCompletionDateBase } from './shared/SubjectivityCompletionDate';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import CheckBoxLabel from '../CheckBoxLabel';

// constants and utils
import { insuranceApplicationCheckboxLabel } from '../constants/constants';

// hocs and hooks
import { withShowable } from '../../../_global/lib/withShowable';
import { useRenewalApplicationDependencies } from './dependencies/useRenewalApplicationDependencies';
import CowbellReferToUWButton from './shared/referral/CowbellReferToUWButton';

const Box = withShowable(MuiBox);
const SubjectivityCompletionDate = withShowable(SubjectivityCompletionDateBase);

const RenewalApplication = (props) => {
  const { eventHandlers, reactHookForm, ...renewalApplicationState } =
    useRenewalApplicationDependencies(props);

  if (renewalApplicationState.isLoading) {
    return null;
  }

  const { quoteSubjectivityId } = props.subjectivities[props.step];

  return (
    <Box className="contrast-text" width="50%">
      <ScreenTitle
        subjectivitySubType={renewalApplicationState.subjectivitySubType}
        title={renewalApplicationState.subjectivityTitle}
      />

      <h3>
        {renewalApplicationState.allSubjectivityInformation
          .subjectivityDescription ?? ''}
      </h3>

      <FormProvider {...reactHookForm.methods}>
        <form onSubmit={reactHookForm.handleSubmit(eventHandlers.onSubmit)}>
          <Box>
            <Box>
              <DocumentUploadBase
                uploadZoneParams={renewalApplicationState.uploadZoneParams}
                files={renewalApplicationState.files}
                isUploadingDoc={renewalApplicationState.isUploadingDoc}
                uploadedDocs={renewalApplicationState.uploadedDocuments}
                isStepEditable={renewalApplicationState.isStepEditable}
                docType="Renewal Application"
                datePickerProps={{
                  displayDatePicker: true,
                  min: renewalApplicationState.allSubjectivityInformation
                    ?.hydratedInfo?.signatureValidRangeStartDate,
                  max: renewalApplicationState.allSubjectivityInformation
                    ?.hydratedInfo?.signatureValidRangeEndDate,
                }}
              />
            </Box>

            <Box
              show={
                renewalApplicationState.files ||
                renewalApplicationState.subjectivityData
              }
            >
              <SignatureHolderConfirmation
                disableFormFields={
                  !renewalApplicationState.isStepEditable ||
                  renewalApplicationState.isUploadingDoc
                }
              />
            </Box>

            <Box
              paddingBottom="1rem"
              show={
                !renewalApplicationState.files &&
                renewalApplicationState.isStepEditable
              }
            >
              <h3>Don't have the signed Application?</h3>

              <CbButton
                styleName="ctaButton"
                size="large"
                onClick={eventHandlers.handleDocDownload}
              >
                Download renewal app
              </CbButton>
            </Box>

            <Box
              show={
                renewalApplicationState.files ||
                renewalApplicationState.subjectivityData
              }
            >
              <Box>
                <CheckBoxBase
                  name="renewalApplicationAcknowledgement"
                  label={
                    <CheckBoxLabel label={insuranceApplicationCheckboxLabel} />
                  }
                  onChange={eventHandlers.handleCheckboxChange}
                  checked={renewalApplicationState.isRenewalAppAcknowledged}
                  disabled={
                    (renewalApplicationState.subjectivityData?.userAttested &&
                      !renewalApplicationState.files &&
                      !renewalApplicationState.isFormDirty) ||
                    renewalApplicationState.isUploadingDoc
                  }
                />
              </Box>
            </Box>
          </Box>

          <SubjectivityCompletionDate
            allSubjectivityInformation={
              renewalApplicationState.allSubjectivityInformation
            }
            show={renewalApplicationState.isStepCompleted}
          />

          <Box paddingTop="2rem">
            <CbButton
              styleName="cancel"
              size="medium"
              onClick={eventHandlers.handleGoBack}
            >
              Back
            </CbButton>

            <CbButton
              styleName="ctaButton"
              size="medium"
              type="submit"
              onClick={eventHandlers.handleGoNext}
              loading={
                renewalApplicationState.isUploadingDoc ||
                renewalApplicationState.isCompletingStep
              }
              disabled={
                renewalApplicationState.isUploadingDoc ||
                renewalApplicationState.isCompletingStep ||
                (!renewalApplicationState.isRenewalAppAcknowledged &&
                  renewalApplicationState.files) ||
                (renewalApplicationState.isStepCompleted &&
                  !renewalApplicationState.isRenewalAppAcknowledged)
              }
              style={{ marginRight: '0.833rem' }}
            >
              Next
            </CbButton>

            <CowbellReferToUWButton
              quoteId={props.quoteDetails.id}
              subjectivityId={quoteSubjectivityId}
              productType={props.quoteDetails.uiData.productType}
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default RenewalApplication;
