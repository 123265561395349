import { makeStyles } from '@mui/styles';

export const useCardStyles = makeStyles(({ palette }) => ({
  root: {
    maxWidth: 825,
    padding: '1rem',
    margin: '0 auto 1.875rem auto',
    position: 'relative',
    border: `1px solid ${palette.primary.modalBorder}`,
    background: palette.background.modal,
  },
}));

export const useGridStylesLeft = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
});

export const useGridStylesRight = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
});
