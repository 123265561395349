import React from 'react';
import { Plugin } from '@devexpress/dx-react-core';
import { PagingState, CustomPaging } from '@devexpress/dx-react-grid';
import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { DefaultTablePagination } from '../table-components/DefaultTablePagination';
import { TableStateContext } from '../TableStateContext';

export const RemotePagingPlugin = ({ ...props }) => {
  const { state, handlers, ...rest } = React.useContext(TableStateContext);
  return (
    <RemotePagingPluginBase {...props} {...state} {...handlers} {...rest} />
  );
};

const RemotePagingPluginBase = ({
  root = DefaultTablePagination,
  meta = {},
  ...props
}) => {
  const { nextToken, prevToken } = meta;

  return (
    <Plugin>
      <PagingState
        currentPage={Number(props.page)}
        onCurrentPageChange={(newPage) =>
          props.onCurrentPageChange(newPage, nextToken, prevToken)
        }
        onPageSizeChange={props.onPageSizeChange}
        pageSize={Number(props.size)}
      />
      <CustomPaging totalCount={meta.total || props.totalCount} />
      <PagingPanel pageSizes={props.pageSizes} containerComponent={root} />
    </Plugin>
  );
};

export default RemotePagingPluginBase;
