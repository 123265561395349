import React from 'react';

const SVG = ({
  style = {},
  fill = '#9192a4',
  width = '100%',
  className = '',
  viewBox = '0 0 30 30',
  title = 'Claims',
}) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .svg-fill-claims { fill: ${fill}; }
        `}
      </style>
    </defs>
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Isolation_Mode" data-name="Isolation Mode">
        <path
          className="svg-fill-claims"
          d="M12,19.34a5.57,5.57,0,1,0,5.57-5.56A5.58,5.58,0,0,0,12,19.34Zm1.05,0a4.52,4.52,0,0,1,3.69-4.44v1.21a2,2,0,0,0,.11,4.06h1.44a.37.37,0,1,1,0,.74h-3v1.66h1.44v1.2A4.51,4.51,0,0,1,13.09,19.34Zm9.05,0a4.52,4.52,0,0,1-3.7,4.43v-1.2a2,2,0,0,0-.11-4.06H16.89a.38.38,0,0,1-.38-.37.37.37,0,0,1,.38-.37h3V16.11H18.44V14.9A4.53,4.53,0,0,1,22.14,19.34Z"
        />
        <path
          className="svg-fill-claims"
          d="M25.31,8.07,19.89,2.69a.67.67,0,0,0-.47-.19H5.16a.66.66,0,0,0-.66.66V26.84a.66.66,0,0,0,.66.66H24.84a.66.66,0,0,0,.66-.66V8.53A.66.66,0,0,0,25.31,8.07Zm-7-3.92V8.89a.66.66,0,0,0,.65.66l4.86,0v16.3l-17.62,0,0-21.67Z"
        />
        <path
          className="svg-fill-claims"
          d="M8.39,20.19h1.2a.85.85,0,1,0,0-1.7H8.39a.85.85,0,1,0,0,1.7Z"
        />
        <path
          className="svg-fill-claims"
          d="M8.39,8.92h7.7a.85.85,0,1,0,0-1.7H8.39a.85.85,0,1,0,0,1.7Z"
        />
        <path
          className="svg-fill-claims"
          d="M8.39,14.56h3.22a.86.86,0,1,0,0-1.71H8.39a.86.86,0,1,0,0,1.71Z"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
