import React from 'react';
import clsx from 'classnames';
import { useSnackbar, SnackbarContent } from 'notistack';

// components
import {
  Card,
  CardActions,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CloseIcon from '@mui/icons-material/Close';

interface PaperSnackbarProps {
  children: React.ReactNode;
  variant?: string;
  id?: string;
  message: any;
  onClose?: () => void;
}

export const PaperSnackbar = React.forwardRef<
  HTMLDivElement,
  PaperSnackbarProps
>(({ children, variant = 'error', ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const actionClasses = clsx(classes.actionRoot, {
    [classes.error]: variant === 'error',
    [classes.warning]: variant === 'warning',
    [classes.info]: variant === 'info',
  });
  const actionIconClasses = clsx(classes.actionIconRoot, {
    [classes.error]: variant === 'error',
    [classes.warning]: variant === 'warning',
    [classes.info]: variant === 'info',
  });

  function handleDismiss() {
    props.onClose?.();
    closeSnackbar(props.id);
  }

  return (
    <SnackbarContent ref={ref}>
      <Card>
        <CardActions className={actionClasses}>
          <Typography variant="subtitle2">{props.message}</Typography>
          <IconButton onClick={handleDismiss} size="small">
            <CloseIcon className={actionIconClasses} />
          </IconButton>
        </CardActions>
        <Paper className={classes.paperRoot}>{children}</Paper>
      </Card>
    </SnackbarContent>
  );
});

const useStyles = makeStyles(({ config, palette }) => ({
  actionRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.83rem',
  },

  error: {
    background: palette.error.main,
    color: palette.error.contrastText,
  },

  warning: {
    background: palette.common.yellow,
    color: palette.common.white,
  },

  info: {
    background: palette.common.cowbellBlue,
    color: palette.common.white,
  },

  actionIconRoot: {
    background: 'transparent',
  },

  paperRoot: {
    maxWidth: 400,
    maxHeight: 400,
    padding: '1.5rem',
    overflow: 'auto',
    background: palette.background.tableRowHover,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    color: palette.primary.contrastText,
    fontSize: config.textSizes.tertia,
  },
}));
