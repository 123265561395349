import AT from '../actions/ActionTypes';

import {
  joinExistingAccount,
  postLoginCredentials,
  postPasswordResetValToken,
  postUserInvitedValToken,
} from '../api/SignupService';

export const submitPasswordResetValToken = (valToken, password) => {
  return () => {
    return postPasswordResetValToken(valToken, password);
  };
};

export const logout = () => {
  // delete local storage, though redux-persist will add it back
  // with the defaults due to the return statement below
  // localStorage.removeItem(`persist:${rootPersistorConfig.key}`);

  // tell authWorker user logged out
  const uiSettings = localStorage.getItem('cowbell');
  localStorage.clear();
  sessionStorage.clear();
  localStorage.setItem('cowbell', uiSettings);

  return {
    type: AT.USER_LOGOUT,
  };
};

/**
 * @deprecated
 */
export const submitLoginCredentials = (credentials) => () => {
  return postLoginCredentials(credentials);
};
/* JOIN Existing User Account */
export const submitJoinExistingAccount = (credentials, token) => () => {
  return joinExistingAccount(credentials, token);
};

export const submitUserInvitedValToken = (valToken, passwords) => {
  return () => {
    return postUserInvitedValToken(valToken, passwords);
  };
};
