import _ from 'lodash';
import { FILL_IN_OPTION, WENT_WITH_COMPETITOR } from './misc';

export function transformFormDataToPayload(formData) {
  const conditionalFields = {
    competitor:
      formData.renewalOptOutReason.value == WENT_WITH_COMPETITOR
        ? formData.competitor.value
        : undefined,
    fillInReason:
      formData.renewalOptOutReason.value == FILL_IN_OPTION
        ? formData.fillInReason
        : undefined,
  };

  return {
    renewalOptOut: {
      ..._.omit(formData, [
        'renewalOptOutReason',
        'competitor',
        'fillInReason',
      ]),
      renewalOptOutReason: formData.renewalOptOutReason.value,
      ...conditionalFields,
    },
  };
}
