import React from 'react';
// mui
import { Grid, styled } from '@mui/material';
// components
import { CoverageHeader } from './CoverageHeader';

export const GroupHeader = ({ labels, name, id }) => {
  return (
    <>
      {labels && <MuiGrid>{name}</MuiGrid>}

      {!labels && id === '1' && <CoverageHeader />}

      {!labels && id !== '1' && <MuiGrid />}
    </>
  );
};

const MuiGrid = styled(Grid)(({ theme }) => ({
  height: '3.66rem',
  paddingRight: '5rem',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  color: theme.palette.primary.contrastText,
  fontSize: theme.config.textSizes.tertia,
  fontWeight: theme.config.weights.bold,
  textTransform: 'upperCase',
}));
