import { commonDateParamMappings } from '../api/common/mappings';
import { trimAndAddAsterisk } from '../utility/QueryUtility';

const commonPolicyMappings = [
  { path: 'page' },
  { path: 'size' },
  {
    path: 'f',
    paramKey: 'filter',
  },
];

export const policyServiceMappings = [
  ...commonDateParamMappings,
  ...commonPolicyMappings,
  { path: 'status' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'agent':
        case 'agentName':
          if (orderBy && order) {
            return `agentFirstName.keyword,${order}`;
          }
          break;
        case 'customerEmail':
        case 'customerPhone':
        case 'quoteNumber':
          if (orderBy && order) {
            return `quoteNumber.keyword,${order}`;
          }
          break;
        case 'agentEmail':
        case 'policyNumber':
        case 'companyName':
        case 'agencyName':
        case 'customerFirstName':
        case 'customerLastName':
        case 'description':
        case 'quoteId':
        case 'product':
        case 'status':
        case 'uwEmail':
        case 'expiresOn':
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          break;
        case 'id':
          if (orderBy && order) {
            return `${orderBy}.keyword,${order}`;
          }
          break;
        case 'customerName':
          if (orderBy && order) {
            return `customerFirstName.keyword,${order}`;
          }
          break;
        case 'tamEmail':
          if (orderBy && order) {
            return `firmographicData.${orderBy}.keyword,${order}`;
          }
          break;
        case 'timestamp':
          if (order) {
            return `created,${order}`;
          }
          break;
        default:
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search }) => (search ? trimAndAddAsterisk(search) : null),
  },
  { path: 'status' },
  { path: 'teamIds' },
  { path: 'isOpenForRenewal' },
  { path: 'accountId.keyword' },
  {
    path: 'filter',
  },
];

export const policySearchV2Mapping = [
  ...commonPolicyMappings,
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }
      return null;
    },
  },
  { path: 'search' },
  { path: 'accountId' },
  { path: 'teamIds' },
  { path: 'binderExpiresOnAfter' },
  { path: 'effectiveDateAfter' },
  { path: 'effectiveDateBefore' },
];
