import React from 'react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import Moment from 'moment';
import { useSnackbar } from 'notistack';
import { Box, CircularProgress, Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { formatPolicy } from '../../policies.services';
import { getPolicyDetails } from '../../../../policies/PolicyService';
import { ProductTypes } from '../../../../types';

export default function PolicyDrawer({
  open,
  onClose,
  policyId,
  product,
  policyNumber,
  fetchLatestPolicyByStatus,
  onFetch,
  children,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handlePolicyDetailsErrorCatch = (axiosError) => {
    if (_.get(axiosError, 'response.status', 404) !== 404) {
      enqueueSnackbar('Policy Details missing at this time', {
        variant: 'error',
      });
    } else throw new Error(axiosError);
  };

  const {
    data: policy,
    isInitialLoading,
    ...queryRequest
  } = useQuery(
    ['policy', policyId],
    () =>
      typeof onFetch === 'function'
        ? onFetch(
            policyId,
            product,
            policyNumber,
            fetchLatestPolicyByStatus
          ).catch(handlePolicyDetailsErrorCatch)
        : fetchPolicyDetails({ policyId, product, enqueueSnackbar }).catch(
            handlePolicyDetailsErrorCatch
          ),
    {
      refetchOnWindowFocus: false,
      enabled: open,
    }
  );

  return (
    <Drawer
      pt={2}
      anchor="right"
      open={open}
      onClose={onClose}
      classes={classes}
    >
      {isInitialLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="13rem"
          width="42rem"
        >
          <CircularProgress size="5rem" />
        </Box>
      )}
      {!isInitialLoading && typeof children === 'function'
        ? children({ policy, ...queryRequest })
        : null}
    </Drawer>
  );
}

const useStyles = makeStyles(({ palette }) => ({
  paper: {
    height: 'auto',
    minWidth: '47rem',
    maxHeight: 'calc(100% - 12rem)',
    padding: '1rem',
    position: 'fixed',
    top: '6rem',
    right: 17,
    zIndex: 10,
    overflow: 'visible',
    color: palette.primary.main,
    backgroundColor: palette.background.paper,
    borderRadius: '0.9rem',
  },
}));

export const fetchPolicyDetails = ({ policyId, product }) => {
  return getPolicyDetails(policyId, product).then(({ data: rawPolicy }) => {
    const policy = {
      ..._.pick(rawPolicy, [
        'expiresOn',
        'id',
        'accountId',
        'additionalInsureds',
        'coveragesAdded',
        'coveragesRemoved',
        'coveragesUpdated',
        'boundOn',
        'quoteId',
        'quoteNumber',
        'policyNumber',
        'premium',
        'agentFirstName',
        'agentLastName',
        'agentEmail',
        'agentPhone',
        'isPaid',
        'isPrimePlus',
        'isCertificateAvailable',
        'isDocumentAvailable',
        'status',
        'noKnownLossLetterReq',
        'created',
        'modified',
        'totalPremium',
        'companyName',
        'description',
        'product',
        'status',
        'isSurplus',
        'customerFirstName',
        'customerLastName',
        'customerEmail',
      ]),
      ..._.pick(rawPolicy.firmographicData, [
        'address1',
        'city',
        'state',
        'zipCode',
      ]),
      ..._.pick(rawPolicy.policyResponse, ['totalPremium']),
      ...(product === ProductTypes.primecloud &&
        _.pick(rawPolicy, [
          'deductible',
          'limit',
          'effectiveDate',
          'isRenewal',
          'isEndorsement',
          'endDate',
        ])),
      ...(product === ProductTypes.p250 && {
        ..._.pick(rawPolicy.initialRequestData, [
          'deductible',
          'limit',
          'effectiveDate',
          'isRenewal',
          'isEndorsement',
          'coverages',
          'address',
          'endDate',
          'additionalInsureds',
        ]),
      }),
      ...(product === ProductTypes.p100 && {
        ..._.pick(rawPolicy, [
          'deductible',
          'limit',
          'effectiveDate',
          'isRenewal',
          'isEndorsement',
          'endDate',
        ]),
        waitingPeriod: _.get(rawPolicy, 'waitingPeriod', 0),
        // Coverages
        coverages: {
          ..._.pick(rawPolicy, [
            'additionalInsureds',
            'businessIncomeCoverage',
            'sublimit',
            'ransomPaymentEndorsement',
            'ransomPaymentLimit',
            'hardwareReplCostEndorsement',
            'hardwareReplCostSubLimit',
            'telecomsFraudEndorsement',
            'telecomsFraudSubLimit',
            'postBreachRemediationEndorsement',
            'postBreachRemediationSubLimit',
            'computerFraudEndorsement',
            'websiteMediaContentLiabilitySubLimit',
            'socialEngLimit',
            'socialEngDeductible',
            'socialEngEndorsement',
            'limit',
            'deductible',
            'retroactivePeriod',
            'waitingPeriod',
            'premium',
            'totalPremium',
            'brokerFee',
            'accountId',
            'teamIds',
            'websiteMediaContentLiabilityEndorsement',
            'endDate',
          ]),
        },
      }),
    };

    return {
      ...formatPolicy(policy),
      effectiveDate: policy.effectiveDate
        ? Moment.utc(policy.effectiveDate).format('MMM DD, YYYY hh:mm A')
        : null,
    };
  });
};
