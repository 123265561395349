import React from 'react';
// helpers
import { useSnackbar } from 'notistack';
import _ from 'lodash';
// mui
import { Box, styled, CircularProgress } from '@mui/material';
// actions
import { useQuery } from '@tanstack/react-query';
import { getSubjectivitiesForUW } from '../../../../inbox/QuotesService';
// utils
import { manageAPIError } from '../../../../utils';
import {
  formatSubjectivities,
  getAddedSubjectivities,
} from '../utils/bindQuoteWorkFlowUtils';

const uwSubjectivitesPlaceholderData = {
  rawData: [],
  formattedData: [],
};

// Dependecies file to fetch Uw available subjectivities and to set default values on the underlying component
export const withUnderwritingSubjectivitiesDependencies = () => {
  return (Component) => {
    return (props) => {
      // helpers
      const { enqueueSnackbar } = useSnackbar();
      // data
      const { quoteId } = props.data;

      const {
        data: uwSubjectivities = uwSubjectivitesPlaceholderData,
        isLoading: areUwSubjectivitiesLoading,
        isError: isUwSubjectivitiesError,
      } = useSubjectivities({ quoteId, productType: props?.data?.productType });

      if (areUwSubjectivitiesLoading) {
        return (
          <LoadingContainer>
            <CircularProgress size="5rem" />
          </LoadingContainer>
        );
      }

      if (isUwSubjectivitiesError) {
        enqueueSnackbar(
          'Something went wrong while fetching the subjectivities.',
          { variant: 'error' }
        );
        return null;
      }

      return (
        <Component
          availableSubjectivities={uwSubjectivities}
          quoteId={quoteId}
          {...props}
        />
      );
    };
  };
};

export const useSubjectivities = (
  { quoteId, productType },
  queryConfig = {}
) => {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    ['uw-subjectivities', quoteId],
    () => {
      return getSubjectivitiesForUW({ productType, quoteId })
        .then((response) => {
          const { sortedData, formattedData } = formatSubjectivities(
            _.get(response, 'data.subjectivities', [])
          );
          return {
            sortedData,
            formattedData,
            totalAddedSubjecitivities: getAddedSubjectivities(
              response.data.subjectivities
            ),
          };
        })
        .catch((error) => {
          enqueueSnackbar(
            manageAPIError(
              error,
              'Subjectivities selection not available at this time. Please try again in sometime'
            ),
            { variant: 'error' }
          );
        });
    },
    queryConfig
  );
};

const LoadingContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  height: '10rem',
}));
