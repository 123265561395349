import { ServiceHandlerFactory } from '../utils/serviceUtils';

// from agency only
export const getMyTeams = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/teams/my',
  },
});

export const applyChangesToTeams = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/team/:accountId',
    method: 'post',
  },
});

// from cowbell only
export const getTeamsForAgency = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/teams/agency/:agencyId',
  },
});

// from cowbell only
export const getTeamsForAgentInAgency = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/teams/user/:userId',
  },
});
