import React from 'react';
import { SvgIcon } from '@mui/material';

export const SurplusIcon = ({ fill = '#fff', ...props }) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={fill}
      d="M-2.849-15.984c2.136 0 3.667-1.116 3.667-2.946a4.383 4.383 0 0 0-2.193-3.47l-2.589-1.832a1.852 1.852 0 0 1-1-1.679c0-.9.624-1.359 1.682-1.359 1.588 0 1.928 1.038 2.041 2.26l1.814-.4c-.246-2.168-1.417-3.191-3.856-3.191-2.211 0-3.591 1.038-3.591 2.855A3.022 3.022 0 0 0-5.4-23.007l2.57 1.832c.964.687 1.663 1.267 1.663 2.382 0 .947-.586 1.45-1.682 1.45-1.644 0-2.06-1.328-2.174-2.657l-1.89.412c.152 2.139 1.399 3.604 4.064 3.604z"
      transform="translate(15.159 34.416)"
    />
    <g fill="none">
      <path d="M12 0A12 12 0 1 1 0 12 12 12 0 0 1 12 0z" />
      <path
        fill="#2180e2"
        d="M12 1.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z"
      />
    </g>
  </SvgIcon>
);
