import React from 'react';
import { Box, Divider, styled } from '@mui/material';

const BindQuoteWorkflowHeader = ({ quoteDetails = {} }) => {
  return (
    <Container
      className="contrast-text"
      data-testid="bind-quote-workflow-header"
    >
      <HeaderContainer>
        <Header>Bind Quote Number {quoteDetails.quoteNumber}</Header>
        <CoverageTypeSymbol quoteDetails={quoteDetails} />
      </HeaderContainer>
      <CompanyName>{quoteDetails.companyName}</CompanyName>
      <Divider />
    </Container>
  );
};

export default BindQuoteWorkflowHeader;

const CoverageTypeSymbol = ({ quoteDetails }) => {
  const symbol = quoteDetails.isSurplus ? 'S' : 'A';

  return <SymbolContainer>{symbol}</SymbolContainer>;
};

const Container = styled(Box)(({ theme }) => ({
  fontWeight: theme.config.weights.bold,
  paddingBottom: '1rem',
}));

const HeaderContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '1.2rem',
});

const Header = styled(Box)(({ theme }) => ({
  fontSize: theme.config.textSizes.canon,
}));

const CompanyName = styled(Box)(({ theme }) => ({
  fontSize: theme.config.textSizes.greatPrimer,
  paddingBottom: '1rem',
}));

const SymbolContainer = styled(Box)(({ theme }) => ({
  height: '2rem',
  width: '2rem',
  borderRadius: '50%',
  border: `2px solid ${theme.palette.primary.loadingIcon}`,
  fontWeight: theme.config.weights.bold,
  fontSize: theme.config.textSizes.tertiaPrime,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
