import type { ReactNode, LabelHTMLAttributes, ComponentProps } from 'react';
import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import classnames from 'classnames';
import { withShowable } from '../../console/_global/lib/withShowable';

export type InputLabelBaseProps = {
  inline?: boolean;
  inlineBlock?: boolean;
  indent?: boolean;
  required?: boolean;
  TypographyProps?: TypographyProps;
  align?: 'right';
  children: ReactNode;
} & LabelHTMLAttributes<HTMLLabelElement>;

type PropsWIthShowable = ComponentProps<
  ReturnType<typeof withShowable<InputLabelBaseProps>>
>;

export const InputLabelBase = withShowable<InputLabelBaseProps>(
  ({
    align,
    className,
    children,
    htmlFor,
    inline,
    inlineBlock,
    indent,
    required,
    show = true,
    TypographyProps = {},
    ...props
  }: PropsWIthShowable) => {
    const { requiredIndicator, ...classes } = useStyles();
    const classNames = classnames(classes.base, className, {
      [classes.inline]: inline,
      [classes.inlineBlock]: inlineBlock,
      [classes.indent]: indent,
      [classes.alignRight]: align === 'right',
    });

    if (!show) {
      return null;
    }

    return (
      <label {...props} className={classNames} htmlFor={htmlFor}>
        <Typography variant="body" {...TypographyProps}>
          {children}
          {required && <i className={requiredIndicator}>*</i>}
        </Typography>
      </label>
    );
  }
);

const useStyles = makeStyles(({ palette }) => ({
  base: {
    display: 'flex',
    padding: '1.5rem 0 0.5rem 0',
    color: palette.primary.contrastText,
  },

  alignRight: {
    justifyContent: 'flex-end',
    textAlign: 'right',
  },

  alignCenter: {
    justifyContent: 'center',
    textAlign: 'center',
  },

  inline: {
    display: 'inline-flex',
    padding: 0,
  },

  inlineBlock: {
    display: 'inline-block',
    padding: 0,
  },

  indent: {
    marginLeft: 10,
  },

  requiredIndicator: {
    color: palette.error.dark,
    fontSize: '1.3333333333rem',
    lineHeight: 1,
  },
}));
