import { Controller } from 'react-hook-form';
import { TextFieldBase } from './TextFieldBase';
import { CleaveBase } from './CleaveBase';

const CleaveInput = ({ inputRef, numericOnly = false, options, ...props }) => {
  return (
    <CleaveBase
      ref={inputRef}
      options={{
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        rawValueTrimPrefix: true,
        numericOnly,
        numeralDecimalScale: 2,
        ...options,
      }}
      {...props}
    />
  );
};

export const NumeralFieldBase = ({
  InputProps = {},
  inputProps = {},
  options = {},
  ...props
}) => {
  return (
    <TextFieldBase
      InputProps={{
        ...InputProps,
        inputProps: { ...inputProps, options },
        inputComponent: CleaveInput,
      }}
      {...props}
    />
  );
};

export const NumeralField = ({ control, ...props }) => (
  <Controller
    control={control}
    name={props.name}
    render={({ field, fieldState, formState }) => (
      <NumeralFieldBase {...field} {...fieldState} {...formState} {...props} />
    )}
  />
);
