import React, { useContext } from 'react';
import { compose } from 'redux';

import { TableBody as MuiTableBody } from '@mui/material';
import { withStyles } from '@mui/styles';

import { TableContext } from './context.table';
import { TableRow } from './row.table';
import { withLoader } from '../hocs/withLoader';

const styles = {};

export const TableBody = compose(
  withStyles(styles),
  withLoader
)(({ render, classes, ...props }) => {
  const { data } = useContext(TableContext);

  if (typeof render === 'function') {
    return (
      <MuiTableBody className={classes.root}>
        {render(data, props)}
      </MuiTableBody>
    );
  }

  return (
    <MuiTableBody className={classes.root}>
      {data.map((row, idx) => (
        <TableRow key={idx} row={row} />
      ))}
    </MuiTableBody>
  );
});

TableBody.displayName = 'TableBody';
