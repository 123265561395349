import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface AdminAccountsQuoteDrawerHeaderProps {
  headerContent: Header[];
}

interface Header {
  style: object;
  text: string;
}

const AdminAccountsQuoteDrawerHeader: React.FC<
  AdminAccountsQuoteDrawerHeaderProps
> = ({ headerContent = [] }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.header}
      display="flex"
      alignItems="center"
      justifyContent="start"
    >
      {headerContent.map((content: Header, index: number) => (
        <>
          <Box key={index} style={content.style}>
            {content.text}
          </Box>
        </>
      ))}
    </Box>
  );
};

export default AdminAccountsQuoteDrawerHeader;

const useStyles = makeStyles(({ palette }) => ({
  header: {
    padding: '1rem 0.8rem',
    background: palette.background.modal,
    textAlign: 'center',
  },
}));
