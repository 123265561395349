import React from 'react';
import type { MouseEventHandler } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Box, Collapse } from '@mui/material';

import type { StyledComponent } from '@mui/styles';
import { Show } from '../../components/Show';
import type { Binder } from '../../console/accounts/summary/types/binder.types';
import { useAgencyBinderPrimeXOptions } from '../../console/agency/logic/binders/agency-binders.logic';
import { usePrimeXAgencyBindersMoreOptionHandlers } from './PrimeXAgencyBindersMenu';

interface AgencyBindersPrimeXMenuOptionsProps {
  binder: Binder;
  MenuItem: StyledComponent<any>;
  makeRefreshCall?: () => void;
}

const AgencyBindersPrimeXMenuOptions = ({
  binder,
  MenuItem,
  makeRefreshCall,
}: AgencyBindersPrimeXMenuOptionsProps) => {
  const [isDocumentsExpanded, setIsDocumentsExpanded] = React.useState(true);
  const menuOptions = useAgencyBinderPrimeXOptions(binder);
  const menuOptionHandlers = usePrimeXAgencyBindersMoreOptionHandlers({
    binder: binder as any,
    makeRefreshCall: makeRefreshCall ?? _.noop,
  });

  const handleDocumentsClick: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    setIsDocumentsExpanded((value) => !value);
  };

  return (
    <>
      <Show when={menuOptions.documents.show}>
        <MenuItem onClick={handleDocumentsClick}>
          {menuOptions.documents.name}
          <Box ml={0.5}>
            {isDocumentsExpanded ? (
              <FontAwesomeIcon icon={light('chevron-up')} />
            ) : (
              <FontAwesomeIcon icon={light('chevron-down')} />
            )}
          </Box>
        </MenuItem>
        <Collapse
          in={isDocumentsExpanded}
          timeout="auto"
          unmountOnExit
          style={{ paddingLeft: '1rem' }}
        >
          <Show when={menuOptions.downloadApplication.show}>
            <MenuItem onClick={menuOptionHandlers.downloadApplication}>
              {menuOptions.downloadApplication.name}
            </MenuItem>
          </Show>
          <Show when={menuOptions.downloadBinder.show}>
            <MenuItem onClick={menuOptionHandlers.downloadBinder}>
              {menuOptions.downloadBinder.name}
            </MenuItem>
          </Show>
          <Show when={menuOptions.downloadInvoice.show}>
            <MenuItem onClick={menuOptionHandlers.downloadInvoice}>
              {menuOptions.downloadInvoice.name}
            </MenuItem>
          </Show>
          <Show when={menuOptions.downloadReceipt.show}>
            <MenuItem onClick={menuOptionHandlers.downloadReceipt}>
              {menuOptions.downloadReceipt.name}
            </MenuItem>
          </Show>
        </Collapse>
        <Show when={menuOptions.rescindBinder.show}>
          <MenuItem onClick={menuOptionHandlers.rescindBinder}>
            {menuOptions.rescindBinder.name}
          </MenuItem>
        </Show>
        <Show when={menuOptions.issuePolicy.show}>
          <MenuItem onClick={menuOptionHandlers.issuePolicy}>
            {menuOptions.issuePolicy.name}
          </MenuItem>
        </Show>
      </Show>
    </>
  );
};

export default AgencyBindersPrimeXMenuOptions;
