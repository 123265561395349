import { trimAndAddAsterisk } from '../utility/QueryUtility';

export const agencyLevelParamsSerializer = [
  { path: 'page' },
  { path: 'size' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: { orderBy: string; order: string }) => {
      if (orderBy && order) {
        switch (orderBy) {
          default:
            return `${orderBy},${order}`;
        }
      }
      return null;
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) =>
      !search ? search : trimAndAddAsterisk(search),
  },
];
