export default (state) => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      growthBook: {
        ...state.growthBook,
        featureFlagsEnabled: false,
      },
    },
  };
};
