import React from 'react';
import Moment from 'moment';

export const useDocSignedDate = ({
  formState,
  files,
  setValue,
  uploadedDocuments,
  subjectivityData,
  binder = {},
}) => {
  const filesRef = React.useRef();

  React.useEffect(() => {
    if (files && !filesRef.current) {
      filesRef.current = true;
      files.forEach((_file, index) => {
        const dateValue = binder.boundOn
          ? Moment.utc(binder?.boundOn).format('YYYY-MM-DD')
          : formState.effectiveDateOfPolicy;
        setValue(`docSignedOn-${index}`, dateValue);
      });
    } else {
      filesRef.current = true;
      uploadedDocuments?.forEach((_file, index) => {
        const dateValue = subjectivityData.uploadedDocuments[index].docSignedOn
          ? Moment.utc(
              subjectivityData.uploadedDocuments[index].docSignedOn
            ).format('YYYY-MM-DD')
          : formState.effectiveDateOfPolicy;
        setValue(`docSignedOn-${index}`, dateValue);
      });
    }
    if (!files) {
      filesRef.current = false;
    }
    return () => {
      filesRef.current = null;
    };
  }, [
    binder.boundOn,
    files,
    formState.effectiveDateOfPolicy,
    setValue,
    subjectivityData,
    uploadedDocuments,
  ]);
};
