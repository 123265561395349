import { ServiceHandlerFactory } from '../../../utils/serviceUtils';
import { saveClaimPayloadMapper } from './claims.service.mappings';

export const getClaims = ServiceHandlerFactory({
  axios: {
    url: 'api/claims/v1/search',
  },
  isCancelable: true,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'created':
          case 'isFiled':
          case 'modified':
            return `${orderBy},${order}`;
          default:
            return `${orderBy}.keyword,${order}`;
        }
      },
    },
  ],
});

export const getClaim = ServiceHandlerFactory({
  axios: {
    url: 'api/claims/v1/:claimId',
  },
  isCancelable: true,
});

export const createClaim = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/claim',
    method: 'post',
  },
  payloadMap: saveClaimPayloadMapper,
});

export const updateClaim = ServiceHandlerFactory({
  axios: {
    url: '/api/claims/v1/:claimId',
    method: 'put',
  },
  payloadMap: saveClaimPayloadMapper,
});

export const deleteClaim = ServiceHandlerFactory({
  axios: {
    url: '/api/claims/v1/:claimId',
    method: 'delete',
  },
});
