import React from 'react';

// MUI
import type { MenuItemProps } from '@mui/material';

// hocs
import { useFeature } from '@growthbook/growthbook-react';
import useLatestPrime250PolicyFunctions from './hooks/useLatestPrime250PolicyFunctions';
import { useAuth } from '../../components/hooks/useAuth';

//utils
import { ProductTypes } from '../../types';
import {
  hasPolicyExpired,
  isPolicyInForce,
} from '../../utils/hamburgerMenus/options.utils';

//components
import AllowedMenuItem from '../../components/AllowedMenuItem';
import type { PolicyTableInfoDto } from '../../types/policy/tables/policy-table-info.dto';
import { FEATURE_FLAG_KEYS } from '../../config/growthbook';

interface MidTermEndorsementOptionProps {
  children: React.ReactNode;
  policy: PolicyTableInfoDto;
}

export const MidTermEndorsementOption = ({
  children,
  policy,
  ...props
}: MidTermEndorsementOptionProps & MenuItemProps) => {
  const auth = useAuth();

  const p100ProMteFeature = useFeature(FEATURE_FLAG_KEYS.P100PRO_MTE);

  const { isCowbellUserJumped } = auth;

  const { canMidTermEndorsePolicy } = useLatestPrime250PolicyFunctions({
    policy,
    queryOptions: {
      enabled: policy.product === ProductTypes.p250,
      retry: false,
      onError: () => null,
    },
  });

  const isCommonEndorsementConstraintsPassing =
    !policy.isEndorsement && !policy.isPrimePlus;

  let show = false;

  if (policy.product === ProductTypes.p100) {
    show =
      isCommonEndorsementConstraintsPassing && isPolicyInForce(policy.status);
  }

  if (policy.product === ProductTypes.p250) {
    show =
      isCommonEndorsementConstraintsPassing &&
      isCowbellUserJumped &&
      (isPolicyInForce(policy.status) ||
        (hasPolicyExpired(policy.status) && canMidTermEndorsePolicy));
  }

  if (policy.product === ProductTypes.p100_pro) {
    show =
      isCommonEndorsementConstraintsPassing &&
      isCowbellUserJumped &&
      isPolicyInForce(policy.status) &&
      p100ProMteFeature.on;
  }

  return (
    <AllowedMenuItem allow={['policy:manage']} {...props} show={show}>
      {children}
    </AllowedMenuItem>
  );
};
