import React from 'react';

import * as Yup from 'yup';

import { Grid as MuiGrid } from '@mui/material';

import Moment from 'moment';
import { withFormController } from '../../../../../../components/hocs/forms';
import { TextFieldBase } from '../../../../../../components/inputs/TextFieldBase';
import LanguageCurrencyFieldBase from '../../../../../../components/inputs/LanguageCurrencyField';

// actions

const TextField = withFormController(TextFieldBase);
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);

const validationKeyCheck = (value) =>
  [
    'Exclusion of Foreign Coverage Endorsement',
    'Amendment of Retroactive Date Endorsement',
    'Specific Event Exclusion Endorsement',
    'Specific Individual/Entity Exclusion Endorsement',
    'Schedule of Underlying Insurance Endorsement',
  ].includes(value);

export const amendmentRetroactiveDateValidation = {
  policyNumber: Yup.string().when('endorsement', {
    is: validationKeyCheck,
    then: Yup.string().label('Policy Number').required(),
  }),
  additionalPremium: Yup.number().when('endorsement', {
    is: validationKeyCheck,
    then: Yup.number()
      .fromCurrency()
      .label('Additional Premium')
      .min(0, '${label} must be greater than zero')
      .min(1, 'Additional Premium must be at least 1 characters')
      .required(),
  }),
  returnPremium: Yup.number().when('endorsement', {
    is: validationKeyCheck,
    then: Yup.number()
      .fromCurrency()
      .min(0, '${label} must be greater than zero')
      .label('Return Premium')
      .required(),
  }),
  effectiveDateOfPolicy: Yup.string().when('endorsement', {
    is: validationKeyCheck,
    then: Yup.string().label('Policy Effective Date').required(),
  }),
  expirationDateOfPolicy: Yup.string().when('endorsement', {
    is: validationKeyCheck,
    then: Yup.string().label('Policy Expiration Date').required(),
  }),
  effectiveDateOfEndorsement: Yup.string().when('endorsement', {
    is: validationKeyCheck,
    then: Yup.string().label('Effective Date of Endorsement').required(),
  }),
};

export const AmendmentRetroactiveDate = () => {
  return (
    <>
      <MuiGrid container spacing={5}>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <TextField
            required
            name="policyNumber"
            label="Policy Number"
            fullWidth
          />
        </MuiGrid>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <TextField
            name="effectiveDateOfPolicy"
            label="Effective Date of Policy"
            InputProps={{
              inputProps: { min: Moment().format('YYYY-MM-DD') },
            }}
            required
            type="date"
          />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container spacing={5}>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <LanguageCurrencyField
            required
            label="Additional Premium"
            name="additionalPremium"
          />
        </MuiGrid>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <TextField
            name="expirationDateOfPolicy"
            label="Expiration Date of Policy"
            InputProps={{
              inputProps: { min: Moment().format('YYYY-MM-DD') },
            }}
            required
            type="date"
          />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container spacing={5}>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <LanguageCurrencyField
            required
            label="Return Premium"
            name="returnPremium"
          />
        </MuiGrid>
        <MuiGrid item md={6} style={{ paddingTop: 0 }}>
          <TextField
            name="effectiveDateOfEndorsement"
            label="Effective Date of Endorsement"
            InputProps={{
              inputProps: { min: Moment().format('YYYY-MM-DD') },
            }}
            required
            type="date"
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
