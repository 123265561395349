import React from 'react';
// actions
import { Typography } from '@mui/material';
import { resendValEmail } from '../../api/SignupService';
import { TextField } from '../inputs';

import CBButton from '../Buttons/CbButton';
import NextPublicWrapper from './NextPublicWrapper';

class ResendValidationEmail extends React.Component {
  state = {
    email: '',
    submitEmail: false,
  };

  handleChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { elements } = event.target;
    resendValEmail(elements.email.value);
    this.setState({
      submitEmail: true,
      email: '',
    });
  };

  render() {
    const { state } = this;

    return (
      <NextPublicWrapper>
        <div style={{ marginTop: 16 }}>
          {!state.submitEmail && (
            <form onSubmit={this.handleSubmit}>
              <Typography>
                Enter your email to resend your validation email.
              </Typography>

              <TextField
                id="email"
                type="email"
                label="Email"
                value={this.state.email}
                required
                onChange={this.handleChange}
              />

              <CBButton
                size="large"
                type="submit"
                styleName="ctaButton"
                buttonText="Resend Validation Email"
                style={{ marginTop: '2rem' }}
              />
            </form>
          )}
          {state.submitEmail && (
            <Typography>
              A validation email has been sent to your email address
            </Typography>
          )}
        </div>
      </NextPublicWrapper>
    );
  }
}

export default ResendValidationEmail;
