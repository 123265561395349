import React, { useState } from 'react';
import _ from 'lodash';
import { FormContext, useForm } from 'react-hook-form-4';
import Moment from 'moment';
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Grid as MuiGrid,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// helpers
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { updateNeedBy } from '../../console/agencies/_services/quotes.service';

// componenets
import CbButton from '../Buttons/CbButton';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/withFormController';
import { COWBELL_QUOTES } from '../tables/table_constants';

const TextField = withFormController(TextFieldBase);
const styles = ({ palette, config }) => {
  return {
    header: {
      marginBottom: '1.25rem',
      fontSize: config.textSizes.primer,
      color: palette.primary.contrastText,
    },
    divider: {
      marginBottom: '1.25rem',
    },
    select: {
      justifyContent: 'left',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    label: {
      padding: 0,
      fontSize: config.textSizes.normal,
    },
    requiredIcon: {
      color: '#f95f53',
      fontSize: '1.25rem',
      lineHeight: '1rem',
    },
  };
};

const schema = Yup.object().shape({});

export const NeedBy = withStyles(styles)(({ close, data }) => {
  const [error, setError] = useState('');
  const [disable, setDisable] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    register,
    watch,
    formState: { isSubmitting },
    ...methods
  } = useForm({
    defaultValues: {
      needByDate:
        Moment.utc(data.needByDate).format('YYYY-MM-DD') ||
        Moment().add('days', 7).format('YYYY-MM-DD'),
    },
    validationSchema: schema,
  });

  const onSubmit = (formData) => {
    const date = formData.needByDate
      ? Moment(formData.needByDate).format('YYYY-MM-DD')
      : '';

    return updateNeedBy({ id: data.id, date })
      .then(() => {
        close();
        enqueueSnackbar(`Quote NeedBy Saved Successfully!`, {
          variant: 'success',
        });
        queryClient.invalidateQueries([COWBELL_QUOTES]);
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong. Please try again later'
            )
          )
        );
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className="modal-title" style={{ textAlign: 'left' }}>
          <MuiGrid container spacing={2} justifyContent="center">
            <MuiGrid item md={12}>
              Select a date
            </MuiGrid>
            <MuiGrid item md={12} style={{ marginTop: -10 }}>
              <TextField
                type="date"
                InputProps={{
                  inputProps: { min: Moment().format('YYYY-MM-DD') },
                }}
                name="needByDate"
                control={control}
              />
            </MuiGrid>
          </MuiGrid>

          {error && (
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Save"
            loading={isSubmitting}
            disabled={isSubmitting || disable}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
});

export default NeedBy;
