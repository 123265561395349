/* eslint-disable no-template-curly-in-string */
import _ from 'lodash';
import * as Yup from 'yup';
import React, { useMemo } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
// mui
import { DialogActions, DialogContent, Grid as MuiGrid } from '@mui/material';
import { withStyles } from '@mui/styles';

// components
import { SimpleSelect } from '../../../components/inputs/selects/SimpleSelect';
import { withFormController } from '../../../components/hocs/withFormController';
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';

import { delayedEvent } from '../../../utils/eventUtils';
import CbButton from '../../../components/Buttons/CbButton';
import { Modal } from '../../../components/modals/v2/helpers/v2.modal.helpers';

// services
import { fetchTams } from '../../../api/UserService';
import { createOverride } from '../../../inbox/QuotesService';

const TextField = withFormController(TextFieldBase);
const Select = withFormController(SimpleSelect);

const schema = Yup.object().shape({
  tam: Yup.string()
    .label('TSM')
    .typeError('${label} is a required field')
    .required(),
  emails: Yup.string()
    .label('Emails associated with this TSM')
    .typeError('${label} is a required field')
    .test('Are emails valid', 'Invalid emails', (value) => {
      const emailsArr = value.trim().split(',');
      return emailsArr.every((email) => {
        const trimEmail = email.trim();
        if (trimEmail) {
          return /\S+@\S+\.\S+/.test(email);
        }
        return true;
      });
    })
    .required(),
});

export const AddSalesInboxOverride = withStyles({
  selectLabel: {
    paddingTop: '0.5rem',
  },
})(({ classes, close, data, ...props }) => {
  const [tams, setTams] = React.useState([]);
  const defaultValues = {
    tamEmail: (data && data.tamEmail) || null,
    emails: (data && data.agentEmail) || '',
  };

  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    formState: { isSubmitting, dirty },
    ...methods
  } = useForm({ defaultValues, validationSchema: schema });
  const values = methods.getValues();

  React.useEffect(() => {
    methods.register({ name: 'tam', type: 'custom' });
    methods.register({ name: 'emails', type: 'custom' });

    Promise.all([fetchTams()])
      .then(([tamsResp]) => {
        const tamsList = _.map(tamsResp.data.content, (value, key) => {
          return {
            label: `${value.firstName} ${value.lastName}`,
            value: value.email,
          };
        });

        setTams(tamsList);
      })
      .catch(() => {
        enqueueSnackbar('Unable to download data', { variant: 'error' });
      });

    // eslint-disable-next-line
  }, []);

  const onSubmit = (formData) => {
    const payload = {
      tamEmail: formData.tam,
      agents: formData.emails
        .trim()
        .split(',')
        .reduce((result, email) => {
          if (email && email.trim()) {
            result.push(email.trim());
          }
          return result;
        }, []),
    };

    return createOverride({}, payload)
      .then(() => {
        enqueueSnackbar('New override succesfully created!', {
          variant: 'success',
        });
        makeRefreshCall();
        Modal.hide('AddSalesInboxOverride');
      })
      .catch(() => {
        enqueueSnackbar(
          'Unable to create new override, please double check that the selected account exists',
          {
            variant: 'error',
          }
        );
      });
  };

  const tamOptions = useMemo(
    () => [
      {
        label: 'Select a TSM...',
        value: null,
        disabled: true,
      },
      ...tams,
    ],
    [tams]
  );

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} data-qa="addAgency">
        <DialogContent>
          <MuiGrid container spacing={4}>
            <MuiGrid item md={8}>
              <Select
                name="tam"
                required
                label="Select a TSM"
                displayEmpty
                options={tamOptions}
                defaultValue={values.tamEmail}
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={4}>
            <MuiGrid item md={12}>
              <TextField
                minRows={6}
                required
                multiline
                value={values.emails}
                name="emails"
                placeholder="Please add comma-separated list of email for all agents to be associated with this TSM"
                label="Add Email Address of all Agents to be associate with this TSM"
              />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Save"
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
});

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, 'SalesInboxOverrides');
}

export const AddSalesInboxOverrideConfig = {
  AddSalesInboxOverride: {
    component: AddSalesInboxOverride,
    config: {
      /* any material ui configuration options  */
      fullWidth: true,
      minWidth: 'sm',
      maxWidth: 'md',
    },
  },
};
