import Color from 'color';

export function createColorStep(color, step = 0.11) {
  return new Array(20).fill('').reduce(
    (acc, value, index) => {
      if (index < 1) {
        return acc;
      }
      const newColor = Color(acc[index - 1])
        .lighten(step)
        .hex();
      return [...acc, newColor];
    },
    [color]
  );
}
