import React from 'react';
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import CbButton from '../../../components/Buttons/CbButton';
import { checkUserLoggedInStatus } from '../../../api/users/users.api';

const Status = {
  PENDING: 'PENDING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

const ErrorType = {
  MULTIPLE_ORIGIN_TEAMS: 'MULTIPLE_ORIGIN_TEAMS',
  LOGGED_IN_USER: 'LOGGED_IN_USER',
};

const Error = {
  SINGLE_MIGRATION_MULTIPLE_ORIGIN_TEAMS: {
    content: (
      <>
        <DialogContentText>
          The target User cannot be migrated at this time, as they are currently
          assigned to multiple Teams.
        </DialogContentText>
        <DialogContentText>
          Please reassign the target User to a single Team before moving
          forward.
        </DialogContentText>
      </>
    ),
    action: undefined,
  },
  BULK_MIGRATION_MULTIPLE_ORIGIN_TEAMS: {
    content: (
      <>
        <DialogContentText>
          The target Users cannot be bulk migrated at this time as they
          originate from more than one Team.
        </DialogContentText>
        <DialogContentText>
          Please go back and edit your selection before proceeding.
        </DialogContentText>
      </>
    ),
    action: undefined,
  },
  [ErrorType.LOGGED_IN_USER]: {
    content: (loggedInUsers) => (
      <>
        <DialogContentText>
          The following Users cannot be migrated because they are currently
          logged into the platform.
        </DialogContentText>
        <List>
          {loggedInUsers.map((userEmail) => (
            <ListItem key={userEmail}>{userEmail}</ListItem>
          ))}
        </List>
        <DialogContentText>
          Please try again later or contact these users to coordinate the
          migration with them directly.
        </DialogContentText>
      </>
    ),
    action: undefined,
  },
};

export const ErrorHandlerWrapper = ({ selection = [], children, ...props }) => {
  const [error, setError] = React.useState({});
  const [requestStatus, setRequestStatus] = React.useState({
    [ErrorType.MULTIPLE_ORIGIN_TEAMS]: Status.PENDING,
    [ErrorType.LOGGED_IN_USER]: Status.PENDING,
  });

  const {
    data: loggedInUsers = [],
    isLoading: isLoadingLoggedInUsers,
    isError: isApiError,
  } = useQuery({
    queryKey: ['LOGGED_IN_USERS', selection],
    queryFn: () =>
      checkUserLoggedInStatus({
        data: { userEmails: selection.map((user) => user.email) },
      }),
    select: (resp) => {
      return Object.keys(resp.data).filter(
        (userEmail) => !!resp.data[userEmail]
      );
    },
  });

  React.useEffect(() => {
    const hasValidTeams = validateTeams(selection);

    if (!hasValidTeams) {
      setError(
        selection.length === 1
          ? Error.SINGLE_MIGRATION_MULTIPLE_ORIGIN_TEAMS
          : Error.BULK_MIGRATION_MULTIPLE_ORIGIN_TEAMS
      );
      setRequestStatus((prev) => ({
        ...prev,
        [ErrorType.MULTIPLE_ORIGIN_TEAMS]: Status.ERROR,
      }));
    } else {
      setRequestStatus((prev) => ({
        ...prev,
        [ErrorType.MULTIPLE_ORIGIN_TEAMS]: Status.SUCCESS,
      }));
    }

    if (isLoadingLoggedInUsers) {
      return;
    }

    if (loggedInUsers.length) {
      setError(Error.LOGGED_IN_USER);
      setRequestStatus((prev) => ({
        ...prev,
        [ErrorType.LOGGED_IN_USER]: Status.ERROR,
      }));
    } else {
      setRequestStatus((prev) => ({
        ...prev,
        [ErrorType.LOGGED_IN_USER]: Status.SUCCESS,
      }));
    }

    // eslint-disable-next-line
  }, [isLoadingLoggedInUsers]);

  if (isApiError) {
    return (
      <DialogContent>
        <DialogContentText>
          There was a problem loading the modal data. Please try again in a few
          minutes.
        </DialogContentText>
        <DialogActions>
          <CbButton styleName="cancel" onClick={props.close}>
            Close
          </CbButton>
        </DialogActions>
      </DialogContent>
    );
  }

  if (
    Object.values(requestStatus).some((status) => status === Status.PENDING)
  ) {
    return (
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </DialogContent>
    );
  }

  if (Object.values(requestStatus).some((status) => status === Status.ERROR)) {
    const { content = '', action = {} } = error;
    return (
      <>
        <DialogContent>
          {typeof content === 'function' ? content(loggedInUsers) : content}
        </DialogContent>
        <DialogActions>
          <CbButton
            styleName="ctaButton"
            onClick={action.onClick ?? props.close}
          >
            {action.label ?? 'Got It'}
          </CbButton>
        </DialogActions>
      </>
    );
  }

  return children;
};

const validateTeams = (selection) => {
  if (selection.length === 1) {
    return selection[0].teams.length <= 1;
  }

  const teamsTouched = new Set();
  return (
    selection.every((user) => user.teams.length === 0) ||
    selection.every((user) => {
      if (user.teams.length !== 1) {
        return false;
      }
      teamsTouched.add(user.teams[0].id);
      return teamsTouched.size === 1;
    })
  );
};
