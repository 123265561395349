import React from 'react';
import { Redirect } from 'react-router-dom';

// components
import {
  Avatar as MuiAvatar,
  Box as MuiBox,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { amber, lightGreen, red } from '@mui/material/colors';
import { Remove, Add, Flip } from '@mui/icons-material';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { withShowable } from '../lib/withShowable';

import { PRODUCTION_ENV } from '../../../config/init.config';

// utils
import { loadScript } from '../../../utils/appUtils';
import { Button } from '../../../components/Buttons/Button';

const { ENV } = window.cowbell.getEnv();

const Avatar = withShowable(MuiAvatar);
const Box = withShowable(MuiBox);

const DiffTool = (props) => {
  const base = React.useRef({});
  const compared = React.useRef({});
  const classes = useStyles();
  const [results, setResults] = React.useState([]);

  React.useEffect(() => {
    const scriptId = 'recursiveDiff';
    const existingScript = document.getElementById(scriptId);

    if (!existingScript || typeof window.recursiveDiff !== 'object') {
      loadScript(
        'vendor-recursive-diff',
        '/js/vendor/recursive-diff.min.js',
        scriptId
      );
    }

    return () => {
      document.getElementById(scriptId).remove();
    };
    // eslint-disable-next-line
  }, []);

  const handleCompare = React.useCallback(() => {
    const diff = window.recursiveDiff.getDiff(base.current, compared.current);
    const formatted = diff.map((d) => ({
      op: d.op,
      path: d.path && d.path.join(' > '),
      value: d.val,
    }));

    setResults(formatted);
    // eslint-disable-next-line
  }, []);

  const setBase = (value) => {
    base.current = value;
  };

  const setCompared = (value) => {
    compared.current = value;
  };

  if (ENV === PRODUCTION_ENV) {
    return <Redirect to="/agency/notfound" />;
  }

  return (
    <Dialog {...props}>
      <DialogTitle classes={{ root: classes.rootTitle }}>
        <Box className={classes.boxTitle}>Data Difference Tool</Box>
        <CancelOutlinedIcon
          className={classes.cancel}
          fontSize="1.3333333333rem"
          onClick={props.close}
        />
      </DialogTitle>
      <DialogContent>
        <Card>
          <Box p={2}>
            <Typography variant="h5" gutterBottom>
              Data Difference Tool
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={7}>
                <Typography variant="body2" gutterBottom>
                  This tool is intended to quickly determine the difference
                  between large nested objects. Example use cases may be to
                  quickly determine the difference in caluclator
                  payloads/responses.
                </Typography>
              </Grid>
              <Grid item md={6}>
                <TextArea label="Base" setValue={setBase} />
              </Grid>
              <Grid item md={6}>
                <TextArea label="Compared" setValue={setCompared} />
              </Grid>
              <Grid item md={12}>
                <Box className="text-center">
                  <Button stylename="primary" fixed onClick={handleCompare}>
                    Compare
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Card style={{ marginTop: 16 }}>
          <CardContent>
            <Box p={1}>
              <Typography
                className="contrast-text"
                variant="body2"
                gutterBottom
              >
                Results {results && `(${results.length})`}
              </Typography>
              <Box show={results.length}>
                <List className={classes.list}>
                  {results.map((result) => (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          show={result.op === 'add'}
                          className={classes.addIcon}
                        >
                          <Add />
                        </Avatar>
                        <Avatar
                          show={result.op === 'delete'}
                          className={classes.deleteIcon}
                        >
                          <Remove />
                        </Avatar>
                        <Avatar
                          show={result.op === 'update'}
                          className={classes.updateIcon}
                        >
                          <Flip />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            <Typography className="tertia-text contrast-text">
                              Path: "Compared" {'>'} {result.path}
                            </Typography>
                            <Typography className="normal-text yellow-text">
                              {statusDescriptions[result.op]}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

const statusDescriptions = {
  update: '"Compared" has the same path as "base" but its value is different',
  add: '"Compared" has a path that is missing in "base"',
  delete: '"Compared" is missing a path that is present in "base"',
};

const useStyles = makeStyles(({ palette }) => ({
  boxTitle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    textOverflow: 'ellipsis',
  },
  rootTitle: {
    '& > h2': {
      maxWidth: '99%',
    },
  },
  cancel: { right: 0, color: '#9aa0ae', cursor: 'pointer' },
  list: {
    overflow: 'scroll',
    border: `1px solid ${palette.primary.border}`,
  },

  addIcon: {
    backgroundColor: lightGreen[400],
    color: palette.getContrastText(lightGreen[400]),
  },

  updateIcon: {
    backgroundColor: amber[400],
    color: palette.getContrastText(amber[400]),
  },

  deleteIcon: {
    backgroundColor: red[400],
    color: palette.getContrastText(red[400]),
  },
}));

function TextArea({ label, setValue, ...props }) {
  const classes = useTextAreaStyles();
  const [isValid, setIsValid] = React.useState(null);

  function handleValidateJson(event) {
    const { value } = event.target;

    try {
      const parsed = JSON.parse(value);
      setIsValid(true);
      setValue(parsed);
    } catch (error) {
      setIsValid(false);
    }
  }

  return (
    <label htmlFor={label}>
      <Box className="flex--spaced">
        <Typography className="contrast-text" variant="body2" gutterBottom>
          {label}
        </Typography>
        <Box show={isValid !== null}>
          <Box className="flex align-center green-text" show={isValid}>
            <CheckCircleOutlineIcon style={{ marginRight: 5 }} /> valid json
          </Box>
          <Box className="flex align-center error-text" show={!isValid}>
            <ErrorOutlineIcon style={{ marginRight: 5 }} /> invalid json
          </Box>
        </Box>
      </Box>
      <TextareaAutosize
        id={label}
        className={classes.root}
        minRows={30}
        maxRows={30}
        style={{ width: '100%' }}
        onChange={handleValidateJson}
        placeholder="Enter a bit of valid JSON"
        {...props}
      />
    </label>
  );
}

const useTextAreaStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 15,
    background: palette.background.default,
    color: palette.text.primary,

    '&:focus': {
      borderColor: palette.common.cowbellBlue,
      outline: 'none',
    },
  },
}));

export const DiffToolConfig = {
  DiffTool: {
    component: DiffTool,
    config: {
      override: true,
      maxWidth: false,
    },
  },
};
