/**
 * @name TabsReducer
 * @description This reducer is intended to be used with redux or other global state mechanisms.
 */
import { handleActions } from 'redux-actions';
import AT from '../../actions/ActionTypes';

// actions
export const SET_ACCOUNT_DETAILS = 'SET_ACCOUNT_DETAILS';
export const SET_ADMIN_DETAILS = 'SET_ADMIN_DETAILS';
export const SET_CARRIER_DETAILS = 'SET_CARRIER_DETAILS';
export const SET_CLUSTER_DETAILS = 'SET_CLUSTER_DETAILS';
export const SET_AGENT_DETAILS = 'SET_AGENT_DETAILS';

// action creators
export const setAccountDetails = (payload) => {
  return {
    type: SET_ACCOUNT_DETAILS,
    payload,
  };
};

export const setAdminDetails = (payload) => {
  return {
    type: SET_ADMIN_DETAILS,
    payload,
  };
};

export const setCarrierDetails = (payload) => {
  return {
    type: SET_CARRIER_DETAILS,
    payload,
  };
};
export const setClusterDetails = (payload) => {
  return {
    type: SET_CLUSTER_DETAILS,
    payload,
  };
};

export const setAgentDetails = (payload) => {
  return {
    type: SET_AGENT_DETAILS,
    payload,
  };
};

// initial state
export const initialState = {
  account: {
    companyName: null,
    accountId: null,
    quoteNumber: null,
  },
  admin: {
    from: null,
    readOnly: {
      quote: false,
      policy: true,
      account: false,
      risk: true,
      documents: {
        upload: false,
        delete: true,
      },
      notes: false,
    },
  },
  carrier: {
    from: null,
    readOnly: {
      quote: false,
      policy: true,
      account: false,
      risk: true,
      documents: {
        upload: false,
        delete: false,
      },
      notes: false,
    },
  },
  cluster: {
    from: null,
    readOnly: {
      quote: false,
      policy: true,
      account: false,
      risk: true,
      documents: {
        upload: false,
        delete: false,
      },
      notes: false,
    },
  },
  agent: {
    from: null,
    readOnly: {
      quote: false,
      policy: true,
      account: false,
      risk: true,
      documents: {
        upload: false,
        delete: true,
      },
    },
  },
};

export const TabsReducer = handleActions(
  {
    [SET_ACCOUNT_DETAILS]: (state, action) => {
      return {
        ...state,
        account: {
          ...state.account,
          ...action.payload.account,
        },
      };
    },
    [SET_ADMIN_DETAILS]: (state, action) => {
      return {
        ...state,
        admin: {
          ...state.admin,
          ...action.payload.admin,
        },
      };
    },
    [SET_CARRIER_DETAILS]: (state, action) => {
      return {
        ...state,
        carrier: {
          ...state.carrier,
          ...action.payload.carrier,
        },
      };
    },
    [SET_CLUSTER_DETAILS]: (state, action) => {
      return {
        ...state,
        cluster: {
          ...state.cluster,
          ...action.payload.cluster,
        },
      };
    },
    [SET_AGENT_DETAILS]: (state, action) => {
      return {
        ...state,
        agent: {
          ...state.agent,
          ...action.payload.agent,
        },
      };
    },
    [AT.USER_LOGOUT]: () => {
      return initialState;
    },
  },
  initialState
);
