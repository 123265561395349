import React from 'react';
import _ from 'lodash';

// components
import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AgencyDashboardSearchListItemBase as ListItemBase } from '../global-search/agency-dashboard-search.components';

// utils
import { TypeaheadLoadingSkeleton } from '../global-search/TypeaheadLoadingSkeleton';
import type { Policy } from '../../../accounts/summary/types/policy.types';
import { searchPoliciesV2 } from '../../../../policies/PolicyService';
import { getData } from '../../../../utils/functional/fp';
import { ACTIVE_STATUS, ISSUED_STATUS } from '../../../_statics/policy.statics';
import { DRAFT_STATUS } from '../../../_statics/quote.statics';

export interface RenewPolicySearchListingProps {
  userInput: string;
  onChange: (value: string) => void;
  onSelect: (policy: Policy) => void;
}

export const RenewPolicySearchListing = ({
  userInput,
  onChange,
  onSelect,
}: RenewPolicySearchListingProps) => {
  const { data: policies = [], isFetching } = usePoliciesQuery({
    search: userInput,
    f: {
      isOpenForRenewal: { bo: 1 },
      status: { in: ['IN_FORCE', ISSUED_STATUS, DRAFT_STATUS, ACTIVE_STATUS] },
    },
  });

  if (isFetching) {
    return <TypeaheadLoadingSkeleton numOfGroups={1} />;
  }

  if (!policies.length) {
    return <RenewPolicySearchNullResult />;
  }

  const policiesGroupedByCompanyName = _.uniqBy<Policy>(
    policies,
    'companyName'
  );

  const handleSelect = (policy: Policy) => {
    onSelect(policy);
    onChange(policy.companyName);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ maxHeight: '13rem', overflow: 'auto', margin: '0.4rem' }}
    >
      {policiesGroupedByCompanyName.map((policy: Policy) => (
        <PolicyListItem
          key={policy.id}
          policy={policy}
          onClick={handleSelect}
        />
      ))}
    </Box>
  );
};

interface PolicyListItemProps {
  policy: Policy;
  onClick: (policy: Policy) => void;
}

const PolicyListItem = ({ policy, onClick }: PolicyListItemProps) => {
  return (
    <ListItemBase>
      <Typography
        variant="body1"
        style={{ marginLeft: '2rem', fontWeight: 'inherit' }}
        onClick={() => onClick(policy)}
      >
        {policy.companyName}
      </Typography>
    </ListItemBase>
  );
};

const RenewPolicySearchNullResult = () => (
  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
    <Typography variant="h6" style={{ textAlign: 'center' }}>
      No policies open for renewal
    </Typography>
  </Box>
);

const usePoliciesQuery = (query: any) => {
  return useQuery({
    queryKey: ['agency-dashboard-policies', query],
    queryFn: () => searchPoliciesV2({ params: query }).then(getData),
    select: (resp) => resp.content,
  });
};
