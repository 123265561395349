import { handleActions } from 'redux-actions';

const modalsInitialState = {};

// action types
export const SET_MODALS = 'SET_MODALS';

// actions creatros
export const setModals = (payload) => {
  return { type: SET_MODALS, payload };
};

export const globalModalsReducer = handleActions(
  {
    SET_MODALS: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  modalsInitialState
);
