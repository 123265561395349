import { getCowbellTranslations } from '../../../../i18n/translations';

const { t, translationKeys } = getCowbellTranslations();

export const bindSteps = {
  POLICY_HOLDER_INFORMATION: 'Policy Holder Contact',
  UPDATE_COMMISSION: 'Commission Percentage',
  AGENCY_INFORMATION: 'Surplus Lines Tax Documentation',
  SECURITY_QUESTIONS: 'Missing Security Questions',
  POLICY_EFFECTIVE_DATE: 'Policy Effective Date',
  DILLIGENT_SEARCH_REPORT: 'Diligent Search Report',
  MANUFACTURING: 'Manufacturing',
  TECH_E_O_COV_CONFIRMATION: 'Tech E&O Coverage Confirmation',
  NO_KNOWN_LOSS_LETTER: 'No Known Loss Letter',
  LOSS_RUNS: 'Loss Runs',
  INSURANCE_APPLICATION: 'Insurance Application',
  RENEWAL_APPLICATION: 'Renewal Application',
  BIND_QUOTE: 'Bind Quote',
  P100_BIND_QUOTE: 'Request to Bind',
  ADDITIONAL_NAMED_INSURED: 'Additional Named Insured Affidavit',
  ISSUE_POLICY: 'Issue Policy',
} as const;

export const subjectivityKeys = {
  POLICY_HOLDER_CONTACT: 'S001',
  SURPLUS_LINES_TAX_DOCUMENTATION: 'S002',
  DILLIGENT_SEARCH_REPORT: 'S003',
  MANUFACTURING: 'S004',
  TECH_E_O_COV_CONFIRMATION: 'S007',
  NO_KNOWN_LOSS_LETTER: 'S006',
  LOSS_RUNS: 'S005',
  INSURANCE_APPLICATION: 'S008',
  ADDITIONAL_NAMED_INSURED: 'S009',
  RENEWAL_APPLICATION: 'S025',
  P100_INSURANCE_APPLICATION: 'S026',
  P100_RENEWAL_APPLICATION: 'S027',
  PRE_ISSUE_NKLL: 'S028',
  PRE_ISSUE_LOSS_RUNS: 'S029',
  SECURITY_QUESTIONS: 'S036',
  PONE_NO_KNOWN_LOSS_LETTER: 'S037',
  P100PRO_INSURANCE_APPLICATION: 'S038',
  PONE_INSURANCE_APPLICATION: 'S039',
  P100PRO_RENEWAL_APPLICATION: 'S040',
  PONE_RENEWAL_APPLICATION: 'S041',
} as const;

export const surplusLinesStates = {
  LA: 'LA',
  MT: 'MT',
} as const;

export const orderedPreBindSteps = [
  bindSteps.POLICY_HOLDER_INFORMATION,
  bindSteps.AGENCY_INFORMATION,
  bindSteps.POLICY_EFFECTIVE_DATE,
  bindSteps.DILLIGENT_SEARCH_REPORT,
  bindSteps.MANUFACTURING,
  bindSteps.TECH_E_O_COV_CONFIRMATION,
  bindSteps.NO_KNOWN_LOSS_LETTER,
  bindSteps.LOSS_RUNS,
  bindSteps.INSURANCE_APPLICATION,
] as const;

export const orderedPreBindSubjectivities = [
  bindSteps.POLICY_HOLDER_INFORMATION,
  bindSteps.AGENCY_INFORMATION,
  bindSteps.DILLIGENT_SEARCH_REPORT,
  bindSteps.MANUFACTURING,
  bindSteps.TECH_E_O_COV_CONFIRMATION,
  bindSteps.NO_KNOWN_LOSS_LETTER,
  bindSteps.LOSS_RUNS,
  bindSteps.INSURANCE_APPLICATION,
] as const;

export const orderedPreIssueSteps = [
  ...orderedPreBindSteps,
  bindSteps.BIND_QUOTE,
  bindSteps.ADDITIONAL_NAMED_INSURED,
] as const;

export const orderedBindSteps = [
  ...orderedPreBindSteps,
  ...orderedPreIssueSteps,
  bindSteps.ISSUE_POLICY,
] as const;

export const PRE_ISSUANCE = 'PreIssuance';
export const POST_ISSUANCE = 'PostIssuance';

export const manufactruingCheckboxLabel = `I confirm that the manufacturing environment is separated and inaccessible
from the internet-facing network. This control is required prior to
binding.`;

export const attestationQuoteCheckBoxLabel = (
  str: string
) => `I understand that by ${str} this box, I am representing and
warranting that the document(s) uploaded and information provided:`;

export const techENOCheckboxLabel =
  'I confirm that the named insured has their own Tech E&O policy in force.';

export const tooltipLegalLanguage =
  'In doing so, I attest that the information presented is a complete, true and accurate representation on this business and understand that any fraudulent intent is subject to criminal and civil penalties.';

export const ANIAffidavitCheckboxLabel = `I confirm the Cowbell Additional Named Insured Affidavit is clearly signed
and dated by the named insured contact within the last 30 days.`;

export const insuranceApplicationCheckboxLabel = `${t(
  translationKeys.check
)} to verify signature and date of application`;

export const securityQuestionsCheckboxLabel = `${t(
  translationKeys.check
)} to verify security questions above are answered accurately.`;

export const noKnowLossLetterCheckboxLabel = `${t(
  translationKeys.check
)} to verify signature and date of NKLL.`;

export const lossRunsCheckboxLabel = `${t(
  translationKeys.check
)} to verify signature and dates of Loss Runs.`;

export const dilligentSearchReportCheckBoxLabel = `${t(
  translationKeys.check
)} to verify the Diligent Search Report is clearly signed and dated by the Named Insured contact.`;

export const MTComplianceCheckBox = `MONTANA SURPLUS LINES NOTICE`;

export const LAComplianceCheckBox = `LOUISIANA SURPLUS LINES NOTICE`;

export const acceptedUploadDocs =
  'image/png, image/jpg, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const acceptedUploadDocsPreviewText = `image/png, image/jpg, image/jpeg, application/pdf, application/doc`;

// P100 constants

export const insuranceAppNkll = `NKLL is attached with the signed application`;

export const commissionPercentageLabel = `${t(
  translationKeys.check
)} to verify commission percentage`;
