import { commonDateParamMappings } from '../../../api/common/mappings';
import { ServiceHandlerFactory } from '../../../utils/serviceUtils';

export const createCommissionsContact = ServiceHandlerFactory({
  axios: {
    url: '/api/commissions/v1/contact',
    method: 'post',
  },
});

export const updateCommissionsContact = ServiceHandlerFactory({
  axios: {
    url: '/api/commissions/v1/contact',
    method: 'put',
  },
});

export const deleteCommissionsContact = ServiceHandlerFactory({
  axios: {
    url: 'api/commissions/v1/contact?id=:id',
    method: 'delete',
  },
});

export const fetchCommissionsContact = ServiceHandlerFactory({
  axios: {
    url: '/api/commissions/v1/contact',
  },
  paramSchema: [
    ...commonDateParamMappings,
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'modified':
            if (orderBy && order) {
              return `${orderBy},${order}`;
            }
            break;
          default:
            return orderBy ? `${orderBy}.keyword,${order}` : null;
        }
      },
    },
  ],
});
