import React from 'react';
import { SvgIcon } from '@mui/material';

export const ExcessIcon = ({ fill = '#fff', ...props }) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g>
      <g fill="none">
        <path
          d="M12 0A12 12 0 1 1 0 12 12 12 0 0 1 12 0z"
          transform="translate(-903 -398) translate(903 398)"
        />
        <path
          fill="#2180e2"
          d="M12 1.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z"
          transform="translate(-903 -398) translate(903 398)"
        />
      </g>
      <path
        fill={fill}
        d="M2.548-13.015H.247L4-6.5.247-.405H2.4l2.762-4.749L7.868-.405h2.283l-3.737-6.2 3.737-6.406H8L5.254-8.045z"
        transform="translate(-903 -398) translate(909.753 417.015)"
      />
    </g>
  </SvgIcon>
);
