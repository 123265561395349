import React from 'react';
import { compose } from 'redux';
import { useForm, FormContext } from 'react-hook-form-4';
import _ from 'lodash';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { withStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';
import CbButton from '../../Buttons/CbButton';
import { regenerateApplication } from '../../../inbox/QuotesService';

export const GenerateQuoteApplication = compose(
  withStyles(({ config }) => ({
    highlight: {
      color: config.colors.cowbellBlue,
      fontWeight: config.weights.bold,
    },
  }))
)(({ data, classes, ...props }) => {
  const { id, quoteNumber, product } = data;
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, ...methods } = useForm({});

  const onSubmit = () => {
    regenerateApplication({ id, product })
      .then(() => {
        enqueueSnackbar(
          'Quote submitted for Application Regeneration!. Updated application document will be ready in about 2 minutes',
          { variant: 'success' }
        );
        props.close();
      })
      .catch((error) => {
        enqueueSnackbar(
          _.get(
            error,
            'response.data',
            'Application Document Regeneration request failed!'
          ),
          {
            variant: 'error',
          }
        );
      });
  };

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className="paf">
            <DialogContentText>
              Are you sure you want to regenerate the application documents for
              Quote Number{' '}
              <span className={classes.highlight}>{quoteNumber}</span> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CbButton onClick={props.close} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton type="submit" styleName="ctaButton">
              Regenerate
            </CbButton>
          </DialogActions>
        </form>
      </FormContext>
    </>
  );
});

export const GenerateQuoteApplicationModalConfig = {
  GenerateQuoteApplication: {
    component: GenerateQuoteApplication,
    config: {
      fullWidth: true,
      title: 'Regenerate Application Document',
    },
  },
};
