import React from 'react';
import _ from 'lodash';

// components
import {
  Box,
  createTheme,
  Dialog,
  DialogTitle as MuiDialogTitle,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
  Typography,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import CloseIcon from '@mui/icons-material/HighlightOff';

import { withShowable } from '../../console/_global/lib/withShowable';

import DialogThemeCreator from '../../themes/dialog.overrides';
import { lightThemebaseV4 } from '../../themes/light.theme';
import { darkThemebaseV4 } from '../../themes/dark.theme';

const BASE_THEMES = {
  light: lightThemebaseV4,
  dark: darkThemebaseV4,
};

const DialogTitle = withShowable(MuiDialogTitle);

export const DialogBase = ({
  title,
  overFlowVisible,
  children,
  override,
  inheritTheme,
  icon,
  ...props
}) => {
  const iconStyles = useIconStyles();
  const closeIconStyles = useCloseIconStyles();
  const classes = useClasses();
  const outerTheme = useTheme();

  const theme = React.useMemo(() => {
    const themebase = BASE_THEMES[inheritTheme || outerTheme.palette.mode];
    const dialogThemebase = DialogThemeCreator(themebase);

    return createTheme(adaptV4Theme(_.merge({}, themebase, dialogThemebase)));
  }, [inheritTheme, outerTheme.palette.mode]);

  if (override) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {React.cloneElement(children, {
            ...props,
            iconStyles,
            closeIconStyles,
          })}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  const cssClasses = {
    ...(overFlowVisible ? { classes: { paper: classes.overflowVisible } } : {}),
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog {...props} {...cssClasses}>
          <DialogTitle show={title} component="div">
            <Typography variant="h6" component="h2">
              <Box>
                <Icon
                  icon={icon}
                  fill={theme.palette.icon.primary}
                  className={iconStyles.root}
                />
                {title}
              </Box>
            </Typography>
          </DialogTitle>
          {children}
          <CloseIcon className={closeIconStyles.root} onClick={props.onClose} />
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const useClasses = makeStyles({
  overflowVisible: {
    overflow: 'visible',
  },
});

const useCloseIconStyles = makeStyles(({ palette }) => ({
  root: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(-1.25rem, 1.25rem)',
    fill: palette.icon.primary,
  },
}));

const useIconStyles = makeStyles(({ palette }) => ({
  root: {
    width: '2rem',
    height: '2rem',
    marginRight: '1rem',
    float: 'left',
    fill: palette.icon.primary,
  },
}));

const Icon = ({ icon, ...props }) => {
  const iconStyles = useIconStyles();

  if (icon) {
    return React.cloneElement(icon, {
      style: {},
      ...props,
    });
  }

  return (
    <img
      src="images/Cowbell.svg"
      alt="cowbell icon"
      className={iconStyles.root}
    />
  );
};
