import React, { useState } from 'react';
import * as yup from 'yup';
import _ from 'lodash';

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import {
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CbButton from '../../../../Buttons/CbButton';
import { TextFieldBase } from '../../../../inputs/TextFieldBase';
import { iconMappings } from '../../../../../console/_global/navigation/IconsMappings';
import { InputLabelBase } from '../../../../inputs/InputLabelBase';
import { withShowable } from '../../../../../console/_global/lib/withShowable';
import { withFormController } from '../../../../hocs/forms';

import { updateWebhook } from '../../../../../api/OutgoingWebhooksService';

const TextField = withFormController(TextFieldBase);

const schema = yup.object().shape({
  userId: yup
    .string()
    .label('User Name')
    .when('$authType', (authType, schemaData) => {
      if (authType === '0') return schemaData.required();
    }),
  password: yup
    .string()
    .label('Password')
    .when('$authType', (authType, schemaData) => {
      if (authType === '0') return schemaData.required();
    }),
  clientId: yup
    .string()
    .label('Client ID')
    .when('$authType', (authType, schemaData) => {
      if (authType === '2') return schemaData.required();
    }),
  clientSecret: yup
    .string()
    .label('Client Secret')
    .when('$authType', (authType, schemaData) => {
      if (authType === '2') return schemaData.required();
    }),
});

const AuthFields = withShowable(({ authType }) => {
  if (authType === '0') {
    return (
      <>
        <Box>
          <InputLabelBase required>User Name</InputLabelBase>
          <TextField name="userId" fullWidth placeholder="Enter a user name" />
        </Box>
        <Box>
          <InputLabelBase required>Password</InputLabelBase>
          <TextField
            name="password"
            type="password"
            fullWidth
            placeholder="Enter a password"
          />
        </Box>
      </>
    );
  }
  return (
    <>
      <Box>
        <InputLabelBase required>Client ID</InputLabelBase>
        <TextField name="clientId" fullWidth placeholder="Enter a client ID" />
      </Box>
      <Box>
        <InputLabelBase required>Client Secret</InputLabelBase>
        <TextField
          name="clientSecret"
          type="password"
          fullWidth
          placeholder="Enter a client secret"
        />
      </Box>
    </>
  );
});

export const ConfigureAuth = ({ data, ...props }) => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { webhook } = data;
  const { webhookId, webhookUrl } = webhook;
  const [authType, setAuthType] = useState(authMap[webhook.authType]);
  const defaultValues = _.pick(webhook, ['clientId', 'userId']);
  const { ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    context: { authType },
  });

  const handleAuthChange = (event) => {
    setAuthType(event.target.value);
    methods.reset({ userId: '', clientId: '' });
  };

  const onSubmit = (values) => {
    const webhookPayload = _.omit(webhook, [
      'clientId',
      'clientSecret',
      'userId',
      'password',
    ]); // remove existing auth-related keys
    updateWebhook(
      { webhookId },
      { ...webhookPayload, ...values, authType: Number(authType) }
    )
      .then(() => {
        queryClient.invalidateQueries(['webhook', webhookId]);
        enqueueSnackbar(`Webhook ${webhookUrl} has been updated.`, {
          variant: 'success',
        });
        props.close();
      })
      .catch((error) => {
        enqueueSnackbar(
          _.get(
            error,
            'response.data',
            `Something went wrong while updating ${webhookUrl}.`
          ),
          {
            variant: 'error',
          }
        );
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <>
          <DialogContent className={classes.dialogContent}>
            <FormControl variant="standard" component="fieldset">
              <RadioGroup
                name="authType"
                value={authType}
                onChange={handleAuthChange}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="secondary" />}
                  label="NO AUTH"
                />
                <FormControlLabel
                  value="0"
                  control={<Radio color="secondary" />}
                  label="BASIC AUTH"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="secondary" />}
                  label="CLIENT ID, CLIENT SECRET"
                />
              </RadioGroup>
            </FormControl>
            <AuthFields authType={authType} show={authType !== '1'} />
          </DialogContent>
          <DialogActions>
            <CbButton onClick={props.close} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton type="submit" styleName="ctaButton">
              Configure
            </CbButton>
          </DialogActions>
        </>
      </form>
    </FormProvider>
  );
};

const authMap = {
  BASIC: '0',
  NOAUTH: '1',
  CLIENTSECRET: '2',
};

const useStyles = makeStyles(() => ({
  dialogContent: {
    paddingTop: '5rem !important',
    textAlign: 'center',
  },
}));

export const ConfigureAuthConfig = {
  ConfigureAuth: {
    component: ConfigureAuth,
    config: {
      fullWidth: true,
      title: 'Configure webhook endpoint',
      icon: iconMappings.ACCOUNTS.iconImport,
    },
  },
};
