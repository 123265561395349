import { ServiceHandlerFactory } from '../utils/serviceUtils';

export const getAllWebhooks = ServiceHandlerFactory({
  axios: {
    url: 'api/outgoingwebhook/v1/webhooks',
  },
});

export const getWebhook = ServiceHandlerFactory({
  axios: {
    url: 'api/outgoingwebhook/v1/webhooks/:webhookId',
  },
});

export const postWebhook = ServiceHandlerFactory({
  axios: {
    url: 'api/outgoingwebhook/v1/webhooks',
    method: 'POST',
  },
});

export const updateWebhook = ServiceHandlerFactory({
  axios: {
    url: 'api/outgoingwebhook/v1/webhooks/:webhookId',
    method: 'PUT',
  },
});

export const updateWebhookStatus = ServiceHandlerFactory({
  axios: {
    url: 'api/outgoingwebhook/v1/webhooks/:webhookId/status',
    method: 'PUT',
  },
});

export const deleteWebhook = ServiceHandlerFactory({
  axios: {
    url: 'api/outgoingwebhook/v1/webhooks/:webhookId',
    method: 'DELETE',
  },
});

export const getAllAvailableEvents = ServiceHandlerFactory({
  axios: {
    url: '/api/outgoingwebhook/v1/webhooks/events',
  },
});
