import React from 'react';

// mui
import { Box as MuiBox } from '@mui/material';

// components
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CbButton from '../../../../components/Buttons/CbButton';
import { DocumentDownloader } from '../../../../components/fileUploader/DocumentDownloader';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import DocumentUploadBase from './shared/DocumentUploadBase';
import CheckBoxLabel from '../CheckBoxLabel';
import { ScreenTitle } from './shared/ScreenTitle';

// hooks and hocs
import useUploadZone from '../../../../components/fileUploader/v2/hooks/useUploadzone';
import { withShowable } from '../../../_global/lib/withShowable';

// services
import useDocUpload from './shared/useDocUpload';

// utils and constants
import { noKnowLossLetterCheckboxLabel } from '../constants/constants';
import useGetSubjectivityProgress from './shared/useGetSubjectivityProgress';
import { downloadNKLLTemplate } from '../../../../api/documents.api';
import useGetSubjectivityDocs from './shared/useGetSubjectivityDocs';
import { signedDocumentConfirmationSchema } from './schemas/validationSchemas';
import SignatureHolderConfirmation from './shared/SignatureHolderConfirmation';
import { SubjectivityCompletionDate as SubjectivityCompletionDateBase } from './shared/SubjectivityCompletionDate';
import useCompleteQuoteSubjectivity from './shared/useCompleteQuoteSubjectivity';
import { useDocSignedDate } from './shared/useDocSignedDate';
import { determineIfStepIsEditable } from '../utils/bindQuoteWorkFlowUtils';
import CowbellReferToUWButton from './shared/referral/CowbellReferToUWButton';

const Box = withShowable(MuiBox);
const SubjectivityCompletionDate = withShowable(SubjectivityCompletionDateBase);

const NoKnownLossLetter = ({
  goForward,
  goBack,
  completeStep,
  step,
  quoteDetails,
  subjectivities,
  quoteProgress,
  formState,
}) => {
  const {
    quoteSubjectivityId,
    completed: isStepCompleted,
    subjectivitySubType,
  } = subjectivities[step];

  const [isNKLLAcknowledged, setIsNKLLAcknowledged] =
    React.useState(isStepCompleted);

  const { uploadMultipleDocuments, isUploadingDoc } = useDocUpload({
    quoteDetails,
  });

  const [uploadedDocs, setUploadedDocs] = React.useState([]);

  const { files, ...uploadZoneParams } = useUploadZone({
    docType: 'No_Known_Loss_Letter',
    maxFiles: 4,
    multiple: true,
  });

  const { handleSubmit, setValue, ...methods } = useForm({
    resolver: yupResolver(
      signedDocumentConfirmationSchema(files ?? uploadedDocs ?? [])
    ),
  });

  const isFormDirty = methods.formState.isDirty;

  const { completeSubjectivity, isCompletingStep } =
    useCompleteQuoteSubjectivity({
      quoteId: quoteDetails.uiData.quoteId,
      productType: quoteDetails.uiData.productType,
    });

  const isStepEditable = determineIfStepIsEditable({
    subjectivitySubType,
    status: quoteProgress.quoteAgencyStatus,
    isAPI: quoteProgress.isAPI,
    productType: quoteDetails.uiData.productType,
  });

  const {
    data: allSubjectivityInformation = {},
    isLoading: isSubjectivityDataLoading,
    isFetching: isFetchingSubjectivityData,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId,
    quoteId: quoteDetails.id,
    productType: quoteDetails.uiData.productType,
  });

  const { data: subjectivityData } = allSubjectivityInformation;

  const {
    data: uploadedDocuments,
    isInitialLoading: isUploadedDocsLoading,
    isFetching: isFetchingUploadedDocs,
  } = useGetSubjectivityDocs({
    quoteSubjectivityId,
    accountId: quoteDetails.uiData?.accountId,
    uploadedDocs: subjectivityData?.uploadedDocuments,
    isFetchingSubjectivityData,
  });

  React.useEffect(() => {
    setUploadedDocs(uploadedDocuments);
  }, [uploadedDocuments]);

  React.useEffect(() => {
    if (files || isFormDirty) {
      setIsNKLLAcknowledged(false);
    }
    if (subjectivityData) {
      setValue('signeeFullName', subjectivityData.signeeFullName);
      setValue('signeeTitle', subjectivityData.signeeTitle);
    }
    if (subjectivityData && files) {
      setValue('signeeFullName', '');
      setValue('signeeTitle', '');
    }
  }, [files, isFormDirty, setValue, subjectivityData]);

  useDocSignedDate({
    formState,
    files,
    setValue,
    uploadedDocuments,
    subjectivityData,
  });

  const isLoadingSubjectivityData =
    isSubjectivityDataLoading ||
    isFetchingSubjectivityData ||
    isUploadedDocsLoading ||
    isFetchingUploadedDocs;

  if (isLoadingSubjectivityData) {
    return null;
  }

  const onSubmit = (formValues) => {
    if (!files) {
      const uploadedDocumentsPayload = uploadedDocuments.map(
        ({ id }, index) => ({
          docId: id,
          docSignedOn: formValues[`docSignedOn-${index}`],
        })
      );
      const params = {
        quoteSubjectivityId,
        goForward,
        payload: {
          uploadedDocuments: uploadedDocumentsPayload,
          userAttested: isNKLLAcknowledged,
          ...formValues,
        },
      };
      return completeSubjectivity(params);
    }

    const uploadDocumentParams = {
      formValues,
      files,
      userAttested: isNKLLAcknowledged,
      quoteDetails,
      completeStep,
      goForward,
      step,
      docTypes: Array(files.length).fill('No_Known_Loss_Letter'),
      quoteSubjectivityId,
    };

    uploadMultipleDocuments(uploadDocumentParams);
  };

  const handleCheckboxChange = () => {
    setIsNKLLAcknowledged((prevState) => !prevState);
  };

  const handleGoNext = () => {
    if (!files && !uploadedDocs?.length) {
      return goForward();
    }
  };

  return (
    <Box className="contrast-text" width="50%">
      <ScreenTitle
        subjectivitySubType={subjectivitySubType}
        title={allSubjectivityInformation.subjectivityTitle}
      />
      <Box show={!subjectivityData}>
        <h3>{allSubjectivityInformation.subjectivityDescription ?? ''}</h3>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <DocumentUploadBase
              uploadZoneParams={uploadZoneParams}
              files={files}
              isUploadingDoc={isUploadingDoc}
              uploadedDocs={uploadedDocuments}
              isStepEditable={isStepEditable}
              docType="NKLL"
              datePickerProps={{
                displayDatePicker: true,
                min: allSubjectivityInformation?.hydratedInfo
                  ?.signatureValidRangeStartDate,
                max: allSubjectivityInformation?.hydratedInfo
                  ?.signatureValidRangeEndDate,
              }}
            />

            <Box show={files || subjectivityData}>
              <SignatureHolderConfirmation
                disableFormFields={!isStepEditable || isUploadingDoc}
              />
            </Box>

            <Box paddingBottom="1rem" show={!files && isStepEditable}>
              <h3>Don't have the signed NKLL?</h3>
              <DocumentDownloader
                type=".pdf"
                name="No Known Loss Letter"
                onDownload={() =>
                  downloadNKLLTemplate(
                    allSubjectivityInformation?.hydratedInfo?.nkllTemplatePath
                  )
                }
              >
                {({ handleDocumentDownload }) => (
                  <CbButton
                    styleName="ctaButton"
                    size="large"
                    onClick={handleDocumentDownload}
                  >
                    Download blank NKLL
                  </CbButton>
                )}
              </DocumentDownloader>
            </Box>
          </Box>
          <Box show={files || subjectivityData}>
            <Box>
              <CheckBoxBase
                name="noKnownLossLetterConfirmation"
                label={<CheckBoxLabel label={noKnowLossLetterCheckboxLabel} />}
                onChange={handleCheckboxChange}
                checked={isNKLLAcknowledged}
                disabled={
                  (subjectivityData?.userAttested && !files && !isFormDirty) ||
                  isUploadingDoc
                }
              />
            </Box>
          </Box>

          <SubjectivityCompletionDate
            allSubjectivityInformation={allSubjectivityInformation}
            show={isStepCompleted}
          />

          <Box paddingTop="1rem">
            <CbButton styleName="cancel" size="medium" onClick={goBack}>
              Back
            </CbButton>

            <CbButton
              styleName="ctaButton"
              size="medium"
              type="submit"
              onClick={handleGoNext}
              loading={isUploadingDoc || isCompletingStep}
              disabled={
                isUploadingDoc ||
                isCompletingStep ||
                (files && !isNKLLAcknowledged) ||
                (isStepCompleted && !isNKLLAcknowledged)
              }
              style={{ marginRight: '0.833rem' }}
            >
              Next
            </CbButton>

            <CowbellReferToUWButton
              quoteId={quoteDetails.id}
              subjectivityId={quoteSubjectivityId}
              productType={quoteDetails.uiData.productType}
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default NoKnownLossLetter;
