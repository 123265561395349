import React from 'react';
import { useTheme, IconButton } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import SimpleTooltip from '../../../../SimpleTooltip';
import { TableStateContext } from '../../TableStateContext';
import { triggerCSVDownload } from '../../../../../providers/CSVDownloadSubWrapper';

export const CsvDownloadButton = ({ ...props }) => {
  const { tableId, columns, rows } = React.useContext(TableStateContext);
  return (
    <CsvDownloadButtonBase
      {...props}
      tableId={tableId}
      columns={Object.values(columns)}
      data={rows}
    />
  );
};

const CsvDownloadButtonBase = ({ ...props }) => {
  const theme = useTheme();

  const onStartDownload = () =>
    triggerCSVDownload({
      columns: props.columns,
      data: props.data,
      filename: props.tableId,
    });

  return (
    <IconButton
      onClick={onStartDownload}
      size="small"
      style={{
        cursor: 'pointer',
        color: theme.palette.text.primary,
        marginLeft: '0.4rem',
      }}
    >
      <SimpleTooltip title="Download Table as CSV file">
        <GetAppIcon
          style={{ fontSize: '2rem', position: 'relative', right: 1 }}
        />
      </SimpleTooltip>
    </IconButton>
  );
};

export default CsvDownloadButtonBase;
