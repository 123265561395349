import React from 'react';
import classnames from 'classnames';

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = ({ palette }) => {
  return {
    radioGroupContainer: {
      width: '100%',
      height: '100%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    form: {
      paddingLeft: '15px',
      marginTop: '-3px',
    },
    borderBlue: {
      border: `1px solid ${palette.tabs.profile} !important`,
    },
  };
};

export const RadioButtons = withStyles(styles)(
  ({
    classes,
    className,
    borderBlue,
    borderWhite,
    required = true,
    ...props
  }) => {
    const classNames = classnames(className, {
      [classes.borderBlue]: borderBlue,
    });

    const handleChange = (event) => {
      const { disabled } = props;
      if (!disabled) {
        if (props.checkError) {
          props.checkError(true);
        }

        props.infunc(event.target.value);
      }
    };

    const radios = props.values.map((radio) => {
      return (
        <FormControlLabel
          value={radio.value}
          control={<Radio color="primary" inputProps={{ required }} />}
          label={radio.label}
          labelPlacement="end"
          className={classes.form}
          disabled={props.disabled}
        />
      );
    });

    return (
      <FormControl
        variant="standard"
        required
        component="fieldset"
        className={`${classNames} ${props.classContainer}`}
      >
        <RadioGroup
          aria-label="position"
          name={props.name}
          value={props.value.value}
          onClick={handleChange}
          required
          row
          className={classes.radioGroupContainer}
        >
          {radios}
        </RadioGroup>
      </FormControl>
    );
  }
);

export default RadioButtons;
