//core
import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

//mui
import { Box, Divider, Typography } from '@mui/material';

//components
import _ from 'lodash';
import type { AxiosResponse } from 'axios';
import TickedSlider from '../../../agencies/quotes/PrimeX/customizeQuote/components/TickedSlider';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import { commissionPercentageLabel } from '../constants/constants';
import CheckBoxLabel from '../CheckBoxLabel';
import CbButton from '../../../../components/Buttons/CbButton';

//utils
import { deriveCurrencyFormat } from '../../../../i18n/currencies';
import { languages } from '../../../../i18n/i18n.language-config';
import { QuoteStatus } from '../../../_statics/quote.statics';

//types
import type { StepperScreenProps } from '../StepperScreen';
import type { GetPrimeXQuoteDetailsResponse } from '../../../../api/quotes.api.types';

//services and utils
import { recalculateQuotePX, updatePrimeXQuote } from '../../../../api';
import type { getFormattedQuoteData } from '../utils/bindQuoteWorkFlowUtils';
import { useAPIErrorHandler } from '../../../../components/hooks/useAPIErrorHandler';
import LoadingSpinnerOverlay from '../../../agencies/quotes/PrimeX/customizeQuote/components/LoadingSpinnerOverlay';
import { getAgencyDetails } from '../../../../agencies/AgencyService';
import { sleep } from '../../../../utils/appUtils';

interface UpdateCommissionResolverProps {
  goForward: () => void;
  goBack: () => void;
  quoteProgress: StepperScreenProps['quoteProgress'];
  quoteDetails: GetPrimeXQuoteDetailsResponse & {
    uiData: ReturnType<typeof getFormattedQuoteData>;
  };
}

const UpdateCommissionResolver = (props: UpdateCommissionResolverProps) => {
  const handleAPIError = useAPIErrorHandler();
  const { data, isLoading } = useQuery({
    queryKey: ['stepper-agency-details'],
    queryFn: () => getAgencyDetails(),
    select: (resp) => resp.data,
    onError: handleAPIError('Unable to fetch agency details'),
  });

  if (isLoading) {
    return <LoadingSpinnerOverlay />;
  }

  return <UpdateCommission {...props} commissionMax={data?.commission} />;
};

interface UpdateCommissionProps extends UpdateCommissionResolverProps {
  commissionMax: number;
}

const UpdateCommission = ({
  goForward,
  goBack,
  quoteProgress,
  quoteDetails,
  commissionMax,
}: UpdateCommissionProps) => {
  const [isCommissionAcknowledged, setIsCommissionAcknowledged] =
    React.useState(true);
  const [commissionRate, setCommissionRate] = React.useState(
    quoteDetails?.quote?.commissionRate ?? 0
  );

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [recalculatedQuote, setRecalculatedQuote] = React.useState({
    totalPremium: quoteDetails.quote.raterResponse?.totalPremium,
    premium: quoteDetails.quote.raterResponse?.basePremium,
    insurancePremiumTax: quoteDetails.quote.raterResponse?.insurancePremiumTax,
    policyIssuanceFee: quoteDetails.quote.raterResponse?.adminFee,
    commissions: quoteDetails.quote.raterResponse?.commissions,
  });

  const { enqueueSnackbar } = useSnackbar();
  const handleAPIError = useAPIErrorHandler();
  const queryClient = useQueryClient();

  const canEditCommission =
    quoteProgress.quoteAgencyStatus !== QuoteStatus.BOUND_STATUS &&
    quoteProgress.quoteAgencyStatus !== QuoteStatus.SIGNED_STATUS;

  const handleSliderChange = _.debounce((newValue: number | number[]) => {
    setIsSubmitting(true);
    recalculateQuotePX(
      quoteDetails.uiData.productType,
      quoteDetails.uiData.quoteId,
      {
        data: {
          commissionRate: newValue,
        },
      }
    )
      .then((response: AxiosResponse) => {
        setCommissionRate(newValue as number);
        setIsCommissionAcknowledged(false);
        setRecalculatedQuote({
          totalPremium: response.data.raterResponse.totalPremium,
          premium: response.data.raterResponse.basePremium,
          insurancePremiumTax: response.data.raterResponse.insurancePremiumTax,
          policyIssuanceFee: response.data.raterResponse.adminFee,
          commissions: response.data.raterResponse.commissions,
        });
      })
      .catch(handleAPIError('Unable to calulate new premium.'))
      .finally(() => setIsSubmitting(false));
  }, 1000);

  const handleCheckboxChange = () => {
    setIsCommissionAcknowledged((prevState) => !prevState);
  };

  const handleUpdateCommission = () => {
    if ((quoteDetails?.quote?.commissionRate ?? 0) === commissionRate) {
      return goForward();
    }

    setIsSubmitting(true);
    const { productType, quoteId } = quoteDetails.uiData;

    updatePrimeXQuote(productType, quoteId, {
      data: {
        commissionRate,
      },
    })
      .then(async () => {
        enqueueSnackbar('Commission updated successfully', {
          variant: 'success',
        });
        await sleep(1000);
        queryClient.invalidateQueries([
          'quote-details',
          quoteDetails.uiData.quoteId,
        ]);
        goForward();
      })
      .catch(handleAPIError('An issue occurred while updating the commission'))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Box maxWidth="60%" className="contrast-text">
      {isSubmitting && <LoadingSpinnerOverlay />}
      <Typography
        variant="h4"
        component="h3"
        style={{
          fontWeight: 600,
          fontSize: '1.5rem',
          marginBottom: '1.17rem',
        }}
      >
        Commission Percentage
      </Typography>

      <Typography
        variant="h4"
        component="span"
        style={{ fontWeight: 600, fontSize: '1.17rem' }}
      >
        You can change the base commission percentage from the max commission
        percentage down to zero. This will update the total amount accordingly.
      </Typography>

      <Box
        mt={2}
        pl={2}
        fontSize="1.2rem"
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        <Box display="flex" alignItems="center">
          <RowItem title="Premium">
            {deriveCurrencyFormat(
              languages['en-GB'],
              recalculatedQuote.premium
            )}
          </RowItem>
        </Box>

        <Box display="flex" alignItems="center">
          <RowItem
            title={`Commission (${commissionRate}% of
            premium)`}
          >
            {deriveCurrencyFormat(
              languages['en-GB'],
              recalculatedQuote.commissions
            )}
          </RowItem>
          <TickedSlider
            boxProps={{
              alignItems: 'center',
              gap: '1rem',
              width: '14rem',
              pl: 2,
            }}
            disabled={!canEditCommission}
            min={0}
            max={commissionMax}
            initialSliderValue={commissionRate}
            onChange={handleSliderChange}
            step={1}
          >
            {(value) => <span>{value}%</span>}
          </TickedSlider>
        </Box>

        <RowItem title="IPT (12% of premium)">
          {deriveCurrencyFormat(
            languages['en-GB'],
            recalculatedQuote.insurancePremiumTax
          )}
        </RowItem>

        <RowItem title="Policy Issuance Fee">
          {deriveCurrencyFormat(
            languages['en-GB'],
            recalculatedQuote.policyIssuanceFee
          )}
        </RowItem>

        <Divider style={{ width: '26rem' }} />

        <RowItem title="Total Amount">
          {deriveCurrencyFormat(
            languages['en-GB'],
            recalculatedQuote.totalPremium
          )}
        </RowItem>

        <CheckBoxBase
          name="commissionPercentageAcknowledgement"
          label={(<CheckBoxLabel label={commissionPercentageLabel} />) as any}
          onChange={handleCheckboxChange}
          checked={isCommissionAcknowledged}
          disabled={!canEditCommission}
        />
      </Box>

      <Box component="p" fontSize="1.2rem">
        <i>
          Note: Once the quote is bound, you will not be able to change the base
          commission. Please contact support@cowbellcyber.ai if you need help.
        </i>
      </Box>

      <Box mt={4}>
        <CbButton styleName="cancel" size="medium" onClick={goBack}>
          Back
        </CbButton>
        <CbButton
          styleName="ctaButton"
          size="medium"
          onClick={handleUpdateCommission}
          disabled={!isCommissionAcknowledged || isSubmitting}
          loading={isSubmitting}
        >
          Next
        </CbButton>
      </Box>
    </Box>
  );
};

export default UpdateCommissionResolver;

interface RowItemProps {
  title: string;
  children: string;
}

const RowItem = ({ title, children }: RowItemProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="20rem">{title}</Box>
      <Box width="5.5rem" textAlign="right">
        {children}
      </Box>
    </Box>
  );
};
