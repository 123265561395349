import { useMemo } from 'react';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import { useQuery } from '@tanstack/react-query';

import type { BoxProps } from '@mui/material';
import { Box, Paper, styled, Typography } from '@mui/material';
import Showable from '../../../../../../components/Showable';
import CoverageCheckbox from '../../customizeQuote/components/CoverageCheckbox';
import CoverageLineItem from './CoverageLineItem';
import CoverageGroupHeader from './CoverageGroupHeader';
import type { QuoteDetailsCoverage } from '../hooks/useGetQuotePageDetails';
import type { ProductType } from '../../../../../../types';
import { getRegionByProduct } from '../../../../../../i18n/utils';

import { fetchPresetCoverageGroups } from '../../../../../../api';

interface CoverageDetailsProps {
  coverages: QuoteDetailsCoverage[];
  productType: ProductType;
  boxProps?: BoxProps;
}

const CoverageDetails = ({
  coverages,
  productType,
  boxProps = {},
}: CoverageDetailsProps) => {
  const language = getRegionByProduct(productType);
  const parsedCoverages = useMemo(() => {
    return coverages.map((curCoverage) => ({
      ...curCoverage,
      name: curCoverage.name?.toLowerCase(),
      groupName: curCoverage.groupName?.toUpperCase(),
    }));
  }, [coverages]);

  const groupedCoverages = _groupBy(parsedCoverages, 'groupName');

  const { data: presetCoverageGroups } = useQuery({
    queryKey: ['preset-coverage-group'],
    queryFn: async () =>
      fetchPresetCoverageGroups().then(
        (resp) => resp.data.presetCoverageGroups
      ),
    select: (coverageGroups) => {
      return _sortBy(coverageGroups, ['order']);
    },
  });

  if (!presetCoverageGroups) return <div>Loading...</div>;

  return (
    <Box {...boxProps}>
      <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
        Coverage Details
      </Typography>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}
      >
        <span className="contrast-text" style={{ marginRight: '1rem' }}>
          Legend:
        </span>
        <CoverageCheckbox.Mandatory />
        <CoverageCheckbox.Selected disabled />
        <CoverageCheckbox.Available disabled />
      </div>

      {presetCoverageGroups.map((presetGroup, idx) => {
        return (
          <Showable
            key={idx}
            show={Boolean(groupedCoverages[presetGroup.name])}
          >
            <CoverageContainer elevation={5} className="contrast-text">
              <CoverageGroupHeader>
                <Typography>{presetGroup.groupName}</Typography>
              </CoverageGroupHeader>
              {groupedCoverages[presetGroup.name]?.map((coverage, index) => {
                return (
                  <CoverageLineItem
                    key={coverage.name}
                    isOdd={index % 2 !== 0}
                    language={language}
                    {...coverage}
                  />
                );
              })}
            </CoverageContainer>
          </Showable>
        );
      })}
    </Box>
  );
};

const CoverageContainer = styled(Paper)({
  marginBottom: '2rem',
  borderRadius: 4,
});

export default CoverageDetails;
