import { DialogActions, DialogContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';

import CbButton from '../../Buttons/CbButton';
import { Modal } from './helpers/v2.modal.helpers';

import { disableEnableUserPrivileges } from '../../../api/UserService';

import { manageAPIError } from '../../../utils';
import { delayedEvent } from '../../../utils/eventUtils';

const DisableEnableUser = ({ data }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const enableOrDisableText = data?.enabled ? 'disable' : 'enable';

  function handleEnableDisable() {
    disableEnableUserPrivileges({ email: data.email, toggle: !data.enabled })
      .then(() => {
        delayedEvent('table-refetch', 1000, data.tableID);

        enqueueSnackbar(
          `User's privileges have successfully been ${`${enableOrDisableText}d`}!`,
          {
            variant: 'success',
          }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          manageAPIError(
            error,
            'Unable to update Privileges, please try again or contact support.'
          ),
          { variant: error }
        );
      })
      .finally(() => {
        Modal.hide();
      });
  }

  return (
    <>
      <DialogContent>
        <Typography align="center" className={classes.typographyText}>
          Are you sure you want to {enableOrDisableText}{' '}
          {data.email ?? data.firstName}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <CbButton buttonText="Cancel" onClick={Modal.hide} styleName="cancel" />
        <CbButton
          buttonText="Update User"
          type="submit"
          styleName="ctaButton"
          onClick={handleEnableDisable}
        />
      </DialogActions>
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  typographyText: {
    color: palette.primary.contrastText,
  },
}));

export const DisableEnableUserConfig = {
  DisableEnableUser: {
    component: DisableEnableUser,
    config: {
      fullWidth: true,
    },
  },
};
