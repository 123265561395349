import React from 'react';
import clsx from 'classnames';
import { useTheme, Box } from '@mui/material';

import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';
import {
  normalizedAverageDataValue,
  normalizedFactorValue,
  factorValueStyle,
} from './range-graph-utils';

export const DetailedCFRangeGraph = ({
  min,
  max,
  factor,
  average,
  lineHeight = 5,
}) => {
  const classes = useClasses();
  const { config } = useTheme();

  const avgDataVal = React.useMemo(
    () => normalizedAverageDataValue(average, min, max),
    [average, min, max]
  );
  const factorDataVal = React.useMemo(
    () => normalizedFactorValue(factor, min, max),
    [factor, max, min]
  );
  const factorStyle = React.useMemo(
    () => factorValueStyle(average, factor, config),
    [average, config, factor]
  );

  return (
    <>
      <Box className={classes.graphContainer}>
        <div className={clsx(classes.rangeValue, classes.minRangeValue)}>
          {min}
        </div>
        <div className={clsx(classes.rangeValue, classes.maxRangeValue)}>
          {max}
        </div>
        <InnerLine lineHeight={lineHeight} className={classes.innerLine}>
          {average && (
            <div style={{ left: `${avgDataVal}%` }} className={classes.average}>
              <span className={classes.graphTriangle} />
              <div>{average}</div>
            </div>
          )}
          <div style={{ left: `${factorDataVal}%` }} className={classes.factor}>
            <div style={{ color: factorStyle.color }}>{factor}</div>
            <span
              className={classes.graphTriangle}
              style={{ borderTop: `10px solid ${factorStyle.color}` }}
            />
          </div>
        </InnerLine>
      </Box>
    </>
  );
};

export const DetailedCFRangeGraphLegend = ({ legendContainerProps }) => {
  const { config } = useTheme();
  const classes = useClasses();
  return (
    <Box className={classes.legendContainer} {...legendContainerProps}>
      <Box display="flex" alignItems="center">
        <div
          className={clsx(
            classes.legendRowSymbol,
            classes.minMaxLegendRowSymbol
          )}
        />
        <div>Min/Max observed for the given Industry</div>
      </Box>
      <Box display="flex" alignItems="center">
        <div className={classes.legendRowSymbol}>
          <span
            style={{ borderTop: `5px solid ${config.colors.cowbellAccent}` }}
            className={classes.legendTriangle}
          />
        </div>
        <div>Cowbell Factor ≥ Industry Avg</div>
      </Box>
      <Box display="flex" alignItems="center">
        <div className={classes.legendRowSymbol}>
          <span
            style={{ borderBottom: `5px solid ${config.colors.cowbellBlue}` }}
            className={classes.legendTriangle}
          />
        </div>
        <div>Industry Average</div>
      </Box>
      <Box display="flex" alignItems="center">
        <div className={classes.legendRowSymbol}>
          <span
            style={{ borderTop: `5px solid ${config.colors.tomato}` }}
            className={classes.legendTriangle}
          />
        </div>
        <div>Cowbell Factor {'<'} Industry Avg</div>
      </Box>
    </Box>
  );
};

export const DetailedNonLiveCFRangeGraph = ({ min, max, factor, average }) => {
  const classes = useClasses();
  const { config } = useTheme();

  const avgDataVal = React.useMemo(
    () => normalizedAverageDataValue(average, min, max),
    [average, min, max]
  );
  const factorDataVal = React.useMemo(
    () => normalizedFactorValue(factor, min, max),
    [factor, max, min]
  );

  return (
    <>
      <section className={classes.graphContainer}>
        <div className={clsx(classes.rangeValue, classes.minRangeValue)}>
          {min}
        </div>
        <div className={clsx(classes.rangeValue, classes.maxRangeValue)}>
          {max}
        </div>
        <div className={classes.innerLine}>
          {average && (
            <div
              style={{ left: `${avgDataVal}%` }}
              className={clsx(classes.average, classes.nonLiveAverage)}
            >
              <span className={classes.graphTriangle} />
              <div>{average}</div>
            </div>
          )}
          <div style={{ left: `${factorDataVal}%` }} className={classes.factor}>
            <div>{factor}</div>
            <span
              className={classes.graphTriangle}
              style={{ borderTop: `10px solid`, color: config.colors.grey6 }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export const DetailedNonLiveCFRangeGraphLegend = ({ legendContainerProps }) => {
  const { config } = useTheme();
  const classes = useClasses();
  return (
    <Box className={classes.legendContainer} {...legendContainerProps}>
      <Box display="flex" alignItems="center">
        <div
          className={classes.legendRowSymbol}
          style={{
            background: config.colors.grey2,
            padding: '2px 0',
            borderRadius: '1rem',
          }}
        />
        <div>Min/Max observed for the given Industry</div>
      </Box>
      <Box display="flex" alignItems="center">
        <div className={classes.legendRowSymbol}>
          <span
            style={{ borderBottom: `5px solid`, color: config.colors.grey2 }}
            className={classes.legendTriangle}
          />
        </div>
        <div>Industry Average</div>
      </Box>
      <Box display="flex" alignItems="center">
        <div className={classes.legendRowSymbol}>
          <span
            style={{ borderTop: `5px solid`, color: config.colors.grey6 }}
            className={classes.legendTriangle}
          />
        </div>
        <div>Organization Cowbell Factor</div>
      </Box>
    </Box>
  );
};

const InnerLine = styled('div')(({ lineHeight }) => {
  return {
    padding: `${lineHeight}px 0 !important`,
  };
});

const useClasses = makeStyles(({ config }) => ({
  graphContainer: {
    position: 'relative',
    paddingTop: '5rem',
    margin: '0 auto',
  },
  innerLine: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '75%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.5rem',
    background: config.colors.grey2,
    padding: '5px 0',
  },
  average: {
    position: 'absolute',
    top: '100%',
    textAlign: 'center',
    transform: 'translateX(-50%)',
    '& > span': {
      display: 'block',
      borderBottom: `10px solid ${config.colors.cowbellBlue}`,
    },
    '& > div': {
      color: config.colors.cowbellBlue,
    },
  },
  nonLiveAverage: {
    '& > span': {
      borderBottom: `10px solid ${config.colors.grey2} !important`,
    },
    '& > div': {
      color: `${config.colors.grey2} !important`,
    },
  },
  factor: {
    position: 'absolute',
    textAlign: 'center',
    bottom: '100%',
    transform: 'translateX(-50%)',
    '& > span': {
      display: 'block',
      top: '0',
    },
  },
  graphTriangle: {
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
  },
  legendContainer: {
    padding: '1rem',
    fontSize: '1rem',
    fontWeight: '500',
    '& > *': {
      gap: '1rem',
    },
  },
  legendRowSymbol: {
    width: '1.5rem',
    textAlign: 'center',
  },
  minMaxLegendRowSymbol: {
    background: config.colors.grey2,
    padding: '2px 0',
    borderRadius: '1rem',
  },
  legendTriangle: {
    display: 'inline-block',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
  },
  rangeValue: {
    position: 'absolute',
    top: '50%',
    color: config.colors.grey2,
  },
  minRangeValue: {
    left: `calc((100% - 75%) / 2 - 1.2rem)`,
    transform: 'translate(-50%, -50%)',
  },
  maxRangeValue: {
    right: `calc((100% - 75%) / 2 - 1.2rem)`,
    transform: 'translate(50%, -50%)',
  },
}));
