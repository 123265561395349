import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

export function CircularProgressWithLabel({ size = 100, ...props }) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress thickness={1} size={size} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          style={props.style}
        >
          {props.value}
        </Typography>
      </Box>
    </Box>
  );
}
