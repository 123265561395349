import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// helpers
import _ from 'lodash';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'classnames';
import Color from 'color';
// mui
import { Box, Divider, Grid } from '@mui/material';
import { styled, makeStyles, useTheme } from '@mui/styles';
// hocs
import useUploadZone from '../../../../../../components/fileUploader/v2/hooks/useUploadzone';
import { useFileTypeIcons } from '../../../../../../components/fileUploader/useFileTypeIcons';
import { withFormController } from '../../../../../../components/hocs/forms';
// components
import { TextFieldBase } from '../../../../../../components/inputs/TextFieldBase';
import CbButton from '../../../../../../components/Buttons/CbButton';
import { Uploadzone } from '../../../../../../components/fileUploader/v2/UploadZone';
import { Checkbox } from '../../../../../../components/inputs';
import { withShowable } from '../../../../../_global/lib/withShowable';
// utils
import {
  bullettedNKLLAttestationForAgencyRenewals,
  getAttestationLabelForAgencyRenewals,
  getFileDetailsToUpload,
  LinearUploadProgress,
} from '../../../../../renewals/renewal.utils';
import { downloadBlankP100NKLL } from '../../../../../../api/documents.api';
import { DocumentDownloader } from '../../../../../../components/fileUploader/DocumentDownloader';

const P100RenewalGenerateNKLL = ({ onUpload, data, ...props }) => {
  const classes = styles();
  const {
    files,
    handleUploads,
    isUploading,
    onRemoveFile,
    ...uploadZoneParams
  } = useUploadZone({
    docType: 'No_Known_Loss_Letter',
  });
  const theme = useTheme();

  const [isNKLLAcknowledged, setIsNKLLAcknowledged] = React.useState(false);

  const {
    onIssuePolicy,
    setDisable,
    disableAttestation,
    setIsNKLLInApp,
    onDelete,
  } = props;

  const fileDetails = getFileDetailsToUpload(files);
  const renderFileIcon = useFileTypeIcons(fileDetails.extension);

  const { handleSubmit, register, setValue, ...methods } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fileName: '',
    },
  });

  methods.watch('fileName');

  React.useEffect(() => {
    if (files && _.isFunction(onUpload)) {
      handleUploads({ asyncUploadFunc: onUpload });
    }
    setValue('fileName', fileDetails.fileName);
    // eslint-disable-next-line
  }, [files]);

  React.useEffect(() => {
    if (!files && !isNKLLAcknowledged && _.isFunction(setDisable)) {
      setDisable(true);
    }

    if (files && isNKLLAcknowledged && _.isFunction(setDisable)) {
      setDisable(false);
    }
    // eslint-disable-next-line
  }, [files, isNKLLAcknowledged]);

  const handleCheckbox = (event) => {
    setIsNKLLAcknowledged(event.target.checked);
    if (_.isFunction(setIsNKLLInApp)) {
      setIsNKLLInApp(false);
    }
  };

  const handleRemoveFile = (fileName) => {
    onRemoveFile(fileName);
    if (_.isFunction(onDelete)) {
      onDelete('No_Known_Loss_Letter');
    }
  };

  const onSubmit = () => {
    const payload = {
      files,
      isNKLLAcknowledged,
    };

    if (_.isFunction(onIssuePolicy)) {
      return onIssuePolicy(payload);
    }
  };

  return (
    <FormProvider {...methods}>
      <form id="UPLOAD_NKLL_FORM" onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={1}
          style={{ fontSize: theme.config.textSizes.tertia }}
          className={clsx('contrastText')}
        >
          <MuiGrid item xs={12}>
            <p>Since the effective date has passed,</p>
            <p>Please provide a No Known Loss Letter</p>
            <MuiDivider />
            <p className={classes.title}>Upload No Known Loss Letter</p>
          </MuiGrid>
          <Grid item xs={12}>
            <Uploadzone
              onRemoveFile={handleRemoveFile}
              files={files}
              previewProps={{ disabled: isUploading }}
              {...uploadZoneParams}
            />
          </Grid>

          <ShowableGrid item md={12} show={!files}>
            <Divider />
            <Box display="flex" justifyContent="center">
              <p className={clsx('contrastText', classes.content)}>
                <strong>No Known Loss Letter Template:</strong>{' '}
              </p>
            </Box>
          </ShowableGrid>
          <ShowableGrid item md={12} show={!files}>
            <Box display="flex" justifyContent="center">
              <DocumentDownloader
                type=".pdf"
                name="No Known Loss Letter"
                onDownload={downloadBlankP100NKLL}
              >
                {({ handleDocumentDownload }) => (
                  <CbButton
                    styleName="ctaButton"
                    onClick={handleDocumentDownload}
                  >
                    Download
                  </CbButton>
                )}
              </DocumentDownloader>
            </Box>
          </ShowableGrid>

          <MuiBox show={files && !isUploading}>
            <MuiDivider />
            <MuiGrid container>
              <BoldGrid md={5}>File Name:</BoldGrid>
              <Grid md={7}>
                <TextField
                  required
                  name="fileName"
                  defaultValue={fileDetails.fileName}
                />
              </Grid>
            </MuiGrid>

            <MuiGrid container>
              <BoldGrid md={5}>File Type:</BoldGrid>
              <Grid md={7}>{renderFileIcon}</Grid>
            </MuiGrid>

            <MuiGrid container>
              <BoldGrid md={5}>Doc Type:</BoldGrid>
              <Grid md={7}>NKLL</Grid>
            </MuiGrid>

            <MuiDivider />

            <MuiCheckbox
              checked={isNKLLAcknowledged}
              onChange={handleCheckbox}
              label={getAttestationLabelForAgencyRenewals()}
              disabled={disableAttestation}
            />
            {bullettedNKLLAttestationForAgencyRenewals()}
          </MuiBox>
        </Grid>

        <MuiBox show={isUploading}>
          <LinearUploadProgress />
        </MuiBox>
      </form>
    </FormProvider>
  );
};

const TextField = withFormController(TextFieldBase);
const ShowableCheckbox = withShowable(Checkbox);
const MuiBox = withShowable(Box);
const ShowableGrid = withShowable(Grid);

const styles = makeStyles(({ config }) => {
  return {
    title: {
      fontSize: `${config.textSizes.canon} !important`,
      fontWeight: 'normal !important',
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
    },
    content: {
      fontSize: '1.33rem',
      margin: '0.5rem 0 0',
    },
  };
});

const MuiCheckbox = styled(ShowableCheckbox)(({ theme: { palette } }) => ({
  '&.MuiCheckbox-root': {
    top: 0,
    left: 5,
    marginRight: 5,
    position: 'relative',
    color: palette.common.cowbellBlue,

    '& > *': {
      color: palette.common.cowbellBlue,
      marginLeft: 2,
    },
  },

  '&.Mui-disabled': {
    '& svg': {
      color: `${Color(palette.text.primary).fade(0.5).string()} !important`,
    },
  },

  '&.Mui-checked': {
    '& svg': {
      color: palette.common.cowbellBlue,
    },
  },
}));

const MuiDivider = styled(Divider)(() => ({
  margin: '1rem 0',
  width: '100%',
}));

const BoldGrid = styled(Grid)(({ theme: { palette, config } }) => ({
  fontSize: config.textSizes.primer,
  color: palette.primary.contrastText,
  fontWeight: config.weights.bold,
}));

const MuiGrid = styled(Grid)(({ theme: { config } }) => ({
  marginBottom: '1rem',
  '& p': {
    margin: 0,
    textAlign: 'center',
    fontSize: config.textSizes.primer,
    fontWeight: config.weights.bold,
  },
}));

const schema = Yup.object().shape({
  fileName: Yup.string().required().label('File Name'),
});

export default P100RenewalGenerateNKLL;
