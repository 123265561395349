import React from 'react';
import type { FieldValues } from 'react-hook-form';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';

//mui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';

//components
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import type { AxiosResponse } from 'axios';
import CbButton from '../../Buttons/CbButton';
import { InputLabelBase } from '../../inputs/InputLabelBase';
import { withFormController } from '../../hocs/forms';

import type { ProductType } from '../../../types';
import { ProductTypes } from '../../../types';
import { agencyLevelTableId } from '../../../console/agencies/quotes/PrimeX/agencyLevels/AgencyLevelsListing';
import DescTooltip from '../../DescTooltip';
import { manageAPIError, useGetPlatformRegion } from '../../../utils';

//types
import type { AddEditAgencyLevelPayload } from '../../../api';

import TextFieldBase from '../../inputs/text-fields/base/TextFieldBase';
import LanguageCurrencyFieldBase from '../../inputs/LanguageCurrencyField';
import { useCowbellTranslations } from '../../../i18n/translations';
import { languages } from '../../../i18n/i18n.language-config';

const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);
const Select = withFormController(MuiSelect);
const TextField = withFormController(TextFieldBase);

type RevenueBand = {
  minRevenue: string;
  maxRevenue: string;
  primaryProductType: string;
  secondaryProductType?: string;
};

interface AddEditAgencyLevelModalProps {
  data: {
    levelName?: string;
    revenueBands?: RevenueBand[];
    onSubmit: (payload: AddEditAgencyLevelPayload) => Promise<AxiosResponse>;
  };
  close: () => void;
}

const AddEditAgencyLevelModal = ({
  data: modalData,
  close,
}: AddEditAgencyLevelModalProps) => {
  const classes = useSelectClasses();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const region = useGetPlatformRegion();
  const ProductTypeOptions = PRODUCTS_BY_REGION[region];

  const { t, translationKeys } = useCowbellTranslations();

  type FormValues = {
    levelName: string;
    agencyLevelRevenueBands: {
      minRevenue: string;
      maxRevenue: string;
      primaryProductType: string;
      secondaryProductType?: string | null;
    }[];
  };

  const revenueBandFormMethods = useForm<FormValues>({
    resolver: yupResolver(deriveSchema(t(translationKeys.revenue))),
    defaultValues: {
      levelName: modalData.levelName,
      agencyLevelRevenueBands: modalData.revenueBands,
    },
  });

  const fieldArrayMethods = useFieldArray({
    control: revenueBandFormMethods.control,
    name: 'agencyLevelRevenueBands',
  });

  const handleAddRevenueBand = () => {
    fieldArrayMethods.append({
      minRevenue: '',
      maxRevenue: '',
      primaryProductType: '',
      secondaryProductType: null,
    });
  };

  const handleRemoveRevenueBand = (index: number) => {
    fieldArrayMethods.remove(index);
  };

  const scrollableRef = React.useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    if (fieldArrayMethods.fields) {
      scrollableRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [fieldArrayMethods.fields]);

  React.useEffect(() => {
    if (!modalData.revenueBands) {
      handleAddRevenueBand();
    }
  }, []);

  const onSubmit = async (formValues: FieldValues) => {
    const qualifiers = formValues.agencyLevelRevenueBands.map(
      (band: RevenueBand) => ({
        ...band,
        secondaryProductType:
          band.secondaryProductType === 'None'
            ? null
            : band.secondaryProductType,
      })
    );
    return modalData
      .onSubmit({
        levelName: formValues.levelName,
        qualifiers,
      })
      .then(() => {
        close();
        enqueueSnackbar('Agency level saved.', { variant: 'success' });
        queryClient.invalidateQueries([agencyLevelTableId]);
      })
      .catch((error) => {
        enqueueSnackbar(manageAPIError(error, 'Unable to save agency level'), {
          variant: 'error',
        });
      });
  };

  return (
    <>
      <FormProvider {...revenueBandFormMethods}>
        <form onSubmit={revenueBandFormMethods.handleSubmit(onSubmit)}>
          <DialogContent style={{ padding: '2rem 6rem', maxHeight: '35rem' }}>
            <Box className="contrast-text" fontSize="1.2rem" fontWeight={600}>
              Level Information
            </Box>
            <InputLabelBase required style={{ paddingTop: 0 }}>
              Level Name
            </InputLabelBase>
            <TextField name="levelName" />

            {fieldArrayMethods.fields.map((field, index) => {
              return (
                <React.Fragment key={field.id}>
                  <Box
                    className="contrast-text"
                    fontSize="1.2rem"
                    fontWeight={600}
                  >
                    {t(translationKeys.revenue)} Band {index + 1}{' '}
                    <DescTooltip
                      tooltip={`Min ${t(
                        translationKeys.revenue
                      )} (incl.) means if the ${t(
                        translationKeys.revenue
                      )} is greater than or equal to the provided value.`}
                    />
                  </Box>

                  <Box
                    key={field.id}
                    display="flex"
                    gap="1.5rem"
                    justifyContent="space-around"
                  >
                    <Box width="22.5%">
                      <InputLabelBase style={{ paddingTop: 0 }} required>
                        Min {t(translationKeys.revenue)} <i>(incl.)</i>{' '}
                      </InputLabelBase>

                      <LanguageCurrencyField
                        required
                        name={`agencyLevelRevenueBands.${index}.minRevenue`}
                      />
                    </Box>

                    <Box width="22.5%">
                      <InputLabelBase style={{ paddingTop: 0 }} required>
                        Max {t(translationKeys.revenue)}
                      </InputLabelBase>
                      <LanguageCurrencyField
                        required
                        name={`agencyLevelRevenueBands.${index}.maxRevenue`}
                      />
                    </Box>

                    <Box width="22.5%">
                      <InputLabelBase style={{ paddingTop: 0 }} required>
                        Primary Product
                      </InputLabelBase>
                      <Select
                        name={`agencyLevelRevenueBands.${index}.primaryProductType`}
                        classes={classes}
                      >
                        {renderOptions(ProductTypeOptions)}
                      </Select>
                      <ErrorMessage
                        name={`agencyLevelRevenueBands.${index}.primaryProductType`}
                        errors={revenueBandFormMethods.formState.errors}
                        render={({ message }) => (
                          <span style={{ color: '#fa8072' }}>{message}</span>
                        )}
                      />
                    </Box>

                    <Box width="22.5%">
                      <InputLabelBase style={{ paddingTop: 0 }}>
                        Secondary Product
                      </InputLabelBase>
                      <Select
                        name={`agencyLevelRevenueBands.${index}.secondaryProductType`}
                        classes={classes}
                      >
                        {renderOptions([
                          ...ProductTypeOptions,
                          { label: 'None', value: 'None' },
                        ])}
                      </Select>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      width="10%"
                    >
                      <Button
                        color="primary"
                        size="small"
                        variant="text"
                        onClick={() => handleRemoveRevenueBand(index)}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })}

            <div ref={scrollableRef} />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleAddRevenueBand}
              startIcon={<AddIcon style={{ pointerEvents: 'none' }} />}
              className="contrast-text"
              style={{ marginRight: 'auto' }}
            >
              Add {t(translationKeys.revenue)} Band
            </Button>

            <CbButton styleName="cancel" size="medium" onClick={close}>
              Cancel
            </CbButton>

            <CbButton
              styleName="ctaButton"
              size="medium"
              type="submit"
              loading={revenueBandFormMethods.formState.isSubmitting}
              disabled={revenueBandFormMethods.formState.isSubmitting}
            >
              Save
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    </>
  );
};

export const AddEditAgencyLevelModalConfig = {
  AddEditAgencyLevelModal: {
    component: AddEditAgencyLevelModal,
    config: {
      fullWidth: true,
      maxWidth: 'md',
    },
  },
};

const PRODUCTS_BY_REGION = {
  [languages['en-US']]: [
    { label: 'P100', value: ProductTypes.p100 },
    { label: 'P250', value: ProductTypes.p250 },
    { label: 'P100 Pro', value: ProductTypes.p100_pro },
  ],
  [languages['en-GB']]: [{ label: 'Prime One', value: ProductTypes.prime_one }],
};

const renderOptions = (
  options: { label: string; value: ProductType | 'None' }[]
) => {
  return options.map(({ label, value }) => {
    return (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    );
  });
};

//styles
const useSelectClasses = makeStyles(({ palette }) => ({
  select: {
    minWidth: '5.5rem',
    padding: '1rem',
    outline: 'none',

    '&.MuiInputBase-input': {
      backgroundColor: palette.background.active,
    },

    '&:focus': {
      backgroundColor: palette.background.active,
      borderRadius: 4,
    },
  },
}));

//schema
const RevenueBandRowSchema = (revenueTranslation: string) => {
  return {
    minRevenue: Yup.mixed()
      .transform((currentValue: string) => {
        if (currentValue.length === 0 || currentValue === '$') {
          return false;
        }
        return Number(currentValue.replace(/[^0-9.-]+/g, ''));
      })
      .test(
        'valid min revenue',
        `Min ${revenueTranslation} is invalid`,
        (value: number | boolean) => {
          if (value === false) return false;

          return +value >= 0;
        }
      ),
    maxRevenue: Yup.mixed()
      .transform((currentValue: string) => {
        if (currentValue.length === 0 || currentValue === '$') {
          return false;
        }
        return Number(currentValue.replace(/[^0-9.-]+/g, ''));
      })
      .test(
        'valid max revenue',
        `Max ${revenueTranslation} is invalid`,
        (value: number | boolean) => {
          if (value === false) return false;

          return +value >= 0;
        }
      ),
    primaryProductType: Yup.string().required('Product type is required'),
    secondaryProductType: Yup.mixed(),
  };
};

const deriveSchema = (revenueTranslation: string) =>
  Yup.object({
    levelName: Yup.string().required('Level name is required.'),
    agencyLevelRevenueBands: Yup.array().of(
      Yup.object().shape(RevenueBandRowSchema(revenueTranslation))
    ),
  });
