import Moment from 'moment';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Showable from '../../../components/Showable';
import {
  FORMAT_DATE_AND_TIME,
  toUniversalUtcDate,
} from '../../../utils/date.utils';

export const EndorsementHeader = ({
  effectiveDate,
  label,
  number,
  endDate,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <div className={classes.heading}>Midterm Endorsement</div>
      <Grid container spacing={0}>
        <Grid container spacing={1}>
          <Grid item md={6} style={{ textAlign: 'right' }}>
            Endorsement Start Date :
          </Grid>
          <Grid item md={6} style={{ textAlign: 'left' }}>
            <span>
              {toUniversalUtcDate(effectiveDate, {
                format: FORMAT_DATE_AND_TIME,
              })}
            </span>
          </Grid>
        </Grid>

        <Showable show={Boolean(endDate)}>
          <Grid container spacing={1}>
            <Grid item md={6} style={{ textAlign: 'right' }}>
              Endorsement End Date :
            </Grid>
            <Grid item md={6} style={{ textAlign: 'left' }}>
              <span>
                {toUniversalUtcDate(endDate, { format: FORMAT_DATE_AND_TIME })}
              </span>
            </Grid>
          </Grid>
        </Showable>
      </Grid>

      <Grid container spacing={1}>
        <Grid item md={6} style={{ textAlign: 'right' }}>
          {label} :
        </Grid>
        <Grid item md={6} style={{ textAlign: 'left' }}>
          <span>{number}</span>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  container: {
    textAlign: 'center',
    fontSize: '1.16rem',
    fontWeight: 600,
    lineHeight: 1.5,
    color: palette.primary.contrastText,
    '& span': {
      color: '#2180e2',
    },
  },
  heading: {
    textDecoration: 'underline',
    'text-underline-offset': '2px',
    textTransform: 'uppercase',
    paddingBottom: '2rem',
  },
}));
