import React from 'react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { DialogActions, Box, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { Autocomplete as MuiAutocomplete } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useFormContext } from 'react-hook-form';
import { AutoCompleteBase, SimpleSelect } from '../../components/inputs';
import { PhoneField as PhoneFieldBase } from '../../components/inputs/PhoneField';
import { withFormController } from '../../components/hocs/forms';
import { Flexbox } from '../../components/layout/Flexbox';
import { fetchAgencies, getAgencySegments } from '../AgencyService';
import { fetchBdsV2, fetchTamsV2 } from '../../api/UserService';
import Checkbox from '../../components/inputs/Checkbox';
import { fetchClusters } from '../../admin/cluster/cluster.service';
import { fetchDAs } from '../../admin/digital/da.service';
import {
  getAgencyLevels,
  getAutocompleteAccounts,
} from '../../accounts/AccountService';
import { getAgencyLabel } from '../../console/admin/users/UsersUtils';
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import Showable from '../../components/Showable';
import LanguageCurrencyFieldBase from '../../components/inputs/LanguageCurrencyField';
import { useCowbellTranslations } from '../../i18n/translations';
import { getAvailableCustomPresets } from '../../api/rater.api';
import { getIsUsPlatform, useGetPlatformRegion } from '../../utils';
import { COUNTRIES } from '../../i18n/i18n.language-config';

const SelectBase = withFormController(SimpleSelect);
export const PhoneField = withFormController(PhoneFieldBase);
export const LanguageCurrencyField = withFormController(
  LanguageCurrencyFieldBase
);

export const dialogPadding = { padding: '3rem 5rem' };

export const DialogFooter = ({ children, message }) => {
  return (
    <DialogActions>
      <Flexbox>
        <Typography variant="body1">{message}</Typography>
        <Box>{children}</Box>
      </Flexbox>
    </DialogActions>
  );
};

export const Select = (props) => {
  const classes = useSelectStyles();
  return (
    <Box style={{ position: 'relative', bottom: '1rem' }}>
      <SelectBase classes={classes} {...props} />
    </Box>
  );
};

const useSelectStyles = makeStyles(({ palette }) => ({
  root: { backgroundColor: palette.background.modal },
  disabled: { backgroundColor: palette.background.default },
}));

export const SectionTitle = styled('h3')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: theme.config.textSizes.greatPrimer,
}));

const Autocomplete = (props) => (
  <AutoCompleteBase
    autoHighlight
    getOptionLabel={getOptionLabel}
    error=""
    fullWidth
    {...props}
  />
);

export const AgencyNameAutocomplete = ({
  aggregateFormState,
  methods,
  ...props
}) => {
  const { updateAggregateFormData } = aggregateFormState;
  const region = useGetPlatformRegion();
  const isUSPlatform = getIsUsPlatform(region);

  const [input, setInput] = React.useState('');
  const [syncInput, setSyncInput] = React.useState('');
  const timeout = React.useRef();
  const params = isUSPlatform
    ? {
        search: input,
        isAgency: true,
      }
    : {
        search: input,
        isAgency: true,
        country: COUNTRIES.UK,
      };

  const { data: nadOptions = [] } = useQuery(
    ['agency-name-typeahead', input],
    () => {
      return getAutocompleteAccounts(params).then((resp) =>
        mapNADResultsToOptions(resp.data.content)
      );
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const agencyNameOptions = React.useMemo(() => {
    const manualOption = {
      label: syncInput,
      value: syncInput,
      data: {
        name: syncInput,
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        cbid: null,
        country: null,
        domainName: null,
        dunsNumber: null,
        phoneNumber: null,
      },
    };
    return _.isEmpty(nadOptions)
      ? [manualOption]
      : [...nadOptions, manualOption];
  }, [syncInput, nadOptions]);

  const onTypeahead = (e, value) => {
    clearTimeout(timeout.current);
    setSyncInput(value);
    timeout.current = setTimeout(() => {
      setInput(value);
    }, 300);
  };

  const onSelect = (e, option) => {
    updateAggregateFormData(option.data);
    const relevantFields = _.pick(option.data, [
      'name',
      'address1',
      'address2',
      'city',
      'state',
      'zipCode',
    ]);

    Object.keys(relevantFields).forEach((field) =>
      methods.setValue(field, relevantFields[field])
    );

    methods.setError('name', '');
  };

  return (
    <Autocomplete
      options={agencyNameOptions}
      onInputChange={onTypeahead}
      onChange={onSelect}
      style={{ marginBottom: '1rem' }}
      error={_.get(methods, 'formState.errors.name.message', '')}
      renderOption={(optionProps, option) => (
        <li {...optionProps}>
          <EnrichedOption option={option} />
        </li>
      )}
      disableClearable
      autoSelect
      {...props}
    />
  );
};

export const EnrichedOption = ({ option: { label, data = {} } }) => {
  const { address1, city, state } = data;
  return (
    <Box display="flex" flexDirection="column">
      <Typography style={{ fontWeight: 'bold' }}>{label}</Typography>
      <Typography>
        {address1} {city} {state}
      </Typography>
    </Box>
  );
};

const getOptionLabel = (option) => option?.label ?? '';

const mapNADResultsToOptions = (agencies = []) => {
  return agencies
    .filter((agency) => !!agency.name)
    .map((agency) => ({
      label: agency.name,
      value: agency.name,
      data: _.omit(agency, ['longitude', 'latitude', 'isAgency', 'complete']),
    }));
};

export const ParentAgencyAutocomplete = ({ aggregateFormState, ...props }) => {
  const [input, setInput] = React.useState('');
  const { t, translationKeys } = useCowbellTranslations();
  const {
    aggregateFormData: { meta },
    updateAggregateFormData,
  } = aggregateFormState;
  const region = useGetPlatformRegion();
  const isUSPlatform = getIsUsPlatform(region);

  const params = isUSPlatform
    ? {
        search: input,
        size: 100,
      }
    : {
        search: input,
        size: 100,
        country: COUNTRIES.UK,
      };

  const { data: agencyOptions = [] } = useQuery(
    ['parent-agency-typeahead', input],
    () => {
      return fetchAgencies(params).then((resp) => {
        const nullOption = {
          value: undefined,
          label: `No ${t(translationKeys.agency)}`,
        };
        return [nullOption, ...mapAgenciesToOptions(resp.data.content)];
      });
    }
  );

  const onTypeahead = _.debounce((e, value) => {
    setInput(value);
  }, 300);

  const onSelect = (e, option) => {
    updateAggregateFormData({
      parentAgencyId: option.value,
      parentAgencyName: option.label,
      meta: { ...meta, parentAgency: option.data },
    });
  };

  return (
    <Autocomplete
      options={agencyOptions}
      onInputChange={onTypeahead}
      onChange={onSelect}
      style={{ marginBottom: '1rem' }}
      {...props}
    />
  );
};

const mapAgenciesToOptions = (agencies = []) => {
  return agencies
    .filter((agency) => !!agency.name)
    .map((agency) => {
      const label = getAgencyLabel(agency);

      return {
        label,
        value: agency.id,
        data: agency,
      };
    });
};

export const TamAutocomplete = ({ aggregateFormState, ...props }) => {
  const {
    aggregateFormData: { meta, tamEmail },
    updateAggregateFormData,
  } = aggregateFormState;
  const [input, setInput] = React.useState('');

  const { data: tamOptions = [] } = useQuery(
    ['tam-options', input],
    () => {
      return fetchTamsV2({ search: input }).then((resp) =>
        mapTamsToOptions(resp.data.content)
      );
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  React.useEffect(() => {
    if (tamEmail) {
      updateAggregateFormData({ meta: _.omit(meta, 'tamError') });
    }
    // eslint-disable-next-line
  }, [tamEmail]);

  const onTypeahead = _.debounce((e, value) => {
    setInput(value);
  }, 300);

  const onSelect = (e, option) => {
    updateAggregateFormData({
      tamEmail: option.value,
    });
  };

  return (
    <Autocomplete
      options={tamOptions}
      onInputChange={onTypeahead}
      onChange={onSelect}
      style={{ marginBottom: '1rem' }}
      error={meta.tamError}
      inputProps={{
        autoComplete: 'off',
      }}
      {...props}
    />
  );
};

const mapTamsToOptions = (tams = []) => {
  return tams
    .filter((tam) => !!tam.email)
    .map((tam) => ({
      label: tam.email,
      value: tam.email,
      data: tam,
    }));
};

export const BdsAutocomplete = ({ aggregateFormState, ...props }) => {
  const {
    aggregateFormData: { meta, bdsEmail },
    updateAggregateFormData,
  } = aggregateFormState;
  const [input, setInput] = React.useState('');

  const { data: bdsOptions = [] } = useQuery(
    ['bds-options', input],
    () => {
      return fetchBdsV2({ search: input }).then((resp) =>
        mapTamsToOptions(resp.data.content)
      );
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  React.useEffect(() => {
    if (bdsEmail) {
      updateAggregateFormData({ meta: _.omit(meta, 'bdsError') });
    }
    // eslint-disable-next-line
  }, [bdsEmail]);

  const onTypeahead = _.debounce((e, value) => {
    setInput(value);
  }, 300);

  const onSelect = (e, option) => {
    updateAggregateFormData({
      bdsEmail: option.value,
    });
  };

  return (
    <Autocomplete
      options={bdsOptions}
      onInputChange={onTypeahead}
      onChange={onSelect}
      style={{ marginBottom: '1rem' }}
      error={meta.bdsError}
      {...props}
    />
  );
};

export const ClusterOrAggregatorAutocomplete = ({
  clusterOrAggregator,
  aggregateFormState,
  ...props
}) => {
  const {
    aggregateFormData: { meta },
    updateAggregateFormData,
  } = aggregateFormState;

  const [input, setInput] = React.useState('');

  const isCluster = clusterOrAggregator === 'CLUSTER';

  const fetchEntities = () => {
    return isCluster
      ? fetchClusters({ search: input })
      : fetchDAs({ search: input });
  };

  const { data: options } = useQuery(
    ['cluster-or-aggregator', clusterOrAggregator, input],
    () => {
      return fetchEntities().then((resp) =>
        mapAgenciesToOptions(resp.data.content)
      );
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!clusterOrAggregator,
    }
  );

  if (!clusterOrAggregator) {
    return null;
  }

  const onTypeahead = _.debounce((e, value) => {
    setInput(value);
  }, 300);

  const fieldName = isCluster ? 'clusterId' : 'aggregatorId';
  const onSelect = (e, option) => {
    updateAggregateFormData({
      [fieldName]: option.value,
      clusterOrAggregatorName: option.label,
    });
  };

  return (
    <Autocomplete
      name={fieldName}
      label={isCluster ? 'Select Cluster' : 'Select Aggregator'}
      options={options}
      onInputChange={onTypeahead}
      onChange={onSelect}
      style={{ marginBottom: '1rem' }}
      error={meta.clusterOrAggregatorError}
      {...props}
    />
  );
};

export const HardlinkCheckbox = ({ ...aggregateFormState }) => {
  const { aggregateFormData, updateAggregateFormData } = aggregateFormState;
  const isHardlinked = aggregateFormData.hardLinkOnQuote;

  const onChange = () => {
    updateAggregateFormData({ hardLinkOnQuote: !isHardlinked });
  };

  return (
    <Checkbox
      name="hardLinkOnQuote"
      label="Hardlink on Quote"
      checked={isHardlinked}
      onChange={onChange}
      style={{ margin: '0 0.5rem' }}
      color="secondary"
    />
  );
};

export const IsAppointedCheckbox = ({ ...aggregateFormState }) => {
  const { aggregateFormData, updateAggregateFormData } = aggregateFormState;
  const { isAppointed } = aggregateFormData;

  const onChange = () => {
    updateAggregateFormData({ isAppointed: !isAppointed });
  };

  return (
    <Checkbox
      name="isAppointed"
      label="Is Appointed?"
      checked={isAppointed}
      onChange={onChange}
      style={{ margin: '0 0.5rem' }}
      color="secondary"
    />
  );
};

export const WholesaleField = (props) => {
  const wholesaleOptions = [
    { label: 'Wholesale', value: true },
    { label: 'Retail', value: false },
  ];
  return <Select options={wholesaleOptions} {...props} />;
};

export const YesNoField = (props) => {
  const yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  return <Select options={yesNoOptions} {...props} />;
};

export const AgencyBillField = (props) => {
  const { translations } = useCowbellTranslations(['Agency']);
  const agencyBillOptions = [
    { label: 'Direct Billed', value: false },
    { label: `${translations.Agency} Billed`, value: true },
  ];
  return <Select options={agencyBillOptions} {...props} />;
};

export const ClusterOrAggregatorField = (props) => {
  const options = [
    { label: 'N/A', value: null },
    { label: 'Cluster', value: 'CLUSTER' },
    { label: 'Aggregator', value: 'AGGREGATOR' },
  ];
  return <Select options={options} {...props} />;
};

export const InheritanceMessage = ({ aggregateFormData }) => {
  const {
    meta: { inheritanceMode },
    parentAgencyName,
  } = aggregateFormData;

  const { translations } = useCowbellTranslations(['Agency']);

  if (!inheritanceMode) {
    return null;
  }

  return (
    <Typography
      variant="subtitle2"
      style={{ position: 'relative', bottom: '1.2rem' }}
    >
      Data inherited from Parent {translations.Agency}:{' '}
      <span style={{ fontWeight: 'bold' }}>{parentAgencyName}</span>
    </Typography>
  );
};

const standardQueryOptions = {
  keepPreviousData: true,
  refetchOnWindowFocus: false,
};

const mapStringsToOptions = (strings = []) => {
  return strings.map((string) => ({
    value: string,
    label: string.split('_').join(' '),
  }));
};

export const SegmentField = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: segmentOptions } = useQuery(
    ['segmentOptions'],
    () => {
      return getAgencySegments().then((resp) => {
        return mapStringsToOptions(Object.values(resp.data));
      });
    },
    {
      ...standardQueryOptions,
      onError: () => {
        enqueueSnackbar('There was a problem loading data, please try again.', {
          variant: 'error',
        });
      },
    }
  );
  return <Select options={segmentOptions} {...props} />;
};

export const LevelField = ({ muiAutocompleteProps = {}, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setValue, getValues, formState } = useFormContext();
  const levelSelected = getValues(props.name);

  const { data: levelOptions = [] } = useQuery(
    ['levelOptions'],
    () => {
      return getAgencyLevels().then((resp) => mapStringsToOptions(resp.data));
    },
    {
      ...standardQueryOptions,
      onError: () => {
        enqueueSnackbar('There was a problem loading data, please try again.', {
          variant: 'error',
        });
      },
    }
  );

  return (
    <>
      <Showable show={Boolean(props.label)}>
        <InputLabelBase indent required style={{ paddingTop: '0.3rem' }}>
          {props.label}
        </InputLabelBase>
      </Showable>
      <MuiAutocomplete
        options={levelOptions}
        getOptionLabel={getOptionLabel}
        onChange={(_event, { value }) => setValue(props.name, value)}
        defaultValue={{
          label: levelSelected?.split('_')?.join(' '),
          value: levelSelected,
        }}
        {...muiAutocompleteProps}
        renderInput={(params) => (
          <TextFieldBase
            placeholder="Select a level"
            name={props.name}
            error={formState.errors?.[props.name]?.message}
            {...params}
          />
        )}
      />
    </>
  );
};

export const useClusterOrAggregatorNullifier = ({
  clusterOrAggregator,
  aggregateFormState,
}) => {
  const {
    aggregateFormData: { meta, clusterId, aggregatorId },
    updateAggregateFormData,
  } = aggregateFormState;

  React.useEffect(() => {
    let payload = { meta: { ...meta, clusterOrAggregatorError: '' } };
    switch (clusterOrAggregator) {
      case 'CLUSTER':
        payload = { ...payload, aggregatorId: null };
        break;
      case 'AGGREGATOR':
        payload = { ...payload, clusterId: null };
        break;
      default:
        payload = { ...payload, clusterId: null, aggregatorId: null };
    }
    updateAggregateFormData(payload);
    // eslint-disable-next-line
  }, [clusterOrAggregator, clusterId, aggregatorId]);
};

export const DefaultCustomPresetSelector = (props) => {
  const options = [{ label: 'None', value: '' }];

  const { data: presetOptions = options } = useQuery(
    ['presetOptions', { agencyId: props.agencyId }],
    () => {
      return getAvailableCustomPresets({
        params: { agencyId: props.agencyId },
      }).then((resp) => {
        const availablePresetOptions = resp.data.map((preset) => {
          return {
            label: preset.name,
            value: preset.id,
          };
        });
        return [...options, ...availablePresetOptions];
      });
    },
    {
      retry: false,
      enabled: !!props.agencyId,
    }
  );

  return (
    <Select
      options={presetOptions}
      displayEmpty
      renderValue={(value) => {
        const option = presetOptions.find(({ value: v }) => v === value);
        return option?.label;
      }}
      {...props}
    />
  );
};
