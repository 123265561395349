import { ServiceHandlerFactory } from '../utils/serviceUtils';
import { Api } from '../config/axiosConfig';
import { agencyServiceMappings } from './agencyServiceMappings';
import { ServiceHandler } from '../utils';

export const fetchPagedAccessibleAgencies = ServiceHandlerFactory({
  axios: {
    url: 'api/auth/v1/admin/accessible',
    method: 'get',
  },
  isCancelable: true,
  paramSchema: agencyServiceMappings,
});

export const fetchPagedAccessibleSHF = ServiceHandlerFactory({
  axios: {
    url: 'api/auth/v1/admin/accessible',
    method: 'get',
  },
  isCancelable: true,
  paramSchema: [
    { path: 'page', transform: ({ page }) => page || 0 },
    { path: 'size', transform: ({ size }) => size || 100 },
  ],
});

export const fetchDAAgencies = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/agency/search',
    method: 'get',
  },
  isCancelable: true,
  paramSchema: agencyServiceMappings,
});

export const fetchAgencies = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/agency/search',
  },
  isCancelable: true,
  paramSchema: agencyServiceMappings,
});

export function markFavAgency(agencyId, flag) {
  return Api.put(`api/auth/v1/admin/agencies/${agencyId}/favorite/${flag}`);
}

export const fetchAgencyStats = ServiceHandlerFactory({
  axios: { url: 'api/account/v1/agency/stats/state' },
  paramSchema: agencyServiceMappings,
});

const uppercaseStateCode = (agency = {}) => {
  return { ...agency, state: agency.state?.toUpperCase() };
};

export function fetchAgencyDetails(agencyId) {
  return Api.get(`/api/account/v1/agency/${agencyId}`, {
    transformResponse: [JSON.parse, uppercaseStateCode],
  });
}

export function agencyDetails() {
  return Api.get(`/api/account/v1/agency`);
}

export function fetchAccount(accountId) {
  return Api.get(`/api/account/v1/account/${accountId}`);
}

export const fetchPagedProducers = ServiceHandlerFactory({
  axios: {
    url: 'api/auth/v1/users/producers',
  },
  isCancelable: true,
  paramSchema: agencyServiceMappings,
});

export const getAggregators = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/agency/daids',
  },
});

export function addNewAgency(agency) {
  return Api.post('/api/auth/v1/admin/agencies', agency);
}

export function updateAgency(accountId, contactDetails) {
  return Api.put(`api/auth/v1/admin/agencies/${accountId}`, contactDetails);
}

export function addAgentToAggregator(daId, agencyId) {
  return Api.put(`api/account/v1/da/agency/${daId}/${agencyId}`);
}
export function addAgentToCluster(id, agencyId) {
  return Api.put(`api/account/v1/cluster/agency/${id}/${agencyId}`);
}
export function updateAgent(agency) {
  return Api.put(`api/account/v1/account/contact`, agency);
}

export function getAgentInfo(agencyId) {
  return Api.get(`api/account/v1/agency/contact/${agencyId}`);
}

export function getApplicationLink(agencyId) {
  return Api.get(`api/account/v1/agency/contact/${agencyId}`);
}

export function requestAgencyAccess(agencyId) {
  return Api.put(`/api/auth/v1/admin/${agencyId}/request/access`);
}

export function refreshAgencyFirmographicData(accountId) {
  return Api.get(`/api/account/v1/agency/reload/${accountId}`);
}

export function filterStarredAgency(value) {
  return Api.get(`/api/account/v1/agency/search?fav=${value}`);
}

export const getAgencyDetails = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/agency`,
  },
  isCancelable: true,
});

export const setAgencyDetails = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/agency/profile',
    method: 'put',
  },
  isCancelable: true,
});

export const getRetailAgentLicense = ServiceHandlerFactory({
  axios: {
    url: `/api/agency/v1/agent/license/retail/search`,
  },
  paramSchema: agencyServiceMappings,
  isCancelable: true,
});

export const setRetailAgentLicense = ServiceHandlerFactory({
  axios: {
    url: '/api/agency/v1/agent/license/retail',
    method: 'put',
  },
  isCancelable: true,
});

export const getAgencySegments = ServiceHandler({
  url: '/api/agency/v1/segments',
});

export const getAgencyHierarchy = ServiceHandler({
  url: '/api/account/v1/agency/hierarchy',
});

export const getFullAgencyHierarchyAsFlatList = ServiceHandler({
  url: '/api/account/v1/agency/hierarchy/agencies',
});

export const getAgencyAccountById = ServiceHandler({
  url: '/api/account/v1/agency/:id',
});

export const getAgencyPrograms = ServiceHandler({
  url: '/api/account/v1/agency/programs/:agencyId',
});

export const postAgencyProgram = ServiceHandler({
  url: '/api/account/v1/agency/programs/:agencyId',
  method: 'POST',
});

export const putAgencyProgram = ServiceHandler({
  url: '/api/account/v1/agency/programs/:agencyId/:programId',
  method: 'PUT',
});

export const deleteAgencyProgram = ServiceHandler({
  url: '/api/account/v1/agency/programs/:agencyId/:programId',
  method: 'DELETE',
});
