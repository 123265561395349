import React from 'react';
// lodash
import _ from 'lodash';
// components
import { styled, Grid, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ControlledTypeAhead } from '../../../../components/inputs/autocomplete/ControlledTypeAhead';

import { InputLabelBase as Label } from '../../../../components/inputs/InputLabelBase';
import { withShowable } from '../../../_global/lib/withShowable';
import { TeamsLookupField, AgentLookupField } from './AccountLookupAdminFields';
import { useCowbellTranslations } from '../../../../i18n/translations';

export const AccountLookupUpdateAgentFields = ({
  agencySearchOptions,
  isAgencyAssigned,
  data,
  agent,
}) => {
  const theme = useTheme();
  const { translations } = useCowbellTranslations(['Agent', 'Agency']);

  const [agentInfo, setAgentInfo] = React.useState(agent);
  const { setValue, watch } = useFormContext();

  const selectedAgency = watch('agency');
  const selectedAgent = watch('agent');

  React.useEffect(() => {
    if (selectedAgency === '') {
      setValue('agency', { label: '', value: '' });
      setAgentInfo(agentFinder());
      setValue('agent', null);
      setValue('team', null);
    }
    // eslint-disable-next-line
  }, [selectedAgency]);

  const handleAgencySearch = React.useCallback(({ input }, callback) => {
    return callback(agencySearchOptions());
    // eslint-disable-next-line
  }, []);

  const agencyOnChange = React.useCallback(
    (nextAgency) => {
      setValue('agency', nextAgency);
      setAgentInfo(agentFinder(data.agencies, nextAgency.value));
      setValue('agent', null);
      setValue('team', null);
    },
    // eslint-disable-next-line
    [setValue]
  );

  if (!isAgencyAssigned) {
    return (
      <InputLabelBase style={{ fontSize: theme.config.textSizes.primer }}>
        Assign an agency to this account to update the agent!
      </InputLabelBase>
    );
  }

  return (
    <div>
      <InputLabelBase htmlFor="agency" required>
        {translations.Agency}
      </InputLabelBase>
      <ControlledTypeAhead
        name="agency"
        onFetch={handleAgencySearch}
        onChange={agencyOnChange}
        value={selectedAgency}
        defaultValue={selectedAgency}
        defaultOptions={[selectedAgency]}
        placeholder="Search for an agency by name"
        required
        fetchOnMount
      />

      <AgentTeamDisplay
        label={`Current ${translations.Agent}`}
        value={`${agentInfo.agent}`}
      />

      <InputLabelBase htmlFor="agent" required>
        Assign New {translations.Agent}
      </InputLabelBase>
      <AgentLookup selectedAgency={selectedAgency} />

      <AgentTeamDisplay
        label={`Current ${translations.Agent}'s Team`}
        value={`${agentInfo.agentTeam}`}
      />

      <InputLabelBase htmlFor="teams" required>
        Set New {translations.Agent}'s Teams
      </InputLabelBase>
      <TeamsLookup selectedAgent={selectedAgent} />
    </div>
  );
};

const AgentLookup = withShowable(AgentLookupField);
const TeamsLookup = withShowable(TeamsLookupField);
const InputLabelBase = withShowable(Label);
const ShowGrid = withShowable(Grid);

const AgentTeamDisplay = ({ label, value }) => {
  const theme = useTheme();

  return (
    <ShowGrid container style={{ margin: '1rem 0 -1rem 0' }}>
      <Grid item md={5}>
        <InputLabelBase style={{ fontSize: theme.config.textSizes.tertia }}>
          {label}:
        </InputLabelBase>
      </Grid>
      <Grid item md={7}>
        <Value>{value}</Value>
      </Grid>
    </ShowGrid>
  );
};

export const Value = styled('div')(
  ({ fontSize, padding = '1.5rem 0 0.5rem 0', theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: fontSize
      ? theme.config.textSizes[fontSize]
      : theme.config.textSizes.tertia,
    padding,
    fontWeight: theme.config.weights.bold,
  })
);

export function agentFinder(agencies, agencyId) {
  const selectedAgencyAssociatedAgent = _.isEmpty(agencies || agencyId)
    ? {}
    : agencies.find((agency) => agency.agencyId === agencyId);

  if (
    _.isEmpty(selectedAgencyAssociatedAgent) ||
    _.isEmpty(selectedAgencyAssociatedAgent.teamNames)
  ) {
    return {
      agent: 'No agent assigned',
      agentTeam: 'No team assigned',
    };
  }

  return {
    agent: getAgentLabel(selectedAgencyAssociatedAgent),
    agentTeam: selectedAgencyAssociatedAgent.teamNames[0],
  };
}

function getAgentLabel(agentInfo) {
  const { agentFirstName, agentLastName, agentEmail } = agentInfo;
  if ((agentFirstName === '' || agentLastName === '') && agentEmail !== '') {
    return agentEmail;
  }
  if (agentFirstName === '' && agentLastName === '' && agentEmail === '') {
    return 'No agent assigned';
  }
  return `${agentFirstName} ${agentLastName} (${agentEmail})`;
}
