import React from 'react';

const SVG = ({
  id = 'fileUploaderIcon',
  style = {},
  fill = '#f8f8f8',
  className = '',
  viewBox = '0 0 75 75',
  title = 'FileUploaderIcon',
}) => (
  <svg
    fill={fill}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .svg-fileUploaderIcon-1 { fill: none; }
          .${id}.svg-fill-fileUploaderIcon { fill: ${fill}; }
        `}
      </style>
    </defs>
    <title>{title}</title>
    <g>
      <path
        fill="#2180e2"
        d="M25 32.125h7.969V41.5h9.063v-9.375H50l-12.5-12.5z"
        transform="translate(-13478 -1884) translate(13478 1892.5)"
      />
      <path
        fill="none"
        d="M0 0h75v75H0z"
        transform="translate(-13478 -1884) translate(13478 1884)"
      />
      <path
        fill="#fff"
        d="M60.469 22.875a23.414 23.414 0 0 0-43.75-6.25A18.742 18.742 0 0 0 18.75 54h40.625a15.58 15.58 0 0 0 1.094-31.125zM59.375 47.75H18.75a12.491 12.491 0 0 1-1.375-24.906l3.344-.344 1.563-2.969a17.147 17.147 0 0 1 32.062 4.563l.938 4.687 4.781.344a9.323 9.323 0 0 1-.687 18.625z"
        transform="translate(-13478 -1884) translate(13478 1892.5)"
      />
    </g>
  </svg>
);

export default SVG;
