import { AdmittedIcon } from '../../../_assets/svg/formsLibrary/AdmittedIcon.svg';
import { SurplusIcon } from '../../../_assets/svg/formsLibrary/SurplusIcon.svg';
import { ExcessIcon } from '../../../_assets/svg/formsLibrary/ExcessIcon.svg';
import { ProductTypes } from '../../../types';
import { languages } from '../../../i18n/i18n.language-config';

export const PRODUCT_MAPPING = {
  P100: {
    label: 'Prime 100',
    value: 'P100',
    productIcon: {
      label: 'A',
      component: AdmittedIcon,
    },
  },
  P250: {
    label: 'Prime 250 [Admitted]',
    value: 'P250',
    productIcon: {
      label: 'A',
      component: AdmittedIcon,
    },
  },
  PRIME_CLOUD: {
    label: 'PrimeCloud [Surplus]',
    value: 'PRIME_CLOUD',
    productIcon: {
      label: 'S',
      component: SurplusIcon,
    },
  },
  P250_S: {
    label: 'Prime 250 [Surplus]',
    value: 'P250_S',
    productIcon: {
      label: 'S',
      component: SurplusIcon,
    },
  },
  P_PLUS: {
    label: 'Prime 250 [Excess]',
    value: 'P_PLUS',
    productIcon: {
      label: 'X',
      component: ExcessIcon,
    },
  },
  NotSupported: {
    label: 'Not Supported',
    value: 'NotSupported',
    productIcon: {
      label: '',
      component: () => null,
    },
  },
  [ProductTypes.prime_one]: {
    label: 'Prime One',
    value: ProductTypes.prime_one,
    region: languages['en-GB'],
    productIcon: {
      label: 'A',
      component: AdmittedIcon,
    },
  },
};

export const PAPER_MAPPING = {
  BENCHMARK: {
    label: 'Benchmark',
    value: 'BENCHMARK',
  },
  PALOMAR: {
    label: 'Palomar',
    value: 'PALOMAR',
  },
  OBSIDIAN: {
    label: 'Obsidian',
    value: 'OBSIDIAN',
  },
  NATIONAL_SPECIALTY: {
    label: 'National Specialty',
    value: 'NATIONAL_SPECIALTY',
  },
  SPINNAKER: {
    label: 'Spinnaker',
    value: 'SPINNAKER',
  },
  SKYWARD: {
    label: 'Skyward',
    value: 'SKYWARD',
  },
};
