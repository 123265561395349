import React from 'react';

import type { SelectProps } from '@mui/material';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import { styled } from '@mui/styles';
import * as Yup from 'yup';

import { useFormContext, type FieldValues } from 'react-hook-form';
import type { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { useSnackbar } from 'notistack';
import { withFormController } from '../../components/hocs/forms';
import TextFieldBase, {
  useInputLabelClasses,
} from '../../components/inputs/text-fields/base/TextFieldBase';
import type { Languages } from '../i18n.language-config';
import {
  COUNTRY_CODES_SELECT_OPTIONS,
  REGION_COUNTRY_CODE_MAPPING,
  languages,
} from '../i18n.language-config';
import { UsStates, UsStatesFull } from '../../utils/USState';
import { FormLanguageContext } from './FormLanguageProvider';
import {
  globalSchema,
  internationalValidations,
} from '../../components/globalValidations';
import UKPhoneFieldBase from '../../components/inputs/UkPhoneField';
import USPhoneFieldBase from '../../components/inputs/USPhoneField';
import type { ValidateAddressResponse } from '../../api';
import { validateAddress as validateAddressApi } from '../../api';
import Showable from '../../components/Showable';
import { PaperSnackbar } from '../../components/snackbars';
import { manageAPIError, useGetPlatformRegion } from '../../utils';
import { useActor } from '../../components/hooks/useActor';

const USPhoneField = withFormController(USPhoneFieldBase);
const UKPhoneField = withFormController(UKPhoneFieldBase);

const TextField = withFormController(TextFieldBase);
const MuiSelect = withFormController(
  styled(Select)(({ theme }) => ({
    backgroundColor: theme.palette.background.active,
  }))
);

type TextFieldProps = Omit<React.ComponentProps<typeof TextField>, 'name'>;

interface FormFieldProps extends TextFieldProps {
  name?: string;
  label?: string;
  suppressLabel?: boolean;
  countryCodeProps?: SelectProps;
}

const defaultOptions = {
  validatePhone: false,
  phoneFieldName: 'phoneNumber',
};

interface Options {
  validatePhone?: boolean;
  phoneFieldName?: string;
}

interface Params {
  language?: Languages;
  options?: Options;
}

export const useLanguageFormState = (params: Params | undefined = {}) => {
  const region = useGetPlatformRegion();
  const { language = region, options } = params;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const _options = { ...defaultOptions, ...options };
  const languageFormSchema = deriveFormSchema(language as Languages, _options);
  const gridLayoutSettings = deriveGridLayoutSettings(language as Languages);
  const actor = useActor();

  const validateAddress = async (formValues: FieldValues) => {
    if (language === languages['en-GB']) {
      const payload = {
        country: 'GBR',
        address1: formValues.address1,
        address2: formValues.address2,
        address3: formValues.address3,
        city: formValues.city,
        zipCode: formValues.zipCode,
      };

      await validateAddressApi({ data: payload })
        .then(async ({ data }) => {
          if (data?.errorFields?.length) {
            await getErrorSnackbar(
              data,
              enqueueSnackbar,
              closeSnackbar,
              actor.isCowbell
            );
          } else {
            return data;
          }
        })
        .catch((err) => {
          enqueueSnackbar(manageAPIError(err, 'Unable to verify address'), {
            variant: 'error',
          });
          throw new Error();
        });
    }

    return null;
  };

  return {
    language,
    languageFormSchema,
    gridLayoutSettings /* intended for use with grid layouts but can be used in other cases as well*/,
    validateAddress,
  };
};

const Address1 = (props: TextFieldProps) => {
  return <TextField name="address1" label="Address 1" required {...props} />;
};

const Address2 = (props: TextFieldProps) => {
  return <TextField name="address2" label="Address 2" {...props} />;
};

const Address3 = (props: TextFieldProps) => {
  const { language } = React.useContext(FormLanguageContext);

  if (language === languages['en-GB']) {
    return <TextField name="address3" label="Address 3" {...props} />;
  }

  return null;
};

const City = (props: TextFieldProps) => {
  const { language } = React.useContext(FormLanguageContext);
  const label = getCityLabel(language);

  return <TextField required name="city" label={label} {...props} />;
};

const State = ({
  showFullStates = false,
  ...props
}: { showFullStates?: boolean } & Omit<SelectProps, 'label'>) => {
  const { language } = React.useContext(FormLanguageContext);

  const states = showFullStates ? UsStatesFull : UsStates;

  if (language === languages['en-US']) {
    return (
      <MuiSelect
        name="state"
        input={<TextFieldBase required label="State" {...props} />}
        {...props}
      >
        {states.map((state) => {
          return (
            <MenuItem key={state.label} value={state.value}>
              {state.label}
            </MenuItem>
          );
        })}
      </MuiSelect>
    );
  }

  return null;
};

const PostalCode = (props: FormFieldProps) => {
  const { language } = React.useContext(FormLanguageContext);
  const { label } = getPostalCodeAttributes(language);

  return <TextField required label={label} name="zipCode" {...props} />;
};

const PhoneField = ({
  name = 'phoneNumber',
  label = 'Business Phone',
  suppressLabel = false,
  countryCodeProps,
  ...props
}: FormFieldProps) => {
  const { language } = React.useContext(FormLanguageContext);
  const formContext = useFormContext();
  const inputLabelClasses = useInputLabelClasses();
  const COUNTRY_CODE =
    REGION_COUNTRY_CODE_MAPPING[
      language as keyof typeof REGION_COUNTRY_CODE_MAPPING
    ];

  const countryCodeValue = formContext.watch(`${name}CountryCode`);

  React.useEffect(() => {
    if (!countryCodeValue) {
      formContext.setValue(`${name}CountryCode`, COUNTRY_CODE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PhoneInput =
    countryCodeValue === REGION_COUNTRY_CODE_MAPPING['en-GB']
      ? UKPhoneField
      : USPhoneField;

  return (
    <FormControl variant="standard" fullWidth>
      <Showable show={!suppressLabel}>
        <InputLabel
          required={props.required ?? true}
          htmlFor={name}
          classes={inputLabelClasses}
        >
          {label}
        </InputLabel>
      </Showable>
      <Box display="flex" gap="0.5rem" width="100%" mt={0.5}>
        <Select
          name={`${name}CountryCode`}
          disabled
          sx={{
            '& .MuiInput-input': {
              background: ({ palette }) => palette.background.active,
            },
          }}
          defaultValue={countryCodeValue ?? COUNTRY_CODE}
          value={countryCodeValue}
          onChange={(e) => {
            formContext.setValue(name, '');
            formContext.setValue(`${name}CountryCode`, e.target.value);
          }}
          renderValue={(selected) => {
            const selectedOption = COUNTRY_CODES_SELECT_OPTIONS.find(
              (option) => option.value === selected
            );
            return selectedOption?.value ?? '';
          }}
          {...countryCodeProps}
        >
          {COUNTRY_CODES_SELECT_OPTIONS.map((_countryCode) => {
            return (
              <MenuItem key={_countryCode.label} value={_countryCode.value}>
                {_countryCode.label}
              </MenuItem>
            );
          })}
        </Select>

        <PhoneInput
          placeholder={label}
          fullWidth
          name={name}
          errorMessage={false}
          {...props}
        />
      </Box>
      <FormHelperText style={{ marginTop: 0 }}>
        {formContext.formState.errors[name]?.message}
      </FormHelperText>
    </FormControl>
  );
};

export default {
  Address1,
  Address2,
  Address3,
  City,
  State,
  PostalCode,
  PhoneField,
};

const getCityLabel = (language: Languages | null) => {
  if (language === languages['en-GB']) {
    return 'Town/City';
  }

  return 'City';
};

const getPostalCodeAttributes = (language: Languages | null) => {
  if (language === languages['en-GB']) {
    return {
      label: 'Postcode',
    };
  }

  return {
    label: 'Zip Code',
  };
};

const deriveFormSchema = (language: Languages, options: Options) => {
  const phoneValidations = derivePhoneValidations(options);

  let schema = {};
  switch (language) {
    case languages['en-GB']:
      schema = {
        address1: Yup.string().trim().required().label('Address 1'),
        city: internationalValidations.city(language).required(),
        zipCode: globalSchema.postalCode,
      };
      break;
    case languages['en-US']:
      schema = {
        address1: Yup.string().trim().required().label('Address 1'),
        city: internationalValidations.city(language).required(),
        state: Yup.string().required().label('State'),
        zipCode: globalSchema.zipCode,
      };
      break;
    default:
      throw new Error(`Unknown language: ${language}`);
  }

  return {
    ...schema,
    ...phoneValidations,
  };
};

const derivePhoneValidations = (options: Options) => {
  if (!options.validatePhone) {
    return {};
  }
  return {
    [options.phoneFieldName as string]: Yup.string()
      .when(`${options.phoneFieldName}CountryCode`, {
        is: REGION_COUNTRY_CODE_MAPPING['en-GB'],
        then: globalSchema.ukPhone,
      })
      .when(`${options.phoneFieldName}CountryCode`, {
        is: REGION_COUNTRY_CODE_MAPPING['en-US'],
        then: globalSchema.usPhone,
      }),
  };
};

const deriveGridLayoutSettings = (language: Languages) => {
  /* This is intended for use with grid layouts. Any grid container will take up UI space regardless of whether it is empty. Some of thgese conditions may be unecessary now, but as we release to new countries any update here should affect every grid */
  return {
    showAddress1: true,
    showAddress2: true,
    showAddress3: language === languages['en-GB'],
    showCity: true,
    showState: language === languages['en-US'],
    showPostCode: true,
    showPhoneNumber: true,
  };
};

export const formatPhoneAsString = (
  language: Languages = 'en-US',
  phoneNumber?: string
) => {
  if (!phoneNumber) {
    return '-';
  }
  switch (language) {
    case languages['en-GB']:
      return formatPhoneAsUK(phoneNumber);
    default:
      return formatPhoneAsUS(phoneNumber);
  }
};

const formatPhoneAsUS = (phoneNumber: string) => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phoneNumber;
};

const formatPhoneAsUK = (phoneNumber: string) => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{5})(\d{6})$/);

  if (match) {
    return `+44 ${match[1]} ${match[2]}`;
  }

  return `+44 ${phoneNumber}`;
};

interface FormatAddressAsStringParams {
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  state?: string;
  zipCode: string;
  language: Languages;
}

export const formatAddressAsString = ({
  language,
  ...params
}: FormatAddressAsStringParams) => {
  const { address1, address2, address3, city, state, zipCode } = params;
  switch (language) {
    case 'en-GB':
      return `${address1}${address2 ? `, ${address2}` : ''}${
        address3 ? `, ${address3}` : ''
      }, ${city}, ${zipCode}`;
    default:
      return `${address1}${
        address2 ? `, ${address2}` : ''
      }, ${city}, ${state}, ${zipCode} `;
  }
};

const getErrorSnackbar = (
  data: ValidateAddressResponse,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey,
  closeSnackbar: (key?: SnackbarKey | undefined) => void,
  isCowbeller: boolean
) => {
  return new Promise((resolve, reject) => {
    if (!isCowbeller) {
      reject();
    }

    const handleConfirm = () => {
      closeSnackbar();
      return resolve(data);
    };

    const handleCancel = () => {
      closeSnackbar();
      reject();
    };

    enqueueSnackbar('Unable to verify address', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      persist: true,
      content: (key, message) => {
        return (
          <PaperSnackbar key={key} message={message} onClose={reject}>
            <strong>The following address fields could not be verified:</strong>
            <ul>
              {data.errorFields.map((errorField, index) => (
                <li key={index} style={{ textTransform: 'capitalize' }}>
                  {errorField.toLowerCase()}
                </li>
              ))}
            </ul>

            {isCowbeller ? (
              <Box>
                <Box pb={1}>
                  If the address entered is correct, please confirm by clicking
                  below.
                </Box>
                <Box display="flex" gap="0.5rem">
                  <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="outlined" onClick={handleConfirm}>
                    Confirm
                  </Button>
                </Box>
              </Box>
            ) : (
              <span>Please update these fields and try again.</span>
            )}
          </PaperSnackbar>
        );
      },
    });
  });
};
