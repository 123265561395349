import * as Ramda from 'ramda';

// helpers
import { hasUrl } from '../../utils/appUtils';

// components
import { ListItemBase } from '../../components/Menus/ListItemBase';
import { withShowable } from '../../console/_global/lib/withShowable';
import { ProtectedSubMenu } from '../../components/Menus/SubMenu';
import { ProtectedMenuItem as ProtectedMenuItemBase } from '../../console/agencies/inbox/table/ProtectedMenuItem';

// utils
import { ProductTypes } from '../../types';
import {
  CANCELLED_STATUS,
  RENEWED_STATUS,
  EXPIRED_STATUS,
} from '../../console/_statics/policy.statics';
import { usePersona } from '../../components/hooks/usePersona';
import { useAgencyPoliciesOptionHandlers } from '../../console/agency/logic/policies/agency-policies.logic';
import policiesService from '../../console/policies/policies.service';

const ProtectedMenuItem = withShowable(ProtectedMenuItemBase);
const ListItem = withShowable(ListItemBase);

// Todo: Component/file, and those related, needs to be refactored to use biz logic from our custom hook

const admin = hasUrl('/admin');
export const CommonPolicyOptions = ({ data, ...props }) => {
  const persona = usePersona();

  const {
    isCertificateAvailable,
    isDocumentAvailable,
    isPaid,
    isPremiumFinanced,
    agencyBilled,
    status,
    isPrimePlus,
  } = data;

  const isActive = ['ACTIVE', 'IN FORCE', 'ISSUED', 'BOUND'].includes(status);

  const canDownloadApplication =
    !isPrimePlus &&
    ![ProductTypes.primecloud, ProductTypes.prime_tech_plus].some(
      Ramda.equals(data.product)
    ) &&
    (isActive || [RENEWED_STATUS, CANCELLED_STATUS].includes(data.status));

  const canDownloadInvoice =
    persona.isInsured &&
    !agencyBilled &&
    (isActive ||
      data.status === RENEWED_STATUS ||
      [EXPIRED_STATUS, CANCELLED_STATUS, RENEWED_STATUS].includes(data.status));

  const canDownloadFinancingAgreement =
    isPremiumFinanced && (isActive || data.status === RENEWED_STATUS);

  const canDownloadPolicyDocuments =
    isDocumentAvailable &&
    (isActive || data.status === RENEWED_STATUS) &&
    !(data.isEndorsement && data.product == ProductTypes.p250);

  const canDownloadReceipt =
    isPaid && (isActive || data.status === RENEWED_STATUS);

  const canDownloadPolicyCertificate =
    ![ProductTypes.primecloud, ProductTypes.prime_tech_plus].some(
      Ramda.equals(data.product)
    ) && isActive;

  const isInvoiceAvailable =
    data.product === ProductTypes.prime_tech_plus ||
    data.product === ProductTypes.primecloud
      ? data.isInvoiceAvailable
      : data.isDocumentAvailable;

  const isReceiptAvailable =
    data.product === ProductTypes.primecloud
      ? data.isPaid
      : data.isDocumentAvailable;

  // functions
  const {
    handleDownloadDocs,
    handleDownloadReceipt,
    handleDownloadInvoice,
    handleDownloadPlan,
    handleDownloadApplication,
    downloadEmailCertificate,
    enterDeleteModal,
  } = useAgencyPoliciesOptionHandlers(data);

  return (
    <>
      <ProtectedMenuItem
        allow={{ policy: 'manage' }}
        show={!isActive && admin}
        onClick={enterDeleteModal}
        {...props}
      >
        Delete Policy
      </ProtectedMenuItem>
      <ProtectedSubMenu
        closeMenu
        header="Documents"
        allow={{ policy: 'view' }}
        show={
          isActive ||
          [RENEWED_STATUS, CANCELLED_STATUS, EXPIRED_STATUS].includes(
            data.status
          )
        }
        {...props}
      >
        <ListItem
          onClick={handleDownloadApplication}
          name="Download Application"
          show={canDownloadApplication}
        />

        <ListItem
          show={canDownloadFinancingAgreement}
          disabled={!isDocumentAvailable}
          onClick={handleDownloadPlan}
          name="Download Financing Agreement"
        />

        <ListItem
          disabled={
            !isInvoiceAvailable ||
            policiesService.isNonPremiumBearingEndorsement(data)
          }
          onClick={handleDownloadInvoice}
          name="Download Invoice"
          show={canDownloadInvoice}
        />

        <ListItem
          disabled={
            !isInvoiceAvailable ||
            policiesService.isNonPremiumBearingEndorsement(data)
          }
          onClick={handleDownloadInvoice}
          name="Download Invoice"
          show={!persona.isInsured}
        />

        <ListItem
          disabled={!isDocumentAvailable}
          onClick={handleDownloadDocs}
          name="Download Policy Documents"
          show={canDownloadPolicyDocuments}
        />

        <ListItem
          show={canDownloadReceipt}
          disabled={!isReceiptAvailable}
          onClick={handleDownloadReceipt}
          name="Download Receipt"
        />

        <ListItem
          disabled={!isCertificateAvailable}
          show={canDownloadPolicyCertificate}
          onClick={downloadEmailCertificate}
          name="Generate Policy Certificate"
        />
      </ProtectedSubMenu>
    </>
  );
};
