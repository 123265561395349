import React from 'react';
import { useSnackbar } from 'notistack';
import { PubSub } from '../../utils/eventUtils';

export const withUploadFeatureApplications = () => {
  return (Component) => {
    return (props) => {
      const { enqueueSnackbar } = useSnackbar();

      const onDrop = React.useCallback(
        (files) => {
          const wrapper = (message, severity) => {
            enqueueSnackbar(message, severity);
          };

          const { size } = files[0];
          const maxSize = 26214400; // 25 * 1024 * 1024
          if (size > maxSize) {
            wrapper('The file size is too big, 25MB max.', {
              variant: 'error',
            });
          } else {
            PubSub.publish(
              'withUploadFeatureApplications:selectedFile',
              files[0]
            );
          }
        },
        // eslint-disable-next-line
        []
      );

      return (
        <section name="withUploadFeatureDocuments">
          <Component {...props} onDrop={onDrop} />
        </section>
      );
    };
  };
};
