import React from 'react';
import _ from 'lodash';
import Moment from 'moment';

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

// platform helpers
import { delayedEvent } from '../../../../utils/eventUtils';
import { COWBELL_POLICY } from '../../../tables/table_constants';

// service
import { extendNocCancellationTriggerDate } from '../../../../api/NOCService';

// components
import { ExtendCancellation } from './extendCancellation/ExtendCancellation';
import { ExtendCancellationConfirmation } from './extendCancellation/ExtendCancellationConfirmation';

export const PauseNOCModal = ({ close, data }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = React.useState(1);
  const [payload, setPayload] = React.useState({});

  const handleExtendCancellation = (formData) => {
    setPage(2);
    setPayload(formData);
  };

  const onSubmit = (finalizedData) => {
    const policyId = _.get(data, 'id');

    const cancellationDatePayload = {
      cancellationTriggerDate:
        Moment.utc(_.get(finalizedData, 'newCancellationTriggerDate')).unix() *
        1000,
      productType: _.get(data, 'product'), // P100 OR P250
    };

    extendNocCancellationTriggerDate({ policyId }, cancellationDatePayload)
      .then(() => {
        queryClient.invalidateQueries([COWBELL_POLICY]);
        enqueueSnackbar('Notice of cancellation date has been extended.', {
          variant: 'success',
        });
        close();
      })
      .catch((error) => {
        const message = _.get(
          error.response,
          'data.message',
          _.get(
            error.response,
            'data',
            'Not able to extend Notice of cancellation due date.'
          )
        );
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  return (
    <div>
      {page === 1 && (
        <ExtendCancellation
          handleExtendCancellation={handleExtendCancellation}
          data={data}
          close={close}
        />
      )}
      {page === 2 && (
        <ExtendCancellationConfirmation
          submission={onSubmit}
          data={data}
          payload={payload}
          close={close}
        />
      )}
    </div>
  );
};

export const PauseNOCConfig = {
  PauseNOCModal: {
    component: PauseNOCModal,
    config: {
      fullWidth: true,
      maxWidth: 'md',
      minWidth: 'sm',
    },
  },
};
