// Routing
const cowbell = 'cowbell';
const agency = 'agency';
const policyholder = 'policyholder';
const da = 'da';
const cluster = 'cluster';
const carrier = 'carrier';

// Map for persona aliases to actual persona types
export const personaAliases = {
  cowbell,
  admin: cowbell,
  agency,
  customer: policyholder,
  policyholder,
  da,
  cluster,
  carrier,
} as const;
