import type { InputLabelBase } from '../../../../../components/inputs/InputLabelBase';

export type InputLabelProps = {
  inputLabelProps?: React.ComponentProps<typeof InputLabelBase>;
};

/* TYPES */
export type Question = {
  label: ({ inputLabelProps }: InputLabelProps) => JSX.Element;
  type: QuestionType;
  name: string;
  placeholder?: string;
  comments?: boolean;
  dynamic?: boolean;
  required: boolean;
  exclusive?: boolean;
  dynamicQuestions?: DynamicQuestion[];
  answers: Answer[];
  questionHeading?: string;
  questionGroupStyle?: { [key: string]: any };
  answerGroupStyle?: { [key: string]: any };
  topLevelQuestion?: ({ inputLabelProps }: InputLabelProps) => JSX.Element;
};

export type Answer = {
  label: string;
  value: number | boolean | string;
  tooltip?: string;
};

export type DynamicQuestion = {
  label: ({ inputLabelProps }: InputLabelProps) => JSX.Element;
  type: QuestionType;
  name: string;
  required?: boolean;
  dynamic?: boolean;
  placeholder?: string;
  comments?: boolean;
  show: { fieldName: string; is?: string; isNot?: string };
  answers: Answer[];
  questionGroupStyle?: { [key: string]: any };
  answerGroupStyle?: { [key: string]: any };
};

export const QuestionTypes = {
  boolAnswer: 'boolAnswer',
  text: 'text',
  singleSelect: 'singleSelect',
  multiSelect: 'multiSelect',
} as const;

export type QuestionType = (typeof QuestionTypes)[keyof typeof QuestionTypes];

/* COMMON STYLING */
export const commonQuestionGroupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const commonAnswerGroupStyles = {
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
};

export const mfaQuestionGroupStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '5rem',
  paddingLeft: '1.5rem',
};

export const encryptionQuestionGroupStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '4.5rem',
  paddingLeft: '1.5rem',
};

export const planningQuestionGroupStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '4.5rem',
  paddingLeft: '1.5rem',
};
