import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import type { WithChildrenProps } from '../../../../types/components/common';

export const ActionButtonText = ({ children }: WithChildrenProps) => {
  const theme = useTheme();

  return (
    <Typography
      variant="body2"
      style={{ color: theme.agencyDash.icon, fontWeight: 'bold' }}
      className="normal-text"
    >
      {children}
    </Typography>
  );
};
