export const useRHFError = (name, errors) => {
  let error;

  if (errors) {
    const errorObj = errors[name] || {};
    error = errorObj.isManual ? errorObj.types.message : errorObj.message;
  }

  return error;
};
