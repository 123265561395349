import React from 'react';
import Moment from 'moment';
import { Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MomentTz from 'moment-timezone';
import {
  FORMAT_DATE,
  FORMAT_DATE_AND_TIME,
  toUniversalDate,
} from '../utils/date.utils';

type SimpleDateDisplayProps = {
  date: number | Date | string;
  displayTime?: boolean;
  Wrapper?: React.FC<{ children: React.ReactNode }>;
};

function SimpleDateDisplay({
  date,
  displayTime = false,
  Wrapper = BaseWrapper,
}: SimpleDateDisplayProps) {
  const toolTipStyles = useTooltipStyles();

  const gmtDate = Moment(date).utc();
  const localDate = Moment(date);

  const tooltipContent = (
    <Box>
      <Box>
        <strong>
          {MomentTz.tz(
            Intl.DateTimeFormat().resolvedOptions().timeZone
          ).zoneAbbr()}
          :
        </strong>{' '}
        {toUniversalDate(localDate, {
          format: displayTime ? FORMAT_DATE_AND_TIME : FORMAT_DATE,
        })}
      </Box>
      <Box mb={0.3}>
        <strong>GMT:</strong>{' '}
        {toUniversalDate(gmtDate, {
          format: displayTime ? FORMAT_DATE_AND_TIME : FORMAT_DATE,
        })}
      </Box>
    </Box>
  );

  return (
    <Tooltip title={tooltipContent} classes={toolTipStyles}>
      <Wrapper>{toUniversalDate(gmtDate)}</Wrapper>
    </Tooltip>
  );
}

export default SimpleDateDisplay;

const BaseWrapper = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>((props, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      style={{ display: 'inline-block', ...props.style }}
    />
  );
});

const useTooltipStyles = makeStyles(({ palette }) => ({
  tooltip: {
    marginTop: 0,
    border: 'none',
    position: 'relative',
  },
}));
