// components
import { MenuItem as MuiMenuItem } from '@mui/material';

export const renderOptions = (option) => {
  if (typeof option === 'string') {
    return (
      <MuiMenuItem key={option} value={option}>
        {option.label}
      </MuiMenuItem>
    );
  }

  return (
    <MuiMenuItem key={option.label} value={option.value}>
      {option.label}
    </MuiMenuItem>
  );
};
