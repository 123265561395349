import { useQuery } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import type { ProductType } from '../../../../types';
import { ProductTypes } from '../../../../types';
import {
  getQuoteDetailsP250,
  getQuoteP100,
  getStepperQuoteDetails,
} from '../../../../api';
import {
  getFormattedQuoteData,
  getP100FormattedData,
  getP250FormattedData,
} from '../utils/bindQuoteWorkFlowUtils';
import { manageAPIError } from '../../../../utils';
import type { P100QuoteDetailsDto } from '../../../../types/quotes/details/p100-quote-details.dto';
import type { P250QuoteDetailsDto } from '../../../../types/quotes/details/p250-quote-details.dto';
import type { GetPrimeXQuoteDetailsResponse } from '../../../../api/quotes.api.types';
import { getRegionByProduct } from '../../../../i18n/utils';

interface UseGetQuoteDetailsParams {
  productType: ProductType;
  quoteId: string;
}

const useGetQuoteDetails = ({
  productType,
  quoteId,
}: UseGetQuoteDetailsParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const language = getRegionByProduct(productType);
  return useQuery<QuoteDetailsResponse, AxiosError>(
    ['quote-details', quoteId],
    () => {
      return getQuoteDetails(productType, quoteId).then(
        ({ data }: AxiosResponse) => {
          const formattedData = formatApiResponse(
            productType,
            data.data,
            language
          );
          return {
            ...data.data,
            uiData: formattedData,
          };
        }
      );
    },
    {
      enabled: Boolean(quoteId && productType),
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          manageAPIError(
            error,
            'There was a problem retrieving the quote details. Please try again later.'
          ),
          { variant: 'error' }
        );
      },
    }
  );
};

export default useGetQuoteDetails;

const getQuoteDetails = (productType: string, quoteId: string) => {
  if (productType === ProductTypes.p250) {
    return getQuoteDetailsP250(quoteId);
  }
  if (productType === ProductTypes.p100) {
    return getQuoteP100(quoteId);
  }

  return getStepperQuoteDetails(productType, quoteId);
};

const formatApiResponse = (
  productType: string,
  quoteData: P100QuoteDetailsDto | P250QuoteDetailsDto,
  language: string
) => {
  if (productType === ProductTypes.p250) {
    return getP250FormattedData(quoteData);
  }
  if (productType === ProductTypes.p100) {
    return getP100FormattedData(quoteData);
  }

  return getFormattedQuoteData(quoteData, language);
};

export type QuoteDetailsResponse = {
  uiData: UIQuoteDetails;
} & Partial<QuoteDetailsDto['data']>;

interface QuoteDetailsDto {
  data:
    | P100QuoteDetailsDto
    | P250QuoteDetailsDto
    | GetPrimeXQuoteDetailsResponse;
}

interface UIQuoteDetails {
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  customerPhone: string;
  companyName: string;
  agencyMailingAddress1: string;
  agencyMailingAddress2: string;
  agencyMailingCity: string;
  agencyMailingState: string;
  agencyMailingZipCode: string;
  surplusLicenseNumber: string;
  surplusLinesBrokerEmail: string;
  surplusLinesBrokerFirstName: string;
  surplusLinesBrokerLastName: string;
  surplusLinesState: string;
  effectiveDateOfPolicy: string;
  quoteNumber: string;
  quoteId: string;
  accountId: string;
  isSurplus: boolean;
  productType: ProductType;
  bindOrderDocumentInfo?: {
    docId: string;
    docSignedOn: string | null;
  };
}
