import React from 'react';
import { Plugin, Getter } from '@devexpress/dx-react-core';
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';

const handleDetailColumnLast = ({ tableColumns }) => {
  const detailColumnIndex = tableColumns.findIndex(
    (col) => col.type === TableRowDetail.COLUMN_TYPE
  );
  if (detailColumnIndex > -1) {
    const detailColumn = tableColumns.splice(detailColumnIndex, 1)[0];
    return [...tableColumns, detailColumn];
  }
  return tableColumns;
};

export const DetailColumnLast = () => (
  <Plugin dependencies={[{ name: 'TableRowDetail' }]}>
    <Getter name="tableColumns" computed={handleDetailColumnLast} />
  </Plugin>
);
