import numeral from 'numeral';
// at some point connect this to the store and pull in language setting options
numeral.register('locale', 'en-us', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal(number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '$',
  },
});

numeral.locale('en-us');
