// react
import React, { useState } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { useForm, FormContext } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';

// mui
import {
  Grid as MuiGrid,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';

// components
import { hostname as hostnameSchema } from '../../utils/validationSchemas/accounts.schema';
import { withFormController } from '../../components/hocs/withFormController';
import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { delayedEvent } from '../../utils/eventUtils';
import { getUrlHost } from '../../utils/appUtils';
import CbButton from '../../components/Buttons/CbButton';
import { HostnameField } from '../../components/inputs/HostnameField';

// actions
import { createCluster, updateCluster } from './cluster.service';

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  hostname: hostnameSchema,
  name: Yup.string().required().label('Name'),
});

export const ClusterCreateEdit = ({ data, close }) => {
  const edit = !_.isEmpty(data);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');
  const urlHost = getUrlHost();

  const {
    handleSubmit,
    formState: { isSubmitting, dirty },
    ...methods
  } = useForm({
    defaultValues: {
      name: data.name || '',
      description: data.description || '',
      hostname: data.hostname || '',
    },
    validationSchema: schema,
  });

  const onSubmit = (formData) => {
    const { name, description, hostname } = formData;
    const finalHostName = `${hostname.split('.')[0]}${urlHost}`;

    const payload = {
      name,
      description,
      hostname: edit ? hostname : finalHostName,
    };

    const api = edit
      ? updateCluster(
          { id: data.id },
          { name, description, hostname: finalHostName }
        )
      : createCluster({}, payload);

    api
      .then(() => {
        enqueueSnackbar(`Cluster Updated successfully!`, {
          variant: 'success',
        });
        delayedEvent('table-refetch', 1000, 'clusters');
        close();
      })
      .catch((error) => {
        const errorMessage = _.get(error, 'response.data');
        if (typeof errorMessage === 'string') {
          setError(errorMessage);
        } else {
          setError('Unable to update your data, please try again');
        }
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} data-qa="addCluser">
        <DialogContent
          style={{ paddingRight: 0, paddingBottom: 10, overflow: 'hidden' }}
        >
          <MuiGrid container spacing={2} style={{ marginTop: 20 }}>
            <MuiGrid item md={10} style={{ paddingTop: 0 }}>
              <TextField
                name="name"
                label="Name"
                required
                fullWidth
                inputProps={{ required: true }}
              />
            </MuiGrid>
            <MuiGrid item md={10} style={{ paddingTop: 0 }}>
              <TextField name="description" label="Description" fullWidth />
            </MuiGrid>
            <MuiGrid item md={10} style={{ paddingTop: 0 }}>
              <HostnameField name="hostname" defaultValue={data.hostname} />
            </MuiGrid>
          </MuiGrid>
        </DialogContent>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText={edit ? 'Update Cluster' : 'Add Cluster'}
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

export const ClusterCreateEditConfig = {
  ClusterCreateEdit: {
    component: ClusterCreateEdit,
    config: {
      fullWidth: true,
      maxWidth: 'xs',
    },
  },
};
