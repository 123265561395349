import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { DialogContent } from '@mui/material';
import { withStyles } from '@mui/styles';

import { deleteTag } from '../../../../accounts/AccountService';
import { PubSub } from '../../../../utils/eventUtils';
import { toggleModalDirect } from '../../../../utils/storeUtils';

const DeleteTag = ({ classes, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const sub = PubSub.subscribe('foot-button-one', (eventData) => {
      if (eventData) {
        onSubmit();
      }
    });

    return () => {
      sub.remove();
    };
  });

  const onSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    deleteTag({}, [data])
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        PubSub.publish('refresh-Tags', data);
        toggleModalDirect('DeleteTag', false);
        enqueueSnackbar('Tag Deleted Successfully!', { variant: 'success' });
      })
      .catch((error) => {
        console.error(error);
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
      });
  };

  return (
    <DialogContent className={classes.container}>
      <form onSubmit={onSubmit} id="submit-form">
        <div className="modal-title">
          Are you sure you want to delete this tag?
        </div>
      </form>
    </DialogContent>
  );
};

const styles = {
  container: {
    width: '30rem',
    height: '2rem',
    margin: 'auto',
    paddingBottom: '4rem',
  },
};

export default withStyles(styles)(DeleteTag);
