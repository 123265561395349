import React from 'react';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { stateFromMarkdown } from 'draft-js-import-markdown';

// css
// import '../../../../draft-example.css';
import '../../_assets/draft-rich-text.css';
import '../../_assets/draft.css';

// lodash
import _ from 'lodash';

// components
import { Editor, EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js';

import { Box, useTheme } from '@mui/material';

export const WysiwygBase = ({
  blockTypes = BLOCK_TYPES,
  inlineTypes = INLINE_STYLES,
  defaultValue = '',
  placeholder = 'Tell your story...',
  onChange,
  ...props
}) => {
  const [editorState, setEditorState] = React.useState(
    defaultValue
      ? EditorState.createWithContent(stateFromMarkdown(defaultValue))
      : EditorState.createEmpty()
  );

  const editor = React.useRef(null);

  const _onChange = React.useRef(
    _.debounce((nextEditorState) => {
      const markdown = stateToMarkdown(nextEditorState.getCurrentContent());
      onChange(markdown);
    }, 200)
  );

  function focusEditor() {
    editor.current.focus();
  }

  React.useEffect(() => {
    focusEditor();
  }, []);

  const handleChange = React.useCallback((nextEditorState) => {
    setEditorState(nextEditorState);

    if (typeof onChange === 'function') {
      _onChange.current(nextEditorState);
    }
    // eslint-disable-next-line
  }, []);

  const handleKeyCommand = React.useCallback(
    (command, currentEditorState) => {
      const newState = RichUtils.handleKeyCommand(currentEditorState, command);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      }
      return 'not-handled';
    },
    [setEditorState]
  );

  const mapKeyToEditorCommand = React.useCallback(
    (e) => {
      switch (e.keyCode) {
        case 9: {
          // TAB
          const newEditorState = RichUtils.onTab(
            e,
            editorState,
            4 /* maxDepth */
          );
          if (newEditorState !== editorState) {
            setEditorState(newEditorState);
          }
          return null;
        }
        default:
          return getDefaultKeyBinding(e);
      }
    },
    [editorState, setEditorState]
  );

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  const theme = useTheme();
  const themeType = theme.palette.mode;
  let className = `RichEditor-editor RichEditor-editor-${themeType}`;
  const contentState = editorState.getCurrentContent();
  // const markdown = stateToMarkdown(editorState.getCurrentContent()); // this is what turns it into markdown

  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  return (
    <Box className="RichEditor-root">
      <BlockStyleControls
        blockTypes={blockTypes}
        editorState={editorState}
        onToggle={(blockType) => {
          const newState = RichUtils.toggleBlockType(editorState, blockType);
          setEditorState(newState);

          if (typeof onChange === 'function') {
            _onChange.current(newState);
          }
        }}
      />
      <InlineStyleControls
        inlineTypes={inlineTypes}
        editorState={editorState}
        onToggle={(inlineStyle) => {
          const newState = RichUtils.toggleInlineStyle(
            editorState,
            inlineStyle
          );

          setEditorState(newState);

          if (typeof onChange === 'function') {
            _onChange.current(newState);
          }
        }}
      />
      <Box className={className} onClick={focusEditor}>
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={handleChange}
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          placeholder={placeholder}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          spellCheck
          {...props}
        />
      </Box>
    </Box>
  );
};

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Code Block', style: 'code-block' },
];

function StyleButton({ onToggle, active, label, style }) {
  const theme = useTheme();
  const themeType = theme.palette.mode;
  let className = `RichEditor-styleButton RichEditor-editor-${themeType}`;

  if (active) {
    className += ' RichEditor-activeButton';
  }

  return (
    <Box
      component="span"
      className={className}
      onMouseDown={(e) => {
        e.preventDefault();
        onToggle(style);
      }}
    >
      {label}
    </Box>
  );
}

const BlockStyleControls = ({ blockTypes, editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <Box className="RichEditor-controls">
      {blockTypes.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </Box>
  );
};

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
];

function InlineStyleControls({ inlineTypes, editorState, onToggle }) {
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {inlineTypes.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
}
