import { useMemo } from 'react';
import clsx from 'classnames';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// components
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  Typography as MuiTypography,
} from '@mui/material';

import CbButton from '../../../../components/Buttons/CbButton';
import { ControlledCheckBox } from '../../../../components/inputs/ControlledCheckBox';
import { MultiSelect } from '../../../../components/Selects';
import TextFieldBase from '../../../../components/inputs/text-fields/base/TextFieldBase';
import PhoneFieldBase from '../../../../components/inputs/text-fields/base/PhoneFieldBase';
import { ScrollableMultiSelect } from '../../../../components/Selects/MultiSelect/ScrollableMultiSelect';

import { withShowable } from '../../../../console/_global/lib/withShowable';
import { withFormController } from '../../../../components/hocs/forms';

// other
import { fetchPagedAccounts } from '../../../../accounts/AccountService';
import { reduceForSelect } from '../../../../console/admin/users/UsersUtils';

import { TOKEN_REALMS } from '../../../../console/_statics/users/roles.statics';
import { useCowbellTranslations } from '../../../../i18n/translations';
import LanguageForm, {
  useLanguageFormState,
} from '../../../../i18n/forms/LanguageForm';
import { FormLanguageProvider } from '../../../../i18n/forms/FormLanguageProvider';

const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);
const ScrollableMultiSelectField = withShowable(ScrollableMultiSelect);
const Typography = withShowable(MuiTypography);

export const AgencyUsersManageModalContent = ({
  accounts,
  roles,
  teams,
  totalAccounts,
  totalTeams,
  closeModal,
  user,
  onSave,
  getTeams,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t, translationKeys } = useCowbellTranslations();
  const languageFormState = useLanguageFormState({
    options: { validatePhone: true, phoneFieldName: 'phone' },
  });

  /* form */
  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      accounts: [],
      teams: [],
      primaryContact: false,
      billingContact: false,
      ...user,
    },
    resolver: yupResolver(
      deriveSchema(languageFormState.languageFormSchema.phone)
    ),
  });

  const { formState } = methods;

  methods.register('accounts');
  methods.register('teams');

  const selectedAccounts = methods.watch('accounts', user?.accounts || []);
  const selectedTeams = methods.watch('teams', user?.teams || []);

  const [role] = methods.watch(['role']);
  /* end form */

  /* render logic */
  const { adminRoleSelected, teamRealmRoleSelected } = useMemo(() => {
    const meta = role?.meta;

    return {
      adminRoleSelected: meta?.admin,
      teamRealmRoleSelected: meta?.teamRealm,
    };
  }, [role]);
  /* end render logic */

  /* handlers */
  async function onSubmit({ accounts: accountIds, role: roleId, ...values }) {
    if (typeof onSave === 'function') {
      const __meta__ = {
        realm: TOKEN_REALMS[role?.meta?.realm],
      };

      const nextPayload = {
        ...values,
        accountIds, // backend expects accounts as accountIds
        roleId, // backend expects role as roleId
        __meta__, // additional information needed to make descisions during edit
      };

      return onSave(nextPayload)
        .then(({ status }) => {
          if (status === 'FINISHED') {
            closeModal();
          }
        })
        .catch((error) => {
          const message =
            error?.response?.data?.message ||
            error?.response?.data ||
            'Failed to save user';

          enqueueSnackbar(message, { variant: 'error' });
        });
    }
  }

  function handleSelectChange(name, value) {
    methods.setValue(name, value);
  }
  /* end handlers */

  return (
    <FormLanguageProvider {...languageFormState}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className="paf">
            <Box width="65%" mb={4}>
              <Typography
                show={Boolean(!user.email)}
                className="normal-text"
                paragraph
                gutterBottom
              >
                After successfully adding this user we will send them an email
                to setup their password and log in for the first time.
              </Typography>
            </Box>
            <Grid container spacing={2} justifyContent="centerz">
              <Grid item xs={12} sm={6}>
                <TextField label="First name" name="firstName" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Last name" name="lastName" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="email"
                  disabled={Boolean(user.email)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LanguageForm.PhoneField
                  label="Contact Number"
                  name="phone"
                  required={false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label="NPN" name="npn" type="number" />
              </Grid>

              <Grid item xs={12}>
                <InputLabel htmlFor="role">Role</InputLabel>
                <Typography className="normal-text">
                  Determines what this user can see and do
                </Typography>
                <MultiSelect
                  name="role"
                  id="Role"
                  isMulti={false}
                  showErrorPlace={false}
                  options={roles}
                  values={role}
                  onChange={handleSelectChange}
                  buttonPlaceholder="Select a role"
                />
              </Grid>

              <Grid item xs={12}>
                <InputLabel
                  htmlFor="accounts"
                  className={clsx({ gutterBottom: !adminRoleSelected })}
                  disabled={adminRoleSelected}
                >
                  Accounts
                </InputLabel>
                <Typography
                  className="normal-text"
                  show={Boolean(role) && adminRoleSelected}
                >
                  Chosen role gives access to all accounts
                </Typography>
                <ScrollableMultiSelectField
                  name="accounts"
                  id="accounts"
                  showErrorPlace={false}
                  options={accounts}
                  values={selectedAccounts}
                  onChange={handleSelectChange}
                  buttonPlaceholder="Assign accounts"
                  reduceFunc={reduceForSelect}
                  totalPages={totalAccounts}
                  disabled={adminRoleSelected}
                  api={fetchPagedAccounts}
                />
              </Grid>

              <Grid item xs={12}>
                <InputLabel
                  htmlFor="teams"
                  className={clsx({
                    gutterBottom: !role || teamRealmRoleSelected,
                  })}
                  disabled={Boolean(role) && !teamRealmRoleSelected}
                >
                  Teams
                </InputLabel>
                <Typography
                  className="normal-text"
                  show={Boolean(role) && !teamRealmRoleSelected}
                >
                  Chosen role gives access to all teams
                </Typography>
                <ScrollableMultiSelectField
                  name="teams"
                  id="teams"
                  showErrorPlace={false}
                  options={teams}
                  values={selectedTeams}
                  onChange={handleSelectChange}
                  buttonPlaceholder="Assign teams"
                  reduceFunc={reduceForSelect}
                  totalPages={totalTeams}
                  disabled={Boolean(role) && !teamRealmRoleSelected}
                  api={getTeams}
                />
              </Grid>

              <Grid item xs={6}>
                <ControlledCheckBox
                  name="primaryContact"
                  label="Primary contact"
                />
                <Box width="100%">
                  <Typography className="normal-text">
                    By making this selection, this user will be considered the
                    primary contact for your{' '}
                    {t(translationKeys.agency).toLowerCase()}.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <ControlledCheckBox
                  name="billingContact"
                  label="Billing contact"
                />
                <Box width="100%">
                  <Typography className="normal-text">
                    By making this selection, this user will be considered a
                    billing contact for your{' '}
                    {t(translationKeys.agency).toLowerCase()}.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CbButton styleName="cancel" onClick={closeModal}>
              Cancel
            </CbButton>
            <CbButton
              type="submit"
              styleName="ctaButton"
              loading={formState.isSubmitting}
              disabled={formState.isSubmitting}
            >
              Save Details
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    </FormLanguageProvider>
  );
};

const deriveSchema = (phoneSchema) =>
  Yup.object().shape({
    firstName: Yup.string().label('First name').required(),
    lastName: Yup.string().label('Last name').required(),
    email: Yup.string().label('Email').email().required(),
    phone: phoneSchema,

    role: Yup.string()
      .label('Role')
      .transform((current, original) => {
        return typeof original === 'string' ? original : original.value;
      })
      .required(),

    accounts: Yup.array()
      .of(Yup.string())
      .transform((current, accounts) => {
        return accounts.map((account) => account.value);
      }),

    teams: Yup.array()
      .of(Yup.string())
      .transform((current, teams) => {
        return teams.map((team) => team.value);
      }),
  });
