import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
// mui
import {
  DialogContent,
  DialogActions,
  Grid,
  FormHelperText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// components
import { useSurplusContext } from '../../console/agencies/quotes/250/prime250.context';
import CbButton from '../../components/Buttons/CbButton';
// actions
import { sendSignRequest } from '../QuotesService';
// platform helpers
import { delayedEvent } from '../../utils/eventUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../reducers/UiSettingsReducers';

const styles = ({ config, palette }) => {
  return {
    header: {
      fontSize: config.textSizes.paragon,
    },
    subText: {
      fontSize: config.textSizes.primer,
      lineHeight: 1.38,
    },
    pagination: {
      fontSize: config.textSizes.tertia,
      color: palette.primary.contrastText,
      fontWeight: 'normal',
      paddingLeft: '3rem',
    },
  };
};

export const SendToSurplusBroker = withStyles(styles)(
  ({ classes, setPage, quoteId, setHandleCancel }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { bind, send, from } = useSurplusContext();
    const surplusData = from === 'bind' ? bind.surplus : send.surplus;
    const [error, setError] = React.useState('');

    const { handleSubmit, ...methods } = useForm();

    const { isSubmitting } = methods.formState;
    const handlePrevious = React.useCallback(() => {
      setPage(2);
      // eslint-disable-next-line
    }, []);

    const onSubmit = () => {
      const {
        surplusLinesBrokerFirstName,
        surplusLinesBrokerLastName,
        surplusLinesBrokerEmail,
        surplusLinesState,
        surplusLicenseNumber,
      } = surplusData;

      const payload = {
        brokerFirstName: surplusLinesBrokerFirstName,
        brokerLastName: surplusLinesBrokerLastName,
        brokerEmail: surplusLinesBrokerEmail,
        surplusLinesState: surplusLinesState.toLowerCase(), // lowe case abbv
        surplusLicenseNumber,
        quoteId,
      };

      return sendSignRequest({}, payload)
        .then(() => {
          if (typeof setHandleCancel === 'function') {
            setHandleCancel();
          }
          enqueueSnackbar('Quote Sent Successfully!', { variant: 'success' });
          delayedEvent('table-refetch', 500, AGENCY_QUOTES_LISTING_STORE_ID);
          delayedEvent('quote:read');
        })
        .catch((error) => {
          setError(
            _.get(
              error,
              'response.data.message',
              'Server Error, please try again!'
            )
          );
        });
    };

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            className="modal-title"
            style={{ padding: '0 5rem 1rem', textAlign: 'left' }}
          >
            <div className={classes.subText}>
              <p>
                A summary of the quote along with the Surplus Lines Notice will
                be sent to{' '}
                <strong>
                  {surplusData.surplusLinesBrokerFirstName}{' '}
                  {surplusData.surplusLinesBrokerLastName}
                </strong>{' '}
                at <strong>{surplusData.surplusLinesBrokerEmail}</strong>.
              </p>
              <p>
                Please note that for Surplus Lines Compliance, we will require
                the Surplus Lines Broker Information along with their signature
                to issue the policy.
              </p>
              <p>
                If you have any questions, please feel free to contact
                underwriting@cowbellcyber.ai
              </p>
            </div>
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Grid className={classes.pagination}>
                Step 3 of 3: Send to Surplus Broker
              </Grid>
              <Grid>
                <CbButton
                  styleName="cancel"
                  onClick={handlePrevious}
                  buttonText="Previous"
                />
                <CbButton
                  styleName="ctaButton"
                  onClick={onSubmit}
                  disabled={isSubmitting}
                  buttonText="Send"
                />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </>
    );
  }
);

export default SendToSurplusBroker;
