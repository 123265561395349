/* eslint-disable no-template-curly-in-string */
import _ from 'lodash';
import * as Yup from 'yup';
import React, { useCallback, useMemo, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  Grid as MuiGrid,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// components
import Highlight from 'react-highlighter';

import { SimpleSelect } from '../../../components/inputs/selects/SimpleSelect';
import { withFormController } from '../../../components/hocs/withFormController';
import { withShowable } from '../../_global/lib/withShowable';
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';

import { delayedEvent } from '../../../utils/eventUtils';
import {
  DefaultAccountOption,
  RegisteredAccountsAutoComplete,
} from '../../../components/accounts/autoCompleteAccounts';
import CbButton from '../../../components/Buttons/CbButton';
import { Modal } from '../../../components/modals/v2/helpers/v2.modal.helpers';

// services
import { createLead } from '../../salesInbox/sales.service';
import { fetchPagedAccounts } from '../../../accounts/AccountService';
import { fetchBds, fetchTams } from '../../../api/UserService';
import { leadSchema } from '../../../components/globalValidations';
import LanguageCurrencyFieldBase from '../../../components/inputs/LanguageCurrencyField';

const TextField = withFormController(TextFieldBase);
const Select = withFormController(SimpleSelect);
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);
const HiddenGrid = withShowable(Grid);

const schema = Yup.object().shape({
  name: Yup.string().label('Name').required(),
  effectiveDate: Yup.string().label('Effective Date').required(),
  targetPremium: Yup.number()
    .label('Target Premium')
    .positive('${label} must be greater than 0')
    .typeError('${label} is a required field')
    .required(),
  winRate: leadSchema.percentToWin,
  tam: Yup.string()
    .label('TSM')
    .typeError('${label} is a required field')
    .required(),
  status: Yup.string()
    .label('Status')
    .typeError('${label} is a required field')
    .required(),
});

const statList = [
  {
    label: 'Select a Lead Status...',
    value: null,
    disabled: true,
  },
  {
    label: 'New',
    value: 'NEW',
  },
  {
    label: 'Terms',
    value: 'TERMS',
  },
  {
    label: 'Presenting',
    value: 'PRESENTING',
  },
  {
    label: 'Released',
    value: 'RELEASED',
  },
  {
    label: 'Verbal Bind',
    value: 'VERBAL_BIND',
  },
  {
    label: 'Bound',
    value: 'BOUND',
  },
  {
    label: 'Won',
    value: 'WON',
  },
  {
    label: 'Lost',
    value: 'LOST',
  },
  {
    label: 'Declined',
    value: 'DECLINED',
  },
];
export const CreateOpportunityModal = withStyles({
  selectLabel: {
    paddingTop: '0.5rem',
  },
})(({ classes, close }) => {
  const [editMode] = useState(false);

  const [bds, setBds] = React.useState([]);
  const [tams, setTams] = React.useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    formState: { isSubmitting, dirty },
    ...methods
  } = useForm({ validationSchema: schema });

  const values = methods.getValues();

  React.useEffect(() => {
    methods.register({ name: 'effectiveDate', type: 'required' });
    methods.register({ name: 'bds', type: 'required' });
    methods.register({ name: 'tam', type: 'custom' });
    methods.register({ name: 'state', type: 'custom' });
    methods.register({ name: 'id', type: 'required' });

    methods.watch(['name']);

    Promise.all([fetchTams(), fetchBds()])
      .then(([tamsResp, bdsResp]) => {
        const tamsList = _.map(tamsResp.data.content, (value) => {
          return {
            label: `${value.firstName} ${value.lastName}`,
            value: value.email,
          };
        });

        const bdsList = _.map(bdsResp.data.content, (value) => ({
          label: `${value.firstName} ${value.lastName}`,
          value: value.email,
        }));

        setBds(bdsList);
        setTams(tamsList);
      })
      .catch(() => {
        enqueueSnackbar('Unable to download data', { variant: 'error' });
      });

    // eslint-disable-next-line
  }, []);

  // State utilized for handling backendErrors
  const [backendErrors] = useState('');

  const onSubmit = (formData) => {
    const payload = {
      accountName: formData.name,
      accountId: formData.id,
      targetDate: formData.effectiveDate,
      tams: [formData.tam],
      bizDevs: [formData.bds],
      summary: formData.summary || 'New Lead',
      status: formData.status || 'NEW',
      targetPremium: formData.targetPremium,
      winRate: formData.winRate,
    };

    createLead({}, payload)
      .then(() => {
        enqueueSnackbar('New lead succesfully created. Good Luck!', {
          variant: 'success',
        });
        makeRefreshCall();
        Modal.hide('CreateOpportunityModal');
      })
      .catch(() => {
        enqueueSnackbar(
          'Unable to create new lead, please double check that the selected account exists',
          {
            variant: 'error',
          }
        );
      });
  };

  const handleNameChange = useCallback(
    (selectedOption) => {
      methods.setValue('name', selectedOption.meta.name);
      methods.setValue('state', selectedOption.meta.state);
      methods.setValue('id', selectedOption.meta.id);
    },
    // eslint-disable-next-line
    []
  );

  const handleChange = ({ target }) => {
    const rawFields = ['targetPremium'];
    const { name, value, rawValue } = target;
    if (rawFields.includes(name)) {
      methods.setValue(name, rawValue);
    } else {
      methods.setValue(name, value);
    }
  };

  const bdsOptions = useMemo(
    () => [
      {
        label: 'Select a BDS..',
        value: null,
        disabled: true,
      },
      ...bds,
    ],
    [bds]
  );

  const tamOptions = useMemo(
    () => [
      {
        label: 'Select a TSM...',
        value: null,
        disabled: true,
      },
      ...tams,
    ],
    [tams]
  );

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} data-qa="addAgency">
        <DialogContent>
          <MuiGrid container spacing={3}>
            <HiddenGrid
              show={!editMode}
              style={{ paddingTop: '39px' }}
              item
              md={12}
            >
              <RegisteredAccountsAutoComplete
                id="name"
                name="name"
                label="Account Name"
                required
                onChange={handleNameChange}
                error={_.get(methods.errors, 'name.message')}
                endpoint={fetchPagedAccounts}
                renderOption={(optionProps, option, state) => (
                  <li {...optionProps}>
                    <DefaultAccountOption option={option} state={state}>
                      {(meta, styles) => (
                        <>
                          <p
                            className={styles.option}
                            style={{ margin: '0', fontWeight: 'bold' }}
                          >
                            <Highlight search={state.inputValue}>
                              {option.meta.name}
                            </Highlight>{' '}
                            ({meta.state})
                          </p>
                        </>
                      )}
                    </DefaultAccountOption>
                  </li>
                )}
              />
            </HiddenGrid>
          </MuiGrid>
          <MuiGrid container spacing={4}>
            <MuiGrid item md={6}>
              <Select
                name="tam"
                required
                label="TSM"
                displayEmpty
                options={tamOptions}
                defaultValue={null}
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <Select
                name="bds"
                label="BDS"
                displayEmpty
                options={bdsOptions}
                defaultValue={null}
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={4}>
            <MuiGrid item md={6}>
              <TextField
                name="effectiveDate"
                label="Target effective date"
                InputProps={{
                  inputProps: { min: moment().format('YYYY-MM-DD') },
                }}
                required
                type="date"
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <LanguageCurrencyField
                name="targetPremium"
                label="Target Premium ($)"
                onChange={handleChange}
                ref={methods.register({ name: 'targetPremium' })}
                error={
                  values.targetPremium !== 0 &&
                  _.get(methods.errors, 'targetPremium.message')
                }
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={4} style={{ marginTop: '-35px' }}>
            <MuiGrid item md={6}>
              <TextField
                required
                label="% to Win"
                name="winRate"
                type="number"
              />
            </MuiGrid>
            <MuiGrid item md={6} className="paddingBottomNone">
              <Select
                name="status"
                required
                label="Lead Status"
                InputLabelProps={{ className: classes.selectLabel }}
                displayEmpty
                options={statList}
                defaultValue={null}
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={4} style={{ marginTop: '-35px' }}>
            <MuiGrid item md={12}>
              <TextField
                rows={6}
                multiline
                name="summary"
                placeholder="Please enter any sales notes"
                label="Add a Note"
              />
            </MuiGrid>
          </MuiGrid>
          <FormHelperText className="api-text-error">
            {backendErrors}
          </FormHelperText>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Create Opportunity"
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
});

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, 'SalesInbox');
}

export const createOpportunityModalConfig = {
  CreateOpportunityModal: {
    component: CreateOpportunityModal,
    config: {
      /* any material ui configuration options  */
      fullWidth: true,
      minWidth: 'sm',
      maxWidth: 'md',
      title: 'Create New Opportunities',
    },
  },
};
