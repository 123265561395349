import {
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  Paper,
  Typography,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Close as CloseIcon } from '@mui/icons-material';
import CbButton from '../../../components/Buttons/CbButton';
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import { formatAsPhone } from '../../../utils';
import { getYesNoLabel } from '../../../utils/appUtils';
import Showable from '../../../components/Showable';

export const SummaryStep = ({
  selection,
  onSelectionChange,
  stepperProps,
  onCancel,
}) => {
  const agencyName = selection[0].account?.name;
  const teamName = selection[0].teams?.[0]?.name;

  return (
    <>
      <DialogContent style={{ padding: '3rem 5rem 2rem' }}>
        <DialogContentText style={{ marginBottom: '2rem' }}>
          Please review the user(s) information below and ensure all is correct
          before initiating the migration process.
        </DialogContentText>
        <UserCardsListing
          users={selection}
          selection={selection}
          onSelectionChange={onSelectionChange}
        />
        <TextFieldBase label="Current Agency" value={agencyName} disabled />
        <TextFieldBase
          label="Current Team"
          value={teamName ?? 'All'}
          disabled
        />
      </DialogContent>
      <DialogActions>
        <CbButton onClick={onCancel} styleName="cancel">
          Cancel
        </CbButton>
        <CbButton onClick={stepperProps.goForward} styleName="ctaButton">
          Next
        </CbButton>
      </DialogActions>
    </>
  );
};

const UserCardsListing = ({ users, selection, onSelectionChange }) => {
  return (
    <Box style={{ maxHeight: '20rem', overflow: 'auto', marginBottom: '2rem' }}>
      {users.map((user) => (
        <UserCard
          key={user.id}
          user={user}
          selection={selection}
          onSelectionChange={onSelectionChange}
        />
      ))}
    </Box>
  );
};

const UserCard = ({ user, selection, onSelectionChange }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box display="flex" style={{ position: 'relative' }}>
        <Showable show={selection.length > 1}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => onSelectionChange(user.id)}
          />
        </Showable>
        <Box display="flex" flexDirection="column" width="50%">
          <ListItem label="First Name" value={user.firstName} />
          <ListItem label="Last Name" value={user.lastName} />
          <ListItem label="Email" value={user.email} />
          <ListItem
            label="Phone Number"
            value={formatAsPhone(user.phone) ?? '-'}
          />
        </Box>

        <Divider flexItem orientation="vertical" style={{ margin: '0 1rem' }} />

        <Box display="flex" flexDirection="column" width="50%">
          <ListItem label="Role" value={user.role.name} />
          <ListItem label="NPN" value={user.npn} />
          <ListItem
            label="Primary Contact"
            value={getYesNoLabel(user.primaryContact)}
          />
          <ListItem
            label="Billing Contact"
            value={getYesNoLabel(user.billingContact)}
          />
        </Box>
      </Box>
    </Paper>
  );
};

const ListItem = ({ label, value }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography>{label}</Typography>
      <Typography color="primary">{value ?? '-'}</Typography>
    </Box>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  paper: {
    padding: '1.2rem',
    border: '1px solid grey',
    marginBottom: '1rem',
  },
  closeIcon: {
    position: 'absolute',
    top: -13,
    right: -13,
    '&:hover': { color: palette.error.light },
  },
}));
