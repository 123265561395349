import React from 'react';

const SVG = ({
  style = { width: 15 },
  fill = '#ffcc58',
  width = '100%',
  className = '',
  viewBox = '0 0 15 15',
}) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>.cls-2{`fill:${fill};`}</style>
    </defs>
    <path
      id="Path_2069"
      d="M6.95 4.75h1.1v1.1h-1.1zm0 2.2h1.1v3.3h-1.1zM7.5 2A5.5 5.5 0 1 0 13 7.5 5.5 5.5 0 0 0 7.5 2zm0 9.9a4.4 4.4 0 1 1 4.4-4.4 4.406 4.406 0 0 1-4.4 4.4z"
      className="cls-2"
      data-name="Path 2069"
    />
  </svg>
);

export default SVG;
