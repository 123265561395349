import moment from 'moment';
import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

//helpers
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

//mui
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@mui/material';
//components
import CbButton from '../../Buttons/CbButton';

import { manageAPIError } from '../../../utils';
//actions
import type { ClaimUpdateCloseDatePayload } from '../../../api';
import { claimUpdateCloseDate } from '../../../api';

//constants
import {
  COWBELL_CLAIMS,
  CLAIMS_INVOICES_AGGS,
} from '../../../console/claims/claims.constants';

import DateFieldBase from '../../inputs/text-fields/DateFieldBase';
import { withFormController } from '../../hocs/forms';

const DateField = withFormController(DateFieldBase);

type FormValues = Pick<ClaimUpdateCloseDatePayload, 'closeDate'>;

interface Props {
  close: () => void;
  data: {
    claimId: string;
    incidentDate: string;
    closeDate: string;
    claimNumber: string;
  };
}

export const UpdateClaimClosingDateModal = ({ data, close }: Props) => {
  const { incidentDate, claimNumber, claimId, closeDate } = data;

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { min, max } = useMemo(() => {
    return {
      min: moment.utc(incidentDate).add(1, 'day').format('YYYY-MM-DD'),
      max: moment.utc().format('YYYY-MM-DD'),
    };
  }, [data]);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      closeDate: closeDate
        ? moment.utc(closeDate).format('YYYY-MM-DD')
        : moment.utc().format('YYYY-MM-DD'),
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const onSubmit = (formValues: FormValues) => {
    return claimUpdateCloseDate({
      data: { claimId, closeDate: formValues.closeDate },
    })
      .then(() => {
        enqueueSnackbar(
          `Claim: ${claimNumber} close date updated successfully.`,
          { variant: 'success' }
        );

        queryClient.invalidateQueries([COWBELL_CLAIMS]);
        queryClient.invalidateQueries([CLAIMS_INVOICES_AGGS]);

        close();
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar(
          manageAPIError(
            error,
            'Could not update close date. Please try again later'
          ),
          { variant: 'error' }
        );
      });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2rem 5rem' }}>
          <Box mb={1} display="flex" justifyContent="center">
            <DialogContentText className="normal-text text-center">
              Manually override the closed date for claim number <br />
              <span className="contrast-text">{claimNumber}</span>
            </DialogContentText>
          </Box>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <DateField
                name="closeDate"
                required
                descriptionText="Closed date cannot be before incident date"
                inputProps={{
                  min,
                  max,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton onClick={close} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton type="submit" disabled={isSubmitting} styleName="ctaButton">
            Update
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const UpdateClaimClosingDateModalConfig = {
  UpdateClaimClosingDate: {
    component: UpdateClaimClosingDateModal,
    config: {
      fullWidth: true,
      title: 'Update Claim Close Date',
    },
  },
};
