import React from 'react';

// components
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { PROVIDER_AGENCY, PROVIDER_COWBELL } from './billing-compliance.consts';

export const ChangeBillingForm = ({ agencyName, provider, onChange }) => {
  function handleChange(event, nextValue) {
    if (typeof onChange === 'function') {
      onChange(nextValue);
    }
  }

  return (
    <FormControl variant="standard" component="fieldset">
      <FormLabel component="legend" className="contrast-text">
        Change Billing
      </FormLabel>
      <Typography>
        Choose who is responsible for the billing activities of the policy.
      </Typography>
      <RadioGroup
        name="billing-provider"
        value={provider}
        onChange={handleChange}
        style={{ marginTop: '0.833rem' }}
      >
        <FormControlLabel
          className="normalized-control-label"
          value={PROVIDER_COWBELL}
          control={<Radio color="secondary" />}
          label="Direct bill (Cowbell)"
        />
        <FormControlLabel
          className="normalized-control-label"
          value={PROVIDER_AGENCY}
          control={<Radio color="secondary" />}
          label={`Agency Bill: ${agencyName} (Agency on file)`}
        />
      </RadioGroup>
    </FormControl>
  );
};
