import React from 'react';

// mui
import { Box as MuiBox } from '@mui/material';

// components
import { useForm, FormProvider } from 'react-hook-form';
import CbButton from '../../../../components/Buttons/CbButton';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import CheckBoxLabel from '../CheckBoxLabel';
import { ScreenTitle } from './shared/ScreenTitle';
import { withShowable } from '../../../_global/lib/withShowable';

// utils
import { subjectivityKeys, techENOCheckboxLabel } from '../constants/constants';
import useCompleteQuoteSubjectivity from './shared/useCompleteQuoteSubjectivity';
import useGetSubjectivityProgress from './shared/useGetSubjectivityProgress';
import useDocUpload from './shared/useDocUpload';
import useUploadZone from '../../../../components/fileUploader/v2/hooks/useUploadzone';
import DocumentUploadBase from './shared/DocumentUploadBase';
import useGetSubjectivityDocs from './shared/useGetSubjectivityDocs';
import { SubjectivityCompletionDate as SubjectivityCompletionDateBase } from './shared/SubjectivityCompletionDate';
import { determineIfStepIsEditable } from '../utils/bindQuoteWorkFlowUtils';
import CowbellReferToUWButton from './shared/referral/CowbellReferToUWButton';

const Box = withShowable(MuiBox);
const SubjectivityCompletionDate = withShowable(SubjectivityCompletionDateBase);

const TechENOCoverageConfirmation = ({
  goForward,
  goBack,
  completeStep,
  step,
  quoteDetails,
  subjectivities,
  quoteProgress,
}) => {
  const {
    quoteSubjectivityId,
    completed: isStepCompleted,
    subjectivitySubType,
  } = subjectivities[subjectivityKeys.TECH_E_O_COV_CONFIRMATION];

  const [isTechENOAcknowledged, setIsTechENOAcknowledged] =
    React.useState(isStepCompleted);

  const isStepEditable = determineIfStepIsEditable({
    subjectivitySubType,
    status: quoteProgress.quoteAgencyStatus,
    isAPI: quoteProgress.isAPI,
    productType: quoteDetails.uiData.productType,
  });

  const { handleSubmit, setValue, ...methods } = useForm();

  const { completeSubjectivity, isCompletingStep } =
    useCompleteQuoteSubjectivity({
      quoteId: quoteDetails.uiData.quoteId,
      productType: quoteDetails.uiData.productType,
    });

  const { uploadMultipleDocuments, isUploadingDoc } = useDocUpload({
    quoteDetails,
  });

  const { files, ...uploadZoneParams } = useUploadZone({
    docType: 'Tech_EO_Policy',
    maxFiles: 4,
    multiple: true,
  });

  React.useEffect(() => {
    if (files) {
      setIsTechENOAcknowledged(false);
    }
  }, [files]);

  const {
    data: allSubjectivityInformation = {},
    isLoading: subjectivityDataLoading,
    isFetching: isFetchingSubjectivityData,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId,
    quoteId: quoteDetails.id,
    productType: quoteDetails.uiData.productType,
  });

  const { data: subjectivityData } = allSubjectivityInformation;

  const {
    data: uploadedDocuments,
    isInitialLoading: isUploadedDocsLoading,
    isFetching: isFetchingUploadedDocs,
  } = useGetSubjectivityDocs({
    quoteSubjectivityId,
    accountId: quoteDetails.uiData?.accountId,
    uploadedDocs: subjectivityData?.uploadedDocuments,
    isFetchingSubjectivityData,
  });

  const isLoadingSubjectivityData =
    subjectivityDataLoading ||
    isFetchingSubjectivityData ||
    isUploadedDocsLoading ||
    isFetchingUploadedDocs;

  if (isLoadingSubjectivityData) {
    return null;
  }

  const handleCheckboxChange = () => {
    setIsTechENOAcknowledged((prevState) => !prevState);
  };

  const onSubmit = (formValues) => {
    if (files) {
      const uploadDocumentParams = {
        formValues,
        files,
        quoteDetails,
        completeStep,
        goForward,
        step,
        docTypes: Array(files.length).fill('Tech_EO_Policy'),
        quoteSubjectivityId,
        userAttested: isTechENOAcknowledged,
      };

      return uploadMultipleDocuments(uploadDocumentParams);
    }

    if (isTechENOAcknowledged) {
      const completeSubjectivityParams = {
        quoteSubjectivityId,
        goForward,
        payload: {
          userAttested: true,
        },
      };
      completeSubjectivity(completeSubjectivityParams);
    } else {
      goForward();
    }
  };

  return (
    <Box className="contrast-text" width="50%">
      <ScreenTitle
        subjectivitySubType={subjectivitySubType}
        title={allSubjectivityInformation.subjectivityTitle}
      />

      <h3>{allSubjectivityInformation.subjectivityDescription ?? ''}</h3>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box show={isStepEditable || !!subjectivityData?.uploadedDocuments}>
            <p style={{ fontSize: '1.2rem' }}>
              Optional: If there is any additional documentation that validates
              or answers this request, please upload the document(s).
            </p>
            <DocumentUploadBase
              uploadZoneParams={uploadZoneParams}
              files={files}
              isUploadingDoc={isUploadingDoc}
              isStepEditable={isStepEditable}
              uploadedDocs={uploadedDocuments}
              docType="Tech E&O Confirmation"
            />
          </Box>

          <Box paddingTop="1rem">
            <CheckBoxBase
              name="techENOAcknowledgement"
              label={<CheckBoxLabel label={techENOCheckboxLabel} />}
              onChange={handleCheckboxChange}
              checked={isTechENOAcknowledged}
              disabled={isStepCompleted && !files}
            />
          </Box>

          <SubjectivityCompletionDate
            allSubjectivityInformation={allSubjectivityInformation}
            show={isStepCompleted}
          />

          <Box paddingTop="2rem">
            <CbButton styleName="cancel" size="medium" onClick={goBack}>
              Back
            </CbButton>

            <CbButton
              styleName="ctaButton"
              size="medium"
              type="submit"
              disabled={
                isCompletingStep ||
                isUploadingDoc ||
                (files && !isTechENOAcknowledged)
              }
              loading={isCompletingStep || isUploadingDoc}
              style={{ marginRight: '0.833rem' }}
            >
              Next
            </CbButton>

            <CowbellReferToUWButton
              quoteId={quoteDetails.id}
              subjectivityId={quoteSubjectivityId}
              productType={quoteDetails.uiData.productType}
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default TechENOCoverageConfirmation;
