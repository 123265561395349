import Numeral from 'numeral';
import { PrimeXProductTypes, ProductTypes } from '../types';
import { getRegionByProduct } from '../i18n/utils';
import { deriveCurrencyFormat } from '../i18n/currencies';

export const stringsToBools = (
  object,
  TRUE_STRING = 'yes',
  FALSE_STRING = 'no'
) => {
  return Object.keys(object).reduce((a, key) => {
    let value = object[key];

    if (value === TRUE_STRING) {
      value = true;
    }

    if (value === FALSE_STRING) {
      value = false;
    }

    return { ...a, [key]: value };
  }, {});
};

export const _isOneOf = (value, options) => {
  for (let i = 0; i < options.length; i += 1) {
    const option = options[i];

    if (value === option) {
      return true;
    }
  }

  return false;
};

/**
 * Pretty Id
 * @param charProfile - 36 encompasses full alpha numeric character set (e.g. 10 would only be numbers)
 * @param prefix
 * @param suffix
 */
export const prettyId = (charProfile = 36, prefix = '', suffix = '') =>
  `${prefix}${Math.random().toString(charProfile).slice(2)}${suffix}`;

export const prepColumnFilters = (columns) =>
  columns.reduce(
    (acc, column) =>
      typeof column.filterType === 'string'
        ? {
            ...acc,
            [column.name.replace('.', '-')]: {
              text: column.label || column.title,
              type: column.filterType,
              getListOptions: column.getListOptions,
            },
          }
        : acc,
    {}
  );

export const getFee = (row) => {
  const isPrimeXProduct = Object.values(PrimeXProductTypes).includes(
    row.product
  );
  let { brokerFee, mgaFee, additionalBrokerFee } = row;

  // Default to 0 if undefined
  additionalBrokerFee = additionalBrokerFee || 0;
  mgaFee = mgaFee || 0;
  brokerFee = brokerFee || 0;

  let totalFee = 0;

  if (row.product === ProductTypes.p100) {
    totalFee = brokerFee;
  } else if (row.product === ProductTypes.p250 || isPrimeXProduct) {
    totalFee = mgaFee;
  }

  totalFee += additionalBrokerFee;
  const language = getRegionByProduct(row.product);
  return totalFee ? deriveCurrencyFormat(language, totalFee) : '-';
};
