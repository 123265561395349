import _ from 'lodash';
import React, { useMemo, useEffect, useCallback } from 'react';

import { AutoCompleteBase } from './AutoCompleteBase';

/**
 * @name TypeAheadBase
 * @ComponentType controlled
 * @description Get results while typing, this component is requires you to control it, it does no state-management for you.
 * @param id {string} (required) The id passed down to the TextField component
 * @param inputValue {string} (required) The value of the input as it's typed
 * @param onChange {function} (required) Function called when user selects an option
 * @param onFetch {function} (required) Function called when the user searches for a value
 * @param onInputChange {function} (required) Function called when the user types in the search box
 * @param onOptionsChange {function} (required) Function called with the results from onFetch
 * @param value {string} (required) The value returned from onChange
 * @param wait {number} The throttle wait time
 */
export const TypeAheadBase = ({
  fetchOnMount = false,
  fetchOnFocus = false,
  inputValue,
  onChange,
  onFetch,
  onInputChange,
  onOptionsChange,
  value,
  wait = 200,
  ...props
}) => {
  const initialRender = React.useRef(true);

  // throttle fetching while typing
  const fetch = useMemo(
    () =>
      _.throttle((request, callback) => {
        onFetch(request, callback);
      }, wait),
    [onFetch, wait]
  );

  // check props
  useEffect(() => {
    if (typeof onOptionsChange !== 'function') {
      throw new Error('Prop "onOptionsChange" is required.');
    }

    if (typeof onInputChange !== 'function') {
      throw new Error('Prop "onInputChange" is required.');
    }

    if (typeof onChange !== 'function') {
      throw new Error('Prop "onChange" is required.');
    }

    if (typeof onFetch !== 'function') {
      throw new Error('Prop "onFetch" is required.');
    }
    // eslint-disable-next-line
  }, []);

  // manage options & value changes
  useEffect(() => {
    let active = true;

    if (fetchOnMount && initialRender.current) {
      fetch({ input: '' }, (results) => {
        onOptionsChange(results);
      });
    }

    if (!initialRender.current) {
      if (inputValue === '') {
        onOptionsChange(value ? [value] : []);
      }

      fetch({ input: inputValue }, (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            if (!inputValue) {
              newOptions = [];
            } else {
              newOptions = [value];
            }
          }

          if (results) {
            newOptions = results;
          }

          onOptionsChange(newOptions);
        }
      });
    } else {
      initialRender.current = false;
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [inputValue, value]);

  const handleChange = useCallback(
    (event, newValue) => {
      if (typeof onChange === 'function') {
        onChange(newValue);
      }
    },
    [onChange]
  );

  const handleInputChange = useCallback(
    (event, newInputValue) => {
      if (typeof onInputChange === 'function') {
        onInputChange(newInputValue);
      }
    },
    [onInputChange]
  );

  const onFocus = useCallback(() => {
    return fetchOnFocus
      ? fetch({ input: inputValue || '' }, (results) =>
          onOptionsChange(results || [])
        )
      : null;
  }, [fetch, fetchOnFocus, inputValue, onOptionsChange]);

  return (
    <AutoCompleteBase
      autoComplete
      filterSelectedOptions
      includeInputInList
      value={value}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionLabel={getOptionLabel}
      onFocus={onFocus}
      {...props}
    />
  );
};

const getOptionLabel = (option) => {
  if (typeof option === 'string') {
    return option;
  }

  if (typeof option === 'object') {
    if (option.label == undefined) {
      return '';
    }

    return option.label;
  }

  return '';
};
