//core
import React from 'react';
import * as Yup from 'yup';
import type { FieldValues } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

//MUI
import { styled } from '@mui/styles';
import { Box, FormHelperText, Typography } from '@mui/material';
import MuiToggleButton from '@mui/lab/ToggleButton';

//components
import type { StepperScreenProps } from '../StepperScreen';
import type { Subjectivity } from '../types/bindWorkflow.types';
import { ScreenTitle } from './shared/ScreenTitle';
import CbButton from '../../../../components/Buttons/CbButton';
import { InputLabelBase } from '../../../../components/inputs/InputLabelBase';
import { RegisteredToggleButtonGroup } from '../../../../components/Buttons/registered/v7';
import Showable from '../../../../components/Showable';
import CheckBoxBase from '../../../../components/inputs/Checkbox';
import CheckBoxLabel from '../CheckBoxLabel';
import TextFieldBase from '../../../../components/inputs/text-fields/base/TextFieldBase';
import { SubjectivityCompletionDate } from './shared/SubjectivityCompletionDate';

//hooks and utils
import { determineIfStepIsEditable } from '../utils/bindQuoteWorkFlowUtils';
import useCompleteQuoteSubjectivity from './shared/useCompleteQuoteSubjectivity';
import useGetSubjectivityProgress from './shared/useGetSubjectivityProgress';
import { withFormController } from '../../../../components/hocs/forms';
import { securityQuestionsCheckboxLabel } from '../constants/constants';
import { getIsRenewalOrMigration, manageAPIError } from '../../../../utils';
import { updateSecurityQuestions } from '../../../../accounts/AccountService';
import type { P250QuoteDetailsDto } from '../../../../types/quotes/details/p250-quote-details.dto';

const TextField = withFormController(TextFieldBase);

interface P250MissingSecurityQuestionsProps {
  goForward: () => void;
  goBack: () => void;
  step: string;
  subjectivities: Record<string, Subjectivity>;
  quoteDetails: StepperScreenProps['quoteDetails'] & P250QuoteDetailsDto;
  quoteProgress: StepperScreenProps['quoteProgress'];
}

const P250MissingSecurityQuestions = (
  props: P250MissingSecurityQuestionsProps
) => {
  const { quoteSubjectivityId } = props.subjectivities[props.step];
  const {
    data: allSubjectivityInformation = {},
    isLoading: isLoadingSubjectivityData,
    isFetching: isFetchingSubjectivityData,
  } = useGetSubjectivityProgress({
    quoteSubjectivityId,
    quoteId: props.quoteDetails.uiData.quoteId,
    productType: props.quoteDetails.uiData.productType,
  });

  if (isLoadingSubjectivityData || isFetchingSubjectivityData) {
    return null;
  }

  return (
    <P250MissingSecurityQuestionsView
      {...props}
      allSubjectivityInformation={allSubjectivityInformation}
    />
  );
};

interface P250MissingSecurityQuestionsViewProps
  extends P250MissingSecurityQuestionsProps {
  allSubjectivityInformation?: Record<string, any>;
}

const P250MissingSecurityQuestionsView = ({
  goForward,
  goBack,
  step,
  quoteDetails,
  subjectivities,
  quoteProgress,
  allSubjectivityInformation,
}: P250MissingSecurityQuestionsViewProps) => {
  const {
    completed: isStepCompleted,
    subjectivitySubType,
    quoteSubjectivityId,
  } = subjectivities[step];

  const isRenewalOrMigration = getIsRenewalOrMigration(
    Boolean(quoteDetails.isRenewal),
    Boolean(quoteDetails.isMigration)
  );

  const [isQuestionsAcknowledged, setIsQuestionsAcknowledged] =
    React.useState(isStepCompleted);
  const { enqueueSnackbar } = useSnackbar();

  const isStepEditable = determineIfStepIsEditable({
    subjectivitySubType,
    status: quoteProgress.quoteAgencyStatus,
    isAPI: quoteProgress.isAPI,
    productType: quoteDetails.uiData.productType,
  });

  const doesQuestionExist = (path: string) => {
    return _.has(allSubjectivityInformation?.hydratedInfo, path);
  };

  const questionRenderLogic = {
    shouldRenderBackupQuestions: doesQuestionExist('backupFrequency'),
    shouldRenderMfaQuestions: doesQuestionExist('mfaAuthentication'),
    shouldRenderIncidentResponseQuestion: doesQuestionExist(
      'incidentResponsePlan'
    ),
    shouldRenderChangeInScope: doesQuestionExist('changeInScope'),
  };

  const formMethods = useForm({
    defaultValues: {
      ...allSubjectivityInformation?.hydratedInfo,
      backupFrequencyOther:
        allSubjectivityInformation?.hydratedInfo?.backupFrequencyOther ?? '',
      mfaOther: allSubjectivityInformation?.hydratedInfo?.mfaOther ?? '',
      changeInScopeDetail:
        allSubjectivityInformation?.hydratedInfo?.changeInScopeDetail ?? '',
      mfaAuthentication:
        allSubjectivityInformation?.hydratedInfo?.mfaAuthentication ??
        undefined,
      incidentResponsePlan:
        allSubjectivityInformation?.hydratedInfo?.incidentResponsePlan ??
        undefined,
      changeInScope:
        allSubjectivityInformation?.hydratedInfo?.changeInScope ?? undefined,
    },
    resolver: yupResolver(deriveValidationSchema(questionRenderLogic)),
  });

  const { completeSubjectivity } = useCompleteQuoteSubjectivity({
    quoteId: quoteDetails.uiData.quoteId,
    productType: quoteDetails.uiData.productType,
  });

  const handleCheckboxChange = () => {
    setIsQuestionsAcknowledged((prevState) => !prevState);
  };

  const onSubmit = async (formValues: FieldValues) => {
    const { subjectivityPayload, accountPayload } = formatPayload(
      formValues,
      questionRenderLogic
    );
    try {
      await updateSecurityQuestions(
        {},
        { ...accountPayload, accountId: quoteDetails.uiData?.accountId }
      );
      return completeSubjectivity({
        payload: { ...subjectivityPayload, userAttested: true },
        goForward,
        quoteSubjectivityId,
      });
    } catch (error: any) {
      enqueueSnackbar(manageAPIError(error), { variant: 'error' });
    }
  };

  /* handles step skipping, and if we dont have questions to show */
  const handleNext = () => {
    if (isStepCompleted) {
      return goForward();
    }

    return completeSubjectivity({
      goForward,
      quoteSubjectivityId,
      payload: { userAttested: true },
    });
  };

  /* form fields to watch */
  const backupFrequency = formMethods.watch('backupFrequency');
  const backupType = formMethods.watch('backupType');
  const mfaAuthentication = formMethods.watch('mfaAuthentication');
  const mfaType = formMethods.watch('mfaType');
  const changeInScope = formMethods.watch('changeInScope');

  /* quote is not in referral. This subj shouldnt have been added. */
  if (!isRenewalOrMigration) {
    return (
      <Box className="contrast-text" maxWidth="65%">
        <ScreenTitle
          subjectivitySubType={subjectivitySubType}
          title={allSubjectivityInformation?.subjectivityTitle}
        />
        <h3>{allSubjectivityInformation?.subjectivityDescription ?? ''}</h3>

        <Typography variant="body1" style={{ marginBottom: '2rem' }}>
          This subjectivity is not required. Please contact Cowbell Support Team
          at <strong>support@cowbellcyber.ai</strong> for assistance.
        </Typography>

        <CbButton styleName="cancel" onClick={goBack}>
          Back
        </CbButton>
        <CbButton styleName="ctaButton" onClick={goForward}>
          Next
        </CbButton>
      </Box>
    );
  }

  /* When quote is in referral but we have no questions to show */
  if (!_.some(questionRenderLogic, (value) => Boolean(value))) {
    return (
      <Box className="contrast-text" maxWidth="65%">
        <ScreenTitle
          subjectivitySubType={subjectivitySubType}
          title={allSubjectivityInformation?.subjectivityTitle}
        />
        <h3>{allSubjectivityInformation?.subjectivityDescription ?? ''}</h3>

        <Typography variant="body1" style={{ marginBottom: '2rem' }}>
          All missing questions have been answered and no further action is
          needed.
        </Typography>

        <CbButton styleName="cancel" onClick={goBack}>
          Back
        </CbButton>
        <CbButton styleName="ctaButton" onClick={handleNext}>
          Next
        </CbButton>
      </Box>
    );
  }

  /*  default return case is to show questions */
  return (
    <Box className="contrast-text" maxWidth="65%">
      <ScreenTitle
        subjectivitySubType={subjectivitySubType}
        title={allSubjectivityInformation?.subjectivityTitle}
      />
      <h3>{allSubjectivityInformation?.subjectivityDescription ?? ''}</h3>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Showable
            show={Boolean(questionRenderLogic.shouldRenderBackupQuestions)}
          >
            <InputLabelBase required>
              How often does the organization perform backups of
              business-critical data?
            </InputLabelBase>
            <RegisteredToggleButtonGroup name="backupFrequency" exclusive>
              <ToggleButton value="WEEKLY">Weekly or more</ToggleButton>
              <ToggleButton value="MONTHLY">Monthly</ToggleButton>
              <ToggleButton value="QUARTERLY">Quarterly</ToggleButton>
              <ToggleButton value="SIX_MONTHS">Every 6 Months</ToggleButton>
              <ToggleButton value="NEVER">Never</ToggleButton>
            </RegisteredToggleButtonGroup>

            <FormHelperText style={{ marginTop: 0 }}>
              {formMethods.formState.errors.backupFrequency?.message ?? ''}
            </FormHelperText>

            <Showable show={backupFrequency !== 'NEVER'}>
              <InputLabelBase required>
                <i>
                  (if your organization performs backups, please select all that
                  apply)
                  <br />
                  Backups are:
                </i>
              </InputLabelBase>
              <RegisteredToggleButtonGroup name="backupType">
                <ToggleButton value="backupFrequencyEncrypted">
                  Encrypted
                </ToggleButton>
                <ToggleButton value="backupFrequencyTested">
                  Tested
                </ToggleButton>
                <ToggleButton value="backupFrequencySeparate">
                  Online or Designated Cloud Service
                </ToggleButton>
                <ToggleButton value="backupFrequencyOther">Other</ToggleButton>
              </RegisteredToggleButtonGroup>
              <FormHelperText style={{ marginTop: 0 }}>
                {formMethods.formState.errors.backupType?.message ?? ''}
              </FormHelperText>
            </Showable>

            <Showable
              show={Boolean(backupType?.includes('backupFrequencyOther'))}
            >
              <InputLabelBase required>
                <i>(Other: Please specify)</i>
              </InputLabelBase>
              <TextField multiline rows={3} name="backupFrequencyOther" />
            </Showable>
          </Showable>

          <Showable
            show={Boolean(questionRenderLogic.shouldRenderMfaQuestions)}
          >
            <InputLabelBase required>
              Do you enforce Multi-Factor Authentication (MFA) for all
              employees, contractors, and partners?
            </InputLabelBase>
            <RegisteredToggleButtonGroup name="mfaAuthentication" exclusive>
              <ToggleButton value>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </RegisteredToggleButtonGroup>
            <FormHelperText style={{ marginTop: 0 }}>
              {formMethods.formState.errors.mfaAuthentication?.message ?? ''}
            </FormHelperText>

            <Showable show={!!mfaAuthentication}>
              <InputLabelBase required>
                <i>(Please select all that apply)</i>
              </InputLabelBase>
              <RegisteredToggleButtonGroup name="mfaType">
                <ToggleButton value="mfaEmail">Email</ToggleButton>
                <ToggleButton value="mfaCloudDeployments">
                  Cloud Deployment
                </ToggleButton>
                <ToggleButton value="mfaMissionCriticalSystems">
                  Mission-critical systems
                </ToggleButton>
                <ToggleButton value="mfaRemoteAccess">
                  Remote Access
                </ToggleButton>
                <ToggleButton value="mfaOther">Other</ToggleButton>
              </RegisteredToggleButtonGroup>
              <FormHelperText style={{ marginTop: 0 }}>
                {formMethods.formState.errors.mfaType?.message ?? ''}
              </FormHelperText>
            </Showable>

            <Showable show={Boolean(mfaType?.includes('mfaOther'))}>
              <InputLabelBase required>
                <i>
                  (Other: Please explain in detail your MFA implementation
                  throughout the organization)
                </i>
              </InputLabelBase>
              <TextField multiline rows={3} name="mfaOther" />
            </Showable>
          </Showable>

          <Showable
            show={Boolean(
              questionRenderLogic.shouldRenderIncidentResponseQuestion
            )}
          >
            <InputLabelBase required>
              Does the organization have an incident response plan - tested and
              in-effect - setting forth specific action items and
              responsibilities for relevant parties in the event of cyber
              incident or data breach matter?
            </InputLabelBase>
            <RegisteredToggleButtonGroup name="incidentResponsePlan" exclusive>
              <ToggleButton value>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </RegisteredToggleButtonGroup>
            <FormHelperText style={{ marginTop: 0 }}>
              {formMethods.formState.errors.incidentResponsePlan?.message ?? ''}
            </FormHelperText>
          </Showable>

          <Showable
            show={Boolean(questionRenderLogic.shouldRenderChangeInScope)}
          >
            <InputLabelBase required>
              In the 12 months prior to this renewal, has the organization
              experienced or is contemplating material change in the scope of
              the organization's business, including the mergers,
              acquisitions,divestitures, and/or repurposing?
            </InputLabelBase>
            <RegisteredToggleButtonGroup name="changeInScope" exclusive>
              <ToggleButton value>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </RegisteredToggleButtonGroup>
            <FormHelperText style={{ marginTop: 0 }}>
              {formMethods.formState.errors.changeInScope?.message ?? ''}
            </FormHelperText>

            <Showable show={Boolean(changeInScope)}>
              <InputLabelBase required>
                <i>(Please provide details)</i>
              </InputLabelBase>
              <TextField multiline rows={3} name="changeInScopeDetail" />
            </Showable>
          </Showable>

          <div>
            <CheckBoxBase
              label={
                (
                  <CheckBoxLabel label={securityQuestionsCheckboxLabel} />
                ) as any
              }
              onChange={handleCheckboxChange}
              checked={isQuestionsAcknowledged}
              disabled={!isStepEditable}
            />
          </div>

          <Showable show={isStepCompleted}>
            <SubjectivityCompletionDate
              allSubjectivityInformation={allSubjectivityInformation}
            />
          </Showable>
          <Box mt={2}>
            <CbButton styleName="cancel" onClick={goBack}>
              Back
            </CbButton>
            <CbButton
              styleName="ctaButton"
              type="submit"
              disabled={
                (formMethods.formState.isDirty && !isQuestionsAcknowledged) ||
                formMethods.formState.isSubmitting
              }
            >
              Next
            </CbButton>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default P250MissingSecurityQuestions;

const ToggleButton = styled(MuiToggleButton)(() => {
  return {
    padding: '0.5rem 3rem',
  };
});

/* Validations */
const deriveValidationSchema = ({
  shouldRenderBackupQuestions,
  shouldRenderMfaQuestions,
  shouldRenderIncidentResponseQuestion,
  shouldRenderChangeInScope,
}: Record<string, boolean>) => {
  const backupQuestionValidations = {
    backupFrequency: Yup.string().label('Backup frequency').required(),
    backupType: Yup.array()
      .label('Backup type')
      .when('backupFrequency', {
        is: (value: string) => value !== 'NEVER',
        then: Yup.array().required(),
      }),
    backupFrequencyOther: Yup.string()
      .label('Other backup frequency')
      .when('backupType', {
        is: (value) => value && value.includes('backupFrequencyOther'),
        then: Yup.string().required(),
      }),
  };

  const mfaQuestionValidations = {
    mfaAuthentication: Yup.bool().label('MFA Authentication').required(),
    mfaType: Yup.array()
      .label('MFA type')
      .when('mfaAuthentication', {
        is: (value) => !!value,
        then: Yup.array().required(),
      }),
    mfaOther: Yup.string()
      .label('MFA Other')
      .when('mfaType', {
        is: (value) => value && value.includes('mfaOther'),
        then: Yup.string().required(),
      }),
  };

  const incidentResponseQuestionValidations = {
    incidentResponsePlan: Yup.bool().label('Incident Response Plan').required(),
  };

  const changeInScopeQuestionValidations = {
    changeInScope: Yup.bool().label('Change in scope').required(),
    changeInScopeDetail: Yup.string()
      .label('Change in scope details')
      .when('changeInScope', {
        is: (value) => !!value,
        then: Yup.string().required(),
      }),
  };

  return Yup.object().shape({
    ...(shouldRenderBackupQuestions && backupQuestionValidations),
    ...(shouldRenderMfaQuestions && mfaQuestionValidations),
    ...(shouldRenderIncidentResponseQuestion &&
      incidentResponseQuestionValidations),
    ...(shouldRenderChangeInScope && changeInScopeQuestionValidations),
  });
};

/* Formats payloads for both account and subjectivity api's */
const formatPayload = (
  formValues: FieldValues,
  questionRenderLogic: Record<string, boolean>
) => {
  const { backupType, mfaType, ..._formValues } = formValues;

  const backupQuestionsPayload = {
    backupFrequency: _formValues.backupFrequency,
    backupFrequencyOther: _formValues.backupFrequencyOther,
    backupFrequencyEncrypted: !!backupType?.includes(
      'backupFrequencyEncrypted'
    ),
    backupFrequencyTested: !!backupType?.includes('backupFrequencyTested'),
    backupFrequencySeparate: !!backupType?.includes('backupFrequencySeparate'),
  };

  const accountBackupQuestionsPayload = {
    p250Details: backupQuestionsPayload,
  };

  const mfaQuestionsPayload = {
    mfaEmail: !!mfaType?.includes('mfaEmail'),
    mfaCloudDeployments: !!mfaType?.includes('mfaCloudDeployments'),
    mfaMissionCriticalSystems: !!mfaType?.includes('mfaMissionCriticalSystems'),
    mfaRemoteAccess: !!mfaType?.includes('mfaRemoteAccess'),
    mfaAuthentication: _formValues.mfaAuthentication,
    mfaOther: _formValues.mfaOther,
  };

  const incidentResponsePayload = {
    incidentResponsePlan: _formValues.incidentResponsePlan,
  };

  const changeInScopePayload = {
    changeInScope: _formValues.changeInScope,
    changeInScopeDetail: _formValues.changeInScopeDetail,
  };

  return {
    subjectivityPayload: {
      ...(questionRenderLogic.shouldRenderBackupQuestions &&
        backupQuestionsPayload),
      ...(questionRenderLogic.shouldRenderMfaQuestions && mfaQuestionsPayload),
      ...(questionRenderLogic.shouldRenderIncidentResponseQuestion &&
        incidentResponsePayload),
      ...(questionRenderLogic.shouldRenderChangeInScope &&
        changeInScopePayload),
    },
    accountPayload: {
      ...(questionRenderLogic.shouldRenderBackupQuestions &&
        accountBackupQuestionsPayload),
      ...(questionRenderLogic.shouldRenderMfaQuestions && mfaQuestionsPayload),
      ...(questionRenderLogic.shouldRenderIncidentResponseQuestion &&
        incidentResponsePayload),
      ...(questionRenderLogic.shouldRenderChangeInScope &&
        changeInScopePayload),
    },
  };
};
