import { createAction, handleActions } from 'redux-actions';

// utils
export const AUTH_SET_AUTHENTICATION = 'AUTH_SET_AUTHENTICATION';

// actions
export const setAuthentication = createAction(AUTH_SET_AUTHENTICATION);

// reducers
const initialState = {
  isAuthenticated: false,
};

const AuthReducer = handleActions(
  {
    AUTH_SET_AUTHENTICATION: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);

export default AuthReducer;
