import React from 'react';
import clsx from 'classnames';

// components
import { Box, Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export function DoubleLabelSwitch({
  offLabel = 'off',
  onLabel = 'on',
  ...props
}) {
  return (
    <Box component="label" display="flex" alignItems="center">
      <Label
        active={!props.value}
        content={offLabel}
        disabled={props.disabled}
      />
      <Switch color="secondary" {...props} />
      <Label active={props.value} content={onLabel} disabled={props.disabled} />
    </Box>
  );
}

function Label({ active, content, disabled }) {
  const classes = useLabelStyles({ disabled });

  if (typeof content === 'string') {
    return (
      <Typography
        className={clsx('normal-text', classes.root, {
          [classes.active]: active,
        })}
      >
        {content}
      </Typography>
    );
  }

  return content;
}

const useLabelStyles = makeStyles(({ palette }) => ({
  root: {
    '&:hover': {
      cursor: ({ disabled }) => (!disabled ? 'pointer' : 'initial'),
    },
  },

  active: {
    color: ({ disabled }) =>
      !disabled ? palette.primary.contrastText : 'inherit',
  },
}));
