import { ProductTypes, type ProductType } from '../types';
import { ServiceHandler } from '../utils';

export interface GetPresetsRequest {
  accountId: string;
  agencyId: string | undefined;
  effectiveDate: string | undefined;
  industryClass: number;
  isFranchisee: boolean;
  franchiseSystemConnectivity: boolean;
  naicsCode: number;
  securityQuestions?: any;
  securityAnswers?: any[];
  preset: string;
  product: ProductType;
  revenue: number;
  selectedLimit: number;
  state: string;
  yearEstablished: number;
}

export interface BasePresetMetadata {
  name: string;
  limit: number;
  deductible: number;
  retroActivePeriod?: number;
  waitingPeriod?: number;
}

export interface PresetMetadata extends BasePresetMetadata {
  alignMax: boolean;
  isAvailable: boolean;
  isSelected: boolean;
  ordinal: number;
  refer: boolean;
  limit: number;
  deductible: number;
  isHidden: boolean;
}

export interface Coverage {
  coverageDescription: string;
  coverageFullName: string;
  coverageName: string;
  preset: PresetMetadata;
  rules: {
    coverageName: string;
    deductibles: number[];
    isMandatory: boolean;
    limits: number[];
    hrs: number[];
    retros?: number[];
  };
}

export interface GetPresetsResponse {
  coverageGroups: {
    [coverageGroup: string]: Coverage[];
  };
  globalDeductibles: number[];
}

export const getPresetsByProduct = (
  productType: ProductType,
  naicsCode: number,
  presetName: string,
  payload: { data: GetPresetsRequest }
) => {
  if (productType === ProductTypes.prime_one) {
    return getPrimeXPresets(naicsCode, presetName, payload);
  }

  return getPresets(productType, naicsCode, presetName, payload);
};

const getPrimeXPresets = ServiceHandler({
  url: '/api/rater/v1/primex/presets/rules/:naics/:presetName',
  method: 'POST',
});

export const getPresets = ServiceHandler<GetPresetsRequest, GetPresetsResponse>(
  {
    url: '/api/rater/v1/presets/rules/:productType/:naics/:presetName',
    method: 'POST',
  }
);

export interface GetRulesGlobalsResponse {
  aggLimits: number[];
  deductibles: number[];
  hrs: number[];
  retros: number[];
}

export const getGlobalOptions = ServiceHandler<
  unknown,
  GetRulesGlobalsResponse
>({
  url: '/api/rater/v1/rules/:productType/globals/:state',
  method: 'POST',
});

export const getUKGlobalOptions = ServiceHandler<
  unknown,
  GetRulesGlobalsResponse
>({
  url: '/api/rater/v1/rules/:productType/globals',
  method: 'POST',
});

interface GetCustomPresetsResponse {
  name: string;
  id: string;
  preset: string;
  agencyId: string;
  email: string;
}

export const getAvailableCustomPresets = ServiceHandler<
  unknown,
  GetCustomPresetsResponse[]
>({
  url: '/api/rater/v1/presets/custom',
});
