import _ from 'lodash';
import * as Yup from 'yup';
import React, { useState, useEffect, useRef } from 'react';
import { useForm, FormContext } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { DialogActions, DialogContent, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

// validations
import '../../console/_global/lib/validations/PhoneSchemaType';
// components
import { CowbellCompliance } from '../surplusCompliance/CowbellCompliance';
import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { withFormController } from '../../components/hocs/withFormController';
import CbButton from '../../components/Buttons/CbButton';
import { PhoneField as PhoneFieldBase } from '../../components/inputs/PhoneField';
import { SurplusSendQuote } from './SurplusSendQuote';
// platform helpers
import { delayedEvent } from '../../utils/eventUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../reducers/UiSettingsReducers';
import { setAgencySendInfo, setRoute } from '../quote.reducer';
// actions
import { sendQuote } from '../QuotesService';
import { QuoteAgencyStatus } from '../../types/common/misc';
import Showable from '../../components/Showable';
import { completeQuoteSubjectivity, getQuoteProgress } from '../../api';
import { subjectivityKeys } from '../../console/workflows/bind-quote/constants/constants';
import { manageAPIError } from '../../utils';

const complianceStates = ['LA', 'MT'];

const styles = ({ palette, config }) => {
  return {
    greenText: {
      color: palette.text.text4,
    },
    boldText: {
      fontWeight: 600,
    },
    whiteText: {
      color: palette.primary.contrastText,
    },
    pagination: {
      fontSize: config.textSizes.tertia,
      color: palette.primary.contrastText,
      fontWeight: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
  };
};
const schema = Yup.object().shape({
  customerFirstName: Yup.string()
    .label("Policy Holder's First Name")
    .required(),
  customerLastName: Yup.string().label("Policy Holder's Last Name ").required(),
  customerEmail: Yup.string().label("Policy Holder's Email").email().required(),
  customerPhone: Yup.number()
    .label("Policy Holder's Phone")
    .fromPhone()
    .phone()
    .required(),
});

const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);

export const AgencySendQuote = withStyles(styles)(
  ({ data, dispatch, setHandleCancel, ...props }) => {
    const { enqueueSnackbar } = useSnackbar();

    const surplusCowbellInsurance = _.get(
      data,
      'initial250RequestData.surplusCowbellInsurance',
      false
    );
    const productType = _.get(data, 'product', '');

    let nextBtnLabel;
    if (data.isSurplus) {
      nextBtnLabel = 'Next';
    } else {
      nextBtnLabel = 'Send to Policy Holder';
    }

    const sendDetails = useRef({
      customerFirstName: data.customerFirstName,
      customerLastName: data.customerLastName,
      customerEmail: data.customerEmail,
      customerPhone: data.customerPhone,
    });

    const { handleSubmit, setValue, ...methods } = useForm({
      validationSchema: schema,
    });

    const [page, setPage] = useState(1);
    const { isSubmitting } = methods.formState;
    const { classes } = props;

    const isCompliance = data.isSurplus
      ? complianceStates.includes(data.surplus.state) &&
        !data.surplus.signatureOnFile
      : false; // checking whether is LA/MT and signature does not exist

    useEffect(() => {
      const {
        customerFirstName,
        customerLastName,
        customerEmail,
        customerPhone,
      } = data;
      dispatch(
        setAgencySendInfo({
          customer: {
            customerFirstName,
            customerLastName,
            customerEmail,
            customerPhone,
          },
        })
      );
      dispatch(setRoute({ from: 'send' }));
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const {
        customerFirstName,
        customerLastName,
        customerEmail,
        customerPhone,
      } = sendDetails.current;
      setValue('customerFirstName', customerFirstName);
      setValue('customerLastName', customerLastName);
      setValue('customerPhone', customerPhone);
      setValue('customerEmail', customerEmail);
      // eslint-disable-next-line
    }, [page]);

    const handleCancel = () => {
      if (typeof setHandleCancel === 'function') {
        setHandleCancel();
      }
    };

    // If quote is bound, form fields below cannot be edited and no need to send api to complete PH subjectivity
    const isQuoteBound = data.status === QuoteAgencyStatus.BOUND;

    const quoteId = isQuoteBound ? data.quoteId : data.id;

    const {
      data: quoteSubjectivities = {},
      isLoading: isLoadingSubjectivities,
    } = useGetSubjectivities({ quoteId, isQuoteBound });

    const handleSendQuote = async (formValues) => {
      if (!isQuoteBound && quoteSubjectivities.shouldCompleteSubjectivity) {
        // complete PH info subj
        await completeQuoteSubjectivity(
          productType,
          quoteSubjectivities.policyHolderSubjectivity[0].quoteSubjectivityId,
          {
            data: formValues,
          }
        );
      }

      return sendQuote({ quoteId, productType, ...formValues })
        .then(() => {
          enqueueSnackbar('Sent Successfully!', { variant: 'success' });
          delayedEvent('table-refetch', 1000, AGENCY_QUOTES_LISTING_STORE_ID);
          delayedEvent('quote:read');
          handleCancel();
        })
        .catch((error) => {
          const message = manageAPIError(
            error,
            'Unable to send to policy holder. Please try again later.'
          );
          enqueueSnackbar(message, { variant: 'error' });
        });
    };

    const onSubmit = (formValues) => {
      dispatch(setAgencySendInfo({ customer: formValues }));
      dispatch(setRoute({ from: 'send' }));

      if (data.isSurplus) {
        setPage(2);
        sendDetails.current = formValues;
      } else {
        return handleSendQuote(formValues);
      }
    };

    return (
      <>
        {page === 1 && (
          <FormContext {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent style={{ padding: '2rem 5rem 2.5rem' }}>
                <Grid container spacing={4}>
                  <Grid item sm={6} style={{ paddingBottom: 0 }}>
                    <TextField
                      name="customerFirstName"
                      label="Policy Holder First Name"
                      placeholder="Please enter policy holder first name"
                      disabled={isQuoteBound}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} style={{ paddingBottom: 0 }}>
                    <TextField
                      name="customerLastName"
                      label="Policy Holder Last Name"
                      placeholder="Please enter policy holder last name"
                      disabled={isQuoteBound}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} style={{ paddingTop: 0 }}>
                    <TextField
                      name="customerEmail"
                      label="Policy Holder Email"
                      type="email"
                      placeholder="Please enter policy holder email"
                      disabled={isQuoteBound}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} style={{ paddingTop: 0 }}>
                    <PhoneField
                      name="customerPhone"
                      label="Policy Holder Phone"
                      disabled={isQuoteBound}
                      required
                      fullWidth
                      placeholder="(___)___-____"
                    />
                  </Grid>
                  <Showable show={isQuoteBound}>
                    <i style={{ paddingLeft: '1rem' }}>
                      Note: This quote has already been bound and the Policy
                      holder information can no longer be edited.{' '}
                    </i>
                  </Showable>
                </Grid>
                <p className={classes.whiteText}>
                  {surplusCowbellInsurance && data.isSurplus && (
                    <i className={classes.whiteText}>
                      Surplus Compliance: Cowbell Insurance Agency.
                    </i>
                  )}
                </p>
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  justifyContent={
                    !data.isSurplus ? 'flex-end' : 'space-between'
                  }
                >
                  {data.isSurplus && (
                    <Grid className={classes.pagination}>
                      Step 1 of {isCompliance ? 3 : 2}: Policyholder Details
                    </Grid>
                  )}
                  <Grid>
                    <CbButton
                      styleName="cancel"
                      onClick={handleCancel}
                      buttonText="Cancel"
                    />
                    <CbButton
                      styleName="ctaButton"
                      type="submit"
                      disabled={isSubmitting || isLoadingSubjectivities}
                      loading={isSubmitting}
                      buttonText={nextBtnLabel}
                    />
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          </FormContext>
        )}
        {page === 2 && surplusCowbellInsurance && (
          <CowbellCompliance
            handleSubmit={handleSendQuote}
            setPage={setPage}
            isSubmitting={isSubmitting}
          />
        )}
        {page === 2 && !surplusCowbellInsurance && (
          <SurplusSendQuote
            canFormBeEdited={isQuoteBound}
            data={data}
            setCustomerPage={setPage}
            setHandleCancel={handleCancel}
          />
        )}
      </>
    );
  }
);

export default AgencySendQuote;

const useGetSubjectivities = ({ quoteId, isQuoteBound }) => {
  return useQuery(['quote-subjectivities', quoteId], () => {
    if (!isQuoteBound) {
      return getQuoteProgress(quoteId).then(({ data: responseData }) => {
        const { preBindSubjectivities = [] } = responseData;
        const policyHolderSubjectivity = preBindSubjectivities.filter(
          (subjectivity) =>
            subjectivity.subjectivityKey ===
            subjectivityKeys.POLICY_HOLDER_CONTACT
        );
        return {
          policyHolderSubjectivity,
          shouldCompleteSubjectivity: Boolean(policyHolderSubjectivity?.length),
          responseData,
        };
      });
    }
  });
};
