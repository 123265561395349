import Logout from '../../components/entry/Logout';
import SignUpSubmitted from '../../components/entry/SignUpSubmitted';
import SignupEmailLanding from '../../components/entry/SignupEmailLanding/SignupEmailLanding';

import ResendValidationEmail from '../../components/entry/ResendValidationEmail';

import ApplicationSigned from '../../components/main/common/ApplicationSgined';
import { ResetMfaLanding } from '../../components/entry/ResetMfaLanding';

import ServerDown from '../../components/main/common/ServerDown';
import { QuoteRequestToBindConfirm } from '../../public/quote/QuoteRequestToBindConfirm';
import { AccessCodeTemp } from '../../components/entry/AccessCodeTemp';
import { BrowserUnsupported } from '../../components/main/common/BrowserUnsupported';
import { AccountantPaymentConfirm } from '../../public/policy/AccountantPaymentConfirm';
import { SurplusNoticeExternalSignature } from '../../inbox/surplusCompliance/SurplusNoticeExternalSignature';

import { PersonaLoadingView } from '../../console/personas/PersonaLoadingView';

import PasswordExpired from '../../components/entry/login/PasswordExpired';
import Login from '../../components/entry/Login';
import LoginExisting from '../../components/entry/LoginExisting';
import ForgotPassword from '../../components/entry/password/ForgotPassword';
import ConfirmPasswordReset from '../../components/entry/password/ConfirmPasswordReset';
import UserInvitationConfirmation from '../../components/entry/password/UserInvitationConfirmation';

export const PUBLIC_ROUTE_MAPPING = [
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/login-existing',
    component: LoginExisting,
    exact: true,
  },
  { path: '/loading', component: PersonaLoadingView, exact: true },
  { path: '/loading/:token', component: PersonaLoadingView, exact: true },
  { path: '/logout', component: Logout, exact: true },
  { path: '/signup-submitted', component: SignUpSubmitted, exact: true },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
  },
  { path: '/password-expired', component: PasswordExpired, exact: true },
  { path: '/validate/:workflowId', component: AccessCodeTemp, exact: true },
  {
    path: '/quote/:id/request-to-bind/:product',
    component: QuoteRequestToBindConfirm,
    exact: true,
  },
  {
    path: '/policy/:id/accountant-payment',
    component: AccountantPaymentConfirm,
    exact: true,
  },
  {
    path: '/signup-validation/:token',
    component: SignupEmailLanding,
    exact: true,
  },
  { path: '/resend-validation', component: ResendValidationEmail, exact: true },
  {
    path: '/confirmUser/:token', // user invited/added from platform
    component: UserInvitationConfirmation,
    exact: true,
  },
  {
    path: '/confirmPasswordReset/:token',
    component: ConfirmPasswordReset,
    exact: true,
  },
  {
    path: '/confirmMfaReset/:resetMFAToken',
    component: ResetMfaLanding,
    exact: true,
  },
  { path: '/serverdown', component: ServerDown, exact: true },
  { path: '/unsupported', component: BrowserUnsupported, exact: true },
  { path: '/application-signed', component: ApplicationSigned, exact: true },
  {
    path: '/quote/:id/attest-surplus-notice',
    component: SurplusNoticeExternalSignature,
    exact: true,
  },
];
