import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// mui
import { Box, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { usePersona } from '../components/hooks/usePersona';

export const PolicyNavigation = () => {
  const { tab } = useParams();
  const { push } = useHistory();
  const tabClasses = tabStyle();
  const tabsClasses = styles();

  const persona = usePersona();

  const handleTabChange = React.useCallback(
    (event, nextTab) => {
      if (nextTab.trim()) {
        push(`${persona.basePath}/policies/${nextTab}`);
      } else {
        push(`${persona.basePath}/policies`);
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Box>
      <Tabs
        value={tab || ' '}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
        classes={tabsClasses}
        onChange={handleTabChange}
      >
        <Tab label="Policies" value=" " disableRipple classes={tabClasses} />

        <Tab
          label="BOR Policies"
          value="bor"
          disableRipple
          classes={tabClasses}
        />

        <Tab
          label="Endorsements"
          value="endorsements"
          disableRipple
          classes={tabClasses}
        />
      </Tabs>
    </Box>
  );
};

const styles = makeStyles(({ palette }) => {
  return {
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    root: {
      minHeight: 'unset',
    },
    flexContainer: {
      '& > button:last-of-type': {
        borderRight: `0.5px solid ${palette.border.grey}`,
      },
    },
  };
});

const tabStyle = makeStyles(({ config, palette }) => ({
  root: {
    textTransform: 'none',
    color: `${config.colors.cowbellBlue} !important`,
    '&:focus': {
      opacity: 1,
    },
    width: '11.5rem',
    fontWeight: 'normal',
    height: '2rem',
    margin: 'auto 0',
    minHeight: 'unset',
    borderLeft: `0.5px solid ${palette.border.grey}`,
    fontSize: config.textSizes.tertia,
  },
  selected: {
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    backgroundColor: palette.background.paper,
    color: `${palette.primary.contrastText} !important`,
    height: '3rem',
    borderRight: `none !important`,
    borderLeft: `none !important`,
    '&.Mui-selected': {
      color: `${palette.primary.contrastText} !important`,
      fontWeight: config.weights.bold,
    },
    '& + button': {
      borderLeft: `none !important`,
    },
  },
}));
