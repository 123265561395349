import React from 'react';
// mui
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import Numeral from 'numeral';
import { generateCoverageText } from './endorsement.utils';
import { withShowable } from '../lib/withShowable';

const MuiGrid = withShowable(Grid);

export const PaymentSummary = ({ data }) => {
  const classes = useStyles();
  const {
    totalPremium: totalPremiumData,
    coverageConsolidatedKeys = [],
    isAIApplicable,
    hasInsuredInfo,
  } = data;

  const getTotalPremium = React.useCallback((totalPremium) => {
    if (!(typeof totalPremium === 'number')) return '-';
    if (totalPremium > 0) return Numeral(totalPremium).format('$0,0.00');
    return `(${Numeral(totalPremium).format('$0,0.00')})`;
  }, []);

  const actionOnCoverageText = generateCoverageText(data);

  return (
    <>
      <div className={classes.heading}>Payment Summary</div>
      <MuiGrid
        container
        justifyContent="space-between"
        className={classes.back}
        show={hasInsuredInfo}
      >
        <Grid item>Insured Information</Grid>
        <Grid item>Updated</Grid>
      </MuiGrid>

      <MuiGrid
        container
        justifyContent="space-between"
        className={classes.back}
        show={
          actionOnCoverageText !== 'None' &&
          coverageConsolidatedKeys?.length > 0
        }
      >
        <Grid item>Coverage/Limits</Grid>
        <Grid item>{actionOnCoverageText}</Grid>
      </MuiGrid>

      <MuiGrid
        container
        justifyContent="space-between"
        className={classes.back}
        show={isAIApplicable !== 'None'}
      >
        <Grid item>Additional Insured</Grid>
        <Grid item>{isAIApplicable}</Grid>
      </MuiGrid>

      <Grid
        container
        justifyContent="space-between"
        className={classes.premium}
      >
        <Grid item>Premium Difference</Grid>
        <Grid item className={classes.blueText}>
          {getTotalPremium(totalPremiumData)}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(({ config, palette }) => {
  return {
    heading: {
      textDecoration: 'underline',
      'text-underline-offset': '2px',
      textTransform: 'uppercase',
      textAlign: 'left',
      fontSize: '1.16rem',
      fontWeight: 600,
      lineHeight: 1.5,
      color: palette.primary.contrastText,
      paddingTop: '2rem',
      paddingBottom: '0.66rem',
    },
    back: {
      background: palette.warning.contrastText,
      color: palette.primary.contrastText,
      padding: '0.33rem 1rem',
      marginBottom: '0.33rem',
    },
    premium: {
      padding: '0.33rem 1rem',
      color: palette.primary.contrastText,
      fontSize: '1.16rem',
      fontWeight: 600,
    },
    blueText: {
      color: config.colors.cowbellBlue,
    },
  };
});
