import React from 'react';

import { SvgIcon } from '@mui/material';

export const DownloadIcon = ({ fill = '#2180e2', ...props }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="23.094"
      height="20"
      viewBox="0 0 23.094 20"
      fill={fill}
      {...props}
    >
      <path d="M0 20v-6.009h2.1v3.831H21v-3.831h2.1V20zM4.476 8.3l1.6-1.624 4.371 4.576V0h2.2v11.217l4.405-4.539L18.617 8.3l-7.07 7.343z" />
    </SvgIcon>
  );
};
