import React from 'react';

import { Box, useTheme } from '@mui/material';

export const SimpleFooter = () => {
  const { palette } = useTheme();

  return (
    <Box
      textAlign="center"
      marginBottom="2rem"
      marginTop="12rem"
      color={palette.common.almostWhite}
    >
      <span className="petite-text">
        © 2020 Cowbell Cyber, Inc. | All Rights Reserved |{' '}
        <a className="blue-text" href="https://cowbell.insure/privacy/">
          Privacy
        </a>{' '}
        |{' '}
        <a className="blue-text" href="https://cowbell.insure/terms/">
          Terms
        </a>{' '}
        |{' '}
        <a className="blue-text" href="https://cowbell.insure/contact-us/">
          Feedback
        </a>
      </span>
    </Box>
  );
};
