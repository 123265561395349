import CbButton from '../../../../components/Buttons/CbButton';

interface FallbackScreenProps {
  goForward: () => void;
  goBack: () => void;
}

const FallbackScreen = ({ goForward, goBack }: FallbackScreenProps) => {
  return (
    <section>
      <div className="contrast-text" style={{ fontSize: '1.2rem' }}>
        Uh oh. Looks like we dont know what step to show you! We apologize for
        the inconvenience.
      </div>

      <div style={{ paddingTop: '1.5rem' }}>
        <CbButton styleName="cancel" size="medium" onClick={goBack}>
          Back
        </CbButton>
        <CbButton styleName="ctaButton" size="medium" onClick={goForward}>
          Next
        </CbButton>
      </div>
    </section>
  );
};

export default FallbackScreen;
