import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CowbellIcon({ ...props }) {
  return (
    <SvgIcon
      id="Component_201_12"
      data-name="Component 201 – 12"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="transparent"
      {...props}
    >
      <defs>
        <style>{'.cls-2{fill:#9ba0ae}'}</style>
      </defs>
      <g id="Isolation_Mode" data-name="Isolation Mode">
        <path
          id="Rectangle_2513"
          data-name="Rectangle 2513"
          style={{ fill: 'none' }}
          d="M0 0h20v20H0z"
        />
        <path
          id="Path_2999"
          data-name="Path 2999"
          className="cls-2"
          d="m5.223 13.506 7.206 1.685a.839.839 0 0 0 .18 0 .8.8 0 0 0 .739-.493L17.4 5.016a.506.506 0 0 0 0-.479.5.5 0 0 0-.413-.233L13.96 4.11a.666.666 0 0 0-.46.186l-8.523 8.345a.486.486 0 0 0 .246.866zm8.711-8.8 2.857.18-4 9.6a.193.193 0 0 1-.18.12l-7.088-1.652z"
          transform="translate(-1.375 -1.373)"
        />
        <path
          id="Path_3000"
          data-name="Path 3000"
          className="cls-2"
          d="m23.333 7.058-.44-1.132a.38.38 0 0 0-.346-.286.373.373 0 0 0-.34.273l-3.928 9.417a1.046 1.046 0 0 0 .107 1.006l1.172 1.685a.539.539 0 0 0 .44.273c.08 0 .346 0 .46-.426l2.935-10.23a.959.959 0 0 0-.06-.58zm-3.4 10.449-1.052-1.518a.453.453 0 0 1-.047-.433l3.709-8.877.226.6a.293.293 0 0 1 0 .2z"
          transform="translate(-5.853 -1.884)"
        />
        <path
          id="Path_3001"
          data-name="Path 3001"
          className="cls-2"
          d="M4.13 18.74h-.12v.113l1.325 2.2s0 .053.067.053a.1.1 0 0 0 .073-.04l1.512-1.7z"
          transform="translate(-1.114 -6.26)"
        />
        <path
          id="Path_3002"
          data-name="Path 3002"
          className="cls-2"
          d="M14.131 21.408a.579.579 0 0 0-.333-.226l-1.145-.266a1.6 1.6 0 0 1-2.844-.666l-1.432-.34-1.552 1.811a.087.087 0 0 0 .073.127l8.8 2.031h.08a.08.08 0 0 0 .053 0 .173.173 0 0 0-.047-.12z"
          transform="translate(-2.051 -6.651)"
        />
      </g>
    </SvgIcon>
  );
}
