import React, { useEffect } from 'react';
import _ from 'lodash';
import { DialogContent, DialogActions } from '@mui/material';
import { withStyles } from '@mui/styles';

import { getACHDetails } from '../_services/BillingService';
import CBButton from '../../../components/Buttons/CbButton';
import { toggleModalDirect } from '../../../utils/storeUtils';
// components

const ACHWireTransfer = ({ classes, data, ...props }) => {
  const [achData, setData] = React.useState(null);

  useEffect(() => {
    getACHDetails({ policyNumber: data.policyNumber })
      .then((resp) => {
        setData(_.get(resp, 'data', {}));
      })
      .catch(console.error.bind(console));
    // eslint-disable-next-line
  }, []);

  const clickHandler = () => {
    toggleModalDirect('ACHWireTransfer', false);
  };

  if (!achData) {
    return null;
  }

  return (
    <section>
      <DialogContent classes={{ root: classes.container }}>
        <p className={classes.header}>
          ACH/Wire transfer the payment directly to Cowbell Cyber Bank Account:
        </p>
        <section className={classes.bankInfoContainer}>
          <section className={classes.row}>
            <p className={classes.title}>BANK</p>
            <p className={classes.value}>{achData.bank}</p>
          </section>
          <section className={classes.row}>
            <p className={classes.title}>ROUTING</p>
            <p className={classes.value}>{achData.routing}</p>
          </section>
          <section className={classes.row}>
            <p className={classes.title}>ACCOUNT</p>
            <p className={classes.value}>{achData.account}</p>
          </section>
          <section className={classes.row}>
            <p className={classes.title}>ACCOUNT NAME</p>
            <p className={classes.value}>{achData.accountName}</p>
          </section>
          <section className={classes.row}>
            <p className={classes.title}>ACCOUNT TYPE</p>
            <p className={classes.value}>{achData.accountType}</p>
          </section>
          <section className={classes.row}>
            <p className={classes.title}>SWIFT</p>
            <p className={classes.value}>{achData.swift}</p>
          </section>
        </section>
        <p className={classes.message}>
          You can check your policy status on the Cowbell Cyber platform or
          contact Cowbell Support for payment confirmation after 3-5 business
          days from the transaction date.
        </p>
        <section>
          <section className={classes.row}>
            <p className={classes.title1}>PHONE</p>
            <p className={classes.value1}>{achData.phone}</p>
          </section>
          <section className={classes.row}>
            <p className={classes.title1}>EMAIL</p>
            <p className={classes.value1}>{achData.email}</p>
          </section>
          <section className={classes.row}>
            <p className={classes.title1}>ADDRESS</p>
            <p className={classes.value1}>{achData.address}</p>
          </section>
        </section>
      </DialogContent>
      <DialogActions>
        <CBButton
          action={clickHandler}
          styleName="ctaButton"
          buttonText="OK"
          data-qa="ok"
        />
      </DialogActions>
    </section>
  );
};

const styles = ({ palette }) => {
  return {
    header: {
      fontSize: '1.33rem',
      lineHeight: 1.13,
      color: palette.primary.main,
      margin: '0 0 1.6667rem 0 !important',
      textAlign: 'center',
    },
    container: {
      width: '60rem',
    },
    bankInfoContainer: {
      marginBottom: '1.6667rem',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      paddingLeft: '10rem',
    },
    title: {
      fontSize: '1.167rem',
      lineHeight: 1.75,
      color: palette.primary.main,
      width: '12rem',
      margin: '0 !important',
    },
    value: {
      fontSize: '1.167rem',
      fontWeight: 600,
      lineHeight: 1.75,
      color: palette.primary.main,
      margin: '0 !important',
    },
    title1: {
      fontSize: '1.167rem',
      lineHeight: 1.75,
      color: palette.primary.text11,
      width: '12rem',
      margin: '0 !important',
    },
    value1: {
      fontSize: '1.167rem',
      fontWeight: 600,
      lineHeight: 1.75,
      color: palette.primary.text11,
      margin: '0 !important',
    },
    message: {
      fontSize: '1.33rem',
      lineHeight: 1.13,
      color: palette.text.text11,
      margin: '0 0 1.6667rem 0 !important',
      textAlign: 'center',
    },
  };
};

export default withStyles(styles)(ACHWireTransfer);
