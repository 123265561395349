import React from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';

import { useMeQuery } from '../components/hooks/useMeQuery';
import { initializeSentry } from '../config/sentry/initializeSentry';
import { AuthenticatedRouter } from '../routes/AuthenticatedRouter';
import type { UserDto } from '../types/users/dto/user.dto';

import { ConsoleLayoutLoading } from './layouts/ConsoleLayoutLoading';

// other
import { setInitialUser } from '../store/actions/user.store.actions';
import { setUserAccount } from '../store/actions/user-account.store.actions';
import { getProfilePhoto } from '../api/ImageService';
import { fetchP100Coverages } from '../accounts/AccountService';
import { setAuthentication } from '../reducers/AuthReducers';
import { setGlobalTeam } from '../reducers/team.reducer';
import { ALL_TEAM } from '../teams/TeamsMenu';
import { PERSONA_TYPES } from './_statics/persona.statics';

const USER_CACHE_STALE_MINUTES = 10;

initializeSentry();

/**
 * @name ConsoleResolver
 * @description The user is logged in by the time they reach this component. Retrieve all user and account dependencies and determine where to send them next.
 */
export function ConsoleResolver() {
  const dispatch = useDispatch();

  /* Temporary to support older P100 Features relying on coverages being in redux */
  React.useEffect(() => {
    fetchP100Coverages().then((resp) => {
      dispatch(setAuthentication({ P_100: resp.data }));
    });
    // eslint-disable-next-line
  }, []);

  async function handleSuccessfulMeResponse(nextUser: UserDto) {
    const { account, ...user } = nextUser;
    const avatarUrl = await getProfilePhoto().then((resp) => resp.data);

    /* todo: make sure there is a user, if not set an error to the store */
    dispatch(setInitialUser({ ...user, avatarUrl }));
    dispatch(setUserAccount(account));

    // * ensures that no team narrowing occurs while in CB persona. Without this, a team selection made after jumping in to an agency could (sneakily) alter behavior on CB persona.
    if (account.accountType == PERSONA_TYPES.COWBELL) {
      dispatch(setGlobalTeam(ALL_TEAM));
    }

    if (user?.id) {
      // set sentry session info
      Sentry.setUser({ id: user.id });
      Sentry.setContext('user', { persona: account?.accountType });
    }
  }

  const {
    data: me,
    isLoading: isLoadingMe,
    isError: isMeError,
  } = useMeQuery({
    staleTime: 60000 * USER_CACHE_STALE_MINUTES,
    refetchOnWindowFocus: false,
    onSuccess: handleSuccessfulMeResponse,
  });

  if (isLoadingMe) {
    return <ConsoleLayoutLoading />;
  }

  if (isMeError) {
    return (
      <ConsoleLayoutLoading message="An error occurred and we couldn't recover, try logging out and back in. If the issue continues contact support." />
    );
  }

  return <AuthenticatedRouter user={me} />;
}
