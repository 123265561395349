import React from 'react';
import DescTooltipBase from '../../../components/DescTooltip';
import { withShowable } from '../../_global/lib/withShowable';
import { tooltipLegalLanguage } from './constants/constants';

const DescTooltip = withShowable(DescTooltipBase);

const CheckBoxLabel = ({ label, tooltip = true }) => {
  return (
    <>
      {label} <DescTooltip show={tooltip} tooltip={tooltipLegalLanguage} />
    </>
  );
};

export default CheckBoxLabel;
