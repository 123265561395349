import { ActionType } from '../../../components/modals/v2/connectors/helpers';

export const SophosSchema = {
  name: 'Sophos Connector',
  steps: {
    0: {
      name: 'Step 1',
      elements: [
        {
          type: 'description',
          description: 'Please provide API credentials to connect.',
          column: 0,
        },
        {
          name: 'clientId',
          label: 'Client ID',
          description: 'Please enter your Sophos client ID',
          type: 'text',
          required: true,
          column: 1,
        },
        {
          name: 'clientSecret',
          label: 'Client Secret',
          description: 'Please enter your Sophos client secret',
          type: 'password',
          required: true,
          column: 2,
        },
      ],
      layout: {
        columns: 3,
        containerProps: { spacing: 2, direction: 'row' },
        columnProps: {
          0: { xs: 12, md: 12 },
          1: { xs: 12, md: 12 },
          2: { xs: 12, md: 12 },
        },
        elementContainerProps: { 0: {}, 1: {}, 2: {} },
      },
      action: {
        type: ActionType.API_ENDPOINT,
        requestConfig: {
          url: '/api/connector/v1/add',
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: null, // this is needed or Content-Type header is removed
        },
      },
    },
    1: {
      name: 'Step 2',
      elements: [
        {
          type: 'description',
          description: 'Sophos has been successfully added as a connector.',
          column: 0,
        },
      ],
      layout: {
        columns: 1,
        containerProps: { spacing: 2, direction: 'row' },
        columnProps: {
          0: { xs: 12, md: 12 },
        },
        elementContainerProps: { 0: {} },
      },
      action: {
        type: ActionType.CLOSE_MODAL,
      },
    },
  },
};
