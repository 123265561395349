import { Backdrop, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

const LoadingSpinnerOverlay = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <LoadingOverlayContainer open>
      <CircularProgressContainer>
        <CircularProgress size="5rem" />
        {children}
      </CircularProgressContainer>
    </LoadingOverlayContainer>
  );
};

export default LoadingSpinnerOverlay;

const LoadingOverlayContainer = styled(Backdrop)<any>(({ theme }) => {
  return {
    zIndex: 100,
    color: theme.palette.primary.contrast,
  };
});

const CircularProgressContainer = styled(Box)(() => {
  return {
    position: 'absolute',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gridGap: '1rem',
  };
});
