import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CloudDownload } from '@mui/icons-material';
import { ProductTypes } from '../../../../../types';
import { BOUND_STATUS } from '../../../../_statics/quote.statics';

//mui

//components
import AdminAccountsQuoteDrawerHeader from '../AdminAccountsQuoteDrawerHeader';
import RowDataWrapper from './RowDataWrapper';
import AdminQuoteDrawerSkeleton from '../AdminQuoteDrawerSkeleton';

//Services
import { downloadQuoteProposal } from '../../../../../inbox/QuotesService';
import { DocumentDownloader } from '../../../../../components/fileUploader/DocumentDownloader';
import { getCowbellTranslations } from '../../../../../i18n/translations';
import { getIsUsPlatform, getPlatformRegion } from '../../../../../utils';

interface QuoteTabViewProps {
  quotes?: any[];
  isLoading: boolean;
}

const QuoteTabView: React.FC<QuoteTabViewProps> = ({
  quotes = [],
  isLoading,
}) => {
  const classes = useStyles();

  if (isLoading) {
    return <AdminQuoteDrawerSkeleton />;
  }

  return (
    <>
      {!quotes.length ? (
        <Box>No quotes to view</Box>
      ) : (
        <Box className={classes.container}>
          <AdminAccountsQuoteDrawerHeader
            headerContent={quotesHeaderContentProp}
          />
          {quotes.map((quote: any, index: number) => {
            const fileName =
              quote.product === ProductTypes.p250 &&
              quote.agencyStatus === BOUND_STATUS
                ? 'Binder'
                : 'Proposal';

            return (
              <RowDataWrapper key={index} index={index}>
                <Box className={classes.quoteNumber}>
                  {quote.ui_quoteNumber}
                </Box>
                <Box className={classes.quoteNumber}>{quote.agencyName}</Box>
                <Box className={classes.limit}>{quote.ui_limit}</Box>
                <Box className={classes.wideColumn}>{quote.ui_deductible}</Box>
                <Box className={classes.wideColumn}>{quote.ui_premium}</Box>
                <Box className={classes.wideColumn}>
                  {quote.ui_effectiveDate}
                </Box>
                <Box className={classes.wideColumn}>{quote.ui_endDate}</Box>
                <Box className={classes.wideColumn}>{quote.ui_status}</Box>
                <Box className={classes.actions}>
                  <DocumentDownloader
                    id={quote.accountId}
                    type=".pdf"
                    name={`${quote.companyName}-${quote.quoteNumber}-Cowbell-${fileName}`}
                    onDownload={() =>
                      downloadQuoteProposal(
                        quote.accountId,
                        quote.id,
                        quote.created
                      )
                    }
                  >
                    {({ handleDocumentDownload }) => (
                      <CloudDownload onClick={handleDocumentDownload} />
                    )}
                  </DocumentDownloader>
                </Box>
              </RowDataWrapper>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default QuoteTabView;

const { t, translationKeys } = getCowbellTranslations();
const region = getPlatformRegion();
const isUkRegion = !getIsUsPlatform(region);

const quotesHeaderContentProp = [
  {
    style: { width: '20%', textAlign: 'left', marginRight: '0.5rem' },
    text: 'Quote Number',
  },
  {
    style: { width: '20%', textAlign: 'left' },
    text: `${t(translationKeys.agency).toUpperCase()} NAME`,
  },
  {
    style: { width: '6%' },
    text: 'LIMIT',
  },
  {
    style: { width: '13%' },
    text: isUkRegion
      ? t(translationKeys.retention).toUpperCase()
      : 'DEDUCTIBLE',
  },
  {
    style: { width: '13%' },
    text: 'PREMIUM',
  },
  {
    style: { width: '13%' },
    text: 'EFF. DATE',
  },
  {
    style: { width: '13%' },
    text: 'EXP. DATE',
  },
  {
    style: { width: '13%' },
    text: 'STATUS',
  },
  {
    style: { width: '9%' },
    text: 'ACTIONS',
  },
];

const useStyles = makeStyles(({ palette }) => ({
  container: {
    border: `solid 1px ${palette.primary.border}`,
  },
  quoteNumber: {
    width: '20%',
    textAlign: 'left',
    marginRight: '0.5rem',
    overflow: 'hidden',
  },
  limit: {
    width: '6%',
  },
  wideColumn: {
    width: '13%',
  },
  actions: {
    width: '9%',
  },
}));
