import React from 'react';
import clsx from 'classnames';

import { Box } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { DropZone } from './DropZone';
import DefaultFileUploadPlaceholder from './DefaultFileUploadPlaceholder';
import { DefaultFilePreview } from './DefaultFilePreview';

export const Uploadzone = ({
  hasPreview,
  Placeholder,
  Preview: PreviewBase = DefaultFilePreview,
  onRemoveFile,
  files,
  isUploading,
  accept,
  previewProps = {},
  ...props
}) => {
  const classes = useStyles();

  return (
    <DropZone accept={accept} {...props}>
      {({ getRootProps, ...drops }) => {
        const zoneClasses = clsx(classes.dropzone, {
          accepted: drops.isDragAccept,
          rejected: drops.isDragReject,
          active: drops.isDragActive,
          disabled: props.disabled,
        });

        return (
          <>
            {hasPreview &&
              React.cloneElement(<PreviewBase />, {
                isUploading,
                rePickFilesFromOS: drops.open,
                onRemoveFile,
                files,
                ...previewProps,
              })}
            <Box {...getRootProps()} className={zoneClasses}>
              {!hasPreview &&
                (Placeholder || (
                  <DefaultFileUploadPlaceholder accept={accept} />
                ))}
            </Box>
          </>
        );
      }}
    </DropZone>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  dropzone: {
    '&:hover:not(.disabled)': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
    '&.disabled': {
      color: palette.text.disabled,
      background: palette.action.disabledBackground,
    },
    '&.active.accepted': {
      borderColor: palette.primary.main,
      '& $placeholder': {
        color: palette.primary.main,
      },
    },
    '&.active.rejected': {
      borderColor: palette.secondary.main,
      '& $placeholder': {
        color: palette.secondary.main,
      },
    },
  },
}));
