import React, { useContext, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import * as Ramda from 'ramda';

// mui
import {
  Box,
  IconButton as MuiIconButton,
  useTheme,
  Snackbar,
  Slide,
  IconButton,
  Card,
  CircularProgress,
} from '@mui/material';
import { makeStyles, styled, withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
// helpers
import Moment from 'moment';
import _ from 'lodash';
// components
import Numeral from 'numeral';
import { NoKnownLossLetterIndicator } from '../../../components/Reusable';
import { UrlDateRangePicker } from '../../../components/inputs/DateRangePicker';
import { withBackfill } from '../../../components/hocs/withBackfill';
import withRouter from '../../../components/hocs/withRouter';
import { FilterModal } from '../../../components/modals/FilterModal';
import { P100PolicyMidTermEndorsementDrawer } from '../../_global/endorsements/P100PolicyMidTermEndorsementDrawer';
import { TableFilterChips } from '../../../components/tables/tableFilters/TableFilterChips';
import ProtectedComponent from '../../../components/main/protected/ProtectedComponent';

import { UrlTable } from '../../../components/tables/tables-new/variations/url.table';
import { ExpandableRowContent } from '../../../components/tables/tables-new/variations/expanable-row-content.table';
import { ExpandableRow } from '../../../components/tables/tables-new/base/expandable-row.table';
import { ColumnCustomizer } from '../../../components/tables/tables-new/base/column-customizer/column-customizer.table';
import { withCsvDownload } from '../../../components/tables/tables-new/hocs/withCsvDownload';
import { withSaveSettings } from '../../../components/tables/tables-new/hocs/withSaveSettings';
import { TableContext } from '../../../components/tables/tables-new/base/context.table';
import { ActionBar } from '../../../components/tables/tables-new/base/action-bar.table';
import { ExpandIcon } from '../../../components/tables/tables-new/variations/expand-icon.table';

import { AdminPolicyOptions } from '../../../policies/options/AdminPolicyOptions';
import PolicyDrawer from '../../policies/drawers/policyholder/PolicyDrawer';
import SimpleTooltip from '../../../components/SimpleTooltip';
import FilterState from '../../../components/tables/tableFilters/FilterState';
import { withColumnValidation } from '../../../components/tables/tables-new/hocs/withColumnValidation';
import SearchBox from '../../../components/main/common/SearchBox';
import TableFilterSelect from '../../../components/tables/tableFilters/TableFilterSelect';
// platform helpers
import { delayedEvent, PubSub } from '../../../utils/eventUtils';
import { getFee, prepColumnFilters } from '../../../utils/data.utils';
import {
  useJQuery,
  YesNoOptions,
  RenewalOptions,
  phoneFormatter,
  getBoolValueText,
} from '../../../utils/appUtils';
import {
  getDateString,
  getShortDateString,
  getShortDateTimeFromUtc,
} from '../../../utils/csvUtils';
import { Note } from '../../../components/notes/notes.helper';
// actions
import { fetchBORPolicies } from '../../../policies/PolicyService';
import { fetchTams } from '../../../api/UserService';
import { getAggregators } from '../../../agencies/AgencyService';
// icons
import RefreshIcon from '../../../_assets/svg/Refresh';
import UWInboxAccNotes from '../../../_assets/svg/UWInboxAccNotes.svg';
import UWInboxNotes from '../../../_assets/svg/UWInboxNotes.svg';
// statics
import StateStatics from '../../_statics/states.statics.json';
import ProductStatics from '../../_statics/product.statics.json';
import { POLICY_STATUS_OPTIONS } from '../../_statics/quote.statics';
import { ClaimIndicator } from '../../../_assets/svg/claims/ClaimIndicator.svg';
import { selectGlobalTeam } from '../../../reducers/team.reducer';
import TableUpdatedSnackbarMessage from '../../../components/snackbars/messages/TableUpdatedSnackbarMessage';
import { ProductTypes } from '../../../types';
import PolicyDetailsAccordion from '../../../policies/PolicyDetailsAccordion';
import { formatTableCurrencyByRegion } from '../../../utils/currency.utils';
import { getCurrencySymbolByProduct } from '../../../i18n/utils';
import { getIsUsPlatform, useGetPlatformRegion } from '../../../utils';
import { useCowbellTranslations } from '../../../i18n/translations';

const EnhancedUrlTable = compose(
  withRouter,
  withSaveSettings('adminBorPolicies'),
  withColumnValidation({
    order: 'desc',
    orderBy: 'modified',
  })
)(UrlTable);

const styles = ({ config }) => ({
  options: {
    width: '30px',
    height: '25px',
    marginLeft: '18px',
    marginTop: '5px',
  },
  card: {
    padding: '0 1rem',
    marginBottom: '2rem',
  },
  link: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  status: {
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },
  close: {
    color: config.colors.darkGrey,
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit !important',
    },
  },
  progress: {
    zIndex: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  claimIndicatorParent: {
    '& tr': {
      position: 'relative',
    },
  },
});

const rqps = [
  'page',
  'size',
  'orderBy',
  'order',
  'search',
  'before',
  'after',
  'status',
];

const CsvDownloadButton = withCsvDownload('CowbellPolicies')((props) => {
  return (
    <MuiIconButton {...props}>
      <img src="images/Download.svg" alt="" className="table-ab-icon" />
    </MuiIconButton>
  );
});

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const useRowStyles = makeStyles(({ palette }) => ({
  root: {
    background: palette.tables.productRow,
    '&:hover': {
      background: palette.background.tableRowHover,
    },
  },
  open: {
    background: palette.background.tableRowHover,
    cursor: 'pointer',
  },
  endorse: {
    background: palette.tables.endorsement,
  },
}));

// ui

const AdminBORPolicies = compose(
  withRouter,
  withCsvDownload('adminBorPolicies'),
  withBackfill('uiSettings.adminBorPolicies', {
    size: 25,
    order: 'desc',
    orderBy: 'modified',
    before: () => Moment().endOf('day').unix(),
    after: () => Moment().subtract(1, 'year').startOf('day').unix(),
  }),
  withStyles(styles)
)(({ classes, q, updateUrl, ...props }) => {
  const team = useSelector(selectGlobalTeam);
  const theme = useTheme();
  const rowStyles = useRowStyles();
  const [showCircular, setShowCircular] = React.useState(false);
  const { isLoaded } = useJQuery();
  const columns = useColumns();

  const context = useMemo(
    () => ({
      setShowCircular,
      isLoaded,
      classes,
      theme,
      styles: {
        row: (row, rowProps) => {
          if (rowProps.expanded) {
            return { root: rowStyles.open };
          }
          if (row.isEndorsement) {
            return { root: rowStyles.endorse };
          }
          if (row.product === ProductTypes.p250) return rowStyles;
        },
      },
      rowsPerPageOptions: [25, 50, 100],
    }),
    [classes, isLoaded, rowStyles, theme]
  );

  useEffect(() => {
    updateUrl({ teamIds: team.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team.id]);

  const filterColumns = React.useMemo(() => {
    return prepColumnFilters(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showCircular && (
        <CircularProgress
          size="25rem"
          thickness="2.5"
          className={classes.progress}
        />
      )}
      <div className={classes.claimIndicatorParent}>
        <EnhancedUrlTable
          id="adminBorPolicies"
          columns={columns}
          context={context}
          delegate={fetch}
          rqps={rqps}
        >
          <ActionBar
            render={() => (
              <>
                <Card className={classes.card}>
                  <Box
                    mt={2}
                    mb={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FilterState
                      id="COWBELL_POLICY"
                      storeId="adminBorPolicies"
                      render={(filterProps, routeProps) => (
                        <>
                          <TableFilterSelect
                            filterProps={filterProps}
                            routeProps={routeProps}
                          />
                          <TableFilterChips
                            filterProps={filterProps}
                            routeProps={routeProps}
                          />
                        </>
                      )}
                    />

                    <Box className="action-bar flex--spaced" width="50%">
                      <SearchBox />
                      <UrlDateRangePicker q={q} inlineLabel />
                      <ColumnCustomizer />
                      <CsvDownloadButton />
                    </Box>
                  </Box>
                  <div id="portalOneContainer" />
                </Card>
              </>
            )}
          />

          <ExpandableRow
            render={(rowProps, ctx) => {
              return (
                <ProtectedComponent allow={{ policy: 'view' }} isAdmin>
                  {rowProps.expanded &&
                    (!rowProps.row.isEndorsement ? (
                      <ExpandableRowContent {...rowProps} ctx={ctx}>
                        <PolicyDetailsAccordion policy={rowProps.row} />
                      </ExpandableRowContent>
                    ) : (
                      <PolicyDrawer
                        policyId={rowProps.row.id}
                        product={rowProps.row.product}
                        open={rowProps.expanded}
                        onClose={() => {
                          PubSub.publish('table-row:expanded');
                        }}
                      >
                        {({ policy = {} }) => (
                          <Box
                            style={{
                              overflowY: 'auto',
                            }}
                          >
                            <P100PolicyMidTermEndorsementDrawer
                              policy={{ ...policy, ...policy.coverages }}
                            />
                          </Box>
                        )}
                      </PolicyDrawer>
                    ))}
                </ProtectedComponent>
              );
            }}
          />
        </EnhancedUrlTable>
      </div>
      <FilterModal
        tableId="COWBELL_POLICY"
        config={filterColumns}
        q={q}
        history={props.history}
        location={props.location}
      />
    </>
  );
});

const SubscribedCell = ({ event, children }) => {
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);

  useEffect(() => {
    const sub = PubSub.subscribe(event, (data) => {
      if (data.id === undefined) {
        setRefresh(data);
        setOpen(data);
        setTransition(() => TransitionLeft);
      }
    });
    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  return <>{children(refresh, open, setOpen, transition)}</>;
};

export const useColumns = () => {
  const region = useGetPlatformRegion();
  const isUsRegion = getIsUsPlatform(region);
  const { translations } = useCowbellTranslations(['Agent', 'Agency']);

  return [
    {
      name: 'id',
      label: 'ID',
      show: false,
    },
    {
      name: 'policyNumber',
      label: 'Policy Number',
      filterType: 'string',
      Cell: ({ row }) => {
        const { theme } = useContext(TableContext);

        return (
          <>
            {row.claimId && (
              <SimpleTooltip
                title={`${_.get(row, 'claimStatus', '')} - Claim#  ${
                  row.claimNumber
                }`}
              >
                <ClaimTag
                  color={
                    row.claimStatus === 'CLOSED'
                      ? theme.config.cowbellAccent
                      : 'salmon'
                  }
                />
              </SimpleTooltip>
            )}
            {row.policyNumber}
          </>
        );
      },
    },
    {
      name: 'premium',
      label: 'Premium',
      filterType: 'number',
      show: false,
      Cell: {
        C: Ramda.compose(
          formatTableCurrencyByRegion()('premium'),
          Ramda.prop('row')
        ),
      },
      csv: formatTableCurrencyByRegion()('premium'),
    },
    {
      name: 'brokerFee',
      label: 'Fee',
      filterType: 'number',
      show: false,
      sort: false,
      Cell: {
        C: ({ row }) => {
          return getFee(row);
        },
      },
      csv: (row) => {
        return getFee(row);
      },
    },
    {
      name: 'totalPremium',
      label: 'Total Premium',
      filterType: 'number',
      Cell: {
        C: Ramda.compose(
          formatTableCurrencyByRegion()('totalPremium'),
          Ramda.prop('row')
        ),
      },
      csv: formatTableCurrencyByRegion()('totalPremium'),
    },
    {
      name: 'companyName',
      label: 'Company Name',
      filterType: 'string',
      ommitInCsv: true,
    },
    ...(isUsRegion
      ? [
          {
            name: 'firmographicData.state',
            label: 'Account State',
            filterType: 'list',
            sort: false,
            show: false,
            getListOptions: async () => StateStatics.full,
            Cell: {
              C: ({ row }) => _.get(row, 'firmographicData.state', '-'),
            },
            csv: (row) => _.get(row, 'firmographicData.state', '-'),
          },
        ]
      : []),
    {
      name: 'customerEmail',
      label: 'Customer Email',
      filterType: 'string',
      show: false,
    },
    {
      name: 'customerFirstName',
      label: 'Customer Name',
      filterType: 'string',
      Cell: {
        C: ({ row }) => {
          return `${row.customerFirstName ? row.customerFirstName : ''} ${
            row.customerLastName ? row.customerLastName : ''
          }`;
        },
      },
      csv: (row) => {
        return `${row.customerFirstName ? row.customerFirstName : ''} ${
          row.customerLastName ? row.customerLastName : ''
        }`;
      },
    },
    {
      name: 'firmographicData.daId',
      label: 'Aggregators',
      filterType: 'list',
      show: false,
      getListOptions: () => {
        return getAggregators().then(({ data }) => {
          return Object.keys(data).map((key) => ({
            label: data[key],
            value: key,
          }));
        });
      },
    },
    {
      name: 'customerPhone',
      label: 'Customer Phone Number',
      filterType: 'string',
      show: false,
      Cell: ({ row }) => phoneFormatter(row.customerPhone, '-'),
    },
    {
      name: 'product',
      label: 'Product',
      filterType: 'list',
      getListOptions: async () => ProductStatics.PRODUCT_OPTIONS,
      show: false,
    },
    {
      name: 'isSurplus',
      label: 'Is Surplus?',
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      show: false,
      sort: false,
      Cell: {
        C: ({ row }) => (row.isSurplus ? 'S' : 'A'),
      },
      csv: (row) => (row.isSurplus ? 'S' : 'A'),
    },
    {
      name: 'isRenewal',
      label: 'Is Renewal?',
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(RenewalOptions)),
      show: false,
      Cell: {
        C: ({ row }) => (row.isRenewal ? 'Renewal' : 'New'),
      },
      csv: (row) => (row.isRenewal ? 'Renewal' : 'New'),
    },
    {
      name: 'isMigration',
      label: 'Is Migration?',
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(YesNoOptions)),
      show: false,
      Cell: {
        C: ({ row }) => getBoolValueText(row.isMigration),
      },
      csv: (row) => getBoolValueText(row.isMigration),
    },
    {
      name: 'isEndorsement',
      label: 'Is Endorsement?',
      show: false,
      filterType: 'bool',
      getListOptions: () => new Promise((resolve) => resolve(YesNoOptions)),
      Cell: {
        C: ({ row }) => getBoolValueText(row.isEndorsement),
      },
      csv: (row) => getBoolValueText(row.isEndorsement),
    },
    {
      name: 'isPrimePlus',
      label: 'Is PrimePlus?',
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      show: false,
      sort: false,
      Cell: {
        C: ({ row }) => getBoolValueText(row.isPrimePlus),
      },
      csv: (row) => getBoolValueText(row.isPrimePlus),
    },
    {
      name: 'agencyBilled',
      label: `${translations.Agency} Billed?`,
      show: false,
      sort: false,
      Cell: {
        C: ({ row }) =>
          getBoolValueText(_.get(row, 'firmographicData.agencyBilled', false)),
      },
      csv: (row) =>
        getBoolValueText(_.get(row, 'firmographicData.agencyBilled', false)),
    },
    {
      name: 'isPremiumFinanced',
      label: 'Premium Financed?',
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      show: false,
      Cell: {
        C: ({ row }) => getBoolValueText(row.isPremiumFinanced),
      },
      csv: (row) => getBoolValueText(row.isPremiumFinanced),
    },
    {
      name: 'isPremiumFinancePaid',
      label: 'Premium Financed Paid?',
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      show: false,
      Cell: {
        C: ({ row }) => getBoolValueText(row.isPremiumFinancePaid),
      },
      csv: (row) => getBoolValueText(row.isPremiumFinancePaid),
    },
    {
      name: 'isPaid',
      label: 'Is Paid?',
      filterType: 'bool',
      getListOptions: async () => YesNoOptions,
      show: false,
      Cell: {
        C: ({ row }) => getBoolValueText(row.isPaid),
      },
      csv: (row) => getBoolValueText(row.isPaid),
    },

    {
      name: 'agencyId',
      label: `${translations.Agency} ID`,
      filterType: 'string',
      show: false,
    },
    {
      name: 'agencyName',
      label: `${translations.Agency} Name`,
      filterType: 'string',
      Cell: {
        C: ({ row }) => {
          const { classes } = useContext(TableContext);
          return (
            <a
              className={classes.link}
              href={`/admin/policies?search=${row.agencyName}`}
            >
              {row.agencyName}
            </a>
          );
        },
      },
    },
    {
      name: 'agent',
      label: translations.Agent,
      show: false,
      Cell: ({ row }) =>
        `${_.get(row, 'agentFirstName', '')} ${_.get(
          row,
          'agentLastName',
          ''
        )}`,
    },
    {
      name: 'description',
      label: 'Description',
      filterType: 'string',
      Cell: {
        C: ({ row }) => {
          let desc = row.description || '-';
          desc = desc.length > 100 ? `${desc.substr(0, 98)} ...` : desc;
          return desc;
        },
      },
    },
    {
      name: 'effectiveDate',
      label: 'Policy Effective',
      filterType: 'date',
      show: false,
      Cell: {
        C: ({ row }) => {
          const invalidStatus = ['DELETED'];

          const dt = _.get(row, 'effectiveDate');
          if (invalidStatus.includes(row.customerStatus)) {
            return '-';
          }

          return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
        },
      },

      csv: (row) => {
        const invalidStatus = ['DELETED'];

        const dt = _.get(row, 'effectiveDate');
        if (invalidStatus.includes(row.customerStatus)) {
          return '-';
        }
        return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
      },
    },
    {
      name: 'endDate',
      label: 'Policy Expiration',
      filterType: 'date',
      show: false,
      Cell: {
        C: ({ row }) => {
          const invalidStatus = ['DELETED'];

          const dt = _.get(row, 'endDate');
          if (invalidStatus.includes(row.customerStatus)) {
            return '-';
          }

          return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
        },
      },

      csv: (row) => {
        const invalidStatus = ['DELETED'];

        const dt = _.get(row, 'endDate');
        if (invalidStatus.includes(row.customerStatus)) {
          return '-';
        }
        return dt ? getShortDateTimeFromUtc(Moment(dt).utc()) : '-';
      },
    },
    {
      name: 'timestamp',
      label: 'Created',
      filterType: 'date',
      show: false,
      Cell: {
        C: ({ row }) => {
          return row.created ? getDateString(row.created) : '-';
        },
      },
      csv: (row) => getDateString(row.created),
    },
    {
      name: 'boundOn',
      label: 'Bound',
      filterType: 'date',
      show: false,
      Cell: {
        C: ({ row }) => {
          return row.boundOn ? getShortDateTimeFromUtc(row.boundOn) : '-';
        },
      },
      csv: (row) => getShortDateTimeFromUtc(row.boundOn),
    },
    {
      name: 'limit',
      label: 'Limit',
      filterType: 'number',
      Head: {
        align: 'right',
      },
      Cell: {
        align: 'right',
        C: ({ row }) => {
          const currencySymbol = getCurrencySymbolByProduct(row.product);
          return _.isUndefined(row.limit)
            ? '-'
            : `${currencySymbol}${Numeral(row.limit).format('0a')}`;
        },
      },
      csv: (row) => {
        const currencySymbol = getCurrencySymbolByProduct(row.product);
        return _.isUndefined(row.limit)
          ? ''
          : `${currencySymbol}${Numeral(row.limit).format('0a')}`;
      },
    },
    {
      name: 'modified',
      label: 'Modified',
      filterType: 'date',
      Cell: {
        C: ({ row }) => {
          return row.modified ? Moment(row.modified).fromNow() : '-';
        },
      },
      csv: (row) => getShortDateString(row.modified),
    },
    {
      name: 'quoteNumber',
      label: 'Quote Number',
      filterType: 'string',
      show: false,
      Cell: {
        C: ({ row }) => {
          const { classes } = useContext(TableContext);
          return (
            <a
              className={classes.link}
              href={`/admin/quotes?search=${row.quoteNumber}`}
            >
              {row.quoteNumber}
            </a>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      filterType: 'list',
      getListOptions: async () => POLICY_STATUS_OPTIONS,
      Cell: {
        C: ({ row }) => {
          const { status, noKnownLossLetterReq } = row;
          const { classes } = useContext(TableContext);

          return (
            <div className={classes.status}>
              {status}
              <NoKnownLossLetterIndicator status={noKnownLossLetterReq} />
            </div>
          );
        },
      },
    },
    {
      name: 'notes',
      label: 'Notes',
      sort: false,
      Head: {
        align: 'center',
      },
      Cell: {
        C: ({ row }) => {
          const attachmentMetaData = {
            accountId: row.accountId,
            agencyId: row.agencyId || _.get(row, 'agencies[0].agencyId', null),
            companyName: row.name,
          };

          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <SimpleTooltip title="Account Notes ">
                <UWInboxAccNotes
                  onClick={(event) => {
                    event.stopPropagation();
                    Note.show('account', {
                      data: { accountId: row.accountId, attachmentMetaData },
                    });
                  }}
                />
              </SimpleTooltip>

              {row.quoteId && (
                <SimpleTooltip title="Quote Notes">
                  <UWInboxNotes
                    onClick={(event) => {
                      event.stopPropagation();
                      Note.show('quote', {
                        data: { quoteId: row.quoteId, product: row.product },
                      });
                    }}
                  />
                </SimpleTooltip>
              )}
            </div>
          );
        },
      },
    },
    ...(isUsRegion
      ? [
          {
            label: 'State Tax',
            name: 'stateTax',
            sort: true,
            show: false,
            filterType: 'number',
            Cell: {
              C: Ramda.compose(
                formatTableCurrencyByRegion()('stateTax'),
                Ramda.prop('row')
              ),
            },
            csv: formatTableCurrencyByRegion()('stateTax'),
          },
        ]
      : []),
    {
      name: 'firmographicData.tamEmail',
      label: 'TSM Email',
      filterType: 'list',
      getListOptions: () =>
        fetchTams()
          .then(({ data }) => {
            if (data.content.length) {
              return data.content.map(({ email }) => ({
                label: email,
                value: email,
              }));
            }
            return [];
          })
          .catch(() => []),
      show: false,
      Cell: {
        C: ({ row }) => _.get(row, 'firmographicData.tamEmail', '-'),
      },
      csv: (row) => _.get(row, 'firmographicData.tamEmail', '-'),
    },
    {
      name: 'options',
      canToggle: false,
      sort: false,
      Cell: {
        C: ({ row }) => {
          const { setShowCircular, isLoaded } = useContext(TableContext);
          const status = [
            'VOID',
            'CANCELLED',
            'INVALID',
            'EXPIRED',
            'CANCEL PENDING',
            'REQUESTED',
            'FLAT CANCELLED',
          ];
          // cowbell policy and cowbell bor table should have the same menu options at all times.
          return (
            !status.includes(row.status) && (
              <AdminPolicyOptions
                data={row}
                handleTableRefetch={makeRefreshCall}
                setShowCircular={setShowCircular}
                isJQueryLoaded={isLoaded}
              />
            )
          );
        },
      },
    },
    {
      name: 'actions',
      canToggle: false,
      sort: false,
      Head: () => {
        const { classes, theme } = useContext(TableContext);

        return (
          <SubscribedCell event="policy-refresh:show">
            {(refresh, open, setOpen, transition) => {
              function onClose() {
                setOpen(false);
              }
              return (
                <>
                  <IconButton
                    onClick={makeRefreshCall}
                    className={classes.refreshContainer}
                    size="small"
                  >
                    <RefreshIcon
                      fill={
                        !refresh
                          ? theme.palette.text.primary
                          : theme.palette.snackBar.primary
                      }
                    />
                  </IconButton>
                  <Snackbar
                    open={open}
                    autoHideDuration={5000}
                    onClose={onClose}
                    TransitionComponent={transition}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    message={
                      <TableUpdatedSnackbarMessage
                        onRefreshClick={makeRefreshCall}
                      />
                    }
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={onClose}
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  />
                </>
              );
            }}
          </SubscribedCell>
        );
      },
      Cell: {
        width: 50,
        C: ({ row }) => {
          return (
            <ProtectedComponent allow={{ policy: 'view' }} isAdmin>
              <ExpandIcon id={row.id} />
            </ProtectedComponent>
          );
        },
      },
    },
  ];
};

const ClaimTag = styled(ClaimIndicator)({
  position: 'absolute',
  top: 0,
});

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, 'adminBorPolicies');
  PubSub.publish('policy-refresh:show', false);
}

function fetch(q) {
  return fetchBORPolicies(q).then(({ data }) => ({
    data: data.content,
    count: data.totalElements,
  }));
}

export default AdminBORPolicies;
