import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { EnhancedTable } from './enhanced.table';
import withRouter from '../../../hocs/withRouter';
import { PubSub } from '../../../../utils/eventUtils';

const UrlTableLocal = ({
  updateUrl,
  delegate,
  onChangePageSize,
  onSort,
  paginationVersion = 'v1',
  ...props
}) => {
  const [dataState, setData] = useState([]);
  const [countState, setCount] = useState(props.count);
  const [loading, setLoading] = useState(true);
  const nToken = useRef();
  const pToken = useRef();

  useEffect(() => {
    function fetchData() {
      setLoading(true);
      delegate(props.q, props.context)
        .then(({ data, count, nextToken, prevToken }) => {
          setLoading(false);
          setData(data);
          setCount(count);

          if (paginationVersion === 'v2') {
            nToken.current = nextToken;
            pToken.current = prevToken;
          }

          PubSub.publish('table-fetch:success', {
            id: props.id,
            search: props.q,
            count,
          });
        })
        .catch((error) => {
          setLoading(false);
          PubSub.publish('table-fetch:failed', { id: props.id, error });
        });
    }

    const refetch = PubSub.subscribe('table-refetch', (id) => {
      if (props.id === id) {
        fetchData();
      }
    });

    fetchData();

    return () => {
      refetch.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.q]);

  const handleSort = useCallback(
    (name, order) => {
      updateUrl({
        orderBy: name,
        order,
      });

      if (_.isFunction(onSort)) {
        onSort(name, order);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateUrl]
  );

  const onPageChange = useCallback(
    (e, page) => {
      if (!_.isNull(e)) {
        if (paginationVersion === 'v2' && (nToken.current || pToken.current)) {
          updateUrl({
            page,
            searchAfter:
              (Number(props.q.page) || 0) < page
                ? nToken.current
                : pToken.current,
            pageRequested: props.q.page > page ? 'PREV' : 'NEXT',
          });
        } else {
          updateUrl({ page });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.q]
  );

  const handleChangeRowsPerPage = useCallback(
    ({ target: { value } }) => {
      updateUrl({
        size: value,
        searchAfter: undefined,
        pageRequested: undefined,
      });

      if (_.isFunction(onChangePageSize)) {
        onChangePageSize(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateUrl]
  );

  const onRefresh = useCallback(() => {
    PubSub.publish('table-refetch', props.id);
  }, [props.id]);

  return (
    <>
      <EnhancedTable
        columns={props.columns}
        loading={loading}
        {...props}
        data={dataState}
        context={{
          rowsPerPage: Math.min(Number(props.q.size), 100),
          rowsPerPageOptions: props.rowsPerPageOptions,
          totalRows: countState,
          onPageChange,
          changePageSize: handleChangeRowsPerPage,
          ...props.context,
          updateUrl,
          ...props.q,
          onRefresh,
          onSort: handleSort,
        }}
      />
    </>
  );
};

UrlTableLocal.defaultProps = {
  columns: [],
  data: [],
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  count: 0,
  rqps: ['size', 'page', 'orderBy', 'order', 'searchAfter', 'pageRequested'],
};

/* do not remove this function, will revisit */
/* function compareProps(prevProps, props) {
  const prevqs = _.pick(prevProps.q, props.rqps);
  const qs = _.pick(props.q, props.rqps);

  return _.isEqual(prevqs, qs);
} */

export const UrlTable = withRouter(UrlTableLocal);
