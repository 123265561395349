import React from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form-4';
import { makeStyles } from '@mui/styles';

// components
import _ from 'lodash';
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import {
  RegisteredTypeAhead,
  ManagedTypeAheadBase as TypeAheadBase,
  ManagedTypeAheadBase,
} from '../../components/inputs/autocomplete';

// services
import { getAgents, getAgentsInAdmin } from '../../api/UserService';
import { ManagedFetchingAutoComplete } from '../../components/inputs/autocomplete/ManagedFetchingAutoComplete';

const defaultLabelProps = {
  style: { paddingTop: 0 },
};

export const AgentAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  showLabel,
  ...props
}) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(({ input }, callback) => {
    return getAgents({ search: input }).then((response) =>
      callback(
        response.data.content.map((agent) => ({
          label: `${agent.firstName} ${agent.lastName} (${agent.email})`,
          value: agent.id,
          meta: {
            agent,
          },
        }))
      )
    );
  }, []);

  return (
    <>
      <InputLabelBase
        indent
        htmlFor={props.name}
        required={props.required}
        show={showLabel}
        {...labelProps}
      >
        {label || 'Select New Agent'}
      </InputLabelBase>
      <TypeAheadBase
        id="newAgent"
        className={inputClasses}
        onFetch={handleSearch}
        filterOptions={filterOptions}
        {...props}
      />
    </>
  );
};

export const RegisteredAgentAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  showLabel,
  ...props
}) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(({ input }, callback) => {
    return getAgents({ search: `${input}` }).then((response) =>
      callback(
        response.data.content.map((agent) => ({
          label: `${agent.firstName} ${agent.lastName} (${agent.email})`,
          value: agent.id,
          meta: {
            ...agent,
          },
        }))
      )
    );
  }, []);

  return (
    <>
      <InputLabelBase
        indent
        htmlFor={props.name}
        required={props.required}
        show={showLabel}
        {...labelProps}
      >
        {label || 'Select New Agent'}
      </InputLabelBase>
      <RegisteredTypeAhead
        id="newAgent"
        className={inputClasses}
        onFetch={handleSearch}
        filterOptions={filterOptions}
        {...props}
      />
    </>
  );
};

function filterOptions(options) {
  return options;
}

const useStyles = makeStyles(() => ({
  inline: {
    '& input': {
      padding: '6px !important',
    },
  },
}));

export const AgentInAdminAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  showLabel = false,
  agencyId,
  defaultOptions,
  ...props
}) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const { setValue } = useFormContext();
  const [options, setOptions] = React.useState(defaultOptions);

  function handleChange(newValue) {
    setValue(props.name, newValue);
  }

  const onFetch = React.useCallback(
    ({ input }, callback) => {
      if (!agencyId) {
        return;
      }
      return getAgentsInAdmin({ agencyId, search: `${input}` }).then(
        (response) =>
          callback(
            response.data.content.map((agent) => ({
              label: `${agent.firstName} ${agent.lastName} (${agent.email})`,
              value: agent.email,
              meta: {
                ...agent,
              },
            }))
          )
      );
    },
    [agencyId]
  );

  function onOptionsChange(newOptions) {
    setOptions(newOptions);
  }

  return (
    <>
      <InputLabelBase
        indent
        htmlFor={props.name}
        required={props.required}
        show={showLabel}
        {...labelProps}
      >
        {label || 'Select New Agent'}
      </InputLabelBase>
      <TypeAheadBase
        id="agent"
        options={options}
        onChange={handleChange}
        onOptionsChange={onOptionsChange}
        onFetch={onFetch}
        className={inputClasses}
        {...props}
      />
    </>
  );
};

export const ManagedAgentInAdminAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  agencyId,
  ...props
}) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(
    _.debounce(({ input }, callback) => {
      return getAgentsInAdmin({ agencyId, search: `${input}` }).then(
        (response) =>
          callback(
            response.data.content.map((agent) => ({
              label: `${agent.firstName} ${agent.lastName} (${agent.email})`,
              value: agent.id,
              meta: {
                ...agent,
              },
            }))
          )
      );
    }, 500),
    [agencyId]
  );

  return (
    <ManagedTypeAheadBase
      label={label}
      className={inputClasses}
      onFetch={handleSearch}
      {...props}
    />
  );
};

export const ManagedAgentByAgencyAutoComplete = ({ agencyId, ...props }) => {
  const queryKey = ['agency-agents-options', agencyId];
  function fetchFuncton({ search }) {
    if (!agencyId) return Promise.resolve([]);

    return getAgentsInAdmin({ agencyId, search }).then((res) =>
      res.data.content.map((agent) => ({
        ...agent,
        name: `${agent.firstName} ${agent.lastName} (${agent.email})`,
        id: agent.id,
      }))
    );
  }

  return (
    <ManagedFetchingAutoComplete
      {...props}
      queryKey={queryKey}
      fetchFunc={fetchFuncton}
    />
  );
};
