import React from 'react';
import { useForm } from 'react-hook-form-4';
// mui
import { DialogContent, FormHelperText, DialogActions } from '@mui/material';
// actions
import { resetMfa } from '../../api/AuthService';
// components
import withRouter from '../hocs/withRouter';
import CbButton from '../Buttons/CbButton';
import { manageAPIError } from '../../utils';

export const ResetMfaConfirm = withRouter(({ data, close, history }) => {
  const [error, setError] = React.useState('');
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const handleCancel = () => {
    close();
    history.push('/login');
  };

  const onSubmit = () => {
    resetMfa({ username: data.username, password: data.password })
      .then(() => {
        close();
      })
      .catch((error) => {
        const errorMessage = manageAPIError(
          error,
          'Cannot reset password at this time.Please try again later.'
        );

        setError(errorMessage);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent className="modal-title">
        Are you sure you’d like to reset your MFA setting?
        <FormHelperText className="api-text-error">{error}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <CbButton
          buttonText="Cancel"
          styleName="cancel"
          onClick={handleCancel}
        />
        <CbButton
          buttonText="Reset MFA"
          styleName="ctaButton"
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </DialogActions>
    </form>
  );
});

export default ResetMfaConfirm;
