import React from 'react';
import { useDropzone } from 'react-dropzone';

export const DropZone = ({ children, inputProps, ...props }) => {
  const { getInputProps, ...dropProps } = useDropzone(props);

  return (
    <>
      <input {...getInputProps()} {...inputProps} />
      {children ? children(dropProps) : null}
    </>
  );
};
