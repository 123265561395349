import type { AxiosResponse } from 'axios';

import type { AggregatedP500FormData } from '../CreateEditPrimePlusPolicyModal';
import { transformToP500Request } from '../utils/transformers';

import {
  createPrimecloudPolicy,
  updatePrimecloudPolicy,
} from '../../../../api/policies.api';

import type { AccountDto } from '../../../../types';

export function makePrimecloudPolicyRequest(
  formState: AggregatedP500FormData,
  accountData: Pick<AccountDto, 'id' | 'name'>,
  policyId?: string
): Promise<AxiosResponse<any>> {
  const requestPayload = transformToP500Request(
    formState,
    accountData.id,
    accountData.name
  );

  return policyId
    ? updatePrimecloudPolicy(policyId, { data: requestPayload })
    : createPrimecloudPolicy({ data: requestPayload });
}
