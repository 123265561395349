import React from 'react';
// mui
import { DialogActions, DialogContent } from '@mui/material';
import { withStyles } from '@mui/styles';

// components
import CbButton from '../components/Buttons/CbButton';

const styles = ({ config }) => {
  return {
    dialog: {
      paddingTop: '4.58rem !important',
      fontWeight: config.weights.bold,
      fontSize: config.textSizes.primer,
    },
  };
};

export const RenewalQuoteIssued = withStyles(styles)(
  ({ classes, close, data }) => {
    return (
      <form>
        <DialogContent className={`${classes.dialog} modal-title`}>
          Renewal Quote is already issued for policy# {data.policyNumber}.
          Please edit the existing quote to make any changes.
        </DialogContent>
        <DialogActions>
          <CbButton
            styleName="ctaButton"
            buttonText="OK"
            action={close}
            size="small"
          />
        </DialogActions>
      </form>
    );
  }
);

export const RenewalQuoteIssuedConfig = {
  RenewalQuoteIssued: {
    component: RenewalQuoteIssued,
    config: {
      fullWidth: false,
      maxWidth: 'sm',
      override: false,
      title: 'Renewal Quote Exists',
    },
  },
};
