import { useState, useEffect } from 'react';
import {
  DialogActions,
  DialogContent,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm, FormContext } from 'react-hook-form-4';
import { useSnackbar } from 'notistack';
/* component */
/* Actions */
import ReactCodeInput from 'react-code-input';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { getQRCode, verifyQR } from '../../api/AuthService';

import { InputLabelBase } from '../inputs/InputLabelBase';
import CbButton from '../Buttons/CbButton';
import { withFormController } from '../hocs/withFormController';

import { manageAPIError } from '../../utils';
import { useMeQuery } from '../hooks/useMeQuery';
import { useActor } from '../hooks/useActor';
import { usePersona } from '../hooks/usePersona';
import { useToggleModal } from '../../utils/modal.utils';

const CodeInput = withFormController(ReactCodeInput);

export default ({ close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const queryClient = useQueryClient();
  const history = useHistory();
  const actor = useActor();
  const persona = usePersona();

  const [url, setUrl] = useState('');
  const [apiError, setApiError] = useState('');
  const classes = useClasses();
  const {
    formState: { isSubmitting },
    handleSubmit,
    ...methods
  } = useForm();

  useEffect(() => {
    const qr = setTimeout(() => {
      getQRCode('GOOGLE')
        .then((response) => {
          setUrl(response.data);
        })
        .catch((error) => {
          const errorMessage = manageAPIError(
            error,
            'An error has occurred fetching the QR Code. Please try again after some time.'
          );

          setApiError(errorMessage);
        });
    }, 250);

    return () => {
      clearTimeout(qr);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const time = setTimeout(() => {
      if (!url) {
        setApiError(
          'Please try again after sometime! The QR code is taking time to load!'
        );
      }
    }, 30000);
    return () => {
      clearTimeout(time);
    };
    // eslint-disable-next-line
  }, [url]);

  const onSubmit = (formValues) => {
    return verifyQR({
      mfaType: 'GOOGLE',
      mfa1: formValues.firstCode,
      mfa2: formValues.secondCode,
    })
      .then(() => {
        toggleModal.direct('MfaEnabled', false);
        enqueueSnackbar('MFA Enabled Successfully!', { variant: 'success' });
        queryClient.invalidateQueries([useMeQuery.QUERY_KEY]);
        if (actor.isInsured && persona.isInsured) {
          setTimeout(() => {
            history.push('/customer/policies');
          }, 2000);
        }
      })
      .catch((error) => {
        setApiError(error.response.data);
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className="modal-title" style={{ textAlign: 'inherit' }}>
          <p className={classes.margins}>
            Step 1:{' '}
            <span className="modal-blue-text">
              Download and open an authenticator app on your phone.
            </span>
          </p>
          <p className={classes.margins}>
            Step 2:{' '}
            <span className="modal-blue-text">
              Scan the QR code below or enter manually, keep time based on.
            </span>
          </p>
          <p className={classes.margins}>
            Step 3:{' '}
            <span className="modal-blue-text">
              Enter the first 6-digit code, and the second 6-digit code.
            </span>
          </p>
          <div className={classes.container}>
            {url ? (
              <img src={url} alt="" width="200" height="200" />
            ) : (
              <div className={classes.qrPlaceholder}>
                {apiError !== '' ? null : (
                  <>
                    <span className={classes.loadingText}>Loading</span>
                    <CircularProgress
                      classes={{
                        root: `${classes.loadingIconRoot}`,
                        circle: `${classes.circles}`,
                      }}
                    />
                  </>
                )}
              </div>
            )}
            <div className={classes.code}>
              <div className={classes.code}>
                <InputLabelBase
                  required
                  style={{ padding: '0 1rem', fontSize: '1rem' }}
                >
                  Enter First Code
                </InputLabelBase>
                <CodeInput
                  name="firstCode"
                  type="text"
                  autoFocus
                  className={classes.in}
                  fields={6}
                />
              </div>
              <div className={classes.code}>
                <InputLabelBase
                  required
                  style={{ padding: '0 1rem', fontSize: '1rem' }}
                >
                  Enter Second Code
                </InputLabelBase>
                <CodeInput
                  name="secondCode"
                  type="text"
                  autoFocus={false}
                  className={classes.in}
                  fields={6}
                />
              </div>
            </div>
          </div>
          <FormHelperText className="api-text-error">{apiError}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" action={close}>
            Cancel
          </CbButton>
          <CbButton
            loading={isSubmitting}
            styleName="ctaButton"
            buttonText="Enable MFA"
            type="submit"
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

const useClasses = makeStyles(({ config, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingTop: '3rem',
  },
  code: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  qrPlaceholder: {
    width: 200,
    height: 200,
  },
  loadingText: {
    position: 'relative',
    left: 80,
    top: 65,
  },
  loadingIconRoot: {
    width: '9.16rem !important',
    height: '9.16rem !important',
    position: 'absolute',
    marginTop: 30,
  },
  circles: {
    '&.MuiCircularProgress-circle': { strokeWidth: 1 },
  },
  in: {
    '& input': {
      borderRadius: 5,
      border: `solid 1px ${config.colors.almostWhite}`,
      background: palette.common.darkBlue,
      marginLeft: 5,
      color: config.colors.white,
      fontSize: config.textSizes.paragon,
      textAlign: 'center',
      width: 36,
      height: 42,
    },
  },
  margins: {
    margin: 0,
  },
}));
