import React from 'react';

import { DialogActions, DialogContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FormContext, useForm } from 'react-hook-form-4';
import Moment from 'moment';
import _ from 'lodash';
import CBButton from '../../../../../components/Buttons/CbButton';
import { setPrime250Ui } from '../../../../_reducers/prime250.reducer';
import CheckBoxSelectedIcon from '../../../../../_assets/svg/CheckBoxSelectedIcon.svg';
import CheckBoxOutlineBlankIcon from '../../../../../_assets/svg/CheckBoxOutlineBlankIcon.svg';
import { CustomCheckbox } from '../../../../../components/inputs';
import { Modal } from '../../../../../components/modals/v2/helpers/v2.modal.helpers';

const UWAdditionalNamedInsuredList = ({
  additionalNamedInsured,
  classes,
  toggleHandler,
  selectedAdditionalNamedInsured,
  ...props
}) => {
  return additionalNamedInsured.map((name) => {
    return (
      <section className={classes.row}>
        <CustomCheckbox
          label={name}
          onChange={toggleHandler(name)}
          checked={selectedAdditionalNamedInsured.indexOf(name) > -1}
        />
      </section>
    );
  });
};

export const UWAdditionalNamedInsuredModal = ({ data, ...props }) => {
  const { additionalNamedInsured, dispatch, accountName } = data;
  const classes = useClasses();
  const { handleSubmit, ...methods } = useForm();
  const [selectedAdditionalNamedInsured, setSelectedAdditionalNamedInsured] =
    React.useState(data.selectedAdditionalNamedInsured);
  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = () => {
    dispatch(
      setPrime250Ui({
        selectedAdditionalNamedInsured,
        shouldRecalculate: Moment().unix(),
      })
    );
    Modal.hide();
  };

  const toggleHandler = React.useMemo(() => {
    return (name) => {
      return (event, selected) => {
        setSelectedAdditionalNamedInsured((prevState) => {
          return selected ? prevState.concat(name) : _.without(prevState, name);
        });
      };
    };
    // eslint-disable-next-line
  }, []);

  return (
    <section>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent classes={{ root: classes.container }}>
            <h3 className={classes.accountName}>{accountName}</h3>
            <Grid container className={classes.legends}>
              <section className={classes.legend}>
                <div className={classes.blueBox} />
                Selected Prior
              </section>
              <section className={classes.legend}>
                <CheckBoxSelectedIcon />
                Selected Now
              </section>
              <section className={classes.legend}>
                <CheckBoxOutlineBlankIcon fill="rgba(255, 255, 255, 0.5)" />
                Available
              </section>
            </Grid>
            <section className={classes.rowsContainer}>
              <UWAdditionalNamedInsuredList
                additionalNamedInsured={additionalNamedInsured}
                classes={classes}
                toggleHandler={toggleHandler}
                selectedAdditionalNamedInsured={selectedAdditionalNamedInsured}
              />
            </section>
          </DialogContent>
          <DialogActions>
            <CBButton onClick={Modal.hide} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Save Changes"
            />
          </DialogActions>
        </form>
      </FormContext>
    </section>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  legend: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '1rem',
  },
  row: {
    borderTop: `solid 1px ${palette.primary.modalBorder}`,
    width: '100%',
    height: '3rem',
  },
  rowsContainer: {
    width: '100%',
    maxHeight: '50rem',
    overflow: 'scrollable',
    fontSize: '1rem',
    lineHeight: 1.75,
  },
  blueBox: {
    width: '0.833rem',
    height: '0.833rem',
    backgroundColor: palette.background.button,
    marginRight: '0.5rem',
  },
  legends: {
    fontSize: '0.833rem',
    lineHeight: 1.5,
    color: palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    marginBottom: '1rem',
  },
  container: {
    maxHeight: '45rem',
    overflow: 'auto',
    paddingBottom: '1rem',
    color: palette.primary.contrastText,
  },
  accountName: {
    fontSize: '1.75rem',
    lineHeight: 1.38,
    color: palette.primary.main,
    margin: 0,
    fontWeight: 500,
    padding: '1.5rem 0 1rem 0',
  },
}));

export const UWAdditionalNamedInsuredConfig = {
  UWAdditionalNamedInsuredModal: {
    component: UWAdditionalNamedInsuredModal,
    config: {
      maxWidth: 'md',
      inheritTheme: 'dark',
      title: 'Add/Edit Additional Named Insured',
    },
  },
};
