import React, { useState } from 'react';
import _ from 'lodash';
import Color from 'color';
import moment from 'moment';
import { useSnackbar } from 'notistack';
// mui
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Box,
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';

// components
import { compose } from 'redux';
import { toggleModalDirect } from '../../../utils/storeUtils';
import { Checkbox } from '../../../components/inputs/index';
import withRouter from '../../../components/hocs/withRouter';

import CbButton from '../../../components/Buttons/CbButton';
// service
import { downloadAWSDoc } from '../../../accounts/AccountService';
import {
  downloadQuoteDocuments,
  attestTermsOnQuote,
} from '../../../inbox/QuotesService';
import { saveBlobFile } from '../../../utils/appUtils';
import { manageAPIError } from '../../../utils';
import { useCowbellTranslations } from '../../../i18n/translations';

const useStyles = makeStyles(({ palette }) => ({
  attestCheckbox: {
    top: 0,
    left: 5,
    marginRight: 5,
    position: 'relative',
    color: palette.common.cowbellBlue,

    '& > *': {
      color: palette.common.cowbellBlue,
      marginLeft: 2,
    },
  },

  attestCheckboxDisabled: {
    '& svg': {
      color: `${Color(palette.text.primary).fade(0.5).string()} !important`,
    },
  },

  attestCheckboxChecked: {
    '& svg': {
      color: palette.common.cowbellBlue,
    },
  },
}));

export const NameInsuredAttestation = compose(
  withRouter,
  withStyles(useStyles)
)(({ history, data, close }) => {
  const [readTerms, setRead] = useState(false);
  const [error, setError] = useState('');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, translationKeys } = useCowbellTranslations();

  const handleCheckBox = (event) => {
    setRead(event.target.checked);
  };

  const downloadDocs = () => {
    const quoteId = _.get(data, 'quoteId');
    const accountId = _.get(data, 'quote.accountId');
    const quoteNumber = _.get(data, 'quote.quoteNumber');
    const name = _.get(data, 'quote.companyName');
    const created = moment(_.get(data, 'quote.created')).unix();
    const isEndorsement = _.get(
      data,
      'quote.initialRequestData.isEndorsement',
      false
    );

    downloadQuoteDocuments(accountId, quoteId, created, isEndorsement)
      .then((response) => {
        const url = _.get(response, 'data');
        downloadAWSDoc(url)
          .then((awsDocResponse) => {
            saveBlobFile(
              awsDocResponse,
              `${name}-${quoteNumber}-Cowbell-Application`,
              '.pdf'
            );
          })
          .catch(() =>
            enqueueSnackbar('Unable to download the Quote Documents', {
              variant: 'error',
            })
          );
      })
      .catch(() =>
        enqueueSnackbar('Unable to fetch the Quote Documents link', {
          variant: 'error',
        })
      );
  };

  const handleSubmit = () => {
    attestTermsOnQuote({ quoteId: data.quoteId })
      .then(() => {
        toggleModalDirect('NameInsuredAttestation', false);
        history.push(`/customer/quotes/${data.quoteId}/sign/${data.product}`);
        close();
      })
      .catch((error) => {
        const errorMessage = manageAPIError(
          error,
          'Not able to attest at this time. Please try again later.'
        );

        setError(errorMessage);
      });
  };

  return (
    <form>
      <DialogContent
        className="modal-title"
        style={{ textAlign: 'left', maxHeight: '55vh', overflow: 'auto' }}
      >
        <div>
          <h4>Cowbell Insurance Agency, LLC</h4>
          <p>Terms and Conditions</p>
          <p>
            I understand that this Commercial Cyber Insurance Policy is produced
            by Cowbell Insurance Agency, LLC (“Cowbell Insurance Agency”) and
            underwritten on behalf of National Specialty Insurance Company and
            Spinnaker Insurance Company. This Commercial Cyber Policy and any
            and all related policy documents will be issued and administered by
            Cowbell Insurance Agency. Any payments made in connection with the
            purchase of this Commercial Cyber Policy are made to and maintained
            by Cowbell Insurance Agency.
          </p>

          <p>
            I affirm that I have read, and Cowbell Insurance Agency has advised
            me to carefully read, the terms, conditions, limitations, exclusions
            and any applicable endorsements of the commercial cyber insurance
            policy that I am applying for, which have been made available to me.
            I affirm that the information that I have provided throughout the
            application for this insurance is, to the best of my knowledge,
            true, accurate, and complete.
          </p>

          <p>
            I understand that any non-disclosure, misrepresentation or
            non-payment of premium may result in cancellation of or non-renewal
            of this policy. I understand and agree that my application for this
            Commercial Cyber Policy will be submitted electronically, and the
            policy documents related to this insurance, including any notices
            and updates there to (collectively, the “Policy Documents”), will be
            made available to me electronically. Cowbell Insurance Agency is
            authorized to send, and I agree to accept delivery of, all Policy
            Documents electronically; accordingly, I understand that I will not
            be receiving copies of the Policy Documents by standard mail unless
            required by the department of insurance of the state in which the
            Policy is issued or pursuant to my request. If I decide that I no
            longer wish to receive the Policy Documents electronically, I shall
            contact Cowbell Insurance Agency at support@cowbellcyber.ai to
            request that the Policy Documents be sent to me by non-electronic
            delivery. It is my responsibility to provide Cowbell Insurance
            Agency with a valid and current email address and to check that
            email address regularly for important Policy Documents from Cowbell
            Insurance Agency or a valid current address to which Cowbell
            Insurance Agency can send important Policy Documents to the extent
            such documents are delivered to me by non-electronic delivery.
          </p>
          <div style={{ paddingTop: '2rem' }}>
            <Checkbox
              name="readTerms"
              checked={readTerms}
              classes={{
                root: classes.attestCheckbox,
                checked: classes.attestCheckboxChecked,
                disabled: classes.attestCheckboxDisabled,
              }}
              label={
                <span style={{ fontSize: '1.16rem' }}>
                  I have read Cowbell Insurance Agency Terms of Use (also
                  available upon request), Insurance Fraud Warnings accompanying
                  my application, and{' '}
                  <Box component="u" onClick={downloadDocs}>
                    State-Specific Signature-Bearing Endorsements
                  </Box>{' '}
                  accompanying my application.
                </span>
              }
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" buttonText="Cancel" onClick={close} />
        <CbButton
          styleName="ctaButton"
          buttonText="OK"
          disabled={!readTerms}
          onClick={handleSubmit}
        />
      </DialogActions>
    </form>
  );
});

export default NameInsuredAttestation;
