import React from 'react';
import {
  init,
  Identify,
  identify,
  track as amplitudeTrack,
} from '@amplitude/analytics-browser';
import { useLocation } from 'react-router-dom';
import type { WithChildrenProps } from '../types/components/common';
import { useActor } from '../components/hooks/useActor';
import { useAuth } from '../components/hooks/useAuth';
import { useEnvQuery } from '../queries/useEnvQuery';

// Amplitude data can only be exported if someone has both the API and Secret Key, so we can safely place the API keys here.
// Use AMPLITUDE_API_KEY_TESTING for testing purposes.
const AMPLITUDE_API_KEY_PROD = '11f08c09028e823c18a29a867db13caf';
const AMPLITUDE_API_KEY_TESTING = 'c99e09fbe77092acb64bfd9e772ae60b';

const identifyObj = new Identify();

type AmplitudeProviderProps = WithChildrenProps;

interface AmplitudeContextType {
  eventProperties?: any;
  track: (eventName: string, eventProperties?: any) => void;
}

const AmplitudeContext = React.createContext<AmplitudeContextType>({
  eventProperties: {},
  track: () => null,
});

export const useAmplitude = () => {
  return React.useContext(AmplitudeContext);
};

const AmplitudeProvider = (props: AmplitudeProviderProps) => {
  const location = useLocation();
  const actor = useActor();
  const auth = useAuth();
  const { data: env } = useEnvQuery();
  const isProd = Boolean(env?.prod);
  const isAmplitudeTestingEnabled =
    window.cowbell.getIsAmplitudeTestingEnabled();

  React.useEffect(() => {
    if (actor.id) {
      if (isProd) {
        init(AMPLITUDE_API_KEY_PROD, actor.id);
      } else if (isAmplitudeTestingEnabled) {
        init(AMPLITUDE_API_KEY_TESTING, actor.id);
      }

      if (auth.subscriberType) {
        identifyObj.set(
          AMPLITUDE_PROPERTIES.subscriberType,
          auth.subscriberType
        );
        identify(identifyObj);
      }
    }
  }, [actor.id, isAmplitudeTestingEnabled]);

  const track = (eventName: string, eventProperties?: any) => {
    const payload = {
      ...eventProperties,
      from: location?.pathname,
      persona: auth.accountType,
    };

    amplitudeTrack(eventName, payload);
  };

  return (
    <AmplitudeContext.Provider
      value={{
        eventProperties: {},
        track,
      }}
    >
      {props.children}
    </AmplitudeContext.Provider>
  );
};

export const AMPLITUDE_EVENTS = {
  sidebarMenuClick: 'sidebar_menu_click',
  forgotPassword: 'forgot_password',
  login: 'login',
  startNewQuote: 'start_new_quote',
  renewPolicy: 'renew_policy',
  editAccount: 'edit_account',
  editQuote: 'edit_quote',
  requestToBind: 'request_to_bind',
  renewalFirmoChange: 'renewal_firmo_change',
  getMoreQuotes: 'get_more_quotes',
  renewalDetails: 'renewal_details',
  downloadApplication: 'download_application',
  downloadQuote: 'download_quote',
  bindQuote: 'bind_quote',
  addQuoteToCart: 'add_quote_to_cart',
  requestQuote: 'request_quote',
  issuePolicy: 'issue_policy',
  addFilter: 'add_filter',
  search: 'search',
  sortListing: 'sort_listing',
  nav: {
    accounts: 'accounts',
    binders: 'binders',
    quotes: 'inbox',
    policies: 'policies',
    commissions: 'commissions',
    cowbellAcademy: 'cowbell_academy',
    cowbellRx: 'cowbell_rx',
    formsLibrary: 'forms_library',
    roles: 'roles',
    teams: 'teams',
    manageUsers: 'manage_users',
    agencyProfile: 'agency_profile',
    supportedStates: 'supported_states',
    locations: 'locations',
    accountLookup: 'account_lookup',
    industryLookup: 'industry_lookup',
    training: 'training',
    agencyLicenses: 'agency_licenses',
    agentLicenses: 'agent_licenses',
    surplusLinesLicenses: 'surplus_lines_licenses',
    activityLog: 'activity_log',
    developers: 'developers',
    apiDocs: 'api_docs',
    support: 'support',
    claims: 'claims',
    resources: 'resources',
    clientProfile: 'client_profile',
  },
  sendEmailToPolicyHolder: 'send_email_to_policyholder',
  uploadDocument: 'upload_document',
  startNewQuoteAgencyDashboard: 'start_new_quote_agency_dashboard',
} as const;

export const AMPLITUDE_PROPERTIES = {
  [AMPLITUDE_EVENTS.login]: {
    status: {
      success: 'success',
      failure: 'failure',
    },
    reason: {
      rememberDevice: 'device remembered, session restored',
      rememberDeviceExpired: 'remember device token stale',
      passwordExpired: 'password expired',
      mfaNotEnabled: 'mfa not enabled',
      serviceDown: 'service down or under maintainence',
      invalidCredentials: 'invalid credentials',
    },
    mfaEnabled: 'mfa enabled',
  },
  [AMPLITUDE_EVENTS.downloadApplication]: {
    source: {
      renewalSummaryPage: 'renewal summary page',
      bindQuoteModal: 'bind quote modal',
    },
  },
  quoteId: 'quote id',
  version: {
    one: 1,
    two: 2,
  },
  subscriberType: 'subscriber type',
  source: {
    renewalSummaryPage: 'renewal summary page',
    bindQuoteModal: 'bind quote modal',
    quotesTab: 'quotes_tab',
    bindersTab: 'binders_tab',
    policiesTab: 'policies_tab',
    clientsTab: 'clients_tab',
  },
} as const;

export default AmplitudeProvider;
