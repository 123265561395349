import { createSelector } from 'reselect';

import type { RootStore } from '../../types';

export const selectUser = (state: RootStore) => state.user;
export const selectUserAccount = (state: RootStore) => state.user_account;

export const selectActor = createSelector(
  selectUser,
  selectUserAccount,
  (user, user_account) => ({
    user,
    account: user_account,
  })
);
