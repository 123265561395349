import React from 'react';
import { useTheme } from '@mui/material';
import type { DefaultTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import TextFieldBase from './base/TextFieldBase';

type Props = React.ComponentProps<typeof TextFieldBase>;

const DateFieldBase = React.forwardRef(
  ({ inputProps, classes, ...props }: Props, ref) => {
    const theme = useTheme();
    const colorTheme = theme.palette.mode;

    const { input } = useStyles({ colorTheme, ...props });

    return (
      <TextFieldBase
        ref={ref}
        type="date"
        inputProps={{
          onKeyDown: (e: React.KeyboardEvent) => e.preventDefault(),
          ...inputProps,
        }}
        classes={{
          input: `${input} ${classes?.input}`,
        }}
        {...props}
      />
    );
  }
);

const useStyles = makeStyles<
  DefaultTheme,
  Props & { colorTheme: 'light' | 'dark' }
>(({ palette }) => ({
  input: {
    colorScheme: (props) => {
      if (props.colorTheme === 'dark') {
        return props.colorTheme;
      }

      return 'inherit';
    },

    '&:focus': {
      backgroundColor: palette.background.modal,
      color: palette.text.secondary,
    },
  },
}));

export default DateFieldBase;
