// MUI
import { DialogActions, DialogContent } from '@mui/material';

import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

// Cowbell UI
import CbButton from '../../Buttons/CbButton';

// apis
import {
  undoCancelledP100Policy,
  undoCancelledP250Policy,
} from '../../../api/policies.api';

// helpers
import { useAPIErrorHandler } from '../../hooks/useAPIErrorHandler';
import type { ProductType } from '../../../types';
import { ProductTypes } from '../../../types';
import { COWBELL_POLICY } from '../../tables/table_constants';

interface UndoCancelledPolicyConfirmModalProps {
  data: {
    id: string;
    policyNumber: string;
    product: ProductType;
  };
  close: () => void;
}

export const UndoCancelledPolicyConfirmModal = ({
  data,
  close,
}: UndoCancelledPolicyConfirmModalProps) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const handleAPIError = useAPIErrorHandler();
  const formMethods = useForm();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const undoCancelledPolicy =
    data.product === ProductTypes.p100
      ? undoCancelledP100Policy
      : undoCancelledP250Policy;

  const onSubmit = () => {
    return undoCancelledPolicy(data.id)
      .then(() => {
        enqueueSnackbar(
          `Request to undo cancelled policy was sent successfully for policy ${data.policyNumber}`,
          {
            variant: 'success',
          }
        );
        queryClient.invalidateQueries([COWBELL_POLICY]);
        close();
      })
      .catch(
        handleAPIError(
          `There was an error with undoing cancelled policy on policy ${data.policyNumber}`
        )
      );
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2rem 5rem' }}>
          <div className="modal-title">
            Are you sure you want to undo cancelled policy for policy#
            <br />
            <span className="modal-blue-text"> {data.policyNumber}</span>?
          </div>
        </DialogContent>
        <DialogActions>
          <CbButton onClick={close} styleName="cancel">
            Cancel
          </CbButton>
          <CbButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
          >
            Confirm
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const UndoCancelledPolicyConfirmModalConfig = {
  UndoCancelledPolicyConfirmModal: {
    component: UndoCancelledPolicyConfirmModal,
    config: {
      title: 'Undo Cancelled Policy',
      maxWidth: 'sm',
      fullWidth: true,
    },
  },
};
