import type { ReactNode } from 'react';
import React from 'react';

// components
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface RowDataWrapperProps {
  index: number;
  children: ReactNode;
}

const RowDataWrapper: React.FC<RowDataWrapperProps> = ({ index, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.row} key={index}>
      {children}
    </Box>
  );
};

export default RowDataWrapper;

const useStyles = makeStyles(({ palette }) => ({
  row: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '0.5rem 0.8rem',
    textAlign: 'center',

    '&:nth-child(odd)': {
      background: palette.background.modal,
    },
  },
}));
