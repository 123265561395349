import React, { cloneElement } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const SquareButton = ({ children, ...props }) => {
  const buttonStyles = useButtonStyles();
  const imageStyles = useImageStyles();

  return (
    <Button classes={buttonStyles} {...props}>
      {cloneElement(children, {
        className: imageStyles.root,
      })}
    </Button>
  );
};

export const squareButtonRootStyles = {
  minWidth: '2.83rem',
  height: '2.83rem',
  padding: 0,
  position: 'relative',
  lineHeight: 1,
};

const useButtonStyles = makeStyles(({ palette }) => ({
  root: {
    ...squareButtonRootStyles,
    background: palette.common.cowbellBlue,

    '&:hover': {
      background: palette.common.darkBlue,
    },
  },

  outlined: {
    background: 'transparent',
    border: `1px solid ${palette.common.cowbellBlue}`,
  },
}));

const useImageStyles = makeStyles({
  root: {
    maxWidth: '2.5rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});
