import Moment from 'moment';
import { trimAndAddAsterisk } from '../../utility/QueryUtility';

export const riskProfileServiceMappings = [
  {
    path: 'from',
    paramKey: 'after',
    transform: ({ from, to }) => {
      if (!from) {
        return null;
      }

      return from === to
        ? Moment.unix(from).startOf('day').toISOString()
        : Moment.unix(from).toISOString();
    },
  },
  {
    path: 'to',
    paramKey: 'before',
    transform: ({ to, from }) => {
      if (!to) {
        return null;
      }

      return from === to
        ? Moment.unix(to).endOf('day').toISOString()
        : Moment.unix(to).toISOString();
    },
  },
  {
    path: 'state',
  },
  { path: 'page' },
  { path: 'size' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'startDate':
        case 'endDate':
        case 'carrierId':
        case 'maxLimit':
        case 'maxRevenue':
        case 'isSurplus':
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          break;
        case 'formattedMaxLimit':
        case 'formattedMaxRevenue':
        case 'formattedIsSurplus':
        case 'formattedStartDate':
        case 'formattedEndDate':
          if (orderBy && order) {
            const temp = orderBy.replace('formatted', '');
            const sortingOrderBy = `${temp.charAt(0).toLowerCase()}${temp.slice(
              1
            )}`;
            return `${sortingOrderBy},${order}`;
          }
          break;
        default:
          if (orderBy && order) {
            return `${orderBy}.keyword,${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search, searchValue }) => {
      if (searchValue) {
        return searchValue;
      }
      return search ? trimAndAddAsterisk(search) : null;
    },
  },
];
