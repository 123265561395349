import { SvgIcon } from '@mui/material';

export default function CommissionsIconLight({ fill = '#1f1f29', ...props }) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <g id="Group_15847" data-name="Group 15847" clipPath="url(#clip-path)">
        <path
          id="Path_7925"
          data-name="Path 7925"
          d="M48.9,22.452A22.452,22.452,0,1,1,26.452,0,22.452,22.452,0,0,1,48.9,22.452"
          transform="translate(-0.903)"
          fill="#ccddec"
        />
        <path
          id="Path_7926"
          data-name="Path 7926"
          d="M7.194,9,1,15.194V49.258H29.645V9Z"
          transform="translate(-0.226 -2.032)"
          fill="#fff"
        />
        <path
          id="Path_7927"
          data-name="Path 7927"
          d="M1,15.968H7.968V9H7.194L1,15.194Z"
          transform="translate(-0.226 -2.032)"
          fill="#7c96bf"
        />
        <path
          id="Path_7928"
          data-name="Path 7928"
          d="M29.645,50.008H1a.75.75,0,0,1-.75-.75V15.194a.75.75,0,0,1,.22-.53L6.663,8.47a.75.75,0,0,1,.53-.22H29.645A.75.75,0,0,1,30.4,9V23.71a.75.75,0,0,1-1.5,0V9.75H7.5L1.75,15.5v33H28.9v-8.54a.75.75,0,0,1,1.5,0v9.29A.75.75,0,0,1,29.645,50.008Z"
          transform="translate(-0.226 -2.032)"
          fill="#0055a2"
        />
        <path
          id="Path_7929"
          data-name="Path 7929"
          d="M5,16.871H8.871V13"
          transform="translate(-1.129 -2.935)"
          fill="none"
          stroke="#0055a2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_7930"
          data-name="Path 7930"
          d="M46.742,27,27.865,45.88l-4.771.954.955-4.771L42.925,23.187a.955.955,0,0,1,1.349,0l2.467,2.467a.955.955,0,0,1,0,1.349"
          transform="translate(-5.215 -5.173)"
          fill="#7c96bf"
        />
        <path
          id="Path_7931"
          data-name="Path 7931"
          d="M46.742,27,27.865,45.88l-4.771.954.955-4.771L42.925,23.187a.955.955,0,0,1,1.349,0l2.467,2.467A.955.955,0,0,1,46.742,27Z"
          transform="translate(-5.215 -5.173)"
          fill="none"
          stroke="#0055a2"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_7961"
          data-name="Path 7961"
          d="M0,0,3.817,3.817"
          transform="translate(35.523 20.202)"
          fill="none"
          stroke="#0055a2"
          strokeWidth="1.5"
        />
        <line
          id="Line_100"
          data-name="Line 100"
          x1="6.968"
          transform="translate(8.516 41.806)"
          fill="none"
          stroke="#0055a2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <circle
          id="Ellipse_366"
          data-name="Ellipse 366"
          cx="2.323"
          cy="2.323"
          r="2.323"
          transform="translate(8.516 19.355)"
          fill="none"
          stroke="#0055a2"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_7932"
          data-name="Path 7932"
          d="M23.323,37.645h0a2.323,2.323,0,1,1,2.323-2.323A2.322,2.322,0,0,1,23.323,37.645Z"
          transform="translate(-4.742 -7.452)"
          fill="none"
          stroke="#0055a2"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_101"
          data-name="Line 101"
          y1="10.839"
          x2="9.29"
          transform="translate(10.065 19.355)"
          fill="none"
          stroke="#0055a2"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Line_44"
          data-name="Line 44"
          d="M.48,4.44H-1V0H.48Z"
          transform="translate(43.361 26.56)"
          fill="#0055a2"
        />
        <path
          id="Line_44-2"
          data-name="Line 44"
          d="M.48,4.44H-1V0H.48Z"
          transform="translate(36.003 39.441)"
          fill="#0055a2"
        />
        <path
          id="Line_45"
          data-name="Line 45"
          d="M4.44.48H0V-1H4.44Z"
          transform="translate(40.881 29.039)"
          fill="#0055a2"
        />
        <path
          id="Line_45-2"
          data-name="Line 45"
          d="M4.44.48H0V-1H4.44Z"
          transform="translate(33.523 41.921)"
          fill="#0055a2"
        />
        <path
          id="Line_46"
          data-name="Line 46"
          d="M.48,4.44H-1V0H.48Z"
          transform="translate(35.263 2.527)"
          fill="#0055a2"
        />
        <path
          id="Line_47"
          data-name="Line 47"
          d="M4.44.48H0V-1H4.44Z"
          transform="translate(32.782 5.008)"
          fill="#0055a2"
        />
      </g>
    </SvgIcon>
  );
}
