import Cleave from 'cleave.js/react';
import type { ComponentProps } from 'react';

const onInit: ComponentProps<typeof Cleave>['onInit'] = (value: any) => {
  value.lastInputValue = '';
};

export const CleaveBase = (props: any) => {
  return <Cleave onInit={onInit} {...props} />;
};
