import React from 'react';
import { DialogContent, DialogActions } from '@mui/material';

import CbButton from '../Buttons/CbButton';
import { useToggleModal } from '../../utils/modal.utils';

const FeedbackConfirm = () => {
  const toggleModal = useToggleModal();

  return (
    <>
      <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
        <h2
          style={{
            fontSize: '1rem',
            fontWeight: 'normal',
            fontFamily: 'Titillium Web',
          }}
        >
          Thank you for providing your valuable feedback
        </h2>
      </DialogContent>
      <DialogActions>
        <CbButton
          action={toggleModal.deferred('FeedbackConfirm', false)}
          type="submit"
          styleName="ctaButton"
          buttonText="Ok"
        />
      </DialogActions>
    </>
  );
};

export default FeedbackConfirm;
