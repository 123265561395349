import _ from 'lodash';
import React from 'react';
import {
  Card as MuiCard,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { useFormContext } from 'react-hook-form-4';

import { withFormController } from '../../../../components/hocs/withFormController';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import { InputLabelBase as InputLabel } from '../../../../components/inputs/InputLabelBase';
import { MultiSelect } from '../../../../components/Selects';

import {
  useCardStyles,
  useGridStylesLeft,
  useGridStylesRight,
} from './ClaimModalStyles';
import { UsStatesFull } from '../../../../utils/USState';

const TextField = withFormController(TextFieldBase);

export const CreateClaimLocationLoss = withStyles(({ palette }) => ({
  heading: {
    marginTop: 0,
    marginBottom: 25,
    color: palette.common.cowbellBlue,
    textAlign: 'center',
  },
}))(({ classes }) => {
  const cardStyles = useCardStyles();
  const gridStylesLeft = useGridStylesLeft();
  const gridStylesRight = useGridStylesRight();
  const { control, getValues, register, onSelect, onMultiSelect, errors } =
    useFormContext();

  const formValues = getValues();

  return (
    <MuiCard classes={cardStyles}>
      <h2 className={classes.heading}>Location And Loss Information</h2>
      <MuiGrid container spacing={1}>
        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="organizationName" required>
            Organization Name:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          {_.get(formValues, 'companyName', '-')}
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="address" required>
            Loss Location Address:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <TextField name="address" control={control} required />
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="city" required>
            Loss Location city, state, zip:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <TextField
            name="city"
            control={control}
            required
            style={{ marginRight: 7 }}
            placeholder="City"
          />
          <MultiSelect
            name="state"
            isMulti={false}
            options={UsStatesFull}
            onChange={onMultiSelect}
            values={formValues.state}
            ref={register({ name: 'state' })}
            error={_.get(errors, 'state.value.message')}
          />
          <TextField
            name="zipCode"
            control={control}
            required
            style={{ marginLeft: 7 }}
            placeholder="Zip code"
          />
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="lossDescription" required>
            Loss Description:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <TextField
            name="lossDescription"
            control={control}
            minRows="4"
            required
            fullWidth
            multiline
          />
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="lossDate">
            Loss Date:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <TextField name="lossDate" control={control} type="date" />
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="impactedDevicesOs">
            Operating System of Impacted Device(s):
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <TextField name="impactedDevicesOs" control={control} fullWidth />
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="impactedDevicesType">
            Type of Impacted Device (model, version):
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <TextField name="impactedDevicesType" control={control} fullWidth />
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="incidentNoticed">
            How was the incident noticed (inbox, browser, other):
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <TextField name="incidentNoticed" control={control} fullWidth />
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="lossInformationExplanation">
            If other, please explain:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <TextField
            name="lossInformationExplanation"
            control={control}
            fullWidth
            multiline
            minRows="4"
          />
        </MuiGrid>

        <MuiGrid item md={6} classes={gridStylesLeft}>
          <InputLabel inline htmlFor="isAuthoritiesInvolved" required>
            Authorities involved?
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6} classes={gridStylesRight}>
          <MuiSelect
            variant="standard"
            name="isAuthoritiesInvolved"
            ref={register({ name: 'isAuthoritiesInvolved' })}
            onChange={onSelect}
            defaultValue={getValues().isAuthoritiesInvolved}
            error={_.get(errors, 'isAuthoritiesInvolved.value.message')}
          >
            {claimContactOptions.map(({ label, value }) => (
              <MuiMenuItem key={label} value={value}>
                {label}
              </MuiMenuItem>
            ))}
          </MuiSelect>
        </MuiGrid>

        {formValues.isAuthoritiesInvolved === 'yes' && (
          <>
            <MuiGrid item md={6} classes={gridStylesLeft}>
              <InputLabel inline htmlFor="authorityName" required>
                Authorities Name:
              </InputLabel>
            </MuiGrid>
            <MuiGrid item md={6} classes={gridStylesRight}>
              <TextField name="authorityName" required control={control} />
            </MuiGrid>

            <MuiGrid item md={6} classes={gridStylesLeft}>
              <InputLabel inline htmlFor="authorityPhoneNumber" required>
                Phone Number:
              </InputLabel>
            </MuiGrid>
            <MuiGrid item md={6} classes={gridStylesRight}>
              <TextField
                name="authorityPhoneNumber"
                required
                control={control}
              />
            </MuiGrid>

            <MuiGrid item md={6} classes={gridStylesLeft}>
              <InputLabel inline htmlFor="isReportFiled" required>
                Report filed?:
              </InputLabel>
            </MuiGrid>
            <MuiGrid item md={6} classes={gridStylesRight}>
              <MuiSelect
                variant="standard"
                name="isReportFiled"
                ref={register({ name: 'isReportFiled' })}
                onChange={onSelect}
                defaultValue={formValues.isReportFiled || 'no'}
              >
                {claimContactOptions.map(({ label, value }) => (
                  <MuiMenuItem key={label} value={value}>
                    {label}
                  </MuiMenuItem>
                ))}
              </MuiSelect>
            </MuiGrid>

            {formValues.isReportFiled === 'yes' && (
              <>
                <MuiGrid item md={6} classes={gridStylesLeft}>
                  <InputLabel inline htmlFor="reportNumber" required>
                    Report Number?:
                  </InputLabel>
                </MuiGrid>
                <MuiGrid item md={6} classes={gridStylesRight}>
                  <TextField name="reportNumber" required control={control} />
                </MuiGrid>
              </>
            )}
          </>
        )}
      </MuiGrid>
    </MuiCard>
  );
});

const claimContactOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
