export const COWBELL_SUBMISSIONS = 'COWBELL_SUBMISSIONS';
export const COWBELL_P100_SUBMISSIONS = 'COWBELL_P100_SUBMISSIONS';

export const RenewalSubmissionStatuses = {
  PENDING_STATUS: 'Pending',
  READY_TO_QUOTE_STATUS: 'Ready to Quote',
  QUOTED_STATUS: 'Quoted',
  BIND_ORDER_RECEIVED_STATUS: 'Bind Order Received',
  BOUND_STATUS: 'Bound',
  ISSUED_POLICY_STATUS: 'Issued Policy',
  LOST_STATUS: 'Lost',
  NON_RENEWED_STATUS: 'Non-renewed',
  MIDTERM_CANCEL_STATUS: 'Midterm Cancel',
  ERP_STATUS: 'ERP',
  RE_FLAGGED_STATUS: 'RE Flagged',
  EXTENDED_STATUS: 'Extended',
};

export const SUBMISSION_STATUS_COLORS: any = {
  [RenewalSubmissionStatuses.PENDING_STATUS]: '#9ba0ae',
  [RenewalSubmissionStatuses.READY_TO_QUOTE_STATUS]: '#f2c142',
  [RenewalSubmissionStatuses.QUOTED_STATUS]: '#bbe890',
  [RenewalSubmissionStatuses.BIND_ORDER_RECEIVED_STATUS]: '#fffd44',
  [RenewalSubmissionStatuses.BOUND_STATUS]: '#aad0f8',
  [RenewalSubmissionStatuses.ISSUED_POLICY_STATUS]: '#2180e2',
  [RenewalSubmissionStatuses.LOST_STATUS]: '#eb8677',
  [RenewalSubmissionStatuses.NON_RENEWED_STATUS]: '#003a7e',
  [RenewalSubmissionStatuses.MIDTERM_CANCEL_STATUS]: 'darkgrey',
  [RenewalSubmissionStatuses.ERP_STATUS]: '#770000',
  [RenewalSubmissionStatuses.EXTENDED_STATUS]: '#5C9032',
};

export const TERRITORY_CONTACT_QUERY_KEY = [
  'renewal-submissions:territory-contacts',
];
