import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useInfiniteQuery } from '@tanstack/react-query';
// react
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form-4';
// mui
import {
  DialogActions,
  Grid,
  DialogContent,
  FormHelperText,
} from '@mui/material';
// components
import { ScrollableMultiSelect } from '../../../../components/Selects/MultiSelect/ScrollableMultiSelect';
import { withFormController } from '../../../../components/hocs/withFormController';
import { delayedEvent } from '../../../../utils/eventUtils';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import CBButton from '../../../../components/Buttons/CbButton';
import { Modal } from '../../../../components/modals/v2/helpers/v2.modal.helpers';
// actions
import { createBillingContact } from '../../../customers/_services/BillingService';
import { createCommissionsContact } from '../../_services/commission.service';
import { getAgencyLocations } from '../../_services/locations.service';
// helpers
import { reduceForLocations } from '../../../admin/users/UsersUtils';

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  contactEmail: Yup.string().email().label('Email').required(),
  contactName: Yup.string().label('Contact Name').required(),
  locations: Yup.array()
    .of(
      Yup.object({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    )
    .required()
    .label('Locations'),
});

export const AddBillingCommissionContact = ({ data, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const agencyDetails = useSelector(({ account }) => account);

  const [backendError, setBackendError] = useState('');

  // validationSchema
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { isSubmitting },
    watch,
    ...methods
  } = useForm({ validationSchema: schema });
  const values = getValues();

  watch('locations');

  const {
    data: agencyLocations,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['agency-locations'],
    ({ pageParam = 0 }) => {
      return getAgencyLocations({ page: pageParam })
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          setBackendError(
            'Not able to fetch locations at this time.Please try again later.'
          );
        });
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.last ? undefined : lastPage.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
    }
  );

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const handleLocation = (name, selectedOption) => {
    setValue(name, selectedOption);
  };

  const toggleAllHandler = () => {
    const selectedLocations = getValues().locations || [];

    if (selectedLocations.length !== 0) {
      return setValue('locations', []);
    }
    setValue('locations', activeAgencyLocations);
  };

  const onSubmit = (formData) => {
    const finalLocation = {};

    formData.locations.map((loc) => {
      return _.assign(finalLocation, {
        [loc.value]: [loc.label, loc.isHeadquarters],
      });
    });

    const payload = {
      contactName: formData.contactName,
      contactEmail: formData.contactEmail,
      agencyId: agencyDetails.id,
      locations: finalLocation,
    };

    if (data.isBill) {
      return createBillingContact({}, payload)
        .then(() => {
          enqueueSnackbar('Billing Contacting was created succesfully', {
            variant: 'success',
          });
          makeRefreshCall();
          Modal.hide();
        })
        .catch((error) => {
          setBackendError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Not able to add contact at this time.Please try again later.'
              )
            )
          );
        });
    }
    return createCommissionsContact({}, payload)
      .then(() => {
        enqueueSnackbar('Commision Contact was created succesfully', {
          variant: 'success',
        });
        makeRefreshCallCommission();
        Modal.hide();
      })
      .catch((error) => {
        setBackendError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Not able to add contact at this time.Please try again later.'
            )
          )
        );
      });
  };

  const activeAgencyLocations = formatActiveAgencyLocations(
    agencyLocations,
    hasNextPage
  );

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ padding: '2.5rem 5rem', overflow: 'visible' }}>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <TextField
                label="Contact or Alias Name"
                name="contactName"
                required
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField label="Email" name="contactEmail" required fullWidth />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <ScrollableMultiSelect
                name="locations"
                label="Locations"
                buttonPlaceholder="Assign Locations"
                options={activeAgencyLocations}
                onChange={handleLocation}
                toggleAllHandler={toggleAllHandler}
                supportSelectAll
                values={values.locations}
                ref={register({ name: 'locations' })}
                api={getAgencyLocations}
                reduceFunc={reduceForLocations}
                required
                error={
                  (_.isUndefined(values.locations) ||
                    (_.isArray(values.locations) &&
                      values.locations.length === 0)) &&
                  _.get(methods.errors, 'locations.message')
                }
              />
            </Grid>
          </Grid>
          <FormHelperText className="api-text-error">
            {backendError}
          </FormHelperText>
        </DialogContent>

        <DialogActions>
          <CBButton
            onClick={props.close}
            styleName="cancel"
            buttonText="Cancel"
          />
          <CBButton
            type="submit"
            styleName="ctaButton"
            buttonText="Add"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </DialogActions>
      </form>
    </FormContext>
  );
};

export const AddBillingCommissionContactConfig = {
  AddBillingCommissionContact: {
    component: AddBillingCommissionContact,
    config: {
      maxWidth: 'md',
      PaperProps: { style: { overflow: 'visible' } },
    },
  },
};

const formatActiveAgencyLocations = (agencyLocations, hasNextPage) => {
  const allAgencyLocations = [];
  if (hasNextPage == false) {
    // go through all pages from useQuery response and format them
    for (let i = 0; i < agencyLocations.pages.length; i += 1) {
      const curPage = agencyLocations.pages[i];
      allAgencyLocations.push(...curPage.content);
    }
  }
  return reduceForLocations(allAgencyLocations);
};

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, 'billingContact');
}

function makeRefreshCallCommission() {
  delayedEvent('table-refetch', 500, 'commissionContacts');
}
