import React from 'react';
// lodash
import _ from 'lodash';
// mui
import { DialogActions, DialogContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { FormProvider, useForm } from 'react-hook-form';
import CbButton from '../../components/Buttons/CbButton';

import { AccountLookupAdminFields } from '../manage/account/lookup/AccountLookupAdminFields';

export const ProcessBOR = ({ close, data, handleAgentSubmit }) => {
  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      agency: '',
      agent: '',
      team: '',
    },
  });

  const selectedAgency = methods.watch('agency');
  const selectedAgent = methods.watch('agent');
  const selectedTeam = methods.watch('team');

  const classes = useStyles();

  const onSubmit = (formValues) => {
    if (_.isFunction(handleAgentSubmit)) {
      handleAgentSubmit(formValues);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={classes.wrapper}>
          <h2 className={classes.header}>Confirm and Add new agency</h2>
          <h3 className={classes.subHeader}>
            Confirm your current agency and add the agency that you would like
            to change your policy to.
          </h3>
          <span>Current Agency: {data.agencyName}</span>

          <AccountLookupAdminFields />
        </DialogContent>

        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid className={classes.pagination}>Step 2 of 3: New Agency</Grid>
            <Grid>
              <CbButton
                buttonText="Cancel"
                styleName="cancel"
                onClick={close}
              />
              <CbButton
                buttonText="Next"
                styleName="ctaButton"
                type="submit"
                disabled={
                  !(
                    selectedAgency?.value &&
                    selectedAgent?.value &&
                    selectedTeam?.value
                  )
                }
              />
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const useStyles = makeStyles(({ config, palette }) => ({
  wrapper: {
    color: palette.primary.contrastText,
    padding: '2rem 5rem !important',
  },
  header: {
    fontWeight: 'normal',
    lineHeight: 0.9,
    margin: 0,
  },
  subHeader: {
    fontWeight: 'normal',
    lineHeight: 1.17,
    fontSize: config.textSizes.normal,
    paddingBottom: '1rem',
  },
  supportedFiles: {
    lineHeight: 1.5,
    textAlign: 'center',
  },
  pagination: {
    fontSize: config.textSizes.tertia,
    color: palette.primary.contrastText,
  },
}));
