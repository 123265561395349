import React from 'react';
import { compose } from 'redux';
// draft.js
import { Editor } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { withStyles } from '@mui/styles';

const styles = ({ config }) => {
  return {
    editor: {
      maxHeight: '6rem',
      fontSize: '1rem',
      color: config.colors.cowbellBlue,
      background: config.colors.lightWhite1,
      padding: '0.416rem',
      overflow: 'auto',
      lineHeight: 1.33,
      fontWeight: 'normal',
    },
  };
};

export const DraftEditor = compose(
  withStyles(styles),
  React.forwardRef
)(({ classes, placeholder, spellcheck, style, ...props }, ref) => {
  return (
    <div className={classes.editor} style={style}>
      <Editor
        ref={ref}
        editorState={props.editorState}
        onChange={(editorState) => props.setEditor(editorState)}
        placeholder={placeholder}
        spellCheck={spellcheck}
        {...props}
      />
    </div>
  );
});
