import React from 'react';
import { Plugin } from '@devexpress/dx-react-core';
import { RowDetailState } from '@devexpress/dx-react-grid';
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { useTheme } from '@mui/material';
import { DetailColumnLast } from './DetailColumnLast';
import { TableStateContext } from '../TableStateContext';

export const RowDetailPlugin = ({ ...props }) => {
  const { state, handlers, ...rest } = React.useContext(TableStateContext);
  return <RowDetailPluginBase {...props} {...state} {...handlers} {...rest} />;
};

export const RowDetailPluginBase = ({ ...props }) => {
  if (!props.rowDetailComponent) {
    throw new Error();
  }
  return (
    <Plugin>
      <RowDetailState
        expandedRowIds={props.expandedRowIds}
        onExpandedRowIdsChange={props.onExpandedRowIdsChange}
      />
      <TableRowDetail
        contentComponent={props.rowDetailComponent}
        cellComponent={DetailCell}
      />
      <DetailColumnLast />
    </Plugin>
  );
};

const DetailCell = (props) => {
  const { palette } = useTheme();
  return (
    <TableRowDetail.Cell
      {...props}
      style={{ backgroundColor: palette.background.default }}
    />
  );
};

export default RowDetailPluginBase;
