/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import _ from 'lodash';
import * as yup from 'yup';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

import {
  Box,
  DialogActions,
  DialogContent,
  List,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CancelOutlined } from '@mui/icons-material';

import CbButton from '../../../../Buttons/CbButton';
import { withShowable } from '../../../../../console/_global/lib/withShowable';
import { withFormController } from '../../../../hocs/forms';
import { InputLabelBase } from '../../../../inputs/InputLabelBase';
import { TextFieldBase } from '../../../../inputs/TextFieldBase';
import { MultiSelectControlledBase } from '../../../../inputs/autocomplete/MultiSelectControlledBase';

import {
  getAllAvailableEvents,
  postWebhook,
  updateWebhook,
} from '../../../../../api/OutgoingWebhooksService';

const TextField = withFormController(TextFieldBase);

const schema = yup.object().shape({
  webhookUrl: yup.string().label('Endpoint Url').url().required(),
  eventsToSend: yup
    .array()
    .label('Events to send')
    .min(1, '${label} must have at least one item'),
});

const DeletableListItem = ({ item, unselectEvent }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      border={1}
      p="0.5rem 1rem"
      style={{
        alignItems: 'center',
        borderRadius: '0.5rem',
        marginBottom: '0.5rem',
      }}
    >
      <Typography>{item}</Typography>
      <CancelOutlined
        color="action"
        style={{ fontSize: 20, color: 'red' }}
        onClick={() => unselectEvent(item)}
      />
    </Box>
  );
};

const EventsList = withShowable(({ selectedEvents, setSelectedEvents }) => {
  const eventLabels = selectedEvents.map((event) => event.label);
  const formContext = useFormContext();

  const unselectEvent = (eventToUnselect) => {
    const filteredEvents = selectedEvents.filter(
      (event) => event.value !== eventToUnselect
    );
    setSelectedEvents(filteredEvents);
    formContext.setValue('eventsToSend', filteredEvents);
  };

  if (!eventLabels) return null;
  return (
    <List>
      {eventLabels.map((event) => {
        return <DeletableListItem item={event} unselectEvent={unselectEvent} />;
      })}
    </List>
  );
});

export const CreateUpdateWebhook = ({ data, ...props }) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { palette } = theme;
  const { action, webhook } = data;
  const defaultValues = {
    webhookUrl: action === 'Update' ? webhook.webhookUrl : '',
    description: action === 'Update' ? webhook.description : '',
    eventsToSend: action === 'Update' ? webhook.events : [],
  };
  const defaultSelectedEvents =
    action === 'Update' ? transformEventsForSelect(webhook.events) : [];
  const [selectedEvents, setSelectedEvents] = useState(defaultSelectedEvents);

  const { ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { data: events, isLoading: areEventsLoading } = useQuery(
    ['events'],
    () => {
      return getAllAvailableEvents().then((res) => {
        return res.data.events;
      });
    },
    { refetchOnWindowFocus: false }
  );

  if (areEventsLoading) {
    return null;
  }

  const eventOptions = transformEventsForSelect(events);

  const onSubmit = (values) => {
    const eventsPayload = selectedEvents.map((event) => event.value);
    if (action === 'Update') {
      const { webhookId, webhookUrl } = webhook;
      const webhookUpdatePayload = {
        ...webhook,
        ...values,
        events: eventsPayload,
      };

      updateWebhook({ webhookId }, webhookUpdatePayload)
        .then((res) => {
          enqueueSnackbar(`Webhook ${webhookUrl} has been updated`, {
            variant: 'success',
          });
          queryClient.invalidateQueries(['webhook', webhookId]);
          props.close();
        })
        .catch((err) => {
          enqueueSnackbar(
            `Webhook ${webhookUrl} could not be updated. Please try again.`,
            { variant: 'error' }
          );
        });
    } else {
      const webhookCreationPayload = {
        ...values,
        events: eventsPayload,
        authType: 1,
      };

      postWebhook({}, webhookCreationPayload)
        .then((res) => {
          enqueueSnackbar('New webhook created', { variant: 'success' });
          queryClient.invalidateQueries(['webhooks']);
          props.close();
        })
        .catch((err) => {
          enqueueSnackbar(
            err.response.data.includes('Data already exists')
              ? err.response.data
              : 'Something went wrong while creating the new webhook. Please try again.',
            {
              variant: 'error',
            }
          );
        });
    }
  };

  const handleEventsChange = (e, selectedOptions) => {
    setSelectedEvents(selectedOptions);
    methods.setValue('eventsToSend', selectedOptions);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogContent className={classes.dialogContent}>
            <Box>
              <InputLabelBase required>Endpoint Url</InputLabelBase>
              <TextField
                name="webhookUrl"
                fullWidth
                placeholder="Endpoint Url"
                defaultValue={defaultValues.webhookUrl}
              />
            </Box>
            <Box>
              <InputLabelBase>Description</InputLabelBase>
              <TextField
                name="description"
                fullWidth
                placeholder="An optional description of what this web hook endpoint is used for."
                defaultValue={defaultValues.description}
              />
            </Box>
            <Box>
              <InputLabelBase required>Events to send</InputLabelBase>
              <MultiSelectControlledBase
                {...methods.register('eventsToSend')}
                options={eventOptions}
                onChange={handleEventsChange}
                value={selectedEvents}
                isOptionEqualToValue={isOptionEqualToValue}
              />
              <p style={{ color: palette.text.text9, marginTop: 0 }}>
                {_.get(methods, 'formState.errors.eventsToSend.message')}
              </p>
              <EventsList
                selectedEvents={selectedEvents}
                setSelectedEvents={setSelectedEvents}
                show={selectedEvents && selectedEvents.length}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <CbButton onClick={props.close} styleName="cancel">
              Cancel
            </CbButton>
            <CbButton type="submit" styleName="ctaButton">
              {data.action === 'Update' ? 'Update endpoint' : 'Add endpoint'}
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    </>
  );
};

const isOptionEqualToValue = (option, selected) => {
  if (option.value === selected.value) return true;
};

const transformEventsForSelect = (events) => {
  return events.map((event) => {
    return { value: event, label: event };
  });
};

const useStyles = makeStyles(() => ({
  dialogContent: {
    paddingTop: '3rem !important',
  },
}));

export const CreateUpdateWebhookConfig = {
  CreateUpdateWebhook: {
    component: CreateUpdateWebhook,
    config: {
      fullWidth: true,
      title: 'Add a webhook endpoint',
      disableEnforceFocus: true,
    },
  },
};
