import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';

/**
 * @name withBackfill
 * @description Middleware that will grab the required query params needed to render a view, if it was missing any params then the url will be updated and it will prevent a render.
 * @param statePath - {string} path to the location of the saved params
 * @param defaults - {object} default params in case the params were missing and the store doesn't have them either
 */
export function withBackfill(statePath, defaults = {}, overrideRqps) {
  return (WrappedComponent) => {
    const C = ({ rqps = [], q = {}, ...props }) => {
      const stored = useSelector((state) => {
        const saved = _.get(state, statePath, {});
        return { ...defaults, ...saved };
      });

      const missing = _.difference(overrideRqps || rqps || [], _.keys(q));

      if (!_.isEmpty(missing) && !_.isEmpty(stored)) {
        const raw = _.pick(stored, [...missing, 'f']);
        const nextParams = Object.keys(raw).reduce((acc, key) => {
          return {
            ...acc,
            [key]: typeof raw[key] === 'function' ? raw[key]() : raw[key],
          };
        }, {});

        props.updateUrl(nextParams);

        return null;
      }

      return <WrappedComponent {...props} q={q} />;
    };

    C.displayName = `withBackfill(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;
    C.wrappedComponent = WrappedComponent;

    return hoistNonReactStatics(C, WrappedComponent);
  };
}
