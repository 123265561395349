import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import React from 'react';

const InvoiceIcon = (
  props: Omit<React.ComponentProps<typeof FontAwesomeIcon>, 'icon'>
) => {
  return <StyledIcon icon={light('file-invoice')} {...props} />;
};

export default InvoiceIcon;

const StyledIcon = styled(FontAwesomeIcon)(({ theme }: { theme: any }) => {
  return {
    color: theme.config.colors.greyWhite,
  };
});
