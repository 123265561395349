import React from 'react';
// mui
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const CoverageHeader = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.header}>
      <Grid item md={2} className={classes.grid} />

      <Grid item md={4} className={classes.grid}>
        <span className={classes.heading}>
          Coverage <br /> Limit
        </span>
      </Grid>

      <Grid item md={2} className={classes.grid}>
        <span className={classes.heading}>D</span>
      </Grid>

      <Grid item md={2} className={classes.centerText}>
        <span className={`${classes.heading} ${classes.waitingP}`}>W</span>
      </Grid>

      <Grid item md={2} className={classes.centerText}>
        <span className={classes.heading}>R</span>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(({ config, palette }) => ({
  header: {
    height: '3.66rem',
    alignItems: 'center',
    paddingTop: '0.5rem',
    color: palette.primary.contrastText,
  },
  heading: {
    textTransform: 'uppercase',
  },
  waitingP: {
    borderWidth: '0 1px',
    borderStyle: 'solid',
    border: config.colors.paleGrey1,
    padding: '0 1rem',
  },
  grid: {
    textAlign: 'right',
    paddingRight: 'calc(1rem + 18px)',
  },
  centerText: {
    textAlign: 'center',
  },
}));

export default CoverageHeader;
