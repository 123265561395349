import React from 'react';
import clsx from 'classnames';
// components
import { Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { InputLabelBase } from '../InputLabelBase';
import { withShowable } from '../../../console/_global/lib/withShowable';
import { InputErrorPlace } from '../InputErrorPlace';

export const SimpleSelect = withShowable(
  ({
    className,
    error,
    label,
    helpText,
    offset = false,
    options = [],
    renderOption,
    variant = 'default',
    InputLabelProps = {},
    ErrorProps = { renderErrorPlaceholder: false },
    ...props
  }) => {
    const { offsetStyles } = useCommonStyles();
    const variantClasses = useVariantStyles(variant);

    const classes = clsx('normal-text', className, {
      [offsetStyles]: offset,
    });

    if (label) {
      return (
        <>
          <InputLabelBase indent required={props.required} {...InputLabelProps}>
            {label}
          </InputLabelBase>
          <Select
            variant="standard"
            className={classes}
            classes={variantClasses}
            inputProps={{ 'data-qa': `SELECT_INPUT:${props.name}` }}
            {...props}
          >
            {renderOptions(options, renderOption)}
          </Select>
          {helpText && !error && <span>{helpText}</span>}
          <InputErrorPlace error={error} {...ErrorProps} />
        </>
      );
    }

    return (
      <Select
        variant="standard"
        className={classes}
        classes={variantClasses}
        {...props}
      >
        {renderOptions(options, renderOption)}
      </Select>
    );
  }
);

function renderOptions(options, renderOption = null) {
  return options.map((option) =>
    typeof renderOption === 'function' ? (
      renderOption(option)
    ) : (
      <MenuItem key={option.value} {...option}>
        {option?.label}
      </MenuItem>
    )
  );
}

const useVariantStyles = (variant) => {
  if (variant === 'naked') {
    return nakedVariant();
  }

  if (variant === 'contained') {
    return containedStyles();
  }

  return {};
};

const useCommonStyles = makeStyles(() => ({
  offsetStyles: {
    '& $icon': {
      transform: 'translate(18px, -50%)',
    },
    '& $select': {
      transform: 'translateX(18px)',
    },
  },
}));

const containedStyles = makeStyles(({ palette, shape }) => ({
  select: {
    borderRadius: shape.borderRadius,

    '&.MuiInputBase-input': {
      background: palette.background.details,
    },

    '&:focus': {
      backgroundColor: palette.background.details,
    },
  },

  // select: {
  //   border: 'none',
  // },

  disabled: {
    backgroundColor: palette.background.disabled,
  },
}));

const nakedVariant = makeStyles(() => ({
  icon: {
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
  },

  select: {
    fontSize: 'inherit',
    justifyContent: 'flex-end',
    padding: 0,
    paddingRight: '18px !important',
    border: 'none',
    background: 'inherit',
  },
}));
