import React from 'react';
import _omit from 'lodash/omit';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { updateCmailMessage } from '../../../api/cmail.api';
import { useMeQuery } from '../../../components/hooks/useMeQuery';
import { useQueryParams } from '../../../components/providers/QueryParamsProvider';
import { useSavedSettings } from '../../../components/tables/dev-express/hooks/useSavedSettings';
import { CMAIL } from '../cmail.statics';

export const ViewType = {
  LISTING: 'LISTING',
  DETAIL: 'DETAIL',
};

export const DetailViewMode = {
  SIDE_BY_SIDE: 'SIDE_BY_SIDE',
  FULL_SCREEN: 'FULL_SCREEN',
};

export const FilterType = {
  INBOX: 'INBOX',
  ARCHIVE: 'ARCHIVE',
  STARRED: 'STARRED',
  CLAIMS: 'CLAIMS',
  SUBMISSIONS: 'SUBMISSIONS',
  HIGH_PRIORITY: 'HIGH_PRIORITY',
  MED_PRIORITY: 'MED_PRIORITY',
  LOW_PRIORITY: 'LOW_PRIORITY',
};

export const DataFilter = {
  INBOX: { isArchived: { bo: 0 } },
  ARCHIVE: { isArchived: { bo: 1 } },
  STARRED: { isStarred: { bo: 1 }, isArchived: { bo: 0 } },
  CLAIMS: { msgCategory: { eq: 'claimsops' } },
  SUBMISSIONS: { msgCategory: { eq: 'submission' } },
  HIGH_PRIORITY: { priority: { gt: 6 } },
  MED_PRIORITY: { priority: { gt: 4, lt: 7 } },
  LOW_PRIORITY: { priority: { lt: 5 } },
};

export const useCmailState = () => {
  const location = useLocation();
  const { query, replace } = useQueryParams();
  const { uiSettings } = useSavedSettings(CMAIL, {});
  const { data: sessionUser } = useMeQuery();
  const queryClient = useQueryClient();

  const [selection, setSelection] = React.useState({});
  const [view, setView] = React.useState(ViewType.LISTING);
  const [targetMessage, setTargetMessage] = React.useState(null);
  const [detailViewMode, setDetailViewMode] = React.useState(
    uiSettings.detailViewMode ?? DetailViewMode.SIDE_BY_SIDE
  );

  const [filterType, setFilterType] = React.useState(FilterType.INBOX);
  const filterParam = DataFilter[filterType];
  const [categoryFilter, setCategoryFilter] = React.useState({});
  const [priorityFilter, setPriorityFilter] = React.useState({});

  const showSplitScreen =
    detailViewMode === DetailViewMode.SIDE_BY_SIDE && !!targetMessage;

  React.useEffect(() => {
    applyFilters();
    // eslint-disable-next-line
  }, [categoryFilter, priorityFilter, filterType]);

  const onSelect = (message) => {
    if (message.msgId in selection) {
      setSelection((prev) => _omit(prev, message.msgId));
    } else {
      setSelection((prev) => ({ ...prev, [message.msgId]: message }));
    }
  };

  const onClearSelection = () => {
    setSelection({});
  };

  const markAsRead = (message) => {
    if (message.seenBy.includes(sessionUser?.email)) {
      return;
    }
    const nextSeenBy = [...message.seenBy, sessionUser?.email];
    updateCmailMessage({
      params: { msgId: message.msgId },
      data: { seenBy: nextSeenBy },
    });
    setTimeout(() => {
      queryClient.invalidateQueries('CMAIL');
    }, 200);
    onClearSelection();
  };

  const batchMarkAsRead = (messages = []) => {
    messages.forEach((message) => markAsRead(message));
  };

  const markAsUnread = (message) => {
    const nextSeenBy = message.seenBy.filter(
      (userEmail) => userEmail !== sessionUser.email
    );
    updateCmailMessage({
      params: { msgId: message.msgId },
      data: { seenBy: nextSeenBy },
    });
    setTimeout(() => {
      queryClient.invalidateQueries('CMAIL');
    }, 200);
    onClearSelection();
  };

  const batchMarkAsUnread = (messages = []) => {
    messages.forEach((message) => markAsUnread(message));
  };

  const markAsArchived = (message) => {
    updateCmailMessage({
      params: { msgId: message.msgId },
      data: { isArchived: true },
    });
    setTimeout(() => {
      queryClient.invalidateQueries('CMAIL');
    }, 200);
    onClearSelection();
  };

  const batchMarkAsArchived = (messages = []) => {
    messages.forEach((message) => markAsArchived(message));
  };

  const markAsUnarchived = (message) => {
    updateCmailMessage({
      params: { msgId: message.msgId },
      data: { isArchived: false },
    });
    setTimeout(() => {
      queryClient.invalidateQueries('CMAIL');
    }, 200);
    onClearSelection();
  };

  const batchMarkAsUnarchived = (messages = []) => {
    messages.forEach((message) => markAsUnarchived(message));
  };

  const toggleStarred = (message) => {
    updateCmailMessage({
      params: { msgId: message.msgId },
      data: { isStarred: !message.isStarred },
    });
    setTimeout(() => {
      queryClient.invalidateQueries('CMAIL');
    }, 100);
  };

  const goToListingView = () => {
    setView(ViewType.LISTING);
    setTargetMessage(null);
  };

  const goToDetailView = (message) => {
    markAsRead(message);
    setTargetMessage(message);
    if (detailViewMode === DetailViewMode.FULL_SCREEN) {
      setView(ViewType.DETAIL);
    }
  };

  const applyFilters = () => {
    replace(location.pathname, {
      query: {
        ...query,
        f: {
          ...DataFilter[filterType],
          ...(DataFilter?.[categoryFilter] ?? {}),
          ...(DataFilter?.[priorityFilter] ?? {}),
        },
        page: 0,
      },
    });
  };

  const onFilterChange = (filterName, bucket) => {
    if (!bucket) {
      setFilterType(filterName);
    }
    if (bucket === 'CATEGORY') {
      if (categoryFilter === filterName) {
        setCategoryFilter(null);
      } else {
        setCategoryFilter(filterName);
      }
    }
    if (bucket === 'PRIORITY') {
      if (priorityFilter === filterName) {
        setPriorityFilter(null);
      } else {
        setPriorityFilter(filterName);
      }
    }
  };

  const onDetailViewModeChange = (mode) => {
    setDetailViewMode(mode);
  };

  return {
    selection,
    onSelect,
    onClearSelection,
    view,
    goToListingView,
    goToDetailView,
    targetMessage,
    markAsRead,
    batchMarkAsRead,
    markAsUnread,
    markAsArchived,
    batchMarkAsArchived,
    markAsUnarchived,
    batchMarkAsUnarchived,
    batchMarkAsUnread,
    toggleStarred,
    filterType,
    filterParam,
    onFilterChange,
    categoryFilter,
    priorityFilter,
    detailViewMode,
    showSplitScreen,
    onDetailViewModeChange,
  };
};
