import React from 'react';
import { MenuItem } from '@mui/material';
import { SimpleMenu } from '../../Menus/SimpleMenu';
import { ActionsButton } from './helpers';
import { useMultiSelectState } from './useMultiSelectState';

function MultiSelectActionMenu({ actions = {} }) {
  const { selectedRows, ...context } = useMultiSelectState();

  const selectedRowsList = React.useMemo(
    () =>
      Object.entries(selectedRows).map(([key, value]) => ({
        id: key,
        data: value,
      })),
    [selectedRows]
  );
  const selectedRowsCount = selectedRowsList.length;

  function onMenuItemClick(handler) {
    if (selectedRowsCount > 0) {
      handler(selectedRowsList, context);
    }
  }

  return (
    <SimpleMenu
      variant="menu"
      getContentAnchorEl={null}
      anchor={<ActionsButton selectedCount={selectedRowsCount} />}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      minWidth="10.4rem"
      style={{ top: 4 }}
    >
      {Object.entries(actions).map(([key, value]) => {
        const { label, handler, renderMenuItem } = value;
        if (typeof renderMenuItem == 'function') {
          return (
            <React.Fragment key={key}>
              {renderMenuItem({ selectedRowsList })}
            </React.Fragment>
          );
        }

        return (
          <MenuItem key={key} onClick={() => onMenuItemClick(handler)}>
            {label}
          </MenuItem>
        );
      })}
    </SimpleMenu>
  );
}

export default MultiSelectActionMenu;
