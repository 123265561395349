import React from 'react';
import _ from 'lodash';

export const useUiSettingsAutosave = (domain, whitelist, blacklist = []) => {
  const watchlist = _.omit(whitelist, [
    'page',
    'search',
    'searchAfter',
    'pageRequested',
    ...blacklist,
  ]);
  const store = JSON.parse(localStorage.getItem('cowbell')) || {};
  const { uiSettings = {} } = store;

  React.useEffect(
    () => {
      if (Object.prototype.hasOwnProperty.call(watchlist, 'columns')) {
        watchlist.columns = Object.values(watchlist.columns).reduce(
          (acc, col) => ({
            ...acc,
            [col.name]: { name: col.name, show: col.show },
          }),
          {}
        );
      }

      localStorage.setItem(
        'cowbell',
        JSON.stringify({
          ...store,
          uiSettings: { ...uiSettings, [domain]: watchlist },
        })
      );
    },
    // eslint-disable-next-line
    [watchlist]
  );
};
