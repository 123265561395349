import React from 'react';
import { compose } from 'redux';
// mui
import {
  Dialog,
  DialogTitle,
  Box,
  IconButton as MuiIconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
// components
import { useSurplusContext } from '../../prime250.context';
import { withSurplus } from '../../../../../../inbox/withSurplus';
import { InsuranceLineSymbol } from '../../../../../../components/InsuranceLineSymbol';
import { UWBindQuoteConfirmation } from './UWBindQuoteConfirmation';

const styles = () => {
  return {
    icon: {
      position: 'absolute',
      right: 0,
      top: '0.75rem',
      paddingRight: '5rem',
    },
  };
};

export const UWBindQuote = compose(
  withSurplus(),
  withStyles(styles)
)(({ iconStyles, data, classes, ...props }) => {
  const { dispatch } = useSurplusContext();

  const handleCancel = () => {
    props.close();
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        <Box>
          <VerifiedUserOutlinedIcon
            fontSize="large"
            className={iconStyles.root}
          />{' '}
          Bind Quote for {data.companyName}
        </Box>
        <div className={classes.icon}>
          <InsuranceLineSymbol isSurplus={data.payload.isSurplus} />
        </div>
        <MuiIconButton className="iconBody" onClick={props.close}>
          <img src="images/Close-Modal.svg" alt="modal-close" />
        </MuiIconButton>
      </DialogTitle>
      <UWBindQuoteConfirmation
        data={data}
        setHandleCancel={handleCancel}
        dispatch={dispatch}
      />
    </Dialog>
  );
});

export const UWBindQuoteConfig = {
  UWBindQuote: {
    component: UWBindQuote,
    config: {
      fullWidth: false,
      maxWidth: 'md',
      override: true,
    },
  },
};
