import React from 'react';

import { Box, Button, DialogActions, DialogContent } from '@mui/material';

import { iconMappings } from '../../../console/_global/navigation/IconsMappings';
import { Uploadzone } from '../../fileUploader/v2/UploadZone';
import useUploadzone from '../../fileUploader/v2/hooks/useUploadzone';
import { CircularProgressIndicatorWithPercentage } from '../../fileUploader/v2/CircularProgressIndicatorWithPercentage';
import { uploadDocumentV3 } from '../../../api/documents.api';

export const TestForm = () => {
  return (
    <>
      <DialogContent>
        <FileUploadForm />
      </DialogContent>
    </>
  );
};

export const TestFormConfig = {
  TestForm: {
    component: TestForm,
    config: {
      /* any material ui configuration options  */
      fullWidth: true,
      // maxWidth: 'sm',

      /* take complete control of the modal, you have to render the Dialog */
      // override: true,

      /* indicate which theme the dialog inherits from (light, dark) */

      /* if not using override, set the title */
      title: 'Testing modal title',

      /* if not using override set the icon, defaults to cowbell logo */
      icon: iconMappings.ACCOUNTS.iconImport,
    },
  },
};

export const FileUploadForm = () => {
  const {
    files,
    handleUploads,
    isUploading,
    percentageUploaded,
    ...uploadZoneParams
  } = useUploadzone();

  const asyncUploadFunc = React.useCallback(({ file, axiosConfig }) => {
    const formData = new FormData();
    formData.append('file', file);

    return uploadDocumentV3({
      data: formData,
      params: {
        accountId: 'aab4d70b-103d-4e41-b2f8-0b7280d387f3',
        agencyId: '400bbe78-6eee-40b4-b684-6b56b023991f',
        docType: 'LPR',
        docName: file.name,
        temp: true,
      },
      ...axiosConfig,
    });
  }, []);

  const onSubmit = React.useCallback(() => {
    handleUploads({
      asyncUploadFunc,
      onFileUploadError: () => 'Will catch each individaul file error',
    }).catch(() => 'Or you can catch them all here ༼ つ ◕_◕ ༽つ');

    // eslint-disable-next-line
  }, [files]);

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Uploadzone {...uploadZoneParams} files={files} />
        {isUploading && (
          <CircularProgressIndicatorWithPercentage value={percentageUploaded} />
        )}
      </Box>

      <DialogActions style={{ paddingRight: 0 }}>
        <Button color="primary" onClick={onSubmit}>
          Submit
        </Button>
      </DialogActions>
    </>
  );
};
