import { commonDateParamMappings } from '../../../api/common/mappings';
import { ServiceHandlerFactory } from '../../../utils/serviceUtils';

export const getTransactions = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/search',
  },
  isCancelable: false,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        const newOrder = order || 'asc';
        const newOrderBy = orderBy === 'modified' ? 'paymentDate' : orderBy;

        switch (newOrderBy) {
          case 'paymentDate':
          case 'modified':
          case 'amountPaid':
            return `${newOrderBy},${newOrder}`;
          default:
            return newOrderBy ? `${newOrderBy}.keyword,${newOrder}` : null;
        }
      },
    },
    ...commonDateParamMappings,
  ],
});

export const getPaymentMethods = ServiceHandlerFactory({
  axios: {
    url: `api/billing/v1/payment`,
  },
  isCancelable: false,
});

export const getACHDetails = ServiceHandlerFactory({
  axios: {
    url: `api/billing/v1/ach/:policyNumber`,
  },
  isCancelable: false,
});

export const getPaymentProcessor = ServiceHandlerFactory({
  axios: {
    url: `api/billing/v1/payment-processor`,
  },
  isCancelable: false,
});

export const getOneIncKey = ServiceHandlerFactory({
  axios: {
    url: `api/billing/v1/oneInc/key`,
  },
  isCancelable: false,
});

export const getOneIncKeyByPolicyNumber = ServiceHandlerFactory({
  axios: {
    url: `api/billing/v1/oneInc/:policyNumber`,
  },
  isCancelable: false,
});

export const paymentComplete = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/paid',
    method: 'post',
  },
});

export const adminPaymentComplete = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/payfor/:accountId',
    method: 'post',
  },
});

export const savePayment = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/save',
    method: 'post',
  },
});

export const sendAccounting = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/accounting',
    method: 'post',
  },
});

export const confirmFinancing = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/agreement',
    method: 'post',
  },
});

export const installmentPlanForm = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/finance',
    method: 'post',
  },
});

export const getXpressDocument = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/finance',
  },
});

export const getTitleDesc = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/desc',
  },
});

export const getXpressStatus = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/xpress-enabled',
  },
});

export const getPaymentStatus = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/oneInc/validate',
  },
});

export const closePremFinance = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/pay/premiumFinance',
    method: 'put',
  },
});
export const createPremFinance = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/create/premiumFinance',
    method: 'post',
  },
});

export const getBalanceOnPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/balance/:policyId',
  },
});

export const getBalanceOnPolicyInvoice = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/invoice/balance/:policyId',
  },
});

export const makePaymentFromCowbell = ServiceHandlerFactory({
  axios: {
    url: '/api/billing/v1/external/applyPayment',
    method: 'post',
  },
});

export const getInstallmentAmount = ServiceHandlerFactory({
  axios: {
    url: '/api/billing/v1/get-installment',
    method: 'post',
  },
});

export const updateTransaction = ServiceHandlerFactory({
  axios: {
    url: '/api/billing/v1/transaction',
    method: 'put',
  },
});

export const checkAgencyBilledInvoice = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/agencyBillInvoice/:month/:year',
  },
});

export const createBillingContact = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/contact',
    method: 'post',
  },
});

export const updateBillingContact = ServiceHandlerFactory({
  axios: {
    url: ' /api/billing/v1/contact',
    method: 'put',
  },
});

export const deleteBillingContact = ServiceHandlerFactory({
  axios: {
    url: 'api/billing/v1/contact?id=:id',
    method: 'delete',
  },
});

export const fetchBillingContact = ServiceHandlerFactory({
  axios: {
    url: ' /api/billing/v1/contact',
  },
  isCancelable: false,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'modified':
            if (orderBy && order) {
              return `${orderBy},${order}`;
            }
            break;
          default:
            return orderBy ? `${orderBy}.keyword,${order}` : null;
        }
      },
    },
    ...commonDateParamMappings,
  ],
});
