import React, { useState } from 'react';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form-4';
// mui
import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
// components
import CbButton from '../../components/Buttons/CbButton';
// utils
import { delayedEvent } from '../../utils/eventUtils';
// actions
import { deleteVendor } from '../../api/vendor.api';

const VendorDeleteConfirm = ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id, name } = data;
  const [error, setError] = useState(false);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = () => {
    return deleteVendor({ id })
      .then(() => {
        enqueueSnackbar('Vendor removed successfully!', { variant: 'success' });
        delayedEvent('table-refetch', 500, 'vendors');
        close();
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data',
            'Something went wrong.Please try again later'
          )
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent style={{ padding: '2rem 5rem' }}>
        <section className="modal-title">
          Are you sure you want to delete{' '}
          <span className="modal-blue-text">{name}</span>?
        </section>
        {error && (
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" action={close}>
          Cancel
        </CbButton>
        <CbButton
          loading={isSubmitting}
          disabled={isSubmitting}
          styleName="ctaButton"
          buttonText="Remove Vendor"
          type="submit"
        />
      </DialogActions>
    </form>
  );
};

export default VendorDeleteConfirm;

export const VendorDeleteConfirmConfig = {
  VendorDeleteConfirm: {
    component: VendorDeleteConfirm,
    config: {
      fullWidth: true,
      maxWidth: 'sm',
    },
  },
};
