import { Controller } from 'react-hook-form';

export const withAutocompleteFormControllerBase = (AutocompleteComponent) => {
  function cleanseMiddleware(onChange) {
    return (event, newValue) => onChange(newValue);
  }

  const Wrapper = function ({
    name,
    label,
    required = false,
    disabled = false,
    cleanseEvent = false,
    autocompleteProps = {},
    controllerProps = {},
  }) {
    return (
      <Controller
        name={name}
        render={({ field, fieldState, formState }) => (
          <AutocompleteComponent
            {...autocompleteProps}
            {...field}
            name={name}
            label={label}
            formState={formState}
            onChange={
              cleanseEvent ? cleanseMiddleware(field.onChange) : field.onChange
            }
            required={required}
            disabled={disabled}
            textFieldProps={{
              ...autocompleteProps.textFieldProps,
              ...fieldState,
              error: fieldState.error?.message,
            }}
          />
        )}
        {...controllerProps}
      />
    );
  };

  Wrapper.displayName = `withAutocompleteFormControllerBase${
    AutocompleteComponent.displayName || AutocompleteComponent.name
  }`;

  return Wrapper;
};
