/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { useQuery } from '@tanstack/react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import type { AxiosError } from 'axios';
import * as Yup from 'yup';

// components
import { Box, Typography as MuiTypography, styled } from '@mui/material';

import Showable from '../../Showable';
import { LoadingButton } from '../../Buttons/LoadingButton';
import { PasswordField } from '../../inputs/text-fields/passwords/PasswordField';

import NextPublicWrapper from '../NextPublicWrapper';
import { PasswordConstraintsList } from './PasswordContraintsList';

import { withShowable } from '../../../console/_global/lib/withShowable';

// other
import {
  checkPasswordResetValToken,
  postPasswordResetValToken,
  validatePassword,
} from '../../../api/SignupService';

import { usePasswordConstraints } from '../../hooks/auth/usePasswordConstraints';
import type { ValidatePasswordDto } from '../../../api/auth/auth.dto';

interface FormValues {
  password: string;
  confirmedPassword: string;
}

interface QueryParams {
  token: string;
}

const Typography = withShowable(MuiTypography);

function NewConfirmPasswordReset() {
  const history = useHistory();
  const { token } = useParams<QueryParams>();

  const methods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  function onTokenValidationError() {
    history.push('/login');
  }

  const { error, isError, isInitialLoading }: any = useResetTokenValidation(
    token,
    onTokenValidationError
  );

  const constraints = usePasswordConstraints();

  function onSubmit(values: FormValues) {
    return postPasswordResetValToken(token, values).then(() => {
      history.replace('/loading');
    });
  }

  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    methods.setValue('password', value);

    handleValidatePassword(value);
  }

  function handleConfirmPasswordChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { value } = event.target;
    methods.setValue('confirmedPassword', value);
  }

  async function handleValidatePassword(nextValue: string) {
    try {
      const remoteErrors: any = await validatePassword({
        params: {
          token,
          password: nextValue,
          isResetToken: true,
        },
      });

      handleValidatePasswordError(remoteErrors);
    } catch (error: any) {
      handleValidatePasswordError(error.response.data);
    }
  }

  function handleValidatePasswordError({ errors }: ValidatePasswordDto) {
    constraints.setPasswordErrors(errors);
  }

  return (
    <NextPublicWrapper
      MarketingSlot={
        <PasswordRequirements
          constraints={constraints.data}
          initial={constraints.initial}
        />
      }
    >
      <Showable show={!isInitialLoading && isError}>
        <Typography paragraph>{error && (error?.message as string)}</Typography>
        <Typography>
          To get a new link, visit{' '}
          <Link
            className="link-underlined"
            to="/forgot-password"
            style={{ color: 'white' }}
          >
            Reset Password
          </Link>
          .
        </Typography>
      </Showable>
      <Showable show={!isError}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box mb={2}>
              <PasswordField
                /* @ts-ignore */
                id="password"
                label="Password"
                required
                onChange={handlePasswordChange}
              />
            </Box>

            <PasswordField
              /* @ts-ignore */
              id="confirmedPassword"
              label="Confirm Password"
              required
              onChange={handleConfirmPasswordChange}
              error={methods.formState.errors.confirmedPassword}
            />
            <ErrorMessage name="confirmedPassword" />

            <Box mt={2}>
              {/* @ts-ignore */}
              <LoadingButton
                styleName="ctaButton"
                type="submit"
                loading={methods.formState.isSubmitting}
                disabled={methods.formState.isSubmitting}
              >
                Save Password
              </LoadingButton>
            </Box>
          </form>
        </FormProvider>
      </Showable>
    </NextPublicWrapper>
  );
}

export default NewConfirmPasswordReset;

const validationSchema = Yup.object().shape({
  password: Yup.string().required(),
  confirmedPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  ),
});

function useResetTokenValidation(token: string, onError: () => void) {
  return useQuery(
    ['password', 'reset', 'token'],
    () =>
      checkPasswordResetValToken(token)
        .then(() => true)
        .catch((error: AxiosError) => {
          if (
            error.response?.status === 400 ||
            error.response?.status === 404
          ) {
            throw new Error(
              `The reset link you're using has expired and you will need to get a new one to continue resetting your password`
            );
          }

          if (error.response?.status === 401) {
            return onError();
          }

          throw new Error(
            `An unexpected error has occurred ${error.response?.status}`
          );
        }),
    {
      retry: false,
    }
  );
}

function PasswordRequirements({ constraints, initial }: any) {
  return (
    <RequirementsContainer className="direction-column justify-center">
      <Typography className="canon-text" gutterBottom>
        We take your cyber security seriously
      </Typography>
      <Typography className="tertia-text" gutterBottom>
        We highly recommend using a secure password manager as an additional
        layer of security and convienence.
      </Typography>
      <RequirementsCard>
        <PasswordConstraintsList constraints={constraints} initial={initial} />
      </RequirementsCard>
      <Box mt={3}>
        <Typography className="normal-text text-center" gutterBottom>
          If you are have trouble resetting your password please reach out to{' '}
          <a
            href="mailto:support@cowbellcyber.ai"
            className="link-underlined"
            style={{ color: 'white' }}
          >
            support@cowbellcyber.ai
          </a>
        </Typography>
      </Box>
    </RequirementsContainer>
  );
}

const RequirementsContainer = styled(Box)({
  padding: '2rem',
  zIndex: 1,
});

const RequirementsCard = styled(Box)(({ theme }) => ({
  marginTop: '1rem',
  background: '#26262e',
  border: '1px solid #1a1a1a',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 15px 15px -5px #000',
}));
