import type React from 'react';
import { useSnackbar } from 'notistack';

// lodash
import _ from 'lodash';

import { downloadAWSDoc } from '../../accounts/AccountService';
import { downloadDocument } from '../../api/DocumentsService';

import { saveBlobFile } from '../../utils/appUtils';

interface ChildrenArgs {
  handleDocumentDownload: () => void;
}

interface Props {
  id?: string;
  name: string;
  type: string;
  onDownload?: () => Promise<any>;
  onError?: () => void;
  children: ({ handleDocumentDownload }: ChildrenArgs) => JSX.Element | null;
}

export const DocumentDownloader: React.FC<Props> = ({
  id,
  name,
  type,
  children,
  onDownload,
  onError = null,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  async function handleDocumentDownload() {
    const handleDownload =
      typeof onDownload === 'function'
        ? onDownload
        : () => downloadDocument({ docId: id });

    const resp = await handleDownload().catch(() => undefined);
    const url = _.get(resp, 'data');

    if (!url) {
      if (typeof onError === 'function') {
        return onError();
      }
      return enqueueSnackbar('Download url not available', {
        variant: 'error',
      });
    }

    try {
      enqueueSnackbar(
        'Document download started, file will be available shortly',
        {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'info',
        }
      );

      const awsResp = await downloadAWSDoc(resp.data);

      saveBlobFile(awsResp, name, `.${type}`);
    } catch (error) {
      enqueueSnackbar('Unable to download document', { variant: 'error' });
    }
  }

  return children({ handleDocumentDownload });
};
