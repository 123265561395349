import React, { cloneElement } from 'react';

import {
  Card as MuiCard,
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableCell,
  useTheme,
} from '@mui/material';
import { Skeleton as MuiSkeleton } from '@mui/lab';

import { ActionBar as DefaultActionBar } from './action-bar.table';
import { TableBody as DefaultTableBody } from './body.table';
import { TableFooter as DefaultTableFooter } from './footer.table';
import { TableHeader as DefaultTableHeader } from './header.table';
import { TableContext } from './context.table';

// utils
import { prettyId } from '../../../../utils/data.utils';

export const TableSkeleton = ({
  classes,
  cardProps,
  children,
  context,
  loading,
  fixed,
  rowHover,
  ...props
}) => {
  const { palette } = useTheme();
  const style = { background: 'none' };
  const rows = props.data.length
    ? props.data
    : Array(5).fill({
        id: prettyId(),
      });

  const { ActionBar, TableHeader, ...childOverrides } =
    createChildHash(children);

  return (
    <TableContext.Provider
      value={{
        id: props.id,
        columns: props.columns,
        data: rows,
        rowHover,
        childOverrides,
        loading,
        fixed,
        ...context,
      }}
    >
      <>
        {ActionBar ? cloneElement(ActionBar, props) : <DefaultActionBar />}
        <MuiCard
          className={
            ActionBar && ActionBar.props.classes
              ? classes.containerBlank
              : classes.container
          }
          {...cardProps}
          style={style}
        >
          <MuiTable
            {...props}
            style={{ tableLayout: fixed && 'fixed' }}
            aria-label="sticky table"
          >
            {TableHeader ? (
              cloneElement(TableHeader, props)
            ) : (
              <DefaultTableHeader />
            )}
            <DefaultTableBody
              loading={loading}
              render={(data) => (
                <>
                  {data.map((row, idx) => (
                    <MuiTableRow
                      key={`tr-${idx}`}
                      row={row}
                      style={{ background: palette.background.lighter }}
                    >
                      {props.columns.map((column, index) => {
                        if (column.show === false) {
                          return null;
                        }

                        return (
                          <TableCell key={`tr-${index}`}>
                            <MuiSkeleton width="60%" />
                          </TableCell>
                        );
                      })}
                    </MuiTableRow>
                  ))}
                </>
              )}
            />
          </MuiTable>
          <DefaultTableFooter />
        </MuiCard>
      </>
    </TableContext.Provider>
  );
};

function createChildHash(children) {
  return React.Children.toArray(children).reduce((acc, child) => {
    return {
      ...acc,
      [child.type.displayName || child.type.name]: child,
    };
  }, {});
}
